import { FC, useCallback, useEffect, useState } from "react";
import { Drawer } from "@mui/material";
import { LiveFilteredResourcesDrawerContentForView } from "./components/view/LiveFilteredResourcesDrawerContentForView";
import { getResourceExplorerLiveFilteredResourcesThunk } from "../../../../../../../../../../../store/resource-explorer/thunks/resource-explorer/getResourceExplorerLiveFilteredResourcesThunk";
import { useAppDispatch } from "../../../../../../../../../../../store/hooks";

interface LiveFilteredResourcesDrawerProps {
  open: boolean;
  onClose(): void;
  loadFrom: "widget" | "resource-explorer";
}
export const LiveFilteredResourcesDrawer: FC<
  LiveFilteredResourcesDrawerProps
> = ({ open, onClose, loadFrom }) => {
  const dispatch = useAppDispatch();
  const [renderBody, setRenderBody] = useState(false);
  const onTransitionEnd = useCallback(() => {
    !renderBody && open && setRenderBody(true);
  }, [renderBody, open]);

  const drawerCloseHandler = useCallback(() => {
    setRenderBody(false);
    onClose();
  }, [onClose]);

  useEffect(() => {
    if (open && loadFrom === "resource-explorer") {
      dispatch(getResourceExplorerLiveFilteredResourcesThunk());
    }
  }, [open, loadFrom, dispatch]);

  return (
    <Drawer
      anchor="right"
      onClose={drawerCloseHandler}
      open={open}
      PaperProps={paperProps}
      onTransitionEnd={onTransitionEnd}
    >
      <LiveFilteredResourcesDrawerContentForView
        onDrawerClose={drawerCloseHandler}
      />
    </Drawer>
  );
};

const paperProps = {
  sx: {
    width: "80dvw",
  },
};
