import { FC, Fragment, memo } from "react";
import { Divider } from "@mui/material";
import { useFlag } from "@unleash/proxy-client-react";
import { IntegrationMenuItemsV2 } from "./integration-menu-items/IntegrationMenuItemsV2";
import { ProtectActionItemV2 } from "./protect/ProtectActionItemV2";
import { CreateWorkflowMenuItemV2 } from "./CreateWorkflowMenuItemV2";
import { CleanV2ActionItems } from "./clean-actions/CleanV2ActionItems";
import { CreateTaskActionItem } from "./CreateTaskActionItem";
import {
  ProviderAccessType,
  RdsSnapshot,
  ResourceType,
} from "../../../../../../../../../../../../services/cloudchipr.api";

interface ActionsMenuCellProps {
  resourceType: ResourceType;
  id: string;
  uniqueIdentifier: string;
  isProtected: boolean;
  inProgress?: boolean;
  unavailableActions?: string[];
  type?: RdsSnapshot["type"];
  closeMenu(): void;
  openProtectDialog(): void;
  accessType: ProviderAccessType;
}

export const ActionsMenuItemsV2: FC<ActionsMenuCellProps> = memo(
  ({
    id,
    resourceType,
    isProtected,
    unavailableActions,
    inProgress,
    closeMenu,
    openProtectDialog,
    uniqueIdentifier,
    accessType,
  }) => {
    const enableWorkflowCreationFromAccountPage = useFlag(
      "EnableWorkflowCreationFromAccountPage",
    );

    return (
      <Fragment>
        <CleanV2ActionItems
          id={id}
          closeMenu={closeMenu}
          inProgress={inProgress}
          isProtected={isProtected}
          resourceType={resourceType}
          unavailableActions={unavailableActions}
          accessType={accessType}
        />

        <IntegrationMenuItemsV2 resourceType={resourceType} id={id} />

        <CreateTaskActionItem
          onClose={closeMenu}
          resourceId={id}
          resourceType={resourceType}
        />

        <Divider />

        {enableWorkflowCreationFromAccountPage && (
          <CreateWorkflowMenuItemV2
            onClose={closeMenu}
            resourceType={resourceType}
            uniqueIdentifier={uniqueIdentifier}
          />
        )}

        <ProtectActionItemV2
          onOpen={openProtectDialog}
          isProtected={isProtected}
          accessType={accessType}
          inProgress={inProgress}
        />
      </Fragment>
    );
  },
);
