import { FC } from "react";
import { InternalDashboardCommitmentUtilizationWidget } from "./InternalDashboardCommitmentUtilizationWidget";
import { DashboardWidgetCommitmentsUtilization } from "../../../../../../services/cloudchipr.api";
import { useGetWidgetsCommitmentsUtilizationByWidgetIdFromAccountServiceQuery } from "../../../../../../services/cloudchipr-account-service.api";

interface InternalDashboardCommitmentUtilizationWidgetWrapperProps
  extends DashboardWidgetCommitmentsUtilization {
  organisationId: string;
  onWidgetMount?(): void;
}

export const InternalDashboardCommitmentUtilizationWidgetWrapper: FC<
  InternalDashboardCommitmentUtilizationWidgetWrapperProps
> = ({ id, organisationId, ...props }) => {
  const { data, isLoading, isUninitialized, isError, error } =
    useGetWidgetsCommitmentsUtilizationByWidgetIdFromAccountServiceQuery(
      { widgetId: id, organisationId },
      { skip: !organisationId, refetchOnMountOrArgChange: true },
    );

  if (isLoading || isUninitialized) {
    return null;
  }

  return (
    <InternalDashboardCommitmentUtilizationWidget
      {...props}
      data={data}
      isError={isError}
      error={error}
      id={id}
    />
  );
};
