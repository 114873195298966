import { FC, Fragment, ReactNode } from "react";
import { Button, Stack, Theme } from "@mui/material";

interface ZoomWrapperProps {
  enabled: boolean;
  zoomed: boolean;
  onReset(): void;
  children?: ReactNode;
}

export const ZoomWrapper: FC<ZoomWrapperProps> = ({
  enabled,
  zoomed,
  onReset,
  children,
}) => {
  if (!enabled) {
    return <Fragment>{children}</Fragment>;
  }

  return (
    <Stack sx={{ userSelect: "none", position: "relative" }} alignItems="end">
      {zoomed && (
        <Button
          variant="outlined"
          size="xsmall"
          sx={{
            px: 0.5,
            top: 25,
            right: 10,
            bgcolor: "white",
            position: "absolute",
            "&:hover": { bgcolor: "white" },
            zIndex: (theme: Theme) => theme.zIndex.fab,
          }}
          onClick={onReset}
        >
          Reset Zoom
        </Button>
      )}

      {children}
    </Stack>
  );
};
