import { FC, useCallback, useMemo } from "react";
import { Stack, Tooltip, Typography } from "@mui/material";
import { FormikErrors } from "formik";
import { EmailsSelect } from "./EmailsSelect";
import { Emails } from "../../../../../../../services/cloudchipr.api";

export type EmailsSelectionErrors = FormikErrors<Emails>;

interface EmailsSelectionProps {
  toEmails: string[];
  ccEmails?: string[] | null;
  bccEmails?: string[] | null;
  errors?: EmailsSelectionErrors;
  onEmailsChange(emails: Emails): void;
}

export const EmailsSelection: FC<EmailsSelectionProps> = ({
  toEmails,
  bccEmails,
  ccEmails,
  errors,
  onEmailsChange,
}) => {
  const emailsChangeHandler = useCallback(
    (type: "cc" | "to" | "bcc") => (emails: string[]) => {
      onEmailsChange({
        to: toEmails,
        cc: ccEmails,
        bcc: bccEmails,
        [type]: emails,
      });
    },
    [onEmailsChange, toEmails, ccEmails, bccEmails],
  );

  const allFieldsSelectedEmails = useMemo(() => {
    return [...(toEmails ?? []), ...(ccEmails ?? []), ...(bccEmails ?? [])];
  }, [toEmails, ccEmails, bccEmails]);

  return (
    <Stack spacing={2} flexGrow={1}>
      <EmailsSelect
        label="To"
        error={errors?.to}
        selectedEmails={toEmails}
        onChange={emailsChangeHandler("to")}
        allFieldsSelectedEmails={allFieldsSelectedEmails}
      />

      {ccEmails && (
        <EmailsSelect
          label="Cc"
          error={errors?.cc}
          selectedEmails={ccEmails}
          onChange={emailsChangeHandler("cc")}
          allFieldsSelectedEmails={allFieldsSelectedEmails}
        />
      )}

      {bccEmails && (
        <EmailsSelect
          label="Bcc"
          error={errors?.bcc}
          selectedEmails={bccEmails}
          onChange={emailsChangeHandler("bcc")}
          allFieldsSelectedEmails={allFieldsSelectedEmails}
        />
      )}

      {(!ccEmails || !bccEmails) && (
        <Stack direction="row" mt={1} spacing={1.5}>
          {!ccEmails && (
            <Tooltip arrow title="Add Cc recipients" placement="right">
              <Typography
                color="primary"
                fontWeight="medium"
                sx={{ cursor: "pointer" }}
                onClick={emailsChangeHandler("cc").bind(null, [])}
              >
                Cc
              </Typography>
            </Tooltip>
          )}

          {!bccEmails && (
            <Tooltip arrow title="Add Bcc recipients" placement="right">
              <Typography
                color="primary"
                fontWeight="medium"
                sx={{ cursor: "pointer" }}
                onClick={emailsChangeHandler("bcc").bind(null, [])}
              >
                Bcc
              </Typography>
            </Tooltip>
          )}
        </Stack>
      )}
    </Stack>
  );
};
