import { FC, useRef } from "react";
import Stack from "@mui/material/Stack";
import { useDidMount } from "rooks";
import { AgGridReact } from "@ag-grid-community/react";
import { SavingsOpportunitiesDataGrid } from "./components/SavingsOpportunitiesDataGrid";
import { SavingsOpportunitiesTableToolbar } from "./components/toolbar/SavingsOpportunitiesTableToolbar";
import { SavingsOpportunitiesMetadata } from "./components/metadata/SavingsOpportunitiesMetadata";
import { PageHeader } from "../common/PageHeader";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { getSavingsOpportunitiesThunk } from "../../../store/savings-opportunities/thunks/getSavingsOpportunitiesThunk";
import { savingsOpportunitiesLoadingSelector } from "../../../store/savings-opportunities/selectors/savingsOpportunitiesLoadingSelector";
import { savingsOpportunitiesDataSelector } from "../../../store/savings-opportunities/selectors/savingsOpportunitiesDataSelector";

export const SavingsOpportunities: FC = () => {
  const dispatch = useAppDispatch();
  const gridRef = useRef<AgGridReact>(null);

  const loading = useAppSelector(savingsOpportunitiesLoadingSelector);
  const data = useAppSelector(savingsOpportunitiesDataSelector);

  useDidMount(() => {
    dispatch(getSavingsOpportunitiesThunk());
  });

  return (
    <Stack bgcolor="white" height="100%">
      <PageHeader
        wrapperSx={{ p: 2 }}
        loading={loading}
        breadcrumbs={breadcrumbs}
      />

      <SavingsOpportunitiesMetadata />

      <SavingsOpportunitiesTableToolbar agGrid={gridRef.current} />
      <SavingsOpportunitiesDataGrid data={data} refToPassGrid={gridRef} />
    </Stack>
  );
};

const breadcrumbs = [{ title: "Saving Opportunities" }];
