import { ColumnSetupType } from "../../../../../../../../../../../../../../storybook/data-grid/utils/types/types";
import { RdsSnapshotSource } from "../../../../../../../../../../../../../../services/cloudchipr.api";

export const rdsSnapshotSourceColumns: ColumnSetupType<RdsSnapshotSource>[] = [
  {
    accessorKey: "name",
    header: "DB Name",
    type: "identifier",
    meta: { sticky: "left", hideFromSettings: true },
  },
  {
    accessorKey: "price_per_month",
    header: "Monthly Price",
    type: "money",
  },
  {
    accessorKey: "account",
    header: "Account",
    type: "account",
    size: 210,
  },
  {
    accessorKey: "billable",
    header: "Billable",
    type: "billable",
  },
  {
    accessorKey: "db_type",
    header: "DB Type",
  },
  {
    accessorKey: "snapshots_count",
    header: "Snapshots per DB",
    minSize: 180,
  },
  {
    accessorKey: "engine",
    header: "DB Engine",
  },
  { accessorKey: "region", header: "Region", type: "withCopy" },
  {
    accessorKey: "exists",
    header: "DB Status",
    type: "exists",
  },
];
