import { createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../../../../store";
import { commitmentsCurrentOrgIdSelector } from "../../../selectors/common/commitmentsCurrentOrgIdSelector";

import { organizationAccountsByCurrentOrgIdSelector } from "../../../selectors/organisations/organizationAccountsByCurrentOrgIdSelector";
import { cloudChiprApi } from "../../../../../services/cloudchipr.api";
import { setCommitmentsRecommendationsFilters } from "../../../commitmentsSlice";

export const setReservationsRecommendationsInitialFiltersByPotentialSavingsThunk =
  createAsyncThunk(
    "commitments/setReservationsRecommendationsInitialFiltersByPotentialSavings",
    async (_: void, { dispatch, getState }) => {
      const state = getState() as RootState;

      const orgId = commitmentsCurrentOrgIdSelector(state);
      const accounts = organizationAccountsByCurrentOrgIdSelector(state);

      const { getUsersMeOrganisationsCurrentReservationsPotentialSavings } =
        cloudChiprApi.endpoints;

      const data = await dispatch(
        getUsersMeOrganisationsCurrentReservationsPotentialSavings.initiate({
          providerOrganisationId: orgId,
        }),
      ).unwrap();

      if (!data) {
        return;
      }

      const withAccounts =
        data.recommendation_parameters.account_scope === "linked";

      const accountIds = withAccounts ? accounts?.map(({ id }) => id) : [];

      dispatch(
        setCommitmentsRecommendationsFilters({
          type: data.recommendation_parameters.types ?? [],
          payment_option: [data.recommendation_parameters.payment_option],
          look_back_period: [data.recommendation_parameters.look_back_period],
          term_in_years: [data.recommendation_parameters.term_in_years],
          account_scope: data.recommendation_parameters.account_scope,
          offering_class: [data.recommendation_parameters.offering_class],
          account_ids: accountIds ?? [],
        }),
      );
    },
  );
