import { RootState } from "../../../store";
import { ResourceType } from "../../../../services/cloudchipr.api";
import { resourceGroups } from "../../../../utils/constants/resources/resourceGroups";
import { liveUsageMgmtProviderSelector } from "../store-selectors/liveUsageMgmtProviderSelector";

export const liveUsageMgmtResourceTypesHierarchySelector = (
  state: RootState,
  resourceType: ResourceType,
): ResourceType[] => {
  const provider = liveUsageMgmtProviderSelector(state);
  if (!provider || !resourceGroups.get(provider)) {
    return [];
  }
  const groups = resourceGroups.get(provider);
  return (
    groups?.find(
      (item) =>
        item.value === resourceType ||
        item.resourceTypes.includes(resourceType),
    )?.hierarchy?.[resourceType] ?? [resourceType]
  );
};
