import { offHoursRecommendationsDismissedResourcesDataByGroupSelector } from "./offHoursRecommendationsDismissedResourcesDataByGroupSelector";
import { RootState } from "../../../../store";

export const offHoursRecommendationsDismissedResourcesSelector = (
  state: RootState,
) => {
  const dbResources =
    offHoursRecommendationsDismissedResourcesDataByGroupSelector(
      state,
      "database",
    ) ?? [];
  const computeResources =
    offHoursRecommendationsDismissedResourcesDataByGroupSelector(
      state,
      "compute",
    ) ?? [];

  return [...dbResources, ...computeResources];
};
