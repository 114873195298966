import { emptySplitApiAccountService as api } from "./empty.account-service.api";
import {
  GetUsersMeOrganisationsCurrentDashboardsByDashboardIdApiArg,
  GetUsersMeOrganisationsCurrentDashboardsByDashboardIdApiResponse,
  GetUsersMeOrganisationsCurrentResourceExplorerFiltersByFilterProviderAndFilterTypeValuesApiArg,
  GetUsersMeOrganisationsCurrentResourceExplorerFiltersByFilterProviderAndFilterTypeValuesApiResponse,
  GetUsersMeOrganisationsCurrentResourceExplorerViewsByResourceExplorerViewIdApiArg,
  GetUsersMeOrganisationsCurrentResourceExplorerViewsByResourceExplorerViewIdApiResponse,
  GetUsersMeOrganisationsCurrentWidgetsCommitmentsUtilizationByWidgetIdApiArg,
  GetUsersMeOrganisationsCurrentWidgetsCommitmentsUtilizationByWidgetIdApiResponse,
  GetUsersMeOrganisationsCurrentWidgetsCostAndUsageSummaryByWidgetIdApiArg,
  GetUsersMeOrganisationsCurrentWidgetsCostAndUsageSummaryByWidgetIdApiResponse,
  GetUsersMeOrganisationsCurrentWidgetsCostBreakdownByWidgetIdApiArg,
  GetUsersMeOrganisationsCurrentWidgetsCostBreakdownByWidgetIdApiResponse,
  GetUsersMeOrganisationsCurrentWidgetsLargestCostChangesByWidgetIdApiArg,
  GetUsersMeOrganisationsCurrentWidgetsLargestCostChangesByWidgetIdApiResponse,
} from "./cloudchipr.api";

// This file is written by hand, not autogenerated
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    //Dashboard endpoints
    getDashboardsByDashboardIdFromAccountService: build.query<
      GetUsersMeOrganisationsCurrentDashboardsByDashboardIdApiResponse,
      GetUsersMeOrganisationsCurrentDashboardsByDashboardIdAccountServiceApiArg
    >({
      query: (queryArg) => ({
        url: `/organisations/${queryArg.organisationId}/dashboards/${queryArg.dashboardId}`,
      }),
    }),
    getWidgetsCostAndUsageSummaryByWidgetIdFromAccountService: build.query<
      GetUsersMeOrganisationsCurrentWidgetsCostAndUsageSummaryByWidgetIdApiResponse,
      GetWidgetsCostAndUsageSummaryByWidgetIdFromAccountServiceApiArgs
    >({
      query: (queryArg) => ({
        url: `/organisations/${queryArg.organisationId}/widgets/cost-and-usage-summary/${queryArg.widgetId}`,
      }),
    }),
    getWidgetsCostBreakdownByWidgetIdFromAccountService: build.query<
      GetUsersMeOrganisationsCurrentWidgetsCostBreakdownByWidgetIdApiResponse,
      GetWidgetsCostBreakdownByWidgetIdFromAccountServiceApiArgs
    >({
      query: (queryArg) => ({
        url: `/organisations/${queryArg.organisationId}/widgets/cost-breakdown/${queryArg.widgetId}`,
      }),
    }),
    getWidgetsLargestCostChangesByWidgetIdFromAccountService: build.query<
      GetUsersMeOrganisationsCurrentWidgetsLargestCostChangesByWidgetIdApiResponse,
      GetWidgetsLargestCostChangesByWidgetIdFromAccountServiceApiArgs
    >({
      query: (queryArg) => ({
        url: `/organisations/${queryArg.organisationId}/widgets/largest-cost-changes/${queryArg.widgetId}`,
      }),
    }),
    getWidgetsCommitmentsUtilizationByWidgetIdFromAccountService: build.query<
      GetUsersMeOrganisationsCurrentWidgetsCommitmentsUtilizationByWidgetIdApiResponse,
      GetWidgetsCommitmentsUtilizationByWidgetIdFromAccountServiceApiArgs
    >({
      query: (queryArg) => ({
        url: `/organisations/${queryArg.organisationId}/widgets/commitments-utilization/${queryArg.widgetId}`,
      }),
    }),

    //Resource Explorer endpoints
    getResourceExplorerViewsByResourceExplorerViewIdFromAccountService:
      build.query<
        GetUsersMeOrganisationsCurrentResourceExplorerViewsByResourceExplorerViewIdApiResponse,
        GetResourceExplorerViewsByResourceExplorerViewIdFromAccountServiceApiArgs
      >({
        query: (queryArg) => ({
          url: `/organisations/${queryArg.organisationId}/resource-explorer/views/${queryArg.resourceExplorerViewId}`,
        }),
      }),
    getResourceExplorerFiltersByFilterProviderAndFilterTypeValuesFromAccountService:
      build.query<
        GetUsersMeOrganisationsCurrentResourceExplorerFiltersByFilterProviderAndFilterTypeValuesApiResponse,
        GetResourceExplorerFiltersByFilterProviderAndFilterTypeValuesFromAccountServiceApiArgs
      >({
        query: (queryArg) => ({
          url: `/organisations/${queryArg.organisationId}/resource-explorer/filters/${queryArg.filterProvider}/${queryArg.filterType}/values`,
          params: { key: queryArg.key },
        }),
      }),
  }),
  overrideExisting: false,
});

export { injectedRtkApi as cloudChiprApiAccountService };

export interface GetUsersMeOrganisationsCurrentDashboardsByDashboardIdAccountServiceApiArg
  extends GetUsersMeOrganisationsCurrentDashboardsByDashboardIdApiArg {
  organisationId: string;
}

export interface GetWidgetsCostAndUsageSummaryByWidgetIdFromAccountServiceApiArgs
  extends GetUsersMeOrganisationsCurrentWidgetsCostAndUsageSummaryByWidgetIdApiArg {
  organisationId: string;
}

export interface GetWidgetsCostBreakdownByWidgetIdFromAccountServiceApiArgs
  extends GetUsersMeOrganisationsCurrentWidgetsCostBreakdownByWidgetIdApiArg {
  organisationId: string;
}

export interface GetWidgetsLargestCostChangesByWidgetIdFromAccountServiceApiArgs
  extends GetUsersMeOrganisationsCurrentWidgetsLargestCostChangesByWidgetIdApiArg {
  organisationId: string;
}

export interface GetWidgetsCommitmentsUtilizationByWidgetIdFromAccountServiceApiArgs
  extends GetUsersMeOrganisationsCurrentWidgetsCommitmentsUtilizationByWidgetIdApiArg {
  organisationId: string;
}

export interface GetResourceExplorerViewsByResourceExplorerViewIdFromAccountServiceApiArgs
  extends GetUsersMeOrganisationsCurrentResourceExplorerViewsByResourceExplorerViewIdApiArg {
  organisationId: string;
}

export interface GetResourceExplorerFiltersByFilterProviderAndFilterTypeValuesFromAccountServiceApiArgs
  extends GetUsersMeOrganisationsCurrentResourceExplorerFiltersByFilterProviderAndFilterTypeValuesApiArg {
  organisationId: string;
}

export const {
  //Dashboard
  useGetDashboardsByDashboardIdFromAccountServiceQuery,
  useGetWidgetsCommitmentsUtilizationByWidgetIdFromAccountServiceQuery,
  useGetWidgetsCostBreakdownByWidgetIdFromAccountServiceQuery,
  useGetWidgetsCostAndUsageSummaryByWidgetIdFromAccountServiceQuery,
  useGetWidgetsLargestCostChangesByWidgetIdFromAccountServiceQuery,

  //Resource Explorer
  useGetResourceExplorerViewsByResourceExplorerViewIdFromAccountServiceQuery,
  useGetResourceExplorerFiltersByFilterProviderAndFilterTypeValuesFromAccountServiceQuery,
} = injectedRtkApi;
