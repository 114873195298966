import { FC } from "react";
import { Typography } from "@mui/material";
import { money } from "../../../../../../../../../../../utils/numeral/money";
import { useAppSelector } from "../../../../../../../../../../../store/hooks";
import { resourceExplorerDataGridDataTotalCostDateDetailsSelector } from "../../../../../../../../../../../store/resource-explorer/selectors/current-resource-explorer/data-grid/resourceExplorerDataGridDataTotalCostDateDetailsSelector";
import { formatDate } from "../../../../../../../../../../../utils/helpers/date-time/datetime-format";
import { TooltipText } from "../../../../../../../../../../common/TooltipText";

interface PreviousPeriodCostCellProps {
  value: number | null;
}

export const PreviousPeriodCostCell: FC<PreviousPeriodCostCellProps> = ({
  value,
}) => {
  const dateDetails = useAppSelector(
    resourceExplorerDataGridDataTotalCostDateDetailsSelector,
  );
  const fromDate = formatDate(dateDetails?.from_date ?? "", { type: "date" });
  const toDate = formatDate(dateDetails?.to_date ?? "", { type: "date" });

  return (
    <Typography variant="body2" align="right" pr={4}>
      {value === null ? (
        <TooltipText
          tooltipTitle={`No data for Previous Period - ${fromDate} - ${toDate}`}
        >
          N/A
        </TooltipText>
      ) : (
        money(value)
      )}
    </Typography>
  );
};
