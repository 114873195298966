import { RootState } from "../../../store";
import {
  cloudChiprApi,
  RightSizingRecommendationDetails,
} from "../../../../services/cloudchipr.api";

export const rightsizingRecommendationDataSelector = (
  state: RootState,
  recommendationId: string | null,
  accountId: string | null,
): RightSizingRecommendationDetails | undefined => {
  const {
    getUsersMeAccountsByAccountIdRightSizingRecommendationsAndRecommendationId,
  } = cloudChiprApi.endpoints;

  if (!recommendationId || !accountId) {
    return;
  }

  return getUsersMeAccountsByAccountIdRightSizingRecommendationsAndRecommendationId.select(
    { recommendationId, accountId },
  )(state).data;
};
