import { FC, useCallback, useMemo } from "react";
import { Box, Card } from "@mui/material";
import { DataGrid } from "../../../../../../storybook/data-grid/DataGrid";
import { Account } from "../../../../../../services/cloudchipr.api";
import { ColumnSetupType } from "../../../../../../storybook/data-grid/utils/types/types";
import { MultiTypeChart } from "../../../../../../storybook/charts/multi-type-chart/MultiTypeChart";
import { moneyFormatter } from "../../../../../../utils/numeral/moneyFormatter";
import { getAccountsBarViewTableColumns } from "../accounts-list/accountsBarViewTableColumns";
import { accountsBarChartDataGenerator } from "../../../utils/helpers/accountsBarChartDataGenerator";
import { MultiTypeChartProvider } from "../../../../../../storybook/charts/multi-type-chart/MultiTypeChartProvider";

interface AccountsBarViewProps {
  columns: ColumnSetupType<Account>[];
  accounts: Account[] | null;
}

export const AccountsBarView: FC<AccountsBarViewProps> = ({
  accounts,
  columns,
}) => {
  const listColumns = useMemo(() => {
    return getAccountsBarViewTableColumns(columns);
  }, [columns]);

  const chartData = useMemo(() => {
    return accountsBarChartDataGenerator(accounts);
  }, [accounts]);

  const yAxisFormatter = useCallback((value: number | string) => {
    return moneyFormatter(+value);
  }, []);

  if (!accounts) {
    return null;
  }

  return (
    <Card variant="outlined" sx={{ px: 3, pb: 3 }}>
      <Box mt={3} mb={4}>
        <MultiTypeChartProvider
          value={{
            initialChartType: "bar",
            data: chartData,
            colors,
          }}
        >
          <MultiTypeChart
            highlight
            selectable
            yAxisLabel="Costs ($)"
            availableTypes={["bar"]}
            xAxisTickProps={getXAxisTickProps(chartData.length)}
            yAxisFormatter={yAxisFormatter}
          />
        </MultiTypeChartProvider>
      </Box>

      <Box
        border="1px solid"
        borderColor="grey.300"
        borderRadius={2}
        position="relative"
      >
        <DataGrid styles={styles} data={accounts} columns={listColumns} />
      </Box>
    </Card>
  );
};

const styles = {
  tableHeaderRow: { "& th": { py: 2 }, "& th:first-of-type": { pl: 2 } },
  tableContainer: {
    "& td:first-of-type": { pl: 2 },
  },
};

const colors = {
  "Tracked Total": "#4CAF50",
  "Filtered Resources": "#F44336",
};

const getXAxisTickProps = (dataLength: number) => {
  if (dataLength > 5) {
    if (dataLength < 11) {
      return {
        textProps: {
          x: 50,
          y: 15,
          transform: "rotate(-10)",
          textAnchor: "end",
        },
        parentHeight: 45,
        labelFormatter,
      };
    }

    return {
      textProps: {
        x: 5,
        y: 0,
        transform: "rotate(-45)",
        textAnchor: "end",
      },
      parentPadding: { left: 16, right: 16 },
      parentHeight: 125,
      labelFormatter,
    };
  }
};

const labelFormatter = (label: string) => {
  return label.length > 28 ? `${label.substring(0, 27)}…` : label;
};
