import { protectorResourcesDataSelector } from "./protectorResourcesDataSelector";
import { RootState } from "../../../store";
import { ResourceType } from "../../../../services/cloudchipr.api";

export const protectorResourceTypesSelector = (
  state: RootState,
  scheduleId?: string,
  batchId?: string,
): ResourceType[] | undefined => {
  const protectorResources = protectorResourcesDataSelector(
    state,
    scheduleId,
    batchId,
  );

  return protectorResources?.map((resource) => resource.resource_type);
};
