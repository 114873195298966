import { createAsyncThunk } from "@reduxjs/toolkit";
import { enqueueSnackbar } from "notistack";
import { cloudChiprApi } from "../../../../services/cloudchipr.api";

export const getDimensionsThunk = createAsyncThunk(
  "dimensions/getDimensions",
  async (forceRefetch: boolean | void, { dispatch }) => {
    const { getUsersMeOrganisationsCurrentDimensions } =
      cloudChiprApi.endpoints;

    try {
      return await dispatch(
        getUsersMeOrganisationsCurrentDimensions.initiate(undefined, {
          forceRefetch: !!forceRefetch,
        }),
      ).unwrap();
    } catch (e) {
      // @ts-expect-error todo: fix api spec
      const errMessage = e?.data?.message || "Something went wrong";
      enqueueSnackbar(errMessage, {
        variant: "snackbarAlert",
        AlertSnackBarProps: {
          severity: "error",
        },
      });
    }
  },
);
