import { FC } from "react";
import { Grid } from "@mui/material";
import { useContextCalendars } from "@rehookify/datepicker";
import { DateRangeCalendarDay } from "./DateRangeCalendarDay";
import { DateRangeCalendarWeekDays } from "../DateRangeCalendarWeekDays";

interface DateRangeCalendarDaySelectionProps {
  highlightToday?: boolean;
}

export const DateRangeCalendarDaySelection: FC<
  DateRangeCalendarDaySelectionProps
> = ({ highlightToday }) => {
  const { calendars } = useContextCalendars();
  const { days } = calendars[0];

  return (
    <Grid container direction="row" flexWrap="wrap">
      <DateRangeCalendarWeekDays />

      {days.map((dpDay) => {
        return (
          <DateRangeCalendarDay
            dpDay={dpDay}
            highlightToday={highlightToday}
            key={dpDay.$date.toString()}
          />
        );
      })}
    </Grid>
  );
};
