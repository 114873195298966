import { FC } from "react";
import { Stack, Typography } from "@mui/material";
import { IntegrationUtilizationAlertCoverageEmailHeader } from "./IntegrationUtilizationAlertCoverageEmailHeader";
import { IntegrationUtilizationAlertCoverageSlackHeader } from "./IntegrationUtilizationAlertCoverageSlackHeader";
import { IntegrationUtilizationAlertCoverageDetails } from "./IntegrationUtilizationAlertCoverageDetails";

interface IntegrationUtilizationAlertCoverageMessageProps {
  message: string;
  type: "email" | "slack";
}

export const IntegrationUtilizationAlertCoverageMessage: FC<
  IntegrationUtilizationAlertCoverageMessageProps
> = ({ message, type }) => {
  return (
    <Stack spacing={1}>
      {type === "email" && <IntegrationUtilizationAlertCoverageEmailHeader />}

      {type === "slack" && <IntegrationUtilizationAlertCoverageSlackHeader />}

      <IntegrationUtilizationAlertCoverageDetails />

      <Typography
        variant="body1"
        dangerouslySetInnerHTML={{ __html: message }}
      />
    </Stack>
  );
};
