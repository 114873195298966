import { cloudChiprApi } from "../../../../services/cloudchipr.api";
import { RootState } from "../../../store";
import { deleteResourceExplorerViewThunkFixedCacheKey } from "../../thunks/common/deleteResourceExplorerViewThunk";

const selector =
  cloudChiprApi.endpoints
    .deleteV2UsersMeOrganisationsCurrentResourceExplorerViewsByResourceExplorerViewId
    .select;

export const deleteResourceExplorerHierarchyItemLoadingSelector = (
  state: RootState,
): boolean => {
  return !!selector(deleteResourceExplorerViewThunkFixedCacheKey)(state)
    ?.isLoading;
};
