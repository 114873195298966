import { FC, useCallback } from "react";
import { capitalize, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { WeekDaysType } from "../../../../../../../store/reports/utils/types/types";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../store/hooks";
import { reportsScheduleWeekDaySelector } from "../../../../../../../store/reports/selector/reportsScheduleWeekDaySelector";
import { setReportsScheduleDataThunk } from "../../../../../../../store/reports/thunks/setReportsScheduleDataThunk";

export const ReportsDrawerWeekDaySelect: FC = () => {
  const dispatch = useAppDispatch();
  const weekDay = useAppSelector(reportsScheduleWeekDaySelector);

  const handleChange = useCallback(
    (e: SelectChangeEvent) => {
      const day = e.target.value as WeekDaysType;

      dispatch(
        setReportsScheduleDataThunk({
          week_day: day,
        }),
      );
    },
    [dispatch],
  );

  return (
    <Select
      value={weekDay}
      size="small"
      onChange={handleChange}
      MenuProps={menuProps}
    >
      {options.map((day) => (
        <MenuItem value={day} key={day}>
          {capitalize(day)}
        </MenuItem>
      ))}
    </Select>
  );
};

const menuProps = {
  sx: {
    maxHeight: 400,
  },
};

const options: WeekDaysType[] = [
  "sunday",
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
];
