import { RootState } from "../../../../store";
import { resourceExplorerViewDataByIdSelector } from "../../resource-explorer-by-id/resourceExplorerViewDataByIdSelector";
import { resourceExplorerDefaultData } from "../../../../../components/pages/resource-explorer/components/resource-explorer-card/utils/constants/common";
import { resourceExplorerDataSelector } from "../data/resourceExplorerDataSelector";
import { arrayIsEqual } from "../../../../../utils/arrayIsEqual";

export const resourceExplorerDataChangedSelector = (
  state: RootState,
  resourceExplorerId: string,
) => {
  const resourceExplorerData = resourceExplorerDataSelector(state);

  const initialResourceExplorer = resourceExplorerViewDataByIdSelector(
    state,
    resourceExplorerId,
  );

  const resourceExplorerInitialData = initialResourceExplorer?.is_default
    ? resourceExplorerDefaultData
    : initialResourceExplorer?.data;

  if (!resourceExplorerInitialData || !resourceExplorerData) {
    return false;
  }

  const groupingChanged =
    resourceExplorerInitialData.grouping !== resourceExplorerData.grouping;
  const groupValuesChanged = !arrayIsEqual(
    resourceExplorerInitialData.group_values ?? [],
    resourceExplorerData.group_values ?? [],
  );

  const forecastOptionChanged =
    resourceExplorerInitialData.forecast_option !==
    resourceExplorerData.forecast_option;

  const dataPointChanged =
    resourceExplorerInitialData.data_point !== resourceExplorerData.data_point;

  const budgetLineChanged =
    resourceExplorerInitialData.show_budget_line !==
    resourceExplorerData.show_budget_line;

  const trendTypeChanged =
    resourceExplorerInitialData.trend_type !== resourceExplorerData.trend_type;

  const costTypeChanged =
    resourceExplorerInitialData.cost_type !== resourceExplorerData.cost_type;

  return (
    groupingChanged ||
    groupValuesChanged ||
    forecastOptionChanged ||
    dataPointChanged ||
    budgetLineChanged ||
    trendTypeChanged ||
    costTypeChanged
  );
};
