import { UniqueIdentifier } from "@dnd-kit/core";
import type { FlattenedItem, TreeItems } from "../types";

export const flattenSortableTreeItems = (items: TreeItems): FlattenedItem[] => {
  return flatten(items);
};

export const flatten = (
  items?: TreeItems,
  parentId: UniqueIdentifier | null = null,
  depth = 0,
): FlattenedItem[] => {
  if (!items) {
    return [];
  }
  return items.reduce<FlattenedItem[]>((acc, item, index) => {
    return [
      ...acc,
      { ...item, parentId, depth, index },
      ...flatten(item.items ?? [], item.id, depth + 1),
    ];
  }, []);
};
