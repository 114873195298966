import { resourcesNotIncludedInScheduleSelect } from "../constants/resourcesNotIncludedInScheduleSelect";
import { ResourceType } from "../../../../../../services/cloudchipr.api";
import { HookResourcesVisibilityReturnedDataType } from "../hooks/useAllResourcesVisibility.hook";
import { offHoursAvailableResourceTypes } from "../constants/common";
import { AutomationType } from "../../../../../../store/automations/utils/types/common";

export const isScheduleResourceTypeVisible = ({
  resourceType,
  resourcesVisibility,
  toggledResourceTypes,
  scheduleType,
}: {
  resourceType: ResourceType;
  resourcesVisibility: HookResourcesVisibilityReturnedDataType;
  toggledResourceTypes: Map<ResourceType, boolean>;
  scheduleType: AutomationType;
}) => {
  const isVisible =
    Object.values(resourcesVisibility).every((visibility) => {
      return visibility?.[resourceType]?.show;
    }) &&
    !toggledResourceTypes.get(resourceType) &&
    !resourcesNotIncludedInScheduleSelect.has(resourceType);

  return scheduleType === "offHours"
    ? isVisible && offHoursAvailableResourceTypes.includes(resourceType)
    : isVisible;
};
