import { FC, useCallback, useMemo } from "react";
import { executionLogsSubGridColumns } from "./executionLogsSubGridColumns";
import { ExecutionLogV3 } from "../../../../../../services/cloudchipr.api";
import { DataGrid } from "../../../../../../storybook/data-grid/DataGrid";
import { ExecutionLogsDataGridResourcesType } from "../../../utils/types/types";

interface ExecutionLogsSubRowTableProps {
  response: ExecutionLogV3["response"];
}

export const ExecutionLogsSubRowTable: FC<ExecutionLogsSubRowTableProps> = ({
  response,
}) => {
  const data: ExecutionLogsDataGridResourcesType[] = useMemo(() => {
    return response
      ?.map(({ type, resources }, groupIndex) => {
        return resources.map((resource, index) => {
          return {
            ...resource,
            groupIndex,
            resourceType: type,
            firstInGroup: index === 0,
          };
        });
      })
      ?.flat();
  }, [response]);

  const generateRowProps = useCallback((row: any) => {
    const groupIndex = row.original.groupIndex;

    return {
      sx: { bgcolor: groupIndex % 2 === 0 ? "white" : "grey.50" },
    };
  }, []);

  if (!data?.length) {
    return null;
  }

  return (
    <DataGrid
      data={data}
      styles={styles}
      enableRowsVirtualization={data.length > 30}
      columnSorting={!!data.length}
      getRowProps={generateRowProps}
      columns={executionLogsSubGridColumns}
    />
  );
};

const styles = {
  table: {
    tableLayout: "fixed",
    "& td": {
      border: "none",
      alignContent: "flex-start",
    },
  },
  tableHeaderRow: {
    bgcolor: "grey.50",
    "& th": { py: 2, border: "none" },
  },
  tableContainer: {
    border: "1px solid",
    borderColor: "grey.300",
    borderRadius: 2,
    maxHeight: 500,
  },
};
