import { FC, ReactNode } from "react";
import { Grid, Stack, SvgIconTypeMap, Typography } from "@mui/material";
import { OverridableComponent } from "@mui/material/OverridableComponent";

interface TaskCreationPropertyRowProps {
  label: string;
  LabelIcon: OverridableComponent<SvgIconTypeMap> & {
    muiName: string;
  };
  children: ReactNode;
}

export const TaskCreationPropertyRow: FC<TaskCreationPropertyRowProps> = ({
  label,
  LabelIcon,
  children,
}) => {
  return (
    <Grid item xs={6}>
      <Stack
        minHeight={30}
        direction="row"
        height="100%"
        alignItems="center"
        justifyContent="center"
      >
        <Stack flex={1} direction="row" spacing={1} alignItems="center">
          <LabelIcon fontSize="small" color="action" />

          <Typography variant="body2">{label}</Typography>
        </Stack>

        <Stack flex={1}>{children}</Stack>
      </Stack>
    </Grid>
  );
};
