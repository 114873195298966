import { SvgIcon, SvgIconProps, useTheme } from "@mui/material";

const EcsSvgIcon = ({ primary }: SvgIconProps & { primary?: boolean }) => {
  const { palette } = useTheme();

  const color = primary ? palette.primary.main : palette.action.active;

  return (
    <SvgIcon>
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_46919_30)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M16 12.1247L14 10.9463V7.91253C14 7.81773 13.944 7.73128 13.8565 7.69051L11 6.36816V4.14246L16 6.59117V12.1247ZM16.361 6.21932L10.861 3.52599C10.7835 3.48767 10.6915 3.4921 10.6185 3.5368C10.545 3.5815 10.5 3.66058 10.5 3.74556V6.52387C10.5 6.61868 10.5555 6.70562 10.6435 6.7459L13.5 8.06825V11.0853C13.5 11.1717 13.5455 11.2513 13.6215 11.2955L16.1215 12.7692C16.161 12.7928 16.2055 12.8045 16.25 12.8045C16.2925 12.8045 16.335 12.7937 16.373 12.7726C16.4515 12.7289 16.5 12.6474 16.5 12.5589V6.43889C16.5 6.34605 16.446 6.26058 16.361 6.21932ZM9.9975 16.4759L4.5 13.6583V6.58429L9 4.16015V6.38093L6.627 7.69886C6.548 7.74257 6.5 7.82412 6.5 7.91253V12.3335C6.5 12.4229 6.5495 12.5054 6.629 12.5486L9.879 14.3062C9.9545 14.347 10.0455 14.347 10.12 14.3067L13.245 12.6208L15.018 13.786L9.9975 16.4759ZM13.397 12.1296C13.319 12.0785 13.2195 12.0746 13.1375 12.1183L10 13.811L7 12.1886V8.05597L9.373 6.73755C9.4515 6.69432 9.5 6.61229 9.5 6.52387V3.74556C9.5 3.65862 9.4535 3.57855 9.3775 3.53434C9.3015 3.49013 9.2075 3.48866 9.1295 3.52992L4.1295 6.22374C4.0495 6.26697 4 6.34949 4 6.43889V13.8071C4 13.899 4.0515 13.9825 4.134 14.0252L9.884 16.9725C9.92 16.9907 9.9605 17 10 17C10.041 17 10.0825 16.9902 10.1195 16.97L15.6195 14.0228C15.696 13.9815 15.7455 13.9044 15.7495 13.8184C15.7535 13.7324 15.712 13.6509 15.639 13.6033L13.397 12.1296Z"
            fill={color}
          />
        </g>
        <defs>
          <clipPath id="clip0_46919_30">
            <rect width="20" height="20" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </SvgIcon>
  );
};

EcsSvgIcon.muiName = "EcsSvgIcon";

export default EcsSvgIcon;
