import { FC, useMemo } from "react";
import { AutomationAccountsPreview } from "./AutomationAccountsPreview";
import { AutomationRegionsPreview } from "./AutomationRegionsPreview";
import { AutomationPreviewTable } from "../form/helper-components/AutomationPreviewTable";
import { useAppSelector } from "../../../../../../store/hooks";
import { automationProviderSelector } from "../../../../../../store/automations/selectros/common/fields/automationProviderSelector";
import { AutomationSectionCard } from "../form/helper-components/AutomationSectionCard";
import { automationAccountIdsSelector } from "../../../../../../store/automations/selectros/common/fields/automationAccountIdsSelector";
import { automationRegionsSelector } from "../../../../../../store/automations/selectros/common/fields/automationRegionsSelector";
import { getProviderName } from "../../../../../../utils/helpers/providers/getProviderName";

export const AutomationEnvironmentPreview: FC = () => {
  const provider = useAppSelector(automationProviderSelector);
  const accountIds = useAppSelector(automationAccountIdsSelector);
  const regions = useAppSelector(automationRegionsSelector);

  const rows = useMemo(() => {
    if (!provider) {
      return [];
    }

    return [
      {
        title: "Cloud Provider",
        label: getProviderName(provider, { title: true }),
      },
      {
        title: "Cloud Account/s",
        label: (
          <AutomationAccountsPreview
            accountIds={accountIds}
            provider={provider}
          />
        ),
      },
      {
        title: "Regions",
        label: (
          <AutomationRegionsPreview regions={regions} provider={provider} />
        ),
      },
    ];
  }, [provider, accountIds, regions]);

  return (
    <AutomationSectionCard
      preview
      icon="environment"
      title="Select Environment"
    >
      <AutomationPreviewTable rows={rows} />
    </AutomationSectionCard>
  );
};
