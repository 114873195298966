import { FC, useCallback } from "react";
import { Button, Stack } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../../../../store/hooks";
import { setPolling } from "../../../../../../store/account-setup/accountSetupSlice";
import { awsConsoleLink } from "../../../../../../store/account-setup/selectors/awsConsoleLink";
import { pollingSelector } from "../../../../../../store/account-setup/selectors/pollingSelector";

export const AWSConsoleLink: FC = () => {
  const dispatch = useAppDispatch();
  const consoleLink = useAppSelector(awsConsoleLink);
  const polling = useAppSelector(pollingSelector);

  const handleClick = useCallback(() => {
    dispatch(setPolling(true));
    window.open(consoleLink, "_blank");
  }, [dispatch, consoleLink]);

  return (
    <Stack alignItems="center" justifyContent="center">
      <Button
        variant="outlined"
        size="large"
        sx={{ mt: 3 }}
        onClick={handleClick}
        disabled={polling || !consoleLink}
      >
        Go to AWS Console
      </Button>
    </Stack>
  );
};
