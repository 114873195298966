import { RootState } from "../../../../store";
import { GetUsersMeOrganisationsCurrentSavingsPlansApiArg } from "../../../../../services/cloudchipr.api";
import { commitmentsCurrentOrgIdSelector } from "../commitmentsCurrentOrgIdSelector";
import { commitmentsDatesSelector } from "../view-options/commitmentsDatesSelector";
import { commitmentsDateGranularitySelector } from "../view-options/commitmentsDateGranularitySelector";
import { commitmentsCoverageGroupingSelector } from "../view-options/commitmentsCoverageGroupingSelector";
import { covertDatesToQueryParam } from "../../../../../components/common/date-range-picker/utils/helpers/covertDatesToQueryParam";

export const commitmentsPayloadDataSelector = (
  state: RootState,
): GetUsersMeOrganisationsCurrentSavingsPlansApiArg => {
  const dates = commitmentsDatesSelector(state);
  const orgId = commitmentsCurrentOrgIdSelector(state);
  const dataPoint = commitmentsDateGranularitySelector(state);
  const coverageGrouping = commitmentsCoverageGroupingSelector(state);

  return {
    dataGranularity: dataPoint,
    providerOrganisationId: orgId || undefined,
    coverageGrouping: coverageGrouping,

    ...covertDatesToQueryParam(dates),
  };
};
