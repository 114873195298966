import { FC, useCallback, useMemo } from "react";
import { ListItemIcon, ListItemText, MenuItem } from "@mui/material";
import { AutomationResourceSelectCollapsableItem } from "./AutomationResourceSelectCollapsableItem";
import { ResourceGroupType } from "../../../../../../../../utils/constants/resources/resourceGroups";
import { getResourceTypeName } from "../../../../../../../../utils/helpers/resources/getResourceTypeName";
import { useAppSelector } from "../../../../../../../../store/hooks";
import { automationProviderSelector } from "../../../../../../../../store/automations/selectros/common/fields/automationProviderSelector";
import { automationAccountIdsSelector } from "../../../../../../../../store/automations/selectros/common/fields/automationAccountIdsSelector";
import { automationResourcesSelector } from "../../../../../../../../store/automations/selectros/common/fields/automationResourcesSelector";
import { ResourceType } from "../../../../../../../../services/cloudchipr.api";
import { useResourceVisibilityHook } from "../../../../../../schedule/common/utils/hooks/useResourceVisibility.hook";
import { automationTypeSelector } from "../../../../../../../../store/automations/selectros/common/automationTypeSelector";
import { IconByResourceType } from "../../../../../../live-usage-mgmt/components/navigation/IconByResourceTypeV2";

interface AutomationResourceSelectItemProps {
  selectedValue?: ResourceType;
  resourceGroup: ResourceGroupType;
  onChange(rt: ResourceType): void;
  recalculateMenuPosition(): void;
}
export const AutomationResourceSelectItem: FC<
  AutomationResourceSelectItemProps
> = ({ resourceGroup, selectedValue, recalculateMenuPosition, onChange }) => {
  const provider = useAppSelector(automationProviderSelector);
  const accountIds = useAppSelector(automationAccountIdsSelector);
  const resources = useAppSelector(automationResourcesSelector);
  const automationType = useAppSelector(automationTypeSelector);

  const resourceTypeVisible = useResourceVisibilityHook({
    provider,
    visibleFor: automationType,
    accountIds: accountIds.join(", "),
  });

  const visibleResourceTypes = useMemo(() => {
    return resourceGroup.actionableResourceTypes.filter(resourceTypeVisible);
  }, [resourceTypeVisible, resourceGroup]);

  const firstActionableResourceType = visibleResourceTypes.at(0);

  const resourceTypeDisabled = useCallback(
    (resourceType: ResourceType) => {
      return resources.some(({ filter }) => filter.type === resourceType);
    },
    [resources],
  );

  const singleItemChangeHandler = useCallback(() => {
    firstActionableResourceType && onChange(firstActionableResourceType);
  }, [firstActionableResourceType, onChange]);

  if (!firstActionableResourceType) {
    return null;
  }

  if (visibleResourceTypes.length > 1) {
    const allNestedResourcesSelected = visibleResourceTypes.every((rt) =>
      resources.some(({ filter }) => filter.type === rt),
    );

    return (
      <AutomationResourceSelectCollapsableItem
        resourcesGroup={resourceGroup}
        onChange={onChange}
        selectedValue={selectedValue}
        isResourceTypeDisabled={resourceTypeDisabled}
        recalculateMenuPosition={recalculateMenuPosition}
        allNestedResourcesSelected={allNestedResourcesSelected}
      />
    );
  }

  const selected = selectedValue === firstActionableResourceType;
  const disabled =
    resourceTypeDisabled(firstActionableResourceType) && !selected;

  return (
    <MenuItem
      disabled={disabled}
      key={firstActionableResourceType}
      onClick={singleItemChangeHandler}
      className={selected ? "Mui-selected" : ""}
    >
      <ListItemIcon sx={{ filter: selected ? "none" : "grayscale(1)" }}>
        <IconByResourceType resourceType={resourceGroup.value} />
      </ListItemIcon>

      <ListItemText>
        {getResourceTypeName(firstActionableResourceType, {
          type: "long",
        })}
      </ListItemText>
    </MenuItem>
  );
};
