import { FC, useMemo } from "react";
import { Box, Stack, Tooltip, Typography } from "@mui/material";
import PersonAddAltOutlinedIcon from "@mui/icons-material/PersonAddAltOutlined";
import { FilterTriggerComponentProps } from "../../../../../../../common/select/dropdown-select/utils/types/filterTriggerComponentProps";
import { User } from "../../../../../../../../services/cloudchipr.api";
import { ChartsColors } from "../../../../../../../../storybook/charts/multi-type-chart/utils/constants/colors";

export const AssigneeSelectionTrigger: FC<FilterTriggerComponentProps> = ({
  onClick,
  selectedValues,
  options,
}) => {
  const selectedUsersByIndex = useMemo(() => {
    return (
      options?.reduce(
        (acc, option, index) => {
          if (selectedValues?.includes(option.value)) {
            acc[index] = option.rawValue;
          }

          return acc;
        },
        {} as Record<string, User>,
      ) ?? {}
    );
  }, [options, selectedValues]);

  const usersArray = Object.keys(selectedUsersByIndex);
  const length = usersArray.length;
  const visibleUsersCount = 3;

  return (
    <Stack
      onClick={onClick}
      alignItems="start"
      justifyContent="center"
      minWidth={100}
    >
      {length ? (
        <Box position="relative" height={30}>
          {usersArray.slice(0, visibleUsersCount).map((userIndex, i) => {
            const user = selectedUsersByIndex[userIndex];

            return (
              <Tooltip
                key={user.id}
                title={user.name || user.email}
                arrow
                placement="top"
              >
                <Stack
                  bgcolor={ChartsColors[+userIndex ?? 0]}
                  sx={{
                    ...avatarStyles,
                    zIndex: visibleUsersCount - i,
                    left: i * 20,
                  }}
                >
                  {(user.name || user.email || "").substring(0, 1)}
                </Stack>
              </Tooltip>
            );
          })}

          {length > visibleUsersCount && (
            <Stack
              bgcolor="grey.400"
              sx={{ ...avatarStyles, left: visibleUsersCount * 20 }}
            >
              <Typography variant="caption">
                +{length - visibleUsersCount}
              </Typography>
            </Stack>
          )}
        </Box>
      ) : (
        <Box
          p={0.5}
          lineHeight={0}
          borderRadius="50%"
          border="1px dashed"
          borderColor="grey.400"
          sx={{ cursor: "pointer" }}
        >
          <PersonAddAltOutlinedIcon fontSize="small" color="action" />
        </Box>
      )}
    </Stack>
  );
};

export const propertySelectionStyles = {
  color: "white",
  width: 28,
  height: 28,
  minWidth: 28,
  minHeight: 28,
  borderRadius: "50%",
  alignItems: "center",
  justifyContent: "center",
};

const avatarStyles = {
  top: 0,
  border: 2,
  cursor: "pointer",
  position: "absolute",
  borderColor: "white",
  ...propertySelectionStyles,
};
