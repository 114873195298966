import { FC } from "react";
import { Stack, Typography } from "@mui/material";
import { NotificationPreviewItem } from "./NotificationPreviewItem";
import { IntegrationLogo } from "../../../../../../../../../common/integration-dialogs/components/common/IntegrationLogo";
import { useAppSelector } from "../../../../../../../../../../store/hooks";
import { integrationsSelector } from "../../../../../../../../../../store/integrations/selectors/integrationsSelector";

interface IntegrationsPreviewItemProps {
  id: string;
  metadata?: string[] | null;
}

export const IntegrationsPreviewItem: FC<IntegrationsPreviewItemProps> = ({
  metadata,
  id,
}) => {
  const { data: integrations } = useAppSelector(integrationsSelector);

  const integration = integrations?.find((int) => int.id === id);

  if (!integration) {
    return null;
  }

  const firstMetadata = metadata?.at(0);
  const prefix = integration.type === "slack" ? "#" : "";
  const otherMetadata = metadata?.slice(1)?.map((str) => `${prefix}${str}`);

  return (
    <Stack gap={1}>
      <Stack direction="row" spacing={1} alignItems="center">
        <IntegrationLogo type={integration.type} width={17} />
        <Typography variant="body2">{integration.name}</Typography>
      </Stack>

      {firstMetadata && (
        <NotificationPreviewItem
          firstItem={`${prefix}${firstMetadata}`}
          restItems={otherMetadata}
        />
      )}
    </Stack>
  );
};
