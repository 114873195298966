import { FC, useMemo } from "react";
import { AutomationActionNotifications } from "../../../common/components/preview/notifications/AutomationActionNotifications";
import { AutomationPreviewTable } from "../../../common/components/form/helper-components/AutomationPreviewTable";
import { AutomationSectionCard } from "../../../common/components/form/helper-components/AutomationSectionCard";
import { useAppSelector } from "../../../../../../store/hooks";
import { automationNotificationsSelector } from "../../../../../../store/automations/selectros/common/fields/automationNotificationsSelector";
import { automationEmailsSelector } from "../../../../../../store/automations/selectros/common/fields/automationEmailsSelector";
import { automationThresholdSelector } from "../../../../../../store/automations/selectros/common/fields/automationThresholdSelector";
import { offHoursActionSelector } from "../../../../../../store/automations/selectros/off-hours/offHoursActionSelector";

export const OffHoursNotificationsPreview: FC = () => {
  const emails = useAppSelector(automationEmailsSelector);
  const notifications = useAppSelector(automationNotificationsSelector);
  const threshold = useAppSelector(automationThresholdSelector);
  const action = useAppSelector(offHoursActionSelector);

  const rows = useMemo(() => {
    return [
      {
        title: "Notify on Action",
        label: (
          <AutomationActionNotifications
            emails={emails}
            minThreshold={threshold}
            notifications={notifications}
          />
        ),
      },
    ];
  }, [emails, threshold, notifications]);

  if (action === "silent") {
    return null;
  }

  return (
    <AutomationSectionCard title="Set Notification" icon="notification" preview>
      <AutomationPreviewTable rows={rows} />
    </AutomationSectionCard>
  );
};
