import { FC } from "react";
import { Stack } from "@mui/material";

import { UsersRequestTable } from "./components/UsersRequestTable";
import { UsersRequestTableHeader } from "./components/UsersRequestTableHeader";
import { useGetUsersMeOrganisationsCurrentInvitationRequestsQuery } from "../../../../../../../services/cloudchipr.api";

export const UsersRequestList: FC = () => {
  const { data } = useGetUsersMeOrganisationsCurrentInvitationRequestsQuery();

  if (!data?.length) {
    return null;
  }

  return (
    <Stack spacing={0.5} pb={2}>
      <UsersRequestTableHeader dataCount={data?.length} />
      <UsersRequestTable data={data} />
    </Stack>
  );
};
