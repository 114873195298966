import { FC, RefObject, useCallback } from "react";
import { AgGridReact } from "@ag-grid-community/react";
import { Typography } from "@mui/material";
import { enqueueSnackbar } from "notistack";
import {
  Role,
  useDeleteUsersMeOrganisationsCurrentRolesByRoleIdMutation,
} from "../../../../../../../../services/cloudchipr.api";
import { getOrgCurrentRolesThunk } from "../../../../../../../../store/user/thunks/roles/getOrgCurrentRolesThunk";
import { useAppDispatch } from "../../../../../../../../store/hooks";
import { DeleteConfirmationDialog } from "../../../../../../../common/modals/DeleteConfirmationDialog";

interface RoleDeletionDialogProps {
  gridRef?: RefObject<AgGridReact>;
  open: boolean;
  onClose(): void;
  role: Role;
}

export const RoleDeletionDialog: FC<RoleDeletionDialogProps> = ({
  open,
  onClose,
  gridRef,
  role,
}) => {
  const dispatch = useAppDispatch();

  const [deleteTrigger, { isLoading: isDeleting }] =
    useDeleteUsersMeOrganisationsCurrentRolesByRoleIdMutation({});
  const deleteRoleHandler = useCallback(async () => {
    if (!role) {
      return;
    }
    await deleteTrigger({
      roleId: role.id,
    });
    enqueueSnackbar("Role successfully deleted.", {
      variant: "snackbarAlert",
    });
    gridRef?.current?.api.applyTransaction({
      remove: [role],
    });
    dispatch(getOrgCurrentRolesThunk());
    onClose();
  }, [role, deleteTrigger, onClose, dispatch, gridRef]);

  if (!role) {
    return null;
  }

  return (
    <DeleteConfirmationDialog
      open={open}
      onClose={onClose}
      onDelete={deleteRoleHandler}
      loading={isDeleting}
      title="Delete Role?"
      content={
        <Typography>
          Please confirm if you want to delete
          <Typography component="span" fontWeight="bold">
            {" "}
            {role.name}{" "}
          </Typography>
          role.
        </Typography>
      }
    />
  );
};
