import { FC } from "react";
import { Button, DialogActions, DialogContent, Divider } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { azureServicePrincipalSelector } from "../../../../../../store/account-setup/selectors/azureServicePrincipalSelector";
import { useAppSelector } from "../../../../../../store/hooks";
import { useServicePrincipalHook } from "../azure/hooks/useServicePrincipal.hook";
import { CommandSnippet } from "../CommandSnippet";
import { AzureProvideTenantIdField } from "../azure/AzureProvideTenantIdField";
import { AzureServicePrincipalMessage } from "../azure/AzureServicePrincipalMessage";
import { CollapsableCommand } from "../CollapsableCommand";
import { AzureCLIInstallationGuide } from "../azure/AzureCLIInstallationGuide";

export const AzureServicePrincipalStep: FC = () => {
  const servicePrincipal = useAppSelector(azureServicePrincipalSelector);

  const { values, handleSubmit, handleChange, errors, isLoading, prevHandler } =
    useServicePrincipalHook();

  return (
    <form onSubmit={handleSubmit}>
      <DialogContent>
        <AzureServicePrincipalMessage />

        <CommandSnippet code={`az ad sp create --id "${servicePrincipal}"`} />

        <AzureProvideTenantIdField
          onChange={handleChange}
          value={values.tenantId}
          error={errors.tenantId}
        />

        <AzureCLIInstallationGuide />
        <CollapsableCommand
          message="* Use the following command to find the Tenant ID"
          commandSnippetCode="az account get-access-token --query tenant --output tsv"
        />
      </DialogContent>
      <Divider light />
      <DialogActions sx={{ px: 3, py: 2 }}>
        <Button
          onClick={prevHandler}
          variant="text"
          color="tertiary"
          size="large"
        >
          Cancel
        </Button>
        <LoadingButton
          loading={isLoading}
          disabled={!!errors.tenantId || !values.tenantId}
          variant="contained"
          size="large"
          color="primary"
          type="submit"
        >
          Next
        </LoadingButton>
      </DialogActions>
    </form>
  );
};
