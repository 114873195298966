import { FC, useCallback, useEffect } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Stack,
} from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";
import { LoadingButton } from "@mui/lab";
import { enqueueSnackbar } from "notistack";
import { CategoriesAccountsSelect } from "./CategoriesAccountsSelect";
import { CategoryNameValue } from "./CategoryNameValue";
import {
  AccountCategoryWithCost,
  useGetUsersMeCategoriesQuery,
  usePostUsersMeCategoriesMutation,
  usePutUsersMeCategoriesByCategoryIdMutation,
} from "../../../../../services/cloudchipr.api";
import { DialogTitleClosable } from "../../../../common/dialog-utils/DialogTitleClosable";

const validationSchema = yup.object({
  name: yup.string().required(),
  value: yup.string().required(),
  account_ids: yup.array().of(yup.string()).min(1),
});

interface CategoriesFormInitialValues {
  id?: string;
  name: string;
  value: string;
  account_ids: string[];
}

const initialValues: CategoriesFormInitialValues = {
  name: "",
  value: "",
  account_ids: [],
};

interface CategoryFormDialogProps {
  open: boolean;
  onClose(): void;
  editingCategory?: AccountCategoryWithCost;
  defaultName?: string;
}

export const CategoryFormDialog: FC<CategoryFormDialogProps> = ({
  open,
  onClose,
  defaultName,
  editingCategory,
}) => {
  const [createCategory, { isLoading: creating }] =
    usePostUsersMeCategoriesMutation();
  const [updateCategory, { isLoading: updating }] =
    usePutUsersMeCategoriesByCategoryIdMutation();

  const { refetch } = useGetUsersMeCategoriesQuery();

  const { values, handleSubmit, setFieldValue, isValid, setValues, resetForm } =
    useFormik({
      initialValues,
      validationSchema,
      validateOnMount: true,
      onSubmit: async (values) => {
        try {
          if (editingCategory) {
            await updateCategory({
              categoryId: editingCategory.id,
              body: {
                name: values.name,
                value: values.value,
                account_ids: values.account_ids,
              },
            }).unwrap();
          } else {
            await createCategory({
              body: {
                name: values.name,
                value: values.value,
                account_ids: values.account_ids,
              },
            }).unwrap();
          }

          const message = `Category successfully ${
            editingCategory ? "updated" : "created"
          }.`;

          enqueueSnackbar(message, {
            variant: "snackbarAlert",
            AlertSnackBarProps: { severity: "success" },
          });

          await refetch();
          closeHandler();
        } catch (e) {
          // @ts-expect-error TODO: api fix
          const errMessage = e?.data?.message || "Something went wrong";
          enqueueSnackbar(errMessage, {
            variant: "snackbarAlert",
            AlertSnackBarProps: {
              severity: "error",
            },
          });
        }
      },
    });

  const closeHandler = useCallback(() => {
    resetForm();
    onClose();
  }, [onClose, resetForm]);

  const accountSelectHandler = useCallback(
    (ids: string[]) => setFieldValue("account_ids", ids),
    [setFieldValue],
  );

  useEffect(() => {
    if (defaultName) {
      setFieldValue("name", defaultName);
    } else if (editingCategory) {
      setValues({
        id: editingCategory.id,
        value: editingCategory.value,
        name: editingCategory.name,
        account_ids: editingCategory.accounts.map(({ id }) => id),
      });
    }
  }, [editingCategory, defaultName, setValues, setFieldValue]);

  const dialogCreateTitle = `Add ${
    defaultName ? "New Category" : "Category Group"
  }`;

  return (
    <Dialog fullWidth maxWidth="sm" open={open}>
      <DialogTitleClosable
        title={
          editingCategory
            ? `Edit category ${editingCategory.name}`
            : dialogCreateTitle
        }
        onClose={closeHandler}
      />

      <form onSubmit={handleSubmit}>
        <DialogContent dividers>
          <Stack spacing={3}>
            <CategoryNameValue
              name={values.name}
              value={values.value}
              setFieldValue={setFieldValue}
              editingCategoryName={editingCategory?.name || defaultName}
            />

            <CategoriesAccountsSelect
              editingAccounts={editingCategory?.accounts}
              categoryName={values.name}
              selectedAccounts={values.account_ids}
              onSelect={accountSelectHandler}
            />
          </Stack>
        </DialogContent>

        <DialogActions sx={{ py: 2, px: 3 }}>
          <Button onClick={closeHandler} color="tertiary">
            Cancel
          </Button>
          <LoadingButton
            type="submit"
            loading={creating || updating}
            disabled={!isValid}
            variant="contained"
          >
            Save
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};
