import { FC, useCallback, useState } from "react";
import { Button, Dialog, DialogActions, DialogContent } from "@mui/material";
import { AlertsIntegrationCustomMessageDialogContent } from "./AlertsIntegrationCustomMessageDialogContent";
import { AlertsIntegrationCustomMessageContentExtendedProps } from "../utils/types/types";
import { DialogTitleClosable } from "../../../../../../../common/dialog-utils/DialogTitleClosable";

interface AlertsIntegrationCustomMessageDialogProps
  extends AlertsIntegrationCustomMessageContentExtendedProps {
  open: boolean;
  onClose(): void;
  onChange(message: string): void;
}

export const AlertsIntegrationCustomMessageDialog: FC<
  AlertsIntegrationCustomMessageDialogProps
> = ({ actionFrom, open, onClose, onChange, message, type }) => {
  const [customMessage, setCustomMessage] = useState<string>(message);

  const submitCustomMessageHandler = useCallback(() => {
    onChange(customMessage);
    onClose();
  }, [onChange, customMessage, onClose]);

  const cancelCustomMessageHandler = useCallback(() => {
    onChange(message);
    onClose();
    setCustomMessage(message);
  }, [onChange, onClose, message]);

  return (
    <Dialog
      open={open}
      onClose={cancelCustomMessageHandler}
      sx={{
        px: 1,
      }}
      fullWidth
      maxWidth="md"
    >
      <DialogTitleClosable
        title="Edit Message"
        onClose={cancelCustomMessageHandler}
        TitleProps={{ sx: { p: 2 } }}
      />
      <DialogContent
        dividers
        sx={{
          p: 2,
        }}
      >
        <AlertsIntegrationCustomMessageDialogContent
          type={type}
          message={customMessage}
          onCustomMessageChange={setCustomMessage}
          actionFrom={actionFrom}
        />
      </DialogContent>
      <DialogActions
        sx={{
          px: 2,
        }}
      >
        <Button onClick={cancelCustomMessageHandler}>Cancel</Button>
        <Button onClick={submitCustomMessageHandler} variant="contained">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};
