import moment from "moment";
import { AlertsData } from "../types/types";
import { formatDate } from "../../../../utils/helpers/date-time/datetime-format";

export const getDatesFromTimeInterval = (
  timeInterval?: string,
  timeIntervalUnit?: AlertsData["timeIntervalUnit"],
) => {
  if (!timeInterval || !timeIntervalUnit) {
    return {};
  }

  const dateTo = formatDate(moment(), { type: "yearMonthDay" });
  const dateFrom = formatDate(
    moment().subtract(timeInterval, timeIntervalUnit),
    {
      type: "yearMonthDay",
    },
  );

  return { dateFrom, dateTo };
};
