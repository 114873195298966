import { createAsyncThunk } from "@reduxjs/toolkit";
import { applyLiveUsageMgmtFilterTemplateThunk } from "./applyLiveUsageMgmtFilterTemplateThunk";
import { RootState } from "../../../store";
import { liveUsageMgmtAccountIdsSelector } from "../../selectors/store-selectors/liveUsageMgmtAccountIdsSelector";
import { getAccountsThunk } from "../../../accounts/thunks/getAccountsThunk";
import {
  setLiveUsageMgmtAppliedFilters,
  setLiveUsageMgmtAppliedFilterTemplateId,
} from "../../liveUsageMgmtSlice";
import { getLiveUsageMgmtResourcesWithFiltersByProviderThunk } from "../resources/getLiveUsageMgmtResourcesWithFiltersByProviderThunk";
import { getOrganisationCurrentFilterTemplatesThunk } from "../getOrganisationCurrentFilterTemplatesThunk";

export const tryToApplyLiveUsageMgmtFilterTemplateThunk = createAsyncThunk(
  "liveUsageMgmt/tryToApplyLiveUsageMgmtFilterTemplate",
  async (filterTemplateId: string, { dispatch, getState }) => {
    const state = getState() as RootState;
    const accountIds = liveUsageMgmtAccountIdsSelector(state);

    if (!accountIds) {
      return;
    }

    const response = await dispatch(
      applyLiveUsageMgmtFilterTemplateThunk({
        accountIds: accountIds ?? [],
        filterTemplateId,
      }),
    ).unwrap();

    if (!response) {
      return;
    }
    dispatch(setLiveUsageMgmtAppliedFilterTemplateId(response?.id));

    response?.filters.forEach((filter) => {
      dispatch(setLiveUsageMgmtAppliedFilters(filter));
    });
    dispatch(getAccountsThunk());

    response?.filters?.forEach(({ type }) => {
      dispatch(getLiveUsageMgmtResourcesWithFiltersByProviderThunk(type));
    });

    dispatch(getOrganisationCurrentFilterTemplatesThunk());

    return response;
  },
);
