import { useCallback } from "react";
import { AutomationType } from "../../../../../../../store/automations/utils/types/common";
import { useLocalStorageHook } from "../../../../../../../utils/hooks/useLocalStorage.hook";

type ReturnType = {
  getColumnSize(colId?: string): number | undefined;
  setColumnSize(colId?: string, size?: number): void;
};

export const useAutomationsColumnsSizes = (
  automationType: AutomationType,
): ReturnType => {
  const { getItem, setItem } = useLocalStorageHook();

  const setColumnSize: ReturnType["setColumnSize"] = useCallback(
    (colId, size) => {
      const key = `c8r:${automationType}-grid-${colId}-column-size`;

      if (!colId || !size) {
        return;
      }

      setItem(key, Math.round(size));
    },
    [setItem, automationType],
  );

  const getColumnSize: ReturnType["getColumnSize"] = useCallback(
    (colId) => {
      const key = `c8r:${automationType}-grid-${colId}-column-size`;

      if (!colId) {
        return;
      }

      return +getItem(key);
    },
    [getItem, automationType],
  );

  return {
    getColumnSize,
    setColumnSize,
  };
};
