import { recommendationsDataGridDataSelector } from "./recommendationsDataGridDataSelector";
import { RootState } from "../../../../store";
import { RecommendationGroupType } from "../../../../../components/pages/recommendations/components/off-hours-recommendations/utils/types/types";

export const recommendationsDataGridDataTotalLengthSelector = (
  state: RootState,
  group: RecommendationGroupType,
) => {
  const data = recommendationsDataGridDataSelector(state, group);

  return data?.length ?? 0;
};
