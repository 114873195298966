import { FC } from "react";

import { Button, Stack, Typography } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { ResourceExplorerBillingExportDialog } from "../../../../../resource-explorer/components/resource-explorer-card/components/resource-explorer-billing-export/components/resource-explorer-billing-export-dialog/ResourceExplorerBillingExportDialog";
import { useDialog } from "../../../../../../../utils/hooks/useDialog.hook";
import { useAppAbility } from "../../../../../../../services/permissions";

export const AddGcpBillingData: FC = () => {
  const { open, openDialog, closeDialog } = useDialog();

  const { cannot } = useAppAbility();
  const canNotGoToGCPConsole = cannot("edit", "billing");

  return (
    <Stack justifyContent="center" alignItems="center" height="100%">
      <InfoOutlinedIcon fontSize="large" />
      <Typography
        color="text.primary"
        variant="h5"
        fontWeight="medium"
        sx={{ pt: 4 }}
      >
        Add Billing Exports
      </Typography>
      <Typography
        color="text.secondary"
        variant="subtitle2"
        fontWeight="regular"
        fontSize={16}
        pt={2}
      >
        Please add billing exports, to see complete billing information.
      </Typography>

      <Button
        variant="contained"
        sx={{ mt: 4 }}
        disabled={canNotGoToGCPConsole}
        onClick={openDialog}
      >
        ADD BILLING EXPORTS
      </Button>
      <ResourceExplorerBillingExportDialog open={open} onClose={closeDialog} />
    </Stack>
  );
};
