import { cloudChiprApi } from "../../../../services/cloudchipr.api";
import { RootState } from "../../../store";
import { createCostAnomalyAlertFixedCacheKey } from "../../utils/constants/fixedCacheKeys";

const endpoint =
  cloudChiprApi.endpoints.postUsersMeOrganisationsCurrentAlertsCostAnomaly.select(
    createCostAnomalyAlertFixedCacheKey,
  );

export const saveCostAnomalyLoadingSelector = (state: RootState) => {
  return endpoint(state)?.isLoading;
};
