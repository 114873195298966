import { createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../../../store";
import { getLiveFilteredTrackedResourcesForViewThunk } from "../live-filtered-resources/getLiveFilteredTrackedResourcesForViewThunk";
import { resourceExplorerPayloadDataSelector } from "../../selectors/current-resource-explorer/resource-explorer-widget/resourceExplorerPayloadDataSelector";
import { resourceExplorerPathLastItemFilterTreeSelector } from "../../selectors/current-resource-explorer/path/resourceExplorerPathLastItemFilterTreeSelector";
import { isTreeNodeFilterGroup } from "../../../../components/pages/common/filters-tree/utils/helpers/isTreeNodeFilterGroup";
import { addFilterTreePathToResourceExplorerPayload } from "../../utils/helpers/addFilterTreePathToResourceExplorerPayload";

export const getResourceExplorerLiveFilteredResourcesThunk = createAsyncThunk(
  "resourceExplorer/getResourceExplorerLiveFilteredResources",
  (_, { dispatch, getState }) => {
    const state = getState() as RootState;
    const pathLastItemFilterTree =
      resourceExplorerPathLastItemFilterTreeSelector(state);

    const resourceExplorerDataGettingPayload =
      resourceExplorerPayloadDataSelector(state);

    let payload = resourceExplorerDataGettingPayload;

    if (
      isTreeNodeFilterGroup(pathLastItemFilterTree) &&
      pathLastItemFilterTree.items.length > 0
    ) {
      payload = addFilterTreePathToResourceExplorerPayload(
        resourceExplorerDataGettingPayload,
        pathLastItemFilterTree,
      );
    }

    dispatch(getLiveFilteredTrackedResourcesForViewThunk(payload));
  },
);
