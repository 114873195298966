import { billingInProgressStatusesSelector } from "./in-progress/billingInProgressStatusesSelector";
import { billingWarningAccountStatusesSelector } from "./warning/billingWarningAccountStatusesSelector";
import { billingErrorAccountStatusesSelector } from "./error/billingErrorAccountStatusesSelector";
import { RootState } from "../../../store";
import { BillingAccountStatusWithProvider } from "../../utils/types/types";
import { isBillingErrorStatus } from "../../utils/helpers/billing-status/isBillingErrorStatus";
import { isBillingWarningStatus } from "../../utils/helpers/billing-status/isBillingWarningStatus";
import { isBillingInProgressStatus } from "../../utils/helpers/billing-status/isBillingInProgressStatus";

export const billingAccountSingleStatusSelector = (
  state: RootState,
): {
  type: "inProgress" | "error" | "warning";
  count: number;
} | null => {
  const inProgressStatuses = billingInProgressStatusesSelector(state);
  const warningAccountStatuses = billingWarningAccountStatusesSelector(state);
  const errorAccountStatuses = billingErrorAccountStatusesSelector(state);

  const allStatuses = [
    inProgressStatuses,
    warningAccountStatuses,
    errorAccountStatuses,
  ];

  let singleStatus: BillingAccountStatusWithProvider[] | undefined;

  for (const statusesArr of allStatuses) {
    if (singleStatus && statusesArr?.length !== 0) {
      return null;
    }

    if (statusesArr?.length !== 0) {
      singleStatus = statusesArr;
    }
  }

  if (!singleStatus) {
    return null;
  }

  const type =
    (isBillingErrorStatus(singleStatus[0].status) && "error") ||
    (isBillingWarningStatus(singleStatus[0].status) && "warning") ||
    (isBillingInProgressStatus(singleStatus[0].status) && "inProgress");

  if (!type) {
    return null;
  }

  return {
    type,
    count: singleStatus.length,
  };
};
