import { billingAccountStatusAllProvidersSelector } from "./billingAccountStatusAllProvidersSelector";
import { isBillingStatusesGettingFulfilledSelector } from "./isBillingStatusesGettingFulfilledSelector";
import { missingProviderStatuses } from "./enabledProvidersByBillingStatusSelector";
import { BillingCollectionStatus } from "../../../../services/cloudchipr.api";
import { RootState } from "../../../store";

export const isBillingAvailableSelector = (state: RootState): boolean => {
  const billingStatusesAllProviders =
    billingAccountStatusAllProvidersSelector(state);

  const billingStatusesAllProvidersArr = Object.values(
    billingStatusesAllProviders ?? {},
  );

  const isFulfilled = isBillingStatusesGettingFulfilledSelector(state);
  return (
    isFulfilled &&
    !!billingStatusesAllProvidersArr.length &&
    checkAvailability(billingStatusesAllProvidersArr)
  );
};

function checkAvailability(providers: BillingCollectionStatus[]) {
  return !providers.every((provider) =>
    missingProviderStatuses.includes(provider),
  );
}
