import { GetQuickFilterTextParams } from "@ag-grid-community/core/dist/types/src/entities/colDef";
import { Schedule } from "../../../../../../../services/cloudchipr.api";
import { automationsAllActions } from "../../../../../schedule/common/utils/constants/common";

export const quickFilterActionText = (
  params: GetQuickFilterTextParams<Schedule>,
) => {
  const schedule = params.data;
  return schedule.action && automationsAllActions[schedule.action];
};
