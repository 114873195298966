import { createAsyncThunk } from "@reduxjs/toolkit";
import { getAllReportsThunk } from "./getAllReportsThunk";
import { createReportThunk } from "./createReportThunk";
import { editReportThunk } from "./editReportThunk";
import { RootState } from "../../store";
import { isReportEditModeSelector } from "../selector/isReportEditModeSelector";
import { resetReportsData, toggleReportsOpenDrawer } from "../reportsSlice";

export const saveReportThunk = createAsyncThunk(
  "reports/saveReport",
  async (_, { dispatch, getState }) => {
    const state = getState() as RootState;
    const isEditMode = isReportEditModeSelector(state);

    let response;

    if (isEditMode) {
      response = await dispatch(editReportThunk()).unwrap();
    } else {
      response = await dispatch(createReportThunk()).unwrap();
    }

    dispatch(toggleReportsOpenDrawer());
    dispatch(resetReportsData());
    await dispatch(getAllReportsThunk());
    return response;
  },
);
