import { reportsDataPropertyByKeySelector } from "./reportsDataPropertyByKeySelector";
import { RootState } from "../../store";

export const sendSingleReportButtonDisabledSelector = (state: RootState) => {
  const exportTypes =
    reportsDataPropertyByKeySelector("export_file_types")(state);
  const emails = reportsDataPropertyByKeySelector("emails")(state);
  const notifications =
    reportsDataPropertyByKeySelector("notifications")(state);

  const notificationsValidation = notifications?.some((integration) => {
    if (integration.type === "email" && !integration.to?.length) {
      return true;
    }
    return integration.type === "slack" && !integration.conversations?.length;
  });

  const integrationValidation =
    notificationsValidation || !(notifications?.length || emails?.to.length);

  return integrationValidation || !exportTypes?.length;
};
