import { FC, ReactNode } from "react";
import { Stack } from "@mui/material";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { TypographyWithTooltip } from "../../../../../../common/TypographyWithTooltip";
import { ExecutionLogV3 } from "../../../../../../../services/cloudchipr.api";
import SkipIcon from "../../../../../../../assets/images/icons/skip.svg";

type StateType = ExecutionLogV3["response"][0]["resources"][0]["state"];

interface ExecutionLogResourceLabelProps {
  label: string;
  state: StateType;
}

export const ExecutionLogResourceLabel: FC<ExecutionLogResourceLabelProps> = ({
  label,
  state,
}) => {
  const stateIcon = new Map<StateType, ReactNode>([
    [
      "succeed",
      <CheckCircleOutlineIcon color="success" fontSize="small" key="succeed" />,
    ],
    [
      "failed",
      <ErrorOutlineIcon color="error" fontSize="small" key="failed" />,
    ],
    ["skipped", <img src={SkipIcon} width={24} alt="Skipped" key="skipped" />],
  ]);

  return (
    <Stack
      direction="row"
      spacing={1}
      alignItems="center"
      sx={{ verticalAlign: "start" }}
    >
      {stateIcon.get(state)}

      <TypographyWithTooltip title={label} variant="body2" maxWidth={250} />
    </Stack>
  );
};
