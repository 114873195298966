import { reservationsRecommendationsPayloadSelector } from "./reservationsRecommendationsPayloadSelector";
import { RootState } from "../../../../store";
import { cloudChiprApi } from "../../../../../services/cloudchipr.api";

export const reservationsRecommendationsSelector = (state: RootState) => {
  const payload = reservationsRecommendationsPayloadSelector(state);

  if (!payload) {
    return;
  }
  return cloudChiprApi.endpoints.getUsersMeOrganisationsCurrentReservationsRecommendations.select(
    payload,
  )(state);
};
