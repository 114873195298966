import { createAsyncThunk } from "@reduxjs/toolkit";
import { getLargestCostChangesWidgetDataBySetupThunk } from "../data-get/getLargestCostChangesWidgetDataBySetupThunk";
import { RootState } from "../../../../../store";
import { setWidgetSetup } from "../../../../dashboardsSlice";
import { largestCostChangesWidgetByIdSelector } from "../../../../selectors/widgets/largest-cost-changes/largestCostChangesWidgetByIdSelector";
import { LargestCostChangesSetupType } from "../../../../utils/types/setups/largestCostChangesSetupType";

export const editLargestCostChangesWidgetThunk = createAsyncThunk(
  "dashboards/editLargestCostChangesWidget",
  (widgetId: string, { dispatch, getState }) => {
    const state = getState() as RootState;
    const widget = largestCostChangesWidgetByIdSelector(state, widgetId);

    if (!widget) {
      return;
    }

    dispatch(
      setWidgetSetup({
        widgetType: "largest_cost_changes",
        id: widget.id,
        name: widget.name,
        dates: widget.dates,
        grouping: widget.grouping,
        group_values: widget.group_values,
        viewId: widget.view_id,
        priceDirection: widget.price_direction,
        sortingBy: widget.sort_by,
        itemsCount: widget.items_count,
        minPercentChange: {
          value: widget.min_percentage_change,
          state: !!widget.min_percentage_change,
        },
        minCostChange: {
          value: widget.min_cost_change,
          state: !!widget.min_cost_change,
        },
      } as LargestCostChangesSetupType),
    );

    dispatch(getLargestCostChangesWidgetDataBySetupThunk());
  },
);
