import { ListItemText, Stack } from "@mui/material";
import { GroupingOptionIcon } from "../GroupingOptionIcon";
import { TypographyWithTooltip } from "../../../../../common/TypographyWithTooltip";
import { ResourceExplorerFilterValues } from "../../../../../../services/cloudchipr.api";
import { DropdownSelectOption } from "../../../../../common/select/dropdown-select/utils/types/types";

export const getDimensionGroupingOptions = (
  dimensionsOptions?: ResourceExplorerFilterValues,
): DropdownSelectOption => ({
  value: "category",
  disableSelection: true,
  label: (
    <Stack direction="row" spacing={1} alignItems="center">
      <GroupingOptionIcon grouping="category" />
      <ListItemText primary="Dimensions" />
    </Stack>
  ),
  rawValue: { search: "dimensions category" },
  nestedOptions: dimensionsOptions?.map((option) => ({
    value: option.value,
    rawValue: { search: `${option.title} category dimension` },
    label: (
      <Stack direction="row" spacing={1} alignItems="center" pl={2}>
        <GroupingOptionIcon grouping="category" />
        <TypographyWithTooltip
          variant="body2"
          title={option.title ?? option.value}
        />
      </Stack>
    ),
  })),
});
