import { createAsyncThunk } from "@reduxjs/toolkit";
import { enqueueSnackbar } from "notistack";
import { taskManagementGetTasksThunk } from "./taskManagementGetTasksThunk";
import { cloudChiprApi } from "../../../../services/cloudchipr.api";
import { taskManagementTaskSavingPayloadSelector } from "../../selectors/form/payload/taskManagementTaskSavingPayloadSelector";
import { RootState } from "../../../store";
import {
  setTaskManagementDrawerOpen,
  setTaskManagementTask,
  setTaskManagementTaskTarget,
} from "../../taskManagementSlice";
import { taskCreationFixedCacheKey } from "../../utils/constants/fixedCacheKeys";

export const taskManagementCreateTaskThunk = createAsyncThunk(
  "taskManagement/taskManagementSaveTask",
  async (_, { dispatch, getState }) => {
    const state = getState() as RootState;
    const { postUsersMeOrganisationsCurrentTasks } = cloudChiprApi.endpoints;

    const payload = taskManagementTaskSavingPayloadSelector(state);

    if (!payload) {
      return;
    }

    try {
      const response = await dispatch(
        postUsersMeOrganisationsCurrentTasks.initiate(
          { body: payload },
          { fixedCacheKey: taskCreationFixedCacheKey },
        ),
      ).unwrap();

      dispatch(taskManagementGetTasksThunk());
      dispatch(setTaskManagementTask(null));
      dispatch(setTaskManagementTaskTarget(null));
      dispatch(setTaskManagementDrawerOpen(false));

      return response;
    } catch (e) {
      // @ts-expect-error TODO: return to this after adding error type
      const errMessage = e?.data?.message || "Something went wrong";
      enqueueSnackbar(errMessage, {
        variant: "snackbarAlert",
        AlertSnackBarProps: { severity: "error" },
      });
    }
  },
);
