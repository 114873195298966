import { Button, Stack } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { FC, useCallback } from "react";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../store/hooks";
import { saveCostAnomalyAlertsThunk } from "../../../../../../../store/alerts/thunks/cost-anomaly-alerts/saveCostAnomalyAlertsThunk";
import { saveCostAnomalyLoadingSelector } from "../../../../../../../store/alerts/selectors/cost-anomaly-alerts/saveCostAnomalyLoadingSelector";
import { saveAlertButtonDisabledSelector } from "../../../../../../../store/alerts/selectors/common/saveAlertButtonDisabledSelector";
import { AlertTabType } from "../../../../../../../store/alerts/utils/types/types";
import { saveUtilizationAlertThunk } from "../../../../../../../store/alerts/thunks/utilization-alerts/saveUtilizationAlertThunk";
import { saveUtilizationLoadingSelector } from "../../../../../../../store/alerts/selectors/utilization-alerts/saveUtilizationLoadingSelector";
import { editCostAnomalyLoadingSelector } from "../../../../../../../store/alerts/selectors/cost-anomaly-alerts/editCostAnomalyLoadingSelector";
import { editUtilizationLoadingSelector } from "../../../../../../../store/alerts/selectors/utilization-alerts/editUtilizationLoadingSelector";

interface AlertActionsProps {
  onClose(): void;
  alertType: AlertTabType;
}

export const AlertActions: FC<AlertActionsProps> = ({ onClose, alertType }) => {
  const dispatch = useAppDispatch();
  const costAnomalyLoading = useAppSelector(saveCostAnomalyLoadingSelector);
  const editCostAnomalyLoading = useAppSelector(editCostAnomalyLoadingSelector);
  const utilizationLoading = useAppSelector(saveUtilizationLoadingSelector);
  const editUtilizationLoading = useAppSelector(editUtilizationLoadingSelector);
  const disabled = useAppSelector((state) =>
    saveAlertButtonDisabledSelector(state, alertType),
  );

  const loading =
    costAnomalyLoading ||
    utilizationLoading ||
    editCostAnomalyLoading ||
    editUtilizationLoading;

  const saveHandler = useCallback(() => {
    if (alertType === "costAnomaly") {
      dispatch(saveCostAnomalyAlertsThunk());
    } else {
      dispatch(saveUtilizationAlertThunk());
    }
  }, [dispatch, alertType]);

  return (
    <Stack direction="row" spacing={2.5}>
      <LoadingButton
        onClick={saveHandler}
        loading={loading}
        disabled={disabled}
        variant="contained"
      >
        Save
      </LoadingButton>

      <Button color="tertiary" onClick={onClose}>
        Cancel
      </Button>
    </Stack>
  );
};
