import { costBreakdownWidgetDataByWidgetIdSelector } from "./costBreakdownWidgetDataByWidgetIdSelector";
import { costBreakdownWidgetFrequencySelector } from "./costBreakdownWidgetFrequencySelector";
import { RootState } from "../../../../../store";
import { formatResourceExplorerChartData } from "../../../../../resource-explorer/utils/helpers/formatResourceExplorerChartData";
import { convertCostToPercent } from "../../../../../resource-explorer/selectors/current-resource-explorer/chart/utils/helpers/convertCostToPercent";
import {
  FormatedData,
  generateCombinatedChartData,
} from "../../../../../resource-explorer/selectors/current-resource-explorer/chart/utils/helpers/generateCombinatedChartData";

export const costBreakdownWidgetCombinedChartDataSelector = (
  state: RootState,
  widgetId: string,
): FormatedData | null => {
  const data = costBreakdownWidgetDataByWidgetIdSelector(state, widgetId)?.data;

  const frequency = costBreakdownWidgetFrequencySelector(state, widgetId);

  if (!data || !frequency) {
    return null;
  }

  const formatedData = formatResourceExplorerChartData(data, frequency);
  const percentData = convertCostToPercent(formatedData);

  return generateCombinatedChartData(formatedData, percentData);
};
