import { FC, useCallback } from "react";
import { Stack } from "@mui/material";
import { CostBreakdownWidgetAdvancedSettingsTrendSetting } from "./CostBreakdownWidgetAdvancedSettingsTrendSetting";
import AdvancedSettingsIcon from "../../../../../../../../../../../assets/images/icons/advanced_settings_icon.svg";
import { WidgetConfigSection } from "../../../../common/widget-configurations/WidgetConfigSection";
import { WidgetForecastSelect } from "../../../../common/toolbar/WidgetForecastSelect";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../../../../store/hooks";
import { costBreakdownSetupPropertyByKeySelector } from "../../../../../../../../../../../store/dashboards/selectors/setups/cost-breakdown/costBreakdownSetupPropertyByKeySelector";
import { costBreakdownWidgetSetupForecastedOptionsSelector } from "../../../../../../../../../../../store/dashboards/selectors/widgets/cost-breakdown/setup/costBreakdownWidgetSetupForecastedOptionsSelector";
import { costBreakdownForecastChangeThunk } from "../../../../../../../../../../../store/dashboards/thunks/widgets/cost-breakdown/setup-change/costBreakdownForecastChangeThunk";

export const CostBreakdownWidgetAdvancedSettingsSection: FC = () => {
  const dispatch = useAppDispatch();
  const forecastOption = useAppSelector(
    costBreakdownSetupPropertyByKeySelector("forecastOption"),
  );

  const forecastedCostsOptions = useAppSelector(
    costBreakdownWidgetSetupForecastedOptionsSelector,
  );

  const forecastChangeHandler = useCallback(
    (event: any) => {
      dispatch(costBreakdownForecastChangeThunk(event.target.value));
    },
    [dispatch],
  );

  return (
    <WidgetConfigSection
      title="Advanced Settings"
      icon={<img src={AdvancedSettingsIcon} alt="Advanced Settings Icon" />}
    >
      <Stack spacing={1.5}>
        <WidgetForecastSelect
          forecast={forecastOption}
          onChange={forecastChangeHandler}
          options={forecastedCostsOptions}
        />
        <CostBreakdownWidgetAdvancedSettingsTrendSetting />
      </Stack>
    </WidgetConfigSection>
  );
};
