import { TagFilter, TagsFilter } from "../types";
import { operatorTypeChanged } from "../../../../../pages/common/filters/utils/helpers/operatorTypeChanged";

export const generateFilterAfterTagFiltersOperatorChange = (
  newOperator: TagFilter["operator"],
  prevFilter: TagsFilter,
  index: number,
) => {
  const updatedListFiltersValue = prevFilter?.value.map((item, i) => {
    if (i === index) {
      return {
        ...item,
        operator: newOperator,
        values: operatorTypeChanged(item?.operator, newOperator)
          ? item.values
          : [],
      };
    }
    return item;
  });

  return {
    ...prevFilter,
    value: updatedListFiltersValue,
  };
};
