import { createDraftSafeSelector } from "@reduxjs/toolkit";
import { cloudChiprApi, Filters } from "../../../services/cloudchipr.api";

const awsAllowedFiltersSelector =
  cloudChiprApi.endpoints.getProvidersByProviderFilters.select({
    provider: "aws",
  });

const gcpAllowedFiltersSelector =
  cloudChiprApi.endpoints.getProvidersByProviderFilters.select({
    provider: "gcp",
  });

const azureAllowedFiltersSelector =
  cloudChiprApi.endpoints.getProvidersByProviderFilters.select({
    provider: "azure",
  });

export const allowedFiltersSelector = createDraftSafeSelector(
  [
    awsAllowedFiltersSelector,
    gcpAllowedFiltersSelector,
    azureAllowedFiltersSelector,
  ],
  (
    { data: awsAllowedFilters },
    { data: gcpAllowedFilters },
    { data: azureAllowedFilters },
  ): Filters => {
    return [
      ...(awsAllowedFilters || []),
      ...(gcpAllowedFilters || []),
      ...(azureAllowedFilters || []),
    ];
  },
);
