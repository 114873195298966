import { FC } from "react";
import { Box, PopoverProps } from "@mui/material";
import {
  DateRangePickerInput,
  InputSize,
} from "./components/DateRangePickerInput";
import { DateRangePickerPopover } from "./components/DateRangePickerPopover";
import { PreDefinedRanges } from "./utils/constants/types";
import { preDefinedRanges } from "./utils/preDefinedRanges/preDefinedRanges";
import { minSelectionDate } from "./utils/constants/constants";
import { useMenuHook } from "../../../utils/hooks/useMenu.hook";
import { Dates } from "../../../services/cloudchipr.api";

interface DateRangePickerProps {
  quarterStartMonth?: number;
  selectedDates: Dates;
  ranges?: PreDefinedRanges;
  highlightToday?: boolean;
  maxDate?: string;
  minDate?: string;
  availableMinDate?: string;
  onDateSelect(dates: Dates): void;
  fullWidth?: boolean;
  size?: InputSize;
  PopoverProps?: Partial<Omit<PopoverProps, "open">>;
}

export const DateRangePicker: FC<DateRangePickerProps> = ({
  highlightToday,
  onDateSelect,
  selectedDates,
  maxDate,
  quarterStartMonth,
  fullWidth,
  ranges = preDefinedRanges,
  availableMinDate,
  minDate = minSelectionDate,
  size,
  PopoverProps,
}) => {
  const { open, openMenu, closeMenu, anchor } = useMenuHook();

  return (
    <Box>
      <DateRangePickerInput
        open={open}
        size={size}
        onClick={openMenu}
        ranges={ranges}
        selectedDates={selectedDates}
        fullWidth={fullWidth}
      />

      <DateRangePickerPopover
        open={open}
        ranges={ranges}
        anchor={anchor}
        minDate={minDate}
        maxDate={maxDate}
        onClose={closeMenu}
        PopoverProps={PopoverProps}
        onDateSelect={onDateSelect}
        selectedDates={selectedDates}
        highlightToday={highlightToday}
        availableMinDate={availableMinDate}
        quarterStartMonth={quarterStartMonth}
      />
    </Box>
  );
};
