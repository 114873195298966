import { FC, useCallback } from "react";
import { useAppSelector } from "../../../../../../../store/hooks";
import { commitmentsDetailsDrawerDateGranularitySelector } from "../../../../../../../store/commitments/selectors/common/details-drawer/commitmentsDetailsDrawerDateGranularitySelector";
import { formatDateByFrequencyType } from "../../../../../../../utils/helpers/date-time/datetime-format";
import { CommitmentsChart } from "../../../../common/components/chart/CommitmentsChart";
import { utilizationAndCoverageDetailsGranularDataGroupedByDateSelector } from "../../../../../../../store/commitments/selectors/coverage-and-utilization/details-drawer/utilizationAndCoverageDetailsGranularDataGroupedByDateSelector";
import { utilizationAndCoverageDetailsLoadingSelector } from "../../../../../../../store/commitments/selectors/coverage-and-utilization/details-drawer/data/utilizationAndCoverageDetailsLoadingSelector";

interface UtilizationAndCoverageDrawerChartProps {
  ChartHeader?: FC;
}

export const UtilizationAndCoverageDrawerChart: FC<
  UtilizationAndCoverageDrawerChartProps
> = ({ ChartHeader }) => {
  const dataGroupedByDate = useAppSelector(
    utilizationAndCoverageDetailsGranularDataGroupedByDateSelector,
  );
  const dataPoint = useAppSelector(
    commitmentsDetailsDrawerDateGranularitySelector,
  );
  const loading = useAppSelector(utilizationAndCoverageDetailsLoadingSelector);

  const labelFormatter = useCallback(
    (value: string) => formatDateByFrequencyType(dataPoint, value),
    [dataPoint],
  );

  return (
    <CommitmentsChart
      loading={loading}
      availableTypes={["area"]}
      labelFormatter={labelFormatter}
      dataGroupedByDate={dataGroupedByDate}
      ChartHeader={ChartHeader}
    />
  );
};
