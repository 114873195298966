import { useEffect, useState } from "react";
import moment from "moment";
import {
  NullableDate,
  usePostUsersMeOffHoursSchedulesNextRunDateMutation,
} from "../../../../../../services/cloudchipr.api";
import { getAutomationPreviewFormatDate } from "../../../common/utils/helpers/common";
import { formatDate } from "../../../../../../utils/helpers/date-time/datetime-format";

export function useOffHoursNextRunDates({
  startDate,
  endDate,
  startCron,
  stopCron,
  timeZone,
}: {
  startDate: string;
  timeZone: string;
  endDate: NullableDate;
  startCron: string | null;
  stopCron: string | null;
}) {
  const startDateTime = startDate || moment().format();

  const [nextRunDates, setNextRunDates] = useState({
    nextRunStartDate: getAutomationPreviewFormatDate(startDateTime ?? ""),
    nextRunStopDate: getAutomationPreviewFormatDate(endDate ?? ""),
  });

  const [getOffHoursDates] =
    usePostUsersMeOffHoursSchedulesNextRunDateMutation();

  useEffect(() => {
    if (!startCron || !stopCron) {
      // on once off-hours we are using {endDate:startAt, startDate:stopAt,} so we need to replace them here
      setNextRunDates((dates) => {
        const [nextRunStartDate, nextRunStopDate] = [
          dates.nextRunStopDate,
          dates.nextRunStartDate,
        ];

        return {
          nextRunStartDate,
          nextRunStopDate,
        };
      });
      return;
    }

    getOffHoursDates({
      body: {
        start_cron: startCron,
        stop_cron: stopCron,
        time_zone: timeZone,
        start_date_time: startDate ? formatDate(startDate) : null,
        end_date_time: endDate ? formatDate(endDate) : endDate,
      },
    })
      .unwrap()
      .then(({ next_start_run_date_time, next_stop_run_date_time }) => {
        setNextRunDates({
          nextRunStartDate: getAutomationPreviewFormatDate(
            next_start_run_date_time || "",
          ),

          nextRunStopDate: getAutomationPreviewFormatDate(
            next_stop_run_date_time || "",
          ),
        });
      });
  }, [startDate, endDate, startCron, timeZone, stopCron, getOffHoursDates]);

  return nextRunDates;
}
