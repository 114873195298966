import { ChangeEvent, FC, useCallback, useMemo } from "react";
import {
  Divider,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from "@mui/material";
import { ResourceActionsMissingPermissionsWrapper } from "./ResourceActionsMissingPermissionsWrapper";
import {
  CleanActionTypes,
  getActionValue,
  getCleanActionOptions,
} from "../../../../../../../../../utils/clean-options";
import { ResourceFiltersWithAction } from "../../../../../../../../../services/cloudchipr.api";
import { useAllResourcesVisibility } from "../../../../../../../schedule/common/utils/hooks/useAllResourcesVisibility.hook";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../../store/hooks";
import { stateAccountsSelector } from "../../../../../../../../../store/accounts/selectors/slice-data/stateAccountsSelector";
import { automationAccountIdsSelector } from "../../../../../../../../../store/automations/selectros/common/fields/automationAccountIdsSelector";
import { automationProviderSelector } from "../../../../../../../../../store/automations/selectros/common/fields/automationProviderSelector";
import { automationResourcesSelector } from "../../../../../../../../../store/automations/selectros/common/fields/automationResourcesSelector";
import { setAutomationData } from "../../../../../../../../../store/automations/automationsSlice";

interface AutomationResourceActionsProps {
  resourceFilters: ResourceFiltersWithAction;
}

export const AutomationResourceActions: FC<AutomationResourceActionsProps> = ({
  resourceFilters,
}) => {
  const dispatch = useAppDispatch();

  const accounts = useAppSelector(stateAccountsSelector);
  const accountIds = useAppSelector(automationAccountIdsSelector);
  const provider = useAppSelector(automationProviderSelector);
  const resources = useAppSelector(automationResourcesSelector);

  const resourceType = resourceFilters.filter.type;
  const value = getActionValue(
    resourceFilters.action,
    resourceFilters.snapshot,
  );

  const readWriteAccountsIds = useMemo(() => {
    if (!accounts) {
      return "";
    }

    const readWriteAccounts = accounts.filter((account) => {
      return (
        account.access_type === "read_write" && accountIds.includes(account.id)
      );
    });

    return readWriteAccounts.map((account) => account.id).join(", ");
  }, [accountIds, accounts]);

  const resourcesVisibility = useAllResourcesVisibility({
    accountIds: readWriteAccountsIds,
    provider,
  });

  const options = useMemo(() => {
    return getCleanActionOptions(resourceType).filter((option) =>
      Object.values(resourcesVisibility).every((visibility) => {
        return visibility?.[resourceType]?.actions?.[option.value] !== null;
      }),
    );
  }, [resourceType, resourcesVisibility]);

  const filterActionChangeHandler = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const action = event.target.value as CleanActionTypes;

      const resourcesNew = resources?.map((resource) => {
        if (action && resource.filter.type === resourceType) {
          return {
            ...resource,
            action: (action?.includes("delete")
              ? "terminate"
              : action) as ResourceFiltersWithAction["action"],
            snapshot: action === "snapshot_and_delete",
          };
        }

        return resource;
      });

      dispatch(setAutomationData({ filter: resourcesNew }));
    },
    [resources, dispatch, resourceType],
  );

  if (!Object.values(resourcesVisibility).length) {
    return null;
  }

  return (
    <Stack
      py={1}
      mt={1}
      px={1.5}
      spacing={1}
      bgcolor="grey.50"
      direction="row"
      alignItems="center"
    >
      <Typography fontWeight="medium" variant="body2">
        Select Action
      </Typography>

      <Divider orientation="vertical" flexItem />

      {!options.length ? (
        <Typography variant="caption">No actions available</Typography>
      ) : (
        <RadioGroup
          row
          onChange={filterActionChangeHandler}
          value={value}
          sx={{ gap: 2 }}
        >
          {options.map((option) => {
            return (
              <ResourceActionsMissingPermissionsWrapper
                key={option.value}
                cleanAction={option.value}
                resourceType={resourceType}
                readWriteAccountsIds={readWriteAccountsIds}
              >
                <FormControlLabel
                  key={option.value}
                  value={option.value}
                  label={option.label}
                  checked={value === option.value}
                  control={<Radio size="small" />}
                />
              </ResourceActionsMissingPermissionsWrapper>
            );
          })}
        </RadioGroup>
      )}
    </Stack>
  );
};
