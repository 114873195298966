import { RootState } from "../../../../store";
import { DashboardWidgetLargestCostChanges } from "../../../../../services/cloudchipr.api";
import { widgetByIdSelector } from "../common/widgetByIdSelector";

export const largestCostChangesWidgetByIdSelector = (
  state: RootState,
  widgetId?: string,
): DashboardWidgetLargestCostChanges | undefined => {
  if (!widgetId) {
    return;
  }

  return widgetByIdSelector(
    state,
    widgetId,
  ) as DashboardWidgetLargestCostChanges;
};
