import { FC } from "react";
import { TypographyWithTooltip } from "../../../../../../../../../common/TypographyWithTooltip";

interface PricingTierCellProps {
  pricingTier: string;
  type: string;
}

export const PricingTierCell: FC<PricingTierCellProps> = ({
  pricingTier,
  type,
}) => {
  return (
    <TypographyWithTooltip title={`${pricingTier}, ${type}`} variant="body2" />
  );
};
