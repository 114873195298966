import { FC } from "react";
import { Chip, Stack, Typography } from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { GroupsOutlined } from "@mui/icons-material";
import { VisibilityType } from "../../utils/types/types";

interface HierarchyAllItemsVisibilityCell {
  visibility: VisibilityType;
}

export const HierarchyAllItemsVisibilityCell: FC<
  HierarchyAllItemsVisibilityCell
> = ({ visibility }) => {
  if (visibility === "visible_only_to_me") {
    return (
      <Chip
        size="small"
        sx={{ px: 0.25 }}
        icon={<LockOutlinedIcon sx={{ fontSize: 16 }} />}
        label="Only me"
      />
    );
  }

  return (
    <Stack direction="row" spacing={0.75}>
      <GroupsOutlined fontSize="small" color="action" />
      <Typography variant="caption" color="text.secondary">
        Everyone
      </Typography>
    </Stack>
  );
};
