import { Card, Typography } from "@mui/material";
import { FC } from "react";

export const AlertsCostsAnomalyPreviewTableFooter: FC = () => {
  return (
    <Card
      variant="outlined"
      sx={{
        p: 1,
        borderTop: "none",
        textAlign: "center",
        borderRadius: "0 0 4px 4px",
      }}
    >
      <Typography variant="body2">No Data</Typography>
    </Card>
  );
};
