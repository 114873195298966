import { FC } from "react";
import { Divider, Stack } from "@mui/material";

import { SlackIntegrationActionExecutedTitle } from "../../../slack/action-execute/SlackIntegrationActionExecutedTitle";
import { SlackIntegrationActionExecutedSubTitle } from "../../../slack/action-execute/SlackIntegrationActionExecutedSubTitle";
import { SlackIntegrationActionExecutedDescription } from "../../../slack/action-execute/SlackIntegrationActionExecutedDescription";
import { SlackIntegrationActionExecutedWorkflowInfo } from "../../../slack/action-execute/SlackIntegrationActionExecutedWorkflowInfo";
import { SlackIntegrationDataGrid } from "../../../slack/common/SlackIntegrationDataGrid";
import { CreateIntegrationMessageEditor } from "../../../../../../../../common/integration-dialogs/components/common/CreateIntegrationMessageEditor";

interface SlackActionExecutedDialogContentProps {
  customMessage: string;
  onCustomMessageChange(message: string): void;
}

export const SlackActionExecutedDialogContent: FC<
  SlackActionExecutedDialogContentProps
> = ({ customMessage, onCustomMessageChange }) => {
  return (
    <Stack spacing={1.5}>
      <SlackIntegrationActionExecutedTitle />

      <SlackIntegrationActionExecutedSubTitle />

      <SlackIntegrationActionExecutedDescription />

      <SlackIntegrationActionExecutedWorkflowInfo />

      <Divider />
      <CreateIntegrationMessageEditor
        value={customMessage ?? ""}
        onChange={onCustomMessageChange}
        placeholder="Write a custom message..."
      />
      <SlackIntegrationDataGrid />
    </Stack>
  );
};
