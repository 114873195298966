import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  cloudChiprApi,
  PostV2UsersMeOrganisationsCurrentResourceExplorerViewsApiArg,
} from "../../../../services/cloudchipr.api";
import { createResourceExplorerThunkFixedCacheKey } from "../resource-explorer/createResourceExplorerThunk";

export const createResourceExplorerVisibilityHierarchyItemByBodyThunk =
  createAsyncThunk(
    "resourceExplorer/createResourceExplorerVisibilityHierarchyItemByApiArgThunk",
    async (
      body: PostV2UsersMeOrganisationsCurrentResourceExplorerViewsApiArg["body"],
      { dispatch },
    ) => {
      const { postV2UsersMeOrganisationsCurrentResourceExplorerViews } =
        cloudChiprApi.endpoints;

      return await dispatch(
        postV2UsersMeOrganisationsCurrentResourceExplorerViews.initiate(
          { body },
          { fixedCacheKey: createResourceExplorerThunkFixedCacheKey },
        ),
      ).unwrap();
    },
  );
