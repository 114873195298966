import { FC } from "react";
import { Stack, Typography } from "@mui/material";
import { yellow } from "@mui/material/colors";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";

export const SlackIntegrationActionExecutedTitle: FC = () => {
  return (
    <Stack spacing={1} direction="row">
      <AutoAwesomeIcon
        fontSize="small"
        sx={{
          color: yellow["700"],
        }}
      />
      <Typography variant="body1" fontWeight="medium">
        Actions Executed
      </Typography>
    </Stack>
  );
};
