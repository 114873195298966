import { FC } from "react";
import { Stack } from "@mui/material";
import { CostAndUsageSumLiveFilteredTitle } from "./CostAndUsageSumLiveFilteredTitle";
import { CostAndUsageDataGridType } from "./data-grid/utils/types";
import { useAppSelector } from "../../../../../../../../../store/hooks";
import { WidgetContentInvalidState } from "../../common/WidgetContentInvalidState";
import { WidgetNumeralView } from "../../common/view-type/WidgetNumeralView";
import { WidgetNumeralViewItem } from "../../common/view-type/WidgetNumeralViewItem";
import { DateLabelNullable } from "../../../../../../../../../services/cloudchipr.api";
import { getTrendTooltipTitle } from "../../../../../../utils/helpers/trend-tooltip-title/getTrendTooltipTitle";
import { costAndUsageSumSetupPropertyByKeySelector } from "../../../../../../../../../store/dashboards/selectors/setups/cost-and-usage/costAndUsageSumSetupPropertyByKeySelector";

interface CostAndUsageSumWidgetViewProps {
  loading: boolean;
  data: CostAndUsageDataGridType[];
  dateLabel?: DateLabelNullable;
}

export const CostAndUsageSumWidgetView: FC<CostAndUsageSumWidgetViewProps> = ({
  loading,
  data,
  dateLabel,
}) => {
  const filter = useAppSelector(
    costAndUsageSumSetupPropertyByKeySelector("filter"),
  );

  if (filter?.length === 0 && !loading) {
    return (
      <Stack p={2} flex={1}>
        <WidgetContentInvalidState message="Select at least one metric from the list to get started." />
      </Stack>
    );
  }

  return (
    <WidgetNumeralView loading={loading} skeletonCount={6}>
      {data?.map((item) => {
        const tooltipTitle = getTrendTooltipTitle(
          item.trendDetails?.currentDates?.from,
          item.trendDetails?.currentDates?.to,
          item.trendDetails?.previousDates?.from,
          item.trendDetails?.previousDates?.to,
          item?.costAndUsageSumWidgetFilterType,
          dateLabel,
        );

        return (
          <WidgetNumeralViewItem
            key={item.costAndUsageSumWidgetFilterType}
            label={item.label}
            CustomLabel={
              item.costAndUsageSumWidgetFilterType === "live_usage_total_cost"
                ? CostAndUsageSumLiveFilteredTitle
                : undefined
            }
            cost={item.cost}
            postfix={
              item.costAndUsageSumWidgetFilterType === "live_usage_total_cost"
                ? "/mo"
                : undefined
            }
            trend={item.trendDetails?.trend ? item.trendDetails.trend : null}
            itemsCount={data?.length}
            tooltipTitle={tooltipTitle}
            approximate={
              item.costAndUsageSumWidgetFilterType ===
                "forecasted_month_end_cost" ||
              item.costAndUsageSumWidgetFilterType ===
                "forecasted_quarter_end_cost"
            }
          />
        );
      })}
    </WidgetNumeralView>
  );
};
