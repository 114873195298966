import { FC, useCallback } from "react";
import { AutomationNotificationThreshold } from "./threshold/AutomationNotificationThreshold";
import { setAutomationData } from "../../../../../../store/automations/automationsSlice";
import { useAppDispatch, useAppSelector } from "../../../../../../store/hooks";
import { automationThresholdSelector } from "../../../../../../store/automations/selectros/common/fields/automationThresholdSelector";

export const AutomationsThreshold: FC = () => {
  const dispatch = useAppDispatch();

  const threshold = useAppSelector(automationThresholdSelector);

  const thresholdChangeHandler = useCallback(
    (threshold: number | null) => {
      dispatch(setAutomationData({ threshold }));
    },
    [dispatch],
  );

  return (
    <AutomationNotificationThreshold
      minThreshold={threshold}
      onThresholdChange={thresholdChangeHandler}
      // error={errors?.min_threshold}
    />
  );
};
