import { createAsyncThunk } from "@reduxjs/toolkit";
import { enqueueSnackbar } from "notistack";
import { cloudChiprApi } from "../../../services/cloudchipr.api";
import { currentBudgetSelector } from "../selectors/current-budget/currentBudgetSelector";
import { RootState } from "../../store";

export const updateBudgetFromCurrentThunk = createAsyncThunk(
  "budgets/updateBudgetFromCurrent",
  async (_: void, { dispatch, getState }) => {
    const state = getState() as RootState;

    const { patchUsersMeOrganisationsCurrentBudgetsByBudgetId } =
      cloudChiprApi.endpoints;

    const currentBudget = currentBudgetSelector(state);

    if (!currentBudget?.id) {
      return;
    }

    try {
      await dispatch(
        patchUsersMeOrganisationsCurrentBudgetsByBudgetId.initiate({
          "budget-id": currentBudget.id,
          body: {
            name: currentBudget.body.name,
            notifications: currentBudget.body.notifications ?? null,
            thresholds: currentBudget.body.thresholds,
            amount: currentBudget.body.amount,
            email_to: currentBudget.body.email_to,
            view_id: currentBudget["view-id"],
          },
        }),
      ).unwrap();

      enqueueSnackbar(`${currentBudget.body.name} Budget updated!`, {
        variant: "snackbarAlert",
        AlertSnackBarProps: { severity: "success" },
      });

      return "success";
    } catch (e) {
      // @ts-expect-error // todo: api fix
      enqueueSnackbar(e?.data?.message ?? "Something went wrong", {
        variant: "snackbarAlert",
        AlertSnackBarProps: {
          severity: "error",
        },
      });
      return "error";
    }
  },
);
