import { FC } from "react";
import { useAppSelector } from "../../../../../../../../../../../store/hooks";
import { formatDate } from "../../../../../../../../../../../utils/helpers/date-time/datetime-format";
import { TooltipText } from "../../../../../../../../../../common/TooltipText";
import { TrendChip } from "../../../../../../../../../../common/chips/TrendChip";
import { costBreakdownWidgetSetupPrevTotalCostSelector } from "../../../../../../../../../../../store/dashboards/selectors/widgets/cost-breakdown/setup/costBreakdownWidgetSetupPrevTotalCostSelector";

interface CostBreakdownTrendCellProps {
  value: number | null;
}

export const CostBreakdownWidgetSetupTrendCell: FC<
  CostBreakdownTrendCellProps
> = ({ value }) => {
  const previousPeriodTotal = useAppSelector(
    costBreakdownWidgetSetupPrevTotalCostSelector,
  );

  const fromDate = formatDate(
    previousPeriodTotal?.dateDetails?.from_date ?? "",
    { type: "date" },
  );
  const toDate = formatDate(previousPeriodTotal?.dateDetails?.to_date ?? "", {
    type: "date",
  });

  if (value === null) {
    return (
      <TooltipText
        tooltipTitle={`No data for Previous Period - ${fromDate} - ${toDate}`}
      >
        N/A
      </TooltipText>
    );
  }

  return <TrendChip value={value} />;
};
