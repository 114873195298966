import { createAsyncThunk } from "@reduxjs/toolkit";
import { cloudChiprApi } from "../../../../services/cloudchipr.api";

export const getRightsizingRecommendationByIdsThunk = createAsyncThunk(
  "rightsizing/getRightsizingRecommendationByIdsThunk",
  async (
    { accountId, recommendationId }: AccountAndRecommendationsIds,
    { dispatch },
  ) => {
    const {
      getUsersMeAccountsByAccountIdRightSizingRecommendationsAndRecommendationId,
    } = cloudChiprApi.endpoints;

    await dispatch(
      getUsersMeAccountsByAccountIdRightSizingRecommendationsAndRecommendationId.initiate(
        { accountId, recommendationId },
        {
          forceRefetch: true,
        },
      ),
    ).unwrap();
  },
);

type AccountAndRecommendationsIds = {
  accountId: string;
  recommendationId: string;
};
