import { FC, useCallback } from "react";
import { ListItemIcon, ListItemText, MenuItem } from "@mui/material";
import InsertLinkOutlinedIcon from "@mui/icons-material/InsertLinkOutlined";
import { useToggle } from "rooks";
import copy from "copy-to-clipboard";

interface TaskCopyLinkActionMenuItemProps {
  taskId: string;
  onClose(): void;
}

export const TaskCopyLinkActionMenuItem: FC<
  TaskCopyLinkActionMenuItemProps
> = ({ taskId, onClose }) => {
  const url = `${window.location.origin}/task-mgmt?taskId=${taskId}`;
  const [copied, toggleCopied] = useToggle(false);

  const copyHandler = useCallback(async () => {
    copy(url);
    toggleCopied();
    setTimeout(onClose, 1000);
  }, [url, onClose, toggleCopied]);

  return (
    <MenuItem dense onClick={copyHandler} disabled={copied}>
      <ListItemIcon>
        <InsertLinkOutlinedIcon fontSize="small" />
      </ListItemIcon>
      <ListItemText>{copied ? "Copied !" : "Copy Link"}</ListItemText>
    </MenuItem>
  );
};
