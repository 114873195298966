import { FC } from "react";
import { Typography } from "@mui/material";

interface AzureSingleCommandProps {
  role: string;
  scope: string;
  identifier: string;
  servicePrincipal?: string;
  actionType: "create" | "delete";
}

export const AzureSingleCommand: FC<AzureSingleCommandProps> = ({
  role,
  scope,
  identifier,
  servicePrincipal,
  actionType,
}) => {
  const firstLine = `az role assignment ${actionText[actionType]} --assignee  `;

  return (
    <Typography fontFamily="monospace" gutterBottom>
      {firstLine}
      <Typography color="warning.light" component="span" fontFamily="inherit">
        {servicePrincipal}
      </Typography>{" "}
      --role{" "}
      <Typography color="success.light" component="span" fontFamily="inherit">
        "{role}"
      </Typography>{" "}
      --scope "{scope}
      <Typography color="secondary.light" component="span" fontFamily="inherit">
        {identifier}
      </Typography>
      "
    </Typography>
  );
};

const actionText = {
  create: "create",
  delete: "delete",
};
