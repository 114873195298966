import { FC, Fragment, memo, useCallback, useState } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import {
  CircularProgress,
  FormHelperText,
  Grid,
  TextField,
} from "@mui/material";
import { useDebounce } from "rooks";
import { selectMenuItem } from "./SelectMenuItem";
import { JiraAutocompleteOptionType } from "../../../../utils/types/types";
import { usePostUsersMeJiraIntegrationsByIntegrationIdAutoCompleteUsersDataMutation } from "../../../../../../../../../services/cloudchipr.api";

interface AutocompleteFieldProps {
  name: string;
  error?: string;
  integrationId: string;
  optionsUrl: string;
  label: string;
  value?: { id: string };
  setFieldValue(key: string, value: any): void;
}

export const AutocompleteField: FC<AutocompleteFieldProps> = memo(
  ({ value, setFieldValue, name, label, optionsUrl, integrationId, error }) => {
    const [inputValue, setInputValue] = useState("");

    const [trigger, { data: autoCompleteOptions, isLoading }] =
      usePostUsersMeJiraIntegrationsByIntegrationIdAutoCompleteUsersDataMutation(
        { fixedCacheKey: label },
      );

    const getOptionsHandler = useCallback(
      (query: string) => {
        const url = `${optionsUrl || ""}${query || ""}`;

        if (!url || !query) {
          return;
        }

        trigger({ integrationId, body: { auto_complete_url: url } });
      },
      [integrationId, optionsUrl, trigger],
    );

    const getOptionsDebounced = useDebounce(getOptionsHandler, 500);

    const inputChangeHandler = useCallback(
      (event: any) => {
        const value = event.target.value;

        getOptionsDebounced(value);
        setInputValue(value);
      },
      [getOptionsDebounced],
    );

    const changeHandler = useCallback(
      (_: any, option: any) => {
        option?.id && setFieldValue(name, { id: option?.id });
      },
      [setFieldValue, name],
    );

    const renderOption = useCallback(
      (props: any, option: JiraAutocompleteOptionType) =>
        selectMenuItem(option, null, props),
      [],
    );

    const renderInput = useCallback(
      (params: any) => (
        <TextField
          {...params}
          label={label}
          value={inputValue}
          onChange={inputChangeHandler}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <Fragment>
                {isLoading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : (
                  params.InputProps.endAdornment
                )}
              </Fragment>
            ),
          }}
        />
      ),
      [inputValue, label, inputChangeHandler, isLoading],
    );

    const autoCompleteSelectedValue =
      autoCompleteOptions?.find(({ id }) => id === value?.id) ?? emptyValue;

    const noOptionsText =
      inputValue && !autoCompleteOptions?.length && !isLoading
        ? "No Options"
        : "Start typing to get a list of possible matches.";

    return (
      <Fragment>
        <Grid item sm={4}>
          <Autocomplete
            fullWidth
            disablePortal
            openOnFocus
            disableClearable
            noOptionsText={isLoading ? "" : noOptionsText}
            size="small"
            filterOptions={(x) => x}
            value={autoCompleteSelectedValue}
            options={autoCompleteOptions || []}
            getOptionLabel={(option) => option.name}
            onChange={changeHandler}
            renderOption={renderOption}
            renderInput={renderInput}
          />
          {!!error && <FormHelperText error>{error} </FormHelperText>}
        </Grid>

        <Grid item sm={6} />
      </Fragment>
    );
  },
);

const emptyValue = { name: "", id: "", email: "", avatar: "" };
