export const getDailyScheduleTitleAndTooltip = (
  scheduleInterval: number,
  time: string,
) => {
  const interval =
    scheduleInterval !== 1 ? `Every ${scheduleInterval} days` : "Every day";

  return {
    title: "Daily",
    tooltip: `${interval} at ${time}`,
  };
};
