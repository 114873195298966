import { FC, Fragment } from "react";
import { Box, ButtonBase, Icon, Stack } from "@mui/material";
import { FilterTemplateCellPopover } from "./FilterTemplateCellPopover";
import {
  AccountLight,
  ResourceType,
} from "../../../../../../../../../../../services/cloudchipr.api";
import { TypographyWithTooltip } from "../../../../../../../../../../common/TypographyWithTooltip";
import filterTemplateIcon from "../../../../../../../../../../../assets/images/icons/filter_template.svg";
import { useMenuHook } from "../../../../../../../../../../../utils/hooks/useMenu.hook";
import { organisationFilterSetNameByFilterSetIdSelector } from "../../../../../../../../../../../store/filters/selectors/current-filter-sets/organisationFilterSetNameByFilterSetIdSelector";
import { useAppSelector } from "../../../../../../../../../../../store/hooks";

interface FilterTemplateCellProps {
  data: AccountLight;
  resourceType: ResourceType;
}
export const FilterTemplateCell: FC<FilterTemplateCellProps> = ({
  data,
  resourceType,
}) => {
  const { anchor, open, openMenu, closeMenu } = useMenuHook();
  const filterSetName = useAppSelector((state) =>
    organisationFilterSetNameByFilterSetIdSelector(state, data?.filter_set_id),
  );

  return (
    <Fragment>
      <Box overflow="hidden" display="flex">
        <ButtonBase
          onClick={openMenu}
          sx={{
            overflow: "hidden",
          }}
        >
          <Stack
            direction="row"
            spacing={0.5}
            px={1}
            py={0.3}
            bgcolor="grey.200"
            borderRadius={1}
            overflow="hidden"
          >
            <Icon
              sx={{
                filter: "grayscale(1)",
                color: "action",
              }}
            >
              <img src={filterTemplateIcon} alt="filter-template" />
            </Icon>
            <TypographyWithTooltip
              title={filterSetName ?? ""}
              placement="top"
              variant="subtitle2"
              fontWeight={400}
              color="text.primary"
            />
          </Stack>
        </ButtonBase>
      </Box>
      {open && (
        <FilterTemplateCellPopover
          open={open}
          anchor={anchor}
          onClose={closeMenu}
          filterSetId={data?.filter_set_id}
          resourceType={resourceType}
        />
      )}
    </Fragment>
  );
};
