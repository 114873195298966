import { FC } from "react";
import { Box, Typography } from "@mui/material";
import { CommandSnippet } from "../../../../common/connectors/components/CommandSnippet";
import { accessTypeNames } from "../../../../../common/chips/account-access-type-chip/utils/constants/accessTypeNames";
import { useAppSelector } from "../../../../../../store/hooks";
import { gcpAccountProviderDetailsByAccountIdSelector } from "../../../../../../store/accounts/selectors/gcp/gcpAccountProviderDetailsByAccountIdSelector";
import {
  AccountId,
  ProviderAccessType,
} from "../../../../../../services/cloudchipr.api";

interface AccountSwitchModeGCPCLIProps {
  accountId: AccountId;
  providerAccessType: ProviderAccessType;
}

export const AccountSwitchModeGcpCLI: FC<AccountSwitchModeGCPCLIProps> = ({
  accountId,
  providerAccessType,
}) => {
  const providerDetails = useAppSelector((state) => {
    return gcpAccountProviderDetailsByAccountIdSelector(state, accountId);
  });

  const fromReadOnlyToReadWriteCommand = [
    `gcloud projects add-iam-policy-binding ${providerDetails?.project_id} --member serviceAccount:${providerDetails?.service_account} --role "roles/editor"`,
  ];
  const fromReadWriteToReadOnlyCommand = [
    `gcloud projects remove-iam-policy-binding ${providerDetails?.project_id} --member serviceAccount:${providerDetails?.service_account} --role "roles/editor"`,
    `gcloud projects add-iam-policy-binding ${providerDetails?.project_id} --member serviceAccount:${providerDetails?.service_account} --role "roles/viewer"`,
  ];
  const command =
    providerAccessType === "read"
      ? fromReadWriteToReadOnlyCommand
      : fromReadOnlyToReadWriteCommand;

  return (
    <Box>
      <Typography variant="body2" fontWeight="medium" pt={2}>
        To switch to {accessTypeNames[providerAccessType].short} mode, copy and
        paste the following code into your terminal.
      </Typography>
      <CommandSnippet code={command.join("\n")}>
        {command.map((c) => (
          <div key={c}>{c}</div>
        ))}
      </CommandSnippet>
    </Box>
  );
};
