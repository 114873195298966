import { FC, Fragment, useCallback } from "react";
import { useFlag } from "@unleash/proxy-client-react";
import { CostBreakdownWidgetSetupDateRangeSelect } from "../components/side-bar/CostBreakdownWidgetSetupDateRangeSelect";
import { CostBreakdownWidgetSetupGroupingSelect } from "../components/side-bar/CostBreakdownWidgetSetupGroupingSelect";
import { CostBreakdownWidgetViewTypeSelect } from "../components/side-bar/CostBreakdownWidgetViewTypeSelect";
import { WidgetFrequencySelect } from "../../common/toolbar/WidgetFrequencySelect";
import { WidgetsViewsHierarchySelect } from "../../common/widgets-views-hierarchy-select/WidgetsViewsHierarchySelect";
import { WidgetForecastSelect } from "../../common/toolbar/WidgetForecastSelect";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../../store/hooks";
import { resourceExplorerViewByIdLoadingSelector } from "../../../../../../../../../store/resource-explorer/selectors/resource-explorer-by-id/resourceExplorerViewByIdLoadingSelector";
import { costBreakdownSetupPropertyByKeySelector } from "../../../../../../../../../store/dashboards/selectors/setups/cost-breakdown/costBreakdownSetupPropertyByKeySelector";
import { costBreakdownSetupVisualizationPropertyByKeySelector } from "../../../../../../../../../store/dashboards/selectors/setups/cost-breakdown/costBreakdownSetupVisualizationPropertyByKeySelector";
import { CostBreakdownWidgetConfigurations } from "../configurations/CostBreakdownWidgetConfigurations";
import { costBreakdownWidgetSetupForecastedOptionsSelector } from "../../../../../../../../../store/dashboards/selectors/widgets/cost-breakdown/setup/costBreakdownWidgetSetupForecastedOptionsSelector";
import { costBreakdownFrequencyChangeThunk } from "../../../../../../../../../store/dashboards/thunks/widgets/cost-breakdown/setup-change/costBreakdownFrequencyChangeThunk";
import { costBreakdownForecastChangeThunk } from "../../../../../../../../../store/dashboards/thunks/widgets/cost-breakdown/setup-change/costBreakdownForecastChangeThunk";
import { costBreakdownREViewChangeThunk } from "../../../../../../../../../store/dashboards/thunks/widgets/cost-breakdown/setup-change/costBreakdownREViewChangeThunk";

export const CostBreakdownWidgetSetupSidebar: FC = () => {
  const dispatch = useAppDispatch();
  const enableWidgetsSideBarConfigurations = useFlag(
    "EnableWidgetsSideBarConfigurations",
  );

  const viewId = useAppSelector(
    costBreakdownSetupPropertyByKeySelector("viewId"),
  );

  const frequency = useAppSelector(
    costBreakdownSetupPropertyByKeySelector("frequency"),
  );

  const viewType = useAppSelector(
    costBreakdownSetupVisualizationPropertyByKeySelector("chart_type"),
  );
  const forecastOption = useAppSelector(
    costBreakdownSetupPropertyByKeySelector("forecastOption"),
  );

  const viewGettingLoading = useAppSelector((state) =>
    resourceExplorerViewByIdLoadingSelector(state, viewId),
  );

  const forecastedCostsOptions = useAppSelector(
    costBreakdownWidgetSetupForecastedOptionsSelector,
  );

  const frequencyChangeHandler = useCallback(
    (e: any) => {
      dispatch(costBreakdownFrequencyChangeThunk(e.target.value));
    },
    [dispatch],
  );

  const forecastChangeHandler = useCallback(
    (event: any) => {
      dispatch(costBreakdownForecastChangeThunk(event.target.value));
    },
    [dispatch],
  );

  const viewChangeHandler = useCallback(
    (id: string) => {
      dispatch(costBreakdownREViewChangeThunk(id));
    },
    [dispatch],
  );

  if (!viewType) {
    return null;
  }

  return (
    <Fragment>
      <WidgetsViewsHierarchySelect
        selectedItemId={viewId}
        onChange={viewChangeHandler}
      />

      <CostBreakdownWidgetSetupGroupingSelect disabled={viewGettingLoading} />
      <CostBreakdownWidgetSetupDateRangeSelect />
      <WidgetFrequencySelect
        frequency={frequency}
        onChange={frequencyChangeHandler}
        disabled={
          ["pie", "numeral", "table"].includes(viewType) || viewGettingLoading
        }
      />

      {enableWidgetsSideBarConfigurations ? (
        <CostBreakdownWidgetConfigurations />
      ) : (
        <Fragment>
          <CostBreakdownWidgetViewTypeSelect />
          <WidgetForecastSelect
            forecast={forecastOption}
            onChange={forecastChangeHandler}
            options={forecastedCostsOptions}
          />
        </Fragment>
      )}
    </Fragment>
  );
};
