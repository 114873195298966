import { CircularProgress, Stack, Theme } from "@mui/material";
import { FC, useMemo } from "react";
import { usePreviousDifferent } from "rooks";
import { AlertsCostsAnomalyPreviewTableFooter } from "./AlertsCostsAnomalyPreviewTableFooter";
import { alertsCostsAnomalyPreviewTableColumns } from "./alertsCostsAnomalyPreviewTableColumns";
import { useAppSelector } from "../../../../../../../../../../store/hooks";
import { getCostAnomalyPreviewDataGridDataLoadingSelector } from "../../../../../../../../../../store/alerts/selectors/cost-anomaly-alerts/getCostAnomalyPreviewDataGridDataLoadingSelector";
import { DataGrid } from "../../../../../../../../../../storybook/data-grid/DataGrid";
import { costAnomalyPreviewDataGridDataSelector } from "../../../../../../../../../../store/alerts/selectors/cost-anomaly-alerts/costAnomalyPreviewDataGridDataSelector";
import { alertsCostAnomalyDataTimeIntervalSelector } from "../../../../../../../../../../store/alerts/selectors/cost-anomaly-alerts/data/alertsCostAnomalyDataTimeIntervalSelector";

export const AlertsCostsAnomalyPreviewTable: FC = () => {
  const loading = useAppSelector(
    getCostAnomalyPreviewDataGridDataLoadingSelector,
  );
  const gridData = useAppSelector(costAnomalyPreviewDataGridDataSelector);
  const prevData = usePreviousDifferent(gridData);
  const timeInterval = useAppSelector(
    alertsCostAnomalyDataTimeIntervalSelector,
  );

  const data = gridData ?? prevData;

  const tableStyles = useMemo(() => {
    if (data?.length) {
      return {
        ...styles,
        tableContainer: {
          ...styles.tableContainer,
          borderRadius: 1,
        },
      };
    }

    return styles;
  }, [data?.length]);

  return (
    <Stack p={1} position="relative">
      {loading && (
        <Stack
          width="100%"
          height="100%"
          zIndex="appBar"
          fontWeight="bold"
          position="absolute"
          alignItems="center"
          justifyContent="center"
          bgcolor="rgba(255, 255, 255, 0.5)"
        >
          <CircularProgress size={24} color="info" />
        </Stack>
      )}

      <DataGrid
        data={!timeInterval ? [] : (data ?? [])}
        styles={tableStyles}
        columns={alertsCostsAnomalyPreviewTableColumns}
        columnSorting={sortingConfig}
        enableRowsVirtualization={(data?.length ?? 0) > 30}
        Footer={
          !data?.length || !timeInterval
            ? AlertsCostsAnomalyPreviewTableFooter
            : undefined
        }
      />
    </Stack>
  );
};

const sortingConfig = { initialSort: [{ id: "average_cost", desc: true }] };

const styles = {
  table: {
    tableLayout: "fixed",
  },
  tableContainer: {
    border: "1px solid",
    borderColor: "grey.300",
    maxHeight: 250,
    borderTopRightRadius: 4,
    borderTopLeftRadius: 4,
  },
  tableHeaderRow: {
    top: 0,
    position: "sticky",
    bgcolor: "grey.200",
    zIndex: (theme: Theme) => theme.zIndex.appBar - 1,
    "& th": { py: 0.5 },
  },
  tableRow: {
    "& td": { py: 0.75 },
    "&:last-of-type td": {
      border: `none`,
    },
  },
};
