import { RootState } from "../../../../../store";
import { costBreakdownWidgetByWidgetIdSelector } from "../widget-view/costBreakdownWidgetByWidgetIdSelector";

export const costBreakdownWidgetByWidgetIdLoadingSelector = (
  state: RootState,
  widgetId: string,
): boolean => {
  const response = costBreakdownWidgetByWidgetIdSelector({ widgetId })(state);

  return !!response?.isLoading;
};
