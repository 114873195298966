import { FC, Fragment, useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import { useEffectOnceWhen } from "rooks";
import { useFlag } from "@unleash/proxy-client-react";
import { TypographyWithTooltip } from "../../../../../common/TypographyWithTooltip";
import { WithoutAccountsOffHoursDialog } from "../WithoutAccountsOffHoursDialog";
import { useOffHoursPreviewDatesInfoHook } from "../../utils/hooks/useOffHoursPreviewDatesInfo.hook";
import { useDialog } from "../../../../../../utils/hooks/useDialog.hook";
import { OffHoursSchedule } from "../../../../../../services/cloudchipr.api";
import { ScheduleResourcesPreviewDrawer } from "../../../../automations/common/components/resources-preview-old/ScheduleResourcesPreviewDrawer";
import { ScheduleResourcesPreviewDrawerAppBar } from "../../../../automations/common/components/resources-preview-old/components/app-bar/ScheduleResourcesPreviewDrawerAppBar";

interface OffHoursListGridNameCellProps {
  schedule: OffHoursSchedule;
}

export const OffHoursDataGridNameCell: FC<OffHoursListGridNameCellProps> = ({
  schedule,
}) => {
  const [searchParams] = useSearchParams();
  const scheduleIdForPreview = searchParams.get("preview");
  const enableNewAutomations = useFlag("EnableWorkflowAutomationSinglePage");

  const { open, openDialog, closeDialog } = useDialog();

  const withoutAccounts = useMemo(() => {
    return schedule.accounts.every((account) => account.status !== "connected");
  }, [schedule]);

  const editingUrl = enableNewAutomations
    ? `/automations/off-hours/edit/${schedule.id}`
    : `/schedule/off-hours/edit/${schedule.id}`;
  const accountIds = schedule.accounts.map((account) => account.id);

  const { nextRuns, frequency } = useOffHoursPreviewDatesInfoHook({
    timeZone: schedule.time_zone,
    startCron: schedule.start_cron,
    stopCron: schedule.stop_cron,
    nextStartRun: schedule?.metadata?.next_start_run_at,
    nextStopRun: schedule?.metadata?.next_stop_run_at,
  });

  useEffectOnceWhen(
    openDialog,
    schedule.id === scheduleIdForPreview && !withoutAccounts,
  );

  if (withoutAccounts) {
    return (
      <Fragment>
        <TypographyWithTooltip
          title={schedule.name}
          onClick={openDialog}
          sx={{ textDecoration: "underline", cursor: "pointer" }}
        />
        <TypographyWithTooltip variant="body2" title={schedule.description} />

        {open && (
          <WithoutAccountsOffHoursDialog
            onClose={closeDialog}
            offHours={schedule}
          />
        )}
      </Fragment>
    );
  }

  return (
    <Fragment>
      <TypographyWithTooltip
        title={schedule.name}
        color="primary"
        onClick={openDialog}
        sx={{
          textDecoration: "underline",
          textUnderlineOffset: 4,
          cursor: "pointer",
        }}
      />

      <TypographyWithTooltip variant="body2" title={schedule.description} />

      {schedule.filter && (
        <ScheduleResourcesPreviewDrawer
          open={open}
          gracePeriodEnabled={false}
          scheduleId={schedule.id}
          scheduleAction={schedule.action}
          onClose={closeDialog}
          regions={schedule.regions}
          filters={schedule.filter}
          accountIds={accountIds}
          status={schedule.status}
          name={schedule.name}
          editingUrl={editingUrl}
        >
          <ScheduleResourcesPreviewDrawerAppBar
            regions={schedule.regions}
            scheduleId={schedule.id}
            nextRuns={nextRuns}
            frequency={frequency}
            accountIds={accountIds}
            filters={schedule.filter}
          />
        </ScheduleResourcesPreviewDrawer>
      )}
    </Fragment>
  );
};
