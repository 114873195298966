import { largestCostChangesSetupSelector } from "./largestCostChangesSetupSelector";
import { RootState } from "../../../../store";
import { LargestCostChangesSetupType } from "../../../utils/types/setups/largestCostChangesSetupType";

export const largestCostChangesSetupPropertyByKeySelector =
  (key: keyof LargestCostChangesSetupType) =>
  (state: RootState): any => {
    const setup = largestCostChangesSetupSelector(state);

    if (!setup) {
      return null;
    }

    return setup[key];
  };
