import { liveUsageMgmtResourceViewIsWithoutFiltersSelector } from "./liveUsageMgmtResourceViewIsWithoutFiltersSelector";
import { liveUsageMgmtPostUsersMeV2OrganisationsCurrentByTypeResourcesSelector } from "./liveUsageMgmtPostUsersMeV2OrganisationsCurrentByTypeResourcesSelector";
import { RootState } from "../../../store";
import { ResourceType } from "../../../../services/cloudchipr.api";
import { liveUsageMgmtFilterTemplatesEnabledSelector } from "../store-selectors/liveUsageMgmtFilterTemplatesEnabledSelector";
import { getResourceTypeDataWithoutFiltersFixedCacheKey } from "../../thunks/resources/getLiveUsageMgmtResourcesWithOutFiltersByProviderThunk";

export const liveUsageMgmtResourceTypeDataLoadingSelector = (
  state: RootState,
  resourceType: ResourceType,
) => {
  const withoutFilters = liveUsageMgmtResourceViewIsWithoutFiltersSelector(
    state,
    resourceType,
  );
  const filterTemplatesEnabled =
    liveUsageMgmtFilterTemplatesEnabledSelector(state);

  const fixedCacheKey =
    withoutFilters || !filterTemplatesEnabled
      ? getResourceTypeDataWithoutFiltersFixedCacheKey(resourceType)
      : resourceType;

  return liveUsageMgmtPostUsersMeV2OrganisationsCurrentByTypeResourcesSelector(
    fixedCacheKey,
  )(state)?.isLoading;
};
