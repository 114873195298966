import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  CategoryWithCosts,
  DimensionWithCosts,
} from "../../../../../services/cloudchipr.api";
import { RootState } from "../../../../store";
import { dimensionIdSelector } from "../../../selectors/slice-data/dimensionIdSelector";
import { dimensionCategoriesEntriesSelector } from "../../../selectors/slice-data/dimensionCategoriesEntriesSelector";
import { isDeepEqual } from "../../../../../utils/is-deep-equal";
import { updateCategoryByIdThunk } from "../../categories/updateCategoryByIdThunk";

export const findAndUpdateChangedCategoriesThunk = createAsyncThunk(
  "dimensions/findAndUpdateChangedCategories",
  async (
    {
      originalCategoriesByIds,
      originalDimension,
    }: {
      originalCategoriesByIds: Record<string, CategoryWithCosts>;
      originalDimension: DimensionWithCosts;
    },
    { dispatch, getState },
  ) => {
    const state = getState() as RootState;
    const dimensionId = dimensionIdSelector(state);
    const dimensionCategories = dimensionCategoriesEntriesSelector(state);

    const categoriesToUpdate = dimensionCategories.filter(([id, category]) => {
      if (!originalCategoriesByIds[id]) {
        return false;
      }

      const original = originalDimension?.categories.find((category) => {
        return category.id === id;
      });

      if (original) {
        return !isDeepEqual(original, category);
      } else {
        return false;
      }
    });

    if (!dimensionId || !categoriesToUpdate?.length) {
      return;
    }

    const promises = categoriesToUpdate.map(([categoryId, category]) => {
      return dispatch(
        updateCategoryByIdThunk({
          dimensionId,
          categoryId,
          name: category.name,
          filter: {
            filter_tree: category.filters.filter_tree,
          },
        }),
      );
    });

    await Promise.all(promises);
  },
);
