import { useCallback, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";
import jwtDecode from "jwt-decode";
import { setAuthToken } from "../../../store/auth/authSlice";
import { authTokenSelector } from "../../../store/auth/authSelectors";
import { useAppSelector } from "../../../store/hooks";

/**
 * This function sets a timeout to trigger the token refresh 1 minute before the token actually expires.
 */
export const useRefreshTokenHook = () => {
  const { getAccessTokenSilently } = useAuth0();
  const dispatch = useDispatch();
  const accessToken = useAppSelector(authTokenSelector);
  const timeoutRef = useRef<number | null>(null);

  const refreshTokenIfNeeded = useCallback(() => {
    const expirationTime = getTokenExpirationTime(accessToken ?? "");
    if (expirationTime !== null) {
      const refreshTimeout = expirationTime - 60000; // Refresh 1 minute before expiration

      if (timeoutRef.current !== null) {
        clearTimeout(timeoutRef.current);
      }
      timeoutRef.current = window.setTimeout(() => {
        getAccessTokenSilently({
          ignoreCache: true,
        })
          .then((newToken) => {
            dispatch(setAuthToken(newToken));
          })
          .catch((error) => console.error("Error refreshing token:", error));
      }, refreshTimeout);
    } else {
      getAccessTokenSilently()
        .then((newToken) => {
          dispatch(setAuthToken(newToken));
        })
        .catch((error) => console.error("Error refreshing token:", error));
    }
  }, [accessToken, getAccessTokenSilently, dispatch]);

  useEffect(() => {
    refreshTokenIfNeeded();
    const refId = timeoutRef?.current;

    return () => {
      if (refId) {
        clearTimeout(refId);
      }
    };
  }, [refreshTokenIfNeeded, timeoutRef]);
};

const getTokenExpirationTime = (token: string) => {
  const decoded: any = jwtDecode(token);
  if (!decoded) {
    return null;
  }
  return decoded.exp * 1000 - Date.now();
};
