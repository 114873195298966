import { RootState } from "../../../../store";
import {
  FilterSet,
  ProviderType,
  ResourceFilters,
  ResourceType,
} from "../../../../../services/cloudchipr.api";
import { resourceGroups } from "../../../../../utils/constants/resources/resourceGroups";
import { filterTemplatesSelector } from "../filterTemplatesSelector";

// todo: we have the same selector for current account, that can be replaced with this
export const defaultFilterTemplateFiltersByAccountIdSelector = (
  state: RootState,
  provider?: ProviderType,
): Record<ResourceType, ResourceFilters> | undefined => {
  if (!provider) {
    return;
  }

  const data = filterTemplatesSelector(state, provider);

  const filterSet: FilterSet | undefined = data?.find((filter_set) => {
    return filter_set.is_default;
  });

  if (!filterSet) {
    return undefined;
  }

  const resourceTypes = provider ? resourceGroups.get(provider) : [];

  return filterSet?.filters.reduce(
    (acc, resourceFilter) => {
      const resourceType = resourceFilter.type;
      const data = resourceTypes?.find((item) => item.value === resourceType);

      acc[resourceType] = resourceFilter;

      if (data?.actionableResourceTypes?.length) {
        data?.actionableResourceTypes?.forEach((rt) => {
          if (acc[rt]) {
            return;
          }

          acc[rt] = { ...resourceFilter, type: rt };
        });
      }

      return acc;
    },
    {} as Record<ResourceType, ResourceFilters>,
  );
};
