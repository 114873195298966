import { FC } from "react";
import { Stack, Tooltip } from "@mui/material";
import WarningAmberRoundedIcon from "@mui/icons-material/WarningAmberRounded";
import { FilterTrigger } from "../../../../../../common/select/dropdown-select/components/FilterTrigger";
import { useAppSelector } from "../../../../../../../store/hooks";
import { rightsizingRecommendationsStatusesGroupedSelector } from "../../../../../../../store/recommendations/selectors/rightsizing/statuses/rightsizingRecommendationsStatusesGroupedSelector";
import { FilterTriggerComponentProps } from "../../../../../../common/select/dropdown-select/utils/types/filterTriggerComponentProps";

interface RightsizingRecommendationsFilterTriggerProps
  extends FilterTriggerComponentProps {
  tooltip: string;
}

export const RightsizingRecommendationsFilterTrigger: FC<
  RightsizingRecommendationsFilterTriggerProps
> = ({ tooltip, ...props }) => {
  const selectedLength = props.selectedValues.length
    ? `(${props.selectedValues.length})`
    : "";

  const chipLabel = `${props.label} ${
    props.options.length && props.selectedValues.length === props.options.length
      ? "(All)"
      : selectedLength
  }`;

  const statuses = useAppSelector(
    rightsizingRecommendationsStatusesGroupedSelector,
  );

  const warning = Object.values(statuses).some(
    ({ missing_permission, disabled }) =>
      !!(disabled.length || missing_permission.length),
  );

  return (
    <FilterTrigger
      {...props}
      label={
        <Tooltip placement="top" title={warning ? tooltip : ""}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            {warning && (
              <WarningAmberRoundedIcon
                fontSize="small"
                color="warning"
                sx={{ mr: 0.5 }}
              />
            )}

            {chipLabel}
          </Stack>
        </Tooltip>
      }
    />
  );
};
