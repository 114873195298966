import { recommendationDismissPeriodLabels } from "./recommendationDismissPeriodLabels";
import { DismissPeriod } from "../../../../../services/cloudchipr.api";

interface RecommendationMessagesArgs {
  action: "dismiss" | "restore";
  multiple: boolean;
  period?: DismissPeriod | null;
  resource?: boolean;
}

export const recommendationMessages = ({
  action,
  period,
  resource,
  multiple,
}: RecommendationMessagesArgs) => {
  let key = action;

  if (resource) {
    key += "_resource";
  } else {
    key += `_${multiple ? "multiple" : "single"}`;

    if (period && period !== "forever") {
      key += "_period";
    }
  }

  return messages.get(key)?.(period);
};

const messages = new Map<string, (period?: DismissPeriod | null) => string>([
  [
    "dismiss_single",
    () => "Please confirm to dismiss the selected recommendation.",
  ],
  [
    "dismiss_multiple",
    () => "Please confirm to dismiss the selected recommendations.",
  ],
  [
    "dismiss_resource",
    () =>
      "If dismissed, Cloudchipr will not display any recommendations for the following resource. No worries, you can always restore it.",
  ],
  [
    "restore_single",
    () => "Please confirm to restore the selected recommendation.",
  ],
  [
    "restore_multiple",
    () => "Please confirm to restore the selected recommendations.",
  ],
  [
    "restore_resource",
    () =>
      "Please confirm to restore all recommendations for the following resource.",
  ],
  [
    "dismiss_single_period",
    (period) =>
      `Please confirm to dismiss the selected recommendation${
        period ? " " + recommendationDismissPeriodLabels[period] : ""
      }.`,
  ],
  [
    "dismiss_multiple_period",
    (period) =>
      `Please confirm to dismiss the selected recommendations${
        period ? " " + recommendationDismissPeriodLabels[period] : ""
      }.`,
  ],
]);
