import { FC, useCallback } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { IconButton, Stack, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../store/hooks";
import { userRoleDataValidationSelector } from "../../../../../../../../store/user/selectors/roles/userRoleDataValidationSelector";
import { createCustomUserRoleThunk } from "../../../../../../../../store/user/thunks/roles/createCustomUserRoleThunk";
import { userRoleCreationLoadingSelector } from "../../../../../../../../store/user/selectors/roles/loading/userRoleCreationLoadingSelector";
import { getOrgCurrentRolesThunk } from "../../../../../../../../store/user/thunks/roles/getOrgCurrentRolesThunk";

interface CreateRoleDrawerHeaderProps {
  onClose(): void;
}

export const CreateRoleDrawerHeader: FC<CreateRoleDrawerHeaderProps> = ({
  onClose,
}) => {
  const dispatch = useAppDispatch();
  const isValidate = useAppSelector(userRoleDataValidationSelector);
  const loading = useAppSelector(userRoleCreationLoadingSelector);

  const saveClickHandler = useCallback(async () => {
    const response = await dispatch(createCustomUserRoleThunk());
    dispatch(getOrgCurrentRolesThunk());
    if (response) {
      onClose();
    }
  }, [dispatch, onClose]);

  return (
    <Stack
      justifyContent="space-between"
      direction="row"
      alignItems="center"
      spacing={1}
      px={2}
      py={1.5}
    >
      <Typography fontWeight="medium" variant="body1">
        Add Custom Role
      </Typography>
      <Stack spacing={1} direction="row" alignItems="center">
        <LoadingButton
          variant="contained"
          disabled={isValidate}
          onClick={saveClickHandler}
          loading={loading}
        >
          Save
        </LoadingButton>

        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </Stack>
    </Stack>
  );
};
