import { FC } from "react";
import { usersTableColumns } from "./usersTableColumns";
import { UsersTableToolbar } from "./UsersTableToolbar";
import { useGetV2UsersMeOrganisationsCurrentUsersQuery } from "../../../../../../services/cloudchipr.api";
import { DataGrid } from "../../../../../../storybook/data-grid/DataGrid";

export const UsersList: FC = () => {
  const { data } = useGetV2UsersMeOrganisationsCurrentUsersQuery({});

  return (
    <DataGrid
      styles={styles}
      toolbar={toolbar}
      data={data ?? []}
      columns={usersTableColumns}
      columnSorting={sortingConfig}
    />
  );
};

const styles = {
  table: {
    "& th,td": { py: 1.5, pl: 2 },
  },
  tableContainer: {
    bgcolor: "white",
    borderRadius: 2,
    border: "1px solid",
    borderColor: "grey.300",
  },
};

const sortingConfig = { initialSort: [{ id: "email", desc: true }] };
const toolbar = { renderToolbar: UsersTableToolbar };
