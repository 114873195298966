import { Select } from "@mui/material";
import { FC, useCallback } from "react";

import { AlertsCostsAnomalyThresholdUnitSelectItem } from "./AlertsCostsAnomalyThresholdUnitSelectItem";
import { costsAnomalyThresholdDataByTypeV2 } from "../../../utils/constants/constants";
import { CostAnomalyAlertUnitType } from "../../../../../../../../../../store/alerts/utils/types/types";

interface AlertsCostsAnomalyThresholdSelectProps {
  unit: CostAnomalyAlertUnitType;
  onChange(value: CostAnomalyAlertUnitType, index: number): void;
  index: number;
  optionDisabled: boolean;
}

export const AlertsCostsAnomalyThresholdUnitSelect: FC<
  AlertsCostsAnomalyThresholdSelectProps
> = ({ unit, onChange, index, optionDisabled }) => {
  const renderValue = useCallback((value: string) => {
    return (
      costsAnomalyThresholdDataByTypeV2.get(value as CostAnomalyAlertUnitType)
        ?.title ?? ""
    );
  }, []);

  const getValueChangeHandler = useCallback(
    (type: CostAnomalyAlertUnitType) => {
      return () => {
        if (unit !== type && optionDisabled) {
          return;
        }

        onChange(type, index);
      };
    },
    [unit, index, optionDisabled, onChange],
  );

  return (
    <Select
      value={unit}
      size="small"
      renderValue={renderValue}
      sx={{
        minWidth: 240,
      }}
    >
      <AlertsCostsAnomalyThresholdUnitSelectItem
        type="percent"
        disabled={unit !== "percent" && optionDisabled}
        selected={unit === "percent"}
        onClick={getValueChangeHandler("percent")}
      />
      <AlertsCostsAnomalyThresholdUnitSelectItem
        type="cost"
        disabled={unit !== "cost" && optionDisabled}
        selected={unit === "cost"}
        onClick={getValueChangeHandler("cost")}
      />
    </Select>
  );
};
