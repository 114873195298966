import { createDraftSafeSelector } from "@reduxjs/toolkit";
import { resourceExplorerChartDataSelector } from "./resourceExplorerChartDataSelector";
import { convertCostToPercent } from "./utils/helpers/convertCostToPercent";
import { resourceExplorerChartBaseSelector } from "../../resourceExplorerChartBaseSelector";

export const resourceExplorerChartDataByBaseSelector = createDraftSafeSelector(
  [resourceExplorerChartDataSelector, resourceExplorerChartBaseSelector],
  (chartData, base) => {
    if (base === "cost" || !chartData) {
      return chartData;
    }

    return convertCostToPercent(chartData);
  },
);
