import { liveUsageMgmtSelectedRowIdsWithHierarchyByResourceTypeSelector } from "./liveUsageMgmtSelectedRowIdsWithHierarchyByResourceTypeSelector";
import { liveUsageMgmtResourceTypeDataWithChildrenSelector } from "./liveUsageMgmtResourceTypeDataWithChildrenSelector";
import { RootState } from "../../../store";
import { ResourceType } from "../../../../services/cloudchipr.api";
import { ResourceDataType } from "../../utils/types/types";
import { getFirstParentInHierarchyResourceType } from "../../../../utils/helpers/resources/getFirstParentInHierarchyResourceType";

export const liveUsageMgmtSelectedResourcesByResourceTypeSelector = (
  state: RootState,
  resourceType: ResourceType,
): ResourceDataType[] | undefined => {
  const parentResourceType =
    getFirstParentInHierarchyResourceType(resourceType);

  const selectedResources =
    liveUsageMgmtSelectedRowIdsWithHierarchyByResourceTypeSelector(
      state,
      resourceType,
    );

  const selectedResourceIds = selectedResources
    ? Object.keys(selectedResources)
    : [];
  const allResources = liveUsageMgmtResourceTypeDataWithChildrenSelector(
    state,
    parentResourceType ?? resourceType,
  );

  return allResources?.filter(({ resource }) =>
    selectedResourceIds?.includes(resource?.id),
  );
};
