import { commitmentUtilizationWidgetSetupDataPayloadSelector } from "./commitmentUtilizationWidgetSetupDataPayloadSelector";
import { cloudChiprApi } from "../../../../../../services/cloudchipr.api";
import { RootState } from "../../../../../store";

export const endpoint =
  cloudChiprApi.endpoints
    .getUsersMeOrganisationsCurrentWidgetsCommitmentsUtilization.select;

export const commitmentUtilizationWidgetSetupEndpointSelector = (
  state: RootState,
) => {
  const payload = commitmentUtilizationWidgetSetupDataPayloadSelector(state);
  if (!payload) {
    return;
  }

  return endpoint(payload)(state);
};
