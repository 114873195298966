import { AccountStatus } from "../../../../../../services/cloudchipr.api";

export const accountStatusNames = new Map<AccountStatus, string>([
  ["initial_pulling", "Analyzing data"],
  ["connected", "Connected"],
  ["failed", "Failed"],
  ["deleted", "Deleted"],
  ["deleting", "Deleting"],
  ["deactivated", "Deactivated"],
  ["connected_for_billing", "Connected for billing"],
  ["inactive", "Inactive"],
]);
