import { deleteDashboardHierarchyFolderThunkFixedCacheKey } from "../../../thunks/common/deleteDashboardFolderThunk";
import { RootState } from "../../../../store";
import { cloudChiprApi } from "../../../../../services/cloudchipr.api";

const selector =
  cloudChiprApi.endpoints.deleteUsersMeOrganisationsCurrentDashboardFoldersByFolderId.select(
    deleteDashboardHierarchyFolderThunkFixedCacheKey,
  );

export const deleteDashboardHierarchyFolderLoadingSelector = (
  state: RootState,
): boolean => {
  return !!selector(state)?.isLoading;
};
