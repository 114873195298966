import { resourceExplorerDataSelector } from "./resourceExplorerDataSelector";
import { RootState } from "../../../../store";
import { Dates } from "../../../../../services/cloudchipr.api";

export const resourceExplorerDatesSelector = (
  state: RootState,
): Dates | undefined => {
  const data = resourceExplorerDataSelector(state);

  return data?.dates;
};
