import { cloudChiprApi } from "../../../../../services/cloudchipr.api";
import { RootState } from "../../../../store";
import { createWidgetFixedCacheKey } from "../../../../../components/pages/dashboard/utils/constants/fixedCacheKeys";

const selector =
  cloudChiprApi.endpoints
    .postUsersMeOrganisationsCurrentDashboardsByDashboardIdWidgets.select;

export const createWidgetLoadingSelector = (
  state: RootState,
): boolean | undefined => {
  return !!selector(createWidgetFixedCacheKey)(state)?.isLoading;
};
