import { createDraftSafeSelector } from "@reduxjs/toolkit";
import { connectedAccountsGroupedByProviderSelector } from "./connectedAccountsGroupedByProviderSelector";
import { accountSelectionOptionsCombiner } from "../../combiners/accountSelectionOptionsCombiner";
import { accountsGroupedByProviderAndOrgCombiner } from "../../combiners/accountsGroupedByProviderAndOrgCombiner";

export const connectedAccountsGroupedByProviderAndOrganization =
  createDraftSafeSelector(
    [connectedAccountsGroupedByProviderSelector],
    (accounts) => {
      const accountsGroupedByProviderAndOrg =
        accountsGroupedByProviderAndOrgCombiner(accounts);

      return accountSelectionOptionsCombiner(accountsGroupedByProviderAndOrg);
    },
  );
