import { enqueueSnackbar } from "notistack";
import { createAsyncThunk } from "@reduxjs/toolkit";

import { setNavigationResourceExplorer } from "../../../navigation/navigationSlice";
import { transformHierarchyDataToTreeItems } from "../../../navigation/utils/helpers/transformHierarchyDataToTreeItems";
import { cloudChiprApi } from "../../../../services/cloudchipr.api";

export const getResourceExplorerVisibilityHierarchyThunk = createAsyncThunk(
  "resourceExplorer/getResourceExplorerVisibilityHierarchy",
  async (_, { dispatch }) => {
    const { getV2UsersMeOrganisationsCurrentResourceExplorerHierarchy } =
      cloudChiprApi.endpoints;

    try {
      const response = await dispatch(
        getV2UsersMeOrganisationsCurrentResourceExplorerHierarchy.initiate(
          {},
          { forceRefetch: true },
        ),
      ).unwrap();

      if (response?.visible_only_to_me) {
        dispatch(
          setNavigationResourceExplorer({
            data:
              transformHierarchyDataToTreeItems(response.visible_only_to_me) ??
              [],
            type: "visible_only_to_me",
          }),
        );
      }
      if (response?.visible_to_everyone) {
        dispatch(
          setNavigationResourceExplorer({
            data:
              transformHierarchyDataToTreeItems(response.visible_to_everyone) ??
              [],
            type: "visible_to_everyone",
          }),
        );
      }
    } catch (e) {
      // @ts-expect-error TODO: return to this after adding error type
      const errMessage = e?.data?.message || "Something went wrong";
      enqueueSnackbar(errMessage, {
        variant: "snackbarAlert",
        AlertSnackBarProps: {
          severity: "error",
        },
      });
    }
  },
);
