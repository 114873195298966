import { ChangeEvent, FC, useCallback } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";
import { enqueueSnackbar } from "notistack";
import { LoadingButton } from "@mui/lab";
import {
  useGetUsersMeCategoriesQuery,
  usePutUsersMeCategoriesMutation,
} from "../../../../../../services/cloudchipr.api";
import { DialogTitleClosable } from "../../../../../common/dialog-utils/DialogTitleClosable";
import { useAppSelector } from "../../../../../../store/hooks";
import { categoryNamesAlphabeticallySortedSelector } from "../../../../../../store/categories/selectors/categoryNamesAlphabeticallySortedSelector";

interface DuplicateCategoryGroupDialogProps {
  open: boolean;
  name: string;
  onClose(): void;
}

const createValidationSchema = (groupedCategoryNames: string[]) => {
  return yup.object({
    name: yup
      .string()
      .test("name", "Category Group name is already in use.", (value) => {
        return !groupedCategoryNames.some((name) => {
          return name.trim().toLowerCase() === value?.trim()?.toLowerCase();
        });
      })
      .required("Name is required"),
  });
};

export const DuplicateCategoryGroupDialog: FC<
  DuplicateCategoryGroupDialogProps
> = ({ open, onClose, name }) => {
  const groupedCategoryNames = useAppSelector(
    categoryNamesAlphabeticallySortedSelector,
  );
  const [duplicateCategory, { isLoading: creating }] =
    usePutUsersMeCategoriesMutation();

  const { refetch } = useGetUsersMeCategoriesQuery();

  const { values, handleSubmit, setValues, isValid, resetForm, errors } =
    useFormik({
      initialValues: {
        name: `${name} (copy)`,
      },
      validationSchema: createValidationSchema(groupedCategoryNames),
      validateOnMount: true,
      onSubmit: async (values) => {
        try {
          await duplicateCategory({
            body: {
              duplicate_category_group_name: values.name,
              category_group_name: name,
            },
          }).unwrap();

          enqueueSnackbar("Category Group successfully duplicated.", {
            variant: "snackbarAlert",
            AlertSnackBarProps: { severity: "success" },
          });

          await refetch();
          closeHandler();
        } catch (e) {
          // @ts-expect-error TODO: api fix
          const errMessage = e?.data?.message || "Something went wrong";
          enqueueSnackbar(errMessage, {
            variant: "snackbarAlert",
            AlertSnackBarProps: {
              severity: "error",
            },
          });
        }
      },
    });

  const closeHandler = useCallback(() => {
    resetForm();
    onClose();
  }, [onClose, resetForm]);

  const nameChangeHandler = useCallback(
    (event: ChangeEvent<HTMLInputElement>) =>
      setValues({ name: event.target.value }),
    [setValues],
  );

  return (
    <Dialog fullWidth maxWidth="sm" open={open}>
      <DialogTitleClosable title="Duplicate Category Group" onClose={onClose} />

      <form onSubmit={handleSubmit}>
        <DialogContent dividers>
          <Typography variant="body2">Name</Typography>

          <TextField
            autoFocus
            fullWidth
            size="small"
            placeholder="Type a duplicated category group name"
            value={values.name}
            error={!isValid}
            sx={{ mt: 0.5 }}
            onChange={nameChangeHandler}
            type="text"
            helperText={errors.name}
          />
        </DialogContent>

        <DialogActions sx={{ py: 2, px: 3 }}>
          <Button onClick={closeHandler} color="tertiary">
            Cancel
          </Button>

          <LoadingButton
            type="submit"
            loading={creating}
            disabled={!isValid}
            variant="contained"
          >
            Duplicate
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};
