import { Stack, Typography } from "@mui/material";
import { ColumnSetupType } from "../../../../../../../../../../../storybook/data-grid/utils/types/types";
import { ReservationsCoverageDataWithId } from "../../../../../../../../common/utils/types";
import { money } from "../../../../../../../../../../../utils/numeral/money";
import { HeaderCellWidthDateTooltip } from "../../../../../../../../common/components/data-grid/cells/HeaderCellWidthDateTooltip";

export const uncoveredUsageColumn: ColumnSetupType<ReservationsCoverageDataWithId> =
  {
    minSize: 220,
    accessorKey: "uncovered_usage",
    header: () => (
      <Stack alignItems="end" flex={1}>
        <HeaderCellWidthDateTooltip title="Not Covered (On-Demand)" />
      </Stack>
    ),
    meta: { headerTitle: "Not Covered (On-Demand)" },
    cell: ({ row }) => (
      <Typography align="right" pr={4} variant="body2">
        {money(row.original.uncovered_usage)}
      </Typography>
    ),
  };
