import { FC } from "react";
import { Stack } from "@mui/material";
import { indigo } from "@mui/material/colors";
import c8rLogo from "../../../assets/images/logos/c8r-logo.svg";
import { TypographyWithTooltip } from "../TypographyWithTooltip";

export const CloudChiprCell: FC = () => {
  return (
    <Stack direction="row" alignItems="center" spacing={1}>
      <Stack p={0.5} bgcolor={indigo[50]} borderRadius={10}>
        <img src={c8rLogo} alt="C8R-logo" style={{ width: 20 }} />
      </Stack>
      <TypographyWithTooltip variant="inherit" title="Cloudchipr" />
    </Stack>
  );
};
