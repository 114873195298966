import { createDraftSafeSelector } from "@reduxjs/toolkit";
import { commitmentUtilizationWidgetSetupDataSelector } from "./commitmentUtilizationWidgetSetupDataSelector";
import { commitmentUtilizationSetupVisualizationPropertyByKeySelector } from "../../../setups/commitment-utilization/commitmentUtilizationSetupVisualizationPropertyByKeySelector";
import { ChartDataType } from "../../../../../../storybook/charts/multi-type-chart/utils/types/types";
import { generateCommitmentUtilizationWidgetChartData } from "../utils/helpers/generateCommitmentUtilizationWidgetChartData";

export const commitmentUtilizationWidgetSetupChartDataSelector =
  createDraftSafeSelector(
    [
      commitmentUtilizationWidgetSetupDataSelector,
      commitmentUtilizationSetupVisualizationPropertyByKeySelector(
        "chart_base_type",
      ),
    ],
    (data, chartBase): ChartDataType[] | undefined => {
      const { groups } = data ?? {};

      return generateCommitmentUtilizationWidgetChartData(groups, chartBase);
    },
  );
