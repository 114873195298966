import { FC } from "react";
import { Alert, Link } from "@mui/material";
import { blue } from "@mui/material/colors";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";

interface MissingPermissionsAlertButtonProps {
  title: string;
  onOpen(): void;
}

export const MissingPermissionsAlertButton: FC<
  MissingPermissionsAlertButtonProps
> = ({ title, onOpen }) => {
  return (
    <Alert
      severity="info"
      variant="outlined"
      icon={<WarningAmberIcon fontSize="medium" />}
      sx={{ bgcolor: blue[50], py: 0, alignItems: "center" }}
    >
      <Link component="button" variant="body2" color="primary" onClick={onOpen}>
        {title}
      </Link>
    </Alert>
  );
};
