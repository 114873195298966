import { applyRecommendationTagFilter } from "./applyRecommendationTagFilter";
import { Tags } from "../../../../services/cloudchipr.api";
import {
  TagsFilter,
  TagsFilterSet,
} from "../../../../components/common/select/tag-filter-dropdown-select/utils/types";

export const filterRecommendationByTagsFilter = (
  tagsFilter: {
    combinationOperator: TagsFilter["combinationOperator"];
    filter: TagsFilterSet;
  },
  resourceTags: Tags,
) => {
  const { filter, combinationOperator } = tagsFilter;

  if (!filter.size) {
    return true;
  }

  const resourceTagsMap = resourceTags.reduce((result, tag) => {
    result.set(tag.key, tag.value);
    return result;
  }, new Map<string, string>());

  const results: boolean[] = [];
  filter.forEach((filterItem, tagFilterKey) => {
    if (!filterItem) {
      return false;
    }

    const { operator, values } = filterItem;

    results.push(
      !!applyRecommendationTagFilter({
        operator,
        tagFilterKey,
        resourceTagsMap,
        tagFilterValues: values,
      }),
    );
  });

  return combinationOperator === "and"
    ? results.every(Boolean)
    : results.some(Boolean);
};
