import { liveUsageMgmtResourceTypeDataSelector } from "./liveUsageMgmtResourceTypeDataSelector";
import { liveUsageMgmtResourceViewIsWithoutFiltersSelector } from "./liveUsageMgmtResourceViewIsWithoutFiltersSelector";
import { RootState } from "../../../store";
import { ResourceType } from "../../../../services/cloudchipr.api";
import { ResourceDataType } from "../../utils/types/types";
import { getResourceTypeDataWithoutFiltersFixedCacheKey } from "../../thunks/resources/getLiveUsageMgmtResourcesWithOutFiltersByProviderThunk";
import { liveUsageMgmtFilterTemplatesEnabledSelector } from "../store-selectors/liveUsageMgmtFilterTemplatesEnabledSelector";

export const liveUsageMgmtResourceTypeDataResourcesSelector = (
  state: RootState,
  resourceType: ResourceType,
): ResourceDataType[] | null => {
  const withoutFilters = liveUsageMgmtResourceViewIsWithoutFiltersSelector(
    state,
    resourceType,
  );
  const filterTemplatesEnabled =
    liveUsageMgmtFilterTemplatesEnabledSelector(state);

  const fixedCacheKey =
    withoutFilters || !filterTemplatesEnabled
      ? getResourceTypeDataWithoutFiltersFixedCacheKey(resourceType)
      : resourceType;

  return (
    liveUsageMgmtResourceTypeDataSelector(state, fixedCacheKey)?.resources ||
    null
  );
};
