import { useGetWidgetsCostBreakdownByWidgetIdFromAccountServiceQuery } from "../../../../../services/cloudchipr-account-service.api";
import { useGetWidgetsCostBreakdownByWidgetIdQuery } from "../../../../../services/cloudchipr-authless.api";
import { UnauthorizedWidgetHookArguments } from "../types/types";
import { covertDatesToQueryParam } from "../../../../common/date-range-picker/utils/helpers/covertDatesToQueryParam";

export function useUnauthorizedCostBreakdownHook({
  widgetId,
  organisationId,
  categoryId,
  overrideQueryDates,
}: UnauthorizedWidgetHookArguments) {
  const accountServiceResponse =
    useGetWidgetsCostBreakdownByWidgetIdFromAccountServiceQuery(
      { widgetId, organisationId: organisationId ?? "" },
      { skip: !organisationId, refetchOnMountOrArgChange: true },
    );

  const datesFormQuery = covertDatesToQueryParam(overrideQueryDates ?? {});
  const apiServiceResponse = useGetWidgetsCostBreakdownByWidgetIdQuery(
    { widgetId: widgetId, categoryId, ...datesFormQuery },
    { skip: !categoryId, refetchOnMountOrArgChange: true },
  );

  return !accountServiceResponse.isUninitialized
    ? accountServiceResponse
    : apiServiceResponse;
}
