import { Row } from "@tanstack/react-table";
import { ResourceDataType } from "../../../../../../../../../../../store/live-usage-mgmt/utils/types/types";

type ExpandedIdsType = { [id in string]: boolean };

export const getAllExpandedRowsIds = (
  rows: Row<ResourceDataType>[],
  open: boolean,
) => {
  const expandedIds: ExpandedIdsType = {};

  const recursion = (
    row: ResourceDataType,
    expandedIds: ExpandedIdsType,
    open: boolean,
  ) => {
    expandedIds[row.id] = true;

    if (row?.child_resource?.resources) {
      row.child_resource.resources.forEach((row: ResourceDataType) =>
        recursion(row, expandedIds, open),
      );
    }
  };

  rows.forEach((row) => {
    recursion(row.original, expandedIds, open);
  });

  return expandedIds;
};
