import { FC, useCallback } from "react";
import { Typography } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../../../../store/hooks";
import { useDeleteUsersMeOrganisationsCurrentFilterSetsByFilterSetIdMutation } from "../../../../../../services/cloudchipr.api";
import { DeleteConfirmationDialog } from "../../../../../common/modals/DeleteConfirmationDialog";
import { getOrganisationFilterSetsThunk } from "../../../../../../store/filters/thunks/filter-set/getOrganisationFilterSetsThunk";
import { organisationCurrentFilterSetsLoadingSelector } from "../../../../../../store/filters/selectors/current-filter-sets/organisationCurrentFilterSetsLoadingSelector";

interface DeleteFilterTemplateDialogProps {
  filterSetId: string;
  onClose(): void;
  name: string;
}

export const DeleteFilterTemplateDialog: FC<
  DeleteFilterTemplateDialogProps
> = ({ filterSetId, onClose, name }) => {
  const dispatch = useAppDispatch();
  const filterSetsLoading = useAppSelector(
    organisationCurrentFilterSetsLoadingSelector,
  );

  const [trigger, { isLoading }] =
    useDeleteUsersMeOrganisationsCurrentFilterSetsByFilterSetIdMutation();

  const deleteHandler = useCallback(async () => {
    await trigger({ filterSetId });
    await dispatch(getOrganisationFilterSetsThunk());

    onClose();
  }, [trigger, dispatch, onClose, filterSetId]);

  return (
    <DeleteConfirmationDialog
      open
      onClose={onClose}
      onDelete={deleteHandler}
      loading={isLoading || filterSetsLoading}
      title="Delete Filter Template?"
      content={
        <Typography>
          Please confirm if you want to delete
          <Typography component="span" fontWeight="bold">
            {" "}
            {name}{" "}
          </Typography>
          Filter Template.
        </Typography>
      }
    />
  );
};
