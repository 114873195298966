import { ColDef } from "@ag-grid-community/core/dist/types/src/entities/colDef";

export const reportsNextExecutionSortingFn: ColDef["comparator"] = (
  valueA,
  valueB,
  nodeA,
  nodeB,
) => {
  const aLastExecution = nodeA.data?.next_execution_date;
  const bLastExecution = nodeB.data?.next_execution_date;

  if (!aLastExecution) {
    return -1;
  }
  if (!bLastExecution) {
    return +1;
  }

  return (
    new Date(aLastExecution).getTime() - new Date(bLastExecution).getTime()
  );
};
