import { getUsersMeOrganisationsCurrentWidgetsCostAndUsageSummaryByWidgetIdSelector } from "./getUsersMeOrganisationsCurrentWidgetsCostAndUsageSummaryByWidgetIdSelector";
import { RootState } from "../../../../../store";

export const costAndUsageSumWidgetDataResponseSelector = (
  state: RootState,
  widgetId: string,
) => {
  return getUsersMeOrganisationsCurrentWidgetsCostAndUsageSummaryByWidgetIdSelector(
    {
      widgetId,
    },
  )(state);
};
