import { createAsyncThunk } from "@reduxjs/toolkit";

import { findAndApplyFilterTemplatesThunk } from "./filters/findAndApplyLiveUsageMgmtFiltersThunk";
import { getLiveUsageMgmtAccountsVisibilitiesThunk } from "./getLiveUsageMgmtAccountsVisibilitiesThunk";
import { getLiveUsageMgmtAllResourceTypesDataThunk } from "./resources/getLiveUsageMgmtAllResourceTypesDataThunk";
import { liveUsageMgmtResetResourcesConfigsPerAccountsThunk } from "./liveUsageMgmtResetResourcesConfigsPerAccountsThunk";
import {
  setAccountIds,
  setLiveUsageMgmtIsPageLoading,
  setLiveUsageMgmtProvider,
} from "../liveUsageMgmtSlice";
import { AccountId, ProviderType } from "../../../services/cloudchipr.api";

interface LiveUsageMgmtAccountsChangeThunkArgs {
  accountIds: AccountId[] | null;
  provider: ProviderType;
}

export const liveUsageMgmtAccountsChangeThunk = createAsyncThunk(
  "liveUsageMgmt/liveUsageMgmtAccountsChange",
  async (
    { accountIds, provider }: LiveUsageMgmtAccountsChangeThunkArgs,
    { dispatch },
  ) => {
    dispatch(setLiveUsageMgmtIsPageLoading(true));
    dispatch(setAccountIds(accountIds ?? []));
    dispatch(setLiveUsageMgmtProvider(provider));
    dispatch(liveUsageMgmtResetResourcesConfigsPerAccountsThunk());
    await dispatch(findAndApplyFilterTemplatesThunk());
    await dispatch(getLiveUsageMgmtAccountsVisibilitiesThunk());
    dispatch(setLiveUsageMgmtIsPageLoading(false));
    await dispatch(getLiveUsageMgmtAllResourceTypesDataThunk());
  },
);
