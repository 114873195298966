import { FC, memo } from "react";
import { TabList } from "@mui/lab";
import Tab from "@mui/material/Tab";
import { Link, useLocation } from "react-router-dom";
import { Typography } from "@mui/material";
import { ProviderType } from "../../../../../services/cloudchipr.api";
import { getProviderName } from "../../../../../utils/helpers/providers/getProviderName";
import { noOrgAccountOrgId } from "../../utils/helpers/noOrganizationConstants";

interface AccountsTabsProps {
  provider: ProviderType;
  activeCount: number;
  inactiveCount: number;
  orgId?: string;
}

export const AccountsTabs: FC<AccountsTabsProps> = memo(
  ({ provider, activeCount, inactiveCount, orgId }) => {
    const organizationId = orgId === noOrgAccountOrgId ? undefined : orgId;
    const location = useLocation();

    return (
      <TabList sx={{ background: "white" }}>
        <Tab
          component={Link}
          to={`${location.pathname}?type=active`}
          label={
            <Typography
              variant="inherit"
              textTransform="none"
              width="max-content"
            >
              Active{" "}
              {organizationId ?? getProviderName(provider, { title: true })}{" "}
              {getProviderName(provider, { plural: true })} ({activeCount ?? 0})
            </Typography>
          }
          value="active"
          sx={tabStyles}
        />
        <Tab
          component={Link}
          to={`${location.pathname}?type=inactive`}
          label={
            <Typography
              variant="inherit"
              textTransform="none"
              width="max-content"
            >
              Inactive {getProviderName(provider, { plural: true })} (
              {inactiveCount})
            </Typography>
          }
          value="inactive"
          disabled={!inactiveCount}
          sx={tabStyles}
        />
      </TabList>
    );
  },
);

const tabStyles = {
  py: 2,
};
