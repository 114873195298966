import { NestedResourcesHierarchy } from "./resources/nestedResourcesHierarchy";
import { ResourceType } from "../../services/cloudchipr.api";

export type FlatHierarchy = Record<ResourceType, ResourceType[]>;

export const generateFlattenHierarchyFromHierarchyObject = (
  hierarchy: NestedResourcesHierarchy,
): FlatHierarchy => {
  const result = {} as FlatHierarchy;

  const collectKeys = (
    currentHierarchy: NestedResourcesHierarchy | null | undefined,
    parentKey: ResourceType,
  ): string[] => {
    const keysArray: ResourceType[] = [parentKey];

    if (currentHierarchy === null) {
      return keysArray;
    }

    for (const key in currentHierarchy) {
      if (currentHierarchy.hasOwnProperty(key)) {
        const typedKey = key as ResourceType;

        if (
          currentHierarchy[typedKey] !== null &&
          typeof currentHierarchy[typedKey] === "object"
        ) {
          const nestedKeys = collectKeys(
            currentHierarchy[typedKey],
            typedKey,
          ) as ResourceType[];
          keysArray.push(...nestedKeys);
        } else {
          keysArray.push(typedKey);
        }
      }
    }

    result[parentKey] = keysArray;
    return keysArray;
  };

  for (const key in hierarchy) {
    if (hierarchy.hasOwnProperty(key)) {
      const typedKey = key as ResourceType;
      collectKeys(hierarchy[typedKey], typedKey);
    }
  }

  return result;
};
