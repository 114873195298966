import { azureAllAccountsSelector } from "./azureAllAccountsSelector";
import { RootState } from "../../../store";

export const accountAzureProviderDetailsByAccountIdSelector = (
  state: RootState,
  accountId: string,
) => {
  const accounts = azureAllAccountsSelector(state);

  const selectedAccount = accounts?.find((account) => account.id === accountId);

  if (
    !selectedAccount ||
    !(
      selectedAccount?.provider_details &&
      "azure" in selectedAccount.provider_details
    ) ||
    !selectedAccount.provider_details.azure
  ) {
    return null;
  }

  return selectedAccount?.provider_details?.["azure"];
};
