import { FC, Fragment, useMemo } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useDidMount, useEffectOnceWhen, useWillUnmount } from "rooks";
import { CircularProgress, Stack } from "@mui/material";
import { OffHoursForm } from "./OffHoursForm";
import { useAppAbility } from "../../../../../services/permissions";
import { PageHeader } from "../../../common/PageHeader";
import { resetAutomationsState } from "../../../../../store/automations/automationsSlice";
import { useAppDispatch, useAppSelector } from "../../../../../store/hooks";
import { initiateOffHoursThunk } from "../../../../../store/automations/thunks/off-hours/initiateOffHoursThunk";
import { editingOffHoursIdIsWrongSelector } from "../../../../../store/automations/selectros/off-hours/data/edit/editingOffHoursIdIsWrongSelector";
import { currentEditingOffHoursLoadingSelector } from "../../../../../store/automations/selectros/off-hours/data/edit/currentEditingOffHoursLoadingSelector";
import { getProvidersRegionsThunk } from "../../../../../store/automations/thunks/regions/getProvidersRegionsThunk";

export const SingleOffHours: FC = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [searchParams] = useSearchParams();
  const recommendationId = searchParams.get("recommendationId");

  const { cannot } = useAppAbility();
  const cannotCreate = cannot("create", "schedule");
  const cannotEdit = cannot("edit", "schedule");

  const loading = useAppSelector(currentEditingOffHoursLoadingSelector);
  const error = useAppSelector(editingOffHoursIdIsWrongSelector);

  const breadcrumbs = useMemo(() => {
    return [
      { title: "Off Hours", to: "/automations/off-hours" },
      { title: `${id ? "Edit" : "Create new"} Off Hours` },
    ];
  }, [id]);

  useEffectOnceWhen(
    () => {
      navigate("/automations/off-hours", { replace: true });
    },
    error || cannotCreate || cannotEdit,
  );

  useDidMount(async () => {
    await dispatch(getProvidersRegionsThunk());
    dispatch(initiateOffHoursThunk({ offHourId: id, recommendationId }));
  });

  useWillUnmount(() => {
    dispatch(resetAutomationsState());
  });

  return (
    <Fragment>
      <PageHeader breadcrumbs={breadcrumbs} />
      {loading ? (
        <Stack alignItems="center" pt={4}>
          <CircularProgress />
        </Stack>
      ) : (
        <OffHoursForm />
      )}
    </Fragment>
  );
};
