import { scheduleNextRunDatesSelector } from "./scheduleNextRunDatesSelector";
import { FutureRunDate } from "../../../../../../services/cloudchipr.api";
import { RootState } from "../../../../../store";
import { AutomationTabType } from "../../../../../../components/pages/schedule/common/utils/types/types";

export const scheduleFutureNextRunDatesWithoutFirstSelector = (
  state: RootState,
  frequency?: AutomationTabType,
): FutureRunDate[] | undefined => {
  const data = scheduleNextRunDatesSelector(state, frequency);

  if (!data) {
    return;
  }
  return data?.future_run_dates?.slice(1);
};
