import { FC } from "react";
import { Box, Drawer } from "@mui/material";
import { ResourceExplorerBillingStatusesDrawerContent } from "./ResourceExplorerBillingStatusesDrawerContent";
import { ResourceExplorerBillingStatusesDrawerHeader } from "./ResourceExplorerBillingStatusesDrawerHeader";
import { ResourceExplorerStatusTypes } from "../../utils/types/resourceExplorerStatusTypes";

interface ResourceExplorerBillingStatusesDrawerProps {
  open: boolean;
  onCloseDrawer(): void;
  status: ResourceExplorerStatusTypes;
}

export const ResourceExplorerBillingStatusesDrawer: FC<
  ResourceExplorerBillingStatusesDrawerProps
> = ({ open, onCloseDrawer, status }) => {
  return (
    <Drawer anchor="right" open={open} onClose={onCloseDrawer}>
      <Box sx={{ width: 600 }}>
        <ResourceExplorerBillingStatusesDrawerHeader onClose={onCloseDrawer} />

        <ResourceExplorerBillingStatusesDrawerContent activeTab={status} />
      </Box>
    </Drawer>
  );
};
