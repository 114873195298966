import { generateUpdateDashboardWidgetFixedCacheKey } from "../../../../../components/pages/dashboard/utils/helpers/dashbaordFixedCacheKeyGenerator";
import { widgetSetupSelector } from "../../setups/widgetSetupSelector";
import { cloudChiprApi } from "../../../../../services/cloudchipr.api";
import { RootState } from "../../../../store";

const selector =
  cloudChiprApi.endpoints
    .patchUsersMeOrganisationsCurrentDashboardsByDashboardIdWidgetsAndWidgetId
    .select;

export const updateWidgetLoadingSelector = (
  state: RootState,
): boolean | undefined => {
  const widgetId = widgetSetupSelector(state)?.id;

  if (!widgetId) {
    return;
  }
  return !!selector(generateUpdateDashboardWidgetFixedCacheKey(widgetId))(state)
    ?.isLoading;
};
