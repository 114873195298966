import { navigationResourceExplorerDataSelector } from "./navigationResourceExplorerDataSelector";
import { RootState } from "../../../store";
import { TreeItems } from "../../../../components/navigation/components/sortable-tree/utils/types";

export const navigationResourceExplorerPublicDataSelector = (
  state: RootState,
): TreeItems | undefined => {
  const data = navigationResourceExplorerDataSelector(state);
  return data?.visible_to_everyone ?? undefined;
};
