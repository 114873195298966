import { FC } from "react";
import { useToggle } from "rooks";
import { Box, Collapse } from "@mui/material";
import { AlertsIntegrationCustomMessageHeader } from "./AlertsIntegrationCustomMessageHeader";
import { AlertsIntegrationCustomMessageContent } from "./AlertsIntegrationCustomMessageContent";
import { AlertsIntegrationCustomMessageContentExtendedProps } from "./utils/types/types";
import { AlertsIntegrationCustomMessageDialog } from "./dialog/AlertsIntegrationCustomMessageDialog";
import { useDialog } from "../../../../../../../utils/hooks/useDialog.hook";

export interface AlertsIntegrationCustomMessageProps
  extends AlertsIntegrationCustomMessageContentExtendedProps {
  onChange(message: string): void;
}

export const AlertsIntegrationCustomMessage: FC<
  AlertsIntegrationCustomMessageProps
> = ({ type, onChange, message, actionFrom }) => {
  const [collapsed, toggleCollapsed] = useToggle();
  const { open, openDialog, closeDialog } = useDialog();

  if (type === "webhook") {
    return null;
  }

  return (
    <Box bgcolor="grey.100" p={1} borderRadius={1}>
      <AlertsIntegrationCustomMessageHeader
        collapsed={collapsed}
        toggleCollapsed={toggleCollapsed}
        openDialog={openDialog}
      />
      <Collapse in={collapsed}>
        <AlertsIntegrationCustomMessageContent
          type={type}
          actionFrom={actionFrom}
          message={message}
        />
      </Collapse>
      {open && (
        <AlertsIntegrationCustomMessageDialog
          open={open}
          onClose={closeDialog}
          message={message}
          type={type}
          onChange={onChange}
          actionFrom={actionFrom}
        />
      )}
    </Box>
  );
};
