import { Row } from "@tanstack/react-table";
import { getMetricsAggregatedData } from "./getMetricsAggregatedData";
import {
  MetricsFrequenciesKeysType,
  MetricsFrequenciesType,
} from "../../types/metricsFrequenciesType";

export const getMetricsSortingFn = (
  id: string,
  metricsFrequencies?: MetricsFrequenciesType,
) => {
  return (currentRow: Row<any>, nextRow: Row<any>) => {
    const duration =
      metricsFrequencies?.[id as MetricsFrequenciesKeysType] ?? "";
    const currentData = getMetricsAggregatedData(
      currentRow,
      duration,
      id as MetricsFrequenciesKeysType,
    );
    const nextData = getMetricsAggregatedData(
      nextRow,
      duration,
      id as MetricsFrequenciesKeysType,
    );

    if (currentData === null) {
      return -1;
    }

    if (nextData === null) {
      return 1;
    }

    return currentData > nextData ? 1 : -1;
  };
};
