import React, { FC, Fragment } from "react";
import { BillingDataItem } from "./components/BillingDataItem";
import { useAppSelector } from "../../../../../store/hooks";
import { billingStatusProcessSelector } from "../../../../../store/common/selectors/billing-status/billingStatusProcessSelector";
import { ProviderType } from "../../../../../services/cloudchipr.api";

export const BillingData: FC = () => {
  const billingStatusProcess = useAppSelector(billingStatusProcessSelector);
  const billingStatusProcessArr = Object.keys(
    billingStatusProcess,
  ) as ProviderType[];

  return (
    <Fragment>
      {billingStatusProcessArr.map((provider) => {
        return <BillingDataItem provider={provider} key={provider} />;
      })}
    </Fragment>
  );
};
