import { FC, Fragment } from "react";
import { Stack } from "@mui/material";
import { NotificationTypeSwitcher } from "./NotificationTypeSwitcher";
import { offHoursActionSelector } from "../../../../../../../store/automations/selectros/off-hours/offHoursActionSelector";
import { useAppSelector } from "../../../../../../../store/hooks";
import { AutomationNotifications } from "../../../../common/components/form/AutomationNotifications";
import { AutomationsThreshold } from "../../../../common/components/form/AutomationsThreshold";

export const OffHoursNotificationsContent: FC = () => {
  const action = useAppSelector(offHoursActionSelector);

  return (
    <Stack spacing={2}>
      <NotificationTypeSwitcher />

      {action === "notify" && (
        <Fragment>
          <AutomationsThreshold />

          <AutomationNotifications />
        </Fragment>
      )}
    </Stack>
  );
};
