import { useState } from "react";
import { useIntervalWhen } from "rooks";
import moment from "moment/moment";
import { NullableDate } from "../../../../../services/cloudchipr.api";

export function useDateFromNow(date: NullableDate | undefined) {
  const [fromNow, setFromNow] = useState<string | null>();

  useIntervalWhen(
    () => {
      setFromNow(moment.utc(date).fromNow(true));
    },
    1000,
    !!date,
    true,
  );

  return fromNow;
}
