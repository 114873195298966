import { createDraftSafeSelector } from "@reduxjs/toolkit";
import { recommendationComputeDailyActiveDataSelector } from "./recommendationComputeDailyActiveDataSelector";
import { recommendationComputeWeeklyActiveDataSelector } from "./recommendationComputeWeeklyActiveDataSelector";

export const recommendationComputeActiveDataSelector = createDraftSafeSelector(
  [
    recommendationComputeDailyActiveDataSelector,
    recommendationComputeWeeklyActiveDataSelector,
  ],
  (daily, weekly) => {
    return [...daily, ...weekly];
  },
);
