import { RefObject, useEffect, useRef, useState } from "react";

export function useHover() {
  const ref = useRef<HTMLElement>(null);
  const [hovered, setHovered] = useState(false);

  const handleMouseEnter = () => setHovered(true);
  const handleMouseLeave = () => setHovered(false);

  useEffect(() => {
    const node = ref.current;

    if (!node) return;

    node.addEventListener("mouseenter", handleMouseEnter);
    node.addEventListener("mouseleave", handleMouseLeave);

    return () => {
      node.removeEventListener("mouseenter", handleMouseEnter);
      node.removeEventListener("mouseleave", handleMouseLeave);
    };
  }, []);

  return {
    ref,
    hovered,
  } as { ref: RefObject<any>; hovered: boolean };
}
