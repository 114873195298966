import { AlertColor, Components, Theme } from "@mui/material";
import { ReactNode } from "react";

declare module "notistack" {
  interface VariantOverrides {
    snackbarAlert: {
      AlertSnackBarProps?: {
        severity?: AlertColor;
        action?: ReactNode;
        footer?: ReactNode;
        onClose?(): void;
        icon?: false | ReactNode;
        title?: string;
        description?: string | ReactNode;
        fullComponent?: ReactNode;
      };
    };
  }
}

declare module "@mui/material/Alert" {
  interface AlertProps {
    shape?: "sharp" | "rounded";
  }

  interface AlertPropsVariantOverrides {
    snackbar: true;
  }
}

export const muiAlert: Components<Theme>["MuiAlert"] = {
  styleOverrides: {
    root: ({ ownerState, theme }) => {
      if (ownerState?.shape === "sharp") {
        return {
          borderRadius: 0,
        };
      }
      if (ownerState?.variant === "snackbar") {
        return {
          background: "white",
          borderLeft: "solid 4px",
          boxShadow: theme.shadows[7],
          minWidth: 320,
          "& .MuiSvgIcon-root ": {
            fontSize: 22,
          },
          "& .MuiAlert-message ": {
            flexGrow: 1,
          },
          ...(ownerState.severity === "error" && {
            borderColor: theme.palette.error.main,
          }),
          ...(ownerState.severity === "warning" && {
            borderColor: theme.palette.warning.main,
          }),
          ...(ownerState.severity === "info" && {
            borderColor: theme.palette.info.main,
          }),
          ...(ownerState.severity === "success" && {
            borderColor: theme.palette.success.main,
          }),
        };
      }
    },
  },
};
