import { ChangeEvent, FC, useCallback, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useNavigate } from "react-router-dom";
import { DialogTitleClosable } from "../../../../../../../common/dialog-utils/DialogTitleClosable";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../store/hooks";
import { navigationHierarchyItemNoDndKey } from "../../../../../sortable-tree/utils/constants";
import { getPressEnterHandler } from "../../../../../../../utils/helpers/getPressEnterHandler";
import { createResourceExplorerLoadingSelector } from "../../../../../../../../store/resource-explorer/selectors/loading/createResourceExplorerLoadingSelector";
import { duplicateResourceExplorerVisibilityHierarchyItemThunk } from "../../../../../../../../store/resource-explorer/thunks/resource-explorer-hierarchy-visibility/duplicateResourceExplorerVisibilityHierarchyItemThunk";
import { NavigationItemsVisibilityType } from "../../../../../../../../store/navigation/utils/types";
import { resourceExplorerByIdLoadingSelector } from "../../../../../../../../store/resource-explorer/selectors/resource-explorer-by-id/resourceExplorerByIdLoadingSelector";
import { updateResourceExplorerVisibilityLoadingSelector } from "../../../../../../../../store/resource-explorer/selectors/views-hierarchy-visibility/updateResourceExplorerVisibilityHierarchyLoadingSelector";

interface ResourceExplorerViewDuplicateDialogProps {
  open: boolean;
  id: string;
  folderId?: string;
  name: string;
  visibility: NavigationItemsVisibilityType;
  closeMenu(): void;
}

export const ResourceExplorerViewDuplicateDialog: FC<
  ResourceExplorerViewDuplicateDialogProps
> = ({ open, closeMenu, id, folderId, name, visibility }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [viewName, setViewName] = useState(`${name} (copy)`);

  const getViewLoading = useAppSelector((state) =>
    resourceExplorerByIdLoadingSelector(state, id),
  );
  const createViewLoading = useAppSelector(
    createResourceExplorerLoadingSelector,
  );
  const updateVisibilityHierarchyLoading = useAppSelector(
    updateResourceExplorerVisibilityLoadingSelector,
  );
  const loading =
    getViewLoading || createViewLoading || updateVisibilityHierarchyLoading;
  const disabled = viewName.trim() === name || !viewName;

  const duplicateViewCallbackHandler = useCallback(
    (id: string) => {
      navigate({ pathname: `/resource-explorer/${id}` });
    },
    [navigate],
  );

  const duplicateHandler = useCallback(async () => {
    const response = await dispatch(
      duplicateResourceExplorerVisibilityHierarchyItemThunk({
        name: viewName,
        visibility,
        viewId: id,
        folderId: folderId,
      }),
    ).unwrap();

    if (response) {
      duplicateViewCallbackHandler(response?.id);
    }
    closeMenu();
  }, [
    duplicateViewCallbackHandler,
    dispatch,
    closeMenu,
    viewName,
    visibility,
    id,
    folderId,
  ]);

  const closeDialogHandler = useCallback(() => {
    closeMenu();
    setViewName("");
  }, [closeMenu]);

  const inputChangeHandler = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setViewName(event.target.value);
    },
    [],
  );

  const handlePressEnter = getPressEnterHandler(
    duplicateHandler,
    disabled || loading,
  );

  return (
    <Dialog
      open={open}
      fullWidth
      onClose={closeDialogHandler}
      onKeyDown={handlePressEnter}
      {...navigationHierarchyItemNoDndKey}
    >
      <DialogTitleClosable
        title="Duplicate View"
        onClose={closeDialogHandler}
      />

      <DialogContent dividers sx={{ py: 3 }}>
        <TextField
          autoFocus
          fullWidth
          type="text"
          value={viewName}
          size="small"
          variant="outlined"
          onChange={inputChangeHandler}
          placeholder="View Name"
          label="View Name"
        />
      </DialogContent>

      <DialogActions sx={{ py: 2, px: 3 }}>
        <Button color="tertiary" onClick={closeDialogHandler}>
          Cancel
        </Button>
        <LoadingButton
          disabled={disabled}
          onClick={duplicateHandler}
          loading={loading}
          variant="contained"
        >
          Duplicate
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
