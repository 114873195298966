import { FC, useCallback } from "react";
import { CommitmentsFilterSelect } from "./CommitmentsFilterSelect";
import {
  reservationTypeFilterOptions,
  savingsPlanTypeFilterOptions,
} from "./utils/contants/filter-options/recommendationsFiltersOptions";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../store/hooks";
import { setCommitmentsRecommendationsSavingsPlanType } from "../../../../../../../../store/commitments/commitmentsSlice";
import {
  CommitmentType,
  SavingsPlanType,
} from "../../../../../../../../services/cloudchipr.api";

import { commitmentsRecommendationsSavingsPlanTypeSelector } from "../../../../../../../../store/commitments/selectors/recommendations/filters/commitmentsRecommendationsSavingsPlanTypeSelector";

interface CommitmentsTypeFilterProps {
  type: CommitmentType;
}
export const CommitmentsTypeFilter: FC<CommitmentsTypeFilterProps> = ({
  type,
}) => {
  const dispatch = useAppDispatch();

  const value = useAppSelector(
    commitmentsRecommendationsSavingsPlanTypeSelector,
  );

  const changeHandler = useCallback(
    (type: string[]) => {
      dispatch(
        setCommitmentsRecommendationsSavingsPlanType(type as SavingsPlanType[]),
      );
    },
    [dispatch],
  );

  if (!value) {
    return null;
  }

  return (
    <CommitmentsFilterSelect
      selectedValues={value}
      label="Type"
      options={
        type === "savings_plan"
          ? savingsPlanTypeFilterOptions
          : reservationTypeFilterOptions
      }
      onChange={changeHandler}
    />
  );
};
