import { RootState } from "../../../store";
import { cloudChiprApi } from "../../../../services/cloudchipr.api";
import { recommendationDismissFixedCacheKey } from "../../utils/constants/fixedCacheKeys";

const {
  patchUsersMeRightSizingRecommendations,
  patchUsersMeDailyOffHoursRecommendations,
  patchUsersMeWeeklyOffHoursRecommendations,
} = cloudChiprApi.endpoints;

const rightsizingSelector = patchUsersMeRightSizingRecommendations.select(
  recommendationDismissFixedCacheKey,
);
const dailySelector = patchUsersMeDailyOffHoursRecommendations.select(
  recommendationDismissFixedCacheKey,
);
const weeklySelector = patchUsersMeWeeklyOffHoursRecommendations.select(
  recommendationDismissFixedCacheKey,
);

export const recommendationDismissingLoadingSelector = (state: RootState) => {
  const rightsizingLoading = rightsizingSelector(state)?.isLoading;
  const dailyLoading = dailySelector(state)?.isLoading;
  const weeklyLoading = weeklySelector(state)?.isLoading;

  return !!(rightsizingLoading || dailyLoading || weeklyLoading);
};
