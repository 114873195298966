import { FC, useCallback } from "react";
import { useAppDispatch } from "../../../../../../../../../store/hooks";
import { Dates } from "../../../../../../../../../services/cloudchipr.api";
import { WidgetSetupDateRangeSelect } from "../../common/toolbar/WidgetSetupDateRangeSelect";
import { costAndUsageSumDateChangeThunk } from "../../../../../../../../../store/dashboards/thunks/widgets/cost-and-usage/widget-change/costAndUsageSumDateChangeThunk";

export const CostAndUsageDateRangeSelect: FC = () => {
  const dispatch = useAppDispatch();

  const dateRangeChangeHandler = useCallback(
    (dates: Dates) => {
      dispatch(costAndUsageSumDateChangeThunk(dates));
    },
    [dispatch],
  );

  return <WidgetSetupDateRangeSelect onDateChange={dateRangeChangeHandler} />;
};
