import {
  RightSizingRecommendation,
  Tag,
} from "../../../../../../../services/cloudchipr.api";
import { getResourceTypeName } from "../../../../../../../utils/helpers/resources/getResourceTypeName";
import { formatDate } from "../../../../../../../utils/helpers/date-time/datetime-format";

export const generateRightsizingRecommendationCsv = (
  recommendation: RightSizingRecommendation,
) => {
  const row = {
    "Instance ID": recommendation.resource_name,
    Name: recommendation.tags.find(({ key }) => key === "Name")?.value,
    "Account ID": recommendation.account.provider_account_id,
    "Account Name": recommendation.account.provider_account_name,
    "Current Type": recommendation.current_machine_type,
    "Current vCPU": recommendation.current_vcpus,
    "Recommended Type": recommendation.recommended_machine_type,
    "Recommended vCPU": recommendation.recommended_vcpus,
    "Rightsize to Save": recommendation.potential_saving,
    "Resource Type": getResourceTypeName(recommendation.resource_type, {
      singular: true,
      type: "long",
    }),
    "Current Costs": recommendation.spending,
    "Recommended Costs":
      recommendation.spending - recommendation.potential_saving,
    "CPU % (Maximum CPU utilization for last 14 days)": recommendation.cpu_max,
    TAGs: recommendation.tags
      ?.map((tag: Tag) => `${tag?.key}:${tag?.value}`)
      .join(" , "),
    Region: recommendation.region,
  };

  if (recommendation.dismissed_by) {
    return [
      {
        ...row,
        "Dismissed By": recommendation.dismissed_by,
        "Will be restored on": recommendation.dismiss_until_date
          ? formatDate(recommendation.dismiss_until_date, {
              type: "dateTime",
            })
          : "",
      },
    ];
  }

  return [row];
};
