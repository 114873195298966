import { liveUsageMgmtAppliedFiltersSelector } from "./store-selectors/liveUsageMgmtAppliedFiltersSelector";
import {
  AccountResourceFilter2,
  ResourceType,
} from "../../../services/cloudchipr.api";
import { RootState } from "../../store";

export const liveUsageMgmtAppliedFiltersByResourceTypeSelector = (
  state: RootState,
  resourceType: ResourceType,
): AccountResourceFilter2 | null => {
  const appliedFilters = liveUsageMgmtAppliedFiltersSelector(state);

  return appliedFilters?.[resourceType] ?? null;
};
