import { ColumnSetupType } from "../../../../../../../../../../../../../../storybook/data-grid/utils/types/types";
import { Vm } from "../../../../../../../../../../../../../../services/cloudchipr.api";
import { ResourceMetricsHeaderCell } from "../../../../../cells/metrics-cells/components/ResourceMetricsHeaderCell";

export const vmColumns: ColumnSetupType<Vm>[] = [
  {
    accessorKey: "image_id",
    header: "Instance Name",
    type: "identifierWithRecommendationLink",
    size: 200,
    meta: { sticky: "left", hideFromSettings: true },
  },
  {
    accessorKey: "price_per_month",
    header: "Monthly Price",
    type: "moneyStoppedCell",
    headerTooltip:
      "Monthly prices are calculated based on the on-demand list price of each resource.",
  },
  {
    accessorKey: "account",
    header: "Account",
    type: "account",
    maxSize: 210,
  },
  {
    accessorKey: "unused_since",
    header: "State",
    type: "state",
  },
  {
    id: "cpu_max",
    size: 220,
    minSize: 165,
    maxSize: 300,
    meta: { cellStyles: { p: 0 }, headerTitle: "CPU Max" },
    accessorKey: "inline_metrics",
    type: "computeMetricsChart",
    header: ResourceMetricsHeaderCell,
  },
  {
    accessorKey: "all_time_spent",
    header: "Total Spend",
    type: "money",
  },
  {
    accessorKey: "created_at",
    header: "Launch time",
    type: "date",
  },
  { accessorKey: "type", header: "Machine Type" },
  {
    accessorKey: "cpu_value",
    header: "CPU Max",
    headerTooltip: "The maximum usage of CPU for the last 7 days.",
    type: "cpuStatistic",
  },
  {
    accessorKey: "availability_zone",
    header: "Av. Zone",
    type: "withTooltipCell",
  },
  { accessorKey: "tags", header: "Labels", type: "tag", enableSorting: false },
  {
    accessorKey: "tags",
    header: "Smart Labels",
    id: "smart_tag",
    type: "smartTag",
    enableSorting: false,
  },
  {
    accessorKey: "issues",
    header: "Ticket Status",
    type: "jiraTicketStatus",
    enableSorting: false,
  },
];
