import { FC, Fragment } from "react";
import Typography from "@mui/material/Typography";
import { Card, CardContent, Container, Grid } from "@mui/material";
import { SmartTagTTLForm } from "./components/SmartTagTTLForm";
import { SmartTagTTLExample } from "./components/SmartTagTTLExample";
import { SmartTagTTLDescription } from "./components/SmartTagTTLDescription";
import { PageHeader } from "../../../common/PageHeader";

export const SmartTagPreferences: FC = () => {
  return (
    <Fragment>
      <PageHeader title="Smart Tag" />

      <Container maxWidth={false} sx={{ p: 2 }} disableGutters>
        <Card variant="outlined">
          <CardContent>
            <Grid container justifyContent="center">
              <Grid xs={12} md={10} lg={6} item pb={5}>
                <Typography variant="h6" pb={4}>
                  Smart Tags
                </Typography>
                <SmartTagTTLDescription />
                <SmartTagTTLForm />
                <SmartTagTTLExample />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Container>
    </Fragment>
  );
};
