import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AccountId, ResourceType } from "../../services/cloudchipr.api";
import { CleanActionTypes } from "../../utils/clean-options";

export type CleanV2ResourceType = {
  type?: string;
  id: string;
  action?: "stop" | "terminate" | "start";
  snapshot?: boolean;
  provider_unique_identifier: string;
};

export interface CleanV2ProcessResources {
  resources?: CleanV2ResourceType[];
  excludedResources?: CleanV2ResourceType[];
}

export interface CleanV2Details extends CleanV2ProcessResources {
  accountIds: AccountId[];
  resourceType: ResourceType;
}

interface CleanV2Process {
  executionLogId: string;
  cleanProcess: CleanV2Details;
}

interface CleanV2Interface {
  details: CleanV2Details | null;
  type: CleanActionTypes | null;
  isOpen: boolean;
  processes: Record<string, CleanV2Details>;
  currentOpenId: string | null;
}

const initialState: CleanV2Interface = {
  details: null,
  isOpen: false,
  type: null,
  processes: {},
  currentOpenId: null,
};

export const cleanV2Slice = createSlice({
  name: "clean",
  initialState: initialState,
  reducers: {
    setCleanV2Details: (
      state,
      action: PayloadAction<CleanV2Details | null>,
    ) => {
      state.details = action.payload;
    },
    setCleanV2CurrentId: (state, action: PayloadAction<string | null>) => {
      state.currentOpenId = action.payload;
    },
    setCleanV2Open: (state, action: PayloadAction<boolean>) => {
      state.isOpen = action.payload;
    },
    setCleanV2ActionType: (
      state,
      action: PayloadAction<CleanActionTypes | null>,
    ) => {
      state.type = action.payload;
    },
    addCleanV2Process: (state, action: PayloadAction<CleanV2Process>) => {
      const { executionLogId, cleanProcess } = action.payload;
      state.processes[executionLogId] = cleanProcess;
    },
    removeCleanV2Process: (state, action: PayloadAction<string>) => {
      const { [action.payload]: value, ...withoutProcess } = state.processes;
      state.processes = withoutProcess;
    },
  },
});

export const {
  setCleanV2Details,
  setCleanV2CurrentId,
  setCleanV2Open,
  addCleanV2Process,
  removeCleanV2Process,
  setCleanV2ActionType,
} = cleanV2Slice.actions;

export default cleanV2Slice.reducer;
