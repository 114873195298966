import { RootState } from "../../../../../store";
import { commitmentsDataViewTabSelector } from "../../../common/view-options/commitmentsDataViewTabSelector";
import { commitmentsDetailsDrawerCoverageGroupingSelector } from "../../../common/details-drawer/commitmentsDetailsDrawerCoverageGroupingSelector";
import { reservationCSVDataGenerator } from "../../../../utils/helpers/csv/reservationCSVDataGenerator";
import { reservationsCoverageDataSelector } from "../reservationsCoverageDataSelector";

export const reservationsCoverageCSVDataSelector = (state: RootState) => {
  const data = reservationsCoverageDataSelector(state);
  const viewTab = commitmentsDataViewTabSelector(state);
  const grouping = commitmentsDetailsDrawerCoverageGroupingSelector(state);

  return reservationCSVDataGenerator({ viewTab, grouping, data });
};
