import { cloudChiprApi } from "../../../../services/cloudchipr.api";

export const getDailyActiveRecommendationComputeSelector =
  cloudChiprApi.endpoints.getUsersMeOrganisationsCurrentResourcesDailyOffHoursRecommendations.select(
    { group: "compute" },
  );
export const getDailyDismissedRecommendationComputeSelector =
  cloudChiprApi.endpoints.getUsersMeOrganisationsCurrentResourcesDailyOffHoursRecommendations.select(
    { group: "compute", isDismissed: true },
  );

export const getDailyActiveRecommendationDbSelector =
  cloudChiprApi.endpoints.getUsersMeOrganisationsCurrentResourcesDailyOffHoursRecommendations.select(
    { group: "database" },
  );

export const getDailyDismissedRecommendationDbSelector =
  cloudChiprApi.endpoints.getUsersMeOrganisationsCurrentResourcesDailyOffHoursRecommendations.select(
    { group: "database", isDismissed: true },
  );
