import { FC, Fragment, useCallback, useEffect } from "react";
import { useFlag } from "@unleash/proxy-client-react";
import { CostBreakdownWidgetSetupContent } from "./content/CostBreakdownWidgetSetupContent";
import { CostBreakdownWidgetSetupSidebar } from "./side-bar/CostBreakdownWidgetSetupSidebar";
import { setWidgetsConfigurationsFeatureToggleFromStorage } from "./utils/helpers/configurations-feature-toggle/setWidgetsConfigurationsFeatureToggleFromStorage";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../store/hooks";
import { WidgetSetupLayout } from "../common/WidgetSetupLayout";
import { WidgetSetupHeader } from "../common/WidgetSetupHeader";
import { scrollPageToBottom } from "../../../../../../utils/helpers/page-scrolling-functions";
import { costBreakdownSetupPropertyByKeySelector } from "../../../../../../../../store/dashboards/selectors/setups/cost-breakdown/costBreakdownSetupPropertyByKeySelector";
import { costBreakdownSetupVisualizationPropertyByKeySelector } from "../../../../../../../../store/dashboards/selectors/setups/cost-breakdown/costBreakdownSetupVisualizationPropertyByKeySelector";
import { costBreakdownSubmitButtonDisabledSelector } from "../../../../../../../../store/dashboards/selectors/setups/cost-breakdown/costBreakdownSubmitButtonDisabledSelector";
import { buildCostBreakdownWidgetThunk } from "../../../../../../../../store/dashboards/thunks/widgets/cost-breakdown/actions/buildCostBreakdownWidgetThunk";

interface CostBreakdownSetupProps {
  onClose(): void;
  onSubmit(): void;
}

export const CostBreakdownWidgetSetup: FC<CostBreakdownSetupProps> = ({
  onClose,
  onSubmit,
}) => {
  const dispatch = useAppDispatch();
  const enableWidgetsSideBarConfigurations = useFlag(
    "EnableWidgetsSideBarConfigurations",
  );

  const viewId = useAppSelector(
    costBreakdownSetupPropertyByKeySelector("viewId"),
  );

  const viewType = useAppSelector(
    costBreakdownSetupVisualizationPropertyByKeySelector("chart_type"),
  );

  const setupId = useAppSelector(costBreakdownSetupPropertyByKeySelector("id"));
  const disabled = useAppSelector(costBreakdownSubmitButtonDisabledSelector);

  const submitHandler = useCallback(async () => {
    const responseId = await dispatch(buildCostBreakdownWidgetThunk()).unwrap();

    onSubmit();
    if (responseId && !setupId) {
      scrollPageToBottom();
    }
  }, [dispatch, setupId, onSubmit]);

  useEffect(() => {
    setWidgetsConfigurationsFeatureToggleFromStorage(
      enableWidgetsSideBarConfigurations,
    );
  }, [enableWidgetsSideBarConfigurations]);

  if (!viewType) {
    return null;
  }

  return (
    <Fragment>
      <WidgetSetupHeader
        disabled={disabled}
        onAdd={submitHandler}
        onCancel={onClose}
      />

      <WidgetSetupLayout
        empty={!viewId ? "Select Resource Explorer View to get started." : ""}
        appBar={<CostBreakdownWidgetSetupSidebar />}
      >
        <CostBreakdownWidgetSetupContent />
      </WidgetSetupLayout>
    </Fragment>
  );
};
