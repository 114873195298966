import { createAsyncThunk } from "@reduxjs/toolkit";
import { getCostAndUsageSumWidgetDataBySetupThunk } from "../data-get/getCostAndUsageSumWidgetDataBySetupThunk";
import { RootState } from "../../../../../store";
import { setWidgetSetup } from "../../../../dashboardsSlice";
import { costAndUsageSumWidgetByIdSelector } from "../../../../selectors/widgets/cost-and-usage/costAndUsageSumWidgetByIdSelector";
import { CostAndUsageSumSetupType } from "../../../../utils/types/setups/costAndUsageSumSetupType";

export const editCostAndUsageSumWidgetThunk = createAsyncThunk(
  "dashboards/editCostAndUsageSumWidgetThunk",
  (widgetId: string, { dispatch, getState }) => {
    const state = getState() as RootState;
    const widget = costAndUsageSumWidgetByIdSelector(state, widgetId);

    if (!widget) {
      return;
    }

    dispatch(
      setWidgetSetup({
        widgetType: "cost_and_usage_summary",
        id: widget.id,
        name: widget.name,
        grouping: widget.grouping,
        group_values: widget.group_values,
        date: {
          from: widget.date_from,
          to: widget.date_to,
          label: widget.date_label ?? null,
        },
        dates: widget.dates,
        viewId: widget.view_id ?? "",
        filter: widget.filter ?? [],
      } as CostAndUsageSumSetupType),
    );

    dispatch(getCostAndUsageSumWidgetDataBySetupThunk());
  },
);
