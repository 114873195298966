import { navigationDashboardPublicDataItemsSelector } from "./navigationDashboardPublicDataItemsSelector";
import { navigationDashboardPrivateDataItemsSelector } from "./navigationDashboardPrivateDataItemsSelector";
import { RootState } from "../../../store";
import { NavigationItemsVisibilityType } from "../../utils/types";

export const navigationDashboardVisibilitySelectedSelector = (
  state: RootState,
  visibility: NavigationItemsVisibilityType,
  id?: string,
): boolean | undefined => {
  let data;
  if (visibility === "visible_to_everyone") {
    data = navigationDashboardPublicDataItemsSelector(state);
  } else {
    data = navigationDashboardPrivateDataItemsSelector(state);
  }

  if (!data) {
    return;
  }
  return data.some((item) => item.id === id);
};
