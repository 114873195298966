import { FC, Fragment, useEffect, useRef } from "react";
import { Card, CardHeader, Divider, Typography } from "@mui/material";
import { ChatTable } from "./ChatTable";

interface ChatAnswerSectionProps {
  question: string;
}

export const ChatAnswerSection: FC<ChatAnswerSectionProps> = ({ question }) => {
  const ref = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (ref.current) {
      ref.current?.scrollTo({ top: ref.current?.scrollHeight });
    }
  }, []);

  return (
    <Card sx={{ overflowY: "auto" }} ref={ref}>
      <CardHeader
        title={
          <Fragment>
            <Typography variant="body1" fontWeight="medium">
              Question:
            </Typography>
            <Typography variant="body1">{question}</Typography>
          </Fragment>
        }
      />
      <Divider />

      {/*<CardContent>*/}
      {/*  <Typography variant="body1" fontWeight="medium">*/}
      {/*    Answer:*/}
      {/*  </Typography>*/}
      {/*  <Typography variant="body1">Hello!</Typography>*/}
      {/*  <Typography variant="body1">*/}
      {/*    Here is a breakdown of your spending for the last three months:*/}
      {/*  </Typography>*/}
      {/*</CardContent>*/}

      <Divider />
      <ChatTable />

      {/*<CardContent>*/}
      {/*  <ChatChartSection />*/}

      {/*  <Typography variant="body1" mt={2}>*/}
      {/*    I hope this information helps you better understand your spending on*/}
      {/*    engineering dev accounts across all cloud providers for the last three*/}
      {/*    months. If you have any further questions or need assistance, please*/}
      {/*    do not hesitate to let me know.*/}
      {/*  </Typography>*/}
      {/*</CardContent>*/}
    </Card>
  );
};
