import { createAsyncThunk } from "@reduxjs/toolkit";
import { enqueueSnackbar } from "notistack";
import { cloudChiprApi } from "../../../../services/cloudchipr.api";
import { RootState } from "../../../store";
import { taskManagementEditingActionsSelector } from "../../selectors/properties/edit/taskManagementEditingActionsSelector";
import { updateTaskActionPropertiesFixedCacheKey } from "../../utils/constants/fixedCacheKeys";
import { taskAvailablePropertiesSelector } from "../../selectors/properties/taskAvailablePropertiesSelector";
import { isDeepEqual } from "../../../../utils/is-deep-equal";
import { removeIdFromNewCreatedProperties } from "../../utils/helpers/removeIdFromNewCreatedProperties";

export const updateTaskActionPropertiesThunk = createAsyncThunk(
  "taskManagement/updateTaskActionProperties",
  async (_: void, { dispatch, getState }) => {
    const state = getState() as RootState;
    const { putUsersMeOrganisationsCurrentTasksPropertiesActions } =
      cloudChiprApi.endpoints;

    const properties = taskManagementEditingActionsSelector(state);
    const initialProperties = taskAvailablePropertiesSelector(state);

    if (isDeepEqual(properties, initialProperties?.actions ?? [])) {
      return;
    }

    try {
      return await dispatch(
        putUsersMeOrganisationsCurrentTasksPropertiesActions.initiate(
          {
            body: { properties: removeIdFromNewCreatedProperties(properties) },
          },
          { fixedCacheKey: updateTaskActionPropertiesFixedCacheKey },
        ),
      ).unwrap();
    } catch (e) {
      // @ts-expect-error TODO: return to this after adding error type
      const errMessage = e?.data?.message || "Something went wrong";
      enqueueSnackbar(errMessage, {
        variant: "snackbarAlert",
        AlertSnackBarProps: { severity: "error" },
      });
    }
  },
);
