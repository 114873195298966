import { reservationsCoverageCSVDataSelector } from "./reservationsCoverageCSVDataSelector";
import { reservedInstancesCSVDataSelector } from "./reservedInstancesCSVDataSelector";
import { RootState } from "../../../../../store";
import { commitmentsDataViewTabSelector } from "../../../common/view-options/commitmentsDataViewTabSelector";

export const reservationsCSVDataSelector = (state: RootState) => {
  const coverageCSV = reservationsCoverageCSVDataSelector(state);
  const rIsCsv = reservedInstancesCSVDataSelector(state);
  const tab = commitmentsDataViewTabSelector(state);

  if (tab === "coverage") {
    return coverageCSV;
  }

  if (tab === "commitment") {
    return rIsCsv;
  }

  return null;
};
