import { RootState } from "../../../../../../store";

import { savingsPlansDataForGranularSelector } from "../../savingsPlansDataForGranularSelector";

export const savingsPlansGranularDataTotalCoverageSelector = (
  state: RootState,
) => {
  const data = savingsPlansDataForGranularSelector(state);

  return data?.date_granular_data?.total_coverage;
};
