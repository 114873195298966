import { FC, useCallback } from "react";
import { Typography } from "@mui/material";
import { SlackAutocompleteTrigger } from "./SlackAutocompleteTrigger";
import {
  DropdownSelect,
  DropdownSelectProps,
} from "../../../../../../../../common/select/dropdown-select/DropdownSelect";
import { slackSelectionOptionsFilter } from "../../utils/helpers/helpers";

type DropdownSelectExtendedProps = Pick<
  DropdownSelectProps,
  "values" | "options" | "optionsLoading"
>;

interface SlackConversationSelectProps extends DropdownSelectExtendedProps {
  optionsFetching: boolean;
  onChange(values: string[]): void;
  searchHandler(keyword: string): void;
}

export const SlackConversationSelect: FC<SlackConversationSelectProps> = ({
  onChange,
  options,
  optionsLoading,
  values,
  searchHandler,
  optionsFetching,
}) => {
  const TriggerComponent = useCallback(
    (triggerProps: any) => {
      return (
        <SlackAutocompleteTrigger
          onChange={onChange}
          searchHandler={searchHandler}
          triggerProps={triggerProps}
          loading={optionsLoading}
        />
      );
    },
    [onChange, optionsLoading, searchHandler],
  );

  const noItemsLabel = useCallback(
    (keyword: any) => (
      <Typography align="center" variant="subtitle2" color="text.secondary">
        No conversations found for “{keyword}”
      </Typography>
    ),
    [],
  );

  return (
    <DropdownSelect
      showSearch
      values={values}
      options={options}
      showSelectAll={false}
      wrapperVariant="dropdown"
      noItemsLabel={noItemsLabel}
      label="Select Conversations*"
      submitHandlerOnClose={onChange}
      optionsLoading={optionsFetching}
      TriggerComponent={TriggerComponent}
      filterFn={slackSelectionOptionsFilter}
      searchPlaceholder="Search for members, private and public channels."
    />
  );
};
