import { FC, useMemo } from "react";
import ReportProblemRoundedIcon from "@mui/icons-material/ReportProblemRounded";
import { BarChart as RechartBar, Bar, XAxis, YAxis } from "recharts";
import { Stack, Typography } from "@mui/material";
import { green, red } from "@mui/material/colors";
import { percentage } from "../utils/helpers/percentage";

interface BarChartProps {
  empty: boolean;
  error: boolean;
  partial: number;
  total: number;
}

export const BarChart: FC<BarChartProps> = ({
  empty,
  error,
  partial,
  total,
}) => {
  if (empty || error) {
    partial = 0;
    total = 0;
  }

  const content = percentage(partial, total);

  const data = useMemo(
    () => [
      {
        spending: total ? 1 - partial / total : 0,
        est_waste: total ? partial / total : 1,
      },
    ],
    [partial, total],
  );

  return (
    <Stack direction="row" alignItems="baseline">
      <RechartBar
        width={170}
        height={16}
        barSize={16}
        margin={{ top: 16, bottom: 0, right: 0, left: 0 }}
        data={data}
        layout="vertical"
        stackOffset="expand"
      >
        <XAxis type="number" hide />
        <YAxis type="category" hide />
        <Bar
          dataKey="est_waste"
          stackId="1"
          fill={error || empty ? "silver" : red[500]}
          name="est_waste"
          background={{ fill: green[100] }}
        />
        <Bar
          dataKey="spending"
          stackId="1"
          fill={error || empty ? "silver" : green[100]}
          name="spending"
        />
      </RechartBar>

      {error ? (
        <ReportProblemRoundedIcon sx={{ color: "#FFCA29" }} fontSize="medium" />
      ) : (
        <Typography
          color={empty ? "text.disabled" : ""}
          variant="body2"
          marginLeft={1}
        >
          {content}
        </Typography>
      )}
    </Stack>
  );
};
