import { FC, Fragment, useCallback, useState } from "react";
import { Button, Container, LinearProgress } from "@mui/material";
import { useSnackbar } from "notistack";
import AddIcon from "@mui/icons-material/Add";
import { UsersList } from "./components/UsersList";
import { UserInviteDialog } from "./components/UserInviteDialog";
import { EmptyUsersList } from "./components/EmptyUsersList";
import { UsersRequestList } from "./components/users-request/UsersRequestList";
import { PageHeader } from "../../../common/PageHeader";
import {
  useGetUsersMeOrganisationsCurrentQuery,
  useGetV2UsersMeOrganisationsCurrentUsersQuery,
} from "../../../../../services/cloudchipr.api";
import { useAppAbility } from "../../../../../services/permissions";

export const Users: FC = () => {
  const {
    data,
    refetch,
    isLoading: loading,
  } = useGetV2UsersMeOrganisationsCurrentUsersQuery({});

  const { cannot } = useAppAbility();
  const cannotCreateUser = cannot("create", "user");

  const { data: org } = useGetUsersMeOrganisationsCurrentQuery();

  const [dialogOpen, setDialogOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const snackbarOpenHandler = useCallback(
    (type: "success" | "error", message: string) => {
      enqueueSnackbar(message, {
        variant: "snackbarAlert",
        AlertSnackBarProps: {
          severity: type,
        },
      });
    },
    [enqueueSnackbar],
  );

  const dialogCloseSuccessHandler = useCallback(() => {
    setDialogOpen(false);
    refetch();
    snackbarOpenHandler("success", "Invitation has been sent successfully!");
  }, [refetch, snackbarOpenHandler]);

  const dialogCloseHandler = useCallback(() => {
    setDialogOpen(false);
  }, []);

  const dialogOpenHandler = useCallback(() => {
    setDialogOpen(true);
  }, []);

  const length = data?.length ? `(${data.length})` : "";

  return (
    <Fragment>
      <PageHeader
        title={`Users ${length}`}
        actions={
          cannotCreateUser && org?.sso_connection_type ? null : (
            <Button
              variant="contained"
              onClick={dialogOpenHandler}
              disabled={cannotCreateUser || loading}
            >
              <AddIcon sx={{ mr: 1 }} /> Invite user
            </Button>
          )
        }
      />

      {loading && <LinearProgress />}

      <Container sx={{ p: 2 }} maxWidth={false} disableGutters>
        {!data?.length && !loading ? (
          <EmptyUsersList onActionClick={dialogOpenHandler} />
        ) : (
          <Fragment>
            {!cannotCreateUser && <UsersRequestList />}
            <UsersList />
          </Fragment>
        )}
      </Container>

      <UserInviteDialog
        open={dialogOpen}
        onClose={dialogCloseHandler}
        onSuccess={dialogCloseSuccessHandler}
      />
    </Fragment>
  );
};
