import { FC, useCallback } from "react";
import { Stack } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useAppDispatch } from "../../../../../../../../../../../../store/hooks";
import { setBillingStatusProcess } from "../../../../../../../../../../../../store/common/commonSlice";
import { BillingExportInitialValues } from "../../../ResourceExplorerBillingExportDialog";
import { createResourceExplorerGcpBillingExportDataThunk } from "../../../../../../../../../../../../store/resource-explorer/thunks/widgets/gcp-billing-data/createResourceExplorerGcpBillingExportDataThunk";
import { usePostUsersMeOrganisationsCurrentGcpBillingExportDataMutation } from "../../../../../../../../../../../../services/cloudchipr.api";
import { createResourceExplorerGcpBillingExportDataFixedCacheKey } from "../../../../../../../utils/constants/fixedCacheKeys";

interface ResourceExplorerBillingExportDialogAccessConsoleCheckPermissionProps {
  values: BillingExportInitialValues;
  onSuccess?(): void;
}

export const ResourceExplorerBillingExportDialogAccessConsoleCheckPermission: FC<
  ResourceExplorerBillingExportDialogAccessConsoleCheckPermissionProps
> = ({ values, onSuccess }) => {
  const dispatch = useAppDispatch();
  const [, { isLoading }] =
    usePostUsersMeOrganisationsCurrentGcpBillingExportDataMutation({
      fixedCacheKey: createResourceExplorerGcpBillingExportDataFixedCacheKey,
    });

  const checkPermissionClickHandler = useCallback(async () => {
    // TODO: Add Error interface in api spec
    const response: any = await dispatch(
      createResourceExplorerGcpBillingExportDataThunk({
        project_id: values.projectId,
        dataset: values.dataSetName,
        table: values.table,
      }),
    ).unwrap();

    dispatch(setBillingStatusProcess("gcp"));
    if (response?.id) {
      onSuccess?.();
    }
  }, [dispatch, values, onSuccess]);

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      my={2}
    >
      Please assign roles and check permissions to proceed.
      <LoadingButton
        loading={isLoading}
        onClick={checkPermissionClickHandler}
        variant="contained"
      >
        check permissions
      </LoadingButton>
    </Stack>
  );
};
