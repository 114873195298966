import moment from "moment/moment";
import { preDefinedRanges } from "../../../../common/date-range-picker/utils/preDefinedRanges/preDefinedRanges";

export const executionLogDateAllTimeLabel = "all_time" as const;

export const MAX_DATE_MONTHS = 6;

const { past_one_hundred_eighty_days, last_twelve_months, ...restRanges } =
  preDefinedRanges;

export const getRreDefinedAllRangesForExecutionLogs = (
  allTimeDateFrom?: string,
) => {
  const to = moment();

  return {
    ...restRanges,

    [executionLogDateAllTimeLabel]: {
      dateLabel: executionLogDateAllTimeLabel,
      dateTo: to.toISOString(),
      dateFrom: allTimeDateFrom ?? "",
      label: "All Time",
      shortLabel: "All Time",
    },
  };
};
