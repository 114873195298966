import { FC, useCallback } from "react";
import { grey } from "@mui/material/colors";
import { ColumnResizeMode } from "@tanstack/react-table";
import { BoxProps, styled, Box as MUIBox } from "@mui/material";

const Box = styled("div")<
  { opacity: number; transform: number | null } & BoxProps
>(({ opacity, transform }) => ({
  position: "absolute",
  right: 0,
  top: 0,
  height: "100%",
  width: "5px",
  cursor: "col-resize",
  userSelect: "none",
  touchAction: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  opacity,
  transform: transform ? `translateX(${transform}px)` : "",
}));

interface ResizeBoxProps {
  resizeHandler(event: unknown): void;
  resizeEndCallback?(): void;
  resizing: boolean;
  deltaOffset: number | null;
  resizeMode: ColumnResizeMode;
}

export const ResizeBox: FC<ResizeBoxProps> = ({
  resizeHandler,
  resizing,
  deltaOffset,
  resizeMode,
  resizeEndCallback,
}) => {
  const onResizeStart = useCallback(
    (event: unknown) => {
      resizeHandler(event);
      const upHandler = () => {
        if (resizeEndCallback) {
          resizeEndCallback();
        }
        document.removeEventListener("mouseup", upHandler);
      };
      document.addEventListener("mouseup", upHandler);
    },
    [resizeHandler, resizeEndCallback],
  );

  return (
    <Box
      transform={resizeMode === "onEnd" ? deltaOffset : null}
      opacity={+resizing}
      onMouseDown={onResizeStart}
      onTouchStart={onResizeStart}
    >
      <MUIBox width={0.3} bgcolor={grey["500"]} height="70%" />
    </Box>
  );
};
