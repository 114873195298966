import { FC, useMemo } from "react";
import AvTimerOutlinedIcon from "@mui/icons-material/AvTimerOutlined";
import AccountTreeOutlinedIcon from "@mui/icons-material/AccountTreeOutlined";
import HistoryToggleOffOutlinedIcon from "@mui/icons-material/HistoryToggleOffOutlined";
import { useLocation } from "react-router-dom";
import { SvgIcon } from "@mui/material";
import { NavItem } from "../nav-item/NavItem";
import { useAppSelector } from "../../../../store/hooks";
import { navigationCollapsedSelector } from "../../../../store/common/selectors/navigation/navigationCollapsedSelector";

export const AutomationNavigation: FC = () => {
  const navigationCollapsed = useAppSelector(navigationCollapsedSelector);

  const location = useLocation();
  const selected = location.pathname.startsWith("/automations");

  const children = useMemo<
    {
      primary: string;
      to: string;
      icon: typeof SvgIcon;
    }[]
  >(() => {
    return [
      {
        primary: "Workflows",
        to: "/automations/workflow",
        icon: AccountTreeOutlinedIcon,
      },
      {
        primary: "Off Hours",
        to: "/automations/off-hours",
        icon: HistoryToggleOffOutlinedIcon,
      },
    ];
  }, []);

  return (
    <NavItem
      primary="Automations"
      icon={AvTimerOutlinedIcon}
      navCollapsed={navigationCollapsed}
      selected={selected}
    >
      {children.map(({ primary, to, icon }) => (
        <NavItem
          nested
          key={to}
          primary={primary}
          to={to}
          icon={icon}
          selected={location.pathname.startsWith(to)}
        />
      ))}
    </NavItem>
  );
};
