import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  cloudChiprApi,
  ResourceType,
} from "../../../../services/cloudchipr.api";
import { RootState } from "../../../store";
import { addRegionsToFilters } from "../../../../utils/helpers/addRegionsToFilters";
import { getFirstParentInHierarchyResourceType } from "../../../../utils/helpers/resources/getFirstParentInHierarchyResourceType";
import { liveUsageMgmtAccountIdsSelector } from "../../selectors/store-selectors/liveUsageMgmtAccountIdsSelector";
import { liveUsageMgmtRegionsSelector } from "../../selectors/store-selectors/liveUsageMgmtRegionsSelector";
import { liveUsageMgmtAppliedFiltersByResourceTypeSelector } from "../../selectors/liveUsageMgmtAppliedFiltersByResourceTypeSelector";

export const getLiveUsageMgmtResourcesWithFiltersByProviderThunk =
  createAsyncThunk(
    "liveUsageMgmt/getLiveUsageMgmtResourcesWithFilters",
    async (resourceType: ResourceType, { dispatch, getState }) => {
      const state = getState() as RootState;
      const accountIds = liveUsageMgmtAccountIdsSelector(state);
      const regions = liveUsageMgmtRegionsSelector(state);

      const type =
        getFirstParentInHierarchyResourceType(resourceType) ?? resourceType;
      if (!accountIds) {
        return;
      }

      let appliedFilter = liveUsageMgmtAppliedFiltersByResourceTypeSelector(
        state,
        type,
      );

      if (!appliedFilter) {
        return;
      }

      appliedFilter = addRegionsToFilters(regions, appliedFilter);

      dispatch(
        cloudChiprApi.endpoints.postUsersMeV2OrganisationsCurrentByTypeResources.initiate(
          {
            type: type,
            body: {
              account_ids: accountIds ?? [],
              filter: appliedFilter,
            },
          },
          { fixedCacheKey: type },
        ),
      ).unwrap();
    },
  );
