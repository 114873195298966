export const integrationCustomEmailCostAnomalyDataGridColumns = [
  {
    header: "Service",
    value: "Service name",
  },
  {
    header: "Total Cost",
    value: "$00.00",
  },
  {
    header: "Percentage Change",
    value: "00.0%",
  },
  {
    header: "Cost Amount Change",
    value: "$00.00",
  },
];
