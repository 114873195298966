import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PostV3UsersMeExecutionLogsApiResponse } from "../../services/cloudchipr.api";
import { executionLogPaginationSize } from "../../components/pages/execution-log/utils/constants/common";
import {
  ExecutionLogFilters,
  ExecutionLogPagination,
  ExecutionLogsDates,
  ExecutionLogState,
} from "../../components/pages/execution-log/utils/types/types";
import { executionLogDateAllTimeLabel } from "../../components/pages/execution-log/utils/constants/dates";

const initialState: ExecutionLogState = {
  data: null,
  filters: null,
  dates: { label: executionLogDateAllTimeLabel },
  pagination: { page: 0, size: executionLogPaginationSize },
};

export const executionLogSlice = createSlice({
  name: "executionLog",
  initialState: initialState,
  reducers: {
    setExecutionLogData: (
      state,
      action: PayloadAction<PostV3UsersMeExecutionLogsApiResponse>,
    ) => {
      state.data = action.payload;
    },
    setExecutionLogFilters: (
      state,
      action: PayloadAction<ExecutionLogFilters>,
    ) => {
      state.filters = { ...state.filters, ...action.payload };
    },
    setExecutionLogDates: (
      state,
      action: PayloadAction<ExecutionLogsDates>,
    ) => {
      state.dates = action.payload;
    },
    setExecutionLogPagination: (
      state,
      action: PayloadAction<ExecutionLogPagination>,
    ) => {
      state.pagination = { ...state.pagination, ...action.payload };
    },
    resetExecutionLogs: () => {
      return initialState;
    },
  },
});

export const {
  setExecutionLogData,
  setExecutionLogFilters,
  setExecutionLogPagination,
  resetExecutionLogs,
  setExecutionLogDates,
} = executionLogSlice.actions;

export default executionLogSlice.reducer;
