import { reportsTableFilteredDataByDashboardIdSelector } from "./reportsTableFilteredDataByDashboardIdSelector";
import { reportsTableFilteredDataByViewIdSelector } from "./reportsTableFilteredDataByViewIdSelector";
import { reportsDataPropertyByKeySelector } from "./reportsDataPropertyByKeySelector";
import { RootState } from "../../store";

export const reportsTableFilteredDataBySourceSelector = (state: RootState) => {
  const sourceType = reportsDataPropertyByKeySelector("source_type")(state);

  if (sourceType === "dashboard") {
    return reportsTableFilteredDataByDashboardIdSelector(state);
  }

  return reportsTableFilteredDataByViewIdSelector(state);
};
