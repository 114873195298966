import { FC, useCallback, useRef } from "react";
import {
  FormControl,
  IconButton,
  InputLabel,
  Popover,
  PopoverOrigin,
  Select,
  Stack,
} from "@mui/material";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { useDidMount } from "rooks";
import { FilterSelectPopoverContent } from "./popover/FilterSelectPopoverContent";
import { NewFilterAddFn } from "./popover/FilterItemSelectMenuItem";

import { useMenuHook } from "../../../../../../utils/hooks/useMenu.hook";
import { ResourceExplorerFilterProvider } from "../../../../../../services/cloudchipr.api";

interface FilterSelectProps {
  padding?: number;
  nested?: boolean;
  filterProvider?: ResourceExplorerFilterProvider;
  onRemove(): void;
  onSelect: NewFilterAddFn;
}

export const FilterSelect: FC<FilterSelectProps> = ({
  onRemove,
  onSelect,
  filterProvider,
  padding = 1,
  nested,
}) => {
  const ref = useRef<null | HTMLDivElement>(null);
  const { anchor, open, openMenu, closeMenu } = useMenuHook();

  const onSelectHandler: NewFilterAddFn = useCallback(
    (...args) => {
      onSelect(...args);
      closeMenu();
      onRemove();
    },
    [onRemove, closeMenu, onSelect],
  );

  const closeHandler = useCallback(() => {
    onRemove();
    closeMenu();
  }, [onRemove, closeMenu]);

  useDidMount(() => {
    setTimeout(() => {
      if (ref.current) {
        openMenu({ currentTarget: ref.current });
      }
    }, 100);
  });

  return (
    <Stack
      flex={1}
      padding={padding}
      direction="row"
      borderRadius={2}
      bgcolor={nested ? "grey.100" : "grey.50"}
      justifyContent="space-between"
    >
      <FormControl
        ref={ref}
        size="xsmall"
        sx={{ width: nested ? 170 : 190 }}
        onClick={openMenu}
      >
        <InputLabel>Select Filter</InputLabel>

        <Select
          value=""
          open={open}
          onClose={closeMenu}
          label="Select Filter"
          MenuProps={{ sx: { display: "none" } }}
        />
      </FormControl>

      <IconButton size="small" onClick={onRemove}>
        <DeleteOutlineIcon fontSize="small" />
      </IconButton>

      <Popover
        open={open}
        anchorEl={anchor}
        onClose={closeHandler}
        anchorOrigin={anchorOrigin}
      >
        <FilterSelectPopoverContent
          filterProvider={filterProvider}
          onSelect={onSelectHandler}
        />
      </Popover>
    </Stack>
  );
};

const anchorOrigin: PopoverOrigin = {
  vertical: "bottom",
  horizontal: "left",
};
