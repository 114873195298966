import { FC, useCallback } from "react";
import { List, Popover, PopoverOrigin } from "@mui/material";
import { ResourceExplorerForecastedCostsOption } from "./ResourceExplorerForecastedCostsOption";
import {
  ForecastedCostRe,
  ForecastOption,
} from "../../../../../../../../../services/cloudchipr.api";

interface ResourceExplorerForecastedCostsPopoverProps {
  open: boolean;
  closeMenu(): void;
  anchor: HTMLElement | null;
  options?: ForecastedCostRe;
  onForecastChange(forecastOption: ForecastOption): void;
  selectedForecastOption: ForecastOption;
}
export const ResourceExplorerForecastedCostsPopover: FC<
  ResourceExplorerForecastedCostsPopoverProps
> = ({
  open,
  closeMenu,
  anchor,
  options,
  onForecastChange,
  selectedForecastOption,
}) => {
  const onForecastOptionClick = useCallback(
    (option: ForecastOption) => {
      onForecastChange(option);
      closeMenu();
    },
    [closeMenu, onForecastChange],
  );

  if (!options) {
    return null;
  }

  return (
    <Popover
      open={open}
      anchorEl={anchor}
      onClose={closeMenu}
      anchorOrigin={anchorOrigin}
      transformOrigin={transformOrigin}
      PaperProps={paperProps}
    >
      <List>
        {(options as ForecastedCostRe)?.map((item) => {
          return (
            <ResourceExplorerForecastedCostsOption
              cost={item.cost}
              key={item.forecast_option}
              onClick={onForecastOptionClick}
              forecastOption={item.forecast_option}
              selected={item.forecast_option === selectedForecastOption}
              dateFrom={item.date_from}
              dateTo={item.date_to}
            />
          );
        })}
      </List>
    </Popover>
  );
};

const anchorOrigin: PopoverOrigin = {
  vertical: "bottom",
  horizontal: "right",
};
const transformOrigin: PopoverOrigin = {
  vertical: "top",
  horizontal: "right",
};

const paperProps = {
  style: { minWidth: 260 },
};
