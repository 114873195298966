import { reservationDetailsDataSelector } from "../data/reservationDetailsDataSelector";
import { RootState } from "../../../../../store";
import { CommitmentCoverageMetadata } from "../../../../../../services/cloudchipr.api";

export const reservationCoverageDetailsMetaDataSelector = (
  state: RootState,
): CommitmentCoverageMetadata | undefined => {
  return reservationDetailsDataSelector(state)
    ?.metadata as CommitmentCoverageMetadata;
};
