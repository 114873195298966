import { organisationCurrentSettingsSelector } from "./organisationCurrentSettingsSelector";
import { RootState } from "../../../store";

export const organisationQuarterStartMonthSelector = (
  state: RootState,
): number | undefined => {
  const settings = organisationCurrentSettingsSelector(state).data;

  const startMonth = settings?.quarter_starts_from_month?.value;

  return startMonth ? +startMonth : undefined;
};
