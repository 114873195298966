import { createDraftSafeSelector } from "@reduxjs/toolkit";
import {
  AccountRecommendationState,
  cloudChiprApi,
} from "../../../../../services/cloudchipr.api";

const selector =
  cloudChiprApi.endpoints.getUsersMeOrganisationsCurrentRecommendationsStatus.select();

export const rightsizingRecommendationsStatusSelector = createDraftSafeSelector(
  [selector],
  (response): AccountRecommendationState[] | undefined => {
    return response?.data;
  },
);
