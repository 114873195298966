import { FC } from "react";
import { Button, Stack } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { CommandSnippet } from "../../common/connectors/components/CommandSnippet";
import { useAppSelector } from "../../../../store/hooks";
import { azureServicePrincipalSelector } from "../../../../store/account-setup/selectors/azureServicePrincipalSelector";
import { CollapsableCommand } from "../../common/connectors/components/CollapsableCommand";
import { AzureServicePrincipalMessage } from "../../common/connectors/components/azure/AzureServicePrincipalMessage";
import { useServicePrincipalHook } from "../../common/connectors/components/azure/hooks/useServicePrincipal.hook";
import { AzureProvideTenantIdField } from "../../common/connectors/components/azure/AzureProvideTenantIdField";
import { AzureCLIInstallationGuide } from "../../common/connectors/components/azure/AzureCLIInstallationGuide";

export const AzureServicePrincipalStep: FC = () => {
  const servicePrincipal = useAppSelector(azureServicePrincipalSelector);

  const { values, handleSubmit, handleChange, errors, isLoading, prevHandler } =
    useServicePrincipalHook();

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <AzureServicePrincipalMessage />

        <CommandSnippet code={`az ad sp create --id "${servicePrincipal}"`} />

        <AzureProvideTenantIdField
          onChange={handleChange}
          value={values.tenantId}
          error={errors.tenantId}
        />

        <AzureCLIInstallationGuide />
        <CollapsableCommand
          message="* Use the following command to find the Tenant ID"
          commandSnippetCode="az account get-access-token --query tenant --output tsv"
        />

        <Stack
          direction="row"
          alignItems="end"
          justifyContent="end"
          gap={2}
          mt={2}
        >
          <Button onClick={prevHandler} variant="text">
            Prev
          </Button>
          <LoadingButton
            type="submit"
            variant="contained"
            color="primary"
            disabled={!!errors.tenantId || !values.tenantId}
            loading={isLoading}
          >
            Next
          </LoadingButton>
        </Stack>
      </form>
    </div>
  );
};
