import { FC, Fragment } from "react";
import { Box, Stack, Typography } from "@mui/material";
import { money } from "../../../../../../../utils/numeral/money";

interface SubscriptionPlanHeaderProps {
  name: string;
  price: number;
  yearly: boolean;
  special: boolean;
  monthlySpendRange?: string;
}

export const SubscriptionPlanHeader: FC<SubscriptionPlanHeaderProps> = ({
  name,
  monthlySpendRange,
  price,
  yearly,
  special,
}) => {
  return (
    <Fragment>
      <Typography variant="h6" color="text.primary">
        {name}
      </Typography>
      <Typography
        variant="caption"
        color="text.secondary"
        component="p"
        lineHeight={1}
      >
        Monthly Cloud spend
      </Typography>
      <Typography
        variant="caption"
        color="text.secondary"
        component="p"
        lineHeight={1}
      >
        {monthlySpendRange}
      </Typography>
      <Stack direction="row" alignItems="baseline" my={3}>
        {special ? (
          <Typography variant="h4" fontWeight="bolder">
            Special offer
          </Typography>
        ) : (
          <Fragment>
            <Typography variant="h4" color="text.primary" fontWeight="bolder">
              {money(price)}
            </Typography>
            <Box ml={1}>
              <Typography color="text.secondary" lineHeight={1}>
                /{yearly ? "year" : "mo"}
              </Typography>
            </Box>
          </Fragment>
        )}
      </Stack>
    </Fragment>
  );
};
