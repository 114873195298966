import { FC } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { TTLExampleStep1 } from "./TTLExampleStep1";
import { TTLExampleStep2 } from "./TTLExampleStep2";

export const SmartTagTTLExample: FC = () => {
  return (
    <Accordion
      elevation={0}
      sx={{
        bgcolor: "grey.50",
      }}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{ py: 1 }}>
        <Typography>Example use case</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <TTLExampleStep1 />
        <TTLExampleStep2 />
      </AccordionDetails>
    </Accordion>
  );
};
