import { createAsyncThunk } from "@reduxjs/toolkit";
import { getResourceExplorerDataThunk } from "./getResourceExplorerDataThunk";
import { setResourceExplorerPathThunk } from "./path/setResourceExplorerPathThunk";
import { setResourceExplorerSearch } from "../../resourceExplorerSlice";

export const getResourceExplorerWidgetDataThunk = createAsyncThunk(
  "resourceExplorer/getResourceExplorerWidgetsData",
  (_: void, { dispatch }) => {
    dispatch(getResourceExplorerDataThunk());
    dispatch(setResourceExplorerPathThunk(false));
    dispatch(setResourceExplorerSearch(""));
  },
);
