import { FC, useCallback, useState } from "react";
import { Stack } from "@mui/material";
import { AgGridReact } from "@ag-grid-community/react";
import { SavingsOpportunitiesTableCsvDownloader } from "./SavingsOpportunitiesTableCSVDownloader";
import { GlobalFilter } from "../../../../../storybook/data-grid/toolbar/GlobalFilter";
import { AGGridColumnsToolPanel } from "../../../common/ag-grid/AGGridColumnsToolPanel";

interface SavingsOpportunitiesTableToolbarProps {
  agGrid: AgGridReact | null;
}

export const SavingsOpportunitiesTableToolbar: FC<
  SavingsOpportunitiesTableToolbarProps
> = ({ agGrid }) => {
  const [searchValue, setSearchValue] = useState("");

  const searchChangeHandler = useCallback(
    (keyword: string) => {
      agGrid?.api?.setGridOption("quickFilterText", keyword);
      setSearchValue(keyword);
    },
    [agGrid?.api],
  );

  return (
    <Stack
      p={2}
      pt={1}
      top={0}
      spacing={2}
      bgcolor="white"
      zIndex="appBar"
      borderBottom={1}
      position="sticky"
      borderColor="grey.300"
    >
      <Stack
        direction="row"
        justifyContent="end"
        alignItems="center"
        spacing={2}
      >
        <GlobalFilter
          globalFilter={searchValue}
          setGlobalFilter={searchChangeHandler}
          size="xsmall"
        />

        <SavingsOpportunitiesTableCsvDownloader />

        <AGGridColumnsToolPanel agGrid={agGrid} />
      </Stack>
    </Stack>
  );
};
