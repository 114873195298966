import { costBreakdownWidgetVisualizationSelector } from "./costBreakdownWidgetVisualizationSelector";
import { costBreakdownWidgetDataByWidgetIdSelector } from "./costBreakdownWidgetDataByWidgetIdSelector";
import { costBreakdownWidgetFrequencySelector } from "./costBreakdownWidgetFrequencySelector";
import { RootState } from "../../../../../store";
import { ChartDataType } from "../../../../../../storybook/charts/multi-type-chart/utils/types/types";
import { formatResourceExplorerChartData } from "../../../../../resource-explorer/utils/helpers/formatResourceExplorerChartData";
import { convertCostToPercent } from "../../../../../resource-explorer/selectors/current-resource-explorer/chart/utils/helpers/convertCostToPercent";

export const costBreakdownWidgetChartDataSelector = (
  state: RootState,
  widgetId: string,
): ChartDataType[] | [] => {
  const visualization = costBreakdownWidgetVisualizationSelector(
    state,
    widgetId,
  );
  const data = costBreakdownWidgetDataByWidgetIdSelector(state, widgetId)?.data;

  const frequency = costBreakdownWidgetFrequencySelector(state, widgetId);

  if (!data || !frequency) {
    return [];
  }

  const formatedData = formatResourceExplorerChartData(data, frequency);

  if (visualization?.chart_base_type === "cost" || !formatedData) {
    return formatedData;
  }

  return convertCostToPercent(formatedData);
};
