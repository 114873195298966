import { FC } from "react";
import { Stack, Typography } from "@mui/material";
import {
  HeaderCellWidthDateTooltip,
  HeaderCellWidthDateTooltipProps,
} from "../../../../../../common/components/data-grid/cells/HeaderCellWidthDateTooltip";
import { useAppSelector } from "../../../../../../../../../store/hooks";
import { savingsPlansCoverageDataTotalsSelector } from "../../../../../../../../../store/commitments/selectors/coverage-and-utilization/data/savingsPlansCoverageDataTotalsSelector";
import { money } from "../../../../../../../../../utils/numeral/money";

interface UtilizationAndCommitmentColumnsHeaderCellWithTotalAndDateProps
  extends HeaderCellWidthDateTooltipProps {
  type: "netSavings" | "coveredUsage" | "unCoveredUsage";
}

export const UtilizationAndCommitmentColumnsHeaderCellWithTotalAndDate: FC<
  UtilizationAndCommitmentColumnsHeaderCellWithTotalAndDateProps
> = ({ type, ...props }) => {
  const totals = useAppSelector(savingsPlansCoverageDataTotalsSelector);

  return (
    <Stack alignItems="end" flex={1} spacing={0.5}>
      <HeaderCellWidthDateTooltip {...props} />

      {totals && (
        <Typography variant="caption" color="text.secondary">
          {money(totals[type])}
        </Typography>
      )}
    </Stack>
  );
};
