import { FC } from "react";
import { Stack, Typography } from "@mui/material";
import { RightsizingRecommendationsEmptyStateCard } from "./RightsizingRecommendationsEmptyStateCard";
import EC2ResourceRecommendationsImage from "../../../../../../assets/recommendations/aws-bg.svg";
import EnableRecommenderApiImage from "../../../../../../assets/recommendations/gcp-bg.svg";
import EmptyStateImage from "../../../../../../assets/images/empty_recommendation_state.svg";

interface RightsizingRecommendationsEmptyStateProps {
  reason: "noData" | "notEnabled";
  single?: boolean;
}

export const RightsizingRecommendationsEmptyState: FC<
  RightsizingRecommendationsEmptyStateProps
> = ({ reason, single }) => {
  if (reason === "noData") {
    return (
      <Stack alignItems="center" mt={25}>
        <img src={EmptyStateImage} alt="EmptyStateImage" />
        <Typography variant="h5" fontWeight="bold" mt={7}>
          There are no recommendations {!single ? "yet" : ""}
        </Typography>
        {!single && (
          <Typography
            variant="h6"
            fontWeight="regular"
            color="text.secondary"
            textAlign="center"
            mt={2}
          >
            We are checking for recommendations on hourly basis. <br /> Please
            come back later.
          </Typography>
        )}
      </Stack>
    );
  }

  return (
    <Stack alignItems="center" justifyContent="center">
      <Typography variant="h5" fontWeight="bold" mt={4}>
        There are no rightsizing recommendations yet
      </Typography>

      <Typography
        variant="h6"
        fontWeight="regular"
        color="text.secondary"
        textAlign="center"
        mt={2}
        mb={4}
      >
        Follow the simple steps below to enable <br />
        rightsizing recommendations.
      </Typography>

      <Stack direction="row" flexWrap="wrap" justifyContent="center" gap={3}>
        <RightsizingRecommendationsEmptyStateCard
          title="EC2 Resource Recommendations"
          provider="aws"
          img={EC2ResourceRecommendationsImage}
          actionButtonLabel="Go To AWS"
          actionButtonUrl="https://console.cloud.google.com/iam-admin/iam"
        />

        <RightsizingRecommendationsEmptyStateCard
          title="GCP Recommender API"
          provider="gcp"
          img={EnableRecommenderApiImage}
          actionButtonLabel="Go To GCP"
          actionButtonUrl="https://console.cloud.google.com/iam-admin/iam"
        />
      </Stack>
    </Stack>
  );
};
