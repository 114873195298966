import { Fragment, useCallback, useEffect, useRef, useState } from "react";
import { AgGridReact } from "@ag-grid-community/react";
import { WorkflowsListToolbar } from "./grid-options/WorkflowsListToolbar";
import { WorkflowsDataGridComponent } from "./WorkflowsDataGridComponent";
import { useAppSelector } from "../../../../../../store/hooks";
import { workflowsFilteredDataSelector } from "../../../../../../store/automations/selectros/workflow/list-data/workflowsFilteredDataSelector";
import { Schedule } from "../../../../../../services/cloudchipr.api";
import type { FilterChangedEvent } from "@ag-grid-community/core/dist/types/src/events";

export const WorkflowsDataGrid = () => {
  const data = useAppSelector(workflowsFilteredDataSelector);

  const gridRef = useRef<AgGridReact>(null);
  const [agGrid, setAgGrid] = useState<AgGridReact | null>(null);
  const [displayedDataCount, setDisplayedDataCount] = useState(0);

  const filterChangeHandler = useCallback(
    (event: FilterChangedEvent<Schedule>) => {
      const count = agGrid?.api?.getDisplayedRowCount();
      if (!count) {
        event.api.showNoRowsOverlay();
      } else {
        event.api.hideOverlay();
      }

      setDisplayedDataCount(count ?? 0);
    },
    [agGrid],
  );

  useEffect(() => {
    setAgGrid(gridRef.current);
  }, [gridRef.current]);

  useEffect(() => {
    setDisplayedDataCount(agGrid?.api?.getDisplayedRowCount() ?? 0);
  }, [data?.length, agGrid]);

  return (
    <Fragment>
      <WorkflowsListToolbar dataCount={displayedDataCount} agGrid={agGrid} />

      <WorkflowsDataGridComponent
        data={data}
        refToPassGrid={gridRef}
        onFilterChanged={filterChangeHandler}
      />
    </Fragment>
  );
};
