import {
  createContext,
  FC,
  ReactNode,
  useContext,
  useMemo,
  useState,
} from "react";
import { VisibilityType } from "../utils/types/types";

interface AllItemsFiltersContextType {
  filters: {
    visibilities?: VisibilityType[];
    setVisibilities(visibilities: VisibilityType[] | undefined): void;

    createdBy?: string[];
    setCreatedBy(createdBy: string[] | undefined): void;
  };
  createdByOptions: string[];
}

const AllItemsFiltersContext = createContext({} as AllItemsFiltersContextType);

export const HierarchyAllItemsFiltersProvider: FC<{
  children?: ReactNode;
  createdByOptions: string[];
}> = ({ children, createdByOptions }) => {
  const [visibilities, setVisibilities] = useState<
    VisibilityType[] | undefined
  >();
  const [createdBy, setCreatedBy] = useState<string[] | undefined>();

  const contextValue = useMemo(
    () => ({
      filters: {
        visibilities,
        setVisibilities,

        createdBy,
        setCreatedBy,
      },
      createdByOptions,
    }),
    [visibilities, createdBy, createdByOptions],
  );

  return (
    <AllItemsFiltersContext.Provider value={contextValue}>
      {children}
    </AllItemsFiltersContext.Provider>
  );
};

export const useAllItemsFiltersContext = () => {
  return useContext(AllItemsFiltersContext);
};
