import { currentV2OpenCleanProcessSelector } from "./currentV2OpenCleanProcessSelector";
import { cleanV2ActionTypeSelector } from "./store-selectors/cleanV2ActionTypeSelector";
import { RootState } from "../../store";
import { getFirstParentInHierarchyResourceType } from "../../../utils/helpers/resources/getFirstParentInHierarchyResourceType";
import { liveUsageMgmtResourceTypeDataWithChildrenSelector } from "../../live-usage-mgmt/selectors/resource-type-data/liveUsageMgmtResourceTypeDataWithChildrenSelector";
import { CleanResourcesDataType } from "../../../components/pages/common/clean/clean-dialog/utils/types";

export const cleanV2ResourcesGroupedByAccountIdSelector = (
  state: RootState,
): Record<string, any> | null => {
  const currentProcess = currentV2OpenCleanProcessSelector(state);
  if (!currentProcess?.resourceType) {
    return null;
  }

  const resources = liveUsageMgmtResourceTypeDataWithChildrenSelector(
    state,
    getFirstParentInHierarchyResourceType(currentProcess?.resourceType) ??
      currentProcess?.resourceType,
  );

  const resourcesForClean = new Set(
    currentProcess?.resources?.map((r) => r.id),
  );

  const cleanActionType = cleanV2ActionTypeSelector(state);

  if (!resources) {
    return null;
  }

  return resources?.reduce(
    (result, { resource }) => {
      if (!resourcesForClean.has(resource.id)) {
        return result;
      }
      result[resource.account.id] = result[resource.account.id]
        ? [
            ...result[resource.account.id],
            {
              ...resource,
              cleanAction: cleanActionType || "delete",
            },
          ]
        : [
            {
              ...resource,
              cleanAction: cleanActionType || "delete",
            },
          ];

      return result;
    },
    {} as Record<string, CleanResourcesDataType>,
  );
};
