import React, { FC, Fragment } from "react";
import { CleanV2Process } from "./CleanProcess";
import { useAppSelector } from "../../../../store/hooks";
import { cleanV2ProcessSelector } from "../../../../store/clean-v2/selectors/store-selectors/cleanV2ProcessSelector";

export const CleanV2FAB: FC = () => {
  const cleanProcesses = useAppSelector(cleanV2ProcessSelector);

  return (
    <Fragment>
      {Object.entries(cleanProcesses).map(([executionLogId, details]) => {
        return (
          <CleanV2Process
            key={executionLogId}
            executionLogId={executionLogId}
            resourceType={details.resourceType}
          />
        );
      })}
    </Fragment>
  );
};
