import { FC, memo } from "react";
import { Stack } from "@mui/material";
import { useAppSelector } from "../../../../../../../../store/hooks";
import {
  DateDataPointNullable,
  DateLabelNullable,
} from "../../../../../../../../services/cloudchipr.api";
import { CostBreakdownWidgetContentCosts } from "../CostBreakdownWidgetContentCosts";
import { ChartType } from "../../../../../../../../storybook/charts/multi-type-chart/utils/types/types";
import { MultiTypeChartProvider } from "../../../../../../../../storybook/charts/multi-type-chart/MultiTypeChartProvider";
import { CostBreakdownWidgetChart } from "../../../../adding-widget/widget-create/widget-setups/cost-breakdown/components/chart-view/CostBreakdownWidgetChart";
import { costBreakdownSetupPropertyByKeySelector } from "../../../../../../../../store/dashboards/selectors/setups/cost-breakdown/costBreakdownSetupPropertyByKeySelector";
import { costBreakdownWidgetChartDataSelector } from "../../../../../../../../store/dashboards/selectors/widgets/cost-breakdown/widget-view/costBreakdownWidgetChartDataSelector";
import { costBreakdownWidgetCombinedChartDataSelector } from "../../../../../../../../store/dashboards/selectors/widgets/cost-breakdown/widget-view/costBreakdownWidgetCombinedChartDataSelector";
import { costBreakdownWidgetVisualizationSelector } from "../../../../../../../../store/dashboards/selectors/widgets/cost-breakdown/widget-view/costBreakdownWidgetVisualizationSelector";
import { costBreakdownWidgetHasAggregationSelector } from "../../../../../../../../store/dashboards/selectors/widgets/cost-breakdown/widget-view/costBreakdownWidgetHasAggregationSelector";
import { costBreakdownWidgetTotalCostDateDetailsSelector } from "../../../../../../../../store/dashboards/selectors/widgets/cost-breakdown/widget-view/costBreakdownWidgetTotalCostDateDetailsSelector";

interface CostBreakdownWidgetChartViewProps {
  widgetId: string;
  chartType: ChartType;
  dateLabel?: DateLabelNullable;
  dateDataPoint: DateDataPointNullable;
}

export const CostBreakdownWidgetChartView: FC<CostBreakdownWidgetChartViewProps> =
  memo(({ chartType, dateDataPoint, widgetId, dateLabel }) => {
    const chartData = useAppSelector((state) =>
      costBreakdownWidgetChartDataSelector(state, widgetId),
    );
    const combinedChartData = useAppSelector((state) =>
      costBreakdownWidgetCombinedChartDataSelector(state, widgetId),
    );
    const chartVisualization = useAppSelector((state) =>
      costBreakdownWidgetVisualizationSelector(state, widgetId),
    );
    const hasAggregations = useAppSelector((state) =>
      costBreakdownWidgetHasAggregationSelector(state, widgetId),
    );

    const dateDetails = useAppSelector((state) =>
      costBreakdownWidgetTotalCostDateDetailsSelector(state, widgetId),
    );
    const loading = useAppSelector(
      costBreakdownSetupPropertyByKeySelector("loading"),
    );
    if (!chartData) {
      return null;
    }

    return (
      <Stack
        px={2}
        py={1}
        gap={1}
        flex={1}
        justifyContent={hasAggregations ? "start" : "center"}
      >
        <MultiTypeChartProvider
          value={{
            initialChartType: chartType,
            data: chartData ?? [],
          }}
        >
          <CostBreakdownWidgetContentCosts
            widgetId={widgetId}
            dateLabel={dateLabel}
          />
          <CostBreakdownWidgetChart
            dateDataPoint={dateDataPoint}
            chartType={chartType}
            dateDetails={dateDetails}
            loading={loading}
            chartBase={chartVisualization?.chart_base_type ?? "cost"}
            combinedChartData={combinedChartData}
          />
        </MultiTypeChartProvider>
      </Stack>
    );
  });
