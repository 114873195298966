import { createAsyncThunk } from "@reduxjs/toolkit";
import { closeSnackbar, enqueueSnackbar, SnackbarKey } from "notistack";
import { Layout, Layouts } from "react-grid-layout";
import { updateDashboardVisibilityHierarchyThunk } from "./updateDashboardVisibilityHierarchyThunk";
import {
  cloudChiprApi,
  PostV2UsersMeOrganisationsCurrentDashboardsApiArg,
} from "../../../../services/cloudchipr.api";
import {
  createDashboardFixedCacheKey,
  updateDashboardFixedCacheKey,
} from "../../../../components/pages/dashboard/utils/constants/fixedCacheKeys";

type Args = PostV2UsersMeOrganisationsCurrentDashboardsApiArg["body"] & {
  dashboardId: string;
  folderId?: string;
};

export const duplicateDashboardVisibilityHierarchyItemThunk = createAsyncThunk(
  "dashboard/duplicateDashboardVisibilityHierarchyItem",
  async ({ name, dashboardId, visibility, folderId }: Args, { dispatch }) => {
    const {
      postV2UsersMeOrganisationsCurrentDashboards,
      getUsersMeOrganisationsCurrentDashboardsByDashboardId,
      patchUsersMeOrganisationsCurrentDashboardsByDashboardId,
    } = cloudChiprApi.endpoints;
    let response;

    try {
      const getResponse = await dispatch(
        getUsersMeOrganisationsCurrentDashboardsByDashboardId.initiate(
          { dashboardId },
          { forceRefetch: true },
        ),
      ).unwrap();

      // prepare widgets to duplicate
      const widgets = getResponse.widgets.map((widget) => {
        let categoryIds: string[] | undefined = undefined;

        if ("categories" in widget) {
          categoryIds = widget.categories.map((cat) => cat.id);
        }

        return {
          ...widget,
          category_ids: categoryIds,
          reference_id: widget.id,
        };
      });

      const postResponse = await dispatch(
        postV2UsersMeOrganisationsCurrentDashboards.initiate(
          { body: { name, visibility, widgets } },
          { fixedCacheKey: createDashboardFixedCacheKey },
        ),
      ).unwrap();

      const refMap = postResponse.widget_reference_map;

      // replace old widgets ids with new duplicated ids
      const layout =
        getResponse.layout && refMap
          ? Object.entries(getResponse.layout).reduce(
              (newLayout, [bp, layout]) => {
                newLayout[bp] = layout.map((ly: Layout) => ({
                  ...ly,
                  i: refMap[ly.i],
                }));
                return newLayout;
              },
              {} as Layouts,
            )
          : null;

      await dispatch(
        patchUsersMeOrganisationsCurrentDashboardsByDashboardId.initiate(
          { dashboardId: postResponse.id, body: { layout, name: null } },
          { fixedCacheKey: updateDashboardFixedCacheKey },
        ),
      );

      await dispatch(
        updateDashboardVisibilityHierarchyThunk({
          folderId,
          id: postResponse.id,
          visibility,
          type: "dashboard",
        }),
      );

      if (response) {
        const snackbarId: SnackbarKey = enqueueSnackbar(
          "Dashboard successfully duplicated.",
          {
            variant: "snackbarAlert",
            AlertSnackBarProps: {
              severity: "success",
              onClose: () => closeSnackbar(snackbarId),
            },
          },
        );
      }

      return postResponse;
    } catch (e) {
      // @ts-expect-error TODO: return to this after adding error type
      const errMessage = e?.data?.message || "Something went wrong";
      const snackbarId: SnackbarKey = enqueueSnackbar(errMessage, {
        variant: "snackbarAlert",
        AlertSnackBarProps: {
          severity: "error",
          onClose: () => closeSnackbar(snackbarId),
        },
      });
    }
  },
);
