import { selectedSavingsPlanUUIdSelector } from "./savings-plan/selectedSavingsPlanUUIdSelector";
import { savingsPlanSelectedCoverageDataSelector } from "./resource-type/savingsPlanSelectedCoverageDataSelector";
import { commitmentsDetailsDrawerTargetTypeSelector } from "../../common/details-drawer/commitmentsDetailsDrawerTargetTypeSelector";
import { GetUsersMeOrganisationsCurrentSavingsPlansDataApiArg } from "../../../../../services/cloudchipr.api";
import { RootState } from "../../../../store";
import { commitmentsDetailsDrawerPayloadDataSelector } from "../../common/details-drawer/commitmentsDetailsDrawerPayloadDataSelector";

export const utilizationAndCoverageDetailsPayloadSelector = (
  state: RootState,
): GetUsersMeOrganisationsCurrentSavingsPlansDataApiArg | undefined => {
  const type = commitmentsDetailsDrawerTargetTypeSelector(state);
  const commitmentPayload = commitmentsDetailsDrawerPayloadDataSelector(state);

  if (!type) {
    return;
  }

  const payloadData: GetUsersMeOrganisationsCurrentSavingsPlansDataApiArg = {
    type,
    commitmentType: "savings_plan",
    ...commitmentPayload,
  };

  if (type === "commitment") {
    payloadData.commitmentId = selectedSavingsPlanUUIdSelector(state);
  }

  if (type === "account") {
    const coverageData = savingsPlanSelectedCoverageDataSelector(state);
    payloadData.accountId = coverageData?.account?.id;
  }

  if (type === "resource_type") {
    const coverageData = savingsPlanSelectedCoverageDataSelector(state);
    payloadData.resourceType = coverageData?.resource_type?.resource_type;
    payloadData.region = coverageData?.resource_type?.region ?? undefined;
    payloadData.instanceFamily =
      coverageData?.resource_type?.instance_family ?? undefined;
  }

  return payloadData;
};
