import { ResourceExplorerDataGridView } from "./resourceExplorerDataGrid";
import {
  ChartType,
  DateDataPointNullable,
  Dates,
  FilterTreeNode,
  ForecastOption,
  ResourceExplorerData,
  ResourceExplorerGrouping,
  TrendType,
  CostType,
} from "../../../../services/cloudchipr.api";
import { ResourceExplorerChartBase } from "../../../../components/pages/resource-explorer/components/resource-explorer-card/components/resource-explorer-chart/utils/types";
import { FilterTreeNodeWithId } from "../../../../components/pages/common/filters-tree/utils/types/common";

export type ResourceExplorerPath = {
  name: string;
  groupedBy: ResourceExplorerGrouping;
  groupingOptions: ResourceExplorerGrouping[];
  groupValues?: string[];
  filterTree?: FilterTreeNode;
  forecastOption?: ForecastOption;
  dates?: Dates;
  chartType?: ChartType;
  dateGranularity?: DateDataPointNullable;
  trendType?: TrendType;
  costType?: CostType;
};

export type ResourceExplorerChartType = Extract<
  ChartType,
  "stack" | "line" | "bar"
>;

export const resourceExplorerPossibleChartTypes: ResourceExplorerChartType[] = [
  "stack",
  "line",
  "bar",
];

export interface ResourceExplorerInterface {
  id: string;
  filterTree?: FilterTreeNodeWithId;
  data: ResourceExplorerData | null;
  path: ResourceExplorerPath[];
  search: string;
  quarterEndForecastVisibility: boolean;
  dataGridView: ResourceExplorerDataGridView | null;
  csvDataHash: string;
  chartBase: ResourceExplorerChartBase;
}

export type WidgetAverageData = {
  cost: number | null;
  trend: number | null;
};
