import { cleanV2ActionTypeSelector } from "./store-selectors/cleanV2ActionTypeSelector";
import { cleanV2ResourcesSelector } from "./cleanV2ResourcesSelector";
import { RootState } from "../../store";

export const cleanV2ResourcesGridDataCountSelector = (
  state: RootState,
): number => {
  const resources = cleanV2ResourcesSelector(state) ?? [];
  const cleanActionType = cleanV2ActionTypeSelector(state);

  return resources?.map((r) => {
    return {
      ...r,
      cleanAction: cleanActionType || "delete",
    };
  }).length;
};
