import { FC, useCallback } from "react";
import {
  Card,
  CardActionArea,
  CardContent,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import TrendingFlatIcon from "@mui/icons-material/TrendingFlat";

interface ChatQuestionProps {
  question: string;
  disabled: boolean;
  onQuestionChange(q: string): void;
}

export const ChatQuestion: FC<ChatQuestionProps> = ({
  question,
  disabled,
  onQuestionChange,
}) => {
  const clickHandler = useCallback(() => {
    onQuestionChange(question);
  }, [question, onQuestionChange]);

  return (
    <Grid item sm={12} md={4} display="flex" alignItems="stretch">
      <Card sx={{ flexGrow: 1 }}>
        <CardActionArea
          sx={{ height: "100%" }}
          onClick={clickHandler}
          disabled={disabled}
        >
          <CardContent>
            <Stack direction="row" alignItems="end">
              <Typography variant="body1">{question}</Typography>
              <TrendingFlatIcon sx={{ color: "text.secondary" }} />
            </Stack>
          </CardContent>
        </CardActionArea>
      </Card>
    </Grid>
  );
};
