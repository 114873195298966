import { Components, Theme } from "@mui/material";

declare module "@mui/material/Autocomplete" {
  interface AutocompletePropsSizeOverrides {
    xsmall: true;
  }
}
export const muiAutocomplete: Components<Theme>["MuiAutocomplete"] = {
  styleOverrides: {
    root: ({ ownerState }) => {
      if (ownerState?.size === "xsmall") {
        return {
          "& .MuiOutlinedInput-root": {
            padding: "2px 6px",
            "& input": {
              padding: "2px 4px !important",
            },
          },

          "& .MuiInputLabel-outlined.MuiFormLabel-root": {
            transform: "translate(12px, 4px) scale(1) !important",
            paddingRight: "8px !important",
          },

          "& .MuiInputLabel-outlined.MuiFormLabel-root.Mui-focused, .MuiInputLabel-outlined.MuiFormLabel-root.MuiFormLabel-filled":
            {
              transform: "translate(14px, -9px) scale(0.75) !important",
              paddingRight: "8px !important",
            },
        };
      }
    },
  },
};
