import { FC } from "react";
import { Button, DialogActions } from "@mui/material";

interface FiltersDialogActionsProps {
  onClose(): void;
  disabled: boolean;
}

export const FiltersDialogActions: FC<FiltersDialogActionsProps> = ({
  onClose,
  disabled,
}) => {
  return (
    <DialogActions sx={{ px: 3, py: 2 }}>
      <Button onClick={onClose} color="tertiary">
        Cancel
      </Button>
      <Button type="submit" variant="contained" disabled={disabled}>
        Apply
      </Button>
    </DialogActions>
  );
};
