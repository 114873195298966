import { enqueueSnackbar } from "notistack";
import { dashboardWidgetLabels } from "../../../../../components/pages/dashboard/utils/constants/constants";
import { WidgetType } from "../../../../../services/cloudchipr.api";

export const openWidgetBuildingSnackbar = (
  widgetType: WidgetType,
  type: "create" | "update" | "duplicate",
) => {
  enqueueSnackbar(
    `${dashboardWidgetLabels[widgetType]} widget successfully ${actionName[type]}.`,
    {
      variant: "snackbarAlert",
      AlertSnackBarProps: { severity: "success" },
    },
  );
};

const actionName: Record<"create" | "update" | "duplicate", string> = {
  create: "created",
  update: "updated",
  duplicate: "duplicated",
};
