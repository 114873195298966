import { FC } from "react";
import { Stack, Typography } from "@mui/material";

import { SlackIntegrationGracePeriodMessageTitle } from "./SlackIntegrationGracePeriodMessageTitle";
import { SlackIntegrationGracePeriodMessageDescription } from "./SlackIntegrationGracePeriodMessageDescription";
import { SlackIntegrationGracePeriodMessageWorkflowInfo } from "./SlackIntegrationGracePeriodMessageWorkflowInfo";
import { SlackIntegrationDataGrid } from "../common/SlackIntegrationDataGrid";

interface SlackIntegrationGracePeriodMessageProps {
  message: string;
}

export const SlackIntegrationGracePeriodMessage: FC<
  SlackIntegrationGracePeriodMessageProps
> = ({ message }) => {
  return (
    <Stack spacing={1.5}>
      <SlackIntegrationGracePeriodMessageTitle />
      <SlackIntegrationGracePeriodMessageDescription />
      <SlackIntegrationGracePeriodMessageWorkflowInfo />
      <Typography fontStyle="italic" variant="caption">
        *This link is private. Please keep it confidential and do not share.
      </Typography>

      {message && (
        <Typography
          variant="body1"
          dangerouslySetInnerHTML={{ __html: message }}
        />
      )}

      <SlackIntegrationDataGrid />
    </Stack>
  );
};
