import { FC, ReactNode } from "react";
import {
  Box,
  Card,
  Collapse,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { useToggle } from "rooks";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

interface TaskPropertyCollapseSectionProps {
  title: string;
  icon: ReactNode;
  children: ReactNode;
}

export const TaskPropertyCollapseSection: FC<
  TaskPropertyCollapseSectionProps
> = ({ title, icon, children }) => {
  const [collapsed, toggleCollapsed] = useToggle();

  return (
    <Card variant="outlined">
      <Stack
        p={1.5}
        direction="row"
        alignItems="center"
        onClick={toggleCollapsed}
        sx={{ cursor: "pointer" }}
        justifyContent="space-between"
      >
        <Stack
          spacing={1}
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          {icon}
          <Typography fontWeight="medium">{title}</Typography>
        </Stack>

        <IconButton size="small">
          {!collapsed ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        </IconButton>
      </Stack>

      <Collapse in={!collapsed}>
        <Box p={1.5}>{children}</Box>
      </Collapse>
    </Card>
  );
};
