import { RowSelectionState, Updater } from "@tanstack/react-table";
import { useCallback, useMemo } from "react";
import { RowExpandingConfig } from "../types/prop-types";
import { useDataGridContext } from "../../DataGridProvider";

export function useRowExpanding(
  data: any[],
  rowExpandingConfig?: RowExpandingConfig,
) {
  const { rowExpanding } = useDataGridContext();

  const { expandedRows, setExpandedRows } = useMemo(() => {
    return {
      expandedRows: filterExpandedRows(data, rowExpanding?.expandedRows ?? {}),
      setExpandedRows: rowExpanding?.setExpandedRows,
    };
  }, [data, rowExpanding]);

  const enabled = !!rowExpandingConfig;

  const rowExpandHandler = useCallback(
    (updater: Updater<RowSelectionState>) => {
      setExpandedRows?.((oldState) => {
        let newState = updater as RowSelectionState;
        if (typeof updater === "function") {
          newState = updater(oldState);
        }

        return newState;
      });
    },
    [setExpandedRows],
  );

  return useMemo(
    () => ({
      expanded: enabled ? expandedRows : undefined,
      rowExpandChange: enabled ? rowExpandHandler : undefined,
    }),
    [expandedRows, enabled, rowExpandHandler],
  );
}
const filterExpandedRows = (
  data: any[],
  expandedIds: Record<string, boolean>,
): RowSelectionState => {
  if (!expandedIds) {
    return {};
  }

  return data.reduce((result: RowSelectionState, item) => {
    if (expandedIds[item.id]) {
      result[item.id] = true;
    }

    return result;
  }, {});
};
