import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ResourceSelectionType } from "./utils/types/selectedResources";
import {
  AccountId,
  AccountResourceFilter2,
  ProviderType,
  ResourceType,
} from "../../services/cloudchipr.api";

export type LiveUsagePageStateType = "uninitialized" | "pending" | "fulfilled";
export type AppliedFilterType = Partial<
  Record<ResourceType, AccountResourceFilter2>
>;

export interface LiveUsageMgmtSlice {
  accountIds: AccountId[] | null;
  state: LiveUsagePageStateType;
  regions: string[] | null;
  selected: ResourceSelectionType;
  viewWithoutFiltersResourceTypes: Partial<Record<ResourceType, boolean>>;
  filterTemplatesEnabled: boolean;
  provider: ProviderType | null;
  appliedFilterTemplateId: string | null;
  isPageLoading: boolean;
  filters: {
    appliedFilters: AppliedFilterType;
    filterChangedManually: boolean;
  };
}

const initialState: LiveUsageMgmtSlice = {
  accountIds: null,
  provider: null,
  state: "uninitialized",
  isPageLoading: false,
  regions: null,
  selected: {},
  viewWithoutFiltersResourceTypes: {},
  filterTemplatesEnabled: true,
  appliedFilterTemplateId: null,
  filters: {
    appliedFilters: {},
    filterChangedManually: false,
  },
};

const liveUsageMgmtSlice = createSlice({
  name: "liveUsageMgmt",
  initialState,
  reducers: {
    setLiveUsageMgmtProvider: (
      state,
      action: PayloadAction<LiveUsageMgmtSlice["provider"]>,
    ) => {
      state.provider = action.payload;
    },

    setLiveUsageMgmtAppliedFilterTemplateId: (
      state,
      action: PayloadAction<string | null>,
    ) => {
      state.appliedFilterTemplateId = action.payload;
      state.filters.filterChangedManually = !action.payload;
    },

    setLiveUsageMgmtAppliedFilters: (
      state,
      action: PayloadAction<AccountResourceFilter2>,
    ) => {
      const filters = action.payload;

      state.filters = {
        ...state.filters,
        appliedFilters: {
          ...state.filters.appliedFilters,
          [filters.type]: filters,
        },
      };
    },
    setLiveUsageMgmtFilterTemplatesEnabled: (
      state,
      action: PayloadAction<boolean>,
    ) => {
      state.filterTemplatesEnabled = action.payload;
    },

    setLiveUsageMgmtFilterChangedManually: (
      state,
      action: PayloadAction<boolean | undefined>,
    ) => {
      if (action.payload === undefined) {
        state.filters.filterChangedManually = true;
      } else {
        state.filters.filterChangedManually = action.payload;
      }
    },
    setLiveUsageMgmtPageState: (
      state,
      action: PayloadAction<LiveUsagePageStateType>,
    ) => {
      state.state = action.payload;
    },
    setLiveUsageMgmtIsPageLoading: (state, action: PayloadAction<boolean>) => {
      state.isPageLoading = action.payload;
    },
    setLiveUsageMgmtViewWithoutFiltersResourceTypes: (
      state,
      action: PayloadAction<{
        resourceType: ResourceType;
        value: boolean;
      }>,
    ) => {
      const { resourceType, value } = action.payload;
      state.viewWithoutFiltersResourceTypes[resourceType] = value;
    },

    resetLiveUsageMgmtViewWithoutFiltersResourceTypes: (state) => {
      state.viewWithoutFiltersResourceTypes = {};
    },

    setLiveUsageMgmtRegion: (state, action: PayloadAction<string[] | null>) => {
      state.regions = action.payload;
    },

    setAccountIds: (
      state,
      action: PayloadAction<LiveUsageMgmtSlice["accountIds"]>,
    ) => {
      state.accountIds = action.payload;
    },

    setLiveUsageMgmtSelectedResources: (
      state,
      action: PayloadAction<{
        resourceType: ResourceType;
        selectedResources: Record<string, boolean>;
        parentSource: string;
      }>,
    ) => {
      const { resourceType, selectedResources, parentSource } = action.payload;

      state.selected[resourceType] = {
        ...state.selected[resourceType],
        [parentSource]: { ...selectedResources },
      };
    },
    resetLiveUsageMgmtSelectedResources: (
      state,
      action: PayloadAction<ResourceType>,
    ) => {
      state.selected[action.payload] = {};
    },
    resetLiveUsageMgmtAllSelectedResources: (state) => {
      state.selected = {};
    },

    resetLiveUsageMgmtSlice: () => initialState,
  },
});

export const {
  setAccountIds,
  setLiveUsageMgmtProvider,
  setLiveUsageMgmtPageState,
  setLiveUsageMgmtFilterTemplatesEnabled,
  setLiveUsageMgmtViewWithoutFiltersResourceTypes,
  setLiveUsageMgmtAppliedFilterTemplateId,
  setLiveUsageMgmtRegion,
  resetLiveUsageMgmtViewWithoutFiltersResourceTypes,
  setLiveUsageMgmtSelectedResources,
  resetLiveUsageMgmtSlice,
  setLiveUsageMgmtFilterChangedManually,
  setLiveUsageMgmtAppliedFilters,
  resetLiveUsageMgmtSelectedResources,
  resetLiveUsageMgmtAllSelectedResources,
  setLiveUsageMgmtIsPageLoading,
} = liveUsageMgmtSlice.actions;

export default liveUsageMgmtSlice.reducer;
