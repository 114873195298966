import { Dispatch, FC, SetStateAction, useCallback } from "react";
import {
  useContextCalendars,
  useContextDatePickerOffsetPropGetters,
} from "@rehookify/datepicker";
import { Button, IconButton, Stack } from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { DPOffsetValue } from "@rehookify/datepicker/dist/types/offset";

type OffSetValue = keyof DPOffsetValue;

interface DateRangeMonthAndYearProps {
  label?: string;
  offSet: OffSetValue;
  setOffSet: Dispatch<SetStateAction<OffSetValue>>;
}

export const DateRangeMonthAndYear: FC<DateRangeMonthAndYearProps> = ({
  offSet,
  setOffSet,
  label,
}) => {
  const { calendars } = useContextCalendars();
  const { addOffset, subtractOffset } = useContextDatePickerOffsetPropGetters();

  const { month, year } = calendars[0];
  const leftProps = subtractOffset({ [offSetValueByOffSet[offSet]]: 1 });
  const rightProps = addOffset({ [offSetValueByOffSet[offSet]]: 1 });

  const offSetChangeHandler = useCallback(() => {
    setOffSet((offSet) => {
      if (offSet === "days") {
        return "months";
      }

      if (offSet === "months") {
        return "years";
      }

      return "days";
    });
  }, [setOffSet]);

  return (
    <Stack
      direction="row"
      px={1.5}
      justifyContent="space-between"
      alignItems="center"
    >
      {offSet !== "years" && (
        <IconButton size="small" {...leftProps}>
          <ChevronLeftIcon
            fontSize="small"
            color={leftProps.disabled ? "disabled" : "action"}
          />
        </IconButton>
      )}

      <Stack direction="row" spacing={1} justifyContent="center" flexGrow={1}>
        <Button
          color="tertiary"
          onClick={offSetChangeHandler}
          sx={{ color: "text.primary", textTransform: "none" }}
        >
          {label ??
            `${offSet === "days" ? month : ""} ${
              offSet === "days" || offSet === "months" ? year : ""
            } ${offSet === "years" ? "Year" : ""}`}
        </Button>
      </Stack>

      {offSet !== "years" && (
        <IconButton size="small" {...rightProps}>
          <ChevronRightIcon
            fontSize="small"
            color={rightProps.disabled ? "disabled" : "action"}
          />
        </IconButton>
      )}
    </Stack>
  );
};

const offSetValueByOffSet: Record<OffSetValue, OffSetValue> = {
  days: "months",
  months: "years",
  years: "years",
};
