import { SvgIcon, SvgIconProps, useTheme } from "@mui/material";

function EKSSvgIcon({ color, ...props }: SvgIconProps) {
  const { palette } = useTheme();

  const fill =
    color === "primary" ? palette.primary.main : palette.action.active;

  return (
    <SvgIcon {...props}>
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.0241 6.07068L8.34042 7.84613L10.1717 9.78787H9.48841L7.81016 8.01241V9.78787H7.25257V6.07068H7.81016V7.73349L9.36814 6.07068H10.0241ZM14.289 9.85134L12.5133 8.48265V5.93658C12.5133 5.78192 12.4322 5.63799 12.2982 5.55664L9.75627 4.01094V2.04596L14.289 4.67964V9.85134ZM14.9769 4.04044L9.53305 0.876633C9.39274 0.795281 9.2169 0.793493 9.07477 0.873057C8.93355 0.952622 8.84517 1.10013 8.84517 1.26105V4.25947C8.84517 4.41413 8.92717 4.55806 9.06019 4.63941L11.6022 6.18511V8.69989C11.6022 8.83667 11.6668 8.9663 11.7771 9.05122L14.463 11.1235C14.545 11.187 14.6434 11.2191 14.7445 11.2191C14.812 11.2191 14.8803 11.2039 14.9432 11.1735C15.0999 11.0993 15.2001 10.9429 15.2001 10.7721V4.42486C15.2001 4.26662 15.1154 4.12001 14.9769 4.04044ZM8.43153 14.2632L2.5112 11.3032V4.67875L7.02844 2.03434V4.00021L4.54477 5.54323C4.41358 5.62548 4.3334 5.76762 4.3334 5.92139V10.031C4.3334 10.2009 4.43089 10.3555 4.58669 10.4315L8.17916 12.1784C8.30671 12.241 8.45886 12.2392 8.58733 12.1757L11.4072 10.7677L13.3014 12.3339L8.43153 14.2632ZM11.7625 9.89336C11.624 9.77803 11.4245 9.75658 11.2623 9.83704L8.37778 11.2772L5.2445 9.7539V6.16634L7.72725 4.62332C7.85936 4.54107 7.93954 4.39893 7.93954 4.24517V1.24764C7.93954 1.08672 7.85116 0.938318 7.70903 0.858754C7.56507 0.778295 7.38923 0.780977 7.25074 0.864118L1.82241 4.04044C1.68483 4.1209 1.6001 4.26662 1.6001 4.42486V11.5767C1.6001 11.7448 1.69576 11.8986 1.84883 11.9746L8.20284 15.1518C8.26662 15.184 8.33769 15.2 8.40966 15.2C8.46706 15.2 8.52446 15.1893 8.58004 15.1679L14.3446 12.8855C14.4922 12.8265 14.5988 12.696 14.6243 12.5404C14.6489 12.3858 14.5897 12.2293 14.4676 12.1283L11.7625 9.89336Z"
          fill={fill}
          fillOpacity="0.87"
        />
      </svg>
    </SvgIcon>
  );
}

EKSSvgIcon.muiName = "EKSSvgIcon";

export default EKSSvgIcon;
