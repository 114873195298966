import { createAsyncThunk } from "@reduxjs/toolkit";
import { Layout, Layouts } from "react-grid-layout";
import { RootState } from "../../../../store";
import { currentDashboardLayoutSelector } from "../../../selectors/dashboard/currentDashboardLayoutSelector";
import { DashboardLayoutBreakpoint } from "../../../utils/types/types";
import { currentDashboardIdSelector } from "../../../selectors/dashboard/currentDashboardIdSelector";
import { updateDashboardByIdLayoutThunk } from "../../dashboard/updateDashboardByIdLayoutThunk";
import { generateBreakpointLayoutById } from "../../../utils/helpers/dashboardLayout";

export const addNewWidgetToLayoutThunk = createAsyncThunk(
  "dashboards/addNewWidgetToLayoutThunk",
  async (widgetId: string, { dispatch, getState }) => {
    const state = getState() as RootState;

    const layouts = currentDashboardLayoutSelector(state);
    const dashboardId = currentDashboardIdSelector(state);

    if (!layouts || !dashboardId) {
      return;
    }

    const keys = Object.keys(layouts) as DashboardLayoutBreakpoint[];

    const lastLayouts = keys.reduce(
      (acc, key) => {
        const last = findLastLayout(layouts, key);

        if (last) {
          acc[key] = last;
        }

        return acc;
      },
      {} as Record<DashboardLayoutBreakpoint, Layout>,
    );

    const newLayout = keys.reduce((acc, key) => {
      const lastLayoutY: number | undefined = lastLayouts?.[key]?.y;
      const params = lastLayoutY ? { y: lastLayoutY + 1 } : undefined;

      acc[key] = [
        ...layouts[key],
        generateBreakpointLayoutById(key, widgetId, params),
      ];

      return acc;
    }, {} as Layouts);

    await dispatch(updateDashboardByIdLayoutThunk({ dashboardId, newLayout }));
  },
);

const findLastLayout = (layouts: Layouts, key: DashboardLayoutBreakpoint) => {
  let maxY = 0;
  let maxYIndex = 0;

  layouts[key].forEach((ly, index) => {
    if (ly.y > maxY) {
      maxY = ly.y;
      maxYIndex = index;
    }
  });

  return layouts[key].at(maxYIndex);
};
