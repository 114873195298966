import { FC, useCallback, useState } from "react";
import {
  Grid,
  IconButton,
  ListItemText,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { Threshold } from "../../../../../../../../../../services/cloudchipr.api";
import { useAppSelector } from "../../../../../../../../../../store/hooks";
import { currentBudgetAmountSelector } from "../../../../../../../../../../store/budgets/selectors/current-budget/data-selectors/currentBudgetAmountSelector";
import {
  getNumberByPercentage,
  getPercentage,
} from "../../../../../../../../../../utils/numeral/getPercentage";
import { capitalizeString } from "../../../../../../../../../../utils/helpers/capitalizeString";
import { fixNumber } from "../../../utils/helpers/common";
import { currentBudgetPeriodSelector } from "../../../../../../../../../../store/budgets/selectors/current-budget/data-selectors/currentBudgetPeriodSelector";

interface ThresholdItemProps {
  index: number;
  removable: boolean;
  threshold: Threshold;
  onRemove(index: number): void;
  onChange(threshold: Threshold, index: number): void;
}

export const ThresholdItem: FC<ThresholdItemProps> = ({
  threshold,
  index,
  onRemove,
  onChange,
  removable,
}) => {
  const budgetAmount = useAppSelector(currentBudgetAmountSelector);
  const budgetPeriod = useAppSelector(currentBudgetPeriodSelector);
  const forecastAvailable =
    budgetPeriod === "monthly" || budgetPeriod === "quarterly";

  const [amount, setAmount] = useState(
    getNumberByPercentage(threshold.percent, budgetAmount),
  );

  const amountChangeHandler = useCallback(
    (event: any) => {
      const value = +event.target.value;

      if (value < 0) {
        return;
      }
      if (value > budgetAmount) {
        return;
      }

      setAmount(fixNumber(value, 2));

      onChange(
        {
          trigger: threshold.trigger,
          percent: fixNumber(getPercentage(value, budgetAmount), 2),
        },
        index,
      );
    },
    [onChange, index, threshold.trigger, budgetAmount],
  );

  const percentageChangeHandler = useCallback(
    (event: any) => {
      const value = +event.target.value;

      if (value < 0) {
        return;
      }
      setAmount(fixNumber(getNumberByPercentage(value, budgetAmount), 2));

      onChange(
        {
          trigger: threshold.trigger,
          percent: fixNumber(value, 2),
        },
        index,
      );
    },
    [onChange, index, threshold.trigger, budgetAmount],
  );

  const triggerChangeHandler = useCallback(
    (event: any) => {
      const value = event.target.value;

      onChange({ ...threshold, trigger: value }, index);
    },
    [onChange, index, threshold],
  );

  const removeHandler = useCallback(() => onRemove(index), [index, onRemove]);
  const xs = removable ? 3 : 3.5;

  return (
    <Grid container alignItems="end" gap={2}>
      <Grid item xs={xs}>
        <Typography variant="body2" mb={1}>
          Threshold*
        </Typography>

        <TextField
          type="number"
          size="small"
          variant="outlined"
          placeholder="0"
          InputProps={{
            startAdornment: (
              <Typography pr={1} color="text.secondary">
                %
              </Typography>
            ),
          }}
          value={threshold.percent || ""}
          onChange={percentageChangeHandler}
        />
      </Grid>

      <Grid item xs={xs}>
        <Typography variant="body2" mb={1}>
          Amount*
        </Typography>

        <TextField
          type="number"
          size="small"
          variant="outlined"
          value={amount || ""}
          onChange={amountChangeHandler}
          placeholder="0"
          InputProps={{
            startAdornment: (
              <Typography pr={1} color="text.secondary">
                $
              </Typography>
            ),
          }}
        />
      </Grid>

      <Grid item xs={xs}>
        <Typography variant="body2" mb={1}>
          Trigger
        </Typography>

        <Select
          fullWidth
          size="small"
          value={threshold.trigger}
          onChange={triggerChangeHandler}
          renderValue={(val) => capitalizeString(val)}
          disabled={!forecastAvailable}
          MenuProps={{
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left",
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "left",
            },
          }}
        >
          <MenuItem value="actual">
            <ListItemText>Actual</ListItemText>
          </MenuItem>

          <MenuItem value="forecasted">
            <ListItemText>Forecasted</ListItemText>
          </MenuItem>
        </Select>
      </Grid>

      {removable && (
        <Grid item xs={1}>
          <IconButton onClick={removeHandler}>
            <DeleteOutlineIcon />
          </IconButton>
        </Grid>
      )}
    </Grid>
  );
};
