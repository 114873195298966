import {
  BaseQueryFn,
  createApi,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
} from "@reduxjs/toolkit/query/react";
import { RootState } from "../store/store";
import { authTokenSelector } from "../store/auth/authSelectors";
import { setLogout } from "../store/auth/authSlice";

const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_API_HOST,
  prepareHeaders: (headers, { getState }) => {
    headers.set("Content-type", "application/json");

    const token = authTokenSelector(getState() as RootState);
    headers.set("authorization", `Bearer ${token}`);

    return headers;
  },
});

const baseQueryWithReAuth: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  const result = await baseQuery(args, api, extraOptions);

  // this should be removed after unauthenticatedMiddleware will be tested
  if (result.error && result.error.status === 401) {
    api.dispatch(setLogout(true));
  }
  return result;
};

// initialize an empty api service that we'll inject endpoints into later as needed
export const emptySplitApi = createApi({
  reducerPath: "cloudchipr",
  baseQuery: baseQueryWithReAuth,
  endpoints: () => ({}),
});
