import moment from "moment/moment";
import { getForecastedTrendTooltipTitle } from "./getForecastedTrendTooltipTitle";
import { getTrendTooltipTitleByDateLabel } from "./getTrendTooltipTitleByDateLabel";
import { formatDate } from "../../../../../../utils/helpers/date-time/datetime-format";
import { DateLabelNullable } from "../../../../../../services/cloudchipr.api";
import { CostAndUsageSumWidgetFilterType } from "../../../../../../store/dashboards/utils/constants/costAndUsageSetupDefaultData";
import { ForecastedType } from "../../types/types";

export const getTrendTooltipTitle = (
  currentDatesFrom?: string,
  currentDatesTo?: string,
  previousDatesFrom?: string,
  previousDatesTo?: string,
  costAndUsageSumWidgetFilterType?: CostAndUsageSumWidgetFilterType,
  dateLabel?: DateLabelNullable,
): string => {
  const currentDatePeriod = getDatePeriod(currentDatesFrom, currentDatesTo);
  const previousDatesPeriod = getDatePeriod(previousDatesFrom, previousDatesTo);

  const forecastedTypes: ForecastedType[] = [
    "forecasted_month_end_cost",
    "forecasted_quarter_end_cost",
  ];

  if (
    costAndUsageSumWidgetFilterType &&
    forecastedTypes.includes(costAndUsageSumWidgetFilterType as ForecastedType)
  ) {
    return getForecastedTrendTooltipTitle(
      currentDatePeriod,
      previousDatesPeriod,
      (costAndUsageSumWidgetFilterType as ForecastedType) ===
        "forecasted_quarter_end_cost"
        ? "quarter"
        : "month",
    );
  }

  return getTrendTooltipTitleByDateLabel(
    currentDatePeriod,
    previousDatesPeriod,
    dateLabel,
  );
};

const getDatePeriod = (from?: string, to?: string) =>
  `${formatDate(moment(from), {
    type: "date",
  })} - ${formatDate(moment(to), {
    type: "date",
  })}`;
