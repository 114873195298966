import { selectedReservedInstancePlanUUIdSelector } from "./reserved-instance/selectedReservedInstancePlanUUIdSelector";
import { reservationSelectedCoverageDataSelector } from "./resource-type/reservationSelectedCoverageDataSelector";
import { commitmentsDetailsDrawerTargetTypeSelector } from "../../common/details-drawer/commitmentsDetailsDrawerTargetTypeSelector";
import { GetUsersMeOrganisationsCurrentReservationsDataApiArg } from "../../../../../services/cloudchipr.api";
import { RootState } from "../../../../store";
import { commitmentsDetailsDrawerPayloadDataSelector } from "../../common/details-drawer/commitmentsDetailsDrawerPayloadDataSelector";

export const reservationsDetailsPayloadSelector = (
  state: RootState,
): GetUsersMeOrganisationsCurrentReservationsDataApiArg | undefined => {
  const type = commitmentsDetailsDrawerTargetTypeSelector(state);
  const commitmentPayload = commitmentsDetailsDrawerPayloadDataSelector(state);

  if (!type) {
    return;
  }

  const payloadData: GetUsersMeOrganisationsCurrentReservationsDataApiArg = {
    type,
    commitmentType: "reservation",
    ...commitmentPayload,
  };

  if (type === "commitment") {
    payloadData.commitmentId = selectedReservedInstancePlanUUIdSelector(state);
  }

  if (type === "account") {
    const coverageData = reservationSelectedCoverageDataSelector(state);
    payloadData.accountId = coverageData?.account?.id;
  }

  if (type === "resource_type") {
    const coverageData = reservationSelectedCoverageDataSelector(state);
    payloadData.resourceType = coverageData?.resource_type?.resource_type;
    payloadData.region = coverageData?.resource_type?.region ?? undefined;
    payloadData.platform = coverageData?.resource_type?.platform ?? undefined;
    payloadData.instanceType =
      coverageData?.resource_type?.instance_type ?? undefined;
  }

  return payloadData;
};
