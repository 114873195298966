import {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
  ReactNode,
} from "react";
import { TableCell, Theme } from "@mui/material";
import { SxProps } from "@mui/system";
import { useEffectOnceWhen, useWillUnmount } from "rooks";
import { getStickyCellStyles } from "../utils/helpers";

interface TableStickyCellProps {
  isLeftLastSticky: boolean;
  isRightFirstSticky: boolean;
  side?: "left" | "right";
  customStyles?: SxProps<Theme>;
  multiplySticky?: boolean;
  children?: ReactNode;
}

export const TableStickyCell: FC<TableStickyCellProps> = ({
  isLeftLastSticky,
  isRightFirstSticky,
  customStyles,
  side,
  children,
  multiplySticky,
}) => {
  const ref = useRef<HTMLTableCellElement>(null);
  const [left, setLeft] = useState<number | null>(null);

  const listener = useCallback(() => {
    setLeft(null);
  }, []);

  const styles: SxProps<Theme> = useMemo(() => {
    const stickyStyles = getStickyCellStyles(
      left,
      isLeftLastSticky || isRightFirstSticky,
      side,
      side === "right" ? 0 : undefined,
    );

    return left !== null || side === "right"
      ? { ...stickyStyles, bgcolor: "inherit" }
      : {
          ...stickyStyles,
          whiteSpace: "nowrap",
        };
  }, [isLeftLastSticky, isRightFirstSticky, left, side]);

  useEffect(() => {
    if (!ref.current || left !== null || side === "right") {
      return;
    }

    const parentLeft =
      ref.current.parentElement?.getBoundingClientRect()?.x || 0;
    setLeft(ref.current?.getBoundingClientRect()?.x - parentLeft);
  }, [left, side]);

  useEffectOnceWhen(() => {
    window.addEventListener("resize", listener);
  }, multiplySticky);

  useWillUnmount(() => {
    window.removeEventListener("resize", listener);
  });

  return (
    <TableCell ref={ref} sx={{ ...styles, ...customStyles }}>
      {children}
    </TableCell>
  );
};
