import { useCallback, useEffect, useState } from "react";
import { RecommendationsTabType } from "../../components/off-hours-recommendations/components/OffHoursRecommendationsContent";
import {
  setRecommendationsAccountFilter,
  setRecommendationsProviderFilter,
  setRecommendationsTypeFilter,
} from "../../../../../store/recommendations/recommendationsSlice";
import { useAppDispatch } from "../../../../../store/hooks";

export function useRecommendationsActiveTabControllerHook(
  loading: boolean,
  activeLength?: number,
  dismissedLength?: number,
  dismissedResourcesLength?: number,
) {
  const dispatch = useAppDispatch();
  const [activeTab, setActiveTab] = useState<RecommendationsTabType>("active");

  const tabChangeHandler = useCallback(
    (props: any) => {
      dispatch(setRecommendationsProviderFilter([]));
      dispatch(setRecommendationsAccountFilter([]));
      dispatch(setRecommendationsTypeFilter([]));

      setActiveTab(props);
    },
    [dispatch],
  );

  useEffect(() => {
    if (loading) {
      return;
    }

    const lengthsByTab = {
      active: activeLength,
      dismissed: dismissedLength,
      dismissed_resources: dismissedResourcesLength,
    };

    const activeTabHasItems = !!lengthsByTab[activeTab];

    if (activeTabHasItems) {
      return;
    }

    const availableTabs = Object.entries(lengthsByTab).filter(
      ([, length]) => !!length,
    );

    const firstAvailableTab = availableTabs?.[0]?.[0] as RecommendationsTabType;

    if (firstAvailableTab) {
      setActiveTab(firstAvailableTab);
    }
  }, [
    activeTab,
    loading,
    activeLength,
    dismissedLength,
    dismissedResourcesLength,
  ]);

  return {
    activeTab,
    setActiveTab: tabChangeHandler,
  };
}
