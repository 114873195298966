import { reportsDataPropertyByKeySelector } from "./reportsDataPropertyByKeySelector";
import { RootState } from "../../store";
import { MonthlyByWeekdaySchedule } from "../../../services/cloudchipr.api";

export const reportsScheduleNumberOfWeeksSelector = (state: RootState) => {
  const schedule = reportsDataPropertyByKeySelector("schedule")(
    state,
  ) as MonthlyByWeekdaySchedule;

  return schedule.number_of_week;
};
