import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  cloudChiprApi,
  PatchUsersMeAccountsByAccountIdResourcesApiArg,
} from "../../../services/cloudchipr.api";
import { generateScheduleResourcesProtectionFixedCacheKey } from "../../../components/pages/schedule/common/utils/helpers/fixedCacheKeys";

export type ScheduleProtectResources = Record<
  string,
  PatchUsersMeAccountsByAccountIdResourcesApiArg["body"]
>;

export const resourceProtectThunk = createAsyncThunk(
  "schedule/resourceProtectThunk",
  async (resourcesToProtect: ScheduleProtectResources, { dispatch }) => {
    const selectedResourcesAccountIds = Object.keys(resourcesToProtect) ?? [];
    let protect: boolean | null = null;

    const { patchUsersMeAccountsByAccountIdResources } =
      cloudChiprApi.endpoints;

    const promises = selectedResourcesAccountIds.map((accountId) => {
      const body = resourcesToProtect[accountId];

      protect = !!body?.at(0)?.is_protected;

      return dispatch(
        patchUsersMeAccountsByAccountIdResources.initiate(
          {
            accountId,
            body: body,
          },
          {
            fixedCacheKey:
              generateScheduleResourcesProtectionFixedCacheKey(accountId),
          },
        ),
      ).unwrap();
    });

    await Promise.all(promises);

    return protect;
  },
);
