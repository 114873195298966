import { FC, useCallback } from "react";
import { CommitmentsFilterSelect } from "./CommitmentsFilterSelect";
import { termFilterOptions } from "./utils/contants/filter-options/recommendationsFiltersOptions";
import { setCommitmentsRecommendationsTerm } from "../../../../../../../../store/commitments/commitmentsSlice";
import { SavingsPlanTermInYears } from "../../../../../../../../services/cloudchipr.api";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../store/hooks";
import { commitmentsRecommendationsSavingsPlanTermSelector } from "../../../../../../../../store/commitments/selectors/recommendations/filters/commitmentsRecommendationsSavingsPlanTermSelector";

export const CommitmentsTermFilter: FC = () => {
  const dispatch = useAppDispatch();
  const value = useAppSelector(
    commitmentsRecommendationsSavingsPlanTermSelector,
  );

  const changeHandler = useCallback(
    (term: string[]) => {
      dispatch(
        setCommitmentsRecommendationsTerm(term as SavingsPlanTermInYears[]),
      );
    },
    [dispatch],
  );

  if (!value) {
    return null;
  }

  return (
    <CommitmentsFilterSelect
      selectedValues={value}
      label="Savings Plans Term"
      options={termFilterOptions}
      onChange={changeHandler}
      showSearch={false}
    />
  );
};
