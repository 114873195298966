// @ts-expect-error | no types
import moment from "moment-fquarter";
import { preDefinedLabelsByDateLabel } from "./preDefinedLabelsByDateLabel";
import { PreDefinedRange } from "../constants/types";
import { DateLabel } from "../../../../../services/cloudchipr.api";

const getQuarterRange = (
  quarterStartMonth: number,
  dateLabel: DateLabel,
): PreDefinedRange | null => {
  const currentQuarter = moment().fquarter(quarterStartMonth);

  let dateTo;
  let dateFrom;

  if (dateLabel === "last_quarter") {
    dateFrom = moment(currentQuarter.start)
      .subtract(3, "month")
      .startOf("month");

    dateTo = moment(currentQuarter.end).subtract(3, "month").endOf("month");
  } else if (dateLabel === "quarter_to_date") {
    dateFrom = moment(currentQuarter.start).startOf("month");
    dateTo = moment().endOf("day");
  } else {
    return null;
  }

  return {
    dateLabel,
    dateTo: dateTo?.format(),
    dateFrom: dateFrom?.format(),
    label: preDefinedLabelsByDateLabel[dateLabel].long,
    shortLabel: preDefinedLabelsByDateLabel[dateLabel].short,
  };
};

export const getQuarterlyPreDefinedRange = (
  label: DateLabel,
  startFromMonth?: number,
): PreDefinedRange | null => getQuarterRange(startFromMonth ?? 1, label);
