import { RootState } from "../../../store";
import { cloudChiprApi } from "../../../../services/cloudchipr.api";
import { taskCreationFixedCacheKey } from "../../utils/constants/fixedCacheKeys";

const selector =
  cloudChiprApi.endpoints.postUsersMeOrganisationsCurrentTasks.select(
    taskCreationFixedCacheKey,
  );

export const taskCreationLoadingSelector = (state: RootState) => {
  return selector(state)?.isLoading;
};
