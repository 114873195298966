import { FC } from "react";
import { TextField } from "@mui/material";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { FilterTriggerComponentProps } from "../../../../../../../../common/select/dropdown-select/utils/types/filterTriggerComponentProps";

interface FilterKeySelectTriggerComponentProps
  extends FilterTriggerComponentProps {
  renderValue(value: string): string;
}

export const FilterKeySelectTriggerComponent: FC<
  FilterKeySelectTriggerComponentProps
> = ({
  selectedValues,
  onClick,
  label,
  renderValue,
  error,
  disabled,
  open,
}) => {
  return (
    <TextField
      fullWidth
      label={label}
      size="xsmall"
      focused={open}
      error={!!error}
      disabled={disabled}
      onClick={onClick}
      value={renderValue(selectedValues.at(0) ?? "")}
      InputProps={{
        readOnly: true,
        endAdornment: open ? (
          <ArrowDropUpIcon fontSize="small" sx={{ color: "action" }} />
        ) : (
          <ArrowDropDownIcon fontSize="small" sx={{ color: "action" }} />
        ),
      }}
    />
  );
};
