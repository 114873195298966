import { FC, useCallback, useMemo } from "react";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import {
  ResourceExplorerDynamicFilterItemType,
  ResourceExplorerFilterProvider,
  useGetUsersMeOrganisationsCurrentResourceExplorerFiltersByFilterProviderAndFilterTypeValuesQuery as useValuesQuery,
} from "../../../../../../../../../services/cloudchipr.api";
import { FilterItemNodeWithId } from "../../../../../utils/types/common";
import { findAdditionalSelectedValues } from "../../../../../utils/helpers/findAdditionalSelectedValues";

interface FilterValueSingleSelectProps {
  filterType: ResourceExplorerDynamicFilterItemType;
  filterValue: string;
  filterLabel: string;
  provider: ResourceExplorerFilterProvider;
  onChange(value: FilterItemNodeWithId["value"]): void;
}

export const FilterValueSingleSelect: FC<FilterValueSingleSelectProps> = ({
  provider,
  filterType,
  onChange,
  filterValue,
  filterLabel,
}) => {
  const { data } = useValuesQuery({
    filterProvider: provider,
    filterType: filterType,
  });

  const additionalValues = useMemo(() => {
    return findAdditionalSelectedValues(filterValue ? [filterValue] : [], data);
  }, [filterValue, data]);

  const options = useMemo(() => {
    return additionalValues ? [...(data ?? []), ...additionalValues] : data;
  }, [additionalValues, data]);

  const changeHandler = useCallback(
    (event: SelectChangeEvent) => {
      onChange(event.target.value);
    },
    [onChange],
  );

  return (
    <FormControl fullWidth size="xsmall">
      <InputLabel>{filterLabel}</InputLabel>

      <Select label={filterLabel} value={filterValue} onChange={changeHandler}>
        {options?.map((option) => {
          return (
            <MenuItem value={option.value} key={option.value}>
              {option.title ?? option.value}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};
