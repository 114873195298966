import { FC, useCallback } from "react";
import { Stack, Typography } from "@mui/material";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../store/hooks";
import { applyFilterTemplateToAccountsThunk } from "../../../../../../../store/filters/thunks/filter-set/applyFilterTemplateToAccountsThunk";
import { getAccountsThunk } from "../../../../../../../store/accounts/thunks/getAccountsThunk";
import { ProviderType } from "../../../../../../../services/cloudchipr.api";
import { filterTemplatesSelector } from "../../../../../../../store/filters/selectors/filter-set/filterTemplatesSelector";
import { organisationCurrentFilterSetsLoadingSelector } from "../../../../../../../store/filters/selectors/current-filter-sets/organisationCurrentFilterSetsLoadingSelector";
import { LiveUsageMgmtFilterTemplateDropDownContent } from "../../../../../live-usage-mgmt/components/filter-templates/templates-dropdown/LiveUsageMgmtFilterTemplateDropDownContent";

interface AccountCardFilterTemplatePopoverContentProps {
  accountId: string;
  onClose(): void;
  provider: ProviderType;
  filterSetId: string | null;
}

export const AccountCardFilterTemplatePopoverContent: FC<
  AccountCardFilterTemplatePopoverContentProps
> = ({ accountId, onClose, filterSetId, provider }) => {
  const dispatch = useAppDispatch();

  const onClick = useCallback((event: any) => {
    event.preventDefault();
  }, []);

  const loading = useAppSelector(organisationCurrentFilterSetsLoadingSelector);

  const filterTemplates = useAppSelector((state) =>
    filterTemplatesSelector(state, provider),
  );

  const applyClickHandler = useCallback(
    async (filterSetId: string) => {
      await dispatch(
        applyFilterTemplateToAccountsThunk({
          filterSetId,
          accountIds: [accountId],
        }),
      );
      dispatch(getAccountsThunk());
      onClose();
    },
    [dispatch, accountId, onClose],
  );

  return (
    <Stack onClick={onClick}>
      <LiveUsageMgmtFilterTemplateDropDownContent
        onApplyFilterTemplate={applyClickHandler}
        appliedFilterSetId={filterSetId ?? ""}
        provider={provider}
        filterTemplates={filterTemplates}
        loading={loading}
        header={
          <Typography variant="subtitle2" px={2} py={1}>
            Filter Templates
          </Typography>
        }
      />
    </Stack>
  );
};
