import { FC } from "react";
import { Stack, Typography } from "@mui/material";

import { EmailIntegrationMessageNotifySubject } from "./EmailIntegrationMessageNotifySubject";
import { EmailIntegrationNotifyWorkflowInfo } from "./EmailIntegrationNotifyWorkflowInfo";
import { EmailIntegrationCsvInfo } from "../common/EmailIntegrationCsvInfo";
import { integrationCustomEmailActionExecuteDataGrid } from "../utils/constants";
import { IntegrationCustomMessageDataGrid } from "../../common/IntegrationCustomMessageDataGrid";

interface EmailIntegrationMessageNotifyProps {
  message: string;
}
export const EmailIntegrationMessageNotify: FC<
  EmailIntegrationMessageNotifyProps
> = ({ message }) => {
  return (
    <Stack spacing={1}>
      <EmailIntegrationMessageNotifySubject />
      <EmailIntegrationNotifyWorkflowInfo />

      <Typography
        variant="body1"
        dangerouslySetInnerHTML={{ __html: message }}
      />
      <IntegrationCustomMessageDataGrid
        data={integrationCustomEmailActionExecuteDataGrid}
        label="Account Name / ID"
      />
      <EmailIntegrationCsvInfo />
    </Stack>
  );
};
