import { FC, ReactNode, useCallback, useMemo } from "react";
import { Grid } from "@mui/material";
import { FormikHelpers } from "formik";
import * as yup from "yup";
import { FilterItem } from "../../../../../../../services/cloudchipr.api";
import { AutocompleteSelect } from "../../../../../../common/select/autocomplete-select/AutocompleteSelect";
import { multipleValueSelectOperators } from "../../../utils/constants/filter-types/autocomplete-multipe-selection-operator-types";
import { DropdownSelectOption } from "../../../../../../common/select/dropdown-select/utils/types/types";
import { TypographyWithTooltip } from "../../../../../../common/TypographyWithTooltip";

export const AutocompleteFilterValueValidation = yup.mixed().when("operator", {
  is: (operator: string) => multipleValueSelectOperators.includes(operator),
  then: yup.array().of(yup.string()).min(1).required(),
  otherwise: yup.string().required(),
});

export interface AutocompleteFilterBodyProps {
  error?: any;
  setFieldValue: FormikHelpers<FilterItem>["setFieldValue"];
}

interface LocalProps extends AutocompleteFilterBodyProps {
  options?: string[];
  label: string;
  selectedValue: string[];
  isLoading: boolean;
  singleSelect?: boolean;
  freeSolo?: boolean;
  creatable?: boolean;
  formatSelectedLabel?(value: string): string;
  renderOption?(option: DropdownSelectOption): ReactNode;
  filterFn?(option: DropdownSelectOption, keyword: string): boolean;
}

export const AutocompleteFilterBody: FC<LocalProps> = ({
  options,
  selectedValue,
  setFieldValue,
  error,
  label,
  isLoading,
  formatSelectedLabel,
  singleSelect,
  freeSolo,
  creatable,
  renderOption,
  filterFn,
}) => {
  const renderOptions = useMemo(() => {
    return options?.map((option) => ({
      value: option,
      label: <TypographyWithTooltip title={option} />,
    }));
  }, [options]);

  const onChange = useCallback(
    (values: string[]) => setFieldValue("value", values),
    [setFieldValue],
  );

  return (
    <Grid item md={6}>
      <AutocompleteSelect
        freeSolo={freeSolo}
        creatable={creatable ?? freeSolo}
        size="xsmall"
        error={!!error}
        label={label}
        placeholder={label}
        onChange={onChange}
        renderOption={renderOption}
        options={renderOptions ?? []}
        optionsLoading={isLoading}
        singleSelect={singleSelect}
        allSelectedLabel="Selected"
        formatSelectedLabel={formatSelectedLabel}
        values={selectedValue ?? []}
        filterFn={filterFn}
        initialSelectedValues={options ? selectedValue : undefined}
      />
    </Grid>
  );
};
