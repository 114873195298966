import { FC, Fragment, useMemo } from "react";
import {
  AreaChart as RechartsAreaChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
  ReferenceLine,
  TooltipProps,
  YAxisProps,
} from "recharts";
import { grey } from "@mui/material/colors";
import { useTheme } from "@mui/material";
import { areaChartAreas } from "./areaChartAreas";
import {
  CustomizedYAxisTick,
  YAxisFormatter,
} from "../../common/CustomizedYAxisTick";
import { useMultiTypeChartContext } from "../../../MultiTypeChartProvider";
import { getYAxisMaxValue } from "../../../utils/helpers/getYAxisMaxValue";
import {
  CustomizedXAxisTick,
  XAxisTickProps,
} from "../../common/CustomizedXAxisTick";
import {
  ChartsTooltipContent,
  ChartsTooltipProps,
} from "../../common/ChartsTooltipContent";
import { ReferenceLineType } from "../../../utils/types/types";
import { chartDataPointKey } from "../../../utils/constants/constants";
import { calculateLabelInterval } from "../../../utils/helpers/helpers";
import { getLoadingLabel } from "../../common/LoadingLabel";
import { LegendContent as MultiTypeChartLegendContent } from "../../common/legend/LegentContent";
import { PieLegendContentProps } from "../pie-chart/PieLegentContent";

interface AreaChartProps {
  highlight: boolean;
  selectable: boolean;
  loading: boolean;
  yAxisFormatter?: YAxisFormatter;
  xAxisTickProps?: XAxisTickProps;
  yAxisProps?: YAxisProps;
  tooltipProps?: ChartsTooltipProps;
  referenceLines?: ReferenceLineType[];
  emptyText?: string;
  TooltipContent?: FC<TooltipProps<any, any>>;
  LegendContent?: FC<PieLegendContentProps>;
}

export const AreaChart: FC<AreaChartProps> = ({
  highlight,
  yAxisFormatter,
  xAxisTickProps,
  selectable,
  tooltipProps,
  referenceLines,
  loading,
  emptyText,
  LegendContent,
  TooltipContent,
  yAxisProps,
}) => {
  const theme = useTheme();
  const { data, keys, colors, hover, chartType } = useMultiTypeChartContext();

  const { maxDataValue: yAxisMaxData, minDataValue: yAxisMinData } = useMemo(
    () => getYAxisMaxValue(data, chartType?.type, referenceLines),
    [data, chartType?.type, referenceLines],
  );

  const isEmpty = !data.length;

  if (!data) {
    return null;
  }

  const legendContentProps = {
    colors,
    selectable,
    highlightable: highlight,
    keys: keys.allKeys,
    visibleKeys: keys.visibleKeys,
    setVisibleKeys: keys.setVisibleKeys,
  };

  return (
    <Fragment>
      <ResponsiveContainer width="100%" height={300}>
        <RechartsAreaChart data={data} height={335} margin={{ top: 20 }}>
          <CartesianGrid
            strokeDasharray="3 3"
            vertical={false}
            strokeOpacity={0.5}
          />

          <XAxis
            height={xAxisTickProps?.parentHeight}
            padding={xAxisTickProps?.parentPadding}
            dataKey={chartDataPointKey}
            strokeOpacity={0.2}
            strokeDasharray="3 3"
            tick={
              <CustomizedXAxisTick
                chartType="area"
                empty={isEmpty}
                xAxisTickProps={xAxisTickProps}
              />
            }
            interval={calculateLabelInterval(data.length)}
          >
            {getLoadingLabel({
              empty: isEmpty,
              loading,
              emptyText,
            })}
          </XAxis>

          <YAxis
            domain={[yAxisMinData, yAxisMaxData]}
            strokeOpacity={0.2}
            strokeDasharray="3 3"
            tick={
              <CustomizedYAxisTick
                empty={isEmpty || loading}
                formatter={yAxisFormatter}
              />
            }
            {...yAxisProps}
          />

          <Tooltip
            cursor={{ fill: isEmpty ? "transparent" : grey[100] }}
            wrapperStyle={{ zIndex: theme.zIndex.tooltip }}
            content={
              TooltipContent ? (
                <TooltipContent />
              ) : (
                <ChartsTooltipContent
                  referenceLines={referenceLines}
                  {...tooltipProps}
                />
              )
            }
          />

          {keys.visibleKeys && (
            <Fragment>
              {areaChartAreas({
                keys: keys.visibleKeys,
                highlighted: hover.key,
                colors,
              })}
            </Fragment>
          )}

          {referenceLines?.map((props) => (
            <ReferenceLine {...props} key={props.uniqueId} />
          ))}
        </RechartsAreaChart>
      </ResponsiveContainer>

      {keys.visibleKeys && (
        <Legend
          wrapperStyle={{ position: "initial", alignSelf: "center" }}
          content={
            LegendContent ? (
              <LegendContent {...legendContentProps} />
            ) : (
              <MultiTypeChartLegendContent {...legendContentProps} />
            )
          }
        />
      )}
    </Fragment>
  );
};
