import { commitmentUtilizationWidgetAggregationSelector } from "./commitmentUtilizationWidgetAggregationSelector";
import { RootState } from "../../../../../store";
import { WidgetCommitmentsUtilizationAggregation } from "../../../../../../services/cloudchipr.api";

export const commitmentUtilizationWidgetHasAggregationSelector = (
  state: RootState,
  widgetId: string,
) => {
  const aggregations = commitmentUtilizationWidgetAggregationSelector(
    state,
    widgetId,
  );

  return Object.keys(aggregations ?? {}).some(
    (key) =>
      !!aggregations?.[key as keyof WidgetCommitmentsUtilizationAggregation],
  );
};
