import { FC, useCallback } from "react";
import { Button } from "@mui/material";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import { useNavigate } from "react-router-dom";

export const BackButton: FC = () => {
  const navigate = useNavigate();

  const backHandler = useCallback(() => {
    if (window.history.length > 1) {
      navigate(-1);
    } else {
      navigate("/budgets");
    }
  }, [navigate]);

  return (
    <Button
      sx={{ mb: 2, color: "black", textTransform: "none" }}
      onClick={backHandler}
      startIcon={<ArrowBackOutlinedIcon color="action" />}
    >
      Back
    </Button>
  );
};
