import {
  Dashboard,
  DashboardFolder,
  ReFolderV2,
  ResourceExplorer,
} from "../../../../services/cloudchipr.api";

export const isHierarchyFolder = (
  data: Dashboard | DashboardFolder | ResourceExplorer | ReFolderV2,
): data is DashboardFolder | ReFolderV2 => {
  return "items" in data;
};
