import { FC } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  Stack,
  TextField,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useFormik } from "formik";
import * as yup from "yup";
import { DialogTitleClosable } from "../../../../../common/dialog-utils/DialogTitleClosable";
import { invitableRoles, userRoleOptions } from "../utils/constants/common";
import { InvitedUserRoles } from "../utils/types/types";

interface UserInviteForm {
  role: InvitedUserRoles;
}

interface ChangeRoleDialogProps {
  open: boolean;
  role: InvitedUserRoles;
  email: string;
  loading?: boolean;
  onClose(): void;
  onSuccess?(role: InvitedUserRoles): Promise<void>;
}

export const ChangeRoleDialog: FC<ChangeRoleDialogProps> = ({
  open,
  role,
  email,
  loading,
  onSuccess,
  onClose,
}) => {
  const formik = useFormik<UserInviteForm>({
    initialValues: { role: role },
    validationSchema: yup.object({
      role: yup.mixed().oneOf(invitableRoles).required(),
    }),
    validateOnMount: true,
    onSubmit: (values) => {
      onSuccess?.(values.role);
    },
  });

  return (
    <Dialog open={open} maxWidth="sm" fullWidth onClose={onClose}>
      <form onSubmit={formik.handleSubmit}>
        <DialogTitleClosable title="Change Role" onClose={onClose} />

        <DialogContent dividers>
          <Stack spacing={2} my={1}>
            <TextField
              disabled
              label="User Email"
              variant="outlined"
              fullWidth
              size="small"
              name="email"
              value={email}
            />

            <FormControl>
              <InputLabel>Role</InputLabel>
              <Select
                label="Role"
                variant="outlined"
                fullWidth
                name="role"
                size="small"
                value={formik.values.role}
                onChange={formik.handleChange}
                renderValue={(val) =>
                  userRoleOptions.find(({ value }) => val === value)?.title ??
                  val
                }
                MenuProps={{ sx: { maxWidth: 400 } }}
              >
                {userRoleOptions.map(({ description, value, title }) => (
                  <MenuItem value={value} key={value}>
                    <ListItemText
                      primary={title}
                      secondary={description}
                      sx={{ whiteSpace: "break-spaces" }}
                      secondaryTypographyProps={{ variant: "caption" }}
                    />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Stack>
        </DialogContent>

        <DialogActions sx={{ py: 2, px: 3 }}>
          <Button color="tertiary" onClick={onClose}>
            Cancel
          </Button>

          <LoadingButton
            variant="contained"
            color="primary"
            type="submit"
            disabled={!formik.isValid || !formik.dirty}
            loading={loading}
          >
            Change
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};
