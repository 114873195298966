import { FC } from "react";
import { FormikErrors, FormikHelpers } from "formik";
import { Stack } from "@mui/material";
import { FilterDialogListItem } from "./FilterDialogListItem";
import { FilterDialogGroupList } from "./FilterDialogGroupList";
import {
  FilterKeyType,
  ResourceFilters,
  ResourceType,
} from "../../../../../../services/cloudchipr.api";
import {
  FilterGroups,
  FilterItems,
  FilterOperators,
  FiltersInitialValues,
  isFilterGroup,
  ModalFilterItem,
} from "../utils/types/types";

interface FilterDialogListProps {
  accountIds?: string[];
  defaultFilters: ResourceFilters;
  filterItems: FilterItems;
  resourceType: ResourceType;
  filterGroups: FilterGroups;
  operators: FilterOperators;
  excludedFilters?: FilterKeyType[];
  errors: FormikErrors<FiltersInitialValues>;
  setValues: FormikHelpers<FiltersInitialValues>["setValues"];
  setFieldValue: FormikHelpers<ModalFilterItem>["setFieldValue"];
}

export const FilterDialogList: FC<FilterDialogListProps> = (props) => {
  const {
    filterGroups,
    filterItems,
    errors,
    accountIds,
    excludedFilters,
    resourceType,
    setFieldValue,
    operators,
    setValues,
    defaultFilters,
  } = props;

  let indexOfOperator = 0;

  return (
    <Stack spacing={2}>
      {filterGroups.map((filterGroup, index) => {
        if (isFilterGroup(filterGroup)) {
          indexOfOperator++;

          return (
            <FilterDialogGroupList
              {...props}
              indexOfGroup={index}
              key={`${indexOfOperator}_group`}
              defaultFilters={defaultFilters}
              filterGroup={filterGroup}
              indexOfOperator={indexOfOperator}
            />
          );
        }

        const currentFilter = filterItems[filterGroup];

        return (
          <FilterDialogListItem
            index={index}
            key={`${filterGroup}_FilterDialogListItem`}
            errors={errors}
            defaultFilters={defaultFilters}
            itemOperator={operators[0]}
            indexOfOperator={0}
            setValues={setValues}
            accountIds={accountIds}
            filterIndex={filterGroup}
            resourceType={resourceType}
            setFieldValue={setFieldValue}
            excludedFilters={excludedFilters}
            currentFilter={currentFilter}
          />
        );
      })}
    </Stack>
  );
};
