import { createAsyncThunk } from "@reduxjs/toolkit";
import { enqueueSnackbar } from "notistack";
import { RootState } from "../../store";
import { cloudChiprApi } from "../../../services/cloudchipr.api";
import { reservationsDetailsPayloadSelector } from "../selectors/reservations/details-drawer/reservationsDetailsPayloadSelector";

export const getReservationDetailsThunk = createAsyncThunk(
  "commitments/getReservationDetails",
  async (_: void, { dispatch, getState }) => {
    const state = getState() as RootState;

    const { getUsersMeOrganisationsCurrentReservationsData } =
      cloudChiprApi.endpoints;

    const payload = reservationsDetailsPayloadSelector(state);

    if (!payload) {
      return;
    }

    try {
      await dispatch(
        getUsersMeOrganisationsCurrentReservationsData.initiate(payload),
      ).unwrap();
    } catch (e) {
      // @ts-expect-error todo: api fix
      const errMessage = e?.data?.message || "Something went wrong";
      enqueueSnackbar(errMessage, {
        variant: "snackbarAlert",
        AlertSnackBarProps: { severity: "error" },
      });

      return null;
    }
  },
);
