import { DropdownSelectOption } from "../types/types";

export const sortOptionsBySelected = (
  options: DropdownSelectOption[],
  values: string[],
) => {
  if (!values || !options) {
    return [];
  }

  const valuesObject = values.reduce(
    (acc, value) => {
      acc[value] = true;
      return acc;
    },
    {} as Record<string, boolean>,
  );

  return [...options].sort((a, b) => {
    if (valuesObject[a.value] && !valuesObject[b.value]) {
      return -1;
    }

    if (!valuesObject[a.value] && valuesObject[b.value]) {
      return 1;
    }

    return 0;
  });
};
