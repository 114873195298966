import { FC } from "react";
import { ResourcesSectionAsTarget } from "./resources/ResourcesSectionAsTarget";
import { useAppSelector } from "../../../../../../../../store/hooks";
import { taskManagementTargetTypeSelector } from "../../../../../../../../store/task-management/selectors/form/target/taskManagementTargetTypeSelector";

export const TaskCreationTargetSection: FC = () => {
  const targetType = useAppSelector(taskManagementTargetTypeSelector);

  if (targetType === "resource") {
    return <ResourcesSectionAsTarget />;
  }

  return null;
};
