import { FC } from "react";
import { Stack } from "@mui/material";
import { TaskPropertyCollapseSection } from "./TaskPropertyCollapseSection";
import TaskPropertyStatusIcon from "../../../../../../../../assets/images/task-properties/TaskPropertyStatusIcon";
import TaskPropertyActionIcon from "../../../../../../../../assets/images/task-properties/TaskPropertyActionIcon";
import TaskPropertyPriorityIcon from "../../../../../../../../assets/images/task-properties/TaskPropertyPriorityIcon";
import TaskPropertyEnvironmentIcon from "../../../../../../../../assets/images/task-properties/TaskPropertyEnvironmentIcon";
import { TaskPropertyEditSection } from "../../../../../../common/task-management/components/common/properties-edit/TaskPropertyEditSection";
import { TaskStatusPropertyEditSection } from "../../../../../../common/task-management/components/common/properties-edit/TaskStatusPropertyEditSection";

export const TaskPropertiesSettingsDrawerBody: FC = () => {
  return (
    <Stack p={2} spacing={2}>
      <TaskPropertyCollapseSection
        title="Status"
        icon={<TaskPropertyStatusIcon />}
      >
        <TaskStatusPropertyEditSection />
      </TaskPropertyCollapseSection>

      <TaskPropertyCollapseSection
        title="Action"
        icon={<TaskPropertyActionIcon />}
      >
        <TaskPropertyEditSection type="actions" />
      </TaskPropertyCollapseSection>

      <TaskPropertyCollapseSection
        title="Priority"
        icon={<TaskPropertyPriorityIcon />}
      >
        <TaskPropertyEditSection type="priorities" />
      </TaskPropertyCollapseSection>

      <TaskPropertyCollapseSection
        title="Environment"
        icon={<TaskPropertyEnvironmentIcon />}
      >
        <TaskPropertyEditSection type="environments" />
      </TaskPropertyCollapseSection>
    </Stack>
  );
};
