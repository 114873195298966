import moment from "moment/moment";
import { UnusedSince } from "../../../../services/cloudchipr.api";

export const compareUnusedSinceTimestamps = (
  current: UnusedSince,
  next: UnusedSince,
) => {
  const currentTimestamp = getTimestampFromRow(current);
  const nextTimestamp = getTimestampFromRow(next);

  return nextTimestamp < currentTimestamp;
};

const getTimestampFromRow = (data: UnusedSince) => {
  const isUsed = data?.is_used;
  let timestamp = data?.timestamp;

  if (isUsed || !timestamp) {
    timestamp = moment().add(100, "year").format();
  }

  return timestamp;
};
