import { FC, useCallback } from "react";
import { Menu, PopoverOrigin, Stack } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { ResourceExplorerViewMoveActionMenuSection } from "./ResourceExplorerViewMoveActionMenuSection";
import { navigationHierarchyItemNoDndKey } from "../../../../../sortable-tree/utils/constants";
import { NavigationItemsVisibilityType } from "../../../../../../../../store/navigation/utils/types";
import { updateResourceExplorerVisibilityHierarchyThunk } from "../../../../../../../../store/resource-explorer/thunks/resource-explorer-hierarchy-visibility/updateResourceExplorerVisibilityHierarchyThunk";
import { useAppDispatch } from "../../../../../../../../store/hooks";

interface ResourceExplorerViewMoveActionMenuProps {
  id: string;
  name: string;
  folderId?: string;
  anchor: HTMLElement | null;
  open: boolean;
  onClose(): void;
  visibility: NavigationItemsVisibilityType;
}

export const ResourceExplorerViewMoveActionMenu: FC<
  ResourceExplorerViewMoveActionMenuProps
> = ({ id, name, folderId, anchor, onClose, open, visibility }) => {
  const { viewId: currentViewId = "" } = useParams<{
    viewId: string;
  }>();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const moveToFolder = useCallback(
    async (visibility: NavigationItemsVisibilityType, folderId?: string) => {
      const viewId = await dispatch(
        updateResourceExplorerVisibilityHierarchyThunk({
          id,
          folderId,
          visibility,
          type: "view",
        }),
      ).unwrap();

      if (viewId !== id && id === currentViewId) {
        navigate(`/resource-explorer/${viewId}`);
      }

      onClose();
    },
    [id, dispatch, onClose, navigate, currentViewId],
  );

  const moveToRoot = useCallback(
    async (visibility: NavigationItemsVisibilityType) => {
      const viewId = await dispatch(
        updateResourceExplorerVisibilityHierarchyThunk({
          id,
          index: 1,
          visibility,
          type: "view",
        }),
      ).unwrap();

      if (viewId !== id && id === currentViewId) {
        navigate(`/resource-explorer/${viewId}`);
      }

      onClose();
    },
    [id, dispatch, onClose, navigate, currentViewId],
  );

  return (
    <Menu
      anchorOrigin={anchorOrigin}
      transformOrigin={transformOrigin}
      open={open}
      onClose={onClose}
      anchorEl={anchor}
      slotProps={slotProps}
      {...navigationHierarchyItemNoDndKey}
    >
      <Stack spacing={1}>
        <ResourceExplorerViewMoveActionMenuSection
          folderId={folderId}
          visibility="visible_to_everyone"
          currentViewName={name}
          currentVisibility={visibility}
          moveToFolder={moveToFolder}
          moveToRoot={moveToRoot}
        />
        <ResourceExplorerViewMoveActionMenuSection
          folderId={folderId}
          visibility="visible_only_to_me"
          currentViewName={name}
          currentVisibility={visibility}
          moveToFolder={moveToFolder}
          moveToRoot={moveToRoot}
        />
      </Stack>
    </Menu>
  );
};

const slotProps = {
  paper: {
    sx: {
      minWidth: 200,
      maxWidth: 400,
      maxHeight: 400,
    },
  },
};

const anchorOrigin: PopoverOrigin = {
  vertical: "top",
  horizontal: "right",
};
const transformOrigin: PopoverOrigin = {
  vertical: "top",
  horizontal: "left",
};
