import { currentDashboardSelector } from "./currentDashboardSelector";
import { RootState } from "../../../store";

export const currentDashboardNameSelector = (
  state: RootState,
): string | null => {
  const dashboard = currentDashboardSelector(state);

  return dashboard?.name ?? null;
};
