import { FC, useCallback, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useFormik } from "formik";
import * as yup from "yup";
import { usePostUsersMeOrganisationsCurrentUsersMutation } from "../../../../../../services/cloudchipr.api";
import { DialogTitleClosable } from "../../../../../common/dialog-utils/DialogTitleClosable";
import { invitableRoles, userRoleOptions } from "../utils/constants/common";
import { InvitedUserRoles } from "../utils/types/types";

interface UserInviteDialogProps {
  open: boolean;
  onClose(): void;
  onSuccess(): void;
  email?: string;
}

interface UserInviteForm {
  email: string;
  role: InvitedUserRoles;
}

export const UserInviteDialog: FC<UserInviteDialogProps> = ({
  open,
  email,
  onClose,
  onSuccess,
}) => {
  const [error, setError] = useState("");
  const [triggerInvite, { isLoading }] =
    usePostUsersMeOrganisationsCurrentUsersMutation();

  const closeHandler = useCallback(() => {
    onClose();
    setError("");
  }, [onClose]);

  const formik = useFormik<UserInviteForm>({
    initialValues: { email: email ?? "", role: "admin" },
    validationSchema: yup.object({
      email: yup.string().trim().email().required(),
      role: yup.mixed().oneOf(invitableRoles).required(),
    }),
    validateOnMount: true,
    onSubmit: async (values) => {
      triggerInvite({ body: { email: values.email.trim(), role: values.role } })
        .unwrap()
        .then(() => {
          onSuccess();
          setError("");
        })
        .catch((e) => {
          setError(e?.data?.message ?? "Something went wrong");
        })
        .finally(formik.resetForm);
    },
  });

  return (
    <Dialog open={open} maxWidth="sm" fullWidth onClose={closeHandler}>
      <form onSubmit={formik.handleSubmit}>
        <DialogTitleClosable title="Invite User" onClose={closeHandler} />

        <DialogContent dividers>
          <Stack spacing={2} my={1}>
            <Typography variant="body2">
              Invite users simply by writing the email address and choosing the
              role.
            </Typography>

            <TextField
              error={!!error}
              helperText={error}
              label="User Email"
              variant="outlined"
              fullWidth
              size="small"
              name="email"
              value={formik.values.email}
              onChange={formik.handleChange}
            />

            <FormControl>
              <InputLabel>Role</InputLabel>
              <Select
                label="Role"
                variant="outlined"
                fullWidth
                name="role"
                size="small"
                value={formik.values.role}
                onChange={formik.handleChange}
                renderValue={(val) =>
                  userRoleOptions.find(({ value }) => val === value)?.title ??
                  val
                }
                MenuProps={{ sx: { maxWidth: 400 } }}
              >
                {userRoleOptions.map(({ description, value, title }) => (
                  <MenuItem value={value} key={value}>
                    <ListItemText
                      primary={title}
                      secondary={description}
                      sx={{ whiteSpace: "break-spaces" }}
                      secondaryTypographyProps={{ variant: "caption" }}
                    />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Stack>
        </DialogContent>

        <DialogActions sx={{ py: 2, px: 3 }}>
          <Button color="tertiary" onClick={closeHandler}>
            Cancel
          </Button>

          <LoadingButton
            variant="contained"
            color="primary"
            type="submit"
            disabled={!formik.isValid}
            loading={isLoading}
          >
            Send Invite
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};
