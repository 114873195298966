import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  Account,
  AccountAttempt,
  AccountId,
  ProviderAccessType,
  ProviderType,
} from "../../services/cloudchipr.api";
import { GcpConnectionType } from "../../components/pages/common/connectors/components/gcp/utils/types";

export enum AccessToAccountTypeEnum {
  CLI_COMMAND = "cli_command",
  CONSOLE_LINK = "console_link",
  TERRAFORM = "terraform",
}

interface AccountSetupInterface {
  provider: ProviderType;
  providerAccessType: ProviderAccessType;
  gcpConnectionType: GcpConnectionType;
  accessToAccountType: AccessToAccountTypeEnum;
  currentStep: number;
  account: Account | null;
  accountAttempt: AccountAttempt | null;
  polling: boolean;
  pollingInterrupted: boolean;
  dialogOpen: boolean;
  prefillAccountId: AccountId | null;
}

const initialState: AccountSetupInterface = {
  provider: "aws",
  providerAccessType: "read_write",
  gcpConnectionType: "single",
  accessToAccountType: AccessToAccountTypeEnum.CONSOLE_LINK,
  currentStep: 0,
  account: null,
  accountAttempt: null,
  polling: false,
  pollingInterrupted: false,
  dialogOpen: false,
  prefillAccountId: null,
};

export const accountSetupSlice = createSlice({
  name: "accountSetup",
  initialState: initialState,
  reducers: {
    setProvider: (state, action: PayloadAction<ProviderType>) => {
      state.provider = action.payload;
    },
    setProviderAccessType: (
      state,
      action: PayloadAction<ProviderAccessType>,
    ) => {
      state.providerAccessType = action.payload;
    },
    setGcpConnectionType: (state, action: PayloadAction<GcpConnectionType>) => {
      state.gcpConnectionType = action.payload;
    },
    setAccessToAccountType: (
      state,
      action: PayloadAction<AccessToAccountTypeEnum>,
    ) => {
      state.accessToAccountType = action.payload;
    },
    goToNextStep: (state) => {
      state.currentStep += 1;
    },
    goToPrevStep: (state) => {
      state.currentStep > 0 && (state.currentStep -= 1);
    },
    setAccount: (state, action: PayloadAction<Account>) => {
      state.account = action.payload;
    },
    setAccountAttempt: (state, action: PayloadAction<AccountAttempt>) => {
      state.accountAttempt = action.payload;
    },
    setPolling: (state, action: PayloadAction<boolean>) => {
      state.polling = action.payload;
    },
    setAccountSetupDialogOpen: (state, action: PayloadAction<boolean>) => {
      state.dialogOpen = action.payload;
    },
    setPollingInterrupted: (state, action: PayloadAction<boolean>) => {
      state.pollingInterrupted = action.payload;
    },
    setPrefillAccountId: (state, action: PayloadAction<AccountId>) => {
      state.prefillAccountId = action.payload;
    },
    resetAccountAttempt: (state) => {
      state.accountAttempt = null;
    },
    resetAccountSetup: () => {
      return initialState;
    },
  },
});

export const {
  setAccount,
  setPolling,
  setProvider,
  goToPrevStep,
  goToNextStep,
  resetAccountSetup,
  setAccountAttempt,
  resetAccountAttempt,
  setPollingInterrupted,
  setProviderAccessType,
  setGcpConnectionType,
  setAccessToAccountType,
  setAccountSetupDialogOpen,
  setPrefillAccountId,
} = accountSetupSlice.actions;

export default accountSetupSlice.reducer;
