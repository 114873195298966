import { FC } from "react";
import { Button, Stack } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { IntegrationType } from "./integration-type/IntegrationTypeCard";
import { useAppAbility } from "../../../../../../../services/permissions";

interface IntegrationFormActionsProps {
  loading: boolean;
  disabled: boolean;
  edit: boolean;
  type: IntegrationType;
  onSubmit(): void;
  onCancel(): void;
}

export const IntegrationFormActions: FC<IntegrationFormActionsProps> = ({
  loading,
  onCancel,
  onSubmit,
  disabled,
  type,
  edit,
}) => {
  const { cannot } = useAppAbility();
  const canNotCreateIntegration = cannot("create", "integration");
  const canNotEditIntegration = cannot("edit", "integration");

  return (
    <Stack direction="row" spacing={2} justifyContent="space-between">
      <Button disabled={loading} onClick={onCancel} color="tertiary">
        Cancel
      </Button>
      <LoadingButton
        onClick={onSubmit}
        disabled={canNotCreateIntegration || canNotEditIntegration || disabled}
        loading={loading}
        variant="contained"
      >
        {type === "jira" && !edit ? "Create" : "Done"}
      </LoadingButton>
    </Stack>
  );
};
