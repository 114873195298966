import { createAsyncThunk } from "@reduxjs/toolkit";
import { patchLiveUsageMgmtFilterTemplateFromAppliedFiltersThunk } from "./patchLiveUsageMgmtFilterTemplateFromAppliedFiltersThunk";
import { createLiveUsageMgmtFilterTemplateFromAppliedFiltersThunk } from "./createLiveUsageMgmtFilterTemplateFromAppliedFiltersThunk";
import { generateCopiedFromDefaultFilterTemplateName } from "./utils/helpers/generateCopiedFromDefaultFilterTemplateName";
import { liveUsageMgmtAppliedTemplateIdSelector } from "../../selectors/store-selectors/liveUsageMgmtAppliedTemplateIdSelector";
import { RootState } from "../../../store";
import { liveUsageMgmtAppliedFilterTemplateIsSourceCloudchiprSelector } from "../../selectors/filter-template/liveUsageMgmtAppliedFilterTemplateIsSourceCloudchiprSelector";
import { liveUsageMgmtAppliedFilterTemplateNameSelector } from "../../selectors/filter-template/liveUsageMgmtAppliedFilterTemplateNameSelector";
import { liveUsageMgmtFilterTemplatesSelector } from "../../selectors/liveUsageMgmtFilterTemplatesSelector";

export const saveFilterTemplateThunk = createAsyncThunk(
  "liveUsageMgmt/saveFilterTemplate",
  async (_, { dispatch, getState }) => {
    const state = getState() as RootState;
    const isCloudchiprTamplate =
      liveUsageMgmtAppliedFilterTemplateIsSourceCloudchiprSelector(state);
    const filterTemplateId = liveUsageMgmtAppliedTemplateIdSelector(state);
    const filterTemplateName =
      liveUsageMgmtAppliedFilterTemplateNameSelector(state);
    const templates = liveUsageMgmtFilterTemplatesSelector(state);
    const newFilterTemplateName = generateCopiedFromDefaultFilterTemplateName(
      templates,
      filterTemplateName,
    );

    let response;

    if (!isCloudchiprTamplate) {
      response = await dispatch(
        patchLiveUsageMgmtFilterTemplateFromAppliedFiltersThunk(
          filterTemplateId ?? "",
        ),
      );
    } else {
      response = await dispatch(
        createLiveUsageMgmtFilterTemplateFromAppliedFiltersThunk(
          newFilterTemplateName,
        ),
      );
    }

    return response;
  },
);
