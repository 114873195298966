import { getUsersMeOrganisationsCurrentDashboardsByDashboardIdSelector } from "./getUsersMeOrganisationsCurrentDashboardsByDashboardIdSelector";
import { RootState } from "../../../store";

export const dashboardByIdSelector = (
  state: RootState,
  dashboardId: string,
) => {
  return getUsersMeOrganisationsCurrentDashboardsByDashboardIdSelector({
    dashboardId,
  })(state);
};
