import { FC, useCallback, useEffect, useMemo } from "react";
import { TooltipProps } from "recharts";
import { Payload } from "recharts/types/component/DefaultTooltipContent";
import { CostBreakdownWidgetChartTooltipContent } from "./CostBreakdownWidgetChartTooltipContent";
import { useMultiTypeChartContext } from "../../../../../../../../../../storybook/charts/multi-type-chart/MultiTypeChartProvider";
import { moneyFormatter } from "../../../../../../../../../../utils/numeral/moneyFormatter";
import { MultiTypeChart } from "../../../../../../../../../../storybook/charts/multi-type-chart/MultiTypeChart";
import {
  ChartBaseType,
  DateDataPointNullable,
  ResourceExplorerCostsDateDetails,
} from "../../../../../../../../../../services/cloudchipr.api";
import {
  ChartDataType,
  ChartType,
} from "../../../../../../../../../../storybook/charts/multi-type-chart/utils/types/types";
import { calculateCostBreakdownTotal } from "../../../../../../widgets/cost-breakdown/utils/helpers/calculateCostBreakdownTotal";
import { formatDate } from "../../../../../../../../../../utils/helpers/date-time/datetime-format";
import { money } from "../../../../../../../../../../utils/numeral/money";
import { FormatedData } from "../../../../../../../../../../store/resource-explorer/selectors/current-resource-explorer/chart/utils/helpers/generateCombinatedChartData";
import { chartDataPointKey } from "../../../../../../../../../../storybook/charts/multi-type-chart/utils/constants/constants";

interface CostBreakdownWidgetChartProps {
  chartType: ChartType;
  dateDetails?: ResourceExplorerCostsDateDetails;
  loading?: boolean;
  dateDataPoint: DateDataPointNullable;
  chartBase: ChartBaseType;
  combinedChartData?: FormatedData | null;
}

export const CostBreakdownWidgetChart: FC<CostBreakdownWidgetChartProps> = ({
  chartType,
  dateDetails,
  loading,
  dateDataPoint,
  chartBase,
  combinedChartData,
}) => {
  const {
    chartType: { type: chartTypeFromProvider, onChange: onChartTypeChange },
    data,
  } = useMultiTypeChartContext();

  const total = useMemo(() => {
    return calculateCostBreakdownTotal(data);
  }, [data]);

  const TooltipContent = useCallback(
    (props: TooltipProps<any, any>) => {
      return (
        <CostBreakdownWidgetChartTooltipContent
          {...props}
          total={total}
          dateDetails={dateDetails}
        />
      );
    },
    [dateDetails, total],
  );

  const yAxisFormatter = useCallback(
    (value: number | string) => {
      return chartBase === "percent"
        ? percentFormater(value)
        : moneyFormatter(+value);
    },
    [chartBase],
  );

  const tooltipValueFormatter = useCallback(
    (payload: Payload<any, any>) => {
      const dateKey = payload.dataKey;
      const identifier = payload.payload[chartDataPointKey];
      const values = combinedChartData?.[identifier]?.values?.[dateKey ?? ""];

      return `${money(values?.cost)} (${values?.percent}%)`;
    },
    [combinedChartData],
  );

  const tooltipTotalCostFormatter = useCallback(
    (data?: ChartDataType) => {
      const identifier = data?.recharts_chart_data_point_unique_key;

      return money(combinedChartData?.[identifier ?? ""]?.totalCost);
    },
    [combinedChartData],
  );

  useEffect(() => {
    if (chartTypeFromProvider === chartType) {
      return;
    }

    onChartTypeChange(chartType);
  }, [chartTypeFromProvider, chartType, onChartTypeChange]);

  return (
    <MultiTypeChart
      zoom
      selectable
      highlight
      loading={loading}
      TooltipContent={TooltipContent}
      tooltipProps={{
        showTotal: true,
        tooltipLabelFormatter:
          dateDataPoint === "daily"
            ? (label: string) => formatDate(label, { type: "weekDayDate" })
            : undefined,
        tooltipValueFormatter,
        tooltipTotalCostFormatter,
      }}
      yAxisFormatter={yAxisFormatter}
      yAxisProps={chartBase === "percent" ? { domain: [0, 100] } : undefined}
      showLoader={false}
      emptyText="No data by your selected date range."
    />
  );
};

const percentFormater = (value: number | string) => `${(+value).toFixed(0)}%`;
