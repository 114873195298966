import { FC, Fragment } from "react";
import { Stack } from "@mui/material";
import { ReportsDrawerTimePicker } from "./ReportsDrawerTimePicker";
import { ReportsDrawerTimeZone } from "./ReportsDrawerTimeZone";
import { ReportsDrawerScheduleInterval } from "./ReportsDrawerScheduleInterval";

export const ReportsDrawerDailySchedule: FC = () => {
  return (
    <Fragment>
      <ReportsDrawerScheduleInterval frequency="day" flexGrow={1.5} />
      <Stack flex={1.5}>
        <ReportsDrawerTimePicker icon />
      </Stack>
      <Stack flex={4}>
        <ReportsDrawerTimeZone />
      </Stack>
    </Fragment>
  );
};
