import { FC } from "react";
import { useAppSelector } from "../../../../../../../../../../../store/hooks";
import { TotalCostColumnHeaderCell } from "../../../../../../../../../resource-explorer/components/resource-explorer-card/components/resource-explorer-data-grid/components/columns/components/common/TotalCostColumnHeaderCell";
import { costBreakdownWidgetSetupTotalCostSelector } from "../../../../../../../../../../../store/dashboards/selectors/widgets/cost-breakdown/setup/costBreakdownWidgetSetupTotalCostSelector";

export const CostBreakdownWidgetSetupTableTotalCostHeaderCell: FC = () => {
  const total = useAppSelector(costBreakdownWidgetSetupTotalCostSelector);

  return (
    <TotalCostColumnHeaderCell
      totalCost={total?.cost ?? 0}
      dateDetails={total?.dateDetails}
    />
  );
};
