import { FC } from "react";
import { Button, Stack, Typography } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import EmptyStateImage from "../utils/assets/images/empty_budgets.svg";
import { useAppAbility } from "../../../../services/permissions";

export const BudgetsEmptyState: FC = () => {
  const { can } = useAppAbility();
  const canCreateBudget = can("create", "budget");

  return (
    <Stack justifyContent="center" alignItems="center" mt={12}>
      <img src={EmptyStateImage} alt="EmptyStateImage" />
      <Typography
        component="h4"
        variant="h5"
        textAlign="center"
        fontWeight="bold"
        mt={5}
      >
        You do not have any budgets
      </Typography>

      <Typography
        variant="h6"
        color="text.secondary"
        textAlign="center"
        fontWeight="normal"
        mt={2}
        maxWidth={640}
      >
        Monitor cloud spend by creating budgets and enabling alerts for exceeded
        limits.
      </Typography>

      <Button
        size="small"
        sx={{ mt: 6 }}
        color="primary"
        variant="contained"
        to="/budgets/create"
        component={RouterLink}
        disabled={!canCreateBudget}
      >
        Create Budget
      </Button>
    </Stack>
  );
};
