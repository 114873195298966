import { RootState } from "../../../../../store";
import { savingsPlansCoverageDataSelector } from "../savingsPlansCoverageDataSelector";
import { utilizationAndCoverageCSVDataGenerator } from "../../../../utils/helpers/csv/utilizationAndCoverageCSVDataGenerator";
import { commitmentsDataViewTabSelector } from "../../../common/view-options/commitmentsDataViewTabSelector";
import { commitmentsDetailsDrawerCoverageGroupingSelector } from "../../../common/details-drawer/commitmentsDetailsDrawerCoverageGroupingSelector";

export const savingsPlansCoverageCSVDataSelector = (state: RootState) => {
  const data = savingsPlansCoverageDataSelector(state);
  const viewTab = commitmentsDataViewTabSelector(state);
  const grouping = commitmentsDetailsDrawerCoverageGroupingSelector(state);
  return utilizationAndCoverageCSVDataGenerator({ viewTab, grouping, data });
};
