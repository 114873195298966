import { protectorResourcesDataSelector } from "./protectorResourcesDataSelector";
import { RootState } from "../../../store";

export const protectorResourcesEmsSelector = (
  state: RootState,
  scheduleId: string,
  batchId?: string,
): number => {
  const resources = protectorResourcesDataSelector(state, scheduleId, batchId);

  const flatResources = resources?.map((resource) => resource.resources).flat();

  return (
    flatResources?.reduce(
      (sum, resource) => sum + (resource?.estimated_monthly_waste ?? 0),
      0,
    ) ?? 0
  );
};
