import {
  createContext,
  Dispatch,
  FC,
  MutableRefObject,
  ReactNode,
  SetStateAction,
  useContext,
  useMemo,
  useRef,
  useState,
} from "react";
import { useCounter } from "rooks";
import { RowSelectionState } from "@tanstack/react-table";
import { getMetricFrequencyKey } from "../../components/pages/live-usage-mgmt/components/resource-card/resource-card-content/resource-card-data/resource-card-grid/resouce-card-data-grid/cells/metrics-cells/utils/helpers/helpers";

interface DataGridContextType {
  wrappedWithProvider: boolean;
  rowSelection: {
    lastSelectedRowIdRef: MutableRefObject<string>;
  };
  cellSpecificMetadata: {
    data: Record<string, any>;
    setData: Dispatch<SetStateAction<Record<string, any>>>;
  };
  rowExpanding: {
    needToReEstimateSize: number;
    defaultRowSizeRef: { current: number | null };
    reEstimateSize(): void;
    expandedRows: RowSelectionState;
    setExpandedRows: Dispatch<SetStateAction<RowSelectionState>>;
  };
}

const DataGridContext = createContext({} as DataGridContextType);

export const DataGridProvider: FC<{ children?: ReactNode }> = ({
  children,
}) => {
  const defaultRowSizeRef = useRef<number | null>(null);
  const lastSelectedRowIdRef = useRef("");

  // todo: move each functionality to a separate hook
  const { value: needToReEstimateSize, increment: reEstimateSize } =
    useCounter(1);
  const [expandedRows, setExpandedRows] = useState<RowSelectionState>({});
  const [cellSpecificMetadata, setCellSpecificMetadata] =
    useState(metricsInitialData);

  const rowExpanding = useMemo(() => {
    return {
      defaultRowSizeRef,
      needToReEstimateSize,
      reEstimateSize,
      expandedRows,
      setExpandedRows,
    };
  }, [
    needToReEstimateSize,
    defaultRowSizeRef,
    reEstimateSize,
    expandedRows,
    setExpandedRows,
  ]);

  const contextValue = useMemo(() => {
    return {
      rowExpanding,
      wrappedWithProvider: true,
      rowSelection: { lastSelectedRowIdRef },
      cellSpecificMetadata: {
        data: cellSpecificMetadata,
        setData: setCellSpecificMetadata,
      },
    };
  }, [rowExpanding, cellSpecificMetadata]);

  return (
    <DataGridContext.Provider value={contextValue}>
      {children}
    </DataGridContext.Provider>
  );
};

export const useDataGridContext = () => {
  return useContext(DataGridContext);
};

const metricsInitialData = {
  [getMetricFrequencyKey("resource.all_request_sum")]: "30d",
  [getMetricFrequencyKey("resource.cpu_max")]: "24h",
  [getMetricFrequencyKey("resource.memory_max")]: "24h",
  [getMetricFrequencyKey("resource.total_connection_sum")]: "24h",
  [getMetricFrequencyKey("resource.unused_memory")]: "24h",
};
