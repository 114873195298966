import { Chip, Stack, Theme, Typography } from "@mui/material";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import { SxProps } from "@mui/system";
import { FC } from "react";

interface AutomationResourceFilterSourceButtonProps {
  openDialog: () => void;
  count: number;
}

export const AutomationResourceFilterSourceButton: FC<
  AutomationResourceFilterSourceButtonProps
> = ({ count, openDialog }) => {
  return (
    <Chip
      sx={styles}
      size="small"
      variant="filled"
      onClick={openDialog}
      label={
        <Stack direction="row" spacing={0.5} alignItems="center">
          <FilterAltOutlinedIcon fontSize="small" />

          <Typography variant="body2">Filters ({count})</Typography>

          <KeyboardArrowDownOutlinedIcon fontSize="small" />
        </Stack>
      }
    />
  );
};

const styles: SxProps<Theme> = {
  bgcolor: "grey.100",
  color: ({ palette }) => palette.primary.main,
};
