import { FC, useCallback, useMemo, useState } from "react";
import { Popover, PopoverOrigin } from "@mui/material";
import { LiveUsageMgmtFilterTemplateDropDownContent } from "./LiveUsageMgmtFilterTemplateDropDownContent";
import { LiveUsageMgmtFilterTemplateDropdownHeader } from "./LiveUsageMgmtFilterTemplateDropdownHeader";
import { useAppDispatch, useAppSelector } from "../../../../../../store/hooks";
import { liveUsageMgmtProviderSelector } from "../../../../../../store/live-usage-mgmt/selectors/store-selectors/liveUsageMgmtProviderSelector";
import { liveUsageMgmtAppliedTemplateIdSelector } from "../../../../../../store/live-usage-mgmt/selectors/store-selectors/liveUsageMgmtAppliedTemplateIdSelector";
import { tryToApplyLiveUsageMgmtFilterTemplateThunk } from "../../../../../../store/live-usage-mgmt/thunks/filter-template/tryToApplyLiveUsageMgmtFilterTemplateThunk";
import { liveUsageMgmtSortedFilterTemplatesSelector } from "../../../../../../store/live-usage-mgmt/selectors/filter-template/liveUsageMgmtSortedFilterTemplatesSelector";

interface LiveUsageMgmtFilterTemplateDropdownProps {
  open: boolean;
  onClose(): void;
  anchor: HTMLElement | null;
}
export const LiveUsageMgmtFilterTemplateDropdown: FC<
  LiveUsageMgmtFilterTemplateDropdownProps
> = ({ open, onClose, anchor }) => {
  const dispatch = useAppDispatch();
  const provider = useAppSelector(liveUsageMgmtProviderSelector);

  const appliedTemplateId = useAppSelector(
    liveUsageMgmtAppliedTemplateIdSelector,
  );
  const [loadingByFilterSetId, setLoadingByFilterSetId] = useState<Record<
    string,
    boolean
  > | null>(null);
  const filterTemplates = useAppSelector(
    liveUsageMgmtSortedFilterTemplatesSelector,
  );

  const applyFilterTemplateHandler = useCallback(
    async (filterSetId: string) => {
      setLoadingByFilterSetId({
        [filterSetId]: true,
      });
      await dispatch(tryToApplyLiveUsageMgmtFilterTemplateThunk(filterSetId));

      setLoadingByFilterSetId(null);
      onClose();
    },
    [dispatch, onClose],
  );

  const filterTemplateHeader = useMemo(() => {
    return <LiveUsageMgmtFilterTemplateDropdownHeader onClose={onClose} />;
  }, [onClose]);

  return (
    <Popover
      open={open}
      anchorEl={anchor}
      onClose={onClose}
      anchorOrigin={anchorOrigin}
      transformOrigin={transformOrigin}
      PaperProps={paperProps}
    >
      <LiveUsageMgmtFilterTemplateDropDownContent
        filterTemplates={filterTemplates ?? []}
        provider={provider ?? undefined}
        onApplyFilterTemplate={applyFilterTemplateHandler}
        appliedFilterSetId={appliedTemplateId ?? ""}
        loadingByFilterSetId={loadingByFilterSetId}
        header={filterTemplateHeader}
      />
    </Popover>
  );
};

const transformOrigin: PopoverOrigin = {
  vertical: "top",
  horizontal: "left",
};
const anchorOrigin: PopoverOrigin = {
  vertical: "bottom",
  horizontal: "left",
};

const paperProps = {
  style: { maxHeight: 400, width: 380 },
};
