import { dimensionCreateLoadingSelector } from "./dimensions/dimensionCreateLoadingSelector";
import { categoryCreateLoadingSelector } from "./categories/categoryCreateLoadingSelector";
import { categoryUpdateLoadingSelector } from "./categories/categoryUpdateLoadingSelector";
import { dimensionUpdateLoadingSelector } from "./dimensions/dimensionUpdateLoadingSelector";
import { dimensionsLoadingSelector } from "../dimensions-data/dimensionsLoadingSelector";
import { RootState } from "../../../store";

export const someActionIsLoadingOnDimensionsSelector = (state: RootState) => {
  const dimensionCreating = dimensionCreateLoadingSelector(state);
  const categoryCreating = categoryCreateLoadingSelector(state);
  const categoryUpdating = categoryUpdateLoadingSelector(state);
  const dimensionUpdating = dimensionUpdateLoadingSelector(state);
  const dimensionsGetting = dimensionsLoadingSelector(state);

  return (
    dimensionCreating ||
    categoryUpdating ||
    categoryCreating ||
    dimensionUpdating ||
    dimensionsGetting
  );
};
