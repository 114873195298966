import { ColumnPinningState } from "@ag-grid-community/core/dist/types/src/interfaces/gridState";
import { savingsOpportunitiesTableColumns } from "../../components/savingsOpportunitiesTableColumns";

export const savingsOpportunitiesTablePinnedColumns =
  savingsOpportunitiesTableColumns.reduce(
    (acc, column) => {
      if (!column.field && !column.colId) {
        return acc;
      }

      if (column.pinned === "right") {
        acc.rightColIds.push(column.colId ?? column.field ?? "");
      } else if (column.pinned === "left") {
        acc.leftColIds.push(column.colId ?? column.field ?? "");
      }

      return acc;
    },
    {
      leftColIds: [],
      rightColIds: [],
    } as ColumnPinningState,
  );
