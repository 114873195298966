import { createDraftSafeSelector } from "@reduxjs/toolkit";
import { reportsTableDataSelector } from "./reportsTableDataSelector";
import { reportsCreatedByFilterValuesSelector } from "./reportsCreatedByFilterValuesSelector";
import { reportsFrequencyFilterValuesSelector } from "./reportsFrequencyFilterValuesSelector";
import { reportsSourceFilterValuesSelector } from "./reportsSourceFilterValuesSelector";
import { reportsEnabledFilterValuesSelector } from "./reportsEnabledFilterValuesSelector";

export const reportsTableFilteredDataSelector = createDraftSafeSelector(
  [
    reportsTableDataSelector,
    reportsFrequencyFilterValuesSelector,
    reportsSourceFilterValuesSelector,
    reportsCreatedByFilterValuesSelector,
    reportsEnabledFilterValuesSelector,
  ],
  (reports, frequency, source, createdBy, enabled) => {
    return reports?.filter((report) => {
      const reportFrequency =
        report?.schedule?.frequency === "monthly_by_weekday" ||
        report?.schedule?.frequency === "monthly_by_day"
          ? "monthly"
          : report?.schedule?.frequency;

      let reportCreatedBy =
        report?.created_by?.name ?? report?.created_by?.email;

      if (reportCreatedBy?.includes("deleted")) {
        reportCreatedBy = "Deleted user";
      }

      if (frequency.length && !frequency.includes(reportFrequency ?? "")) {
        return false;
      }

      if (source.length && !source.includes(report?.source_type ?? "")) {
        return false;
      }

      if (createdBy.length && !createdBy.includes(reportCreatedBy ?? "")) {
        return false;
      }

      return !(enabled.length && !enabled.includes(report?.status ?? ""));
    });
  },
);
