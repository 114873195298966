import { largesCostChangesSetupWidgetDataResponseSelector } from "./largesCostChangesSetupWidgetDataResponseSelector";
import { RootState } from "../../../../../store";

export const largestCostChangesSetupPrevPeriodCostDateDetailsSelector = (
  state: RootState,
) => {
  const response = largesCostChangesSetupWidgetDataResponseSelector(state);

  return response?.data?.previous_period_cost_date_details;
};
