import { organisationCurrentFilterSetsSelector } from "./organisationCurrentFilterSetsSelector";
import { RootState } from "../../../store";
import {
  AccountResourceFilter2,
  ResourceType,
} from "../../../../services/cloudchipr.api";

export const organisationFilterSetByResourceTypeSelector = (
  state: RootState,
  resourceType: ResourceType,
  filterSetId?: string | null,
): AccountResourceFilter2 | undefined => {
  const data = organisationCurrentFilterSetsSelector(state);
  if (!filterSetId || !data) {
    return;
  }

  return data
    ?.find((item) => item.id === filterSetId)
    ?.filters?.find((item) => item.type === resourceType);
};
