import { FC } from "react";
import { CommitmentUtilizationWidgetAdvancedSettingsTrendSetting } from "./CommitmentUtilizationWidgetAdvancedSettingsTrendSetting";
import AdvancedSettingsIcon from "../../../../../../../../../../../../../assets/images/icons/advanced_settings_icon.svg";
import { WidgetConfigSection } from "../../../../../../common/widget-configurations/WidgetConfigSection";

export const CommitmentUtilizationWidgetAdvancedSettingsSection: FC = () => {
  return (
    <WidgetConfigSection
      title="Advanced Settings"
      icon={<img src={AdvancedSettingsIcon} alt="Advanced Settings Icon" />}
    >
      <CommitmentUtilizationWidgetAdvancedSettingsTrendSetting />
    </WidgetConfigSection>
  );
};
