import { resourceTypeLongNames } from "./resourceTypeLongNames";
import { ResourceType } from "../../../../services/cloudchipr.api";

export const resourceServiceLongNames: Map<
  ResourceType,
  { singular: string; plural: string }
> = new Map([
  ...Array.from(resourceTypeLongNames),
  [
    "ec2",
    {
      singular: "Elastic Compute Cloud (EC2)",
      plural: "Elastic Compute Clouds (EC2)",
    },
  ],
  [
    "rds",
    {
      singular: "Relational Database Service (RDS)",
      plural: "Relational Database Service (RDS)",
    },
  ],
  [
    "eks",
    {
      singular: "Elastic Kubernetes Service (EKS)",
      plural: "Elastic Kubernetes Service (EKS)",
    },
  ],
  [
    "vm",
    {
      singular: "Google Compute Engine (GCE)",
      plural: "Google Compute Engine (GCE)",
    },
  ],
  ["ip", { singular: "Network", plural: "Networks" }],
  ["dynamo_db_table", { singular: "DynamoDB", plural: "DynamoDB" }],
  ["nat_gateway", { singular: "VPC", plural: "VPC" }],
  [
    "ecs",
    {
      singular: "Elastic Container Service (ECS)",
      plural: "Elastic Container Service (ECS)",
    },
  ],
]);
