import { Components, Theme } from "@mui/material";

export const muiCard: Components<Theme>["MuiCard"] = {
  styleOverrides: {
    root: ({ ownerState }) => {
      if (ownerState.variant === "outlined") {
        // todo: in many places we have added border radius to card, since this added we can remove them
        return { borderRadius: 8 };
      }
    },
  },
};
