import { FC } from "react";
import { Button, Grid, Typography } from "@mui/material";
import { apiKeysMaxCount } from "../ApiKeysList";
import { useAppAbility } from "../../../../../../services/permissions";

interface ApiKeysEmptyStateProps {
  showForm(): void;
}

export const ApiKeysEmptyState: FC<ApiKeysEmptyStateProps> = ({ showForm }) => {
  const { cannot } = useAppAbility();
  const cannotCreateApiKey = cannot("create", "api-key");

  return (
    <Grid container justifyContent="center" textAlign="center" pt={7}>
      <Grid md={6} item>
        <Typography variant="h4">
          You have not created any <br /> API keys yet!
        </Typography>
        <Typography variant="body2" color="text.disabled" mt={2}>
          You can create up to {apiKeysMaxCount} API keys
        </Typography>
        <Button
          onClick={showForm}
          type="submit"
          color="primary"
          variant="contained"
          disabled={cannotCreateApiKey}
          sx={{ mt: 6 }}
        >
          create api key
        </Button>
      </Grid>
    </Grid>
  );
};
