import { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { styled, TableCell, TableCellProps } from "@mui/material";
import {
  ColumnOrderState,
  ColumnResizeMode,
  Header,
} from "@tanstack/react-table";
import { useEffectOnceWhen, useWillUnmount } from "rooks";
import { TableHeaderCellContent } from "./TableHeaderCellContent";
import { getStickyCellStyles } from "../utils/helpers";

const TableCellStyled = styled(TableCell)<{ width: number } & TableCellProps>(
  ({ width }) => ({
    width,
    padding: 8,
    position: "relative",
    verticalAlign: "baseline",
  }),
);

interface TableHeaderCellProps {
  header: Header<any, any>;
  filtersEnabled: boolean;
  sortEnabled: boolean;
  dndEnabled: boolean;
  stickyColumnsEnabled: boolean;
  resizeMode?: ColumnResizeMode;
  rerender(): void;
  resizingDeltaOffset: number | null;
  columnOrder: ColumnOrderState;
  setColumnOrder(order: ColumnOrderState): void;
}

export const TableHeaderCell: FC<TableHeaderCellProps> = ({
  header,
  filtersEnabled,
  resizingDeltaOffset,
  dndEnabled,
  sortEnabled,
  resizeMode,
  stickyColumnsEnabled,
  rerender,
  columnOrder,
  setColumnOrder,
}) => {
  const ref = useRef<HTMLTableCellElement>(null);
  const [left, setLeft] = useState<number | null>(null);

  const [hover, setHover] = useState(false);
  const onEnter = useCallback(() => setHover(true), []);
  const onLeave = useCallback(() => setHover(false), []);

  const stickySide = header?.column?.columnDef?.meta?.sticky;
  const isSticky = stickyColumnsEnabled && stickySide;

  const isLeftLastSticky =
    stickySide === "left" &&
    !header?.headerGroup?.headers?.[header.index + 1]?.column?.columnDef?.meta
      ?.sticky;

  const isRightFirstSticky =
    stickySide === "right" &&
    !header?.headerGroup?.headers?.[header.index - 1]?.column?.columnDef?.meta
      ?.sticky;

  const multiplySticky =
    !!header?.headerGroup?.headers?.[header.index + 1]?.column?.columnDef
      ?.meta ||
    !!header?.headerGroup?.headers?.[header.index - 1]?.column?.columnDef?.meta;

  const listener = useCallback(() => {
    setLeft(null);
  }, []);

  const stickyStyles = useMemo(
    () =>
      getStickyCellStyles(
        left,
        isLeftLastSticky || isRightFirstSticky,
        stickySide,
        stickySide === "right" ? 0 : undefined,
      ),
    [left, stickySide, isLeftLastSticky, isRightFirstSticky],
  );

  if (header.column.getCanResize()) {
    stickyStyles.overflow = "visible";
  }

  const handleRerender = useCallback(() => {
    if (isSticky) {
      rerender();
    }
  }, [rerender, isSticky]);

  useEffect(() => {
    if (!ref.current || left !== null || !isSticky || stickySide === "right") {
      return;
    }

    const parentLeft =
      ref.current.parentElement?.getBoundingClientRect()?.x || 0;
    setLeft(ref.current?.getBoundingClientRect()?.x - parentLeft);
  }, [left, isSticky, stickySide]);

  useEffectOnceWhen(() => {
    window.addEventListener("resize", listener);
  }, multiplySticky);

  useWillUnmount(() => {
    window.removeEventListener("resize", listener);
  });

  return (
    <TableCellStyled
      ref={ref}
      width={header.getSize()}
      colSpan={header.colSpan}
      onMouseEnter={onEnter}
      onMouseLeave={onLeave}
      sx={isSticky ? stickyStyles : undefined}
    >
      <TableHeaderCellContent
        header={header}
        hovered={hover}
        dndEnabled={dndEnabled}
        resizeMode={resizeMode}
        sortEnabled={sortEnabled}
        columnOrder={columnOrder}
        filtersEnabled={filtersEnabled}
        setColumnOrder={setColumnOrder}
        onResizeEndHandler={handleRerender}
        resizingDeltaOffset={resizingDeltaOffset}
      />
    </TableCellStyled>
  );
};
