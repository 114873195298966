import { FC, useCallback, useMemo } from "react";
import { Stack, TextField } from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";
import { useSnackbar } from "notistack";
import { LoadingButton } from "@mui/lab";
import {
  useGetUsersMeOrganisationsCurrentSettingsQuery,
  usePatchUsersMeOrganisationsCurrentSettingsByKeyMutation,
} from "../../../../../../services/cloudchipr.api";

interface SmartTagTtlFormValues {
  name: string;
}

export const SmartTagTTLForm: FC = () => {
  const { data, refetch } = useGetUsersMeOrganisationsCurrentSettingsQuery();
  const [patchOrg, { isLoading }] =
    usePatchUsersMeOrganisationsCurrentSettingsByKeyMutation();
  const { enqueueSnackbar } = useSnackbar();

  const handleSnackbarShow = useCallback(() => {
    enqueueSnackbar("Smart tag updated successfully!", {
      variant: "snackbarAlert",
      AlertSnackBarProps: {
        severity: "success",
      },
      autoHideDuration: 5000,
    });
  }, [enqueueSnackbar]);

  const smartTagTtlInitialValues = useMemo<SmartTagTtlFormValues>(
    () => ({
      name: data?.smart_tag_ttl?.value || "",
    }),
    [data?.smart_tag_ttl?.value],
  );

  const formik = useFormik<SmartTagTtlFormValues>({
    initialValues: smartTagTtlInitialValues,
    validationSchema: yup.object({
      name: yup.string().required(),
    }),
    onSubmit: async (values) => {
      await patchOrg({
        key: "smart_tag_ttl",
        body: {
          value: values.name,
        },
      });
      refetch();
      handleSnackbarShow();
    },
    enableReinitialize: true,
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Stack direction="row" my={4} gap={4}>
        <TextField
          type="text"
          label="Tag Type"
          name="name"
          size="small"
          value={formik.values.name}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={!!(formik.errors.name && formik.touched.name)}
          sx={{ flexGrow: 1 }}
        />
        <LoadingButton
          type="submit"
          disabled={!((formik.isValid && formik.dirty) || isLoading)}
          loading={isLoading}
          color="primary"
          variant="contained"
        >
          confirm change
        </LoadingButton>
      </Stack>
    </form>
  );
};
