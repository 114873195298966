import { FC } from "react";
import { Typography } from "@mui/material";

export const EmailIntegrationMessageNotifySubject: FC = () => {
  return (
    <Typography variant="body1">
      Subject:{" "}
      <Typography component="span" fontWeight="medium" variant="body1">
        Resources Found by Automation Workflow - {`{{workflow name}}`}
      </Typography>
    </Typography>
  );
};
