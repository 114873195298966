import { FC, ReactNode } from "react";
import {
  Box,
  Skeleton,
  Stack,
  Theme,
  Typography,
  TypographyVariant,
} from "@mui/material";
import { SxProps } from "@mui/system";
import { blue, grey, red, green } from "@mui/material/colors";

export type StatisticColors = "blue" | "red" | "green" | "default";
export type StatisticPropsSize = "small" | "medium" | "large";
interface StatisticProps {
  title: string;
  value: string | number;
  chipValue?: string | number;
  color?: StatisticColors;
  indicator?: "monthly";
  size?: StatisticPropsSize;
  loading?: boolean;
  sx?: SxProps<Theme>;
  endAdornment?: ReactNode;
}

export const Statistic: FC<StatisticProps> = ({
  title,
  value,
  color = "default",
  size = "medium",
  loading,
  sx,
  chipValue,
  indicator,
  endAdornment,
}) => {
  const styles = {
    color: StatisticColor.get(color),
    bgcolor: StatisticBgColor.get(color),
    pl: StatisticSpacing.get(size)?.get("pl"),
    pr: StatisticSpacing.get(size)?.get("pr"),
    py: StatisticSpacing.get(size)?.get("py"),
    borderLeft: StatisticBorder.get(size)?.get("borderLeft"),
    valueVariant: StatisticTypography.get(size)?.get("value")?.variant,
    titleVariant: StatisticTypography.get(size)?.get("title")?.variant,
    valueFontSize: StatisticTypography.get(size)?.get("value")?.fontSize,
    titleFontSize: StatisticTypography.get(size)?.get("title")?.fontSize,
    valueFontWeight: StatisticTypography.get(size)?.get("value")?.fontWeight,
    titleFontWeight: StatisticTypography.get(size)?.get("title")?.fontWeight,
  };

  return (
    <Box
      borderLeft={styles.borderLeft}
      borderColor={styles.color}
      pl={styles.pl}
      py={styles.py}
      pr={styles.pr}
      sx={sx}
    >
      {loading ? (
        <Skeleton variant="text" height={styles.valueFontSize} width={40} />
      ) : (
        <Stack direction="row" justifyContent="space-between" pb={0.5}>
          <Typography
            variant={styles.valueVariant}
            fontSize={styles.valueFontSize}
            lineHeight={1}
            fontWeight={styles.valueFontWeight}
            pt={0.5}
          >
            {value}

            {indicator === "monthly" && (
              <Typography
                component="span"
                variant="body2"
                color="text.secondary"
              >
                /mo
              </Typography>
            )}
          </Typography>
          {endAdornment}
        </Stack>
      )}

      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Typography
          variant={styles.titleVariant}
          fontWeight={styles.titleFontWeight}
          color="text.secondary"
          fontSize={styles.titleFontSize}
          lineHeight={1}
        >
          {title}
        </Typography>

        {chipValue !== undefined && (
          <Box
            px={1}
            ml={1}
            borderRadius={1}
            alignItems="center"
            bgcolor={styles.bgcolor}
          >
            <Typography
              variant="caption"
              color={styles.color}
              fontWeight={500}
              noWrap
            >
              {chipValue}
            </Typography>
          </Box>
        )}
      </Stack>
    </Box>
  );
};

const StatisticColor = new Map<"blue" | "red" | "green" | "default", string>([
  ["blue", blue[400]],
  ["red", red[400]],
  ["green", green[500]],
  ["default", grey[400]],
]);

const StatisticBgColor = new Map<"blue" | "red" | "green" | "default", string>([
  ["blue", blue[50]],
  ["red", red[50]],
  ["green", green[50]],
  ["default", grey[50]],
]);

type StatisticSpacingType = Map<"py" | "pl" | "pr" | "px", number>;
type StatisticBorderType = Map<"borderLeft", number>;
type StatisticTypographyType = Map<
  "title" | "value",
  {
    variant: TypographyVariant;
    fontSize?: number;
    fontWeight?: number | string;
  }
>;

const StatisticSpacingSmall: StatisticSpacingType = new Map([
  ["py", 0.5],
  ["pl", 2],
  ["pr", 2],
]);

const StatisticSpacingMedium: StatisticSpacingType = new Map([
  ["py", 1],
  ["pl", 2],
  ["pr", 2],
]);

const StatisticSpacingLarge: StatisticSpacingType = new Map([
  ["py", 0],
  ["pl", 2],
  ["pr", 2],
]);

const StatisticBorderSmall: StatisticBorderType = new Map([["borderLeft", 2]]);

const StatisticBorderMedium: StatisticBorderType = new Map([["borderLeft", 2]]);

const StatisticBorderLarge: StatisticBorderType = new Map([["borderLeft", 4]]);

const StatisticTypographySmall: StatisticTypographyType = new Map([
  ["title", { variant: "caption", fontSize: 10, fontWeight: "bold" }],
  ["value", { variant: "subtitle2", fontSize: 14 }],
]);
const StatisticTypographyMedium: StatisticTypographyType = new Map([
  ["title", { variant: "caption", fontSize: 12, fontWeight: "bold" }],
  ["value", { variant: "h6", fontSize: 20 }],
]);

const StatisticTypographyLarge: StatisticTypographyType = new Map([
  ["title", { variant: "caption", fontSize: 12, fontWeight: 500 }],
  ["value", { variant: "subtitle1", fontWeight: 700 }],
]);

const StatisticSpacing = new Map<StatisticPropsSize, StatisticSpacingType>([
  ["small", StatisticSpacingSmall],
  ["medium", StatisticSpacingMedium],
  ["large", StatisticSpacingLarge],
]);

const StatisticBorder = new Map<StatisticPropsSize, StatisticBorderType>([
  ["small", StatisticBorderSmall],
  ["large", StatisticBorderLarge],
  ["medium", StatisticBorderMedium],
]);

const StatisticTypography = new Map<
  StatisticPropsSize,
  StatisticTypographyType
>([
  ["small", StatisticTypographySmall],
  ["medium", StatisticTypographyMedium],
  ["large", StatisticTypographyLarge],
]);
