import { RootState } from "../../../../../store";
import { Reservation } from "../../../../../../services/cloudchipr.api";
import { commitmentsDetailsDrawerTargetIdSelector } from "../../../common/details-drawer/commitmentsDetailsDrawerTargetIdSelector";
import { reservationsDataSelector } from "../../data/reservationsDataSelector";

export const selectedReservationDataSelector = (
  state: RootState,
): Reservation | undefined => {
  const data = reservationsDataSelector(state);
  const id = commitmentsDetailsDrawerTargetIdSelector(state);

  return data?.find((sp) => sp.provider_unique_id === id);
};
