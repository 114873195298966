import { FilterFn } from "@tanstack/react-table";
import moment from "moment/moment";
import { normalizeString } from "./common";
import {
  dateFromUtcByTimeZone,
  formatDate,
} from "../../../../utils/helpers/date-time/datetime-format";
import { age, durationToNow } from "../../../../utils/helpers/date-time/time";

export const dateFilter: FilterFn<any> = (row, columnId, value) => {
  const date = `${row.getValue(columnId) || ""}`;

  return commonDateFilter(columnId, value, date);
};

export const commonDateFilter = (
  columnId: string,
  value: string,
  data: string,
) => {
  const val = normalizeString(value);

  const launchDate = moment(dateFromUtcByTimeZone(data));

  const content = `${formatDate(launchDate, {
    type: "dateTime",
  })} (${durationToNow(age(launchDate.format()))})`;

  return content.includes(val);
};
