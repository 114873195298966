import { createDraftSafeSelector } from "@reduxjs/toolkit";
import { liveUsageMgmtFilterTemplatesSelector } from "./liveUsageMgmtFilterTemplatesSelector";

export const liveUsageMgmtFilterNoneCloudchiprTemplatesSelector =
  createDraftSafeSelector(
    [liveUsageMgmtFilterTemplatesSelector],
    (templates) => {
      return templates?.filter(({ source }) => source !== "cloudchipr");
    },
  );
