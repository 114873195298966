import { cloudChiprApi } from "../../../../services/cloudchipr.api";
import { RootState } from "../../../store";

const selector =
  cloudChiprApi.endpoints
    .patchUsersMeOrganisationsCurrentResourceExplorerViewsByResourceExplorerViewId
    .select;

export const updateResourceExplorerLoadingSelector = (
  state: RootState,
  viewId: string,
): boolean | undefined => {
  if (!viewId) {
    return false;
  }

  return selector(viewId)(state)?.isLoading;
};
