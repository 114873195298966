import { FC } from "react";
import { Chip, Stack } from "@mui/material";
import AttachmentOutlinedIcon from "@mui/icons-material/AttachmentOutlined";
import { ReportsNotificationsCustomMessageContentProps } from "./ReportsNotificationsCustomMessageContent";
import { ReportsCustomMessageSubject } from "./ReportsCustomMessageSubject";
import { ReportsCustomMessageBody } from "./ReportsCustomMessageBody";
import { CreateIntegrationMessageEditor } from "../../../../../../common/integration-dialogs/components/common/CreateIntegrationMessageEditor";

interface ReportsCustomMessageDialogContentProps
  extends ReportsNotificationsCustomMessageContentProps {
  onCustomMessageChange(message: string): void;
}

export const ReportsCustomMessageDialogContent: FC<
  ReportsCustomMessageDialogContentProps
> = ({ type, onCustomMessageChange, message }) => {
  return (
    <Stack spacing={1} p={2} bgcolor="grey.50">
      {type === "email" && <ReportsCustomMessageSubject />}

      <ReportsCustomMessageBody />

      <CreateIntegrationMessageEditor
        value={message ?? ""}
        onChange={onCustomMessageChange}
        placeholder="Write a custom message..."
      />

      <Stack direction="row" spacing={1}>
        <Chip
          avatar={<AttachmentOutlinedIcon fontSize="small" />}
          label="Resources.csv"
        />
        <Chip
          avatar={<AttachmentOutlinedIcon fontSize="small" />}
          label="Resources.pdf"
        />
      </Stack>
    </Stack>
  );
};
