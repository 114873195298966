import { RootState } from "../../../../../store";
import { workflowsDataSelector } from "../../list-data/workflowsDataSelector";
import { getCronFrequency } from "../../../../utils/helpers/cron/getCronFrequency";

export const automationsFrequenciesFilterOptionsSelector = (
  state: RootState,
) => {
  const workflows = workflowsDataSelector(state);

  const options = workflows?.map((workflow) => {
    return getCronFrequency(workflow.cron);
  });

  return Array.from(new Set(options));
};
