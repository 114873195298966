import { FC } from "react";
import Stack from "@mui/material/Stack";
import { Typography } from "@mui/material";
import DiscountOutlinedIcon from "@mui/icons-material/DiscountOutlined";
import { VewTagsSelect } from "./VewTagsSelect";

export const ViewTagsSettings: FC = () => {
  return (
    <Stack px={2} py={2} spacing={1}>
      <Stack direction="row" spacing={0.5}>
        <DiscountOutlinedIcon fontSize="small" />
        <Typography variant="body2" fontWeight="bold">
          Enable Cost Allocation Tags
        </Typography>
      </Stack>
      <Stack spacing={2}>
        <Stack spacing={1}>
          <Typography variant="caption" lineHeight={1.25}>
            For cost allocation tags to appear in your filters and allow you to
            filter your billing data, you must enable them from the list below.
          </Typography>
          <Typography variant="caption" lineHeight={1.25} fontStyle="italic">
            *It may take up to 12 hours for the tags to appear in the filters.
          </Typography>
        </Stack>

        <VewTagsSelect />
      </Stack>
    </Stack>
  );
};
