import { FC } from "react";
import { Divider, Stack } from "@mui/material";
import { SlackIntegrationMessageNotifyMetaData } from "../../../slack/notify/SlackIntegrationMessageNotifyMetaData";
import { SlackIntegrationMessageNotifyWorkflowInfo } from "../../../slack/notify/SlackIntegrationMessageNotifyWorkflowInfo";
import { SlackIntegrationDataGrid } from "../../../slack/common/SlackIntegrationDataGrid";
import { CreateIntegrationMessageEditor } from "../../../../../../../../common/integration-dialogs/components/common/CreateIntegrationMessageEditor";

interface SlackIntegrationNotifyDialogContentProps {
  customMessage: string;
  onCustomMessageChange(message: string): void;
}

export const SlackIntegrationNotifyDialogContent: FC<
  SlackIntegrationNotifyDialogContentProps
> = ({ customMessage, onCustomMessageChange }) => {
  return (
    <Stack spacing={1.5}>
      <SlackIntegrationMessageNotifyMetaData />
      <SlackIntegrationMessageNotifyWorkflowInfo />

      <CreateIntegrationMessageEditor
        value={customMessage ?? ""}
        onChange={onCustomMessageChange}
        placeholder="Write a custom message..."
      />
      <Divider sx={{ mt: 1 }} />

      <SlackIntegrationDataGrid />
    </Stack>
  );
};
