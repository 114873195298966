import { FC, Fragment, useCallback, useEffect, useState } from "react";
import {
  Box,
  Button,
  Divider,
  IconButton,
  Stack,
  Tooltip,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { AutomationResourceSelect } from "./resource-select/AutomationResourceSelect";
import {
  setAutomationData,
  setAutomationErrors,
} from "../../../../../../../store/automations/automationsSlice";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../store/hooks";
import { ResourceType } from "../../../../../../../services/cloudchipr.api";
import { automationResourcesSelector } from "../../../../../../../store/automations/selectros/common/fields/automationResourcesSelector";
import { automationProviderSelector } from "../../../../../../../store/automations/selectros/common/fields/automationProviderSelector";
import { automationAccountIdsSelector } from "../../../../../../../store/automations/selectros/common/fields/automationAccountIdsSelector";
import { allDefaultFiltersForAutomationSelector } from "../../../../../../../store/automations/selectros/common/helpers-data/allDefaultFiltersForAutomationSelector";
import { useFilterActionsAdder } from "../../../../workflows/single-workflow/utils/hooks/useCurrentAutomationFilterActionsAdder.hook";
import { automationFilterExistenceErrorSelector } from "../../../../../../../store/automations/selectros/common/errors/filters/automationFilterExistenceErrorSelector";

export const AddResourceButton: FC = () => {
  const dispatch = useAppDispatch();
  const addActionToFilter = useFilterActionsAdder();

  const provider = useAppSelector(automationProviderSelector);
  const error = useAppSelector(automationFilterExistenceErrorSelector);
  const accountIds = useAppSelector(automationAccountIdsSelector);
  const resources = useAppSelector(automationResourcesSelector);
  const allDefaultFilters = useAppSelector(
    allDefaultFiltersForAutomationSelector,
  );

  const [selectionVisible, setSelectionVisible] = useState(!resources.length);

  const addResourceButtonClickHandler = useCallback(() => {
    setSelectionVisible(true);
  }, []);

  const removeHandler = useCallback(() => {
    setSelectionVisible(false);
  }, []);

  const selectedResourcesExist = !!resources.length;

  const changeHandler = useCallback(
    (resourceType: ResourceType) => {
      const defaultFilters = allDefaultFilters?.[resourceType];

      if (!defaultFilters) {
        return;
      }

      setSelectionVisible(false);
      dispatch(
        setAutomationData({
          filter: [...resources, addActionToFilter(defaultFilters)],
        }),
      );

      if (!selectedResourcesExist && error) {
        dispatch(setAutomationErrors({ filter: "" }));
      }
    },
    [
      error,
      resources,
      dispatch,
      allDefaultFilters,
      addActionToFilter,
      selectedResourcesExist,
    ],
  );

  const selectionDisabled = !accountIds.length || !provider;

  useEffect(() => {
    if (!resources.length) {
      setSelectionVisible(true);
    }
  }, [resources.length]);

  return (
    <Fragment>
      {selectionVisible && (
        <Tooltip
          arrow
          placement="top"
          title={
            selectionDisabled
              ? "At least one account should be selected to enable Resource Selection."
              : ""
          }
        >
          <Box mt={2}>
            {!!resources.length && <Divider>AND</Divider>}
            <Stack
              direction="row"
              spacing={2}
              alignItems="center"
              mt={selectedResourcesExist ? 2 : 0}
            >
              <AutomationResourceSelect
                error={error}
                onChange={changeHandler}
                disabled={selectionDisabled}
              />

              {selectedResourcesExist && (
                <IconButton onClick={removeHandler}>
                  <DeleteOutlineIcon />
                </IconButton>
              )}
            </Stack>
          </Box>
        </Tooltip>
      )}

      <Button
        size="small"
        variant="outlined"
        sx={{ alignSelf: "start", mt: 2 }}
        disabled={selectionVisible}
        startIcon={<AddIcon />}
        onClick={addResourceButtonClickHandler}
      >
        Add Service
      </Button>
    </Fragment>
  );
};
