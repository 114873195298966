import { FC } from "react";
import { Stack, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import { CommitmentsProgressBar } from "../../../../../../common/components/CommitmentsProgressBar";
import { SavingsPlan } from "../../../../../../../../../services/cloudchipr.api";
import { getPercentage } from "../../../../../../../../../utils/numeral/getPercentage";
import { money } from "../../../../../../../../../utils/numeral/money";
import { getColorByValue } from "../../../../../../common/utils/helpers/grid/dataGridHelpers";

interface UtilizationCellProps {
  savingsPlan: SavingsPlan;
}

export const UtilizationCell: FC<UtilizationCellProps> = ({ savingsPlan }) => {
  const used = savingsPlan.used_commitment_to_date;
  const total = savingsPlan.total_commitment_to_date;
  const state = savingsPlan.state;
  const expired = state === "expired";
  const percent = +getPercentage(used ?? 0, total ?? 0).toFixed(2);
  const color = getColorByValue(percent);

  return (
    <Stack>
      <Stack direction="row" spacing={0.5}>
        <Typography
          variant="body2"
          fontWeight="medium"
          color={expired ? "text.secondary" : color}
        >
          {money(used)}
        </Typography>
      </Stack>

      <CommitmentsProgressBar
        value={percent}
        overrideColor={expired ? grey[500] : undefined}
      />
    </Stack>
  );
};
