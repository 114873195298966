import { FC, SyntheticEvent, useCallback, useState } from "react";
import { Box, Grid, RadioGroup, Typography } from "@mui/material";
import { GCPConnectionTypeOption } from "./GCPConnectionTypeOption";
import { GcpConnectionType } from "../utils/types";
import { setGcpConnectionType } from "../../../../../../../store/account-setup/accountSetupSlice";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../store/hooks";
import { gcpConnectionTypeSelector } from "../../../../../../../store/account-setup/selectors/gcpConnectionTypeSelector";

export const GcpConnectionTypes: FC = () => {
  const dispatch = useAppDispatch();
  const connectionType = useAppSelector(gcpConnectionTypeSelector);

  const [selectedConnectionType, setSelectedConnectionType] =
    useState(connectionType);

  const handleRadioSelection = useCallback(
    (_: SyntheticEvent, value: string) => {
      setSelectedConnectionType(value as GcpConnectionType);
      dispatch(setGcpConnectionType(value as GcpConnectionType));
    },
    [dispatch],
  );

  return (
    <Box pb={4}>
      <Typography variant="body1" pb={2} fontWeight="medium">
        Choose Single Project or Organization
      </Typography>
      <RadioGroup
        value={selectedConnectionType}
        onChange={handleRadioSelection}
      >
        <Grid container spacing={2}>
          {gcpConnectionTypeContents.map((ct) => (
            <Grid
              item
              key={ct.value}
              xs={6}
              display="flex"
              alignItems="stretch"
            >
              <GCPConnectionTypeOption
                key={ct.value}
                title={ct.title}
                value={ct.value}
                selected={ct.value === selectedConnectionType}
                description={ct.caption}
              />
            </Grid>
          ))}
        </Grid>
      </RadioGroup>
    </Box>
  );
};

const gcpConnectionTypeContents = [
  {
    value: "single",
    title: "Single Project",
    caption: "Provide access to single project.",
  },
  {
    value: "organization",
    title: "Organization",
    caption:
      "Cloudchipr will automatically detect all projects in organization.",
  },
];
