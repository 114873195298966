import { createDraftSafeSelector } from "@reduxjs/toolkit";
import { reservationDetailsDataSelector } from "./data/reservationDetailsDataSelector";
import { CommitmentDateGranularDataPoint } from "../../../../../services/cloudchipr.api";

export const reservationDetailsGranularDataGroupedByDateSelector =
  createDraftSafeSelector(
    [reservationDetailsDataSelector],
    (data): Record<string, CommitmentDateGranularDataPoint> | undefined => {
      return data?.data?.reduce(
        (acc, data) => {
          acc[data.date] = data;

          return acc;
        },
        {} as Record<string, CommitmentDateGranularDataPoint>,
      );
    },
  );
