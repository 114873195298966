import { largestCostChangesWidgetByIdSelector } from "./largestCostChangesWidgetByIdSelector";
import { RootState } from "../../../../store";
import { largestCostChangesSetupSelector } from "../../setups/larges-cost-changes/largestCostChangesSetupSelector";

export const largestCostChangesWidgetEditChangesExistSelector = (
  state: RootState,
) => {
  const setup = largestCostChangesSetupSelector(state);

  if (!setup?.id) {
    return false;
  }

  const widget = largestCostChangesWidgetByIdSelector(state, setup?.id);

  if (!widget) {
    return false;
  }

  return (
    setup.name !== widget.name ||
    setup.viewId !== widget.view_id ||
    setup.grouping !== widget.grouping ||
    setup.dates.from !== widget.dates.from ||
    setup.dates.to !== widget.dates.to ||
    setup.dates.label !== widget.dates.label ||
    setup.dates.value !== widget.dates.value ||
    setup.dates.type !== widget.dates.type ||
    !!widget.min_cost_change !== !!setup.minCostChange?.state ||
    setup.minCostChange?.value !== widget.min_cost_change ||
    setup.priceDirection !== widget.price_direction ||
    setup.sortingBy !== widget.sort_by ||
    !!widget.min_percentage_change !== !!setup.minPercentChange?.state ||
    setup.minPercentChange?.value !== widget.min_percentage_change ||
    setup.itemsCount !== widget.items_count
  );
};
