import { FC, Ref, useCallback, useRef } from "react";
import {
  FormControl,
  InputLabel,
  Select,
  FormHelperText,
  PopoverActions,
} from "@mui/material";
import { AutomationResourceSelectItem } from "./AutomationResourceSelectItem";
import { ResourceType } from "../../../../../../../../services/cloudchipr.api";
import { getResourceTypeName } from "../../../../../../../../utils/helpers/resources/getResourceTypeName";
import { resourceGroups } from "../../../../../../../../utils/constants/resources/resourceGroups";
import { useAppSelector } from "../../../../../../../../store/hooks";
import { automationProviderSelector } from "../../../../../../../../store/automations/selectros/common/fields/automationProviderSelector";

interface AutomationResourceSelectProps {
  value?: ResourceType;
  disabled?: boolean;
  error?: string | boolean;
  onChange(rt: ResourceType): void;
}

export const AutomationResourceSelect: FC<AutomationResourceSelectProps> = ({
  value,
  onChange,
  error,
  disabled,
}) => {
  const popoverActions = useRef<PopoverActions>();
  const provider = useAppSelector(automationProviderSelector);

  const renderValue = useCallback(
    (rt: any) => getResourceTypeName(rt as ResourceType, { type: "long" }),
    [],
  );

  const recalculateMenuPosition = useCallback(() => {
    popoverActions?.current?.updatePosition();
  }, []);

  return (
    <FormControl size="small" fullWidth>
      <InputLabel color={error ? "error" : undefined}>
        Select Service*
      </InputLabel>
      <Select
        size="small"
        value={value ?? ""}
        color={error ? "error" : undefined}
        error={!!error}
        disabled={disabled}
        label="Select Service*"
        renderValue={renderValue}
        MenuProps={{ action: popoverActions as Ref<PopoverActions> }}
      >
        {provider &&
          resourceGroups.get(provider)?.map((resourceGroup) => {
            return (
              <AutomationResourceSelectItem
                onChange={onChange}
                key={resourceGroup.value}
                resourceGroup={resourceGroup}
                recalculateMenuPosition={recalculateMenuPosition}
              />
            );
          })}
      </Select>

      {error && <FormHelperText error>{error}</FormHelperText>}
    </FormControl>
  );
};
