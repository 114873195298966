import { FC, Fragment } from "react";
import { IconButton, Typography } from "@mui/material";
import Stack from "@mui/material/Stack";
import CloseIcon from "@mui/icons-material/Close";
import PlayCircleFilledWhiteOutlinedIcon from "@mui/icons-material/PlayCircleFilledWhiteOutlined";
import SpokeOutlinedIcon from "@mui/icons-material/SpokeOutlined";
import { LiveIndicator } from "../../../LiveIndicator";
import { money } from "../../../../../../../../../../../../utils/numeral/money";
import { useAppSelector } from "../../../../../../../../../../../../store/hooks";
import { liveFilteredResourcesEmsSumSelector } from "../../../../../../../../../../../../store/resource-explorer/selectors/live-filtered-resources/liveFilteredResourcesEmsSumSelector";

interface LiveFilteredResourcesDrawerByViewHeaderProps {
  onDrawerClose(): void;
  secondaryTitle?: string;
}
export const LiveFilteredResourcesDrawerHeader: FC<
  LiveFilteredResourcesDrawerByViewHeaderProps
> = ({ onDrawerClose, secondaryTitle }) => {
  const totalCost = useAppSelector(liveFilteredResourcesEmsSumSelector);
  return (
    <Stack
      p={2}
      top={0}
      direction="row"
      bgcolor="white"
      zIndex="appBar"
      borderBottom={1}
      position="sticky"
      alignItems="center"
      borderColor="grey.300"
      justifyContent="space-between"
    >
      <Stack direction="row" spacing={1} alignItems="center">
        <LiveIndicator />
        <Stack spacing={0.5} direction="row" alignItems="center">
          <PlayCircleFilledWhiteOutlinedIcon
            sx={{
              fontSize: 16,
            }}
          />
          <Typography variant="body1">Live Filtered Resources:</Typography>
        </Stack>
        <Stack spacing={0.25} pl={0.5} direction="row" alignItems="center">
          <Typography variant="body1" fontWeight="medium">
            {money(totalCost)}
          </Typography>
          {totalCost !== null && (
            <Typography variant="caption" color="text.secondary">
              /mo
            </Typography>
          )}
        </Stack>
        {secondaryTitle && (
          <Fragment>
            <SpokeOutlinedIcon fontSize="medium" />
            <Typography variant="body1" fontWeight="medium">
              {secondaryTitle}
            </Typography>
          </Fragment>
        )}
      </Stack>

      <IconButton onClick={onDrawerClose} size="small">
        <CloseIcon />
      </IconButton>
    </Stack>
  );
};
