import { FC } from "react";
import { Box, CircularProgress, Stack, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import { useAppSelector } from "../../../../../../../../store/hooks";
import { billingInProgressStatusesSelector } from "../../../../../../../../store/common/selectors/billing-status/in-progress/billingInProgressStatusesSelector";
import { ProviderIcon } from "../../../../../../../common/ProviderIcon";
import { getProviderName } from "../../../../../../../../utils/helpers/providers/getProviderName";

export const ResourceExplorerInProgressBillingStatusPanel: FC = () => {
  const inProgressStatuses = useAppSelector(billingInProgressStatusesSelector);

  return (
    <Stack spacing={2}>
      {inProgressStatuses.map((item) => {
        return (
          <Stack
            key={`${item.account_id}${item.provider}`}
            direction="row"
            bgcolor="grey.100"
            borderRadius={4}
            gap={1}
            p={2}
            justifyContent="space-between"
            alignItems="center"
          >
            <Stack direction="row" bgcolor="grey.100" borderRadius={4} gap={1}>
              <ProviderIcon provider={item.provider} />
              <Typography
                variant="subtitle1"
                fontWeight={500}
                sx={{
                  wordBreak: "break-all",
                }}
              >
                Collecting billing data for{" "}
                {item?.name || item?.provider_account_id}{" "}
                {getProviderName(item.provider)}.
              </Typography>
            </Stack>
            <Box>
              <CircularProgress
                size={24}
                sx={{
                  color: grey["600"],
                }}
              />
            </Box>
          </Stack>
        );
      })}
    </Stack>
  );
};
