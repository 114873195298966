import { getUsersMeOrganisationsCurrentWidgetsLargestCostChangesSelector } from "./getUsersMeOrganisationsCurrentWidgetsLargestCostChangesSelector";
import { RootState } from "../../../../../store";
import { largesCostChangesSetupWidgetPayloadDataSelector } from "../largesCostChangesSetupWidgetPayloadDataSelector";

export const largesCostChangesSetupWidgetDataResponseSelector = (
  state: RootState,
) => {
  const payload = largesCostChangesSetupWidgetPayloadDataSelector(state);

  if (!payload) {
    return;
  }

  return getUsersMeOrganisationsCurrentWidgetsLargestCostChangesSelector(
    payload,
  )(state);
};
