import { organisationCurrentFilterSetsSelector } from "./organisationCurrentFilterSetsSelector";
import { RootState } from "../../../store";
import { FilterSet } from "../../../../services/cloudchipr.api";

export const organisationFilterSetByFilterSetIdSelector = (
  state: RootState,
  filterSetId?: string | null,
): FilterSet | undefined => {
  if (!filterSetId) {
    return;
  }
  const data = organisationCurrentFilterSetsSelector(state);
  return data?.find((item) => item.id === filterSetId);
};
