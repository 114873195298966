import { FC, useCallback } from "react";
import { TimePicker } from "../../../../../../common/TimePicker";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../store/hooks";
import { workflowSchedulerSelector } from "../../../../../../../store/automations/selectros/workflow/workflowSchedulerSelector";
import { setWorkflowSpecificData } from "../../../../../../../store/automations/automationsSlice";
import { NullableDate } from "../../../../../../../services/cloudchipr.api";
import { fetchWorkflowNextRunsThunk } from "../../../../../../../store/automations/thunks/wokrflows/fetchWorkflowNextRunsThunk";

export const WorkflowRunTime: FC = () => {
  const dispatch = useAppDispatch();

  const scheduler = useAppSelector(workflowSchedulerSelector);

  const changeHandler = useCallback(
    (date: NullableDate) => {
      dispatch(
        setWorkflowSpecificData({
          scheduler: { ...scheduler, runTime: date },
        }),
      );
      dispatch(fetchWorkflowNextRunsThunk());
    },
    [dispatch, scheduler],
  );

  return (
    <TimePicker icon value={scheduler?.runTime} onChange={changeHandler} />
  );
};
