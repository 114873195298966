import { FC, useEffect, useState } from "react";
import { Tooltip, TooltipProps, Typography } from "@mui/material";
import Stack from "@mui/material/Stack";
import { useHover } from "../../../../../../../../utils/hooks/useHover.hook";

interface TypographyWithTooltipProps {
  title: TooltipProps["title"];
}

export const DescriptionPopover: FC<TypographyWithTooltipProps> = ({
  title,
}) => {
  const { ref, hovered } = useHover();
  const [withTooltip, setWithTooltip] = useState(true);

  useEffect(() => {
    if (!ref?.current) {
      return;
    }

    const { clientWidth, scrollWidth } = ref.current;

    if (clientWidth >= scrollWidth) {
      setWithTooltip(false);
    } else {
      setWithTooltip(true);
    }
  }, [title, hovered, ref]);

  return (
    <Tooltip
      arrow
      placement="bottom"
      componentsProps={componentsProps}
      title={
        withTooltip && title ? (
          <Stack spacing={1}>
            <Typography variant="body2" fontWeight="medium">
              Description
            </Typography>

            <Typography variant="body2">{title}</Typography>
          </Stack>
        ) : (
          ""
        )
      }
    >
      <Typography
        ref={ref}
        variant="body2"
        color="text.secondary"
        sx={{
          textOverflow: "ellipsis",
          overflow: "hidden",
          whiteSpace: "nowrap",
        }}
      >
        {title}
      </Typography>
    </Tooltip>
  );
};

const componentsProps: TooltipProps["componentsProps"] = {
  tooltip: {
    sx: {
      p: 1,
      boxShadow: 7,
      fontSize: 14,
      minWidth: 300,
      color: "text.primary",
      bgcolor: "background.paper",
    },
  },
  arrow: { sx: { color: "background.paper" } },
};
