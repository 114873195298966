import { RootState } from "../../../../../store";
import { CommitmentDateGranularDataPoint } from "../../../../../../services/cloudchipr.api";
import { savingsPlansDataForGranularSelector } from "../savingsPlansDataForGranularSelector";

export const savingsPlansGranularDataSelector = (
  state: RootState,
): CommitmentDateGranularDataPoint[] | undefined => {
  const data = savingsPlansDataForGranularSelector(state);

  return data?.date_granular_data?.data;
};
