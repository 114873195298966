import { coverageRecommendationsPayloadSelector } from "./coverageRecommendationsPayloadSelector";
import { RootState } from "../../../../../store";
import { cloudChiprApi } from "../../../../../../services/cloudchipr.api";

export const savingPlansCoverageRecommendationsSelector = (
  state: RootState,
) => {
  const payload = coverageRecommendationsPayloadSelector(state);

  if (!payload) {
    return;
  }
  return cloudChiprApi.endpoints.getUsersMeOrganisationsCurrentSavingsPlansRecommendations.select(
    payload,
  )(state);
};
