import { FC, useCallback } from "react";
import {
  Button,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { Logout } from "@mui/icons-material";
import { useAuth0 } from "@auth0/auth0-react";

interface SettingsNavigationLogoutButtonProps {
  inPopover: boolean;
}
export const SettingsNavigationLogoutButton: FC<
  SettingsNavigationLogoutButtonProps
> = ({ inPopover }) => {
  const { logout } = useAuth0();
  const handleOnLogOutClick = useCallback(
    () => logout({ returnTo: window.location.origin }),
    [logout],
  );

  return (
    <ListItem sx={{ px: 1 }}>
      {inPopover ? (
        <ListItemButton
          sx={{ borderRadius: 2, p: 1 }}
          onClick={handleOnLogOutClick}
        >
          <ListItemIcon sx={{ minWidth: 28, color: "text.primary" }}>
            <Logout fontSize="small" />
          </ListItemIcon>

          <ListItemText
            sx={{ m: 0 }}
            primaryTypographyProps={{
              fontWeight: "normal",
              variant: "subtitle2",
            }}
          >
            Log Out
          </ListItemText>
        </ListItemButton>
      ) : (
        <Button
          variant="outlined"
          sx={{ flexGrow: 1 }}
          onClick={handleOnLogOutClick}
          startIcon={<Logout fontSize="small" />}
        >
          Log Out
        </Button>
      )}
    </ListItem>
  );
};
