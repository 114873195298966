import { FC, useCallback, useMemo, useState } from "react";
import { Button, Stack } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useNavigate } from "react-router-dom";
import { BudgetPreviewTable } from "./components/BudgetPreviewTable";
import { NotificationsPreview } from "./components/NotificationsPreview";
import { StepsProps } from "../../utils/types/types";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../../store/hooks";
import { budgetPreviewSummaryDataSelector } from "../../../../../../../../../store/budgets/selectors/current-budget/data-selectors/budgetPreviewSummaryDataSelector";
import { setCurrentBudgetData } from "../../../../../../../../../store/budgets/budgetsSlice";
import { budgetPreviewNotificationDataSelector } from "../../../../../../../../../store/budgets/selectors/current-budget/data-selectors/budgetPreviewNotificationDataSelector";
import { createBudgetFromCurrentThunk } from "../../../../../../../../../store/budgets/thunks/createBudgetFromCurrentThunk";
import { isCurrentBudgetEditingSelector } from "../../../../../../../../../store/budgets/selectors/current-budget/data-selectors/isCurrentBudgetEditingSelector";
import { updateBudgetFromCurrentThunk } from "../../../../../../../../../store/budgets/thunks/updateBudgetFromCurrentThunk";
import { useGetUsersMeOrganisationsCurrentBudgetsQuery } from "../../../../../../../../../services/cloudchipr.api";

export const Step2: FC<StepsProps> = ({ onPrevious }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const { refetch } = useGetUsersMeOrganisationsCurrentBudgetsQuery();

  const isEdit = useAppSelector(isCurrentBudgetEditingSelector);
  const summaryData = useAppSelector(budgetPreviewSummaryDataSelector);
  const notificationData = useAppSelector(
    budgetPreviewNotificationDataSelector,
  );

  const summaryEditableData = useMemo(() => {
    return (
      summaryData?.map((data) => {
        if (data.key !== "Budget Name") {
          return data;
        }

        return {
          ...data,
          onValueEdit: (name: string) => {
            dispatch(setCurrentBudgetData({ name }));
          },
        };
      }) ?? null
    );
  }, [dispatch, summaryData]);

  const notificationEditedData = useMemo(() => {
    return notificationData
      ? [
          ...notificationData,
          {
            key: "Notification Method",
            value: <NotificationsPreview />,
          },
        ]
      : null;
  }, [notificationData]);

  const submitBudgetHandler = useCallback(async () => {
    setLoading(true);

    const thunk = isEdit
      ? updateBudgetFromCurrentThunk
      : createBudgetFromCurrentThunk;

    const response = await dispatch(thunk());

    if (response.payload === "success") {
      await refetch();
      setLoading(false);

      navigate("/budgets");
    } else {
      setLoading(false);
    }
  }, [dispatch, refetch, isEdit, navigate]);

  return (
    <div>
      {summaryEditableData && (
        <BudgetPreviewTable data={summaryEditableData} title="Budget Summary" />
      )}

      {notificationEditedData && (
        <BudgetPreviewTable
          data={notificationEditedData}
          title="Budget Notification"
        />
      )}

      <Stack direction="row" spacing={2}>
        <Button variant="outlined" onClick={onPrevious}>
          Previous
        </Button>
        <LoadingButton
          loading={loading}
          variant="contained"
          onClick={submitBudgetHandler}
        >
          {isEdit ? "Save" : "Create"}
        </LoadingButton>
      </Stack>
    </div>
  );
};
