import { FC, useMemo } from "react";
import { ProviderType } from "../../../../../services/cloudchipr.api";
import { AccountAddButton } from "../accounts/AccountAddButton";
import { PageHeader } from "../../../common/PageHeader";
import { GcpAccountsHeaderActionButtons } from "../accounts/gcp-billing-export/GcpAccountsHeaderActionButtons";
import { AccountsTabs } from "../accounts/AccountsTabs";
import { useAppSelector } from "../../../../../store/hooks";
import { accountsNeededInfoLoadingSelector } from "../../../../../store/accounts/selectors/loadings/accountsNeededInfoLoadingSelector";
import { AccountTabs } from "../../utils/types/types";

interface AccountsHeaderProps {
  provider: ProviderType;
  activeTab: AccountTabs;
  activeCount?: number;
  inactiveCount?: number;
  orgId?: string;
}

export const AccountsHeader: FC<AccountsHeaderProps> = ({
  provider,
  activeCount,
  inactiveCount,
  orgId,
  activeTab,
}) => {
  const accountsExist = !!(activeCount || inactiveCount);
  const loading = useAppSelector((state) =>
    accountsNeededInfoLoadingSelector(state, provider, activeTab, orgId),
  );

  const actions = useMemo(() => {
    if (!accountsExist) {
      return;
    }
    if (provider === "gcp") {
      return <GcpAccountsHeaderActionButtons />;
    }
    return <AccountAddButton provider={provider} />;
  }, [accountsExist, provider]);

  return (
    <PageHeader
      sticky
      breadcrumbs={
        accountsExist ? (
          <AccountsTabs
            activeCount={activeCount ?? 0}
            inactiveCount={inactiveCount ?? 0}
            orgId={orgId}
            provider={provider}
          />
        ) : undefined
      }
      loading={loading}
      actions={actions}
    />
  );
};
