import { RootState } from "../../../../store";
import { taskManagementSliceSelector } from "../../taskManagementSliceSelector";
import { TaskTargetType } from "../../../utils/types/types";

export const taskManagementTargetSelector = (
  state: RootState,
): TaskTargetType | null => {
  const slice = taskManagementSliceSelector(state);

  return slice.form.target;
};
