import { gcpAllAccountsSelector } from "./gcpAllAccountsSelector";
import { RootState } from "../../../store";

export const gcpAccountProviderDetailsByAccountIdSelector = (
  state: RootState,
  accountId: string,
) => {
  const accounts = gcpAllAccountsSelector(state);

  const selectedAccount = accounts?.find((account) => account.id === accountId);

  if (
    !selectedAccount ||
    !(
      selectedAccount?.provider_details &&
      "gcp" in selectedAccount.provider_details
    ) ||
    !selectedAccount.provider_details.gcp
  ) {
    return null;
  }

  return selectedAccount?.provider_details?.["gcp"];
};
