import moment from "moment";
import { BudgetPeriod } from "../../../../../../../../../services/cloudchipr.api";

export const budgetPeriodUnits: Record<string, string> = {
  daily: "day",
  monthly: "month",
  quarterly: "quarter",
  annually: "year",
};

export const budgetPeriods = Object.keys(budgetPeriodUnits) as BudgetPeriod[];

export const budgetCreateYears = Array(10)
  .fill(moment().year())
  .map((year, index) => year + index);

export const monthNames = [
  "january",
  "february",
  "march",
  "april",
  "may",
  "june",
  "july",
  "august",
  "september",
  "october",
  "november",
  "december",
];

export const budgetQuarters = [
  {
    firstMonthName: "january",
    lasMonthName: "march",
    quarterName: "Q1",
    firstMonthIndex: 0,
    lastMonthIndex: 2,
  },
  {
    firstMonthName: "april",
    lasMonthName: "june",
    quarterName: "Q2",
    firstMonthIndex: 3,
    lastMonthIndex: 5,
  },
  {
    firstMonthName: "july",
    lasMonthName: "september",
    quarterName: "Q3",
    firstMonthIndex: 6,
    lastMonthIndex: 8,
  },
  {
    firstMonthName: "october",
    lasMonthName: "december",
    quarterName: "Q4",
    firstMonthIndex: 9,
    lastMonthIndex: 11,
  },
];
