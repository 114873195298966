import { ResourceType } from "../../../services/cloudchipr.api";
import { resourceTypeGroups } from "../../constants/resources/resources";

export const getFirstParentInHierarchyResourceType = (
  resourceType: ResourceType,
): ResourceType | undefined => {
  const parents = Object.keys(resourceTypeGroups) as ResourceType[];

  return parents.reduce(
    (result, item) => {
      if (resourceTypeGroups?.[item]?.includes(resourceType)) {
        result = item;
      }

      return result;
    },
    undefined as ResourceType | undefined,
  );
};
