import { createAsyncThunk } from "@reduxjs/toolkit";
import { deleteCostAnomalyAlertByIdThunk } from "../cost-anomaly-alerts/deleteCostAnomalyAlertByIdThunk";
import { deleteUtilizationAlertByIdThunk } from "../utilization-alerts/deleteUtilizationAlertByIdThunk";
import { AlertTabType } from "../../utils/types/types";

export const deleteAlertByIdAndTypeThunk = createAsyncThunk(
  "alerts/deleteAlertByIdAndType",
  async ({ id, type }: { id: string; type: AlertTabType }, { dispatch }) => {
    if (type === "costAnomaly") {
      await dispatch(deleteCostAnomalyAlertByIdThunk(id)).unwrap();
    } else {
      await dispatch(deleteUtilizationAlertByIdThunk(id)).unwrap();
    }
  },
);
