import { FC } from "react";
import { Divider, Stack } from "@mui/material";
import { IntegrationUtilizationAlertCommitmentsExpirationEmailHeader } from "../../message/commitments-expiration/IntegrationUtilizationAlertCommitmentsExpirationEmailHeader";
import { IntegrationUtilizationAlertCommitmentsExpirationSlackHeader } from "../../message/commitments-expiration/IntegrationUtilizationAlertCommitmentsExpirationSlackHeader";
import { IntegrationUtilizationAlertCommitmentsExpirationDetails } from "../../message/commitments-expiration/IntegrationUtilizationAlertCommitmentsExpirationDetails";
import { CreateIntegrationMessageEditor } from "../../../../../../../../../common/integration-dialogs/components/common/CreateIntegrationMessageEditor";

interface IntegrationUtilizationAlertCommitmentsExpirationDialogContent {
  customMessage: string;
  onCustomMessageChange(message: string): void;
  type: "email" | "slack";
}

export const IntegrationUtilizationAlertCommitmentsExpirationDialogContent: FC<
  IntegrationUtilizationAlertCommitmentsExpirationDialogContent
> = ({ type, onCustomMessageChange, customMessage }) => {
  return (
    <Stack spacing={1}>
      {type === "email" && (
        <IntegrationUtilizationAlertCommitmentsExpirationEmailHeader divider />
      )}

      {type === "slack" && (
        <IntegrationUtilizationAlertCommitmentsExpirationSlackHeader />
      )}

      <IntegrationUtilizationAlertCommitmentsExpirationDetails />

      <Divider />

      <CreateIntegrationMessageEditor
        value={customMessage ?? ""}
        onChange={onCustomMessageChange}
        placeholder="Write a custom message..."
      />
    </Stack>
  );
};
