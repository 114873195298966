import { Stack, Typography } from "@mui/material";
import { getSavingsPlanCommonColumns } from "./getSavingsPlanCommonColumns";
import { SavingsPlanCoverageDataWithId } from "../../../../../../../common/utils/types";
import { ColumnSetupType } from "../../../../../../../../../../storybook/data-grid/utils/types/types";
import { netSavingsColumn } from "../coverage/common/netSavingsColumn";

export const savingsPlanGroupedByAccountColumns: ColumnSetupType<SavingsPlanCoverageDataWithId>[] =
  [
    {
      accessorKey: "account",
      header: "Account",
      cell: ({ row }) => {
        return (
          <Stack>
            <Typography variant="body2">
              {row.original.account?.name}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {row.original.account?.provider_account_id}
            </Typography>
          </Stack>
        );
      },
    },
    netSavingsColumn,
    ...getSavingsPlanCommonColumns("account"),
  ];
