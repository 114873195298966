import { FC } from "react";
import { Skeleton, Stack } from "@mui/material";

export const CommitmentsOverviewContentLoading: FC = () => {
  return (
    <Stack spacing={2} p={2}>
      <Stack direction="row" spacing={4}>
        <Skeleton variant="text" width={120} height={20} />
        <Skeleton variant="text" height={20} width="100%" />
        <Skeleton variant="text" width={120} height={20} />
      </Stack>
      <Stack direction="row" spacing={4}>
        <Skeleton variant="text" width={120} height={20} />
        <Skeleton variant="text" width="100%" height={20} />
        <Skeleton variant="text" width={120} height={20} />
      </Stack>
      <Stack direction="row" spacing={4}>
        <Skeleton variant="text" width={120} height={20} />
        <Skeleton variant="text" width="100%" height={20} />
        <Skeleton variant="text" width={120} height={20} />
      </Stack>
      <Stack direction="row" spacing={4}>
        <Skeleton variant="text" width={120} height={20} />
        <Skeleton variant="text" width="100%" height={20} />
        <Skeleton variant="text" width={120} height={20} />
      </Stack>
      <Stack direction="row" spacing={4}>
        <Skeleton variant="text" width={120} height={20} />
        <Skeleton variant="text" width="100%" height={20} />
        <Skeleton variant="text" width={120} height={20} />
      </Stack>
    </Stack>
  );
};
