import { changesExistOnTaskEditSelector } from "./changesExistOnTaskEditSelector";
import { RootState } from "../../../store";
import { taskManagementTaskSavingPayloadSelector } from "../form/payload/taskManagementTaskSavingPayloadSelector";

export const taskEditSaveButtonDisabledSelector = (state: RootState) => {
  const changesExist = changesExistOnTaskEditSelector(state);
  const payload = taskManagementTaskSavingPayloadSelector(state);

  return !changesExist || !payload?.name?.trim();
};
