import { previewSelectedResourceIdsSelector } from "./previewSelectedResourceIdsSelector";
import { previewSelectedResourcesSelector } from "./previewSelectedResourcesSelector";
import { ResourceType } from "../../../../../../services/cloudchipr.api";
import { RootState } from "../../../../../store";
import { protectorAllResourcesSelector } from "../../../../../schedules/selectors/resources-for-protector/protectorAllResourcesSelector";
import { ScheduleProtectResources } from "../../../../../schedules/thunks/resourceProtectThunk";

export const previewSelectedResourcesToProtectSelector = (
  state: RootState,
  resourceTypes: ResourceType[],
  accountIds: string[],
  scheduleId: string,
  batchId?: string,
): ScheduleProtectResources => {
  const schedulePreviewResources = previewSelectedResourcesSelector(
    state,
    resourceTypes,
    accountIds,
    scheduleId,
  );

  const protectorResources = protectorAllResourcesSelector(
    state,
    scheduleId,
    batchId,
  );

  const resources = batchId ? protectorResources : schedulePreviewResources;

  const ids = previewSelectedResourceIdsSelector(state);
  const selectedResourceIds = resourceTypes
    .map((rt) => Object.keys(ids[rt] ?? {}))
    .flat();

  return (
    resources?.reduce((acc, resource) => {
      if (!resource) {
        return acc;
      }

      const resourceId = resource?.id;
      const isProtected = resource?.is_protected;
      const resourceType = resource?.resource_type;

      if (!selectedResourceIds?.includes(resourceId)) {
        return acc;
      }

      const accountId = resource.account.id;

      if (!accountId) {
        return acc;
      }

      if (!acc[accountId]) {
        acc[accountId] = [];
      }

      acc[accountId].push({
        id: resourceId,
        type: resourceType,
        is_protected: !isProtected,
      });

      return acc;
    }, {} as ScheduleProtectResources) ?? ({} as ScheduleProtectResources)
  );
};
