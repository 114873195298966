import { FC, Fragment, ReactNode } from "react";
import { CardContent, Divider, Stack, Typography } from "@mui/material";

interface RecommendationsTitleProps {
  heading: string;
  description: ReactNode;
}

export const RecommendationsTitle: FC<RecommendationsTitleProps> = ({
  description,
  heading,
}) => {
  return (
    <Fragment>
      <CardContent sx={{ p: 3 }}>
        <Stack direction="row" alignItems="center">
          <Typography variant="h5" fontWeight="bold">
            {heading}
          </Typography>
        </Stack>
        <Typography variant="subtitle1" color="text.secondary" gutterBottom>
          {description}
        </Typography>
      </CardContent>

      <Divider />
    </Fragment>
  );
};
