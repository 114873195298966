import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface InitialState {
  postCacheKey: string;
}

const initialState: InitialState = {
  postCacheKey: "",
};
export const cloudChatSlice = createSlice({
  name: "cloudChat",
  initialState,
  reducers: {
    setCacheKey: (state, action: PayloadAction<string>) => {
      state.postCacheKey = action.payload;
    },
  },
});
export const { setCacheKey } = cloudChatSlice.actions;

export default cloudChatSlice.reducer;
