import { ChangeEvent, FC } from "react";
import {
  FormControl,
  FormLabel,
  Stack,
  TextField,
  Tooltip,
} from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { BillingExportInitialValues } from "../../../ResourceExplorerBillingExportDialog";

interface ResourceExplorerBillingExportDialogDetailsTextFieldsProps {
  values: BillingExportInitialValues;
  onChange: (e: ChangeEvent<any>) => void;
}

export const ResourceExplorerBillingExportDialogDetailsTextFields: FC<
  ResourceExplorerBillingExportDialogDetailsTextFieldsProps
> = ({ values, onChange }) => {
  return (
    <Stack gap={2}>
      <FormControl fullWidth margin="normal">
        <FormLabel>
          <Stack direction="row" alignItems="center" gap={1}>
            Project ID
            <Tooltip
              arrow
              placement="top"
              title="GCP stores the Billing Exports in BigQuery service inside one of the projects."
            >
              <InfoOutlinedIcon fontSize="small" color="action" />
            </Tooltip>
          </Stack>
        </FormLabel>
        <TextField
          value={values.projectId}
          name="projectId"
          size="small"
          fullWidth
          onChange={onChange}
        />
      </FormControl>
      <FormLabel>
        Dataset Name
        <TextField
          value={values.dataSetName}
          name="dataSetName"
          size="small"
          fullWidth
          onChange={onChange}
        />
      </FormLabel>
      <FormLabel>
        Table
        <TextField
          value={values.table}
          name="table"
          size="small"
          fullWidth
          onChange={onChange}
        />
      </FormLabel>
    </Stack>
  );
};
