import { createAsyncThunk } from "@reduxjs/toolkit";
import { getSavingPlansCoverageRecommendationsThunk } from "./getSavingPlansCoverageRecommendationsThunk";
import { setSavingPlansCoverageRecommendationsInitialFiltersThunk } from "./setSavingPlansCoverageRecommendationsInitialFiltersThunk";

export const initiateSavingPlansCoverageDrawerThunk = createAsyncThunk(
  "commitments/initiateSavingPlansCoverageDrawer",
  async (_: void, { dispatch, getState }) => {
    await dispatch(getSavingPlansCoverageRecommendationsThunk());
    dispatch(setSavingPlansCoverageRecommendationsInitialFiltersThunk());
  },
);
