import React, { FC, useCallback } from "react";
import { Alert, Button } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../../../store/hooks";
import {
  goToPrevStep,
  resetAccountAttempt,
} from "../../../../../store/account-setup/accountSetupSlice";
import { accountAttemptSelector } from "../../../../../store/account-setup/selectors/accountAttemptSelector";

export const FailedAccountAttemptReason: FC = () => {
  const dispatch = useAppDispatch();
  const accountAttempt = useAppSelector(accountAttemptSelector);

  const handleResetClick = useCallback(() => {
    dispatch(resetAccountAttempt());
    dispatch(goToPrevStep());
  }, [dispatch]);

  if (accountAttempt?.status !== "failed") {
    return null;
  }
  return (
    <Alert
      variant="outlined"
      severity="error"
      sx={{ mt: 2 }}
      action={
        <Button color="warning" onClick={handleResetClick}>
          RESET
        </Button>
      }
    >
      {accountAttempt.status_details?.failed?.reason}
    </Alert>
  );
};
