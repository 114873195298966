import { FC, Fragment, useCallback, ChangeEvent } from "react";
import { Box, FormControlLabel, Radio, RadioGroup } from "@mui/material";
import { AutomationDateTimePicker } from "./AutomationDateTimePicker";
import { NullableDate } from "../../../../../../../../services/cloudchipr.api";

interface DateSelectionContentProps {
  disabled?: boolean;
  error?: string | boolean;
  selectedDate: NullableDate;
  onChange(date: NullableDate): void;
  labels: {
    none: string;
    specific: string;
  };
  dateDefaultValue: string;
}

export const DateSelectionContent: FC<DateSelectionContentProps> = ({
  selectedDate,
  onChange,
  error,
  disabled,
  labels,
  dateDefaultValue,
}) => {
  const dateToggleHandler = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      onChange(e.target.value === "none" ? null : dateDefaultValue);
    },
    [onChange, dateDefaultValue],
  );

  return (
    <Fragment>
      <RadioGroup
        row
        onChange={dateToggleHandler}
        value={selectedDate ? "specific" : "none"}
      >
        <FormControlLabel
          value="none"
          label={labels.none}
          disabled={disabled}
          control={<Radio size="small" sx={{ p: 0.5, mr: 0.5 }} />}
        />
        <FormControlLabel
          value="specific"
          sx={{ ml: 2 }}
          label={labels.specific}
          disabled={disabled}
          control={<Radio size="small" sx={{ p: 0.5, mr: 0.5 }} />}
        />
      </RadioGroup>

      {selectedDate && (
        <Box mb={-1} ml={2}>
          <AutomationDateTimePicker
            error={error}
            date={selectedDate}
            disabled={disabled}
            onAccept={onChange}
            minDate={dateDefaultValue}
          />
        </Box>
      )}
    </Fragment>
  );
};
