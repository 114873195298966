import { FC } from "react";
import { CustomCellRendererProps } from "@ag-grid-community/react";
import { SavingsOpportunity } from "../../../../../services/cloudchipr.api";
import { TagCell } from "../../../../common/data-grid-cells/tag-cell/TagCell";

export const SavingsOpportunityTagsCellRenderer: FC<
  CustomCellRendererProps<SavingsOpportunity>
> = (cell) => {
  const data = cell.data as SavingsOpportunity;

  if (!data?.tags) {
    return null;
  }

  return <TagCell tags={data.tags} />;
};
