import { FC } from "react";
import { Stack, Typography } from "@mui/material";
import { TypographyWithTooltip } from "../../../../../../../common/TypographyWithTooltip";

interface UserRequestInfoCellProps {
  name: string;
  email?: string;
}
export const UserRequestInfoCell: FC<UserRequestInfoCellProps> = ({
  name,
  email,
}) => {
  return (
    <Stack direction="row" spacing={0.5} overflow="hidden">
      <TypographyWithTooltip
        title={email}
        color="primary.main"
        variant="body1"
      />
      {name !== email && (
        <TypographyWithTooltip
          title={`(${name})`}
          variant="body1"
          color="text.secondary"
        />
      )}
      <Typography variant="body1" whiteSpace="nowrap" color="text.secondary">
        has requested to join.
      </Typography>
    </Stack>
  );
};
