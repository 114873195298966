import { FC } from "react";
import { Stack, Tooltip } from "@mui/material";
import Typography from "@mui/material/Typography";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { TrendChip } from "../../../../../../common/chips/TrendChip";
import { money } from "../../../../../../../utils/numeral/money";

interface CommitmentUtilizationWidgetContentCostsCardProps {
  title: string;
  tooltipTitle: string;
  cost?: number;
  percent?: number;
  trend?: number | null;
  trendTooltipTitle?: string;
}

export const CommitmentUtilizationWidgetContentCostsCard: FC<
  CommitmentUtilizationWidgetContentCostsCardProps
> = ({ title, tooltipTitle, cost, percent, trend, trendTooltipTitle }) => {
  return (
    <Stack>
      <Stack direction="row" spacing={0.5} alignItems="center">
        <Typography variant="body2" color="text.secondary" whiteSpace="nowrap">
          {title}
        </Typography>

        <Tooltip arrow placement="top" title={tooltipTitle}>
          <InfoOutlinedIcon color="action" sx={{ fontSize: 16 }} />
        </Tooltip>
      </Stack>

      <Stack
        spacing={1}
        direction="row"
        justifyContent="end"
        alignItems="center"
      >
        <Typography variant="h6" fontWeight="medium" mr={1}>
          {money(cost)} {percent ? `(${percent}%)` : ""}
        </Typography>

        <TrendChip value={trend} tooltipTitle={trendTooltipTitle} />
      </Stack>
    </Stack>
  );
};
