import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import moment from "moment";
import {
  Budget,
  PostUsersMeOrganisationsCurrentViewsByViewIdBudgetsApiArg,
} from "../../services/cloudchipr.api";
import { formatDate } from "../../utils/helpers/date-time/datetime-format";

export type CurrentBudgetType =
  PostUsersMeOrganisationsCurrentViewsByViewIdBudgetsApiArg & {
    id?: string;
  };

interface BudgetsInterface {
  currentBudget: CurrentBudgetType | null;
  currentBudgetDataLoading: boolean;
}

const initialState: BudgetsInterface = {
  currentBudget: null,
  currentBudgetDataLoading: false,
};

export const budgetsSlice = createSlice({
  name: "budgets",
  initialState: initialState,
  reducers: {
    initiateBudgetForEdit: (state, action: PayloadAction<Budget>) => {
      const {
        amount,
        thresholds,
        notifications,
        start_date,
        period,
        name,
        view,
        id,
        email_to,
      } = action.payload;

      state.currentBudgetDataLoading = false;
      state.currentBudget = {
        id,
        "view-id": view.id,
        body: {
          name,
          amount,
          period,
          start_date,
          thresholds,
          notifications,
          email_to: email_to ?? null,
        },
      };
    },
    initiateBudgetForCreate: (state) => {
      state.currentBudget = {
        "view-id": "",
        body: {
          name: "",
          start_date: formatDate(moment().startOf("month")),
          notifications: null,
          email_to: null,
          amount: 0,
          period: "monthly",
          thresholds: [
            {
              percent: 0,
              trigger: "actual",
            },
          ],
        },
      };
    },
    resetCurrentBudget: (state) => {
      state.currentBudget = null;
    },
    setCurrentBudgetLoading: (state, action: PayloadAction<boolean>) => {
      state.currentBudgetDataLoading = action.payload;
    },
    setCurrentBudgetData: (
      state,
      action: PayloadAction<
        {
          viewId?: string;
        } & Partial<
          PostUsersMeOrganisationsCurrentViewsByViewIdBudgetsApiArg["body"]
        >
      >,
    ) => {
      const {
        viewId,
        period,
        amount,
        thresholds,
        notifications,
        start_date,
        name,
        email_to,
      } = action.payload;

      if (!state.currentBudget) {
        return;
      }

      if (viewId !== undefined) {
        state.currentBudget["view-id"] = viewId;
      }
      if (period !== undefined) {
        state.currentBudget.body.period = period;
      }
      if (amount !== undefined) {
        state.currentBudget.body.amount = amount;
      }
      if (thresholds !== undefined) {
        state.currentBudget.body.thresholds = thresholds;
      }
      if (notifications !== undefined) {
        state.currentBudget.body.notifications = notifications;
      }
      if (start_date !== undefined) {
        state.currentBudget.body.start_date = start_date;
      }
      if (name !== undefined) {
        state.currentBudget.body.name = name;
      }
      if (email_to !== undefined) {
        state.currentBudget.body.email_to = email_to;
      }
    },
  },
});

export const {
  initiateBudgetForEdit,
  initiateBudgetForCreate,
  setCurrentBudgetData,
  resetCurrentBudget,
  setCurrentBudgetLoading,
} = budgetsSlice.actions;

export default budgetsSlice.reducer;
