import { FC, Fragment } from "react";
import { PermissionStep } from "./steps/PermissionStep";
import { AccessToAccountStep } from "./steps/AccessToAccountStep";
import { DataCollectionStep } from "./steps/DataCollectionStep";
import { AzureServicePrincipalStep } from "./steps/AzureServicePrincipalStep";
import { AzureAssignRolesStep } from "./steps/AzureAssignRolesStep";
import { AccountSetupStepper } from "./steps/AccountSetupStepper";
import { useAppSelector } from "../../../../../store/hooks";
import { currentStepSelector } from "../../../../../store/account-setup/selectors/currentStepSelector";
import { providerSelector } from "../../../../../store/account-setup/selectors/providerSelector";

export const AccountSetupDialogContent: FC = () => {
  const provider = useAppSelector(providerSelector);
  const currentStep = useAppSelector(currentStepSelector);

  if (provider === "azure") {
    return (
      <Fragment>
        <AccountSetupStepper currentStep={currentStep} provider={provider} />
        {currentStep === 0 && <PermissionStep provider={provider} />}
        {currentStep === 1 && <AzureServicePrincipalStep />}
        {currentStep === 2 && <AzureAssignRolesStep />}
        {currentStep === 3 && <DataCollectionStep />}
      </Fragment>
    );
  }

  return (
    <Fragment>
      <AccountSetupStepper currentStep={currentStep} provider={provider} />
      {currentStep === 0 && <PermissionStep provider={provider} />}
      {currentStep === 1 && <AccessToAccountStep />}
      {currentStep === 2 && <DataCollectionStep />}
    </Fragment>
  );
};
