import { RootState } from "../../../../store";
import { getUsersMeOrganisationsCurrentDismissedResourcesSelector } from "../../dismiss/resources/getUsersMeOrganisationsCurrentDismissedResourcesSelector";

export const rightsizingRecommendationsDismissedResourcesLoadingSelector = (
  state: RootState,
): boolean => {
  return !!getUsersMeOrganisationsCurrentDismissedResourcesSelector({
    group: "compute",
    recommendationType: "right_sizing",
  })(state)?.isLoading;
};
