import { ChangeEvent, FC, useCallback } from "react";
import { TextField } from "@mui/material";
import { useAppDispatch } from "../../../../../../../store/hooks";
import { setCategoryName } from "../../../../../../../store/dimensions/dimensionsSlice";

interface CategoryNameChangeableProps {
  id: string;
  name: string;
}

export const CategoryNameField: FC<CategoryNameChangeableProps> = ({
  name,
  id,
}) => {
  const dispatch = useAppDispatch();

  const nameChangeHandler = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      dispatch(
        setCategoryName({
          id,
          name: event.target.value,
        }),
      );
    },
    [id, dispatch],
  );

  return (
    <TextField
      autoFocus
      fullWidth
      size="small"
      value={name}
      type="text"
      sx={{ mt: 0.5 }}
      label="Category Name"
      onChange={nameChangeHandler}
    />
  );
};
