import { ChangeEvent, FC, Fragment } from "react";
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from "@mui/material";
import { WebhookTestMessage } from "./test-message/WebhookTestMessage";

interface WebhookFormProps {
  url?: string;
  contentType?: string;
  urlError?: string | boolean;
  contentTypeError?: string | boolean;
  handleChange(e: ChangeEvent<any> | SelectChangeEvent<string>): void;
}

const ContentTypes = ["application/json", "application/x-www-form-"];

export const WebhookForm: FC<WebhookFormProps> = ({
  url,
  contentType,
  urlError,
  contentTypeError,
  handleChange,
}) => {
  return (
    <Fragment>
      <TextField
        fullWidth
        value={url}
        size="small"
        label="URL *"
        name="url"
        variant="outlined"
        error={!!urlError}
        helperText={urlError}
        onChange={handleChange}
      />

      <FormControl size="small">
        <InputLabel>Content Type*</InputLabel>
        <Select
          fullWidth
          size="small"
          value={contentType}
          label="Content Type*"
          onChange={handleChange}
          name="content_type"
          error={!!contentTypeError}
        >
          {ContentTypes.map((option) => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </Select>
        {!!contentTypeError && (
          <FormHelperText error>{contentTypeError}</FormHelperText>
        )}
      </FormControl>

      <WebhookTestMessage url={url} content_type={contentType} />
    </Fragment>
  );
};
