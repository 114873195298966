import { CellContext } from "@tanstack/react-table";
import { Stack, Tooltip, Typography } from "@mui/material";
import NotificationsActiveOutlinedIcon from "@mui/icons-material/NotificationsActiveOutlined";
import { green } from "@mui/material/colors";
import { ResourceDataType } from "../../../../../../../../../store/live-usage-mgmt/utils/types/types";
import { UpcomingAction } from "../../../../../../../../../services/cloudchipr.api";
import {
  dateFromUtcByTimeZone,
  formatDate,
} from "../../../../../../../../../utils/helpers/date-time/datetime-format";

export const getPreviewDataGridActionDateColumn = () => ({
  accessorKey: "upcoming_actions",
  header: "Action Date",
  size: 280,
  meta: { sticky: "right", hideFromSettings: true },
  cell: (cell: CellContext<ResourceDataType, any>) => {
    const isProtected = cell.row.original?.resource?.is_protected;

    const upcomingActions = cell.getValue() as UpcomingAction[];
    const upcomingActionDate = upcomingActions?.at(0)?.action_date;

    const date =
      upcomingActionDate && !isProtected
        ? formatDate(dateFromUtcByTimeZone(upcomingActionDate) ?? "", {
            type: "dateTime",
          })
        : "";

    const utcDate = formatDate(upcomingActionDate ?? "", {
      type: "dateTime",
    });

    return (
      <Tooltip
        title={
          date
            ? `Users got notified about this resource. Cloudchipr will take action on this resource on ${utcDate} in UTC time.`
            : ""
        }
      >
        <Typography variant="body2">
          {date || "-"}

          {upcomingActionDate && !isProtected && (
            <Stack
              direction="row"
              p={0.5}
              width="fit-content"
              borderRadius={1}
              bgcolor={green[50]}
              alignItems="center"
              mb={0.5}
            >
              <NotificationsActiveOutlinedIcon
                sx={{ color: "success.light" }}
                fontSize="small"
              />
              <Typography
                color="success.light"
                fontWeight="bold"
                ml={0.5}
                fontSize="small"
              >
                Notified
              </Typography>
            </Stack>
          )}
        </Typography>
      </Tooltip>
    );
  },
});
