import { FC, useCallback } from "react";
import { Box, Stack, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { ProviderIcon } from "../../../../../../../common/ProviderIcon";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../store/hooks";
import { goAwsConsoleThunk } from "../../../../../../../../store/common/thunks/billing-status/goAwsConsoleThunk";
import { cfnChangeSetsLoadingSelector } from "../../../../../../../../store/common/thunks/billing-status/cfnChangeSetsLoadingSelector";
import { useAppAbility } from "../../../../../../../../services/permissions";

interface ResourceExplorerAwsErrorBillingStatusPanelItemProps {
  error: string;
  accountId?: string;
  name?: string | null;
  providerAccountId?: string;
}
export const ResourceExplorerAwsErrorBillingStatusPanelItem: FC<
  ResourceExplorerAwsErrorBillingStatusPanelItemProps
> = ({ error, accountId, name, providerAccountId }) => {
  const dispatch = useAppDispatch();
  const awsConsoleLoading = useAppSelector(cfnChangeSetsLoadingSelector);
  const { cannot } = useAppAbility();
  const canNotGoToAWSConsole = cannot("edit", "resource-explorer");

  const goAwsConsoleHandler = useCallback(async () => {
    if (!accountId) {
      return;
    }
    dispatch(goAwsConsoleThunk(accountId));
  }, [accountId, dispatch]);

  return (
    <Stack bgcolor="grey.100" borderRadius={4} gap={1} p={2}>
      <Stack direction="row" gap={1}>
        <ProviderIcon provider="aws" />
        <div>
          <Typography variant="subtitle1" fontWeight="medium">
            Billing Data Error
          </Typography>
          {!!(name || providerAccountId) && (
            <Typography variant="body2">
              Account: {""}
              <Typography
                component="span"
                variant="inherit"
                fontWeight="medium"
              >
                {name ?? providerAccountId}
              </Typography>
            </Typography>
          )}
        </div>
      </Stack>
      {error && (
        <Stack bgcolor="grey.300" p={1} borderRadius={2}>
          <Typography fontWeight="bold" variant="tiny" color="text.disabled">
            Error
          </Typography>
          <Typography variant="caption">{error}</Typography>
        </Stack>
      )}
      <Typography variant="body2">
        To fix the error please execute the CloudFormation template from AWS
        console.
      </Typography>
      <Box display="flex" justifyContent="flex-end">
        <LoadingButton
          variant="outlined"
          onClick={goAwsConsoleHandler}
          disabled={canNotGoToAWSConsole || awsConsoleLoading}
          loading={awsConsoleLoading}
        >
          Go to AWS Console
        </LoadingButton>
      </Box>
    </Stack>
  );
};
