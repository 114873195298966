import { createDraftSafeSelector } from "@reduxjs/toolkit";
import { dashboardHierarchyMixedVisibilitiesDataSelector } from "./dashboardHierarchyMixedVisibilitiesDataSelector";
import { getFlattenMixedHierarchyData } from "../../../common/utils/helpers/getFlattenMixedHierarchyData";
import { getFlattenMixedHierarchyMap } from "../../../common/utils/helpers/getFlattenMixedHierarchyMap";

export const dashboardFlattenHierarchyMixedVisibilitiesMapSelector =
  createDraftSafeSelector(
    [dashboardHierarchyMixedVisibilitiesDataSelector],
    (data) => {
      if (!data) {
        return null;
      }

      const flattenData = getFlattenMixedHierarchyData(data);

      return getFlattenMixedHierarchyMap(flattenData);
    },
  );
