import { cloudChiprApi } from "../../../../services/cloudchipr.api";

export const getWeeklyRecommendationComputeSelector =
  cloudChiprApi.endpoints.getUsersMeOrganisationsCurrentResourcesWeeklyOffHoursRecommendations.select(
    { group: "compute" },
  );

export const getWeeklyRecommendationComputeDismissedSelector =
  cloudChiprApi.endpoints.getUsersMeOrganisationsCurrentResourcesWeeklyOffHoursRecommendations.select(
    { group: "compute", isDismissed: true },
  );

export const getWeeklyRecommendationDataBaseSelector =
  cloudChiprApi.endpoints.getUsersMeOrganisationsCurrentResourcesWeeklyOffHoursRecommendations.select(
    { group: "database" },
  );

export const getWeeklyRecommendationDataBaseDismissedSelector =
  cloudChiprApi.endpoints.getUsersMeOrganisationsCurrentResourcesWeeklyOffHoursRecommendations.select(
    { group: "database", isDismissed: true },
  );
