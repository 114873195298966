import { FC, Fragment, useCallback } from "react";
import { NotificationSnoozing } from "./NotificationSnoozing";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../store/hooks";
import { workflowActionSelector } from "../../../../../../../../store/automations/selectros/workflow/workflowActionSelector";
import { workflowSnoozedSelector } from "../../../../../../../../store/automations/selectros/workflow/workflowSnoozedSelector";
import { setWorkflowSpecificData } from "../../../../../../../../store/automations/automationsSlice";
import { AutomationsThreshold } from "../../../../../common/components/form/AutomationsThreshold";

export const WorkflowNotificationOptions: FC = () => {
  const dispatch = useAppDispatch();
  const action = useAppSelector(workflowActionSelector);
  const snoozed = useAppSelector(workflowSnoozedSelector);

  const snoozedChangeHandler = useCallback(
    (snoozed: boolean) => {
      dispatch(setWorkflowSpecificData({ snoozed }));
    },
    [dispatch],
  );

  if (action !== "notify") {
    return null;
  }

  return (
    <Fragment>
      <AutomationsThreshold />

      <NotificationSnoozing
        snoozed={!!snoozed}
        onChange={snoozedChangeHandler}
      />
    </Fragment>
  );
};
