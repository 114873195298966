import { FC, useCallback } from "react";
import { Stack, Typography } from "@mui/material";
import { Emails } from "../../../../../../../../../services/cloudchipr.api";
import { EmailsSelectionErrors } from "../../../../../../../../common/integration-dialogs/components/email/components/email-selection/EmailsSelection";
import { NotificationsSelection } from "../../../../../../../common/notification-selection/NotificationsSelection";
import { IntegrationNotification } from "../../../../../../../common/notification-selection/utils/types/types";
import { useAppDispatch } from "../../../../../../../../../store/hooks";
import { setCurrentBudgetData } from "../../../../../../../../../store/budgets/budgetsSlice";

interface BudgetsNotificationsProps {
  integrations: IntegrationNotification[] | null;
  emails: Emails | null;
  emailsErrors: EmailsSelectionErrors;
  setFieldValue(key: string, value: any): void;
}

export const BudgetsNotifications: FC<BudgetsNotificationsProps> = ({
  integrations,
  setFieldValue,
  emails,
  emailsErrors,
}) => {
  const dispatch = useAppDispatch();

  const integrationChangeHandler = useCallback(
    (integrations: IntegrationNotification[] | null) => {
      setFieldValue(
        "notifications",
        integrations?.length ? integrations : null,
      );
      dispatch(
        setCurrentBudgetData({
          notifications: integrations?.length ? integrations : null,
        }),
      );
    },
    [setFieldValue, dispatch],
  );

  const emailsChangeHandler = useCallback(
    (emails: Emails | null) => {
      setFieldValue("email_to", emails);
      dispatch(setCurrentBudgetData({ email_to: emails }));
    },
    [setFieldValue, dispatch],
  );

  return (
    <Stack spacing={1}>
      <Typography variant="body1" fontWeight="medium">
        Notification preferences
      </Typography>

      <NotificationsSelection
        emailsErrors={emailsErrors}
        selectedEmails={emails}
        selectedIntegrations={integrations}
        disabledIntegrationTypes={["jira", "webhook"]}
        emailsChangeHandler={emailsChangeHandler}
        setSelectedIntegrations={integrationChangeHandler}
      />
    </Stack>
  );
};
