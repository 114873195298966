import { FC } from "react";
import { IntegrationType } from "../../../../pages/integrations/components/integrations-create/components/common/integration-type/IntegrationTypeCard";
import EmailGrey from "../../../../../assets/images/logos/integrations/email_grey.svg";
import SlackGrey from "../../../../../assets/images/logos/integrations/slack_grey.svg";
import WebhookGrey from "../../../../../assets/images/logos/integrations/webhook_grey.svg";
import JiraGrey from "../../../../../assets/images/logos/integrations/jira_grey.svg";
import EmailLogo from "../../../../../assets/images/logos/integrations/email.svg";
import SlackLogo from "../../../../../assets/images/logos/integrations/slack.svg";
import WebhookLogo from "../../../../../assets/images/logos/integrations/webhook.svg";
import JiraLogo from "../../../../../assets/images/logos/integrations/jira.svg";

const greyLogos = new Map([
  ["email", EmailGrey],
  ["slack", SlackGrey],
  ["webhook", WebhookGrey],
  ["jira", JiraGrey],
]);

const coloredLogos = new Map([
  ["email", EmailLogo],
  ["slack", SlackLogo],
  ["webhook", WebhookLogo],
  ["jira", JiraLogo],
]);

const logos = {
  grey: greyLogos,
  colored: coloredLogos,
};

interface IntegrationLogoProps {
  type: IntegrationType;
  variant?: "colored" | "grey";
  width?: number;
  disabled?: boolean;
}

export const IntegrationLogo: FC<IntegrationLogoProps> = ({
  type,
  width,
  variant = "colored",
  disabled,
}) => {
  const map = logos[variant];

  return (
    <img
      src={map.get(type)}
      style={{ filter: `grayscale(${disabled ? 1 : 0})` }}
      alt={type}
      width={width}
    />
  );
};
