import moment from "moment/moment";
import { RootState } from "../../../store";
import { ResourceType } from "../../../../services/cloudchipr.api";
import { getResourceTypeName } from "../../../../utils/helpers/resources/getResourceTypeName";
import { getProviderTypeByResourceType } from "../../../../utils/helpers/providers/getProviderTypeByResourceType";
import { liveUsageMgmtSelectedAccountsSelector } from "../accounts-data/liveUsageMgmtSelectedAccountsSelector";

export const liveUsageMgmtResourceTypeCsvDataFileNameSelector = (
  state: RootState,
  resourceType: ResourceType,
) => {
  const accounts = liveUsageMgmtSelectedAccountsSelector(state);

  const names = accounts?.reduce((result, item, index) => {
    result +=
      (result.length && !!index ? "," : "") + item.provider_account_name ??
      item.provider_account_id;
    return result;
  }, "");

  const provider = getProviderTypeByResourceType(resourceType);

  const date = moment().format("MM.DD.YY");

  const resourceName =
    getResourceTypeName(resourceType, {
      type: "long",
    })
      ?.toLowerCase()
      ?.replaceAll(" ", "-") ?? "";

  return `${provider}-${names}-${date}-${resourceName}`;
};
