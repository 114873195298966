import { FC } from "react";
import { Stack } from "@mui/material";
import { UserRoleEditableMetaData } from "../../../common/UserRoleEditableMetaData";
import { UserRoleGroupedPermissions } from "../../../common/UserRoleGroupedPermissions";

export const CreateRoleDrawerContent: FC = () => {
  return (
    <Stack px={2} py={1.5} spacing={2}>
      <UserRoleEditableMetaData />
      <UserRoleGroupedPermissions />
    </Stack>
  );
};
