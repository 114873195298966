import { createDraftSafeSelector } from "@reduxjs/toolkit";
import { resourceExplorerMutatedDataForDataGridSelector } from "./resourceExplorerMutatedDataForDataGridSelector";
import { ResourceExplorerGridDataWithId } from "../../../utils/types/resourceExplorerDataGrid";

export const resourceExplorerDataGridDataSelector = createDraftSafeSelector(
  [resourceExplorerMutatedDataForDataGridSelector],
  (data): ResourceExplorerGridDataWithId[] | null => {
    if (!data) {
      return null;
    }

    return data?.gridData;
  },
);
