import { FC } from "react";
import { ReportsTableData } from "../../../../../../../store/reports/utils/types/types";

import { getScheduleTitleAndTooltip } from "../../utils/helpers/getScheduleTitleAndTooltip";
import { TooltipText } from "../../../../../../common/TooltipText";

interface ReportsFrequencyCellProps {
  schedule: ReportsTableData["schedule"];
}

export const ReportsFrequencyCell: FC<ReportsFrequencyCellProps> = ({
  schedule,
}) => {
  if (!schedule) {
    return " ";
  }

  const { tooltip, title } = getScheduleTitleAndTooltip(schedule);

  return (
    <TooltipText variant="body2" tooltipTitle={tooltip}>
      {title}
    </TooltipText>
  );
};
