import { createDraftSafeSelector } from "@reduxjs/toolkit";
import { reservationsCoverageDataSelector } from "./reservationsCoverageDataSelector";

export const reservationsCoverageDataTotalsSelector = createDraftSafeSelector(
  [reservationsCoverageDataSelector],
  (data) => {
    return data?.reduce(
      (acc, item) => {
        acc.netSavings += item.net_savings ?? 0;
        acc.coveredUsage += item.covered_usage ?? 0;
        acc.unCoveredUsage += item.uncovered_usage ?? 0;
        acc.coveredHours += item.covered_hours ?? 0;

        return acc;
      },
      { netSavings: 0, coveredUsage: 0, unCoveredUsage: 0, coveredHours: 0 },
    );
  },
);
