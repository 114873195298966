import { Avatar, Checkbox, ListItemText, MenuItem } from "@mui/material";
import { JiraOption, JiraUser } from "../../../../utils/types/types";
import {
  isOptionJiraOption,
  isOptionJiraString,
  isOptionJiraUser,
} from "../../../../utils/helpers/typeCheckers";

export const selectMenuItem = (
  option: JiraOption | JiraUser | string,
  selected: string[] | null,
  autoCompleteProps?: any,
) => {
  if (isOptionJiraUser(option)) {
    const props = autoCompleteProps ?? { value: option.id };

    return (
      <MenuItem {...props} key={option.id}>
        {option.avatar && (
          <Avatar
            sx={{ width: 24, height: 24, mr: 2 }}
            alt="avatar"
            src={option.avatar}
          />
        )}

        <ListItemText primary={option.name || option.email} />
      </MenuItem>
    );
  }

  if (isOptionJiraOption(option)) {
    const props = autoCompleteProps ?? { value: option.id };

    return (
      <MenuItem {...props} key={option.id}>
        {selected !== null && (
          <Checkbox checked={selected?.includes(option.id) ?? false} />
        )}

        <ListItemText primary={option.name ?? option.value} />
      </MenuItem>
    );
  }

  if (isOptionJiraString(option)) {
    const props = autoCompleteProps ?? { value: option };

    return (
      <MenuItem {...props} key={option}>
        <ListItemText primary={option} />
      </MenuItem>
    );
  }

  return null;
};
