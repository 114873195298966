import { automationSliceSelector } from "../common/automationSliceSelector";
import { RootState } from "../../../store";
import { WorkflowSpecificData } from "../../utils/types/common";

export const workflowSchedulerSelector = (
  state: RootState,
): WorkflowSpecificData["scheduler"] | undefined => {
  const slice = automationSliceSelector(state);

  return slice.workflowSpecific?.scheduler;
};
