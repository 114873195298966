import { FC, Fragment, useCallback } from "react";
import { useDispatch } from "react-redux";
import { LoadingButton } from "@mui/lab";
import { ResourceExplorerAppBarSplitButton } from "./ResourceExplorerAppBarSplitButton";
import { useAppSelector } from "../../../../../../store/hooks";
import { useAppAbility } from "../../../../../../services/permissions";
import { updateResourceExplorerThunk } from "../../../../../../store/resource-explorer/thunks/resource-explorer/updateResourceExplorerThunk";
import { createResourceExplorerLoadingSelector } from "../../../../../../store/resource-explorer/selectors/loading/createResourceExplorerLoadingSelector";
import { updateResourceExplorerLoadingSelector } from "../../../../../../store/resource-explorer/selectors/loading/updateResourceExplorerLoadingSelector";
import { resourceExplorerByIdLoadingSelector } from "../../../../../../store/resource-explorer/selectors/resource-explorer-by-id/resourceExplorerByIdLoadingSelector";
import { ResourceExplorerActionType } from "../../../utils/types/types";
import { isBillingAvailableSelector } from "../../../../../../store/common/selectors/billing-status/isBillingAvailableSelector";
import { resourceExplorerIdSelector } from "../../../../../../store/resource-explorer/selectors/current-resource-explorer/resourceExplorerIdSelector";
import { resourceExplorerIsDefaultSelector } from "../../../../../../store/resource-explorer/selectors/current-resource-explorer/resourceExplorerIsDefaultSelector";
import { resourceExplorerNameSelector } from "../../../../../../store/resource-explorer/selectors/current-resource-explorer/resourceExplorerNameSelector";
import { changesExistOnResourceExplorer } from "../../../../../../store/resource-explorer/selectors/current-resource-explorer/checkers/changesExistOnResourceExplorer";

interface ResourceExplorerAppBarSaveButtonProps {
  openDialog: (key: ResourceExplorerActionType) => void;
  viewId: string;
}

export const ResourceExplorerAppBarSaveButton: FC<
  ResourceExplorerAppBarSaveButtonProps
> = ({ openDialog, viewId }) => {
  const dispatch = useDispatch();

  const { cannot } = useAppAbility();
  const resourceExplorerId = useAppSelector(resourceExplorerIdSelector);
  const resourceExplorerName = useAppSelector(resourceExplorerNameSelector);
  const resourceExplorerIsDefault = useAppSelector(
    resourceExplorerIsDefaultSelector,
  );

  const cannotCreateResourceExplorerView = cannot(
    "create",
    "resource-explorer",
  );
  const cannotUpdateResourceExplorerView = cannot("edit", "resource-explorer");

  const anyFilterChanged = useAppSelector(changesExistOnResourceExplorer);

  const isResourceExplorerAvailable = useAppSelector(
    isBillingAvailableSelector,
  );
  const updateResourceExplorerViewLoading = useAppSelector((state) =>
    updateResourceExplorerLoadingSelector(state, viewId),
  );
  const getResourceExplorerLoading = useAppSelector((state) =>
    resourceExplorerByIdLoadingSelector(state, viewId),
  );
  const createResourceExplorerViewLoading = useAppSelector(
    createResourceExplorerLoadingSelector,
  );

  const loading =
    createResourceExplorerViewLoading || updateResourceExplorerViewLoading;
  const disabled = loading || getResourceExplorerLoading || !anyFilterChanged;

  const createResourceExplorerViewHandler = useCallback(() => {
    openDialog("create");
  }, [openDialog]);

  const resourceExplorerActionHandler = useCallback(
    (type: ResourceExplorerActionType) => {
      if (type === "create") {
        openDialog("create");
        return;
      }

      dispatch(
        updateResourceExplorerThunk({
          viewId: resourceExplorerId,
          name: resourceExplorerName ?? "",
        }),
      );
    },
    [resourceExplorerId, resourceExplorerName, openDialog, dispatch],
  );

  return (
    <Fragment>
      {!resourceExplorerIsDefault ? (
        <ResourceExplorerAppBarSplitButton
          onViewAction={resourceExplorerActionHandler}
          loading={!!loading}
          disabled={{
            create:
              cannotCreateResourceExplorerView ||
              disabled ||
              !isResourceExplorerAvailable,
            update:
              cannotUpdateResourceExplorerView ||
              disabled ||
              !isResourceExplorerAvailable,
          }}
        />
      ) : (
        <LoadingButton
          onClick={createResourceExplorerViewHandler}
          loading={createResourceExplorerViewLoading}
          variant="contained"
          disabled={
            cannotCreateResourceExplorerView ||
            disabled ||
            !isResourceExplorerAvailable
          }
        >
          Save as New
        </LoadingButton>
      )}
    </Fragment>
  );
};
