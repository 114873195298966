import { costAndUsageSumSetupWidgetDataSelector } from "./costAndUsageSumSetupWidgetDataSelector";
import { RootState } from "../../../../../store";
import { CostAndUsageDataGridType } from "../../../../../../components/pages/dashboard/components/adding-widget/widget-create/widget-setups/cost-and-usage/content/data-grid/utils/types";
import { generateCostAndUsageSumDataGridData } from "../../../widgets/cost-and-usage/utils/helpers/generateCostAndUsageSumDataGridData";

export const costAndUsageSumSetupWidgetDataGridDataSelector = (
  state: RootState,
): CostAndUsageDataGridType[] | null => {
  const data = costAndUsageSumSetupWidgetDataSelector(state);

  if (!data) {
    return null;
  }

  return generateCostAndUsageSumDataGridData(data);
};
