import { FC } from "react";
import { Box, TextField } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { FilterTriggerComponentProps } from "../../../../../../../../common/select/dropdown-select/utils/types/filterTriggerComponentProps";
import { SlackConversationIcon } from "../SlackConversationIcon";

export const SlackDefaultConversationTrigger: FC<
  FilterTriggerComponentProps
> = ({ onClick, open, selectedValues, options, label }) => {
  const selected = options.find(
    (option) => option.value === selectedValues.at(0),
  )?.rawValue;

  return (
    <TextField
      size="small"
      label={label}
      onClick={onClick}
      contentEditable={false}
      value={selected?.name ?? ""}
      InputProps={{
        inputProps: {
          style: {
            cursor: "pointer",
            caretColor: "transparent",
          },
        },
        sx: { cursor: "pointer", bgcolor: "white" },
        endAdornment: open ? (
          <ArrowDropUpIcon fontSize="small" sx={{ color: "action" }} />
        ) : (
          <ArrowDropDownIcon fontSize="small" sx={{ color: "action" }} />
        ),
        startAdornment: selected?.type && (
          <Box pr={0.5} pt={0.5}>
            <SlackConversationIcon type={selected.type} />
          </Box>
        ),
      }}
    />
  );
};
