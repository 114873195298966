import { ColumnSetupType } from "../../../../../../../../../../../../../../storybook/data-grid/utils/types/types";
import { AzAks } from "../../../../../../../../../../../../../../services/cloudchipr.api";

export const azAksColumns: ColumnSetupType<AzAks>[] = [
  {
    accessorKey: "name",
    header: "Cluster Name",
    type: "identifierWithRecommendationLink",
    meta: { sticky: "left", hideFromSettings: true },
  },
  {
    accessorKey: "price_per_month",
    header: "Monthly Price",
    type: "money",
    headerTooltip:
      "Monthly prices are calculated based on the on-demand list price of each resource.",
  },
  {
    accessorKey: "account",
    header: "Account",
    type: "account",
    maxSize: 210,
  },
  {
    accessorKey: "all_time_spent",
    header: "Total Spend",
    type: "money",
  },
  {
    accessorKey: "created_at",
    header: "Launch time",
    type: "date",
  },
  {
    accessorKey: "resource_group",
    header: "Resource Group",
    type: "copyWithTooltip",
    size: 180,
  },
  {
    accessorKey: "node_pool_count",
    header: "Node Pools",
  },
  {
    accessorKey: "nodes_count",
    header: "Nodes",
  },
  {
    accessorKey: "pricing_tier",
    header: "Pricing Tier",
  },
  {
    accessorKey: "total_available_cpus",
    header: "Total Available CPUs",
    minSize: 220,
  },
  {
    accessorKey: "total_available_memory",
    header: "Total Available Memory",
    minSize: 220,
  },
  { accessorKey: "region", header: "Region", type: "withCopy" },
  { accessorKey: "tags", header: "Tags", type: "tag", enableSorting: false },
];
