import { FC } from "react";
import { Divider, Stack } from "@mui/material";
import { IntegrationUtilizationAlertCoverageEmailHeader } from "../../message/coverage/IntegrationUtilizationAlertCoverageEmailHeader";
import { IntegrationUtilizationAlertCoverageSlackHeader } from "../../message/coverage/IntegrationUtilizationAlertCoverageSlackHeader";
import { IntegrationUtilizationAlertCoverageDetails } from "../../message/coverage/IntegrationUtilizationAlertCoverageDetails";
import { CreateIntegrationMessageEditor } from "../../../../../../../../../common/integration-dialogs/components/common/CreateIntegrationMessageEditor";

interface IntegrationUtilizationAlertCommitmentsUtilizationDialogContentProps {
  customMessage: string;
  onCustomMessageChange(message: string): void;
  type: "email" | "slack";
}

export const IntegrationUtilizationAlertCoverageDialogContent: FC<
  IntegrationUtilizationAlertCommitmentsUtilizationDialogContentProps
> = ({ type, onCustomMessageChange, customMessage }) => {
  return (
    <Stack spacing={1}>
      {type === "email" && (
        <IntegrationUtilizationAlertCoverageEmailHeader divider />
      )}

      {type === "slack" && <IntegrationUtilizationAlertCoverageSlackHeader />}

      <IntegrationUtilizationAlertCoverageDetails />

      <Divider />

      <CreateIntegrationMessageEditor
        value={customMessage ?? ""}
        onChange={onCustomMessageChange}
        placeholder="Write a custom message..."
      />
    </Stack>
  );
};
