import { SvgIcon, SvgIconProps, useTheme, colors } from "@mui/material";

const GkeSvgIcon = ({ primary }: SvgIconProps & { primary?: boolean }) => {
  const { palette } = useTheme();

  const colorLight = primary ? colors.indigo["300"] : palette.action.disabled;

  return (
    <SvgIcon>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M5.21072 21.6037L0.288844 13.0781C-0.0962812 12.411 -0.0962812 11.5892 0.288844 10.922L5.21072 2.39642C5.59584 1.7293 6.30759 1.3183 7.07803 1.3183H16.9218C17.692 1.3183 18.4038 1.7293 18.7891 2.39642L23.711 10.922C24.0961 11.5892 24.0961 12.411 23.711 13.0781L18.7891 21.6037C18.404 22.2709 17.6922 22.6817 16.9218 22.6817H7.07803C6.30778 22.6817 5.59584 22.2707 5.21072 21.6037Z"
          fill={colorLight}
        />
        <mask
          id="mask0_664_114424"
          maskUnits="userSpaceOnUse"
          x="0"
          y="1"
          width="24"
          height="22"
        >
          <path
            d="M5.21072 21.2854L0.288844 12.7598C-0.0962812 12.0926 -0.0962812 11.2708 0.288844 10.6037L5.21072 2.07806C5.59584 1.41094 6.30759 0.999939 7.07803 0.999939H16.9218C17.692 0.999939 18.4038 1.41094 18.7891 2.07806L23.711 10.6037C24.0961 11.2708 24.0961 12.0926 23.711 12.7598L18.7891 21.2854C18.404 21.9525 17.6922 22.3633 16.9218 22.3633H7.07803C6.30778 22.3633 5.59584 21.9523 5.21072 21.2854Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask0_664_114424)">
          <path
            opacity="0.07"
            d="M23.2591 15.5363L16.8053 9.0825L11.9997 6.9375L7.40594 9.43313L7.21338 14.9344L15.1909 22.9106L18.3128 22.8825L23.2591 15.5363Z"
            fill="black"
          />
        </g>
        <path
          d="M16.6932 8.99867L12.2539 6.43555C12.0974 6.34517 11.9044 6.34517 11.7479 6.43555L7.3086 8.99867C7.15204 9.08905 7.05566 9.25611 7.05566 9.43686V14.5629C7.05566 14.7437 7.15204 14.9107 7.3086 15.0011L11.7479 17.5642C11.9044 17.6546 12.0974 17.6546 12.2539 17.5642L16.6932 15.0011C16.8497 14.9107 16.9461 14.7437 16.9461 14.5629V9.43686C16.9461 9.25611 16.8497 9.08905 16.6932 8.99867ZM16.0842 14.0724L14.5354 13.1782V10.8227L12.2483 12.1427V14.7845L14.2881 13.6069L15.8369 14.501L12.0008 16.7158L8.16473 14.501L9.71348 13.6069L11.7535 14.7845V12.1427L9.46635 10.8227V13.1782L7.9176 14.0724V9.64292L11.7537 7.42817V9.21655L9.71366 10.3942L12.0008 11.7142L14.2879 10.3942L12.2481 9.21655V7.42817L16.0842 9.64292V14.0724Z"
          fill="white"
        />
      </svg>
    </SvgIcon>
  );
};

GkeSvgIcon.muiName = "GkeSvgIcon";

export default GkeSvgIcon;
