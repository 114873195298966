import { FC } from "react";
import { CostAnomalyAlertTimeIntervalUnit } from "../../../../../../../store/alerts/utils/types/types";
import { TypographyWithTooltip } from "../../../../../../common/TypographyWithTooltip";

interface AlertsCostAnomalyDataGridTimeIntervalCellProps {
  timeInterval?: number;
  timeIntervalUnit: CostAnomalyAlertTimeIntervalUnit;
}

export const AlertsCostAnomalyDataGridTimeIntervalCell: FC<
  AlertsCostAnomalyDataGridTimeIntervalCellProps
> = ({ timeIntervalUnit, timeInterval }) => {
  if (!timeInterval || !timeIntervalUnit) {
    return;
  }

  const unit =
    timeInterval > 1 ? timeIntervalUnit : timeIntervalUnit.slice(0, -1);

  return (
    <TypographyWithTooltip
      variant="inherit"
      title={`${timeInterval} ${unit}`}
    />
  );
};
