import { FC, ReactNode } from "react";
import { Skeleton, Typography } from "@mui/material";

interface InfoDataChipProps {
  loading?: boolean;
  children?: ReactNode;
}

export const InfoDataChip: FC<InfoDataChipProps> = ({ loading, children }) => {
  return (
    <Typography
      px={1}
      py={0.25}
      borderRadius={1}
      variant="body2"
      bgcolor="grey.200"
      whiteSpace="nowrap"
      color="text.secondary"
    >
      {loading ? (
        <Skeleton
          variant="text"
          height={18}
          width={50}
          sx={{ display: "inline-block" }}
        />
      ) : (
        children
      )}
    </Typography>
  );
};
