import { createAsyncThunk } from "@reduxjs/toolkit";
import { enqueueSnackbar } from "notistack";
import { taskManagementGetTasksThunk } from "./taskManagementGetTasksThunk";
import {
  cloudChiprApi,
  PatchUsersMeOrganisationsCurrentTasksApiArg,
} from "../../../../services/cloudchipr.api";
import { taskUpdateFixedCacheKey } from "../../utils/constants/fixedCacheKeys";

export const taskManagementUpdateTaskThunk = createAsyncThunk(
  "taskManagement/taskManagementUpdateTask",
  async (
    payload: PatchUsersMeOrganisationsCurrentTasksApiArg["body"],
    { dispatch },
  ) => {
    const { patchUsersMeOrganisationsCurrentTasks } = cloudChiprApi.endpoints;

    try {
      await dispatch(
        patchUsersMeOrganisationsCurrentTasks.initiate(
          { body: payload },
          { fixedCacheKey: taskUpdateFixedCacheKey },
        ),
      ).unwrap();

      await dispatch(taskManagementGetTasksThunk()).unwrap();
    } catch (e) {
      // @ts-expect-error TODO: return to this after adding error type
      const errMessage = e?.data?.message || "Something went wrong";
      enqueueSnackbar(errMessage, {
        variant: "snackbarAlert",
        AlertSnackBarProps: { severity: "error" },
      });
    }
  },
);
