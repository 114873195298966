import { useMemo } from "react";
import { capitalizeString } from "../../../../../../utils/helpers/capitalizeString";
import {
  getAutomationPreviewFormatDate,
  getRunDateFromCron,
} from "../../../common/utils/helpers/common";
import { getCronFrequency } from "../../../../../../store/automations/utils/helpers/cron/getCronFrequency";
import { dateToUtcByTimeZone } from "../../../../../../utils/helpers/date-time/datetime-format";

export function useOffHoursPreviewDatesInfoHook({
  startCron,
  stopCron,
  timeZone,
  nextStartRun,
  nextStopRun,
}: {
  nextStartRun: string;
  nextStopRun: string;
  startCron: string | null;
  stopCron: string | null;
  timeZone: string;
}) {
  const nextRuns = useMemo(() => {
    let startRunDate = "";
    const stopRunDate = "";

    let startRunDateUTC = "";
    let stopRunDateUTC = "";

    if (nextStartRun) {
      startRunDate = getAutomationPreviewFormatDate(nextStartRun ?? "") ?? "";
      startRunDateUTC = getAutomationPreviewFormatDate(
        dateToUtcByTimeZone(nextStartRun, timeZone) || nextStartRun,
      );
    }

    if (nextStopRun) {
      startRunDate = getAutomationPreviewFormatDate(nextStopRun ?? "") ?? "";
      stopRunDateUTC = getAutomationPreviewFormatDate(
        dateToUtcByTimeZone(nextStopRun, timeZone) || nextStopRun,
      );
    }

    return [
      {
        title: "Next Start Date",
        date: startRunDate || "Never",
        utc: startRunDateUTC || "",
      },
      {
        title: "Next Stop Date",
        date: stopRunDate || "Never",
        utc: stopRunDateUTC || "",
      },
    ];
  }, [timeZone, nextStartRun, nextStopRun]);

  const frequency = useMemo(() => {
    if (!timeZone) {
      return;
    }

    if (!stopCron || !startCron) {
      return [
        {
          title: "Instance STOP",
          label: "Once",
          tooltip: nextStopRun ? `Once at ${nextStopRun}` : "",
        },
        {
          title: "Instance START",
          label: "Once",
          tooltip: nextStartRun ? `Once at ${nextStartRun}` : "",
        },
      ];
    }

    return [
      {
        title: "Instance STOP",
        label: capitalizeString(getCronFrequency(stopCron)),
        tooltip: getRunDateFromCron(stopCron),
      },
      {
        title: "Instance START",
        label: capitalizeString(getCronFrequency(startCron)),
        tooltip: getRunDateFromCron(startCron),
      },
    ];
  }, [startCron, stopCron, timeZone, nextStopRun, nextStartRun]);

  return {
    frequency,
    nextRuns,
    nextRunStartDate: nextStartRun,
    nextRunStopDate: nextStopRun,
  };
}
