import { FC } from "react";
import { Tooltip } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

export const StopDateInfoTooltip: FC = () => {
  return (
    <Tooltip
      arrow
      placement="top"
      title="Cloudchipr will only start resources that were stopped previously from this schedule. No other resources will be affected."
    >
      <InfoOutlinedIcon
        sx={{ verticalAlign: "middle", color: "text.secondary", ml: 2 }}
        fontSize="small"
      />
    </Tooltip>
  );
};
