import { FC, useMemo } from "react";
import { Stack } from "@mui/material";
import { ChartType } from "../../../../../storybook/charts/multi-type-chart/utils/types/types";
import {
  ChartBaseType,
  DateDataPointNullable,
  DateLabelNullable,
  GetUsersMeOrganisationsCurrentWidgetsCostBreakdownByWidgetIdApiResponse,
  WidgetCostBreakdownAggregation,
  WidgetCostBreakdownVisualization,
} from "../../../../../services/cloudchipr.api";
import { MultiTypeChartProvider } from "../../../../../storybook/charts/multi-type-chart/MultiTypeChartProvider";
import { ForecastOption } from "../../../../../services/cloudchipr-authless.api";
import { formatResourceExplorerChartData } from "../../../../../store/resource-explorer/utils/helpers/formatResourceExplorerChartData";
import { WidgetTotalsSummary } from "../../../../pages/dashboard/components/widgets/common/WidgetTotalsSummary";
import { WidgetCosts } from "../../../../../store/dashboards/utils/types/types";
import { convertCostToPercent } from "../../../../../store/resource-explorer/selectors/current-resource-explorer/chart/utils/helpers/convertCostToPercent";
import { generateCombinatedChartData } from "../../../../../store/resource-explorer/selectors/current-resource-explorer/chart/utils/helpers/generateCombinatedChartData";
import { CostBreakdownWidgetChart } from "../../../../pages/dashboard/components/adding-widget/widget-create/widget-setups/cost-breakdown/components/chart-view/CostBreakdownWidgetChart";

interface UnauthorizedCostBreakdownWidgetChartViewProps {
  loading: boolean;
  chartType: ChartType;
  dateLabel?: DateLabelNullable;
  dateDataPoint: DateDataPointNullable;
  forecastOption?: ForecastOption;
  widgetData: GetUsersMeOrganisationsCurrentWidgetsCostBreakdownByWidgetIdApiResponse;
  widgetCosts?: WidgetCosts;
  chartBase: ChartBaseType;
  visualization: WidgetCostBreakdownVisualization;
  aggregation: WidgetCostBreakdownAggregation;
}

export const UnauthorizedCostBreakdownWidgetChartView: FC<
  UnauthorizedCostBreakdownWidgetChartViewProps
> = ({
  chartType,
  dateDataPoint,
  loading,
  dateLabel,
  forecastOption,
  widgetData,
  widgetCosts,
  chartBase,
  visualization,
  aggregation,
}) => {
  const costChartData = useMemo(() => {
    if (!widgetData.data || !dateDataPoint) {
      return [];
    }
    return formatResourceExplorerChartData(widgetData.data, dateDataPoint);
  }, [widgetData.data, dateDataPoint]);
  const percentChartData = useMemo(
    () => convertCostToPercent(costChartData),
    [costChartData],
  );
  const chartData = useMemo(
    () =>
      visualization.chart_base_type === "percent"
        ? percentChartData
        : costChartData,
    [visualization.chart_base_type, percentChartData, costChartData],
  );
  const combinatedChartData = useMemo(
    () => generateCombinatedChartData(costChartData, percentChartData),
    [costChartData, percentChartData],
  );

  if (!chartData) {
    return null;
  }

  return (
    <Stack px={2} py={1} gap={1}>
      <MultiTypeChartProvider
        value={{ initialChartType: chartType, data: chartData ?? [] }}
      >
        {dateDataPoint && forecastOption && widgetCosts && (
          <WidgetTotalsSummary
            frequency={dateDataPoint}
            costsData={widgetCosts}
            forecastOption={forecastOption}
            dateLabel={dateLabel}
            showTrends={visualization.trend}
            aggregations={aggregation}
          />
        )}

        <CostBreakdownWidgetChart
          loading={loading}
          chartType={chartType}
          dateDataPoint={dateDataPoint}
          dateDetails={widgetData.total_cost_date_details}
          chartBase={chartBase}
          combinedChartData={combinatedChartData}
        />
      </MultiTypeChartProvider>
    </Stack>
  );
};
