import { isRejectedWithValue, Middleware } from "@reduxjs/toolkit";
import { setLogout } from "../auth/authSlice";

export const unauthenticatedMiddleware: Middleware =
  ({ dispatch }) =>
  (next) =>
  (action) => {
    if (isRejectedWithValue(action) && action.payload.status === 401) {
      dispatch(setLogout(true));
    }

    return next(action);
  };
