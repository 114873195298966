import { FC } from "react";
import { Box, Typography } from "@mui/material";

export const SlackIntegrationMessageNotifyWorkflowInfo: FC = () => {
  return (
    <Box>
      <Typography variant="body2" color="text.secondary" fontWeight="medium">
        Workflow:{" "}
        <Typography color="primary.main" component="span" fontWeight="inherit">
          {`{{workflow name}}`}
        </Typography>
      </Typography>

      <Typography variant="body2" color="text.secondary" fontWeight="medium">
        Monthly Price:{" "}
        <Typography color="text.primary" component="span" fontWeight="inherit">
          {`{{$0.00}}`}
        </Typography>
      </Typography>
    </Box>
  );
};
