import { createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../../../store";
import {
  cloudChiprApi,
  FilterTreeNode,
} from "../../../../services/cloudchipr.api";
import { resourceExplorerPostFixedCacheKey } from "../../selectors/current-resource-explorer/resource-explorer-widget/resourceExplorerWidgetSelector";
import { resourceExplorerGranularDataFixedCacheKey } from "../../selectors/current-resource-explorer/data-grid/granular-costs-view/resourceExplorerGranularViewResponseSelector";
import { resourceExplorerDataGridViewSelector } from "../../selectors/current-resource-explorer/resourceExplorerDataGridViewSelector";
import { resourceExplorerPayloadDataSelector } from "../../selectors/current-resource-explorer/resource-explorer-widget/resourceExplorerPayloadDataSelector";
import { addFilterTreePathToResourceExplorerPayload } from "../../utils/helpers/addFilterTreePathToResourceExplorerPayload";

export const getResourceExplorerDataThunk = createAsyncThunk(
  "resourceExplorer/getResourceExplorerDataThunk",
  async (filterTree: FilterTreeNode | void, { dispatch, getState }) => {
    const state = getState() as RootState;
    const resourceExplorerDataGettingPayload =
      resourceExplorerPayloadDataSelector(state);
    const dataGridView = resourceExplorerDataGridViewSelector(state);

    const {
      postUsersMeOrganisationsCurrentResourceExplorer,
      postUsersMeOrganisationsCurrentResourceExplorerGranularCosts,
    } = cloudChiprApi.endpoints;

    if (!resourceExplorerDataGettingPayload.group_by) {
      return;
    }

    const payload = addFilterTreePathToResourceExplorerPayload(
      resourceExplorerDataGettingPayload,
      filterTree ?? undefined,
    );

    const promises: Promise<any>[] = [];

    promises.push(
      dispatch(
        postUsersMeOrganisationsCurrentResourceExplorer.initiate(
          { resourceExplorerFilterSchema: payload },
          { fixedCacheKey: resourceExplorerPostFixedCacheKey },
        ),
      ).unwrap(),
    );

    if (dataGridView === "granular") {
      promises.push(
        dispatch(
          postUsersMeOrganisationsCurrentResourceExplorerGranularCosts.initiate(
            { resourceExplorerFilterSchema: payload },
            { fixedCacheKey: resourceExplorerGranularDataFixedCacheKey },
          ),
        ).unwrap(),
      );
    }

    const responses = await Promise.all(promises);
    return responses.at(0);
  },
);
