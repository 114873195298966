import { createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../../../store";
import { cleanV2DetailsSelector } from "../../selectors/store-selectors/cleanV2DetailsSelector";
import { getResourceCleanActionType } from "../../utils/helpers";
import {
  addCleanV2Process,
  CleanV2ResourceType,
  setCleanV2CurrentId,
  setCleanV2Details,
} from "../../cleanV2Slice";
import {
  cloudChiprApi,
  PutUsersMeAccountsByAccountIdResourcesApiArg,
} from "../../../../services/cloudchipr.api";
import { getLiveUsageMgmtResourceTypeDataThunk } from "../../../live-usage-mgmt/thunks/resources/getLiveUsageMgmtResourceTypeDataThunk";
import { resetLiveUsageMgmtSelectedResources } from "../../../live-usage-mgmt/liveUsageMgmtSlice";
import { cleanV2ResourcesGroupedByAccountIdSelector } from "../../selectors/cleanV2ResourcesGroupedByAccountIdSelector";
import { CleanResourcesDataType } from "../../../../components/pages/common/clean/clean-dialog/utils/types";

export const cleanV2ResourcesThunk = createAsyncThunk(
  "cleanV2/cleanResources",
  async (_: void, { dispatch, getState }) => {
    const state = getState() as RootState;
    const newCleanProcess = cleanV2DetailsSelector(state);

    const resourcesForCleanGroupedByAccountId =
      cleanV2ResourcesGroupedByAccountIdSelector(state);

    if (!newCleanProcess?.accountIds || !resourcesForCleanGroupedByAccountId) {
      return;
    }

    const accountIds = Object.keys(resourcesForCleanGroupedByAccountId ?? {});

    const { putUsersMeAccountsByAccountIdResources } = cloudChiprApi.endpoints;

    const promises = accountIds?.map((accountId) => {
      const cleanResourcesData = generateData(
        resourcesForCleanGroupedByAccountId[
          accountId
        ] as CleanResourcesDataType,
      );

      const request: PutUsersMeAccountsByAccountIdResourcesApiArg = {
        accountId,
        body: cleanResourcesData,
      };
      return dispatch(
        putUsersMeAccountsByAccountIdResources.initiate(request),
      ).unwrap();
    });

    if (!promises) {
      return;
    }
    const responses = await Promise.all(promises);

    responses.forEach((item, index) => {
      if (!item?.execution_id) {
        return;
      }

      const cleanProcess = {
        executionLogId: item?.execution_id,
        cleanProcess: {
          ...newCleanProcess,
          resources: generateData(
            resourcesForCleanGroupedByAccountId[
              accountIds[index]
            ] as CleanResourcesDataType,
          ),
        },
      };
      dispatch(addCleanV2Process(cleanProcess));
      dispatch(setCleanV2CurrentId(item?.execution_id));
    });
    dispatch(
      getLiveUsageMgmtResourceTypeDataThunk(newCleanProcess.resourceType),
    );

    dispatch(setCleanV2Details(null));

    dispatch(resetLiveUsageMgmtSelectedResources(newCleanProcess.resourceType));
  },
);

const generateData = (resources: CleanResourcesDataType) => {
  return resources?.map((r) => {
    return {
      id: r.id,
      type: r.resource_type,
      action: getResourceCleanActionType(r.cleanAction),
      snapshot: r.cleanAction === "snapshot_and_delete",
      provider_unique_identifier: r.provider_unique_identifier,
    };
  }) as CleanV2ResourceType[];
};
