import { FC } from "react";
import { Stack } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { FilterTriggerComponentProps } from "../../../../../common/select/dropdown-select/utils/types/filterTriggerComponentProps";
import { TypographyWithTooltip } from "../../../../../common/TypographyWithTooltip";
export const LiveUsageMgmtRegionsTriggerComponent: FC<
  FilterTriggerComponentProps
> = ({ onClick, open, selectedValues, options }) => {
  const selectedLength = selectedValues.length
    ? `(${selectedValues.length})`
    : "";

  let label =
    selectedValues.length === options.length
      ? "All Regions"
      : `Regions ${selectedLength}`;

  if (selectedValues?.length === 1) {
    label =
      options?.find((option) => option.rawValue.key === selectedValues.at(0))
        ?.rawValue?.name ?? label;
  }

  return (
    <Stack
      direction="row"
      width={120}
      p={1}
      pl={1.5}
      border={1}
      justifyContent="space-between"
      onClick={onClick}
      borderRadius={1}
      borderColor="grey.300"
      sx={{
        transition: "border-color 0.3s",
        ":hover": {
          borderColor: "initial",
        },
        cursor: "pointer",
      }}
    >
      <TypographyWithTooltip
        title={label}
        variant="caption"
        color="text.secondary"
      />

      {!open ? (
        <ArrowDropDownIcon
          fontSize="small"
          sx={{
            color: "text.secondary",
          }}
        />
      ) : (
        <ArrowDropUpIcon
          fontSize="small"
          sx={{
            color: "text.secondary",
          }}
        />
      )}
    </Stack>
  );
};
