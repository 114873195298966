import { FC, useCallback } from "react";
import { Switch } from "@mui/material";
import { useToggle } from "rooks";
import { Report } from "../../../../../../../services/cloudchipr.api";
import { useAppAbility } from "../../../../../../../services/permissions";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../store/hooks";
import { toggleReportStatusThunk } from "../../../../../../../store/reports/thunks/toggleReportStatusThunk";
import { toggleReportStatusLoadingSelector } from "../../../../../../../store/reports/selector/toggleReportStatusLoadingSelector";

interface ReportsStatusSwitchProps {
  status?: Report["status"];
  reportId: string;
}

export const ReportsStatusSwitch: FC<ReportsStatusSwitchProps> = ({
  status,
  reportId,
}) => {
  const dispatch = useAppDispatch();
  const { cannot } = useAppAbility();
  const canNotEditSchedule = cannot("edit", "report");

  const [checked, toggleChecked] = useToggle(status === "active");
  const loading = useAppSelector((state) =>
    toggleReportStatusLoadingSelector(state, reportId ?? ""),
  );

  const toggleHandler = useCallback(() => {
    toggleChecked();
    dispatch(toggleReportStatusThunk({ reportId, status }));
  }, [reportId, status, dispatch, toggleChecked]);

  return (
    <Switch
      size="small"
      checked={checked}
      onChange={toggleHandler}
      disabled={canNotEditSchedule || loading}
    />
  );
};
