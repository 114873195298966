import { FC, Fragment } from "react";
import Typography from "@mui/material/Typography";
import { Stack } from "@mui/material";
import { AlertsUtilizationAlertTypeSelect } from "./components/AlertsUtilizationAlertTypeSelect";
import { AlertsUtilizationProviderOrgIdSelect } from "./components/AlertsUtilizationProviderOrgIdSelect";
import { AlertsUtilizationThresholdsSection } from "./components/AlertsUtilizationThresholdsSection";
import { AlertsNameTextField } from "../common/AlertsNameTextField";
import { AlertsNotifications } from "../common/AlertsNotifications";
import { AlertActions } from "../common/AlertActions";
import { useAppSelector } from "../../../../../../../store/hooks";
import { alertsUtilizationDataAlertTypeSelector } from "../../../../../../../store/alerts/selectors/utilization-alerts/data/alertsUtilizationDataAlertTypeSelector";

interface AlertsUtilizationDrawerProps {
  onClose(): void;
}

export const AlertsUtilizationDrawer: FC<AlertsUtilizationDrawerProps> = ({
  onClose,
}) => {
  const selectedAlertType = useAppSelector(
    alertsUtilizationDataAlertTypeSelector,
  );

  return (
    <Fragment>
      <Typography variant="body1" fontWeight="medium">
        Alert details
      </Typography>

      <AlertsNameTextField />

      <Stack direction="row" spacing={1}>
        <AlertsUtilizationAlertTypeSelect />
        <AlertsUtilizationProviderOrgIdSelect />
      </Stack>

      <AlertsUtilizationThresholdsSection />

      <AlertsNotifications actionFrom={selectedAlertType} />

      <AlertActions onClose={onClose} alertType="utilization" />
    </Fragment>
  );
};
