import { calculateTotalCostTrend } from "../../../../components/pages/utils/helpers/calculateTotalCostTrend";

export const generateResourceExplorerTotalCostTrend = (
  totalCost: number,
  previousPeriodCost: number,
) => {
  const total = totalCost ?? 0;
  const previous = previousPeriodCost ?? 0;

  const trend = calculateTotalCostTrend(total, previous);

  return previous > 0 ? trend : null;
};
