import { FC } from "react";
import { Stack } from "@mui/material";
import { AlertsIntegrationCustomMessageContentProps } from "../AlertsIntegrationCustomMessageContent";
import { IntegrationsCostAnomalyAlertDialogContent } from "../cost-anomaly-alert/dialog/IntegrationsCostAnomalyAlertDialogContent";
import { IntegrationUtilizationAlertCommitmentsExpirationDialogContent } from "../utilization-alert/dialog/commitments-expiration/IntegrationUtilizationAlertCommitmentsExpirationDialogContent";
import { IntegrationUtilizationAlertCommitmentsUtilizationDialogContent } from "../utilization-alert/dialog/commitments-utilization/IntegrationUtilizationAlertCommitmentsUtilizationDialogContent";
import { IntegrationUtilizationAlertCoverageDialogContent } from "../utilization-alert/dialog/coverage/IntegrationUtilizationAlertCoverageDialogContent";

interface AlertsIntegrationCustomMessageDialogContentProps
  extends AlertsIntegrationCustomMessageContentProps {
  onCustomMessageChange(message: string): void;
}

export const AlertsIntegrationCustomMessageDialogContent: FC<
  AlertsIntegrationCustomMessageDialogContentProps
> = ({ actionFrom, type, onCustomMessageChange, message }) => {
  if (actionFrom === "cost-anomaly-alert") {
    return (
      <Stack spacing={1.5} p={2} bgcolor="grey.50">
        {type === "email" && (
          <IntegrationsCostAnomalyAlertDialogContent
            onCustomMessageChange={onCustomMessageChange}
            customMessage={message}
            type="email"
          />
        )}
        {type === "slack" && (
          <IntegrationsCostAnomalyAlertDialogContent
            onCustomMessageChange={onCustomMessageChange}
            customMessage={message}
            type="slack"
          />
        )}
      </Stack>
    );
  }

  if (actionFrom === "commitments_expiration") {
    return (
      <Stack spacing={1.5} p={2} bgcolor="grey.50">
        {type === "email" && (
          <IntegrationUtilizationAlertCommitmentsExpirationDialogContent
            onCustomMessageChange={onCustomMessageChange}
            customMessage={message}
            type="email"
          />
        )}
        {type === "slack" && (
          <IntegrationUtilizationAlertCommitmentsExpirationDialogContent
            onCustomMessageChange={onCustomMessageChange}
            customMessage={message}
            type="slack"
          />
        )}
      </Stack>
    );
  }

  if (actionFrom === "commitments_utilization") {
    return (
      <Stack spacing={1.5} p={2} bgcolor="grey.50">
        {type === "email" && (
          <IntegrationUtilizationAlertCommitmentsUtilizationDialogContent
            onCustomMessageChange={onCustomMessageChange}
            customMessage={message}
            type="email"
          />
        )}
        {type === "slack" && (
          <IntegrationUtilizationAlertCommitmentsUtilizationDialogContent
            onCustomMessageChange={onCustomMessageChange}
            customMessage={message}
            type="slack"
          />
        )}
      </Stack>
    );
  }

  if (actionFrom === "coverage") {
    return (
      <Stack spacing={1.5} p={2} bgcolor="grey.50">
        {type === "email" && (
          <IntegrationUtilizationAlertCoverageDialogContent
            onCustomMessageChange={onCustomMessageChange}
            customMessage={message}
            type="email"
          />
        )}
        {type === "slack" && (
          <IntegrationUtilizationAlertCoverageDialogContent
            onCustomMessageChange={onCustomMessageChange}
            customMessage={message}
            type="slack"
          />
        )}
      </Stack>
    );
  }

  return null;
};
