import { FC } from "react";
import { Stack, Link } from "@mui/material";
import OpenInNewOutlinedIcon from "@mui/icons-material/OpenInNewOutlined";
import { money } from "../../../../../../../../utils/numeral/money";
import { TypographyWithTooltip } from "../../../../../../../common/TypographyWithTooltip";

interface CommitmentsRecommendationsEstimatedMonthlySavingCellProps {
  value: number;
  link: string;
}
export const CommitmentsRecommendationsEstimatedMonthlySavingCell: FC<
  CommitmentsRecommendationsEstimatedMonthlySavingCellProps
> = ({ value, link }) => {
  return (
    <Stack
      direction="row"
      spacing={1}
      alignItems="center"
      justifyContent="right"
      pr={2}
    >
      <TypographyWithTooltip
        title={money(value)}
        variant="body2"
        fontWeight="bold"
      />

      <Link href={link} target="_blank" pt={0.5}>
        <OpenInNewOutlinedIcon fontSize="small" color="action" />
      </Link>
    </Stack>
  );
};
