import { FilterKeyType } from "../../../../../../../../services/cloudchipr.api";

export const statisticsSelectOptionsByFilterType: Partial<
  Record<FilterKeyType, string[]>
> = {
  processed_data: ["sum"],
};

export const statisticsSelectDefaultOptions = [
  "sum",
  "maximum",
  "minimum",
  "average",
];
