import { Fragment } from "react";
import { Button, LinearProgress } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { Link as RouterLink } from "react-router-dom";
import { IntegrationsList } from "./components/integrations-list/IntegrationsList";
import { PageHeader } from "../common/PageHeader";
import { useAppAbility } from "../../../services/permissions";
import { useGetUsersMeIntegrationsQuery } from "../../../services/cloudchipr.api";

export const Integrations = () => {
  const { cannot } = useAppAbility();
  const canNotCreateIntegration = cannot("create", "integration");
  const { data, isLoading: loading } = useGetUsersMeIntegrationsQuery({});

  return (
    <Fragment>
      <PageHeader
        title="Integrations"
        actions={
          !!data?.length && (
            <Button
              variant="contained"
              disabled={canNotCreateIntegration}
              color="primary"
              component={RouterLink}
              to="/integrations/create"
            >
              <AddIcon sx={{ mr: 1 }} /> create INTEGRATION
            </Button>
          )
        }
      />

      {loading && <LinearProgress />}

      <IntegrationsList />
    </Fragment>
  );
};
