import { FC, Fragment, useMemo } from "react";
import { Box, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import { RightsizingSubRowInfoSection } from "../../rightsizing-recommendations/components/table/subrow/RightsizingSubRowInfoSection";
import { RightsizingRecommendationsChart } from "../../rightsizing-recommendations/components/table/subrow/charts/RightsizingRecommendationsChart";
import { generateChartData } from "../../rightsizing-recommendations/utils/helpers/generateChartData";
import { RightSizingRecommendationDetails } from "../../../../../../services/cloudchipr.api";

interface SingleRightsizingRecommendationSubRowProps {
  recommendation: RightSizingRecommendationDetails;
}

export const SingleRightsizingRecommendationSubRow: FC<
  SingleRightsizingRecommendationSubRowProps
> = ({ recommendation }) => {
  const recommendedCosts =
    recommendation.spending - recommendation.potential_saving;

  const chartData = useMemo(() => {
    if (!recommendation?.cpu) {
      return null;
    }

    return generateChartData(
      recommendation.cpu,
      recommendation.current_vcpus,
      recommendation.recommended_vcpus,
      recommendation.current_machine_type,
      recommendation.recommended_machine_type,
      recommendation?.account?.provider === "azure" ? "average" : "maximum",
    );
  }, [
    recommendation?.current_vcpus,
    recommendation?.recommended_vcpus,
    recommendation?.cpu,
    recommendation?.current_machine_type,
    recommendation?.recommended_machine_type,
    recommendation?.account?.provider,
  ]);

  if (!chartData) {
    return null;
  }

  return (
    <Box p={2} bgcolor={grey[50]} sx={boxStyles}>
      <RightsizingSubRowInfoSection
        resourceType={recommendation.resource_type}
        spending={recommendation.spending}
        region={recommendation.region}
        recommendedCosts={recommendedCosts}
        provider={recommendation?.account?.provider}
      />

      {!!chartData.length && (
        <Fragment>
          <Typography variant="subtitle2" mt={6} mb={2}>
            CPU Utilization (%)
          </Typography>
          <RightsizingRecommendationsChart
            data={chartData}
            currentType={recommendation.current_machine_type}
            recommendedType={recommendation.recommended_machine_type}
            metric={
              recommendation?.account?.provider === "azure"
                ? "average"
                : "maximum"
            }
          />
        </Fragment>
      )}
    </Box>
  );
};

const boxStyles = {
  "& .MuiBox-root": {
    pl: 0,
  },
  "& .recharts-responsive-container": {
    position: "relative",
    width: "calc(100% + 40px) !important",
    left: "-40px",
  },
};
