import { FC, Fragment, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDidMount, useEffectOnceWhen, useWillUnmount } from "rooks";
import { Box, CircularProgress, Stack } from "@mui/material";
import { WorkflowForm } from "./WorkflowForm";
import { useAppAbility } from "../../../../../services/permissions";
import { PageHeader } from "../../../common/PageHeader";
import { useAppDispatch, useAppSelector } from "../../../../../store/hooks";
import { resetAutomationsState } from "../../../../../store/automations/automationsSlice";
import { currentEditingWorkflowLoadingSelector } from "../../../../../store/automations/selectros/workflow/data/edit/currentEditingWorkflowLoadingSelector";
import { editingWorkflowIdIsWrongSelector } from "../../../../../store/automations/selectros/workflow/data/edit/editingWorkflowIdIsWrongSelector";
import { initiateWorkflowThunk } from "../../../../../store/automations/thunks/wokrflows/initiate/initiateWorkflowThunk";
import { getProvidersRegionsThunk } from "../../../../../store/automations/thunks/regions/getProvidersRegionsThunk";

export const SingleWorkflow: FC = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const { cannot } = useAppAbility();
  const dispatch = useAppDispatch();
  const cannotCreate = cannot("create", "schedule");
  const cannotEdit = cannot("edit", "schedule");

  const loading = useAppSelector(currentEditingWorkflowLoadingSelector);
  const error = useAppSelector(editingWorkflowIdIsWrongSelector);

  const breadcrumbs = useMemo(() => {
    return [
      { title: "Workflows", to: "/automations/workflow" },
      { title: `${id ? "Edit" : "Create new"} Workflow` },
    ];
  }, [id]);

  useEffectOnceWhen(
    () => {
      navigate("/automations/workflow", { replace: true });
    },
    error || cannotCreate || cannotEdit,
  );

  useDidMount(async () => {
    await dispatch(getProvidersRegionsThunk());
    dispatch(initiateWorkflowThunk(id));
  });

  useWillUnmount(() => {
    dispatch(resetAutomationsState());
  });

  return (
    <Fragment>
      <PageHeader breadcrumbs={breadcrumbs} />

      <Box bgcolor="white" flex={1}>
        {loading ? (
          <Stack alignItems="center" pt={4}>
            <CircularProgress />
          </Stack>
        ) : (
          <WorkflowForm />
        )}
      </Box>
    </Fragment>
  );
};
