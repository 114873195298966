import { isOptionJiraOptionWithChildren } from "./typeCheckers";
import {
  JiraCustomFieldType,
  JiraSchemaItemTypes,
  JiraSchemaTypes,
} from "../types/types";
import { jiraParagraphFieldKey } from "../../components/rows/custom/ParagraphField";

export const getJiraCustomFiledType = (customField: JiraCustomFieldType) => {
  const type = customField.schema.type as JiraSchemaTypes;
  const itemType = customField.schema.item_type as JiraSchemaItemTypes;
  const customType = customField.schema.custom_type;

  const isAutocomplete =
    (type === "user" || itemType === "user") && customField.auto_complete_url;

  const isMultipleAutocomplete =
    type === "array" && itemType === "user" && customField.auto_complete_url;

  const isNumberField = type === "number";

  const isIssueURL = type === "issuelink";

  const isDate = type === "date" || type === "datetime";

  const isSingleSelect = customField.options && type !== "array";

  const isMultipleSelect = customField.options && type === "array";

  const isMultipleSelectWithOptions =
    isMultipleSelect && (itemType === "user" || itemType === "option");

  const isNestedSelect =
    customField.options &&
    type === "option-with-child" &&
    isOptionJiraOptionWithChildren(customField?.options?.[0]);

  const isTextField =
    (type === "string" || type === "url") &&
    !isIssueURL &&
    customType !== jiraParagraphFieldKey;

  const isRichText = customType === jiraParagraphFieldKey;

  return {
    isDate,
    isRichText,
    isIssueURL,
    isTextField,
    isNumberField,
    isSingleSelect,
    isNestedSelect,
    isAutocomplete,
    isMultipleSelect,
    isMultipleAutocomplete,
    isMultipleSelectWithOptions,
  };
};
