import { FC } from "react";
import { Button, Stack, Typography } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { useAppAbility } from "../../../../../../services/permissions";
import EmptyStateImage from "../../../../../../assets/images/empty_schedules-list.png";

export const WorkflowsEmptyState: FC = () => {
  const { cannot } = useAppAbility();
  const canNotCreateSchedule = cannot("create", "schedule");

  return (
    <Stack justifyContent="center" alignItems="center" mt={12}>
      <img src={EmptyStateImage} alt="EmptyStateImage" />
      <Typography component="h4" variant="h4" textAlign="center" mt={5}>
        It’s Time to plan your first Workflow!
      </Typography>
      <Typography variant="body1" textAlign="center" mt={2}>
        Choose the resources and parameters to run an automation workflow.
      </Typography>
      <Button
        sx={{ mt: 6 }}
        variant="contained"
        color="primary"
        size="small"
        component={RouterLink}
        to={"/automations/workflow/create"}
        disabled={canNotCreateSchedule}
      >
        Create Workflow
      </Button>
    </Stack>
  );
};
