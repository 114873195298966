import { createAsyncThunk } from "@reduxjs/toolkit";
import { getLiveUsageMgmtResourcesWithFiltersByProviderThunk } from "./resources/getLiveUsageMgmtResourcesWithFiltersByProviderThunk";
import { ResourceType } from "../../../services/cloudchipr.api";
import { RootState } from "../../store";

import { liveUsageMgmtSelectedResourcesByResourceTypeSelector } from "../selectors/resource-type-data/liveUsageMgmtSelectedResourcesByResourceTypeSelector";
import { liveUsageMgmtAppliedFiltersByResourceTypeSelector } from "../selectors/filter-template/liveUsageMgmtAppliedFiltersByResourceTypeSelector";
import {
  resetLiveUsageMgmtSelectedResources,
  setLiveUsageMgmtAppliedFilters,
  setLiveUsageMgmtFilterChangedManually,
} from "../liveUsageMgmtSlice";
import { addProviderUniqueIdentifierFilterToAppliedFilters } from "../utils/helpers/addProviderUniqueIdentifierFilterToAppliedFilters";

export const filterOutResourcesThunk = createAsyncThunk(
  "liveUsageMgmt/filterOutResources",
  (resourceType: ResourceType, { dispatch, getState }) => {
    const state = getState() as RootState;

    const selectedResources =
      liveUsageMgmtSelectedResourcesByResourceTypeSelector(state, resourceType);

    const appliedFilters = liveUsageMgmtAppliedFiltersByResourceTypeSelector(
      state,
      resourceType,
    );

    if (!selectedResources) {
      return;
    }

    const instanceIds = selectedResources?.map(
      (item) => item.resource.provider_unique_identifier,
    );
    const uniqueIdFilter = addProviderUniqueIdentifierFilterToAppliedFilters({
      resourceType,
      resourceIds: instanceIds || null,
      appliedFilters,
      operator: "not_in",
    });

    if (uniqueIdFilter) {
      dispatch(setLiveUsageMgmtAppliedFilters(uniqueIdFilter));
      dispatch(setLiveUsageMgmtFilterChangedManually());
      dispatch(resetLiveUsageMgmtSelectedResources(resourceType));
      dispatch(
        getLiveUsageMgmtResourcesWithFiltersByProviderThunk(resourceType),
      );
    }
  },
);
