import { FC } from "react";
import { Typography } from "@mui/material";
import { Billable } from "../../../../../../../../../../services/cloudchipr.api";
import { TooltipText } from "../../../../../../../../../common/TooltipText";

interface BillableCellProps {
  data: Billable;
}

export const BillableCell: FC<BillableCellProps> = ({ data }) => {
  if (data === "analyzing") {
    return (
      <TooltipText tooltipTitle="Cloudchipr gets billing data from CUR(cost usage reports). Billing and cost data for snapshots get updated every 24 hours.">
        Analyzing
      </TooltipText>
    );
  }

  return (
    <Typography fontSize="small" textTransform="capitalize">
      {data}
    </Typography>
  );
};
