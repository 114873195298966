import { reportsDataPropertyByKeySelector } from "./reportsDataPropertyByKeySelector";
import { RootState } from "../../store";
import { currentDashboardNameSelector } from "../../dashboards/selectors/dashboard/currentDashboardNameSelector";
import { resourceExplorerNameSelector } from "../../resource-explorer/selectors/current-resource-explorer/resourceExplorerNameSelector";

export const reportCurrentSourceNameBySourceSelector = (state: RootState) => {
  const sourceType = reportsDataPropertyByKeySelector("source_type")(state);

  if (sourceType === "dashboard") {
    return currentDashboardNameSelector(state);
  }

  return resourceExplorerNameSelector(state);
};
