import { createAsyncThunk } from "@reduxjs/toolkit";
import { enqueueSnackbar } from "notistack";
import { cloudChiprApi } from "../../../../../services/cloudchipr.api";
import { RootState } from "../../../../store";
import { recommendationRestoreByResourceFixedCacheKey } from "../../../utils/constants/fixedCacheKeys";
import { refetchDataAfterActionThunk } from "../../refetchDataAfterActionThunk";
import { rightsizingRecommendationsDismissedResourcesAccountIdsByResourceIdSelector } from "../../../selectors/rightsizing/dismissed-resources/rightsizingRecommendationsDismissedResourcesAccountIdsByResourceIdSelector";

export const restoreRightsizingRecommendationResourceThunk = createAsyncThunk(
  "recommendations/restoreRightsizingRecommendationResourceThunk",
  async (_: void, { dispatch, getState }) => {
    const state = getState() as RootState;

    const {
      patchUsersMeOrganisationsCurrentRecommendationsRightSizingByResource,
    } = cloudChiprApi.endpoints;

    const selectedIds =
      rightsizingRecommendationsDismissedResourcesAccountIdsByResourceIdSelector(
        state,
      );

    if (!selectedIds) {
      return;
    }

    try {
      await dispatch(
        patchUsersMeOrganisationsCurrentRecommendationsRightSizingByResource.initiate(
          { restoreRecommendationByResourceRequest: selectedIds },
          { fixedCacheKey: recommendationRestoreByResourceFixedCacheKey },
        ),
      ).unwrap();

      await dispatch(
        refetchDataAfterActionThunk({
          rightsizing: true,
          rightsizingDismissedResources: true,
        }),
      );
    } catch (error: any) {
      enqueueSnackbar(error?.data?.message ?? "Something went wrong.", {
        variant: "snackbarAlert",
        AlertSnackBarProps: {
          severity: "error",
        },
      });
    }
  },
);
