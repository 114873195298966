import { v4 as uuid } from "uuid";
import { ModalFilterItem } from "../types/types";
import {
  FilterItemTemplate,
  ResourceFilters,
  ResourceType,
} from "../../../../../../../services/cloudchipr.api";

export const createNewFilterTemplateByResourceType = (
  resourceType: ResourceType,
  defaultFilters: ResourceFilters,
  allowedFilters?: FilterItemTemplate[],
): ModalFilterItem | undefined => {
  const firstFilterItem = defaultFilters?.filter_items?.[0];

  if (firstFilterItem) {
    return {
      id: uuid(),
      ...firstFilterItem,
    };
  }

  if (!allowedFilters) {
    return undefined;
  }

  return {
    type: resourceType,
    id: uuid(),
    filter: {
      key: allowedFilters[0].key,
      operator: allowedFilters[0].operators[0].key,
    },
  };
};
