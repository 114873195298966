import { commitmentUtilizationWidgetSetupDataSelector } from "./commitmentUtilizationWidgetSetupDataSelector";
import { RootState } from "../../../../../store";

export const commitmentUtilizationWidgetSetupEmptyDataSelector = (
  state: RootState,
) => {
  const data = commitmentUtilizationWidgetSetupDataSelector(state);

  return !data?.groups.length;
};
