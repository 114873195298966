import { FC, useCallback } from "react";
import { capitalize } from "@mui/material";
import { DropdownSelect } from "../../../../../../../common/select/dropdown-select/DropdownSelect";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../store/hooks";
import { setReportFilters } from "../../../../../../../../store/reports/reportsSlice";
import { reportsFrequencyFilterValuesSelector } from "../../../../../../../../store/reports/selector/reportsFrequencyFilterValuesSelector";

export const ReportsFrequenciesFilter: FC = () => {
  const dispatch = useAppDispatch();
  const initialFrequencies = useAppSelector(
    reportsFrequencyFilterValuesSelector,
  );

  const options = frequencies.map((frequency) => ({
    value: frequency,
    hideOnly: true,
    label: capitalize(frequency),
  }));

  const optionsCount = options.length;

  const changeHandler = useCallback(
    (newFilters: string[]) => {
      dispatch(
        setReportFilters({
          key: "frequency",
          values: newFilters.length === optionsCount ? [] : newFilters,
        }),
      );
    },
    [dispatch, optionsCount],
  );

  return (
    <DropdownSelect
      initialSelectedValues={initialFrequencies}
      showSearch={false}
      label="Frequency"
      options={options}
      submitHandlerOnClose={changeHandler}
    />
  );
};

const frequencies = ["daily", "weekly", "monthly"];
