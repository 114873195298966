import moment from "moment";
import { ReportsData, ReportsSchedule } from "../types/types";
import {
  DailySchedule,
  MonthlyByDaySchedule,
  MonthlyByWeekdaySchedule,
  WeeklySchedule,
} from "../../../../services/cloudchipr.api";
import { getTimeZoneObject } from "../../../../utils/helpers/date-time/timeZones";
import { formatDate } from "../../../../utils/helpers/date-time/datetime-format";

const defaultTime = formatDate(moment().set({ h: 12, m: 0, s: 0 }), {
  type: "unTime",
});

export const reportsDailyDefaultData: DailySchedule = {
  frequency: "daily",
  interval: 1,
  time: defaultTime,
};

export const reportsWeeklyDefaultData: WeeklySchedule = {
  frequency: "weekly",
  interval: 1,
  time: defaultTime,
  week_days: ["monday"],
};

export const reportsMonthlyByDayDefaultData: MonthlyByDaySchedule = {
  frequency: "monthly_by_day",
  interval: 1,
  time: defaultTime,
  day_of_month: 1,
};

export const reportsMonthlyByWeekdayDefaultData: MonthlyByWeekdaySchedule = {
  frequency: "monthly_by_weekday",
  interval: 1,
  time: defaultTime,
  week_day: "monday",
  number_of_week: 1,
};

export const reportsScheduleDefaultDataByFrequency = new Map<
  ReportsSchedule["frequency"],
  ReportsSchedule
>([
  ["daily", reportsDailyDefaultData],
  ["weekly", reportsWeeklyDefaultData],
  ["monthly_by_day", reportsMonthlyByDayDefaultData],
  ["monthly_by_weekday", reportsMonthlyByWeekdayDefaultData],
]);

export const reportsDefaultData: ReportsData = {
  name: "",
  source_type: "view",
  export_file_types: ["pdf"],
  schedule: reportsDailyDefaultData,
  notifications: undefined,
  time_zone: getTimeZoneObject().tzCode,
  emails: undefined,
};
