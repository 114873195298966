import { FC, ReactNode } from "react";
import { Box, Card, Stack, Typography } from "@mui/material";
import MoreTimeIcon from "@mui/icons-material/MoreTime";
import CloudQueueIcon from "@mui/icons-material/CloudQueue";
import LibraryAddCheckOutlinedIcon from "@mui/icons-material/LibraryAddCheckOutlined";
import CategoryOutlinedIcon from "@mui/icons-material/CategoryOutlined";
import NotificationAddOutlinedIcon from "@mui/icons-material/NotificationAddOutlined";
import {
  AutomationSectionName,
  getAutomationSectionIdBySectionName,
} from "../../../../workflows/single-workflow/utils/sectionsIds";

const icons = {
  schedule: MoreTimeIcon,
  environment: CloudQueueIcon,
  action: LibraryAddCheckOutlinedIcon,
  services: CategoryOutlinedIcon,
  notification: NotificationAddOutlinedIcon,
};

interface AutomationSectionCardProps {
  sectionName?: AutomationSectionName;
  title: string;
  children: ReactNode;
  icon: keyof typeof icons;
  preview?: boolean;
}

export const AutomationSectionCard: FC<AutomationSectionCardProps> = ({
  children,
  title,
  icon,
  preview,
  sectionName,
}) => {
  const Icon = icons[icon];

  return (
    <Card
      variant="outlined"
      id={getAutomationSectionIdBySectionName(sectionName)}
    >
      <Stack
        py={1}
        px={2}
        borderBottom={1}
        bgcolor="grey.100"
        borderColor="grey.300"
      >
        <Stack direction="row" spacing={1} alignItems="center">
          {Icon && <Icon fontSize="small" />}

          <Typography whiteSpace="nowrap" fontWeight="bold" variant="body1">
            {title}
          </Typography>
        </Stack>
      </Stack>

      <Box p={preview ? 0 : 2}>{children}</Box>
    </Card>
  );
};
