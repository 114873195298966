import { liveUsageMgmtAppliedFiltersByResourceTypeSelector } from "./filter-template/liveUsageMgmtAppliedFiltersByResourceTypeSelector";
import { RootState } from "../../store";
import { ResourceType } from "../../../services/cloudchipr.api";

export const isLiveUsageMgmtFilterAppliedToResourceSelector = (
  state: RootState,
  resourceType: ResourceType,
) => {
  const filters = liveUsageMgmtAppliedFiltersByResourceTypeSelector(
    state,
    resourceType,
  );

  return !!filters?.filter_items?.length;
};
