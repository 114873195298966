import { Row } from "@tanstack/react-table";
import {
  CostAnomalyAlertsDataGridDataType,
  UtilizationAlertDataGridDataType,
} from "../../../../../../../../store/alerts/utils/types/types";

export const alertsNotificationsSortingFn = <
  T extends
    | CostAnomalyAlertsDataGridDataType
    | UtilizationAlertDataGridDataType,
>(
  currentRow: Row<T>,
  nextRow: Row<T>,
) => {
  const currentIntegrationName = currentRow.original.integrations?.at(0)?.name;
  const currentEmail = currentRow.original.emails?.to?.at(0);
  const current = (currentIntegrationName ?? currentEmail ?? "").toLowerCase();

  const nextIntegrationName = nextRow.original.integrations?.at(0)?.name ?? "";
  const nextEmail = nextRow.original.emails?.to?.at(0);
  const next = (nextIntegrationName ?? nextEmail ?? "").toLowerCase();

  return current > next ? 1 : -1;
};
