import { createDraftSafeSelector } from "@reduxjs/toolkit";
import { rightsizingRecommendationsStatusesGroupedSelector } from "./rightsizingRecommendationsStatusesGroupedSelector";

export const allProvidersStatusesDisabledSelector = createDraftSafeSelector(
  [rightsizingRecommendationsStatusesGroupedSelector],
  (data) => {
    return (
      !data?.aws.enabled.length &&
      !data?.aws.not_detected.length &&
      !data?.gcp.enabled.length &&
      !data?.gcp.not_detected.length &&
      !data?.azure.enabled.length &&
      !data?.azure.not_detected.length
    );
  },
);
