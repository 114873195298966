import { FC } from "react";
import { Stack, Typography } from "@mui/material";
import EmptyStateImage from "../../../../assets/images/empty_categories_state.svg";
import { AddingNewCategory } from "../../common/categories/category-adding/AddingNewCategory";
import { useAppAbility } from "../../../../services/permissions";

export const CategoriesEmptyState: FC = () => {
  const { cannot } = useAppAbility();
  const canNotCreateCategory = cannot("create", "category");
  return (
    <Stack alignItems="center" mt={12}>
      <img src={EmptyStateImage} alt="EmptyStateImage" />
      <Typography
        component="h5"
        variant="h5"
        fontWeight="bold"
        textAlign="center"
        mt={5}
      >
        You have not created any categories yet!
      </Typography>
      <Stack alignItems="center" maxWidth={520} spacing={4}>
        <Typography variant="body1" textAlign="center" mt={2}>
          Once you define categories based on multiple cloud accounts Cloudchipr
          will create a chart broken down by categories.
        </Typography>

        <AddingNewCategory
          buttonTitle="Add Category Group"
          disabled={canNotCreateCategory}
        />
      </Stack>
    </Stack>
  );
};
