import { FC, Fragment, useCallback, useMemo } from "react";
import {
  Card,
  CardContent,
  Box,
  Stack,
  Typography,
  Container,
  LinearProgress,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { BillingContent } from "./BillingContent";
import {
  useGetUsersMeSubscriptionsCurrentQuery,
  useGetV2PlansQuery,
  usePostUsersMeOrganisationsCurrentBillingPortalMutation,
} from "../../../../../services/cloudchipr.api";
import StripeLogo from "../../../../../assets/images/stripe.png";
import { useAppAbility } from "../../../../../services/permissions";
import { PageHeader } from "../../../common/PageHeader";
import { FailedPaymentSystemMessage } from "../../../../system-message/components/FailedPaymentSystemMessage";

export const Billing: FC = () => {
  const [trigger, { isLoading: loadingTheLink }] =
    usePostUsersMeOrganisationsCurrentBillingPortalMutation();

  const { data: currentSubscription } =
    useGetUsersMeSubscriptionsCurrentQuery();

  const { isLoading: loading } = useGetV2PlansQuery();

  const { cannot } = useAppAbility();
  const canNotEditBilling = cannot("edit", "billing");

  const handleStripeCustomerPortalClick = useCallback(async () => {
    const response = await trigger();
    const link = "data" in response && response?.data?.link;
    if (link) {
      window.open(link, "_blank");
    }
  }, [trigger]);

  const isAWSMarketplace = currentSubscription?.type === "aws_marketplace";
  const contentDisablingStyles = useMemo(() => {
    return { pointerEvents: "none", opacity: 0.3, filter: "grayscale(1)" };
  }, []);

  return (
    <Fragment>
      <PageHeader
        title="Payment & Billing"
        actions={
          <LoadingButton
            variant="contained"
            loading={loadingTheLink}
            onClick={handleStripeCustomerPortalClick}
            disabled={loadingTheLink || canNotEditBilling}
          >
            Manage billing and payment methods
          </LoadingButton>
        }
      />

      {loading && <LinearProgress />}

      <FailedPaymentSystemMessage />

      {isAWSMarketplace && (
        <Typography variant="h5" color="primary" mb={4} textAlign="center">
          The subscription is managed through AWS Marketplace.
        </Typography>
      )}

      <Container sx={{ p: 2 }} disableGutters>
        <Card variant="outlined">
          <CardContent>
            <Box sx={isAWSMarketplace ? contentDisablingStyles : undefined}>
              <BillingContent />

              <Stack
                my={2}
                spacing={1}
                direction="row"
                alignItems="flex-end"
                justifyContent="flex-end"
              >
                {currentSubscription?.type && (
                  <Fragment>
                    <Typography variant="body1" fontWeight="bold">
                      Powered by
                    </Typography>
                    <img src={StripeLogo} alt="Stripe" width={48} />
                  </Fragment>
                )}
              </Stack>
            </Box>
          </CardContent>
        </Card>
      </Container>
    </Fragment>
  );
};
