import { FC } from "react";
import { WidgetNumeralView } from "../../../adding-widget/widget-create/widget-setups/common/view-type/WidgetNumeralView";
import { WidgetNumeralViewItem } from "../../../adding-widget/widget-create/widget-setups/common/view-type/WidgetNumeralViewItem";
import { money } from "../../../../../../../utils/numeral/money";
import { GetUsersMeOrganisationsCurrentWidgetsCommitmentsUtilizationByWidgetIdApiResponse } from "../../../../../../../services/cloudchipr.api";
import { getLabelColorByCostAmount } from "../../../../utils/helpers/widget/getLabelColorByCostAmount";

interface CommitmentUtilizationWidgetNumeralViewProps {
  data?: GetUsersMeOrganisationsCurrentWidgetsCommitmentsUtilizationByWidgetIdApiResponse;
}

export const CommitmentUtilizationWidgetNumeralView: FC<
  CommitmentUtilizationWidgetNumeralViewProps
> = ({ data }) => {
  const { groups } = data ?? {};

  return (
    <WidgetNumeralView
      loading={false}
      skeletonCount={groups?.length ?? 6}
      width={itemWidth}
    >
      {groups?.map((item) => {
        return (
          <WidgetNumeralViewItem
            key={item.name}
            label={item.name ?? ""}
            costTooltipTitle="Utilization"
            cost={item?.utilization?.amount}
            trend={null}
            percent={item?.utilization?.percentage}
            itemsCount={groups.length}
            secondLabelTooltipTitle="Net Savings"
            secondaryLabel={money(item?.net_savings)}
            secondaryLabelColor={getLabelColorByCostAmount(item?.net_savings)}
            width={itemWidth}
          />
        );
      })}
    </WidgetNumeralView>
  );
};

const itemWidth = "25%";
