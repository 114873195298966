import { ChangeEvent, FC, useCallback, useState } from "react";
import { useDebounce, useEffectOnceWhen } from "rooks";
import { TextField } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../../../../store/hooks";
import {
  setAutomationData,
  setAutomationErrors,
} from "../../../../../../store/automations/automationsSlice";
import { automationNameSelector } from "../../../../../../store/automations/selectros/common/fields/automationNameSelector";
import { automationNameErrorSelector } from "../../../../../../store/automations/selectros/common/errors/automationNameErrorSelector";

export const AutomationName: FC = () => {
  const dispatch = useAppDispatch();
  const name = useAppSelector(automationNameSelector);
  const nameError = useAppSelector(automationNameErrorSelector);

  const [value, setValue] = useState<string | null>(null);

  const nameChangeDispatcher = useCallback(
    (name: string) => {
      dispatch(setAutomationData({ name }));

      if (name && nameError) {
        dispatch(setAutomationErrors({ name: "" }));
      }
    },
    [dispatch, nameError],
  );

  const handleNameChange = useDebounce(nameChangeDispatcher, 500);

  const nameChangeHandler = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value;

      setValue(value);
      handleNameChange(value);
    },
    [handleNameChange],
  );

  const blurHandler = useCallback(() => {
    setValue(value?.trim() ?? "");
    handleNameChange(value?.trim() ?? "");
  }, [handleNameChange, value]);

  useEffectOnceWhen(() => setValue(name), !!(name && value === null));

  return (
    <TextField
      fullWidth
      size="small"
      value={value ?? ""}
      type="text"
      label="Name*"
      error={!!nameError}
      helperText={nameError}
      sx={{ mt: 0.5, bgcolor: "white" }}
      onBlur={blurHandler}
      onChange={nameChangeHandler}
    />
  );
};
