import { createDraftSafeSelector } from "@reduxjs/toolkit";
import { selectedIdsSelector } from "./selectedIdsSelector";
import { rightsizingRecommendationsDismissedDataSelector } from "../rightsizing/data/rightsizingRecommendationsDismissedDataSelector";
import { recommendationComputeDailyDismissedDataSelector } from "../off-hours/data/compute/recommendationComputeDailyDismissedDataSelector";
import { recommendationComputeWeeklyDismissedDataSelector } from "../off-hours/data/compute/recommendationComputeWeeklyDismissedDataSelector";
import { recommendationDbDailyDismissedDataSelector } from "../off-hours/data/database/recommendationDbDailyDismissedDataSelector";
import { recommendationDbWeeklyDismissedDataSelector } from "../off-hours/data/database/recommendationDbWeeklyDismissedDataSelector";
import { recommendationByIdSeparatorByTypeCombiner } from "../../combiners/recommendationByIdSeparatorByTypeCombiner";

export const selectedDismissedRecommendationIdsByTypesSelector =
  createDraftSafeSelector(
    [
      selectedIdsSelector,
      recommendationDbDailyDismissedDataSelector,
      recommendationDbWeeklyDismissedDataSelector,
      recommendationComputeDailyDismissedDataSelector,
      recommendationComputeWeeklyDismissedDataSelector,
      rightsizingRecommendationsDismissedDataSelector,
    ],
    recommendationByIdSeparatorByTypeCombiner,
  );
