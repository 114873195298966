import { CardContent, Stack } from "@mui/material";
import { RightsizingRecommendationsCSVDownloader } from "./RightsizingRecommendationsCSVDownloader";
import { RightsizingAccountFilter } from "../filters/accountFilter/RightsizingAccountFilter";
import { RightsizingProviderFilter } from "../filters/providerFilter/RightsizingProviderFilter";
import { RightsizingTypeFilter } from "../filters/RightsizingTypeFilter";
import { RightsizingAdvancedFilterTrigger } from "../filters/advanced-filters/RightsizingAdvancedFilterTrigger";
import { RightsizingAdvancedFilterCheckboxes } from "../filters/advanced-filters/RightsizingAdvancedFilterCheckboxes";
import { RightsizingTagsFilter } from "../filters/tags-filter/RightsizingTagsFilter";
import { useToggleAdvancedFilterTriggerHook } from "../../utils/hooks/useToggleAdvancedFilterTrigger.hook";
import { RecommendationRestoreButton } from "../../../common/dismiss/RecommendationRestoreButton";
import { RecommendationsTabType } from "../../../off-hours-recommendations/components/OffHoursRecommendationsContent";
import { RecommendationDismiss } from "../../../common/dismiss/RecommendationDismiss";
import { ResourceRestoreButton } from "../../../common/dismiss/by-resource/ResourceRestoreButton";
import { RenderToolbar } from "../../../../../../../storybook/data-grid/utils/types/prop-types";
import { GlobalFilter } from "../../../../../../../storybook/data-grid/toolbar/GlobalFilter";

type RightsizingRecommendationsTableToolbarProps = RenderToolbar<{
  tab: RecommendationsTabType;
}>;

export const RightsizingRecommendationsTableToolbar: RightsizingRecommendationsTableToolbarProps =
  ({ globalFilter, setGlobalFilter, tab }) => {
    const [advancedFiltersOpen, toggleAdvancedFilter] =
      useToggleAdvancedFilterTriggerHook();

    return (
      <CardContent sx={{ px: 3 }}>
        <Stack direction="row" justifyContent="space-between">
          <Stack direction="row" spacing={2}>
            <RightsizingProviderFilter />
            <RightsizingAccountFilter />
            <RightsizingTypeFilter tab={tab} />
            <RightsizingTagsFilter tab={tab} />
            {tab === "active" && (
              <RightsizingAdvancedFilterTrigger
                advancedFiltersOpen={advancedFiltersOpen}
                toggleAdvancedFilter={toggleAdvancedFilter}
              />
            )}
          </Stack>
          {tab === "active" && (
            <RecommendationDismiss multiple recommendationType="rightsizing" />
          )}

          {tab === "dismissed" && <RecommendationRestoreButton multiple />}

          {tab === "dismissed_resources" && <ResourceRestoreButton multiple />}
        </Stack>

        {advancedFiltersOpen && tab === "active" && (
          <RightsizingAdvancedFilterCheckboxes />
        )}

        <Stack direction="row" justifyContent="flex-end" spacing={2} mt={2}>
          <GlobalFilter
            globalFilter={globalFilter}
            setGlobalFilter={setGlobalFilter}
            size="xsmall"
          />

          <RightsizingRecommendationsCSVDownloader
            dismissed={tab === "dismissed"}
          />
        </Stack>
      </CardContent>
    );
  };
