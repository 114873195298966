import { FC, RefObject } from "react";
import { ColumnResizeMode, Table } from "@tanstack/react-table";
import { TableHead, Theme } from "@mui/material";
import { SxProps } from "@mui/system";
import { VirtualItem } from "react-virtual";
import { TableHeaderRowNotVirtualized } from "./header-row/TableHeaderRowNotVirtualized";
import { TableHeaderRowVirtualized } from "./header-row/TableHeaderRowVirtualized";

interface TableHeadComponentProps {
  table: Table<any>;
  columnsFilterEnabled: boolean;
  rowDnDEnabled: boolean;
  columnsDnDEnabled: boolean;
  columnsSortEnabled: boolean;
  stickyColumnsEnabled: boolean;
  enableColumnVirtualization: boolean;
  styles?: SxProps<Theme>;
  columnResizeMode?: ColumnResizeMode;
  rerender(): void;
  tableContainerRef: RefObject<HTMLTableElement>;
  reAssignVirtualColumns(config: VirtualColumnsConfig): void;
}

export type VirtualColumnsConfig = {
  columns: VirtualItem[];
  pl: number;
  pr: number;
};

export const TableHeadComponent: FC<TableHeadComponentProps> = ({
  enableColumnVirtualization,
  ...props
}) => {
  return (
    <TableHead>
      {props.table.getHeaderGroups().map((headerGroup) => {
        if (enableColumnVirtualization) {
          return (
            <TableHeaderRowVirtualized
              key={headerGroup.id}
              headerGroup={headerGroup}
              {...props}
            />
          );
        }

        return (
          <TableHeaderRowNotVirtualized
            key={headerGroup.id}
            headerGroup={headerGroup}
            {...props}
          />
        );
      })}
    </TableHead>
  );
};
