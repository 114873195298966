import { ColumnSetupType } from "../../../../../../../../../../../../../../storybook/data-grid/utils/types/types";
import { AzLb } from "../../../../../../../../../../../../../../services/cloudchipr.api";

export const azLbColumns: ColumnSetupType<AzLb>[] = [
  {
    accessorKey: "name",
    header: "LB Name",
    type: "identifier",
    size: 200,
    meta: { sticky: "left", hideFromSettings: true },
  },
  {
    accessorKey: "price_per_month",
    header: "Monthly Price",
    type: "money",
    headerTooltip:
      "Monthly prices are calculated based on the on-demand list price of each resource.",
  },
  {
    accessorKey: "account",
    header: "Account",
    type: "account",
    maxSize: 210,
  },
  {
    accessorKey: "all_time_spent",
    header: "Total Spend",
    type: "money",
  },
  {
    accessorKey: "resource_group",
    header: "Resource Group",
    type: "copyWithTooltip",
    size: 180,
  },
  {
    accessorKey: "creation_data",
    header: "Created By",
    type: "createdBy",
    headerTooltip:
      "The Created By value is only available for resources created within the last 90 days.",
  },
  {
    accessorKey: "unused_since",
    header: "No Traffic Since",
    type: "unusedSince",
  },
  {
    accessorKey: "sku",
    header: "SKU",
  },
  {
    accessorKey: "has_backend",
    header: "Backends Empty",
    type: "hasField",
    headerTooltip:
      "Backends Empty: YES - indicates that the load balancer has no running backend resources.",
  },
  { accessorKey: "number_of_rules", header: "Rules" },
  { accessorKey: "region", header: "Region", type: "withCopy" },
  { accessorKey: "tags", header: "Tags", type: "tag", enableSorting: false },
  {
    accessorKey: "tags",
    header: "Smart Tags",
    id: "smart_tag",
    type: "smartTag",
    enableSorting: false,
  },
  {
    accessorKey: "issues",
    header: "Ticket Status",
    type: "jiraTicketStatus",
    enableSorting: false,
  },
];
