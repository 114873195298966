import { createDraftSafeSelector } from "@reduxjs/toolkit";
import { ColumnDef } from "@tanstack/react-table";
import { queryAnswerDataSelector } from "./queryAnswerDataSelector";

export const chatAnswerDataGridColumnsSelector = createDraftSafeSelector(
  [queryAnswerDataSelector],
  (data) => {
    if (!data?.length) {
      return null;
    }

    return Object.keys(data[0]).map((key) => {
      const isCost =
        key.includes("cost") || key.includes("spend") || key.includes("waste");

      const prefix = isCost ? " $" : "";

      return {
        accessorKey: key,
        header: key.replaceAll("_", " ") + prefix,
        cell: ({ cell }) => cell.getValue() as string,
      } as ColumnDef<any>;
    });
  },
);
