import { FC } from "react";
import { Stack } from "@mui/material";
import { CommitmentsOverviewCoverage } from "./components/coverage/CommitmentsOverviewCoverage";
import { CommitmentsOverviewCommitments } from "./components/commitments/CommitmentsOverviewCommitments";

export const CommitmentsOverviewContent: FC = () => {
  return (
    <Stack direction="row" spacing={1} flex={1} justifyContent={"center"}>
      <CommitmentsOverviewCoverage />
      <CommitmentsOverviewCommitments />
    </Stack>
  );
};
