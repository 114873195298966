import { FC, Fragment } from "react";
import { Tooltip, Typography } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

interface NodeCountCellProps {
  data: string;
  count?: number;
}

export const NodeCountCell: FC<NodeCountCellProps> = ({ data, count = 1 }) => {
  return (
    <Fragment>
      {data}
      {count > 1 && (
        <Fragment>
          <Typography ml={1} variant="caption" color="text.secondary">
            (Cluster)
          </Typography>
          <Tooltip
            arrow
            title="Any action taken on this resource will affect the whole cluster."
            placement="top"
          >
            <InfoOutlinedIcon
              color="action"
              fontSize="small"
              sx={{ verticalAlign: "middle", ml: 1 }}
            />
          </Tooltip>
        </Fragment>
      )}
    </Fragment>
  );
};
