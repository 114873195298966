import { ChangeEvent, FC, Fragment } from "react";
import { Grid, TextField } from "@mui/material";

interface InputFieldProps {
  type: "text" | "number";
  error?: string;
  name: string;
  label: string;
  value?: string | number;
  onChange(event: ChangeEvent<HTMLInputElement>): void;
}

export const InputField: FC<InputFieldProps> = ({
  value,
  onChange,
  name,
  label,
  type,
  error,
}) => {
  return (
    <Fragment>
      <Grid item sm={4}>
        <TextField
          fullWidth
          type={type}
          name={name}
          value={value || ""}
          size="small"
          error={!!error}
          variant="outlined"
          helperText={error}
          placeholder={label}
          onChange={onChange}
          FormHelperTextProps={{ sx: { mx: 0 } }}
        />
      </Grid>
      <Grid item sm={6} />
    </Fragment>
  );
};
