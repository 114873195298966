import { FC } from "react";
import { Box, Typography } from "@mui/material";

interface SchedulePreviewGroupTitleProps {
  title: string;
}

export const SchedulePreviewSectionTitle: FC<
  SchedulePreviewGroupTitleProps
> = ({ title }) => {
  return (
    <Box bgcolor="grey.50" py={0.5} px={2} mb={3}>
      <Typography fontWeight="bold">{title}</Typography>
    </Box>
  );
};
