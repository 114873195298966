import { useLocation } from "react-router-dom";

const pdfWidth = 1153;

export const useChartWidth = () => {
  const location = useLocation();
  const needToSetWidth = location.pathname.includes("internal");

  return needToSetWidth ? pdfWidth : "100%";
};
