import { ColDef } from "@ag-grid-community/core/dist/types/src/entities/colDef";

export const automationsAccountsSortingFn: ColDef["comparator"] = (
  valueA,
  valueB,
  nodeA,
  nodeB,
) => {
  const current = nodeA.data.accounts?.at(0)?.provider_account_name;
  const next = nodeB.data.accounts?.at(0)?.provider_account_name;

  if (!current) return 1;
  if (!next) return -1;

  return current.localeCompare(next);
};
