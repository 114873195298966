import { createAsyncThunk } from "@reduxjs/toolkit";
import { enqueueSnackbar } from "notistack";
import { cloudChiprApi } from "../../../services/cloudchipr.api";

export const getOrganizationsForCommitmentsThunk = createAsyncThunk(
  "commitments/getOrganizationsForCommitments",
  async (_: void, { dispatch }) => {
    const { getUsersMeOrganisationsCurrentProviderOrganisations } =
      cloudChiprApi.endpoints;

    try {
      await dispatch(
        getUsersMeOrganisationsCurrentProviderOrganisations.initiate(),
      ).unwrap();
    } catch (e) {
      // @ts-expect-error todo: api fix
      const errMessage = e?.data?.message || "Something went wrong";
      enqueueSnackbar(errMessage, {
        variant: "snackbarAlert",
        AlertSnackBarProps: { severity: "error" },
      });

      return null;
    }
  },
);
