import { StatusesAccumulatorType } from "../../../../../../../../../../store/recommendations/selectors/rightsizing/statuses/rightsizingRecommendationsStatusesGroupedSelector";
import { ProviderType } from "../../../../../../../../../../services/cloudchipr.api";

export const getAccountOptionDisabledData = (
  statuses: StatusesAccumulatorType,
  id: string,
  provider?: ProviderType,
) => {
  const disabled = provider && statuses[provider].disabled.includes(id);
  const missingPermission =
    provider && statuses[provider].missing_permission.includes(id);
  const inProgress = provider && statuses[provider].not_detected.includes(id);

  const warningMessage = disabled
    ? "Recommendations are not enabled for this account."
    : "Missing permissions. Please add permissions to see complete data.";

  return disabled || inProgress || missingPermission
    ? {
        type: inProgress ? "info" : "warning",
        reason: inProgress
          ? "We are currently checking permissions for rightsizing recommendations on this account. This process may take up to 24 hours."
          : warningMessage,
      }
    : false;
};
