import { red } from "@mui/material/colors";
import { Budget, DateDataPoint } from "../../../../services/cloudchipr.api";
import { moneyFormatter } from "../../../../utils/numeral/moneyFormatter";
import { ReferenceLineType } from "../../../../storybook/charts/multi-type-chart/utils/types/types";

export const generateBudgetDataOnChart = (
  viewPeriod?: DateDataPoint,
  budget?: Budget | null,
) => {
  if (!budget) {
    return null;
  }

  if (budget.period === "daily" && viewPeriod === "monthly") {
    return getLineData(budget.amount * 30);
  }

  if (budget.period === "monthly" && viewPeriod === "daily") {
    return getLineData(fixNumber(budget.amount / 30));
  }

  // quarterly budget
  if (budget.period === "quarterly" && viewPeriod === "daily") {
    return getLineData(fixNumber(budget.amount / 90));
  }

  if (budget.period === "quarterly" && viewPeriod === "monthly") {
    return getLineData(fixNumber(budget.amount / 3));
  }

  // annually budget
  if (budget.period === "annually" && viewPeriod === "daily") {
    return getLineData(fixNumber(budget.amount / 386));
  }

  if (budget.period === "annually" && viewPeriod === "monthly") {
    return getLineData(fixNumber(budget.amount / 12));
  }

  return getLineData(budget.amount);
};

const getLineData = (amount: number) =>
  [
    {
      name: "Budget",
      y: amount,
      stroke: red[400],
      strokeWidth: 2,
      legend: { type: "dashed", label: "Budget", color: red[400] },
      uniqueId: "resource_explorer_budget_reference_line",
      strokeDasharray: "4 4",
      label: {
        value: moneyFormatter(amount),
        position: "insideBottomLeft",
        fill: "red",
        fontSize: 14,
      },
    },
  ] as ReferenceLineType[];

const fixNumber = (num: number) => +num.toFixed(2);
