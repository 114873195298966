import { FC, useCallback } from "react";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../../store/hooks";
import { largestCostChangesSetupChangeThunk } from "../../../../../../../../../store/dashboards/thunks/widgets/largest-cost-changes/widget-change/largestCostChangesSetupChangeThunk";
import { largestCostChangesSetupPropertyByKeySelector } from "../../../../../../../../../store/dashboards/selectors/setups/larges-cost-changes/largestCostChangesSetupPropertyByKeySelector";
import { largestCostSortByCountValues } from "../utils/constants";

export const LargestCostChangesSortByItemsCount: FC = () => {
  const dispatch = useAppDispatch();

  const itemsCount = useAppSelector(
    largestCostChangesSetupPropertyByKeySelector("itemsCount"),
  );

  const itemsCountChangeHandler = useCallback(
    (event: any) => {
      dispatch(
        largestCostChangesSetupChangeThunk({
          itemsCount: event.target.value,
        }),
      );
    },
    [dispatch],
  );

  return (
    <FormControl size="small" fullWidth>
      <InputLabel>Count</InputLabel>
      <Select
        value={itemsCount}
        label="Count"
        onChange={itemsCountChangeHandler}
        sx={{
          flex: 1,
          "& .MuiOutlinedInput-notchedOutline": { borderColor: "grey.300" },
        }}
      >
        {largestCostSortByCountValues.map((item) => {
          return (
            <MenuItem key={item} value={item}>
              {item}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};
