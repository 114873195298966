import { FC } from "react";
import { Stack, Typography } from "@mui/material";
import EmptyTasks from "../../../../assets/images/empty_tasks.svg";

export const TasksEmptyPage: FC = () => {
  return (
    <Stack alignItems="center" pt={15}>
      <img src={EmptyTasks} alt="Empty Tasks" width={180} />
      <Typography variant="h6" fontWeight="normal">
        There aren't any tasks yet.
      </Typography>
    </Stack>
  );
};
