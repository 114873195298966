import { FC, Fragment } from "react";
import { Card, CardContent } from "@mui/material";
import { useDidMount, useEffectOnceWhen, useWillUnmount } from "rooks";
import { ExecutionLogsHeader } from "./components/header/ExecutionLogsHeader";
import { ExecutionLogsDataGrid } from "./components/data-grid/ExecutionLogsDataGrid";
import { ExecutionLogsToolbar } from "./components/ExecutionLogsToolbar";
import { useExecutionLogPathIds } from "./utils/hooks/useExecutionLogPathIds.hook";
import { resetExecutionLogs } from "../../../store/execution-log/executionLogSlice";
import { useAppDispatch } from "../../../store/hooks";
import { getExecutionLogThunk } from "../../../store/execution-log/thunks/getExecutionLogThunk";
import { getWorkflowsThunk } from "../../../store/automations/thunks/wokrflows/getWorkflowsThunk";
import { getExecutionLogByIdsThunk } from "../../../store/execution-log/thunks/getExecutionLogByIdsThunk";
import { getOffHoursThunk } from "../../../store/automations/thunks/off-hours/getOffHoursThunk";

export const ExecutionLogs: FC = () => {
  const executionLogIds = useExecutionLogPathIds();
  const dispatch = useAppDispatch();

  useEffectOnceWhen(() => {
    dispatch(getExecutionLogByIdsThunk(executionLogIds));
  }, !!executionLogIds?.length);

  useEffectOnceWhen(() => {
    dispatch(getExecutionLogThunk({}));
  }, !executionLogIds?.length);

  useWillUnmount(() => {
    dispatch(resetExecutionLogs());
  });

  useDidMount(() => {
    dispatch(getWorkflowsThunk());
    dispatch(getOffHoursThunk());
  });

  return (
    <Fragment>
      <ExecutionLogsHeader />

      <Card variant="outlined" sx={cardStyles}>
        <CardContent sx={cardContentStyles}>
          <ExecutionLogsToolbar />

          <ExecutionLogsDataGrid executionLogIds={executionLogIds} />
        </CardContent>
      </Card>
    </Fragment>
  );
};

const cardStyles = { m: 2, display: "flex" };
const cardContentStyles = {
  p: 2,
  flexGrow: 1,
  display: "flex",
  flexDirection: "column",
};
