import { createAsyncThunk } from "@reduxjs/toolkit";
import { enqueueSnackbar } from "notistack";
import { getUtilizationAlertsThunk } from "./getUtilizationAlertsThunk";
import { cloudChiprApi } from "../../../../services/cloudchipr.api";
import { deleteUtilizationAlertFixedCacheKey } from "../../utils/constants/fixedCacheKeys";

export const deleteUtilizationAlertByIdThunk = createAsyncThunk(
  "alerts/deleteAlertByIdAndType",
  async (id: string, { dispatch }) => {
    const {
      deleteUsersMeOrganisationsCurrentAlertsUtilizationAndCoverageByAlertId,
    } = cloudChiprApi.endpoints;

    try {
      await dispatch(
        deleteUsersMeOrganisationsCurrentAlertsUtilizationAndCoverageByAlertId.initiate(
          { alertId: id },
          { fixedCacheKey: deleteUtilizationAlertFixedCacheKey },
        ),
      ).unwrap();

      await dispatch(getUtilizationAlertsThunk()).unwrap();
    } catch (e) {
      // @ts-expect-error todo: fix api spec
      const errMessage = e?.data?.message || "Something went wrong";
      enqueueSnackbar(errMessage, {
        variant: "snackbarAlert",
        AlertSnackBarProps: {
          severity: "error",
        },
      });
    }
  },
);
