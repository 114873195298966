import { FC } from "react";
import { Stack, Typography } from "@mui/material";

import { SlackIntegrationGracePeriodMessageTitle } from "../../../slack/grace-period/SlackIntegrationGracePeriodMessageTitle";
import { SlackIntegrationGracePeriodMessageDescription } from "../../../slack/grace-period/SlackIntegrationGracePeriodMessageDescription";
import { SlackIntegrationGracePeriodMessageWorkflowInfo } from "../../../slack/grace-period/SlackIntegrationGracePeriodMessageWorkflowInfo";
import { SlackIntegrationDataGrid } from "../../../slack/common/SlackIntegrationDataGrid";
import { CreateIntegrationMessageEditor } from "../../../../../../../../common/integration-dialogs/components/common/CreateIntegrationMessageEditor";

interface SlackIntegrationGracePeriodDialogContentProps {
  customMessage: string;
  onCustomMessageChange(message: string): void;
}
export const SlackIntegrationGracePeriodDialogContent: FC<
  SlackIntegrationGracePeriodDialogContentProps
> = ({ customMessage, onCustomMessageChange }) => {
  return (
    <Stack spacing={1.5}>
      <SlackIntegrationGracePeriodMessageTitle />

      <SlackIntegrationGracePeriodMessageDescription />

      <SlackIntegrationGracePeriodMessageWorkflowInfo />

      <Typography fontStyle="italic" variant="caption">
        *This link is private. Please keep it confidential and do not share.
      </Typography>
      <CreateIntegrationMessageEditor
        value={customMessage ?? ""}
        onChange={onCustomMessageChange}
        placeholder="Write a custom message..."
      />

      <SlackIntegrationDataGrid />
    </Stack>
  );
};
