import { FC } from "react";
import { Card } from "@mui/material";
import { AwsAccessController } from "./components/aws-access/AwsAccessController";
import { GcpAccessController } from "./components/gcp-access/GcpAccessController";
import { AzureAccessController } from "./components/azure-access/AzureAccessController";
import viewPermissionImage from "../../../../../assets/images/resourece-explorer-permission.png";
import { ProviderType } from "../../../../../services/cloudchipr.api";

interface ProviderBillingDataAccessControllerProps {
  provider: ProviderType;
}

export const ProviderBillingDataAccessController: FC<
  ProviderBillingDataAccessControllerProps
> = ({ provider }) => {
  return (
    <Card
      variant="outlined"
      sx={{
        backgroundImage: `url(${viewPermissionImage})`,
        alignItems: "center",
        justifyContent: "center",
        display: "flex",
        minHeight: "calc(100vh - 120px)",
      }}
    >
      {provider === "aws" && <AwsAccessController provider={provider} />}
      {provider === "azure" && <AzureAccessController provider={provider} />}
      {provider === "gcp" && <GcpAccessController provider={provider} />}
    </Card>
  );
};
