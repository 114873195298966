import { FC } from "react";
import { Card, Container } from "@mui/material";
import { OffHoursGridEmptyState } from "./OffHoursGridEmptyState";
import { OffHoursDataGrid } from "./OffHoursDataGrid";
import { useGetUsersMeOffHoursSchedulesQuery } from "../../../../../../services/cloudchipr.api";
import { PageTitle } from "../../../../common/PageTitle";

export const OffHoursGrid: FC = () => {
  const { data } = useGetUsersMeOffHoursSchedulesQuery(
    {},
    { refetchOnMountOrArgChange: true },
  );

  return (
    <Container sx={{ p: 2 }} disableGutters maxWidth={false}>
      <OffHoursGridEmptyState />

      {!!data?.length && (
        <Card variant="outlined">
          <PageTitle
            title="Off Hours"
            subTitle={`${data?.length} scheduled Off Hours.`}
          />

          <OffHoursDataGrid offHours={data} />
        </Card>
      )}
    </Container>
  );
};
