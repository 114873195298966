import { createAsyncThunk } from "@reduxjs/toolkit";
import { cloudChiprApi } from "../../services/cloudchipr.api";

export const getSettingsByKeyThunk = createAsyncThunk(
  "settings/getSettings",
  async (key: string, { dispatch }) => {
    await dispatch(
      cloudChiprApi.endpoints.getUsersMeOrganisationsCurrentSettingsByKey.initiate(
        { key },
      ),
    );
  },
);
