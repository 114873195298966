import { FC, Fragment } from "react";
import { Card, Container, LinearProgress } from "@mui/material";
import { CategoriesEmptyState } from "./components/CategoriesEmptyState";
import { CategoriesGridList } from "./components/table/CategoriesGridList";
import { PageHeader } from "../common/PageHeader";
import { AddingNewCategory } from "../common/categories/category-adding/AddingNewCategory";
import { useGetUsersMeCategoriesQuery } from "../../../services/cloudchipr.api";
import { useAppAbility } from "../../../services/permissions";

export const Categories: FC = () => {
  const { data, isLoading: loading } = useGetUsersMeCategoriesQuery();
  const { cannot } = useAppAbility();
  const canNotCreateCategory = cannot("create", "category");

  return (
    <Fragment>
      <PageHeader
        title="Categories"
        actions={
          data?.length ? (
            <AddingNewCategory
              buttonTitle="Add Category Group"
              disabled={canNotCreateCategory}
            />
          ) : null
        }
      />

      {loading && <LinearProgress />}

      <Container sx={{ p: 2 }} maxWidth={false} disableGutters>
        {!loading && !data?.length ? (
          <CategoriesEmptyState />
        ) : (
          <Card variant="outlined">
            <CategoriesGridList />
          </Card>
        )}
      </Container>
    </Fragment>
  );
};
