import { GetQuickFilterTextParams } from "@ag-grid-community/core/dist/types/src/entities/colDef";
import { getUserActivityQuickFilterText } from "./getUserActivityQuickFilterText";
import { ReportsTableData } from "../../../../../../../../store/reports/utils/types/types";

export const reportsCreatedByQuickFilter = (
  params: GetQuickFilterTextParams<ReportsTableData>,
) => {
  const report = params.data;
  const details = report?.created_by;
  const date = report?.created_at;

  return getUserActivityQuickFilterText(details, date);
};
