import { FC, Fragment } from "react";
import { Stack, Typography } from "@mui/material";
import { useFlag } from "@unleash/proxy-client-react";
import { CommitmentsPageHeaderMetadataCard } from "../../../common/components/CommitmentsPageHeaderMetadataCard";
import { money } from "../../../../../../utils/numeral/money";
import { useAppSelector } from "../../../../../../store/hooks";
import { commitmentsCurrentOrgIdSelector } from "../../../../../../store/commitments/selectors/common/commitmentsCurrentOrgIdSelector";
import { useGetUsersMeOrganisationsCurrentCommitmentsPotentialSavingsQuery } from "../../../../../../services/cloudchipr.api";

export const CommitmentsOverviewPotentialSavingsMetadata: FC = () => {
  const orgId = useAppSelector(commitmentsCurrentOrgIdSelector);
  const enableReservationRecommendation = useFlag(
    "EnableReservationRecommendation",
  );
  const { data, isLoading } =
    useGetUsersMeOrganisationsCurrentCommitmentsPotentialSavingsQuery({
      providerOrganisationId: orgId,
    });

  if (!enableReservationRecommendation) {
    return null;
  }

  return (
    <Stack flex={1}>
      <CommitmentsPageHeaderMetadataCard
        primary="Potential Savings"
        loading={isLoading}
        valueColor="success.light"
        value={
          data?.savings_amount !== null
            ? `~${money(data?.savings_amount)}`
            : "N/A"
        }
        tooltip={
          <Fragment>
            <Typography variant="caption" fontWeight="medium">
              Total amount of potential savings based on Commitments
              Recommendations.
            </Typography>
            <li>
              SP Recommendations: {money(data?.savings_plan_total_amount)}
            </li>
            <li>
              RI Recommendations: {money(data?.reservations_total_amount)}
            </li>
          </Fragment>
        }
      />
    </Stack>
  );
};
