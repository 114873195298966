import { FC, Fragment } from "react";
import { Button, Chip } from "@mui/material";
import { ScheduleSendOutlined } from "@mui/icons-material";
import { useDidMount, useToggle } from "rooks";
import { ReportsBySourceDrawer } from "./ReportsBySourceDrawer";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { reportsTableFilteredDataBySourceLengthSelector } from "../../../../store/reports/selector/reportsTableFilteredDataBySourceLengthSelector";
import { Report } from "../../../../services/cloudchipr.api";
import { setReportsData } from "../../../../store/reports/reportsSlice";
import { getAllReportsLoadingSelector } from "../../../../store/reports/selector/getAllReportsLoadingSelector";

interface ReportsBySourceButtonProps {
  sourceId: string;
  sourceType: Report["source_type"];
}

export const ReportsBySourceButton: FC<ReportsBySourceButtonProps> = ({
  sourceId,
  sourceType,
}) => {
  const dispatch = useAppDispatch();
  const [openDrawer, toggleOpenDrawer] = useToggle(false);
  const length = useAppSelector(reportsTableFilteredDataBySourceLengthSelector);
  const loading = useAppSelector(getAllReportsLoadingSelector);

  useDidMount(() => {
    dispatch(
      setReportsData({
        source_type: sourceType,
        source_id: sourceId,
      }),
    );
  });

  return (
    <Fragment>
      <Button
        variant="outlined"
        size="small"
        onClick={toggleOpenDrawer}
        disabled={loading}
        startIcon={<ScheduleSendOutlined />}
      >
        <Chip
          size="small"
          label={length ?? 0}
          sx={{
            bgcolor: loading ? "grey.100" : "primary.light",
            color: loading ? "text.secondary" : "primary.main",
          }}
        />
      </Button>

      <ReportsBySourceDrawer
        sourceId={sourceId}
        open={openDrawer}
        sourceType={sourceType}
        toggleOpen={toggleOpenDrawer}
      />
    </Fragment>
  );
};
