import { reservationsRecommendationsSelector } from "./reservationsRecommendationsSelector";
import { RootState } from "../../../../store";

export const reservationsRecommendationsLoadingSelector = (
  state: RootState,
) => {
  const response = reservationsRecommendationsSelector(state);

  return response?.isLoading;
};
