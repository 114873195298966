import { createAsyncThunk } from "@reduxjs/toolkit";
import { WidgetCostBreakdownAggregation } from "../../../../../../services/cloudchipr.api";
import { RootState } from "../../../../../store";
import { costBreakdownWidgetSetupSelector } from "../../../../selectors/setups/cost-breakdown/costBreakdownWidgetSetupSelector";
import { setWidgetSetup } from "../../../../dashboardsSlice";

export const costBreakdownViewAggregationsChangeThunk = createAsyncThunk(
  "dashboards/costBreakdownViewAggregationsChange",
  async (
    aggregation: WidgetCostBreakdownAggregation,
    { dispatch, getState },
  ) => {
    const state = getState() as RootState;
    const existingSetup = costBreakdownWidgetSetupSelector(state);

    if (!existingSetup) {
      return;
    }

    dispatch(
      setWidgetSetup({
        ...existingSetup,
        aggregation: {
          ...existingSetup.aggregation,
          ...aggregation,
        },
      }),
    );
  },
);
