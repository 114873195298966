import { createAsyncThunk } from "@reduxjs/toolkit";
import { enqueueSnackbar } from "notistack";
import moment from "moment";
import { RootState } from "../../store";
import { cloudChiprApi } from "../../../services/cloudchipr.api";
import { step0FormDataSelector } from "../selectors/current-budget/forms/step0FormDataSelector";
import {
  formatDate,
  MomentDateTimeUnitsType,
} from "../../../utils/helpers/date-time/datetime-format";
import { budgetPeriodUnits } from "../../../components/pages/budgets/components/budget-create/steps/steps/utils/constants/date-selection";
import { resourceExplorerDataByViewIdFixedCacheKeyGenerator } from "../selectors/current-budget/chart/resourceExplorerDataByViewIdSelector";
import { currentBudgetViewIdSelector } from "../selectors/current-budget/data-selectors/currentBudgetViewIdSelector";
import { selectedViewSelector } from "../selectors/current-budget/view/selectedViewSelector";
import { resourceExplorerDefaultData } from "../../../components/pages/resource-explorer/components/resource-explorer-card/utils/constants/common";

export const getResourceExplorerDataByBudgetThunk = createAsyncThunk(
  "budgets/getResourceExplorerDataByBudget",
  async (_: void, { dispatch, getState }) => {
    const state = getState() as RootState;
    const viewId = currentBudgetViewIdSelector(state);
    const { postUsersMeOrganisationsCurrentResourceExplorer } =
      cloudChiprApi.endpoints;

    const budgetData = step0FormDataSelector(state);
    const selectedView = selectedViewSelector(state);

    const dataPoint = budgetData?.period === "daily" ? "daily" : "monthly";

    const unit = budgetData?.period
      ? (budgetPeriodUnits[budgetData?.period] as MomentDateTimeUnitsType)
      : "month";
    const dateTo = formatDate(moment(), { type: "yearMonthDay" });
    const dateFrom = formatDate(moment().subtract(5, unit), {
      type: "yearMonthDay",
    });

    try {
      await dispatch(
        postUsersMeOrganisationsCurrentResourceExplorer.initiate(
          {
            resourceExplorerFilterSchema: {
              group_by:
                selectedView?.data?.grouping ??
                resourceExplorerDefaultData.grouping,
              group_values: selectedView?.data?.group_values,
              data_point: dataPoint,
              date_to: dateTo,
              date_from: dateFrom,
            },
          },
          {
            fixedCacheKey:
              resourceExplorerDataByViewIdFixedCacheKeyGenerator(viewId),
          },
        ),
      );
    } catch (e) {
      // @ts-expect-error //todo: remove this when backend will add types
      enqueueSnackbar(e?.data?.message ?? "Something went wrong", {
        variant: "snackbarAlert",
        AlertSnackBarProps: {
          severity: "error",
        },
      });
    }
  },
);
