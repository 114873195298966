import {
  ResourceExplorerFilterProvider,
  ResourceExplorerGrouping,
} from "../../../../../../../services/cloudchipr.api";
import { nextGroupingByCurrentGroupingAndProvider } from "../constants/groupings/nextGroupingByCurrentGroupingAndProvider";

export const getNextGroupingByCurrentGroupingAndProvider = (
  provider: ResourceExplorerFilterProvider,
  currentGrouping: ResourceExplorerGrouping,
): ResourceExplorerGrouping => {
  if (!currentGrouping) {
    return null;
  }

  const nextGrouping =
    nextGroupingByCurrentGroupingAndProvider[provider]?.[currentGrouping];

  return nextGrouping ?? currentGrouping;
};
