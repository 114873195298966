import { resourceExplorerViewByIdSelector } from "./resourceExplorerViewByIdSelector";
import { RootState } from "../../../store";

export const resourceExplorerViewByIdLoadingSelector = (
  state: RootState,
  viewId?: string,
) => {
  if (!viewId) {
    return;
  }
  return !!resourceExplorerViewByIdSelector(state, viewId)?.isLoading;
};
