import { FC, useCallback } from "react";
import {
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  TableCell,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import AddIcon from "@mui/icons-material/Add";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { DeleteCategoryGroupConfirmDialog } from "./DeleteCategoryGroupConfirmDialog";
import { DuplicateCategoryGroupDialog } from "./DuplicateCategoryGroupDialog";
import { CategoryFormDialog } from "../../../../common/categories/category-adding/CategoryFormDialog";
import { useDialog } from "../../../../../../utils/hooks/useDialog.hook";
import { useMenuHook } from "../../../../../../utils/hooks/useMenu.hook";
import { AccountCategoryWithCost } from "../../../../../../services/cloudchipr.api";
import { useAppAbility } from "../../../../../../services/permissions";

interface CategoryActionsCellProps {
  name: string;
  categories: AccountCategoryWithCost[];
}

export const CategoryActionsCell: FC<CategoryActionsCellProps> = ({
  name,
  categories,
}) => {
  const { anchor, open, openMenu, closeMenu } = useMenuHook();
  const { cannot } = useAppAbility();
  const canNotDeleteCategory = cannot("delete", "category");
  const canNotEditCategory = cannot("edit", "category");
  const {
    open: removeOpen,
    openDialog: removeOpenHandler,
    closeDialog: removeCloseHandler,
  } = useDialog();

  const {
    open: createOpen,
    openDialog: createOpenHandler,
    closeDialog: createCloseHandler,
  } = useDialog();

  const {
    open: duplicateOpen,
    openDialog: duplicateOpenDialog,
    closeDialog: duplicateCloseDialog,
  } = useDialog();

  const closeHandler = useCallback(() => {
    closeMenu();
    createCloseHandler();
    removeCloseHandler();
    duplicateCloseDialog();
  }, [closeMenu, createCloseHandler, removeCloseHandler, duplicateCloseDialog]);

  return (
    <TableCell sx={{ textAlign: "end" }}>
      <IconButton size="small" onClick={openMenu} sx={{ p: 0 }}>
        <MoreVertIcon />
      </IconButton>

      <Menu open={open} onClose={closeMenu} anchorEl={anchor}>
        <MenuItem
          dense
          onClick={createOpenHandler}
          disabled={canNotEditCategory}
        >
          <ListItemIcon>
            <AddIcon />
          </ListItemIcon>

          <ListItemText>Add Category</ListItemText>
        </MenuItem>
        <MenuItem dense onClick={duplicateOpenDialog}>
          <ListItemIcon>
            <ContentCopyIcon />
          </ListItemIcon>

          <ListItemText>
            <ListItemText>Duplicate</ListItemText>
          </ListItemText>
        </MenuItem>
        <MenuItem
          dense
          onClick={removeOpenHandler}
          disabled={canNotDeleteCategory}
        >
          <ListItemIcon>
            <DeleteOutlineIcon />
          </ListItemIcon>

          <ListItemText>
            <ListItemText>Delete</ListItemText>
          </ListItemText>
        </MenuItem>
      </Menu>

      {createOpen && (
        <CategoryFormDialog open defaultName={name} onClose={closeHandler} />
      )}

      <DuplicateCategoryGroupDialog
        open={duplicateOpen}
        name={name}
        onClose={closeHandler}
      />

      <DeleteCategoryGroupConfirmDialog
        open={removeOpen}
        name={name}
        categoryIds={categories.map(({ id }) => id)}
        onClose={closeHandler}
      />
    </TableCell>
  );
};
