import { createAsyncThunk } from "@reduxjs/toolkit";
import { setWidgetSetup } from "../../../../dashboardsSlice";
import { RootState } from "../../../../../store";
import { ChartType } from "../../../../../../services/cloudchipr.api";
import { costBreakdownWidgetSetupSelector } from "../../../../selectors/setups/cost-breakdown/costBreakdownWidgetSetupSelector";
import { costBreakdownWidgetDefaultData } from "../../../../utils/constants/costBreakdownSetupDefaultData";
import { getCostBreakdownWidgetSetupDataThunk } from "../getCostBreakdownWidgetSetupDataThunk";

export const costBreakdownViewTypeChangeThunk = createAsyncThunk(
  "dashboards/costBreakdownViewTypeChange",
  async (viewType: ChartType, { dispatch, getState }) => {
    const state = getState() as RootState;
    const existingSetup = costBreakdownWidgetSetupSelector(state);

    if (!existingSetup) {
      return;
    }
    dispatch(
      setWidgetSetup({
        ...existingSetup,
        //TODO: EnableWidgetsSideBarConfigurations, remove viewType
        viewType,
        visualization: {
          ...costBreakdownWidgetDefaultData.visualization,
          //TODO change chart_type and set other settings to default
          chart_type: viewType,
        },
        frequency: viewType === "pie" ? "monthly" : existingSetup.frequency,
      }),
    );
    dispatch(getCostBreakdownWidgetSetupDataThunk());
  },
);
