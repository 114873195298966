import { createAsyncThunk } from "@reduxjs/toolkit";
import { enqueueSnackbar } from "notistack";
import { RootState } from "../../../../store";
import { cloudChiprApi } from "../../../../../services/cloudchipr.api";
import { resourceExplorerPayloadDataSelector } from "../../../selectors/current-resource-explorer/resource-explorer-widget/resourceExplorerPayloadDataSelector";
import { resourceExplorerDataGridViewSelector } from "../../../selectors/current-resource-explorer/resourceExplorerDataGridViewSelector";
import { resourceExplorerPathLastItemFilterTreeSelector } from "../../../selectors/current-resource-explorer/path/resourceExplorerPathLastItemFilterTreeSelector";
import { addFilterTreePathToResourceExplorerPayload } from "../../../utils/helpers/addFilterTreePathToResourceExplorerPayload";

export const resourceExplorerCSVDataFixedCacheKey =
  "postUsersMeOrganisationsCurrentWidgetsResourceExplorerDownloadCsv-fixed-cache-key";

export const resourceExplorerCSVDataHashGeneratorThunk = createAsyncThunk(
  "resourceExplorer/resourceExplorerCSVDataHashGenerator",
  async (_: void, { dispatch, getState }) => {
    const state = getState() as RootState;
    const payload = resourceExplorerPayloadDataSelector(state);
    const pathLastItemFilterTree =
      resourceExplorerPathLastItemFilterTreeSelector(state);
    const dataGridView = resourceExplorerDataGridViewSelector(state);

    const resourceExplorerFilterSchema = pathLastItemFilterTree
      ? addFilterTreePathToResourceExplorerPayload(
          payload,
          pathLastItemFilterTree,
        )
      : payload;

    const {
      postUsersMeOrganisationsCurrentWidgetsResourceExplorerDownloadCsv,
      postUsersMeOrganisationsCurrentResourceExplorerGranularCostsDownloadCsv,
    } = cloudChiprApi.endpoints;

    const endpoint =
      dataGridView === "granular"
        ? postUsersMeOrganisationsCurrentResourceExplorerGranularCostsDownloadCsv
        : postUsersMeOrganisationsCurrentWidgetsResourceExplorerDownloadCsv;

    try {
      const response = await dispatch(
        endpoint.initiate(
          { resourceExplorerFilterSchema },
          { fixedCacheKey: resourceExplorerCSVDataFixedCacheKey },
        ),
      ).unwrap();

      return response.hash;
    } catch (e) {
      // @ts-expect-error //todo: remove this when backend will add types
      enqueueSnackbar(e?.data?.message ?? "Something went wrong", {
        variant: "snackbarAlert",
        autoHideDuration: 1000,
        AlertSnackBarProps: {
          severity: "error",
        },
      });
    }
  },
);
