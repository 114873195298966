import { FC } from "react";
import { IconButton, Link, Stack, Tooltip } from "@mui/material";
import { PlayCircleOutlined } from "@mui/icons-material";
import { useHover } from "../../../../../../../../../../../utils/hooks/useHover.hook";
import { ResourceExplorerGridDataWithId } from "../../../../../../../../../../../store/resource-explorer/utils/types/resourceExplorerDataGrid";
import { TypographyWithCopy } from "../../../../../../../../../../common/TypographyWithCopy";

interface ResourceExplorerTableAccountCellProps {
  data: ResourceExplorerGridDataWithId;
}

export const ResourceExplorerTableAccountCell: FC<
  ResourceExplorerTableAccountCellProps
> = ({ data }) => {
  const { ref, hovered } = useHover();
  const metadata = data.metadata;
  const title =
    metadata?.provider_account_name ?? metadata?.provider_account_id ?? "-";

  return (
    <Stack
      ref={ref}
      spacing={1}
      direction="row"
      overflow="hidden"
      alignItems="center"
    >
      <TypographyWithCopy
        overrideHovered={hovered}
        value={title}
        hideCopyButtonArea
      />

      {hovered && data.accountExistInLiveUsage && (
        <Tooltip title="View in Live Usage and Mgmt." placement="top" arrow>
          <IconButton
            component={Link}
            href={`/${data.cloud_provider}/live-usage/?accountIds=${metadata.live_account_id}`}
            target="_blank"
            size="small"
          >
            <PlayCircleOutlined color="action" fontSize="small" />
          </IconButton>
        </Tooltip>
      )}
    </Stack>
  );
};
