import { FC } from "react";
import { CircularProgress, Stack } from "@mui/material";
import { CreateBudgetButton } from "./CreateBudgetButton";
import { ResourceExplorerBudget } from "./ResourceExplorerBudget";
import { useAppSelector } from "../../../../../../../../../store/hooks";
import { resourceExplorerIdSelector } from "../../../../../../../../../store/resource-explorer/selectors/current-resource-explorer/resourceExplorerIdSelector";
import { resourceExplorerViewBudgetSelector } from "../../../../../../../../../store/resource-explorer/selectors/budget/resourceExplorerViewBudgetSelector";
import { resourceExplorerByIdLoadingSelector } from "../../../../../../../../../store/resource-explorer/selectors/resource-explorer-by-id/resourceExplorerByIdLoadingSelector";

export const ResourceExplorerBudgets: FC = () => {
  const viewId = useAppSelector(resourceExplorerIdSelector);

  const data = useAppSelector(resourceExplorerViewBudgetSelector);
  const isLoading = useAppSelector((state) =>
    resourceExplorerByIdLoadingSelector(state, viewId),
  );

  const content = data ? (
    <ResourceExplorerBudget />
  ) : (
    <CreateBudgetButton viewId={viewId} />
  );

  return <Stack>{isLoading ? <CircularProgress /> : content}</Stack>;
};
