import { FC } from "react";
import { Stack, Typography } from "@mui/material";
import { ConfirmationDialog } from "../../../../../../common/modals/ConfirmationDialog";
import { NavigationItemsVisibilityType } from "../../../../../../../store/navigation/utils/types";
import { useAppSelector } from "../../../../../../../store/hooks";
import { updateDashboardVisibilityHierarchyLoadingSelector } from "../../../../../../../store/dashboards/selectors/dashboard-hierarchy-visibility/updateDashboardVisibilityHierarchyLoadingSelector";

interface DashboardV2FolderMoveActionMenuItemProps {
  name: string;
  onClose(): void;
  onMove(): Promise<void>;
  visibility: NavigationItemsVisibilityType;
}

export const DashboardV2FolderMoveActionMenuConfirmationDialog: FC<
  DashboardV2FolderMoveActionMenuItemProps
> = ({ onMove, onClose, visibility, name }) => {
  const loading = useAppSelector(
    updateDashboardVisibilityHierarchyLoadingSelector,
  );

  return (
    <ConfirmationDialog
      open
      title="Move Folder?"
      dialogContent={
        <Stack spacing={3}>
          {visibility === "visible_only_to_me" && (
            <Typography variant="inherit">
              Changing the folder visibility will also change the visibility of
              all dashboards inside. Changing the dashboard visibility to{" "}
              <Typography variant="inherit" fontWeight="bold" component="span">
                Visible to everyone
              </Typography>{" "}
              will deactivate any widgets linked to your{" "}
              <Typography variant="inherit" fontWeight="bold" component="span">
                Visible only to me
              </Typography>{" "}
              views. This action cannot be undone. This action cannot be undone.
            </Typography>
          )}

          <Typography variant="inherit">
            Please confirm if you want to move the{" "}
            <Typography variant="inherit" fontWeight="bold" component="span">
              {name}
            </Typography>{" "}
            folder.
          </Typography>
        </Stack>
      }
      CancelButtonProps={{
        onClick: onClose,
      }}
      ConfirmButtonProps={{
        children: "Move",
        onClick: onMove,
        loading,
      }}
      onClose={onClose}
    />
  );
};
