import { RootState } from "../../../store";
import {
  Dashboard,
  DashboardFolder,
} from "../../../../services/cloudchipr.api";
import { currentDashboardHierarchyVisibilitiesDataSelector } from "../dashboard-hierarchy-visibility/currentDashboardHierarchyVisibilitiesDataSelector";

export const dashboardHierarchyDataSelector = (
  state: RootState,
): (Dashboard | DashboardFolder)[] | undefined => {
  return currentDashboardHierarchyVisibilitiesDataSelector(state);
};
