import { createAsyncThunk } from "@reduxjs/toolkit";
import { enqueueSnackbar } from "notistack";
import { RootState } from "../../../../../store";
import { cloudChiprApi } from "../../../../../../services/cloudchipr.api";
import { costAndUsageSumSetupWidgetPayloadDataSelector } from "../../../../selectors/setups/cost-and-usage/costAndUsageSumSetupWidgetPayloadDataSelector";
import { viewNotFoundSelector } from "../../../../../resource-explorer/selectors/all-resource-explorers/viewNotFoundSelector";

export const getCostAndUsageSumWidgetDataBySetupThunk = createAsyncThunk(
  "dashboards/getCostAndUsageSumWidgetDataBySetup",
  async (_, { dispatch, getState }) => {
    const state = getState() as RootState;

    const { getUsersMeOrganisationsCurrentWidgetsCostAndUsageSummary } =
      cloudChiprApi.endpoints;
    const payload = costAndUsageSumSetupWidgetPayloadDataSelector(state);

    if (!payload?.viewId) {
      return;
    }

    const viewNotFound = viewNotFoundSelector(state, payload.viewId);

    if (viewNotFound) {
      return;
    }

    try {
      return await dispatch(
        getUsersMeOrganisationsCurrentWidgetsCostAndUsageSummary.initiate(
          payload,
        ),
      ).unwrap();
    } catch (e) {
      // @ts-expect-error todo: api fix
      const errMessage = e?.data?.message || "Something went wrong";
      enqueueSnackbar(errMessage, {
        variant: "snackbarAlert",
        AlertSnackBarProps: { severity: "error" },
      });
    }
  },
);
