import { FC, Fragment, useCallback } from "react";
import {
  Checkbox,
  FormControlLabel,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { useToggle } from "rooks";
import { Schedule } from "../../../../../../../services/cloudchipr.api";

const defaultThreshold = 250;

interface AutomationNotificationThresholdProps {
  minThreshold?: Schedule["min_threshold"];
  onThresholdChange(threshold: number | null): void;
  error?: string;
}

export const AutomationNotificationThreshold: FC<
  AutomationNotificationThresholdProps
> = ({ minThreshold, onThresholdChange, error }) => {
  const [checked, toggleChecked] = useToggle(!!minThreshold);

  const thresholdToggleHandler = useCallback(
    (e: any, enabled: any) => {
      toggleChecked();

      onThresholdChange(enabled ? defaultThreshold : null);
    },
    [toggleChecked, onThresholdChange],
  );

  const thresholdChangeHandler = useCallback(
    (e: any) => {
      onThresholdChange(e.target.value);
    },
    [onThresholdChange],
  );

  return (
    <Stack direction="row" alignItems="center" gap={1} pl={1}>
      <FormControlLabel
        control={
          <Checkbox
            size="small"
            checked={checked}
            onChange={thresholdToggleHandler}
          />
        }
        label={
          <Typography variant="body2" color="text.secondary">
            Set minimum threshold
          </Typography>
        }
        sx={{ mr: 0, flexShrink: 0 }}
      />
      <Tooltip
        arrow
        placement="top"
        title="Receive notifications when costs exceed the defined threshold."
      >
        <InfoOutlinedIcon fontSize="small" color="info" />
      </Tooltip>
      {checked && (
        <Fragment>
          <TextField
            error={!!error}
            size="xsmall"
            name="min_threshold"
            placeholder={`${minThreshold ?? defaultThreshold}`}
            label="Amount ($)"
            sx={{ width: 150 }}
            value={minThreshold}
            onChange={thresholdChangeHandler}
            type="number"
            inputProps={{ min: 1, step: 1 }}
          />
          {error && (
            <Typography color="error" variant="caption">
              {error}
            </Typography>
          )}
        </Fragment>
      )}
    </Stack>
  );
};
