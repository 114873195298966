import { RootState } from "../../../store";
import {
  cloudChiprApi,
  ProviderType,
} from "../../../../services/cloudchipr.api";
import { AccountTabs } from "../../../../components/pages/accounts-group/utils/types/types";
import { accountsSortBySelector } from "../slice-data/accountsSortBySelector";
import { getAccountsSortBySettingsKey } from "../../../../components/pages/live-usage-mgmt/utils/hooks/useAccountsSortBy.hook";

const selector =
  cloudChiprApi.endpoints.getUsersMeCurrentPreferenceByKey.select;

export const accountsSortByLoadingSelector = (
  state: RootState,
  provider: ProviderType,
  tabValue: AccountTabs,
  orgId?: string,
) => {
  const sortBy = accountsSortBySelector(state);
  const key = getAccountsSortBySettingsKey(provider, tabValue, orgId);

  const data = selector({ key })(state);

  return data?.error || sortBy ? false : data.isLoading;
};
