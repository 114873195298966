import { FC } from "react";
import { ExecutionLogsFilters } from "./filters/ExecutionLogsFilters";
import { useAppSelector } from "../../../../../store/hooks";
import { executionLogDataLoadingSelector } from "../../../../../store/execution-log/selectors/executionLogDataLoadingSelector";
import { PageHeader } from "../../../common/PageHeader";

export const ExecutionLogsHeader: FC = () => {
  const isLoading = useAppSelector(executionLogDataLoadingSelector);

  return (
    <PageHeader
      sticky
      breadcrumbs={[
        {
          title: "Execution Logs",
        },
      ]}
      extra={<ExecutionLogsFilters />}
      loading={isLoading}
    />
  );
};
