import { FC, useCallback } from "react";
import { Typography, Box } from "@mui/material";
import { UnusedSinceCell } from "./UnusedSinceCell";
import { UnusedSince } from "../../../../../../../../../../services/cloudchipr.api";

interface StateUnusedSinceCellProps {
  data: UnusedSince;
}

export const StateWithUnusedSinceCell: FC<StateUnusedSinceCellProps> = ({
  data,
}) => {
  const addPrefix = useCallback(
    (title: string) => `Last detached: ${title}`,
    [],
  );

  return (
    <Box>
      <Typography variant="body2">
        {data?.is_used ? "In-use" : "Available"}
      </Typography>
      {!data.is_used && (
        <UnusedSinceCell
          data={data}
          variant="caption"
          textFormatter={addPrefix}
        />
      )}
    </Box>
  );
};
