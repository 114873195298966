import { userRoleDataSelector } from "./userRoleDataSelector";
import { userRoleDataPermissionsGroupsSelector } from "./userRoleDataPermissionsGroupsSelector";
import { RootState } from "../../../store";

export const userRoleDataValidationSelector = (state: RootState): boolean => {
  const creationData = userRoleDataSelector(state);
  const groups = userRoleDataPermissionsGroupsSelector(state);
  const isAnyPermissionSelected = groups.some((group) =>
    creationData.permissions?.[group].data.some((perm) => perm.selected),
  );

  return creationData.name.trim().length === 0 || !isAnyPermissionSelected;
};
