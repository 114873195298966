import { FC } from "react";
import { TableCell } from "@mui/material";
import { TypographyWithTooltip } from "../../../../../common/TypographyWithTooltip";

interface TableNameCellProps {
  name: string;
  subRow?: boolean;
}

export const TableNameCell: FC<TableNameCellProps> = ({ name, subRow }) => {
  const styleProps = subRow
    ? { color: "text.secondary" }
    : { fontWeight: "bold" };

  return (
    <TableCell sx={{ whiteSpace: "nowrap", py: 2, width: 350, minWidth: 350 }}>
      <TypographyWithTooltip title={name} maxWidth={250} {...styleProps} />
    </TableCell>
  );
};
