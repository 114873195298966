import { Plan } from "../../../../services/cloudchipr.api";

export const enterprisePlan: Plan = {
  id: "",
  name: "Enterprise",
  name_key: "enterprise",
  price: NaN,
  yearly: true,
  most_popular: false,
  details: {
    monthly_cloud_spend_range: "$100,000 +",
    options: [
      {
        title: "Unlimited Accounts",
        availability: true,
      },
      {
        title: "Check Every 5m",
        availability: true,
      },
      {
        title: "Unlimited Integrations",
        availability: true,
      },
      {
        title: "Scheduled Run",
        availability: true,
      },
      {
        title: "Auto Clean",
        availability: true,
      },
      {
        title: "Anomaly Detection",
        availability: true,
      },
      {
        title: "Team Access",
        availability: true,
      },
      {
        title: "Organization SSO",
        availability: true,
      },
      {
        title: "Dedicated SRE consultant",
        availability: true,
      },
    ],
  },
};
