import { FC } from "react";
import { Button, Stack } from "@mui/material";
import { LoadingButton } from "@mui/lab";

interface WidgetSetupHeaderActionsProps {
  action: "create" | "update";
  disabled?: boolean;
  onAdd(): void;
  onCancel(): void;
  loading: boolean;
}

export const WidgetSetupHeaderActions: FC<WidgetSetupHeaderActionsProps> = ({
  onCancel,
  onAdd,
  loading,
  disabled,
  action,
}) => {
  return (
    <Stack direction="row" spacing={2} justifyContent="space-between">
      <Button color="tertiary" onClick={onCancel}>
        Cancel
      </Button>

      <LoadingButton
        disabled={disabled}
        loading={loading}
        variant="contained"
        onClick={onAdd}
      >
        {action === "create" ? "Add" : "Save"}
      </LoadingButton>
    </Stack>
  );
};
