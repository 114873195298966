import { FC } from "react";
import {
  AccountLight,
  ResourceType,
} from "../../../../../../../../../../services/cloudchipr.api";
import { AccountInfoData } from "../../../../../../../../../common/AccountInfoData";

interface AccountCellProps {
  data: AccountLight;
  providerLink?: string;
  resourceType?: ResourceType;
  linkTooltipTitle?: string;
  liveUsageIcon?: boolean;
}
export const AccountCell: FC<AccountCellProps> = ({
  data,
  providerLink,
  resourceType,
  linkTooltipTitle,
  liveUsageIcon,
}) => {
  return (
    <AccountInfoData
      fontSize={14}
      liveUsageIcon={liveUsageIcon}
      provider={data.provider}
      accessType={data.provider_access_type}
      accountName={data.provider_account_name ?? data.provider_account_id}
      link={`/${data.provider}/${data.id}?rt=${resourceType}`}
      providerLink={providerLink}
      linkTooltipTitle={linkTooltipTitle}
    />
  );
};
