import { FC, ReactNode, useCallback } from "react";
import {
  Card,
  CardActionArea,
  CardContent,
  FormControlLabel,
  lighten,
  Radio,
  RadioProps,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { indigo } from "@mui/material/colors";

interface ToggleBoxItemProps {
  value: string;
  tooltip?: string;
  label: ReactNode;
  checked: boolean;
  disabled: boolean;
  description?: ReactNode;
  size?: RadioProps["size"];
  onChange?(value: string): void;
}

export const ToggleBoxItem: FC<ToggleBoxItemProps> = ({
  value,
  label,
  checked,
  description,
  onChange,
  disabled,
  tooltip,
  size,
}) => {
  const handleClick = useCallback(() => {
    if (!disabled && onChange) {
      onChange(value);
    }
  }, [onChange, value, disabled]);

  const checkedStyles = checked
    ? { borderColor: "primary.light", bgcolor: lighten(indigo["50"], 0.5) }
    : {};

  return (
    <Card
      variant="outlined"
      onClick={handleClick}
      sx={{ borderRadius: 1, ...checkedStyles }}
    >
      <Tooltip arrow title={tooltip ?? ""}>
        <CardActionArea>
          <CardContent sx={size === "small" ? { p: 1, px: 0.5 } : undefined}>
            <FormControlLabel
              disabled={disabled}
              sx={{ m: 0 }}
              control={<Radio size={size} />}
              checked={checked}
              label={
                <Stack justifyContent="space-between">
                  <Typography variant="subtitle2" gutterBottom={false}>
                    {label}
                  </Typography>
                  <Typography variant="caption" color="text.secondary">
                    {description}
                  </Typography>
                </Stack>
              }
            />
          </CardContent>
        </CardActionArea>
      </Tooltip>
    </Card>
  );
};
