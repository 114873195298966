import { ResourceType } from "../../../../services/cloudchipr.api";

export const resourceTypeShortNames: Map<
  ResourceType,
  { singular: string; plural: string }
> = new Map([
  //AWS --------------------------------------------------------------------
  ["ec2", { singular: "Instance", plural: "Instances" }],
  ["asg", { singular: "ASG", plural: "ASGs" }],
  ["asg_ec2", { singular: "Instance", plural: "Instances" }],
  ["ebs", { singular: "Volume", plural: "Volumes" }],
  ["ebs_snapshot", { singular: "Snapshot", plural: "Snapshots" }],
  ["eip", { singular: "IP", plural: "IPs" }],
  ["elb", { singular: "Load Balancer", plural: "Load Balancers" }],
  ["s3", { singular: "Bucket", plural: "Buckets" }],
  ["rds", { singular: "Database", plural: "Databases" }],
  ["rds_snapshot", { singular: "Snapshot", plural: "Snapshots" }],
  ["rds_snapshot_source", { singular: "Snapshot", plural: "Snapshots" }],
  ["eks", { singular: "Cluster", plural: "Clusters" }],
  ["eks_ec2", { singular: "Node", plural: "Nodes" }],
  ["node_group", { singular: "Node Group", plural: "Node Groups" }],
  ["elc", { singular: "ElastiCache", plural: "ElastiCache" }],
  ["dynamo_db_table", { singular: "Table", plural: "Tables" }],
  ["dax_cluster", { singular: "DAX Cluster", plural: "DAX Clusters" }],
  ["dynamo_db_backup", { singular: "Backup", plural: "Backups" }],
  ["nat_gateway", { singular: "NAT Gateway", plural: "NAT Gateways" }],
  ["ecs", { singular: "Cluster", plural: "Clusters" }],
  ["ecs_workload", { singular: "Workload", plural: "Workloads" }],

  // GCP --------------------------------------------------------------------
  ["vm", { singular: "Virtual Machine", plural: "Virtual Machines" }],
  ["mig", { singular: "MIG", plural: "MIGs" }],
  ["mig_vm", { singular: "Instance", plural: "Instances" }],
  ["disk", { singular: "Disk", plural: "Disks" }],
  ["ip", { singular: "External IP", plural: "External IPs" }],
  ["lb", { singular: "Load Balancer", plural: "Load Balancers" }],
  ["sql", { singular: "Database", plural: "Databases" }],
  ["gke", { singular: "Cluster", plural: "Clusters" }],
  ["node_pool", { singular: "Node Pool", plural: "Node Pools" }],
  ["gke_vm", { singular: "Node", plural: "Nodes" }],

  // AZURE --------------------------------------------------------------------
  ["az_vm", { singular: "Virtual Machine", plural: "Virtual Machines" }],
  ["az_disk", { singular: "Disk", plural: "Disks" }],
  ["az_ip", { singular: "Public IP", plural: "Public IPs" }],
  ["az_lb", { singular: "Load Balancer", plural: "Load Balancers" }],
  ["az_sql", { singular: "Database", plural: "Databases" }],
  ["az_vmss", { singular: "VM Scale Set", plural: "VM Scale Sets" }],
  ["az_vmss_vm", { singular: "Instance", plural: "Instances" }],
  ["az_aks", { singular: "Cluster", plural: "Clusters" }],
  ["az_node_pool", { singular: "Node Pool", plural: "Node Pools" }],
  ["az_aks_vm", { singular: "Node", plural: "Nodes" }],
]);
