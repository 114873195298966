import { savingPlansCoverageRecommendationsSelector } from "./savingPlansCoverageRecommendationsSelector";
import { RootState } from "../../../../../store";
import { SavingsPlanRecommendation } from "../../../../../../services/cloudchipr.api";

export const savingPlansCoverageRecommendationsDataSelector = (
  state: RootState,
): SavingsPlanRecommendation[] | undefined => {
  const selector = savingPlansCoverageRecommendationsSelector(state);
  return selector?.data;
};
