import { FC } from "react";
import { Box, Stack, Tooltip, TooltipProps, Typography } from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { TypographyWithTooltip } from "../../../../../common/TypographyWithTooltip";
import { CopyIconButton } from "../../../../../common/CopyIconButton";

interface CategoryNameCellProps {
  name: string;
  categoryId: string;
}

export const CategoryNameCell: FC<CategoryNameCellProps> = ({
  categoryId,
  name,
}) => {
  return (
    <Tooltip
      placement="right"
      slotProps={slotProps}
      title={
        <Stack spacing={0.5}>
          <Typography variant="body2" fontWeight={500}>
            {name}
          </Typography>

          <Stack direction="row" spacing={0.5} alignItems="center">
            <Typography
              variant="caption"
              whiteSpace="nowrap"
              color="text.secondary"
            >
              ID: {categoryId}
            </Typography>

            <CopyIconButton data={categoryId} sx={{ width: 20, height: 20 }}>
              <ContentCopyIcon sx={{ fontSize: 14 }} />
            </CopyIconButton>
          </Stack>
        </Stack>
      }
    >
      <Box width="fit-content">
        <TypographyWithTooltip
          title={name}
          width="fit-content"
          variant="subtitle1"
          fontWeight="medium"
          color="text.secondary"
        />
      </Box>
    </Tooltip>
  );
};

const slotProps: TooltipProps["slotProps"] = {
  tooltip: {
    sx: {
      p: 1,
      minWidth: 280,
      bgcolor: (theme) => theme.palette.common.white,
      color: (theme) => theme.palette.text.primary,
      boxShadow: (theme) => theme.shadows.at(1),
    },
  },
};
