import { resourceExplorerGranularTotalCostsSelector } from "./resourceExplorerGranularTotalCostsSelector";
import { RootState } from "../../../../../store";

export const resourceExplorerGranularTotalCostByKeySelector = (
  state: RootState,
  key: string,
) => {
  const totals = resourceExplorerGranularTotalCostsSelector(state);

  return totals?.[key];
};
