import { FC } from "react";
import { Chip, Typography } from "@mui/material";
import { money } from "../../../../../../../../../../utils/numeral/money";

interface TotalCostChipCellProps {
  data: string | number;
}

export const totalCostChipCellParentStyles = {
  width: "100%",
  textAlign: "end",
};

export const TotalCostChipCell: FC<TotalCostChipCellProps> = ({ data }) => {
  return (
    <Chip
      label={
        <Typography variant="inherit">
          Total Monthly Cost:{" "}
          <Typography variant="inherit" fontWeight="bold" component="span">
            {money(data)}
          </Typography>
        </Typography>
      }
      variant="outlined"
    />
  );
};
