import { noOrgAccountOrgId } from "../../../components/pages/accounts-group/utils/helpers/noOrganizationConstants";
import { getProviderName } from "../../../utils/helpers/providers/getProviderName";
import { Account, ProviderType } from "../../../services/cloudchipr.api";
import { AccountOptionType } from "../../../components/common/account-select/utils/types/types";
import { sortByAlphanumericForAccountOptionType } from "../../../components/common/account-select/utils/helpers/sorting/sortByAlphanumericForAccountOptionType";
import { AccountGroupedByProviderAndOrgType } from "../utils/types/types";

export const accountSelectionOptionsCombiner = (
  accounts: AccountGroupedByProviderAndOrgType | null,
): AccountOptionType[] => {
  if (!accounts) {
    return [];
  }

  return Object.entries(accounts)
    .map(([key, accountGroups]) => {
      const provider = key as ProviderType;

      return generateAccountSelectionOptions(accountGroups, provider);
    })
    .flat();
};

const generateAccountSelectionOptions = (
  accounts: Record<string, Account[]> | null,
  provider: ProviderType,
): AccountOptionType[] => {
  let accountSelectionOptions: AccountOptionType[][] = [];

  if (!accounts) {
    return [];
  }

  const orgIds = Object.keys(accounts);

  accountSelectionOptions = orgIds.map((orgId) => {
    const orgAccounts = accounts[orgId];

    const options = orgAccounts.map((account) => {
      let groupName;

      const providerName = getProviderName(provider, {
        uppercase: true,
        title: true,
      });

      if (orgId !== noOrgAccountOrgId) {
        groupName = `${providerName} Organization: ${account.group_id}`;
      } else {
        const multipleOrgsExist = orgIds.length > 1;

        if (multipleOrgsExist) {
          groupName = `Other ${getProviderName(provider, {
            plural: true,
            capitalize: true,
            nameAndTitle: true,
          })}`;
        } else {
          groupName = getProviderName(provider, {
            plural: true,
            uppercase: true,
            nameAndTitle: true,
          });
        }
      }

      return {
        value: account.id,
        orgId: account.group_id || noOrgAccountOrgId,
        label:
          account.provider_account_name || `ID: ${account.provider_account_id}`,
        accessType: account.access_type,
        providerAccountId: account.provider_account_id ?? "",
        accountType: account.type,
        disabled: account.status === "deactivated",
        status: account.status,
        groupName,
        warning: !account.has_satisfied_permissions,
      };
    });

    return sortByAlphanumericForAccountOptionType(options);
  });

  return accountSelectionOptions.flat();
};
