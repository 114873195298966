import { FC } from "react";
import { ResourceType } from "../../../../../services/cloudchipr.api";
// AWS
import ec2Selected from "../../../../../assets/images/logos/resources/aws/ec2_selected.svg";
import eksSelected from "../../../../../assets/images/logos/resources/aws/eks_selected.svg";
import elcSelected from "../../../../../assets/images/logos/resources/aws/elc_selected.svg";
import rdsSelected from "../../../../../assets/images/logos/resources/aws/rds_selected.svg";
import s3Selected from "../../../../../assets/images/logos/resources/aws/s3_selected.svg";
import dynamoDbSelected from "../../../../../assets/images/logos/resources/aws/dynamoDb_selected.svg";
import natGatewaySelected from "../../../../../assets/images/logos/resources/aws/nat_gateway_selected.svg";
import ecsSelected from "../../../../../assets/images/logos/resources/aws/ecs_selected.svg";
// GCP
import vmSelected from "../../../../../assets/images/logos/resources/gcp/vm_selected.svg";
import ipSelected from "../../../../../assets/images/logos/resources/gcp/network_selected.svg";
import sqlSelected from "../../../../../assets/images/logos/resources/gcp/sql_selected.svg";
import gkeSelected from "../../../../../assets/images/logos/resources/gcp/gke_selected.svg";
// Azure
import azLbSelected from "../../../../../assets/images/logos/resources/azure/az_lb_selected.svg";
import azVmSelected from "../../../../../assets/images/logos/resources/azure/az_vm_selected.svg";
import azSqlSelected from "../../../../../assets/images/logos/resources/azure/az_sql_selected.svg";
import azDiskSelected from "../../../../../assets/images/logos/resources/azure/az_disk_selected.svg";
import azIpSelected from "../../../../../assets/images/logos/resources/azure/az_ip_selected.svg";
import azVmssSelected from "../../../../../assets/images/logos/resources/azure/az_vmss_selected.svg";
import azAksSelected from "../../../../../assets/images/logos/resources/azure/az_aks_selected.svg";

interface IconByResourceTypeProps {
  resourceType: ResourceType;
}

const icons: Record<string, string> = {
  // AWS
  ec2: ec2Selected,
  eks: eksSelected,
  elc: elcSelected,
  rds: rdsSelected,
  s3: s3Selected,
  dynamo_db_table: dynamoDbSelected,
  nat_gateway: natGatewaySelected,
  ecs: ecsSelected,
  // GCP
  vm: vmSelected,
  sql: sqlSelected,
  gke: gkeSelected,
  ip: ipSelected,
  // AZURE
  az_lb: azLbSelected,
  az_vm: azVmSelected,
  az_sql: azSqlSelected,
  az_disk: azDiskSelected,
  az_ip: azIpSelected,
  az_vmss: azVmssSelected,
  az_aks: azAksSelected,
};

// TODO: Deprecated
export const IconByResourceType: FC<IconByResourceTypeProps> = ({
  resourceType,
}) => {
  return <img src={icons[resourceType]} alt={resourceType} />;
};
