import { FC } from "react";
import { Typography } from "@mui/material";
import { WidgetOptionCard } from "../../WidgetOptionCard";
import Cover from "../../../../utils/assets/images/widget-covers/live_usage.svg";

export const LiveUsageCard: FC = () => {
  return (
    <WidgetOptionCard
      title="Live usage"
      description="Understand your current spending pattern and discover opportunities for optimization."
      disabledAction={
        <Typography
          variant="caption"
          color="white"
          align="center"
          display="block"
        >
          Coming soon.
        </Typography>
      }
    >
      <img src={Cover} alt="ConstAndUsageCard" />
    </WidgetOptionCard>
  );
};
