import { RootState } from "../../../../store";
import { defaultFilterTemplateFiltersByAccountIdSelector } from "../../../../filters/selectors/filter-set/default/defaultFilterTemplateFiltersByAccountIdSelector";
import { automationProviderSelector } from "../fields/automationProviderSelector";

export const allDefaultFiltersForAutomationSelector = (state: RootState) => {
  const provider = automationProviderSelector(state);

  if (!provider) {
    return;
  }

  return defaultFilterTemplateFiltersByAccountIdSelector(state, provider);
};
