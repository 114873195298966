import {
  GetUsersMeOrganisationsCurrentDismissedResourcesApiResponse,
  ProviderType,
} from "../../../../../services/cloudchipr.api";
import {
  TagsFilter,
  TagsFilterSet,
} from "../../../../../components/common/select/tag-filter-dropdown-select/utils/types";
import { filterRecommendationByTagsFilter } from "../../../utils/helpers/filterRecommendationByTagsFilter";

export const recommendationsDismissedResourcesDataByFiltersCombiner = (
  data: GetUsersMeOrganisationsCurrentDismissedResourcesApiResponse | undefined,
  providers: ProviderType[],
  types: string[],
  accounts: string[],
  tags: {
    combinationOperator: TagsFilter["combinationOperator"];
    filter: TagsFilterSet;
  },
) => {
  return data?.filter((value) => {
    const provider = value?.account?.provider;
    if (!!providers.length && provider && !providers.includes(provider)) {
      return false;
    }

    if (!!types.length && !types.includes(value.resource_type)) {
      return false;
    }
    const existsByTagFilter = filterRecommendationByTagsFilter(
      tags,
      value?.tags ?? [],
    );

    if (!existsByTagFilter) {
      return false;
    }

    const accountId = value?.account?.id ?? "";

    return accounts.length ? accounts.includes(accountId) : true;
  });
};
