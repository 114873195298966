import {
  FilterItemWithType,
  ResourceFilters,
} from "../../services/cloudchipr.api";

export const addRegionsToFilters = (
  regions: string[] | null,
  filters: ResourceFilters,
): ResourceFilters => {
  let filter = { ...filters };

  if (regions && filter) {
    const regionFilter: FilterItemWithType = {
      type: filter.type,
      filter: { key: "region", operator: "in", value: regions },
    };

    const regionFilterIndex = filter?.filter_items.length;

    filter = {
      ...filter,
      operators: ["and", ...filter.operators],
      filter_items: [...filter.filter_items, regionFilter],
    };

    if (filter.filter_groups.length) {
      filter.filter_groups = [filter.filter_groups, regionFilterIndex];
    } else {
      filter.filter_groups = [regionFilterIndex];
    }
  }

  return filter;
};
