import { FilterFn } from "@tanstack/react-table";
import { normalizeString } from "./common";
import { money } from "../../../../utils/numeral/money";

export const moneyFilter: FilterFn<any> = (row, columnId, value) => {
  return commonMoneyFilter(columnId, value, row.getValue(columnId));
};

export const commonMoneyFilter = (
  columnId: string,
  value: string,
  data: string,
) => {
  const val = normalizeString(value);
  return money(normalizeString(data)).includes(val);
};
