import { RootState } from "../../../store";
import { taskManagementTaskIdSelector } from "../form/properties/taskManagementTaskIdSelector";
import { tasksListDataByIdSelector } from "../data/tasksListDataByIdSelector";
import { taskManagementTaskSavingPayloadSelector } from "../form/payload/taskManagementTaskSavingPayloadSelector";

export const changesExistOnTaskEditSelector = (state: RootState) => {
  const editingTaskId = taskManagementTaskIdSelector(state);
  const payload = taskManagementTaskSavingPayloadSelector(state);

  const task = tasksListDataByIdSelector(state, editingTaskId);

  return (
    compareProps(task?.status?.id, payload?.status_id) ||
    compareProps(task?.name, payload?.name) ||
    compareProps(task?.due_date, payload?.due_date) ||
    compareProps(task?.action?.id, payload?.action_id) ||
    compareProps(task?.priority?.id, payload?.priority_id) ||
    compareProps(task?.environment?.id, payload?.environment_id) ||
    compareProps(task?.description, payload?.description) ||
    compareProps(
      task?.assignees
        ?.map(({ id }) => id)
        ?.toSorted()
        ?.join(""),
      payload?.assignees?.toSorted()?.join(""),
    )
  );
};

const compareProps = (prop1: unknown, prop2: unknown): boolean => {
  return (prop1 ?? null) !== (prop2 ?? null);
};
