import {
  ChartBaseType,
  CommitmentsGroupResponse,
} from "../../../../../../../services/cloudchipr.api";
import { chartDataPointKey } from "../../../../../../../storybook/charts/multi-type-chart/utils/constants/constants";
import { ChartDataType } from "../../../../../../../storybook/charts/multi-type-chart/utils/types/types";

export const generateCommitmentUtilizationWidgetChartData = (
  groups?: CommitmentsGroupResponse[],
  chartBase?: ChartBaseType,
) => {
  return groups?.map((group) => ({
    [chartDataPointKey]: group?.name ?? "",
    Utilization: Number(
      (chartBase === "cost"
        ? group?.utilization?.amount
        : group?.utilization?.percentage) ?? 0,
    ),
    Waste: Number(
      (chartBase === "cost"
        ? group?.waste?.amount
        : group?.waste?.percentage) ?? 0,
    ),
  })) as unknown as ChartDataType[] | undefined;
};
