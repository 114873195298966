import { DashboardMixedVisibilitiesItemType } from "../../../../components/pages/dashboard-all-items/components/table/utils/types/types";
import { ReMixedVisibilitiesItemType } from "../../../../components/pages/resource-explorer-all-views/components/table/utils/types/types";
import { isHierarchyFolder } from "../../../navigation/utils/helpers/isHierarchyFolder";
import { VisibilityType } from "../../../../components/pages/common/hierarchy-all-items/utils/types/types";

export const filterHierarchyMixedVisibilitiesDataByVisibilityAndCreatedBy = <
  T extends
    | DashboardMixedVisibilitiesItemType[]
    | ReMixedVisibilitiesItemType[]
    | null,
>(
  data: T,
  visibilities?: VisibilityType[],
  createdBy?: string[],
) => {
  const dataFilteredByVisibility = data?.filter(
    (item) => visibilities?.includes(item.visibility) || !visibilities?.length,
  );

  return dataFilteredByVisibility?.filter((item) => {
    let itemName = item?.created_by?.name ?? item?.created_by?.email ?? "";
    itemName = itemName.includes("deleted") ? "Deleted user" : itemName;

    if (!isHierarchyFolder(item)) {
      return createdBy?.includes(itemName) || !createdBy?.length;
    }

    return (
      item?.items?.some((childItem) => {
        const childItemName =
          childItem?.created_by?.name ?? childItem?.created_by?.email ?? "";

        return createdBy?.includes(childItemName) || !createdBy?.length;
      }) ||
      createdBy?.includes(itemName) ||
      !createdBy?.length
    );
  }) as T;
};
