import { rightsizingRecommendationsFilteredDataSelector } from "./rightsizingRecommendationsFilteredDataSelector";
import { rightsizingRecommendationsDismissedDataSelector } from "./rightsizingRecommendationsDismissedDataSelector";
import { RootState } from "../../../../store";
import { Tag } from "../../../../../services/cloudchipr.api";
import { getResourceTypeName } from "../../../../../utils/helpers/resources/getResourceTypeName";
import { formatDate } from "../../../../../utils/helpers/date-time/datetime-format";

export const rightsizingRecommendationsCSVDataSelector = (
  state: RootState,
  dismissed?: boolean,
) => {
  const filteredData = dismissed
    ? rightsizingRecommendationsDismissedDataSelector(state)
    : rightsizingRecommendationsFilteredDataSelector(state);

  return (
    filteredData?.map(
      ({
        account,
        current_vcpus,
        current_machine_type,
        recommended_vcpus,
        recommended_machine_type,
        resource_type,
        tags,
        region,
        resource_name,
        potential_saving,
        spending,
        cpu_max,
        dismissed_by,
        dismiss_until_date,
      }) => {
        const row = {
          "Instance ID": resource_name,
          Name: tags.find(({ key }) => key === "Name")?.value,
          "Account ID": account.provider_account_id,
          "Account Name": account.provider_account_name,
          "Current Type": current_machine_type,
          "Current vCPU": current_vcpus,
          "Recommended Type": recommended_machine_type,
          "Recommended vCPU": recommended_vcpus,
          "Rightsize to Save": potential_saving,
          "Resource Type": getResourceTypeName(resource_type, {
            singular: true,
            type: "long",
          }),
          "Current Costs": spending,
          "Recommended Costs": spending - potential_saving,
          "CPU % (Maximum CPU utilization for last 14 days)": cpu_max,
          TAGs: tags
            ?.map((tag: Tag) => `${tag?.key}:${tag?.value}`)
            .join(" , "),
          Region: region,
        };

        if (dismissed) {
          return {
            ...row,
            "Dismissed By": dismissed_by,
            "Will be restored on": dismiss_until_date
              ? formatDate(dismiss_until_date, {
                  type: "dateTime",
                })
              : "",
          };
        }

        return row;
      },
    ) || []
  );
};
