import { FC, Fragment } from "react";
import { Typography } from "@mui/material";

export const AccessToAccountSetupHeader: FC = () => {
  return (
    <Fragment>
      <Typography variant="body1" fontWeight="medium" pb={1}>
        Choose preferred setup method
      </Typography>
      <Typography variant="body2" color="text.secondary" pb={1}>
        Cloudchipr will begin collecting data immediately after connecting to
        the cloud provider. No actions will be taken on cloud resources during
        this process.
      </Typography>
    </Fragment>
  );
};
