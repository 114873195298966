import { ChangeEvent, FC, Fragment, useEffect } from "react";
import { CircularProgress, Grid, Stack, Typography } from "@mui/material";
import { FieldComponentByFieldType } from "./FieldComponentByFieldType";
import { usePostUsersMeJiraIntegrationsByIntegrationIdProjectsAndProjectIdIssueTypesIssueTypeFieldsMutation } from "../../../../../../../../services/cloudchipr.api";
import { RowLabelColumn } from "../../RowLabelColumn";
import { jiraCustomFieldsFetchFixedCacheKey } from "../../../utils/constants/fixedCacheKeys";

interface UserDefinedCustomRowsV2Props {
  integrationId: string;
  projectKey: string;
  issueType: string;
  values: Record<string, any>;
  errors: Record<string, any>;
  onChange(event: ChangeEvent<any>): void;
  setFieldValue(key: string, value: any): void;
}

export const UserDefinedCustomRowsV2: FC<UserDefinedCustomRowsV2Props> = ({
  values,
  integrationId,
  onChange,
  setFieldValue,
  errors,
  issueType,
  projectKey,
}) => {
  const [fetchFields, { data, isLoading }] =
    usePostUsersMeJiraIntegrationsByIntegrationIdProjectsAndProjectIdIssueTypesIssueTypeFieldsMutation(
      { fixedCacheKey: jiraCustomFieldsFetchFixedCacheKey },
    );

  useEffect(() => {
    if (integrationId && projectKey && issueType) {
      fetchFields({
        integrationId,
        projectId: projectKey,
        issueType,
      });
    }
  }, [fetchFields, integrationId, projectKey, issueType]);

  if (!integrationId || !projectKey || !issueType) {
    return null;
  }

  if (isLoading) {
    return (
      <Fragment>
        <Grid item sm={2} />

        <Grid item sm={10}>
          <Stack direction="row" alignItems="center">
            <CircularProgress size={24} />
            <Typography variant="subtitle1" color="text.secondary" ml={3}>
              Fetching fields ...
            </Typography>
          </Stack>
        </Grid>
      </Fragment>
    );
  }

  return (
    <Fragment>
      {data?.map((customField: any) => {
        const key = customField.key as string;

        return (
          <Fragment key={key}>
            <RowLabelColumn label={customField.name} required />

            <FieldComponentByFieldType
              error={errors.body?.fields?.[key] ?? ""}
              onChange={onChange}
              integrationId={integrationId}
              customField={customField}
              setFieldValue={setFieldValue}
              value={values.fields[key]}
            />
          </Fragment>
        );
      })}
    </Fragment>
  );
};
