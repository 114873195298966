import { LinearProgress, Stack } from "@mui/material";
import { Fragment } from "react";
import { RenderToolbar } from "../../../../../../../../storybook/data-grid/utils/types/prop-types";
import { useAppSelector } from "../../../../../../../../store/hooks";
import { reservationsRecommendationsCSVDataSelector } from "../../../../../../../../store/commitments/selectors/recommendations/reservations/reservationsRecommendationsCSVDataSelector";
import { ReservationsDataGridViewTabs } from "../../data-grid/components/ReservationsDataViewTabs";
import { reservationsRecommendationsLoadingSelector } from "../../../../../../../../store/commitments/selectors/recommendations/reservations/reservationsRecommendationsLoadingSelector";
import { CommitmentsRecommendationsFilters } from "../../../../../utilization-and-coverage/components/content/recommendations/filters/CommitmentsRecommendationsFilters";
import { GlobalFilter } from "../../../../../../../../storybook/data-grid/toolbar/GlobalFilter";
import { CommitmentsRecommendationsCsvDataDownloader } from "../../../../../common/components/data-grid/CommitmentsRecommendationsCsvDataDownloader";
import { ColumnsShowHideSwitcher } from "../../../../../common/components/data-grid/ColumnsShowHideSwitcher";

export const ReservationsRecommendationsDataGridToolbar: RenderToolbar<
  unknown
> = ({ setGlobalFilter, globalFilter, table }) => {
  const loading = useAppSelector(reservationsRecommendationsLoadingSelector);

  const csvData = useAppSelector(reservationsRecommendationsCSVDataSelector);

  return (
    <Fragment>
      <Stack pb={2} spacing={2}>
        <ReservationsDataGridViewTabs />
        <Stack
          justifyContent="space-between"
          alignItems="center"
          direction="row"
          gap={2}
          flexWrap="wrap"
        >
          <CommitmentsRecommendationsFilters type="reservation" />
          <Stack direction="row" spacing={1} alignItems="center">
            <GlobalFilter
              globalFilter={globalFilter}
              setGlobalFilter={setGlobalFilter}
              size="xsmall"
            />

            <CommitmentsRecommendationsCsvDataDownloader data={csvData} />

            <ColumnsShowHideSwitcher table={table} />
          </Stack>
        </Stack>
      </Stack>

      {loading && <LinearProgress />}
    </Fragment>
  );
};
