import { FC } from "react";
import { Stack, Typography } from "@mui/material";

import { SlackIntegrationActionExecutedTitle } from "./SlackIntegrationActionExecutedTitle";
import { SlackIntegrationActionExecutedSubTitle } from "./SlackIntegrationActionExecutedSubTitle";
import { SlackIntegrationActionExecutedDescription } from "./SlackIntegrationActionExecutedDescription";
import { SlackIntegrationActionExecutedWorkflowInfo } from "./SlackIntegrationActionExecutedWorkflowInfo";
import { SlackIntegrationDataGrid } from "../common/SlackIntegrationDataGrid";

interface SlackIntegrationActionExecutedProps {
  message: string;
}

export const SlackIntegrationActionExecuted: FC<
  SlackIntegrationActionExecutedProps
> = ({ message }) => {
  return (
    <Stack spacing={1.5}>
      <SlackIntegrationActionExecutedTitle />

      <SlackIntegrationActionExecutedSubTitle />

      <SlackIntegrationActionExecutedDescription />

      <SlackIntegrationActionExecutedWorkflowInfo />

      <Typography
        variant="body1"
        dangerouslySetInnerHTML={{ __html: message }}
      />

      <SlackIntegrationDataGrid />
    </Stack>
  );
};
