import { FC } from "react";
import { CostAnomalyPreviewTableHeaderCell } from "./CostAnomalyPreviewTableHeaderCell";
import { money } from "../../../../../../../../../../../utils/numeral/money";
import { useAppSelector } from "../../../../../../../../../../../store/hooks";
import { alertsCostAnomalyDataTimeIntervalSelector } from "../../../../../../../../../../../store/alerts/selectors/cost-anomaly-alerts/data/alertsCostAnomalyDataTimeIntervalSelector";
import { alertsCostAnomalyDataTimeIntervalUnitSelector } from "../../../../../../../../../../../store/alerts/selectors/cost-anomaly-alerts/data/alertsCostAnomalyDataTimeIntervalUnitSelector";
import { costAnomalyPreviewDataGridDataSumsSelector } from "../../../../../../../../../../../store/alerts/selectors/cost-anomaly-alerts/costAnomalyPreviewDataGridDataSumsSelector";
import { costAnomalyPreviewDatesFromTimeIntervalSelector } from "../../../../../../../../../../../store/alerts/selectors/cost-anomaly-alerts/costAnomalyPreviewDatesFromTimeIntervalSelector";

export const CostAnomalyPreviewTotalCostHeaderCell: FC = () => {
  const timeInterval = useAppSelector(
    alertsCostAnomalyDataTimeIntervalSelector,
  );
  const timeIntervalUnit = useAppSelector(
    alertsCostAnomalyDataTimeIntervalUnitSelector,
  );
  const dataSums = useAppSelector(costAnomalyPreviewDataGridDataSumsSelector);
  const { dateFrom, dateTo } = useAppSelector(
    costAnomalyPreviewDatesFromTimeIntervalSelector,
  );

  const unit =
    Number(timeInterval) === 1
      ? timeIntervalUnit?.slice(0, -1)
      : timeIntervalUnit;

  return (
    <CostAnomalyPreviewTableHeaderCell
      title="Total cost"
      description={money(dataSums?.totalCostSum)}
      tooltipTitle={`Total cost for last ${timeInterval} ${unit} ${dateFrom} to ${dateTo}`}
    />
  );
};
