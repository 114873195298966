import { generateCostAndUsageSumFilterTypeData } from "./generateCostAndUsageSumFilterTypeData";
import {
  ForecastedCostRe,
  GetUsersMeOrganisationsCurrentWidgetsCostAndUsageSummaryApiResponse,
  GetUsersMeOrganisationsCurrentWidgetsCostAndUsageSummaryByWidgetIdApiResponse,
} from "../../../../../../../services/cloudchipr.api";
import { CostAndUsageDataGridType } from "../../../../../../../components/pages/dashboard/components/adding-widget/widget-create/widget-setups/cost-and-usage/content/data-grid/utils/types";
import { calculateTotalCostTrend } from "../../../../../../../components/pages/utils/helpers/calculateTotalCostTrend";
import { forecastedCostOptionLabels } from "../../../../../../../components/pages/resource-explorer/components/resource-explorer-card/components/resource-explorer-metadata/components/utils/constants";

export const generateCostAndUsageSumDataGridData = (
  data:
    | GetUsersMeOrganisationsCurrentWidgetsCostAndUsageSummaryApiResponse
    | GetUsersMeOrganisationsCurrentWidgetsCostAndUsageSummaryByWidgetIdApiResponse,
): CostAndUsageDataGridType[] => {
  const costAndUsageSumDataGridData: CostAndUsageDataGridType[] = [];

  costAndUsageSumDataGridData.push(
    generateCostAndUsageSumFilterTypeData({
      costAndUsageSumWidgetFilterType: "total_cost",
      label: "Total Cost",
      cost: data.total_cost,
      trend: data.previous_period_total_cost
        ? calculateTotalCostTrend(
            data.total_cost ?? 0,
            data.previous_period_total_cost ?? 0,
          )
        : null,
      currentDatesFrom: data.total_cost_date_details.from_date,
      currentDatesTo: data.total_cost_date_details.to_date,
      previousDatesFrom: data.previous_period_cost_date_details.from_date,
      previousDatesTo: data.previous_period_cost_date_details.to_date,
    }),
  );

  costAndUsageSumDataGridData.push(
    generateCostAndUsageSumFilterTypeData({
      costAndUsageSumWidgetFilterType: "previous_period_total_cost",
      label: "Previous Period Cost",
      cost: data.previous_period_total_cost,
      trend: null,
    }),
  );

  if (data.average_monthly_cost_details.average_monthly_cost !== null) {
    costAndUsageSumDataGridData.push(
      generateCostAndUsageSumFilterTypeData({
        costAndUsageSumWidgetFilterType: "average_monthly_cost_details",
        label: "Average Monthly Cost",
        cost: data.average_monthly_cost_details.average_monthly_cost,
        trend: data.average_monthly_cost_details.trend,
        currentDatesFrom: data.average_monthly_cost_details.from_date,
        currentDatesTo: data.average_monthly_cost_details.to_date,
        previousDatesFrom: data.previous_period_cost_date_details.from_date,
        previousDatesTo: data.previous_period_cost_date_details.to_date,
      }),
    );
  }

  if (data.average_daily_cost_details.average_daily_cost !== null) {
    costAndUsageSumDataGridData.push(
      generateCostAndUsageSumFilterTypeData({
        costAndUsageSumWidgetFilterType: "average_daily_cost_details",
        label: "Average Daily Cost",
        cost: data.average_daily_cost_details.average_daily_cost,
        trend: data.average_daily_cost_details.trend,
        currentDatesFrom: data.average_daily_cost_details.from_date,
        currentDatesTo: data.average_daily_cost_details.to_date,
        previousDatesFrom: data.previous_period_cost_date_details.from_date,
        previousDatesTo: data.previous_period_cost_date_details.to_date,
      }),
    );
  }
  costAndUsageSumDataGridData.push(
    ...generateForecastedCostsData(data?.forecasted_costs),
  );

  costAndUsageSumDataGridData.push(
    generateCostAndUsageSumFilterTypeData({
      costAndUsageSumWidgetFilterType: "live_usage_total_cost",
      label: "Live Filtered",
      cost: data.live_usage_total_cost,
      trend: null,
    }),
  );

  return costAndUsageSumDataGridData;
};

const generateForecastedCostsData = (
  forecastedCosts?: ForecastedCostRe,
): CostAndUsageDataGridType[] => {
  const monthEndForecast = forecastedCosts?.find(
    (item) => item.forecast_option === "month",
  );
  const quarterEndForecast = forecastedCosts?.find(
    (item) => item.forecast_option === "quarter",
  );

  return [
    generateCostAndUsageSumFilterTypeData({
      costAndUsageSumWidgetFilterType: "forecasted_month_end_cost",
      label: `Forecasted ${forecastedCostOptionLabels.get("month")} Cost`,
      cost: monthEndForecast?.cost ?? null,
      trend: monthEndForecast?.trend,
      currentDatesFrom: monthEndForecast?.date_from,
      currentDatesTo: monthEndForecast?.date_to,
      previousDatesFrom: monthEndForecast?.previous_period_from,
      previousDatesTo: monthEndForecast?.previous_period_to,
    }),
    generateCostAndUsageSumFilterTypeData({
      costAndUsageSumWidgetFilterType: "forecasted_quarter_end_cost",
      label: `Forecasted ${forecastedCostOptionLabels.get("quarter")} Cost`,
      cost: quarterEndForecast?.cost ?? null,
      trend: quarterEndForecast?.trend,
      currentDatesFrom: quarterEndForecast?.date_from,
      currentDatesTo: quarterEndForecast?.date_to,
      previousDatesFrom: quarterEndForecast?.previous_period_from,
      previousDatesTo: quarterEndForecast?.previous_period_to,
    }),
  ];
};
