import { allExpanded, defaultStyles, JsonView } from "react-json-view-lite";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Stack,
  Typography,
} from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { enqueueSnackbar } from "notistack";
import CopyToClipboard from "react-copy-to-clipboard";
import { FC, Fragment, MouseEvent, useCallback, useEffect } from "react";
import { DialogTitleClosable } from "../../../../common/dialog-utils/DialogTitleClosable";
import CFNIcon from "../../../../../assets/images/icons/aws_cloudformation.svg";
import { useDialog } from "../../../../../utils/hooks/useDialog.hook";
import "react-json-view-lite/dist/index.css";
import {
  ProviderAccessType,
  usePostProvidersAwsCfnTemplatesMutation,
} from "../../../../../services/cloudchipr.api";
import { useAppSelector } from "../../../../../store/hooks";
import {
  awsCfnStackFixedCacheKeyGenerator,
  awsCfnStackJsonSelector,
} from "../../../../../store/account-setup/selectors/awsCfnStackJsonSelector";

interface CFNStackProps {
  accessType: ProviderAccessType;
}

export const CFNStack: FC<CFNStackProps> = ({ accessType }) => {
  const { open, openDialog, closeDialog } = useDialog();
  const json = useAppSelector((state) =>
    awsCfnStackJsonSelector(state, accessType),
  );

  const [trigger] = usePostProvidersAwsCfnTemplatesMutation({
    fixedCacheKey: awsCfnStackFixedCacheKeyGenerator(accessType),
  });

  const handleCopyClick = useCallback(() => {
    enqueueSnackbar("CFN Stack Copied", {
      variant: "snackbarAlert",
      AlertSnackBarProps: { severity: "info" },
      autoHideDuration: 1000,
    });
  }, []);

  const handleTitleClick = useCallback(
    (e: MouseEvent<HTMLElement>) => {
      e.preventDefault();
      openDialog();
    },
    [openDialog],
  );

  useEffect(() => {
    if (!accessType || json) {
      return;
    }

    trigger({ accessType });
  }, [trigger, accessType, json]);

  if (!json) {
    return null;
  }

  return (
    <Fragment>
      <Button
        variant="outlined"
        size="small"
        onClick={handleTitleClick}
        sx={buttonStyles}
        startIcon={<img src={CFNIcon} alt="CFN stack" />}
      >
        CFN Stack
      </Button>

      <Dialog open={open} fullWidth maxWidth="md" onClose={closeDialog}>
        <DialogTitleClosable title="View CFN stack" onClose={closeDialog} />

        <DialogContent dividers sx={{ py: 3 }}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="body1">
              CFN stack
              <Typography
                variant="inherit"
                component="span"
                color="text.secondary"
              >
                {" "}
                (JSON)
              </Typography>
            </Typography>

            <CopyToClipboard text={json} onCopy={handleCopyClick}>
              <Button startIcon={<ContentCopyIcon />} variant="outlined">
                Copy JSON
              </Button>
            </CopyToClipboard>
          </Stack>

          <Box
            height={400}
            overflow="auto"
            mt={2}
            bgcolor="grey.200"
            borderRadius={2}
          >
            <JsonView
              data={JSON.parse(json)}
              shouldExpandNode={allExpanded}
              style={defaultStyles}
            />
          </Box>
        </DialogContent>

        <DialogActions sx={{ py: 2, px: 3 }}>
          <Button onClick={closeDialog} color="tertiary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

const buttonStyles = {
  borderRadius: 2,
  fontWeight: "medium",
  textTransform: "none",
  fontSize: 10,
  color: "primary.main",
  "& .MuiButton-startIcon": {
    mr: 0.5,
  },
};
