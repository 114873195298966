import { currentV2OpenCleanProcessSelector } from "./currentV2OpenCleanProcessSelector";
import { cleanV2ActionTypeSelector } from "./store-selectors/cleanV2ActionTypeSelector";
import { RootState } from "../../store";
import { getFirstParentInHierarchyResourceType } from "../../../utils/helpers/resources/getFirstParentInHierarchyResourceType";
import { CleanResourcesDataType } from "../../../components/pages/common/clean/clean-dialog/utils/types";
import { liveUsageMgmtResourceTypeDataWithChildrenSelector } from "../../live-usage-mgmt/selectors/resource-type-data/liveUsageMgmtResourceTypeDataWithChildrenSelector";

export const cleanV2ResourcesSelector = (
  state: RootState,
): CleanResourcesDataType | null => {
  const currentProcess = currentV2OpenCleanProcessSelector(state);
  if (!currentProcess?.resourceType) {
    return null;
  }

  const resources = liveUsageMgmtResourceTypeDataWithChildrenSelector(
    state,
    getFirstParentInHierarchyResourceType(currentProcess?.resourceType) ??
      currentProcess?.resourceType,
  );

  const resourcesForClean = new Set(
    currentProcess?.resources?.map((r) => r.id),
  );

  const cleanActionType = cleanV2ActionTypeSelector(state);

  if (!resources) {
    return null;
  }

  return resources
    .filter(({ resource }) => resourcesForClean.has(resource.id))
    .map(({ resource }) => ({
      ...resource,
      cleanAction: cleanActionType || "delete",
    }));
};
