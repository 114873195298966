import { formatNotificationsFromHtmlToSlack } from "../../../../../utils/helpers/formatNotificationsFromHtmlToSlack";
import { generateAutomationPayloadData } from "../../../../schedule/common/utils/helpers/common";
import {
  PatchUsersMeSchedulesByScheduleIdApiArg,
  ResourceFiltersWithAction,
  Schedule,
  ScheduleActionType,
} from "../../../../../../services/cloudchipr.api";
import { WorkflowFormData } from "../types/types";
import { getCronFrequency } from "../../../../../../store/automations/utils/helpers/cron/getCronFrequency";
import { getStartsOnDate } from "../../../../schedule/common/utils/constants/common";

const configureWorkflowResources = (
  resources: ResourceFiltersWithAction[],
  action: ScheduleActionType,
) => {
  if (action !== "notify") {
    return resources;
  }

  return resources.map((resource) => {
    return {
      ...resource,
      action: undefined,
      snapshot: undefined,
    };
  });
};

export const getWorkflowUpdatePayloadData = (
  schedule: WorkflowFormData,
): PatchUsersMeSchedulesByScheduleIdApiArg => {
  const data = generateAutomationPayloadData(schedule);

  const frequency = getCronFrequency(schedule.cron);

  const { start_date_time, time_zone } = schedule;
  const startDate = start_date_time || getStartsOnDate(time_zone, true);

  const updatedNotifications = formatNotificationsFromHtmlToSlack(
    schedule.notifications,
  );
  const updatedGracePeriod = formatNotificationsFromHtmlToSlack(
    schedule.grace_period?.notifications,
  );

  return {
    scheduleId: schedule.id,
    body: {
      ...data.body,
      notifications: updatedNotifications,
      start_date_time: startDate,
      status: schedule.status,
      cron: schedule.cron,
      action: schedule.action,
      is_snoozed: schedule.is_snoozed,
      grace_period: schedule?.grace_period
        ? {
            ...schedule?.grace_period,
            notifications: updatedGracePeriod,
          }
        : undefined,
      weekly_schedule_interval:
        frequency === "weekly" && schedule.weekly_schedule_interval
          ? +schedule.weekly_schedule_interval
          : null,
      daily_schedule_interval:
        frequency === "daily" && schedule.daily_schedule_interval
          ? +schedule.daily_schedule_interval
          : null,
      filter: configureWorkflowResources(schedule.filter, schedule.action),
    },
  };
};

export const generateWorkflowUpdatingData = (
  schedule: Schedule,
): WorkflowFormData => {
  const { accounts, ...other } = schedule;

  return {
    ...other,
    account_ids: accounts.map(({ id }) => id),
    provider: accounts?.[0]?.provider,
  };
};
