import { IconButton, Stack } from "@mui/material";
import { FC, useCallback } from "react";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";

import { AlertsCostsAnomalyThresholdUnitSelect } from "./AlertsCostsAnomalyThresholdUnitSelect";
import { AlertsCostsAnomalyThresholdSelectValue } from "./AlertsCostsAnomalyThresholdSelectValue";
import { CostAnomalyAlertUnitType } from "../../../../../../../../../../store/alerts/utils/types/types";

interface AlertsCostsAnomalyThresholdItemProps {
  unit: CostAnomalyAlertUnitType;
  amount: string;
  onUnitChange(value: CostAnomalyAlertUnitType, index: number): void;
  onAmountChange(value: string, index: number): void;
  index: number;
  onRemove?(index: number): void;
  optionDisabled: boolean;
}

export const AlertsCostsAnomalyThresholdItem: FC<
  AlertsCostsAnomalyThresholdItemProps
> = ({
  unit,
  onUnitChange,
  index,
  onAmountChange,
  amount,
  onRemove,
  optionDisabled,
}) => {
  const removeClickHandler = useCallback(() => {
    onRemove?.(index);
  }, [onRemove, index]);
  return (
    <Stack direction="row" spacing={0.5} alignItems="center">
      <Stack spacing={1.5} direction="row">
        <AlertsCostsAnomalyThresholdUnitSelect
          unit={unit}
          onChange={onUnitChange}
          index={index}
          optionDisabled={optionDisabled}
        />
        <AlertsCostsAnomalyThresholdSelectValue
          unit={unit}
          amount={amount}
          onChange={onAmountChange}
          index={index}
        />
      </Stack>

      <IconButton onClick={removeClickHandler} disabled={!onRemove}>
        <DeleteOutlineIcon fontSize="small" />
      </IconButton>
    </Stack>
  );
};
