import { FC } from "react";
import { Box, Stack, Typography } from "@mui/material";
import { getColorByValue } from "../utils/helpers/grid/dataGridHelpers";

interface CommitmentsProgressBarProps {
  value: number | null;
  width?: number | string;
  overrideColor?: string;
}

export const CommitmentsProgressBar: FC<CommitmentsProgressBarProps> = ({
  value,
  width = 150,
  overrideColor,
}) => {
  const colorByValue = getColorByValue(value);
  const color = overrideColor ?? colorByValue;

  return (
    <Stack direction="row" spacing={1} alignItems="center">
      <Box
        width={width}
        height={8}
        bgcolor="grey.300"
        borderRadius={2}
        overflow="hidden"
      >
        <Box width={`${value ?? 0}%`} bgcolor={color} height={8} />
      </Box>

      <Typography variant="caption" color={color}>
        {value !== null && !isNaN(value) ? `${value}%` : "N/A"}
      </Typography>
    </Stack>
  );
};
