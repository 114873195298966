import { rightsizingRecommendationsDataSelector } from "./data/rightsizingRecommendationsDataSelector";
import { RootState } from "../../../store";

export const rightsizingRecommendationsEstimatedTotalPriceSelector = (
  state: RootState,
) => {
  const rightSizingRecommendations =
    rightsizingRecommendationsDataSelector(state);

  const highestSavings =
    rightSizingRecommendations?.reduce(
      (acc, { potential_saving, provider_unique_identifier }) => {
        if (
          !acc[provider_unique_identifier] ||
          potential_saving > acc[provider_unique_identifier]
        ) {
          acc[provider_unique_identifier] = potential_saving;
        }
        return acc;
      },
      {} as { [key: string]: number },
    ) ?? {};

  return Object.values(highestSavings).reduce(
    (acc, currentValue) => acc + currentValue,
    0,
  );
};
