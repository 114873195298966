import { FC } from "react";
import { Typography, TypographyProps } from "@mui/material";

interface ExampleTypographyProps extends TypographyProps {
  children: string;
}

export const ExampleTypography: FC<ExampleTypographyProps> = ({
  children,
  ...typographyProps
}) => {
  return (
    <Typography
      variant="inherit"
      component="span"
      bgcolor="grey.200"
      p={0.2}
      {...typographyProps}
    >
      {`{{${children}}}`}
    </Typography>
  );
};
