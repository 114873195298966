import { FC, useMemo } from "react";
import { Stack } from "@mui/material";
import { UtilizationAndCoverageMultiTypeChartMetadata } from "./UtilizationAndCoverageMultiTypeChartMetadata";
import { useAppSelector } from "../../../../../../../../store/hooks";
import { formatDateByFrequencyType } from "../../../../../../../../utils/helpers/date-time/datetime-format";
import { commitmentsDateGranularitySelector } from "../../../../../../../../store/commitments/selectors/common/view-options/commitmentsDateGranularitySelector";
import { savingsPlansGranularDataGroupedByDateSelector } from "../../../../../../../../store/commitments/selectors/coverage-and-utilization/data/granular/savingsPlansGranularDataGroupedByDateSelector";
import { CommitmentsChart } from "../../../../../common/components/chart/CommitmentsChart";
import { commitmentsChartTypeSelector } from "../../../../../../../../store/commitments/selectors/common/view-options/commitmentsChartTypeSelector";
import { savingsPlansForGranularLoadingSelector } from "../../../../../../../../store/commitments/selectors/coverage-and-utilization/data/loading/savingsPlansForGranularLoadingSelector";
import { CommitmentsDateGranularity } from "../../../../../common/components/options/date-granularity/CommitmentsDateGranularity";
import { CommitmentsChartTypeSelect } from "../../../../../common/components/chart/CommitmentsChartTypeSelect";

export const UtilizationAndCoverageMultiTypeChart: FC = () => {
  const chartType = useAppSelector(commitmentsChartTypeSelector);
  const loading = useAppSelector(savingsPlansForGranularLoadingSelector);
  const dataPoint = useAppSelector(commitmentsDateGranularitySelector);
  const dataGroupedByDate = useAppSelector(
    savingsPlansGranularDataGroupedByDateSelector,
  );

  const chartProps = useMemo(() => {
    return {
      loading,
      dataGroupedByDate,
      labelFormatter: (value: string) =>
        formatDateByFrequencyType(dataPoint, value),
    };
  }, [dataPoint, dataGroupedByDate, loading]);

  if (chartType === "gauge") {
    return null;
  }

  return (
    <Stack>
      <UtilizationAndCoverageMultiTypeChartMetadata />

      <Stack
        direction="row"
        alignItems="center"
        justifyContent="end"
        spacing={1}
      >
        <CommitmentsDateGranularity />

        <CommitmentsChartTypeSelect />
      </Stack>

      {chartType === "bar" ? (
        <CommitmentsChart key="bar" availableTypes={["bar"]} {...chartProps} />
      ) : (
        <CommitmentsChart
          key="area"
          availableTypes={["area"]}
          {...chartProps}
        />
      )}
    </Stack>
  );
};
