import { utilizationAlertByIdSelector } from "./utilizationAlertByIdSelector";
import { RootState } from "../../../store";
import { isDeepEqual } from "../../../../utils/is-deep-equal";
import { alertsDataSelector } from "../common/data/alertsDataSelector";

export const isUtilizationAlertChangedSelector = (state: RootState) => {
  const alertData = alertsDataSelector(state);
  const alert = utilizationAlertByIdSelector(state, alertData.id ?? "");

  const alertDataThreshold = alertData?.threshold?.threshold_items
    ?.map(({ unit, amount }) => ({
      unit,
      amount: Number(amount),
    }))
    .toSorted((first, second) => (first.amount > second.amount ? 1 : -1));
  const alertThreshold = alert?.thresholds
    ?.map(({ unit, amount }) => ({
      unit,
      amount: Number(amount),
    }))
    .toSorted((first, second) => (first.amount > second.amount ? 1 : -1));

  if (!alert) {
    return false;
  }

  return (
    alertData.name.trim() !== alert.name.trim() ||
    alertData.basedOn !== alert.based_on ||
    alertData.providerOrganisationId !== alert.provider_organisation_id ||
    !isDeepEqual(alertDataThreshold ?? [], alertThreshold ?? []) ||
    !isDeepEqual(alertData?.emails ?? {}, alert.emails ?? {}) ||
    !isDeepEqual(alertData.notifications ?? {}, alert.notifications ?? {})
  );
};
