function TaskPropertyActionIcon() {
  return (
    <svg
      width="30"
      height="31"
      viewBox="0 0 30 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect y="0.5" width="30" height="30" rx="8" fill="#9575CD" />
      <path
        d="M11.2498 10.1665L13.3331 11.3332L12.1665 9.24984L13.3331 7.1665L11.2498 8.33317L9.16647 7.1665L10.3331 9.24984L9.16647 11.3332L11.2498 10.1665ZM21.2498 18.3332L19.1665 17.1665L20.3331 19.2498L19.1665 21.3332L21.2498 20.1665L23.3331 21.3332L22.1665 19.2498L23.3331 17.1665L21.2498 18.3332ZM23.3331 7.1665L21.2498 8.33317L19.1665 7.1665L20.3331 9.24984L19.1665 11.3332L21.2498 10.1665L23.3331 11.3332L22.1665 9.24984L23.3331 7.1665ZM16.9748 11.5748C16.6498 11.2498 16.1248 11.2498 15.7998 11.5748L6.0748 21.2998C5.7498 21.6248 5.7498 22.1498 6.0748 22.4748L8.0248 24.4248C8.3498 24.7498 8.8748 24.7498 9.1998 24.4248L18.9165 14.7082C19.2415 14.3832 19.2415 13.8582 18.9165 13.5332L16.9748 11.5748ZM16.1165 16.1498L14.3498 14.3832L16.3831 12.3498L18.1498 14.1165L16.1165 16.1498Z"
        fill="white"
      />
    </svg>
  );
}

export default TaskPropertyActionIcon;
