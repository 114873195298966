import { FC, useCallback } from "react";
import { Box, Button, Chip, Stack } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import { useAppDispatch, useAppSelector } from "../../../../../store/hooks";
import { selectedTasksLengthSelector } from "../../../../../store/task-management/selectors/selection/selectedTasksLengthSelector";
import { setTaskManagementSelectedTasks } from "../../../../../store/task-management/taskManagementSlice";
import { useTaskManagementContext } from "../TaskManagementProvider";

export const FloatingToolbarActions: FC = () => {
  const dispatch = useAppDispatch();
  const { gridApi } = useTaskManagementContext();

  const tasksCount = useAppSelector(selectedTasksLengthSelector);

  const selectionRemoveHandler = useCallback(() => {
    gridApi?.deselectAll();
    dispatch(setTaskManagementSelectedTasks([]));
  }, [dispatch, gridApi]);

  return (
    <Stack
      p={1.5}
      spacing={2}
      direction="row"
      bgcolor="white"
      borderRadius={2}
      justifyContent="space-between"
      boxShadow="0px 3px 14px 2px rgba(0, 0, 0, 0.12)"
    >
      <Box borderRight={1} borderColor="grey.200" pr={2}>
        <Chip
          sx={{ borderRadius: 1 }}
          variant="outlined"
          deleteIcon={
            <ClearIcon fontSize="small" color="success" sx={{ color: "red" }} />
          }
          onDelete={selectionRemoveHandler}
          label={`${tasksCount} task${tasksCount > 1 ? "s" : ""} selected`}
        />
      </Box>

      <Button color="inherit">Action 1</Button>

      <Button color="inherit">Action 2</Button>
    </Stack>
  );
};
