import { useCallback, useEffect, useState } from "react";
import { DragEndEvent } from "@dnd-kit/core";
import { arrayMove } from "@dnd-kit/sortable";

export function useDnDDataHook(
  initialData: any[],
  dndEnabled: boolean,
  onRowsDndChange: ((data: any, drop?: boolean) => void) | undefined,
) {
  const [data, setData] = useState(initialData);

  const reorderRow = useCallback(
    (event: DragEndEvent) => {
      const { active, over } = event;

      if (!over) {
        return;
      }

      if (active.id !== over.id) {
        const oldIndex = data.findIndex((row) => row.id === active.id);
        const newIndex = data.findIndex((row) => row.id === over.id);
        const newData = arrayMove(data, oldIndex, newIndex);

        setData(newData);
        onRowsDndChange && onRowsDndChange(newData, true);
      }
    },
    [data, onRowsDndChange],
  );

  useEffect(() => {
    if (dndEnabled) {
      setData(initialData);
    }
  }, [initialData, dndEnabled]);

  return {
    data: dndEnabled ? data : initialData,
    reorderRow,
  };
}
