import { FC, Fragment, useCallback } from "react";
import {
  ListItemButton,
  ListItemButtonProps,
  ListItemText,
  Tooltip,
} from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../../store/hooks";
import { commitmentsRecommendationsAccountScopeSelector } from "../../../../../../../../../store/commitments/selectors/recommendations/filters/commitmentsRecommendationsAccountScopeSelector";
import { FilterTriggerComponentProps } from "../../../../../../../../common/select/dropdown-select/utils/types/filterTriggerComponentProps";
import {
  setCommitmentsRecommendationsAccountIds,
  setCommitmentsRecommendationsAccountScope,
} from "../../../../../../../../../store/commitments/commitmentsSlice";

export const RecommendationLevelFilterAccountsSelectTriggerComponent: FC<
  FilterTriggerComponentProps
> = ({ onClick, options, selectedValues }) => {
  const dispatch = useAppDispatch();

  const scope = useAppSelector(commitmentsRecommendationsAccountScopeSelector);

  const setSelectedAccountsIds = useCallback(
    (selectedValues: string[]) => {
      if (selectedValues.length === 0) {
        dispatch(
          setCommitmentsRecommendationsAccountIds(
            options.map((item) => item.value),
          ),
        );
      } else {
        dispatch(setCommitmentsRecommendationsAccountIds(selectedValues));
      }
    },
    [options, dispatch],
  );

  const clickHandler: ListItemButtonProps["onClick"] = (event) => {
    onClick(event);
    dispatch(setCommitmentsRecommendationsAccountScope("linked"));
    setSelectedAccountsIds(selectedValues);
  };

  return (
    <ListItemButton
      onClick={clickHandler}
      sx={{
        py: 0.5,
      }}
      selected={scope === "linked"}
    >
      <Fragment>
        <ListItemText>Linked Account</ListItemText>

        <Tooltip
          arrow
          placement="bottom"
          title="Linked account - Recommendations are calculated at the individual or member account level to maximize savings for each account."
        >
          <InfoOutlinedIcon color="action" fontSize="small" />
        </Tooltip>

        <ChevronRightIcon fontSize="small" color="action" />
      </Fragment>
    </ListItemButton>
  );
};
