import { recommendationsDataGridDataSelector } from "./data/recommendationsDataGridDataSelector";
import { RootState } from "../../../store";
import { RecommendationGroupType } from "../../../../components/pages/recommendations/components/off-hours-recommendations/utils/types/types";

export const offHoursRecommendationsTotalPriceSelector = (
  state: RootState,
  group: RecommendationGroupType,
) => {
  const data = recommendationsDataGridDataSelector(state, group);

  return (
    data?.reduce((acc, { potential_saving }) => {
      return acc + potential_saving;
    }, 0) || 0
  );
};
