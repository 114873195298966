import { FC, Fragment, useMemo } from "react";
import { Divider } from "@mui/material";
import { RecommendationsTabType } from "./OffHoursRecommendationsContent";
import { useAppSelector } from "../../../../../../store/hooks";
import { RecommendationGroupType } from "../utils/types/types";
import { offHoursRecommendationsDismissedResourcesDataLengthByGroupSelector } from "../../../../../../store/recommendations/selectors/off-hours/dismissed-resources/offHoursRecommendationsDismissedResourcesDataLengthByGroupSelector";
import { RecommendationsTabs } from "../../common/RecommendationsTabs";
import { recommendationsDataGridDataTotalLengthSelector } from "../../../../../../store/recommendations/selectors/off-hours/data/recommendationsDataGridDataTotalLengthSelector";
import { recommendationsDataGridDismissedDataTotalLengthSelector } from "../../../../../../store/recommendations/selectors/off-hours/data/recommendationsDataGridDismissedDataTotalLengthSelector";

interface OffHoursRecommendationTabsProps {
  loading: boolean;
  group: RecommendationGroupType;
  setActiveTab(tab: RecommendationsTabType): void;
}

export const OffHoursRecommendationTabs: FC<
  OffHoursRecommendationTabsProps
> = ({ loading, setActiveTab, group }) => {
  const activeLength = useAppSelector((state) =>
    recommendationsDataGridDataTotalLengthSelector(state, group),
  );
  const dismissedLength = useAppSelector((state) =>
    recommendationsDataGridDismissedDataTotalLengthSelector(state, group),
  );

  const dismissedResourcesLength = useAppSelector((state) =>
    offHoursRecommendationsDismissedResourcesDataLengthByGroupSelector(
      state,
      group,
    ),
  );

  const tabs = useMemo(() => {
    return [
      {
        value: "active",
        label: "Active",
        count: activeLength,
        disabled: !activeLength,
        tooltip: "There are no active recommendations",
      },
      {
        value: "dismissed",
        label: "Dismissed",
        count: dismissedLength,
        disabled: !dismissedLength,
        tooltip: "There are no dismissed recommendations",
      },
      {
        value: "dismissed_resources",
        label: "Dismissed Resources",
        count: dismissedResourcesLength,
        disabled: !dismissedResourcesLength,
        tooltip: "There are no dismissed resources",
      },
    ];
  }, [activeLength, dismissedLength, dismissedResourcesLength]);

  return (
    <Fragment>
      <RecommendationsTabs
        tabs={tabs}
        showCount={!loading}
        setActiveTab={setActiveTab}
      />

      <Divider />
    </Fragment>
  );
};
