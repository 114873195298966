import { FC } from "react";
import { TableCell, Typography } from "@mui/material";
import { money } from "../../../../../../utils/numeral/money";

interface TableFilteredResourcesCellProps {
  costs: number;
}

export const TableFilteredResourcesCell: FC<
  TableFilteredResourcesCellProps
> = ({ costs }) => {
  return (
    <TableCell sx={{ width: 150, minWidth: 150 }}>
      <Typography
        variant="subtitle1"
        color="error.dark"
        align="right"
        fontWeight="medium"
      >
        {money(costs)}
      </Typography>
    </TableCell>
  );
};
