import { FC, Fragment } from "react";
import { Button, PopoverOrigin } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useMenuHook } from "../../../../../../utils/hooks/useMenu.hook";
import { DashboardV2NavigationAddActionMenu } from "../../../../../navigation/components/dashboard-v2/components/DashboardV2NavigationAddActionMenu";

interface DashboardAllItemsHeaderActionsProps {
  disabled?: boolean;
}

export const DashboardAllItemsHeaderActions: FC<
  DashboardAllItemsHeaderActionsProps
> = ({ disabled }) => {
  const { anchor, openMenu, closeMenu, open } = useMenuHook();

  return (
    <Fragment>
      <Button
        variant="contained"
        startIcon={<AddIcon />}
        onClick={openMenu}
        disabled={disabled}
        sx={{ textTransform: "none" }}
      >
        Add
      </Button>

      <DashboardV2NavigationAddActionMenu
        open={open}
        anchorEl={anchor}
        onCloseMenu={closeMenu}
        anchorOrigin={anchorOrigin}
        redirectAfterCreate={false}
        transformOrigin={transformOrigin}
      />
    </Fragment>
  );
};

const anchorOrigin: PopoverOrigin = {
  vertical: "bottom",
  horizontal: "left",
};

const transformOrigin: PopoverOrigin = {
  vertical: "top",
  horizontal: "left",
};
