import { FC, ReactNode } from "react";
import { Stack, Box, IconButton } from "@mui/material";
import { Column, ColumnOrderState, Header } from "@tanstack/react-table";
import { useDrag, useDrop } from "react-dnd";
import DragHandleIcon from "@mui/icons-material/DragHandle";

interface DnDWrapperProps {
  header: Header<any, any>;
  columnOrder: ColumnOrderState;
  setColumnOrder(order: ColumnOrderState): void;
  children?: ReactNode;
}

const reorderColumn = (
  draggedColumnId: string,
  targetColumnId: string,
  columnOrder: string[],
): ColumnOrderState => {
  const targetIndex = columnOrder.indexOf(targetColumnId);
  const draggedIndex = columnOrder.indexOf(draggedColumnId);

  const item = columnOrder.splice(draggedIndex, 1)[0];
  columnOrder.splice(targetIndex, 0, item);

  return [...columnOrder];
};

export const DnDWrapper: FC<DnDWrapperProps> = ({
  header,
  columnOrder,
  setColumnOrder,
  children,
}) => {
  const { column } = header;

  const [, dropRef] = useDrop({
    accept: "column",
    drop: (draggedColumn: Column<any>) => {
      const newColumnOrder = reorderColumn(
        draggedColumn.id,
        column.id,
        columnOrder,
      );

      setColumnOrder(newColumnOrder);
    },
  });

  const [{ isDragging }, dragRef, previewRef] = useDrag({
    collect: (monitor) => ({ isDragging: monitor.isDragging() }),
    item: () => column,
    type: "column",
  });

  return (
    <Box ref={dropRef} sx={{ opacity: isDragging ? 0.5 : 1 }}>
      <Stack ref={previewRef} direction="row" alignItems="center">
        {children}
        <IconButton ref={dragRef} sx={{ cursor: "grab" }}>
          <DragHandleIcon color="disabled" />
        </IconButton>
      </Stack>
    </Box>
  );
};
