import { FC, useCallback, useMemo } from "react";
import { useDidMount } from "rooks";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../store/hooks";
import { automationProviderSelector } from "../../../../../../../store/automations/selectros/common/fields/automationProviderSelector";
import { automationAccountIdsSelector } from "../../../../../../../store/automations/selectros/common/fields/automationAccountIdsSelector";
import { AccountSelect } from "../../../../../../common/account-select/AccountSelect";
import { allAccountsSelectOptionsSelector } from "../../../../../../../store/accounts/selectors/all-providers/allAccountsSelectOptionsSelector";
import {
  setAutomationData,
  setAutomationErrors,
  setWorkflowSpecificData,
} from "../../../../../../../store/automations/automationsSlice";
import { automationTypeSelector } from "../../../../../../../store/automations/selectros/common/automationTypeSelector";
import { workflowActionSelector } from "../../../../../../../store/automations/selectros/workflow/workflowActionSelector";
import { automationAccountIdsErrorSelector } from "../../../../../../../store/automations/selectros/common/errors/automationAccountIdsErrorSelector";
import { getOrganisationCurrentFilterTemplatesThunk } from "../../../../../../../store/live-usage-mgmt/thunks/getOrganisationCurrentFilterTemplatesThunk";

export const AutomationAccountsSelection: FC = () => {
  const dispatch = useAppDispatch();

  const provider = useAppSelector(automationProviderSelector);
  const action = useAppSelector(workflowActionSelector);
  const accountIds = useAppSelector(automationAccountIdsSelector);
  const accountIdsError = useAppSelector(automationAccountIdsErrorSelector);
  const automationType = useAppSelector(automationTypeSelector);
  const groupedAllAccounts = useAppSelector(allAccountsSelectOptionsSelector);

  const providerAccounts = useMemo(() => {
    return groupedAllAccounts.filter(
      ({ accountType }) => accountType === provider,
    );
  }, [groupedAllAccounts, provider]);

  const options = useMemo(() => {
    return providerAccounts.map((data) => ({
      ...data,
      disabled:
        data.disabled ||
        (automationType === "offHours" && data.accessType === "read") ||
        data.status !== "connected",
    }));
  }, [automationType, providerAccounts]);

  const affectWorkflowSpecific = useCallback(
    (accountIds: string[]) => {
      if (!accountIds.length) {
        dispatch(setWorkflowSpecificData({ action: null, gracePeriod: null }));
        return;
      }

      const selectedAccounts = providerAccounts?.filter(({ value }) =>
        accountIds?.includes(value),
      );
      const allReadOnly = selectedAccounts?.every(
        ({ accessType }) => accessType === "read",
      );

      if (allReadOnly) {
        dispatch(
          setWorkflowSpecificData({
            gracePeriod: null,
            action: action?.includes("notify") ? "notify" : null,
          }),
        );
      }
    },
    [dispatch, action, providerAccounts],
  );

  const changeHandler = useCallback(
    (accountIds: string[]) => {
      if (automationType === "workflow") {
        affectWorkflowSpecific(accountIds);
      }

      dispatch(setAutomationData({ accountIds }));

      if (accountIds.length && accountIdsError) {
        dispatch(setAutomationErrors({ accountIds: "" }));
      }
    },
    [dispatch, accountIdsError, automationType, affectWorkflowSpecific],
  );

  useDidMount(() => {
    dispatch(getOrganisationCurrentFilterTemplatesThunk());
  });

  return (
    <AccountSelect
      error={accountIdsError}
      listWidth={700}
      disabled={!provider}
      onChange={changeHandler}
      options={options}
      selectedValue={accountIds || []}
      wrapperVariant="popover"
    />
  );
};
