import { FC } from "react";
import { Box, Card, Skeleton, Stack, Tooltip, Typography } from "@mui/material";
import { OverviewEffectiveSavingsRateProgressbar } from "./OverviewEffectiveSavingsRateProgressbar";
import { OverviewEffectiveSavingsRateHeader } from "./OverviewEffectiveSavingsRateHeader";
import { useAppSelector } from "../../../../../../store/hooks";
import { overviewEffectiveSavingRateSelector } from "../../../../../../store/commitments/selectors/overview/overviewEffectiveSavingRateSelector";
import { overviewLoadingSelector } from "../../../../../../store/commitments/selectors/overview/loading/overviewLoadingSelector";
import { TrendChip } from "../../../../../common/chips/TrendChip";
import { overviewPreviousDateDetailsSelector } from "../../../../../../store/commitments/selectors/overview/overviewPreviousDateDetailsSelector";
import { commitmentsDatesSelector } from "../../../../../../store/commitments/selectors/common/view-options/commitmentsDatesSelector";
import { organisationQuarterStartMonthSelector } from "../../../../../../store/common/selectors/org-current-settings/organisationQuarterStartMonthSelector";
import { generateDatesCompareData } from "../../../../../common/date-range-picker/utils/helpers/date-formatter/generateDatesCompareData";

export const OverviewEffectiveSavingsRate: FC = () => {
  const effectiveSavingRate = useAppSelector(
    overviewEffectiveSavingRateSelector,
  );
  const quarterStartMonth = useAppSelector(
    organisationQuarterStartMonthSelector,
  );
  const dates = useAppSelector(commitmentsDatesSelector);
  const prevDateDetails = useAppSelector(overviewPreviousDateDetailsSelector);
  const loading = useAppSelector(overviewLoadingSelector);

  const value =
    effectiveSavingRate?.savings_rate === null
      ? "N/A"
      : `${effectiveSavingRate?.savings_rate}%`;

  const dateInfo = generateDatesCompareData({
    prevDate: {
      from_date: prevDateDetails?.date_from,
      to_date: prevDateDetails?.date_to,
    },
    date: dates,
    quarterStartMonth,
  });

  return (
    <Card
      variant="outlined"
      sx={{ px: 2, py: 1, flexGrow: 1, borderRadius: 1.5 }}
    >
      <Stack justifyContent="space-between" height="100%">
        <OverviewEffectiveSavingsRateHeader />

        <Stack direction="row" spacing={2} alignItems="center">
          <Typography mt={0.5} variant="h4" fontWeight="medium">
            {loading ? loadingSkeleton : value}
          </Typography>
          <Box>
            <Tooltip title={dateInfo.title} arrow placement="top">
              <span>
                <TrendChip value={effectiveSavingRate?.trend} />
              </span>
            </Tooltip>
          </Box>
        </Stack>

        <OverviewEffectiveSavingsRateProgressbar />
      </Stack>
    </Card>
  );
};

const loadingSkeleton = <Skeleton variant="text" height={32} width={150} />;
