import { RootState } from "../../../../../store";
import {
  commitmentsPaymentOptionLabels,
  commitmentsResourceTypeNames,
  commitmentsStateLabels,
} from "../../../../../../components/pages/commtiments/common/utils/constants/labels";
import { getPercentage } from "../../../../../../utils/numeral/getPercentage";
import { Tag } from "../../../../../../services/cloudchipr.api";
import { reservationsDataSelector } from "../reservationsDataSelector";

export const reservedInstancesCSVDataSelector = (state: RootState) => {
  const data = reservationsDataSelector(state);

  return data?.map((item) => {
    const utilizationPercentage = getPercentage(
      item.used_commitment_to_date?.cost ?? 0,
      item.total_commitment_to_date?.cost ?? 0,
    ).toFixed(2);

    const utilizationIsNA =
      item.used_commitment_to_date?.cost === null ||
      item.total_commitment_to_date?.cost === null;

    return {
      "Reservation ID": item.provider_unique_id,
      Type: commitmentsResourceTypeNames[item.type],
      "Instance Type": item.instance_type,
      "Instance Count": item.instance_count,
      "Linked Account":
        item.account.provider_account_name ?? item.account.provider_account_id,
      "Utilization / Reserved": utilizationIsNA
        ? "N/A"
        : `${item.used_commitment_to_date?.cost} / ${item.total_commitment_to_date?.cost} (${utilizationPercentage}%)`,
      Platform: item.platform,
      "Region/AZ": item.region,
      "Payment Option": commitmentsPaymentOptionLabels[item.payment_option],
      "Net Savings": item.net_savings_to_date,
      "Start Date": item.start_date,
      "End Date": item.end_date,
      Status: commitmentsStateLabels[item.state],
      Tags: item.tags
        ?.map((tag: Tag) => `${tag?.key}:${tag?.value}`)
        .join(" , "),
    };
  });
};
