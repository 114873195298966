import { largestCostChangesWidgetDataResponseSelector } from "./largestCostChangesWidgetDataResponseSelector";
import { RootState } from "../../../../../store";

export const largestCostChangesWidgetPrevPeriodCostDateDetailsSelector = (
  state: RootState,
  widgetId: string,
) => {
  const response = largestCostChangesWidgetDataResponseSelector(
    state,
    widgetId,
  );

  return response?.data?.previous_period_cost_date_details;
};
