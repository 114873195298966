import { FC } from "react";
import { IconButton, Stack, Tooltip } from "@mui/material";
import { Table } from "@tanstack/react-table";
import { Tune } from "@mui/icons-material";
import { GlobalFilter } from "../../../../../../storybook/data-grid/toolbar/GlobalFilter";
import { ColumnsSwitcher } from "../../../../../../storybook/data-grid/toolbar/ColumnsSwitcher";
import { useMenuHook } from "../../../../../../utils/hooks/useMenu.hook";

interface AlertsDataGridToolbarProps {
  globalFilter: string | number;
  setGlobalFilter: (value: string) => void;
  table: Table<any>;
}

export const AlertsDataGridToolbar: FC<AlertsDataGridToolbarProps> = ({
  globalFilter,
  setGlobalFilter,
  table,
}) => {
  const { anchor, openMenu, closeMenu, open } = useMenuHook();

  return (
    <Stack
      py={2.5}
      px={1.5}
      gap={2}
      justifyContent="flex-end"
      direction="row"
      bgcolor="white"
    >
      <GlobalFilter
        globalFilter={globalFilter}
        setGlobalFilter={setGlobalFilter}
        size="xsmall"
        sx={{ width: 260 }}
      />

      <Tooltip arrow title="Table Settings" placement="top">
        <IconButton onClick={openMenu} size="small">
          <Tune fontSize="small" />
        </IconButton>
      </Tooltip>

      <ColumnsSwitcher
        open={open}
        onClose={closeMenu}
        anchor={anchor}
        table={table}
      />
    </Stack>
  );
};
