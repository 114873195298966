import { FC, Fragment, useCallback } from "react";
import { IconButton, Popover, PopoverProps, Stack } from "@mui/material";
import WaterDropOutlinedIcon from "@mui/icons-material/WaterDropOutlined";
import DoneOutlinedIcon from "@mui/icons-material/DoneOutlined";
import { useMenuHook } from "../../../../../../../../utils/hooks/useMenu.hook";
import { TaskPropertyType } from "../../../../../../../../store/task-management/utils/types/types";
import { taskPropertiesColors } from "../../../../utils/constants/taskPropertiesColors";

interface TaskPropertyColorProps {
  selectedColor: string;
  disabled: boolean;
  onPropertyChange(property: Partial<TaskPropertyType>): void;
}

export const TaskPropertyColor: FC<TaskPropertyColorProps> = ({
  selectedColor,
  onPropertyChange,
  disabled,
}) => {
  const { anchor, open, openMenu, closeMenu } = useMenuHook();

  const changeHandler = useCallback(
    (color: string) => () => {
      onPropertyChange({ color });
    },
    [onPropertyChange],
  );

  return (
    <Fragment>
      <IconButton size="small" onClick={openMenu} disabled={disabled}>
        <WaterDropOutlinedIcon
          fontSize="small"
          color={disabled ? "disabled" : "action"}
        />
      </IconButton>

      <Popover
        anchorEl={anchor}
        open={open}
        onClose={closeMenu}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
      >
        <Stack
          p={1}
          gap={1}
          width={168}
          direction="row"
          flexWrap="wrap"
          borderRadius={1}
        >
          {taskPropertiesColors.map((color) => (
            <Stack
              key={color}
              width={24}
              height={24}
              bgcolor={color}
              borderRadius={1}
              alignItems="center"
              justifyContent="center"
              sx={{ cursor: "pointer" }}
              onClick={changeHandler(color)}
            >
              {selectedColor.toLowerCase() === color.toLowerCase() && (
                <DoneOutlinedIcon fontSize="small" sx={{ color: "white" }} />
              )}
            </Stack>
          ))}
        </Stack>
      </Popover>
    </Fragment>
  );
};

const anchorOrigin: PopoverProps["anchorOrigin"] = {
  vertical: "bottom",
  horizontal: "right",
};

const transformOrigin: PopoverProps["transformOrigin"] = {
  vertical: "top",
  horizontal: "right",
};
