import { FC, Fragment, useCallback } from "react";
import {
  ListItemIcon,
  ListItemText,
  MenuItem,
  Typography,
} from "@mui/material";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { DeleteConfirmationDialog } from "../../../../../../common/modals/DeleteConfirmationDialog";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../store/hooks";
import { useAppAbility } from "../../../../../../../services/permissions";
import { taskDeletionLoadingSelector } from "../../../../../../../store/task-management/selectors/loading/taskDeletionLoadingSelector";
import { tasksListGetLoadingSelector } from "../../../../../../../store/task-management/selectors/loading/tasksListGetLoadingSelector";
import { taskManagementDeleteTasksByIdsThunk } from "../../../../../../../store/task-management/thunks/actions/taskManagementDeleteTasksByIdsThunk";
import { useDialog } from "../../../../../../../utils/hooks/useDialog.hook";
import { Task } from "../../../../../../../services/cloudchipr.api";

interface TaskDeleteActionMenuItemProps {
  task: Task;
  onClose(): void;
}

export const TaskDeleteActionMenuItem: FC<TaskDeleteActionMenuItemProps> = ({
  task,
  onClose,
}) => {
  const name = task.name;
  const id = task.id;

  const dispatch = useAppDispatch();
  const { open, openDialog, closeDialog } = useDialog();

  const { can } = useAppAbility();
  const canDeleteTask = can("delete", "task");

  const deletionLoading = useAppSelector(taskDeletionLoadingSelector);
  const getLoading = useAppSelector(tasksListGetLoadingSelector);

  const deleteHandler = useCallback(async () => {
    await dispatch(taskManagementDeleteTasksByIdsThunk([id]));

    onClose();
  }, [dispatch, id, onClose]);

  const closeHandler = useCallback(() => {
    closeDialog();
    onClose();
  }, [closeDialog, onClose]);

  return (
    <Fragment>
      <MenuItem
        dense
        disabled={!canDeleteTask || deletionLoading || getLoading}
        onClick={openDialog}
      >
        <ListItemIcon>
          <DeleteOutlineIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText>Delete</ListItemText>
      </MenuItem>

      <DeleteConfirmationDialog
        open={open}
        onClose={closeHandler}
        onDelete={deleteHandler}
        loading={getLoading || deletionLoading}
        title="Delete Task?"
        content={
          <Typography>
            Please confirm if you want to delete the
            <Typography
              component="span"
              fontWeight="bold"
              whiteSpace="break-spaces"
              sx={{ wordBreak: "break-word" }}
            >
              {" "}
              {name}{" "}
            </Typography>
            task
          </Typography>
        }
      />
    </Fragment>
  );
};
