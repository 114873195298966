import { createAsyncThunk } from "@reduxjs/toolkit";
import { getCostAnomalyAlertsThunk } from "./cost-anomaly-alerts/getCostAnomalyAlertsThunk";
import { getUtilizationAlertsThunk } from "./utilization-alerts/getUtilizationAlertsThunk";
import { getAllIntegrationsThunk } from "../../integrations/thunks/getAllIntegrationsThunk";
import { getResourceExplorerVisibilityHierarchyThunk } from "../../resource-explorer/thunks/resource-explorer-hierarchy-visibility/getResourceExplorerVisibilityHierarchyThunk";
import { getOrganizationsForCommitmentsThunk } from "../../commitments/thunks/getOrganizationsForCommitmentsThunk";

export const getAlertsPageDataThunk = createAsyncThunk(
  "alerts/getAlertsPageData",
  (_, { dispatch }) => {
    dispatch(getAllIntegrationsThunk());
    dispatch(getCostAnomalyAlertsThunk());
    dispatch(getUtilizationAlertsThunk());
    dispatch(getResourceExplorerVisibilityHierarchyThunk());
    dispatch(getOrganizationsForCommitmentsThunk());
  },
);
