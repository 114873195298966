import { FC } from "react";
import { Divider, Stack } from "@mui/material";
import { AutomationResource } from "./AutomationResource";
import { useAppSelector } from "../../../../../../../../store/hooks";
import { automationResourcesSelector } from "../../../../../../../../store/automations/selectros/common/fields/automationResourcesSelector";
import { automationFiltersErrorSelector } from "../../../../../../../../store/automations/selectros/common/errors/filters/automationFiltersErrorSelector";

export const AutomationResourcesList: FC = () => {
  const resources = useAppSelector(automationResourcesSelector);
  const errors = useAppSelector(automationFiltersErrorSelector);

  return (
    <Stack divider={<Divider>AND</Divider>} spacing={2}>
      {resources.map((resourceFilters, index) => {
        return (
          <AutomationResource
            index={index}
            error={errors?.[`${index}`]}
            key={resourceFilters.filter.type}
            resourceFilters={resourceFilters}
          />
        );
      })}
    </Stack>
  );
};
