import { FC } from "react";
import { Box, Popover, PopoverOrigin, Stack, Typography } from "@mui/material";
import { NotificationsChip } from "./NotificationsChip";
import { Integration } from "../../../../../../../services/cloudchipr.api";

interface NotificationsChipPopoverProps {
  open: boolean;
  closeMenu(): void;
  anchor: HTMLElement | null;
  integrations: Partial<Integration>[];
}

export const NotificationsChipPopover: FC<NotificationsChipPopoverProps> = ({
  open,
  anchor,
  closeMenu,
  integrations,
}) => {
  return (
    <Popover
      open={open}
      anchorEl={anchor}
      onClose={closeMenu}
      anchorOrigin={anchorOrigin}
      transformOrigin={transformOrigin}
      PaperProps={paperProps}
    >
      <Box top={0} position="sticky" bgcolor="white" px={2} py={1} zIndex={100}>
        <Typography variant="subtitle2" color="text.secondary">
          Integrations ({integrations.length})
        </Typography>
      </Box>
      <Stack spacing={2} p={2} pt={0}>
        {integrations.map((integration) => {
          return (
            <NotificationsChip
              key={integration.id}
              type={integration.type ?? "email"}
              name={integration.name ?? ""}
            />
          );
        })}
      </Stack>
    </Popover>
  );
};

const anchorOrigin: PopoverOrigin = {
  vertical: "bottom",
  horizontal: "right",
};
const transformOrigin: PopoverOrigin = {
  vertical: "top",
  horizontal: "right",
};

const paperProps = {
  style: { maxHeight: 400, minWidth: 120, maxWidth: 400 },
};
