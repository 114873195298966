import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import { indigo } from "@mui/material/colors";
import { FC, useCallback, MouseEvent } from "react";
import { ResourceExplorerChartBase } from "../utils/types";

interface ResourceExplorerChartBaseSelectProps {
  base: ResourceExplorerChartBase;
  onChartBaseChange(base: ResourceExplorerChartBase): void;
}

export const ResourceExplorerChartBaseSelect: FC<
  ResourceExplorerChartBaseSelectProps
> = ({ base, onChartBaseChange }) => {
  const handleChartBaseChange = useCallback(
    (event: MouseEvent<HTMLElement>) => {
      const target = event.target as HTMLInputElement;
      onChartBaseChange(target.value as ResourceExplorerChartBase);
    },
    [onChartBaseChange],
  );

  return (
    <ToggleButtonGroup
      exclusive
      color="primary"
      size="small"
      value={base}
      onChange={handleChartBaseChange}
    >
      <ToggleButton value="cost" sx={buttonStyles}>
        $
      </ToggleButton>
      <ToggleButton value="percent" sx={buttonStyles}>
        %
      </ToggleButton>
    </ToggleButtonGroup>
  );
};

const buttonStyles = {
  maxHeight: 32,
  width: 32,
  fontSize: 16,
  fontWeight: "bold",
  borderColor: indigo["100"],
};
