import { FC, Fragment, memo } from "react";
import { SingleDismissButton } from "./SingleDismissButton";
import { DismissTypesMenu } from "./DismissTypesMenu";
import { MultipleDismissButton } from "./MultipleDismissButton";
import { useMenuHook } from "../../../../../../utils/hooks/useMenu.hook";
import { useAppAbility } from "../../../../../../services/permissions";

interface RecommendationDismissProps {
  multiple?: boolean;
  recommendationId?: string;
  recommendationType: "off-hours" | "rightsizing";
}

export const RecommendationDismiss: FC<RecommendationDismissProps> = memo(
  ({ recommendationId, multiple, recommendationType }) => {
    const { anchor, open, openMenu, closeMenu } = useMenuHook();

    const { cannot } = useAppAbility();
    const canNotEditRecommendation = cannot("edit", "recommendation");

    return (
      <Fragment>
        {multiple ? (
          <MultipleDismissButton
            open={open}
            onClick={openMenu}
            disabled={canNotEditRecommendation}
          />
        ) : (
          <SingleDismissButton
            onClick={openMenu}
            disabled={canNotEditRecommendation}
          />
        )}

        <DismissTypesMenu
          open={open}
          anchor={anchor}
          onClose={closeMenu}
          recommendationId={recommendationId}
          recommendationType={recommendationType}
        />
      </Fragment>
    );
  },
);
