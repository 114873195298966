import { FC } from "react";
import { IconButton, Menu, Stack } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { AlertsDataGridDeleteAction } from "./AlertsDataGridDeleteAction";
import { AlertDataGridDuplicateAction } from "./AlertsDataGridDuplicateAction";
import { AlertDataGridEditAction } from "./AlertDataGridEditAction";
import { AlertsDataGridSwitch } from "./AlertsDataGridSwitch";
import { useMenuHook } from "../../../../../../../utils/hooks/useMenu.hook";
import { AlertTabType } from "../../../../../../../store/alerts/utils/types/types";
import {
  CostAnomalyAlert,
  UtilizationAndCoverageAlert,
} from "../../../../../../../services/cloudchipr.api";

interface AlertsDataGridActionsCellProps {
  alertsActiveTab: AlertTabType;
  alert: CostAnomalyAlert | UtilizationAndCoverageAlert;
}

export const AlertsDataGridActionsCell: FC<AlertsDataGridActionsCellProps> = ({
  alert,
  alertsActiveTab,
}) => {
  const { anchor, open, openMenu, closeMenu } = useMenuHook();
  const { id, name, status } = alert;

  return (
    <Stack
      alignItems="center"
      justifyContent="space-around"
      direction="row"
      px={1}
    >
      <AlertsDataGridSwitch
        id={id}
        status={status}
        alertsActiveTab={alertsActiveTab}
      />
      <IconButton onClick={openMenu}>
        <MoreVertIcon />
      </IconButton>
      <Menu anchorEl={anchor} open={open} onClose={closeMenu}>
        <AlertDataGridEditAction
          alertId={alert.id}
          alertType={alertsActiveTab}
          closeMenu={closeMenu}
        />
        <AlertDataGridDuplicateAction
          alert={alert}
          alertsActiveTab={alertsActiveTab}
        />
        <AlertsDataGridDeleteAction
          id={id}
          name={name}
          alertsActiveTab={alertsActiveTab}
        />
      </Menu>
    </Stack>
  );
};
