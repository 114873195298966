import { FC } from "react";
import { Menu } from "@mui/material";
import { ResourceExplorerViewMoveAction } from "../action-items/move-action/ResourceExplorerViewMoveAction";
import { navigationHierarchyItemNoDndKey } from "../../../../sortable-tree/utils/constants";
import { NavigationItemsVisibilityType } from "../../../../../../../store/navigation/utils/types";
import { CopyURLAction } from "../../../../../../common/copy-url/CopyURLAction";
import { ResourceExplorerRenameActionItem } from "../action-items/rename-action/ResourceExplorerRenameActionItem";
import { ResourceExplorerDeleteActionItem } from "../action-items/delete-action/ResourceExplorerDeleteActionItem";
import { ResourceExplorerDuplicateActionItem } from "../action-items/duplicate-action/ResourceExplorerDuplicateActionItem";

interface ResourceExplorerViewListItemActionsMenuProps {
  id: string;
  name: string;
  open: boolean;
  closeMenu(): void;
  anchor: HTMLElement | null;
  folderId?: string;
  visibility: NavigationItemsVisibilityType;
}

export const ResourceExplorerActionsMenu: FC<
  ResourceExplorerViewListItemActionsMenuProps
> = ({ name, id, open, anchor, closeMenu, folderId, visibility }) => {
  return (
    <Menu
      open={open}
      onClose={closeMenu}
      anchorEl={anchor}
      {...navigationHierarchyItemNoDndKey}
    >
      <ResourceExplorerRenameActionItem
        id={id}
        name={name}
        onClose={closeMenu}
      />

      <CopyURLAction
        link={`${window.location.host}/resource-explorer/${id}`}
        onClose={closeMenu}
      />

      <ResourceExplorerViewMoveAction
        id={id}
        name={name}
        folderId={folderId}
        onClose={closeMenu}
        visibility={visibility}
      />

      <ResourceExplorerDuplicateActionItem
        id={id}
        name={name}
        onClose={closeMenu}
        folderId={folderId}
        visibility={visibility}
      />

      <ResourceExplorerDeleteActionItem
        id={id}
        name={name}
        onClose={closeMenu}
      />
    </Menu>
  );
};
