import { TagsFilter } from "../types";

export const generateFilterAfterTagFiltersKeyChange = (
  newTagKey: string,
  prevFilter: TagsFilter,
  index: number,
) => {
  const updatedListFiltersValue = prevFilter?.value.map((item, i) => {
    if (item.key === newTagKey) {
      return item;
    }
    if (i === index) {
      return {
        ...item,
        key: newTagKey,
        values: [],
      };
    }
    return item;
  });

  return {
    ...prevFilter,
    value: updatedListFiltersValue,
  };
};
