import { FC } from "react";
import GridViewOutlinedIcon from "@mui/icons-material/GridViewOutlined";
import { HierarchyAllItemsFolderNameCell } from "../../../../../common/hierarchy-all-items/components/table-cells/HierarchyAllItemsFolderNameCell";
import { HierarchyAllItemsItemNameCell } from "../../../../../common/hierarchy-all-items/components/table-cells/HierarchyAllItemsItemNameCell";
import { VisibilityType } from "../../../../../common/hierarchy-all-items/utils/types/types";

interface ResourceExplorerItemNameCellProps {
  id: string;
  name: string;
  folder: boolean;
  expanded: boolean;
  visibility: VisibilityType;
  toggleExpandedHandler(): void;
}

export const DashboardFolderItemNameCell: FC<
  ResourceExplorerItemNameCellProps
> = ({ folder, name, id, visibility, expanded, toggleExpandedHandler }) => {
  if (folder) {
    return (
      <HierarchyAllItemsFolderNameCell
        name={name}
        expanded={expanded}
        visibility={visibility}
        toggleExpandedHandler={toggleExpandedHandler}
      />
    );
  }

  return (
    <HierarchyAllItemsItemNameCell
      link={`/dashboards/${id}`}
      name={name}
      visibility={visibility}
      icon={GridViewOutlinedIcon}
    />
  );
};
