import { recommendationsDataGridDataSelector } from "../data/recommendationsDataGridDataSelector";
import { RootState } from "../../../../store";
import { RecommendationGroupType } from "../../../../../components/pages/recommendations/components/off-hours-recommendations/utils/types/types";
import { RecommendationsTabType } from "../../../../../components/pages/recommendations/components/off-hours-recommendations/components/OffHoursRecommendationsContent";
import { recommendationDismissedDataSelector } from "../data/recommendationsDataGridDimissedDataSelector";
import { offHoursRecommendationsDismissedResourcesDataByGroupSelector } from "../dismissed-resources/offHoursRecommendationsDismissedResourcesDataByGroupSelector";

export const recommendationAvailableTypesSelector = (
  state: RootState,
  group: RecommendationGroupType,
  recommendationsType: RecommendationsTabType,
): string[] => {
  const activeRecommendations = recommendationsDataGridDataSelector(
    state,
    group,
  );
  const dismissedRecommendations = recommendationDismissedDataSelector(
    state,
    group,
  );
  const dismissedResources =
    offHoursRecommendationsDismissedResourcesDataByGroupSelector(state, group);

  let data: { type: string }[] | undefined = activeRecommendations;

  if (recommendationsType === "dismissed") {
    data = dismissedRecommendations;
  } else if (recommendationsType === "dismissed_resources") {
    data = dismissedResources;
  }

  const types = data?.map(({ type }) => type) ?? [];

  return Array.from(new Set(types));
};
