import { reportsDataPropertyByKeySelector } from "./reportsDataPropertyByKeySelector";
import { RootState } from "../../store";
import { MonthlyByWeekdaySchedule } from "../../../services/cloudchipr.api";

export const reportsScheduleWeekDaySelector = (state: RootState) => {
  const schedule = reportsDataPropertyByKeySelector("schedule")(
    state,
  ) as MonthlyByWeekdaySchedule;

  return schedule.week_day;
};
