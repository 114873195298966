import { FC } from "react";
import { Stack, Tooltip } from "@mui/material";
import { useFlag } from "@unleash/proxy-client-react";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { money } from "../../../../../utils/numeral/money";
import { StatisticCard } from "../../../../common/statisticCard/StatisticCard";

interface MessageCardsProps {
  ems: number;
  spending: number;
  saving?: number;
}

export const AccountCashFlowMessages: FC<MessageCardsProps> = ({
  ems,
  spending,
  saving,
}) => {
  const enableLifetimeSaving = useFlag("EnableLifetimeSaving");

  const showLifetimeSavingCard = enableLifetimeSaving && !!saving;

  return (
    <Stack spacing={1} direction="row" width="100%">
      <StatisticCard
        title="Live costs for all filtered resources"
        value={money(ems)}
        color="red"
        size="large"
        sx={{
          borderWidth: 4,
        }}
        endAdornment={
          <Tooltip
            arrow
            placement="left"
            title="Monthly prices are calculated based on the on-demand list price of each resource."
          >
            <InfoOutlinedIcon fontSize="small" color="action" />
          </Tooltip>
        }
      />

      <StatisticCard
        title="Live costs for all tracked resources"
        value={money(spending)}
        color="green"
        size="large"
        endAdornment={
          <Tooltip
            arrow
            placement="left"
            title="Monthly prices are calculated based on the on-demand list price of each resource."
          >
            <InfoOutlinedIcon fontSize="small" color="action" />
          </Tooltip>
        }
      />

      {showLifetimeSavingCard && (
        <StatisticCard
          title="Saved with Cloudchipr"
          value={money(saving)}
          color="blue"
          size="large"
          endAdornment={
            <Tooltip
              arrow
              placement="left"
              title="Combined monthly price of all resources terminated by Cloudchipr users and automation workflows."
            >
              <InfoOutlinedIcon fontSize="small" color="action" />
            </Tooltip>
          }
        />
      )}
    </Stack>
  );
};
