import { FC } from "react";
import { Link, Stack, Typography } from "@mui/material";

interface SlackConversationsDropdownFooterProps {
  integrationId: string;
}

export const SlackConversationsDropdownFooter: FC<
  SlackConversationsDropdownFooterProps
> = ({ integrationId }) => {
  return (
    <Stack alignItems="center" p={2}>
      <Link
        target="_blank"
        fontWeight="medium"
        href={`/integrations/edit/slack/${integrationId}`}
        underline="hover"
        textTransform="uppercase"
      >
        Manage Conversations List
      </Link>
      <Typography color="text.secondary" variant="caption">
        If you can’t find your preferred conversation, choose to manage the
        list.
      </Typography>
    </Stack>
  );
};
