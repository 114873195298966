import { TextField } from "@mui/material";
import { ChangeEvent, FC, useCallback, WheelEvent } from "react";
import { TextFieldProps } from "@mui/material/TextField/TextField";

type NumberFieldProps = Omit<TextFieldProps, "type" | "onWheel"> & {
  maxValue?: number;
  minValue?: number;
};

export const NumberField: FC<NumberFieldProps> = ({
  maxValue,
  minValue,
  onChange,
  InputProps,
  ...props
}) => {
  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const value = Number(event.target.value);
      if (
        ((maxValue && value > maxValue) || (minValue && value < minValue)) &&
        event.target.value !== ""
      ) {
        return;
      }

      onChange?.(event);
    },
    [onChange, minValue, maxValue],
  );

  const disableOnWheelEvent = useCallback((e: WheelEvent<HTMLDivElement>) => {
    const target = e.target as HTMLInputElement;
    target.blur();
  }, []);

  return (
    <TextField
      {...props}
      type="number"
      onChange={handleChange}
      onWheel={disableOnWheelEvent}
      InputProps={{
        inputProps: {
          min: minValue,
          max: maxValue,
          ...(InputProps?.inputProps ?? []),
        },
        ...InputProps,
      }}
    />
  );
};
