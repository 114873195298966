import { reservationsDataForTableSelector } from "./reservationsDataForTableSelector";
import { RootState } from "../../../../store";
import { Reservation } from "../../../../../services/cloudchipr.api";

export const reservationsDataSelector = (
  state: RootState,
): Reservation[] | undefined => {
  const data = reservationsDataForTableSelector(state);

  return data?.utilization_and_coverage.reservations;
};
