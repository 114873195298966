import { dashboardHierarchyMixedVisibilitiesDataSelector } from "./dashboardHierarchyMixedVisibilitiesDataSelector";
import { RootState } from "../../../store";
import { VisibilityType } from "../../../../components/pages/common/hierarchy-all-items/utils/types/types";
import { filterHierarchyMixedVisibilitiesDataByVisibilityAndCreatedBy } from "../../../common/utils/helpers/filterHierarchyMixedVisibilitiesDataByVisibilityAndCreatedBy";
import { DashboardMixedVisibilitiesItemType } from "../../../../components/pages/dashboard-all-items/components/table/utils/types/types";

export const dashboardHierarchyMixedVisibilitiesDataByFiltersSelector = (
  state: RootState,
  visibilities?: VisibilityType[],
  createdBy?: string[],
) => {
  const data = dashboardHierarchyMixedVisibilitiesDataSelector(state);

  return filterHierarchyMixedVisibilitiesDataByVisibilityAndCreatedBy<
    DashboardMixedVisibilitiesItemType[] | null
  >(data, visibilities, createdBy);
};
