import { FC, Fragment, ReactNode, useCallback } from "react";
import {
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { useMenuHook } from "../../../../../../utils/hooks/useMenu.hook";
import { useDialog } from "../../../../../../utils/hooks/useDialog.hook";
import { useAppAbility } from "../../../../../../services/permissions";

export type ActionsMenuConfirmationDialogProps = {
  onClose(): void;
  open: boolean;
};

interface ActionsMenuProps {
  onEditOpen(): void;
  deleteDisabled?: boolean;
  ConfirmationDialog: FC<ActionsMenuConfirmationDialogProps>;
  children?: ReactNode;
}

export const ActionsMenu: FC<ActionsMenuProps> = ({
  onEditOpen,
  ConfirmationDialog,
  deleteDisabled,
  children,
}) => {
  const { cannot } = useAppAbility();
  const canNotDeleteCategory = cannot("delete", "category");
  const canNotEditCategory = cannot("edit", "category");

  const { anchor, open, openMenu, closeMenu } = useMenuHook();
  const { open: deleteDialogOpen, openDialog, closeDialog } = useDialog();

  const dialogCloseHandler = useCallback(() => {
    closeDialog();
    closeMenu();
  }, [closeMenu, closeDialog]);

  const editDialogOpenHandler = useCallback(() => {
    onEditOpen();
    closeMenu();
  }, [onEditOpen, closeMenu]);

  return (
    <Fragment>
      <IconButton size="small" onClick={openMenu} sx={{ p: 0 }}>
        <MoreVertIcon />
      </IconButton>

      <Menu open={open} onClose={closeMenu} anchorEl={anchor}>
        <MenuItem
          dense
          onClick={editDialogOpenHandler}
          disabled={canNotEditCategory}
        >
          <ListItemIcon>
            <EditOutlinedIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Edit</ListItemText>
        </MenuItem>

        <MenuItem
          dense
          onClick={openDialog}
          disabled={canNotDeleteCategory || deleteDisabled}
        >
          <ListItemIcon>
            <DeleteOutlineIcon />
          </ListItemIcon>

          <ListItemText>
            <ListItemText>Delete</ListItemText>
          </ListItemText>
        </MenuItem>

        {children}
      </Menu>

      <ConfirmationDialog
        onClose={dialogCloseHandler}
        open={deleteDialogOpen}
      />
    </Fragment>
  );
};
