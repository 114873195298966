import { FC, Fragment } from "react";
import { Divider, Stack, Typography } from "@mui/material";
import { AccountCardFilterTemplateName } from "./AccountCardFilterTemplateName";
import {
  AccountStatus,
  ProviderType,
} from "../../../../../../../services/cloudchipr.api";
import { activeAccountStatuses } from "../../../../../../../utils/constants/accounts";

interface AccountCardFilterTemplateProps {
  accountId: string;
  provider: ProviderType;
  filterSetId: string | null;
  status: AccountStatus;
}

export const AccountCardFilterTemplate: FC<AccountCardFilterTemplateProps> = ({
  accountId,
  provider,
  filterSetId,
  status,
}) => {
  if (!activeAccountStatuses.has(status)) {
    return null;
  }
  return (
    <Fragment>
      <Divider />
      <Stack direction="row" alignItems="center" py={1} px={2} spacing={1}>
        <Typography variant="caption" color="text.disabled">
          Filter Template:
        </Typography>
        <AccountCardFilterTemplateName
          accountId={accountId}
          provider={provider}
          filterSetId={filterSetId}
        />
      </Stack>
    </Fragment>
  );
};
