import { FC, Fragment } from "react";
import { Theme, Typography } from "@mui/material";
import { SxProps } from "@mui/system";
import { NullableDate } from "../../../../../../services/cloudchipr.api";
import { useDateFromNow } from "../../../../live-usage-mgmt/utils/hooks/useDateFromNow";

interface LastUpdateDateProps {
  date?: NullableDate;
  sx?: SxProps<Theme>;
}
export const LastUpdateDate: FC<LastUpdateDateProps> = ({ date, sx }) => {
  const fromNow = useDateFromNow(date);

  return (
    <Typography variant="caption" color="text.primary" sx={sx}>
      {fromNow && (
        <Fragment>
          Updated{" "}
          <Typography fontWeight="bold" variant="inherit" component="span">
            {fromNow}
          </Typography>{" "}
          ago
        </Fragment>
      )}
    </Typography>
  );
};
