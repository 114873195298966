import { createDraftSafeSelector } from "@reduxjs/toolkit";
import { reportsTableFilteredDataSelector } from "./reportsTableFilteredDataSelector";
import { resourceExplorerIdSelector } from "../../resource-explorer/selectors/current-resource-explorer/resourceExplorerIdSelector";

export const reportsTableFilteredDataByViewIdSelector = createDraftSafeSelector(
  [reportsTableFilteredDataSelector, resourceExplorerIdSelector],
  (reports, id) => {
    return reports?.filter(({ source_id }) => source_id === id);
  },
);
