import React, { FC } from "react";
import { Stack, Typography } from "@mui/material";
import { ManageAllReportsButton } from "./toolbar/ManageAllReportsButton";
import NoData from "../../../../../../assets/images/reports_no_data.svg";
import { CreateReportButton } from "../../CreateReportButton";

interface ReportsTableNoDataProps {
  manageAllButton?: boolean;
  applyOnCreateButtonClick?(): void;
}

export const ReportsTableNoData: FC<ReportsTableNoDataProps> = ({
  manageAllButton,
  applyOnCreateButtonClick,
}) => {
  return (
    <Stack spacing={6} alignItems="center" pt={15}>
      <img src={NoData} alt="NoDataImage" />

      <Stack spacing={4} alignItems="center">
        <Stack spacing={2} alignItems="center">
          <Typography variant="h6" fontWeight="bold">
            Subscribe for automatic updates
          </Typography>

          <Typography variant="body1" color="text.secondary" px={2}>
            Create a subscription to receive automatic updates and insights for
            your selected dashboards or views.
          </Typography>
        </Stack>

        <Stack direction="row" spacing={1}>
          {manageAllButton && <ManageAllReportsButton />}
          <CreateReportButton applyOnClick={applyOnCreateButtonClick} />
        </Stack>
      </Stack>
    </Stack>
  );
};
