import { FC } from "react";
import { IconButton, Tooltip } from "@mui/material";
import { CSVLink } from "react-csv";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import moment from "moment";
import { useAppSelector } from "../../../../../store/hooks";
import { savingsOpportunitiesCSVDataSelector } from "../../../../../store/savings-opportunities/selectors/savingsOpportunitiesCSVDataSelector";

export const SavingsOpportunitiesTableCsvDownloader: FC = () => {
  const data = useAppSelector(savingsOpportunitiesCSVDataSelector);

  const fileName = `${moment().format("MM.DD.YY")}-Savings-Opportunities.csv`;

  return (
    <Tooltip arrow title="Export CSV" placement="top">
      <span>
        <IconButton
          disabled={!data?.length}
          size="small"
          sx={{ "& a": { textDecoration: "none", color: "inherit" }, pb: 0 }}
        >
          <CSVLink data={data} filename={fileName}>
            <DownloadOutlinedIcon fontSize="small" />
          </CSVLink>
        </IconButton>
      </span>
    </Tooltip>
  );
};
