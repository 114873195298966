import { FC, Fragment } from "react";
import { GcpMissingApisDialog } from "./components/apis-dialog/GcpMissingApisDialog";
import { GcpMissingRolesDialog } from "./components/roles-dialog/GcpMissingRolesDialog";

import { GcpCliTabProps } from "./components/roles-dialog/missing-roles-dialog-tabs/cli-tab/GcpCliTab";
import { MissingPermissionsAlertButton } from "../common/MissingPermissionsAlertButton";
import { useDialog } from "../../../../../../../../utils/hooks/useDialog.hook";
import {
  useGetUsersMeProvidersGcpAccountsByAccountIdMissingApisQuery,
  useGetUsersMeProvidersGcpAccountsByAccountIdMissingRolesQuery,
} from "../../../../../../../../services/cloudchipr.api";

interface LiveUsageMgmtGcpMissingsProps extends GcpCliTabProps {
  accountId: string;
}
export const LiveUsageMgmtGcpMissings: FC<LiveUsageMgmtGcpMissingsProps> = ({
  accountId,
  serviceAccount,
  projectId,
}) => {
  const {
    open: rolesOpen,
    openDialog: openRoles,
    closeDialog: closeRoles,
  } = useDialog();
  const {
    open: apisOpen,
    openDialog: openApis,
    closeDialog: closeApis,
  } = useDialog();

  const { data: missingRoles } =
    useGetUsersMeProvidersGcpAccountsByAccountIdMissingRolesQuery(
      { accountId: accountId || "" },
      { skip: !accountId },
    );

  const { data: missingApis } =
    useGetUsersMeProvidersGcpAccountsByAccountIdMissingApisQuery(
      { accountId: accountId || "" },
      { skip: !accountId },
    );

  if (!missingApis?.length && !missingRoles?.length) {
    return null;
  }

  return (
    <Fragment>
      {!!missingRoles?.length && (
        <MissingPermissionsAlertButton
          onOpen={openRoles}
          title="Missing Permissions"
        />
      )}

      {!!missingApis?.length && (
        <MissingPermissionsAlertButton onOpen={openApis} title="Missing APIs" />
      )}

      <GcpMissingRolesDialog
        open={rolesOpen}
        onClose={closeRoles}
        accountId={accountId}
        serviceAccount={serviceAccount}
        projectId={projectId}
      />
      <GcpMissingApisDialog
        open={apisOpen}
        onClose={closeApis}
        accountId={accountId}
      />
    </Fragment>
  );
};
