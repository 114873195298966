import { FC } from "react";
import { useAppSelector } from "../../../../../../../store/hooks";
import { WidgetTotalsSummary } from "../../common/WidgetTotalsSummary";
import { DateLabelNullable } from "../../../../../../../services/cloudchipr.api";
import { costBreakdownWidgetCostsSelector } from "../../../../../../../store/dashboards/selectors/widgets/cost-breakdown/widget-view/costBreakdownWidgetCostsSelector";
import { costBreakdownWidgetVisualizationSelector } from "../../../../../../../store/dashboards/selectors/widgets/cost-breakdown/widget-view/costBreakdownWidgetVisualizationSelector";
import { costBreakdownWidgetFrequencySelector } from "../../../../../../../store/dashboards/selectors/widgets/cost-breakdown/widget-view/costBreakdownWidgetFrequencySelector";
import { costBreakdownWidgetForecastOptionSelector } from "../../../../../../../store/dashboards/selectors/widgets/cost-breakdown/widget-view/costBreakdownWidgetForecastOptionSelector";
import { costBreakdownWidgetAggregationsSelector } from "../../../../../../../store/dashboards/selectors/widgets/cost-breakdown/widget-view/costBreakdownWidgetAggregationsSelector";

interface CostBreakdownWidgetContentCostsProps {
  widgetId: string;
  dateLabel?: DateLabelNullable;
}

export const CostBreakdownWidgetContentCosts: FC<
  CostBreakdownWidgetContentCostsProps
> = ({ widgetId, dateLabel }) => {
  const costsData = useAppSelector((state) =>
    costBreakdownWidgetCostsSelector(state, widgetId),
  );

  const visualisations = useAppSelector((state) =>
    costBreakdownWidgetVisualizationSelector(state, widgetId),
  );

  const frequency = useAppSelector((state) =>
    costBreakdownWidgetFrequencySelector(state, widgetId),
  );

  const forecastOption = useAppSelector((state) =>
    costBreakdownWidgetForecastOptionSelector(state, widgetId),
  );

  const aggregations = useAppSelector((state) =>
    costBreakdownWidgetAggregationsSelector(state, widgetId),
  );

  if (!costsData || !frequency || !forecastOption) {
    return null;
  }

  return (
    <WidgetTotalsSummary
      frequency={frequency}
      costsData={costsData}
      forecastOption={forecastOption}
      dateLabel={dateLabel}
      aggregations={aggregations}
      showTrends={visualisations?.trend}
    />
  );
};
