import { FC } from "react";
import { Stack } from "@mui/material";
import { ResourceExplorerGcpErrorBillingStatusPanelItem } from "./ResourceExplorerGcpErrorBillingStatusPanelItem";
import { ResourceExplorerAwsErrorBillingStatusPanelItem } from "./ResourceExplorerAwsErrorBillingStatusPanelItem";
import { ResourceExplorerAzureErrorBillingStatusPanelItem } from "./ResourceExplorerAzureErrorBillingStatusPanelItem";
import { useAppSelector } from "../../../../../../../../store/hooks";
import { billingErrorAccountStatusesSelector } from "../../../../../../../../store/common/selectors/billing-status/error/billingErrorAccountStatusesSelector";

export const ResourceExplorerErrorBillingStatusPanel: FC = () => {
  const errorAccountStatuses = useAppSelector(
    billingErrorAccountStatusesSelector,
  );

  return (
    <Stack spacing={2}>
      {errorAccountStatuses.map((item) => {
        const key = `${item.account_id}${item.provider}`;
        if (item.provider === "gcp") {
          return (
            <ResourceExplorerGcpErrorBillingStatusPanelItem
              key={key}
              name={item?.name ?? ""}
              error={item.problem_message ?? ""}
              providerAccountId={item?.provider_account_id}
            />
          );
        }

        if (item.provider === "aws") {
          return (
            <ResourceExplorerAwsErrorBillingStatusPanelItem
              error={item.problem_message ?? ""}
              accountId={item.account_id}
              key={key}
              name={item?.name}
              providerAccountId={item.provider_account_id}
            />
          );
        }

        if (item.provider === "azure") {
          return (
            <ResourceExplorerAzureErrorBillingStatusPanelItem
              key={key}
              name={item?.name ?? ""}
              error={item.problem_message ?? ""}
              providerAccountId={item?.provider_account_id}
            />
          );
        }
        return null;
      })}
    </Stack>
  );
};
