import { FC } from "react";
import { Box, Card } from "@mui/material";
import { ReservationsMultiTypeChart } from "./chart/multi-type-chart/ReservationsMultiTypeChart";
import { ReservationsGaugeChart } from "./chart/ReservationsGaugeChart";
import { ReservationsDataGrid } from "./data-grid/ReservationsDataGrid";

export const ReservationsContent: FC = () => {
  return (
    <Card sx={{ p: 2 }} variant="outlined">
      <ReservationsMultiTypeChart />
      <ReservationsGaugeChart />

      <Box mt={4}>
        <ReservationsDataGrid />
      </Box>
    </Card>
  );
};
