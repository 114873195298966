import { providerDisabledTooltipTitleCombiner } from "./providerDisabledTooltipTitleCombiner";
import { RootState } from "../../../../../store";
import { azureNonDeletedAndNotBillingAccountsSelector } from "../../../../../accounts/selectors/azure/azureNonDeletedAndNotBillingAccountsSelector";
import { automationTypeSelector } from "../../automationTypeSelector";

export const azureDisabledTooltipTitleSelector = (state: RootState) => {
  const accounts = azureNonDeletedAndNotBillingAccountsSelector(state);
  const automationType = automationTypeSelector(state);

  return providerDisabledTooltipTitleCombiner(
    automationType === "offHours",
    "azure",
    accounts,
  );
};
