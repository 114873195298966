import { DateRange } from "moment-range";

interface FindRecommendedRangesArgs {
  mainRange: DateRange;
  firstRange: DateRange;
  rangeType: "day" | "week";
}

export const findRecommendedRanges = ({
  firstRange,
  mainRange,
  rangeType,
}: FindRecommendedRangesArgs) => {
  const ranges: DateRange[] = [];
  let currentRange = firstRange;

  while (true) {
    if (currentRange.start.isAfter(mainRange.end)) {
      break;
    }

    ranges.push(currentRange);

    const newRange = currentRange.clone();
    newRange.start.add(1, rangeType);
    newRange.end.add(1, rangeType);
    currentRange = newRange;
  }

  return ranges;
};
