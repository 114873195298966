import { FC } from "react";
import { Box, Typography } from "@mui/material";
import { money } from "../../../../../../../../utils/numeral/money";
import { useAppSelector } from "../../../../../../../../store/hooks";
import { liveUsageMgmtFilterTemplatesEnabledSelector } from "../../../../../../../../store/live-usage-mgmt/selectors/store-selectors/liveUsageMgmtFilterTemplatesEnabledSelector";

interface LiveUsageMgmtNavItemStatsProps {
  ems: number;
  total: number;
}

export const LiveUsageMgmtNavItemStats: FC<LiveUsageMgmtNavItemStatsProps> = ({
  ems,
  total,
}) => {
  const filterTemplatesEnabled = useAppSelector(
    liveUsageMgmtFilterTemplatesEnabledSelector,
  );
  return (
    <Box whiteSpace="nowrap">
      {filterTemplatesEnabled && (
        <Typography color="error" variant="caption" component="span">
          {money(ems, 1000)}
        </Typography>
      )}
      <Typography color="text.secondary" variant="caption" component="span">
        {filterTemplatesEnabled && " / "}
        {money(total, 1000)}
      </Typography>
    </Box>
  );
};
