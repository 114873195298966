import { RootState } from "../../../store";
import { ResourceType } from "../../../../services/cloudchipr.api";
import { ResourceDataType } from "../../../live-usage-mgmt/utils/types/types";
import { liveUsageMgmtResourceTypeDataSelector } from "../../../live-usage-mgmt/selectors/resource-type-data/liveUsageMgmtResourceTypeDataSelector";

export const liveFilteredResourcesSelector = (
  state: RootState,
  resourceType: ResourceType,
): ResourceDataType[] | undefined => {
  return liveUsageMgmtResourceTypeDataSelector(state, resourceType)?.resources;
};
