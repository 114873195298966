import { commitmentsRecommendationsFiltersSelector } from "./commitmentsRecommendationsFiltersSelector";
import { RootState } from "../../../../store";
import {
  ReservationType,
  SavingsPlanType,
} from "../../../../../services/cloudchipr.api";

export const commitmentsRecommendationsSavingsPlanTypeSelector = (
  state: RootState,
): SavingsPlanType[] | ReservationType[] | undefined => {
  const filters = commitmentsRecommendationsFiltersSelector(state);

  return filters.type ?? undefined;
};
