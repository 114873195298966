import { FC } from "react";
import LightbulbOutlinedIcon from "@mui/icons-material/LightbulbOutlined";
import { Box, Divider, IconButton, Typography } from "@mui/material";
import PopupState, {
  bindPopover,
  bindHover,
  bindFocus,
} from "material-ui-popup-state";
import HoverPopover from "material-ui-popup-state/HoverPopover";
import { RecommendationLinkPopoverContent } from "./RecommendationLinkPopoverContent";
import {
  Recommendations,
  ResourceType,
} from "../../../../../../../../../../../services/cloudchipr.api";
import { money } from "../../../../../../../../../../../utils/numeral/money";
import {
  generateOffHoursRecommendationLink,
  generateRightSizingRecommendationLink,
  getMaxSavingRecommendation,
} from "../../utils/helpers/recommendationLinkGenerators";

interface RecommendationLinkIconProps {
  resourceType?: ResourceType;
  rightsizingRecommendations?: Recommendations["off_hours_recommendations"];
  offHoursRecommendations?: Recommendations["off_hours_recommendations"];
  accountId?: string;
}

export const RecommendationLinkIcon: FC<RecommendationLinkIconProps> = ({
  resourceType,
  rightsizingRecommendations,
  offHoursRecommendations,
  accountId,
}) => {
  const maxSavingRightsizingRecommendation = getMaxSavingRecommendation(
    rightsizingRecommendations,
  );
  const maxSavingOffHoursRecommendation = getMaxSavingRecommendation(
    offHoursRecommendations,
  );
  const rightsizingLink = generateRightSizingRecommendationLink(
    maxSavingRightsizingRecommendation?.id,
    accountId,
  );
  const offHoursLink = generateOffHoursRecommendationLink(
    resourceType,
    maxSavingOffHoursRecommendation,
  );

  if (!(rightsizingLink ?? offHoursLink)) {
    return null;
  }

  return (
    <PopupState
      variant="popover"
      popupId={`demo-popup-popover-${resourceType}`}
    >
      {(popupState) => (
        <div>
          <IconButton
            size="small"
            {...bindHover(popupState)}
            {...bindFocus(popupState)}
          >
            <LightbulbOutlinedIcon color="warning" fontSize="inherit" />

            {popupState.isOpen && (
              <Box
                {...bindHover(popupState)}
                {...bindFocus(popupState)}
                position="absolute"
                width={120}
                height={50}
              />
            )}
          </IconButton>

          <HoverPopover
            {...bindPopover(popupState)}
            transformOrigin={{
              horizontal: "left",
              vertical: "top",
            }}
            anchorOrigin={{
              horizontal: "left",
              vertical: "bottom",
            }}
          >
            <Typography
              variant="body1"
              color="text.primary"
              fontWeight="medium"
              sx={{ pl: 2, py: 1 }}
            >
              Recommendations
            </Typography>
            <Divider />
            <RecommendationLinkPopoverContent
              title="Off Hours"
              link={offHoursLink}
              maxSaving={money(
                maxSavingOffHoursRecommendation?.potential_savings,
              )}
            />
            <Divider />
            <RecommendationLinkPopoverContent
              title="Rightsizing"
              link={rightsizingLink}
              maxSaving={money(
                maxSavingRightsizingRecommendation?.potential_savings,
              )}
            />
          </HoverPopover>
        </div>
      )}
    </PopupState>
  );
};
