import { emptySplitApiAuthLess as api } from "./empty.authless.api";
import { CostType } from "./cloudchipr.api";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    postAccountsByAccountIdResources: build.mutation<
      PostAccountsByAccountIdResourcesApiResponse,
      PostAccountsByAccountIdResourcesApiArg
    >({
      query: (queryArg) => ({
        url: `/accounts/${queryArg.accountId}/resources`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
    getProvidersByProviderFilters: build.query<
      GetProvidersByProviderFiltersApiResponse,
      GetProvidersByProviderFiltersApiArg
    >({
      query: (queryArg) => ({
        url: `/providers/${queryArg.pathProvider}/filters`,
      }),
    }),
    getAccountsByAccountId: build.query<
      GetAccountsByAccountIdApiResponse,
      GetAccountsByAccountIdApiArg
    >({
      query: (queryArg) => ({ url: `/accounts/${queryArg.accountId}` }),
    }),
    getAccounts: build.query<GetAccountsApiResponse, GetAccountsApiArg>({
      query: (queryArg) => ({
        url: `/accounts`,
        params: {
          statuses: queryArg.statuses,
          provider: queryArg.provider,
          access_type: queryArg.accessType,
        },
      }),
    }),
    getProvidersByProviderRegions: build.query<
      GetProvidersByProviderRegionsApiResponse,
      GetProvidersByProviderRegionsApiArg
    >({
      query: (queryArg) => ({
        url: `/providers/${queryArg.pathProvider}/regions`,
      }),
    }),
    getDashboardsByDashboardId: build.query<
      GetDashboardsByDashboardIdApiResponse,
      GetDashboardsByDashboardIdApiArg
    >({
      query: (queryArg) => ({ url: `/dashboards/${queryArg.dashboardId}` }),
    }),
    getWidgetsLargestCostChangesByWidgetId: build.query<
      GetWidgetsLargestCostChangesByWidgetIdApiResponse,
      GetWidgetsLargestCostChangesByWidgetIdApiArg
    >({
      query: (queryArg) => ({
        url: `/widgets/largest-cost-changes/${queryArg.widgetId}`,
        params: {
          category_id: queryArg.categoryId,
          date_label: queryArg.dateLabel,
          date_from: queryArg.dateFrom,
          date_to: queryArg.dateTo,
          date_type: queryArg.dateType,
          date_value: queryArg.dateValue,
          date_unit: queryArg.dateUnit,
        },
      }),
    }),
    getWidgetsCostAndUsageSummaryByWidgetId: build.query<
      GetWidgetsCostAndUsageSummaryByWidgetIdApiResponse,
      GetWidgetsCostAndUsageSummaryByWidgetIdApiArg
    >({
      query: (queryArg) => ({
        url: `/widgets/cost-and-usage-summary/${queryArg.widgetId}`,
        params: {
          category_id: queryArg.categoryId,
          date_label: queryArg.dateLabel,
          date_from: queryArg.dateFrom,
          date_to: queryArg.dateTo,
          date_type: queryArg.dateType,
          date_value: queryArg.dateValue,
          date_unit: queryArg.dateUnit,
        },
      }),
    }),
    getWidgetsCostBreakdownByWidgetId: build.query<
      GetWidgetsCostBreakdownByWidgetIdApiResponse,
      GetWidgetsCostBreakdownByWidgetIdApiArg
    >({
      query: (queryArg) => ({
        url: `/widgets/cost-breakdown/${queryArg.widgetId}`,
        params: {
          category_id: queryArg.categoryId,
          date_label: queryArg.dateLabel,
          date_from: queryArg.dateFrom,
          date_to: queryArg.dateTo,
          date_type: queryArg.dateType,
          date_value: queryArg.dateValue,
          date_unit: queryArg.dateUnit,
        },
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as cloudChiprApiAuthLess };
export type PostAccountsByAccountIdResourcesApiResponse =
  /** status 200 Json object of resources that matched with filters */ {
    /** List of elastic block stores */
    ebs: EbsRead[];
    /** List of AWS ec2 instances */
    ec2: Ec2Read[];
    /** List of AWS rds instances */
    rds: RdsRead[];
    /** List of AWS eip addresses */
    eip: EipRead[];
    /** List of AWS elasticache resources */
    elc: ElcRead[];
    /** List of AWS load balancers */
    elb: ElbRead[];
    /** List of GCP virtual machines */
    vm: VmRead[];
    /** List of GCP disks */
    disk: DiskRead[];
    /** List of GCP sql instances */
    sql: SqlRead[];
    /** List of GCP Ip addresses */
    ip: IpRead[];
    /** List of GCP load balancers */
    lb: LbRead[];
  };
export type PostAccountsByAccountIdResourcesApiArg = {
  /** The id of account which resources will be fetched */
  accountId: string;
  body: {
    /** The resource type to which filters will be applied */
    type:
      | "ec2"
      | "ebs"
      | "eip"
      | "elb"
      | "rds"
      | "elc"
      | "vm"
      | "disk"
      | "ip"
      | "lb"
      | "sql";
    filters: {
      key: any & FilterKeyType;
      value?:
        | (
            | (string | null)
            | (number | null)
            | (number | null)
            | (boolean | null)
          )
        | null;
      operator: any & FilterOperatorType;
      metric?: any & FilterMetric;
    }[];
  }[];
};
export type GetProvidersByProviderFiltersApiResponse =
  /** status 200 Example response */ {
    type?: ResourceType;
    filters?: FilterItemTemplate[];
  }[];
export type GetProvidersByProviderFiltersApiArg = {
  pathProvider: "aws" | "gcp";
  /** Cloud provider */
  _pathProvider: ProviderType;
};
export type GetAccountsByAccountIdApiResponse = /** status 200 OK */ Account;
export type GetAccountsByAccountIdApiArg = {
  accountId: string;
};
export type GetAccountsApiResponse = /** status 200 OK */ Account[];
export type GetAccountsApiArg = {
  statuses?: string;
  provider?: "aws" | "gcp";
  accessType?: "read" | "read_write";
};
export type GetProvidersByProviderRegionsApiResponse =
  /** status 200 OK */ Regions;
export type GetProvidersByProviderRegionsApiArg = {
  pathProvider: "aws" | "gcp" | "azure";
  /** Cloud provider */
  _pathProvider: ProviderType;
};
export type GetDashboardsByDashboardIdApiResponse =
  /** status 200 OK */ DashboardDetails;
export type GetDashboardsByDashboardIdApiArg = {
  dashboardId: string;
};
export type GetWidgetsLargestCostChangesByWidgetIdApiResponse =
  /** status 200 Largest Cost Changes Widget Response */ {
    total: ResourceExplorerGridTotal[];
    previous_period_cost_date_details: ResourceExplorerCostsDateDetails;
    current_period_cost_date_details: ResourceExplorerCostsDateDetails;
  };
export type GetWidgetsLargestCostChangesByWidgetIdApiArg = {
  widgetId: string;
  categoryId?: string;
  dateLabel?: DateLabelNullable;
  dateFrom?: NullableDate;
  dateTo?: NullableDate;
  dateType?: DateType;
  dateValue?: number | null;
  dateUnit?: DateUnit;
};
export type GetWidgetsCostAndUsageSummaryByWidgetIdApiResponse =
  /** status 200 Cost And Usage Summary Widget Response */ {
    total_cost: number;
    previous_period_total_cost: number;
    average_daily_cost_details: AverageDailyCostDetails;
    average_monthly_cost_details: AverageMonthlyCostDetails;
    live_usage_total_cost: number;
    total_cost_date_details: ResourceExplorerCostsDateDetails;
    previous_period_cost_date_details: ResourceExplorerCostsDateDetails;
    forecasted_costs: ForecastedCostRe;
    trend_type?: TrendType;
  };
export type GetWidgetsCostAndUsageSummaryByWidgetIdApiArg = {
  widgetId: string;
  categoryId?: string;
  dateLabel?: DateLabelNullable;
  dateFrom?: NullableDate;
  dateTo?: NullableDate;
  dateType?: DateType;
  dateValue?: number | null;
  dateUnit?: DateUnit;
};
export type GetWidgetsCostBreakdownByWidgetIdApiResponse =
  /** status 200 Cost Breakdown Widget Response */ {
    data: ResourceExplorerChartData[];
    total: ResourceExplorerGridTotal[];
    total_cost: number;
    previous_period_total_cost: number;
    average_daily_cost_details: AverageDailyCostDetails;
    average_monthly_cost_details: AverageMonthlyCostDetails;
    live_usage_total_cost: number;
    total_cost_date_details: ResourceExplorerCostsDateDetails;
    previous_period_cost_date_details: ResourceExplorerCostsDateDetails;
    forecasted_costs: ForecastedCostRe;
    trend_type?: TrendType;
    cost_type: CostType;
  };
export type GetWidgetsCostBreakdownByWidgetIdApiArg = {
  widgetId: string;
  categoryId?: string;
  dateLabel?: DateLabelNullable;
  dateFrom?: NullableDate;
  dateTo?: NullableDate;
  dateType?: DateType;
  dateValue?: number | null;
  dateUnit?: DateUnit;
};
export type Date = string;
export type Tags = {
  key: string;
  value: string;
};
export type Ebs = {
  volume_id: string;
  size: string;
  state: string;
  type: string;
  price_per_month: number;
  availability_zone: string;
  has_attachments: boolean;
  created_at: any & Date;
  /** List of tags */
  tags: (any & Tags)[];
  /** Region of ebs volume */
  region: string;
  link: string;
};
export type EbsRead = {
  id: string;
  volume_id: string;
  size: string;
  state: string;
  type: string;
  price_per_month: number;
  availability_zone: string;
  has_attachments: boolean;
  created_at: any & Date;
  /** List of tags */
  tags: (any & Tags)[];
  /** Region of ebs volume */
  region: string;
  link: string;
};
export type Metric = {
  timestamp: string;
  average: number;
  maximum: number;
  minimum: number;
  sum: number;
  unit: string;
};
export type Ec2 = {
  image_id: string;
  instance_id: string;
  is_spot_instance: boolean;
  type: string;
  cpu: Metric[];
  cpu_by_hours: Metric[];
  network_in: Metric[];
  network_in_by_hours: Metric[];
  network_out: Metric[];
  network_out_by_hours: Metric[];
  created_at: any & Date;
  price_per_month: number;
  availability_zone: string;
  region: string;
  tags: any & Tags;
  link: string;
};
export type Ec2Read = {
  id: string;
  image_id: string;
  instance_id: string;
  is_spot_instance: boolean;
  type: string;
  cpu: Metric[];
  cpu_by_hours: Metric[];
  network_in: Metric[];
  network_in_by_hours: Metric[];
  network_out: Metric[];
  network_out_by_hours: Metric[];
  created_at: any & Date;
  price_per_month: number;
  availability_zone: string;
  region: string;
  tags: any & Tags;
  link: string;
};
export type Rds = {
  db_id: string;
  price_per_month: number;
  instance_type: string;
  storage_type: string;
  connections_by_hours: Metric[];
  cpu: Metric[];
  cpu_by_hours: Metric[];
  connections: Metric[];
  db_type: string;
  created_at: any & Date;
  availability_zone: string;
  status: string;
  region: string;
  tags: any & Tags;
  cluster_id: string | null;
  nodes_count: number | null;
  link: string;
};
export type RdsRead = {
  id: string;
  db_id: string;
  price_per_month: number;
  instance_type: string;
  storage_type: string;
  connections_by_hours: Metric[];
  cpu: Metric[];
  cpu_by_hours: Metric[];
  connections: Metric[];
  db_type: string;
  created_at: any & Date;
  availability_zone: string;
  status: string;
  region: string;
  tags: any & Tags;
  cluster_id: string | null;
  nodes_count: number | null;
  link: string;
};
export type Eip = {
  ip: string;
  allocation_id: string | null;
  association_id: string | null;
  instance_id: string | null;
  domain: string | null;
  price_per_month: number;
  created_at: any & Date;
  region: string;
  tags: (any & Tags)[];
  link: string;
};
export type EipRead = {
  id: string;
  ip: string;
  allocation_id: string | null;
  association_id: string | null;
  instance_id: string | null;
  domain: string | null;
  price_per_month: number;
  created_at: any & Date;
  region: string;
  tags: (any & Tags)[];
  link: string;
};
export type Elc = {
  cluster_id: string;
  price_per_month: number;
  instance_type: string;
  cache_type: "redis" | "memcached";
  nodes_count: number;
  connections: Metric[];
  connections_by_hours: Metric[];
  cpu: Metric[];
  cpu_by_hours?: Metric[];
  created_at: any & Date;
  region: string;
  tags: Tags & any;
  link: string;
};
export type ElcRead = {
  id: string;
  cluster_id: string;
  price_per_month: number;
  instance_type: string;
  cache_type: "redis" | "memcached";
  nodes_count: number;
  connections: Metric[];
  connections_by_hours: Metric[];
  cpu: Metric[];
  cpu_by_hours?: Metric[];
  created_at: any & Date;
  region: string;
  tags: Tags & any;
  link: string;
};
export type Elb = {
  type: string;
  load_balancer_name: string;
  load_balancer_arn: string | null;
  price_per_month: number;
  dns_name: string;
  created_at: any & Date;
  region: string;
  has_attachments: boolean;
  tags: Tags & any;
  link: string;
};
export type ElbRead = {
  id: string;
  type: string;
  load_balancer_name: string;
  load_balancer_arn: string | null;
  price_per_month: number;
  dns_name: string;
  created_at: any & Date;
  region: string;
  has_attachments: boolean;
  tags: Tags & any;
  link: string;
};
export type Vm = {
  image_id: string;
  type: string;
  cpu: Metric[];
  cpu_by_hours: Metric[];
  network_in: Metric[];
  network_in_by_hours: Metric[];
  network_out: Metric[];
  network_out_by_hours: Metric[];
  created_at: any & Date;
  price_per_month: number;
  availability_zone: string;
  region: string | null;
  tags: any & Tags;
  link: string;
};
export type VmRead = {
  id: string;
  image_id: string;
  type: string;
  cpu: Metric[];
  cpu_by_hours: Metric[];
  network_in: Metric[];
  network_in_by_hours: Metric[];
  network_out: Metric[];
  network_out_by_hours: Metric[];
  created_at: any & Date;
  price_per_month: number;
  availability_zone: string;
  region: string | null;
  tags: any & Tags;
  link: string;
};
export type Disk = {
  name: string;
  price_per_month: number;
  type: string;
  size: number;
  state: string;
  availability_zone: string;
  region: string | null;
  tags: any & Tags;
  has_attachments: boolean;
  link: string;
};
export type DiskRead = {
  id: string;
  name: string;
  price_per_month: number;
  type: string;
  size: number;
  state: string;
  availability_zone: string;
  region: string | null;
  tags: any & Tags;
  has_attachments: boolean;
  link: string;
};
export type Sql = {
  multi_az: boolean;
  price_per_month: number;
  cpu_size: string;
  ram_size: string;
  storage: string;
  connections_by_hours: Metric[];
  cpu: Metric[];
  cpu_by_hours: Metric[];
  connections: Metric[];
  type: string;
  created_at: any & Date;
  region: string;
  tags: any & Tags;
  link: string;
};
export type SqlRead = {
  id: string;
  multi_az: boolean;
  price_per_month: number;
  cpu_size: string;
  ram_size: string;
  storage: string;
  connections_by_hours: Metric[];
  cpu: Metric[];
  cpu_by_hours: Metric[];
  connections: Metric[];
  type: string;
  created_at: any & Date;
  region: string;
  tags: any & Tags;
  link: string;
};
export type Ip = {
  ip: string;
  name: string;
  is_in_use: boolean;
  price_per_month: number;
  region: string;
  tags: any & Tags;
  created_at: any & Date;
  link: string;
};
export type IpRead = {
  id: string;
  ip: string;
  name: string;
  is_in_use: boolean;
  price_per_month: number;
  region: string;
  tags: any & Tags;
  created_at: any & Date;
  link: string;
};
export type Lb = {
  load_balancer_name: string;
  price_per_month: number;
  type: "TCP";
  global: boolean;
  created_at: any & Date;
  has_attachments: boolean;
  tags: any & Tags;
  region?: string | null;
  link: string;
};
export type LbRead = {
  id: string;
  load_balancer_name: string;
  price_per_month: number;
  type: "TCP";
  global: boolean;
  created_at: any & Date;
  has_attachments: boolean;
  tags: any & Tags;
  region?: string | null;
  link: string;
};
export type FilterKeyType =
  | "launch_time"
  | "cpu"
  | "network_in"
  | "network_out"
  | "tag"
  | "attachments"
  | "volume_age"
  | "database_connections"
  | "instances"
  | "instance_ids"
  | "association_ids"
  | "region"
  | "in_use"
  | "total_connections"
  | "cloudchipr_ttl"
  | "provider_unique_identifier";
export type FilterOperatorType =
  | "equal"
  | "not_equal"
  | "greater_than"
  | "greater_than_equal_to"
  | "less_than"
  | "less_than_equal_to"
  | "exists"
  | "does_not_exist"
  | "contains"
  | "does_not_contain"
  | "is_empty"
  | "is_not_empty"
  | "is_absent"
  | "is_not_absent"
  | "in"
  | "is_true"
  | "is_false"
  | "is_expired"
  | "is_invalid"
  | "does_never_expire"
  | "not_in";
export type FilterStatisticsType = "sum" | "maximum" | "minimum" | "average";
export type FilterMetric = {
  statistics_type?: FilterStatisticsType;
  since?: {
    value?: number;
    unit?: "hours" | "days";
  };
};
export type ResourceType =
  | "ec2"
  | "ebs"
  | "elb"
  | "eip"
  | "rds"
  | "vm"
  | "disk"
  | "lb"
  | "ip"
  | "sql"
  | "elc";
export type FilterItemTemplate = {
  key: FilterKeyType;
  value_type?: ("number" | "percentage" | "boolean" | "string") | null;
  min_value: number | null;
  max_value: number | null;
  allowed_operators: FilterOperatorType[];
};
export type ProviderType = "aws" | "gcp";
export type AwsAccountDetails = {
  is_root: boolean | null;
  organisation_id: string | null;
};
export type GcpAccountDetails = {
  project_id: string | null;
  service_account: string;
};
export type AccountProviderDetailsResponse = {
  aws: (any | null) & AwsAccountDetails;
  gcp: (any | null) & GcpAccountDetails;
};
export type Account = {
  id: string;
  type: string;
  access_type: string;
  provider_account_id: string;
  provider_account_name: string | null;
  provider_details: AccountProviderDetailsResponse & (any | null);
  is_sub_account: boolean;
  status: string;
  created_at: string;
  outdated: boolean;
  last_resource_updated_date: string | null;
  estimated_monthly_savings: number;
  total_monthly_saved_costs: number;
  total_costs: number;
};
export type Region = {
  name: string;
  key: string;
};
export type Regions = Region[];
export type DashboardDetails = any;
export type AFlexibleKeyValueMapContainingMetadataAboutTheRow = {
  [key: string]: string;
};
export type ForecastOption = "month" | "quarter";
export type ForecastedCost = {
  cost: number;
  forecast_option: ForecastOption;
  trend: number | null;
  date_from: Date;
  date_to: Date;
  previous_period_from: Date;
  previous_period_to: Date;
};
export type NullableForecastedCost = (any | null) & ForecastedCost;
export type ResourceExplorerGridTotal = {
  field: string;
  label?: string | null;
  total_cost: number;
  previous_period_total_cost: number | null;
  period_total_cost_difference: number | null;
  trend: number | null;
  cloud_provider: ProviderType;
  metadata: AFlexibleKeyValueMapContainingMetadataAboutTheRow;
  live_usage_cost?: number | null;
  monthly_forecast: NullableForecastedCost;
  quarterly_forecast: NullableForecastedCost;
};
export type ResourceExplorerCostsDateDetails = {
  from_date: Date;
  to_date: Date;
};
export type DateLabel =
  | "year_to_date"
  | "month_to_date"
  | "last_one_month"
  | "last_three_months"
  | "last_six_months"
  | "past_fourteen_days"
  | "past_seven_days"
  | "past_thirty_days"
  | "past_forty_five_days"
  | "past_ninety_days"
  | "past_one_hundred_eighty_days"
  | "last_twelve_months"
  | "quarter_to_date"
  | "last_quarter";
export type DateLabelNullable = DateLabel | null;
export type NullableDate = string | null;
export type DateType = "past" | "last";
export type DateUnit = "day" | "week" | "month";
export type AverageDailyCostDetails = {
  from_date: Date;
  to_date: Date;
  average_daily_cost: number | null;
  trend: number | null;
};
export type AverageMonthlyCostDetails = {
  from_date: Date;
  to_date: Date;
  average_monthly_cost: number | null;
  trend: number | null;
};
export type ForecastedCostRe = ForecastedCost[];
export type TrendType = "period_over_period" | "month_over_month";
export type ResourceExplorerChartDataItem = {
  field?: string;
  label?: string | null;
  cost?: number;
};
export type ResourceExplorerChartData = {
  date?: string;
  items?: ResourceExplorerChartDataItem[];
};
export const {
  usePostAccountsByAccountIdResourcesMutation,
  useGetProvidersByProviderFiltersQuery,
  useGetAccountsByAccountIdQuery,
  useGetAccountsQuery,
  useGetProvidersByProviderRegionsQuery,
  useGetDashboardsByDashboardIdQuery,
  useGetWidgetsLargestCostChangesByWidgetIdQuery,
  useGetWidgetsCostAndUsageSummaryByWidgetIdQuery,
  useGetWidgetsCostBreakdownByWidgetIdQuery,
} = injectedRtkApi;
