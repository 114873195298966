import { costBreakdownWidgetByIdSelector } from "./costBreakdownWidgetByIdSelector";
import { RootState } from "../../../../../store";
import { ForecastOption } from "../../../../../../services/cloudchipr.api";

export const costBreakdownWidgetForecastOptionSelector = (
  state: RootState,
  widgetId: string,
): ForecastOption | undefined => {
  const data = costBreakdownWidgetByIdSelector(state, widgetId);
  if (!data) {
    return;
  }
  return data?.forecast_option;
};
