import { FC } from "react";
import { Stack, Typography } from "@mui/material";
import { IntegrationUtilizationAlertCommitmentsUtilizationEmailHeader } from "./IntegrationUtilizationAlertCommitmentsUtilizationEmailHeader";
import { IntegrationUtilizationAlertCommitmentsUtilizationSlackHeader } from "./IntegrationUtilizationAlertCommitmentsUtilizationSlackHeader";
import { IntegrationUtilizationAlertCommitmentsUtilizationDetails } from "./IntegrationUtilizationAlertCommitmentsUtilizationDetails";

interface IntegrationUtilizationAlertCommitmentsUtilizationMessageProps {
  message: string;
  type: "email" | "slack";
}

export const IntegrationUtilizationAlertCommitmentsUtilizationMessage: FC<
  IntegrationUtilizationAlertCommitmentsUtilizationMessageProps
> = ({ message, type }) => {
  return (
    <Stack spacing={1}>
      {type === "email" && (
        <IntegrationUtilizationAlertCommitmentsUtilizationEmailHeader />
      )}

      {type === "slack" && (
        <IntegrationUtilizationAlertCommitmentsUtilizationSlackHeader />
      )}

      <IntegrationUtilizationAlertCommitmentsUtilizationDetails />

      <Typography
        variant="body1"
        dangerouslySetInnerHTML={{ __html: message }}
      />
    </Stack>
  );
};
