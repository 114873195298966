import { FC } from "react";
import {
  Button,
  Checkbox,
  Divider,
  Link,
  Stack,
  Typography,
} from "@mui/material";
import OpenInNewOutlinedIcon from "@mui/icons-material/OpenInNewOutlined";

export const OrganizationAccessSection: FC = () => {
  return (
    <Stack>
      <Divider
        sx={{
          my: 4,
        }}
      />
      <Typography variant="h6" fontWeight="medium">
        Access
      </Typography>

      <Stack direction="row" spacing={0.5}>
        <Checkbox checked disabled size="small" />
        <Typography variant="body1" color="text.secondary">
          Your account representative at Cloudchipr has read-only access to the
          Cloudchipr UI to deliver faster and more effective support for you and
          your team.
        </Typography>
      </Stack>

      <Button
        component={Link}
        href={
          "https://communityinviter.com/apps/cloudchiprcommunity/landing-page"
        }
        color="primary"
        variant="outlined"
        endIcon={<OpenInNewOutlinedIcon fontSize="small" />}
        sx={{
          alignSelf: "flex-end",
          pt: 0.5,
        }}
        size="small"
        target="_blank"
      >
        Contact us
      </Button>
    </Stack>
  );
};
