import { Stack } from "@mui/material";
import { CommitmentsDrawerDataGridGrouping } from "./CommitmentsDrawerDataGridGrouping";
import { CommitmentsDrawerCSVDownloader } from "./CommitmentsDrawerCSVDownloader";
import { GlobalFilter } from "../../../../../../../storybook/data-grid/toolbar/GlobalFilter";
import { RenderToolbar } from "../../../../../../../storybook/data-grid/utils/types/prop-types";

export const CommitmentsDrawerDataGridToolbar: RenderToolbar<unknown> = ({
  setGlobalFilter,
  globalFilter,
}) => {
  return (
    <Stack
      pb={1.5}
      direction="row"
      alignItems="start"
      justifyContent="space-between"
    >
      <CommitmentsDrawerDataGridGrouping />

      <Stack alignItems="center" direction="row" spacing={2}>
        <GlobalFilter
          globalFilter={globalFilter}
          setGlobalFilter={setGlobalFilter}
          size="small"
        />

        <CommitmentsDrawerCSVDownloader />
      </Stack>
    </Stack>
  );
};
