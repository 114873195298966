import { createDraftSafeSelector } from "@reduxjs/toolkit";
import { reservationsGranularDataSelector } from "./reservationsGranularDataSelector";
import { CommitmentDateGranularDataPoint } from "../../../../../../services/cloudchipr.api";

export const reservationsGranularDataGroupedByDateSelector =
  createDraftSafeSelector(
    [reservationsGranularDataSelector],
    (data): Record<string, CommitmentDateGranularDataPoint> | undefined => {
      return data?.reduce(
        (acc, data) => {
          acc[data.date] = data;

          return acc;
        },
        {} as Record<string, CommitmentDateGranularDataPoint>,
      );
    },
  );
