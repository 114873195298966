import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { UserSlice } from "./utils/types/common";
import { Permission } from "../../services/cloudchipr.api";

const initialState: UserSlice = {
  roles: {
    data: {
      id: undefined,
      name: "",
      description: "",
      permissions: null,
    },
  },
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUserRoleData: (
      state,
      action: PayloadAction<Partial<UserSlice["roles"]["data"]>>,
    ) => {
      state.roles.data = {
        ...state.roles.data,
        ...action.payload,
      };
    },
    setUserRoleDataPermissionsGroupCollapsed: (
      state,
      action: PayloadAction<{ group: Permission["group"]; collapsed: boolean }>,
    ) => {
      const { group, collapsed } = action.payload;
      if (state.roles.data.permissions?.[group]) {
        state.roles.data.permissions[group] = {
          ...state.roles.data.permissions[group],
          collapsed: collapsed,
        };
      }
    },
    resetUserRoleData: (state) => {
      state.roles.data = initialState.roles.data;
    },
  },
});

export const {
  setUserRoleData,
  setUserRoleDataPermissionsGroupCollapsed,
  resetUserRoleData,
} = userSlice.actions;

export default userSlice.reducer;
