import { costAndUsageSumSetupWidgetDataResponseSelector } from "./costAndUsageSumSetupWidgetDataResponseSelector";
import { RootState } from "../../../../../store";
import { GetUsersMeOrganisationsCurrentWidgetsCostAndUsageSummaryApiResponse } from "../../../../../../services/cloudchipr.api";

export const costAndUsageSumSetupWidgetDataSelector = (
  state: RootState,
):
  | GetUsersMeOrganisationsCurrentWidgetsCostAndUsageSummaryApiResponse
  | undefined => {
  const response = costAndUsageSumSetupWidgetDataResponseSelector(state);

  return response?.data;
};
