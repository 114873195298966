import { FC, Fragment, useCallback, useMemo } from "react";
import { IconButton } from "@mui/material";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { DeleteDimensionConfirmationDialog } from "../../../data-grid/dimensions/actions/DeleteDimensionConfirmationDialog";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../store/hooks";
import { dimensionIdSelector } from "../../../../../../../store/dimensions/selectors/slice-data/dimensionIdSelector";
import { useDialog } from "../../../../../../../utils/hooks/useDialog.hook";
import { dimensionNameSelector } from "../../../../../../../store/dimensions/selectors/slice-data/dimensionNameSelector";
import { dimensionCategoriesSelector } from "../../../../../../../store/dimensions/selectors/slice-data/dimensionCategoriesSelector";
import { dimensionDrawerModeSelector } from "../../../../../../../store/dimensions/selectors/slice-data/dimensionDrawerModeSelector";
import { CategoryWithCosts } from "../../../../../../../services/cloudchipr.api";
import { resetDimensions } from "../../../../../../../store/dimensions/dimensionsSlice";
import { useAppAbility } from "../../../../../../../services/permissions";

export const DimensionDeleteButton: FC = () => {
  const dispatch = useAppDispatch();
  const { cannot } = useAppAbility();
  const canNotDeleteCategory = cannot("delete", "category");

  const { open, openDialog, closeDialog } = useDialog();

  const dimensionId = useAppSelector(dimensionIdSelector);
  const dimensionName = useAppSelector(dimensionNameSelector);
  const mode = useAppSelector(dimensionDrawerModeSelector);
  const categoriesEntries = useAppSelector(dimensionCategoriesSelector);

  const categories = useMemo(
    () => Object.values(categoriesEntries),
    [categoriesEntries],
  );

  const closeHandler = useCallback(() => {
    closeDialog();
    dispatch(resetDimensions());
  }, [dispatch, closeDialog]);

  if (mode !== "dimensionEdit" || !dimensionId) {
    return null;
  }

  return (
    <Fragment>
      <IconButton
        size="small"
        onClick={openDialog}
        disabled={canNotDeleteCategory}
      >
        <DeleteOutlineIcon />
      </IconButton>

      <DeleteDimensionConfirmationDialog
        open={open}
        name={dimensionName}
        onDelete={closeHandler}
        onClose={closeDialog}
        categories={categories as CategoryWithCosts[]}
        dimensionId={dimensionId}
      />
    </Fragment>
  );
};
