import { FC, Fragment, useEffect, useMemo } from "react";
import { useFlag } from "@unleash/proxy-client-react";
import { useParams } from "react-router-dom";
import { LinearProgress, Stack } from "@mui/material";
import { useWillUnmount } from "rooks";
import { UserRoleEditContent } from "./components/UserRoleEditContent";
import { PageHeader } from "../../../../common/PageHeader";
import { useAppDispatch, useAppSelector } from "../../../../../../store/hooks";
import {
  resetUserRoleData,
  setUserRoleData,
} from "../../../../../../store/user/userSlice";
import { userRoleDataNameSelector } from "../../../../../../store/user/selectors/roles/userRoleDataNameSelector";
import { initiateCreateUserRolesDrawerThunk } from "../../../../../../store/user/thunks/roles/initiateuserEditRoleThunk";
import { userRoleGettingByIdLoadingSelector } from "../../../../../../store/user/selectors/roles/loading/userRoleGettingByIdLoadingSelector";
import { userRolePermissionsGettingLoadingSelector } from "../../../../../../store/user/selectors/roles/loading/userRolePermissionsGettingLoadingSelector";

export const UserRoleEdit: FC = () => {
  const enableUserRoleUsageFlow = useFlag("EnableUserRoleUsageFlow");
  const { id } = useParams();

  const dispatch = useAppDispatch();
  const name = useAppSelector(userRoleDataNameSelector);
  const loading = useAppSelector(userRoleGettingByIdLoadingSelector);
  const permissionsLoading = useAppSelector(
    userRolePermissionsGettingLoadingSelector,
  );

  const breadcrumbs = useMemo(() => {
    return [{ title: "Roles", to: "/settings/roles" }, { title: name ?? "" }];
  }, [name]);

  useEffect(() => {
    if (!id) {
      return;
    }
    dispatch(setUserRoleData({ id }));
    dispatch(initiateCreateUserRolesDrawerThunk());
  }, [dispatch, id]);

  useWillUnmount(() => {
    dispatch(resetUserRoleData());
  });

  if (!enableUserRoleUsageFlow) {
    return null;
  }

  if (loading || permissionsLoading) {
    return <LinearProgress />;
  }

  return (
    <Fragment>
      <PageHeader breadcrumbs={breadcrumbs} />
      <Stack alignSelf="center" maxWidth={740} width="100%" pt={4} pb={2}>
        <UserRoleEditContent />
      </Stack>
    </Fragment>
  );
};
