import { RootState } from "../../../store";
import { ResourceType } from "../../../../services/cloudchipr.api";
import { liveUsageMgmtSelectedGroupedRowsByResourceTypeSelector } from "../store-selectors/liveUsageMgmtSelectedGroupedRowsByResourceTypeSelector";

export const liveUsageMgmtSelectedRowsIdsByResourceTypeSelector = (
  state: RootState,
  resourceType?: ResourceType,
) => {
  if (!resourceType) {
    return;
  }
  const resources = liveUsageMgmtSelectedGroupedRowsByResourceTypeSelector(
    state,
    resourceType,
  );

  const selectedResourcesGroupedArr = Object.values(resources ?? {});

  return selectedResourcesGroupedArr.reduce(
    (result, item) => {
      result = {
        ...result,
        ...item,
      };
      return result;
    },
    {} as Record<string, boolean>,
  );
};
