import { FC, useCallback } from "react";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../../../../store/hooks";
import { costBreakdownSetupVisualizationPropertyByKeySelector } from "../../../../../../../../../../../store/dashboards/selectors/setups/cost-breakdown/costBreakdownSetupVisualizationPropertyByKeySelector";
import { costBreakdownViewVisualizationChangeThunk } from "../../../../../../../../../../../store/dashboards/thunks/widgets/cost-breakdown/setup-change/costBreakdownViewVisualizationChangeThunk";
import { ChartBaseType } from "../../../../../../../../../../../services/cloudchipr.api";
import { WidgetSetupChartBaseSelect } from "../../../../common/toolbar/WidgetSetupChartBaseSelect";

export const CostBreakdownWidgetVisualizationChartBase: FC = () => {
  const dispatch = useAppDispatch();
  const base = useAppSelector(
    costBreakdownSetupVisualizationPropertyByKeySelector("chart_base_type"),
  );

  const handleChartBaseChange = useCallback(
    (chartBase: ChartBaseType) => {
      dispatch(
        costBreakdownViewVisualizationChangeThunk({
          chart_base_type: chartBase,
        }),
      );
    },
    [dispatch],
  );

  return (
    <WidgetSetupChartBaseSelect
      chartBase={base}
      onChartChange={handleChartBaseChange}
    />
  );
};
