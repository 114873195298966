import { isStringResourceType } from "./isStringResourceType";
import { ResourceType } from "../../../services/cloudchipr.api";
import {
  nestedResourcesHierarchy,
  NestedResourcesHierarchy,
} from "../../constants/resources/nestedResourcesHierarchy";

export const getChildResourceType = (
  resourceType: ResourceType,
): ResourceType => {
  let child: ResourceType | undefined;

  const recursive = (
    hierarchy: NestedResourcesHierarchy,
    resourceType: ResourceType,
  ) => {
    Object.entries(hierarchy).forEach(([rt, children]) => {
      if (!isStringResourceType(rt) || !children) {
        return;
      }

      if (resourceType === rt) {
        const childResource = Object.keys(children).at(0);
        if (isStringResourceType(childResource)) {
          child = childResource;
        }
        return;
      }

      return recursive(children, resourceType);
    });
  };

  recursive(nestedResourcesHierarchy, resourceType);

  return child ?? resourceType;
};
