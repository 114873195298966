import { Components, Theme } from "@mui/material";

declare module "@mui/material/TextField" {
  interface TextFieldPropsSizeOverrides {
    xsmall: true;
  }
}

export const muiTextField: Components<Theme>["MuiTextField"] = {
  styleOverrides: {
    root: ({ ownerState }) => {
      if (ownerState?.size === "xsmall") {
        return {
          "& .MuiInputBase-input ": {
            paddingTop: "4px",
            paddingBottom: "4px",
            fontSize: "0.75rem",
            minHeight: "1.4rem",
          },
          "& .MuiInputLabel-outlined.MuiFormLabel-root": {
            transform: "translate(12px, 4px) scale(1) !important",
          },
          "& .MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-shrink.MuiInputLabel-sizeXsmall":
            {
              transform: "translate(14px, -9px) scale(0.75) !important",
            },
          "& .MuiInputLabel-outlined.MuiFormLabel-root.Mui-focused, .MuiInputLabel-outlined.MuiFormLabel-root.MuiFormLabel-filled":
            {
              transform: "translate(14px, -9px) scale(0.75) !important",
            },
        };
      }
    },
  },
};
