import { RootState } from "../../../store";
import { Account } from "../../../../services/cloudchipr.api";
import { allAccountsGroupedByProviderSelector } from "../../../accounts/selectors/all-providers/allAccountsGroupedByProviderSelector";
import { liveUsageMgmtProviderSelector } from "../store-selectors/liveUsageMgmtProviderSelector";
import { liveUsageMgmtAccountIdsSelector } from "../store-selectors/liveUsageMgmtAccountIdsSelector";

export const liveUsageMgmtSelectedAccountsSelector = (
  state: RootState,
): Account[] | undefined => {
  const accountsByProvider = allAccountsGroupedByProviderSelector(state);
  const provider = liveUsageMgmtProviderSelector(state);
  const accountIds = liveUsageMgmtAccountIdsSelector(state);

  if (!provider || !accountIds || !accountsByProvider) {
    return;
  }
  const accounts = accountsByProvider?.[provider];

  return accountIds?.reduce((result, item) => {
    const data = accounts?.find((account) => account.id === item);
    if (data) {
      result.push(data);
    }
    return result;
  }, [] as Account[]);
};
