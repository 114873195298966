import { FC, memo } from "react";
import { Box, Typography } from "@mui/material";
import { Editor } from "../../../../../../storybook/editor/Editor";

interface CreateEmailMessageEditorProps {
  value?: string | null;
  onChange(value: string): void;
}

export const CreateEmailMessageEditor: FC<CreateEmailMessageEditorProps> = memo(
  ({ value, onChange }) => {
    return (
      <Box flexGrow={1} sx={styles}>
        <Typography variant="body1" fontWeight="bold" mb={1}>
          Message
        </Typography>

        <Editor
          value={value ?? ""}
          onChange={onChange}
          toolbarOptions={options}
        />
      </Box>
    );
  },
);

const styles = {
  "& .sun-editor": {
    borderBottom: "none",
    "& .se-resizing-bar": { display: "none" },
  },
};

const options = {
  minHeight: "220px",
  buttonList: [["bold", "italic", "strike"], [], ["link"]],
};
