import { FC, Fragment, ReactNode, useCallback, useMemo, useState } from "react";
import { Button, Stack } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { v4 as uuid } from "uuid";
import {
  FilterOperatorType,
  ResourceExplorerDynamicFilterItemType,
  ResourceExplorerFilterProvider,
} from "../../../../../../../services/cloudchipr.api";
import { FilterSelect } from "../FilterSelect";
import { GroupItemsOperator } from "../../provider-dynamic-filters/GroupItemsOperator";
import {
  FilterGroupNodeWithId,
  FilterItemNodeWithId,
} from "../../../utils/types/common";
import { useFilterTreeContext } from "../../FilterTreeProvider";

interface AddFilterInProviderProps {
  filterProvider: ResourceExplorerFilterProvider;
  filters: FilterGroupNodeWithId;
  onFiltersChange(newFilters: FilterGroupNodeWithId, index: number): void;
  groupIndex: number;
  nested?: boolean;
  children?: ReactNode;
}

export const AddFilterInProvider: FC<AddFilterInProviderProps> = ({
  onFiltersChange,
  filterProvider,
  filters,
  nested,
  children,
  groupIndex,
}) => {
  const [selectionType, setSelectionType] = useState<
    "addGroup" | "addFilter" | null
  >(null);

  const { possibleFilters } = useFilterTreeContext();

  const singleFilterItem = useMemo(() => {
    const filters = possibleFilters.filter(
      (f) => f.filter_provider === filterProvider,
    );
    return filters.length === 1 ? filters?.at(0) : null;
  }, [filterProvider, possibleFilters]);

  const addFilterGroupClickHandler = useCallback(() => {
    setSelectionType("addGroup");
  }, []);

  const addFilterRemoveHandler = useCallback(() => {
    setSelectionType(null);
  }, []);

  const addFilterHandler = useCallback(
    (
      provider: ResourceExplorerFilterProvider,
      key: ResourceExplorerDynamicFilterItemType,
      operator: FilterOperatorType,
    ) => {
      const newFilter: FilterItemNodeWithId = {
        id: uuid(),
        node_type: "item",
        type: key,
        filter_provider: provider,
        value: null,
        operator,
      };

      const newGroup: FilterGroupNodeWithId = {
        node_type: "group",
        operator: "and",
        items: [newFilter],
      };

      const filterItem = selectionType === "addGroup" ? newGroup : newFilter;

      onFiltersChange(
        { ...filters, items: [...filters.items, filterItem] },
        groupIndex,
      );
    },
    [groupIndex, onFiltersChange, filters, selectionType],
  );

  const addFilterClickHandler = useCallback(() => {
    if (!singleFilterItem) {
      setSelectionType("addFilter");
      return;
    }

    const operator = singleFilterItem.operators?.at(0)?.key;

    if (operator) {
      addFilterHandler(
        singleFilterItem.filter_provider,
        singleFilterItem.key,
        operator,
      );
    }
  }, [singleFilterItem, addFilterHandler]);

  return (
    <Stack spacing={1} flex={1}>
      <Stack direction="row" spacing={1} flex={1}>
        {selectionType && (
          <Fragment>
            <GroupItemsOperator first={false} operator={filters.operator} />

            <FilterSelect
              padding={0}
              nested={nested}
              filterProvider={filterProvider}
              onSelect={addFilterHandler}
              onRemove={addFilterRemoveHandler}
            />
          </Fragment>
        )}
      </Stack>

      <Stack
        direction="row"
        spacing={1}
        flex={1}
        justifyContent={nested ? "space-between" : undefined}
      >
        <Button
          size="small"
          startIcon={<AddIcon />}
          disabled={!!selectionType}
          sx={{ alignSelf: "flex-start" }}
          onClick={addFilterClickHandler}
        >
          Add Filter
        </Button>

        {!nested && (
          <Button
            size="small"
            startIcon={<AddIcon />}
            disabled={!!selectionType}
            sx={{ alignSelf: "flex-start" }}
            onClick={addFilterGroupClickHandler}
          >
            Add Filter Group
          </Button>
        )}

        {children}
      </Stack>
    </Stack>
  );
};
