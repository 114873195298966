import { createAsyncThunk } from "@reduxjs/toolkit";
import { enqueueSnackbar } from "notistack";
import { capitalize } from "@mui/material";
import { RootState } from "../../store";
import { cloudChiprApi } from "../../../services/cloudchipr.api";
import { sendSingleReportFixedCacheKey } from "../utils/constants/fixedCacheKeys";
import { reportsDataSelector } from "../selector/reportsDataSelector";
import { formatNotificationsFromHtmlToSlack } from "../../../components/utils/helpers/formatNotificationsFromHtmlToSlack";

export const sendSingleReportThunk = createAsyncThunk(
  "reports/sendSingleReport",
  async (_, { dispatch, getState }) => {
    const state = getState() as RootState;

    const { postUsersMeOrganisationsCurrentReportsOneTime } =
      cloudChiprApi.endpoints;

    const { export_file_types, emails, notifications, source_type, source_id } =
      reportsDataSelector(state);

    try {
      const response = await dispatch(
        postUsersMeOrganisationsCurrentReportsOneTime.initiate(
          {
            oneTimeReport: {
              source_type,
              source_id,
              file_types: export_file_types,
              notifications:
                formatNotificationsFromHtmlToSlack(notifications) ?? null,
              emails,
            },
          },
          {
            fixedCacheKey: sendSingleReportFixedCacheKey,
          },
        ),
      ).unwrap();

      enqueueSnackbar(`${capitalize(source_type)} sent successfully!`, {
        variant: "snackbarAlert",
        AlertSnackBarProps: {
          severity: "success",
        },
      });

      return response;
    } catch (e) {
      // @ts-expect-error todo: fix api spec
      const errMessage = e?.data?.message || "Something went wrong";
      enqueueSnackbar(errMessage, {
        variant: "snackbarAlert",
        AlertSnackBarProps: {
          severity: "error",
        },
      });
    }
  },
);
