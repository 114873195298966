import { FC, Fragment, useCallback } from "react";
import { Stack, Tab, Tabs, Tooltip } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { AzureSubscriptionTabContent } from "./AzureSubscriptionTabContent";
import { AzureTenantTabContent } from "./AzureTenantTabContent";
import { FailedAccountAttemptReason } from "../FailedAccountAttemptReason";
import { PollingInterruptedMessage } from "../PollingInterruptedMessage";
import { useAppSelector } from "../../../../../../store/hooks";
import { azureServicePrincipalSelector } from "../../../../../../store/account-setup/selectors/azureServicePrincipalSelector";

interface AzureAssignRolesStepContentProps {
  value: "tenant" | "subscription";
  onChange(value: "tenant" | "subscription"): void;
}

export const AzureAssignRolesStepContent: FC<
  AzureAssignRolesStepContentProps
> = ({ value, onChange }) => {
  const servicePrincipal = useAppSelector(azureServicePrincipalSelector);
  const handleTabChange = useCallback(
    (_: any, value: "tenant" | "subscription") => {
      onChange(value);
    },
    [onChange],
  );
  return (
    <Fragment>
      <Tabs variant="fullWidth" value={value} onChange={handleTabChange}>
        <Tab
          label={
            <Stack direction="row" alignItems="center" gap={1}>
              CONNECT MANAGEMENT GROUP
              <Tooltip
                arrow
                placement="top"
                title="Connecting your Management Group will allow Cloudchipr to collect resources from all associated subscriptions."
              >
                <InfoOutlinedIcon fontSize="small" />
              </Tooltip>
            </Stack>
          }
          value="tenant"
        />
        <Tab label="CONNECT SUBSCRIPTION" value="subscription" />
      </Tabs>

      {servicePrincipal &&
        (value === "subscription" ? (
          <AzureSubscriptionTabContent />
        ) : (
          <AzureTenantTabContent />
        ))}

      <FailedAccountAttemptReason />
      <PollingInterruptedMessage />
    </Fragment>
  );
};
