import { FC, useCallback } from "react";
import { FormHelperText, Grid } from "@mui/material";
import SunEditor from "suneditor-react";
import { SunEditorOptions } from "suneditor/src/options";
import { htmlToJiraADF } from "../../../utils/helpers/htmlToJiraADF";

interface ParagraphFieldProps {
  error?: string;
  name: string;
  setFieldValue(key: string, value: any): void;
}

export const jiraParagraphFieldKey =
  "com.atlassian.jira.plugin.system.customfieldtypes:textarea";

export const ParagraphField: FC<ParagraphFieldProps> = ({
  setFieldValue,
  name,
  error,
}) => {
  const blurHandler = useCallback(
    (_: any, editorContents: string) => {
      setFieldValue(name, htmlToJiraADF(editorContents));
    },
    [setFieldValue, name],
  );

  return (
    <Grid item sm={10}>
      <SunEditor onBlur={blurHandler} setOptions={editorOption} />
      {!!error && <FormHelperText error>{error}</FormHelperText>}
    </Grid>
  );
};

const editorOption: SunEditorOptions = {
  hideToolbar: true,
  resizeEnable: true,
  buttonList: [["bold", "italic", "strike"]],
  defaultTag: "div",
  minHeight: "100px",
  showPathLabel: false,
};
