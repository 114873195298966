import { FC } from "react";
import { CustomCellRendererProps } from "@ag-grid-community/react";
import Stack from "@mui/material/Stack";
import { Link, Typography } from "@mui/material";
import OpenInNewOutlinedIcon from "@mui/icons-material/OpenInNewOutlined";
import { SavingsOpportunity } from "../../../../../services/cloudchipr.api";
import { money } from "../../../../../utils/numeral/money";

export const SavingsOpportunityMonthlySavingsCellRenderer: FC<
  CustomCellRendererProps<SavingsOpportunity>
> = (cell) => {
  const data = cell.data as SavingsOpportunity;

  if (!data) {
    return null;
  }

  return (
    <Stack
      spacing={1}
      direction="row"
      alignItems="center"
      sx={{ lineHeight: 0 }}
    >
      <Typography variant="body2">{money(data.monthly_savings)}</Typography>

      {data.link && (
        <Link href={data.link} target="_blank" pt={0.5}>
          <OpenInNewOutlinedIcon fontSize="small" color="action" />
        </Link>
      )}
    </Stack>
  );
};
