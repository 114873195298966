import { RootState } from "../../../../store";
import { automationErrorsSelector } from "../../common/errors/automationErrorsSelector";

export const workflowGracePeriodNotificationExistenceError = (
  state: RootState,
) => {
  const errors = automationErrorsSelector(state);

  return errors["gracePeriod.notifications"];
};
