import { FC, useCallback } from "react";
import { InternalDashboardCommitmentUtilizationWidgetWrapper } from "./widgets/commitments-utilization/InternalDashboardCommitmentUtilizationWidgetWrapper";
import {
  GetUsersMeOrganisationsCurrentDashboardsByDashboardIdApiResponse,
  WidgetType,
} from "../../../../services/cloudchipr.api";
import { UnauthorizedDashboardCostBreakdownWidgetWrapper } from "../../common/components/cost-breakdown/UnauthorizedDashboardCostBreakdownWidgetWrapper";
import { UnauthorizedLargestCostChangesWidgetWrapper } from "../../common/components/largest-cost-changes/UnauthorizedLargestCostChangesWidgetWrapper";
import { UnauthorizedDashboardCostAndUsageSumWidgetWrapper } from "../../common/components/cost-and-usage-sum/UnauthorizedDashboardCostAndUsageSumWidgetWrapper";
import { RegisterRequestState } from "../utils/hooks/useDashboardStateGlobalVar.hook";

interface InternalWidgetComponentByWidgetTypeProps {
  widget: GetUsersMeOrganisationsCurrentDashboardsByDashboardIdApiResponse["widgets"][0];
  organisationId: string;
  registerRequestState: RegisterRequestState;
}

export const InternalWidgetComponentByWidgetType: FC<
  InternalWidgetComponentByWidgetTypeProps
> = ({ widget, registerRequestState, organisationId }) => {
  const Component = widgetsComponents[widget.type];
  const onWidgetMount = useCallback(() => {
    registerRequestState(widget.type, widget.id, true);
  }, [widget.type, widget.id, registerRequestState]);

  if (!Component) {
    return null;
  }

  return (
    <Component
      {...widget}
      organisationId={organisationId}
      onWidgetMount={onWidgetMount}
    />
  );
};

const widgetsComponents: Partial<Record<WidgetType, FC<any>>> = {
  cost_breakdown: UnauthorizedDashboardCostBreakdownWidgetWrapper,
  largest_cost_changes: UnauthorizedLargestCostChangesWidgetWrapper,
  cost_and_usage_summary: UnauthorizedDashboardCostAndUsageSumWidgetWrapper,
  commitments_utilization: InternalDashboardCommitmentUtilizationWidgetWrapper,
};
