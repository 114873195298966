import { FC, useCallback } from "react";
import { Checkbox, FormControlLabel } from "@mui/material";
import Typography from "@mui/material/Typography";

interface RightsizingAdvancedFilterCheckBoxProps {
  onClick(): void;
  checked: boolean;
  label: string;
  disabled?: boolean;
}

export const RightsizingAdvancedFilterCheckbox: FC<
  RightsizingAdvancedFilterCheckBoxProps
> = ({ onClick, checked, disabled, label }) => {
  const handleClick = useCallback(() => {
    if (disabled) {
      return;
    }

    onClick();
  }, [onClick, disabled]);

  return (
    <FormControlLabel
      disabled={disabled}
      onClick={handleClick}
      sx={{ px: 1, mr: 0, width: "fit-content" }}
      control={<Checkbox size="small" checked={checked} />}
      label={<Typography variant="body2">{label}</Typography>}
    />
  );
};
