import { useCallback } from "react";
import { getModifiedAdvancedFilterPreferencesJson } from "../helpers/getModifiedAdvancedFilterPreferencesJson";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../store/hooks";
import { rightsizingAdvancedFiltersSettingsSelector } from "../../../../../../../store/recommendations/selectors/rightsizing/advanced-filters/rightsizingAdvancedFiltersSettingsSelector";
import {
  usePatchUsersMeCurrentPreferenceByKeyMutation,
  usePostUsersMeCurrentPreferenceMutation,
} from "../../../../../../../services/cloudchipr.api";
import { RightsizingAdvancedFilterType } from "../../../../../../../store/recommendations/utils/types/rightsizingAdvancedFilterType";
import {
  getRightsizingRecommendationsAdvancedFiltersThunk,
  rightsizingRecommendationsAdvancedFiltersPreferenceKey,
} from "../../../../../../../store/recommendations/thunks/rightsizing/getRightsizingRecommendationsAdvancedFiltersThunk";
import { RightsizingRecommendationsAdvancedFilters } from "../../../../../../../store/recommendations/utils/types/types";

export const useToggleRightsizingAdvancedFilterUserPreference = () => {
  const dispatch = useAppDispatch();
  const preferences = useAppSelector(
    rightsizingAdvancedFiltersSettingsSelector,
  );
  const [createPreferences, { isLoading: isCreateLoading }] =
    usePostUsersMeCurrentPreferenceMutation();
  const [updatePreferences, { isLoading: isUpdateLoading }] =
    usePatchUsersMeCurrentPreferenceByKeyMutation();

  const toggleRightsizingAdvancedFilterUserPreference = useCallback(
    async (filterType: RightsizingAdvancedFilterType) => {
      const preferenceJSON = getModifiedAdvancedFilterPreferencesJson(
        preferences,
        filterType,
      );

      if (checkAdvancedFiltersPreferenceExistence(preferences)) {
        await updatePreferences({
          key: rightsizingRecommendationsAdvancedFiltersPreferenceKey,
          body: {
            value: preferenceJSON,
          },
        });
      } else {
        await createPreferences({
          body: {
            key: rightsizingRecommendationsAdvancedFiltersPreferenceKey,
            value: preferenceJSON,
          },
        });
      }

      dispatch(getRightsizingRecommendationsAdvancedFiltersThunk());
    },
    [createPreferences, updatePreferences, dispatch, preferences],
  );

  return {
    toggleRightsizingAdvancedFilterUserPreference,
    loading: isCreateLoading || isUpdateLoading,
  };
};

const checkAdvancedFiltersPreferenceExistence = (
  preferences: RightsizingRecommendationsAdvancedFilters,
) => {
  return !(
    preferences.displayRecommendationsAcrossInstanceFamily === undefined &&
    preferences.multipleRecommendationsPerResource === undefined
  );
};
