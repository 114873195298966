import { FC } from "react";
import { Box } from "@mui/material";

interface DataGridDefaultCellProps {
  data?: string | null;
  isNoWrap?: boolean;
  emptyData?: string;
}

export const DefaultCell: FC<DataGridDefaultCellProps> = ({
  data,
  isNoWrap = false,
  emptyData,
}) => {
  const whiteSpace = isNoWrap ? "nowrap" : "normal";

  if (emptyData && !data) {
    return <Box whiteSpace={whiteSpace}>{emptyData}</Box>;
  }
  return <Box whiteSpace={whiteSpace}>{data}</Box>;
};
