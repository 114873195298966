import { allAccountsSelectOptionsSelector } from "./allAccountsSelectOptionsSelector";
import { RootState } from "../../../store";
import { ProviderType } from "../../../../services/cloudchipr.api";

export const disabledNonConnectedAllAccountsIdsByProviderSelector = (
  state: RootState,
  provider?: ProviderType,
) => {
  const allAccounts = allAccountsSelectOptionsSelector(state);

  if (!provider) {
    return;
  }

  return allAccounts
    .filter(
      ({ accountType, disabled, status }) =>
        accountType === provider && (disabled || status !== "connected"),
    )
    .map(({ value }) => value);
};
