import { FC, Fragment, memo, useCallback, useMemo } from "react";
import { FormikHandlers } from "formik";
import { SelectChangeEvent } from "@mui/material";
import {
  AutocompleteFilterBody,
  AutocompleteFilterBodyProps,
} from "../AutocompleteFilterBody";
import {
  FilterItem,
  Operator,
  ResourceType,
  useGetUsersMeProvidersAzureResourcesTypesByResourceTypeResourceGroupsQuery,
} from "../../../../../../../../services/cloudchipr.api";
import { FilterItemOperatorType } from "../../../FilterItemOperatorType";
import { multipleValueSelectOperators } from "../../../../utils/constants/filter-types/autocomplete-multipe-selection-operator-types";

type ResourceGroupFilterResourceType = "az_disk" | "az_ip" | "az_vm";

interface AzResourcesGroupFilterProps extends AutocompleteFilterBodyProps {
  accountIds?: string[];
  resourceType: ResourceType;
  filter: FilterItem;
  operators?: Operator[];
  onChange: FormikHandlers["handleChange"];
}

export const AzResourcesGroupFilter: FC<AzResourcesGroupFilterProps> = memo(
  ({ resourceType, accountIds, operators, filter, onChange, ...props }) => {
    const { data, isLoading } =
      useGetUsersMeProvidersAzureResourcesTypesByResourceTypeResourceGroupsQuery(
        {
          resourceType: resourceType as ResourceGroupFilterResourceType,
          // @ts-expect-error  //todo: this should be optional
          accountIds: accountIds?.length ? accountIds.join(",") : undefined,
        },
      );

    const singleSelect = !multipleValueSelectOperators.includes(
      filter?.operator,
    );

    const operatorChangeHandler = useCallback(
      (event: SelectChangeEvent) => {
        const operator = event.target.value;

        if (
          !multipleValueSelectOperators.includes(filter.operator) ||
          !multipleValueSelectOperators.includes(operator)
        ) {
          props.setFieldValue("value", null);
        }

        onChange(event);
      },
      [onChange, props, filter.operator],
    );

    const selectedValue = useMemo(() => {
      const value = filter?.value;

      const stringValue = value && typeof value === "string" ? [value] : [];
      return Array.isArray(value) ? value : stringValue;
    }, [filter?.value]);

    const filterValueChangeHandler = useCallback(
      (_: any, values: string[]) => {
        const value = singleSelect ? values[0] : values;
        props.setFieldValue("value", value);
      },
      [props, singleSelect],
    );

    return (
      <Fragment>
        <FilterItemOperatorType
          onChange={operatorChangeHandler}
          options={operators}
          value={filter.operator || "in"}
        />

        <AutocompleteFilterBody
          {...props}
          isLoading={isLoading}
          label="Resource Group"
          options={data ?? []}
          selectedValue={selectedValue}
          // @ts-expect-error | types looks ok, can't understand what's the reason
          setFieldValue={filterValueChangeHandler}
          freeSolo={singleSelect}
          singleSelect={singleSelect}
        />
      </Fragment>
    );
  },
);
