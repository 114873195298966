import { FC } from "react";
import { useFlag } from "@unleash/proxy-client-react";
import { CommitmentsPageHeaderMetadataCard } from "../../../common/components/CommitmentsPageHeaderMetadataCard";
import { money } from "../../../../../../utils/numeral/money";
import { useAppSelector } from "../../../../../../store/hooks";
import { commitmentsCurrentOrgIdSelector } from "../../../../../../store/commitments/selectors/common/commitmentsCurrentOrgIdSelector";
import { useGetUsersMeOrganisationsCurrentReservationsPotentialSavingsQuery } from "../../../../../../services/cloudchipr.api";

export const ReservationPotentialSavingsMetadata: FC = () => {
  const orgId = useAppSelector(commitmentsCurrentOrgIdSelector);
  const enableReservationRecommendation = useFlag(
    "EnableReservationRecommendation",
  );
  const { data, isLoading } =
    useGetUsersMeOrganisationsCurrentReservationsPotentialSavingsQuery({
      providerOrganisationId: orgId,
    });

  if (!enableReservationRecommendation) {
    return null;
  }

  return (
    <CommitmentsPageHeaderMetadataCard
      primary="Potential Monthly Savings"
      loading={isLoading}
      valueColor="success.light"
      value={
        data?.savings_amount !== null
          ? `~${money(data?.savings_amount)}`
          : "N/A"
      }
      tooltip="Total amount of potential savings based on Savings Plan Recommendations."
    />
  );
};
