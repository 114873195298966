import numeral from "numeral";

// TODO: Refactor `value` types to accept only `number` values
export const money = (
  value?: number | string | null,
  fractionThreshold: number = 0,
) => {
  if (value || value === 0) {
    if (fractionThreshold && +value >= fractionThreshold) {
      return numeral(value).format("$0.0a").toUpperCase();
    }

    return numeral(value).format("$0,0.00");
  }

  return "N/A";
};
