import React, { FC, Fragment } from "react";
import { IconButton } from "@mui/material";
import { SendReportDialog } from "./SendReportDialog";
import { ReportSourceType } from "../../../../services/cloudchipr.api";
import send from "../../../../assets/images/icons/send.svg";
import { useDialog } from "../../../../utils/hooks/useDialog.hook";

export interface SendReportButtonProps {
  sourceId: string;
  sourceType: ReportSourceType;
  disabled?: boolean;
}

export const SendReportButton: FC<SendReportButtonProps> = ({
  sourceId,
  sourceType,
  disabled,
}) => {
  const { open, openDialog, closeDialog } = useDialog();

  return (
    <Fragment>
      <IconButton size="medium" onClick={openDialog} disabled={disabled}>
        <img src={send} alt="send" />
      </IconButton>

      {open && (
        <SendReportDialog
          onClose={closeDialog}
          sourceId={sourceId}
          sourceType={sourceType}
        />
      )}
    </Fragment>
  );
};
