import { commitmentsRecommendationsFiltersSelector } from "./commitmentsRecommendationsFiltersSelector";
import { RootState } from "../../../../store";
import { SavingsPlanTermInYears } from "../../../../../services/cloudchipr.api";

export const commitmentsRecommendationsSavingsPlanTermSelector = (
  state: RootState,
): SavingsPlanTermInYears[] | undefined => {
  const filters = commitmentsRecommendationsFiltersSelector(state);

  return filters.term_in_years;
};
