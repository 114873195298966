import { SortingFn } from "@tanstack/react-table";
import { UnusedSince } from "../../../../services/cloudchipr.api";
import { compareUnusedSinceTimestamps } from "../helpers/compareUnusedSinceTimestamps";

export const sortWithState: SortingFn<any> = (currentRow, nextRow) => {
  const current: UnusedSince = currentRow.original.resource.unused_since;
  const next: UnusedSince = nextRow.original.resource.unused_since;

  return compareUnusedSinceTimestamps(current, next) ? 1 : -1;
};
