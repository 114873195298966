import { RootState } from "../../../../../store";
import { workflowsDataSelector } from "../../list-data/workflowsDataSelector";

export const automationsActionsFilterOptionsSelector = (state: RootState) => {
  const workflows = workflowsDataSelector(state);

  const options = workflows?.map((workflow) => {
    if (workflow.action !== "notify") {
      return "action";
    }

    return "notify";
  });

  return Array.from(new Set(options));
};
