import { FC, ReactElement, useCallback, useMemo } from "react";
import { Button, Chip, Stack } from "@mui/material";
import HorizontalRuleIcon from "@mui/icons-material/HorizontalRule";
import { DropdownSelect } from "../../../../../../common/select/dropdown-select/DropdownSelect";
import { GetUsersMeOrganisationsCurrentTasksPropertiesApiResponse } from "../../../../../../../services/cloudchipr.api";
import { DropdownSelectOption } from "../../../../../../common/select/dropdown-select/utils/types/types";
import { FilterTriggerComponentProps } from "../../../../../../common/select/dropdown-select/utils/types/filterTriggerComponentProps";
import { useAppSelector } from "../../../../../../../store/hooks";
import { taskAvailablePropertiesSelector } from "../../../../../../../store/task-management/selectors/properties/taskAvailablePropertiesSelector";

interface TaskPropertySelectionProps {
  label: string;
  required?: boolean;
  value?: string | null;
  optionAvatar?: ReactElement;
  onChange(value: string | null): void;
  optionsKey: keyof GetUsersMeOrganisationsCurrentTasksPropertiesApiResponse;
}

export const TaskPropertySelection: FC<TaskPropertySelectionProps> = ({
  optionsKey,
  label,
  optionAvatar,
  value,
  onChange,
  required,
}) => {
  const data = useAppSelector(taskAvailablePropertiesSelector);
  const options = data?.[optionsKey];

  const renderOptions = useMemo(() => {
    const defaultOptions: DropdownSelectOption[] = [];

    if (!required) {
      defaultOptions.push({
        value: unselectOption,
        label: (
          <Stack justifyContent="center">
            <HorizontalRuleIcon color="action" fontSize="small" />
          </Stack>
        ),
      } as DropdownSelectOption);
    }

    options?.forEach((option) => {
      defaultOptions.push({
        rawValue: option,
        value: option.id,
        label: (
          <Chip
            size="small"
            variant="filled"
            label={option.name}
            avatar={optionAvatar}
            sx={{ bgcolor: option.color, color: "white" }}
          />
        ),
      });
    });

    return defaultOptions;
  }, [optionAvatar, options, required]);

  const changeHandler = useCallback(
    (values: string[]) => {
      const value = values.at(0) ?? null;

      onChange(value === unselectOption ? null : value);
    },
    [onChange],
  );

  const TriggerComponent = useCallback(
    ({ onClick }: FilterTriggerComponentProps) => {
      const selectedOption = options?.find((option) => option.id === value);

      return (
        <Stack onClick={onClick} alignItems="start" justifyContent="center">
          {selectedOption ? (
            <Chip
              size="small"
              variant="filled"
              onClick={onClick}
              avatar={optionAvatar}
              label={selectedOption.name}
              sx={{
                color: "white",
                bgcolor: selectedOption.color,
                "&:hover": { bgcolor: selectedOption.color },
              }}
            />
          ) : (
            <Button
              size="xsmall"
              color="tertiary"
              onClick={onClick}
              startIcon={<HorizontalRuleIcon color="action" fontSize="small" />}
            />
          )}
        </Stack>
      );
    },
    [optionAvatar, options, value],
  );

  return (
    <DropdownSelect
      sortSelectedOptions={false}
      singleSelect
      listWidth={300}
      filterFn={filterFn}
      options={renderOptions}
      wrapperVariant="popover"
      label={`Select ${label}`}
      values={value ? [value] : []}
      TriggerComponent={TriggerComponent}
      submitHandlerOnClose={changeHandler}
    />
  );
};

const filterFn = (option: DropdownSelectOption, keyword: string) => {
  const target =
    `${option.rawValue?.name} ${option.rawValue?.category}`.toLowerCase();

  return target.includes(keyword.toLowerCase().trim());
};

const unselectOption = "unselectOption";
