import { FC } from "react";
import { Box } from "@mui/material";

export const LiveIndicator: FC = () => {
  return (
    <Box
      sx={{
        width: 8,
        height: 8,
        borderRadius: 2,
        bgcolor: "#e6a6a6",
      }}
    >
      <Box
        sx={{
          width: 8,
          height: 8,
          borderRadius: 2,
          bgcolor: (theme) => theme.palette.error.light,
          animation: "pulse 3s ease infinite",
          "@keyframes pulse": {
            "0%": { transform: "scale(0.1)" },
            "50%": { transform: "scale(1.0)" },
            "100%": { transform: "scale(0.1)" },
          },
        }}
      />
    </Box>
  );
};
