import { FC } from "react";
import { IntegrationType } from "./integration-type/IntegrationTypeCard";
import { JiraHelperVideo } from "../jira/JiraHelperVideo";

interface HelperGuideByTypeProps {
  type: IntegrationType;
}

export const HelperGuideByType: FC<HelperGuideByTypeProps> = ({ type }) => {
  if (type === "jira") {
    return <JiraHelperVideo />;
  }
  return null;
};
