import { RootState } from "../../../store";
import { alertsDataSelector } from "../common/data/alertsDataSelector";
import { formatNotificationsFromHtmlToSlack } from "../../../../components/utils/helpers/formatNotificationsFromHtmlToSlack";

export const utilizationAlertPayloadDataSelector = (state: RootState) => {
  const data = alertsDataSelector(state);

  const thresholds =
    data?.threshold?.threshold_items?.map((threshold) => ({
      amount: Number(threshold.amount),
      unit: threshold.unit,
    })) ?? [];

  return {
    name: data.name,
    provider_organisation_id: data.providerOrganisationId ?? "",
    based_on: data.basedOn,
    notifications:
      formatNotificationsFromHtmlToSlack(data.notifications) ?? null,
    thresholds,
    emails: data.emails ?? undefined,
  };
};
