import { costBreakdownWidgetSetupSelector } from "./costBreakdownWidgetSetupSelector";
import { costBreakdownWidgetSetupDataPayloadSelector } from "./costBreakdownWidgetSetupDataPayloadSelector";
import { RootState } from "../../../../../store";

export const costBreakdownWidgetSetupDataSelector = (state: RootState) => {
  const payload = costBreakdownWidgetSetupDataPayloadSelector(state);
  if (!payload) {
    return;
  }
  const response = costBreakdownWidgetSetupSelector(payload)(state);

  return response?.data;
};
