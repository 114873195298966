import { FC } from "react";
import { Box, Card } from "@mui/material";
import { AddingWidget } from "./AddingWidget";

export const DashboardLayoutAddingWidgetCardButton: FC = () => {
  return (
    <Box px={2}>
      <Card variant="outlined" sx={{ p: 1.5, mt: 2 }}>
        <AddingWidget startIcon variant="text" />
      </Card>
    </Box>
  );
};
