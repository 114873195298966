import { FC, ReactElement } from "react";
import { Stack, Tooltip } from "@mui/material";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import WarningAmberRoundedIcon from "@mui/icons-material/WarningAmberRounded";
import { DropdownSelectOptionDisabled } from "../utils/types/types";

interface FilterListItemDisabledReasonTooltipProps {
  disabled?: DropdownSelectOptionDisabled | boolean;
}

export const FilterListItemDisabledReasonTooltip: FC<
  FilterListItemDisabledReasonTooltipProps
> = ({ disabled }) => {
  if (!disabled) {
    return null;
  }

  const tooltip =
    isDisabledObject(disabled) && disabled.type && disabled.reason;

  if (!tooltip) {
    return null;
  }

  return (
    <Stack alignItems="center" mx={1}>
      <Tooltip arrow title={disabled.reason} placement="right">
        {disabledIcons[disabled.type]}
      </Tooltip>
    </Stack>
  );
};

const disabledIcons: Record<
  DropdownSelectOptionDisabled["type"],
  ReactElement
> = {
  error: <ErrorOutlineIcon color="error" />,
  warning: <WarningAmberRoundedIcon color="warning" />,
  inProgress: <InfoOutlinedIcon color="primary" />,
  info: <InfoOutlinedIcon color="info" />,
};

const isDisabledObject = (
  disabled: any,
): disabled is DropdownSelectOptionDisabled => {
  return typeof disabled !== "boolean";
};
