import { overviewDataSelector } from "./overviewDataSelector";
import { RootState } from "../../../store";

export const overviewReservationTotalCostSelector = (
  state: RootState,
): number | null => {
  const data = overviewDataSelector(state);

  return data?.reservation_total_commitments ?? null;
};
