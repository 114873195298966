import { Table } from "@tanstack/react-table";
import { COMPONENT_ID } from "../constants/components-ids";

export const expandRowAfterFirstRender = (
  table: Table<any>,
  ids?: string[],
) => {
  // todo: this function must provide data-grid, refactor and make it more generic
  if (!ids?.length) {
    return;
  }
  const rows = table.getSortedRowModel().rows;

  const expandableRows = ids.reduce(
    (acc, id) => {
      const expandableRowIndex = rows.findIndex(
        ({ original }) => original.id === id,
      );
      const expandableRowId = rows[expandableRowIndex]?.id;

      if (expandableRowIndex < 0 || !expandableRowId) {
        return acc;
      }

      acc[expandableRowId] = true;

      return acc;
    },
    {} as { [key: string]: boolean },
  );

  const firstExpandableRowIndex = rows.findIndex(
    ({ original }) => original.id === ids.at(0),
  );

  const pageSize = table.getState().pagination.pageSize;
  const pageIndex = Math.ceil((firstExpandableRowIndex + 1) / pageSize) - 1;

  table.setPageIndex(pageIndex);
  table.setExpanded(expandableRows);

  setTimeout(() => {
    const row = document.getElementById(ids.at(0) ?? "");

    const commonWrapper = document.getElementById(
      COMPONENT_ID.APP_OUTLET_WRAPPER,
    );

    commonWrapper?.scrollTo({
      top: row?.getBoundingClientRect()?.top ?? 0,
      behavior: "smooth",
    });
  });
};
