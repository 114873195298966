import { FC, Fragment } from "react";
import { Typography } from "@mui/material";
import { useGetUsersMeOrganisationsCurrentSettingsQuery } from "../../../../../../services/cloudchipr.api";

export const TTLExampleStep2: FC = () => {
  const { data } = useGetUsersMeOrganisationsCurrentSettingsQuery();
  const value = data?.smart_tag_ttl?.value;
  return (
    <Fragment>
      <Typography color="primary" sx={{ mt: 2 }}>
        Step 2
      </Typography>
      <Typography color="text.secondary">
        Create a schedule for EC2 resources smart tagged:{" "}
        <Typography component="span" fontWeight="medium">
          {value}: expired
        </Typography>
        <br />
        Based on given frequency the scheduler will regularly scan all the
        resources in order to find expired resources, based on the{" "}
        <Typography component="span" color="text.primary">
          following values:
        </Typography>
      </Typography>
      <Typography component="span" color="text.secondary">
        <ol>
          <li>Resource Creation Time</li>
          <li>Current Time</li>
          <li>{value} Tag value</li>
        </ol>
      </Typography>
      <Typography color="text.secondary">
        Once identified the resource will undergo through the action defined by
        the user in a given schedule.
      </Typography>
    </Fragment>
  );
};
