import { anyChangesExistOnCategoryEditSelector } from "./anyChangesExistOnCategoryEditSelector";
import { editingCategorySelector } from "../../slice-data/editing-category/editingCategorySelector";
import { RootState } from "../../../../store";
import { filterTreeFiltersAreInvalid } from "../../../../../components/pages/common/filters-tree/utils/helpers/filterTreeFiltersAreInvalid";

export const categoriesCreateDisabledSelector = (state: RootState) => {
  const editingCategory = editingCategorySelector(state);

  if (!editingCategory) {
    return;
  }

  const invalid = filterTreeFiltersAreInvalid(
    editingCategory.filters.filter_tree,
  );

  const somethingChanged = anyChangesExistOnCategoryEditSelector(state);

  return (
    !editingCategory?.name ||
    invalid ||
    !somethingChanged ||
    !editingCategory.name
  );
};
