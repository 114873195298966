import { FC, useEffect, useState } from "react";
import { Slide, Stack } from "@mui/material";
import { FloatingToolbarActions } from "./FloatingToolbarActions";
import { COMPONENT_ID } from "../../../../../utils/constants/components-ids";
import { useAppSelector } from "../../../../../store/hooks";
import { selectedTasksLengthSelector } from "../../../../../store/task-management/selectors/selection/selectedTasksLengthSelector";

export const FloatingToolbar: FC = () => {
  const visible = !!useAppSelector(selectedTasksLengthSelector);
  const [navBarSize, setNavBarSize] = useState(0);

  useEffect(() => {
    if (!visible) {
      return;
    }

    const navBar = document.getElementById(COMPONENT_ID.MAIN_NAVIGATION);
    setNavBarSize(navBar?.clientWidth ?? 0);
  }, [visible]);

  return (
    <Slide direction="up" in={visible} mountOnEnter unmountOnExit>
      <Stack
        sx={{
          left: 0,
          bottom: 50,
          top: "auto",
          width: "100%",
          position: "fixed",
          pl: `${navBarSize}px`,
          zIndex: (theme) => theme.zIndex.snackbar + 1,
        }}
      >
        <Stack
          flex={1}
          direction="row"
          alignItems="center"
          justifyContent="center"
        >
          <FloatingToolbarActions />
        </Stack>
      </Stack>
    </Slide>
  );
};
