import { RootState } from "../../../../store";
import { resourceExplorerViewDataByIdSelector } from "../../resource-explorer-by-id/resourceExplorerViewDataByIdSelector";
import { resourceExplorerFilterTreeSelector } from "../../filter-tree/resourceExplorerFilterTreeSelector";
import { removeIdsFromFilterTreeItems } from "../../../../../components/pages/common/filters-tree/utils/helpers/removeIdsFromFilterTreeItems";
import { isDeepEqual } from "../../../../../utils/is-deep-equal";
import { resourceExplorerIdSelector } from "../resourceExplorerIdSelector";

export const filterTreeChangedSelector = (state: RootState) => {
  const viewId = resourceExplorerIdSelector(state);

  const initialResourceExplorerData = resourceExplorerViewDataByIdSelector(
    state,
    viewId,
  );

  const initialFilterTree = initialResourceExplorerData?.filter_tree;

  const appliedFilterTree = resourceExplorerFilterTreeSelector(state);

  const appliedFilterTreeWithoutIds = appliedFilterTree
    ? removeIdsFromFilterTreeItems(appliedFilterTree)
    : null;

  const onlyOneExist =
    [appliedFilterTreeWithoutIds, initialFilterTree].filter((item) => item)
      .length === 1;

  if (onlyOneExist) {
    return true;
  }

  return !!(
    appliedFilterTreeWithoutIds &&
    initialFilterTree &&
    !isDeepEqual(appliedFilterTreeWithoutIds, initialFilterTree)
  );
};
