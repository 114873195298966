import { ResourceType } from "../../../../../services/cloudchipr.api";
import { getResourcesCleanActionTypes } from "../../../../../utils/clean-options";
import { executionLogCleanActionName } from "../constants/common";

export const getResourceExplorerResourceActionLabel = (
  resourceType: ResourceType,
  action: string,
  snapshot: boolean | null,
) => {
  const snapshotLabel =
    getResourcesCleanActionTypes(resourceType)?.find(
      (a) => a?.type === "snapshot_and_delete",
    )?.label ?? action;

  return snapshot ? snapshotLabel : executionLogCleanActionName.get(action);
};
