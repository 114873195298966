import { FC, useCallback } from "react";
import { DateSelectionContent } from "./DateSelectionContent";
import { AutomationDatesLabelWrapper } from "./AutomationDatesLabelWrapper";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../store/hooks";
import { automationStartDateSelector } from "../../../../../../../../store/automations/selectros/common/fields/automationStartDateSelector";
import { setAutomationData } from "../../../../../../../../store/automations/automationsSlice";
import { NullableDate } from "../../../../../../../../services/cloudchipr.api";
import { getStartsOnDate } from "../../../../../../schedule/common/utils/constants/common";
import { automationTimeZoneSelector } from "../../../../../../../../store/automations/selectros/common/fields/automationTimeZoneSelector";
import { automationFrequencySelector } from "../../../../../../../../store/automations/selectros/common/automationFrequencySelector";
import { fetchWorkflowNextRunsThunk } from "../../../../../../../../store/automations/thunks/wokrflows/fetchWorkflowNextRunsThunk";

export const AutomationStartDateSelection: FC = () => {
  const dispatch = useAppDispatch();

  const frequency = useAppSelector(automationFrequencySelector);
  const statDate = useAppSelector(automationStartDateSelector);
  const timeZone = useAppSelector(automationTimeZoneSelector);

  const startDateChangeHandler = useCallback(
    (startDate: NullableDate) => {
      dispatch(setAutomationData({ startDate }));
      dispatch(fetchWorkflowNextRunsThunk());
    },
    [dispatch],
  );

  if (frequency === "once") {
    return null;
  }

  return (
    <AutomationDatesLabelWrapper label="Starts:">
      <DateSelectionContent
        labels={labels}
        selectedDate={statDate}
        onChange={startDateChangeHandler}
        dateDefaultValue={getStartsOnDate(timeZone)}
      />
    </AutomationDatesLabelWrapper>
  );
};

const labels = {
  none: "Now",
  specific: "Starts On",
};
