import { FC } from "react";
import { Stack, Typography } from "@mui/material";

import { EmailIntegrationGracePeriodSubject } from "./EmailIntegrationGracePeriodSubject";
import { EmailIntegrationGracePeriodWorkflowInfo } from "./EmailIntegrationGracePeriodWorkflowInfo";
import { integrationCustomEmailActionExecuteDataGrid } from "../utils/constants";
import { EmailIntegrationCsvInfo } from "../common/EmailIntegrationCsvInfo";
import { IntegrationCustomMessageDataGrid } from "../../common/IntegrationCustomMessageDataGrid";

interface EmailIntegrationGracePeriodMessageProps {
  message: string;
}
export const EmailIntegrationGracePeriodMessage: FC<
  EmailIntegrationGracePeriodMessageProps
> = ({ message }) => {
  return (
    <Stack spacing={1}>
      <EmailIntegrationGracePeriodSubject />
      <EmailIntegrationGracePeriodWorkflowInfo />

      <Typography variant="body1">
        In case you need to keep any of the attached resources, please go to the
        <Typography component="span" fontWeight="medium">
          {" "}
          {`{{workflow name}}`}{" "}
        </Typography>
        workflow page to review and protect them.
      </Typography>

      <Typography
        variant="body1"
        dangerouslySetInnerHTML={{ __html: message }}
      />

      <IntegrationCustomMessageDataGrid
        data={integrationCustomEmailActionExecuteDataGrid}
        label="Resource Type"
      />
      <EmailIntegrationCsvInfo showLinkInfo />
    </Stack>
  );
};
