import { RootState } from "../../../../store";
import { cloudChiprApi } from "../../../../../services/cloudchipr.api";

export const resourceExplorerPostFixedCacheKey =
  "postUsersMeOrganisationsCurrentResourceExplorer-fixed-cache-key";

export const resourceExplorerWidgetSelector = (state: RootState) => {
  return cloudChiprApi.endpoints.postUsersMeOrganisationsCurrentResourceExplorer.select(
    resourceExplorerPostFixedCacheKey,
  )(state);
};
