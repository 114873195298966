import { FC, useCallback, useState } from "react";
import {
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  TableCell,
  Typography,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import {
  useDeleteUsersMeOrganisationsCurrentApiKeysByKeyIdMutation,
  useGetUsersMeOrganisationsCurrentApiKeysQuery,
} from "../../../../../../services/cloudchipr.api";
import { useMenuHook } from "../../../../../../utils/hooks/useMenu.hook";
import { useAppAbility } from "../../../../../../services/permissions";
import { ConfirmationDialog } from "../../../../../common/modals/ConfirmationDialog";
import { DeleteConfirmationDialog } from "../../../../../common/modals/DeleteConfirmationDialog";

type ApiKeysListGridActionCellProps = {
  keyId: string;
  showForm(): void;
  keyName: string;
};

export const ApiKeysListGridActionCell: FC<ApiKeysListGridActionCellProps> = ({
  keyId,
  keyName,
  showForm,
}) => {
  const { cannot } = useAppAbility();
  const cannotDeleteAPIKey = cannot("delete", "api-key");
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [removeApiKeyDialogOpen, setRemoveApiKeyDialogOpen] = useState(false);
  const { anchor, open, openMenu, closeMenu } = useMenuHook();
  const [deleteApiKey, { isLoading }] =
    useDeleteUsersMeOrganisationsCurrentApiKeysByKeyIdMutation();
  const { refetch } = useGetUsersMeOrganisationsCurrentApiKeysQuery();

  const openRemoveConfirmationDialog = useCallback(() => {
    setRemoveApiKeyDialogOpen(true);
  }, []);

  const closeRemoveConfirmationDialog = useCallback(() => {
    closeMenu();
    setRemoveApiKeyDialogOpen(false);
  }, [closeMenu]);

  const openConfirmDialog = useCallback(() => {
    setConfirmDialogOpen(true);
  }, []);

  const closeConfirmDialogHandler = useCallback(() => {
    closeMenu();
    setConfirmDialogOpen(false);
  }, [closeMenu]);

  const onDeleteHandler = useCallback(async () => {
    await deleteApiKey({ keyId }).unwrap();
    closeRemoveConfirmationDialog();
    refetch();
  }, [keyId, refetch, deleteApiKey, closeRemoveConfirmationDialog]);

  const onRecreateConfirmHandler = useCallback(() => {
    onDeleteHandler();
    showForm();
  }, [onDeleteHandler, showForm]);

  return (
    <TableCell sx={{ textAlign: "right" }}>
      <IconButton onClick={openMenu}>
        <MoreVertIcon />
      </IconButton>

      <Menu anchorEl={anchor} open={open} onClose={closeMenu}>
        <MenuItem
          dense
          onClick={openConfirmDialog}
          disabled={cannotDeleteAPIKey}
        >
          <ListItemIcon>
            <RestartAltIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Recreate</ListItemText>
        </MenuItem>
        <MenuItem
          dense
          onClick={openRemoveConfirmationDialog}
          sx={{ color: "error.dark" }}
          disabled={cannotDeleteAPIKey}
        >
          <ListItemIcon>
            <DeleteOutlinedIcon fontSize="small" color="error" />
          </ListItemIcon>
          <ListItemText primaryTypographyProps={{ color: "error" }}>
            Delete
          </ListItemText>
        </MenuItem>
      </Menu>
      <ConfirmationDialog
        open={confirmDialogOpen}
        title="Recreate API Key?"
        dialogContent="Recreating your API key will revoke the current key and replace it with a new key."
        CancelButtonProps={{
          onClick: closeConfirmDialogHandler,
        }}
        ConfirmButtonProps={{
          onClick: onRecreateConfirmHandler,
          children: "Recreate",
        }}
        onClose={closeConfirmDialogHandler}
      />

      <DeleteConfirmationDialog
        open={removeApiKeyDialogOpen}
        onClose={closeRemoveConfirmationDialog}
        onDelete={onDeleteHandler}
        loading={isLoading}
        title="Delete API Key?"
        content={
          <Typography>
            Please confirm if you want to delete
            <Typography component="span" fontWeight="bold">
              {" "}
              {keyName}{" "}
            </Typography>
            API key.
          </Typography>
        }
      />
    </TableCell>
  );
};
