import moment from "moment";
import { getCronFrequency } from "./cron/getCronFrequency";
import { parseCron } from "./cron/parseCron";
import { OffHoursSchedule } from "../../../../services/cloudchipr.api";
import { OffHoursSpecificData } from "../types/common";

export const generateOffHoursSpecificData = (
  offHours: OffHoursSchedule,
): OffHoursSpecificData => {
  const frequency = getCronFrequency(offHours.start_cron);

  const parsedStartCron = parseCron(offHours.start_cron);
  const parsedStopCron = parseCron(offHours.stop_cron);

  const startTime = offHours.stop_cron
    ? moment()
        .set({
          hour: parsedStartCron?.hours || 0,
          minute: parsedStartCron?.minutes || 0,
        })
        .format()
    : offHours.end_date_time;

  const stopTime = offHours.start_cron
    ? moment()
        .set({
          hour: parsedStopCron?.hours || 0,
          minute: parsedStopCron?.minutes || 0,
        })
        .format()
    : offHours.start_date_time;

  const startWeekDays = parsedStartCron?.daysOfWeek ?? undefined;
  const stopWeekDays = parsedStopCron?.daysOfWeek ?? undefined;

  const repeatValue =
    (frequency === "daily" && parsedStartCron?.daysOfMonth) || undefined;

  return {
    action: offHours.action,
    scheduler: {
      repeatValue,
      startTime,
      stopTime,
      startWeekDays,
      stopWeekDays,
    },
  };
};
