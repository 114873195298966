import { RootState } from "../../../store";
import { liveUsageMgmtFilterTemplatesSelector } from "../liveUsageMgmtFilterTemplatesSelector";

export const orgCurrentFilterTemplatesNamesSetSelector = (
  state: RootState,
): Set<string> | undefined => {
  const templates = liveUsageMgmtFilterTemplatesSelector(state);

  if (!templates) {
    return;
  }
  return templates?.reduce((result, item) => {
    result.add(item.name);

    return result;
  }, new Set(""));
};
