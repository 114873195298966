import { FC, useCallback } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../../store/hooks";
import { offHoursRecommendationsLoadingSelector } from "../../../../../../store/recommendations/selectors/off-hours/loading/offHoursRecommendationsLoadingSelector";
import { offHoursDismissedRecommendationsLoadingSelector } from "../../../../../../store/recommendations/selectors/off-hours/loading/offHoursDismissedRecommendationsLoadingSelector";
import { rightsizingRecommendationsLoadingSelector } from "../../../../../../store/recommendations/selectors/rightsizing/loading/rightsizingRecommendationsLoadingSelector";
import { rightsizingDismissedRecommendationsLoadingSelector } from "../../../../../../store/recommendations/selectors/rightsizing/loading/rightsizingDismissedRecommendationsLoadingSelector";
import { recommendationRestoringLoadingSelector } from "../../../../../../store/recommendations/selectors/dismiss/recommendationRestoringLoadingSelector";
import { ConfirmationDialog } from "../../../../../common/modals/ConfirmationDialog";
import {
  closeReadyToActionsDialogs,
  resetDismiss,
} from "../../../../../../store/recommendations/recommendationsSlice";
import { recommendationRestoreThunk } from "../../../../../../store/recommendations/thunks/recommendationRestoreThunk";
import { recommendationsReadyToRestoreSelector } from "../../../../../../store/recommendations/selectors/dismiss/recommendationsReadyToRestoreSelector";
import { selectedIdsSelector } from "../../../../../../store/recommendations/selectors/selected-ids/selectedIdsSelector";
import { recommendationMessages } from "../../../utils/constants/recommendationDismissMessages";

export const RecommendationRestoreConfirmDialog: FC = () => {
  const dispatch = useAppDispatch();

  const open = useAppSelector(recommendationsReadyToRestoreSelector);
  const ids = useAppSelector(selectedIdsSelector);
  const multiple = ids?.length > 1;

  const restoreLoading = useAppSelector(recommendationRestoringLoadingSelector);
  const offHoursGetLoading = useAppSelector(
    offHoursRecommendationsLoadingSelector,
  );
  const offHoursDismissedGetLoading = useAppSelector(
    offHoursDismissedRecommendationsLoadingSelector,
  );
  const rightSizingGetLoading = useAppSelector(
    rightsizingRecommendationsLoadingSelector,
  );
  const rightSizingDismissedGetLoading = useAppSelector(
    rightsizingDismissedRecommendationsLoadingSelector,
  );

  const loading =
    restoreLoading ||
    offHoursGetLoading ||
    offHoursDismissedGetLoading ||
    rightSizingDismissedGetLoading ||
    rightSizingGetLoading;

  const closeHandler = useCallback(() => {
    dispatch(closeReadyToActionsDialogs());
  }, [dispatch]);

  const restoreHandler = useCallback(async () => {
    await dispatch(recommendationRestoreThunk());
    dispatch(resetDismiss());
  }, [dispatch]);

  const confirmationMessage = recommendationMessages({
    action: "restore",
    multiple: multiple,
  });

  return (
    <ConfirmationDialog
      open={open}
      title={`Restore recommendation${multiple ? "s" : ""}?`}
      dialogContent={confirmationMessage}
      CancelButtonProps={{ onClick: closeHandler }}
      ConfirmButtonProps={{
        loading,
        children: "Restore",
        onClick: restoreHandler,
      }}
      onClose={closeHandler}
    />
  );
};
