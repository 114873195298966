import { FC, ReactNode } from "react";
import { Stack, Typography } from "@mui/material";
import {
  Dates,
  ResourceExplorerGrouping,
} from "../../../../services/cloudchipr.api";
import { WidgetSourceInfo } from "../../../pages/dashboard/components/widgets/common/widget-header/source-and-name/WidgetSourceInfo";

interface WidgetHeaderProps {
  name: string;
  grouping?: ResourceExplorerGrouping;
  dates?: Dates;
  children?: ReactNode;
}

export const UnauthorizedWidgetHeader: FC<WidgetHeaderProps> = ({
  name,
  grouping,
  dates,
  children,
}) => {
  //TODO: add dimension grouping name
  return (
    <Stack
      pl={2}
      pr={0}
      py={1}
      top={0}
      zIndex={2}
      spacing={0.5}
      bgcolor="white"
      borderBottom={1}
      position="sticky"
      borderColor="grey.300"
    >
      <Typography variant="subtitle1" fontWeight="medium">
        {name}
      </Typography>

      <WidgetSourceInfo
        sourceData={{
          icon: "pie",
        }}
        groupBy={grouping}
        dates={dates ?? {}}
      >
        {children}
      </WidgetSourceInfo>
    </Stack>
  );
};
