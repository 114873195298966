import { ChangeEvent, FC, useCallback, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useNavigate } from "react-router-dom";
import { DialogTitleClosable } from "../../../../../../common/dialog-utils/DialogTitleClosable";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../store/hooks";
import { navigationHierarchyItemNoDndKey } from "../../../../sortable-tree/utils/constants";
import { getPressEnterHandler } from "../../../../../../utils/helpers/getPressEnterHandler";
import { NavigationItemsVisibilityType } from "../../../../../../../store/navigation/utils/types";
import { duplicateDashboardVisibilityHierarchyItemThunk } from "../../../../../../../store/dashboards/thunks/dashboard-hierarchy-visibility/duplicateDashboardVisibilityHierarchyItemThunk";
import { getDashboardByIdLoadingSelector } from "../../../../../../../store/dashboards/selectors/dashboard/loadings/getDashboardByIdLoadingSelector";
import { createDashboardLoadingSelector } from "../../../../../../../store/dashboards/selectors/dashboard/loadings/createDashboardLoadingSelector";
import { updateDashboardVisibilityHierarchyLoadingSelector } from "../../../../../../../store/dashboards/selectors/dashboard-hierarchy-visibility/updateDashboardVisibilityHierarchyLoadingSelector";
import { patchDashboardVisibilityHierarchyByDashboardIdLoadingSelector } from "../../../../../../../store/dashboards/selectors/dashboard-hierarchy-visibility/patchDashboardVisibilityHierarchyByDashboardIdLoadingSelector";

interface DashboardV2ItemDuplicateDialogProps {
  open: boolean;
  id: string;
  folderId?: string;
  name: string;
  visibility: NavigationItemsVisibilityType;
  closeMenu(): void;
}

export const DashboardV2ItemDuplicateDialog: FC<
  DashboardV2ItemDuplicateDialogProps
> = ({ open, closeMenu, id, folderId, name, visibility }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [dashboardName, setDashboardName] = useState(`${name} (copy)`);

  const getLoading = useAppSelector((state) =>
    getDashboardByIdLoadingSelector(state, id),
  );
  const createLoading = useAppSelector(createDashboardLoadingSelector);
  const patchLoading = useAppSelector(
    patchDashboardVisibilityHierarchyByDashboardIdLoadingSelector,
  );
  const updateLoading = useAppSelector(
    updateDashboardVisibilityHierarchyLoadingSelector,
  );
  const loading = getLoading || createLoading || patchLoading || updateLoading;

  const disabled = dashboardName.trim() === name || !dashboardName;

  const duplicateHandler = useCallback(async () => {
    const response = await dispatch(
      duplicateDashboardVisibilityHierarchyItemThunk({
        name: dashboardName,
        visibility,
        dashboardId: id,
        folderId: folderId,
      }),
    ).unwrap();

    if (response) {
      navigate({ pathname: `/dashboards/${response?.id}` });
    }
    closeMenu();
  }, [navigate, dispatch, closeMenu, dashboardName, visibility, id, folderId]);

  const closeDialogHandler = useCallback(() => {
    closeMenu();
    setDashboardName("");
  }, [closeMenu]);

  const inputChangeHandler = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setDashboardName(event.target.value);
    },
    [],
  );

  const handlePressEnter = getPressEnterHandler(
    duplicateHandler,
    disabled || loading,
  );

  return (
    <Dialog
      open={open}
      fullWidth
      onClose={closeDialogHandler}
      onKeyDown={handlePressEnter}
      {...navigationHierarchyItemNoDndKey}
    >
      <DialogTitleClosable
        title="Duplicate Dashboard"
        onClose={closeDialogHandler}
      />

      <DialogContent dividers sx={{ py: 3 }}>
        <TextField
          autoFocus
          fullWidth
          type="text"
          value={dashboardName}
          size="small"
          variant="outlined"
          onChange={inputChangeHandler}
          placeholder="Dashboard Name"
          label="Dashboard Name"
        />
      </DialogContent>

      <DialogActions sx={{ py: 2, px: 3 }}>
        <Button color="tertiary" onClick={closeDialogHandler}>
          Cancel
        </Button>
        <LoadingButton
          disabled={disabled}
          onClick={duplicateHandler}
          loading={loading}
          variant="contained"
        >
          Duplicate
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
