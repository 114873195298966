import { FC } from "react";
import Stack from "@mui/material/Stack";
import { WorkflowFrequenciesFilter } from "./WorkflowFrequenciesFilter";
import { WorkflowActionsFilter } from "./WorkflowActionsFilter";
import { WorkflowEnabledStateFilter } from "./WorkflowEnabledStateFilter";
import { WorkflowAccountsFilter } from "./WorkflowAccountsFilter";

export const WorkflowsDataGridFilters: FC = () => {
  return (
    <Stack direction="row" spacing={2}>
      <WorkflowFrequenciesFilter />

      <WorkflowActionsFilter />

      <WorkflowAccountsFilter />

      <WorkflowEnabledStateFilter />
    </Stack>
  );
};
