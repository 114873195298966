import { offHoursRecommendationsDismissedResourcesDataByGroupSelector } from "./offHoursRecommendationsDismissedResourcesDataByGroupSelector";
import { RootState } from "../../../../store";
import { RecommendationGroupType } from "../../../../../components/pages/recommendations/components/off-hours-recommendations/utils/types/types";

export const offHoursRecommendationsDismissedResourcesDataLengthByGroupSelector =
  (state: RootState, group: RecommendationGroupType): number => {
    const data = offHoursRecommendationsDismissedResourcesDataByGroupSelector(
      state,
      group,
    );

    return data?.length ?? 0;
  };
