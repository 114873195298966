import { FC, useCallback } from "react";
import { Stack, Typography, Chip, Button } from "@mui/material";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import { money } from "../../../../../../../../../utils/numeral/money";
import { resourceExplorerCSVDataLinkFetcherThunk } from "../../../../../../../../../store/resource-explorer/thunks/widgets/csv/resourceExplorerCSVDataLinkFetcherThunk";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../../store/hooks";
import { resourceExplorerCsvDownloadLoadingSelector } from "../../../../../../../../../store/resource-explorer/selectors/current-resource-explorer/csv/resourceExplorerCsvDownloadLoadingSelector";

interface ResourceExplorerTableFooterProps {
  count: number;
  gridDataCount: number;
  threshold: number;
  totalCost: number;
}

export const ResourceExplorerTableFooter: FC<
  ResourceExplorerTableFooterProps
> = ({ count, threshold, totalCost, gridDataCount }) => {
  const dispatch = useAppDispatch();
  const preparingLoading = useAppSelector(
    resourceExplorerCsvDownloadLoadingSelector,
  );

  const downloadHandler = useCallback(() => {
    dispatch(resourceExplorerCSVDataLinkFetcherThunk());
  }, [dispatch]);

  return (
    <Stack
      px={2}
      py={1}
      border={1}
      gap={0.5}
      alignItems="flex-start"
      borderColor="grey.300"
      borderRadius="0 0 8px 8px"
      justifyContent="space-between"
    >
      <Stack gap={1} direction="row">
        <Typography variant="body2">
          Showing{" "}
          <Typography component="span" fontWeight="medium" variant="body2">
            {gridDataCount}
          </Typography>{" "}
          of{" "}
          <Typography component="span" fontWeight="medium" variant="body2">
            {gridDataCount + count}
          </Typography>{" "}
          resources
        </Typography>
        <Chip
          size="xsmall"
          label={`Combined cost of ${count} resources costing less than ${money(
            threshold,
          )} is ${money(totalCost)}.`}
          sx={{
            fontWeight: "medium",
            color: "text.secondary",
            borderRadius: 2,
          }}
        />
      </Stack>
      <Stack direction="row" gap={1} alignItems="center">
        <Typography variant="caption">Export CSV to see all data</Typography>
        <Button
          onClick={downloadHandler}
          disabled={preparingLoading}
          size="small"
          variant="outlined"
          startIcon={<DownloadOutlinedIcon fontSize="small" />}
        >
          Export CSV
        </Button>
      </Stack>
    </Stack>
  );
};
