import { FC, Fragment, useCallback } from "react";
import { ListItemText, MenuItem, Select, Typography } from "@mui/material";
import moment from "moment/moment";
import {
  budgetQuarters,
  monthNames,
} from "../../utils/constants/date-selection";
import { capitalizeString } from "../../../../../../../../../utils/helpers/capitalizeString";
import { BudgetPeriod } from "../../../../../../../../../services/cloudchipr.api";
import { formatDate } from "../../../../../../../../../utils/helpers/date-time/datetime-format";
import { useAppSelector } from "../../../../../../../../../store/hooks";
import { isCurrentBudgetEditingSelector } from "../../../../../../../../../store/budgets/selectors/current-budget/data-selectors/isCurrentBudgetEditingSelector";

interface DateSelectionMonthComponentProps {
  period: BudgetPeriod;
  startDate: string;
  onDateChange(date: string): void;
}

export const DateSelectionMonthComponent: FC<
  DateSelectionMonthComponentProps
> = ({ startDate, period, onDateChange }) => {
  const isEdit = useAppSelector(isCurrentBudgetEditingSelector);

  const selectedMonthIndex = moment(startDate).get("month");

  const monthChangeHandler = useCallback(
    (event: any) => {
      onDateChange(
        formatDate(
          moment(startDate || undefined).set("month", +event.target.value),
        ),
      );
    },
    [onDateChange, startDate],
  );

  const monthSelectRenderValue = useCallback(
    (index: string) => {
      if (period === "quarterly") {
        return (
          budgetQuarters.find(
            ({ firstMonthIndex }) => firstMonthIndex === +index,
          )?.quarterName ?? index
        );
      }

      return capitalizeString(monthNames.at(+index) ?? "");
    },
    [period],
  );

  const checkIsDisabled = useCallback(
    (index: number) => {
      const currentYear = moment().get("year");
      const currentMonthIndex = moment().get("month");
      const selectedYear = moment(startDate || undefined).get("year");

      let disabled = false;

      if (selectedYear < currentYear) {
        disabled = true;
      } else if (selectedYear === currentYear) {
        disabled = currentMonthIndex > index;
      }

      return disabled;
    },
    [startDate],
  );

  return (
    <Fragment>
      <Typography variant="body2" mb={1}>
        Start {period === "monthly" ? "Month*" : "Quarter*"}
      </Typography>

      <Select
        fullWidth
        size="small"
        disabled={isEdit}
        onChange={monthChangeHandler}
        renderValue={monthSelectRenderValue}
        value={!isNaN(selectedMonthIndex) ? `${selectedMonthIndex}` : ""}
      >
        {period === "quarterly"
          ? budgetQuarters.map((quarter) => (
              <MenuItem
                key={quarter.quarterName}
                disabled={checkIsDisabled(quarter.firstMonthIndex)}
                value={`${quarter.firstMonthIndex}`}
              >
                <ListItemText>{quarter.quarterName}</ListItemText>
              </MenuItem>
            ))
          : monthNames.map((month, index) => (
              <MenuItem
                key={month}
                value={`${index}`}
                disabled={checkIsDisabled(index)}
              >
                <ListItemText>{capitalizeString(month)}</ListItemText>
              </MenuItem>
            ))}
      </Select>
    </Fragment>
  );
};
