import { FC } from "react";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Collapse,
  Typography,
} from "@mui/material";
import KeyboardArrowUpOutlinedIcon from "@mui/icons-material/KeyboardArrowUpOutlined";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import { useToggle } from "rooks";
import { blue } from "@mui/material/colors";
import { Step0 } from "./Step0";
import { Step1 } from "./Step1";
import { Step2 } from "./Step2";
import {
  GuideStep,
  StepByStepGuide,
} from "../../../../../../../../../../../common/step-by-step-guide/StepByStepGuide";
import step0Media from "../utils/assets/images/step0.svg";
import step1Media from "../utils/assets/videos/gcp_step_1.mp4";
import step2Media from "../utils/assets/videos/gcp_step_2.mp4";

export const ConsoleTabGuide: FC = () => {
  // todo: use common stepByStepGuide component instead of this, when it is merged, aslo add videos instead of images
  const [collapsed, toggleCollapsed] = useToggle();

  return (
    <Card>
      <CardHeader
        onClick={toggleCollapsed}
        sx={{
          p: 2,
          cursor: "pointer",
          bgcolor: "grey.100",
          "& .MuiCardHeader-action": {
            alignSelf: "center",
          },
        }}
        title={
          <Typography variant="body1" fontWeight="medium" py={1.5}>
            Don’t know how to assign IAM Roles? View step-by-step guide.
          </Typography>
        }
        action={
          <Box px={2}>
            {collapsed ? (
              <KeyboardArrowUpOutlinedIcon />
            ) : (
              <KeyboardArrowDownOutlinedIcon />
            )}
          </Box>
        }
      />

      <Collapse in={collapsed}>
        <CardContent
          sx={{
            py: 0,
            px: 2,
            overflow: "auto",
            bgcolor: "grey.100",
          }}
        >
          <StepByStepGuide
            steps={steps}
            onDone={toggleCollapsed}
            mediaHeight={275}
          />
        </CardContent>
      </Collapse>
    </Card>
  );
};

const steps: GuideStep[] = [
  {
    media: step0Media,
    component: <Step0 />,
    borderRadius: 2,
  },
  {
    media: step1Media,
    mediaType: "video",
    borderColor: blue[400],
    borderRadius: 2,
    component: <Step1 />,
  },
  {
    media: step2Media,
    mediaType: "video",
    borderColor: blue[400],
    borderRadius: 2,
    component: <Step2 />,
  },
];
