import { ChartDataType } from "../../../../../../../storybook/charts/multi-type-chart/utils/types/types";

export type FormatedData = {
  [recharts_chart_data_point_unique_key in string]: {
    totalCost: string;
    values: {
      [key in string]: {
        cost: number;
        percent: number;
      };
    };
  };
};

export const generateCombinatedChartData = (
  costData: ChartDataType[] | null,
  percentData: ChartDataType[] | null,
) => {
  const data: FormatedData = {};

  costData?.forEach((chartData, index) => {
    const keys = Object.keys(chartData);

    const totalCost = Object.values(chartData)
      .reduce((sum: number, value) => {
        return typeof value === "number" ? sum + value : sum;
      }, 0)
      ?.toFixed(2);

    data[chartData.recharts_chart_data_point_unique_key] = {
      values: {},
      totalCost: "0",
    };

    keys.forEach((key) => {
      data[chartData.recharts_chart_data_point_unique_key].values[key] = {
        cost: chartData[key] ?? 0,
        percent: percentData?.[index]?.[key] ?? 0,
      };
    });

    data[chartData.recharts_chart_data_point_unique_key] = {
      ...data[chartData.recharts_chart_data_point_unique_key],
      totalCost,
    };
  });

  return data;
};
