import {
  useGetUsersMeAccountByAccountIdDailyOffHoursRecommendationsAndRecommendationIdQuery,
  useGetUsersMeAccountByAccountIdWeeklyOffHoursRecommendationsAndRecommendationIdQuery,
  GetUsersMeAccountByAccountIdDailyOffHoursRecommendationsAndRecommendationIdApiArg,
  Metrics,
  MetricType,
} from "../../../../../../../services/cloudchipr.api";
import { sortArrayByDate } from "../../../../../../../utils/helpers/sorting";

type ArgsType = {
  frequency: "daily" | "weekly";
} & GetUsersMeAccountByAccountIdDailyOffHoursRecommendationsAndRecommendationIdApiArg;

export function useResourceCpuData({
  recommendationId,
  accountId,
  frequency,
}: ArgsType): {
  metrics?: Metrics[];
  isLoading: boolean;
} {
  const { data: dailyRecommendation, isLoading: dailyLoading } =
    useGetUsersMeAccountByAccountIdDailyOffHoursRecommendationsAndRecommendationIdQuery(
      { recommendationId, accountId },
      { skip: frequency !== "daily" },
    );

  const { data: weeklyRecommendation, isLoading: weeklyLoading } =
    useGetUsersMeAccountByAccountIdWeeklyOffHoursRecommendationsAndRecommendationIdQuery(
      { recommendationId, accountId },
      { skip: frequency !== "weekly" },
    );

  const isLoading = dailyLoading || weeklyLoading;

  let metrics = dailyRecommendation?.metrics || weeklyRecommendation?.metrics;

  metrics = metrics?.map((metric) => {
    let sorted = sortArrayByDate([...metric.metrics], "timestamp");

    if (frequency === "daily" && sorted.length) {
      sorted = sorted.slice(sorted.length - 24 * 7);
    }

    return {
      ...metric,
      type: metric.type.toLowerCase() as MetricType,
      metrics: sorted,
    };
  });

  return { metrics, isLoading };
}
