import { FC } from "react";
import { Stack } from "@mui/material";
import { AlertsCustomMessageIntegrationActionFromType } from "./utils/types/types";
import { IntegrationCostAnomalyAlertMessage } from "./cost-anomaly-alert/message/IntegrationCostAnomalyAlertMessage";
import { IntegrationUtilizationAlertCommitmentsExpirationMessage } from "./utilization-alert/message/commitments-expiration/IntegrationUtilizationAlertCommitmentsExpirationMessage";
import { IntegrationUtilizationAlertCommitmentsUtilizationMessage } from "./utilization-alert/message/commitments-utilization/IntegrationUtilizationAlertCommitmentsUtilizationMessage";
import { IntegrationUtilizationAlertCoverageMessage } from "./utilization-alert/message/coverage/IntegrationUtilizationAlertCoverageMessage";
import { NestedIntegration } from "../../../../../../../services/cloudchipr.api";

export interface AlertsIntegrationCustomMessageContentProps {
  type: NestedIntegration["type"];
  message: string;
  actionFrom?: AlertsCustomMessageIntegrationActionFromType;
}

export const AlertsIntegrationCustomMessageContent: FC<
  AlertsIntegrationCustomMessageContentProps
> = ({ type, actionFrom, message }) => {
  if (actionFrom === "cost-anomaly-alert") {
    return (
      <Stack spacing={1.5} pt={2}>
        {type === "email" && (
          <IntegrationCostAnomalyAlertMessage message={message} type="email" />
        )}
        {type === "slack" && (
          <IntegrationCostAnomalyAlertMessage message={message} type="slack" />
        )}
      </Stack>
    );
  }

  if (actionFrom === "commitments_expiration") {
    return (
      <Stack spacing={1.5} pt={2}>
        {type === "email" && (
          <IntegrationUtilizationAlertCommitmentsExpirationMessage
            message={message}
            type="email"
          />
        )}
        {type === "slack" && (
          <IntegrationUtilizationAlertCommitmentsExpirationMessage
            message={message}
            type="slack"
          />
        )}
      </Stack>
    );
  }

  if (actionFrom === "commitments_utilization") {
    return (
      <Stack spacing={1.5} pt={2}>
        {type === "email" && (
          <IntegrationUtilizationAlertCommitmentsUtilizationMessage
            message={message}
            type="email"
          />
        )}
        {type === "slack" && (
          <IntegrationUtilizationAlertCommitmentsUtilizationMessage
            message={message}
            type="slack"
          />
        )}
      </Stack>
    );
  }

  if (actionFrom === "coverage") {
    return (
      <Stack spacing={1.5} pt={2}>
        {type === "email" && (
          <IntegrationUtilizationAlertCoverageMessage
            message={message}
            type="email"
          />
        )}
        {type === "slack" && (
          <IntegrationUtilizationAlertCoverageMessage
            message={message}
            type="slack"
          />
        )}
      </Stack>
    );
  }

  return null;
};
