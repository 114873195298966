import { costBreakdownWidgetSetupSelector } from "./costBreakdownWidgetSetupSelector";
import { RootState } from "../../../../store";
import { CostBreakdownWidgetSetupType } from "../../../utils/types/setups/costBreakdownSetupType";

export const costBreakdownSetupPropertyByKeySelector =
  (key: keyof CostBreakdownWidgetSetupType) =>
  (state: RootState): any => {
    const setup = costBreakdownWidgetSetupSelector(state);

    if (!setup) {
      return null;
    }

    return setup[key];
  };
