import { FC } from "react";
import { Stack } from "@mui/material";
import { FilterItemsGroup } from "./FilterItemsGroup";
import { FilterItem } from "./filter-item/FilterItem";
import { ResourceExplorerFilterProvider } from "../../../../../../services/cloudchipr.api";
import {
  FiltersCombinationOperatorType,
  FilterTreeNodeWithId,
} from "../../utils/types/common";
import { isTreeNodeFilterGroup } from "../../utils/helpers/isTreeNodeFilterGroup";

interface FilterRowProps {
  filter: FilterTreeNodeWithId;
  index: number;
  filterProvider: ResourceExplorerFilterProvider;
  parentOperator: FiltersCombinationOperatorType;

  onFilterRemove(index: number): void;
  onFiltersChange(filter: FilterTreeNodeWithId, index: number): void;
  onOperatorChange(operator: FiltersCombinationOperatorType): void;
}

export const FilterRow: FC<FilterRowProps> = ({
  filter,
  index,
  onFiltersChange,
  onOperatorChange,
  parentOperator,
  filterProvider,
  onFilterRemove,
}) => {
  const filterGroup = isTreeNodeFilterGroup(filter);

  return (
    <Stack direction="row">
      {filterGroup ? (
        <FilterItemsGroup
          index={index}
          filters={filter}
          filterProvider={filterProvider}
          onFilterRemove={onFilterRemove}
          parentOperator={parentOperator}
          onOperatorChange={onOperatorChange}
          onFiltersChange={onFiltersChange}
        />
      ) : (
        <FilterItem
          index={index}
          filter={filter}
          onFilterRemove={onFilterRemove}
          parentOperator={parentOperator}
          onFilterChange={onFiltersChange}
          onOperatorChange={onOperatorChange}
        />
      )}
    </Stack>
  );
};
