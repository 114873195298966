import { getUsersMeOrganisationsCurrentWidgetsCostAndUsageSummarySelector } from "./getUsersMeOrganisationsCurrentWidgetsCostAndUsageSummarySelector";
import { RootState } from "../../../../../store";
import { costAndUsageSumSetupWidgetPayloadDataSelector } from "../costAndUsageSumSetupWidgetPayloadDataSelector";

export const costAndUsageSumSetupWidgetDataResponseSelector = (
  state: RootState,
) => {
  const payload = costAndUsageSumSetupWidgetPayloadDataSelector(state);

  if (!payload) {
    return;
  }

  return getUsersMeOrganisationsCurrentWidgetsCostAndUsageSummarySelector(
    payload,
  )(state);
};
