import { FC, Fragment } from "react";
import { Button, Skeleton } from "@mui/material";
import { useDialog } from "../../../../../../../../utils/hooks/useDialog.hook";
import { useAppSelector } from "../../../../../../../../store/hooks";
import { scheduleResourcesCountSelector } from "../../../../../../../../store/automations/selectros/workflow/resources-preview/scheduleResourcesCountSelector";
import {
  ResourceFiltersWithAction,
  ResourceType,
} from "../../../../../../../../services/cloudchipr.api";
import { scheduleResourcesLoadingSelector } from "../../../../../../../../store/automations/selectros/workflow/resources-preview/scheduleResourcesLoadingSelector";
import { ScheduleResourcesPreviewDrawer } from "../../../resources-preview-old/ScheduleResourcesPreviewDrawer";
import { ScheduleResourcesPreviewDrawerAppBar } from "../../../resources-preview-old/components/app-bar/ScheduleResourcesPreviewDrawerAppBar";

interface AutomationsFoundResourcesProps {
  accountIds: string[];
  resourceType: ResourceType;
  resourceFilters: ResourceFiltersWithAction;
  regions: string[];
  scheduleAction?: string | null;
  scheduleId: string;
  gracePeriodEnabled: boolean;
}

export const AutomationsFoundResources: FC<AutomationsFoundResourcesProps> = ({
  accountIds,
  resourceType,
  resourceFilters,
  regions,
  scheduleAction,
  scheduleId,
  gracePeriodEnabled,
}) => {
  const { open, openDialog, closeDialog } = useDialog();

  const isLoading = useAppSelector((state) =>
    scheduleResourcesLoadingSelector(state, resourceType, scheduleId),
  );

  const resourcesCount = useAppSelector((state) =>
    scheduleResourcesCountSelector(state, resourceType, scheduleId),
  );

  return (
    <Fragment>
      <Button
        sx={{ textTransform: "none", textDecoration: "underline" }}
        onClick={openDialog}
        disabled={resourcesCount === 0}
      >
        {isLoading ? ScheduleResourcesPreviewLoadingState : resourcesCount}{" "}
        resources found
      </Button>

      <ScheduleResourcesPreviewDrawer
        open={open}
        gracePeriodEnabled={gracePeriodEnabled}
        scheduleAction={scheduleAction ?? null}
        scheduleId={scheduleId}
        onClose={closeDialog}
        accountIds={accountIds}
        filters={[resourceFilters]}
        regions={regions}
      >
        <ScheduleResourcesPreviewDrawerAppBar
          accountIds={accountIds}
          scheduleId={scheduleId}
          regions={regions}
          filters={[resourceFilters]}
        />
      </ScheduleResourcesPreviewDrawer>
    </Fragment>
  );
};

const ScheduleResourcesPreviewLoadingState = (
  <Skeleton
    variant="text"
    height={24}
    width={30}
    sx={{ display: "inline-block", mx: 1 }}
  />
);
