import { createDraftSafeSelector } from "@reduxjs/toolkit";
import { recommendationAppliedTagsSelector } from "./recommendationAppliedTagsSelector";
import {
  TagsFilter,
  TagsFilterSet,
} from "../../../../components/common/select/tag-filter-dropdown-select/utils/types";

export const recommendationAppliedTagsSetSelector = createDraftSafeSelector(
  [recommendationAppliedTagsSelector],
  (
    data,
  ): {
    combinationOperator: TagsFilter["combinationOperator"];
    filter: TagsFilterSet;
  } => {
    const tagsValues = data?.value ?? [];

    const filter = tagsValues?.reduce((result, item) => {
      const values: Set<string> = new Set();
      if (typeof item?.values === "string") {
        values.add(item?.values);
      } else {
        item?.values.forEach((value) => {
          values.add(value);
        });
      }

      result.set(item.key, {
        values,
        operator: item.operator,
      });
      return result;
    }, new Map() as TagsFilterSet);

    return {
      combinationOperator: data?.combinationOperator,
      filter,
    };
  },
);
