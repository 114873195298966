import { FC, useState } from "react";

import { UserInvitationSent } from "./UserInvitationSent";
import { UserAwaitingInvitation } from "./UserAwaitingInvitation";
import { UserInvitationRequestApproved } from "./UserInvitationRequestApproved";
import { UserStatus } from "../../../services/cloudchipr.api";

interface UserInvitationWrapperProps {
  status?: UserStatus;
}
export const UserInvitationWrapper: FC<UserInvitationWrapperProps> = ({
  status,
}) => {
  const [localStatus, setLocalStatus] = useState<UserStatus | undefined>(
    status,
  );

  if (!localStatus) {
    return null;
  }

  if (localStatus === "awaiting_invitation") {
    return <UserAwaitingInvitation onStatusChange={setLocalStatus} />;
  }

  if (localStatus === "in_review") {
    return <UserInvitationSent />;
  }

  if (localStatus === "active") return <UserInvitationRequestApproved />;

  return null;
};
