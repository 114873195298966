import { scheduleResourcesSelector } from "./scheduleResourcesSelector";
import { ResourceType } from "../../../../../services/cloudchipr.api";
import { RootState } from "../../../../store";

export const scheduleResourcesLoadingSelector = (
  state: RootState,
  resourceType: ResourceType,
  scheduleId: string,
): boolean => {
  const response = scheduleResourcesSelector(state, resourceType, scheduleId);

  return !!response?.isLoading;
};
