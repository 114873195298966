import { SvgIcon, SvgIconProps } from "@mui/material";

function DetailsSvgIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <svg
        width="17"
        height="16"
        viewBox="0 0 17 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M3.16667 12H9.83333C10.2 12 10.5 11.7 10.5 11.3333C10.5 10.9667 10.2 10.6667 9.83333 10.6667H3.16667C2.8 10.6667 2.5 10.9667 2.5 11.3333C2.5 11.7 2.8 12 3.16667 12ZM2.5 4.66667C2.5 5.03333 2.8 5.33333 3.16667 5.33333H13.8333C14.2 5.33333 14.5 5.03333 14.5 4.66667C14.5 4.3 14.2 4 13.8333 4H3.16667C2.8 4 2.5 4.3 2.5 4.66667ZM3.16667 8.66667H13.8333C14.2 8.66667 14.5 8.36667 14.5 8C14.5 7.63333 14.2 7.33333 13.8333 7.33333H3.16667C2.8 7.33333 2.5 7.63333 2.5 8C2.5 8.36667 2.8 8.66667 3.16667 8.66667Z"
          fill="#3F51B5"
        />
      </svg>
    </SvgIcon>
  );
}

DetailsSvgIcon.muiName = "DetailsSvgIcon";

export default DetailsSvgIcon;
