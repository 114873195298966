import { FC } from "react";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { List, Paper, Typography } from "@mui/material";
import { ListRowItem } from "./ListRowItem";
import { HoverPopover } from "../../../../../../../common/hover-popover/HoverPopover";
import { gcpOrganizationRoleViewerName } from "../../utils/gcp-roles";

export const TabPanelGcpConsoleOrganizationRoleIcon: FC = () => {
  return (
    <HoverPopover
      hoverElement={
        <InfoOutlinedIcon
          color="action"
          fontSize="small"
          sx={{ verticalAlign: "middle", ml: 1 }}
        />
      }
    >
      <Paper sx={{ px: 2, py: 1 }}>
        <Typography variant="body2" mb={0.5}>
          The role name can be different based on your language settings:
        </Typography>

        <List disablePadding>
          <ListRowItem type="US" role={gcpOrganizationRoleViewerName} />

          <ListRowItem
            type="UK"
            role={gcpOrganizationRoleViewerName.replace(
              "Organization",
              "Organisation",
            )}
          />
        </List>
      </Paper>
    </HoverPopover>
  );
};
