import { ChangeEvent, FC, Fragment } from "react";
import { TextField, Typography } from "@mui/material";

interface AzureProvideTenantIdFieldProps {
  value: string;
  error?: string;
  onChange(event: ChangeEvent): void;
}
export const AzureProvideTenantIdField: FC<AzureProvideTenantIdFieldProps> = ({
  value,
  error,
  onChange,
}) => {
  return (
    <Fragment>
      <Typography
        variant="body2"
        gutterBottom
        mt={4}
        mb={2}
        color="text.secondary"
        fontWeight="medium"
      >
        Please provide the following values in order to connect your Azure
        subscription.
      </Typography>

      <TextField
        value={value}
        error={!!error}
        helperText={error}
        name="tenantId"
        onChange={onChange}
        size="medium"
        fullWidth
        label="Tenant ID"
      />
    </Fragment>
  );
};
