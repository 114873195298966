import { ColDef } from "@ag-grid-community/core/dist/types/src/entities/colDef";

export const reportsModifiedBySortingFn: ColDef["comparator"] = (
  valueA,
  valueB,
  nodeA,
  nodeB,
) => {
  const current =
    nodeA.data.modified_by?.name ?? nodeA.data.modified_by?.email ?? "";
  const next =
    nodeB.data.modified_by?.name ?? nodeB.data.modified_by?.email ?? "";

  if (current?.includes("deleted")) {
    return 1;
  }

  if (next?.includes("deleted")) {
    return -1;
  }

  return current.localeCompare(next);
};
