import { FC, Fragment, useCallback } from "react";
import { Menu, MenuItem, PopoverOrigin } from "@mui/material";
import Typography from "@mui/material/Typography";
import DashboardCustomizeOutlinedIcon from "@mui/icons-material/DashboardCustomizeOutlined";
import { DashboardV2FolderMoveActionMenuConfirmationDialog } from "./DashboardV2FolderMoveActionMenuConfirmationDialog";
import { navigationHierarchyItemNoDndKey } from "../../../../sortable-tree/utils/constants";
import { NavigationItemsVisibilityType } from "../../../../../../../store/navigation/utils/types";
import { TypographyWithTooltip } from "../../../../../../common/TypographyWithTooltip";
import { updateDashboardVisibilityHierarchyThunk } from "../../../../../../../store/dashboards/thunks/dashboard-hierarchy-visibility/updateDashboardVisibilityHierarchyThunk";
import { useAppDispatch } from "../../../../../../../store/hooks";
import { useDialog } from "../../../../../../../utils/hooks/useDialog.hook";

interface DashboardV2FolderMoveActionMenuProps {
  id: string;
  name: string;
  open: boolean;
  onClose(): void;
  anchor: HTMLElement | null;
  visibility: NavigationItemsVisibilityType;
}

export const DashboardV2FolderMoveActionMenu: FC<
  DashboardV2FolderMoveActionMenuProps
> = ({ id, anchor, onClose, open, visibility, name }) => {
  const dispatch = useAppDispatch();
  const {
    open: openConfirm,
    openDialog: openConfirmDialog,
    closeDialog: closeConfirmDialog,
  } = useDialog();

  const handleClose = useCallback(() => {
    closeConfirmDialog();
    onClose();
  }, [closeConfirmDialog, onClose]);

  const moveHandler = useCallback(async () => {
    await dispatch(
      updateDashboardVisibilityHierarchyThunk({
        id,
        index: 0,
        visibility:
          visibility === "visible_to_everyone"
            ? "visible_only_to_me"
            : "visible_to_everyone",
        type: "folder",
      }),
    );

    onClose();
  }, [id, dispatch, onClose, visibility]);

  return (
    <Fragment>
      <Menu
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
        open={open}
        onClose={onClose}
        anchorEl={anchor}
        slotProps={slotProps}
        {...navigationHierarchyItemNoDndKey}
      >
        <Typography variant="subtitle2" color="text.secondary" pl={1}>
          {visibility === "visible_only_to_me"
            ? "Visible to everyone"
            : "Visible only to me"}
        </Typography>

        <MenuItem onClick={openConfirmDialog} sx={{ pl: 1.5 }}>
          <DashboardCustomizeOutlinedIcon fontSize="small" />
          <TypographyWithTooltip
            title="Root"
            variant="body2"
            placement="top"
            sx={{ pl: 1 }}
          />
        </MenuItem>
      </Menu>

      {openConfirm && (
        <DashboardV2FolderMoveActionMenuConfirmationDialog
          name={name}
          onMove={moveHandler}
          visibility={visibility}
          onClose={handleClose}
        />
      )}
    </Fragment>
  );
};

const slotProps = {
  paper: {
    sx: {
      minWidth: 200,
      maxWidth: 400,
      maxHeight: 400,
    },
  },
};
const anchorOrigin: PopoverOrigin = {
  vertical: "top",
  horizontal: "right",
};
const transformOrigin: PopoverOrigin = {
  vertical: "top",
  horizontal: "left",
};
