import { resourceTypeAbbreviationNames } from "./resourceTypeAbbreviationNames";
import { ResourceType } from "../../../../services/cloudchipr.api";

export const resourceServiceAbbreviationNames: Map<
  ResourceType,
  { singular: string; plural: string }
> = new Map([
  ...Array.from(resourceTypeAbbreviationNames),
  ["ec2", { singular: "EC2", plural: "EC2" }],
  ["nat_gateway", { singular: "VPC", plural: "VPC" }],
  ["rds", { singular: "RDS", plural: "RDS" }],
  ["eks", { singular: "EKS", plural: "EKS" }],
  ["vm", { singular: "Compute Engine", plural: "Compute Engines" }],
  ["ip", { singular: "Network", plural: "Networks" }],
  ["dynamo_db_table", { singular: "DynamoDB", plural: "DynamoDB" }],
  ["ecs", { singular: "ECS", plural: "ECS" }],
]);
