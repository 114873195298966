import { FC, useCallback } from "react";
import { MenuItem } from "@mui/material";
import { MenuItemProps } from "@mui/material/MenuItem/MenuItem";
import {
  AccountId,
  ProviderType,
} from "../../../../../../../services/cloudchipr.api";
import { useAccountAddFunctional } from "../../../../utils/hooks/useAccountAddFunctional.hook";

interface AccountAttachMenuItemProps extends MenuItemProps {
  accountId: AccountId;
  provider: ProviderType;
}

export const AccountAttachMenuItem: FC<AccountAttachMenuItemProps> = ({
  accountId,
  provider,
}) => {
  const { startAddAccountFlow, canNotCreateAccount } = useAccountAddFunctional({
    accountId,
    provider,
  });

  const handleAttachClick = useCallback(() => {
    startAddAccountFlow();
  }, [startAddAccountFlow]);

  return (
    <MenuItem onClick={handleAttachClick} disabled={canNotCreateAccount}>
      Attach
    </MenuItem>
  );
};
