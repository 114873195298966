import { ProviderType } from "../../../../../services/cloudchipr.api";
import { RootState } from "../../../../store";
import { BillingAccountStatusWithProvider } from "../../../utils/types/types";
import { isBillingWarningStatus } from "../../../utils/helpers/billing-status/isBillingWarningStatus";
import { getCurrentProvidersByProviderBillingStatusDataSelector } from "../getCurrentProvidersByProviderBillingStatusDataSelector";

export const billingWarningAccountStatusesByProviderSelector = (
  state: RootState,
  provider: ProviderType,
): BillingAccountStatusWithProvider[] => {
  const data = getCurrentProvidersByProviderBillingStatusDataSelector(
    state,
    provider,
  );

  const billingStatuses = data?.billing_account_statuses ?? [];

  if (!billingStatuses.length && isBillingWarningStatus(data?.status)) {
    return [
      {
        status: data?.status,
        provider,
      },
    ];
  }

  const result: BillingAccountStatusWithProvider[] = [];
  billingStatuses?.forEach((item) => {
    if (isBillingWarningStatus(item.status)) {
      result.push({
        ...item,
        provider,
      });
    }
  });

  return result;
};
