import { FC, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import Typography from "@mui/material/Typography";

export const BackToRecommendationsButton: FC = () => {
  const navigate = useNavigate();

  const backHandler = useCallback(() => {
    navigate("/recommendations/rightsizing");
  }, [navigate]);

  return (
    <Button
      onClick={backHandler}
      startIcon={<ArrowBackOutlinedIcon sx={iconStyles} color="action" />}
      sx={{ mb: 2 }}
    >
      <Typography
        variant="body1"
        color="text.primary"
        textTransform="none"
        fontWeight="medium"
      >
        Back
      </Typography>
    </Button>
  );
};

const iconStyles = {
  "& .BackToRecommendationsButton": {
    fontSize: 24,
  },
};
