import { FC, Fragment, useMemo } from "react";
import { Chip, Stack } from "@mui/material";
import {
  AccountsListPopover,
  AccountsWithPopoverAccounts,
} from "./AccountsListPopover";
import { AccountInfoData } from "../AccountInfoData";
import { useMenuHook } from "../../../utils/hooks/useMenu.hook";

interface AccountsWithPopoverProps {
  accounts: AccountsWithPopoverAccounts;
  chipView?: boolean;
  maxWidth?: number;
}

export const AccountsWithPopover: FC<AccountsWithPopoverProps> = ({
  accounts,
  chipView,
  maxWidth,
}) => {
  const { anchor, open, openMenu, closeMenu } = useMenuHook();
  const activeAccounts = useMemo(() => {
    return accounts.filter(
      ({ status }) => status === "connected" || status === undefined,
    );
  }, [accounts]);

  const firstAccount = activeAccounts?.[0];

  const restAccounts = useMemo(() => {
    const { aws, gcp, azure, other } = activeAccounts.reduce(
      (all, account) => {
        if (account.provider === "aws") {
          all.aws.push(account);
        } else if (account.provider === "gcp") {
          all.gcp.push(account);
        } else if (account.provider === "azure") {
          all.azure.push(account);
        } else {
          all.other.push(account);
        }

        return all;
      },
      { aws: [], gcp: [], azure: [], other: [] } as Record<
        string,
        AccountsWithPopoverAccounts
      >,
    );

    return [...aws, ...gcp, ...azure, ...other];
  }, [activeAccounts]);

  if (!firstAccount) {
    return null;
  }

  const isDeletedOneAccount = accounts.every((account) =>
    account.status ? account.status !== "connected" : false,
  );

  if (isDeletedOneAccount) {
    return <Fragment>-</Fragment>;
  }

  return (
    <Stack direction="row" alignItems="center" overflow="hidden">
      <AccountInfoData
        chipView={chipView}
        accountName={firstAccount.provider_account_name}
        accountId={firstAccount.provider_account_id}
        provider={firstAccount.provider}
        maxWidth={maxWidth}
      />

      {restAccounts?.length > 1 && (
        <Fragment>
          <Chip
            label={`+${restAccounts.length - 1}`}
            onClick={openMenu}
            variant="outlined"
            size="small"
            sx={{ ml: 0.5 }}
          />

          <AccountsListPopover
            open={open}
            anchor={anchor}
            accounts={restAccounts}
            closeMenu={closeMenu}
          />
        </Fragment>
      )}
    </Stack>
  );
};
