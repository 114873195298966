import { Stack, Tooltip, Typography } from "@mui/material";
import StarsIcon from "@mui/icons-material/Stars";
import { ColumnSetupType } from "../../../../../../../../storybook/data-grid/utils/types/types";
import {
  SavingsPlanCoverageGrouping,
  CommitmentResourceType,
} from "../../../../../../../../services/cloudchipr.api";
import { money } from "../../../../../../../../utils/numeral/money";
import { CommitmentsProgressBar } from "../../../../../common/components/CommitmentsProgressBar";
import { SavingsPlanCoverageDataWithId } from "../../../../../common/utils/types";
import { CommitmentsTableName } from "../../../../../common/components/data-grid/cells/CommitmentsTableName";
import { commitmentsResourceTypeNames } from "../../../../../common/utils/constants/labels";
import { HeaderCellWidthDateTooltip } from "../../../../../common/components/data-grid/cells/HeaderCellWidthDateTooltip";
import { TypographyWithTooltip } from "../../../../../../../common/TypographyWithTooltip";
import { UtilizationAndCommitmentColumnsHeaderCellWithTotalAndDate } from "../components/cells/UtilizationAndCommitmentColumnsHeaderCellWithTotalAndDate";

const groupedByAccountColumns: ColumnSetupType<SavingsPlanCoverageDataWithId>[] =
  [
    {
      minSize: 100,
      accessorKey: "account.name" as keyof SavingsPlanCoverageDataWithId,
      header: "Account",
      meta: { hideFromSettings: true },
      cell: ({ row }) => {
        return (
          <CommitmentsTableName
            type="account"
            id={row.original.id}
            name={
              <Stack alignItems="start">
                <Stack direction="row" spacing={0.5}>
                  {row.original.account?.is_management_account && (
                    <Tooltip title="Management account" placement="top" arrow>
                      <StarsIcon color="primary" fontSize="small" />
                    </Tooltip>
                  )}

                  <TypographyWithTooltip
                    variant="body2"
                    title={row.original.account?.name}
                  />
                </Stack>
                <TypographyWithTooltip
                  variant="body2"
                  title={row.original.account?.provider_account_id}
                />
              </Stack>
            }
          />
        );
      },
    },
  ];

const groupedByResourceColumns: ColumnSetupType<SavingsPlanCoverageDataWithId>[] =
  [
    {
      meta: { hideFromSettings: true },
      minSize: 120,
      id: "resource_type",
      accessorKey:
        "resource_type.resource_type" as keyof SavingsPlanCoverageDataWithId,
      header: "Resource Type",
      cell: ({ row }) => {
        const rt = row.original.resource_type
          ?.resource_type as CommitmentResourceType;

        return (
          <CommitmentsTableName
            type="resource_type"
            id={row.original.id}
            name={
              <TypographyWithTooltip
                title={commitmentsResourceTypeNames[rt]}
                variant="body2"
                align="left"
              />
            }
          />
        );
      },
    },
    {
      minSize: 110,
      id: "instance_family",
      accessorKey:
        "resource_type.instance_family" as keyof SavingsPlanCoverageDataWithId,
      header: "Instance Family",
      cell: ({ row }) => {
        return row.original.resource_type?.instance_family ?? "-";
      },
    },
    {
      minSize: 70,
      id: "region",
      accessorKey:
        "resource_type.region" as keyof SavingsPlanCoverageDataWithId,
      header: "Region",
      cell: ({ row }) => {
        return row.original.resource_type?.region ?? null;
      },
    },
  ];

const columnsWithoutGrouping: ColumnSetupType<SavingsPlanCoverageDataWithId>[] =
  [
    {
      minSize: 210,
      accessorKey: "covered_usage",
      header: () => (
        <UtilizationAndCommitmentColumnsHeaderCellWithTotalAndDate
          title="Spend Covered by Savings Plans"
          type="coveredUsage"
        />
      ),

      cell: ({ row }) => (
        // height 45 is for virtualization
        <Stack justifyContent="center" height={45}>
          <Typography align="right" pr={3.5} variant="body2">
            {money(row.original.covered_usage)}
          </Typography>
        </Stack>
      ),
      meta: { headerTitle: "Spend Covered by Savings Plans" },
    },
    {
      minSize: 210,
      accessorKey: "uncovered_usage",
      header: () => (
        <UtilizationAndCommitmentColumnsHeaderCellWithTotalAndDate
          type="unCoveredUsage"
          title="Not Covered (On-Demand)"
        />
      ),
      meta: { headerTitle: "Not Covered (On-Demand)" },
      cell: ({ row }) => (
        <Typography align="right" pr={4} variant="body2">
          {money(row.original.uncovered_usage)}
        </Typography>
      ),
    },
    {
      minSize: 80,
      accessorKey: "net_savings",
      header: () => (
        <UtilizationAndCommitmentColumnsHeaderCellWithTotalAndDate
          title="Net Savings"
          type="netSavings"
        />
      ),
      cell: ({ row }) => (
        <Typography align="right" pr={4} variant="body2">
          {money(row.original.net_savings)}
        </Typography>
      ),
      meta: { headerTitle: "Net Savings" },
    },
    {
      enableResizing: false,
      minSize: 150,
      accessorKey: "coverage",
      header: () => <HeaderCellWidthDateTooltip title="Coverage" />,
      cell: ({ row }) => (
        <CommitmentsProgressBar value={row.original.coverage} />
      ),
      meta: { headerTitle: "Coverage" },
    },
  ];

const accountGroupedColumns = [
  ...groupedByAccountColumns,
  ...columnsWithoutGrouping,
];
const resourceGroupedColumns = [
  ...groupedByResourceColumns,
  ...columnsWithoutGrouping,
];

export const savingsPlanCoverageTableColumns = (
  grouping: SavingsPlanCoverageGrouping,
): ColumnSetupType<SavingsPlanCoverageDataWithId>[] => {
  if (grouping === "account") {
    return accountGroupedColumns;
  }

  if (grouping === "resource_type") {
    return resourceGroupedColumns;
  }

  return [];
};
