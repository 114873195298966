import { userRolesSelector } from "./userRolesSelector";
import { RootState } from "../../../store";
import { UserSlice } from "../../utils/types/common";

export const userRoleDataSelector = (
  state: RootState,
): UserSlice["roles"]["data"] => {
  const slice = userRolesSelector(state);

  return slice?.data;
};
