import { allResourceTypesWithProviders } from "./allResourceTypesWithProviders";
import { ProviderType, ResourceType } from "../../../services/cloudchipr.api";

// this returns all resources types, included child resources types
export const allResourcesTypesByProvider = Object.entries(
  allResourceTypesWithProviders,
).reduce(
  (acc, [rt, provider]) => {
    const resourceType = rt as ResourceType;

    if (acc[provider]) {
      acc[provider].push(resourceType);
    } else {
      acc[provider] = [resourceType];
    }

    return acc;
  },
  {} as Record<ProviderType, ResourceType[]>,
);
