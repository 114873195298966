import { FC, useCallback } from "react";
import { TaskPropertyItem } from "./TaskPropertyItem";
import { TaskPropertiesDragDropWrapper } from "./components/TaskPropertiesDragDropWrapper";
import { TaskPropertyAdder } from "./components/TaskPropertyAdder";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../store/hooks";
import { GetUsersMeOrganisationsCurrentTasksPropertiesApiResponse } from "../../../../../../../services/cloudchipr.api";
import { setTaskManagementProperties } from "../../../../../../../store/task-management/taskManagementSlice";
import { TaskPropertyType } from "../../../../../../../store/task-management/utils/types/types";
import { taskManagementEditingActionsSelector } from "../../../../../../../store/task-management/selectors/properties/edit/taskManagementEditingActionsSelector";
import { taskManagementEditingPrioritiesSelector } from "../../../../../../../store/task-management/selectors/properties/edit/taskManagementEditingPrioritiesSelector";
import { taskManagementEditingEnvironmentsSelector } from "../../../../../../../store/task-management/selectors/properties/edit/taskManagementEditingEnvironmentsSelector";
import { newCreatedPropId } from "../../../utils/constants/common";

interface TaskPropertyEditSectionProps {
  type: Exclude<
    keyof GetUsersMeOrganisationsCurrentTasksPropertiesApiResponse,
    "statuses"
  >;
}

const propertiesSelectorsByType = {
  actions: taskManagementEditingActionsSelector,
  priorities: taskManagementEditingPrioritiesSelector,
  environments: taskManagementEditingEnvironmentsSelector,
};

export const TaskPropertyEditSection: FC<TaskPropertyEditSectionProps> = ({
  type,
}) => {
  const dispatch = useAppDispatch();
  const properties = useAppSelector(propertiesSelectorsByType[type]);

  const propertyChangeHandler = useCallback(
    (properties: TaskPropertyType[]) => {
      dispatch(setTaskManagementProperties({ [type]: properties }));
    },
    [dispatch, type],
  );

  const newPropertyAddHandler = useCallback(
    (color: string) => {
      dispatch(
        setTaskManagementProperties({
          [type]: [
            ...properties,
            { name: "", color, id: `${newCreatedPropId}_${properties.length}` },
          ],
        }),
      );
    },
    [dispatch, type, properties],
  );

  return (
    <TaskPropertiesDragDropWrapper
      properties={properties}
      onPropertiesChange={propertyChangeHandler}
    >
      {properties.map(({ name, color, id }, index) => {
        return (
          <TaskPropertyItem
            key={id + color}
            id={id}
            color={color}
            name={name}
            index={index}
            properties={properties}
            onPropertiesChange={propertyChangeHandler}
            isLast={properties.length === 1}
          />
        );
      })}

      <TaskPropertyAdder
        label={labels[type]}
        properties={properties}
        onPropertyAdd={newPropertyAddHandler}
      />
    </TaskPropertiesDragDropWrapper>
  );
};

const labels = {
  actions: "Action",
  priorities: "Priority",
  environments: "Environment",
};
