import { FC, Fragment } from "react";
import { Card, LinearProgress } from "@mui/material";
import { CommitmentUtilizationWidgetSetupSubHeader } from "./components/CommitmentUtilizationWidgetSetupSubHeader";
import { CommitmentUtilizationWidgetSetupContentCosts } from "./components/CommitmentUtilizationWidgetSetupContentCosts";
import { CommitmentUtilizationWidgetSetupNumeralView } from "./components/CommitmentUtilizationWidgetSetupNumeralView";
import { CommitmentUtilizationWidgetSetupTableView } from "./components/CommitmentUtilizationWidgetSetupTableView";
import { CommitmentUtilizationWidgetSetupChartView } from "./components/CommitmentUtilizationWidgetSetupChartView";
import { WidgetSetupContentWrapper } from "../../../common/WidgetSetupContentWrapper";
import { widgetSetupContentChartHeight } from "../../../../../../../utils/constants/stylings";
import { useAppSelector } from "../../../../../../../../../../store/hooks";
import { commitmentUtilizationSetupVisualizationPropertyByKeySelector } from "../../../../../../../../../../store/dashboards/selectors/setups/commitment-utilization/commitmentUtilizationSetupVisualizationPropertyByKeySelector";
import { commitmentUtilizationWidgetSetupDataLoadingSelector } from "../../../../../../../../../../store/dashboards/selectors/widgets/commitment-utilization/setup/commitmentUtilizationWidgetSetupDataLoadingSelector";
import { commitmentUtilizationWidgetSetupEmptyDataSelector } from "../../../../../../../../../../store/dashboards/selectors/widgets/commitment-utilization/setup/commitmentUtilizationWidgetSetupEmptyDataSelector";
import { NoCommitmentsCard } from "../../../../../../widgets/commitment-utlization/components/NoCommitmentsCard";
import { commitmentUtilizationSetupHasAggregationSelector } from "../../../../../../../../../../store/dashboards/selectors/setups/commitment-utilization/commitmentUtilizationSetupHasAggregationSelector";

export const CommitmentUtilizationWidgetSetupContent: FC = () => {
  const chartType = useAppSelector(
    commitmentUtilizationSetupVisualizationPropertyByKeySelector("chart_type"),
  );
  const hasAggregation = useAppSelector(
    commitmentUtilizationSetupHasAggregationSelector,
  );
  const loading = useAppSelector(
    commitmentUtilizationWidgetSetupDataLoadingSelector,
  );
  const emptyData = useAppSelector(
    commitmentUtilizationWidgetSetupEmptyDataSelector,
  );

  return (
    <WidgetSetupContentWrapper>
      <Card
        elevation={3}
        sx={{
          height: widgetSetupContentChartHeight,
          display: "flex",
          flexDirection: "column",
        }}
      >
        {loading && <LinearProgress />}

        <CommitmentUtilizationWidgetSetupSubHeader />

        {emptyData && !loading ? (
          <NoCommitmentsCard />
        ) : (
          <Fragment>
            {hasAggregation && <CommitmentUtilizationWidgetSetupContentCosts />}

            {chartType === "numeral" && (
              <CommitmentUtilizationWidgetSetupNumeralView />
            )}

            {chartType === "table" && (
              <CommitmentUtilizationWidgetSetupTableView />
            )}

            {!["numeral", "table"].includes(chartType) && (
              <CommitmentUtilizationWidgetSetupChartView />
            )}
          </Fragment>
        )}
      </Card>
    </WidgetSetupContentWrapper>
  );
};
