import { FC, Fragment, useCallback, useState } from "react";
import { ListItemIcon, Menu, MenuItem, Typography } from "@mui/material";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { useFlag } from "@unleash/proxy-client-react";
import { DashboardV2ItemRenameDialog } from "./dialogs/DashboardV2ItemRenameDialog";
import { DashboardV2ItemDeleteDialog } from "./dialogs/DashboardV2ItemDeleteDialog";
import { DashboardV2ItemMoveAction } from "./actions/move-action/DashboardV2ItemMoveAction";
import { DashboardV2AddWidgetAction } from "./actions/DashboardV2AddWidgetAction";
import { DashboardV2ItemDuplicateDialog } from "./dialogs/DashboardV2ItemDuplicateDialog";
import { CopyURLAction } from "../../../../../common/copy-url/CopyURLAction";
import { navigationHierarchyItemNoDndKey } from "../../../sortable-tree/utils/constants";
import { NavigationItemsVisibilityType } from "../../../../../../store/navigation/utils/types";

interface DashboardV2ItemListItemActionMenuProps {
  id: string;
  name: string;
  open: boolean;
  closeMenu(): void;
  anchor: HTMLElement | null;
  folderId?: string;
  visibility: NavigationItemsVisibilityType;
}

export const DashboardV2ItemListItemActionMenu: FC<
  DashboardV2ItemListItemActionMenuProps
> = ({ name, id, open, anchor, closeMenu, folderId, visibility }) => {
  const [selectedActionType, setSelectedActionType] = useState<
    "delete" | "rename" | "duplicate" | null
  >();

  const enableResourceExplorerDuplicate = useFlag(
    "EnableResourceExplorerDuplicate",
  );

  const deleteClickHandler = useCallback(() => {
    setSelectedActionType("delete");
  }, []);

  const renameClickHandler = useCallback(() => {
    setSelectedActionType("rename");
  }, []);

  const duplicateClickHandler = useCallback(() => {
    setSelectedActionType("duplicate");
  }, []);

  const closeDialog = useCallback(() => {
    closeMenu();
    setSelectedActionType(null);
  }, [closeMenu]);

  return (
    <Fragment>
      <Menu
        open={open}
        onClose={closeMenu}
        anchorEl={anchor}
        {...navigationHierarchyItemNoDndKey}
      >
        <MenuItem onClick={renameClickHandler} sx={menuItemSx}>
          <ListItemIcon>
            <EditOutlinedIcon fontSize="small" sx={iconSx} />
          </ListItemIcon>
          <Typography variant="body2">Rename</Typography>
        </MenuItem>

        <CopyURLAction
          iconSx={iconSx}
          link={`${window.location.host}/dashboards/${id}`}
          onClose={closeMenu}
          menuItemSx={menuItemSx}
        />

        <DashboardV2AddWidgetAction
          iconSx={iconSx}
          dashboardId={id}
          onClose={closeMenu}
          menuItemSx={menuItemSx}
        />

        <DashboardV2ItemMoveAction
          id={id}
          name={name}
          folderId={folderId}
          onClose={closeMenu}
          visibility={visibility}
        />

        {enableResourceExplorerDuplicate && (
          <MenuItem onClick={duplicateClickHandler} sx={menuItemSx}>
            <ListItemIcon>
              <ContentCopyIcon fontSize="small" sx={iconSx} />
            </ListItemIcon>
            <Typography variant="body2">Duplicate</Typography>
          </MenuItem>
        )}

        <MenuItem onClick={deleteClickHandler} sx={menuItemSx}>
          <ListItemIcon>
            <DeleteOutlineIcon fontSize="small" sx={iconSx} />
          </ListItemIcon>
          <Typography variant="body2">Delete</Typography>
        </MenuItem>
      </Menu>

      {selectedActionType === "delete" && (
        <DashboardV2ItemDeleteDialog
          open
          id={id}
          name={name}
          closeMenu={closeDialog}
          visibility={visibility}
        />
      )}

      {selectedActionType === "rename" && (
        <DashboardV2ItemRenameDialog
          open
          id={id}
          name={name}
          closeMenu={closeDialog}
        />
      )}

      {selectedActionType === "duplicate" && (
        <DashboardV2ItemDuplicateDialog
          open
          id={id}
          folderId={folderId}
          name={name}
          visibility={visibility}
          closeMenu={closeDialog}
        />
      )}
    </Fragment>
  );
};

const menuItemSx = {
  pl: 1,
  pr: 1.5,
};

const iconSx = {
  minWidth: 28,
};
