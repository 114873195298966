import { recommendationsDataGridDataSelector } from "./data/recommendationsDataGridDataSelector";
import { RootState } from "../../../store";
import { RecommendationDataType } from "../../../../components/pages/recommendations/components/off-hours-recommendations/utils/types/types";

export const offHoursRecommendationsSelector = (
  state: RootState,
): RecommendationDataType[] => {
  const computeRecommendations = recommendationsDataGridDataSelector(
    state,
    "compute",
  );
  const dbRecommendations = recommendationsDataGridDataSelector(
    state,
    "database",
  );

  return [...computeRecommendations, ...dbRecommendations];
};
