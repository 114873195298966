import { FC, ReactChild, useCallback } from "react";
import { Alert, Box, Link, List, ListItem, Typography } from "@mui/material";
import { gcpOrganizationRoleViewerName } from "./utils/gcp-roles";
import { TabPanelGcpConsoleOrganizationRoleIcon } from "./components/organization-role-viewer/TabPanelGCPConsoleOrganizationRoleIcon";
import { CommandSnippet } from "../CommandSnippet";
import { CopyIconButton } from "../../../../../common/CopyIconButton";

interface TabPanelGCPConsoleProps {
  serviceAccount?: string;
  description?: string | ReactChild;
  roles?: string[];
  guideText: string;
}
export const TabPanelGCPConsole: FC<TabPanelGCPConsoleProps> = ({
  serviceAccount,
  description,
  roles,
  guideText,
}) => {
  const preventCapturing = useCallback((event: any) => {
    event.stopPropagation();
  }, []);

  return (
    <Box color="text.secondary" mb={3}>
      {serviceAccount && <CommandSnippet code={serviceAccount} />}
      <Alert
        variant="outlined"
        icon={false}
        severity="info"
        sx={{ alignItems: "center", bgcolor: "#E5F4F8", borderRadius: 2 }}
      >
        {description}
        <List dense sx={{ width: 280, mt: 1 }}>
          {roles?.map((role) => (
            <ListItem key={role} sx={{ px: 0, py: 0.5 }}>
              <Typography variant="body2" color="primary" fontWeight="bold">
                {role}
              </Typography>

              {gcpOrganizationRoleViewerName === role && (
                <TabPanelGcpConsoleOrganizationRoleIcon />
              )}

              <CopyIconButton data={role} visible color="primary" />
            </ListItem>
          ))}
        </List>
      </Alert>

      <Typography variant="body2" mt={2}>
        {guideText}{" "}
        <Link
          href="https://cloud.google.com/iam/docs/granting-changing-revoking-access#grant-single-role"
          target="_blank"
          underline="none"
          onClick={preventCapturing}
        >
          View the guide.
        </Link>
      </Typography>
    </Box>
  );
};
