import { createAsyncThunk } from "@reduxjs/toolkit";
import { tasksListDataByIdSelector } from "../selectors/data/tasksListDataByIdSelector";
import { RootState } from "../../store";
import {
  setTaskManagementDrawerOpen,
  setTaskManagementTask,
  setTaskManagementTaskTarget,
} from "../taskManagementSlice";

export const openDrawerForTaskByIdThunk = createAsyncThunk(
  "taskManagement/openDrawerForTaskById",
  async (taskId: string, { dispatch, getState }) => {
    const state = getState() as RootState;

    const task = tasksListDataByIdSelector(state, taskId);

    if (!task) {
      return;
    }

    dispatch(setTaskManagementDrawerOpen(true));

    dispatch(
      setTaskManagementTask({
        id: task.id,
        name: task.name,
        status_id: task.status.id,
        priority_id: task.priority?.id ?? null,
        action_id: task.action?.id ?? null,
        environment_id: task.environment?.id ?? null,
        description: task.description,
        assignees: task.assignees?.map(({ id }) => id),
        due_date: task.due_date,
      }),
    );

    dispatch(
      setTaskManagementTaskTarget({
        type: "resource",
        resources: [task.target],
      }),
    );
  },
);
