import { generateKey } from "./generateKey";
import {
  getResourceExplorerDataGridSavedData,
  getResourceExplorerSavedDataGridVisibilities,
} from "./getResourceExplorerDataGridSavedData";
import { ResourceExplorerDataGridView } from "../../../../../../../../store/resource-explorer/utils/types/resourceExplorerDataGrid";

export const setResourceExplorerDataGridSavingData = (
  viewId: string,
  {
    viewType,
    visibilities,
  }: {
    viewType?: ResourceExplorerDataGridView;
    visibilities?: Record<string, boolean>;
  },
) => {
  const key = generateKey(viewId);

  const savedViewType = getResourceExplorerDataGridSavedData(viewId);
  const savedVisibilities =
    getResourceExplorerSavedDataGridVisibilities(viewId);

  return localStorage.setItem(
    key,
    JSON.stringify({
      viewType: viewType ?? savedViewType,
      visibilities: visibilities ?? savedVisibilities,
    }),
  );
};
