import { getLastPastTrendTooltipTitle } from "./getLastPastTrendTooltipTitle";
import { getToDateTrendTooltipTitle } from "./getToDateTrendTooltipTitle";
import { getCustomRangeTrendTooltipTitle } from "./getCustomRangeTrendTooltipTitle";
import { DateLabelNullable } from "../../../../../../services/cloudchipr.api";

export const getTrendTooltipTitleByDateLabel = (
  currentDatePeriod: string,
  previousDatesPeriod: string,
  dateLabel?: DateLabelNullable,
) => {
  if (!dateLabel) {
    return getCustomRangeTrendTooltipTitle(
      currentDatePeriod,
      previousDatesPeriod,
    );
  }

  if (dateLabel === "month_to_date" || dateLabel === "quarter_to_date") {
    return getToDateTrendTooltipTitle(
      currentDatePeriod,
      previousDatesPeriod,
      dateLabel === "month_to_date" ? "month" : "quarter",
    );
  }

  return getLastPastTrendTooltipTitle(
    currentDatePeriod,
    previousDatesPeriod,
    dateLabel,
  );
};
