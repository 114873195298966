import { Account, ProviderType } from "../../../services/cloudchipr.api";
import { noOrgAccountOrgId } from "../../../components/pages/accounts-group/utils/helpers/noOrganizationConstants";
import {
  AccountGroupedByProviderAndOrgType,
  AccountsGroupedByProvider,
} from "../utils/types/types";

export const accountsGroupedByProviderAndOrgCombiner = (
  accountsGroupedByProvider: AccountsGroupedByProvider | null,
) => {
  if (!accountsGroupedByProvider) {
    return null;
  }

  return Object.entries(accountsGroupedByProvider).reduce((acc, entry) => {
    const provider = entry.at(0) as ProviderType;
    const accounts = (entry.at(1) ?? []) as Account[];

    acc[provider] = accounts.reduce(
      (acc, account) => {
        const orgId = account.group_id || noOrgAccountOrgId;

        if (!acc[orgId]) {
          acc[orgId] = [];
        }

        acc[orgId].push(account);

        return acc;
      },
      {} as Record<string, Account[]>,
    );

    return acc;
  }, {} as AccountGroupedByProviderAndOrgType);
};
