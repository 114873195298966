import { IconButton } from "@mui/material";
import { KeyboardArrowDown, KeyboardArrowRight } from "@mui/icons-material";
import { ColumnSetupType } from "../utils/types/types";

export const getExpanderCell = <T,>(
  props?: Partial<ColumnSetupType<T>>,
): ColumnSetupType<T> => ({
  id: "__expander__",
  enableResizing: false,
  enableSorting: false,
  size: 40,
  cell: ({ row }) => (
    <IconButton size="small" onClick={row.getToggleExpandedHandler()}>
      {row.getIsExpanded() ? <KeyboardArrowDown /> : <KeyboardArrowRight />}
    </IconButton>
  ),
  ...props,
});
