import { FC } from "react";
import { ResourceExplorerTableFooter } from "./ResourceExplorerTableFooter";
import { useAppSelector } from "../../../../../../../../../store/hooks";
import { resourceExplorerDataGridAggregatedDataSelector } from "../../../../../../../../../store/resource-explorer/selectors/current-resource-explorer/data-grid/resourceExplorerDataGridAggregatedDataSelector";
import { resourceExplorerDataGridDataCountSelector } from "../../../../../../../../../store/resource-explorer/selectors/current-resource-explorer/data-grid/resourceExplorerDataGridDataCountSelector";

export const ResourceExplorerTotalViewFooter: FC = () => {
  const aggregatedData = useAppSelector(
    resourceExplorerDataGridAggregatedDataSelector,
  );
  const gridDataCount = useAppSelector(
    resourceExplorerDataGridDataCountSelector,
  );

  if (!aggregatedData || !gridDataCount) {
    return null;
  }

  return (
    <ResourceExplorerTableFooter
      count={+aggregatedData.metadata.count}
      threshold={+aggregatedData.metadata.threshold}
      totalCost={aggregatedData.total_cost}
      gridDataCount={gridDataCount}
    />
  );
};
