import { FC, Fragment, useMemo } from "react";
import { Divider } from "@mui/material";
import { ScheduledNextRunListItem } from "./ScheduledNextRunListItem";
import { NullableDate } from "../../../../../../services/cloudchipr.api";
import {
  dateFromUtcByTimeZone,
  dateToUtcByTimeZone,
} from "../../../../../../utils/helpers/date-time/datetime-format";
import { getTimeZoneObject } from "../../../../../../utils/helpers/date-time/timeZones";

interface ScheduledMoreNextRunsPopoverTimeZonesProps {
  nextRun?: NullableDate;
  timeZone: string;
}
export const ScheduledMoreNextRunsPopoverTimeZones: FC<
  ScheduledMoreNextRunsPopoverTimeZonesProps
> = ({ nextRun, timeZone }) => {
  const localNextRunTime = useMemo(() => {
    const utcDate = dateToUtcByTimeZone(nextRun ?? "", timeZone);

    return dateFromUtcByTimeZone(utcDate, getTimeZoneObject().tzCode);
  }, [nextRun, timeZone]);

  if (!nextRun) {
    return null;
  }

  return (
    <Fragment>
      <ScheduledNextRunListItem
        nextRun={nextRun}
        timeZone={timeZone}
        label="In Workflow Time Zone"
      />
      <Divider light />
      <ScheduledNextRunListItem
        nextRun={localNextRunTime}
        label="In Local Time Zone"
      />
    </Fragment>
  );
};
