import { FC, Fragment } from "react";
import Typography from "@mui/material/Typography";
import { Card, CardContent, Container, Grid } from "@mui/material";
import { ProfileUpdateForm } from "./components/ProfileUpdateForm";
import { ProfileSelfDelete } from "./components/ProfileSelfDelete";
import { PageHeader } from "../../../common/PageHeader";

export const Profile: FC = () => {
  return (
    <Fragment>
      <PageHeader title="Profile" />

      <Container sx={{ p: 2 }} disableGutters maxWidth={false}>
        <Card variant="outlined">
          <CardContent>
            <Grid container justifyContent="center">
              <Grid item xs={12} md={10} lg={6} pb={5}>
                <Typography variant="h6">Profile information.</Typography>
                <Typography variant="body1" color="text.secondary" gutterBottom>
                  Edit your profile information, Invite new users or check your
                  account billing information here
                </Typography>
                <ProfileUpdateForm />
                <ProfileSelfDelete />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Container>
    </Fragment>
  );
};
