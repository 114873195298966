import { FC, useCallback } from "react";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../store/hooks";
import { alertsDataNotificationsSelector } from "../../../../../../../store/alerts/selectors/common/data/alertsDataNotificationsSelector";
import {
  CostAnomalyAlert,
  Emails,
} from "../../../../../../../services/cloudchipr.api";
import { setAlertsData } from "../../../../../../../store/alerts/alertsSlice";
import { NotificationsSelection } from "../../../../../common/notification-selection/NotificationsSelection";
import { alertsDataEmailsSelector } from "../../../../../../../store/alerts/selectors/common/data/alertsDataEmailsSelector";
import { AlertsCustomMessageIntegrationActionFromType } from "../custom-message/utils/types/types";
import { AlertsIntegrationCustomMessage } from "../custom-message/AlertsIntegrationCustomMessage";
import { IntegrationCustomMessageProps } from "../../../../../automations/common/components/custom-message/IntegrationCustomMessage";

interface AlertsNotificationsProps {
  actionFrom: AlertsCustomMessageIntegrationActionFromType;
}

export const AlertsNotifications: FC<AlertsNotificationsProps> = ({
  actionFrom,
}) => {
  const dispatch = useAppDispatch();

  const emails = useAppSelector(alertsDataEmailsSelector);
  const notifications = useAppSelector(alertsDataNotificationsSelector);

  const emailsChangeHandler = useCallback(
    (emails: Emails | null) => {
      dispatch(setAlertsData({ emails }));
    },
    [dispatch],
  );

  const integrationsChangeHandler = useCallback(
    (notifications: CostAnomalyAlert["notifications"]) => {
      dispatch(setAlertsData({ notifications }));
    },
    [dispatch],
  );

  const integrationCustomMessageHandler = useCallback(
    (props: IntegrationCustomMessageProps) => {
      return (
        <AlertsIntegrationCustomMessage {...props} actionFrom={actionFrom} />
      );
    },
    [actionFrom],
  );

  return (
    <NotificationsSelection
      selectedEmails={emails ?? null}
      emailsChangeHandler={emailsChangeHandler}
      disabledIntegrationTypes={["jira"]}
      selectedIntegrations={notifications}
      setSelectedIntegrations={integrationsChangeHandler}
      IntegrationCustomMessage={integrationCustomMessageHandler}
    />
  );
};
