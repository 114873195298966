import { navigationDashboardDataSelector } from "./navigationDashboardDataSelector";
import { TreeItems } from "../../../../components/navigation/components/sortable-tree/utils/types";
import { RootState } from "../../../store";

export const navigationDashboardPublicDataSelector = (
  state: RootState,
): TreeItems | undefined => {
  const data = navigationDashboardDataSelector(state);
  return data?.visible_to_everyone ?? undefined;
};
