import { FC } from "react";
import { Stack, Typography } from "@mui/material";
import { CreateAlertButton } from "../../CreateAlertButton";
import AlertsEmptyState from "../../../../../../assets/images/alerts_empty_state.png";

export const AlertsEmptyPage: FC = () => {
  return (
    <Stack alignItems="center" justifyContent="center" py={15} gap={4}>
      <img src={AlertsEmptyState} alt="AlertsEmptyState" width={160} />

      <Stack gap={3} alignItems="center">
        <Typography variant="h6" fontWeight="bold">
          You haven't created any alerts yet.
        </Typography>
        <Typography variant="body1" color="text.secondary">
          Start by creating a new alert to get notified about any significant
          cost changes.
        </Typography>
        <CreateAlertButton variant="outlined" />
      </Stack>
    </Stack>
  );
};
