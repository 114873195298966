import { FC, Fragment, useMemo } from "react";
import { Grid } from "@mui/material";
import { getTimeZoneObject } from "../../../../../../../utils/helpers/date-time/timeZones";
import { getAutomationPreviewFormatDate } from "../../../../common/utils/helpers/common";
import { SchedulePreviewTable } from "../../../../../automations/common/components/resources-preview-old/preview/SchedulePreviewTable";
import { SchedulePreviewSectionTitle } from "../../../../../automations/common/components/resources-preview-old/preview/SchedulePreviewSectionTitle";

interface OffHoursDetailsPreviewProps {
  timeZone: string;
  startDate: string;
  nextRunStartDate: string;
  nextRunStopDate: string;
  endDate: string | null;
  instanceStart?: string;
  instanceStop?: string;
}

export const OffHoursDetailsPreview: FC<OffHoursDetailsPreviewProps> = ({
  timeZone,
  endDate,
  startDate,
  nextRunStartDate,
  nextRunStopDate,
  instanceStart,
  instanceStop,
}) => {
  const data = useMemo(() => {
    const rows = [];

    if (instanceStart && instanceStop) {
      rows.push(
        {
          title: "Instance STOP",
          label: instanceStop,
        },
        {
          title: "Instance START",
          label: instanceStart,
        },
      );
    }

    rows.push(
      { title: "Next stop date", label: nextRunStopDate },
      { title: "Next start date", label: nextRunStartDate },
      {
        title: "Begins",
        label: startDate ? getAutomationPreviewFormatDate(startDate) : "now",
      },
      {
        title: "Ends",
        label: endDate ? getAutomationPreviewFormatDate(endDate) : "never",
      },
      {
        title: "Time zone",
        label: getTimeZoneObject(timeZone).name,
      },
    );

    return rows;
  }, [
    endDate,
    startDate,
    nextRunStartDate,
    nextRunStopDate,
    timeZone,
    instanceStart,
    instanceStop,
  ]);

  return (
    <Fragment>
      <SchedulePreviewSectionTitle title="Schedule Details" />

      <Grid container>
        <Grid item xs={12} md={12}>
          <SchedulePreviewTable data={data} />
        </Grid>
      </Grid>
    </Fragment>
  );
};
