import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";

import { FC, useCallback } from "react";
import { Box } from "@mui/material";
import { Responsive, WidthProvider, Layout, Layouts } from "react-grid-layout";
import { EmptyDashboard } from "./corner-components/EmptyDashboard";
import { DashboardCostAndUsageSumWidget } from "./widgets/cost-and-usage/DashboardCostAndUsageSumWidget";
import { DashboardLayoutAddingWidgetCardButton } from "./adding-widget/DashboardLayoutAddingWidgetCardButton";
import { WidgetWrapper } from "./WidgetWrapper";
import { LargestCostChangesWidget } from "./widgets/largest-cost-change/LargestCostChangesWidget";
import { DashboardCostBreakdownWidget } from "./widgets/cost-breakdown/DashboardCostBreakdownWidget";
import { DashboardCommitmentUtilizationWidget } from "./widgets/commitment-utlization/DashboardCommitmentUtilizationWidget";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { WidgetType } from "../../../../services/cloudchipr.api";
import { widgetSetupContentChartHeight } from "../utils/constants/stylings";
import { currentDashboardLayoutSelector } from "../../../../store/dashboards/selectors/dashboard/currentDashboardLayoutSelector";
import { updateDashboardByIdLayoutThunk } from "../../../../store/dashboards/thunks/dashboard/updateDashboardByIdLayoutThunk";
import {
  draggableHandleClassnames,
  reactGridLayoutBreakpoints,
  reactGridLayoutCols,
  reactGridLayoutMargin,
  reactGridLayoutStyles,
} from "../utils/constants/reactGridLayout";
import { dashboardByIdDataSelector } from "../../../../store/dashboards/selectors/dashboard/dashboardByIdDataSelector";
import { dashboardByIdLoadingSelector } from "../../../../store/dashboards/selectors/dashboard/loadings/dashboardByIdLoadingSelector";
import { dashboardHierarchyVisibilitiesLoadingSelector } from "../../../../store/dashboards/selectors/dashboard-hierarchy-visibility/dashboardHierarchyVisibilitiesLoadingSelector";

const ResponsiveGridLayout = WidthProvider(Responsive);

interface DashboardLayoutProps {
  dashboardId: string;
}

export const DashboardLayout: FC<DashboardLayoutProps> = ({ dashboardId }) => {
  const dispatch = useAppDispatch();

  const dashboard = useAppSelector((state) =>
    dashboardByIdDataSelector(state, dashboardId),
  );
  const loading = useAppSelector((state) =>
    dashboardByIdLoadingSelector(state, dashboardId),
  );
  const widgetsData = dashboard?.widgets;

  const layouts = useAppSelector(currentDashboardLayoutSelector);
  const dashboardLoading = useAppSelector(
    dashboardHierarchyVisibilitiesLoadingSelector,
  );

  const handleLayoutChange = useCallback(
    (_: Layout[], newLayout: Layouts) => {
      if (dashboardId) {
        dispatch(updateDashboardByIdLayoutThunk({ dashboardId, newLayout }));
      }
    },
    [dispatch, dashboardId],
  );

  if (!widgetsData?.length && !loading) {
    return <EmptyDashboard />;
  }

  if (!layouts || dashboardLoading) {
    return null;
  }

  return (
    <Box pb={3} sx={reactGridLayoutStyles}>
      <ResponsiveGridLayout
        isDraggable
        isResizable
        layouts={layouts}
        className="layout"
        cols={reactGridLayoutCols}
        margin={reactGridLayoutMargin}
        onLayoutChange={handleLayoutChange}
        rowHeight={widgetSetupContentChartHeight}
        breakpoints={reactGridLayoutBreakpoints}
        draggableHandle={draggableHandleClassnames.wrapper}
      >
        {widgetsData?.map((widget) => {
          const Component = widgetsComponents[widget.type];

          if (!Component) {
            return null;
          }

          return (
            <WidgetWrapper key={widget.id} widgetId={widget.id}>
              <Component widgetId={widget.id} />
            </WidgetWrapper>
          );
        })}
      </ResponsiveGridLayout>

      <DashboardLayoutAddingWidgetCardButton />
    </Box>
  );
};

const widgetsComponents: Partial<Record<WidgetType, FC<any>>> = {
  cost_breakdown: DashboardCostBreakdownWidget,
  cost_and_usage_summary: DashboardCostAndUsageSumWidget,
  largest_cost_changes: LargestCostChangesWidget,
  commitments_utilization: DashboardCommitmentUtilizationWidget,
};
