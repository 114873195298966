import { FC, useCallback, useState, Fragment } from "react";
import { Box, TextField, Typography } from "@mui/material";
import { getGcpConnectionInitialConnectionId } from "./utils/getGcpConnectionInitialConnectionId";
import { CommandSnippet } from "../../CommandSnippet";
import { useAppSelector } from "../../../../../../../store/hooks";
import { providerAccessTypeSelector } from "../../../../../../../store/account-setup/selectors/providerAccessTypeSelector";
import { prefillAccountDetailsSelector } from "../../../../../../../store/account-setup/selectors/prefillAccountDetailsSelector";
import { gcpConnectionTypeSelector } from "../../../../../../../store/account-setup/selectors/gcpConnectionTypeSelector";
import { CollapsableCommand } from "../../CollapsableCommand";
import { capitalizeString } from "../../../../../../../utils/helpers/capitalizeString";
import { gcpServiceAccountSelector } from "../../../../../../../store/account-setup/selectors/gcpServiceAccountSelector";

export const TabPanelGCPCli: FC = () => {
  const serviceAccount = useAppSelector(gcpServiceAccountSelector);
  const providerAccessType = useAppSelector(providerAccessTypeSelector);
  const prefillAccountDetails = useAppSelector(prefillAccountDetailsSelector);
  const connectionType = useAppSelector(gcpConnectionTypeSelector);

  const [id, setId] = useState(
    prefillAccountDetails?.connectionTypeId ||
      getGcpConnectionInitialConnectionId(connectionType),
  );

  const projectIdChangeHandler = useCallback((e: any) => {
    setId(e.target.value);
  }, []);

  const connectionTypeIdJSX = (
    <Typography
      component="span"
      fontFamily="monospace"
      color={id ? "#FFCC56" : "error"}
    >
      {id || "--"}
    </Typography>
  );

  const connectionTitle =
    connectionType === "single" ? "project" : "organization";

  const commandRw =
    connectionType === "organization"
      ? `gcloud ${connectionTitle}s add-iam-policy-binding ${id} --member serviceAccount:${serviceAccount} --role "roles/editor" \ngcloud ${connectionTitle}s add-iam-policy-binding ${id} --member serviceAccount:${serviceAccount} --role "roles/iam.organizationRoleViewer" \ngcloud ${connectionTitle}s add-iam-policy-binding ${id} --member serviceAccount:${serviceAccount} --role "roles/browser"
      `
      : `gcloud ${connectionTitle}s add-iam-policy-binding ${id} --member serviceAccount:${serviceAccount} --role "roles/editor"`;
  const commandRo =
    connectionType === "organization"
      ? `gcloud ${connectionTitle}s add-iam-policy-binding ${id} --member serviceAccount:${serviceAccount} --role "roles/viewer" \ngcloud ${connectionTitle}s add-iam-policy-binding ${id} --member serviceAccount:${serviceAccount} --role "roles/iam.organizationRoleViewer" \ngcloud ${connectionTitle}s add-iam-policy-binding ${id} --member serviceAccount:${serviceAccount} --role "roles/browser"`
      : `gcloud ${connectionTitle}s add-iam-policy-binding ${id} --member serviceAccount:${serviceAccount} --role "roles/viewer"`;
  const command = providerAccessType === "read_write" ? commandRw : commandRo;

  const projectIdFocusHandler = useCallback(
    (event: any) => {
      if (
        event.target.value ===
        getGcpConnectionInitialConnectionId(connectionType)
      ) {
        event.target.select();
      }
    },
    [connectionType],
  );

  return (
    <Box>
      <Typography variant="body2" color="text.secondary" component="div">
        Please make sure you have gcloud CLI installed and you are logged in to
        your GCP {connectionTitle}. Enter your{" "}
        {capitalizeString(connectionTitle)} ID, then run the following command
        in your terminal. Cloudchipr will proceed to the next step automatically
        as soon as the access is provided.
      </Typography>

      <TextField
        autoFocus
        fullWidth
        value={id}
        onChange={projectIdChangeHandler}
        size="small"
        placeholder={`${connectionTitle.toUpperCase()} ID`}
        label={`${connectionTitle.toUpperCase()} ID`}
        sx={{ verticalAlign: "middle", mt: 2, mb: 1 }}
        onFocus={projectIdFocusHandler}
      />

      <CommandSnippet code={command} disabled={!id}>
        <div>
          {providerAccessType === "read_write" ? (
            <Fragment>
              gcloud {`${connectionTitle}s `}
              add-iam-policy-binding {connectionTypeIdJSX} --member
              serviceAccount:{serviceAccount} --role "roles/editor"
              {connectionType === "organization" && (
                <Fragment>
                  <br />
                  gcloud {`${connectionTitle}s `}
                  add-iam-policy-binding {connectionTypeIdJSX} --member
                  serviceAccount:{serviceAccount} --role
                  "roles/iam.organizationRoleViewer"
                  <br />
                  gcloud {`${connectionTitle}s `}
                  add-iam-policy-binding {connectionTypeIdJSX} --member
                  serviceAccount:{serviceAccount} --role "roles/browser"
                </Fragment>
              )}
            </Fragment>
          ) : (
            <Fragment>
              gcloud {`${connectionTitle}s `}
              add-iam-policy-binding {connectionTypeIdJSX} --member
              serviceAccount:{serviceAccount} --role "roles/viewer"
              {connectionType === "organization" && (
                <Fragment>
                  <br />
                  gcloud {`${connectionTitle}s `}
                  add-iam-policy-binding {connectionTypeIdJSX} --member
                  serviceAccount:{serviceAccount} --role
                  "roles/iam.organizationRoleViewer"
                  <br />
                  gcloud {`${connectionTitle}s `}
                  add-iam-policy-binding {connectionTypeIdJSX} --member
                  serviceAccount:{serviceAccount} --role "roles/browser"
                </Fragment>
              )}
            </Fragment>
          )}
        </div>
      </CommandSnippet>

      <CollapsableCommand
        message={`* Use the following command to find the ${capitalizeString(
          connectionTitle,
        )}  ID`}
        commandSnippetCode={`gcloud ${connectionTitle}s list`}
      />
    </Box>
  );
};
