import { RootState } from "../../../../store";
import { cloudChiprApi } from "../../../../../services/cloudchipr.api";
import { createResourceExplorerGcpBillingExportDataFixedCacheKey } from "../../../../../components/pages/resource-explorer/components/resource-explorer-card/utils/constants/fixedCacheKeys";

const selector =
  cloudChiprApi.endpoints.postUsersMeOrganisationsCurrentGcpBillingExportData.select(
    createResourceExplorerGcpBillingExportDataFixedCacheKey,
  );
export const createResourceExplorerGcpBillingExportDataErrorSelector = (
  state: RootState,
) => {
  // TODO: Add error type in api spec
  const error: any = selector(state).error;
  return error?.data;
};
