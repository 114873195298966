import { Stack, Tooltip, Typography } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { DimensionFiltersCount } from "./DimensionFiltersCount";
import { DimensionActions } from "./actions/DimensionActions";
import { dimensionsNameColumnFilter } from "./helpers/filterFunctions/dimensionsNameColumnFilter";
import { dimensionsTotalCostColumnFilter } from "./helpers/filterFunctions/dimensionsTotalCostColumnFilter";
import { dimensionsFilteredCostColumnFilter } from "./helpers/filterFunctions/dimensionsFilteredCostColumnFilter";
import { ColumnSetupType } from "../../../../../../storybook/data-grid/utils/types/types";
import { money } from "../../../../../../utils/numeral/money";
import { DimensionWithCosts } from "../../../../../../services/cloudchipr.api";
import { getExpanderCell } from "../../../../../../storybook/data-grid/common/getExpanderCell";
import { TypographyWithTooltip } from "../../../../../common/TypographyWithTooltip";

export const dimensionsDataGridColumns: ColumnSetupType<DimensionWithCosts>[] =
  [
    getExpanderCell<DimensionWithCosts>(),
    {
      size: 250,
      minSize: 250,
      maxSize: 250,
      accessorKey: "name",
      header: "Name",
      cell: (cell) => {
        return (
          <TypographyWithTooltip
            title={cell.getValue() as string}
            variant="subtitle1"
            fontWeight="medium"
            color="text.secondary"
          />
        );
      },
      filterFn: dimensionsNameColumnFilter,
    },
    {
      accessorKey: "total_cost",
      size: 150,
      minSize: 150,
      maxSize: 150,
      header: () => (
        <Typography
          align="right"
          width="100%"
          variant="body2"
          fontWeight="medium"
        >
          Total Cost (MTD)
        </Typography>
      ),
      cell: (cell) => {
        return (
          <Typography
            align="right"
            pr={4}
            variant="subtitle1"
            fontWeight="medium"
          >
            {money(cell.getValue() as string)}
          </Typography>
        );
      },
      filterFn: dimensionsTotalCostColumnFilter,
    },
    {
      accessorKey: "filtered_resource_costs",
      size: 200,
      minSize: 200,
      maxSize: 200,
      header: () => (
        <Stack direction="row" flex={1} spacing={1} justifyContent="end">
          <Typography variant="body2" fontWeight="medium">
            Live Filtered
          </Typography>

          <Tooltip
            title="Total monthly price of live filtered resources found in Live Usage & Mgmt for current account groups."
            placement="top"
            arrow
          >
            <InfoOutlinedIcon color="action" fontSize="small" />
          </Tooltip>
        </Stack>
      ),

      cell: (cell) => {
        return (
          <Typography
            align="right"
            pr={4}
            variant="subtitle1"
            fontWeight="medium"
            color="error"
          >
            {money(cell.getValue() as string)}
          </Typography>
        );
      },
      filterFn: dimensionsFilteredCostColumnFilter,
    },

    {
      id: "filters",
      size: 150,
      minSize: 150,
      maxSize: 150,
      header: "Filters",
      enableSorting: false,
      cell: DimensionFiltersCount,
    },

    {
      id: "action",
      size: 40,
      minSize: 40,
      maxSize: 40,
      enableSorting: false,
      cell: DimensionActions,
    },
  ];
