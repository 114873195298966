import { RootState } from "../../store";
import { cloudChiprApi } from "../../../services/cloudchipr.api";
import { createReportFixedCacheKey } from "../utils/constants/fixedCacheKeys";

const endpoint =
  cloudChiprApi.endpoints.postUsersMeOrganisationsCurrentReports.select(
    createReportFixedCacheKey,
  );

export const createReportLoadingSelector = (state: RootState) => {
  return endpoint(state)?.isLoading;
};
