import { createAsyncThunk } from "@reduxjs/toolkit";
import { goToNextStep, setAccountAttempt } from "../accountSetupSlice";
import {
  cloudChiprApi,
  PostUsersMeAccountAttemptsApiArg,
} from "../../../services/cloudchipr.api";
import { RootState } from "../../store";

export const createAccountAttemptThunk = createAsyncThunk(
  "accountSetup/createAccountAttempt",
  async (_, { dispatch, getState }) => {
    const { postUsersMeAccountAttempts } = cloudChiprApi.endpoints;
    const {
      accountSetup: { provider, providerAccessType },
    } = getState() as RootState;
    const postArg: PostUsersMeAccountAttemptsApiArg = {
      body: {
        type: provider,
        access_type: providerAccessType,
      },
    };

    const result = await dispatch(
      postUsersMeAccountAttempts.initiate(postArg),
    ).unwrap();

    if (result) {
      dispatch(setAccountAttempt(result));
      dispatch(goToNextStep());
    }
  },
);
