import {
  FilterSet,
  ProviderType,
} from "../../../../../../services/cloudchipr.api";

export const getDefaultFilterTemplates = (
  data: FilterSet[],
  provider: ProviderType,
) => {
  const defaultTemplate = data.find(
    (filterSet) =>
      filterSet.source === "cloudchipr" &&
      filterSet.is_default &&
      filterSet.cloud_provider === provider,
  );

  const c8rTemplates = data.filter(
    (filterSet) =>
      filterSet.source === "cloudchipr" &&
      filterSet.cloud_provider === provider &&
      defaultTemplate?.id !== filterSet.id,
  );

  if (!defaultTemplate) {
    return c8rTemplates;
  }

  return [defaultTemplate, ...c8rTemplates];
};
