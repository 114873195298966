import { FC } from "react";
import { Container, Card, Divider, Typography } from "@mui/material";
import { useDidMount } from "rooks";
import { IntegrationsEmptyState } from "./IntegrationsEmptyState";
import { IntegrationsListGrid } from "./IntegrationsListGrid";
import { useGetUsersMeIntegrationsQuery } from "../../../../../services/cloudchipr.api";
import { getWorkflowsThunk } from "../../../../../store/automations/thunks/wokrflows/getWorkflowsThunk";
import { useAppDispatch } from "../../../../../store/hooks";
import { getCostAnomalyAlertsThunk } from "../../../../../store/alerts/thunks/cost-anomaly-alerts/getCostAnomalyAlertsThunk";
import { getUtilizationAlertsThunk } from "../../../../../store/alerts/thunks/utilization-alerts/getUtilizationAlertsThunk";

export const IntegrationsList: FC = () => {
  const dispatch = useAppDispatch();
  const { data } = useGetUsersMeIntegrationsQuery({});

  useDidMount(() => {
    dispatch(getWorkflowsThunk());
    dispatch(getCostAnomalyAlertsThunk());
    dispatch(getUtilizationAlertsThunk());
  });

  return (
    <Container sx={{ p: 2 }} maxWidth={false} disableGutters>
      <IntegrationsEmptyState />

      {!!data?.length && (
        <Card variant="outlined">
          <Typography variant="h5" fontWeight="bold" my={3} pl={3}>
            Integrations
          </Typography>
          <Divider />
          <IntegrationsListGrid />
        </Card>
      )}
    </Container>
  );
};
