import { generateLiveFilteredResourcesColumns } from "./generateLiveFilteredResourcesColumns";
import { ResourceType } from "../../../../../../../../../../../../../services/cloudchipr.api";
import { ColumnSetupType } from "../../../../../../../../../../../../../storybook/data-grid/utils/types/types";
import { newMetricColumnsByFT } from "../../../../../../../../../../../live-usage-mgmt/components/resource-card/resource-card-content/resource-card-data/resource-card-grid/resouce-card-data-grid/cells/metrics-cells/utils/helpers/newMetricColumnsEnablenes";
import { getResourceTypeColumns } from "../../../../../../../../../../../live-usage-mgmt/components/resource-card/resource-card-content/resource-card-data/resource-card-grid/resouce-card-data-grid/utils/data-grid/columns-setup/getResourceTypeColumns";
import { ResourceItemType } from "../../../../../../../../../../../../../store/live-usage-mgmt/utils/types/types";

export const getLiveFilteredResourcesColumns = (
  resourceType: ResourceType,
  options?: { includeFilterTemplate?: boolean; enableMetricsCharts?: boolean },
) => {
  const columns = getResourceTypeColumns(
    resourceType,
    newMetricColumnsByFT(options?.enableMetricsCharts),
  ) as ColumnSetupType<ResourceItemType>[];

  return generateLiveFilteredResourcesColumns(columns, options);
};
