import { FC, Fragment } from "react";
import { Button } from "@mui/material";

interface IntegrationCustomMessageDialogActionsProps {
  onSecondaryBtnClick(): void;
  onPrimaryBtnClick(): void;
}
export const IntegrationCustomMessageDialogActions: FC<
  IntegrationCustomMessageDialogActionsProps
> = ({ onSecondaryBtnClick, onPrimaryBtnClick }) => {
  return (
    <Fragment>
      <Button onClick={onSecondaryBtnClick}>Cancel</Button>
      <Button onClick={onPrimaryBtnClick} variant="contained">
        Save
      </Button>
    </Fragment>
  );
};
