import { FC, useCallback, useMemo } from "react";
import { ListItemIcon, ListItemText, MenuItem } from "@mui/material";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../store/hooks";
import { useAppAbility } from "../../../../../../../services/permissions";
import { tasksListGetLoadingSelector } from "../../../../../../../store/task-management/selectors/loading/tasksListGetLoadingSelector";
import { taskManagementUpdateTaskThunk } from "../../../../../../../store/task-management/thunks/actions/taskManagementUpdateTaskThunk";
import { taskAvailablePropertiesSelector } from "../../../../../../../store/task-management/selectors/properties/taskAvailablePropertiesSelector";
import { taskUpdateLoadingSelector } from "../../../../../../../store/task-management/selectors/loading/taskUpdateLoadingSelector";

interface TaskCompleteActionMenuItemProps {
  taskId: string;
  onClose(): void;
}

export const TaskCompleteActionMenuItem: FC<
  TaskCompleteActionMenuItemProps
> = ({ taskId, onClose }) => {
  const dispatch = useAppDispatch();
  const { can } = useAppAbility();
  const canEditTask = can("edit", "task");

  const properties = useAppSelector(taskAvailablePropertiesSelector);

  const completedStatusId = useMemo(() => {
    return properties?.statuses?.find(
      (status) => status.category === "completed",
    )?.id;
  }, [properties]);

  const updateLoading = useAppSelector(taskUpdateLoadingSelector);
  const getLoading = useAppSelector(tasksListGetLoadingSelector);

  const clickHandler = useCallback(async () => {
    if (!completedStatusId) {
      return;
    }

    await dispatch(
      taskManagementUpdateTaskThunk({
        task_ids: [taskId],
        status_id: completedStatusId,
      }),
    );

    onClose();
  }, [dispatch, onClose, completedStatusId, taskId]);

  return (
    <MenuItem
      dense
      disabled={!canEditTask || updateLoading || getLoading}
      onClick={clickHandler}
    >
      <ListItemIcon>
        <CheckCircleOutlineOutlinedIcon fontSize="small" />
      </ListItemIcon>
      <ListItemText>Mark Complete</ListItemText>
    </MenuItem>
  );
};
