import { savingsOpportunitiesDataSelector } from "./savingsOpportunitiesDataSelector";
import { RootState } from "../../store";
import { Tag } from "../../../services/cloudchipr.api";
import { getResourceTypeName } from "../../../utils/helpers/resources/getResourceTypeName";
import { money } from "../../../utils/numeral/money";

export const savingsOpportunitiesCSVDataSelector = (state: RootState) => {
  const opportunities = savingsOpportunitiesDataSelector(state);

  return (
    opportunities?.map((opportunity) => {
      return {
        "Action Type": opportunity.action_type,
        Description: opportunity.description,
        Service: getResourceTypeName(opportunity.service, {
          type: "long",
          singular: true,
        }),
        "Resource Type": opportunity.resource_type,
        "Resource ID": opportunity.resource_id,
        "Implementation Effort": opportunity.implementation_effort,
        "Based On Past": opportunity.based_on_past
          ? `${opportunity.based_on_past} days`
          : "-",
        Region: opportunity.region,
        Account:
          opportunity.account?.provider_account_name ||
          opportunity.account?.provider_account_id,
        Tags: opportunity?.tags
          ?.map((tag: Tag) => `${tag?.key}:${tag?.value}`)
          .join(" , "),
        "Current Monthly Price": money(opportunity.current_monthly_price),
        "Estimated Monthly Price": money(opportunity.estimated_monthly_price),
        "Monthly Savings": money(opportunity.monthly_savings),
      };
    }) ?? []
  );
};
