import { createAsyncThunk } from "@reduxjs/toolkit";
import { enqueueSnackbar } from "notistack";
import { getResourceProtectionResourcesThunk } from "./getResourceProtectionResourcesThunk";
import { removePreviewSelectedResources } from "../../schedulesSlice";
import { RootState } from "../../../store";
import { protectorResourceTypesSelector } from "../../selectors/resources-for-protector/protectorResourceTypesSelector";
import {
  resourceProtectThunk,
  ScheduleProtectResources,
} from "../resourceProtectThunk";

export const protectorResourceProtectThunk = createAsyncThunk(
  "schedule/protectorResourceProtectThunk",
  async (
    {
      resourcesToProtect,
      scheduleId,
      batchId,
    }: {
      scheduleId: string;
      batchId: string;
      resourcesToProtect: ScheduleProtectResources;
    },
    { dispatch, getState },
  ) => {
    const state = getState() as RootState;
    const response = await dispatch(resourceProtectThunk(resourcesToProtect));
    const protect = response?.payload;

    await dispatch(
      getResourceProtectionResourcesThunk({ scheduleId, batchId }),
    );

    const protectorResourcesResourceTypes = protectorResourceTypesSelector(
      state,
      scheduleId,
      batchId,
    );

    protectorResourcesResourceTypes?.forEach((type) => {
      dispatch(removePreviewSelectedResources(type));
    });

    if (protect !== null) {
      enqueueSnackbar(`Protection ${protect ? "enabled" : "disabled"}`, {
        variant: "snackbarAlert",
        AlertSnackBarProps: { severity: "success" },
      });
    }
  },
);
