import { RootState } from "../../../../store";
import { cloudChiprApi } from "../../../../../services/cloudchipr.api";

export const rightsizingRecommendationLoadingSelector = (
  state: RootState,
  recommendationId: string | null,
  accountId: string | null,
) => {
  const {
    getUsersMeAccountsByAccountIdRightSizingRecommendationsAndRecommendationId,
  } = cloudChiprApi.endpoints;

  if (!recommendationId || !accountId) {
    return false;
  }

  return getUsersMeAccountsByAccountIdRightSizingRecommendationsAndRecommendationId.select(
    { recommendationId, accountId },
  )(state).isLoading;
};
