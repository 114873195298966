import { createAsyncThunk } from "@reduxjs/toolkit";
import { enqueueSnackbar } from "notistack";
import { getAllReportsThunk } from "./getAllReportsThunk";
import { cloudChiprApi, Report } from "../../../services/cloudchipr.api";
import { getToggleReportStatusLoadingFixedCacheKey } from "../utils/constants/fixedCacheKeys";

interface ToggleReportStatusThunkArgs {
  status?: Report["status"];
  reportId: string;
}

export const toggleReportStatusThunk = createAsyncThunk(
  "reports/toggleReportStatus",
  async ({ reportId, status }: ToggleReportStatusThunkArgs, { dispatch }) => {
    const { patchUsersMeOrganisationsCurrentReportsByReportId } =
      cloudChiprApi.endpoints;

    try {
      const response = await dispatch(
        patchUsersMeOrganisationsCurrentReportsByReportId.initiate(
          {
            reportId,
            body: {
              status: status === "active" ? "paused" : "active",
            },
          },
          {
            fixedCacheKey: getToggleReportStatusLoadingFixedCacheKey(reportId),
          },
        ),
      );

      await dispatch(getAllReportsThunk());
      return response;
    } catch (e) {
      // @ts-expect-error todo: fix api spec
      const errMessage = e?.data?.message || "Something went wrong";
      enqueueSnackbar(errMessage, {
        variant: "snackbarAlert",
        AlertSnackBarProps: {
          severity: "error",
        },
      });
    }
  },
);
