import { FC, useCallback, MouseEvent } from "react";
import DashboardCustomizeOutlinedIcon from "@mui/icons-material/DashboardCustomizeOutlined";
import AddIcon from "@mui/icons-material/Add";
import { IconButton, PopoverOrigin } from "@mui/material";
import { useEffectOnceWhen } from "rooks";
import { useLocation, useNavigate } from "react-router-dom";
import { useFlag } from "@unleash/proxy-client-react";
import { DashboardV2NavigationAddActionMenu } from "./components/DashboardV2NavigationAddActionMenu";
import { DashboardV2Visibility } from "./components/visibility-types/DashboardV2Visibility";
import { NavItem } from "../nav-item/NavItem";

import { useAppAbility } from "../../../../services/permissions";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { navigationCollapsedSelector } from "../../../../store/common/selectors/navigation/navigationCollapsedSelector";
import { useMenuHook } from "../../../../utils/hooks/useMenu.hook";

import { getDashboardsVisibilityHierarchyThunk } from "../../../../store/dashboards/thunks/dashboard-hierarchy-visibility/getDashboardsVisibilityHierarchyThunk";

export const DashboardV2Navigation: FC = () => {
  const navigate = useNavigate();
  const { anchor, openMenu, closeMenu, open } = useMenuHook();

  const enableHierarchySeparatePages = useFlag("EnableHierarchySeparatePages");

  const dispatch = useAppDispatch();
  const { cannot, can } = useAppAbility();

  const location = useLocation();
  const selected = location.pathname.startsWith("/dashboards");

  const cannotCreateDashboard = cannot("create", "dashboard");
  const dashboardsAvailable = can("view", "dashboard");

  const navigationCollapsed = useAppSelector(navigationCollapsedSelector);

  const handleTextClick = useCallback(
    (_: MouseEvent<HTMLDivElement>) => {
      navigate("/dashboards");
    },
    [navigate],
  );

  useEffectOnceWhen(() => {
    dispatch(getDashboardsVisibilityHierarchyThunk());
  }, dashboardsAvailable);

  return (
    <NavItem
      primary="Dashboards"
      icon={DashboardCustomizeOutlinedIcon}
      selected={selected}
      secondaryAction={
        <IconButton
          size="small"
          disabled={cannotCreateDashboard}
          onClick={openMenu}
        >
          <AddIcon fontSize="inherit" />
        </IconButton>
      }
      onTextClick={enableHierarchySeparatePages ? handleTextClick : undefined}
      navCollapsed={navigationCollapsed}
    >
      <DashboardV2Visibility />

      <DashboardV2NavigationAddActionMenu
        open={open}
        onCloseMenu={closeMenu}
        anchorEl={anchor}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
      />
    </NavItem>
  );
};

const anchorOrigin: PopoverOrigin = {
  vertical: "top",
  horizontal: "right",
};

const transformOrigin: PopoverOrigin = {
  vertical: "top",
  horizontal: "right",
};
