import { GetQuickFilterTextParams } from "@ag-grid-community/core/dist/types/src/entities/colDef";
import { Schedule } from "../../../../../../../services/cloudchipr.api";
import { formatDate } from "../../../../../../../utils/helpers/date-time/datetime-format";

export const quickFilterModifiedByText = (
  params: GetQuickFilterTextParams<Schedule>,
) => {
  const details = params.data.modified_by;
  const date = params.data.modified_at;
  let target = `${details?.name} ${details?.email} ${formatDate(date ?? "", { type: "dateTime" })}`;

  if (target.includes("deleted")) {
    target = "deleted";
  }

  return target.toLowerCase();
};
