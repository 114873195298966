import { createAsyncThunk } from "@reduxjs/toolkit";
import { closeSnackbar, enqueueSnackbar, SnackbarKey } from "notistack";
import { getResourceExplorerViewByIdAndSetDataThunk } from "./getResourceExplorerViewByIdAndSetDataThunk";
import {
  cloudChiprApi,
  PatchUsersMeOrganisationsCurrentResourceExplorerViewsByResourceExplorerViewIdApiArg,
} from "../../../../services/cloudchipr.api";
import { RootState } from "../../../store";
import { resourceExplorerFilterTreeSelector } from "../../selectors/filter-tree/resourceExplorerFilterTreeSelector";
import { getResourceExplorerVisibilityHierarchyThunk } from "../resource-explorer-hierarchy-visibility/getResourceExplorerVisibilityHierarchyThunk";
import { resourceExplorerDataSelector } from "../../selectors/current-resource-explorer/data/resourceExplorerDataSelector";

interface UpdateResourceExplorerThunkArgs {
  viewId: string;
  name: string;
}

export const updateResourceExplorerThunk = createAsyncThunk(
  "resourceExplorer/updateResourceExplorer",
  async (
    { viewId, name }: UpdateResourceExplorerThunkArgs,
    { dispatch, getState },
  ) => {
    const state = getState() as RootState;
    const data = resourceExplorerDataSelector(state);

    const {
      patchUsersMeOrganisationsCurrentResourceExplorerViewsByResourceExplorerViewId,
    } = cloudChiprApi.endpoints;

    const filterTree = resourceExplorerFilterTreeSelector(state);

    let response;
    try {
      const payload: PatchUsersMeOrganisationsCurrentResourceExplorerViewsByResourceExplorerViewIdApiArg["body"] =
        { data, name, filter_tree: filterTree };

      response = await dispatch(
        patchUsersMeOrganisationsCurrentResourceExplorerViewsByResourceExplorerViewId.initiate(
          {
            resourceExplorerViewId: viewId,
            body: payload,
          },
          { fixedCacheKey: viewId },
        ),
      ).unwrap();

      if (response) {
        dispatch(getResourceExplorerViewByIdAndSetDataThunk(viewId));
        dispatch(getResourceExplorerVisibilityHierarchyThunk());
        const snackbarId: SnackbarKey = enqueueSnackbar(
          "View successfully updated.",
          {
            variant: "snackbarAlert",
            AlertSnackBarProps: {
              severity: "success",
              onClose: () => closeSnackbar(snackbarId),
            },
          },
        );
      }
    } catch (e) {
      // @ts-expect-error TODO: return to this after adding error type
      const errMessage = e?.data?.message || "Something went wrong";

      const snackbarId: SnackbarKey = enqueueSnackbar(errMessage, {
        variant: "snackbarAlert",
        AlertSnackBarProps: {
          severity: "error",
          onClose: () => closeSnackbar(snackbarId),
        },
      });
    }
    return response;
  },
);
