import moment from "moment";
import { commitmentsDetailsDrawerCoverageGroupingSelector } from "./commitmentsDetailsDrawerCoverageGroupingSelector";
import { RootState } from "../../../../store";
import { formatDate } from "../../../../../utils/helpers/date-time/datetime-format";

export const commitmentsDetailsDrawerCSVFileNameSelector = (
  state: RootState,
) => {
  const grouping = commitmentsDetailsDrawerCoverageGroupingSelector(state);

  if (grouping === "resource_type") {
    return `${formatDate(moment(), {
      type: "dateWithComma",
    })}_Grouped_By_Resource_type_CSV`;
  }

  if (grouping === "account") {
    return `${formatDate(moment(), {
      type: "dateWithComma",
    })}_Grouped_By_Account_CSV`;
  }

  if (grouping === "commitment") {
    return `${formatDate(moment(), {
      type: "dateWithComma",
    })}_Grouped_By_Commitments_CSV`;
  }

  return "";
};
