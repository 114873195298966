import { FC, Fragment } from "react";
import { Box } from "@mui/material";
import { useAppSelector } from "../../../../../../../../../../store/hooks";
import { WidgetNumeralView } from "../../../common/view-type/WidgetNumeralView";
import { WidgetNumeralViewItem } from "../../../common/view-type/WidgetNumeralViewItem";
import { getCostBreakdownNumeralItemSecondaryLabel } from "../../../../../../widgets/cost-breakdown/components/chart/numeral/utils/helpers/getCostBreakdownNumeralItemSecondaryLabel";
import { costBreakdownSetupPropertyByKeySelector } from "../../../../../../../../../../store/dashboards/selectors/setups/cost-breakdown/costBreakdownSetupPropertyByKeySelector";
import { costBreakdownSetupVisualizationPropertyByKeySelector } from "../../../../../../../../../../store/dashboards/selectors/setups/cost-breakdown/costBreakdownSetupVisualizationPropertyByKeySelector";
import { CostBreakdownWidgetSetupContentCosts } from "../CostBreakdownWidgetSetupContentCosts";
import { costBreakdownSetupHasAggregationSelector } from "../../../../../../../../../../store/dashboards/selectors/setups/cost-breakdown/costBreakdownSetupHasAggregationSelector";
import { costBreakdownWidgetSetupLoadingSelector } from "../../../../../../../../../../store/dashboards/selectors/widgets/cost-breakdown/setup/costBreakdownWidgetSetupLoadingSelector";
import { costBreakdownWidgetSetupNoChartViewDataSelector } from "../../../../../../../../../../store/dashboards/selectors/widgets/cost-breakdown/setup/costBreakdownWidgetSetupNoChartViewDataSelector";

export const CostBreakdownWidgetSetupNumeralView: FC = () => {
  const data = useAppSelector(costBreakdownWidgetSetupNoChartViewDataSelector);
  const showTrend = useAppSelector(
    costBreakdownSetupVisualizationPropertyByKeySelector("trend"),
  );
  const showForecastedCost = useAppSelector(
    costBreakdownSetupVisualizationPropertyByKeySelector("forecasted_cost"),
  );
  const forecastOption = useAppSelector(
    costBreakdownSetupPropertyByKeySelector("forecastOption"),
  );

  const grouping = useAppSelector(
    costBreakdownSetupPropertyByKeySelector("grouping"),
  );
  const hasAggregations = useAppSelector(
    costBreakdownSetupHasAggregationSelector,
  );

  const loading = useAppSelector(costBreakdownWidgetSetupLoadingSelector);

  return (
    <Fragment>
      {hasAggregations && (
        <Box px={2} pt={2}>
          <CostBreakdownWidgetSetupContentCosts />
        </Box>
      )}

      <WidgetNumeralView
        loading={loading}
        skeletonCount={data?.length}
        width={itemWidth}
      >
        {data?.map((item) => {
          const providers = item?.multiProviders ?? [item.cloud_provider];

          const secondaryLabel = getCostBreakdownNumeralItemSecondaryLabel({
            monthlyCost: item?.monthly_forecast?.cost,
            quarterlyCost: item?.quarterly_forecast?.cost,
            grouping,
            forecastOption,
          });

          return (
            <WidgetNumeralViewItem
              key={item.cloud_provider + item.field}
              providers={item.cloud_provider ? providers : []}
              label={item.field}
              cost={item.total_cost}
              trend={showTrend ? item.trend : null}
              itemsCount={data.length}
              secondaryLabel={showForecastedCost ? secondaryLabel : undefined}
              width={itemWidth}
            />
          );
        })}
      </WidgetNumeralView>
    </Fragment>
  );
};

const itemWidth = "20%";
