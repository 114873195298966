import { FC } from "react";
import { Link, Typography } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";

interface WidgetWithREViewDisabledActionProps {
  viewId?: string;
  disabled?: boolean;
  available: boolean;
}
export const WidgetWithREViewDisabledAction: FC<
  WidgetWithREViewDisabledActionProps
> = ({ viewId, disabled, available }) => {
  if (disabled) {
    return (
      <Typography
        variant="caption"
        color="white"
        align="center"
        display="block"
      >
        Coming soon.
      </Typography>
    );
  }

  if (!available && viewId) {
    return (
      <Typography
        variant="caption"
        color="white"
        align="center"
        component="div"
      >
        Please{" "}
        {
          <Link
            to={`/resource-explorer/${viewId}`}
            underline="always"
            component={RouterLink}
            sx={{ color: "info.main" }}
          >
            enable
          </Link>
        }{" "}
        billing exports, to see your billing information. Once enabled, you can
        create widget.
      </Typography>
    );
  }

  return null;
};
