import { Typography } from "@mui/material";
import { ColumnSetupType } from "../../../../../../../../../../../storybook/data-grid/utils/types/types";
import { ReservationsCoverageDataWithId } from "../../../../../../../../common/utils/types";
import { money } from "../../../../../../../../../../../utils/numeral/money";

export const netSavingsColumn: ColumnSetupType<ReservationsCoverageDataWithId> =
  {
    accessorKey: "net_savings",
    header: () => (
      <Typography
        align="right"
        variant="body2"
        fontWeight="medium"
        width="100%"
      >
        Net Savings
      </Typography>
    ),
    cell: ({ row }) => (
      <Typography align="right" pr={4} variant="body2">
        {money(row.original.net_savings)}
      </Typography>
    ),
  };
