import { FC, useCallback, useMemo } from "react";
import { Button, Stack, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { ThresholdItem } from "./ThresholdItem";
import { Threshold } from "../../../../../../../../../../services/cloudchipr.api";

interface ThresholdsSectionProps {
  thresholds: Threshold[];
  onThresholdChange(value: Threshold[]): void;
}

export const ThresholdsSection: FC<ThresholdsSectionProps> = ({
  thresholds,
  onThresholdChange,
}) => {
  const thresholdData = useMemo(() => {
    return thresholds.map((threshold, index) => ({ ...threshold, id: index }));
  }, [thresholds]);

  const incompleteThresholdExist = useMemo(() => {
    return thresholds.some(
      (threshold) => !threshold.percent || !threshold.trigger,
    );
  }, [thresholds]);

  const thresholdChangeHandler = useCallback(
    (threshold: Threshold, index: number) => {
      onThresholdChange(
        thresholds.map((th, i) => {
          if (i === index) {
            return threshold;
          }

          return th;
        }),
      );
    },
    [onThresholdChange, thresholds],
  );

  const addThresholdHandler = useCallback(() => {
    onThresholdChange([
      ...thresholds,
      {
        percent: 0,
        trigger: "actual",
      },
    ]);
  }, [onThresholdChange, thresholds]);

  const removeThresholdHandler = useCallback(
    (index: number) => {
      onThresholdChange(thresholds.filter((_, i) => i !== index));
    },
    [onThresholdChange, thresholds],
  );

  return (
    <Stack spacing={2} alignItems="start">
      <Typography variant="body1" fontWeight="medium">
        Threshold rules
      </Typography>

      {thresholdData?.map((threshold, index) => {
        return (
          <ThresholdItem
            index={index}
            key={threshold.id}
            threshold={threshold}
            onChange={thresholdChangeHandler}
            onRemove={removeThresholdHandler}
            removable={thresholdData.length > 1}
          />
        );
      })}

      <Button
        variant="text"
        startIcon={<AddIcon />}
        onClick={addThresholdHandler}
        disabled={incompleteThresholdExist}
      >
        Add Threshold
      </Button>
    </Stack>
  );
};
