import { scheduleResourcesSelector } from "./scheduleResourcesSelector";
import { ResourceType } from "../../../../../services/cloudchipr.api";
import { RootState } from "../../../../store";
import { ResourceDataType } from "../../../../live-usage-mgmt/utils/types/types";

export const scheduleResourcesDataSelector = (
  state: RootState,
  accountIds: string[],
  resourceType: ResourceType,
  scheduleId: string,
): ResourceDataType[] | null => {
  const response = scheduleResourcesSelector(state, resourceType, scheduleId);

  const resources = response?.data?.resources as unknown as ResourceDataType[];

  if (!resources) {
    return null;
  }

  return resources;
};
