import { ColDef } from "@ag-grid-community/core/dist/types/src/entities/colDef";

export const statusComparatorFn: ColDef["comparator"] = (
  _,
  __,
  nodeA,
  nodeB,
) => {
  const current = nodeA.data?.status?.name ?? "";
  const next = nodeB.data?.status?.name ?? "";

  return current.localeCompare(next);
};
