import { FC } from "react";
import { FormControl, Grid, InputLabel, MenuItem, Select } from "@mui/material";
import { FormikHandlers, FormikHelpers } from "formik";
import { useTimeoutWhen } from "rooks";
import {
  statisticsSelectDefaultOptions,
  statisticsSelectOptionsByFilterType,
} from "./utils/constants";
import {
  FilterItem,
  FilterKeyType,
} from "../../../../../../../services/cloudchipr.api";

interface StatisticsSelectProps {
  statisticsType?: string;
  error?: string;
  onChange: FormikHandlers["handleChange"];
  setFieldValue: FormikHelpers<FilterItem>["setFieldValue"];
  filterType: FilterKeyType;
}

export const StatisticsSelect: FC<StatisticsSelectProps> = ({
  statisticsType,
  filterType,
  onChange,
  setFieldValue,
  error,
}) => {
  const options =
    statisticsSelectOptionsByFilterType[filterType] ??
    statisticsSelectDefaultOptions;

  useTimeoutWhen(
    () => {
      // TODO: timeOut can be changed removed after filters errors issue fix ( we are showing unnecessary error on all filters fields on mount, that should be changed, and error should be shown after user change )
      setFieldValue("metric.statistics_type", options.at(0));
    },
    100,
    options.length === 1 && !statisticsType,
  );

  return (
    <Grid item md={3} flexShrink={0} flexGrow={0}>
      <FormControl fullWidth size="xsmall">
        <InputLabel id="statistics-type">Statistics</InputLabel>
        <Select
          name="metric.statistics_type"
          labelId="statistics-type"
          value={statisticsType}
          error={!!error}
          label="Statistics"
          onChange={onChange}
          MenuProps={{
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left",
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "left",
            },
          }}
        >
          {options.map((item) => {
            return (
              <MenuItem key={item} value={item}>
                {item}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </Grid>
  );
};
