import { RootState } from "../../../../store";
import { dimensionIdSelector } from "../../slice-data/dimensionIdSelector";
import { dimensionByIdSelector } from "../../dimensions-data/dimensionByIdSelector";
import { isDeepEqual } from "../../../../../utils/is-deep-equal";
import { dimensionNameSelector } from "../../slice-data/dimensionNameSelector";
import { dimensionCategoriesSelector } from "../../slice-data/dimensionCategoriesSelector";

export const anyChangesExistOnDimensionEditSelector = (state: RootState) => {
  const dimensionId = dimensionIdSelector(state);
  const dimensionName = dimensionNameSelector(state);

  const originalDimension = dimensionByIdSelector(state, dimensionId ?? "");
  const categories = dimensionCategoriesSelector(state);

  if (!originalDimension || !categories) {
    return true;
  }

  if (originalDimension.name !== dimensionName) {
    return true;
  }

  return !isDeepEqual(originalDimension.categories, Object.values(categories));
};
