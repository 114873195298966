import { FC } from "react";
import { TableBody, TableCell, TableRow } from "@mui/material";
import { useAppSelector } from "../../../../../../../../store/hooks";
import { overviewCoveragesSelector } from "../../../../../../../../store/commitments/selectors/overview/overviewCoveragesSelector";
import { commitmentsResourceTypeNames } from "../../../../../common/utils/constants/labels";
import { money } from "../../../../../../../../utils/numeral/money";
import { CommitmentsProgressBar } from "../../../../../common/components/CommitmentsProgressBar";
import { TypographyWithTooltip } from "../../../../../../../common/TypographyWithTooltip";

export const CommitmentsOverviewCoverageTableBody: FC = () => {
  const coverages = useAppSelector(overviewCoveragesSelector);

  return (
    <TableBody sx={{ pt: 1 }}>
      {coverages?.map((item) => {
        return (
          <TableRow
            key={item.resource_type + item.uncovered_usage + item.coverage}
          >
            <TableCell sx={{ ...tableCellSx }}>
              <TypographyWithTooltip
                title={commitmentsResourceTypeNames?.[item.resource_type]}
                variant="body2"
                fontWeight="bold"
              />
            </TableCell>
            <TableCell sx={{ ...tableCellSx }}>
              <CommitmentsProgressBar
                value={item.coverage}
                overrideColor="info.light"
                width="90%"
              />
            </TableCell>
            <TableCell sx={{ ...tableCellSx }}>
              <TypographyWithTooltip
                variant="body2"
                fontWeight="medium"
                textAlign="end"
                title={
                  item.uncovered_usage === null
                    ? "N/A"
                    : money(item.uncovered_usage)
                }
              />
            </TableCell>
          </TableRow>
        );
      })}
    </TableBody>
  );
};

const tableCellSx = {
  py: 1,
  borderBottom: "none",
};
