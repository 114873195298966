import { commitmentsDetailsDrawerTargetTypeSelector } from "../../../common/details-drawer/commitmentsDetailsDrawerTargetTypeSelector";
import { savingsPlanSelectedCoverageDataSelector } from "../resource-type/savingsPlanSelectedCoverageDataSelector";
import { GetUsersMeOrganisationsCurrentSavingsPlansRecommendationsApiArg } from "../../../../../../services/cloudchipr.api";
import { RootState } from "../../../../../store";
import { commitmentsCurrentOrgIdSelector } from "../../../common/commitmentsCurrentOrgIdSelector";

export const coverageRecommendationsPayloadSelector = (
  state: RootState,
): GetUsersMeOrganisationsCurrentSavingsPlansRecommendationsApiArg => {
  const orgId = commitmentsCurrentOrgIdSelector(state);
  const type = commitmentsDetailsDrawerTargetTypeSelector(state);

  if (!type) {
    return {};
  }

  const payloadData: GetUsersMeOrganisationsCurrentSavingsPlansRecommendationsApiArg =
    {
      providerOrganisationId: orgId,
    };

  if (type === "resource_type") {
    const coverageData = savingsPlanSelectedCoverageDataSelector(state);

    payloadData.resourceType = coverageData?.resource_type?.resource_type;
    payloadData.region = coverageData?.resource_type?.region ?? undefined;
    payloadData.instanceFamily =
      coverageData?.resource_type?.instance_family ?? undefined;
  }

  if (type === "account") {
    const coverageData = savingsPlanSelectedCoverageDataSelector(state);

    payloadData.accountId = coverageData?.account?.id;
  }

  return payloadData;
};
