import { v4 as uuid } from "uuid";
import { isTreeNodeFilterGroup } from "./isTreeNodeFilterGroup";
import { FilterTreeNode } from "../../../../../../services/cloudchipr.api";
import { FilterItemNodeWithId, FilterTreeNodeWithId } from "../types/common";

export const addIdsToFilterTreeItems = (
  treeNode: FilterTreeNode,
): FilterTreeNodeWithId => {
  if (!isTreeNodeFilterGroup(treeNode)) {
    return { ...treeNode, id: uuid() } as FilterItemNodeWithId;
  }

  return {
    ...treeNode,
    items: treeNode.items.map(addIdsToFilterTreeItems),
  };
};
