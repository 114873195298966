import { RootState } from "../../../../../store";
import { automationErrorsSelector } from "../automationErrorsSelector";

export const automationNotificationExistenceErrorSelector = (
  state: RootState,
) => {
  const errors = automationErrorsSelector(state);

  return errors.notifications;
};
