import { Box, Stack, Typography } from "@mui/material";
import { FC, ReactNode } from "react";
import { PermissionStepContentType } from "../../../common/connectors/utils/types/permissionStepContentType";

interface StepOptionProps {
  content: PermissionStepContentType;
  actions?: ReactNode;
}

export const StepOption: FC<StepOptionProps> = ({ content, actions }) => {
  return (
    <Box my={1}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography variant="subtitle1" fontWeight="medium" mb={1}>
          {content.title}
        </Typography>

        {actions}
      </Stack>
      {content.caption}
    </Box>
  );
};
