import { createDraftSafeSelector } from "@reduxjs/toolkit";
import moment from "moment";
import { resourceExplorerDataByViewIdSelector } from "./resourceExplorerDataByViewIdSelector";
import { ChartDataType } from "../../../../../storybook/charts/multi-type-chart/utils/types/types";
import { currentBudgetPeriodSelector } from "../data-selectors/currentBudgetPeriodSelector";
import { formatDate } from "../../../../../utils/helpers/date-time/datetime-format";
import { BudgetPeriod } from "../../../../../services/cloudchipr.api";
import { chartDataPointKey } from "../../../../../storybook/charts/multi-type-chart/utils/constants/constants";

export const budgetChartDataSelector = createDraftSafeSelector(
  [resourceExplorerDataByViewIdSelector, currentBudgetPeriodSelector],
  (resourceExplorerData, period): ChartDataType[] | null => {
    const data = resourceExplorerData?.data?.data;

    if (!data || !Array.isArray(data)) {
      return null;
    }

    const chartData = data?.reduce((acc, { date, items }) => {
      let dataKeyValue = date ?? "";
      if (period === "monthly") {
        dataKeyValue = formatDate(dataKeyValue, { type: "monthNameYear" });
      } else if (period === "daily") {
        dataKeyValue = formatDate(dataKeyValue, { type: "dayMonthName" });
      }

      acc.push({
        [chartDataPointKey]: dataKeyValue,
        Actual:
          items?.reduce(
            (sum, item) =>
              (item?.cost && !isNaN(+item?.cost) ? item.cost : 0) + sum,
            0,
          ) ?? 0,
      } as unknown as ChartDataType);

      return acc;
    }, [] as ChartDataType[]);

    if (period === "monthly" || period === "daily") {
      return chartData;
    }

    return groupChartDataByPeriod(chartData, period);
  },
);

const groupChartDataByPeriod = (
  chartData: ChartDataType[],
  period?: BudgetPeriod,
): ChartDataType[] => {
  const withChangedLabels = chartData.map((data) => {
    let dataKeyValue = `Q${moment(data[chartDataPointKey]).quarter()} ${moment(
      data[chartDataPointKey],
    ).get("year")}`;

    if (period === "annually") {
      dataKeyValue = `${moment(data[chartDataPointKey]).get("year")}`;
    }

    return { ...data, [chartDataPointKey]: dataKeyValue } as Record<
      string,
      number | string
    >;
  });

  const groupedByDate = withChangedLabels.reduce(
    (acc, dataItem) => {
      const date = dataItem[chartDataPointKey];

      const sum = acc[date] ? +acc[date] : 0;

      acc[date] = sum + +dataItem.Actual;

      return acc;
    },
    {} as Record<string, number>,
  );

  return Object.entries(groupedByDate).map(([key, value]) => {
    return {
      [chartDataPointKey]: key,
      Actual: value,
    } as unknown as ChartDataType;
  });
};
