export const filterUnits: Map<string, string> = new Map([
  ["network_in", "GiB"],
  ["network_out", "GiB"],
  ["cpu", "%"],
  ["read_usage", "%"],
  ["write_usage", "%"],
  ["size", "(Byte)"],
  ["memory_reservation", "GB"],
  ["storage", "GB"],
]);
