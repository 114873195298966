import { SvgIcon, SvgIconProps, useTheme, colors } from "@mui/material";

const AzVmSvgIcon = ({ primary }: SvgIconProps & { primary?: boolean }) => {
  const { palette } = useTheme();

  const color = primary ? palette.primary.main : palette.action.active;
  const colorLight = primary ? colors.indigo["300"] : palette.action.disabled;

  return (
    <SvgIcon>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_551_89701)">
          <path
            d="M23.0135 1.35938H0.613477C0.171649 1.35938 -0.186523 1.71755 -0.186523 2.15938V16.5594C-0.186523 17.0012 0.171649 17.3594 0.613477 17.3594H23.0135C23.4553 17.3594 23.8135 17.0012 23.8135 16.5594V2.15938C23.8135 1.71755 23.4553 1.35938 23.0135 1.35938Z"
            fill={colorLight}
          />
          <path
            d="M22.0398 2.69336H1.58648C1.34348 2.69336 1.14648 2.89035 1.14648 3.13336V15.5867C1.14648 15.8297 1.34348 16.0267 1.58648 16.0267H22.0398C22.2828 16.0267 22.4798 15.8297 22.4798 15.5867V3.13336C22.4798 2.89035 22.2828 2.69336 22.0398 2.69336Z"
            fill="white"
          />
          <path
            d="M15.8135 7.02734V11.6807L11.8135 14.0273V9.36068L15.8135 7.02734Z"
            fill={colorLight}
          />
          <path
            d="M15.8135 7.02669L11.8135 9.37336L7.81348 7.02669L11.8135 4.69336L15.8135 7.02669Z"
            fill={color}
            fillOpacity="0.08"
          />
          <path
            d="M11.8135 9.37401V14.0273L7.81348 11.6807V7.02734L11.8135 9.37401Z"
            fill={color}
            fillOpacity="0.5"
          />
          <path
            opacity="0.2"
            d="M7.81348 11.6794L11.8135 9.35938V14.026L7.81348 11.6794Z"
            fill={color}
            fillOpacity="0.08"
          />
          <path
            opacity="0.2"
            d="M15.8135 11.6794L11.8135 9.35938V14.026L15.8135 11.6794Z"
            fill={color}
            fillOpacity="0.5"
          />
          <path
            d="M16.6135 22.1994C14.2535 21.826 14.1602 20.106 14.1602 17.3594H9.4535C9.4535 20.106 9.36017 21.826 7.00017 22.1994C6.67254 22.2356 6.36996 22.392 6.15081 22.6382C5.93167 22.8844 5.8115 23.2031 5.8135 23.5327H17.8135C17.8152 23.201 17.6931 22.8806 17.4712 22.6341C17.2494 22.3875 16.9435 22.2325 16.6135 22.1994Z"
            fill={color}
          />
        </g>
        <defs>
          <clipPath id="clip0_551_89701">
            <rect width="24" height="24" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </SvgIcon>
  );
};

AzVmSvgIcon.muiName = "AzVmSvgIcon";

export default AzVmSvgIcon;
