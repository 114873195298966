import { RootState } from "../../../../../store";
import { cloudChiprApi } from "../../../../../../services/cloudchipr.api";
import { reservationsDetailsPayloadSelector } from "../reservationsDetailsPayloadSelector";

const selector =
  cloudChiprApi.endpoints.getUsersMeOrganisationsCurrentReservationsData.select;

export const reservationDetailsSelector = (state: RootState) => {
  const payload = reservationsDetailsPayloadSelector(state);

  if (!payload) {
    return;
  }

  return selector(payload)(state);
};
