import { createDraftSafeSelector } from "@reduxjs/toolkit";
import {
  amber,
  blue,
  blueGrey,
  brown,
  cyan,
  deepOrange,
  deepPurple,
  green,
  indigo,
  lightBlue,
  lightGreen,
  lime,
  orange,
  pink,
  purple,
  red,
  teal,
  yellow,
} from "@mui/material/colors";
import { currentBudgetThresholdsSelector } from "../data-selectors/currentBudgetThresholdsSelector";
import { currentBudgetAmountSelector } from "../data-selectors/currentBudgetAmountSelector";
import { getNumberByPercentage } from "../../../../../utils/numeral/getPercentage";
import { ReferenceLineType } from "../../../../../storybook/charts/multi-type-chart/utils/types/types";

export const chartReferenceLinesByThresholdsSelector = createDraftSafeSelector(
  [currentBudgetThresholdsSelector, currentBudgetAmountSelector],
  (thresholds, amount) => {
    const data: ReferenceLineType[] = [
      {
        y: amount,
        stroke: red[400],
        strokeWidth: 2,
        legend: { type: "line", label: "Budget", color: red[400] },
        uniqueId: "budget_reference_line",
      },
    ];

    thresholds?.forEach(({ percent }, index) => {
      if (percent > 0) {
        data.push({
          y: getNumberByPercentage(percent, amount),
          stroke: colors.at(index)?.[400] ?? "",
          strokeWidth: 2,
          strokeDasharray: 3,
          uniqueId: `${index}_reference_line`,
          legend: {
            type: "dashed",
            color: colors.at(index)?.[400] ?? "",
            label: `${percent}% threshold`,
          },
        });
      }
    });

    return data;
  },
);

const colors = [
  purple,
  deepPurple,
  cyan,
  lightGreen,
  indigo,
  brown,
  pink,
  teal,
  orange,
  lightBlue,
  yellow,
  cyan,
  green,
  deepOrange,
  amber,
  blueGrey,
  lime,
  red,
  blue,
];
