import { FC, MouseEvent, useCallback } from "react";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import { ChartBaseType } from "../../../../../../../../../services/cloudchipr.api";

interface WidgetSetupChartBaseSelectProps {
  chartBase: ChartBaseType;
  onChartChange: (chartBase: ChartBaseType) => void;
}

export const WidgetSetupChartBaseSelect: FC<
  WidgetSetupChartBaseSelectProps
> = ({ chartBase, onChartChange }) => {
  const handleChartBaseChange = useCallback(
    (event: MouseEvent<HTMLElement>) => {
      const target = event.target as HTMLInputElement;
      onChartChange(target.value as ChartBaseType);
    },
    [onChartChange],
  );

  return (
    <ToggleButtonGroup
      exclusive
      color="primary"
      size="small"
      value={chartBase}
      onChange={handleChartBaseChange}
    >
      <ToggleButton value="cost" sx={buttonStyles}>
        $
      </ToggleButton>
      <ToggleButton value="percent" sx={buttonStyles}>
        %
      </ToggleButton>
    </ToggleButtonGroup>
  );
};

const buttonStyles = {
  maxHeight: 40,
  width: 40,
  fontSize: 20,
  fontWeight: "bold",
};
