import { ResourceType } from "../../../../services/cloudchipr.api";
import { getResourceTypeName } from "../../../helpers/resources/getResourceTypeName";

export const resourceIdentifierLabels = new Map<ResourceType, string>([
  // AWS ________________________________________________________________________
  [
    "asg_ec2",
    `${getResourceTypeName("asg_ec2", { singular: true, type: "short" })} Id`,
  ],
  [
    "dax_cluster",
    `${getResourceTypeName("dax_cluster", { singular: true, type: "short" })} Id`,
  ],
  [
    "dynamo_db_table",
    `${getResourceTypeName("dynamo_db_table", { singular: true, type: "short" })} Id`,
  ],
  [
    "dynamo_db_backup",
    `${getResourceTypeName("dynamo_db_backup", { singular: true, type: "short" })} Id`,
  ],
  [
    "ebs",
    `${getResourceTypeName("ebs", { singular: true, type: "short" })} Id`,
  ],
  [
    "ebs_snapshot",
    `${getResourceTypeName("ebs_snapshot", { singular: true, type: "short" })} Id`,
  ],
  [
    "ec2",
    `${getResourceTypeName("ec2", { singular: true, type: "short" })} Id`,
  ],
  [
    "ecs",
    `${getResourceTypeName("ecs", { singular: true, type: "short" })} Id`,
  ],
  [
    "ecs_workload",
    `${getResourceTypeName("ecs_workload", { singular: true, type: "short" })} Id`,
  ],
  [
    "eip",
    `${getResourceTypeName("eip", { singular: true, type: "short" })} Id`,
  ],
  [
    "eks",
    `${getResourceTypeName("eks", { singular: true, type: "short" })} Id`,
  ],
  [
    "eks_ec2",
    `${getResourceTypeName("eks_ec2", { singular: true, type: "short" })} Id`,
  ],
  [
    "elb",
    `${getResourceTypeName("elb", { singular: true, type: "short" })} Id`,
  ],
  [
    "elc",
    `${getResourceTypeName("elc", { singular: true, type: "short" })} Id`,
  ],
  [
    "nat_gateway",
    `${getResourceTypeName("nat_gateway", { singular: true, type: "short" })} Id`,
  ],
  [
    "node_group",
    `${getResourceTypeName("node_group", { singular: true, type: "short" })} Id`,
  ],
  [
    "rds",
    `${getResourceTypeName("rds", { singular: true, type: "short" })} Id`,
  ],
  [
    "rds_snapshot",
    `${getResourceTypeName("rds_snapshot", { singular: true, type: "short" })} Id`,
  ],
  [
    "rds_snapshot_source",
    `${getResourceTypeName("rds_snapshot_source", { singular: true, type: "short" })} Id`,
  ],
  ["s3", `${getResourceTypeName("s3", { singular: true, type: "short" })} Id `],

  // GCP ________________________________________________________________________
  [
    "disk",
    `${getResourceTypeName("disk", { singular: true, type: "short" })} Id`,
  ],
  [
    "gke",
    `${getResourceTypeName("gke", { singular: true, type: "short" })} Id`,
  ],
  [
    "gke_vm",
    `${getResourceTypeName("gke_vm", { singular: true, type: "short" })} Id`,
  ],
  ["ip", `${getResourceTypeName("ip", { singular: true, type: "short" })} Id `],
  ["lb", `${getResourceTypeName("lb", { singular: true, type: "short" })} Id `],
  [
    "mig",
    `${getResourceTypeName("mig", { singular: true, type: "short" })} Id`,
  ],
  [
    "mig_vm",
    `${getResourceTypeName("mig_vm", { singular: true, type: "short" })} Id`,
  ],
  [
    "node_pool",
    `${getResourceTypeName("node_pool", { singular: true, type: "short" })} Id`,
  ],
  [
    "sql",
    `${getResourceTypeName("sql", { singular: true, type: "short" })} Id`,
  ],
  ["vm", `${getResourceTypeName("vm", { singular: true, type: "short" })} Id `],

  // Azure ________________________________________________________________________
  [
    "az_aks",
    `${getResourceTypeName("az_aks", { singular: true, type: "short" })} Id`,
  ],
  [
    "az_aks_vm",
    `${getResourceTypeName("az_aks_vm", { singular: true, type: "short" })} Id`,
  ],
  [
    "az_disk",
    `${getResourceTypeName("az_disk", { singular: true, type: "short" })} Id`,
  ],
  [
    "az_ip",
    `${getResourceTypeName("az_ip", { singular: true, type: "short" })} Id`,
  ],
  [
    "az_lb",
    `${getResourceTypeName("az_lb", { singular: true, type: "short" })} Id`,
  ],
  [
    "node_pool",
    `${getResourceTypeName("node_pool", { singular: true, type: "short" })} Id`,
  ],
  [
    "az_node_pool",
    `${getResourceTypeName("az_node_pool", { singular: true, type: "short" })} Id`,
  ],
  [
    "az_sql",
    `${getResourceTypeName("az_sql", { singular: true, type: "short" })} Id`,
  ],
  [
    "az_vm",
    `${getResourceTypeName("az_vm", { singular: true, type: "short" })} Id`,
  ],
  [
    "az_vmss",
    `${getResourceTypeName("az_vmss", { singular: true, type: "short" })} Id`,
  ],
  [
    "az_vmss_vm",
    `${getResourceTypeName("az_vmss_vm", { singular: true, type: "short" })} Id`,
  ],
]);
