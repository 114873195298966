import { commitmentsRecommendationsFiltersSelector } from "./commitmentsRecommendationsFiltersSelector";
import { RootState } from "../../../../store";
import { LookbackPeriod } from "../../../../../services/cloudchipr.api";

export const commitmentsRecommendationsLockBackPeriodSelector = (
  state: RootState,
): LookbackPeriod[] | undefined => {
  const filters = commitmentsRecommendationsFiltersSelector(state);

  return filters.look_back_period;
};
