import { Components, Theme } from "@mui/material";

export const muiTabs: Components<Theme>["MuiTabs"] = {
  styleOverrides: {
    root: ({ ownerState, theme }) => {
      if (ownerState.orientation === "vertical") {
        return {
          "& .MuiTab-root": {
            fontSize: 18,
            fontWeight: "normal",
            textTransform: "capitalize",
            alignItems: "start",
            "&.Mui-selected": {
              border: "none",
              borderRadius: 6,
              backgroundColor: theme.palette.primary.light,
            },
          },
        };
      } else if (ownerState.variant === "scrollable") {
        return {
          "& .MuiTabs-scroller": {
            padding: "0 8px",
            display: "flex",
            "& > .MuiTabs-flexContainer": {
              flexGrow: 1,
              justifyContent: "space-evenly",
              "& .MuiTab-root": {
                flexGrow: 1,
                maxWidth: "inherit",
              },
            },
          },
        };
      }
    },
  },
};
