import { createDraftSafeSelector } from "@reduxjs/toolkit";
import { organisationFilterSetByFilterSetIdSelector } from "./organisationFilterSetByFilterSetIdSelector";

export const organisationFilterSetNameByFilterSetIdSelector =
  createDraftSafeSelector(
    [organisationFilterSetByFilterSetIdSelector],
    (data): string | undefined => {
      return data?.name;
    },
  );
