import { liveUsageMgmtResourceTypeDataSelector } from "../liveUsageMgmtResourceTypeDataSelector";
import { liveUsageMgmtResourceViewIsWithoutFiltersSelector } from "../liveUsageMgmtResourceViewIsWithoutFiltersSelector";
import { RootState } from "../../../../store";
import { ResourceType } from "../../../../../services/cloudchipr.api";
import { liveUsageMgmtFilterTemplatesEnabledSelector } from "../../store-selectors/liveUsageMgmtFilterTemplatesEnabledSelector";
import { getResourceTypeDataWithoutFiltersFixedCacheKey } from "../../../thunks/resources/getLiveUsageMgmtResourcesWithOutFiltersByProviderThunk";

export const liveUsageMgmtResourceTypeDataTotalCountSelector = (
  state: RootState,
  resourceType: ResourceType,
) => {
  const withoutFilters = liveUsageMgmtResourceViewIsWithoutFiltersSelector(
    state,
    resourceType,
  );

  const filterTemplatesEnabled =
    liveUsageMgmtFilterTemplatesEnabledSelector(state);

  const fixedCacheKey =
    withoutFilters || !filterTemplatesEnabled
      ? getResourceTypeDataWithoutFiltersFixedCacheKey(resourceType)
      : resourceType;

  return liveUsageMgmtResourceTypeDataSelector(state, fixedCacheKey)
    ?.total_resources;
};
