import { WeekDaysType } from "../../../../../store/reports/utils/types/types";

export const weekDaysToNumber: Record<WeekDaysType, number> = {
  monday: 1,
  tuesday: 2,
  wednesday: 3,
  thursday: 4,
  friday: 5,
  saturday: 6,
  sunday: 0,
};

export const numbersToWeekDays: Record<number, WeekDaysType> = {
  1: "monday",
  2: "tuesday",
  3: "wednesday",
  4: "thursday",
  5: "friday",
  6: "saturday",
  0: "sunday",
};
