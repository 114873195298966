import { FC, ReactNode } from "react";
import { CircularProgress, Stack, Typography } from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { UnusedSinceCell } from "./UnusedSinceCell";
import {
  ResourceState,
  UnusedSince,
} from "../../../../../../../../../../services/cloudchipr.api";

interface ResourceStateProps {
  state?: ResourceState;
  unusedSince?: UnusedSince;
}

export const ResourceStateCell: FC<ResourceStateProps> = ({
  state,
  unusedSince,
}) => {
  return (
    <Stack direction="row" alignItems="center" spacing={1}>
      {(state && availableOptions.get(state)?.icon) ?? null}
      <Stack>
        <Typography variant="body2">
          {(state && availableOptions.get(state)?.title) ??
            state?.toLocaleUpperCase()}
        </Typography>
        {!unusedSince?.is_used && unusedSince?.timestamp && (
          <UnusedSinceCell data={unusedSince} variant="caption" />
        )}
      </Stack>
    </Stack>
  );
};

const availableOptions = new Map<
  ResourceState,
  {
    title: string;
    icon: ReactNode;
  }
>([
  [
    "running",
    {
      title: "Running",
      icon: <CheckCircleOutlineIcon color="success" fontSize="small" />,
    },
  ],
  [
    "stopping",
    {
      title: "Stopping",
      icon: <CircularProgress color="inherit" size={18} />,
    },
  ],
  [
    "stopped",
    {
      title: "Stopped",
      icon: <RemoveCircleOutlineIcon color="action" fontSize="small" />,
    },
  ],
  [
    "stopped_allocated",
    {
      title: "Stopped (deallocated)",
      icon: <RemoveCircleOutlineIcon color="action" fontSize="small" />,
    },
  ],
]);
