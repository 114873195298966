import { FC, Fragment, memo } from "react";
import { FormikHandlers } from "formik";
import {
  AutocompleteFilterBody,
  AutocompleteFilterBodyProps,
} from "../AutocompleteFilterBody";
import {
  FilterItem,
  Operator,
  useGetUsersMeDynamoDbTableStatusesQuery,
} from "../../../../../../../../services/cloudchipr.api";
import { FilterItemOperatorType } from "../../../FilterItemOperatorType";
import { getCreatedByFormattedValue } from "../../../../../../utils/helpers/getCreatedByFormattedValue";

interface StatusFilterProps extends AutocompleteFilterBodyProps {
  filter: FilterItem;
  operators?: Operator[];
  onChange: FormikHandlers["handleChange"];
}

export const StatusFilter: FC<StatusFilterProps> = memo(
  ({ operators, filter, onChange, ...props }) => {
    const { data, isLoading } = useGetUsersMeDynamoDbTableStatusesQuery();

    return (
      <Fragment>
        <FilterItemOperatorType
          onChange={onChange}
          options={operators}
          value={filter.operator || "in"}
        />

        <AutocompleteFilterBody
          {...props}
          options={data ?? []}
          isLoading={isLoading}
          label="Status"
          selectedValue={Array.isArray(filter?.value) ? filter.value : []}
          formatSelectedLabel={getCreatedByFormattedValue}
        />
      </Fragment>
    );
  },
);
