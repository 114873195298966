import { FC } from "react";
import { Stack, Typography } from "@mui/material";
import ProcessingDataImage from "../../../../../../../assets/images/processing-data.png";

export const AwsInProgressState: FC = () => {
  return (
    <Stack justifyContent="center" alignItems="center" height="100%" gap={2}>
      <Typography color="text.primary" variant="h5" fontWeight="medium">
        Generating Cost Usage Report (CUR)
      </Typography>

      <Typography color="text.secondary" variant="subtitle1">
        This process can take a up to 24 hours.
      </Typography>

      <img src={ProcessingDataImage} alt="ProcessingDataImage" />
    </Stack>
  );
};
