import { SvgIcon, SvgIconProps, useTheme, colors } from "@mui/material";

const IpSvgIcon = ({ primary }: SvgIconProps & { primary?: boolean }) => {
  const { palette } = useTheme();

  const color = primary ? palette.primary.main : palette.action.active;
  const colorLight = primary ? colors.indigo["300"] : palette.action.disabled;

  return (
    <SvgIcon>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.93994 13.7L11.5299 20.22L12.6199 19.07L6.29994 12.93L4.93994 13.7Z"
          fill={colorLight}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.57 4.06L11.48 5.22L17.69 11.44L19.47 11.06L12.57 4.06Z"
          fill={colorLight}
        />
        <path d="M12.6299 5.13H11.3799V10.13H12.6299V5.13Z" fill={color} />
        <path d="M12.6299 14.5H11.3799V19.5H12.6299V14.5Z" fill={color} />
        <path
          d="M12.0001 6.38C13.0384 6.38 13.8801 5.53829 13.8801 4.5C13.8801 3.4617 13.0384 2.62 12.0001 2.62C10.9618 2.62 10.1201 3.4617 10.1201 4.5C10.1201 5.53829 10.9618 6.38 12.0001 6.38Z"
          fill={color}
          fillOpacity="0.5"
        />
        <path
          d="M12.0001 21.38C13.0384 21.38 13.8801 20.5383 13.8801 19.5C13.8801 18.4617 13.0384 17.62 12.0001 17.62C10.9618 17.62 10.1201 18.4617 10.1201 19.5C10.1201 20.5383 10.9618 21.38 12.0001 21.38Z"
          fill={color}
          fillOpacity="0.5"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.65991 11.38H17.7299L21.3699 13.25H6.29991L2.65991 11.38Z"
          fill={colorLight}
        />
        <path
          d="M4.5 14.81C5.88071 14.81 7 13.6907 7 12.31C7 10.9293 5.88071 9.81 4.5 9.81C3.11929 9.81 2 10.9293 2 12.31C2 13.6907 3.11929 14.81 4.5 14.81Z"
          fill={color}
          fillOpacity="0.5"
        />
        <path
          d="M19.5 14.81C20.8807 14.81 22 13.6907 22 12.31C22 10.9293 20.8807 9.81 19.5 9.81C18.1193 9.81 17 10.9293 17 12.31C17 13.6907 18.1193 14.81 19.5 14.81Z"
          fill={color}
          fillOpacity="0.5"
        />
      </svg>
    </SvgIcon>
  );
};

IpSvgIcon.muiName = "IpSvgIcon";

export default IpSvgIcon;
