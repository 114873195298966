import { createAsyncThunk } from "@reduxjs/toolkit";
import { enqueueSnackbar } from "notistack";
import { generateGroupedRolePermissions } from "./utils/generateGroupedRolePermissions";
import { applyRolePermissionsToPermissionsList } from "./utils/applyRolePermissionsToPermissionsList";
import { cloudChiprApi } from "../../../../services/cloudchipr.api";
import { setUserRoleData } from "../../userSlice";
import { userRolePermissionsSelector } from "../../selectors/roles/userRolePermissionsSelector";
import { RootState } from "../../../store";

export const getUserRoleByIdThunk = createAsyncThunk(
  "roles/getUserRoleById",
  async (id: string, { dispatch, getState }) => {
    const state = getState() as RootState;
    const permissionsList = userRolePermissionsSelector(state);
    try {
      const response = await dispatch(
        cloudChiprApi.endpoints.getUsersMeOrganisationsCurrentRolesByRoleId.initiate(
          { roleId: id },
          {
            forceRefetch: true,
          },
        ),
      ).unwrap();

      if (!response) {
        return;
      }
      const appliedData = applyRolePermissionsToPermissionsList(
        permissionsList ?? [],
        response.permissions ?? [],
      );
      dispatch(
        setUserRoleData({
          name: response.name,
          description: response.description ?? "",
          permissions: generateGroupedRolePermissions(appliedData),
        }),
      );
    } catch (e) {
      // @ts-expect-error // todo: api fix
      enqueueSnackbar(e?.data?.message ?? "Something went wrong", {
        variant: "snackbarAlert",
        AlertSnackBarProps: {
          severity: "error",
        },
      });
    }
  },
);
