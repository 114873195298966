import { RootState } from "../../../../store";
import { resourceExplorerViewDataByIdSelector } from "../../resource-explorer-by-id/resourceExplorerViewDataByIdSelector";
import { resourceExplorerDatesSelector } from "../data/resourceExplorerDatesSelector";
import { isDeepEqual } from "../../../../../utils/is-deep-equal";

export const resourceExplorerDatesChangedSelector = (
  state: RootState,
  viewId: string,
) => {
  const dates = resourceExplorerDatesSelector(state);

  const initialResourceExplorer = resourceExplorerViewDataByIdSelector(
    state,
    viewId,
  );

  const initialDates = initialResourceExplorer?.data?.dates;

  if (!dates || !initialDates) {
    return true;
  }

  return !isDeepEqual(dates, initialDates);
};
