import { FC } from "react";
import { IconButton, Stack } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { ResourceExplorerBillingStatusTitle } from "./ResourceExplorerBillingStatusTitle";
import { billingAccountSingleStatusSelector } from "../../../../../../../../../store/common/selectors/billing-status/billingAccountSingleStatusSelector";
import { useAppSelector } from "../../../../../../../../../store/hooks";

interface ResourceExplorerBillingStatusesDrawerHeaderProps {
  onClose(): void;
}

export const ResourceExplorerBillingStatusesDrawerHeader: FC<
  ResourceExplorerBillingStatusesDrawerHeaderProps
> = ({ onClose }) => {
  const singleStatus = useAppSelector(billingAccountSingleStatusSelector);

  return (
    <Stack
      px={3}
      py={2}
      direction="row"
      alignItems="center"
      justifyContent={singleStatus ? "space-between" : "flex-end"}
    >
      {singleStatus && (
        <ResourceExplorerBillingStatusTitle
          type={singleStatus.type}
          count={singleStatus.count}
        />
      )}

      <IconButton onClick={onClose} size="small">
        <CloseIcon fontSize="small" />
      </IconButton>
    </Stack>
  );
};
