import { RootState } from "../../../store";
import { AlertTabType } from "../../utils/types/types";
import { isCostAnomalyAlertChangedSelector } from "../cost-anomaly-alerts/isCostAnomalyAlertChangedSelector";
import { isUtilizationAlertChangedSelector } from "../utilization-alerts/isUtilizationAlertChangedSelector";

export const isAlertDataChangedSelector = (
  state: RootState,
  alertType: AlertTabType,
) => {
  return alertType === "costAnomaly"
    ? isCostAnomalyAlertChangedSelector(state)
    : isUtilizationAlertChangedSelector(state);
};
