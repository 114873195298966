import { ProviderAccessType } from "../../../../../../services/cloudchipr.api";

export const accessTypeNames: Record<
  ProviderAccessType,
  Record<string, string>
> = {
  read: {
    long: "Read Only",
    short: "R/O",
    lowercase: "read-only",
  },
  read_write: {
    long: "Read / Write",
    short: "R/W",
    lowercase: "read-write",
  },
};
