import {
  generateAutomationPayloadData,
  getAutomationSteps,
  isFilterAdded,
} from "../../../common/utils/helpers/common";
import {
  OffHoursSchedule,
  PatchUsersMeOffHoursSchedulesByScheduleIdApiArg,
  PostUsersMeOffHoursSchedulesApiArg,
} from "../../../../../../services/cloudchipr.api";
import { getTimeZoneObject } from "../../../../../../utils/helpers/date-time/timeZones";
import { OffHoursFormData } from "../types/types";
import { AutomationsStepsType } from "../../../common/utils/types/types";
import { getStartsOnDate } from "../../../common/utils/constants/common";

export const generateOffHoursSteps = (
  offHours: OffHoursFormData,
): AutomationsStepsType => {
  const isFirstCompleted = !!(
    offHours.name &&
    offHours.account_ids.length &&
    offHours.provider &&
    offHours.regions.length
  );

  const isSecondCompleted = !!(
    isFirstCompleted &&
    offHours.filter &&
    isFilterAdded(offHours.filter)
  );

  const isThirdCompleted =
    isSecondCompleted &&
    !!(offHours.action === "notify"
      ? offHours?.notifications?.length || offHours?.emails?.to?.length
      : true);

  const isFourthCompleted = !!(
    isThirdCompleted &&
    ((offHours.start_date_time && offHours.end_date_time) ||
      (offHours.start_cron && offHours.stop_cron))
  );

  return getAutomationSteps(
    isFirstCompleted,
    isSecondCompleted,
    isThirdCompleted,
    isFourthCompleted,
    { third: "Set Notification" },
  );
};

export const getOffHoursCreatePayloadData = (
  schedule: OffHoursFormData,
): PostUsersMeOffHoursSchedulesApiArg => {
  const data = generateAutomationPayloadData(schedule);

  return {
    body: {
      ...data.body,
      action: schedule.action,
      start_cron: schedule.start_cron!,
      stop_cron: schedule.stop_cron!,
    },
  };
};

export const getOffHoursUpdatePayloadData = (
  schedule: OffHoursFormData,
): PatchUsersMeOffHoursSchedulesByScheduleIdApiArg => {
  const data = generateAutomationPayloadData(schedule);

  const { start_date_time, time_zone } = schedule;
  const startDate = start_date_time || getStartsOnDate(time_zone, true);

  return {
    scheduleId: schedule.id,
    body: {
      ...data.body,
      start_date_time: startDate,
      action: schedule.action,
      start_cron: schedule.start_cron!,
      stop_cron: schedule.stop_cron!,
      status: schedule.status,
    },
  };
};

export const generateOffHoursEditingData = (
  schedule: OffHoursSchedule,
): OffHoursFormData => {
  const { time_zone, accounts, ...other } = schedule;

  const timeZone = time_zone || getTimeZoneObject().tzCode;

  return {
    ...other,
    emails: other.emails ?? null,
    account_ids: accounts.map(({ id }) => id),
    provider: accounts?.[0]?.provider,
    time_zone: timeZone,
  };
};

export const generateOffHoursUpdatingData = (
  schedule: OffHoursSchedule,
): OffHoursFormData => {
  const { accounts, ...other } = schedule;

  return {
    ...other,
    account_ids: accounts.map(({ id }) => id),
    provider: accounts?.[0]?.provider,
  };
};
