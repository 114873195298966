import { FC } from "react";
import { CSVLink } from "react-csv";
import { IconButton, Tooltip } from "@mui/material";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import { utilizationAndCoverageDetailsCSVDataSelector } from "../../../../../../../store/commitments/selectors/coverage-and-utilization/details-drawer/data/utilizationAndCoverageDetailsCSVDataSelector";
import { commitmentsDetailsDrawerCSVFileNameSelector } from "../../../../../../../store/commitments/selectors/common/details-drawer/commitmentsDetailsDrawerCSVFileNameSelector";
import { useAppSelector } from "../../../../../../../store/hooks";
import { reservationDetailsCSVDataSelector } from "../../../../../../../store/commitments/selectors/reservations/details-drawer/data/reservationDetailsCSVDataSelector";
import { commitmentsTypeTabSelector } from "../../../../../../../store/commitments/selectors/common/commitmentsTypeTabSelector";

export const CommitmentsDrawerCSVDownloader: FC = () => {
  const fileName = useAppSelector(commitmentsDetailsDrawerCSVFileNameSelector);
  const activeTab = useAppSelector(commitmentsTypeTabSelector);
  const utilizationAndCoverageData = useAppSelector(
    utilizationAndCoverageDetailsCSVDataSelector,
  );
  const reservationData = useAppSelector(reservationDetailsCSVDataSelector);
  const data =
    activeTab === "reservation" ? reservationData : utilizationAndCoverageData;

  if (!data) {
    return null;
  }

  return (
    <CSVLink filename={fileName} data={data}>
      <Tooltip title="Export CSV" placement="top">
        <IconButton size="medium">
          <DownloadOutlinedIcon fontSize="medium" />
        </IconButton>
      </Tooltip>
    </CSVLink>
  );
};
