import { FC, Fragment } from "react";
import { WorkflowInterval } from "../WorkflowInterval";
import { AutomationTimeZone } from "../../../../../common/components/form/schedule-content/AutomationTimeZone";
import { useAppSelector } from "../../../../../../../../store/hooks";
import { automationFrequencySelector } from "../../../../../../../../store/automations/selectros/common/automationFrequencySelector";

export const WorkflowHourlyScheduler: FC = () => {
  const frequency = useAppSelector(automationFrequencySelector);

  if (frequency !== "hourly") {
    return null;
  }

  return (
    <Fragment>
      <WorkflowInterval />
      <AutomationTimeZone />
    </Fragment>
  );
};
