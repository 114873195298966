import { ChangeEvent, FC, Fragment, memo } from "react";
import { Grid, TextField } from "@mui/material";
import { RowLabelColumn } from "../RowLabelColumn";

interface SummaryRowProps {
  summary: string;
  onChange(event: ChangeEvent): void;
}

export const SummaryRow: FC<SummaryRowProps> = memo(({ summary, onChange }) => {
  return (
    <Fragment>
      <RowLabelColumn label="Summary" required />

      <Grid item sm={10}>
        <TextField
          fullWidth
          value={summary}
          type="text"
          size="small"
          name="body.summary"
          placeholder="Summary"
          variant="outlined"
          onChange={onChange}
        />
      </Grid>
    </Fragment>
  );
});
