import { useFormik } from "formik";
import * as yup from "yup";
import { useCallback } from "react";
import { usePatchUsersMeProvidersAzureAccountAttemptsByAccountAttemptIdMutation } from "../../../../../../../services/cloudchipr.api";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../store/hooks";
import { accountAttemptIdSelector } from "../../../../../../../store/account-setup/selectors/accountAttemptIdSelector";
import {
  goToNextStep,
  goToPrevStep,
  resetAccountAttempt,
} from "../../../../../../../store/account-setup/accountSetupSlice";

export function useServicePrincipalHook() {
  const accountAttemptId = useAppSelector(accountAttemptIdSelector);

  const dispatch = useAppDispatch();
  const prevHandler = useCallback(() => {
    dispatch(resetAccountAttempt());
    dispatch(goToPrevStep());
  }, [dispatch]);
  const nextHandler = useCallback(() => {
    dispatch(goToNextStep());
  }, [dispatch]);

  const [triggerMutation, { isLoading }] =
    usePatchUsersMeProvidersAzureAccountAttemptsByAccountAttemptIdMutation();

  const { values, handleChange, handleSubmit, errors } = useFormik({
    initialValues: {
      tenantId: "",
    },
    validationSchema: yup.object({
      tenantId: yup.string().required(),
    }),
    onSubmit: async (values, { setFieldError }) => {
      if (!accountAttemptId || !values.tenantId) {
        return;
      }

      try {
        await triggerMutation({
          accountAttemptId: accountAttemptId,
          body: {
            tenant_id: values.tenantId,
          },
        }).unwrap();

        nextHandler();
      } catch (error) {
        // @ts-expect-error // todo: api fix
        if (error.status === 400) {
          setFieldError("tenantId", "Provided Tenant id is wrong");
        }
      }
    },
  });

  return {
    values,
    handleChange,
    handleSubmit,
    errors,
    isLoading,
    prevHandler,
    nextHandler,
  };
}
