import { FC } from "react";
import { CSVLink } from "react-csv";
import { IconButton, Tooltip } from "@mui/material";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import { RecommendationGroupType } from "../../utils/types/types";
import { RecommendationsTabType } from "../OffHoursRecommendationsContent";
import { useAppSelector } from "../../../../../../../store/hooks";
import { offHoursCsvExportDataSelector } from "../../../../../../../store/recommendations/selectors/off-hours/data/offHoursCsvExportDataSelector";

interface OffHoursRecommendationsCsvDownloaderProps {
  group: RecommendationGroupType;
  tab: RecommendationsTabType;
}

export const OffHoursRecommendationsCsvDownloader: FC<
  OffHoursRecommendationsCsvDownloaderProps
> = ({ tab, group }) => {
  const data = useAppSelector((state) =>
    offHoursCsvExportDataSelector(state, group, tab === "active"),
  );

  if (!data) {
    return null;
  }

  const messagePostfix = `${
    tab === "dismissed" ? " Dismissed" : ""
  } Recommendations`;

  return (
    <CSVLink
      filename={
        group === "compute"
          ? `Compute Instance${messagePostfix}`
          : `SQL Database${messagePostfix}`
      }
      data={data}
    >
      <Tooltip title="Export CSV" placement="top">
        <IconButton size="small">
          <DownloadOutlinedIcon fontSize="small" />
        </IconButton>
      </Tooltip>
    </CSVLink>
  );
};
