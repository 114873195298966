import { MouseEvent, FC, useCallback } from "react";
import { DPMonth, useContextMonthsPropGetters } from "@rehookify/datepicker";
import { Chip } from "@mui/material";

interface DateRangeCalendarMonthProps {
  dpMonth: DPMonth;
  onSelect(): void;
}

export const DateRangeCalendarMonth: FC<DateRangeCalendarMonthProps> = ({
  dpMonth,
  onSelect,
}) => {
  const { monthButton } = useContextMonthsPropGetters();

  const buttonProps = monthButton(dpMonth);
  const onClick = buttonProps.onClick;

  const clickHandler = useCallback(
    (e: MouseEvent<HTMLElement>) => {
      onClick?.(e);
      onSelect();
    },
    [onSelect, onClick],
  );

  return (
    <Chip
      label={dpMonth.month}
      size="small"
      color="primary"
      {...monthButton(dpMonth)}
      onClick={clickHandler}
      sx={{
        border: "none",
        color: dpMonth.selected ? "primary" : "text.secondary",
      }}
      variant={dpMonth.selected ? "filled" : "outlined"}
    />
  );
};
