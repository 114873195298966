import { FC } from "react";

import { Box, Button, Stack, Typography } from "@mui/material";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import { Link as RouterLink } from "react-router-dom";
import { useAppSelector } from "../../../../../../../store/hooks";
import { billingErrorAccountStatusesByProviderSelector } from "../../../../../../../store/common/selectors/billing-status/error/billingErrorAccountStatusesByProviderSelector";
import SlackLogo from "../../../../../../../assets/images/logos/integrations/slack.svg";

export const GcpBillingErrorState: FC = () => {
  const accountStatuses = useAppSelector((state) =>
    billingErrorAccountStatusesByProviderSelector(state, "gcp"),
  );

  const errorMessage = accountStatuses?.at(0)?.problem_message;

  return (
    <Stack justifyContent="center" alignItems="center" height="100%" gap={2}>
      <ErrorOutlineOutlinedIcon color="error" fontSize="large" />
      <Typography variant="h5" fontWeight={500} mt={2}>
        Billing Data Error
      </Typography>
      <Typography
        color="text.secondary"
        variant="subtitle2"
        fontWeight="regular"
        fontSize={16}
      >
        Please contact us to resolve the issue.
      </Typography>
      {errorMessage && (
        <Box bgcolor="grey.300" p={1} borderRadius={2} maxWidth={624}>
          <Typography fontWeight={700} fontSize={10} color="text.disabled">
            Error
          </Typography>
          <Typography variant="body2"> {errorMessage}</Typography>
        </Box>
      )}
      <Button
        component={RouterLink}
        to="https://communityinviter.com/apps/cloudchiprcommunity/landing-page"
        variant="outlined"
        target="_blank"
        startIcon={<img src={SlackLogo} alt="slackLogo" width={16} />}
        sx={{ mt: 2 }}
      >
        Contact Us
      </Button>
    </Stack>
  );
};
