import { FC } from "react";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import { FilterTemplatesDialogTabType } from "../utils/types";

interface SaveSimplifiedFilterTemplatesDialogTabsProps {
  tab: FilterTemplatesDialogTabType;
  handleTabChange(_: unknown, tab: FilterTemplatesDialogTabType): void;
}

export const SaveSimplifiedFilterTemplatesDialogTabs: FC<
  SaveSimplifiedFilterTemplatesDialogTabsProps
> = ({ tab, handleTabChange }) => {
  return (
    <ToggleButtonGroup
      exclusive
      color="primary"
      size="small"
      value={tab}
      onChange={handleTabChange}
    >
      <ToggleButton value="saveAsNew" size="small">
        Save as new
      </ToggleButton>
      <ToggleButton value="rewriteExisting" size="small">
        Rewrite existing
      </ToggleButton>
    </ToggleButtonGroup>
  );
};
