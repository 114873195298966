import { commitmentUtilizationSetupPropertyByKeySelector } from "./commitmentUtilizationSetupPropertyByKeySelector";
import { CommitmentUtilizationWidgetSetupType } from "../../../utils/types/setups/commitmentUtilizationWidgetSetupType";
import { RootState } from "../../../../store";

export const commitmentUtilizationSetupVisualizationPropertyByKeySelector =
  (key: keyof CommitmentUtilizationWidgetSetupType["visualization"]) =>
  (state: RootState): any => {
    const setup =
      commitmentUtilizationSetupPropertyByKeySelector("visualization")(state);

    if (!setup) {
      return null;
    }

    return setup[key];
  };
