import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Integration, ResourceType } from "../../services/cloudchipr.api";

export interface IntegrationsSliceInterface {
  currentIntegrationType: Integration["type"] | null;
  resourceType: ResourceType | null;
}

const initialState: IntegrationsSliceInterface = {
  currentIntegrationType: null,
  resourceType: null,
};

export const integrationsSlice = createSlice({
  name: "integrations",
  initialState: initialState,
  reducers: {
    initiateCurrentIntegration: (
      state,
      action: PayloadAction<{
        type: Integration["type"];
        resourceType: ResourceType;
      }>,
    ) => {
      state.currentIntegrationType = action.payload.type;
      state.resourceType = action.payload.resourceType;
    },
    destroyCurrentIntegration: () => {
      return initialState;
    },
  },
});

export const { initiateCurrentIntegration, destroyCurrentIntegration } =
  integrationsSlice.actions;

export default integrationsSlice.reducer;
