import { FC, useCallback } from "react";
import { Checkbox, FormControlLabel } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../../../../store/hooks";
import { setTaskManagementShowCompletedTasks } from "../../../../../../store/task-management/taskManagementSlice";
import { taskManagementShowCompletedTasksSelector } from "../../../../../../store/task-management/selectors/list/taskManagementShowCompletedTasksSelector";
import { tasksListIsEmptySelector } from "../../../../../../store/task-management/selectors/data/tasksListIsEmptySelector";

export const TaskManagementShowCompleted: FC = () => {
  const dispatch = useAppDispatch();
  const show = useAppSelector(taskManagementShowCompletedTasksSelector);
  const empty = useAppSelector(tasksListIsEmptySelector);

  const changeHandler = useCallback(
    (_: unknown, checked: boolean) => {
      dispatch(setTaskManagementShowCompletedTasks(checked));
    },
    [dispatch],
  );

  return (
    <FormControlLabel
      disabled={empty}
      label="Show Completed"
      onChange={changeHandler}
      sx={{ whiteSpace: "nowrap" }}
      control={<Checkbox size="small" checked={show} />}
    />
  );
};
