import "@ag-grid-community/styles/ag-grid.css";
import "@ag-grid-community/styles/ag-theme-quartz.css";

import { FC, RefObject, useMemo } from "react";
import { AgGridReact } from "@ag-grid-community/react";
import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
import { ColDef, ModuleRegistry } from "@ag-grid-community/core";
import { Box } from "@mui/material";
import { grey, indigo } from "@mui/material/colors";
import { savingsOpportunitiesTableColumns } from "./savingsOpportunitiesTableColumns";
import { AutoGroupColumnDefInnerRenderer } from "./cell-renderers/AutoGroupColumnDefInnerRenderer";
import { agGridOverwrittenIcons } from "../../automations/workflows/data-grid/utils/constans/grid-icons";
import { useSavingsOpportunitiesTableSortingHook } from "../utils/hooks/useSavingsOpportunitiesTableSorting.hook";
import { SavingsOpportunity } from "../../../../services/cloudchipr.api";
import { savingsOpportunitiesTablePinnedColumns } from "../utils/constants/pinnedColumns";

ModuleRegistry.registerModules([ClientSideRowModelModule]);

interface SavingsOpportunitiesDataGridProps {
  data?: SavingsOpportunity[];
  refToPassGrid: RefObject<AgGridReact>;
}

export const SavingsOpportunitiesDataGrid: FC<
  SavingsOpportunitiesDataGridProps
> = ({ refToPassGrid, data }) => {
  const { onColumnsSortingChange, sorting } =
    useSavingsOpportunitiesTableSortingHook();

  const initialState = useMemo(() => {
    return {
      sort: { sortModel: sorting },
      columnPinning: savingsOpportunitiesTablePinnedColumns,
    };
  }, [sorting]);

  const autoGroupColumnDef = useMemo<ColDef>(
    () => ({
      minWidth: 200,
      pinned: "left",
      sortable: false,
      headerValueGetter: () => "Grouped by",
      cellRendererParams: { innerRenderer: AutoGroupColumnDefInnerRenderer },
    }),
    [],
  );

  return (
    <Box className="ag-theme-quartz" sx={wrapperStyles}>
      <AgGridReact
        enableCellTextSelection
        rowHeight={55}
        rowData={data}
        ref={refToPassGrid}
        gridOptions={griOptions}
        initialState={initialState}
        rowGroupPanelShow="always"
        groupDefaultExpanded={1}
        autoGroupColumnDef={autoGroupColumnDef}
        onSortChanged={onColumnsSortingChange}
        columnDefs={savingsOpportunitiesTableColumns}
      />
    </Box>
  );
};

const iconsStyles = {
  "& span.ag-drag-handle ": { lineHeight: 0 },
  "& span.ag-menu-option-part.ag-menu-option-icon": { lineHeight: 0 },
  "& span.ag-column-drop-cell-button.ag-column-drop-horizontal-cell-button": {
    lineHeight: 0,
  },
  "& .ag-column-drop-title-bar.ag-column-drop-horizontal-title-bar": {
    lineHeight: 0,
  },
};

const columnSortIconStyles = {
  "& .ag-sort-indicator-icon": {
    visibility: "hidden",
    marginLeft: "5px",
    padding: "2px 4px",
    borderRadius: " 50%",
    "&:hover": { background: grey[300] },
  },

  "& .ag-header-cell-sortable.ag-header-active:hover .ag-sort-indicator-icon": {
    visibility: "visible",
  },

  "& .ag-header-cell-sorted-desc .ag-sort-indicator-icon": {
    visibility: "visible !important",
  },
  "& .ag-header-cell-sorted-asc .ag-sort-indicator-icon": {
    visibility: "visible !important",
  },

  '& .ag-header-cell[aria-sort="ascending"] .ag-sort-indicator-icon': {
    visibility: "visible !important",
  },
  '& .ag-header-cell[aria-sort="descending"] .ag-sort-indicator-icon': {
    visibility: "visible !important",
  },

  "& .ag-sort-indicator-container .ag-sort-order": {
    display: "none",
  },
};

const rowHoverStyles = {
  "--ag-active-color": grey[100],
  "& .ag-row-hover": { backgroundColor: grey[100] },
};

const resizeHandleStyles = {
  "& .ag-header-cell-resize": { width: 20, visibility: "hidden" },
  "& .ag-header-cell:hover .ag-header-cell-resize": { visibility: "visible" },
  "--ag-header-column-resize-handle-height": "80%",
};

const checkboxStyles = {
  ".ag-checkbox-input-wrapper.ag-checked::after": {
    color: `${indigo["500"]} !important`,
  },
  ".ag-checkbox-input-wrapper.ag-indeterminate::after": {
    color: `${indigo["500"]} !important`,
  },
};

const expanderStyles = {
  "& .ag-group-expanded,.ag-group-contracted": {
    padding: "4px",
    overflow: "hidden",
    borderRadius: "50%",
    height: "24px !important",
    width: "24px !important",
    "&:hover": { background: grey[300] },
  },
};

const wrapperStyles = {
  height: "100%",
  width: "100%",
  "& .ag-cell-wrapper": { height: "100%", alignItems: "center" },
  "& .ag-root-wrapper": { borderRadius: 0, border: "none" },
  "--ag-header-height": "56px",
  "--ag-header-background-color": grey[100],

  ...columnSortIconStyles,
  ...rowHoverStyles,
  ...resizeHandleStyles,
  ...checkboxStyles,
  ...iconsStyles,
  ...expanderStyles,
};

const griOptions = { icons: agGridOverwrittenIcons };
