import { FC, useCallback, useMemo } from "react";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../store/hooks";
import { setReportsScheduleDataThunk } from "../../../../../../../store/reports/thunks/setReportsScheduleDataThunk";
import { reportsScheduleDefaultDataByFrequency } from "../../../../../../../store/reports/utils/constants/constants";
import { ReportsDataFrequencyButtonType } from "../../../../../../../store/reports/utils/types/types";
import { reportsScheduleDataByKeySelector } from "../../../../../../../store/reports/selector/reportsScheduleDataByKeySelector";

export const ReportsDrawerScheduleFrequencies: FC = () => {
  const dispatch = useAppDispatch();
  const scheduleFrequency = useAppSelector(
    reportsScheduleDataByKeySelector("frequency"),
  );
  const frequency = useMemo(() => {
    if (
      scheduleFrequency === "monthly_by_day" ||
      scheduleFrequency === "monthly_by_weekday"
    ) {
      return "monthly";
    }

    return scheduleFrequency;
  }, [scheduleFrequency]);

  const handleChange = useCallback(
    (_: unknown, frequency: ReportsDataFrequencyButtonType | null) => {
      if (!frequency) {
        return;
      }

      if (frequency === "monthly") {
        dispatch(
          setReportsScheduleDataThunk(
            reportsScheduleDefaultDataByFrequency.get("monthly_by_day") || {},
          ),
        );
        return;
      }

      dispatch(
        setReportsScheduleDataThunk(
          reportsScheduleDefaultDataByFrequency.get(frequency) || {},
        ),
      );
    },
    [dispatch],
  );

  return (
    <ToggleButtonGroup
      exclusive
      fullWidth
      size="small"
      color="primary"
      value={frequency}
      onChange={handleChange}
    >
      {frequencies.map((frequency) => {
        return (
          <ToggleButton
            key={frequency}
            value={frequency}
            sx={toggleButtonStyles}
          >
            {frequency}
          </ToggleButton>
        );
      })}
    </ToggleButtonGroup>
  );
};

const frequencies: ReportsDataFrequencyButtonType[] = [
  "daily",
  "weekly",
  "monthly",
];

const toggleButtonStyles = {
  px: 2,
  textTransform: "capitalize",
};
