import { RecommendationTarget } from "../../../../services/cloudchipr.api";

export const filterRecommendationByDisplayAcrossInstanceFamilyFilter = (
  recommendationTarget: RecommendationTarget | null,
  displayRecommendationsAcrossInstanceFamily?: boolean,
) => {
  if (
    !displayRecommendationsAcrossInstanceFamily &&
    recommendationTarget === "same_instance_family"
  ) {
    return true;
  }

  return !!displayRecommendationsAcrossInstanceFamily;
};
