import { FC } from "react";
import { Typography } from "@mui/material";

interface IntegrationCustomMessageInfoSectionProps {
  start: string;
  tail: string;
  color?: "primary.main" | "text.primary";
}
export const IntegrationCustomMessageInfoSection: FC<
  IntegrationCustomMessageInfoSectionProps
> = ({ start, tail, color = "text.primary" }) => {
  return (
    <Typography variant="body2" color="text.secondary" fontWeight="medium">
      {start}:{" "}
      <Typography color={color} component="span" fontWeight="inherit">
        {tail}
      </Typography>
    </Typography>
  );
};
