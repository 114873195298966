import { ResourceType } from "../../../../../../services/cloudchipr.api";

interface GetProtectingResourcesDataItem {
  type?: string;
  id?: string;
  is_protected?: boolean;
}
export const getProtectingResourcesMutatedData = (
  resourceType: ResourceType,
  protectedResources: string[],
  actionType: "protect" | "unprotect",
): GetProtectingResourcesDataItem[] => {
  return protectedResources.map((item) => {
    return {
      id: item,
      type: resourceType,
      is_protected: actionType === "protect",
    };
  });
};
