import { costBreakdownWidgetTotalDataWithIdsSelector } from "./costBreakdownWidgetTotalDataWithIdsSelector";
import { RootState } from "../../../../../store";
import { ResourceExplorerGridDataWithId } from "../../../../../resource-explorer/utils/types/resourceExplorerDataGrid";
import { combineCostBreakdownWidgetGridDataWithOthers } from "../../../../utils/helpers/costBreakdown/combineCostBreakdownWidgetGridDataWithOthers";

export const costBreakdownWidgetTableViewDataSelector = (
  state: RootState,
  widgetId: string,
): ResourceExplorerGridDataWithId[] => {
  const data = costBreakdownWidgetTotalDataWithIdsSelector(state, widgetId);

  if (!data) {
    return [];
  }

  return combineCostBreakdownWidgetGridDataWithOthers(data);
};
