import { FC, Fragment } from "react";
import { Chip, IconButton, Stack } from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { LoadingButton } from "@mui/lab";

interface FeaturePaginationProps {
  maxCount: number;
  activeStep: number;
  onClickNext(): void;
  onClickBack(): void;
  loading: boolean;
  handleMarkSeenClick(): void;
}

export const FeaturePagination: FC<FeaturePaginationProps> = ({
  maxCount,
  activeStep,
  onClickNext,
  onClickBack,
  loading,
  handleMarkSeenClick,
}) => {
  return (
    <Fragment>
      <Stack direction="row" alignItems="center">
        <IconButton
          disabled={activeStep === 0}
          onClick={onClickBack}
          color="primary"
        >
          <ArrowBackIcon fontSize="small" />
        </IconButton>
        <Chip
          label={activeStep + 1 + " / " + maxCount}
          color="primary"
          variant="outlined"
          size="small"
        />
        <IconButton
          disabled={activeStep === maxCount - 1}
          onClick={onClickNext}
          color="primary"
        >
          <ArrowForwardIcon fontSize="small" />
        </IconButton>
      </Stack>
      {activeStep !== 0 && activeStep !== maxCount - 1 && (
        <LoadingButton
          variant="text"
          onClick={handleMarkSeenClick}
          loading={loading}
        >
          Skip All
        </LoadingButton>
      )}
      {activeStep === maxCount - 1 && (
        <LoadingButton
          variant="contained"
          color="primary"
          onClick={handleMarkSeenClick}
          loading={loading}
        >
          Thanks!
        </LoadingButton>
      )}
    </Fragment>
  );
};
