import { FC, useCallback, useMemo } from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { Box, Stack, Typography } from "@mui/material";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import { TaskPropertyName } from "./components/TaskPropertyName";
import { TaskPropertyColor } from "./components/TaskPropertyColor";
import { TaskPropertyDeleteButton } from "./components/TaskPropertyDeleteButton";
import { TaskPropertyType } from "../../../../../../../store/task-management/utils/types/types";

interface TaskPropertyItemProps extends TaskPropertyType {
  index: number;
  isLast: boolean;
  dragable?: boolean;
  removable?: boolean;
  colorable?: boolean;
  properties: TaskPropertyType[];
  onPropertiesChange(properties: TaskPropertyType[]): void;
}

export const TaskPropertyItem: FC<TaskPropertyItemProps> = ({
  id,
  name,
  color,
  index,
  onPropertiesChange,
  isLast,
  properties,
  dragable = true,
  removable = true,
  colorable = true,
}) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const changePropertyByIndex = useCallback(
    (property: Partial<TaskPropertyType>) => {
      const newItems = [...properties];
      newItems[index] = { ...newItems[index], ...property };

      onPropertiesChange(newItems);
    },
    [index, onPropertiesChange, properties],
  );

  const otherPropertiesNames = useMemo(
    () =>
      properties
        .filter((prop) => id !== prop.id)
        .map((property) => property.name.toLowerCase()),
    [properties, id],
  );
  const error = otherPropertiesNames.includes(name.toLowerCase());

  return (
    <Stack direction="row" alignItems="center" style={style} spacing={0.5}>
      {dragable && (
        <Box
          ref={setNodeRef}
          {...attributes}
          {...listeners}
          sx={{ cursor: isDragging ? "grabbing" : "grab", lineHeight: 0 }}
        >
          <DragIndicatorIcon color="action" fontSize="small" />
        </Box>
      )}

      <Stack flex={1}>
        <Stack
          flex={1}
          border={1}
          bgcolor="white"
          direction="row"
          borderRadius={1}
          borderColor={error ? "error.main" : "grey.400"}
          justifyContent="space-between"
        >
          <Stack direction="row" flex={1} spacing={1} alignItems="center">
            <Box width={4} height="100%" bgcolor={color} />

            <TaskPropertyName
              value={name}
              onPropertyChange={changePropertyByIndex}
            />
          </Stack>

          <Stack direction="row" spacing={1} p={0.75}>
            <TaskPropertyColor
              disabled={!colorable}
              selectedColor={color}
              onPropertyChange={changePropertyByIndex}
            />

            <TaskPropertyDeleteButton
              properties={properties}
              index={index}
              isLast={isLast}
              onPropertiesChange={onPropertiesChange}
              disabled={!removable}
            />
          </Stack>
        </Stack>

        {error && (
          <Typography variant="caption" color="error">
            Options cannot have duplicate names
          </Typography>
        )}
      </Stack>
    </Stack>
  );
};
