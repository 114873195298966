import { createDraftSafeSelector } from "@reduxjs/toolkit";
import { resourceExplorerWidgetDataSelector } from "../resource-explorer-widget/resourceExplorerWidgetDataSelector";

export const resourceExplorerDataGridDataPrevPeriodTotalCostSelector =
  createDraftSafeSelector(
    [resourceExplorerWidgetDataSelector],
    (data): number => {
      return data?.previous_period_total_cost ?? 0;
    },
  );
