import { providerDisabledTooltipTitleCombiner } from "./providerDisabledTooltipTitleCombiner";
import { RootState } from "../../../../../store";
import { gcpNonDeletedAndNotBillingAccountsSelector } from "../../../../../accounts/selectors/gcp/gcpNonDeletedAndNotBillingAccountsSelector";
import { automationTypeSelector } from "../../automationTypeSelector";

export const gcpDisabledTooltipTitleSelector = (state: RootState) => {
  const accounts = gcpNonDeletedAndNotBillingAccountsSelector(state);
  const automationType = automationTypeSelector(state);

  return providerDisabledTooltipTitleCombiner(
    automationType === "offHours",
    "gcp",
    accounts,
  );
};
