import { FC } from "react";
import { Stack, Typography } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

export const SlackIntegrationMessageNotifyMetaData: FC = () => {
  return (
    <Stack spacing={1.5}>
      <Stack direction="row" spacing={0.5}>
        <SearchIcon
          sx={{
            color: "info.main",
          }}
        />
        <Typography variant="body1" fontWeight="medium">
          Resources Found
        </Typography>
      </Stack>

      <Typography variant="body1">
        Resources found by workflow
        <Typography component="span" variant="inherit" fontWeight="medium">
          {`  {{workflow name}}.`}
        </Typography>
        <Typography variant="inherit" component="span">
          Please see the detailed list attached.
        </Typography>
      </Typography>
    </Stack>
  );
};
