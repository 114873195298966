import { createAsyncThunk } from "@reduxjs/toolkit";
import { closeSnackbar, enqueueSnackbar, SnackbarKey } from "notistack";
import { resourceExplorerCSVDataHashGeneratorThunk } from "./resourceExplorerCSVDataHashGeneratorThunk";
import { cloudChiprApi } from "../../../../../services/cloudchipr.api";
import { CsvDownloadAlertAction } from "../../../../../components/pages/resource-explorer/components/resource-explorer-card/components/resource-explorer-data-grid/components/csv/CsvDownloadAlertAction";
import { resourceExplorerCsvFileNameSelector } from "../../../selectors/current-resource-explorer/csv/resourceExplorerCsvFileNameSelector";
import { RootState } from "../../../../store";
import { CsvDownloadAlertTitle } from "../../../../../components/pages/resource-explorer/components/resource-explorer-card/components/resource-explorer-data-grid/components/csv/CsvDownloadAlertTitle";
import { setResourceExplorerCsvDataHash } from "../../../resourceExplorerSlice";

export const resourceExplorerCSVDataLinkFetcherThunk = createAsyncThunk(
  "resourceExplorer/resourceExplorerCSVDataDownloaderThunk",
  async (_: void, { dispatch, getState }) => {
    const state = getState() as RootState;
    const key: SnackbarKey = enqueueSnackbar("Preparing CSV...", {
      variant: "snackbarAlert",
      autoHideDuration: null,
      AlertSnackBarProps: {
        severity: "info",
        onClose: () => closeSnackbar(key),
      },
    });

    const hash = await dispatch(
      resourceExplorerCSVDataHashGeneratorThunk(),
    ).unwrap();

    const { getUsersMeOrganisationsCurrentWidgetsResourceExplorerDownloadCsv } =
      cloudChiprApi.endpoints;

    if (!hash) {
      return;
    }

    const csvFileName = resourceExplorerCsvFileNameSelector(state);

    dispatch(setResourceExplorerCsvDataHash(hash));

    const getCsvUrlRequest = () => {
      return dispatch(
        getUsersMeOrganisationsCurrentWidgetsResourceExplorerDownloadCsv.initiate(
          { hash: hash },
          { forceRefetch: true },
        ),
      );
    };

    try {
      getCsvUrlRequest();
      let isIntervalRequestInProgress = false;

      const intervalId = setInterval(() => {
        if (isIntervalRequestInProgress) {
          return;
        }
        isIntervalRequestInProgress = true;

        getCsvUrlRequest()
          .then((response) => {
            const url = response?.data?.url;

            if (url) {
              clearInterval(intervalId);
              closeSnackbar(key);
              dispatch(setResourceExplorerCsvDataHash(""));
              openDownloadSnackBar(csvFileName, url);
            }
          })
          .finally(() => {
            isIntervalRequestInProgress = false;
          });
      }, 2000);
    } catch (e) {
      // @ts-expect-error //todo: remove this when backend will add types
      enqueueSnackbar(e?.data?.message ?? "Something went wrong", {
        variant: "snackbarAlert",
        autoHideDuration: 1000,
        AlertSnackBarProps: { severity: "error" },
      });

      return false;
    }
  },
);

const openDownloadSnackBar = (csvFileName: string, url: string) => {
  const downloadKey: SnackbarKey = enqueueSnackbar("", {
    variant: "snackbarAlert",
    autoHideDuration: null,
    AlertSnackBarProps: {
      severity: "success",
      footer: CsvDownloadAlertAction(url, () => closeSnackbar(downloadKey)),
      action: CsvDownloadAlertTitle(csvFileName),
    },
  });
};
