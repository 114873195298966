import { cloudChiprApi } from "../../../../services/cloudchipr.api";
import { RootState } from "../../../store";

const selector =
  cloudChiprApi.endpoints
    .getUsersMeOrganisationsCurrentResourceExplorerViewsByResourceExplorerViewId
    .select;

export const resourceExplorerViewByIdSelector = (
  state: RootState,
  viewId?: string,
) => {
  if (!viewId) {
    return;
  }
  return selector({ resourceExplorerViewId: viewId })(state);
};
