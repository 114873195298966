import { FC, ReactNode } from "react";
import Stack from "@mui/material/Stack";
import { Typography } from "@mui/material";

interface AuthLessDashboardHeaderProps {
  name?: string;
  children?: ReactNode;
}

export const AuthLessDashboardHeader: FC<AuthLessDashboardHeaderProps> = ({
  name,
  children,
}) => {
  return (
    <Stack
      p={1}
      top={0}
      bgcolor="white"
      zIndex="appBar"
      direction="row"
      borderBottom={1}
      position="sticky"
      alignItems="center"
      borderColor="grey.300"
      justifyContent="space-between"
    >
      <Typography variant="subtitle1" fontWeight="medium">
        {name ?? "Dashboard"}
      </Typography>

      {children}
    </Stack>
  );
};
