import { TagsFilter } from "../types";

export const generateFilterAfterTagFiltersValuesChange = (
  newTagValues: string[] | string,
  prevFilter: TagsFilter,
  index: number,
) => {
  const updatedListFiltersValue = prevFilter?.value.map((item, i) => {
    if (i === index) {
      return {
        ...item,
        values: newTagValues,
      };
    }
    return item;
  });

  return {
    ...prevFilter,
    value: updatedListFiltersValue,
  };
};
