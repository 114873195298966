import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const storageKeyForApiKey = "C8R:api-key";

const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_API_PUBLIC_HOST,
  prepareHeaders: (headers) => {
    headers.set("Content-type", "application/json");

    const apiKey = sessionStorage.getItem(storageKeyForApiKey);

    if (apiKey) {
      headers.set("x-api-key", apiKey);
    }

    return headers;
  },
});

// initialize an empty api service that we'll inject endpoints into later as needed
export const emptySplitApiAuthLess = createApi({
  reducerPath: "cloudchipr-authless",
  baseQuery: baseQuery,
  endpoints: () => ({}),
});
