import { FC } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Stack } from "@mui/material";
import C8rLogo from "../../../assets/images/logos/c8r-logo-white-text.svg";
import accountSetupSidebarImage from "../../../assets/images/account-setup-sidebar.png";
import awsBadge from "../../../assets/images/aws-badge.svg";
import soc2Badge from "../../../assets/images/soc2-badge.svg";

export const AccountSetupSidebar: FC = () => {
  return (
    <Box sx={sidebarStyles}>
      <Box sx={{ color: "white", mt: 44 }}>
        <img src={C8rLogo} alt="Cloudchipr" />
        <Typography variant="h3" pt={2} pb={1} whiteSpace="nowrap">
          Almost there!
        </Typography>
        <Typography variant="h6" pb={3} fontWeight="regular">
          Setup your clouds in a few clicks
        </Typography>

        <Stack direction="row" spacing={2.5}>
          <img src={soc2Badge} alt="SOC2 certified" />
          <img src={awsBadge} alt="aws qualified" />
        </Stack>
      </Box>
    </Box>
  );
};

const sidebarStyles = {
  minHeight: "100vh",
  flexBasis: 560,
  flexGrow: 0,
  flexShrink: 1,
  backgroundImage: `url(${accountSetupSidebarImage})`,
  backgroundSize: "cover",
  p: 3,
  display: "flex",
  alignItems: "flex-start",
  justifyContent: "center",
};
