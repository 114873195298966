import { recommendationDbActiveDataSelector } from "./database/recommendationDbActiveDataSelector";
import { recommendationDbDismissedDataSelector } from "./database/recommendationDbDismissedDataSelector";
import { getUsersMeOrganisationsCurrentDismissedResourcesSelector } from "../../dismiss/resources/getUsersMeOrganisationsCurrentDismissedResourcesSelector";
import { RootState } from "../../../../store";

export const offHoursRecommendationsDbEmptySelector = (state: RootState) => {
  const dbActiveData = recommendationDbActiveDataSelector(state);
  const dbDismissedData = recommendationDbDismissedDataSelector(state);
  const dbDismissedResources =
    getUsersMeOrganisationsCurrentDismissedResourcesSelector({
      group: "database",
      recommendationType: "off_hours",
    })(state).data;

  return !(
    dbActiveData.length ||
    dbDismissedData.length ||
    dbDismissedResources?.length
  );
};
