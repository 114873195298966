import { createDraftSafeSelector } from "@reduxjs/toolkit";
import { currentDashboardSelector } from "./currentDashboardSelector";
import { DashboardWidgetType } from "../../utils/types/types";

export const dashboardWidgetsSelector = createDraftSafeSelector(
  [currentDashboardSelector],
  (dashboard): DashboardWidgetType[] => {
    if (!dashboard) {
      return [];
    }

    return (dashboard?.widgets ?? []) as DashboardWidgetType[];
  },
);
