import { reportsDataSelector } from "./reportsDataSelector";
import { isReportDataChangedSelector } from "./isReportDataChangedSelector";
import { RootState } from "../../store";

export const reportsSaveButtonDisabledSelector = (state: RootState) => {
  const { name, source_id, notifications, emails, id, schedule } =
    reportsDataSelector(state);
  const isDataChange = isReportDataChangedSelector(state);
  const isEditModeAndNoDataChange = !!id && !isDataChange;

  const notificationsValidation = notifications?.some((integration) => {
    if (integration.type === "email" && !integration.to?.length) {
      return true;
    }
    return integration.type === "slack" && !integration.conversations?.length;
  });

  const integrationValidation =
    notificationsValidation || !(notifications?.length || emails?.to.length);

  return (
    !name ||
    !source_id ||
    integrationValidation ||
    (schedule.frequency === "daily" && !schedule.interval) ||
    (schedule.frequency === "weekly" && !schedule.interval) ||
    isEditModeAndNoDataChange
  );
};
