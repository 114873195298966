import { FC } from "react";
import { GridApi, RowNode } from "@ag-grid-community/core";
import { Box } from "@mui/material";

interface AutoGroupColumnDefInnerRendererProps {
  api: GridApi;
  node: RowNode;
}

export const AutoGroupColumnDefInnerRenderer: FC<
  AutoGroupColumnDefInnerRendererProps
> = (props) => {
  const groupingColumns = props.api.getRowGroupColumns();
  const currentGrouping = props.node.field as string;

  if (!currentGrouping) {
    return null;
  }

  const currentCol = groupingColumns.find(
    (col) => col?.getColId() === currentGrouping,
  );

  if (!currentCol) {
    return null;
  }

  const data = props.node?.allLeafChildren?.at(0)?.data;

  return (
    <Box pr={1}>
      {currentCol?.getColDef()?.cellRenderer?.({ ...props, data })}
    </Box>
  );
};
