import { FC, useCallback } from "react";
import { IconButton, Menu, MenuItem, Stack, Tooltip } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { AccountAttachMenuItem } from "./AccountAttachMenuItem";
import { AccountRetryMenuItem } from "./AccountRetryMenuItem";
import { AccountSwitchModeItem } from "./AccountSwitchModeItem";
import {
  Account,
  ProviderAccessType,
  ProviderType,
  useDeleteUsersMeAccountsByAccountIdMutation,
} from "../../../../../../../services/cloudchipr.api";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../store/hooks";
import { useMenuHook } from "../../../../../../../utils/hooks/useMenu.hook";
import { useAppAbility } from "../../../../../../../services/permissions";
import { getAccountsThunk } from "../../../../../../../store/accounts/thunks/getAccountsThunk";
import { useDialog } from "../../../../../../../utils/hooks/useDialog.hook";
import { ConfirmationDialog } from "../../../../../../common/modals/ConfirmationDialog";
import { awsAccountManagementAccountDetailsSelector } from "../../../../../../../store/accounts/selectors/aws/awsAccountManagementAccountDetailsSelector";

interface AccountsTableActionCellProps {
  accountId: string;
  disableDetach: boolean;
  attach: boolean;
  retry: boolean;
  provider: ProviderType;
  accessMode: ProviderAccessType;
  status?: Account["status"];
  subAccount: boolean;
}

export const AccountActions: FC<AccountsTableActionCellProps> = ({
  accountId,
  disableDetach,
  attach,
  provider,
  retry,
  accessMode,
  status,
  subAccount,
}) => {
  const dispatch = useAppDispatch();
  const { cannot, can } = useAppAbility();

  const [deleteAccount, { isLoading }] =
    useDeleteUsersMeAccountsByAccountIdMutation();

  const { open: dialogOpen, openDialog, closeDialog } = useDialog();
  const { anchor, open, openMenu, closeMenu } = useMenuHook();

  const managementAccount = useAppSelector((state) =>
    awsAccountManagementAccountDetailsSelector(state, accountId),
  );

  const canNotDeleteAccount = cannot("delete", "account");
  const canEditAccount = can("edit", "account");

  const canEditAccountAccessModeByStatus = status !== "connected_for_billing";

  const handlePreventCapturing = useCallback((event: any) => {
    event.stopPropagation();
    event.preventDefault();
  }, []);

  const handleDelete = useCallback(async () => {
    if (!accountId) return;
    await deleteAccount({ accountId });
    closeDialog();
    closeMenu();
    await dispatch(getAccountsThunk());
  }, [accountId, deleteAccount, dispatch, closeDialog, closeMenu]);

  const detachMenuItemJSX = (
    <MenuItem
      onClick={openDialog}
      disabled={isLoading || disableDetach || canNotDeleteAccount || subAccount}
    >
      Detach
    </MenuItem>
  );

  return (
    <Stack alignItems="end" flexGrow={1} onClick={handlePreventCapturing}>
      <IconButton onClick={openMenu}>
        <MoreVertIcon />
      </IconButton>
      <Menu anchorEl={anchor} open={open} onClose={closeMenu}>
        {attach && (
          <AccountAttachMenuItem accountId={accountId} provider={provider} />
        )}

        {retry && <AccountRetryMenuItem accountId={accountId} />}

        {canEditAccount && canEditAccountAccessModeByStatus && (
          <AccountSwitchModeItem
            accessMode={accessMode}
            providerType={provider}
            accountId={accountId}
            managementAccountMessage={
              !managementAccount
                ? null
                : `This is sub-account of ✪ ${managementAccount?.provider_account_name} account. Please manage permissions from Management account or re-add it separately and Cloudchipr will overwrite it.`
            }
            onClose={closeMenu}
          />
        )}

        {subAccount ? (
          <Tooltip
            title={`This is sub-account of ✪ ${managementAccount?.provider_account_name} account. Auto-discovered sub-accounts can not be detached.`}
            arrow
            placement="right"
          >
            <span>{detachMenuItemJSX}</span>
          </Tooltip>
        ) : (
          detachMenuItemJSX
        )}
      </Menu>

      <ConfirmationDialog
        open={dialogOpen}
        title="Detach Account?"
        dialogContent="This will completely detach your cloud account and delete all the dashboards and schedules for this account. Please confirm if you want to proceed."
        CancelButtonProps={{
          onClick: closeDialog,
        }}
        ConfirmButtonProps={{
          children: "detach",
          onClick: handleDelete,
          loading: isLoading,
        }}
        onClose={closeDialog}
      />
    </Stack>
  );
};
