import { FC } from "react";
import { DialogContent, Divider, TextField, Typography } from "@mui/material";
import { FormikHandlers } from "formik";
import { LinkRow } from "../common/LinkRow";
import { SsoFormDialogHeader } from "../common/SsoFormDialogHeader";
import { SsoFormDialogStep } from "../common/SsoFormDialogStep";

interface AzureSsoFormDialogBodyProps {
  entityId: string;
  replyUrl: string;
  onChange: FormikHandlers["handleChange"];
}

export const AzureSsoFormDialogBody: FC<AzureSsoFormDialogBodyProps> = ({
  entityId,
  replyUrl,
  onChange,
}) => {
  const documentationHref =
    "https://docs.cloudchipr.com/docs/single-sign-on-sso-using-azure";

  return (
    <DialogContent dividers>
      <SsoFormDialogHeader
        title="To enable Microsoft as SSO Provider, please follow the steps below:"
        href={documentationHref}
      />

      <SsoFormDialogStep step={1} title="Basic SAML Configuration">
        <Typography variant="caption" color="text.secondary" mt={0.5}>
          In Basic SAML Configuration section, enter the "Identifier (Entity
          ID)" and "Reply URL (Assertion Consumer Service URL)”.
          <br /> Ensure that your SAML configuration is saved and active. This
          step is essential to generate the Federation Metadata XML.
        </Typography>
        <LinkRow
          mt={2}
          link={entityId}
          title="Identifier"
          subTitle="Entity ID"
        />

        <LinkRow
          mt={1}
          link={replyUrl}
          title="Reply URL"
          subTitle="Assertion Consumer Service URL"
        />
      </SsoFormDialogStep>

      <Divider sx={{ my: 4 }} />

      <SsoFormDialogStep step={2} title="Federation Metadata XML">
        <Typography variant="caption" color="text.secondary" mt={0.5}>
          Download and paste the content of Federation Metadata XML file into
          the box below.
        </Typography>
        <TextField
          fullWidth
          multiline
          name="metadata"
          rows={4}
          label="Federation Metadata XML"
          margin="normal"
          onChange={onChange}
        />
      </SsoFormDialogStep>
    </DialogContent>
  );
};
