import { FC } from "react";
import { Stack, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import { v4 as uuid } from "uuid";
import { reservedInstanceDrawerRecommendationsGridColumns } from "./ReservedInstanceDrawerRecommendationsGridColumns";
import { useGetUsersMeOrganisationsCurrentReservationsUtilizationRecommendationsQuery } from "../../../../../../../../services/cloudchipr.api";
import { CommitmentsDrawerContent } from "../../../../../common/components/drawer/CommitmentsDrawerContent";
import { DataGrid } from "../../../../../../../../storybook/data-grid/DataGrid";
import { StylesProps } from "../../../../../../../../storybook/data-grid/utils/types/prop-types";
import { useAppSelector } from "../../../../../../../../store/hooks";
import { selectedReservedInstancePlanUUIdSelector } from "../../../../../../../../store/commitments/selectors/reservations/details-drawer/reserved-instance/selectedReservedInstancePlanUUIdSelector";

export const ReservedInstanceDrawerRecommendationsSection: FC = () => {
  const reservationId = useAppSelector(
    selectedReservedInstancePlanUUIdSelector,
  );

  const { data } =
    useGetUsersMeOrganisationsCurrentReservationsUtilizationRecommendationsQuery(
      { reservationId: reservationId ?? "" },
      {
        skip: !reservationId,
        selectFromResult: ({ data }) => {
          return {
            data: data?.map((recommendation) => ({
              ...recommendation,
              id: uuid(),
            })),
          };
        },
      },
    );

  return (
    <CommitmentsDrawerContent title="Recommendations" icon="recommendation">
      {!data?.length ? (
        <Stack alignItems="center" p={3}>
          <Typography
            variant="subtitle1"
            fontWeight="medium"
            color="text.secondary"
          >
            No Recommendations
          </Typography>
        </Stack>
      ) : (
        <DataGrid
          enableStickyColumns
          columnSorting
          styles={styles}
          data={data ?? []}
          columns={reservedInstanceDrawerRecommendationsGridColumns}
        />
      )}
    </CommitmentsDrawerContent>
  );
};

const styles: StylesProps["styles"] = {
  table: {
    borderCollapse: "separate",
    tableLayout: "fixed",
    "& th": { py: 2, bgcolor: "grey.100" },
    "& th:first-of-type": { pl: 2 },
    "& td:first-of-type": { pl: 2 },
    "& tr:hover td": { bgcolor: grey[100] },
    "& tr td": { borderColor: grey[100], bgcolor: "white" },
  },

  tableContainer: {
    bgcolor: "white",
    borderRadius: 2,
    border: "1px solid",
    borderColor: "grey.300",
  },
};
