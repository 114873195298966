import { FC, ReactNode } from "react";
import { Stack, Typography } from "@mui/material";

interface AutomationDatesLabelWrapperProps {
  label: string;
  children: ReactNode;
}

export const AutomationDatesLabelWrapper: FC<
  AutomationDatesLabelWrapperProps
> = ({ children, label }) => {
  return (
    <Stack direction="row" alignItems="center" height={38}>
      <Typography variant="subtitle2" fontWeight="bold" width={60}>
        {label}
      </Typography>

      {children}
    </Stack>
  );
};
