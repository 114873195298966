import { offHoursDataSelector } from "../../../automations/selectros/off-hours/data/offHoursDataSelector";
import { RootState } from "../../../store";
import { OffHoursSchedule } from "../../../../services/cloudchipr.api";

export const offHoursByIdSelector = (
  state: RootState,
  id: string,
): OffHoursSchedule | undefined => {
  const offHours = offHoursDataSelector(state);

  return offHours?.find((offHour) => offHour.id === id);
};
