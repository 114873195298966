import { FC, useMemo } from "react";
import { Button, DialogActions } from "@mui/material";
import { useAppSelector } from "../../../../../../store/hooks";
import { getResourcesCleanActionTypes } from "../../../../../../utils/clean-options";
import { cleanV2ActionTypeSelector } from "../../../../../../store/clean-v2/selectors/store-selectors/cleanV2ActionTypeSelector";
import { cleanV2DetailsResourceTypeSelector } from "../../../../../../store/clean-v2/selectors/store-selectors/cleanV2DetailsResourceTypeSelector";

interface CleanV2DialogActionsProps {
  onCancel(): void;
  onClean(): void;
  disabled: boolean;
}

export const CleanV2DialogActions: FC<CleanV2DialogActionsProps> = ({
  onClean,
  onCancel,
  disabled,
}) => {
  const cleanActionType = useAppSelector(cleanV2ActionTypeSelector);
  const resourceType = useAppSelector(cleanV2DetailsResourceTypeSelector);

  const cleanActionButtonLabel = useMemo(() => {
    if (!resourceType) {
      return null;
    }
    const currentCleanAction = getResourcesCleanActionTypes(resourceType).find(
      (option) => option.type === cleanActionType,
    );

    return currentCleanAction ? currentCleanAction.label : "";
  }, [cleanActionType, resourceType]);

  if (!resourceType) {
    return null;
  }

  return (
    <DialogActions>
      <Button color="tertiary" onClick={onCancel}>
        Cancel
      </Button>
      <Button variant="contained" onClick={onClean} disabled={disabled}>
        {cleanActionButtonLabel}
      </Button>
    </DialogActions>
  );
};
