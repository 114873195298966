import { FC } from "react";
import PaidOutlinedIcon from "@mui/icons-material/PaidOutlined";
import { useLocation } from "react-router-dom";
import { NavItem } from "../nav-item/NavItem";
import { useAppSelector } from "../../../../store/hooks";
import { navigationCollapsedSelector } from "../../../../store/common/selectors/navigation/navigationCollapsedSelector";

export const BudgetsNavigation: FC = () => {
  const location = useLocation();

  const budgetsSelected = location.pathname.startsWith("/budgets");

  const navigationCollapsed = useAppSelector(navigationCollapsedSelector);

  return (
    <NavItem
      to="/budgets"
      primary="Budgets"
      icon={PaidOutlinedIcon}
      navCollapsed={navigationCollapsed}
      selected={budgetsSelected}
    />
  );
};
