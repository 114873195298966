import { FC } from "react";
import { useAppSelector } from "../../../../../../../../../../../store/hooks";
import { resourceExplorerDataGridDataTotalTrendSelector } from "../../../../../../../../../../../store/resource-explorer/selectors/current-resource-explorer/data-grid/resourceExplorerDataGridDataTotalTrendSelector";
import { TrendColumnHeaderCell } from "../common/TrendColumnHeaderCell";

export const ResourceExplorerTrendColumnHeaderCell: FC = () => {
  const trend = useAppSelector(resourceExplorerDataGridDataTotalTrendSelector);

  return <TrendColumnHeaderCell trend={trend} />;
};
