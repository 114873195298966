import {
  TargetState,
  TaskSource,
} from "../../../../../services/cloudchipr.api";

export const taskSourceLabels: Record<TaskSource, string> = {
  live_usage: "Live Usage",
};

export const taskTargetStateLabels: Record<TargetState, string> = {
  not_found: "Not Found",
  live: "Live",
};
