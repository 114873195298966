import { taskManagementTargetResourcesSelector } from "./taskManagementTargetResourcesSelector";
import { RootState } from "../../../../../store";
import {
  ResourceTargetRequest,
  ResourceType,
} from "../../../../../../services/cloudchipr.api";

export const taskManagementTargetResourcesForPayloadSelector = (
  state: RootState,
): ResourceTargetRequest["resources"] | undefined => {
  const resources = taskManagementTargetResourcesSelector(state);

  if (!resources) {
    return;
  }

  const groupedByResourceType = resources?.reduce(
    (acc, resource) => {
      const rt = resource.resource_type;

      if (!acc[rt]) {
        acc[rt] = [];
      }

      acc[rt].push(resource.id);

      return acc;
    },
    {} as Record<ResourceType, string[]>,
  );

  return Object.keys(groupedByResourceType).map((rt) => {
    return {
      resource_type: rt as ResourceType,
      ids: groupedByResourceType[rt as ResourceType],
    };
  });
};
