import { FC, useCallback, useState } from "react";
import {
  Box,
  Step,
  StepButton,
  StepContent,
  StepLabel,
  Stepper,
} from "@mui/material";
import { Step0 } from "./steps/components/step-0/Step0";
import { Step1 } from "./steps/components/step-1/Step1";
import { Step2 } from "./steps/components/step-2/Step2";
import { StepsProps } from "./steps/utils/types/types";
import { useAppSelector } from "../../../../../../store/hooks";
import { step0CompletedSelector } from "../../../../../../store/budgets/selectors/current-budget/forms/step0CompletedSelector";
import { step1CompletedSelector } from "../../../../../../store/budgets/selectors/current-budget/forms/step1CompletedSelector";

export const BudgetsCreateSteps: FC = () => {
  const firstCompleted = useAppSelector(step0CompletedSelector);
  const secondCompleted = useAppSelector(step1CompletedSelector);

  const stepsCompleted = [
    firstCompleted,
    secondCompleted,
    firstCompleted && secondCompleted,
  ];
  const stepsEnabled = [
    true,
    firstCompleted,
    secondCompleted && firstCompleted,
  ];

  const [activeStep, setActiveStep] = useState(0);

  const nextStepHandler = useCallback(() => {
    setActiveStep((s) => s + 1);
  }, []);

  const prevStepHandler = useCallback(() => {
    setActiveStep((s) => s - 1);
  }, []);

  return (
    <Stepper orientation="vertical" activeStep={activeStep} nonLinear>
      {steps.map((step, index) => {
        const StepComponent = step.component;

        return (
          <Step
            key={step.title}
            completed={stepsCompleted.at(index) && activeStep !== index}
          >
            <StepButton
              onClick={setActiveStep.bind(null, index)}
              disabled={!stepsEnabled.at(index)}
            >
              <StepLabel>{step.title}</StepLabel>
            </StepButton>

            <StepContent>
              <Box sx={{ px: 2, py: 2 }}>
                <StepComponent
                  onNext={nextStepHandler}
                  onPrevious={prevStepHandler}
                />
              </Box>
            </StepContent>
          </Step>
        );
      })}
    </Stepper>
  );
};

const steps: { title: string; component: FC<StepsProps> }[] = [
  {
    title: "Set Budget",
    component: Step0,
  },
  {
    title: "Set Alerts",
    component: Step1,
  },
  {
    title: "Preview",
    component: Step2,
  },
];
