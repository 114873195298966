import { resourceExplorerPathSelector } from "./resourceExplorerPathSelector";
import { RootState } from "../../../../store";

export const resourceExplorerPathLastItemFilterTreeSelector = (
  state: RootState,
) => {
  const path = resourceExplorerPathSelector(state);

  return path?.at(-1)?.filterTree;
};
