import { createAsyncThunk } from "@reduxjs/toolkit";
import { dismissRightsizingRecommendationThunk } from "./rightsizing/dismiss/dismissRightsizingRecommendationThunk";
import { dismissDailyOffHoursRecommendationThunk } from "./off-hours/dismiss/dismissDailyOffHoursRecommendationThunk";
import { dismissWeeklyOffHoursRecommendationThunk } from "./off-hours/dismiss/dismissWeeklyOffHoursRecommendationThunk";
import { refetchDataAfterActionThunk } from "./refetchDataAfterActionThunk";
import { RootState } from "../../store";
import { selectedActiveRecommendationIdsByTypesSelector } from "../selectors/selected-ids/selectedActiveRecommendationIdsByTypesSelector";

export const recommendationDismissThunk = createAsyncThunk(
  "recommendations/recommendationDismissThunk",
  async (_: void, { dispatch, getState }) => {
    const state = getState() as RootState;

    const ids = selectedActiveRecommendationIdsByTypesSelector(state);

    const computeDaly = ids.computeDaily;
    const computeWeekly = ids.computeWeekly;

    const dbDaily = ids.dbDaily;
    const dbWeekly = ids.dbWeekly;

    if (ids.rightsizing.length) {
      await dispatch(dismissRightsizingRecommendationThunk(ids.rightsizing));
    }

    if (computeDaly.length || dbDaily.length) {
      await dispatch(
        dismissDailyOffHoursRecommendationThunk([...computeDaly, ...dbDaily]),
      );
    }

    if (computeWeekly.length || dbWeekly.length) {
      await dispatch(
        dismissWeeklyOffHoursRecommendationThunk([
          ...computeWeekly,
          ...dbWeekly,
        ]),
      );
    }

    await dispatch(
      refetchDataAfterActionThunk({
        rightsizing: !!ids.rightsizing.length,
        computeDaly: !!computeDaly.length,
        computeWeekly: !!computeWeekly.length,
        dbWeekly: !!dbWeekly.length,
        dbDaily: !!dbDaily.length,
      }),
    );
  },
);
