import { Account, ProviderType } from "../../../../services/cloudchipr.api";

export const getAccountProviderDetailsOrgId = (
  providerDetails: Account["provider_details"],
  provider: ProviderType,
) => {
  if ("aws" in providerDetails && provider === "aws") {
    return providerDetails.aws?.organisation_id ?? undefined;
  }
};
