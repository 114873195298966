import { commitmentUtilizationWidgetSetupSelector } from "./commitmentUtilizationWidgetSetupSelector";
import { CommitmentUtilizationWidgetSetupType } from "../../../utils/types/setups/commitmentUtilizationWidgetSetupType";
import { RootState } from "../../../../store";

export const commitmentUtilizationSetupPropertyByKeySelector =
  (key: keyof CommitmentUtilizationWidgetSetupType) =>
  (state: RootState): any => {
    const setup = commitmentUtilizationWidgetSetupSelector(state);

    if (!setup) {
      return null;
    }

    return setup[key];
  };
