import { createDraftSafeSelector } from "@reduxjs/toolkit";
import { categoriesDataSelector } from "./categoriesDataSelector";

export const uniqueCategoryGroupNamesSelector = createDraftSafeSelector(
  [categoriesDataSelector],
  (categories): string[] => {
    const categoriesSet = new Set<string>();

    categories?.forEach(({ name }) => categoriesSet.add(name));

    return Array.from(categoriesSet);
  },
);
