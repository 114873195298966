export type AutomationSectionName =
  | "name"
  | "environment"
  | "notification"
  | "filter";

const automationsSectionsIdsWithKeywords: Record<
  AutomationSectionName,
  string[]
> = {
  name: ["name"],
  environment: ["accountIds", "regions"],
  notification: ["gracePeriod", "notifications", "emails"],
  filter: ["filter"],
};

export const getAutomationSectionIdBySectionName = (
  name?: AutomationSectionName,
) => {
  if (!name || !automationsSectionsIdsWithKeywords[name]) {
    return;
  }

  return `automation-${name}-section-id`;
};

export const getAutomationSectionIdByKeyword = (keyword: string) => {
  const foundData = Object.entries(automationsSectionsIdsWithKeywords).find(
    ([_, keywords]) => {
      return keywords.includes(keyword);
    },
  );

  const fountName = foundData?.at(0) as AutomationSectionName;

  return getAutomationSectionIdBySectionName(fountName);
};
