import { FC } from "react";
import { Divider, Stack } from "@mui/material";
import { IntegrationUtilizationAlertCommitmentsUtilizationEmailHeader } from "../../message/commitments-utilization/IntegrationUtilizationAlertCommitmentsUtilizationEmailHeader";
import { IntegrationUtilizationAlertCommitmentsUtilizationSlackHeader } from "../../message/commitments-utilization/IntegrationUtilizationAlertCommitmentsUtilizationSlackHeader";
import { IntegrationUtilizationAlertCommitmentsUtilizationDetails } from "../../message/commitments-utilization/IntegrationUtilizationAlertCommitmentsUtilizationDetails";
import { CreateIntegrationMessageEditor } from "../../../../../../../../../common/integration-dialogs/components/common/CreateIntegrationMessageEditor";

interface IntegrationUtilizationAlertCommitmentsUtilizationDialogContentProps {
  customMessage: string;
  onCustomMessageChange(message: string): void;
  type: "email" | "slack";
}

export const IntegrationUtilizationAlertCommitmentsUtilizationDialogContent: FC<
  IntegrationUtilizationAlertCommitmentsUtilizationDialogContentProps
> = ({ type, onCustomMessageChange, customMessage }) => {
  return (
    <Stack spacing={1}>
      {type === "email" && (
        <IntegrationUtilizationAlertCommitmentsUtilizationEmailHeader divider />
      )}

      {type === "slack" && (
        <IntegrationUtilizationAlertCommitmentsUtilizationSlackHeader />
      )}

      <IntegrationUtilizationAlertCommitmentsUtilizationDetails />

      <Divider />

      <CreateIntegrationMessageEditor
        value={customMessage ?? ""}
        onChange={onCustomMessageChange}
        placeholder="Write a custom message..."
      />
    </Stack>
  );
};
