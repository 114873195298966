import { FC, Fragment, useCallback, useMemo } from "react";
import { Stack, Typography } from "@mui/material";
import Chip from "@mui/material/Chip";
import { SchedulePreviewTable } from "./SchedulePreviewTable";
import { SchedulePreviewSectionTitle } from "./SchedulePreviewSectionTitle";
import { SchedulePreviewFoundResources } from "./SchedulePreviewFoundResources";
import {
  ResourceType,
  ResourceFiltersWithAction,
  ScheduleStatus,
} from "../../../../../../../services/cloudchipr.api";
import { FiltersDisabledView } from "../../../../../common/filters/filters-disabled-view/FiltersDisabledView";
import { useAppSelector } from "../../../../../../../store/hooks";
import { scheduleResourcesCountSelector } from "../../../../../../../store/automations/selectros/workflow/resources-preview/scheduleResourcesCountSelector";
import { getResourceTypeName } from "../../../../../../../utils/helpers/resources/getResourceTypeName";
import {
  getActionValue,
  getCleanActionLabel,
} from "../../../../../../../utils/clean-options";

interface SchedulePreviewResourcesProps {
  hideTitle?: boolean;
  showAction?: boolean;
  filters: ResourceFiltersWithAction[];
  accountIds: string[];
  scheduleAction: string | null;
  regions: string[];
  name: string;
  scheduleId: string;
  status: ScheduleStatus;
  gracePeriodValue?: number;
  nextRuns?: { title: string; date: string; utc?: string }[];
  frequency?: { title: string; label: string; tooltip: string }[];
}

export const SchedulePreviewResources: FC<SchedulePreviewResourcesProps> = ({
  filters,
  accountIds,
  name,
  status,
  regions,
  gracePeriodValue,
  nextRuns,
  frequency,
  scheduleAction,
  scheduleId,
  hideTitle,
  showAction,
}) => {
  const resourcesCountSelector = useCallback(
    (state: any) => {
      return filters.reduce(
        (acc, { filter }) => {
          acc[filter.type] = scheduleResourcesCountSelector(
            state,
            filter.type,
            scheduleId,
          );

          return acc;
        },
        {} as Record<ResourceType, number>,
      );
    },
    [filters, scheduleId],
  );

  const resourcesCount = useAppSelector(resourcesCountSelector);

  const allResourcesCount = useMemo(() => {
    return Object.values(resourcesCount).reduce((sum, num) => sum + num, 0);
  }, [resourcesCount]);

  const data = useMemo(() => {
    return filters.map(({ filter, action, snapshot }, index) => {
      const label = <FiltersDisabledView filter={filter} />;

      const actionLabel = getCleanActionLabel(
        getActionValue(action, snapshot),
        filter.type,
      );

      return {
        id: `${index}`,
        title: (
          <Stack alignItems="start">
            <Typography variant="body2" fontWeight="medium">
              {getResourceTypeName(filter.type, {
                type: "long",
              })}
            </Typography>
            <Typography variant="caption" color="text.secondary">
              {resourcesCount[filter.type]} resources found
            </Typography>

            {showAction &&
              (actionLabel ? (
                <Chip size="small" variant="outlined" label={actionLabel} />
              ) : (
                <Typography variant="caption">No Action Available</Typography>
              ))}
          </Stack>
        ),
        label,
      };
    });
  }, [filters, showAction, resourcesCount]);

  return (
    <Fragment>
      {!hideTitle && <SchedulePreviewSectionTitle title="Selected Services" />}

      <SchedulePreviewFoundResources
        scheduleAction={scheduleAction}
        scheduleId={scheduleId}
        nextRuns={nextRuns}
        frequency={frequency}
        gracePeriodValue={gracePeriodValue}
        filters={filters}
        regions={regions}
        drawerTitle={name}
        status={status}
        accountIds={accountIds}
        count={allResourcesCount}
      />

      <SchedulePreviewTable data={data} />
    </Fragment>
  );
};
