import { FC } from "react";
import { Checkbox, FormControlLabel, Stack, Typography } from "@mui/material";

interface DigestCheckboxProps {
  label: string;
  description: string;
  checked: boolean;
  toggleDigest(): void;
  disabled: boolean;
}

export const DigestOption: FC<DigestCheckboxProps> = ({
  toggleDigest,
  label,
  description,
  disabled,
  checked,
}) => {
  return (
    <FormControlLabel
      disableTypography
      disabled={disabled}
      onChange={toggleDigest}
      sx={{ width: "fit-content" }}
      control={<Checkbox size="medium" checked={checked} />}
      label={
        <Stack spacing={0.5} pl={1}>
          <Typography variant="body1" fontWeight="medium">
            {label}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {description}
          </Typography>
        </Stack>
      }
    />
  );
};
