import { commitmentUtilizationWidgetSetupEndpointSelector } from "./commitmentUtilizationWidgetSetupEndpointSelector";
import { RootState } from "../../../../../store";
import { GetUsersMeOrganisationsCurrentWidgetsCommitmentsUtilizationApiResponse } from "../../../../../../services/cloudchipr.api";

export const commitmentUtilizationWidgetSetupDataSelector = (
  state: RootState,
):
  | GetUsersMeOrganisationsCurrentWidgetsCommitmentsUtilizationApiResponse
  | undefined => {
  return commitmentUtilizationWidgetSetupEndpointSelector(state)?.data;
};
