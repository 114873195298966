import { FC, Fragment } from "react";
import { IconButton, Popover } from "@mui/material";
import { ScheduledMoreNextRunsPopoverContent } from "./ScheduledMoreNextRunsPopoverContent";
import {
  FutureRunDate,
  NullableDate,
} from "../../../../../../services/cloudchipr.api";
import { useMenuHook } from "../../../../../../utils/hooks/useMenu.hook";
import DetailsSvgIcon from "../../../../../../assets/images/icons/DetailsSvgIcon";

interface ScheduledMoreNextRunsProps {
  nextRun?: NullableDate;
  timeZone: string;
  nextRunList?: FutureRunDate[] | null;
  disabled?: boolean;
}

export const ScheduledMoreNextRuns: FC<ScheduledMoreNextRunsProps> = ({
  nextRun,
  nextRunList,
  timeZone,
  disabled,
}) => {
  const { anchor, open, openMenu, closeMenu } = useMenuHook();

  return (
    <Fragment>
      <IconButton disabled={disabled} size="small" onClick={openMenu}>
        <DetailsSvgIcon sx={{ fontSize: 14 }} color="primary" />
      </IconButton>

      <Popover
        open={open}
        anchorEl={anchor}
        onClose={closeMenu}
        transformOrigin={{
          horizontal: "center",
          vertical: "top",
        }}
        anchorOrigin={{
          horizontal: "center",
          vertical: "bottom",
        }}
        PaperProps={paperProps}
      >
        <ScheduledMoreNextRunsPopoverContent
          nextRunList={nextRunList ?? []}
          nextRun={nextRun}
          timeZone={timeZone}
        />
      </Popover>
    </Fragment>
  );
};

const paperProps = {
  sx: {
    maxHeight: 310,
    minWidth: 260,
    maxWidth: 400,
  },
};
