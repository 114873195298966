import { FC } from "react";

import { Stack, Tooltip, Typography } from "@mui/material";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";

export const EMSCell: FC = () => {
  return (
    <Stack direction="row" spacing={1} flex={1} justifyContent="flex-end">
      <Typography variant="body2" fontWeight="medium">
        EMS
      </Typography>
      <Tooltip arrow title="Estimated Monthly Savings" placement="top">
        <HelpOutlineIcon
          fontSize="small"
          sx={{ verticalAlign: "middle", ml: 1, color: "info.main" }}
        />
      </Tooltip>
    </Stack>
  );
};
