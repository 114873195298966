import { FC } from "react";
import { useAppSelector } from "../../../../../../../../../../../store/hooks";
import { resourceExplorerDataGridDataTotalCostDateDetailsSelector } from "../../../../../../../../../../../store/resource-explorer/selectors/current-resource-explorer/data-grid/resourceExplorerDataGridDataTotalCostDateDetailsSelector";
import { resourceExplorerWidgetTotalCostSelector } from "../../../../../../../../../../../store/resource-explorer/selectors/current-resource-explorer/resource-explorer-widget/resourceExplorerWidgetTotalCostSelector";
import { TotalCostColumnHeaderCell } from "../common/TotalCostColumnHeaderCell";

export const ResourceExplorerTotalCostColumnHeaderCell: FC = () => {
  const totalCost = useAppSelector(resourceExplorerWidgetTotalCostSelector);
  const dateDetails = useAppSelector(
    resourceExplorerDataGridDataTotalCostDateDetailsSelector,
  );

  return (
    <TotalCostColumnHeaderCell
      totalCost={totalCost}
      dateDetails={dateDetails}
    />
  );
};
