import { FC, memo } from "react";
import { Stack, Typography } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { blue } from "@mui/material/colors";
import { CleanV2ResourceGrid } from "./CleanV2ResourceGrid";
import { useAppSelector } from "../../../../../../../../store/hooks";
import { cleanV2ExcludedResourcesSelector } from "../../../../../../../../store/clean-v2/selectors/cleanV2ExcludedResourcesSelector";
import { cleanV2DetailsResourceTypeSelector } from "../../../../../../../../store/clean-v2/selectors/store-selectors/cleanV2DetailsResourceTypeSelector";
export const CleanV2ExcludedResourcesInfo: FC = memo(() => {
  const data = useAppSelector(cleanV2ExcludedResourcesSelector);
  const resourceType = useAppSelector(cleanV2DetailsResourceTypeSelector);

  if (!data?.length || !resourceType) {
    return null;
  }
  return (
    <Stack
      sx={{
        bgcolor: blue["50"],
      }}
      px={2}
      py={1.5}
      spacing={1.5}
    >
      <Stack direction="row" spacing={1.5}>
        <InfoOutlinedIcon
          fontSize="small"
          sx={{
            color: (theme) => theme.palette.info.main,
          }}
        />
        <Typography variant="subtitle2" fontWeight="medium">
          Actions cannot be performed on protected resources or resources in
          Read-Only accounts.
        </Typography>
      </Stack>
      <CleanV2ResourceGrid resourceType={resourceType} data={data} />
    </Stack>
  );
});
