import { FC, Fragment } from "react";
import numeral from "numeral";

interface ByteToGibCellProps {
  data: number;
}

export const ByteToGibCell: FC<ByteToGibCellProps> = ({ data }) => {
  return <Fragment>{numeral(data).format("0.00 b")}</Fragment>;
};
