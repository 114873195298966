import { FC } from "react";
import { Step, StepLabel, Stepper, Typography } from "@mui/material";
import { indigo } from "@mui/material/colors";
import { ProviderType } from "../../../../../../services/cloudchipr.api";

interface AccountSetupStepperProps {
  currentStep: number;
  provider: ProviderType;
}

export const AccountSetupStepper: FC<AccountSetupStepperProps> = ({
  currentStep,
  provider,
}) => {
  const steps = stepsByProvider.get(provider);

  return (
    <Stepper
      activeStep={currentStep}
      sx={{ pl: 2, pr: 3, py: 3, bgcolor: "grey.50" }}
    >
      {steps?.map((label: string) => (
        <Step
          key={label}
          sx={{
            "& .Mui-completed": {
              fill: indigo["500"],
            },
          }}
        >
          <StepLabel>
            <Typography variant="body2">{label}</Typography>
          </StepLabel>
        </Step>
      ))}
    </Stepper>
  );
};

const stepsByProvider = new Map([
  ["aws", ["Access", "Account Setup", "Collecting Information"]],
  ["gcp", ["Access", "Setup", "Collecting Information"]],
  [
    "azure",
    ["Access", "Account Details", "Assign Roles", "Collecting Information"],
  ],
]);
