import { FC } from "react";
import { Stack } from "@mui/material";
import { CommitmentsGaugeChartCard } from "../../../../common/components/chart/gauge-chart/CommitmentsGaugeChartCard";
import { useAppSelector } from "../../../../../../../store/hooks";
import { savingsPlansGranularDataTotalCommitmentSelector } from "../../../../../../../store/commitments/selectors/coverage-and-utilization/data/granular/totals/savingsPlansGranularDataTotalCommitmentSelector";
import { savingsPlansGranularDataTotalCoverageSelector } from "../../../../../../../store/commitments/selectors/coverage-and-utilization/data/granular/totals/savingsPlansGranularDataTotalCoverageSelector";
import { savingsPlansGranularDataTotalUtilizationSelector } from "../../../../../../../store/commitments/selectors/coverage-and-utilization/data/granular/totals/savingsPlansGranularDataTotalUtilizationSelector";
import { savingsPlansGranularDataUncoveredUsageSelector } from "../../../../../../../store/commitments/selectors/coverage-and-utilization/data/granular/totals/savingsPlansGranularDataUncoveredUsageSelector";
import { savingsPlansForGranularLoadingSelector } from "../../../../../../../store/commitments/selectors/coverage-and-utilization/data/loading/savingsPlansForGranularLoadingSelector";
import { commitmentsChartTypeSelector } from "../../../../../../../store/commitments/selectors/common/view-options/commitmentsChartTypeSelector";
import { CommitmentsChartTypeSelect } from "../../../../common/components/chart/CommitmentsChartTypeSelect";

export const UtilizationAndCoverageGaugeChart: FC = () => {
  const totalCommitment = useAppSelector(
    savingsPlansGranularDataTotalCommitmentSelector,
  );
  const totalCoverage = useAppSelector(
    savingsPlansGranularDataTotalCoverageSelector,
  );
  const totalUtilization = useAppSelector(
    savingsPlansGranularDataTotalUtilizationSelector,
  );
  const uncoveredUsage = useAppSelector(
    savingsPlansGranularDataUncoveredUsageSelector,
  );
  const loading = useAppSelector(savingsPlansForGranularLoadingSelector);

  const contentView = useAppSelector(commitmentsChartTypeSelector);

  if (contentView !== "gauge") {
    return null;
  }

  return (
    <Stack spacing={1}>
      <CommitmentsChartTypeSelect />

      <Stack direction="row" spacing={2}>
        <CommitmentsGaugeChartCard
          title="Utilization"
          loading={loading}
          primary={{
            label: "Utilization",
            value: totalUtilization?.amount,
            percent: totalUtilization?.percent,
          }}
          secondary={{
            label: "Commitment",
            value: totalCommitment,
          }}
        />

        <CommitmentsGaugeChartCard
          title="Coverage"
          loading={loading}
          primary={{
            label: "Covered",
            value: totalCoverage?.amount,
            percent: totalCoverage?.percent,
          }}
          secondary={{
            label: "Not Covered (On-Demand)",
            value: uncoveredUsage,
          }}
        />
      </Stack>
    </Stack>
  );
};
