import { allowedFiltersSelector } from "./allowedFiltersSelector";
import { RootState } from "../../store";
import { Filters } from "../../../services/cloudchipr.api";

export const allResourceTypesAllowedFiltersSelector = (
  state: RootState,
): Filters => {
  const allowedFilters = allowedFiltersSelector(state);

  return findChildFilters(allowedFilters);
};

const findChildFilters = (allowedFilters: Filters) => {
  const childFilters: Filters[] = [];

  const noChildFilters = allowedFilters.every(
    (filter) => !filter.child_filters,
  );

  if (noChildFilters) {
    return allowedFilters;
  }

  allowedFilters.forEach((filter) => {
    if (filter.child_filters) {
      childFilters.push(filter.child_filters);

      filter.child_filters.forEach((childFilter) => {
        if (childFilter.child_filters) {
          childFilters.push(findChildFilters(childFilter.child_filters));
        }
      });
    }
  });

  return [...childFilters.flat(), ...allowedFilters];
};
