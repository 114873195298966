export const createReportFixedCacheKey =
  "postUsersMeOrganisationsCurrentReports-fixed-cache-key";
export const editReportFixedCacheKey =
  "putUsersMeOrganisationsCurrentReportsByReportId-fixed-cache-key";
export const getToggleReportStatusLoadingFixedCacheKey = (reportId: string) =>
  `patchUsersMeOrganisationsCurrentReportsByReportId-fixed-cache-key-${reportId}`;
export const deleteReportFixedCacheKey =
  "deleteUsersMeOrganisationsCurrentReportsByReportId-fixed-cache-key";
export const sendSingleReportFixedCacheKey =
  "postUsersMeOrganisationsCurrentReportsOneTime-fixed-cache-key";
