import { FC } from "react";
import { Tooltip, TooltipProps } from "@mui/material";
import { ResourceExplorerBudgetTooltipContent } from "./ResourceExplorerBudgetTooltipContent";
import { BudgetPeriod } from "../../../../../../../../../../services/cloudchipr.api";

interface ResourceExplorerBudgetTooltipProps
  extends Omit<TooltipProps, "title"> {
  name?: string;
  progress: number;
  amount: number;
  startDate?: string;
  period: BudgetPeriod;
}

export const ResourceExplorerBudgetTooltip: FC<
  ResourceExplorerBudgetTooltipProps
> = ({
  children,
  progress,
  name,
  amount,
  startDate,
  period,
  placement = "right",
}) => {
  if (!children) {
    return null;
  }

  return (
    <Tooltip
      title={
        <ResourceExplorerBudgetTooltipContent
          name={name}
          amount={amount}
          progress={progress}
          startDate={startDate}
          period={period}
        />
      }
      arrow
      placement={placement}
      componentsProps={{
        tooltip: { sx: customTooltipStyles },
      }}
    >
      <span>{children}</span>
    </Tooltip>
  );
};
const customTooltipStyles = {
  color: "text.primary",
  bgcolor: "background.paper",
  "& .MuiTooltip-arrow": {
    color: "background.paper",
  },
  boxShadow: 7,
  p: 0,
};
