import { FC, Fragment } from "react";
import { Stack, Typography } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

interface SubscriptionPlanOptionsProps {
  options: {
    title?: string;
    availability?: boolean;
  }[];
}

export const SubscriptionPlanOptions: FC<SubscriptionPlanOptionsProps> = ({
  options,
}) => {
  return (
    <Stack gap={1.5} mt={2}>
      <Typography variant="body2" fontWeight="bold" color="text.secondary">
        This includes:
      </Typography>

      {options.map((f) => (
        <Stack direction="row" alignItems="center" key={f.title} gap={1}>
          {f.availability && (
            <Fragment>
              <CheckCircleIcon sx={{ fontSize: 14, color: "success.light" }} />
              <Typography color="text.secondary" variant="body2">
                {f.title}
              </Typography>
            </Fragment>
          )}
        </Stack>
      ))}
    </Stack>
  );
};
