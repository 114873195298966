import { createDraftSafeSelector } from "@reduxjs/toolkit";
import { currentBudgetSelector } from "../currentBudgetSelector";
import { Step0InitialValues } from "../../../../../components/pages/budgets/components/budget-create/steps/steps/utils/types/formInitialValues";

export const step0FormDataSelector = createDraftSafeSelector(
  [currentBudgetSelector],
  (budget): Step0InitialValues | null => {
    if (!budget) {
      return null;
    }

    return {
      name: budget.body.name,
      viewId: budget["view-id"],
      period: budget.body.period,
      startDate: budget.body.start_date,
      amount: budget.body.amount || "",
    };
  },
);
