import { FC } from "react";
import { Button } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { useAppAbility } from "../../../../../../../services/permissions";
import { ProviderAccessType } from "../../../../../../../services/cloudchipr.api";

interface OffHoursRecommendationCreateButtonProps {
  recommendationId: string;
  providerAccessType?: ProviderAccessType;
}

export const OffHoursRecommendationCreateButton: FC<
  OffHoursRecommendationCreateButtonProps
> = ({ recommendationId, providerAccessType }) => {
  const { cannot } = useAppAbility();
  const canNotCreateSchedule = cannot("create", "schedule");

  return (
    <Button
      variant="outlined"
      color="primary"
      size="small"
      component={RouterLink}
      to={`/automations/off-hours/create?recommendationId=${recommendationId}`}
      disabled={canNotCreateSchedule || providerAccessType === "read"}
    >
      Create
    </Button>
  );
};
