import { FC, Fragment } from "react";
import { Alert, Box, Typography } from "@mui/material";
import LightbulbIcon from "@mui/icons-material/Lightbulb";
import moment from "moment";
import {
  dateFromUtcByTimeZone,
  formatDate,
} from "../../../../../../../../utils/helpers/date-time/datetime-format";
import { TooltipText } from "../../../../../../../common/TooltipText";
import { ResourceType } from "../../../../../../../../services/cloudchipr.api";
import { MIN_CPU_USAGE } from "../../../utils/constants/constants";
import { getResourceTypeName } from "../../../../../../../../utils/helpers/resources/getResourceTypeName";

interface SubRowInfoSectionProps {
  name: string;
  fromHour: number;
  toHour: number;
  fromToDiff: number;
  withDbConnections: boolean;
  fromDay?: string;
  toDay?: string;
  type: ResourceType;
  frequency: "daily" | "weekly";
}

export const SubRowInfoSection: FC<SubRowInfoSectionProps> = ({
  fromHour,
  toHour,
  name,
  fromToDiff,
  fromDay,
  toDay,
  type,
  withDbConnections,
  frequency,
}) => {
  const localDateFrom = dateFromUtcByTimeZone(
    moment().set({ hours: fromHour, minutes: 0 }).format(),
  );
  const localDateTo = dateFromUtcByTimeZone(
    moment().set({ hours: toHour, minutes: 0 }).format(),
  );

  const isNextDay = fromHour > toHour;

  const fromTime = localDateFrom && formatDate(localDateFrom, { type: "time" });
  const toTime = localDateTo && formatDate(localDateTo, { type: "time" });

  const fromJSX = (
    <TooltipText
      color="inherit"
      fontWeight="bold"
      tooltipTitle={`${formatDate(
        moment().set({ hours: fromHour, minutes: 0 }).format(),
        {
          type: "time",
        },
      )} in UTC time`}
    >
      {fromTime}
    </TooltipText>
  );

  const toJSX = (
    <TooltipText
      color="inherit"
      fontWeight="bold"
      tooltipTitle={`${formatDate(
        moment().set({ hours: toHour, minutes: 0 }).format(),
        {
          type: "time",
        },
      )} in UTC time`}
    >
      {toTime}
    </TooltipText>
  );

  const withConnectionsText = withDbConnections
    ? ", number of connections is less than 1 "
    : " ";

  return (
    <Box pl={6} my={4}>
      <Typography variant="body1" fontWeight="medium">
        {name} is defined as constantly underutilised
      </Typography>

      <Typography variant="body2">
        {fromDay && toDay ? (
          <Fragment>
            The Maximum CPU utilization of this{" "}
            {getResourceTypeName(type, { singular: true, type: "long" })} falls
            below {MIN_CPU_USAGE}%{withConnectionsText}for a minimum of{" "}
            {fromToDiff} hours every week, occurring between{" "}
            <Typography
              component="span"
              variant="inherit"
              textTransform="capitalize"
            >
              {fromDay}
            </Typography>{" "}
            at {fromJSX} and{" "}
            <Typography
              component="span"
              variant="inherit"
              textTransform="capitalize"
            >
              {toDay}
            </Typography>{" "}
            at {toJSX}.
          </Fragment>
        ) : (
          <Fragment>
            The Maximum CPU utilization of this{" "}
            {getResourceTypeName(type, { singular: true, type: "long" })} falls
            below {MIN_CPU_USAGE}%{withConnectionsText}for a minimum of{" "}
            {fromToDiff} hours every day of the week, occurring between{" "}
            {fromJSX} and {toJSX}
            {isNextDay ? " next day" : ""}.
          </Fragment>
        )}
      </Typography>

      <Alert
        sx={{ mt: 2 }}
        variant="outlined"
        icon={<LightbulbIcon sx={{ color: "success", mr: 2 }} />}
        severity="success"
      >
        <Typography variant="body1" fontWeight="medium">
          Cloudchipr Recommends
        </Typography>
        <Typography variant="body2">
          {fromDay && toDay ? (
            <Fragment>
              Schedule{" "}
              <Typography
                component="span"
                variant="inherit"
                fontWeight="bold"
                textTransform="capitalize"
              >
                {frequency}
              </Typography>{" "}
              Off Hours for this resource to be stopped every{" "}
              <Typography component="span" variant="inherit" fontWeight="bold">
                {" "}
                <Typography
                  component="span"
                  variant="inherit"
                  textTransform="capitalize"
                >
                  {fromDay}
                </Typography>{" "}
                at {fromJSX} and started every{" "}
                <Typography
                  component="span"
                  variant="inherit"
                  textTransform="capitalize"
                >
                  {toDay}
                </Typography>{" "}
                at {toJSX}.
              </Typography>
            </Fragment>
          ) : (
            <Fragment>
              Schedule{" "}
              <Typography
                component="span"
                variant="inherit"
                fontWeight="bold"
                textTransform="capitalize"
              >
                {frequency}
              </Typography>{" "}
              Off Hours for this resource to be stopped every day at {fromJSX}{" "}
              <Typography component="span" variant="inherit" fontWeight="bold">
                and started at {toJSX}
                {isNextDay ? " next day" : ""}.
              </Typography>
            </Fragment>
          )}
        </Typography>
      </Alert>
    </Box>
  );
};
