import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { FC, useCallback } from "react";
import { TagFilter } from "../utils/types";
import { tagFilterOperators } from "../utils/constants";

interface TagFilterDropdownSelectOperatorProps {
  onChange(value: string): void;
  value?: TagFilter["operator"];
}
export const TagFilterDropdownSelectOperator: FC<
  TagFilterDropdownSelectOperatorProps
> = ({ onChange, value }) => {
  const handleChange = useCallback(
    (event: any) => {
      onChange(event.target.value);
    },
    [onChange],
  );

  return (
    <FormControl fullWidth size="xsmall">
      <InputLabel>Operator</InputLabel>
      <Select value={value ?? "in"} label="Operator" onChange={handleChange}>
        {tagFilterOperators.map((operator) => {
          return (
            <MenuItem key={operator.value} value={operator.value}>
              {operator.label}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};
