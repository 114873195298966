import { FC } from "react";
import { Stack } from "@mui/material";
import { WorkflowScheduler } from "./WorkflowScheduler";
import { WorkflowNextRuns } from "./WorkflowNextRuns";
import { AutomationFrequency } from "../../../../common/components/form/schedule-content/AutomationFrequency";
import { AutomationStartDateSelection } from "../../../../common/components/form/schedule-content/dates/AutomationStartDateSelection";
import { AutomationEndDateSelection } from "../../../../common/components/form/schedule-content/dates/AutomationEndDateSelection";
import { AutomationFrequencyType } from "../../../../../../../store/automations/utils/types/common";

export const WorkflowScheduleContent: FC = () => {
  return (
    <Stack spacing={2}>
      <AutomationFrequency frequencies={frequencies} />

      <WorkflowScheduler />

      <AutomationStartDateSelection />
      <AutomationEndDateSelection />

      <WorkflowNextRuns asSeparateSection showTimeZone />
    </Stack>
  );
};

const frequencies: AutomationFrequencyType[] = [
  "hourly",
  "daily",
  "weekly",
  "once",
];
