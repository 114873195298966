import { Permission } from "../../../../../services/cloudchipr.api";
import { PermissionWithSelectedState } from "../../../utils/types/common";

export const applyRolePermissionsToPermissionsList = (
  permissionsList: Permission[],
  appliedPermissions: Permission[],
): PermissionWithSelectedState[] => {
  const appliedPermissionNames = new Set(
    appliedPermissions.map((perm) => perm.name),
  );

  return permissionsList.map((item) => ({
    ...item,
    selected: appliedPermissionNames.has(item.name),
  }));
};
