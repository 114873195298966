import { coverageGroupedByCommitmentColumns } from "./coverage/coverageGroupedByCommitmentColumns";
import { coverageGroupedByResourceTypeColumns } from "./coverage/coverageGroupedByResourceTypeColumns";
import { savingsPlanGroupedByResourceTypeColumns } from "./savings-plan/savingsPlanGroupedByResourceTypeColumns";
import { coverageGroupedByAccountColumns } from "./coverage/coverageGroupedByAccountColumns";
import { savingsPlanGroupedByAccountColumns } from "./savings-plan/savingsPlanGroupedByAccountColumns";
import { ColumnSetupType } from "../../../../../../../../../storybook/data-grid/utils/types/types";
import { SavingsPlanCoverageDataWithId } from "../../../../../../common/utils/types";
import { SavingsPlanCoverageGrouping } from "../../../../../../../../../services/cloudchipr.api";
import { DataViewTab } from "../../../../../../../../../store/commitments/utils/types/types";

type Columns = Partial<
  Record<
    SavingsPlanCoverageGrouping,
    ColumnSetupType<SavingsPlanCoverageDataWithId>[]
  >
>;

export const utilizationAndCoverageDrawerDataGridColumns: Partial<
  Record<DataViewTab, Columns>
> = {
  coverage: {
    account: coverageGroupedByAccountColumns,
    commitment: coverageGroupedByCommitmentColumns,
    resource_type: coverageGroupedByResourceTypeColumns,
  },
  commitment: {
    account: savingsPlanGroupedByAccountColumns,
    resource_type: savingsPlanGroupedByResourceTypeColumns,
  },
};
