import { currentEditingWorkflowSelector } from "./currentEditingWorkflowSelector";
import { automationSliceSelector } from "../../../common/automationSliceSelector";
import { RootState } from "../../../../../store";
import { Schedule } from "../../../../../../services/cloudchipr.api";
import { isDeepEqual } from "../../../../../../utils/is-deep-equal";
import { changesExistOnAutomation } from "../../../../utils/helpers/changesExistOnAutomation";

export const changesExistOnCurrentEditWorkflowSelector = (state: RootState) => {
  const original: Schedule | undefined =
    currentEditingWorkflowSelector(state)?.data;
  const slice = automationSliceSelector(state);
  const automationData = slice.data;
  const workflowData = slice.workflowSpecific;

  if (!original || !workflowData) {
    return false;
  }

  const automationHasChanges = changesExistOnAutomation(
    automationData,
    original,
  );

  const gracePeriodIsNotEqual = !isDeepEqual(
    original.grace_period ?? {},
    workflowData.gracePeriod ?? {},
  );

  return (
    automationHasChanges ||
    gracePeriodIsNotEqual ||
    original.action !== workflowData.action ||
    original.is_snoozed !== workflowData?.snoozed
  );
};
