import { FC, useMemo } from "react";
import { Button, Dialog, DialogActions, DialogContent } from "@mui/material";
import { FilterTemplatesFiltersDialogItem } from "./FilterTemplatesFiltersDialogItem";
import { DialogTitleClosable } from "../../../../../../common/dialog-utils/DialogTitleClosable";
import {
  FilterSet,
  ProviderType,
  ResourceType,
} from "../../../../../../../services/cloudchipr.api";
import { useToggledResourceTypes } from "../../../../utils/hooks/useToggledResources";
import { resourceTypesByProvider } from "../../../../../../../utils/helpers/providers/resourceTypesByProvider";

interface FilterTemplatesFiltersDialogProps {
  open: boolean;
  onClose(): void;
  filterSet: FilterSet;
  provider?: ProviderType;
}

export const FilterTemplatesFiltersDialog: FC<
  FilterTemplatesFiltersDialogProps
> = ({ filterSet, onClose, open, provider }) => {
  const toggledResourceTypes = useToggledResourceTypes();

  const sortedResources = useMemo(() => {
    const sortedResourcesKeys = resourceTypesByProvider(
      provider ?? "aws",
    ).reduce(
      (acc, value, index) => {
        acc[value] = index;
        return acc;
      },
      {} as Record<ResourceType, number>,
    );

    return filterSet.filters
      ?.filter(
        (rt) =>
          !toggledResourceTypes.get(rt.type) &&
          sortedResourcesKeys.hasOwnProperty(rt.type),
      )
      .sort((a, b) =>
        sortedResourcesKeys[a.type] > sortedResourcesKeys[b.type] ? 1 : -1,
      );
  }, [filterSet.filters, toggledResourceTypes, provider]);

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <DialogTitleClosable
        TitleProps={{ sx: { p: 2 } }}
        title={`Filters for "${filterSet.name}"`}
        onClose={onClose}
      />
      <DialogContent dividers sx={{ p: 2 }}>
        {sortedResources.map((filter, i) => {
          return (
            filter && (
              <FilterTemplatesFiltersDialogItem
                filter={filter}
                // todo: mechanism need to generate unique value from filter for key and other
                key={filter.type + i}
              />
            )
          );
        })}
      </DialogContent>
      <DialogActions sx={{ p: 2 }}>
        <Button variant="outlined" onClick={onClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};
