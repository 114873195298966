import { useParams, useSearchParams } from "react-router-dom";
import { useMemo } from "react";

export const useExecutionLogPathIds = () => {
  const [searchParams] = useSearchParams();
  const ids = searchParams.get("ids");
  const { id } = useParams();

  return useMemo(() => (id ? [id] : ids?.split(",")), [id, ids]);
};
