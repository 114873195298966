import { FC, ReactNode } from "react";
import { Auth0Provider } from "@auth0/auth0-react";
import { FlagProvider } from "@unleash/proxy-client-react";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import {
  AUTH0_CONFIG,
  UNLEASH_CONFIG,
} from "../utils/env-var/env-var-constants";
import { CloudChiprTheme } from "../theme/CloudChiprTheme";
import { store } from "../store/store";

export const Providers: FC<{ children?: ReactNode }> = ({ children }) => {
  return (
    <BrowserRouter>
      <CloudChiprTheme>
        <Auth0Provider
          {...AUTH0_CONFIG}
          redirectUri={window.location.origin}
          useRefreshTokens={true}
          cacheLocation="localstorage"
        >
          <FlagProvider config={UNLEASH_CONFIG}>
            <Provider store={store}>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                {children}
              </LocalizationProvider>
            </Provider>
          </FlagProvider>
        </Auth0Provider>
      </CloudChiprTheme>
    </BrowserRouter>
  );
};
