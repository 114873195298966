import { FC, memo, useMemo } from "react";
import { useDidMount } from "rooks";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../store/hooks";
import { ProviderType } from "../../../../../../../../services/cloudchipr.api";
import { AccountSelect } from "../../../../../../../common/account-select/AccountSelect";
import { allAccountsSelectOptionsSelector } from "../../../../../../../../store/accounts/selectors/all-providers/allAccountsSelectOptionsSelector";
import { getOrganisationCurrentFilterTemplatesThunk } from "../../../../../../../../store/live-usage-mgmt/thunks/getOrganisationCurrentFilterTemplatesThunk";

interface SchedulesAccountsProps {
  provider?: ProviderType;
  disabled: boolean;
  roDisabled?: boolean;
  onChange: (ids: string[]) => void;
  accountIds?: string[];
}

export const OffHoursAccounts: FC<SchedulesAccountsProps> = memo(
  ({ accountIds, disabled, onChange, roDisabled, provider }) => {
    const dispatch = useAppDispatch();
    const groupedAllAccounts = useAppSelector(allAccountsSelectOptionsSelector);

    const options = useMemo(() => {
      return groupedAllAccounts
        .filter(({ accountType }) => accountType === provider)
        .map((data) => ({
          ...data,
          disabled:
            data.disabled ||
            (roDisabled && data.accessType === "read") ||
            data.status !== "connected",
        }));
    }, [groupedAllAccounts, provider, roDisabled]);

    useDidMount(() => {
      dispatch(getOrganisationCurrentFilterTemplatesThunk());
    });

    return (
      <AccountSelect
        listWidth={513}
        disabled={disabled}
        onChange={onChange}
        options={options}
        selectedValue={accountIds || []}
        wrapperVariant="popover"
        PopoverProps={{
          extendAnchorWidth: true,
        }}
      />
    );
  },
);
