import { Stack } from "@mui/material";
import { FC } from "react";
import { CFNStack } from "../../../common/connectors/components/CFNStack";
import { TerraformModuleLink } from "../../../common/connectors/components/TerraformModuleLink";
import { ProviderAccessType } from "../../../../../services/cloudchipr.api";

interface StepOptionActionsProps {
  accessType: ProviderAccessType;
  terraformModuleLink?: string;
}

export const StepOptionActions: FC<StepOptionActionsProps> = ({
  terraformModuleLink,
  accessType,
}) => {
  if (!terraformModuleLink) {
    return;
  }

  return (
    <Stack direction="row" spacing={1}>
      <CFNStack accessType={accessType} />
      <TerraformModuleLink terraformModuleLink={terraformModuleLink} />
    </Stack>
  );
};
