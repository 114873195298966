import { createDraftSafeSelector } from "@reduxjs/toolkit";
import { getDailyDismissedRecommendationComputeSelector } from "../../recommendationDailyQuerySelector";
import { OrganisationDailyOffHoursRecommendation } from "../../../../../../services/cloudchipr.api";

export const recommendationComputeDailyDismissedDataSelector =
  createDraftSafeSelector(
    [getDailyDismissedRecommendationComputeSelector],
    (response): OrganisationDailyOffHoursRecommendation[] =>
      response?.data ?? [],
  );
