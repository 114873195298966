import { Stack, Tooltip, Typography } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import {
  RenderToolbar,
  RenderToolbarProps,
} from "../../../../../../../../storybook/data-grid/utils/types/prop-types";
import { GlobalFilter } from "../../../../../../../../storybook/data-grid/toolbar/GlobalFilter";

export const WidgetSetupTableToolbar: RenderToolbar<
  RenderToolbarProps & { tooltip?: string }
> = ({ globalFilter, setGlobalFilter, tooltip }) => {
  return (
    <Stack
      p={1.5}
      direction="row"
      alignItems="center"
      justifyContent="space-between"
    >
      <Stack direction="row" spacing={1} alignItems="center">
        <Typography variant="body1" fontWeight="medium">
          Choose items to display on the widget
        </Typography>

        {tooltip && (
          <Tooltip title={tooltip} placement="top" arrow>
            <InfoOutlinedIcon color="action" fontSize="small" />
          </Tooltip>
        )}
      </Stack>

      <GlobalFilter
        globalFilter={globalFilter}
        setGlobalFilter={setGlobalFilter}
      />
    </Stack>
  );
};
