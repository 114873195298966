import { FC, useEffect } from "react";
import Stack from "@mui/material/Stack";
import { UtilizationAndCoverageMetadata } from "./components/metadata/UtilizationAndCoverageMetadata";
import { UtilizationAndCoverageContent } from "./components/content/UtilizationAndCoverageContent";
import { UtilizationAndCoverageDrawers } from "./components/drawers/UtilizationAndCoverageDrawers";
import { getSavingsPlansThunk } from "../../../../store/commitments/thunks/savings-plans/getSavingsPlansThunk";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { commitmentsCurrentOrgIdSelector } from "../../../../store/commitments/selectors/common/commitmentsCurrentOrgIdSelector";

export const UtilizationAndCoverage: FC = () => {
  const dispatch = useAppDispatch();

  const orgId = useAppSelector(commitmentsCurrentOrgIdSelector);

  useEffect(() => {
    dispatch(getSavingsPlansThunk());
  }, [dispatch, orgId]);

  return (
    <Stack spacing={1}>
      <UtilizationAndCoverageMetadata />
      <UtilizationAndCoverageContent />

      <UtilizationAndCoverageDrawers />
    </Stack>
  );
};
