import { FC } from "react";
import { Stack, Typography } from "@mui/material";
import { IntegrationUtilizationAlertCommitmentsExpirationEmailHeader } from "./IntegrationUtilizationAlertCommitmentsExpirationEmailHeader";
import { IntegrationUtilizationAlertCommitmentsExpirationDetails } from "./IntegrationUtilizationAlertCommitmentsExpirationDetails";
import { IntegrationUtilizationAlertCommitmentsExpirationSlackHeader } from "./IntegrationUtilizationAlertCommitmentsExpirationSlackHeader";

interface IntegrationUtilizationAlertCommitmentsExpirationMessageProps {
  message: string;
  type: "email" | "slack";
}

export const IntegrationUtilizationAlertCommitmentsExpirationMessage: FC<
  IntegrationUtilizationAlertCommitmentsExpirationMessageProps
> = ({ message, type }) => {
  return (
    <Stack spacing={1}>
      {type === "email" && (
        <IntegrationUtilizationAlertCommitmentsExpirationEmailHeader />
      )}

      {type === "slack" && (
        <IntegrationUtilizationAlertCommitmentsExpirationSlackHeader />
      )}

      <IntegrationUtilizationAlertCommitmentsExpirationDetails />

      <Typography
        variant="body1"
        dangerouslySetInnerHTML={{ __html: message }}
      />
    </Stack>
  );
};
