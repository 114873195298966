import { FC } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableContainer,
} from "@mui/material";
import { UserRequestInfoCell } from "./UserRequestInfoCell";

import { UserRequestActionCell } from "./UserRequestActionCell";
import { User } from "../../../../../../../../services/cloudchipr.api";

interface UsersRequestTableProps {
  data: User[];
}
export const UsersRequestTable: FC<UsersRequestTableProps> = ({ data }) => {
  return (
    <TableContainer sx={tableContainerSx}>
      <Table
        size="small"
        sx={{
          tableLayout: "fixed",
          borderCollapse: "separate",
        }}
      >
        <TableBody>
          {data?.map((item) => {
            return (
              <TableRow sx={{ bgcolor: "white" }} key={item.id}>
                <TableCell sx={{ minWidth: 50, py: 2 }}>
                  <UserRequestInfoCell
                    name={item?.name ?? ""}
                    email={item?.email ?? ""}
                  />
                </TableCell>
                <TableCell sx={{ py: 2, width: 190 }}>
                  <UserRequestActionCell
                    id={item.id}
                    email={item.email ?? undefined}
                  />
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const tableContainerSx = {
  border: 1,
  borderColor: "grey.300",
  borderRadius: 2,
  maxHeight: 385,
};
