import { FC, useState } from "react";
import { useDidMount, useTimeoutWhen } from "rooks";
import { setPolling } from "../../../../../../../store/account-setup/accountSetupSlice";
import { useAppDispatch } from "../../../../../../../store/hooks";
import { AccessToAccountsTabs } from "../../AccessToAccountsTabs";

export const AccessToGCPProjectTabs: FC = () => {
  const dispatch = useAppDispatch();
  const [pollingStarted, setPollingStarted] = useState(false);

  useDidMount(() => {
    setPollingStarted(true);
  });

  useTimeoutWhen(
    () => {
      dispatch(setPolling(true));
    },
    10000,
    pollingStarted,
  );

  return <AccessToAccountsTabs />;
};
