import { FC } from "react";
import { CSVLink } from "react-csv";
import { IconButton, Tooltip } from "@mui/material";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import { useAppSelector } from "../../../../../../../store/hooks";
import { rightsizingRecommendationsCSVDataSelector } from "../../../../../../../store/recommendations/selectors/rightsizing/data/rightsizingRecommendationsCSVDataSelector";

interface RightsizingRecommendationsCSVDownloaderProps {
  dismissed?: boolean;
}

export const RightsizingRecommendationsCSVDownloader: FC<
  RightsizingRecommendationsCSVDownloaderProps
> = ({ dismissed }) => {
  const data = useAppSelector((state) =>
    rightsizingRecommendationsCSVDataSelector(state, dismissed),
  );

  const filename = `${
    dismissed ? "Dismissed " : ""
  }Rightsizing Recommendations`;

  return (
    <CSVLink filename={filename} data={data}>
      <Tooltip title="Export CSV" placement="top">
        <IconButton size="small">
          <DownloadOutlinedIcon fontSize="small" />
        </IconButton>
      </Tooltip>
    </CSVLink>
  );
};
