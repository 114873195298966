import { createDraftSafeSelector } from "@reduxjs/toolkit";
import { liveUsageMgmtAppliedFilterTemplateSelector } from "./liveUsageMgmtAppliedFilterTemplateSelector";
import { liveUsageMgmtProviderSelector } from "../store-selectors/liveUsageMgmtProviderSelector";
import { liveUsageMgmtAppliedFiltersSelector } from "../store-selectors/liveUsageMgmtAppliedFiltersSelector";
import { orgCurrentFilterTemplatesGettingLoadingSelector } from "../org-filter-templates/orgCurrentFilterTemplatesGettingLoadingSelector";
import { isDeepEqual } from "../../../../utils/is-deep-equal";

export const liveUsageMgmtDefaultFilterTemplateFiltersChangedSelector =
  createDraftSafeSelector(
    [
      liveUsageMgmtProviderSelector,
      liveUsageMgmtAppliedFiltersSelector,
      liveUsageMgmtAppliedFilterTemplateSelector,
      orgCurrentFilterTemplatesGettingLoadingSelector,
    ],
    (provider, appliedFilters, appliedFilterSet, isLoading) => {
      if (!provider || !appliedFilters || isLoading) {
        return false;
      }

      if (!appliedFilterSet) {
        return true;
      }

      return appliedFilterSet.filters.some((filter) => {
        const appliedFilterByType = appliedFilters[filter.type];

        if (!appliedFilterByType) {
          return false;
        }

        if (
          !filter ||
          !appliedFilterByType ||
          appliedFilterByType?.filter_items?.length !==
            filter?.filter_items?.length ||
          appliedFilterByType?.filter_groups?.length !==
            filter?.filter_groups?.length ||
          appliedFilterByType?.operators?.length !== filter?.operators?.length
        ) {
          return true;
        }

        return !isDeepEqual(appliedFilterByType, filter);
      });
    },
  );
