import { createAsyncThunk } from "@reduxjs/toolkit";
import { cloudChiprApi } from "../../../../services/cloudchipr.api";

export const taskManagementGetTasksThunk = createAsyncThunk(
  "taskManagement/taskManagementGetTasks",
  async (_: void, { dispatch }) => {
    const { getUsersMeOrganisationsCurrentTasks } = cloudChiprApi.endpoints;

    return await dispatch(
      getUsersMeOrganisationsCurrentTasks.initiate(undefined, {
        forceRefetch: true,
      }),
    ).unwrap();
  },
);
