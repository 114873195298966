import { FC } from "react";
import { Divider, Stack } from "@mui/material";

import { IntegrationCustomMessageDataGrid } from "../../../common/IntegrationCustomMessageDataGrid";
import { EmailIntegrationCsvInfo } from "../../../email/common/EmailIntegrationCsvInfo";
import { integrationCustomEmailActionExecuteDataGrid } from "../../../email/utils/constants";
import { EmailIntegrationMessageNotifySubject } from "../../../email/notify/EmailIntegrationMessageNotifySubject";
import { EmailIntegrationNotifyWorkflowInfo } from "../../../email/notify/EmailIntegrationNotifyWorkflowInfo";
import { CreateIntegrationMessageEditor } from "../../../../../../../../common/integration-dialogs/components/common/CreateIntegrationMessageEditor";

interface EmailIntegrationNotifyDialogContentProps {
  customMessage: string;
  onCustomMessageChange(message: string): void;
}

export const EmailIntegrationNotifyDialogContent: FC<
  EmailIntegrationNotifyDialogContentProps
> = ({ customMessage, onCustomMessageChange }) => {
  return (
    <Stack spacing={2}>
      <EmailIntegrationMessageNotifySubject />
      <Divider />
      <EmailIntegrationNotifyWorkflowInfo />
      <Divider />
      <CreateIntegrationMessageEditor
        value={customMessage ?? ""}
        onChange={onCustomMessageChange}
        placeholder="Write a custom message..."
      />
      <Divider sx={{ mt: 1 }} />
      <IntegrationCustomMessageDataGrid
        data={integrationCustomEmailActionExecuteDataGrid}
        label="Account Name / ID"
      />
      <EmailIntegrationCsvInfo />
    </Stack>
  );
};
