import { providerDisabledTooltipTitleCombiner } from "./providerDisabledTooltipTitleCombiner";
import { RootState } from "../../../store";
import { gcpNonDeletedAndNotBillingAccountsSelector } from "../../../accounts/selectors/gcp/gcpNonDeletedAndNotBillingAccountsSelector";

export const gcpDisabledTooltipTitleSelector = (
  state: RootState,
  forOffHours: boolean,
) => {
  const accounts = gcpNonDeletedAndNotBillingAccountsSelector(state);

  return providerDisabledTooltipTitleCombiner(forOffHours, "gcp", accounts);
};
