export const AUTH0_CONFIG = {
  domain: process.env.REACT_APP_AUTH0_DOMAIN || "",
  clientId: process.env.REACT_APP_AUTH0_CLIENT_ID || "",
  audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
};

export const UNLEASH_CONFIG = {
  url: process.env.REACT_APP_UNLEASH_URL || "",
  clientKey: process.env.REACT_APP_UNLEASH_CLIENT_KEY || "",
  appName: process.env.REACT_APP_UNLEASH_APP_NAME || "",
};

export const POSTHOG_CONFIG = {
  api_host: process.env.REACT_APP_POSTHOG_API_HOST,
  token: process.env.REACT_APP_POSTHOG_TOKEN,
  enabled: process.env.REACT_APP_POSTHOG_ENABLED,
};

export const APP_CONFIG = {
  host: process.env.REACT_APP_HOST || "",
  api_host: process.env.REACT_APP_API_HOST || "",
};

export const SENTRY_CONFIG = {
  dsn: process.env.REACT_APP_SENTRY_DSN,
  tracePropagationTargets: [APP_CONFIG.host, APP_CONFIG.api_host],
};

export const SLACK_CONFIG = {
  clientId: process.env.REACT_APP_SLACK_CLIENT_ID,
};
