import {
  MetricsFrequenciesKeysType,
  MetricsFrequenciesType,
} from "../types/metricsFrequenciesType";

export const generateMetricsCsvKey = (
  headerTitle?: string,
  metricsFrequencies?: MetricsFrequenciesType,
  id?: string,
) => {
  return `${headerTitle ?? ""} ${id ? (metricsFrequencies?.[id as MetricsFrequenciesKeysType] ?? "") : ""}`;
};
