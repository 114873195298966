import { savingsPlanSelectedCoverageDataSelector } from "./savingsPlanSelectedCoverageDataSelector";
import { RootState } from "../../../../../store";

export const savingsPlanSelectedCoverageDataAccountNameAndIdSelector = (
  state: RootState,
): string => {
  const data = savingsPlanSelectedCoverageDataSelector(state);

  return `${data?.account?.name} (${data?.account?.provider_account_id})`;
};
