import { FC } from "react";
import { Typography, Link } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";

export const ResourceExplorerBillingExportDialogDetailsInstruction: FC = () => {
  return (
    <Typography variant="body2" color="text.secondary">
      Enable
      <Typography
        component="span"
        variant="body2"
        color="text.primary"
        fontWeight="medium"
      >
        {" "}
        Detailed usage cost exports
      </Typography>{" "}
      from the{" "}
      <Link
        to="https://console.cloud.google.com/billing"
        component={RouterLink}
        target="_blank"
      >
        billing account
      </Link>{" "}
      to enable billing exports. Please see the detailed instructions -{" "}
      <Link
        to="https://cloud.google.com/billing/docs/how-to/export-data-bigquery-setup"
        component={RouterLink}
        target="_blank"
      >
        https://cloud.google.com/billing/docs/how-to/export-data-bigquery-setup
      </Link>
      .
    </Typography>
  );
};
