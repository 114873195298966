import { FC, useCallback, useMemo } from "react";
import { Stack, Typography } from "@mui/material";
import { RecommendationLevelFilterAccountsSelectTriggerComponent } from "./RecommendationLevelFilterAccountsSelectTriggerComponent";
import { DropdownSelect } from "../../../../../../../../common/select/dropdown-select/DropdownSelect";
import { DropdownSelectOption } from "../../../../../../../../common/select/dropdown-select/utils/types/types";
import { setCommitmentsRecommendationsAccountIds } from "../../../../../../../../../store/commitments/commitmentsSlice";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../../store/hooks";
import { commitmentsRecommendationsAccountIdsSelector } from "../../../../../../../../../store/commitments/selectors/recommendations/filters/commitmentsRecommendationsAccountIdsSelector";
import { organizationAccountsByCurrentOrgIdSelector } from "../../../../../../../../../store/commitments/selectors/organisations/organizationAccountsByCurrentOrgIdSelector";

interface RecommendationLevelFilterAccountsSelectProps {
  toggleOpen(action: any): void;
}

export const RecommendationLevelFilterAccountsSelect: FC<
  RecommendationLevelFilterAccountsSelectProps
> = ({ toggleOpen }) => {
  const dispatch = useAppDispatch();
  const accounts = useAppSelector(organizationAccountsByCurrentOrgIdSelector);
  const accountIds = useAppSelector(
    commitmentsRecommendationsAccountIdsSelector,
  );

  const options = useMemo(() => {
    return (
      accounts?.map((account) => {
        return {
          value: account.id,
          listItemHeight: account.provider_account_id ? 55 : undefined,
          label: (
            <Stack justifyContent="space-between">
              <Typography variant="body2">
                {account.provider_account_name ?? account.provider_account_id}
              </Typography>
              <Typography variant="caption" color="text.secondary">
                {account.provider_account_id}
              </Typography>
            </Stack>
          ),
          rawValue: account,
        };
      }) ?? []
    );
  }, [accounts]);

  const setSelectedAccountsIds = useCallback(
    (selectedValues: string[]) => {
      if (selectedValues.length === 0) {
        dispatch(
          setCommitmentsRecommendationsAccountIds(
            options.map((item) => item.value),
          ),
        );
      } else {
        dispatch(setCommitmentsRecommendationsAccountIds(selectedValues));
      }
    },
    [options, dispatch],
  );

  const submitHandlerOnClose = useCallback(
    (values: string[]) => {
      setSelectedAccountsIds(values);
      toggleOpen(values);
    },
    [toggleOpen, setSelectedAccountsIds],
  );

  return (
    <DropdownSelect
      label="Accounts"
      options={options}
      values={accountIds}
      filterFn={accountOptionFilterFn}
      TriggerComponent={RecommendationLevelFilterAccountsSelectTriggerComponent}
      submitHandlerOnClose={submitHandlerOnClose}
      PopoverProps={{
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
        transformOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      }}
    />
  );
};

const accountOptionFilterFn = (
  option: DropdownSelectOption,
  keyword: string,
) => {
  if (!option.rawValue) {
    return false;
  }

  const aim = `${option.rawValue.provider_account_name} ${option.rawValue.provider_account_id}`;

  return aim.toLowerCase().includes(keyword.trim().toLowerCase());
};
