import { FC, Fragment, useCallback } from "react";
import Typography from "@mui/material/Typography";
import { Link as RouterLink } from "react-router-dom";
import { Box, IconButton, Link, Stack, Tooltip } from "@mui/material";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import { ResourceExplorerBudgetProgress } from "./ResourceExplorerBudgetProgress";
import { ResourceExplorerBudgetTooltip } from "./tooltip/ResourceExplorerBudgetTooltip";
import { generateDateLabelByBudgetPeriod } from "./tooltip/utils/helpers/generateDateLabelByBudgetPeriod";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../../store/hooks";
import { money } from "../../../../../../../../../utils/numeral/money";
import { useAppAbility } from "../../../../../../../../../services/permissions";
import { capitalizeString } from "../../../../../../../../../utils/helpers/capitalizeString";
import { resourceExplorerViewBudgetSelector } from "../../../../../../../../../store/resource-explorer/selectors/budget/resourceExplorerViewBudgetSelector";
import { useHover } from "../../../../../../../../../utils/hooks/useHover.hook";
import { resourceExplorerShowBudgetLineSelector } from "../../../../../../../../../store/resource-explorer/selectors/current-resource-explorer/data/resourceExplorerShowBudgetLineSelector";
import { toggleShowBudgetLine } from "../../../../../../../../../store/resource-explorer/resourceExplorerSlice";
import { resourceExplorerChartBaseSelector } from "../../../../../../../../../store/resource-explorer/selectors/resourceExplorerChartBaseSelector";

export const ResourceExplorerBudget: FC = () => {
  const { can } = useAppAbility();
  const dispatch = useAppDispatch();
  const { ref, hovered } = useHover();
  const showBudgetLine = useAppSelector(resourceExplorerShowBudgetLineSelector);
  const chartBase = useAppSelector(resourceExplorerChartBaseSelector);
  const canEditBudget = can("edit", "budget");

  const budget = useAppSelector(resourceExplorerViewBudgetSelector);

  const dateLabelByBudgetPeriod = generateDateLabelByBudgetPeriod(
    budget?.start_date,
    budget?.period,
  );

  const tooltipText = showBudgetLine ? "Hide budget line" : "Show budget line";

  const handleShowBudgetLineClick = useCallback(() => {
    dispatch(toggleShowBudgetLine());
  }, [dispatch]);

  if (!budget) {
    return null;
  }

  return (
    <Box ref={ref} py={1}>
      <Stack direction="row" spacing={0.5} alignItems="center">
        {canEditBudget ? (
          <Link
            to={`/budgets/edit/${budget?.id}`}
            component={RouterLink}
            variant="subtitle2"
            fontWeight="regular"
            color="text.secondary"
            underline="none"
            sx={{
              "&:hover": {
                color: "primary.main",
              },
            }}
          >
            Budget - {capitalizeString(budget.period)} (
            {dateLabelByBudgetPeriod})
          </Link>
        ) : (
          <Typography
            variant="subtitle2"
            color="text.secondary"
            fontWeight="regular"
          >
            Budget - {capitalizeString(budget.period)} (
            {dateLabelByBudgetPeriod})
          </Typography>
        )}

        <Tooltip
          title={
            chartBase === "percent"
              ? "Budget line is hidden in percentage (%) mode. Switch to dollar ($) mode to see it."
              : tooltipText
          }
          arrow
          placement="top"
        >
          <span>
            <IconButton
              onClick={handleShowBudgetLineClick}
              size="small"
              disabled={chartBase === "percent"}
              sx={{ visibility: hovered ? "visible" : "hidden" }}
            >
              {showBudgetLine ? (
                <VisibilityOffOutlinedIcon fontSize="inherit" />
              ) : (
                <VisibilityOutlinedIcon fontSize="inherit" />
              )}
            </IconButton>
          </span>
        </Tooltip>
      </Stack>

      <ResourceExplorerBudgetTooltip
        name={budget.name}
        amount={budget.amount}
        progress={budget.progress}
        startDate={budget.start_date}
        period={budget.period}
      >
        <Fragment>
          <Typography variant="h6" fontWeight="medium">
            {money(budget.amount)}
          </Typography>
          <ResourceExplorerBudgetProgress progress={budget.progress} />
        </Fragment>
      </ResourceExplorerBudgetTooltip>
    </Box>
  );
};
