import { FC } from "react";
import { Button, Stack, Tooltip } from "@mui/material";
import ReportProblemOutlinedIcon from "@mui/icons-material/ReportProblemOutlined";
import AddIcon from "@mui/icons-material/Add";
import { useAppAbility } from "../../../../../../services/permissions";
import { apiKeysMaxCount } from "../ApiKeysList";

interface ApiKeyCreateValidationProps {
  isMaxCount: boolean;
  showForm(): void;
}

export const ApiKeyCreateValidation: FC<ApiKeyCreateValidationProps> = ({
  isMaxCount,
  showForm,
}) => {
  const { cannot } = useAppAbility();
  const cannotCreateAPIKey = cannot("create", "api-key");

  return (
    <Stack direction="row" alignItems="center">
      {isMaxCount ? (
        <Tooltip
          arrow
          title={`Cloudchipr allows to have maximum ${apiKeysMaxCount} API keys. If you would like more, please contact us support@cloudchipr.com`}
          placement="right"
        >
          <div>
            <Button
              onClick={showForm}
              type="submit"
              disabled
              variant="contained"
              size="large"
            >
              create api key
              <ReportProblemOutlinedIcon
                sx={{
                  ml: 1,
                  mb: 0.3,
                }}
              />
            </Button>
          </div>
        </Tooltip>
      ) : (
        <Button
          onClick={showForm}
          type="submit"
          color="primary"
          variant="contained"
          sx={{ ml: 7 }}
          disabled={cannotCreateAPIKey}
          size="large"
        >
          <AddIcon sx={{ mr: 1 }} /> create api key
        </Button>
      )}
    </Stack>
  );
};
