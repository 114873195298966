import { RootState } from "../../../../../store";
import { costBreakdownWidgetSetupDataPayloadSelector } from "../setup/costBreakdownWidgetSetupDataPayloadSelector";
import { costBreakdownWidgetSetupSelector } from "../setup/costBreakdownWidgetSetupSelector";

export const costBreakdownWidgetSetupDataGettingLoadingSelector = (
  state: RootState,
): boolean => {
  const payload = costBreakdownWidgetSetupDataPayloadSelector(state);
  if (!payload) {
    return false;
  }
  const response = costBreakdownWidgetSetupSelector(payload)(state);

  return response.isLoading;
};
