import { getAccountsResourcesVisibilitiesSelector } from "./getAccountsResourcesVisibilitiesSelector";
import { RootState } from "../../../store";
import { liveUsageMgmtAccountIdsSelector } from "../store-selectors/liveUsageMgmtAccountIdsSelector";
import { liveUsageMgmtProviderSelector } from "../store-selectors/liveUsageMgmtProviderSelector";
import {
  AccountsVisibilities,
  ResourceType,
  ResourceVisibility,
} from "../../../../services/cloudchipr.api";

export const liveUsageMgmtAccountsVisibilitiesSelector = (state: RootState) => {
  const accountIds = liveUsageMgmtAccountIdsSelector(state);
  const provider = liveUsageMgmtProviderSelector(state);

  if (!provider || !accountIds) {
    return null;
  }

  const response = getAccountsResourcesVisibilitiesSelector(state);

  return getAllResourceStatuses(response ?? []) || null;
};

function getAllResourceStatuses(
  accounts: AccountsVisibilities[],
): Partial<Record<ResourceType, ResourceVisibility["visibilities"]>> {
  let result: Partial<
    Record<ResourceType, ResourceVisibility["visibilities"]>
  > = {};

  accounts.forEach((account) => {
    account.accounts_visibilities.forEach((resource) => {
      const { type, visibilities } = resource;
      const { show, actions } = visibilities;

      // Initialize the resource in the result object if not present
      if (!result[type]) {
        result[type] = {
          show: false,
          actions: {
            delete: null,
            snapshot_and_delete: null,
            stop: null,
            start: null,
          },
        };
      }

      // Update the final show value if any resource show is true
      if (show) result[type].show = true;

      // Check if any of the actions are true and update the final result for each resource
      Object.keys(actions).forEach((actionKey) => {
        const currentActionValue = (actions as any)?.[actionKey];
        if (currentActionValue === true) {
          result = {
            ...result,
            [type]: {
              ...result[type],
              actions: {
                ...(result?.[type]?.actions ?? {}),
                [actionKey]: true,
              },
            },
          };
        } else if (
          currentActionValue === false &&
          (result?.[type]?.actions as any)?.[actionKey] !== true
        ) {
          result = {
            ...result,
            [type]: {
              ...result[type],
              actions: {
                ...(result?.[type]?.actions ?? {}),
                [actionKey]: false,
              },
            },
          };
        } else if (
          currentActionValue === null &&
          (result?.[type]?.actions as any)[actionKey] !== true &&
          (result?.[type]?.actions as any)[actionKey] !== false
        ) {
          // Set to null only if it hasn't been set to true or false
          result = {
            ...result,
            [type]: {
              ...result[type],
              actions: {
                ...(result?.[type]?.actions ?? {}),
                [actionKey]: null,
              },
            },
          };
        }
      });
    });
  });

  return result;
}
