import { FC, useCallback, useState } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import { IconButton, Tooltip } from "@mui/material";
import LinkIcon from "@mui/icons-material/Link";
import { currentDashboardIdSelector } from "../../../../../store/dashboards/selectors/dashboard/currentDashboardIdSelector";
import { useAppSelector } from "../../../../../store/hooks";

interface UrlCopyButtonProps {
  disabled: boolean;
}

export const UrlCopyButton: FC<UrlCopyButtonProps> = ({ disabled }) => {
  const dashboardId = useAppSelector(currentDashboardIdSelector);

  const [copied, setCopied] = useState(false);

  const copyHandler = useCallback(() => {
    setCopied(true);

    setTimeout(() => setCopied(false), 3000);
  }, []);

  return (
    <Tooltip arrow placement="top" title={copied ? "Copied!" : "Copy URL"}>
      <span>
        <CopyToClipboard
          onCopy={copyHandler}
          text={`${window.location.host}/dashboards/${dashboardId}`}
        >
          <IconButton
            color="primary"
            size="small"
            disabled={copied || disabled}
          >
            <LinkIcon />
          </IconButton>
        </CopyToClipboard>
      </span>
    </Tooltip>
  );
};
