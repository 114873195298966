import { FC, Fragment, useCallback } from "react";
import { Grid } from "@mui/material";
import { DatePicker, MobileDateTimePicker } from "@mui/x-date-pickers";
import moment from "moment";
import { formatDate } from "../../../../../../../../utils/helpers/date-time/datetime-format";

interface DatePickerFieldProps {
  type: "date" | "datetime";
  error?: string;
  name: string;
  label: string;
  value?: string;
  setFieldValue(key: string, value: string): void;
}

export const DatePickerField: FC<DatePickerFieldProps> = ({
  type,
  value,
  label,
  name,
  setFieldValue,
  error,
}) => {
  const changeHandler = useCallback(
    (value: any) => {
      const date = formatDate(value, {
        type: type === "date" ? "yearMonthDay" : undefined,
      });

      setFieldValue(name, date);
    },
    [setFieldValue, name, type],
  );

  return (
    <Fragment>
      <Grid item sm={4}>
        {type === "date" && (
          <DatePicker
            value={value ? moment(value) : null}
            onChange={changeHandler}
            slotProps={{
              textField: {
                fullWidth: true,
                size: "small",
                helperText: error,
                placeholder: label,
                variant: "outlined",
                error: !!error,
              },
            }}
          />
        )}

        {type === "datetime" && (
          <MobileDateTimePicker
            ampm
            value={value ? moment(value) : null}
            onChange={changeHandler}
            slotProps={{
              textField: {
                fullWidth: true,
                size: "small",
                helperText: error,
                placeholder: label,
                variant: "outlined",
                error: !!error,
              },
            }}
          />
        )}
      </Grid>
      <Grid item sm={6} />
    </Fragment>
  );
};
