import { FC } from "react";
import { IconButton, Link, Tooltip } from "@mui/material";
import BarChartIcon from "@mui/icons-material/BarChart";
import { useAppSelector } from "../../../../../../store/hooks";
import { resourceExplorerViewsHierarchyDefaultViewSelector } from "../../../../../../store/resource-explorer/selectors/views-hierarchy/resourceExplorerViewsHierarchyDefaultViewSelector";

interface OpenInResourceExplorerButtonProps {
  dimensionId: string;
  categoryId?: string;
}

export const OpenInResourceExplorerButton: FC<
  OpenInResourceExplorerButtonProps
> = ({ dimensionId, categoryId }) => {
  const defaultResourceExplorerViewId = useAppSelector(
    resourceExplorerViewsHierarchyDefaultViewSelector,
  );

  let href = `/resource-explorer/${defaultResourceExplorerViewId}?dimensionId=${dimensionId}`;

  if (categoryId) {
    href = `${href}&categoryId=${categoryId}`;
  }

  return (
    <Tooltip arrow title="Open in Resource Explorer" placement="top">
      <IconButton
        size="small"
        color="primary"
        component={Link}
        href={href}
        target="_blank"
      >
        <BarChartIcon fontSize="small" />
      </IconButton>
    </Tooltip>
  );
};
