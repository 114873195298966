import { createAsyncThunk } from "@reduxjs/toolkit";
import { enqueueSnackbar } from "notistack";
import { RootState } from "../../../store";
import {
  AccountId,
  AccountResourceFilter,
  cloudChiprApi,
} from "../../../../services/cloudchipr.api";
import { liveUsageMgmtProviderSelector } from "../../selectors/store-selectors/liveUsageMgmtProviderSelector";
import { getAccountsThunk } from "../../../accounts/thunks/getAccountsThunk";
import { findAndApplyFilterTemplatesThunk } from "../filters/findAndApplyLiveUsageMgmtFiltersThunk";
import { getOrganisationCurrentFilterTemplatesThunk } from "../getOrganisationCurrentFilterTemplatesThunk";

interface CreateLiveUsageMgmtFilterTemplateByAccountIdsThunkArgs {
  name: string;
  accountIds: AccountId[];
  filters: AccountResourceFilter[];
}
export const createLiveUsageMgmtFilterTemplateByAccountIdsThunk =
  createAsyncThunk(
    "liveUsageMgmt/createLiveUsageMgmtFilterTemplateByAccountIds",
    async (
      {
        name,
        filters,
        accountIds,
      }: CreateLiveUsageMgmtFilterTemplateByAccountIdsThunkArgs,
      { dispatch, getState },
    ) => {
      const state = getState() as RootState;

      const provider = liveUsageMgmtProviderSelector(state);

      const { postUsersMeOrganisationsCurrentFilterSetsV2 } =
        cloudChiprApi.endpoints;

      if (!provider) {
        return;
      }

      try {
        const response = await dispatch(
          postUsersMeOrganisationsCurrentFilterSetsV2.initiate({
            body: {
              name,
              filters,
              cloud_provider: provider,
              accounts_to_apply: accountIds ?? null,
            },
          }),
        ).unwrap();

        if (response?.id) {
          await dispatch(getAccountsThunk());
          await dispatch(getOrganisationCurrentFilterTemplatesThunk());
          dispatch(findAndApplyFilterTemplatesThunk());
        }

        return response;
      } catch (e) {
        // @ts-expect-error //todo: remove this when backend will add types
        enqueueSnackbar(e?.data?.message ?? "Something went wrong", {
          variant: "snackbarAlert",
          AlertSnackBarProps: {
            severity: "error",
          },
        });
      }
    },
  );
