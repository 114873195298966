import { FC } from "react";
import { Tooltip, Stack, Typography } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { MoneyCell } from "./MoneyCell";
import { ResourceType } from "../../../../../../../../../../services/cloudchipr.api";

interface MoneyStoppedCellProps {
  data: number | null;
  resourceType: ResourceType;
  stopped: boolean;
}

export const MoneyStoppedCell: FC<MoneyStoppedCellProps> = ({
  data,
  resourceType,
  stopped,
}) => {
  const title = titleByResourceType[resourceType];

  return (
    <Stack direction="row" alignItems="center" spacing={1}>
      <Typography variant="body2">
        <MoneyCell data={data} />
      </Typography>

      {stopped && (
        <Tooltip title={title ?? ""} placement="top" arrow>
          <InfoOutlinedIcon color="action" fontSize="small" />
        </Tooltip>
      )}
    </Stack>
  );
};

const titleByResourceType: Partial<Record<ResourceType, string>> = {
  ec2: "Stopped instances are charged for the attached EBS volumes.",
  vm: "Stopped virtual machines are charged for the attached Persistent Disks.",
  az_vm:
    "Stopped virtual machines are charged for the attached  Managed Disks.",
};
