import { commitmentsRecommendationsFiltersSelector } from "./commitmentsRecommendationsFiltersSelector";
import { RootState } from "../../../../store";
import { SavingsPlanAccountScope } from "../../../../../services/cloudchipr.api";

export const commitmentsRecommendationsAccountScopeSelector = (
  state: RootState,
): SavingsPlanAccountScope | undefined => {
  const filters = commitmentsRecommendationsFiltersSelector(state);

  return filters.account_scope;
};
