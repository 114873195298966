import { RootState } from "../../../../store";
import { commitmentsSliceSelector } from "../commitmentsSliceSelector";
import { UtilizationAndCoverageDetailsDrawer } from "../../../utils/types/types";

export const commitmentsDetailsDrawerDetailsSelector = (
  state: RootState,
): UtilizationAndCoverageDetailsDrawer => {
  const slice = commitmentsSliceSelector(state);

  return slice.detailsDrawer;
};
