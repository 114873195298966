import { liveUsageMgmtSelectedResourcesByResourceTypeSelector } from "./liveUsageMgmtSelectedResourcesByResourceTypeSelector";
import { RootState } from "../../../store";
import { ResourceType } from "../../../../services/cloudchipr.api";

export const liveUsageMgmtResourcesProtectDisabledSelector = (
  state: RootState,
  resourceType: ResourceType,
) => {
  const selectedResources =
    liveUsageMgmtSelectedResourcesByResourceTypeSelector(state, resourceType);

  if (!selectedResources) {
    return true;
  }

  return selectedResources.every((item) => {
    return (
      item.resource.account.provider_access_type === "read" ||
      item.resource.marked_for_deletion
    );
  });
};
