import { FC, useCallback } from "react";
import { CommitmentsFilterSelectTriggerComponent } from "./CommitmentsFilterSelectTriggerComponent";
import { DropdownSelect } from "../../../../../../../common/select/dropdown-select/DropdownSelect";

export type FilterSelectOptions = {
  value: string;
  label: string;
  tooltip?: string;
  disabled?: boolean;
}[];

interface CommitmentsFilterSelectProps {
  label: string;
  selectedValues: string[];
  onChange(value: string[]): void;
  options: FilterSelectOptions;
  showSearch?: boolean;
}

export const CommitmentsFilterSelect: FC<CommitmentsFilterSelectProps> = ({
  label,
  selectedValues,
  onChange,
  options,
  showSearch,
}) => {
  const optionChangeHandler = useCallback(
    (selectedValues: string[]) => {
      if (selectedValues?.length === 0) {
        const optionsValues = options.map((item) => item.value);
        onChange(optionsValues);
      } else {
        onChange(selectedValues);
      }
    },
    [onChange, options],
  );
  return (
    <DropdownSelect
      listWidth={200}
      submitHandlerOnClose={optionChangeHandler}
      label={label}
      options={options}
      TriggerComponent={CommitmentsFilterSelectTriggerComponent}
      initialSelectedValues={selectedValues}
      values={selectedValues}
      showSearch={showSearch}
    />
  );
};
