import { FC, useCallback, useEffect, useState } from "react";
import { GlobalFilter } from "../../../../../../storybook/data-grid/toolbar/GlobalFilter";
import { useTaskManagementContext } from "../../TaskManagementProvider";
import { useAppSelector } from "../../../../../../store/hooks";
import { tasksListIsEmptySelector } from "../../../../../../store/task-management/selectors/data/tasksListIsEmptySelector";

export const TaskManagementSearch: FC = () => {
  const { gridApi } = useTaskManagementContext();
  const empty = useAppSelector(tasksListIsEmptySelector);

  const [searchValue, setSearchValue] = useState("");

  const quickFilter = gridApi?.getQuickFilter();

  const searchChangeHandler = useCallback(
    (keyword: string) => {
      gridApi?.setGridOption("quickFilterText", keyword);

      setSearchValue(keyword);
    },
    [gridApi],
  );

  useEffect(() => {
    setSearchValue(quickFilter ?? "");
  }, [quickFilter]);

  return (
    <GlobalFilter
      size="xsmall"
      disabled={empty}
      globalFilter={searchValue}
      setGlobalFilter={searchChangeHandler}
    />
  );
};
