import { Stack, Typography } from "@mui/material";
import { FC } from "react";
import { ChartColorsType } from "../../../utils/types/types";
import { LegendItem } from "../../common/legend/LegendItem";
import { TypographyWithTooltip } from "../../../../../../components/common/TypographyWithTooltip";
import { money } from "../../../../../../utils/numeral/money";

export interface PieLegendContentProps {
  keys: string[];
  data?: { name: string; value: number }[];
  highlightable: boolean;
  selectable: boolean;
  colors: ChartColorsType;
  visibleKeys: string[];
  setVisibleKeys(key: string[]): void;
  legendItemLabelFormatter?(key: string): string;
}

export const PieLegendContent: FC<PieLegendContentProps> = ({
  colors,
  keys,
  highlightable,
  setVisibleKeys,
  selectable,
  visibleKeys,
  legendItemLabelFormatter,
  data,
}) => {
  return (
    <Stack>
      {keys.map((key) => {
        const value = data?.find((item) => item.name === key)?.value ?? 0;

        return (
          <LegendItem
            key={key}
            itemKey={key}
            allKeys={keys}
            colors={colors}
            selectable={selectable}
            visibleKeys={visibleKeys}
            setVisibleKeys={setVisibleKeys}
            highlightable={highlightable}
            legendItemLabelFormatter={legendItemLabelFormatter}
          >
            <Stack direction="row" alignItems="center" overflow="hidden">
              <TypographyWithTooltip title={`${key} -`} variant="body1" />

              <Typography variant="body2" fontWeight="bold" ml={0.5}>
                {money(value)}
              </Typography>
            </Stack>
          </LegendItem>
        );
      })}
    </Stack>
  );
};
