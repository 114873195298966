import { FC, Fragment, useCallback } from "react";
import { Column } from "@tanstack/react-table";
import { DebouncedInput } from "../common/DebouncedInput";
import { getSortedUniqueValues } from "../utils/helpers";

interface FilterProps {
  column: Column<any>;
}

export const Filter: FC<FilterProps> = ({ column }) => {
  const columnFilterValue = (column.getFilterValue() || "") as string;

  const sortedUniqueValues = getSortedUniqueValues(column);

  const filterChangeHandler = useCallback(
    (value: any) => column.setFilterValue(value),
    [column],
  );

  if (!column.getCanFilter()) return null;

  return (
    <Fragment>
      <datalist id={column.id + "list"}>
        {sortedUniqueValues.map((value) => (
          <option value={value} key={value} />
        ))}
      </datalist>
      <DebouncedInput
        variant="standard"
        sx={{ minWidth: 90 }}
        size="small"
        type="search"
        value={columnFilterValue}
        onChange={filterChangeHandler}
        placeholder="Search"
        inputProps={{ list: column.id + "list" }}
      />
    </Fragment>
  );
};
