import { FilterSet } from "../../../../../../services/cloudchipr.api";

export const getDefaultFilterTemplates = (data: FilterSet[]) => {
  const defaultTemplate = data.find(
    ({ source, is_default }) => is_default && source === "cloudchipr",
  );

  const c8rTemplates = data.filter(
    ({ id, source }) => source === "cloudchipr" && defaultTemplate?.id !== id,
  );

  if (!defaultTemplate) {
    return c8rTemplates;
  }

  return [defaultTemplate, ...c8rTemplates];
};
