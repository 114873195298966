import { createAsyncThunk } from "@reduxjs/toolkit";
import { cloudChiprApi } from "../../../../services/cloudchipr.api";
import {
  setTaskManagementDrawerOpen,
  setTaskManagementTask,
} from "../../taskManagementSlice";

export const taskManagementInitiateEmptyTaskThunk = createAsyncThunk(
  "taskManagement/taskManagementInitiateEmptyTask",
  async (_, { dispatch }) => {
    const { getUsersMeOrganisationsCurrentTasksProperties } =
      cloudChiprApi.endpoints;

    const response = await dispatch(
      getUsersMeOrganisationsCurrentTasksProperties.initiate(),
    ).unwrap();

    const status_id = response?.statuses?.at(0)?.id ?? "";
    const priority_id = response?.priorities?.at(0)?.id ?? "";
    const action_id = response?.actions?.at(0)?.id ?? "";

    dispatch(
      setTaskManagementTask({
        status_id,
        priority_id,
        action_id,
        environment_id: null,
        description: "",
        assignees: null,
        due_date: null,
      }),
    );

    dispatch(setTaskManagementDrawerOpen(true));
  },
);
