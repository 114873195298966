import { createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../../../store";
import { alertsDataThresholdSelector } from "../../selectors/common/data/alertsDataThresholdSelector";
import { setAlertsData } from "../../alertsSlice";
import { AlertThresholdTypeItem } from "../../utils/types/types";

export const addCostAnomalyAlertThresholdThunk = createAsyncThunk(
  "alerts/addCostAnomalyAlertThreshold",
  async (_, { dispatch, getState }) => {
    const state = getState() as RootState;

    const threshold = alertsDataThresholdSelector(state);

    if (!threshold) {
      return;
    }
    const thresholdItems = threshold?.threshold_items
      ? [...(threshold?.threshold_items ?? [])]
      : [];
    const prevUnit = thresholdItems[0]?.unit;
    const newItem: AlertThresholdTypeItem = {
      unit: prevUnit === "percent" ? "cost" : "percent",
      amount: "1",
    };
    thresholdItems.push(newItem);
    dispatch(
      setAlertsData({
        threshold: {
          ...threshold,
          threshold_items: thresholdItems,
        },
      }),
    );
  },
);
