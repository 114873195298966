import { createAsyncThunk } from "@reduxjs/toolkit";
import { cloudChiprApi } from "../../services/cloudchipr.api";

export const getCurrentSubscriptionThunk = createAsyncThunk(
  "subscriptions/getCurrent",
  async (_, { dispatch }) => {
    const { getUsersMeSubscriptionsCurrent } = cloudChiprApi.endpoints;

    try {
      await dispatch(getUsersMeSubscriptionsCurrent.initiate()).unwrap();

      return true;
    } catch (e) {
      return false;
    }
  },
);
