import { costAndUsageSumSetupWidgetDataResponseSelector } from "./costAndUsageSumSetupWidgetDataResponseSelector";
import { RootState } from "../../../../../store";

export const costAndUsageSumSetupWidgetDataLoadingSelector = (
  state: RootState,
) => {
  const response = costAndUsageSumSetupWidgetDataResponseSelector(state);

  return !!response?.isLoading;
};
