import { selectedSavingsPlanDataSelector } from "./selectedSavingsPlanDataSelector";
import { RootState } from "../../../../../store";

export const selectedSavingsPlanURLSelector = (
  state: RootState,
): string | undefined => {
  const savingsPlan = selectedSavingsPlanDataSelector(state);

  return savingsPlan?.url;
};
