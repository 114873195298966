import { FC, useCallback } from "react";
import { MessageActions } from "./MessageActions";
import { usePostUsersMeWebhookIntegrationsTestsMutation } from "../../../../../../../../services/cloudchipr.api";
import { useMessageHook } from "../../../../../utils/hooks/useMessage.hook";

interface WebhookMessageActionsProps {
  url?: string;
  content_type?: string;
}

export const WebhookMessageActions: FC<WebhookMessageActionsProps> = ({
  url,
  content_type,
}) => {
  const [trigger, { isLoading }] =
    usePostUsersMeWebhookIntegrationsTestsMutation();

  const { status, error, cleanError, updateError, successStatusUpdate } =
    useMessageHook();

  const webhookMessageHandler = useCallback(() => {
    if (!url || !content_type) return;
    cleanError();

    trigger({ body: { url, content_type } })
      .unwrap()
      .then(() => successStatusUpdate())
      .catch((error) => {
        updateError(error?.data?.message);
      });
  }, [
    trigger,
    url,
    content_type,
    cleanError,
    updateError,
    successStatusUpdate,
  ]);

  return (
    <MessageActions
      isLoading={isLoading}
      status={status}
      error={error}
      sendMessageHandler={webhookMessageHandler}
      isDisabled={!url || !content_type}
    />
  );
};
