export const getSourceAndExecutedByFromSelectValues = (values: string[]) => {
  const { source, executedBy } = values.reduce(
    (filters, value) => {
      const [source, executedBy] = value.split(sourceAndExecutedBySeparator);

      filters.source.push(source);
      filters.executedBy.push(executedBy);

      return filters;
    },
    { source: [] as string[], executedBy: [] as string[] },
  );

  return {
    source: Array.from(new Set(source)),
    executedBy: Array.from(new Set(executedBy)),
  };
};

export const sourceAndExecutedBySeparator = "_sep_";
