import { FC } from "react";
import { Grid, Typography } from "@mui/material";
import { Emails } from "../../../../../../../services/cloudchipr.api";
import { IntegrationNotification } from "../../../../../common/notification-selection/utils/types/types";
import { AutomationActionNotifications } from "../../../../../automations/common/components/preview/notifications/AutomationActionNotifications";

interface SchedulePreviewNotificationsProps {
  notifications?: IntegrationNotification[] | null;
  minThreshold?: number | null;
  snoozed?: boolean | null;
  emails: Emails;
}

export const OffHoursPreviewNotifications: FC<
  SchedulePreviewNotificationsProps
> = ({ notifications, minThreshold, snoozed, emails }) => {
  return (
    <Grid container px={2} mb={4} alignItems="baseline">
      <Grid item sm={4}>
        <Typography variant="subtitle2">Action Notifications</Typography>
      </Grid>

      <Grid item sm={8}>
        <AutomationActionNotifications
          emails={emails}
          snoozed={snoozed}
          minThreshold={minThreshold}
          notifications={notifications}
        />
      </Grid>
    </Grid>
  );
};
