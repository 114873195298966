import { FC, useCallback, useEffect, useState } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import { TextField } from "@mui/material";

interface CategoryNameAutocompleteProps {
  name: string;
  options: string[];
  onSelect(name: string): void;
  disabled?: boolean;
}

export const CategoryNameAutocomplete: FC<CategoryNameAutocompleteProps> = ({
  options,
  name,
  onSelect,
  disabled,
}) => {
  const [value, setValue] = useState(name);

  const changeHandler = useCallback(
    (_: any, value: string) => {
      onSelect(value);
      setValue(value);
    },
    [onSelect],
  );

  const inputValueChangeHandler = useCallback(
    (e: any) => {
      setValue(e.target.value);
      onSelect(e.target.value);
    },
    [onSelect],
  );

  const renderInput = useCallback(
    (params: any) => (
      <TextField
        {...params}
        autoFocus
        name="emails"
        variant="outlined"
        placeholder="Type a new category or select an existing one"
        onChange={inputValueChangeHandler}
      />
    ),
    [inputValueChangeHandler],
  );

  useEffect(() => setValue(name), [name]);

  return (
    <Autocomplete
      freeSolo
      fullWidth
      disabled={disabled}
      disableClearable
      size="small"
      sx={{ mt: 0.5 }}
      value={value}
      inputValue={value}
      options={options}
      onChange={changeHandler}
      renderInput={renderInput}
    />
  );
};
