import { formatDateData } from "./formatDateData";
import { formatPreviousDateData } from "./formatPreviousDateData";
import {
  Dates,
  ResourceExplorerCostsDateDetails,
} from "../../../../../../services/cloudchipr.api";

type Args = {
  date?: Dates;
  quarterStartMonth?: number;
  prevDate?: Partial<ResourceExplorerCostsDateDetails>;
};

export const generateDatesCompareData = ({
  date,
  prevDate,
  quarterStartMonth,
}: Args) => {
  const formattedDate = date
    ? formatDateData(date, quarterStartMonth)
    : undefined;

  const endDateLabel = formatPreviousDateData(
    {
      ...date,
      from: prevDate?.from_date,
      to: prevDate?.to_date,
    },
    quarterStartMonth,
  );

  const dateInfo = {
    startDateLabel: formattedDate?.label,
    startDateRange: formattedDate?.dateRange,
    endDateLabel: endDateLabel?.label,
    endDateRange: endDateLabel?.range,
  };

  const title = `${dateInfo.startDateLabel} ${
    dateInfo.startDateRange && `(${dateInfo.startDateRange})`
  } compared with ${dateInfo.endDateLabel} ${
    dateInfo.endDateRange && `(${dateInfo.endDateRange})`
  } `;

  return {
    ...dateInfo,
    title,
  };
};
