import { ThresholdItem } from "../../../../../../../../services/cloudchipr.api";
import { money } from "../../../../../../../../utils/numeral/money";

export const joinThresholds = (thresholds: ThresholdItem[]): string[] => {
  return thresholds
    .toSorted((first) => {
      if (first.unit === "percent") {
        return -1;
      }
      return 1;
    })
    .map((threshold) => {
      if (threshold.unit === "cost") {
        return money(threshold.amount);
      }

      if (threshold.unit === "day") {
        return `${threshold.amount} ${threshold.amount === 1 ? "day" : "days"}`;
      }

      return `${threshold.amount}%`;
    });
};
