import { FC, useCallback } from "react";
import { Button, DialogActions, Stack } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { BillingExportInitialValues } from "../../../ResourceExplorerBillingExportDialog";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../../../../../store/hooks";
import { createResourceExplorerGcpBillingExportDataThunk } from "../../../../../../../../../../../../store/resource-explorer/thunks/widgets/gcp-billing-data/createResourceExplorerGcpBillingExportDataThunk";
import { createResourceExplorerGcpBillingExportDataLoadingSelector } from "../../../../../../../../../../../../store/resource-explorer/selectors/billing/gcp-billing-data/createResourceExplorerGcpBillingExportDataLoadingSelector";
import { setBillingStatusProcess } from "../../../../../../../../../../../../store/common/commonSlice";

interface ResourceExplorerBillingExportDialogBillingDetailsActionsProps {
  onNextStep(): void;
  onCloseDialog(): void;
  primaryBtnDisabled: boolean;
  values: BillingExportInitialValues;
  onSkipStep(count: number): void;
  onSuccess?(): void;
}

export const ResourceExplorerBillingExportDialogBillingDetailsActions: FC<
  ResourceExplorerBillingExportDialogBillingDetailsActionsProps
> = ({
  onNextStep,
  onCloseDialog,
  primaryBtnDisabled,
  values,
  onSkipStep,
  onSuccess,
}) => {
  const dispatch = useAppDispatch();

  const creationLoading = useAppSelector(
    createResourceExplorerGcpBillingExportDataLoadingSelector,
  );

  const primaryBtnClickHandler = useCallback(async () => {
    // TODO: Add Error interface in api spec
    const response: any = await dispatch(
      createResourceExplorerGcpBillingExportDataThunk({
        project_id: values.projectId,
        dataset: values.dataSetName,
        table: values.table,
      }),
    ).unwrap();

    if (response?.status === 403) {
      onNextStep();
    } else if (response?.id) {
      dispatch(setBillingStatusProcess("gcp"));
      onSkipStep(2);
      onSuccess?.();
    }
  }, [onNextStep, values, dispatch, onSkipStep, onSuccess]);

  return (
    <DialogActions sx={{ px: 4, my: 1 }}>
      <Stack direction="row" spacing={2}>
        <Button color="primary" onClick={onCloseDialog} variant="outlined">
          Cancel
        </Button>
        <LoadingButton
          variant="contained"
          color="primary"
          disabled={primaryBtnDisabled}
          onClick={primaryBtnClickHandler}
          loading={creationLoading}
        >
          Next
        </LoadingButton>
      </Stack>
    </DialogActions>
  );
};
