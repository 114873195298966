import { resourceExplorerViewsDataSelector } from "../../../../resource-explorer/selectors/all-resource-explorers/resourceExplorerViewsDataSelector";
import { RootState } from "../../../../store";

export const widgetViewNameByViewIdIdSelector = (
  state: RootState,
  viewId?: string,
): string | undefined => {
  if (!viewId) {
    return;
  }
  const views = resourceExplorerViewsDataSelector(state);

  return views.find((item) => item.id === viewId)?.name;
};
