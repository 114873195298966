import { Account } from "../../../services/cloudchipr.api";
import { AccountSortByFields } from "../accountsSlice";
import { nonWorkingAccountStatuses } from "../../../utils/constants/accounts";

export const accountsSortingCombiner = (
  accounts: Account[] | null,
  sortBy: AccountSortByFields,
): Account[] | null => {
  if (!accounts) {
    return null;
  }

  if (!sortBy || sortBy === "custom") {
    return accounts;
  }

  const { active, inactive } = accounts.reduce(
    (acc, account) => {
      if (nonWorkingAccountStatuses.has(account.status)) {
        acc.inactive.push(account);
      } else {
        acc.active.push(account);
      }

      return acc;
    },
    { active: [], inactive: [] } as Record<string, Account[]>,
  );

  const sortByCB =
    sortBy === "ems" || sortBy === "total_costs"
      ? numberSortCallback(sortBy)
      : stringSortCallback(sortBy);

  const sortedInactive = [...inactive].sort(sortByCB);
  const sortedActive = [...active].sort(sortByCB);
  return sortedActive.concat(sortedInactive);
};

const numberSortCallback =
  (sortBy: "ems" | "total_costs") => (a: Account, b: Account) =>
    b[sortBy] - a[sortBy];

const stringSortCallback =
  (sortBy: "provider_account_id" | "provider_account_name") =>
  (a: Account, b: Account) => {
    let current = a[sortBy];
    let next = b[sortBy];

    if (!current || !next) {
      return 0;
    }

    current = current.toLowerCase();
    next = next.toLowerCase();

    if (current < next) {
      return -1;
    }

    if (current > next) {
      return 1;
    }

    return 0;
  };
