import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  AutomationsData,
  OffHoursSpecificData,
} from "../../utils/types/common";
import {
  setAutomationData,
  setAutomationInitialized,
  setOffHoursSpecificData,
} from "../../automationsSlice";

interface SetOffHourDataThunkArguments {
  offHoursSpecificData: Partial<OffHoursSpecificData>;
  offHoursAutomationData: Partial<AutomationsData>;
}

export const setOffHourDataThunk = createAsyncThunk(
  "automations/setOffHourData",
  async (
    {
      offHoursSpecificData,
      offHoursAutomationData,
    }: SetOffHourDataThunkArguments,
    { dispatch },
  ) => {
    dispatch(setOffHoursSpecificData(offHoursSpecificData));
    dispatch(setAutomationData(offHoursAutomationData));

    dispatch(setAutomationInitialized(true));
  },
);
