import { filterRecommendationByTagFilterInOperator } from "./filterRecommendationByTagFilterInOperator";
import { filterRecommendationByTagFilterExistsOperator } from "./filterRecommendationByTagFilterExistsOperator";
import { filterRecommendationByTagFilterContainsOperator } from "./filterRecommendationByTagFilterContainsOperator";
import { filterRecommendationByTagFilterNotContainsOperator } from "./filterRecommendationByTagFilterNotContainsOperator";
import { filterRecommendationByTagFilterNotInOperator } from "./filterRecommendationByTagFilterNotInOperator";
import { TagFilter } from "../../../../components/common/select/tag-filter-dropdown-select/utils/types";

interface ApplyRecommendationTagFilterArgs {
  operator: TagFilter["operator"];
  tagFilterKey: string;
  tagFilterValues: Set<string>;
  resourceTagsMap: Map<string, string>;
}
export const applyRecommendationTagFilter = ({
  tagFilterKey,
  operator,
  tagFilterValues,
  resourceTagsMap,
}: ApplyRecommendationTagFilterArgs) => {
  const resourceTagValue = resourceTagsMap.get(tagFilterKey);
  switch (operator) {
    case "in":
      return filterRecommendationByTagFilterInOperator(
        tagFilterValues,
        resourceTagValue,
      );
    case "not_in":
      return filterRecommendationByTagFilterNotInOperator(
        tagFilterValues,
        resourceTagValue,
      );
    case "contains":
      return filterRecommendationByTagFilterContainsOperator(
        tagFilterValues,
        resourceTagValue,
      );
    case "does_not_contain":
      return filterRecommendationByTagFilterNotContainsOperator(
        tagFilterValues,
        resourceTagValue,
      );
    case "exists":
      return filterRecommendationByTagFilterExistsOperator(
        resourceTagsMap,
        tagFilterKey,
      );
    case "does_not_exist":
      return !filterRecommendationByTagFilterExistsOperator(
        resourceTagsMap,
        tagFilterKey,
      );
    default:
      return false;
  }
};
