import { FC } from "react";
import { Button, Stack, Typography } from "@mui/material";
import {
  MessagesDialog,
  MessagesDialogProps,
} from "../../common/MessagesDialog";
import { MissingPermissionsRecheck } from "../../common/MissingPermissionsRecheck";
import { useGetUsersMeProvidersAzureAccountsByAccountIdMissingRolesQuery } from "../../../../../../../../../services/cloudchipr.api";
import { CommandSnippet } from "../../../../../../../common/connectors/components/CommandSnippet";
import { getAzureRoleAssignmentCommand } from "../../../utils/helpers/getAzureRoleAssignmentCommand";

interface AzureMissingRolesDialog extends MessagesDialogProps {
  accountId: string;
  servicePrincipal?: string;
  subscriptionId?: string;
}
export const AzureMissingRolesDialog: FC<AzureMissingRolesDialog> = ({
  onClose,
  open,
  accountId,
  servicePrincipal,
  subscriptionId,
}) => {
  const { data, refetch } =
    useGetUsersMeProvidersAzureAccountsByAccountIdMissingRolesQuery(
      { accountId: accountId || "" },
      { skip: !accountId },
    );

  if (!servicePrincipal) {
    return null;
  }

  return (
    <MessagesDialog
      name="Permissions"
      open={open}
      onClose={onClose}
      actions={
        <Button color="tertiary" onClick={onClose}>
          Close
        </Button>
      }
    >
      <Stack spacing={2}>
        <Typography variant="body2">
          To assign role, please run the following command in your terminal.
        </Typography>

        {data?.map(({ title, name }) => {
          const role = title || name;

          return role && servicePrincipal ? (
            <CommandSnippet
              key={role}
              code={getAzureRoleAssignmentCommand(
                servicePrincipal ?? "",
                role,
                `/subscriptions/${subscriptionId}`,
              )}
            >
              az role assignment create --assignee{" "}
              <Typography
                fontFamily="monospace"
                color="warning.light"
                component="span"
              >
                {servicePrincipal}
              </Typography>{" "}
              --role{" "}
              <Typography
                component="span"
                fontFamily="monospace"
                color="success.light"
              >
                "{role}"
              </Typography>{" "}
              --scope /subscriptions/
              <Typography
                fontFamily="monospace"
                color="warning.light"
                component="span"
              >
                {subscriptionId}
              </Typography>
            </CommandSnippet>
          ) : null;
        })}

        <MissingPermissionsRecheck
          forApi
          refetch={refetch}
          accountId={accountId}
        />
      </Stack>
    </MessagesDialog>
  );
};
