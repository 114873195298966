import { FC } from "react";
import { ForecastedCostHeaderCell } from "../../../../../../../../../resource-explorer/components/resource-explorer-card/components/resource-explorer-data-grid/components/columns/components/common/ForecastedCostHeaderCell";
import { useAppSelector } from "../../../../../../../../../../../store/hooks";
import { costBreakdownWidgetForecastOptionSelector } from "../../../../../../../../../../../store/dashboards/selectors/widgets/cost-breakdown/widget-view/costBreakdownWidgetForecastOptionSelector";
import { costBreakdownWidgetForecastedCostsSumSelector } from "../../../../../../../../../../../store/dashboards/selectors/widgets/cost-breakdown/widget-view/costBreakdownWidgetForecastedCostsSumSelector";

interface CostBreakdownWidgetTableForecastedCostHeaderCellProps {
  widgetId: string;
}
export const CostBreakdownWidgetTableForecastedCostHeaderCell: FC<
  CostBreakdownWidgetTableForecastedCostHeaderCellProps
> = ({ widgetId }) => {
  const forecastOption = useAppSelector((state) =>
    costBreakdownWidgetForecastOptionSelector(state, widgetId),
  );

  const forecastedCost = useAppSelector((state) =>
    costBreakdownWidgetForecastedCostsSumSelector(state, widgetId),
  );

  if (!forecastOption) {
    return null;
  }

  return (
    <ForecastedCostHeaderCell
      forecastOption={forecastOption}
      forecastedCost={forecastedCost}
    />
  );
};
