import { FC } from "react";
import { Stack } from "@mui/material";
import { CommitmentsDrawerChartHeader } from "../../../../common/components/drawer/CommitmentsDrawerChartHeader";
import { useAppSelector } from "../../../../../../../store/hooks";
import { utilizationAndCoverageDetailsLoadingSelector } from "../../../../../../../store/commitments/selectors/coverage-and-utilization/details-drawer/data/utilizationAndCoverageDetailsLoadingSelector";
import { CommitmentsDrawerContentInfoCard } from "../../../../common/components/drawer/CommitmentsDrawerContentInfoCard";
import { CommitmentsProgressBar } from "../../../../common/components/CommitmentsProgressBar";
import { money } from "../../../../../../../utils/numeral/money";
import { savingsPlanCoverageDetailsMetaDataSelector } from "../../../../../../../store/commitments/selectors/coverage-and-utilization/details-drawer/resource-type/savingsPlanCoverageDetailsMetaDataSelector";

export const SavingsPlanCoverageDrawerChartHeader: FC = () => {
  const metadata = useAppSelector(savingsPlanCoverageDetailsMetaDataSelector);
  const isLoading = useAppSelector(
    utilizationAndCoverageDetailsLoadingSelector,
  );

  if (!metadata) {
    return null;
  }

  return (
    <CommitmentsDrawerChartHeader>
      <Stack direction="row" flexWrap="wrap" gap={1} mt={1.5}>
        <CommitmentsDrawerContentInfoCard
          cardSx={cardSx}
          loading={isLoading}
          title="Coverage"
        >
          <CommitmentsProgressBar width="100%" value={metadata.coverage} />
        </CommitmentsDrawerContentInfoCard>

        <CommitmentsDrawerContentInfoCard
          cardSx={cardSx}
          loading={isLoading}
          title="Spend Covered by Commitment"
          value={money(metadata.covered_usage)}
        />

        <CommitmentsDrawerContentInfoCard
          cardSx={cardSx}
          loading={isLoading}
          title="Not Covered (On-Demand)"
          value={money(metadata.uncovered_usage)}
        />
      </Stack>
    </CommitmentsDrawerChartHeader>
  );
};

const cardSx = { minWidth: 256, flex: 1 };
