export const filterRecommendationByTagFilterContainsOperator = (
  tagsFilterValues: Set<string>,
  resourceTagValue?: string,
) => {
  let includesValue: boolean = false;
  tagsFilterValues.forEach((filterValue) => {
    if (resourceTagValue?.includes(filterValue)) {
      includesValue = true;
    }
  });
  return !!resourceTagValue && includesValue;
};
