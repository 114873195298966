import { taskManagementTargetSelector } from "./taskManagementTargetSelector";
import { RootState } from "../../../../store";
import { TargetType } from "../../../../../services/cloudchipr.api";

export const taskManagementTargetTypeSelector = (
  state: RootState,
): TargetType | undefined => {
  const target = taskManagementTargetSelector(state);

  return target?.type;
};
