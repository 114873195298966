import { FC, useCallback } from "react";
import Stack from "@mui/material/Stack";
import { useAuth0 } from "@auth0/auth0-react";
import { Button, Typography } from "@mui/material";
import { PageHeaderWithLogo } from "../PageHeaderWithLogo";
import ApprovedReviewIcon from "../../../assets/images/approved-review.png";

export const UserInvitationRequestApproved: FC = () => {
  const { logout } = useAuth0();

  const logoutClickHandler = useCallback(() => {
    logout({ returnTo: window.location.origin });
  }, [logout]);

  return (
    <Stack height="100vh" bgcolor="grey.50">
      <PageHeaderWithLogo />
      <Stack alignItems="center" mt={20} spacing={3.5} px={1}>
        <Typography variant="h4" color="primary.dark" fontWeight="medium">
          Invitation Request Approved
        </Typography>
        <img width={140} src={ApprovedReviewIcon} alt="Invitation Required" />
        <Typography
          variant="h6"
          textAlign="center"
          color="text.secondary"
          fontWeight="normal"
        >
          Your request to join organization on Cloudchipr has been approved.
          <br />
          Please go back to login.
        </Typography>
        <Stack direction="row" spacing={3.5}>
          <Button
            onClick={logoutClickHandler}
            sx={{ textTransform: "none" }}
            variant="outlined"
          >
            Back to Login
          </Button>
        </Stack>
      </Stack>
    </Stack>
  );
};
