import { allAccountsSelectOptionsSelector } from "./allAccountsSelectOptionsSelector";
import { RootState } from "../../../store";
import { ProviderType } from "../../../../services/cloudchipr.api";

export const allAccountsIdsByProviderSelector = (
  state: RootState,
  provider?: ProviderType,
) => {
  const allAccounts = allAccountsSelectOptionsSelector(state);

  if (!provider) {
    return;
  }

  return allAccounts
    .filter(({ accountType }) => accountType === provider)
    .map(({ value }) => value);
};
