import { Row } from "@tanstack/react-table";
import { CostAnomalyAlertsDataGridDataType } from "../../../../../../../../../store/alerts/utils/types/types";

export const alertsCostAnomalyTimeIntervalFilter = (
  row: Row<CostAnomalyAlertsDataGridDataType>,
  _: string,
  keyword: string,
) => {
  const interval = row.original.time_interval;
  const timeInterval =
    `${interval.time_interval} ${interval.time_interval_unit}`.toLowerCase();
  const filterValue = keyword.toLowerCase().trim();

  return timeInterval.includes(filterValue);
};
