import { automationNameSelector } from "./fields/automationNameSelector";
import { automationAccountIdsSelector } from "./fields/automationAccountIdsSelector";
import { automationRegionsSelector } from "./fields/automationRegionsSelector";
import { automationNotificationsSelector } from "./fields/automationNotificationsSelector";
import { automationEmailsSelector } from "./fields/automationEmailsSelector";
import { automationResourcesSelector } from "./fields/automationResourcesSelector";
import { RootState } from "../../../store";

export const automationDataToValidateSelector = (state: RootState) => {
  const name = automationNameSelector(state);
  const accountIds = automationAccountIdsSelector(state);
  const regions = automationRegionsSelector(state);
  const notifications = automationNotificationsSelector(state);
  const emails = automationEmailsSelector(state);
  const filter = automationResourcesSelector(state);

  return {
    name,
    accountIds,
    regions,
    filter,
    notifications,
    emails,
  };
};
