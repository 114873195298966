import { RootState } from "../../../../store";
import { cloudChiprApi } from "../../../../../services/cloudchipr.api";
import { dimensionCreateFixedCacheKey } from "../../../thunks/dimensions/createDimensionThunk";

const dimensionCreateSelector =
  cloudChiprApi.endpoints.postUsersMeOrganisationsCurrentDimensions.select(
    dimensionCreateFixedCacheKey,
  );

export const dimensionCreateLoadingSelector = (state: RootState) => {
  return !!dimensionCreateSelector(state)?.isLoading;
};
