import { createAsyncThunk } from "@reduxjs/toolkit";
import { enqueueSnackbar } from "notistack";
import { duplicateOffHoursThunk } from "./duplicateOffHoursThunk";
import { duplicateWorkflowThunk } from "./duplicateWorkflowThunk";
import { cloudChiprApi } from "../../../services/cloudchipr.api";
import { AutomationType } from "../../automations/utils/types/common";
import { getWorkflowsThunk } from "../../automations/thunks/wokrflows/getWorkflowsThunk";

interface DuplicateOffHourThunkArgs {
  id: string;
  name: string;
  type: AutomationType;
}
export const duplicateAutomationThunk = createAsyncThunk(
  "schedules/duplicateAutomation",
  async ({ id, name, type }: DuplicateOffHourThunkArgs, { dispatch }) => {
    const { getUsersMeOffHoursSchedules } = cloudChiprApi.endpoints;

    if (type === "offHours") {
      const response = await dispatch(
        duplicateOffHoursThunk({
          id,
          name,
        }),
      ).unwrap();
      if (response) {
        enqueueSnackbar("Off Hours successfully duplicated.", {
          variant: "snackbarAlert",
          AlertSnackBarProps: {
            severity: "success",
          },
        });
        dispatch(
          getUsersMeOffHoursSchedules.initiate({}, { forceRefetch: true }),
        );
      }
      return response;
    } else {
      const response = await dispatch(
        duplicateWorkflowThunk({
          id,
          name,
        }),
      ).unwrap();
      if (response) {
        enqueueSnackbar("Workflow successfully duplicated.", {
          variant: "snackbarAlert",
          AlertSnackBarProps: {
            severity: "success",
          },
        });
        dispatch(getWorkflowsThunk(true));
      }
      return response;
    }
  },
);
