import { FC, useCallback } from "react";
import { Stack } from "@mui/material";
import { ResourceExplorerBreadcrumbItem } from "./ResourceExplorerBreadcrumbItem";
import { ResourceExplorerBreadcrumbsMenu } from "./ResourceExplorerBreadcrumbsMenu";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../store/hooks";
import { resourceExplorerPathSelector } from "../../../../../../../store/resource-explorer/selectors/current-resource-explorer/path/resourceExplorerPathSelector";
import { changePathOnBreadcrumbSelectThunk } from "../../../../../../../store/resource-explorer/thunks/widgets/path/changePathOnBreadcrumbSelectThunk";
import {
  breadcrumbPathEndPartLength,
  breadcrumbPathStartPartLength,
} from "../../utils/constants/breadcrumb";

export const ResourceExplorerBreadcrumbsWithMenu: FC = () => {
  const dispatch = useAppDispatch();

  const path = useAppSelector(resourceExplorerPathSelector);

  const breadcrumbClickHandler = useCallback(
    (index: number) => {
      dispatch(changePathOnBreadcrumbSelectThunk(index));
    },
    [dispatch],
  );

  return (
    <Stack
      px={1}
      py={0.5}
      spacing={0.5}
      direction="row"
      borderRadius={3}
      bgcolor="grey.200"
      alignItems="center"
      overflow="auto"
    >
      {path.slice(0, breadcrumbPathStartPartLength).map((pathItem, index) => (
        <ResourceExplorerBreadcrumbItem
          pathItem={pathItem}
          index={index}
          key={pathItem.groupedBy + pathItem.name}
          onBreadcrumbClick={breadcrumbClickHandler}
          pathLength={path.length}
        />
      ))}
      <ResourceExplorerBreadcrumbsMenu />

      {path
        .slice(path.length - breadcrumbPathEndPartLength, path.length)
        .map((pathItem) => (
          <ResourceExplorerBreadcrumbItem
            pathItem={pathItem}
            index={path.findIndex((item) => item?.name === pathItem.name)}
            key={pathItem.groupedBy + pathItem.name}
            onBreadcrumbClick={breadcrumbClickHandler}
            pathLength={path.length}
          />
        ))}
    </Stack>
  );
};
