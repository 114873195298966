import { getResourceExplorerStorageSavingData } from "./getResourceExplorerStorageSavingData";
import { ResourceExplorerData } from "../../../../services/cloudchipr.api";

export const getResourceExplorerSettingData = (
  resourceExplorerData: ResourceExplorerData,
  isDefault?: boolean,
) => {
  const parsedData = getResourceExplorerStorageSavingData();

  return parsedData?.data && isDefault
    ? parsedData?.data
    : resourceExplorerData;
};
