import { useCallback, useMemo, useState } from "react";
import { useEffectOnceWhen } from "rooks";
import {
  GetUsersMeOrganisationsCurrentDashboardsByDashboardIdApiResponse,
  WidgetType,
} from "../../../../../services/cloudchipr.api";
import { setGlobalVarReadyWithTimeout } from "../../../common/utils/helpers/setGlobalVarReadyWithTimeout";

export type RegisterRequestState = (
  type: WidgetType,
  id: string,
  finished: boolean,
) => void;

export const useDashboardStateGlobalVar = (
  isSuccess: boolean,
  widgets?: GetUsersMeOrganisationsCurrentDashboardsByDashboardIdApiResponse["widgets"],
) => {
  const [finishedRequestsList, setFinishedRequestsList] = useState<
    Record<string, boolean>
  >({});

  const allFinished = useMemo(() => {
    return (
      (!widgets?.length ||
        Object.values(finishedRequestsList).filter((finished) => finished)
          .length === widgets?.length) &&
      isSuccess
    );
  }, [finishedRequestsList, widgets, isSuccess]);

  const registerRequestState: RegisterRequestState = useCallback(
    (type: WidgetType, id: string, finished: boolean) => {
      setFinishedRequestsList((prev) => ({
        ...prev,
        [getWidgetIdentifier(type, id)]: finished,
      }));
    },
    [],
  );

  useEffectOnceWhen(() => {
    setGlobalVarReadyWithTimeout();
  }, allFinished);

  useEffectOnceWhen(() => {
    widgets?.forEach((widget) => {
      setFinishedRequestsList((prev) => ({
        ...prev,
        [getWidgetIdentifier(widget.type, widget.id)]: false,
      }));
    });
  }, !!widgets?.length);

  return { registerRequestState };
};

const getWidgetIdentifier = (type: WidgetType, id: string) => `${type}-${id}`;
