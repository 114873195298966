import { FC, useCallback, useMemo } from "react";
import {
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
} from "@mui/material";
import { ProviderIcon } from "../../../../../../common/ProviderIcon";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../store/hooks";
import { automationProviderSelector } from "../../../../../../../store/automations/selectros/common/fields/automationProviderSelector";
import { setAutomationData } from "../../../../../../../store/automations/automationsSlice";
import { ProviderType } from "../../../../../../../services/cloudchipr.api";
import { getProviderName } from "../../../../../../../utils/helpers/providers/getProviderName";
import { awsDisabledTooltipTitleSelector } from "../../../../../../../store/automations/selectros/common/helpers-data/providers-disabled/awsDisabledTooltipTitleSelector";
import { gcpDisabledTooltipTitleSelector } from "../../../../../../../store/automations/selectros/common/helpers-data/providers-disabled/gcpDisabledTooltipTitleSelector";
import { azureDisabledTooltipTitleSelector } from "../../../../../../../store/automations/selectros/common/helpers-data/providers-disabled/azureDisabledTooltipTitleSelector";
import { automationIdSelector } from "../../../../../../../store/automations/selectros/common/automationIdSelector";
import { providersRegionsSelector } from "../../../../../../../store/automations/selectros/common/helpers-data/regions/providersRegionsSelector";

export const AutomationProviderSelection: FC = () => {
  const dispatch = useAppDispatch();

  const automationId = useAppSelector(automationIdSelector);
  const provider = useAppSelector(automationProviderSelector);
  const awsTooltip = useAppSelector(awsDisabledTooltipTitleSelector);
  const gcpTooltip = useAppSelector(gcpDisabledTooltipTitleSelector);
  const azureTooltip = useAppSelector(azureDisabledTooltipTitleSelector);
  const regions = useAppSelector(providersRegionsSelector);

  const changeHandler = useCallback(
    (_: unknown, value: any) => {
      const provider = value as ProviderType;

      if (!provider) {
        return;
      }

      dispatch(
        setAutomationData({
          provider,
          filter: [],
          accountIds: [],
          regions: regions[provider].map((region) => region.key),
        }),
      );
    },
    [dispatch, regions],
  );

  const providers = useMemo(() => {
    return [
      { provider: "aws", tooltip: awsTooltip },
      { provider: "gcp", tooltip: gcpTooltip },
      { provider: "azure", tooltip: azureTooltip },
    ] as { provider: ProviderType; tooltip: string }[];
  }, [awsTooltip, gcpTooltip, azureTooltip]);

  return (
    <ToggleButtonGroup
      exclusive
      size="small"
      color="primary"
      value={provider}
      onChange={changeHandler}
    >
      {providers.map(({ provider, tooltip }) => {
        return (
          <Tooltip key={provider} title={tooltip}>
            <ToggleButton
              value={provider}
              disabled={!!automationId || !!tooltip}
              sx={{
                px: 2,
                textTransform: "none",
                borderColor: "primary.light",
              }}
            >
              <Stack direction="row" spacing={1}>
                <ProviderIcon provider={provider} />
                <Typography>
                  {getProviderName(provider, { title: true })}
                </Typography>
              </Stack>
            </ToggleButton>
          </Tooltip>
        );
      })}
    </ToggleButtonGroup>
  );
};
