import { FC, Fragment } from "react";
import { useFlag } from "@unleash/proxy-client-react";
import { AwsMissingPermissions } from "./components/AwsMissingPermissions";
import { MissingPermissionsAlertButton } from "../common/MissingPermissionsAlertButton";
import { useGetUsersMeProvidersAwsAccountsByAccountIdMissingPermissionsQuery } from "../../../../../../../../services/cloudchipr.api";
import { useDialog } from "../../../../../../../../utils/hooks/useDialog.hook";

interface LiveUsageMgmtAwsMissingsProps {
  accountId: string;
  isRootAccount?: boolean;
  isSubAccount?: boolean;
  parentId?: string;
}
export const LiveUsageMgmtAwsMissings: FC<LiveUsageMgmtAwsMissingsProps> = ({
  accountId,
  isRootAccount,
  isSubAccount,
  parentId,
}) => {
  const { open, openDialog, closeDialog } = useDialog();

  const enableMissingPermissionButtonForAWSRootAccount = useFlag(
    "EnableMissingPermissionButtonForAWSRootAccount",
  );

  const { data } =
    useGetUsersMeProvidersAwsAccountsByAccountIdMissingPermissionsQuery(
      { accountId: accountId || "" },
      { skip: !accountId },
    );

  if (
    data?.length ||
    (enableMissingPermissionButtonForAWSRootAccount && isRootAccount)
  ) {
    return (
      <Fragment>
        <MissingPermissionsAlertButton
          onOpen={openDialog}
          title="Missing Permissions"
        />

        <AwsMissingPermissions
          open={open}
          onClose={closeDialog}
          accountId={accountId}
          isSubAccount={isSubAccount}
          parentId={parentId}
        />
      </Fragment>
    );
  }

  return null;
};
