import moment from "moment/moment";
import { createDraftSafeSelector } from "@reduxjs/toolkit";
import { resourceExplorerGranularDataSelector } from "./resourceExplorerGranularDataSelector";
import { ResourceExplorerGranularCostsData } from "../../../../utils/types/resourceExplorerDataGrid";
import { formatDate } from "../../../../../../utils/helpers/date-time/datetime-format";
import { resourceExplorerGroupingSelector } from "../../data/resourceExplorerGroupingSelector";
import { activeStatusAccountObjectDataSelector } from "../../../../../accounts/selectors/slice-data/activeStatusAccountObjectDataSelector";

export const resourceExplorerGranularViewGridDataSelector =
  createDraftSafeSelector(
    [
      resourceExplorerGranularDataSelector,
      resourceExplorerGroupingSelector,
      activeStatusAccountObjectDataSelector,
    ],
    (
      granularCostsResponse,
      grouping,
      accountsIds,
    ): ResourceExplorerGranularCostsData[] | undefined => {
      const granularCosts = granularCostsResponse?.data;
      return granularCosts
        ?.filter(({ metadata }) => !metadata?.aggregated_by_cost)
        ?.map(({ dates, ...props }, index) => {
          const liveAccountId = props.metadata.live_account_id;

          const data = props as ResourceExplorerGranularCostsData;
          data.id = props.field + index;
          data.resourceExplorerGrouping = grouping;
          data.accountExistInLiveUsage = !!accountsIds?.[liveAccountId];

          dates.forEach((dateItem) => {
            const date = dateItem.date;
            const trend = dateItem.trend;
            let trendTooltip = "";

            if (trend) {
              const currentDay = formatDate(date ?? "", { type: "date" });
              const prevDay = formatDate(moment(date).subtract(1, "day"), {
                type: "date",
              });

              trendTooltip = date
                ? `${currentDay} compared with ${prevDay}`
                : "";
            }

            const cost = dateItem.total_cost;

            data[dateItem.date] = {
              date,
              trend,
              trendTooltip,
              cost: cost !== null ? +cost.toFixed(4) : cost,
            };
          });

          return data;
        }, []);
    },
  );
