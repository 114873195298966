import { overviewDataSelector } from "./overviewDataSelector";
import { RootState } from "../../../store";
import { EffectiveSavingsRate } from "../../../../services/cloudchipr.api";

export const overviewEffectiveSavingRateSelector = (
  state: RootState,
): EffectiveSavingsRate | null => {
  const data = overviewDataSelector(state);

  return data?.effective_savings_rate ?? null;
};
