import { createAsyncThunk } from "@reduxjs/toolkit";
import { enqueueSnackbar } from "notistack";
import { getScheduleSelectedResourcesThunk } from "./getScheduleSelectedResourcesThunk";
import {
  resourceProtectThunk,
  ScheduleProtectResources,
} from "./resourceProtectThunk";
import { ResourceFiltersWithAction } from "../../../services/cloudchipr.api";
import { removePreviewSelectedResources } from "../schedulesSlice";

export const scheduleResourcesProtectionThunk = createAsyncThunk(
  "schedule/scheduleResourcesProtectionThunk",
  async (
    {
      resourcesToProtect,
      resourceFilters,
      accountIds,
      regions,
      scheduleId,
    }: {
      scheduleId: string;
      regions?: string[];
      accountIds: string[];
      resourceFilters: ResourceFiltersWithAction[];
      resourcesToProtect: ScheduleProtectResources;
    },
    { dispatch },
  ) => {
    const response = await dispatch(resourceProtectThunk(resourcesToProtect));
    const protect = response?.payload;

    const refetchPromises =
      resourceFilters?.map(({ filter }) => {
        return dispatch(
          getScheduleSelectedResourcesThunk({
            accountIds,
            scheduleId,
            resourceFilters: filter,
            regions,
          }),
        ).unwrap();
      }) ?? [];

    resourceFilters?.forEach(({ filter }) => {
      dispatch(removePreviewSelectedResources(filter.type));
    });

    if (protect !== null) {
      enqueueSnackbar(`Protection ${protect ? "enabled" : "disabled"}`, {
        variant: "snackbarAlert",
        AlertSnackBarProps: { severity: "success" },
      });
    }

    await Promise.all(refetchPromises);
  },
);
