import { sortAlphabeticallyCallback } from "../../../../../../../../../utils/helpers/sorting";

export const isEqualArrays = (arr1: string[], arr2: string[]) => {
  if (arr1.length !== arr2.length) return false;

  // Sort both arrays and compare
  const sortedArr1 = arr1.slice().sort(sortAlphabeticallyCallback);
  const sortedArr2 = arr2.slice().sort(sortAlphabeticallyCallback);

  return sortedArr1.every((value, index) => value === sortedArr2[index]);
};
