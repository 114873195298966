import { FC } from "react";
import { Box } from "@mui/material";
import CloudChiprLogoText from "../../assets/images/cloudchipr-text.svg";

export const PageHeaderWithLogo: FC = () => {
  return (
    <Box
      py={2}
      px={3}
      bgcolor="white"
      sx={{ borderBottom: 1, borderColor: "grey.300" }}
    >
      <img src={CloudChiprLogoText} alt="Cloudchipr" />
    </Box>
  );
};
