import { FC, Fragment, useCallback } from "react";
import { Button, Stack } from "@mui/material";
import PlayCircleFilledWhiteRoundedIcon from "@mui/icons-material/PlayCircleFilledWhiteRounded";
import { Link as RouterLink } from "react-router-dom";
import LaunchIcon from "@mui/icons-material/Launch";
import RefreshRoundedIcon from "@mui/icons-material/RefreshRounded";
import { LoadingButton } from "@mui/lab";
import { rightSizingRecommendationsVideos, VideoDialog } from "../VideoDialog";
import { getProviderName } from "../../../../../../../utils/helpers/providers/getProviderName";
import { useDialog } from "../../../../../../../utils/hooks/useDialog.hook";
import {
  ProviderType,
  usePostUsersMeOrganisationsCurrentAccountsByAccountIdRecommendationsStatusMutation,
} from "../../../../../../../services/cloudchipr.api";
import { providerEnablingLinks } from "../../utils/constants/constants";
import { getRightSizingRecommendationsStatusesThunk } from "../../../../../../../store/recommendations/thunks/rightsizing/getRightSizingRecommendationsStatusesThunk";
import { useAppDispatch } from "../../../../../../../store/hooks";

export interface DrawerActionsProps {
  provider: ProviderType;
  accountId: string;
}

export const DrawerActions: FC<DrawerActionsProps> = ({
  provider,
  accountId,
}) => {
  const dispatch = useAppDispatch();
  const { open, openDialog, closeDialog } = useDialog();
  const goToLink = providerEnablingLinks[provider];
  const [trigger, { isLoading }] =
    usePostUsersMeOrganisationsCurrentAccountsByAccountIdRecommendationsStatusMutation();

  const checkHandler = useCallback(async () => {
    const response = await trigger({ accountId });
    const status = "data" in response && response?.data?.status;

    if (status && status !== "disabled") {
      dispatch(getRightSizingRecommendationsStatusesThunk());
    }
  }, [trigger, accountId, dispatch]);

  return (
    <Stack direction="row" alignItems="center" justifyContent="space-between">
      {rightSizingRecommendationsVideos[provider] && (
        <Fragment>
          <Button
            size="small"
            startIcon={<PlayCircleFilledWhiteRoundedIcon />}
            onClick={openDialog}
          >
            Watch Video Guide
          </Button>
          <VideoDialog open={open} provider={provider} onClose={closeDialog} />
        </Fragment>
      )}

      <Stack direction="row" justifyContent="space-between" spacing={1}>
        {goToLink && (
          <Button
            to={goToLink}
            component={RouterLink}
            target="_blank"
            variant="outlined"
            size="small"
            endIcon={<LaunchIcon />}
          >
            Go To {getProviderName(provider, { title: true, uppercase: true })}
          </Button>
        )}

        <LoadingButton
          size="small"
          loading={isLoading}
          variant="outlined"
          onClick={checkHandler}
          startIcon={<RefreshRoundedIcon />}
        >
          Check
        </LoadingButton>
      </Stack>
    </Stack>
  );
};
