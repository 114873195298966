import { createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../../../../../store";
import { costAndUsageSumWidgetByIdSelector } from "../../../../selectors/widgets/cost-and-usage/costAndUsageSumWidgetByIdSelector";
import { createWidgetThunk } from "../../common/createWidgetThunk";
import { openWidgetBuildingSnackbar } from "../../utils/openWidgetBuildingSnackbar";

export const duplicateCostAndUsageSumWidgetThunk = createAsyncThunk(
  "dashboards/duplicateCostAndUsageSumWidget",
  async (
    { widgetId, widgetName }: { widgetId: string; widgetName: string },
    { dispatch, getState },
  ) => {
    const state = getState() as RootState;
    const widget = costAndUsageSumWidgetByIdSelector(state, widgetId);

    if (!widget) {
      return;
    }

    const response = await dispatch(
      createWidgetThunk({
        type: "cost_and_usage_summary",
        name: widgetName,
        filter: widget.filter,
        dates: widget.dates,
        date_from: widget.date_from,
        date_to: widget.date_to,
        date_label: widget.date_label,
        view_id: widget.view_id ?? "",
        layout: null,
      }),
    ).unwrap();

    if (response) {
      openWidgetBuildingSnackbar(widget.type, "duplicate");
    }

    return response;
  },
);
