import {
  ResourceFiltersWithAction,
  ResourceType,
} from "../../../../services/cloudchipr.api";
import { getFirstParentInHierarchyResourceType } from "../../../../utils/helpers/resources/getFirstParentInHierarchyResourceType";

export const generateOffHoursRecommendationFilter = (
  resourceType: ResourceType,
  providerUniqueIdentifier: string,
): ResourceFiltersWithAction[] => {
  return [
    {
      action: "stop",
      snapshot: false,
      filter: {
        type:
          getFirstParentInHierarchyResourceType(resourceType) ?? resourceType,
        filter_items: [
          {
            type: resourceType,
            filter: {
              key: "provider_unique_identifier",
              value: [providerUniqueIdentifier],
              operator: "in",
            },
          },
        ],
        filter_groups: [0],
        operators: ["and"],
      },
    },
  ];
};
