import { Metric } from "../../../../../../../services/cloudchipr.api";

export const generateChartData = (
  cpuData: Metric[],
  currentCpus: number,
  recommendedCpus: number,
  currentType: string,
  recommendedType: string,
  metric: "maximum" | "average",
) => {
  let quotient: number;
  if (currentCpus === 0 || recommendedCpus === 0) {
    quotient = 0;
  } else {
    quotient = currentCpus / recommendedCpus;
  }
  return cpuData.map(({ timestamp, maximum, average }) => {
    const cpu = metric === "average" ? average : maximum;
    return {
      timestamp,
      cpu,
      recommended: cpu * quotient,
      currentType,
      recommendedType,
      metric,
    };
  });
};
