import { FC } from "react";
import { DialogContent, Typography } from "@mui/material";
import { useEffectOnceWhen } from "rooks";
import { ProgressBlock } from "../../../../account-setup/steps/components/ProgressBlock";
import { useGetUsersMeAccountsByAccountIdQuery } from "../../../../../../services/cloudchipr.api";
import {
  resetAccountSetup,
  setAccount,
  setPolling,
} from "../../../../../../store/account-setup/accountSetupSlice";
import { useAppDispatch, useAppSelector } from "../../../../../../store/hooks";
import { currentAccountSelector } from "../../../../../../store/account-setup/selectors/currentAccountSelector";
import { accountAttemptSelector } from "../../../../../../store/account-setup/selectors/accountAttemptSelector";
import { getProviderName } from "../../../../../../utils/helpers/providers/getProviderName";
import { getAccountsThunk } from "../../../../../../store/accounts/thunks/getAccountsThunk";
import { gcpConnectionTypeSelector } from "../../../../../../store/account-setup/selectors/gcpConnectionTypeSelector";
import { providerSelector } from "../../../../../../store/account-setup/selectors/providerSelector";
import { getAllProvidersBillingStatusThunk } from "../../../../../../store/common/thunks/billing-status/getAllProvidersBillingStatusThunk";

export const DataCollectionStep: FC = () => {
  const dispatch = useAppDispatch();
  const account = useAppSelector(currentAccountSelector);
  const accountAttempt = useAppSelector(accountAttemptSelector);
  const provider = useAppSelector(providerSelector);

  const { data } = useGetUsersMeAccountsByAccountIdQuery(
    { accountId: accountAttempt?.status_details?.closed?.account_id || "" },
    {
      pollingInterval: 3000,
    },
  );

  const connectionType = useAppSelector(gcpConnectionTypeSelector);

  useEffectOnceWhen(async () => {
    await dispatch(getAccountsThunk());
    dispatch(getAllProvidersBillingStatusThunk());
    dispatch(setPolling(false));
    dispatch(resetAccountSetup());
  }, account?.status === "connected");

  if (data) {
    dispatch(setAccount(data));
  }

  const connectionTitle =
    connectionType === "single" ? "Project" : "Organization";

  const accountTypeName =
    provider === "gcp"
      ? connectionTitle
      : getProviderName(provider, { capitalize: true });

  const connectionMessage = `Cloudchipr is now connected to your ${provider?.toUpperCase()} ${accountTypeName}`;

  return (
    <DialogContent>
      <Typography variant="body1" fontWeight="medium" mb={3}>
        {connectionMessage}
      </Typography>
      <Typography variant="body2" color="text.secondary" mb={3}>
        This may take few minutes
      </Typography>

      <ProgressBlock />
    </DialogContent>
  );
};
