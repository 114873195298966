import { FC, useCallback } from "react";
import { LoadingButton } from "@mui/lab";
import { useEffectOnceWhen } from "rooks";
import { usePostUsersMeOrganisationsCurrentBillingPortalPlansByPlanIdMutation } from "../../../../../../../services/cloudchipr.api";
import { useAppAbility } from "../../../../../../../services/permissions";
import { SubscriptionPlanActionType } from "../SubscriptionPlans";
import { useAppSelector } from "../../../../../../../store/hooks";
import { isCurrentSubscriptionPlanEnterprise } from "../../../../../../../store/subscriptions/subscriptionsSelectors";

interface SubscriptionPlanLinkToBillingProps {
  id: string;
  action: SubscriptionPlanActionType;
}

export const SubscriptionPlanLinkToBilling: FC<
  SubscriptionPlanLinkToBillingProps
> = ({ id, action }) => {
  const currentIsEnterprise = useAppSelector(
    isCurrentSubscriptionPlanEnterprise,
  );
  const { cannot } = useAppAbility();
  const canNotEditBilling = cannot("edit", "billing");
  const [trigger, { data: billingPortal, isLoading: loadingTheLink }] =
    usePostUsersMeOrganisationsCurrentBillingPortalPlansByPlanIdMutation();

  const handleStripeCustomerPortalClick = useCallback(async () => {
    await trigger({ planId: id });
  }, [trigger, id]);

  useEffectOnceWhen(() => {
    if (billingPortal?.link) {
      window.location.replace(billingPortal.link);
    }
  }, !!billingPortal?.link);

  return (
    <LoadingButton
      fullWidth
      color={action === "Downgrade" ? "error" : "primary"}
      onClick={handleStripeCustomerPortalClick}
      loading={loadingTheLink}
      variant={action === "Upgrade" ? "contained" : "outlined"}
      disabled={
        !!billingPortal?.link || canNotEditBilling || currentIsEnterprise
      }
    >
      {action}
    </LoadingButton>
  );
};
