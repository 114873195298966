import { FC, useMemo } from "react";
import { Stack } from "@mui/material";
import { IntegrationsPreviewItem } from "./IntegrationsPreviewItem";
import { EmailsPreview } from "./EmailsPreview";
import { useAppSelector } from "../../../../../../../../../../store/hooks";
import { step1FormDataSelector } from "../../../../../../../../../../store/budgets/selectors/current-budget/forms/step1FormDataSelector";

export const NotificationsPreview: FC = () => {
  const data = useAppSelector(step1FormDataSelector);
  const emails = data?.email_to;

  const previewIntegrations = useMemo(() => {
    return data?.notifications?.map(
      ({ type, integration_id, conversations, to }) => {
        const metadata =
          type === "email"
            ? to
            : conversations?.map((conversation: any) => conversation.name);

        return {
          id: integration_id,
          metadata,
        };
      },
    );
  }, [data?.notifications]);

  if (!previewIntegrations && !emails) {
    return null;
  }

  return (
    <Stack gap={2}>
      <EmailsPreview />

      {previewIntegrations?.map(({ id, metadata }) => {
        return <IntegrationsPreviewItem key={id} id={id} metadata={metadata} />;
      })}
    </Stack>
  );
};
