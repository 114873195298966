import { Button, Stack } from "@mui/material";

export const CsvDownloadAlertAction = (url: string, onClose: () => void) => {
  return (
    <Stack direction="row" width="100%" justifyContent="space-between" mt={1}>
      <Button onClick={onClose} color="tertiary" size="small">
        Cancel
      </Button>

      <Button
        href={url}
        download="asd.csv"
        variant="contained"
        size="small"
        onClick={onClose}
      >
        Download
      </Button>
    </Stack>
  );
};
