import { FC } from "react";
import moment from "moment";
import { OffHoursRecommendationsChartColors } from "../off-hours-recommendations/utils/constants/constants";
import {
  dateFromUtcByTimeZone,
  formatDate,
} from "../../../../../utils/helpers/date-time/datetime-format";

export const CustomizedXAxisTick: FC<any> = (props) => {
  const { x, y, payload } = props;

  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={0}
        y={10}
        textAnchor="start"
        fill={OffHoursRecommendationsChartColors.TextColor}
        fontSize={10}
      >
        {formatDate(dateFromUtcByTimeZone(payload.value) || moment(), {
          type: "dateTime",
        })}
      </text>
    </g>
  );
};
