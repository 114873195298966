import { FC, memo, useCallback, useMemo } from "react";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../store/hooks";
import { setRecommendationsTagsFilter } from "../../../../../../../store/recommendations/recommendationsSlice";
import { TagFilterDropdownSelect } from "../../../../../../common/select/tag-filter-dropdown-select/TagFilterDropdownSelect";
import { RecommendationGroupType } from "../../utils/types/types";
import { offHoursDataGridDataByTabSelector } from "../../../../../../../store/recommendations/selectors/off-hours/data/offHoursDataGridDataByTabSelector";
import { RecommendationsTabType } from "../OffHoursRecommendationsContent";
import { generateRecommendationsTagsFilterOptionsByTableData } from "../../../common/utils/helpers/generateRecommendationsTagsFilterOptionsByTableData";

interface OffHoursRecommendationsTagsFilterProps {
  group: RecommendationGroupType;
  tab: RecommendationsTabType;
}

export const OffHoursRecommendationsTagsFilter: FC<OffHoursRecommendationsTagsFilterProps> =
  memo(({ group, tab }) => {
    const dispatch = useAppDispatch();

    const tableData = useAppSelector((state) =>
      offHoursDataGridDataByTabSelector(state, group, tab),
    );

    const tagFilterOptions = useMemo(() => {
      return generateRecommendationsTagsFilterOptionsByTableData(tableData);
    }, [tableData]);

    const closeSubmitHandler = useCallback(
      (filter: any) => {
        dispatch(setRecommendationsTagsFilter(filter));
      },
      [dispatch],
    );

    return (
      <TagFilterDropdownSelect
        label="Tags/Labels"
        onCloseSubmit={closeSubmitHandler}
        keysOptions={tagFilterOptions?.keysOptions ?? []}
        valuesOptions={tagFilterOptions?.valuesOptions}
      />
    );
  });
