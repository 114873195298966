import { isTreeNodeFilterGroup } from "./isTreeNodeFilterGroup";
import { FilterTreeNode } from "../../../../../../services/cloudchipr.api";

export const calculateFilterTreeFiltersCount = (
  tree?: FilterTreeNode,
): number => {
  if (!isTreeNodeFilterGroup(tree)) {
    return 0;
  }

  return tree.items.reduce((sum, item) => {
    sum +=
      item.node_type === "item" ? 1 : calculateFilterTreeFiltersCount(item);

    return sum;
  }, 0);
};
