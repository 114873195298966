import { sortWithConvertingToNumber } from "./sortWithConvertingToNumber";
import { sortWithCreatedBy } from "./sortWithCreatedBy";
import { sortWithUnusedSince } from "./sortWithUnusedSince";
import { sortWithState } from "./sortWithState";
import { TableCellTypes } from "../types/types";

export const sortingFnByCellType: Map<TableCellTypes, keyof typeof sortingFns> =
  new Map([
    ["byteToGib", "sortWithConvertingToNumber"],
    ["createdBy", "sortWithCreatedBy"],
    ["unusedSince", "sortWithUnusedSince"],
    ["state", "sortWithState"],
    ["stateWithUnusedSinceCell", "sortWithState"],
  ]);

export const sortingFns = {
  sortWithConvertingToNumber,
  sortWithCreatedBy,
  sortWithUnusedSince,
  sortWithState,
};
