import { savingsPlansCoverageCSVDataSelector } from "./savingsPlansCoverageCSVDataSelector";
import { savingsPlansCSVDataSelector } from "./savingsPlansCSVDataSelector";
import { RootState } from "../../../../../store";
import { commitmentsDataViewTabSelector } from "../../../common/view-options/commitmentsDataViewTabSelector";

export const utilizationAndCoverageCSVDataSelector = (state: RootState) => {
  const coverageCSV = savingsPlansCoverageCSVDataSelector(state);
  const savingsCSV = savingsPlansCSVDataSelector(state);
  const tab = commitmentsDataViewTabSelector(state);

  if (tab === "coverage") {
    return coverageCSV;
  }

  if (tab === "commitment") {
    return savingsCSV;
  }

  return null;
};
