import parser from "cron-parser";

export const parseCron = (cron: string | null) => {
  if (!cron) return {};

  const cronParts = cron.split(" ");
  const weekDays = cronParts.pop();
  const decreased = weekDays
    ?.split("")
    ?.map((x) => (isNaN(+x) ? x : +x - 1))
    .join("");

  cron = `${cronParts.join(" ")} ${decreased}`;

  cron = cron.replaceAll("?", "*");

  const cronExpression = parser.parseString(cron);

  const expressionsFields = cronExpression.expressions[0].fields;
  const { hour, minute } = expressionsFields;
  const dayOfMonth = cronExpression.expressions[0].fields
    .dayOfMonth as number[];

  const dayOfWeek = expressionsFields.dayOfWeek;

  const hours = hour.length > 1 ? hour[1] - hour[0] : hour[0];
  const minutes = minute.length > 1 ? null : minute[0];
  const daysOfWeek = dayOfWeek.length > 7 ? null : (dayOfWeek as number[]);
  const daysOfMonth =
    dayOfMonth.length > 1 ? dayOfMonth[1] - dayOfMonth[0] : dayOfMonth[0];

  return { hours, minutes, daysOfWeek, daysOfMonth };
};
