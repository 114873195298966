export const integrationCustomEmailActionExecuteDataGrid = [
  {
    header: "Resource",
    value: "value",
  },
  {
    header: "Account",
    value: "value",
  },
  {
    header: "Action",
    value: "Value",
  },
  {
    header: "Monthly Price",
    value: "$00.00",
  },
];
