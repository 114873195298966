import { filterTreeChangedSelector } from "./filterTreeChangedSelector";
import { resourceExplorerDataChangedSelector } from "./resourceExplorerDataChangedSelector";
import { resourceExplorerDatesChangedSelector } from "./resourceExplorerDatesChangedSelector";
import { RootState } from "../../../../store";
import { resourceExplorerIdSelector } from "../resourceExplorerIdSelector";

export const changesExistOnResourceExplorer = (state: RootState): boolean => {
  const viewId = resourceExplorerIdSelector(state);

  const filterTreeChanged = filterTreeChangedSelector(state);

  const datesChanged = resourceExplorerDatesChangedSelector(state, viewId);

  const resourceExplorerDataChanged = resourceExplorerDataChangedSelector(
    state,
    viewId,
  );

  return resourceExplorerDataChanged || datesChanged || filterTreeChanged;
};
