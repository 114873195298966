import { ResourceType } from "../../../../services/cloudchipr.api";

export const resourceTypeAbbreviationNames: Map<
  ResourceType,
  { singular: string; plural: string }
> = new Map([
  // AWS --------------------------------------------------------------------
  ["ec2", { singular: "Instances", plural: "Instances" }],
  ["asg", { singular: "ASG", plural: "ASGs" }],
  ["asg_ec2", { singular: "ASG EC2s", plural: "ASG EC2s" }],
  ["ebs", { singular: "Volume", plural: "Volumes" }],
  ["ebs_snapshot", { singular: "Snapshot", plural: "Snapshots" }],
  ["eip", { singular: "EIP", plural: "EIPs" }],
  ["elb", { singular: "ELB", plural: "ELBs" }],
  ["s3", { singular: "S3", plural: "S3" }],
  ["rds", { singular: "Database", plural: "Databases" }],
  ["rds_snapshot", { singular: "Snapshot", plural: "Snapshots" }],
  ["rds_snapshot_source", { singular: "Snapshot", plural: "Snapshots" }],
  ["eks", { singular: "EKS", plural: "EKS" }],
  ["eks_ec2", { singular: "Node", plural: "Nodes" }],
  ["node_group", { singular: "Node Group", plural: "Node Groups" }],
  ["elc", { singular: "ElastiCache", plural: "ElastiCache" }],
  [
    "dynamo_db_table",
    { singular: "DynamoDB Table", plural: "DynamoDB Tables" },
  ],
  ["dax_cluster", { singular: "DAX Cluster", plural: "DAX Clusters" }],
  [
    "dynamo_db_backup",
    { singular: "DynamoDB Backup", plural: "DynamoDB Backups" },
  ],
  ["nat_gateway", { singular: "NAT Gateway", plural: "NAT Gateways" }],
  ["ecs", { singular: "Clusters", plural: "Clusters" }],
  ["ecs_workload", { singular: "Workload", plural: "Workloads" }],

  // GCP --------------------------------------------------------------------
  ["vm", { singular: "VM", plural: "VMs" }],
  ["mig", { singular: "MIG", plural: "MIGs" }],
  ["mig_vm", { singular: "MIG VM", plural: "MIG Vms" }],
  ["disk", { singular: "Disk", plural: "Disks" }],
  ["ip", { singular: "External IP", plural: "External IPs" }],
  ["lb", { singular: "LB", plural: "LBs" }],
  ["sql", { singular: "SQL", plural: "SQL" }],
  ["gke", { singular: "GKE", plural: "GKE" }],
  ["node_pool", { singular: "Node Pool", plural: "Node Pools" }],
  ["gke_vm", { singular: "Node", plural: "Nodes" }],

  // AZURE --------------------------------------------------------------------
  ["az_vm", { singular: "Azure VM", plural: "Azure VMs" }],
  ["az_disk", { singular: "Disk", plural: "Disks" }],
  ["az_ip", { singular: "Public IP", plural: "Public IPs" }],
  ["az_lb", { singular: "LB", plural: "LBs" }],
  ["az_sql", { singular: "SQL", plural: "SQL" }],
  ["az_vmss", { singular: "VMSS", plural: "VMSS" }],
  ["az_aks", { singular: "AKS", plural: "AKS" }],
  ["az_node_pool", { singular: "Node Pool", plural: "Node Pools" }],
  ["az_aks_vm", { singular: "Node", plural: "Nodes" }],
]);
