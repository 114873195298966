import { FC } from "react";
import { Stack, Typography } from "@mui/material";
import { PieLegendContentProps } from "../../../../../../storybook/charts/multi-type-chart/components/chart-by-type/pie-chart/PieLegentContent";
import { LegendItem } from "../../../../../../storybook/charts/multi-type-chart/components/common/legend/LegendItem";
import { capitalizeString } from "../../../../../../utils/helpers/capitalizeString";

export const CommitmentsContentChartLegendContent: FC<
  PieLegendContentProps
> = ({
  keys,
  colors,
  visibleKeys,
  legendItemLabelFormatter,
  setVisibleKeys,
  selectable,
  highlightable,
}) => {
  return (
    <Stack direction="row" justifyContent="center" mt={2}>
      {keys?.map((key) => {
        return (
          <LegendItem
            key={key}
            itemKey={key}
            allKeys={keys}
            colors={colors}
            selectable={selectable}
            visibleKeys={visibleKeys}
            setVisibleKeys={setVisibleKeys}
            highlightable={highlightable}
            legendItemLabelFormatter={legendItemLabelFormatter}
          >
            <Typography variant="caption" color="text.secondary">
              {capitalizeString(key)}
            </Typography>
          </LegendItem>
        );
      })}
    </Stack>
  );
};
