import { createAsyncThunk } from "@reduxjs/toolkit";
import { closeSnackbar, enqueueSnackbar, SnackbarKey } from "notistack";
import {
  cloudChiprApi,
  PostV2UsersMeOrganisationsCurrentDashboardsApiArg,
} from "../../../../services/cloudchipr.api";
import { createDashboardFixedCacheKey } from "../../../../components/pages/dashboard/utils/constants/fixedCacheKeys";

export const createDashboardVisibilityHierarchyItemThunk = createAsyncThunk(
  "dashboard/createDashboardVisibilityHierarchyItem",
  async (
    {
      name,
      visibility,
      widgets,
    }: PostV2UsersMeOrganisationsCurrentDashboardsApiArg["body"],
    { dispatch },
  ) => {
    const { postV2UsersMeOrganisationsCurrentDashboards } =
      cloudChiprApi.endpoints;
    let response;

    try {
      response = await dispatch(
        postV2UsersMeOrganisationsCurrentDashboards.initiate(
          { body: { name, visibility, widgets } },
          { fixedCacheKey: createDashboardFixedCacheKey },
        ),
      ).unwrap();

      if (response) {
        const snackbarId: SnackbarKey = enqueueSnackbar(
          "Dashboard successfully created.",
          {
            variant: "snackbarAlert",
            AlertSnackBarProps: {
              severity: "success",
              onClose: () => closeSnackbar(snackbarId),
            },
          },
        );
      }
      return response;
    } catch (e) {
      // @ts-expect-error TODO: return to this after adding error type
      const errMessage = e?.data?.message || "Something went wrong";
      const snackbarId: SnackbarKey = enqueueSnackbar(errMessage, {
        variant: "snackbarAlert",
        AlertSnackBarProps: {
          severity: "error",
          onClose: () => closeSnackbar(snackbarId),
        },
      });
    }
  },
);
