import { createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../../../../../store";
import { createWidgetThunk } from "../../common/createWidgetThunk";
import { openWidgetBuildingSnackbar } from "../../utils/openWidgetBuildingSnackbar";
import { costBreakdownWidgetByIdSelector } from "../../../../selectors/widgets/cost-breakdown/widget-view/costBreakdownWidgetByIdSelector";

export const duplicateCostBreakdownWidgetThunk = createAsyncThunk(
  "dashboards/duplicateCostBreakdownWidgetThunk",
  async (
    { widgetId, widgetName }: { widgetId: string; widgetName: string },
    { dispatch, getState },
  ) => {
    const state = getState() as RootState;
    const widget = costBreakdownWidgetByIdSelector(state, widgetId);

    if (!widget) {
      return;
    }

    const response = await dispatch(
      createWidgetThunk({
        name: widgetName,
        grouping: widget.grouping,
        group_values: widget.group_values,
        type: "cost_breakdown",
        chart_type: widget.chart_type,
        date_granularity: widget.date_granularity,
        view_id: widget.view_id ?? "",
        layout: null,
        forecast_option: widget.forecast_option,
        dates: widget.dates,
        aggregation: widget.aggregation,
        visualization: widget.visualization,
        trend_type: widget.trend_type,
      }),
    ).unwrap();

    if (response) {
      openWidgetBuildingSnackbar(widget.type, "duplicate");
    }
    return response;
  },
);
