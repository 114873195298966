import { Typography } from "@mui/material";
import { CostAndUsageSumWidgetLabelCell } from "./CostAndUsageSumWidgetLabelCell";
import { CostAndUsageTrendChip } from "./CostAndUsageTrendChip";
import { ColumnSetupType } from "../../../../../../../../../../../storybook/data-grid/utils/types/types";
import { CostAndUsageDataGridType } from "../utils/types";
import { money } from "../../../../../../../../../../../utils/numeral/money";

export const costAndUsageSumDataGridColumns: ColumnSetupType<CostAndUsageDataGridType>[] =
  [
    {
      id: "label",
      accessorKey: "label",
      cell: CostAndUsageSumWidgetLabelCell,
    },
    {
      accessorKey: "cost",
      cell: (cell) => {
        const val = cell.getValue() as number;
        const isLiveUsage =
          cell.row.original.costAndUsageSumWidgetFilterType ===
          "live_usage_total_cost";
        return (
          <Typography
            variant="body2"
            align="right"
            pr={4}
            color={isLiveUsage ? "error.main" : "inherit"}
          >
            {isNaN(val) ? "N / A" : money(val)}
          </Typography>
        );
      },
    },
    {
      id: "trendDetails",
      accessorKey: "label",
      filterFn: (row, _, filterValue) => {
        return `${row.original.trendDetails?.trend}`.includes(filterValue);
      },
      cell: (cell) => {
        const data = cell.row.original.trendDetails;
        const costAndUsageSumWidgetFilterType =
          cell.row.original.costAndUsageSumWidgetFilterType;

        if (data === null) {
          return "-";
        }

        const { currentDates, previousDates, trend } = data;

        return (
          <CostAndUsageTrendChip
            currentDates={currentDates}
            previousDates={previousDates}
            trend={trend}
            costAndUsageSumWidgetFilterType={costAndUsageSumWidgetFilterType}
          />
        );
      },
    },
  ];
