import { Stack } from "@mui/material";
import { grey } from "@mui/material/colors";
import { FC, useMemo } from "react";
import { MultiTypeChartProvider } from "../../../../../../../storybook/charts/multi-type-chart/MultiTypeChartProvider";
import { ChartType } from "../../../../../../../storybook/charts/multi-type-chart/utils/types/types";
import { CommitmentUtilizationWidgetChart } from "../../../../../../pages/dashboard/components/widgets/commitment-utlization/components/CommitmentUtilizationWidgetChart";
import {
  ChartBaseType,
  GetUsersMeOrganisationsCurrentWidgetsCommitmentsUtilizationByWidgetIdApiResponse,
} from "../../../../../../../services/cloudchipr.api";
import { generateCommitmentUtilizationWidgetChartData } from "../../../../../../../store/dashboards/selectors/widgets/commitment-utilization/utils/helpers/generateCommitmentUtilizationWidgetChartData";

interface InternalCommitmentUtilizationWidgetChartViewProps {
  hasAggregation: boolean;
  chartType?: ChartType;
  chartBase?: ChartBaseType;
  data?: GetUsersMeOrganisationsCurrentWidgetsCommitmentsUtilizationByWidgetIdApiResponse;
}

export const InternalCommitmentUtilizationWidgetChartView: FC<
  InternalCommitmentUtilizationWidgetChartViewProps
> = ({ hasAggregation, chartType, chartBase, data }) => {
  const { groups } = data ?? {};
  const chartData = useMemo(
    () => generateCommitmentUtilizationWidgetChartData(groups, chartBase),
    [groups, chartBase],
  );

  return (
    <Stack flex={1} p={2} justifyContent={hasAggregation ? "start" : "center"}>
      <MultiTypeChartProvider
        value={{
          data: chartData ?? [],
          initialChartType: chartType ?? "stack",
          disabledKeysSorting: true,
          colors,
        }}
      >
        <CommitmentUtilizationWidgetChart
          chartType={chartType}
          chartBase={chartBase}
          groups={groups}
        />
      </MultiTypeChartProvider>
    </Stack>
  );
};

const colors = {
  Waste: grey[400],
  Utilization: "#9966FF",
};
