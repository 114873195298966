import { currentBudgetViewIdSelector } from "../data-selectors/currentBudgetViewIdSelector";
import { resourceExplorerViewsDataSelector } from "../../../../resource-explorer/selectors/all-resource-explorers/resourceExplorerViewsDataSelector";
import { ResourceExplorer } from "../../../../../services/cloudchipr.api";
import { RootState } from "../../../../store";

export const selectedViewSelector = (
  state: RootState,
): ResourceExplorer | undefined => {
  const viewId = currentBudgetViewIdSelector(state);
  const resourceExplorerViews = resourceExplorerViewsDataSelector(state);

  return resourceExplorerViews?.find(({ id, is_default }) =>
    viewId ? id === viewId : is_default,
  );
};
