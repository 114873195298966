import { arrayMove } from "@dnd-kit/sortable";

import { getSortableTreeParentId } from "./getSortableTreeParentId";
import { FlattenedItem, TreeItemDraggingData } from "../types";

interface GetSortableTreeItemProjectionArgs {
  items: FlattenedItem[];
  dragOffset: number;
  indentationWidth: number;
  overData: TreeItemDraggingData | null;
  activeData: TreeItemDraggingData | null;
}
export const getSortableTreeItemProjection = (
  args: GetSortableTreeItemProjectionArgs,
) => {
  const { items, dragOffset, indentationWidth, overData, activeData } = args;

  if (!overData || !activeData) {
    return;
  }
  const overItemIndex = overData.index;
  const activeItemIndex = activeData.index;
  const activeItem = items[activeItemIndex];
  const newItems = arrayMove(items, activeItemIndex, overItemIndex);
  const previousItem = newItems[overItemIndex - 1];
  const nextItem = newItems[overItemIndex + 1];
  const dragDepth = getDragDepth(dragOffset, indentationWidth);
  let projectedDepth = activeItem.depth + dragDepth;
  let maxDepth = getMaxDepth({
    previousItem,
  });

  const minDepth = getMinDepth({ nextItem });
  let depth = projectedDepth;

  if (activeItem?.type === "folder") {
    depth = 0;
    projectedDepth = 0;
    maxDepth = 0;
  } else if (previousItem?.type === "item" && !previousItem?.parentId) {
    // If overData type is 'item', force depth to be the same as the overItem
    depth = items[overItemIndex].depth;
    projectedDepth = items[overItemIndex].depth;
  }

  if (maxDepth > 1) {
    maxDepth = 1;
  }

  if (projectedDepth >= maxDepth) {
    depth = maxDepth;
  } else if (projectedDepth < minDepth) {
    depth = minDepth;
  }

  return {
    depth,
    parentId: getSortableTreeParentId({
      newItems,
      depth,
      overData,
      previousItem,
    }),
  };
};

const getMaxDepth = ({ previousItem }: { previousItem: FlattenedItem }) => {
  if (previousItem) {
    return previousItem.depth + 1;
  }

  return 0;
};

const getMinDepth = ({ nextItem }: { nextItem: FlattenedItem }) => {
  if (nextItem) {
    return nextItem.depth;
  }

  return 0;
};

const getDragDepth = (offset: number, indentationWidth: number) => {
  return Math.round(offset / indentationWidth);
};
