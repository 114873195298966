import { RootState } from "../../../store";
import { cloudChiprApi } from "../../../../services/cloudchipr.api";
import { generateApplyFilterSetToAccountFixedCacheKey } from "../../../filters/selectors/filter-set/utils/helpers/fixedCacheKeyGenerator";

const selector =
  cloudChiprApi.endpoints
    .patchUsersMeOrganisationsCurrentFilterSetsByFilterSetIdV2.select;

export const patchLiveUsageFilterTemplateLoadingSelector = (
  state: RootState,
  filterTemplateId: string,
) => {
  return !!selector(
    generateApplyFilterSetToAccountFixedCacheKey(filterTemplateId),
  )(state)?.isLoading;
};
