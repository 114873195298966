import { cleanV2ResourcesSelector } from "./cleanV2ResourcesSelector";
import { RootState } from "../../store";

export const cleanV2ResourcesTotalEMSSelector = (state: RootState) => {
  const resources = cleanV2ResourcesSelector(state);

  return (
    resources?.reduce((acc, cur) => {
      return acc + cur.price_per_month;
    }, 0) ?? 0
  );
};
