import { largestCostChangesSetupSelector } from "./largestCostChangesSetupSelector";
import { RootState } from "../../../../store";
import { GetUsersMeOrganisationsCurrentWidgetsLargestCostChangesApiArg } from "../../../../../services/cloudchipr.api";

export const largesCostChangesSetupWidgetPayloadDataSelector = (
  state: RootState,
):
  | GetUsersMeOrganisationsCurrentWidgetsLargestCostChangesApiArg
  | undefined => {
  const setup = largestCostChangesSetupSelector(state);

  if (!setup?.viewId) {
    return;
  }

  return {
    viewId: setup.viewId,

    dateLabel: setup.dates.label ?? undefined,
    dateTo: setup.dates.to ?? undefined,
    dateFrom: setup.dates.from ?? undefined,
    dateType: setup.dates.type ?? undefined,
    dateUnit: setup.dates.unit ?? undefined,
    dateValue: setup.dates.value ?? undefined,

    grouping: setup.grouping ?? undefined,
    groupValues: setup.group_values ?? undefined,
    itemsCount: setup.itemsCount ?? undefined,
    priceDirection: setup.priceDirection ?? undefined,
    sortBy: setup.sortingBy ?? undefined,

    minPercentChange: getMinChanges(setup.minPercentChange),
    minCostChange: getMinChanges(setup.minCostChange),
  };
};

const getMinChanges = (
  data: {
    state?: boolean;
    value: number | null;
  } | null,
) => {
  return data?.state && data?.value ? data.value : undefined;
};
