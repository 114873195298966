import { FC } from "react";
import { Box } from "@mui/material";
import { useFlag } from "@unleash/proxy-client-react";
import { ReservedInstancesDataGrid } from "./ReservedInstancesDataGrid";
import { ReservationsCoverageDataGrid } from "./ReservationsCoverageDataGrid";
import { ReservationsDataGridViewTabs } from "./components/ReservationsDataViewTabs";
import { useAppSelector } from "../../../../../../../store/hooks";
import { commitmentsDataViewTabSelector } from "../../../../../../../store/commitments/selectors/common/view-options/commitmentsDataViewTabSelector";
import { ToolbarConfig } from "../../../../../../../storybook/data-grid/utils/types/prop-types";
import { CommitmentsGridLoadingLayer } from "../../../../common/components/data-grid/CommitmentsGridLoadingLayer";
import { reservationsForTableLoadingSelector } from "../../../../../../../store/commitments/selectors/reservations/data/loading/reservationsForTableLoadingSelector";
import { ReservationsRecommendationsDataGrid } from "../recomendations/ReservationsRecommendationsDataGrid";
import { ReservationsRecommendationsDataGridToolbar } from "../recomendations/data-grid/ReservationsRecommendationsDataGridToolbar";
import { CommitmentsDataGridToolbar } from "../../../../common/components/data-grid/CommitmentsDataGridToolbar";

export const ReservationsDataGrid: FC = () => {
  const tab = useAppSelector(commitmentsDataViewTabSelector);
  const loading = useAppSelector(reservationsForTableLoadingSelector);

  const enableReservationRecommendation = useFlag(
    "EnableReservationRecommendation",
  );
  if (tab === "recommendations") {
    if (enableReservationRecommendation) {
      return (
        <ReservationsRecommendationsDataGrid toolbar={recommendationsToolbar} />
      );
    }
    return null;
  }

  return (
    <Box position="relative" zIndex={1}>
      {loading && <CommitmentsGridLoadingLayer />}

      {tab === "commitment" && <ReservedInstancesDataGrid toolbar={toolbar} />}

      {tab === "coverage" && <ReservationsCoverageDataGrid toolbar={toolbar} />}
    </Box>
  );
};

const toolbar: ToolbarConfig = {
  renderToolbar: (props) => {
    return (
      <CommitmentsDataGridToolbar {...props}>
        <ReservationsDataGridViewTabs />
      </CommitmentsDataGridToolbar>
    );
  },
};
const recommendationsToolbar: ToolbarConfig = {
  renderToolbar: (props) => (
    <ReservationsRecommendationsDataGridToolbar {...props} />
  ),
};
