import { DateRange, extendMoment } from "moment-range";
import * as Moment from "moment/moment";
import { findRecommendedRanges } from "./findRecommendedRanges";

const moment = extendMoment(Moment);

interface FindWeeklyRecommendedRanges {
  firstTimestamp: string;
  lastTimestamp: string;
  fromHour: number;
  toHour: number;
  fromDay: number;
  toDay: number;
}

export const findWeeklyRecommendedRanges = ({
  firstTimestamp,
  lastTimestamp,
  fromDay,
  toDay,
  fromHour,
  toHour,
}: FindWeeklyRecommendedRanges): DateRange[] => {
  const mainRange = moment.range(
    moment(firstTimestamp).utc(),
    moment(lastTimestamp).utc(),
  );

  const firstRangeStart = moment(firstTimestamp).utc().subtract(1, "week").set({
    weekday: fromDay,
    hour: fromHour,
    minutes: 0,
  });
  const firstRangeEnd = moment(firstTimestamp)
    .utc()
    .subtract(1, "week")
    .add(fromDay > toDay ? 1 : 0, "week")
    .set({
      weekday: toDay,
      hour: toHour,
      minutes: 0,
    });

  const firstRange = moment.range(firstRangeStart, firstRangeEnd);

  return findRecommendedRanges({ mainRange, firstRange, rangeType: "week" });
};
