import { FC } from "react";
import { Link, List, ListItem, Stack, Typography } from "@mui/material";
import { ProviderIcon } from "../../../../../../../../common/ProviderIcon";
import { useAppSelector } from "../../../../../../../../../store/hooks";
import { accountsWithMissingPermissionsByIdsSelector } from "../../../../../../../../../store/accounts/selectors/accountsWithMissingPermissionsByIdsSelector";
import { getProviderName } from "../../../../../../../../../utils/helpers/providers/getProviderName";

interface ResourceActionMissingPermissionsTooltipTitleProps {
  missingPermissionAccountIds: string[];
}

export const ResourceActionMissingPermissionsTooltipTitle: FC<
  ResourceActionMissingPermissionsTooltipTitleProps
> = ({ missingPermissionAccountIds }) => {
  const accounts = useAppSelector((state) =>
    accountsWithMissingPermissionsByIdsSelector(
      state,
      missingPermissionAccountIds,
    ),
  );

  return (
    <Stack py={1} px={1.5}>
      <Typography variant="body2" pb={1}>
        Actions cannot be performed. Missing permissions on following{" "}
        {getProviderName(accounts?.at(0)?.provider ?? "aws")}s:
      </Typography>

      <List sx={{ listStyleType: "disc" }} disablePadding>
        {accounts?.map((account) => (
          <ListItem
            sx={{
              display: "list-item",
              ml: 2.5,
              pb: 1,
            }}
            disablePadding
            key={account.id}
          >
            <Stack direction="row" alignItems="center">
              <ProviderIcon provider={account.provider} width={16} />
              <Link variant="body2" href={account.url} target="_blank" pl={0.5}>
                {account.name}
              </Link>
            </Stack>
          </ListItem>
        ))}
      </List>

      <Typography
        variant="body2"
        color="text.secondary"
        fontStyle="italic"
        fontSize={10}
      >
        *After permissions are granted, the actions will be taken on the next
        run of the workflow.
      </Typography>
    </Stack>
  );
};
