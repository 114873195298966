import { FC, ReactNode } from "react";
import {
  IconButton,
  TableCell,
  TableRow as TableRowMui,
  TableRowProps,
  Theme,
} from "@mui/material";
import { Row } from "@tanstack/react-table";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import { SxProps } from "@mui/system";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

interface DraggableRowProps {
  row: Row<unknown>;
  styles?: SxProps<Theme>;
  rowProps?: TableRowProps;
  children?: ReactNode;
}

export const DraggableRow: FC<DraggableRowProps> = ({
  children,
  row,
  styles,
  rowProps,
}) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id: row.id });

  return (
    <TableRowMui
      id={row.id}
      sx={{
        bgcolor: "white",
        position: isDragging ? "relative" : undefined,
        zIndex: isDragging ? 1000 : 1,
        boxShadow: isDragging ? "0 0 5px rgba(0,0,0,0.2)" : 0,
        transform: CSS.Transform.toString(transform),
        transition,
        ...styles,
      }}
      {...rowProps}
      className="hover-class"
    >
      <TableCell sx={{ pl: 1, pr: 0, width: 20 }}>
        <IconButton
          ref={setNodeRef}
          sx={{ cursor: "grab" }}
          size="small"
          {...listeners}
          {...attributes}
        >
          <DragIndicatorIcon color="action" fontSize="small" />
        </IconButton>
      </TableCell>

      {children}
    </TableRowMui>
  );
};
