import { accountsTotalSpendAndEmsSelector } from "./accountsTotalSpendAndEmsSelector";
import { RootState } from "../../../store";

export const accountTotalSpendByAccountIdSelector = (
  state: RootState,
  accountId: string,
) => {
  const data = accountsTotalSpendAndEmsSelector(state);

  return data?.[accountId]?.totalSpend;
};
