import { FC } from "react";
import { Stack } from "@mui/material";
import { TaskManagementSearch } from "./components/TaskManagementSearch";
import { TasksGridCsvDownloader } from "./components/TasksGridCsvDownloader";
import { TaskManagementShowCompleted } from "./components/TaskManagementShowCompleted";
import { TaskManagementColumnsToolPanel } from "./components/TaskManagementColumnsToolPanel";
import { NavigationExpander } from "../../../common/navigation-expander/NavigationExpander";
import { TaskManagementPropertiesSettings } from "../toolbar/components/properties-settings/TaskManagementPropertiesSettings";

export const TaskManagementAppbar: FC = () => {
  return (
    <Stack direction="row" justifyContent="space-between" p={1.5}>
      <Stack direction="row" spacing={1.5} alignItems="center">
        <NavigationExpander />
        <TaskManagementShowCompleted />
      </Stack>

      <Stack direction="row" spacing={1.5} alignItems="center">
        <TaskManagementSearch />

        <TasksGridCsvDownloader />

        <TaskManagementPropertiesSettings />

        <TaskManagementColumnsToolPanel />
      </Stack>
    </Stack>
  );
};
