import { createDraftSafeSelector } from "@reduxjs/toolkit";
import { dashboardHierarchyMixedVisibilitiesDataSelector } from "./dashboardHierarchyMixedVisibilitiesDataSelector";
import { getHierarchyMixedVisibilitiesCreatedByData } from "../../../common/utils/helpers/getHierarchyMixedVisibilitiesCreatedByData";

export const dashboardHierarchyVisibilitiesCreatedByDataSelector =
  createDraftSafeSelector(
    [dashboardHierarchyMixedVisibilitiesDataSelector],
    (data): string[] => {
      return getHierarchyMixedVisibilitiesCreatedByData(data);
    },
  );
