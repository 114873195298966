import { FC, useMemo } from "react";
import { Icon, Stack, Typography } from "@mui/material";
import { useAppSelector } from "../../../../../../../../store/hooks";
import { money } from "../../../../../../../../utils/numeral/money";
import { ResourceType } from "../../../../../../../../services/cloudchipr.api";

import { resourceServices } from "../../../../../../../../utils/constants/resources/resources";
import { resourceGroups } from "../../../../../../../../utils/constants/resources/resourceGroups";
import { getResourceTypeName } from "../../../../../../../../utils/helpers/resources/getResourceTypeName";
import { getProviderTypeByResourceType } from "../../../../../../../../utils/helpers/providers/getProviderTypeByResourceType";
import { cleanV2ResourcesTotalEMSSelector } from "../../../../../../../../store/clean-v2/selectors/cleanV2ResourcesTotalEMSSelector";
import { cleanV2ResourcesGridDataCountSelector } from "../../../../../../../../store/clean-v2/selectors/cleanV2ResourcesGridDataCountSelector";
import { IconByResourceType } from "../../../../../../live-usage-mgmt/components/navigation/IconByResourceType";

interface CleanV2DescriptionSectionProps {
  resourceType: ResourceType;
}
export const CleanV2DescriptionSection: FC<CleanV2DescriptionSectionProps> = ({
  resourceType,
}) => {
  const totalEms = useAppSelector(cleanV2ResourcesTotalEMSSelector);

  const cleanResourcesCount = useAppSelector(
    cleanV2ResourcesGridDataCountSelector,
  );

  const serviceType = useMemo(() => {
    const provider = getProviderTypeByResourceType(resourceType);

    if (!provider) {
      return resourceType;
    }

    const groups = resourceGroups.get(provider);

    if (!groups) {
      return resourceType;
    }

    return (
      groups.find((group) => {
        return (
          group.value === resourceType ||
          group.resourceTypes.includes(resourceType)
        );
      })?.value ?? resourceType
    );
  }, [resourceType]);

  return (
    <Stack px={3} pt={3} pb={2} spacing={3}>
      <Typography
        component="span"
        variant="body1"
        color="text.primary"
        fontWeight="bold"
      >
        {money(totalEms)} Monthly Price
      </Typography>
      <Stack direction="row" alignItems="center">
        {resourceServices.has(serviceType) && (
          <Icon
            sx={{
              alignSelf: "start",
              color: "primary.main",
              minWidth: 30,
              filter: "grayscale(1)",
            }}
          >
            <IconByResourceType resourceType={serviceType} />
          </Icon>
        )}
        <Typography sx={{ m: 0 }} variant="body2" fontWeight={500}>
          {getResourceTypeName(resourceType, {
            service: true,
            singular: true,
            type: "abbreviation",
          })}{" "}
          - {cleanResourcesCount}{" "}
          {getResourceTypeName(resourceType, {
            singular: cleanResourcesCount === 1,
          })}
        </Typography>
      </Stack>
    </Stack>
  );
};
