import { isTreeNodeFilterGroup } from "./isTreeNodeFilterGroup";
import { filterAvailableFieldsChecker } from "./data-type-checkers/filterAvailableFieldsChecker";
import { FilterItemNodeWithId, FilterTreeNodeWithId } from "../types/common";

export const removeFiltersWithEmptyValues = (
  filterTree: FilterTreeNodeWithId,
): FilterTreeNodeWithId => {
  if (!isTreeNodeFilterGroup(filterTree)) {
    return filterTree;
  }

  return {
    ...filterTree,
    items: filterTree.items.reduce((nodes, node) => {
      if (isTreeNodeFilterGroup(node)) {
        if (!node.items.length) {
          return nodes;
        }

        nodes.push(removeFiltersWithEmptyValues(node));
      } else {
        const hasLength =
          (typeof node.value === "string" || Array.isArray(node.value)) &&
          node.value.length > 0;

        const isNumber = typeof node.value === "number" && node.value > 0;

        const { keyPassed, valuePassed } = validateKeyValuePair(node);

        if (hasLength || isNumber || (keyPassed && valuePassed)) {
          nodes.push(node);
        }
      }

      return nodes;
    }, [] as FilterTreeNodeWithId[]),
  };
};

const validateKeyValuePair = (node: FilterItemNodeWithId) => {
  const { hasKey, hasValue } = filterAvailableFieldsChecker(
    node.type,
    node.operator,
  );

  let keyPassed = !hasKey;
  let valuePassed = !hasValue;

  if (typeof node.value === "object" && node.value && "key" in node.value) {
    keyPassed = hasKey ? !!node.value?.key : true;
    valuePassed = hasValue ? !!node.value?.value?.length : true;
  }

  return { keyPassed, valuePassed };
};
