import { createDraftSafeSelector } from "@reduxjs/toolkit";
import { liveUsageMgmtResourceTypeDataResourcesSelector } from "./liveUsageMgmtResourceTypeDataResourcesSelector";
import { ResourceDataType } from "../../utils/types/types";

export const liveUsageMgmtResourceTypeDataWithChildrenSelector =
  createDraftSafeSelector(
    [liveUsageMgmtResourceTypeDataResourcesSelector],
    (data) => {
      const result: ResourceDataType[] = [];

      const processResource = (data: ResourceDataType) => {
        if (data?.resource) {
          result.push(data);
        }

        if (data?.child_resource?.resources) {
          data.child_resource.resources.forEach((child: ResourceDataType) => {
            processResource(child);
          });
        }
      };

      data?.forEach((item) => {
        processResource(item);
      });

      return result;
    },
  );
