import { createAsyncThunk } from "@reduxjs/toolkit";
import { setResourceExplorerPathThunk } from "./setResourceExplorerPathThunk";
import { getResourceExplorerDataThunk } from "../getResourceExplorerDataThunk";
import { setResourceExplorerGrouping } from "../../../resourceExplorerSlice";
import {
  ResourceExplorerFilterProvider,
  ResourceExplorerGrouping,
} from "../../../../../services/cloudchipr.api";
import { ResourceExplorerPath } from "../../../utils/types/resourceExplorer";
import { possibleGroupingsByCurrentGrouping } from "../../../../../components/pages/resource-explorer/components/resource-explorer-card/utils/constants/groupings";
import { RootState } from "../../../../store";
import { generateFilterTreeForPath } from "../../../utils/helpers/generateFilterTreeForPath";
import { resourceExplorerGroupValuesSelector } from "../../../selectors/current-resource-explorer/data/resourceExplorerGroupValuesSelector";
import { getNextGroupingByCurrentGroupingAndProvider } from "../../../../../components/pages/resource-explorer/components/resource-explorer-card/utils/helpers/getNextGroupingByCurrentGroupingAndProvider";

type Args = {
  pathName: string;
  filterValue?: string;
  currentGroupBy: ResourceExplorerGrouping;
  currentPath: ResourceExplorerPath[];
  aggregated: boolean;
  provider: ResourceExplorerFilterProvider;
};

export const changePathOnRowSelectThunk = createAsyncThunk(
  "resourceExplorer/changePathOnRowSelectThunk",
  (args: Args, { dispatch, getState }) => {
    const { pathName, currentPath, currentGroupBy, provider } = args;

    const state = getState() as RootState;

    if (!currentGroupBy) {
      return currentPath;
    }

    const groupValues = resourceExplorerGroupValuesSelector(state);
    const dimensionId = groupValues?.at(0);

    const filterTree = generateFilterTreeForPath(args, dimensionId);

    const nextGrouping = getNextGroupingByCurrentGroupingAndProvider(
      provider,
      currentGroupBy,
    );

    if (!nextGrouping) {
      return currentPath;
    }

    const pathItem: ResourceExplorerPath = {
      filterTree,
      name: pathName,
      groupedBy: nextGrouping,
      groupingOptions: possibleGroupingsByCurrentGrouping[nextGrouping],
    };

    dispatch(setResourceExplorerPathThunk([...currentPath, pathItem]));
    dispatch(setResourceExplorerGrouping({ grouping: nextGrouping }));
    dispatch(getResourceExplorerDataThunk(filterTree));
  },
);
