import { ResourceType } from "../services/cloudchipr.api";

export type CleanActionTypes =
  | "delete"
  | "stop"
  | "snapshot_and_delete"
  | "start";

export type CleanActionDetails = {
  value: CleanActionTypes;
  label: string;
  snapshot: boolean;
  pastSimpleLabel: string;
  presentContinueLabel: string;
};

const CreateImageAndDeleteAvailableFor = new Set<ResourceType>(["vm", "ec2"]);

export const getCleanActionOptions = (
  resourceType: ResourceType,
): CleanActionDetails[] => [
  {
    value: "delete",
    label: "Delete",
    snapshot: false,
    pastSimpleLabel: "Deleted",
    presentContinueLabel: "Deleting",
  },
  {
    value: "snapshot_and_delete",
    label: CreateImageAndDeleteAvailableFor.has(resourceType)
      ? "Create Image & Delete"
      : "Snapshot & Delete",
    snapshot: true,
    pastSimpleLabel: CreateImageAndDeleteAvailableFor.has(resourceType)
      ? "Created Image & Deleted"
      : "Snapshotted & Deleted",
    presentContinueLabel: CreateImageAndDeleteAvailableFor.has(resourceType)
      ? "Creating Image & Deleting"
      : "Snapshotting & Deleting",
  },
  {
    value: "stop",
    label: "Stop",
    snapshot: false,
    pastSimpleLabel: "Stopped",
    presentContinueLabel: "Stopping",
  },
  {
    value: "start",
    label: "Start",
    snapshot: false,
    pastSimpleLabel: "Started",
    presentContinueLabel: "Starting",
  },
];

export const getActionValue = (
  action?: string | null,
  snapshot?: boolean | null,
): CleanActionTypes => {
  if (snapshot) {
    return "snapshot_and_delete";
  }

  return (action === "terminate" ? "delete" : action) as CleanActionTypes;
};

export type CleanActionTypeDetails = {
  type: CleanActionTypes;
  label: string;
};

// todo: "getCleanActionOptions" can be used instead of this
export const getResourcesCleanActionTypes = (
  resourceType: ResourceType,
): CleanActionTypeDetails[] => [
  {
    type: "delete",
    label: "Delete",
  },
  {
    type: "stop",
    label: "Stop",
  },
  {
    type: "snapshot_and_delete",
    label: CreateImageAndDeleteAvailableFor.has(resourceType)
      ? "Create Image & Delete"
      : "Snapshot & Delete",
  },
  {
    type: "start",
    label: "Start",
  },
];
export const getCleanActionLabel = (
  action: CleanActionTypes,
  resourceType: ResourceType,
) => {
  const actions = getResourcesCleanActionTypes(resourceType);

  return actions.find(({ type }) => type === action)?.label ?? action;
};
