import { RootState } from "../../store";
import { cloudChiprApi, User } from "../../../services/cloudchipr.api";

const selector =
  cloudChiprApi.endpoints.getV2UsersMeOrganisationsCurrentUsers.select({});

export const organisationUsersSelector = (
  state: RootState,
): User[] | undefined => {
  return selector(state)?.data;
};
