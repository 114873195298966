export const getAzureTagsFeatureToggleFromStorage = () => {
  const response = sessionStorage.getItem(
    `C8R-FT-EnableAzureBillingExportProcessing`,
  );

  if (!response) {
    return false;
  }

  return JSON.parse(response);
};
