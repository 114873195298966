import { FC, useCallback } from "react";
import { Stack } from "@mui/material";
import { MissingPermissionsTooltip } from "./MissingPermissionsTooltip";
import {
  Account,
  AccountMissingPermissions,
} from "../../../../../../services/cloudchipr.api";
import { CopyIconButton } from "../../../../../common/CopyIconButton";
import { useHover } from "../../../../../../utils/hooks/useHover.hook";
import { AccountStatusChip } from "../../../../../common/chips/account-status-chip/AccountStatusChip";
import { TypographyWithTooltip } from "../../../../../common/TypographyWithTooltip";
import { AccountAccessTypeChip } from "../../../../../common/chips/account-access-type-chip/AccountAccessTypeChip";

interface AccountCardHeaderInfoProps {
  accountId?: string;
  status?: Account["status"];
  accessType: Account["access_type"];
  missingPermissions: AccountMissingPermissions;
}

export const AccountCardHeaderInfo: FC<AccountCardHeaderInfoProps> = ({
  accountId,
  status,
  accessType,
  missingPermissions,
}) => {
  const { ref, hovered } = useHover();

  const accountStatusInfoClickHandler = useCallback((event: any) => {
    event.preventDefault();
  }, []);

  if (!accountId) {
    return null;
  }
  return (
    <Stack direction="row" alignItems="center" px={2} pb={2} spacing={1}>
      <Stack direction="row" ref={ref} overflow="hidden" flex={1}>
        <TypographyWithTooltip
          title={`ID: ${accountId}`}
          variant="overline"
          color="text.disabled"
        />
        <CopyIconButton data={accountId} visible={hovered} />
      </Stack>

      <Stack
        direction="row"
        onClick={accountStatusInfoClickHandler}
        justifyContent="flex-end"
      >
        {status && <AccountStatusChip status={status} />}
        <AccountAccessTypeChip
          accessType={accessType}
          warning={!!missingPermissions?.length}
          customWarning={
            <MissingPermissionsTooltip
              missingPermissions={missingPermissions}
            />
          }
          customTooltipStyles={
            missingPermissions?.length ? warningTooltipStyles : undefined
          }
        />
      </Stack>
    </Stack>
  );
};

const warningTooltipStyles = {
  color: "text.primary",
  bgcolor: "background.paper",
  "& .MuiTooltip-arrow": {
    color: "background.paper",
  },
  boxShadow: 7,
  p: 0,
};
