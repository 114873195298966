import { createAsyncThunk } from "@reduxjs/toolkit";
import { getOffHoursByIdThunk } from "./getOffHoursByIdThunk";
import { setOffHourDataThunk } from "./setOffHourDataThunk";

import { generateOffHoursSpecificData } from "../../utils/helpers/generateOffHoursSpecificData";
import { generateOffHoursAutomationData } from "../../utils/helpers/generateOffHoursAutomationData";

export const initiateOffHoursByOffHourIdThunk = createAsyncThunk(
  "automation/initiateOffHoursByOffHourId",
  async (offHourId: string, { dispatch }) => {
    const offHours = await dispatch(getOffHoursByIdThunk(offHourId)).unwrap();

    if (!offHours) {
      return;
    }

    const offHoursSpecificData = generateOffHoursSpecificData(offHours);
    const offHoursAutomationData = generateOffHoursAutomationData(offHours);

    dispatch(
      setOffHourDataThunk({
        offHoursSpecificData,
        offHoursAutomationData,
      }),
    );
  },
);
