import { createAsyncThunk } from "@reduxjs/toolkit";
import { cloudChiprApi } from "../../services/cloudchipr.api";

export const getCurrentOrganizationThunk = createAsyncThunk(
  "organizations/getCurrent",
  async (_, { dispatch }) => {
    const { getUsersMeOrganisationsCurrent } = cloudChiprApi.endpoints;

    await dispatch(getUsersMeOrganisationsCurrent.initiate());
  },
);
