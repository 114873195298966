import { resourceExplorerHierarchyVisibilitiesSelector } from "./resourceExplorerHierarchyVisibilitiesSelector";
import { RootState } from "../../../store";
import { GetV2UsersMeOrganisationsCurrentResourceExplorerHierarchyApiResponse } from "../../../../services/cloudchipr.api";

export const resourceExplorerHierarchyVisibilitiesDataSelector = (
  state: RootState,
):
  | GetV2UsersMeOrganisationsCurrentResourceExplorerHierarchyApiResponse
  | undefined => {
  return resourceExplorerHierarchyVisibilitiesSelector(state)?.data;
};
