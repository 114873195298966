import { SvgIcon, SvgIconProps } from "@mui/material";

function SidebarSvgIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <svg
        width="18"
        height="16"
        viewBox="0 0 18 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M4.09788 3.68627C3.69169 3.68627 3.36258 4.01539 3.36258 4.42157C3.36258 4.82775 3.69169 5.15686 4.09788 5.15686H5.56846C5.97465 5.15686 6.30376 4.82775 6.30376 4.42157C6.30376 4.01539 5.97465 3.68627 5.56846 3.68627H4.09788Z"
          fill="black"
          fillOpacity="0.87"
        />
        <path
          d="M4.09788 6.62745C3.69169 6.62745 3.36258 6.95656 3.36258 7.36275C3.36258 7.76893 3.69169 8.09804 4.09788 8.09804H5.56846C5.97465 8.09804 6.30376 7.76893 6.30376 7.36275C6.30376 6.95656 5.97465 6.62745 5.56846 6.62745H4.09788Z"
          fill="black"
          fillOpacity="0.87"
        />
        <path
          d="M3.36258 10.3039C3.36258 9.89774 3.69169 9.56863 4.09788 9.56863H5.56846C5.97465 9.56863 6.30376 9.89774 6.30376 10.3039C6.30376 10.7101 5.97465 11.0392 5.56846 11.0392H4.09788C3.69169 11.0392 3.36258 10.7101 3.36258 10.3039Z"
          fill="black"
          fillOpacity="0.87"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.09788 0.5C2.20267 0.5 0.666504 2.03617 0.666504 3.93137V11.7745C0.666504 13.6697 2.20267 15.2059 4.09788 15.2059H13.9018C15.797 15.2059 17.3332 13.6697 17.3332 11.7745V3.93137C17.3332 2.03617 15.797 0.5 13.9018 0.5H4.09788ZM13.9018 1.97059H8.75474V13.7353H13.9018C14.9846 13.7353 15.8626 12.8573 15.8626 11.7745V3.93137C15.8626 2.84854 14.9846 1.97059 13.9018 1.97059ZM4.09788 13.7353H7.28415V1.97059H4.09788C3.01504 1.97059 2.13709 2.84854 2.13709 3.93137V11.7745C2.13709 12.8573 3.01504 13.7353 4.09788 13.7353Z"
          fill="black"
          fillOpacity="0.87"
        />
      </svg>
    </SvgIcon>
  );
}

SidebarSvgIcon.muiName = "SidebarSvgIcon";

export default SidebarSvgIcon;
