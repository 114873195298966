import moment from "moment/moment";
import { parseCron } from "./parseCron";
import { formatDate } from "../../../../../utils/helpers/date-time/datetime-format";

export const getTimeFromCron = (cron: string | null) => {
  if (!cron) return null;

  const { hours, minutes } = parseCron(cron);

  if (hours !== undefined && minutes !== undefined) {
    return formatDate(moment().set({ hours, minutes: minutes as number }));
  }

  return null;
};
