import { FC, Fragment } from "react";
import { Skeleton, Stack } from "@mui/material";
import { DashboardEmptyWidgetState } from "./DashboardEmptyWidgetState";

export const WidgetLoadingState: FC = () => {
  return (
    <Fragment>
      <Stack
        px={2}
        py={1}
        direction="row"
        borderBottom={1}
        sx={{ borderBottomColor: "grey.300" }}
        justifyContent="space-between"
      >
        <Stack>
          <Skeleton variant="text" height={24} width={150} />
          <Stack direction="row" spacing={1}>
            <Skeleton height={26} width={100} />
            <Skeleton height={26} width={100} />
            <Skeleton height={26} width={100} />
          </Stack>
        </Stack>
      </Stack>

      <Stack p={2} pb={0} gap={1}>
        <div>
          <Skeleton variant="text" height={24} width={100} />
          <Skeleton variant="text" height={30} width={100} />
        </div>

        <DashboardEmptyWidgetState />
      </Stack>
    </Fragment>
  );
};
