import { createDraftSafeSelector } from "@reduxjs/toolkit";
import { reservationDetailsDataSelector } from "./reservationDetailsDataSelector";
import { ReservationsCoverageDataWithId } from "../../../../../../components/pages/commtiments/common/utils/types";
import { addIdInCoverageData } from "../../../../utils/helpers/addIdInCoverageData";
import { ReservationCoverageData } from "../../../../../../services/cloudchipr.api";

export const reservationDetailsCoverageDataSelector = createDraftSafeSelector(
  [reservationDetailsDataSelector],
  (data): ReservationsCoverageDataWithId[] | undefined => {
    return addIdInCoverageData<ReservationCoverageData>(data?.coverage_data);
  },
);
