import { createDraftSafeSelector } from "@reduxjs/toolkit";
import { reservationsDataForTableSelector } from "./reservationsDataForTableSelector";
import { ReservationsCoverageDataWithId } from "../../../../../components/pages/commtiments/common/utils/types";
import { addIdInCoverageData } from "../../../utils/helpers/addIdInCoverageData";
import { ReservationCoverageData } from "../../../../../services/cloudchipr.api";

export const reservationsCoverageDataSelector = createDraftSafeSelector(
  [reservationsDataForTableSelector],
  (data): ReservationsCoverageDataWithId[] | undefined => {
    return addIdInCoverageData<ReservationCoverageData>(
      data?.utilization_and_coverage?.coverage_data,
    );
  },
);
