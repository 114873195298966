import { FC } from "react";
import {
  Divider,
  Popover,
  PopoverOrigin,
  Stack,
  Typography,
} from "@mui/material";
import { useAppSelector } from "../../../../../../../../../../../store/hooks";
import { organisationFilterSetByResourceTypeSelector } from "../../../../../../../../../../../store/filters/selectors/current-filter-sets/organisationFilterSetByResourceTypeSelector";
import { ResourceType } from "../../../../../../../../../../../services/cloudchipr.api";
import { getResourceTypeName } from "../../../../../../../../../../../utils/helpers/resources/getResourceTypeName";
import { FiltersDisabledView } from "../../../../../../../../../common/filters/filters-disabled-view/FiltersDisabledView";

interface FilterTemplateCellPopoverProps {
  open: boolean;
  onClose(): void;
  anchor: HTMLElement | null;
  filterSetId?: string | null;
  resourceType: ResourceType;
}
export const FilterTemplateCellPopover: FC<FilterTemplateCellPopoverProps> = ({
  open,
  onClose,
  anchor,
  filterSetId,
  resourceType,
}) => {
  const filter = useAppSelector((state) =>
    organisationFilterSetByResourceTypeSelector(
      state,
      resourceType,
      filterSetId,
    ),
  );

  if (!filter) {
    return null;
  }

  return (
    <Popover
      open={open}
      anchorEl={anchor}
      onClose={onClose}
      anchorOrigin={anchorOrigin}
      transformOrigin={transformOrigin}
      PaperProps={paperProps}
    >
      <Typography px={2} py={1} variant="body2" fontWeight="medium">
        {getResourceTypeName(resourceType)}
      </Typography>
      <Divider />
      <Stack px={2} py={1}>
        {filter?.filter_items.length === 0 ? (
          <Typography variant="body2" color="text.secondary">
            No filters applied
          </Typography>
        ) : (
          <FiltersDisabledView filter={filter} />
        )}
      </Stack>
    </Popover>
  );
};

const transformOrigin: PopoverOrigin = {
  vertical: "top",
  horizontal: "left",
};
const anchorOrigin: PopoverOrigin = {
  vertical: "bottom",
  horizontal: "left",
};

const paperProps = {
  style: { maxHeight: 800, width: 420, p: 1 },
};
