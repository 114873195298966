import { FC, Fragment } from "react";
import { Box, Typography, Stack } from "@mui/material";
import { Cube } from "../../../../../../../../../storybook/charts/multi-type-chart/components/common/Cube";
import {
  MIN_CPU_USAGE,
  OffHoursRecommendationsChartColors,
} from "../../../../utils/constants/constants";

interface LegendContentProps {
  withDbConnection: boolean;
}

export const LegendContent: FC<LegendContentProps> = ({ withDbConnection }) => {
  return (
    <Stack direction="row" alignItems="center" justifyContent="center">
      <Typography mr={2} variant="caption" component="span">
        <Cube radius={0.5} color={OffHoursRecommendationsChartColors.CpuArea} />
        Maximum CPU Utilization %
      </Typography>

      {withDbConnection && (
        <Typography mr={2} variant="caption" component="span">
          <Cube
            radius={0.5}
            color={OffHoursRecommendationsChartColors.DbConnectionsArea}
          />
          Database Connections
        </Typography>
      )}

      <Typography mr={2} variant="caption" component="span">
        <Cube
          color={OffHoursRecommendationsChartColors.HighlightedArea}
          radius={1}
        />
        Recommended Off Hours
      </Typography>

      {withDbConnection && (
        <Fragment>
          <Box
            width={10}
            height={2}
            mr={0.5}
            display="inline-block"
            bgcolor={OffHoursRecommendationsChartColors.DbConnectionsLimitColor}
          />
          <Typography mr={2} variant="caption" component="span">
            Limit DB Connections - 1
          </Typography>

          <Box
            width={10}
            height={2}
            mr={0.5}
            display="inline-block"
            bgcolor={OffHoursRecommendationsChartColors.CpuLimitColor}
          />
          <Typography mr={2} variant="caption" component="span">
            Limit CPU Max - {MIN_CPU_USAGE}%
          </Typography>
        </Fragment>
      )}
    </Stack>
  );
};
