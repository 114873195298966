import { FC } from "react";
import {
  Box,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";

export const ResourceDetailsLoading: FC = () => {
  return (
    <Box width={500} minHeight={200}>
      <Box>
        <Skeleton sx={{ ml: 2 }} variant="text" width={200} />
        <Table size="small">
          <TableHead>
            <TableRow sx={{ bgcolor: "grey.50" }}>
              <TableCell width="50%">
                <Skeleton variant="text" width={80} />
              </TableCell>
              <TableCell width="50%">
                <Skeleton variant="text" width={120} />
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>
                <Skeleton variant="text" width={150} />
              </TableCell>
              <TableCell>
                <Skeleton variant="text" width={100} />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Skeleton variant="text" width={120} />
              </TableCell>
              <TableCell>
                <Skeleton variant="text" width={150} />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Skeleton variant="text" width={130} />
              </TableCell>
              <TableCell>
                <Skeleton variant="text" width={170} />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Box>
    </Box>
  );
};
