import { emptySplitApiBillingService as api } from "./empty.billing-service.api";
import {
  PostUsersMeOrganisationsCurrentResourceExplorerApiArg,
  PostUsersMeOrganisationsCurrentResourceExplorerApiResponse,
} from "./cloudchipr.api";

// This file is written by hand, not autogenerated
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    postResourceExplorerDataFromBillingService: build.mutation<
      PostUsersMeOrganisationsCurrentResourceExplorerApiResponse,
      PostResourceExplorerDataFromBillingServiceApiArgs
    >({
      query: (queryArg) => ({
        url: `/organisations/${queryArg.organisationId}/resource-explorer `,
        method: "POST",
        body: queryArg.resourceExplorerFilterSchema,
        headers: { "Content-Type": queryArg["Content-Type"] },
      }),
    }),
  }),
  overrideExisting: false,
});

export { injectedRtkApi as cloudChiprApiBillingService };

export interface PostResourceExplorerDataFromBillingServiceApiArgs
  extends PostUsersMeOrganisationsCurrentResourceExplorerApiArg {
  organisationId: string;
}

export const { usePostResourceExplorerDataFromBillingServiceMutation } =
  injectedRtkApi;
