import { RootState } from "../../../../store";
import { resourceExplorerWidgetDataSelector } from "../resource-explorer-widget/resourceExplorerWidgetDataSelector";
import { ResourceExplorerCostsDateDetails } from "../../../../../services/cloudchipr.api";

export const resourceExplorerDataGridDataPreviousCostDateDetailsSelector = (
  state: RootState,
): ResourceExplorerCostsDateDetails | undefined => {
  return resourceExplorerWidgetDataSelector(state)
    ?.previous_period_cost_date_details;
};
