import { ProviderAccessType } from "../../../../../../../services/cloudchipr.api";

type AzureCommandArgs = {
  providerAccessType: ProviderAccessType;
  servicePrincipal: string;
  type: "tenant" | "subscription";
  identifier: string;
  actionType: "update" | "create";
};

export const generateAzureCommand = ({
  providerAccessType,
  type,
  actionType,
  servicePrincipal,
  identifier,
}: AzureCommandArgs) => {
  const scope =
    type === "subscription"
      ? "/subscriptions/"
      : "/providers/Microsoft.Management/managementGroups/";

  const assignmentDeletionCommand = `az role assignment delete --assignee ${servicePrincipal} --role "Contributor" --scope "${scope}${identifier}"
  `;

  const readerRoleCommand = `az role assignment create --assignee ${servicePrincipal} --role "Reader" --scope "${scope}${identifier}"`;

  const readOnlyCommand =
    actionType === "update"
      ? `${readerRoleCommand} ${assignmentDeletionCommand}`
      : readerRoleCommand;

  return providerAccessType === "read_write"
    ? `az role assignment create --assignee ${servicePrincipal} --role "Contributor" --scope "${scope}${identifier}"
`
    : `${readOnlyCommand}`;
};

export type AzureAccessCommand = {
  actionType: "create" | "delete";
  role: "reader" | "contributor";
};

export const azureReadWriteCommands: AzureAccessCommand[] = [
  {
    actionType: "create",
    role: "contributor",
  },
];

export const azureReadOnlyCommands: AzureAccessCommand[] = [
  {
    actionType: "create",
    role: "reader",
  },
];
