import { RootState } from "../../../../../store";
import { commitmentUtilizationWidgetSetupSelector } from "../../../setups/commitment-utilization/commitmentUtilizationWidgetSetupSelector";
import { commitmentUtilizationWidgetByIdSelector } from "../widget-view/commitmentUtilizationWidgetByIdSelector";
import { isDeepEqual } from "../../../../../../utils/is-deep-equal";

export const commitmentUtilizationWidgetEditChangesExistSelector = (
  state: RootState,
) => {
  const setup = commitmentUtilizationWidgetSetupSelector(state);

  if (!setup?.id) {
    return false;
  }

  const widget = commitmentUtilizationWidgetByIdSelector(state, setup?.id);

  if (!widget) {
    return false;
  }

  return (
    setup.name !== widget.name ||
    setup.grouping !== widget.grouping ||
    setup.providerOrganisationId !== widget.provider_organisation_id ||
    setup?.dates.label !== widget?.dates?.label ||
    setup?.dates.to !== widget?.dates?.to ||
    setup?.dates.from !== widget?.dates?.from ||
    (setup?.dates.unit ?? null) !== (widget?.dates?.unit ?? null) ||
    (setup?.dates.type ?? null) !== (widget?.dates?.type ?? null) ||
    (setup?.dates.value ?? null) !== (widget?.dates?.value ?? null) ||
    !isDeepEqual(setup?.visualization ?? {}, widget?.visualization ?? {}) ||
    !isDeepEqual(setup?.aggregation ?? {}, widget?.aggregation ?? {}) ||
    setup?.trendType !== widget.trend_type ||
    setup.commitmentType !== widget.commitment_type
  );
};
