import { FC, useCallback } from "react";
import { Stack } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../store/hooks";
import { taskManagementUpdateEditingTaskThunk } from "../../../../../../../store/task-management/thunks/actions/taskManagementUpdateEditingTaskThunk";
import { taskUpdateLoadingSelector } from "../../../../../../../store/task-management/selectors/loading/taskUpdateLoadingSelector";
import { tasksListGetLoadingSelector } from "../../../../../../../store/task-management/selectors/loading/tasksListGetLoadingSelector";
import { TaskGridRowActionsMenu } from "../../../../../task-management/components/grid/cell-renderers/row-actions/TaskGridRowActionsMenu";
import { tasksListDataByIdSelector } from "../../../../../../../store/task-management/selectors/data/tasksListDataByIdSelector";
import { taskManagementTaskIdSelector } from "../../../../../../../store/task-management/selectors/form/properties/taskManagementTaskIdSelector";
import { taskEditSaveButtonDisabledSelector } from "../../../../../../../store/task-management/selectors/edit/taskEditSaveButtonDisabledSelector";

interface TaskCreationDrawerHeaderEditActionsProps {
  onClose(): void;
}

export const TaskCreationDrawerHeaderEditActions: FC<
  TaskCreationDrawerHeaderEditActionsProps
> = ({ onClose }) => {
  const dispatch = useAppDispatch();
  const getLoading = useAppSelector(tasksListGetLoadingSelector);
  const editLoading = useAppSelector(taskUpdateLoadingSelector);
  const disabled = useAppSelector(taskEditSaveButtonDisabledSelector);
  const editingTaskId = useAppSelector(taskManagementTaskIdSelector);
  const task = useAppSelector((state) =>
    tasksListDataByIdSelector(state, editingTaskId),
  );

  const saveClickHandler = useCallback(async () => {
    await dispatch(taskManagementUpdateEditingTaskThunk());
    onClose();
  }, [dispatch, onClose]);

  return (
    <Stack direction="row" spacing={1} alignItems="center">
      <LoadingButton
        size="small"
        variant="contained"
        disabled={disabled}
        loading={getLoading || editLoading}
        onClick={saveClickHandler}
      >
        Save
      </LoadingButton>

      <TaskGridRowActionsMenu
        data={task}
        onClose={onClose}
        hiddenActions={["edit"]}
      />
    </Stack>
  );
};
