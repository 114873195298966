import { FC, useCallback } from "react";
import { Button, Stack } from "@mui/material";
import { useFormik } from "formik";
import { useEffectOnceWhen } from "rooks";
import { PeriodAndDateSelection } from "./PeriodAndDateSelection";
import { BudgetAmount } from "./BudgetAmount";
import { BudgetName } from "./BudgetName";
import { BudgetViewSelectionWrapper } from "./BudgetViewSelectionWrapper";
import { Step0InitialValues } from "../../utils/types/formInitialValues";
import { step0ValidationSchema } from "../../utils/constants/formikValidations";
import { StepsProps } from "../../utils/types/types";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../../store/hooks";
import { step0FormDataSelector } from "../../../../../../../../../store/budgets/selectors/current-budget/forms/step0FormDataSelector";
import { setCurrentBudgetData } from "../../../../../../../../../store/budgets/budgetsSlice";
import { getResourceExplorerDataByBudgetThunk } from "../../../../../../../../../store/budgets/thunks/getResourceExplorerDataByBudgetThunk";

export const Step0: FC<StepsProps> = ({ onNext }) => {
  const dispatch = useAppDispatch();

  const formInitialData = useAppSelector(step0FormDataSelector);

  const {
    values,
    handleSubmit,
    setFieldValue,
    isValid,
    dirty,
    setValues,
    handleChange,
    errors,
  } = useFormik({
    initialValues: {} as Step0InitialValues,
    validationSchema: step0ValidationSchema,
    validateOnMount: true,
    onSubmit: (values) => {
      dispatch(
        setCurrentBudgetData({
          name: values.name,
          amount: +values.amount,
          viewId: values.viewId,
          period: values.period,
          start_date: values.startDate,
        }),
      );

      onNext();
    },
  });

  const viewChaneHandler = useCallback(
    (viewId: string) => {
      if (values.viewId === viewId) {
        return;
      }

      setFieldValue("viewId", viewId);
      dispatch(setCurrentBudgetData({ viewId }));
      dispatch(getResourceExplorerDataByBudgetThunk());
    },
    [dispatch, setFieldValue, values.viewId],
  );

  useEffectOnceWhen(() => {
    formInitialData && setValues(formInitialData);
  }, !!formInitialData);

  if (values.viewId === undefined) {
    return null;
  }

  return (
    <form onSubmit={handleSubmit}>
      <Stack spacing={3}>
        <BudgetName name={values.name} handleChange={handleChange} />

        <BudgetViewSelectionWrapper
          selectedItemId={values.viewId}
          onChange={viewChaneHandler}
        />

        <PeriodAndDateSelection
          period={values.period}
          startDate={values.startDate}
          setFieldValue={setFieldValue}
        />

        <BudgetAmount
          error={errors.amount}
          amount={values.amount}
          handleChange={handleChange}
        />

        <Button
          type="submit"
          variant="contained"
          disabled={!dirty || !isValid}
          sx={{ width: "max-content" }}
        >
          Next
        </Button>
      </Stack>
    </form>
  );
};
