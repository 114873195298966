import { FC, Fragment, useCallback, useState } from "react";
import {
  Button,
  DialogActions,
  DialogContent,
  Divider,
  Stack,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useFlag } from "@unleash/proxy-client-react";
import { PermissionOptions } from "../PermissionOptions";
import { GcpConnectionTypes } from "../gcp/components/GcpConnectionTypes";
import { useAppDispatch } from "../../../../../../store/hooks";
import { createAccountAttemptThunk } from "../../../../../../store/account-setup/thunks/createAccountAttemptThunk";
import {
  resetAccountSetup,
  setAccountSetupDialogOpen,
} from "../../../../../../store/account-setup/accountSetupSlice";
import { ProviderType } from "../../../../../../services/cloudchipr.api";

interface PermissionStepProps {
  provider: ProviderType;
}

export const PermissionStep: FC<PermissionStepProps> = ({ provider }) => {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const enableGCPOrganizationSetup = useFlag("EnableGCPOrganizationSetup");

  const handleNextClick = useCallback(() => {
    setLoading(true);
    dispatch(createAccountAttemptThunk());
  }, [dispatch]);

  const handleCloseDialog = useCallback(() => {
    dispatch(setAccountSetupDialogOpen(false));
    dispatch(resetAccountSetup());
  }, [dispatch]);

  return (
    <Fragment>
      <DialogContent>
        {enableGCPOrganizationSetup && provider === "gcp" && (
          <GcpConnectionTypes />
        )}
        <PermissionOptions provider={provider} />
      </DialogContent>
      <Divider light />
      <DialogActions sx={{ px: 3, py: 2 }}>
        <Stack direction="row" spacing={2}>
          <Button
            variant="text"
            color="tertiary"
            size="large"
            onClick={handleCloseDialog}
          >
            Cancel
          </Button>
          <LoadingButton
            loading={loading}
            disabled={loading}
            onClick={handleNextClick}
            variant="contained"
            color="primary"
            size="large"
          >
            Get Started
          </LoadingButton>
        </Stack>
      </DialogActions>
    </Fragment>
  );
};
