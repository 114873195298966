import { FC, Fragment, useState } from "react";
import { Card, Box } from "@mui/material";
import TabContext from "@mui/lab/TabContext";
import { TabPanel } from "@mui/lab";
import { useDidMount, useEffectOnceWhen, useIntervalWhen } from "rooks";
import { useParams } from "react-router-dom";
import { OffHoursRecommendationsGroups } from "./components/OffHoursRecommendationsGroups";
import { OffHoursRecommendationsContent } from "./components/OffHoursRecommendationsContent";
import { RecommendationGroupType } from "./utils/types/types";
import { OffHoursRecommendationsEmptyState } from "./components/OffHoursRecommendationsEmptyState";
import { useAppDispatch, useAppSelector } from "../../../../../store/hooks";
import { PageHeader } from "../../../common/PageHeader";
import { computeRecommendationDoesntExistSelector } from "../../../../../store/recommendations/selectors/off-hours/computeRecommendationDoesntExistSelector";
import { getOffHoursAllRecommendationsThunk } from "../../../../../store/recommendations/thunks/off-hours/data-get/getOffHoursAllRecommendationsThunk";
import { dbResourceTypes } from "../../../../../utils/constants/resources/resources";
import { getOffHoursDismissedResourcesByGroupThunk } from "../../../../../store/recommendations/thunks/off-hours/dismiss/resource/getOffHoursDismissedResourcesByGroupThunk";
import { offHoursRecommendationsPageLoadingSelector } from "../../../../../store/recommendations/selectors/off-hours/loading/offHoursRecommendationsPageLoadingSelector";
import { recommendationsDataGridDataTotalLengthSelector } from "../../../../../store/recommendations/selectors/off-hours/data/recommendationsDataGridDataTotalLengthSelector";
import { recommendationsDataGridDismissedDataTotalLengthSelector } from "../../../../../store/recommendations/selectors/off-hours/data/recommendationsDataGridDismissedDataTotalLengthSelector";
import { offHoursRecommendationsDismissedResourcesDataLengthByGroupSelector } from "../../../../../store/recommendations/selectors/off-hours/dismissed-resources/offHoursRecommendationsDismissedResourcesDataLengthByGroupSelector";
import { offHoursRecommendationsDataGridAllEmptySelector } from "../../../../../store/recommendations/selectors/off-hours/data/offHoursRecommendationsDataGridAllEmptySelector";
import { resetRecommendationsFilters } from "../../../../../store/recommendations/recommendationsSlice";

export const OffHoursRecommendations: FC = () => {
  const { type } = useParams();
  const isDbResource = type && dbResourceTypes.has(type);

  const [group, setGroup] = useState<RecommendationGroupType>(
    isDbResource ? "database" : "compute",
  );
  const dispatch = useAppDispatch();

  const changeTab = useAppSelector(computeRecommendationDoesntExistSelector);
  const loading = useAppSelector(offHoursRecommendationsPageLoadingSelector);

  const activeLength = useAppSelector((state) =>
    recommendationsDataGridDataTotalLengthSelector(state, group),
  );
  const dismissedLength = useAppSelector((state) =>
    recommendationsDataGridDismissedDataTotalLengthSelector(state, group),
  );

  const dismissedResourcesLength = useAppSelector((state) =>
    offHoursRecommendationsDismissedResourcesDataLengthByGroupSelector(
      state,
      group,
    ),
  );

  const allEmpty = useAppSelector(
    offHoursRecommendationsDataGridAllEmptySelector,
  );

  useIntervalWhen(
    () => {
      dispatch(getOffHoursAllRecommendationsThunk());
      dispatch(getOffHoursDismissedResourcesByGroupThunk("compute"));
      dispatch(getOffHoursDismissedResourcesByGroupThunk("database"));
    },
    180_000,
    true,
    true,
  );

  useDidMount(() => {
    dispatch(resetRecommendationsFilters());
  });

  useEffectOnceWhen(() => {
    setGroup("database");
  }, changeTab);

  const empty = !activeLength && !dismissedLength && !dismissedResourcesLength;

  return (
    <Fragment>
      <PageHeader loading={loading} title="Off Hours Recommendations" />

      {!allEmpty && (
        <Box sx={{ m: 2 }}>
          <Card variant="outlined">
            <TabContext value={group}>
              <OffHoursRecommendationsGroups setGroup={setGroup} />

              <TabPanel value="database" sx={{ p: 0 }}>
                <OffHoursRecommendationsContent group="database" />
              </TabPanel>

              <TabPanel value="compute" sx={{ p: 0 }}>
                <OffHoursRecommendationsContent group="compute" />
              </TabPanel>
            </TabContext>
          </Card>
        </Box>
      )}

      {!loading && empty && <OffHoursRecommendationsEmptyState />}
    </Fragment>
  );
};
