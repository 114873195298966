import { FC, useCallback } from "react";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../../../../../../../store/hooks";
import { commitmentUtilizationSetupVisualizationPropertyByKeySelector } from "../../../../../../../../../../../../../../store/dashboards/selectors/setups/commitment-utilization/commitmentUtilizationSetupVisualizationPropertyByKeySelector";
import { commitmentUtilizationWidgetVisualizationChangeThunk } from "../../../../../../../../../../../../../../store/dashboards/thunks/widgets/commitment-utilization/setup-change/commitmentUtilizationWidgetVisualizationChangeThunk";
import { ChartBaseType } from "../../../../../../../../../../../../../../services/cloudchipr.api";
import { WidgetSetupChartBaseSelect } from "../../../../../../../common/toolbar/WidgetSetupChartBaseSelect";

export const CommitmentUtilizationWidgetChartBaseSelect: FC = () => {
  const dispatch = useAppDispatch();
  const chartBase = useAppSelector(
    commitmentUtilizationSetupVisualizationPropertyByKeySelector(
      "chart_base_type",
    ),
  );

  const handleChange = useCallback(
    (base: ChartBaseType) => {
      dispatch(
        commitmentUtilizationWidgetVisualizationChangeThunk({
          chart_base_type: base,
        }),
      );
    },
    [dispatch],
  );

  return (
    <WidgetSetupChartBaseSelect
      chartBase={chartBase}
      onChartChange={handleChange}
    />
  );
};
