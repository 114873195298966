import { createAsyncThunk } from "@reduxjs/toolkit";
import { ResourceType } from "../../../../services/cloudchipr.api";
import { RootState } from "../../../store";
import { liveUsageMgmtSelectedRowsWithHierarchyByResourceTypeSelector } from "../../../live-usage-mgmt/selectors/resource-type-data/liveUsageMgmtSelectedRowsWithHierarchyByResourceTypeSelector";
import { cleanV2ProcessResourcesSelector } from "../../selectors/cleanV2ProcessResourcesSelector";
import {
  CleanV2Details,
  setCleanV2CurrentId,
  setCleanV2Details,
  setCleanV2Open,
} from "../../cleanV2Slice";
import { NEW_CLEAN_ID } from "../../utils/constants";
import { liveUsageMgmtProviderSelector } from "../../../live-usage-mgmt/selectors/store-selectors/liveUsageMgmtProviderSelector";
import { liveUsageMgmtSelectedResourcesAccountIdsSelector } from "../../../live-usage-mgmt/selectors/resource-type-data/liveUsageMgmtSelectedResourcesAccountIdsSelector";

export const createCleanV2Thunk = createAsyncThunk(
  "cleanV2/createCleanV2Thunk",
  (resourceType: ResourceType, { dispatch, getState }) => {
    const state = getState() as RootState;

    const accountIds = liveUsageMgmtSelectedResourcesAccountIdsSelector(
      state,
      resourceType,
    );
    const provider = liveUsageMgmtProviderSelector(state);
    const selectedResources =
      liveUsageMgmtSelectedRowsWithHierarchyByResourceTypeSelector(
        state,
        resourceType,
      );

    const type = Object.keys(selectedResources ?? {})[0] as ResourceType;
    const data = Object.values(selectedResources ?? {})[0];

    if (!type || !data) {
      return;
    }
    const cleanProcessData = cleanV2ProcessResourcesSelector(
      state,
      type,
      data ?? {},
    );

    if (accountIds && provider) {
      const cleanProcess: CleanV2Details = {
        accountIds: accountIds,
        resourceType: type,
        resources: cleanProcessData?.resources,
        excludedResources: cleanProcessData?.excludedResources,
      };

      dispatch(setCleanV2Details(cleanProcess));
      dispatch(setCleanV2CurrentId(NEW_CLEAN_ID));
      dispatch(setCleanV2Open(true));
    }
  },
);
