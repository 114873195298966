import { Fragment } from "react";
import moment from "moment";
import { Typography } from "@mui/material";
import { Payload } from "recharts/types/component/DefaultTooltipContent";
import numeral from "numeral";
import {
  dateFromUtcByTimeZone,
  formatDate,
} from "../../../../../../../../../utils/helpers/date-time/datetime-format";
import { metricLabel } from "../../../../utils/helpers/metricLabel";
import { RightsizingRecommendationsChartColors } from "../../../../utils/constants/constants";

export const tooltipFormatter = (
  time: string,
  data: Payload<string, any>[],
) => {
  const payload = data?.[0]?.payload;

  return (
    <Fragment>
      <Typography variant="body2" fontSize={10} mt={1} component="span">
        {formatDate(dateFromUtcByTimeZone(time) || moment(), {
          type: "dateTime",
        })}
      </Typography>
      <br />
      <Typography variant="body2" fontSize={10} component="span">
        {payload?.recommendedType} {metricLabel(payload?.metric)} CPU{" "}
        {numeral(payload?.recommended).format("0.00")}%
        <Typography
          variant="inherit"
          fontWeight="bold"
          component="span"
          ml={0.5}
          color={RightsizingRecommendationsChartColors.RecommendedSize}
        >
          (Recommended)
        </Typography>
      </Typography>
      <br />
      <Typography variant="body2" fontSize={10} component="span">
        {payload?.currentType} {metricLabel(payload?.metric)} CPU{" "}
        {numeral(payload?.cpu).format("0.00")}%
        <Typography
          variant="inherit"
          fontWeight="bold"
          component="span"
          color={RightsizingRecommendationsChartColors.CpuArea}
          ml={0.5}
        >
          (Current)
        </Typography>
      </Typography>
    </Fragment>
  );
};
