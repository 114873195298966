import {
  SavingsPlanRecommendation,
  SavingsPlanType,
} from "../../../../services/cloudchipr.api";
import { CommitmentsRecommendationsFilters } from "../types/types";

export const filterSavingsPlansRecommendationsByFilters = (
  filters: CommitmentsRecommendationsFilters,
  recommendations?: (SavingsPlanRecommendation & { id: string })[],
) => {
  const {
    type,
    account_ids,
    term_in_years,
    account_scope,
    payment_option,
    look_back_period,
  } = filters;

  return recommendations?.filter((rec) => {
    const typeMatch = (type as SavingsPlanType[])?.includes(
      rec.savings_plan_type,
    );
    const match =
      look_back_period.includes(rec.lookback_period) &&
      payment_option.includes(rec.payment_option) &&
      term_in_years.includes(rec.term_in_years) &&
      (!type ? true : typeMatch);

    const accountsMatch =
      account_scope === "linked" && rec.account_scope !== "payer"
        ? account_ids?.includes(rec.account.id)
        : rec.account_scope === account_scope;

    return match && accountsMatch;
  });
};
