import { FC } from "react";
import { TextField } from "@mui/material";
import { FormikHandlers } from "formik";
import { IntegrationType } from "./integration-type/IntegrationTypeCard";

interface IntegrationFormHeaderFieldProps {
  type: IntegrationType;
  value: string;
  error?: string;
  handleChange: FormikHandlers["handleChange"];
}

export const IntegrationFormHeaderField: FC<
  IntegrationFormHeaderFieldProps
> = ({ handleChange, value, error, type }) => {
  if (type === "slack") {
    return null;
  }

  return (
    <TextField
      fullWidth
      name="name"
      size="small"
      variant="outlined"
      value={value}
      onChange={handleChange}
      label="Integration Name*"
      error={!!error}
      helperText={error}
    />
  );
};
