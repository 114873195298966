import { FC, Fragment } from "react";
import { Stack, Typography } from "@mui/material";

import { CreatedByCellWithChip } from "./CreatedByCellWithChip";
import { CopyIconButton } from "../../../../../../../../../../common/CopyIconButton";
import { useHover } from "../../../../../../../../../../../utils/hooks/useHover.hook";
import { ResourceCreationData } from "../../../../../../../../../../../services/cloudchipr.api";
import { getCreatedByFormattedValue } from "../../../../../../../../../utils/helpers/getCreatedByFormattedValue";
import { TooltipText } from "../../../../../../../../../../common/TooltipText";

interface CreatedByCellProps {
  data: ResourceCreationData;
  copy?: boolean;
}

export const CreatedByCell: FC<CreatedByCellProps> = ({
  data,
  copy = true,
}) => {
  const { ref, hovered } = useHover();

  if (!data?.creator) {
    return <Typography variant="body2">N/A</Typography>;
  }

  const seperatedDataByEndPoint = data.creator.split(":");
  const lastSeperatedItem = seperatedDataByEndPoint.at(-1) ?? data.creator;

  const seperatedData: { excludedPart?: string; value?: string } =
    exportedTexts.reduce((result, item) => {
      if (data?.creator?.startsWith(item)) {
        result = {
          excludedPart: data.creator?.substring(0, item.length).trim(),
          value: data.creator?.substring(item.length).trim(),
        };
      }
      return result;
    }, {});

  return (
    <Stack direction="row" ref={ref} alignItems="center">
      {seperatedData?.value ? (
        <CreatedByCellWithChip
          start={seperatedData?.excludedPart ?? ""}
          end={seperatedData?.value}
          hovered={hovered}
          value={data.creator}
          copy={copy}
        />
      ) : (
        <Fragment>
          <TooltipText
            whiteSpace="nowrap"
            overflow="hidden"
            textOverflow="ellipsis"
            tooltipTitle={data?.creator}
          >
            {getCreatedByFormattedValue(data.creator)}
          </TooltipText>

          {copy && (
            <CopyIconButton
              data={lastSeperatedItem}
              visible={hovered}
              sx={{
                ml: 0.5,
                display: hovered ? "flex" : "none",
              }}
            />
          )}
        </Fragment>
      )}
    </Stack>
  );
};

const exportedTexts = ["app:", "EC2 instance:"];
