import { FC, useCallback } from "react";
import { CSVLink } from "react-csv";
import { SvgIconOwnProps } from "@mui/material/SvgIcon/SvgIcon";
import { ResourceExplorerCsvDownloaderTrigger } from "./ResourceExplorerCsvDownloaderTrigger";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../../store/hooks";
import { resourceExplorerCsvFileNameSelector } from "../../../../../../../../../store/resource-explorer/selectors/current-resource-explorer/csv/resourceExplorerCsvFileNameSelector";
import { resourceExplorerCsvDataSelector } from "../../../../../../../../../store/resource-explorer/selectors/current-resource-explorer/csv/resourceExplorerCsvDataSelector";
import { resourceExplorerGroupingSelector } from "../../../../../../../../../store/resource-explorer/selectors/current-resource-explorer/data/resourceExplorerGroupingSelector";
import { resourceExplorerCSVDataLinkFetcherThunk } from "../../../../../../../../../store/resource-explorer/thunks/widgets/csv/resourceExplorerCSVDataLinkFetcherThunk";
import { resourceExplorerGranularViewCsvDataSelector } from "../../../../../../../../../store/resource-explorer/selectors/current-resource-explorer/csv/resourceExplorerGranularViewCsvDataSelector";
import { resourceExplorerDataGridViewSelector } from "../../../../../../../../../store/resource-explorer/selectors/current-resource-explorer/resourceExplorerDataGridViewSelector";
import { resourceExplorerCsvDownloadLoadingSelector } from "../../../../../../../../../store/resource-explorer/selectors/current-resource-explorer/csv/resourceExplorerCsvDownloadLoadingSelector";

interface ResourceExplorerCsvDownloaderProps {
  trigger: "button" | "menuItem";
  color?: SvgIconOwnProps["color"];
  onDownload?(): void;
}

export const ResourceExplorerCsvDownloader: FC<
  ResourceExplorerCsvDownloaderProps
> = ({ trigger, onDownload, color }) => {
  const dispatch = useAppDispatch();
  const csvFileName = useAppSelector(resourceExplorerCsvFileNameSelector);
  const groupBy = useAppSelector(resourceExplorerGroupingSelector);
  const preparingLoading = useAppSelector(
    resourceExplorerCsvDownloadLoadingSelector,
  );
  const dataGridView = useAppSelector(resourceExplorerDataGridViewSelector);
  const totalCsvData = useAppSelector(resourceExplorerCsvDataSelector);
  const granularCsvData = useAppSelector(
    resourceExplorerGranularViewCsvDataSelector,
  );
  const csvData = dataGridView === "granular" ? granularCsvData : totalCsvData;

  const downloadHandler = useCallback(() => {
    onDownload?.();
    dispatch(resourceExplorerCSVDataLinkFetcherThunk());
  }, [dispatch, onDownload]);

  if (groupBy === "resource") {
    return (
      <ResourceExplorerCsvDownloaderTrigger
        color={color}
        trigger={trigger}
        onClick={downloadHandler}
        disabled={preparingLoading}
      />
    );
  }

  return (
    <CSVLink
      data={csvData ?? []}
      filename={`${csvFileName}.csv`}
      style={{ textDecoration: "none" }}
    >
      <ResourceExplorerCsvDownloaderTrigger trigger={trigger} color={color} />
    </CSVLink>
  );
};
