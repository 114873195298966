import { Typography } from "@mui/material";
import { ColumnSetupType } from "../../../../../../../../../../storybook/data-grid/utils/types/types";
import { SavingsPlanCoverageDataWithId } from "../../../../../../../common/utils/types";
import { money } from "../../../../../../../../../../utils/numeral/money";
import { CommitmentsProgressBar } from "../../../../../../../common/components/CommitmentsProgressBar";
import { SavingsPlanCoverageGrouping } from "../../../../../../../../../../services/cloudchipr.api";
import { getUtilizationAndCoverageDrawerDynamicHeaderCellTitles } from "../../../../../../utils/helpers/getUtilizationAndCoverageDrawerDynamicHeaderCellTitles";

export const getSavingsPlanCommonColumns = (
  grouping: SavingsPlanCoverageGrouping,
): ColumnSetupType<SavingsPlanCoverageDataWithId>[] => [
  {
    accessorKey: "covered_usage",
    header: () => (
      <Typography
        align="right"
        width="100%"
        variant="body2"
        fontWeight="medium"
      >
        {
          getUtilizationAndCoverageDrawerDynamicHeaderCellTitles(
            "commitment",
            grouping,
          )?.coveredUsageCell
        }
      </Typography>
    ),
    meta: {
      headerTitle: getUtilizationAndCoverageDrawerDynamicHeaderCellTitles(
        "commitment",
        grouping,
      )?.coveredUsageCell,
    },
    cell: ({ row }) => (
      <Typography align="right" pr={4} variant="body2">
        {money(row.original.covered_usage)}
      </Typography>
    ),
  },

  {
    accessorKey: "coverage",
    header: getUtilizationAndCoverageDrawerDynamicHeaderCellTitles(
      "commitment",
      grouping,
    )?.coverageCell,
    cell: ({ row }) => (
      <CommitmentsProgressBar
        overrideColor="info.light"
        value={row.original.coverage}
      />
    ),
  },
];
