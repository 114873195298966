import { createAsyncThunk } from "@reduxjs/toolkit";
import { enqueueSnackbar } from "notistack";
import { addNewWidgetToLayoutThunk } from "./addNewWidgetToLayoutThunk";
import { cloudChiprApi } from "../../../../../services/cloudchipr.api";
import { currentDashboardIdSelector } from "../../../selectors/dashboard/currentDashboardIdSelector";
import { RootState } from "../../../../store";
import { createWidgetFixedCacheKey } from "../../../../../components/pages/dashboard/utils/constants/fixedCacheKeys";
import { setWidgetSetup } from "../../../dashboardsSlice";
import { CreateDashboardWidgetRequestType } from "../../../utils/types/types";

export const createWidgetThunk = createAsyncThunk(
  "dashboards/createWidget",
  async (widget: CreateDashboardWidgetRequestType, { dispatch, getState }) => {
    const state = getState() as RootState;
    const { postUsersMeOrganisationsCurrentDashboardsByDashboardIdWidgets } =
      cloudChiprApi.endpoints;

    const dashboardId = currentDashboardIdSelector(state);

    if (!dashboardId) {
      return;
    }

    try {
      const response = await dispatch(
        postUsersMeOrganisationsCurrentDashboardsByDashboardIdWidgets.initiate(
          {
            dashboardId,
            body: { widget },
          },
          { fixedCacheKey: createWidgetFixedCacheKey },
        ),
      ).unwrap();

      dispatch(setWidgetSetup(null));
      await dispatch(addNewWidgetToLayoutThunk(response.id));

      return response;
    } catch (e) {
      // @ts-expect-error todo: api fix
      const errMessage = e?.data?.message || "Something went wrong";
      enqueueSnackbar(errMessage, {
        variant: "snackbarAlert",
        AlertSnackBarProps: { severity: "error" },
      });

      return null;
    }
  },
);
