import { FC, useMemo } from "react";
import { FilterValueInput } from "./FilterValueInput";
import { FilterValueAutocomplete } from "./FilterValueAutocomplete";
import { FilterValueByKeyAutocomplete } from "./FilterValueByKeyAutocomplete";
import { FilterValueSingleSelect } from "./FilterValueSingleSelect";
import { FilterValueAutocompleteWithAsyncSearch } from "./FilterValueAutocompleteWithAsyncSearch";
import { KeyValuePair } from "../../../../../../../../../services/cloudchipr.api";
import { getFilterValueDataTypeByOperator } from "../../../../../utils/helpers/data-type-checkers/getFilterValueDataTypeByOperator";
import { filterValueIsSingleSelectByOperator } from "../../../../../utils/helpers/data-type-checkers/filterValueIsSingleSelectByOperator";
import { FilterItemNodeWithId } from "../../../../../utils/types/common";
import { filterAvailableFieldsChecker } from "../../../../../utils/helpers/data-type-checkers/filterAvailableFieldsChecker";
import { useFilterTreeContext } from "../../../../FilterTreeProvider";
import { filterTypeWithAsyncSearch } from "../../../../../utils/constants/common";
import { isStringProviderType } from "../../../../../../../../../utils/helpers/providers/isStringProviderType";
import { getProviderName } from "../../../../../../../../../utils/helpers/providers/getProviderName";

interface FilterValueProps {
  label?: string;
  filter: FilterItemNodeWithId;
  onChange(value: FilterItemNodeWithId["value"]): void;
}

export const FilterValue: FC<FilterValueProps> = ({
  filter,
  label,
  onChange,
}) => {
  const { possibleFilters } = useFilterTreeContext();

  const { hasKey } = filterAvailableFieldsChecker(filter.type, filter.operator);
  const valueType = getFilterValueDataTypeByOperator(filter.operator);
  const filterValue = hasKey
    ? (filter.value as KeyValuePair)?.value
    : filter.value;

  const valueIsPrimitive = valueType === "string";

  const filterLabel = useMemo(() => {
    if (label) {
      return label;
    }

    let filterLabel = possibleFilters?.find(
      (pf) => pf.key === filter.type,
    )?.label;

    if (
      filter.type === "account" &&
      isStringProviderType(filter.filter_provider)
    ) {
      filterLabel = getProviderName(filter.filter_provider, {
        capitalize: true,
      });
    }

    return filterLabel ?? filter.type;
  }, [label, possibleFilters, filter.type, filter.filter_provider]);

  if (valueIsPrimitive) {
    if (filterValueIsSingleSelectByOperator(filter.operator)) {
      return (
        <FilterValueSingleSelect
          onChange={onChange}
          filterValue={filterValue as string}
          filterType={filter.type}
          filterLabel={filterLabel}
          provider={filter.filter_provider}
        />
      );
    } else {
      return (
        <FilterValueInput
          filterLabel={filterLabel}
          onChange={onChange}
          filter={filter}
        />
      );
    }
  }

  if (filterTypeWithAsyncSearch.has(filter.type)) {
    return (
      <FilterValueAutocompleteWithAsyncSearch
        onChange={onChange}
        filterLabel={filterLabel}
        filterValue={filterValue as string[]}
        filterType={filter.type}
        provider={filter.filter_provider}
      />
    );
  }

  if (hasKey) {
    return (
      <FilterValueByKeyAutocomplete
        onChange={onChange}
        filterLabel={filterLabel}
        filterType={filter.type}
        provider={filter.filter_provider}
        value={filter?.value as KeyValuePair}
      />
    );
  }

  return (
    <FilterValueAutocomplete
      onChange={onChange}
      filterLabel={filterLabel}
      filterValue={filterValue as string[]}
      filterType={filter.type}
      provider={filter.filter_provider}
    />
  );
};
