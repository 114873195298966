import { FC, Fragment } from "react";
import { WorkflowSchedulePreview } from "./components/WorkflowSchedulePreview";
import { WorkflowResourcesPreview } from "./components/WorkflowResourcesPreview";
import { WorkflowNotificationsPreview } from "./components/WorkflowNotificationsPreview";
import { AutomationEnvironmentPreview } from "../../../common/components/preview/AutomationEnvironmentPreview";

export const WorkflowFormPreview: FC = () => {
  return (
    <Fragment>
      <WorkflowSchedulePreview />

      <AutomationEnvironmentPreview />

      <WorkflowNotificationsPreview />

      <WorkflowResourcesPreview />
    </Fragment>
  );
};
