import { FC } from "react";
import { Stack } from "@mui/material";
import { AccountCardFilterTemplateName } from "./account-card/filter-template/AccountCardFilterTemplateName";
import {
  AccountStatus,
  ProviderType,
} from "../../../../../services/cloudchipr.api";
import { activeAccountStatuses } from "../../../../../utils/constants/accounts";

interface FilterTemplateCellProps {
  accountId: string | null;
  provider: ProviderType;
  filterSetId: string | null;
  status: AccountStatus;
}

export const FilterTemplateCell: FC<FilterTemplateCellProps> = ({
  accountId,
  provider,
  filterSetId,
  status,
}) => {
  if (!activeAccountStatuses.has(status)) {
    return null;
  }
  return (
    <Stack direction="row" spacing={0.25} width={200}>
      <AccountCardFilterTemplateName
        accountId={accountId ?? ""}
        provider={provider}
        filterSetId={filterSetId}
      />
    </Stack>
  );
};
