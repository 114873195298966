import { RootState } from "../../store";
import {
  cloudChiprApi,
  ProviderAccessType,
} from "../../../services/cloudchipr.api";

export const awsCfnStackFixedCacheKeyGenerator = (
  accessType?: ProviderAccessType,
) => {
  return `aws_cfn_json_${accessType}_cache_key`;
};

export const awsCfnStackJsonSelector = (
  state: RootState,
  accessType: ProviderAccessType,
) => {
  const data = cloudChiprApi.endpoints.postProvidersAwsCfnTemplates.select(
    awsCfnStackFixedCacheKeyGenerator(accessType),
  )(state)?.data;

  if (!data) {
    return "";
  }

  return JSON.stringify(data);
};
