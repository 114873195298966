import { Box, Stack } from "@mui/material";
import { GetUsersMeOrganisationsCurrentDismissedResourcesApiResponse } from "../../../../../../../services/cloudchipr.api";
import { ColumnSetupType } from "../../../../../../../storybook/data-grid/utils/types/types";
import { TypographyWithTooltip } from "../../../../../../common/TypographyWithTooltip";
import { SelectAllHeaderCheckbox } from "../../../../../../../storybook/data-grid/common/SelectAllCheckboxHeaderCell";
import { ExecutionLogsDataGridResourcesType } from "../../../../../execution-log/utils/types/types";
import { AccountInfoData } from "../../../../../../common/AccountInfoData";
import { ResourceRestoreButton } from "../../../common/dismiss/by-resource/ResourceRestoreButton";
import { RowSelectionCellCheckbox } from "../../../../../../../storybook/data-grid/common/RowSelectionCellCheckbox";

export const rightsizingDismissedResourcesDataGridColumns: ColumnSetupType<
  GetUsersMeOrganisationsCurrentDismissedResourcesApiResponse[0]
>[] = [
  {
    accessorKey: "name",
    header: (props) => (
      <Stack direction="row" spacing={0.5} alignItems="center">
        <SelectAllHeaderCheckbox {...props} sxProps={{ pl: 0 }} />
        Instance ID
      </Stack>
    ),
    cell: (cell) => (
      <Stack direction="row" spacing={0.5} alignItems="center">
        <Box sx={{ "& span": { pl: 0 } }}>
          <RowSelectionCellCheckbox row={cell.row} table={cell.table} />
        </Box>

        <TypographyWithTooltip
          maxWidth={200}
          variant="body2"
          title={(cell.getValue() as string) ?? ""}
        />
      </Stack>
    ),
  },
  {
    header: "Account",
    accessorKey: "account",
    cell: (cell) => {
      const account =
        cell.getValue() as ExecutionLogsDataGridResourcesType["account"];

      return (
        <AccountInfoData
          chipView
          maxWidth={150}
          accountId={
            account.provider_account_name ||
            account.provider_account_id ||
            account.id
          }
          provider={account.provider}
        />
      );
    },
  },
  {
    header: "Current Type (vCPU)",
    accessorKey: "type",
  },
  {
    header: "Dismissed By",
    accessorKey: "dismissed_by",
  },
  {
    header: "Actions",
    id: "actions",
    cell: (cell) => {
      const data = cell.row
        .original as GetUsersMeOrganisationsCurrentDismissedResourcesApiResponse[0];

      return <ResourceRestoreButton resourceId={data.id} />;
    },
  },
];
