import { FC, Fragment, ReactNode } from "react";
import Typography from "@mui/material/Typography";
import { Box, Button, IconButton, Stack } from "@mui/material";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import { capitalizeString } from "../../../../utils/helpers/capitalizeString";
import { Budget } from "../../../../services/cloudchipr.api";
import { ResourceExplorerBudgetTooltip } from "../../../pages/resource-explorer/components/resource-explorer-card/components/resource-explorer-metadata/components/budgets/tooltip/ResourceExplorerBudgetTooltip";
import { money } from "../../../../utils/numeral/money";
import { ResourceExplorerBudgetProgress } from "../../../pages/resource-explorer/components/resource-explorer-card/components/resource-explorer-metadata/components/budgets/ResourceExplorerBudgetProgress";
import { generateDateLabelByBudgetPeriod } from "../../../pages/resource-explorer/components/resource-explorer-card/components/resource-explorer-metadata/components/budgets/tooltip/utils/helpers/generateDateLabelByBudgetPeriod";

interface InternalResourceExplorerBudgetProps {
  budget: Budget | null;
  showBudgetLine: boolean;
}

export const InternalResourceExplorerBudget: FC<
  InternalResourceExplorerBudgetProps
> = ({ budget, showBudgetLine }) => {
  const dateLabelByBudgetPeriod = generateDateLabelByBudgetPeriod(
    budget?.start_date,
    budget?.period,
  );

  if (!budget) {
    return CreateBudgetButton;
  }

  return (
    <Box>
      <Stack direction="row" spacing={0.5} alignItems="center">
        <Typography
          variant="subtitle2"
          color="text.secondary"
          fontWeight="regular"
        >
          Budget - {capitalizeString(budget.period)} ({dateLabelByBudgetPeriod})
        </Typography>

        <IconButton size="small">
          {showBudgetLine ? (
            <VisibilityOffOutlinedIcon fontSize="inherit" />
          ) : (
            <VisibilityOutlinedIcon fontSize="inherit" />
          )}
        </IconButton>
      </Stack>

      <ResourceExplorerBudgetTooltip
        name={budget.name}
        amount={budget.amount}
        progress={budget.progress}
        startDate={budget.start_date}
        period={budget.period}
      >
        <Fragment>
          <Typography variant="h6" fontWeight="medium">
            {money(budget.amount)}
          </Typography>
          <ResourceExplorerBudgetProgress progress={budget.progress} />
        </Fragment>
      </ResourceExplorerBudgetTooltip>
    </Box>
  );
};

const CreateBudgetButton: ReactNode = (
  <Stack>
    <Typography variant="subtitle2" color="text.secondary" fontWeight="regular">
      Budget
    </Typography>

    <Button
      sx={{
        minWidth: 28,
        width: 28,
        height: 28,
        borderRadius: 2,
        bgcolor: "grey.200",
        fontSize: 22,
        fontWeight: 400,
      }}
      color="tertiary"
      variant="contained"
    >
      +
    </Button>
  </Stack>
);
