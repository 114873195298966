import { FC } from "react";
import { yellow } from "@mui/material/colors";
import { Stack, Typography } from "@mui/material";
import NotificationsIcon from "@mui/icons-material/Notifications";

export const SlackIntegrationGracePeriodMessageTitle: FC = () => {
  return (
    <Stack spacing={1} direction="row">
      <NotificationsIcon
        fontSize="small"
        sx={{
          color: yellow["700"],
        }}
      />
      <Typography variant="body1" fontWeight="medium">
        Actions will be performed after Grace Period expires.
      </Typography>
    </Stack>
  );
};
