import { largestCostChangesSetupPropertyByKeySelector } from "./largestCostChangesSetupPropertyByKeySelector";
import { RootState } from "../../../../store";
import { largestCostChangesWidgetEditChangesExistSelector } from "../../widgets/largest-cost-changes/largestCostChangesWidgetEditChangesExistSelector";

export const largesCostChangesSubmitButtonDisabledSelector = (
  state: RootState,
): boolean => {
  const editDisabledByChange =
    largestCostChangesWidgetEditChangesExistSelector(state);
  const viewId = largestCostChangesSetupPropertyByKeySelector("viewId")(state);
  const name = largestCostChangesSetupPropertyByKeySelector("name")(state);
  const id = largestCostChangesSetupPropertyByKeySelector("id")(state);

  return (id && !editDisabledByChange) || !name || !viewId;
};
