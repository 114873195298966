import { slackMdToHtml } from "../../common/integration-dialogs/components/slack/utils/slackMdToHtml";
import { Schedule } from "../../../services/cloudchipr.api";

export const formatNotificationsFromSlackToHtml = (
  notifications: Schedule["notifications"],
) => {
  return notifications?.map((item) => {
    if (item.type === "slack") {
      item = {
        ...item,
        custom_message: item.custom_message
          ? slackMdToHtml(item.custom_message)
          : "",
      };
      return item;
    }
    return item;
  });
};
