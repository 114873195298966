import { Row } from "@tanstack/react-table";
import { ResourceExplorerGridDataWithId } from "../../../../../../../../../../../../store/resource-explorer/utils/types/resourceExplorerDataGrid";
import { ForecastOption } from "../../../../../../../../../../../../services/cloudchipr.api";

export const getForecastedCostsSortingFn = (forecastOption: ForecastOption) => {
  return (
    currentRow: Row<ResourceExplorerGridDataWithId>,
    nextRow: Row<ResourceExplorerGridDataWithId>,
  ) => {
    const currentCost =
      forecastOption === "month"
        ? currentRow.original.monthly_forecast?.cost
        : currentRow.original.quarterly_forecast?.cost;

    const nextCost =
      forecastOption === "month"
        ? nextRow.original.monthly_forecast?.cost
        : nextRow.original.quarterly_forecast?.cost;

    return currentCost > nextCost || !nextCost ? -1 : 1;
  };
};
