import { FC, Fragment } from "react";
import { CostBreakdownWidgetVisualizationSection } from "./components/visualization/CostBreakdownWidgetVisualizationSection";
import { CostBreakdownWidgetAggregationsSection } from "./components/aggregations/CostBreakdownWidgetAggregationsSection";
import { CostBreakdownWidgetAdvancedSettingsSection } from "./components/advanced-settings/CostBreakdownWidgetAdvancedSettingsSection";

export const CostBreakdownWidgetConfigurations: FC = () => {
  return (
    <Fragment>
      <CostBreakdownWidgetVisualizationSection />
      <CostBreakdownWidgetAggregationsSection />
      <CostBreakdownWidgetAdvancedSettingsSection />
    </Fragment>
  );
};
