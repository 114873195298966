import { CSSProperties, FC, ReactNode, useCallback, useState } from "react";
import {
  Box,
  Button,
  Card,
  Grid,
  IconButton,
  LinearProgress,
  Stack,
} from "@mui/material";
import ArrowCircleLeftOutlinedIcon from "@mui/icons-material/ArrowCircleLeftOutlined";
import { VideoWithoutCaptions } from "../../../common/VideoWithoutCaptions";

export type GuideStep = {
  media: string;
  mediaType?: "img" | "video";
  borderColor?: string;
  borderRadius?: number;
  component: ReactNode;
};

interface StepByStepGuideProps {
  steps: GuideStep[];
  mediaHeight?: number;
  onDone?(step: number): void;
}

export const StepByStepGuide: FC<StepByStepGuideProps> = ({
  steps,
  onDone,
  mediaHeight,
}) => {
  const [step, setStep] = useState(0);
  const lastStep = steps.length - 1;

  const previousHandler = useCallback(() => {
    setStep((step) => step - 1);
  }, []);

  const nextHandler = useCallback(() => {
    setStep((step) => {
      if (step === lastStep) {
        onDone?.(0);
        return 0;
      }

      return step + 1;
    });
  }, [onDone, lastStep]);

  const stepComponent = steps?.at(step)?.component;
  const stepMedia = steps?.at(step)?.media;
  const stepMediaType = steps?.at(step)?.mediaType;
  const borderColor = steps?.at(step)?.borderColor;
  const borderRadius = steps?.at(step)?.borderRadius;

  if (!stepComponent || !stepMedia) {
    return null;
  }
  return (
    <Card variant="outlined" sx={{ p: 2, width: "100%" }}>
      <Grid container>
        <Grid item sm={12} md={6}>
          <Box
            border={borderColor ? `2px solid ${borderColor}` : undefined}
            borderRadius={borderRadius}
            fontSize={0}
            overflow="hidden"
          >
            {stepMediaType === "video" ? (
              <VideoWithoutCaptions
                src={stepMedia}
                autoPlay
                loop
                style={{
                  ...mediaStyles,
                  height: mediaHeight ?? mediaStyles.height,
                }}
              />
            ) : (
              <img
                src={stepMedia}
                alt={`Step ${step}`}
                style={{
                  ...mediaStyles,
                  height: mediaHeight ?? mediaStyles.height,
                }}
              />
            )}
          </Box>
        </Grid>

        <Grid item sm={12} md={6}>
          <Stack
            height="100%"
            justifyContent="space-between"
            pl={2}
            maxHeight={380}
            overflow="auto"
          >
            <Box overflow="auto">{stepComponent}</Box>

            <Box>
              <Stack
                direction="row"
                justifyContent="end"
                alignItems="center"
                mt={1}
                mb={2}
              >
                {step !== 0 && (
                  <IconButton onClick={previousHandler} sx={{ mr: 1, p: 0 }}>
                    <ArrowCircleLeftOutlinedIcon
                      color="primary"
                      fontSize="large"
                    />
                  </IconButton>
                )}

                <Button variant="contained" onClick={nextHandler}>
                  {step === lastStep ? "Done" : "Next"}
                </Button>
              </Stack>

              <LinearProgress
                variant="determinate"
                value={Math.min(Math.ceil(100 / lastStep) * step, 100)}
              />
            </Box>
          </Stack>
        </Grid>
      </Grid>
    </Card>
  );
};

const mediaStyles: CSSProperties = {
  height: "380px",
  width: "100%",
  objectFit: "cover",
};
