import { FC, useCallback, useEffect, useState } from "react";
import { CustomCellRendererProps } from "@ag-grid-community/react";
import { Task } from "../../../../../../services/cloudchipr.api";
import { useAppDispatch } from "../../../../../../store/hooks";
import { taskManagementUpdateTaskThunk } from "../../../../../../store/task-management/thunks/actions/taskManagementUpdateTaskThunk";
import { TaskPropertySelection } from "../../../../common/task-management/components/common/properties/TaskPropertySelection";

export const PriorityCellRenderer: FC<CustomCellRendererProps<Task>> = ({
  data,
}) => {
  const dispatch = useAppDispatch();
  const [priorityId, setPriorityId] = useState<string | null>();

  const changeHandler = useCallback(
    (value: string | null) => {
      if (!data?.id || priorityId === value) {
        return null;
      }

      setPriorityId(value);

      dispatch(
        taskManagementUpdateTaskThunk({
          task_ids: [data.id],
          priority_id: { value },
        }),
      );
    },
    [dispatch, data?.id, priorityId],
  );

  useEffect(() => {
    setPriorityId(data?.priority?.id ?? null);
  }, [data?.priority?.id]);

  if (!data) {
    return null;
  }

  return (
    <TaskPropertySelection
      optionsKey="priorities"
      value={priorityId}
      label="Priority"
      onChange={changeHandler}
    />
  );
};
