import { createAsyncThunk } from "@reduxjs/toolkit";
import { enqueueSnackbar } from "notistack";
import {
  cloudChiprApi,
  WidgetType,
} from "../../../../../services/cloudchipr.api";
import { currentDashboardIdSelector } from "../../../selectors/dashboard/currentDashboardIdSelector";
import { RootState } from "../../../../store";
import { generateUpdateDashboardWidgetFixedCacheKey } from "../../../../../components/pages/dashboard/utils/helpers/dashbaordFixedCacheKeyGenerator";
import { getDashboardByIdThunk } from "../../dashboard/getDashboardByIdThunk";
import { dashboardWidgetLabels } from "../../../../../components/pages/dashboard/utils/constants/constants";

interface UpdateWidgetNameThunkArgs {
  widgetId: string;
  widgetType: WidgetType;
  newName: string;
}

export const updateWidgetNameThunk = createAsyncThunk(
  "dashboards/updateWidgetNameThunk",
  async (
    { widgetId, widgetType, newName }: UpdateWidgetNameThunkArgs,
    { dispatch, getState },
  ) => {
    const state = getState() as RootState;
    const {
      patchUsersMeOrganisationsCurrentDashboardsByDashboardIdWidgetsAndWidgetId,
    } = cloudChiprApi.endpoints;

    const dashboardId = currentDashboardIdSelector(state);

    if (!dashboardId) {
      return;
    }

    try {
      const response = await dispatch(
        patchUsersMeOrganisationsCurrentDashboardsByDashboardIdWidgetsAndWidgetId.initiate(
          {
            dashboardId,
            widgetId,
            body: { widget: { name: newName, type: widgetType } },
          },
          {
            fixedCacheKey: generateUpdateDashboardWidgetFixedCacheKey(widgetId),
          },
        ),
      ).unwrap();

      if (response) {
        enqueueSnackbar(
          `${dashboardWidgetLabels[widgetType]} widget name successfully updated.`,
          {
            variant: "snackbarAlert",
            AlertSnackBarProps: { severity: "success" },
          },
        );

        await dispatch(getDashboardByIdThunk(dashboardId));
      }

      return response;
    } catch (e) {
      // @ts-expect-error todo: api fix
      const errMessage = e?.data?.message || "Something went wrong";
      enqueueSnackbar(errMessage, {
        variant: "snackbarAlert",
        AlertSnackBarProps: { severity: "error" },
      });

      return null;
    }
  },
);
