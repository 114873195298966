import { FC, Fragment, useRef } from "react";
import { AgGridReact } from "@ag-grid-community/react";
import { useFlag } from "@unleash/proxy-client-react";
import { RolesDataGrid } from "./list/RolesDataGrid";
import { RolesDataGridToolbar } from "./list/cells/toolbar/RolesDataGridToolbar";
import { PageHeader } from "../../../common/PageHeader";

export const UserRoles: FC = () => {
  const enableUserRoleUsageFlow = useFlag("EnableUserRoleUsageFlow");
  const gridRef = useRef<AgGridReact>(null);

  if (!enableUserRoleUsageFlow) {
    return null;
  }

  return (
    <Fragment>
      <PageHeader title="Roles" />
      <RolesDataGridToolbar gridRef={gridRef} />
      <RolesDataGrid refToPassGrid={gridRef} />
    </Fragment>
  );
};
