import { FC, useCallback } from "react";
import {
  Checkbox,
  FormControlLabel,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

interface NotificationSnoozingProps {
  snoozed: boolean;
  onChange(snoozed: boolean): void;
}

export const NotificationSnoozing: FC<NotificationSnoozingProps> = ({
  snoozed,
  onChange,
}) => {
  const changeHandler = useCallback(
    () => onChange(!snoozed),
    [snoozed, onChange],
  );

  return (
    <Stack direction="row" alignItems="center" pl={1}>
      <FormControlLabel
        control={
          <Checkbox size="small" checked={!snoozed} onChange={changeHandler} />
        }
        label={
          <Typography variant="body2" color="text.secondary">
            Always Notify
          </Typography>
        }
      />

      <Tooltip
        arrow
        placement="top"
        title="If checked, Cloudchipr will not keep track of the resources found during
        each run and will send notifications for the same resources every time.
        By default, Cloudchipr will remember the resources found after each run
        and will only send notifications when new resources are found or actions
        are taken on previously discovered resources."
      >
        <InfoOutlinedIcon fontSize="small" color="info" />
      </Tooltip>
    </Stack>
  );
};
