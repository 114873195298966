import { createAsyncThunk } from "@reduxjs/toolkit";
import { cloudChiprApi } from "../../../services/cloudchipr.api";

export const getOrganisationUsersThunk = createAsyncThunk(
  "users/getOrganisationUsers",
  async (_, { dispatch }) => {
    return await dispatch(
      cloudChiprApi.endpoints.getV2UsersMeOrganisationsCurrentUsers.initiate(
        {},
      ),
    ).unwrap();
  },
);
