import { FC, useCallback, useMemo } from "react";
import { Button } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import {
  taskPropertyColorByDefault,
  taskPropertiesColors,
} from "../../../../utils/constants/taskPropertiesColors";
import { TaskPropertyType } from "../../../../../../../../store/task-management/utils/types/types";

interface TaskPropertyAdderProps {
  label: string;
  properties: TaskPropertyType[];
  onPropertyAdd(color: string): void;
}

export const TaskPropertyAdder: FC<TaskPropertyAdderProps> = ({
  label,
  onPropertyAdd,
  properties,
}) => {
  const firstAvailableColor = useMemo(() => {
    return (
      taskPropertiesColors
        .filter((color) =>
          properties.every(
            (prop) => prop.color.toLowerCase() !== color.toLowerCase(),
          ),
        )
        .at(0) ?? taskPropertyColorByDefault
    );
  }, [properties]);

  const disabled = useMemo(() => {
    return (
      properties.length >= taskPropertiesColors.length ||
      properties.some((prop) => !prop.name)
    );
  }, [properties]);

  const addHandler = useCallback(() => {
    onPropertyAdd(firstAvailableColor);
  }, [firstAvailableColor, onPropertyAdd]);

  return (
    <Button
      onClick={addHandler}
      disabled={disabled}
      sx={{ alignSelf: "start" }}
      startIcon={<AddIcon fontSize="small" />}
    >
      Add {label}
    </Button>
  );
};
