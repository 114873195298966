import { FC, useMemo } from "react";
import { Box, IconButton, Tooltip } from "@mui/material";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import { CSVLink } from "react-csv";
import { ExecutionLogV3 } from "../../../../../../services/cloudchipr.api";
import { generateCsvDataFromResponse } from "../../../utils/helpers/generateCsvDataFromResponse";
import { useAppSelector } from "../../../../../../store/hooks";
import { executionLogAutomationExistSelector } from "../../../../../../store/execution-log/selectors/executionLogAutomationExistSelector";

interface ExecutionLogsDataGridCSVCellProps {
  executionLog: ExecutionLogV3;
}

export const ExecutionLogsDataGridCSVCell: FC<
  ExecutionLogsDataGridCSVCellProps
> = ({ executionLog }) => {
  const automationExist = useAppSelector((state) =>
    executionLogAutomationExistSelector(
      state,
      executionLog.source?.metadata?.id,
    ),
  );

  const csvData = useMemo(
    () => generateCsvDataFromResponse(executionLog, automationExist),
    [executionLog, automationExist],
  );

  return (
    <Box textAlign="end">
      <CSVLink
        filename={`Crated_at_${executionLog.created_at}_execution_log_data.csv`}
        data={csvData ?? []}
      >
        <Tooltip title="Export CSV" placement="top">
          <IconButton size="small">
            <DownloadOutlinedIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      </CSVLink>
    </Box>
  );
};
