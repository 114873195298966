import { FC, memo } from "react";
import { Box, Typography } from "@mui/material";
import { Editor } from "../../../../../storybook/editor/Editor";

interface CreateIntegrationMessageEditorProps {
  value: string;
  minHeight?: string;
  onChange(value: string): void;
  placeholder?: string;
}

export const CreateIntegrationMessageEditor: FC<CreateIntegrationMessageEditorProps> =
  memo(({ value, onChange, minHeight, placeholder }) => {
    return (
      <Box flexGrow={1}>
        <Typography variant="body1" fontWeight="bold" mb={1}>
          Message
        </Typography>

        <Editor
          value={value}
          onChange={onChange}
          toolbarOptions={{
            minHeight: minHeight ?? "120px",
            buttonList: [["bold", "italic", "strike"], [], ["link"]],
          }}
          placeholder={placeholder}
        />
      </Box>
    );
  });
