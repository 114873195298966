import { FC, useCallback, useState } from "react";
import {
  Button,
  Chip,
  Divider,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from "@mui/material";
import { useEffectOnceWhen } from "rooks";
import { OktaSsoFormDialog } from "./components/okta-form-dialog/OktaSsoFormDialog";
import { AzureSsoFormDialog } from "./components/azure-form-dialog/AzureSsoFormDialog";
import { DisableSsoButton } from "./components/DisableSsoButton";
import {
  PostUsersMeOrganisationsCurrentSsoApiArg,
  useGetUsersMeOrganisationsCurrentQuery,
  useGetUsersMeOrganisationsCurrentSsoOptionsCurrentQuery,
} from "../../../../../../../services/cloudchipr.api";
import { useDialog } from "../../../../../../../utils/hooks/useDialog.hook";

export const AuthSSO: FC = () => {
  const [ssoType, setSsoType] =
    useState<PostUsersMeOrganisationsCurrentSsoApiArg["body"]["type"]>("okta");

  const {
    data: organization,
    refetch,
    isLoading,
  } = useGetUsersMeOrganisationsCurrentQuery();

  const { isError, isFetching } =
    useGetUsersMeOrganisationsCurrentSsoOptionsCurrentQuery();

  const {
    open: oktaOpen,
    openDialog: openOktaDialog,
    closeDialog: closeOktaDialog,
  } = useDialog();

  const {
    open: azureOpen,
    openDialog: openAzureDialog,
    closeDialog: closeAzureDialog,
  } = useDialog();

  const handleFormSubmit = useCallback(() => {
    closeOktaDialog();
    closeAzureDialog();
    refetch();
  }, [refetch, closeOktaDialog, closeAzureDialog]);

  const ssoTypeChangeHandler = useCallback((event: any) => {
    setSsoType(event.target.value);
  }, []);

  const openSsoDialogHandler = useCallback(() => {
    if (ssoType === "okta") {
      openOktaDialog();
    } else if (ssoType === "azure_ad") {
      openAzureDialog();
    }
  }, [ssoType, openOktaDialog, openAzureDialog]);

  useEffectOnceWhen(() => {
    if (organization?.sso_connection_type) {
      setSsoType(organization?.sso_connection_type);
    }
  }, !!organization?.sso_connection_type);

  if (isError || isFetching) {
    return null;
  }

  return (
    <Stack spacing={2} mt={5}>
      <Divider sx={{ mb: 3 }} />
      <Stack direction="row" alignItems="center" gap={2}>
        <Typography variant="h6">Enable Single Sign On (SSO) </Typography>
        <Chip
          label="Enterprise"
          variant="filled"
          color="primary"
          size="small"
        />
      </Stack>

      <Typography variant="body1" color="text.secondary" mt={2} mb={5}>
        All users are unlinked from SSO when a new SSO provider is selected. If
        you proceed, all users must re-authenticate with an SSO provider.
      </Typography>

      {!isLoading && (
        <FormControl disabled={!!organization?.sso_connection_type}>
          <RadioGroup
            defaultValue="okta"
            value={ssoType}
            onChange={ssoTypeChangeHandler}
          >
            <FormControlLabel value="okta" control={<Radio />} label="Okta" />
            <FormControlLabel
              value="azure_ad"
              control={<Radio />}
              label="Microsoft"
            />
          </RadioGroup>
        </FormControl>
      )}

      <DisableSsoButton ssoType={ssoType} />

      {!organization?.sso_connection_type && (
        <Button
          variant="outlined"
          color="primary"
          onClick={openSsoDialogHandler}
        >
          Enable
        </Button>
      )}

      <OktaSsoFormDialog
        open={oktaOpen}
        onClose={closeOktaDialog}
        onSubmit={handleFormSubmit}
      />

      <AzureSsoFormDialog
        open={azureOpen}
        onClose={closeAzureDialog}
        onSubmit={handleFormSubmit}
      />
    </Stack>
  );
};
