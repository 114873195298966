import { FC, SyntheticEvent, useCallback } from "react";
import { RadioGroup } from "@mui/material";
import { AlertsDrawerTab } from "./AlertsDrawerTab";
import { AlertTabType } from "../../../../../../store/alerts/utils/types/types";
import { resetAlertsData } from "../../../../../../store/alerts/alertsSlice";
import { useAppDispatch } from "../../../../../../store/hooks";
import { initiateCostAnomalyAlertThunk } from "../../../../../../store/alerts/thunks/cost-anomaly-alerts/initiateCostAnomalyAlertThunk";
import { initiateUtilizationAlertThunk } from "../../../../../../store/alerts/thunks/utilization-alerts/initiateUtilizationAlertThunk";

interface AlertsDrawerTabsProps {
  drawerTab: AlertTabType;
  setDrawerTab(drawerTab: AlertTabType): void;
}

export const AlertsDrawerTabs: FC<AlertsDrawerTabsProps> = ({
  drawerTab,
  setDrawerTab,
}) => {
  const dispatch = useAppDispatch();

  const handleChange = useCallback(
    (event: SyntheticEvent) => {
      const target = event.target as HTMLInputElement;
      const tab = target.defaultValue as AlertTabType;

      setDrawerTab(tab);
      dispatch(resetAlertsData());

      if (tab === "costAnomaly") {
        dispatch(initiateCostAnomalyAlertThunk());
      } else {
        dispatch(initiateUtilizationAlertThunk());
      }
    },
    [setDrawerTab, dispatch],
  );

  return (
    <RadioGroup row value={drawerTab} sx={{ gap: 1 }}>
      <AlertsDrawerTab
        labelTitle="Cost Anomaly Alert"
        value="costAnomaly"
        onChange={handleChange}
        selected={drawerTab === "costAnomaly"}
        tooltipTitle={costAnomalyTooltipTitle}
      />
      <AlertsDrawerTab
        labelTitle="Utilization & Coverage Alert"
        value="utilization"
        onChange={handleChange}
        selected={drawerTab === "utilization"}
        tooltipTitle={utilizationTooltipTitle}
      />
    </RadioGroup>
  );
};

const costAnomalyTooltipTitle =
  "Detect and configure alerts for significant cost changes.";
const utilizationTooltipTitle =
  "Receive notifications about the utilization and coverage of your Savings Plans and reservations to ensure they remain within the defined targets.";
