import { FC, useMemo } from "react";
import { Divider, Stack, Typography } from "@mui/material";
import { InternalResourceExplorerBudget } from "./InternalResourceExplorerBudget";
import { getResourceExplorerWidgetAverageData } from "../../../../store/resource-explorer/utils/helpers/getResourceExplorerWidgetAverageData";
import {
  ResourceExplorer,
  ResourceExplorerWidget,
} from "../../../../services/cloudchipr.api";
import { generateResourceExplorerTotalCostTrend } from "../../../../store/resource-explorer/utils/helpers/generateResourceExplorerTotalCostTrend";
import { ResourceExplorerForecastedCosts } from "../../../pages/resource-explorer/components/resource-explorer-card/components/resource-explorer-metadata/components/forecasted/ResourceExplorerForecastedCosts";
import { LiveFilteredTitle } from "../../../pages/resource-explorer/components/resource-explorer-card/components/resource-explorer-metadata/components/live-usage/LiveFilteredTitle";
import { money } from "../../../../utils/numeral/money";
import { AverageCost } from "../../../pages/resource-explorer/components/resource-explorer-card/components/resource-explorer-metadata/components/AverageCost";
import { TotalCost } from "../../../pages/resource-explorer/components/resource-explorer-card/components/resource-explorer-metadata/components/TotalCost";

interface InternalResourceExplorerMetadataProps {
  viewData: ResourceExplorerWidget;
  view: ResourceExplorer;
}

export const InternalResourceExplorerMetadata: FC<
  InternalResourceExplorerMetadataProps
> = ({ viewData, view }) => {
  const fixedTotalCost = +viewData.total_cost.toFixed(2);
  const averageData = useMemo(
    () => getResourceExplorerWidgetAverageData(viewData, view.data.data_point),
    [viewData, view.data.data_point],
  );
  const totalCostTrend = useMemo(
    () =>
      generateResourceExplorerTotalCostTrend(
        viewData.total_cost,
        viewData?.previous_period_total_cost ?? 0,
      ),
    [viewData.total_cost, viewData?.previous_period_total_cost],
  );

  return (
    <Stack direction="row" justifyContent="space-between">
      <Stack
        direction="row"
        spacing={2}
        divider={<Divider orientation="vertical" flexItem />}
      >
        {fixedTotalCost && (
          <TotalCost
            totalCost={fixedTotalCost}
            totalCostTrend={totalCostTrend}
            totalCostDates={viewData.total_cost_date_details}
            dateLabel={view.data.date_label}
            costType={viewData.cost_type}
          />
        )}

        {averageData?.cost && (
          <AverageCost
            average={averageData?.cost}
            averageTrend={averageData?.trend}
            previousPeriodCostDates={viewData.previous_period_cost_date_details}
            dateLabel={view.data.date_label}
            dataPoint={view.data.data_point}
          />
        )}

        <InternalResourceExplorerBudget
          budget={view.budget}
          showBudgetLine={!!view.data.show_budget_line}
        />
      </Stack>

      <Stack direction="row" spacing={2}>
        <ResourceExplorerForecastedCosts
          forecastedCosts={viewData.forecasted_costs}
          selectedForecastOption={view.data.forecast_option}
        />

        <Stack
          bgcolor="grey.100"
          p={1}
          borderRadius={2}
          sx={{ position: "relative" }}
        >
          <LiveFilteredTitle tooltipTitle={tooltipTitle} />

          <Stack direction="row" alignItems="center">
            <Typography variant="h6" fontWeight="medium">
              {money(viewData.live_usage_total_cost)}
              {viewData.live_usage_total_cost !== undefined && (
                <Typography pl={0.25} variant="caption" color="text.secondary">
                  /mo
                </Typography>
              )}
            </Typography>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};

const tooltipTitle =
  "Total monthly price of live filtered resources found in Live Usage & Mgmt for the current view.";
