import { FC, useMemo } from "react";
import { useEffectOnceWhen } from "rooks";
import { ToggleBox } from "../../../../../../../common/toggle-box/ToggleBox";
import { SchedulesActionType } from "../../../../../../schedule/common/utils/types/types";
import { useAppSelector } from "../../../../../../../../store/hooks";
import { stateAccountsSelector } from "../../../../../../../../store/accounts/selectors/slice-data/stateAccountsSelector";

interface ActionsTypeSelectorProps {
  action?: SchedulesActionType;
  accountIds: string[];
  onChange(action: SchedulesActionType): void;
}

export const WorkflowActionsTypeSelector: FC<ActionsTypeSelectorProps> = ({
  action,
  onChange,
  accountIds,
}) => {
  const accounts = useAppSelector(stateAccountsSelector);

  const options = useMemo(() => {
    const selectedAccounts = accounts?.filter(({ id }) =>
      accountIds?.includes(id),
    );
    const allReadOnly = selectedAccounts?.every(
      ({ access_type }) => access_type === "read",
    );

    if (!accounts) {
      return null;
    }

    const noAccountTooltip = accountIds.length
      ? ""
      : "At least one account should be selected to enable actions.";

    const allReadOnlyTooltip = allReadOnly
      ? "Resources in your selected accounts cannot be modified, please switch to Read/Write access mode to enable actions."
      : "";

    return [
      {
        disabled: allReadOnly || !!noAccountTooltip,
        tooltipText: noAccountTooltip || allReadOnlyTooltip,
        value: "clean",
        label: "Take Actions",
        description: "Take actions, notify teams before and after actions.",
      },
      {
        disabled: !!noAccountTooltip,
        value: "notify",
        tooltipText: noAccountTooltip,
        label: "Notify Only",
        description: "Notify teams with detailed list of resources found.",
      },
    ];
  }, [accounts, accountIds]);

  useEffectOnceWhen(
    () => {
      onChange(options?.at(0)?.disabled ? "notify" : "clean");
    },
    !action && !!options && !!accountIds?.length,
  );

  if (!options) {
    return null;
  }

  return (
    <ToggleBox
      size="small"
      options={options}
      onChange={onChange}
      value={action?.includes("clean") ? "clean" : action}
    />
  );
};
