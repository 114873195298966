import { FC } from "react";
import { Stack } from "@mui/material";
import Typography from "@mui/material/Typography";
import { Table } from "@tanstack/react-table";
import NoFilteredDataImage from "../../assets/images/no-filtered-data.png";

interface NoFilteredDataProps {
  table: Table<any>;
}

export const NoFilteredData: FC<NoFilteredDataProps> = ({ table }) => {
  const rows = table.getRowModel().rows;

  if (rows.length) {
    return null;
  }

  return (
    <Stack pt={12} spacing={4} alignItems="center" justifyContent="center">
      <img src={NoFilteredDataImage} alt="NoFilteredData" width={140} />
      <Typography variant="h6" fontWeight="normal">
        No matching results found.
      </Typography>
    </Stack>
  );
};
