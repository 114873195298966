export const getWidgetsConfigurationsFeatureToggleFromStorage = () => {
  const response = sessionStorage.getItem(
    `C8R-EnableWidgetsSideBarConfigurations`,
  );

  if (!response) {
    return false;
  }

  return JSON.parse(response);
};
