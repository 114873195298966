import { FC, useCallback } from "react";
import Stack from "@mui/material/Stack";
import { FormControl } from "@mui/material";
import { RenderToolbarProps } from "../../../../../../storybook/data-grid/utils/types/prop-types";
import { useAppDispatch, useAppSelector } from "../../../../../../store/hooks";
import { GlobalFilter } from "../../../../../../storybook/data-grid/toolbar/GlobalFilter";
import { setDimensionsSearch } from "../../../../../../store/dimensions/dimensionsSlice";
import { dimensionsSearchSelector } from "../../../../../../store/dimensions/selectors/slice-data/DimensionsSearchSelector";

export const DimensionsDataGridToolbar: FC<RenderToolbarProps> = ({
  setGlobalFilter,
}) => {
  const dispatch = useAppDispatch();
  const search = useAppSelector(dimensionsSearchSelector);

  const onSearchChange = useCallback(
    (value: string) => {
      dispatch(setDimensionsSearch(value));
      setGlobalFilter(value);
    },
    [dispatch, setGlobalFilter],
  );

  return (
    <Stack
      direction="row"
      justifyContent="end"
      p={1}
      borderBottom={1}
      borderColor="grey.300"
    >
      <FormControl size="small" sx={{ minWidth: 200 }}>
        <GlobalFilter
          globalFilter={search ?? ""}
          setGlobalFilter={onSearchChange}
          placeholder="Search"
          size="small"
          fullWidth
        />
      </FormControl>
    </Stack>
  );
};
