import { Button, Link, Typography } from "@mui/material";
import OpenInNewOutlinedIcon from "@mui/icons-material/OpenInNewOutlined";
import { ColumnSetupType } from "../../../../../../../../storybook/data-grid/utils/types/types";
import { SavingsPlanUtilizationRecommendation } from "../../../../../../../../services/cloudchipr.api";
import { money } from "../../../../../../../../utils/numeral/money";
import { CommitmentsProgressBar } from "../../../../../common/components/CommitmentsProgressBar";
import { DatesCell } from "../../../../../common/components/data-grid/cells/DatesCell";

export const savingsPlanDrawerRecommendationsGridColumns: ColumnSetupType<SavingsPlanUtilizationRecommendation>[] =
  [
    {
      accessorKey: "current_hourly_commitment",
      header: "Current Cost",
      cell: (cell) => {
        return (
          <Typography variant="body2">
            {money(cell.getValue() as number)}{" "}
            <Typography
              variant="inherit"
              component="span"
              color="text.secondary"
            >
              /hour
            </Typography>
          </Typography>
        );
      },
    },
    {
      minSize: 170,
      accessorKey: "recommended_hourly_commitment",
      header: "Recommended Cost",
      cell: (cell) => {
        return (
          <Typography variant="body2">
            {money(cell.getValue() as number)}{" "}
            <Typography
              variant="inherit"
              component="span"
              color="text.secondary"
            >
              /hour
            </Typography>
          </Typography>
        );
      },
    },
    {
      minSize: 170,
      accessorKey: "current_utilization",
      header: "Current Utilization",
      cell: (cell) => (
        <CommitmentsProgressBar value={cell.getValue() as number} />
      ),
    },
    {
      minSize: 180,
      accessorKey: "estimated_utilization",
      header: "Estimated Utilization",
      cell: (cell) => (
        <CommitmentsProgressBar value={cell.getValue() as number} />
      ),
    },
    {
      minSize: 240,
      accessorKey: "savings_plan_end_date",
      header: "Current Commitment End Date",
      cell: (cell) => <DatesCell date={cell.getValue() as string} />,
    },
    {
      minSize: 220,
      accessorKey: "recommended_start_date",
      header: "Recommended Start Date",
      cell: (cell) => <DatesCell date={cell.getValue() as string} />,
    },
    {
      minSize: 220,
      accessorKey: "estimated_monthly_savings",
      header: () => {
        return (
          <Typography
            variant="body2"
            fontWeight="medium"
            align="right"
            width="100%"
          >
            Estimated Monthly Savings
          </Typography>
        );
      },
      cell: (cell) => {
        return (
          <Typography
            variant="body2"
            color="success.light"
            align="right"
            pr={4}
          >
            {money(cell.getValue() as number)}{" "}
          </Typography>
        );
      },
    },
    {
      size: 110,
      accessorKey: "link",
      header: "",
      enableSorting: false,
      meta: { sticky: "right" },
      cell: (cell) => {
        return (
          <Button
            component={Link}
            href={cell.getValue() as string}
            target="_blank"
            variant="outlined"
            endIcon={<OpenInNewOutlinedIcon />}
            size="small"
          >
            RENEW
          </Button>
        );
      },
    },
  ];
