import { createAsyncThunk } from "@reduxjs/toolkit";
import { getLiveUsageMgmtResourcesWithOutFiltersThunk } from "./resources/getLiveUsageMgmtResourcesWithOutFiltersThunk";
import { RootState } from "../../store";
import { ResourceType } from "../../../services/cloudchipr.api";
import { liveUsageMgmtFilterTemplatesEnabledSelector } from "../selectors/store-selectors/liveUsageMgmtFilterTemplatesEnabledSelector";
import { setLiveUsageMgmtFilterTemplatesEnabled } from "../liveUsageMgmtSlice";

interface LiveUsageMgmtFilterTemplateEnabledChangeThunkArgs {
  resourceTypes: ResourceType[];
  toggledResourceTypes?: Map<ResourceType, boolean>;
}

export const liveUsageMgmtFilterTemplateEnabledChangeThunk = createAsyncThunk(
  "liveUsageMgmt/liveUsageMgmtFilterTemplateEnabledChange",
  async (
    {
      resourceTypes,
      toggledResourceTypes,
    }: LiveUsageMgmtFilterTemplateEnabledChangeThunkArgs,
    { dispatch, getState },
  ) => {
    const state = getState() as RootState;
    const filterTemplatesEnabled =
      liveUsageMgmtFilterTemplatesEnabledSelector(state);
    const value = !filterTemplatesEnabled;
    dispatch(setLiveUsageMgmtFilterTemplatesEnabled(value));
    if (!value) {
      dispatch(getLiveUsageMgmtResourcesWithOutFiltersThunk());
    }
  },
);
