import { arrayMove } from "@dnd-kit/sortable";
import { UniqueIdentifier, Active, Over } from "@dnd-kit/core";

import { buildSortableTree } from "./buildSortableTree";
import { flattenSortableTreeItems } from "./flattenSortableTreeItems";
import { FlattenedItem, TreeItems } from "../types";

interface GenerateTreeItemsAfterDropArgs {
  over: Over | null;
  items?: TreeItems;
  projected: {
    depth: number;
    parentId: UniqueIdentifier | null;
  };
  active: Active;
}
export const generateTreeItemsAfterDrop = ({
  over,
  items,
  projected,
  active,
}: GenerateTreeItemsAfterDropArgs) => {
  if (!items) {
    return null;
  }
  const { depth, parentId } = projected;
  const clonedItems: FlattenedItem[] = JSON.parse(
    JSON.stringify(flattenSortableTreeItems(items ?? [])),
  );
  const overIndex = over?.data?.current?.sortable?.index;
  const activeIndex = active?.data?.current?.sortable?.index;

  const activeTreeItem = clonedItems[activeIndex];

  clonedItems[activeIndex] = { ...activeTreeItem, depth, parentId };

  const sortedItems = arrayMove(clonedItems, activeIndex, overIndex);
  return buildSortableTree(sortedItems);
};
