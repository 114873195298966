import { FC, useCallback, useMemo } from "react";
import { Button } from "@mui/material";
import RotateLeftIcon from "@mui/icons-material/RotateLeft";
import { useLocation, useNavigate } from "react-router-dom";
import { setLiveUsageMgmtAppliedFilters } from "../../../../../../store/live-usage-mgmt/liveUsageMgmtSlice";
import { getLiveUsageMgmtResourcesWithFiltersByProviderThunk } from "../../../../../../store/live-usage-mgmt/thunks/resources/getLiveUsageMgmtResourcesWithFiltersByProviderThunk";
import { setFilterChangedManually } from "../../../../../../store/filters/filtersSlice";
import { useAppDispatch, useAppSelector } from "../../../../../../store/hooks";
import { liveUsageMgmtAppliedFilterTemplateFiltersSelector } from "../../../../../../store/live-usage-mgmt/selectors/filter-template/liveUsageMgmtAppliedFilterTemplateFiltersSelector";
import { resourceIdsSearchParam } from "../../navigation/utils/constants";

interface FilterTemplateViewActionsRevertButtonProps {
  snackBarUi?: boolean;
  onNotify(): void;
}

export const FilterTemplateViewActionsRevertButton: FC<
  FilterTemplateViewActionsRevertButtonProps
> = ({ snackBarUi, onNotify }) => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = useMemo(() => {
    return new URLSearchParams(location.search);
  }, [location.search]);

  const appliedFilterTemplateFilters = useAppSelector(
    liveUsageMgmtAppliedFilterTemplateFiltersSelector,
  );

  const resetUrl = useCallback(() => {
    if (searchParams.get(resourceIdsSearchParam)) {
      searchParams.delete(resourceIdsSearchParam);

      navigate({
        pathname: location.pathname,
        search: searchParams.toString(),
      });
    }
  }, [navigate, searchParams, location]);

  const revertHandler = useCallback(
    (event: any) => {
      if (!appliedFilterTemplateFilters) {
        return;
      }

      appliedFilterTemplateFilters.forEach((resourceFilters) => {
        dispatch(setLiveUsageMgmtAppliedFilters(resourceFilters));
        dispatch(
          getLiveUsageMgmtResourcesWithFiltersByProviderThunk(
            resourceFilters.type,
          ),
        );
      });

      dispatch(setFilterChangedManually(false));
      onNotify();
      resetUrl();
      event.stopPropagation();
      event.preventDefault();
    },
    [dispatch, onNotify, resetUrl, appliedFilterTemplateFilters],
  );

  if (snackBarUi) {
    return (
      <Button size="small" color="tertiary" onClick={revertHandler}>
        Revert
      </Button>
    );
  }

  return (
    <Button
      size="xsmall"
      color="tertiary"
      onClick={revertHandler}
      sx={{ textTransform: "none" }}
      startIcon={<RotateLeftIcon sx={{ fontSize: 16 }} />}
    >
      Revert
    </Button>
  );
};
