import { CostAndUsageDataGridType } from "../../../../../../../components/pages/dashboard/components/adding-widget/widget-create/widget-setups/cost-and-usage/content/data-grid/utils/types";
import { CostAndUsageSumWidgetFilterType } from "../../../../../utils/constants/costAndUsageSetupDefaultData";

export interface GenerateCostAndUsageSumFilterTypeDataArgs {
  costAndUsageSumWidgetFilterType: CostAndUsageSumWidgetFilterType;
  label: string;
  cost: number | null;
  trend?: number | null;
  currentDatesFrom?: string;
  currentDatesTo?: string;
  previousDatesFrom?: string;
  previousDatesTo?: string;
}

export const generateCostAndUsageSumFilterTypeData = (
  args: GenerateCostAndUsageSumFilterTypeDataArgs,
): CostAndUsageDataGridType => {
  const {
    costAndUsageSumWidgetFilterType,
    label,
    cost,
    currentDatesFrom,
    currentDatesTo,
    previousDatesFrom,
    previousDatesTo,
  } = args;
  const trend = args.trend ?? null;

  return {
    id: costAndUsageSumWidgetFilterType,
    costAndUsageSumWidgetFilterType,
    label,
    cost,
    trendDetails:
      trend !== null
        ? {
            trend: trend ?? 0,
            currentDates: {
              from: currentDatesFrom,
              to: currentDatesTo,
            },
            previousDates: {
              from: previousDatesFrom,
              to: previousDatesTo,
            },
          }
        : null,
  };
};
