import { billingStatusByProviderSelector } from "./billingStatusByProviderSelector";
import { RootState } from "../../../store";
import { ProviderType } from "../../../../services/cloudchipr.api";
import { connectedProvidersSelector } from "../connectedProvidersSelector";

export const enabledProvidersByBillingStatusSelector = (
  state: RootState,
): ProviderType[] => {
  const connectedProviders = connectedProvidersSelector(state);

  return connectedProviders.reduce((result, item) => {
    const status = billingStatusByProviderSelector(state, item);
    if (status && !missingProviderStatuses.includes(status)) {
      result.push(item);
    }
    return result;
  }, [] as ProviderType[]);
};

export const missingProviderStatuses = [
  "missing_setup",
  "waiting_for_cloud_provider",
];
