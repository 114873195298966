import { ColDef } from "@ag-grid-community/core";
import { CustomCellRendererProps } from "@ag-grid-community/react";
import { ReportsNameCell } from "./cells/ReportsNameCell";
import { ReportsSourceCell } from "./cells/ReportsSourceCell";
import { ReportsFrequencyCell } from "./cells/ReportsFrequencyCell";
import { ReportsGridActionsCell } from "./cells/ReportsGridActionsCell";
import { ReportsTableData } from "../../../../../../store/reports/utils/types/types";
import { reportsScheduleSortingFn } from "../utils/helpers/sorting-functions/reportsScheduleSortingFn";
import { UserActivityCell } from "../../../../../common/data-grid-cells/UserActivityCell";
import { reportsCreatedBySortingFn } from "../utils/helpers/sorting-functions/reportsCreatedBySortingFn";
import { NotificationsCell } from "../../../../../common/data-grid-cells/NotificationsCell";
import { reportsModifiedBySortingFn } from "../utils/helpers/sorting-functions/reportsModifiedBySortingFn";
import { reportsNotificationsSortingFn } from "../utils/helpers/sorting-functions/reportsNotificationsSortingFn";
import { reportStatusSortingFn } from "../utils/helpers/sorting-functions/reportStatusSortingFn";
import { reportsSourceQuickFilter } from "../utils/helpers/quick-filters/reportsSourceQuickFilter";
import { reportsFrequencyQuickFilter } from "../utils/helpers/quick-filters/reportsFrequencyQuickFilter";
import { reportsLastModifiedQuickFilter } from "../utils/helpers/quick-filters/reportsLastModifiedQuickFilter";
import { reportsCreatedByQuickFilter } from "../utils/helpers/quick-filters/reportsCreatedByQuickFilter";
import { reportsNotificationsQuickFilter } from "../utils/helpers/quick-filters/reportsNotificationsQuickFilter";
import { formatDate } from "../../../../../../utils/helpers/date-time/datetime-format";
import { TypographyWithTooltip } from "../../../../../common/TypographyWithTooltip";
import { reportsLastExecutionSortingFn } from "../utils/helpers/sorting-functions/reportsLastExecutionSortingFn";
import { reportsLastExecutionQuickFilter } from "../utils/helpers/quick-filters/reportsLastExecutionQuickFilter";
import { reportsNextExecutionQuickFilter } from "../utils/helpers/quick-filters/reportsNextExecutionQuickFilter";
import { reportsNextExecutionSortingFn } from "../utils/helpers/sorting-functions/reportsNextExecutionSortingFn";

export const reportsTableColumns: ColDef[] = [
  {
    field: "name",
    flex: 1,
    minWidth: 100,
    maxWidth: 200,
    unSortIcon: true,
    suppressMovable: true,
    pinned: "left",
    cellStyle: { paddingLeft: 16 },
    suppressColumnsToolPanel: true,
    cellRenderer: ReportsNameCell,
  },
  {
    field: "sourceName",
    headerName: "Source",
    flex: 0.8,
    minWidth: 120,
    unSortIcon: true,
    getQuickFilterText: reportsSourceQuickFilter,
    cellRenderer: ({ data }: CustomCellRendererProps<ReportsTableData>) => {
      return (
        <ReportsSourceCell
          id={data?.source_id}
          sourceType={data?.source_type}
          sourceName={data?.sourceName}
        />
      );
    },
  },
  {
    field: "schedule",
    headerName: "Frequency",
    minWidth: 100,
    flex: 0.6,
    unSortIcon: true,
    getQuickFilterText: reportsFrequencyQuickFilter,
    comparator: reportsScheduleSortingFn,
    cellRenderer: ({ data }: CustomCellRendererProps<ReportsTableData>) => {
      return <ReportsFrequencyCell schedule={data?.schedule} />;
    },
  },
  {
    field: "last_execution_date",
    headerName: "Last Execution",
    flex: 0.8,
    minWidth: 120,
    unSortIcon: true,
    getQuickFilterText: reportsLastExecutionQuickFilter,
    comparator: reportsLastExecutionSortingFn,
    cellRenderer: ({ data }: CustomCellRendererProps<ReportsTableData>) => {
      if (!data?.last_execution_date) {
        return "-";
      }

      return (
        <TypographyWithTooltip
          variant="body2"
          title={formatDate(data.last_execution_date, {
            type: "dateTimeWithComma",
          })}
        />
      );
    },
  },
  {
    field: "next_execution_date",
    headerName: "Next Execution",
    flex: 0.8,
    minWidth: 120,
    unSortIcon: true,
    getQuickFilterText: reportsNextExecutionQuickFilter,
    comparator: reportsNextExecutionSortingFn,
    cellRenderer: ({ data }: CustomCellRendererProps<ReportsTableData>) => {
      if (!data?.next_execution_date) {
        return "-";
      }

      return (
        <TypographyWithTooltip
          variant="body2"
          title={formatDate(data.next_execution_date, {
            type: "dateTimeWithComma",
          })}
        />
      );
    },
  },
  {
    field: "created_by",
    headerName: "Created by",
    minWidth: 130,
    flex: 0.8,
    unSortIcon: true,
    getQuickFilterText: reportsCreatedByQuickFilter,
    comparator: reportsCreatedBySortingFn,
    cellRenderer: ({ data }: CustomCellRendererProps<ReportsTableData>) => {
      const details = data?.created_by;
      const date = data?.created_at;

      return (
        <UserActivityCell
          date={date}
          name={details?.name ?? details?.email ?? ""}
          deleted={details?.name?.includes("deleted")}
        />
      );
    },
  },
  {
    field: "modified_by",
    headerName: "Last Modified By",
    minWidth: 150,
    flex: 0.8,
    unSortIcon: true,
    getQuickFilterText: reportsLastModifiedQuickFilter,
    comparator: reportsModifiedBySortingFn,
    cellRenderer: ({ data }: CustomCellRendererProps<ReportsTableData>) => {
      const details = data?.modified_by;
      const date = data?.modified_at;

      if (!date) {
        return "-";
      }

      return (
        <UserActivityCell
          date={date}
          name={details?.name ?? details?.email ?? ""}
          deleted={details?.name?.includes("deleted")}
        />
      );
    },
  },
  {
    field: "integrations",
    headerName: "Notification Methods",
    minWidth: 200,
    flex: 1,
    unSortIcon: true,
    getQuickFilterText: reportsNotificationsQuickFilter,
    comparator: reportsNotificationsSortingFn,
    cellRenderer: ({ data }: CustomCellRendererProps<ReportsTableData>) => {
      return (
        <NotificationsCell
          integrations={data?.integrations ?? []}
          emails={data?.emails}
        />
      );
    },
  },

  {
    // column to fit rest place
    flex: 0.1,
    colId: "toFitEmptySpace",
    headerName: "",
    sortable: false,
    cellDataType: false,
    resizable: false,
    suppressMovable: true,
    suppressColumnsToolPanel: true,
    suppressHeaderMenuButton: true,
    suppressHeaderContextMenu: true,
  },

  {
    colId: "actions",
    maxWidth: 150,
    width: 150,
    minWidth: 150,
    resizable: false,
    unSortIcon: true,
    suppressMovable: true,
    pinned: "right",
    headerName: "Enabled",
    comparator: reportStatusSortingFn,
    suppressColumnsToolPanel: true,
    cellRenderer: ReportsGridActionsCell,
  },
];
