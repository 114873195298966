import { reservationsRecommendationsSelector } from "./reservationsRecommendationsSelector";
import { RootState } from "../../../../store";
import { ReservationRecommendation } from "../../../../../services/cloudchipr.api";

export const reservationsRecommendationsDataSelector = (
  state: RootState,
): ReservationRecommendation[] | undefined => {
  const response = reservationsRecommendationsSelector(state);

  return response?.data;
};
