import { FC, useCallback } from "react";
import { Box, Fade, Slide, Card, CardActions } from "@mui/material";
import { useCounter } from "rooks";
import { SwitchTransition } from "react-transition-group";
import { FeatureCardContent } from "./components/FeatureCardContent";
import { FeaturePagination } from "./components/FeaturePagination";
import { features } from "./utils/constants/features";
import {
  usePostUsersMeFeaturesMutation,
  useGetUsersMeFeaturesQuery,
} from "../../services/cloudchipr.api";
import { useAppAbility } from "../../services/permissions";

export const FeatureUpdates: FC = () => {
  const { can } = useAppAbility();
  const featuresAvailable = can("view", "account");

  const { data, refetch } = useGetUsersMeFeaturesQuery(undefined, {
    skip: !featuresAvailable,
  });
  const { value, increment, decrement } = useCounter(0);
  const [trigger, { isLoading }] = usePostUsersMeFeaturesMutation();

  const handleMarkSeenClick = useCallback(async () => {
    const feature_keys = data?.map((feature) => feature.feature_key);
    await trigger({ body: { feature_keys } });
    await refetch();
  }, [trigger, refetch, data]);

  if (!data?.length) {
    return null;
  }

  const featureDetails = features[data[value]?.feature_key];

  if (!featureDetails) {
    return null;
  }

  return (
    <Box width={350} position="fixed" right={50} bottom={50} zIndex="appBar">
      <Slide in exit direction="up" unmountOnExit mountOnEnter>
        <Card elevation={6}>
          <SwitchTransition mode="out-in">
            <Fade key={data[value]?.feature_key}>
              <div>
                <FeatureCardContent {...featureDetails} />
              </div>
            </Fade>
          </SwitchTransition>
          <CardActions sx={{ justifyContent: "space-between" }}>
            <FeaturePagination
              maxCount={data.length}
              activeStep={value}
              onClickNext={increment}
              onClickBack={decrement}
              loading={isLoading}
              handleMarkSeenClick={handleMarkSeenClick}
            />
          </CardActions>
        </Card>
      </Slide>
    </Box>
  );
};
