import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useCallback } from "react";
import { accountIdsSearchParam, resourceTypeSearchParams } from "../constants";
import { ResourceType } from "../../../../../../../services/cloudchipr.api";

export const useLiveUsageMgmtPageNavigate = (resourceType: ResourceType) => {
  const navigate = useNavigate();
  const location = useLocation();
  const pathname = location.pathname;
  const [searchParams] = useSearchParams();
  const accountIds = searchParams.get(accountIdsSearchParam);

  return useCallback(() => {
    navigate({
      pathname,
      search: `?${accountIdsSearchParam}=${accountIds}&${resourceTypeSearchParams}=${resourceType}`,
    });
  }, [navigate, pathname, resourceType, accountIds]);
};
