import { DetailedHTMLProps, FC, VideoHTMLAttributes } from "react";

type VideoWithoutCaptionsProps = DetailedHTMLProps<
  VideoHTMLAttributes<HTMLVideoElement>,
  HTMLVideoElement
>;

export const VideoWithoutCaptions: FC<VideoWithoutCaptionsProps> = ({
  ...props
}) => {
  return (
    <video {...props}>
      <track
        label="Captions"
        kind="captions"
        src="../../assets/captions/without-captions.vtt"
      />
      <source src={props.src} type="video/mp4" />
    </video>
  );
};
