import { createAsyncThunk } from "@reduxjs/toolkit";
import { cloudChiprApi } from "../../../../services/cloudchipr.api";

export const getResourceProtectionResourcesThunk = createAsyncThunk(
  "schedules/getResourceProtectionResourcesThunk",
  async (
    {
      scheduleId,
      batchId,
    }: {
      scheduleId?: string;
      batchId?: string;
    },
    { dispatch },
  ) => {
    const { getUsersMeWorkflowsByWorkflowIdBatchesAndBatchIdResources } =
      cloudChiprApi.endpoints;

    if (!scheduleId || !batchId) {
      return;
    }

    return await dispatch(
      getUsersMeWorkflowsByWorkflowIdBatchesAndBatchIdResources.initiate(
        {
          workflowId: scheduleId,
          batchId,
        },
        { forceRefetch: true },
      ),
    ).unwrap();
  },
);
