import { Bar } from "recharts";
import { ChartColorsType } from "../../../utils/types/types";

export const barChartBars = ({
  keys,
  stack,
  colors,
  highlighted,
  onMouseEnter,
  onMouseLeave,
}: {
  keys: string[];
  highlighted: string;
  onMouseEnter: (payload: any) => void;
  onMouseLeave(): void;
  colors: ChartColorsType;
  stack?: boolean;
}) => {
  const specificProps = stack
    ? { stackId: "stacked_chart_bar_id" }
    : { minPointSize: 3 };

  return keys.map((key) => {
    const filter = highlighted === key ? "none" : "grayscale(1)";
    const opacity = highlighted === key ? 1 : 0.2;

    return (
      <Bar
        key={key}
        unit={key}
        dataKey={key}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        filter={highlighted ? filter : "none"}
        opacity={highlighted ? opacity : "none"}
        fill={colors[key]}
        {...specificProps}
      />
    );
  });
};
