import { SortingFn } from "@tanstack/react-table";

export const sortWithConvertingToNumber: SortingFn<any> = (
  currentRow,
  nextRow,
  columnId,
) => {
  const a = currentRow.getValue(columnId) as string;
  const b = nextRow.getValue(columnId) as string;

  return parseFloat(a) - parseFloat(b);
};
