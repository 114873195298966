import { FC, memo, useCallback, useEffect, useMemo } from "react";
import { FormikHelpers } from "formik";
import {
  FilterItem,
  ResourceType,
  usePostUsersMeOrganisationsCurrentByTypeResourcesTagKeysMutation,
  usePostUsersMeOrganisationsCurrentByTypeResourcesTagValuesMutation,
} from "../../../../../../../services/cloudchipr.api";
import { AutocompleteSelect } from "../../../../../../common/select/autocomplete-select/AutocompleteSelect";
import { tagFilterKeysFixedCacheKeyGenerator } from "../../utils/helpers/tagFilterKeysFixedCacheKeyGenerator";
import { TypographyWithTooltip } from "../../../../../../common/TypographyWithTooltip";
import { multipleValueSelectOperators } from "../../../utils/constants/filter-types/autocomplete-multipe-selection-operator-types";

interface TagFilterValuesProps {
  error?: boolean;
  operator: string;
  accountIds?: string[];
  resourceType: ResourceType;
  tagKey: string;
  disabled?: boolean;
  value: string[];
  setFieldValue: FormikHelpers<FilterItem>["setFieldValue"];
}

export const TagFilterValues: FC<TagFilterValuesProps> = memo(
  ({
    setFieldValue,
    disabled,
    value,
    tagKey,
    resourceType,
    accountIds,
    error,
    operator,
  }) => {
    const singleSelect = !multipleValueSelectOperators.includes(operator);

    const [getValues, { data, isLoading }] =
      usePostUsersMeOrganisationsCurrentByTypeResourcesTagValuesMutation();

    const [, keysData] =
      usePostUsersMeOrganisationsCurrentByTypeResourcesTagKeysMutation({
        fixedCacheKey: tagFilterKeysFixedCacheKeyGenerator(resourceType),
      });

    const autoCompleteSelectChangeHandler = useCallback(
      (selectedVaule: any) => {
        const data = singleSelect ? selectedVaule[0] : selectedVaule;
        const value = data?.length ? data : null;

        setFieldValue("value.value", value);
      },
      [singleSelect, setFieldValue],
    );

    const values = useMemo(() => {
      const stringValue = value && typeof value === "string" ? [value] : [];
      return Array.isArray(value) ? value : stringValue;
    }, [value]);

    const options = useMemo(() => {
      return data?.map((item) => {
        return {
          label: <TypographyWithTooltip title={item} />,
          value: item,
        };
      });
    }, [data]);

    const renderOption = useCallback((option: any) => {
      return <TypographyWithTooltip title={option.value} />;
    }, []);

    useEffect(() => {
      if (!tagKey) {
        return;
      }

      getValues({
        type: resourceType,
        body: {
          account_ids: accountIds ?? [],
          key: tagKey,
        },
      });
    }, [tagKey, accountIds, resourceType, getValues]);

    return (
      <AutocompleteSelect
        size="xsmall"
        freeSolo
        creatable
        singleSelect={singleSelect}
        label="Value"
        placeholder="Value"
        options={options ?? []}
        error={error}
        initialSelectedValues={data ? values : undefined}
        onChange={autoCompleteSelectChangeHandler}
        disabled={disabled}
        values={values ?? []}
        optionsLoading={isLoading || keysData?.isLoading}
        renderOption={renderOption}
      />
    );
  },
);
