import { FC, Fragment, useCallback, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  ListItemIcon,
  ListItemText,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { LoadingButton } from "@mui/lab";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../store/hooks";
import { useAppAbility } from "../../../../../../../../services/permissions";
import { WidgetType } from "../../../../../../../../services/cloudchipr.api";
import { createWidgetLoadingSelector } from "../../../../../../../../store/dashboards/selectors/widgets/common/createWidgetLoadingSelector";
import { widgetDataNotFoundSelector } from "../../../../../../../../store/dashboards/selectors/widgets/common/widgetDataNotFoundSelector";
import { useDialog } from "../../../../../../../../utils/hooks/useDialog.hook";
import { DialogTitleClosable } from "../../../../../../../common/dialog-utils/DialogTitleClosable";
import { getDashboardByIdLoadingSelector } from "../../../../../../../../store/dashboards/selectors/dashboard/loadings/getDashboardByIdLoadingSelector";
import { currentDashboardIdSelector } from "../../../../../../../../store/dashboards/selectors/dashboard/currentDashboardIdSelector";
import { duplicateWidgetThunk } from "../../../../../../../../store/dashboards/thunks/widgets/common/duplicateWidgetThunk";
import { getPressEnterHandler } from "../../../../../../../utils/helpers/getPressEnterHandler";

interface WidgetEditActionMenuItemProps {
  widgetId: string;
  widgetName: string;
  widgetType: WidgetType;
  onClose(): void;
}

export const WidgetDuplicateActionMenuItem: FC<
  WidgetEditActionMenuItemProps
> = ({ widgetType, widgetId, onClose, widgetName }) => {
  const dispatch = useAppDispatch();
  const { cannot } = useAppAbility();
  const cannotCreateWidget = cannot("create", "dashboard");

  const { open, openDialog, closeDialog } = useDialog();
  const [value, setValue] = useState(`${widgetName} (copy)`);

  const dashboardId = useAppSelector(currentDashboardIdSelector);
  const creating = useAppSelector(createWidgetLoadingSelector);
  const getLoading = useAppSelector((state) =>
    getDashboardByIdLoadingSelector(state, dashboardId ?? ""),
  );
  const widgetIsNotFound = useAppSelector((state) =>
    widgetDataNotFoundSelector(state, widgetId, widgetType),
  );

  const loading = creating || getLoading;

  const duplicateWidgetHandler = useCallback(async () => {
    await dispatch(
      duplicateWidgetThunk({
        widgetId,
        widgetType,
        widgetName: value,
      }),
    );

    onClose();
  }, [dispatch, value, onClose, widgetId, widgetType]);

  const inputChangeHandler = useCallback((e: any) => {
    setValue(e.target.value);
  }, []);

  const contentKeyDownHandler = useCallback(
    (e: any) => e.stopPropagation(),
    [],
  );

  const handlePressEnter = getPressEnterHandler(
    duplicateWidgetHandler,
    loading,
  );

  return (
    <Fragment>
      <MenuItem
        onClick={openDialog}
        disabled={cannotCreateWidget || loading || widgetIsNotFound}
      >
        <ListItemIcon>
          <ContentCopyIcon sx={{ fontSize: 16 }} />
        </ListItemIcon>
        <ListItemText>
          <Typography variant="body2">Duplicate</Typography>
        </ListItemText>
      </MenuItem>

      <Dialog
        open={open}
        fullWidth
        onClose={closeDialog}
        onKeyDown={handlePressEnter}
      >
        <DialogTitleClosable
          title="Duplicate widget"
          onClose={closeDialog}
          TitleProps={{ sx: { p: 2 } }}
        />

        <DialogContent dividers sx={{ p: 2 }} onKeyDown={contentKeyDownHandler}>
          <Typography variant="body2" mb={1}>
            Widget Title
          </Typography>
          <TextField
            autoFocus
            fullWidth
            type="text"
            value={value}
            size="small"
            variant="outlined"
            onChange={inputChangeHandler}
          />
        </DialogContent>

        <DialogActions sx={{ p: 2 }}>
          <Button color="tertiary" onClick={closeDialog}>
            Cancel
          </Button>
          <LoadingButton
            onClick={duplicateWidgetHandler}
            loading={loading}
            variant="contained"
          >
            Duplicate
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};
