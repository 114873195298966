import { FC } from "react";
import { CardHeader, IconButton, Stack } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { LiveUsageMgmtResourceCardHeaderTitle } from "./LiveUsageMgmtResourceCardHeaderTitle";
import { LiveUsageMgmtResourceCardHeaderFullScreenButton } from "./LiveUsageMgmtResourceCardHeaderFullScreenButton";
import { useAppSelector } from "../../../../../../store/hooks";
import { ResourceType } from "../../../../../../services/cloudchipr.api";
import { money } from "../../../../../../utils/numeral/money";
import { liveUsageMgmtResourceTypeDataEmsSelector } from "../../../../../../store/live-usage-mgmt/selectors/resource-type-data/liveUsageMgmtResourceTypeDataEmsSelector";
import { liveUsageMgmtResourceTypeDataSpendingSelector } from "../../../../../../store/live-usage-mgmt/selectors/resource-type-data/liveUsageMgmtResourceTypeDataSpendingSelector";
import { LiveUsageMgmtResourceStatistic } from "../../common/LiveUsageMgmtResourceStatistic";

interface ResourceCardHeaderProps {
  resourceType: ResourceType;
  collapsed: boolean;
  onCollapse(): void;
  onFullScreen(): void;
}

export const LiveUsageMgmtResourceCardHeader: FC<ResourceCardHeaderProps> = ({
  resourceType,
  onCollapse,
  onFullScreen,
  collapsed,
}) => {
  const ems = useAppSelector((state) =>
    liveUsageMgmtResourceTypeDataEmsSelector(state, resourceType),
  );

  const spending = useAppSelector((state) =>
    liveUsageMgmtResourceTypeDataSpendingSelector(state, resourceType),
  );

  return (
    <CardHeader
      title={
        <LiveUsageMgmtResourceCardHeaderTitle resourceType={resourceType} />
      }
      sx={cardHeaderSx}
      action={
        collapsed ? (
          <Stack direction="row" spacing={1} pr={1}>
            <LiveUsageMgmtResourceStatistic
              type="filtered"
              value={money(ems)}
            />
            <LiveUsageMgmtResourceStatistic
              type="all"
              value={money(spending)}
            />
          </Stack>
        ) : (
          <LiveUsageMgmtResourceCardHeaderFullScreenButton
            enter
            onClick={onFullScreen}
          />
        )
      }
      avatar={
        <IconButton
          onClick={onCollapse}
          color="primary"
          size="small"
          sx={{
            transform: collapsed ? "rotate(0deg)" : "rotate(180deg)",
            transition: "transform 0.3s",
          }}
        >
          <ExpandMoreIcon />
        </IconButton>
      }
    />
  );
};

const cardHeaderSx = {
  p: 1,
  pr: 1.5,
  bgcolor: "primary.light",
  ".MuiCardHeader-action": {
    minWidth: 34,
    alignSelf: "center",
  },
  ".MuiCardHeader-avatar": { m: 0, mr: 0.5 },
};
