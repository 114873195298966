import { grey } from "@mui/material/colors";

export const getColorByValue = (value?: number | null) => {
  if (!value && value !== 0) {
    return grey[500];
  }

  if (value < 69) {
    return "error.light";
  }

  if (value < 89) {
    return "warning.main";
  }

  return "success.light";
};
