import { FC, Fragment, useCallback } from "react";
import {
  ListItemIcon,
  ListItemText,
  MenuItem,
  Typography,
} from "@mui/material";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../store/hooks";
import { useAppAbility } from "../../../../../../../../services/permissions";
import { useDialog } from "../../../../../../../../utils/hooks/useDialog.hook";
import { DeleteConfirmationDialog } from "../../../../../../../common/modals/DeleteConfirmationDialog";
import { reportByIdSelector } from "../../../../../../../../store/reports/selector/reportByIdSelector";
import { deleteReportThunk } from "../../../../../../../../store/reports/thunks/deleteReportThunk";
import { deleteReportLoadingSelector } from "../../../../../../../../store/reports/selector/deleteReportLoadingSelector";
import { getAllReportsThunk } from "../../../../../../../../store/reports/thunks/getAllReportsThunk";

interface ReportDeleteActionProps {
  reportId: string;
  closeMenu(): void;
}

export const ReportDeleteAction: FC<ReportDeleteActionProps> = ({
  reportId,
  closeMenu,
}) => {
  const dispatch = useAppDispatch();
  const { cannot } = useAppAbility();
  const cannotEdit = cannot("delete", "report");

  const reportData = useAppSelector((state) =>
    reportByIdSelector(state, reportId),
  );
  const { open, openDialog, closeDialog } = useDialog();

  const loading = useAppSelector(deleteReportLoadingSelector);

  const handleDelete = useCallback(async () => {
    await dispatch(deleteReportThunk(reportId));
    closeMenu();
    await dispatch(getAllReportsThunk());
  }, [dispatch, reportId, closeMenu]);

  return (
    <Fragment>
      <MenuItem dense onClick={openDialog} disabled={cannotEdit}>
        <ListItemIcon>
          <DeleteOutlineIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText color="error">Delete</ListItemText>
      </MenuItem>

      <DeleteConfirmationDialog
        open={open}
        onClose={closeDialog}
        onDelete={handleDelete}
        loading={loading}
        title="Delete Subscription?"
        content={
          <Typography>
            Please confirm if you want to delete the
            <Typography
              component="span"
              fontWeight="bold"
              whiteSpace="break-spaces"
              sx={{ wordBreak: "break-word" }}
            >
              {" "}
              {reportData?.name}{" "}
            </Typography>
            subscription
          </Typography>
        }
      />
    </Fragment>
  );
};
