import { FC, useCallback, useMemo, useState } from "react";
import {
  Box,
  IconButton,
  ClickAwayListener,
  Popper,
  Tooltip,
  Collapse,
  useTheme,
} from "@mui/material";
import { NavLink, useMatch, useResolvedPath } from "react-router-dom";
import { NavItemExpanded } from "./NavItemExpanded";
import { NavItemProps } from "../NavItem";
import { NavigationOverlayContext } from "../utils/useNavigationOverlay.context";
import { useMenuHook } from "../../../../../utils/hooks/useMenu.hook";
import { useAppSelector } from "../../../../../store/hooks";
import { isSubscriptionDisabledSelector } from "../../../../../store/subscriptions/subscriptionsSelectors";

interface NavItemCollapsedProps extends NavItemProps {}

export const NavItemCollapsed: FC<NavItemCollapsedProps> = ({
  primary,
  icon: Icon,
  selected,
  children,
  to,
  onButtonClick,
  disabled,
  tooltipTitle,
  ...rest
}) => {
  const { open, anchor, openMenu, closeMenu } = useMenuHook();
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const {
    zIndex: { drawer },
  } = useTheme();

  const isSubscriptionDisabled = useAppSelector(isSubscriptionDisabledSelector);

  disabled = disabled || isSubscriptionDisabled;

  const resolvedPath = useResolvedPath(to || "");
  const match = useMatch({ path: resolvedPath.pathname });

  selected = (resolvedPath.pathname !== "/" && !!match) || selected;

  const handleIconButtonClick = useCallback(
    (e: any) => {
      setTooltipOpen(false);
      if (children?.length) {
        open ? closeMenu() : openMenu(e);
      } else {
        onButtonClick?.(e);
      }
    },
    [openMenu, closeMenu, open, children?.length, onButtonClick],
  );

  const handleTooltipOpen = useCallback(() => {
    !open && setTooltipOpen(true);
  }, [open]);

  const handleTooltipClose = useCallback(() => {
    setTooltipOpen(false);
  }, []);

  const buttonProps = to
    ? {
        to,
        component: NavLink,
      }
    : {
        onClick: handleIconButtonClick,
      };

  const navigationContextValue = useMemo(() => {
    return { onNavigate: closeMenu };
  }, [closeMenu]);

  return (
    <ClickAwayListener onClickAway={closeMenu}>
      <Tooltip
        arrow
        placement="right"
        open={tooltipOpen}
        onOpen={handleTooltipOpen}
        onClose={handleTooltipClose}
        title={tooltipTitle ?? primary}
        slotProps={tooltipSlotProps}
      >
        <Box textAlign="center" my={0.5}>
          <IconButton
            {...buttonProps}
            sx={{
              borderRadius: 2,
              bgcolor: selected ? "primary.light" : "default",
              opacity: disabled ? 0.4 : undefined,
            }}
            disabled={disabled}
          >
            {Icon ? (
              <Icon fontSize="small" color={selected ? "primary" : undefined} />
            ) : null}
          </IconButton>
          <Popper
            open={open}
            anchorEl={anchor}
            sx={{ transform: "unset !important", zIndex: drawer }}
          >
            <Box
              position="fixed"
              left={53}
              top={0}
              height="100vh"
              width={340}
              bgcolor="grey.50"
              boxShadow="8px 0px 16px 0px rgba(0, 0, 0, 0.1)"
              pt={1.25}
              overflow="auto"
            >
              <NavItemExpanded
                primary={primary}
                icon={Icon}
                expandable={!!children?.length}
                {...rest}
              />
              <NavigationOverlayContext.Provider value={navigationContextValue}>
                <Collapse in>{children}</Collapse>
              </NavigationOverlayContext.Provider>
            </Box>
          </Popper>
        </Box>
      </Tooltip>
    </ClickAwayListener>
  );
};

const tooltipSlotProps = {
  popper: {
    modifiers: [
      {
        name: "offset",
        options: {
          offset: [0, -12],
        },
      },
    ],
  },
};
