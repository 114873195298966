import { RootState } from "../../../../store";
import { cloudChiprApi } from "../../../../../services/cloudchipr.api";
import { dimensionDeleteFixedCacheKey } from "../../../thunks/dimensions/deleteDimensionThunk";

const dimensionDeleteSelector =
  cloudChiprApi.endpoints.deleteUsersMeOrganisationsCurrentDimensionsByDimensionId.select(
    dimensionDeleteFixedCacheKey,
  );

export const dimensionDeleteLoadingSelector = (state: RootState) => {
  return !!dimensionDeleteSelector(state)?.isLoading;
};
