import { FC } from "react";
import Typography from "@mui/material/Typography";
import { SxProps } from "@mui/system";
import { Theme, Tooltip } from "@mui/material";
import Stack from "@mui/material/Stack";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

interface InfoCardProps {
  value?: number | string;
  title: string;
  titleSx?: SxProps<Theme>;
  valueSx?: SxProps<Theme>;
  tooltip?: string;
}

export const InfoCard: FC<InfoCardProps> = ({
  value,
  title,
  titleSx,
  valueSx,
  tooltip,
}) => {
  return (
    <div>
      <Stack direction="row" gap={1} alignItems="center">
        <Typography
          variant="subtitle2"
          color="text.secondary"
          fontWeight="regular"
          whiteSpace="nowrap"
          sx={{ ...titleSx }}
        >
          {title ?? "-"}
        </Typography>

        {tooltip && (
          <Tooltip arrow placement="top" title={tooltip}>
            <InfoOutlinedIcon color="action" sx={{ fontSize: 16 }} />
          </Tooltip>
        )}
      </Stack>
      <Typography
        color="text.primary"
        variant="h6"
        whiteSpace="nowrap"
        sx={{ ...valueSx }}
      >
        {value ?? "-"}
      </Typography>
    </div>
  );
};
