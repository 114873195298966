import { FC, Fragment, ReactNode } from "react";
import { LinearProgress } from "@mui/material";
import { UserInvitationWrapper } from "./common/user-invitation/UserInvitationWrapper";
import { useAppSelector } from "../store/hooks";
import { currentUserSelector } from "../store/profile/profileSelectors";
import { useAppAbility } from "../services/permissions";

export const UserPermissionAbilities: FC<{ children?: ReactNode }> = ({
  children,
}) => {
  const user = useAppSelector(currentUserSelector);

  const { can } = useAppAbility();
  const canViewInvitationRequest = can("view", "invitation-request");
  const appLoading = useAppSelector((state) => state.common.appLoading);

  if (user && canViewInvitationRequest) {
    return <UserInvitationWrapper status={user?.status} />;
  }

  if (appLoading) {
    return <LinearProgress />;
  }
  return <Fragment>{children}</Fragment>;
};
