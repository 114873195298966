import { AccountStatus } from "../../services/cloudchipr.api";

export const nonWorkingAccountStatuses = new Set<AccountStatus>([
  "deactivated",
  "connected_for_billing",
  "inactive",
  "deleting",
]);

export const nonDetachableAccountStatuses = new Set<AccountStatus>([
  "connected_for_billing",
  "deleting",
]);

export const activeAccountStatuses = new Set<AccountStatus>([
  "connected",
  "initial_pulling",
  "deleting",
]);

export const loadingAccountStatuses = new Set<AccountStatus>([
  "initial_pulling",
]);
