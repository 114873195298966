import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  OffHoursSchedule,
  ResourceType,
  Schedule,
} from "../../services/cloudchipr.api";

type PreviewSelectedResources = Record<ResourceType, Record<string, boolean>>;

interface InitialStateInterface {
  offHours: OffHoursSchedule | null;
  workflow: Schedule | null;
  previewSelectedResources: PreviewSelectedResources;
  workflowCreationDrawerOpen: boolean;
}

const initialState: InitialStateInterface = {
  offHours: null,
  workflow: null,
  workflowCreationDrawerOpen: false,
  previewSelectedResources: {} as PreviewSelectedResources,
};

export const schedulesSlice = createSlice({
  name: "schedules",
  initialState,
  reducers: {
    initiateWorkflow: (state, action: PayloadAction<Schedule>) => {
      /* eslint-disable */
      // @ts-ignore
      state.workflow = action.payload;
      /* eslint-enable */
    },
    setWorkflowCreationDrawerOpen: (state, action: PayloadAction<boolean>) => {
      state.workflowCreationDrawerOpen = action.payload;
    },
    setPreviewSelectedResources: (
      state,
      action: PayloadAction<{
        resourceType: ResourceType;
        resources: Record<string, boolean>;
      }>,
    ) => {
      const { resourceType, resources } = action.payload;

      state.previewSelectedResources[resourceType] = resources;
    },
    removePreviewSelectedResources: (
      state,
      action: PayloadAction<ResourceType>,
    ) => {
      const resourceType = action.payload;

      const types = Object.keys(
        state.previewSelectedResources,
      ) as ResourceType[];

      state.previewSelectedResources = types.reduce((acc, type) => {
        if (type !== resourceType) {
          acc[type] = state.previewSelectedResources[type];
        }

        return acc;
      }, {} as PreviewSelectedResources);
    },

    resetSchedulesState: () => initialState,
  },
});

export const {
  initiateWorkflow,
  resetSchedulesState,
  setWorkflowCreationDrawerOpen,
  setPreviewSelectedResources,
  removePreviewSelectedResources,
} = schedulesSlice.actions;

export default schedulesSlice.reducer;
