import { FC, useCallback } from "react";
import { useDebounce } from "rooks";
import { FormHelperText, Stack, TextField, Typography } from "@mui/material";
import { setWorkflowSpecificData } from "../../../../../../../store/automations/automationsSlice";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../store/hooks";
import { workflowSchedulerSelector } from "../../../../../../../store/automations/selectros/workflow/workflowSchedulerSelector";
import { automationFrequencySelector } from "../../../../../../../store/automations/selectros/common/automationFrequencySelector";
import { AutomationFrequencyType } from "../../../../../../../store/automations/utils/types/common";
import { fetchWorkflowNextRunsThunk } from "../../../../../../../store/automations/thunks/wokrflows/fetchWorkflowNextRunsThunk";

interface WorkflowIntervalProps {
  error?: string | boolean;
}

export const WorkflowInterval: FC<WorkflowIntervalProps> = ({ error }) => {
  const dispatch = useAppDispatch();
  const frequency = useAppSelector(automationFrequencySelector);
  const scheduler = useAppSelector(workflowSchedulerSelector);

  const maxValue = frequencyMaxValues[frequency];
  const value = scheduler?.repeatValue;

  const fetchNextRunHandler = useCallback(() => {
    dispatch(fetchWorkflowNextRunsThunk());
  }, [dispatch]);
  const fetchDebounced = useDebounce(fetchNextRunHandler, 500);

  const changeHandler = useCallback(
    (event: any) => {
      const value = Number(event.target.value);

      if ((maxValue && value > maxValue) || value < 0) {
        return;
      }

      dispatch(
        setWorkflowSpecificData({
          scheduler: { ...scheduler, repeatValue: value },
        }),
      );

      if (value > 0) {
        fetchDebounced();
      }
    },
    [dispatch, maxValue, scheduler, fetchDebounced],
  );

  const blurHandler = useCallback(() => {
    if ((value ?? 0) < 1) {
      dispatch(
        setWorkflowSpecificData({
          scheduler: { ...scheduler, repeatValue: 1 },
        }),
      );
    }
  }, [dispatch, scheduler, value]);

  return (
    <Stack direction="row" spacing={0.5} alignItems="baseline">
      <Typography variant="body2" color="text.secondary">
        Every
      </Typography>

      <Stack>
        <TextField
          type="number"
          size="small"
          error={!!error}
          sx={{ width: 100 }}
          value={value || ""}
          onBlur={blurHandler}
          onChange={changeHandler}
          InputProps={{ inputProps: { min: 1, max: maxValue } }}
        />
        {error && <FormHelperText error>{error}</FormHelperText>}
      </Stack>

      <Typography variant="body2" color="text.secondary">
        {frequencyLabels[frequency]}
        {!!(value && value > 1) && "s"}
      </Typography>
    </Stack>
  );
};

const frequencyLabels: Record<AutomationFrequencyType, string> = {
  hourly: "hour",
  daily: "day",
  weekly: "week",
  once: "once",
};

const frequencyMaxValues: Record<AutomationFrequencyType, number> = {
  hourly: 23,
  daily: 31,
  weekly: 1000,
  once: 0,
};
