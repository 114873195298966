import { FC, Fragment } from "react";
import { Alert, Link, List, ListItem, Typography } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { ConsoleTabGuide } from "./components/ConsoleTabGuide";
import { MissingPermissionsRecheck } from "../../../../../common/MissingPermissionsRecheck";
import { useAppAbility } from "../../../../../../../../../../../../services/permissions";
import { useGetUsersMeProvidersGcpAccountsByAccountIdMissingRolesQuery } from "../../../../../../../../../../../../services/cloudchipr.api";
import { CopyIconButton } from "../../../../../../../../../../../common/CopyIconButton";
import { CommandSnippet } from "../../../../../../../../../../common/connectors/components/CommandSnippet";

interface GcpConsoleTabProps {
  accountId: string;
  serviceAccount?: string;
}
export const GcpConsoleTab: FC<GcpConsoleTabProps> = ({
  accountId,
  serviceAccount,
}) => {
  const { can } = useAppAbility();
  const canEditAccount = can("edit", "account");

  const { data, refetch } =
    useGetUsersMeProvidersGcpAccountsByAccountIdMissingRolesQuery(
      { accountId: accountId || "" },
      { skip: !accountId },
    );

  if (!data?.length) {
    return null;
  }

  return (
    <Fragment>
      <Typography variant="body1">
        Please go to{" "}
        <Typography
          color="primary"
          variant="body1"
          to="https://console.cloud.google.com/iam-admin/iam"
          component={RouterLink}
          target="_blank"
        >
          IAM in GCP Console
        </Typography>{" "}
        and assign following role to Cloudchipr’s Service Account.
      </Typography>
      {serviceAccount && (
        <div>
          <Typography variant="caption" component="div" mb={-1.5}>
            Service account
          </Typography>
          <CommandSnippet code={serviceAccount} />
        </div>
      )}
      <div>
        <Typography variant="caption" mb={0.5} component="div">
          Role
        </Typography>
        <Alert
          variant="outlined"
          icon={false}
          severity="info"
          sx={{ alignItems: "center", bgcolor: "#E5F4F8", borderRadius: 2 }}
        >
          <List>
            {data?.map((p) => {
              const role = p.title || p.name;

              return (
                <ListItem key={role} sx={{ p: 0 }}>
                  {p.url && canEditAccount ? (
                    <Link href={p.url} target="_blank" fontWeight="medium">
                      {role}
                    </Link>
                  ) : (
                    <Typography
                      variant="body2"
                      color="primary"
                      fontWeight="medium"
                    >
                      {role}
                    </Typography>
                  )}

                  <CopyIconButton data={role} visible color="primary" />
                </ListItem>
              );
            })}
          </List>
        </Alert>
      </div>

      <MissingPermissionsRecheck refetch={refetch} accountId={accountId} />

      <ConsoleTabGuide />
    </Fragment>
  );
};
