// @ts-expect-error : ignored because there are no types for "htmlparser",
import htmlparser from "htmlparser";
import * as entities from "html-entities";

type DomEntity = {
  data?: string;
  name?: string;
  raw?: string;
  type?: string;
  children?: DomEntity[];
  attribs?: Record<string, string>;
};

export const htmlToSlackMd = (html: string) => {
  const handler = new htmlparser.DefaultHandler(() => {
    // error ignored
  });

  const parser = new htmlparser.Parser(handler);
  parser.parseComplete(html);
  const dom = handler.dom;

  if (!dom) {
    return "";
  }

  return entities.decode(walk(dom));
};

function walk(domEntities?: DomEntity[]) {
  if (!domEntities) {
    return "";
  }

  let result = "";

  domEntities.forEach((entity) => {
    if ("text" === entity.type) {
      result += entity.data;
    }

    if ("tag" === entity.type) {
      switch (entity.name) {
        case "a":
          result +=
            "<" + entity.attribs?.href + "|" + walk(entity.children) + ">";
          break;

        case "strong":
        case "b":
          result += "*" + walk(entity.children) + "*";
          break;

        case "del":
          result += "~" + walk(entity.children) + "~";
          break;

        case "i":
        case "em":
          result += "_" + walk(entity.children) + "_";
          break;

        case "br":
          result += "\n";
          break;

        default:
          result += walk(entity.children);
      }
    }
  });

  return result;
}
