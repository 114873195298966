import { FC, Fragment } from "react";
import { Typography } from "@mui/material";
import { money } from "../../../../../../utils/numeral/money";
import { RecommendationGroupType } from "../utils/types/types";
import { RecommendationsTitle } from "../../common/RecommendationsTitle";
import { useAppSelector } from "../../../../../../store/hooks";
import { offHoursRecommendationsTotalPriceSelector } from "../../../../../../store/recommendations/selectors/off-hours/offHoursRecommendationsTotalPriceSelector";
import { recommendationsDataGrdDataTotalLengthSelector } from "../../../../../../store/recommendations/selectors/off-hours/data/database/recommendationsDataGrdDataTotalLengthSelector";

interface OffHoursRecommendationsTitleProps {
  group: RecommendationGroupType;
}

export const OffHoursRecommendationsTitle: FC<
  OffHoursRecommendationsTitleProps
> = ({ group }) => {
  const totalPrice = useAppSelector((state) =>
    offHoursRecommendationsTotalPriceSelector(state, group),
  );
  const length = useAppSelector((state) =>
    recommendationsDataGrdDataTotalLengthSelector(state, group),
  );

  return (
    <RecommendationsTitle
      heading={`Recommended Off Hours For ${
        group === "database" ? " SQL Databases" : " Instances"
      }`}
      description={
        <Fragment>
          There are {length} recommended Off Hours which can save{" "}
          <Typography component="span" fontWeight="bold">
            {money(totalPrice)}
          </Typography>{" "}
          per month.
        </Fragment>
      }
    />
  );
};
