import { GetQuickFilterTextParams } from "@ag-grid-community/core/dist/types/src/entities/colDef";
import { Role } from "../../../../../../../../services/cloudchipr.api";

export const getQuickFilterNameText = (
  params: GetQuickFilterTextParams<Role>,
) => {
  const role = params.data;

  return `${role.name} ${role.description}`;
};
