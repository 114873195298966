import { FC, useCallback } from "react";
import { Typography } from "@mui/material";
import { enqueueSnackbar } from "notistack";
import {
  useDeleteUsersMeCategoriesByCategoryIdMutation,
  useGetUsersMeCategoriesQuery,
} from "../../../../../../services/cloudchipr.api";
import { DeleteConfirmationDialog } from "../../../../../common/modals/DeleteConfirmationDialog";

interface DeleteCategoryGroupConfirmDialogProps {
  open: boolean;
  name: string;
  categoryIds: string[];
  onClose(): void;
}

export const DeleteCategoryGroupConfirmDialog: FC<
  DeleteCategoryGroupConfirmDialogProps
> = ({ categoryIds, onClose, open, name }) => {
  const [trigger, { isLoading }] =
    useDeleteUsersMeCategoriesByCategoryIdMutation();
  const { refetch } = useGetUsersMeCategoriesQuery();

  const deleteHandler = useCallback(async () => {
    const requests = categoryIds.map((id) => trigger({ categoryId: id }));

    await Promise.all(requests);
    await refetch();

    onClose();

    enqueueSnackbar("Category successfully deleted.", {
      variant: "snackbarAlert",
      AlertSnackBarProps: { severity: "success" },
    });
  }, [categoryIds, onClose, trigger, refetch]);

  return (
    <DeleteConfirmationDialog
      open={open}
      onClose={onClose}
      onDelete={deleteHandler}
      loading={isLoading}
      title="Delete Category?"
      content={
        <Typography color="text.secondary" variant="subtitle1">
          Are you sure you want to delete category
          <Typography component="span" fontWeight="bold">
            {` ${name} `}
          </Typography>
          ?
        </Typography>
      }
    />
  );
};
