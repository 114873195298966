import { Fragment } from "react";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Box, Stack, Tooltip, Typography } from "@mui/material";
import { RightsizingRecommendationsActionCell } from "./RightsizingRecommendationsActionCell";
import { RightsizingRecommendationsExportDataCell } from "./RightsizingRecommendationsExportDataCell";
import { AccountInfoData } from "../../../../../../common/AccountInfoData";
import { ColumnSetupType } from "../../../../../../../storybook/data-grid/utils/types/types";
import { money } from "../../../../../../../utils/numeral/money";
import {
  EmbeddedAccount,
  RightSizingRecommendation,
  Tag,
} from "../../../../../../../services/cloudchipr.api";
import { getExpanderCell } from "../../../../../../../storybook/data-grid/common/getExpanderCell";
import { RecommendationsTabType } from "../../../off-hours-recommendations/components/OffHoursRecommendationsContent";
import { SelectAllHeaderCheckbox } from "../../../../../../../storybook/data-grid/common/SelectAllCheckboxHeaderCell";
import { TypographyWithTooltip } from "../../../../../../common/TypographyWithTooltip";
import { RowSelectionCellCheckbox } from "../../../../../../../storybook/data-grid/common/RowSelectionCellCheckbox";
import { TagCell } from "../../../../../../common/data-grid-cells/tag-cell/TagCell";
import { IdentifierCell } from "../../../../../live-usage-mgmt/components/resource-card/resource-card-content/resource-card-data/resource-card-grid/resouce-card-data-grid/cells/identifier-cell/IdentifierCell";

export const rightsizingRecommendationsTableColumns = (
  tab: RecommendationsTabType,
  single?: boolean,
): ColumnSetupType<RightSizingRecommendation>[] => {
  const columnsFirstPart: ColumnSetupType<RightSizingRecommendation>[] = [
    {
      accessorKey: "resource_name",
      minSize: 190,
      meta: { cellStyles: { px: single ? 1 : 0 } },
      header: (props) => (
        <Stack direction="row" spacing={0.5} alignItems="center">
          {!single && (
            <SelectAllHeaderCheckbox {...props} sxProps={{ pl: 0 }} />
          )}
          Instance ID
        </Stack>
      ),
      cell: ({ row, table }) => (
        <Stack direction="row" spacing={0.5} alignItems="center">
          {!single && (
            <Box sx={{ "& span": { pl: 0 } }}>
              <RowSelectionCellCheckbox row={row} table={table} />
            </Box>
          )}

          <Box overflow="hidden">
            <IdentifierCell
              data={
                row?.original.resource_name ||
                row?.original.provider_unique_identifier
              }
            />
          </Box>
        </Stack>
      ),
    },
    {
      accessorKey: "account",
      header: "Account",
      minSize: 200,
      cell: (cell) => {
        const account = cell.getValue() as EmbeddedAccount;

        return (
          <AccountInfoData
            provider={account.provider}
            accountId={account.provider_account_id}
            accountName={account.provider_account_name}
          />
        );
      },
    },
    {
      accessorKey: "tags",
      header: "Tags",
      minSize: 80,
      cell: (cell) => {
        const tags = cell.getValue() as Tag[];

        return <TagCell tags={tags} widthToEllipsis={60} />;
      },
    },
    {
      accessorKey: "cpu_max",
      header: () => {
        return (
          <Fragment>
            CPU %
            <Tooltip
              arrow
              title="Maximum CPU utilization for last 14 days."
              placement="top"
            >
              <InfoOutlinedIcon
                sx={{ verticalAlign: "middle", color: "info.light", ml: 2 }}
                fontSize="small"
              />
            </Tooltip>
          </Fragment>
        );
      },
      minSize: 80,
      cell: (cell) => {
        const cpuMax = cell.getValue() as string;

        return <Typography variant="body2">{cpuMax}%</Typography>;
      },
    },
    {
      accessorKey: "current_machine_type",
      header: "Current Type (vCPU)",
      cell: (cell) => {
        return (
          <Typography variant="body2">
            {cell.getValue() as string} ({cell.row.original.current_vcpus})
          </Typography>
        );
      },
      minSize: 170,
    },
    {
      accessorKey: "recommended_machine_type",
      header: "Recommended Type (vCPU)",
      minSize: 220,
      cell: (cell) => {
        return (
          <Typography variant="body2">
            {cell.getValue() as string} ({cell.row.original.recommended_vcpus})
          </Typography>
        );
      },
    },
    {
      accessorKey: "potential_saving",
      header: "Rightsize to Save",
      minSize: 170,
      cell: (cell) => (
        <Typography variant="body2">
          {money(cell.getValue() as number)} /
          <Typography component="span" fontSize="small">
            per month
          </Typography>
        </Typography>
      ),
    },
  ];
  const columnsSecondPart: ColumnSetupType<RightSizingRecommendation>[] = [
    {
      id: "dataExport",
      header: "",
      enableSorting: false,
      enableResizing: false,
      size: 80,
      cell: (cell) => {
        const recommendation = cell.row.original;

        return (
          <RightsizingRecommendationsExportDataCell
            recommendation={recommendation}
          />
        );
      },
    },
  ];

  if (!single) {
    columnsFirstPart.unshift(getExpanderCell<RightSizingRecommendation>());

    columnsSecondPart.unshift({
      id: "actions",
      header: "Actions",
      enableSorting: false,
      minSize: 190,
      cell: (cell) => {
        const recommendation = cell.row.original;

        return (
          <RightsizingRecommendationsActionCell
            tab={tab}
            recommendationId={recommendation.id}
            recommendationLink={recommendation.link}
            dismissUntilDate={
              recommendation.is_forever_dismissed
                ? null
                : recommendation.dismiss_until_date
            }
          />
        );
      },
    });
  }

  if (tab === "active") {
    return [...columnsFirstPart, ...columnsSecondPart];
  }

  return [
    ...columnsFirstPart,
    {
      header: "Dismissed By",
      accessorKey: "dismissed_by",
      cell: (cell) => {
        return (
          <TypographyWithTooltip
            maxWidth={300}
            title={(cell.getValue() as string) ?? ""}
            variant="body2"
          />
        );
      },
    },
    ...columnsSecondPart,
  ];
};
