import { RootState } from "../../../../store";
import { getUsersMeOrganisationsCurrentDismissedResourcesSelector } from "../../dismiss/resources/getUsersMeOrganisationsCurrentDismissedResourcesSelector";
import { GetUsersMeOrganisationsCurrentDismissedResourcesApiResponse } from "../../../../../services/cloudchipr.api";

export const rightsizingRecommendationsDismissedResourcesDataSelector = (
  state: RootState,
): GetUsersMeOrganisationsCurrentDismissedResourcesApiResponse | undefined => {
  const response = getUsersMeOrganisationsCurrentDismissedResourcesSelector({
    group: "compute",
    recommendationType: "right_sizing",
  })(state);

  return response?.data;
};
