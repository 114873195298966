import { FC, useCallback } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { Box, Divider, Stack, Tooltip } from "@mui/material";
import { useDidMount } from "rooks";
import { TabPanel } from "@mui/lab";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { DateTimePicker } from "./date-time/DateTimePicker";
import { StopStartDatesTitle } from "./date-time/StopStartDatesTitle";
import { ScheduleDatesInterface } from "./date-time/DateTimeContent";
import { TimeZones } from "./date-time/TimeZones";
import {
  formatDate,
  getDateNowByTimeZone,
} from "../../../../../../../utils/helpers/date-time/datetime-format";
import { NextPrevious } from "../form/tools/NextPrevious";
import { onceAutomationValidationSchema } from "../../../../common/utils/constants/validation";
import { OffHoursSchedulerData } from "../../../utils/types/types";

interface InitialValuesInterface {
  id: string;
  timeZone: string;
  startAt: string | null;
  stopAt: string | null;
}

const initialValues: InitialValuesInterface = {
  startAt: null,
  stopAt: null,
  timeZone: "",
  id: "",
};

const validationSchema = Yup.object({
  startAt: onceAutomationValidationSchema,
  stopAt: onceAutomationValidationSchema,
});

interface OnceProps {
  setScheduleDatesData(data: OffHoursSchedulerData): void;
  onPreviousStep(): void;
  isDirty: boolean;
}

export const Once: FC<OnceProps & ScheduleDatesInterface> = ({
  startDate,
  endDate,
  setScheduleDatesData,
  onPreviousStep,
  timeZone,
  id,
  isDirty,
}) => {
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      setScheduleDatesData({
        startDate: values.stopAt,
        endDate: values.startAt,
        startCron: null,
        stopCron: null,
        timeZone: values.timeZone,
      });
    },
  });

  const {
    values,
    errors,
    touched,
    submitForm,
    setFieldValue,
    resetForm,
    dirty,
    isValid,
    setValues,
  } = formik;

  useDidMount(() => {
    const values = { stopAt: startDate, startAt: endDate, timeZone, id };

    if (id) {
      resetForm({ values });
    } else {
      setValues(values);
    }
  });

  const dateChangeHandler = useCallback(
    (name: "startAt" | "stopAt") => (value: string | null) => {
      if (value) {
        setFieldValue(name, formatDate(value));
      }
    },
    [setFieldValue],
  );

  return (
    <TabPanel value="once">
      <form>
        <Stack direction="row" spacing={2} alignItems="center">
          <StopStartDatesTitle title="Stop Resources At" type="stop" />
          <DateTimePicker
            date={values.stopAt}
            error={touched.stopAt && errors.stopAt}
            onChange={dateChangeHandler("stopAt")}
            minDateTime={getDateNowByTimeZone(values.timeZone)}
          />
        </Stack>

        <Box mt={3}>
          <Stack direction="row" spacing={2} alignItems="center">
            <StopStartDatesTitle title="Start Resources At" type="start" />
            <DateTimePicker
              date={values.startAt}
              error={touched.startAt && errors.startAt}
              onChange={dateChangeHandler("startAt")}
              minDateTime={getDateNowByTimeZone(values.timeZone)}
            />

            <Tooltip
              arrow
              title="Cloudchipr will only start resources that were stopped previously from this schedule. No other resources will be affected."
            >
              <InfoOutlinedIcon
                sx={{ verticalAlign: "middle", color: "text.secondary" }}
                fontSize="small"
              />
            </Tooltip>
          </Stack>
        </Box>
        <Stack mt={4}>
          <Divider />
          <TimeZones timeZone={values.timeZone} setFieldValue={setFieldValue} />
        </Stack>

        <Box mt={12}>
          <NextPrevious
            isNextDisabled={!id ? !dirty || !isValid : !isValid}
            onNext={submitForm}
            onPrevious={onPreviousStep}
            isDirty={dirty || isDirty}
          />
        </Box>
      </form>
    </TabPanel>
  );
};
