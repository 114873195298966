import { createAsyncThunk } from "@reduxjs/toolkit";
import { CategoryWithCosts } from "../../../../../services/cloudchipr.api";
import { RootState } from "../../../../store";
import { dimensionIdSelector } from "../../../selectors/slice-data/dimensionIdSelector";
import { dimensionCategoriesSelector } from "../../../selectors/slice-data/dimensionCategoriesSelector";
import { deleteCategoryByIdThunk } from "../../categories/deleteCategoryByIdThunk";

export const findAndDeleteRemovedCategoriesThunk = createAsyncThunk(
  "dimensions/findAndDeleteRemovedCategories",
  async (
    originalCategoriesByIds: Record<string, CategoryWithCosts>,
    { dispatch, getState },
  ) => {
    const state = getState() as RootState;
    const dimensionId = dimensionIdSelector(state);
    const dimensionCategories = dimensionCategoriesSelector(state);

    const removedCategories = Object.keys(originalCategoriesByIds).filter(
      (id) => !dimensionCategories[id],
    );

    if (!dimensionId || !removedCategories?.length) {
      return;
    }

    const promises = removedCategories.map((id) => {
      return dispatch(
        deleteCategoryByIdThunk({
          dimensionId,
          categoryId: id,
        }),
      );
    });

    await Promise.all(promises);
  },
);
