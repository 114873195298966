import { resourceExplorerByCurrentIdSelector } from "../resource-explorer-by-id/resourceExplorerByCurrentIdSelector";
import { RootState } from "../../../store";

export const resourceExplorerIsDefaultSelector = (
  state: RootState,
): boolean => {
  const resourceExplorer = resourceExplorerByCurrentIdSelector(state);

  return !!resourceExplorer?.is_default;
};
