import { createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../../../../../store";
import { costAndUsageSumWidgetSetupSelector } from "../../../../selectors/setups/cost-and-usage/costAndUsageSumWidgetSetupSelector";
import { setWidgetSetup } from "../../../../dashboardsSlice";
import { getCostAndUsageSumWidgetDataBySetupThunk } from "../data-get/getCostAndUsageSumWidgetDataBySetupThunk";
import { costAndUsageSumDefaultFilters } from "../../../../utils/constants/costAndUsageSetupDefaultData";
import { getResourceExplorerViewByIdThunk } from "../../../../../common/thunks/resource-explorer/getResourceExplorerViewByIdThunk";
import { CostAndUsageSumSetupType } from "../../../../utils/types/setups/costAndUsageSumSetupType";

export const costAndUsageSumREViewChangeThunk = createAsyncThunk(
  "dashboards/costAndUsageREViewChange",
  async (viewId: string, { dispatch, getState }) => {
    const state = getState() as RootState;
    const setup = costAndUsageSumWidgetSetupSelector(state);

    if (!setup) {
      return;
    }
    dispatch(setWidgetSetup({ ...setup, viewId }));

    const response = await dispatch(
      getResourceExplorerViewByIdThunk({ viewId }),
    ).unwrap();

    if (!response) {
      return;
    }

    const { data } = response;

    const to = data?.to_date;
    const from = data?.from_date;
    const label = data?.date_label;

    dispatch(
      setWidgetSetup({
        ...setup,
        filter: costAndUsageSumDefaultFilters,
        viewId: viewId,
        grouping: data.grouping === "resource" ? "service" : data.grouping,
        date: {
          label,
          to,
          from,
        },
      } as CostAndUsageSumSetupType),
    );

    await dispatch(getCostAndUsageSumWidgetDataBySetupThunk());
  },
);
