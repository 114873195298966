import { ChangeEvent, FC, useCallback, useState } from "react";
import {
  Box,
  Collapse,
  IconButton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useToggle } from "rooks";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import { CostAllocationTagsDrawerBody } from "./CostAllocationTagsDrawerBody";

export const VewTagsSelect: FC = () => {
  const [collapsed, toggleCollapsed] = useToggle();
  const [search, setSearch] = useState("");

  const searchChangeHandler = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setSearch(e.target.value);
    },
    [],
  );

  return (
    <Box border={1} borderRadius={1} p={1} borderColor="grey.400">
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        onClick={toggleCollapsed}
        sx={{ cursor: "pointer" }}
      >
        <Typography variant="body2" fontWeight="medium">
          AWS Cost Allocation Tags
        </Typography>

        <IconButton size="small">
          {collapsed ? (
            <ArrowDropUpIcon fontSize="small" sx={{ color: "action" }} />
          ) : (
            <ArrowDropDownIcon fontSize="small" sx={{ color: "action" }} />
          )}
        </IconButton>
      </Stack>

      <Collapse in={collapsed}>
        <Stack spacing={1} maxHeight={400} pt={1}>
          <TextField
            fullWidth
            size="small"
            onChange={searchChangeHandler}
            placeholder="Search by tag key"
            InputProps={inputProps}
          />

          <CostAllocationTagsDrawerBody provider="aws" searchValue={search} />
        </Stack>
      </Collapse>
    </Box>
  );
};

const inputProps = {
  startAdornment: (
    <InputAdornment position="start">
      <SearchIcon />
    </InputAdornment>
  ),
};
