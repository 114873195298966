import { FC, useCallback, useEffect } from "react";
import { Divider, Stack, ToggleButton, ToggleButtonGroup } from "@mui/material";
import ViewAgendaIcon from "@mui/icons-material/ViewAgenda";
import VideoLabelIcon from "@mui/icons-material/VideoLabel";
import { useLocalStorageHook } from "../../../../../../utils/hooks/useLocalStorage.hook";

export type PossibleVisibilities = "chart" | "grid" | "chart_grid";
const possibleVisibilities: PossibleVisibilities[] = [
  "chart",
  "grid",
  "chart_grid",
];

interface ScreenSplitButtonsProps {
  viewId: string;
  visibleContent: PossibleVisibilities;
  setVisibleContent(value: PossibleVisibilities): void;
}

export const ScreenSplitButtons: FC<ScreenSplitButtonsProps> = ({
  viewId,
  visibleContent,
  setVisibleContent,
}) => {
  const key = `c8r:${viewId}-resource-explorer:ScreenVisibility`;

  const { getItem, setItem } = useLocalStorageHook();
  const savedVisibility = getItem(key) as PossibleVisibilities;

  const visibleContentChangeHandler = useCallback(
    (_: unknown, visibleContent: "chart" | "grid" | "chart_grid") => {
      if (!visibleContent) {
        return;
      }

      setVisibleContent(visibleContent);
      setItem(key, visibleContent);
    },
    [setItem, setVisibleContent, key],
  );

  useEffect(() => {
    if (possibleVisibilities.includes(savedVisibility)) {
      setVisibleContent(savedVisibility);
    }
  }, [savedVisibility, setVisibleContent]);

  return (
    <Stack direction="row" alignItems="center" mt={1}>
      <Divider sx={dividerSx} />

      <ToggleButtonGroup
        exclusive
        size="small"
        value={visibleContent}
        sx={toggleButtonGroupStyles}
        onChange={visibleContentChangeHandler}
      >
        <ToggleButton value="chart_grid" sx={firstButtonSx}>
          <ViewAgendaIcon />
        </ToggleButton>

        <ToggleButton value="chart">
          <VideoLabelIcon />
        </ToggleButton>

        <ToggleButton value="grid" sx={lastButtonSx}>
          <VideoLabelIcon sx={{ transform: "rotate(180deg)" }} />
        </ToggleButton>
      </ToggleButtonGroup>

      <Divider sx={dividerSx} />
    </Stack>
  );
};

const toggleButtonGroupStyles = {
  "& .Mui-selected svg": { color: "primary.main" },
};

const firstButtonSx = { borderRadius: "8px 0 0 8px" };
const lastButtonSx = { borderRadius: "0 8px 8px 0" };
const dividerSx = { flexGrow: 1 };
