import { createDraftSafeSelector } from "@reduxjs/toolkit";
import { reservationDetailsCoverageDataSelector } from "./reservationDetailsCoverageDataSelector";
import { commitmentsDataViewTabSelector } from "../../../common/view-options/commitmentsDataViewTabSelector";
import { commitmentsDetailsDrawerCoverageGroupingSelector } from "../../../common/details-drawer/commitmentsDetailsDrawerCoverageGroupingSelector";
import { reservationCSVDataGenerator } from "../../../../utils/helpers/csv/reservationCSVDataGenerator";

export const reservationDetailsCSVDataSelector = createDraftSafeSelector(
  [
    reservationDetailsCoverageDataSelector,
    commitmentsDataViewTabSelector,
    commitmentsDetailsDrawerCoverageGroupingSelector,
  ],
  (data, viewTab, grouping) => {
    return reservationCSVDataGenerator({ viewTab, data, grouping });
  },
);
