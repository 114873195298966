import { createAsyncThunk } from "@reduxjs/toolkit";
import { cloudChiprApi } from "../../../../services/cloudchipr.api";

export const getProvidersRegionsThunk = createAsyncThunk(
  "automation/getProvidersRegions",
  async (_: void, { dispatch }) => {
    const { getProvidersByProviderRegions } = cloudChiprApi.endpoints;

    return await Promise.all([
      dispatch(
        getProvidersByProviderRegions.initiate({ provider: "aws" }),
      ).unwrap(),
      dispatch(
        getProvidersByProviderRegions.initiate({ provider: "gcp" }),
      ).unwrap(),
      dispatch(
        getProvidersByProviderRegions.initiate({ provider: "azure" }),
      ).unwrap(),
    ]);
  },
);
