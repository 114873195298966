import { FC, Fragment, useMemo } from "react";
import { Link, Stack, Tooltip, Typography } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { useAppSelector } from "../../../../../../store/hooks";
import { organisationQuarterStartMonthSelector } from "../../../../../../store/common/selectors/org-current-settings/organisationQuarterStartMonthSelector";
import { commitmentsDatesSelector } from "../../../../../../store/commitments/selectors/common/view-options/commitmentsDatesSelector";
import { generateFromToFromDates } from "../../../utilization-and-coverage/utils/helpers/generateFromToFromDates";

export const OverviewEffectiveSavingsRateHeader: FC = () => {
  const quarterStartMonth = useAppSelector(
    organisationQuarterStartMonthSelector,
  );
  const dates = useAppSelector(commitmentsDatesSelector);
  const datesFromTo = useMemo(() => {
    return generateFromToFromDates(dates, quarterStartMonth);
  }, [dates, quarterStartMonth]);

  return (
    <Stack direction="row" spacing={0.5} alignItems="center">
      <Typography variant="body2" fontWeight="medium" color="text.secondary">
        Effective Savings Rate
      </Typography>

      <Tooltip
        title={
          <Fragment>
            <Typography
              variant="caption"
              fontWeight="medium"
              lineHeight={1}
              fontSize={11}
            >
              ESR represents the percentage of Net Savings compared to the
              On-Demand Cost of Covered Usage {datesFromTo}. <br />
              It is calculated as: (Net Savings / On-Demand Cost of Covered
              Usage) * 100. <br />
            </Typography>
            <Link
              sx={{
                textDecoration: "underline white",
                color: "white",
              }}
              target="_blank"
              href="https://www.finops.org/wg/how-to-calculate-effective-savings-rate-esr/#:~:text=The%20Effective%20Savings%20Rate%20(ESR)%20is%20the%20Return%20on%20Investment%20(ROI)%20for%20cloud%20discount%20instruments%20and%20the%20one%20output%20metric%20you%20can%20measure%20true%20savings%20performance."
            >
              Read more
            </Link>
          </Fragment>
        }
        placement="top"
        arrow
      >
        <InfoOutlinedIcon sx={{ fontSize: 16, color: "action.disabled" }} />
      </Tooltip>
    </Stack>
  );
};
