import { costBreakdownWidgetSetupTotalDataWithIdsSelector } from "./costBreakdownWidgetSetupTotalDataWithIdsSelector";
import { RootState } from "../../../../../store";
import { ResourceExplorerGridDataWithId } from "../../../../../resource-explorer/utils/types/resourceExplorerDataGrid";
import { formatcostBreakdownWidgetTableViewLastGridItem } from "../utils/helpers/formatCostBreakdownV2WidgetTableViewLastGridItem";

export const costBreakdownWidgetSetupNoChartViewDataSelector = (
  state: RootState,
): ResourceExplorerGridDataWithId[] => {
  const data = costBreakdownWidgetSetupTotalDataWithIdsSelector(state);
  if (!data) {
    return [];
  }

  const lastItems = data.slice(itemsCount, data?.length);
  const startItems = data.slice(0, itemsCount);

  const lastItem = formatcostBreakdownWidgetTableViewLastGridItem(lastItems);

  const completeData = [...startItems];

  if (lastItems.length) {
    completeData.push(lastItem);
  }

  return completeData;
};

const itemsCount = 9;
