import { useCallback } from "react";
import { useAllItemsFiltersContext } from "../HierarchyAllItemsFiltersProvider";
import { DropdownSelect } from "../../../../../common/select/dropdown-select/DropdownSelect";
import { VisibilityType } from "../../utils/types/types";
import { DropdownSelectOption } from "../../../../../common/select/dropdown-select/utils/types/types";

export const HierarchyAllItemsVisibilityFilter = () => {
  const {
    filters: { visibilities, setVisibilities },
  } = useAllItemsFiltersContext();

  const changeHandler = useCallback(
    (visibilities?: string[]) => {
      setVisibilities(visibilities as VisibilityType[] | undefined);
    },
    [setVisibilities],
  );

  return (
    <DropdownSelect
      showSearch={false}
      initialSelectedValues={visibilities}
      label="Visibility"
      options={options}
      listWidth={200}
      submitHandlerOnClose={changeHandler}
      sortSelectedOptions={false}
    />
  );
};

const options: DropdownSelectOption[] = [
  { value: "visible_only_to_me", label: "Visible only to me" },
  { value: "visible_to_everyone", label: "Visible to everyone" },
];
