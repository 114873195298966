import { FC, useMemo } from "react";
import { Stack } from "@mui/material";
import { IntegrationTypeCard, IntegrationType } from "./IntegrationTypeCard";
import { integrationTypes } from "../../../../../utils/constants/integration-types";

interface IntegrationTypesProps {
  onChange(type: IntegrationType): void;
  activeType: IntegrationType;
  disabledIntegrationTypes?: IntegrationType[];
  disabled: boolean;
}

export const IntegrationTypes: FC<IntegrationTypesProps> = ({
  onChange,
  activeType,
  disabled,
  disabledIntegrationTypes,
}) => {
  const filteredIntegrationTypes = useMemo(() => {
    return integrationTypes.filter(
      (item) => !disabledIntegrationTypes?.includes(item),
    );
  }, [disabledIntegrationTypes]);

  return (
    <Stack direction="row" spacing={2}>
      {filteredIntegrationTypes.map((item) => {
        return (
          <IntegrationTypeCard
            key={item}
            type={item}
            onClick={onChange}
            selected={activeType === item}
            disabled={disabled}
          />
        );
      })}
    </Stack>
  );
};
