import { reservationsRecommendationsDataSelector } from "./reservationsRecommendationsDataSelector";
import { RootState } from "../../../../store";
import {
  commitmentsPaymentOptionLabels,
  commitmentsResourceTypeNames,
  savingsPlanLockBackPeriodLabels,
  savingsPlanTermLabels,
} from "../../../../../components/pages/commtiments/common/utils/constants/labels";

export const reservationsRecommendationsCSVDataSelector = (
  state: RootState,
) => {
  const recommendations = reservationsRecommendationsDataSelector(state);

  return recommendations?.map((rec) => {
    const data: Record<string, any> = {};

    return {
      ...data,
      Type: commitmentsResourceTypeNames[rec.reservation_type],
      Term: savingsPlanTermLabels[rec.term_in_years],
      "Recommendation Level": rec.account_scope,
      "Linked Account":
        rec.account.provider_account_name ?? rec.account.provider_account_id,
      "Instance Type": rec.instance_type,
      "Instance Count": rec.instance_count,
      Platform: rec.platform,
      Region: rec.region,
      "Payment option": commitmentsPaymentOptionLabels[rec.payment_option],
      "Based On Past": savingsPlanLockBackPeriodLabels[rec.lookback_period],
      "Estimated Monthly Savings": rec.estimated_monthly_savings,
    };
  });
};
