import { resourceExplorerViewDataByIdSelector } from "../resource-explorer-by-id/resourceExplorerViewDataByIdSelector";
import { RootState } from "../../../store";
import { resourceExplorerIdSelector } from "../current-resource-explorer/resourceExplorerIdSelector";

export const resourceExplorerViewBudgetSelector = (state: RootState) => {
  const viewId = resourceExplorerIdSelector(state);
  const view = resourceExplorerViewDataByIdSelector(state, viewId);

  return view?.budget;
};
