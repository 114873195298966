import { RootState } from "../../../../store";
import { WidgetType } from "../../../../../services/cloudchipr.api";
import { costAndUsageWidgetNotFoundSelector } from "../cost-and-usage/costAndUsageWidgetNotFoundSelector";
import { largestCostChangesWidgetNotFoundSelector } from "../largest-cost-changes/largestCostChangesWidgetNotFoundSelector";
import { commitmentUtilizationChangesWidgetNotFoundSelector } from "../commitment-utilization/widget-view/commitmentUtilizationChangesWidgetNotFoundSelector";
import { costBreakdownWidgetNotFoundSelector } from "../cost-breakdown/widget-view/costBreakdownWidgetNotFoundSelector";

export const widgetDataNotFoundSelector = (
  state: RootState,
  widgetId: string,
  widgetType: WidgetType,
) => {
  if (widgetType === "cost_breakdown") {
    return costBreakdownWidgetNotFoundSelector(state, widgetId);
  }

  if (widgetType === "cost_and_usage_summary") {
    return costAndUsageWidgetNotFoundSelector(state, widgetId);
  }

  if (widgetType === "largest_cost_changes") {
    return largestCostChangesWidgetNotFoundSelector(state, widgetId);
  }

  if (widgetType === "commitments_utilization") {
    return commitmentUtilizationChangesWidgetNotFoundSelector(state, widgetId);
  }

  return false;
};
