import { FC, useCallback } from "react";
import { ListItemText, MenuItem } from "@mui/material";
import {
  CleanActionTypeDetails,
  CleanActionTypes,
} from "../../../../../../../../../../../../../utils/clean-options";
import { useAppSelector } from "../../../../../../../../../../../../../store/hooks";
import { ResourceType } from "../../../../../../../../../../../../../services/cloudchipr.api";
import { useAppAbility } from "../../../../../../../../../../../../../services/permissions";
import { liveUsageMgmtAccountsVisibilitiesByResourceTypeSelector } from "../../../../../../../../../../../../../store/live-usage-mgmt/selectors/accounts-data/liveUsageMgmtAccountsVisibilitiesByResourceTypeSelector";

interface CleanActionItemProps {
  disabled: boolean;
  resourceType: ResourceType;
  data: CleanActionTypeDetails;
  onClick: (actionType: CleanActionTypes) => void;
}

export const CleanV2ActionItem: FC<CleanActionItemProps> = ({
  data,
  onClick,
  resourceType,
  disabled,
}) => {
  const { cannot } = useAppAbility();
  const canNotCleanResource = cannot("clean", "resource");

  const resourceVisibility = useAppSelector((state) =>
    liveUsageMgmtAccountsVisibilitiesByResourceTypeSelector(
      state,
      resourceType,
    ),
  );

  const onMenuItemClick = useCallback(() => {
    onClick(data.type);
  }, [data, onClick]);

  const disabledLocal =
    resourceVisibility?.actions[data.type] === false ||
    canNotCleanResource ||
    disabled;

  return (
    <MenuItem
      dense
      onClick={onMenuItemClick}
      key={data.type}
      disabled={disabledLocal}
    >
      <ListItemText>{data.label}</ListItemText>
    </MenuItem>
  );
};
