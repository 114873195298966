import { FC, Fragment, useMemo } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { useEffectOnceWhen } from "rooks";
import { Box } from "@mui/material";
import { SingleRightsizingRecommendationContent } from "./components/SingleRightsizingRecommendationContent";
import { BackToRecommendationsButton } from "./components/BackToRecommendationsButton";
import { SingleRightsizingRecommendationWarning } from "./components/SingleRightsizingRecommendationWarning";
import { useAppDispatch, useAppSelector } from "../../../../../store/hooks";
import { rightsizingRecommendationDataSelector } from "../../../../../store/recommendations/selectors/rightsizing/rightsizingRecommendationDataSelector";
import { getRightsizingRecommendationByIdsThunk } from "../../../../../store/recommendations/thunks/rightsizing/getRightsizingRecommendationByIdsThunk";
import { PageHeader } from "../../../common/PageHeader";
import { rightsizingRecommendationLoadingSelector } from "../../../../../store/recommendations/selectors/rightsizing/loading/rightsizingRecommendationLoadingSelector";
import { RightsizingRecommendationsEmptyState } from "../rightsizing-recommendations/components/RightsizingRecommendationsEmptyState";

export const SingleRightsizingRecommendation: FC = () => {
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const { recommendationId } = useParams();
  const accountId = searchParams.get?.("accountId");

  const recommendation = useAppSelector((state) =>
    rightsizingRecommendationDataSelector(
      state,
      recommendationId ?? "",
      accountId,
    ),
  );
  const loading = useAppSelector((state) =>
    rightsizingRecommendationLoadingSelector(
      state,
      recommendationId ?? "",
      accountId,
    ),
  );

  const { resource_name, provider_unique_identifier } = recommendation ?? {};

  const breadcrumbs = useMemo(() => {
    const resourceBreadcrumbsTitle =
      resource_name || provider_unique_identifier;

    const recommendationsBreadcrumbs = {
      title: "Rightsizing Recommendations",
      to: "/recommendations/rightsizing",
    };
    const resourceBreadcrumbs = {
      title: resourceBreadcrumbsTitle,
    };

    return resourceBreadcrumbsTitle
      ? [recommendationsBreadcrumbs, resourceBreadcrumbs]
      : [recommendationsBreadcrumbs];
  }, [resource_name, provider_unique_identifier]);

  useEffectOnceWhen(() => {
    if (!recommendationId || !accountId) {
      return;
    }

    dispatch(
      getRightsizingRecommendationByIdsThunk({
        recommendationId,
        accountId,
      }),
    );
  }, !!recommendationId && !!accountId);

  return (
    <Fragment>
      <PageHeader
        breadcrumbs={breadcrumbs}
        actions={
          <SingleRightsizingRecommendationWarning
            accountId={recommendation?.account.id}
            provider={recommendation?.account.provider}
          />
        }
        loading={loading}
      />
      <Box p={2}>
        <BackToRecommendationsButton />

        {!recommendation && !loading && (
          <RightsizingRecommendationsEmptyState reason="noData" single />
        )}

        {!!recommendation && (
          <SingleRightsizingRecommendationContent
            recommendation={recommendation}
          />
        )}
      </Box>
    </Fragment>
  );
};
