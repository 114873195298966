import { resourceExplorerPathSelector } from "./resourceExplorerPathSelector";
import { RootState } from "../../../../store";
import { resourceExplorerGroupingSelector } from "../data/resourceExplorerGroupingSelector";

import { nextGroupingByCurrentGrouping } from "../../../../../components/pages/resource-explorer/components/resource-explorer-card/utils/constants/groupings/nextGroupingByCurrentGrouping";

export const canRowDrillDownMoreSelector = (state: RootState) => {
  const path = resourceExplorerPathSelector(state);
  const groupedBy = resourceExplorerGroupingSelector(state);

  if (!groupedBy) {
    return false;
  }

  if (groupedBy === "cost_allocation_tag_value" && path.length === 1) {
    return false;
  }

  const nextGrouping = nextGroupingByCurrentGrouping[groupedBy];

  const nextGroupingAlreadyExist = path?.some(
    (pathItem, index) =>
      pathItem?.groupedBy === nextGrouping && index !== path.length - 1,
  );

  return !nextGroupingAlreadyExist;
};
