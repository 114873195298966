import numeral from "numeral";

export const sizeGenerator = (
  size: string,
): Record<string, string | number> => {
  return {
    "Size in bytes": size,
    Size: numeral(size).format("0.00 b"),
  };
};
