import { liveUsageMgmtFilterTemplatesSelector } from "../liveUsageMgmtFilterTemplatesSelector";
import { RootState } from "../../../store";
import { FilterSet } from "../../../../services/cloudchipr.api";

export const liveUsageMgmtDefaultFilterTemplateSelector = (
  state: RootState,
): FilterSet | undefined => {
  const filterTemplates = liveUsageMgmtFilterTemplatesSelector(state);
  return filterTemplates?.find((item) => item.is_default);
};
