import { FC } from "react";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import { Button } from "@mui/material";

export interface RevertButtonProps {
  onRevertFilters?(): void;
}

export const RevertButton: FC<RevertButtonProps> = ({ onRevertFilters }) => {
  return (
    <Button
      size="small"
      variant="text"
      color="tertiary"
      startIcon={<RestartAltIcon />}
      onClick={onRevertFilters}
      sx={{ alignSelf: "flex-start" }}
    >
      Revert All
    </Button>
  );
};
