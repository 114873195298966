import { largesCostChangesSetupWidgetDataResponseSelector } from "./largesCostChangesSetupWidgetDataResponseSelector";
import { RootState } from "../../../../../store";

export const largesCostChangesSetupWidgetDataLoadingSelector = (
  state: RootState,
) => {
  const response = largesCostChangesSetupWidgetDataResponseSelector(state);

  return !!response?.isLoading;
};
