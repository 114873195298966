export const gcpOrganizationRoleViewerName = "Organization Role Viewer";
export const gcpRoles: Map<string, Map<string, string[]>> = new Map([
  [
    "single",
    new Map([
      ["read", ["Viewer"]],
      ["read_write", ["Editor"]],
    ]),
  ],
  [
    "organization",
    new Map([
      ["read", ["Viewer", gcpOrganizationRoleViewerName, "Browser"]],
      ["read_write", ["Editor", gcpOrganizationRoleViewerName, "Browser"]],
    ]),
  ],
]);
