import { WidgetViewType } from "../../../utils/types/types";

export const buildingInProgressWidgetId = "buildingInProgressWidgetId";

export const buildingInProgressWidgetIdWithNoFilter =
  "buildingInProgressWidgetIdWithNoFilter";

export const previousPeriodVisualizationTypes = new Set<WidgetViewType>([
  "numeral",
  "table",
]);
