import { costBreakdownWidgetSetupDataSelector } from "./costBreakdownWidgetSetupDataSelector";
import { RootState } from "../../../../../store";
import { ResourceExplorerGridDataWithId } from "../../../../../resource-explorer/utils/types/resourceExplorerDataGrid";
import { formatCostBreakdownREDataGridData } from "../../../../utils/helpers/costBreakdown/formatCostBreakdownREDataGridData";

export const costBreakdownWidgetSetupTotalDataWithIdsSelector = (
  state: RootState,
): ResourceExplorerGridDataWithId[] | undefined => {
  const data = costBreakdownWidgetSetupDataSelector(state)?.total;
  return formatCostBreakdownREDataGridData(data ?? [], true) ?? [];
};
