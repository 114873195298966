import { FC, memo } from "react";
import { Divider, Stack, Typography } from "@mui/material";
import { NextRunDatesTime } from "./NextRunDatesTime";
import { AutomationTabType } from "../../../../../common/utils/types/types";

interface NextRunDatesSectionProps {
  timeZone: string;
  spacing?: "long" | "short";
  tabType?: AutomationTabType;
}
export const NextRunDatesSection: FC<NextRunDatesSectionProps> = memo(
  ({ timeZone, spacing, tabType }) => {
    return (
      <Stack mt={2} spacing={3}>
        <Divider />
        <Stack direction="row">
          <Typography
            variant="subtitle2"
            mr={2}
            width={spacing === "short" ? 64 : 100}
            alignSelf="center"
          >
            Next run:
          </Typography>
          <NextRunDatesTime timeZone={timeZone} tabType={tabType} />
        </Stack>
      </Stack>
    );
  },
);
