import { FC, memo, useCallback } from "react";
import { MobileTimePicker } from "@mui/x-date-pickers";
import { Stack } from "@mui/material";
import moment from "moment";
import { StopStartDatesTitle } from "../date-time/StopStartDatesTitle";

interface StopStartDatesProps {
  variant: "stop" | "start";
  value: string | null;
  label: string;
  error?: string | boolean;
  name: string;
  setFieldValue(key: string, date: string | null): void;
}

export const StopStartDates: FC<StopStartDatesProps> = memo(
  ({ setFieldValue, value, error, label, name, variant }) => {
    const changeHandler = useCallback(
      (value: any) => {
        if (value) {
          setFieldValue(name, moment(value).format());
        }
      },
      [name, setFieldValue],
    );

    return (
      <Stack direction="row" spacing={2} alignItems="center">
        <StopStartDatesTitle title={label} type={variant} />
        <MobileTimePicker
          ampm
          value={value ? moment(value) : null}
          label="Time"
          onChange={changeHandler}
          slotProps={{
            textField: {
              size: "small",
              error: !!error,
              helperText: error,
              variant: "outlined",
            },
          }}
        />
      </Stack>
    );
  },
);
