import { navigationDashboardPublicDataSelector } from "./navigationDashboardPublicDataSelector";
import { navigationDashboardPrivateDataSelector } from "./navigationDashboardPrivateDataSelector";
import { NavigationItemsVisibilityType } from "../../utils/types";
import { RootState } from "../../../store";
import { TreeItems } from "../../../../components/navigation/components/sortable-tree/utils/types";

export const navigationDataByVisibilitySelector = (
  state: RootState,
  visibility?: NavigationItemsVisibilityType,
): TreeItems | undefined => {
  if (visibility === "visible_to_everyone") {
    return navigationDashboardPublicDataSelector(state);
  }
  return navigationDashboardPrivateDataSelector(state);
};
