import { FC } from "react";

import { Box, Stack, Typography } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import { useAppSelector } from "../../../../../../../store/hooks";
import { billingErrorAccountStatusesByProviderSelector } from "../../../../../../../store/common/selectors/billing-status/error/billingErrorAccountStatusesByProviderSelector";

export const AzureBillingErrorState: FC = () => {
  const accountStatuses = useAppSelector((state) =>
    billingErrorAccountStatusesByProviderSelector(state, "azure"),
  );

  const errorData = accountStatuses?.at(0);

  return (
    <Stack
      justifyContent="center"
      alignItems="center"
      height="100%"
      gap={2}
      maxWidth={624}
    >
      <ErrorOutlineOutlinedIcon color="error" fontSize="large" />
      <Typography variant="h5" fontWeight={500} mt={2}>
        Billing Data Error
      </Typography>
      {(errorData?.name || errorData?.provider_account_id) && (
        <Typography variant="body2">
          We are not able to see the cost and usage + billing data for certain
          periods on {errorData?.name ?? errorData?.provider_account_id}, with
          the following response from Azure
        </Typography>
      )}

      {errorData?.problem_message && (
        <Box bgcolor="grey.300" p={1} borderRadius={2}>
          <Typography fontWeight={700} fontSize={10} color="text.disabled">
            Error
          </Typography>
          <Typography variant="caption">
            {errorData?.problem_message}
          </Typography>
        </Box>
      )}

      <Typography variant="body2">
        In order to fix this please follow
        <Typography
          color="primary"
          variant="body2"
          to="https://learn.microsoft.com/en-us/azure/cost-management-billing/costs/assign-access-acm-data#enable-access-to-costs-in-the-azure-portal"
          component={RouterLink}
          target="_blank"
        >
          {" "}
          the instructions from Microsoft Azure Documentation.
        </Typography>
      </Typography>
    </Stack>
  );
};
