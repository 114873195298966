import { cloudChiprApi } from "../../../../services/cloudchipr.api";
import { RootState } from "../../../store";
import { createResourceExplorerFolderThunkFixedCacheKey } from "../../thunks/resource-explorer-hierarchy-visibility/createResourceExplorerVisibilityHierarchyFolderThunk";

const selector =
  cloudChiprApi.endpoints.postV3UsersMeOrganisationsCurrentResourceExplorerFolders.select(
    createResourceExplorerFolderThunkFixedCacheKey,
  );

export const createResourceExplorerFolderLoadingSelector = (
  state: RootState,
): boolean => {
  return !!selector(state)?.isLoading;
};
