import { Dispatch, FC, SetStateAction, useCallback, useMemo } from "react";
import { Box } from "@mui/material";
import { AgGridReact } from "@ag-grid-community/react";
import { GridApi, GridReadyEvent } from "@ag-grid-community/core";
import { SxProps, Theme } from "@mui/system";
import { reportsTableColumns } from "./reportsTableColumns";
import { ReportsTableNoRowsComponent } from "./ReportsTableNoRowsComponent";
import {
  reportsTableOptions,
  reportsTableWrapperStyles,
} from "../utils/constants/tableConfigs";
import { ReportsTableData } from "../../../../../../store/reports/utils/types/types";

interface ReportsTableComponentProps {
  setGridApi: Dispatch<SetStateAction<GridApi | undefined>>;
  reports?: ReportsTableData[];
  containerSx?: SxProps<Theme>;
}

export const ReportsTableComponent: FC<ReportsTableComponentProps> = ({
  setGridApi,
  reports,
  containerSx,
}) => {
  const styles = useMemo(() => {
    return {
      ...reportsTableWrapperStyles,
      height: reports?.length
        ? reports?.length * gridRowHeight + gridHeaderRowHeight
        : gridDefaultHeight,
      ...containerSx,
    };
  }, [reports?.length, containerSx]);

  const onGridReady = useCallback(
    ({ api }: GridReadyEvent) => {
      setGridApi(api);
    },
    [setGridApi],
  );

  return (
    <Box className="ag-theme-quartz" sx={styles}>
      <AgGridReact
        onGridReady={onGridReady}
        enableCellTextSelection
        suppressMovableColumns
        rowHeight={gridRowHeight}
        rowData={reports}
        columnDefs={reportsTableColumns}
        gridOptions={reportsTableOptions}
        noRowsOverlayComponent={ReportsTableNoRowsComponent}
      />
    </Box>
  );
};

const gridRowHeight = 56;
const gridHeaderRowHeight = 70;
const gridDefaultHeight = 500;
