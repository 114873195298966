import { FC, Fragment, useCallback } from "react";
import { IconButton, Tooltip } from "@mui/material";
import { useDidMount } from "rooks";
import { ProviderType } from "../../../../../../services/cloudchipr.api";
import { useDialog } from "../../../../../../utils/hooks/useDialog.hook";
import { getOrganisationFilterSetsThunk } from "../../../../../../store/filters/thunks/filter-set/getOrganisationFilterSetsThunk";
import { useAppDispatch, useAppSelector } from "../../../../../../store/hooks";
import { filterTemplatesSelector } from "../../../../../../store/filters/selectors/filter-set/filterTemplatesSelector";
import filterTemplateIcon from "../../../../../../assets/images/icons/filter_template.svg";
import { LiveUsageMgmtFilterTemplatesDialog } from "../../../../live-usage-mgmt/components/filter-templates/templates-table/LiveUsageMgmtFilterTemplatesDialog";

interface AccountsFilterTemplateDropdownProps {
  provider: ProviderType;
}

export const AccountsFilterTemplateDropdown: FC<
  AccountsFilterTemplateDropdownProps
> = ({ provider }) => {
  const dispatch = useAppDispatch();
  const {
    open: openFilterTemplate,
    openDialog: openFilterTemplateDialog,
    closeDialog: closeFilterTemplateDialog,
  } = useDialog();
  const filterSets = useAppSelector((state) =>
    filterTemplatesSelector(state, provider),
  );

  const filterTemplateClickHandler = useCallback(() => {
    openFilterTemplateDialog();
  }, [openFilterTemplateDialog]);

  useDidMount(() => {
    dispatch(getOrganisationFilterSetsThunk());
  });

  return (
    <Fragment>
      <Tooltip title="Manage Filter Templates" arrow placement="top">
        <IconButton color="primary" onClick={filterTemplateClickHandler}>
          <img
            src={filterTemplateIcon}
            width={24}
            height={24}
            alt="filter-template"
          />
        </IconButton>
      </Tooltip>
      <LiveUsageMgmtFilterTemplatesDialog
        filterTemplates={filterSets}
        provider={provider}
        onClose={closeFilterTemplateDialog}
        open={openFilterTemplate}
      />
    </Fragment>
  );
};
