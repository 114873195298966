import { ColDef } from "@ag-grid-community/core";
import { RefObject } from "react";
import { AgGridReact, CustomCellRendererProps } from "@ag-grid-community/react";
import { getQuickFilterNameText } from "./getQuickFilterTextFunctions/getQuickFilterNameText";
import { getQuickFilterCreatedAtText } from "./getQuickFilterTextFunctions/getQuickFilterCreatedAtText";
import { getQuickFilterCreatedByText } from "./getQuickFilterTextFunctions/getQuickFilterCreatedByText";
import { RolesGridNameCell } from "../cells/RolesGridNameCell";
import { RolesGridCreatedDateCell } from "../cells/RolesGridCreatedDateCell";
import { RolesGridActionCell } from "../cells/RolesGridActionCell";
import { Role } from "../../../../../../../services/cloudchipr.api";
import { UserActivityCell } from "../../../../../../common/data-grid-cells/UserActivityCell";

export const getRolesDataGridColumns = (
  gridRef: RefObject<AgGridReact>,
): ColDef[] => {
  return [
    {
      field: "name",
      flex: 2,
      minWidth: 100,
      unSortIcon: true,
      cellStyle: { paddingLeft: 16 },
      suppressColumnsToolPanel: true,
      getQuickFilterText: getQuickFilterNameText,
      cellRenderer: RolesGridNameCell,
    },

    {
      field: "created_at",
      flex: 2,
      minWidth: 100,
      headerName: "Created Date",
      unSortIcon: true,
      getQuickFilterText: getQuickFilterCreatedAtText,
      cellRenderer: RolesGridCreatedDateCell,
    },
    {
      field: "created_by.name",
      flex: 3,
      minWidth: 100,
      headerName: "Created By",
      unSortIcon: true,
      getQuickFilterText: getQuickFilterCreatedByText,
      cellRenderer: ({ data: role }: CustomCellRendererProps) => (
        <UserActivityCell
          name={role?.created_by?.name ?? role?.created_by?.email}
          byCloudchipr={role.is_default}
        />
      ),
    },
    {
      // column to fit rest place
      flex: 1,
      colId: "toFitEmptySpace",
      headerName: "",
      sortable: false,
      cellDataType: false,
      resizable: false,
      suppressMovable: true,
      suppressColumnsToolPanel: true,
    },
    {
      colId: "actions",
      width: 80,
      pinned: "right",
      sortable: false,
      resizable: false,
      unSortIcon: true,
      cellDataType: false,
      suppressMovable: true,
      suppressColumnsToolPanel: true,
      cellRenderer: (props: CustomCellRendererProps<Role>) => (
        <RolesGridActionCell gridRef={gridRef} {...props} />
      ),
    },
  ];
};
