import { FC, useCallback, useState } from "react";
import { Dialog, DialogActions, DialogContent } from "@mui/material";

import { IntegrationCustomMessageDialogActions } from "./IntegrationCustomMessageDialogActions";
import { IntegrationCustomMessageDialogContent } from "./IntegrationCustomMessageDialogContent";
import { IntegrationCustomMessageContentProps } from "../IntegrationCustomMessageContent";
import { DialogTitleClosable } from "../../../../../../common/dialog-utils/DialogTitleClosable";

type IntegrationCustomMessageDialogContentExtendedProps = Pick<
  IntegrationCustomMessageContentProps,
  "actionFrom" | "message" | "type"
>;

interface IntegrationCustomMessageDialogProps
  extends IntegrationCustomMessageDialogContentExtendedProps {
  open: boolean;
  onClose(): void;
  onChange(message: string): void;
}

export const IntegrationCustomMessageDialog: FC<
  IntegrationCustomMessageDialogProps
> = ({ actionFrom, open, onClose, onChange, message, type }) => {
  const [customMessage, setCustomMessage] = useState<string>(message);

  const submitCustomMessageHandler = useCallback(() => {
    onChange(customMessage);
    onClose();
  }, [onChange, customMessage, onClose]);

  const cancelCustomMessageHandler = useCallback(() => {
    onChange(message);
    onClose();
    setCustomMessage(message);
  }, [onChange, onClose, message]);

  return (
    <Dialog
      open={open}
      onClose={cancelCustomMessageHandler}
      sx={{
        px: 1,
      }}
      fullWidth
      maxWidth="md"
    >
      <DialogTitleClosable
        title="Edit Message"
        onClose={cancelCustomMessageHandler}
        TitleProps={{ sx: { p: 2 } }}
      />
      <DialogContent
        dividers
        sx={{
          p: 2,
        }}
      >
        <IntegrationCustomMessageDialogContent
          type={type}
          message={customMessage}
          onCustomMessageChange={setCustomMessage}
          actionFrom={actionFrom}
        />
      </DialogContent>
      <DialogActions
        sx={{
          px: 2,
        }}
      >
        <IntegrationCustomMessageDialogActions
          onSecondaryBtnClick={cancelCustomMessageHandler}
          onPrimaryBtnClick={submitCustomMessageHandler}
        />
      </DialogActions>
    </Dialog>
  );
};
