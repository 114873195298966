import Typography from "@mui/material/Typography";
import { FC } from "react";

export const IntegrationUtilizationAlertCommitmentsUtilizationSlackHeader: FC =
  () => {
    return (
      <Typography variant="body1">
        Commitment Utilization Below 80% -{" "}
        <Typography component="span" fontWeight="medium">
          {`{{Alert Name}}`}
        </Typography>
      </Typography>
    );
  };
