import { Account } from "../../../services/cloudchipr.api";

export const nonDeletedAndNotBillingAccountsCombiner = (
  accounts: Account[] | null,
): Account[] | null => {
  return (
    accounts?.filter(
      (a) => a.status !== "deleted" && a.status !== "connected_for_billing",
    ) ?? null
  );
};
