import { FC, Fragment } from "react";
import { Divider, IconButton, Stack, Typography } from "@mui/material";
import WarningAmberRoundedIcon from "@mui/icons-material/WarningAmberRounded";
import CloseIcon from "@mui/icons-material/Close";
import { useAppSelector } from "../../../../../../../store/hooks";
import { rightsizingRecommendationsWarningStatusesCountSelector } from "../../../../../../../store/recommendations/selectors/rightsizing/statuses/rightsizingRecommendationsWarningStatusesCountSelector";

interface DrawerHeaderProps {
  onClose(): void;
}

export const DrawerHeader: FC<DrawerHeaderProps> = ({ onClose }) => {
  const count = useAppSelector(
    rightsizingRecommendationsWarningStatusesCountSelector,
  );

  return (
    <Fragment>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        p={2}
      >
        <Stack direction="row" spacing={1} alignItems="center">
          <WarningAmberRoundedIcon color="warning" />
          <Typography fontWeight="medium" color="primary" fontSize={14}>
            WARNING ({count})
          </Typography>
        </Stack>

        <IconButton onClick={onClose} size="small">
          <CloseIcon />
        </IconButton>
      </Stack>
      <Divider />
    </Fragment>
  );
};
