import { FC, useCallback } from "react";
import { useDebounce } from "rooks";
import { Stack } from "@mui/material";
import { WeekDaysSelect } from "../../../../../../../common/WeekDaysSelect";
import { AutomationTimeZone } from "../../../../../common/components/form/schedule-content/AutomationTimeZone";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../store/hooks";
import { workflowSchedulerSelector } from "../../../../../../../../store/automations/selectros/workflow/workflowSchedulerSelector";
import { setWorkflowSpecificData } from "../../../../../../../../store/automations/automationsSlice";
import { automationFrequencySelector } from "../../../../../../../../store/automations/selectros/common/automationFrequencySelector";
import { WorkflowRunTime } from "../WorkflowRunTime";
import { fetchWorkflowNextRunsThunk } from "../../../../../../../../store/automations/thunks/wokrflows/fetchWorkflowNextRunsThunk";
import { WorkflowInterval } from "../WorkflowInterval";

export const WorkflowWeeklyScheduler: FC = () => {
  const dispatch = useAppDispatch();

  const scheduler = useAppSelector(workflowSchedulerSelector);
  const frequency = useAppSelector(automationFrequencySelector);

  const fetchNextRunHandler = useCallback(() => {
    dispatch(fetchWorkflowNextRunsThunk());
  }, [dispatch]);
  const fetchDebounced = useDebounce(fetchNextRunHandler, 500);

  const weekDaysChangeHandler = useCallback(
    (weekDays: number[]) => {
      if (!weekDays.length) {
        return;
      }

      dispatch(
        setWorkflowSpecificData({
          scheduler: { ...scheduler, weekDays },
        }),
      );
      fetchDebounced();
    },
    [dispatch, fetchDebounced, scheduler],
  );

  if (frequency !== "weekly") {
    return null;
  }

  return (
    <Stack flexGrow={1} spacing={2}>
      <WeekDaysSelect
        color="primary"
        weekDays={scheduler?.weekDays ?? []}
        onChange={weekDaysChangeHandler}
      />

      <Stack direction="row" alignItems="center" spacing={1.5}>
        <WorkflowInterval />

        <WorkflowRunTime />

        <AutomationTimeZone />
      </Stack>
    </Stack>
  );
};
