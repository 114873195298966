import { createAsyncThunk } from "@reduxjs/toolkit";
import { getRightsizingAllRecommendationsThunk } from "./rightsizing/getRightsizingAllRecommendationsThunk";
import { getDailyOffHoursRecommendationsThunk } from "./off-hours/data-get/getDailyOffHoursRecommendationsThunk";
import { getWeeklyOffHoursRecommendationsThunk } from "./off-hours/data-get/getWeeklyOffHoursRecommendationsThunk";
import { getOffHoursDismissedResourcesByGroupThunk } from "./off-hours/dismiss/resource/getOffHoursDismissedResourcesByGroupThunk";
import { getRightsizingDismissedResourcesThunk } from "./rightsizing/dismiss/getRightsizingDismissedResourcesThunk";

export const refetchDataAfterActionThunk = createAsyncThunk(
  "recommendations/refetchDataAfterActionThunk",
  async (
    {
      rightsizing,
      computeWeekly,
      dbWeekly,
      computeDaly,
      dbDaily,
      dbDismissedResources,
      computeDismissedResources,
      rightsizingDismissedResources,
    }: {
      rightsizing?: boolean;
      computeDaly?: boolean;
      computeWeekly?: boolean;
      dbDaily?: boolean;
      dbWeekly?: boolean;

      rightsizingDismissedResources?: boolean;
      dbDismissedResources?: boolean;
      computeDismissedResources?: boolean;
    },
    { dispatch },
  ) => {
    const promises = [];

    if (rightsizing) {
      promises.push(dispatch(getRightsizingAllRecommendationsThunk()));
    }

    if (computeDaly) {
      promises.push(
        dispatch(
          getDailyOffHoursRecommendationsThunk({
            group: "compute",
          }),
        ),
        dispatch(
          getDailyOffHoursRecommendationsThunk({
            group: "compute",
            dismissed: true,
          }),
        ),
      );
    }

    if (computeWeekly) {
      promises.push(
        dispatch(
          getWeeklyOffHoursRecommendationsThunk({
            group: "compute",
          }),
        ),
        dispatch(
          getWeeklyOffHoursRecommendationsThunk({
            group: "compute",
            dismissed: true,
          }),
        ),
      );
    }

    if (dbDaily) {
      promises.push(
        dispatch(
          getDailyOffHoursRecommendationsThunk({
            group: "database",
          }),
        ),
        dispatch(
          getDailyOffHoursRecommendationsThunk({
            group: "database",
            dismissed: true,
          }),
        ),
      );
    }

    if (dbWeekly) {
      promises.push(
        dispatch(
          getWeeklyOffHoursRecommendationsThunk({
            group: "database",
          }),
        ),
        dispatch(
          getWeeklyOffHoursRecommendationsThunk({
            group: "database",
            dismissed: true,
          }),
        ),
      );
    }

    if (rightsizingDismissedResources) {
      promises.push(dispatch(getRightsizingDismissedResourcesThunk()));
    }

    if (computeDismissedResources) {
      promises.push(
        dispatch(getOffHoursDismissedResourcesByGroupThunk("compute")),
      );
    }

    if (dbDismissedResources) {
      promises.push(
        dispatch(getOffHoursDismissedResourcesByGroupThunk("database")),
      );
    }

    await Promise.all(promises);
  },
);
