import { FC, useCallback } from "react";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import { useFlag } from "@unleash/proxy-client-react";
import { useAppDispatch, useAppSelector } from "../../../../../store/hooks";
import { commitmentsDataViewTabSelector } from "../../../../../store/commitments/selectors/common/view-options/commitmentsDataViewTabSelector";
import { setCommitmentsDataViewTab } from "../../../../../store/commitments/commitmentsSlice";
import { DataViewTab } from "../../../../../store/commitments/utils/types/types";

export const CommitmentsOverviewRecommendationsDataGridViewTabs: FC = () => {
  const dispatch = useAppDispatch();
  const enableReservationRecommendation = useFlag(
    "EnableReservationRecommendation",
  );
  const tab = useAppSelector(commitmentsDataViewTabSelector);

  const tabChangeHandler = useCallback(
    (_: unknown, tab: string | null) => {
      if (!tab) {
        return;
      }
      dispatch(setCommitmentsDataViewTab(tab as DataViewTab));
    },
    [dispatch],
  );

  return (
    <ToggleButtonGroup
      exclusive
      size="small"
      value={tab}
      color="primary"
      sx={{ borderRadius: 2 }}
      onChange={tabChangeHandler}
    >
      <ToggleButton value="saving_plans" sx={toggleButtonSx}>
        Savings Plans
      </ToggleButton>
      {enableReservationRecommendation && (
        <ToggleButton value="reservation" sx={toggleButtonSx}>
          Reservations
        </ToggleButton>
      )}
    </ToggleButtonGroup>
  );
};

const toggleButtonSx = {
  textTransform: "none",
  borderRadius: 2,
  fontSize: 14,
  px: 2,
};
