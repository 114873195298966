import { createDraftSafeSelector } from "@reduxjs/toolkit";
import { accountsFilterValueSelector } from "./slice-data/accountsFilterValueSelector";
import { accountsSortBySelector } from "./slice-data/accountsSortBySelector";
import { accountsByProviderAndOrgIdSelector } from "./accountsByProviderAndOrgIdSelector";
import { accountsSortingCombiner } from "../combiners/accountsSortingCombiner";
import { Account } from "../../../services/cloudchipr.api";
import { activeAccountStatuses } from "../../../utils/constants/accounts";
import { AccountTabs } from "../../../components/pages/accounts-group/utils/types/types";

export const filteredAccountsConvertedForProviderAccountsPageByOrgIdSelector =
  createDraftSafeSelector(
    [
      accountsByProviderAndOrgIdSelector,
      accountsFilterValueSelector,
      accountsSortBySelector,
    ],
    (accounts, filterValue, sortBy) => {
      if (!accounts) {
        return null;
      }

      const searchValue = filterValue.toLowerCase();

      const filteredAccounts = accounts.filter(
        (account) =>
          account.provider_account_id?.toLowerCase()?.includes(searchValue) ||
          account.provider_account_name?.toLowerCase()?.includes(searchValue),
      );

      const sortedAccounts =
        accountsSortingCombiner(filteredAccounts, sortBy) ?? [];

      return sortedAccounts.reduce(
        (result, account) => {
          const type = activeAccountStatuses.has(account.status)
            ? "active"
            : "inactive";

          result[type].push(account);

          return result;
        },
        { active: [], inactive: [] } as Record<AccountTabs, Account[]>,
      );
    },
  );
