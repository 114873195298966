import { RootState } from "../../../../store";
import { isBillingInProgressStatus } from "../../../utils/helpers/billing-status/isBillingInProgressStatus";
import { BillingAccountStatusWithProvider } from "../../../utils/types/types";
import { getCurrentProvidersByProviderBillingStatusDataSelector } from "../getCurrentProvidersByProviderBillingStatusDataSelector";
import { connectedProvidersSelector } from "../../connectedProvidersSelector";

export const billingInProgressStatusesSelector = (
  state: RootState,
): BillingAccountStatusWithProvider[] => {
  const connectedProviders = connectedProvidersSelector(state);

  return connectedProviders.reduce((result, provider) => {
    const data = getCurrentProvidersByProviderBillingStatusDataSelector(
      state,
      provider,
    );
    const billingStatuses = data?.billing_account_statuses ?? [];

    if (!billingStatuses.length && isBillingInProgressStatus(data?.status)) {
      result.push({
        status: data?.status,
        provider,
      });
    }
    billingStatuses?.forEach((item) => {
      if (isBillingInProgressStatus(item.status)) {
        result.push({
          ...item,
          provider,
        });
      }
    });

    return result;
  }, [] as BillingAccountStatusWithProvider[]);
};
