import { createAsyncThunk } from "@reduxjs/toolkit";
import { cloudChiprApi } from "../../services/cloudchipr.api";

export const getProfileThunk = createAsyncThunk(
  "profile/getProfile",
  async (arg, thunkAPI) => {
    return await thunkAPI
      .dispatch(cloudChiprApi.endpoints.getUsersMe.initiate({}))
      .unwrap();
  },
);
