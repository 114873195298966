import { costBreakdownWidgetByIdSelector } from "./costBreakdownWidgetByIdSelector";
import { RootState } from "../../../../../store";
import { DateDataPointNullable } from "../../../../../../services/cloudchipr.api";

export const costBreakdownWidgetFrequencySelector = (
  state: RootState,
  widgetId: string,
): DateDataPointNullable | undefined => {
  const widgetData = costBreakdownWidgetByIdSelector(state, widgetId);

  return widgetData?.date_granularity;
};
