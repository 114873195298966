import { FC } from "react";
import { Stack, Typography } from "@mui/material";
import { TargetSingleResource } from "./single-resource/TargetSingleResource";
import { TargetMultipleResources } from "./multiple-resources/TargetMultipleResources";
import { useAppSelector } from "../../../../../../../../../store/hooks";
import { taskManagementTargetResourcesSelector } from "../../../../../../../../../store/task-management/selectors/form/target/resources/taskManagementTargetResourcesSelector";

export const ResourcesSectionAsTarget: FC = () => {
  const resources = useAppSelector(taskManagementTargetResourcesSelector);

  if (!resources) {
    return null;
  }

  return (
    <Stack spacing={1} overflow="auto" width="100%" height="100%">
      <Typography fontWeight="medium">
        Resource{resources.length > 1 ? "s" : ""} Details
      </Typography>

      {resources.length === 1 ? (
        <TargetSingleResource />
      ) : (
        <TargetMultipleResources />
      )}
    </Stack>
  );
};
