import { FC } from "react";
import { FormControl, IconButton, Stack, Typography } from "@mui/material";

import { Tune } from "@mui/icons-material";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import { GlobalFilter } from "../../../../storybook/data-grid/toolbar/GlobalFilter";

import { getResourcesExplorerGroupingLabel } from "../../../pages/resource-explorer/components/resource-explorer-card/utils/helpers/getResourcesExplorerGroupingLabel";
import { ResourceExplorerGrouping } from "../../../../services/cloudchipr.api";
import { ResourceExplorerDataGridViewTab } from "../../../pages/resource-explorer/components/resource-explorer-card/components/resource-explorer-data-grid/components/columns-view-tabs/ResourceExplorerDataGridViewTab";

interface InternalResourceExplorerCardDataGridToolbarProps {
  groupBy: ResourceExplorerGrouping;
}

export const InternalResourceExplorerCardDataGridToolbar: FC<
  InternalResourceExplorerCardDataGridToolbarProps
> = ({ groupBy }) => {
  return (
    <Stack direction="row" justifyContent="space-between" spacing={3}>
      <Stack direction="row" paddingY={2} alignItems="center" overflow="hidden">
        <Typography variant="body1" sx={{ pl: 1.5 }}>
          {getResourcesExplorerGroupingLabel(groupBy)}
        </Typography>
      </Stack>
      <Stack direction="row" alignItems="center" spacing={1}>
        <FormControl size="small" sx={{ minWidth: 200 }}>
          <GlobalFilter
            globalFilter=""
            setGlobalFilter={() => {}}
            placeholder="Search"
            size="small"
            fullWidth
          />
        </FormControl>

        <Stack direction="row" bgcolor="grey.200" borderRadius={1} p={0.25}>
          <ResourceExplorerDataGridViewTab
            value="total"
            onChange={() => {}}
            selected
          />
          <ResourceExplorerDataGridViewTab
            value="granular"
            onChange={() => {}}
            selected={false}
          />
        </Stack>

        <IconButton size="small">
          <DownloadOutlinedIcon fontSize="small" />
        </IconButton>

        <IconButton size="small">
          <Tune fontSize="small" />
        </IconButton>
      </Stack>
    </Stack>
  );
};
