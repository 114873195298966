import { FC } from "react";
import { Stack, Typography } from "@mui/material";
import { PlayCircleOutlined, StopCircleOutlined } from "@mui/icons-material";

interface OffHoursSchedulerFieldLabelProps {
  title: string;
  type: "stop" | "start";
}

export const OffHoursSchedulerFieldLabel: FC<
  OffHoursSchedulerFieldLabelProps
> = ({ title, type }) => {
  return (
    <Stack direction="row" alignItems="center" spacing={0.5}>
      {type === "stop" ? (
        <StopCircleOutlined color="action" fontSize="small" />
      ) : (
        <PlayCircleOutlined color="action" fontSize="small" />
      )}

      <Typography variant="body2">{title}</Typography>
    </Stack>
  );
};
