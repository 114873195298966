import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useLocalStorageHook } from "../../../utils/hooks/useLocalStorage.hook";
import { useAppSelector } from "../../../store/hooks";
import { currentUserIdSelector } from "../../../store/profile/profileSelectors";

export const useUserRoute = (update: boolean) => {
  const userId = useAppSelector(currentUserIdSelector);

  const { getItem, setItem } = useLocalStorageHook();
  const key = `c8r:${userId}-user-last-visited-page`;

  const location = useLocation();
  const pathName = location.pathname;

  useEffect(() => {
    if (!update || pathsToIgnore.includes(pathName)) {
      return;
    }

    setItem(key, pathName);
  }, [setItem, pathName, key, update]);

  const url = getItem(key);

  return url === "/" ? null : url;
};

const pathsToIgnore = ["/settings/profile"];
