import { reportsDataSelector } from "./reportsDataSelector";
import { reportByIdSelector } from "./reportByIdSelector";
import { RootState } from "../../store";
import { Report } from "../../../services/cloudchipr.api";
import { isDeepEqual } from "../../../utils/is-deep-equal";
import { sortAlphabeticallyCallback } from "../../../utils/helpers/sorting";

export const isReportDataChangedSelector = (state: RootState) => {
  const reportData = reportsDataSelector(state);
  if (!reportData.id) {
    return false;
  }

  const report: Report | undefined = reportByIdSelector(state, reportData.id);

  if (!report) {
    return false;
  }

  return (
    reportData.name.trim() !== report.name?.trim() ||
    reportData.source_type !== report.source_type ||
    reportData.source_id !== report.source_id ||
    !isDeepEqual(
      reportData.export_file_types?.toSorted(sortAlphabeticallyCallback) ?? [],
      report.export_file_types?.toSorted(sortAlphabeticallyCallback) ?? [],
    ) ||
    reportData.time_zone !== report.time_zone ||
    !isDeepEqual(reportData.schedule ?? {}, report.schedule ?? {}) ||
    !isDeepEqual(reportData?.emails ?? {}, report.emails ?? {}) ||
    !isDeepEqual(reportData.notifications ?? {}, report.notifications ?? {})
  );
};
