import { FilterFn } from "@tanstack/react-table";
import { normalizeString } from "./common";

export const defaultFilter: FilterFn<any> = (row, columnId, value) => {
  return commonDefaultFilter(columnId, value, row.getValue(columnId));
};

export const commonDefaultFilter = (
  columnId: string,
  value: string,
  data: string,
) => {
  return normalizeString(data)?.includes(normalizeString(value));
};
