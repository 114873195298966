import { FC, useCallback } from "react";
import { DateSelectionContent } from "./DateSelectionContent";
import { AutomationDatesLabelWrapper } from "./AutomationDatesLabelWrapper";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../store/hooks";
import { automationEndDateSelector } from "../../../../../../../../store/automations/selectros/common/fields/automationEndDateSelector";
import { setAutomationData } from "../../../../../../../../store/automations/automationsSlice";
import { NullableDate } from "../../../../../../../../services/cloudchipr.api";
import { getEndsOnDate } from "../../../../../../schedule/common/utils/constants/common";
import { automationTimeZoneSelector } from "../../../../../../../../store/automations/selectros/common/fields/automationTimeZoneSelector";
import { automationFrequencySelector } from "../../../../../../../../store/automations/selectros/common/automationFrequencySelector";
import { fetchWorkflowNextRunsThunk } from "../../../../../../../../store/automations/thunks/wokrflows/fetchWorkflowNextRunsThunk";

export const AutomationEndDateSelection: FC = () => {
  const dispatch = useAppDispatch();

  const frequency = useAppSelector(automationFrequencySelector);
  const timeZone = useAppSelector(automationTimeZoneSelector);
  const endDate = useAppSelector(automationEndDateSelector);

  const endDateChangeHandler = useCallback(
    (endDate: NullableDate) => {
      dispatch(setAutomationData({ endDate }));
      dispatch(fetchWorkflowNextRunsThunk());
    },
    [dispatch],
  );

  if (frequency === "once") {
    return null;
  }

  return (
    <AutomationDatesLabelWrapper label="Ends:">
      <DateSelectionContent
        labels={labels}
        selectedDate={endDate}
        onChange={endDateChangeHandler}
        dateDefaultValue={getEndsOnDate(timeZone)}
      />
    </AutomationDatesLabelWrapper>
  );
};

const labels = {
  none: "Never Ends",
  specific: "Ends On",
};
