import { ColDef, type ICellRendererParams } from "@ag-grid-community/core";
import {
  ResourceTarget,
  ResourceType,
} from "../../../../../../../../../../services/cloudchipr.api";
import { resourceIdentifierLabels } from "../../../../../../../../../../utils/constants/resources/resourceTypeNames/resourceIdentifierLabels";
import { TargetSingleResourceTableIdentifierRow } from "../single-resource/TargetSingleResourceTableIdentifierRow";
import { money } from "../../../../../../../../../../utils/numeral/money";
import { TargetSingleResourceTableIStateRow } from "../single-resource/TargetSingleResourceTableIStateRow";
import { TagCell } from "../../../../../../../../../common/data-grid-cells/tag-cell/TagCell";
import { getResourceTypeName } from "../../../../../../../../../../utils/helpers/resources/getResourceTypeName";
import { ProviderIcon } from "../../../../../../../../../common/ProviderIcon";
import { CreatedByCell } from "../../../../../../../../live-usage-mgmt/components/resource-card/resource-card-content/resource-card-data/resource-card-grid/resouce-card-data-grid/cells/created-by-cell/CreatedByCell";

export const getTargetMultipleResourcesGridColumns = (
  resourceType: ResourceType,
  edit?: boolean,
) => {
  const columns: ColDef[] = [
    {
      field: "provider_identifier",
      unSortIcon: true,
      minWidth: 300,
      headerName: resourceIdentifierLabels.get(resourceType) ?? "Instance ID",
      cellRenderer: ({ data }: ICellRendererParams<ResourceTarget>) => {
        if (!data) {
          return null;
        }

        return (
          <TargetSingleResourceTableIdentifierRow
            showOnlyOnHover
            resource={data}
          />
        );
      },
    },
    {
      field: "price_per_month",
      unSortIcon: true,
      minWidth: 100,
      headerName: "Monthly Price",
      cellRenderer: ({ data }: ICellRendererParams<ResourceTarget>) => {
        if (!data) {
          return null;
        }

        return money(data.price_per_month);
      },
    },
    {
      minWidth: 100,
      field: "creation_data",
      unSortIcon: true,
      headerName: "Created By",
      cellRenderer: ({ data }: ICellRendererParams<ResourceTarget>) => {
        if (!data) {
          return null;
        }

        return <CreatedByCell data={data.creation_data} />;
      },
    },
  ];

  if (edit) {
    columns.push({
      minWidth: 100,
      field: "state",
      unSortIcon: true,
      headerName: "State",
      cellRenderer: ({ data }: ICellRendererParams<ResourceTarget>) => {
        if (!data) {
          return null;
        }

        return <TargetSingleResourceTableIStateRow state={data.state} />;
      },
    });
  }

  columns.push(
    {
      field: "region",
      minWidth: 100,
      unSortIcon: true,
      headerName: "Region",
    },
    {
      field: "resource_type",
      minWidth: 100,
      unSortIcon: true,
      headerName: "Resource Type",
      cellRenderer: ({ data }: ICellRendererParams<ResourceTarget>) => {
        if (!data) {
          return null;
        }

        return getResourceTypeName(data.resource_type, {
          singular: true,
          type: "long",
        });
      },
    },
    {
      field: "account",
      minWidth: 100,
      unSortIcon: true,
      headerName: "Cloud Provider",
      cellRenderer: ({ data }: ICellRendererParams<ResourceTarget>) => {
        if (!data) {
          return null;
        }

        return <ProviderIcon provider={data.account.provider} />;
      },
    },
    {
      field: "tags",
      minWidth: 100,
      unSortIcon: true,
      headerName: "Tags",
      cellRenderer: ({ data }: ICellRendererParams<ResourceTarget>) => {
        if (!data) {
          return null;
        }

        return data.tags?.length ? <TagCell tags={data.tags} /> : "-";
      },
    },
    {
      // column to fit rest place
      flex: 1,
      colId: "toFitEmptySpace",
      headerName: "",
      sortable: false,
      cellDataType: false,
      resizable: false,
      suppressMovable: true,
      suppressColumnsToolPanel: true,
    },
  );

  return columns;
};
