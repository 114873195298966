import { newCreatedPropId } from "../../../../components/pages/common/task-management/utils/constants/common";

export const removeIdFromNewCreatedProperties = <T extends { id: string }>(
  properties: T[],
): T[] => {
  return properties.map((property) => {
    if (property.id.startsWith(newCreatedPropId)) {
      return { ...property, id: null };
    }

    return property;
  });
};
