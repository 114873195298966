export const generateTableFromTheJson = (
  jsonData: Record<string, string | number>[],
) => {
  if (!jsonData?.at(0)) {
    return null;
  }

  const container = document.createElement("p");
  const table = document.createElement("table");
  const thead = document.createElement("thead");
  const tr = document.createElement("tr");

  const cols = Object.keys(jsonData[0]);

  cols.forEach((item) => {
    const th = document.createElement("th");
    th.innerText = item;

    tr.appendChild(th);
  });

  thead.appendChild(tr);
  table.append(tr);

  jsonData.forEach((item) => {
    const tr = document.createElement("tr");
    const vals = Object.values(item);

    vals.forEach((elem) => {
      const td = document.createElement("td");
      td.innerText = `${elem}`;
      tr.appendChild(td);
    });
    table.appendChild(tr);
  });

  container.appendChild(table);

  return container;
};
