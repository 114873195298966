import { RightsizingRecommendationsAdvancedFilters } from "../../../../../../../store/recommendations/utils/types/types";
import { RightsizingAdvancedFilterType } from "../../../../../../../store/recommendations/utils/types/rightsizingAdvancedFilterType";

export const getModifiedAdvancedFilterPreferencesJson = (
  preferences: RightsizingRecommendationsAdvancedFilters,
  filterType: RightsizingAdvancedFilterType,
) => {
  const modifiedPreferences = {
    displayRecommendationsAcrossInstanceFamily:
      preferences.displayRecommendationsAcrossInstanceFamily ?? true,
    multipleRecommendationsPerResource:
      preferences.multipleRecommendationsPerResource ?? true,
    [filterType]: !preferences[filterType],
  };

  return JSON.stringify(modifiedPreferences);
};
