import { isTreeNodeFilterGroup } from "./isTreeNodeFilterGroup";
import { ResourceExplorerFilterProvider } from "../../../../../../services/cloudchipr.api";
import { FilterTreeNodeWithId } from "../types/common";

export const findFilterProviderInTree = (
  filters: FilterTreeNodeWithId[],
): ResourceExplorerFilterProvider | undefined => {
  const first = filters?.at(0);

  if (!first) {
    return;
  }

  if (isTreeNodeFilterGroup(first)) {
    return findFilterProviderInTree(first.items);
  }

  return first.filter_provider;
};
