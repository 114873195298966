import { FC } from "react";
import { Stack, Typography } from "@mui/material";
import { AddDimensionButton } from "./AddDimensionButton";
import EmptyStateImage from "../../../../assets/images/empty_categories_state.svg";

export const DimensionsEmptyState: FC = () => {
  return (
    <Stack alignItems="center" mt={12}>
      <img src={EmptyStateImage} alt="EmptyStateImage" />
      <Typography
        component="h5"
        variant="h5"
        fontWeight="bold"
        textAlign="center"
        mt={5}
      >
        You have not created any dimensions yet!
      </Typography>
      <Stack alignItems="center" maxWidth={520} spacing={4}>
        <Typography variant="body1" textAlign="center" mt={2}>
          Once you define dimensions based on multiple categories Cloudchipr
          will create a chart broken down by categories.
        </Typography>

        <AddDimensionButton />
      </Stack>
    </Stack>
  );
};
