import { FC, useCallback, useMemo } from "react";
import { IconButton, Stack, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { LoadingButton } from "@mui/lab";
import { setTaskManagementProperties } from "../../../../../../../../store/task-management/taskManagementSlice";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../store/hooks";
import { taskAvailablePropertiesSelector } from "../../../../../../../../store/task-management/selectors/properties/taskAvailablePropertiesSelector";
import { taskManagementPropertiesEditingLoadingSelector } from "../../../../../../../../store/task-management/selectors/properties/edit/loadings/taskManagementPropertiesEditingLoadingSelector";
import { updateTaskPropertiesThunk } from "../../../../../../../../store/task-management/thunks/properties/updateTaskPropertiesThunk";
import { taskManagementEditingPropertiesSelector } from "../../../../../../../../store/task-management/selectors/properties/edit/taskManagementEditingPropertiesSelector";
import { taskManagementGetTasksThunk } from "../../../../../../../../store/task-management/thunks/actions/taskManagementGetTasksThunk";

interface TaskPropertiesSettingsDrawerHeaderProps {
  onClose(): void;
}

export const TaskPropertiesSettingsDrawerHeader: FC<
  TaskPropertiesSettingsDrawerHeaderProps
> = ({ onClose }) => {
  const dispatch = useAppDispatch();

  const editingProperties = useAppSelector(
    taskManagementEditingPropertiesSelector,
  );
  const availableProperties = useAppSelector(taskAvailablePropertiesSelector);
  const editLoading = useAppSelector(
    taskManagementPropertiesEditingLoadingSelector,
  );

  const disabled = useMemo(() => {
    return Object.values(editingProperties)
      .flat()
      .some((property) => !property.name);
  }, [editingProperties]);

  const closeHandler = useCallback(() => {
    if (availableProperties) {
      dispatch(setTaskManagementProperties(availableProperties));
    }

    onClose();
  }, [dispatch, onClose, availableProperties]);

  const saveHandler = useCallback(async () => {
    await dispatch(updateTaskPropertiesThunk());
    await dispatch(taskManagementGetTasksThunk());
    onClose();
  }, [dispatch, onClose]);

  return (
    <Stack
      p={1.5}
      top={0}
      bgcolor="white"
      zIndex="drawer"
      direction="row"
      position="sticky"
      borderBottom={1}
      alignItems="center"
      borderColor="grey.300"
      justifyContent="space-between"
    >
      <Typography fontWeight="medium">Edit Properties</Typography>

      <Stack direction="row" spacing={1} alignItems="center">
        <LoadingButton
          size="small"
          variant="contained"
          loading={editLoading}
          onClick={saveHandler}
          disabled={disabled}
        >
          Save
        </LoadingButton>

        <IconButton sx={{ p: 0.25 }} size="small" onClick={closeHandler}>
          <CloseIcon fontSize="small" />
        </IconButton>
      </Stack>
    </Stack>
  );
};
