import { createAsyncThunk } from "@reduxjs/toolkit";
import { WidgetType } from "../../../../../services/cloudchipr.api";
import { scrollPageToBottom } from "../../../../../components/pages/utils/helpers/page-scrolling-functions";
import { duplicateCostAndUsageSumWidgetThunk } from "../cost-and-usage/actions/duplicateCostAndUsageSumWidgetThunk";
import { duplicateLargesCostChangesWidgetThunk } from "../largest-cost-changes/actions/duplicateLargesCostChangesWidgetThunk";
import { duplicateCommitmentUtilizationWidgetThunk } from "../commitment-utilization/actions/duplicateCommitmentUtilizationWidgetThunk";
import { duplicateCostBreakdownWidgetThunk } from "../cost-breakdown/actions/duplicateCostBreakdownWidgetThunk";

interface EditWidgetThunkArgs {
  widgetId: string;
  widgetType: WidgetType;
  widgetName: string;
}

export const duplicateWidgetThunk = createAsyncThunk(
  "dashboards/duplicateWidgetThunk",
  async (
    { widgetId, widgetType, widgetName }: EditWidgetThunkArgs,
    { dispatch },
  ) => {
    let response;

    if (widgetType === "cost_breakdown") {
      response = await dispatch(
        duplicateCostBreakdownWidgetThunk({ widgetId, widgetName }),
      ).unwrap();
    }

    if (widgetType === "largest_cost_changes") {
      response = await dispatch(
        duplicateLargesCostChangesWidgetThunk({ widgetId, widgetName }),
      ).unwrap();
    }

    if (widgetType === "cost_and_usage_summary") {
      response = await dispatch(
        duplicateCostAndUsageSumWidgetThunk({ widgetId, widgetName }),
      ).unwrap();
    }

    if (widgetType === "commitments_utilization") {
      response = await dispatch(
        duplicateCommitmentUtilizationWidgetThunk({ widgetId, widgetName }),
      ).unwrap();
    }

    if (response?.id) {
      scrollPageToBottom();
    }
  },
);
