import { isRejectedWithValue, Middleware } from "@reduxjs/toolkit";

export const rtkQueryErrorLoggerMiddleware: Middleware =
  () => (next) => (action) => {
    if (isRejectedWithValue(action)) {
      console.error({
        status: action.payload?.status,
        message: action.payload?.data?.message || action.error?.message,
        endpoint: action.meta?.arg?.endpointName,
      });
    }

    return next(action);
  };
