import { FC } from "react";
import { Stack } from "@mui/material";
import { CommitmentUtilizationWidgetChartTypeSelect } from "./components/CommitmentUtilizationWidgetChartTypeSelect";
import { CommitmentUtilizationWidgetChartBaseSelect } from "./components/CommitmentUtilizationWidgetChartBaseSelect";
import { CommitmentUtilizationWidgetVisualizationUtilization } from "./components/CommitmentUtilizationWidgetVisualizationUtilization";
import { CommitmentUtilizationWidgetVisualizationCommitment } from "./components/CommitmentUtilizationWidgetVisualizationCommitment";
import { CommitmentUtilizationWidgetVisualizationNetSavings } from "./components/CommitmentUtilizationWidgetVisualizationNetSavings";
import VisualizationIcon from "../../../../../../../../../../../../../assets/images/icons/visualization_icon.svg";
import { WidgetConfigSection } from "../../../../../../common/widget-configurations/WidgetConfigSection";
import {
  ChartType,
  WidgetCommitmentsUtilizationVisualization,
} from "../../../../../../../../../../../../../services/cloudchipr.api";
import { useAppSelector } from "../../../../../../../../../../../../../store/hooks";
import { commitmentUtilizationSetupVisualizationPropertyByKeySelector } from "../../../../../../../../../../../../../store/dashboards/selectors/setups/commitment-utilization/commitmentUtilizationSetupVisualizationPropertyByKeySelector";

export const CommitmentUtilizationWidgetVisualizationSection: FC = () => {
  const chartType = useAppSelector(
    commitmentUtilizationSetupVisualizationPropertyByKeySelector("chart_type"),
  );

  return (
    <WidgetConfigSection
      initialExpanded
      title="Visualization"
      icon={<img src={VisualizationIcon} alt="Visualization Icon" />}
    >
      <Stack spacing={1.5}>
        <CommitmentUtilizationWidgetChartTypeSelect />

        {commitmentUtilizationVisualizationDetailsByChartType
          .get(chartType)
          ?.map((type) => {
            const Component =
              commitmentUtilizationVisualizationDetailsComponentByType.get(
                type,
              );

            if (!Component) {
              return;
            }

            return <Component key={type} />;
          })}
      </Stack>
    </WidgetConfigSection>
  );
};

const commitmentUtilizationVisualizationDetailsByChartType = new Map<
  ChartType,
  (keyof WidgetCommitmentsUtilizationVisualization)[]
>([
  ["stack", ["chart_base_type"]],
  ["horizontal_stack_bar", ["chart_base_type"]],
  ["table", ["utilization", "commitment", "net_savings"]],
]);

const commitmentUtilizationVisualizationDetailsComponentByType = new Map<
  keyof WidgetCommitmentsUtilizationVisualization,
  FC
>([
  ["chart_base_type", CommitmentUtilizationWidgetChartBaseSelect],
  ["utilization", CommitmentUtilizationWidgetVisualizationUtilization],
  ["commitment", CommitmentUtilizationWidgetVisualizationCommitment],
  ["net_savings", CommitmentUtilizationWidgetVisualizationNetSavings],
]);
