import { tasksListDataSelector } from "./tasksListDataSelector";
import { RootState } from "../../../store";

export const tasksListResourceIdsSelector = (
  state: RootState,
): Record<string, boolean> => {
  const tasks = tasksListDataSelector(state);

  return (
    tasks?.reduce(
      (acc, task) => {
        acc[task.target.provider_identifier] = true;
        return acc;
      },
      {} as Record<string, boolean>,
    ) ?? {}
  );
};
