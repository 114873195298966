import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CommonInterface } from "./utils/types/types";
import { ProviderType } from "../../services/cloudchipr.api";

const initialState: CommonInterface = {
  appLoading: false,
  navCollapsed: false,
  billingStatusProcess: {},
};

export const commonSlice = createSlice({
  name: "common",
  initialState,
  reducers: {
    setAppLoading: (state, action: PayloadAction<boolean>) => {
      state.appLoading = action.payload;
    },
    setNavCollapsed: (state, action: PayloadAction<boolean>) => {
      state.navCollapsed = action.payload;
    },
    setBillingStatusProcess: (state, action: PayloadAction<ProviderType>) => {
      state.billingStatusProcess = {
        ...state.billingStatusProcess,
        [action.payload]: true,
      };
    },
    removeBillingStatusByProvider: (
      state,
      action: PayloadAction<ProviderType>,
    ) => {
      state.billingStatusProcess = {
        ...state.billingStatusProcess,
        [action.payload]: false,
      };
    },
  },
});

export const {
  setAppLoading,
  setNavCollapsed,
  setBillingStatusProcess,
  removeBillingStatusByProvider,
} = commonSlice.actions;

export default commonSlice.reducer;
