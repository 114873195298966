import { FC, Fragment } from "react";
import { Chip } from "@mui/material";
import { ScheduleStatus } from "../../../../../../services/cloudchipr.api";

type ChipColorsType = "primary" | "warning" | "error" | "success" | "default";
export type ScheduleStatusStateType =
  | ScheduleStatus
  | "normal"
  | "paused"
  | "complete"
  | "error"
  | "blocked"
  | "";

const colors: Partial<Record<ScheduleStatusStateType, ChipColorsType>> = {
  active: "success",
  inactive: "warning",
  deleted: "error",
};

interface AutomationStatusChipProps {
  status: ScheduleStatusStateType;
}

export const AutomationStatusChip: FC<AutomationStatusChipProps> = ({
  status,
}) => {
  if (!status) {
    return <Fragment>-</Fragment>;
  }

  return (
    <Chip
      sx={{ textTransform: "capitalize" }}
      size="small"
      label={status}
      color={colors[status]}
      variant="outlined"
    />
  );
};
