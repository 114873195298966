import { FC } from "react";
import { Stack } from "@mui/material";
import { useDidMount, useWillUnmount } from "rooks";
import { CreateReportButton } from "./components/CreateReportButton";
import { ReportsDrawer } from "./components/drawer/ReportsDrawer";
import { ReportsTable } from "./components/table/ReportsTable";
import { PageHeader } from "../common/PageHeader";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { getAllReportsThunk } from "../../../store/reports/thunks/getAllReportsThunk";
import { getAllReportsLoadingSelector } from "../../../store/reports/selector/getAllReportsLoadingSelector";
import { resetReportsFilters } from "../../../store/reports/reportsSlice";

export const Reports: FC = () => {
  const dispatch = useAppDispatch();
  const loading = useAppSelector(getAllReportsLoadingSelector);

  useDidMount(() => {
    dispatch(getAllReportsThunk());
  });

  useWillUnmount(() => {
    dispatch(resetReportsFilters());
  });

  return (
    <Stack flexGrow={1} bgcolor="white">
      <PageHeader
        sticky
        title="Subscriptions"
        actions={<CreateReportButton />}
        loading={loading}
      />

      <ReportsTable />

      <ReportsDrawer />
    </Stack>
  );
};
