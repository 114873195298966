import { FC, useCallback, useMemo } from "react";
import { FiltersCountButtonCell } from "../common/FiltersCountButtonCell";
import { CategoryWithCosts } from "../../../../../../services/cloudchipr.api";
import { useAppDispatch } from "../../../../../../store/hooks";
import { setDimensionsState } from "../../../../../../store/dimensions/dimensionsSlice";
import { generateDimensionCategoriesFromCategoriesWithCosts } from "../../../utils/helpers/generateDimensionCategoriesFromCategoriesWithCosts";
import { calculateFilterTreeFiltersCount } from "../../../../common/filters-tree/utils/helpers/calculateFilterTreeFiltersCount";

interface CategoryFiltersCountCellProps {
  dimensionId: string;
  category: CategoryWithCosts;
  categories: CategoryWithCosts[];
}

export const CategoryFiltersCountCell: FC<CategoryFiltersCountCellProps> = ({
  dimensionId,
  category,
  categories,
}) => {
  const dispatch = useAppDispatch();
  const categoryId = category.id;

  const count = useMemo(() => {
    return calculateFilterTreeFiltersCount(category?.filters?.filter_tree);
  }, [category.filters]);

  const buttonClickHandler = useCallback(() => {
    dispatch(
      setDimensionsState({
        dimensionId,
        drawerMode: "categoryEdit",
        dimensionName: "",
        editingCategoryId: categoryId,
        categories:
          generateDimensionCategoriesFromCategoriesWithCosts(categories),
      }),
    );
  }, [dispatch, dimensionId, categories, categoryId]);

  return (
    <FiltersCountButtonCell
      count={count}
      categoryId={categoryId}
      dimensionId={dimensionId}
      onClick={buttonClickHandler}
    />
  );
};
