import { IconButton, Stack, Typography } from "@mui/material";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { ChangeEvent, FC, useCallback } from "react";
import InputAdornment from "@mui/material/InputAdornment";
import { UtilizationAlertsType } from "../../../../../utils/types/types";
import { NumberField } from "../../../../../../../common/NumberField";

interface AlertsUtilizationThresholdsDaysSingleSelectProps {
  index: number;
  amount: string;
  utilizationAlertType: UtilizationAlertsType;
  onDelete?(index: number): void;
  onChange(amount: string, index: number): void;
}

export const AlertsUtilizationThresholdsSingleSelect: FC<
  AlertsUtilizationThresholdsDaysSingleSelectProps
> = ({ amount, utilizationAlertType, onDelete, index, onChange }) => {
  const isCommitmentsExpiration =
    utilizationAlertType === "commitments_expiration";
  const limit = isCommitmentsExpiration ? 365 : 100;

  const handleDelete = useCallback(() => {
    onDelete?.(index);
  }, [onDelete, index]);

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      onChange(event.target.value, index);
    },
    [onChange, index],
  );

  return (
    <Stack direction="row" alignItems="center" mt={1.5}>
      <NumberField
        size="small"
        minValue={1}
        maxValue={limit}
        label={isCommitmentsExpiration ? "Before" : ""}
        sx={{ width: 200 }}
        value={amount}
        onChange={handleChange}
        InputProps={{
          startAdornment: !isCommitmentsExpiration && (
            <InputAdornment position="start">%</InputAdornment>
          ),
        }}
      />
      {isCommitmentsExpiration && (
        <Typography variant="body2" color="text.secondary" ml={1}>
          day
        </Typography>
      )}

      <IconButton size="small" onClick={handleDelete} disabled={!onDelete}>
        <DeleteOutlineOutlinedIcon fontSize="small" />
      </IconButton>
    </Stack>
  );
};
