import { FC } from "react";
import { Stack, Typography } from "@mui/material";
import { TrendChip } from "../../../../../../../../../common/chips/TrendChip";

interface AccountInsightsProps {
  data: number;
}

export const AccountInsightsCell: FC<AccountInsightsProps> = ({ data }) => {
  return (
    <Stack direction="row" alignItems="center">
      {data ? (
        <TrendChip value={data} />
      ) : (
        <Typography fontSize={14} py={0.5} noWrap color="text.secondary">
          No comparison yet
        </Typography>
      )}
    </Stack>
  );
};
