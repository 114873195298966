import { FC, Fragment, ReactNode } from "react";
import { ColumnOrderState, Header } from "@tanstack/react-table";
import { DnDWrapper } from "./DnDWrapper";

interface HeaderDnDWrapperProps {
  wrap: boolean;
  header: Header<any, any>;
  columnOrder: ColumnOrderState;
  setColumnOrder(order: ColumnOrderState): void;
  children?: ReactNode;
}

export const HeaderDnDWrapper: FC<HeaderDnDWrapperProps> = ({
  wrap,
  children,
  header,
  columnOrder,
  setColumnOrder,
}) => {
  if (!wrap) {
    return <Fragment>{children}</Fragment>;
  }

  return (
    <DnDWrapper
      header={header}
      columnOrder={columnOrder}
      setColumnOrder={setColumnOrder}
    >
      {children}
    </DnDWrapper>
  );
};
