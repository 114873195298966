import { createDraftSafeSelector } from "@reduxjs/toolkit";
import { getWeeklyRecommendationComputeSelector } from "../../recommendationWeeklyQuerySelector";
import { OrganisationWeeklyOffHoursRecommendation } from "../../../../../../services/cloudchipr.api";

export const recommendationComputeWeeklyActiveDataSelector =
  createDraftSafeSelector(
    [getWeeklyRecommendationComputeSelector],
    (response): OrganisationWeeklyOffHoursRecommendation[] =>
      response?.data ?? [],
  );
