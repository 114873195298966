import { FC } from "react";
import { AddGcpBillingData } from "./AddGcpBillingData";
import { GcpBillingErrorState } from "./GcpBillingErrorState";
import { useAppSelector } from "../../../../../../../store/hooks";
import { billingStatusByProviderSelector } from "../../../../../../../store/common/selectors/billing-status/billingStatusByProviderSelector";
import { AnalyzingBillingData } from "../AnalyzingBillingData";
import { ProviderType } from "../../../../../../../services/cloudchipr.api";

interface GcpAccessControllerProps {
  provider: ProviderType;
}
export const GcpAccessController: FC<GcpAccessControllerProps> = ({
  provider,
}) => {
  const status = useAppSelector((state) =>
    billingStatusByProviderSelector(state, provider),
  );

  if (status === "missing_setup") {
    return <AddGcpBillingData />;
  }

  if (status === "collecting" || status === "analysing") {
    return <AnalyzingBillingData />;
  }

  if (status === "error" || status === "missing_data") {
    return <GcpBillingErrorState />;
  }

  return null;
};
