import { categoriesDataSelector } from "./categoriesDataSelector";
import { RootState } from "../../store";
import { AccountCategoryWithCost } from "../../../services/cloudchipr.api";

export const groupedCategoriesSelector = (state: RootState) => {
  const categories = categoriesDataSelector(state);

  return (
    categories?.reduce(
      (acc, category) => {
        acc[category.name] = {
          id: category.id,
          categories: acc[category.name]
            ? acc[category.name]?.categories?.concat(category)
            : [category],
        };

        return acc;
      },
      {} as Record<
        string,
        {
          id: string;
          categories: AccountCategoryWithCost[];
        }
      >,
    ) || {}
  );
};
