import { FC, useCallback } from "react";
import { Box, Card, Radio, Skeleton, Stack, Typography } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { green, red } from "@mui/material/colors";
import { money } from "../../../../../../../../utils/numeral/money";

export type ResourceCardStatisticsType = "all" | "filtered";

interface ResourceCardStatisticsCardProps {
  price: number;
  instances: string;
  title: string;
  tooltip?: string;
  selected: boolean;
  loading?: boolean;
  value: ResourceCardStatisticsType;
  onChange(type: ResourceCardStatisticsType): void;
  hideRadioButton?: boolean;
}

export const ResourceCardStatisticsCard: FC<
  ResourceCardStatisticsCardProps
> = ({
  title,
  onChange,
  price,
  value,
  instances,
  selected,
  loading,
  tooltip,
  hideRadioButton,
}) => {
  const changeHandler = useCallback(() => {
    onChange(value);
  }, [value, onChange]);

  return (
    <Card
      variant="outlined"
      onClick={changeHandler}
      sx={{
        pr: 0.5,
        cursor: "pointer",
        bgcolor: selected ? "grey.50" : undefined,
        borderColor: "grey.200",
      }}
    >
      <Stack
        direction="row"
        borderLeft={4}
        alignItems="center"
        justifyContent="space-between"
        borderColor={colorsByValue[value].dark}
      >
        {!hideRadioButton && (
          <Radio
            disableRipple
            checked={selected}
            size="small"
            sx={{ alignSelf: "center" }}
          />
        )}

        <Stack
          flexGrow={1}
          spacing={1}
          direction="row"
          alignItems="baseline"
          p={hideRadioButton ? 1.5 : 0.5}
        >
          {loading ? (
            <Skeleton variant="text" height={24} width={80} />
          ) : (
            <Typography variant="body1" fontWeight="bold" whiteSpace="nowrap">
              {money(price)}
              <Typography
                component="span"
                variant="caption"
                color="text.secondary"
                fontWeight="normal"
              >
                /mo
              </Typography>
            </Typography>
          )}

          <Typography
            variant="caption"
            color="text.secondary"
            fontWeight="bold"
          >
            {title}
          </Typography>

          <Box
            px={1}
            ml={1}
            borderRadius={1}
            alignItems="center"
            bgcolor={colorsByValue[value].light}
          >
            <Typography
              variant="caption"
              color={colorsByValue[value].dark}
              fontWeight="medium"
              noWrap
            >
              {instances}
            </Typography>
          </Box>

          {tooltip && (
            <Tooltip arrow title={tooltip} placement="top">
              <InfoOutlinedIcon
                sx={{ color: "text.secondary" }}
                fontSize="small"
              />
            </Tooltip>
          )}
        </Stack>
      </Stack>
    </Card>
  );
};

const colorsByValue = {
  filtered: {
    dark: red[500],
    light: red[50],
  },
  all: {
    dark: green[500],
    light: green[50],
  },
};
