import { FC, ReactNode } from "react";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { ConfirmationDialog } from "./ConfirmationDialog";

interface DeleteConfirmationDialogProps {
  open: boolean;
  loading?: boolean;
  title: string;
  onClose(): void;
  onDelete(): void;
  content: ReactNode;
}

export const DeleteConfirmationDialog: FC<DeleteConfirmationDialogProps> = ({
  open,
  onClose,
  onDelete,
  title,
  loading,
  content,
}) => {
  return (
    <ConfirmationDialog
      closeIcon
      open={open}
      title={title}
      dialogContent={content}
      CancelButtonProps={{ onClick: onClose }}
      ConfirmButtonProps={{
        color: "error",
        children: "Delete",
        onClick: onDelete,
        loading: loading,
        startIcon: <DeleteOutlineIcon />,
      }}
      onClose={onClose}
    />
  );
};
