import { createAsyncThunk } from "@reduxjs/toolkit";
import { getSavingsPlansForGranularThunk } from "./getSavingsPlansForGranularThunk";
import { getSavingsPlansForDataGridThunk } from "./getSavingsPlansForDataGridThunk";

export const getSavingsPlansThunk = createAsyncThunk(
  "commitments/getSavingsPlans",
  (_: void, { dispatch }) => {
    dispatch(getSavingsPlansForDataGridThunk());
    dispatch(getSavingsPlansForGranularThunk());
  },
);
