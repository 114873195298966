import { useCallback } from "react";
import { ResourceType } from "../../../../../services/cloudchipr.api";
import { useAppSelector } from "../../../../../store/hooks";
import {
  generateStorageKey,
  useLocalStorageHook,
} from "../../../../../utils/hooks/useLocalStorage.hook";

import { liveUsageMgmtAccountIdsSelector } from "../../../../../store/live-usage-mgmt/selectors/store-selectors/liveUsageMgmtAccountIdsSelector";

export function useResourceCardCollapsedState(
  resourceType: ResourceType,
  initialState?: boolean,
) {
  const { getItem, setItem } = useLocalStorageHook();

  const accountIds = useAppSelector(liveUsageMgmtAccountIdsSelector) ?? [];
  const keys = accountIds.map((accountId) => {
    return generateStorageKey({
      accountId,
      resourceType,
      identifier: "resourceCardConfig",
    });
  });
  const config = getItem(keys[0]);
  const initialCollapsed = config?.collapsed || initialState;

  const setCollapsedState = useCallback(
    (collapsed: boolean) => {
      keys.forEach((key) => {
        setItem(key, { ...config, collapsed });
      });
    },
    [keys, setItem, config],
  );

  return {
    collapsedState: initialCollapsed,
    setCollapsedState,
  };
}
