import { FC, useCallback, useState } from "react";
import { IconButton, Stack, Tooltip } from "@mui/material";
import { Table } from "@tanstack/react-table";
import { Tune } from "@mui/icons-material";
import { useSearchParams } from "react-router-dom";
import { useEffectOnceWhen } from "rooks";
import { GlobalFilter } from "../../../../../../storybook/data-grid/toolbar/GlobalFilter";
import { ColumnsSwitcher } from "../../../../../../storybook/data-grid/toolbar/ColumnsSwitcher";
import { useMenuHook } from "../../../../../../utils/hooks/useMenu.hook";

interface ScheduledJobsToolbarProps {
  globalFilter: string | number;
  setGlobalFilter: (value: string) => void;
  table: Table<any>;
}

export const OffHoursDataGridToolbar: FC<ScheduledJobsToolbarProps> = ({
  globalFilter,
  setGlobalFilter,
  table,
}) => {
  const [searchParams] = useSearchParams();
  const searchFromPath = searchParams.get("search") ?? null;
  const [searchValue, setSearchValue] = useState(
    searchFromPath ?? globalFilter ?? "",
  );
  const { anchor, openMenu, closeMenu, open } = useMenuHook();

  const searchChangeHandler = useCallback(
    (keyword: string) => {
      setGlobalFilter(keyword);
      setSearchValue(keyword);
    },
    [setGlobalFilter],
  );

  useEffectOnceWhen(() => {
    searchChangeHandler(searchFromPath ?? "");
  }, !!searchFromPath && !!table);

  return (
    <Stack py={1} px={1.5} justifyContent="flex-end" direction="row">
      <GlobalFilter
        globalFilter={searchValue}
        setGlobalFilter={searchChangeHandler}
        size="xsmall"
      />

      <Tooltip arrow title="Table Settings" placement="top">
        <IconButton onClick={openMenu} size="small" sx={{ ml: 1.5 }}>
          <Tune fontSize="small" />
        </IconButton>
      </Tooltip>

      <ColumnsSwitcher
        open={open}
        onClose={closeMenu}
        anchor={anchor}
        table={table}
      />
    </Stack>
  );
};
