import { RootState } from "../../../../store";
import { cloudChiprApi } from "../../../../../services/cloudchipr.api";
import { deleteDashboardHierarchyItemThunkFixedCacheKey } from "../../../thunks/common/deleteDashboardItemThunk";

const selector =
  cloudChiprApi.endpoints.deleteV2UsersMeOrganisationsCurrentDashboardsByDashboardId.select(
    deleteDashboardHierarchyItemThunkFixedCacheKey,
  );

export const deleteDashboardByIdLoadingSelector = (
  state: RootState,
): boolean => {
  return !!selector(state)?.isLoading;
};
