import { createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../../../../store";

import { setCommitmentsRecommendationsFilters } from "../../../commitmentsSlice";
import { commitmentsDetailsDrawerTargetTypeSelector } from "../../../selectors/common/details-drawer/commitmentsDetailsDrawerTargetTypeSelector";
import {
  lockBackPeriodFilterOptions,
  paymentFilterOptions,
  reservationTypeFilterOptions,
  termFilterOptions,
} from "../../../../../components/pages/commtiments/utilization-and-coverage/components/content/recommendations/filters/utils/contants/filter-options/recommendationsFiltersOptions";
import { reservationsRecommendationsDataGroupedByAccountScopeSelector } from "../../../selectors/recommendations/reservations/reservationsRecommendationsDataGroupedByAccountScopeSelector";

export const setReservationsRecommendationsInitialFiltersThunk =
  createAsyncThunk(
    "commitments/setReservationsRecommendationsInitialFilters",
    async (_: void, { dispatch, getState }) => {
      const state = getState() as RootState;
      const targetType = commitmentsDetailsDrawerTargetTypeSelector(state);

      const recommendations =
        reservationsRecommendationsDataGroupedByAccountScopeSelector(state);

      if (!recommendations) {
        return;
      }
      const isPayer =
        (recommendations?.payer?.length ?? 0) >
        (recommendations?.linked?.length ?? 0);

      const accountIds = isPayer
        ? []
        : recommendations.linked?.reduce((result, item) => {
            const accountId: string = item.account.id;
            if (!result.includes(accountId)) {
              result.push(accountId);
            }
            return result;
          }, [] as string[]);

      dispatch(
        setCommitmentsRecommendationsFilters({
          type: targetType === "resource_type" ? null : typeFilterOptions,
          payment_option: paymentOptions,
          look_back_period: lockBackPeriodOptions,
          term_in_years: termOptions,
          account_scope: isPayer ? "payer" : "linked",
          account_ids: accountIds ?? [],
        }),
      );
    },
  );

const lockBackPeriodOptions = lockBackPeriodFilterOptions.map(
  (item) => item.value,
);
const typeFilterOptions = reservationTypeFilterOptions.map(
  (item) => item.value,
);
const termOptions = termFilterOptions.map((item) => item.value);
const paymentOptions = paymentFilterOptions.map((item) => item.value);
