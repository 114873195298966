import { FC, useCallback } from "react";
import {
  Box,
  Button,
  Divider,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import CloseIcon from "@mui/icons-material/Close";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { useNavigate } from "react-router-dom";
import {
  AutomationStatusChip,
  ScheduleStatusStateType,
} from "../../grid/AutomationStatusChip";
import { useAppAbility } from "../../../../../../../services/permissions";
import { TypographyWithTooltip } from "../../../../../../common/TypographyWithTooltip";

interface ResourcesPreviewHeaderProps {
  title: string;
  editingUrl?: string;
  status?: ScheduleStatusStateType;
  onClose(): void;
}

export const ResourcesPreviewHeader: FC<ResourcesPreviewHeaderProps> = ({
  status,
  onClose,
  title,
  editingUrl,
}) => {
  const { cannot } = useAppAbility();
  const canNotEditSchedule = cannot("edit", "schedule");

  const navigate = useNavigate();

  const editHandler = useCallback(() => {
    editingUrl && navigate(editingUrl);
  }, [navigate, editingUrl]);

  return (
    <Box top={0} position="sticky" zIndex={(theme) => theme.zIndex.appBar + 1}>
      <Stack
        p={2}
        direction="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <Stack direction="row" spacing={2} alignItems="center">
          <FilterAltIcon color="action" />

          <Typography variant="h6" fontWeight="normal">
            Found Resources{title && ": "}
          </Typography>

          <TypographyWithTooltip
            maxWidth={500}
            title={title}
            fontWeight="bold"
            variant="h6"
          />

          {status && <AutomationStatusChip status={status} />}
        </Stack>

        <Stack direction="row" spacing={2} alignItems="center">
          {editingUrl && (
            <Button
              size="small"
              variant="outlined"
              onClick={editHandler}
              disabled={canNotEditSchedule}
              startIcon={<EditOutlinedIcon />}
            >
              Edit
            </Button>
          )}

          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Stack>
      </Stack>

      <Divider />
    </Box>
  );
};
