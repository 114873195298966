import { costBreakdownWidgetDataByWidgetIdSelector } from "./costBreakdownWidgetDataByWidgetIdSelector";
import { costBreakdownWidgetFrequencySelector } from "./costBreakdownWidgetFrequencySelector";
import { RootState } from "../../../../../store";
import { WidgetCosts } from "../../../../utils/types/types";
import { generateCostBreakdownWidgetCosts } from "../../../../utils/helpers/costBreakdown/generateCostBreakdownWidgetCosts";

export const costBreakdownWidgetCostsSelector = (
  state: RootState,
  widgetId: string,
): WidgetCosts | undefined => {
  const widgetData = costBreakdownWidgetDataByWidgetIdSelector(state, widgetId);
  const frequency = costBreakdownWidgetFrequencySelector(state, widgetId);

  return generateCostBreakdownWidgetCosts(widgetData, frequency);
};
