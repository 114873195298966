import { createAsyncThunk } from "@reduxjs/toolkit";
import { setAlertsData } from "../../alertsSlice";

import { alertsDrawerEditModeSelector } from "../../selectors/alertsDrawerEditModeSelector";
import { RootState } from "../../../store";
import { defaultResourceExplorerViewIdSelector } from "../../../resource-explorer/selectors/all-resource-explorers/defaultResourceExplorerViewIdSelector";

export const initiateCostAnomalyAlertThunk = createAsyncThunk(
  "alert/initiateCostAnomalyAlert",
  (_, { dispatch, getState }) => {
    const state = getState() as RootState;
    const editMode = alertsDrawerEditModeSelector(state);
    const defaultViewId = defaultResourceExplorerViewIdSelector(state);

    dispatch(
      setAlertsData({
        threshold: {
          operator: "or",
          threshold_items: [
            {
              unit: "percent",
              amount: "1",
            },
          ],
        },
      }),
    );

    if (!editMode && defaultViewId) {
      dispatch(setAlertsData({ viewId: defaultViewId }));
    }
  },
);
