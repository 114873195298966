import { FC, ReactElement, ReactNode } from "react";
import { Breadcrumbs as MuiBreadcrumbs, Typography } from "@mui/material";
import { NestedItems } from "./NestedItems";
import { BreadcrumbsItem } from "./BreadcrumbsItem";

export type BreadcrumbsType = {
  title: ReactNode;
  to?: string;
  icon?: ReactElement;
  nested?: Omit<BreadcrumbsType, "nested">[];
};

interface BreadcrumbsProps {
  breadcrumbs: BreadcrumbsType[];
}

export const Breadcrumbs: FC<BreadcrumbsProps> = ({ breadcrumbs }) => {
  return (
    <MuiBreadcrumbs
      separator={
        <Typography variant="body1" color="text.secondary">
          /
        </Typography>
      }
    >
      {breadcrumbs.map(({ to, title, icon, nested }) => {
        if (nested?.length) {
          return <NestedItems key={to} items={nested} title={title} />;
        }

        return (
          <BreadcrumbsItem
            key={title?.toString()}
            title={title}
            to={to}
            icon={icon}
          />
        );
      })}
    </MuiBreadcrumbs>
  );
};
