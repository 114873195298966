import { useCallback } from "react";
import { useDidMount } from "rooks";
import {
  ProviderType,
  usePatchUsersMeCurrentPreferenceByKeyMutation,
  usePostUsersMeCurrentPreferenceMutation,
} from "../../../../../services/cloudchipr.api";
import { AccountTabs } from "../../../accounts-group/utils/types/types";
import { AccountSortByFields } from "../../../../../store/accounts/accountsSlice";
import { useAppDispatch, useAppSelector } from "../../../../../store/hooks";
import { getUsersPreferenceByKeyThunk } from "../../../../../store/user-preference/thunks/getUsersPreferenceByKeyThunk";
import { getUsersPreferenceByKeySelector } from "../../../../../store/user-preference/selectors/getUsersPreferenceByKeySelector";

export function useAccountsSortBy(
  provider: ProviderType,
  type: AccountTabs,
  orgId?: string,
) {
  const dispatch = useAppDispatch();
  const sortByKey = getAccountsSortBySettingsKey(provider, type, orgId);

  const [createOrdering] = usePostUsersMeCurrentPreferenceMutation();
  const [updateOrdering] = usePatchUsersMeCurrentPreferenceByKeyMutation();

  const accountSortBy = useAppSelector((state) =>
    getUsersPreferenceByKeySelector(state, sortByKey),
  );

  useDidMount(() => {
    dispatch(
      getUsersPreferenceByKeyThunk({ key: sortByKey, forceRefetch: true }),
    );
  });

  const saveAccountSortBy = useCallback(
    async (sortBy: AccountSortByFields) => {
      const body = {
        key: sortByKey,
        body: { value: sortBy },
      };

      const res = await dispatch(
        getUsersPreferenceByKeyThunk({ key: sortByKey }),
      ).unwrap();

      if (sortBy === res?.value) {
        return;
      }

      if (res?.value) {
        await updateOrdering(body);
      } else {
        await createOrdering({ body });
      }

      await dispatch(
        getUsersPreferenceByKeyThunk({ key: sortByKey, forceRefetch: true }),
      );
    },
    [updateOrdering, createOrdering, sortByKey, dispatch],
  );

  return {
    accountSortBy: accountSortBy?.value as AccountSortByFields,
    saveAccountSortBy,
  };
}

export const getAccountsSortBySettingsKey = (
  provider: ProviderType,
  type: AccountTabs,
  id?: string,
) => {
  const identifier = id ? `${provider}-${id}` : provider;
  return `c8r:${identifier}:${type}AccountsSortBy`;
};
