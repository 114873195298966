import { FC, RefObject, useCallback, useState } from "react";
import { Button, Stack } from "@mui/material";
import { LockPersonOutlined } from "@mui/icons-material";
import { AgGridReact } from "@ag-grid-community/react";
import { useMenuHook } from "../../../../../../../../utils/hooks/useMenu.hook";
import { CreateRoleDrawer } from "../../create-role-drawer/CreateRoleDrawer";
import { GlobalFilter } from "../../../../../../../../storybook/data-grid/toolbar/GlobalFilter";

interface RolesDataGridToolbarProps {
  gridRef: RefObject<AgGridReact>;
}

export const RolesDataGridToolbar: FC<RolesDataGridToolbarProps> = ({
  gridRef,
}) => {
  const [searchValue, setSearchValue] = useState("");
  const { open, openMenu, closeMenu } = useMenuHook();

  const searchChangeHandler = useCallback(
    (keyword: string) => {
      gridRef.current!.api.setGridOption("quickFilterText", keyword);
      setSearchValue(keyword);
    },
    [gridRef],
  );

  return (
    <Stack alignItems="flex-end" p={1.5}>
      <Stack direction="row" spacing={1}>
        <GlobalFilter
          globalFilter={searchValue}
          setGlobalFilter={searchChangeHandler}
          size="xsmall"
          id="quickFilterText"
        />
        <Button
          variant="outlined"
          startIcon={<LockPersonOutlined />}
          onClick={openMenu}
          size="small"
        >
          add custom role
        </Button>
      </Stack>
      <CreateRoleDrawer open={open} onClose={closeMenu} />
    </Stack>
  );
};
