import { FC } from "react";
import { useSearchParams } from "react-router-dom";
import { useDidMount, useWillUnmount } from "rooks";
import { Box, LinearProgress } from "@mui/material";
import { TaskManagementAppbar } from "./components/appbar/TaskManagementAppbar";
import { TaskManagementProvider } from "./components/TaskManagementProvider";
import { TasksGrid } from "./components/grid/TasksGrid";
import { TasksEmptyPage } from "./components/TasksEmptyPage";
import { FloatingToolbar } from "./components/floating-toolbar/FloatingToolbar";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { taskManagementGetTasksThunk } from "../../../store/task-management/thunks/actions/taskManagementGetTasksThunk";
import { getOrganisationUsersThunk } from "../../../store/user/thunks/getOrganisationUsersThunk";
import { openDrawerForTaskByIdThunk } from "../../../store/task-management/thunks/openDrawerForTaskByIdThunk";
import { tasksListGetLoadingSelector } from "../../../store/task-management/selectors/loading/tasksListGetLoadingSelector";
import { tasksListIsEmptySelector } from "../../../store/task-management/selectors/data/tasksListIsEmptySelector";
import { setTaskManagementSelectedTasks } from "../../../store/task-management/taskManagementSlice";
import { getTaskAvailablePropertiesThunk } from "../../../store/task-management/thunks/properties/getTaskAvailablePropertiesThunk";
import { tasksListGridDataSelector } from "../../../store/task-management/selectors/data/tasksListGridDataSelector";

const TaskManagementComponent: FC = () => {
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const taskId = searchParams.get("taskId");

  const empty = useAppSelector(tasksListIsEmptySelector);
  const loading = useAppSelector(tasksListGetLoadingSelector);
  const allTasks = useAppSelector(tasksListGridDataSelector);

  useDidMount(async () => {
    dispatch(getTaskAvailablePropertiesThunk());
    dispatch(getOrganisationUsersThunk());
    await dispatch(taskManagementGetTasksThunk());

    if (taskId) {
      dispatch(openDrawerForTaskByIdThunk(taskId));
    }
  });

  useWillUnmount(() => {
    dispatch(setTaskManagementSelectedTasks([]));
  });

  if (!allTasks && !loading) {
    return null;
  }

  return (
    <Box bgcolor="white" pb={2} flex={1} position="relative">
      {loading && (
        <Box position="absolute" top={0} left={0} right={0}>
          <LinearProgress />
        </Box>
      )}

      <TaskManagementAppbar />

      {empty ? (
        <TasksEmptyPage />
      ) : (
        <TasksGrid tasks={allTasks ?? []} renderRows singleGroup />
      )}

      <FloatingToolbar />
    </Box>
  );
};

export const TaskManagement: FC = () => {
  return (
    <TaskManagementProvider>
      <TaskManagementComponent />
    </TaskManagementProvider>
  );
};
