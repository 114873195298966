import { FC, useCallback, useMemo } from "react";
import { MenuItem } from "@mui/material";
import {
  CleanActionTypeDetails,
  CleanActionTypes,
} from "../../../../../../../../../../../utils/clean-options";
import { ResourceType } from "../../../../../../../../../../../services/cloudchipr.api";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../../../../store/hooks";
import { useAppAbility } from "../../../../../../../../../../../services/permissions";
import { liveUsageMgmtAccountsVisibilitiesByResourceTypeSelector } from "../../../../../../../../../../../store/live-usage-mgmt/selectors/accounts-data/liveUsageMgmtAccountsVisibilitiesByResourceTypeSelector";
import { liveUsageMgmtResourcesCleanDisabledSelector } from "../../../../../../../../../../../store/live-usage-mgmt/selectors/resource-type-data/liveUsageMgmtResourcesCleanDisabledSelector";
import { liveUsageMgmtSelectedResourcesByResourceTypeSelector } from "../../../../../../../../../../../store/live-usage-mgmt/selectors/resource-type-data/liveUsageMgmtSelectedResourcesByResourceTypeSelector";
import { setCleanV2ActionType } from "../../../../../../../../../../../store/clean-v2/cleanV2Slice";
import { createCleanV2Thunk } from "../../../../../../../../../../../store/clean-v2/thunks/thunks/createCleanV2Thunk";

interface LiveUsageMgmtToolbarCleanActionItemProps {
  item: CleanActionTypeDetails;
  resourceType: ResourceType;
  onClose(): void;
}

export const LiveUsageMgmtToolbarCleanActionItem: FC<
  LiveUsageMgmtToolbarCleanActionItemProps
> = ({ item, resourceType, onClose }) => {
  const resourceVisibility = useAppSelector((state) =>
    liveUsageMgmtAccountsVisibilitiesByResourceTypeSelector(
      state,
      resourceType,
    ),
  );

  const dispatch = useAppDispatch();
  const { cannot } = useAppAbility();
  const canNotCleanResource = cannot("clean", "resource");

  const cleanDisabled = useAppSelector((state) =>
    liveUsageMgmtResourcesCleanDisabledSelector(state, resourceType),
  );
  const selectedResources = useAppSelector((state) =>
    liveUsageMgmtSelectedResourcesByResourceTypeSelector(state, resourceType),
  );

  const selectedResourcesLength = selectedResources?.length;

  const onCleanItemClick = useCallback(
    (type: CleanActionTypes) => () => {
      dispatch(setCleanV2ActionType(type));
      dispatch(createCleanV2Thunk(resourceType));
      onClose();
    },
    [dispatch, resourceType, onClose],
  );

  const isAllResourcesDisabledForSomeReason = useMemo(() => {
    return selectedResources?.every(
      ({ resource }) =>
        resource.is_protected ||
        resource.marked_for_deletion ||
        resource.not_allowed_actions.includes(item.type),
    );
  }, [selectedResources, item.type]);

  const itemDisabled =
    !selectedResourcesLength ||
    canNotCleanResource ||
    isAllResourcesDisabledForSomeReason ||
    cleanDisabled ||
    resourceVisibility?.actions[item.type] === false;

  return (
    <MenuItem
      dense
      key={item.type}
      onClick={onCleanItemClick(item.type)}
      disabled={itemDisabled}
    >
      {item.label}
    </MenuItem>
  );
};
