import { FC, useCallback, useState } from "react";
import { Dialog } from "@mui/material";
import { SnackbarKey, useSnackbar } from "notistack";
import { CleanV2DialogActions } from "./components/CleanV2DialogActions";
import { CleanV2DialogContent } from "./components/dialog-content/CleanV2DialogContent";
import { useAppDispatch, useAppSelector } from "../../../../../store/hooks";
import { DialogTitleClosable } from "../../../../common/dialog-utils/DialogTitleClosable";
import { getResourceTypeName } from "../../../../../utils/helpers/resources/getResourceTypeName";
import { cleanV2OpenStateSelector } from "../../../../../store/clean-v2/selectors/store-selectors/cleanV2OpenStateSelector";
import { cleanV2ActionTypeSelector } from "../../../../../store/clean-v2/selectors/store-selectors/cleanV2ActionTypeSelector";
import { cleanV2ResourcesGridDataSelector } from "../../../../../store/clean-v2/selectors/cleanV2ResourcesGridDataSelector";
import { cleanV2ResourcesThunk } from "../../../../../store/clean-v2/thunks/thunks/cleanV2ResourceThunk";
import { closeCleanV2Thunk } from "../../../../../store/clean-v2/thunks/thunks/closeCleanV2Thunk";
import { getCleanActionOptions } from "../../../../../utils/clean-options";
import { cleanV2DetailsResourceTypeSelector } from "../../../../../store/clean-v2/selectors/store-selectors/cleanV2DetailsResourceTypeSelector";

export const CleanV2Dialog: FC = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const [cleanInputValue, setCleanInputValue] = useState("");
  const resourceType = useAppSelector(cleanV2DetailsResourceTypeSelector);
  const isOpen = useAppSelector(cleanV2OpenStateSelector);
  const cleanType = useAppSelector(cleanV2ActionTypeSelector);
  const data = useAppSelector(cleanV2ResourcesGridDataSelector);
  const dispatch = useAppDispatch();

  const cleanActionOptions = resourceType
    ? getCleanActionOptions(resourceType)
    : undefined;

  const cleanActionType = cleanActionOptions?.find(
    (item) => item.value === cleanType,
  );

  const closeDialogHandler = useCallback(() => {
    dispatch(closeCleanV2Thunk());
    setCleanInputValue("");
  }, [setCleanInputValue, dispatch]);

  const onCleanResourceHandler = useCallback(() => {
    closeDialogHandler();
    if (resourceType) {
      const key: SnackbarKey = enqueueSnackbar(
        `${cleanActionType?.presentContinueLabel} ${getResourceTypeName(
          resourceType,
          {
            singular: data?.length === 1,
          },
        )}`,
        {
          variant: "snackbarAlert",
          AlertSnackBarProps: {
            onClose: () => closeSnackbar(key),
            severity: "info",
            icon: false,
          },
        },
      );
    }
    dispatch(cleanV2ResourcesThunk());
  }, [
    resourceType,
    dispatch,
    cleanActionType,
    closeDialogHandler,
    enqueueSnackbar,
    closeSnackbar,
    data,
  ]);

  if (!resourceType) {
    return null;
  }

  return (
    <Dialog open={isOpen} fullWidth maxWidth="md" onClose={closeDialogHandler}>
      <DialogTitleClosable
        title={`${cleanActionType?.label} ${getResourceTypeName(resourceType, {
          singular: data.length === 1,
        })}?`}
        onClose={closeDialogHandler}
      />

      <CleanV2DialogContent
        cleanInputValue={cleanInputValue}
        onCleanInputChange={setCleanInputValue}
        actionType={cleanActionType?.label.toLowerCase() ?? ""}
      />

      <CleanV2DialogActions
        onClean={onCleanResourceHandler}
        onCancel={closeDialogHandler}
        disabled={
          !cleanInputValue.length ||
          cleanInputValue !== cleanActionType?.label.toLowerCase()
        }
      />
    </Dialog>
  );
};
