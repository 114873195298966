import { createAsyncThunk } from "@reduxjs/toolkit";
import { userRoleCreationFixedCacheKey } from "./createCustomUserRoleThunk";
import {
  cloudChiprApi,
  PermissionName,
} from "../../../../services/cloudchipr.api";

interface PostCustomUserRoleThunkArgs {
  name: string;
  description?: string | null;
  permissions: PermissionName[];
}

export const postCustomUserRoleThunk = createAsyncThunk(
  "roles/postCustomUserRole",
  async (
    { name, description, permissions }: PostCustomUserRoleThunkArgs,
    { dispatch, getState },
  ) => {
    return await dispatch(
      cloudChiprApi.endpoints.postUsersMeOrganisationsCurrentRoles.initiate(
        {
          body: {
            name,
            description: description?.length !== 0 ? description : null,
            permissions,
          },
        },
        {
          fixedCacheKey: userRoleCreationFixedCacheKey,
        },
      ),
    ).unwrap();
  },
);
