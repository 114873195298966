import { costAndUsageSumWidgetSetupSelector } from "./costAndUsageSumWidgetSetupSelector";
import { RootState } from "../../../../store";
import { GetUsersMeOrganisationsCurrentWidgetsCostAndUsageSummaryApiArg } from "../../../../../services/cloudchipr.api";

export const costAndUsageSumSetupWidgetPayloadDataSelector = (
  state: RootState,
):
  | GetUsersMeOrganisationsCurrentWidgetsCostAndUsageSummaryApiArg
  | undefined => {
  const setup = costAndUsageSumWidgetSetupSelector(state);

  if (!setup?.viewId) {
    return;
  }

  return {
    viewId: setup.viewId,
    dateLabel: setup.dates.label ?? undefined,
    dateTo: setup.dates.to ?? undefined,
    dateFrom: setup.dates.from ?? undefined,
    dateType: setup.dates.type ?? undefined,
    dateUnit: setup.dates.unit ?? undefined,
    dateValue: setup.dates.value ?? undefined,
  };
};
