import { createDraftSafeSelector } from "@reduxjs/toolkit";
import { tasksListDataSelector } from "./tasksListDataSelector";
import { Task } from "../../../../services/cloudchipr.api";
import { taskManagementShowCompletedTasksSelector } from "../list/taskManagementShowCompletedTasksSelector";

export const tasksListGridDataSelector = createDraftSafeSelector(
  [tasksListDataSelector, taskManagementShowCompletedTasksSelector],
  (tasks, showCompletedTasks): Task[] | undefined => {
    if (!tasks) {
      return;
    }

    if (showCompletedTasks) {
      return tasks;
    }

    const notAllCompleted = tasks.some(
      (task) => task.status.category !== "completed",
    );

    if (!notAllCompleted) {
      return tasks;
    }

    return tasks.filter((task) => task.status.category !== "completed");
  },
);
