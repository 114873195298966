import { FC, useCallback } from "react";
import moment from "moment";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../store/hooks";
import { automationFrequencySelector } from "../../../../../../../store/automations/selectros/common/automationFrequencySelector";
import {
  setAutomationData,
  setOffHoursSpecificData,
  setWorkflowSpecificData,
} from "../../../../../../../store/automations/automationsSlice";
import { AutomationFrequencyType } from "../../../../../../../store/automations/utils/types/common";
import {
  offHoursInitialData,
  workflowInitialData,
} from "../../../../../../../store/automations/utils/constants/common";
import { automationTypeSelector } from "../../../../../../../store/automations/selectros/common/automationTypeSelector";
import { fetchWorkflowNextRunsThunk } from "../../../../../../../store/automations/thunks/wokrflows/fetchWorkflowNextRunsThunk";

interface AutomationFrequencyProps {
  frequencies: AutomationFrequencyType[];
}

export const AutomationFrequency: FC<AutomationFrequencyProps> = ({
  frequencies,
}) => {
  const dispatch = useAppDispatch();
  const frequency = useAppSelector(automationFrequencySelector);
  const automationType = useAppSelector(automationTypeSelector);

  const changeHandler = useCallback(
    (_: unknown, frequency: unknown) => {
      if (!frequency) {
        return;
      }

      dispatch(
        setAutomationData({
          frequency: frequency as AutomationFrequencyType,
          startDate: null,
          endDate: null,
        }),
      );

      const weekDays =
        frequency === "weekly" ? [moment().isoWeekday() + 1] : undefined;

      if (automationType === "offHours") {
        dispatch(
          setOffHoursSpecificData({
            scheduler: {
              ...offHoursInitialData.scheduler,
              startWeekDays: weekDays,
              stopWeekDays: weekDays,
            },
          }),
        );
      } else {
        dispatch(
          setWorkflowSpecificData({
            scheduler: { ...workflowInitialData.scheduler, weekDays },
          }),
        );
      }

      dispatch(fetchWorkflowNextRunsThunk());
    },
    [dispatch, automationType],
  );

  return (
    <ToggleButtonGroup
      exclusive
      size="small"
      color="primary"
      value={frequency}
      onChange={changeHandler}
    >
      {frequencies.map((frequency) => {
        return (
          <ToggleButton
            key={frequency}
            value={frequency}
            sx={{
              px: 2,
              textTransform: "capitalize",
              borderColor: "primary.light",
            }}
          >
            {frequency}
          </ToggleButton>
        );
      })}
    </ToggleButtonGroup>
  );
};
