import { FC, Fragment, memo, useCallback } from "react";
import { Box, Drawer, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import { ACCOUNT_DRAWER_WIDTH } from "../../../../../utils/constants/style-sizes";
import { ProviderType } from "../../../../../services/cloudchipr.api";
import { accountInfoDrawerContent } from "../../../../../utils/helpers/accountInfoDrawerContent";
interface AccountInfoDrawerProps {
  open: boolean;
  onChangeDrawerState: (state: boolean) => void;
  provider: ProviderType;
}

export const AccountInfoDrawer: FC<AccountInfoDrawerProps> = memo(
  ({ open, onChangeDrawerState, provider }) => {
    const onCloseDrawer = useCallback(() => {
      onChangeDrawerState(false);
    }, [onChangeDrawerState]);

    const content = accountInfoDrawerContent(provider);

    return (
      <Drawer
        anchor="right"
        open={open}
        onClose={onCloseDrawer}
        PaperProps={{
          sx: {
            width: ACCOUNT_DRAWER_WIDTH,
            px: 3,
          },
        }}
      >
        <Box pt={2}>
          {content.map((item) => {
            return (
              <Fragment key={item.title}>
                <Typography fontWeight="bold" pt={3}>
                  {item.title}
                </Typography>
                <Box component="ul" my={0} pl={2.5} pt={1}>
                  {item.children.map((child) => (
                    <Typography
                      component="li"
                      key={child}
                      color={grey[700]}
                      fontSize={14}
                    >
                      {child}
                    </Typography>
                  ))}
                </Box>
              </Fragment>
            );
          })}
        </Box>
      </Drawer>
    );
  },
);
