import { cloudChiprApi } from "../../../../services/cloudchipr.api";
import { updateDashboardVisibilityHierarchyFixedCacheKey } from "../../utils/constants/fixedCacheKeys";
import { RootState } from "../../../store";

const endpoint =
  cloudChiprApi.endpoints.patchV2UsersMeOrganisationsCurrentDashboardHierarchy.select(
    updateDashboardVisibilityHierarchyFixedCacheKey,
  );

export const updateDashboardVisibilityHierarchyLoadingSelector = (
  state: RootState,
) => {
  return endpoint(state)?.isLoading;
};
