import { costBreakdownWidgetByWidgetIdSelector } from "./costBreakdownWidgetByWidgetIdSelector";
import { RootState } from "../../../../../store";

export const costBreakdownWidgetDataByWidgetIdSelector = (
  state: RootState,
  widgetId: string,
) => {
  const response = costBreakdownWidgetByWidgetIdSelector({ widgetId })(state);

  return response?.data;
};
