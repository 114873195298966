import { FC, useCallback } from "react";
import {
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  TableCell,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import EditIcon from "@mui/icons-material/Edit";
import { DeleteCategoryConfirmDialog } from "./DeleteCategoryConfirmDialog";
import { CategoryFormDialog } from "../../../../common/categories/category-adding/CategoryFormDialog";
import { useDialog } from "../../../../../../utils/hooks/useDialog.hook";
import { useMenuHook } from "../../../../../../utils/hooks/useMenu.hook";
import { AccountCategoryWithCost } from "../../../../../../services/cloudchipr.api";
import { useAppAbility } from "../../../../../../services/permissions";

interface CategoriesListActionsCellProps {
  category: AccountCategoryWithCost;
}

export const SubCategoryActionsCell: FC<CategoriesListActionsCellProps> = ({
  category,
}) => {
  const { anchor, open, openMenu, closeMenu } = useMenuHook();
  const { cannot } = useAppAbility();
  const canNotDeleteCategory = cannot("delete", "category");
  const canNotEditCategory = cannot("edit", "category");
  const {
    open: removeOpen,
    openDialog: removeOpenHandler,
    closeDialog: removeCloseHandler,
  } = useDialog();
  const {
    open: editOpen,
    openDialog: editOpenHandler,
    closeDialog: editCloseHandler,
  } = useDialog();

  const closeHandler = useCallback(() => {
    closeMenu();
    editCloseHandler();
    removeCloseHandler();
  }, [closeMenu, editCloseHandler, removeCloseHandler]);

  return (
    <TableCell sx={{ textAlign: "end" }}>
      <IconButton size="small" onClick={openMenu} sx={{ p: 0 }}>
        <MoreVertIcon />
      </IconButton>

      <Menu open={open} onClose={closeMenu} anchorEl={anchor}>
        <MenuItem dense onClick={editOpenHandler} disabled={canNotEditCategory}>
          <ListItemIcon>
            <EditIcon />
          </ListItemIcon>
          <ListItemText>Edit</ListItemText>
        </MenuItem>
        <MenuItem
          dense
          onClick={removeOpenHandler}
          disabled={canNotDeleteCategory}
        >
          <ListItemIcon>
            <DeleteOutlineIcon />
          </ListItemIcon>
          <ListItemText>
            <ListItemText>Delete</ListItemText>
          </ListItemText>
        </MenuItem>
      </Menu>

      {editOpen && (
        <CategoryFormDialog
          open
          onClose={closeHandler}
          editingCategory={category}
        />
      )}

      <DeleteCategoryConfirmDialog
        open={removeOpen}
        name={category.name}
        categoryId={category.id}
        onClose={closeHandler}
      />
    </TableCell>
  );
};
