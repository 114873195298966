import { FC, useCallback } from "react";
import { Stack, Typography } from "@mui/material";
import { TaskPropertyItem } from "./TaskPropertyItem";
import { TaskPropertiesDragDropWrapper } from "./components/TaskPropertiesDragDropWrapper";
import { TaskPropertyAdder } from "./components/TaskPropertyAdder";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../store/hooks";
import { setTaskManagementProperties } from "../../../../../../../store/task-management/taskManagementSlice";
import { TaskStatus } from "../../../../../../../services/cloudchipr.api";
import { taskManagementEditingStatusesSelector } from "../../../../../../../store/task-management/selectors/properties/edit/taskManagementEditingStatusesSelector";
import { newCreatedPropId } from "../../../utils/constants/common";

export const TaskStatusPropertyEditSection: FC = () => {
  const dispatch = useAppDispatch();
  const properties = useAppSelector(taskManagementEditingStatusesSelector);

  const todoProperty = properties?.find(
    (status) => status.category === "to_do",
  );
  const completedProperty = properties?.find(
    (status) => status.category === "completed",
  );

  const propertyChangeHandler = useCallback(
    (properties: TaskStatus[]) => {
      dispatch(setTaskManagementProperties({ statuses: properties }));
    },
    [dispatch],
  );

  const newPropertyAddHandler = useCallback(
    (color: string) => {
      dispatch(
        setTaskManagementProperties({
          statuses: [
            ...properties,
            {
              color,
              name: "",
              category: "in_progress",
              id: `${newCreatedPropId}_${properties.length}`,
            },
          ],
        }),
      );
    },
    [dispatch, properties],
  );

  if (!todoProperty || !completedProperty) {
    return null;
  }

  return (
    <Stack spacing={1}>
      <Typography variant="body2" color="text.secondary" fontWeight="medium">
        To-do
      </Typography>
      <TaskPropertyItem
        index={0}
        isLast={false}
        dragable={false}
        colorable={false}
        removable={false}
        properties={properties}
        id={todoProperty.id}
        name={todoProperty.name}
        color={todoProperty.color}
        onPropertiesChange={propertyChangeHandler}
      />

      <Typography variant="body2" color="text.secondary" fontWeight="medium">
        In progress
      </Typography>
      <TaskPropertiesDragDropWrapper
        properties={properties}
        onPropertiesChange={propertyChangeHandler}
      >
        {properties.map(({ name, color, id, category }, index) => {
          if (["to_do", "completed"].includes(category)) {
            return null;
          }

          return (
            <TaskPropertyItem
              key={id + color}
              id={id}
              color={color}
              name={name}
              properties={properties}
              index={index}
              onPropertiesChange={propertyChangeHandler}
              isLast={properties.length - 2 === 1}
            />
          );
        })}

        <TaskPropertyAdder
          label="Status"
          properties={properties}
          onPropertyAdd={newPropertyAddHandler}
        />
      </TaskPropertiesDragDropWrapper>

      <Typography variant="body2" color="text.secondary" fontWeight="medium">
        Completed
      </Typography>
      <TaskPropertyItem
        isLast={false}
        dragable={false}
        colorable={false}
        removable={false}
        properties={properties}
        index={(properties?.length ?? 0) - 1}
        id={completedProperty.id}
        name={completedProperty.name}
        color={completedProperty.color}
        onPropertiesChange={propertyChangeHandler}
      />
    </Stack>
  );
};
