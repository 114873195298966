import { FC, Fragment, useCallback } from "react";
import { IconButton, Stack } from "@mui/material";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import { FilterItemFields } from "./components/FilterItemFields";
import { DimensionFilterItem } from "./components/dimension-filter-item/DimensionFilterItem";
import { GroupItemsOperator } from "../GroupItemsOperator";
import {
  FilterItemNodeWithId,
  FiltersCombinationOperatorType,
} from "../../../utils/types/common";

interface FilterItemProps {
  index: number;
  parentOperator: FiltersCombinationOperatorType;
  filter: FilterItemNodeWithId;
  onOperatorChange(operator: FiltersCombinationOperatorType): void;
  onFilterChange(filter: FilterItemNodeWithId, index: number): void;
  onFilterRemove(index: number): void;
}

export const FilterItem: FC<FilterItemProps> = ({
  filter,
  parentOperator,
  index,
  onOperatorChange,
  onFilterChange,
  onFilterRemove,
}) => {
  const removeClickHandler = useCallback(() => {
    onFilterRemove(index);
  }, [onFilterRemove, index]);

  return (
    <Fragment>
      <GroupItemsOperator
        first={index === 0}
        operator={parentOperator}
        onChange={onOperatorChange}
      />

      <Stack
        flex={1}
        spacing={1}
        direction="row"
        alignItems="center"
        justifyContent="space-between"
      >
        {filter.type === "dimension_id" ? (
          <DimensionFilterItem
            index={index}
            filter={filter}
            onFilterChange={onFilterChange}
          />
        ) : (
          <FilterItemFields
            index={index}
            filter={filter}
            onFilterChange={onFilterChange}
          />
        )}

        <IconButton size="small" onClick={removeClickHandler}>
          <DeleteOutlinedIcon fontSize="small" />
        </IconButton>
      </Stack>
    </Fragment>
  );
};
