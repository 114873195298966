import { Stack, Tooltip, Typography, TypographyProps } from "@mui/material";
import { FC } from "react";
import { money } from "../../../../../../../../../../utils/numeral/money";

interface LargestCostChangesWidgetSetupResourceCostItemProps {
  tooltipTitle: string;
  title: string;
  cost: number | null;
  totalCostTypographyProps?: TypographyProps;
  priceCostTypographyProps?: TypographyProps;
}

export const LargestCostChangesWidgetSetupResourceCostItem: FC<
  LargestCostChangesWidgetSetupResourceCostItemProps
> = ({
  tooltipTitle,
  title,
  cost,
  totalCostTypographyProps,
  priceCostTypographyProps,
}) => {
  return (
    <Stack flex="1 1 0">
      <Tooltip arrow placement="top" title={tooltipTitle}>
        <Stack width="max-content">
          <Typography color="text.secondary" {...totalCostTypographyProps}>
            {title}
          </Typography>
          <Typography {...priceCostTypographyProps}>{money(cost)}</Typography>
        </Stack>
      </Tooltip>
    </Stack>
  );
};
