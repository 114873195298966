import { FC, Fragment, useCallback } from "react";
import { Button, CssBaseline, Typography } from "@mui/material";
import { useAuth0 } from "@auth0/auth0-react";
import { useSearchParams } from "react-router-dom";
import Stack from "@mui/material/Stack";
import { formatErrorMessage } from "./utils/helpers/formatErrorMessage";
import { PageHeaderWithLogo } from "../common/PageHeaderWithLogo";
import ErrorPageImage from "../../assets/images/error-page.png";

export const WhiteListError: FC = () => {
  const { logout } = useAuth0();
  const [searchParams] = useSearchParams();
  const errorType = searchParams.get("error");
  const description = searchParams.get("error_description");

  const logoutClickHandler = useCallback(() => {
    logout({ returnTo: window.location.origin });
  }, [logout]);

  return (
    <Fragment>
      <CssBaseline />
      <Stack height="100vh" bgcolor="white">
        <PageHeaderWithLogo />
        <Stack alignItems="center" mt={20} spacing={3.5} px={1}>
          <Typography variant="h4" color="primary.dark" fontWeight="medium">
            {formatErrorMessage(errorType ?? "")}
          </Typography>
          <img width={140} src={ErrorPageImage} alt="Invitation Required" />
          <Typography
            variant="h6"
            textAlign="center"
            color="text.secondary"
            fontWeight="normal"
          >
            {description ??
              "We track these errors automatically, but if the problem persist please feel free to contact us."}

          </Typography>
          <Button
            onClick={logoutClickHandler}
            sx={{ textTransform: "none" }}
            variant="outlined"
          >
            Back to Login
          </Button>
        </Stack>
      </Stack>
    </Fragment>
  );
};
