import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { useCallback } from "react";
import { commitmentGroupByTitleByGrouping } from "../../../utils/constants/constants";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../../../../store/hooks";
import { CommitmentsUtilizationGrouping } from "../../../../../../../../../../../services/cloudchipr.api";
import { commitmentUtilizationSetupPropertyByKeySelector } from "../../../../../../../../../../../store/dashboards/selectors/setups/commitment-utilization/commitmentUtilizationSetupPropertyByKeySelector";
import { commitmentUtilizationWidgetSetupDataChangeThunk } from "../../../../../../../../../../../store/dashboards/thunks/widgets/commitment-utilization/setup-change/commitmentUtilizationWidgetSetupDataChangeThunk";

export const CommitmentUtilizationWidgetSetupGroupBySelect = () => {
  const dispatch = useAppDispatch();

  const grouping: CommitmentsUtilizationGrouping = useAppSelector(
    commitmentUtilizationSetupPropertyByKeySelector("grouping"),
  );

  const handleChange = useCallback(
    (event: SelectChangeEvent<CommitmentsUtilizationGrouping>) => {
      dispatch(
        commitmentUtilizationWidgetSetupDataChangeThunk({
          grouping: event.target.value as CommitmentsUtilizationGrouping,
        }),
      );
    },
    [dispatch],
  );

  return (
    <FormControl fullWidth size="small">
      <InputLabel>Group By</InputLabel>

      <Select value={grouping} onChange={handleChange} label="Group By">
        <MenuItem value="commitment_type">
          {commitmentGroupByTitleByGrouping.get("commitment_type")}
        </MenuItem>
        <MenuItem value="id">
          {commitmentGroupByTitleByGrouping.get("id")}
        </MenuItem>
        <MenuItem value="ungrouped">
          {commitmentGroupByTitleByGrouping.get("ungrouped")}
        </MenuItem>
      </Select>
    </FormControl>
  );
};
