import { FC, useCallback } from "react";
import { FormHelperText, Stack } from "@mui/material";
import { NotificationsSelection } from "../../../../common/notification-selection/NotificationsSelection";
import { Schedule } from "../../../../../../services/cloudchipr.api";
import {
  setAutomationData,
  setAutomationErrors,
} from "../../../../../../store/automations/automationsSlice";
import { useAppDispatch, useAppSelector } from "../../../../../../store/hooks";
import { automationNotificationsSelector } from "../../../../../../store/automations/selectros/common/fields/automationNotificationsSelector";
import { automationEmailsSelector } from "../../../../../../store/automations/selectros/common/fields/automationEmailsSelector";
import { automationEmailsErrorsSelector } from "../../../../../../store/automations/selectros/common/errors/automationEmailsErrorsSelector";
import { automationNotificationsErrorsSelector } from "../../../../../../store/automations/selectros/common/errors/notifications/automationNotificationsErrorsSelector";
import { automationNotificationExistenceErrorSelector } from "../../../../../../store/automations/selectros/common/errors/notifications/automationNotificationExistenceErrorSelector";
import { IntegrationCustomMessageProps } from "../custom-message/IntegrationCustomMessage";

interface AutomationNotificationsProps {
  IntegrationCustomMessage?: FC<IntegrationCustomMessageProps>;
}

export const AutomationNotifications: FC<AutomationNotificationsProps> = ({
  IntegrationCustomMessage,
}) => {
  const dispatch = useAppDispatch();

  const notifications = useAppSelector(automationNotificationsSelector);
  const emails = useAppSelector(automationEmailsSelector);
  const emailsErrors = useAppSelector(automationEmailsErrorsSelector);
  const notificationsErrors = useAppSelector(
    automationNotificationsErrorsSelector,
  );
  const notificationExistence = useAppSelector(
    automationNotificationExistenceErrorSelector,
  );

  const clearNotificationErrors = useCallback(() => {
    notificationsErrors.forEach((error, i) => {
      let key = "";

      if (error.conversations) {
        key = `notifications[${i}].conversations`;
      }
      if (error.to) {
        key = `notifications[${i}].to`;
      }

      if (key) {
        dispatch(setAutomationErrors({ [key]: "" }));
      }
    });
  }, [dispatch, notificationsErrors]);

  const clearEmailsErrors = useCallback(() => {
    Object.entries(emailsErrors).forEach(([key, errors]) => {
      if (Array.isArray(errors)) {
        errors.forEach((error, index) => {
          if (error) {
            dispatch(setAutomationErrors({ [`emails.${key}[${index}]`]: "" }));
          }
        });
      }
    });
  }, [dispatch, emailsErrors]);

  const integrationsChangeHandler = useCallback(
    (notifications: Schedule["notifications"]) => {
      dispatch(setAutomationData({ notifications }));
      clearNotificationErrors();
    },
    [dispatch, clearNotificationErrors],
  );

  const emailsChangeHandler = useCallback(
    (emails: Schedule["emails"]) => {
      dispatch(setAutomationData({ emails }));
      clearEmailsErrors();
    },
    [dispatch, clearEmailsErrors],
  );

  return (
    <Stack spacing={0.5}>
      <NotificationsSelection
        selectedEmails={emails}
        emailsErrors={emailsErrors}
        disabledIntegrationTypes={["jira"]}
        selectedIntegrations={notifications}
        integrationErrors={notificationsErrors}
        emailsChangeHandler={emailsChangeHandler}
        setSelectedIntegrations={integrationsChangeHandler}
        IntegrationCustomMessage={IntegrationCustomMessage}
      />

      {notificationExistence && (
        <FormHelperText error>{notificationExistence}</FormHelperText>
      )}
    </Stack>
  );
};
