import { FC } from "react";
import { Box, Stack, Typography } from "@mui/material";
import { TooltipProps } from "recharts";
import numeral from "numeral";
import { formatDate } from "../../../../../../../../../../../../utils/helpers/date-time/datetime-format";
import { ChartDataType } from "../../../../../../../../../../../../storybook/charts/multi-type-chart/utils/types/types";
import {
  colorsByMetricType,
  metricsLabel,
  metricsUnit,
} from "../utils/constants";
import { MetricType } from "../utils/types";

interface ResourceMetricsChartTooltipContentProps
  extends TooltipProps<any, any> {
  type: MetricType;
}

export const ResourceMetricsChartTooltipContent: FC<
  ResourceMetricsChartTooltipContentProps
> = ({ payload, type }) => {
  const data = payload?.[0]?.payload as ChartDataType;

  if (!data) {
    return null;
  }

  const date = formatDate(`${data.date}`, {
    type: "dateTimeWithComma",
  });

  return (
    <Stack sx={styles} gap={1}>
      {date && (
        <Typography variant="caption" color="text.secondary" fontSize={10}>
          {date} (UTC)
        </Typography>
      )}

      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Stack direction="row" alignItems="center" spacing={1}>
          <Box
            minWidth={3}
            borderRadius={0.5}
            height={14}
            bgcolor={colorsByMetricType.get(type)?.["700"]}
          />

          <Typography variant="caption" color="text.secondary" fontSize={10}>
            {metricsLabel[type]}
          </Typography>
        </Stack>

        <Typography variant="caption" color="text.secondary" fontSize={10}>
          {data.value === null ? "N/A" : numeral(data.value).format("0,0.[00]")}
          {data.value !== null && !isNaN(data.value) && metricsUnit[type]}
        </Typography>
      </Stack>
    </Stack>
  );
};

const styles = {
  p: 1,
  border: 1,
  borderRadius: 3,
  borderColor: "grey.300",
  minWidth: 130,
  color: "grey.600",
  bgcolor: "white",
};
