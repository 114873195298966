import { FC, Fragment } from "react";
import { Stack } from "@mui/material";
import { ReportsDrawerScheduleInterval } from "./ReportsDrawerScheduleInterval";
import { ReportsDrawerTimePicker } from "./ReportsDrawerTimePicker";
import { ReportsDrawerTimeZone } from "./ReportsDrawerTimeZone";
import { ReportsDrawerWeekDaysSelect } from "./ReportsDrawerWeekDaysSelect";

export const ReportsDrawerWeeklySchedule: FC = () => {
  return (
    <Fragment>
      <ReportsDrawerWeekDaysSelect />
      <Stack spacing={1.5} direction="row" alignItems="center" flex={1}>
        <ReportsDrawerScheduleInterval frequency="week" flexGrow={2} />
        <Stack flex={1.5}>
          <ReportsDrawerTimePicker />
        </Stack>
        <Stack flex={4}>
          <ReportsDrawerTimeZone />
        </Stack>
      </Stack>
    </Fragment>
  );
};
