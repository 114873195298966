import { useCallback } from "react";
import { useAppDispatch } from "../../../../../store/hooks";
import {
  setAccountSetupDialogOpen,
  setPrefillAccountId,
  setProvider,
} from "../../../../../store/account-setup/accountSetupSlice";
import {
  AccountId,
  ProviderType,
} from "../../../../../services/cloudchipr.api";
import { useAppAbility } from "../../../../../services/permissions";

interface AccountAddFunctionalProps {
  provider: ProviderType;
  accountId?: AccountId;
}

export function useAccountAddFunctional({
  provider,
  accountId,
}: AccountAddFunctionalProps) {
  const dispatch = useAppDispatch();
  const { cannot } = useAppAbility();
  const canNotCreateAccount = cannot("create", "account");

  const initiateHandler = useCallback(() => {
    dispatch(setProvider(provider));
    dispatch(setAccountSetupDialogOpen(true));

    if (accountId) {
      dispatch(setPrefillAccountId(accountId));
    }
  }, [dispatch, provider, accountId]);

  return {
    canNotCreateAccount,
    startAddAccountFlow: initiateHandler,
  };
}
