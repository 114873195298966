import { FC, Fragment, useCallback } from "react";
import { Button, DialogActions, DialogContent, Divider } from "@mui/material";
import {
  goToPrevStep,
  resetAccountAttempt,
} from "../../../../../../store/account-setup/accountSetupSlice";
import { useAppDispatch, useAppSelector } from "../../../../../../store/hooks";
import { AccessToAWSAccountTabs } from "../aws/AccessToAWSAccountTabs";
import { PollingProgress } from "../PollingProgress";
import { FailedAccountAttemptReason } from "../FailedAccountAttemptReason";
import { PollingInterruptedMessage } from "../PollingInterruptedMessage";
import { AccessToAccountSetupHeader } from "../AccessToAccountSetupHeader";
import { AccessToGCPProjectTabs } from "../gcp/components/AccessToGCPProjectTabs";
import { pollingSelector } from "../../../../../../store/account-setup/selectors/pollingSelector";
import { providerSelector } from "../../../../../../store/account-setup/selectors/providerSelector";

export const AccessToAccountStep: FC = () => {
  const dispatch = useAppDispatch();
  const polling = useAppSelector(pollingSelector);
  const provider = useAppSelector(providerSelector);

  const handleBackClick = useCallback(() => {
    dispatch(resetAccountAttempt());
    dispatch(goToPrevStep());
  }, [dispatch]);

  return (
    <Fragment>
      <DialogContent>
        <AccessToAccountSetupHeader />
        {provider === "aws" && <AccessToAWSAccountTabs />}
        {provider === "gcp" && <AccessToGCPProjectTabs />}

        <FailedAccountAttemptReason />
        <PollingInterruptedMessage />
      </DialogContent>
      <PollingProgress />
      <Divider light />
      <DialogActions sx={{ px: 3, py: 2 }}>
        <Button
          onClick={handleBackClick}
          disabled={polling}
          variant="outlined"
          size="medium"
        >
          Back
        </Button>
      </DialogActions>
    </Fragment>
  );
};
