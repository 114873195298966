import { FC, MouseEvent, useCallback } from "react";
import { Chip } from "@mui/material";
import { DPYear, useContextYearsPropGetters } from "@rehookify/datepicker";

interface DateRangeCalendarYearProps {
  dpYear: DPYear;
  onSelect(): void;
}

export const DateRangeCalendarYear: FC<DateRangeCalendarYearProps> = ({
  dpYear,
  onSelect,
}) => {
  const { yearButton } = useContextYearsPropGetters();

  const buttonProps = yearButton(dpYear);
  const onClick = buttonProps.onClick;

  const clickHandler = useCallback(
    (e: MouseEvent<HTMLElement>) => {
      onClick?.(e);
      onSelect();
    },
    [onSelect, onClick],
  );

  return (
    <Chip
      label={dpYear.year}
      size="small"
      color="primary"
      {...buttonProps}
      onClick={clickHandler}
      sx={{
        border: "none",
        color: dpYear.selected ? "primary" : "text.secondary",
      }}
      variant={dpYear.selected ? "filled" : "outlined"}
    />
  );
};
