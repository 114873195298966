import { offHoursRecommendationsLoadingSelector } from "./offHoursRecommendationsLoadingSelector";
import { offHoursDismissedRecommendationsLoadingSelector } from "./offHoursDismissedRecommendationsLoadingSelector";
import { RootState } from "../../../../store";
import { offHoursRecommendationsDismissedResourcesLoadingSelector } from "../dismissed-resources/offHoursRecommendationsDismissedResourcesLoadingSelector";

export const offHoursRecommendationsPageLoadingSelector = (
  state: RootState,
) => {
  const activeLoading = offHoursRecommendationsLoadingSelector(state);
  const dismissedLoading =
    offHoursDismissedRecommendationsLoadingSelector(state);
  const dismissedResourcesLoading =
    offHoursRecommendationsDismissedResourcesLoadingSelector(state);

  return activeLoading || dismissedLoading || dismissedResourcesLoading;
};
