import { liveUsageMgmtSelectedResourcesByResourceTypeSelector } from "./liveUsageMgmtSelectedResourcesByResourceTypeSelector";
import { ResourceType } from "../../../../services/cloudchipr.api";
import { RootState } from "../../../store";
import { generateExportedResourceData } from "../../../../components/pages/live-usage-mgmt/components/resource-card/resource-card-content/resource-card-data/resource-card-grid/resouce-card-data-grid/utils/csv-data/generateExportedResourceData";

export const selectedResourcesCSVDataDisabledColumns = ["issues"];

export const liveUsageMgmtSelectedResourcesByResourceTypeCsvDataSelector = (
  state: RootState,
  resourceType: ResourceType,
  disabledColumns?: string[],
) => {
  const resources = liveUsageMgmtSelectedResourcesByResourceTypeSelector(
    state,
    resourceType,
  );

  if (!resources) {
    return null;
  }

  return generateExportedResourceData(resourceType, resources, [
    ...(disabledColumns ?? []),
    ...selectedResourcesCSVDataDisabledColumns,
  ]);
};
