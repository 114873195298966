import { FC } from "react";
import { Button } from "@mui/material";
import { SubscriptionPlanLinkToBilling } from "./SubscriptionPlanLinkToBilling";
import { SubscriptionPlanActionType } from "../SubscriptionPlans";

interface SubscriptionPlanActionProps {
  id: string;
  current: boolean;
  special: boolean;
  action: SubscriptionPlanActionType;
}

export const SubscriptionPlanAction: FC<SubscriptionPlanActionProps> = ({
  id,
  current,
  special,
  action,
}) => {
  if (current) {
    return (
      <Button fullWidth disabled>
        Current
      </Button>
    );
  }

  if (special) {
    return (
      <Button
        fullWidth
        color="primary"
        variant="outlined"
        href="mailto:support@cloudchipr.com"
      >
        Contact us
      </Button>
    );
  }

  return <SubscriptionPlanLinkToBilling id={id} action={action} />;
};
