import { FC } from "react";
import { useAppSelector } from "../../../../../../../store/hooks";
import { editingCategorySelector } from "../../../../../../../store/dimensions/selectors/slice-data/editing-category/editingCategorySelector";
import { CategoryItem } from "../dimension/categories/CategoryItem";

export const EditingCategory: FC = () => {
  const editingCategory = useAppSelector(editingCategorySelector);

  if (!editingCategory?.id) {
    return null;
  }

  return <CategoryItem category={editingCategory} />;
};
