import { createAsyncThunk } from "@reduxjs/toolkit";
import { CommitmentUtilizationWidgetSetupType } from "../../../../utils/types/setups/commitmentUtilizationWidgetSetupType";
import { RootState } from "../../../../../store";
import { commitmentUtilizationWidgetSetupSelector } from "../../../../selectors/setups/commitment-utilization/commitmentUtilizationWidgetSetupSelector";
import { isDeepEqual } from "../../../../../../utils/is-deep-equal";
import { setWidgetSetup } from "../../../../dashboardsSlice";
import { getCommitmentUtilizationWidgetSetupDataThunk } from "../getCommitmentUtilizationWidgetSetupDataThunk";

export const commitmentUtilizationWidgetSetupDataChangeThunk = createAsyncThunk(
  "dashboard/commitmentUtilizationWidgetSetupDataChange",
  async (
    data: Partial<CommitmentUtilizationWidgetSetupType>,
    { dispatch, getState },
  ) => {
    const state = getState() as RootState;
    const existingSetup = commitmentUtilizationWidgetSetupSelector(state);

    if (!existingSetup || isDeepEqual(existingSetup, data)) {
      return;
    }

    dispatch(
      setWidgetSetup({
        ...existingSetup,
        ...data,
      }),
    );

    dispatch(getCommitmentUtilizationWidgetSetupDataThunk());
  },
);
