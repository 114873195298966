import { FC, useCallback } from "react";
import { Button } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../store/hooks";
import { addNewCategory } from "../../../../../../../../store/dimensions/dimensionsSlice";
import { categoriesCreationDataIsInvalidSelector } from "../../../../../../../../store/dimensions/selectors/options/categories/categoriesCreationDataIsInvalidSelector";
import { generateEmptyCategory } from "../../../../../utils/helpers/generateEmptyCategory";
import { dimensionCategoriesLengthSelector } from "../../../../../../../../store/dimensions/selectors/slice-data/dimensionCategoriesLengthSelector";

export const AddCategoryButton: FC = () => {
  const dispatch = useAppDispatch();
  const disabled = useAppSelector(categoriesCreationDataIsInvalidSelector);
  const categoriesLength = useAppSelector(dimensionCategoriesLengthSelector);

  const addCategoryHandler = useCallback(() => {
    dispatch(addNewCategory(generateEmptyCategory(categoriesLength)));
  }, [dispatch, categoriesLength]);

  return (
    <Button
      variant="outlined"
      disabled={disabled}
      startIcon={<AddIcon />}
      onClick={addCategoryHandler}
      sx={{ alignSelf: "start", textTransform: "none" }}
    >
      Add Category
    </Button>
  );
};
