import { createDraftSafeSelector } from "@reduxjs/toolkit";
import { liveFilteredTrackedResourcesSelector } from "../liveFilteredTrackedResourcesSelector";
import { RootState } from "../../../../store";
import { PostUsersMeOrganisationsCurrentTrackedResourcesApiResponse } from "../../../../../services/cloudchipr.api";
import { liveFilteredResourcesEmsPriceSelector } from "../liveFilteredResourcesEmsPriceSelector";

type ReturnType =
  | PostUsersMeOrganisationsCurrentTrackedResourcesApiResponse
  | undefined;

const sortedDataSelector = (state: RootState): ReturnType => {
  const response = liveFilteredTrackedResourcesSelector(state);

  return response?.data?.toSorted((current, next) => {
    const currentCost = liveFilteredResourcesEmsPriceSelector(
      state,
      current.resource_type,
    );
    const nextCost = liveFilteredResourcesEmsPriceSelector(
      state,
      next.resource_type,
    );

    return (nextCost ?? 0) - (currentCost ?? 0);
  });
};

export const liveFilteredTrackedResourcesDataForViewSelector =
  createDraftSafeSelector([sortedDataSelector], (data): ReturnType => {
    return data;
  });
