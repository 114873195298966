import { FC } from "react";
import { ResourceExplorerTableFooter } from "./ResourceExplorerTableFooter";
import { useAppSelector } from "../../../../../../../../../store/hooks";
import { resourceExplorerGranularViewGridAggregatedDataSelector } from "../../../../../../../../../store/resource-explorer/selectors/current-resource-explorer/data-grid/granular-costs-view/resourceExplorerGranularViewGridAggregatedDataSelector";
import { resourceExplorerGranularDataCountSelector } from "../../../../../../../../../store/resource-explorer/selectors/current-resource-explorer/data-grid/granular-costs-view/resourceExplorerGranularDataCountSelector";

export const ResourceExplorerGranularViewFooter: FC = () => {
  const aggregatedData = useAppSelector(
    resourceExplorerGranularViewGridAggregatedDataSelector,
  );

  const gridDataCount = useAppSelector(
    resourceExplorerGranularDataCountSelector,
  );

  if (!aggregatedData || !gridDataCount) {
    return null;
  }

  return (
    <ResourceExplorerTableFooter
      count={+aggregatedData?.count}
      threshold={+aggregatedData?.threshold}
      totalCost={+aggregatedData?.totalCost}
      gridDataCount={gridDataCount}
    />
  );
};
