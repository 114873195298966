import { FC, Fragment } from "react";
import StarsIcon from "@mui/icons-material/Stars";
import { Tooltip, Typography } from "@mui/material";
import { TypographyWithCopy } from "../../../../../common/TypographyWithCopy";

interface AccountCardHeaderTitleProps {
  name?: string;
  accountId?: string;
  isRoot?: boolean;
}

export const AccountCardHeaderTitle: FC<AccountCardHeaderTitleProps> = ({
  name,
  accountId,
  isRoot,
}) => {
  if (!accountId) {
    return null;
  }
  return (
    <Fragment>
      {name ? (
        <TypographyWithCopy value={name} variant="body1" />
      ) : (
        <Typography variant="body1" color="text.disabled">
          -
        </Typography>
      )}
      {isRoot && (
        <Tooltip title="Management account" placement="top" arrow>
          <StarsIcon color="primary" sx={{ verticalAlign: "middle", ml: 1 }} />
        </Tooltip>
      )}
    </Fragment>
  );
};
