import { FC } from "react";
import { Stack } from "@mui/material";
import { InUseCellUsageChip } from "./InUseCellUsageChip";
import { useIntegrationsUsage } from "../../../utils/hooks/useIntegrationsUsage.hook";
import { UsageType } from "../../../utils/types/integration-usage";

interface IntegrationsListInUseCellProps {
  id: string;
}

export const IntegrationsListInUseCell: FC<IntegrationsListInUseCellProps> = ({
  id,
}) => {
  const { getIntegrationsUsage } = useIntegrationsUsage();
  const usages = getIntegrationsUsage(id);

  const keys = Object.keys(usages) as UsageType[];
  const usageExist = Object.values(usages).flat().length;

  if (!usageExist) {
    return <div>-</div>;
  }

  return (
    <Stack direction="row" alignItems="center" spacing={1}>
      {keys.map((type) => {
        return (
          <InUseCellUsageChip
            key={type}
            usages={usages[type]}
            usageType={type}
          />
        );
      })}
    </Stack>
  );
};
