import DnsOutlinedIcon from "@mui/icons-material/DnsOutlined";
import VpnKeyOutlinedIcon from "@mui/icons-material/VpnKeyOutlined";
import AvTimerOutlinedIcon from "@mui/icons-material/AvTimerOutlined";
import AddAlertOutlinedIcon from "@mui/icons-material/AddAlertOutlined";
import GridViewOutlinedIcon from "@mui/icons-material/GridViewOutlined";
import ExtensionOutlinedIcon from "@mui/icons-material/ExtensionOutlined";
import AssignmentOutlinedIcon from "@mui/icons-material/AssignmentOutlined";
import InsertChartOutlinedIcon from "@mui/icons-material/InsertChartOutlined";
import ScheduleSendOutlinedIcon from "@mui/icons-material/ScheduleSendOutlined";
import DatasetLinkedOutlinedIcon from "@mui/icons-material/DatasetLinkedOutlined";
import MonetizationOnOutlinedIcon from "@mui/icons-material/MonetizationOnOutlined";
import ManageAccountsOutlinedIcon from "@mui/icons-material/ManageAccountsOutlined";
import AdminPanelSettingsOutlinedIcon from "@mui/icons-material/AdminPanelSettingsOutlined";
import PlayCircleFilledWhiteOutlinedIcon from "@mui/icons-material/PlayCircleFilledWhiteOutlined";

export const rolePermissionsMapping: Map<
  string,
  {
    Icon: any;
    label: string;
  }
> = new Map([
  [
    "user_management",
    {
      Icon: ManageAccountsOutlinedIcon,
      label: "User Management",
    },
  ],
  [
    "workflow",
    {
      Icon: AvTimerOutlinedIcon,
      label: "Schedule",
    },
  ],
  [
    "account_management",
    {
      Icon: PlayCircleFilledWhiteOutlinedIcon,
      label: "Account Management",
    },
  ],
  [
    "resource_management",
    {
      Icon: DnsOutlinedIcon,
      label: "Resource Management",
    },
  ],
  [
    "integration",
    {
      Icon: ExtensionOutlinedIcon,
      label: "Integration",
    },
  ],
  [
    "budget",
    {
      Icon: MonetizationOnOutlinedIcon,
      label: "Budget",
    },
  ],
  [
    "commitment",
    {
      Icon: DatasetLinkedOutlinedIcon,
      label: "Commitment",
    },
  ],
  [
    "report",
    {
      Icon: ScheduleSendOutlinedIcon,
      label: "Subscription",
    },
  ],
  [
    "sso",
    {
      Icon: AdminPanelSettingsOutlinedIcon,
      label: "SSO",
    },
  ],
  [
    "api_key",
    {
      Icon: VpnKeyOutlinedIcon,
      label: "Api Key",
    },
  ],
  [
    "task_management",
    {
      Icon: AssignmentOutlinedIcon,
      label: "Task Management",
    },
  ],
  [
    "alert",
    {
      Icon: AddAlertOutlinedIcon,
      label: "Alert",
    },
  ],
  [
    "dashboard",
    {
      Icon: GridViewOutlinedIcon,
      label: "Dashboard",
    },
  ],
  [
    "resource_explorer",
    {
      Icon: InsertChartOutlinedIcon,
      label: "Resource Explorer",
    },
  ],
]);
