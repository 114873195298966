import { FC, useCallback } from "react";
import {
  FormControl,
  InputLabel,
  Select,
  SelectProps,
  Typography,
} from "@mui/material";
import Chip from "@mui/material/Chip";
import { useToggle } from "rooks";
import { RecommendationLevelFilterAccountsSelect } from "./RecommendationLevelFilterAccountsSelect";
import { RecommendationLevelFilterPayer } from "./RecommendationLevelFilterPayer";
import { useAppSelector } from "../../../../../../../../../store/hooks";
import { commitmentsRecommendationsAccountScopeSelector } from "../../../../../../../../../store/commitments/selectors/recommendations/filters/commitmentsRecommendationsAccountScopeSelector";
import { commitmentsRecommendationsAccountIdsSelector } from "../../../../../../../../../store/commitments/selectors/recommendations/filters/commitmentsRecommendationsAccountIdsSelector";
import { organizationAccountsByCurrentOrgIdSelector } from "../../../../../../../../../store/commitments/selectors/organisations/organizationAccountsByCurrentOrgIdSelector";

export const RecommendationLevelFilter: FC = () => {
  const value = useAppSelector(commitmentsRecommendationsAccountScopeSelector);
  const accountIds = useAppSelector(
    commitmentsRecommendationsAccountIdsSelector,
  );
  const accounts = useAppSelector(organizationAccountsByCurrentOrgIdSelector);
  const allSelected = accounts?.length === accountIds?.length;

  const [open, toggleOpen] = useToggle(false);

  const renderValue = useCallback(
    (value: unknown) => {
      if (value === "payer") {
        return "Payer";
      }

      return (
        <Typography component="div" variant="caption">
          Linked Account{" "}
          <Chip
            size="small"
            sx={{ ml: 1 }}
            variant="filled"
            label={
              <Typography
                fontWeight="bold"
                color="text.secondary"
                variant="caption"
              >
                {allSelected ? "All" : accountIds?.length}
              </Typography>
            }
          />
        </Typography>
      );
    },
    [accountIds, allSelected],
  );

  if (!value) {
    return null;
  }

  return (
    <FormControl size="xsmall" sx={{ width: 170 }}>
      <InputLabel>Recommendation Level</InputLabel>
      <Select
        open={open}
        value={value}
        label="Recommendation Level"
        renderValue={renderValue}
        onOpen={toggleOpen}
        onClose={toggleOpen}
        MenuProps={menuProps}
      >
        <RecommendationLevelFilterPayer toggleOpen={toggleOpen} />

        <RecommendationLevelFilterAccountsSelect toggleOpen={toggleOpen} />
      </Select>
    </FormControl>
  );
};

const menuProps: SelectProps["MenuProps"] = {
  PaperProps: {
    sx: {
      width: 250,
    },
  },
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left",
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "left",
  },
};
