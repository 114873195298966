import {
  OffHoursSchedule,
  Schedule,
} from "../../../../services/cloudchipr.api";
import { AutomationsData } from "../types/common";
import { isDeepEqual } from "../../../../utils/is-deep-equal";

export const changesExistOnAutomation = (
  automationData: AutomationsData,
  original: Schedule | OffHoursSchedule,
) => {
  const filtersIsNotEqual = !isDeepEqual(
    original.filter ?? {},
    automationData.filter ?? {},
  );
  const emailsIsNotEqual = !isDeepEqual(
    original.emails ?? {},
    automationData.emails ?? {},
  );

  const accountsIsNotEqual =
    original.accounts.map((account) => account.id).join("") !==
    automationData.accountIds.join("");

  const notificationsIsNotEqual = !isDeepEqual(
    original.notifications ?? [],
    automationData.notifications ?? [],
  );

  return (
    original.name !== automationData.name ||
    (original.description ?? "") !== (automationData.description ?? "") ||
    original.cloud_provider !== automationData.provider ||
    original.start_date_time !== automationData.startDate ||
    original.end_date_time !== automationData.endDate ||
    original.time_zone !== automationData.timeZone ||
    original.min_threshold !== automationData.threshold ||
    original.regions.join("") !== automationData.regions.join("") ||
    accountsIsNotEqual ||
    filtersIsNotEqual ||
    notificationsIsNotEqual ||
    emailsIsNotEqual
  );
};
