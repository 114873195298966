import { ParserOptions } from "cron-parser/types/common";
import moment from "moment/moment";
import parser from "cron-parser";

type CronOptions = {
  startDate?: string;
  endDate?: string;
  hour?: number;
  minute?: number;
  dayOfMonth?: number;
  dayOfWeek?: number[];
};

export const createCron = ({
  endDate,
  hour,
  minute,
  dayOfMonth,
  dayOfWeek,
}: CronOptions): string => {
  const options: ParserOptions = {
    currentDate: moment().format(),
  };

  if (endDate) {
    options.endDate = endDate;
  }

  const interval = parser.parseExpression("* * * * *", options);
  const fields = JSON.parse(JSON.stringify(interval.fields));

  if (typeof hour === "number" && typeof minute !== "number") {
    fields.hour = [hour];
    fields.minute = [0];
  }

  if (typeof hour === "number" && typeof minute === "number") {
    fields.hour = [hour];
    fields.minute = [minute];
  }

  if (dayOfMonth) {
    fields.dayOfMonth = [dayOfMonth];
  }

  if (dayOfWeek) {
    fields.dayOfWeek = dayOfWeek;
  }

  const modifiedInterval = parser.fieldsToExpression(fields);

  return modifiedInterval.stringify();
};
