import { GetQuickFilterTextParams } from "@ag-grid-community/core/dist/types/src/entities/colDef";
import { Task } from "../../../../../../services/cloudchipr.api";

export const taskAssigneesFilterText = ({
  data,
}: GetQuickFilterTextParams<Task>) => {
  return (
    data?.assignees?.map((user) => `${user?.name} ${user?.email}`)?.join(" ") ??
    ""
  );
};
