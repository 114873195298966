import { FC, useCallback } from "react";
import {
  FormControl,
  InputLabel,
  ListSubheader,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import { useAppSelector } from "../../../../../../../../store/hooks";
import { liveUsageMgmtFilterNoneCloudchiprTemplatesSelector } from "../../../../../../../../store/live-usage-mgmt/selectors/liveUsageMgmtFilterNoneCloudchiprTemplatesSelector";

interface SaveSimplifiedFilterTemplatesDialogRewriteExistingProps {
  setSelectedFilterTemplateId(id: string): void;
  selectedFilterTemplateId: string;
}

export const SaveSimplifiedFilterTemplatesDialogRewriteExisting: FC<
  SaveSimplifiedFilterTemplatesDialogRewriteExistingProps
> = ({ setSelectedFilterTemplateId, selectedFilterTemplateId }) => {
  const notDefaultFilterTemplates = useAppSelector(
    liveUsageMgmtFilterNoneCloudchiprTemplatesSelector,
  );

  const selectHandler = useCallback(
    (event: SelectChangeEvent<string>) => {
      setSelectedFilterTemplateId(event.target.value);
    },
    [setSelectedFilterTemplateId],
  );

  return (
    <FormControl fullWidth size="small">
      <InputLabel>Filter Templates</InputLabel>

      <Select
        label="Filter Templates"
        onChange={selectHandler}
        value={selectedFilterTemplateId}
      >
        <ListSubheader sx={{ pb: 1 }}>
          <Typography variant="subtitle2" color="text.secondary">
            Filter Templates
          </Typography>
        </ListSubheader>

        {notDefaultFilterTemplates?.map(({ id, name }) => (
          <MenuItem key={id} value={id}>
            {name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
