import { Row } from "@tanstack/react-table";

export const getRowRange = (
  rows: Array<Row<any>>,
  firstId: string,
  secondId: string,
) => {
  const firstIndex = rows.findIndex((row) => row.id === firstId);
  const secondIndex = rows.findIndex((row) => row.id === secondId);

  const start = Math.min(firstIndex, secondIndex);
  const end = Math.max(firstIndex, secondIndex) + 1;

  return rows.slice(start, end);
};

export const getRowsSelectionModel = (rows: Row<any>[], selected: boolean) => {
  return rows.reduce(
    (acc, row) => {
      acc[row.id] = selected;
      return acc;
    },
    {} as Record<string, boolean>,
  );
};

export const generateSelectionResult = (
  outOfRangeSelectedRows: Record<string, boolean>,
  inRangeSelectedRows: Record<string, boolean>,
) => {
  const result = {
    ...outOfRangeSelectedRows,
    ...inRangeSelectedRows,
  };

  return Object.keys(result)
    .filter((id) => result[id])
    .reduce(
      (acc, id) => {
        acc[id] = true;
        return acc;
      },
      {} as Record<string, boolean>,
    );
};
