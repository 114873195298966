import { FC, ReactNode, useCallback, useEffect, useState } from "react";
import { Box, Drawer } from "@mui/material";
import { useEffectOnceWhen } from "rooks";
import { ResourcesPreviewHeader } from "./components/ResourcesPreviewHeader";
import { PreviewDataGridSkeleton } from "./components/PreviewDataGridSkeleton";
import { SchedulePreviewDataGrid } from "./components/data-grid/SchedulePreviewDataGrid";
import {
  ResourceFiltersWithAction,
  ScheduleStatus,
} from "../../../../../../services/cloudchipr.api";
import { useAppDispatch } from "../../../../../../store/hooks";
import { getScheduleSelectedResourcesThunk } from "../../../../../../store/schedules/thunks/getScheduleSelectedResourcesThunk";
import { getActionValue } from "../../../../../../utils/clean-options";

interface ScheduleResourcesPreviewDrawerProps {
  open: boolean;
  name?: string;
  status?: ScheduleStatus;
  accountIds: string[];
  regions: string[];
  filters: ResourceFiltersWithAction[];
  onClose(): void;
  editingUrl?: string;
  scheduleAction: string | null;
  scheduleId: string;
  gracePeriodEnabled: boolean;
  children?: ReactNode;
}

export const ScheduleResourcesPreviewDrawer: FC<
  ScheduleResourcesPreviewDrawerProps
> = ({
  name,
  status,
  onClose,
  open,
  accountIds,
  filters,
  regions,
  children,
  editingUrl,
  scheduleAction,
  scheduleId,
  gracePeriodEnabled,
}) => {
  const dispatch = useAppDispatch();
  const [renderBody, setRenderBody] = useState(false);

  const onTransitionEnd = useCallback(() => {
    !renderBody && open && setRenderBody(true);
  }, [renderBody, open]);

  useEffectOnceWhen(
    () => {
      filters.forEach(({ filter }) => {
        dispatch(
          getScheduleSelectedResourcesThunk({
            scheduleId,
            accountIds,
            resourceFilters: filter,
            regions,
          }),
        );
      });
    },
    !!(accountIds && filters) && !editingUrl,
  );

  useEffect(() => {
    if (!open) {
      setRenderBody(false);
    }
  }, [open]);

  return (
    <Drawer
      open={open}
      anchor="right"
      onClose={onClose}
      onTransitionEnd={onTransitionEnd}
      PaperProps={{ sx: { width: "80vw" } }}
    >
      <ResourcesPreviewHeader
        editingUrl={editingUrl}
        status={status}
        title={name ?? ""}
        onClose={onClose}
      />

      {children}

      <Box px={3} py={2} overflow="auto">
        {renderBody ? (
          open &&
          filters.map(({ filter, action, snapshot }) => {
            return (
              <SchedulePreviewDataGrid
                key={filter.type}
                gracePeriodEnabled={gracePeriodEnabled}
                resourceType={filter.type}
                regions={regions}
                scheduleId={scheduleId}
                resourceFilter={filter}
                accountIds={accountIds}
                scheduleAction={scheduleAction}
                singleGrid={filters.length === 1}
                action={getActionValue(action, snapshot)}
              />
            );
          })
        ) : (
          <PreviewDataGridSkeleton />
        )}
      </Box>
    </Drawer>
  );
};
