import moment from "moment";
import { GracePeriodFrequencyTypes } from "../../../../common/utils/helpers/gracePeriodCalculations";

export const threeMonthsBySeconds = 7257600;

export const getFrequencyErrorMessage = (
  errMessage: string,
  frequency: GracePeriodFrequencyTypes,
): string => {
  const secondAsString = threeMonthsBySeconds.toString();
  if (!errMessage.includes(secondAsString)) {
    return errMessage;
  }
  if (frequency === "days") {
    return `${errMessage.replace(
      secondAsString,
      moment.duration(threeMonthsBySeconds, "second").asDays().toString(),
    )} ${frequency}.`;
  }
  if (frequency === "weeks") {
    return `${errMessage.replace(
      secondAsString,
      moment.duration(threeMonthsBySeconds, "second").asWeeks().toString(),
    )} ${frequency}.`;
  }
  if (frequency === "hours") {
    return `${errMessage.replace(
      secondAsString,
      moment.duration(threeMonthsBySeconds, "second").asHours().toString(),
    )} ${frequency}.`;
  }
  return "";
};
