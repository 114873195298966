import { FC } from "react";
import { Alert, AlertTitle } from "@mui/material";

interface EmptyFilterAlertProps {
  show: boolean;
}

export const EmptyFilterAlert: FC<EmptyFilterAlertProps> = ({ show }) => {
  if (!show) {
    return null;
  }

  return (
    <Alert variant="outlined" severity="warning" sx={{ mt: 2 }}>
      <AlertTitle>Filter Required</AlertTitle>
      To proceed, please add at least one filter for the selected service.
    </Alert>
  );
};
