import { cloudChiprApi } from "../../../services/cloudchipr.api";
import { RootState } from "../../store";
import { getExecutionLogThunkFixedCacheKey } from "../../../components/pages/execution-log/utils/constants/common";

const executionLogsSelector =
  cloudChiprApi.endpoints.postV3UsersMeExecutionLogs.select(
    getExecutionLogThunkFixedCacheKey,
  );

export const executionLogDataLoadingSelector = (state: RootState) => {
  return executionLogsSelector(state)?.isLoading;
};
