import { createDraftSafeSelector } from "@reduxjs/toolkit";
import { savingsPlansDataForTableSelector } from "./savingsPlansDataForTableSelector";
import { SavingsPlanCoverageDataWithId } from "../../../../../components/pages/commtiments/common/utils/types";
import { addIdInCoverageData } from "../../../utils/helpers/addIdInCoverageData";
import { SavingsPlanCoverageData } from "../../../../../services/cloudchipr.api";

export const savingsPlansCoverageDataSelector = createDraftSafeSelector(
  [savingsPlansDataForTableSelector],
  (data): SavingsPlanCoverageDataWithId[] | undefined => {
    return addIdInCoverageData<SavingsPlanCoverageData>(
      data?.utilization_and_coverage?.coverage_data,
    );
  },
);
