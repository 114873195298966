import { FC } from "react";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import { Stack, Typography } from "@mui/material";

interface ResourceExplorerBillingStatusTitleProps {
  type: "inProgress" | "error" | "warning";
  count: number;
}

export const ResourceExplorerBillingStatusTitle: FC<
  ResourceExplorerBillingStatusTitleProps
> = ({ type, count }) => {
  const label = statuses[type].label;
  const icon = statuses[type].icon;

  return (
    <Stack direction="row" spacing={1} alignItems="center">
      {icon}

      <Typography variant="button" color="primary.main">
        {label} ({count})
      </Typography>
    </Stack>
  );
};

const statuses = {
  inProgress: {
    label: "In Progress",
    icon: <InfoOutlinedIcon color="primary" fontSize="small" />,
  },
  error: {
    label: "Error",
    icon: <ErrorOutlineIcon color="error" fontSize="small" />,
  },
  warning: {
    label: "Warning",
    icon: <WarningAmberIcon color="warning" fontSize="small" />,
  },
};
