import { RootState } from "../../../../../store";
import { cloudChiprApi } from "../../../../../../services/cloudchipr.api";

export const resourceExplorerGranularDataFixedCacheKey =
  "postUsersMeOrganisationsCurrentResourceExplorerGranularCosts-granular-data-fixed-cache-key";

export const resourceExplorerGranularViewResponseSelector = (
  state: RootState,
) => {
  return cloudChiprApi.endpoints.postUsersMeOrganisationsCurrentResourceExplorerGranularCosts.select(
    resourceExplorerGranularDataFixedCacheKey,
  )(state);
};
