import { FC, Fragment, useCallback } from "react";
import {
  CircularProgress,
  IconButton,
  Menu,
  Stack,
  Tooltip,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import { ProtectDialogV2 } from "./menu-items/protect/ProtectDialogV2";
import { ActionsMenuItemsV2 } from "./menu-items/ActionsMenuItemsV2";
import { UpcomingActionIcon } from "./UpcomingAction";
import { Protection } from "./Protection";
import { useDialog } from "../../../../../../../../../../../utils/hooks/useDialog.hook";
import { useMenuHook } from "../../../../../../../../../../../utils/hooks/useMenu.hook";
import {
  ProviderAccessType,
  ResourceType,
  UpcomingAction,
} from "../../../../../../../../../../../services/cloudchipr.api";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../../../../store/hooks";
import { cleanV2ResourcesProtectionLoadingSelector } from "../../../../../../../../../../../store/clean-v2/selectors/cleanV2ResourcesProtectionLoadingSelector";
import { createV2SingleProtectThunk } from "../../../../../../../../../../../store/clean-v2/thunks/thunks/createV2SingleProtectThunk";

interface ActionsMenuCellV2Props {
  resourceType: ResourceType;
  id: string;
  uniqueIdentifier: string;
  isProtected: boolean;
  inProgress?: boolean;
  unavailableActions?: string[];
  failureReason?: string;
  accountId: string;
  protectedBy: string | null;
  upcomingActions?: UpcomingAction[];
  accessType: ProviderAccessType;
}

export const ActionsMenuCellV2: FC<ActionsMenuCellV2Props> = ({
  id,
  resourceType,
  isProtected,
  unavailableActions,
  inProgress,
  failureReason,
  uniqueIdentifier,
  accountId,
  upcomingActions,
  protectedBy,
  accessType,
}) => {
  const dispatch = useAppDispatch();

  const protectionLoading = useAppSelector((state) =>
    cleanV2ResourcesProtectionLoadingSelector(state, [accountId], [id]),
  );
  const { anchor, open, openMenu, closeMenu } = useMenuHook();
  const {
    open: protectDialogOpen,
    openDialog: openProtectDialog,
    closeDialog: closeProtectDialog,
  } = useDialog();

  const closeProtectDialogHandler = useCallback(() => {
    closeProtectDialog();
    closeMenu();
  }, [closeMenu, closeProtectDialog]);

  const onProtectDialogConfirm = useCallback(async () => {
    await dispatch(
      createV2SingleProtectThunk({
        resourceType,
        id: id,
        isProtected,
        accountId,
      }),
    );
    closeProtectDialogHandler();
  }, [
    dispatch,
    id,
    isProtected,
    resourceType,
    closeProtectDialogHandler,
    accountId,
  ]);

  return (
    <Fragment>
      <Stack
        direction="row"
        alignItems="center"
        gap={0.4}
        justifyContent="flex-end"
      >
        {inProgress && (
          <Tooltip title="Action in progress..." arrow>
            <CircularProgress
              size={20}
              color="warning"
              sx={{ verticalAlign: "middle", color: "info.main" }}
            />
          </Tooltip>
        )}

        {isProtected && <Protection protectedBy={protectedBy} />}

        {failureReason && !inProgress && (
          <Tooltip title={failureReason} arrow sx={{ display: "inline" }}>
            <WarningAmberIcon fontSize="small" color="error" />
          </Tooltip>
        )}

        <UpcomingActionIcon upcomingActions={upcomingActions} />

        <IconButton
          size="small"
          onClick={openMenu}
          disabled={inProgress}
          sx={{ p: 0 }}
        >
          <MoreVertIcon />
        </IconButton>
      </Stack>
      <Menu open={open} onClose={closeMenu} anchorEl={anchor}>
        <ActionsMenuItemsV2
          id={id}
          uniqueIdentifier={uniqueIdentifier}
          isProtected={isProtected}
          inProgress={inProgress}
          unavailableActions={unavailableActions}
          closeMenu={closeMenu}
          resourceType={resourceType}
          openProtectDialog={openProtectDialog}
          accessType={accessType}
        />
      </Menu>
      {protectDialogOpen && (
        <ProtectDialogV2
          isProtected={isProtected}
          onClose={closeProtectDialogHandler}
          onConfirm={onProtectDialogConfirm}
          isLoading={protectionLoading}
        />
      )}
    </Fragment>
  );
};
