import { createAsyncThunk } from "@reduxjs/toolkit";
import { enqueueSnackbar } from "notistack";
import { getCostAnomalyAlertsThunk } from "./getCostAnomalyAlertsThunk";
import { cloudChiprApi } from "../../../../services/cloudchipr.api";
import { deleteCostAnomalyAlertFixedCacheKey } from "../../utils/constants/fixedCacheKeys";

export const deleteCostAnomalyAlertByIdThunk = createAsyncThunk(
  "alerts/deleteAlertByIdAndType",
  async (id: string, { dispatch }) => {
    const { deleteUsersMeOrganisationsCurrentAlertsCostAnomalyByAlertId } =
      cloudChiprApi.endpoints;

    try {
      await dispatch(
        deleteUsersMeOrganisationsCurrentAlertsCostAnomalyByAlertId.initiate(
          { alertId: id },
          { fixedCacheKey: deleteCostAnomalyAlertFixedCacheKey },
        ),
      ).unwrap();

      await dispatch(getCostAnomalyAlertsThunk()).unwrap();
    } catch (e) {
      // @ts-expect-error todo: fix api spec
      const errMessage = e?.data?.message || "Something went wrong";
      enqueueSnackbar(errMessage, {
        variant: "snackbarAlert",
        AlertSnackBarProps: {
          severity: "error",
        },
      });
    }
  },
);
