import { grey } from "@mui/material/colors";
import { Theme } from "@mui/material";

export const widgetSetupContentChartHeight = 560;

export const widgetDataGridStyle = {
  table: {
    borderCollapse: "separate",
    tableLayout: "fixed",
    "& tr:hover td": { bgcolor: grey[100] },
    "& tr td": { borderColor: grey[100] },
  },
  tableRow: {
    height: 40,
  },

  tableHeaderRow: {
    "& th": {
      p: 1,
      verticalAlign: "inherit",
      bgcolor: grey[100],
    },
    top: 0,
    position: "sticky",
    zIndex: (theme: Theme) => theme.zIndex.speedDial - 1,
  },

  tableContainer: {
    border: 1,
    borderColor: grey[200],
  },
};
