import { largestCostChangesWidgetDataResponseSelector } from "./largestCostChangesWidgetDataResponseSelector";
import { RootState } from "../../../../../store";
import { ResourceExplorerGridTotal } from "../../../../../../services/cloudchipr.api";

export const largestCostChangesWidgetDataTotalSelector = (
  state: RootState,
  widgetId: string,
): ResourceExplorerGridTotal[] | undefined => {
  const response = largestCostChangesWidgetDataResponseSelector(
    state,
    widgetId,
  );

  return response?.data?.total;
};
