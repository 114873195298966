import { FC, Fragment } from "react";
import { Box } from "@mui/material";
import { useDidMount } from "rooks";
import { SavingsPlanCoverageDrawerContentHeaderInfoCards } from "./SavingsPlanCoverageDrawerContentHeaderInfoCards";
import { CoverageDrawerRecommendationsSection } from "./recommendations/CoverageDrawerRecommendationsSection";
import { SavingsPlanCoverageDrawerChartHeader } from "./SavingsPlanCoverageDrawerChartHeader";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../store/hooks";
import { CommitmentsDrawerHeader } from "../../../../common/components/drawer/header/CommitmentsDrawerHeader";
import { getUtilizationAndCoverageDetailsThunk } from "../../../../../../../store/commitments/thunks/getUtilizationAndCoverageDetailsThunk";
import { commitmentsDetailsDrawerTargetTypeSelector } from "../../../../../../../store/commitments/selectors/common/details-drawer/commitmentsDetailsDrawerTargetTypeSelector";
import { savingsPlanSelectedCoverageDataAccountNameAndIdSelector } from "../../../../../../../store/commitments/selectors/coverage-and-utilization/details-drawer/resource-type/savingsPlanSelectedCoverageDataAccountNameAndIdSelector";
import { CommitmentsDrawerContent } from "../../../../common/components/drawer/CommitmentsDrawerContent";
import { UtilizationAndCoverageDrawerChart } from "../common/UtilizationAndCoverageDrawerChart";
import { UtilizationAndCoverageDrawerDataGrid } from "../common/data-grid/UtilizationAndCoverageDrawerDataGrid";

interface SavingsPlanCoverageDrawerContentProps {
  onClose(): void;
}

export const SavingsPlanCoverageDrawerContent: FC<
  SavingsPlanCoverageDrawerContentProps
> = ({ onClose }) => {
  const dispatch = useAppDispatch();

  const accountId = useAppSelector(
    savingsPlanSelectedCoverageDataAccountNameAndIdSelector,
  );
  const targetType = useAppSelector(commitmentsDetailsDrawerTargetTypeSelector);

  useDidMount(() => {
    dispatch(getUtilizationAndCoverageDetailsThunk());
  });

  return (
    <Fragment>
      <CommitmentsDrawerHeader
        title={targetType === "resource_type" ? "Resource Type" : accountId}
        onClose={onClose}
      >
        {targetType === "resource_type" && (
          <SavingsPlanCoverageDrawerContentHeaderInfoCards />
        )}
      </CommitmentsDrawerHeader>

      <Box width="80dvw" bgcolor="grey.50" height="100%">
        <CommitmentsDrawerContent
          title="Coverage"
          icon="utilization"
          padding="16px 16px 0px 16px"
        >
          <UtilizationAndCoverageDrawerChart
            ChartHeader={SavingsPlanCoverageDrawerChartHeader}
          />

          <UtilizationAndCoverageDrawerDataGrid viewTab="coverage" />
        </CommitmentsDrawerContent>

        <CoverageDrawerRecommendationsSection />
      </Box>
    </Fragment>
  );
};
