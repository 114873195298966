import { FC, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { ListItemIcon, MenuItem, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { SxProps, Theme } from "@mui/system";
import { useAppDispatch } from "../../../../../../../store/hooks";
import { useGetUsersMeOrganisationsCurrentDashboardsPossibleWidgetsQuery } from "../../../../../../../services/cloudchipr.api";
import { setWidgetSelectorState } from "../../../../../../../store/dashboards/dashboardsSlice";

interface DashboardV2ActionProps {
  onClose(): void;
  dashboardId: string;
  iconSx: SxProps<Theme>;
  menuItemSx: SxProps<Theme>;
}

export const DashboardV2AddWidgetAction: FC<DashboardV2ActionProps> = ({
  dashboardId,
  onClose,
  menuItemSx,
  iconSx,
}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { refetch } =
    useGetUsersMeOrganisationsCurrentDashboardsPossibleWidgetsQuery({});

  const openWidgetSelector = useCallback(() => {
    navigate(`/dashboards/${dashboardId}`);
    dispatch(setWidgetSelectorState(true));
    refetch();
    onClose();
  }, [dispatch, refetch, dashboardId, navigate, onClose]);

  return (
    <MenuItem sx={menuItemSx} onClick={openWidgetSelector}>
      <ListItemIcon>
        <AddIcon fontSize="small" sx={iconSx} />
      </ListItemIcon>
      <Typography variant="body2">Add Widget</Typography>
    </MenuItem>
  );
};
