import { createDraftSafeSelector } from "@reduxjs/toolkit";
import { liveUsageMgmtAppliedFilterTemplateSelector } from "./liveUsageMgmtAppliedFilterTemplateSelector";

export const liveUsageMgmtAppliedFilterTemplateFiltersSelector =
  createDraftSafeSelector(
    [liveUsageMgmtAppliedFilterTemplateSelector],
    (filterTemplate) => {
      return filterTemplate?.filters;
    },
  );
