import { isStringResourceType } from "../../../../utils/helpers/resources/isStringResourceType";
import { AccountResourceFilter2 } from "../../../../services/cloudchipr.api";

export const createUniqueIdFilterFromSearchParams = (
  uniqueIdentifiers: string | null,
  resourceType: string | null,
): AccountResourceFilter2 | null => {
  if (!isStringResourceType(resourceType)) {
    return null;
  }

  const idsArr = uniqueIdentifiers?.split(",");

  if (!idsArr?.length) {
    return null;
  }

  return {
    type: resourceType,
    filter_items: [
      {
        type: resourceType,
        filter: {
          key: "provider_unique_identifier",
          value: idsArr,
          operator: "in",
        },
      },
    ],
    filter_groups: [0],
    operators: ["and"],
  };
};
