import { offHoursRecommendationsDismissedResourcesDataByGroupSelector } from "./offHoursRecommendationsDismissedResourcesDataByGroupSelector";
import { selectedIdsSelector } from "../../selected-ids/selectedIdsSelector";
import { RestoreRecommendationByResourceRequest } from "../../../../../services/cloudchipr.api";
import { RootState } from "../../../../store";

export const offHoursRecommendationsDismissedResourcesAccountIdsByResourceIdSelector =
  (state: RootState): RestoreRecommendationByResourceRequest | undefined => {
    const dbResources =
      offHoursRecommendationsDismissedResourcesDataByGroupSelector(
        state,
        "database",
      ) ?? [];
    const computeResources =
      offHoursRecommendationsDismissedResourcesDataByGroupSelector(
        state,
        "compute",
      ) ?? [];

    const selectedIds = selectedIdsSelector(state);

    return [...dbResources, ...computeResources]?.reduce(
      (acc, item) => {
        if (selectedIds.includes(item.id)) {
          acc.account_ids.push(item?.account?.id ?? "");
          acc.resource_identifiers.push(item.resource_identifier);
        }

        return acc;
      },
      {
        account_ids: [],
        resource_identifiers: [],
      } as RestoreRecommendationByResourceRequest,
    );
  };
