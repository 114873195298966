import { FC, useCallback } from "react";
import { Button, Stack } from "@mui/material";
import { useFormik } from "formik";
import { useEffectOnceWhen } from "rooks";
import { ThresholdsSection } from "./threshold/ThresholdsSection";
import { BudgetsNotifications } from "./BudgetsNotifications";
import { StepsProps } from "../../utils/types/types";
import { Step1InitialValues } from "../../utils/types/formInitialValues";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../../store/hooks";
import { step1FormDataSelector } from "../../../../../../../../../store/budgets/selectors/current-budget/forms/step1FormDataSelector";
import { setCurrentBudgetData } from "../../../../../../../../../store/budgets/budgetsSlice";
import { step1ValidationSchema } from "../../utils/constants/formikValidations";
import { Threshold } from "../../../../../../../../../services/cloudchipr.api";
import { EmailsSelectionErrors } from "../../../../../../../../common/integration-dialogs/components/email/components/email-selection/EmailsSelection";

export const Step1: FC<StepsProps> = ({ onNext, onPrevious }) => {
  const dispatch = useAppDispatch();
  const formInitialData = useAppSelector(step1FormDataSelector);

  const { values, handleSubmit, setFieldValue, isValid, setValues, errors } =
    useFormik({
      initialValues: {} as Step1InitialValues,
      validationSchema: step1ValidationSchema,
      validateOnMount: true,
      onSubmit: (values) => {
        dispatch(
          setCurrentBudgetData({
            email_to: values.email_to,
            thresholds: values.thresholds,
            notifications: values.notifications,
          }),
        );

        onNext();
      },
    });

  const thresholdsChangeHandler = useCallback(
    (thresholds: Threshold[]) => {
      setFieldValue("thresholds", thresholds);
      dispatch(setCurrentBudgetData({ thresholds }));
    },
    [dispatch, setFieldValue],
  );

  useEffectOnceWhen(() => {
    formInitialData && setValues(formInitialData);
  }, !!formInitialData);

  if (values.notifications === undefined || values.thresholds === undefined) {
    return null;
  }

  return (
    <form onSubmit={handleSubmit}>
      <Stack spacing={3}>
        <ThresholdsSection
          thresholds={values.thresholds}
          onThresholdChange={thresholdsChangeHandler}
        />

        <BudgetsNotifications
          emailsErrors={errors.email_to as EmailsSelectionErrors}
          emails={values.email_to}
          setFieldValue={setFieldValue}
          integrations={values.notifications}
        />

        <Stack direction="row" spacing={2}>
          <Button type="button" variant="outlined" onClick={onPrevious}>
            Previous
          </Button>
          <Button type="submit" variant="contained" disabled={!isValid}>
            Next
          </Button>
        </Stack>
      </Stack>
    </form>
  );
};
