import { FC, Fragment } from "react";
import { Button, LinearProgress } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import { BudgetsEmptyState } from "./components/BudgetsEmptyState";
import { BudgetsList } from "./components/budgets-list/BudgetsList";
import { PageHeader } from "../common/PageHeader";
import { useGetUsersMeOrganisationsCurrentBudgetsQuery } from "../../../services/cloudchipr.api";
import { useAppAbility } from "../../../services/permissions";

export const Budgets: FC = () => {
  const { data, isLoading: loading } =
    useGetUsersMeOrganisationsCurrentBudgetsQuery();
  const { can } = useAppAbility();
  const canCreateBudget = can("create", "budget");

  return (
    <Fragment>
      <PageHeader
        title="Budgets"
        actions={
          !!data?.length && (
            <Button
              color="primary"
              variant="contained"
              to="/budgets/create"
              component={RouterLink}
              disabled={!canCreateBudget}
            >
              <AddIcon sx={{ mr: 1 }} /> Create Budget
            </Button>
          )
        }
      />

      {loading && <LinearProgress />}

      {!!data?.length && <BudgetsList />}

      {!loading && !data?.length && <BudgetsEmptyState />}
    </Fragment>
  );
};
