import { allReportsSelector } from "./allReportsSelector";
import { RootState } from "../../store";
import { Report } from "../../../services/cloudchipr.api";

export const reportByIdSelector = (
  state: RootState,
  reportId: string,
): Report | undefined => {
  return allReportsSelector(state)?.find((report) => report.id === reportId);
};
