import { FC, Fragment } from "react";
import { Stack } from "@mui/material";
import { useFlag } from "@unleash/proxy-client-react";
import { UrlCopyButton } from "./URLCopyButton";
import { DashboardAppBarNavigation } from "./breadcrumb-navigation/DashboardAppBarNavigation";
import { useAppSelector } from "../../../../../store/hooks";
import { AddingWidget } from "../adding-widget/AddingWidget";
import { dashboardWidgetsSelector } from "../../../../../store/dashboards/selectors/dashboard/dashboardWidgetsSelector";
import { PageHeader } from "../../../common/PageHeader";
import { SendReportButton } from "../../../common/send-report/SendReportButton";
import { currentDashboardIdSelector } from "../../../../../store/dashboards/selectors/dashboard/currentDashboardIdSelector";
import { ReportsBySourceButton } from "../../../common/reports-by-source/ReportsBySourceButton";

interface DashboardHeaderProps {
  loading: boolean;
}

export const DashboardHeader: FC<DashboardHeaderProps> = ({ loading }) => {
  const enableSubscriptions = useFlag("EnableSubscriptionsPage");
  const widgetsData = useAppSelector(dashboardWidgetsSelector);
  const dashboardId = useAppSelector(currentDashboardIdSelector);

  return (
    <PageHeader
      sticky
      loading={loading}
      title={<DashboardAppBarNavigation loading={loading} />}
      actions={
        <Stack
          spacing={2}
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <UrlCopyButton disabled={loading} />

          {enableSubscriptions && (
            <Fragment>
              <SendReportButton
                disabled={loading}
                sourceType="dashboard"
                sourceId={dashboardId ?? ""}
              />

              <ReportsBySourceButton
                sourceId={dashboardId ?? ""}
                sourceType="dashboard"
              />
            </Fragment>
          )}

          {!!widgetsData?.length && (
            <AddingWidget startIcon disabled={loading} />
          )}
        </Stack>
      }
    />
  );
};
