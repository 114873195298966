import { createDraftSafeSelector } from "@reduxjs/toolkit";
import { recommendationAppliedFiltersSelector } from "../../filters/recommendationAppliedFiltersSelector";

export const rightsizingAdvancedFiltersSettingsSelector =
  createDraftSafeSelector([recommendationAppliedFiltersSelector], (filters) => {
    return {
      multipleRecommendationsPerResource:
        filters.multipleRecommendationsPerResource,
      displayRecommendationsAcrossInstanceFamily:
        filters.displayRecommendationsAcrossInstanceFamily,
    };
  });
