import { Row } from "@tanstack/react-table";
import { hierarchyAllItemsCreationDateFilter } from "./hierarchyAllItemsCreationDateFilter";
import { ReMixedVisibilitiesItemType } from "../../../../resource-explorer-all-views/components/table/utils/types/types";
import { DashboardMixedVisibilitiesItemType } from "../../../../dashboard-all-items/components/table/utils/types/types";
import { isHierarchyFolder } from "../../../../../../store/navigation/utils/helpers/isHierarchyFolder";

export const hierarchyAllItemsCreatedByFilter = <
  T extends ReMixedVisibilitiesItemType | DashboardMixedVisibilitiesItemType,
>(
  row: Row<T>,
  _: string,
  keyword: string,
) => {
  const data = row.original;
  const filterValue = keyword.toLowerCase().trim();
  const filterData = `${data.created_by?.email} ${data.created_by?.name}`
    .toLowerCase()
    .trim();

  if (!isHierarchyFolder(data) && data?.created_by?.name?.includes("deleted")) {
    return "deleted".includes(filterValue);
  }

  if (isHierarchyFolder(data)) {
    const childViewsFilterData = data.items?.reduce((cur, item) => {
      const name = item?.created_by?.name?.includes("deleted")
        ? "deleted"
        : item.created_by?.name;

      return `${cur} ${item.created_by?.email} ${name}`;
    }, "");

    return `${filterData} ${childViewsFilterData?.toLowerCase().trim()}`.includes(
      filterValue,
    );
  }

  return (
    filterData.includes(filterValue) ||
    hierarchyAllItemsCreationDateFilter(row, "", keyword)
  );
};
