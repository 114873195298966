import { RootState } from "../../../store";
import {
  cloudChiprApi,
  ProviderType,
} from "../../../../services/cloudchipr.api";

export const resourceExplorerGroupingTagKeysSelector = (
  state: RootState,
  provider: ProviderType,
) => {
  return cloudChiprApi.endpoints.getUsersMeOrganisationsCurrentResourceExplorerFiltersByFilterProviderAndFilterTypeValues.select(
    {
      filterProvider: provider,
      filterType: "tag",
    },
  )(state);
};
