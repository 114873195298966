import { FC, useCallback } from "react";
import { Box, Stack, Typography } from "@mui/material";
import { Editor } from "../../../../../../../storybook/editor/Editor";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../store/hooks";
import { taskManagementDescriptionSelector } from "../../../../../../../store/task-management/selectors/form/properties/taskManagementDescriptionSelector";
import { updateTaskManagementTask } from "../../../../../../../store/task-management/taskManagementSlice";

export const TaskCreationDescription: FC = () => {
  const dispatch = useAppDispatch();

  const value = useAppSelector(taskManagementDescriptionSelector);

  const changeHandler = useCallback(
    (value: string) => {
      dispatch(updateTaskManagementTask({ description: value }));
    },
    [dispatch],
  );

  return (
    <Stack spacing={1}>
      <Typography fontWeight="medium">Description</Typography>

      <Box sx={editorWrapperSx}>
        <Editor
          value={value ?? ""}
          onChange={changeHandler}
          toolbarOptions={{
            minHeight: "150px",
            buttonList: [["bold", "italic", "strike"], [], ["link"]],
          }}
          placeholder="Add description"
        />
      </Box>
    </Stack>
  );
};

const editorWrapperSx = {
  border: 1,
  borderRadius: 2,
  overflow: "hidden",
  borderColor: "grey.300",
  "& .sun-editor": { border: "none" },
  "& .se-resizing-bar": { display: "none" },
  "& .se-btn-tray": { padding: "8px 16px", lineHeight: 0 },
};
