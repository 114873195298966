import { FC, useCallback } from "react";
import { Button } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useAppAbility } from "../../../../../services/permissions";
import { useGetUsersMeOrganisationsCurrentDashboardsPossibleWidgetsQuery } from "../../../../../services/cloudchipr.api";
import { useAppDispatch } from "../../../../../store/hooks";
import { setWidgetSelectorState } from "../../../../../store/dashboards/dashboardsSlice";

interface AddingWidgetProps {
  startIcon?: boolean;
  variant?: "text" | "contained";
  disabled?: boolean;
}

export const AddingWidget: FC<AddingWidgetProps> = ({
  startIcon,
  variant = "contained",
  disabled,
}) => {
  const dispatch = useAppDispatch();
  const { cannot } = useAppAbility();
  const canNotCreateWidget = cannot("create", "dashboard");
  const { refetch } =
    useGetUsersMeOrganisationsCurrentDashboardsPossibleWidgetsQuery({});

  const openWidgetSelector = useCallback(() => {
    dispatch(setWidgetSelectorState(true));
    refetch();
  }, [dispatch, refetch]);

  return (
    <Button
      startIcon={startIcon ? <AddIcon /> : undefined}
      disabled={canNotCreateWidget || disabled}
      variant={variant}
      onClick={openWidgetSelector}
      sx={{ whiteSpace: "nowrap" }}
    >
      Add Widget
    </Button>
  );
};
