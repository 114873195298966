import { resourceTypeAbbreviationNames } from "./resourceTypeAbbreviationNames";
import { resourceTypeShortNames } from "./resourceTypeShortNames";
import { resourceTypeLongNames } from "./resourceTypeLongNames";
import { resourceServiceLongNames } from "./resourceServiceLongNames";
import { resourceServiceShortNames } from "./resourceServiceShortNames";
import { resourceServiceAbbreviationNames } from "./resourceServiceAbbreviationNames";
import { ResourceType } from "../../../../services/cloudchipr.api";

export const resourceTypeNamesData: Record<
  "short" | "long" | "abbreviation",
  Map<ResourceType, { singular: string; plural: string }>
> = {
  short: resourceTypeShortNames,
  long: resourceTypeLongNames,
  abbreviation: resourceTypeAbbreviationNames,
};

export const resourceTypeServiceNamesData: Record<
  "short" | "long" | "abbreviation",
  Map<ResourceType, { singular: string; plural: string }>
> = {
  short: resourceServiceShortNames,
  long: resourceServiceLongNames,
  abbreviation: resourceServiceAbbreviationNames,
};
