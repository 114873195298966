import { FC } from "react";
import {
  TableCell,
  TableRow,
  Stack,
  Link,
  Typography,
  Box,
} from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { IntegrationsListActionCell } from "./IntegrationsListActionCell";
import { IntegrationsListInUseCell } from "./in-use-cell/IntegrationsListInUseCell";
import { Integration } from "../../../../../services/cloudchipr.api";
import { useAppAbility } from "../../../../../services/permissions";
import { IntegrationLogo } from "../../../../common/integration-dialogs/components/common/IntegrationLogo";

interface IntegrationsListItemProps {
  integration: Integration;
}

export const IntegrationsListGridRow: FC<IntegrationsListItemProps> = ({
  integration,
}) => {
  const { cannot } = useAppAbility();
  const canNotEditIntegration = cannot("edit", "integration");

  return (
    <TableRow>
      <TableCell sx={{ pl: 3 }}>
        <Stack direction="row" alignItems="center" spacing={2}>
          <Box pr={1} pt={0.5}>
            <IntegrationLogo
              variant="colored"
              type={integration.type}
              width={15}
            />
          </Box>

          {canNotEditIntegration ? (
            integration.name
          ) : (
            <Link
              component={RouterLink}
              color="text.primary"
              to={`/integrations/edit/${integration.type}/${integration.id}`}
            >
              {integration.name}
            </Link>
          )}
        </Stack>
      </TableCell>

      <TableCell>
        <Typography variant="body2" sx={{ textTransform: "capitalize" }}>
          Notification
        </Typography>
      </TableCell>

      <TableCell>
        <IntegrationsListInUseCell id={integration.id} />
      </TableCell>

      <IntegrationsListActionCell
        integrationId={integration.id}
        type={integration.type}
        name={integration.name}
      />
    </TableRow>
  );
};
