import { FC } from "react";
import { Stack } from "@mui/material";
import { CommitmentsTermFilter } from "./CommitmentsTermFilter";
import { CommitmentsPaymentOptionsFilter } from "./CommitmentsPaymentOptionsFilter";
import { CommitmentsLockBackPeriodFilter } from "./CommitmentsLockBackPeriodFilter";
import { RecommendationLevelFilter } from "./recommendation-level-filter/RecommendationLevelFilter";
import { CommitmentsTypeFilter } from "./CommitmentsTypeFilter";
import { CommitmentType } from "../../../../../../../../services/cloudchipr.api";

interface CommitmentsRecommendationsFiltersProps {
  type: CommitmentType;
  hideFilters?: Set<string>;
}
export const CommitmentsRecommendationsFilters: FC<
  CommitmentsRecommendationsFiltersProps
> = ({ type, hideFilters }) => {
  return (
    <Stack direction="row" spacing={1}>
      {!hideFilters?.has("type") && <CommitmentsTypeFilter type={type} />}

      <RecommendationLevelFilter />

      <CommitmentsTermFilter />

      <CommitmentsPaymentOptionsFilter />

      <CommitmentsLockBackPeriodFilter />
    </Stack>
  );
};
