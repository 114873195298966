import { FC, useCallback, useEffect, useState } from "react";
import { CustomCellRendererProps } from "@ag-grid-community/react";
import { Task } from "../../../../../../services/cloudchipr.api";
import { useAppDispatch } from "../../../../../../store/hooks";
import { taskManagementUpdateTaskThunk } from "../../../../../../store/task-management/thunks/actions/taskManagementUpdateTaskThunk";
import { TaskPropertySelection } from "../../../../common/task-management/components/common/properties/TaskPropertySelection";

export const StatusCellRenderer: FC<CustomCellRendererProps<Task>> = ({
  data,
}) => {
  const dispatch = useAppDispatch();
  const [statusId, setStatusId] = useState<string | null>();

  const changeHandler = useCallback(
    (value: string | null) => {
      if (!data?.id || statusId === value) {
        return null;
      }

      setStatusId(value);

      dispatch(
        taskManagementUpdateTaskThunk({
          task_ids: [data.id],
          status_id: value,
        }),
      );
    },
    [dispatch, data?.id, statusId],
  );

  useEffect(() => {
    setStatusId(data?.status?.id ?? null);
  }, [data?.status?.id]);

  if (!data) {
    return null;
  }

  return (
    <TaskPropertySelection
      required
      optionsKey="statuses"
      value={statusId}
      label="Status"
      onChange={changeHandler}
    />
  );
};
