import { createDraftSafeSelector } from "@reduxjs/toolkit";
import { selectedIdsSelector } from "./selectedIdsSelector";
import { rightsizingRecommendationsDataSelector } from "../rightsizing/data/rightsizingRecommendationsDataSelector";
import { recommendationComputeDailyActiveDataSelector } from "../off-hours/data/compute/recommendationComputeDailyActiveDataSelector";
import { recommendationComputeWeeklyActiveDataSelector } from "../off-hours/data/compute/recommendationComputeWeeklyActiveDataSelector";
import { recommendationDbDailyActiveDataSelector } from "../off-hours/data/database/recommendationDbDailyActiveDataSelector";
import { recommendationDbWeeklyActiveDataSelector } from "../off-hours/data/database/recommendationDbWeeklyActiveDataSelector";
import { recommendationByIdSeparatorByTypeCombiner } from "../../combiners/recommendationByIdSeparatorByTypeCombiner";

export const selectedActiveRecommendationIdsByTypesSelector =
  createDraftSafeSelector(
    [
      selectedIdsSelector,
      recommendationDbDailyActiveDataSelector,
      recommendationDbWeeklyActiveDataSelector,
      recommendationComputeDailyActiveDataSelector,
      recommendationComputeWeeklyActiveDataSelector,
      rightsizingRecommendationsDataSelector,
    ],
    recommendationByIdSeparatorByTypeCombiner,
  );
