import { rightsizingRecommendationsDismissedResourcesDataSelector } from "./rightsizingRecommendationsDismissedResourcesDataSelector";
import { RootState } from "../../../../store";

export const rightsizingRecommendationsDismissedResourcesDataTotalLengthSelector =
  (state: RootState): number => {
    const data =
      rightsizingRecommendationsDismissedResourcesDataSelector(state);

    return data?.length ?? 0;
  };
