import { FC, ReactElement } from "react";
import { Link } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { Account } from "../../../../../../services/cloudchipr.api";
import {
  loadingAccountStatuses,
  nonWorkingAccountStatuses,
} from "../../../../../../utils/constants/accounts";

interface AccountCardRouterWrapperProps {
  status?: Account["status"];
  navigateTo: string;
  children: ReactElement;
}

export const AccountCardRouterWrapper: FC<AccountCardRouterWrapperProps> = ({
  status,
  navigateTo,
  children,
}) => {
  if (
    !status ||
    nonWorkingAccountStatuses.has(status) ||
    loadingAccountStatuses.has(status)
  ) {
    return children;
  }

  return (
    <Link
      underline="none"
      color="inherit"
      component={RouterLink}
      to={navigateTo}
    >
      {children}
    </Link>
  );
};
