import { createAsyncThunk } from "@reduxjs/toolkit";
import { enqueueSnackbar } from "notistack";
import { cloudChiprApi } from "../../../../../../services/cloudchipr.api";

import { RootState } from "../../../../../store";
import { costAndUsageWidgetNotFoundSelector } from "../../../../selectors/widgets/cost-and-usage/costAndUsageWidgetNotFoundSelector";

export const getCostAndUsageSumWidgetDataByWidgetIdThunk = createAsyncThunk(
  "dashboards/getCostAndUsageSumWidgetDataByWidgetId",
  async (widgetId: string, { dispatch, getState }) => {
    const state = getState() as RootState;

    const {
      getUsersMeOrganisationsCurrentWidgetsCostAndUsageSummaryByWidgetId,
    } = cloudChiprApi.endpoints;

    const viewNotFound = costAndUsageWidgetNotFoundSelector(state, widgetId);

    if (viewNotFound) {
      return;
    }

    try {
      return await dispatch(
        getUsersMeOrganisationsCurrentWidgetsCostAndUsageSummaryByWidgetId.initiate(
          { widgetId },
          { forceRefetch: true },
        ),
      ).unwrap();
    } catch (e) {
      // @ts-expect-error todo: api fix
      const errMessage = e?.data?.message || "Something went wrong";
      enqueueSnackbar(errMessage, {
        variant: "snackbarAlert",
        AlertSnackBarProps: { severity: "error" },
      });
    }
  },
);
