import { FC } from "react";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  IconButton,
  Stack,
} from "@mui/material";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import { Link as RouterLink } from "react-router-dom";
import LaunchIcon from "@mui/icons-material/Launch";
import { VideoDialog } from "./VideoDialog";
import { ProviderIcon } from "../../../../../common/ProviderIcon";
import { ProviderType } from "../../../../../../services/cloudchipr.api";
import { useDialog } from "../../../../../../utils/hooks/useDialog.hook";

interface RightsizingRecommendationsEmptyStateCardProps {
  title: string;
  provider: ProviderType;
  img: string;
  actionButtonLabel?: string;
  actionButtonUrl?: string;
}

export const RightsizingRecommendationsEmptyStateCard: FC<
  RightsizingRecommendationsEmptyStateCardProps
> = ({ title, provider, img, actionButtonLabel, actionButtonUrl }) => {
  const { open, openDialog, closeDialog } = useDialog();

  return (
    <Card variant="outlined" sx={{ m: 1, width: 450 }}>
      <CardHeader
        avatar={<ProviderIcon provider={provider} />}
        title={title}
        titleTypographyProps={{ fontWeight: "bold", fontSize: 16 }}
      />
      <Divider light />

      <CardContent>
        <Stack
          sx={{
            backgroundImage: `url(${img})`,
            height: "220px",
            backgroundSize: "cover",
            borderRadius: 1,
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <IconButton
            sx={{
              bgcolor: "rgba(33, 33, 33, 0.5)",
            }}
            onClick={openDialog}
          >
            <PlayArrowIcon fontSize="large" />
          </IconButton>
        </Stack>

        {actionButtonLabel && actionButtonUrl && (
          <Button
            to={actionButtonUrl}
            component={RouterLink}
            target="_blank"
            variant="outlined"
            endIcon={<LaunchIcon />}
            sx={{ mt: 3, flex: 1, width: "100%" }}
          >
            {actionButtonLabel}
          </Button>
        )}
      </CardContent>

      <VideoDialog open={open} provider={provider} onClose={closeDialog} />
    </Card>
  );
};
