import { createDraftSafeSelector } from "@reduxjs/toolkit";
import { allReportsSelector } from "./allReportsSelector";
import { resourceExplorerFlattenHierarchyMixedVisibilitiesMapSelector } from "../../resource-explorer/selectors/views-hierarchy-visibility/resourceExplorerFlattenHierarchyMixedVisibilitiesMapSelector";
import { dashboardFlattenHierarchyMixedVisibilitiesMapSelector } from "../../dashboards/selectors/dashboard-hierarchy-visibility/dashboardFlattenHierarchyMixedVisibilitiesMapSelector";
import { ReportsTableData } from "../utils/types/types";
import { integrationsDataAsObjectSelector } from "../../integrations/selectors/integrationsDataAsObjectSelector";

export const reportsTableDataSelector = createDraftSafeSelector(
  [
    allReportsSelector,
    resourceExplorerFlattenHierarchyMixedVisibilitiesMapSelector,
    dashboardFlattenHierarchyMixedVisibilitiesMapSelector,
    integrationsDataAsObjectSelector,
  ],
  (
    data,
    viewsMap,
    dashboardsMap,
    integrations,
  ): ReportsTableData[] | undefined => {
    return data?.map((report) => {
      const sourceData =
        report.source_type === "dashboard"
          ? dashboardsMap?.[report?.source_id ?? ""]
          : viewsMap?.[report?.source_id ?? ""];

      const usedIntegrations = (
        report?.notifications?.map(
          (notification) => integrations[notification.integration_id],
        ) ?? []
      ).filter((integration) => !!integration);

      return {
        ...report,
        integrations: usedIntegrations,
        sourceName: sourceData?.name ?? undefined,
      };
    });
  },
);
