import { FC } from "react";
import { Stack, Typography } from "@mui/material";
import DoNotDisturbAltIcon from "@mui/icons-material/DoNotDisturbAlt";

interface DashboardNotFoundWidgetProps {
  name: string;
  label: string;
}
export const DashboardNotFoundWidget: FC<DashboardNotFoundWidgetProps> = ({
  name,
  label,
}) => {
  return (
    <Stack p={2} flex={1}>
      <Stack
        bgcolor="grey.50"
        borderRadius={2}
        alignItems="center"
        flex={1}
        justifyContent="center"
      >
        <DoNotDisturbAltIcon color="disabled" sx={{ fontSize: 40 }} />

        <Typography variant="body1" pt={2}>
          The{" "}
          <Typography fontWeight="bold" component="span">
            {name}
          </Typography>{" "}
          {label} has been deleted.
        </Typography>
      </Stack>
    </Stack>
  );
};
