import { RootState } from "../../../store";
import { AlertTabType } from "../../utils/types/types";
import { cloudChiprApi } from "../../../../services/cloudchipr.api";
import { generateToggleAlertActionFixedCacheKey } from "../../utils/constants/fixedCacheKeys";

export const toggleAlertStatusLoadingSelector = (
  state: RootState,
  id: string,
  alertsActiveTab: AlertTabType,
) => {
  const {
    patchUsersMeOrganisationsCurrentAlertsCostAnomalyByAlertId,
    patchUsersMeOrganisationsCurrentAlertsUtilizationAndCoverageByAlertId,
  } = cloudChiprApi.endpoints;

  const endpoint =
    alertsActiveTab === "costAnomaly"
      ? patchUsersMeOrganisationsCurrentAlertsCostAnomalyByAlertId
      : patchUsersMeOrganisationsCurrentAlertsUtilizationAndCoverageByAlertId;

  const fixedCacheKey = generateToggleAlertActionFixedCacheKey(
    id,
    alertsActiveTab,
  );

  return endpoint.select(fixedCacheKey)(state)?.isLoading;
};
