import { SvgIcon, SvgIconProps, useTheme, colors } from "@mui/material";

const VmSvgIcon = ({ primary }: SvgIconProps & { primary?: boolean }) => {
  const { palette } = useTheme();

  const color = primary ? palette.primary.main : palette.action.active;
  const colorLight = primary ? colors.indigo["300"] : palette.action.disabled;

  return (
    <SvgIcon>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M15 9H9V15H15V9Z" fill={color} fillOpacity="0.5" />
        <path d="M13 2H11V6H13V2Z" fill={colorLight} />
        <path d="M9 2H7V6H9V2Z" fill={colorLight} />
        <path d="M17 2H15V6H17V2Z" fill={colorLight} />
        <path d="M13 18H11V22H13V18Z" fill={color} />
        <path d="M9 18H7V22H9V18Z" fill={color} />
        <path d="M17 18H15V22H17V18Z" fill={color} />
        <path d="M18 11V13H22V11H18Z" fill={color} />
        <path d="M18 15V17H22V15H18Z" fill={color} />
        <path d="M18 7V9H22V7H18Z" fill={color} />
        <path d="M2 11V13H6V11H2Z" fill={colorLight} />
        <path d="M2 15V17H6V15H2Z" fill={colorLight} />
        <path d="M2 7V9H6V7H2Z" fill={colorLight} />
        <path
          d="M5 5V19H19V5H5ZM17 17H7V7H17V17Z"
          fill={color}
          fillOpacity="0.5"
        />
        <path d="M9 15H15L12 12L9 15Z" fill={colorLight} />
        <path d="M12 12L15 15V9L12 12Z" fill={color} />
      </svg>
    </SvgIcon>
  );
};

VmSvgIcon.muiName = "VmSvgIcon";

export default VmSvgIcon;
