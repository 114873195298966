export const wrapWithParentKey = (key: string) => {
  // todo: used in many places, bad solution, rethink one more time
  return `resource.${key}`;
};

export const nfVisibilitiesConverter = (
  visibility: Record<string, boolean>,
) => {
  return Object.entries(visibility).reduce(
    (vis, [key, val]) => {
      if (!key.startsWith(wrapWithParentKey(""))) {
        vis[wrapWithParentKey(key)] = val;
      } else {
        vis[key] = val;
      }

      return vis;
    },
    {} as Record<string, boolean>,
  );
};
export const nfSortingConverter = (
  sorting: { id: string; desc: boolean }[],
) => {
  return sorting.map((sort) => {
    if (!sort.id.startsWith(wrapWithParentKey(""))) {
      return {
        ...sort,
        id: wrapWithParentKey(sort.id),
      };
    }
    return sort;
  });
};
