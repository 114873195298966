import { FC, useCallback, useMemo } from "react";
import {
  Checkbox,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";

import { RolePermissionsCollapseContentItem } from "./RolePermissionsCollapseContentItem";
import { UserRoleUpdatedPermissionsKeys } from "./utils/types";
import { PermissionWithSelectedState } from "../../../../../../../store/user/utils/types/common";
import { Permission } from "../../../../../../../services/cloudchipr.api";

interface RolePermissionsCollapseContentProps {
  data?: PermissionWithSelectedState[];
  onPermissionChange(
    group: Permission["group"],
    updatedPermissions: UserRoleUpdatedPermissionsKeys[],
  ): void;
}

export const RolePermissionsCollapseContent: FC<
  RolePermissionsCollapseContentProps
> = ({ data, onPermissionChange }) => {
  const headerCheckboxState = useMemo(() => {
    const checkedCounts = data?.filter((item) => item.selected);
    return checkedCounts?.length === data?.length;
  }, [data]);

  const headerCheckboxChangeHandler = useCallback(() => {
    if (!data) {
      return;
    }
    const updatedPermissions = data.map((item) => {
      return {
        selected: !headerCheckboxState,
        name: item.name,
      };
    });
    const firstItem = data?.at(0)?.group;

    if (firstItem) {
      onPermissionChange(firstItem, updatedPermissions);
    }
  }, [onPermissionChange, data, headerCheckboxState]);

  if (!data) {
    return null;
  }

  return (
    <Stack direction="row" spacing={2} alignItems="center">
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell sx={cellPaddings}>
              <Checkbox
                size="small"
                checked={headerCheckboxState}
                onChange={headerCheckboxChangeHandler}
              />
            </TableCell>
            <TableCell sx={cellPaddings}>Permission</TableCell>
            <TableCell sx={cellPaddings}>Description</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {data.map((item) => {
            return (
              <RolePermissionsCollapseContentItem
                key={item.group + item.name}
                {...item}
                onChange={onPermissionChange}
              />
            );
          })}
        </TableBody>
      </Table>
    </Stack>
  );
};

const cellPaddings = {
  py: 0,
  px: 0.5,
};
