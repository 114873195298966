import { getUsersMeOrganisationsCurrentWidgetsLargestCostChangesByWidgetIdSelector } from "./getUsersMeOrganisationsCurrentWidgetsLargestCostChangesByWidgetIdSelector";
import { RootState } from "../../../../../store";

export const largestCostChangesWidgetDataResponseSelector = (
  state: RootState,
  widgetId: string,
) => {
  return getUsersMeOrganisationsCurrentWidgetsLargestCostChangesByWidgetIdSelector(
    {
      widgetId,
    },
  )(state);
};
