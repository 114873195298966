import moment from "moment/moment";
import { AlertsData } from "../../../../../../../../../../../store/alerts/utils/types/types";
import { formatDate } from "../../../../../../../../../../../utils/helpers/date-time/datetime-format";

export const getCostAnomalyPreviewDatesFromTimeInterval = (
  timeInterval?: number,
  timeIntervalUnit?: AlertsData["timeIntervalUnit"],
) => {
  if (!timeInterval || !timeIntervalUnit) {
    return {};
  }

  const dateTo = formatDate(moment(), {
    type: "date",
  });

  const dateFrom = formatDate(
    moment().subtract(timeInterval, timeIntervalUnit),
    {
      type: "date",
    },
  );

  const previousDateTo = formatDate(
    moment().subtract(timeInterval, timeIntervalUnit).subtract(1, "day"),
    {
      type: "date",
    },
  );
  const previousDateFrom = formatDate(
    moment()
      .subtract(timeInterval * 2, timeIntervalUnit)
      .subtract(1, "day"),
    {
      type: "date",
    },
  );

  return { dateFrom, dateTo, previousDateFrom, previousDateTo };
};
