import { FC, useCallback } from "react";
import { ListItemIcon, ListItemText, MenuItem } from "@mui/material";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../store/hooks";
import { useAppAbility } from "../../../../../../../../services/permissions";
import {
  setReportsData,
  toggleReportsOpenDrawer,
} from "../../../../../../../../store/reports/reportsSlice";
import { reportByIdSelector } from "../../../../../../../../store/reports/selector/reportByIdSelector";
import { formatNotificationsFromSlackToHtml } from "../../../../../../../utils/helpers/formatNotificationsFromSlackToHtml";

interface ReportEditActionProps {
  reportId: string;
  closeMenu(): void;
}

export const ReportEditAction: FC<ReportEditActionProps> = ({
  closeMenu,
  reportId,
}) => {
  const dispatch = useAppDispatch();
  const { cannot } = useAppAbility();
  const cannotEdit = cannot("edit", "report");

  const reportData = useAppSelector((state) =>
    reportByIdSelector(state, reportId),
  );

  const handleClick = useCallback(() => {
    closeMenu();

    if (!reportData) {
      return;
    }

    dispatch(toggleReportsOpenDrawer());
    dispatch(
      setReportsData({
        ...reportData,
        notifications: formatNotificationsFromSlackToHtml(
          reportData.notifications,
        ),
      }),
    );
  }, [dispatch, closeMenu, reportData]);

  return (
    <MenuItem dense onClick={handleClick} disabled={cannotEdit}>
      <ListItemIcon>
        <EditOutlinedIcon fontSize="small" />
      </ListItemIcon>
      <ListItemText>Edit</ListItemText>
    </MenuItem>
  );
};
