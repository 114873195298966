import { dashboardHierarchyVisibilitiesSelector } from "./dashboardHierarchyVisibilitiesSelector";
import { RootState } from "../../../store";
import { GetV2UsersMeOrganisationsCurrentDashboardsHierarchyApiResponse } from "../../../../services/cloudchipr.api";

export const dashboardHierarchyVisibilitiesDataSelector = (
  state: RootState,
):
  | GetV2UsersMeOrganisationsCurrentDashboardsHierarchyApiResponse
  | undefined => {
  return dashboardHierarchyVisibilitiesSelector(state)?.data;
};
