import { FC, Fragment, useCallback } from "react";
import { Chip, Stack, Typography } from "@mui/material";
import { TypographyWithTooltip } from "../../TypographyWithTooltip";
import { ProviderIcon } from "../../ProviderIcon";
import { FilterTriggerComponentProps } from "../../select/dropdown-select/utils/types/filterTriggerComponentProps";
import { AccountOptionType } from "../utils/types/types";

export const AccountSelectTriggerInputComponent: FC<
  FilterTriggerComponentProps
> = ({
  options,
  selectedValues,
  disabled,
  setSelectedValues,
  onCloseAndSubmit,
}) => {
  const allSelected = selectedValues.length === options.length;

  const account = options.find(
    (option) => option.value === selectedValues.at(0),
  )?.rawValue as AccountOptionType;

  const deleteAllHandler = useCallback(() => {
    setSelectedValues([]);
    onCloseAndSubmit([]);
  }, [setSelectedValues, onCloseAndSubmit]);

  const deleteHandler = useCallback(() => {
    const updatedValues = selectedValues.filter((sv) => sv !== account.value);

    setSelectedValues(updatedValues);
    onCloseAndSubmit(updatedValues);
  }, [setSelectedValues, account, onCloseAndSubmit, selectedValues]);

  return (
    <Stack p={1} direction="row" width="100%">
      {allSelected ? (
        <Chip
          size="small"
          variant="outlined"
          label="All accounts"
          disabled={disabled}
          onDelete={deleteAllHandler}
        />
      ) : (
        <Fragment>
          {account && (
            <Chip
              size="small"
              sx={{ pl: 1 }}
              variant="outlined"
              disabled={disabled}
              onDelete={deleteHandler}
              label={
                <TypographyWithTooltip
                  title={account.label}
                  fontSize="inherit"
                />
              }
              avatar={
                account.accountType ? (
                  <ProviderIcon provider={account.accountType} />
                ) : undefined
              }
            />
          )}

          {selectedValues.length > 1 && (
            <Typography
              ml={1}
              variant="body2"
              whiteSpace="nowrap"
              color="text.secondary"
            >
              + {selectedValues.length - 1} accounts
            </Typography>
          )}
        </Fragment>
      )}
    </Stack>
  );
};
