export const arrayIsEqual = (
  arr1: (string | number)[],
  arr2: (string | number)[],
) => {
  if (arr1 === arr2) {
    return true;
  }

  if (arr1.length !== arr2.length) {
    return false;
  }

  const arr2Set = new Set(arr2);

  return arr1.every((value) => arr2Set.has(value));
};
