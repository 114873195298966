import * as Yup from "yup";
import momentTz from "moment-timezone";
import { getStartsOnDate } from "./common";
import { formatDate } from "../../../../../../utils/helpers/date-time/datetime-format";
import { Emails } from "../../../../../../services/cloudchipr.api";
import { integrationNotificationValidationSchema } from "../../../../common/notification-selection/utils/validation/validation";

export const startEndDatesValidation = Yup.date()
  .nullable()
  .when(["id", "timeZone"], (id, timeZone) => {
    if (timeZone && !id) {
      return Yup.date()
        .nullable()
        .min(getStartsOnDate(timeZone), "Past date can't be selected");
    }

    return Yup.date().nullable();
  });

export const resourceStopStartValidation = (type: "stop" | "start") =>
  Yup.date()
    .nullable()
    .typeError(`Please select ${type} time`)
    .required(`Please select ${type} time`);

export const automationValidationSchema = Yup.object({
  startDate: startEndDatesValidation,
  endDate: startEndDatesValidation,
  action: Yup.string().required("Please select action"),
  filter: Yup.array()
    .of(
      Yup.object({
        filter: Yup.object({
          type: Yup.string(),
          filter_items: Yup.array().min(
            1,
            "Please select at least one filter for resource",
          ),
        }),
      }),
    )
    .min(1, "At least 1 resource must be selected"),
  min_threshold: Yup.number().nullable().positive(),
});

export const onceAutomationValidationSchema = Yup.date().when(
  ["id", "timeZone"],
  (id, timeZone) => {
    if (timeZone && !id) {
      return Yup.date()
        .nullable()
        .required("Date should be selected")
        .min(
          formatDate(momentTz().tz(timeZone).add(1, "minute")),
          "Past date can't be selected",
        );
    }

    return Yup.date().nullable();
  },
);

export const schedulesIntegrationsValidationSchema = Yup.array()
  .nullable()
  .when("action", {
    is: (action: string) => action?.includes("notify"),
    then: Yup.array()
      .nullable()
      .when("emails", {
        is: (emails: Emails) => !emails,
        then: Yup.array()
          .of(integrationNotificationValidationSchema)
          .min(1, "At least one integration should be selected")
          .required("Please select integration"),
        otherwise: Yup.array()
          .of(integrationNotificationValidationSchema)
          .nullable(),
      }),
  });
