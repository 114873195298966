import { integrationsSelector } from "./integrationsSelector";
import { RootState } from "../../store";
import { IntegrationType } from "../../../components/pages/integrations/components/integrations-create/components/common/integration-type/IntegrationTypeCard";

export const integrationByTypeExistSelector = (
  state: RootState,
  integrationType: IntegrationType,
): boolean | null => {
  const { data } = integrationsSelector(state);

  if (!data) {
    return null;
  }

  return data.some(({ type }) => type === integrationType);
};
