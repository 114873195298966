import { createAsyncThunk } from "@reduxjs/toolkit";
import { getLiveUsageMgmtResourcesWithOutFiltersByProviderThunk } from "./getLiveUsageMgmtResourcesWithOutFiltersByProviderThunk";
import { getLiveUsageMgmtResourcesWithFiltersByProviderThunk } from "./getLiveUsageMgmtResourcesWithFiltersByProviderThunk";
import { ResourceType } from "../../../../services/cloudchipr.api";
import { RootState } from "../../../store";
import { getFirstParentInHierarchyResourceType } from "../../../../utils/helpers/resources/getFirstParentInHierarchyResourceType";
import { liveUsageMgmtAccountIdsSelector } from "../../selectors/store-selectors/liveUsageMgmtAccountIdsSelector";
import { liveUsageMgmtFilterTemplatesEnabledSelector } from "../../selectors/store-selectors/liveUsageMgmtFilterTemplatesEnabledSelector";
import { liveUsageMgmtResourceViewIsWithoutFiltersSelector } from "../../selectors/resource-type-data/liveUsageMgmtResourceViewIsWithoutFiltersSelector";

export const getLiveUsageMgmtResourceTypeDataThunk = createAsyncThunk(
  "liveUsageMgmt/getLiveUsageMgmtResourceTypeData",
  async (resourceType: ResourceType, { dispatch, getState }) => {
    const state = getState() as RootState;

    const showingAllResources =
      liveUsageMgmtResourceViewIsWithoutFiltersSelector(state, resourceType);
    const filterTemplatesEnabled =
      liveUsageMgmtFilterTemplatesEnabledSelector(state);

    const accountIds = liveUsageMgmtAccountIdsSelector(state);

    const type =
      getFirstParentInHierarchyResourceType(resourceType) ?? resourceType;

    if (!accountIds) {
      return;
    }

    if (showingAllResources || !filterTemplatesEnabled) {
      await dispatch(
        getLiveUsageMgmtResourcesWithOutFiltersByProviderThunk(type),
      );
    } else {
      await dispatch(getLiveUsageMgmtResourcesWithFiltersByProviderThunk(type));
    }
  },
);
