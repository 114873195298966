import { FC, useState } from "react";
import { useDidMount } from "rooks";
import BarChartOutlinedIcon from "@mui/icons-material/BarChartOutlined";
import { ResourceExplorerAllViewsHeaderActions } from "./components/ResourceExplorerAllViewsHeaderActions";
import { PageHeader } from "../../../common/PageHeader";
import { useAppSelector } from "../../../../../store/hooks";
import { resourceExplorerHierarchyVisibilitiesDataLoadingSelector } from "../../../../../store/resource-explorer/selectors/views-hierarchy-visibility/resourceExplorerHierarchyVisibilitiesDataLoadingSelector";

export const ResourceExplorerAllViewsHeader: FC = () => {
  const [artificialLoading, setArtificialLoading] = useState(false);
  const loading = useAppSelector(
    resourceExplorerHierarchyVisibilitiesDataLoadingSelector,
  );

  useDidMount(() => {
    if (loading) {
      return;
    }

    setArtificialLoading(true);

    setTimeout(() => setArtificialLoading(false), 1000);
  });

  return (
    <PageHeader
      breadcrumbs={breadcrumbs}
      loading={loading || artificialLoading}
      actions={<ResourceExplorerAllViewsHeaderActions disabled={loading} />}
    />
  );
};

const breadcrumbs = [
  {
    title: "Resource Explorer",
    icon: <BarChartOutlinedIcon fontSize="small" />,
  },
];
