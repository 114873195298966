import { FC } from "react";
import { Stack, Typography } from "@mui/material";

export const EmptyState: FC = () => {
  return (
    <Stack justifyContent="space-between" alignItems="center" py={6}>
      <Typography>Nothing found for your request</Typography>
    </Stack>
  );
};
