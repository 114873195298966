import { FC, Fragment, useEffect } from "react";
import { Navigate, useParams } from "react-router-dom";
import { useDidMount } from "rooks";
import { DashboardHeader } from "./components/header/DashboardHeader";
import { DashboardLayout } from "./components/DashboardLayout";
import { WidgetCreateDrawer } from "./components/adding-widget/widget-create/WidgetCreateDrawer";
import { WidgetSelectDrawer } from "./components/adding-widget/widget-select/WidgetSelectDrawer";
import { EmptyDashboards } from "./components/corner-components/EmptyDashboards";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { getDashboardByIdThunk } from "../../../store/dashboards/thunks/dashboard/getDashboardByIdThunk";
import {
  useGetUsersMeCategoriesQuery,
  useGetUsersMeOrganisationsCurrentSettingsQuery,
} from "../../../services/cloudchipr.api";
import { dashboardNotFoundSelector } from "../../../store/dashboards/selectors/dashboard/dashboardNotFoundSelector";
import { dashboardsCountSelector } from "../../../store/dashboards/selectors/dashboard/dashboardsCountSelector";
import { dashboardsItemsCountSelector } from "../../../store/dashboards/selectors/dashboard-hierarchy/dashboardsItemsCountSelector";
import { currentDashboardIdSelector } from "../../../store/dashboards/selectors/dashboard/currentDashboardIdSelector";
import { getAllReportsThunk } from "../../../store/reports/thunks/getAllReportsThunk";

export const Dashboard: FC = () => {
  const { dashboardId = "" } = useParams<{
    dashboardId: string;
  }>();

  const dispatch = useAppDispatch();

  const currentDashboardId = useAppSelector(currentDashboardIdSelector);
  const dashboardNotFound = useAppSelector((state) =>
    dashboardNotFoundSelector(state, dashboardId),
  );
  const dashboardsCount = useAppSelector(dashboardsCountSelector);
  const dashboardsHierarchyCount = useAppSelector(dashboardsItemsCountSelector);

  useGetUsersMeOrganisationsCurrentSettingsQuery();
  useGetUsersMeCategoriesQuery();

  useEffect(() => {
    dashboardId && dispatch(getDashboardByIdThunk(dashboardId));
  }, [dashboardId, dispatch]);

  useDidMount(() => {
    dispatch(getAllReportsThunk());
  });

  if (dashboardNotFound) {
    return <Navigate to="/not-found" replace={true} />;
  }

  if (dashboardsCount === 0 || dashboardsHierarchyCount === 0) {
    return <EmptyDashboards />;
  }

  return (
    <Fragment>
      <DashboardHeader loading={currentDashboardId !== dashboardId} />

      {currentDashboardId === dashboardId && (
        <DashboardLayout dashboardId={dashboardId} />
      )}

      <WidgetCreateDrawer />
      <WidgetSelectDrawer />
    </Fragment>
  );
};
