import { offHoursRecommendationsLoadingSelector } from "./loading/offHoursRecommendationsLoadingSelector";
import { offHoursDismissedRecommendationsLoadingSelector } from "./loading/offHoursDismissedRecommendationsLoadingSelector";
import { offHoursRecommendationsDismissedResourcesLoadingSelector } from "./dismissed-resources/offHoursRecommendationsDismissedResourcesLoadingSelector";
import { offHoursRecommendationsComputeEmptySelector } from "./data/offHoursRecommendationsComputeEmptySelector";
import { offHoursRecommendationsDbEmptySelector } from "./data/offHoursRecommendationsDbEmptySelector";
import { RootState } from "../../../store";

export const computeRecommendationDoesntExistSelector = (state: RootState) => {
  const activeLoading = offHoursRecommendationsLoadingSelector(state);
  const dismissedLoading =
    offHoursDismissedRecommendationsLoadingSelector(state);
  const dismissedResourcesLoading =
    offHoursRecommendationsDismissedResourcesLoadingSelector(state);

  const computeEmpty = offHoursRecommendationsComputeEmptySelector(state);
  const dbEmpty = offHoursRecommendationsDbEmptySelector(state);

  const loading =
    activeLoading || dismissedLoading || dismissedResourcesLoading;

  return !loading && computeEmpty && !dbEmpty;
};
