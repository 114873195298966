import { cloudChiprApi } from "../../../services/cloudchipr.api";
import { ChartAnswerData } from "../../../components/pages/cloudchat/utils/types";
import { RootState } from "../../store";

export const queryAnswerDataSelector = (state: RootState) => {
  const key = state.cloudchat.postCacheKey;

  const selector = cloudChiprApi.endpoints.postUsersMeChatQuestions.select(key);

  const response = selector(state);

  const data = response?.data?.data as ChartAnswerData;

  if (!data || typeof data !== "object") {
    return null;
  }

  return data;
};
