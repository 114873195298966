import { FC } from "react";
import { Stack, Typography } from "@mui/material";
import { ByteToGibCell } from "./ByteToGibCell";

interface ProcessedDataCellProps {
  data: number | null;
  days?: number | null;
}

export const ProcessedDataCell: FC<ProcessedDataCellProps> = ({
  data,
  days,
}) => {
  return (
    <Stack>
      <ByteToGibCell data={data ?? 0} />
      {days !== null && (
        <Typography variant="caption" color="text.secondary" fontSize={10}>
          {days === 0 ? "for today" : `for the last ${days} days`}
        </Typography>
      )}
    </Stack>
  );
};
