import { Stack, Typography } from "@mui/material";
import { FC } from "react";
import { ProviderIcon } from "../../../../../common/ProviderIcon";
import { TypographyWithTooltip } from "../../../../../common/TypographyWithTooltip";

interface GcpBillingExportItemProps {
  projectId: string;
  dataSet: string;
  tableName: string;
}
export const GcpBillingExportItem: FC<GcpBillingExportItemProps> = ({
  projectId,
  dataSet,
  tableName,
}) => {
  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      spacing={2}
      bgcolor="grey.100"
      borderRadius={2}
      p={2}
    >
      <Stack direction="row" spacing={1} flex={1} overflow="hidden">
        <ProviderIcon provider="gcp" />
        <Stack overflow="hidden">
          <Typography variant="body2" color="text.primary">
            Project ID
          </Typography>
          <TypographyWithTooltip
            title={projectId}
            variant="body1"
            fontWeight="medium"
          />
        </Stack>
      </Stack>
      <Stack overflow="hidden" flex={1}>
        <Typography variant="body2" color="text.primary">
          Dataset Name
        </Typography>
        <TypographyWithTooltip
          title={dataSet}
          variant="body1"
          fontWeight="medium"
        />
      </Stack>
      <Stack overflow="hidden" flex={1}>
        <Typography variant="body2" color="text.primary">
          Table Name
        </Typography>
        <TypographyWithTooltip
          title={tableName ?? "-"}
          variant="body1"
          fontWeight="medium"
        />
      </Stack>
    </Stack>
  );
};
