import { FC, JSXElementConstructor, SyntheticEvent, useCallback } from "react";
import InputAdornment from "@mui/material/InputAdornment";
import LanguageIcon from "@mui/icons-material/Language";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { PopperProps } from "@mui/material/Popper";
import { Autocomplete, Popper, TextField } from "@mui/material";
import { AutocompleteRenderInputParams } from "@mui/material/Autocomplete/Autocomplete";
import {
  getTimeZoneObject,
  TimeZoneObjectType,
  timeZonesList,
} from "../../utils/helpers/date-time/timeZones";

interface TimeZoneSelectProps {
  timeZone: string;
  onChange: (timeZone: string) => void;
}

export const TimeZoneSelect: FC<TimeZoneSelectProps> = ({
  onChange,
  timeZone,
}) => {
  const activeTimeZone = getTimeZoneObject(timeZone);

  const changeHandler = useCallback(
    (_: SyntheticEvent, value: unknown) => {
      const timeZone = (value as TimeZoneObjectType)?.tzCode;

      if (timeZone) {
        onChange(timeZone);
      }
    },
    [onChange],
  );

  const PopperComponent: JSXElementConstructor<PopperProps> = useCallback(
    (props) => <Popper {...props} style={{ width: 600 }} />,
    [],
  );

  const renderInput = useCallback(
    (params: AutocompleteRenderInputParams) => (
      <TextField
        {...params}
        sx={{ minWidth: 220 }}
        placeholder="Search"
        InputProps={{
          ...params.InputProps,
          startAdornment,
          endAdornment,
        }}
        variant="outlined"
      />
    ),
    [],
  );

  return (
    <Autocomplete
      freeSolo
      disablePortal
      disableClearable
      clearOnBlur
      size="small"
      onChange={changeHandler}
      value={activeTimeZone}
      getOptionLabel={(option: any) => option.name}
      sx={{ overflow: "unset", flex: 1 }}
      PopperComponent={PopperComponent}
      options={timeZonesList}
      renderInput={renderInput}
    />
  );
};

const startAdornment = (
  <InputAdornment position="start">
    <LanguageIcon />
  </InputAdornment>
);

const endAdornment = (
  <InputAdornment position="end">
    <ArrowDropDownIcon />
  </InputAdornment>
);
