import { reservationsRecommendationsDataSelector } from "./reservationsRecommendationsDataSelector";
import { RootState } from "../../../../store";

export const reservationsRecommendationsDataLengthSelector = (
  state: RootState,
): number | undefined => {
  const data = reservationsRecommendationsDataSelector(state);

  return data?.length;
};
