import { RootState } from "../../../../store";
import { commitmentsSliceSelector } from "../commitmentsSliceSelector";
import { DateDataPoint } from "../../../../../services/cloudchipr.api";

export const commitmentsDetailsDrawerDateGranularitySelector = (
  state: RootState,
): DateDataPoint => {
  const slice = commitmentsSliceSelector(state);

  return slice.detailsDrawer.dateGranularity;
};
