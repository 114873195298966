import { FC, MouseEvent, ReactElement, useCallback } from "react";
import { Chip, CircularProgress, Tooltip } from "@mui/material";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { accountStatusNames } from "./utils/constants/accountStatusNames";
import { AccountStatus } from "../../../../services/cloudchipr.api";

interface AccountStatusChipProps {
  status?: AccountStatus;
}

export const AccountStatusChip: FC<AccountStatusChipProps> = ({ status }) => {
  const deleteIconClickHandler = useCallback((event: MouseEvent) => {
    event.preventDefault();
  }, []);
  if (!status) {
    return null;
  }

  const label = accountStatusNames.get(status);
  const color = colors.get(status) ?? "error";

  const InitialPullingTooltipIcon = (
    <CircularProgress color="inherit" size={12} />
  );

  const DeactivationTooltipIcon = (
    <Tooltip
      arrow
      title="We can't verify that we have permissions to access your cloud account. Please check your account accesses or reconnect your account"
    >
      <ErrorOutlineIcon />
    </Tooltip>
  );

  let icon = status === "deactivated" ? DeactivationTooltipIcon : undefined;
  if (status === "initial_pulling") {
    icon = InitialPullingTooltipIcon;
  }

  const deleteIconData = deleteIconTooltip.get(status);

  const deleteIcon = deleteIconData?.icon ? (
    <Tooltip title={deleteIconData.title} arrow placement="top">
      {deleteIconData?.icon}
    </Tooltip>
  ) : undefined;

  return (
    <Chip
      size="small"
      color={color}
      variant="outlined"
      label={label}
      icon={icon}
      deleteIcon={deleteIcon}
      onDelete={deleteIconData ? deleteIconClickHandler : undefined}
      sx={{
        pl: 0.5,
        "& .MuiChip-deleteIcon": {
          cursor: "auto",
        },
      }}
    />
  );
};

const colors: Map<string, "warning" | "info" | "success"> = new Map([
  ["deleting", "warning"],
  ["initial_pulling", "info"],
  ["connected", "success"],
]);

const deleteIconTooltip: Map<string, { icon: ReactElement; title: string }> =
  new Map([
    [
      "initial_pulling",
      {
        icon: <InfoOutlinedIcon fontSize="small" color="info" />,
        title: "Analyzing resources and metrics.This may take up to 1 hour.",
      },
    ],
  ]);
