import {
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { FC, useCallback } from "react";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../store/hooks";
import { alertsUtilizationDataAlertTypeSelector } from "../../../../../../../../store/alerts/selectors/utilization-alerts/data/alertsUtilizationDataProviderOrdIdSelector";
import { setAlertsData } from "../../../../../../../../store/alerts/alertsSlice";
import { awsOrganizationSelector } from "../../../../../../../../store/commitments/selectors/organisations/awsOrganizationSelector";

export const AlertsUtilizationProviderOrgIdSelect: FC = () => {
  const dispatch = useAppDispatch();
  const options = useAppSelector(awsOrganizationSelector);
  const selectedOrgId = useAppSelector(alertsUtilizationDataAlertTypeSelector);

  const handleChange = useCallback(
    (event: SelectChangeEvent<string>) => {
      const orgId = event.target.value;

      if (orgId !== selectedOrgId) {
        dispatch(setAlertsData({ providerOrganisationId: orgId }));
      }
    },
    [dispatch, selectedOrgId],
  );

  const renderValue = useCallback((value: string) => {
    return value;
  }, []);

  if (!selectedOrgId) {
    return;
  }

  return (
    <FormControl fullWidth size="small" disabled={!options} sx={{ flex: 1 }}>
      <InputLabel>Select Organization</InputLabel>

      <Select
        fullWidth
        size="small"
        label="Select Organization"
        onChange={handleChange}
        renderValue={renderValue}
        value={selectedOrgId}
      >
        {options?.map((org) => (
          <MenuItem key={org.organization_id} value={org.organization_id ?? ""}>
            <ListItemText
              primary={org.organization_id ?? ""}
              primaryTypographyProps={{ variant: "body1" }}
            />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
