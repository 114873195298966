import {
  ResourceExplorerDynamicFilterItemType,
  ResourceExplorerGrouping,
} from "../../../../../../../services/cloudchipr.api";

export const resourceExplorerEKSGroupings: ResourceExplorerGrouping[] = [
  "cluster",
  "parent_resource_id",
  "type",
  "deployment",
  "workload_name",
  "workload_type",
  "namespace",
];

export const resourceExplorerGroupings: ResourceExplorerGrouping[] = [
  "none",
  "account",
  "service",
  "region",
  "resource",
  "product_family",
  "cloud_provider",
  "cost_allocation_tag",
  "cost_allocation_tag_value",
  "charge_type",
  "instance_family",
  "instance_type",
  "environment",
  "marketplace_service",
  "usage_type",
  "pricing_term",
  "description",
];

export const resourceExplorerAllGroupings: ResourceExplorerGrouping[] = [
  ...resourceExplorerGroupings,
  ...resourceExplorerEKSGroupings,
];

export const resourcesExplorerGroupingLabels: Map<
  ResourceExplorerGrouping,
  string
> = new Map([
  ["none", "None"],
  ["category", "Categories"],
  ["account", "Accounts"],
  ["cloud_provider", "Cloud Providers"],
  ["service", "Services"],
  ["region", "Regions"],
  ["product_family", "Product Families"],
  ["charge_type", "Charge Types (Discounts)"],
  ["resource", "Resources"],
  ["instance_family", "Instance Families"],
  ["instance_type", "Instance Types"],
  ["environment", "Environments"],
  ["marketplace_service", "Marketplace Services"],

  ["pricing_term", "Pricing Terms"],
  ["usage_type", "Usage Types"],
  ["description", "Items Description"],

  // EKS
  ["cluster", "Clusters"],
  ["parent_resource_id", "Parent Resource Ids"],
  ["type", "Workload Usage Types"],
  ["deployment", "Deployments"],
  ["workload_name", "Workload Names"],
  ["workload_type", "Workload Types"],
  ["namespace", "Namespaces"],
]);

export const resourcesExplorerGroupingSingularLabels: Map<
  ResourceExplorerGrouping,
  string
> = new Map([
  ["account", "Account"],
  ["category", "Category"],
  ["cloud_provider", "Cloud Provider"],
  ["service", "Service"],
  ["region", "Region"],
  ["product_family", "Product Family"],
  ["charge_type", "Charge Type / Discount"],
  ["resource", "Resource"],
  ["instance_family", "Instance Family"],
  ["instance_type", "Instance Type"],
  ["none", "None"],
  ["environment", "Environment"],
  ["marketplace_service", "Marketplace Service"],

  ["pricing_term", "Pricing Term"],
  ["usage_type", "Usage Type"],
  ["description", "Item Description"],

  // EKS
  ["cluster", "Cluster"],
  ["parent_resource_id", "Parent Resource Id"],
  ["type", "Workload Usage Type"],
  ["deployment", "Deployment"],
  ["workload_name", "Workload Name"],
  ["workload_type", "Workload Type"],
  ["namespace", "Namespace"],
]);

export const possibleGroupingsByCurrentGrouping: Record<
  string,
  ResourceExplorerGrouping[]
> = {
  region: ["region"],
  cloud_provider: ["cloud_provider"],
  account: resourceExplorerGroupings,
  environment: ["environment"],
  marketplace_service: ["marketplace_service"],
  service: ["service"],
  resource: ["product_family", "resource", "account", "region", "service"],
  product_family: [
    "product_family",
    "resource",
    "account",
    "region",
    "usage_type",
    "description",
  ],
  none: ["none"],
  category: ["category"],
  // EKS
  cluster: ["cluster"],
  parent_resource_id: ["parent_resource_id"],
  type: ["type"],
  deployment: ["deployment"],
  workload_name: ["workload_name"],
  workload_type: ["workload_type"],
  namespace: ["namespace"],

  pricing_term: ["pricing_term"],
  usage_type: ["usage_type"],
  description: ["description"],
};

export const filterTreeKeyByCurrentGrouping: Record<
  string,
  {
    type: "array" | "object";
    key: ResourceExplorerDynamicFilterItemType;
  }
> = {
  region: { type: "array", key: "region" },
  account: { type: "array", key: "account" },
  service: { type: "array", key: "service" },
  environment: { type: "array", key: "environment" },
  resource: { type: "array", key: "resource_id" },
  product_family: { type: "array", key: "product_family" },
  cloud_provider: { type: "array", key: "cloud_provider" },
  marketplace_service: { type: "array", key: "marketplace" },
  instance_family: { type: "array", key: "instance_family" },
  instance_type: { type: "array", key: "instance_type" },
  cost_allocation_tag_value: { type: "object", key: "tag" },
  cost_allocation_tag: { type: "object", key: "tag" },
  category: { type: "object", key: "dimension_id" },
  charge_type: { type: "array", key: "charge_type" },
  // EKS
  type: { type: "array", key: "type" },
  cluster: { type: "array", key: "cluster" },
  namespace: { type: "array", key: "namespace" },
  deployment: { type: "array", key: "deployment" },
  workload_name: { type: "array", key: "workload_name" },
  workload_type: { type: "array", key: "workload_type" },
  parent_resource_id: { type: "array", key: "parent_resource_id" },

  pricing_term: { type: "array", key: "pricing_term" },
  description: { type: "array", key: "description" },
  usage_type: { type: "array", key: "usage_type" },
};
