import { createAsyncThunk } from "@reduxjs/toolkit";
import { getReservationsForGranularThunk } from "./getReservationsForGranularThunk";
import { getReservationsForDataGridThunk } from "./getReservationsForDataGridThunk";

export const getReservationsThunk = createAsyncThunk(
  "commitments/getReservations",
  (_: void, { dispatch }) => {
    dispatch(getReservationsForDataGridThunk());
    dispatch(getReservationsForGranularThunk());
  },
);
