import { FC, useCallback } from "react";
import { CommitmentsFilterSelect } from "./CommitmentsFilterSelect";
import { lockBackPeriodFilterOptions } from "./utils/contants/filter-options/recommendationsFiltersOptions";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../store/hooks";
import { setCommitmentsRecommendationsLockBackPeriod } from "../../../../../../../../store/commitments/commitmentsSlice";
import { LookbackPeriod } from "../../../../../../../../services/cloudchipr.api";
import { commitmentsRecommendationsLockBackPeriodSelector } from "../../../../../../../../store/commitments/selectors/recommendations/filters/commitmentsRecommendationsLockBackPeriodSelector";

export const CommitmentsLockBackPeriodFilter: FC = () => {
  const dispatch = useAppDispatch();
  const value = useAppSelector(
    commitmentsRecommendationsLockBackPeriodSelector,
  );

  const changeHandler = useCallback(
    (period: string[]) => {
      dispatch(
        setCommitmentsRecommendationsLockBackPeriod(period as LookbackPeriod[]),
      );
    },
    [dispatch],
  );

  if (!value) {
    return null;
  }

  return (
    <CommitmentsFilterSelect
      selectedValues={value}
      label="Based On The Past"
      options={lockBackPeriodFilterOptions}
      onChange={changeHandler}
      showSearch={false}
    />
  );
};
