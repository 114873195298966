import React, { FC, Fragment, SyntheticEvent, useCallback } from "react";
import { Box, Tab, Tabs, Typography } from "@mui/material";
import { useFlag } from "@unleash/proxy-client-react";
import { TabPanelAWSCli } from "./aws/TabPanelAWSCli";
import { TabPanelAWSConsole } from "./aws/TabPanelAWSConsole";
import { TabPanelAWSTerraform } from "./aws/TabPanelAWSTerraform";
import { TabPanelGCPCli } from "./gcp/components/TabPanelGCPCli";
import { TabPanelGCPConsole } from "./gcp/TabPanelGCPConsole";
import { gcpRoles } from "./gcp/utils/gcp-roles";
import { useAppDispatch, useAppSelector } from "../../../../../store/hooks";
import {
  AccessToAccountTypeEnum,
  setAccessToAccountType,
} from "../../../../../store/account-setup/accountSetupSlice";
import { accessToAccountTypeSelector } from "../../../../../store/account-setup/selectors/accessToAccountTypeSelector";
import { providerSelector } from "../../../../../store/account-setup/selectors/providerSelector";
import { gcpServiceAccountSelector } from "../../../../../store/account-setup/selectors/gcpServiceAccountSelector";
import { providerAccessTypeSelector } from "../../../../../store/account-setup/selectors/providerAccessTypeSelector";
import { gcpConnectionTypeSelector } from "../../../../../store/account-setup/selectors/gcpConnectionTypeSelector";

export const AccessToAccountsTabs: FC = () => {
  const dispatch = useAppDispatch();
  const provider = useAppSelector(providerSelector);
  const type = useAppSelector(accessToAccountTypeSelector);
  const gcpServiceAccount = useAppSelector(gcpServiceAccountSelector);

  const handleTabChange = useCallback(
    (_: SyntheticEvent, value: AccessToAccountTypeEnum) => {
      dispatch(setAccessToAccountType(value));
    },
    [dispatch],
  );
  const providerAccessType = useAppSelector(providerAccessTypeSelector);
  const connectionType = useAppSelector(gcpConnectionTypeSelector);

  const roles = gcpRoles.get(connectionType)?.get(providerAccessType);

  const enableTerraformConnection = useFlag("EnableTerraformConnection");

  return (
    <Fragment>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }} my={2}>
        <Tabs variant="fullWidth" onChange={handleTabChange} value={type}>
          <Tab
            label={`Run through ${provider.toUpperCase()} Console`}
            value={AccessToAccountTypeEnum.CONSOLE_LINK}
          />
          <Tab
            label="Run through CLI"
            value={AccessToAccountTypeEnum.CLI_COMMAND}
          />
          {provider === "aws" && enableTerraformConnection && (
            <Tab
              label="Run Through Terraform"
              value={AccessToAccountTypeEnum.TERRAFORM}
            />
          )}
        </Tabs>
      </Box>

      {provider === "gcp" && (
        <Fragment>
          {type === AccessToAccountTypeEnum.CLI_COMMAND && <TabPanelGCPCli />}
          {type === AccessToAccountTypeEnum.CONSOLE_LINK && (
            <Fragment>
              <Typography variant="body2" color="text.secondary" mt={3}>
                {connectionType === "single"
                  ? "Here is the Cloudchipr service account that we’ll use to get access to your GCP project"
                  : "Here is Cloudchipr's service account, which will be used to access your GCP organization"}
              </Typography>
              <TabPanelGCPConsole
                roles={roles}
                serviceAccount={gcpServiceAccount}
                description={
                  <Typography variant="body2" color="text.secondary" mb={2}>
                    Please go to GCP Console and add the following IAM role to
                    provided service account.
                  </Typography>
                }
                guideText="Don’t know how to add IAM Roles?"
              />
            </Fragment>
          )}
        </Fragment>
      )}

      {provider === "aws" && (
        <Fragment>
          {type === AccessToAccountTypeEnum.CLI_COMMAND && <TabPanelAWSCli />}
          {type === AccessToAccountTypeEnum.CONSOLE_LINK && (
            <TabPanelAWSConsole />
          )}
          {type === AccessToAccountTypeEnum.TERRAFORM && (
            <TabPanelAWSTerraform />
          )}
        </Fragment>
      )}
    </Fragment>
  );
};
