import { SortingFn } from "@tanstack/react-table";
import { ResourceCreationData } from "../../../../services/cloudchipr.api";

export const sortWithCreatedBy: SortingFn<any> = (
  currentRow,
  nextRow,
  columnId,
) => {
  const current =
    (currentRow.getValue(columnId) as ResourceCreationData)?.creator ?? "";
  const next =
    (nextRow.getValue(columnId) as ResourceCreationData)?.creator ?? "";

  return current.localeCompare(next);
};
