import { costBreakdownWidgetDataByWidgetIdSelector } from "./costBreakdownWidgetDataByWidgetIdSelector";
import { RootState } from "../../../../../store";

export const costBreakdownWidgetTotalCostDateDetailsSelector = (
  state: RootState,
  widgetId: string,
) => {
  return costBreakdownWidgetDataByWidgetIdSelector(state, widgetId)
    ?.total_cost_date_details;
};
