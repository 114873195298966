import {
  FC,
  SyntheticEvent,
  useCallback,
  useLayoutEffect,
  useState,
} from "react";
import { Divider, Stack, Tab } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { PeriodSelection } from "./period/PeriodSelection";
import { RangeSelection } from "./range/RangeSelection";
import {
  Dates,
  DateType,
  DateUnit,
  NullableDate,
} from "../../../../../services/cloudchipr.api";

type TabType = "range" | "past" | "last" | "";

interface DateRangePickerDateIntervalSectionProps {
  onClose(): void;
  maxDate?: string;
  selectedDates: Dates;
  highlightToday?: boolean;
  quarterStartMonth?: number;
  onDateSelect(dates: Dates): void;
  minDate?: string;
  availableMinDate?: string;
}

export const DateRangePickerDateIntervalSection: FC<
  DateRangePickerDateIntervalSectionProps
> = ({
  highlightToday,
  selectedDates,
  onDateSelect,
  onClose,
  minDate,
  maxDate,
  quarterStartMonth,
  availableMinDate,
}) => {
  const [activeTab, setActiveTab] = useState<TabType>("");

  const tabChangeHandler = useCallback((_: SyntheticEvent, t: TabType) => {
    setActiveTab(t);
  }, []);

  const rangeSelectHandler = useCallback(
    (from: NullableDate, to: NullableDate) => {
      onDateSelect({ from, to, label: null });
    },
    [onDateSelect],
  );

  const periodSelectHandler = useCallback(
    (type: DateType, unit: DateUnit, value: number) => {
      onDateSelect({ from: null, to: null, label: null, type, unit, value });
      onClose();
    },
    [onDateSelect, onClose],
  );

  useLayoutEffect(() => {
    if (activeTab) {
      return;
    }

    setActiveTab(selectedDates.type ?? "range");
  }, [selectedDates.type, activeTab]);

  if (!activeTab) {
    return null;
  }

  return (
    <Stack>
      <TabContext value={activeTab}>
        <TabList onChange={tabChangeHandler}>
          <Tab value="range" label="Date Range" />
          <Tab value="past" label="Past" />
          <Tab value="last" label="Last" />
        </TabList>
        <Divider />

        <TabPanel value="range" sx={{ p: 0 }}>
          <RangeSelection
            onClose={onClose}
            minDate={minDate}
            maxDate={maxDate}
            selectedDates={selectedDates}
            onApply={rangeSelectHandler}
            highlightToday={highlightToday}
            availableMinDate={availableMinDate}
            quarterStartMonth={quarterStartMonth}
          />
        </TabPanel>

        <TabPanel value="past" sx={{ p: 0 }}>
          <PeriodSelection
            dateType="past"
            minDate={minDate}
            onApply={periodSelectHandler}
            selectedUnit={selectedDates.unit}
            availableMinDate={availableMinDate}
            selectedValue={`${selectedDates.value}`}
          />
        </TabPanel>

        <TabPanel value="last" sx={{ p: 0 }}>
          <PeriodSelection
            dateType="last"
            minDate={minDate}
            onApply={periodSelectHandler}
            selectedUnit={selectedDates.unit}
            availableMinDate={availableMinDate}
            selectedValue={`${selectedDates.value}`}
          />
        </TabPanel>
      </TabContext>
    </Stack>
  );
};
