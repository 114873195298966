import { FC } from "react";
import { Stack, Tooltip, Typography, TypographyProps } from "@mui/material";
import StarsIcon from "@mui/icons-material/Stars";
import { TypographyWithTooltip } from "../../../../../../common/TypographyWithTooltip";

interface AccountCellProps {
  accountId?: string;
  accountName?: string;
  isRootAccount?: boolean;
  TypographyProps?: TypographyProps;
}

export const AccountCell: FC<AccountCellProps> = ({
  accountName,
  isRootAccount,
  accountId,
  TypographyProps,
}) => {
  return (
    <Stack>
      <Stack direction="row" spacing={0.5} alignItems="center">
        {isRootAccount && (
          <Tooltip title="Management account" placement="top" arrow>
            <StarsIcon color="primary" sx={{ fontSize: 16 }} />
          </Tooltip>
        )}

        {accountName && (
          <TypographyWithTooltip {...TypographyProps} title={accountName} />
        )}
      </Stack>

      {accountId && (
        <Typography variant="body2" color="text.secondary">
          {accountId}
        </Typography>
      )}
    </Stack>
  );
};
