import { FC, Fragment } from "react";
import { Typography } from "@mui/material";
import { money } from "../../../../../../utils/numeral/money";
import { useAppSelector } from "../../../../../../store/hooks";
import { rightsizingRecommendationsEstimatedTotalPriceSelector } from "../../../../../../store/recommendations/selectors/rightsizing/rightsizingRecommendationsEstimatedTotalPriceSelector";
import { RecommendationsTitle } from "../../common/RecommendationsTitle";
import { rightsizingRecommendationsDataTotalLengthSelector } from "../../../../../../store/recommendations/selectors/rightsizing/data/rightsizingRecommendationsDataTotalLengthSelector";

export const RightsizingRecommendationsTitle: FC = () => {
  const totalPrice = useAppSelector(
    rightsizingRecommendationsEstimatedTotalPriceSelector,
  );

  const length = useAppSelector(
    rightsizingRecommendationsDataTotalLengthSelector,
  );

  return (
    <RecommendationsTitle
      heading="Rightsizing Recommendations From Cloud Providers"
      description={
        length ? (
          <Fragment>
            There are {length} Rightsizing Recommendations with estimated
            savings{" "}
            <Typography component="span" fontWeight="bold">
              {money(totalPrice)}
            </Typography>{" "}
            per month.
          </Fragment>
        ) : (
          "There are no Rightsizing Recommendations yet."
        )
      }
    />
  );
};
