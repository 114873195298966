import { createAsyncThunk } from "@reduxjs/toolkit";
import { cloudChiprApi } from "../../../../services/cloudchipr.api";

export const getUserRolesPermissionsThunk = createAsyncThunk(
  "roles/getUserRolesPermissions",
  async (_, { dispatch }) => {
    return await dispatch(
      cloudChiprApi.endpoints.getPermissions.initiate(),
    ).unwrap();
  },
);
