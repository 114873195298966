import { FC, useCallback } from "react";
import { Dialog } from "@mui/material";
import { AccountSetupDialogTitle } from "./components/AccountSetupDialogTitle";
import { AccountSetupDialogContent } from "./components/AccountSetupDialogContent";
import {
  resetAccountSetup,
  setAccountSetupDialogOpen,
} from "../../../../store/account-setup/accountSetupSlice";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { isAccountSetupDialogOpenSelector } from "../../../../store/account-setup/selectors/isAccountSetupDialogOpenSelector";

export const AccountSetupDialog: FC = () => {
  const isAccountSetupDialogOpen = useAppSelector(
    isAccountSetupDialogOpenSelector,
  );
  const dispatch = useAppDispatch();

  const handleCloseDialog = useCallback(() => {
    dispatch(setAccountSetupDialogOpen(false));
    dispatch(resetAccountSetup());
  }, [dispatch]);

  if (!isAccountSetupDialogOpen) {
    return null;
  }

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      disableEscapeKeyDown
      onClose={handleCloseDialog}
      open={isAccountSetupDialogOpen}
      onBackdropClick={undefined}
    >
      <AccountSetupDialogTitle onClose={handleCloseDialog} />
      <AccountSetupDialogContent />
    </Dialog>
  );
};
