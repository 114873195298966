import { postUsersMeV2OrganisationsCurrentByTypeResourcesSelector } from "./postUsersMeV2OrganisationsCurrentByTypeResourcesSelector";
import { RootState } from "../../../../store";
import { ResourceType } from "../../../../../services/cloudchipr.api";
import { generateScheduleResourcesFetchingFixedCacheKey } from "../../../../../components/pages/schedule/common/utils/helpers/fixedCacheKeys";

export const scheduleResourcesSelector = (
  state: RootState,
  resourceType: ResourceType,
  scheduleId: string,
) => {
  const key = generateScheduleResourcesFetchingFixedCacheKey(
    resourceType,
    scheduleId,
  );

  return postUsersMeV2OrganisationsCurrentByTypeResourcesSelector(key)(state);
};
