import { ChangeEvent, FC, useCallback } from "react";
import { TextField } from "@mui/material";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../store/hooks";
import { alertsDataNameSelector } from "../../../../../../../store/alerts/selectors/common/data/alertsDataNameSelector";
import { setAlertsData } from "../../../../../../../store/alerts/alertsSlice";

export const AlertsNameTextField: FC = () => {
  const dispatch = useAppDispatch();
  const name = useAppSelector(alertsDataNameSelector);

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      dispatch(setAlertsData({ name: event.target.value }));
    },
    [dispatch],
  );

  return (
    <TextField
      label="Alert Name"
      size="small"
      value={name}
      onChange={handleChange}
    />
  );
};
