import { ColDef } from "@ag-grid-community/core/dist/types/src/entities/colDef";

export const targetTagComparatorFn: ColDef["comparator"] = (
  _,
  __,
  nodeA,
  nodeB,
) => {
  const current = nodeA.data?.target?.tags?.at(0)?.key ?? "";
  const next = nodeB.data?.target?.tags?.at(0)?.key ?? "";

  return current.localeCompare(next);
};
