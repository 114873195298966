import { FC } from "react";
import { Typography } from "@mui/material";
import { ResourceType } from "../../../../../../services/cloudchipr.api";
import { getResourceTypeName } from "../../../../../../utils/helpers/resources/getResourceTypeName";
import { useAppSelector } from "../../../../../../store/hooks";
import { liveUsageMgmtResourceTypeDataTotalCountSelector } from "../../../../../../store/live-usage-mgmt/selectors/resource-type-data/count/liveUsageMgmtResourceTypeDataTotalCountSelector";

interface ResourceCardHeaderTitleProps {
  resourceType: ResourceType;
}

export const LiveUsageMgmtResourceCardHeaderTitle: FC<
  ResourceCardHeaderTitleProps
> = ({ resourceType }) => {
  const resourcesTotalCount = useAppSelector((state) =>
    liveUsageMgmtResourceTypeDataTotalCountSelector(state, resourceType),
  );

  return (
    <Typography color="primary" variant="body2" fontWeight="medium">
      {getResourceTypeName(resourceType, {
        type: "long",
      })}{" "}
      <Typography
        color="text.disabled"
        component="span"
        variant="body2"
        fontWeight="medium"
      >
        ({resourcesTotalCount})
      </Typography>
    </Typography>
  );
};
