import { createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../../../../../store";
import { commitmentUtilizationWidgetByIdSelector } from "../../../../selectors/widgets/commitment-utilization/widget-view/commitmentUtilizationWidgetByIdSelector";
import { createWidgetThunk } from "../../common/createWidgetThunk";
import { openWidgetBuildingSnackbar } from "../../utils/openWidgetBuildingSnackbar";

export const duplicateCommitmentUtilizationWidgetThunk = createAsyncThunk(
  "dashboard/duplicateCommitmentUtilizationWidget",
  async (
    { widgetId, widgetName }: { widgetId: string; widgetName: string },
    { dispatch, getState },
  ) => {
    const state = getState() as RootState;
    const widget = commitmentUtilizationWidgetByIdSelector(state, widgetId);

    if (!widget) {
      return;
    }

    const response = await dispatch(
      createWidgetThunk({
        type: "commitments_utilization",
        name: widgetName,
        provider_organisation_id: widget.provider_organisation_id,
        grouping: widget.grouping,
        commitment_type: widget.commitment_type,
        trend_type: widget.trend_type,
        dates: widget.dates,
        aggregation: widget.aggregation,
        visualization: widget.visualization,
      }),
    ).unwrap();

    if (response) {
      openWidgetBuildingSnackbar(widget.type, "duplicate");
    }

    return response;
  },
);
