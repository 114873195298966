import { FC, Fragment } from "react";
import { Alert, Typography } from "@mui/material";
import { CopyIconButton } from "../../../../../common/CopyIconButton";
import { ProviderAccessType } from "../../../../../../services/cloudchipr.api";

interface AccountSwitchModeGcpConsoleProps {
  serviceAccount: string;
  providerAccessType: ProviderAccessType;
}

export const AccountSwitchModeGcpConsole: FC<
  AccountSwitchModeGcpConsoleProps
> = ({ serviceAccount, providerAccessType }) => {
  return (
    <Alert
      variant="outlined"
      icon={false}
      severity="info"
      sx={{ alignItems: "center", bgcolor: "#E5F4F8", borderRadius: 2 }}
    >
      <Typography variant="body2" color="text.secondary" mb={1}>
        Please go to your GCP Console and change the following IAM roles from
        Cloudchipr service account.{" "}
        <Typography component="span" variant="body2" fontWeight="bold">
          {serviceAccount}{" "}
        </Typography>
        <CopyIconButton data={serviceAccount} visible />
      </Typography>

      {providerAccessType === "read" && (
        <Fragment>
          <RoleAction action="Add" role="Viewer" />
          <RoleAction action="Remove" role="Editor" />
        </Fragment>
      )}

      {providerAccessType === "read_write" && (
        <RoleAction action="Add" role="Editor" />
      )}
    </Alert>
  );
};

const RoleAction: FC<{ action: "Add" | "Remove"; role: string }> = ({
  action,
  role,
}) => {
  return (
    <Fragment>
      <Typography variant="body2" gutterBottom={false}>
        {action}
      </Typography>
      <ul>
        <li>
          <Typography fontWeight="medium" color="primary">
            {role}
            <CopyIconButton data={role} visible color="primary" />
          </Typography>
        </li>
      </ul>
    </Fragment>
  );
};
