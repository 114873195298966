import { FC } from "react";
import { EmptyState } from "./EmptyState";
import { DataGrid } from "../../../../storybook/data-grid/DataGrid";
import { useAppSelector } from "../../../../store/hooks";
import { queryAnswerDataSelector } from "../../../../store/cloudchat/selectors/queryAnswerDataSelector";
import { chatAnswerDataGridColumnsSelector } from "../../../../store/cloudchat/selectors/chatAnswerDataGridColumnsSelector";

export const ChatTable: FC = () => {
  const data = useAppSelector(queryAnswerDataSelector);
  const columns = useAppSelector(chatAnswerDataGridColumnsSelector);

  if (!data) {
    return <EmptyState />;
  }

  if (!columns) {
    return null;
  }

  return (
    <DataGrid
      toolbar
      columnSorting
      data={data}
      styles={{
        tableHeaderRow: {
          bgcolor: "grey.50",
          "& th": { textTransform: "capitalize" },
        },
      }}
      columns={columns}
    />
  );
};
