import {
  cloudChiprApi,
  ProviderType,
} from "../../../../services/cloudchipr.api";
import { RootState } from "../../../store";

const selector =
  cloudChiprApi.endpoints
    .getV2UsersMeOrganisationsCurrentProvidersByProviderBillingStatus.select;

export const getCurrentProvidersByProviderBillingStatusSelector = (
  state: RootState,
  provider: ProviderType,
) => {
  return selector({ provider })(state);
};
