import { RootState } from "../../../store";
import { navigationResourceExplorerPublicDataItemsSelector } from "../../../navigation/selectors/resource-explorer/navigationResourceExplorerPublicDataItemsSelector";
import { navigationResourceExplorerPrivateDataItemsSelector } from "../../../navigation/selectors/resource-explorer/navigationResourceExplorerPrivateDataItemsSelector";
import { NavigationItemsVisibilityType } from "../../../navigation/utils/types";
import { resourceExplorerIdSelector } from "../current-resource-explorer/resourceExplorerIdSelector";

export const currentResourceExplorerHierarchyVisibilitySelector = (
  state: RootState,
): NavigationItemsVisibilityType | undefined => {
  const id = resourceExplorerIdSelector(state);
  const publicDataItems =
    navigationResourceExplorerPublicDataItemsSelector(state);
  const privateDataItems =
    navigationResourceExplorerPrivateDataItemsSelector(state);

  const dataInPublic = publicDataItems?.find((item) => item.id === id);
  if (dataInPublic) {
    return "visible_to_everyone";
  }
  const dataInPrivate = privateDataItems?.find((item) => item.id === id);

  if (dataInPrivate) {
    return "visible_only_to_me";
  }
};
