import { reservationsRecommendationsDataSelector } from "./reservationsRecommendationsDataSelector";
import { RootState } from "../../../../store";
import {
  ReservationRecommendation,
  SavingsPlanAccountScope,
} from "../../../../../services/cloudchipr.api";

export const reservationsRecommendationsDataGroupedByAccountScopeSelector = (
  state: RootState,
):
  | Partial<Record<SavingsPlanAccountScope, ReservationRecommendation[]>>
  | undefined => {
  const data = reservationsRecommendationsDataSelector(state);

  return data?.reduce(
    (result, item) => {
      if (item.account_scope === "payer") {
        result["payer"].push(item);
      } else {
        result["linked"].push(item);
      }
      return result;
    },
    {
      payer: [],
      linked: [],
    } as Record<SavingsPlanAccountScope, ReservationRecommendation[]>,
  );
};
