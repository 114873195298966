import { FC } from "react";
import {
  IconButton,
  Popover,
  PopoverOrigin,
  Stack,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { DPUserConfig } from "@rehookify/datepicker/dist/types/config";
import { DateRangeSelect } from "./calendar/DateRangeSelect";

interface RangeSelectionPopoverProps {
  open: boolean;
  minDate?: string;
  maxDate?: string;
  onClose(): void;
  clickSource: string;
  highlightToday?: boolean;
  anchor: HTMLDivElement | null;
  onChange: DPUserConfig["onDatesChange"];
  selectedDates: DPUserConfig["selectedDates"];
}

export const RangeSelectionPopover: FC<RangeSelectionPopoverProps> = ({
  open,
  onClose,
  anchor,
  highlightToday,
  selectedDates,
  onChange,
  minDate,
  maxDate,
  clickSource,
}) => {
  return (
    <Popover
      open={open}
      onClose={onClose}
      anchorEl={anchor}
      anchorOrigin={anchorOrigin}
      transformOrigin={transformOrigin}
    >
      <Stack width={288} pb={1}>
        <Stack
          p={1}
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography variant="subtitle2" fontWeight="medium">
            Select date range
          </Typography>

          <IconButton onClick={onClose} size="small">
            <CloseIcon fontSize="small" />
          </IconButton>
        </Stack>

        <DateRangeSelect
          minDate={minDate}
          maxDate={maxDate}
          onChange={onChange}
          clickSource={clickSource}
          selectedDates={selectedDates}
          highlightToday={highlightToday}
        />
      </Stack>
    </Popover>
  );
};

const transformOrigin: PopoverOrigin = {
  vertical: "top",
  horizontal: -5,
};
const anchorOrigin: PopoverOrigin = {
  vertical: "top",
  horizontal: "right",
};
