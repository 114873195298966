import { ColumnSetupType } from "../../../../../../../../../../../../../../storybook/data-grid/utils/types/types";
import { Ip } from "../../../../../../../../../../../../../../services/cloudchipr.api";

export const ipColumns: ColumnSetupType<Ip>[] = [
  {
    accessorKey: "ip",
    header: "IP",
    type: "identifier",
    meta: { sticky: "left", hideFromSettings: true },
  },
  {
    accessorKey: "price_per_month",
    header: "Monthly Price",
    type: "money",
    headerTooltip:
      "Monthly prices are calculated based on the on-demand list price of each resource.",
  },
  {
    accessorKey: "account",
    header: "Account",
    type: "account",
    size: 210,
  },
  {
    accessorKey: "all_time_spent",
    header: "Total Spend",
    type: "money",
  },
  { accessorKey: "name", header: "Name" },
  { accessorKey: "region", header: "Region", type: "withCopy" },
  { accessorKey: "tags", header: "Labels", type: "tag", enableSorting: false },
  {
    accessorKey: "tags",
    header: "Smart Labels",
    id: "smart_tag",
    type: "smartTag",
    enableSorting: false,
  },
  {
    accessorKey: "issues",
    header: "Ticket Status",
    type: "jiraTicketStatus",
    enableSorting: false,
  },
];
