import { FC } from "react";
import { Box, List, ListItem, Typography } from "@mui/material";

export const Step2: FC = () => {
  return (
    <Box px={2}>
      <Typography variant="h5" fontWeight="medium">
        Step 2
      </Typography>

      <List
        sx={{
          listStyleType: "disc",
          pl: 2,
          "& .MuiListItem-root": {
            display: "list-item",
          },
        }}
      >
        <ListItem sx={{ px: 0 }}>
          <Typography variant="subtitle1" color="text.secondary">
            Choose{" "}
            <Typography component="span" fontWeight="medium">
              Add ANOTHER ROLE.
            </Typography>
          </Typography>
        </ListItem>

        <ListItem sx={{ px: 0 }}>
          <Typography variant="subtitle1" color="text.secondary">
            Select required role.
          </Typography>
        </ListItem>

        <ListItem sx={{ px: 0 }}>
          <Typography variant="subtitle1" color="text.secondary">
            Choose{" "}
            <Typography component="span" fontWeight="medium">
              SAVE.
            </Typography>
          </Typography>
        </ListItem>
      </List>
    </Box>
  );
};
