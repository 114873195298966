import { FC, Fragment } from "react";
import { useContextCalendars } from "@rehookify/datepicker";
import { Grid, Stack, Typography } from "@mui/material";

export const DateRangeCalendarWeekDays: FC = () => {
  const { weekDays } = useContextCalendars();

  return (
    <Fragment>
      {weekDays.map((day) => (
        <Grid item md={12 / 7} key={day}>
          <Stack alignItems="center" justifyContent="center" pb={1}>
            <Typography variant="caption" color="text.secondary" align="center">
              {day}
            </Typography>
          </Stack>
        </Grid>
      ))}
    </Fragment>
  );
};
