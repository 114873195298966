export const getLabelColorByCostAmount = (costAmount?: number | null) => {
  if (costAmount && costAmount > 0) {
    return "success.main";
  }

  if (costAmount && costAmount < 0) {
    return "error.main";
  }

  return "text.secondary";
};
