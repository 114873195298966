import { ChangeEvent, FC } from "react";
import { DialogContent } from "@mui/material";
import { ResourceExplorerBillingExportDialogStepper } from "./ResourceExplorerBillingExportDialogStepper";
import { ResourceExplorerBillingExportDialogAccessStep } from "./components/resource-explorer-billing-export-dialog-access-step/ResourceExplorerBillingExportDialogAccessStep";
import { ResourceExplorerBillingExportDialogDetailsStep } from "./components/resource-explorer-billing-export-dialog-billing-details-step/ResourceExplorerBillingExportDialogDetailsStep";
import { BillingExportInitialValues } from "./ResourceExplorerBillingExportDialog";
import { ResourceExplorerBillingExportDialogProcessStep } from "./components/resource-explorer-billing-export-dialog-process-step/ResourceExplorerBillingExportDialogProcessStep";

interface ResourceExplorerBillingExportDialogContentProps {
  onCloseDialog(): void;
  currentStep: number;
  onNextStep(): void;
  onPrevStep(): void;
  onSkipStep(count: number): void;
  values: BillingExportInitialValues;
  onValuesChange(event: ChangeEvent): void;
  dirty: boolean;
  isValid: boolean;
  onSuccess?(): void;
}
export const ResourceExplorerBillingExportDialogContent: FC<
  ResourceExplorerBillingExportDialogContentProps
> = ({
  currentStep,
  values,
  onValuesChange,
  onCloseDialog,
  onNextStep,
  dirty,
  isValid,
  onPrevStep,
  onSkipStep,
  onSuccess,
}) => {
  return (
    <DialogContent sx={{ p: 0 }} dividers>
      <ResourceExplorerBillingExportDialogStepper currentStep={currentStep} />
      {currentStep === 0 && (
        <ResourceExplorerBillingExportDialogDetailsStep
          values={values}
          onValuesChange={onValuesChange}
          onNextStep={onNextStep}
          onSkipStep={onSkipStep}
          onCloseDialog={onCloseDialog}
          primaryBtnDisabled={!dirty || !isValid}
          onSuccess={onSuccess}
        />
      )}
      {currentStep === 1 && (
        <ResourceExplorerBillingExportDialogAccessStep
          onNextStep={onNextStep}
          onCloseDialog={onCloseDialog}
          onPrevStep={onPrevStep}
          values={values}
          onSuccess={onSuccess}
        />
      )}
      {currentStep === 2 && (
        <ResourceExplorerBillingExportDialogProcessStep
          onCloseDialog={onCloseDialog}
        />
      )}
    </DialogContent>
  );
};
