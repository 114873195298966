import { createDraftSafeSelector } from "@reduxjs/toolkit";
import { getDailyActiveRecommendationComputeSelector } from "../../recommendationDailyQuerySelector";
import { OrganisationDailyOffHoursRecommendation } from "../../../../../../services/cloudchipr.api";

export const recommendationComputeDailyActiveDataSelector =
  createDraftSafeSelector(
    [getDailyActiveRecommendationComputeSelector],
    (response): OrganisationDailyOffHoursRecommendation[] =>
      response?.data ?? [],
  );
