import { FC } from "react";
import { AzureBillingErrorState } from "./AzureBillingErrorState";
import { ProviderType } from "../../../../../../../services/cloudchipr.api";
import { useAppSelector } from "../../../../../../../store/hooks";
import { billingStatusByProviderSelector } from "../../../../../../../store/common/selectors/billing-status/billingStatusByProviderSelector";
import { AnalyzingBillingData } from "../AnalyzingBillingData";

interface AzureAccessControllerProps {
  provider: ProviderType;
}
export const AzureAccessController: FC<AzureAccessControllerProps> = ({
  provider,
}) => {
  const status = useAppSelector((state) =>
    billingStatusByProviderSelector(state, provider),
  );

  if (status === "error" || status === "missing_setup") {
    return <AzureBillingErrorState />;
  }

  if (status === "collecting" || status === "analysing") {
    return <AnalyzingBillingData />;
  }

  return null;
};
