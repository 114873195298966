import { FC, useMemo } from "react";
import { Box } from "@mui/material";
import { AgGridReact } from "@ag-grid-community/react";
import { getCommitmentUtilizationTableViewColumnDefs } from "../../../adding-widget/widget-create/widget-setups/commitment-utilization /utils/helpers/getCommitmentUtilizationTableViewColumnDefs";
import {
  commitmentUtilizationTableOptions,
  commitmentUtilizationTableWrapperStyles,
} from "../utils/constants/constants";
import {
  GetUsersMeOrganisationsCurrentWidgetsCommitmentsUtilizationByWidgetIdApiResponse,
  WidgetCommitmentsUtilizationVisualization,
} from "../../../../../../../services/cloudchipr.api";

interface CommitmentUtilizationWidgetTableViewProps {
  data?: GetUsersMeOrganisationsCurrentWidgetsCommitmentsUtilizationByWidgetIdApiResponse;
  visualization?: WidgetCommitmentsUtilizationVisualization;
}

export const CommitmentUtilizationWidgetTableView: FC<
  CommitmentUtilizationWidgetTableViewProps
> = ({ data, visualization }) => {
  const { groups } = data ?? {};

  const columnDefs = useMemo(
    () => getCommitmentUtilizationTableViewColumnDefs(visualization),
    [visualization],
  );

  return (
    <Box
      className="ag-theme-quartz"
      sx={commitmentUtilizationTableWrapperStyles}
    >
      <AgGridReact
        enableCellTextSelection
        rowHeight={40}
        rowData={groups ?? []}
        columnDefs={columnDefs}
        gridOptions={commitmentUtilizationTableOptions}
      />
    </Box>
  );
};
