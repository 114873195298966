import { COMPONENT_ID } from "../../../../../utils/constants/components-ids";

export const scrollingOnDnD = (scrollOffSet: number, mouseClientY?: number) => {
  const node = document.getElementById(COMPONENT_ID.APP_OUTLET_WRAPPER);

  if (mouseClientY === undefined || !node) {
    return;
  }

  if (mouseClientY > window.innerHeight - 200) {
    node.scrollTo(0, node.scrollTop + scrollOffSet);
  } else if (mouseClientY < 200) {
    const nextScrollTop = node.scrollTop - scrollOffSet;

    if (nextScrollTop >= 0) {
      node.scrollTo(0, nextScrollTop);
    }
  }
};
