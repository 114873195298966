import { FC } from "react";
import { useLocation } from "react-router-dom";
import AssignmentOutlinedIcon from "@mui/icons-material/AssignmentOutlined";
import { useFlag } from "@unleash/proxy-client-react";
import { useAppSelector } from "../../../../store/hooks";
import { navigationCollapsedSelector } from "../../../../store/common/selectors/navigation/navigationCollapsedSelector";
import { NavItem } from "../nav-item/NavItem";

export const TasksNavigation: FC = () => {
  const location = useLocation();
  const enableTaskManagement = useFlag("EnableTaskManagement");

  const selected = location.pathname.startsWith("/task-mgmt");

  const navigationCollapsed = useAppSelector(navigationCollapsedSelector);

  if (!enableTaskManagement) {
    return null;
  }

  return (
    <NavItem
      to="/task-mgmt"
      primary="Tasks"
      icon={AssignmentOutlinedIcon}
      navCollapsed={navigationCollapsed}
      selected={selected}
    />
  );
};
