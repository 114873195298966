import { DateDataPoint } from "../../../../../services/cloudchipr.api";

export const getAverageDataByFrequency = (
  frequency: DateDataPoint,
  widget?: {
    average_daily_cost_details: {
      average_daily_cost: number | null;
      trend: number | null;
    };
    average_monthly_cost_details: {
      average_monthly_cost: number | null;
      trend: number | null;
    };
  },
) => {
  const cost =
    frequency === "daily"
      ? widget?.average_daily_cost_details?.average_daily_cost
      : widget?.average_monthly_cost_details?.average_monthly_cost;

  const trend =
    frequency === "daily"
      ? widget?.average_daily_cost_details?.trend
      : widget?.average_monthly_cost_details?.trend;

  return { cost, trend };
};
