import { FC, Fragment, SyntheticEvent, useCallback } from "react";
import { TabList } from "@mui/lab";
import { Divider, Tab } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useFlag } from "@unleash/proxy-client-react";
import { useAppDispatch } from "../../../../../store/hooks";
import { commitmentTypesLabels } from "../utils/constants/labels";
import {
  setCommitmentsDataViewTab,
  setCommitmentsTypeTab,
} from "../../../../../store/commitments/commitmentsSlice";
import { CommitmentType } from "../../../../../services/cloudchipr.api";

export const CommitmentTypeTabs: FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const enableCommitmentsOverview = useFlag("EnableCommitmentsOverview");
  const handleGroupChange = useCallback(
    (_: SyntheticEvent, type: CommitmentType) => {
      dispatch(setCommitmentsTypeTab(type));
      dispatch(setCommitmentsDataViewTab("commitment"));
      navigate({ search: `active=${type}` });
    },
    [dispatch, navigate],
  );

  return (
    <Fragment>
      <TabList onChange={handleGroupChange} sx={{ bgcolor: "white" }}>
        {enableCommitmentsOverview && (
          <Tab
            value="overview"
            label={commitmentTypesLabels.overview}
            sx={{ textTransform: "none" }}
          />
        )}
        <Tab
          value="savings_plan"
          label={commitmentTypesLabels.savings_plan}
          sx={{ textTransform: "none" }}
        />
        <Tab
          value="reservation"
          label={commitmentTypesLabels.reservation}
          sx={{ textTransform: "none" }}
        />
      </TabList>

      <Divider />
    </Fragment>
  );
};
