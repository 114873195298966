import { FC, Fragment, useCallback } from "react";
import { ListItemIcon, ListItemText, MenuItem, Tooltip } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../store/hooks";
import { dismissOffHoursRecommendationResourceThunk } from "../../../../../../../store/recommendations/thunks/off-hours/dismiss/resource/dismissOffHoursRecommendationResourceThunk";
import { ConfirmationDialog } from "../../../../../../common/modals/ConfirmationDialog";
import { useDialog } from "../../../../../../../utils/hooks/useDialog.hook";
import { recommendationResourceDismissLoadingSelector } from "../../../../../../../store/recommendations/selectors/dismiss/resources/recommendationResourceDismissLoadingSelector";
import { dismissRightsizingRecommendationResourceThunk } from "../../../../../../../store/recommendations/thunks/rightsizing/dismiss/dismissRightsizingRecommendationResourceThunk";
import { offHoursRecommendationsPageLoadingSelector } from "../../../../../../../store/recommendations/selectors/off-hours/loading/offHoursRecommendationsPageLoadingSelector";
import { rightsizingRecommendationsPageLoadingSelector } from "../../../../../../../store/recommendations/selectors/rightsizing/loading/rightsizingRecommendationsPageLoadingSelector";
import { recommendationMessages } from "../../../../utils/constants/recommendationDismissMessages";
import { selectedActiveRecommendationsResourceNameSelector } from "../../../../../../../store/recommendations/selectors/dismiss/selectedActiveRecommendationsResourceNameSelector";

interface DismissByResourceProps {
  recommendationId: string;
  recommendationType: "off-hours" | "rightsizing";
}

export const DismissByResource: FC<DismissByResourceProps> = ({
  recommendationId,
  recommendationType,
}) => {
  const dispatch = useAppDispatch();
  const { open, openDialog, closeDialog } = useDialog();

  const offHoursLoading = useAppSelector(
    offHoursRecommendationsPageLoadingSelector,
  );
  const rightsizingLoading = useAppSelector(
    rightsizingRecommendationsPageLoadingSelector,
  );
  const dismissing = useAppSelector(
    recommendationResourceDismissLoadingSelector,
  );

  const resourceNames = useAppSelector((state) =>
    selectedActiveRecommendationsResourceNameSelector(
      state,
      recommendationId,
      recommendationType,
    ),
  );

  const loading = rightsizingLoading || offHoursLoading || dismissing;

  const dismissHandler = useCallback(() => {
    if (recommendationType === "rightsizing") {
      dispatch(dismissRightsizingRecommendationResourceThunk(recommendationId));
    } else {
      dispatch(dismissOffHoursRecommendationResourceThunk(recommendationId));
    }
  }, [dispatch, recommendationId, recommendationType]);

  const confirmationMessage = recommendationMessages({
    action: "dismiss",
    resource: true,
    multiple: false,
  });

  return (
    <Fragment>
      <MenuItem onClick={openDialog}>
        <ListItemText>Dismiss by resource</ListItemText>

        <ListItemIcon sx={{ ml: 2, minWidth: "20px !important" }}>
          <Tooltip
            arrow
            placement="top"
            title="This will dismiss all current and future recommendations related to this resource."
          >
            <InfoOutlinedIcon color="action" fontSize="small" />
          </Tooltip>
        </ListItemIcon>
      </MenuItem>

      <ConfirmationDialog
        open={open}
        title="Dismiss all recommendations?"
        dialogContent={
          <Fragment>
            {confirmationMessage}
            <br />
            <strong>{resourceNames}</strong>
          </Fragment>
        }
        CancelButtonProps={{ onClick: closeDialog }}
        ConfirmButtonProps={{
          loading: loading,
          children: "Dismiss",
          onClick: dismissHandler,
        }}
        onClose={closeDialog}
      />
    </Fragment>
  );
};
