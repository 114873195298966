import { createAsyncThunk } from "@reduxjs/toolkit";
import { cloudChiprApi } from "../../../../services/cloudchipr.api";

export const getRightsizingRecommendationsThunk = createAsyncThunk(
  "recommendations/getRightsizingRecommendationsThunk",
  async (dismissed: boolean | void, { dispatch }) => {
    const {
      getUsersMeOrganisationsCurrentResourcesRightSizingRecommendations,
    } = cloudChiprApi.endpoints;

    await dispatch(
      getUsersMeOrganisationsCurrentResourcesRightSizingRecommendations.initiate(
        { isDismissed: !!dismissed },
        {
          forceRefetch: true,
        },
      ),
    ).unwrap();
  },
);
