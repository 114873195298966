import { ChartDataType } from "../../../../../storybook/charts/multi-type-chart/utils/types/types";
import { Account } from "../../../../../services/cloudchipr.api";
import { chartDataPointKey } from "../../../../../storybook/charts/multi-type-chart/utils/constants/constants";

export const accountsBarChartDataGenerator = (accounts: Account[] | null) => {
  let chartData = accounts?.map((account) => {
    const name =
      account.provider_account_name ||
      account.provider_account_id ||
      account.id;

    return {
      [chartDataPointKey]: name,
      "Tracked Total": account.total_costs || 0,
      "Filtered Resources": account.ems || 0,
    };
  }) as unknown as ChartDataType[];

  if (chartData?.length && chartData.length > 15) {
    const firstPart = chartData.splice(0, 14);
    const secondPart = chartData.splice(14);

    const others = secondPart.reduce(
      (res, data) => {
        return {
          [chartDataPointKey]: "Others",
          "Tracked Total":
            (res["Tracked Total"] ?? 0) + (data["Tracked Total"] ?? 0),
          "Filtered Resources":
            (res["Filtered Resources"] ?? 0) +
            (data["Filtered Resources"] ?? 0),
        } as unknown as ChartDataType;
      },
      {} as unknown as ChartDataType,
    );

    chartData = [...firstPart, others] as unknown as ChartDataType[];
  }

  return (chartData ?? []) as unknown as ChartDataType[];
};
