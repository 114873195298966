import { FC } from "react";
import Stack from "@mui/material/Stack";
import { CategoryItem } from "./CategoryItem";
import { useAppSelector } from "../../../../../../../../store/hooks";
import { dimensionCategoriesEntriesSelector } from "../../../../../../../../store/dimensions/selectors/slice-data/dimensionCategoriesEntriesSelector";

export const DimensionsCategoriesList: FC = () => {
  const categoriesEntries = useAppSelector(dimensionCategoriesEntriesSelector);

  return (
    <Stack spacing={3}>
      {categoriesEntries.map(([id, category]) => {
        return (
          <CategoryItem
            key={id}
            category={category}
            deleteDisabled={categoriesEntries.length === 1}
          />
        );
      })}
    </Stack>
  );
};
