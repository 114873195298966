import { FC } from "react";
import { DialogContent, Divider, TextField, Typography } from "@mui/material";
import { FormikHandlers } from "formik";
import { SsoFormDialogHeader } from "../common/SsoFormDialogHeader";
import { SsoFormDialogStep } from "../common/SsoFormDialogStep";
import { LinkRow } from "../common/LinkRow";

interface OktaSsoFormDialogBodyProps {
  signInUrl: string;
  audienceUri: string;
  onChange: FormikHandlers["handleChange"];
}

export const OktaSsoFormDialogBody: FC<OktaSsoFormDialogBodyProps> = ({
  signInUrl,
  audienceUri,
  onChange,
}) => {
  const documentationHref =
    "https://docs.cloudchipr.com/docs/single-sign-on-sso-using-okta";

  return (
    <DialogContent dividers>
      <SsoFormDialogHeader
        title="To enable Okta as SSO Provider, please follow the steps below:"
        href={documentationHref}
      />

      <SsoFormDialogStep step={1} title="Create SAML 2.0 App">
        <Typography variant="caption" color="text.secondary" mt={0.5}>
          Create a new app using the SAML 2.0 sign-in method. Enter the
          Cloudchipr provided{" "}
          <Typography component="span" variant="inherit" fontWeight="medium">
            Single Sign On URL
          </Typography>{" "}
          and{" "}
          <Typography component="span" variant="inherit" fontWeight="medium">
            Audience URI (SP Entity ID).
          </Typography>{" "}
          Ensure that your SAML integration is saved and active. This step is
          essential to generate the IDP metadata.
        </Typography>
        <LinkRow
          mt={2}
          link={signInUrl}
          title="Single Sign On URL"
          subTitle=""
        />
        <LinkRow
          mt={1}
          link={audienceUri}
          title="Audience URI"
          subTitle="SP Entity ID"
        />
      </SsoFormDialogStep>

      <Divider sx={{ my: 4 }} />

      <SsoFormDialogStep step={2} title="Provide IDP Metadata">
        <Typography variant="caption" color="text.secondary" mt={0.5}>
          View SAML setup instructions, copy the content of the{" "}
          <Typography
            component="span"
            variant="caption"
            color="text.secondary"
            fontWeight="medium"
          >
            IDP metadata,
          </Typography>{" "}
          and paste it into the box below.
        </Typography>
        <TextField
          fullWidth
          multiline
          name="metadata"
          rows={4}
          label="Paste IDP Metadata here"
          margin="normal"
          onChange={onChange}
        />
      </SsoFormDialogStep>
    </DialogContent>
  );
};
