import { FC, Fragment } from "react";
import { Typography } from "@mui/material";
import { AzureSubscriptionAppIdCodeSnippet } from "./AzureSubscriptionAppIdCodeSnippet";
import {
  generateAzureCommand,
  azureReadOnlyCommands,
  azureReadWriteCommands,
} from "./utils/generateAzureCommand";
import { useAppSelector } from "../../../../../../store/hooks";
import { azureServicePrincipalSelector } from "../../../../../../store/account-setup/selectors/azureServicePrincipalSelector";
import { providerAccessTypeSelector } from "../../../../../../store/account-setup/selectors/providerAccessTypeSelector";
import { azureTenantIdSelector } from "../../../../../../store/account-setup/selectors/azureTenantIdSelector";

interface AzureTenantTabContentProps {}

export const AzureTenantTabContent: FC<AzureTenantTabContentProps> = () => {
  const servicePrincipal = useAppSelector(azureServicePrincipalSelector);
  const tenantId = useAppSelector(azureTenantIdSelector);
  const providerAccessType = useAppSelector(providerAccessTypeSelector);

  const command = generateAzureCommand({
    type: "tenant",
    actionType: "create",
    providerAccessType,
    identifier: tenantId || "",
    servicePrincipal: servicePrincipal || "",
  });

  return (
    <Fragment>
      <Typography
        variant="body2"
        fontWeight="medium"
        color="text.secondary"
        mt={3}
      >
        Please copy and execute the following snippet in your terminal.
      </Typography>
      <AzureSubscriptionAppIdCodeSnippet
        scope="tenant"
        command={command}
        identifier={tenantId || ""}
        commands={
          providerAccessType === "read"
            ? azureReadOnlyCommands
            : azureReadWriteCommands
        }
        servicePrincipal={servicePrincipal}
      />
    </Fragment>
  );
};
