import { JiraOption, JiraOptionWithChildren, JiraUser } from "../types/types";

export const isOptionJiraUser = (option: any): option is JiraUser => {
  return (
    typeof option === "object" &&
    option !== null &&
    "id" in option &&
    "name" in option &&
    "email" in option &&
    "avatar" in option
  );
};

export const isOptionJiraOption = (option: any): option is JiraOption => {
  return (
    typeof option === "object" &&
    option !== null &&
    "id" in option &&
    "name" in option &&
    "value" in option
  );
};

export const isOptionJiraOptionWithChildren = (
  option: any,
): option is JiraOptionWithChildren => {
  return isOptionJiraOption(option) && "children" in option;
};

export const isOptionJiraString = (option: any): option is string => {
  return typeof option === "string";
};
