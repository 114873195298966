import { FC } from "react";
import { Skeleton, Stack, Tooltip, Typography } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

interface UtilizationAndCoverageChartHeaderCardProps {
  title: string;
  loading?: boolean;
  value?: string | null;
  tooltip?: string;
  secondaryValue?: string | null;
}

export const CommitmentsChartMetadataCard: FC<
  UtilizationAndCoverageChartHeaderCardProps
> = ({ secondaryValue, title, value, loading, tooltip }) => {
  return (
    <Stack>
      <Stack direction="row" spacing={1} alignItems="center">
        <Typography variant="caption" color="text.secondary">
          {title}
        </Typography>
        {tooltip && (
          <Tooltip title={tooltip} placement="top" arrow>
            <InfoOutlinedIcon sx={{ fontSize: 16, color: "action.disabled" }} />
          </Tooltip>
        )}
      </Stack>

      {loading ? (
        <Skeleton variant="text" height={32} width={150} />
      ) : (
        <Typography variant="h6" fontWeight="medium">
          {value}

          {!!secondaryValue && (
            <Typography
              component="span"
              variant="inherit"
              color="text.secondary"
            >
              {" "}
              {secondaryValue}
            </Typography>
          )}
        </Typography>
      )}
    </Stack>
  );
};
