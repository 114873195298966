import React, { FC, Fragment } from "react";
import { useFlag } from "@unleash/proxy-client-react";
import { TrialSystemMessage } from "./components/TrialSystemMessage";
import { DisabledByPlanSystemMessage } from "./components/DisabledByPlanSystemMessage";
import { UnpaidInvoicesMessage } from "./components/UnpaidInvoicesMessage";

export const SystemMessages: FC = () => {
  const enableUnpaidInvoicesSystemMessage = useFlag(
    "EnableUnpaidInvoicesSystemMessage",
  );

  return (
    <Fragment>
      {enableUnpaidInvoicesSystemMessage && <UnpaidInvoicesMessage />}
      <TrialSystemMessage />
      <DisabledByPlanSystemMessage />
    </Fragment>
  );
};
