import { FC, Fragment, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { Stack, Typography } from "@mui/material";
import GridViewOutlinedIcon from "@mui/icons-material/GridViewOutlined";
import DashboardCustomizeOutlinedIcon from "@mui/icons-material/DashboardCustomizeOutlined";
import { useFlag } from "@unleash/proxy-client-react";
import { useAppSelector } from "../../../../../../store/hooks";
import { currentDashboardFolderSelector } from "../../../../../../store/dashboards/selectors/dashboard-hierarchy/currentDashboardFolderSelector";
import { currentDashboardNameSelector } from "../../../../../../store/dashboards/selectors/dashboard/currentDashboardNameSelector";
import { DashboardV2ItemActionButton } from "../../../../../navigation/components/dashboard-v2/components/item-action-menu/DashboardV2ItemActionButton";
import { currentDashboardIdSelector } from "../../../../../../store/dashboards/selectors/dashboard/currentDashboardIdSelector";
import { HierarchicalBreadcrumbs } from "../../../../common/breadcrumbs/hierarchical-breadcrumbs/HierarchicalBreadcrumbs";
import { currentDashboardHierarchyVisibilitySelector } from "../../../../../../store/dashboards/selectors/dashboard-hierarchy-visibility/currentDashboardHierarchyVisibilitySelector";
import { HierarchyDashboardSelectionWrapper } from "../../../../common/hierarchy-dashboard-selection/HierarchyDashboardSelectionWrapper";
import { BreadcrumbNavigationTrigger } from "../../../../resource-explorer/components/app-bar/components/breadcrumb-navigation/BreadcrumbNavigationTrigger";
import { FilterTriggerComponentProps } from "../../../../../common/select/dropdown-select/utils/types/filterTriggerComponentProps";
import { Breadcrumbs } from "../../../../common/breadcrumbs/Breadcrumbs";

interface DashboardBreadcrumbsProps {
  loading: boolean;
}

export const DashboardAppBarNavigation: FC<DashboardBreadcrumbsProps> = ({
  loading,
}) => {
  const navigate = useNavigate();
  const enableHierarchySeparatePages = useFlag("EnableHierarchySeparatePages");

  const name = useAppSelector(currentDashboardNameSelector);
  const currentFolder = useAppSelector(currentDashboardFolderSelector);
  const dashboardId = useAppSelector(currentDashboardIdSelector);

  const visibility = useAppSelector(
    currentDashboardHierarchyVisibilitySelector,
  );

  const changeNavigationHandler = useCallback(
    (id: string) => {
      navigate(`/dashboards/${id}`);
    },
    [navigate],
  );

  return (
    <Stack direction="row" spacing={1} alignItems="center">
      {enableHierarchySeparatePages && (
        <Fragment>
          <Breadcrumbs breadcrumbs={allItemsBreadcrumbs} />
          <Typography variant="body1" color="text.secondary">
            /
          </Typography>
        </Fragment>
      )}

      <HierarchicalBreadcrumbs
        folderName={currentFolder?.name}
        loading={!name || loading}
      >
        <HierarchyDashboardSelectionWrapper
          selectedItemId={dashboardId ?? ""}
          CustomTriggerComponent={DashboardBreadcrumbNavigationTrigger}
          onChange={changeNavigationHandler}
        />
      </HierarchicalBreadcrumbs>
      <DashboardV2ItemActionButton
        id={dashboardId ?? ""}
        name={name ?? ""}
        folderId={currentFolder?.id}
        visibility={visibility ?? "visible_to_everyone"}
      />
    </Stack>
  );
};

const allItemsBreadcrumbs = [
  {
    title: "Dashboards",
    icon: <DashboardCustomizeOutlinedIcon fontSize="small" />,
    to: "/dashboards",
  },
];

const DashboardBreadcrumbNavigationTrigger = (
  props: FilterTriggerComponentProps,
) => {
  return <BreadcrumbNavigationTrigger {...props} icon={GridViewOutlinedIcon} />;
};
