import { FC, Fragment, ReactNode, useCallback, MouseEvent } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Slide,
  Typography,
} from "@mui/material";
import { SxProps } from "@mui/system";
import {
  AccountStatus,
  ProviderType,
  usePatchUsersMeAccountsByAccountIdMutation,
} from "../../../../../../services/cloudchipr.api";
import { useAccountAddFunctional } from "../../../utils/hooks/useAccountAddFunctional.hook";
import { getProviderName } from "../../../../../../utils/helpers/providers/getProviderName";
import { nonWorkingAccountStatuses } from "../../../../../../utils/constants/accounts";

interface TooltipCardWrapperProps {
  status: AccountStatus;
  provider: ProviderType;
  accountId: string;
  hovered: boolean;
  children?: ReactNode;
}

export const TooltipCardWrapper: FC<TooltipCardWrapperProps> = ({
  status,
  provider,
  children,
  accountId,
  hovered,
}) => {
  const [trigger, { isLoading }] = usePatchUsersMeAccountsByAccountIdMutation();
  const { startAddAccountFlow, canNotCreateAccount } = useAccountAddFunctional({
    accountId,
    provider,
  });

  const handleRetryClick = useCallback(() => {
    trigger({ accountId });
  }, [trigger, accountId]);

  const handleAttachClick = useCallback(() => {
    startAddAccountFlow();
  }, [startAddAccountFlow]);

  const handleBoxClick = useCallback((event: MouseEvent<HTMLDivElement>) => {
    event.preventDefault();
  }, []);

  if (!nonWorkingAccountStatuses.has(status)) {
    return <Fragment>{children}</Fragment>;
  }

  return (
    <Fragment>
      {children}

      <Slide in={hovered} direction="up" style={{ opacity: 0.8 }} timeout={500}>
        <Box
          p={2}
          bottom={0}
          bgcolor="black"
          position="absolute"
          onClick={handleBoxClick}
        >
          <Typography color="white" variant="caption">
            {status === "deactivated" ? (
              <Fragment>
                We are unable to verify that we have permission to access your
                cloud {getProviderName(provider)}. Please check your{" "}
                {getProviderName(provider)} access or{" "}
                <Button
                  variant="text"
                  onClick={handleRetryClick}
                  sx={buttonStyles}
                >
                  {isLoading ? (
                    <CircularProgress size={16} disableShrink />
                  ) : (
                    "reconnect"
                  )}{" "}
                </Button>
                your account.
              </Fragment>
            ) : (
              <Fragment>
                To start seeing your spending in real time, click{" "}
                <Button
                  variant="text"
                  onClick={handleAttachClick}
                  disabled={canNotCreateAccount}
                  sx={buttonStyles}
                >
                  {isLoading ? (
                    <CircularProgress size={16} disableShrink />
                  ) : (
                    `add ${getProviderName(provider)}`
                  )}{" "}
                </Button>{" "}
                and complete the quick setup.
              </Fragment>
            )}
          </Typography>
        </Box>
      </Slide>
    </Fragment>
  );
};

const buttonStyles: SxProps = {
  fontSize: 12,
  color: "info.main",
  textTransform: "lowercase",
  textDecoration: "underline",
  display: "contents",
  "&.Mui-disabled": {
    color: "grey.500",
  },
};
