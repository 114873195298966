import { RootState } from "../../../../store";
import { automationDataToValidateSelector } from "../../common/automationDataToValidateSelector";
import { offHoursActionSelector } from "../offHoursActionSelector";

export const offHoursDataToValidateSelector = (state: RootState) => {
  const automationDataToValidate = automationDataToValidateSelector(state);
  const action = offHoursActionSelector(state);

  return {
    ...automationDataToValidate,
    action,
    gracePeriod: null,
  };
};
