import { FC, memo, useCallback, useEffect, useState } from "react";
import {
  InputAdornment,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { useDebounce } from "rooks";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import {
  setExecutionLogFilters,
  setExecutionLogPagination,
} from "../../../../store/execution-log/executionLogSlice";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { executionLogFiltersSelector } from "../../../../store/execution-log/selectors/executionLogFiltersSelector";
import { getExecutionLogThunk } from "../../../../store/execution-log/thunks/getExecutionLogThunk";

export const ExecutionLogsSearch: FC = memo(() => {
  const dispatch = useAppDispatch();
  const filters = useAppSelector(executionLogFiltersSelector);

  const [searchValue, setSearchValue] = useState("");

  const searchChangeHandler = useCallback(
    (search: any) => {
      dispatch(setExecutionLogFilters({ search }));
      dispatch(setExecutionLogPagination({ page: 0 }));
      dispatch(getExecutionLogThunk({}));
    },
    [dispatch],
  );

  const setValueDebounced = useDebounce(searchChangeHandler, 300);

  const debouncedValueChangeHandler = useCallback(
    (event: any) => {
      const value = event.target.value;

      setValueDebounced(value);
      setSearchValue(value);
    },
    [setValueDebounced],
  );

  useEffect(() => {
    setSearchValue(filters?.search ?? "");
  }, [filters?.search]);

  return (
    <TextField
      type="search"
      placeholder="Search"
      value={searchValue}
      size="xsmall"
      onChange={debouncedValueChangeHandler}
      sx={{ maxWidth: 200 }}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        ),
        endAdornment: tooltip,
      }}
    />
  );
});

const tooltip = (
  <Tooltip
    arrow
    title={
      <Stack>
        <Typography variant="caption">You can search by</Typography>
        <ul style={{ margin: 0, padding: "0 0 0 20px" }}>
          <li>ID</li>
          <li>Service</li>
          <li>Region</li>
          <li>Details (ID and Message)</li>
        </ul>
      </Stack>
    }
  >
    <InfoOutlinedIcon
      color="action"
      fontSize="small"
      sx={{ verticalAlign: "middle", ml: 1 }}
    />
  </Tooltip>
);
