import { DateRange, extendMoment } from "moment-range";
import * as Moment from "moment/moment";
import { findRecommendedRanges } from "./findRecommendedRanges";

const moment = extendMoment(Moment);

interface FindDailyRecommendedRangesArgs {
  firstTimestamp: string;
  lastTimestamp: string;
  fromHour: number;
  toHour: number;
}

export const findDailyRecommendedRanges = ({
  firstTimestamp,
  lastTimestamp,
  fromHour,
  toHour,
}: FindDailyRecommendedRangesArgs): DateRange[] => {
  const firstRangeStart = moment(firstTimestamp).utc().subtract(1, "day").set({
    hour: fromHour,
    minutes: 0,
  });
  const firstRangeEnd = moment(firstTimestamp)
    .utc()
    .subtract(fromHour < toHour ? 1 : 0, "day")
    .set({
      hour: toHour,
      minutes: 0,
    });

  const mainRange = moment.range(
    moment(firstTimestamp).utc(),
    moment(lastTimestamp).utc(),
  );

  const firstRange = moment.range(firstRangeStart, firstRangeEnd);

  return findRecommendedRanges({ mainRange, firstRange, rangeType: "day" });
};
