import { FC, Fragment, memo, useCallback, useState } from "react";
import { FormikHandlers, FormikHelpers } from "formik";
import moment from "moment";
import * as yup from "yup";
import { Grid } from "@mui/material";
import { FilterItemOperatorType } from "../FilterItemOperatorType";
import { InputWithTimeUnit } from "../InputWithTimeUnit";
import {
  FilterItem,
  Operator,
} from "../../../../../../services/cloudchipr.api";
import {
  DayMultiplier,
  HourMultiplier,
  TimeUnit,
} from "../../../../../../utils/helpers/date-time/time";

export const RelativeTimeFilterValueValidation = yup
  .number()
  .required("This field is required");

interface RelativeTimeFilterProps {
  operators?: Operator[];
  filter: FilterItem;
  error?: any;
  onChange: FormikHandlers["handleChange"];
  setFieldValue: FormikHelpers<FilterItem>["setFieldValue"];
}

export const RelativeTimeFilter: FC<RelativeTimeFilterProps> = memo(
  ({ operators, filter, onChange, setFieldValue, error }) => {
    let initialTimeUnit: TimeUnit = "h";
    if (filter.value) {
      const durationAsDays = moment.duration(+filter.value, "seconds").asDays();

      if (durationAsDays % 1 === 0) {
        initialTimeUnit = "d";
      }
    }

    const [timeUnit, setTimeUnit] = useState<TimeUnit>(initialTimeUnit);
    const multiplier = timeUnit === "h" ? HourMultiplier : DayMultiplier;

    const timeUnitChangeHandler = useCallback(
      (unit: TimeUnit) => {
        setTimeUnit(unit);

        if (filter.value) {
          const valInUnit = +filter.value / multiplier;

          const newMultiplier = unit === "h" ? HourMultiplier : DayMultiplier;
          setFieldValue("value", +valInUnit * newMultiplier);
        }
      },
      [setFieldValue, filter.value, multiplier],
    );

    const valueChangeHandler = useCallback(
      (value: string) => {
        const val = +value * multiplier;
        setFieldValue("value", val || null);
      },
      [setFieldValue, multiplier],
    );

    return (
      <Fragment>
        <FilterItemOperatorType
          value={filter.operator || ""}
          options={operators}
          onChange={onChange}
        />

        <Grid item md={3}>
          <InputWithTimeUnit
            value={filter.value ? `${+filter.value / multiplier}` : ""}
            error={!!error}
            unit={timeUnit}
            onChange={valueChangeHandler}
            onUnitChange={timeUnitChangeHandler}
            inputLabel={timeUnit === "h" ? "Hours" : "Days"}
          />
        </Grid>

        <Grid item md={5} />
      </Fragment>
    );
  },
);
