import { cleanV2ResourcesSelector } from "./cleanV2ResourcesSelector";
import { cleanV2ActionTypeSelector } from "./store-selectors/cleanV2ActionTypeSelector";
import { RootState } from "../../store";
import { CleanResourcesDataType } from "../../../components/pages/common/clean/clean-dialog/utils/types";

export const cleanV2ResourcesGridDataSelector = (
  state: RootState,
): CleanResourcesDataType => {
  const resources = cleanV2ResourcesSelector(state) ?? [];
  const cleanActionType = cleanV2ActionTypeSelector(state);

  return resources?.map((r) => {
    return {
      ...r,
      cleanAction: cleanActionType || "delete",
    };
  });
};
