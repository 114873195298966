import { FC } from "react";
import { Stack, Typography } from "@mui/material";

import { EmailActionExecutedSubject } from "./EmailActionExecutedSubject";
import { EmailActionExecutedWorkflowInfo } from "./EmailActionExecutedWorkflowInfo";
import { EmailIntegrationCsvInfo } from "../common/EmailIntegrationCsvInfo";
import { integrationCustomEmailActionExecuteDataGrid } from "../utils/constants";
import { IntegrationCustomMessageDataGrid } from "../../common/IntegrationCustomMessageDataGrid";

interface EmailActionExecutedProps {
  message: string;
}

export const EmailActionExecuted: FC<EmailActionExecutedProps> = ({
  message,
}) => {
  return (
    <Stack spacing={1.5}>
      <EmailActionExecutedSubject />
      <EmailActionExecutedWorkflowInfo />

      {message && (
        <Typography
          variant="body1"
          dangerouslySetInnerHTML={{ __html: message }}
        />
      )}

      <IntegrationCustomMessageDataGrid
        data={integrationCustomEmailActionExecuteDataGrid}
        label="Account Name / ID"
      />
      <EmailIntegrationCsvInfo />
    </Stack>
  );
};
