import { FC } from "react";
import { Box, List, ListItem, Typography } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";

export const Step1: FC = () => {
  return (
    <Box px={2}>
      <Typography variant="h5" fontWeight="medium">
        Step 1
      </Typography>

      <List
        sx={{
          listStyleType: "disc",
          pl: 2,
          "& .MuiListItem-root": {
            display: "list-item",
          },
        }}
      >
        <ListItem sx={{ px: 0 }}>
          <Typography variant="subtitle1" color="text.secondary">
            Go to{" "}
            <Typography
              color="primary"
              variant="body1"
              to="https://console.cloud.google.com/iam-admin/iam"
              component={RouterLink}
              target="_blank"
            >
              IAM in GCP Console
            </Typography>{" "}
          </Typography>
        </ListItem>

        <ListItem sx={{ px: 0 }}>
          <Typography variant="subtitle1" color="text.secondary">
            Search for provided Cloudchipr’s Service Account.
          </Typography>
        </ListItem>

        <ListItem sx={{ px: 0 }}>
          <Typography variant="subtitle1" color="text.secondary">
            Choose{" "}
            <Typography component="span" fontWeight="medium">
              Edit principal
            </Typography>{" "}
            (pencil icon).
          </Typography>
        </ListItem>
      </List>
    </Box>
  );
};
