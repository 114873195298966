import { costAndUsageSumWidgetByIdSelector } from "./costAndUsageSumWidgetByIdSelector";
import { RootState } from "../../../../store";
import { resourceExplorerViewNameByViewIdSelector } from "../../../../resource-explorer/selectors/all-resource-explorers/resourceExplorerViewNameByViewIdSelector";

export const costAndUsageSummaryWidgetViewNameSelector = (
  state: RootState,
  widgetId: string,
) => {
  const widget = costAndUsageSumWidgetByIdSelector(state, widgetId);
  const viewId = widget?.view_id;

  return resourceExplorerViewNameByViewIdSelector(state, viewId);
};
