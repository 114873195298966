import { useCallback, useMemo } from "react";
import { useAppSelector } from "../../../../../../store/hooks";
import { allowedFiltersByResourceTypeWithChildrenFiltersSelector } from "../../../../../../store/filters/selectors/allowedFiltersByResourceTypeWithChildrenFiltersSelector";
import {
  FilterItem,
  FilterKeyType,
  MetricOfFilterItem,
  ResourceType,
  Tag,
} from "../../../../../../services/cloudchipr.api";
import { durationFilterTypes } from "../constants/filter-types/duration-filter-types";
import { secondsToDuration } from "../../../../../../utils/helpers/date-time/time";
import { metricFilterTypes } from "../constants/filter-types/metric-filter-types";
import { convertByteToGB } from "../helpers/unitsConverting";
import { dateFilterTypes } from "../constants/filter-types/date-filter-types";
import { formatDate } from "../../../../../../utils/helpers/date-time/datetime-format";
import { filterUnits } from "../constants/filter-units";
import { unaryOperators } from "../constants/unary-operators";
import { isSmartTag } from "../helpers/isSmartTag";
import { smartTagTypes } from "../constants/smart-tag-types";
import { filterRuleLabels } from "../constants/filter-rule-labels";
import { allResourceTypesAllowedFiltersSelector } from "../../../../../../store/filters/selectors/allResourceTypesAllowedFiltersSelector";

export function useFilterLabel(resourceType: ResourceType) {
  const allowedAllFiltersWithChildren = useAppSelector((state) =>
    allowedFiltersByResourceTypeWithChildrenFiltersSelector(
      state,
      resourceType,
    ),
  );

  const allowedFiltersWithChildren = allowedAllFiltersWithChildren
    ?.map(({ data }) => data)
    ?.flat();

  const allAllowedFilters = useAppSelector(
    allResourceTypesAllowedFiltersSelector,
  );

  const getFilterKeyLabel = useCallback(
    (filterKey: FilterKeyType) => {
      const filter = allowedFiltersWithChildren?.find(
        (f) => f.key === filterKey,
      );

      return filter?.label;
    },
    [allowedFiltersWithChildren],
  );

  const getFilterLabel = useCallback(
    (filter: FilterItem, fullLength?: boolean) => {
      const resourceLabel =
        allAllowedFilters.find(({ type }) => type === resourceType)?.label ??
        "";

      let prefix = unaryOperators.has(filter.operator) ? ":" : "";

      if (isSmartTag(filter.key)) {
        prefix = `- ${(smartTagTypes.get(filter.key) ?? "") + prefix}`;
      }

      const filterKeyLabel = getFilterKeyLabel(filter.key);
      const filterOperator = filterRuleLabels.get(filter.operator);
      const filterValue = getFilterValue(filter.key, filter.value, fullLength);
      const filterMetric = getFilterMetricLabel(filter.metric);

      return `${filterKeyLabel} (${resourceLabel}) ${prefix} ${filterOperator} ${filterValue} ${filterMetric}`;
    },
    [getFilterKeyLabel, allAllowedFilters, resourceType],
  );

  return useMemo(
    () => ({
      getFilterKeyLabel,
      getFilterLabel,
    }),
    [getFilterKeyLabel, getFilterLabel],
  );
}

const reduceFilterValue = (value: Tag | string[], fullLength?: boolean) => {
  if (Array.isArray(value)) {
    if (fullLength) {
      return value.join(", ");
    } else {
      return value.length > 1
        ? `${value[0]}, +${value.length - 1} more`
        : value[0];
    }
  }

  let str = value?.["key"] || "";

  if (value?.["value"]) {
    str = `${str}:${value?.["value"]}`;
  }

  return str;
};

const getFilterMetricLabel = (filterMetric?: MetricOfFilterItem) => {
  return filterMetric?.since && filterMetric.statistics_type
    ? `(${filterMetric.statistics_type} since ${filterMetric.since.value} ${filterMetric.since.unit})`
    : "";
};

const getFilterValue = (
  key: FilterKeyType,
  value: FilterItem["value"],
  fullLength?: boolean,
) => {
  if (value === undefined) {
    return "";
  }

  if (typeof value === "object") {
    return reduceFilterValue(value as Tag | string[], fullLength);
  }

  if (durationFilterTypes.has(key) || key === "unused_since") {
    value = secondsToDuration(value);
  } else if (metricFilterTypes.has(key)) {
    value = convertByteToGB(value as number);
  } else if (dateFilterTypes.has(key)) {
    value = formatDate(value as string, { type: "date" });
  }

  return value + (filterUnits.get(key) ?? "");
};
