import { FC } from "react";
import { Box, Stack, Typography } from "@mui/material";
import AccessDeniedIcon from "../../assets/images/access-denied.png";

export const AccessDenied: FC = () => {
  return (
    <Box
      height="100vh"
      textAlign="center"
      bgcolor="white"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <Stack alignItems="center" spacing={2}>
        <img src={AccessDeniedIcon} alt="Access Denied" width="146" />
        <Typography variant="h5" color="text.primary">
          Access denied
        </Typography>
        <Typography variant="body2" color="text.secondary">
          You don’t have permission to view this page. Please contact your
          administrator to grant you the appropriate access.
        </Typography>
      </Stack>
    </Box>
  );
};
