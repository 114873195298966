import { allResourceTypesAllowedFiltersSelector } from "./allResourceTypesAllowedFiltersSelector";
import { RootState } from "../../store";
import {
  FilterItemTemplate,
  Filters,
  ResourceType,
} from "../../../services/cloudchipr.api";

export const allowedFiltersByResourceTypeWithChildrenFiltersSelector = (
  state: RootState,
  resourceType: ResourceType,
): { resourceType: ResourceType; data: FilterItemTemplate[] }[] | undefined => {
  const allowedFilters = allResourceTypesAllowedFiltersSelector(state);

  const allowedFiltersByResourceType = allowedFilters.find(
    (f) => f.type === resourceType,
  );

  if (!allowedFiltersByResourceType) {
    return;
  }

  return generateFiltersData(allowedFiltersByResourceType);
};

const generateFiltersData = (filter: Filters[0]) => {
  const childFilters: {
    data: FilterItemTemplate[];
    resourceType: ResourceType;
  }[] = [];

  if (filter.child_filters) {
    filter.child_filters?.forEach((f) => {
      childFilters.push(...generateFiltersData(f));
    });
  }

  return [
    {
      data: filter.filters,
      resourceType: filter.type,
    },
    ...childFilters,
  ];
};
