import { DropdownSelectOption } from "../../../../../../../../common/select/dropdown-select/utils/types/types";
import { SlackConversationResponse } from "../../../../../../../../../services/cloudchipr.api";

export const findSlackSelectedOptions = (
  options: DropdownSelectOption[],
  values: string[],
) => {
  return options
    .filter((option) => values.includes(option.value))
    .map((option) => option.rawValue);
};

export const slackSelectionOptionsFilter = (
  option: DropdownSelectOption,
  keyword: string,
) => {
  const aim =
    `${option.rawValue.name} ${option.rawValue.display_name}`.toLowerCase();

  return aim.includes(keyword.toLowerCase().trim());
};

export const mergeSlackConversations = (
  conversationsA: SlackConversationResponse[],
  conversationsB: SlackConversationResponse[],
) => {
  let uniqueConversations = conversationsA.reduce(
    (acc, option) => {
      acc[option.id] = option;

      return acc;
    },
    {} as Record<string, SlackConversationResponse>,
  );

  uniqueConversations = conversationsB.reduce((acc, option) => {
    acc[option.id] = option;

    return acc;
  }, uniqueConversations);

  return Object.values(uniqueConversations);
};
