import { createCron } from "./createCron";

export const generateHourlyCron = (interval: number): string => {
  const cronArr = createCron({
    hour: interval,
  }).split(" ");
  cronArr[1] = "0/" + cronArr[1];
  cronArr[4] = "?";

  return cronArr.join(" ");
};
