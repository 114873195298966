import { reportsDataPropertyByKeySelector } from "./reportsDataPropertyByKeySelector";
import { RootState } from "../../store";
import { WeeklySchedule } from "../../../services/cloudchipr.api";

export const reportsScheduleWeekDaysSelector = (state: RootState) => {
  const schedule = reportsDataPropertyByKeySelector("schedule")(
    state,
  ) as WeeklySchedule;

  return schedule.week_days;
};
