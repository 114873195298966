import { FC, useCallback } from "react";
import Tab from "@mui/material/Tab";
import { Tooltip } from "@mui/material";
import { TabList } from "@mui/lab";
import { RecommendationsTabType } from "../off-hours-recommendations/components/OffHoursRecommendationsContent";

interface RecommendationsTabsProps {
  tabs: {
    value: string;
    label: string;
    count: number;
    disabled: boolean;
    tooltip: string;
  }[];
  showCount: boolean;
  setActiveTab(tab: RecommendationsTabType): void;
}

export const RecommendationsTabs: FC<RecommendationsTabsProps> = ({
  tabs,
  setActiveTab,
  showCount,
}) => {
  const tabChangeHandler = useCallback(
    (_: any, tab: RecommendationsTabType) => {
      setActiveTab(tab);
    },
    [setActiveTab],
  );

  return (
    <TabList onChange={tabChangeHandler}>
      {tabs.map((tab) => {
        return (
          <Tab
            key={tab.value}
            value={tab.value}
            style={{ pointerEvents: "auto" }}
            label={
              <div>
                <Tooltip
                  title={tab.disabled ? tab.tooltip : ""}
                  placement="top"
                >
                  <span>
                    {tab.label} {showCount && `(${tab.count})`}
                  </span>
                </Tooltip>
              </div>
            }
            disabled={tab.disabled}
          />
        );
      })}
    </TabList>
  );
};
