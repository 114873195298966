import { createAsyncThunk } from "@reduxjs/toolkit";
import { generateGroupedRolePermissions } from "./utils/generateGroupedRolePermissions";
import { RootState } from "../../../store";
import { userRolePermissionsSelector } from "../../selectors/roles/userRolePermissionsSelector";
import { setUserRoleData } from "../../userSlice";
import { userRoleDataPermissionsSelector } from "../../selectors/roles/userRoleDataPermissionsSelector";
import { PermissionWithSelectedState } from "../../utils/types/common";

export const userRolePermissionsSearchThunk = createAsyncThunk(
  "roles/userRolePermissionsSearch",
  (searchValue: string, { dispatch, getState }) => {
    const state = getState() as RootState;
    const permissions = userRolePermissionsSelector(state);
    const storePermissions = userRoleDataPermissionsSelector(state);

    const value = searchValue.trim().toLowerCase();

    if (!permissions) {
      return;
    }

    if (!value.length) {
      dispatch(
        setUserRoleData({
          permissions: generateGroupedRolePermissions(permissions),
        }),
      );
      return;
    }

    const filteredData = permissions?.reduce((result, item) => {
      if (
        item.name?.toLowerCase()?.includes(value) ||
        item.group?.toLowerCase()?.includes(value) ||
        item.description?.toLowerCase()?.includes(value)
      ) {
        const selected = storePermissions?.[item.group].data.find(
          (perm) => perm.name === item.name,
        )?.selected;
        result.push({
          ...item,
          selected,
        });
      }

      return result;
    }, [] as PermissionWithSelectedState[]);

    if (!filteredData) {
      return;
    }
    dispatch(
      setUserRoleData({
        permissions: generateGroupedRolePermissions(filteredData),
      }),
    );
  },
);
