import { FC } from "react";
import { Alert, Link } from "@mui/material";
import { isSubscriptionPlanOverdueSelector } from "../../../store/subscriptions/subscriptionsSelectors";
import { useAppSelector } from "../../../store/hooks";

export const FailedPaymentSystemMessage: FC = () => {
  const isSubscriptionOverdue = useAppSelector(
    isSubscriptionPlanOverdueSelector,
  );

  if (!isSubscriptionOverdue) {
    return null;
  }

  return (
    <Alert severity="error" variant="filled" shape="sharp">
      In order to continue using Cloudchipr please update your payment method to
      pay for your current plan or contact our support team by{" "}
      <Link href="mailto:support@cloudchipr.com" color="inherit">
        support@cloudchipr.com
      </Link>
    </Alert>
  );
};
