import { FC } from "react";
import { Navigate, useLocation, useParams } from "react-router-dom";
import { noOrgAccountOrgId } from "./utils/helpers/noOrganizationConstants";

export const AccountsOldUrlsTmpGuard: FC = () => {
  const location = useLocation();
  const { orgId } = useParams();

  const provider = location.pathname.split("/").at(1);

  if (orgId) {
    return <Navigate to={`${provider}/${orgId}/accounts`} />;
  }

  return <Navigate to={`/${provider}/${noOrgAccountOrgId}/accounts`} />;
};
