import { FC, Fragment } from "react";

interface ReadWriteUsageCellProps {
  data: number;
  type: "on-demand" | "provisioned";
  reservation: "rcu" | "wcu";
}

export const ReadWriteUsageCell: FC<ReadWriteUsageCellProps> = ({
  type,
  data,
  reservation,
}) => {
  return (
    <Fragment>
      {data} {type === "provisioned" ? "%" : reservation.toUpperCase()}
    </Fragment>
  );
};
