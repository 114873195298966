import { FC } from "react";
import { Alert, Box, Stack, Typography } from "@mui/material";
import AutoFixHighOutlinedIcon from "@mui/icons-material/AutoFixHighOutlined";
import { IntegrationCustomMessageInfoSection } from "../../common/IntegrationCustomMessageInfoSection";

export const EmailActionExecutedWorkflowInfo: FC = () => {
  return (
    <Box>
      <Stack justifyContent="space-between" direction="row">
        <IntegrationCustomMessageInfoSection
          start="Workflow"
          tail="{{workflow name}}"
          color="primary.main"
        />

        <Typography variant="body2" color="text.secondary" fontWeight="medium">
          Monthly Price:
        </Typography>
      </Stack>

      <Stack justifyContent="space-between" direction="row" spacing={0.5}>
        <Stack direction="row" alignItems="center" spacing={0.5}>
          <AutoFixHighOutlinedIcon fontSize="small" color="success" />
          <Typography variant="body1" fontWeight="medium">
            Actions Executed
          </Typography>
        </Stack>
        <Typography variant="body2" fontWeight="medium">
          {`{{0.00}}`}
        </Typography>
      </Stack>
      <IntegrationCustomMessageInfoSection start="Run date" tail="{{date}}}" />
      <Alert
        severity="info"
        sx={{
          color: "text.secondary",
          fontVariant: "caption",
          alignItems: "center",
          mt: 1.5,
          "& .MuiAlert-icon": {
            color: "text.secondary",
          },
        }}
      >
        Cloudchipr dispatches an email once it performes an action, but it
        doesn't provide updates on the action's status. The action statuses can
        be viewed in Execution Logs. Please be aware that some actions may take
        time to complete.
      </Alert>
    </Box>
  );
};
