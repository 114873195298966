import { FC, useCallback } from "react";
import { Grid } from "@mui/material";
import { FilterItemNodeWithId } from "../../../../../utils/types/common";
import { FilterKeySelect, FilterWithKey } from "../filter-key/FilterKeySelect";
import { FilterValue } from "../filter-value/FilterValue";

interface DimensionFilterItemProps {
  index: number;
  filter: FilterItemNodeWithId;
  onFilterChange(filter: FilterItemNodeWithId, index: number): void;
}

export const DimensionFilterItem: FC<DimensionFilterItemProps> = ({
  filter,
  onFilterChange,
  index,
}) => {
  const filterValueChangeHandler = useCallback(
    (value: FilterItemNodeWithId["value"]) => {
      onFilterChange({ ...filter, value }, index);
    },
    [index, onFilterChange, filter],
  );

  return (
    <Grid container columnSpacing={0.5}>
      <Grid item xs={6}>
        <FilterKeySelect
          index={index}
          label="Select Dimension"
          filter={filter as FilterWithKey}
          onFilterChange={onFilterChange}
        />
      </Grid>

      <Grid item xs={6}>
        <FilterValue
          label="Category"
          filter={filter}
          onChange={filterValueChangeHandler}
        />
      </Grid>
    </Grid>
  );
};
