import { FC } from "react";

import { ResourceExplorerV2ViewCreateFolderOrViewDialogV2 } from "../create-folder-or-view-dialog-v2/ResourceExplorerV2ViewCreateFolderOrViewDialogV2";
import {
  NavigationHierarchyItemType,
  NavigationItemsVisibilityType,
} from "../../../../../../store/navigation/utils/types";

interface ResourceExplorerCreateFolderOrItemDialogWrapperProps {
  onClose(): void;
  type: NavigationHierarchyItemType;
  folderId?: string;
  visibility?: NavigationItemsVisibilityType;
  title?: string;
  viewId?: string;
  redirectAfterCreate?: boolean;
}

export const ResourceExplorerCreateFolderOrItemDialogWrapper: FC<
  ResourceExplorerCreateFolderOrItemDialogWrapperProps
> = ({
  onClose,
  type,
  folderId,
  visibility,
  title,
  viewId,
  redirectAfterCreate,
}) => {
  return (
    <ResourceExplorerV2ViewCreateFolderOrViewDialogV2
      onClose={onClose}
      type={type}
      folderId={folderId}
      visibility={visibility}
      title={title}
      viewId={viewId}
      redirectAfterCreate={redirectAfterCreate}
    />
  );
};
