import { FC, useCallback, useState } from "react";
import { Button, TableCell, TableRow } from "@mui/material";
import { ApiKeysTest } from "./ApiKeysTest";
import { ApiKeysListGridActionCell } from "./ApiKeysListGridActionCell";
import { LocalDateCell } from "./LocalDateCell";
import { ApiKey } from "../../../../../../services/cloudchipr.api";
import { CopyCell } from "../../../../../common/data-grid-cells/CopyCell";

interface ApiKeysListGridRowProps {
  apiKey: ApiKey;
  showForm(): void;
}

export const ApiKeysListGridRow: FC<ApiKeysListGridRowProps> = ({
  apiKey,
  showForm,
}) => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = useCallback(() => {
    setOpen(true);
  }, []);

  const handleClickClose = useCallback(() => {
    setOpen(false);
  }, []);

  return (
    <TableRow>
      <TableCell>{apiKey.name}</TableCell>
      <TableCell>
        <CopyCell data={apiKey.api_key} />
      </TableCell>

      <LocalDateCell date={apiKey.last_used_at} />
      <LocalDateCell date={apiKey.created_at} />
      <LocalDateCell date={apiKey.expires_at} />
      <TableCell>
        <Button variant="outlined" onClick={handleClickOpen} size="small">
          Test
        </Button>
      </TableCell>
      <ApiKeysListGridActionCell
        keyId={apiKey.id}
        keyName={apiKey.name}
        showForm={showForm}
      />
      <ApiKeysTest
        open={open}
        handleClose={handleClickClose}
        apiKey={apiKey.api_key}
      />
    </TableRow>
  );
};
