import { FC } from "react";
import { Card, Divider, Stack } from "@mui/material";
import { LiveUsageMgmtEnableFilterTemplatesSwitcher } from "./enable-filter-templates-switcher/LiveUsageMgmtEnableFilterTemplatesSwitcher";
import { LiveUsageMgmtAccountFilters } from "./LiveUsageMgmtAccountFilters";
import { CreateWorkflowButton } from "../create-workflow/CreateWorkflowButton";
import { useAppSelector } from "../../../../../../store/hooks";
import { liveUsageMgmtFilterTemplatesEnabledSelector } from "../../../../../../store/live-usage-mgmt/selectors/store-selectors/liveUsageMgmtFilterTemplatesEnabledSelector";
import { LiveUsageMgmtFilterTemplateView } from "../../filter-templates/LiveUsageMgmtFilterTemplateView";

export const LiveUsageMgmtToolbar: FC = () => {
  const filterTemplatesEnabled = useAppSelector(
    liveUsageMgmtFilterTemplatesEnabledSelector,
  );

  return (
    <Stack
      spacing={2}
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      px={1}
      borderBottom={1}
      borderColor="grey.300"
      minHeight={52}
      boxSizing="content-box"
    >
      <Card variant="outlined" sx={{ borderColor: "grey.100" }}>
        <Stack direction="row" alignItems="center">
          <LiveUsageMgmtEnableFilterTemplatesSwitcher />

          <Divider
            orientation="vertical"
            flexItem
            sx={{ borderColor: "grey.100" }}
          />
          <Stack
            sx={{
              pr: 1,
              pointerEvents: filterTemplatesEnabled ? "inherit" : "none",
              opacity: filterTemplatesEnabled ? 1 : 0.5,
            }}
            direction="row"
          >
            <LiveUsageMgmtFilterTemplateView />

            <Divider
              orientation="vertical"
              flexItem
              sx={{ borderColor: "grey.100" }}
            />

            <LiveUsageMgmtAccountFilters />
          </Stack>
        </Stack>
      </Card>
      <CreateWorkflowButton />
    </Stack>
  );
};
