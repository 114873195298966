import { FC } from "react";
import { SxProps } from "@mui/system";
import { Theme } from "@mui/material";
import { CSS } from "@dnd-kit/utilities";
import { AnimateLayoutChanges, useSortable } from "@dnd-kit/sortable";

import { TreeItem, TreeItemProps } from "./TreeItem";
interface SortableTreeItemProps extends TreeItemProps {
  id: string;
}

const animateLayoutChanges: AnimateLayoutChanges = ({
  isSorting,
  wasDragging,
}) => !(isSorting || wasDragging);

export const SortableTreeItem: FC<SortableTreeItemProps> = ({
  id,
  depth,
  type,
  disabled,
  items,
  collapsed,
  ...props
}) => {
  const {
    attributes,
    isDragging,
    listeners,
    setDraggableNodeRef,
    setDroppableNodeRef,
    transform,
    transition,
  } = useSortable({
    id,
    animateLayoutChanges,
    data: {
      type,
    },
    disabled,
  });
  const style: SxProps<Theme> = {
    transform: CSS.Translate.toString(transform),
    transition,
  };

  return (
    <TreeItem
      ref={setDraggableNodeRef}
      wrapperRef={setDroppableNodeRef}
      depth={depth}
      id={id}
      ghost={isDragging}
      handleProps={{
        ...attributes,
        ...listeners,
      }}
      type={type}
      {...props}
      sx={style}
      collapsed={collapsed}
      disabled={disabled}
      items={items}
    />
  );
};
