import { FC, useCallback } from "react";
import { TaskPropertyType } from "../../../../../../../../store/task-management/utils/types/types";
import { DebouncedInput } from "../../../../../../../../storybook/data-grid/common/DebouncedInput";

interface TaskPropertyNameProps {
  value: string;
  onPropertyChange(property: Partial<TaskPropertyType>): void;
}

export const TaskPropertyName: FC<TaskPropertyNameProps> = ({
  onPropertyChange,
  value,
}) => {
  const changeHandler = useCallback(
    (name: string | number) => {
      onPropertyChange({ name: `${name}` });
    },
    [onPropertyChange],
  );

  return (
    <DebouncedInput
      fullWidth
      focused={!value}
      value={value}
      variant="standard"
      InputProps={inputProps}
      onChange={changeHandler}
      placeholder="Type a property name..."
    />
  );
};

const inputProps = {
  sx: { "&::before": { border: "none" } },
};
