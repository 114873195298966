import { cleanV2StateSelector } from "./store-selectors/cleanV2StateSelector";
import { CleanV2Details } from "../cleanV2Slice";
import { RootState } from "../../store";
import { NEW_CLEAN_ID } from "../utils/constants";

export const currentV2OpenCleanProcessSelector = (
  state: RootState,
): CleanV2Details | null => {
  const cleanState = cleanV2StateSelector(state);
  if (cleanState.currentOpenId === NEW_CLEAN_ID) {
    return cleanState.details;
  }

  if (
    !cleanState.currentOpenId ||
    !cleanState.processes[cleanState.currentOpenId]
  ) {
    return null;
  }

  return cleanState.processes[cleanState.currentOpenId];
};
