import { createAsyncThunk } from "@reduxjs/toolkit";
import { resourceExplorerIsDefaultSelector } from "../../selectors/current-resource-explorer/resourceExplorerIsDefaultSelector";
import { RootState } from "../../../store";
import { resourceExplorerDataSelector } from "../../selectors/current-resource-explorer/data/resourceExplorerDataSelector";
import { resourceExplorerFilterTreeSelector } from "../../selectors/filter-tree/resourceExplorerFilterTreeSelector";
import { FilterTreeNodeWithId } from "../../../../components/pages/common/filters-tree/utils/types/common";
import { ResourceExplorerData } from "../../../../services/cloudchipr.api";
import { resourceExplorerSessionStorageSavingDataKey } from "../../../../components/pages/resource-explorer/utils/constants";
import { changesExistOnResourceExplorer } from "../../selectors/current-resource-explorer/checkers/changesExistOnResourceExplorer";

export const resourceExplorerUnMountThunk = createAsyncThunk(
  "resourceExplorer/resourceExplorerUnMount",
  async (_, { getState }) => {
    const state = getState() as RootState;
    const data = resourceExplorerDataSelector(state);
    const isDefault = resourceExplorerIsDefaultSelector(state);
    const filterTree = resourceExplorerFilterTreeSelector(state);
    const anyFilterChanged = changesExistOnResourceExplorer(state);

    if (!isDefault || !anyFilterChanged) {
      return;
    }

    const resourceExplorerSavingData: {
      filterTree?: FilterTreeNodeWithId;
      data?: ResourceExplorerData;
    } = {};

    if (data) {
      resourceExplorerSavingData.data = data;
    }

    if (filterTree) {
      resourceExplorerSavingData.filterTree = filterTree;
    }

    sessionStorage.setItem(
      resourceExplorerSessionStorageSavingDataKey,
      JSON.stringify(resourceExplorerSavingData),
    );
  },
);
