import { rightsizingRecommendationsDataSelector } from "../data/rightsizingRecommendationsDataSelector";
import { RootState } from "../../../../store";
import { RecommendationsTabType } from "../../../../../components/pages/recommendations/components/off-hours-recommendations/components/OffHoursRecommendationsContent";
import { rightsizingRecommendationsDismissedDataSelector } from "../data/rightsizingRecommendationsDismissedDataSelector";
import { rightsizingRecommendationsDismissedResourcesDataSelector } from "../dismissed-resources/rightsizingRecommendationsDismissedResourcesDataSelector";

export const rightsizingRecommendationsAvailableTypesSelector = (
  state: RootState,
  recommendationsType: RecommendationsTabType,
): string[] => {
  const activeRecommendations = rightsizingRecommendationsDataSelector(state);
  const dismissedRecommendations =
    rightsizingRecommendationsDismissedDataSelector(state);
  const dismissedResources =
    rightsizingRecommendationsDismissedResourcesDataSelector(state);

  const recommendations =
    recommendationsType === "active"
      ? activeRecommendations
      : dismissedRecommendations;

  const types =
    recommendations?.map(({ current_machine_type }) => current_machine_type) ??
    [];

  if (recommendationsType === "dismissed_resources") {
    const types = dismissedResources?.map(({ type }) => type) ?? [];
    return Array.from(new Set(types));
  }

  return Array.from(new Set(types));
};
