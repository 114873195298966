import { createAsyncThunk } from "@reduxjs/toolkit";
import { enqueueSnackbar } from "notistack";
import { RootState } from "../../../store";
import { cloudChiprApi } from "../../../../services/cloudchipr.api";
import { commitmentsGranularPayloadDataSelector } from "../../selectors/common/payload/commitmentsGranularPayloadDataSelector";

export const getReservationsForGranularThunk = createAsyncThunk(
  "commitments/getReservationsForGranular",
  async (_: void, { dispatch, getState }) => {
    const state = getState() as RootState;

    const { getUsersMeOrganisationsCurrentReservations } =
      cloudChiprApi.endpoints;

    const payload = commitmentsGranularPayloadDataSelector(state);

    try {
      await dispatch(
        getUsersMeOrganisationsCurrentReservations.initiate(payload),
      ).unwrap();
    } catch (e) {
      // @ts-expect-error todo: api fix
      const errMessage = e?.data?.message || "Something went wrong";
      enqueueSnackbar(errMessage, {
        variant: "snackbarAlert",
        AlertSnackBarProps: { severity: "error" },
      });

      return null;
    }
  },
);
