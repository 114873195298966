import { createAsyncThunk } from "@reduxjs/toolkit";
import { createDashboardVisibilityHierarchyItemByFolderIdThunk } from "./createDashboardVisibilityHierarchyItemByFolderIdThunk";
import { createDashboardVisibilityHierarchyItemThunk } from "./createDashboardVisibilityHierarchyItemThunk";
import { createDashboardVisibilityHierarchyFolderThunk } from "./createDashboardVisibilityHierarchyFolderThunk";
import { getDashboardsVisibilityHierarchyThunk } from "./getDashboardsVisibilityHierarchyThunk";
import {
  NavigationHierarchyItemType,
  NavigationItemsVisibilityType,
} from "../../../navigation/utils/types";

interface CreateDashboardHierarchyFolderOrItemThunkArg {
  type: NavigationHierarchyItemType;
  folderId?: string;
  name: string;
  visibility: NavigationItemsVisibilityType;
}

export const createDashboardVisibilityHierarchyFolderOrItemThunk =
  createAsyncThunk(
    "dashboard/createDashboardVisibilityHierarchyFolderOrItem",
    async (
      {
        folderId,
        name,
        type,
        visibility,
      }: CreateDashboardHierarchyFolderOrItemThunkArg,
      { dispatch },
    ) => {
      let response;
      if (type === "item") {
        if (folderId) {
          response = await dispatch(
            createDashboardVisibilityHierarchyItemByFolderIdThunk({
              name,
              folderId,
              visibility,
            }),
          ).unwrap();
        } else {
          response = await dispatch(
            createDashboardVisibilityHierarchyItemThunk({
              name,
              visibility,
            }),
          ).unwrap();
          dispatch(getDashboardsVisibilityHierarchyThunk());
        }
      } else {
        await dispatch(
          createDashboardVisibilityHierarchyFolderThunk({ name, visibility }),
        );
      }

      return response;
    },
  );
