import { FC, ReactNode } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
} from "@mui/material";
import { DialogTitleClosable } from "../../../../../../../common/dialog-utils/DialogTitleClosable";

export interface MessagesDialogProps {
  open: boolean;
  actions?: ReactNode;
  width?: DialogProps["maxWidth"];
  onClose(): void;
  children?: ReactNode;
}

export const MessagesDialog: FC<
  MessagesDialogProps & {
    name: string;
  }
> = ({ open, onClose, name, children, actions, width }) => {
  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth={width}>
      <DialogTitleClosable title={`Missing ${name}`} onClose={onClose} />

      <DialogContent dividers>{children}</DialogContent>

      {actions && <DialogActions sx={{ p: 2 }}>{actions}</DialogActions>}
    </Dialog>
  );
};
