import { FC } from "react";
import { TooltipProps } from "@mui/material";
import { DateTimeCell as CommonDateTimeCell } from "../../../../../../../../../../storybook/data-grid/cells/DateTimeCell";

interface DataGridDateTimeCellProps {
  data: string;
  title?: TooltipProps["title"];
}

export const DateTimeCell: FC<DataGridDateTimeCellProps> = ({
  data,
  title,
}) => {
  return <CommonDateTimeCell data={data} title={title} />;
};
