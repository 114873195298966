import { useState } from "react";
import { useTimeoutWhen } from "rooks";

interface MessageHook {
  status: string;
  error: string;
  cleanError(): void;
  cleanStatus(): void;
  updateError(errorMessage: string): void;
  successStatusUpdate(): void;
}

export const useMessageHook = (): MessageHook => {
  const [status, setStatus] = useState<"error" | "success" | "">("");
  const [error, setError] = useState("");

  const cleanError = () => {
    setError("");
  };

  const cleanStatus = () => {
    setStatus("");
  };

  const updateError = (errorMessage: string) => {
    setError(errorMessage || "Something went wrong.");
    setStatus("error");
  };

  const successStatusUpdate = () => {
    setStatus("success");
  };

  useTimeoutWhen(() => setStatus(""), 5000, !!status);

  return {
    status,
    error,
    cleanError,
    cleanStatus,
    updateError,
    successStatusUpdate,
  };
};
