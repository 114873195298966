import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { FC, useCallback } from "react";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../store/hooks";
import { reportsDataPropertyByKeySelector } from "../../../../../../../store/reports/selector/reportsDataPropertyByKeySelector";
import { ReportsData } from "../../../../../../../store/reports/utils/types/types";
import { setReportsData } from "../../../../../../../store/reports/reportsSlice";

interface ReportsDrawerSourceTypeSelectProps {
  disabled?: boolean;
}

export const ReportsDrawerSourceTypeSelect: FC<
  ReportsDrawerSourceTypeSelectProps
> = ({ disabled }) => {
  const dispatch = useAppDispatch();
  const source = useAppSelector(
    reportsDataPropertyByKeySelector("source_type"),
  );

  const handleChange = useCallback(
    (event: SelectChangeEvent) => {
      const value = event.target.value as ReportsData["source_type"];

      if (value === "dashboard") {
        dispatch(
          setReportsData({
            export_file_types: ["pdf"],
          }),
        );
      }

      dispatch(
        setReportsData({
          source_type: value,
          source_id: undefined,
        }),
      );
    },
    [dispatch],
  );

  return (
    <FormControl fullWidth size="small" disabled={disabled}>
      <InputLabel>Select Source</InputLabel>

      <Select
        size="small"
        value={source}
        label="Select Source"
        onChange={handleChange}
      >
        <MenuItem value="view">Resource Explorer View</MenuItem>
        <MenuItem value="dashboard">Dashboard</MenuItem>
      </Select>
    </FormControl>
  );
};
