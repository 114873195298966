import { ColumnSetupType } from "../../../../../../../../../../../storybook/data-grid/utils/types/types";
import { ReservationsCoverageDataWithId } from "../../../../../../../../common/utils/types";
import { CommitmentsProgressBar } from "../../../../../../../../common/components/CommitmentsProgressBar";
import { SavingsPlanCoverageGrouping } from "../../../../../../../../../../../services/cloudchipr.api";

export const getCoverageColumn = (
  grouping: SavingsPlanCoverageGrouping,
): ColumnSetupType<ReservationsCoverageDataWithId> => ({
  accessorKey: "coverage",
  header: grouping !== "commitment" ? "Covered by this RI" : "RI Covers", // resource type drawer grouping:reservation,account
  cell: ({ row }) => (
    <CommitmentsProgressBar
      overrideColor="info.light"
      value={row.original.coverage}
    />
  ),
});
