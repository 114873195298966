import { BillingCollectionStatus } from "../../../../../services/cloudchipr.api";

export const isBillingInProgressStatus = (
  status: BillingCollectionStatus | undefined,
): boolean => {
  return (
    status === "waiting_for_cloud_provider" ||
    status === "collecting" ||
    status === "analysing"
  );
};
