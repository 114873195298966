import { FC, useCallback } from "react";
import { Box, Stack, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import { LoadingButton } from "@mui/lab";
import { MissingPermissions } from "./MissingPermissions";
import { DrawerActionsProps } from "../DrawerActions";
import { getProviderName } from "../../../../../../../../utils/helpers/providers/getProviderName";
import { WarningCardHeader } from "../WarningCardHeader";
import { usePostUsersMeProvidersAwsAccountsByAccountIdCfnChangeSetsMutation } from "../../../../../../../../services/cloudchipr.api";
import { useAppAbility } from "../../../../../../../../services/permissions";

export const DrawerMissingPermissionCard: FC<DrawerActionsProps> = ({
  provider,
  accountId,
}) => {
  const { can } = useAppAbility();
  const canEditAccount = can("edit", "account");
  const { enqueueSnackbar } = useSnackbar();
  const [getChangeSetLink, { isLoading }] =
    usePostUsersMeProvidersAwsAccountsByAccountIdCfnChangeSetsMutation();

  const handleGoToAWSClick = useCallback(async () => {
    if (!accountId) {
      return;
    }

    try {
      const linkResult = await getChangeSetLink({
        accountId,
      }).unwrap();

      const link = linkResult?.link;
      window.open(link, "_blank");
    } catch (e) {
      // @ts-expect-error // todo: api should be fixed
      enqueueSnackbar(e?.data?.error ?? "Something went wrong", {
        variant: "snackbarAlert",
        AlertSnackBarProps: { severity: "error" },
      });
    }
  }, [enqueueSnackbar, accountId, getChangeSetLink]);

  return (
    <Box p={2}>
      <Stack bgcolor="grey.50" p={2} borderRadius={4} spacing={1}>
        <WarningCardHeader
          accountId={accountId}
          provider={provider}
          title="Missing Permissions"
        />

        <MissingPermissions accountId={accountId} />

        <Typography variant="body2">
          To add permissions please execute the CloudFormation template from AWS
          console.
        </Typography>

        <LoadingButton
          autoFocus
          size="small"
          variant="outlined"
          loading={isLoading}
          disabled={!canEditAccount}
          onClick={handleGoToAWSClick}
          sx={{ alignSelf: "end", width: "fit-content" }}
        >
          Go To {getProviderName(provider, { title: true, uppercase: true })}{" "}
          Console
        </LoadingButton>
      </Stack>
    </Box>
  );
};
