import { createDraftSafeSelector } from "@reduxjs/toolkit";
import { liveUsageMgmtSelectedResourcesByResourceTypeSelector } from "./liveUsageMgmtSelectedResourcesByResourceTypeSelector";

export const liveUsageMgmtSelectedResourcesAccountIdsSelector =
  createDraftSafeSelector(
    [liveUsageMgmtSelectedResourcesByResourceTypeSelector],
    (data): string[] | undefined => {
      if (!data) {
        return;
      }

      return data.map((item) => item.resource.account.id);
    },
  );
