import { FC, Fragment, useCallback, useRef } from "react";
import { MenuItem, Tooltip } from "@mui/material";
import {
  AccountId,
  ProviderAccessType,
  ProviderType,
} from "../../../../../../../services/cloudchipr.api";
import { accessTypeNames } from "../../../../../../common/chips/account-access-type-chip/utils/constants/accessTypeNames";
import { useDialog } from "../../../../../../../utils/hooks/useDialog.hook";
import { AccountSwitchModeDialogs } from "../../account-switch-mode-dialogs/AccountSwtichModeDialogs";

interface AccountSwitchModeItemProps {
  accessMode: ProviderAccessType;
  providerType: ProviderType;
  accountId: AccountId;
  managementAccountMessage: string | null;
  onClose(): void;
}

export const AccountSwitchModeItem: FC<AccountSwitchModeItemProps> = ({
  accessMode,
  providerType,
  accountId,
  managementAccountMessage,
  onClose,
}) => {
  const { open, openDialog, closeDialog } = useDialog();

  const oppositeAccessTypeValueWithRef = useRef<ProviderAccessType>(
    accessMode === "read_write" ? "read" : "read_write",
  );

  const oppositeAccessTypeValue =
    accessMode === "read_write" ? "read" : "read_write";

  const oppositeAccessTypeTitle =
    "Switch to " + accessTypeNames[oppositeAccessTypeValueWithRef.current].long;

  const closeDialogMiddleware = useCallback(() => {
    closeDialog();
    onClose();
  }, [onClose, closeDialog]);

  const menuItemJSX = (
    <MenuItem disabled={!!managementAccountMessage} onClick={openDialog}>
      {oppositeAccessTypeTitle}
    </MenuItem>
  );

  return (
    <Fragment>
      {managementAccountMessage ? (
        <Tooltip title={managementAccountMessage} arrow placement="right">
          <span>{menuItemJSX}</span>
        </Tooltip>
      ) : (
        menuItemJSX
      )}
      <AccountSwitchModeDialogs
        accessMode={oppositeAccessTypeValue}
        providerType={providerType}
        accountId={accountId}
        open={open}
        closeDialog={closeDialogMiddleware}
      />
    </Fragment>
  );
};
