import { allowedFiltersSelector } from "./allowedFiltersSelector";
import { RootState } from "../../store";
import {
  FilterItemTemplate,
  FilterKeyType,
  Filters,
  ResourceType,
} from "../../../services/cloudchipr.api";
import { isSmartTag } from "../../../components/pages/common/filters/utils/helpers/isSmartTag";

export const allowedFiltersHierarchyByResourceTypeOptionsSelector = (
  state: RootState,
  resourceType: ResourceType,
  excludedFilters?: FilterKeyType[],
): { label: string; filters: FilterItemTemplate[]; type: ResourceType }[] => {
  const allowedFilters = allowedFiltersSelector(state);

  const allowedFilter = allowedFilters.find((f) => f.type === resourceType);

  if (!allowedFilter) {
    return [];
  }

  return makeFilterTypeOptionsFromFilters(allowedFilter, excludedFilters);
};

const makeFilterTypeOptionsFromFilters = (
  filter: Filters[0],
  excludedFilters?: FilterKeyType[],
): { label: string; filters: FilterItemTemplate[]; type: ResourceType }[] => {
  const smartTagKeys: FilterItemTemplate[] = [];

  const filteredOptions = filter.filters.filter((option) => {
    if (excludedFilters?.includes(option.key)) {
      return false;
    }

    if (!isSmartTag(option.key)) {
      return true;
    }

    smartTagKeys.push(option);
    return false;
  });

  filteredOptions.push(...smartTagKeys);

  const options = [
    {
      type: filter.type,
      label: filter.label,
      filters: filteredOptions,
    },
  ];

  if (filter.child_filters) {
    filter.child_filters?.forEach((f) => {
      options.push(...makeFilterTypeOptionsFromFilters(f, excludedFilters));
    });
  }

  return options;
};
