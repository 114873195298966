import { FC, useCallback } from "react";
import { Stack, Typography } from "@mui/material";
import { PictureAsPdfOutlined, TextSnippetOutlined } from "@mui/icons-material";
import { ReportsDrawerAttachmentsCheckbox } from "./ReportsDrawerAttachmentsCheckbox";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../store/hooks";
import { reportsDataPropertyByKeySelector } from "../../../../../../../store/reports/selector/reportsDataPropertyByKeySelector";
import { setReportsScheduleAttachmentsThunk } from "../../../../../../../store/reports/thunks/setReportsScheduleAttachmentsThunk";

export const ReportsDrawerAttachments: FC = () => {
  const dispatch = useAppDispatch();
  const sourceType = useAppSelector(
    reportsDataPropertyByKeySelector("source_type"),
  );
  const exportTypes = useAppSelector(
    reportsDataPropertyByKeySelector("export_file_types"),
  );
  const pdfCheck = !!exportTypes?.includes("pdf");
  const csvCheck = !!exportTypes?.includes("csv");

  const getExportTypeChangeHandler = useCallback(
    (type: "pdf" | "csv") => {
      return (checked: boolean) => {
        dispatch(setReportsScheduleAttachmentsThunk({ checked, type }));
      };
    },
    [dispatch],
  );

  return (
    <Stack spacing={1}>
      <Typography variant="body1" fontWeight="medium">
        Attach Files
      </Typography>
      <Stack direction="row" spacing={1}>
        <ReportsDrawerAttachmentsCheckbox
          title="PDF"
          icon={PictureAsPdfOutlined}
          checked={pdfCheck}
          disabled={exportTypes?.length === 1 && pdfCheck}
          onChange={getExportTypeChangeHandler("pdf")}
        />

        {sourceType !== "dashboard" && (
          <ReportsDrawerAttachmentsCheckbox
            title="CSV"
            icon={TextSnippetOutlined}
            checked={csvCheck}
            disabled={exportTypes?.length === 1 && csvCheck}
            onChange={getExportTypeChangeHandler("csv")}
          />
        )}
      </Stack>
    </Stack>
  );
};
