import { FC } from "react";
import numeral from "numeral";
import { CostAnomalyPreviewTableHeaderCell } from "./CostAnomalyPreviewTableHeaderCell";
import { useAppSelector } from "../../../../../../../../../../../store/hooks";
import { costAnomalyPreviewDataGridDataSumsSelector } from "../../../../../../../../../../../store/alerts/selectors/cost-anomaly-alerts/costAnomalyPreviewDataGridDataSumsSelector";

export const CostAnomalyPreviewTrendHeaderCell: FC = () => {
  const dataSums = useAppSelector(costAnomalyPreviewDataGridDataSumsSelector);
  const formatedValue = numeral(dataSums?.trendSum ?? 0).format("0.00");

  return (
    <CostAnomalyPreviewTableHeaderCell
      title="Trend"
      description={
        (dataSums?.trendSum ?? 0) > 0
          ? `+${formatedValue}%`
          : `${formatedValue}%`
      }
      tooltipTitle="Total Cost compared with Previous Period Cost"
    />
  );
};
