export const statisticsFilterTypes: Set<string> = new Set([
  "cpu",
  "network_in",
  "network_out",
  "database_connections",
  "connections",
  "total_connections",
  "requests",
  "read_usage",
  "write_usage",
  "memory",
  "processed_data",
  "active_connections",
  "memory_available",
]);
