import { providerDisabledTooltipTitleCombiner } from "./providerDisabledTooltipTitleCombiner";
import { RootState } from "../../../store";
import { azureNonDeletedAndNotBillingAccountsSelector } from "../../../accounts/selectors/azure/azureNonDeletedAndNotBillingAccountsSelector";

export const azureDisabledTooltipTitleSelector = (
  state: RootState,
  forOffHours: boolean,
) => {
  const accounts = azureNonDeletedAndNotBillingAccountsSelector(state);

  return providerDisabledTooltipTitleCombiner(forOffHours, "azure", accounts);
};
