import { FC, Fragment } from "react";
import { Drawer, IconButton, Tooltip } from "@mui/material";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import { TaskPropertiesSettingsDrawerHeader } from "./components/TaskPropertiesSettingsDrawerHeader";
import { TaskPropertiesSettingsDrawerBody } from "./components/TaskPropertiesSettingsDrawerBody";
import { useDialog } from "../../../../../../../utils/hooks/useDialog.hook";

export const TaskManagementPropertiesSettings: FC = () => {
  const { open, openDialog, closeDialog } = useDialog();

  return (
    <Fragment>
      <Tooltip arrow title="Properties Settings" placement="top">
        <span>
          <IconButton onClick={openDialog} size="small">
            <SettingsOutlinedIcon fontSize="small" />
          </IconButton>
        </span>
      </Tooltip>

      <Drawer
        anchor="right"
        open={open}
        PaperProps={paperProps}
        slotProps={slotProps}
      >
        <TaskPropertiesSettingsDrawerHeader onClose={closeDialog} />

        <TaskPropertiesSettingsDrawerBody />
      </Drawer>
    </Fragment>
  );
};

const paperProps = {
  sx: { width: "30dvw", minWidth: 500 },
};

const slotProps = {
  backdrop: { invisible: true },
};
