import { FC } from "react";
import { Stack, Typography } from "@mui/material";
import { CommandSnippet } from "../../../../../../../../../../common/connectors/components/CommandSnippet";
import { useAppSelector } from "../../../../../../../../../../../../store/hooks";
import { resourceExplorerGcpBillingExportDataErrorSelector } from "../../../../../../../../../../../../store/resource-explorer/selectors/billing/gcp-billing-data/resourceExplorerGcpBillingExportDataErrorSelector";

interface ResourceExplorerBillingExportDialogAccessCLITabProps {
  projectId: string;
}

export const ResourceExplorerBillingExportDialogAccessCLITab: FC<
  ResourceExplorerBillingExportDialogAccessCLITabProps
> = ({ projectId }) => {
  const error = useAppSelector(
    resourceExplorerGcpBillingExportDataErrorSelector,
  );

  const serviceAccount = error?.data?.service_account;

  const copyCommand = `gcloud projects add-iam-policy-binding ${projectId} --member serviceAccount:${serviceAccount} --role "roles/bigquery.dataViewer" \ngcloud projects add-iam-policy-binding ${projectId} --member serviceAccount:${serviceAccount} --role "roles/bigquery.jobUser"`;

  return (
    <Stack gap={2} mt={3}>
      <Stack gap={1}>
        <Typography variant="body1" fontWeight={400}>
          Here is the Cloudchipr service account that we’ll use to get access to
          your GCP project
        </Typography>
        <CommandSnippet code={serviceAccount} parentBoxSx={{ my: 0 }} />
      </Stack>
      <Stack gap={1}>
        <Typography variant="body1" fontWeight={400}>
          Please add the following IAM Policy binding
        </Typography>
        <CommandSnippet code={copyCommand}>
          gcloud projects add-iam-policy-binding{" "}
          <Typography
            fontFamily="monospace"
            color="warning.light"
            component="span"
          >
            {projectId}
          </Typography>{" "}
          --member serviceAccount:
          <Typography
            fontFamily="monospace"
            color="warning.light"
            component="span"
          >
            {serviceAccount}
          </Typography>{" "}
          --role{" "}
          <Typography
            component="span"
            fontFamily="monospace"
            color="success.light"
          >
            "roles/bigquery.dataViewer"
          </Typography>{" "}
          {<br />}
          gcloud projects add-iam-policy-binding{" "}
          <Typography
            fontFamily="monospace"
            color="warning.light"
            component="span"
          >
            {projectId}
          </Typography>{" "}
          --member serviceAccount:
          <Typography
            fontFamily="monospace"
            color="warning.light"
            component="span"
          >
            {serviceAccount}
          </Typography>{" "}
          --role{" "}
          <Typography
            component="span"
            fontFamily="monospace"
            color="success.light"
          >
            "roles/bigquery.jobUser"
          </Typography>
        </CommandSnippet>
      </Stack>
    </Stack>
  );
};
