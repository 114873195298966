import { TagFilter } from "../types";

export const validateTagsFilters = (filters: TagFilter[]): TagFilter[] => {
  return filters?.filter((item) => {
    return (
      !!item.key &&
      (["exists", "does_not_exist"].includes(item.operator) ||
        !!item?.values?.length)
    );
  });
};
