import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Account } from "../../services/cloudchipr.api";

export const accountsDefaultSort: AccountSortByFields = "total_costs";

export type AccountsViewType = "grid" | "list" | "bar" | null;

export type AccountSortByFields =
  | null
  | "custom"
  | "ems"
  | "total_costs"
  | "provider_account_id"
  | "provider_account_name";

export interface AccountsInterface {
  accounts: Account[] | null;
  accountSortBy: AccountSortByFields;
  filterValue: string;
  accountsView: AccountsViewType;
}

const initialState: AccountsInterface = {
  accounts: null,
  accountSortBy: null,
  filterValue: "",
  accountsView: null,
};

export const accountsSlice = createSlice({
  name: "auth",
  initialState: initialState,
  reducers: {
    setAccounts: (state, action: PayloadAction<Account[]>) => {
      state.accounts = action.payload;
    },
    setAccountSortBy: (state, action: PayloadAction<AccountSortByFields>) => {
      state.accountSortBy = action.payload;
    },
    setFilterValue: (state, action: PayloadAction<string>) => {
      state.filterValue = action.payload;
    },
    setAccountsView: (state, action: PayloadAction<AccountsViewType>) => {
      state.accountsView = action.payload;
    },
  },
});

export const {
  setAccounts,
  setAccountSortBy,
  setFilterValue,
  setAccountsView,
} = accountsSlice.actions;

export default accountsSlice.reducer;
