import { FC, useCallback } from "react";
import { SwitchWithTypography } from "../../../../../../../../../../SwitchWithTypography";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../../../../store/hooks";
import { costBreakdownSetupVisualizationPropertyByKeySelector } from "../../../../../../../../../../../store/dashboards/selectors/setups/cost-breakdown/costBreakdownSetupVisualizationPropertyByKeySelector";
import { costBreakdownViewVisualizationChangeThunk } from "../../../../../../../../../../../store/dashboards/thunks/widgets/cost-breakdown/setup-change/costBreakdownViewVisualizationChangeThunk";

export const CostBreakdownWidgetVisualizationPreviousPeriodColumn: FC = () => {
  const dispatch = useAppDispatch();
  const previousPeriodColumnChecked = useAppSelector(
    costBreakdownSetupVisualizationPropertyByKeySelector(
      "previous_period_cost",
    ),
  );

  const handlePreviousPeriodCheckedChange = useCallback(
    (checked: boolean) => {
      dispatch(
        costBreakdownViewVisualizationChangeThunk({
          previous_period_cost: checked,
        }),
      );
    },
    [dispatch],
  );

  return (
    <SwitchWithTypography
      checked={previousPeriodColumnChecked}
      onChange={handlePreviousPeriodCheckedChange}
      title="Previous Period Cost"
    />
  );
};
