import { costAndUsageSumWidgetDataSelector } from "./costAndUsageSumWidgetDataSelector";
import { costAndUsageSumWidgetFiltersByIdSelector } from "../costAndUsageSumWidgetFiltersByIdSelector";
import { RootState } from "../../../../../store";
import { CostAndUsageDataGridType } from "../../../../../../components/pages/dashboard/components/adding-widget/widget-create/widget-setups/cost-and-usage/content/data-grid/utils/types";
import { generateCostAndUsageSumDataGridData } from "../utils/helpers/generateCostAndUsageSumDataGridData";

export const costAndUSageSumWidgetViewDataSelector = (
  state: RootState,
  widgetId: string,
): CostAndUsageDataGridType[] | undefined => {
  const data = costAndUsageSumWidgetDataSelector(state, widgetId);

  if (!data) {
    return [];
  }

  const gridData = generateCostAndUsageSumDataGridData(data);

  if (!gridData) {
    return;
  }

  const filters = costAndUsageSumWidgetFiltersByIdSelector(state, widgetId);

  if (!filters?.length) {
    return gridData;
  }

  return gridData.filter((item) => {
    return filters?.includes(item.costAndUsageSumWidgetFilterType);
  });
};
