import { reservationSelectedCoverageDataSelector } from "./reservationSelectedCoverageDataSelector";
import { RootState } from "../../../../../store";

export const reservationSelectedCoverageDataAccountNameAndIdSelector = (
  state: RootState,
): string => {
  const data = reservationSelectedCoverageDataSelector(state);

  return `${data?.account?.name} (${data?.account?.provider_account_id})`;
};
