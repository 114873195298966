import { ec2Columns } from "./resources/aws/ec2Columns";
import { ebsColumns } from "./resources/aws/ebsColumns";
import { elbColumns } from "./resources/aws/elbColumns";
import { eipColumns } from "./resources/aws/eipColumns";
import { rdsColumns } from "./resources/aws/rdsColumns";
import { elcColumns } from "./resources/aws/elcColumns";
import { vmColumns } from "./resources/gcp/vmColumns";
import { diskColumns } from "./resources/gcp/diskColumns";
import { sqlColumns } from "./resources/gcp/sqlColumns";
import { ipColumns } from "./resources/gcp/ipColumns";
import { lbColumns } from "./resources/gcp/lbColumns";
import { azVmColumns } from "./resources/azure/azVmColumns";
import { azDiskColumns } from "./resources/azure/azDiskColumns";
import { azIpColumns } from "./resources/azure/azIpColumns";
import { azLbColumns } from "./resources/azure/azLbColumns";
import { azSqlColumns } from "./resources/azure/azSqlColumns";
import { s3Columns } from "./resources/aws/s3Columns";
import { asgColumns } from "./resources/aws/asgColumns";
import { asgEc2Columns } from "./resources/aws/asgEc2Columns";
import { migColumns } from "./resources/gcp/migColumns";
import { migVmColumns } from "./resources/gcp/migVmColumns";
import { eksColumns } from "./resources/aws/eksColumns";
import { nodeGroupColumns } from "./resources/aws/nodeGroupColumns";
import { eksEc2Columns } from "./resources/aws/eksEc2Columns";
import { azVmssColumns } from "./resources/azure/azVmssColumns";
import { azVmssVmColumns } from "./resources/azure/azVmssVmColumns";
import { gkeColumns } from "./resources/gcp/gkeColumns";
import { gkeVmColumns } from "./resources/gcp/gkeVmColumns";
import { nodePoolColumns } from "./resources/gcp/nodePoolColumns";
import { azAksColumns } from "./resources/azure/azAksColumns";
import { azNodePoolColumns } from "./resources/azure/azNodePoolsColumns";
import { azAksVmColumns } from "./resources/azure/azAksVmColumns";
import { rdsSnapshotColumns } from "./resources/aws/rdsSnapshotColumns";
import { ebsSnapshotColumns } from "./resources/aws/ebsSnapshotColumns";
import { rdsSnapshotSourceColumns } from "./resources/aws/rdsSnapshotSourceColumns";
import { dynamoDbTableColumns } from "./resources/aws/dynamoDbTableColumns";
import { dynamoDbBackupsTableColumns } from "./resources/aws/dynamoDbBackupsTableColumns";
import { daxClusterColumns } from "./resources/aws/daxClusterColumns";
import { natGatewayColumns } from "./resources/aws/natGatewayColumns";
import { ecsColumns } from "./resources/aws/ecsColumns";
import { ecsWorkloadsColumns } from "./resources/aws/ecsWorkloadsColumns";
import { ColumnSetupType } from "../../../../../../../../../../../../storybook/data-grid/utils/types/types";
import { ResourceType } from "../../../../../../../../../../../../services/cloudchipr.api";

export const columnsMapV2 = new Map<ResourceType, ColumnSetupType<any>[]>([
  // AWS
  ["eks", eksColumns],
  ["node_group", nodeGroupColumns],
  ["eks_ec2", eksEc2Columns],
  ["asg_ec2", asgEc2Columns],
  ["asg", asgColumns],
  ["ec2", ec2Columns],
  ["s3", s3Columns],
  ["ebs", ebsColumns],
  ["ebs_snapshot", ebsSnapshotColumns],
  ["elb", elbColumns],
  ["eip", eipColumns],
  ["rds", rdsColumns],
  ["rds_snapshot", rdsSnapshotColumns],
  ["rds_snapshot_source", rdsSnapshotSourceColumns],
  ["elc", elcColumns],
  ["dynamo_db_table", dynamoDbTableColumns],
  ["dynamo_db_backup", dynamoDbBackupsTableColumns],
  ["dax_cluster", daxClusterColumns],
  ["nat_gateway", natGatewayColumns],
  ["ecs", ecsColumns],
  ["ecs_workload", ecsWorkloadsColumns],

  // GCP
  ["node_pool", nodePoolColumns],
  ["vm", vmColumns],
  ["mig", migColumns],
  ["mig_vm", migVmColumns],
  ["disk", diskColumns],
  ["sql", sqlColumns],
  ["ip", ipColumns],
  ["lb", lbColumns],
  ["gke", gkeColumns],
  ["gke_vm", gkeVmColumns],

  // Azure
  ["az_vm", azVmColumns],
  ["az_vmss", azVmssColumns],
  ["az_vmss_vm", azVmssVmColumns],
  ["az_disk", azDiskColumns],
  ["az_ip", azIpColumns],
  ["az_lb", azLbColumns],
  ["az_sql", azSqlColumns],
  ["az_aks", azAksColumns],
  ["az_node_pool", azNodePoolColumns],
  ["az_aks_vm", azAksVmColumns],
]);
