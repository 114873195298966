import { resourceExplorerDataSelector } from "./resourceExplorerDataSelector";
import { RootState } from "../../../../store";
import { resourceExplorerChartBaseSelector } from "../../resourceExplorerChartBaseSelector";

export const resourceExplorerShowBudgetLineSelector = (state: RootState) => {
  const data = resourceExplorerDataSelector(state);
  const chartBase = resourceExplorerChartBaseSelector(state);

  return data?.show_budget_line && chartBase !== "percent";
};
