import { currentEditingOffHoursSelector } from "./currentEditingOffHoursSelector";
import { automationSliceSelector } from "../../../common/automationSliceSelector";
import { OffHoursSchedule } from "../../../../../../services/cloudchipr.api";
import { changesExistOnAutomation } from "../../../../utils/helpers/changesExistOnAutomation";
import { RootState } from "../../../../../store";

export const changesExistOnCurrentEditOffHoursSelector = (state: RootState) => {
  const original: OffHoursSchedule | undefined =
    currentEditingOffHoursSelector(state)?.data;
  const slice = automationSliceSelector(state);
  const automationData = slice.data;
  const offHoursData = slice.offHoursSpecific;

  if (!original || !offHoursData) {
    return false;
  }

  const automationHasChanges = changesExistOnAutomation(
    automationData,
    original,
  );

  return automationHasChanges || original.action !== offHoursData.action;
};
