import { FC, useCallback } from "react";
import { Grid, SelectChangeEvent } from "@mui/material";
import { FormikErrors, FormikHelpers } from "formik";
import { v4 as uuid } from "uuid";
import { FilterDialogListItemRemoveButton } from "./FilterDialogListItemRemoveButton";
import { FilterDialogListItemOperator } from "./FilterDialogListItemOperator";
import {
  FilterItemTemplate,
  FilterItemWithType,
  FilterKeyType,
  ResourceFilters,
  ResourceType,
} from "../../../../../../services/cloudchipr.api";
import { filterKeyAndTypePrefix, FilterTypeSelect } from "../FilterTypeSelect";
import { FilterBodyByType } from "../FilterBodyByType";
import {
  ModalFilterItem,
  FiltersInitialValues,
  FilterOperators,
} from "../utils/types/types";
import { useAppSelector } from "../../../../../../store/hooks";
import { allowedFiltersByResourceTypeWithChildrenFiltersSelector } from "../../../../../../store/filters/selectors/allowedFiltersByResourceTypeWithChildrenFiltersSelector";
import { getActionableResourceTypeByParentResourceType } from "../../../../../../utils/helpers/resources/getActionableResourceTypeByParentResourceType";

interface FilterDialogListItemProps {
  index: number;
  filterIndex: number;
  indexOfOperator: number;
  defaultFilters: ResourceFilters;
  accountIds?: string[];
  itemOperator: FilterOperators[0];
  resourceType: ResourceType;
  currentFilter: FilterItemWithType;
  excludedFilters?: FilterKeyType[];
  errors: FormikErrors<FiltersInitialValues>;
  setFieldValue: FormikHelpers<ModalFilterItem>["setFieldValue"];
  setValues: FormikHelpers<FiltersInitialValues>["setValues"];
}

export const FilterDialogListItem: FC<FilterDialogListItemProps> = ({
  errors,
  index,
  filterIndex,
  indexOfOperator,
  currentFilter,
  resourceType,
  setFieldValue,
  accountIds,
  excludedFilters,
  setValues,
  itemOperator,
  defaultFilters,
}) => {
  const allowedAllFilters = useAppSelector((state) =>
    allowedFiltersByResourceTypeWithChildrenFiltersSelector(
      state,
      resourceType,
    ),
  );
  const allowedFilters = allowedAllFilters?.find(
    (item) => item.resourceType === currentFilter.type,
  )?.data as FilterItemTemplate[];

  const filterTemplate = allowedFilters?.find(
    (f) => f.key === currentFilter.filter.key,
  );

  const filterTypeChangeHandler = useCallback(
    (event: SelectChangeEvent) => {
      const [key, type] = event.target.value.split(filterKeyAndTypePrefix) as [
        FilterKeyType,
        ResourceType,
      ];

      const defaultFilter = defaultFilters?.filter_items?.find(
        ({ filter }) => filter.key === key,
      )?.filter;

      if (defaultFilter) {
        setFieldValue(`filter_items[${filterIndex}]`, {
          type,
          id: uuid(),
          filter: defaultFilter,
        });
        return;
      }

      const allowedFilters = allowedAllFilters?.find(
        ({ resourceType }) => resourceType === type,
      )?.data as FilterItemTemplate[];

      const allowedFilter = allowedFilters?.find((f) => f.key === key);

      if (allowedFilter) {
        setFieldValue(`filter_items[${filterIndex}]`, {
          type,
          id: uuid(),
          filter: {
            key: allowedFilter.key,
            operator: allowedFilter.operators[0].key,
          },
        });
      }
    },
    [
      filterIndex,
      setFieldValue,
      allowedAllFilters,
      defaultFilters?.filter_items,
    ],
  );

  const changeHandler = useCallback(
    (event: any) => {
      const name = `filter_items[${filterIndex}].filter.${event.target.name}`;

      setFieldValue(name, event.target.value);
    },
    [setFieldValue, filterIndex],
  );

  const setValueByName = useCallback(
    (name: string, value: ModalFilterItem["filter"]["value"]) => {
      setFieldValue(`filter_items[${filterIndex}].filter.${name}`, value);
    },
    [setFieldValue, filterIndex],
  );

  if (!allowedFilters || !filterTemplate) {
    return null;
  }

  return (
    <Grid container wrap="nowrap" gap={2} flexShrink={0} flexGrow={0}>
      <Grid item md={1}>
        <FilterDialogListItemOperator
          index={index}
          setValues={setValues}
          operator={itemOperator}
          indexOfOperator={indexOfOperator}
        />
      </Grid>

      <Grid
        item
        container
        wrap="nowrap"
        md={11}
        flexShrink={0}
        flexGrow={0}
        spacing={2}
        alignItems="center"
      >
        <Grid item md={3} flexShrink={0} flexGrow={0}>
          <FilterTypeSelect
            resourceType={resourceType}
            currentFilter={currentFilter}
            onChange={filterTypeChangeHandler}
            excludedFilters={excludedFilters}
          />
        </Grid>
        <Grid container item md={8} spacing={2} wrap="nowrap">
          <FilterBodyByType
            accountIds={accountIds}
            error={
              (
                errors?.filter_items?.[
                  filterIndex
                ] as FormikErrors<ModalFilterItem>
              )?.filter
            }
            onChange={changeHandler}
            filters={allowedFilters}
            resourceType={getActionableResourceTypeByParentResourceType(
              resourceType,
            )}
            currentFilter={currentFilter}
            // @ts-expect-error | types looks ok, can't understand what's the reason
            setFieldValue={setValueByName}
            filterTemplate={filterTemplate}
          />
        </Grid>

        <Grid
          item
          md={1}
          flexShrink={0}
          flexGrow={0}
          justifyContent="end"
          display="flex"
        >
          <FilterDialogListItemRemoveButton
            index={filterIndex}
            setValues={setValues}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};
