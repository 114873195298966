import { reportsDataPropertyByKeySelector } from "./reportsDataPropertyByKeySelector";
import { RootState } from "../../store";
import { MonthlyByDaySchedule } from "../../../services/cloudchipr.api";

export const reportsScheduleDayOfMonthSelector = (state: RootState) => {
  const schedule = reportsDataPropertyByKeySelector("schedule")(
    state,
  ) as MonthlyByDaySchedule;

  return schedule.day_of_month;
};
