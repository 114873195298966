import { createDraftSafeSelector } from "@reduxjs/toolkit";
import { selectedIdsSelector } from "./selectedIdsSelector";

export const selectedIdsInGridFormatSelector = createDraftSafeSelector(
  [selectedIdsSelector],
  (selectedIds) => {
    return selectedIds.reduce(
      (acc, id) => {
        acc[id] = true;

        return acc;
      },
      {} as Record<string, boolean>,
    );
  },
);
