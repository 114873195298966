import { FC, ReactNode } from "react";
import PopupState, {
  bindPopover,
  bindHover,
  bindFocus,
} from "material-ui-popup-state";
import HoverPopoverFromLyb from "material-ui-popup-state/HoverPopover";
import { PopoverProps } from "@mui/material";

interface HoverPopoverProps {
  id?: string;
  hoverElement: ReactNode;
  transformOrigin?: PopoverProps["transformOrigin"];
  anchorOrigin?: PopoverProps["anchorOrigin"];
  children?: ReactNode;
}

export const HoverPopover: FC<HoverPopoverProps> = ({
  id,
  children,
  transformOrigin,
  anchorOrigin,
  hoverElement,
}) => {
  return (
    <PopupState variant="popover" popupId={`hover-popup-popover-${id}`}>
      {(popupState) => (
        <div>
          <div {...bindHover(popupState)} {...bindFocus(popupState)}>
            {hoverElement}

            {popupState.isOpen && (
              <div {...bindHover(popupState)} {...bindFocus(popupState)} />
            )}
          </div>

          <HoverPopoverFromLyb
            {...bindPopover(popupState)}
            transformOrigin={{
              horizontal: "left",
              vertical: "top",
              ...transformOrigin,
            }}
            anchorOrigin={{
              horizontal: "left",
              vertical: "bottom",
              ...anchorOrigin,
            }}
          >
            {children}
          </HoverPopoverFromLyb>
        </div>
      )}
    </PopupState>
  );
};
