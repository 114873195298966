import { DateRange, extendMoment } from "moment-range";
import * as Moment from "moment/moment";
import { findDailyRecommendedRanges } from "./findDailyRecommendedRanges";
import { findWeeklyRecommendedRanges } from "./findWeeklyRecommendedRanges";
import {
  ChartDataGenerationArgsType,
  OffHoursRecommendationsChartData,
} from "../types/types";

const moment = extendMoment(Moment);

export const generateChartDataFromMetrics = ({
  metrics,
  fromHour,
  fromDay,
  toHour,
  toDay,
}: ChartDataGenerationArgsType) => {
  const cpuMetrics =
    metrics.find((metric) => metric.type === "cpu")?.metrics ?? [];

  let max = cpuMetrics.reduce((max, val) => Math.max(val.maximum || 0, max), 0);
  max = Math.round(max * 0.1) / 0.1;
  max = Math.max(100, max);

  const firstTimestamp = cpuMetrics?.[0]?.timestamp;
  const lastTimestamp = cpuMetrics?.[cpuMetrics.length - 1]?.timestamp;

  if (!firstTimestamp || !lastTimestamp) {
    return [];
  }

  let ranges: DateRange[] = [];

  if (fromDay && toDay) {
    ranges = findWeeklyRecommendedRanges({
      firstTimestamp,
      lastTimestamp,
      fromHour,
      toHour,
      fromDay,
      toDay,
    });
  } else {
    ranges = findDailyRecommendedRanges({
      firstTimestamp,
      lastTimestamp,
      fromHour,
      toHour,
    });
  }

  const chartData: OffHoursRecommendationsChartData[] = [];

  metrics.forEach((metricsData) => {
    metricsData.metrics.forEach((metric) => {
      const existingData = chartData.find(
        ({ timestamp }) => timestamp === metric.timestamp,
      );
      const isRecommended = !!ranges.find((range) =>
        range.contains(moment.utc(metric.timestamp)),
      );

      if (existingData) {
        existingData[metricsData.type] = metric.maximum;
      } else {
        chartData.push({
          timestamp: metric.timestamp,
          [metricsData.type]: metric.maximum,
          recommendedHours: isRecommended ? max : 0,
        });
      }
    });
  });

  return chartData;
};
