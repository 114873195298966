import { grey } from "@mui/material/colors";
import { agGridOverwrittenIcons } from "../../../../../automations/workflows/data-grid/utils/constans/grid-icons";

const columnSortIconStyles = {
  "& .ag-sort-indicator-icon": { visibility: "hidden" },
  "& .ag-header-active:hover .ag-sort-indicator-icon": {
    visibility: "visible",
  },

  "& .ag-header-cell-sorted-desc .ag-sort-indicator-icon": {
    visibility: "visible !important",
  },
  "& .ag-header-cell-sorted-asc .ag-sort-indicator-icon": {
    visibility: "visible !important",
  },

  '& .ag-header-cell[aria-sort="ascending"] .ag-sort-indicator-icon': {
    visibility: "visible !important",
  },
  '& .ag-header-cell[aria-sort="descending"] .ag-sort-indicator-icon': {
    visibility: "visible !important",
  },
};

const rowHoverStyles = {
  "--ag-active-color": grey[100],
  "& .ag-row-hover": { backgroundColor: grey[100] },
};

const resizeHandle = {
  "& .ag-header-cell-resize": { width: 20, visibility: "hidden" },
  "& .ag-header-cell:hover .ag-header-cell-resize": { visibility: "visible" },
  "--ag-header-column-resize-handle-height": "80%",
};

export const reportsTableWrapperStyles = {
  height: "100%",
  width: "100%",
  "& .ag-cell-wrapper": { height: "100%" },
  "& .ag-react-container": { height: "90%" },
  "& .ag-root-wrapper": { borderRadius: 0, border: "none" },
  "--ag-header-height": "44px",
  "--ag-header-background-color": grey[100],

  ...columnSortIconStyles,
  ...rowHoverStyles,
  ...resizeHandle,
};

export const reportsTableOptions = {
  icons: agGridOverwrittenIcons,
  headerHeight: 52,
};
