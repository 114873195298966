import { RootState } from "../../../store";
import { rightsizingRecommendationsDataSelector } from "../rightsizing/data/rightsizingRecommendationsDataSelector";
import { offHoursRecommendationsSelector } from "../off-hours/offHoursRecommendationsSelector";

export const selectedActiveRecommendationsResourceNameSelector = (
  state: RootState,
  recommendationId: string,
  recommendationType: "off-hours" | "rightsizing",
): string | undefined => {
  let names: string[];

  if (recommendationType === "off-hours") {
    const offHoursRecommendations = offHoursRecommendationsSelector(state);
    names =
      offHoursRecommendations
        ?.filter((r) => recommendationId === r.id)
        ?.map((r) => r.resource_name ?? "") ?? [];
  } else {
    const rightsizingRecommendationsData =
      rightsizingRecommendationsDataSelector(state);

    names =
      rightsizingRecommendationsData
        ?.filter((r) => recommendationId === r.id)
        ?.map((r) => r.resource_name ?? "") ?? [];
  }

  return names?.join(", ");
};
