import { FC } from "react";
import {
  Alert,
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { getResourceTypeName } from "../../../../../../../../utils/helpers/resources/getResourceTypeName";
import { money } from "../../../../../../../../utils/numeral/money";
import {
  ProviderType,
  ResourceType,
} from "../../../../../../../../services/cloudchipr.api";

interface RightsizingSubRowInfoSectionProps {
  resourceType: string | null;
  spending: number;
  region: string;
  recommendedCosts: number;
  provider?: ProviderType;
}

export const RightsizingSubRowInfoSection: FC<
  RightsizingSubRowInfoSectionProps
> = ({ resourceType, provider, spending, region, recommendedCosts }) => {
  const resourceTypeName = getResourceTypeName(resourceType as ResourceType, {
    singular: true,
  });

  return (
    <Box pl={5}>
      {provider !== "azure" && (
        <Alert severity="warning">
          Only CPU utilization is considered for this recommendation. Check
          memory utilization and other metrics relevant to the application
          running on this resource before taking any action.
        </Alert>
      )}
      <Table sx={{ border: 1, borderColor: "grey.300", mt: 2 }}>
        <TableHead>
          <TableRow>
            <TableCell>Resource Type</TableCell>
            <TableCell>Current Costs</TableCell>
            <TableCell>Recommended Costs</TableCell>
            <TableCell>Region</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>{resourceTypeName}</TableCell>
            <TableCell>{money(spending)}</TableCell>
            <TableCell>{money(recommendedCosts)}</TableCell>
            <TableCell>{region}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Box>
  );
};
