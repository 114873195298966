import { FC, ReactNode } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  Theme,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { SxProps } from "@mui/system";

type SchedulesPreviewTableDataType = {
  id?: string;
  title: string | ReactNode;
  label: string | ReactNode;
  labelSx?: SxProps<Theme>;
}[];

interface SchedulePreviewTableProps {
  data: SchedulesPreviewTableDataType;
  children?: ReactNode;
}

export const SchedulePreviewTable: FC<SchedulePreviewTableProps> = ({
  data,
  children,
}) => {
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <Table size="small">
      <TableBody>
        {children}
        {data.map(({ id, title, label, labelSx }) => (
          <TableRow key={typeof title === "string" ? title : id}>
            <TableCell
              component="th"
              sx={{
                width: isLargeScreen ? 150 : "unset",
                p: 2,
                fontWeight: "medium",
                verticalAlign: "top",
              }}
            >
              {title}
            </TableCell>

            <TableCell sx={{ p: 2, ...labelSx }}>{label}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};
