import { FC, useMemo } from "react";
import { Card } from "@mui/material";
import { SingleRightsizingRecommendationSubRow } from "./SingleRightsizingRecommendationSubRow";
import { RightSizingRecommendationDetails } from "../../../../../../services/cloudchipr.api";
import { DataGrid } from "../../../../../../storybook/data-grid/DataGrid";
import { DataGridProvider } from "../../../../../../storybook/data-grid/DataGridProvider";
import { rightsizingRecommendationsTableColumns } from "../../rightsizing-recommendations/components/table/rightsizingRecommendationsTableColumns";

interface SingleRightsizingRecommendationContentProps {
  recommendation: RightSizingRecommendationDetails;
}

export const SingleRightsizingRecommendationContent: FC<
  SingleRightsizingRecommendationContentProps
> = ({ recommendation }) => {
  const columns = useMemo(() => {
    return rightsizingRecommendationsTableColumns("active", true);
  }, []);

  return (
    <Card variant="outlined">
      <DataGridProvider>
        <DataGrid
          columns={columns}
          data={[recommendation]}
          columnResizeMode="onEnd"
        />
      </DataGridProvider>
      <SingleRightsizingRecommendationSubRow recommendation={recommendation} />
    </Card>
  );
};
