import { Box, Grid, GridSize, RadioProps } from "@mui/material";
import { FC, ReactNode } from "react";
import { ToggleBoxItem } from "./components/ToggleBoxItem";

type ToggleBoxOption = {
  value: string;
  label: ReactNode;
  disabled?: boolean;
  tooltipText?: string;
  description?: ReactNode;
};

interface ToggleBoxProps {
  options: ToggleBoxOption[];
  value?: string;
  gridSize?: GridSize;
  size?: RadioProps["size"];
  onChange(value: string): void;
}

export const ToggleBox: FC<ToggleBoxProps> = ({
  options,
  value,
  gridSize = 6,
  onChange,
  size,
}) => {
  return (
    <Box>
      <Grid container spacing={1.5}>
        {options.map((option) => {
          return (
            <Grid item xs={gridSize} key={option.value}>
              <ToggleBoxItem
                size={size}
                tooltip={option.tooltipText}
                value={option.value}
                label={option.label}
                disabled={!!option.disabled}
                checked={option.value === value}
                description={option?.description}
                onChange={onChange}
              />
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
};
