import {
  ResourceExplorerFilterProvider,
  ResourceExplorerPossibleFilterV2,
} from "../../../../../../services/cloudchipr.api";

export const findOptionByProviderAndKey = (
  possibleFilters: ResourceExplorerPossibleFilterV2[],
  provider: ResourceExplorerFilterProvider,
  key?: string,
) => {
  return possibleFilters.find(
    (option) => option.key === key && option.filter_provider === provider,
  );
};
