import { createAsyncThunk } from "@reduxjs/toolkit";
import { getRightsizingRecommendationsThunk } from "./getRightsizingRecommendationsThunk";

export const getRightsizingAllRecommendationsThunk = createAsyncThunk(
  "recommendations/getRightsizingAllRecommendationsThunk",
  async (arg, { dispatch }) => {
    return await Promise.all([
      dispatch(getRightsizingRecommendationsThunk(true)),
      dispatch(getRightsizingRecommendationsThunk()),
    ]);
  },
);
