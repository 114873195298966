import { FC, Fragment } from "react";
import TabContext from "@mui/lab/TabContext";
import { TabPanel } from "@mui/lab";
import { OffHoursRecommendationsTitle } from "./OffHoursRecommendationsTitle";
import { OffHoursRecommendationsTable } from "./table/OffHoursRecommendationsTable";
import { OffHoursRecommendationTabs } from "./OffHoursRecommendationTabs";
import { OffHoursDismissedResourcesDataGrid } from "./dismissed-resources/OffHoursDismissedResourcesDataGrid";
import { useAppSelector } from "../../../../../../store/hooks";
import { RecommendationGroupType } from "../utils/types/types";
import { recommendationsDataGridFilteredDataSelector } from "../../../../../../store/recommendations/selectors/off-hours/data/recommendationsDataGridFilteredDataSelector";
import { recommendationsDataGrdDismissedFilteredDataSelector } from "../../../../../../store/recommendations/selectors/off-hours/data/recommendationsDataGrdDismissedFilteredDataSelector";
import { RecommendationDismissConfirmDialog } from "../../common/dismiss/RecommendationDismissConfirmDialog";
import { RecommendationRestoreConfirmDialog } from "../../common/dismiss/RecommendationRestoreConfirmDialog";
import { offHoursRecommendationsPageLoadingSelector } from "../../../../../../store/recommendations/selectors/off-hours/loading/offHoursRecommendationsPageLoadingSelector";
import { useRecommendationsActiveTabControllerHook } from "../../../utils/hooks/useRecommendationsActiveTabController.hook";
import { offHoursRecommendationsDismissedResourcesDataLengthByGroupSelector } from "../../../../../../store/recommendations/selectors/off-hours/dismissed-resources/offHoursRecommendationsDismissedResourcesDataLengthByGroupSelector";
import { ResourceRestoreConfirmDialog } from "../../common/dismiss/by-resource/ResourceRestoreConfirmDialog";
import { recommendationsDataGridDataTotalLengthSelector } from "../../../../../../store/recommendations/selectors/off-hours/data/recommendationsDataGridDataTotalLengthSelector";
import { recommendationsDataGridDismissedDataTotalLengthSelector } from "../../../../../../store/recommendations/selectors/off-hours/data/recommendationsDataGridDismissedDataTotalLengthSelector";

export type RecommendationsTabType =
  | "active"
  | "dismissed"
  | "dismissed_resources";

interface OffHoursRecommendationsContentProps {
  group: RecommendationGroupType;
}

export const OffHoursRecommendationsContent: FC<
  OffHoursRecommendationsContentProps
> = ({ group }) => {
  const loading = useAppSelector(offHoursRecommendationsPageLoadingSelector);

  const activeLength = useAppSelector((state) =>
    recommendationsDataGridDataTotalLengthSelector(state, group),
  );
  const dismissedLength = useAppSelector((state) =>
    recommendationsDataGridDismissedDataTotalLengthSelector(state, group),
  );
  const recommendationsData = useAppSelector((state) =>
    recommendationsDataGridFilteredDataSelector(state, group),
  );
  const recommendationsDismissedData = useAppSelector((state) =>
    recommendationsDataGrdDismissedFilteredDataSelector(state, group),
  );
  const dismissedResourcesLength = useAppSelector((state) =>
    offHoursRecommendationsDismissedResourcesDataLengthByGroupSelector(
      state,
      group,
    ),
  );

  const { activeTab, setActiveTab } = useRecommendationsActiveTabControllerHook(
    loading,
    activeLength,
    dismissedLength,
    dismissedResourcesLength,
  );

  if (!activeLength && !dismissedLength && !dismissedResourcesLength) {
    return null;
  }

  return (
    <Fragment>
      <OffHoursRecommendationsTitle group={group} />

      <TabContext value={activeTab}>
        <OffHoursRecommendationTabs
          loading={loading}
          setActiveTab={setActiveTab}
          group={group}
        />

        <TabPanel value="active" sx={{ p: 0 }}>
          <OffHoursRecommendationsTable
            group={group}
            tab={activeTab}
            recommendations={recommendationsData}
          />
        </TabPanel>

        <TabPanel value="dismissed" sx={{ p: 0 }}>
          <OffHoursRecommendationsTable
            group={group}
            tab={activeTab}
            recommendations={recommendationsDismissedData}
          />
        </TabPanel>

        <TabPanel value="dismissed_resources" sx={{ p: 0 }}>
          <OffHoursDismissedResourcesDataGrid group={group} />
        </TabPanel>
      </TabContext>

      <RecommendationDismissConfirmDialog />
      <RecommendationRestoreConfirmDialog />
      <ResourceRestoreConfirmDialog recommendationType="off-hours" />
    </Fragment>
  );
};
