import { costBreakdownSetupPropertyByKeySelector } from "./costBreakdownSetupPropertyByKeySelector";
import { RootState } from "../../../../store";
import { WidgetCostBreakdownAggregation } from "../../../../../services/cloudchipr.api";

export const costBreakdownSetupHasAggregationSelector = (state: RootState) => {
  const aggregations: Required<WidgetCostBreakdownAggregation> | null =
    costBreakdownSetupPropertyByKeySelector("aggregation")(state);

  return Object.keys(aggregations ?? {}).some(
    (key) => !!aggregations?.[key as keyof WidgetCostBreakdownAggregation],
  );
};
