import { createDraftSafeSelector } from "@reduxjs/toolkit";
import { resourceExplorerDataGridDataPrevPeriodTotalCostSelector } from "./resourceExplorerDataGridDataPrevPeriodTotalCostSelector";
import { resourceExplorerWidgetTotalCostSelector } from "../resource-explorer-widget/resourceExplorerWidgetTotalCostSelector";
import { generateResourceExplorerTotalCostTrend } from "../../../utils/helpers/generateResourceExplorerTotalCostTrend";

export const resourceExplorerDataGridDataTotalTrendSelector =
  createDraftSafeSelector(
    [
      resourceExplorerWidgetTotalCostSelector,
      resourceExplorerDataGridDataPrevPeriodTotalCostSelector,
    ],
    (totalCost, previousPeriodCost): number | null => {
      return generateResourceExplorerTotalCostTrend(
        totalCost,
        previousPeriodCost,
      );
    },
  );
