import { FC } from "react";
import { Stack } from "@mui/material";
import { CostBreakdownWidgetVisualizationChartBase } from "./CostBreakdownWidgetVisualizationChartBase";
import { CostBreakdownWidgetVisualizationForecastedCost } from "./CostBreakdownWidgetVisualizationForecastedCost";
import { CostBreakdownWidgetVisualizationAccountColumn } from "./CostBreakdownWidgetVisualizationAccountColumn";
import { CostBreakdownWidgetVisualizationRegionColumn } from "./CostBreakdownWidgetVisualizationRegionColumn";
import { CostBreakdownWidgetVisualizationPreviousPeriodColumn } from "./CostBreakdownWidgetVisualizationPreviousPeriodColumn";
import { CostBreakdownWidgetVisualizationTrend } from "./CostBreakdownWidgetVisualizationTrend";
import { CostBreakdownWidgetViewTypeSelect } from "../../../components/side-bar/CostBreakdownWidgetViewTypeSelect";
import { WidgetConfigSection } from "../../../../common/widget-configurations/WidgetConfigSection";
import VisualizationIcon from "../../../../../../../../../../../assets/images/icons/visualization_icon.svg";
import {
  ChartType,
  WidgetCostBreakdownVisualization,
} from "../../../../../../../../../../../services/cloudchipr.api";
import { useAppSelector } from "../../../../../../../../../../../store/hooks";
import { costBreakdownSetupVisualizationPropertyByKeySelector } from "../../../../../../../../../../../store/dashboards/selectors/setups/cost-breakdown/costBreakdownSetupVisualizationPropertyByKeySelector";

export const CostBreakdownWidgetVisualizationSection: FC = () => {
  const chartType = useAppSelector(
    costBreakdownSetupVisualizationPropertyByKeySelector("chart_type"),
  );

  return (
    <WidgetConfigSection
      initialExpanded
      title="Visualization"
      icon={<img src={VisualizationIcon} alt="Visualization Icon" />}
    >
      <Stack spacing={1.5}>
        <CostBreakdownWidgetViewTypeSelect />

        {costBreakdownVisualizationDetailsByChartType
          .get(chartType)
          ?.map((type) => {
            const Component =
              costBreakdownVisualizationDetailsComponentByType.get(type);

            if (!Component) {
              return;
            }

            return <Component key={type} />;
          })}
      </Stack>
    </WidgetConfigSection>
  );
};

const costBreakdownVisualizationDetailsByChartType = new Map<
  ChartType,
  (keyof WidgetCostBreakdownVisualization)[]
>([
  ["stack", ["chart_base_type"]],
  ["pie", ["trend"]],
  ["numeral", ["trend", "forecasted_cost"]],
  [
    "table",
    //TODO: after adding account and region column only add "account_column", "region_column", to this array
    ["previous_period_cost", "trend", "forecasted_cost"],
  ],
]);

const costBreakdownVisualizationDetailsComponentByType = new Map<
  keyof WidgetCostBreakdownVisualization,
  FC
>([
  ["chart_base_type", CostBreakdownWidgetVisualizationChartBase],
  ["trend", CostBreakdownWidgetVisualizationTrend],
  ["forecasted_cost", CostBreakdownWidgetVisualizationForecastedCost],
  ["account_column", CostBreakdownWidgetVisualizationAccountColumn],
  ["region_column", CostBreakdownWidgetVisualizationRegionColumn],
  [
    "previous_period_cost",
    CostBreakdownWidgetVisualizationPreviousPeriodColumn,
  ],
]);
