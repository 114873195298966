import { FC } from "react";
import { Stack } from "@mui/material";
import { AddResourceButton } from "./AddResourceButton";
import { AutomationResourcesList } from "./resources/AutomationResourcesList";

export const AutomationResourcesContent: FC = () => {
  return (
    <Stack>
      <AutomationResourcesList />
      <AddResourceButton />
    </Stack>
  );
};
