import { Typography } from "@mui/material";
import { FilterSourceButton } from "../../../../../../common/filters/filters-selection/utils/types/types";

export const ResourceFiltersTextViewSourceButton: FilterSourceButton = ({
  openDialog,
}) => {
  return (
    <Typography
      onClick={openDialog}
      variant="body2"
      color="primary"
      fontWeight="medium"
      sx={{ cursor: "pointer" }}
    >
      Filtered Results:
    </Typography>
  );
};
