import { FC, ReactNode } from "react";
import { Card, Theme } from "@mui/material";
import { SxProps } from "@mui/system";
import {
  Statistic,
  StatisticColors,
  StatisticPropsSize,
} from "../statistic/Statistic";

interface StatisticProps {
  title: string;
  value: string | number;
  color?: StatisticColors;
  size?: StatisticPropsSize;
  loading?: boolean;
  sx?: SxProps<Theme>;
  endAdornment?: ReactNode;
}

export const StatisticCard: FC<StatisticProps> = ({
  title,
  value,
  color = "default",
  size = "medium",
  loading,
  sx,
  endAdornment,
}) => {
  return (
    <Card
      sx={{
        py: 1,
        flex: 1,
      }}
      variant="outlined"
    >
      <Statistic
        indicator="monthly"
        title={title}
        value={value}
        color={color}
        size={size}
        sx={sx}
        loading={loading}
        endAdornment={endAdornment}
      />
    </Card>
  );
};
