import { FC, useCallback, useMemo } from "react";
import { Stack, Typography } from "@mui/material";
import { LiveUsageMgmtRegionsTriggerComponent } from "./LiveUsageMgmtRegionsTriggerComponent";
import {
  ProviderType,
  useGetProvidersByProviderRegionsQuery,
} from "../../../../../../services/cloudchipr.api";
import { useAppDispatch, useAppSelector } from "../../../../../../store/hooks";
import { DropdownSelect } from "../../../../../common/select/dropdown-select/DropdownSelect";
import { liveUsageMgmtRegionsSelector } from "../../../../../../store/live-usage-mgmt/selectors/store-selectors/liveUsageMgmtRegionsSelector";
import { setLiveUsageMgmtRegion } from "../../../../../../store/live-usage-mgmt/liveUsageMgmtSlice";
import { refreshLiveUsageMgmtPageThunk } from "../../../../../../store/live-usage-mgmt/thunks/refreshLiveUsageMgmtPageThunk";

interface LiveUsageMgmtRegionsSelectProps {
  provider: ProviderType;
}

export const LiveUsageMgmtRegionsSelect: FC<
  LiveUsageMgmtRegionsSelectProps
> = ({ provider }) => {
  const dispatch = useAppDispatch();

  const { data } = useGetProvidersByProviderRegionsQuery({
    provider,
  });

  const currentRegions = useAppSelector(liveUsageMgmtRegionsSelector);
  const allRegions = useMemo(() => data?.map(({ key }) => key) ?? [], [data]);

  const regionsSubmitHandler = useCallback(
    (selectedRegions: string[]) => {
      const appliedRegions = currentRegions ?? allRegions;

      if (appliedRegions.join() === selectedRegions.join()) {
        return;
      }

      dispatch(
        setLiveUsageMgmtRegion(
          selectedRegions.length ? selectedRegions : currentRegions,
        ),
      );

      dispatch(refreshLiveUsageMgmtPageThunk());
    },
    [dispatch, currentRegions, allRegions],
  );

  const options = useMemo(() => {
    return (
      data?.map((region) => {
        return {
          value: region.key,
          rawValue: region,
          label: (
            <Stack direction="row" spacing={1} justifyContent="space-between">
              <Typography variant="body2">{region.name}</Typography>
              <Typography
                variant="body2"
                color="text.secondary"
                textAlign="right"
              >
                {region.key}
              </Typography>
            </Stack>
          ),
        };
      }) ?? []
    );
  }, [data]);

  if (!allRegions.length) {
    return null;
  }

  return (
    <DropdownSelect
      label="Regions"
      options={options}
      values={currentRegions ?? undefined}
      initialSelectedValues={currentRegions ? undefined : allRegions}
      TriggerComponent={LiveUsageMgmtRegionsTriggerComponent}
      submitHandlerOnClose={regionsSubmitHandler}
      listWidth={320}
    />
  );
};
