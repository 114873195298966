import { FC, memo, useCallback, useMemo } from "react";
import { FormikHelpers } from "formik";
import { useDidMount } from "rooks";
import {
  FilterItem,
  ResourceType,
  usePostUsersMeOrganisationsCurrentByTypeResourcesTagKeysMutation,
} from "../../../../../../../services/cloudchipr.api";
import { AutocompleteSelect } from "../../../../../../common/select/autocomplete-select/AutocompleteSelect";
import { TypographyWithTooltip } from "../../../../../../common/TypographyWithTooltip";
import { tagFilterKeysFixedCacheKeyGenerator } from "../../utils/helpers/tagFilterKeysFixedCacheKeyGenerator";
import { DropdownSelectOption } from "../../../../../../common/select/dropdown-select/utils/types/types";

interface TagFilterKeysProps {
  error?: boolean;
  setFieldValue: FormikHelpers<FilterItem>["setFieldValue"];
  accountIds?: string[];
  resourceType: ResourceType;
  disabled: boolean;
  tagValueVisible: boolean;
  value?: string;
}

export const TagFilterKeys: FC<TagFilterKeysProps> = memo(
  ({
    value,
    resourceType,
    accountIds,
    setFieldValue,
    error,
    tagValueVisible,
    disabled,
  }) => {
    const [getKeys, { data, isLoading }] =
      usePostUsersMeOrganisationsCurrentByTypeResourcesTagKeysMutation({
        fixedCacheKey: tagFilterKeysFixedCacheKeyGenerator(resourceType),
      });

    const autoCompleteSelectChangeHandler = useCallback(
      (data: any) => {
        if (value !== data[0]) {
          setFieldValue("value.key", data[0]);
          tagValueVisible && setFieldValue("value.value", []);
        }
      },
      [setFieldValue, tagValueVisible, value],
    );

    const options = useMemo(() => {
      return data?.map((item) => {
        return {
          label: <TypographyWithTooltip title={item} />,
          value: item,
        };
      });
    }, [data]);

    const renderOption = useCallback((option: DropdownSelectOption) => {
      return <TypographyWithTooltip title={option.value} />;
    }, []);

    useDidMount(() => {
      getKeys({
        type: resourceType,
        body: {
          account_ids: accountIds ?? [],
        },
      });
    });

    return (
      <AutocompleteSelect
        size="xsmall"
        freeSolo
        creatable
        singleSelect
        label="Key"
        placeholder="Key"
        allSelectedLabel="keys"
        options={options ?? []}
        error={error}
        initialSelectedValues={data && value ? [value] : undefined}
        values={data && value ? [value] : undefined}
        onChange={autoCompleteSelectChangeHandler}
        disabled={disabled}
        optionsLoading={isLoading}
        renderOption={renderOption}
      />
    );
  },
);
