import moment from "moment/moment";

export const previousMonthSamePeriod = (): string => {
  const now = moment();

  const currentDay = now.date();
  const lastMonth = moment().subtract(1, "month").format("MMM");

  if (currentDay === 1) {
    return `1 ${lastMonth}`;
  }

  return `1-${currentDay} ${lastMonth}`;
};
