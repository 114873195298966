import { SvgIcon, SvgIconProps } from "@mui/material";

function UsageTypeIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <svg
        width="24"
        height="24"
        fill="none"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M13 2.02344V5.05344C16.39 5.54344 19 8.44344 19 11.9734C19 12.8734 18.82 13.7234 18.52 14.5134L21.12 16.0434C21.68 14.8034 22 13.4234 22 11.9734C22 6.79344 18.05 2.52344 13 2.02344ZM12 18.9734C8.13 18.9734 5 15.8434 5 11.9734C5 8.44344 7.61 5.54344 11 5.05344V2.02344C5.94 2.52344 2 6.78344 2 11.9734C2 17.4934 6.47 21.9734 11.99 21.9734C15.3 21.9734 18.23 20.3634 20.05 17.8834L17.45 16.3534C16.17 17.9534 14.21 18.9734 12 18.9734Z"
          fill="black"
          fillOpacity="0.54"
        />
      </svg>
    </SvgIcon>
  );
}

UsageTypeIcon.muiName = "UsageTypeIcon";

export default UsageTypeIcon;
