import {
  commitmentsPaymentOptionLabels,
  commitmentsResourceTypeNames,
  savingsPlanLockBackPeriodLabels,
  savingsPlansTypeLabels,
  savingsPlanTermLabels,
} from "../../../../../../../../common/utils/constants/labels";
import {
  CommitmentPaymentOption,
  LookbackPeriod,
  ReservationType,
  SavingsPlanTermInYears,
  SavingsPlanType,
} from "../../../../../../../../../../../services/cloudchipr.api";

export const lockBackPeriodFilterOptions: {
  label: string;
  value: LookbackPeriod;
}[] = [
  {
    value: "seven_days",
    label: savingsPlanLockBackPeriodLabels.seven_days,
  },
  {
    value: "thirty_days",
    label: savingsPlanLockBackPeriodLabels.thirty_days,
  },
  {
    value: "sixty_days",
    label: savingsPlanLockBackPeriodLabels.sixty_days,
  },
];

export const paymentFilterOptions: {
  value: CommitmentPaymentOption;
  label: string;
}[] = [
  {
    value: "all_upfront",
    label: commitmentsPaymentOptionLabels.all_upfront,
  },
  {
    value: "partial_upfront",
    label: commitmentsPaymentOptionLabels.partial_upfront,
  },
  {
    value: "no_upfront",
    label: commitmentsPaymentOptionLabels.no_upfront,
  },
];

export const termFilterOptions: {
  value: SavingsPlanTermInYears;
  label: string;
}[] = [
  {
    value: "one_year",
    label: savingsPlanTermLabels.one_year,
  },
  {
    value: "three_years",
    label: savingsPlanTermLabels.three_years,
  },
];

export const savingsPlanTypeFilterOptions: {
  value: SavingsPlanType;
  label: string;
}[] = [
  {
    value: "compute",
    label: savingsPlansTypeLabels.compute,
  },
  {
    value: "ec2_instance",
    label: savingsPlansTypeLabels.ec2_instance,
  },
  {
    value: "sage_maker",
    label: savingsPlansTypeLabels.sage_maker,
  },
];

export const reservationTypeFilterOptions: {
  value: ReservationType;
  label: string;
}[] = [
  {
    value: "ec2",
    label: commitmentsResourceTypeNames.ec2,
  },
  {
    value: "rds",
    label: commitmentsResourceTypeNames.rds,
  },
  {
    value: "elasticache",
    label: commitmentsResourceTypeNames.elasticache,
  },
  {
    value: "redshift",
    label: commitmentsResourceTypeNames.redshift,
  },
  {
    value: "opensearch",
    label: commitmentsResourceTypeNames.opensearch,
  },
];
