import { FC, useCallback, useMemo } from "react";
import { WeekDaysSelect } from "../../../../../../common/WeekDaysSelect";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../store/hooks";
import { reportsScheduleWeekDaysSelector } from "../../../../../../../store/reports/selector/reportsScheduleWeekDaysSelector";
import {
  numbersToWeekDays,
  weekDaysToNumber,
} from "../../../../utils/constants/constants";
import { setReportsScheduleDataThunk } from "../../../../../../../store/reports/thunks/setReportsScheduleDataThunk";

export const ReportsDrawerWeekDaysSelect: FC = () => {
  const dispatch = useAppDispatch();
  const weekDays = useAppSelector(reportsScheduleWeekDaysSelector);
  const weekDaysAsNumbers = useMemo(
    () => weekDays.map((day) => weekDaysToNumber[day]),
    [weekDays],
  );

  const handleChange = useCallback(
    (days: number[]) => {
      const weekDays = days.map((day) => numbersToWeekDays[day]);

      dispatch(
        setReportsScheduleDataThunk({
          week_days: weekDays,
        }),
      );
    },
    [dispatch],
  );

  return (
    <WeekDaysSelect weekDays={weekDaysAsNumbers} onChange={handleChange} />
  );
};
