import { DataViewTab } from "../../../../../../store/commitments/utils/types/types";
import { SavingsPlanCoverageGrouping } from "../../../../../../services/cloudchipr.api";

export const getUtilizationAndCoverageDrawerDynamicHeaderCellTitles = (
  dataViewTab: DataViewTab,
  grouping: SavingsPlanCoverageGrouping,
) => {
  if (dataViewTab === "coverage") {
    return {
      coverageCell:
        grouping === "commitment" ? "Savings Plan Covers" : "Coverage",
      coveredUsageCell: "Spend Covered by Savings Plans",
    };
  }

  if (dataViewTab === "commitment") {
    return {
      coverageCell: "Covered by This Savings Plan",
      coveredUsageCell: "Spend Covered by This Savings Plan",
    };
  }
};
