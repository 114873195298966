import { FC } from "react";
import Button from "@mui/material/Button";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { Typography } from "@mui/material";
import { UseMenuHookOpenMenu } from "../../../../../../../../../../../utils/hooks/useMenu.hook";

interface LiveUsageMgmtToolbarActionsButtonProps {
  onClick?: UseMenuHookOpenMenu;
  disabled?: boolean;
}

export const LiveUsageMgmtToolbarActionsButton: FC<
  LiveUsageMgmtToolbarActionsButtonProps
> = ({ onClick, disabled }) => {
  return (
    <Button
      variant="contained"
      color="primary"
      onClick={onClick}
      sx={{ pr: 1 }}
      disabled={disabled}
    >
      <Typography pr={0.5}>Actions</Typography>
      <ArrowDropDownIcon />
    </Button>
  );
};
