import { Stack } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { ResourceExplorerBillingExportDialogDetailsInstruction } from "../../../../resource-explorer/components/resource-explorer-card/components/resource-explorer-billing-export/components/resource-explorer-billing-export-dialog/components/resource-explorer-billing-export-dialog-billing-details-step/components/ResourceExplorerBillingExportDialogDetailsInstruction";
export const GcpBillingExportDrawerFooter = () => {
  return (
    <Stack direction="row" spacing={1} alignItems="center" px={2}>
      <InfoOutlinedIcon color="action" />
      <ResourceExplorerBillingExportDialogDetailsInstruction />
    </Stack>
  );
};
