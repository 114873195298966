import { FC } from "react";
import { Stack, Typography } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

import { integrationCustomSlackActionExecuteDataGrid } from "../utils/constants";
import { IntegrationCustomMessageDataGrid } from "../../common/IntegrationCustomMessageDataGrid";

export const SlackIntegrationDataGrid: FC = () => {
  return (
    <IntegrationCustomMessageDataGrid
      data={integrationCustomSlackActionExecuteDataGrid}
      label={
        <Stack direction="row">
          <Typography variant="caption">resources</Typography>
          <ArrowDropDownIcon
            fontSize="small"
            sx={{
              color: "text.secondary",
              ml: -0.25,
            }}
          />
        </Stack>
      }
    />
  );
};
