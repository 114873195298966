import { costBreakdownWidgetSetupForecastedOptionSelector } from "./costBreakdownWidgetSetupForecastedOptionSelector";
import { costBreakdownWidgetSetupCostsSelector } from "./costBreakdownWidgetSetupCostsSelector";
import { RootState } from "../../../../../store";
import { ForecastedCost } from "../../../../../../services/cloudchipr.api";

export const costBreakdownWidgetSetupForecastedCostsSumSelector = (
  state: RootState,
): ForecastedCost | undefined => {
  const forecastedOption =
    costBreakdownWidgetSetupForecastedOptionSelector(state);
  if (!forecastedOption) {
    return;
  }
  const costs = costBreakdownWidgetSetupCostsSelector(state);

  return costs?.forecasted_costs?.find(
    (item) => item.forecast_option === forecastedOption,
  );
};
