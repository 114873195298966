import { FC, Fragment, useCallback, useState } from "react";
import {
  ListItemIcon,
  ListItemText,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { useAppAbility } from "../../../../../../../../services/permissions";
import { ConfirmationDialog } from "../../../../../../../common/modals/ConfirmationDialog";
import { useDialog } from "../../../../../../../../utils/hooks/useDialog.hook";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../store/hooks";
import { duplicateMeOffHoursLoadingSelector } from "../../../../../../../../store/schedules/selectors/off-hours/duplicateMeOffHoursLoadingSelector";
import { duplicateWorkflowLoadingSelector } from "../../../../../../../../store/automations/selectros/workflow/create/duplicateWorkflowLoadingSelector";
import { COMPONENT_ID } from "../../../../../../../../utils/constants/components-ids";
import { duplicateAutomationThunk } from "../../../../../../../../store/schedules/thunks/duplicateAutomationThunk";
import { AutomationType } from "../../../../../../../../store/automations/utils/types/common";

interface ScheduleListGridDuplicateActionProps {
  name: string;
  id: string;
  type: AutomationType;
  onCloseMenu(): void;
}
export const ScheduleListGridDuplicateAction: FC<
  ScheduleListGridDuplicateActionProps
> = ({ type, name, id, onCloseMenu }) => {
  const dispatch = useAppDispatch();
  const { cannot } = useAppAbility();
  const canNotCreateSchedule = cannot("create", "schedule");
  const {
    open: duplicateDialogOpen,
    openDialog: openDuplicateDialog,
    closeDialog: closeDuplicateDialog,
  } = useDialog();
  const [localName, setLocalName] = useState(`${name} (copy)`);

  const duplicateOffHourLoading = useAppSelector((state) =>
    duplicateMeOffHoursLoadingSelector(state, id),
  );
  const duplicateWorkflowLoading = useAppSelector((state) =>
    duplicateWorkflowLoadingSelector(state, id),
  );

  const isOffHour = type === "offHours";

  const duplicateAutomationHandler = useCallback(async () => {
    const wrapperId = document.getElementById(COMPONENT_ID.APP_OUTLET_WRAPPER);
    const response = await dispatch(
      duplicateAutomationThunk({
        type,
        id,
        name: localName,
      }),
    ).unwrap();
    onCloseMenu();
    closeDuplicateDialog();

    if (wrapperId && response) {
      wrapperId.scrollTo({ top: 0, behavior: "smooth" });
    }
  }, [closeDuplicateDialog, onCloseMenu, type, id, localName, dispatch]);

  const inputChangeHandler = useCallback((e: any) => {
    setLocalName(e.target.value);
  }, []);

  return (
    <Fragment>
      <MenuItem
        dense
        onClick={openDuplicateDialog}
        disabled={canNotCreateSchedule}
      >
        <ListItemIcon>
          <ContentCopyIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText>Duplicate</ListItemText>
      </MenuItem>

      <ConfirmationDialog
        open={duplicateDialogOpen}
        onClose={closeDuplicateDialog}
        ConfirmButtonProps={{
          onClick: duplicateAutomationHandler,
          loading: duplicateOffHourLoading ?? duplicateWorkflowLoading,
          disabled: !localName,
          children: "duplicate",
        }}
        CancelButtonProps={{
          onClick: closeDuplicateDialog,
          disabled: duplicateOffHourLoading ?? duplicateWorkflowLoading,
        }}
        title={`Duplicate ${isOffHour ? "Off Hours" : "Workflow"}`}
        dividers
        dialogContent={
          <Stack spacing={1}>
            <Typography variant="body2">
              {isOffHour ? "Off Hours" : "Workflow"} Name
            </Typography>
            <TextField
              autoFocus
              fullWidth
              type="text"
              value={localName}
              size="small"
              variant="outlined"
              onChange={inputChangeHandler}
              error={!localName}
            />
          </Stack>
        }
      />
    </Fragment>
  );
};
