import { FC } from "react";
import { Stack } from "@mui/material";
import { ReportsDrawerSourceTypeSelect } from "./ReportsDrawerSourceTypeSelect";
import { ReportsDrawerViewSelect } from "./ReportsDrawerViewSelect";
import { ReportsDrawerDashboardSelect } from "./ReportsDrawerDashboardSelect";
import { useAppSelector } from "../../../../../../../store/hooks";
import { reportsDataPropertyByKeySelector } from "../../../../../../../store/reports/selector/reportsDataPropertyByKeySelector";

interface ReportsDrawerSourceSectionProps {
  disableSourceSelect?: boolean;
}

export const ReportsDrawerSourceSection: FC<
  ReportsDrawerSourceSectionProps
> = ({ disableSourceSelect }) => {
  const source = useAppSelector(
    reportsDataPropertyByKeySelector("source_type"),
  );

  return (
    <Stack spacing={1.5} direction="row">
      <Stack flex={1}>
        <ReportsDrawerSourceTypeSelect disabled={disableSourceSelect} />
      </Stack>
      <Stack flex={1}>
        {source === "view" && <ReportsDrawerViewSelect />}
        {source === "dashboard" && <ReportsDrawerDashboardSelect />}
      </Stack>
    </Stack>
  );
};
