import { FC, Fragment, useCallback } from "react";
import { Grid, TextField } from "@mui/material";

interface IssueURLProps {
  error?: string;
  name: string;
  label: string;
  value?: { id: string };
  setFieldValue(key: string, value: any): void;
}

export const IssueURL: FC<IssueURLProps> = ({
  value,
  name,
  label,
  error,
  setFieldValue,
}) => {
  const changeHandler = useCallback(
    (event: any) => {
      setFieldValue(`body.fields.${name}`, { id: event.target.value });
    },
    [name, setFieldValue],
  );

  return (
    <Fragment>
      <Grid item sm={4}>
        <TextField
          fullWidth
          type="text"
          value={value?.id || ""}
          size="small"
          error={!!error}
          variant="outlined"
          helperText={error}
          placeholder={label}
          onChange={changeHandler}
          FormHelperTextProps={{ sx: { mx: 0 } }}
        />
      </Grid>
      <Grid item sm={6} />
    </Fragment>
  );
};
