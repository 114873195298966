import { ChangeEvent, FC, Fragment, memo, useCallback } from "react";
import { FormikHandlers, FormikHelpers } from "formik";
import { Grid, TextField } from "@mui/material";
import * as yup from "yup";
import { FilterItemOperatorType } from "../FilterItemOperatorType";
import {
  FilterItem,
  Operator,
} from "../../../../../../services/cloudchipr.api";
import { DayMultiplier } from "../../../../../../utils/helpers/date-time/time";

export const UnusedSinceFilterValueValidation = yup.number().when("operator", {
  is: (operator: string) => operator !== "no_data",
  then: yup.number().required("This field is required"),
});

interface UnusedSinceFilterProps {
  operators?: Operator[];
  filter: FilterItem;
  error?: any;
  onChange: FormikHandlers["handleChange"];
  setFieldValue: FormikHelpers<FilterItem>["setFieldValue"];
}

export const UnusedSinceFilter: FC<UnusedSinceFilterProps> = memo(
  ({ operators, filter, onChange, setFieldValue, error }) => {
    const valueChangeHandler = useCallback(
      (e: ChangeEvent<any>) => {
        const value = e.target.value;
        if (isNaN(value)) {
          return;
        }

        const val = +value * DayMultiplier;
        setFieldValue("value", val || null);
      },
      [setFieldValue],
    );

    return (
      <Fragment>
        <FilterItemOperatorType
          value={filter.operator || ""}
          options={operators}
          onChange={onChange}
        />

        <Grid item md={3}>
          {filter.operator !== "no_data" && (
            <TextField
              size="xsmall"
              label="Days"
              fullWidth
              onChange={valueChangeHandler}
              value={filter.value ? `${+filter.value / DayMultiplier}` : ""}
              error={!!error}
              InputProps={{ sx: { pr: 0 } }}
            />
          )}
        </Grid>

        <Grid item md={5} />
      </Fragment>
    );
  },
);
