import { FC, useMemo } from "react";
import InsertChartOutlinedOutlinedIcon from "@mui/icons-material/InsertChartOutlinedOutlined";

import { HierarchySelectVisibility } from "../hierarchy-select-visibility/HierarchySelectVisibility";
import { SelectViewTrigger } from "../view-select-trigger/SelectViewTrigger";
import { FilterTriggerComponentProps } from "../../../common/select/dropdown-select/utils/types/filterTriggerComponentProps";
import { DropdownFooterComponent } from "../../../common/select/dropdown-select/utils/types/types";
import { useAppSelector } from "../../../../store/hooks";
import { resourceExplorerHierarchyVisibilitiesMergedDataItemsSelector } from "../../../../store/resource-explorer/selectors/views-hierarchy-visibility/resourceExplorerHierarchyVisibilitiesMergedDataItemsSelector";
import { NavigationItemsVisibilityType } from "../../../../store/navigation/utils/types";
import { navigationResourceExplorerDataSelector } from "../../../../store/navigation/selectors/resource-explorer/navigationResourceExplorerDataSelector";

interface HierarchyViewSelectionWrapperProps {
  onChange(id: string): void;
  selectedItemId: string;
  selectedViews?: string[];
  CustomTriggerComponent?: FC<FilterTriggerComponentProps>;
  DropdownFooter?: DropdownFooterComponent;
  visibility?: NavigationItemsVisibilityType;
}

export const HierarchyViewSelectionWrapper: FC<
  HierarchyViewSelectionWrapperProps
> = ({
  onChange,
  selectedItemId,
  selectedViews,
  CustomTriggerComponent,
  DropdownFooter,
  visibility,
}) => {
  const mergedHierarchyVisibilityItems = useAppSelector(
    resourceExplorerHierarchyVisibilitiesMergedDataItemsSelector,
  );

  const hierarchyVisibility = useAppSelector(
    navigationResourceExplorerDataSelector,
  );

  const label = useMemo(() => {
    const selectedItem = mergedHierarchyVisibilityItems?.find(
      (visibilityItem) => {
        if (visibilityItem.id === selectedItemId) {
          return visibilityItem;
        }
      },
    );

    return selectedItem?.name ?? "";
  }, [mergedHierarchyVisibilityItems, selectedItemId]);

  return (
    <HierarchySelectVisibility
      label={label}
      selectedItemId={selectedItemId}
      hierarchy={hierarchyVisibility}
      onChange={onChange}
      ItemIcon={InsertChartOutlinedOutlinedIcon}
      disabledOptionsIds={selectedViews}
      CustomTriggerComponent={CustomTriggerComponent ?? SelectViewTrigger}
      DropdownFooter={DropdownFooter}
      visibility={visibility}
    />
  );
};
