import { createDraftSafeSelector } from "@reduxjs/toolkit";
import { recommendationDbDailyActiveDataSelector } from "./recommendationDbDailyActiveDataSelector";
import { recommendationDbWeeklyActiveDataSelector } from "./recommendationDbWeeklyActiveDataSelector";

export const recommendationDbActiveDataSelector = createDraftSafeSelector(
  [
    recommendationDbDailyActiveDataSelector,
    recommendationDbWeeklyActiveDataSelector,
  ],
  (daily, weekly) => {
    return [...daily, ...weekly];
  },
);
