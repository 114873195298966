import { useCallback, useMemo } from "react";
import { ResourceType } from "../../services/cloudchipr.api";

export function useLocalStorageHook() {
  const setItem = useCallback((key: string, value: any) => {
    if (!key) {
      return;
    }

    localStorage.setItem(key, JSON.stringify(value));
  }, []);

  const getItem = useCallback((key: string): any => {
    if (!key) {
      return;
    }

    const item = localStorage.getItem(key);
    let parsed = {};

    try {
      parsed = JSON.parse(item || "{}");
    } catch (e) {
      return {};
    }

    return parsed;
  }, []);

  const removeItem = useCallback((key: string) => {
    if (!key) {
      return;
    }

    localStorage.removeItem(key);
  }, []);

  return useMemo(() => {
    return {
      setItem,
      getItem,
      removeItem,
      clear: localStorage.clear,
    };
  }, [setItem, getItem, removeItem]);
}

interface GenerateStorageKeyArgs {
  accountId: string;
  resourceType: ResourceType;
  identifier: "tableConfig" | "resourceCardConfig";
}
export const generateStorageKey = ({
  accountId,
  resourceType,
  identifier,
}: GenerateStorageKeyArgs): string => {
  return `c8r:${accountId}:${resourceType}:${identifier}`;
};
