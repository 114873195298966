import { FC, useMemo } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import { CategoryFiltersCountCell } from "./CategoryFiltersCountCell";
import { CategoryActions } from "./actions/CategoryActions";
import { AddCategoryButtonRow } from "./AddCategoryButtonRow";
import { CategoryNameCell } from "./CategoryNameCell";
import { CategoryWithCosts } from "../../../../../../services/cloudchipr.api";
import { money } from "../../../../../../utils/numeral/money";
import { useAppSelector } from "../../../../../../store/hooks";
import { dimensionsSearchSelector } from "../../../../../../store/dimensions/selectors/slice-data/DimensionsSearchSelector";

interface CategoriesTableProps {
  dimensionId: string;
  dimensionName: string;
  categories: CategoryWithCosts[];
}

export const CategoriesTable: FC<CategoriesTableProps> = ({
  dimensionName,
  dimensionId,
  categories,
}) => {
  const search = useAppSelector(dimensionsSearchSelector);

  const filteredCategories = useMemo(() => {
    const keyword = search?.toLowerCase()?.trim() ?? "";

    if (dimensionName.toLowerCase().includes(keyword)) {
      return categories;
    }

    return categories.filter((category) => {
      return `${category.name.toLowerCase()} ${money(category.total_cost)}`.includes(
        keyword,
      );
    });
  }, [categories, dimensionName, search]);

  return (
    <Table sx={styles.table}>
      <TableBody>
        {filteredCategories.map((category) => (
          <TableRow sx={styles.tableRow} key={category.id}>
            <TableCell width={40} />

            <TableCell width={250} sx={{ px: 1 }}>
              <CategoryNameCell categoryId={category.id} name={category.name} />
            </TableCell>

            <TableCell width={150} sx={{ pr: 5 }}>
              <Typography align="right" variant="subtitle1">
                {money(category.total_cost)}
              </Typography>
            </TableCell>

            <TableCell width={200} sx={{ pr: 5 }}>
              <Typography align="right" variant="subtitle1" color="error.dark">
                {money(category.filtered_resource_costs)}
              </Typography>
            </TableCell>

            <TableCell width={150} sx={{ px: 1 }}>
              <CategoryFiltersCountCell
                category={category}
                categories={categories}
                dimensionId={dimensionId}
              />
            </TableCell>

            <TableCell width={40} sx={{ px: 1 }}>
              <CategoryActions
                category={category}
                categories={categories}
                dimensionId={dimensionId}
              />
            </TableCell>
          </TableRow>
        ))}

        <AddCategoryButtonRow
          dimensionId={dimensionId}
          categories={categories}
          dimensionName={dimensionName}
        />
      </TableBody>
    </Table>
  );
};

const styles = {
  table: {
    tableLayout: "fixed",
  },

  tableRow: {
    bgcolor: "grey.50",
    "& td": { borderColor: "grey.200" },
  },
};
