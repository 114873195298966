import { billingWarningAccountStatusesSelector } from "./billingWarningAccountStatusesSelector";
import { RootState } from "../../../../store";

export const billingWarningsCountAllProvidersSelector = (
  state: RootState,
): number => {
  const warningAccountStatuses = billingWarningAccountStatusesSelector(state);

  return warningAccountStatuses.length ?? 0;
};
