import { RootState } from "../../../store";
import { dimensionsSliceSelector } from "../dimensionsSliceSelector";
import { DimensionCategories } from "../../utils/types/common";

export const dimensionCategoriesSelector = (
  state: RootState,
): DimensionCategories => {
  const slice = dimensionsSliceSelector(state);

  return slice.categories;
};
