import { FC, useCallback } from "react";
import { TimeZoneSelect } from "../../../../../../common/TimeZoneSelect";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../store/hooks";
import { setReportsData } from "../../../../../../../store/reports/reportsSlice";
import { reportsDataPropertyByKeySelector } from "../../../../../../../store/reports/selector/reportsDataPropertyByKeySelector";

export const ReportsDrawerTimeZone: FC = () => {
  const dispatch = useAppDispatch();
  const timeZone = useAppSelector(
    reportsDataPropertyByKeySelector("time_zone"),
  );

  const changeHandler = useCallback(
    (timeZone: string) => {
      dispatch(
        setReportsData({
          time_zone: timeZone,
        }),
      );
    },
    [dispatch],
  );

  return <TimeZoneSelect timeZone={timeZone} onChange={changeHandler} />;
};
