import { FC } from "react";
import { CircularProgress, Stack } from "@mui/material";
import { WorkflowFormSetup } from "./form/WorkflowFormSetup";
import { WorkflowFormPreview } from "./preview/WorkflowFormPreview";
import { AutomationActions } from "../../common/components/form/actions/AutomationActions";
import { AutomationStepper } from "../../common/components/form/helper-components/AutomationStepper";
import { AutomationName } from "../../common/components/form/AutomationName";
import { AutomationDescription } from "../../common/components/form/AutomationDescription";
import { useAppSelector } from "../../../../../store/hooks";
import { automationCurrentStepSelector } from "../../../../../store/automations/selectros/common/automationCurrentStepSelector";
import { automationInitializedSelector } from "../../../../../store/automations/selectros/common/automationInitializedSelector";
import { AutomationErrorsAlert } from "../../common/components/form/AutomationErrorsAlert";

export const WorkflowForm: FC = () => {
  const step = useAppSelector(automationCurrentStepSelector);
  const initialized = useAppSelector(automationInitializedSelector);

  if (!initialized) {
    return (
      <Stack alignItems="center" pt={10}>
        <CircularProgress />
      </Stack>
    );
  }

  return (
    <Stack alignItems="center" p={3}>
      <Stack maxWidth={740} width="100%" spacing={2}>
        <AutomationStepper />

        <AutomationErrorsAlert />

        <AutomationName />

        <AutomationDescription />

        {step === "setup" ? <WorkflowFormSetup /> : <WorkflowFormPreview />}

        <AutomationActions />
      </Stack>
    </Stack>
  );
};
