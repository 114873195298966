import { FC, useMemo } from "react";
import { Stack } from "@mui/material";
import { PotentialSavingsMetadata } from "./PotentialSavingsMetadata";
import { CommitmentsPageHeaderMetadataCard } from "../../../common/components/CommitmentsPageHeaderMetadataCard";
import { useAppSelector } from "../../../../../../store/hooks";
import { savingsPlansNetSavingsSelector } from "../../../../../../store/commitments/selectors/coverage-and-utilization/data/totals/savingsPlansNetSavingsSelector";
import { money } from "../../../../../../utils/numeral/money";
import { savingsPlansForGranularLoadingSelector } from "../../../../../../store/commitments/selectors/coverage-and-utilization/data/loading/savingsPlansForGranularLoadingSelector";
import { savingsPlansGranularDataUncoveredUsageSelector } from "../../../../../../store/commitments/selectors/coverage-and-utilization/data/granular/totals/savingsPlansGranularDataUncoveredUsageSelector";
import { organisationQuarterStartMonthSelector } from "../../../../../../store/common/selectors/org-current-settings/organisationQuarterStartMonthSelector";
import { commitmentsDatesSelector } from "../../../../../../store/commitments/selectors/common/view-options/commitmentsDatesSelector";
import { generateFromToFromDates } from "../../utils/helpers/generateFromToFromDates";
import { savingsPlansGranularRealCostSelector } from "../../../../../../store/commitments/selectors/coverage-and-utilization/data/granular/totals/savingsPlansGranularRealCostSelector";

export const UtilizationAndCoverageMetadata: FC = () => {
  const netSavings = useAppSelector(savingsPlansNetSavingsSelector);
  const realCost = useAppSelector(savingsPlansGranularRealCostSelector);
  const loading = useAppSelector(savingsPlansForGranularLoadingSelector);
  const uncoveredUsage = useAppSelector(
    savingsPlansGranularDataUncoveredUsageSelector,
  );

  const quarterStartMonth = useAppSelector(
    organisationQuarterStartMonthSelector,
  );

  const dates = useAppSelector(commitmentsDatesSelector);
  const datesFromTo = useMemo(() => {
    return generateFromToFromDates(dates, quarterStartMonth);
  }, [dates, quarterStartMonth]);

  return (
    <Stack
      gap={1}
      direction="row"
      flexWrap="wrap"
      justifyContent="space-between"
    >
      <Stack flex={1}>
        <CommitmentsPageHeaderMetadataCard
          primary="Real Cost"
          loading={loading}
          value={money(realCost)}
          tooltip={`Total costs paid for all resources eligible for Savings Plans including Savings Plan Recurring Fees.`}
        />
      </Stack>
      <Stack flex={1}>
        <CommitmentsPageHeaderMetadataCard
          primary="Not Covered (On-Demand)"
          loading={loading}
          value={money(uncoveredUsage)}
          tooltip={`Eligible On-Demand spend not covered by Savings Plans for date range ${datesFromTo}.`}
        />
      </Stack>

      <Stack flex={1}>
        <CommitmentsPageHeaderMetadataCard
          primary="Net Savings"
          loading={loading}
          value={money(netSavings)}
          tooltip={`Total net savings from commitments for the date range ${datesFromTo}. Calculated by comparing the usage cost covered by Savings Plans to the public On-Demand cost.`}
        />
      </Stack>
      <Stack flex={1}>
        <PotentialSavingsMetadata />
      </Stack>
    </Stack>
  );
};
