import { costAndUsageSumWidgetDataResponseSelector } from "./costAndUsageSumWidgetDataResponseSelector";
import { RootState } from "../../../../../store";

export const costAndUsageSumWidgetDataLoadingSelector = (
  state: RootState,
  widgetId: string,
) => {
  const response = costAndUsageSumWidgetDataResponseSelector(state, widgetId);

  return !!response?.isLoading;
};
