import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  CategoryFilter,
  cloudChiprApi,
} from "../../../../services/cloudchipr.api";

type Args = {
  dimensionId: string;
  categoryId: string;
  categoryName: string;
  filters: CategoryFilter;
};

export const getCategoryCreationFixedCacheKey = (id: string) =>
  `${id}-postUsersMeOrganisationsCurrentDimensionsByDimensionIdCategories-fixed-cache-key`;

export const createCategoryByDimensionIdThunk = createAsyncThunk(
  "dimensions/createCategoryByDimensionId",
  async (args: Args, { dispatch }) => {
    const { dimensionId, categoryName, filters, categoryId } = args;

    const { postUsersMeOrganisationsCurrentDimensionsByDimensionIdCategories } =
      cloudChiprApi.endpoints;

    return await dispatch(
      postUsersMeOrganisationsCurrentDimensionsByDimensionIdCategories.initiate(
        {
          dimensionId,
          body: { name: categoryName, filter: filters },
        },
        { fixedCacheKey: getCategoryCreationFixedCacheKey(categoryId) },
      ),
    ).unwrap();
  },
);
