import { FC, Fragment } from "react";
import { Chip, Popover, Stack } from "@mui/material";
import { useMenuHook } from "../../../../../../../../../../utils/hooks/useMenu.hook";

interface NotificationPreviewItemProps {
  firstItem?: string;
  restItems?: string[];
}

export const NotificationPreviewItem: FC<NotificationPreviewItemProps> = ({
  firstItem,
  restItems,
}) => {
  const { anchor, open, openMenu, closeMenu } = useMenuHook();

  if (!firstItem) {
    return null;
  }

  return (
    <Stack direction="row" spacing={1} alignItems="center">
      <Chip variant="outlined" size="small" label={firstItem} />

      {!!restItems?.length && (
        <Fragment>
          <Chip
            variant="outlined"
            size="small"
            onClick={openMenu}
            label={`+${restItems.length}`}
          />

          <Popover
            open={open}
            anchorEl={anchor}
            onClose={closeMenu}
            transformOrigin={{
              horizontal: "left",
              vertical: "top",
            }}
            anchorOrigin={{
              horizontal: "left",
              vertical: "bottom",
            }}
          >
            <Stack p={2} spacing={1} maxHeight={300} alignItems="start">
              {restItems.map((metadata) => (
                <Chip
                  key={metadata}
                  variant="outlined"
                  size="small"
                  label={metadata}
                />
              ))}
            </Stack>
          </Popover>
        </Fragment>
      )}
    </Stack>
  );
};
