import { FC, Fragment } from "react";
import { Card, CardContent, Container, Grid } from "@mui/material";
import { AuthMFA } from "./components/mfa/AuthMFA";
import { AuthSSO } from "./components/sso/AuthSSO";
import { useAppAbility } from "../../../../../services/permissions";
import { PageHeader } from "../../../common/PageHeader";

export const AuthAndSSO: FC = () => {
  const { can } = useAppAbility();

  return (
    <Fragment>
      <PageHeader title="Account Security" />

      <Container sx={{ p: 2 }} disableGutters maxWidth={false}>
        <Card variant="outlined">
          <CardContent>
            <Grid container justifyContent="center">
              <Grid item xs={12} md={10} lg={6} pb={5}>
                <AuthMFA />

                {can("create", "sso") && <AuthSSO />}
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Container>
    </Fragment>
  );
};
