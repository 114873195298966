import { createAsyncThunk } from "@reduxjs/toolkit";
import { cloudChiprApi } from "../../../services/cloudchipr.api";
import { RootState } from "../../store";
import { liveUsageMgmtProviderSelector } from "../selectors/store-selectors/liveUsageMgmtProviderSelector";
import { liveUsageMgmtAccountIdsSelector } from "../selectors/store-selectors/liveUsageMgmtAccountIdsSelector";

export const getLiveUsageMgmtAccountsVisibilitiesThunk = createAsyncThunk(
  "liveUsageMgmt/getLiveUsageMgmtAccountsVisibilities",
  async (_, { getState, dispatch }) => {
    const state = getState() as RootState;

    const provider = liveUsageMgmtProviderSelector(state);
    const accountsIds = liveUsageMgmtAccountIdsSelector(state);

    if (!accountsIds || !provider) {
      return;
    }

    await dispatch(
      cloudChiprApi.endpoints.getUsersMeProvidersByProviderAccountsResourcesVisibilities.initiate(
        {
          provider,
          accountIds: accountsIds.join(","),
        },
      ),
    );
  },
);
