import { Area } from "recharts";
import { ChartColorsType } from "../../../utils/types/types";

export const areaChartAreas = ({
  keys,
  colors,
  highlighted,
}: {
  keys: string[];
  highlighted: string;
  colors: ChartColorsType;
}) => {
  return keys.map((key) => {
    const filter = highlighted === key ? "none" : "grayscale(1)";
    const opacity = highlighted === key ? 1 : 0.2;

    return (
      <Area
        key={key}
        type="linear"
        dataKey={key}
        fill={colors[key]}
        filter={highlighted ? filter : "none"}
        opacity={highlighted ? opacity : "none"}
        strokeWidth={2}
        stroke={colors[key]}
      />
    );
  });
};
