import React, { FC } from "react";
import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query/react";
import { SerializedError } from "@reduxjs/toolkit";
import { Stack } from "@mui/material";
import { useDidMount } from "rooks";
import { generateCostAndUsageSumDataGridData } from "../../../../../store/dashboards/selectors/widgets/cost-and-usage/utils/helpers/generateCostAndUsageSumDataGridData";
import {
  DashboardWidgetCostAndUsageSummary,
  GetUsersMeOrganisationsCurrentWidgetsCostAndUsageSummaryByWidgetIdApiResponse,
} from "../../../../../services/cloudchipr.api";
import { UnauthorizedWidgetHeader } from "../UnauthorizedWidgetHeader";
import { DashboardNotFoundWidget } from "../../../../pages/dashboard/components/widgets/common/DashboardNotFoundWidget";
import { WidgetNumeralView } from "../../../../pages/dashboard/components/adding-widget/widget-create/widget-setups/common/view-type/WidgetNumeralView";
import { getTrendTooltipTitle } from "../../../../pages/dashboard/utils/helpers/trend-tooltip-title/getTrendTooltipTitle";
import { WidgetNumeralViewItem } from "../../../../pages/dashboard/components/adding-widget/widget-create/widget-setups/common/view-type/WidgetNumeralViewItem";
import { CostAndUsageSumLiveFilteredTitle } from "../../../../pages/dashboard/components/adding-widget/widget-create/widget-setups/cost-and-usage/content/CostAndUsageSumLiveFilteredTitle";

interface UnauthorizedDashboardCostAndUsageSumWidgetProps
  extends DashboardWidgetCostAndUsageSummary {
  data?: GetUsersMeOrganisationsCurrentWidgetsCostAndUsageSummaryByWidgetIdApiResponse;
  isError: boolean;
  error?: FetchBaseQueryError | SerializedError;
  onWidgetMount?(): void;
}

export const UnauthorizedDashboardCostAndUsageSumWidget: FC<
  UnauthorizedDashboardCostAndUsageSumWidgetProps
> = ({ data, error, isError, name, date_label, dates, onWidgetMount }) => {
  const status = error && "status" in error ? error?.status : 0;
  const gridData = data ? generateCostAndUsageSumDataGridData(data) : null;

  useDidMount(() => {
    onWidgetMount?.();
  });

  if (isError && status !== 404) {
    return;
  }

  return (
    <Stack flexGrow={1} overflow="auto">
      <UnauthorizedWidgetHeader name={name} dates={dates} />

      {status === 404 && (
        <DashboardNotFoundWidget name="" label="resource explorer view" />
      )}

      {status !== 404 && (
        <WidgetNumeralView loading={false} skeletonCount={6}>
          {gridData?.map((item) => {
            const tooltipTitle = getTrendTooltipTitle(
              item.trendDetails?.currentDates?.from,
              item.trendDetails?.currentDates?.to,
              item.trendDetails?.previousDates?.from,
              item.trendDetails?.previousDates?.to,
              item?.costAndUsageSumWidgetFilterType,
              date_label,
            );

            return (
              <WidgetNumeralViewItem
                key={item.costAndUsageSumWidgetFilterType}
                label={item.label}
                CustomLabel={
                  item.costAndUsageSumWidgetFilterType ===
                  "live_usage_total_cost"
                    ? CostAndUsageSumLiveFilteredTitle
                    : undefined
                }
                cost={item.cost}
                postfix={
                  item.costAndUsageSumWidgetFilterType ===
                  "live_usage_total_cost"
                    ? "/mo"
                    : undefined
                }
                trend={
                  item.trendDetails?.trend ? item.trendDetails.trend : null
                }
                itemsCount={gridData?.length}
                tooltipTitle={tooltipTitle}
                approximate={
                  item.costAndUsageSumWidgetFilterType ===
                    "forecasted_month_end_cost" ||
                  item.costAndUsageSumWidgetFilterType ===
                    "forecasted_quarter_end_cost"
                }
              />
            );
          })}
        </WidgetNumeralView>
      )}
    </Stack>
  );
};
