import { Dispatch, FC, SetStateAction, useCallback } from "react";
import { Grid, IconButton, Stack } from "@mui/material";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import { TagFilterDropdownSelectKeys } from "./TagFilterDropdownSelectKeys";
import { TagFilterDropdownSelectValues } from "./TagFilterDropdownSelectValues";
import { TagFilterCombinedOperator } from "./TagFilterCombinedOperator";
import { TagFilterDropdownSelectOperator } from "./TagFilterDropdownSelectOperator";
import { TagFilter, TagsFilter } from "../utils/types";
import { tagsFilterDefaultCombinedOperator } from "../utils/constants";
import { generateFilterAfterTagFiltersOperatorChange } from "../utils/helpers/generateFilterAfterTagFiltersOperatorChange";
import { generateFilterAfterTagFiltersValuesChange } from "../utils/helpers/generateFilterAfterTagFiltersValuesChange";
import { generateFilterAfterTagFiltersKeyChange } from "../utils/helpers/generateFilterAfterTagFiltersKeyChange";

interface TagFilterDropdownSelectItemProps {
  valuesOptions?: {
    value: string;
    title?: string;
  }[];
  keysOptions: {
    value: string;
    title?: string;
  }[];
  index: number;
  filter?: TagFilter;
  setFilter: Dispatch<SetStateAction<TagsFilter | null>>;
  alreadyFilteredTagKeys: string[];
  combinedOperator: TagsFilter["combinationOperator"];
}

export const TagFilterDropdownSelectItem: FC<
  TagFilterDropdownSelectItemProps
> = ({
  valuesOptions,
  keysOptions,
  index,
  filter,
  setFilter,
  alreadyFilteredTagKeys,
  combinedOperator,
}) => {
  const operatorChangeHandler = useCallback(
    (operator: any) => {
      setFilter((prevFilter) => {
        if (!prevFilter) {
          return null;
        }
        return generateFilterAfterTagFiltersOperatorChange(
          operator,
          prevFilter,
          index,
        );
      });
    },
    [index, setFilter],
  );

  const tagValuesChangeHandler = useCallback(
    (values: string[] | string) => {
      setFilter((prevFilter) => {
        if (!prevFilter) {
          return null;
        }
        return generateFilterAfterTagFiltersValuesChange(
          values,
          prevFilter,
          index,
        );
      });
    },
    [setFilter, index],
  );

  const tagKeysChangeHandler = useCallback(
    (key: string) => {
      setFilter((prevFilter) => {
        if (!prevFilter) {
          return null;
        }

        return generateFilterAfterTagFiltersKeyChange(key, prevFilter, index);
      });
    },
    [index, setFilter],
  );

  const removeTagClickHandler = useCallback(() => {
    setFilter((filter) => {
      if (!filter) {
        return null;
      }

      const data = filter?.value.filter((item, i) => i !== index);
      return {
        ...filter,
        value: data,
      };
    });
  }, [setFilter, index]);

  const valuesFilterHidden = ["exists", "does_not_exist"].includes(
    filter?.operator ?? "",
  );

  const combinedOperatorChangeHandler = useCallback(() => {
    setFilter((prevFilter) => {
      if (!prevFilter) {
        return null;
      }
      return {
        ...prevFilter,
        combinationOperator:
          prevFilter.combinationOperator === "and" ? "or" : "and",
      };
    });
  }, [setFilter]);

  return (
    <Stack px={2}>
      {index > 0 && (
        <TagFilterCombinedOperator
          disabled={index > 1}
          operator={combinedOperator ?? tagsFilterDefaultCombinedOperator}
          onClick={combinedOperatorChangeHandler}
        />
      )}
      <Stack direction="row" spacing={1} pt={1.5}>
        <Grid container spacing={1} overflow="auto">
          <Grid item md={4.5}>
            <TagFilterDropdownSelectKeys
              options={keysOptions ?? []}
              onChange={tagKeysChangeHandler}
              alreadyFilteredTagKeys={alreadyFilteredTagKeys}
              filter={filter?.key}
            />
          </Grid>

          <Grid item md={valuesFilterHidden ? 7.5 : 3}>
            <TagFilterDropdownSelectOperator
              value={filter?.operator}
              onChange={operatorChangeHandler}
            />
          </Grid>

          {!valuesFilterHidden && (
            <Grid item md={4.5}>
              <TagFilterDropdownSelectValues
                options={valuesOptions ?? []}
                onChange={tagValuesChangeHandler}
                operator={filter?.operator}
                values={filter?.values ?? []}
                disabled={!filter?.key}
              />
            </Grid>
          )}
        </Grid>

        <IconButton onClick={removeTagClickHandler} size="small">
          <DeleteOutlinedIcon fontSize="small" />
        </IconButton>
      </Stack>
    </Stack>
  );
};
