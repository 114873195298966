import { FC, useCallback } from "react";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../store/hooks";
import { categorySaveHandlerThunk } from "../../../../../../../store/dimensions/thunks/categories/categorySaveHandlerThunk";
import { categoriesCreateDisabledSelector } from "../../../../../../../store/dimensions/selectors/options/categories/categoriesCreateDisabledSelector";
import { FormActions } from "../common/FormActions";

interface CategoryFormActionsProps {
  onClose(): void;
}

export const CategoryFormActions: FC<CategoryFormActionsProps> = ({
  onClose,
}) => {
  const dispatch = useAppDispatch();
  const disabled = useAppSelector(categoriesCreateDisabledSelector);

  const saveHandler = useCallback(async () => {
    const response = await dispatch(categorySaveHandlerThunk());

    if (response.payload) {
      onClose();
    }
  }, [dispatch, onClose]);

  return (
    <FormActions
      disabled={!!disabled}
      onSave={saveHandler}
      onCancel={onClose}
    />
  );
};
