import { IconButton, Stack, Tooltip } from "@mui/material";
import { Tune } from "@mui/icons-material";
import { RenderToolbar } from "../../../../../../../storybook/data-grid/utils/types/prop-types";
import { useMenuHook } from "../../../../../../../utils/hooks/useMenu.hook";
import { ColumnsSwitcher } from "../../../../../../../storybook/data-grid/toolbar/ColumnsSwitcher";

export const Toolbar: RenderToolbar<unknown> = ({ table }) => {
  const { anchor, openMenu, closeMenu, open } = useMenuHook();

  return (
    <Stack alignItems="end" p={1} borderBottom={1} borderColor="grey.300">
      <Tooltip arrow title="Table Settings" placement="top">
        <IconButton onClick={openMenu} size="small">
          <Tune fontSize="small" />
        </IconButton>
      </Tooltip>

      <ColumnsSwitcher
        open={open}
        onClose={closeMenu}
        anchor={anchor}
        table={table}
      />
    </Stack>
  );
};
