import { createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../../../store";
import { cloudChiprApi } from "../../../../services/cloudchipr.api";
import { getCostAnomalyPreviewRequestArgsSelector } from "../../selectors/cost-anomaly-alerts/getCostAnomalyPreviewRequestArgsSelector";

export const getCostAnomalyPreviewDataGridDataThunk = createAsyncThunk(
  "alerts/getCostAnomalyPreviewDataGridData",
  async (_, { dispatch, getState }) => {
    const state = getState() as RootState;
    const args = getCostAnomalyPreviewRequestArgsSelector(state);

    if (!args?.dateFrom || !args?.dateTo) {
      return null;
    }

    const {
      getUsersMeOrganisationsCurrentResourceExplorerViewsByResourceExplorerViewIdAverage,
    } = cloudChiprApi.endpoints;

    return dispatch(
      getUsersMeOrganisationsCurrentResourceExplorerViewsByResourceExplorerViewIdAverage.initiate(
        args,
      ),
    );
  },
);
