import moment from "moment/moment";
import { createCron } from "./createCron";

export const generateDailyCron = (day: number, date: string | null) => {
  const cron = createCron({
    dayOfMonth: day,
    hour: +moment(date).hours(),
    minute: +moment(date).minutes(),
  }).split(" ");

  cron[2] = "1/" + cron[2];
  cron[4] = "?";

  return cron.join(" ");
};
