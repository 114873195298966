import { FC } from "react";
import { LinearProgress } from "@mui/material";

interface ResourceExplorerBudgetProgressProps {
  progress: number;
}

export const ResourceExplorerBudgetProgress: FC<
  ResourceExplorerBudgetProgressProps
> = ({ progress }) => {
  return (
    <LinearProgress
      color="info"
      sx={{
        flexGrow: 1,
        height: 6,
        borderRadius: 1,
        bgcolor: "grey.100",
        "& .MuiLinearProgress-bar": {
          bgcolor: progress > 100 ? "red" : "primary",
        },
      }}
      variant="determinate"
      value={Math.min(progress, 100)}
    />
  );
};
