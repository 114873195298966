import { FC, Fragment } from "react";
import { Typography } from "@mui/material";
import ActiveInactiveAccountsEmptyStateImage from "../../../../../assets/images/active_inative_empty_accounts.svg";
import { ProviderType } from "../../../../../services/cloudchipr.api";
import { getProviderName } from "../../../../../utils/helpers/providers/getProviderName";

interface ActiveAccountsEmptyTemplateProps {
  provider: ProviderType;
}

export const ActiveAccountsEmptyTemplate: FC<
  ActiveAccountsEmptyTemplateProps
> = ({ provider }) => {
  const providerName = getProviderName(provider, { plural: true });
  return (
    <Fragment>
      <img src={ActiveInactiveAccountsEmptyStateImage} alt="EmptyStateImage" />

      <Typography
        component="h5"
        fontWeight="bold"
        variant="h5"
        textAlign="center"
        mt={6}
      >
        There are no active {providerName}
      </Typography>

      <Typography
        mt={2}
        mb={6}
        variant="h6"
        maxWidth={600}
        color="text.secondary"
        textAlign="center"
        fontWeight="normal"
      >
        To view your {providerName}, click on the "Inactive{" "}
        {getProviderName(provider, { plural: true, capitalize: true })}" tab and
        follow the required steps to reconnect any existing {providerName} or
        add a new one.
      </Typography>
    </Fragment>
  );
};
