import { FC, useCallback, useState } from "react";
import { CommitmentUtilizationTypeSelectTrigger } from "./CommitmentUtilizationTypeSelectTrigger";
import { DropdownSelect } from "../../../../../../../../../../common/select/dropdown-select/DropdownSelect";
import { CommitmentUtilizationType } from "../../../../../../../../../../../services/cloudchipr.api";
import { DropdownSelectOption } from "../../../../../../../../../../common/select/dropdown-select/utils/types/types";
import { commitmentTypeTitleByType } from "../../../utils/constants/constants";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../../../../store/hooks";
import { commitmentUtilizationSetupPropertyByKeySelector } from "../../../../../../../../../../../store/dashboards/selectors/setups/commitment-utilization/commitmentUtilizationSetupPropertyByKeySelector";
import { commitmentUtilizationWidgetSetupDataChangeThunk } from "../../../../../../../../../../../store/dashboards/thunks/widgets/commitment-utilization/setup-change/commitmentUtilizationWidgetSetupDataChangeThunk";

export const CommitmentUtilizationWidgetSetupTypeSelect: FC = () => {
  const dispatch = useAppDispatch();

  const commitmentType: CommitmentUtilizationType = useAppSelector(
    commitmentUtilizationSetupPropertyByKeySelector("commitmentType"),
  );
  const [commitmentTypes, setCommitmentTypes] = useState<
    CommitmentUtilizationType[]
  >(commitmentType === "all" ? allOption : [commitmentType]);

  const handleChange = useCallback(
    (value: CommitmentUtilizationType[]) => {
      if (!value?.length) {
        setCommitmentTypes([...allOption]);
        commitmentUtilizationWidgetSetupDataChangeThunk({
          commitmentType: "all",
        });
        return;
      }

      const commitmentType = value.length === 1 ? value.at(0) : "all";

      if (!commitmentType) {
        return;
      }

      setCommitmentTypes(value);
      dispatch(
        commitmentUtilizationWidgetSetupDataChangeThunk({ commitmentType }),
      );
    },
    [dispatch],
  );

  return (
    <DropdownSelect
      disableEmptySelectionList
      listWidth={200}
      label="Select Type"
      showSearch={false}
      options={options}
      values={commitmentTypes}
      submitHandlerOnClose={handleChange}
      TriggerComponent={CommitmentUtilizationTypeSelectTrigger}
    />
  );
};

const allOption: CommitmentUtilizationType[] = ["savings_plan", "reservation"];

const options: DropdownSelectOption[] = [
  {
    value: "savings_plan",
    label: commitmentTypeTitleByType.get("savings_plan"),
    hideOnly: true,
  },
  {
    value: "reservation",
    label: commitmentTypeTitleByType.get("reservation"),
    hideOnly: true,
  },
];
