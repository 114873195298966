import { createDraftSafeSelector } from "@reduxjs/toolkit";
import { cloudChiprApi } from "../../../../../services/cloudchipr.api";

const selector =
  cloudChiprApi.endpoints.getUsersMeOrganisationsCurrentRecommendationsStatus.select();

export const rightsizingRecommendationsStatusLoadingSelector =
  createDraftSafeSelector([selector], (response): boolean => {
    return !!response?.isLoading;
  });
