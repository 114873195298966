import { GetQuickFilterTextParams } from "@ag-grid-community/core/dist/types/src/entities/colDef";
import { Task } from "../../../../../../services/cloudchipr.api";
import { formatDate } from "../../../../../../utils/helpers/date-time/datetime-format";

export const taskCreationFilterText = (
  params: GetQuickFilterTextParams<Task>,
) => {
  const createdBy =
    params.data?.created_by?.name ?? params.data?.created_by?.email;
  const date = params.data?.created_at;
  const target = `${createdBy} ${formatDate(date, { type: "dateTime" })}`;

  return target.toLowerCase();
};
