import { LinearProgress, Stack } from "@mui/material";
import { Fragment } from "react";
import { CommitmentsOverviewRecommendationsDataGridViewTabs } from "./CommitmentsOverviewRecommendationsDataGridViewTabs";
import { commitmentsRecommendationsLoadingSelector } from "../../../../../store/commitments/selectors/recommendations/data/commitmentsRecommendationsLoadingSelector";
import { useAppSelector } from "../../../../../store/hooks";
import { RenderToolbar } from "../../../../../storybook/data-grid/utils/types/prop-types";
import { GlobalFilter } from "../../../../../storybook/data-grid/toolbar/GlobalFilter";
import { ColumnsShowHideSwitcher } from "../../common/components/data-grid/ColumnsShowHideSwitcher";
import { CommitmentsRecommendationsFilters } from "../../utilization-and-coverage/components/content/recommendations/filters/CommitmentsRecommendationsFilters";
import { CommitmentType } from "../../../../../services/cloudchipr.api";
import { commitmentsRecommendationsCSVDataByTypeSelector } from "../../../../../store/commitments/selectors/recommendations/common/commitmentsRecommendationsCSVDataByTypeSelector";
import { CommitmentsRecommendationsCsvDataDownloader } from "../../common/components/data-grid/CommitmentsRecommendationsCsvDataDownloader";

export const CommitmentsOverviewRecommendationsDataGridToolbar: RenderToolbar<{
  type: CommitmentType;
}> = ({ setGlobalFilter, globalFilter, table, type }) => {
  const loading = useAppSelector(commitmentsRecommendationsLoadingSelector);

  const csvData = useAppSelector((state) =>
    commitmentsRecommendationsCSVDataByTypeSelector(state, type),
  );
  return (
    <Fragment>
      <Stack pb={2}>
        <Stack
          pb={2}
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <CommitmentsOverviewRecommendationsDataGridViewTabs />
        </Stack>
        <Stack
          justifyContent="space-between"
          alignItems="center"
          direction="row"
          gap={2}
          flexWrap="wrap"
        >
          <CommitmentsRecommendationsFilters type={type} />
          <Stack alignItems="center" direction="row" spacing={1}>
            <GlobalFilter
              globalFilter={globalFilter}
              setGlobalFilter={setGlobalFilter}
              size="xsmall"
            />

            <CommitmentsRecommendationsCsvDataDownloader data={csvData} />
            <ColumnsShowHideSwitcher table={table} />
          </Stack>
        </Stack>
      </Stack>

      {loading && <LinearProgress />}
    </Fragment>
  );
};
