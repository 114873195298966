import { FC, useCallback } from "react";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../store/hooks";
import { dimensionCreateDisabledSelector } from "../../../../../../../store/dimensions/selectors/options/dimensions/dimensionCreateDisabledSelector";
import { dimensionSaveHandlerThunk } from "../../../../../../../store/dimensions/thunks/dimensions/dimensionSaveHandlerThunk";
import { FormActions } from "../common/FormActions";

interface DimensionFormActionsProps {
  onClose(): void;
}

export const DimensionFormActions: FC<DimensionFormActionsProps> = ({
  onClose,
}) => {
  const dispatch = useAppDispatch();
  const disabled = useAppSelector(dimensionCreateDisabledSelector);

  const saveHandler = useCallback(async () => {
    const response = await dispatch(dimensionSaveHandlerThunk());

    if (response.payload) {
      onClose();
    }
  }, [dispatch, onClose]);

  return (
    <FormActions disabled={disabled} onSave={saveHandler} onCancel={onClose} />
  );
};
