import { FC } from "react";
import { Divider, Stack } from "@mui/material";

import { EmailActionExecutedSubject } from "../../../email/action-executed/EmailActionExecutedSubject";
import { EmailActionExecutedWorkflowInfo } from "../../../email/action-executed/EmailActionExecutedWorkflowInfo";
import { IntegrationCustomMessageDataGrid } from "../../../common/IntegrationCustomMessageDataGrid";
import { EmailIntegrationCsvInfo } from "../../../email/common/EmailIntegrationCsvInfo";
import { integrationCustomEmailActionExecuteDataGrid } from "../../../email/utils/constants";
import { CreateIntegrationMessageEditor } from "../../../../../../../../common/integration-dialogs/components/common/CreateIntegrationMessageEditor";

interface EmailActionExecutedDialogContentProps {
  customMessage: string;
  onCustomMessageChange(message: string): void;
}

export const EmailActionExecutedDialogContent: FC<
  EmailActionExecutedDialogContentProps
> = ({ customMessage, onCustomMessageChange }) => {
  return (
    <Stack spacing={1.5}>
      <EmailActionExecutedSubject />
      <Divider />
      <EmailActionExecutedWorkflowInfo />
      <Divider />

      <CreateIntegrationMessageEditor
        value={customMessage ?? ""}
        onChange={onCustomMessageChange}
        placeholder="Write a custom message..."
      />
      <Divider />
      <IntegrationCustomMessageDataGrid
        data={integrationCustomEmailActionExecuteDataGrid}
        label="Account Name / ID"
      />
      <EmailIntegrationCsvInfo />
    </Stack>
  );
};
