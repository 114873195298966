import { ColumnPinningState } from "@ag-grid-community/core/dist/types/src/interfaces/gridState";
import { workflowsTableColumns } from "../../components/grid-options/workflowsTableColumns";

export const workflowsPinnedColumns = workflowsTableColumns.reduce(
  (acc, column) => {
    if (!column.field && !column.colId) {
      return acc;
    }

    if (column.pinned === "right") {
      acc.rightColIds.push(column.colId ?? column.field ?? "");
    } else if (column.pinned === "left") {
      acc.leftColIds.push(column.colId ?? column.field ?? "");
    }

    return acc;
  },
  {
    leftColIds: [],
    rightColIds: [],
  } as ColumnPinningState,
);
