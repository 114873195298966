import { FC, Fragment } from "react";

interface ReservationCellProps {
  data: number | null;
  reservation: "rcu" | "wcu";
}

export const ReservationCell: FC<ReservationCellProps> = ({
  reservation,
  data,
}) => {
  if (data === null) {
    return <Fragment>-</Fragment>;
  }

  return (
    <Fragment>
      {data} {reservation.toUpperCase()}
    </Fragment>
  );
};
