import { commitmentUtilizationWidgetVisualizationSelector } from "./commitmentUtilizationWidgetVisualizationSelector";
import { RootState } from "../../../../../store";

export const commitmentUtilizationWidgetVisualizationChartTypeSelector = (
  state: RootState,
  widgetId: string,
) => {
  return commitmentUtilizationWidgetVisualizationSelector(state, widgetId)
    ?.chart_type;
};
