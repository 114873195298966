import { recommendationsDataGridFilteredDataSelector } from "./recommendationsDataGridFilteredDataSelector";
import { recommendationsDataGrdDismissedFilteredDataSelector } from "./recommendationsDataGrdDismissedFilteredDataSelector";
import { RootState } from "../../../../store";
import { RecommendationGroupType } from "../../../../../components/pages/recommendations/components/off-hours-recommendations/utils/types/types";
import { getResourceTypeName } from "../../../../../utils/helpers/resources/getResourceTypeName";
import { Tag } from "../../../../../services/cloudchipr.api";
import { money } from "../../../../../utils/numeral/money";
import { capitalizeString } from "../../../../../utils/helpers/capitalizeString";

export const offHoursCsvExportDataSelector = (
  state: RootState,
  group: RecommendationGroupType,
  active: boolean,
) => {
  const activeData = recommendationsDataGridFilteredDataSelector(state, group);
  const recommendationsDismissedData =
    recommendationsDataGrdDismissedFilteredDataSelector(state, group);

  const recommendations = active ? activeData : recommendationsDismissedData;

  if (!recommendations) {
    return null;
  }

  return recommendations.map((r) => {
    return {
      "Service Type": getResourceTypeName(r.resource_type, {
        singular: true,
        type: "long",
      }),
      "Account ID": r.account.provider_account_id,
      "Account Name": r.account.provider_account_name,
      "Resource Type": r.type,
      Name: r.tags?.find(({ key }) => key === "Name")?.value,
      Region: r.region,
      "Potential Monthly Saving": r.potential_saving,
      Frequency: capitalizeString(r.frequency),
      "Stop Day (UTC +0)": r.from_day,
      "Stop time (UTC +0)": r.from_hour + ":00",
      "Start Day (UTC +0)": r.to_day,
      "Start time (UTC +0)": r.to_hour + ":00",
      TAGs: r.tags?.map((tag: Tag) => `${tag?.key}:${tag?.value}`).join(" , "),
      Comment:
        r.frequency === "daily"
          ? `Stop this resource every day at ${r.from_hour}:00 and start at ${
              r.to_hour
            }:00. Potential monthly saving - ${money(r.potential_saving)}.`
          : `Stop this resource every ${r.from_day} at ${
              r.from_hour
            }:00 and start every ${r.to_day} at ${
              r.to_hour
            }:00. Potential monthly saving - ${money(r.potential_saving)}.`,
    };
  });
};
