import { FC, Fragment, useCallback } from "react";
import { Table } from "@tanstack/react-table";
import { Theme } from "@mui/material";
import { executionLogsTableColumns } from "./executionLogsDataGridColumns";
import { ExecutionLogsSubRowTable } from "./sub-row/ExecutionLogsSubRowTable";
import { ExecutionLogsDataGridPagination } from "./ExecutionLogsDataGridPagination";
import { useAppSelector } from "../../../../../store/hooks";
import { DataGrid } from "../../../../../storybook/data-grid/DataGrid";
import { DataGridProvider } from "../../../../../storybook/data-grid/DataGridProvider";
import { executionLogDataSelector } from "../../../../../store/execution-log/selectors/executionLogDataSelector";
import { ExecutionLogsEmptyState } from "../ExecutionLogsEmptyState";
import { expandRowAfterFirstRender } from "../../../../../utils/helpers/expandRowAfterFirstRender";

interface ExecutionLogsDataGridProps {
  executionLogIds?: string[];
}

export const ExecutionLogsDataGrid: FC<ExecutionLogsDataGridProps> = ({
  executionLogIds,
}) => {
  const data = useAppSelector(executionLogDataSelector);

  const afterRenderCBFn = useCallback(
    (table: Table<any>) => {
      expandRowAfterFirstRender(table, executionLogIds);
    },
    [executionLogIds],
  );

  if (!data) {
    return null;
  }

  return (
    <Fragment>
      <DataGridProvider>
        <DataGrid
          afterFirstRenderCallback={
            executionLogIds?.length ? afterRenderCBFn : undefined
          }
          columnSorting={!!data.items?.length}
          styles={styles}
          rowExpanding={{
            parentRowStyles: { "& > td": { p: 1 } },
            renderExpandedRowSubRow: (row) => (
              <ExecutionLogsSubRowTable response={row.original.response} />
            ),
          }}
          data={data.items ?? []}
          columns={executionLogsTableColumns}
        />
      </DataGridProvider>

      {!data.items?.length && <ExecutionLogsEmptyState />}

      <ExecutionLogsDataGridPagination />
    </Fragment>
  );
};

const styles = {
  table: {
    tableLayout: "fixed",
  },
  tableHeaderRow: {
    bgcolor: "grey.200",
    "& th": { py: 2 },
    top: 0,
    position: "sticky",
    zIndex: (theme: Theme) => theme.zIndex.appBar - 1,
  },
  tableContainer: { border: "1px solid", borderColor: "grey.300" },
};
