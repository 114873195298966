import { getCostAnomalyPreviewRequestArgsSelector } from "./getCostAnomalyPreviewRequestArgsSelector";
import { getCostAnomalyPreviewDataGridDataSelector } from "./getCostAnomalyPreviewDataGridDataSelector";
import { RootState } from "../../../store";

export const getCostAnomalyPreviewDataGridDataLoadingSelector = (
  state: RootState,
) => {
  const args = getCostAnomalyPreviewRequestArgsSelector(state);

  if (!args) {
    return false;
  }

  return getCostAnomalyPreviewDataGridDataSelector(args)(state)?.isLoading;
};
