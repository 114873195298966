import { resourceExplorerHierarchyVisibilitiesDataSelector } from "./resourceExplorerHierarchyVisibilitiesDataSelector";
import { currentResourceExplorerHierarchyVisibilitySelector } from "./currentResourceExplorerHierarchyVisibilitySelector";
import { RootState } from "../../../store";
import {
  ReFolderV2,
  ResourceExplorer,
} from "../../../../services/cloudchipr.api";

export const currentResourceExplorerHierarchyVisibilitiesDataSelector = (
  state: RootState,
): (ResourceExplorer | ReFolderV2)[] => {
  const data = resourceExplorerHierarchyVisibilitiesDataSelector(state);
  const visibility = currentResourceExplorerHierarchyVisibilitySelector(state);
  const privateData = data?.visible_only_to_me ?? [];
  const publicData = data?.visible_to_everyone ?? [];

  return visibility === "visible_only_to_me" ? privateData : publicData;
};
