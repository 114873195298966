import { ResourceType } from "../../../../../../../../../../../../services/cloudchipr.api";

export const nestedResourcesExcludedRowsByParentDataGenerator = (
  resourceType: ResourceType,
  data: RdsSnapshotSourceExcludedColumnsArgs,
): string[] => {
  if (excludedRows[resourceType]) {
    return excludedRows[resourceType]?.(data) ?? [];
  }

  return [];
};

interface RdsSnapshotSourceExcludedColumnsArgs {
  isAurora: boolean;
}
const rdsSnapshotSourceExcludedColumns = ({
  isAurora,
}: RdsSnapshotSourceExcludedColumnsArgs): string[] => {
  if (!isAurora) {
    return ["price_per_month", "billable"];
  }

  return ["storage_type"];
};

const excludedRows: Partial<
  Record<ResourceType, (data: RdsSnapshotSourceExcludedColumnsArgs) => string[]>
> = {
  rds_snapshot_source: rdsSnapshotSourceExcludedColumns,
};
