import { Button, Stack } from "@mui/material";
import { FC } from "react";
import AddIcon from "@mui/icons-material/Add";

import { AlertsCostsAnomalyThresholdsOperator } from "./AlertsCostsAnomalyThresholdsOperator";
import { AlertsCostsAnomalyThresholdItem } from "./AlertsCostsAnomalyThresholdItem";
import {
  AlertThresholdOperatorType,
  CostAnomalyAlertUnitType,
} from "../../../../../../../../../../store/alerts/utils/types/types";

interface AlertsCostsAnomalyThresholdItemWithActionsProps {
  unit: CostAnomalyAlertUnitType;
  amount: string;
  onUnitChange(value: CostAnomalyAlertUnitType, index: number): void;
  onAmountChange(value: string, index: number): void;
  index: number;
  onRemove?(index: number): void;
  onAddThresholdClick(): void;
  onOperatorChange(operator: AlertThresholdOperatorType): void;
  operator: AlertThresholdOperatorType;
  count: number;
  optionDisabled: boolean;
}

export const AlertsCostsAnomalyThresholdItemWithActions: FC<
  AlertsCostsAnomalyThresholdItemWithActionsProps
> = ({
  unit,
  onUnitChange,
  index,
  onAmountChange,
  amount,
  onRemove,
  count,
  onAddThresholdClick,
  onOperatorChange,
  operator,
  optionDisabled,
}) => {
  return (
    <Stack spacing={1.5}>
      <AlertsCostsAnomalyThresholdItem
        unit={unit}
        amount={amount}
        onUnitChange={onUnitChange}
        onAmountChange={onAmountChange}
        index={index}
        onRemove={onRemove}
        optionDisabled={optionDisabled}
      />
      {count < thresholdsMaxCount && (
        <Button
          variant="outlined"
          sx={{
            width: "fit-content",
          }}
          startIcon={<AddIcon fontSize="small" />}
          onClick={onAddThresholdClick}
        >
          Add threshold
        </Button>
      )}

      {index < count - 1 && (
        <AlertsCostsAnomalyThresholdsOperator
          operator={operator}
          onChange={onOperatorChange}
        />
      )}
    </Stack>
  );
};

const thresholdsMaxCount = 2;
