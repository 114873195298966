import { createAsyncThunk } from "@reduxjs/toolkit";
import { RecommendationGroupType } from "../../../../../../components/pages/recommendations/components/off-hours-recommendations/utils/types/types";
import { cloudChiprApi } from "../../../../../../services/cloudchipr.api";

export const getOffHoursDismissedResourcesByGroupThunk = createAsyncThunk(
  "offHoursRecommendations/getOffHoursDismissedResourcesByGroupThunk",
  async (group: RecommendationGroupType, { dispatch }) => {
    const { getUsersMeOrganisationsCurrentDismissedResources } =
      cloudChiprApi.endpoints;

    return await dispatch(
      getUsersMeOrganisationsCurrentDismissedResources.initiate(
        {
          group,
          recommendationType: "off_hours",
        },
        { forceRefetch: true },
      ),
    ).unwrap();
  },
);
