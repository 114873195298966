import { createDraftSafeSelector } from "@reduxjs/toolkit";
import { v4 as uuid } from "uuid";
import { reservationsRecommendationsDataSelector } from "./reservationsRecommendationsDataSelector";
import { ReservationRecommendation } from "../../../../../services/cloudchipr.api";
import { commitmentsRecommendationsFiltersSelector } from "../filters/commitmentsRecommendationsFiltersSelector";
import { filterReservationsRecommendationsByFilters } from "../../../utils/helpers/filterReservationsRecommendationsByFilters";

export const reservationsRecommendationsFilteredDataSelector =
  createDraftSafeSelector(
    [
      reservationsRecommendationsDataSelector,
      commitmentsRecommendationsFiltersSelector,
    ],
    (
      data,
      filters,
    ): (ReservationRecommendation & { id: string })[] | undefined => {
      return filterReservationsRecommendationsByFilters(
        filters,
        data?.map((rec) => ({ ...rec, id: uuid() })),
      );
    },
  );
