import { FC, Fragment, useMemo } from "react";
import { Box, Skeleton } from "@mui/material";
import { getNumeralViewItemsPaddings } from "./utils/helpers/getNumeralViewItemsPaddings";

interface WidgetNumeralViewLoaderProps {
  count: number;
  width?: string;
}
export const WidgetNumeralViewLoader: FC<WidgetNumeralViewLoaderProps> = ({
  count,
  width = "50%",
}) => {
  const loader = useMemo(() => {
    const elements = [];
    for (let i = 0; i < count; ++i) {
      elements.push(
        <Box
          key={i}
          p={getNumeralViewItemsPaddings(count)}
          flexBasis={width}
          flexGrow="1"
          boxSizing="border-box"
        >
          <Skeleton variant="rectangular" height="100%" width="100%" />
        </Box>,
      );
    }
    return elements;
  }, [count, width]);

  return <Fragment>{loader}</Fragment>;
};
