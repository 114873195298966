import { Account } from "../../../services/cloudchipr.api";
import { AccountsGroupedByProvider } from "../utils/types/types";

export const accountsGroupedByProviderCombiner = (
  accounts: Account[] | null,
): AccountsGroupedByProvider | null => {
  if (!accounts) {
    return null;
  }

  return accounts.reduce(
    (acc, currentValue) => {
      acc?.[currentValue.type]?.push(currentValue);
      return acc;
    },
    {
      aws: [],
      gcp: [],
      azure: [],
    } as AccountsGroupedByProvider,
  );
};
