import { scheduleResourcesSelector } from "./scheduleResourcesSelector";
import { RootState } from "../../../../store";
import { ResourceType } from "../../../../../services/cloudchipr.api";

export const scheduleResourcesCountSelector = (
  state: RootState,
  resourceType: ResourceType,
  scheduleId: string,
): number => {
  const response = scheduleResourcesSelector(state, resourceType, scheduleId);

  return response?.data?.resources?.length ?? 0;
};
