import { FC } from "react";
import { Box } from "@mui/material";

interface CubeProps {
  color?: string;
  border?: string;
  radius?: number;
  width?: number;
  height?: number | string;
}

export const Cube: FC<CubeProps> = ({
  color,
  border,
  radius = 0.5,
  width = 8,
  height = 8,
}) => {
  return (
    <Box
      component="span"
      mr={0.5}
      width={width}
      height={height}
      bgcolor={color}
      display="inline-block"
      borderRadius={radius}
      border={border}
      sx={{ flexShrink: 0 }}
    />
  );
};
