import { commitmentUtilizationWidgetByWidgetIdDataSelector } from "./commitmentUtilizationWidgetByWidgetIdDataSelector";
import { commitmentUtilizationWidgetVisualizationChartBaseSelector } from "./commitmentUtilizationWidgetVisualizationChartBaseSelector";
import { RootState } from "../../../../../store";
import { generateCommitmentUtilizationWidgetChartData } from "../utils/helpers/generateCommitmentUtilizationWidgetChartData";

export const commitmentUtilizationWidgetChartDataSelector = (
  state: RootState,
  widgetId: string,
) => {
  const data = commitmentUtilizationWidgetByWidgetIdDataSelector(
    state,
    widgetId,
  );
  const { groups } = data ?? {};
  const chartBase = commitmentUtilizationWidgetVisualizationChartBaseSelector(
    state,
    widgetId,
  );

  return generateCommitmentUtilizationWidgetChartData(groups, chartBase);
};
