import { createAsyncThunk } from "@reduxjs/toolkit";
import { cloudChiprApi } from "../../../../services/cloudchipr.api";

export const getOrganisationFilterSetsThunk = createAsyncThunk(
  "filter/getOrganisationFilterSets",
  async (_: void, { dispatch }) => {
    const { getUsersMeOrganisationsCurrentFilterSets } =
      cloudChiprApi.endpoints;

    await dispatch(
      getUsersMeOrganisationsCurrentFilterSets.initiate(undefined, {
        forceRefetch: true,
      }),
    );
  },
);
