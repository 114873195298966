import { FC, memo, useMemo } from "react";
import { Theme } from "@mui/material";
import { getCleanDialogGridColumns } from "./cleanV2DialogGridColumns";
import { DataGrid } from "../../../../../../../../storybook/data-grid/DataGrid";
import { ResourceType } from "../../../../../../../../services/cloudchipr.api";
import { CleanResourcesDataType } from "../../../utils/types";

interface CleanV2ResourceGridProps {
  resourceType: ResourceType;
  data: CleanResourcesDataType;
}

export const CleanV2ResourceGrid: FC<CleanV2ResourceGridProps> = memo(
  ({ resourceType, data }) => {
    const columns = useMemo(
      () => getCleanDialogGridColumns(resourceType),
      [resourceType],
    );

    if (!data) {
      return null;
    }

    return (
      <DataGrid
        toolbar
        data={data}
        styles={styles}
        columns={columns}
        columnResizeMode="onEnd"
        columnSorting={sortingConfig}
      />
    );
  },
);

const sortingConfig = { initialSort: [{ id: "price_per_month", desc: true }] };
const styles = {
  tableHeaderRow: {
    top: 0,
    position: "sticky",
    bgcolor: "grey.200",
    zIndex: (theme: Theme) => theme.zIndex.appBar - 1,
  },
  tableContainer: {
    maxHeight: 320,
    "& td": { py: 1 },
    borderLeft: 1,
    borderRight: 1,
    borderColor: "grey.300",
  },
  tableRow: {
    bgcolor: "white",
  },
};
