import { createDraftSafeSelector } from "@reduxjs/toolkit";
import { resourceExplorerHierarchyVisibilitiesDataSelector } from "./resourceExplorerHierarchyVisibilitiesDataSelector";
import { VisibilityType } from "../../../../components/pages/common/hierarchy-all-items/utils/types/types";
import { ReMixedVisibilitiesItemType } from "../../../../components/pages/resource-explorer-all-views/components/table/utils/types/types";

export const resourceExplorerHierarchyMixedVisibilitiesDataSelector =
  createDraftSafeSelector(
    [resourceExplorerHierarchyVisibilitiesDataSelector],
    (data): ReMixedVisibilitiesItemType[] | null => {
      if (!data) {
        return null;
      }

      return [
        ...(data?.visible_to_everyone?.map((view) => ({
          ...view,
          visibility: "visible_to_everyone" as VisibilityType,
        })) ?? []),
        ...(data?.visible_only_to_me?.map((view) => ({
          ...view,
          visibility: "visible_only_to_me" as VisibilityType,
        })) ?? []),
      ];
    },
  );
