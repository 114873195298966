import { FC, Fragment, useMemo } from "react";
import { Chip, Popover, PopoverOrigin, Stack } from "@mui/material";
import { NatGatewayIdCellPopoverContent } from "./NatGatewayIdCellPopoverContent";
import { useMenuHook } from "../../../../../../../../../../../utils/hooks/useMenu.hook";
import { TypographyWithTooltip } from "../../../../../../../../../../common/TypographyWithTooltip";

interface NatGatewayIdCellProps {
  data: string[];
  columnId: string;
}

export const NatGatewayIdCell: FC<NatGatewayIdCellProps> = ({
  data,
  columnId,
}) => {
  const { anchor, open, openMenu, closeMenu } = useMenuHook();

  const secondaryValues = useMemo(() => {
    if (!data) {
      return [];
    }
    return data?.slice(1);
  }, [data]);

  if (!data?.length) {
    return <div>N/A</div>;
  }

  return (
    <Stack direction="row" alignItems="center" spacing={1}>
      <TypographyWithTooltip title={data[0]} />

      {secondaryValues?.length > 1 && (
        <Fragment>
          <Chip
            label={`+${secondaryValues.length}`}
            onClick={openMenu}
            variant="outlined"
            size="small"
            sx={{ ml: 0.5 }}
          />
          <Popover
            open={open}
            anchorEl={anchor}
            onClose={closeMenu}
            transformOrigin={transformOrigin}
            anchorOrigin={anchorOrigin}
            PaperProps={paperProps}
          >
            <NatGatewayIdCellPopoverContent
              primaryValue={data[0]}
              secondaryValues={secondaryValues}
              columnKey={columnId.split(".")[1]}
            />
          </Popover>
        </Fragment>
      )}
    </Stack>
  );
};

const transformOrigin: PopoverOrigin = {
  horizontal: "right",
  vertical: "top",
};
const anchorOrigin: PopoverOrigin = {
  horizontal: "center",
  vertical: "bottom",
};
const paperProps = {
  style: { maxHeight: 400, minWidth: 220, maxWidth: 400 },
};
