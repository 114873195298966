import {
  cloudChiprApi,
  GetUsersMeOrganisationsCurrentTasksPropertiesApiResponse,
} from "../../../../services/cloudchipr.api";
import { RootState } from "../../../store";

export const getUsersMeOrganisationsCurrentTasksPropertiesSelector =
  cloudChiprApi.endpoints.getUsersMeOrganisationsCurrentTasksProperties.select();

export const taskAvailablePropertiesSelector = (
  state: RootState,
): GetUsersMeOrganisationsCurrentTasksPropertiesApiResponse | undefined => {
  return getUsersMeOrganisationsCurrentTasksPropertiesSelector(state)?.data;
};
