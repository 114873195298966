import { Layout, Layouts } from "react-grid-layout";

export const isLayoutsEqual = (firstLayout: Layouts, secondLayout: Layouts) => {
  return Object.entries(firstLayout)
    .map(([breakpoint, layout]) =>
      compareLayouts(layout, secondLayout[breakpoint]),
    )
    .every((res) => res);
};

const compareLayouts = (a: Layout[], b: Layout[]) => {
  if (a.length !== b.length) {
    return false;
  }

  const first = a.map(getPropsFromLayout).join(", ");
  const second = b.map(getPropsFromLayout).join(", ");

  return first === second;
};

const getPropsFromLayout = (layout: Layout) =>
  `${layout.i}${layout.w}${layout.h}${layout.x}${layout.y}`;
