import { FC, Fragment } from "react";
import {
  Button,
  DialogActions,
  DialogContent,
  Divider,
  Typography,
} from "@mui/material";
import { useAssignRolesHook } from "../azure/hooks/useAssignRoles.hook";
import { AzureAssignRolesStepContent } from "../azure/AzureAssignRolesStepContent";
import { PollingProgress } from "../PollingProgress";

export const AzureAssignRolesStep: FC = () => {
  const { prevHandler, tab, setTab, isPolling } = useAssignRolesHook();

  return (
    <Fragment>
      <DialogContent>
        <Typography variant="body2" fontWeight="medium" pb={3} pt={1}>
          Assign role(s) to the created service principal
        </Typography>
        <AzureAssignRolesStepContent value={tab} onChange={setTab} />
      </DialogContent>
      <PollingProgress />
      <Divider light />
      <DialogActions sx={{ px: 3, py: 2 }}>
        <Button
          onClick={prevHandler}
          variant="outlined"
          size="medium"
          disabled={isPolling}
        >
          Back
        </Button>
      </DialogActions>
    </Fragment>
  );
};
