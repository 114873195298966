import { automationErrorsSelector } from "./automationErrorsSelector";
import { RootState } from "../../../../store";
import { EmailsSelectionErrors } from "../../../../../components/common/integration-dialogs/components/email/components/email-selection/EmailsSelection";

export const automationEmailsErrorsSelector = (state: RootState) => {
  const errors = automationErrorsSelector(state);

  const to = errors["emails.to"];
  const cc = errors["emails.cc"];
  const bcc = errors["emails.bcc"];

  const emailsErrors = Object.keys(errors).reduce(
    (acc, key) => {
      const isEmailError = key.startsWith("emails") && key.endsWith("]");

      if (!isEmailError) {
        return acc;
      }

      const neededPart = key.split(".")?.at(1)?.split("[");

      const index = neededPart?.at(1)?.split("]")?.at(0);
      const emailType = neededPart?.at(0) as keyof typeof acc;

      if (emailType && index) {
        acc[emailType][+index] = errors[key];
      }

      return acc;
    },
    { to: [], cc: [], bcc: [] } as {
      to: string[];
      cc: string[];
      bcc: string[];
    },
  );

  if (to) {
    emailsErrors.to.push(to);
  }
  if (cc) {
    emailsErrors.cc.push(cc);
  }
  if (bcc) {
    emailsErrors.bcc.push(bcc);
  }

  return emailsErrors as unknown as EmailsSelectionErrors;
};
