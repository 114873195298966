import { FC } from "react";
import { useToggle } from "rooks";
import { IconButton, Stack } from "@mui/material";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import { ResourceExplorerGroupBySelector } from "./components/ResourceExplorerGroupBySelector";
import { ResourceExplorerDateRange } from "./components/ResourceExplorerDateRange";
import { ResourceExplorerFilterTree } from "./components/filter-tree/ResourceExplorerFilterTree";
import { useAppSelector } from "../../../../../../../store/hooks";
import { isBillingAvailableSelector } from "../../../../../../../store/common/selectors/billing-status/isBillingAvailableSelector";
import { ViewSettingsDrawer } from "../../../../../../navigation/components/resource-explorer-v2/components/view-action-menu/action-items/view-settings-action/components/ViewSettingsDrawer";

export const ResourceExplorerToolbar: FC = () => {
  const [settingDrawerOpen, toggleSettingsDrawerOpen] = useToggle();

  const isResourceExplorerAvailable = useAppSelector(
    isBillingAvailableSelector,
  );

  if (!isResourceExplorerAvailable) {
    return null;
  }

  return (
    <Stack
      p={1}
      direction="row"
      borderBottom={1}
      justifyContent="space-between"
      borderColor="grey.300"
      alignItems="center"
    >
      <Stack direction="row" spacing={1}>
        <ResourceExplorerGroupBySelector />

        <ResourceExplorerDateRange />

        <ResourceExplorerFilterTree />
      </Stack>

      <IconButton onClick={toggleSettingsDrawerOpen}>
        <SettingsOutlinedIcon fontSize="small" />
      </IconButton>

      <ViewSettingsDrawer
        open={settingDrawerOpen}
        onClose={toggleSettingsDrawerOpen}
      />
    </Stack>
  );
};
