import { FC } from "react";
import { useFlag } from "@unleash/proxy-client-react";
import { Skeleton, Typography } from "@mui/material";
import { useAppSelector } from "../../../store/hooks";
import { executionLogTotalPriceSelector } from "../../../store/execution-log/selectors/executionLogTotalPriceSelector";
import { executionLogDetailsLoadingSelector } from "../../../store/execution-log/selectors/executionLogDetailsLoadingSelector";
import { money } from "../../../utils/numeral/money";

export const ExecutionLogTotal: FC = () => {
  const total = useAppSelector(executionLogTotalPriceSelector);
  const totalLoading = useAppSelector(executionLogDetailsLoadingSelector);
  const enableExecutionLogTotal = useFlag("EnableExecutionLogTotal");

  if (!enableExecutionLogTotal) {
    return;
  }

  return (
    <Typography
      px={1}
      py={0.25}
      borderRadius={1}
      variant="caption"
      bgcolor="grey.200"
    >
      Total:{" "}
      <Typography fontWeight="medium" component="span" variant="inherit">
        {totalLoading ? (
          <Skeleton
            variant="text"
            height={18}
            width={50}
            sx={{ display: "inline-block" }}
          />
        ) : (
          money(total)
        )}
      </Typography>
    </Typography>
  );
};
