import { Stack } from "@mui/material";
import FolderOpenIcon from "@mui/icons-material/FolderOpen";
import { SvgIconComponent } from "@mui/icons-material";

import { TypographyWithTooltip } from "../../../../common/TypographyWithTooltip";
import { NavigationItemsVisibilityType } from "../../../../../store/navigation/utils/types";
import { TreeItems } from "../../../../navigation/components/sortable-tree/utils/types";

interface FormatHierarchyVisibilityOptionsArgs {
  data?: TreeItems;
  disabledOptionsIds?: string[];
  ItemIcon?: SvgIconComponent;
  visibility?: NavigationItemsVisibilityType;
}
export const formatHierarchyVisibilityOptions = ({
  data,
  disabledOptionsIds,
  ItemIcon,
  visibility,
}: FormatHierarchyVisibilityOptionsArgs) => {
  return (
    data
      ?.map((item) => {
        const groupName =
          visibility === "visible_to_everyone"
            ? "Visible to everyone"
            : "Visible only to me";
        if (item.type === "item") {
          return {
            groupName,
            value: item.id,
            rawValue: item,
            label: getViewLabel(item.name, ItemIcon),
            disabled: disabledOptionsIds?.includes(item.id),
          };
        }

        let names = "";

        const folderViews =
          item.items?.map((row) => {
            names += row.name;

            return {
              groupName,
              value: row.id,
              rawValue: { ...row, valueForSearch: item.name },
              label: getViewLabel(row.name, ItemIcon, 2),
              disabled: disabledOptionsIds?.includes(row.id),
            };
          }) ?? [];

        return [
          {
            groupName,
            value: item.id,
            disableSelection: true,
            rawValue: { ...item, valueForSearch: names },
            label: (
              <Stack direction="row" spacing={1} px={2} alignItems="center">
                <FolderOpenIcon fontSize="small" />
                <TypographyWithTooltip title={item.name} />
              </Stack>
            ),
          },
          ...folderViews,
        ];
      })
      ?.flat() ?? []
  );
};

const getViewLabel = (name: string, Icon?: SvgIconComponent, pl?: number) => {
  return (
    <Stack direction="row" spacing={1} pl={pl} alignItems="center">
      {Icon && <Icon fontSize="small" />}
      <TypographyWithTooltip title={name} />
    </Stack>
  );
};
