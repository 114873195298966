import { RootState } from "../../../../../store";
import { commitmentUtilizationWidgetSetupSelector } from "../../../setups/commitment-utilization/commitmentUtilizationWidgetSetupSelector";
import { GetUsersMeOrganisationsCurrentWidgetsCommitmentsUtilizationApiArg } from "../../../../../../services/cloudchipr.api";

export const commitmentUtilizationWidgetSetupDataPayloadSelector = (
  state: RootState,
):
  | GetUsersMeOrganisationsCurrentWidgetsCommitmentsUtilizationApiArg
  | undefined => {
  const setup = commitmentUtilizationWidgetSetupSelector(state);
  if (!setup?.providerOrganisationId) {
    return;
  }

  return {
    providerOrganisationId: setup.providerOrganisationId,
    dateLabel: setup.dates.label ?? undefined,
    dateFrom: setup.dates.from ?? undefined,
    dateTo: setup.dates.to ?? undefined,
    dateType: setup.dates.type ?? undefined,
    dateValue: setup.dates.value ?? undefined,
    dateUnit: setup.dates.unit ?? undefined,
    grouping: setup.grouping,
    commitmentType: setup.commitmentType,
    trendType: setup.trendType,
  };
};
