import { providerDisabledTooltipTitleCombiner } from "./providerDisabledTooltipTitleCombiner";
import { RootState } from "../../../store";
import { awsNonDeletedAndNotBillingAccountsSelector } from "../../../accounts/selectors/aws/awsNonDeletedAndNotBillingAccountsSelector";

export const awsDisabledTooltipTitleSelector = (
  state: RootState,
  forOffHours: boolean,
) => {
  const accounts = awsNonDeletedAndNotBillingAccountsSelector(state);

  return providerDisabledTooltipTitleCombiner(forOffHours, "aws", accounts);
};
