import { FC } from "react";
import { Button } from "@mui/material";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { UseMenuHookOpenMenu } from "../../../../../../utils/hooks/useMenu.hook";
import { useAppSelector } from "../../../../../../store/hooks";
import { selectedIdsExistSelector } from "../../../../../../store/recommendations/selectors/selected-ids/selectedIdsExistSelector";

interface MultipleDismissButtonProps {
  open: boolean;
  disabled: boolean;
  onClick: UseMenuHookOpenMenu;
}

export const MultipleDismissButton: FC<MultipleDismissButtonProps> = ({
  open,
  disabled,
  onClick,
}) => {
  const idsExist = useAppSelector(selectedIdsExistSelector);

  return (
    <Button
      size="small"
      variant="contained"
      onClick={onClick}
      disabled={!idsExist || disabled}
      endIcon={open ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
    >
      Actions
    </Button>
  );
};
