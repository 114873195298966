import { RootState } from "../../../store";
import { Account, AccountId } from "../../../../services/cloudchipr.api";
import { stateAccountsSelector } from "../slice-data/stateAccountsSelector";

export const awsAccountManagementAccountDetailsSelector = (
  state: RootState,
  accountId: AccountId,
): Account | null => {
  const accounts = stateAccountsSelector(state);
  const account = accounts?.find((a) => accountId === a.id);

  if (!account) {
    return null;
  }

  return accounts?.find((a) => a.id === account.parent_id) ?? null;
};
