import { FC, useCallback } from "react";
import {
  FormControl,
  FormLabel,
  RadioGroup,
  Stack,
  Typography,
} from "@mui/material";
import { LargestCostChangesPriceDirectionItem } from "./LargestCostChangesPriceDirectionItem";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../../store/hooks";
import { largestCostChangesSetupChangeThunk } from "../../../../../../../../../store/dashboards/thunks/widgets/largest-cost-changes/widget-change/largestCostChangesSetupChangeThunk";
import { largestCostChangesSetupPropertyByKeySelector } from "../../../../../../../../../store/dashboards/selectors/setups/larges-cost-changes/largestCostChangesSetupPropertyByKeySelector";
export const LargestCostChangesPriceDirection: FC = () => {
  const dispatch = useAppDispatch();

  const priceDirection = useAppSelector(
    largestCostChangesSetupPropertyByKeySelector("priceDirection"),
  );

  const priceDirectionChangeHandler = useCallback(
    (event: any) => {
      dispatch(
        largestCostChangesSetupChangeThunk({
          priceDirection: event.target.value,
        }),
      );
    },
    [dispatch],
  );

  return (
    <FormControl>
      <FormLabel>
        <Typography variant="body2" color="text.primary" pb={0.5}>
          Change
        </Typography>
      </FormLabel>
      <RadioGroup value={priceDirection} onChange={priceDirectionChangeHandler}>
        <Stack direction="row" spacing={2}>
          <LargestCostChangesPriceDirectionItem
            checked={priceDirection === "increasing"}
            value="increasing"
          />
          <LargestCostChangesPriceDirectionItem
            checked={priceDirection === "decreasing"}
            value="decreasing"
          />
        </Stack>
      </RadioGroup>
    </FormControl>
  );
};
