import {
  AccountResourceFilter2,
  FilterItemWithType,
  FilterOperatorType,
  ResourceType,
} from "../../../../services/cloudchipr.api";

interface AddProviderUniqueIdentifierFilterToAppliedFiltersProps {
  resourceIds: string[] | null;
  resourceType: ResourceType;
  operator?: FilterOperatorType;
  appliedFilters?: AccountResourceFilter2 | null;
}
export const addProviderUniqueIdentifierFilterToAppliedFilters = ({
  resourceIds,
  operator = "in",
  resourceType,
  appliedFilters,
}: AddProviderUniqueIdentifierFilterToAppliedFiltersProps): AccountResourceFilter2 | null => {
  if (!resourceIds?.length) {
    return null;
  }

  const { filter_items = [], filter_groups = [] } = appliedFilters ?? {};
  const filterItemsNewRef = [...filter_items];

  const existingFilterIndex = filterItemsNewRef.findIndex(
    (item) =>
      item.filter.key === "provider_unique_identifier" &&
      item.filter.operator === "not_in",
  );
  const filterExists = existingFilterIndex !== -1;

  const updatedFilterGroups = filterExists
    ? filter_groups
    : [...filter_groups, filter_groups.length ?? 0];

  const updatedFilterItems = filterExists
    ? updateExistingFilter(filterItemsNewRef, existingFilterIndex, resourceIds)
    : addNewFilterItem(filterItemsNewRef, resourceType, resourceIds, operator);

  const updatedAppliedFilters: AccountResourceFilter2 = {
    type: resourceType,
    filter_groups: updatedFilterGroups,
    filter_items: updatedFilterItems,
    operators: appliedFilters?.operators?.length
      ? appliedFilters.operators
      : ["and"],
  };

  return updatedAppliedFilters ?? null;
};

const updateExistingFilter = (
  filterItems: FilterItemWithType[],
  index: number,
  resourceIds: string[],
): FilterItemWithType[] => {
  const existingFilter = filterItems[index];
  const filterStringArrValues = (existingFilter.filter.value as string[]) ?? [];
  const existingValuesSet = new Set(filterStringArrValues ?? []);

  filterItems[index] = {
    ...existingFilter,
    filter: {
      ...existingFilter.filter,
      value: [
        ...filterStringArrValues,
        ...resourceIds.filter((id) => !existingValuesSet.has(id)),
      ],
    },
  };

  return filterItems;
};

const addNewFilterItem = (
  filterItems: FilterItemWithType[],
  resourceType: ResourceType,
  resourceIds: string[],
  operator: FilterOperatorType,
): FilterItemWithType[] => {
  return [
    ...filterItems,
    {
      type: resourceType,
      filter: {
        key: "provider_unique_identifier",
        value: resourceIds,
        operator,
      },
    },
  ];
};
