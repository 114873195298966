import { resourceExplorerCsvDataHashSelector } from "./resourceExplorerCsvDataHashSelector";
import { RootState } from "../../../../store";
import { cloudChiprApi } from "../../../../../services/cloudchipr.api";
import { resourceExplorerDataGridViewSelector } from "../resourceExplorerDataGridViewSelector";
import { resourceExplorerCSVDataFixedCacheKey } from "../../../thunks/widgets/csv/resourceExplorerCSVDataHashGeneratorThunk";

export const resourceExplorerCsvDownloadLoadingSelector = (
  state: RootState,
) => {
  const {
    getUsersMeOrganisationsCurrentWidgetsResourceExplorerDownloadCsv,
    postUsersMeOrganisationsCurrentWidgetsResourceExplorerDownloadCsv,
    postUsersMeOrganisationsCurrentResourceExplorerGranularCostsDownloadCsv,
  } = cloudChiprApi.endpoints;

  const dataGridView = resourceExplorerDataGridViewSelector(state);
  const hash = resourceExplorerCsvDataHashSelector(state);

  const hashEndpoint =
    dataGridView === "granular"
      ? postUsersMeOrganisationsCurrentResourceExplorerGranularCostsDownloadCsv
      : postUsersMeOrganisationsCurrentWidgetsResourceExplorerDownloadCsv;

  const downloadRequest =
    getUsersMeOrganisationsCurrentWidgetsResourceExplorerDownloadCsv.select({
      hash,
    })(state);

  return (
    downloadRequest?.isLoading ||
    (downloadRequest?.isSuccess && !downloadRequest?.data?.url) ||
    hashEndpoint.select(resourceExplorerCSVDataFixedCacheKey)(state)?.isLoading
  );
};
