import { getCostAnomalyPreviewRequestArgsSelector } from "./getCostAnomalyPreviewRequestArgsSelector";
import { getCostAnomalyPreviewDataGridDataSelector } from "./getCostAnomalyPreviewDataGridDataSelector";
import { RootState } from "../../../store";
import { ResourceExplorerDataAverage } from "../../../../services/cloudchipr.api";

export const costAnomalyPreviewDataGridDataSelector = (
  state: RootState,
): ResourceExplorerDataAverage[] | null => {
  const args = getCostAnomalyPreviewRequestArgsSelector(state);

  if (!args) {
    return [];
  }

  return getCostAnomalyPreviewDataGridDataSelector(args)(state)?.data ?? null;
};
