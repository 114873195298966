import { createAsyncThunk } from "@reduxjs/toolkit";
import { cloudChiprApi } from "../../../../services/cloudchipr.api";

export const getUtilizationAlertsThunk = createAsyncThunk(
  "alerts/getUtilizationAlerts",
  async (_, { dispatch }) => {
    const { getUsersMeOrganisationsCurrentAlertsUtilizationAndCoverage } =
      cloudChiprApi.endpoints;

    return await dispatch(
      getUsersMeOrganisationsCurrentAlertsUtilizationAndCoverage.initiate(
        undefined,
        { forceRefetch: true },
      ),
    ).unwrap();
  },
);
