import { createAsyncThunk } from "@reduxjs/toolkit";
import { enqueueSnackbar } from "notistack";
import { cloudChiprApi } from "../../../../services/cloudchipr.api";

export const getOrgCurrentRolesThunk = createAsyncThunk(
  "roles/getOrgCurrentRoles",
  async (_, { dispatch }) => {
    try {
      await dispatch(
        cloudChiprApi.endpoints.getUsersMeOrganisationsCurrentRoles.initiate(
          _,
          {
            forceRefetch: true,
          },
        ),
      ).unwrap();
    } catch (e) {
      // @ts-expect-error // todo: api fix
      enqueueSnackbar(e?.data?.message ?? "Something went wrong", {
        variant: "snackbarAlert",
        AlertSnackBarProps: {
          severity: "error",
        },
      });
    }
  },
);
