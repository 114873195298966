import { getCurrentProvidersByProviderBillingStatusDataSelector } from "./getCurrentProvidersByProviderBillingStatusDataSelector";
import {
  BillingCollectionStatus,
  ProviderType,
} from "../../../../services/cloudchipr.api";
import { RootState } from "../../../store";
import { connectedProvidersSelector } from "../connectedProvidersSelector";

export const billingAccountStatusAllProvidersSelector = (
  state: RootState,
): Record<ProviderType, BillingCollectionStatus> | undefined => {
  const enabledProviders = connectedProvidersSelector(state);
  return enabledProviders.reduce(
    (result, item) => {
      const data = getCurrentProvidersByProviderBillingStatusDataSelector(
        state,
        item,
      );
      if (data?.status) {
        result[item] = data?.status;
      }
      return result;
    },
    {} as Record<ProviderType, BillingCollectionStatus>,
  );
};
