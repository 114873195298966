import { Row } from "@tanstack/react-table";
import {
  CostAnomalyAlertsDataGridDataType,
  UtilizationAlertDataGridDataType,
} from "../../../../../../../../store/alerts/utils/types/types";

export const alertsNotificationsFilter = <
  T extends
    | CostAnomalyAlertsDataGridDataType
    | UtilizationAlertDataGridDataType,
>(
  row: Row<T>,
  _: string,
  keyword: string,
) => {
  const integrationName = row?.original?.integrations?.reduce((acc, cur) => {
    return acc + cur.name;
  }, "");
  const email = row?.original?.emails?.to?.join(" ");
  const searchValue = `${integrationName} ${email}`.toLowerCase().trim();
  const filterValue = keyword.toLowerCase().trim();

  if (!searchValue) {
    return false;
  }

  return searchValue.includes(filterValue);
};
