export const generateRemoveDashboardWidgetFixedCacheKey = (
  id: string,
): string => {
  return `remove-dashboard-${id}-widget`;
};

export const generateUpdateDashboardWidgetFixedCacheKey = (
  id: string,
): string => {
  return `update-dashboard-${id}-widget`;
};
