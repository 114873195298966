import { FC, useCallback } from "react";
import { Checkbox, TableCell, TableRow, Typography } from "@mui/material";

import { UserRoleUpdatedPermissionsKeys } from "./utils/types";
import { PermissionWithSelectedState } from "../../../../../../../store/user/utils/types/common";
import { Permission } from "../../../../../../../services/cloudchipr.api";

interface RolePermissionsCollapseContentItemProps
  extends PermissionWithSelectedState {
  onChange(
    group: Permission["group"],
    updatedPermissions: UserRoleUpdatedPermissionsKeys[],
  ): void;
}

export const RolePermissionsCollapseContentItem: FC<
  RolePermissionsCollapseContentItemProps
> = ({ onChange, selected, name, description, group }) => {
  const onRowChangeHandler = useCallback(() => {
    onChange(group, [
      {
        name,
        selected: !selected,
      },
    ]);
  }, [onChange, selected, name, group]);

  return (
    <TableRow sx={{ position: "relative" }}>
      <TableCell sx={{ width: 30, ...cellPaddings }}>
        <Checkbox
          checked={selected}
          onChange={onRowChangeHandler}
          size="small"
        />
      </TableCell>
      <TableCell sx={{ maxWidth: 70, ...cellPaddings }}>
        <Typography color="text.secondary" variant="caption">
          {name}
        </Typography>
      </TableCell>
      <TableCell sx={{ ...cellPaddings }}>
        <Typography color="text.secondary" variant="caption">
          {description}
        </Typography>
      </TableCell>
    </TableRow>
  );
};

const cellPaddings = {
  py: 0,
  px: 0.5,
};
