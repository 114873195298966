import { FC, useCallback } from "react";
import { IconButton, Tooltip } from "@mui/material";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { TaskPropertyType } from "../../../../../../../../store/task-management/utils/types/types";

interface TaskPropertyDeleteButtonProps {
  index: number;
  isLast: boolean;
  disabled: boolean;
  properties: TaskPropertyType[];
  onPropertiesChange(properties: TaskPropertyType[]): void;
}

export const TaskPropertyDeleteButton: FC<TaskPropertyDeleteButtonProps> = ({
  disabled,
  onPropertiesChange,
  index,
  isLast,
  properties,
}) => {
  const changePropertyByIndex = useCallback(() => {
    onPropertiesChange(properties.filter((_, i) => i !== index));
  }, [index, onPropertiesChange, properties]);

  return (
    <Tooltip title={isLast ? "At least one option is required" : ""} arrow>
      <span>
        <IconButton
          size="small"
          disabled={disabled || isLast}
          onClick={changePropertyByIndex}
        >
          <DeleteOutlineOutlinedIcon
            fontSize="small"
            color={disabled || isLast ? "disabled" : "action"}
          />
        </IconButton>
      </span>
    </Tooltip>
  );
};
