import { ChangeEvent, FC, useCallback, useState } from "react";
import { Box, TextField } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";

interface PopoverHeaderProps {
  keyword: string;
  setKeyword(keyword: string): void;
}

export const PopoverHeader: FC<PopoverHeaderProps> = ({
  keyword,
  setKeyword,
}) => {
  const [value, setValue] = useState(keyword);

  const changeHandler = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value;

      setValue(value);
      setKeyword(value);
    },
    [setKeyword],
  );

  return (
    <Box p={1}>
      <TextField
        autoFocus
        fullWidth
        value={value}
        placeholder="Search"
        size="xsmall"
        onChange={changeHandler}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
      />
    </Box>
  );
};
