import { AccountId } from "../../../../../services/cloudchipr.api";

export const generateAccountIdsFromSearchParams = (
  accountIds: AccountId | null,
): AccountId[] => {
  if (!accountIds) {
    return [];
  }
  return accountIds.split(",");
};
