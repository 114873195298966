import { FC, ReactNode } from "react";
import { Stack } from "@mui/material";
import { WidgetNumeralViewLoader } from "./WidgetNumeralViewLoader";

interface WidgetNumeralViewProps {
  loading: boolean;
  skeletonCount?: number;
  children?: ReactNode;
  width?: string;
}

export const WidgetNumeralView: FC<WidgetNumeralViewProps> = ({
  loading,
  skeletonCount,
  children,
  width,
}) => {
  return (
    <Stack direction="row" flexWrap="wrap" overflow="auto" flexGrow={1} p={1.5}>
      {loading ? (
        <WidgetNumeralViewLoader
          count={skeletonCount || skeletonDefaultCount}
          width={width}
        />
      ) : (
        children
      )}
    </Stack>
  );
};

const skeletonDefaultCount = 10;
