import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  CategoryFilter,
  cloudChiprApi,
} from "../../../../services/cloudchipr.api";

export const categoryUpdateFixedCacheKey =
  "putUsersMeOrganisationsCurrentDimensionsByDimensionIdCategoriesAndCategoryId-fixed-cache-key";

export const updateCategoryByIdThunk = createAsyncThunk(
  "dimensions/updateCategoryById",
  async (
    {
      dimensionId,
      categoryId,
      name,
      filter,
    }: {
      dimensionId: string;
      name?: string;
      categoryId: string;
      filter?: CategoryFilter;
    },
    { dispatch },
  ) => {
    const {
      putUsersMeOrganisationsCurrentDimensionsByDimensionIdCategoriesAndCategoryId,
    } = cloudChiprApi.endpoints;

    return await dispatch(
      putUsersMeOrganisationsCurrentDimensionsByDimensionIdCategoriesAndCategoryId.initiate(
        { dimensionId, categoryId, body: { name, filter } },
        { fixedCacheKey: categoryUpdateFixedCacheKey },
      ),
    ).unwrap();
  },
);
