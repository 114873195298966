import { FC, useCallback, useMemo } from "react";
import { Table } from "@tanstack/react-table";
import { useSearchParams } from "react-router-dom";
import { useWillUnmount } from "rooks";
import { Stack, Typography } from "@mui/material";
import { OffHoursRecommendationsTableToolbar } from "./OffHoursRecommendationsTableToolbar";
import { offHoursRecommendationsTableColumns } from "./offHoursRecommendationsTableColumns";
import { offHoursRecommendationsSubRow } from "./subrow/OffHoursRecommendationsSubRow";
import {
  RecommendationDataType,
  RecommendationGroupType,
} from "../../utils/types/types";
import { expandRowAfterFirstRender } from "../../../../../../../utils/helpers/expandRowAfterFirstRender";
import { DataGridProvider } from "../../../../../../../storybook/data-grid/DataGridProvider";
import { DataGrid } from "../../../../../../../storybook/data-grid/DataGrid";
import { RecommendationsTabType } from "../OffHoursRecommendationsContent";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../store/hooks";
import {
  resetDismiss,
  setSelectedIds,
} from "../../../../../../../store/recommendations/recommendationsSlice";
import { selectedIdsInGridFormatSelector } from "../../../../../../../store/recommendations/selectors/selected-ids/selectedIdsInGridFormatSelector";
import { offHoursRecommendationsPageLoadingSelector } from "../../../../../../../store/recommendations/selectors/off-hours/loading/offHoursRecommendationsPageLoadingSelector";

interface OffHoursRecommendationsTableProps {
  group: RecommendationGroupType;
  tab: RecommendationsTabType;
  recommendations: RecommendationDataType[];
}

export const OffHoursRecommendationsTable: FC<
  OffHoursRecommendationsTableProps
> = ({ group, recommendations, tab }) => {
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id") ?? "";
  const dispatch = useAppDispatch();

  const selectedIds = useAppSelector(selectedIdsInGridFormatSelector);
  const loading = useAppSelector(offHoursRecommendationsPageLoadingSelector);

  const columns = useMemo(() => {
    return offHoursRecommendationsTableColumns(group, tab);
  }, [group, tab]);

  const afterRenderCBFn = useCallback(
    (table: Table<any>) => {
      return expandRowAfterFirstRender(table, [id]);
    },
    [id],
  );

  const onRowSelectionChange = useCallback(
    (ids: Record<string, boolean>): void => {
      dispatch(setSelectedIds(Object.keys(ids)));
    },
    [dispatch],
  );

  useWillUnmount(() => {
    dispatch(resetDismiss());
  });

  return (
    <DataGridProvider>
      <DataGrid
        styles={styles}
        columns={columns}
        data={recommendations ?? []}
        columnSorting={sortingConfig}
        afterFirstRenderCallback={
          !loading && recommendations?.length ? afterRenderCBFn : undefined
        }
        pagination={paginationConfig}
        rowExpanding={{
          renderExpandedRowSubRow: offHoursRecommendationsSubRow,
        }}
        rowSelection={{
          initialSelectedItems: selectedIds,
          rowSelectionChange: onRowSelectionChange,
        }}
        toolbar={{
          renderToolbar: (props) => (
            <OffHoursRecommendationsTableToolbar
              {...props}
              group={group}
              tab={tab}
            />
          ),
        }}
      />

      {!loading && !recommendations?.length && (
        <Stack p={2} alignItems="center" justifyContent="center">
          <Typography
            fontWeight="medium"
            variant="subtitle1"
            color="text.secondary"
          >
            No recommendations found
          </Typography>
        </Stack>
      )}
    </DataGridProvider>
  );
};

const styles = {
  table: {
    tableLayout: "fixed",
    borderCollapse: "separate",
  },
  tableHeaderRow: {
    "& th:nth-of-type(2)": { pl: 0 },
    "& th": { verticalAlign: "inherit" },
  },
};

const sortingConfig = {
  initialSort: [{ id: "potential_saving", desc: true }],
};

const paginationConfig = { pageSize: 10 };
