import { FC, Fragment } from "react";
import { Stack } from "@mui/material";
import {
  NullableDate,
  ScheduleStatus,
} from "../../../../../../../services/cloudchipr.api";
import { AutomationStatusChip } from "../../../../common/components/grid/AutomationStatusChip";
import { ScheduledMoreNextRuns } from "../../../../common/components/next-runs-old/ScheduledMoreNextRuns";
import { ScheduledNextRunDateTime } from "../../../../common/components/next-runs-old/ScheduledNextRunDateTime";
import { ScheduledNextRunDateTimeZone } from "../../../../common/components/next-runs-old/ScheduledNextRunDateTimeZone";
import { useAppSelector } from "../../../../../../../store/hooks";
import { scheduleNextRunsByScheduleIdSelector } from "../../../../../../../store/automations/selectros/workflow/next-runs/old/scheduleNextRunsByScheduleIdSelector";

interface WorkflowNextRunCellProps {
  status: ScheduleStatus;
  nextRun?: NullableDate;
  timeZone: string;
  id: string;
}

export const WorkflowNextRunCell: FC<WorkflowNextRunCellProps> = ({
  status,
  nextRun,
  timeZone,
  id,
}) => {
  const nextRuns = useAppSelector((state) =>
    scheduleNextRunsByScheduleIdSelector(state, id),
  );

  return (
    <Stack
      justifyContent="center"
      height="100%"
      alignItems="start"
      overflow="hidden"
    >
      {status === "inactive" ? (
        <AutomationStatusChip status={status} />
      ) : (
        <Fragment>
          {nextRun ? (
            <Stack overflow="hidden" width="100%">
              <ScheduledNextRunDateTime nextRun={nextRun} />

              <Stack direction="row" spacing={1} alignItems="center">
                <ScheduledNextRunDateTimeZone timeZone={timeZone} />
                <ScheduledMoreNextRuns
                  nextRun={nextRun}
                  nextRunList={nextRuns}
                  timeZone={timeZone}
                />
              </Stack>
            </Stack>
          ) : (
            "Never"
          )}
        </Fragment>
      )}
    </Stack>
  );
};
