import { FC, useCallback } from "react";
import { Theme } from "@mui/material";
import { Row, Table } from "@tanstack/react-table";
import { dashboardAllItemsTableColumns } from "./components/dashboardAllItemsTableColumns";
import { DashboardMixedVisibilitiesItemType } from "./utils/types/types";
import { dashboardAllItemsTableSubRow } from "./components/sub-row/DashboardAllItemsTableSubRow";
import { DashboardAllItemsTableNoData } from "./components/DashboardAllItemsTableNoData";
import { useAllItemsFiltersContext } from "../../../common/hierarchy-all-items/components/HierarchyAllItemsFiltersProvider";
import { useAppSelector } from "../../../../../store/hooks";
import { dashboardHierarchyMixedVisibilitiesDataByFiltersSelector } from "../../../../../store/dashboards/selectors/dashboard-hierarchy-visibility/dashboardHierarchyMixedVisibilitiesDataByFiltersSelector";
import { DataGridProvider } from "../../../../../storybook/data-grid/DataGridProvider";
import { DataGrid } from "../../../../../storybook/data-grid/DataGrid";
import {
  RowExpandingConfig,
  ToolbarConfig,
} from "../../../../../storybook/data-grid/utils/types/prop-types";
import { HierarchyAllItemsTableToolbar } from "../../../common/hierarchy-all-items/components/HierarchyAllItemsTableToolbar";
import { isHierarchyFolder } from "../../../../../store/navigation/utils/helpers/isHierarchyFolder";
import { NoFilteredData } from "../../../../common/NoFilteredData";
import { dashboardHierarchyVisibilitiesDataLengthSelector } from "../../../../../store/dashboards/selectors/dashboard-hierarchy-visibility/dashboardHierarchyMixedVisibilitiesDataLengthSelector";

export const DashboardAllItemsTable: FC = () => {
  const {
    filters: { createdBy, visibilities },
  } = useAllItemsFiltersContext();
  const dataLength = useAppSelector(
    dashboardHierarchyVisibilitiesDataLengthSelector,
  );
  const filteredData = useAppSelector((state) =>
    dashboardHierarchyMixedVisibilitiesDataByFiltersSelector(
      state,
      visibilities,
      createdBy,
    ),
  );

  const Footer = useCallback<FC<{ table: Table<any> }>>(
    ({ table }) => {
      if (!dataLength) {
        return <DashboardAllItemsTableNoData />;
      }

      return <NoFilteredData table={table} />;
    },
    [dataLength],
  );

  if (!filteredData) {
    return null;
  }

  return (
    <DataGridProvider>
      <DataGrid
        columnSorting
        globalFilter
        columnVisibility
        data={filteredData}
        enableRowsVirtualization={filteredData.length > 30}
        columns={dashboardAllItemsTableColumns}
        toolbar={dataGridToolbar}
        styles={styles}
        rowExpanding={rowExpandingConfig}
        Footer={Footer}
      />
    </DataGridProvider>
  );
};

const rowExpandingConfig: RowExpandingConfig = {
  getCanRowExpand: (row: Row<DashboardMixedVisibilitiesItemType>) =>
    isHierarchyFolder(row.original),
  renderExpandedRowSubRow: dashboardAllItemsTableSubRow,
  parentRowStyles: { "& > td": { p: 0 } },
};

const dataGridToolbar: ToolbarConfig = {
  renderToolbar: HierarchyAllItemsTableToolbar,
};

const styles = {
  table: {
    tableLayout: "fixed",
    "& .hover-class:hover": { bgcolor: "grey.100" },
  },
  tableHeaderRow: {
    top: 0,
    position: "sticky",
    bgcolor: "grey.100",
    zIndex: (theme: Theme) => theme.zIndex.appBar - 1,
    "& th": { py: 1.5, borderColor: "grey.200" },
    "& th:first-of-type": { px: 2 },
  },
};
