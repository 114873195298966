import { RootState } from "../../../../../store";
import { resourceExplorerByIdLoadingSelector } from "../../../../../resource-explorer/selectors/resource-explorer-by-id/resourceExplorerByIdLoadingSelector";
import { costBreakdownSetupPropertyByKeySelector } from "../../../setups/cost-breakdown/costBreakdownSetupPropertyByKeySelector";
import { costBreakdownWidgetSetupDataGettingLoadingSelector } from "../loading/costBreakdownWidgetSetupDataGettingLoadingSelector";

export const costBreakdownWidgetSetupLoadingSelector = (
  state: RootState,
): boolean => {
  const viewId = costBreakdownSetupPropertyByKeySelector("viewId")(state);
  const dataGettingLoading =
    costBreakdownWidgetSetupDataGettingLoadingSelector(state);

  const viewChangeLoading =
    costBreakdownSetupPropertyByKeySelector("loading")(state);

  const resourceExplorerViewLoading = resourceExplorerByIdLoadingSelector(
    state,
    viewId,
  );
  return dataGettingLoading || viewChangeLoading || resourceExplorerViewLoading;
};
