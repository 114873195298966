import { cloudChiprApi } from "../../../../services/cloudchipr.api";

export const rightsizingRecommendationsSelector =
  cloudChiprApi.endpoints.getUsersMeOrganisationsCurrentResourcesRightSizingRecommendations.select(
    { isDismissed: false },
  );

export const rightsizingDismissedRecommendationsSelector =
  cloudChiprApi.endpoints.getUsersMeOrganisationsCurrentResourcesRightSizingRecommendations.select(
    { isDismissed: true },
  );
