import { liveUsageMgmtResourceTypeDataWithChildrenSelector } from "./liveUsageMgmtResourceTypeDataWithChildrenSelector";
import { RootState } from "../../../store";
import { ResourceType } from "../../../../services/cloudchipr.api";
import { getFirstParentInHierarchyResourceType } from "../../../../utils/helpers/resources/getFirstParentInHierarchyResourceType";

export const liveUsageMgmtResourceByIdAndTypeSelector = (
  state: RootState,
  resourceType: ResourceType,
  resourceId: string,
) => {
  const parentResourceType =
    getFirstParentInHierarchyResourceType(resourceType);

  const resources = liveUsageMgmtResourceTypeDataWithChildrenSelector(
    state,
    parentResourceType ?? resourceType,
  );

  return resources?.find((resource) => resource.id === resourceId);
};
