import { FC, useCallback } from "react";
import { ReportsDashboardSelectTrigger } from "./ReportsDashboardSelectTrigger";
import { HierarchyDashboardSelectionWrapper } from "../../../../../common/hierarchy-dashboard-selection/HierarchyDashboardSelectionWrapper";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../store/hooks";
import { reportsDataPropertyByKeySelector } from "../../../../../../../store/reports/selector/reportsDataPropertyByKeySelector";
import { setReportsData } from "../../../../../../../store/reports/reportsSlice";

export const ReportsDrawerDashboardSelect: FC = () => {
  const dispatch = useAppDispatch();
  const dashboardId = useAppSelector(
    reportsDataPropertyByKeySelector("source_id"),
  );

  const changeNavigationHandler = useCallback(
    (viewId: string) => {
      dispatch(
        setReportsData({
          source_id: viewId,
        }),
      );
    },
    [dispatch],
  );

  return (
    <HierarchyDashboardSelectionWrapper
      selectedItemId={dashboardId ?? ""}
      CustomTriggerComponent={ReportsDashboardSelectTrigger}
      onChange={changeNavigationHandler}
    />
  );
};
