import { SvgIcon, SvgIconProps, useTheme } from "@mui/material";

const AzLbSvgIcon = ({ primary }: SvgIconProps & { primary?: boolean }) => {
  const { palette } = useTheme();

  const color = primary ? palette.primary.main : palette.action.active;

  return (
    <SvgIcon>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_551_89843)">
          <path
            d="M0.240114 11.4268L11.4268 0.240114C11.5013 0.163509 11.5905 0.102621 11.6889 0.0610441C11.7874 0.0194677 11.8932 -0.00195313 12.0001 -0.00195312C12.107 -0.00195312 12.2128 0.0194677 12.3113 0.0610441C12.4098 0.102621 12.4989 0.163509 12.5734 0.240114L23.7601 11.4268C23.8367 11.5013 23.8976 11.5905 23.9392 11.6889C23.9808 11.7874 24.0022 11.8932 24.0022 12.0001C24.0022 12.107 23.9808 12.2128 23.9392 12.3113C23.8976 12.4098 23.8367 12.4989 23.7601 12.5734L12.5601 23.7734C12.4106 23.92 12.2095 24.0021 12.0001 24.0021C11.7907 24.0021 11.5897 23.92 11.4401 23.7734L0.240114 12.5734C0.163509 12.4989 0.102621 12.4098 0.0610441 12.3113C0.0194677 12.2128 -0.00195312 12.107 -0.00195312 12.0001C-0.00195313 11.8932 0.0194677 11.7874 0.0610441 11.6889C0.102621 11.5905 0.163509 11.5013 0.240114 11.4268Z"
            fill={color}
          />
          <path
            d="M14.9334 5.33259L12.1067 2.51926C12.0774 2.49302 12.0394 2.47852 12 2.47852C11.9607 2.47852 11.9227 2.49302 11.8934 2.51926L9.06671 5.33259C9.04069 5.3501 9.02168 5.37621 9.01301 5.40635C9.00434 5.43648 9.00657 5.46871 9.0193 5.49736C9.03204 5.52602 9.05447 5.54927 9.08265 5.56303C9.11082 5.57678 9.14295 5.58017 9.17338 5.57259H10.8267C10.846 5.57259 10.865 5.57639 10.8828 5.58376C10.9006 5.59113 10.9168 5.60193 10.9304 5.61555C10.944 5.62917 10.9548 5.64534 10.9622 5.66313C10.9696 5.68093 10.9734 5.7 10.9734 5.71926V8.38593C10.9734 8.42482 10.9888 8.46213 11.0163 8.48963C11.0438 8.51714 11.0811 8.53259 11.12 8.53259H12.88C12.9189 8.53259 12.9562 8.51714 12.9838 8.48963C13.0113 8.46213 13.0267 8.42482 13.0267 8.38593V5.71926C13.0267 5.7 13.0305 5.68093 13.0379 5.66313C13.0452 5.64534 13.056 5.62917 13.0697 5.61555C13.0833 5.60193 13.0995 5.59113 13.1172 5.58376C13.135 5.57639 13.1541 5.57259 13.1734 5.57259H14.8267C14.8571 5.58017 14.8893 5.57678 14.9174 5.56303C14.9456 5.54927 14.968 5.52602 14.9808 5.49736C14.9935 5.46871 14.9957 5.43648 14.9871 5.40635C14.9784 5.37621 14.9594 5.3501 14.9334 5.33259Z"
            fill="white"
            fillOpacity="0.3"
          />
          <path
            d="M5.33352 8.81367L2.53352 11.6537C2.50821 11.6808 2.49414 11.7166 2.49414 11.7537C2.49414 11.7908 2.50821 11.8265 2.53352 11.8537L5.33352 14.667C5.35402 14.6888 5.38069 14.7038 5.40995 14.71C5.43921 14.7162 5.46966 14.7133 5.49723 14.7016C5.52479 14.69 5.54815 14.6703 5.56417 14.645C5.58019 14.6198 5.5881 14.5902 5.58685 14.5603V12.9203C5.58685 12.8814 5.6023 12.8441 5.62981 12.8166C5.65731 12.7891 5.69462 12.7737 5.73352 12.7737H8.40018C8.41888 12.7738 8.43738 12.7699 8.45449 12.7624C8.4716 12.7549 8.48694 12.7439 8.49951 12.73C8.51208 12.7162 8.52161 12.6999 8.52747 12.6821C8.53333 12.6644 8.53539 12.6456 8.53352 12.627V10.867C8.54468 10.8483 8.55109 10.8272 8.5522 10.8054C8.55331 10.7837 8.54907 10.762 8.53986 10.7423C8.53066 10.7225 8.51676 10.7054 8.49938 10.6922C8.48201 10.6791 8.46168 10.6705 8.44018 10.667H5.77352C5.75492 10.6689 5.73614 10.6668 5.71839 10.6609C5.70064 10.6551 5.68432 10.6456 5.67049 10.633C5.65666 10.6204 5.64563 10.6051 5.63811 10.588C5.63059 10.5709 5.62676 10.5524 5.62685 10.5337V8.92033C5.641 8.88144 5.63911 8.83851 5.62161 8.801C5.6041 8.7635 5.57242 8.73448 5.53352 8.72033C5.49462 8.70619 5.45169 8.70808 5.41419 8.72558C5.37668 8.74308 5.34766 8.77477 5.33352 8.81367Z"
            fill="white"
            fillOpacity="0.3"
          />
          <path
            d="M18.7733 14.6663L21.6133 11.8396C21.6386 11.8125 21.6526 11.7767 21.6526 11.7396C21.6526 11.7025 21.6386 11.6668 21.6133 11.6396L18.7733 8.81294C18.7522 8.79555 18.7268 8.78437 18.6997 8.78066C18.6727 8.77695 18.6452 8.78086 18.6202 8.79194C18.5953 8.80302 18.5739 8.82084 18.5586 8.8434C18.5432 8.86595 18.5345 8.89234 18.5333 8.91961V10.5863C18.5334 10.605 18.5295 10.6235 18.522 10.6406C18.5145 10.6577 18.5035 10.673 18.4896 10.6856C18.4758 10.6982 18.4595 10.7077 18.4417 10.7136C18.424 10.7194 18.4052 10.7215 18.3866 10.7196H15.7199C15.7012 10.7195 15.6827 10.7234 15.6656 10.7309C15.6485 10.7384 15.6332 10.7494 15.6206 10.7632C15.608 10.7771 15.5985 10.7934 15.5927 10.8111C15.5868 10.8289 15.5847 10.8477 15.5866 10.8663V12.6263C15.5847 12.6449 15.5868 12.6637 15.5927 12.6814C15.5985 12.6992 15.608 12.7155 15.6206 12.7293C15.6332 12.7431 15.6485 12.7542 15.6656 12.7617C15.6827 12.7692 15.7012 12.773 15.7199 12.7729H18.3866C18.4255 12.7729 18.4628 12.7884 18.4903 12.8159C18.5178 12.8434 18.5333 12.8807 18.5333 12.9196V14.5729C18.5366 14.5991 18.547 14.6238 18.5632 14.6446C18.5795 14.6653 18.601 14.6813 18.6256 14.6908C18.6501 14.7004 18.6768 14.7031 18.7028 14.6988C18.7288 14.6945 18.7531 14.6833 18.7733 14.6663Z"
            fill="white"
            fillOpacity="0.3"
          />
          <path
            d="M15.7202 12.0009C15.7245 11.3072 15.5348 10.6262 15.1724 10.0347C14.8101 9.44317 14.2896 8.96475 13.6697 8.65345C13.0498 8.34216 12.3552 8.21038 11.6644 8.27301C10.9736 8.33563 10.314 8.59017 9.76023 9.00787C9.20643 9.42556 8.78045 9.98978 8.53039 10.6368C8.28033 11.2838 8.21617 11.9879 8.34514 12.6694C8.4741 13.351 8.79107 13.9829 9.26023 14.4939C9.7294 15.0048 10.3321 15.3744 11.0002 15.5609V16.8275C10.5462 17.0621 10.1842 17.4424 9.97224 17.9074C9.76028 18.3724 9.71068 18.8951 9.83139 19.3917C9.9521 19.8882 10.2361 20.3298 10.6379 20.6456C11.0397 20.9614 11.5359 21.1331 12.0469 21.1331C12.5579 21.1331 13.0541 20.9614 13.4558 20.6456C13.8576 20.3298 14.1416 19.8882 14.2623 19.3917C14.3831 18.8951 14.3335 18.3724 14.1215 17.9074C13.9095 17.4424 13.5475 17.0621 13.0935 16.8275V15.4942C13.8461 15.2672 14.5067 14.8061 14.9791 14.1778C15.4515 13.5495 15.7111 12.7869 15.7202 12.0009Z"
            fill="white"
          />
          <path
            d="M12.0135 14.1462C13.2065 14.1462 14.1735 13.1791 14.1735 11.9862C14.1735 10.7932 13.2065 9.82617 12.0135 9.82617C10.8206 9.82617 9.85352 10.7932 9.85352 11.9862C9.85352 13.1791 10.8206 14.1462 12.0135 14.1462Z"
            fill={color}
          />
        </g>
        <defs>
          <clipPath id="clip0_551_89843">
            <rect width="24" height="24" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </SvgIcon>
  );
};

AzLbSvgIcon.muiName = "AzLbSvgIcon";

export default AzLbSvgIcon;
