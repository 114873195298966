import { FC } from "react";
import { Box, CardHeader, Dialog, Theme } from "@mui/material";
import { indigo } from "@mui/material/colors";
import { LiveUsageMgmtResourceCardHeaderTitle } from "./resource-card-header/LiveUsageMgmtResourceCardHeaderTitle";
import { LiveUsageMgmtResourceCardHeaderFullScreenButton } from "./resource-card-header/LiveUsageMgmtResourceCardHeaderFullScreenButton";
import { LiveUsageMgmtResourceCardGrid } from "./resource-card-content/resource-card-data/resource-card-grid/LiveUsageMgmtResourceCardGrid";
import { ResourceType } from "../../../../../services/cloudchipr.api";

interface LiveUsageMgmtResourceCardDialogProps {
  onFullScreen(): void;
  resourceType: ResourceType;
}

export const LiveUsageMgmtResourceCardDialog: FC<
  LiveUsageMgmtResourceCardDialogProps
> = ({ onFullScreen, resourceType }) => {
  return (
    <Dialog
      open={true}
      onClose={onFullScreen}
      maxWidth="xxl"
      sx={{
        borderRadius: 3,
        zIndex: (theme: Theme) => theme.zIndex.drawer,
      }}
    >
      <Box height="99dvh">
        <CardHeader
          title={
            <LiveUsageMgmtResourceCardHeaderTitle resourceType={resourceType} />
          }
          titleTypographyProps={{ color: "grey.50", variant: "h6" }}
          sx={cardHeaderSx}
          action={
            <LiveUsageMgmtResourceCardHeaderFullScreenButton
              onClick={onFullScreen}
            />
          }
        />
        <LiveUsageMgmtResourceCardGrid resourceType={resourceType} fullHeight />
      </Box>
    </Dialog>
  );
};

const cardHeaderSx = {
  py: 1.5,
  px: 2,
  bgcolor: indigo[50],
  ".MuiCardHeader-action": {
    minWidth: 34,
    alignSelf: "center",
  },
};
