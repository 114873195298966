import { FC } from "react";
import { CommitmentUtilizationWidgetCosts } from "./CommitmentUtilizationWidgetCosts";
import { useAppSelector } from "../../../../../../../store/hooks";
import { organisationQuarterStartMonthSelector } from "../../../../../../../store/common/selectors/org-current-settings/organisationQuarterStartMonthSelector";
import { commitmentUtilizationWidgetByWidgetIdDataSelector } from "../../../../../../../store/dashboards/selectors/widgets/commitment-utilization/widget-view/commitmentUtilizationWidgetByWidgetIdDataSelector";
import { commitmentUtilizationWidgetAggregationSelector } from "../../../../../../../store/dashboards/selectors/widgets/commitment-utilization/widget-view/commitmentUtilizationWidgetAggregationSelector";
import { commitmentUtilizationWidgetDatesSelector } from "../../../../../../../store/dashboards/selectors/widgets/commitment-utilization/widget-view/commitmentUtilizationWidgetDatesSelector";

interface CommitmentUtilizationWidgetContentCostsProps {
  widgetId: string;
}

export const CommitmentUtilizationWidgetContentCosts: FC<
  CommitmentUtilizationWidgetContentCostsProps
> = ({ widgetId }) => {
  const aggregation = useAppSelector((state) =>
    commitmentUtilizationWidgetAggregationSelector(state, widgetId),
  );
  const dates = useAppSelector((state) =>
    commitmentUtilizationWidgetDatesSelector(state, widgetId),
  );
  const data = useAppSelector((state) =>
    commitmentUtilizationWidgetByWidgetIdDataSelector(state, widgetId),
  );
  const quarterStartMonth = useAppSelector(
    organisationQuarterStartMonthSelector,
  );

  return (
    <CommitmentUtilizationWidgetCosts
      data={data}
      dates={dates}
      quarterStartMonth={quarterStartMonth}
      aggregation={aggregation}
    />
  );
};
