import { FC } from "react";
import { Button, Stack, Tooltip } from "@mui/material";
import { OpenInResourceExplorerButton } from "./OpenInResourceExplorerButton";

interface FiltersCountButtonCellProps {
  count: number;
  onClick(): void;
  dimensionId: string;
  categoryId?: string;
}

export const FiltersCountButtonCell: FC<FiltersCountButtonCellProps> = ({
  count,
  onClick,
  dimensionId,
  categoryId,
}) => {
  return (
    <Stack direction="row" spacing={1} alignItems="center">
      <Tooltip title="View Filters" placement="top" arrow>
        <Button variant="outlined" size="xsmall" onClick={onClick}>
          {count} Filter{count > 1 && "s"}
        </Button>
      </Tooltip>

      <OpenInResourceExplorerButton
        dimensionId={dimensionId}
        categoryId={categoryId}
      />
    </Stack>
  );
};
