import { FC, useMemo } from "react";
import { Stack, Typography } from "@mui/material";
import { InfoDataChip } from "./widget-header/source-and-name/InfoDataChip";
import { GroupingOptionIcon } from "../../../../common/resource-explorer-grouping-selector/components/GroupingOptionIcon";
import {
  ResourceExplorerGrouping,
  useGetUsersMeOrganisationsCurrentResourceExplorerFiltersByFilterProviderAndFilterTypeValuesQuery as useValuesQuery,
} from "../../../../../../services/cloudchipr.api";
import { getResourcesExplorerGroupingLabel } from "../../../../resource-explorer/components/resource-explorer-card/utils/helpers/getResourcesExplorerGroupingLabel";

interface WidgetGroupingChipProps {
  groupBy: ResourceExplorerGrouping;
  groupValues?: string[];
}

export const WidgetGroupingChip: FC<WidgetGroupingChipProps> = ({
  groupValues,
  groupBy,
}) => {
  const { data, isError } = useValuesQuery(
    { filterProvider: "dimensions", filterType: "dimension_id" },
    { skip: groupBy !== "category" },
  );

  const dimensionName = useMemo(() => {
    if (groupBy !== "category") {
      return "";
    }

    if (isError) {
      return "Dimension";
    }

    const firstValue = groupValues?.at(0);
    const selected = data?.find((option) => option.value === firstValue);

    return selected?.title ?? selected?.value ?? "";
  }, [groupValues, groupBy, data, isError]);

  const label =
    groupBy === "category"
      ? dimensionName
      : getResourcesExplorerGroupingLabel(groupBy ?? "service", true);

  return (
    <InfoDataChip>
      <Stack spacing={0.5} direction="row">
        <Typography variant="inherit">Group By:</Typography>
        <GroupingOptionIcon grouping={groupBy} />
        <Typography
          component="span"
          variant="inherit"
          fontWeight="medium"
          color="text.primary"
        >
          {label}
        </Typography>
      </Stack>
    </InfoDataChip>
  );
};
