import { RootState } from "../../../../store";
import { PostUsersMeOrganisationsCurrentTasksApiArg } from "../../../../../services/cloudchipr.api";
import { taskManagementActionSelector } from "../properties/taskManagementActionSelector";
import { taskManagementAssigneeSelector } from "../properties/taskManagementAssigneeSelector";
import { taskManagementDescriptionSelector } from "../properties/taskManagementDescriptionSelector";
import { taskManagementDueDateSelector } from "../properties/taskManagementDueDateSelector";
import { taskManagementEnvironmentSelector } from "../properties/taskManagementEnvironmentSelector";
import { taskManagementPrioritySelector } from "../properties/taskManagementPrioritySelector";
import { taskManagementStatusSelector } from "../properties/taskManagementStatusSelector";
import { taskManagementTargetSelector } from "../target/taskManagementTargetSelector";
import { taskManagementTargetResourcesForPayloadSelector } from "../target/resources/taskManagementTargetResourcesForPayloadSelector";
import { taskManagementNameSelector } from "../properties/taskManagementNameSelector";

type PayloadType = PostUsersMeOrganisationsCurrentTasksApiArg["body"] & {
  name?: string;
};

export const taskManagementTaskSavingPayloadSelector = (
  state: RootState,
): PayloadType | undefined => {
  const action_id = taskManagementActionSelector(state);
  const name = taskManagementNameSelector(state);
  const assignees = taskManagementAssigneeSelector(state);
  const description = taskManagementDescriptionSelector(state) || null;
  const due_date = taskManagementDueDateSelector(state);
  const environment_id = taskManagementEnvironmentSelector(state);
  const priority_id = taskManagementPrioritySelector(state);
  const status_id = taskManagementStatusSelector(state);
  const target = taskManagementTargetSelector(state);
  const targetResources =
    taskManagementTargetResourcesForPayloadSelector(state);

  if (!status_id) {
    return;
  }

  return {
    name,
    assignees,
    due_date,
    action_id,
    status_id,
    priority_id,
    description,
    environment_id,
    target: { type: target?.type, resources: targetResources },
  };
};
