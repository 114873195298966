import { FC, useCallback } from "react";
import { Switch } from "@mui/material";
import { useToggle } from "rooks";
import { AlertTabType } from "../../../../../../../store/alerts/utils/types/types";
import { AlertStatus } from "../../../../../../../services/cloudchipr.api";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../store/hooks";
import { toggleAlertStatusByIdAndTypeThunk } from "../../../../../../../store/alerts/thunks/common/toggleAlertStatusByIdAndTypeThunk";
import { toggleAlertStatusLoadingSelector } from "../../../../../../../store/alerts/selectors/common/toggleAlertStatusLoadingSelector";
import { useAppAbility } from "../../../../../../../services/permissions";

interface AlertsDataGridSwitchProps {
  id: string;
  status: AlertStatus;
  alertsActiveTab: AlertTabType;
}

export const AlertsDataGridSwitch: FC<AlertsDataGridSwitchProps> = ({
  alertsActiveTab,
  status,
  id,
}) => {
  const { cannot } = useAppAbility();
  const cannotEdit = cannot("edit", "alert");

  const dispatch = useAppDispatch();
  const [checked, toggleChecked] = useToggle(status === "active");
  const loading = useAppSelector((state) =>
    toggleAlertStatusLoadingSelector(state, id, alertsActiveTab),
  );

  const handleClick = useCallback(() => {
    toggleChecked();
    dispatch(
      toggleAlertStatusByIdAndTypeThunk({ id, status, alertsActiveTab }),
    );
  }, [toggleChecked, dispatch, id, status, alertsActiveTab]);

  return (
    <Switch
      size="small"
      checked={checked}
      onClick={handleClick}
      disabled={loading || cannotEdit}
    />
  );
};
