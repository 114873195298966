import { createDraftSafeSelector } from "@reduxjs/toolkit";
import { usersListSelector } from "./usersListSelector";

export const activeUsersNameEmailSelector = createDraftSafeSelector(
  [usersListSelector],
  (users) => {
    return users
      ?.filter((user) => user.status === "active")
      .map((user) => user.name ?? user.email);
  },
);
