import { Row } from "@tanstack/react-table";
import {
  CostAnomalyAlertsDataGridDataType,
  UtilizationAlertDataGridDataType,
} from "../../../../../../../../store/alerts/utils/types/types";
import { joinThresholds } from "../../../cost-anomaly/utils/helpers/joinThresholds";
import { isCostAnomalyAlertsDataGridDataType } from "../../../cost-anomaly/utils/helpers/isCostAnomalyAlertsDataGridDataType";

export const alertsThresholdFilter = <
  T extends
    | CostAnomalyAlertsDataGridDataType
    | UtilizationAlertDataGridDataType,
>(
  row: Row<T>,
  _: string,
  keyword: string,
) => {
  const isCostAnomaly = isCostAnomalyAlertsDataGridDataType(row.original);
  const joinedThresholds = joinThresholds(
    isCostAnomaly
      ? ((row.original as CostAnomalyAlertsDataGridDataType).threshold
          ?.threshold_items ?? [])
      : row.original.thresholds,
  );
  const filterValue = keyword.toLowerCase().trim();

  return joinedThresholds.some((item) => item.includes(filterValue));
};
