import { liveUsageMgmtSelectedResourcesByResourceTypeSelector } from "./liveUsageMgmtSelectedResourcesByResourceTypeSelector";
import { RootState } from "../../../store";
import { ResourceType } from "../../../../services/cloudchipr.api";

export const liveUsageMgmtResourcesCleanDisabledSelector = (
  state: RootState,
  resourceType: ResourceType,
) => {
  const selectedResources =
    liveUsageMgmtSelectedResourcesByResourceTypeSelector(state, resourceType);

  const excludedItemsCount = selectedResources?.reduce((result, item) => {
    if (
      item.resource.account.provider_access_type === "read" ||
      item.resource.marked_for_deletion ||
      item.resource.is_protected
    ) {
      result++;
    }
    return result;
  }, 0);

  return excludedItemsCount === selectedResources?.length;
};
