import { FC } from "react";
import { Box, Collapse } from "@mui/material";
import { useToggle } from "rooks";
import {
  ReportsNotificationsCustomMessageContent,
  ReportsNotificationsCustomMessageContentProps,
} from "./ReportsNotificationsCustomMessageContent";
import { ReportsCustomMessageDialog } from "./ReportsCustomMessageDialog";
import { useDialog } from "../../../../../../../utils/hooks/useDialog.hook";
import { IntegrationCustomMessageHeader } from "../../../../../automations/common/components/custom-message/IntegrationCustomMessageHeader";

interface ReportsDrawerNotificationsCustomMessageProps
  extends ReportsNotificationsCustomMessageContentProps {
  onChange(message: string): void;
}

export const ReportsNotificationsCustomMessage: FC<
  ReportsDrawerNotificationsCustomMessageProps
> = ({ type, onChange, message }) => {
  const [collapsed, toggleCollapsed] = useToggle();
  const { open, openDialog, closeDialog } = useDialog();

  if (type === "webhook") {
    return null;
  }

  return (
    <Box bgcolor="grey.100" p={1} borderRadius={1}>
      <IntegrationCustomMessageHeader
        collapsed={collapsed}
        toggleCollapsed={toggleCollapsed}
        openDialog={openDialog}
      />
      <Collapse in={collapsed}>
        <ReportsNotificationsCustomMessageContent
          type={type}
          message={message}
        />
      </Collapse>
      {open && (
        <ReportsCustomMessageDialog
          open={open}
          onClose={closeDialog}
          message={message}
          type={type}
          onChange={onChange}
        />
      )}
    </Box>
  );
};
