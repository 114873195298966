import { RootState } from "../../../../../../store";

import { reservationsDataForGranularSelector } from "../../reservationsDataForGranularSelector";

export const reservationsGranularDataTotalCommitmentSelector = (
  state: RootState,
) => {
  const data = reservationsDataForGranularSelector(state);

  return {
    cost: data?.date_granular_data?.total_commitment,
    hours: data?.date_granular_data?.total_commitment_in_hours,
  };
};
