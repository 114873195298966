import { FC } from "react";
import Typography from "@mui/material/Typography";

export const IntegrationCostAnomalyAlertSubject: FC = () => {
  return (
    <Typography variant="body1">
      Subject:{" "}
      <Typography component="span" fontWeight="medium">
        Cost anomaly alert for {`{{All Resources}}`} view.
      </Typography>
    </Typography>
  );
};
