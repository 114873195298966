import { capitalize, Typography } from "@mui/material";
import { CommitmentsRecommendationsEstimatedMonthlySavingCell } from "../cells/CommitmentsRecommendationsEstimatedMonthlySavingCell";
import { ColumnSetupType } from "../../../../../../../../storybook/data-grid/utils/types/types";
import {
  ReservationRecommendation,
  ReservationType,
} from "../../../../../../../../services/cloudchipr.api";
import {
  commitmentsPaymentOptionLabels,
  commitmentsResourceTypeNames,
  savingsPlanLockBackPeriodLabels,
  savingsPlanTermLabels,
} from "../../../../../common/utils/constants/labels";
import { AccountCell } from "../../../../../common/components/data-grid/cells/AccountCell";
import { TypographyWithTooltip } from "../../../../../../../common/TypographyWithTooltip";
import { EMSCell } from "../../../../../common/components/data-grid/cells/EMSCell";

export const reservationsRecommendationsDataGridColumns: ColumnSetupType<ReservationRecommendation>[] =
  [
    {
      minSize: 100,
      accessorKey: "reservation_type",
      header: "Type",
      cell: ({ row }) => (
        <TypographyWithTooltip
          title={
            commitmentsResourceTypeNames[
              row.original.reservation_type as ReservationType
            ]
          }
          pl={1}
          variant="body2"
          textTransform="capitalize"
        />
      ),
      meta: { headerTitle: "Type", hideFromSettings: true },
    },
    {
      size: 130,
      minSize: 100,
      accessorKey: "term_in_years",
      header: "Term",
      cell: ({ row }) => (
        <Typography variant="body2">
          {savingsPlanTermLabels[row.original.term_in_years]}
        </Typography>
      ),
      meta: { headerTitle: "Reservation term" },
    },
    {
      size: 220,
      minSize: 100,
      accessorKey: "account_scope",
      header: "Recommendation Level",
      cell: ({ row }) => (
        <Typography variant="body2" textTransform="capitalize">
          {row.original.account_scope}
        </Typography>
      ),
      meta: { headerTitle: "Recommendation Level" },
    },
    {
      size: 250,
      minSize: 180,
      accessorKey: "account",
      header: "Account",
      cell: ({ row }) => (
        <AccountCell
          accountId={row.original.account?.provider_account_id}
          accountName={row.original.account?.provider_account_name}
          isRootAccount={row.original.account?.is_provider_root_account}
        />
      ),
      meta: { headerTitle: "Account" },
    },
    {
      size: 100,
      accessorKey: "instance_type",
      header: "Instance Type",
    },
    {
      size: 160,
      accessorKey: "instance_count",
      header: "Instance Count",
    },
    {
      size: 130,
      minSize: 120,
      accessorKey: "platform",
      header: "Platform",
      cell: ({ row }) => (
        <TypographyWithTooltip
          variant="inherit"
          title={row.original.platform ?? "-"}
        />
      ),
    },
    {
      size: 150,
      minSize: 110,
      accessorKey: "region",
      header: "Region",
    },
    {
      size: 150,
      accessorKey: "offering_class",
      header: "Offering Class",
      cell: ({ row }) => capitalize(row.original.offering_class ?? "-"),
    },
    {
      size: 170,
      accessorKey: "payment_option",
      header: "Payment option",
      cell: ({ row }) =>
        commitmentsPaymentOptionLabels[row.original.payment_option],
    },
    {
      size: 170,
      minSize: 150,
      accessorKey: "lookback_period",
      header: "Based On Past",
      cell: ({ row }) =>
        savingsPlanLockBackPeriodLabels[row.original.lookback_period],
    },
    {
      minSize: 100,
      enableResizing: false,
      accessorKey: "estimated_monthly_savings",
      header: EMSCell,
      cell: ({ row }) => (
        <CommitmentsRecommendationsEstimatedMonthlySavingCell
          value={row.original.estimated_monthly_savings}
          link={row.original.action_url}
        />
      ),
      headerTooltip: "Estimated Monthly Savings",
      meta: {
        sticky: "right",
        hideFromSettings: true,
        cellStyles: {
          bgcolor: "white",
        },
      },
    },
  ];
