import { createAsyncThunk } from "@reduxjs/toolkit";
import { closeSnackbar, enqueueSnackbar, SnackbarKey } from "notistack";

import { getDashboardsVisibilityHierarchyThunk } from "./getDashboardsVisibilityHierarchyThunk";
import {
  cloudChiprApi,
  PostV2UsersMeOrganisationsCurrentDashboardsApiArg,
} from "../../../../services/cloudchipr.api";

export const createDashboardVisibilityHierarchyFolderThunkFixedCacheKey =
  "createDashboardVisibilityHierarchyFolderThunk-fixedCacheKey";

interface CreateDashboardHierarchyFolderThunkArgs {
  name: string;
  visibility: PostV2UsersMeOrganisationsCurrentDashboardsApiArg["body"]["visibility"];
}
export const createDashboardVisibilityHierarchyFolderThunk = createAsyncThunk(
  "dashboard/createDashboardVisibilityHierarchyFolder",
  async (
    { name, visibility }: CreateDashboardHierarchyFolderThunkArgs,
    { dispatch },
  ) => {
    const { postV2UsersMeOrganisationsCurrentDashboardFolders } =
      cloudChiprApi.endpoints;

    try {
      const response = await dispatch(
        postV2UsersMeOrganisationsCurrentDashboardFolders.initiate(
          {
            body: {
              name: name,
              visibility,
            },
          },
          {
            fixedCacheKey:
              createDashboardVisibilityHierarchyFolderThunkFixedCacheKey,
          },
        ),
      ).unwrap();

      if (response) {
        dispatch(getDashboardsVisibilityHierarchyThunk());
        const snackbarId: SnackbarKey = enqueueSnackbar(
          "Folder successfully created.",
          {
            variant: "snackbarAlert",
            AlertSnackBarProps: {
              severity: "success",
              onClose: () => closeSnackbar(snackbarId),
            },
          },
        );
      }
    } catch (e) {
      // @ts-expect-error TODO: return to this after adding error type
      const errMessage = e?.data?.message || "Something went wrong";
      const snackbarId: SnackbarKey = enqueueSnackbar(errMessage, {
        variant: "snackbarAlert",
        AlertSnackBarProps: {
          severity: "error",
          onClose: () => closeSnackbar(snackbarId),
        },
      });
    }
  },
);
