import { FC, Fragment, memo, useCallback, useEffect, useMemo } from "react";
import { FormikHandlers } from "formik";
import { SelectChangeEvent } from "@mui/material";
import { FilterItemOperatorType } from "../../../FilterItemOperatorType";
import {
  FilterItem,
  Operator,
  ResourceType,
  usePostUsersMeAccountsByTypeResourcesMutation,
} from "../../../../../../../../services/cloudchipr.api";
import {
  AutocompleteFilterBody,
  AutocompleteFilterBodyProps,
} from "../AutocompleteFilterBody";
import { multipleValueSelectOperators } from "../../../../utils/constants/filter-types/autocomplete-multipe-selection-operator-types";

interface NameFilterProps extends AutocompleteFilterBodyProps {
  accountIds?: string[];
  resourceType: ResourceType;
  filter: FilterItem;
  operators?: Operator[];
  onChange: FormikHandlers["handleChange"];
}

export const NameFilter: FC<NameFilterProps> = memo(
  ({
    setFieldValue,
    resourceType,
    accountIds,
    operators,
    filter,
    onChange,
    ...props
  }) => {
    const [trigger, { data, isLoading }] =
      usePostUsersMeAccountsByTypeResourcesMutation();

    const singleSelect = !multipleValueSelectOperators.includes(
      filter?.operator,
    );

    const names = useMemo(() => {
      return data?.reduce((result, item) => {
        item.resources.forEach((resource) => {
          if (resource.name) {
            result.push(resource.name);
          }
        });

        return result;
      }, [] as string[]);
    }, [data]);

    const selectedValue = useMemo(() => {
      const value = filter?.value;

      const stringValue = value && typeof value === "string" ? [value] : [];
      return Array.isArray(value) ? value : stringValue;
    }, [filter?.value]);

    const operatorChangeHandler = useCallback(
      (event: SelectChangeEvent) => {
        const operator = event.target.value;

        if (
          !multipleValueSelectOperators.includes(filter.operator) ||
          !multipleValueSelectOperators.includes(operator)
        ) {
          setFieldValue("value.value", "");
        }

        onChange(event);
      },
      [onChange, setFieldValue, filter.operator],
    );

    const changeHandler = useCallback(
      (_: any, values: string[]) => {
        const value = singleSelect ? values[0] : values;
        setFieldValue("value", value);
      },
      [setFieldValue, singleSelect],
    );

    useEffect(() => {
      if (!accountIds) {
        return;
      }
      trigger({
        type: resourceType,
        body: { account_ids: accountIds },
      });
    }, [resourceType, accountIds, trigger]);

    return (
      <Fragment>
        <FilterItemOperatorType
          value={filter.operator || "in"}
          options={operators}
          onChange={operatorChangeHandler}
        />

        <AutocompleteFilterBody
          {...props}
          freeSolo
          label="Add or select names"
          options={names}
          isLoading={isLoading}
          singleSelect={singleSelect}
          // @ts-expect-error | types looks ok, can't understand what's the reason
          setFieldValue={changeHandler}
          selectedValue={selectedValue}
        />
      </Fragment>
    );
  },
);
