import { FC, Fragment, useCallback } from "react";
import { ConfirmationDialog } from "../../../../../../common/modals/ConfirmationDialog";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../store/hooks";
import { recommendationResourceRestoreLoadingSelector } from "../../../../../../../store/recommendations/selectors/dismiss/resources/recommendationResourceRestoreLoadingSelector";
import { offHoursRecommendationsPageLoadingSelector } from "../../../../../../../store/recommendations/selectors/off-hours/loading/offHoursRecommendationsPageLoadingSelector";
import { rightsizingRecommendationsPageLoadingSelector } from "../../../../../../../store/recommendations/selectors/rightsizing/loading/rightsizingRecommendationsPageLoadingSelector";
import {
  closeReadyToActionsDialogs,
  resetDismiss,
} from "../../../../../../../store/recommendations/recommendationsSlice";
import { restoreRightsizingRecommendationResourceThunk } from "../../../../../../../store/recommendations/thunks/rightsizing/dismiss/restoreRightsizingRecommendationResourceThunk";
import { restoreOffHoursRecommendationResourceThunk } from "../../../../../../../store/recommendations/thunks/off-hours/dismiss/resource/restoreOffHoursRecommendationResourceThunk";
import { selectedIdsSelector } from "../../../../../../../store/recommendations/selectors/selected-ids/selectedIdsSelector";
import { recommendationsReadyToRestoreSelector } from "../../../../../../../store/recommendations/selectors/dismiss/resources/resourcesReadyToRestoreSelector";
import { recommendationMessages } from "../../../../utils/constants/recommendationDismissMessages";
import { recommendationsDismissedResourcesNamesSelector } from "../../../../../../../store/recommendations/selectors/dismiss/recommendationsDismissedResourcesNamesSelector";

interface ResourceRestoreConfirmDialogProps {
  recommendationType: "off-hours" | "rightsizing";
}

export const ResourceRestoreConfirmDialog: FC<
  ResourceRestoreConfirmDialogProps
> = ({ recommendationType }) => {
  const dispatch = useAppDispatch();

  const open = useAppSelector(recommendationsReadyToRestoreSelector);
  const ids = useAppSelector(selectedIdsSelector);
  const multiple = ids?.length > 1;
  const restoring = useAppSelector(
    recommendationResourceRestoreLoadingSelector,
  );
  const offHoursLoading = useAppSelector(
    offHoursRecommendationsPageLoadingSelector,
  );
  const rightsizingLoading = useAppSelector(
    rightsizingRecommendationsPageLoadingSelector,
  );

  const loading = rightsizingLoading || offHoursLoading || restoring;

  const closeHandler = useCallback(() => {
    dispatch(closeReadyToActionsDialogs());
  }, [dispatch]);

  const restoreHandler = useCallback(async () => {
    if (recommendationType === "rightsizing") {
      await dispatch(restoreRightsizingRecommendationResourceThunk());
    } else {
      await dispatch(restoreOffHoursRecommendationResourceThunk());
    }
    dispatch(resetDismiss());
  }, [dispatch, recommendationType]);

  const resourceNames = useAppSelector((state) =>
    recommendationsDismissedResourcesNamesSelector(
      state,
      ids,
      recommendationType,
    ),
  );

  const confirmationMessage = recommendationMessages({
    action: "restore",
    multiple: multiple,
    resource: true,
  });

  return (
    <ConfirmationDialog
      open={open}
      dialogContent={
        <Fragment>
          {confirmationMessage}
          <br />
          <strong>{resourceNames}</strong>
        </Fragment>
      }
      title={`Restore resource${multiple ? "s" : ""}?`}
      CancelButtonProps={{ onClick: closeHandler }}
      ConfirmButtonProps={{
        loading: loading,
        children: "Restore",
        onClick: restoreHandler,
      }}
      onClose={closeHandler}
    />
  );
};
