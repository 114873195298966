import { createDraftSafeSelector } from "@reduxjs/toolkit";
import { utilizationAndCoverageDetailsDataSelector } from "./utilizationAndCoverageDetailsDataSelector";
import { SavingsPlanCoverageDataWithId } from "../../../../../../components/pages/commtiments/common/utils/types";
import { addIdInCoverageData } from "../../../../utils/helpers/addIdInCoverageData";
import { SavingsPlanCoverageData } from "../../../../../../services/cloudchipr.api";

export const utilizationAndCoverageDetailsCoverageDataSelector =
  createDraftSafeSelector(
    [utilizationAndCoverageDetailsDataSelector],
    (data): SavingsPlanCoverageDataWithId[] | undefined => {
      return addIdInCoverageData<SavingsPlanCoverageData>(data?.coverage_data);
    },
  );
