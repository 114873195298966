import { FC, useCallback } from "react";
import { Button, Stack, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import NotFoundImage from "../../assets/images/not-found.png";

export const NotFound: FC = () => {
  const navigate = useNavigate();
  const backToHomeClickHandler = useCallback(() => {
    navigate("/", {
      replace: true,
    });
  }, [navigate]);

  return (
    <Stack alignItems="center" gap={2} height="100vh" justifyContent="center">
      <img src={NotFoundImage} alt="EmptyDashboard" />
      <Typography variant="h4" fontWeight="medium">
        Oops! Page Not Found
      </Typography>
      <Typography variant="body1" color="text.secondary">
        We apologize, but the page you are looking for seems to be missing.
      </Typography>
      <Button
        variant="contained"
        onClick={backToHomeClickHandler}
        sx={{ mt: 3 }}
      >
        Back to Home
      </Button>
    </Stack>
  );
};
