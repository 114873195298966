import { FC, ReactNode } from "react";
import PlayCircleFilledWhiteOutlinedIcon from "@mui/icons-material/PlayCircleFilledWhiteOutlined";
import { Stack, Tooltip, Typography } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { LiveIndicator } from "./LiveIndicator";

interface LiveFilteredTitleProps {
  tooltipTitle: string;
  large?: boolean;
  customTextComponent?: ReactNode;
}

export const LiveFilteredTitle: FC<LiveFilteredTitleProps> = ({
  tooltipTitle,
  large,
  customTextComponent,
}) => {
  return (
    <Stack direction="row" spacing={0.5} alignItems="center">
      <LiveIndicator />

      <PlayCircleFilledWhiteOutlinedIcon
        sx={{
          fontSize: large ? 20 : 16,
        }}
        color={!large ? "action" : undefined}
      />

      {customTextComponent || (
        <Typography
          variant={large ? "body2" : "subtitle2"}
          color={large ? "text.primary" : "primary"}
          whiteSpace="nowrap"
          fontSize={!large ? 12 : undefined}
        >
          Live Filtered
        </Typography>
      )}

      <Tooltip arrow title={tooltipTitle} placement="top">
        <InfoOutlinedIcon color="action" sx={{ fontSize: 16 }} />
      </Tooltip>
    </Stack>
  );
};
