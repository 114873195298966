import { FC, Fragment } from "react";
import { Box } from "@mui/material";
import { Table } from "@tanstack/react-table";
import { PaginationConfig } from "../utils/types/prop-types";
import { Pagination as CommonPagination } from "../common/Pagination";

interface PaginationProps {
  table: Table<any>;
  pagination: PaginationConfig;
}

export const Pagination: FC<PaginationProps> = ({ table, pagination }) => {
  if (!pagination) {
    return null;
  }

  if (typeof pagination === "boolean") {
    return (
      <Box p={2}>
        <CommonPagination table={table} />
      </Box>
    );
  }

  if (pagination.renderPagination) {
    return <Fragment>{pagination.renderPagination(table)}</Fragment>;
  }

  return table.getPageCount() > 1 ? (
    <Box p={2}>
      <CommonPagination
        table={table}
        onPageChange={pagination?.onPageChange}
        placement={pagination?.placement}
      />
    </Box>
  ) : null;
};
