import { FC, Fragment, ReactNode } from "react";
import { Navigate, Outlet, useOutletContext } from "react-router-dom";
import { Subjects, useAppAbility } from "../../../services/permissions";
import { OutletContextType } from "../../layout/MainLayout";

interface ViewAccessCheckProps {
  subject: Subjects;
  redirectUrl?: string;
  children?: ReactNode;
}

export const ViewAccessCheck: FC<ViewAccessCheckProps> = ({
  subject,
  redirectUrl,
  children,
}) => {
  const { cannot } = useAppAbility();
  const cannotView = cannot("view", subject);

  const outletContext = useOutletContext<OutletContextType>();

  if (cannotView) {
    return <Navigate to={redirectUrl ?? "/access-denied"} replace />;
  }

  return <Fragment>{children || <Outlet context={outletContext} />}</Fragment>;
};
