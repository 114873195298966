import { FC } from "react";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import TagOutlinedIcon from "@mui/icons-material/TagOutlined";
import { SlackConversation } from "../../../../../../../../services/cloudchipr.api";

const icons = {
  private_channel: LockOutlinedIcon,
  member: PersonOutlineOutlinedIcon,
  public_channel: TagOutlinedIcon,
};

interface SlackConversationIconProps {
  type: SlackConversation["type"];
}

export const SlackConversationIcon: FC<SlackConversationIconProps> = ({
  type,
}) => {
  if (!type) {
    return null;
  }

  const Icon = icons[type];

  return <Icon color="action" fontSize="small" />;
};
