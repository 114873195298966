import { Row } from "@tanstack/react-table";
import { MetricsFrequenciesKeysType } from "../../types/metricsFrequenciesType";
import { MetricDataType } from "../types";
import { getMetricsByType } from "../../../cells/metrics-cells/utils/helpers/getMetricsByType";
import { metricsChartValueGenerator } from "../../csv-data/valueGenerators/metricsChartValueGenerator";
import { getMetricsTypeAndOptionById } from "../constants";

export const getMetricsAggregatedData = (
  row: Row<any>,
  duration: string,
  id: MetricsFrequenciesKeysType,
) => {
  const accessorKey = "inline_metrics";
  const data = (
    accessorKey in row.original.resource
      ? row.original.resource[accessorKey]
      : undefined
  ) as MetricDataType;

  if (!data) {
    return null;
  }

  const { type, option } = getMetricsTypeAndOptionById.get(id) ?? {};

  if (!type || !option) {
    return null;
  }

  const metrics = getMetricsByType(data, type);
  const value = metricsChartValueGenerator(duration, option, metrics);

  if (value === "N/A") {
    return null;
  }

  return Number(value);
};
