import { recommendationsDataGridDataSelector } from "./recommendationsDataGridDataSelector";
import { recommendationDismissedDataSelector } from "./recommendationsDataGridDimissedDataSelector";
import { RootState } from "../../../../store";
import {
  RecommendationDataType,
  RecommendationGroupType,
} from "../../../../../components/pages/recommendations/components/off-hours-recommendations/utils/types/types";
import { RecommendationsTabType } from "../../../../../components/pages/recommendations/components/off-hours-recommendations/components/OffHoursRecommendationsContent";
import { GetUsersMeOrganisationsCurrentDismissedResourcesApiResponse } from "../../../../../services/cloudchipr.api";
import { offHoursRecommendationsDismissedResourcesDataByGroupSelector } from "../dismissed-resources/offHoursRecommendationsDismissedResourcesDataByGroupSelector";

export const offHoursDataGridDataByTabSelector = (
  state: RootState,
  group: RecommendationGroupType,
  tab: RecommendationsTabType,
):
  | RecommendationDataType[]
  | GetUsersMeOrganisationsCurrentDismissedResourcesApiResponse => {
  if (tab === "active") {
    return recommendationsDataGridDataSelector(state, group);
  }
  if (tab === "dismissed") {
    return recommendationDismissedDataSelector(state, group);
  }

  return (
    offHoursRecommendationsDismissedResourcesDataByGroupSelector(
      state,
      group,
    ) ?? []
  );
};
