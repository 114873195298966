import { protectorResourcesDataSelector } from "./protectorResourcesDataSelector";
import { RootState } from "../../../store";
import { ResourceType } from "../../../../services/cloudchipr.api";
import { getActionValue } from "../../../../utils/clean-options";

export const protectorResourceActionsByResourceTypeSelector = (
  state: RootState,
  scheduleId?: string,
  batchId?: string,
): Record<ResourceType, string> | undefined => {
  const protectorResources = protectorResourcesDataSelector(
    state,
    scheduleId,
    batchId,
  );

  return protectorResources?.reduce(
    (acc, resource) => {
      acc[resource.resource_type] = getActionValue(
        resource.action,
        resource.snapshot,
      );

      return acc;
    },
    {} as Record<ResourceType, string>,
  );
};
