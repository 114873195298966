import { FC, useCallback } from "react";
import {
  Divider,
  Popover,
  PopoverOrigin,
  PopoverProps,
  Stack,
} from "@mui/material";
import { DateRangePickerDateLabelsSection } from "./DateRangePickerDateLabelsSection";
import { DateRangePickerDateIntervalSection } from "./date-interval-section/DateRangePickerDateIntervalSection";
import { DateLabel, Dates } from "../../../../services/cloudchipr.api";
import { PreDefinedRanges } from "../utils/constants/types";

interface DateRangePickerPopoverProps {
  open: boolean;
  maxDate?: string;
  minDate?: string;
  availableMinDate?: string;
  onClose(): void;
  selectedDates: Dates;
  highlightToday?: boolean;
  ranges: PreDefinedRanges;
  anchor: HTMLElement | null;
  quarterStartMonth?: number;
  onDateSelect(dates: Dates): void;
  PopoverProps?: Partial<Omit<PopoverProps, "open">>;
}

export const DateRangePickerPopover: FC<DateRangePickerPopoverProps> = ({
  open,
  onClose,
  anchor,
  highlightToday,
  onDateSelect,
  selectedDates,
  minDate,
  availableMinDate,
  maxDate,
  ranges,
  quarterStartMonth,
  PopoverProps,
}) => {
  const labelSelectHandler = useCallback(
    (label: DateLabel) => {
      onDateSelect({ label, from: null, to: null });
      onClose();
    },
    [onDateSelect, onClose],
  );

  return (
    <Popover
      open={open}
      onClose={onClose}
      anchorEl={anchor}
      anchorOrigin={anchorOrigin}
      transformOrigin={transformOrigin}
      {...PopoverProps}
    >
      <Stack direction="row">
        <DateRangePickerDateLabelsSection
          ranges={ranges}
          minDate={minDate}
          onSelect={labelSelectHandler}
          selectedLabel={selectedDates.label ?? null}
        />

        <Divider orientation="vertical" flexItem />

        <DateRangePickerDateIntervalSection
          onClose={onClose}
          minDate={minDate}
          maxDate={maxDate}
          onDateSelect={onDateSelect}
          selectedDates={selectedDates}
          highlightToday={highlightToday}
          availableMinDate={availableMinDate}
          quarterStartMonth={quarterStartMonth}
        />
      </Stack>
    </Popover>
  );
};

const transformOrigin: PopoverOrigin = { vertical: -5, horizontal: 0 };
const anchorOrigin: PopoverOrigin = { vertical: "bottom", horizontal: "left" };
