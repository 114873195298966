import { FC, useCallback, useMemo, useState } from "react";
import {
  DndContext,
  useSensor,
  useSensors,
  DragStartEvent,
  DragMoveEvent,
  DragEndEvent,
  DragOverEvent,
  MeasuringStrategy,
  UniqueIdentifier,
  closestCenter,
} from "@dnd-kit/core";
import {
  SortableContext,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { useDidMount } from "rooks";
import { useLocation } from "react-router-dom";

import { SortableNavItemOverlay } from "./SortableNavItemOverlay";
import { SortableTreeItemWrapper } from "./SortableTreeItemWrapper";
import {
  TreeItems,
  TreeItemDraggingData,
  SortableTreeOnchangeArgs,
} from "./utils/types";
import {
  SortableTreeContent,
  TreeItemExtendedProps,
} from "./SortableTreeContent";
import { PointerSensor } from "./utils/helpers/PointerSensor";
import { getTreeItemIndexById } from "./utils/helpers/getTreeItemIndexById";
import { flattenSortableTreeItems } from "./utils/helpers/flattenSortableTreeItems";
import { generateTreeItemsAfterDrop } from "./utils/helpers/generateTreeItemsAfterDrop";
import { getSortableTreeItemProjection } from "./utils/helpers/getSortableTreeItemProjection";

interface SortableTreeProps extends TreeItemExtendedProps {
  items?: TreeItems;
  indentationWidth?: number;
  onChange(args: SortableTreeOnchangeArgs): void;
  onReset(): void;
  disabled?: boolean;
}

export const SortableTree: FC<SortableTreeProps> = ({
  items,
  indentationWidth = 20,
  renderFolderAction,
  renderItemAction,
  onChange,
  getNavigateTo,
  onReset,
  icon,
  rightIcon,
  disabled,
  emptyText,
}) => {
  const location = useLocation();
  const [offsetLeft, setOffsetLeft] = useState(0);

  const [expandedItems, setExpandedItems] = useState<
    Record<UniqueIdentifier, boolean>
  >({});

  const [overData, setOverData] = useState<TreeItemDraggingData | null>(null);

  const [activeData, setActiveData] = useState<TreeItemDraggingData | null>(
    null,
  );

  const flattenedItems = useMemo(() => {
    return flattenSortableTreeItems(items ?? []);
  }, [items]);

  const projected = useMemo(() => {
    if (overData) {
      return getSortableTreeItemProjection({
        items: flattenedItems,
        dragOffset: offsetLeft,
        indentationWidth,
        overData,
        activeData,
      });
    }
    return null;
  }, [activeData, flattenedItems, offsetLeft, overData, indentationWidth]);

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 7,
      },
    }),
  );

  const sortedIds = useMemo(
    () => flattenedItems.map(({ id }) => id),
    [flattenedItems],
  );

  const activeItem = useMemo(() => {
    return activeData?.id
      ? flattenedItems.find(({ id }) => id === activeData.id)
      : null;
  }, [activeData?.id, flattenedItems]);

  const resetState = useCallback(() => {
    setOverData(null);
    setActiveData(null);
    setOffsetLeft(0);
    onReset();
  }, [onReset]);

  const dragStartHandler = useCallback(
    ({ active: { id: activeId, data } }: DragStartEvent) => {
      setActiveData({
        id: activeId,
        type: data?.current?.type,
        index: data?.current?.sortable?.index,
      });
      setOverData({
        id: activeId,
        type: data?.current?.type,
        index: data?.current?.sortable?.index,
      });
    },
    [],
  );

  const dragMoveHandler = useCallback(({ delta }: DragMoveEvent) => {
    setOffsetLeft(delta.x);
  }, []);

  const dragOverHandler = useCallback(({ over }: DragOverEvent) => {
    const data = over?.id
      ? {
          id: over.id,
          type: over?.data?.current?.type,
          index: over.data?.current?.sortable?.index,
        }
      : null;

    setOverData(data);
  }, []);

  const dragEndHandler = useCallback(
    ({ active, over }: DragEndEvent) => {
      resetState();

      if (projected && over) {
        const newItems = generateTreeItemsAfterDrop({
          active,
          over,
          projected,
          items,
        });

        if (active?.id && newItems) {
          const index = getTreeItemIndexById(newItems, active.id);

          if (index !== null) {
            onChange({
              index,
              id: active.id,
              parentId: projected?.parentId,
              items: newItems,
              type: active?.data?.current?.type ?? "item",
            });
          }
        }
      }
    },
    [items, projected, onChange, resetState],
  );

  const dragCancelHandler = useCallback(() => {
    resetState();
  }, [resetState]);

  useDidMount(() => {
    const pathName = location.pathname;
    flattenedItems.forEach((item) => {
      if (pathName.startsWith(getNavigateTo(item.id))) {
        if (item.parentId) {
          setExpandedItems({
            [item.parentId]: true,
          });
        }
      }
    });
  });

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      measuring={measuring}
      onDragStart={dragStartHandler}
      onDragMove={dragMoveHandler}
      onDragOver={dragOverHandler}
      onDragEnd={dragEndHandler}
      onDragCancel={dragCancelHandler}
    >
      <SortableContext items={sortedIds} strategy={verticalListSortingStrategy}>
        <SortableTreeContent
          items={items}
          indentationWidth={indentationWidth}
          renderFolderAction={renderFolderAction}
          renderItemAction={renderItemAction}
          getNavigateTo={getNavigateTo}
          offsetLeft={offsetLeft}
          overData={overData}
          activeData={activeData}
          expandedItems={expandedItems}
          setExpandedItems={setExpandedItems}
          icon={icon}
          rightIcon={rightIcon}
          disabled={disabled}
          emptyText={emptyText}
        />
        {activeItem && (
          <SortableNavItemOverlay>
            <SortableTreeItemWrapper
              name={activeItem.name}
              id={activeItem.id}
              type={activeItem.type}
            />
          </SortableNavItemOverlay>
        )}
      </SortableContext>
    </DndContext>
  );
};

const measuring = {
  droppable: {
    strategy: MeasuringStrategy.Always,
  },
};
