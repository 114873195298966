import { FC } from "react";
import { Box, CircularProgress, Stack, Typography } from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { useAppSelector } from "../../../../../store/hooks";
import { currentAccountSelector } from "../../../../../store/account-setup/selectors/currentAccountSelector";
import { gcpConnectionTypeSelector } from "../../../../../store/account-setup/selectors/gcpConnectionTypeSelector";
import { providerSelector } from "../../../../../store/account-setup/selectors/providerSelector";

export const ProgressBlock: FC = () => {
  const account = useAppSelector(currentAccountSelector);
  const connectionType = useAppSelector(gcpConnectionTypeSelector);
  const provider = useAppSelector(providerSelector);

  const collectingResourcesMessage = `Collecting ${provider?.toUpperCase()} ${
    connectionType === "organization" ? "Projects" : "Resources"
  }`;

  return (
    <Box>
      <Stack direction="row" alignItems="center" mb={3}>
        {account?.status === "connected" ? (
          <CheckCircleOutlineIcon fontSize="medium" color="success" />
        ) : (
          <CircularProgress size={16} />
        )}

        <Typography sx={{ ml: 1 }} variant="body1" fontWeight="bold">
          {provider && collectingResourcesMessage}
        </Typography>
      </Stack>
    </Box>
  );
};
