import { ChangeEvent, FC, Fragment, memo, useCallback, useEffect } from "react";
import {
  Avatar,
  CircularProgress,
  FormHelperText,
  Grid,
  ListItemText,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import { RowLabelColumn } from "../RowLabelColumn";
import BugIcon from "../../../../../../../assets/images/logos/jira-issue-types/bug.svg";
import EpicIcon from "../../../../../../../assets/images/logos/jira-issue-types/epic.svg";
import SubtaskIcon from "../../../../../../../assets/images/logos/jira-issue-types/subtask.svg";
import TaskIcon from "../../../../../../../assets/images/logos/jira-issue-types/task.svg";
import StoryIcon from "../../../../../../../assets/images/logos/jira-issue-types/story.svg";
import DefaultIcon from "../../../../../../../assets/images/logos/jira-issue-types/default.svg";
import { usePostUsersMeJiraIntegrationsByIntegrationIdProjectsAndProjectIdIssueTypesMutation } from "../../../../../../../services/cloudchipr.api";

const icons: Record<string, string> = {
  Task: TaskIcon,
  Epic: EpicIcon,
  Bug: BugIcon,
  Subtask: SubtaskIcon,
  Story: StoryIcon,
  Default: DefaultIcon,
};

interface IssueTypeRowV2Props {
  integrationId: string;
  projectKey: string;
  error?: string;
  selectedIssueId: string;
  onChange(event: ChangeEvent<any>): void;
  setFieldValue(key: string, value: any): void;
}

export const IssueTypeRowV2: FC<IssueTypeRowV2Props> = memo(
  ({
    selectedIssueId,
    onChange,
    error,
    projectKey,
    integrationId,
    setFieldValue,
  }) => {
    const [fetchIssueTypes, { data, isLoading }] =
      usePostUsersMeJiraIntegrationsByIntegrationIdProjectsAndProjectIdIssueTypesMutation();

    const changeHandler = useCallback(
      (event: any) => {
        onChange(event);
        setFieldValue("body.fields", {});
      },
      [onChange, setFieldValue],
    );

    useEffect(() => {
      if (projectKey && integrationId) {
        fetchIssueTypes({ integrationId, projectId: projectKey });
      }
    }, [fetchIssueTypes, projectKey, integrationId]);

    const renderValue = useCallback(
      (selected: any) => {
        if (isLoading) {
          return "";
        }

        const selectedItem = data?.find(
          ({ id }) => id?.toString() === selected?.toString(),
        );

        if (!selectedItem) {
          return selected;
        }

        const icon = icons[selectedItem.name];

        return (
          <Stack direction="row" alignItems="center" p={0}>
            {icon && (
              <Avatar
                sx={{ width: 24, height: 24, mr: 2 }}
                alt="avatar"
                src={icon}
              />
            )}

            <ListItemText primary={selectedItem?.name} sx={{ m: 0 }} />
          </Stack>
        );
      },

      [isLoading, data],
    );

    if (!integrationId || !projectKey) {
      return null;
    }

    return (
      <Fragment>
        <RowLabelColumn label="Issue Type" required />

        <Grid item sm={4}>
          <Select
            startAdornment={
              isLoading ? (
                <Stack alignItems="center" direction="row" spacing={2}>
                  <Typography variant="body2">Loading</Typography>
                  <CircularProgress disableShrink size={16} />
                </Stack>
              ) : undefined
            }
            disabled={isLoading}
            fullWidth
            size="small"
            value={selectedIssueId}
            onChange={changeHandler}
            name="body.issue_type_id"
            renderValue={renderValue}
          >
            {data?.map(({ id, name }) => {
              const icon = icons[name] || icons.Default;

              return (
                <MenuItem value={id} key={name}>
                  {icon && (
                    <Avatar
                      sx={{ width: 24, height: 24, mr: 2 }}
                      alt="avatar"
                      src={icon}
                    />
                  )}

                  <ListItemText primary={name} />
                </MenuItem>
              );
            })}
          </Select>
          {!!error && <FormHelperText error>{error}</FormHelperText>}
        </Grid>
        <Grid item sm={6} />
      </Fragment>
    );
  },
);
