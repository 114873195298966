import { FC } from "react";
import { CustomCellRendererProps } from "@ag-grid-community/react";
import { SavingsOpportunity } from "../../../../../services/cloudchipr.api";
import { TypographyWithTooltip } from "../../../../common/TypographyWithTooltip";
import { money } from "../../../../../utils/numeral/money";

export const getMoneyTypographyCellRenderer =
  (
    key: keyof SavingsOpportunity,
  ): FC<CustomCellRendererProps<SavingsOpportunity>> =>
  (cell) => {
    const data = cell.data as SavingsOpportunity;
    const value = data?.[key];

    if (typeof value !== "number") {
      return null;
    }

    return <TypographyWithTooltip title={money(value)} variant="body2" />;
  };
