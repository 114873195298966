import { FC } from "react";
import { Stack } from "@mui/material";
import { IntegrationCustomMessageInfoSection } from "../../common/IntegrationCustomMessageInfoSection";

export const SlackIntegrationGracePeriodMessageWorkflowInfo: FC = () => {
  return (
    <Stack>
      <IntegrationCustomMessageInfoSection
        start="Workflow"
        tail="{{workflow name}}"
        color="primary.main"
      />

      <IntegrationCustomMessageInfoSection
        start="Monthly Price"
        tail="{{$0.00}}"
      />
      <IntegrationCustomMessageInfoSection
        start="Grace Period"
        tail="{{period}}"
      />

      <IntegrationCustomMessageInfoSection
        start="Grace Period Expires"
        tail="{{date}}"
      />
    </Stack>
  );
};
