import { IntegrationTypeWithoutWebhook } from "../../../../../../../../../../integrations/components/integrations-create/components/common/integration-type/IntegrationTypeCard";

export const liveUsageRowActionTooltipTitles: Record<
  IntegrationTypeWithoutWebhook | "filter_out" | "crate_task",
  { disabled: string; active: string }
> = {
  email: {
    disabled: "Select resources to send Email.",
    active: "Send Email with selected resources.",
  },
  slack: {
    disabled: "Select resources to send Slack.",
    active: "Send Slack with selected resources.",
  },
  jira: {
    disabled: "Select resources to create Jira ticket.",
    active: "Create Jira Ticket with selected resources.",
  },
  filter_out: {
    disabled: "Select resources to Filter Out.",
    active: "Filter Out selected resources.",
  },
  crate_task: {
    disabled: "Select resources to Create Task.",
    active: "Create Task.",
  },
};
