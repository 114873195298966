import { FC } from "react";
import { CustomCellRendererProps } from "@ag-grid-community/react";
import Stack from "@mui/material/Stack";
import { SavingsOpportunity } from "../../../../../services/cloudchipr.api";
import { TypographyWithTooltip } from "../../../../common/TypographyWithTooltip";
import { CopyIconButton } from "../../../../common/CopyIconButton";
import { useHover } from "../../../../../utils/hooks/useHover.hook";

export const getSimpleTypographyCellRenderer =
  (
    key: keyof SavingsOpportunity,
  ): FC<CustomCellRendererProps<SavingsOpportunity>> =>
  (cell) => {
    const { ref, hovered } = useHover();

    const data = cell.data as SavingsOpportunity;
    const value = data?.[key];

    if (typeof value !== "string") {
      return null;
    }

    return (
      <Stack direction="row" spacing={0.5} alignItems="center" ref={ref}>
        <TypographyWithTooltip title={value} variant="body2" />

        <CopyIconButton data={data.action_type} visible={hovered} />
      </Stack>
    );
  };
