import { FC } from "react";
import { IconButton, Tooltip } from "@mui/material";
import BarChartIcon from "@mui/icons-material/BarChart";
import { useAppSelector } from "../../../../../../store/hooks";
import { defaultResourceExplorerViewIdSelector } from "../../../../../../store/resource-explorer/selectors/all-resource-explorers/defaultResourceExplorerViewIdSelector";
import { useGetUsersMeOrganisationsCurrentResourceExplorerViewsQuery } from "../../../../../../services/cloudchipr.api";

interface OpenResourceExplorerProps {
  categoryId?: string;
}

export const OpenResourceExplorer: FC<OpenResourceExplorerProps> = ({
  categoryId,
}) => {
  const defaultResourceExplorerViewId = useAppSelector(
    defaultResourceExplorerViewIdSelector,
  );
  const { isLoading } =
    useGetUsersMeOrganisationsCurrentResourceExplorerViewsQuery({});

  if (!categoryId) {
    return null;
  }

  return (
    <Tooltip title="Open in Resource Explorer." placement="top" arrow>
      <IconButton
        target="_blank"
        disabled={isLoading}
        href={`/resource-explorer/${defaultResourceExplorerViewId}?category=${categoryId}`}
      >
        <BarChartIcon fontSize="small" />
      </IconButton>
    </Tooltip>
  );
};
