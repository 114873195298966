function TaskPropertyEnvironmentIcon() {
  return (
    <svg
      width="30"
      height="31"
      viewBox="0 0 30 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect y="0.5" width="30" height="30" rx="8" fill="#82B1FF" />
      <path
        d="M14.9997 15.4998C14.083 15.4998 13.333 14.7498 13.333 13.8332C13.333 12.9165 14.083 12.1665 14.9997 12.1665C15.9163 12.1665 16.6663 12.9165 16.6663 13.8332C16.6663 14.7498 15.9163 15.4998 14.9997 15.4998ZM19.9997 13.9998C19.9997 10.9748 17.7913 8.83317 14.9997 8.83317C12.208 8.83317 9.99967 10.9748 9.99967 13.9998C9.99967 15.9498 11.6247 18.5332 14.9997 21.6165C18.3747 18.5332 19.9997 15.9498 19.9997 13.9998ZM14.9997 7.1665C18.4997 7.1665 21.6663 9.84984 21.6663 13.9998C21.6663 16.7665 19.4413 20.0415 14.9997 23.8332C10.558 20.0415 8.33301 16.7665 8.33301 13.9998C8.33301 9.84984 11.4997 7.1665 14.9997 7.1665Z"
        fill="white"
      />
    </svg>
  );
}

export default TaskPropertyEnvironmentIcon;
