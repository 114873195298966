import { FC, useCallback } from "react";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../store/hooks";
import {
  setAutomationData,
  setOffHoursSpecificData,
} from "../../../../../../../store/automations/automationsSlice";
import { OffHoursScheduleActionType } from "../../../../../../../services/cloudchipr.api";
import { ToggleBox } from "../../../../../../common/toggle-box/ToggleBox";
import { offHoursActionSelector } from "../../../../../../../store/automations/selectros/off-hours/offHoursActionSelector";

export const NotificationTypeSwitcher: FC = () => {
  const dispatch = useAppDispatch();
  const currentAction = useAppSelector(offHoursActionSelector);

  const actionChangeHandler = useCallback(
    (newAction: OffHoursScheduleActionType) => {
      dispatch(setOffHoursSpecificData({ action: newAction }));
      dispatch(setAutomationData({ notifications: null, emails: null }));
    },
    [dispatch],
  );

  if (!currentAction) {
    return null;
  }

  return (
    <ToggleBox
      size="small"
      options={options}
      onChange={actionChangeHandler}
      value={currentAction}
    />
  );
};

const options = [
  { value: "silent", label: "Execute Actions Silently" },
  { value: "notify", label: "Notify on Actions" },
];
