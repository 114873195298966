import { FC, useCallback } from "react";
import {
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../../../../store/hooks";
import { awsOrganizationSelector } from "../../../../../../../../../../../store/commitments/selectors/organisations/awsOrganizationSelector";
import { commitmentUtilizationSetupPropertyByKeySelector } from "../../../../../../../../../../../store/dashboards/selectors/setups/commitment-utilization/commitmentUtilizationSetupPropertyByKeySelector";
import { commitmentUtilizationWidgetSetupDataChangeThunk } from "../../../../../../../../../../../store/dashboards/thunks/widgets/commitment-utilization/setup-change/commitmentUtilizationWidgetSetupDataChangeThunk";

export const CommitmentUtilizationWidgetSetupOrgSelect: FC = () => {
  const dispatch = useAppDispatch();
  const options = useAppSelector(awsOrganizationSelector);

  const orgId = useAppSelector(
    commitmentUtilizationSetupPropertyByKeySelector("providerOrganisationId"),
  );

  const handleChange = useCallback(
    (event: SelectChangeEvent<string>) => {
      const providerOrganisationId = event.target.value;
      dispatch(
        commitmentUtilizationWidgetSetupDataChangeThunk({
          providerOrganisationId,
        }),
      );
    },
    [dispatch],
  );

  if (!options || options.length === 1) {
    return null;
  }

  return (
    <FormControl fullWidth size="xsmall" disabled={!options}>
      <InputLabel>Organization</InputLabel>

      <Select
        fullWidth
        label="Organization"
        onChange={handleChange}
        value={orgId}
      >
        {options?.map((org) => (
          <MenuItem key={org.organization_id} value={org.organization_id ?? ""}>
            <ListItemText
              primary={org.organization_id ?? ""}
              primaryTypographyProps={{ variant: "body1" }}
            />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
