import Typography from "@mui/material/Typography";
import { FC } from "react";
import { Flip } from "@mui/icons-material";
import { Stack, Tooltip } from "@mui/material";
import { TrendType } from "../../../../../../../../../services/cloudchipr.api";

interface TrendSettingsExampleDatesProps {
  trendType: TrendType;
}

export const TrendSettingsExampleDates: FC<TrendSettingsExampleDatesProps> = ({
  trendType,
}) => {
  return (
    <Stack direction="row" spacing={1} alignItems="center">
      <Typography variant="caption" color="text.secondary" lineHeight={1.25}>
        E.g.{" "}
        <Typography variant="inherit" fontWeight="bold" component="span">
          10 Aug - 20 Aug
        </Typography>
      </Typography>

      <Tooltip placement="top" title="Compared with" slotProps={slotProps}>
        <Flip fontSize="small" />
      </Tooltip>

      <Typography
        variant="caption"
        color="text.secondary"
        lineHeight={1.25}
        fontWeight="bold"
      >
        {trendType === "month_over_month"
          ? "10 Jul - 20 Jul"
          : "1 Aug - 10 Aug"}
      </Typography>
    </Stack>
  );
};

const slotProps = {
  popper: {
    modifiers: [
      {
        name: "offset",
        options: {
          offset: [0, -10],
        },
      },
    ],
  },
};
