import { cloudChiprApi } from "../../../../../../services/cloudchipr.api";
import { updateTaskActionPropertiesFixedCacheKey } from "../../../../utils/constants/fixedCacheKeys";
import { RootState } from "../../../../../store";

const selector =
  cloudChiprApi.endpoints.putUsersMeOrganisationsCurrentTasksPropertiesActions.select(
    updateTaskActionPropertiesFixedCacheKey,
  );

export const taskManagementEditingActionsLoadingSelector = (
  state: RootState,
) => {
  return selector(state)?.isLoading;
};
