import { FC, Fragment } from "react";
import { Button, Stack, Typography } from "@mui/material";
import LaunchIcon from "@mui/icons-material/Launch";
import { RecommendationDismiss } from "../../../common/dismiss/RecommendationDismiss";
import { formatDate } from "../../../../../../../utils/helpers/date-time/datetime-format";
import { RecommendationsTabType } from "../../../off-hours-recommendations/components/OffHoursRecommendationsContent";
import { RecommendationRestoreButton } from "../../../common/dismiss/RecommendationRestoreButton";

interface RightsizingRecommendationsActionCellProps {
  tab: RecommendationsTabType;
  recommendationId: string;
  recommendationLink: string;
  dismissUntilDate: string | null;
}

export const RightsizingRecommendationsActionCell: FC<
  RightsizingRecommendationsActionCellProps
> = ({ tab, dismissUntilDate, recommendationLink, recommendationId }) => {
  return (
    <Stack direction="row" spacing={1}>
      {tab === "active" && (
        <Fragment>
          {recommendationLink && (
            <Button
              href={recommendationLink}
              target="_blank"
              variant="outlined"
              size="small"
            >
              View <LaunchIcon fontSize="small" sx={{ ml: 1 }} />
            </Button>
          )}

          <RecommendationDismiss
            recommendationId={recommendationId}
            recommendationType="rightsizing"
          />
        </Fragment>
      )}

      {tab === "dismissed" && (
        <Stack alignItems="start" spacing={0.5}>
          <RecommendationRestoreButton recommendationId={recommendationId} />

          {dismissUntilDate && (
            <Typography variant="caption" color="text.secondary" pr={1}>
              Will be restored on{" "}
              <Typography fontWeight="bold" component="span" variant="inherit">
                {formatDate(dismissUntilDate, { type: "date" })}
              </Typography>
            </Typography>
          )}
        </Stack>
      )}
    </Stack>
  );
};
