import { FC, useCallback } from "react";
import { Stack } from "@mui/material";
import { TypographyWithTooltip } from "../../../../../../common/TypographyWithTooltip";
import { ReportsTableData } from "../../../../../../../store/reports/utils/types/types";
import { titleBySourceType } from "../../utils/constants/constants";

interface ReportsSourceCellProps {
  id: ReportsTableData["source_id"];
  sourceType: ReportsTableData["source_type"];
  sourceName: ReportsTableData["sourceName"];
}

export const ReportsSourceCell: FC<ReportsSourceCellProps> = ({
  id,
  sourceType,
  sourceName,
}) => {
  const handleClick = useCallback(() => {
    const linkPrefix =
      sourceType === "view" ? "/resource-explorer" : "/dashboards";
    window.open(`${linkPrefix}/${id}`, "_blank");
  }, [id, sourceType]);

  return (
    <Stack overflow="hidden">
      <TypographyWithTooltip
        fontWeight="regular"
        color="primary"
        onClick={handleClick}
        sx={{ textDecoration: "underline", cursor: "pointer" }}
        variant="body2"
        title={sourceName ?? id}
      />

      <TypographyWithTooltip
        title={titleBySourceType.get(sourceType)}
        variant="caption"
        color="text.secondary"
      />
    </Stack>
  );
};
