import { FC, useCallback } from "react";
import { Box, Button, Stack } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { PollingProgress } from "../../common/connectors/components/PollingProgress";
import { AccessToAWSAccountTabs } from "../../common/connectors/components/aws/AccessToAWSAccountTabs";
import { FailedAccountAttemptReason } from "../../common/connectors/components/FailedAccountAttemptReason";
import { PollingInterruptedMessage } from "../../common/connectors/components/PollingInterruptedMessage";
import {
  goToPrevStep,
  resetAccountAttempt,
} from "../../../../store/account-setup/accountSetupSlice";
import { AccessToGCPProjectTabs } from "../../common/connectors/components/gcp/components/AccessToGCPProjectTabs";
import { pollingSelector } from "../../../../store/account-setup/selectors/pollingSelector";
import { providerSelector } from "../../../../store/account-setup/selectors/providerSelector";
import { AccessToAccountSetupHeader } from "../../common/connectors/components/AccessToAccountSetupHeader";

export const AccessToAccountStep: FC = () => {
  const provider = useAppSelector(providerSelector);
  const polling = useAppSelector(pollingSelector);

  const dispatch = useAppDispatch();
  const prevHandler = useCallback(() => {
    dispatch(resetAccountAttempt());
    dispatch(goToPrevStep());
  }, [dispatch]);

  return (
    <div>
      <AccessToAccountSetupHeader />

      {provider === "aws" && <AccessToAWSAccountTabs />}
      {provider === "gcp" && <AccessToGCPProjectTabs />}

      <Box mt={3}>
        <PollingProgress />
      </Box>

      <FailedAccountAttemptReason />
      <PollingInterruptedMessage />

      <Stack
        direction="row"
        alignItems="end"
        justifyContent="end"
        gap={2}
        mt={2}
      >
        <Button
          onClick={prevHandler}
          variant="outlined"
          disabled={polling}
          size="medium"
        >
          Back
        </Button>
      </Stack>
    </div>
  );
};
