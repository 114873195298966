import { SvgIcon, SvgIconProps } from "@mui/material";

function AreaChartSvgIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M14.1667 6.25008L10 2.91675L5.83333 8.75008L2.5 6.25008V17.0834H17.5V6.25008H14.1667ZM15.8333 14.5417L10 10.0001L6.66667 14.5834L4.16667 12.5834V9.58342L6.2 11.1084L10.3333 5.31675L13.5833 7.91675H15.8333V14.5417Z"
          fill="black"
          fillOpacity="0.54"
        />
      </svg>
    </SvgIcon>
  );
}

AreaChartSvgIcon.muiName = "AreaChartSvgIcon";

export default AreaChartSvgIcon;
