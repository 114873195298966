import { taskManagementEditingActionsLoadingSelector } from "./taskManagementEditingActionsLoadingSelector";
import { taskManagementEditingPrioritiesLoadingSelector } from "./taskManagementEditingPrioritiesLoadingSelector";
import { taskManagementEditingStatusesLoadingSelector } from "./taskManagementEditingStatusesLoadingSelector";
import { taskManagementEditingEnvironmentsLoadingSelector } from "./taskManagementEditingEnvironmentsLoadingSelector";
import { taskAvailablePropertiesLoadingSelector } from "./taskAvailablePropertiesLoadingSelector";
import { RootState } from "../../../../../store";

export const taskManagementPropertiesEditingLoadingSelector = (
  state: RootState,
) => {
  return (
    taskAvailablePropertiesLoadingSelector(state) ||
    taskManagementEditingActionsLoadingSelector(state) ||
    taskManagementEditingPrioritiesLoadingSelector(state) ||
    taskManagementEditingEnvironmentsLoadingSelector(state) ||
    taskManagementEditingStatusesLoadingSelector(state)
  );
};
