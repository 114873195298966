import {
  DateDataPoint,
  ResourceExplorerWidget,
} from "../../../../services/cloudchipr.api";
import { WidgetAverageData } from "../types/resourceExplorer";

export const getResourceExplorerWidgetAverageData = (
  data?: ResourceExplorerWidget,
  dataPoint?: DateDataPoint,
): WidgetAverageData | undefined => {
  let averageMonthlyCost =
    data?.average_monthly_cost_details?.average_monthly_cost;
  const averageMonthlyTrend = data?.average_monthly_cost_details?.trend;
  let averageDailyCost = data?.average_daily_cost_details?.average_daily_cost;
  const averageDailyTrend = data?.average_daily_cost_details?.trend;

  if (!data) {
    return;
  }

  if (averageDailyCost === undefined) {
    averageDailyCost = 0;
  }

  if (averageMonthlyCost === undefined) {
    averageMonthlyCost = 0;
  }

  if (dataPoint === "daily") {
    return {
      cost: averageDailyCost !== null ? +averageDailyCost.toFixed(2) : null,
      trend: averageDailyTrend !== undefined ? averageDailyTrend : 0,
    };
  }

  return {
    cost: averageMonthlyCost !== null ? +averageMonthlyCost.toFixed(2) : null,
    trend: averageMonthlyTrend !== undefined ? averageMonthlyTrend : 0,
  };
};
