import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  cloudChiprApi,
  PostUsersMeExecutionLogsDetailsApiArg,
} from "../../../services/cloudchipr.api";

export const getExecutionLogDetailsThunkFixedCacheKey =
  "postUsersMeExecutionLogsDetails-fixed-cache-key";

export const getExecutionLogDetailsThunk = createAsyncThunk(
  "executionLog/getExecutionLogDetailsThunk",
  async (
    filters: PostUsersMeExecutionLogsDetailsApiArg["body"],
    { dispatch },
  ) => {
    await dispatch(
      cloudChiprApi.endpoints.postUsersMeExecutionLogsDetails.initiate(
        { body: filters },
        { fixedCacheKey: getExecutionLogDetailsThunkFixedCacheKey },
      ),
    );
  },
);
