import { createAsyncThunk } from "@reduxjs/toolkit";
import { closeSnackbar, enqueueSnackbar, SnackbarKey } from "notistack";
import { cloudChiprApi } from "../../../../services/cloudchipr.api";
import { RootState } from "../../../store";
import { generateApplyFilterSetToAccountFixedCacheKey } from "../../../filters/selectors/filter-set/utils/helpers/fixedCacheKeyGenerator";
import { liveUsageMgmtProviderSelector } from "../../selectors/store-selectors/liveUsageMgmtProviderSelector";

type ApplyLiveUsageMgmtFilterTemplateThunkArgs = {
  accountIds: string[];
  filterTemplateId: string;
  name?: string;
};

export const applyLiveUsageMgmtFilterTemplateThunk = createAsyncThunk(
  "liveUsageMgmt/applyFilterSetToAccountsThunk",
  async (
    {
      accountIds,
      filterTemplateId,
      name,
    }: ApplyLiveUsageMgmtFilterTemplateThunkArgs,
    { dispatch, getState },
  ) => {
    const { patchUsersMeOrganisationsCurrentFilterSetsByFilterSetIdV2 } =
      cloudChiprApi.endpoints;
    const state = getState() as RootState;
    const provider = liveUsageMgmtProviderSelector(state);

    if (!provider) {
      return;
    }
    let response;

    try {
      response = dispatch(
        patchUsersMeOrganisationsCurrentFilterSetsByFilterSetIdV2.initiate(
          {
            filterSetId: filterTemplateId,
            body: {
              account_ids: accountIds,
              cloud_provider: provider,
              name: name ?? null,
              priority_account_id: null,
            },
          },
          {
            fixedCacheKey:
              generateApplyFilterSetToAccountFixedCacheKey(filterTemplateId),
          },
        ),
      ).unwrap();
      return response;
    } catch (e) {
      // @ts-expect-error TODO: return to this after adding error type
      const errMessage = e?.data?.message || "Something went wrong";
      const snackbarId: SnackbarKey = enqueueSnackbar(errMessage, {
        variant: "snackbarAlert",
        AlertSnackBarProps: {
          severity: "error",
          onClose: () => closeSnackbar(snackbarId),
        },
      });
    }
  },
);
