import { FC } from "react";
import {
  Alert,
  Box,
  List,
  ListItem,
  Stack,
  Typography,
  Link,
} from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { CopyIconButton } from "../../../../../../../../../../../common/CopyIconButton";
import { CommandSnippet } from "../../../../../../../../../../common/connectors/components/CommandSnippet";
import { useAppSelector } from "../../../../../../../../../../../../store/hooks";
import { resourceExplorerGcpBillingExportDataErrorSelector } from "../../../../../../../../../../../../store/resource-explorer/selectors/billing/gcp-billing-data/resourceExplorerGcpBillingExportDataErrorSelector";

export const ResourceExplorerBillingExportDialogAccessConsoleTab: FC = () => {
  const error = useAppSelector(
    resourceExplorerGcpBillingExportDataErrorSelector,
  );

  return (
    <Stack gap={2} mt={3}>
      <Box>
        <Typography variant="body1">
          Please go to{" "}
          <Link
            to="https://console.cloud.google.com/iam-admin/iam"
            component={RouterLink}
            target="_blank"
          >
            IAM in GCP Console
          </Link>{" "}
          and assign following roles to Cloudchipr’s Service Account.
        </Typography>
        <Typography variant="body2" color="text.secondary" component="span">
          Cloudchipr will have read-only access to BigQuery billing exports.
        </Typography>
      </Box>
      <Box>
        <Typography variant="caption">Service account</Typography>

        <CommandSnippet
          code={error?.data?.service_account}
          parentBoxSx={{ my: 0 }}
        />
      </Box>
      <Box>
        <Typography variant="caption">Roles</Typography>
        <Alert variant="standard" icon={false} severity="info">
          <List dense sx={{ maxWidth: 190, py: 0 }}>
            {["BigQuery Data Viewer", "BigQuery Job User"].map((role) => (
              <ListItem
                key={role}
                disableGutters
                secondaryAction={
                  <CopyIconButton data={role} visible color="primary" />
                }
              >
                <Typography variant="body2" color="primary" fontWeight="bold">
                  {role}
                </Typography>
              </ListItem>
            ))}
          </List>
        </Alert>
      </Box>
    </Stack>
  );
};
