import { FC, Fragment, useCallback, useMemo, useState } from "react";
import {
  Divider,
  List,
  ListItem,
  ListSubheader,
  Typography,
} from "@mui/material";
import { ScheduledMoreNextRunsPopoverTimeZones } from "./ScheduledMoreNextRunsPopoverTimeZones";
import { ScheduledNextRunListItem } from "./ScheduledNextRunListItem";
import {
  FutureRunDate,
  NullableDate,
} from "../../../../../../services/cloudchipr.api";
import {
  workflowNextRunsInitialDataCount,
  workflowNextRunsMaxCount,
} from "../../utils/next-run/constants";

interface ScheduledMoreNextRunsContentProps {
  nextRun?: NullableDate;
  timeZone: string;
  nextRunList: FutureRunDate[];
}

export const ScheduledMoreNextRunsPopoverContent: FC<
  ScheduledMoreNextRunsContentProps
> = ({ nextRun, nextRunList, timeZone }) => {
  const initialData = useMemo(() => {
    return nextRunList?.slice(0, workflowNextRunsInitialDataCount) ?? [];
  }, [nextRunList]);

  const [upcomingMoreRuns, setUpcomingMoreRuns] =
    useState<FutureRunDate[]>(initialData);

  const moreNextRunsClickHandler = useCallback(() => {
    setUpcomingMoreRuns(nextRunList?.slice(0, workflowNextRunsMaxCount) ?? []);
  }, [nextRunList]);

  return (
    <List sx={{ pt: 0 }}>
      {nextRun && (
        <Fragment>
          <ListSubheader
            sx={{
              lineHeight: 1,
              pt: 1,
              pb: 0.5,
              color: "text.secondary",
              fontSize: 12,
            }}
          >
            NEXT RUN
          </ListSubheader>
          <ScheduledMoreNextRunsPopoverTimeZones
            nextRun={nextRun}
            timeZone={timeZone}
          />
          {!!nextRunList?.length && <Divider />}
        </Fragment>
      )}
      {!!nextRunList?.length && (
        <Fragment>
          <ListSubheader sx={{ flexDirection: "column", lineHeight: 1, pt: 1 }}>
            <Typography
              variant="caption"
              component="div"
              fontWeight="medium"
              color="text.secondary"
            >
              UPCOMING RUNS
            </Typography>
            <Typography variant="caption" color="text.disabled">
              In Workflow Time Zone
            </Typography>
          </ListSubheader>
          {upcomingMoreRuns.map((item) => {
            return (
              <Fragment key={item.date}>
                <ScheduledNextRunListItem
                  nextRun={item.date}
                  timeZone={timeZone}
                />
                <Divider light />
              </Fragment>
            );
          })}

          {upcomingMoreRuns.length < nextRunList?.length && (
            <ListItem>
              <Typography
                color="primary"
                sx={{ textDecoration: "underline", cursor: "pointer" }}
                onClick={moreNextRunsClickHandler}
                variant="caption"
              >
                Show Next 10 Runs
              </Typography>
            </ListItem>
          )}
        </Fragment>
      )}
    </List>
  );
};
