import { FC } from "react";

import { Stack, Typography } from "@mui/material";
import ProcessingDataImage from "../../../../../../assets/images/processing-data.png";

export const AnalyzingBillingData: FC = () => {
  return (
    <Stack justifyContent="center" alignItems="center" height="100%" gap={2}>
      <Typography color="text.primary" variant="h5" fontWeight="medium">
        Collecting Billing Data
      </Typography>
      <Typography
        color="text.secondary"
        variant="subtitle1"
        fontWeight="regular"
      >
        This process can take a few hours.
      </Typography>
      <img src={ProcessingDataImage} alt="ProcessingDataImage" />
    </Stack>
  );
};
