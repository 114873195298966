import { ChangeEvent, FC, useCallback } from "react";
import { Stack, Typography } from "@mui/material";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../store/hooks";
import { reportsScheduleDataByKeySelector } from "../../../../../../../store/reports/selector/reportsScheduleDataByKeySelector";
import { NumberField } from "../../../../../../common/NumberField";
import { setReportsScheduleDataThunk } from "../../../../../../../store/reports/thunks/setReportsScheduleDataThunk";

interface ReportsDrawerScheduleIntervalProps {
  frequency: "week" | "day";
  flexGrow?: number;
}

export const ReportsDrawerScheduleInterval: FC<
  ReportsDrawerScheduleIntervalProps
> = ({ frequency, flexGrow }) => {
  const dispatch = useAppDispatch();
  const interval = useAppSelector(reportsScheduleDataByKeySelector("interval"));

  const handleIntervalChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      dispatch(
        setReportsScheduleDataThunk({ interval: Number(event.target.value) }),
      );
    },
    [dispatch],
  );

  return (
    <Stack direction="row" spacing={0.5} alignItems="baseline" flex={flexGrow}>
      <Typography variant="body2" color="text.secondary">
        Every
      </Typography>

      <NumberField
        fullWidth
        size="small"
        minValue={1}
        value={interval || ""}
        onChange={handleIntervalChange}
        maxValue={maxValueByFrequency[frequency]}
      />

      <Typography variant="body2" color="text.secondary">
        {frequency}
        {!!(interval && interval > 1) && "s"}
      </Typography>
    </Stack>
  );
};

const maxValueByFrequency = {
  day: 31,
  week: 51,
};
