import { createAsyncThunk } from "@reduxjs/toolkit";
import { enqueueSnackbar } from "notistack";
import { RootState } from "../../../store";
import { cloudChiprApi } from "../../../../services/cloudchipr.api";
import { commitmentsDataGridPayloadDataSelector } from "../../selectors/common/payload/commitmentsDataGridPayloadDataSelector";
import { awsBillingStatusNotAvailableSelector } from "../../selectors/organisations/awsBillingStatusNotAvailableSelector";

export const getSavingsPlansForDataGridThunk = createAsyncThunk(
  "commitments/getSavingsPlansForDataGrid",
  async (_: void, { dispatch, getState }) => {
    const state = getState() as RootState;

    const { getUsersMeOrganisationsCurrentSavingsPlans } =
      cloudChiprApi.endpoints;

    const payload = commitmentsDataGridPayloadDataSelector(state);

    const billingStatusNotAvailable =
      awsBillingStatusNotAvailableSelector(state);

    try {
      await dispatch(
        getUsersMeOrganisationsCurrentSavingsPlans.initiate(payload),
      ).unwrap();
    } catch (e) {
      if (billingStatusNotAvailable) {
        return;
      }

      // @ts-expect-error todo: api fix
      const errMessage = e?.data?.message || "Something went wrong";
      enqueueSnackbar(errMessage, {
        variant: "snackbarAlert",
        AlertSnackBarProps: { severity: "error" },
      });

      return null;
    }
  },
);
