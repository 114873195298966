import { FC, memo } from "react";
import { Divider, Stack, Theme } from "@mui/material";
import { SxProps } from "@mui/system";
import { InfoCard } from "./InfoCard";

interface InfoCardsProps {
  values: {
    title: string;
    value?: number | string;
    titleSx?: SxProps<Theme>;
    valueSx?: SxProps<Theme>;
  }[];
}

export const InfoCards: FC<InfoCardsProps> = memo(({ values }) => {
  return (
    <Stack
      direction="row"
      spacing={3}
      divider={<Divider light orientation="vertical" flexItem />}
    >
      {values.map(({ title, value, titleSx, valueSx }) => {
        return (
          <InfoCard
            key={title}
            value={value}
            title={title}
            valueSx={valueSx}
            titleSx={titleSx}
          />
        );
      })}
    </Stack>
  );
});
