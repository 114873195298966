import { createDraftSafeSelector } from "@reduxjs/toolkit";
import { integrationsDataSelector } from "./integrationsDataSelector";
import { Integration } from "../../../services/cloudchipr.api";

export const integrationsDataAsObjectSelector = createDraftSafeSelector(
  [integrationsDataSelector],
  (integrations) => {
    return integrations.reduce(
      (acc, currentValue) => {
        acc[currentValue.id] = currentValue;
        return acc;
      },
      {} as Record<string, Integration>,
    );
  },
);
