import { liveUsageMgmtAppliedFilterTemplateNameSelector } from "./liveUsageMgmtAppliedFilterTemplateNameSelector";
import { RootState } from "../../../store";
import { orgCurrentFilterTemplatesNamesSetSelector } from "../org-filter-templates/orgCurrentFilterTemplatesNamesSetSelector";
import { generateUniqueNameBySuffix } from "../../../../components/utils/helpers/generateUniqueNameBySuffix";

export const liveUsageMgmtCreatingNewFilterTemplateNameSelector = (
  state: RootState,
): string => {
  const appliedFilterTemplateName =
    liveUsageMgmtAppliedFilterTemplateNameSelector(state);
  const namesSet = orgCurrentFilterTemplatesNamesSetSelector(state);

  if (!appliedFilterTemplateName || !namesSet) {
    return "";
  }

  return generateUniqueNameBySuffix(
    appliedFilterTemplateName,
    namesSet,
    "(copy)",
  );
};
