import { liveUsageMgmtPostUsersMeV2OrganisationsCurrentByTypeResourcesSelector } from "./liveUsageMgmtPostUsersMeV2OrganisationsCurrentByTypeResourcesSelector";
import { RootState } from "../../../store";
import { PostUsersMeV2OrganisationsCurrentByTypeResourcesApiResponse } from "../../../../services/cloudchipr.api";

export const liveUsageMgmtResourceTypeDataSelector = (
  state: RootState,
  fixedCacheKey: string,
): PostUsersMeV2OrganisationsCurrentByTypeResourcesApiResponse | undefined => {
  return liveUsageMgmtPostUsersMeV2OrganisationsCurrentByTypeResourcesSelector(
    fixedCacheKey,
  )(state)?.data;
};
