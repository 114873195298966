import { Button, Stack } from "@mui/material";
import { FC } from "react";
import AddIcon from "@mui/icons-material/Add";
import { GcpBillingExportItem } from "./GcpBillingExportItem";
import { useGetUsersMeOrganisationsCurrentGcpBillingExportDataQuery } from "../../../../../../services/cloudchipr.api";

interface GcpBillingExportDrawerContentProps {
  onOpenBillingExportModal(): void;
}

export const GcpBillingExportDrawerContent: FC<
  GcpBillingExportDrawerContentProps
> = ({ onOpenBillingExportModal }) => {
  const { data } = useGetUsersMeOrganisationsCurrentGcpBillingExportDataQuery();
  return (
    <Stack p={2} spacing={2}>
      <Stack spacing={2}>
        {data?.map((item) => {
          return (
            <GcpBillingExportItem
              key={item.id}
              dataSet={item.dataset}
              projectId={item.project_id}
              tableName={item.table}
            />
          );
        })}
      </Stack>
      <Stack alignSelf="center">
        <Button
          color="primary"
          onClick={onOpenBillingExportModal}
          startIcon={<AddIcon />}
        >
          Add Billing Export Details
        </Button>
      </Stack>
    </Stack>
  );
};
