import { createDraftSafeSelector } from "@reduxjs/toolkit";
import { getWeeklyRecommendationDataBaseDismissedSelector } from "../../recommendationWeeklyQuerySelector";
import { OrganisationWeeklyOffHoursRecommendation } from "../../../../../../services/cloudchipr.api";

export const recommendationDbWeeklyDismissedDataSelector =
  createDraftSafeSelector(
    [getWeeklyRecommendationDataBaseDismissedSelector],
    (response): OrganisationWeeklyOffHoursRecommendation[] =>
      response?.data ?? [],
  );
