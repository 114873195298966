import { FC, useCallback } from "react";
import Dialog from "@mui/material/Dialog";
import { DialogContent, Stack } from "@mui/material";
import { useFormik } from "formik";
import { useSnackbar } from "notistack";
import * as yup from "yup";
import { useEffectOnceWhen } from "rooks";
import { htmlToSlackMd } from "./utils/htmlToSlackMd";
import { SlackConversationsSelect } from "./SlackConversationsSelect";
import { CreateIntegrationMessageEditor } from "../common/CreateIntegrationMessageEditor";
import {
  PostUsersMeSlackIntegrationsByIntegrationIdMessageApiArg,
  ResourceType,
  SlackConversation,
  usePostUsersMeSlackIntegrationsByIntegrationIdMessageMutation,
} from "../../../../../services/cloudchipr.api";
import { DialogTitleClosable } from "../../../dialog-utils/DialogTitleClosable";
import { useAppDispatch, useAppSelector } from "../../../../../store/hooks";
import { getIntegrationByIdThunk } from "../../../../../store/integrations/thunks/getIntegrationByIdThunk";
import { CreateIntegrationMessageFormActions } from "../common/CreateIntegrationMessageFormActions";
import { IntegrationSelect } from "../common/IntegrationSelect";
import { slackMessageSelectorV2 } from "../../../../../store/integrations/selectors/slack/slackMessageSelectorV2";
import { liveUsageMgmtSelectedResourcesByResourceTypeCsvDataSelector } from "../../../../../store/live-usage-mgmt/selectors/resource-type-data/liveUsageMgmtSelectedResourcesByResourceTypeCsvDataSelector";
import { CreateIntegrationResourcesTableV2 } from "../common/CreateIntegrationResourcesTableV2";

interface CreateSlackMessageDialogProps {
  resourceType: ResourceType;
  onClose(): void;
}

const validationSchema = yup.object({
  integrationId: yup.string().required("This field is required"),
  body: yup.object({
    conversations: yup
      .array()
      .min(1, "At least one Slack conversation should be selected."),
  }),
});

const initialValues: PostUsersMeSlackIntegrationsByIntegrationIdMessageApiArg =
  {
    integrationId: "",
    body: {
      message: "",
      conversations: [],
      resources: [],
    },
  };

export const CreateSlackMessageDialogV2: FC<CreateSlackMessageDialogProps> = ({
  resourceType,
  onClose,
}) => {
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const message = useAppSelector((state) =>
    slackMessageSelectorV2(state, resourceType),
  );

  const resources = useAppSelector((state) =>
    liveUsageMgmtSelectedResourcesByResourceTypeCsvDataSelector(
      state,
      resourceType,
    ),
  );

  const [createMessage, { isLoading }] =
    usePostUsersMeSlackIntegrationsByIntegrationIdMessageMutation();

  const formik = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      const payload = {
        ...values,
        body: {
          ...values.body,
          message: values.body.message
            ? htmlToSlackMd(values.body.message)
            : "",
        },
      };

      try {
        await createMessage(payload).unwrap();

        enqueueSnackbar("Slack message successfully sent.", {
          variant: "snackbarAlert",
          AlertSnackBarProps: { severity: "success" },
        });

        onClose();
      } catch (e: any) {
        enqueueSnackbar(e?.data?.message || "Something went wrong", {
          variant: "snackbarAlert",
          AlertSnackBarProps: { severity: "error" },
        });
      }
    },
  });

  const { isValid, values, submitForm, setFieldValue, errors, touched } =
    formik;

  const conversationsChangeHandler = useCallback(
    (conversations: SlackConversation[]) => {
      setFieldValue("body.conversations", conversations);
    },
    [setFieldValue],
  );

  const integrationChangeHandler = useCallback(
    (integrationId: string) => {
      setFieldValue("integrationId", integrationId);

      dispatch(
        getIntegrationByIdThunk({ id: integrationId, type: "slack" }),
      ).then((response: any) => {
        const conversation = response?.payload?.data?.conversation;
        setFieldValue("body.conversations", conversation ? [conversation] : []);
      });
    },
    [dispatch, setFieldValue],
  );

  const messageChangeHandler = useCallback(
    (value: string) => setFieldValue("body.message", value),
    [setFieldValue],
  );

  useEffectOnceWhen(() => {
    setFieldValue("body.message", message);
    setFieldValue("body.resources", resources);
  }, !!message && !!resources?.length);

  return (
    <Dialog open={true} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitleClosable title="Send Slack" onClose={onClose} />
      <DialogContent dividers>
        <IntegrationSelect
          type="slack"
          value={values.integrationId}
          onChange={integrationChangeHandler}
          error={touched.integrationId ? errors.integrationId : ""}
        />

        <SlackConversationsSelect
          disabled={!values.integrationId}
          integrationId={values.integrationId}
          onChange={conversationsChangeHandler}
          selectedConversations={values.body.conversations}
          error={touched.body?.conversations ? errors.body?.conversations : ""}
        />

        <Stack p={2} my={3} spacing={2} bgcolor="grey.50">
          <CreateIntegrationResourcesTableV2
            label="Resources.csv (snippet)"
            resourceType={resourceType}
          />

          <CreateIntegrationMessageEditor
            value={values.body.message ?? ""}
            onChange={messageChangeHandler}
          />
        </Stack>
      </DialogContent>
      <CreateIntegrationMessageFormActions
        disabled={!isValid}
        onCancel={onClose}
        onSubmit={submitForm}
        isLoading={isLoading}
      />
    </Dialog>
  );
};
