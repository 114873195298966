import { FC, memo } from "react";
import { AccountCashFlowMessages } from "./AccountCashFlowMessages";
import { ProviderType } from "../../../../../services/cloudchipr.api";
import { useAppSelector } from "../../../../../store/hooks";
import { allAccountsCostsByProviderAndOrgIdSelector } from "../../../../../store/accounts/selectors/all-providers/allAccountsCostsByProviderAndOrgIdSelector";

interface AccountsHeaderProps {
  provider: ProviderType;
  organizationId: string;
}

export const ProviderAccountCashs: FC<AccountsHeaderProps> = memo(
  ({ provider, organizationId }) => {
    const orgAccountsCosts = useAppSelector((state) =>
      allAccountsCostsByProviderAndOrgIdSelector(
        state,
        provider,
        organizationId,
      ),
    );

    const ems = orgAccountsCosts?.ems ?? 0;
    const spending = orgAccountsCosts?.spending ?? 0;
    const saving = orgAccountsCosts?.savedCost ?? 0;

    return (
      <AccountCashFlowMessages ems={ems} spending={spending} saving={saving} />
    );
  },
);
