import { FC, Fragment } from "react";
import { DynamoDbBackupType } from "../../../../../../../../../../services/cloudchipr.api";

interface BackupTypeProps {
  data: DynamoDbBackupType;
}

export const BackupType: FC<BackupTypeProps> = ({ data }) => {
  return <Fragment>{labels?.[data]}</Fragment>;
};

const labels: Record<DynamoDbBackupType, string> = {
  aws_backup: "AWS Backup",
  user: "User",
  system: "System",
};
