import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  cloudChiprApi,
  Date,
  ScheduleFrequency,
} from "../../../services/cloudchipr.api";
import { formatDate } from "../../../utils/helpers/date-time/datetime-format";
import { getCronFrequency } from "../../automations/utils/helpers/cron/getCronFrequency";
import { getScheduleNextRunDatesFixedCacheKey } from "../../automations/utils/helpers/getScheduleNextRunDatesFixedCacheKey";

interface GetScheduleNextRunDatesThunkArgs {
  startDate?: Date | null;
  endDate?: Date | null;
  cron: string;
  timeZone: string;
  frequency?: ScheduleFrequency;
  interval?: number;
}

export const getScheduleNextRunDatesThunk = createAsyncThunk(
  "schedules/getScheduleNextRunDatesThunkArgs",
  async (
    {
      startDate,
      cron,
      frequency,
      interval,
      endDate,
      timeZone,
    }: GetScheduleNextRunDatesThunkArgs,
    { dispatch },
  ) => {
    const { postUsersMeSchedulesNextRunDate } = cloudChiprApi.endpoints;

    await dispatch(
      postUsersMeSchedulesNextRunDate.initiate(
        {
          body: {
            cron,
            time_zone: timeZone,
            end_date_time: endDate,
            schedule_frequency: frequency,
            schedule_interval: interval,
            start_date_time: startDate ? formatDate(startDate) : null,
          },
        },
        {
          fixedCacheKey: getScheduleNextRunDatesFixedCacheKey(
            getCronFrequency(cron),
          ),
        },
      ),
    ).unwrap();
  },
);
