import { FC, Fragment, useCallback, useState } from "react";
import { Menu } from "@mui/material";
import { LiveUsageMgmtToolbarCleanActionItems } from "./LiveUsageMgmtToolbarCleanActionItems";
import { LiveUsageMgmtProtectionMenuItems } from "./LiveUsageMgmtProtectionMenuItems";
import { useDialog } from "../../../../../../../../../../../utils/hooks/useDialog.hook";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../../../../store/hooks";
import { ResourceType } from "../../../../../../../../../../../services/cloudchipr.api";
import { createV2ProtectThunk } from "../../../../../../../../../../../store/clean-v2/thunks/thunks/createV2ProtectThunk";
import { cleanV2ResourcesProtectionLoadingSelector } from "../../../../../../../../../../../store/clean-v2/selectors/cleanV2ResourcesProtectionLoadingSelector";
import { liveUsageMgmtSelectedResourcesAccountIdsSelector } from "../../../../../../../../../../../store/live-usage-mgmt/selectors/resource-type-data/liveUsageMgmtSelectedResourcesAccountIdsSelector";
import { liveUsageMgmtSelectedRowsIdsByResourceTypeSelector } from "../../../../../../../../../../../store/live-usage-mgmt/selectors/resource-type-data/liveUsageMgmtSelectedRowsIdsByResourceTypeSelector";
import { ProtectDialogV2 } from "../../cells/actions-menu-cell-v2/menu-items/protect/ProtectDialogV2";

interface LiveUsageMgmtToolbarActionsMenuProps {
  resourceType: ResourceType;
  open: boolean;
  anchor: Element | null;
  onClose(): void;
}

export const LiveUsageMgmtToolbarActionsMenu: FC<
  LiveUsageMgmtToolbarActionsMenuProps
> = ({ resourceType, open, onClose, anchor }) => {
  const dispatch = useAppDispatch();

  const accountIds = useAppSelector((state) =>
    liveUsageMgmtSelectedResourcesAccountIdsSelector(state, resourceType),
  );

  const selectedResourceIds = useAppSelector((state) =>
    liveUsageMgmtSelectedRowsIdsByResourceTypeSelector(state, resourceType),
  );

  const protectionLoading = useAppSelector((state) =>
    cleanV2ResourcesProtectionLoadingSelector(
      state,
      accountIds ?? [],
      Object.keys(selectedResourceIds ?? {}),
    ),
  );

  const [protectAction, setProtectAction] = useState<"protect" | "unprotect">();
  const {
    open: protectDialogOpen,
    openDialog: openProtectDialog,
    closeDialog: closeProtectDialog,
  } = useDialog();

  const onProtectDialogConfirm = useCallback(() => {
    if (!protectAction) {
      return;
    }

    dispatch(
      createV2ProtectThunk({
        resourceType,
        actionType: protectAction,
        callBack: closeProtectDialog,
      }),
    );
  }, [dispatch, closeProtectDialog, protectAction, resourceType]);

  return (
    <Fragment>
      <Menu anchorEl={anchor} open={open} onClose={onClose}>
        <LiveUsageMgmtToolbarCleanActionItems
          onClose={onClose}
          resourceType={resourceType}
        />

        <LiveUsageMgmtProtectionMenuItems
          onClose={onClose}
          resourceType={resourceType}
          setProtectAction={setProtectAction}
          openProtectDialog={openProtectDialog}
        />
      </Menu>

      {protectDialogOpen && (
        <ProtectDialogV2
          onClose={closeProtectDialog}
          onConfirm={onProtectDialogConfirm}
          isProtected={protectAction !== "protect"}
          isLoading={protectionLoading}
        />
      )}
    </Fragment>
  );
};
