import { FC } from "react";
import SquareRoundedIcon from "@mui/icons-material/SquareRounded";
import { Stack, Typography } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import InfoIcon from "@mui/icons-material/Info";
import { green, red } from "@mui/material/colors";
import { IconProps } from "@mui/material/Icon/Icon";

interface AccountCardLabelProps {
  label: string;
  description?: string;
  value?: number | string;
  color?: IconProps["color"];
}

export const AccountCardLabel: FC<AccountCardLabelProps> = ({
  label,
  description,
  value,
  color,
}) => {
  const styles = {
    color: SquareRoundedIconColor.get(color),
  };
  return (
    <Stack direction="row" alignItems="flex-start" spacing={1}>
      <SquareRoundedIcon
        color={color}
        sx={{ fontSize: 12, mt: 0.5, color: styles.color }}
      />
      <div>
        <Typography color="grey.700" fontWeight="regular" fontSize="small">
          {label}
          {description && (
            <Tooltip arrow title={description} placement="top">
              <InfoIcon
                fontSize="small"
                sx={{ verticalAlign: "middle", ml: 1, color: "grey.400" }}
              />
            </Tooltip>
          )}
        </Typography>
        <Typography variant="body2" fontWeight="medium">
          {value}/
          <Typography variant="caption" component="span" color="grey.600">
            mo
          </Typography>
        </Typography>
      </div>
    </Stack>
  );
};

const SquareRoundedIconColor = new Map<IconProps["color"], string>([
  ["primary", green[500]],
  ["error", red[500]],
]);
