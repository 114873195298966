import { SvgIcon, SvgIconProps } from "@mui/material";

function SidebarFilledSvgIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <svg
        width="18"
        height="16"
        viewBox="0 0 18 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.666473 3.93137C0.666473 2.03617 2.20264 0.5 4.09785 0.5H13.9018C15.797 0.5 17.3331 2.03617 17.3331 3.93137V11.7745C17.3331 13.6697 15.797 15.2059 13.9018 15.2059H4.09785C2.20264 15.2059 0.666473 13.6697 0.666473 11.7745V3.93137ZM8.75471 1.97059H13.9018C14.9846 1.97059 15.8626 2.84854 15.8626 3.93137V11.7745C15.8626 12.8573 14.9846 13.7353 13.9018 13.7353H8.75471V1.97059ZM4.09789 3.68628C3.69171 3.68628 3.3626 4.01539 3.3626 4.42157C3.3626 4.82775 3.69171 5.15687 4.09789 5.15687H5.56848C5.97467 5.15687 6.30378 4.82775 6.30378 4.42157C6.30378 4.01539 5.97467 3.68628 5.56848 3.68628H4.09789ZM4.09789 6.62745C3.69171 6.62745 3.3626 6.95656 3.3626 7.36275C3.3626 7.76893 3.69171 8.09804 4.09789 8.09804H5.56848C5.97467 8.09804 6.30378 7.76893 6.30378 7.36275C6.30378 6.95656 5.97467 6.62745 5.56848 6.62745H4.09789ZM4.09789 9.56863C3.69171 9.56863 3.3626 9.89774 3.3626 10.3039C3.3626 10.7101 3.69171 11.0392 4.09789 11.0392H5.56848C5.97467 11.0392 6.30378 10.7101 6.30378 10.3039C6.30378 9.89774 5.97467 9.56863 5.56848 9.56863H4.09789Z"
          fill="black"
          fillOpacity="0.87"
        />
      </svg>
    </SvgIcon>
  );
}

SidebarFilledSvgIcon.muiName = "SidebarFilledSvgIcon";

export default SidebarFilledSvgIcon;
