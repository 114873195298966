import { FC } from "react";
import { Box, Stack, Tooltip, Typography } from "@mui/material";
import StarsIcon from "@mui/icons-material/Stars";
import {
  AccountAwsProviderDetails,
  AccountAzureProviderDetails,
  AccountGcpProviderDetails,
} from "../../../../../services/cloudchipr.api";

interface ProviderAccountNameCellProps {
  providerId: string | null;
  providerName?: string | null;
  providerDetails:
    | AccountAwsProviderDetails
    | AccountGcpProviderDetails
    | AccountAzureProviderDetails;
}

export const ProviderAccountNameCell: FC<ProviderAccountNameCellProps> = ({
  providerId,
  providerName,
  providerDetails,
}) => {
  let isRoot = false;

  if (providerDetails && "aws" in providerDetails) {
    isRoot = !!providerDetails?.aws?.is_root;
  }

  return (
    <Stack direction="row" alignItems="center">
      <Box whiteSpace="nowrap">
        {providerName || ""}
        <Typography color="text.disabled" variant="body2">
          {providerId}
        </Typography>
      </Box>
      {isRoot && (
        <Tooltip title="Management account" placement="top" arrow>
          <StarsIcon color="primary" sx={{ verticalAlign: "middle", ml: 2 }} />
        </Tooltip>
      )}
    </Stack>
  );
};
