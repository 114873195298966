import { cloudChiprApi } from "../../../../services/cloudchipr.api";
import { updateResourceExplorerVisibilityHierarchyFixedCacheKey } from "../../utils/constants/fixedCacheKeys";
import { RootState } from "../../../store";

const endpoint =
  cloudChiprApi.endpoints.patchV2UsersMeOrganisationsCurrentResourceExplorerHierarchy.select(
    updateResourceExplorerVisibilityHierarchyFixedCacheKey,
  );

export const updateResourceExplorerVisibilityLoadingSelector = (
  state: RootState,
) => {
  return endpoint(state)?.isLoading;
};
