import { FC, useCallback, useEffect } from "react";
import { Table } from "@tanstack/react-table";

import { reservationsRecommendationsDataGridColumns } from "./data-grid/reservationsRecommendationsDataGridColumns";
import { ReservationsRecommendationsDataGridFooter } from "./ReservationsRecommendationsDataGridFooter";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../store/hooks";
import { DataGrid } from "../../../../../../../storybook/data-grid/DataGrid";
import { ToolbarConfig } from "../../../../../../../storybook/data-grid/utils/types/prop-types";
import { useCommitmentsColumnsVisibility } from "../../../../common/utils/hooks/useColumnsVisibility.hook";
import { getCommitmentsTableStyles } from "../../../../common/utils/helpers/grid/getCommitmentsTableStyles";
import { commitmentsCurrentOrgIdSelector } from "../../../../../../../store/commitments/selectors/common/commitmentsCurrentOrgIdSelector";
import { getReservationsRecommendationsThunk } from "../../../../../../../store/commitments/thunks/recommendations/reservations/getReservationsRecommendationsThunk";
import { reservationsRecommendationsFilteredDataSelector } from "../../../../../../../store/commitments/selectors/recommendations/reservations/reservationsRecommendationsFilteredDataSelector";
import { reservationsRecommendationsLoadingSelector } from "../../../../../../../store/commitments/selectors/recommendations/reservations/reservationsRecommendationsLoadingSelector";
import { setCommitmentsRecommendationsFilters } from "../../../../../../../store/commitments/commitmentsSlice";

import { setReservationsRecommendationsInitialFiltersByPotentialSavingsThunk } from "../../../../../../../store/commitments/thunks/recommendations/reservations/setReservationsRecommendationsInitialFiltersByPotentialSavingsThunk";

interface ReservationsRecommendationsDataGridProps {
  toolbar: ToolbarConfig;
}

export const ReservationsRecommendationsDataGrid: FC<
  ReservationsRecommendationsDataGridProps
> = ({ toolbar }) => {
  const dispatch = useAppDispatch();

  const data = useAppSelector(reservationsRecommendationsFilteredDataSelector);
  const orgId = useAppSelector(commitmentsCurrentOrgIdSelector);
  const loading = useAppSelector(reservationsRecommendationsLoadingSelector);

  const empty = !loading && !data?.length;

  const { visibility, setVisibility } = useCommitmentsColumnsVisibility(
    "reservation",
    "recommendations",
  );

  const renderPagination = useCallback(
    (table: Table<any>) => {
      const rows = table.getRowModel().rows;

      if (empty) {
        return <ReservationsRecommendationsDataGridFooter option="emptyData" />;
      }

      if (rows.length || loading) {
        return null;
      }

      return (
        <ReservationsRecommendationsDataGridFooter option="noFilteredData" />
      );
    },
    [empty, loading],
  );

  useEffect(() => {
    dispatch(setCommitmentsRecommendationsFilters(null));
    dispatch(getReservationsRecommendationsThunk());
    dispatch(
      setReservationsRecommendationsInitialFiltersByPotentialSavingsThunk(),
    );
  }, [dispatch, orgId]);

  return (
    <DataGrid
      columnVisibility={{
        initialVisibility: visibility,
        onColumnsVisibilityChange: setVisibility,
      }}
      columnSorting
      columnResizeMode="onEnd"
      enableStickyColumns
      data={data ?? []}
      columns={reservationsRecommendationsDataGridColumns}
      toolbar={toolbar}
      styles={getCommitmentsTableStyles(empty)}
      pagination={{
        renderPagination,
      }}
    />
  );
};
