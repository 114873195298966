import { createAsyncThunk } from "@reduxjs/toolkit";
import { enqueueSnackbar } from "notistack";
import { checkForOrganizationExistenceThunk } from "../checkForOrganizationExistenceThunk";
import { RootState } from "../../../../store";
import { cloudChiprApi } from "../../../../../services/cloudchipr.api";
import { reservationsRecommendationsPayloadSelector } from "../../../selectors/recommendations/reservations/reservationsRecommendationsPayloadSelector";

export const getReservationsRecommendationsThunk = createAsyncThunk(
  "commitments/getReservationsRecommendationsThunk",
  async (_: void, { dispatch, getState }) => {
    dispatch(checkForOrganizationExistenceThunk());

    const state = getState() as RootState;

    const { getUsersMeOrganisationsCurrentReservationsRecommendations } =
      cloudChiprApi.endpoints;

    const payload = reservationsRecommendationsPayloadSelector(state);
    try {
      await dispatch(
        getUsersMeOrganisationsCurrentReservationsRecommendations.initiate({
          ...payload,
        }),
      ).unwrap();
    } catch (e) {
      // @ts-expect-error todo: api fix
      const errMessage = e?.data?.message || "Something went wrong";
      enqueueSnackbar(errMessage, {
        variant: "snackbarAlert",
        AlertSnackBarProps: { severity: "error" },
      });

      return null;
    }
  },
);
