import { ColumnSetupType } from "../../../../../../../../../../../../../../storybook/data-grid/utils/types/types";
import { NodePool } from "../../../../../../../../../../../../../../services/cloudchipr.api";

export const nodePoolColumns: ColumnSetupType<NodePool>[] = [
  {
    accessorKey: "name",
    header: "Name",
    size: 200,
    type: "identifier",
    meta: { sticky: "left", hideFromSettings: true },
  },
  {
    accessorKey: "price_per_month",
    header: "Monthly Price",
    type: "money",
    size: 200,
    headerTooltip:
      "Monthly prices are calculated based on the on-demand list price of each resource.",
  },
  {
    accessorKey: "instances_count",
    header: "Nodes",
    size: 200,
  },
  {
    accessorKey: "created_at",
    header: "Launch time",
    type: "date",
    size: 200,
  },
  {
    accessorKey: "tags",
    header: "Tags",
    type: "tag",
    enableSorting: false,
    size: 200,
  },
];
