import { FC, memo, useEffect, useState } from "react";
import { Card, Stack } from "@mui/material";
import TabContext from "@mui/lab/TabContext";
import { Daily } from "../tabs/daily/Daily";
import { Weekly } from "../tabs/weekly/Weekly";
import { Once } from "../tabs/Once";
import { ScheduleTabs } from "../tabs/ScheduleTabs";
import { OffHoursSchedulerData } from "../../../utils/types/types";
import { AutomationTabType } from "../../../../common/utils/types/types";
import { getCronFrequency } from "../../../../../../../store/automations/utils/helpers/cron/getCronFrequency";

interface ScheduleComponentProps {
  id: string;
  timeZone: string;
  startDate: string | null;
  endDate: string | null;
  stopCron: string | null;
  startCron: string | null;
  setScheduleDatesData(data: OffHoursSchedulerData): void;
  onPreviousStep(): void;
  isDirty: boolean;
}

export const ScheduleComponent: FC<ScheduleComponentProps> = memo(
  ({
    endDate,
    startDate,
    startCron,
    stopCron,
    setScheduleDatesData,
    timeZone,
    onPreviousStep,
    id,
    isDirty,
  }) => {
    const [activeTab, setActiveTab] = useState<AutomationTabType>("daily");

    useEffect(() => {
      if (!startCron && startDate) {
        setActiveTab("once");
      }

      if (!startCron) {
        return;
      }

      setActiveTab(getCronFrequency(startCron));
    }, [startCron, timeZone, startDate]);

    return (
      <TabContext value={activeTab}>
        <Stack direction="row" mt={6} spacing={3}>
          <ScheduleTabs
            tabs={["daily", "weekly", "once"]}
            setActiveTab={setActiveTab}
            activeTab={activeTab}
          />

          <Card sx={{ width: "100%" }} variant="outlined">
            <Daily
              id={id}
              startCron={startCron}
              stopCron={stopCron}
              endDate={endDate}
              timeZone={timeZone}
              startDate={startDate}
              onPreviousStep={onPreviousStep}
              setScheduleDatesData={setScheduleDatesData}
              isDirty={isDirty}
            />
            <Weekly
              id={id}
              startCron={startCron}
              stopCron={stopCron}
              endDate={endDate}
              timeZone={timeZone}
              startDate={startDate}
              onPreviousStep={onPreviousStep}
              setScheduleDatesData={setScheduleDatesData}
              isDirty={isDirty}
            />
            <Once
              id={id}
              endDate={endDate}
              timeZone={timeZone}
              startDate={startDate}
              onPreviousStep={onPreviousStep}
              setScheduleDatesData={setScheduleDatesData}
              isDirty={isDirty}
            />
          </Card>
        </Stack>
      </TabContext>
    );
  },
);
