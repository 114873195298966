import { FC } from "react";
import { Chip, IconButton, Stack } from "@mui/material";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import { useAppSelector } from "../../../../store/hooks";
import { reportsTableFilteredDataBySourceLengthSelector } from "../../../../store/reports/selector/reportsTableFilteredDataBySourceLengthSelector";
import { reportCurrentSourceNameBySourceSelector } from "../../../../store/reports/selector/reportCurrentSourceNameBySourceSelector";
import { reportsDataPropertyByKeySelector } from "../../../../store/reports/selector/reportsDataPropertyByKeySelector";

interface ResourceExplorerDrawerHeaderProps {
  onClose(): void;
}

export const ReportsBySourceDrawerHeader: FC<
  ResourceExplorerDrawerHeaderProps
> = ({ onClose }) => {
  const sourceType = useAppSelector(
    reportsDataPropertyByKeySelector("source_type"),
  );
  const length = useAppSelector(reportsTableFilteredDataBySourceLengthSelector);
  const name = useAppSelector(reportCurrentSourceNameBySourceSelector);

  return (
    <Stack
      py={1}
      px={2}
      direction="row"
      alignItems="center"
      justifyContent="space-between"
    >
      <Stack direction="row" spacing={1} alignItems="center">
        <Typography variant="subtitle1">
          Subscriptions of{" "}
          <Typography component="span" variant="inherit" fontWeight="medium">
            {name}
          </Typography>{" "}
          {sourceType}
        </Typography>

        <Chip
          size="small"
          label={length ?? 0}
          sx={{ bgcolor: "primary.light", color: "primary.main" }}
        />
      </Stack>
      <IconButton size="small" onClick={onClose}>
        <CloseIcon fontSize="small" />
      </IconButton>
    </Stack>
  );
};
