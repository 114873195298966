import { FC } from "react";
import { IconButton, Link, Stack, Tooltip } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import CloudOutlinedIcon from "@mui/icons-material/CloudOutlined";
import { PlayCircleOutlined } from "@mui/icons-material";
import { ResourceTarget } from "../../../../../../../../../../services/cloudchipr.api";
import { getProviderName } from "../../../../../../../../../../utils/helpers/providers/getProviderName";
import { TypographyWithTooltip } from "../../../../../../../../../common/TypographyWithTooltip";
import { useHover } from "../../../../../../../../../../utils/hooks/useHover.hook";
import { useAppSelector } from "../../../../../../../../../../store/hooks";
import { isTaskManagementDrawerEditModeSelector } from "../../../../../../../../../../store/task-management/selectors/form/options/isTaskManagementDrawerEditModeSelector";

interface TargetSingleResourceTableIdentifierRowProps {
  resource: ResourceTarget;
  showOnlyOnHover?: boolean;
}

export const TargetSingleResourceTableIdentifierRow: FC<
  TargetSingleResourceTableIdentifierRowProps
> = ({ resource, showOnlyOnHover }) => {
  const edit = useAppSelector(isTaskManagementDrawerEditModeSelector);

  const { ref, hovered } = useHover();

  const showIcon = showOnlyOnHover ? hovered : true;

  return (
    <Stack
      direction="row"
      spacing={1}
      alignItems="center"
      ref={showOnlyOnHover ? ref : undefined}
    >
      <TypographyWithTooltip
        title={resource.provider_identifier}
        variant="body2"
        fontWeight="medium"
      />

      {showIcon && resource.link && (
        <Tooltip
          arrow
          placement="top"
          title={`View in ${getProviderName(resource.account.provider, { title: true })}`}
        >
          <IconButton
            size="small"
            target="_blank"
            to={resource.link}
            component={RouterLink}
          >
            <CloudOutlinedIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      )}

      {edit && resource.state === "live" && (
        <Tooltip title="View in Live Usage & Mgmt." arrow placement="top">
          <IconButton
            size="small"
            component={Link}
            href={`/${resource.account.provider}/live-usage/?accountIds=${resource.account.id}&rt=${resource.resource_type}&resourceIds=${resource.provider_identifier}`}
            target="_blank"
          >
            <PlayCircleOutlined fontSize="small" color="action" />
          </IconButton>
        </Tooltip>
      )}
    </Stack>
  );
};
