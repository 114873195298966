import { createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../../../store";
import { alertsDataThresholdSelector } from "../../selectors/common/data/alertsDataThresholdSelector";
import { setAlertsData } from "../../alertsSlice";
import { AlertThresholdTypeItem } from "../../utils/types/types";

interface ChangeUtilizationAlertThresholdAmountThunkArgs {
  index: number;
  amount?: string;
}

export const changeUtilizationAlertThresholdAmountThunk = createAsyncThunk(
  "alerts/changeUtilizationAlertThresholdAmount",
  async (
    { index, amount }: ChangeUtilizationAlertThresholdAmountThunkArgs,
    { dispatch, getState },
  ) => {
    const state = getState() as RootState;

    const threshold = alertsDataThresholdSelector(state);

    if (!threshold) {
      return;
    }

    const updatedData = threshold?.threshold_items?.map((item, itemIndex) => {
      if (index === itemIndex) {
        const data: AlertThresholdTypeItem = {};

        if (amount !== undefined) {
          data.amount = amount;
        }
        return {
          ...item,
          ...data,
        };
      }
      return item;
    });

    if (updatedData) {
      dispatch(
        setAlertsData({
          threshold: {
            ...threshold,
            threshold_items: updatedData,
          },
        }),
      );
    }
  },
);
