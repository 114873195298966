import { getCostBreakdownREDataFieldsCountOccurrences } from "./getCostBreakdownREDataFieldsCountOccurrences";
import {
  ProviderType,
  ResourceExplorerGridTotal,
} from "../../../../../services/cloudchipr.api";
import { ResourceExplorerGridDataWithId } from "../../../../resource-explorer/utils/types/resourceExplorerDataGrid";

export const formatCostBreakdownREDataGridData = (
  data: ResourceExplorerGridTotal[],
  includeDuplications?: boolean,
) => {
  if (!data) {
    return null;
  }

  if (includeDuplications) {
    return data.reduce((result, item) => {
      if (!item.metadata.aggregated_by_cost) {
        result.push({
          ...item,
          id: generateId(
            item.field,
            item?.metadata?.live_account_id,
            item.cloud_provider,
          ),
        });
      }
      return result;
    }, [] as ResourceExplorerGridDataWithId[]);
  }
  const occurrences = getCostBreakdownREDataFieldsCountOccurrences(data);

  const keys = occurrences?.keys();
  const keysArr = keys ? Array.from(keys) : [];
  return keysArr.reduce((result, key) => {
    const item = occurrences?.get(key);
    const occurrencesItem = occurrences?.get(item.field);
    if (!item.metadata.aggregated_by_cost) {
      result.push({
        ...occurrencesItem,
        id: generateId(item.field, item?.metadata?.account_id),
      });
    }
    return result;
  }, [] as ResourceExplorerGridDataWithId[]);
};

const generateId = (
  field: string,
  id: string,
  provider?: ProviderType,
): string => {
  id = id ?? field;

  if (provider) {
    return id + provider;
  }

  return id;
};
