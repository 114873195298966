import { FC, useCallback, useMemo } from "react";
import moment from "moment/moment";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import {
  setExecutionLogDates,
  setExecutionLogPagination,
} from "../../../../store/execution-log/executionLogSlice";
import { getExecutionLogThunk } from "../../../../store/execution-log/thunks/getExecutionLogThunk";
import {
  Dates,
  useGetUsersMeExecutionLogsMinimumAvailableDateQuery,
} from "../../../../services/cloudchipr.api";
import { DateRangePicker } from "../../../common/date-range-picker/DateRangePicker";
import { executionLogDatesSelector } from "../../../../store/execution-log/selectors/executionLogDatesSelector";
import {
  getRreDefinedAllRangesForExecutionLogs,
  MAX_DATE_MONTHS,
} from "../utils/constants/dates";

export const ExecutionLogsDateRangeSelector: FC = () => {
  const dispatch = useAppDispatch();
  const dates = useAppSelector(executionLogDatesSelector);
  const { data } = useGetUsersMeExecutionLogsMinimumAvailableDateQuery();
  const minAvailableDate = data?.minimum_available_date ?? undefined;
  const ranges = useMemo(
    () => getRreDefinedAllRangesForExecutionLogs(minAvailableDate),
    [minAvailableDate],
  );

  const submitHandler = useCallback(
    (dates: Dates) => {
      dispatch(setExecutionLogDates(dates));
      dispatch(setExecutionLogPagination({ page: 0 }));
      dispatch(getExecutionLogThunk({}));
    },
    [dispatch],
  );

  return (
    <DateRangePicker
      highlightToday
      minDate={minAvailableDate ?? minDate}
      selectedDates={dates as Dates}
      onDateSelect={submitHandler}
      availableMinDate={minAvailableDate}
      ranges={ranges}
    />
  );
};

const minDate = moment()
  .subtract(MAX_DATE_MONTHS, "month")
  .startOf("month")
  .format();
