export const agGridOverwrittenIcons = {
  sortAscending: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960" width="16" height="16"  fill="currentColor"><path d="M444-192v-438L243-429l-51-51 288-288 288 288-51 51-201-201v438h-72Z"/></svg>`,
  sortDescending: `<svg xmlns="http://www.w3.org/2000/svg" height="18" viewBox="0 -960 960 960" width="18" fill="currentColor"><path d="M444-768v438L243-531l-51 51 288 288 288-288-51-51-201 201v-438h-72Z"/></svg>`,
  sortUnSort: `<svg xmlns="http://www.w3.org/2000/svg" height="16" viewBox="0 -960 960 960" width="16" fill="currentColor"><path d="M480-144 318-306l51-51 111 111 111-111 51 51-162 162ZM369-603l-51-51 162-162 162 162-51 51-111-111-111 111Z"/></svg>`,
  dropNotAllowed: `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none"><path fill="#000" d="m3.928 2.867.53.53 8.05 8.05.53.531-1.06 1.06-.53-.53-8.05-8.05-.53-.53 1.06-1.06Z" /><path fill="#000" d="M8 2.5a5.5 5.5 0 1 0 0 11 5.5 5.5 0 0 0 0-11ZM1 8a7 7 0 1 1 14 0A7 7 0 0 1 1 8Z"/></svg>`,
  rowDrag: `<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 -960 960 960" fill="currentColor"><path d="M360-160q-33 0-56.5-23.5T280-240q0-33 23.5-56.5T360-320q33 0 56.5 23.5T440-240q0 33-23.5 56.5T360-160Zm240 0q-33 0-56.5-23.5T520-240q0-33 23.5-56.5T600-320q33 0 56.5 23.5T680-240q0 33-23.5 56.5T600-160ZM360-400q-33 0-56.5-23.5T280-480q0-33 23.5-56.5T360-560q33 0 56.5 23.5T440-480q0 33-23.5 56.5T360-400Zm240 0q-33 0-56.5-23.5T520-480q0-33 23.5-56.5T600-560q33 0 56.5 23.5T680-480q0 33-23.5 56.5T600-400ZM360-640q-33 0-56.5-23.5T280-720q0-33 23.5-56.5T360-800q33 0 56.5 23.5T440-720q0 33-23.5 56.5T360-640Zm240 0q-33 0-56.5-23.5T520-720q0-33 23.5-56.5T600-800q33 0 56.5 23.5T680-720q0 33-23.5 56.5T600-640Z"/></svg>`,
  columnDrag: `<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 -960 960 960" fill="currentColor"><path d="M360-160q-33 0-56.5-23.5T280-240q0-33 23.5-56.5T360-320q33 0 56.5 23.5T440-240q0 33-23.5 56.5T360-160Zm240 0q-33 0-56.5-23.5T520-240q0-33 23.5-56.5T600-320q33 0 56.5 23.5T680-240q0 33-23.5 56.5T600-160ZM360-400q-33 0-56.5-23.5T280-480q0-33 23.5-56.5T360-560q33 0 56.5 23.5T440-480q0 33-23.5 56.5T360-400Zm240 0q-33 0-56.5-23.5T520-480q0-33 23.5-56.5T600-560q33 0 56.5 23.5T680-480q0 33-23.5 56.5T600-400ZM360-640q-33 0-56.5-23.5T280-720q0-33 23.5-56.5T360-800q33 0 56.5 23.5T440-720q0 33-23.5 56.5T360-640Zm240 0q-33 0-56.5-23.5T520-720q0-33 23.5-56.5T600-800q33 0 56.5 23.5T680-720q0 33-23.5 56.5T600-640Z"/></svg>`,
  columnMoveMove: `<svg xmlns="http://www.w3.org/2000/svg" height="16" viewBox="0 -960 960 960" width="16" fill="currentColor"><path d="M480-96 312-264l51-51 81 81v-174h72v174l81-81 51 51L480-96ZM264-312 96-480l168-168 51 51-81 81h174v72H234l81 81-51 51Zm432 0-51-51 81-81H552v-72h174l-81-81 51-51 168 168-168 168ZM444-552v-174l-81 81-51-51 168-168 168 168-51 51-81-81v174h-72Z"/></svg>`,
  menuAlt: `<svg xmlns="http://www.w3.org/2000/svg" height="18" viewBox="0 0 24 24" width="18"  fill="currentColor"><path d="M0 0h24v24H0z" fill="none"/><path d="M12 8c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm0 2c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z"/></svg>`,
  groupContracted: `<svg xmlns="http://www.w3.org/2000/svg" height="18" viewBox="0 0 24 24" width="18" fill="currentColor"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z"/></svg>`,
  groupExpanded: `<svg xmlns="http://www.w3.org/2000/svg" height="18" viewBox="0 0 24 24" width="18" fill="currentColor" ><path d="M0 0h24v24H0z" fill="none"/><path d="M7.41 15.41L12 10.83l4.59 4.58L18 14l-6-6-6 6z"/></svg>`,
  cancel: `<svg xmlns="http://www.w3.org/2000/svg" height="18" viewBox="0 0 24 24" width="18" fill="currentColor"><path d="M0 0h24v24H0z" fill="none"/><path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"/></svg>`,
  close: `<svg xmlns="http://www.w3.org/2000/svg" height="18" viewBox="0 0 24 24" width="18" fill="currentColor"><path d="M0 0h24v24H0z" fill="none"/><path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"/></svg>`,
  check: `<svg xmlns="http://www.w3.org/2000/svg" height="18" viewBox="0 0 24 24" width="18" fill="currentColor"><path d="M0 0h24v24H0z" fill="none"/><path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z"/></svg>`,
  rowGroupPanel: `<svg xmlns="http://www.w3.org/2000/svg" height="18" viewBox="0 0 24 24" width="18" fill="currentColor"><g><rect fill="none" height="24" width="24"/></g><g><g><rect height="2" width="11" x="3" y="10"/><rect height="2" width="11" x="3" y="6"/><rect height="2" width="7" x="3" y="14"/><polygon points="20.59,11.93 16.34,16.17 14.22,14.05 12.81,15.46 16.34,19 22,13.34"/></g></g></svg>`,
  menuRemoveRowGroup: `<svg xmlns="http://www.w3.org/2000/svg" height="18" viewBox="0 0 24 24" width="18" fill="currentColor"><g><rect fill="none" height="24" width="24"/></g><g><g><rect height="2" width="11" x="3" y="10"/><rect height="2" width="11" x="3" y="6"/><rect height="2" width="7" x="3" y="14"/><polygon points="20.59,11.93 16.34,16.17 14.22,14.05 12.81,15.46 16.34,19 22,13.34"/></g></g></svg>`,
  menuPin: `<svg xmlns="http://www.w3.org/2000/svg" height="18" viewBox="0 0 24 24" width="18" fill="transparent" stroke="black" stroke-width="1"><g><path d="M16,9V4l1,0c0.55,0,1-0.45,1-1v0c0-0.55-0.45-1-1-1H7C6.45,2,6,2.45,6,3v0 c0,0.55,0.45,1,1,1l1,0v5c0,1.66-1.34,3-3,3h0v2h5.97v7l1,1l1-1v-7H19v-2h0C17.34,12,16,10.66,16,9z" /></g></svg>`,
  smallRight: `<svg xmlns="http://www.w3.org/2000/svg" height="18" viewBox="0 0 24 24" width="18" fill="currentColor"><path d="M0 0h24v24H0z" fill="none"/><path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"/></svg>`,

  // columnMoveRight: `R`,
  // menu: '<i class="fa fa-university"/>',
  // filter:: '<i class="fa fa-university"/>',
  // columns: '<i class="fa fa-university"/>',
  // columnMovePin: '<i class="far fa-hand-rock"/>',
  // columnMoveAdd: '<i class="fa fa-plus-square"/>',
  // columnMoveHide: '<i class="fa fa-times"/>',
  // columnMoveLeft: '<i class="fa fa-arrow-left"/>',
  // columnMoveGroup: '<i class="fa fa-users"/>',
  // pivotPanel: '<i class="fa fa-magic"/>',
  // valuePanel: '<i class="fa fa-magnet"/>',
  // menuValue: "V",
  // menuAddRowGroup: "A",
  // menuRemoveRowGroup: "R",
  // clipboardCopy: ">>",
  // clipboardCut: "<<",
  // clipboardPaste: ">>",
  // columnGroupOpened: "expanded",
  // columnGroupClosed: "contracted",
  // columnSelectClosed: "tree-closed",
  // columnSelectOpen: "tree-open",
  // columnSelectIndeterminate: "tree-indeterminate",
  // columnMovePivot: "pivot",
  // setFilterGroupClosed: "tree-closed",
  // setFilterGroupOpen: "tree-open",
  // setFilterGroupIndeterminate: "tree-indeterminate",
  // chart: "chart",
  // check: "tick",
  // first: "first",
  // previous: "previous",
  // next: "next",
  // last: "last",
  // linked: "linked",
  // unlinked: "unlinked",
  // colorPicker: "color-picker",
  // groupLoading: "loading",
  // maximize: "maximize",
  // minimize: "minimize",
  // save: "save",
  // csvExport: "csv",
  // excelExport: "excel",
  // smallDown: "small-down",
  // smallLeft: "small-left",
  // smallUp: "small-up",
  // advancedFilterBuilder: "group",
  // advancedFilterBuilderDrag: "grip",
  // advancedFilterBuilderInvalid: "not-allowed",
  // advancedFilterBuilderMoveUp: "up",
  // advancedFilterBuilderMoveDown: "down",
  // advancedFilterBuilderAdd: "plus",
  // advancedFilterBuilderRemove: "minus",
  // chartsMenuEdit: "chart",
  // chartsMenuAdvancedSettings: "settings",
  // chartsMenuAdd: "plus",
  // checkboxChecked: "checkbox-checked",
  // checkboxIndeterminate: "checkbox-indeterminate",
  // checkboxUnchecked: "checkbox-unchecked",
  // radioButtonOn: "radio-button-on",
  // radioButtonOff: "radio-button-off",
};
