import { taskManagementTargetSelector } from "../taskManagementTargetSelector";
import { RootState } from "../../../../../store";
import { tasksListResourceIdsSelector } from "../../../data/tasksListResourceIdsSelector";

export const taskManagementAlreadyHasTaskResourcesIdsSelector = (
  state: RootState,
): string[] | undefined => {
  const target = taskManagementTargetSelector(state);
  const resourceIdsInTasks = tasksListResourceIdsSelector(state);

  return target?.resources
    ?.filter((resource) => resourceIdsInTasks[resource.provider_identifier])
    .map((resource) => resource.provider_identifier);
};
