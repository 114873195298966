import { FC } from "react";
import { Stack, Typography } from "@mui/material";
import EmptyImage from "../../../../assets/images/execution_logs_empty_state.svg";

export const ExecutionLogsEmptyState: FC = () => {
  return (
    <Stack
      justifyContent="center"
      alignItems="center"
      py={10}
      border={1}
      borderColor="grey.300"
    >
      <Stack justifyContent="center" alignItems="center">
        <img src={EmptyImage} alt="Empty Execution Logs" width={120} />
        <Typography
          variant="body1"
          color="text.disabled"
          textAlign="center"
          fontWeight="medium"
        >
          No results found.
        </Typography>
        <Typography
          variant="body1"
          color="text.disabled"
          textAlign="center"
          fontWeight="medium"
        >
          Please refine your search.
        </Typography>
      </Stack>{" "}
    </Stack>
  );
};
