import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  RecommendationsSliceInterface,
  RightsizingRecommendationsAdvancedFilters,
} from "./utils/types/types";
import { DismissPeriod, ProviderType } from "../../services/cloudchipr.api";
import { TagsFilter } from "../../components/common/select/tag-filter-dropdown-select/utils/types";

const initialState: RecommendationsSliceInterface = {
  dismiss: {
    selectedIds: [],
    dismissPeriod: null,
    resourcesReadyToRestore: false,
    recommendationsReadyToDismiss: false,
    recommendationsReadyToRestore: false,
  },

  filters: {
    providers: [],
    types: [],
    accounts: [],
    tags: null,
    displayRecommendationsAcrossInstanceFamily: false,
    multipleRecommendationsPerResource: false,
  },
};

export const recommendationsSlice = createSlice({
  name: "recommendations",
  initialState,
  reducers: {
    // dismiss/restore
    setDismissPeriod: (state, action: PayloadAction<DismissPeriod | null>) => {
      state.dismiss.dismissPeriod = action.payload;
    },
    setSelectedIds: (state, action: PayloadAction<string[]>) => {
      state.dismiss.selectedIds = action.payload;
    },
    setRecommendationsReadyToDismiss: (
      state,
      action: PayloadAction<boolean>,
    ) => {
      state.dismiss.recommendationsReadyToDismiss = action.payload;
    },
    setRecommendationsReadyToRestore: (
      state,
      action: PayloadAction<boolean>,
    ) => {
      state.dismiss.recommendationsReadyToRestore = action.payload;
    },
    setResourcesReadyToRestore: (state, action: PayloadAction<boolean>) => {
      state.dismiss.resourcesReadyToRestore = action.payload;
    },
    closeReadyToActionsDialogs: (state) => {
      state.dismiss.dismissPeriod = null;
      state.dismiss.resourcesReadyToRestore = false;
      state.dismiss.recommendationsReadyToRestore = false;
      state.dismiss.recommendationsReadyToDismiss = false;
    },
    resetDismiss: (state) => {
      state.dismiss = initialState.dismiss;
    },

    // filters
    setRecommendationsProviderFilter: (
      state,
      action: PayloadAction<ProviderType[]>,
    ) => {
      state.filters.providers = action.payload;
    },
    setRecommendationsTypeFilter: (state, action: PayloadAction<string[]>) => {
      state.filters.types = action.payload;
    },
    setRecommendationsAccountFilter: (
      state,
      action: PayloadAction<string[]>,
    ) => {
      state.filters.accounts = action.payload;
    },
    setRecommendationsTagsFilter: (
      state,
      action: PayloadAction<TagsFilter>,
    ) => {
      state.filters.tags = action.payload;
    },
    setRecommendationsAdvancedFilters: (
      state,
      action: PayloadAction<RightsizingRecommendationsAdvancedFilters>,
    ) => {
      state.filters.displayRecommendationsAcrossInstanceFamily =
        action.payload.displayRecommendationsAcrossInstanceFamily;
      state.filters.multipleRecommendationsPerResource =
        action.payload.multipleRecommendationsPerResource;
    },
    toggleRecommendationsDisplayRecommendationsAcrossInstanceFamilyFilter: (
      state,
    ) => {
      state.filters.displayRecommendationsAcrossInstanceFamily =
        !state.filters.displayRecommendationsAcrossInstanceFamily;
    },
    toggleRecommendationsMultipleRecommendationsPerResourceFilter: (state) => {
      state.filters.multipleRecommendationsPerResource =
        !state.filters.multipleRecommendationsPerResource;
    },
    resetRecommendationsFilters: (state) => {
      state.filters = initialState.filters;
    },
  },
});

export const {
  resetDismiss,
  setSelectedIds,
  setDismissPeriod,
  closeReadyToActionsDialogs,
  setResourcesReadyToRestore,
  setRecommendationsReadyToDismiss,
  setRecommendationsReadyToRestore,

  setRecommendationsTypeFilter,
  setRecommendationsProviderFilter,
  setRecommendationsAccountFilter,
  setRecommendationsTagsFilter,
  setRecommendationsAdvancedFilters,
  toggleRecommendationsDisplayRecommendationsAcrossInstanceFamilyFilter,
  toggleRecommendationsMultipleRecommendationsPerResourceFilter,
  resetRecommendationsFilters,
} = recommendationsSlice.actions;

export default recommendationsSlice.reducer;
