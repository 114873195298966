import { createDraftSafeSelector } from "@reduxjs/toolkit";
import { dashboardHierarchyVisibilitiesDataSelector } from "./dashboardHierarchyVisibilitiesDataSelector";
import {
  Dashboard,
  DashboardFolder,
} from "../../../../services/cloudchipr.api";
import { isHierarchyFolder } from "../../../navigation/utils/helpers/isHierarchyFolder";

export const dashboardHierarchyVisibilitiesMergedDataItemsSelector =
  createDraftSafeSelector(
    [dashboardHierarchyVisibilitiesDataSelector],
    (data): Dashboard[] | undefined => {
      const privateData = data?.visible_only_to_me;
      const publicData = data?.visible_to_everyone;
      const privateDataItems = formatData(privateData);
      const publicDataItems = formatData(publicData);
      return publicDataItems.concat(privateDataItems);
    },
  );

const formatData = (data?: (Dashboard | DashboardFolder)[]) => {
  return (
    data?.reduce((result, item) => {
      if (!isHierarchyFolder(item)) {
        result.push(item);
      } else if (item.items) {
        result = result.concat(item.items);
      }
      return result;
    }, [] as Dashboard[]) ?? []
  );
};
