import { FC, useCallback } from "react";
import { ListItemIcon, ListItemText, MenuItem } from "@mui/material";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { useAppDispatch } from "../../../../../../../store/hooks";
import { useAppAbility } from "../../../../../../../services/permissions";
import { openDrawerForTaskByIdThunk } from "../../../../../../../store/task-management/thunks/openDrawerForTaskByIdThunk";

interface TaskEditActionMenuItemProps {
  taskId: string;
  onClose(): void;
}

export const TaskEditActionMenuItem: FC<TaskEditActionMenuItemProps> = ({
  taskId,
  onClose,
}) => {
  const dispatch = useAppDispatch();
  const { can } = useAppAbility();
  const canEditTask = can("edit", "task");

  const editHandler = useCallback(async () => {
    onClose();
    dispatch(openDrawerForTaskByIdThunk(taskId));
  }, [dispatch, onClose, taskId]);

  return (
    <MenuItem dense disabled={!canEditTask} onClick={editHandler}>
      <ListItemIcon>
        <EditOutlinedIcon fontSize="small" />
      </ListItemIcon>
      <ListItemText>Edit</ListItemText>
    </MenuItem>
  );
};
