import { FC } from "react";
import { ColumnResizeMode, HeaderGroup, Table } from "@tanstack/react-table";
import { TableCell, TableRow, Theme } from "@mui/material";
import { SxProps } from "@mui/system";
import { TableHeaderCell } from "../TableHeaderCell";

interface TableHeaderRowNotVirtualizedProps {
  table: Table<any>;
  columnsFilterEnabled: boolean;
  rowDnDEnabled: boolean;
  columnsDnDEnabled: boolean;
  columnsSortEnabled: boolean;
  stickyColumnsEnabled: boolean;
  styles?: SxProps<Theme>;
  columnResizeMode?: ColumnResizeMode;
  rerender(): void;
  headerGroup: HeaderGroup<any>;
}

export const TableHeaderRowNotVirtualized: FC<
  TableHeaderRowNotVirtualizedProps
> = ({
  table,
  columnsFilterEnabled,
  columnsDnDEnabled,
  columnsSortEnabled,
  columnResizeMode,
  rowDnDEnabled,
  styles,
  stickyColumnsEnabled,
  rerender,
  headerGroup,
}) => {
  return (
    <TableRow id={headerGroup.id} sx={styles}>
      {rowDnDEnabled && <TableCell />}

      {headerGroup.headers.map((header) => {
        return (
          <TableHeaderCell
            key={header.id}
            header={header}
            rerender={rerender}
            resizeMode={columnResizeMode}
            dndEnabled={columnsDnDEnabled}
            sortEnabled={columnsSortEnabled}
            filtersEnabled={columnsFilterEnabled}
            setColumnOrder={table.setColumnOrder}
            columnOrder={table.getState().columnOrder}
            stickyColumnsEnabled={stickyColumnsEnabled}
            resizingDeltaOffset={table.getState().columnSizingInfo.deltaOffset}
          />
        );
      })}
    </TableRow>
  );
};
