import { RootState } from "../../../store";
import { getCurrentProvidersByProviderBillingStatusDataSelector } from "../../../common/selectors/billing-status/getCurrentProvidersByProviderBillingStatusDataSelector";
import { isBillingStatusesGettingFulfilledSelector } from "../../../common/selectors/billing-status/isBillingStatusesGettingFulfilledSelector";

export const awsBillingStatusNotAvailableSelector = (
  state: RootState,
): boolean => {
  const billingStatuses =
    getCurrentProvidersByProviderBillingStatusDataSelector(
      state,
      "aws",
    )?.billing_account_statuses;

  const isBillingStatusesGettingFulfilled =
    isBillingStatusesGettingFulfilledSelector(state);

  if (!isBillingStatusesGettingFulfilled) {
    return false;
  }

  return !!billingStatuses?.some(
    ({ status }) =>
      status === "missing_setup" || status === "waiting_for_cloud_provider",
  );
};
