import { ChangeEvent, FC } from "react";
import { Grid } from "@mui/material";
import { SingleSelectField } from "./select/SingleSelectField";
import { MultipleSelectField } from "./select/MultipleSelectField";
import { DatePickerField } from "./DatePickerField";
import { InputField } from "./InputField";
import { NestedSelectField } from "./select/NestedSelectField";
import { MultipleSelectWithOptionsField } from "./select/MultipleSelectWithOptionsField";
import { AutocompleteField } from "./select/AutocompleteField";
import { MultipleAutocompleteField } from "./select/MultipleAutocompleteField";
import { ParagraphField } from "./ParagraphField";
import { IssueURL } from "./IssueURL";
import {
  CustomFieldValueType,
  JiraCustomFieldType,
  JiraOptionWithChildren,
} from "../../../utils/types/types";
import { getJiraCustomFiledType } from "../../../utils/helpers/get-jira-custom-filed-type";

interface FieldComponentByFieldTypeProps {
  error?: string;
  integrationId: string;
  value: CustomFieldValueType;
  customField: JiraCustomFieldType;
  onChange(event: ChangeEvent<any>): void;
  setFieldValue(key: string, value: any): void;
}

export const FieldComponentByFieldType: FC<FieldComponentByFieldTypeProps> = ({
  customField,
  onChange,
  value,
  setFieldValue,
  error,
  integrationId,
}) => {
  const {
    isDate,
    isRichText,
    isIssueURL,
    isTextField,
    isNumberField,
    isSingleSelect,
    isNestedSelect,
    isAutocomplete,
    isMultipleSelect,
    isMultipleAutocomplete,
    isMultipleSelectWithOptions,
  } = getJiraCustomFiledType(customField);

  if (isMultipleAutocomplete) {
    return (
      <MultipleAutocompleteField
        error={error}
        optionsUrl={customField.auto_complete_url!}
        integrationId={integrationId}
        label={customField.name}
        setFieldValue={setFieldValue}
        value={value as { id: string }[]}
        name={`body.fields.${customField.key}`}
      />
    );
  }

  if (isAutocomplete) {
    return (
      <AutocompleteField
        error={error}
        optionsUrl={customField.auto_complete_url!}
        integrationId={integrationId}
        label={customField.name}
        setFieldValue={setFieldValue}
        value={value as { id: string }}
        name={`body.fields.${customField.key}`}
      />
    );
  }

  if (isNestedSelect) {
    return (
      <NestedSelectField
        error={error}
        value={value}
        label={customField.name}
        setFieldValue={setFieldValue}
        name={`body.fields.${customField.key}`}
        options={customField.options as JiraOptionWithChildren[]}
      />
    );
  }

  if (isSingleSelect) {
    return (
      <SingleSelectField
        error={error}
        label={customField.name}
        options={customField.options}
        setFieldValue={setFieldValue}
        value={value as { id: string }}
        name={`body.fields.${customField.key}`}
      />
    );
  }

  if (isMultipleSelectWithOptions) {
    return (
      <MultipleSelectWithOptionsField
        error={error}
        label={customField.name}
        setFieldValue={setFieldValue}
        options={customField.options}
        value={value as { id: string }[]}
        name={`body.fields.${customField.key}`}
      />
    );
  }

  if (isMultipleSelect) {
    return (
      <MultipleSelectField
        error={error}
        onChange={onChange}
        label={customField.name}
        value={value as string[]}
        options={customField.options}
        name={`body.fields.${customField.key}`}
      />
    );
  }

  if (isDate) {
    return (
      <DatePickerField
        error={error}
        value={value as string}
        label={customField.name}
        setFieldValue={setFieldValue}
        name={`body.fields.${customField.key}`}
        type={customField.schema.type as "datetime" | "date"}
      />
    );
  }

  if (isIssueURL) {
    return (
      <IssueURL
        error={error}
        name={customField.key}
        label={customField.name}
        setFieldValue={setFieldValue}
        value={value as { id: string }}
      />
    );
  }

  if (isRichText) {
    return (
      <ParagraphField
        error={error}
        setFieldValue={setFieldValue}
        name={`body.fields.${customField.key}`}
      />
    );
  }

  if (isTextField) {
    return (
      <InputField
        error={error}
        type="text"
        onChange={onChange}
        value={value as string}
        label={customField.name}
        name={`body.fields.${customField.key}`}
      />
    );
  }

  if (isNumberField) {
    return (
      <InputField
        error={error}
        type="number"
        onChange={onChange}
        value={value as number}
        label={customField.name}
        name={`body.fields.${customField.key}`}
      />
    );
  }

  return (
    <Grid item sm={10}>
      Unsupported Field
    </Grid>
  );
};
