import { Row } from "@tanstack/react-table";
import { isHierarchyFolder } from "../../../../../../store/navigation/utils/helpers/isHierarchyFolder";
import { DashboardMixedVisibilitiesItemType } from "../../../../dashboard-all-items/components/table/utils/types/types";
import { ReMixedVisibilitiesItemType } from "../../../../resource-explorer-all-views/components/table/utils/types/types";

export const hierarchyAllItemsNameFilter = <
  T extends ReMixedVisibilitiesItemType | DashboardMixedVisibilitiesItemType,
>(
  row: Row<T>,
  _: string,
  keyword: string,
) => {
  const data = row.original;
  const filterValue = keyword.toLowerCase().trim();
  const name = data.name.toLowerCase().trim();

  if (isHierarchyFolder(data)) {
    const childViewsNames = data.items?.reduce((cur, item) => {
      return `${cur} ${item.name}`;
    }, "");

    return `${name} ${childViewsNames?.toLowerCase().trim()}`.includes(
      filterValue,
    );
  }

  return name.includes(filterValue);
};
