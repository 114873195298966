import { FC } from "react";
import { Typography } from "@mui/material";

export const EmailIntegrationGracePeriodSubject: FC = () => {
  return (
    <Typography variant="body1">
      Subject:{" "}
      <Typography component="span" fontWeight="medium">
        Actions will be performed on {`{{action date}}`} by{" "}
        {`{{workflow name}}`}
      </Typography>
    </Typography>
  );
};
