import { MouseEvent, useState } from "react";

export type UseMenuHookOpenMenu = (
  event:
    | MouseEvent<HTMLButtonElement | HTMLDivElement>
    | { currentTarget: HTMLButtonElement | HTMLDivElement | HTMLSpanElement }
) => void;

export function useMenuHook() {
  const [anchor, setAnchor] = useState<null | HTMLElement>(null);
  const open = Boolean(anchor);

  const openMenu: UseMenuHookOpenMenu = (event) => {
    setAnchor(event.currentTarget);
  };
  const closeMenu = () => {
    setAnchor(null);
  };

  return {
    anchor,
    open,
    openMenu,
    closeMenu,
  };
}
