import { commitmentsPayloadDataSelector } from "./commitmentsPayloadDataSelector";
import { RootState } from "../../../../store";
import { GetUsersMeOrganisationsCurrentSavingsPlansApiArg } from "../../../../../services/cloudchipr.api";

export const commitmentsDataGridPayloadDataSelector = (
  state: RootState,
): GetUsersMeOrganisationsCurrentSavingsPlansApiArg => {
  const { dataGranularity, ...payload } = commitmentsPayloadDataSelector(state);

  return {
    ...payload,
    loadTotals: false,
    loadChartData: false,
  };
};
