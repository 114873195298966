import { commitmentsDetailsDrawerTargetIdSelector } from "../../../common/details-drawer/commitmentsDetailsDrawerTargetIdSelector";
import { RootState } from "../../../../../store";
import { ReservationsCoverageDataWithId } from "../../../../../../components/pages/commtiments/common/utils/types";
import { reservationsCoverageDataSelector } from "../../data/reservationsCoverageDataSelector";

export const reservationSelectedCoverageDataSelector = (
  state: RootState,
): ReservationsCoverageDataWithId | undefined => {
  const coverageData = reservationsCoverageDataSelector(state);
  const id = commitmentsDetailsDrawerTargetIdSelector(state);

  return coverageData?.find((data) => data.id === id);
};
