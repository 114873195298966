import { FC } from "react";
import { Stack } from "@mui/material";
import { UnauthorizedLargestCostChangesWidget } from "./UnauthorizedLargestCostChangesWidget";
import { DashboardWidgetLargestCostChanges } from "../../../../../services/cloudchipr.api";
import { UnauthorizedWidgetHookArguments } from "../../utils/types/types";
import { useUnauthorizedLargestCostChangesHook } from "../../utils/hooks/useUnauthorizedLargestCostChangesHook";
import { UnauthorizedWidgetHeader } from "../UnauthorizedWidgetHeader";
import { WidgetNumeralView } from "../../../../pages/dashboard/components/adding-widget/widget-create/widget-setups/common/view-type/WidgetNumeralView";
import { largestCostChangesDefaultData } from "../../../../../store/dashboards/utils/constants/largestCostChangesDefaultData";

interface UnauthorizedLargestCostChangesWidgetWrapperProps
  extends DashboardWidgetLargestCostChanges,
    Omit<UnauthorizedWidgetHookArguments, "widgetId"> {
  onWidgetMount?(): void;
}

export const UnauthorizedLargestCostChangesWidgetWrapper: FC<
  UnauthorizedLargestCostChangesWidgetWrapperProps
> = ({
  id,
  organisationId,
  categoryId,
  overrideQueryDates,
  name,
  dates,
  grouping,
  ...props
}) => {
  const { data, isFetching, error, isError, isUninitialized } =
    useUnauthorizedLargestCostChangesHook({
      widgetId: id,
      categoryId,
      organisationId,
      overrideQueryDates,
    });

  if (isFetching || isUninitialized) {
    return (
      <Stack flexGrow={1} overflow="auto">
        <UnauthorizedWidgetHeader
          name={name}
          grouping={grouping}
          dates={dates}
        />
        <WidgetNumeralView
          loading
          skeletonCount={largestCostChangesDefaultData.itemsCount}
        ></WidgetNumeralView>
      </Stack>
    );
  }

  return (
    <UnauthorizedLargestCostChangesWidget
      {...props}
      data={data}
      isError={isError}
      error={error}
      id={id}
      name={name}
      grouping={grouping}
      dates={dates}
    />
  );
};
