import { FC } from "react";
import { Typography } from "@mui/material";

export const SlackIntegrationActionExecutedDescription: FC = () => {
  return (
    <Typography variant="body2" fontStyle="italic">
      Cloudchipr dispatches a message once it executes an action, but it doesn't
      provide updates on the action's status. The action statuses can be viewed
      in{" "}
      <Typography
        sx={{ textDecoration: "underline" }}
        component="span"
        variant="body2"
        fontStyle="italic"
      >
        Execution Logs
      </Typography>
      . Please be aware that some actions may take time to complete.
    </Typography>
  );
};
