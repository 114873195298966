import { FC, useCallback, useMemo } from "react";
import { Row } from "@tanstack/react-table";
import { TableRowProps } from "@mui/material";
import { useDidMount, usePreviousDifferent } from "rooks";
import {
  StylesProps,
  ToolbarConfig,
} from "../../../../../../../../../storybook/data-grid/utils/types/prop-types";
import { DataGrid } from "../../../../../../../../../storybook/data-grid/DataGrid";
import { getResourceExplorerDataGridColumns } from "../columns/setups/getResourceExplorerDataGridColumns";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../../store/hooks";
import { resourceExplorerDataGridDataSelector } from "../../../../../../../../../store/resource-explorer/selectors/current-resource-explorer/data-grid/resourceExplorerDataGridDataSelector";
import { resourceExplorerGroupingSelector } from "../../../../../../../../../store/resource-explorer/selectors/current-resource-explorer/data/resourceExplorerGroupingSelector";
import { getResourceExplorerDataThunk } from "../../../../../../../../../store/resource-explorer/thunks/widgets/getResourceExplorerDataThunk";
import { setQuarterEndForecastVisibility } from "../../../../../../../../../store/resource-explorer/resourceExplorerSlice";
import { resourceExplorerDataGridVisibilitiesSelector } from "../../../../../../../../../store/resource-explorer/selectors/current-resource-explorer/resourceExplorerDataGridVisibilitiesSelector";
import { setResourceExplorerDataGridSavingData } from "../../../../utils/helpers/resourceExplorerDataGridSavedDataGetterSetter/setResourceExplorerDataGridSavingData";
import { resourceExplorerIdSelector } from "../../../../../../../../../store/resource-explorer/selectors/current-resource-explorer/resourceExplorerIdSelector";

interface ResourceExplorerTotalViewDataGridProps extends StylesProps {
  toolbar: ToolbarConfig;
  getRowProps?: (row: Row<any>) => TableRowProps;
}

export const ResourceExplorerTotalViewDataGrid: FC<
  ResourceExplorerTotalViewDataGridProps
> = ({ toolbar, getRowProps, styles }) => {
  const dispatch = useAppDispatch();
  const gridData = useAppSelector(resourceExplorerDataGridDataSelector);
  const groupBy = useAppSelector(resourceExplorerGroupingSelector);
  const prevData = usePreviousDifferent(gridData);
  const viewId = useAppSelector(resourceExplorerIdSelector);

  const initialVisibility = useAppSelector(
    resourceExplorerDataGridVisibilitiesSelector,
  );

  const columns = useMemo(() => {
    return getResourceExplorerDataGridColumns(groupBy);
  }, [groupBy]);

  const data = gridData ?? prevData;

  const columnsVisibilityChangeHandler = useCallback(
    (data: any) => {
      setResourceExplorerDataGridSavingData(viewId, {
        visibilities: data,
      });
      dispatch(setQuarterEndForecastVisibility(data.quarterly_forecast));

      if (data.quarterly_forecast) {
        dispatch(getResourceExplorerDataThunk());
      }
    },
    [dispatch, viewId],
  );

  useDidMount(() => {
    if (initialVisibility?.["quarterly_forecast"]) {
      dispatch(
        setQuarterEndForecastVisibility(
          initialVisibility?.["quarterly_forecast"],
        ),
      );
    }
  });

  if (!data) {
    return null;
  }

  return (
    <DataGrid
      globalFilter
      data={data}
      columnVisibility={{
        initialVisibility: initialVisibility,
        onColumnsVisibilityChange: columnsVisibilityChangeHandler,
      }}
      columns={columns}
      toolbar={toolbar}
      styles={styles}
      columnResizeMode="onEnd"
      columnSorting={sortingConfig}
      getRowProps={getRowProps}
      enableRowsVirtualization={data.length > 30}
    />
  );
};

const sortingConfig = {
  initialSort: [{ id: "total_cost", desc: true }],
};
