import { FC, memo, useCallback, useState } from "react";
import { Button, Stack } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { LoadingButton } from "@mui/lab";
import { useAppAbility } from "../../../../../../../../services/permissions";
import { ConfirmationDialog } from "../../../../../../../common/modals/ConfirmationDialog";

interface NextPreviousProps {
  submitText?: string;
  hideCancelButton?: boolean;
  isNextDisabled?: boolean;
  isLoading?: boolean;
  isDirty?: boolean;
  onNext(): void;
  onPrevious?(): void;
}

export const NextPrevious: FC<NextPreviousProps> = memo(
  ({
    isNextDisabled,
    onNext,
    onPrevious,
    submitText,
    isLoading,
    isDirty,
    hideCancelButton,
  }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const { cannot } = useAppAbility();
    const canNotCreateSchedule = cannot("create", "schedule");
    const canNotEditSchedule = cannot("edit", "schedule");
    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);

    const handleDiscardClick = useCallback(() => {
      const pathname = location.pathname.split("/");

      navigate(`/${pathname[1]}/${pathname[2]}`);
    }, [navigate, location]);

    const closeConfirmDialogHandler = useCallback(() => {
      setConfirmDialogOpen(false);
    }, []);

    const handleCancelClick = useCallback(() => {
      if (!isDirty) {
        handleDiscardClick();
        return;
      }

      setConfirmDialogOpen(true);
    }, [isDirty, handleDiscardClick]);

    return (
      <Stack
        mt={8}
        width="100%"
        direction="row"
        justifyContent={hideCancelButton ? "end" : "space-between"}
      >
        {!hideCancelButton && (
          <Button color="tertiary" onClick={handleCancelClick}>
            Cancel
          </Button>
        )}

        <div>
          <Button
            sx={{ mr: 2 }}
            variant="outlined"
            color="primary"
            onClick={onPrevious}
            disabled={!onPrevious}
          >
            Previous
          </Button>
          {submitText ? (
            <LoadingButton
              type="button"
              disabled={
                isNextDisabled || canNotEditSchedule || canNotCreateSchedule
              }
              loading={isLoading}
              variant="contained"
              onClick={onNext}
            >
              {submitText}
            </LoadingButton>
          ) : (
            <Button
              variant="contained"
              color="primary"
              disabled={
                isNextDisabled || canNotEditSchedule || canNotCreateSchedule
              }
              onClick={onNext}
            >
              Next
            </Button>
          )}
        </div>

        <ConfirmationDialog
          title="Discard Unsaved Changes?"
          dialogContent="The changes you made will not be saved."
          open={confirmDialogOpen}
          CancelButtonProps={{
            onClick: closeConfirmDialogHandler,
          }}
          ConfirmButtonProps={{
            children: "Discard",
            onClick: handleDiscardClick,
          }}
          onClose={closeConfirmDialogHandler}
        />
      </Stack>
    );
  },
);
