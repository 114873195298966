import { FC, ChangeEvent, MouseEvent } from "react";
import { Checkbox, CheckboxProps, Theme } from "@mui/material";
import { SxProps } from "@mui/system";

export type IndeterminateCheckboxOnChange = (
  e: MouseEvent<HTMLInputElement> | ChangeEvent<HTMLInputElement>
) => void;

interface IndeterminateCheckboxProps {
  checked: boolean;
  indeterminate: boolean;
  disabled?: boolean;
  size?: CheckboxProps["size"];
  onChange?: IndeterminateCheckboxOnChange;
  sx?: SxProps<Theme>;
}

export const IndeterminateCheckbox: FC<IndeterminateCheckboxProps> = ({
  indeterminate,
  size = "small",
  sx,
  ...rest
}) => {
  return (
    <Checkbox
      size={size}
      indeterminate={!rest.checked && indeterminate}
      sx={{ py: 0, ...sx }}
      {...rest}
      disableRipple
    />
  );
};
