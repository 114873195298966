import { FC } from "react";
import { Box } from "@mui/material";
import { AutomationSectionCard } from "../../../../common/components/form/helper-components/AutomationSectionCard";
import { SchedulePreviewResources } from "../../../../common/components/resources-preview-old/preview/SchedulePreviewResources";
import { useAppSelector } from "../../../../../../../store/hooks";
import { automationIdSelector } from "../../../../../../../store/automations/selectros/common/automationIdSelector";
import { workflowActionSelector } from "../../../../../../../store/automations/selectros/workflow/workflowActionSelector";
import { automationNameSelector } from "../../../../../../../store/automations/selectros/common/fields/automationNameSelector";
import { automationResourcesSelector } from "../../../../../../../store/automations/selectros/common/fields/automationResourcesSelector";
import { automationAccountIdsSelector } from "../../../../../../../store/automations/selectros/common/fields/automationAccountIdsSelector";
import { workflowGracePeriodSelector } from "../../../../../../../store/automations/selectros/workflow/workflowGracePeriodSelector";
import { automationFrequencySelector } from "../../../../../../../store/automations/selectros/common/automationFrequencySelector";
import { scheduleNextRunDateTimeSelector } from "../../../../../../../store/automations/selectros/workflow/next-runs/old/scheduleNextRunDateTimeSelector";
import { getCronFrequency } from "../../../../../../../store/automations/utils/helpers/cron/getCronFrequency";
import { useSchedulePreviewDatesInfoHook } from "../../../../common/utils/hooks/useSchedulePreviewDatesInfo.hook";
import { useWorkflowsNextRunDates } from "../../../../common/utils/hooks/useWorkflowsNextRunDates";
import { automationTimeZoneSelector } from "../../../../../../../store/automations/selectros/common/fields/automationTimeZoneSelector";
import { automationStartDateSelector } from "../../../../../../../store/automations/selectros/common/fields/automationStartDateSelector";
import { generateCronForPayload } from "../../../../../../../store/automations/utils/helpers/cron/generateCronForPayload";
import { workflowSchedulerSelector } from "../../../../../../../store/automations/selectros/workflow/workflowSchedulerSelector";
import { automationRegionsSelector } from "../../../../../../../store/automations/selectros/common/fields/automationRegionsSelector";
import { automationStatusSelector } from "../../../../../../../store/automations/selectros/common/automationStatusSelector";

export const WorkflowResourcesPreview: FC = () => {
  const automationId = useAppSelector(automationIdSelector);
  const automationName = useAppSelector(automationNameSelector);
  const filter = useAppSelector(automationResourcesSelector);
  const accountIds = useAppSelector(automationAccountIdsSelector);
  const timeZone = useAppSelector(automationTimeZoneSelector);
  const startDate = useAppSelector(automationStartDateSelector);
  const automationFrequency = useAppSelector(automationFrequencySelector);
  const scheduler = useAppSelector(workflowSchedulerSelector);
  const action = useAppSelector(workflowActionSelector);
  const regions = useAppSelector(automationRegionsSelector);
  const gracePeriod = useAppSelector(workflowGracePeriodSelector);
  const status = useAppSelector(automationStatusSelector);

  const cron = generateCronForPayload(
    automationFrequency,
    scheduler?.runTime ?? null,
    scheduler?.repeatValue,
    scheduler?.weekDays,
  );

  const requestedNextRunDate = useWorkflowsNextRunDates({
    cron,
    timeZone,
    startDate: startDate ?? "",
  });
  const nextRunDateTime = useAppSelector((state) =>
    scheduleNextRunDateTimeSelector(state, getCronFrequency(cron)),
  );

  const { nextRuns, frequency } = useSchedulePreviewDatesInfoHook({
    cron,
    timeZone,
    weeklyInterval: scheduler?.repeatValue,
    nextRun: nextRunDateTime ?? requestedNextRunDate,
  });

  return (
    <AutomationSectionCard title="Select Services" icon="services" preview>
      <Box pt={2}>
        <SchedulePreviewResources
          hideTitle
          regions={regions}
          filters={filter}
          nextRuns={nextRuns}
          frequency={frequency}
          name={automationName}
          accountIds={accountIds}
          status={status ?? "active"}
          scheduleId={automationId ?? ""}
          showAction={action?.includes("clean")}
          gracePeriodValue={gracePeriod?.period}
          scheduleAction={action ?? null}
        />
      </Box>
    </AutomationSectionCard>
  );
};
