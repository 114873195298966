import { costBreakdownWidgetSetupTotalDataWithIdsSelector } from "./costBreakdownWidgetSetupTotalDataWithIdsSelector";
import { RootState } from "../../../../../store";

export const costBreakdownWidgetSetupTotalDataCountSelector = (
  state: RootState,
): number | undefined => {
  const data = costBreakdownWidgetSetupTotalDataWithIdsSelector(state);

  return data?.length;
};
