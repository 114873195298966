import { Typography } from "@mui/material";
import { ExecutionLogResourceLabel } from "./cells/ExecutionLogResourceLabel";
import { ResourceType } from "../../../../../../services/cloudchipr.api";
import { ColumnSetupType } from "../../../../../../storybook/data-grid/utils/types/types";
import { ExecutionLogsDataGridResourcesType } from "../../../utils/types/types";
import { getResourceTypeName } from "../../../../../../utils/helpers/resources/getResourceTypeName";
import { money } from "../../../../../../utils/numeral/money";
import { AccountInfoData } from "../../../../../common/AccountInfoData";
import { TagCell } from "../../../../../common/data-grid-cells/tag-cell/TagCell";
import { getResourceExplorerResourceActionLabel } from "../../../utils/helpers/getResourceExplorerResourceActionLabel";
import {
  accountSortingFn,
  resourceSortingFn,
} from "../../../utils/helpers/sortingFunctions";
import { TypographyWithTooltip } from "../../../../../common/TypographyWithTooltip";

export const executionLogsSubGridColumns: ColumnSetupType<ExecutionLogsDataGridResourcesType>[] =
  [
    {
      accessorKey: "resourceType",
      header: "Service Name",
      size: 170,
      meta: { alwaysShowSortIcon: true },
      cell: (cell) => {
        const resourceType = cell.getValue() as ResourceType;
        const firstInGroup = cell.row.original.firstInGroup;

        if (!firstInGroup) {
          return null;
        }

        return (
          <TypographyWithTooltip
            variant="body2"
            fontWeight="bold"
            title={getResourceTypeName(resourceType, {
              type: "long",
            })}
          />
        );
      },
    },
    {
      accessorKey: "action",
      header: "Action",
      minSize: 100,
      meta: { alwaysShowSortIcon: true },
      cell: (cell) => {
        const action = cell.getValue() as string;
        const resourceType = cell.row.original.resourceType;
        const snapshot = cell.row.original.snapshot;

        return (
          <Typography variant="body2" sx={{ textTransform: "capitalize" }}>
            {getResourceExplorerResourceActionLabel(
              resourceType,
              action,
              snapshot,
            )}
          </Typography>
        );
      },
    },
    {
      accessorKey: "price_per_month",
      header: () => (
        <Typography
          align="right"
          flexGrow={1}
          variant="body2"
          fontWeight="medium"
        >
          Monthly Price
        </Typography>
      ),
      minSize: 100,
      meta: { alwaysShowSortIcon: true },
      cell: (cell) => {
        const status = cell.row.original.succeded;
        const price =
          cell.getValue() as ExecutionLogsDataGridResourcesType["price_per_month"];

        return (
          <Typography variant="body2" fontWeight="medium" align="right" pr={3}>
            {status ? money(price) : "-"}
          </Typography>
        );
      },
    },
    {
      accessorKey: "name",
      header: "Resources",
      minSize: 220,
      sortingFn: resourceSortingFn,
      meta: { alwaysShowSortIcon: true },
      cell: (cell) => {
        const name = cell.row.original.name;
        const id = cell.row.original.provider_unique_identifier;

        return (
          <ExecutionLogResourceLabel
            label={name || id}
            state={cell.row.original.state}
          />
        );
      },
    },
    {
      accessorKey: "account",
      header: "Account",
      minSize: 200,
      sortingFn: accountSortingFn,
      meta: { alwaysShowSortIcon: true },
      cell: (cell) => {
        const account =
          cell.getValue() as ExecutionLogsDataGridResourcesType["account"];

        return (
          <AccountInfoData
            chipView
            maxWidth={150}
            accountId={
              account.provider_account_name ||
              account.provider_account_id ||
              account.id
            }
            provider={account.provider}
          />
        );
      },
    },
    {
      accessorKey: "region",
      header: "Region",
      enableSorting: false,
      minSize: 100,
      cell: (cell) => {
        const region =
          cell.getValue() as ExecutionLogsDataGridResourcesType["region"];

        return <Typography variant="body2">{region}</Typography>;
      },
    },
    {
      accessorKey: "tags",
      header: "Tags",
      enableSorting: false,
      minSize: 200,
      cell: (cell) => {
        const tags =
          cell.getValue() as ExecutionLogsDataGridResourcesType["tags"];

        return tags?.length ? (
          <TagCell tags={tags} widthToEllipsis={120} />
        ) : (
          "-"
        );
      },
    },
    {
      accessorKey: "log",
      header: "Log",
      meta: { alwaysShowSortIcon: true },
      minSize: 300,
      cell: (cell) => {
        const log =
          cell.getValue() as ExecutionLogsDataGridResourcesType["log"];

        return (
          <Typography
            variant="body2"
            sx={{
              whiteSpace: "normal",
              wordWrap: "break-word",
            }}
          >
            {log}
          </Typography>
        );
      },
    },
  ];
