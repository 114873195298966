import { FC } from "react";
import { Stack } from "@mui/material";
import { ExecutionLogsDateRangeSelector } from "./ExecutionLogsDateRangeSelector";
import { ExecutionLogsSearch } from "./ExecutionLogsSearch";
import { ExecutionLogsShowAll } from "./ExecutionLogsShowAll";
import { ExecutionLogTotal } from "../ExecutionLogTotal";

export const ExecutionLogsToolbar: FC = () => {
  return (
    <Stack mb={2} spacing={2} alignItems="start">
      <Stack
        width="100%"
        direction="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <ExecutionLogsDateRangeSelector />
        <ExecutionLogsSearch />
      </Stack>

      <Stack spacing={1} direction="row" alignItems="center">
        <ExecutionLogTotal />
        <ExecutionLogsShowAll />
      </Stack>
    </Stack>
  );
};
