import { FC, Fragment } from "react";
import { Box, Chip, Popover, Stack, Typography } from "@mui/material";
import { ProviderType } from "../../../../../../services/cloudchipr.api";
import { TypographyWithTooltip } from "../../../../../common/TypographyWithTooltip";
import { useMenuHook } from "../../../../../../utils/hooks/useMenu.hook";
import { useAppSelector } from "../../../../../../store/hooks";
import { providersRegionsSelector } from "../../../../../../store/automations/selectros/common/helpers-data/regions/providersRegionsSelector";

interface AutomationRegionsPreviewProps {
  regions: string[];
  provider: ProviderType;
}

export const AutomationRegionsPreview: FC<AutomationRegionsPreviewProps> = ({
  regions,
  provider,
}) => {
  const { anchor, open, openMenu, closeMenu } = useMenuHook();

  const allRegions = useAppSelector(providersRegionsSelector);
  const regionsData = allRegions[provider];

  const firstPart = regions.slice(0, 9);
  const secondPart = regions.slice(9);

  const isAllSelected = regionsData?.length === regions.length;

  if (isAllSelected) {
    return (
      <Chip
        variant="outlined"
        size="small"
        label={
          <TypographyWithTooltip
            title="All Regions"
            fontSize="inherit"
            maxWidth={110}
          />
        }
      />
    );
  }

  return (
    <Stack direction="row" gap={1} flexWrap="wrap">
      {firstPart.map((region) => {
        return (
          <Chip
            key={region}
            variant="outlined"
            size="small"
            label={
              <TypographyWithTooltip
                title={region}
                fontSize="inherit"
                maxWidth={110}
              />
            }
          />
        );
      })}

      {!!secondPart?.length && (
        <Fragment>
          <Chip
            onClick={openMenu}
            variant="outlined"
            size="small"
            label={`+ ${secondPart.length}`}
          />

          <Popover
            open={open}
            anchorEl={anchor}
            onClose={closeMenu}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            PaperProps={{
              style: { maxHeight: 400, minWidth: 200, maxWidth: 400 },
            }}
          >
            <Box top={0} position="sticky" bgcolor="white" p={2}>
              <Typography variant="body2" color="text.secondary">
                Selected Regions
              </Typography>
            </Box>

            <Stack spacing={2} p={2} pt={0}>
              {secondPart.map((region) => {
                return <Typography key={region}>{region}</Typography>;
              })}
            </Stack>
          </Popover>
        </Fragment>
      )}
    </Stack>
  );
};
