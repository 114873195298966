import { FC, useCallback } from "react";
import { Typography } from "@mui/material";
import { enqueueSnackbar } from "notistack";
import { DeleteConfirmationDialog } from "../../../../../../common/modals/DeleteConfirmationDialog";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../store/hooks";
import { dimensionsLoadingSelector } from "../../../../../../../store/dimensions/selectors/dimensions-data/dimensionsLoadingSelector";
import { deleteCategoryByIdThunk } from "../../../../../../../store/dimensions/thunks/categories/deleteCategoryByIdThunk";
import { categoryDeleteLoadingSelector } from "../../../../../../../store/dimensions/selectors/options/categories/categoryDeleteLoadingSelector";
import { getDimensionsThunk } from "../../../../../../../store/dimensions/thunks/dimensions/getDimensionsThunk";

interface DeleteCategoryConfirmationDialogProps {
  open: boolean;
  dimensionId: string;
  categoryId: string;
  name: string;
  onClose(): void;
}

export const DeleteCategoryConfirmationDialog: FC<
  DeleteCategoryConfirmationDialogProps
> = ({ dimensionId, name, onClose, open, categoryId }) => {
  const dispatch = useAppDispatch();
  const deleteLoading = useAppSelector(categoryDeleteLoadingSelector);
  const getLoading = useAppSelector(dimensionsLoadingSelector);
  const loading = deleteLoading || getLoading;

  const deleteHandler = useCallback(async () => {
    await dispatch(deleteCategoryByIdThunk({ dimensionId, categoryId }));
    await dispatch(getDimensionsThunk(true));

    enqueueSnackbar("Category successfully deleted.", {
      variant: "snackbarAlert",
      AlertSnackBarProps: { severity: "success" },
    });

    onClose();
  }, [dispatch, dimensionId, onClose, categoryId]);

  return (
    <DeleteConfirmationDialog
      open={open}
      onClose={onClose}
      onDelete={deleteHandler}
      loading={loading}
      title="Delete Category?"
      content={
        <Typography color="text.secondary" variant="subtitle1">
          Are you sure you want to delete{" "}
          <Typography component="span" fontWeight="bold">
            {` ${name} `}
          </Typography>{" "}
          category?
        </Typography>
      }
    />
  );
};
