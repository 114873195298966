import { FC, memo, useCallback } from "react";
import { Divider, Grid, Stack, TextField } from "@mui/material";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useDidMount } from "rooks";
import { SchedulesProviders } from "./providers/SchedulesProviders";
import { OffHoursAccounts } from "./OffHoursAccounts";
import { OffHoursRegions } from "./OffHoursRegions";
import { NextPrevious } from "../tools/NextPrevious";
import {
  ProviderType,
  useGetProvidersByProviderRegionsQuery,
} from "../../../../../../../../services/cloudchipr.api";
import { useAppAbility } from "../../../../../../../../services/permissions";
import { WorkflowFormData } from "../../../../../../automations/common/utils/types/types";
import { SchedulesActionType } from "../../../../../common/utils/types/types";
import { AutomationType } from "../../../../../../../../store/automations/utils/types/common";

export type ScheduleEnvironmentType = Pick<
  WorkflowFormData,
  "id" | "name" | "provider" | "regions" | "account_ids"
>;

const validationSchema = Yup.object({
  name: Yup.string().required("Name is required"),
  account_ids: Yup.array()
    .of(Yup.string())
    .min(1, "At least 1 account must be selected"),
  regions: Yup.array()
    .of(Yup.string())
    .min(1, "At least 1 region must be selected"),
});

const initialValues: ScheduleEnvironmentType = {
  id: "",
  name: "",
  regions: [],
  account_ids: [],
  provider: "" as ProviderType,
};

interface ScheduleEnvironmentProps {
  action: SchedulesActionType;
  hideCancelButton?: boolean;
  parentScheduleType: AutomationType;
  providerChangeDisabled?: boolean;
  onNext(data: ScheduleEnvironmentType): void;
  onFieldChange(key: string, value: any): void;
}

export const OffHoursEnvironment: FC<
  ScheduleEnvironmentProps & ScheduleEnvironmentType
> = memo(
  ({
    id,
    provider,
    account_ids,
    regions,
    name,
    onNext,
    onFieldChange,
    parentScheduleType,
    providerChangeDisabled,
    hideCancelButton,
    action,
  }) => {
    const { data: awsRegions } = useGetProvidersByProviderRegionsQuery({
      provider: "aws",
    });
    const { data: gcpRegions } = useGetProvidersByProviderRegionsQuery({
      provider: "gcp",
    });
    const { data: azureRegions } = useGetProvidersByProviderRegionsQuery({
      provider: "azure",
    });

    const { cannot } = useAppAbility();
    const canNotEditSchedule = cannot("edit", "schedule");

    const formik = useFormik({
      initialValues,
      validationSchema,
      onSubmit: (values) => {
        onNext(values);
      },
    });

    const {
      values,
      touched,
      submitForm,
      setFieldValue,
      handleChange,
      errors,
      resetForm,
      isValid,
      dirty,
      setValues,
    } = formik;

    const inputBlurHandler = useCallback(() => {
      onFieldChange("name", values.name);
    }, [values.name, onFieldChange]);

    const accountIdChangeHandler = useCallback(
      (ids: string[]) => {
        setFieldValue("account_ids", ids);
        onFieldChange("account_ids", ids);

        if (parentScheduleType !== "offHours") {
          onFieldChange("action", action?.includes("notify") ? "notify" : "");
        }
      },
      [onFieldChange, setFieldValue, parentScheduleType, action],
    );

    const regionsChangeHandler = useCallback(
      (regions: string[]) => {
        setFieldValue("regions", regions);
        onFieldChange("regions", regions);
      },
      [onFieldChange, setFieldValue],
    );

    const providerChangeHandler = useCallback(
      (provider: ProviderType) => {
        let regions;

        switch (provider) {
          case "aws":
            regions = awsRegions;
            break;
          case "gcp":
            regions = gcpRegions;
            break;
          case "azure":
            regions = azureRegions;
            break;
        }

        setFieldValue("provider", provider);
        setFieldValue("account_ids", []);
        setFieldValue("regions", regions?.map((region) => region.key) || []);
        setFieldValue("filter", []);

        onFieldChange("provider", provider);
        onFieldChange("account_ids", []);
        onFieldChange("regions", regions?.map((region) => region.key) || []);
        onFieldChange("filter", []);
      },
      [setFieldValue, onFieldChange, awsRegions, gcpRegions, azureRegions],
    );

    useDidMount(() => {
      const values = { id, name, regions, provider, account_ids };

      if (id) {
        resetForm({ values });
      } else {
        setValues(values);
      }
    });

    return (
      <form>
        <Grid container justifyContent="center">
          <Grid item sm={10} md={8}>
            <Stack spacing={4}>
              <TextField
                value={values.name}
                variant="outlined"
                label="Name*"
                size="small"
                name="name"
                helperText={touched.name ? errors.name : ""}
                error={!!(touched.name && errors.name)}
                onChange={handleChange}
                onBlur={inputBlurHandler}
                fullWidth
              />

              <Divider sx={{ width: "100%" }} />

              <SchedulesProviders
                isDisabled={!!values.id || !!providerChangeDisabled}
                activeProvider={values.provider}
                onChange={providerChangeHandler}
                parentScheduleType={parentScheduleType}
              />

              <OffHoursAccounts
                key={values.provider}
                accountIds={account_ids}
                provider={values.provider}
                onChange={accountIdChangeHandler}
                roDisabled={parentScheduleType === "offHours"}
                disabled={!values.provider}
              />

              {values?.provider && (
                <OffHoursRegions
                  selectedRegions={values.regions}
                  provider={values.provider}
                  error={touched.regions ? errors.regions : ""}
                  onChange={regionsChangeHandler}
                  disabled={!values.provider || canNotEditSchedule}
                />
              )}
            </Stack>
          </Grid>
        </Grid>

        <NextPrevious
          hideCancelButton={hideCancelButton}
          isNextDisabled={!id ? !dirty || !isValid : !isValid}
          onNext={submitForm}
          isDirty={dirty}
        />
      </form>
    );
  },
);
