import { FC } from "react";
import {
  Box,
  Table,
  TableBody,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import TableCell from "@mui/material/TableCell";
import { ProviderType } from "../../../../../../../../services/cloudchipr.api";
import { AccountInfoData } from "../../../../../../../common/AccountInfoData";

interface UnprotectDialogTableProps {
  data: {
    id?: string;
    actionDate: string;
    resource: string;
    action: string;
    account?: string;
    provider?: ProviderType;
  }[];
}

export const UnprotectDialogTable: FC<UnprotectDialogTableProps> = ({
  data,
}) => {
  return (
    <Box border={`1px solid ${grey[300]}`} borderRadius={2} overflow="hidden">
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>
              <Typography variant="caption">Resource</Typography>
            </TableCell>
            <TableCell>
              <Typography variant="caption">Account</Typography>
            </TableCell>
            <TableCell>
              <Typography variant="caption" sx={{ whiteSpace: "nowrap" }}>
                Action Date
              </Typography>
            </TableCell>
            <TableCell>
              <Typography variant="caption">Action</Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((item) => {
            if (!item) {
              return null;
            }

            const { id, provider, resource, action, account, actionDate } =
              item;

            return (
              <TableRow key={id} sx={{ bgcolor: "white" }}>
                <TableCell>
                  <Typography variant="caption">{resource}</Typography>
                </TableCell>

                <TableCell>
                  <AccountInfoData
                    accountName={account}
                    provider={provider}
                    maxWidth={220}
                  />
                </TableCell>

                <TableCell>{actionDate}</TableCell>
                <TableCell>
                  <Typography variant="caption" fontWeight="bold">
                    {action}
                  </Typography>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </Box>
  );
};
