import { FC, Fragment, SyntheticEvent, useCallback } from "react";
import { Divider, Tab } from "@mui/material";
import { TabList } from "@mui/lab";
import { useAppDispatch, useAppSelector } from "../../../../../../store/hooks";
import { recommendationComputeDataLengthSelector } from "../../../../../../store/recommendations/selectors/off-hours/data/compute/recommendationComputeDataLengthSelector";
import { recommendationDbDataLengthSelector } from "../../../../../../store/recommendations/selectors/off-hours/data/database/recommendationDbDataLengthSelector";
import { RecommendationGroupType } from "../utils/types/types";
import { recommendationComputeDismissedDataLengthSelector } from "../../../../../../store/recommendations/selectors/off-hours/data/compute/recommendationComputeDismissedDataLengthSelector";
import { recommendationDbDismissedDataLengthSelector } from "../../../../../../store/recommendations/selectors/off-hours/data/database/recommendationDbDismissedDataLengthSelector";
import { resetRecommendationsFilters } from "../../../../../../store/recommendations/recommendationsSlice";

interface OffHoursRecommendationsGroupsProps {
  setGroup(group: RecommendationGroupType): void;
}

export const OffHoursRecommendationsGroups: FC<
  OffHoursRecommendationsGroupsProps
> = ({ setGroup }) => {
  const dispatch = useAppDispatch();

  const computeActiveDataLength = useAppSelector(
    recommendationComputeDataLengthSelector,
  );
  const dbActiveDataLength = useAppSelector(recommendationDbDataLengthSelector);

  const computeDismissedDataLength = useAppSelector(
    recommendationComputeDismissedDataLengthSelector,
  );
  const dbDismissedDataLength = useAppSelector(
    recommendationDbDismissedDataLengthSelector,
  );

  const handleGroupChange = useCallback(
    (_: SyntheticEvent<Element, Event>, group: RecommendationGroupType) => {
      setGroup(group);
      dispatch(resetRecommendationsFilters());
    },
    [setGroup, dispatch],
  );

  return (
    <Fragment>
      <TabList variant="fullWidth" onChange={handleGroupChange}>
        <Tab
          value="compute"
          label={`Compute Instance Recommendations (${
            computeActiveDataLength + computeDismissedDataLength
          })`}
          sx={{ py: 3 }}
        />
        <Tab
          value="database"
          label={`SQL Database Recommendations (${
            dbActiveDataLength + dbDismissedDataLength
          })`}
          sx={{ py: 3 }}
        />
      </TabList>

      <Divider />
    </Fragment>
  );
};
