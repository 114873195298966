import { FC, Fragment, useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import { useEffectOnceWhen } from "rooks";
import Stack from "@mui/material/Stack";
import { CustomCellRendererProps } from "@ag-grid-community/react";
import { DescriptionPopover } from "./DescriptionPopover";
import { useDialog } from "../../../../../../../../utils/hooks/useDialog.hook";
import { useSchedulePreviewDatesInfoHook } from "../../../../../common/utils/hooks/useSchedulePreviewDatesInfo.hook";
import { TypographyWithTooltip } from "../../../../../../../common/TypographyWithTooltip";
import { WithoutAccountsWorkflowDialog } from "../../../../../common/components/grid/WithoutAccountsWorkflowDialog";
import { Schedule } from "../../../../../../../../services/cloudchipr.api";
import ActionIcon from "../../../../../../../../assets/images/icons/workflows/action.svg";
import ActionWithGracePeriodIcon from "../../../../../../../../assets/images/icons/workflows/action_with_grace_period.svg";
import NotificationIcon from "../../../../../../../../assets/images/icons/workflows/notification.svg";
import { ScheduleResourcesPreviewDrawerAppBar } from "../../../../../common/components/resources-preview-old/components/app-bar/ScheduleResourcesPreviewDrawerAppBar";
import { ScheduleResourcesPreviewDrawer } from "../../../../../common/components/resources-preview-old/ScheduleResourcesPreviewDrawer";

export const WorkflowNameCell: FC<CustomCellRendererProps<Schedule>> = ({
  data: schedule,
}) => {
  const [searchParams] = useSearchParams();
  const scheduleIdForPreview = searchParams.get("preview");

  const { open, openDialog, closeDialog } = useDialog();

  const withoutAccounts = useMemo(() => {
    return schedule?.accounts?.every(
      (account) => account.status !== "connected",
    );
  }, [schedule]);

  const accountIds = schedule?.accounts?.map((account) => account.id) ?? [];
  const gracePeriodValue = schedule?.grace_period?.period;

  const { nextRuns, frequency } = useSchedulePreviewDatesInfoHook({
    nextRun: schedule?.metadata?.next_run_at ?? "",
    timeZone: schedule?.time_zone ?? "",
    cron: schedule?.cron ?? null,
    weeklyInterval: schedule?.weekly_schedule_interval,
  });

  useEffectOnceWhen(
    openDialog,
    schedule?.id === scheduleIdForPreview && !withoutAccounts,
  );

  if (!schedule) {
    return null;
  }

  let icon = ActionIcon;
  if (schedule.action === "notify") {
    icon = NotificationIcon;
  } else if (schedule.grace_period) {
    icon = ActionWithGracePeriodIcon;
  }

  const content = (
    <Stack direction="row" height="100%" alignItems="center" spacing={1}>
      <img src={icon} alt={schedule.action} />

      <Stack overflow="hidden">
        <TypographyWithTooltip
          placement="top"
          title={schedule.name}
          color="primary"
          onClick={openDialog}
          sx={{ textDecoration: "underline", cursor: "pointer" }}
        />

        <DescriptionPopover title={schedule.description} />
      </Stack>
    </Stack>
  );

  if (withoutAccounts) {
    return (
      <Fragment>
        {content}

        {open && (
          <WithoutAccountsWorkflowDialog
            onClose={closeDialog}
            workflow={schedule as Schedule}
          />
        )}
      </Fragment>
    );
  }

  return (
    <Fragment>
      {content}

      {schedule.filter && (
        <ScheduleResourcesPreviewDrawer
          open={open}
          gracePeriodEnabled={!!schedule.grace_period}
          scheduleId={schedule.id}
          scheduleAction={schedule.action}
          onClose={closeDialog}
          regions={schedule.regions}
          filters={schedule.filter}
          accountIds={accountIds}
          status={schedule.status}
          name={schedule.name}
          editingUrl={`/automations/workflow/edit/${schedule.id}`}
        >
          <ScheduleResourcesPreviewDrawerAppBar
            scheduleId={schedule.id}
            regions={schedule.regions}
            nextRuns={nextRuns}
            frequency={frequency}
            accountIds={accountIds}
            filters={schedule.filter}
            gracePeriodValue={gracePeriodValue}
          />
        </ScheduleResourcesPreviewDrawer>
      )}
    </Fragment>
  );
};
