import { FC, useMemo } from "react";
import { Stack, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import { useFlag } from "@unleash/proxy-client-react";
import { useDidMount } from "rooks";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../store/hooks";
import { DataGrid } from "../../../../../../../../storybook/data-grid/DataGrid";
import {
  StylesProps,
  ToolbarConfig,
} from "../../../../../../../../storybook/data-grid/utils/types/prop-types";
import { GlobalFilter } from "../../../../../../../../storybook/data-grid/toolbar/GlobalFilter";
import { CommitmentsDrawerContent } from "../../../../../common/components/drawer/CommitmentsDrawerContent";
import { CommitmentsRecommendationsFilters } from "../../../../../utilization-and-coverage/components/content/recommendations/filters/CommitmentsRecommendationsFilters";
import { reservationsRecommendationsDataGridColumns } from "../../../content/recomendations/data-grid/reservationsRecommendationsDataGridColumns";
import { reservationsRecommendationsFilteredDataSelector } from "../../../../../../../../store/commitments/selectors/recommendations/reservations/reservationsRecommendationsFilteredDataSelector";
import { reservationsRecommendationsDataLengthSelector } from "../../../../../../../../store/commitments/selectors/recommendations/reservations/reservationsRecommendationsDataLengthSelector";
import { commitmentsDetailsDrawerTargetTypeSelector } from "../../../../../../../../store/commitments/selectors/common/details-drawer/commitmentsDetailsDrawerTargetTypeSelector";
import { initiateReservationCoverageDrawerThunk } from "../../../../../../../../store/commitments/thunks/recommendations/reservations/initiateReservationCoverageDrawerThunk";

export const ReservationCoverageDrawerRecommendationsSection: FC = () => {
  const dispatch = useAppDispatch();
  const enableReservationRecommendation = useFlag(
    "EnableReservationRecommendation",
  );

  const notFilteredDataLength = useAppSelector(
    reservationsRecommendationsDataLengthSelector,
  );
  const data = useAppSelector(reservationsRecommendationsFilteredDataSelector);
  const targetType = useAppSelector(commitmentsDetailsDrawerTargetTypeSelector);

  useDidMount(() => {
    dispatch(initiateReservationCoverageDrawerThunk());
  });

  const toolbar: ToolbarConfig = useMemo(() => {
    return {
      renderToolbar: ({ setGlobalFilter, globalFilter }) => {
        return (
          <Stack
            alignItems="center"
            direction="row"
            spacing={2}
            justifyContent="space-between"
            py={1}
          >
            <CommitmentsRecommendationsFilters
              type="reservation"
              hideFilters={
                targetType === "resource_type" ? hiddenFilters : undefined
              }
            />
            <GlobalFilter
              globalFilter={globalFilter}
              setGlobalFilter={setGlobalFilter}
              size="xsmall"
            />
          </Stack>
        );
      },
    };
  }, [targetType]);
  if (!enableReservationRecommendation) {
    return null;
  }

  return (
    <CommitmentsDrawerContent title="Recommendations" icon="recommendation">
      {!notFilteredDataLength ? (
        <Stack alignItems="center" p={3}>
          <Typography
            variant="subtitle1"
            fontWeight="medium"
            color="text.secondary"
          >
            No Recommendations
          </Typography>
        </Stack>
      ) : (
        <DataGrid
          enableStickyColumns
          columnSorting
          styles={styles}
          data={data ?? []}
          toolbar={toolbar}
          columns={reservationsRecommendationsDataGridColumns}
        />
      )}
    </CommitmentsDrawerContent>
  );
};

const styles: StylesProps["styles"] = {
  table: {
    borderCollapse: "separate",
    tableLayout: "fixed",
    "& th": { py: 2, bgcolor: "white" },
    "& th:first-of-type": { pl: 2 },
    "& td:first-of-type": { pl: 2 },
    "& tr:hover td": { bgcolor: grey[100] },
    "& tr td": { borderColor: grey[100], bgcolor: "white" },
  },

  tableContainer: {
    bgcolor: "white",
    borderRadius: 2,
    border: "1px solid",
    borderColor: "grey.300",
  },
};

const hiddenFilters = new Set(["type"]);
