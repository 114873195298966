import { cloudChiprApi } from "../../../../services/cloudchipr.api";
import { RootState } from "../../../store";
import { generateRemoveDashboardWidgetFixedCacheKey } from "../../../../components/pages/dashboard/utils/helpers/dashbaordFixedCacheKeyGenerator";

export const widgetsRemovalLoadingSelector = (
  state: RootState,
  widgetId?: string,
): boolean => {
  if (!widgetId) {
    return false;
  }
  const selector =
    cloudChiprApi.endpoints
      .deleteUsersMeOrganisationsCurrentDashboardsByDashboardIdWidgetsAndWidgetId
      .select;

  return !!selector(generateRemoveDashboardWidgetFixedCacheKey(widgetId))(state)
    ?.isLoading;
};
