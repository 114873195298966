import { createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../../store";
import { setReportsData } from "../reportsSlice";
import { reportsDataPropertyByKeySelector } from "../selector/reportsDataPropertyByKeySelector";

interface SetReportsScheduleAttachmentsThunkArgs {
  checked: boolean;
  type: "pdf" | "csv";
}

export const setReportsScheduleAttachmentsThunk = createAsyncThunk(
  "reports/setReportsScheduleAttachments",
  (
    { checked, type }: SetReportsScheduleAttachmentsThunkArgs,
    { dispatch, getState },
  ) => {
    const state = getState() as RootState;
    const exportTypes =
      reportsDataPropertyByKeySelector("export_file_types")(state);

    dispatch(
      setReportsData({
        export_file_types: checked
          ? [...(exportTypes ?? []), type]
          : (exportTypes?.filter((curType) => curType !== type) ?? []),
      }),
    );
  },
);
