import moment from "moment/moment";

export const sortArrayByDate = (arr: any[], dateKey: string) => {
  return arr.sort((a, b) => {
    const aDate = moment(a[dateKey]).format();
    const bDate = moment(b[dateKey]).format();

    if (aDate < bDate) {
      return -1;
    }

    if (aDate > bDate) {
      return 1;
    }

    return 0;
  });
};

export const sortAlphabeticallyCallback = (a: string, b: string) =>
  a.localeCompare(b);
