import { monthDayToString } from "./monthDayToString";
import { MonthlyByDaySchedule } from "../../../../../../../services/cloudchipr.api";

export const getMonthlyByDayScheduleTitleAndTooltip = (
  time: string,
  dayOfMonth: MonthlyByDaySchedule["day_of_month"],
) => {
  return {
    title: "Monthly",
    tooltip: `On the day ${monthDayToString(dayOfMonth)} of month at ${time}`,
  };
};
