import { executionLogDatesSelector } from "./executionLogDatesSelector";
import { RootState } from "../../store";
import { calculateFromToByDatesData } from "../../../components/common/date-range-picker/utils/helpers/calculateFromToByDatesData";
import {
  formatDate,
  resetTimeInDate,
} from "../../../utils/helpers/date-time/datetime-format";
import { executionLogDateAllTimeLabel } from "../../../components/pages/execution-log/utils/constants/dates";
import { Dates } from "../../../services/cloudchipr.api";

export const executionLogPayloadDatesSelector = (state: RootState) => {
  const dates = executionLogDatesSelector(state);

  let from = dates.from;
  let to = dates.to;

  if (dates.label === executionLogDateAllTimeLabel) {
    return {
      date_from: null,
      date_to: null,
    };
  }

  if (!from || !to) {
    const { fromDate, toDate } = calculateFromToByDatesData(dates as Dates);

    from = fromDate;
    to = toDate;
  }

  return {
    date_from: from ? formatDate(resetTimeInDate(from)) : null,
    date_to: to ? formatDate(resetTimeInDate(to)) : null,
  };
};
