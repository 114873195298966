import { RootState } from "../../../../store";
import { commitmentsSliceSelector } from "../commitmentsSliceSelector";
import { SavingsPlanCoverageGrouping } from "../../../../../services/cloudchipr.api";

export const commitmentsDetailsDrawerCoverageGroupingSelector = (
  state: RootState,
): SavingsPlanCoverageGrouping => {
  const slice = commitmentsSliceSelector(state);

  return slice.detailsDrawer.coverageGrouping;
};
