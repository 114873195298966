import { cloudChiprApi } from "../../../../services/cloudchipr.api";
import { RootState } from "../../../store";
import { createUtilizationAlertFixedCacheKey } from "../../utils/constants/fixedCacheKeys";

const endpoint =
  cloudChiprApi.endpoints.postUsersMeOrganisationsCurrentAlertsUtilizationAndCoverage.select(
    createUtilizationAlertFixedCacheKey,
  );

export const saveUtilizationLoadingSelector = (state: RootState) => {
  return endpoint(state)?.isLoading;
};
