import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  cloudChiprApi,
  ResourceFilters,
} from "../../../services/cloudchipr.api";
import { addRegionsToFilters } from "../../../utils/helpers/addRegionsToFilters";
import { generateScheduleResourcesFetchingFixedCacheKey } from "../../../components/pages/schedule/common/utils/helpers/fixedCacheKeys";
import { getFirstParentInHierarchyResourceType } from "../../../utils/helpers/resources/getFirstParentInHierarchyResourceType";

export const getScheduleSelectedResourcesThunk = createAsyncThunk(
  "getScheduleSelectedResources",
  async (
    {
      scheduleId,
      accountIds,
      regions,
      resourceFilters,
    }: {
      scheduleId: string;
      accountIds: string[];
      resourceFilters: ResourceFilters;
      regions?: string[];
    },
    { dispatch },
  ) => {
    const { postUsersMeV2OrganisationsCurrentByTypeResources } =
      cloudChiprApi.endpoints;

    if (!accountIds) {
      return;
    }

    const fixedCacheKey = generateScheduleResourcesFetchingFixedCacheKey(
      resourceFilters.type,
      scheduleId,
    );

    return await dispatch(
      postUsersMeV2OrganisationsCurrentByTypeResources.initiate(
        {
          type:
            getFirstParentInHierarchyResourceType(resourceFilters.type) ??
            resourceFilters.type,
          body: {
            account_ids: accountIds,
            filter: addRegionsToFilters(regions ?? [], resourceFilters),
          },
        },
        { fixedCacheKey },
      ),
    ).unwrap();
  },
);
