import { protectorResourcesDataSelector } from "./protectorResourcesDataSelector";
import { RootState } from "../../../store";
import { ResourceItemType } from "../../../live-usage-mgmt/utils/types/types";

export const protectorAllResourcesSelector = (
  state: RootState,
  scheduleId?: string,
  batchId?: string,
): ResourceItemType[] => {
  const protectorResources = protectorResourcesDataSelector(
    state,
    scheduleId,
    batchId,
  );

  return (
    protectorResources
      ?.map(({ resources }) => resources.resources)
      ?.flat()
      ?.map((resource) => resource.resource) ?? []
  );
};
