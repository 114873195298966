import { FC, memo, useCallback } from "react";
import { List, Popover } from "@mui/material";
import { DismissMenuOption } from "./DismissMenuOption";
import { DismissByResource } from "./by-resource/DismissByResource";
import { DismissPeriod } from "../../../../../../services/cloudchipr.api";
import { useAppDispatch } from "../../../../../../store/hooks";
import {
  setDismissPeriod,
  setRecommendationsReadyToDismiss,
  setSelectedIds,
} from "../../../../../../store/recommendations/recommendationsSlice";
import { recommendationDismissPeriodLabels } from "../../../utils/constants/recommendationDismissPeriodLabels";

interface DismissTypesMenuProps {
  open: boolean;
  recommendationId?: string;
  onClose(): void;
  anchor: HTMLElement | null;
  recommendationType: "off-hours" | "rightsizing";
}

export const DismissTypesMenu: FC<DismissTypesMenuProps> = memo(
  ({ anchor, open, onClose, recommendationId, recommendationType }) => {
    const dispatch = useAppDispatch();

    const onDismissClick = useCallback(
      (value: DismissPeriod) => {
        if (recommendationId) {
          dispatch(setSelectedIds([recommendationId]));
        }

        dispatch(setDismissPeriod(value));
        dispatch(setRecommendationsReadyToDismiss(true));
        onClose();
      },
      [dispatch, onClose, recommendationId],
    );

    const periods = Object.keys(
      recommendationDismissPeriodLabels,
    ) as DismissPeriod[];

    return (
      <Popover
        open={open}
        onClose={onClose}
        anchorEl={anchor}
        transformOrigin={{ vertical: "top", horizontal: "left" }}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <List>
          {periods.map((period) => {
            return (
              <DismissMenuOption
                key={period}
                value={period}
                onClick={onDismissClick}
              >
                Dismiss {recommendationDismissPeriodLabels[period]}
              </DismissMenuOption>
            );
          })}

          {recommendationId && (
            <DismissByResource
              recommendationId={recommendationId}
              recommendationType={recommendationType}
            />
          )}
        </List>
      </Popover>
    );
  },
);
