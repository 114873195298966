import { FC, Fragment, useCallback } from "react";
import { Button, Stack, Typography } from "@mui/material";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import { useDialog } from "../../../../../../utils/hooks/useDialog.hook";
import { LiveUsageMgmtFilterTemplatesDialog } from "../templates-table/LiveUsageMgmtFilterTemplatesDialog";
import { useAppSelector } from "../../../../../../store/hooks";
import { liveUsageMgmtSortedFilterTemplatesSelector } from "../../../../../../store/live-usage-mgmt/selectors/filter-template/liveUsageMgmtSortedFilterTemplatesSelector";
import { liveUsageMgmtProviderSelector } from "../../../../../../store/live-usage-mgmt/selectors/store-selectors/liveUsageMgmtProviderSelector";

interface FilterTemplateDropdownHeaderProps {
  onClose(): void;
}
export const LiveUsageMgmtFilterTemplateDropdownHeader: FC<
  FilterTemplateDropdownHeaderProps
> = ({ onClose }) => {
  const {
    open: openFilterTemplate,
    openDialog: openFilterTemplateDialog,
    closeDialog: closeFilterTemplateDialog,
  } = useDialog();
  const templates = useAppSelector(liveUsageMgmtSortedFilterTemplatesSelector);
  const provider = useAppSelector(liveUsageMgmtProviderSelector);

  const dialogCloseHandler = useCallback(() => {
    closeFilterTemplateDialog();
    onClose();
  }, [onClose, closeFilterTemplateDialog]);

  const filterTemplateClickHandler = useCallback(() => {
    openFilterTemplateDialog();
  }, [openFilterTemplateDialog]);

  return (
    <Fragment>
      <Stack
        direction="row"
        justifyContent="space-between"
        py={1}
        pl={1.5}
        pr={1}
        alignItems="center"
      >
        <Typography variant="subtitle2">Filter Templates</Typography>
        <Button
          variant="text"
          startIcon={<SettingsOutlinedIcon />}
          size="small"
          onClick={filterTemplateClickHandler}
        >
          Manage Templates
        </Button>
      </Stack>
      <LiveUsageMgmtFilterTemplatesDialog
        filterTemplates={templates}
        provider={provider ?? undefined}
        onClose={closeFilterTemplateDialog}
        onApplyCallback={dialogCloseHandler}
        open={openFilterTemplate}
      />
    </Fragment>
  );
};
