import { FC, ReactNode, useCallback } from "react";
import {
  closestCenter,
  DndContext,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { Stack } from "@mui/material";
import { TaskPropertyType } from "../../../../../../../../store/task-management/utils/types/types";
import type { DragEndEvent } from "@dnd-kit/core/dist/types";

interface TaskPropertiesDragDropWrapperProps {
  children: ReactNode;
  properties: TaskPropertyType[];
  onPropertiesChange(properties: TaskPropertyType[]): void;
}

export const TaskPropertiesDragDropWrapper: FC<
  TaskPropertiesDragDropWrapperProps
> = ({ children, onPropertiesChange, properties }) => {
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  const dragEndHandler = useCallback(
    ({ active, over }: DragEndEvent) => {
      if (!active.id || !over?.id || active.id === over?.id) {
        return;
      }
      const oldIndex = properties.findIndex((item) => item.id === active.id);
      const newIndex = properties.findIndex((item) => item.id === over.id);

      onPropertiesChange(arrayMove(properties, oldIndex, newIndex));
    },
    [properties, onPropertiesChange],
  );

  return (
    <DndContext
      sensors={sensors}
      onDragEnd={dragEndHandler}
      collisionDetection={closestCenter}
    >
      <SortableContext
        items={properties}
        strategy={verticalListSortingStrategy}
      >
        <Stack spacing={1}>{children}</Stack>
      </SortableContext>
    </DndContext>
  );
};
