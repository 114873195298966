import { createAsyncThunk } from "@reduxjs/toolkit";
import { setAlertsData } from "../../alertsSlice";
import { costAnomalyAlertByIdSelector } from "../../selectors/cost-anomaly-alerts/costAnomalyAlertByIdSelector";
import { RootState } from "../../../store";
import { formatNotificationsFromSlackToHtml } from "../../../../components/utils/helpers/formatNotificationsFromSlackToHtml";

export const setCostAnomalyAlertEditDataThunk = createAsyncThunk(
  "alerts/setCostAnomalyEditData",
  (id: string, { dispatch, getState }) => {
    const state = getState() as RootState;
    const alert = costAnomalyAlertByIdSelector(state, id);

    if (!alert) {
      return;
    }

    const thresholdItems =
      alert.threshold?.threshold_items?.map((threshold) => ({
        ...threshold,
        amount: threshold?.amount?.toString(),
      })) ?? [];

    dispatch(
      setAlertsData({
        id: alert.id,
        name: alert.name,
        grouping: alert.grouping,
        viewId: alert.view_id,
        threshold: {
          operator: alert?.threshold?.operator,
          threshold_items: thresholdItems,
        },
        emails: alert.emails,
        notifications: formatNotificationsFromSlackToHtml(alert.notifications),
        timeInterval: alert.time_interval.time_interval?.toString(),
        timeIntervalUnit: alert.time_interval.time_interval_unit,
      }),
    );
  },
);
