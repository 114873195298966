import { FC, useCallback } from "react";
import { WidgetCommitmentsUtilizationAggregation } from "../../../../../../../../../../../../../services/cloudchipr.api";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../../../../../../store/hooks";
import { commitmentUtilizationSetupAggregationPropertyByKeySelector } from "../../../../../../../../../../../../../store/dashboards/selectors/setups/commitment-utilization/commitmentUtilizationSetupAggregationPropertyByKeySelector";
import { SwitchWithTypography } from "../../../../../../../../../../../../SwitchWithTypography";
import { commitmentUtilizationWidgetAggregationChangeThunk } from "../../../../../../../../../../../../../store/dashboards/thunks/widgets/commitment-utilization/setup-change/commitmentUtilizationWidgetAggregationChangeThunk";

export interface CommitmentUtilizationWidgetAggregationsSwitchProps {
  selectorKey: keyof WidgetCommitmentsUtilizationAggregation;
  title: string;
}

export const CommitmentUtilizationWidgetAggregationsSwitch: FC<
  CommitmentUtilizationWidgetAggregationsSwitchProps
> = ({ selectorKey, title }) => {
  const dispatch = useAppDispatch();
  const checked = useAppSelector(
    commitmentUtilizationSetupAggregationPropertyByKeySelector(selectorKey),
  );

  const handleCheckedChange = useCallback(
    (checked: boolean) => {
      dispatch(
        commitmentUtilizationWidgetAggregationChangeThunk({
          [selectorKey]: checked,
        }),
      );
    },
    [dispatch, selectorKey],
  );

  return (
    <SwitchWithTypography
      checked={checked}
      onChange={handleCheckedChange}
      title={title}
    />
  );
};
