import { FC, ReactNode } from "react";
import { Box, Typography } from "@mui/material";

interface WidgetSetupContentWrapperProps {
  children?: ReactNode;
}

export const WidgetSetupContentWrapper: FC<WidgetSetupContentWrapperProps> = ({
  children,
}) => {
  return (
    <Box bgcolor="grey.100" p={2}>
      <Typography
        variant="body1"
        fontWeight="medium"
        color="text.secondary"
        mb={1.5}
        mt={1}
      >
        Widget Preview
      </Typography>

      {children}
    </Box>
  );
};
