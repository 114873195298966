import { RootState } from "../../../../store";
import { userRoleDataIdSelector } from "../userRoleDataIdSelector";
import { orgCurrentRoleByIdSelector } from "../role-by-id/orgCurrentRoleByIdSelector";

export const userRoleGettingByIdLoadingSelector = (state: RootState) => {
  const id = userRoleDataIdSelector(state);
  if (!id) {
    return;
  }
  return orgCurrentRoleByIdSelector({ roleId: id })(state)?.isLoading;
};
