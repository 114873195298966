import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { IntegrationsListGridRow } from "./IntegrationsListGridRow";
import { useGetUsersMeIntegrationsQuery } from "../../../../../services/cloudchipr.api";

export const IntegrationsListGrid = () => {
  const { data: integrations } = useGetUsersMeIntegrationsQuery({});

  return (
    <TableContainer>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell sx={{ pl: 3 }}>Name</TableCell>
            <TableCell>Type</TableCell>
            <TableCell>In Use</TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {integrations?.map((item) => {
            return <IntegrationsListGridRow key={item.id} integration={item} />;
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
