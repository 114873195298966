import { commitmentUtilizationWidgetByIdSelector } from "./commitmentUtilizationWidgetByIdSelector";
import { RootState } from "../../../../../store";

export const commitmentUtilizationWidgetProviderOrganizationIdSelector = (
  state: RootState,
  widgetId: string,
) => {
  return commitmentUtilizationWidgetByIdSelector(state, widgetId)
    ?.provider_organisation_id;
};
