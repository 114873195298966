import { ResourceType } from "../../../services/cloudchipr.api";
import { allResourcesTypesByProvider } from "../../constants/resources/allResourcesTypesByProvider";

export const isStringResourceType = (
  str?: string | null,
): str is ResourceType => {
  return Object.values(allResourcesTypesByProvider)
    .flat()
    .includes(str as ResourceType);
};
