import { FC, Fragment, useCallback, useEffect, useMemo, useState } from "react";
import { Box, Stack, Theme, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import { useFlag } from "@unleash/proxy-client-react";
import { DataGrid } from "../../../../../storybook/data-grid/DataGrid";
import { useAppSelector } from "../../../../../store/hooks";
import { ResourceType } from "../../../../../services/cloudchipr.api";
import { ColumnSetupType } from "../../../../../storybook/data-grid/utils/types/types";
import { DataGridProvider } from "../../../../../storybook/data-grid/DataGridProvider";
import { RootState } from "../../../../../store/store";
import { liveUsageMgmtSelectedResourcesByResourceTypeSelector } from "../../../../../store/live-usage-mgmt/selectors/resource-type-data/liveUsageMgmtSelectedResourcesByResourceTypeSelector";
import { getResourceTypeColumns } from "../../../../pages/live-usage-mgmt/components/resource-card/resource-card-content/resource-card-data/resource-card-grid/resouce-card-data-grid/utils/data-grid/columns-setup/getResourceTypeColumns";
import { generateColumns } from "../../../../pages/live-usage-mgmt/components/resource-card/resource-card-content/resource-card-data/resource-card-grid/resouce-card-data-grid/utils/data-grid/columns-setup/resourcesColumnsGenerator";
import { newMetricColumnsByFT } from "../../../../pages/live-usage-mgmt/components/resource-card/resource-card-content/resource-card-data/resource-card-grid/resouce-card-data-grid/cells/metrics-cells/utils/helpers/newMetricColumnsEnablenes";
import { selectedResourcesCSVDataDisabledColumns } from "../../../../../store/live-usage-mgmt/selectors/resource-type-data/liveUsageMgmtSelectedResourcesByResourceTypeCsvDataSelector";
import { ResourceDataType } from "../../../../../store/live-usage-mgmt/utils/types/types";

interface CreateIntegrationResourcesTableProps {
  resourceType: ResourceType;
  label: string;
}

export const CreateIntegrationResourcesTableV2: FC<
  CreateIntegrationResourcesTableProps
> = ({ resourceType, label }) => {
  const enableMetricsCharts = useFlag("EnableResourceSmallCharts");

  const [data, setData] = useState<ResourceDataType[] | null>(null);

  const columns = useMemo(() => {
    const resourceTypeColumns = getResourceTypeColumns(
      resourceType,
      selectedResourcesCSVDataDisabledColumns.concat(
        newMetricColumnsByFT(enableMetricsCharts),
      ),
    ) as ColumnSetupType<ResourceDataType>[];

    const columns = [...resourceTypeColumns];
    columns[0].type = "identifier";

    return generateColumns(columns);
  }, [resourceType, enableMetricsCharts]);

  const selector = useCallback(
    (state: RootState) => {
      return liveUsageMgmtSelectedResourcesByResourceTypeSelector(
        state,
        resourceType,
      );
    },
    [resourceType],
  );

  const resources = useAppSelector(selector);

  useEffect(() => {
    resources && setData(resources.slice(0, 25));
  }, [resources]);

  if (!data) {
    return null;
  }

  return (
    <Stack>
      <Typography variant="body1" fontWeight="bold" mb={1}>
        {label}
      </Typography>
      <Typography variant="caption" fontSize="x-small" color="text.secondary">
        {data.length > 24 ? (
          <Fragment>
            Showing{" "}
            <Typography fontWeight="bold" fontSize="inherit" component="span">
              {data.length}
            </Typography>{" "}
            of{" "}
            <Typography fontWeight="bold" component="span" fontSize="inherit">
              {resources?.length ?? data.length}
            </Typography>{" "}
            resources
          </Fragment>
        ) : (
          <Fragment>
            Showing{" "}
            <Typography fontWeight="bold" component="span" fontSize="inherit">
              {data.length}
            </Typography>{" "}
            resource{data.length > 1 ? "s" : ""}
          </Fragment>
        )}
      </Typography>

      <Box border={`1px solid ${grey[300]}`} borderRadius={1}>
        <DataGridProvider>
          <DataGrid data={data} styles={styles} columns={columns} />
        </DataGridProvider>
      </Box>
    </Stack>
  );
};

const styles = {
  table: {
    borderCollapse: "separate",
    tableLayout: "fixed",
  },
  tableHeaderRow: {
    top: 0,
    position: "sticky",
    bgcolor: "grey.100",
    zIndex: (theme: Theme) => theme.zIndex.appBar - 1,
  },
  tableContainer: {
    maxHeight: 150,
    "& td": { border: "none", borderLeft: `1px solid ${grey[200]}` },
    "& tH": { borderLeft: `1px solid ${grey[200]}` },
    "& td:first-of-type": { border: "none" },
  },
};
