import { FC, useCallback, useMemo } from "react";
import { PreviewDataGridActionComponent } from "../PreviewDataGridActionComponent";
import { ResourceItemType } from "../../../../../../../../../store/live-usage-mgmt/utils/types/types";
import { CleanActionTypes } from "../../../../../../../../../utils/clean-options";
import {
  ResourceFilters,
  ResourceType,
} from "../../../../../../../../../services/cloudchipr.api";
import { useAppDispatch } from "../../../../../../../../../store/hooks";
import { useAppAbility } from "../../../../../../../../../services/permissions";
import { scheduleResourcesProtectionThunk } from "../../../../../../../../../store/schedules/thunks/scheduleResourcesProtectionThunk";
import { protectorResourceProtectThunk } from "../../../../../../../../../store/schedules/thunks/resources-for-protector/protectorResourceProtectThunk";

interface PreviewDataGridActionCellProps {
  resource: ResourceItemType;
  action: CleanActionTypes;
  accountIds: string[];
  resourceFilter?: ResourceFilters;
  regions?: string[];
  scheduleId: string;
  batchId?: string;
}

export const PreviewDataGridActionCell: FC<PreviewDataGridActionCellProps> = ({
  resource,
  action,
  accountIds,
  regions,
  resourceFilter,
  scheduleId,
  batchId,
}) => {
  const dispatch = useAppDispatch();
  const { cannot } = useAppAbility();
  const canNotProtectResource = cannot("protect", "resource");

  const resourceId = resource?.id;
  const accountId = resource?.account?.id;
  const isProtected = !!resource?.is_protected;
  const resourceType = resource?.resource_type as ResourceType;
  const viewIsForProtector = !resourceFilter && batchId;

  const resourcesToProtect = useMemo(() => {
    return {
      [accountId]: [
        {
          id: resourceId,
          type: resourceType,
          is_protected: !isProtected,
        },
      ],
    };
  }, [accountId, resourceId, resourceType, isProtected]);

  const scheduleResourcesProtectHandler = useCallback(async () => {
    if (!resourceFilter) {
      return;
    }

    await dispatch(
      scheduleResourcesProtectionThunk({
        scheduleId,
        accountIds,
        regions,
        resourcesToProtect,
        resourceFilters: [{ filter: resourceFilter }],
      }),
    );
  }, [
    resourcesToProtect,
    dispatch,
    scheduleId,
    resourceFilter,
    accountIds,
    regions,
  ]);

  const protectorResourcesProtectHandler = useCallback(async () => {
    if (!batchId) {
      return;
    }

    await dispatch(
      protectorResourceProtectThunk({
        scheduleId,
        batchId,
        resourcesToProtect,
      }),
    );
  }, [resourcesToProtect, dispatch, scheduleId, batchId]);

  const submitHandler = viewIsForProtector
    ? protectorResourcesProtectHandler
    : scheduleResourcesProtectHandler;

  return (
    <PreviewDataGridActionComponent
      action={action}
      batchId={batchId}
      resource={resource}
      scheduleId={scheduleId}
      onSubmit={submitHandler}
      protectDisabled={canNotProtectResource}
    />
  );
};
