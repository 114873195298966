import { GetQuickFilterTextParams } from "@ag-grid-community/core/dist/types/src/entities/colDef";
import { Task } from "../../../../../../services/cloudchipr.api";

export const taskTargetTagFilterText = ({
  data,
}: GetQuickFilterTextParams<Task>) => {
  return (
    data?.target?.tags?.map((tag) => `${tag.key} ${tag.value}`)?.join(" ") ?? ""
  );
};
