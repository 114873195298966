import { ColDef } from "@ag-grid-community/core";
import { AutoGroupColumnDefInnerRenderer } from "./AutoGroupColumnDefInnerRenderer";
import { autoGroupColumnDefComparatorFn } from "./autoGroupColumnDefComparatorFn";
import { GetUsersMeOrganisationsCurrentTasksPropertiesApiResponse } from "../../../../../../services/cloudchipr.api";

export const autoGroupColumnId = "ag-Grid-AutoColumn";

export const getAutoGroupColumnDef = (
  taskAvailableProperties?: GetUsersMeOrganisationsCurrentTasksPropertiesApiResponse,
): ColDef => ({
  minWidth: 200,
  sort: "asc",
  pinned: "left",
  sortable: false,
  headerValueGetter: () => "Grouped by",
  comparator: autoGroupColumnDefComparatorFn(taskAvailableProperties),
  cellRendererParams: { innerRenderer: AutoGroupColumnDefInnerRenderer },
});
