export const integrationCustomSlackActionExecuteDataGrid = [
  {
    header: "Account Name",
    value: "value",
  },
  {
    header: "Account ID",
    value: "value",
  },
  {
    header: "Resource Type",
    value: "Value",
  },
  {
    header: "Resource ID",
    value: "Value",
  },
  {
    header: "Region",
    value: "Value",
  },
];
