import { FC, Fragment, useCallback, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Card,
  CardContent,
  CircularProgress,
  Container,
  Stack,
} from "@mui/material";
import { IntegrationCreation } from "./components/integrations-create/IntegrationCreation";
import { IntegrationType } from "./components/integrations-create/components/common/integration-type/IntegrationTypeCard";
import { PageHeader } from "../common/PageHeader";
import { useGetUsersMeByTypeIntegrationsAndIntegrationIdQuery } from "../../../services/cloudchipr.api";

export const IntegrationItem: FC = () => {
  const { id, type } = useParams<{ id: string; type: IntegrationType }>();
  const navigate = useNavigate();

  const { data, isLoading, error } =
    useGetUsersMeByTypeIntegrationsAndIntegrationIdQuery(
      { type: type || "", integrationId: id || "" },
      { skip: !id || !type, refetchOnMountOrArgChange: true },
    );

  const breadcrumbs = useMemo(() => {
    return [
      { title: "Integrations", to: "/integrations" },
      { title: `${id ? "Edit" : "Create"} integration` },
    ];
  }, [id]);

  const cancelHandler = useCallback(() => {
    navigate("/integrations");
  }, [navigate]);

  if (error && "status" in error && error.status === 400) {
    cancelHandler();
  }

  return (
    <Fragment>
      <PageHeader breadcrumbs={breadcrumbs} />
      <Container sx={{ p: 2 }} disableGutters>
        {isLoading ? (
          <Stack alignItems="center" pt={4}>
            <CircularProgress disableShrink />
          </Stack>
        ) : (
          <Card variant="outlined">
            <CardContent sx={{ p: 3 }}>
              <IntegrationCreation
                integration={data}
                type={type}
                onCancel={cancelHandler}
                onSubmit={cancelHandler}
              />
            </CardContent>
          </Card>
        )}
      </Container>
    </Fragment>
  );
};
