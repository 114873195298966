import { RootState } from "../../../../store";
import { cloudChiprApi } from "../../../../../services/cloudchipr.api";
import { currentBudgetViewIdSelector } from "../data-selectors/currentBudgetViewIdSelector";

export const resourceExplorerDataByViewIdFixedCacheKeyGenerator = (
  viewId?: string | void,
) => {
  return viewId || "resourceExplorerDataByViewIdFixedCacheKey";
};

export const resourceExplorerDataByViewIdSelector = (state: RootState) => {
  const viewId = currentBudgetViewIdSelector(state);

  return cloudChiprApi.endpoints.postUsersMeOrganisationsCurrentResourceExplorer.select(
    resourceExplorerDataByViewIdFixedCacheKeyGenerator(viewId),
  )(state);
};
