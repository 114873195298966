export enum RightsizingRecommendationsChartColors {
  UsableCapacity = "#2196F3",
  RecommendedSize = "#4CAF50",
  CpuArea = "#9966FF",
}

export const providerEnablingLinks = {
  aws: "https://us-east-1.console.aws.amazon.com/cost-management/home?region=us-east-1#/settings",
  gcp: "https://console.cloud.google.com/marketplace/product/google/recommender.googleapis.com",
  azure: "",
};
