import { Row } from "@tanstack/react-table";
import { ExecutionLogsDataGridResourcesType } from "../types/types";

const sortLabels = (current: string, next: string) => {
  if (current > next) {
    return -1;
  }
  if (current < next) {
    return 1;
  }
  return 0;
};

export const accountSortingFn = (
  currentRow: Row<ExecutionLogsDataGridResourcesType>,
  nextRow: Row<ExecutionLogsDataGridResourcesType>,
) => {
  const current =
    currentRow.original.account?.provider_account_name ||
    currentRow.original.account?.provider_account_id ||
    currentRow.original.account?.id;

  const next =
    nextRow.original.account?.provider_account_name ||
    nextRow.original.account?.provider_account_id ||
    nextRow.original.account?.id;

  return sortLabels(current, next);
};

export const resourceSortingFn = (
  currentRow: Row<ExecutionLogsDataGridResourcesType>,
  nextRow: Row<ExecutionLogsDataGridResourcesType>,
) => {
  const current =
    currentRow.original.name || currentRow.original.provider_unique_identifier;
  const next =
    nextRow.original.name || nextRow.original.provider_unique_identifier;

  return sortLabels(current, next);
};
