import { FC } from "react";
import { Box, Typography } from "@mui/material";
import { useAppSelector } from "../../../../../../../../../../store/hooks";
import { formatDate } from "../../../../../../../../../../utils/helpers/date-time/datetime-format";
import { TooltipText } from "../../../../../../../../../common/TooltipText";
import { money } from "../../../../../../../../../../utils/numeral/money";
import { costBreakdownWidgetPrevTotalCostSelector } from "../../../../../../../../../../store/dashboards/selectors/widgets/cost-breakdown/widget-view/costBreakdownWidgetPrevTotalCostSelector";

interface CostBreakdownPreviousPeriodCellProps {
  widgetId: string;
  value: number | null;
}

export const CostBreakdownPreviousPeriodCell: FC<
  CostBreakdownPreviousPeriodCellProps
> = ({ widgetId, value }) => {
  const previousPeriodTotal = useAppSelector((state) =>
    costBreakdownWidgetPrevTotalCostSelector(state, widgetId),
  );

  const fromDate = formatDate(
    previousPeriodTotal?.dateDetails?.from_date ?? "",
    { type: "date" },
  );
  const toDate = formatDate(previousPeriodTotal?.dateDetails?.to_date ?? "", {
    type: "date",
  });

  return value === null ? (
    <Box textAlign="right" pr={4}>
      <TooltipText
        tooltipTitle={`No data for Previous Period - ${fromDate} - ${toDate}`}
      >
        N/A
      </TooltipText>
    </Box>
  ) : (
    <Typography variant="body2" align="right" pr={4}>
      {money(value)}
    </Typography>
  );
};
