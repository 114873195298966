import { FC, useMemo } from "react";
import { Box, Stack, Typography } from "@mui/material";
import { ThresholdItem } from "../../../../../../services/cloudchipr.api";
import { joinThresholds } from "../cost-anomaly/utils/helpers/joinThresholds";
import { AlertTabType } from "../../../../../../store/alerts/utils/types/types";
import { TypographyWithTooltip } from "../../../../../common/TypographyWithTooltip";

interface AlertsDataGridThresholdCellProps {
  thresholds: ThresholdItem[];
  alertType: AlertTabType;
  operator?: "and" | "or";
}

export const AlertsDataGridThresholdCell: FC<
  AlertsDataGridThresholdCellProps
> = ({ thresholds, alertType, operator }) => {
  const thresholdTitles = useMemo(() => {
    return joinThresholds(thresholds);
  }, [thresholds]);

  if (!thresholds) {
    return "-";
  }

  if (alertType === "costAnomaly") {
    return (
      <Stack direction="row" spacing={0.5} alignItems="center">
        <TypographyWithTooltip title={thresholdTitles[0]} variant="inherit" />
        {operator && thresholds.length > 1 && (
          <Box bgcolor="grey.100" py={0} px={0.5} borderRadius={1}>
            <Typography
              variant="body2"
              color="text.secondary"
              fontWeight="medium"
            >
              {operator}
            </Typography>
          </Box>
        )}
        <TypographyWithTooltip title={thresholdTitles[1]} variant="inherit" />
      </Stack>
    );
  }

  return (
    <TypographyWithTooltip
      variant="inherit"
      title={thresholdTitles.join(" , ")}
    />
  );
};
