import { FC } from "react";
import { Typography } from "@mui/material";

export const SlackIntegrationGracePeriodMessageDescription: FC = () => {
  return (
    <Typography variant="body1">
      On
      <Typography component="span" variant="body1" fontWeight="medium">
        {` {{action date}} `}
      </Typography>
      Cloudchipr will perform actions on the attached resources. In case you
      need to keep any of the attached resources, please go to the
      <Typography component="span" variant="body1" fontWeight="medium">
        {` {{workflow name}} `}
      </Typography>
      workflow page to review and protect them.
    </Typography>
  );
};
