import { createDraftSafeSelector } from "@reduxjs/toolkit";
import { alertsCostAnomalyDataTimeIntervalSelector } from "./data/alertsCostAnomalyDataTimeIntervalSelector";
import { alertsCostAnomalyDataTimeIntervalUnitSelector } from "./data/alertsCostAnomalyDataTimeIntervalUnitSelector";
import { getCostAnomalyPreviewDatesFromTimeInterval } from "../../../../components/pages/alerts/components/drawer/components/cost-anomaly/components/preview-section/utils/helpers/getCostAnomalyPreviewDatesFromTimeInterval";

export const costAnomalyPreviewDatesFromTimeIntervalSelector =
  createDraftSafeSelector(
    [
      alertsCostAnomalyDataTimeIntervalSelector,
      alertsCostAnomalyDataTimeIntervalUnitSelector,
    ],
    (timeInterval, timeIntervalUnit) => {
      return getCostAnomalyPreviewDatesFromTimeInterval(
        Number(timeInterval),
        timeIntervalUnit,
      );
    },
  );
