import { FC } from "react";
import { TypographyProps } from "@mui/material";
import moment from "moment/moment";
import { NullableDate } from "../../../../../../services/cloudchipr.api";
import { formatDate } from "../../../../../../utils/helpers/date-time/datetime-format";
import { TypographyWithTooltip } from "../../../../../common/TypographyWithTooltip";

interface ScheduledNextRunDateTimeProps extends TypographyProps {
  nextRun?: NullableDate;
}

export const ScheduledNextRunDateTime: FC<ScheduledNextRunDateTimeProps> = ({
  nextRun,
  ...props
}) => {
  if (!nextRun) {
    return null;
  }

  return (
    <TypographyWithTooltip
      title={`${moment(nextRun).format("ddd")}, 
      ${formatDate(nextRun, {
        type: "dateWithComma",
      })} 
      ${formatDate(nextRun, {
        type: "time",
      })}`}
      variant="body2"
      sx={{ whiteSpace: "nowrap" }}
      {...props}
    />
  );
};
