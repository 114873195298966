import { createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../../../store";
import { connectedOrganizationExistsSelector } from "../../selectors/organisations/connectedOrganizationExistsSelector";
import { organizationAccountsByCurrentOrgIdSelector } from "../../selectors/organisations/organizationAccountsByCurrentOrgIdSelector";
import {
  setCommitmentsRecommendationsAccountIds,
  setCommitmentsRecommendationsAccountScope,
} from "../../commitmentsSlice";
import { commitmentsRecommendationsAccountIdsSelector } from "../../selectors/recommendations/filters/commitmentsRecommendationsAccountIdsSelector";

export const checkForOrganizationExistenceThunk = createAsyncThunk(
  "commitments/checkForOrganizationExistence",
  async (_: void, { dispatch, getState }) => {
    const state = getState() as RootState;

    const accountIds = commitmentsRecommendationsAccountIdsSelector(state);

    if (accountIds?.length) {
      return;
    }

    const orgExists = connectedOrganizationExistsSelector(state);

    if (!orgExists) {
      const orgAccounts = organizationAccountsByCurrentOrgIdSelector(state);
      const ids = orgAccounts?.map((account) => account.id);

      if (ids?.length) {
        dispatch(setCommitmentsRecommendationsAccountIds(ids));
        dispatch(setCommitmentsRecommendationsAccountScope("linked"));
      }
    }
  },
);
