import { Stack } from "@mui/material";
import Typography from "@mui/material/Typography";
import { FC } from "react";
import { AlertsUtilizationThresholdsSelects } from "./AlertsUtilizationThresholdsSelects";
import { AlertsUtilizationAddThresholdButton } from "./AlertsUtilizationAddThresholdButton";
import { utilizationAlertThresholdTexts } from "../utils/constants/constants";
import { alertsUtilizationDataAlertTypeSelector } from "../../../../../../../../store/alerts/selectors/utilization-alerts/data/alertsUtilizationDataAlertTypeSelector";
import { useAppSelector } from "../../../../../../../../store/hooks";

export const AlertsUtilizationThresholdsSection: FC = () => {
  const utilizationAlertType = useAppSelector(
    alertsUtilizationDataAlertTypeSelector,
  );

  return (
    <Stack spacing={1.5}>
      <Stack spacing={0.25}>
        <Typography variant="body2" fontWeight="medium">
          {utilizationAlertThresholdTexts.get(utilizationAlertType)?.title}
        </Typography>
        <Typography variant="caption" color="text.secondary">
          {
            utilizationAlertThresholdTexts.get(utilizationAlertType)
              ?.description
          }
        </Typography>
      </Stack>

      <AlertsUtilizationThresholdsSelects
        utilizationAlertType={utilizationAlertType}
      />

      <AlertsUtilizationAddThresholdButton
        utilizationAlertType={utilizationAlertType}
      />
    </Stack>
  );
};
