import { FC, Fragment, memo, useCallback } from "react";
import { FormHelperText, Grid, Select, SelectChangeEvent } from "@mui/material";
import { selectMenuItem } from "./SelectMenuItem";
import { selectRenderValue } from "./selectRenderValue";
import { JiraSelectOptionsType } from "../../../../utils/types/types";

interface MultipleSelectFieldProps {
  name: string;
  error?: string;
  label: string;
  value?: string[];
  options: JiraSelectOptionsType;
  onChange(event: SelectChangeEvent<string[]>): void;
}

export const MultipleSelectField: FC<MultipleSelectFieldProps> = memo(
  ({ value, onChange, name, options, label, error }) => {
    const renderValue = useCallback(
      (selected: any) => selectRenderValue(selected, options),
      [options],
    );

    return (
      <Fragment>
        <Grid item sm={4}>
          <Select
            multiple
            fullWidth
            name={name}
            size="small"
            value={value || []}
            placeholder={label}
            onChange={onChange}
            renderValue={renderValue}
          >
            {options?.map((option) => selectMenuItem(option, value ?? null))}
          </Select>
          {!!error && <FormHelperText error>{error}</FormHelperText>}
        </Grid>
        <Grid item sm={6} />
      </Fragment>
    );
  },
);
