import { FC } from "react";
import { DialogActions, Stack } from "@mui/material";
import { LoadingButton } from "@mui/lab";
interface ResourceExplorerBillingExportDialogProcessStepActionsProps {
  onCloseDialog(): void;
}
export const ResourceExplorerBillingExportDialogProcessStepActions: FC<
  ResourceExplorerBillingExportDialogProcessStepActionsProps
> = ({ onCloseDialog }) => {
  return (
    <DialogActions sx={{ px: 4, my: 1 }}>
      <Stack direction="row" spacing={2}>
        <LoadingButton
          variant="contained"
          color="primary"
          onClick={onCloseDialog}
        >
          Done
        </LoadingButton>
      </Stack>
    </DialogActions>
  );
};
