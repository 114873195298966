import { FC } from "react";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { Tooltip } from "@mui/material";
import { blue } from "@mui/material/colors";

interface HeaderCellInfoIconProps {
  title: string;
}

export const HeaderCellInfoIcon: FC<HeaderCellInfoIconProps> = ({ title }) => {
  return (
    <Tooltip arrow title={title} placement="top">
      <HelpOutlineIcon
        fontSize="small"
        sx={{ verticalAlign: "middle", ml: 1, color: blue[500] }}
      />
    </Tooltip>
  );
};
