import Typography from "@mui/material/Typography";
import { FC } from "react";

export const IntegrationUtilizationAlertCoverageSlackHeader: FC = () => {
  return (
    <Typography variant="body1">
      Resource Type Coverage Below 50% -{" "}
      <Typography component="span" fontWeight="medium">
        {`{{Alert Name}}`}
      </Typography>
    </Typography>
  );
};
