import { createDraftSafeSelector } from "@reduxjs/toolkit";
import {
  cloudChiprApi,
  ProviderType,
  Region,
} from "../../../../../../services/cloudchipr.api";

const awsSelector =
  cloudChiprApi.endpoints.getProvidersByProviderRegions.select({
    provider: "aws",
  });

const gcpSelector =
  cloudChiprApi.endpoints.getProvidersByProviderRegions.select({
    provider: "gcp",
  });

const azureSelector =
  cloudChiprApi.endpoints.getProvidersByProviderRegions.select({
    provider: "azure",
  });

export const providersRegionsSelector = createDraftSafeSelector(
  [awsSelector, gcpSelector, azureSelector],
  (aws, gcp, azure): Record<ProviderType, Region[]> => {
    return {
      aws: aws.data ?? [],
      gcp: gcp.data ?? [],
      azure: azure.data ?? [],
    };
  },
);
