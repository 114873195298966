import { recommendationDismissedDataSelector } from "./recommendationsDataGridDimissedDataSelector";
import { RootState } from "../../../../store";
import { RecommendationGroupType } from "../../../../../components/pages/recommendations/components/off-hours-recommendations/utils/types/types";

export const recommendationsDataGridDismissedDataTotalLengthSelector = (
  state: RootState,
  group: RecommendationGroupType,
) => {
  const data = recommendationDismissedDataSelector(state, group);

  return data?.length ?? 0;
};
