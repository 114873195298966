import { RootState } from "../../../store";
import { ResourceType } from "../../../../services/cloudchipr.api";
import { getFirstParentInHierarchyResourceType } from "../../../../utils/helpers/resources/getFirstParentInHierarchyResourceType";
import { liveUsageMgmtResourceTypeDataSelector } from "../../../live-usage-mgmt/selectors/resource-type-data/liveUsageMgmtResourceTypeDataSelector";

export const liveFilteredResourcesEmsPriceSelector = (
  state: RootState,
  resourceType: ResourceType,
): number | null => {
  const data = liveUsageMgmtResourceTypeDataSelector(
    state,
    getFirstParentInHierarchyResourceType(resourceType) ?? resourceType,
  );

  return data?.estimated_monthly_waste ?? null;
};
