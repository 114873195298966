import { createAsyncThunk } from "@reduxjs/toolkit";
import { enqueueSnackbar } from "notistack";
import { cloudChiprApi } from "../../../../../services/cloudchipr.api";
import { dismissPeriodSelector } from "../../../selectors/dismiss/dismissPeriodSelector";
import { RootState } from "../../../../store";
import { recommendationDismissFixedCacheKey } from "../../../utils/constants/fixedCacheKeys";

export const dismissDailyOffHoursRecommendationThunk = createAsyncThunk(
  "recommendations/dismissDailyOffHoursRecommendationThunk",
  async (ids: string[], { dispatch, getState }) => {
    const state = getState() as RootState;

    const { patchUsersMeDailyOffHoursRecommendations } =
      cloudChiprApi.endpoints;

    const dismissPeriod = dismissPeriodSelector(state);

    if (!dismissPeriod) {
      return;
    }

    try {
      return await dispatch(
        patchUsersMeDailyOffHoursRecommendations.initiate(
          {
            body: {
              recommendation_ids: ids,
              dismiss_period: dismissPeriod,
            },
          },
          {
            fixedCacheKey: recommendationDismissFixedCacheKey,
          },
        ),
      ).unwrap();
    } catch (error: any) {
      enqueueSnackbar(error?.data?.message ?? "Something went wrong.", {
        variant: "snackbarAlert",
        AlertSnackBarProps: {
          severity: "error",
        },
      });
    }
  },
);
