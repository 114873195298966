import { FC, useCallback } from "react";
import { DropdownSelect } from "../../../../../../common/select/dropdown-select/DropdownSelect";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../store/hooks";
import { setAutomationFilters } from "../../../../../../../store/automations/automationsSlice";
import { automationsFrequenciesFilterOptionsSelector } from "../../../../../../../store/automations/selectros/workflow/filters/available/automationsFrequenciesFilterOptionsSelector";
import { capitalizeString } from "../../../../../../../utils/helpers/capitalizeString";

export const WorkflowFrequenciesFilter: FC = () => {
  const dispatch = useAppDispatch();

  const frequencies = useAppSelector(
    automationsFrequenciesFilterOptionsSelector,
  );

  const options = frequencies.map((frequency) => ({
    value: frequency,
    hideOnly: true,
    label: capitalizeString(frequency),
  }));

  const optionsCount = options.length;

  const changeHandler = useCallback(
    (newFilters: string[]) => {
      dispatch(
        setAutomationFilters({
          key: "frequencies",
          values: newFilters.length === optionsCount ? [] : newFilters,
        }),
      );
    },
    [dispatch, optionsCount],
  );

  return (
    <DropdownSelect
      showSearch={false}
      label="Frequencies"
      options={options}
      submitHandlerOnClose={changeHandler}
    />
  );
};
