import { LinearProgress, Stack } from "@mui/material";
import { Fragment } from "react";
import { SavingPlansRecommendationsCsvDataDownloader } from "./SavingPlansRecommendationsCsvDataDownloader";
import { UtilizationAndCoverageDataViewTabs } from "../../data-grid/components/UtilizationAndCoverageDataViewTabs";
import { GlobalFilter } from "../../../../../../../../storybook/data-grid/toolbar/GlobalFilter";
import { RenderToolbar } from "../../../../../../../../storybook/data-grid/utils/types/prop-types";
import { CommitmentsRecommendationsFilters } from "../filters/CommitmentsRecommendationsFilters";
import { useAppSelector } from "../../../../../../../../store/hooks";
import { commitmentsRecommendationsLoadingSelector } from "../../../../../../../../store/commitments/selectors/recommendations/data/commitmentsRecommendationsLoadingSelector";
import { ColumnsShowHideSwitcher } from "../../../../../common/components/data-grid/ColumnsShowHideSwitcher";

export const SavingPlansRecommendationsDataGridToolbar: RenderToolbar<
  unknown
> = ({ setGlobalFilter, globalFilter, table }) => {
  const loading = useAppSelector(commitmentsRecommendationsLoadingSelector);

  return (
    <Fragment>
      <Stack pb={2} spacing={2}>
        <UtilizationAndCoverageDataViewTabs />
        <Stack
          justifyContent="space-between"
          alignItems="center"
          direction="row"
          gap={2}
          flexWrap="wrap"
        >
          <CommitmentsRecommendationsFilters type="savings_plan" />
          <Stack direction="row" spacing={2}>
            <GlobalFilter
              globalFilter={globalFilter}
              setGlobalFilter={setGlobalFilter}
              size="xsmall"
            />

            <SavingPlansRecommendationsCsvDataDownloader />

            <ColumnsShowHideSwitcher table={table} />
          </Stack>
        </Stack>
      </Stack>

      {loading && <LinearProgress />}
    </Fragment>
  );
};
