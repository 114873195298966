import { FC } from "react";
import { Stack, Typography, Box } from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { UnusedSinceCell } from "./UnusedSinceCell";
import { UnusedSince } from "../../../../../../../../../../services/cloudchipr.api";

interface StateCellProps {
  data: UnusedSince;
}

export const StateCell: FC<StateCellProps> = ({ data }) => {
  return (
    <Stack direction="row" alignItems="center" spacing={1}>
      {data?.is_used ? (
        <CheckCircleOutlineIcon color="success" fontSize="small" />
      ) : (
        <RemoveCircleOutlineIcon color="action" fontSize="small" />
      )}

      <Box>
        <Typography variant="body2">
          {data?.is_used ? "Running" : "Stopped"}
        </Typography>
        {!data?.is_used && data?.timestamp && (
          <UnusedSinceCell data={data} variant="caption" />
        )}
      </Box>
    </Stack>
  );
};
