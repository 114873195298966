import { FC } from "react";
import { Typography } from "@mui/material";

export const EmailActionExecutedSubject: FC = () => {
  return (
    <Typography variant="body1">
      Subject:{" "}
      <Typography component="span" fontWeight="medium">
        Actions executed by automation workflow - {`{{workflow name}}`}
      </Typography>
    </Typography>
  );
};
