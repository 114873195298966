import { createDraftSafeSelector } from "@reduxjs/toolkit";
import { resourceExplorerHierarchyVisibilitiesDataSelector } from "./resourceExplorerHierarchyVisibilitiesDataSelector";
import {
  ReFolderV2,
  ResourceExplorer,
} from "../../../../services/cloudchipr.api";
import { isHierarchyFolder } from "../../../navigation/utils/helpers/isHierarchyFolder";

export const resourceExplorerHierarchyVisibilitiesMergedDataItemsSelector =
  createDraftSafeSelector(
    [resourceExplorerHierarchyVisibilitiesDataSelector],
    (data): ResourceExplorer[] | undefined => {
      const privateData = data?.visible_only_to_me;
      const publicData = data?.visible_to_everyone;
      const privateDataItems = formatData(privateData);
      const publicDataItems = formatData(publicData);
      return publicDataItems.concat(privateDataItems);
    },
  );

const formatData = (data?: (ResourceExplorer | ReFolderV2)[]) => {
  return (
    data?.reduce((result, item) => {
      if (!isHierarchyFolder(item)) {
        result.push(item);
      } else if (item.items) {
        result = result.concat(item.items);
      }
      return result;
    }, [] as ResourceExplorer[]) ?? []
  );
};
