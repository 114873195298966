import { navigationResourceExplorerPublicDataItemsSelector } from "./navigationResourceExplorerPublicDataItemsSelector";
import { navigationResourceExplorerPrivateDataItemsSelector } from "./navigationResourceExplorerPrivateDataItemsSelector";
import { RootState } from "../../../store";
import { NavigationItemsVisibilityType } from "../../utils/types";

export const navigationResourceExplorerVisibilitySelectedSelector = (
  state: RootState,
  visibility: NavigationItemsVisibilityType,
  id?: string,
): boolean | undefined => {
  let data;
  if (visibility === "visible_to_everyone") {
    data = navigationResourceExplorerPublicDataItemsSelector(state);
  } else {
    data = navigationResourceExplorerPrivateDataItemsSelector(state);
  }

  if (!data) {
    return;
  }
  return data.some((item) => item.id === id);
};
