import { FC } from "react";
import { ForecastedCostHeaderCell } from "../../../../../../../../../resource-explorer/components/resource-explorer-card/components/resource-explorer-data-grid/components/columns/components/common/ForecastedCostHeaderCell";
import { useAppSelector } from "../../../../../../../../../../../store/hooks";
import { costBreakdownWidgetSetupForecastedCostsSumSelector } from "../../../../../../../../../../../store/dashboards/selectors/widgets/cost-breakdown/setup/costBreakdownWidgetSetupForecastedCostsSumSelector";
import { costBreakdownWidgetSetupForecastedOptionSelector } from "../../../../../../../../../../../store/dashboards/selectors/widgets/cost-breakdown/setup/costBreakdownWidgetSetupForecastedOptionSelector";

export const CostBreakdownWidgetSetupTableForecastedCostHeaderCell: FC = () => {
  const forecastedCost = useAppSelector(
    costBreakdownWidgetSetupForecastedCostsSumSelector,
  );
  const forecastedOption = useAppSelector(
    costBreakdownWidgetSetupForecastedOptionSelector,
  );

  if (!forecastedOption) {
    return null;
  }
  return (
    <ForecastedCostHeaderCell
      forecastOption={forecastedOption}
      forecastedCost={forecastedCost}
    />
  );
};
