import { FC } from "react";
import { Button, DialogActions } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useAppAbility } from "../../../../../../../services/permissions";
import { useAppSelector } from "../../../../../../../store/hooks";
import { someActionIsLoadingOnDimensionsSelector } from "../../../../../../../store/dimensions/selectors/options/someActionIsLoadingOnDimensionsSelector";

interface FormActionsProps {
  onCancel(): void;
  onSave(): void;
  disabled: boolean;
}

export const FormActions: FC<FormActionsProps> = ({
  disabled,
  onSave,
  onCancel,
}) => {
  const { cannot } = useAppAbility();
  const canNotCreateCategory = cannot("create", "category");
  const canNotEditCategory = cannot("edit", "category");

  const loading = useAppSelector(someActionIsLoadingOnDimensionsSelector);

  return (
    <DialogActions
      sx={{
        p: 2,
        bottom: 0,
        bgcolor: "white",
        position: "sticky",
        zIndex: "appBar",
        justifyContent: "flex-start",
      }}
    >
      <LoadingButton
        type="button"
        disabled={disabled || canNotCreateCategory || canNotEditCategory}
        variant="contained"
        onClick={onSave}
        loading={loading}
      >
        Save
      </LoadingButton>

      <Button onClick={onCancel} color="tertiary">
        Cancel
      </Button>
    </DialogActions>
  );
};
