import { resourceExplorerPathSelector } from "./resourceExplorerPathSelector";
import { RootState } from "../../../../store";
import { ResourceExplorerGrouping } from "../../../../../services/cloudchipr.api";

export const resourceExplorerPathLastItemGroupingOptionsSelector = (
  state: RootState,
): ResourceExplorerGrouping[] | undefined => {
  const path = resourceExplorerPathSelector(state);
  const lastItem = path?.at(-1);

  return lastItem?.groupingOptions?.filter((option) => {
    return (
      path.every(({ groupedBy }) => groupedBy !== option) ||
      option === lastItem.groupedBy
    );
  });
};
