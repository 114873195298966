import { createAsyncThunk } from "@reduxjs/toolkit";
import { enqueueSnackbar } from "notistack";
import { getWorkflowsThunk } from "./getWorkflowsThunk";
import { cloudChiprApi } from "../../../../services/cloudchipr.api";
import { workflowPayloadDataSelector } from "../../selectros/workflow/data/workflowPayloadDataSelector";
import { automationIdSelector } from "../../selectros/common/automationIdSelector";
import { RootState } from "../../../store";
import {
  createAutomationFixedCacheKey,
  updateAutomationFixedCacheKey,
} from "../../utils/constants/fixedCacheKeys";
import { validateAutomationDataThunk } from "../validateAutomationDataThunk";
import { setAutomationStep } from "../../automationsSlice";

export const saveWorkflowThunk = createAsyncThunk(
  "automation/saveWorkflow",
  async (_: void, { dispatch, getState }) => {
    const state = getState() as RootState;

    const { postUsersMeSchedules, patchUsersMeSchedulesByScheduleId } =
      cloudChiprApi.endpoints;

    const errors = await dispatch(validateAutomationDataThunk()).unwrap();

    if (errors) {
      dispatch(setAutomationStep("setup"));
      return;
    }

    const automationId = automationIdSelector(state);
    const payload = workflowPayloadDataSelector(state);

    try {
      let response;
      if (automationId) {
        response = await dispatch(
          patchUsersMeSchedulesByScheduleId.initiate(
            {
              scheduleId: automationId,
              body: payload,
            },
            { fixedCacheKey: updateAutomationFixedCacheKey },
          ),
        ).unwrap();
      } else {
        response = await dispatch(
          postUsersMeSchedules.initiate(
            {
              body: payload,
            },
            { fixedCacheKey: createAutomationFixedCacheKey },
          ),
        ).unwrap();
      }

      await dispatch(getWorkflowsThunk(true)).unwrap();

      return response;
    } catch (e) {
      // @ts-expect-error todo: fix api spec
      const errMessage = e?.data?.message || "Something went wrong";
      enqueueSnackbar(errMessage, {
        variant: "snackbarAlert",
        AlertSnackBarProps: {
          severity: "error",
        },
      });
    }
  },
);
