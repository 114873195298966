import { FC, useCallback } from "react";
import SettingsInputHdmiOutlinedIcon from "@mui/icons-material/SettingsInputHdmiOutlined";
import {
  Box,
  IconButton,
  List,
  MenuItem,
  Popover,
  Stack,
  Typography,
} from "@mui/material";
import {
  Integration,
  useGetUsersMeIntegrationsQuery,
} from "../../../../../../../services/cloudchipr.api";
import { useMenuHook } from "../../../../../../../utils/hooks/useMenu.hook";
import { IntegrationLogo } from "../../../common/IntegrationLogo";
import { useAppDispatch } from "../../../../../../../store/hooks";
import { getIntegrationByIdThunk } from "../../../../../../../store/integrations/thunks/getIntegrationByIdThunk";

interface SelectFromEmailIntegrationsProps {
  selectedEmails: string[];
  allFieldsSelectedEmails: string[];
  onChange(metadata: string[]): void;
}

export const SelectFromEmailIntegrations: FC<
  SelectFromEmailIntegrationsProps
> = ({ onChange, selectedEmails, allFieldsSelectedEmails }) => {
  const dispatch = useAppDispatch();
  const { anchor, open, openMenu, closeMenu } = useMenuHook();

  const { data: integrations } = useGetUsersMeIntegrationsQuery({
    type: "email",
  });

  const selectHandler = useCallback(
    (id: string) => {
      dispatch(getIntegrationByIdThunk({ id: id, type: "email" })).then(
        (response: any) => {
          const data = response?.payload?.data as Integration;

          const emails = data.emails ?? [];

          onChange([...(selectedEmails ?? []), ...emails]);
          closeMenu();
        },
      );
    },
    [dispatch, onChange, selectedEmails, closeMenu],
  );

  const isDisabled = useCallback(
    (emails?: string[]) =>
      !!emails?.every((email) => allFieldsSelectedEmails?.includes(email)),
    [allFieldsSelectedEmails],
  );

  if (!integrations?.length) {
    return null;
  }

  return (
    <Box alignItems="end" position="absolute" right={4} bottom={4}>
      <IconButton size="small" onClick={openMenu}>
        <SettingsInputHdmiOutlinedIcon color="primary" />
      </IconButton>

      <Popover
        open={open}
        anchorEl={anchor}
        onClose={closeMenu}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Stack spacing={2} maxHeight={600}>
          <Typography
            px={2}
            pt={1}
            variant="caption"
            fontWeight="medium"
            color="text.secondary"
          >
            AUTOFILL FROM INTEGRATIONS
          </Typography>

          <List sx={{ mt: "0 !important" }}>
            {integrations?.map(({ name, id, metadata }) => {
              return (
                <MenuItem
                  key={id}
                  disabled={isDisabled(metadata?.emails)}
                  sx={{ cursor: "pointer" }}
                  onClick={selectHandler.bind(null, id)}
                >
                  <IntegrationLogo type="email" width={20} />
                  <Typography variant="body2" pl={1}>
                    {name}
                  </Typography>
                </MenuItem>
              );
            })}
          </List>
        </Stack>
      </Popover>
    </Box>
  );
};
