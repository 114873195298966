import { FC, Fragment, useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import { ConfirmationDialog } from "../../../../../../common/modals/ConfirmationDialog";
import { useDialog } from "../../../../../../../utils/hooks/useDialog.hook";
import { useAppSelector } from "../../../../../../../store/hooks";
import { automationHasChangesSelector } from "../../../../../../../store/automations/selectros/common/automationHasChangesSelector";
import { automationInDrawerOpenSelector } from "../../../../../../../store/automations/selectros/common/automationInDrawerOpenSelector";

export const ActionsCancelButton: FC = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { open, openDialog, closeDialog } = useDialog();

  const changesExist = useAppSelector(automationHasChangesSelector);
  const inDrawerMode = useAppSelector(automationInDrawerOpenSelector);

  const handleDiscardClick = useCallback(() => {
    const pathname = location.pathname.split("/");

    navigate(`/${pathname[1]}/${pathname[2]}`);
  }, [navigate, location]);

  const handleCancelClick = useCallback(() => {
    if (changesExist) {
      openDialog();
      return;
    }

    handleDiscardClick();
  }, [handleDiscardClick, changesExist, openDialog]);

  if (inDrawerMode) {
    return <div />;
  }

  return (
    <Fragment>
      <Button onClick={handleCancelClick} color="tertiary">
        Cancel
      </Button>

      <ConfirmationDialog
        title="Discard Unsaved Changes?"
        dialogContent="The changes you made will not be saved."
        open={open}
        CancelButtonProps={{
          onClick: closeDialog,
        }}
        ConfirmButtonProps={{
          children: "Discard",
          onClick: handleDiscardClick,
        }}
        onClose={closeDialog}
      />
    </Fragment>
  );
};
