import { createAsyncThunk } from "@reduxjs/toolkit";
import { cloudChiprApi } from "../../../../services/cloudchipr.api";
import { generateApplyFilterSetToAccountFixedCacheKey } from "../../selectors/filter-set/utils/helpers/fixedCacheKeyGenerator";

type Args = {
  accountIds: string[];
  filterSetId?: string;
};

export const applyFilterTemplateToAccountsThunk = createAsyncThunk(
  "filter/applyFilterSetToAccountsThunk",
  async ({ accountIds, filterSetId }: Args, { dispatch }) => {
    const { postUsersMeAccountsByAccountIdFiltersV2 } = cloudChiprApi.endpoints;

    const requests = accountIds.map((accountId) => {
      return dispatch(
        postUsersMeAccountsByAccountIdFiltersV2.initiate(
          {
            accountId,
            body: { filter_set_id: filterSetId },
          },
          {
            fixedCacheKey:
              generateApplyFilterSetToAccountFixedCacheKey(filterSetId),
          },
        ),
      );
    });

    await Promise.all(requests);
  },
);
