import { FC, Fragment, memo, useCallback, useMemo } from "react";
import { FormikHandlers, FormikHelpers } from "formik";
import { useDidMount } from "rooks";
import { Grid } from "@mui/material";
import { FilterItemOperatorType } from "../FilterItemOperatorType";
import {
  FilterItem,
  Operator,
  ResourceType,
  usePostUsersMeAccountsByTypeResourcesMutation,
} from "../../../../../../services/cloudchipr.api";
import { AutocompleteSelect } from "../../../../../common/select/autocomplete-select/AutocompleteSelect";
import { DropdownSelectOption } from "../../../../../common/select/dropdown-select/utils/types/types";

interface UniqueIdFilterProps {
  operators?: Operator[];
  filter: FilterItem;
  resourceType: ResourceType;
  accountIds: string[];
  error?: any;
  onChange: FormikHandlers["handleChange"];
  setFieldValue: FormikHelpers<FilterItem>["setFieldValue"];
}

export const UniqueIdFilter: FC<UniqueIdFilterProps> = memo(
  ({
    operators,
    filter,
    setFieldValue,
    accountIds,
    resourceType,
    onChange,
    error,
  }) => {
    const [trigger, { data, isLoading }] =
      usePostUsersMeAccountsByTypeResourcesMutation();

    const options = useMemo(() => {
      let renderOptions: DropdownSelectOption[] = [];
      const selectedIds = filter.value || [];
      const ids: string[] = [];

      data?.forEach(
        ({ provider_account_id, provider_account_name, resources }) => {
          resources.forEach(({ provider_unique_identifier }) => {
            ids.push(provider_unique_identifier);

            renderOptions.push({
              groupName: provider_account_name || provider_account_id,
              value: provider_unique_identifier,
              label: provider_unique_identifier,
            });
          });
        },
      );

      renderOptions = sortOptions(renderOptions);

      const removedIds = [selectedIds]
        .flat()
        .filter((id) => !ids.includes(id as string)) as string[];

      if (!removedIds.length) {
        return renderOptions;
      }

      return renderOptions.map((option) => {
        if (!removedIds.includes(option.value)) {
          return option;
        }

        return {
          ...option,
          disabled: true,
          groupName: "Removed",
        };
      });
    }, [data, filter.value]);

    const valueChangeHandler = useCallback(
      (arr: string[]) => setFieldValue("value", arr),
      [setFieldValue],
    );

    useDidMount(() => {
      trigger({
        type: resourceType,
        body: { account_ids: accountIds },
      });
    });

    return (
      <Fragment>
        <FilterItemOperatorType
          value={filter.operator || "in"}
          options={operators}
          onChange={onChange}
        />

        <Grid item md={7}>
          <AutocompleteSelect
            size="xsmall"
            error={!!error}
            label="Provider Unique ID"
            placeholder="Provider Unique ID"
            allSelectedLabel="Instances"
            options={options}
            optionsLoading={isLoading}
            onChange={valueChangeHandler}
            initialSelectedValues={(filter.value || []) as string[]}
          />
        </Grid>
      </Fragment>
    );
  },
);

const sortOptions = (options: DropdownSelectOption[]) => {
  return [...options]?.sort((a, b) => {
    const current = a?.groupName?.toLowerCase() ?? 0;
    const next = b?.groupName?.toLowerCase() ?? 0;

    if (current < next) {
      return -1;
    }
    if (current > next) {
      return 1;
    }

    return 0;
  });
};
