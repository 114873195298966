import { createDraftSafeSelector } from "@reduxjs/toolkit";
import { costBreakdownWidgetCostsSelector } from "./costBreakdownWidgetCostsSelector";
import { calculateTotalCostTrend } from "../../../../../../components/pages/utils/helpers/calculateTotalCostTrend";

export const costBreakdownWidgetTableTrendSelector = createDraftSafeSelector(
  [costBreakdownWidgetCostsSelector],
  (costs): number | null => {
    const total = costs?.total.cost ?? 0;
    const previous = costs?.previousPeriodTotal?.cost ?? 0;

    const trend = calculateTotalCostTrend(total, previous);

    return previous > 0 ? trend : null;
  },
);
