import { FC, useMemo } from "react";
import Typography from "@mui/material/Typography";
import { IconButton, Stack, Tooltip } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { ResourceExplorerForecastedCostsPopover } from "./ResourceExplorerForecastedCostsPopover";
import { money } from "../../../../../../../../../utils/numeral/money";
import { TrendChip } from "../../../../../../../../common/chips/TrendChip";
import {
  ForecastedCostRe,
  ForecastOption,
} from "../../../../../../../../../services/cloudchipr.api";
import { useMenuHook } from "../../../../../../../../../utils/hooks/useMenu.hook";
import { forecastedCostOptionLabels } from "../utils/constants";
import { getTrendTooltipTitle } from "../../../../../../../dashboard/utils/helpers/trend-tooltip-title/getTrendTooltipTitle";
import { ForecastedType } from "../../../../../../../dashboard/utils/types/types";
import { formatDate } from "../../../../../../../../../utils/helpers/date-time/datetime-format";

interface ResourceExplorerForecastedCostsProps {
  forecastedCosts?: ForecastedCostRe;
  selectedForecastOption: ForecastOption;
  onForecastChange?(forecastOption: ForecastOption): void;
  showTrend?: boolean;
}
export const ResourceExplorerForecastedCosts: FC<
  ResourceExplorerForecastedCostsProps
> = ({
  forecastedCosts,
  selectedForecastOption,
  onForecastChange,
  showTrend = true,
}) => {
  const { anchor, open, openMenu, closeMenu } = useMenuHook();

  const selectedForecast = useMemo(() => {
    return forecastedCosts?.find(
      (item) => item.forecast_option === selectedForecastOption,
    );
  }, [forecastedCosts, selectedForecastOption]);

  const forecastedType: ForecastedType =
    selectedForecastOption === "month"
      ? "forecasted_month_end_cost"
      : "forecasted_quarter_end_cost";

  const trendTooltipTitle = getTrendTooltipTitle(
    selectedForecast?.date_from,
    selectedForecast?.date_to,
    selectedForecast?.previous_period_from,
    selectedForecast?.previous_period_to,
    forecastedType,
  );

  const fromDate = formatDate(selectedForecast?.date_from ?? "", {
    type: "date",
  });
  const toDate = formatDate(selectedForecast?.date_to ?? "", { type: "date" });
  const withPopover = !!onForecastChange;
  return (
    <Stack px={1}>
      <Stack
        direction="row"
        spacing={withPopover ? 0.5 : 1}
        alignItems="center"
      >
        <Typography
          variant="subtitle2"
          color="text.secondary"
          fontWeight="regular"
          whiteSpace="nowrap"
        >
          Forecasted {forecastedCostOptionLabels.get(selectedForecastOption)}{" "}
          Cost
        </Typography>

        {withPopover && (
          <IconButton onClick={openMenu} size="small">
            {open ? (
              <ArrowDropUpIcon fontSize="small" />
            ) : (
              <ArrowDropDownIcon fontSize="small" />
            )}
          </IconButton>
        )}

        <Tooltip
          arrow
          placement="top"
          title={`Forecasted Cost from ${fromDate} to ${toDate}`}
        >
          <InfoOutlinedIcon color="action" sx={{ fontSize: 16 }} />
        </Tooltip>
      </Stack>

      <Stack
        spacing={1}
        direction="row"
        justifyContent="end"
        alignItems="center"
      >
        <Typography variant="h6" fontWeight="medium" mr={1}>
          ~{money(selectedForecast?.cost)}
        </Typography>

        {showTrend && (
          <TrendChip
            value={selectedForecast?.trend}
            tooltipTitle={trendTooltipTitle}
          />
        )}
      </Stack>

      {withPopover && (
        <ResourceExplorerForecastedCostsPopover
          open={open}
          anchor={anchor}
          closeMenu={closeMenu}
          options={forecastedCosts}
          onForecastChange={onForecastChange}
          selectedForecastOption={selectedForecastOption}
        />
      )}
    </Stack>
  );
};
