import { providerDisabledTooltipTitleCombiner } from "./providerDisabledTooltipTitleCombiner";
import { RootState } from "../../../../../store";
import { awsNonDeletedAndNotBillingAccountsSelector } from "../../../../../accounts/selectors/aws/awsNonDeletedAndNotBillingAccountsSelector";
import { automationTypeSelector } from "../../automationTypeSelector";

export const awsDisabledTooltipTitleSelector = (state: RootState) => {
  const accounts = awsNonDeletedAndNotBillingAccountsSelector(state);
  const automationType = automationTypeSelector(state);

  return providerDisabledTooltipTitleCombiner(
    automationType === "offHours",
    "aws",
    accounts,
  );
};
