import {
  ResourceExplorer,
  ResourceExplorerFilterSchema,
  ResourceExplorerGrouping,
  ResourceExplorerWidget,
} from "../../../../../services/cloudchipr.api";
import { ResourceExplorerGridMutatedData } from "../../../../../store/resource-explorer/utils/types/resourceExplorerDataGrid";

export const getInternalResourceExplorerFilterSchema = (
  view: ResourceExplorer,
): ResourceExplorerFilterSchema => {
  return {
    dates: view.data?.dates,

    group_by: view.data?.grouping,
    group_values: view.data?.group_values,

    data_point: view.data?.data_point ?? "monthly",

    load_monthly_forecast_for_grouping_items: true,
    load_quarterly_forecast_for_grouping_items: true,

    trend_type: view.data?.trend_type,
    cost_type: view.data?.cost_type,

    filter_tree: view.filter_tree,
  };
};

export const getResourceExplorerMutatedData = (
  widgetDataResponse: ResourceExplorerWidget,
  grouping: ResourceExplorerGrouping,
) => {
  const data = widgetDataResponse?.total;
  if (!data) {
    return {} as ResourceExplorerGridMutatedData;
  }

  return (
    data?.reduce(
      (result, item, index) => {
        if (item.metadata.aggregated && item.metadata.aggregated_by_cost) {
          result.aggregatedData = item;
          result.dataCount = data?.length ? data?.length - 1 : 0;
        } else {
          result.gridData.push({
            ...item,
            id: item.field + index,
            resourceExplorerGrouping: grouping,
          });

          result.totalCostSum = (result.totalCostSum ?? 0) + item.total_cost;

          if (item?.previous_period_total_cost !== null) {
            if (result.prevPeriodTotalCostSum === null) {
              result.prevPeriodTotalCostSum = item?.previous_period_total_cost;
            } else {
              result.prevPeriodTotalCostSum =
                (result.prevPeriodTotalCostSum ?? 0) +
                item?.previous_period_total_cost;
            }
          }

          result.dataCount = data?.length;
        }
        return result;
      },
      {
        gridData: [],
      } as ResourceExplorerGridMutatedData,
    ) ?? ({} as ResourceExplorerGridMutatedData)
  );
};
