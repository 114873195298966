import { forwardRef, ReactNode } from "react";
import { SnackbarContent, CustomContentProps } from "notistack";
import {
  Alert,
  AlertColor,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import TaskAltIcon from "@mui/icons-material/TaskAlt";

interface SnackbarAlertProps extends CustomContentProps {
  AlertSnackBarProps?: {
    severity?: AlertColor;
    action?: ReactNode;
    footer?: ReactNode;

    onClose?(): void;
    icon?: false | ReactNode;
    description?: string | ReactNode;
    fullComponent?: ReactNode;
  };
}

const SnackbarAlertIcons = {
  warning: <WarningAmberIcon color="warning" />,
  error: <ErrorOutlineOutlinedIcon color="error" />,
  info: <InfoOutlinedIcon color="info" />,
  success: <TaskAltIcon color="success" />,
};

export const SnackbarAlert = forwardRef<HTMLDivElement, SnackbarAlertProps>(
  (props, ref) => {
    const {
      severity,
      action,
      footer,
      onClose,
      icon,
      description,
      fullComponent,
    } = props.AlertSnackBarProps || {};
    const snackbarAlertIcon = SnackbarAlertIcons[severity || "success"];

    if (fullComponent) {
      return (
        <SnackbarContent ref={ref} role="alert">
          {fullComponent}
        </SnackbarContent>
      );
    }

    return (
      <SnackbarContent ref={ref} role="alert">
        <Alert variant="snackbar" severity={severity} icon={false}>
          <Stack spacing={1}>
            <Stack
              spacing={1}
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Stack direction="row" spacing={1} alignItems="center">
                {icon !== false && (icon || snackbarAlertIcon)}

                {props.message && (
                  <Typography
                    variant="body2"
                    fontWeight="bold"
                    color="text.primary"
                  >
                    {props.message}
                  </Typography>
                )}

                {action}
              </Stack>

              {onClose && (
                <IconButton onClick={onClose} size="small">
                  <CloseIcon />
                </IconButton>
              )}
            </Stack>

            {description || (
              <Typography variant="body2" color="text.primary" mr={5}>
                {description}
              </Typography>
            )}
            {footer}
          </Stack>
        </Alert>
      </SnackbarContent>
    );
  },
);
