import moment from "moment";

export type TimeUnit = "h" | "d";
export const HourMultiplier = 3_600;
export const DayMultiplier = 24 * HourMultiplier;

export const secondsToDuration = (value: string | number): string => {
  const duration = moment.duration(value, "seconds");
  const asDays = duration.asDays();

  if (asDays % 1 === 0) {
    return asDays + "d";
  }

  return duration.asHours() + "h";
};

export const durationToNow = (age: number): string | number => {
  const dateFrom = moment.utc().subtract(age, "seconds").format();
  return moment.utc(dateFrom).fromNow(true);
};

export const age = (createDate: string): number => {
  return moment.utc().diff(moment.utc(createDate), "seconds");
};
