import { FC } from "react";
import { CostAnomalyPreviewTableHeaderCell } from "./CostAnomalyPreviewTableHeaderCell";
import { getResourcesExplorerGroupingLabel } from "../../../../../../../../../resource-explorer/components/resource-explorer-card/utils/helpers/getResourcesExplorerGroupingLabel";
import { useAppSelector } from "../../../../../../../../../../../store/hooks";
import { alertsCostAnomalyDataGroupingSelector } from "../../../../../../../../../../../store/alerts/selectors/cost-anomaly-alerts/data/alertsCostAnomalyDataGroupingSelector";
import { costAnomalyPreviewDataGridDataLengthSelector } from "../../../../../../../../../../../store/alerts/selectors/cost-anomaly-alerts/costAnomalyPreviewDataGridDataLengthSelector";
import { connectedProvidersSelector } from "../../../../../../../../../../../store/common/selectors/connectedProvidersSelector";

export const CostAnomalyPreviewFieldHeaderCell: FC = () => {
  const count = useAppSelector(costAnomalyPreviewDataGridDataLengthSelector);
  const groupBy = useAppSelector(alertsCostAnomalyDataGroupingSelector);
  const providers = useAppSelector(connectedProvidersSelector);

  return (
    <CostAnomalyPreviewTableHeaderCell
      title={getResourcesExplorerGroupingLabel(groupBy, false, providers)}
      description={count?.toString() ?? "0"}
    />
  );
};
