import { FilterFns } from "@tanstack/table-core";
import { FilterFn } from "@tanstack/react-table";
import { commonDefaultFilter, defaultFilter } from "./defaultFilter";
import { strictEqualityFilter } from "./strictEqualityFilter";
import { commonDateFilter, dateFilter } from "./dateFilter";
import { commonMoneyFilter, moneyFilter } from "./moneyFilter";
import { commonCpuFilter, cpuFilter } from "./cpuFilter";
import { commonTagFilter, tagFilter } from "./tagFilter";
import { accountDataFilter } from "./accountDataFilter";
import { childResourcesDataFilter } from "./childResourcesDataFilter";
import { labelFilter } from "./labelFilter";
import { createdByFilter } from "./createdByFilter";
import { TableCellTypes } from "../types/types";

export const filterFnByCellType: Map<TableCellTypes, keyof FilterFns> = new Map(
  [
    ["tag", "tagFilter"],
    ["smartTag", "tagFilter"],
    ["money", "moneyFilter"],
    ["date", "dateFilter"],
    ["cpuStatistic", "cpuFilter"],
    ["account", "accountDataFilter"],
    ["createdBy", "createdByFilter"],
  ],
);

export const customFilterFnByCellType: Map<TableCellTypes, CustomFilterFns> =
  new Map([
    ["tag", "commonTagFilter"],
    ["money", "commonMoneyFilter"],
    ["date", "commonDateFilter"],
    ["cpuStatistic", "commonCpuFilter"],
  ]);
export const normalizeString = (value: string): string => {
  value = value || "";
  return `${value}`.trim().toLowerCase();
};

const globalFilter: FilterFn<any> = (...props) => {
  const row = props[0];
  const columnId = props[1];
  const allColumns = row._getAllCellsByColumnId();
  const column = allColumns?.[columnId]?.column;
  const columnFilterFn = column.columnDef.filterFn;

  if (columnFilterFn && typeof columnFilterFn === "function") {
    return columnFilterFn(...props);
  }

  return (
    defaultFilter(...props) ||
    labelFilter(...props) ||
    strictEqualityFilter(...props) ||
    dateFilter(...props) ||
    moneyFilter(...props) ||
    cpuFilter(...props) ||
    tagFilter(...props) ||
    accountDataFilter(...props) ||
    createdByFilter(...props) ||
    childResourcesDataFilter(...props)
  );
};

export const filterFns = {
  tagFilter,
  defaultFilter,
  strictEqualityFilter,
  moneyFilter,
  dateFilter,
  cpuFilter,
  globalFilter,
  accountDataFilter,
  createdByFilter,
  labelFilter,
};

export const customFilterFn = {
  commonTagFilter,
  commonDefaultFilter,
  commonMoneyFilter,
  commonDateFilter,
  commonCpuFilter,
};

type CustomFilterFns =
  | "commonTagFilter"
  | "commonCpuFilter"
  | "commonMoneyFilter"
  | "commonDateFilter";
