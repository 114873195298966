import { FC } from "react";
import {
  Divider,
  List,
  ListItem,
  ListSubheader,
  Typography,
} from "@mui/material";
import { TypographyWithCopy } from "../../../../../../../../../../common/TypographyWithCopy";

interface NatGatewayIdCellPopoverContentProps {
  primaryValue: string;
  columnKey: string;
  secondaryValues: string[];
}

export const NatGatewayIdCellPopoverContent: FC<
  NatGatewayIdCellPopoverContentProps
> = ({ secondaryValues, columnKey, primaryValue }) => {
  return (
    <List sx={{ pt: 0, pb: 1 }}>
      <ListSubheader sx={subHeaderSx}>
        <Typography
          variant="caption"
          fontWeight="bold"
          mt={2}
          color="text.disabled"
          fontSize={10}
        >
          {subTitles[columnKey]?.primary}
        </Typography>
      </ListSubheader>
      <ListItem sx={listItemSx}>
        <TypographyWithCopy value={primaryValue} variant="caption" />
      </ListItem>
      <Divider />
      <ListSubheader sx={subHeaderSx}>
        <Typography
          variant="caption"
          fontWeight="bold"
          mt={2}
          color="text.disabled"
          fontSize={10}
        >
          {subTitles[columnKey]?.secondary}
        </Typography>
      </ListSubheader>
      {secondaryValues?.map((value) => {
        return (
          <ListItem sx={listItemSx} key={value}>
            <TypographyWithCopy value={value} variant="caption" />
          </ListItem>
        );
      })}
    </List>
  );
};

const subTitles: Record<string, { primary: string; secondary: string }> = {
  public_addresses: {
    primary: "PRIMARY PUBLIC IPv4 ADDRESS",
    secondary: "SECONDARY PUBLIC IPv4 ADDRESSES",
  },
  private_addresses: {
    primary: "PRIMARY PRIVATE IPV4 ADDRESS",
    secondary: "SECONDARY PRIVATE IPV4 ADDRESSES",
  },
  network_interface_ids: {
    primary: "PRIMARY NETWORK INTERFACE ID",
    secondary: "SECONDARY NETWORK INTERFACE IDs",
  },
};

const listItemSx = {
  px: 1.5,
  pb: 1.5,
  pt: 0,
};

const subHeaderSx = {
  lineHeight: 1,
  pt: 1,
  px: 1.5,
  pb: 1,
};
