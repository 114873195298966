import { FC, useCallback } from "react";
import { Button } from "@mui/material";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Refresh } from "@mui/icons-material";
import { getExecutionLogThunk } from "../../../../store/execution-log/thunks/getExecutionLogThunk";
import { useExecutionLogPathIds } from "../utils/hooks/useExecutionLogPathIds.hook";

export const ExecutionLogsShowAll: FC = () => {
  const executionLogIds = useExecutionLogPathIds();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleClick = useCallback(() => {
    dispatch(getExecutionLogThunk({}));
    navigate("/execution-log", {
      replace: true,
    });
  }, [dispatch, navigate]);

  if (!executionLogIds) {
    return;
  }

  return (
    <Button
      size="small"
      variant="text"
      sx={buttonStyles}
      onClick={handleClick}
      startIcon={<Refresh />}
    >
      Show All
    </Button>
  );
};

const buttonStyles = {
  textTransform: "none",
  "& .MuiButton-startIcon": {
    mr: 0.5,
  },
};
