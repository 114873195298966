import { RootState } from "../../../store";
import { cloudChiprApi } from "../../../../services/cloudchipr.api";
import { recommendationRestoreFixedCacheKey } from "../../utils/constants/fixedCacheKeys";

const {
  patchUsersMeOrganisationsCurrentRecommendationsRightSizing,
  patchUsersMeOrganisationsCurrentRecommendationsDailyOffHours,
  patchUsersMeOrganisationsCurrentRecommendationsWeeklyOffHours,
} = cloudChiprApi.endpoints;

const rightsizingSelector =
  patchUsersMeOrganisationsCurrentRecommendationsRightSizing.select(
    recommendationRestoreFixedCacheKey,
  );
const dailySelector =
  patchUsersMeOrganisationsCurrentRecommendationsDailyOffHours.select(
    recommendationRestoreFixedCacheKey,
  );
const weeklySelector =
  patchUsersMeOrganisationsCurrentRecommendationsWeeklyOffHours.select(
    recommendationRestoreFixedCacheKey,
  );

export const recommendationRestoringLoadingSelector = (state: RootState) => {
  const rightsizingLoading = rightsizingSelector(state)?.isLoading;
  const dailyLoading = dailySelector(state)?.isLoading;
  const weeklyLoading = weeklySelector(state)?.isLoading;

  return !!(rightsizingLoading || dailyLoading || weeklyLoading);
};
