import { FC, useCallback } from "react";
import { CustomCellRendererProps } from "@ag-grid-community/react";
import { Stack } from "@mui/material";
import { Task } from "../../../../../../services/cloudchipr.api";
import { TypographyWithTooltip } from "../../../../../common/TypographyWithTooltip";
import { openDrawerForTaskByIdThunk } from "../../../../../../store/task-management/thunks/openDrawerForTaskByIdThunk";
import { useAppDispatch } from "../../../../../../store/hooks";
import { useAppAbility } from "../../../../../../services/permissions";

export const NameCellRenderer: FC<CustomCellRendererProps<Task>> = ({
  data,
}) => {
  const dispatch = useAppDispatch();
  const { can } = useAppAbility();
  const canEditTask = can("edit", "task");

  const clickHandler = useCallback(() => {
    if (!data?.id) {
      return;
    }

    dispatch(openDrawerForTaskByIdThunk(data?.id));
  }, [dispatch, data?.id]);

  if (!data) {
    return null;
  }

  return (
    <Stack
      px={1}
      height="100%"
      justifyContent="center"
      sx={{ cursor: canEditTask ? "pointer" : undefined }}
      onClick={canEditTask ? clickHandler : undefined}
    >
      <TypographyWithTooltip title={data.name} variant="body2" />
    </Stack>
  );
};
