import { ColumnSetupType } from "../../../../../../../../../../storybook/data-grid/utils/types/types";
import { ResourceExplorerGridDataWithId } from "../../../../../../../../../../store/resource-explorer/utils/types/resourceExplorerDataGrid";

export const getResourceExplorerDataGridColumnsInitialVisibility = (
  columns: ColumnSetupType<ResourceExplorerGridDataWithId>[],
  quarterEndForecastVisibility: boolean,
) => {
  return columns.reduce(
    (result, item) => {
      if (!item?.accessorKey) {
        return result;
      }

      result = {
        ...result,
        [item?.accessorKey]: true,
        quarterly_forecast: quarterEndForecastVisibility,
      };

      return result;
    },
    {} as Record<string, boolean>,
  );
};
