import { FC } from "react";
import { Alert } from "@mui/material";
import { WebhookMessageActions } from "./WebhookMessageActions";

interface WebhookTestMessageProps {
  url?: string;
  content_type?: string;
}

export const WebhookTestMessage: FC<WebhookTestMessageProps> = ({
  url,
  content_type,
}) => {
  return (
    <Alert
      icon={false}
      variant="outlined"
      sx={{ alignItems: "center", borderColor: "grey.400" }}
      action={<WebhookMessageActions url={url} content_type={content_type} />}
    >
      Send a test message to provided url. Please revisit your WebHook
      configuration in case you do not receive it!
    </Alert>
  );
};
