import { FC, Fragment } from "react";
import { useToggle } from "rooks";
import { useFormik } from "formik";
import * as yup from "yup";
import { WidgetNameEditView } from "./WidgetNameEditView";
import { WidgetNameDefaultView } from "./WidgetNameDefaultView";
import { useAppDispatch } from "../../../../../../../../../store/hooks";
import { WidgetType } from "../../../../../../../../../services/cloudchipr.api";
import { updateWidgetNameThunk } from "../../../../../../../../../store/dashboards/thunks/widgets/common/updateWidgetNameThunk";

interface WidgetNameProps {
  name: string;
  hovered: boolean;
  widgetId: string;
  widgetType: WidgetType;
}

export const WidgetName: FC<WidgetNameProps> = ({
  name,
  hovered,
  widgetId,
  widgetType,
}) => {
  const dispatch = useAppDispatch();
  const [editMode, toggleEditMode] = useToggle();

  const formik = useFormik({
    initialValues: {
      name,
    },
    validationSchema: yup.object({
      name: yup.string().trim().required(),
    }),
    onSubmit: (values) => {
      toggleEditMode();

      if (values.name === name) {
        return;
      }

      dispatch(
        updateWidgetNameThunk({
          widgetId,
          widgetType,
          newName: values.name.trim(),
        }),
      );
    },
  });

  const { isValid, values, handleChange, handleSubmit } = formik;

  return (
    <Fragment>
      {editMode ? (
        <WidgetNameEditView
          handleSubmit={handleSubmit}
          handleChange={handleChange}
          name={values.name}
          isValid={isValid}
          hovered={hovered}
        />
      ) : (
        <WidgetNameDefaultView
          name={values.name}
          hovered={hovered}
          toggleEditMode={toggleEditMode}
        />
      )}
    </Fragment>
  );
};
