import { FC } from "react";
import { Popover, Table, TableBody, TableHead, TableRow } from "@mui/material";
import TableCell from "@mui/material/TableCell";
import { Tags } from "../../../../services/cloudchipr.api";
import { CopyCell } from "../CopyCell";

interface TagsPopoverProps {
  open: boolean;
  otherTags: Tags;
  closeMenu(): void;
  anchor: HTMLElement | null;
}

export const TagsPopover: FC<TagsPopoverProps> = ({
  open,
  anchor,
  otherTags,
  closeMenu,
}) => {
  return (
    <Popover
      open={open}
      anchorEl={anchor}
      onClose={closeMenu}
      transformOrigin={{
        horizontal: "center",
        vertical: "top",
      }}
      anchorOrigin={{
        horizontal: "center",
        vertical: "bottom",
      }}
    >
      <Table size="small" sx={{ my: 1 }}>
        <TableHead>
          <TableRow>
            <TableCell sx={{ width: 150 }}>Key</TableCell>
            <TableCell sx={{ width: 250 }}>Value</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {otherTags.map((tag) => (
            <TableRow key={tag.key}>
              <TableCell>
                <CopyCell data={tag.key} />
              </TableCell>

              <TableCell>
                <CopyCell data={tag.value} />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Popover>
  );
};
