import { FC, Fragment, ReactNode } from "react";
import { Chip, FormHelperText, Stack } from "@mui/material";
import KeyboardArrowUpOutlinedIcon from "@mui/icons-material/KeyboardArrowUpOutlined";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import { UseMenuHookOpenMenu } from "../../../../../utils/hooks/useMenu.hook";

interface FilterTriggerProps {
  label: ReactNode;
  error?: string | boolean;
  disabled: boolean;
  selected: boolean;
  open: boolean;
  selectedValues: string[];
  onClick: UseMenuHookOpenMenu;
  optionsLength?: number;
}

export const FilterTrigger: FC<FilterTriggerProps> = ({
  onClick,
  selected,
  label,
  open,
  selectedValues,
  disabled,
  optionsLength,
  error,
}) => {
  const selectedLength = selectedValues.length
    ? `(${selectedValues.length})`
    : "";

  const chipLabel = `${label} ${
    optionsLength && selectedValues.length === optionsLength
      ? "(All)"
      : selectedLength
  }`;

  const chipLabelComponent = typeof label === "string" ? chipLabel : label;

  return (
    <Fragment>
      <Chip
        clickable
        disabled={disabled}
        onClick={onClick}
        variant="outlined"
        color={error ? "error" : undefined}
        sx={
          selected
            ? {
                color: "primary.main",
                bgcolor: "primary.light",
                borderColor: "primary.main",
                "&:hover": {
                  bgcolor: "primary.main",
                },
              }
            : undefined
        }
        label={
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            {chipLabelComponent}

            {open ? (
              <KeyboardArrowUpOutlinedIcon fontSize="small" />
            ) : (
              <KeyboardArrowDownOutlinedIcon fontSize="small" />
            )}
          </Stack>
        }
      />

      {error && typeof error === "string" && (
        <FormHelperText error>{error}</FormHelperText>
      )}
    </Fragment>
  );
};
