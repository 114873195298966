import { FC } from "react";
import { TooltipText } from "../../../../common/TooltipText";
import { formatDate } from "../../../../../utils/helpers/date-time/datetime-format";
import { capitalizeString } from "../../../../../utils/helpers/capitalizeString";

interface PeriodCellProps {
  period: string;
  startDate: string;
}

export const PeriodCell: FC<PeriodCellProps> = ({ period, startDate }) => {
  return (
    <TooltipText
      tooltipTitle={`Started on ${formatDate(startDate, { type: "date" })}`}
    >
      {capitalizeString(period)}
    </TooltipText>
  );
};
