import { Row } from "@tanstack/react-table";
import { DimensionWithCosts } from "../../../../../../../../services/cloudchipr.api";

export const dimensionsNameColumnFilter = (
  row: Row<DimensionWithCosts>,
  _: string,
  keyword: string,
) => {
  const data = row.original;
  const filterValue = keyword.toLowerCase().trim();
  const name = data.name.toLowerCase().trim();

  if (name.includes(filterValue)) {
    return true;
  }
  if ("categories" in data) {
    return data.categories?.some((category) =>
      category.name.toLowerCase().trim().includes(filterValue),
    );
  }
  return false;
};
