import { FC, useMemo } from "react";
import { Box, Stack, Typography } from "@mui/material";
import { useAppSelector } from "../../../../../../store/hooks";
import { overviewEffectiveSavingRateSelector } from "../../../../../../store/commitments/selectors/overview/overviewEffectiveSavingRateSelector";
import { overviewLoadingSelector } from "../../../../../../store/commitments/selectors/overview/loading/overviewLoadingSelector";
import { CommitmentsProgressBar } from "../../../common/components/CommitmentsProgressBar";

export const OverviewEffectiveSavingsRateProgressbar: FC = () => {
  const effectiveSavingRate = useAppSelector(
    overviewEffectiveSavingRateSelector,
  );
  const loading = useAppSelector(overviewLoadingSelector);

  const savingRate = effectiveSavingRate?.savings_rate;

  const pointer = useMemo(() => {
    if (savingRate === null || savingRate === undefined || savingRate > 100) {
      return null;
    }
    let color = "";
    const isError = savingRate <= 8 || savingRate < 0;
    if (isError) {
      color = "error.light";
    } else if (savingRate <= 24) {
      color = "warning.main";
    } else {
      color = "success.light";
    }
    const left = savingRate < 0 ? 0 : savingRate;
    return (
      <Box
        height={20}
        bgcolor="white"
        p={0.125}
        position="absolute"
        left={`${left}%`}
      >
        <Box height="100%" width={3} bgcolor={color} borderRadius={8} />
      </Box>
    );
  }, [savingRate]);

  if (loading) {
    return (
      <CommitmentsProgressBar
        value={100}
        overrideColor="grey.300"
        width="100%"
      />
    );
  }

  if (!effectiveSavingRate) {
    return null;
  }

  return (
    <Stack>
      <Stack
        direction="row"
        borderRadius={20}
        spacing={0.25}
        alignItems="center"
        flex={1}
        width="100%"
        position="relative"
      >
        {pointer}
        <Box sx={leftBorderSx} height={12} width="8%" bgcolor="error.light" />
        <Box height={12} width="16%" bgcolor="warning.light" />
        <Box
          sx={rightBorderSx}
          height={12}
          display={"flex"}
          flex={1}
          bgcolor="success.light"
        />
      </Stack>

      <Stack justifyContent="space-between" direction="row">
        <Typography variant="caption" color="text.secondary">
          {!savingRate || (savingRate ?? 0) > 0 ? 0 : savingRate}%
        </Typography>
        <Typography variant="caption" color="text.secondary">
          ESR Benchmark
        </Typography>
        <Typography variant="caption" color="text.secondary">
          100%
        </Typography>
      </Stack>
    </Stack>
  );
};

const leftBorderSx = { borderTopLeftRadius: 8, borderBottomLeftRadius: 8 };
const rightBorderSx = { borderTopRightRadius: 8, borderBottomRightRadius: 8 };
