import { liveUsageMgmtSelectedAccountsSelector } from "./liveUsageMgmtSelectedAccountsSelector";
import { RootState } from "../../../store";
import { liveUsageMgmtProviderSelector } from "../store-selectors/liveUsageMgmtProviderSelector";

export const liveUsageMgmtSelectedAccountsMetaDataSelector = (
  state: RootState,
) => {
  const accounts = liveUsageMgmtSelectedAccountsSelector(state);
  const provider = liveUsageMgmtProviderSelector(state);

  if (!accounts || !provider) {
    return;
  }
  return {
    provider,
    groupId: accounts?.at(0)?.group_id,
  };
};
