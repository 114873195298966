import { RootState } from "../../../store";
import { cloudChiprApi } from "../../../../services/cloudchipr.api";
import {
  createAutomationFixedCacheKey,
  updateAutomationFixedCacheKey,
} from "../../utils/constants/fixedCacheKeys";
import { offHoursDataLoadingSelector } from "../off-hours/data/offHoursDataLoadingSelector";
import { workflowsDataLoadingSelector } from "../workflow/list-data/workflowsDataLoadingSelector";
import { createUsersMeOffHoursSchedulesSelector } from "../workflow/create/createV2USersMeScheduleSelector";

const workflowCreateResponse = createUsersMeOffHoursSchedulesSelector(
  createAutomationFixedCacheKey,
);
const workflowUpdateResponse =
  cloudChiprApi.endpoints.patchUsersMeSchedulesByScheduleId.select(
    updateAutomationFixedCacheKey,
  );

const offHoursCreateResponse =
  cloudChiprApi.endpoints.postUsersMeOffHoursSchedules.select(
    createAutomationFixedCacheKey,
  );
const offHoursUpdateResponse =
  cloudChiprApi.endpoints.patchUsersMeOffHoursSchedulesByScheduleId.select(
    updateAutomationFixedCacheKey,
  );

export const automationSavingLoadingSelector = (state: RootState) => {
  return (
    workflowCreateResponse(state)?.isLoading ||
    workflowUpdateResponse(state)?.isLoading ||
    offHoursCreateResponse(state)?.isLoading ||
    offHoursUpdateResponse(state)?.isLoading ||
    offHoursDataLoadingSelector(state) ||
    workflowsDataLoadingSelector(state)
  );
};
