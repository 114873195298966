import { liveFilteredTrackedResourcesDataForViewSelector } from "./view/liveFilteredTrackedResourcesDataForViewSelector";
import { liveFilteredResourcesEmsPriceSelector } from "./liveFilteredResourcesEmsPriceSelector";
import { RootState } from "../../../store";

export const liveFilteredResourcesEmsSumSelector = (
  state: RootState,
): number | null => {
  const trackedResources =
    liveFilteredTrackedResourcesDataForViewSelector(state);

  if (!trackedResources) {
    return null;
  }

  return trackedResources.reduce((res: number, resource) => {
    const monthlyCosyByResourceType = liveFilteredResourcesEmsPriceSelector(
      state,
      resource.resource_type,
    );
    res = res + (monthlyCosyByResourceType ?? 0);

    return res;
  }, 0);
};
