import { liveUsageMgmtSelectedResourcesAccountsMetaDataSelector } from "../resource-type-data/liveUsageMgmtSelectedResourcesAccountsMetaDataSelector";
import { RootState } from "../../../store";
import {
  ProviderAccessType,
  ResourceType,
} from "../../../../services/cloudchipr.api";

export const liveUsageMgmtSelectedResourcesAccountsAccessTypeSelector = (
  state: RootState,
  resourceType: ResourceType,
): ProviderAccessType | undefined => {
  const accountsMetadata =
    liveUsageMgmtSelectedResourcesAccountsMetaDataSelector(state, resourceType);

  let accessType: ProviderAccessType = "read";

  if (accountsMetadata?.some((item) => item.accessType === "read_write")) {
    accessType = "read_write";
  }
  return accessType;
};
