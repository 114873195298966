import { costBreakdownSetupPropertyByKeySelector } from "./costBreakdownSetupPropertyByKeySelector";
import { CostBreakdownWidgetSetupType } from "../../../utils/types/setups/costBreakdownSetupType";
import { RootState } from "../../../../store";
import { getWidgetsConfigurationsFeatureToggleFromStorage } from "../../../../../components/pages/dashboard/components/adding-widget/widget-create/widget-setups/cost-breakdown/utils/helpers/configurations-feature-toggle/getWidgetsConfigurationsFeatureToggleFromStorage";

export const costBreakdownSetupVisualizationPropertyByKeySelector =
  (key: keyof CostBreakdownWidgetSetupType["visualization"]) =>
  (state: RootState): any => {
    const enableWidgetsSideBarConfigurations =
      getWidgetsConfigurationsFeatureToggleFromStorage();

    if (!enableWidgetsSideBarConfigurations && key == "chart_type") {
      return costBreakdownSetupPropertyByKeySelector("viewType")(state);
    }

    const setup =
      costBreakdownSetupPropertyByKeySelector("visualization")(state);

    if (!setup) {
      return null;
    }

    return setup?.[key];
  };
