import { FC } from "react";
import Chip from "@mui/material/Chip";
import { FilterItemWithType } from "../../../../../services/cloudchipr.api";
import { useFilterLabel } from "../utils/hooks/useFilterLabel.hook";

interface FiltersDisabledViewListItemProps {
  filter: FilterItemWithType;
}

export const FiltersDisabledViewListItem: FC<
  FiltersDisabledViewListItemProps
> = ({ filter }) => {
  const { getFilterLabel } = useFilterLabel(filter.type);

  return (
    <Chip
      key={filter.filter.key}
      label={getFilterLabel(
        filter.filter,
        filter.filter.key === "provider_unique_identifier",
      )}
      variant="outlined"
    />
  );
};
