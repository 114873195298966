import { Column } from "@tanstack/react-table";
import { SxProps } from "@mui/system";
import { Tags } from "../../../services/cloudchipr.api";
import { isSmartTag } from "../../../components/pages/common/filters/utils/helpers/isSmartTag";

export const concatTagsForFiltering = (tags: Tags) => {
  return tags.map((tag) => `${tag.key}: ${tag.value}`);
};

const getUniqueValuesFromTags = (data: Tags[], smartTag?: boolean) => {
  let arr = concatTagsForFiltering(data.flat());

  if (smartTag) {
    arr = arr.filter(isSmartTag);
  }

  return Array.from(new Set(arr));
};

export const getSortedUniqueValues = (column: Column<any>) => {
  const arrFromMap = Array.from(column.getFacetedUniqueValues()?.keys() || [])
    ?.filter((item) => item)
    ?.sort((a, b) => a - b);

  if (
    ["string", "number"].includes(typeof arrFromMap[0]) ||
    !arrFromMap.length
  ) {
    return arrFromMap;
  }

  const isSmartTag = column.id.includes("smart_tag");

  return getUniqueValuesFromTags(arrFromMap, isSmartTag);
};

export const getStickyCellStyles = (
  left: number | null,
  withBorder: boolean,
  side?: "left" | "right",
  right?: number,
) => {
  const stickyStyles: SxProps = {
    p: 1,
    left,
    right,
    top: 0,
    zIndex: 1,
    bgcolor: "inherit",
    position: "sticky",
  };

  if (withBorder) {
    if (side === "left") {
      stickyStyles.borderRight = "1px solid";
    } else if (side === "right") {
      stickyStyles.borderLeft = "1px solid";
    }

    stickyStyles.borderColor = "grey.300";
  }

  return stickyStyles;
};
