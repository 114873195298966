import {
  Breadcrumbs as MuiBreadcrumbs,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";
import FolderOpenIcon from "@mui/icons-material/FolderOpen";
import { FC, ReactNode } from "react";
import { TypographyWithTooltip } from "../../../../common/TypographyWithTooltip";

interface HierarchicalBreadcrumbsProps {
  folderName?: string;
  loading?: boolean;
  children: ReactNode;
}

export const HierarchicalBreadcrumbs: FC<HierarchicalBreadcrumbsProps> = ({
  folderName,
  loading,
  children,
}) => {
  if (loading) {
    return <Skeleton width={200} height={35} />;
  }

  return (
    <MuiBreadcrumbs
      separator={
        <Typography variant="body1" color="text.secondary">
          /
        </Typography>
      }
    >
      {folderName && (
        <Stack direction="row" spacing={1}>
          <FolderOpenIcon fontSize="small" />
          <TypographyWithTooltip
            title={folderName}
            color="text.primary"
            variant="body2"
          />
        </Stack>
      )}
      {children}
    </MuiBreadcrumbs>
  );
};
