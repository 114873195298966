import { createAsyncThunk } from "@reduxjs/toolkit";
import { initiateOffHoursByRecommendationIdThunk } from "./initiateOffHoursByRecommendationIdThunk";
import { initiateOffHoursByOffHourIdThunk } from "./initiateOffHoursByOffHourIdThunk";
import { initiateEmptyAutomationThunk } from "../initiateEmptyAutomationThunk";

interface InitiateOffHoursThunkArguments {
  offHourId: string | undefined;
  recommendationId: string | null;
}

export const initiateOffHoursThunk = createAsyncThunk(
  "automation/initiateOffHours",
  async (
    { offHourId, recommendationId }: InitiateOffHoursThunkArguments,
    { dispatch },
  ) => {
    if (recommendationId) {
      dispatch(initiateOffHoursByRecommendationIdThunk(recommendationId));
      return;
    }

    if (!offHourId) {
      dispatch(initiateEmptyAutomationThunk("offHours"));
      return;
    }

    dispatch(initiateOffHoursByOffHourIdThunk(offHourId));
  },
);
