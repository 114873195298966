import { FC, useCallback } from "react";
import { DropdownSelect } from "../../../../../../../common/select/dropdown-select/DropdownSelect";
import { setReportFilters } from "../../../../../../../../store/reports/reportsSlice";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../store/hooks";
import { reportsSourceFilterValuesSelector } from "../../../../../../../../store/reports/selector/reportsSourceFilterValuesSelector";
import { titleBySourceType } from "../../../utils/constants/constants";

export const ReportsSourceFilter: FC = () => {
  const dispatch = useAppDispatch();
  const initialSources = useAppSelector(reportsSourceFilterValuesSelector);

  const optionsCount = options.length;

  const changeHandler = useCallback(
    (newFilters: string[]) => {
      dispatch(
        setReportFilters({
          key: "source",
          values: newFilters.length === optionsCount ? [] : newFilters,
        }),
      );
    },
    [dispatch, optionsCount],
  );

  return (
    <DropdownSelect
      initialSelectedValues={initialSources}
      showSearch={false}
      label="Source"
      options={options}
      submitHandlerOnClose={changeHandler}
    />
  );
};

const options = [
  {
    value: "view",
    hideOnly: true,
    label: titleBySourceType.get("view"),
  },
  {
    value: "dashboard",
    hideOnly: true,
    label: titleBySourceType.get("dashboard"),
  },
];
