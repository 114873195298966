import { ChangeEvent, FC, Fragment } from "react";
import { DialogContent, Divider, Stack, Typography } from "@mui/material";
import { ResourceExplorerBillingExportDialogDetailsTextFields } from "./components/ResourceExplorerBillingExportDialogDetailsTextFields";
import { ResourceExplorerBillingExportDialogDetailsInstruction } from "./components/ResourceExplorerBillingExportDialogDetailsInstruction";
import { ResourceExplorerBillingExportDialogBillingDetailsActions } from "./components/ResourceExplorerBillingExportDialogDetailsStepActions";
import { ResourceExplorerBillingExportDialogBillingStatus } from "../common/ResourceExplorerBillingExportDialogBillingStatus";
import { BillingExportInitialValues } from "../../ResourceExplorerBillingExportDialog";
import { useAppSelector } from "../../../../../../../../../../../store/hooks";
import { resourceExplorerGcpBillingExportDataErrorSelector } from "../../../../../../../../../../../store/resource-explorer/selectors/billing/gcp-billing-data/resourceExplorerGcpBillingExportDataErrorSelector";

interface ResourceExplorerBillingExportDialogDetailsStepProps {
  values: BillingExportInitialValues;
  onValuesChange(event: ChangeEvent): void;
  onCloseDialog(): void;
  onNextStep(): void;
  onSkipStep(count: number): void;
  primaryBtnDisabled: boolean;
  onSuccess?(): void;
}

export const ResourceExplorerBillingExportDialogDetailsStep: FC<
  ResourceExplorerBillingExportDialogDetailsStepProps
> = ({
  values,
  onValuesChange,
  onCloseDialog,
  onNextStep,
  primaryBtnDisabled,
  onSkipStep,
  onSuccess,
}) => {
  const error = useAppSelector(
    resourceExplorerGcpBillingExportDataErrorSelector,
  );

  return (
    <Fragment>
      <DialogContent sx={{ p: 4 }}>
        <Stack gap={2}>
          <Typography variant="body1">
            Please provide where your billing data is stored
          </Typography>
          <ResourceExplorerBillingExportDialogDetailsTextFields
            values={values}
            onChange={onValuesChange}
          />
          <ResourceExplorerBillingExportDialogDetailsInstruction />
          <ResourceExplorerBillingExportDialogBillingStatus
            message={error?.message ?? ""}
          />
        </Stack>
      </DialogContent>

      <Divider />

      <ResourceExplorerBillingExportDialogBillingDetailsActions
        values={values}
        onCloseDialog={onCloseDialog}
        onNextStep={onNextStep}
        primaryBtnDisabled={primaryBtnDisabled}
        onSkipStep={onSkipStep}
        onSuccess={onSuccess}
      />
    </Fragment>
  );
};
