import { FC, Fragment, memo, useCallback } from "react";
import { FormHelperText, Grid, Select, SelectChangeEvent } from "@mui/material";
import { selectMenuItem } from "./SelectMenuItem";
import { selectRenderValue } from "./selectRenderValue";
import { JiraSelectOptionsType } from "../../../../utils/types/types";

interface MultipleSelectWithOptionsFieldProps {
  name: string;
  error?: string;
  label: string;
  value?: { id: string }[];
  options: JiraSelectOptionsType;
  setFieldValue(key: string, value: any): void;
}

export const MultipleSelectWithOptionsField: FC<MultipleSelectWithOptionsFieldProps> =
  memo(({ value, setFieldValue, name, options, label, error }) => {
    const renderValue = useCallback(
      (selected: any) => selectRenderValue(selected, options),
      [options],
    );

    const changeHandler = useCallback(
      (event: SelectChangeEvent<string[]>) => {
        const value = event.target.value as string[];

        setFieldValue(
          name,
          value.map((id) => ({ id })),
        );
      },
      [setFieldValue, name],
    );

    const selectedValues = value?.map(({ id }) => id) || [];

    return (
      <Fragment>
        <Grid item sm={4}>
          <Select
            multiple
            fullWidth
            size="small"
            placeholder={label}
            value={selectedValues}
            onChange={changeHandler}
            renderValue={renderValue}
          >
            {options?.map((option) => selectMenuItem(option, selectedValues))}
          </Select>
          {!!error && <FormHelperText error>{error}</FormHelperText>}
        </Grid>
        <Grid item sm={6} />
      </Fragment>
    );
  });
