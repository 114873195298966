import { getCronFrequency } from "./cron/getCronFrequency";
import { OffHoursSchedule } from "../../../../services/cloudchipr.api";
import { AutomationsData } from "../types/common";

export const generateOffHoursAutomationData = (
  offHours: OffHoursSchedule,
): Partial<AutomationsData> => {
  const frequency = getCronFrequency(offHours.start_cron);

  return {
    frequency,

    id: offHours.id,

    name: offHours.name,
    description: offHours.description ?? undefined,

    startDate: offHours.start_date_time,
    endDate: offHours.end_date_time,
    timeZone: offHours.time_zone,
    threshold: offHours.min_threshold,

    provider: offHours.cloud_provider,
    accountIds: offHours.accounts.map(({ id }) => id),
    regions: offHours.regions,

    notifications: offHours.notifications,
    emails: offHours.emails,

    filter: offHours.filter,

    status: offHours.status,
  };
};
