import { createAsyncThunk } from "@reduxjs/toolkit";
import { getUserRolesPermissionsThunk } from "./getUserRolesPermissionsThunk";
import { generateGroupedRolePermissions } from "./utils/generateGroupedRolePermissions";
import { setUserRoleData } from "../../userSlice";

export const initiateCreateUserRolesDrawerThunk = createAsyncThunk(
  "roles/initiateCreateUserRolesDrawer",
  async (_, { dispatch }) => {
    const permissions = await dispatch(getUserRolesPermissionsThunk()).unwrap();

    if (!permissions) {
      return;
    }

    dispatch(
      setUserRoleData({
        permissions: generateGroupedRolePermissions(permissions),
      }),
    );
  },
);
