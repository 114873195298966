import { List } from "@mui/material";
import { FC } from "react";
import { LiveFilteredResourceItem } from "../LiveFilteredResourceItem";
import { useAppSelector } from "../../../../../../../../../../../../../store/hooks";
import { liveFilteredTrackedResourcesDataForViewSelector } from "../../../../../../../../../../../../../store/resource-explorer/selectors/live-filtered-resources/view/liveFilteredTrackedResourcesDataForViewSelector";
import { LiveFilteredResourcesEmptyState } from "../LiveFilteredResourcesEmptyState";

interface LiveFilteredResourcesDrawerBodyForViewProps {
  loading: boolean;
}
export const LiveFilteredResourcesDrawerBodyForView: FC<
  LiveFilteredResourcesDrawerBodyForViewProps
> = ({ loading }) => {
  const data = useAppSelector(liveFilteredTrackedResourcesDataForViewSelector);

  if (!data?.length && !loading) {
    return (
      <LiveFilteredResourcesEmptyState description="No Live Filtered Resources are found in Live Usage & Mgmt that match the selected filters and date range for this view." />
    );
  }

  return (
    <List sx={{ p: 2 }}>
      {data?.map((item) => {
        return (
          <LiveFilteredResourceItem
            key={item.resource_type}
            resourceType={item.resource_type}
            accountIds={item.account_ids}
            resourceIds={item.resource_ids}
            dataCount={data?.length}
          />
        );
      })}
    </List>
  );
};
