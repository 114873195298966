import { FC } from "react";
import { Button, DialogActions } from "@mui/material";
import { LoadingButton } from "@mui/lab";

interface NavigationVisibilitySectionDialogActionsProps {
  onClose(): void;
  onSubmit(): void;
  loading?: boolean;
  disabled?: boolean;
}

export const NavigationVisibilitySectionDialogActions: FC<
  NavigationVisibilitySectionDialogActionsProps
> = ({ disabled, loading, onSubmit, onClose }) => {
  return (
    <DialogActions sx={{ py: 2, px: 3 }}>
      <Button onClick={onClose} color="tertiary">
        Cancel
      </Button>
      <LoadingButton
        variant="contained"
        loading={loading}
        onClick={onSubmit}
        disabled={disabled}
      >
        Add
      </LoadingButton>
    </DialogActions>
  );
};
