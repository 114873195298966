import { liveUsageMgmtAppliedFilterTemplateIsSourceCloudchiprSelector } from "./liveUsageMgmtAppliedFilterTemplateIsSourceCloudchiprSelector";
import { RootState } from "../../../store";
import { cloudChiprApi } from "../../../../services/cloudchipr.api";
import {
  createFilterTemplateFixedFromAppliedCacheKey,
  updateFilterTemplateFixedCacheKey,
} from "../../thunks/filter-template/utils/constants/fixedCacheKeys";

const createEndpoint =
  cloudChiprApi.endpoints.postUsersMeOrganisationsCurrentFilterSetsV2.select(
    createFilterTemplateFixedFromAppliedCacheKey,
  );
const updateEndpoint =
  cloudChiprApi.endpoints.patchUsersMeOrganisationsCurrentFilterSetsByFilterSetIdV2.select(
    updateFilterTemplateFixedCacheKey,
  );

export const saveFilterTemplateLoadingSelector = (state: RootState) => {
  const isCloudchiprTamplate =
    liveUsageMgmtAppliedFilterTemplateIsSourceCloudchiprSelector(state);

  if (isCloudchiprTamplate) {
    return createEndpoint(state)?.isLoading;
  }

  return updateEndpoint(state)?.isLoading;
};
