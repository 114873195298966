import { DashboardMixedVisibilitiesItemType } from "../../../../components/pages/dashboard-all-items/components/table/utils/types/types";
import { ReMixedVisibilitiesItemType } from "../../../../components/pages/resource-explorer-all-views/components/table/utils/types/types";
import { isHierarchyFolder } from "../../../navigation/utils/helpers/isHierarchyFolder";

export const getFlattenMixedHierarchyData = <
  T extends DashboardMixedVisibilitiesItemType | ReMixedVisibilitiesItemType,
>(
  hierarchy: T[],
): T[] => {
  return hierarchy.reduce<T[]>((result, item) => {
    if (isHierarchyFolder(item)) {
      result.push(...((item.items ?? []) as T[]));
    } else {
      result.push(item);
    }
    return result;
  }, [] as T[]);
};
