import { RootState } from "../../../../../store";
import { GetUsersMeOrganisationsCurrentWidgetsCostBreakdownApiArg } from "../../../../../../services/cloudchipr.api";
import { costBreakdownWidgetSetupSelector } from "../../../setups/cost-breakdown/costBreakdownWidgetSetupSelector";

export const costBreakdownWidgetSetupDataPayloadSelector = (
  state: RootState,
): GetUsersMeOrganisationsCurrentWidgetsCostBreakdownApiArg | undefined => {
  const setup = costBreakdownWidgetSetupSelector(state);
  if (!setup) {
    return;
  }
  const {
    grouping,
    frequency,
    viewId,
    group_values,
    forecastOption,
    dates,
    visualization,
    trendType,
  } = setup;

  return {
    viewId: viewId ?? "",
    dateLabel: dates?.label ?? undefined,
    dateFrom: dates?.from ?? undefined,
    dateTo: dates?.to ?? undefined,
    dateType: dates?.type ?? undefined,
    dateValue: dates?.value ?? undefined,
    dateUnit: dates?.unit ?? undefined,
    dataPoint: frequency ?? undefined,
    grouping,
    groupValues: group_values ?? undefined,
    forecastOption,
    //TODO: EnableWidgetsSideBarConfigurations, remove viewType
    trendType,
    chartType: visualization?.chart_type,
  };
};
