import { RootState } from "../../../../../store";
import { DashboardWidgetCommitmentsUtilization } from "../../../../../../services/cloudchipr.api";
import { widgetByIdSelector } from "../../common/widgetByIdSelector";

export const commitmentUtilizationWidgetByIdSelector = (
  state: RootState,
  widgetId?: string,
): DashboardWidgetCommitmentsUtilization | undefined => {
  if (!widgetId) {
    return;
  }

  return widgetByIdSelector(
    state,
    widgetId,
  ) as DashboardWidgetCommitmentsUtilization;
};
