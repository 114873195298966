import { dashboardHierarchyDataSelector } from "./dashboardHierarchyDataSelector";
import { currentDashboardIdSelector } from "../dashboard/currentDashboardIdSelector";
import { RootState } from "../../../store";
import { isHierarchyFolder } from "../../../navigation/utils/helpers/isHierarchyFolder";

export const currentDashboardFolderSelector = (state: RootState) => {
  const dashboardId = currentDashboardIdSelector(state);
  const hierarchy = dashboardHierarchyDataSelector(state);

  return hierarchy?.find((item) => {
    if (isHierarchyFolder(item)) {
      return item.items?.some((item) => item.id === dashboardId);
    }
  });
};
