import { FC } from "react";
import { Stack, Typography } from "@mui/material";
import { CopyIconButton } from "../../../../../../../../common/CopyIconButton";
import { TypographyWithTooltip } from "../../../../../../../../common/TypographyWithTooltip";

interface LinkRowProps {
  title: string;
  subTitle: string;
  link: string;
  mt: number;
}

export const LinkRow: FC<LinkRowProps> = ({ link, mt, subTitle, title }) => {
  return (
    <Stack direction="row" mt={mt}>
      <Stack minWidth={200}>
        <Typography variant="body1" fontWeight="medium">
          {title}
        </Typography>
        <Typography variant="caption" color="text.secondary" lineHeight="20px">
          {subTitle}
        </Typography>
      </Stack>

      <Stack direction="row" alignItems="center" width="max-content">
        <TypographyWithTooltip
          title={link}
          color="text.disabled"
          maxWidth={350}
        />
        <CopyIconButton data={link} />
      </Stack>
    </Stack>
  );
};
