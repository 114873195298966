import { FC, Fragment, useCallback } from "react";

import { Box, Divider } from "@mui/material";
import {
  NotificationsEmailSelection,
  NotificationsEmailSelectionProps,
} from "./NotificationsEmailSelection";
import { IntegrationCustomMessageProps } from "../../../../automations/common/components/custom-message/IntegrationCustomMessage";

interface NotificationsEmailSelectionWrapperProps
  extends Omit<NotificationsEmailSelectionProps, "CustomMessage"> {
  CustomMessage?: FC<IntegrationCustomMessageProps>;
}

export const NotificationsEmailSelectionWrapper: FC<
  NotificationsEmailSelectionWrapperProps
> = ({
  emailsChangeHandler,
  selectedEmails,
  emailsErrors,
  CustomMessage,
  selectedIntegrations,
  disabledIntegrationTypes,
  onAddNewIntegration,
  onCreateDialogOpen,
}) => {
  const customMessageChangeHandler = useCallback(
    (message: string) => {
      if (selectedEmails) {
        emailsChangeHandler({
          ...selectedEmails,
          custom_message: message ?? "",
        });
      }
    },
    [emailsChangeHandler, selectedEmails],
  );

  if (!selectedEmails) {
    return null;
  }

  return (
    <Fragment>
      <NotificationsEmailSelection
        emailsErrors={emailsErrors}
        selectedEmails={selectedEmails}
        emailsChangeHandler={emailsChangeHandler}
        selectedIntegrations={selectedIntegrations}
        disabledIntegrationTypes={disabledIntegrationTypes}
        onAddNewIntegration={onAddNewIntegration}
        onCreateDialogOpen={onCreateDialogOpen}
        CustomMessage={
          CustomMessage && (
            <CustomMessage
              type="email"
              onChange={customMessageChangeHandler}
              message={selectedEmails?.custom_message ?? ""}
            />
          )
        }
      />

      {!!selectedIntegrations?.length && (
        <Box my={2}>
          <Divider>AND</Divider>
        </Box>
      )}
    </Fragment>
  );
};
