import { FC } from "react";
import { Stack } from "@mui/material";
import FolderOpenIcon from "@mui/icons-material/FolderOpen";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import { VisibilityType } from "../../utils/types/types";
import { TypographyWithTooltip } from "../../../../../common/TypographyWithTooltip";
import { useHover } from "../../../../../../utils/hooks/useHover.hook";

interface ResourceExplorerFolderNameCellProps {
  name: string;
  expanded: boolean;
  visibility: VisibilityType;
  toggleExpandedHandler(): void;
}

export const HierarchyAllItemsFolderNameCell: FC<
  ResourceExplorerFolderNameCellProps
> = ({ name, expanded, visibility, toggleExpandedHandler }) => {
  const { ref, hovered } = useHover();
  const expandIcon = expanded ? (
    <ArrowDropDownIcon fontSize="small" />
  ) : (
    <ArrowRightIcon fontSize="small" />
  );

  return (
    <Stack
      pl={1}
      py={1}
      ref={ref}
      spacing={1}
      direction="row"
      alignItems="center"
      width="min-content"
      sx={{ cursor: "pointer" }}
      onClick={toggleExpandedHandler}
    >
      {hovered ? expandIcon : <FolderOpenIcon fontSize="small" />}

      {visibility === "visible_only_to_me" && (
        <LockOutlinedIcon color="action" sx={{ fontSize: 16 }} />
      )}

      <TypographyWithTooltip variant="inherit" title={name} />
    </Stack>
  );
};
