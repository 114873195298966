import { FC, useCallback } from "react";
import { Stack } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useSnackbar } from "notistack";
import { useAppAbility } from "../../../../../../../../../services/permissions";
import { usePostUsersMeProvidersAwsAccountsByAccountIdCfnChangeSetsMutation } from "../../../../../../../../../services/cloudchipr.api";

interface AwsMissingPermissionsApplyButtonProps {
  accountId: string;
}
export const AwsMissingPermissionsApplyButton: FC<
  AwsMissingPermissionsApplyButtonProps
> = ({ accountId }) => {
  const { can } = useAppAbility();
  const canEditAccount = can("edit", "account");
  const { enqueueSnackbar } = useSnackbar();

  const [getChangeSetLink, { isLoading }] =
    usePostUsersMeProvidersAwsAccountsByAccountIdCfnChangeSetsMutation();

  const handleGoToAWSClick = useCallback(async () => {
    if (!accountId) {
      return;
    }

    try {
      const linkResult = await getChangeSetLink({
        accountId: accountId,
      }).unwrap();

      const link = linkResult?.link;
      window.open(link, "_blank");
    } catch (e) {
      // @ts-expect-error // todo: api should be fixed
      enqueueSnackbar(e?.data?.error ?? "Something went wrong", {
        variant: "snackbarAlert",
        AlertSnackBarProps: { severity: "error" },
      });
    }
  }, [enqueueSnackbar, accountId, getChangeSetLink]);

  return (
    <Stack direction="row" spacing={2}>
      <LoadingButton
        onClick={handleGoToAWSClick}
        loading={isLoading}
        disabled={!canEditAccount}
        variant="contained"
        autoFocus
        size="small"
      >
        Go to AWS console
      </LoadingButton>
    </Stack>
  );
};
