import { FC } from "react";
import { useAppSelector } from "../../../../../../../../../store/hooks";
import { organisationQuarterStartMonthSelector } from "../../../../../../../../../store/common/selectors/org-current-settings/organisationQuarterStartMonthSelector";
import { Dates } from "../../../../../../../../../services/cloudchipr.api";
import { allDateRangesByQuarterStartMonthSelector } from "../../../../../../../../../store/common/selectors/date-ranges/allDateRangesByQuarterStartMonthSelector";
import { DateRangePicker } from "../../../../../../../../common/date-range-picker/DateRangePicker";
import { widgetSetupWidgetDatesSelector } from "../../../../../../../../../store/dashboards/selectors/setups/common/widgetSetupWidgetDatesSelector";

interface WidgetSetupDateRangeSelectProps {
  onDateChange(dates: Dates): void;
}
export const WidgetSetupDateRangeSelect: FC<
  WidgetSetupDateRangeSelectProps
> = ({ onDateChange }) => {
  const dateRanges = useAppSelector(allDateRangesByQuarterStartMonthSelector);
  const orgQuarterStartMonth = useAppSelector(
    organisationQuarterStartMonthSelector,
  );

  const dates = useAppSelector(widgetSetupWidgetDatesSelector);

  if (!dates) {
    return null;
  }

  return (
    <DateRangePicker
      fullWidth
      highlightToday
      ranges={dateRanges}
      selectedDates={dates}
      onDateSelect={onDateChange}
      quarterStartMonth={orgQuarterStartMonth}
    />
  );
};
