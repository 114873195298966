import { Collapse, IconButton, Stack } from "@mui/material";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import Typography from "@mui/material/Typography";
import { FC, useCallback } from "react";
import { useDidMount } from "rooks";
import { AlertsCostsAnomalyPreviewTable } from "./components/AlertsCostsAnomalyPreviewTable";
import { getCostAnomalyPreviewDataGridDataThunk } from "../../../../../../../../../store/alerts/thunks/cost-anomaly-alerts/getCostAnomalyPreviewDataGridDataThunk";
import { useAppDispatch } from "../../../../../../../../../store/hooks";

interface AlertsCostsAnomalyAverageCostSectionProps {
  collapsed: boolean;
  toggleCollapsed(): void;
}

export const AlertsCostsAnomalyPreviewSection: FC<
  AlertsCostsAnomalyAverageCostSectionProps
> = ({ collapsed, toggleCollapsed }) => {
  const dispatch = useAppDispatch();

  const handleClick = useCallback(() => {
    if (!collapsed) {
      dispatch(getCostAnomalyPreviewDataGridDataThunk());
    }
    toggleCollapsed();
  }, [collapsed, toggleCollapsed, dispatch]);

  useDidMount(() => {
    dispatch(getCostAnomalyPreviewDataGridDataThunk());
  });

  return (
    <Stack p={1} bgcolor="grey.50" borderRadius={1}>
      <Stack
        width="fit-content"
        direction="row"
        alignItems="center"
        spacing={0.5}
        onClick={handleClick}
      >
        <IconButton size="small">
          {collapsed ? (
            <KeyboardArrowUp color="action" />
          ) : (
            <KeyboardArrowDown color="action" />
          )}
        </IconButton>
        <Typography
          variant="body2"
          fontWeight="medium"
          sx={{ cursor: "pointer" }}
        >
          Costs
        </Typography>
      </Stack>
      <Collapse in={collapsed}>
        <AlertsCostsAnomalyPreviewTable />
      </Collapse>
    </Stack>
  );
};
