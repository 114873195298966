import { FC, useCallback } from "react";
import { recommendationAvailableTypesSelector } from "../../../../../../../store/recommendations/selectors/off-hours/filters/recommendationAvailableTypesSelector";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../store/hooks";
import { DropdownSelect } from "../../../../../../common/select/dropdown-select/DropdownSelect";
import { setRecommendationsTypeFilter } from "../../../../../../../store/recommendations/recommendationsSlice";
import { RecommendationGroupType } from "../../utils/types/types";
import { RecommendationsTabType } from "../OffHoursRecommendationsContent";

interface TypeFilterProps {
  group: RecommendationGroupType;
  tab: RecommendationsTabType;
}

export const OffHoursRecommendationsTypeFilter: FC<TypeFilterProps> = ({
  group,
  tab,
}) => {
  const dispatch = useAppDispatch();
  const types = useAppSelector((state) =>
    recommendationAvailableTypesSelector(state, group, tab),
  );

  const options = types.map((type) => ({
    value: type,
    label: type,
  }));

  const changeHandler = useCallback(
    (types: string[]) => {
      dispatch(setRecommendationsTypeFilter(types));
    },
    [dispatch],
  );

  return (
    <DropdownSelect
      label="Resource Types"
      options={options}
      submitHandlerOnClose={changeHandler}
    />
  );
};
