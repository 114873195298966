import { SvgIcon, SvgIconProps } from "@mui/material";

function AzureSvgIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <svg
        width="59"
        height="60"
        viewBox="0 0 59 60"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M20.4894 4.52161L36.4919 4.52161L19.8798 53.7417C19.709 54.2475 19.384 54.687 18.9504 54.9984C18.5167 55.3097 17.9964 55.4772 17.4626 55.4773H5.00868C4.60423 55.4774 4.20558 55.3811 3.84571 55.1965C3.48584 55.0119 3.17509 54.7443 2.93918 54.4158C2.70327 54.0873 2.54897 53.7073 2.48905 53.3073C2.42913 52.9073 2.46531 52.4988 2.5946 52.1156L18.0717 6.25719C18.2423 5.75121 18.5674 5.31152 19.0012 5.00005C19.4349 4.68858 19.9554 4.52103 20.4894 4.521V4.52161Z"
          fill="url(#paint0_linear_26_40870)"
        />
        <path
          d="M43.7433 37.5356H18.3672C18.1313 37.5354 17.9007 37.6062 17.7056 37.7388C17.5105 37.8714 17.3599 38.0598 17.2733 38.2792C17.1867 38.4987 17.1683 38.7391 17.2204 38.9693C17.2724 39.1994 17.3926 39.4085 17.5651 39.5693L33.8713 54.7888C34.346 55.2317 34.9711 55.4779 35.6204 55.4778H49.9893L43.7433 37.5356Z"
          fill="#0078D4"
        />
        <path
          d="M20.4901 4.52199C19.9503 4.51992 19.4239 4.69051 18.9879 5.00884C18.552 5.32717 18.2292 5.77656 18.0668 6.29138L2.6143 52.0741C2.47632 52.4588 2.433 52.871 2.48801 53.2759C2.54302 53.6808 2.69474 54.0665 2.93034 54.4003C3.16594 54.7342 3.47848 55.0064 3.84154 55.194C4.20459 55.3815 4.60747 55.4788 5.01609 55.4777H17.7914C18.2672 55.3927 18.712 55.1829 19.0802 54.8698C19.4484 54.5567 19.7268 54.1514 19.8872 53.6954L22.9687 44.6137L33.9759 54.8803C34.4371 55.2619 35.0158 55.4728 35.6144 55.4777H49.9298L43.6513 37.5356L25.3484 37.5399L36.5504 4.52199L20.4901 4.52199Z"
          fill="url(#paint1_linear_26_40870)"
        />
        <path
          d="M40.9284 6.2551C40.7579 5.74993 40.4333 5.31097 40.0002 5.00004C39.5671 4.68912 39.0474 4.52191 38.5143 4.52197L20.6797 4.52197C21.2128 4.522 21.7325 4.68925 22.1656 5.00016C22.5986 5.31107 22.9233 5.74998 23.0938 6.2551L38.5714 52.1153C38.7008 52.4986 38.7371 52.9072 38.6772 53.3073C38.6174 53.7074 38.4631 54.0875 38.2272 54.4161C37.9913 54.7448 37.6805 55.0125 37.3206 55.1972C36.9606 55.3819 36.5619 55.4783 36.1574 55.4783L53.9926 55.4783C54.3971 55.4782 54.7957 55.3818 55.1556 55.197C55.5154 55.0123 55.8261 54.7445 56.0619 54.4159C56.2978 54.0873 56.452 53.7072 56.5118 53.3072C56.5717 52.9071 56.5354 52.4986 56.406 52.1153L40.9284 6.2551Z"
          fill="url(#paint2_linear_26_40870)"
        />
        <defs>
          <linearGradient
            id="paint0_linear_26_40870"
            x1="26.3218"
            y1="8.29761"
            x2="9.70287"
            y2="57.3942"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#114A8B" />
            <stop offset="1" stopColor="#0669BC" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_26_40870"
            x1="31.5139"
            y1="31.1783"
            x2="27.6696"
            y2="32.4782"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopOpacity="0.3" />
            <stop offset="0.071" stopOpacity="0.2" />
            <stop offset="0.321" stopOpacity="0.1" />
            <stop offset="0.623" stopOpacity="0.05" />
            <stop offset="1" stopOpacity="0" />
          </linearGradient>
          <linearGradient
            id="paint2_linear_26_40870"
            x1="29.3988"
            y1="6.86599"
            x2="47.6415"
            y2="55.4679"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#3CCBF4" />
            <stop offset="1" stopColor="#2892DF" />
          </linearGradient>
        </defs>
      </svg>
    </SvgIcon>
  );
}

AzureSvgIcon.muiName = "AzureSvgIcon";

export default AzureSvgIcon;
