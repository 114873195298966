import { FC, Fragment } from "react";
import { Divider, Typography } from "@mui/material";

interface IntegrationUtilizationAlertCommitmentsUtilizationEmailHeaderProps {
  divider?: boolean;
}

export const IntegrationUtilizationAlertCommitmentsUtilizationEmailHeader: FC<
  IntegrationUtilizationAlertCommitmentsUtilizationEmailHeaderProps
> = ({ divider }) => {
  return (
    <Fragment>
      <Typography variant="body1">
        Subject:{" "}
        <Typography component="span" fontWeight="medium">
          Commitment Utilization is Below {`{{ 80%}}`} - {`{{Alert Name}}`}
        </Typography>
      </Typography>

      {divider && <Divider />}

      <Typography variant="body1">Dear Customer,</Typography>

      <Typography variant="body1">
        Utilization of the{" "}
        <Typography component="span" fontWeight="medium">
          {`{{Commitment ID}}`}
        </Typography>{" "}
        has dropped below{" "}
        <Typography component="span" fontWeight="medium">
          {`{{80%}}`}
        </Typography>
        .
      </Typography>
    </Fragment>
  );
};
