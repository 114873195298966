import { FC, useMemo } from "react";
import { Typography } from "@mui/material";
import {
  Dates,
  ResourceExplorerCostsDateDetails,
} from "../../../../../../../../services/cloudchipr.api";
import { generateDatesCompareData } from "../../../../../../../common/date-range-picker/utils/helpers/date-formatter/generateDatesCompareData";

interface LargestCostChangesWidgetFooterProps {
  prevDate?: ResourceExplorerCostsDateDetails;
  date?: Dates;
  quarterStartMonth?: number;
}

export const LargestCostChangesWidgetFooter: FC<
  LargestCostChangesWidgetFooterProps
> = ({ prevDate, date, quarterStartMonth }) => {
  const dateCompareData = useMemo(() => {
    return generateDatesCompareData({
      date: date,
      prevDate: prevDate,
      quarterStartMonth,
    });
  }, [prevDate, date, quarterStartMonth]);

  return (
    <Typography
      fontWeight="bold"
      sx={{ display: "inline" }}
      variant="body2"
      color="text.secondary"
      px={2}
      pb={1}
    >
      {dateCompareData.startDateLabel}{" "}
      {dateCompareData.startDateRange && (
        <Typography variant="body2" component="span">
          ({dateCompareData.startDateRange}){" "}
        </Typography>
      )}
      <Typography variant="body2" component="span">
        compared with{" "}
      </Typography>
      {dateCompareData.endDateLabel}
      {dateCompareData.endDateRange && (
        <Typography variant="body2" component="span">
          {" "}
          ({dateCompareData.endDateRange})
        </Typography>
      )}
    </Typography>
  );
};
