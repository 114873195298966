import { ChangeEvent, CSSProperties, FC, useCallback, useState } from "react";
import { useDebounce, useEffectOnceWhen } from "rooks";
import { grey } from "@mui/material/colors";
import { useAppDispatch, useAppSelector } from "../../../../../../store/hooks";
import { setAutomationData } from "../../../../../../store/automations/automationsSlice";
import { automationDescriptionSelector } from "../../../../../../store/automations/selectros/common/fields/automationDescriptionSelector";

export const AutomationDescription: FC = () => {
  const dispatch = useAppDispatch();

  const description = useAppSelector(automationDescriptionSelector);

  const [value, setValue] = useState<string | null>(null);

  const descriptionChangeDispatcher = useCallback(
    (description: string) => {
      dispatch(
        setAutomationData({
          description,
        }),
      );
    },
    [dispatch],
  );

  const handleDescriptionChange = useDebounce(descriptionChangeDispatcher, 500);

  const descriptionChangeHandler = useCallback(
    (event: ChangeEvent<HTMLTextAreaElement>) => {
      const value = event.target.value;

      setValue(value);
      handleDescriptionChange(value);
    },
    [handleDescriptionChange],
  );

  useEffectOnceWhen(
    () => setValue(description),
    !!(description && value === null),
  );

  return (
    <textarea
      value={value ?? ""}
      style={textareaStyles}
      placeholder="Description"
      onChange={descriptionChangeHandler}
    />
  );
};

const textareaStyles: CSSProperties = {
  lineHeight: 1.6,
  fontSize: 16,
  minHeight: 40,
  height: 40,
  padding: 8,
  resize: "vertical",
  width: "100%",
  borderColor: grey[400],
  borderRadius: 4,
  fontFamily: "inherit",
};
