import { UniqueIdentifier } from "@dnd-kit/core";
import { TreeItems } from "../types";

export const getTreeItemIndexById = (
  items: TreeItems,
  id: UniqueIdentifier,
): number | null => {
  return items.reduce(
    (result, item, index) => {
      if (item.id === id) {
        result = index;
      } else if (item.type === "folder") {
        item?.items?.forEach((nestedItem, nestedIndex) => {
          if (nestedItem.id === id) {
            result = nestedIndex;
          }
        });
      }
      return result;
    },
    null as number | null,
  );
};
