import { FC } from "react";
import { Divider, Drawer } from "@mui/material";
import { ViewSettingsDrawerHeader } from "./ViewSettingsDrawerHeader";
import { ViewTrendSettings } from "./view-trend-settings/ViewTrendSettings";
import { ViewCostTypeSettings } from "./view-cost-type/ViewCostTypeSettings";
import { ViewTagsSettings } from "./view-tags-settings/ViewTagsSettings";

interface ViewSettingsDrawerProps {
  open: boolean;
  onClose(): void;
}

export const ViewSettingsDrawer: FC<ViewSettingsDrawerProps> = ({
  open,
  onClose,
}) => {
  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={onClose}
      PaperProps={paperProps}
      slotProps={slotProps}
    >
      <ViewSettingsDrawerHeader onClose={onClose} />

      <Divider />
      <ViewTrendSettings />

      <Divider />
      <ViewCostTypeSettings />

      <Divider />
      <ViewTagsSettings />
    </Drawer>
  );
};

const paperProps = {
  sx: { width: "30dvw" },
};

const slotProps = {
  backdrop: { invisible: true },
};
