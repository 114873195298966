import { FC, memo, useCallback } from "react";
import { Step, StepButton, Stepper, Theme } from "@mui/material";
import { AutomationsStepsType } from "../../../../../common/utils/types/types";

interface ScheduleStepperProps {
  activeStep: number;
  steps: AutomationsStepsType;
  inDrawer?: boolean;
  changeStep(index: number): void;
}

export const OffHoursStepper: FC<ScheduleStepperProps> = memo(
  ({ steps, activeStep, changeStep, inDrawer }) => {
    const stepChangeHandler = useCallback(
      (index: number) => () => {
        if (steps[index].enabled) {
          changeStep(index);
        }
      },
      [changeStep, steps],
    );

    const stickyStyles = inDrawer
      ? {
          position: "sticky",
          top: 90,
          zIndex: (theme: Theme) => theme.zIndex.appBar + 1,
        }
      : undefined;

    return (
      <Stepper
        nonLinear
        activeStep={activeStep}
        sx={{
          mb: 8,
          p: 3,
          bgcolor: "grey.100",
          ...stickyStyles,
        }}
      >
        {steps.map(({ label, index, completed }) => (
          <Step key={label} completed={completed && activeStep !== index}>
            <StepButton onClick={stepChangeHandler(index)}>{label}</StepButton>
          </Step>
        ))}
      </Stepper>
    );
  },
);
