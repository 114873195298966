import { RootState } from "../../../../store";
import { getUsersMeOrganisationsCurrentDismissedResourcesSelector } from "../../dismiss/resources/getUsersMeOrganisationsCurrentDismissedResourcesSelector";

const computeSelector =
  getUsersMeOrganisationsCurrentDismissedResourcesSelector({
    group: "compute",
    recommendationType: "off_hours",
  });

const dbSelector = getUsersMeOrganisationsCurrentDismissedResourcesSelector({
  group: "database",
  recommendationType: "off_hours",
});

export const offHoursRecommendationsDismissedResourcesLoadingSelector = (
  state: RootState,
): boolean => {
  const computeResponse = computeSelector(state);
  const dbResponse = dbSelector(state);

  return !!(computeResponse?.isLoading || dbResponse?.isLoading);
};
