import { RightsizingRecommendationsAdvancedFilters } from "../../utils/types/types";
import { filterRecommendationByTagsFilter } from "../../utils/helpers/filterRecommendationByTagsFilter";
import { filterRecommendationByMultiplePerResourceFilter } from "../../utils/helpers/filterRecommendationByMultiplePerResourceFilter";
import { filterRecommendationByDisplayAcrossInstanceFamilyFilter } from "../../utils/helpers/filterRecommendationByDisplayAcrossInstanceFamilyFilter";
import {
  ProviderType,
  RightSizingRecommendation,
} from "../../../../services/cloudchipr.api";
import {
  TagsFilter,
  TagsFilterSet,
} from "../../../../components/common/select/tag-filter-dropdown-select/utils/types";

export const rightsizingRecommendationsDataByFiltersFilterCombiner = (
  data: RightSizingRecommendation[],
  providers: ProviderType[],
  types: string[],
  accounts: string[],
  tags: {
    combinationOperator: TagsFilter["combinationOperator"];
    filter: TagsFilterSet;
  },
  advancedFilters?: RightsizingRecommendationsAdvancedFilters,
) => {
  return data?.filter((value) => {
    if (!!providers.length && !providers.includes(value?.account?.provider)) {
      return false;
    }

    if (
      !!types.length &&
      !types.includes(value.current_machine_type) &&
      !types.includes(value.recommended_machine_type)
    ) {
      return false;
    }

    const existsByTagFilter = filterRecommendationByTagsFilter(
      tags,
      value?.tags ?? [],
    );

    if (!existsByTagFilter) {
      return false;
    }

    if (value.account.provider === "aws" && !!advancedFilters) {
      const existByMultipleRecommendationsPerResource =
        filterRecommendationByMultiplePerResourceFilter(
          value.is_default,
          advancedFilters?.multipleRecommendationsPerResource,
        );

      if (!existByMultipleRecommendationsPerResource) {
        return false;
      }

      const existByDisplayRecommendationsAcrossInstanceFamily =
        filterRecommendationByDisplayAcrossInstanceFamilyFilter(
          value.recommendation_target,
          advancedFilters?.displayRecommendationsAcrossInstanceFamily,
        );

      if (!existByDisplayRecommendationsAcrossInstanceFamily) {
        return false;
      }
    }

    return accounts.length ? accounts.includes(value?.account?.id) : true;
  });
};
