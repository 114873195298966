import { commitmentsRecommendationsSelector } from "./commitmentsRecommendationsSelector";
import { RootState } from "../../../../store";
import { SavingsPlanRecommendationSummary } from "../../../../../services/cloudchipr.api";

export const commitmentsRecommendationsDataSelector = (
  state: RootState,
): SavingsPlanRecommendationSummary | undefined => {
  const response = commitmentsRecommendationsSelector(state);

  return response?.data;
};
