import { ColumnSetupType } from "../../../../../../../../../../../storybook/data-grid/utils/types/types";
import { SavingsPlanCoverageDataWithId } from "../../../../../../../../common/utils/types";
import { SavingsPlanCoverageGrouping } from "../../../../../../../../../../../services/cloudchipr.api";
import { CommitmentsProgressBar } from "../../../../../../../../common/components/CommitmentsProgressBar";
import { getUtilizationAndCoverageDrawerDynamicHeaderCellTitles } from "../../../../../../../utils/helpers/getUtilizationAndCoverageDrawerDynamicHeaderCellTitles";

export const getCoverageColumn = (
  grouping: SavingsPlanCoverageGrouping,
): ColumnSetupType<SavingsPlanCoverageDataWithId> => {
  return {
    accessorKey: "coverage",
    header: getUtilizationAndCoverageDrawerDynamicHeaderCellTitles(
      "coverage",
      grouping,
    )?.coverageCell,
    cell: ({ row }) => (
      <CommitmentsProgressBar
        value={row.original.coverage}
        overrideColor="info.light"
      />
    ),
  };
};
