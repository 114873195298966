import {
  Grid,
  InputAdornment,
  MenuItem,
  Select,
  Stack,
  TextField,
  Tooltip,
} from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { FormikHandlers, FormikHelpers } from "formik";
import { FC, Fragment, memo, useCallback, useMemo } from "react";
import * as yup from "yup";
import { useEffectOnceWhen } from "rooks";
import { StatisticsSelect } from "./StatisticsSelect";
import { FilterItemOperatorType } from "../../FilterItemOperatorType";
import {
  FilterItem,
  FilterKeyType,
  Operator,
  ResourceType,
} from "../../../../../../../services/cloudchipr.api";
import { filterUnits } from "../../../utils/constants/filter-units";
import { metricFilterTypes } from "../../../utils/constants/filter-types/metric-filter-types";
import {
  convertByteToGB,
  convertGBToByte,
} from "../../../utils/helpers/unitsConverting";

export const StatisticsFilterValueValidation = yup.number().required();

interface StatisticsFilterProps {
  operators?: Operator[];
  filter: FilterItem;
  min: number;
  max: number;
  error?: any;
  onChange: FormikHandlers["handleChange"];
  setFieldValue: FormikHelpers<FilterItem>["setFieldValue"];
  resourceType: ResourceType;
  filterType: FilterKeyType;
}

export const StatisticsFilter: FC<StatisticsFilterProps> = memo(
  ({
    resourceType,
    operators,
    filter,
    min,
    max,
    onChange,
    setFieldValue,
    error,
    filterType,
  }) => {
    const valueFieldLabelUnit = filterUnits.get(filter.key);
    const labelUnit = valueFieldLabelUnit ?? "";

    const filterValue = useMemo(() => {
      if (metricFilterTypes.has(filter.key) && filter?.value) {
        return convertByteToGB(filter?.value as number);
      }

      return filter?.value || "";
    }, [filter]);

    const valueChangeHandler = useCallback(
      (event: any) => {
        let val = event.target.value;

        if (metricFilterTypes.has(filter.key)) {
          val = convertGBToByte(+event.target.value);
        }

        setFieldValue(event.target.name, val);
      },
      [filter.key, setFieldValue],
    );

    const sinceValueChangeHandler = useCallback(
      (event: any) => {
        onChange(event);
      },
      [onChange],
    );

    useEffectOnceWhen(() => {
      setFieldValue("metric.since.unit", "days");
    }, !filter.metric?.since?.unit);

    return (
      <Fragment>
        <StatisticsSelect
          onChange={onChange}
          filterType={filterType}
          setFieldValue={setFieldValue}
          error={error?.metric?.statistics_type}
          statisticsType={filter.metric?.statistics_type || ""}
        />

        <FilterItemOperatorType
          value={filter.operator || ""}
          options={operators}
          onChange={onChange}
        />

        <Grid item md={2} flexShrink={0} flexGrow={0}>
          <TextField
            fullWidth
            name="value"
            size="xsmall"
            label={`Value ${labelUnit}`}
            onChange={valueChangeHandler}
            value={filterValue}
            type="number"
            inputProps={{ min, max, step: "any" }}
            error={!!error?.value}
          />
        </Grid>

        <Grid item md={3} flexShrink={0} flexGrow={0}>
          <Stack direction="row" alignItems="center" spacing={1}>
            <TextField
              fullWidth
              name="metric.since.value"
              size="xsmall"
              label="Since"
              onChange={sinceValueChangeHandler}
              error={
                !!(error?.metric?.since?.value || error?.metric?.since?.unit)
              }
              value={filter.metric?.since?.value || ""}
              type="text"
              inputProps={{ min }}
              InputProps={{
                sx: { pr: 0 },
                endAdornment: (
                  <InputAdornment position="end">
                    <Select
                      variant="outlined"
                      sx={{
                        ".MuiOutlinedInput-notchedOutline": {
                          border: "none",
                        },
                      }}
                      size="small"
                      name="metric.since.unit"
                      value={filter.metric?.since?.unit || "days"}
                      onChange={onChange}
                    >
                      {resourceType !== "s3" && (
                        <MenuItem value="hours">Hour</MenuItem>
                      )}

                      <MenuItem value="days">Day</MenuItem>
                    </Select>
                  </InputAdornment>
                ),
              }}
            />

            <Tooltip
              arrow
              title={`Only integer values are allowed. Maximum ${
                filter.metric?.since?.unit === "days" ? "30 Days" : "24 Hours"
              }`}
            >
              <InfoOutlinedIcon
                sx={{ color: "text.secondary" }}
                fontSize="small"
              />
            </Tooltip>
          </Stack>
        </Grid>
      </Fragment>
    );
  },
);
