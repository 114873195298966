import { createDraftSafeSelector } from "@reduxjs/toolkit";
import { stateAccountsSelector } from "../slice-data/stateAccountsSelector";
import { Account } from "../../../../services/cloudchipr.api";

export const connectedAccountsSelector = createDraftSafeSelector(
  [stateAccountsSelector],
  (accounts: Account[] | null): Account[] | null => {
    return accounts?.filter((a) => a.status === "connected") ?? null;
  },
);
