import moment from "moment";
import { getDailyScheduleTitleAndTooltip } from "./getDailyScheduleTitleAndTooltip";
import { getWeeklyScheduleTitleAndTooltip } from "./getWeeklyScheduleTitleAndTooltip";
import { getMonthlyByDayScheduleTitleAndTooltip } from "./getMonthlyByDayScheduleTitleAndTooltip";
import { getMonthlyByWeekdayScheduleTitleAndTooltip } from "./getMonthlyByWeekdayScheduleTitleAndTooltip";
import { ReportsTableData } from "../../../../../../../store/reports/utils/types/types";
import { formatDate } from "../../../../../../../utils/helpers/date-time/datetime-format";

export const getScheduleTitleAndTooltip = (
  schedule: ReportsTableData["schedule"],
) => {
  const unTime = schedule?.time;
  const today = formatDate(moment(), { type: "yearMonthDay" });
  const time = formatDate(`${today} ${unTime}`, { type: "time" });

  if (schedule?.frequency === "daily") {
    return getDailyScheduleTitleAndTooltip(schedule.interval, time);
  }

  if (schedule?.frequency === "weekly") {
    return getWeeklyScheduleTitleAndTooltip(
      schedule.interval,
      time,
      schedule.week_days,
    );
  }

  if (schedule?.frequency === "monthly_by_day") {
    return getMonthlyByDayScheduleTitleAndTooltip(time, schedule.day_of_month);
  }

  if (schedule?.frequency === "monthly_by_weekday") {
    return getMonthlyByWeekdayScheduleTitleAndTooltip(
      schedule.number_of_week,
      schedule.week_day,
      time,
    );
  }

  return {
    title: "",
    tooltip: "",
  };
};
