import { FC, ReactNode } from "react";
import { Stack } from "@mui/material";
import { SourceDataChip } from "./SourceDataChip";
import { DateInfoChip } from "../../../../adding-widget/widget-create/widget-setups/common/DateInfoChip";
import { useAppSelector } from "../../../../../../../../store/hooks";
import { organisationQuarterStartMonthSelector } from "../../../../../../../../store/common/selectors/org-current-settings/organisationQuarterStartMonthSelector";
import { WidgetTitleProps } from "../../../../../utils/types/types";

import { WidgetGroupingChip } from "../../WidgetGroupingChip";

interface WidgetSourceInfoProps extends Omit<WidgetTitleProps, "widgetName"> {
  children?: ReactNode;
}

export const WidgetSourceInfo: FC<WidgetSourceInfoProps> = ({
  groupBy,
  groupValues,
  dates,
  sourceData,
  children,
}) => {
  const quarterStartMonth = useAppSelector(
    organisationQuarterStartMonthSelector,
  );

  return (
    <Stack direction="row" gap={1} alignItems="center" overflow="auto">
      {sourceData && <SourceDataChip {...sourceData} />}

      {groupBy && (
        <WidgetGroupingChip groupBy={groupBy} groupValues={groupValues} />
      )}

      <DateInfoChip {...dates} quarterStartMonth={quarterStartMonth} />

      {children}
    </Stack>
  );
};
