export const recommendationDismissFixedCacheKey =
  "recommendationDismissFixedCacheKey";

export const recommendationRestoreFixedCacheKey =
  "recommendationRestoreFixedCacheKey";

export const recommendationDismissByResourceFixedCacheKey =
  "recommendationDismissByResourceFixedCacheKey";

export const recommendationRestoreByResourceFixedCacheKey =
  "recommendationRestoreByResourceFixedCacheKey";
