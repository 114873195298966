import { currentDashboardIdSelector } from "../dashboard/currentDashboardIdSelector";
import { RootState } from "../../../store";
import { NavigationItemsVisibilityType } from "../../../navigation/utils/types";
import { navigationDashboardPublicDataItemsSelector } from "../../../navigation/selectors/dashboard/navigationDashboardPublicDataItemsSelector";
import { navigationDashboardPrivateDataItemsSelector } from "../../../navigation/selectors/dashboard/navigationDashboardPrivateDataItemsSelector";

export const currentDashboardHierarchyVisibilitySelector = (
  state: RootState,
): NavigationItemsVisibilityType | undefined => {
  const id = currentDashboardIdSelector(state);
  const publicDataItems = navigationDashboardPublicDataItemsSelector(state);
  const privateDataItems = navigationDashboardPrivateDataItemsSelector(state);

  const dataInPublic = publicDataItems?.find((item) => item.id === id);
  if (dataInPublic) {
    return "visible_to_everyone";
  }
  const dataInPrivate = privateDataItems?.find((item) => item.id === id);

  if (dataInPrivate) {
    return "visible_only_to_me";
  }
};
