import { FC } from "react";
import { TextField } from "@mui/material";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { FilterTriggerComponentProps } from "../../../../../../../../../../common/select/dropdown-select/utils/types/filterTriggerComponentProps";
import { commitmentTypeTitleByType } from "../../../utils/constants/constants";
import { CommitmentType } from "../../../../../../../../../../../services/cloudchipr.api";

export const CommitmentUtilizationTypeSelectTrigger: FC<
  FilterTriggerComponentProps
> = (props) => {
  const { selectedValues, onClick, open, label } = props;

  const value =
    selectedValues.length === 1
      ? commitmentTypeTitleByType.get(selectedValues.at(0) as CommitmentType)
      : "All";

  const error =
    props.error && typeof props.error === "string" ? props.error : undefined;

  return (
    <TextField
      fullWidth
      value={value}
      label={label}
      size="small"
      focused={false}
      error={!!props.error}
      helperText={error}
      onClick={onClick}
      InputLabelProps={{ shrink: !!selectedValues.length || open }}
      InputProps={{
        sx: { pr: 1, cursor: "pointer" },
        readOnly: true,
        endAdornment: open ? (
          <ArrowDropUpIcon fontSize="medium" color="action" />
        ) : (
          <ArrowDropDownIcon fontSize="medium" color="action" />
        ),
      }}
    />
  );
};
