import { createAsyncThunk } from "@reduxjs/toolkit";
import { setOffHourDataThunk } from "./setOffHourDataThunk";
import { getOffHoursAllActiveRecommendationsThunk } from "../../../recommendations/thunks/off-hours/data-get/getOffHoursAllActiveRecommendationsThunk";

import { generateOffHoursRecommendationScheduler } from "../../utils/helpers/generateOffHoursRecommendationScheduler";
import { generateOffHoursRecommendationData } from "../../utils/helpers/generateOffHoursRecommendationData";

export const initiateOffHoursByRecommendationIdThunk = createAsyncThunk(
  "automation/initiateOffHoursByRecommendationId",
  async (recommendationId: string, { dispatch }) => {
    const allActiveRecommendations = await dispatch(
      getOffHoursAllActiveRecommendationsThunk(),
    ).unwrap();
    const recommendation = allActiveRecommendations.find(
      (recommendation) => recommendation.id === recommendationId,
    );

    if (!recommendation) {
      return;
    }

    const offHoursScheduler =
      generateOffHoursRecommendationScheduler(recommendation);
    const offHoursAutomationData =
      generateOffHoursRecommendationData(recommendation);

    dispatch(
      setOffHourDataThunk({
        offHoursSpecificData: {
          scheduler: offHoursScheduler,
        },
        offHoursAutomationData,
      }),
    );
  },
);
