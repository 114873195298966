import { FC } from "react";
import { Link as RouterLink } from "react-router-dom";
import { Link } from "@mui/material";

interface MessageLinkToBillingProps {
  title?: string;
}

export const MessageLinkToBilling: FC<MessageLinkToBillingProps> = ({
  title,
}) => {
  return (
    <Link color="inherit" component={RouterLink} to="/settings/billing">
      {title || "Please upgrade your plan"}{" "}
    </Link>
  );
};
