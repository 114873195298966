import { FC, useCallback } from "react";
import { MenuItem, Select, SelectChangeEvent } from "@mui/material";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../store/hooks";
import { reportsScheduleDayOfMonthSelector } from "../../../../../../../store/reports/selector/reportsScheduleDayOfMonthSelector";
import { setReportsScheduleDataThunk } from "../../../../../../../store/reports/thunks/setReportsScheduleDataThunk";
import { monthDayToString } from "../../../table/utils/helpers/monthDayToString";

export const ReportsDrawerMonthDaySelect: FC = () => {
  const dispatch = useAppDispatch();
  const dayOfMonth = useAppSelector(reportsScheduleDayOfMonthSelector);

  const handleChange = useCallback(
    (e: SelectChangeEvent) => {
      const value = Number(e.target.value);

      dispatch(
        setReportsScheduleDataThunk({
          day_of_month: value,
        }),
      );
    },
    [dispatch],
  );

  return (
    <Select
      value={dayOfMonth.toString()}
      size="small"
      onChange={handleChange}
      MenuProps={menuProps}
    >
      {options.map((num) => (
        <MenuItem value={num} key={num}>
          {monthDayToString(num)}
        </MenuItem>
      ))}
    </Select>
  );
};

const menuProps = {
  sx: {
    maxHeight: 400,
  },
};

const options = Array.from({ length: 31 }, (_, i) => i + 1);
