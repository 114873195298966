import { ColumnSetupType } from "../../../../../../../../../../../../../../storybook/data-grid/utils/types/types";
import { EcsWorkload } from "../../../../../../../../../../../../../../services/cloudchipr.api";
import { capitalizeString } from "../../../../../../../../../../../../../../utils/helpers/capitalizeString";

export const ecsWorkloadsColumns: ColumnSetupType<EcsWorkload>[] = [
  {
    accessorKey: "name",
    header: "Name",
    type: "identifierWithRecommendationLink",
    size: 210,
    meta: { sticky: "left", hideFromSettings: true },
  },
  {
    accessorKey: "price_per_month",
    header: "Monthly Price",
    type: "money",
  },
  {
    accessorKey: "type",
    header: "Type",
    cell: (cell) => {
      const value = (cell.getValue() ?? "") as string;
      return capitalizeString(value);
    },
  },
  {
    accessorKey: "created_at",
    header: "Start time",
    type: "date",
  },
  {
    accessorKey: "creation_data",
    header: "Created By",
    type: "createdBy",
    headerTooltip:
      "The Created By value is only available for resources created within the last 90 days.",
  },
  {
    accessorKey: "all_time_spent",
    header: "Total Spend",
    type: "money",
  },
  {
    accessorKey: "cpu_value",
    header: "CPU Max",
    type: "cpuStatistic",
    headerTooltip: "The maximum usage of CPU for the last 7 days.",
  },
  {
    accessorKey: "cpu_reservation",
    header: "CPU Reservation Per Task",
    type: "vCpuReservation",
    size: 220,
  },
  {
    accessorKey: "memory_value",
    header: "Memory Max  ",
    type: "cpuStatistic",
    size: 180,
    headerTooltip: "The maximum usage of memory for the last 7 days.",
  },
  {
    accessorKey: "memory_reservation",
    header: "Memory Reservation Per Task",
    type: "byteToGib",
    size: 240,
  },
  {
    accessorKey: "storage",
    header: "Storage Per Task",
    type: "byteToGib",
  },
  {
    accessorKey: "status",
    header: "Status",
    cell: (cell) => {
      const value = (cell.getValue() ?? "") as string;
      return capitalizeString(value.toLowerCase());
    },
  },
  {
    accessorKey: "capacity_provider",
    header: "Capacity Provider",
  },
  {
    accessorKey: "task_count",
    header: "Task Count",
  },
  { accessorKey: "region", header: "Region", type: "withCopy" },
  {
    accessorKey: "revision",
    header: "Revision",
  },
  { accessorKey: "tags", header: "Labels", type: "tag", enableSorting: false },
  {
    accessorKey: "tags",
    header: "Smart Labels",
    id: "smart_tag",
    type: "smartTag",
    enableSorting: false,
  },
  {
    accessorKey: "issues",
    header: "Ticket Status",
    type: "jiraTicketStatus",
    enableSorting: false,
  },
];
