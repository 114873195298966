import { RootState } from "../../../../store";
import { cloudChiprApi } from "../../../../../services/cloudchipr.api";
import { categoryDeleteFixedCacheKey } from "../../../thunks/categories/deleteCategoryByIdThunk";

const dimensionDeleteSelector =
  cloudChiprApi.endpoints.deleteUsersMeOrganisationsCurrentDimensionsByDimensionIdCategoriesAndCategoryId.select(
    categoryDeleteFixedCacheKey,
  );

export const categoryDeleteLoadingSelector = (state: RootState) => {
  return !!dimensionDeleteSelector(state)?.isLoading;
};
