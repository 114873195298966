import { RootState } from "../../../store";
import { resourceExplorerHierarchyVisibilitiesDataSelector } from "../views-hierarchy-visibility/resourceExplorerHierarchyVisibilitiesDataSelector";
import { isHierarchyFolder } from "../../../navigation/utils/helpers/isHierarchyFolder";

export const resourceExplorerViewsHierarchyDefaultViewSelector = (
  state: RootState,
): string | undefined => {
  const publicVisibilities =
    resourceExplorerHierarchyVisibilitiesDataSelector(
      state,
    )?.visible_to_everyone;

  return publicVisibilities?.find(
    (item) => !isHierarchyFolder(item) && item.is_default,
  )?.id;
};
