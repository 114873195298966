import { createAsyncThunk } from "@reduxjs/toolkit";
import { setUserRoleData } from "../../userSlice";
import { userRoleDataPermissionsSelector } from "../../selectors/roles/userRoleDataPermissionsSelector";
import { Permission } from "../../../../services/cloudchipr.api";
import { RootState } from "../../../store";
import { UserRoleUpdatedPermissionsKeys } from "../../../../components/pages/settings/components/roles/common/role-permission-collapse/utils/types";

interface UserRolePermissionChangeArgs {
  group: Permission["group"];
  updatedPermissions: UserRoleUpdatedPermissionsKeys[];
}

export const userRolePermissionChangeThunk = createAsyncThunk(
  "roles/userRolePermissionChange",
  (
    { updatedPermissions, group }: UserRolePermissionChangeArgs,
    { dispatch, getState },
  ) => {
    const state = getState() as RootState;
    const initialPermissions = userRoleDataPermissionsSelector(state);

    if (!initialPermissions) {
      return;
    }
    const updatedGroupPermissions =
      initialPermissions[group]?.data.map((item) => {
        const foundItem = updatedPermissions.find(
          (member) => member.name === item.name,
        );
        return foundItem ? { ...item, ...foundItem } : item;
      }) ?? [];

    if (!updatedGroupPermissions) {
      return;
    }
    dispatch(
      setUserRoleData({
        permissions: {
          ...initialPermissions,
          [group]: {
            ...initialPermissions[group],
            data: [...updatedGroupPermissions],
          },
        },
      }),
    );
  },
);
