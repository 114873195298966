import { resourceExplorerIdSelector } from "../resourceExplorerIdSelector";
import { resourceExplorerHierarchyVisibilitiesDataFoldersSelector } from "../../views-hierarchy-visibility/resourceExplorerHierarchyVisibilitiesDataFoldersSelector";
import { RootState } from "../../../../store";

export const currentResourceExplorerFolderSelector = (state: RootState) => {
  const viewId = resourceExplorerIdSelector(state);

  const folders =
    resourceExplorerHierarchyVisibilitiesDataFoldersSelector(state);

  return folders?.find((item) => {
    return item.items?.some((view) => view.id === viewId);
  });
};
