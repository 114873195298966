import { FC, Fragment, memo, ReactChild, RefObject, useMemo } from "react";
import { SxProps } from "@mui/system";
import { Cell, flexRender, Row, RowData, Table } from "@tanstack/react-table";
import { TableCell, Theme } from "@mui/material";
import { ExpandedRow, ExpandedRowProps } from "../ExpandedRow";
import { RowDnDWrapper, WithGetRowProps } from "../RowDnDWrapper";
import { TableStickyCell } from "../TableStickyCell";
import { isCellStylesCallable } from "../../utils/types/types";
import { VirtualColumnsConfig } from "../../table-header/TableHeadComponent";

export interface TableRowVirtualizedCellsProps extends WithGetRowProps {
  table: Table<any>;
  row: Row<any>;
  expandingRowConfig?: ExpandedRowProps["config"];
  dndEnabled: boolean;
  styles?: SxProps<Theme>;
  globalFilter?: string;
  tableContainerRef: RefObject<HTMLTableElement>;
  virtualColumnsConfig: VirtualColumnsConfig;
  stickyColumnsEnabled: boolean;
}

export const TableRowVirtualizedCells: FC<TableRowVirtualizedCellsProps> = memo(
  ({
    table,
    row,
    expandingRowConfig,
    dndEnabled,
    styles,
    globalFilter,
    tableContainerRef,
    getRowProps,
    virtualColumnsConfig,
    stickyColumnsEnabled,
  }) => {
    const virtualPl = virtualColumnsConfig.pl;
    const virtualPr = virtualColumnsConfig.pr;
    const virtualColumns = virtualColumnsConfig.columns;
    const visibleCells = row.getVisibleCells();

    const { noStickyCells, rightCells, leftCells } = useMemo(() => {
      if (!stickyColumnsEnabled) {
        return {
          noStickyCells: visibleCells,
          rightCells: [],
          leftCells: [],
        };
      }

      return visibleCells?.reduce(
        (acc, cell) => {
          if (!cell.column.columnDef.meta?.sticky) {
            acc.noStickyCells.push(cell);
          } else if (cell.column.columnDef.meta?.sticky === "left") {
            acc.leftCells.push(cell);
          } else if (cell.column.columnDef.meta?.sticky === "right") {
            acc.rightCells.push(cell);
          }

          return acc;
        },
        {
          noStickyCells: [],
          rightCells: [],
          leftCells: [],
        } as Record<string, Cell<RowData, any>[]>,
      );
    }, [stickyColumnsEnabled, visibleCells]);

    return (
      <Fragment>
        <RowDnDWrapper
          wrap={dndEnabled}
          row={row}
          styles={styles}
          getRowProps={getRowProps}
        >
          {leftCells.map((cell, i) => {
            const child = flexRender(
              cell.column.columnDef.cell,
              cell.getContext(),
            ) as ReactChild;

            let cellStyles = cell?.column?.columnDef?.meta?.cellStyles;
            if (isCellStylesCallable(cellStyles)) {
              cellStyles = cellStyles(row);
            }

            const isLeftLastSticky =
              !leftCells[i + 1]?.column?.columnDef?.meta?.sticky;

            const multiplySticky =
              !!leftCells[i + 1]?.column?.columnDef?.meta?.sticky ||
              !!leftCells[i - 1]?.column?.columnDef?.meta?.sticky;

            return (
              <TableStickyCell
                key={cell.id}
                side="left"
                isLeftLastSticky={isLeftLastSticky}
                isRightFirstSticky={false}
                customStyles={cellStyles}
                multiplySticky={multiplySticky}
              >
                {child}
              </TableStickyCell>
            );
          })}

          {virtualPl ? <td style={{ width: virtualPl }} /> : null}

          {virtualColumns.map((vc) => {
            const cell = noStickyCells[vc.index];

            if (!cell) {
              return null;
            }

            const child = flexRender(
              cell.column.columnDef.cell,
              cell.getContext(),
            ) as ReactChild;

            let cellStyles = cell?.column?.columnDef?.meta?.cellStyles;
            if (isCellStylesCallable(cellStyles)) {
              cellStyles = cellStyles(row);
            }

            return (
              <TableCell
                key={cell.id}
                sx={{ p: 1, whiteSpace: "nowrap", ...cellStyles }}
              >
                {child}
              </TableCell>
            );
          })}

          {virtualPr ? <td style={{ width: virtualPr }} /> : null}

          {rightCells.map((cell, i) => {
            const child = flexRender(
              cell.column.columnDef.cell,
              cell.getContext(),
            ) as ReactChild;

            let cellStyles = cell?.column?.columnDef?.meta?.cellStyles;
            if (isCellStylesCallable(cellStyles)) {
              cellStyles = cellStyles(row);
            }

            const firstSticky =
              !rightCells[i - 1]?.column?.columnDef?.meta?.sticky;

            return (
              <TableStickyCell
                key={cell.id}
                side="right"
                isLeftLastSticky={false}
                isRightFirstSticky={firstSticky}
                customStyles={cellStyles}
              >
                {child}
              </TableStickyCell>
            );
          })}
        </RowDnDWrapper>

        <ExpandedRow
          row={row}
          table={table}
          config={expandingRowConfig}
          globalFilter={globalFilter}
          tableContainerRef={tableContainerRef}
        />
      </Fragment>
    );
  },
);
