import { resourceExplorerGranularDataSelector } from "./resourceExplorerGranularDataSelector";
import { RootState } from "../../../../../store";

export const resourceExplorerGranularViewGridAggregatedDataSelector = (
  state: RootState,
) => {
  const aggregatedRow = resourceExplorerGranularDataSelector(state)?.data?.find(
    (value) => value.metadata.aggregated_by_cost,
  );

  if (!aggregatedRow) {
    return null;
  }

  return {
    totalCost: aggregatedRow?.total_cost ?? null,
    count: aggregatedRow?.metadata.count ?? null,
    threshold: aggregatedRow?.metadata.threshold ?? null,
  };
};
