import { FC, useCallback } from "react";
import { useToggle } from "rooks";
import copy from "copy-to-clipboard";
import { ListItemIcon, ListItemText, MenuItem } from "@mui/material";
import SettingsEthernetIcon from "@mui/icons-material/SettingsEthernet";

interface CopyIdMenuItemProps {
  categoryId: string;
}

export const CopyIdMenuItem: FC<CopyIdMenuItemProps> = ({ categoryId }) => {
  const [copied, toggleCopied] = useToggle(false);

  const clickHandler = useCallback(() => {
    copy(categoryId);
    toggleCopied();

    setTimeout(toggleCopied, 1000);
  }, [categoryId, toggleCopied]);

  return (
    <MenuItem dense disabled={copied} onClick={clickHandler}>
      <ListItemIcon>
        <SettingsEthernetIcon />
      </ListItemIcon>

      <ListItemText>
        <ListItemText>{copied ? "Copied !" : "Copy ID"}</ListItemText>
      </ListItemText>
    </MenuItem>
  );
};
