import { SvgIcon, SvgIconProps, useTheme } from "@mui/material";

const AzIpSvgIcon = ({ primary }: SvgIconProps & { primary?: boolean }) => {
  const { palette } = useTheme();

  const color = primary ? palette.primary.main : palette.action.active;

  return (
    <SvgIcon>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0.666504 7.7207H23.3332V20.3607C23.3332 20.5623 23.2531 20.7556 23.1106 20.8981C22.968 21.0406 22.7747 21.1207 22.5732 21.1207H1.4265C1.22494 21.1207 1.03163 21.0406 0.889103 20.8981C0.746575 20.7556 0.666504 20.5623 0.666504 20.3607V7.7207Z"
          fill={color}
          fillOpacity="0.7"
        />
        <path
          d="M1.4265 2.89258H22.5732C22.7747 2.89258 22.968 2.97265 23.1106 3.11518C23.2531 3.2577 23.3332 3.45101 23.3332 3.65258V7.71924H0.666504V3.63924C0.67 3.44 0.751612 3.2501 0.893762 3.11044C1.03591 2.97079 1.22723 2.89255 1.4265 2.89258Z"
          fill={color}
        />
        <path
          d="M17.0934 15.4261C18.1096 15.4261 18.9334 14.6023 18.9334 13.5861C18.9334 12.5699 18.1096 11.7461 17.0934 11.7461C16.0772 11.7461 15.2534 12.5699 15.2534 13.5861C15.2534 14.6023 16.0772 15.4261 17.0934 15.4261Z"
          fill="#F2F2F2"
        />
        <path
          d="M12.0802 15.4261C13.0964 15.4261 13.9202 14.6023 13.9202 13.5861C13.9202 12.5699 13.0964 11.7461 12.0802 11.7461C11.064 11.7461 10.2402 12.5699 10.2402 13.5861C10.2402 14.6023 11.064 15.4261 12.0802 15.4261Z"
          fill="#F2F2F2"
        />
        <path
          d="M6.90689 15.4261C7.9231 15.4261 8.74689 14.6023 8.74689 13.5861C8.74689 12.5699 7.9231 11.7461 6.90689 11.7461C5.89069 11.7461 5.06689 12.5699 5.06689 13.5861C5.06689 14.6023 5.89069 15.4261 6.90689 15.4261Z"
          fill="#F2F2F2"
        />
        <path
          d="M19.9202 4.33398H4.09355C3.88736 4.33398 3.72021 4.50113 3.72021 4.70732V5.90732C3.72021 6.1135 3.88736 6.28065 4.09355 6.28065H19.9202C20.1264 6.28065 20.2935 6.1135 20.2935 5.90732V4.70732C20.2935 4.50113 20.1264 4.33398 19.9202 4.33398Z"
          fill="#F2F2F2"
        />
      </svg>
    </SvgIcon>
  );
};

AzIpSvgIcon.muiName = "AzIpSvgIcon";

export default AzIpSvgIcon;
