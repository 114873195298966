import { FC, useCallback, useState } from "react";
import { Stack } from "@mui/material";
import { ResourceExplorerToolbar } from "./components/resource-explorer-toolbar/ResourceExplorerToolbar";
import { ResourceExplorerAppBarNavigation } from "./components/breadcrumb-navigation/ResourceExplorerAppBarNavigation";
import { ResourceExplorerAppBarActions } from "./components/ResourceExplorerAppBarActions";
import { ResourceExplorerActionType } from "../../utils/types/types";
import { PageHeader } from "../../../common/PageHeader";
import { useAppSelector } from "../../../../../store/hooks";
import { resourceExplorerWidgetDataLoadingSelector } from "../../../../../store/resource-explorer/selectors/loading/resourceExplorerWidgetDataLoadingSelector";
import { resourceExplorerViewByIdLoadingSelector } from "../../../../../store/resource-explorer/selectors/resource-explorer-by-id/resourceExplorerViewByIdLoadingSelector";
import { ResourceExplorerCreateFolderOrItemDialogWrapper } from "../../../../navigation/components/resource-explorer-v2/components/common/ResourceExplorerCreateFolderOrItemDialogWrapper";

interface ResourceExplorerAppBarProps {
  viewId: string;
}

export const ResourceExplorerAppBar: FC<ResourceExplorerAppBarProps> = ({
  viewId,
}) => {
  const [dialogType, setDialogType] = useState<
    ResourceExplorerActionType | undefined
  >();
  const widgetsGettingLoading = useAppSelector(
    resourceExplorerWidgetDataLoadingSelector,
  );
  const viewGettingLoading = useAppSelector((state) =>
    resourceExplorerViewByIdLoadingSelector(state, viewId),
  );

  const loading = widgetsGettingLoading || viewGettingLoading;

  const openDialogHandler = useCallback(
    (type: ResourceExplorerActionType) => {
      setDialogType(type);
    },
    [setDialogType],
  );

  const closeDialogHandler = useCallback(() => {
    setDialogType(undefined);
  }, [setDialogType]);

  return (
    <PageHeader
      sticky
      loading={loading}
      breadcrumbs={<ResourceExplorerAppBarNavigation viewId={viewId} />}
      actions={
        <ResourceExplorerAppBarActions
          loading={loading}
          viewId={viewId}
          onSaveClick={openDialogHandler}
        />
      }
      extra={
        <Stack spacing={1}>
          <ResourceExplorerToolbar />

          {dialogType && (
            <ResourceExplorerCreateFolderOrItemDialogWrapper
              type="item"
              title="Save as New View"
              onClose={closeDialogHandler}
              viewId={viewId}
            />
          )}
        </Stack>
      }
    />
  );
};
