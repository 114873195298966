import { FC } from "react";
import { Stack } from "@mui/material";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { ResourceFiltersTextViewSourceButton } from "./sourceButtons/ResourceFiltersTextViewSourceButton";
import { WithResourceType } from "../../../../utils/types";
import { LiveUsageMgmtResourceFilters } from "../../../filters/resource-filters/LiveUsageMgmtResourceFilters";

export const ResourceFiltersTextView: FC<WithResourceType> = ({
  resourceType,
}) => {
  return (
    <Stack direction="row" spacing={0.5}>
      <FilterAltIcon fontSize="small" color="primary" />
      <LiveUsageMgmtResourceFilters
        resourceType={resourceType}
        SourceButton={ResourceFiltersTextViewSourceButton}
      />
    </Stack>
  );
};
