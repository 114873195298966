import { Account, ProviderType } from "../../../services/cloudchipr.api";
import { AccountGroupedByProviderAndOrgType } from "../utils/types/types";

export const allAccountsGroupedByOrgsCombiner =
  (provider: ProviderType) =>
  (
    accounts: AccountGroupedByProviderAndOrgType | null,
  ): Record<string, Account[]> | null => {
    if (!accounts) {
      return null;
    }

    return accounts[provider];
  };
