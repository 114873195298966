import { FC } from "react";
import { TypographyWithTooltip } from "../../../../../../../../../common/TypographyWithTooltip";

interface WithTooltipCellProps {
  data: string;
}

export const WithTooltipCell: FC<WithTooltipCellProps> = ({ data }) => {
  return <TypographyWithTooltip title={data} variant="body2" />;
};
