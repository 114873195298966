import { createDraftSafeSelector } from "@reduxjs/toolkit";
import { stateAccountsSelector } from "./stateAccountsSelector";
import { activeAccountStatuses } from "../../../../utils/constants/accounts";

export const activeStatusAccountObjectDataSelector = createDraftSafeSelector(
  [stateAccountsSelector],
  (accounts) => {
    return accounts?.reduce(
      (acc, account) => {
        if (activeAccountStatuses.has(account.status)) {
          acc[account.id] = true;
        }

        return acc;
      },
      {} as Record<string, boolean>,
    );
  },
);
