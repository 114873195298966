import { FC, useCallback, useMemo } from "react";
import { Stack, Step, StepButton, Stepper, Theme } from "@mui/material";
import { SxProps } from "@mui/system";
import { setAutomationStep } from "../../../../../../../store/automations/automationsSlice";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../store/hooks";
import { automationCurrentStepSelector } from "../../../../../../../store/automations/selectros/common/automationCurrentStepSelector";
import { AutomationSteps } from "../../../../../../../store/automations/utils/types/common";
import { automationHasErrorSelector } from "../../../../../../../store/automations/selectros/common/errors/automationHasErrorSelector";
import { validateAutomationDataThunk } from "../../../../../../../store/automations/thunks/validateAutomationDataThunk";
import { automationTypeSelector } from "../../../../../../../store/automations/selectros/common/automationTypeSelector";

type Steps = Record<
  AutomationSteps,
  { label: string; value: number; enabled: boolean; completed?: boolean }
>;

interface AutomationsStepperProps {
  inDrawer?: boolean;
}

export const AutomationStepper: FC<AutomationsStepperProps> = ({
  inDrawer,
}) => {
  const dispatch = useAppDispatch();
  const activeStep = useAppSelector(automationCurrentStepSelector);
  const hasErrors = useAppSelector(automationHasErrorSelector);
  const automationType = useAppSelector(automationTypeSelector);

  const steps: Steps = useMemo(() => {
    return {
      setup: {
        label: `Configure ${automationType === "offHours" ? "Off Hours" : "Workflow"}`,
        value: 0,
        enabled: true,
        completed: !hasErrors,
      },
      preview: { label: "Review", value: 1, enabled: true },
    };
  }, [hasErrors, automationType]);

  const activeStepValue = steps[activeStep].value;

  const styles = useMemo(() => {
    const styles: SxProps<Theme> = { p: 0, width: 300 };

    if (inDrawer) {
      styles.position = "sticky";
      styles.top = 90;
      styles.zIndex = (theme: Theme) => theme.zIndex.appBar + 1;
    }

    return styles;
  }, [inDrawer]);

  const stepChangeHandler = useCallback(
    async (step: AutomationSteps) => {
      if (step === "preview") {
        const errors = await dispatch(validateAutomationDataThunk()).unwrap();

        if (errors) {
          return;
        }
      }

      dispatch(setAutomationStep(step));
    },
    [dispatch],
  );

  return (
    <Stack alignItems="center">
      <Stepper activeStep={activeStepValue} sx={styles}>
        {Object.entries(steps).map(
          ([key, { label, value, completed, enabled }]) => (
            <Step
              key={label}
              completed={completed && activeStepValue !== value}
            >
              <StepButton
                disabled={!enabled}
                sx={{ py: 0.5, whiteSpace: "nowrap" }}
                onClick={stepChangeHandler.bind(null, key as AutomationSteps)}
              >
                {label}
              </StepButton>
            </Step>
          ),
        )}
      </Stepper>
    </Stack>
  );
};
