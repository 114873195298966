import { FC } from "react";
import { Stack } from "@mui/material";
import { LiveUsageMgmtRegionsSelect } from "./regions/LiveUsageMgmtRegionsSelect";
import { LiveUsageMgmtAccountBreadcrumbs } from "./account-breadcrumbs/LiveUsageMgmtAccountBreadcrumbs";
import { LiveUsageMissingPermissions } from "./missing-permissions/LiveUsageMissingPermissions";
import { LiveUsageMgmtToolbar } from "./toolbar/LiveUsageMgmtToolbar";
import { useAppSelector } from "../../../../../store/hooks";
import { PageHeader } from "../../../common/PageHeader";
import { liveUsageMgmtProviderSelector } from "../../../../../store/live-usage-mgmt/selectors/store-selectors/liveUsageMgmtProviderSelector";

export const LiveUsageMgmtHeader: FC = () => {
  const provider = useAppSelector(liveUsageMgmtProviderSelector);

  if (!provider) {
    return null;
  }
  return (
    <PageHeader
      title={<LiveUsageMgmtAccountBreadcrumbs />}
      actions={
        <Stack direction="row" spacing={2} alignItems="center">
          <LiveUsageMissingPermissions />

          {provider && <LiveUsageMgmtRegionsSelect provider={provider} />}
        </Stack>
      }
      extra={<LiveUsageMgmtToolbar />}
    />
  );
};
