import { Typography } from "@mui/material";
import { ColumnSetupType } from "../../../../../../../../../../../storybook/data-grid/utils/types/types";
import { ReservationsCoverageDataWithId } from "../../../../../../../../common/utils/types";
import { money } from "../../../../../../../../../../../utils/numeral/money";
import { SavingsPlanCoverageGrouping } from "../../../../../../../../../../../services/cloudchipr.api";

export const getCoveredUsageColumn = (
  grouping: SavingsPlanCoverageGrouping,
): ColumnSetupType<ReservationsCoverageDataWithId> => {
  const header =
    grouping !== "commitment"
      ? "Spend Covered by this RI"
      : " Spend Covered by RIs";

  return {
    accessorKey: "covered_usage",
    header: () => (
      // reservation drawer grouping:resource,account
      <Typography
        align="right"
        width="100%"
        variant="body2"
        fontWeight="medium"
      >
        {header}
      </Typography>
    ),
    meta: { headerTitle: header },
    cell: ({ row }) => (
      <Typography align="right" pr={4} variant="body2">
        {money(row.original.covered_usage)}
      </Typography>
    ),
  };
};
