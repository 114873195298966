import { FC } from "react";
import { useContextDaysPropGetters } from "@rehookify/datepicker";
import { Grid, IconButton, Stack, Typography } from "@mui/material";
import { DPDay } from "@rehookify/datepicker/dist/types/day";

interface DateRangeCalendarDayProps {
  dpDay: DPDay;
  highlightToday?: boolean;
}

export const DateRangeCalendarDay: FC<DateRangeCalendarDayProps> = ({
  dpDay,
  highlightToday,
}) => {
  const { dayButton } = useContextDaysPropGetters();

  const range = dpDay.range;
  const rangeStart = range === "range-start";
  const rangeStartEnd = range === "range-start range-end";
  const willBeRangeStart = range === "will-be-range-start";
  const rangeEnd = range === "range-end";
  const willBeRangeEnd = range === "will-be-range-end";
  const willBeInRange = range === "will-be-in-range";
  const inRange = range === "in-range";

  const today = highlightToday && dpDay.now && !inRange;

  const startOrEnd =
    rangeStart ||
    rangeEnd ||
    willBeRangeStart ||
    willBeRangeEnd ||
    rangeStartEnd;

  const selected = startOrEnd || dpDay.selected;

  const gradientPosition = rangeStart || willBeRangeStart ? "left" : "right";

  return (
    <Grid item md={12 / 7} sx={{ opacity: !dpDay.disabled ? 1 : 0.3 }}>
      <Stack
        alignItems="center"
        justifyContent="center"
        bgcolor={inRange || willBeInRange ? "primary.light" : undefined}
        sx={{
          background: (theme) =>
            startOrEnd && !rangeStartEnd
              ? `linear-gradient(to ${gradientPosition}, ${theme.palette.primary.light} 35%, white 65%)`
              : undefined,
        }}
      >
        <IconButton
          disabled={dpDay.disabled}
          disableRipple={!!range}
          sx={{
            width: 30,
            height: 30,
            bgcolor: selected ? "primary.main" : undefined,
            "&:hover": {
              bgcolor: selected ? "primary.main" : undefined,
            },
            border: today ? 1 : undefined,
            borderColor: today ? "primary.main" : undefined,
          }}
          {...dayButton(dpDay)}
        >
          <Typography
            key={dpDay.$date.toDateString()}
            variant="caption"
            color={selected ? "white" : "text.primary"}
            align="center"
          >
            {dpDay.day}
          </Typography>
        </IconButton>
      </Stack>
    </Grid>
  );
};
