import { FC, useCallback } from "react";
import { CommitmentsFilterSelect } from "./CommitmentsFilterSelect";
import { paymentFilterOptions } from "./utils/contants/filter-options/recommendationsFiltersOptions";
import { setCommitmentsRecommendationsPaymentOption } from "../../../../../../../../store/commitments/commitmentsSlice";
import { CommitmentPaymentOption } from "../../../../../../../../services/cloudchipr.api";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../store/hooks";
import { commitmentsRecommendationsPaymentOptionSelector } from "../../../../../../../../store/commitments/selectors/recommendations/filters/commitmentsRecommendationsPaymentOptionSelector";

export const CommitmentsPaymentOptionsFilter: FC = () => {
  const dispatch = useAppDispatch();
  const value = useAppSelector(commitmentsRecommendationsPaymentOptionSelector);

  const changeHandler = useCallback(
    (option: string[]) => {
      dispatch(
        setCommitmentsRecommendationsPaymentOption(
          option as CommitmentPaymentOption[],
        ),
      );
    },
    [dispatch],
  );

  if (!value) {
    return null;
  }

  return (
    <CommitmentsFilterSelect
      selectedValues={value}
      label="Payment Options"
      options={paymentFilterOptions}
      onChange={changeHandler}
      showSearch={false}
    />
  );
};
