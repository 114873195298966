import { FC, useCallback, useMemo } from "react";
import { Stack, Typography } from "@mui/material";
import { FilterTypeSelectTriggerComponent } from "./FilterTypeSelectTriggerComponent";
import { ResourceExplorerDynamicFilterItemType } from "../../../../../../../../../services/cloudchipr.api";
import { DropdownSelect } from "../../../../../../../../common/select/dropdown-select/DropdownSelect";
import { ProviderIcon } from "../../../../../../../../common/ProviderIcon";
import { isStringProviderType } from "../../../../../../../../../utils/helpers/providers/isStringProviderType";
import { useFilterTreeContext } from "../../../../FilterTreeProvider";
import { FilterTriggerComponentProps } from "../../../../../../../../common/select/dropdown-select/utils/types/filterTriggerComponentProps";
import { FilterItemNodeWithId } from "../../../../../utils/types/common";

interface FilterTypeSelectProps {
  filter: FilterItemNodeWithId;
  onChange(type: ResourceExplorerDynamicFilterItemType): void;
}

export const FilterTypeSelect: FC<FilterTypeSelectProps> = ({
  filter,
  onChange,
}) => {
  const provider = filter.filter_provider;
  const { possibleFilters } = useFilterTreeContext();

  const renderOptions = useMemo(() => {
    return possibleFilters
      .filter((option) => option.filter_provider === provider)
      .map((option) => ({
        value: option.key,
        rawValue: option,
        label: (
          <Stack direction="row" spacing={1}>
            {isStringProviderType(option.filter_provider) && (
              <ProviderIcon provider={option.filter_provider} />
            )}

            <Typography variant="body2">{option.label}</Typography>
          </Stack>
        ),
      }));
  }, [possibleFilters, provider]);

  const changeHandler = useCallback(
    (selected: string[]) => {
      const value = selected.at(0);

      if (!value || filter.type === value) {
        return;
      }

      onChange(value as ResourceExplorerDynamicFilterItemType);
    },
    [onChange, filter.type],
  );

  const TriggerComponent = useCallback(
    (props: FilterTriggerComponentProps) => {
      return (
        <FilterTypeSelectTriggerComponent {...props} provider={provider} />
      );
    },
    [provider],
  );

  return (
    <DropdownSelect
      singleSelect
      label="Filter"
      options={renderOptions}
      wrapperVariant="popover"
      disabled={renderOptions.length === 1}
      submitHandlerOnClose={changeHandler}
      values={filter.type ? [filter.type] : []}
      TriggerComponent={TriggerComponent}
    />
  );
};
