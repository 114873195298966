import { FC, useCallback } from "react";
import { Box, Button, Stack, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { ProviderIcon } from "../../../../../../../common/ProviderIcon";
import { BillingAccountStatusWithProvider } from "../../../../../../../../store/common/utils/types/types";
import { goAwsConsoleThunk } from "../../../../../../../../store/common/thunks/billing-status/goAwsConsoleThunk";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../store/hooks";
import { cfnChangeSetsLoadingSelector } from "../../../../../../../../store/common/thunks/billing-status/cfnChangeSetsLoadingSelector";
import { useAppAbility } from "../../../../../../../../services/permissions";

interface ResourceExplorerWarningBillingStatusPanelItemProps
  extends BillingAccountStatusWithProvider {
  onOpenDialog(): void;
}

export const ResourceExplorerWarningBillingStatusPanelItem: FC<
  ResourceExplorerWarningBillingStatusPanelItemProps
> = ({ onOpenDialog, name, provider, account_id, provider_account_id }) => {
  const dispatch = useAppDispatch();
  const awsConsoleLoading = useAppSelector(cfnChangeSetsLoadingSelector);

  const { cannot } = useAppAbility();
  const canNotGoToProviderConsole = cannot("edit", "resource-explorer");

  const goAwsConsoleHandler = useCallback(async () => {
    if (!account_id) {
      return;
    }
    dispatch(goAwsConsoleThunk(account_id));
  }, [account_id, dispatch]);

  return (
    <Stack bgcolor="grey.100" borderRadius={4} gap={1} p={2}>
      <Stack gap={1}>
        <Stack direction="row" gap={1}>
          <ProviderIcon provider={provider} />
          <Typography
            variant="subtitle1"
            fontWeight={500}
            sx={{ wordBreak: "break-all" }}
          >
            {provider !== "gcp" && account_id
              ? `Billing Exports Disabled for ${name || provider_account_id}`
              : `Billing Reports Disabled`}
          </Typography>
        </Stack>

        <Typography variant="body2">
          {provider === "aws"
            ? "Please execute the CloudFormation template from AWS console to enable cost usage reports."
            : "Please enable billing exports, to see complete billing information."}
        </Typography>
      </Stack>
      <Box display="flex" justifyContent="flex-end">
        {provider === "aws" && (
          <LoadingButton
            variant="outlined"
            onClick={goAwsConsoleHandler}
            disabled={canNotGoToProviderConsole || awsConsoleLoading}
            loading={awsConsoleLoading}
          >
            Go to AWS Console
          </LoadingButton>
        )}
        {provider === "gcp" && (
          <Button
            variant="outlined"
            onClick={onOpenDialog}
            disabled={canNotGoToProviderConsole}
          >
            Enable
          </Button>
        )}
      </Box>
    </Stack>
  );
};
