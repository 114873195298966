import { FC, useEffect } from "react";
import Stack from "@mui/material/Stack";
import { CommitmentsOverviewMetadata } from "./metadata/CommitmentsOverviewMetadata";
import { CommitmentsOverviewContent } from "./content/CommitmentsOverviewContent";
import { CommitmentsOverviewRecommendations } from "./recommendation/CommitmentsOverviewRecommendations";
import { getCommitmentsOverviewThunk } from "../../../../store/commitments/thunks/overview/getCommitmentsOverviewThunk";
import { commitmentsCurrentOrgIdSelector } from "../../../../store/commitments/selectors/common/commitmentsCurrentOrgIdSelector";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";

export const CommitmentsOverview: FC = () => {
  const dispatch = useAppDispatch();

  const orgId = useAppSelector(commitmentsCurrentOrgIdSelector);

  useEffect(() => {
    dispatch(getCommitmentsOverviewThunk());
  }, [dispatch, orgId]);

  return (
    <Stack spacing={1}>
      <CommitmentsOverviewMetadata />
      <CommitmentsOverviewContent />
      <CommitmentsOverviewRecommendations />
    </Stack>
  );
};
