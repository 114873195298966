import { FC, Fragment } from "react";
import { Divider, Typography } from "@mui/material";

interface IntegrationUtilizationAlertCoverageEmailHeaderProps {
  divider?: boolean;
}

export const IntegrationUtilizationAlertCoverageEmailHeader: FC<
  IntegrationUtilizationAlertCoverageEmailHeaderProps
> = ({ divider }) => {
  return (
    <Fragment>
      <Typography variant="body1">
        Subject:{" "}
        <Typography component="span" fontWeight="medium">
          Resource Type Coverage is Below {`{{ 50%}}`} - {`{{Alert Name}}`}
        </Typography>
      </Typography>

      {divider && <Divider />}

      <Typography variant="body1">Dear Customer,</Typography>

      <Typography variant="body1">
        The coverage of resource types has fallen below{" "}
        <Typography component="span" fontWeight="medium">
          {`{{50%}}`}
        </Typography>{" "}
        of its threshold.
      </Typography>
    </Fragment>
  );
};
