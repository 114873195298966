import { RootState } from "../../../store";
import { ResourceType } from "../../../../services/cloudchipr.api";
import { getProviderName } from "../../../../utils/helpers/providers/getProviderName";
import { generateTableFromTheJson } from "../../../../components/common/integration-dialogs/components/jira/utils/helpers/generateTableFromTheJson";
import { getResourceTypeName } from "../../../../utils/helpers/resources/getResourceTypeName";
import { liveUsageMgmtSelectedResourcesByResourceTypeSelector } from "../../../live-usage-mgmt/selectors/resource-type-data/liveUsageMgmtSelectedResourcesByResourceTypeSelector";
import { liveUsageMgmtProviderSelector } from "../../../live-usage-mgmt/selectors/store-selectors/liveUsageMgmtProviderSelector";
import { liveUsageMgmtSelectedResourcesAccountsMetaDataSelector } from "../../../live-usage-mgmt/selectors/resource-type-data/liveUsageMgmtSelectedResourcesAccountsMetaDataSelector";
import { generateAccountIdsQueryParams } from "../../../../components/pages/live-usage-mgmt/utils/helpers/generateAccountIdsQueryParams";
import { generateExportedResourceData } from "../../../../components/pages/live-usage-mgmt/components/resource-card/resource-card-content/resource-card-data/resource-card-grid/resouce-card-data-grid/utils/csv-data/generateExportedResourceData";

export const jiraDescriptionSelectorV2 = (
  state: RootState,
  resourceType: ResourceType,
) => {
  const accountType = liveUsageMgmtProviderSelector(state);
  const accountsData = liveUsageMgmtSelectedResourcesAccountsMetaDataSelector(
    state,
    resourceType,
  );
  const selectedResources =
    liveUsageMgmtSelectedResourcesByResourceTypeSelector(state, resourceType);

  if (!selectedResources) {
    return "";
  }

  const csvData = generateExportedResourceData(
    resourceType,
    selectedResources,
    ["issues"],
    { onlyNameTag: true },
  );

  const resource = getResourceTypeName(resourceType, {
    type: "long",
  });
  const provider = accountType
    ? getProviderName(accountType, { title: true })
    : "";

  const accountUrls = accountsData?.reduce((result, item) => {
    const url = `${window.location.origin}/${item.provider}/live-usage/${generateAccountIdsQueryParams([item.id])}`;
    const comma = result.length ? "," : "";
    result =
      result + ` <a href="${url}" target="_blank">${comma}${item.name}</a>`;
    return result;
  }, "");

  let description = `<b>${provider}</b> - ${accountUrls} <br/> <b>${resource}</b> <br/> <br/> `;

  if (csvData) {
    const table = generateTableFromTheJson(csvData);

    if (table) {
      description += table.innerHTML;
    }
  }

  return description;
};
