import { ColumnSetupType } from "../../../../../../../../../../../../../storybook/data-grid/utils/types/types";
import { ResourceItemType } from "../../../../../../../../../../../../../store/live-usage-mgmt/utils/types/types";

export const generateLiveFilteredResourcesColumns = (
  columns: ColumnSetupType<ResourceItemType>[] | null,
  options?: { includeFilterTemplate?: boolean },
): ColumnSetupType<ResourceItemType>[] | null => {
  if (!columns) {
    return null;
  }

  const monthlyPriceIndex =
    columns.findIndex((item) => item.accessorKey === "price_per_month") ?? 1;

  let extraColumns = addingColumns;
  if (!options?.includeFilterTemplate) {
    extraColumns = extraColumns.filter(
      (item) => item.type !== "filterTemplate",
    );
  }
  columns.splice(monthlyPriceIndex + 1, 0, ...extraColumns);
  columns[0] = { ...columns[0], type: "liveFilteredIdentifier" };

  return columns;
};

const addingColumns: ColumnSetupType<ResourceItemType>[] = [
  {
    accessorKey: "account",
    header: "Account",
    type: "liveFilteredAccount",
    size: 240,
    id: "account",
  },
  {
    accessorKey: "account",
    header: "Filter Template",
    type: "filterTemplate",
    size: 240,
    id: "filterTemplate",
  },
];
