import { createAsyncThunk } from "@reduxjs/toolkit";
import { setResourceExplorerDataGridView } from "../../resourceExplorerSlice";
import { resourceExplorerIdSelector } from "../../selectors/current-resource-explorer/resourceExplorerIdSelector";
import { RootState } from "../../../store";
import { ResourceExplorerDataGridView } from "../../utils/types/resourceExplorerDataGrid";
import { setResourceExplorerDataGridSavingData } from "../../../../components/pages/resource-explorer/components/resource-explorer-card/utils/helpers/resourceExplorerDataGridSavedDataGetterSetter/setResourceExplorerDataGridSavingData";
import { getResourceExplorerDataThunk } from "../widgets/getResourceExplorerDataThunk";
import { resourceExplorerPathLastItemFilterTreeSelector } from "../../selectors/current-resource-explorer/path/resourceExplorerPathLastItemFilterTreeSelector";

export const setResourceExplorerGridDataViewTypeThunk = createAsyncThunk(
  "resourceExplorer/setResourceExplorerGridDataViewType",
  async (viewType: ResourceExplorerDataGridView, { dispatch, getState }) => {
    const state = getState() as RootState;
    const viewId = resourceExplorerIdSelector(state);
    if (!viewId) {
      return;
    }

    const filterTreeFromPath =
      resourceExplorerPathLastItemFilterTreeSelector(state);

    dispatch(setResourceExplorerDataGridView(viewType));
    setResourceExplorerDataGridSavingData(viewId, {
      viewType,
    });

    dispatch(getResourceExplorerDataThunk(filterTreeFromPath));
  },
);
