import { FC, useCallback } from "react";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../store/hooks";
import { rightsizingRecommendationsAvailableTypesSelector } from "../../../../../../../store/recommendations/selectors/rightsizing/filters/rightsizingRecommendationsAvailableTypesSelector";
import { DropdownSelect } from "../../../../../../common/select/dropdown-select/DropdownSelect";
import { setRecommendationsTypeFilter } from "../../../../../../../store/recommendations/recommendationsSlice";
import { RecommendationsTabType } from "../../../off-hours-recommendations/components/OffHoursRecommendationsContent";

interface TypeFilterProps {
  tab: RecommendationsTabType;
}

export const RightsizingTypeFilter: FC<TypeFilterProps> = ({ tab }) => {
  const dispatch = useAppDispatch();

  const types = useAppSelector((state) =>
    rightsizingRecommendationsAvailableTypesSelector(state, tab),
  );

  const options = types.map((type) => ({
    value: type,
    label: type,
  }));

  const changeHandler = useCallback(
    (types: string[]) => {
      dispatch(setRecommendationsTypeFilter(types));
    },
    [dispatch],
  );

  return (
    <DropdownSelect
      label="Resource Types"
      options={options}
      submitHandlerOnClose={changeHandler}
    />
  );
};
