import { FC, memo, useCallback, useState } from "react";
import { MobileTimePicker } from "@mui/x-date-pickers/MobileTimePicker";
import { IconButton, InputAdornment } from "@mui/material";
import moment from "moment";
import AccessTimeIcon from "@mui/icons-material/AccessTime";

interface TimePickerProps {
  value: string;
  error?: string | boolean;
  withIcon?: boolean;
  name: string;
  setFieldValue(key: string, date: string | null): void;
}

export const TimePicker: FC<TimePickerProps> = memo(
  ({ setFieldValue, value, error, name, withIcon }) => {
    const [open, setOpen] = useState(false);

    const handleOpen = useCallback(() => setOpen(true), []);
    const handleClose = useCallback(() => setOpen(false), []);

    const dateAcceptHandler = useCallback(
      (value: any) => {
        if (value) {
          setFieldValue(name, moment(value).format());
        }
      },
      [setFieldValue, name],
    );

    // As we don't wanna react after every date change, we react only after onAccept
    const onChange = useCallback(() => {}, []);

    return (
      <MobileTimePicker
        ampm
        open={open}
        onOpen={handleOpen}
        onClose={handleClose}
        value={value ? moment(value) : null}
        label="Time"
        onAccept={dateAcceptHandler}
        onChange={onChange}
        slotProps={{
          textField: {
            fullWidth: true,
            size: "small",
            variant: "outlined",
            error: !!error,
            sx: { width: withIcon ? 160 : 90 },
            InputProps: {
              endAdornment: withIcon ? (
                <InputAdornment position="end">
                  <IconButton edge="end" onClick={handleOpen}>
                    <AccessTimeIcon />
                  </IconButton>
                </InputAdornment>
              ) : undefined,
            },
          },
        }}
      />
    );
  },
);
