import * as Yup from "yup";
import { useCallback } from "react";
import ValidationError from "yup/lib/ValidationError";
import { JiraSchemaTypes } from "../types/types";
import { usePostUsersMeJiraIntegrationsByIntegrationIdProjectsAndProjectIdIssueTypesIssueTypeFieldsMutation } from "../../../../../../../services/cloudchipr.api";
import { jiraCustomFieldsFetchFixedCacheKey } from "../constants/fixedCacheKeys";
import { jiraParagraphFieldKey } from "../../components/rows/custom/ParagraphField";

const validationBySchemaType: Record<JiraSchemaTypes, any> = {
  user: Yup.object().required("This field is required"),
  option: Yup.object().required("This field is required"),
  number: Yup.number().required("This field is required"),
  string: Yup.string().required("This field is required"),
  issuelink: Yup.object().required("This field is required"),
  [jiraParagraphFieldKey]: Yup.object().required("This field is required"),
  url: Yup.string().url("URL is incorrect").required("This field is required"),
  datetime: Yup.string().required("This field is required"),
  date: Yup.string().required("This field is required"),
  array: Yup.array()
    .min(1, "At least 1 item must be selected")
    .required("At least 1 item must be selected"),
  "option-with-child": Yup.object().required("This field is required"),
};

export function useJiraValidationHook() {
  const [, { data }] =
    usePostUsersMeJiraIntegrationsByIntegrationIdProjectsAndProjectIdIssueTypesIssueTypeFieldsMutation(
      { fixedCacheKey: jiraCustomFieldsFetchFixedCacheKey },
    );

  const validationFields: Record<string, any> = {};

  data?.forEach((customField) => {
    const key = customField.key;
    const schemaType = customField.schema.type as JiraSchemaTypes;
    const schemaCustomType = customField.schema.custom_type as JiraSchemaTypes;

    validationFields[key] =
      validationBySchemaType[schemaCustomType] ??
      validationBySchemaType[schemaType];
  });

  const validationSchema = Yup.object(validationFields);

  return useCallback(
    async (values: any) => {
      const response = validationSchema
        .validate(values, { abortEarly: false })
        .then(() => null)
        .catch((error) => {
          return error.inner.map((errObj: ValidationError) => {
            const key = (errObj.path || errObj?.params?.path) as string;
            if (!key) {
              return {};
            }

            return { [key]: errObj.message };
          });
        });

      return await response;
    },
    [validationSchema],
  );
}
