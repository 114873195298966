import { FC, useCallback } from "react";
import { TextField, Typography } from "@mui/material";
import { FormikHandlers } from "formik";
import { useDebounce } from "rooks";
import { useAppDispatch } from "../../../../../../../../../store/hooks";
import { setCurrentBudgetData } from "../../../../../../../../../store/budgets/budgetsSlice";

interface BudgetNameProps {
  name: string;
  handleChange: FormikHandlers["handleChange"];
}

export const BudgetName: FC<BudgetNameProps> = ({ name, handleChange }) => {
  const dispatch = useAppDispatch();

  const dispatchNameHandler = useCallback(
    (name: string) => {
      dispatch(setCurrentBudgetData({ name }));
    },
    [dispatch],
  );

  const dispatchNameDebounced = useDebounce(dispatchNameHandler, 500);

  const changeHandler = useCallback(
    (event: any) => {
      handleChange(event);
      dispatchNameDebounced(event.target.value);
    },
    [dispatchNameDebounced, handleChange],
  );

  return (
    <div>
      <Typography variant="body2" mb={1}>
        Budget Name*
      </Typography>

      <TextField
        fullWidth
        name="name"
        size="small"
        value={name}
        variant="outlined"
        onChange={changeHandler}
        placeholder="e.g. Engineering Department"
      />
    </div>
  );
};
