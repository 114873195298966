import { FC, Fragment, useCallback, useMemo } from "react";
import { Link, List, ListItem, Typography } from "@mui/material";
import {
  AccountMissingPermissions,
  PermissionType,
} from "../../../../../../services/cloudchipr.api";

interface MissingPermissionsTooltipProps {
  missingPermissions: AccountMissingPermissions;
}

type MissingPermissionsByType = {
  [key in PermissionType]: AccountMissingPermissions;
};

export const MissingPermissionsTooltip: FC<MissingPermissionsTooltipProps> = ({
  missingPermissions,
}) => {
  const missingPermissionsByType: MissingPermissionsByType = useMemo(() => {
    return missingPermissions?.reduce((acc, permission) => {
      let permissionType = permission?.type;

      if (!permissionType || permissionType === "iam_permission") {
        permissionType = "permission";
      }

      if (!acc[permissionType]) {
        acc[permissionType] = [permission];
      } else {
        acc[permissionType].push(permission);
      }

      return acc;
    }, {} as MissingPermissionsByType);
  }, [missingPermissions]);

  const stopLinkClickEventPropagation = useCallback((event: any) => {
    event.stopPropagation();
  }, []);

  return (
    <List sx={missingPermissionsTooltipStyles}>
      {Object.keys(missingPermissionsByType).map((permissionType) => {
        const permissions =
          missingPermissionsByType[permissionType as PermissionType];

        return (
          <Fragment key={permissionType}>
            <Typography variant="body2" fontWeight="medium">
              Missing {permissionType}s ({permissions?.length}):
            </Typography>
            {permissions?.map((permission) => (
              <ListItem
                sx={{ display: "list-item", p: 0, ml: 3 }}
                key={permission.name}
                onClick={stopLinkClickEventPropagation}
              >
                {permission.url ? (
                  <Link variant="caption" href={permission.url} target="_blank">
                    {permission.name}
                  </Link>
                ) : (
                  <Typography variant="caption">{permission.name}</Typography>
                )}
              </ListItem>
            ))}
          </Fragment>
        );
      })}
    </List>
  );
};

const missingPermissionsTooltipStyles = {
  listStyleType: "disc",
  px: 1,
  py: 1.5,
  maxHeight: 145,
  overflowY: "auto",
  overflowX: "hidden",
};
