import { createDraftSafeSelector } from "@reduxjs/toolkit";
import { Layouts } from "react-grid-layout";
import { dashboardWidgetsSelector } from "./dashboardWidgetsSelector";
import { currentDashboardSelector } from "./currentDashboardSelector";
import { generateDefaultLayoutFromWidgetIds } from "../../utils/helpers/dashboardLayout";

export const currentDashboardLayoutSelector = createDraftSafeSelector(
  [currentDashboardSelector, dashboardWidgetsSelector],
  (dashboard, widgets): Layouts => {
    const layout = dashboard?.layout as Layouts;

    if (!layout) {
      return generateDefaultLayoutFromWidgetIds(
        widgets.map((widget) => widget.id),
      );
    }

    return layout;
  },
);
