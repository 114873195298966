import { FC, Fragment } from "react";
import { Divider, Skeleton, Stack, Typography } from "@mui/material";
import { useEffectOnceWhen } from "rooks";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../store/hooks";
import { automationTimeZoneSelector } from "../../../../../../../store/automations/selectros/common/fields/automationTimeZoneSelector";
import { automationFrequencySelector } from "../../../../../../../store/automations/selectros/common/automationFrequencySelector";
import { workflowNextRunsAvailableSelector } from "../../../../../../../store/automations/selectros/workflow/next-runs/workflowNextRunsAvailableSelector";
import { ScheduledNextRunDateTime } from "../../../../common/components/next-runs-old/ScheduledNextRunDateTime";
import { ScheduledNextRunDateTimeZone } from "../../../../common/components/next-runs-old/ScheduledNextRunDateTimeZone";
import { ScheduledMoreNextRuns } from "../../../../common/components/next-runs-old/ScheduledMoreNextRuns";
import { scheduleFutureNextRunDatesWithoutFirstSelector } from "../../../../../../../store/automations/selectros/workflow/next-runs/old/scheduleFutureNextRunDatesWithoutFirstSelector";
import { scheduleNextRunDatesLoadingSelector } from "../../../../../../../store/automations/selectros/workflow/next-runs/old/scheduleNextRunDatesLoadingSelector";
import { scheduleNextRunDateTimeSelector } from "../../../../../../../store/automations/selectros/workflow/next-runs/old/scheduleNextRunDateTimeSelector";
import { fetchWorkflowNextRunsThunk } from "../../../../../../../store/automations/thunks/wokrflows/fetchWorkflowNextRunsThunk";

interface WorkflowNextRunsProps {
  asSeparateSection?: boolean;
  showTimeZone?: boolean;
}

export const WorkflowNextRuns: FC<WorkflowNextRunsProps> = ({
  asSeparateSection,
  showTimeZone,
}) => {
  const dispatch = useAppDispatch();
  const timeZone = useAppSelector(automationTimeZoneSelector);
  const frequency = useAppSelector(automationFrequencySelector);
  const nextRunsAvailable = useAppSelector(workflowNextRunsAvailableSelector);

  const futureRunDates = useAppSelector((state) =>
    scheduleFutureNextRunDatesWithoutFirstSelector(state, frequency),
  );
  const loading = useAppSelector((state) =>
    scheduleNextRunDatesLoadingSelector(state, frequency),
  );
  const nextRunDateTime = useAppSelector((state) =>
    scheduleNextRunDateTimeSelector(state, frequency),
  );

  useEffectOnceWhen(() => {
    dispatch(fetchWorkflowNextRunsThunk());
  }, nextRunsAvailable);

  if (!nextRunsAvailable) {
    return null;
  }

  return (
    <Fragment>
      {asSeparateSection && <Divider />}

      <Stack direction="row" minHeight={40}>
        {asSeparateSection && (
          <Typography variant="subtitle2" mr={2}>
            Next run:
          </Typography>
        )}

        <Stack direction="row" flex={1} spacing={1} alignItems="start">
          <Stack>
            {loading ? (
              <Skeleton variant="text" height={20} width={176} />
            ) : (
              <ScheduledNextRunDateTime nextRun={nextRunDateTime} />
            )}

            {showTimeZone && (
              <ScheduledNextRunDateTimeZone timeZone={timeZone} />
            )}
          </Stack>

          <ScheduledMoreNextRuns
            nextRunList={futureRunDates ?? []}
            timeZone={timeZone}
            disabled={loading}
          />
        </Stack>
      </Stack>
    </Fragment>
  );
};
