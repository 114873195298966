import { getUsersMeOrganisationsCurrentDashboardsByDashboardIdSelector } from "./getUsersMeOrganisationsCurrentDashboardsByDashboardIdSelector";
import { RootState } from "../../../store";

export const dashboardNotFoundSelector = (
  state: RootState,
  dashboardId?: string,
): boolean => {
  if (!dashboardId) {
    return false;
  }

  const errorStatus =
    getUsersMeOrganisationsCurrentDashboardsByDashboardIdSelector({
      dashboardId,
      // @ts-expect-error //TODO: api fix
    })(state)?.error?.status;

  return errorStatus === 404;
};
