import { createDraftSafeSelector } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { ApiScopeTuple } from "../../services/permissions";

const authState = (state: RootState) => state.auth;

export const authTokenSelector = createDraftSafeSelector(
  [authState],
  (state) => state.token,
);

export const authPermissionsSelector = createDraftSafeSelector(
  [authState],
  (state): ApiScopeTuple[] => {
    return state.permissions.map(
      (permission) => permission.split(":") as ApiScopeTuple,
    );
  },
);

export const logoutSelector = createDraftSafeSelector(
  [authState],
  (state): boolean => state.logout,
);
