import { createAsyncThunk } from "@reduxjs/toolkit";
import { setRecommendationsAdvancedFilters } from "../../recommendationsSlice";
import { cloudChiprApi } from "../../../../services/cloudchipr.api";

export const rightsizingRecommendationsAdvancedFiltersPreferenceKey =
  "c8r:RightsizingRecommendations";

export const getRightsizingRecommendationsAdvancedFiltersThunk =
  createAsyncThunk(
    "recommendations/getRightsizingRecommendationsAdvancedFiltersThunk",
    async (_, { dispatch }) => {
      const { getUsersMeCurrentPreferenceByKey } = cloudChiprApi.endpoints;

      const data = await dispatch(
        getUsersMeCurrentPreferenceByKey.initiate(
          {
            key: rightsizingRecommendationsAdvancedFiltersPreferenceKey,
          },
          { forceRefetch: true },
        ),
      ).unwrap();

      const advancedFilters = JSON.parse(data?.value ?? "");

      dispatch(setRecommendationsAdvancedFilters(advancedFilters));
    },
  );
