import { FC } from "react";
import { Button } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { grey, indigo } from "@mui/material/colors";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import AWSSvgIcon from "../../../../../../../../assets/images/logos/providers/AWSSvgIcon";

interface RightsizingAdvancedFilterTriggerProps {
  advancedFiltersOpen: boolean;
  toggleAdvancedFilter(): void;
}

export const RightsizingAdvancedFilterTrigger: FC<
  RightsizingAdvancedFilterTriggerProps
> = ({ advancedFiltersOpen, toggleAdvancedFilter }) => {
  return (
    <Button
      sx={{
        bgcolor: advancedFiltersOpen ? indigo[50] : grey[50],
        "&:hover": {
          bgcolor: advancedFiltersOpen ? indigo[50] : grey[100],
        },
        py: 1,
        px: 1.5,
        borderRadius: 2,
        color: "text.primary",
        textTransform: "none",
      }}
      onClick={toggleAdvancedFilter}
      startIcon={<AWSSvgIcon fontSize="small" />}
      size="xsmall"
      endIcon={
        advancedFiltersOpen ? (
          <KeyboardArrowUpIcon fontSize="small" />
        ) : (
          <KeyboardArrowDownIcon fontSize="small" />
        )
      }
    >
      {advancedFiltersOpen ? "Hide Advanced" : "Show Advanced"} Filters
    </Button>
  );
};
