import { colors, SvgIcon, SvgIconProps, useTheme } from "@mui/material";

const AzDiskSvgIcon = ({ primary }: SvgIconProps & { primary?: boolean }) => {
  const { palette } = useTheme();

  const color = primary ? palette.primary.main : palette.action.active;
  const colorDark = primary ? palette.primary.dark : palette.action.disabled;
  const colorLight = primary ? colors.indigo["300"] : palette.action.focus;

  return (
    <SvgIcon>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12.1999 16.6258C14.0335 16.6258 15.5199 16.2199 15.5199 15.7192C15.5199 15.2184 14.0335 14.8125 12.1999 14.8125C10.3663 14.8125 8.87988 15.2184 8.87988 15.7192C8.87988 16.2199 10.3663 16.6258 12.1999 16.6258Z"
          fill={colorDark}
        />
        <path
          d="M12.0005 19.7864C17.5338 19.7864 22.0005 17.9464 22.0005 15.6797V18.1064C21.8138 20.3064 17.4138 22.1064 12.0005 22.1064C6.58714 22.1064 2.00047 20.2664 2.00047 17.9997V15.6797C1.94714 17.9464 6.42714 19.7864 12.0005 19.7864Z"
          fill={color}
        />
        <path
          d="M11.9463 19.8676C17.4691 19.8676 21.9463 18.029 21.9463 15.761C21.9463 13.4929 17.4691 11.6543 11.9463 11.6543C6.42344 11.6543 1.94629 13.4929 1.94629 15.761C1.94629 18.029 6.42344 19.8676 11.9463 19.8676Z"
          fill={colorLight}
          fillOpacity="0.5"
        />

        <path
          d="M12.0005 10.6672C17.5338 10.6672 22.0005 8.82721 22.0005 6.56055V8.98721C21.8138 11.1872 17.4138 12.9872 12.0005 12.9872C6.58714 12.9872 2.00047 11.1472 2.00047 8.88055V6.56055C1.94714 8.82721 6.42714 10.6672 12.0005 10.6672Z"
          fill={color}
        />
        <path
          d="M11.9463 10.7602C17.4691 10.7602 21.9463 8.92159 21.9463 6.65354C21.9463 4.38549 17.4691 2.54688 11.9463 2.54688C6.42344 2.54688 1.94629 4.38549 1.94629 6.65354C1.94629 8.92159 6.42344 10.7602 11.9463 10.7602Z"
          fill={colorLight}
        />
        <path
          d="M12.1999 7.50669C14.0335 7.50669 15.5199 7.10076 15.5199 6.60003C15.5199 6.09929 14.0335 5.69336 12.1999 5.69336C10.3663 5.69336 8.87988 6.09929 8.87988 6.60003C8.87988 7.10076 10.3663 7.50669 12.1999 7.50669Z"
          fill={colorDark}
        />
      </svg>
    </SvgIcon>
  );
};

AzDiskSvgIcon.muiName = "AzDiskSvgIcon";

export default AzDiskSvgIcon;
