import { FC, useMemo } from "react";
import Chip from "@mui/material/Chip";
import { Stack, Tooltip } from "@mui/material";
import { ExecutionLogV3 } from "../../../../../../services/cloudchipr.api";

interface ExecutionLogsDataGridStatusCellProps {
  data: ExecutionLogV3["response"];
}

export const ExecutionLogsDataGridStatusCell: FC<
  ExecutionLogsDataGridStatusCellProps
> = ({ data }) => {
  const { succeed, failed, skipped } = useMemo(() => {
    return data.reduce(
      (acc, { resources }) => {
        resources.forEach(({ state }) => {
          if (state) {
            acc[state] += 1;
          }
        });

        return acc;
      },
      {
        succeed: 0,
        failed: 0,
        skipped: 0,
      },
    );
  }, [data]);

  return (
    <Stack direction="row" spacing={2}>
      {!!succeed && (
        <Tooltip
          title={`Action completed on ${succeed} resources.`}
          placement="top"
        >
          <Chip
            label={succeed}
            size="small"
            color="success"
            variant="outlined"
          />
        </Tooltip>
      )}

      {!!failed && (
        <Tooltip
          title={`Action failed on ${failed} resources.`}
          placement="top"
        >
          <Chip label={failed} size="small" color="error" variant="outlined" />
        </Tooltip>
      )}

      {!!skipped && (
        <Tooltip
          title={`Action skipped on ${skipped} resources.`}
          placement="top"
        >
          <Chip
            label={skipped}
            size="small"
            color="default"
            variant="outlined"
          />
        </Tooltip>
      )}
    </Stack>
  );
};
