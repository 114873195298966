import React, { FC, useCallback } from "react";
import { Alert, Button } from "@mui/material";
import {
  goToPrevStep,
  resetAccountAttempt,
  setPollingInterrupted,
} from "../../../../../store/account-setup/accountSetupSlice";
import { useAppDispatch, useAppSelector } from "../../../../../store/hooks";
import { pollingInterruptedSelector } from "../../../../../store/account-setup/selectors/pollingInterruptedSelector";

export const PollingInterruptedMessage: FC = () => {
  const pollingInterrupted = useAppSelector(pollingInterruptedSelector);
  const dispatch = useAppDispatch();

  const handleResetClick = useCallback(() => {
    dispatch(resetAccountAttempt());
    dispatch(goToPrevStep());
    setPollingInterrupted(false);
  }, [dispatch]);

  if (!pollingInterrupted) {
    return null;
  }

  return (
    <Alert
      variant="outlined"
      severity="warning"
      sx={{ mt: 2 }}
      action={
        <Button color="warning" onClick={handleResetClick}>
          RESET
        </Button>
      }
    >
      Status check stopped. You can reset and start creating account in
      Cloudchipr from scratch.
    </Alert>
  );
};
