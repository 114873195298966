import { Components, Theme } from "@mui/material";

export const muiStep: Components<Theme>["MuiStep"] = {
  styleOverrides: {
    root: ({ ownerState, theme }) => {
      return {
        "& .Mui-completed": {
          fill: theme.palette.primary.main,
        },
        "& .MuiStepButton-root:hover": {
          textDecoration: ownerState.completed ? "underline" : "unset",
        },
        "& .Mui-active.MuiStepLabel-label": {
          color: theme.palette.primary.main,
        },
      };
    },
  },
};
