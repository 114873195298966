import { DropdownSelectOption } from "../types/types";

export const filterOptionsByKeyword = (
  options: DropdownSelectOption[],
  keyword: string,
  filterFn?: (options: DropdownSelectOption, keyword: string) => boolean,
): DropdownSelectOption[] => {
  const cb = (option: DropdownSelectOption): boolean => {
    if (filterFn) {
      return filterFn(option, keyword);
    }

    return (
      option.value
        .trim()
        .toLowerCase()
        .includes(keyword.trim().toLowerCase()) ||
      option.label
        ?.toLocaleString()
        .trim()
        .toLowerCase()
        .includes(keyword.trim().toLowerCase()) ||
      !!option.nestedOptions?.some((option) => cb(option))
    );
  };

  return options.filter(cb).map((option) => {
    if (option.nestedOptions) {
      return {
        ...option,
        nestedOptions: filterOptionsByKeyword(
          option.nestedOptions,
          keyword,
          filterFn,
        ),
      };
    }

    return option;
  });
};
