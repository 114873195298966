import { FC } from "react";
import { Stack } from "@mui/material";
import { ResourceExplorerWarningBillingStatusPanelItem } from "./ResourceExplorerWarningBillingStatusPanelItem";
import { useAppSelector } from "../../../../../../../../store/hooks";
import { billingWarningAccountStatusesSelector } from "../../../../../../../../store/common/selectors/billing-status/warning/billingWarningAccountStatusesSelector";
import { ResourceExplorerBillingExportDialog } from "../../../../resource-explorer-card/components/resource-explorer-billing-export/components/resource-explorer-billing-export-dialog/ResourceExplorerBillingExportDialog";
import { useDialog } from "../../../../../../../../utils/hooks/useDialog.hook";

export const ResourceExplorerWarningBillingStatusPanel: FC = () => {
  const { open, openDialog, closeDialog } = useDialog();

  const warningAccountStatuses = useAppSelector(
    billingWarningAccountStatusesSelector,
  );

  return (
    <Stack spacing={2}>
      {warningAccountStatuses.map((item) => {
        return (
          <ResourceExplorerWarningBillingStatusPanelItem
            {...item}
            key={`${item.account_id}${item.provider}`}
            onOpenDialog={openDialog}
          />
        );
      })}
      <ResourceExplorerBillingExportDialog open={open} onClose={closeDialog} />
    </Stack>
  );
};
