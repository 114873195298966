import { FC } from "react";
import { Stack } from "@mui/material";
import TimerOutlinedIcon from "@mui/icons-material/TimerOutlined";
import { Schedule } from "../../../../../../../services/cloudchipr.api";
import { TypographyWithTooltip } from "../../../../../../common/TypographyWithTooltip";
import { convertSecondsToUnits } from "../../../../common/utils/helpers/gracePeriodCalculations";
import { automationsAllActions } from "../../../../../schedule/common/utils/constants/common";

interface WorkflowActionCellProps {
  schedule: Schedule;
}

export const WorkflowActionCell: FC<WorkflowActionCellProps> = ({
  schedule,
}) => {
  const { value: gracePeriodValue, message: gracePeriodMessage } =
    convertSecondsToUnits(schedule?.grace_period?.period ?? 0);
  const action = schedule.action && automationsAllActions[schedule.action];

  return (
    <Stack
      spacing={0.25}
      height="100%"
      overflow="hidden"
      justifyContent="center"
    >
      <TypographyWithTooltip title={action} variant="body2" />

      {!!gracePeriodValue && (
        <Stack direction="row" alignItems="center" spacing={0.5}>
          <TimerOutlinedIcon sx={{ fontSize: 14 }} color="action" />

          <TypographyWithTooltip
            variant="caption"
            color="text.disabled"
            title={`Grace Period: ${gracePeriodMessage}`}
          />
        </Stack>
      )}
    </Stack>
  );
};
