import { cloudChiprApi } from "../../../../services/cloudchipr.api";
import { RootState } from "../../../store";
import { updateDashboardFixedCacheKey } from "../../../../components/pages/dashboard/utils/constants/fixedCacheKeys";

const endpoint =
  cloudChiprApi.endpoints.patchUsersMeOrganisationsCurrentDashboardsByDashboardId.select(
    updateDashboardFixedCacheKey,
  );

export const patchDashboardVisibilityHierarchyByDashboardIdLoadingSelector = (
  state: RootState,
) => {
  return endpoint(state)?.isLoading;
};
