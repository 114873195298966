import { createDraftSafeSelector } from "@reduxjs/toolkit";
import { recommendationComputeActiveDataSelector } from "./compute/recommendationComputeActiveDataSelector";
import { recommendationDbActiveDataSelector } from "./database/recommendationDbActiveDataSelector";
import { recommendationGridDataCombiner } from "../../../combiners/off-hours/recommendationGridDataCombiner";
import { RootState } from "../../../../store";
import { RecommendationGroupType } from "../../../../../components/pages/recommendations/components/off-hours-recommendations/utils/types/types";

export const recommendationsDataGridDataSelector = createDraftSafeSelector(
  [
    recommendationComputeActiveDataSelector,
    recommendationDbActiveDataSelector,
    (_: RootState, group: RecommendationGroupType) => group,
  ],
  (computeData, dbData, group) => {
    const data = group === "compute" ? computeData : dbData;

    return recommendationGridDataCombiner(group, data);
  },
);
