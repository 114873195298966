import { Components, Theme } from "@mui/material";

declare module "@mui/material/FormControl" {
  interface FormControlPropsSizeOverrides {
    xsmall: true;
  }
}
export const muiFormControl: Components<Theme>["MuiFormControl"] = {
  styleOverrides: {
    root: ({ ownerState }) => {
      if (ownerState?.size === "xsmall") {
        return {
          "& .MuiInputBase-input ": {
            paddingTop: "4px",
            paddingBottom: "4px",
            fontSize: "0.75rem",
          },

          "& .MuiInputLabel-outlined.MuiFormLabel-root": {
            transform: "translate(12px, 4px) scale(1) !important",
            paddingRight: "8px !important",
          },
          "& .MuiInputLabel-outlined.MuiFormLabel-root.Mui-focused, .MuiInputLabel-outlined.MuiFormLabel-root.MuiFormLabel-filled":
            {
              transform: "translate(14px, -9px) scale(0.75) !important",
              paddingRight: "8px !important",
            },
        };
      }
    },
  },
};
