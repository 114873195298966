import { FC, Fragment, useCallback } from "react";
import { ProviderFilterCard } from "./ProviderFilterCard";
import { isTreeNodeFilterGroup } from "../../utils/helpers/isTreeNodeFilterGroup";
import { findFilterProviderInTree } from "../../utils/helpers/findFilterProviderInTree";
import { FilterGroupNodeWithId } from "../../utils/types/common";

interface ProvidersDynamicFiltersProps {
  filterTree: FilterGroupNodeWithId;
  setFilters(filters: FilterGroupNodeWithId): void;
}

export const ProvidersFiltersTree: FC<ProvidersDynamicFiltersProps> = ({
  filterTree,
  setFilters,
}) => {
  const filtersChangeHandler = useCallback(
    (newFilters: FilterGroupNodeWithId | null, index: number) => {
      if (newFilters === null) {
        setFilters({
          ...filterTree,
          items: filterTree.items.filter((_, i) => i !== index),
        });
      } else {
        setFilters({
          ...filterTree,
          items: filterTree.items.map((node, i) =>
            i === index ? newFilters : node,
          ),
        });
      }
    },
    [setFilters, filterTree],
  );

  return (
    <Fragment>
      {filterTree.items.map((node, index) => {
        if (!isTreeNodeFilterGroup(node)) {
          // first level can't be filter items, should be groups
          return null;
        }

        const filterProvider = findFilterProviderInTree(node.items);

        if (!filterProvider) {
          return null;
        }

        return (
          <ProviderFilterCard
            filters={node}
            groupIndex={index}
            key={filterProvider}
            filterProvider={filterProvider}
            onFiltersChange={filtersChangeHandler}
          />
        );
      })}
    </Fragment>
  );
};
