import { createAsyncThunk } from "@reduxjs/toolkit";
import { enqueueSnackbar } from "notistack";
import { DashboardWidgetCostBreakdownRequest } from "../../../../../../services/cloudchipr.api";
import { currentDashboardIdSelector } from "../../../../selectors/dashboard/currentDashboardIdSelector";
import { RootState } from "../../../../../store";
import { buildWidgetThunk } from "../../common/buildWidgetThunk";
import { costBreakdownWidgetSetupSelector } from "../../../../selectors/setups/cost-breakdown/costBreakdownWidgetSetupSelector";
import { getCostBreakdownWidgetDataByWidgetIdThunk } from "../getCostBreakdownWidgetDataByWidgetIdThunk";

export const buildCostBreakdownWidgetThunk = createAsyncThunk(
  "dashboards/buildCostBreakdownWidget",
  async (_, { dispatch, getState }) => {
    const state = getState() as RootState;

    const dashboardId = currentDashboardIdSelector(state);
    const setup = costBreakdownWidgetSetupSelector(state);

    if (!dashboardId || !setup?.viewId) {
      return;
    }

    const widget: DashboardWidgetCostBreakdownRequest = {
      name: setup.name,
      view_id: setup.viewId,
      grouping: setup.grouping,
      group_values: setup.group_values ?? undefined,
      type: "cost_breakdown",
      date_granularity: setup.frequency,
      //TODO: EnableWidgetsSideBarConfigurations, remove chart_type
      chart_type: setup.visualization.chart_type,
      visualization: setup.visualization,
      aggregation: setup.aggregation,
      layout: null,
      forecast_option: setup.forecastOption,
      dates: setup.dates,
      trend_type: setup.trendType,
    };

    try {
      const response = await dispatch(
        buildWidgetThunk({
          widgetId: setup?.id,
          widget,
        }),
      ).unwrap();

      if (response && setup?.id) {
        await dispatch(getCostBreakdownWidgetDataByWidgetIdThunk(response.id));
      }
      return response?.id;
    } catch (e) {
      // @ts-expect-error todo: api fix
      const errMessage = e?.data?.message || "Something went wrong";
      enqueueSnackbar(errMessage, {
        variant: "snackbarAlert",
        AlertSnackBarProps: { severity: "error" },
      });

      return null;
    }
  },
);
