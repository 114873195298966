import { FC } from "react";
import { Stack, Typography } from "@mui/material";
import { money } from "../../../../../../../utils/numeral/money";

interface CategoryTotalCostProps {
  totalCost?: number;
}

export const CategoryTotalCost: FC<CategoryTotalCostProps> = ({
  totalCost,
}) => {
  return (
    <Stack>
      <Typography variant="tiny" fontWeight="medium" color="text.secondary">
        Total Cost (MTD)
      </Typography>

      <Typography variant="body2" align="right" fontWeight="medium">
        {money(totalCost)}
      </Typography>
    </Stack>
  );
};
