import { FC, useCallback, useRef } from "react";
import { Button, IconButton, Stack, Tooltip } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import moment from "moment/moment";
import { Moment } from "moment";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import { formatDate } from "../../../../../../../utils/helpers/date-time/datetime-format";
import { useDialog } from "../../../../../../../utils/hooks/useDialog.hook";

interface TaskDueDateSelectionProps {
  value?: string | null;
  onChange(value: string | null): void;
}

export const TaskDueDateSelection: FC<TaskDueDateSelectionProps> = ({
  value,
  onChange,
}) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const { open, openDialog, closeDialog } = useDialog();

  const changeHandler = useCallback(
    (date: Moment | null) => {
      closeDialog();
      onChange(date ? formatDate(date, { type: "yearMonthDay" }) : null);
    },
    [closeDialog, onChange],
  );

  const isToday = moment(value).isSame(moment(), "day");
  const isPastDue = moment(value).isBefore(moment(), "day");

  const date = formatDate(value ?? "", { type: "date" });
  const label = getLabel(value);

  let buttonColor = isToday ? "#C77700" : "text.secondary";
  if (isPastDue) {
    buttonColor = "error.main";
  }

  return (
    <Stack alignItems="start" ref={ref}>
      {value ? (
        <Tooltip title={label ? date : ""} placement="top" arrow>
          <Button
            color="tertiary"
            onClick={openDialog}
            sx={{
              textTransform: "none",
              color: buttonColor,
              fontWeight: "normal",
            }}
          >
            {label ?? date}
          </Button>
        </Tooltip>
      ) : (
        <IconButton
          onClick={openDialog}
          sx={{
            border: "1px dashed",
            borderColor: "grey.400",
            whiteSpace: "nowrap",
          }}
        >
          <CalendarTodayIcon color="action" sx={{ fontSize: 16 }} />
        </IconButton>
      )}

      <DatePicker
        open={open}
        onClose={closeDialog}
        value={value ? moment(value) : null}
        onChange={changeHandler}
        slotProps={{
          textField: {
            error: false,
            size: "small",
            variant: "standard",
            sx: { width: 0, height: 0, p: 0 },
            InputProps: { endAdornment: null },
          },
          popper: {
            anchorEl: ref.current,
            placement: "bottom-start",
          },
        }}
      />
    </Stack>
  );
};

const getLabel = (value?: string | null) => {
  const isToday = moment(value).isSame(moment(), "day");
  if (isToday) {
    return "Today";
  }

  const isYesterday = moment(value).isSame(moment().subtract(1, "day"), "day");
  if (isYesterday) {
    return "Yesterday";
  }

  const isTomorrow = moment(value).isSame(moment().add(1, "day"), "day");
  if (isTomorrow) {
    return "Tomorrow";
  }

  return null;
};
