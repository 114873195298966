import { TextField } from "@mui/material";
import { FC, useCallback, useEffect, useState } from "react";
import { useDebounce } from "rooks";
import { useAppSelector } from "../../../../../../../../store/hooks";
import { liveUsageMgmtFilterTemplatesSelector } from "../../../../../../../../store/live-usage-mgmt/selectors/liveUsageMgmtFilterTemplatesSelector";

interface SaveSimplifiedFilterTemplatesDialogSaveAsNewProps {
  newFilterTemplateName: string;
  setNewFilterTemplateName(name: string): void;
  setSaveButtonDisabled(disabled: boolean): void;
}

export const SaveSimplifiedFilterTemplatesDialogSaveAsNew: FC<
  SaveSimplifiedFilterTemplatesDialogSaveAsNewProps
> = ({
  newFilterTemplateName,
  setNewFilterTemplateName,
  setSaveButtonDisabled,
}) => {
  const templates = useAppSelector(liveUsageMgmtFilterTemplatesSelector);
  const [duplicationError, setDuplicationError] = useState("");

  const validateName = useCallback(
    (newName: string) => {
      const duplicated = templates?.some(
        ({ name }) =>
          name.trim().toLowerCase() === newName.trim().toLowerCase(),
      );

      if (duplicated) {
        setDuplicationError(
          `Filter template named "${newName}" already exists.`,
        );
        setSaveButtonDisabled(true);
      } else {
        setDuplicationError("");
        setSaveButtonDisabled(false);
      }
    },
    [templates, setSaveButtonDisabled],
  );

  const validateNameDebounced = useDebounce(validateName, 300);

  const filterTemplateNameChangeHandler = useCallback(
    (event: any) => {
      const newName = event.target.value;
      setNewFilterTemplateName(newName);
      validateNameDebounced(newName);
    },
    [validateNameDebounced, setNewFilterTemplateName],
  );

  useEffect(() => {
    validateNameDebounced(newFilterTemplateName);
  }, [newFilterTemplateName, validateNameDebounced]);

  return (
    <TextField
      fullWidth
      size="small"
      label="Filter Template Name"
      variant="outlined"
      error={!!duplicationError}
      helperText={duplicationError}
      value={newFilterTemplateName}
      onChange={filterTemplateNameChangeHandler}
    />
  );
};
