import { cloudChiprApi } from "../../../../services/cloudchipr.api";
import { RootState } from "../../../store";
import { updateResourceExplorerFolderNameThunkFixedCacheKey } from "../../thunks/common/updateResourceExplorerFolderNameThunk";

const selector =
  cloudChiprApi.endpoints.patchV2UsersMeOrganisationsCurrentResourceExplorerFoldersByResourceExplorerFolderId.select(
    updateResourceExplorerFolderNameThunkFixedCacheKey,
  );

export const updateResourceExplorerFolderLoadingSelector = (
  state: RootState,
): boolean | undefined => {
  return selector(state)?.isLoading;
};
