import { savingsPlansCoverageDataSelector } from "../../data/savingsPlansCoverageDataSelector";
import { commitmentsDetailsDrawerTargetIdSelector } from "../../../common/details-drawer/commitmentsDetailsDrawerTargetIdSelector";
import { RootState } from "../../../../../store";
import { SavingsPlanCoverageDataWithId } from "../../../../../../components/pages/commtiments/common/utils/types";

export const savingsPlanSelectedCoverageDataSelector = (
  state: RootState,
): SavingsPlanCoverageDataWithId | undefined => {
  const coverageData = savingsPlansCoverageDataSelector(state);
  const id = commitmentsDetailsDrawerTargetIdSelector(state);

  return coverageData?.find((data) => data.id === id);
};
