export const mutationFirstCacheKey = "first_cache_key";

export const preDefinedQuestions = [
  "Show me how much we spend on EC2 on average",
  "Show me how much I spend on GPU nodes on average",
  // "Show me the total size of all GP2 volumes in GBs",
  "Show me all Regions and accounts where we have a spend more than $500 per month",
  "Show me all the service types that we are using and their total spend",
  "Show me the total number of CPUs, Memory and Disks that we have in the AWS",
  // "Show me how much we have spent on traffic for the last 5 days",
  "Show me the total spending of resources created by the user",
  "Show me the total spending on all AWS accounts for last month",
  "Show me the daily average spend on all AWS accounts this month so far",
  "Show me the total spending of all AWS RDS Resources for last month",
  "Show me the total spending of all AWS Load Balancers for last month",
  "Show me how much we spend on S3",
  // "",
  // "",
  "Show me the total spending breakdown based on Accounts",
  "Show me the breakdown of the total spending based on Services",
  // "",
  // "",
  "Show me how much I spend on old generation EBS volumes",
];
