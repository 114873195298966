import { FC } from "react";
import { Stack, Typography } from "@mui/material";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { TooltipText } from "../../../../../../../../../common/TooltipText";
import { InfoDataChip } from "../../../../../../widgets/common/widget-header/source-and-name/InfoDataChip";
import { Dates } from "../../../../../../../../../../services/cloudchipr.api";
import { formatPreviousDateData } from "../../../../../../../../../common/date-range-picker/utils/helpers/date-formatter/formatPreviousDateData";

interface LargestCostChangesPrevDateDetailsProps {
  prevDates?: Dates;
  quarterStartMonth?: number;
}

export const LargestCostChangesPrevDateDetails: FC<
  LargestCostChangesPrevDateDetailsProps
> = ({ prevDates, quarterStartMonth }) => {
  const formattedDate = formatPreviousDateData(prevDates, quarterStartMonth);

  return (
    <InfoDataChip>
      <Stack direction="row" spacing={0.5} alignItems="center" component="span">
        <AccessTimeIcon sx={{ fontSize: 18 }} />
        {formattedDate?.range ? (
          <TooltipText
            tooltipTitle={formattedDate.range}
            tooltipPlacement="top"
          >
            {formattedDate.label}
          </TooltipText>
        ) : (
          <Typography variant="body2" color="text.primary" component="span">
            {formattedDate?.label}
          </Typography>
        )}
      </Stack>
    </InfoDataChip>
  );
};
