import { createAsyncThunk } from "@reduxjs/toolkit";
import { getReservationsRecommendationsThunk } from "./getReservationsRecommendationsThunk";
import { setReservationsRecommendationsInitialFiltersThunk } from "./setReservationsRecommendationsInitialFiltersThunk";

export const initiateReservationCoverageDrawerThunk = createAsyncThunk(
  "commitments/initiateReservationCoverageDrawer",
  async (_: void, { dispatch, getState }) => {
    await dispatch(getReservationsRecommendationsThunk());
    dispatch(setReservationsRecommendationsInitialFiltersThunk());
  },
);
