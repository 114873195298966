import { FC, useCallback } from "react";
import { TextField } from "@mui/material";
import { taskManagementNameSelector } from "../../../../../../../store/task-management/selectors/form/properties/taskManagementNameSelector";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../store/hooks";
import { updateTaskManagementTask } from "../../../../../../../store/task-management/taskManagementSlice";
import { isTaskManagementDrawerEditModeSelector } from "../../../../../../../store/task-management/selectors/form/options/isTaskManagementDrawerEditModeSelector";

export const TaskCreationName: FC = () => {
  const dispatch = useAppDispatch();

  const name = useAppSelector(taskManagementNameSelector);
  const editMode = useAppSelector(isTaskManagementDrawerEditModeSelector);

  const handleChange = useCallback(
    (event: any) => {
      dispatch(updateTaskManagementTask({ name: event.target.value }));
    },
    [dispatch],
  );

  if (!editMode) {
    return null;
  }

  return (
    <TextField
      size="small"
      value={name}
      label="Task Name*"
      onChange={handleChange}
    />
  );
};
