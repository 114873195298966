import {
  cloudChiprApi,
  ResourceType,
} from "../../../../services/cloudchipr.api";
import { RootState } from "../../../store";

const selector =
  cloudChiprApi.endpoints.postUsersMeV2OrganisationsCurrentByTypeResources
    .select;
export const liveFilteredResourcesLoadingSelector = (
  state: RootState,
  resourceType: ResourceType,
) => {
  return selector(resourceType)(state)?.isLoading;
};
