import { createDraftSafeSelector } from "@reduxjs/toolkit";
import {
  getDailyActiveRecommendationComputeSelector,
  getDailyActiveRecommendationDbSelector,
} from "../recommendationDailyQuerySelector";
import {
  getWeeklyRecommendationComputeSelector,
  getWeeklyRecommendationDataBaseSelector,
} from "../recommendationWeeklyQuerySelector";

export const offHoursRecommendationsLoadingSelector = createDraftSafeSelector(
  [
    getDailyActiveRecommendationComputeSelector,
    getWeeklyRecommendationComputeSelector,
    getDailyActiveRecommendationDbSelector,
    getWeeklyRecommendationDataBaseSelector,
  ],
  (...all) => {
    return all.some((value) => value.isLoading);
  },
);
