import { groupingsExcludingForecastedCostsColumns } from "../../../../../../../../../resource-explorer/components/resource-explorer-card/components/resource-explorer-data-grid/components/utils/constants/groupingsExcludingForecastedCostsColumns";
import {
  ForecastOption,
  ResourceExplorerGrouping,
} from "../../../../../../../../../../../services/cloudchipr.api";
import { money } from "../../../../../../../../../../../utils/numeral/money";

interface GetCostBreakdownNumeralItemSecondaryLabelArgs {
  monthlyCost?: number;
  quarterlyCost?: number;
  forecastOption: ForecastOption;
  grouping?: ResourceExplorerGrouping;
}
export const getCostBreakdownNumeralItemSecondaryLabel = ({
  monthlyCost,
  quarterlyCost,
  forecastOption,
  grouping,
}: GetCostBreakdownNumeralItemSecondaryLabelArgs): string | undefined => {
  if (grouping && groupingsExcludingForecastedCostsColumns.has(grouping)) {
    return;
  }
  const forecastedCost: number | undefined =
    forecastOption === "month" ? monthlyCost : quarterlyCost;

  return !forecastedCost ? "-" : `~${money(forecastedCost)}`;
};
