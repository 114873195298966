import { GridApi } from "@ag-grid-community/core";
import { FC, useCallback, useState } from "react";
import { GlobalFilter } from "../../../../../../../storybook/data-grid/toolbar/GlobalFilter";

interface ReportsSearchProps {
  gridApi?: GridApi;
  size?: "small" | "medium" | "xsmall";
}

export const ReportsSearch: FC<ReportsSearchProps> = ({ gridApi, size }) => {
  const [searchValue, setSearchValue] = useState("");

  const searchChangeHandler = useCallback(
    (keyword: string) => {
      gridApi?.setGridOption("quickFilterText", keyword);
      setSearchValue(keyword);
    },
    [gridApi],
  );

  return (
    <GlobalFilter
      globalFilter={searchValue}
      setGlobalFilter={searchChangeHandler}
      size={size ?? "xsmall"}
    />
  );
};
