import { overviewDataSelector } from "./overviewDataSelector";
import { RootState } from "../../../store";

export const overviewTotalCoverageSelector = (
  state: RootState,
): number | null => {
  const data = overviewDataSelector(state);

  return data?.total_coverage ?? null;
};
