import { RootState } from "../../../../../store";
import { automationSliceSelector } from "../../../common/automationSliceSelector";

export const automationsAppliedFrequenciesFiltersSelector = (
  state: RootState,
) => {
  const slice = automationSliceSelector(state);

  return slice.filters.frequencies;
};
