import { FC } from "react";
import { Card, LinearProgress } from "@mui/material";

import { CostBreakdownWidgetSetupChartView } from "./CostBreakdownWidgetSetupChartView";
import { CostBreakdownWidgetSetupSubHeader } from "../components/CostBreakdownWidgetSetupSubHeader";
import { useAppSelector } from "../../../../../../../../../store/hooks";
import { widgetSetupContentChartHeight } from "../../../../../../utils/constants/stylings";
import { WidgetSetupContentWrapper } from "../../common/WidgetSetupContentWrapper";
import { CostBreakdownWidgetSetupTableView } from "../components/table-view/CostBreakdownWidgetSetupTableView";
import { CostBreakdownWidgetSetupNumeralView } from "../components/numeral-view/CostBreakdownWidgetSetupNumeralView";
import { costBreakdownSetupVisualizationPropertyByKeySelector } from "../../../../../../../../../store/dashboards/selectors/setups/cost-breakdown/costBreakdownSetupVisualizationPropertyByKeySelector";
import { costBreakdownWidgetSetupLoadingSelector } from "../../../../../../../../../store/dashboards/selectors/widgets/cost-breakdown/setup/costBreakdownWidgetSetupLoadingSelector";

export const CostBreakdownWidgetSetupContent: FC = () => {
  const setupChartType = useAppSelector(
    costBreakdownSetupVisualizationPropertyByKeySelector("chart_type"),
  );

  const chartType = setupChartType ?? "line";

  const loading = useAppSelector(costBreakdownWidgetSetupLoadingSelector);

  return (
    <WidgetSetupContentWrapper>
      <Card
        elevation={3}
        sx={{
          height: widgetSetupContentChartHeight,
          display: "flex",
          flexDirection: "column",
        }}
      >
        {loading && <LinearProgress />}

        <CostBreakdownWidgetSetupSubHeader />

        {chartType === "table" && <CostBreakdownWidgetSetupTableView />}

        {chartType === "numeral" && <CostBreakdownWidgetSetupNumeralView />}

        {!["numeral", "table"].includes(chartType) && (
          <CostBreakdownWidgetSetupChartView />
        )}
      </Card>
    </WidgetSetupContentWrapper>
  );
};
