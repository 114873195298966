import { createReportLoadingSelector } from "./createReportLoadingSelector";
import { editReportLoadingSelector } from "./editReportLoadingSelector";
import { RootState } from "../../store";

export const saveReportLoadingSelector = (state: RootState) => {
  const createReportLoading = createReportLoadingSelector(state);
  const editReportLoading = editReportLoadingSelector(state);

  return createReportLoading || editReportLoading;
};
