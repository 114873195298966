import { createDraftSafeSelector } from "@reduxjs/toolkit";
import { stateAccountsSelector } from "../slice-data/stateAccountsSelector";

export const accountsTotalSpendAndEmsSelector = createDraftSafeSelector(
  [stateAccountsSelector],
  (
    accounts,
  ): Record<string, { ems: number; totalSpend: number }> | undefined => {
    return accounts?.reduce(
      (acc, account) => {
        acc[account.id] = { ems: account.ems, totalSpend: account.total_costs };

        return acc;
      },
      {} as Record<string, { ems: number; totalSpend: number }>,
    );
  },
);
