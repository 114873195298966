import { InvitedUserRoles } from "../types/types";
import { UserRole } from "../../../../../../../services/cloudchipr.api";

export const userRoleLabels = new Map<UserRole, string>([
  ["owner", "Owner"],
  ["super_admin", "Super Admin"],
  ["admin", "Admin"],
  ["viewer", "Viewer"],
  ["billing_admin", "Billing Admin"],
  ["viewer_protector", "Viewer Protector"],
  ["workflow_resources_protector", "Workflow Resources Protector"],
]);

export const userRoleDescriptions = new Map<UserRole, string>([
  ["owner", "Has full access to all Cloudchipr features"],
  [
    "super_admin",
    "Has full access to all services and actions including user management. Can add, remove users and manage user roles.",
  ],
  [
    "admin",
    "Has full access to all Cloudchipr features except user invitation.",
  ],
  [
    "viewer",
    "Has full access to all Cloudchipr features with read-only privileges.",
  ],
  [
    "billing_admin",
    "Has read-only privileges to all the features and admin privileges in the Billing section.",
  ],
  [
    "viewer_protector",
    "Has the same privileges as the Viewer role, with the additional ability to protect resources.",
  ],
  [
    "workflow_resources_protector",
    "Has access to view or protect only the resources provided through the private link sent by workflows. Users with this role cannot access anything outside the private link.",
  ],
]);

export const userRoleOptions: {
  title: string;
  description: string;
  value: InvitedUserRoles;
}[] = [
  {
    value: "super_admin",
    title: userRoleLabels.get("super_admin") ?? "",
    description: userRoleDescriptions.get("super_admin") ?? "",
  },
  {
    value: "admin",
    title: userRoleLabels.get("admin") ?? "",
    description: userRoleDescriptions.get("admin") ?? "",
  },
  {
    value: "billing_admin",
    title: userRoleLabels.get("billing_admin") ?? "",
    description: userRoleDescriptions.get("billing_admin") ?? "",
  },
  {
    value: "viewer",
    title: userRoleLabels.get("viewer") ?? "",
    description: userRoleDescriptions.get("viewer") ?? "",
  },
  {
    value: "viewer_protector",
    title: userRoleLabels.get("viewer_protector") ?? "",
    description: userRoleDescriptions.get("viewer_protector") ?? "",
  },
  {
    value: "workflow_resources_protector",
    title: userRoleLabels.get("workflow_resources_protector") ?? "",
    description: userRoleDescriptions.get("workflow_resources_protector") ?? "",
  },
];

export const invitableRoles: InvitedUserRoles[] = [
  "super_admin",
  "admin",
  "billing_admin",
  "viewer",
  "workflow_resources_protector",
  "viewer_protector",
];
