import { RootState } from "../../../../store";
import { resourceExplorerWidgetDataSelector } from "../resource-explorer-widget/resourceExplorerWidgetDataSelector";
import { ForecastedCostRe } from "../../../../../services/cloudchipr.api";

export const resourceExplorerForecastedCostsSelector = (
  state: RootState,
): ForecastedCostRe | undefined => {
  const data = resourceExplorerWidgetDataSelector(state);

  return data?.forecasted_costs;
};
