import { FC } from "react";
import { useAppSelector } from "../../../../../../../../../../../store/hooks";
import { TotalCostColumnHeaderCell } from "../../../../../../../../../resource-explorer/components/resource-explorer-card/components/resource-explorer-data-grid/components/columns/components/common/TotalCostColumnHeaderCell";
import { costBreakdownWidgetTotalCostSelector } from "../../../../../../../../../../../store/dashboards/selectors/widgets/cost-breakdown/widget-view/costBreakdownWidgetTotalCostSelector";

interface CostBreakdownWidgetTableTotalCostHeaderCellProps {
  widgetId: string;
}
export const CostBreakdownWidgetTableTotalCostHeaderCell: FC<
  CostBreakdownWidgetTableTotalCostHeaderCellProps
> = ({ widgetId }) => {
  const total = useAppSelector((state) =>
    costBreakdownWidgetTotalCostSelector(state, widgetId),
  );

  return (
    <TotalCostColumnHeaderCell
      totalCost={total?.cost ?? 0}
      dateDetails={total?.dateDetails}
    />
  );
};
