import { createDraftSafeSelector } from "@reduxjs/toolkit";
import { step0FormDataSelector } from "./step0FormDataSelector";

export const step0CompletedSelector = createDraftSafeSelector(
  [step0FormDataSelector],
  (data) => {
    return !!(
      data?.viewId &&
      data?.name &&
      data?.period &&
      data?.startDate &&
      data?.amount
    );
  },
);
