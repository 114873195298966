import { recommendationAppliedFiltersSelector } from "./recommendationAppliedFiltersSelector";
import { RootState } from "../../../store";
import { TagsFilter } from "../../../../components/common/select/tag-filter-dropdown-select/utils/types";

export const recommendationAppliedTagsSelector = (
  state: RootState,
): TagsFilter | null => {
  const filters = recommendationAppliedFiltersSelector(state);

  return filters.tags ?? null;
};
