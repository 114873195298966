import { Box, Stack } from "@mui/material";
import { FC } from "react";
import { LegendItem } from "./LegendItem";
import { ChartColorsType, ReferenceLineType } from "../../../utils/types/types";
import { TypographyWithTooltip } from "../../../../../../components/common/TypographyWithTooltip";

interface LegendContentProps {
  keys: string[];
  highlightable: boolean;
  selectable: boolean;
  colors: ChartColorsType;
  visibleKeys: string[];
  setVisibleKeys(key: string[]): void;
  legendItemLabelFormatter?(key: string): string;
  referenceLines?: ReferenceLineType[];
}

export const LegendContent: FC<LegendContentProps> = ({
  colors,
  keys,
  highlightable,
  setVisibleKeys,
  selectable,
  visibleKeys,
  referenceLines,
  legendItemLabelFormatter,
}) => {
  return (
    <Stack
      mt={2}
      px={4}
      direction="row"
      flexWrap="wrap"
      alignItems="center"
      justifyContent="center"
    >
      {keys.map((key) => (
        <LegendItem
          key={key}
          itemKey={key}
          allKeys={keys}
          colors={colors}
          selectable={selectable}
          visibleKeys={visibleKeys}
          setVisibleKeys={setVisibleKeys}
          highlightable={highlightable}
          legendItemLabelFormatter={legendItemLabelFormatter}
        />
      ))}

      {referenceLines?.map((referenceLine) => {
        //todo; this is specific solution, maybe better to remove this from generic chart, or make this functionality more generic
        return (
          <Stack
            mb={1}
            mx={1}
            spacing={1}
            direction="row"
            alignItems="center"
            key={referenceLine.uniqueId}
          >
            <Box
              mr={0.5}
              height={0}
              width={12}
              border={
                referenceLine.legend?.type === "dashed" ? "1px dashed" : 2
              }
              borderColor={referenceLine.legend?.color}
            />

            <TypographyWithTooltip
              pt={0.25}
              title={referenceLine.legend?.label ?? ""}
              maxWidth={150}
              variant="caption"
              color="text.secondary"
            />
          </Stack>
        );
      })}
    </Stack>
  );
};
