import { AccountId, cloudChiprApi } from "../../../services/cloudchipr.api";
import { RootState } from "../../store";
import { getProtectResourceCacheKey } from "../utils/helpers";

export const cleanV2ResourcesProtectionLoadingSelector = (
  state: RootState,
  accountIds: AccountId[] | null,
  resourceIds: string[],
) => {
  const loadings: boolean[] = [];

  accountIds?.forEach((accountId) => {
    const loading =
      !!cloudChiprApi.endpoints.patchUsersMeAccountsByAccountIdResources.select(
        getProtectResourceCacheKey(accountId, resourceIds),
      )(state)?.isLoading;
    if (loading) {
      loadings.push(loading);
    }
  });

  return !!loadings.length;
};
