import {
  Recommendations,
  ResourceType,
} from "../../../../../../../../../../../services/cloudchipr.api";

type RecommendationType = (
  | Recommendations["right_sizing_recommendations"]
  | Recommendations["off_hours_recommendations"]
)[0];

export const getMaxSavingRecommendation = (
  recommendations?: RecommendationType[],
) => {
  if (!recommendations) {
    return;
  }

  const sorted = [...recommendations].sort((a, b) => {
    return b.potential_savings - a.potential_savings;
  });

  return sorted.at(0);
};

export const generateRightSizingRecommendationLink = (
  recommendationId?: string,
  accountId?: string,
) => {
  if (!recommendationId || !accountId) {
    return;
  }

  return `/recommendations/rightsizing/${recommendationId}?accountId=${accountId}`;
};
export const generateOffHoursRecommendationLink = (
  resourceType?: ResourceType,
  recommendation?: RecommendationType,
) => {
  if (!recommendation || !resourceType) {
    return;
  }

  return `/recommendations/off-hours/${resourceType}?id=${recommendation.id}`;
};
