import { ColumnSetupType } from "../../../../../../storybook/data-grid/utils/types/types";
import { AlertsDataGridNameCell } from "../common/AlertsDataGridNameCell";
import { UtilizationAlertDataGridDataType } from "../../../../../../store/alerts/utils/types/types";
import { TypographyWithTooltip } from "../../../../../common/TypographyWithTooltip";
import { alertsThresholdSortingFn } from "../common/utils/sortingFunctions/alertsThresholdSortingFn";
import { alertsCreatedByFilter } from "../common/utils/filterFunctions/alertsCreatedByFilter";
import { alertsCreatedBySortingFn } from "../common/utils/sortingFunctions/alertsCreatedBySortingFn";
import { NotificationsCell } from "../../../../../common/data-grid-cells/NotificationsCell";
import { alertsNotificationsFilter } from "../common/utils/filterFunctions/alertsNotificationsFilter";
import { alertsNotificationsSortingFn } from "../common/utils/sortingFunctions/alertsNotificationsSortingFn";
import { AlertsDataGridActionsCell } from "../common/actions/AlertsDataGridActionsCell";
import { utilizationAlertTypeTitles } from "../../drawer/components/utilization/utils/constants/constants";
import { UtilizationAlertsType } from "../../../utils/types/types";
import { UserActivityCell } from "../../../../../common/data-grid-cells/UserActivityCell";
import { alertsModifiedByFilter } from "../common/utils/filterFunctions/alertsModifiedByFilter";
import { AlertsDataGridThresholdCell } from "../common/AlertsDataGridThresholdCell";
import { alertsThresholdFilter } from "../common/utils/filterFunctions/alertsThresholdFilter";

export const alertsUtilizationDataGridColumns: ColumnSetupType<UtilizationAlertDataGridDataType>[] =
  [
    {
      accessorKey: "name",
      header: "Alert Name",
      minSize: 100,
      size: 160,
      cell: (cell) => {
        const alert = cell.row.original;
        return (
          <AlertsDataGridNameCell
            alertName={alert.name}
            alertId={alert.id}
            alertType="utilization"
          />
        );
      },
      meta: {
        sticky: "left",
        hideFromSettings: true,
        cellStyles: { bgcolor: "white" },
      },
    },
    {
      accessorKey: "based_on",
      header: "Alert Type",
      minSize: 100,
      size: 170,
      cell: (cell) => {
        const alertType = cell.getValue() as UtilizationAlertsType;

        return (
          <TypographyWithTooltip
            variant="inherit"
            title={utilizationAlertTypeTitles.get(alertType)}
          />
        );
      },
    },
    {
      id: "thresholds",
      accessorKey: "id",
      header: "Threshold",
      minSize: 100,
      size: 170,
      filterFn: alertsThresholdFilter,
      sortingFn: alertsThresholdSortingFn,
      cell: ({ row }) => {
        const thresholds = row.original.thresholds;
        return (
          <AlertsDataGridThresholdCell
            thresholds={thresholds}
            alertType="utilization"
          />
        );
      },
    },
    {
      accessorKey: "id",
      id: "modified_by",
      header: "Last Modified By",
      minSize: 150,
      size: 180,
      filterFn: alertsModifiedByFilter,
      sortingFn: alertsCreatedBySortingFn,
      cell: (cell) => {
        const alert = cell.row.original;
        const modifiedBy = alert.modified_by;

        return (
          <UserActivityCell
            date={alert.modified_at ?? ""}
            name={modifiedBy?.name ?? modifiedBy?.email ?? ""}
            deleted={modifiedBy?.name?.includes("deleted")}
          />
        );
      },
    },
    {
      accessorKey: "id",
      id: "created_by",
      header: "Created By",
      minSize: 100,
      size: 180,
      filterFn: alertsCreatedByFilter,
      sortingFn: alertsCreatedBySortingFn,
      cell: (cell) => {
        const alert = cell.row.original;
        const createdBy = alert.created_by;

        return (
          <UserActivityCell
            date={alert.created_at}
            name={createdBy?.name ?? createdBy?.email ?? ""}
            deleted={createdBy?.name?.includes("deleted")}
          />
        );
      },
    },
    {
      id: "notifications",
      accessorKey: "id",
      header: "Notification Method",
      minSize: 160,
      size: 220,
      filterFn: alertsNotificationsFilter,
      sortingFn: alertsNotificationsSortingFn,
      cell: ({ row }) => {
        const integrations = row.original.integrations;
        const emails = row.original.emails;

        return (
          <NotificationsCell emails={emails} integrations={integrations} />
        );
      },
    },
    {
      id: "actions",
      size: 110,
      enableResizing: false,
      enableGlobalFilter: false,
      cell: (cell) => {
        const alert = cell.row.original;

        return (
          <AlertsDataGridActionsCell
            alert={alert}
            alertsActiveTab="utilization"
          />
        );
      },
      meta: {
        sticky: "right",
        hideFromSettings: true,
        cellStyles: { bgcolor: "white", p: 0 },
      },
    },
  ];
