import { FC } from "react";
import { grey } from "@mui/material/colors";
import { Stack } from "@mui/material";
import { CommitmentUtilizationWidgetChart } from "./CommitmentUtilizationWidgetChart";
import { MultiTypeChartProvider } from "../../../../../../../storybook/charts/multi-type-chart/MultiTypeChartProvider";
import { commitmentUtilizationWidgetVisualizationChartTypeSelector } from "../../../../../../../store/dashboards/selectors/widgets/commitment-utilization/widget-view/commitmentUtilizationWidgetVisualizationChartTypeSelector";
import { useAppSelector } from "../../../../../../../store/hooks";
import { commitmentUtilizationWidgetVisualizationChartBaseSelector } from "../../../../../../../store/dashboards/selectors/widgets/commitment-utilization/widget-view/commitmentUtilizationWidgetVisualizationChartBaseSelector";
import { commitmentUtilizationWidgetByWidgetIdDataSelector } from "../../../../../../../store/dashboards/selectors/widgets/commitment-utilization/widget-view/commitmentUtilizationWidgetByWidgetIdDataSelector";
import { ChartType } from "../../../../../../../storybook/charts/multi-type-chart/utils/types/types";
import { commitmentUtilizationWidgetChartDataSelector } from "../../../../../../../store/dashboards/selectors/widgets/commitment-utilization/widget-view/commitmentUtilizationWidgetChartDataSelector";
import { commitmentUtilizationWidgetHasAggregationSelector } from "../../../../../../../store/dashboards/selectors/widgets/commitment-utilization/widget-view/commitmentUtilizationWidgetHasAggregationSelector";

interface CommitmentUtilizationWidgetChartViewProps {
  widgetId: string;
}

export const CommitmentUtilizationWidgetChartView: FC<
  CommitmentUtilizationWidgetChartViewProps
> = ({ widgetId }) => {
  const data = useAppSelector((state) =>
    commitmentUtilizationWidgetByWidgetIdDataSelector(state, widgetId),
  );
  const { groups } = data ?? {};
  const chartData = useAppSelector((state) =>
    commitmentUtilizationWidgetChartDataSelector(state, widgetId),
  );
  const chartType = useAppSelector((state) =>
    commitmentUtilizationWidgetVisualizationChartTypeSelector(state, widgetId),
  );
  const chartBase = useAppSelector((state) =>
    commitmentUtilizationWidgetVisualizationChartBaseSelector(state, widgetId),
  );
  const hasAggregations = useAppSelector((state) =>
    commitmentUtilizationWidgetHasAggregationSelector(state, widgetId),
  );

  return (
    <Stack flex={1} p={2} justifyContent={hasAggregations ? "start" : "center"}>
      <MultiTypeChartProvider
        value={{
          data: chartData ?? [],
          initialChartType: (chartType ?? "stack") as ChartType,
          disabledKeysSorting: true,
          colors,
        }}
      >
        <CommitmentUtilizationWidgetChart
          chartType={chartType as ChartType}
          chartBase={chartBase}
          groups={groups}
        />
      </MultiTypeChartProvider>
    </Stack>
  );
};

const colors = {
  Waste: grey[400],
  Utilization: "#9966FF",
};
