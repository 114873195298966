import { FC } from "react";
import Dialog from "@mui/material/Dialog";
import { IntegrationCreation } from "../../../../integrations/components/integrations-create/IntegrationCreation";
import { DialogTitleClosable } from "../../../../../common/dialog-utils/DialogTitleClosable";
import { IntegrationType } from "../../../../integrations/components/integrations-create/components/common/integration-type/IntegrationTypeCard";

interface IntegrationsDialogProps {
  open: boolean;
  onClose(): void;
  onNewIntegrationCreate(type: IntegrationType, integrationId: string): void;
  disabledIntegrationTypes?: IntegrationType[];
}

export const IntegrationsDialog: FC<IntegrationsDialogProps> = ({
  open,
  onClose,
  onNewIntegrationCreate,
  disabledIntegrationTypes,
}) => {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitleClosable title="Create integration" onClose={onClose} />

      <IntegrationCreation
        inDialog
        onCancel={onClose}
        onSubmit={onClose}
        onNewIntegrationCreate={onNewIntegrationCreate}
        disabledIntegrationTypes={disabledIntegrationTypes}
      />
    </Dialog>
  );
};
