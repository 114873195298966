import { FC } from "react";
import { Stack, TextField } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import { DropdownHeaderComponentProps } from "../../../../common/select/dropdown-select/utils/types/types";

export const HierarchySelectDropdownHeader: FC<
  DropdownHeaderComponentProps
> = ({ onSearchChange }) => {
  return (
    <Stack
      p={1}
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      spacing={2}
    >
      <TextField
        fullWidth
        autoFocus
        size="xsmall"
        variant="outlined"
        placeholder="Search..."
        onChange={onSearchChange}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
      />
    </Stack>
  );
};
