import { FC, useCallback } from "react";
import { ListItemIcon, ListItemText, MenuItem } from "@mui/material";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";

import { useAppAbility } from "../../../../../../../services/permissions";
import { toggleAlertsOpenDrawer } from "../../../../../../../store/alerts/alertsSlice";
import { useAppDispatch } from "../../../../../../../store/hooks";
import { AlertTabType } from "../../../../../../../store/alerts/utils/types/types";
import { setCostAnomalyAlertEditDataThunk } from "../../../../../../../store/alerts/thunks/cost-anomaly-alerts/setCostAnomalyAlertEditDataThunk";
import { setUtilizationAlertEditDataThunk } from "../../../../../../../store/alerts/thunks/utilization-alerts/setUtilizationAlertEditDataThunk";

interface AlertDataGridEditActionProps {
  alertId: string;
  closeMenu(): void;
  alertType: AlertTabType;
}

export const AlertDataGridEditAction: FC<AlertDataGridEditActionProps> = ({
  alertId,
  alertType,
  closeMenu,
}) => {
  const dispatch = useAppDispatch();

  const { cannot } = useAppAbility();
  const cannotEdit = cannot("edit", "alert");

  const handleClick = useCallback(() => {
    closeMenu();
    dispatch(toggleAlertsOpenDrawer());

    if (alertType === "costAnomaly") {
      dispatch(setCostAnomalyAlertEditDataThunk(alertId));
    } else {
      dispatch(setUtilizationAlertEditDataThunk(alertId));
    }
  }, [dispatch, closeMenu, alertId, alertType]);

  return (
    <MenuItem dense onClick={handleClick} disabled={cannotEdit}>
      <ListItemIcon>
        <EditOutlinedIcon fontSize="small" />
      </ListItemIcon>
      <ListItemText>Edit</ListItemText>
    </MenuItem>
  );
};
