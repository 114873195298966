import { FC, ReactNode, useCallback } from "react";
import { FormControl, Select, Stack, Typography } from "@mui/material";
import InsertChartOutlinedOutlinedIcon from "@mui/icons-material/InsertChartOutlinedOutlined";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { FilterTriggerComponentProps } from "../../../common/select/dropdown-select/utils/types/filterTriggerComponentProps";

export const SelectViewTrigger: FC<FilterTriggerComponentProps> = ({
  onClick,
  open,
  label,
}) => {
  const renderValue = useCallback((value: ReactNode) => {
    if (value) {
      return (
        <Stack direction="row" alignItems="center">
          <InsertChartOutlinedOutlinedIcon fontSize="small" sx={{ pr: 1 }} />
          {value}
        </Stack>
      );
    }
  }, []);

  return (
    <Stack>
      <Typography variant="body2" mb={1}>
        Select View*
      </Typography>
      <FormControl size="small" fullWidth>
        <Select
          onClick={onClick}
          size="small"
          open={false}
          value={label}
          IconComponent={open ? ArrowDropUpIcon : ArrowDropDownIcon}
          renderValue={renderValue}
        />
      </FormControl>
    </Stack>
  );
};
