export const actionFilterOptions = [
  { label: "Delete", value: "terminate" },
  { label: "Stop", value: "stop" },
  { label: "Start", value: "start" },
  { label: "Snapshot and Delete", value: "snapshot_and_delete" },
  { label: "Create Image & Delete", value: "create_image_and_delete" },
];

export const statusFilterOptions = [
  { label: "Succeed", value: "succeed" },
  { label: "Failed", value: "failed" },
  { label: "Skipped", value: "skipped" },
];
