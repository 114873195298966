import { createAsyncThunk } from "@reduxjs/toolkit";
import { getLiveUsageMgmtResourcesWithOutFiltersByProviderThunk } from "./getLiveUsageMgmtResourcesWithOutFiltersByProviderThunk";
import { resourceTypesByProvider } from "../../../../utils/helpers/providers/resourceTypesByProvider";
import { RootState } from "../../../store";
import { liveUsageMgmtProviderSelector } from "../../selectors/store-selectors/liveUsageMgmtProviderSelector";

export const getLiveUsageMgmtResourcesWithOutFiltersThunk = createAsyncThunk(
  "getCurrentAccountAllResourceTypesDataWithoutFilter",
  async (_: void, { dispatch, getState }) => {
    const state = getState() as RootState;
    const provider = liveUsageMgmtProviderSelector(state);

    if (!provider) {
      return;
    }

    const resourceTypes = resourceTypesByProvider(provider);
    resourceTypes?.forEach((resourceType) => {
      dispatch(
        getLiveUsageMgmtResourcesWithOutFiltersByProviderThunk(resourceType),
      );
    });
  },
);
