import { RootState } from "../../../../store";
import { resourceExplorerWidgetDataSelector } from "../resource-explorer-widget/resourceExplorerWidgetDataSelector";

export const resourceExplorerLiveUsageTotalCostSelector = (
  state: RootState,
): number | undefined => {
  const data = resourceExplorerWidgetDataSelector(state);

  return data?.live_usage_total_cost;
};
