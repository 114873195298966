import { ChartDataType } from "../../../../../../../../storybook/charts/multi-type-chart/utils/types/types";

export const calculateCostBreakdownTotal = (data: ChartDataType[]) => {
  let sum = 0;

  data?.forEach((chartDataItem) => {
    Object.keys(chartDataItem).forEach((key) => {
      if (typeof chartDataItem[key] === "string") {
        return;
      }

      sum = sum + (chartDataItem?.[key] ?? 0);
    });
  });

  return sum;
};
