import { createAsyncThunk } from "@reduxjs/toolkit";
import { createResourceExplorerVisibilityHierarchyFolderThunk } from "./createResourceExplorerVisibilityHierarchyFolderThunk";
import { createResourceExplorerVisibilityHierarchyItemThunk } from "./createResourceExplorerVisibilityHierarchyItemThunk";
import { createResourceExplorerVisibilityHierarchyItemByFolderIdThunk } from "./createResourceExplorerVisibilityHierarchyItemByFolderIdThunk";
import {
  NavigationHierarchyItemType,
  NavigationItemsVisibilityType,
} from "../../../navigation/utils/types";

interface CreateResourceExplorerVisibilityHierarchyFolderOrItemThunkArg {
  type: NavigationHierarchyItemType;
  folderId?: string;
  name: string;
  visibility: NavigationItemsVisibilityType;
  viewId?: string;
}
export const createResourceExplorerVisibilityHierarchyFolderOrItemThunk =
  createAsyncThunk(
    "resourceExplorer/createResourceExplorerVisibilityHierarchyFolderOrItem",
    async (
      {
        folderId,
        name,
        type,
        visibility,
        viewId,
      }: CreateResourceExplorerVisibilityHierarchyFolderOrItemThunkArg,
      { dispatch },
    ) => {
      let response;
      if (type === "item") {
        if (folderId) {
          response = await dispatch(
            createResourceExplorerVisibilityHierarchyItemByFolderIdThunk({
              name,
              folderId,
              visibility,
            }),
          ).unwrap();
        } else {
          response = await dispatch(
            createResourceExplorerVisibilityHierarchyItemThunk({
              name,
              visibility,
              refetch: true,
              viewId,
            }),
          ).unwrap();
        }
      } else {
        await dispatch(
          createResourceExplorerVisibilityHierarchyFolderThunk({
            name,
            visibility,
          }),
        );
      }

      return response;
    },
  );
