import { FC } from "react";
import { Stack } from "@mui/material";
import {
  LargestCostChangesWidgetCardHeaderDate,
  LargestCostChangesWidgetCardHeaderDateProps,
} from "./LargestCostChangesWidgetCardHeaderDate";
import { SourceDataChip } from "../../../common/widget-header/source-and-name/SourceDataChip";
import { WidgetTitleProps } from "../../../../../utils/types/types";
import { WidgetGroupingChip } from "../../../common/WidgetGroupingChip";

interface LargestCostChangesWidgetHeaderSourceDataProps
  extends LargestCostChangesWidgetCardHeaderDateProps,
    WidgetTitleProps {}

export const LargestCostChangesWidgetHeaderCardSourceData: FC<
  LargestCostChangesWidgetHeaderSourceDataProps
> = ({ prevDate, groupBy, groupValues, ...props }) => {
  return (
    <Stack direction="row" gap={1} alignItems="center" overflow="auto">
      {props.sourceData && <SourceDataChip {...props.sourceData} />}

      {groupBy && (
        <WidgetGroupingChip groupBy={groupBy} groupValues={groupValues} />
      )}

      <LargestCostChangesWidgetCardHeaderDate
        prevDate={prevDate}
        date={props.dates}
      />
    </Stack>
  );
};
