import { FC, Fragment } from "react";
import { Typography } from "@mui/material";
import { money } from "../../../../../../../../../../utils/numeral/money";
import { TooltipText } from "../../../../../../../../../common/TooltipText";

interface DataGridMoneyCellProps {
  data: number | null;
}

export const MoneyCell: FC<DataGridMoneyCellProps> = ({ data }) => {
  if (data === null) {
    return <Typography variant="body2">N/A</Typography>;
  }

  const price = money(data);

  if (data < 0.01 && data > 0) {
    return <TooltipText tooltipTitle={`$${data}`}>{price}</TooltipText>;
  }

  return <Fragment>{price}</Fragment>;
};
