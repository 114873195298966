import { FC, Fragment } from "react";
import { Divider, Typography } from "@mui/material";

interface IntegrationUtilizationAlertCommitmentsExpirationEmailHeaderProps {
  divider?: boolean;
}

export const IntegrationUtilizationAlertCommitmentsExpirationEmailHeader: FC<
  IntegrationUtilizationAlertCommitmentsExpirationEmailHeaderProps
> = ({ divider }) => {
  return (
    <Fragment>
      <Typography variant="body1">
        Subject:{" "}
        <Typography component="span" fontWeight="medium">
          Commitment Expiring Soon: {`{{7 Days Left}}`} - {`{{Alert Name}}`}
        </Typography>
      </Typography>

      {divider && <Divider />}

      <Typography variant="body1">Dear Customer,</Typography>

      <Typography variant="body1">
        Your commitment{" "}
        <Typography component="span" fontWeight="medium">
          {`{{Commitment ID}}`}
        </Typography>{" "}
        expires in{" "}
        <Typography component="span" fontWeight="medium">
          {`{{7 days}}`}
        </Typography>
        .
      </Typography>
    </Fragment>
  );
};
