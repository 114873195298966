import { FC, Fragment } from "react";
import Typography from "@mui/material/Typography";
import { useGetUsersMeOrganisationsCurrentSettingsQuery } from "../../../../../../services/cloudchipr.api";

export const SmartTagTTLDescription: FC = () => {
  const { data } = useGetUsersMeOrganisationsCurrentSettingsQuery();

  return (
    <Fragment>
      <Typography gutterBottom color="text.secondary">
        <Typography component="span" fontWeight="medium">
          Expiration{" "}
        </Typography>
        smart tag related actions will be triggered once{" "}
        <Typography component="span" fontWeight="medium">
          {data?.smart_tag_ttl?.value}{" "}
        </Typography>
        tag is used on supported resources.
      </Typography>
      <Typography color="text.secondary">
        It is possible to change the name used for Expiration smart tagging.
        <br />
        Please note the name is case insensitive.
      </Typography>
    </Fragment>
  );
};
