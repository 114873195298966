import { ColDef, ICellRendererParams } from "@ag-grid-community/core";
import { Typography } from "@mui/material";
import { ValueGetterParams } from "@ag-grid-community/core/dist/types/src/entities/colDef";
import { TaskGridRowActionsMenu } from "./cell-renderers/row-actions/TaskGridRowActionsMenu";
import { DueDateCellRenderer } from "./cell-renderers/DueDateCellRenderer";
import { StatusCellRenderer } from "./cell-renderers/StatusCellRenderer";
import { EnvironmentCellRenderer } from "./cell-renderers/EnvironmentCellRenderer";
import { PriorityCellRenderer } from "./cell-renderers/PriorityCellRenderer";
import { ActionCellRenderer } from "./cell-renderers/ActionCellRenderer";
import { AssigneeCellRenderer } from "./cell-renderers/AssigneeCellRenderer";
import { NameCellRenderer } from "./cell-renderers/NameCellRenderer";
import {
  GetUsersMeOrganisationsCurrentTasksPropertiesApiResponse,
  Task,
} from "../../../../../services/cloudchipr.api";
import { TypographyWithTooltip } from "../../../../common/TypographyWithTooltip";
import { AccountInfoData } from "../../../../common/AccountInfoData";
import { TagCell } from "../../../../common/data-grid-cells/tag-cell/TagCell";
import { TargetSingleResourceTableIStateRow } from "../../../common/task-management/components/form/content/target-section/resources/single-resource/TargetSingleResourceTableIStateRow";
import { money } from "../../../../../utils/numeral/money";
import { getResourceTypeName } from "../../../../../utils/helpers/resources/getResourceTypeName";
import { taskSourceLabels } from "../../utils/constants/labels";
import { taskAssigneesFilterText } from "../../utils/helpers/quick-filter-text-functions/taskAssigneesFilterText";
import { taskDueDateFilterText } from "../../utils/helpers/quick-filter-text-functions/taskDueDateFilterText";
import { taskTargetAccountFilterText } from "../../utils/helpers/quick-filter-text-functions/taskTargetAccountFilterText";
import { taskTargetTagFilterText } from "../../utils/helpers/quick-filter-text-functions/taskTargetTagFilterText";
import { actionComparatorFn } from "../../utils/helpers/comparator-functions/actionComparatorFn";
import { assigneesComparatorFn } from "../../utils/helpers/comparator-functions/assigneesComparatorFn";
import { priorityComparatorFn } from "../../utils/helpers/comparator-functions/priorityComparatorFn";
import { environmentComparatorFn } from "../../utils/helpers/comparator-functions/environmentComparatorFn";
import { statusComparatorFn } from "../../utils/helpers/comparator-functions/statusComparatorFn";
import { targetAccountComparatorFn } from "../../utils/helpers/comparator-functions/targetAccountComparatorFn";
import { targetTagComparatorFn } from "../../utils/helpers/comparator-functions/targetTagComparatorFn";
import { UserActivityCell } from "../../../../common/data-grid-cells/UserActivityCell";
import { taskCreationFilterText } from "../../utils/helpers/quick-filter-text-functions/taskCreationFilterText";
import { creationComparatorFn } from "../../utils/helpers/comparator-functions/creationComparatorFn";

const nameColumn: ColDef = {
  field: "name",
  pinned: "left",
  headerName: "Task Name",
  cellClass: "name-cell-classname",
  suppressColumnsToolPanel: true,
  cellStyle: { padding: 0 },
  cellRenderer: NameCellRenderer,
};

const resourceIdColumn: ColDef = {
  field: "target.provider_identifier",
  headerName: "Resource ID/Name",
  cellRenderer: ({ data }: ICellRendererParams<Task>) => {
    const target = data?.target;

    if (!target) {
      return null;
    }

    return (
      <TypographyWithTooltip
        title={target.provider_identifier}
        variant="body2"
      />
    );
  },
};

const getTaskRelatedColumns = (
  properties?: GetUsersMeOrganisationsCurrentTasksPropertiesApiResponse,
): ColDef[] => [
  {
    valueGetter: ({ data }: ValueGetterParams<Task>) => data?.action?.id,
    field: "action",
    headerName: "Action",
    cellRenderer: ActionCellRenderer,
    comparator: actionComparatorFn,
    getQuickFilterText: ({ data }) => `${data.action?.name}`,
  },
  {
    valueGetter: ({ data }: ValueGetterParams<Task>) =>
      data?.assignees?.map((assignee) => assignee.id).join(" "),
    field: "assignees",
    headerName: "Assignee",
    comparator: assigneesComparatorFn,
    cellRenderer: AssigneeCellRenderer,
    getQuickFilterText: taskAssigneesFilterText,
  },
  {
    valueGetter: ({ data }: ValueGetterParams<Task>) => data?.priority?.id,
    field: "priority",
    headerName: "Priority",
    comparator: priorityComparatorFn(properties?.priorities),
    cellRenderer: PriorityCellRenderer,
    getQuickFilterText: ({ data }) => `${data.priority?.name}`,
  },
  {
    field: "due_date",
    headerName: "Due Date",
    cellRenderer: DueDateCellRenderer,
    getQuickFilterText: taskDueDateFilterText,
  },
  {
    valueGetter: ({ data }: ValueGetterParams<Task>) => data?.environment?.id,
    field: "environment",
    headerName: "Environment",
    comparator: environmentComparatorFn,
    cellRenderer: EnvironmentCellRenderer,
    getQuickFilterText: ({ data }) => `${data.environment?.name}`,
  },
  {
    rowGroup: true,
    valueGetter: ({ data }: ValueGetterParams<Task>) => data?.status?.id,
    field: "status",
    headerName: "Status",
    cellRenderer: StatusCellRenderer,
    comparator: statusComparatorFn,
    getQuickFilterText: ({ data }) =>
      `${data.status?.name} ${data.status?.category}`,
  },
];

const taskTargetRelatedColumns: ColDef[] = [
  {
    field: "target.state",
    headerName: "Resource State",
    cellRenderer: ({ data }: ICellRendererParams<Task>) => {
      if (!data?.target) {
        return null;
      }

      return <TargetSingleResourceTableIStateRow state={data.target?.state} />;
    },
  },
  {
    field: "target.price_per_month",
    headerName: "Monthly Price",
    cellRenderer: ({ data }: ICellRendererParams<Task>) => {
      if (!data?.target) {
        return null;
      }

      return (
        <Typography variant="body2">
          {money(data.target.price_per_month)}
        </Typography>
      );
    },
  },
  {
    field: "target.account",
    headerName: "Account",
    getQuickFilterText: taskTargetAccountFilterText,
    comparator: targetAccountComparatorFn,
    cellRenderer: ({ data }: ICellRendererParams<Task>) => {
      const account = data?.target?.account;

      if (!account) {
        return null;
      }

      return (
        <AccountInfoData
          accountName={account.provider_account_name}
          accountId={account.provider_account_id}
          provider={account.provider}
        />
      );
    },
  },
  {
    field: "target.resource_type",
    headerName: "Resource Type",
    cellRenderer: ({ data }: ICellRendererParams<Task>) => {
      const target = data?.target;

      if (!target) {
        return null;
      }

      return (
        <TypographyWithTooltip
          title={getResourceTypeName(target?.resource_type, {
            type: "long",
            singular: true,
          })}
          variant="body2"
        />
      );
    },
  },
  {
    field: "target.tags",
    headerName: "Tags / Labels",
    comparator: targetTagComparatorFn,
    getQuickFilterText: taskTargetTagFilterText,
    cellRenderer: ({ data }: ICellRendererParams<Task>) => {
      if (!data?.target?.tags) {
        return "-";
      }

      return <TagCell tags={data.target.tags} />;
    },
  },
  {
    field: "source",

    headerName: "Source",
    cellRenderer: ({ data }: ICellRendererParams<Task>) => {
      if (!data?.source) {
        return "-";
      }

      return (
        <Typography variant="body2">{taskSourceLabels[data.source]}</Typography>
      );
    },
  },
  {
    valueGetter: ({ data }: ValueGetterParams<Task>) =>
      data?.created_by?.name ?? data?.created_by?.email,
    field: "created_by",
    width: 300,
    maxWidth: 300,
    headerName: "Task Created by",
    getQuickFilterText: taskCreationFilterText,
    comparator: creationComparatorFn,
    cellRenderer: ({ data }: ICellRendererParams<Task>) => {
      if (!data) {
        return "-";
      }

      const details = data?.created_by;

      return (
        <UserActivityCell
          date={data.created_at}
          name={details?.name ?? details?.email ?? ""}
          deleted={details?.name?.includes("deleted")}
        />
      );
    },
  },
  {
    field: "modified_by",
    width: 300,
    maxWidth: 300,
    headerName: "Last Modified by",
    getQuickFilterText: taskCreationFilterText,
    comparator: creationComparatorFn,
    cellRenderer: ({ data }: ICellRendererParams<Task>) => {
      if (!data) {
        return "-";
      }

      const details = data?.modified_by;

      return (
        <UserActivityCell
          date={data.modified_at ?? undefined}
          name={details?.name ?? details?.email ?? ""}
          deleted={details?.name?.includes("deleted")}
        />
      );
    },
  },
];

export const endColumns: ColDef[] = [
  {
    // column to fit rest place
    flex: 0.5,
    colId: "toFitEmptySpace",
    headerName: "",
    sortable: false,
    enableRowGroup: false,
    cellDataType: false,
    resizable: false,
    unSortIcon: false,
    suppressMovable: true,
    suppressColumnsToolPanel: true,
    suppressHeaderMenuButton: true,
    suppressHeaderContextMenu: true,
  },
  {
    colId: "actions",
    maxWidth: 70,
    width: 70,
    minWidth: 70,
    pinned: "right",
    sortable: false,
    enableRowGroup: false,
    cellDataType: false,
    resizable: false,
    unSortIcon: false,
    suppressMovable: true,
    suppressColumnsToolPanel: true,
    suppressHeaderMenuButton: true,
    suppressHeaderContextMenu: true,
    cellRenderer: TaskGridRowActionsMenu,
  },
];

export const getTasksGridColumns = (
  properties?: GetUsersMeOrganisationsCurrentTasksPropertiesApiResponse,
): ColDef[] => {
  return [
    nameColumn,
    resourceIdColumn,
    ...getTaskRelatedColumns(properties),
    ...taskTargetRelatedColumns,
    ...endColumns,
  ];
};
