import { FC, useCallback } from "react";
import { enqueueSnackbar } from "notistack";
import { ConfirmationDialog } from "../../../../../common/modals/ConfirmationDialog";
import {
  generateWorkflowUpdatingData,
  getWorkflowUpdatePayloadData,
} from "../../utils/helpers/helpers";
import {
  Schedule,
  usePatchUsersMeSchedulesByScheduleIdMutation,
} from "../../../../../../services/cloudchipr.api";
import { getWorkflowsThunk } from "../../../../../../store/automations/thunks/wokrflows/getWorkflowsThunk";
import { useAppDispatch, useAppSelector } from "../../../../../../store/hooks";
import { workflowsDataLoadingSelector } from "../../../../../../store/automations/selectros/workflow/list-data/workflowsDataLoadingSelector";

interface WithoutAccountsWorkflowDialogProps {
  workflow: Schedule;
  onClose(): void;
}

export const WithoutAccountsWorkflowDialog: FC<
  WithoutAccountsWorkflowDialogProps
> = ({ onClose, workflow }) => {
  const dispatch = useAppDispatch();

  const [updateSchedule, { isLoading }] =
    usePatchUsersMeSchedulesByScheduleIdMutation();
  const dataLoading = useAppSelector(workflowsDataLoadingSelector);

  const deleteHandler = useCallback(async () => {
    await updateSchedule(
      getWorkflowUpdatePayloadData(
        generateWorkflowUpdatingData({ ...workflow, status: "deleted" }),
      ),
    );
    enqueueSnackbar("Workflow successfully deleted.", {
      variant: "snackbarAlert",
      AlertSnackBarProps: { severity: "success" },
    });

    await dispatch(getWorkflowsThunk(true));
    onClose();
  }, [workflow, onClose, dispatch, updateSchedule]);

  return (
    <ConfirmationDialog
      open
      title="Schedule has no accounts"
      dialogContent="The account you previously added to this schedule is no longer connected. Want to delete incomplete schedule?"
      CancelButtonProps={{
        onClick: onClose,
      }}
      ConfirmButtonProps={{
        loading: isLoading || dataLoading,
        onClick: deleteHandler,
        children: "Delete",
      }}
      onClose={onClose}
    />
  );
};
