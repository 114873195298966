import { FC, Fragment, useCallback } from "react";
import {
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { useNavigate } from "react-router-dom";
import { enqueueSnackbar } from "notistack";
import { useMenuHook } from "../../../../../utils/hooks/useMenu.hook";
import { useDialog } from "../../../../../utils/hooks/useDialog.hook";
import { useAppAbility } from "../../../../../services/permissions";
import {
  useDeleteUsersMeOrganisationsCurrentBudgetsByBudgetIdMutation,
  useGetUsersMeOrganisationsCurrentBudgetsQuery,
} from "../../../../../services/cloudchipr.api";
import { DeleteConfirmationDialog } from "../../../../common/modals/DeleteConfirmationDialog";

interface BudgetListActionsCellProps {
  id: string;
  name: string;
}

export const BudgetListActionsCell: FC<BudgetListActionsCellProps> = ({
  id,
  name,
}) => {
  const navigate = useNavigate();

  const { can } = useAppAbility();
  const canEditBudget = can("edit", "budget");
  const canDeleteBudget = can("delete", "budget");

  const { anchor, open: menuOpen, openMenu, closeMenu } = useMenuHook();
  const { open: confirmDialogOpen, openDialog, closeDialog } = useDialog();

  const [deleteTrigger, { isLoading: isDeleting }] =
    useDeleteUsersMeOrganisationsCurrentBudgetsByBudgetIdMutation({});
  const { refetch, isLoading } =
    useGetUsersMeOrganisationsCurrentBudgetsQuery();

  const editHandler = useCallback(() => {
    navigate(`/budgets/edit/${id}`);
  }, [id, navigate]);

  const closeConfirmDialogHandler = useCallback(() => {
    closeDialog();
    closeMenu();
  }, [closeMenu, closeDialog]);

  const deleteBudgetHandler = useCallback(async () => {
    await deleteTrigger({ "budget-id": id });
    await refetch();

    enqueueSnackbar(`${name} Budget deleted!`, {
      variant: "snackbarAlert",
      AlertSnackBarProps: { severity: "success" },
    });
    closeConfirmDialogHandler();
  }, [deleteTrigger, id, name, refetch, closeConfirmDialogHandler]);

  return (
    <Fragment>
      <IconButton onClick={openMenu}>
        <MoreVertIcon />
      </IconButton>

      <Menu anchorEl={anchor} open={menuOpen} onClose={closeMenu}>
        <MenuItem dense onClick={editHandler} disabled={!canEditBudget}>
          <ListItemIcon>
            <EditOutlinedIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Edit</ListItemText>
        </MenuItem>

        <MenuItem dense onClick={openDialog} disabled={!canDeleteBudget}>
          <ListItemIcon>
            <DeleteOutlineIcon color="error" fontSize="small" />
          </ListItemIcon>
          <ListItemText
            color="error"
            primaryTypographyProps={{ color: "error" }}
          >
            Delete
          </ListItemText>
        </MenuItem>
      </Menu>

      <DeleteConfirmationDialog
        open={confirmDialogOpen}
        onClose={closeConfirmDialogHandler}
        onDelete={deleteBudgetHandler}
        loading={isLoading || isDeleting}
        title="Delete Budget?"
        content={
          <Typography>
            Please confirm if you want to delete
            <Typography component="span" fontWeight="bold">
              {" "}
              {name}{" "}
            </Typography>
            Budget.
          </Typography>
        }
      />
    </Fragment>
  );
};
