import { FC, useMemo } from "react";
import { Button, Stack } from "@mui/material";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import { InternalGroupBySelectorSkeleton } from "./InternalGroupBySelectorSkeleton";
import { calculateFilterTreeFiltersCount } from "../../../pages/common/filters-tree/utils/helpers/calculateFilterTreeFiltersCount";
import {
  Dates,
  FilterTreeNode,
  ResourceExplorerGrouping,
} from "../../../../services/cloudchipr.api";
import { DateRangePicker } from "../../../common/date-range-picker/DateRangePicker";

interface InternalResourceExplorerToolbarProps {
  filterTree?: FilterTreeNode;
  groupBy: ResourceExplorerGrouping;
  groupValues?: string[];
  dates?: Dates;
  organisationId: string;
}

export const InternalResourceExplorerToolbar: FC<
  InternalResourceExplorerToolbarProps
> = ({ filterTree, groupBy, groupValues, dates, organisationId }) => {
  const filtersCount = useMemo(
    () => calculateFilterTreeFiltersCount(filterTree),
    [filterTree],
  );

  return (
    <Stack
      px={2}
      py={1.5}
      spacing={1.5}
      flexWrap="wrap"
      direction="row"
      borderBottom={1}
      borderColor="grey.300"
    >
      <InternalGroupBySelectorSkeleton
        groupingValues={groupValues}
        grouping={groupBy}
        organisationId={organisationId}
      />

      {dates && (
        <DateRangePicker
          highlightToday
          size="xsmall"
          selectedDates={dates}
          onDateSelect={() => {}}
        />
      )}

      <Button
        size="small"
        variant="outlined"
        startIcon={<FilterAltOutlinedIcon />}
        sx={{
          lineHeight: 0,
          bgcolor: filtersCount ? "primary.light" : undefined,
        }}
      >
        Filters{!!filtersCount && ` (${filtersCount})`}
      </Button>
    </Stack>
  );
};
