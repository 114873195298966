import { FC, useMemo } from "react";
import { TextField } from "@mui/material";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { FilterTriggerComponentProps } from "../../../../../../../../common/select/dropdown-select/utils/types/filterTriggerComponentProps";
import { useFilterTreeContext } from "../../../../FilterTreeProvider";
import { ResourceExplorerFilterProvider } from "../../../../../../../../../services/cloudchipr.api";
import { findOptionByProviderAndKey } from "../../../../../utils/helpers/findOptionByProviderAndKey";

interface FilterTypeSelectTriggerComponentProps
  extends FilterTriggerComponentProps {
  provider: ResourceExplorerFilterProvider;
}

export const FilterTypeSelectTriggerComponent: FC<
  FilterTypeSelectTriggerComponentProps
> = ({ selectedValues, onClick, error, disabled, open, provider }) => {
  const { possibleFilters } = useFilterTreeContext();

  const value = useMemo(() => {
    return findOptionByProviderAndKey(
      possibleFilters,
      provider,
      selectedValues.at(0),
    )?.label;
  }, [provider, possibleFilters, selectedValues]);

  if (!value) {
    return null;
  }

  const endAdornment = open ? (
    <ArrowDropUpIcon fontSize="small" sx={{ color: "action" }} />
  ) : (
    <ArrowDropDownIcon fontSize="small" sx={{ color: "action" }} />
  );

  return (
    <TextField
      fullWidth
      label="Filter"
      size="xsmall"
      focused={false}
      error={!!error}
      onClick={disabled ? undefined : onClick}
      value={value}
      InputProps={{
        readOnly: true,
        endAdornment: disabled ? undefined : endAdornment,
      }}
    />
  );
};
