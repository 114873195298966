import { ResourceExplorerGrouping } from "../../../../services/cloudchipr.api";
import { ResourceExplorerPath } from "../types/resourceExplorer";
import { getResourcesExplorerGroupingLabel } from "../../../../components/pages/resource-explorer/components/resource-explorer-card/utils/helpers/getResourcesExplorerGroupingLabel";
import { resourceExplorerGroupings } from "../../../../components/pages/resource-explorer/components/resource-explorer-card/utils/constants/groupings";

export const generateDefaultPath = (
  grouping?: ResourceExplorerGrouping,
  groupValues?: string[],
): ResourceExplorerPath[] => {
  return [
    {
      name: grouping
        ? `${getResourcesExplorerGroupingLabel(grouping)}`
        : "Services",
      // as this is not dynamic there is no need to keep it in the path
      groupingOptions: resourceExplorerGroupings,
      groupValues: groupValues,
      groupedBy: grouping ?? "service",
      filterTree: undefined,
    },
  ];
};
