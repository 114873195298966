import { FC } from "react";
import { ListItem, Typography } from "@mui/material";
import { CopyIconButton } from "../../../../../../../common/CopyIconButton";

interface ListRowItemProps {
  type: "UK" | "US";
  role: string;
}

export const ListRowItem: FC<ListRowItemProps> = ({ type, role }) => {
  return (
    <ListItem sx={{ py: 0, px: 1 }}>
      <Typography variant="body2">
        • English {type}:{" "}
        <Typography
          component="span"
          variant="inherit"
          fontWeight="medium"
          color="primary"
        >
          {role}
          <CopyIconButton data={role} visible color="primary" />
        </Typography>
      </Typography>
    </ListItem>
  );
};
