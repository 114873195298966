import { resourceExplorerSingleResourceDataSelector } from "./resourceExplorerSingleResourceDataSelector";
import { RootState } from "../../../store";
import { ProviderType } from "../../../../services/cloudchipr.api";

export const resourceExplorerSingleResourceDataDataSelector = (
  state: RootState,
  provider: ProviderType,
  accountId: string,
  resourceId: string,
) => {
  return resourceExplorerSingleResourceDataSelector({
    resourceId,
    accountId,
    provider,
  })(state)?.data;
};
