import { createAsyncThunk } from "@reduxjs/toolkit";
import { cloudChiprApi } from "../../../services/cloudchipr.api";

export const getAllowedFiltersThunk = createAsyncThunk(
  "filters-selection/getAllowedFilters",
  (arg, { dispatch }) => {
    const { getProvidersByProviderFilters } = cloudChiprApi.endpoints;

    dispatch(getProvidersByProviderFilters.initiate({ provider: "aws" }));
    dispatch(getProvidersByProviderFilters.initiate({ provider: "gcp" }));
    dispatch(getProvidersByProviderFilters.initiate({ provider: "azure" }));
  },
);
