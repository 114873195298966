import { FC, useState, useMemo, useEffect, useCallback } from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { useSearchParams } from "react-router-dom";
import { useAppSelector } from "../../../../../store/hooks";
import { resourceGroups } from "../../../../../utils/constants/resources/resourceGroups";

import { ResourceType } from "../../../../../services/cloudchipr.api";
import { COMPONENT_ID } from "../../../../../utils/constants/components-ids";
import { useToggledResourceTypes } from "../../utils/hooks/useToggledResources";
import { resourceTypeSearchParams } from "../navigation/utils/constants";
import { liveUsageMgmtAccountIdsSelector } from "../../../../../store/live-usage-mgmt/selectors/store-selectors/liveUsageMgmtAccountIdsSelector";
import { liveUsageMgmtProviderSelector } from "../../../../../store/live-usage-mgmt/selectors/store-selectors/liveUsageMgmtProviderSelector";
import {
  generateResourceCardId,
  LiveUsageMgmtResourceCard,
} from "../resource-card/LiveUsageMgmtResourceCard";

export const LiveUsageResourcesArea: FC = () => {
  const [searchParams] = useSearchParams();
  const resourceTypeFromRouter = searchParams.get(
    resourceTypeSearchParams,
  ) as ResourceType;

  const accountIds = useAppSelector(liveUsageMgmtAccountIdsSelector);
  const provider = useAppSelector(liveUsageMgmtProviderSelector);

  const toggledResourceTypes = useToggledResourceTypes();

  const resourceGroup = provider && resourceGroups.get(provider);

  const [activeResourceType, setActiveResourceType] = useState(
    resourceGroup?.[0]?.value,
  );

  const activeResourceGroup = useMemo(() => {
    return resourceGroup?.find((rg) => rg.value === activeResourceType);
  }, [resourceGroup, activeResourceType]);

  const scrollHandler = useCallback((resourceType: ResourceType) => {
    setTimeout(() => {
      const resourceCardId = generateResourceCardId(resourceType);
      const card = document.getElementById(resourceCardId);
      const commonWrapper = document.getElementById(
        COMPONENT_ID.ACCOUNT_PAGE_SCROLL_AREA,
      );
      const top = (card?.offsetTop ?? 0) - 168;

      commonWrapper?.scrollTo({
        top,
        behavior: "smooth",
      });
    });
  }, []);

  useEffect(() => {
    const resourceType = resourceGroup?.find(
      ({ value, resourceTypes }) =>
        value === resourceTypeFromRouter ||
        resourceTypes.includes(resourceTypeFromRouter),
    )?.value;

    if (resourceType) {
      setActiveResourceType(resourceType);
      scrollHandler(resourceTypeFromRouter);
    }
  }, [resourceTypeFromRouter, resourceGroup, scrollHandler]);

  if (!accountIds || !resourceGroup || !provider || !activeResourceType) {
    return null;
  }

  return (
    <Stack direction="row" overflow="auto" flexGrow={1}>
      <Box flexGrow={1} overflow="auto">
        <Stack gap={1} p={1}>
          {activeResourceGroup?.resourceTypes?.map((resourceType) => {
            return toggledResourceTypes.get(resourceType) ? null : (
              <Box key={resourceType}>
                <LiveUsageMgmtResourceCard
                  key={resourceType}
                  resourceType={resourceType}
                />
              </Box>
            );
          })}
        </Stack>
      </Box>
    </Stack>
  );
};
