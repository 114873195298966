import { capitalize } from "@mui/material";
import { ColumnSetupType } from "../../../../../../../../../../../../../../storybook/data-grid/utils/types/types";
import { Asg } from "../../../../../../../../../../../../../../services/cloudchipr.api";

export const asgColumns: ColumnSetupType<Asg>[] = [
  {
    accessorKey: "name",
    header: "Name",
    type: "identifierWithRecommendationLink",
    meta: { sticky: "left", hideFromSettings: true },
  },
  {
    accessorKey: "price_per_month",
    header: "Monthly Price",
    type: "money",
    headerTooltip:
      "Monthly prices are calculated based on the on-demand list price of each resource.",
  },
  {
    accessorKey: "account",
    header: "Account",
    type: "account",
    size: 210,
  },
  {
    accessorKey: "all_time_spent",
    header: "Total Spend",
    type: "money",
  },
  {
    accessorKey: "instances_count",
    header: "Instances",
  },
  {
    accessorKey: "desired_capacity",
    header: "Desired Capacity",
  },
  { accessorKey: "max_capacity", header: "Max" },
  { accessorKey: "min_capacity", header: "Min" },
  {
    accessorKey: "public_ip",
    header: "Public IP",
    headerTooltip:
      "Checks if Auto Scaling groups have public IP addresses enabled through their Launch Configurations.",
    cell: (cell) => {
      const value = cell.getValue() as string;
      return value ? capitalize(value) : "-";
    },
  },
  { accessorKey: "region", header: "Region", type: "withCopy" },
  {
    accessorKey: "created_at",
    header: "Launch time",
    type: "date",
  },
  { accessorKey: "tags", header: "Tags", type: "tag", enableSorting: false },
];
