import { FC } from "react";
import { Divider, Drawer } from "@mui/material";
import { GcpBillingExportDrawerFooter } from "./GcpBillingExportDrawerFooter";
import { GcpBillingExportDrawerHeader } from "./GcpBillingExportDrawerHeader";
import { GcpBillingExportDrawerContent } from "./GcpBillingExportDrawerContent";

interface GcpAccountsHeaderActionButtonsProps {
  open: boolean;
  onClose(): void;
  onOpenBillingExportModal(): void;
}

export const GcpBillingExportsDrawer: FC<
  GcpAccountsHeaderActionButtonsProps
> = ({ open, onClose, onOpenBillingExportModal }) => {
  return (
    <Drawer anchor="right" open={open} onClose={onClose} PaperProps={paperProp}>
      <GcpBillingExportDrawerHeader onClose={onClose} />
      <Divider />
      <GcpBillingExportDrawerContent
        onOpenBillingExportModal={onOpenBillingExportModal}
      />
      <GcpBillingExportDrawerFooter />
    </Drawer>
  );
};
const paperProp = {
  sx: { width: 600 },
};
