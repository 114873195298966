import { DropdownSelectOption } from "../../../dropdown-select/utils/types/types";

export const findExtraOptions = (
  options: DropdownSelectOption[],
  values?: string[],
) => {
  const optionsObj = options.reduce(
    (acc, option) => {
      acc[option.value] = true;

      return acc;
    },
    {} as Record<string, boolean>,
  );

  const extraValues = values?.filter((value) => !optionsObj[value]);

  return extraValues?.map((value) => ({ value, label: value })) ?? [];
};
