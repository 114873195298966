import { FC } from "react";
import { Box, Typography } from "@mui/material";

interface LiveUsageMgmtResourceStatisticProps {
  value: string;
  type: "filtered" | "all";
}

export const LiveUsageMgmtResourceStatistic: FC<
  LiveUsageMgmtResourceStatisticProps
> = ({ value, type }) => {
  return (
    <Box
      borderLeft={4}
      borderColor={type === "filtered" ? "error.light" : "success.light"}
      borderRadius={1}
      bgcolor="white"
      px={1}
      py={0.5}
    >
      <Typography color="text.secondary" variant="body1" fontWeight="medium">
        {value}
      </Typography>
    </Box>
  );
};
