import { FC, useCallback } from "react";
import { Alert, Box, Typography } from "@mui/material";
import { CommandSnippet } from "../CommandSnippet";
import { useAppDispatch, useAppSelector } from "../../../../../../store/hooks";
import { setPolling } from "../../../../../../store/account-setup/accountSetupSlice";
import { cliCommandSelector } from "../../../../../../store/account-setup/selectors/cliCommandSelector";

export const TabPanelAWSCli: FC = () => {
  const dispatch = useAppDispatch();
  const cliCommand = useAppSelector(cliCommandSelector);

  const copyHandler = useCallback(() => {
    setTimeout(() => dispatch(setPolling(true)), 3000);
  }, [dispatch]);

  return (
    <Box>
      <Typography variant="body2" color="text.secondary">
        Please make sure you have AWS CLI installed and you are logged in to
        your desired AWS account. Then copy and paste the following command to
        your terminal and run it. Cloudchipr will automatically detect when the
        access is provided and immediately proceed to the next step.
      </Typography>

      {cliCommand && <CommandSnippet code={cliCommand} onCopy={copyHandler} />}

      <Alert variant="outlined" severity="info" sx={{ alignItems: "center" }}>
        Don't worry, this command won't do anything on your cloud, it only
        creates permissions.
      </Alert>
    </Box>
  );
};
