export const taskCreationFixedCacheKey =
  "postUsersMeOrganisationsCurrentTasks-fixed-cache-key";

export const taskUpdateFixedCacheKey =
  "patchUsersMeOrganisationsCurrentTasks-fixed-cache-key";

export const taskDeletionFixedCacheKey =
  "putUsersMeOrganisationsCurrentTasks-fixed-cache-key";

// properties
export const updateTaskActionPropertiesFixedCacheKey =
  "putUsersMeOrganisationsCurrentTasksPropertiesActions-fixed-cache-key";
export const updateTaskEnvironmentPropertiesFixedCacheKey =
  "putUsersMeOrganisationsCurrentTasksPropertiesEnvironments-fixed-cache-key";
export const updateTaskPriorityPropertiesFixedCacheKey =
  "putUsersMeOrganisationsCurrentTasksPropertiesPriorities-fixed-cache-key";
export const updateTaskStatusPropertiesFixedCacheKey =
  "putUsersMeOrganisationsCurrentTasksPropertiesStatuses-fixed-cache-key";
