import { RootState } from "../../../../../store";
import { SavingsPlan } from "../../../../../../services/cloudchipr.api";
import { savingsPlansDataSelector } from "../../data/savingsPlansDataSelector";
import { commitmentsDetailsDrawerTargetIdSelector } from "../../../common/details-drawer/commitmentsDetailsDrawerTargetIdSelector";

export const selectedSavingsPlanDataSelector = (
  state: RootState,
): SavingsPlan | undefined => {
  const savingsPlans = savingsPlansDataSelector(state);
  const savingsPlanId = commitmentsDetailsDrawerTargetIdSelector(state);

  return savingsPlans?.find((sp) => sp.provider_unique_id === savingsPlanId);
};
