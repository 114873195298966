import { liveUsageMgmtFilterTemplatesSelector } from "./liveUsageMgmtFilterTemplatesSelector";
import { liveUsageMgmtAppliedFilterTemplateSelector } from "./filter-template/liveUsageMgmtAppliedFilterTemplateSelector";
import { RootState } from "../../store";
import {
  ResourceFilters,
  ResourceType,
} from "../../../services/cloudchipr.api";

export const liveUsageMgmtApplyingFilterTemplateFiltersByResourceTypeSelector =
  (
    state: RootState,
    resourceType: ResourceType,
  ): ResourceFilters | undefined => {
    const filterTemplates = liveUsageMgmtFilterTemplatesSelector(state);

    let filters = liveUsageMgmtAppliedFilterTemplateSelector(state)?.filters;

    if (!filters) {
      filters = filterTemplates?.find(({ is_default }) => is_default)?.filters;
    }

    return filters?.find(({ type }) => type === resourceType);
  };
