import { FC, Fragment } from "react";
import { useDidMount } from "rooks";
import { Stack } from "@mui/material";
import { LargestCostChangesWidgetContent } from "./components/LargestCostChangesWidgetContent";
import { LargestCostChangesWidgetCardHeader } from "./components/header/LargestCostChangesWidgetCardHeader";
import { LargestCostChangesWidgetFooter } from "./components/footer/LargestCostChangesWidgetFooter";
import { useAppDispatch, useAppSelector } from "../../../../../../store/hooks";
import { WidgetLoadingState } from "../common/WidgetLoadingState";
import { useHover } from "../../../../../../utils/hooks/useHover.hook";
import { getLargestCostChangesWidgetDataByWidgetIdThunk } from "../../../../../../store/dashboards/thunks/widgets/largest-cost-changes/data-get/getLargestCostChangesWidgetDataByWidgetIdThunk";
import { largestCostChangesWidgetViewIdByWidgetIdSelector } from "../../../../../../store/dashboards/selectors/widgets/largest-cost-changes/largestCostChangesWidgetViewIdByWidgetIdSelector";
import { resourceExplorerViewNameByViewIdSelector } from "../../../../../../store/resource-explorer/selectors/all-resource-explorers/resourceExplorerViewNameByViewIdSelector";
import { largestCostChangesWidgetTitlePropsSelector } from "../../../../../../store/dashboards/selectors/widgets/largest-cost-changes/largestCostChangesWidgetTitlePropsSelector";
import { largestCostChangesWidgetDataLoadingSelector } from "../../../../../../store/dashboards/selectors/widgets/largest-cost-changes/widget-data/largestCostChangesWidgetDataLoadingSelector";
import { largestCostChangesWidgetDataExistSelector } from "../../../../../../store/dashboards/selectors/widgets/largest-cost-changes/widget-data/largestCostChangesWidgetDataExistSelector";
import { largestCostChangesWidgetPrevPeriodCostDateDetailsSelector } from "../../../../../../store/dashboards/selectors/widgets/largest-cost-changes/widget-data/largestCostChangesWidgetPrevPeriodCostDateDetailsSelector";
import { organisationQuarterStartMonthSelector } from "../../../../../../store/common/selectors/org-current-settings/organisationQuarterStartMonthSelector";

interface LargestCostChangesWidgetProps {
  widgetId: string;
}

export const LargestCostChangesWidget: FC<LargestCostChangesWidgetProps> = ({
  widgetId,
}) => {
  const dispatch = useAppDispatch();
  const { ref, hovered } = useHover();

  const viewId = useAppSelector((state) =>
    largestCostChangesWidgetViewIdByWidgetIdSelector(state, widgetId),
  );

  const loading = useAppSelector((state) =>
    largestCostChangesWidgetDataLoadingSelector(state, widgetId),
  );
  const viewName = useAppSelector((state) =>
    resourceExplorerViewNameByViewIdSelector(state, viewId),
  );

  const dataExists = useAppSelector((state) =>
    largestCostChangesWidgetDataExistSelector(state, widgetId),
  );

  const widgetTitleProps = useAppSelector((state) =>
    largestCostChangesWidgetTitlePropsSelector(state, widgetId),
  );

  const prevDate = useAppSelector((state) =>
    largestCostChangesWidgetPrevPeriodCostDateDetailsSelector(state, widgetId),
  );

  const quarterStartMonth = useAppSelector(
    organisationQuarterStartMonthSelector,
  );

  useDidMount(() => {
    dispatch(getLargestCostChangesWidgetDataByWidgetIdThunk(widgetId));
  });

  return (
    <Stack ref={ref} flexGrow={1} overflow="auto">
      {loading ? (
        <WidgetLoadingState />
      ) : (
        <Fragment>
          <LargestCostChangesWidgetCardHeader
            widgetId={widgetId}
            hovered={hovered}
          />

          <LargestCostChangesWidgetContent
            widgetId={widgetId}
            viewName={viewName}
            previousPeriodDate={prevDate}
            date={widgetTitleProps?.dates}
          />

          {dataExists && (
            <LargestCostChangesWidgetFooter
              prevDate={prevDate}
              quarterStartMonth={quarterStartMonth}
              date={widgetTitleProps?.dates}
            />
          )}
        </Fragment>
      )}
    </Stack>
  );
};
