import { FC, useCallback, useEffect, useState } from "react";
import {
  Autocomplete,
  Card,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { preDefinedQuestions } from "../utils/constants";

interface ChatFormProps {
  isLoading: boolean;
  question: string;
  onSubmit(question: string): void;
}

export const ChatForm: FC<ChatFormProps> = ({
  onSubmit,
  isLoading,
  question,
}) => {
  const [value, setValue] = useState(question);

  const inputChangeHandler = useCallback((e: any) => {
    setValue(e.target.value);
  }, []);
  const selectHandler = useCallback((_: any, value: string) => {
    setValue(value);
  }, []);

  const renderInput = useCallback(
    (params: any) => (
      <TextField
        {...params}
        name="emails"
        variant="outlined"
        label="Query"
        value={value}
        onChange={inputChangeHandler}
      />
    ),
    [value, inputChangeHandler],
  );

  const submitHandler = useCallback(() => {
    onSubmit(value);
  }, [onSubmit, value]);

  useEffect(() => {
    setValue(question);
  }, [question]);

  return (
    <Card sx={{ p: 3, mt: 3, flexShrink: 0 }}>
      <Typography variant="body2">Ask a Question of the data</Typography>

      <Stack direction="row" alignItems="center" spacing={2} mt={1}>
        <Autocomplete
          freeSolo
          fullWidth
          disableClearable
          size="small"
          value={value}
          disabled={isLoading}
          options={preDefinedQuestions}
          onChange={selectHandler}
          renderInput={renderInput}
        />

        <LoadingButton
          disabled={value.length < 4}
          variant="contained"
          sx={{ whiteSpace: "nowrap", px: 3 }}
          loading={isLoading}
          onClick={submitHandler}
        >
          Ask Cloudchipr
        </LoadingButton>
      </Stack>
    </Card>
  );
};
