import { FC, Fragment } from "react";
import { Stack } from "@mui/material";
import { useDidMount } from "rooks";
import { CommitmentUtilizationWidgetCardHeader } from "./components/CommitmentUtilizationWidgetCardHeader";
import { CommitmentUtilizationWidgetContent } from "./components/CommitmentUtilizationWidgetContent";
import { useAppDispatch, useAppSelector } from "../../../../../../store/hooks";
import { useHover } from "../../../../../../utils/hooks/useHover.hook";
import { commitmentUtilizationWidgetByWidgetIdLoadingSelector } from "../../../../../../store/dashboards/selectors/widgets/commitment-utilization/widget-view/commitmentUtilizationWidgetByWidgetIdLoadingSelector";
import { WidgetLoadingState } from "../common/WidgetLoadingState";
import { getCommitmentUtilizationWidgetDataByWidgetIdThunk } from "../../../../../../store/dashboards/thunks/widgets/commitment-utilization/getCommitmentUtilizationWidgetDataByWidgetIdThunk";

interface DashboardCommitmentUtilizationWidgetProps {
  widgetId: string;
}

export const DashboardCommitmentUtilizationWidget: FC<
  DashboardCommitmentUtilizationWidgetProps
> = ({ widgetId }) => {
  const dispatch = useAppDispatch();
  const { ref, hovered } = useHover();

  const loading = useAppSelector((state) =>
    commitmentUtilizationWidgetByWidgetIdLoadingSelector(state, widgetId),
  );

  useDidMount(() => {
    dispatch(getCommitmentUtilizationWidgetDataByWidgetIdThunk(widgetId));
  });

  return (
    <Stack ref={ref} flexGrow={1} overflow="auto">
      {loading ? (
        <WidgetLoadingState />
      ) : (
        <Fragment>
          <CommitmentUtilizationWidgetCardHeader
            widgetId={widgetId}
            hovered={hovered}
          />
          <CommitmentUtilizationWidgetContent widgetId={widgetId} />
        </Fragment>
      )}
    </Stack>
  );
};
