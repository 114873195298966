import { addIdsToFilterTreeItems } from "../../../common/filters-tree/utils/helpers/addIdsToFilterTreeItems";
import { DimensionCategories } from "../../../../../store/dimensions/utils/types/common";
import { CategoryWithCosts } from "../../../../../services/cloudchipr.api";

export const generateDimensionCategoriesFromCategoriesWithCosts = (
  categories: CategoryWithCosts[],
) => {
  return categories.reduce((acc, category) => {
    const filterTree = category.filters?.filter_tree
      ? addIdsToFilterTreeItems(category.filters.filter_tree)
      : undefined;

    if (filterTree) {
      acc[category.id] = { ...category, filters: { filter_tree: filterTree } };
    }

    return acc;
  }, {} as DimensionCategories);
};
