import { createAsyncThunk } from "@reduxjs/toolkit";
import { cloudChiprApi } from "../../../services/cloudchipr.api";
import {
  initiateBudgetForEdit,
  setCurrentBudgetLoading,
} from "../budgetsSlice";

export const initiateBudgetByIdThunk = createAsyncThunk(
  "budgets/initiateBudgetById",
  async (id: string, { dispatch }) => {
    const { getUsersMeOrganisationsCurrentBudgetsByBudgetId } =
      cloudChiprApi.endpoints;

    dispatch(setCurrentBudgetLoading(true));

    const response = await dispatch(
      getUsersMeOrganisationsCurrentBudgetsByBudgetId.initiate(
        { "budget-id": id },
        { forceRefetch: true },
      ),
    );

    if (response.data) {
      dispatch(initiateBudgetForEdit(response.data));
    }
  },
);
