import { FC, useCallback, useState } from "react";
import { IconButton, Stack, TextField } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import EditIcon from "@mui/icons-material/Edit";

interface EditableCellProps {
  value: string;
  onEdit(newValue: string): void;
}

export const EditableCell: FC<EditableCellProps> = ({ value, onEdit }) => {
  const [inputValue, setInputValue] = useState<null | string>(null);

  const editValueHandler = useCallback(() => {
    setInputValue((val) => {
      if (val === null) {
        return value;
      }

      onEdit(val);
      return null;
    });
  }, [onEdit, value]);

  const onInputChange = useCallback((event: any) => {
    setInputValue(event.target.value);
  }, []);

  return (
    <Stack direction="row" alignItems="center" spacing={1}>
      {inputValue === null ? (
        value
      ) : (
        <TextField
          size="xsmall"
          variant="outlined"
          value={inputValue}
          onChange={onInputChange}
          InputProps={{ sx: { fontSize: 14 } }}
        />
      )}

      <IconButton
        onClick={editValueHandler}
        disabled={!inputValue && inputValue !== null}
        size="small"
        sx={{ p: 0, ml: 1 }}
      >
        {inputValue === null ? (
          <EditIcon fontSize="inherit" />
        ) : (
          <CheckIcon fontSize="inherit" />
        )}
      </IconButton>
    </Stack>
  );
};
