import { createAsyncThunk } from "@reduxjs/toolkit";
import { enqueueSnackbar } from "notistack";
import {
  AlertStatus,
  cloudChiprApi,
} from "../../../../services/cloudchipr.api";
import { AlertTabType } from "../../utils/types/types";
import { getCostAnomalyAlertsThunk } from "../cost-anomaly-alerts/getCostAnomalyAlertsThunk";
import { getUtilizationAlertsThunk } from "../utilization-alerts/getUtilizationAlertsThunk";
import { generateToggleAlertActionFixedCacheKey } from "../../utils/constants/fixedCacheKeys";

export const toggleAlertStatusByIdAndTypeThunk = createAsyncThunk(
  "alert/toggleAlertStatusByIdAndType",
  async (
    {
      id,
      status,
      alertsActiveTab,
    }: {
      id: string;
      status: AlertStatus;
      alertsActiveTab: AlertTabType;
    },
    { dispatch },
  ) => {
    const {
      patchUsersMeOrganisationsCurrentAlertsCostAnomalyByAlertId,
      patchUsersMeOrganisationsCurrentAlertsUtilizationAndCoverageByAlertId,
    } = cloudChiprApi.endpoints;

    const fixedCacheKey = generateToggleAlertActionFixedCacheKey(
      id,
      alertsActiveTab,
    );
    const updatedStatus = status === "active" ? "inactive" : "active";

    try {
      if (alertsActiveTab === "costAnomaly") {
        await dispatch(
          patchUsersMeOrganisationsCurrentAlertsCostAnomalyByAlertId.initiate(
            {
              alertId: id,
              body: {
                status: updatedStatus,
              },
            },
            { fixedCacheKey },
          ),
        );

        await dispatch(getCostAnomalyAlertsThunk()).unwrap();
      } else {
        await dispatch(
          patchUsersMeOrganisationsCurrentAlertsUtilizationAndCoverageByAlertId.initiate(
            {
              alertId: id,
              body: {
                status: updatedStatus,
              },
            },
            { fixedCacheKey },
          ),
        );

        await dispatch(getUtilizationAlertsThunk()).unwrap();
      }
    } catch (e) {
      // @ts-expect-error todo: fix api spec
      const errMessage = e?.data?.message || "Something went wrong";
      enqueueSnackbar(errMessage, {
        variant: "snackbarAlert",
        AlertSnackBarProps: {
          severity: "error",
        },
      });
    }
  },
);
