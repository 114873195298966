import { FC } from "react";
import { Divider, Stack, Typography } from "@mui/material";

import { IntegrationCustomMessageDataGrid } from "../../../common/IntegrationCustomMessageDataGrid";
import { EmailIntegrationCsvInfo } from "../../../email/common/EmailIntegrationCsvInfo";
import { integrationCustomEmailActionExecuteDataGrid } from "../../../email/utils/constants";
import { EmailIntegrationGracePeriodSubject } from "../../../email/grace-period/EmailIntegrationGracePeriodSubject";
import { EmailIntegrationGracePeriodWorkflowInfo } from "../../../email/grace-period/EmailIntegrationGracePeriodWorkflowInfo";
import { CreateIntegrationMessageEditor } from "../../../../../../../../common/integration-dialogs/components/common/CreateIntegrationMessageEditor";

interface EmailIntegrationGracePeriodDialogContentProps {
  customMessage: string;
  onCustomMessageChange(message: string): void;
}

export const EmailIntegrationGracePeriodDialogContent: FC<
  EmailIntegrationGracePeriodDialogContentProps
> = ({ customMessage, onCustomMessageChange }) => {
  return (
    <Stack spacing={1.5}>
      <EmailIntegrationGracePeriodSubject />

      <Divider />
      <EmailIntegrationGracePeriodWorkflowInfo />

      <Typography variant="body1">
        In case you need to keep any of the attached resources, please go to the
        <Typography component="span" fontWeight="medium">
          {` {{workflow name}} `}
        </Typography>
        workflow page to review and protect them.
      </Typography>

      <CreateIntegrationMessageEditor
        value={customMessage ?? ""}
        onChange={onCustomMessageChange}
        placeholder="Write a custom message..."
      />
      <Divider />
      <IntegrationCustomMessageDataGrid
        data={integrationCustomEmailActionExecuteDataGrid}
        label="Account Name / ID"
      />
      <EmailIntegrationCsvInfo showLinkInfo />
    </Stack>
  );
};
