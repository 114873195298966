import { useEffectOnceWhen } from "rooks";
import {
  usePostUsersMeJiraIntegrationsByIntegrationIdProjectsMutation,
  usePostUsersMeJiraIntegrationsProjectsMutation,
} from "../../../../../services/cloudchipr.api";
import { jiraProjectsOnCreateFixedCacheKey } from "../constants/fixedCacheKeys";

export const useIntegrationProjects = (integrationId?: string) => {
  const [, { data: projectsOnCreate, isLoading: loadingOnCreate }] =
    usePostUsersMeJiraIntegrationsProjectsMutation({
      fixedCacheKey: jiraProjectsOnCreateFixedCacheKey,
    });

  const [fetchProjects, { data: projectsOnEdit, isLoading: loadingOnEdit }] =
    usePostUsersMeJiraIntegrationsByIntegrationIdProjectsMutation();

  const projects = integrationId ? projectsOnEdit : projectsOnCreate;
  const projectsLoading = integrationId ? loadingOnEdit : loadingOnCreate;

  useEffectOnceWhen(() => {
    if (integrationId) {
      fetchProjects({ integrationId }).unwrap();
    }
  }, !!integrationId);

  return {
    projects,
    projectsLoading,
  };
};
