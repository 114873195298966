import { TagFilter } from "./types";

export const tagsFilterDefaultOperator = "in";

export const tagsFilterDefaultCombinedOperator = "and";

export const tagFilterOperators: {
  label: string;
  value: TagFilter["operator"];
}[] = [
  {
    label: "In",
    value: "in",
  },
  {
    label: "Not In",
    value: "not_in",
  },
  {
    value: "contains",
    label: "Contains",
  },
  {
    value: "does_not_contain",
    label: "Doesn't Contain",
  },
  {
    value: "exists",
    label: "Exists",
  },
  {
    value: "does_not_exist",
    label: "Doesn't Exist",
  },
];
