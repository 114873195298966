import { FC } from "react";
import { Stack } from "@mui/material";
import { useAppSelector } from "../../../../../../../../../store/hooks";
import { MultiTypeChartProvider } from "../../../../../../../../../storybook/charts/multi-type-chart/MultiTypeChartProvider";
import { CostBreakdownWidgetSetupContentCosts } from "../components/CostBreakdownWidgetSetupContentCosts";
import { CostBreakdownWidgetChart } from "../components/chart-view/CostBreakdownWidgetChart";
import { costBreakdownSetupPropertyByKeySelector } from "../../../../../../../../../store/dashboards/selectors/setups/cost-breakdown/costBreakdownSetupPropertyByKeySelector";
import { costBreakdownSetupVisualizationPropertyByKeySelector } from "../../../../../../../../../store/dashboards/selectors/setups/cost-breakdown/costBreakdownSetupVisualizationPropertyByKeySelector";
import { costBreakdownSetupHasAggregationSelector } from "../../../../../../../../../store/dashboards/selectors/setups/cost-breakdown/costBreakdownSetupHasAggregationSelector";
import { costBreakdownWidgetSetupChartDataSelector } from "../../../../../../../../../store/dashboards/selectors/widgets/cost-breakdown/setup/costBreakdownWidgetSetupChartDataSelector";
import { costBreakdownWidgetSetupCombinedChartDataSelector } from "../../../../../../../../../store/dashboards/selectors/widgets/cost-breakdown/setup/costBreakdownWidgetSetupCombinedChartDataSelector";
import { costBreakdownWidgetSetupTotalCostDateDetailsSelector } from "../../../../../../../../../store/dashboards/selectors/widgets/cost-breakdown/setup/costBreakdownWidgetSetupTotalCostDateDetailsSelector";
import { costBreakdownWidgetSetupLoadingSelector } from "../../../../../../../../../store/dashboards/selectors/widgets/cost-breakdown/setup/costBreakdownWidgetSetupLoadingSelector";

export const CostBreakdownWidgetSetupChartView: FC = () => {
  const data = useAppSelector(costBreakdownWidgetSetupChartDataSelector);
  const dateDataPoint = useAppSelector(
    costBreakdownSetupPropertyByKeySelector("frequency"),
  );

  const combinedChartData = useAppSelector(
    costBreakdownWidgetSetupCombinedChartDataSelector,
  );

  const dateDetails = useAppSelector(
    costBreakdownWidgetSetupTotalCostDateDetailsSelector,
  );
  const loading = useAppSelector(costBreakdownWidgetSetupLoadingSelector);

  const chartType =
    useAppSelector(
      costBreakdownSetupVisualizationPropertyByKeySelector("chart_type"),
    ) ?? "line";

  const chartBase = useAppSelector(
    costBreakdownSetupVisualizationPropertyByKeySelector("chart_base_type"),
  );

  const hasAggregations = useAppSelector(
    costBreakdownSetupHasAggregationSelector,
  );

  return (
    <Stack flex={1} p={2} justifyContent={hasAggregations ? "start" : "center"}>
      <MultiTypeChartProvider
        value={{
          data: data ?? [],
          initialChartType: chartType,
        }}
      >
        <CostBreakdownWidgetSetupContentCosts />
        <CostBreakdownWidgetChart
          dateDataPoint={dateDataPoint}
          chartType={chartType}
          dateDetails={dateDetails}
          loading={!data.length && loading}
          chartBase={chartBase}
          combinedChartData={combinedChartData}
        />
      </MultiTypeChartProvider>
    </Stack>
  );
};
