import { FC, useCallback } from "react";
import { Stack, Tooltip } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { RightsizingAdvancedFilterCheckbox } from "./RightsizingAdvancedFilterCheckbox";
import { useToggleRightsizingAdvancedFilterUserPreference } from "../../../utils/hooks/useToggleRightsizingAdvancedFilterUserPreference";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../store/hooks";
import { rightsizingAdvancedFiltersSettingsSelector } from "../../../../../../../../store/recommendations/selectors/rightsizing/advanced-filters/rightsizingAdvancedFiltersSettingsSelector";
import { getRightsizingAdvancedFiltersSettingsLoadingSelector } from "../../../../../../../../store/recommendations/selectors/rightsizing/advanced-filters/getRightsizingAdvancedFiltersSettingsLoadingSelector";
import {
  toggleRecommendationsDisplayRecommendationsAcrossInstanceFamilyFilter,
  toggleRecommendationsMultipleRecommendationsPerResourceFilter,
} from "../../../../../../../../store/recommendations/recommendationsSlice";

export const RightsizingAdvancedFilterCheckboxes: FC = () => {
  const dispatch = useAppDispatch();
  const preferences = useAppSelector(
    rightsizingAdvancedFiltersSettingsSelector,
  );
  const preferencesLoading = useAppSelector(
    getRightsizingAdvancedFiltersSettingsLoadingSelector,
  );
  const { toggleRightsizingAdvancedFilterUserPreference, loading } =
    useToggleRightsizingAdvancedFilterUserPreference();

  const handleDisplayRecommendationsAcrossInstanceFamilyClick =
    useCallback(() => {
      toggleRightsizingAdvancedFilterUserPreference(
        "displayRecommendationsAcrossInstanceFamily",
      );
      dispatch(
        toggleRecommendationsDisplayRecommendationsAcrossInstanceFamilyFilter(),
      );
    }, [toggleRightsizingAdvancedFilterUserPreference, dispatch]);

  const handleMultipleRecommendationsPerResourceClick = useCallback(() => {
    toggleRightsizingAdvancedFilterUserPreference(
      "multipleRecommendationsPerResource",
    );
    dispatch(toggleRecommendationsMultipleRecommendationsPerResourceFilter());
  }, [toggleRightsizingAdvancedFilterUserPreference, dispatch]);

  return (
    <Stack bgcolor="grey.50" borderRadius={2} mt={1} p={1}>
      <Stack direction="row" alignItems="center">
        <RightsizingAdvancedFilterCheckbox
          onClick={handleMultipleRecommendationsPerResourceClick}
          disabled={loading || preferencesLoading}
          checked={!!preferences.multipleRecommendationsPerResource}
          label="Multiple recommendations per resource"
        />
        <Tooltip
          placement="top"
          title="By default, Cloudchipr shows only one top
              recommendation per resource. If checked, Cloudchipr
              will display all available recommendations per resource."
        >
          <InfoOutlinedIcon fontSize="small" color="info" />
        </Tooltip>
      </Stack>
      <RightsizingAdvancedFilterCheckbox
        onClick={handleDisplayRecommendationsAcrossInstanceFamilyClick}
        disabled={loading || preferencesLoading}
        checked={!!preferences.displayRecommendationsAcrossInstanceFamily}
        label="Display recommendations across instance families"
      />
    </Stack>
  );
};
