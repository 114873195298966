import { largestCostChangesWidgetDataResponseSelector } from "./largestCostChangesWidgetDataResponseSelector";
import { RootState } from "../../../../../store";

export const largestCostChangesWidgetDataLoadingSelector = (
  state: RootState,
  widgetId: string,
) => {
  const response = largestCostChangesWidgetDataResponseSelector(
    state,
    widgetId,
  );

  return !!response?.isLoading;
};
