import { capitalize } from "@mui/material";
import { CellContext } from "@tanstack/react-table";

export const CapitalizedTextCell = (cell: CellContext<any, any>) => {
  const value = cell.getValue() as string;
  if (!value) {
    return "-";
  }
  return capitalize(value.toLowerCase());
};
