import { FilterItemTemplate } from "../../../../../../../services/cloudchipr.api";

export const getStatisticsFilterMinMax = (
  filterTemplate: FilterItemTemplate,
) => {
  const min: number =
    filterTemplate?.min !== undefined &&
    filterTemplate?.min !== null &&
    Number.isInteger(filterTemplate?.min)
      ? +filterTemplate.min
      : Number.MIN_SAFE_INTEGER;

  const max: number =
    filterTemplate?.max !== undefined &&
    filterTemplate?.max !== null &&
    Number.isInteger(filterTemplate?.max)
      ? +filterTemplate?.max
      : Number.MAX_SAFE_INTEGER;

  return { min, max };
};
