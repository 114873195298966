import { FC } from "react";
import { TableCell, Typography } from "@mui/material";
import { money } from "../../../../../../utils/numeral/money";

interface TableSpendingCellProps {
  spending: number;
}

export const TableSpendingCell: FC<TableSpendingCellProps> = ({ spending }) => {
  return (
    <TableCell sx={{ width: 200, minWidth: 200 }}>
      <Typography variant="subtitle1" align="right" fontWeight="medium">
        {money(spending)}
      </Typography>
    </TableCell>
  );
};
