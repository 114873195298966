import { createAsyncThunk } from "@reduxjs/toolkit";
import { cloudChiprApi } from "../../../services/cloudchipr.api";

export const getAllReportsThunk = createAsyncThunk(
  "reports/getAllReports",
  async (_, { dispatch }) => {
    const { getUsersMeOrganisationsCurrentReports } = cloudChiprApi.endpoints;

    return await dispatch(
      getUsersMeOrganisationsCurrentReports.initiate(undefined, {
        forceRefetch: true,
      }),
    ).unwrap();
  },
);
