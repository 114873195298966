import { createDraftSafeSelector } from "@reduxjs/toolkit";
import { currentBudgetSelector } from "../currentBudgetSelector";
import { Step1InitialValues } from "../../../../../components/pages/budgets/components/budget-create/steps/steps/utils/types/formInitialValues";

export const step1FormDataSelector = createDraftSafeSelector(
  [currentBudgetSelector],
  (budget): Step1InitialValues | null => {
    if (!budget) {
      return null;
    }

    return {
      thresholds: budget.body.thresholds,
      email_to: budget.body.email_to ?? null,
      notifications: budget.body.notifications ?? null,
    };
  },
);
