import { FC, memo, useEffect, useState } from "react";
import { useFlag } from "@unleash/proxy-client-react";
import { getResourceTypeColumns } from "./resouce-card-data-grid/utils/data-grid/columns-setup/getResourceTypeColumns";
import { LiveUsageMgmtResourcesDataGrid } from "./resouce-card-data-grid/LiveUsageMgmtResourcesDataGrid";
import { newMetricColumnsByFT } from "./resouce-card-data-grid/cells/metrics-cells/utils/helpers/newMetricColumnsEnablenes";
import { useAppSelector } from "../../../../../../../../store/hooks";
import { WithResourceType } from "../../../../../utils/types";
import { DataGridProvider } from "../../../../../../../../storybook/data-grid/DataGridProvider";
import { liveUsageMgmtResourceTypeDataResourcesSelector } from "../../../../../../../../store/live-usage-mgmt/selectors/resource-type-data/liveUsageMgmtResourceTypeDataResourcesSelector";

interface LiveUsageMgmtResourceCardGridProps extends WithResourceType {
  fullHeight?: boolean;
}

export const LiveUsageMgmtResourceCardGrid: FC<LiveUsageMgmtResourceCardGridProps> =
  memo(({ resourceType, fullHeight }) => {
    const enableMetricsCharts = useFlag("EnableResourceSmallCharts");

    const columns = getResourceTypeColumns(
      resourceType,
      newMetricColumnsByFT(enableMetricsCharts),
    );

    const resourcesData = useAppSelector((state) =>
      liveUsageMgmtResourceTypeDataResourcesSelector(state, resourceType),
    );

    const [gridData, setGridData] = useState(resourcesData || []);

    useEffect(() => {
      if (resourcesData) {
        setGridData(resourcesData);
      }
    }, [resourcesData]);

    if (!columns) {
      return null;
    }

    return (
      <DataGridProvider>
        <LiveUsageMgmtResourcesDataGrid
          data={gridData}
          columns={columns}
          fullHeight={fullHeight}
          resourceType={resourceType}
        />
      </DataGridProvider>
    );
  });
