import { FC, useCallback } from "react";
import { AutomationResourceFilterSourceButton } from "./AutomationResourceFilterSourceButton";
import { FiltersSourceButton } from "../../../../../../../common/filters/filters-selection/FiltersSourceButton";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../../store/hooks";
import { automationAccountIdsSelector } from "../../../../../../../../../store/automations/selectros/common/fields/automationAccountIdsSelector";
import {
  ResourceFilters,
  ResourceFiltersWithAction,
} from "../../../../../../../../../services/cloudchipr.api";
import { automationResourcesSelector } from "../../../../../../../../../store/automations/selectros/common/fields/automationResourcesSelector";
import { allDefaultFiltersForAutomationSelector } from "../../../../../../../../../store/automations/selectros/common/helpers-data/allDefaultFiltersForAutomationSelector";
import { FilterSourceButton } from "../../../../../../../common/filters/filters-selection/utils/types/types";
import { setAutomationErrors } from "../../../../../../../../../store/automations/automationsSlice";

interface AutomationResourceFiltersProps {
  index: number;
  resourceFilters: ResourceFiltersWithAction;
  onChange(filter: ResourceFiltersWithAction[]): void;
}

export const AutomationResourceFilters: FC<AutomationResourceFiltersProps> = ({
  onChange,
  resourceFilters,
  index,
}) => {
  const dispatch = useAppDispatch();
  const accountIds = useAppSelector(automationAccountIdsSelector);
  const resources = useAppSelector(automationResourcesSelector);
  const allDefaultFilters = useAppSelector(
    allDefaultFiltersForAutomationSelector,
  );
  const resourceType = resourceFilters.filter.type;
  const filtersCount = resourceFilters.filter.filter_items.length;

  const submitFiltersHandler = useCallback(
    (resourceFilters: ResourceFilters) => {
      const newFilters = resources.map((resource) => {
        return resourceFilters.type === resource.filter.type
          ? {
              ...resource,
              filter: resourceFilters,
            }
          : resource;
      });

      onChange(newFilters);

      if (accountIds.length) {
        dispatch(
          setAutomationErrors({ [`filter[${index}].filter.filter_items`]: "" }),
        );
      }
    },
    [onChange, resources, index, accountIds.length, dispatch],
  );

  const SourceButton: FilterSourceButton = useCallback(
    ({ openDialog }) => {
      return (
        <AutomationResourceFilterSourceButton
          openDialog={openDialog}
          count={filtersCount}
        />
      );
    },
    [filtersCount],
  );

  if (!allDefaultFilters?.[resourceType]) {
    return null;
  }

  return (
    <FiltersSourceButton
      hideRevert
      accountIds={accountIds}
      resourceType={resourceType}
      onSubmit={submitFiltersHandler}
      filters={resourceFilters.filter}
      SourceButton={SourceButton}
      defaultFilters={allDefaultFilters[resourceType]}
    />
  );
};
