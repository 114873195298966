import { createDraftSafeSelector } from "@reduxjs/toolkit";
import { rightsizingRecommendationsStatusesGroupedSelector } from "./rightsizingRecommendationsStatusesGroupedSelector";

export const allProvidersStatusesNotDisabledSelector = createDraftSafeSelector(
  [rightsizingRecommendationsStatusesGroupedSelector],
  (data) => {
    return (
      !data?.aws.disabled.length &&
      !data?.gcp.disabled.length &&
      !data?.azure.disabled.length &&
      !data?.aws.missing_permission.length &&
      !data?.gcp.missing_permission.length &&
      !data?.azure.missing_permission.length
    );
  },
);
