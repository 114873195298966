import { createAsyncThunk } from "@reduxjs/toolkit";
import { getLiveUsageMgmtResourcesWithFiltersThunk } from "./resources/getLiveUsageMgmtResourcesWithFiltersThunk";
import { getLiveUsageMgmtAllResourceTypesDataThunk } from "./resources/getLiveUsageMgmtAllResourceTypesDataThunk";
import { RootState } from "../../store";
import { liveUsageMgmtAccountIdsSelector } from "../selectors/store-selectors/liveUsageMgmtAccountIdsSelector";

export const refreshLiveUsageMgmtPageThunk = createAsyncThunk(
  "liveUsageMgmt/refreshLiveUsageMgmtPage",
  async (_: void, { dispatch, getState }) => {
    const state = getState() as RootState;
    const accountIds = liveUsageMgmtAccountIdsSelector(state);

    if (!accountIds) {
      return;
    }

    dispatch(getLiveUsageMgmtAllResourceTypesDataThunk());
    dispatch(getLiveUsageMgmtResourcesWithFiltersThunk());
  },
);
