import { RootState } from "../../../../../store";
import { automationSliceSelector } from "../../../common/automationSliceSelector";

export const automationsAppliedEnabledStateFiltersSelector = (
  state: RootState,
) => {
  const slice = automationSliceSelector(state);

  return slice.filters.enabledState;
};
