import { createAsyncThunk } from "@reduxjs/toolkit";
import { enqueueSnackbar } from "notistack";
import { cloudChiprApi } from "../../../../services/cloudchipr.api";
import { setTaskManagementProperties } from "../../taskManagementSlice";

export const getTaskAvailablePropertiesThunk = createAsyncThunk(
  "taskManagement/getTaskAvailableProperties",
  async (forceRefetch: boolean | void, { dispatch }) => {
    const { getUsersMeOrganisationsCurrentTasksProperties } =
      cloudChiprApi.endpoints;

    try {
      const response = await dispatch(
        getUsersMeOrganisationsCurrentTasksProperties.initiate(undefined, {
          forceRefetch: forceRefetch ?? false,
        }),
      ).unwrap();

      if (response) {
        dispatch(setTaskManagementProperties(response));
      }
    } catch (e) {
      // @ts-expect-error TODO: return to this after adding error type
      const errMessage = e?.data?.message || "Something went wrong";
      enqueueSnackbar(errMessage, {
        variant: "snackbarAlert",
        AlertSnackBarProps: { severity: "error" },
      });
    }
  },
);
