import { offHoursRecommendationsDismissedResourcesDataByGroupSelector } from "./offHoursRecommendationsDismissedResourcesDataByGroupSelector";
import { RootState } from "../../../../store";
import { RecommendationGroupType } from "../../../../../components/pages/recommendations/components/off-hours-recommendations/utils/types/types";
import { recommendationAppliedProvidersSelector } from "../../filters/recommendationAppliedProvidersSelector";
import { recommendationAppliedTypesSelector } from "../../filters/recommendationAppliedTypesSelector";
import { recommendationAppliedAccountsSelector } from "../../filters/recommendationAppliedAccountsSelector";
import { recommendationsDismissedResourcesDataByFiltersCombiner } from "../../dismiss/resources/recommendationsDismissedResourcesDataByFiltersCombiner";
import { recommendationAppliedTagsSetSelector } from "../../filters/recommendationAppliedTagsSetSelector";

export const offHoursRecommendationsDismissedResourcesFilteredDataByGroupSelector =
  (state: RootState, group: RecommendationGroupType) => {
    const data = offHoursRecommendationsDismissedResourcesDataByGroupSelector(
      state,
      group,
    );

    const providers = recommendationAppliedProvidersSelector(state);
    const types = recommendationAppliedTypesSelector(state);
    const accounts = recommendationAppliedAccountsSelector(state);
    const tags = recommendationAppliedTagsSetSelector(state);

    return recommendationsDismissedResourcesDataByFiltersCombiner(
      data,
      providers,
      types,
      accounts,
      tags,
    );
  };
