import { FC, useCallback } from "react";
import { FormHelperText, Stack, TextField, Typography } from "@mui/material";
import { setOffHoursSpecificData } from "../../../../../../../../../store/automations/automationsSlice";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../../store/hooks";
import { offHoursSchedulerSelector } from "../../../../../../../../../store/automations/selectros/off-hours/offHoursSchedulerSelector";

const maxValue = 31;

interface DailyIntervalProps {
  error?: string | boolean;
}

export const DailyInterval: FC<DailyIntervalProps> = ({ error }) => {
  const dispatch = useAppDispatch();
  const scheduler = useAppSelector(offHoursSchedulerSelector);
  const value = scheduler?.repeatValue;

  const changeHandler = useCallback(
    (event: any) => {
      const val = Number(event.target.value);

      if (val > maxValue || val < 0) {
        return;
      }

      dispatch(
        setOffHoursSpecificData({
          scheduler: { ...scheduler, repeatValue: val },
        }),
      );
    },
    [dispatch, scheduler],
  );

  const blurHandler = useCallback(() => {
    if ((value ?? 0) < 1) {
      dispatch(
        setOffHoursSpecificData({
          scheduler: { ...scheduler, repeatValue: 1 },
        }),
      );
    }
  }, [dispatch, scheduler, value]);

  return (
    <Stack direction="row" spacing={0.5} alignItems="baseline">
      <Typography variant="body2" color="text.secondary">
        Every
      </Typography>

      <Stack>
        <TextField
          type="number"
          size="small"
          error={!!error}
          sx={{ width: 100 }}
          value={value || ""}
          onBlur={blurHandler}
          onChange={changeHandler}
          InputProps={{ inputProps: { min: 1, max: maxValue } }}
        />
        {error && <FormHelperText error>{error}</FormHelperText>}
      </Stack>

      <Typography variant="body2" color="text.secondary">
        day
        {!!(value && value > 1) && "s"}
      </Typography>
    </Stack>
  );
};
