import { FC } from "react";
import { Stack, Typography } from "@mui/material";
import EmptyWidget from "../../../../../utils/assets/images/empty_widget.svg";

interface WidgetContentInvalidStateProps {
  message: string;
}

export const WidgetContentInvalidState: FC<WidgetContentInvalidStateProps> = ({
  message,
}) => {
  return (
    <Stack p={2} flex={1}>
      <Stack
        bgcolor="grey.50"
        px={1}
        alignItems="center"
        justifyContent="center"
        borderRadius={2}
        flex={1}
      >
        <img src={EmptyWidget} alt="No Data" />

        <Typography
          variant="body1"
          mt={3}
          color="text.secondary"
          textAlign="center"
        >
          {message}
        </Typography>
      </Stack>
    </Stack>
  );
};
