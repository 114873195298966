import Typography from "@mui/material/Typography";
import { FC } from "react";

export const IntegrationUtilizationAlertCommitmentsExpirationSlackHeader: FC =
  () => {
    return (
      <Typography variant="body1">
        7 Days Left Until Commitment Expiration -{" "}
        <Typography component="span" fontWeight="medium">
          {`{{Alert Name}}`}
        </Typography>
      </Typography>
    );
  };
