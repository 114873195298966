import moment from "moment/moment";
import { createDraftSafeSelector } from "@reduxjs/toolkit";
import { resourceExplorerGranularDataSelector } from "./resourceExplorerGranularDataSelector";

export const resourceExplorerGranularViewGridColumnKeysSelector =
  createDraftSafeSelector([resourceExplorerGranularDataSelector], (data) => {
    const columns = data?.columns;

    return columns?.filter((key) => moment(key).isValid()) ?? [];
  });
