import { createDraftSafeSelector } from "@reduxjs/toolkit";
import { awsNonDeletedAndNotBillingAccountsSelector } from "../../accounts/selectors/aws/awsNonDeletedAndNotBillingAccountsSelector";
import { gcpNonDeletedAndNotBillingAccountsSelector } from "../../accounts/selectors/gcp/gcpNonDeletedAndNotBillingAccountsSelector";
import { azureNonDeletedAndNotBillingAccountsSelector } from "../../accounts/selectors/azure/azureNonDeletedAndNotBillingAccountsSelector";
import { Account, ProviderType } from "../../../services/cloudchipr.api";
import { activeAccountStatuses } from "../../../utils/constants/accounts";

export const offHourDefaultProviderSelector = createDraftSafeSelector(
  [
    awsNonDeletedAndNotBillingAccountsSelector,
    gcpNonDeletedAndNotBillingAccountsSelector,
    azureNonDeletedAndNotBillingAccountsSelector,
  ],
  (
    awsAccounts: Account[] | null,
    gcpAccounts: Account[] | null,
    azureAccounts: Account[] | null,
  ): ProviderType | null => {
    if (activeAccountExist(awsAccounts)) {
      return "aws";
    }

    if (activeAccountExist(gcpAccounts)) {
      return "gcp";
    }

    if (activeAccountExist(azureAccounts)) {
      return "azure";
    }

    return null;
  },
);

const activeAccountExist = (accounts?: Account[] | null) => {
  return accounts?.some(
    (account) =>
      account.access_type === "read_write" &&
      activeAccountStatuses.has(account.status),
  );
};
