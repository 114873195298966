import { createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../../../store";
import { UtilizationAlertsType } from "../../../../components/pages/alerts/utils/types/types";
import { setAlertsData } from "../../alertsSlice";
import { alertsDataThresholdItemsSelector } from "../../selectors/common/data/alertsDataThresholdItemsSelector";
import { ThresholdItem } from "../../../../services/cloudchipr.api";

export const addUtilizationAlertThresholdByTypeThunk = createAsyncThunk(
  "alerts/addCostAnomalyAlertThreshold",
  async (type: UtilizationAlertsType, { dispatch, getState }) => {
    const state = getState() as RootState;

    const thresholdItems = alertsDataThresholdItemsSelector(state);

    const updatedThresholdItems = [
      ...thresholdItems,
      {
        unit: (type === "commitments_expiration"
          ? "day"
          : "percent") as ThresholdItem["unit"],
        amount: "1",
      },
    ];
    dispatch(
      setAlertsData({
        threshold: {
          threshold_items: updatedThresholdItems,
        },
      }),
    );
  },
);
