import { generateMetricsCsvKey } from "./generateMetricsCsvKey";
import { ColumnSetupType } from "../../../../../../../../../../../storybook/data-grid/utils/types/types";
import { MetricsFrequenciesType } from "../types/metricsFrequenciesType";
import { ResourceItemType } from "../../../../../../../../../../../store/live-usage-mgmt/utils/types/types";

export const generateNonPrimitiveHeaderKey = (
  column: ColumnSetupType<ResourceItemType>,
  metricsFrequencies?: MetricsFrequenciesType,
): string => {
  if (column.accessorKey === ("inline_metrics" as keyof ResourceItemType)) {
    return generateMetricsCsvKey(
      column.meta?.headerTitle,
      metricsFrequencies,
      column.id,
    );
  }

  return column.meta?.headerTitle ?? column.accessorKey ?? "";
};
