import { getMetricsSortingFn } from "./getMetricsSortingFn";
import { TableCellTypes } from "../../../../../../../../../../../../storybook/data-grid/utils/types/types";
import { sortingFnByCellType } from "../../../../../../../../../../../../storybook/data-grid/utils/sorts/common";
import { MetricsFrequenciesType } from "../../types/metricsFrequenciesType";

export const getSortingFnByKeyAndType = (
  type: TableCellTypes,
  accessorKey?: string,
  id?: string,
  metricsFrequencies?: MetricsFrequenciesType,
) => {
  if (accessorKey === "inline_metrics") {
    if (!id) {
      return "basic";
    }

    return getMetricsSortingFn(id, metricsFrequencies);
  }

  return sortingFnByCellType.get(type) ?? "basic";
};
