import { DropdownSelectOption } from "../../../../../common/select/dropdown-select/utils/types/types";

export const hierarchySelectFilterFn = (
  option: DropdownSelectOption,
  keyword: string,
) => {
  keyword = keyword.trim().toLowerCase();

  if (!option.rawValue) {
    return false;
  }

  const { valueForSearch, name } = option.rawValue;

  const target = `${name} ${valueForSearch}`.toLowerCase();

  return target.includes(keyword);
};
