import { createAsyncThunk } from "@reduxjs/toolkit";
import { protectV2Thunk } from "./protectV2Thunk";
import { ResourceType } from "../../../../services/cloudchipr.api";
import { getLiveUsageMgmtResourceTypeDataThunk } from "../../../live-usage-mgmt/thunks/resources/getLiveUsageMgmtResourceTypeDataThunk";
import { resetLiveUsageMgmtSelectedResources } from "../../../live-usage-mgmt/liveUsageMgmtSlice";

interface CreateSingleProtectThunkArgs {
  isProtected: boolean;
  id: string;
  resourceType: ResourceType;
  accountId: string;
}
export const createV2SingleProtectThunk = createAsyncThunk(
  "createSingleProtect",
  async (
    { isProtected, id, resourceType, accountId }: CreateSingleProtectThunkArgs,
    { dispatch },
  ) => {
    await dispatch(
      protectV2Thunk({
        resources: [id],
        actionType: !isProtected ? "protect" : "unprotect",
        resourceType: resourceType,
        accountId,
      }),
    );
    dispatch(getLiveUsageMgmtResourceTypeDataThunk(resourceType));
    dispatch(resetLiveUsageMgmtSelectedResources(resourceType));
  },
);
