import { FC, useCallback } from "react";
import { Drawer } from "@mui/material";
import { DimensionDrawerForm } from "./components/dimension/DimensionDrawerForm";
import { CategoryDrawerForm } from "./components/category/CategoryDrawerForm";
import { useAppDispatch, useAppSelector } from "../../../../../store/hooks";
import { dimensionDrawerModeSelector } from "../../../../../store/dimensions/selectors/slice-data/dimensionDrawerModeSelector";
import { resetDimensions } from "../../../../../store/dimensions/dimensionsSlice";

export const DimensionDrawers: FC = () => {
  const dispatch = useAppDispatch();
  const mode = useAppSelector(dimensionDrawerModeSelector);

  const closeHandler = useCallback(() => {
    dispatch(resetDimensions());
  }, [dispatch]);

  return (
    <Drawer
      anchor="right"
      open={!!mode}
      onClose={closeHandler}
      PaperProps={{ sx: { width: "50%", minWidth: 1000 } }}
    >
      {(mode === "dimensionCreate" || mode === "dimensionEdit") && (
        <DimensionDrawerForm onClose={closeHandler} />
      )}

      {(mode === "categoryEdit" || mode === "categoryCreate") && (
        <CategoryDrawerForm onClose={closeHandler} />
      )}
    </Drawer>
  );
};
