import { FC } from "react";
import { Stack, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import { Reservation } from "../../../../../../../../../services/cloudchipr.api";
import { getPercentage } from "../../../../../../../../../utils/numeral/getPercentage";
import { CommitmentsProgressBar } from "../../../../../../common/components/CommitmentsProgressBar";
import { getColorByValue } from "../../../../../../common/utils/helpers/grid/dataGridHelpers";
import { money } from "../../../../../../../../../utils/numeral/money";

interface UtilizationCellProps {
  reservation: Reservation;
}

export const UtilizationCell: FC<UtilizationCellProps> = ({ reservation }) => {
  const usedCost = reservation.used_commitment_to_date?.cost;
  const totalCost = reservation.total_commitment_to_date?.cost;
  const usedHours = reservation.used_commitment_to_date?.hours;

  const state = reservation.state;
  const expired = state === "expired";
  const percent = +getPercentage(usedCost ?? 0, totalCost ?? 0).toFixed(2);
  const color = getColorByValue(percent);

  return (
    <Stack>
      <Stack direction="row" spacing={0.5} width="fit-content">
        <Typography
          variant="body2"
          fontWeight="medium"
          color={expired ? "text.secondary" : color}
        >
          {money(usedCost)}
        </Typography>
        <Typography variant="body2" fontWeight="medium" color="text.secondary">
          ({usedHours}h)
        </Typography>
      </Stack>

      <CommitmentsProgressBar
        value={percent}
        overrideColor={expired ? grey[500] : undefined}
      />
    </Stack>
  );
};
