import { ExecutionLogV3 } from "../../../../../services/cloudchipr.api";
import { executionLogsSourceNames } from "../constants/common";

export const getExecutionLogsExecutorData = (
  source: ExecutionLogV3["source"],
  automationExist: boolean,
) => {
  const scheduleType = source.source && scheduleTypes[source.source];

  const url =
    scheduleType && automationExist
      ? `/automations/${scheduleType}?search=${source?.metadata?.name}`
      : "";

  const sourceName = source.source
    ? executionLogsSourceNames.get(source.source)
    : "";
  const name = source?.metadata?.name ?? "";

  const linkForCsv = url ? `${window.location.host}${url}` : "";
  const separator = name ? ":" : "";
  const noLinkText = scheduleType ? `${sourceName}${separator} ${name}` : name;

  const csvString =
    scheduleType && linkForCsv
      ? `${sourceName}${separator} ${linkForCsv}`
      : noLinkText;

  return {
    url,
    name,
    csvString,
    sourceName,
    scheduleType,
  };
};

const scheduleTypes: Record<string, string> = {
  workflow: "workflow",
  off_hours: "off-hours",
};
