import { FC, memo } from "react";
import { Stack, Typography } from "@mui/material";
import { PlayCircleOutlined, StopCircleOutlined } from "@mui/icons-material";

interface StopStartDatesTitleProps {
  title: string;
  type: "stop" | "start";
}

export const StopStartDatesTitle: FC<StopStartDatesTitleProps> = memo(
  ({ title, type }) => {
    return (
      <Stack direction="row" alignItems="center">
        <Stack color="text.disabled">
          {type === "stop" ? (
            <StopCircleOutlined color="inherit" />
          ) : (
            <PlayCircleOutlined color="inherit" />
          )}
        </Stack>

        <Typography variant="subtitle2" ml={1}>
          {title}
        </Typography>
      </Stack>
    );
  },
);
