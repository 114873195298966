import {
  indigo,
  amber,
  blue,
  cyan,
  blueGrey,
  pink,
  teal,
  yellow,
  brown,
  deepPurple,
  orange,
  grey,
} from "@mui/material/colors";

export const ChartsColors = [
  blue[400],
  teal[300],
  deepPurple[300],
  amber[400],
  pink[300],
  amber[700],
  grey[400],
  pink["A700"],
  pink[200],
  brown[300],
  indigo[400],
  brown[500],
  teal[500],
  teal[200],
  cyan[700],
  yellow[700],
  orange[500],
  blueGrey[800],
  blue["A100"],
  blue["A700"],
  pink[900],
];
