import { IconButton, Tooltip } from "@mui/material";
import { Tune } from "@mui/icons-material";
import { Table } from "@tanstack/react-table";
import { FC, Fragment } from "react";
import { useMenuHook } from "../../../../../../utils/hooks/useMenu.hook";
import { ColumnsSwitcher } from "../../../../../../storybook/data-grid/toolbar/ColumnsSwitcher";

interface ColumnsShowHideSwitcherProps {
  table: Table<any>;
}

export const ColumnsShowHideSwitcher: FC<ColumnsShowHideSwitcherProps> = ({
  table,
}) => {
  const { anchor, openMenu, closeMenu, open } = useMenuHook();

  return (
    <Fragment>
      <Tooltip arrow title="Table Settings" placement="top">
        <IconButton onClick={openMenu} size="medium">
          <Tune fontSize="small" />
        </IconButton>
      </Tooltip>

      <ColumnsSwitcher
        open={open}
        onClose={closeMenu}
        anchor={anchor}
        table={table}
      />
    </Fragment>
  );
};
