import { FC, Fragment, memo, useCallback } from "react";
import {
  Grid,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { useEffectOnceWhen } from "rooks";
import {
  useGetUsersMeIntegrationsQuery,
  usePostUsersMeJiraIntegrationsByIntegrationIdProjectsMutation,
} from "../../../../../../../services/cloudchipr.api";
import { RowLabelColumn } from "../RowLabelColumn";
import { getIntegrationByIdThunk } from "../../../../../../../store/integrations/thunks/getIntegrationByIdThunk";
import { useAppDispatch } from "../../../../../../../store/hooks";
import { jiraProjectsFetchFixedCacheKey } from "../../utils/constants/fixedCacheKeys";

interface IntegrationsRowProps {
  integrationId: string;
  onChange(id: string, projectKey?: string, issueTypeId?: string): void;
}

export const IntegrationsRow: FC<IntegrationsRowProps> = memo(
  ({ onChange, integrationId }) => {
    const dispatch = useAppDispatch();

    const [fetchProjects] =
      usePostUsersMeJiraIntegrationsByIntegrationIdProjectsMutation({
        fixedCacheKey: jiraProjectsFetchFixedCacheKey,
      });

    const { data: integrations } = useGetUsersMeIntegrationsQuery(
      { type: "jira" },
      { refetchOnMountOrArgChange: !integrationId },
    );

    const renderValue = useCallback(
      (selected: string) =>
        integrations?.find(({ id }) => id === selected)?.name || selected,
      [integrations],
    );

    const integrationIdChangeHandler = useCallback(
      (integrationId?: string) => {
        if (!integrationId) {
          return;
        }

        dispatch(getIntegrationByIdThunk({ id: integrationId, type: "jira" }))
          .then(({ payload }: any) => {
            const projectKey = payload?.data?.default_project_key ?? "";
            const issueTypeId = payload?.data?.default_issue_type_id ?? "";

            onChange(integrationId, projectKey, issueTypeId);
          })
          .catch(() => onChange(integrationId));

        fetchProjects({ integrationId });
      },
      [dispatch, onChange, fetchProjects],
    );

    const changeHandler = useCallback(
      (event: SelectChangeEvent) =>
        integrationIdChangeHandler(event.target.value),
      [integrationIdChangeHandler],
    );

    useEffectOnceWhen(() => {
      integrationIdChangeHandler(integrations?.[0]?.id);
    }, !!integrations?.length);

    return (
      <Fragment>
        <RowLabelColumn label="Integration" required />

        <Grid item sm={4}>
          <Select
            fullWidth
            size="small"
            name="integrationId"
            onChange={changeHandler}
            value={integrationId}
            renderValue={renderValue}
          >
            {integrations?.map((integration) => {
              return (
                <MenuItem value={integration.id} key={integration.id}>
                  <ListItemText primary={integration.name} sx={{ m: 0 }} />
                </MenuItem>
              );
            })}
          </Select>
        </Grid>

        <Grid item sm={6} />
      </Fragment>
    );
  },
);
