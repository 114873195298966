import numeral from "numeral";
import { MetricType } from "../types";

export const formatMaxPoint = (type: MetricType, point?: number | null) => {
  if (point === null) {
    return null;
  }

  if (type === "compute") {
    return point;
  }

  return numeral(point).format("0.[00]a");
};
