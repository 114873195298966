import { FC, useCallback } from "react";
import { InputLabel, Stack, TextField, Typography } from "@mui/material";

interface CleanV2ConfirmationSectionProps {
  cleanInputValue: string;
  onCleanInputChange(value: string): void;
  placeholder: string;
}
export const CleanV2ConfirmationSection: FC<
  CleanV2ConfirmationSectionProps
> = ({ cleanInputValue, onCleanInputChange, placeholder }) => {
  const inputValueChangeHandler = useCallback(
    (event: any) => {
      onCleanInputChange(event.target.value);
    },
    [onCleanInputChange],
  );

  return (
    <Stack sx={{ p: 3 }}>
      <InputLabel
        sx={{
          fontSize: 14,
          color: "text.secondary",
          pb: 1,
        }}
      >
        Please type{" "}
        <Typography fontSize={14} fontStyle="italic" component="span">
          {placeholder}
        </Typography>{" "}
        to confirm
      </InputLabel>
      <TextField
        value={cleanInputValue}
        onChange={inputValueChangeHandler}
        name="cleanInputValue"
        placeholder={placeholder}
        fullWidth
      />
    </Stack>
  );
};
