import { FC } from "react";
import { Button } from "@mui/material";

interface ApplyButtonProps {
  onClick(): void;
  disabled?: boolean;
}

export const ApplyButton: FC<ApplyButtonProps> = ({ disabled, onClick }) => {
  return (
    <Button
      size="small"
      variant="contained"
      onClick={onClick}
      disabled={disabled}
      sx={{ mt: 3, alignSelf: "end" }}
    >
      Apply
    </Button>
  );
};
