import { FC, Fragment, useMemo } from "react";
import { TextField } from "@mui/material";
import ArrowDropDownOutlinedIcon from "@mui/icons-material/ArrowDropDownOutlined";
import { GroupingOptionIcon } from "../../../pages/common/resource-explorer-grouping-selector/components/GroupingOptionIcon";
import { CountChipWithTooltip } from "../../../pages/common/resource-explorer-grouping-selector/components/CountChipWithTooltip";
import { ResourceExplorerGrouping } from "../../../../services/cloudchipr.api";
import { resourceExplorerGroupings } from "../../../pages/resource-explorer/components/resource-explorer-card/utils/constants/groupings";
import { useGetResourceExplorerFiltersByFilterProviderAndFilterTypeValuesFromAccountServiceQuery } from "../../../../services/cloudchipr-account-service.api";
import { getResourcesExplorerGroupingLabel } from "../../../pages/resource-explorer/components/resource-explorer-card/utils/helpers/getResourcesExplorerGroupingLabel";

interface InternalGroupBySelectorTriggerProps {
  grouping: ResourceExplorerGrouping;
  groupingValues?: string[];
  organisationId: string;
}

export const InternalGroupBySelectorSkeleton: FC<
  InternalGroupBySelectorTriggerProps
> = ({ grouping, organisationId, groupingValues }) => {
  const groupBy = resourceExplorerGroupings.includes(grouping)
    ? grouping
    : "category";

  const { data } =
    useGetResourceExplorerFiltersByFilterProviderAndFilterTypeValuesFromAccountServiceQuery(
      {
        filterProvider: "dimensions",
        filterType: "dimension_id",
        organisationId,
      },
      { skip: groupBy !== "category" },
    );

  const dimensionName = useMemo(() => {
    if (groupBy !== "category") {
      return "";
    }

    const firstValue = groupingValues?.at(0);
    const selected = data?.find((option) => option.value === firstValue);

    return selected?.title ?? selected?.value ?? "";
  }, [groupingValues, groupBy, data]);

  const label =
    groupBy === "category"
      ? dimensionName
      : getResourcesExplorerGroupingLabel(groupBy, true);

  return (
    <TextField
      size="xsmall"
      label="Group by"
      contentEditable={false}
      value={grouping ? label : ""}
      InputProps={{
        readOnly: true,
        inputProps: {
          style: {
            paddingLeft: 8,
            cursor: "pointer",
            caretColor: "transparent",
            textOverflow: "ellipsis",
            overflow: "hidden",
          },
        },
        sx: { pr: 1, cursor: "pointer", minWidth: 175 },
        endAdornment: (
          <TriggerEndAdornment
            values={
              groupBy === "cost_allocation_tag" ? groupingValues : undefined
            }
          />
        ),
        startAdornment: grouping ? (
          <GroupingOptionIcon grouping={groupBy} />
        ) : undefined,
      }}
    />
  );
};

const TriggerEndAdornment: FC<{
  values?: string[];
}> = ({ values }) => {
  return (
    <Fragment>
      {!!values?.length && <CountChipWithTooltip values={values} />}

      <ArrowDropDownOutlinedIcon color="action" />
    </Fragment>
  );
};
