import { getCurrentProvidersByProviderBillingStatusDataSelector } from "./getCurrentProvidersByProviderBillingStatusDataSelector";
import { RootState } from "../../../store";
import { connectedProvidersSelector } from "../connectedProvidersSelector";

export const isBillingStatusesGettingFulfilledSelector = (state: RootState) => {
  const providers = connectedProvidersSelector(state);
  const succeededCallsCount = providers.reduce((result, item) => {
    if (getCurrentProvidersByProviderBillingStatusDataSelector(state, item)) {
      result++;
    }

    return result;
  }, 0);

  return providers.length === succeededCallsCount;
};
