import { costBreakdownSetupPropertyByKeySelector } from "./costBreakdownSetupPropertyByKeySelector";
import { CostBreakdownWidgetSetupType } from "../../../utils/types/setups/costBreakdownSetupType";
import { RootState } from "../../../../store";

export const costBreakdownSetupAggregationsPropertyByKeySelector =
  (key: keyof CostBreakdownWidgetSetupType["aggregation"]) =>
  (state: RootState): any => {
    const setup = costBreakdownSetupPropertyByKeySelector("aggregation")(state);

    if (!setup) {
      return null;
    }

    return setup?.[key];
  };
