import * as Yup from "yup";

export const integrationNotificationValidationSchema = Yup.object({
  to: Yup.array()
    .nullable()
    .when("type", {
      is: (type: string) => type === "email",
      then: Yup.array()
        .of(Yup.string().trim().email("Invalid email format"))
        .min(1, "At least one email should be selected.")
        .required("Please select integration"),
    }),

  conversations: Yup.array()
    .nullable()
    .when("type", {
      is: (type: string) => type === "slack",
      then: Yup.array()
        .min(1, "At least one conversation should be selected.")
        .required("Please select integration"),
    }),
});
