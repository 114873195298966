import { ChangeEvent, FC } from "react";
import { IconButton, Stack, TextField, Tooltip } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import ReplayIcon from "@mui/icons-material/Replay";

interface SlackConversationsSelectDropdownHeaderProps {
  onSearchChange(value: ChangeEvent): void;
  onRefresh(): void;
}

export const SlackConversationsSelectDropdownHeader: FC<
  SlackConversationsSelectDropdownHeaderProps
> = ({ onSearchChange, onRefresh }) => {
  return (
    <Stack
      p={2}
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      spacing={2}
    >
      <TextField
        fullWidth
        autoFocus
        size="xsmall"
        variant="outlined"
        placeholder="Search..."
        onChange={onSearchChange}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
      />
      <Tooltip title="Refresh conversations list">
        <IconButton onClick={onRefresh}>
          <ReplayIcon fontSize="small" color="primary" />
        </IconButton>
      </Tooltip>
    </Stack>
  );
};
