import { FC } from "react";
import { AwsBillingErrorState } from "./AwsBillingErrorState";
import { AwsInProgressState } from "./AwsInProgressState";
import { AnalyzingBillingData } from "../AnalyzingBillingData";
import { useAppSelector } from "../../../../../../../store/hooks";
import { billingStatusByProviderSelector } from "../../../../../../../store/common/selectors/billing-status/billingStatusByProviderSelector";
import { ProviderType } from "../../../../../../../services/cloudchipr.api";

interface AwsAccessControllerProps {
  provider: ProviderType;
}

export const AwsAccessController: FC<AwsAccessControllerProps> = ({
  provider,
}) => {
  const status = useAppSelector((state) =>
    billingStatusByProviderSelector(state, provider),
  );

  if (
    status === "error" ||
    status === "missing_data" ||
    status === "missing_setup"
  ) {
    return <AwsBillingErrorState />;
  }

  if (status === "waiting_for_cloud_provider") {
    return <AwsInProgressState />;
  }

  if (status === "collecting" || status === "analysing") {
    return <AnalyzingBillingData />;
  }

  return null;
};
