import { FC, useCallback } from "react";
import moment from "moment";
import { TimePicker } from "../../../../../../common/TimePicker";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../store/hooks";
import { NullableDate } from "../../../../../../../services/cloudchipr.api";
import { setReportsScheduleDataThunk } from "../../../../../../../store/reports/thunks/setReportsScheduleDataThunk";
import { reportsScheduleDataByKeySelector } from "../../../../../../../store/reports/selector/reportsScheduleDataByKeySelector";
import { formatDate } from "../../../../../../../utils/helpers/date-time/datetime-format";

interface ReportsDrawerTimePickerProps {
  icon?: boolean;
}

export const ReportsDrawerTimePicker: FC<ReportsDrawerTimePickerProps> = ({
  icon,
}) => {
  const dispatch = useAppDispatch();
  const time = useAppSelector(reportsScheduleDataByKeySelector("time"));
  const today = formatDate(moment(), { type: "yearMonthDay" });

  const changeHandler = useCallback(
    (time: NullableDate) => {
      if (!time) {
        return;
      }

      dispatch(
        setReportsScheduleDataThunk({
          time: formatDate(time, { type: "unTime" }),
        }),
      );
    },
    [dispatch],
  );

  return (
    <TimePicker
      icon={icon}
      onChange={changeHandler}
      value={`${today} ${time}`}
    />
  );
};
