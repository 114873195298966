import { Typography } from "@mui/material";
import { ColumnSetupType } from "../../../../../../../../storybook/data-grid/utils/types/types";
import { ReservationUtilizationRecommendation } from "../../../../../../../../services/cloudchipr.api";
import { money } from "../../../../../../../../utils/numeral/money";
import { CommitmentsProgressBar } from "../../../../../common/components/CommitmentsProgressBar";
import { DatesCell } from "../../../../../common/components/data-grid/cells/DatesCell";
import { CommitmentsRecommendationsEstimatedMonthlySavingCell } from "../../../content/recomendations/cells/CommitmentsRecommendationsEstimatedMonthlySavingCell";
import { TypographyWithTooltip } from "../../../../../../../common/TypographyWithTooltip";

export const reservedInstanceDrawerRecommendationsGridColumns: ColumnSetupType<ReservationUtilizationRecommendation>[] =
  [
    {
      minSize: 200,
      accessorKey: "current_instance_count",
      header: "Current Instance Count",
      cell: ({ row }) => {
        return (
          <Typography variant="body2" fontWeight="medium">
            {row.original.current_instance_count}
          </Typography>
        );
      },
    },
    {
      minSize: 240,
      accessorKey: "recommended_instance_count",
      header: "Recommended Instance Count",
      cell: ({ row }) => {
        return (
          <Typography variant="body2" fontWeight="medium">
            {" "}
            {row.original.recommended_instance_count}
          </Typography>
        );
      },
    },
    {
      minSize: 100,
      accessorKey: "platform",
      header: "Platform",
      cell: ({ row }) => (
        <TypographyWithTooltip
          title={row.original.platform ?? "-"}
          variant="body2"
          fontWeight="medium"
        />
      ),
    },

    {
      minSize: 220,
      accessorKey: "current_utilization",
      header: "Current Utilization",
      cell: (cell) => (
        <CommitmentsProgressBar value={cell.getValue() as number} />
      ),
    },
    {
      minSize: 220,
      accessorKey: "estimated_utilization",
      header: "Estimated Utilization",
      cell: (cell) => (
        <CommitmentsProgressBar value={cell.getValue() as number} />
      ),
    },
    {
      minSize: 240,
      accessorKey: "reservation_end_date",
      header: "Current Commitment End Date",
      cell: (cell) => <DatesCell date={cell.getValue() as string} />,
    },
    {
      minSize: 220,
      accessorKey: "recommended_start_date",
      header: "Recommended Start Date",
      cell: (cell) => <DatesCell date={cell.getValue() as string} />,
    },
    {
      minSize: 220,
      accessorKey: "estimated_monthly_savings",
      header: () => {
        return (
          <Typography
            variant="body2"
            fontWeight="medium"
            align="right"
            width="100%"
          >
            Estimated Monthly Savings
          </Typography>
        );
      },
      cell: (cell) => {
        return (
          <Typography
            variant="body2"
            color="success.light"
            align="right"
            pr={4}
          >
            {money(cell.getValue() as number)}{" "}
          </Typography>
        );
      },
    },
    {
      minSize: 215,
      accessorKey: "estimated_monthly_savings",
      header: "Estimated Monthly Savings",
      cell: ({ row }) => (
        <CommitmentsRecommendationsEstimatedMonthlySavingCell
          value={row.original.estimated_monthly_savings}
          link={row.original.link}
        />
      ),
      meta: {
        sticky: "right",
        hideFromSettings: true,
        cellStyles: {
          bgcolor: "white",
        },
      },
    },
  ];
