import { AccountOptionType } from "../../types/types";

export const sortByAlphanumericForAccountOptionType = (
  options: AccountOptionType[],
) => {
  return [...options].sort((a, b) => {
    let current = a.label;
    let next = b.label;

    if (!current || !next) {
      return 0;
    }

    current = current.toLowerCase();
    next = next.toLowerCase();

    if (current < next) {
      return -1;
    }

    if (current > next) {
      return 1;
    }

    return 0;
  });
};
