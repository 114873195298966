import { commitmentUtilizationWidgetByIdSelector } from "./commitmentUtilizationWidgetByIdSelector";
import { RootState } from "../../../../../store";

export const commitmentUtilizationWidgetVisualizationSelector = (
  state: RootState,
  widgetId: string,
) => {
  return commitmentUtilizationWidgetByIdSelector(state, widgetId)
    ?.visualization;
};
