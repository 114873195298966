import { COMPONENT_ID } from "../../../../utils/constants/components-ids";

export const scrollToElement = (elementId: string, wrapperId: string) => {
  setTimeout(() => {
    const node = document.getElementById(elementId);
    const commonWrapper = document.getElementById(wrapperId);

    commonWrapper?.scrollTo({
      top: (node?.offsetTop ?? 0) + (node?.clientHeight ?? 0),
      behavior: "smooth",
    });
  }, 500);
};

export const scrollPageToBottom = (delay = 300) => {
  setTimeout(() => {
    const commonWrapper = document.getElementById(
      COMPONENT_ID.APP_OUTLET_WRAPPER,
    );

    commonWrapper?.scrollTo({
      top: commonWrapper.scrollHeight,
      behavior: "smooth",
    });
  }, delay);
};

export const scrollPageToTop = (delay = 300) => {
  setTimeout(() => {
    const commonWrapper = document.getElementById(
      COMPONENT_ID.APP_OUTLET_WRAPPER,
    );

    commonWrapper?.scrollTo({ top: 0, behavior: "smooth" });
  }, delay);
};
