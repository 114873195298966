import { FC, useCallback, useMemo } from "react";
import { AutocompleteSelect } from "../../autocomplete-select/AutocompleteSelect";
import { DropdownFooterComponent } from "../../dropdown-select/utils/types/types";
import { TypographyWithTooltip } from "../../../TypographyWithTooltip";

interface TagFilterDropdownSelectKeysProps {
  options: {
    value: string;
    title?: string;
  }[];
  filter?: string;
  onChange: (tag: string) => void;
  alreadyFilteredTagKeys: string[];
  DropdownFooter?: DropdownFooterComponent;
  label?: string;
  placeholder?: string;
}

export const TagFilterDropdownSelectKeys: FC<
  TagFilterDropdownSelectKeysProps
> = ({
  alreadyFilteredTagKeys,
  options,
  filter,
  onChange,
  DropdownFooter,
  placeholder,
  label,
}) => {
  const dropdownOptions = useMemo(() => {
    return (
      options?.map(({ value, title }) => {
        return {
          value: value,
          label: (
            <TypographyWithTooltip variant="body2" title={title || value} />
          ),
          disabled: !!alreadyFilteredTagKeys?.find(
            (member) => member === value && filter !== member,
          ),
        };
      }) ?? []
    );
  }, [options, alreadyFilteredTagKeys, filter]);

  const tagChangeHandler = useCallback(
    (values: string[]) => {
      const value = values[0];

      value && onChange(value);
    },
    [onChange],
  );

  return (
    <AutocompleteSelect
      size="xsmall"
      freeSolo
      creatable
      singleSelect
      DropdownFooter={DropdownFooter}
      label={label ?? "Select Tag"}
      placeholder={placeholder ?? "Select Tag"}
      options={dropdownOptions ?? []}
      initialSelectedValues={filter ? [filter] : []}
      onChange={tagChangeHandler}
      listWidth={500}
    />
  );
};
