import { FC, Fragment } from "react";

interface SchemeCellProps {
  data: string;
}

export const SchemeCell: FC<SchemeCellProps> = ({ data }) => {
  if (!data) {
    return <div>-</div>;
  }
  return <Fragment>{labels?.[data] ?? data ?? "-"}</Fragment>;
};

const labels: Record<string, string> = {
  internal: "Internal",
  internet_facing: "Internet-facing",
};
