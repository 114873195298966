import { createAsyncThunk } from "@reduxjs/toolkit";
import { enqueueSnackbar } from "notistack";

import { getDashboardsVisibilityHierarchyThunk } from "../dashboard-hierarchy-visibility/getDashboardsVisibilityHierarchyThunk";
import { getDashboardByIdThunk } from "../dashboard/getDashboardByIdThunk";
import { cloudChiprApi } from "../../../../services/cloudchipr.api";
import { updateDashboardFixedCacheKey } from "../../../../components/pages/dashboard/utils/constants/fixedCacheKeys";

type UpdateDashboardHierarchyItemNameThunkArgs = {
  name: string;
  dashboardId: string;
  activeDashboardId?: string;
};

export const updateDashboardHierarchyItemNameThunk = createAsyncThunk(
  "dashboards/updateDashboardHierarchyItemName",
  async (
    {
      name,
      dashboardId,
      activeDashboardId,
    }: UpdateDashboardHierarchyItemNameThunkArgs,
    { dispatch },
  ) => {
    const { patchUsersMeOrganisationsCurrentDashboardsByDashboardId } =
      cloudChiprApi.endpoints;

    try {
      await dispatch(
        patchUsersMeOrganisationsCurrentDashboardsByDashboardId.initiate(
          {
            dashboardId,
            body: { name },
          },
          { fixedCacheKey: updateDashboardFixedCacheKey },
        ),
      ).unwrap();
      dispatch(getDashboardsVisibilityHierarchyThunk());

      if (dashboardId === activeDashboardId) {
        dispatch(getDashboardByIdThunk(dashboardId));
      }

      enqueueSnackbar("Dashboard name successfully updated.", {
        variant: "snackbarAlert",
        AlertSnackBarProps: {
          severity: "success",
        },
      });
    } catch (e) {
      // @ts-expect-error TODO: return to this after adding error type
      const errMessage = e?.data?.message || "Something went wrong";
      enqueueSnackbar(errMessage, {
        variant: "snackbarAlert",
        AlertSnackBarProps: {
          severity: "error",
        },
      });
    }
  },
);
