import { offHoursRecommendationsDbEmptySelector } from "./offHoursRecommendationsDbEmptySelector";
import { offHoursRecommendationsComputeEmptySelector } from "./offHoursRecommendationsComputeEmptySelector";
import { RootState } from "../../../../store";

export const offHoursRecommendationsDataGridAllEmptySelector = (
  state: RootState,
) => {
  const computeEmpty = offHoursRecommendationsComputeEmptySelector(state);
  const dbEmpty = offHoursRecommendationsDbEmptySelector(state);

  return computeEmpty && dbEmpty;
};
