import { createDraftSafeSelector } from "@reduxjs/toolkit";
import { awsNonDeletedAndNotBillingAccountsSelector } from "./aws/awsNonDeletedAndNotBillingAccountsSelector";
import { gcpNonDeletedAndNotBillingAccountsSelector } from "./gcp/gcpNonDeletedAndNotBillingAccountsSelector";
import { azureNonDeletedAndNotBillingAccountsSelector } from "./azure/azureNonDeletedAndNotBillingAccountsSelector";
import { ProviderType } from "../../../services/cloudchipr.api";

export const initialProviderSelector = createDraftSafeSelector(
  [
    awsNonDeletedAndNotBillingAccountsSelector,
    gcpNonDeletedAndNotBillingAccountsSelector,
    azureNonDeletedAndNotBillingAccountsSelector,
  ],
  (aws, gcp, azure): ProviderType | null => {
    let provider: ProviderType | null = aws?.length ? "aws" : null;

    if (!provider && gcp?.length) {
      provider = "gcp";
    }

    if (!provider && azure?.length) {
      provider = "azure";
    }

    return provider;
  },
);
