import { FilterFn } from "@tanstack/react-table";
import { normalizeString } from "./common";

export const labelFilter: FilterFn<any> = (row, columnId, value) => {
  const data = row.getValue(columnId) ?? row.original.field;
  return (
    normalizeString(data)?.includes(normalizeString(value)) ||
    normalizeString(row.original.id)?.includes(normalizeString(value))
  );
};
