import { dashboardHierarchyVisibilitiesDataSelector } from "./dashboardHierarchyVisibilitiesDataSelector";
import { RootState } from "../../../store";
import {
  Dashboard,
  DashboardFolder,
} from "../../../../services/cloudchipr.api";

export const currentDashboardHierarchyVisibilitiesDataSelector = (
  state: RootState,
): (Dashboard | DashboardFolder)[] => {
  const data = dashboardHierarchyVisibilitiesDataSelector(state);
  const privateData = data?.visible_only_to_me ?? [];
  const publicData = data?.visible_to_everyone ?? [];

  return publicData.concat(privateData);
};
