import { ColumnSetupType } from "../../../../../../../../../../../../../../storybook/data-grid/utils/types/types";
import { DynamoDbBackup } from "../../../../../../../../../../../../../../services/cloudchipr.api";

export const dynamoDbBackupsTableColumns: ColumnSetupType<DynamoDbBackup>[] = [
  {
    accessorKey: "name",
    header: "Name",
    type: "identifier",
    size: 210,
    meta: { sticky: "left", hideFromSettings: true },
  },
  {
    accessorKey: "price_per_month",
    header: "Monthly Price",
    type: "money",
    headerTooltip:
      "Monthly prices are calculated based on the on-demand list price of each resource.",
  },
  {
    accessorKey: "account",
    header: "Account",
    type: "account",
    size: 210,
  },
  {
    accessorKey: "created_at",
    header: "Date Created",
    type: "date",
  },
  {
    accessorKey: "all_time_spent",
    header: "Total Spend",
    type: "money",
  },
  {
    accessorKey: "table_name",
    header: "Table",
  },
  {
    accessorKey: "size",
    header: "Size",
    type: "byteToGib",
  },
  {
    accessorKey: "type",
    header: "Type",
    type: "backupType",
  },
  {
    accessorKey: "storage_tier",
    header: "Storage Tier",
  },
  {
    accessorKey: "creation_data",
    header: "Created By",
    type: "createdBy",
    headerTooltip:
      "The Created By value is only available for resources created within the last 90 days.",
  },
  { accessorKey: "region", header: "Region", type: "withCopy" },
  { accessorKey: "tags", header: "Tags", type: "tag", enableSorting: false },
  {
    accessorKey: "tags",
    header: "Smart Tags",
    id: "smart_tag",
    type: "smartTag",
    enableSorting: false,
  },
  {
    accessorKey: "issues",
    header: "Ticket Status",
    type: "jiraTicketStatus",
    enableSorting: false,
  },
];
