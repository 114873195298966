import { RootState } from "../../../store";
import { liveUsageMgmtAppliedTemplateIdSelector } from "../store-selectors/liveUsageMgmtAppliedTemplateIdSelector";
import { liveUsageMgmtFilterTemplatesSelector } from "../liveUsageMgmtFilterTemplatesSelector";
import { FilterSet } from "../../../../services/cloudchipr.api";

export const liveUsageMgmtAppliedFilterTemplateSelector = (
  state: RootState,
): FilterSet | undefined => {
  const filterSetsList = liveUsageMgmtFilterTemplatesSelector(state);
  const appliedFilterSetId = liveUsageMgmtAppliedTemplateIdSelector(state);

  if (!appliedFilterSetId) {
    return;
  }
  return filterSetsList?.find(({ id }) => id === appliedFilterSetId);
};
