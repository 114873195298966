import { RootState } from "../../../../../store";
import { cloudChiprApi } from "../../../../../../services/cloudchipr.api";
import { updateTaskEnvironmentPropertiesFixedCacheKey } from "../../../../utils/constants/fixedCacheKeys";

const selector =
  cloudChiprApi.endpoints.putUsersMeOrganisationsCurrentTasksPropertiesEnvironments.select(
    updateTaskEnvironmentPropertiesFixedCacheKey,
  );

export const taskManagementEditingEnvironmentsLoadingSelector = (
  state: RootState,
) => {
  return selector(state)?.isLoading;
};
