import { FC } from "react";
import { Stack } from "@mui/material";
import Typography from "@mui/material/Typography";
import { NavigationItemsVisibilityType } from "../../../../../../../../store/navigation/utils/types";

interface DashboardMoveActionDialogContentProps {
  currentItemName: string;
  visibility: NavigationItemsVisibilityType;
}

export const DashboardMoveActionDialogContent: FC<
  DashboardMoveActionDialogContentProps
> = ({ visibility, currentItemName }) => {
  return (
    <Stack spacing={3}>
      {visibility === "visible_to_everyone" && (
        <Typography>
          Changing the dashboard visibility to{" "}
          <Typography variant="inherit" fontWeight="bold" component="span">
            Visible to everyone
          </Typography>{" "}
          will deactivate any widgets linked to your{" "}
          <Typography variant="inherit" fontWeight="bold" component="span">
            Visible only to me
          </Typography>{" "}
          views. This action cannot be undone.
        </Typography>
      )}

      <Typography variant="inherit">
        Please confirm if you want to move the{" "}
        <Typography variant="inherit" fontWeight="bold" component="span">
          {currentItemName}
        </Typography>{" "}
        dashboard.
      </Typography>
    </Stack>
  );
};
