import { DashboardMixedVisibilitiesItemType } from "../../../../components/pages/dashboard-all-items/components/table/utils/types/types";
import { ReMixedVisibilitiesItemType } from "../../../../components/pages/resource-explorer-all-views/components/table/utils/types/types";

export const getFlattenMixedHierarchyMap = <
  T extends DashboardMixedVisibilitiesItemType | ReMixedVisibilitiesItemType,
>(
  data: T[],
): Record<string, T> => {
  return data.reduce(
    (map, item) => {
      map[item.id] = item;
      return map;
    },
    {} as Record<string, T>,
  );
};
