import { cloudChiprApi } from "../../../services/cloudchipr.api";
import { editReportFixedCacheKey } from "../utils/constants/fixedCacheKeys";
import { RootState } from "../../store";

const endpoint =
  cloudChiprApi.endpoints.putUsersMeOrganisationsCurrentReportsByReportId.select(
    editReportFixedCacheKey,
  );

export const editReportLoadingSelector = (state: RootState) => {
  return endpoint(state)?.isLoading;
};
