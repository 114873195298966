import { FC, useCallback } from "react";
import { Button, ButtonProps } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useAppAbility } from "../../../../services/permissions";
import { useAppDispatch } from "../../../../store/hooks";
import { setDimensionsDrawerMode } from "../../../../store/dimensions/dimensionsSlice";

interface AddDimensionButtonProps {
  variant?: ButtonProps["variant"];
  size?: ButtonProps["size"];
}

export const AddDimensionButton: FC<AddDimensionButtonProps> = ({
  variant = "contained",
  size = "small",
}) => {
  const { cannot } = useAppAbility();
  const canNotCreateCategory = cannot("create", "category");
  const dispatch = useAppDispatch();

  const dimensionDrawerOpenHandler = useCallback(() => {
    dispatch(setDimensionsDrawerMode("dimensionCreate"));
  }, [dispatch]);

  return (
    <Button
      sx={sx}
      size={size}
      variant={variant}
      disabled={canNotCreateCategory}
      onClick={dimensionDrawerOpenHandler}
      startIcon={<AddIcon fontSize="small" />}
    >
      Add Dimension
    </Button>
  );
};

const sx = { whiteSpace: "nowrap", textTransform: "none" };
