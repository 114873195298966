import { FC, Fragment, useCallback } from "react";
import {
  Box,
  ListItemText,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import moment from "moment";
import { DateSelectionMonthComponent } from "./DateSelectionMonthComponent";
import { BudgetPeriod } from "../../../../../../../../../services/cloudchipr.api";
import { formatDate } from "../../../../../../../../../utils/helpers/date-time/datetime-format";
import { budgetCreateYears } from "../../utils/constants/date-selection";
import { useAppSelector } from "../../../../../../../../../store/hooks";
import { isCurrentBudgetEditingSelector } from "../../../../../../../../../store/budgets/selectors/current-budget/data-selectors/isCurrentBudgetEditingSelector";

interface StartDateSelectionProps {
  period: BudgetPeriod;
  startDate: string;
  onDateChange(date: string): void;
}

export const StartDateSelection: FC<StartDateSelectionProps> = ({
  period,
  startDate,
  onDateChange,
}) => {
  const isEdit = useAppSelector(isCurrentBudgetEditingSelector);

  const dateChangeHandler = useCallback(
    (date: any) => onDateChange(formatDate(date)),
    [onDateChange],
  );

  const yearChangeHandler = useCallback(
    (event: any) => {
      const currentDate = moment().format();
      const newDate = moment(startDate || undefined).set(
        "year",
        event.target.value,
      );

      const diff = moment(newDate).diff(currentDate);

      onDateChange(formatDate(diff <= 0 ? currentDate : newDate));
    },
    [onDateChange, startDate],
  );

  if (period === "daily") {
    return (
      <Fragment>
        <Typography variant="body2" mb={1}>
          Start Day*
        </Typography>

        <DatePicker
          value={moment(startDate)}
          disabled={isEdit}
          minDate={moment()}
          onChange={dateChangeHandler}
          slotProps={{
            textField: {
              fullWidth: true,
              placeholder: "Start Day",
              size: "small",
              variant: "outlined",
              name: "value",
            },
          }}
        />
      </Fragment>
    );
  }

  const selectedYear = moment(startDate || undefined).get("year");

  const YearSelectionComponent = (
    <Fragment>
      <Typography variant="body2" mb={1}>
        Start Year*
      </Typography>

      <Select
        fullWidth
        disabled={isEdit}
        size="small"
        value={startDate ? selectedYear : ""}
        renderValue={(year) => year}
        onChange={yearChangeHandler}
      >
        {budgetCreateYears.map((year) => {
          return (
            <MenuItem value={year} key={year}>
              <ListItemText>{year}</ListItemText>
            </MenuItem>
          );
        })}
      </Select>
    </Fragment>
  );

  if (period === "annually") {
    return YearSelectionComponent;
  }

  return (
    <Stack direction="row" spacing={2} justifyContent="space-between">
      <Box flexGrow={1}>
        <DateSelectionMonthComponent
          period={period}
          startDate={startDate}
          onDateChange={onDateChange}
        />
      </Box>

      <Box flexGrow={1}>{YearSelectionComponent}</Box>
    </Stack>
  );
};
