import { createDraftSafeSelector } from "@reduxjs/toolkit";
import { step1FormDataSelector } from "./step1FormDataSelector";

export const step1CompletedSelector = createDraftSafeSelector(
  [step1FormDataSelector],
  (data) => {
    return !!(
      data?.thresholds?.at(0)?.percent &&
      (data?.notifications?.at(0)?.integration_id || data?.email_to?.to?.at(0))
    );
  },
);
