import { RootState } from "../../../store";
import { cloudChiprApi } from "../../../../services/cloudchipr.api";
import { resourceExplorerMinDateFixedCacheKey } from "../../thunks/widgets/getResourceExplorerMinAvailableDateThunk";

const selector =
  cloudChiprApi.endpoints.postUsersMeOrganisationsCurrentMinimumAvailableDate.select(
    resourceExplorerMinDateFixedCacheKey,
  );

export const resourceExplorerMinAvailableDateSelector = (
  state: RootState,
): string | undefined => {
  return selector(state)?.data?.minimum_available_date ?? undefined;
};
