import { Line } from "recharts";
import { ChartColorsType } from "../../../utils/types/types";

export const lineChartLines = ({
  keys,
  onMouseLeave,
  onMouseEnter,
  colors,
  highlighted,
}: {
  keys: string[];
  highlighted: string;
  colors: ChartColorsType;
  onMouseEnter(key: string): void;
  onMouseLeave(): void;
}) => {
  return keys.map((key) => {
    const filter = highlighted === key ? "none" : "grayscale(1)";
    const opacity = highlighted === key ? 1 : 0.2;

    return (
      <Line
        key={key}
        dataKey={key}
        type="linear"
        strokeWidth={3}
        stroke={colors[key]}
        onMouseEnter={onMouseEnter.bind(null, key)}
        onMouseLeave={onMouseLeave}
        dot={false}
        activeDot={
          <CustomizedDot
            color={colors[key]}
            onMouseEnter={onMouseEnter.bind(null, key)}
            onMouseLeave={onMouseLeave}
          />
        }
        filter={highlighted ? filter : "none"}
        strokeOpacity={highlighted ? opacity : "none"}
      />
    );
  });
};

const CustomizedDot = (props: any) => {
  const { cx, cy, color, onMouseEnter, onMouseLeave, value } = props;

  if (!value && value !== 0) {
    return null;
  }

  return (
    <circle
      cx={cx}
      cy={cy}
      r="8"
      stroke="white"
      strokeWidth="3"
      fill={color}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    />
  );
};
