import { FC, memo, RefObject } from "react";
import { Row } from "@tanstack/react-table";
import { ResourcesDataGridSubRowTable } from "./ResourcesDataGridSubRowTable";
import { ResourceEmptyState, SubRowDataGridProps } from "./SubRowDataGridNF";
import { getFirstResourceTypeFromResources } from "../utils/helpers/getFirstResourceTypeFromResources";
import { nestedResourcesExcludedRowsByParentDataGenerator } from "../utils/data-grid/columns-setup/nestedResourcesExcludedRowsByParentDataGenerator";
import {
  RdsSnapshot,
  RdsSnapshotSource,
} from "../../../../../../../../../../services/cloudchipr.api";
import { expandableResourceTypes } from "../utils/data-grid/constants";
import { ResourceDataType } from "../../../../../../../../../../store/live-usage-mgmt/utils/types/types";

interface ResourcesDataGridSubRowProps {
  resource: SubRowDataGridProps["resource"] | null;
  globalFilter?: string;
  parentSource?: string;
  parentContainerRef: RefObject<HTMLTableElement>;
  exportedColumns?: string[];
}

const ResourcesSubRowNF: FC<ResourcesDataGridSubRowProps> = memo(
  ({
    exportedColumns,
    parentContainerRef,
    resource,
    globalFilter,
    parentSource,
  }) => {
    const subRowResourceType = getFirstResourceTypeFromResources(
      resource?.resources,
    );

    if (!subRowResourceType || !resource) {
      return <ResourceEmptyState />;
    }

    return (
      <ResourcesDataGridSubRowTable
        resource={resource}
        globalFilter={globalFilter}
        parentSource={parentSource ?? ""}
        parentContainerRef={parentContainerRef}
        exportedColumns={exportedColumns}
        expandable={expandableResourceTypes.has(subRowResourceType)}
      />
    );
  },
);

export const resourcesDataGridSubRow = (
  row: Row<ResourceDataType>,
  parentContainerRef: RefObject<HTMLTableElement>,
  globalFilter?: string,
) => {
  return (
    <ResourcesSubRowNF
      resource={row.original.child_resource}
      parentSource={row.original.resource.id}
      globalFilter={globalFilter}
      parentContainerRef={parentContainerRef}
      exportedColumns={nestedResourcesExcludedRowsByParentDataGenerator(
        row?.original?.resource_type,
        {
          isAurora: (row?.original?.resource as RdsSnapshot | RdsSnapshotSource)
            ?.is_aurora,
        },
      )}
    />
  );
};
