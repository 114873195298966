import { createAsyncThunk } from "@reduxjs/toolkit";
import { cloudChiprApi } from "../../../../services/cloudchipr.api";

export const getCurrentOrganisationSettingsThunk = createAsyncThunk(
  "common/getCurrentOrganisationSettings",
  async (_, { dispatch }) => {
    const { getUsersMeOrganisationsCurrentSettings } = cloudChiprApi.endpoints;
    return await dispatch(getUsersMeOrganisationsCurrentSettings.initiate());
  },
);
