import { FC, ReactNode } from "react";
import { Box, Table, TableBody, TableRow, Typography } from "@mui/material";
import TableCell from "@mui/material/TableCell";
import { EditableCell } from "./EditableCell";

interface BudgetPreviewTableProps {
  title: string;
  data: {
    key: string;
    value: string | ReactNode;
    onValueEdit?(newValue: string): void;
  }[];
}

export const BudgetPreviewTable: FC<BudgetPreviewTableProps> = ({
  title,
  data,
}) => {
  return (
    <Box mb={3}>
      <Typography
        variant="body1"
        bgcolor="grey.50"
        py={1}
        px={2}
        fontWeight="bold"
      >
        {title}
      </Typography>
      <Table size="small" sx={{ my: 1 }}>
        <TableBody>
          {data.map(({ key, value, onValueEdit }) => {
            return (
              <TableRow key={key}>
                <TableCell component="th" sx={{ py: 1, width: 210 }}>
                  <Typography variant="body2" fontWeight="medium">
                    {key}
                  </Typography>
                </TableCell>

                <TableCell sx={{ py: 1 }}>
                  {onValueEdit && typeof value === "string" ? (
                    <EditableCell onEdit={onValueEdit} value={value} />
                  ) : (
                    value
                  )}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </Box>
  );
};
