import { createAsyncThunk } from "@reduxjs/toolkit";
import { protectV2Thunk } from "./protectV2Thunk";
import { ResourceType } from "../../../../services/cloudchipr.api";
import { RootState } from "../../../store";
import { liveUsageMgmtSelectedRowsIdsByResourceTypeSelector } from "../../../live-usage-mgmt/selectors/resource-type-data/liveUsageMgmtSelectedRowsIdsByResourceTypeSelector";
import { resetLiveUsageMgmtSelectedResources } from "../../../live-usage-mgmt/liveUsageMgmtSlice";
import { getLiveUsageMgmtResourceTypeDataThunk } from "../../../live-usage-mgmt/thunks/resources/getLiveUsageMgmtResourceTypeDataThunk";
import { liveUsageMgmtSelectedResourcesAccountIdsSelector } from "../../../live-usage-mgmt/selectors/resource-type-data/liveUsageMgmtSelectedResourcesAccountIdsSelector";

interface CreateV2ProtectThunkArgs {
  resourceType: ResourceType;
  actionType: "protect" | "unprotect";
  callBack?(): void;
}

export const createV2ProtectThunk = createAsyncThunk(
  "cleanV2/createV2Protect",
  async (
    { resourceType, actionType, callBack }: CreateV2ProtectThunkArgs,
    { dispatch, getState },
  ) => {
    const state = getState() as RootState;
    const accountIds = liveUsageMgmtSelectedResourcesAccountIdsSelector(
      state,
      resourceType,
    );

    const selectedResources =
      liveUsageMgmtSelectedRowsIdsByResourceTypeSelector(state, resourceType) ??
      [];

    const promises = accountIds?.map((accountId) => {
      return dispatch(
        protectV2Thunk({
          resources: selectedResources ? Object.keys(selectedResources) : [],
          actionType,
          resourceType: resourceType,
          accountId,
        }),
      );
    });

    await Promise.all(promises ?? []);

    dispatch(getLiveUsageMgmtResourceTypeDataThunk(resourceType));
    if (callBack) {
      callBack();
    }
    dispatch(resetLiveUsageMgmtSelectedResources(resourceType));
  },
);
