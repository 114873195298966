import { Stack, Typography } from "@mui/material";
import { uncoveredUsageColumn } from "./common/uncoveredUsageColumn";
import { getCoveredUsageColumn } from "./common/coveredUsageColumn";
import { netSavingsColumn } from "./common/netSavingsColumn";
import { totalCoverageColumn } from "./common/totalCoverageColumn";
import { ReservationsCoverageDataWithId } from "../../../../../../../common/utils/types";
import { ColumnSetupType } from "../../../../../../../../../../storybook/data-grid/utils/types/types";
import { CommitmentsProgressBar } from "../../../../../../../common/components/CommitmentsProgressBar";

export const reservationCoverageGroupedByAccountColumns: ColumnSetupType<ReservationsCoverageDataWithId>[] =
  [
    {
      accessorKey: "account",
      header: "Account",
      cell: ({ row }) => {
        return (
          <Stack>
            <Typography variant="body2">
              {row.original.account?.name}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {row.original.account?.provider_account_id}
            </Typography>
          </Stack>
        );
      },
    },

    getCoveredUsageColumn("account"),

    uncoveredUsageColumn,
    netSavingsColumn,
    {
      accessorKey: "coverage",
      header: "Coverage",
      cell: ({ row }) => (
        <CommitmentsProgressBar
          overrideColor="info.light"
          value={row.original.coverage}
        />
      ),
    },
    totalCoverageColumn,
  ];
