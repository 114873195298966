import {
  CommitmentsUtilizationGrouping,
  CommitmentUtilizationType,
} from "../../../../../../../../../../services/cloudchipr.api";

export const commitmentTypeTitleByType = new Map<
  CommitmentUtilizationType,
  string
>([
  ["savings_plan", "Savings Plan"],
  ["reservation", "Reservation"],
]);

export const commitmentGroupByTitleByGrouping = new Map<
  CommitmentsUtilizationGrouping,
  string
>([
  ["commitment_type", "Type"],
  ["id", "ID"],
  ["ungrouped", "Ungrouped"],
]);
