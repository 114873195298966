import { billingStatusProcessSelector } from "./billingStatusProcessSelector";
import { RootState } from "../../../store";
import { ProviderType } from "../../../../services/cloudchipr.api";

export const billingStatusProcessByProviderSelector = (
  state: RootState,
  provider: ProviderType,
): boolean | undefined => {
  const data = billingStatusProcessSelector(state);
  return data[provider];
};
