import React, { FC, Fragment } from "react";
import { Button, Divider, Typography } from "@mui/material";
import { ProfileSelfDeleteConfirmation } from "./ProfileSelfDeleteConfirmation";
import { useAppAbility } from "../../../../../../services/permissions";
import { useDialog } from "../../../../../../utils/hooks/useDialog.hook";

export const ProfileSelfDelete: FC = () => {
  const { cannot } = useAppAbility();
  const { open, openDialog, closeDialog } = useDialog();

  if (cannot("self-delete", "user")) {
    return null;
  }

  return (
    <Fragment>
      <Divider sx={{ my: 6 }} />
      <Typography variant="h6" gutterBottom mt={6}>
        Delete Your Account
      </Typography>
      <Typography variant="body2" color="text.secondary" mb={5}>
        This will permanently delete your Cloudchipr account <br />
        After you delete your account you won't be able to use Cloudchipr until
        the Organization owner invites you again.
      </Typography>
      <Button color="error" variant="outlined" onClick={openDialog}>
        Delete your account
      </Button>
      <ProfileSelfDeleteConfirmation open={open} onClose={closeDialog} />
    </Fragment>
  );
};
