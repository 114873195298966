import { DropdownSelectOption } from "../types/types";

export const spreadAllOptions = (options: DropdownSelectOption[]) => {
  const values: DropdownSelectOption[] = [];
  const rec = (option: DropdownSelectOption) => {
    if (option.nestedOptions) {
      option.nestedOptions.forEach(rec);
    } else {
      values.push(option);
    }
  };

  options.forEach((option) => {
    rec(option);
  });

  return values;
};
