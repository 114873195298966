import { FC } from "react";
import { CategoryNameField } from "../common/CategoryNameField";
import { useAppSelector } from "../../../../../../../store/hooks";
import { editingCategoryNameSelector } from "../../../../../../../store/dimensions/selectors/slice-data/editing-category/editingCategoryNameSelector";
import { editingCategoryIdSelector } from "../../../../../../../store/dimensions/selectors/slice-data/editing-category/editingCategoryIdSelector";

export const CategoryName: FC = () => {
  const categoryId = useAppSelector(editingCategoryIdSelector);
  const categoryName = useAppSelector(editingCategoryNameSelector);

  return <CategoryNameField name={categoryName ?? ""} id={categoryId ?? ""} />;
};
