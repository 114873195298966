import {
  SavingsPlanCoverageGrouping,
  CommitmentFilterType,
} from "../../../../../../services/cloudchipr.api";

type GroupingType = {
  value: SavingsPlanCoverageGrouping;
  riLabel: string;
  spLabel: string;
};

const resourceType: GroupingType = {
  value: "resource_type",
  spLabel: "Covered Resource Types",
  riLabel: "Covered Resources",
};
const account: GroupingType = {
  value: "account",
  spLabel: "Accounts",
  riLabel: "Accounts",
};
const commitment: GroupingType = {
  value: "commitment",
  spLabel: "Savings Plans",
  riLabel: "Reservations",
};

export const drawerDataGridPossibleGroupings: Record<
  CommitmentFilterType,
  GroupingType[]
> = {
  commitment: [resourceType, account],
  account: [resourceType, commitment],
  resource_type: [commitment, account],
  savings_plan: [],
};
