import { FC } from "react";

export const CustomizedYAxisTick: FC<any> = (props) => {
  const { x, y, payload, orientation } = props;
  const isLeft = orientation === "left";

  return (
    <g transform={`translate(${isLeft ? x : x + 12},${y + 5})`}>
      <text textAnchor="end" fontSize={isLeft ? 8 : 12}>
        {payload.value}
      </text>
    </g>
  );
};
