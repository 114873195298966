import { createDraftSafeSelector } from "@reduxjs/toolkit";
import { rightsizingRecommendationsDismissedDataSelector } from "./rightsizingRecommendationsDismissedDataSelector";
import { RightSizingRecommendation } from "../../../../../services/cloudchipr.api";
import { recommendationAppliedProvidersSelector } from "../../filters/recommendationAppliedProvidersSelector";
import { recommendationAppliedTypesSelector } from "../../filters/recommendationAppliedTypesSelector";
import { recommendationAppliedAccountsSelector } from "../../filters/recommendationAppliedAccountsSelector";
import { rightsizingRecommendationsDataByFiltersFilterCombiner } from "../../../combiners/rightsizing/rightsizingRecommendationsDataByFiltersFilterCombiner";
import { recommendationAppliedTagsSetSelector } from "../../filters/recommendationAppliedTagsSetSelector";

export const rightsizingRecommendationsDismissedFilteredDataSelector =
  createDraftSafeSelector(
    [
      rightsizingRecommendationsDismissedDataSelector,
      recommendationAppliedProvidersSelector,
      recommendationAppliedTypesSelector,
      recommendationAppliedAccountsSelector,
      recommendationAppliedTagsSetSelector,
    ],
    (data, providers, types, accounts, tags): RightSizingRecommendation[] => {
      if (!data) {
        return [];
      }

      return rightsizingRecommendationsDataByFiltersFilterCombiner(
        data,
        providers,
        types,
        accounts,
        tags,
      );
    },
  );
