import { FC, memo } from "react";
import { Button, DialogActions, Grid, Stack } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useAppSelector } from "../../../../../../../../../../../../store/hooks";
import { resourceExplorerGcpBillingExportDataErrorSelector } from "../../../../../../../../../../../../store/resource-explorer/selectors/billing/gcp-billing-data/resourceExplorerGcpBillingExportDataErrorSelector";
import { usePostUsersMeOrganisationsCurrentGcpBillingExportDataMutation } from "../../../../../../../../../../../../services/cloudchipr.api";
import { createResourceExplorerGcpBillingExportDataFixedCacheKey } from "../../../../../../../utils/constants/fixedCacheKeys";

interface ResourceExplorerBillingExportDialogActionsProps {
  onPrevStep(): void;
  onNextStep(): void;
  onCloseDialog(): void;
}

export const ResourceExplorerBillingExportDialogAccessStepActions: FC<ResourceExplorerBillingExportDialogActionsProps> =
  memo(({ onNextStep, onPrevStep, onCloseDialog }) => {
    const error = useAppSelector(
      resourceExplorerGcpBillingExportDataErrorSelector,
    );

    const [, { isLoading }] =
      usePostUsersMeOrganisationsCurrentGcpBillingExportDataMutation({
        fixedCacheKey: createResourceExplorerGcpBillingExportDataFixedCacheKey,
      });

    return (
      <DialogActions sx={{ px: 4, my: 1 }}>
        <Grid container justifyContent="space-between">
          <Grid item>
            <Button color="tertiary" onClick={onCloseDialog} variant="text">
              Cancel
            </Button>
          </Grid>
          <Grid item>
            <Stack direction="row" spacing={2}>
              <Button color="primary" onClick={onPrevStep} variant="outlined">
                Previous
              </Button>
              <LoadingButton
                variant="contained"
                color="primary"
                disabled={error || isLoading}
                onClick={onNextStep}
              >
                Next
              </LoadingButton>
            </Stack>
          </Grid>
        </Grid>
      </DialogActions>
    );
  });
