import { FC } from "react";
import { Box, Stack } from "@mui/material";
import { CopyIconButton } from "../CopyIconButton";
import { useHover } from "../../../utils/hooks/useHover.hook";

interface CopyCellProps {
  data: string;
  noWrap?: boolean;
}

export const CopyCell: FC<CopyCellProps> = ({ data, noWrap }) => {
  const whiteSpace = noWrap ? "nowrap" : "normal";
  const { ref, hovered } = useHover();

  return (
    <Box ref={ref} sx={{ whiteSpace }}>
      <Stack direction="row" alignItems="center" width="max-content">
        {data}
        {data && <CopyIconButton data={data} visible={hovered} />}
      </Stack>
    </Box>
  );
};
