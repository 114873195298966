import { FC, useCallback } from "react";
import { IconButton, Stack, Tooltip } from "@mui/material";
import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";
import { LiveUsageMgmtToolbarRowIntegrationAction } from "./LiveUsageMgmtToolbarRowIntegrationAction";
import { liveUsageRowActionTooltipTitles } from "./utils/constants";
import { ToolbarTaskCreateActionItem } from "./ToolbarTaskCreateActionItem";
import { ResourceType } from "../../../../../../../../../../../services/cloudchipr.api";
import {
  IntegrationType,
  IntegrationTypeWithoutWebhook,
} from "../../../../../../../../../integrations/components/integrations-create/components/common/integration-type/IntegrationTypeCard";
import { initiateCurrentIntegration } from "../../../../../../../../../../../store/integrations/integrationsSlice";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../../../../store/hooks";
import { liveUsageMgmtResourceCleanActionsButtonDisabledReasonSelector } from "../../../../../../../../../../../store/live-usage-mgmt/selectors/resource-type-data/liveUsageMgmtResourceCleanActionsButtonDisabledReasonSelector";
import { filterOutResourcesThunk } from "../../../../../../../../../../../store/live-usage-mgmt/thunks/filterOutResourcesThunk";
import { integrationOptions } from "../../utils/constants/constants";

interface LiveUsageMgmtRowActionsProps {
  resourceType: ResourceType;
}

export const LiveUsageMgmtToolbarRowActions: FC<
  LiveUsageMgmtRowActionsProps
> = ({ resourceType }) => {
  const dispatch = useAppDispatch();
  const resourceCleanActionsButtonDisabledReason = useAppSelector((state) =>
    liveUsageMgmtResourceCleanActionsButtonDisabledReasonSelector(
      state,
      resourceType,
    ),
  );
  const integrationIconClickHandler = useCallback(
    (type: IntegrationType) => {
      dispatch(initiateCurrentIntegration({ type, resourceType }));
    },
    [dispatch, resourceType],
  );

  const filterOutClickHandler = useCallback(() => {
    dispatch(filterOutResourcesThunk(resourceType));
  }, [dispatch, resourceType]);

  const tooltipType = resourceCleanActionsButtonDisabledReason
    ? "disabled"
    : "active";

  return (
    <Stack
      bgcolor="grey.100"
      spacing={0.5}
      direction="row"
      p={0.5}
      alignItems="center"
      borderRadius={2}
    >
      <Tooltip
        title={liveUsageRowActionTooltipTitles.filter_out[tooltipType] ?? ""}
        placement="top"
        arrow
      >
        <span>
          <IconButton
            sx={{ p: 0.5 }}
            onClick={filterOutClickHandler}
            disabled={!!resourceCleanActionsButtonDisabledReason}
          >
            <FilterAltOffIcon fontSize="small" />
          </IconButton>
        </span>
      </Tooltip>

      <ToolbarTaskCreateActionItem
        resourceType={resourceType}
        disabled={!!resourceCleanActionsButtonDisabledReason}
      />

      {integrationOptions.map(({ type }) => {
        return (
          <LiveUsageMgmtToolbarRowIntegrationAction
            key={type}
            type={type as IntegrationTypeWithoutWebhook}
            onClick={integrationIconClickHandler}
            disabled={!!resourceCleanActionsButtonDisabledReason}
          />
        );
      })}
    </Stack>
  );
};
