import { CardContent, Stack } from "@mui/material";
import { OffHoursRecommendationsCsvDownloader } from "./OffHoursRecommendationsCSVDownloader";
import { OffHoursRecommendationsAccountFilter } from "../filters/OffHoursRecommendationsAccountFilter";
import { OffHoursRecommendationsProviderFilter } from "../filters/OffHoursRecommendationsProviderFilter";
import { OffHoursRecommendationsTypeFilter } from "../filters/OffHoursRecommendationsTypeFilter";
import { RenderToolbar } from "../../../../../../../storybook/data-grid/utils/types/prop-types";
import { GlobalFilter } from "../../../../../../../storybook/data-grid/toolbar/GlobalFilter";
import { RecommendationGroupType } from "../../utils/types/types";
import { RecommendationDismiss } from "../../../common/dismiss/RecommendationDismiss";
import { RecommendationsTabType } from "../OffHoursRecommendationsContent";
import { RecommendationRestoreButton } from "../../../common/dismiss/RecommendationRestoreButton";
import { ResourceRestoreButton } from "../../../common/dismiss/by-resource/ResourceRestoreButton";
import { OffHoursRecommendationsTagsFilter } from "../filters/OffHoursRecommendationsTagsFilter";

type OffHoursRecommendationsTableToolbarProps = RenderToolbar<{
  group: RecommendationGroupType;
  tab: RecommendationsTabType;
}>;

export const OffHoursRecommendationsTableToolbar: OffHoursRecommendationsTableToolbarProps =
  ({ globalFilter, setGlobalFilter, group, tab }) => {
    return (
      <CardContent sx={{ px: 3 }}>
        <Stack direction="row" justifyContent="space-between">
          <Stack direction="row" spacing={2}>
            <OffHoursRecommendationsProviderFilter />
            <OffHoursRecommendationsAccountFilter />
            <OffHoursRecommendationsTypeFilter group={group} tab={tab} />
            <OffHoursRecommendationsTagsFilter group={group} tab={tab} />
          </Stack>

          <Stack direction="row" spacing={2} alignItems="center">
            <GlobalFilter
              globalFilter={globalFilter}
              setGlobalFilter={setGlobalFilter}
              size="xsmall"
            />

            {tab !== "dismissed_resources" && (
              <OffHoursRecommendationsCsvDownloader tab={tab} group={group} />
            )}

            {tab === "active" && (
              <RecommendationDismiss multiple recommendationType="off-hours" />
            )}

            {tab === "dismissed" && <RecommendationRestoreButton multiple />}

            {tab === "dismissed_resources" && (
              <ResourceRestoreButton multiple />
            )}
          </Stack>
        </Stack>
      </CardContent>
    );
  };
