import { costBreakdownWidgetSetupDataSelector } from "./costBreakdownWidgetSetupDataSelector";
import { RootState } from "../../../../../store";
import { formatResourceExplorerChartData } from "../../../../../resource-explorer/utils/helpers/formatResourceExplorerChartData";
import { costBreakdownSetupPropertyByKeySelector } from "../../../setups/cost-breakdown/costBreakdownSetupPropertyByKeySelector";
import { convertCostToPercent } from "../../../../../resource-explorer/selectors/current-resource-explorer/chart/utils/helpers/convertCostToPercent";
import {
  FormatedData,
  generateCombinatedChartData,
} from "../../../../../resource-explorer/selectors/current-resource-explorer/chart/utils/helpers/generateCombinatedChartData";

export const costBreakdownWidgetSetupCombinedChartDataSelector = (
  state: RootState,
): FormatedData | null => {
  const data = costBreakdownWidgetSetupDataSelector(state)?.data;
  const frequency = costBreakdownSetupPropertyByKeySelector("frequency")(state);

  if (!data) {
    return null;
  }

  const formatedData = formatResourceExplorerChartData(data, frequency);
  const percentData = convertCostToPercent(formatedData);

  return generateCombinatedChartData(formatedData, percentData);
};
