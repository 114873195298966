export const sortStringArrayByAlphanumeric = (arr: string[]): string[] => {
  return [...arr].sort((a, b) => {
    if (a < b) {
      return -1;
    }
    if (b < a) {
      return 1;
    }
    return 0;
  });
};
