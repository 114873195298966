import { FC } from "react";
import moment from "moment";
import { Stack, Typography } from "@mui/material";
import { ChartsTooltipContentStyles } from "./ChartsTooltipContent";

interface ChartsTooltipZoomProps {
  x1?: number | string;
  x2?: number | string;
}

export const ChartsTooltipZoom: FC<ChartsTooltipZoomProps> = ({ x1, x2 }) => {
  if (x1 === undefined || x2 === undefined) {
    return null;
  }

  const content = moment(x1) > moment(x2) ? `${x2} - ${x1}` : `${x1} - ${x2}`;

  return (
    <Stack sx={ChartsTooltipContentStyles}>
      <Typography variant="caption">{content}</Typography>
    </Stack>
  );
};
