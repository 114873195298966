import { categoriesDataSelector } from "./categoriesDataSelector";
import { RootState } from "../../store";
import { AccountCategoryWithCost } from "../../../services/cloudchipr.api";

export const categoriesGroupedByGroupSelector = (
  state: RootState,
): Record<string, AccountCategoryWithCost[]> => {
  const data = categoriesDataSelector(state);

  return (
    data?.reduce(
      (acc, category) => {
        if (acc[category.name]) {
          acc[category.name].push(category);
        } else {
          acc[category.name] = [category];
        }

        return acc;
      },
      {} as Record<string, AccountCategoryWithCost[]>,
    ) || {}
  );
};
