import { FC, memo, useCallback } from "react";
import { FormHelperText, Stack, TextField, Typography } from "@mui/material";

interface IntervalProps {
  type: "Days" | "Hours";
  value: number;
  error?: string | boolean;
  setFieldValue(key: string, value: number): void;
}

export const Interval: FC<IntervalProps> = memo(
  ({ type, setFieldValue, value, error }) => {
    const maxValue = type === "Hours" ? 23 : 31;

    const changeHandler = useCallback(
      (event: any) => {
        const val = Number(event.target.value);

        if (val > maxValue || val < 0) return;

        setFieldValue("interval", val);
      },
      [setFieldValue, maxValue],
    );

    return (
      <Stack direction="row" spacing={3} alignItems="baseline">
        <Typography variant="subtitle2">Every</Typography>
        <Stack>
          <TextField
            value={value || ""}
            type="number"
            label={type}
            error={!!error}
            size="small"
            onChange={changeHandler}
            sx={{ width: "152px" }}
          />
          {error && <FormHelperText error>{error}</FormHelperText>}
        </Stack>

        <Typography variant="subtitle2">{type}</Typography>
      </Stack>
    );
  },
);
