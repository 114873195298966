import SmartTagFeatureGif from "../../../../assets/feature-gifs/smart-tag.gif";
import IntegrationFeatureVideo from "../../../../assets/feature-videos/integration.mp4";
import OffHoursOptimizedFlowFeatureVideo from "../../../../assets/feature-videos/off-hours-optimized-flow.mp4";
import ResourceSpecificActionsFeatureVideo from "../../../../assets/feature-videos/resource-specific-actions-in-scheduler.mp4";
import CreateImagesBeforeTerminationFeatureVideo from "../../../../assets/feature-videos/create-image-before-termination.mp4";
import HourlyMetricsFeatureVideo from "../../../../assets/feature-videos/hourly-metrics.mp4";
import DefaultFiltersUpdate from "../../../../assets/feature-videos/default-filters-update.mp4";
import FilterSetVideo from "../../../../assets/feature-videos/filter_set.mp4";
import CreatedByVideo from "../../../../assets/feature-videos/created_by.mp4";
import GridSelectionVideo from "../../../../assets/feature-videos/grid_selection.mp4";
import S3BucketsVideo from "../../../../assets/feature-videos/s3_buckets.mp4";
import RightsizingRecommendationsVideo from "../../../../assets/feature-videos/rightsizing_recommendation.mp4";
import UnusedSinceVideo from "../../../../assets/feature-videos/unused_since.mp4";
import { FeatureUpdateProps } from "../../components/FeatureCardContent";

export const features: Record<string, FeatureUpdateProps> = {
  feature_smart_tag: {
    title: "Smart Tags from Cloudchipr",
    label: "New feature",
    description:
      "Try Cloudchipr Smart Tags and have better organized resources. Now you will find what you are looking for easier than before.",
    media: SmartTagFeatureGif,
  },
  feature_off_hours: {
    title: "Off-hours",
    label: "New feature",
    description:
      "Off Hours is a new feature allowing users to schedule stop and start periods for selected resources and only pay for hours the resources are actually being used.",
  },
  feature_elasticache: {
    title: "We now support AWS ElastiCache service!",
    label: "New feature",
    description:
      "Both Redis and Memcached resources come with enough metrics to be able to identify if they are being wasted.",
  },
  feature_account_linking: {
    title: "Account Linking is now available!",
    label: "New feature",
    description:
      "If you are using email/password authentication feel free to link it with your GitHub or GMail accounts!",
  },
  feature_mfa: {
    title: "MFA is now available and is 2 clicks away!",
    label: "New feature",
    description: "We recommend to go to settings and quickly enable it!",
  },
  feature_stop: {
    title: "Stop functionality is added to Cloudchipr now!",
    label: "New feature",
    description:
      "Relational Databases and Compute Instances can now be stopped as part of clean actions.",
  },
  feature_snapshot: {
    title: "Snapshotting before delete!",
    label: "New feature",
    description:
      "Cloudchipr supports Snapshotting before delete for applicable resources, such as Volumes and Relational Databases.",
  },
  feature_integration: {
    title: "Integrations!",
    label: "New feature",
    description:
      "Email and Slack integrations are now available! New integrations can be created or selected from automation workflows to get notified on every action.",
    media: IntegrationFeatureVideo,
    mediaType: "video",
  },
  feature_off_hours_optimized_flow: {
    title: "Schedules/Off hours optimized flow!",
    label: "New feature",
    description: "New simplified workflow is available now!",
    media: OffHoursOptimizedFlowFeatureVideo,
    mediaType: "video",
  },
  feature_resource_specific_actions: {
    title: "Resource specific actions in Scheduler!",
    label: "New feature",
    description:
      "Now it is possible to select resource specific more granular actions in scheduler! ",
    media: ResourceSpecificActionsFeatureVideo,
    mediaType: "video",
  },
  feature_create_images_before_termination: {
    title: "Create images before termination for compute resources!",
    label: "New feature",
    description:
      "For AWS EC2 Instances and GCP VMs users can now choose to create images before terminating the resources!",
    media: CreateImagesBeforeTerminationFeatureVideo,
    mediaType: "video",
  },
  feature_hourly_metrics: {
    title: "Hourly Metrics!",
    label: "New feature",
    description:
      "For more granular filtering is now available.Introducing hourly metrics for all supported resources. Hourly metrics can go back for up to 1 week",
    media: HourlyMetricsFeatureVideo,
    mediaType: "video",
  },
  feature_aws_accounts_from_different_organizations: {
    title: "AWS accounts from different organizations!",
    label: "New feature",
    description:
      "Enterprise users can attach AWS management and standalone accounts from different organizations!",
  },
  feature_new_default_filters: {
    title: "New Default Filters",
    description:
      "We have changed our default filters for compute resources, they are more relaxed now!",
    media: DefaultFiltersUpdate,
    mediaType: "video",
  },
  feature_rightsizing_recommendations: {
    title: "Rightsizing recommendations",
    label: "New feature",
    description:
      "Cloudchipr now provides rightsizing recommendations collected from cloud providers, along with additional insights on VM performance.",
    media: RightsizingRecommendationsVideo,
    mediaType: "video",
  },
  feature_s3_buckets: {
    title: "S3 Buckets",
    label: "New feature",
    description:
      "Cloudchipr has implemented the collection of S3 buckets. You can now utilize filters and explore the S3 buckets in your cloud environment.",
    media: S3BucketsVideo,
    mediaType: "video",
  },
  feature_grid_selection: {
    title: "Resource grid selection",
    label: "New feature",
    description:
      "Easily select your preferred resources and take actions. This process enables efficient management of your selected resources.",
    media: GridSelectionVideo,
    mediaType: "video",
  },
  feature_filter_sets: {
    title: "Filter Sets",
    label: "New feature",
    description:
      "Create custom filters for resources, save them, and apply them to preferred accounts.",
    media: FilterSetVideo,
    mediaType: "video",
  },
  feature_created_by: {
    title: "Created By",
    label: "New feature",
    description:
      "Cloudchipr now collects information on users or services that initiated the launch of any specific resource in your cloud. This feature is currently supported only for resources launched within the past 90 days.",
    media: CreatedByVideo,
    mediaType: "video",
  },
  feature_unused_since: {
    title: "Unused Since",
    label: "New feature",
    description:
      "Now you can check the duration for which a given resource has remained in an unused state.",
    media: UnusedSinceVideo,
    mediaType: "video",
  },
};
