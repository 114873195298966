import { FC, Fragment } from "react";
import { Stack, Typography } from "@mui/material";
import { useAppSelector } from "../../../../../../../../../../../store/hooks";
import { resourceExplorerGroupingSelector } from "../../../../../../../../../../../store/resource-explorer/selectors/current-resource-explorer/data/resourceExplorerGroupingSelector";
import { resourceExplorerDataGridDataCountSelector } from "../../../../../../../../../../../store/resource-explorer/selectors/current-resource-explorer/data-grid/resourceExplorerDataGridDataCountSelector";
import { getResourcesExplorerGroupingLabel } from "../../../../../../utils/helpers/getResourcesExplorerGroupingLabel";
import { resourceExplorerGranularDataCountSelector } from "../../../../../../../../../../../store/resource-explorer/selectors/current-resource-explorer/data-grid/granular-costs-view/resourceExplorerGranularDataCountSelector";
import { resourceExplorerDataGridViewSelector } from "../../../../../../../../../../../store/resource-explorer/selectors/current-resource-explorer/resourceExplorerDataGridViewSelector";

export const LabelColumnHeaderCell: FC = () => {
  const grouping = useAppSelector(resourceExplorerGroupingSelector);
  const dataGridView = useAppSelector(resourceExplorerDataGridViewSelector);
  const totalDataCount = useAppSelector(
    resourceExplorerDataGridDataCountSelector,
  );
  const granularDataCount = useAppSelector(
    resourceExplorerGranularDataCountSelector,
  );
  const dataCount =
    dataGridView === "total" ? totalDataCount : granularDataCount;

  if (!grouping) {
    return <Fragment>Total</Fragment>;
  }

  if (grouping === "none") {
    return null;
  }

  return (
    <Stack direction="row" spacing={2} alignItems="center">
      <div>
        {getResourcesExplorerGroupingLabel(grouping, true)}

        <Typography fontSize={10} color="text.disabled" fontWeight={500}>
          {dataCount}
        </Typography>
      </div>
    </Stack>
  );
};
