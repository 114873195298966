import { ColumnSetupType } from "../../../../../../../../../../../../../../storybook/data-grid/utils/types/types";
import { Mig } from "../../../../../../../../../../../../../../services/cloudchipr.api";

export const migColumns: ColumnSetupType<Mig>[] = [
  {
    accessorKey: "name",
    header: "Name",
    type: "identifierWithRecommendationLink",
    meta: { sticky: "left", hideFromSettings: true },
  },
  {
    accessorKey: "price_per_month",
    header: "Monthly Price",
    type: "money",
    headerTooltip:
      "Monthly prices are calculated based on the on-demand list price of each resource.",
  },
  {
    accessorKey: "account",
    header: "Account",
    type: "account",
    size: 210,
  },
  {
    accessorKey: "all_time_spent",
    header: "Total Spend",
    type: "money",
  },
  {
    accessorKey: "instances_count",
    header: "Instances",
  },
  { accessorKey: "recommended_size", header: "Recommended size", size: 200 },
  { accessorKey: "max_num_replicas", header: "Max" },
  { accessorKey: "min_num_replicas", header: "Min" },
  { accessorKey: "region", header: "Region", type: "withCopy" },
  {
    accessorKey: "created_at",
    header: "Launch time",
    type: "date",
  },
  {
    accessorKey: "issues",
    header: "Ticket Status",
    type: "jiraTicketStatus",
    enableSorting: false,
  },
];
