import { FC, useCallback } from "react";
import { Button, Stack } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useNavigate } from "react-router-dom";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../store/hooks";
import { updateUserRoleThunk } from "../../../../../../../store/user/thunks/roles/updateUserRoleThunk";
import { userRoleCreationLoadingSelector } from "../../../../../../../store/user/selectors/roles/loading/userRoleEditingLoadingSelector";
import { userRoleDataValidationSelector } from "../../../../../../../store/user/selectors/roles/userRoleDataValidationSelector";
import { userRoleDataPermissionsGroupsSelector } from "../../../../../../../store/user/selectors/roles/userRoleDataPermissionsGroupsSelector";
import { userRoleIsDefaultByIdSelector } from "../../../../../../../store/user/selectors/roles/role-by-id/userRoleIsDefaultByIdSelector";

export const UserRoleEditFooter: FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const loading = useAppSelector(userRoleCreationLoadingSelector);
  const validation = useAppSelector(userRoleDataValidationSelector);
  const groups = useAppSelector(userRoleDataPermissionsGroupsSelector);

  const isDefault = useAppSelector(userRoleIsDefaultByIdSelector);
  const cancelClickHandler = useCallback(() => {
    navigate("/settings/roles");
  }, [navigate]);

  const updateClickHandler = useCallback(async () => {
    const response = await dispatch(updateUserRoleThunk());
    if (response) {
      cancelClickHandler();
    }
  }, [dispatch, cancelClickHandler]);

  if (!groups?.length) {
    return null;
  }

  return (
    <Stack direction="row" justifyContent="space-between">
      <Button color="tertiary" onClick={cancelClickHandler}>
        cancel
      </Button>

      <LoadingButton
        onClick={updateClickHandler}
        variant="contained"
        loading={loading}
        disabled={validation || isDefault}
      >
        Update
      </LoadingButton>
    </Stack>
  );
};
