import { FC, useCallback } from "react";
import { Button } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { UtilizationAlertsType } from "../../../../../utils/types/types";
import { useAppDispatch } from "../../../../../../../../store/hooks";
import { addUtilizationAlertThresholdByTypeThunk } from "../../../../../../../../store/alerts/thunks/utilization-alerts/addUtilizationAlertThresholdByTypeThunk";

interface AlertsUtilizationAddThresholdButtonProps {
  utilizationAlertType: UtilizationAlertsType;
}

export const AlertsUtilizationAddThresholdButton: FC<
  AlertsUtilizationAddThresholdButtonProps
> = ({ utilizationAlertType }) => {
  const dispatch = useAppDispatch();

  const handleClick = useCallback(() => {
    dispatch(addUtilizationAlertThresholdByTypeThunk(utilizationAlertType));
  }, [utilizationAlertType, dispatch]);

  return (
    <Button
      variant="text"
      onClick={handleClick}
      startIcon={<AddIcon fontSize="small" />}
      sx={{ width: "fit-content", textTransform: "none" }}
    >
      Add Threshold
    </Button>
  );
};
