import { FC } from "react";
import { Box, Stack, Typography } from "@mui/material";
import { money } from "../../../../../../../../../../../utils/numeral/money";
import { formatDate } from "../../../../../../../../../../../utils/helpers/date-time/datetime-format";
import { TooltipText } from "../../../../../../../../../../common/TooltipText";
import { ResourceExplorerCostsDateDetails } from "../../../../../../../../../../../services/cloudchipr.api";

interface PrevPeriodCostColumnHeaderCellProps {
  sum: number | null;
  dateDetails?: ResourceExplorerCostsDateDetails;
}
export const PrevPeriodCostColumnHeaderCell: FC<
  PrevPeriodCostColumnHeaderCellProps
> = ({ sum, dateDetails }) => {
  const fromDate = formatDate(dateDetails?.from_date ?? "", { type: "date" });
  const toDate = formatDate(dateDetails?.to_date ?? "", { type: "date" });

  const titleComponent = (
    <Box>
      Previous Period Cost <br /> from{" "}
      <Typography fontWeight="medium" fontSize="inherit" component="span">
        {fromDate}
      </Typography>{" "}
      to{" "}
      <Typography fontWeight="medium" fontSize="inherit" component="span">
        {toDate}
      </Typography>
    </Box>
  );

  return (
    <Stack alignItems="end" flexGrow={1}>
      <TooltipText
        fontWeight="medium"
        tooltipPlacement="top"
        tooltipTitle={fromDate && toDate ? titleComponent : ""}
      >
        <div>Previous Period Cost</div>
      </TooltipText>

      <Typography
        mt={0.5}
        fontSize={10}
        color="text.disabled"
        fontWeight={500}
        align="right"
      >
        {sum === null ? "N/A" : money(sum)}
      </Typography>
    </Stack>
  );
};
