import { FilteredResourcesType } from "../../../../../../../../../../../store/live-usage-mgmt/utils/types/types";

export const addInstancesCount = (
  data: Record<string, string | number>,
  resource: FilteredResourcesType | null,
) => {
  if (resource) {
    data["Instances based on filters"] =
      `${resource.resources.length} out of ${resource.total_resources}`;
  }

  return data;
};
