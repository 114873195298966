import { AlertsData } from "../types/types";

export const alertsInitialData: AlertsData = {
  id: "",
  name: "",
  notifications: null,
  threshold: null,
  emails: null,
  timeInterval: "1",
  timeIntervalUnit: "days",
  grouping: "service",
  basedOn: "commitments_expiration",
};
