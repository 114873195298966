import { Avatar, ListItemText, Stack } from "@mui/material";
import { JiraSelectOptionsType } from "../../../../utils/types/types";
import {
  isOptionJiraOption,
  isOptionJiraString,
  isOptionJiraUser,
} from "../../../../utils/helpers/typeCheckers";

export const selectRenderValue = (
  selected: string | string[],
  options: JiraSelectOptionsType,
) => {
  if (!options) {
    return "";
  }

  if (isOptionJiraString(options[0]) && typeof selected === "string") {
    return selected;
  }

  if (Array.isArray(selected)) {
    const selectedItems = selected.map((id) => {
      const selectedItem = getSelectedItem(options, id);

      if (isOptionJiraOption(selectedItem)) {
        return selectedItem.name || selectedItem.value;
      }
      if (isOptionJiraUser(selectedItem)) {
        return selectedItem.name || selectedItem.email;
      }

      return id;
    });

    return selectedItems.join(", ");
  }

  const selectedItem = getSelectedItem(options, selected);

  if (isOptionJiraUser(selectedItem)) {
    return (
      <Stack direction="row" alignItems="center" p={0}>
        {selectedItem.avatar && (
          <Avatar
            sx={{ width: 24, height: 24, mr: 2 }}
            alt="avatar"
            src={selectedItem?.avatar}
          />
        )}

        <ListItemText
          primary={selectedItem?.name || selectedItem.email}
          sx={{ m: 0 }}
        />
      </Stack>
    );
  }

  if (isOptionJiraOption(selectedItem)) {
    return (
      <Stack direction="row" alignItems="center" p={0}>
        <ListItemText
          sx={{ m: 0 }}
          primary={selectedItem.name || selectedItem.value}
        />
      </Stack>
    );
  }

  return `${selected}`;
};

const getSelectedItem = (options: JiraSelectOptionsType, id: string) => {
  return options?.find((option) => {
    return typeof option !== "string" && "id" in option && option?.id === id;
  });
};
