import { FC, memo } from "react";
import moment from "moment";
import { TooltipProps } from "@mui/material";
import {
  formatDate,
  dateFromUtcByTimeZone,
} from "../../../utils/helpers/date-time/datetime-format";
import { TooltipText } from "../../../components/common/TooltipText";

interface DataGridDateTimeCellProps {
  data: string;
  title?: TooltipProps["title"];
}

export const DateTimeCell: FC<DataGridDateTimeCellProps> = memo(
  ({ data, title }) => {
    if (!data) {
      return null;
    }
    const formattedData = moment(dateFromUtcByTimeZone(data));

    const value = moment(formattedData).fromNow();

    const titleDate = `${formatDate(formattedData, {
      type: "dateTime",
    })}`;

    return <TooltipText tooltipTitle={title || titleDate}>{value}</TooltipText>;
  },
);
