import { FC, forwardRef } from "react";
import { Box, Card } from "@mui/material";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import { widgetSetupContentChartHeight } from "../utils/constants/stylings";
import { draggableHandleClassnames } from "../utils/constants/reactGridLayout";

export const WidgetWrapper: FC<any> = forwardRef(
  ({ children, resizeHandle, widgetId, ...props }, ref) => {
    const dragging = props.className.includes("dragging");

    return (
      <Card
        {...props}
        position="relative"
        variant="outlined"
        id={widgetId}
        ref={ref}
        sx={{
          display: "flex",
          flexDirection: "column",
          height: widgetSetupContentChartHeight,
        }}
      >
        <Box
          p={0}
          left={0}
          top={13}
          position="absolute"
          className={draggableHandleClassnames.target}
          sx={{ cursor: dragging ? "grabbing" : "grab", zIndex: 3 }}
        >
          <DragIndicatorIcon sx={{ fontSize: 16 }} color="action" />
        </Box>

        {children}

        {resizeHandle}
      </Card>
    );
  },
);
