// todo: @DEPRECATED, should be used from allowed filers endpoint
export const filterRuleLabels: Map<string, string> = new Map([
  ["equal", "=="],
  ["not_equal", "!="],
  ["greater_than", ">"],
  ["greater_than_equal_to", ">="],
  ["less_than", "<"],
  ["less_than_equal_to", "<="],
  ["is_empty", "empty"],
  ["is_absent", "absent"],
  ["is_not_empty", "not empty"],
  ["is_not_absent", "not absent"],
  ["in", "in"],
  ["not_in", "not in"],
  ["exists", "exists"],
  ["does_not_exist", "doesn't exist"],
  ["contains", "contains"],
  ["does_not_contain", "doesn't contain"],
  ["is_true", "true"],
  ["is_false", "false"],
  ["is_invalid", "invalid"],
  ["is_expired", "expired"],
  ["does_never_expire", "never expire"],
  ["is_running", "Running"],
  ["is_stopped", "Stopped"],
]);
