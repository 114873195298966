import { liveUsageMgmtAccountsVisibilitiesSelector } from "./liveUsageMgmtAccountsVisibilitiesSelector";
import { RootState } from "../../../store";
import { ResourceType } from "../../../../services/cloudchipr.api";

export const liveUsageMgmtAccountsVisibilitiesByResourceTypeSelector = (
  state: RootState,
  resourceType: ResourceType,
) => {
  const accountVisibilities = liveUsageMgmtAccountsVisibilitiesSelector(state);
  return accountVisibilities?.[resourceType];
};
