import { FC } from "react";
import { Typography, Stack } from "@mui/material";
import { Cube } from "../../../../../../../../../storybook/charts/multi-type-chart/components/common/Cube";
import { RightsizingRecommendationsChartColors } from "../../../../utils/constants/constants";

interface LegendContentProps {
  currentType: string;
  recommendedType: string;
  metric?: string;
}

export const LegendContent: FC<LegendContentProps> = ({
  metric,
  recommendedType,
  currentType,
}) => {
  return (
    <Stack direction="row" alignItems="center" justifyContent="center">
      <Typography mr={2} variant="caption" component="span">
        <Cube
          radius={0.5}
          color={RightsizingRecommendationsChartColors.CpuArea}
        />
        {currentType} {metric} CPU %{" "}
        <Typography fontWeight="bold" variant="inherit" component="span">
          (Current)
        </Typography>
      </Typography>
      <Typography mr={2} variant="caption" component="span">
        <Cube
          radius={0.5}
          color={RightsizingRecommendationsChartColors.RecommendedSize}
        />
        {recommendedType} {metric} CPU %{" "}
        <Typography fontWeight="bold" variant="inherit" component="span">
          (Recommended)
        </Typography>
      </Typography>
    </Stack>
  );
};
