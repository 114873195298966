import { costBreakdownWidgetAggregationsSelector } from "./costBreakdownWidgetAggregationsSelector";
import { RootState } from "../../../../../store";
import { WidgetCostBreakdownAggregation } from "../../../../../../services/cloudchipr.api";

export const costBreakdownWidgetHasAggregationSelector = (
  state: RootState,
  widgetId: string,
) => {
  const aggregations = costBreakdownWidgetAggregationsSelector(state, widgetId);

  return Object.keys(aggregations ?? {}).some(
    (key) => !!aggregations?.[key as keyof WidgetCostBreakdownAggregation],
  );
};
