import { FC, useCallback } from "react";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { costTypes } from "./utils/constants/constants";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../../../store/hooks";
import { resourceExplorerCostTypeSelector } from "../../../../../../../../../../store/resource-explorer/selectors/current-resource-explorer/resourceExplorerCostTypeSelector";
import { CostType } from "../../../../../../../../../../services/cloudchipr.api";
import { resourceExplorerPathLastItemFilterTreeSelector } from "../../../../../../../../../../store/resource-explorer/selectors/current-resource-explorer/path/resourceExplorerPathLastItemFilterTreeSelector";
import { setResourceExplorerCostType } from "../../../../../../../../../../store/resource-explorer/resourceExplorerSlice";
import { getResourceExplorerDataThunk } from "../../../../../../../../../../store/resource-explorer/thunks/widgets/getResourceExplorerDataThunk";

export const ViewCostTypeSelect: FC = () => {
  const selectedCostType = useAppSelector(resourceExplorerCostTypeSelector);
  const renderValue = useCallback(
    (value: CostType) => `${costTypes[value]} Costs`,
    [],
  );

  const dispatch = useAppDispatch();

  const pathLastItemFilterTree = useAppSelector(
    resourceExplorerPathLastItemFilterTreeSelector,
  );

  const handleChange = useCallback(
    (event: SelectChangeEvent<CostType>) => {
      const costType = event.target.value as CostType;
      if (costType !== selectedCostType) {
        dispatch(setResourceExplorerCostType(costType));
        dispatch(getResourceExplorerDataThunk(pathLastItemFilterTree));
      }
    },
    [dispatch, pathLastItemFilterTree, selectedCostType],
  );

  return (
    <FormControl fullWidth size="xsmall">
      <InputLabel>Cost Type</InputLabel>
      <Select
        fullWidth
        label="Cost type"
        renderValue={renderValue}
        value={selectedCostType}
        onChange={handleChange}
      >
        {Object.entries(costTypes).map(([costType, label]) => (
          <MenuItem dense value={costType} key={costType}>
            {label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
