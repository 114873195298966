import { FC, ReactNode } from "react";
import { Box, Stack, Typography } from "@mui/material";

interface UserRoleEditMetaDataItemProps {
  value: string;
  description: string | ReactNode;
  color?: string;
}
export const UserRoleEditMetaDataItem: FC<UserRoleEditMetaDataItemProps> = ({
  value,
  description,
  color,
}) => {
  return (
    <Stack direction="row" py={1} px={2} bgcolor={color} flex={1}>
      <Box width={200}>
        <Typography variant="body2" color="text.secondary" fontWeight="medium">
          {value}
        </Typography>
      </Box>
      <Typography variant="body2">{description}</Typography>
    </Stack>
  );
};
