import { ResourceExplorerFilterProvider } from "../../../../../../../services/cloudchipr.api";

export const tagLabels = new Map<ResourceExplorerFilterProvider, string>([
  ["aws", "tags"],
  ["gcp", "labels"],
  ["azure", "tags"],
  ["confluent", "tags"],
  ["dimensions", "tags"],
  ["eks", "tags"],
  ["global", "tags"],
]);
