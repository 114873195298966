export const newMetricColumnsByFT = (enableMetricsCharts?: boolean) => {
  const hiddenColumns: string[] = [];

  if (enableMetricsCharts) {
    // todo: remove this columns and their components from: "ec2, rds, s3, eksEc2, asgEc2, vm, sql" after FT removal
    hiddenColumns.push("requests_value", "connections_value", "cpu_value");
  } else {
    hiddenColumns.push("inline_metrics");
  }

  return hiddenColumns;
};
