import { FC, ReactElement, useCallback } from "react";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Radio } from "@mui/material";
import { setProvider } from "../../../../../store/account-setup/accountSetupSlice";
import { useAppDispatch } from "../../../../../store/hooks";
import { ProviderType } from "../../../../../services/cloudchipr.api";

interface ProviderOptionProps {
  provider: ProviderType;
  title: string;
  description: string;
  selected: boolean;
  icon: ReactElement;
}

export const ProviderOption: FC<ProviderOptionProps> = ({
  provider,
  title,
  description,
  icon,
  selected,
}) => {
  const dispatch = useAppDispatch();

  const clickHandler = useCallback(() => {
    dispatch(setProvider(provider));
  }, [dispatch, provider]);

  return (
    <Stack
      mb={3}
      spacing={2}
      border={1}
      minHeight={150}
      direction="row"
      alignItems="center"
      borderRadius="12px"
      onClick={clickHandler}
      sx={{ cursor: "pointer" }}
      bgcolor={selected ? "#3f51b50a" : "none"}
      borderColor={selected ? "#3f51b580" : "#3F51B50A"}
    >
      <Radio checked={selected} />
      <Box flexBasis={60} flexShrink={0}>
        {icon}
      </Box>
      <Box>
        <Typography
          color={selected ? "primary" : "text.secondary"}
          fontWeight="medium"
          variant="body1"
        >
          {title}
        </Typography>
        <Typography
          variant="body2"
          color={selected ? "text.secondary" : "grey.500"}
        >
          {description}
        </Typography>
      </Box>
    </Stack>
  );
};
