import { FC, useCallback } from "react";
import { Stack, Typography } from "@mui/material";
import GridOnIcon from "@mui/icons-material/GridOn";
import TableRowsOutlinedIcon from "@mui/icons-material/TableRowsOutlined";
import { ResourceExplorerDataGridView } from "../../../../../../../../../store/resource-explorer/utils/types/resourceExplorerDataGrid";

const icons = {
  total: TableRowsOutlinedIcon,
  granular: GridOnIcon,
};

interface ResourceExplorerDataGridViewTabProps {
  value: ResourceExplorerDataGridView;
  selected: boolean;
  onChange(value: ResourceExplorerDataGridView): void;
}

export const ResourceExplorerDataGridViewTab: FC<
  ResourceExplorerDataGridViewTabProps
> = ({ value, selected, onChange }) => {
  const Icon = icons[value];

  const changeHandler = useCallback(() => {
    onChange(value);
  }, [onChange, value]);

  return (
    <Stack
      sx={styles}
      onClick={changeHandler}
      bgcolor={selected ? "white" : undefined}
    >
      <Icon fontSize="small" color={selected ? "primary" : "action"} />

      <Typography
        variant="body2"
        fontWeight="medium"
        textTransform="capitalize"
        color={selected ? "primary" : "text.secondary"}
      >
        {value}
      </Typography>
    </Stack>
  );
};

const styles = {
  py: 1,
  gap: 0.5,
  width: 100,
  borderRadius: 1,
  cursor: "pointer",
  alignItems: "center",
  flexDirection: "row",
  justifyContent: "center",
};
