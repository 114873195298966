import { getMetricFrequencyKey } from "../../cells/metrics-cells/utils/helpers/helpers";
import { MetricsFrequenciesType } from "../types/metricsFrequenciesType";

export const generateMetricsFrequenciesFromContextData = (
  data: Record<string, any>,
): MetricsFrequenciesType => {
  return {
    unused_memory:
      data[getMetricFrequencyKey("resource.unused_memory")] ?? "24h",
    memory_max: data[getMetricFrequencyKey("resource.memory_max")] ?? "24h",
    cpu_max: data[getMetricFrequencyKey("resource.cpu_max")] ?? "24h",
    total_connection_sum:
      data[getMetricFrequencyKey("resource.total_connection_sum")] ?? "24h",
    all_request_sum:
      data[getMetricFrequencyKey("resource.all_request_sum")] ?? "24h",
  };
};
