import { createAsyncThunk } from "@reduxjs/toolkit";
import { Dates } from "../../../../../../services/cloudchipr.api";
import { setWidgetSetup } from "../../../../dashboardsSlice";
import { RootState } from "../../../../../store";
import { costBreakdownWidgetSetupSelector } from "../../../../selectors/setups/cost-breakdown/costBreakdownWidgetSetupSelector";
import { getCostBreakdownWidgetSetupDataThunk } from "../getCostBreakdownWidgetSetupDataThunk";

export const costBreakdownDateRangeChangeThunk = createAsyncThunk(
  "dashboards/costBreakdownDateRangeChange",
  async (dates: Dates, { dispatch, getState }) => {
    const state = getState() as RootState;
    const existingSetup = costBreakdownWidgetSetupSelector(state);

    if (!existingSetup || !dates) {
      return;
    }

    dispatch(setWidgetSetup({ ...existingSetup, dates }));

    dispatch(getCostBreakdownWidgetSetupDataThunk());
  },
);
