import { commitmentsRecommendationsFiltersSelector } from "./commitmentsRecommendationsFiltersSelector";
import { RootState } from "../../../../store";

export const commitmentsRecommendationsAccountIdsSelector = (
  state: RootState,
): string[] | undefined => {
  const filters = commitmentsRecommendationsFiltersSelector(state);

  return filters.account_ids;
};
