import { createDraftSafeSelector } from "@reduxjs/toolkit";
import {
  getDailyDismissedRecommendationComputeSelector,
  getDailyDismissedRecommendationDbSelector,
} from "../recommendationDailyQuerySelector";
import {
  getWeeklyRecommendationComputeDismissedSelector,
  getWeeklyRecommendationDataBaseDismissedSelector,
} from "../recommendationWeeklyQuerySelector";

export const offHoursDismissedRecommendationsLoadingSelector =
  createDraftSafeSelector(
    [
      getDailyDismissedRecommendationComputeSelector,
      getWeeklyRecommendationComputeDismissedSelector,
      getDailyDismissedRecommendationDbSelector,
      getWeeklyRecommendationDataBaseDismissedSelector,
    ],
    (...all) => {
      return all.some((value) => value.isLoading);
    },
  );
