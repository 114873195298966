import { useFlag } from "@unleash/proxy-client-react";
import { ResourceType } from "../../../../../services/cloudchipr.api";

export const useToggledResourceTypes = (): Map<ResourceType, boolean> => {
  const enableEbsSnapshot = useFlag("EnableEBSSnapshot");
  const enableRdsSnapshot = useFlag("EnableRDSSnapshot");
  const enableDynamoDBTable = useFlag("EnableDynamoTable");
  const enableDynamoDBBackup = useFlag("EnableDynamoDBBackup");
  const enableDaxCluster = useFlag("EnableDAXCluster");
  const enableNatGateway = useFlag("EnableNatGateway");
  const enableEcs = useFlag("EnableEcs");

  return new Map([
    ["ebs_snapshot", !enableEbsSnapshot],
    ["rds_snapshot", !enableRdsSnapshot],
    ["rds_snapshot_source", !enableRdsSnapshot],
    ["dynamo_db_table", !enableDynamoDBTable],
    ["dynamo_db_backup", !enableDynamoDBBackup],
    ["dax_cluster", !enableDaxCluster],
    ["nat_gateway", !enableNatGateway],
    ["ecs", !enableEcs],
  ]);
};
