import { commitmentsDetailsDrawerTargetIdSelector } from "../../../common/details-drawer/commitmentsDetailsDrawerTargetIdSelector";
import { RootState } from "../../../../../store";
import { reservationsDataSelector } from "../../data/reservationsDataSelector";

export const selectedReservedInstancePlanUUIdSelector = (state: RootState) => {
  const id = commitmentsDetailsDrawerTargetIdSelector(state);
  const reservations = reservationsDataSelector(state);

  return reservations?.find(
    (reservations) => reservations.provider_unique_id === id,
  )?.id;
};
