import { FC, useCallback } from "react";
import {
  Grid,
  ListItemText,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import moment from "moment";
import { StartDateSelection } from "./StartDateSelection";
import { BudgetPeriod } from "../../../../../../../../../services/cloudchipr.api";
import { capitalizeString } from "../../../../../../../../../utils/helpers/capitalizeString";
import { budgetPeriods } from "../../utils/constants/date-selection";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../../store/hooks";
import { isCurrentBudgetEditingSelector } from "../../../../../../../../../store/budgets/selectors/current-budget/data-selectors/isCurrentBudgetEditingSelector";
import { setCurrentBudgetData } from "../../../../../../../../../store/budgets/budgetsSlice";
import { getResourceExplorerDataByBudgetThunk } from "../../../../../../../../../store/budgets/thunks/getResourceExplorerDataByBudgetThunk";
import { formatDate } from "../../../../../../../../../utils/helpers/date-time/datetime-format";

interface PeriodAndDateSelectionProps {
  period: BudgetPeriod;
  startDate: string;
  setFieldValue(key: string, val: string): void;
}

export const PeriodAndDateSelection: FC<PeriodAndDateSelectionProps> = ({
  period,
  startDate,
  setFieldValue,
}) => {
  const dispatch = useAppDispatch();
  const isEdit = useAppSelector(isCurrentBudgetEditingSelector);

  const startDateChaneHandler = useCallback(
    (startDate: string, p?: BudgetPeriod) => {
      let dateObj = moment(startDate);
      if (p !== "daily" && period !== "daily") {
        dateObj = dateObj.startOf("month");
      }

      const date = formatDate(dateObj);

      setFieldValue("startDate", date);
      dispatch(setCurrentBudgetData({ start_date: date }));
    },
    [setFieldValue, dispatch, period],
  );

  const periodChangeHandler = useCallback(
    (event: any) => {
      const period = event.target.value;
      setFieldValue("period", period);
      dispatch(setCurrentBudgetData({ period }));

      let startDate = moment().format();

      if (period === "quarterly") {
        startDate = moment()
          .quarter(moment().quarter())
          .startOf("quarter")
          .format();
      }

      dispatch(getResourceExplorerDataByBudgetThunk());

      startDateChaneHandler(startDate, period);
    },
    [dispatch, startDateChaneHandler, setFieldValue],
  );

  return (
    <Grid container alignItems="end" justifyContent="space-between">
      <Grid item xs={12} md={6} pr={2}>
        <Typography variant="body2" mb={1}>
          Period*
        </Typography>

        <Select
          fullWidth
          size="small"
          value={period}
          disabled={isEdit}
          onChange={periodChangeHandler}
          renderValue={(val) => capitalizeString(val)}
          label={period ? "" : "Period"}
        >
          {budgetPeriods.map((period) => {
            return (
              <MenuItem key={period} value={period}>
                <ListItemText>{capitalizeString(period)}</ListItemText>
              </MenuItem>
            );
          })}
        </Select>
      </Grid>

      <Grid item xs={12} md={6}>
        <StartDateSelection
          period={period}
          startDate={startDate}
          onDateChange={startDateChaneHandler}
        />
      </Grid>
    </Grid>
  );
};
