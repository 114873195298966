import { createDraftSafeSelector } from "@reduxjs/toolkit";
import { workflowsDataSelector } from "./workflowsDataSelector";
import { Schedule } from "../../../../../services/cloudchipr.api";
import { automationsAppliedAccountsFiltersSelector } from "../filters/applied/automationsAppliedAccountsFiltersSelector";
import { automationsAppliedActionsFiltersSelector } from "../filters/applied/automationsAppliedActionsFiltersSelector";
import { automationsAppliedEnabledStateFiltersSelector } from "../filters/applied/automationsAppliedEnabledStateFiltersSelector";
import { automationsAppliedFrequenciesFiltersSelector } from "../filters/applied/automationsAppliedFrequenciesFiltersSelector";
import { getCronFrequency } from "../../../utils/helpers/cron/getCronFrequency";

export const workflowsFilteredDataSelector = createDraftSafeSelector(
  [
    workflowsDataSelector,
    automationsAppliedAccountsFiltersSelector,
    automationsAppliedActionsFiltersSelector,
    automationsAppliedEnabledStateFiltersSelector,
    automationsAppliedFrequenciesFiltersSelector,
  ],
  (
    workflows,
    accountsFilters,
    actionsFilters,
    enabledStateFilters,
    frequencyFilters,
  ): Schedule[] | undefined => {
    return workflows?.filter((workflow) => {
      let passed = true;

      if (actionsFilters?.length) {
        const action = workflow.action === "notify" ? "notify" : "action";
        passed = passed && actionsFilters.includes(action);
      }

      if (enabledStateFilters?.length) {
        const enabled = workflow.status === "active" ? "yes" : "no";
        passed = passed && enabledStateFilters.includes(enabled);
      }

      if (frequencyFilters?.length) {
        const frequency = getCronFrequency(workflow.cron);
        passed = passed && frequencyFilters.includes(frequency);
      }

      if (accountsFilters?.length) {
        passed =
          passed &&
          workflow.accounts.some((account) =>
            accountsFilters.includes(account.id),
          );
      }

      return passed;
    });
  },
);
