import { FC } from "react";
import { CommitmentUtilizationWidgetSubHeader } from "../../../../../../../widgets/commitment-utlization/components/CommitmentUtilizationWidgetSubHeader";
import { useAppSelector } from "../../../../../../../../../../../store/hooks";
import { commitmentUtilizationWidgetSetupSelector } from "../../../../../../../../../../../store/dashboards/selectors/setups/commitment-utilization/commitmentUtilizationWidgetSetupSelector";

export const CommitmentUtilizationWidgetSetupSubHeader: FC = () => {
  const setup = useAppSelector(commitmentUtilizationWidgetSetupSelector);

  return (
    <CommitmentUtilizationWidgetSubHeader
      name={setup?.name ?? ""}
      providerOrganisationId={setup?.providerOrganisationId}
      commitmentType={setup?.commitmentType}
      grouping={setup?.grouping}
      dates={setup?.dates}
    />
  );
};
