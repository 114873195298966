import { AlertTabType } from "../../../../../store/alerts/utils/types/types";

export const isAlertTabType = (
  alertType: string | null,
): alertType is AlertTabType => {
  if (!alertType) {
    return false;
  }

  return alertType === "costAnomaly" || alertType === "utilization";
};
