import { rightsizingRecommendationsLoadingSelector } from "./rightsizingRecommendationsLoadingSelector";
import { rightsizingDismissedRecommendationsLoadingSelector } from "./rightsizingDismissedRecommendationsLoadingSelector";
import { RootState } from "../../../../store";
import { rightsizingRecommendationsStatusLoadingSelector } from "../statuses/rightsizingRecommendationsStatusLoadingSelector";
import { rightsizingRecommendationsDismissedResourcesLoadingSelector } from "../dismissed-resources/rightsizingRecommendationsDismissedResourcesLoadingSelector";

export const rightsizingRecommendationsPageLoadingSelector = (
  state: RootState,
) => {
  const activeLoading = rightsizingRecommendationsLoadingSelector(state);
  const dismissedLoading =
    rightsizingDismissedRecommendationsLoadingSelector(state);

  const dismissedResourcesLoading =
    rightsizingRecommendationsDismissedResourcesLoadingSelector(state);

  const statusesLoading =
    rightsizingRecommendationsStatusLoadingSelector(state);

  return (
    statusesLoading ||
    activeLoading ||
    dismissedLoading ||
    dismissedResourcesLoading
  );
};
