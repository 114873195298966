import { currentEditingOffHoursSelector } from "./currentEditingOffHoursSelector";
import { RootState } from "../../../../../store";

export const editingOffHoursIdIsWrongSelector = (state: RootState) => {
  const response = currentEditingOffHoursSelector(state);

  return !!(
    response.error &&
    "status" in response.error &&
    +response.error.status >= 400
  );
};
