import { FC, SyntheticEvent, useCallback } from "react";
import { Checkbox, FormControlLabel, Stack, Typography } from "@mui/material";
import { SvgIconComponent } from "@mui/icons-material";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";

interface ReportsDrawerAttachmentsCheckboxProps {
  title: string;
  checked: boolean;
  icon: SvgIconComponent;
  onChange(checked: boolean): void;
  disabled?: boolean;
}

export const ReportsDrawerAttachmentsCheckbox: FC<
  ReportsDrawerAttachmentsCheckboxProps
> = ({ title, checked, icon: Icon, onChange, disabled }) => {
  const handleChange = useCallback(
    (_: SyntheticEvent, checked: boolean) => {
      onChange(checked);
    },
    [onChange],
  );

  return (
    <FormControlLabel
      sx={formControlStyles}
      onChange={handleChange}
      control={<Checkbox size="small" checked={checked} disabled={disabled} />}
      label={
        <Stack spacing={0.5} direction="row" alignItems="center">
          <Icon color="action" fontSize="small" />
          <Typography variant="body2">{title}</Typography>
          <Stack>
            <DownloadOutlinedIcon
              color="action"
              fontSize="small"
              sx={{ ml: 1 }}
            />
          </Stack>
        </Stack>
      }
    />
  );
};

const formControlStyles = {
  border: 1,
  borderColor: "grey.300",
  borderRadius: 1,
  pl: 0.5,
  pr: 1.5,
};
