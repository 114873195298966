import { getResourceExplorerStorageSavingData } from "./getResourceExplorerStorageSavingData";
import { addIdsToFilterTreeItems } from "../../../../components/pages/common/filters-tree/utils/helpers/addIdsToFilterTreeItems";
import { FilterTreeNode } from "../../../../services/cloudchipr.api";

export const getResourceExplorerSettingFilterTree = (
  filterTree?: FilterTreeNode,
  isDefault?: boolean,
) => {
  const parsedData = getResourceExplorerStorageSavingData();

  if (parsedData?.filterTree && isDefault) {
    return parsedData?.filterTree;
  }
  return filterTree ? addIdsToFilterTreeItems(filterTree) : undefined;
};
