import { FC } from "react";
import { AzureSingleCommand } from "./AzureSingleCommand";
import { AzureAccessCommand } from "./utils/generateAzureCommand";
import { CommandSnippet } from "../CommandSnippet";

interface AzureSubscriptionAppIdCodeSnippetProps {
  command: string;
  scope: "subscription" | "tenant";
  identifier: string;
  disabled?: boolean;
  servicePrincipal?: string;
  commands: AzureAccessCommand[];
}

export const AzureSubscriptionAppIdCodeSnippet: FC<
  AzureSubscriptionAppIdCodeSnippetProps
> = ({ servicePrincipal, command, scope, identifier, disabled, commands }) => {
  const scopeJSX =
    scope === "subscription"
      ? "/subscriptions/"
      : "/providers/Microsoft.Management/managementGroups/";

  return (
    <CommandSnippet code={command} disabled={disabled}>
      {commands.map((item) => {
        return (
          <AzureSingleCommand
            key={componentRoleToTitleMap[item.role]}
            role={componentRoleToTitleMap[item.role]}
            scope={scopeJSX}
            identifier={identifier}
            servicePrincipal={servicePrincipal}
            actionType={item.actionType}
          />
        );
      })}
    </CommandSnippet>
  );
};

const componentRoleToTitleMap = {
  reader: "Reader",
  contributor: "Contributor",
};
