import { commitmentUtilizationWidgetByWidgetIdEndpointSelector } from "./commitmentUtilizationWidgetByWidgetIdEndpointSelector";
import { RootState } from "../../../../../store";

export const commitmentUtilizationWidgetByWidgetIdLoadingSelector = (
  state: RootState,
  widgetId: string,
) => {
  const response = commitmentUtilizationWidgetByWidgetIdEndpointSelector({
    widgetId,
  })(state);

  return !!response?.isLoading;
};
