import { createAsyncThunk } from "@reduxjs/toolkit";
import { enqueueSnackbar } from "notistack";
import { getUtilizationAlertsThunk } from "./getUtilizationAlertsThunk";
import {
  cloudChiprApi,
  UtilizationAndCoverageAlert,
} from "../../../../services/cloudchipr.api";
import { createUtilizationAlertFixedCacheKey } from "../../utils/constants/fixedCacheKeys";
import { formatNotificationsFromHtmlToSlack } from "../../../../components/utils/helpers/formatNotificationsFromHtmlToSlack";

export const duplicateUtilizationAlertThunk = createAsyncThunk(
  "alerts/duplicateUtilizationAlert",
  async (alertData: UtilizationAndCoverageAlert, { dispatch }) => {
    const { postUsersMeOrganisationsCurrentAlertsUtilizationAndCoverage } =
      cloudChiprApi.endpoints;

    try {
      const response = await dispatch(
        postUsersMeOrganisationsCurrentAlertsUtilizationAndCoverage.initiate(
          {
            body: {
              name: alertData.name,
              provider_organisation_id: alertData.provider_organisation_id,
              status: "inactive",
              notifications:
                formatNotificationsFromHtmlToSlack(alertData.notifications) ??
                null,
              thresholds: alertData.thresholds,
              emails: alertData.emails,
              based_on: alertData.based_on,
            },
          },
          { fixedCacheKey: createUtilizationAlertFixedCacheKey },
        ),
      ).unwrap();

      await dispatch(getUtilizationAlertsThunk()).unwrap();

      return response;
    } catch (e) {
      // @ts-expect-error todo: fix api spec
      const errMessage = e?.data?.message || "Something went wrong";
      enqueueSnackbar(errMessage, {
        variant: "snackbarAlert",
        AlertSnackBarProps: {
          severity: "error",
        },
      });
    }
  },
);
