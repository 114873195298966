import { FC, useCallback } from "react";
import { Row, Table } from "@tanstack/react-table";
import {
  IndeterminateCheckbox,
  IndeterminateCheckboxOnChange,
} from "./IndeterminateCheckbox";
import { useDataGridContext } from "../DataGridProvider";
import {
  generateSelectionResult,
  getRowRange,
  getRowsSelectionModel,
} from "../utils/helpers/rowSelectionHelpers";

interface RowSelectionCellCheckboxProps {
  row: Row<any>;
  table: Table<any>;
  disabled?: boolean;
}

export const RowSelectionCellCheckbox: FC<RowSelectionCellCheckboxProps> = ({
  row,
  disabled,
  table,
}) => {
  const { rowSelection } = useDataGridContext();
  const lastSelectedRowIdRef = rowSelection?.lastSelectedRowIdRef;

  const changeHandler: IndeterminateCheckboxOnChange = useCallback(
    (e) => {
      const event = e.nativeEvent;
      const lastSelectedId = lastSelectedRowIdRef.current;

      const multipleSelection =
        "shiftKey" in event &&
        event.shiftKey &&
        lastSelectedId &&
        row.id !== lastSelectedId;

      if (!multipleSelection) {
        lastSelectedRowIdRef.current = row.id;
        row.toggleSelected();
        return;
      }

      const { rows } = table.getRowModel();
      const { rows: selectedRows } = table.getSelectedRowModel();

      const inRangeRows = getRowRange(rows, row.id, lastSelectedId);

      const inRangeSelectedRows = getRowsSelectionModel(
        inRangeRows,
        !row.getIsSelected(),
      );
      const outOfRangeSelectedRows = getRowsSelectionModel(selectedRows, true);

      const ids = generateSelectionResult(
        outOfRangeSelectedRows,
        inRangeSelectedRows,
      );

      table.setRowSelection(ids);

      lastSelectedRowIdRef.current = row.id;
    },
    [table, row, lastSelectedRowIdRef],
  );

  return (
    <IndeterminateCheckbox
      checked={row.getIsSelected()}
      disabled={disabled || !row.getCanSelect()}
      indeterminate={row.getIsSomeSelected()}
      onChange={changeHandler}
    />
  );
};
