import { FC } from "react";
import Stack from "@mui/material/Stack";
import { StackProps, Typography } from "@mui/material";
import { useAppSelector } from "../../../../../../../../../store/hooks";
import {
  HeaderCellWidthDateTooltip,
  HeaderCellWidthDateTooltipProps,
} from "../../../../../../common/components/data-grid/cells/HeaderCellWidthDateTooltip";
import { money } from "../../../../../../../../../utils/numeral/money";
import { reservationsCoverageDataTotalsSelector } from "../../../../../../../../../store/commitments/selectors/reservations/data/reservationsCoverageDataTotalsSelector";

interface ReservationsColumnsHeaderCellWithTotalAndDateProps
  extends HeaderCellWidthDateTooltipProps {
  type: "netSavings" | "coveredUsage" | "unCoveredUsage" | "coveredHours";
  alignItems?: StackProps["alignItems"];
}

export const ReservationsColumnsHeaderCellWithTotalAndDate: FC<
  ReservationsColumnsHeaderCellWithTotalAndDateProps
> = ({ type, alignItems = "end", ...props }) => {
  const totals = useAppSelector(reservationsCoverageDataTotalsSelector);

  return (
    <Stack alignItems={alignItems} flex={1} spacing={0.5}>
      <HeaderCellWidthDateTooltip {...props} />

      {totals && (
        <Typography variant="caption" color="text.secondary">
          {type === "coveredHours" ? `${totals[type]}h` : money(totals[type])}
        </Typography>
      )}
    </Stack>
  );
};
