import { SvgIcon, SvgIconProps, useTheme } from "@mui/material";

const Ec2SvgIcon = ({ primary }: SvgIconProps & { primary?: boolean }) => {
  const { palette } = useTheme();

  const color = primary ? palette.primary.main : palette.action.active;

  return (
    <SvgIcon>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.6312 16.2L15.6 8.36881L7.79998 8.40001V16.2312L15.6312 16.2ZM16.2 8.40001H17.4V9.00001H16.2V10.2H17.4V10.8H16.2V12H17.4V12.6H16.2V13.8H17.4V14.4H16.2V15.6H17.4V16.2H16.2V16.2312C16.1997 16.382 16.1396 16.5265 16.033 16.6331C15.9264 16.7397 15.7819 16.7997 15.6312 16.8H15.6V18H15V16.8H13.8V18H13.2V16.8H12V18H11.4V16.8H10.2V18H9.59998V16.8H8.39998V18H7.79998V16.8H7.76878C7.61802 16.7997 7.47353 16.7397 7.36692 16.6331C7.26032 16.5265 7.20029 16.382 7.19998 16.2312V16.2H5.99998V15.6H7.19998V14.4H5.99998V13.8H7.19998V12.6H5.99998V12H7.19998V10.8H5.99998V10.2H7.19998V9.00001H5.99998V8.40001H7.19998V8.36881C7.20029 8.21805 7.26032 8.07356 7.36692 7.96695C7.47353 7.86035 7.61802 7.80032 7.76878 7.80001H7.79998V6.60001H8.39998V7.80001H9.59998V6.60001H10.2V7.80001H11.4V6.60001H12V7.80001H13.2V6.60001H13.8V7.80001H15V6.60001H15.6V7.80001H15.6312C15.7819 7.80032 15.9264 7.86035 16.033 7.96695C16.1396 8.07356 16.1997 8.21805 16.2 8.36881V8.40001ZM12.6 19.8H4.19998V11.4H5.39998V10.8H4.23718C3.88618 10.8 3.59998 11.0862 3.59998 11.4372V19.7628C3.59998 20.1138 3.88618 20.4 4.23718 20.4H12.5634C12.9144 20.4 13.2 20.1138 13.2 19.7628V18.6H12.6V19.8ZM20.4 4.23721V12.5628C20.4 12.9138 20.1144 13.2 19.7634 13.2H18V12.6H19.8V4.20001H11.4V6.00001H10.8V4.23721C10.8 3.88621 11.0862 3.60001 11.4372 3.60001H19.7634C20.1144 3.60001 20.4 3.88621 20.4 4.23721Z"
          fill={color}
        />
      </svg>
    </SvgIcon>
  );
};

Ec2SvgIcon.muiName = "Ec2SvgIcon";

export default Ec2SvgIcon;
