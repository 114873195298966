import { FC, Fragment } from "react";
import { ByteToGibCell } from "./ByteToGibCell";

interface NetworkInOutProps {
  networkIn: number | null;
  networkOut: number | null;
}

export const NetworkInOut: FC<NetworkInOutProps> = ({
  networkOut,
  networkIn,
}) => {
  if (networkOut === null && networkIn === null) {
    return <Fragment>N/A</Fragment>;
  }
  return (
    <Fragment>
      {networkIn === null ? "-" : <ByteToGibCell data={networkIn} />}/
      {networkOut === null ? "-" : <ByteToGibCell data={networkOut} />}
    </Fragment>
  );
};
