import { ResourceExplorerFilterSchema } from "../../../../../services/cloudchipr.api";
import { resourceExplorerQuarterEndForecastVisibilitySelector } from "../data-grid/resourceExplorerQuarterEndForecastVisibilitySelector";
import { groupingsExcludingForecastedCostsColumns } from "../../../../../components/pages/resource-explorer/components/resource-explorer-card/components/resource-explorer-data-grid/components/utils/constants/groupingsExcludingForecastedCostsColumns";
import { resourceExplorerDataPointSelector } from "../data/resourceExplorerDataPointSelector";
import { resourceExplorerGroupingSelector } from "../data/resourceExplorerGroupingSelector";
import { resourceExplorerGroupValuesSelector } from "../data/resourceExplorerGroupValuesSelector";
import { resourceExplorerDatesSelector } from "../data/resourceExplorerDatesSelector";
import { resourceExplorerTrendTypeSelector } from "../resourceExplorerTrendTypeSelector";
import { resourceExplorerCostTypeSelector } from "../resourceExplorerCostTypeSelector";
import { resourceExplorerFilterTreeSelector } from "../../filter-tree/resourceExplorerFilterTreeSelector";
import { RootState } from "../../../../store";

export const resourceExplorerPayloadDataSelector = (
  state: RootState,
): ResourceExplorerFilterSchema => {
  const dates = resourceExplorerDatesSelector(state);
  const dataPoint = resourceExplorerDataPointSelector(state);
  const groupBy = resourceExplorerGroupingSelector(state);
  const groupValues = resourceExplorerGroupValuesSelector(state);
  const quarterEndForecastVisibility =
    resourceExplorerQuarterEndForecastVisibilitySelector(state);
  const filterTree = resourceExplorerFilterTreeSelector(state);
  const trendType = resourceExplorerTrendTypeSelector(state);
  const costType = resourceExplorerCostTypeSelector(state);

  const loadMonthlyForecastForGroupingItems =
    groupBy && groupingsExcludingForecastedCostsColumns.has(groupBy)
      ? undefined
      : true;

  const loadQuarterlyForecastForGroupingItems =
    groupBy && groupingsExcludingForecastedCostsColumns.has(groupBy)
      ? undefined
      : quarterEndForecastVisibility;

  return {
    dates,

    group_by: groupBy,
    group_values: groupValues ?? undefined,

    data_point: dataPoint ?? "monthly",

    load_monthly_forecast_for_grouping_items:
      loadMonthlyForecastForGroupingItems,
    load_quarterly_forecast_for_grouping_items:
      loadQuarterlyForecastForGroupingItems,

    trend_type: trendType,
    cost_type: costType,

    filter_tree: filterTree,
  };
};
