import momentTz from "moment-timezone";
import moment from "moment";
import { formatDate } from "../../../../../../utils/helpers/date-time/datetime-format";
import { getTimeZoneObject } from "../../../../../../utils/helpers/date-time/timeZones";
import { ResourceType } from "../../../../../../services/cloudchipr.api";

export const automationsAllActions = {
  clean: "Resource Actions",
  notify: "Notify",
  silent: "Silent",
  clean_and_notify: "Resource Actions, Notify",
};

export const offHoursAvailableResourceTypes: ResourceType[] = [
  "ec2",
  "rds",
  "vm",
  "sql",
  "az_vm",
];

export const getStartsOnDate = (
  timeZone?: string,
  today: boolean = false,
): string => {
  timeZone = timeZone || getTimeZoneObject().tzCode;

  return formatDate(
    momentTz()
      .tz(timeZone)
      .add(today ? 0 : 1, "day")
      .set({ hours: 0, minutes: 0, seconds: 0 }),
  );
};

export const getEndsOnDate = (timeZone?: string): string => {
  return formatDate(moment(getStartsOnDate(timeZone)).add(1, "day"));
};
