import { createDraftSafeSelector } from "@reduxjs/toolkit";
import { recommendationComputeDailyDismissedDataSelector } from "./recommendationComputeDailyDismissedDataSelector";
import { recommendationComputeWeeklyDismissedDataSelector } from "./recommendationComputeWeeklyDismissedDataSelector";

export const recommendationComputeDismissedDataSelector =
  createDraftSafeSelector(
    [
      recommendationComputeDailyDismissedDataSelector,
      recommendationComputeWeeklyDismissedDataSelector,
    ],
    (daily, weekly) => {
      return [...daily, ...weekly];
    },
  );
