import { createAsyncThunk } from "@reduxjs/toolkit";
import { enqueueSnackbar } from "notistack";
import { cloudChiprApi } from "../../../../services/cloudchipr.api";

interface GetResourceExplorerViewByIdThunkArgs {
  viewId: string;
  forceRefetch?: boolean;
}

export const getResourceExplorerViewByIdThunk = createAsyncThunk(
  "resourceExplorer/getResourceExplorerViewById",
  async (
    { viewId, forceRefetch }: GetResourceExplorerViewByIdThunkArgs,
    { dispatch },
  ) => {
    const {
      getUsersMeOrganisationsCurrentResourceExplorerViewsByResourceExplorerViewId,
    } = cloudChiprApi.endpoints;
    try {
      return await dispatch(
        getUsersMeOrganisationsCurrentResourceExplorerViewsByResourceExplorerViewId.initiate(
          {
            resourceExplorerViewId: viewId,
          },
          { forceRefetch },
        ),
      ).unwrap();
    } catch (e) {
      // @ts-expect-error//TODO: return to this after adding error type
      const errMessage = e?.data?.message || "Something went wrong";
      enqueueSnackbar(errMessage, {
        variant: "snackbarAlert",
        AlertSnackBarProps: {
          severity: "error",
        },
      });
    }
  },
);
