import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DashboardsInterface, WidgetSetupType } from "./utils/types/types";

const initialState: DashboardsInterface = {
  id: null,
  setup: null,
  widgetSelectorState: false,
};

export const dashboardsSlice = createSlice({
  name: "dashboard",
  initialState: initialState,
  reducers: {
    setCurrentDashboardId: (state, action: PayloadAction<string>) => {
      state.id = action.payload;
    },
    setWidgetSetup: (state, action: PayloadAction<WidgetSetupType | null>) => {
      state.setup = action.payload;
    },
    setWidgetSetupName: (state, action: PayloadAction<string>) => {
      if (state.setup) {
        state.setup.name = action.payload;
      }
    },
    setWidgetSelectorState: (state, action: PayloadAction<boolean>) => {
      state.widgetSelectorState = action.payload;
    },
  },
});

export const {
  setCurrentDashboardId,
  setWidgetSetup,
  setWidgetSetupName,
  setWidgetSelectorState,
} = dashboardsSlice.actions;

export default dashboardsSlice.reducer;
