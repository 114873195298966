// TODO: Percentage calculations should be moved to numeral() instead manual calculation and formatting
export const getPercentage = (partial: number, total: number): number => {
  return (partial / total) * 100;
};

// TODO: Deprecated
export const getFixedPercentage = (value: number, total: number) => {
  const percentage = getPercentage(value, total) ?? 0;

  return percentage === 0 ? percentage : percentage.toFixed(2);
};

export const getNumberByPercentage = (
  percent: number,
  total: number,
): number => {
  return (percent / 100) * total;
};
