import { RootState } from "../../../../store";
import { cloudChiprApi } from "../../../../../services/cloudchipr.api";
import { getCategoryCreationFixedCacheKey } from "../../../thunks/categories/createCategoryByDimensionIdThunk";
import { dimensionCategoriesEntriesSelector } from "../../slice-data/dimensionCategoriesEntriesSelector";

const categoryCreateSelector =
  cloudChiprApi.endpoints
    .postUsersMeOrganisationsCurrentDimensionsByDimensionIdCategories.select;

export const categoryCreateLoadingSelector = (state: RootState) => {
  const categories = dimensionCategoriesEntriesSelector(state);

  return categories.some(([id]) => {
    return !!categoryCreateSelector(getCategoryCreationFixedCacheKey(id))(state)
      ?.isLoading;
  });
};
