import { FC, useCallback } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../../store/hooks";
import { CommitmentsDateRangeSelector } from "../options/date-range/CommitmentsDateRangeSelector";
import { commitmentsDetailsDrawerDatesSelector } from "../../../../../../store/commitments/selectors/common/details-drawer/commitmentsDetailsDrawerDatesSelector";
import { getUtilizationAndCoverageDetailsThunk } from "../../../../../../store/commitments/thunks/getUtilizationAndCoverageDetailsThunk";
import { setCommitmentsDetailsDrawerDates } from "../../../../../../store/commitments/commitmentsSlice";
import { Dates } from "../../../../../../services/cloudchipr.api";
import { getReservationDetailsThunk } from "../../../../../../store/commitments/thunks/getReservationDetailsThunk";
import { commitmentsTypeTabSelector } from "../../../../../../store/commitments/selectors/common/commitmentsTypeTabSelector";

export const CommitmentsDrawerDateRange: FC = () => {
  const dispatch = useAppDispatch();
  const dates = useAppSelector(commitmentsDetailsDrawerDatesSelector);
  const activeTab = useAppSelector(commitmentsTypeTabSelector);

  const dateChangeHandler = useCallback(
    (dates: Dates) => {
      dispatch(setCommitmentsDetailsDrawerDates(dates));

      if (activeTab === "reservation") {
        dispatch(getReservationDetailsThunk());
      } else {
        dispatch(getUtilizationAndCoverageDetailsThunk());
      }
    },
    [dispatch, activeTab],
  );

  return (
    <CommitmentsDateRangeSelector dates={dates} onChange={dateChangeHandler} />
  );
};
