import { RootState } from "../../../../../store";
import { automationErrorsSelector } from "../automationErrorsSelector";

export const automationFiltersErrorSelector = (
  state: RootState,
): Record<string, string> => {
  const errors = automationErrorsSelector(state);

  return Object.keys(errors).reduce(
    (acc, key) => {
      if (!key.startsWith("filter")) {
        return acc;
      }

      const [_, secondPart] = key.split("filter[");
      const index = secondPart?.at(0);

      if (!index) {
        return acc;
      }

      acc[index] = errors[key];

      return acc;
    },
    {} as Record<string, string>,
  );
};
