import { FC, memo, useCallback, useMemo } from "react";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../store/hooks";
import { setRecommendationsTagsFilter } from "../../../../../../../../store/recommendations/recommendationsSlice";
import { rightsizingRecommendationsDataByTabSelector } from "../../../../../../../../store/recommendations/selectors/rightsizing/data/rightsizingRecommendationsDataByTabSelector";
import { RecommendationsTabType } from "../../../../off-hours-recommendations/components/OffHoursRecommendationsContent";
import { TagFilterDropdownSelect } from "../../../../../../../common/select/tag-filter-dropdown-select/TagFilterDropdownSelect";
import { generateRecommendationsTagsFilterOptionsByTableData } from "../../../../common/utils/helpers/generateRecommendationsTagsFilterOptionsByTableData";

interface RightsizingTagsFilterProps {
  tab: RecommendationsTabType;
}
export const RightsizingTagsFilter: FC<RightsizingTagsFilterProps> = memo(
  ({ tab }) => {
    const dispatch = useAppDispatch();

    const tableData = useAppSelector((state) =>
      rightsizingRecommendationsDataByTabSelector(state, tab),
    );

    const tagFilterOptions = useMemo(() => {
      return generateRecommendationsTagsFilterOptionsByTableData(
        tableData ?? [],
      );
    }, [tableData]);

    const closeSubmitHandler = useCallback(
      (filter: any) => {
        dispatch(setRecommendationsTagsFilter(filter));
      },
      [dispatch],
    );

    return (
      <TagFilterDropdownSelect
        label="Tags/Labels"
        onCloseSubmit={closeSubmitHandler}
        keysOptions={tagFilterOptions?.keysOptions ?? []}
        valuesOptions={tagFilterOptions?.valuesOptions}
      />
    );
  },
);
