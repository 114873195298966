import { FC, Fragment } from "react";
import { Tooltip, Typography } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { useGetUsersMeOrganisationsCurrentSettingsQuery } from "../../../../../../services/cloudchipr.api";

export const TTLExampleStep1: FC = () => {
  const { data } = useGetUsersMeOrganisationsCurrentSettingsQuery();

  const value = data?.smart_tag_ttl?.value;
  return (
    <Fragment>
      <Typography color="primary">Step 1</Typography>
      <Typography color="text.secondary">
        Add a tag to an EC2 instance with
        <br />
        Key:{" "}
        <Typography component="span" fontWeight="medium">
          {value},{" "}
        </Typography>
        Value:{" "}
        <Typography component="span" fontWeight="medium">
          1h
        </Typography>
        <Tooltip
          arrow
          title="The value must be any number of m(minutes), h(hours) or d(days) or never, e.g. 2d"
        >
          <InfoOutlinedIcon
            sx={{
              verticalAlign: "middle",
              ml: 0.5,
            }}
            fontSize="small"
          />
        </Tooltip>
      </Typography>
      <Typography color="text.secondary" fontStyle="italic" variant="caption">
        In case you would like a resource to never expire, just simply set:
        <br />
        Key:{" "}
        <Typography component="span" fontWeight="medium" variant="inherit">
          {value},{" "}
        </Typography>
        Value:{" "}
        <Typography component="span" fontWeight="medium" variant="inherit">
          never
        </Typography>
      </Typography>
    </Fragment>
  );
};
