import * as yup from "yup";
import { Emails } from "../../../../../../services/cloudchipr.api";

export const emailsValidationSchema = (data: Emails) => {
  const to = data?.to ?? [];
  const cc = data?.cc ?? [];
  const bcc = data?.bcc ?? [];

  return yup.object({
    to: yup
      .array()
      .required()
      .of(
        yup
          .string()
          .trim()
          .notOneOf([...cc, ...bcc], "Emails should not be duplicated")
          .email("Invalid email format"),
      )
      .min(1, "At least one email should be selected."),

    cc: yup
      .array()
      .nullable()
      .of(
        yup
          .string()
          .trim()
          .notOneOf([...to, ...bcc], "Emails should not be duplicated")
          .email("Invalid email format"),
      ),

    bcc: yup
      .array()
      .nullable()
      .of(
        yup
          .string()
          .trim()
          .notOneOf([...to, ...cc], "Emails should not be duplicated")
          .email("Invalid email format"),
      ),
  });
};

export const getEmailsValidationSchema = (nullable?: boolean) => {
  return yup.lazy((data: Emails) => {
    const schema = emailsValidationSchema(data);

    if (nullable) {
      return schema.nullable();
    }

    return schema;
  });
};
