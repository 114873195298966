import { FC, Fragment, useState } from "react";
import { GridApi } from "@ag-grid-community/core";
import { ReportsTableComponent } from "./components/ReportsTableComponent";
import { ReportsTableToolbar } from "./components/toolbar/ReportsTableToolbar";
import { ReportsTableNoData } from "./components/ReportsTableNoData";
import { useAppSelector } from "../../../../../store/hooks";
import { reportsIsEmptyDataSelector } from "../../../../../store/reports/selector/reportsIsEmptyDataSelector";
import { getAllReportsLoadingSelector } from "../../../../../store/reports/selector/getAllReportsLoadingSelector";
import { reportsTableFilteredDataSelector } from "../../../../../store/reports/selector/reportsTableFilteredDataSelector";

export const ReportsTable: FC = () => {
  const isEmptyData = useAppSelector(reportsIsEmptyDataSelector);
  const loading = useAppSelector(getAllReportsLoadingSelector);
  const reports = useAppSelector(reportsTableFilteredDataSelector);
  const [gridApi, setGridApi] = useState<GridApi>();

  if (isEmptyData && !loading) {
    return <ReportsTableNoData />;
  }

  return (
    <Fragment>
      <ReportsTableToolbar gridApi={gridApi} />
      <ReportsTableComponent setGridApi={setGridApi} reports={reports} />
    </Fragment>
  );
};
