import { createAsyncThunk } from "@reduxjs/toolkit";
import { cloudChiprApi } from "../../../services/cloudchipr.api";

export const getAllIntegrationsThunk = createAsyncThunk(
  "integrations/getAllIntegrationsThunk",
  async (_: void, { dispatch }) => {
    return await dispatch(
      cloudChiprApi.endpoints.getUsersMeIntegrations.initiate(
        {},
        { forceRefetch: true },
      ),
    );
  },
);
