import { FC, Fragment, useCallback } from "react";
import { Chip, Typography } from "@mui/material";
import { DropdownSelectOption } from "../../dropdown-select/utils/types/types";
import { TypographyWithTooltip } from "../../../TypographyWithTooltip";
import { FilterTriggerComponentProps } from "../../dropdown-select/utils/types/filterTriggerComponentProps";

export type RenderTagsComponentProps = {
  disabled: boolean;
  options: DropdownSelectOption[];
  selectedValues: string[];
  onChange(values: string[]): void;
  deleteAllHandler(): void;
  deleteHandler(): void;
  formatSelectedLabel?(value: string): string;
};

export interface AutocompleteRenderTagsProps {
  triggerProps: FilterTriggerComponentProps;
  allSelectedLabel?: string;
  singleSelect: boolean;
  disabled: boolean;
  onChange(values: string[]): void;
  formatSelectedLabel?(value: string): string;
  RenderTagsComponent?: FC<RenderTagsComponentProps>;
  loading?: boolean;
  freeSolo?: boolean;
}

export const AutocompleteRenderTags: FC<AutocompleteRenderTagsProps> = ({
  triggerProps,
  allSelectedLabel,
  onChange,
  RenderTagsComponent,
  disabled,
  singleSelect,
  formatSelectedLabel,
  loading,
  freeSolo,
}) => {
  const { options, selectedValues, setSelectedValues } = triggerProps;

  const allSelected =
    options.length > 1 && options.length === selectedValues.length;

  const changeHandler = useCallback(
    (values: string[]) => {
      setSelectedValues(values);
      onChange(values);
    },
    [setSelectedValues, onChange],
  );

  const deleteHandler = useCallback(() => {
    const values = selectedValues.filter(
      (s: string) => s !== selectedValues[0],
    );

    changeHandler(values);
  }, [selectedValues, changeHandler]);

  const deleteAllHandler = useCallback(() => {
    changeHandler([]);
  }, [changeHandler]);

  if (RenderTagsComponent) {
    return (
      <RenderTagsComponent
        options={options}
        disabled={disabled}
        onChange={changeHandler}
        deleteAllHandler={deleteAllHandler}
        deleteHandler={deleteHandler}
        selectedValues={selectedValues}
        formatSelectedLabel={formatSelectedLabel}
      />
    );
  }

  const firstItem = selectedValues?.at(0) ?? "";
  const selectedLabel = formatSelectedLabel
    ? formatSelectedLabel(firstItem)
    : firstItem;

  if (loading) {
    return null;
  }

  if (singleSelect && !freeSolo) {
    return <TypographyWithTooltip variant="body2" title={selectedLabel} />;
  }

  if (!singleSelect && allSelected) {
    return (
      <Chip
        size="small"
        variant="outlined"
        disabled={disabled}
        sx={{ minWidth: "20px" }}
        onDelete={deleteAllHandler}
        label={`All ${allSelectedLabel ?? "Selected"}`}
      />
    );
  }

  return (
    <Fragment>
      {!singleSelect && !allSelected && !!selectedValues?.length && (
        <Fragment>
          <Chip
            size="small"
            variant="outlined"
            label={selectedLabel}
            disabled={disabled}
            onDelete={deleteHandler}
            sx={{ minWidth: "50px" }}
          />

          {selectedValues.length > 1 && (
            <Typography
              ml={1}
              variant="body2"
              whiteSpace="nowrap"
              color="text.secondary"
            >
              + {selectedValues.length - 1}{" "}
              {(allSelectedLabel ?? "").toLowerCase()}
            </Typography>
          )}
        </Fragment>
      )}
    </Fragment>
  );
};
