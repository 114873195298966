import { ColDef } from "@ag-grid-community/core/dist/types/src/entities/colDef";
import { frequencySortingFn } from "../../../../../schedule/common/utils/sortingFunctions/frequencySortingFn";

export const automationsFrequencySortingFn: ColDef["comparator"] = (
  valueA,
  valueB,
  nodeA,
  nodeB,
) => {
  const current = nodeA.data.cron;
  const next = nodeB.data.cron;

  return frequencySortingFn(current, next);
};
