import { FC, useCallback } from "react";

import { Box, Stack, Typography } from "@mui/material";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import { LoadingButton } from "@mui/lab";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../store/hooks";
import { billingErrorAccountStatusesByProviderSelector } from "../../../../../../../store/common/selectors/billing-status/error/billingErrorAccountStatusesByProviderSelector";
import { goAwsConsoleThunk } from "../../../../../../../store/common/thunks/billing-status/goAwsConsoleThunk";
import { cfnChangeSetsLoadingSelector } from "../../../../../../../store/common/thunks/billing-status/cfnChangeSetsLoadingSelector";
import { useAppAbility } from "../../../../../../../services/permissions";

export const AwsBillingErrorState: FC = () => {
  const accountStatuses = useAppSelector((state) =>
    billingErrorAccountStatusesByProviderSelector(state, "aws"),
  );

  const errorMessage = accountStatuses[0]?.problem_message;
  const accountId = accountStatuses[0]?.account_id;
  const dispatch = useAppDispatch();
  const awsConsoleLoading = useAppSelector(cfnChangeSetsLoadingSelector);

  const { cannot } = useAppAbility();
  const canNotGoToAWSConsole = cannot("edit", "billing");

  const goAwsConsoleHandler = useCallback(async () => {
    if (!accountId) {
      return;
    }
    dispatch(goAwsConsoleThunk(accountId));
  }, [accountId, dispatch]);

  return (
    <Stack justifyContent="center" alignItems="center" height="100%" gap={2}>
      <ErrorOutlineOutlinedIcon color="error" fontSize="large" />
      <Typography variant="h5" fontWeight={500} mt={2}>
        Billing Data Error
      </Typography>
      {errorMessage && (
        <Box bgcolor="grey.300" p={1} borderRadius={2} maxWidth={624}>
          <Typography fontWeight={700} fontSize={10} color="text.disabled">
            Error
          </Typography>
          <Typography variant="body2">{errorMessage}</Typography>
        </Box>
      )}
      <Typography variant="body2">
        To fix the error please execute the CloudFormation template from AWS
        console.
      </Typography>
      <LoadingButton
        variant="contained"
        onClick={goAwsConsoleHandler}
        disabled={canNotGoToAWSConsole || awsConsoleLoading}
        loading={awsConsoleLoading}
      >
        Go to AWS Console
      </LoadingButton>
    </Stack>
  );
};
