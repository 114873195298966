import { FC } from "react";
import { CustomCellRendererProps } from "@ag-grid-community/react";
import { Stack } from "@mui/material";
import { ReportsStatusSwitch } from "./ReportsStatusSwitch";
import { ReportActionsCell } from "./actions/ReportActionsCell";
import { ReportsTableData } from "../../../../../../../store/reports/utils/types/types";

export const ReportsGridActionsCell: FC<
  CustomCellRendererProps<ReportsTableData>
> = ({ data }) => {
  if (!data?.id) {
    return;
  }

  return (
    <Stack
      direction="row"
      spacing={1}
      height="100%"
      alignItems="center"
      justifyContent="space-between"
    >
      <ReportsStatusSwitch reportId={data.id} status={data.status} />
      <ReportActionsCell reportId={data.id} />
    </Stack>
  );
};
