import { dimensionsDataSelector } from "./dimensionsDataSelector";
import { RootState } from "../../../store";

export const dimensionByIdSelector = (
  state: RootState,
  dimensionId: string,
) => {
  const dimensions = dimensionsDataSelector(state);

  return dimensions?.find((dimension) => dimension.id === dimensionId);
};
