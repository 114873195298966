import {
  GroupedRolePermissions,
  PermissionWithSelectedState,
} from "../../../utils/types/common";

export const generateGroupedRolePermissions = (
  permissions: PermissionWithSelectedState[],
): GroupedRolePermissions => {
  return permissions.reduce((result, item) => {
    const newItem = {
      selected: item.selected || false,
      ...item,
    };
    if (!result?.[item.group]) {
      result = {
        ...result,
        [item.group]: {
          collapsed: false,
          data: [newItem],
        },
      };
    } else {
      result?.[item.group].data.push(newItem);
    }
    return result;
  }, {} as GroupedRolePermissions);
};
