import { createAsyncThunk } from "@reduxjs/toolkit";
import { enqueueSnackbar } from "notistack";
import { getLargestCostChangesWidgetDataByWidgetIdThunk } from "../data-get/getLargestCostChangesWidgetDataByWidgetIdThunk";
import { UpdateDashboardWidgetLargestCostChangesRequest } from "../../../../../../services/cloudchipr.api";
import { currentDashboardIdSelector } from "../../../../selectors/dashboard/currentDashboardIdSelector";
import { RootState } from "../../../../../store";
import { buildWidgetThunk } from "../../common/buildWidgetThunk";
import { largestCostChangesSetupSelector } from "../../../../selectors/setups/larges-cost-changes/largestCostChangesSetupSelector";
import { largestCostChangesDefaultData } from "../../../../utils/constants/largestCostChangesDefaultData";

export const buildLargestCostChangesWidgetThunk = createAsyncThunk(
  "dashboards/buildLargestCostChangesWidget",
  async (_, { dispatch, getState }) => {
    const state = getState() as RootState;

    const dashboardId = currentDashboardIdSelector(state);
    const setup = largestCostChangesSetupSelector(state);

    if (!dashboardId || !setup?.viewId) {
      return;
    }

    const minCostChangeDefaultValue = setup?.id ? null : undefined;
    const minPercentageChangeDefaultValue = setup?.id ? null : undefined;

    const widget = {
      type: "largest_cost_changes",
      name: setup.name,
      date_to: setup.dates.to,
      view_id: setup.viewId,
      grouping: setup.grouping,
      group_values: setup.group_values ?? undefined,
      date_from: setup.dates.from,
      date_label: setup.dates.label,
      dates: setup.dates,
      sort_by: setup.sortingBy ?? undefined,
      price_direction: setup.priceDirection ?? undefined,
      items_count: setup.itemsCount ?? largestCostChangesDefaultData.itemsCount,
      min_cost_change:
        setup.minCostChange?.state && setup.minCostChange?.value
          ? setup.minCostChange?.value
          : minCostChangeDefaultValue,
      min_percentage_change:
        setup.minPercentChange?.state && setup.minPercentChange?.value
          ? setup.minPercentChange?.value
          : minPercentageChangeDefaultValue,
      layout: null,
    } as UpdateDashboardWidgetLargestCostChangesRequest;

    try {
      const response = await dispatch(
        buildWidgetThunk({
          widgetId: setup?.id,
          widget,
        }),
      ).unwrap();

      if (response && setup?.id) {
        await dispatch(
          getLargestCostChangesWidgetDataByWidgetIdThunk(response.id),
        );
      }
      return response?.id;
    } catch (e) {
      // @ts-expect-error todo: api fix
      const errMessage = e?.data?.message || "Something went wrong";
      enqueueSnackbar(errMessage, {
        variant: "snackbarAlert",
        AlertSnackBarProps: { severity: "error" },
      });

      return null;
    }
  },
);
