import { FC, memo, RefObject } from "react";
import { SxProps } from "@mui/system";
import { TableBody, Theme } from "@mui/material";
import { Row, Table } from "@tanstack/react-table";
import { WithGetRowProps } from "./RowDnDWrapper";
import { TableRow } from "./rows/TableRow";
import { RowExpandingConfig } from "../utils/types/prop-types";
import { VirtualColumnsConfig } from "../table-header/TableHeadComponent";

interface TableBodyComponentProps extends WithGetRowProps {
  table: Table<any>;
  rows: Row<any>[];
  rowDnDEnabled: boolean;
  stickyColumnsEnabled: boolean;
  enableColumnVirtualization: boolean;
  expandingRowConfig?: RowExpandingConfig;
  styles?: SxProps<Theme>;
  globalFilter?: string;
  tableContainerRef: RefObject<HTMLTableElement>;
  virtualColumnsConfig: VirtualColumnsConfig;
}

export const TableBodyComponent: FC<TableBodyComponentProps> = memo(
  ({
    table,
    rows,
    expandingRowConfig,
    rowDnDEnabled,
    stickyColumnsEnabled,
    styles,
    globalFilter,
    tableContainerRef,
    getRowProps,
    virtualColumnsConfig,
    enableColumnVirtualization,
  }) => {
    return (
      <TableBody>
        {rows.map((row) => {
          return (
            <TableRow
              key={
                row.id +
                row.getIsExpanded().toString() +
                row.getIsSelected().toString()
              }
              styles={styles}
              table={table}
              row={row}
              enableColumnVirtualization={enableColumnVirtualization}
              stickyColumnsEnabled={stickyColumnsEnabled}
              virtualColumnsConfig={virtualColumnsConfig}
              getRowProps={getRowProps}
              dndEnabled={rowDnDEnabled}
              expandingRowConfig={expandingRowConfig}
              globalFilter={globalFilter}
              tableContainerRef={tableContainerRef}
            />
          );
        })}
      </TableBody>
    );
  },
);
