import { FC, memo, useCallback } from "react";
import {
  Box,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from "@mui/material";
import { AutomationDateTimePicker } from "../../../../../../automations/common/components/form/schedule-content/dates/AutomationDateTimePicker";
import { formatDate } from "../../../../../../../../utils/helpers/date-time/datetime-format";
import { getEndsOnDate } from "../../../../../common/utils/constants/common";

interface EndDateProps {
  timeZone: string;
  endDate: string | null;
  error?: string | boolean;
  spacing: "long" | "short";
  setFieldValue(key: string, date: string | null): void;
}

export const EndDate: FC<EndDateProps> = memo(
  ({ endDate, setFieldValue, error, timeZone, spacing }) => {
    const dateAcceptHandler = useCallback(
      (value: string | null) => {
        if (value) {
          setFieldValue("endDate", formatDate(value));
        }
      },
      [setFieldValue],
    );

    const dateToggleHandler = useCallback(
      (e: any) => {
        if (e.target.value === "never") {
          setFieldValue("endDate", null);
        } else {
          setFieldValue("endDate", getEndsOnDate(timeZone));
        }
      },
      [setFieldValue, timeZone],
    );

    return (
      <Stack direction="row" alignItems="flex-start">
        <Typography variant="subtitle2" width={spacing === "short" ? 58 : 110}>
          Ends:
        </Typography>
        <Stack direction="row" alignItems="flex-end">
          <RadioGroup
            value={endDate ? "on" : "never"}
            onChange={dateToggleHandler}
          >
            <FormControlLabel
              value="never"
              control={<Radio sx={{ py: 0 }} />}
              label="Never Ends"
            />
            <FormControlLabel
              sx={{ mt: 3 }}
              value="on"
              control={<Radio sx={{ py: 0 }} />}
              label="Ends On"
            />
          </RadioGroup>

          {endDate && (
            <Box mb={-1} ml={2}>
              <AutomationDateTimePicker
                date={endDate}
                error={error}
                onAccept={dateAcceptHandler}
                minDate={getEndsOnDate(timeZone)}
              />
            </Box>
          )}
        </Stack>
      </Stack>
    );
  },
);
