import { FC } from "react";
import { IconButton, Tooltip } from "@mui/material";
import { CSVLink } from "react-csv";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import moment from "moment";
import { tasksListCsvSelector } from "../../../../../../store/task-management/selectors/data/tasksListCSVSelector";
import { useAppSelector } from "../../../../../../store/hooks";
import { formatDate } from "../../../../../../utils/helpers/date-time/datetime-format";
import { tasksListIsEmptySelector } from "../../../../../../store/task-management/selectors/data/tasksListIsEmptySelector";

export const TasksGridCsvDownloader: FC = () => {
  const csvData = useAppSelector(tasksListCsvSelector);
  const fileName = `${formatDate(moment(), { type: "date" })}_tasks`;
  const empty = useAppSelector(tasksListIsEmptySelector);

  if (!csvData) {
    return null;
  }

  return (
    <Tooltip arrow title="Export CSV" placement="top">
      <span>
        <IconButton
          disabled={!csvData?.length || empty}
          size="small"
          sx={{ "& a": { textDecoration: "none", color: "inherit" }, pb: 0 }}
        >
          <CSVLink
            filename={`${fileName}.csv`}
            data={csvData}
            enclosingCharacter=""
            separator=";"
          >
            <DownloadOutlinedIcon fontSize="small" />
          </CSVLink>
        </IconButton>
      </span>
    </Tooltip>
  );
};
