import { createAsyncThunk } from "@reduxjs/toolkit";
import { getOffHoursRecommendationsThunk } from "./getOffHoursRecommendationsThunk";

export const getOffHoursAllRecommendationsThunk = createAsyncThunk(
  "offHoursRecommendations/getOffHoursAllRecommendationsThunk",
  async (arg, { dispatch }) => {
    await Promise.all([
      dispatch(
        getOffHoursRecommendationsThunk({ group: "compute", dismissed: true }),
      ),
      dispatch(getOffHoursRecommendationsThunk({ group: "compute" })),
      dispatch(
        getOffHoursRecommendationsThunk({ group: "database", dismissed: true }),
      ),

      dispatch(getOffHoursRecommendationsThunk({ group: "database" })),
    ]);
  },
);
