import { ColDef } from "@ag-grid-community/core/dist/types/src/entities/colDef";

export const creationComparatorFn: ColDef["comparator"] = (
  _,
  __,
  nodeA,
  nodeB,
) => {
  const current = nodeA.data?.created_at ?? "";
  const next = nodeB.data?.created_at ?? "";

  return current.localeCompare(next);
};
