import { FC } from "react";
import { Box, Button, Stack, Typography } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { ProviderIcon } from "../../../../../../../common/ProviderIcon";
import SlackLogo from "../../../../../../../../assets/images/logos/integrations/slack.svg";

interface ResourceExplorerAzureErrorBillingStatusPanelItemProps {
  name?: string | null;
  providerAccountId?: string;
  error?: string;
}
export const ResourceExplorerAzureErrorBillingStatusPanelItem: FC<
  ResourceExplorerAzureErrorBillingStatusPanelItemProps
> = ({ name, providerAccountId, error }) => {
  return (
    <Stack bgcolor="grey.100" borderRadius={4} gap={1} p={2}>
      <Stack direction="row" gap={1}>
        <ProviderIcon provider="azure" />
        <div>
          <Typography variant="subtitle1" fontWeight="medium">
            Billing Data Error
          </Typography>
          {!!(name || providerAccountId) && (
            <Typography variant="body2">
              Subscription:{" "}
              <Typography
                component="span"
                variant="inherit"
                fontWeight="medium"
              >
                {name ?? providerAccountId}
              </Typography>
            </Typography>
          )}
        </div>
      </Stack>
      {(name || providerAccountId) && (
        <Typography variant="body2">
          We are not able to see the cost and usage + billing data for certain
          periods, with the following response from Azure.
        </Typography>
      )}

      {error && (
        <Stack bgcolor="grey.300" p={1} borderRadius={2}>
          <Typography fontWeight="bold" variant="tiny" color="text.disabled">
            Error
          </Typography>
          <Typography variant="caption">{error}</Typography>
        </Stack>
      )}
      <Typography variant="body2">
        In order to fix this please follow{" "}
        <Typography
          color="primary"
          variant="body2"
          to="https://learn.microsoft.com/en-us/azure/cost-management-billing/costs/assign-access-acm-data#enable-access-to-costs-in-the-azure-portal"
          component={RouterLink}
          target="_blank"
        >
          {" "}
          the instructions from Microsoft.
        </Typography>
      </Typography>
      <Box display="flex" justifyContent="flex-end">
        <Button
          component={RouterLink}
          to="https://communityinviter.com/apps/cloudchiprcommunity/landing-page"
          variant="outlined"
          target="_blank"
          startIcon={<img src={SlackLogo} alt="slackLogo" width={16} />}
        >
          Contact Us
        </Button>
      </Box>
    </Stack>
  );
};
