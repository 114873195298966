import { FC } from "react";
import { useLocation } from "react-router-dom";
import AddAlertOutlinedIcon from "@mui/icons-material/AddAlertOutlined";
import { useAppSelector } from "../../../../store/hooks";
import { navigationCollapsedSelector } from "../../../../store/common/selectors/navigation/navigationCollapsedSelector";
import { NavItem } from "../nav-item/NavItem";

export const AlertsNavigation: FC = () => {
  const location = useLocation();

  const alertsSelected = location.pathname.startsWith("/alerts");

  const navigationCollapsed = useAppSelector(navigationCollapsedSelector);

  return (
    <NavItem
      to="/alerts"
      primary="Alerts"
      icon={AddAlertOutlinedIcon}
      navCollapsed={navigationCollapsed}
      selected={alertsSelected}
    />
  );
};
