import { FC, useCallback } from "react";
import { automationTimeZoneSelector } from "../../../../../../../store/automations/selectros/common/fields/automationTimeZoneSelector";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../store/hooks";
import { setAutomationData } from "../../../../../../../store/automations/automationsSlice";

import { fetchWorkflowNextRunsThunk } from "../../../../../../../store/automations/thunks/wokrflows/fetchWorkflowNextRunsThunk";
import { TimeZoneSelect } from "../../../../../../common/TimeZoneSelect";

export const AutomationTimeZone: FC = () => {
  const dispatch = useAppDispatch();
  const timeZone = useAppSelector(automationTimeZoneSelector);

  const changeHandler = useCallback(
    (timeZone: string) => {
      dispatch(setAutomationData({ timeZone }));
      dispatch(fetchWorkflowNextRunsThunk());
    },
    [dispatch],
  );

  return <TimeZoneSelect timeZone={timeZone} onChange={changeHandler} />;
};
