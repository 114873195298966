import { resourceExplorerDataSelector } from "./resourceExplorerDataSelector";
import { RootState } from "../../../../store";
import { DateDataPoint } from "../../../../../services/cloudchipr.api";

export const resourceExplorerDataPointSelector = (
  state: RootState,
): DateDataPoint | undefined => {
  const data = resourceExplorerDataSelector(state);

  return data?.data_point;
};
