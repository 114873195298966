import { resourceExplorerDataSelector } from "./resourceExplorerDataSelector";
import { RootState } from "../../../../store";
import { ForecastOption } from "../../../../../services/cloudchipr.api";

export const resourceExplorerForecastOptionSelector = (
  state: RootState,
): ForecastOption => {
  const data = resourceExplorerDataSelector(state);

  return data?.forecast_option ?? "month";
};
