import {
  ResourceExplorerFilterValue,
  ResourceExplorerFilterValues,
} from "../../../../../../services/cloudchipr.api";

export const findAdditionalSelectedValues = (
  values: string[],
  options?: ResourceExplorerFilterValues,
): ResourceExplorerFilterValues | null => {
  if (!values?.length) {
    return null;
  }

  const optionsObject =
    options?.reduce(
      (acc, option) => {
        acc[option.value] = option;
        return acc;
      },
      {} as Record<string, ResourceExplorerFilterValue>,
    ) ?? {};

  const additionalValues = values.filter((value) => !optionsObject[value]);

  if (!additionalValues?.length) {
    return null;
  }

  const firstOption = options?.at(0);

  if (!firstOption) {
    return null;
  }

  return additionalValues.map((value) => ({
    ...firstOption,
    value,
    title: value,
  }));
};
