import { RootState } from "../../../../../store";
import { CommitmentUtilizationMetadata } from "../../../../../../services/cloudchipr.api";
import { reservationDetailsDataSelector } from "../data/reservationDetailsDataSelector";

export const reservationDetailsMetaDataSelector = (
  state: RootState,
): CommitmentUtilizationMetadata | undefined => {
  return reservationDetailsDataSelector(state)
    ?.metadata as CommitmentUtilizationMetadata;
};
