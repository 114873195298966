import { createAsyncThunk } from "@reduxjs/toolkit";
import { getDailyOffHoursRecommendationsThunk } from "./getDailyOffHoursRecommendationsThunk";
import { getWeeklyOffHoursRecommendationsThunk } from "./getWeeklyOffHoursRecommendationsThunk";
import { RecommendationGroupType } from "../../../../../components/pages/recommendations/components/off-hours-recommendations/utils/types/types";

export const getOffHoursRecommendationsThunk = createAsyncThunk(
  "offHoursRecommendations/getOffHoursRecommendationsThunk",
  async (
    {
      group,
      dismissed,
    }: {
      group: RecommendationGroupType;
      dismissed?: boolean;
    },
    { dispatch },
  ) => {
    return await Promise.all([
      dispatch(
        getDailyOffHoursRecommendationsThunk({ group, dismissed }),
      ).unwrap(),
      dispatch(
        getWeeklyOffHoursRecommendationsThunk({ group, dismissed }),
      ).unwrap(),
    ]);
  },
);
