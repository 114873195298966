import { Components, Theme } from "@mui/material";

// todo: add new prop (underline:"dashed" | "solid") for typography to not set `textDecoration` styles everywhere by sx
declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    tiny: true;
  }
}
export const muiTypography: Components<Theme>["MuiTypography"] = {
  styleOverrides: {
    root: ({ ownerState }) => {
      if (ownerState?.variant === "tiny") {
        return {
          fontSize: 10,
          textUnderlineOffset: 4,
          lineHeight: 1,
        };
      }
      // todo: this added on all underlined typographies by sx, remove from them all as this is default for our application
      return { textUnderlineOffset: 4 };
    },
  },
};
