import { FC, useCallback } from "react";
import { Button, Card, IconButton, Stack, Typography } from "@mui/material";
import RocketLaunchIcon from "@mui/icons-material/RocketLaunch";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import { useLocation, useNavigate } from "react-router-dom";
import { LoadingButton } from "@mui/lab";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import Impersonation from "../../../../assets/images/impersonation.png";
import { usePatchUsersMeOrganisationsCurrentMutation } from "../../../../services/cloudchipr.api";

interface ImpersonationAlertContentProps {
  onClose?(): void;
}
export const ImpersonationAlertContent: FC<ImpersonationAlertContentProps> = ({
  onClose,
}) => {
  const navigate = useNavigate();
  const location = useLocation();

  const [patchOrg, { isLoading }] =
    usePatchUsersMeOrganisationsCurrentMutation();

  const isInSettingPage = location?.pathname?.includes("settings/organization");

  const manageSettingClickHandler = useCallback(() => {
    navigate("/settings/organization");
    onClose?.();
  }, [navigate, onClose]);

  const greatClickHandler = useCallback(async () => {
    const response = await patchOrg({
      body: { access_enabled: true },
    });

    // @ts-expect-error todo: fix api spec
    if (response?.data) {
      onClose?.();
    }
  }, [patchOrg, onClose]);

  return (
    <Card sx={{ width: 340, bgcolor: "white", position: "relative" }}>
      <img width="100%" src={Impersonation} alt="Impersonation" />

      <IconButton
        size="small"
        onClick={onClose}
        sx={{ m: 0.5, right: 0, position: "absolute" }}
      >
        <CloseOutlinedIcon />
      </IconButton>

      <Stack p={2} spacing={0.5}>
        <Typography variant="body1" fontWeight="medium">
          Access Settings
        </Typography>

        <Typography variant="body1" color="text.secondary">
          Your account representative at Cloudchipr has read-only access to the
          Cloudchipr UI to deliver faster and more effective support for you and
          your team.
        </Typography>

        <Stack direction="row" spacing={1} justifyContent="flex-end" pt={1.5}>
          {!isInSettingPage && (
            <Button
              size="small"
              onClick={manageSettingClickHandler}
              startIcon={<SettingsOutlinedIcon fontSize="small" />}
            >
              Manage In Settings
            </Button>
          )}

          <LoadingButton
            size="small"
            variant="contained"
            onClick={greatClickHandler}
            startIcon={<RocketLaunchIcon fontSize="small" />}
            loading={isLoading}
          >
            Great
          </LoadingButton>
        </Stack>
      </Stack>
    </Card>
  );
};
