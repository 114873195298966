import { costAndUsageSumSetupPropertyByKeySelector } from "./costAndUsageSumSetupPropertyByKeySelector";
import { RootState } from "../../../../store";
import { costAndUsageSumWidgetEditChangesExistSelector } from "../../widgets/cost-and-usage/costAndUsageSumWidgetEditChangesExistSelector";

export const costAndUsageSubmitButtonDisabledSelector = (
  state: RootState,
): boolean => {
  const editDisabledByChange =
    costAndUsageSumWidgetEditChangesExistSelector(state);
  const filter = costAndUsageSumSetupPropertyByKeySelector("filter")(state);
  const viewId = costAndUsageSumSetupPropertyByKeySelector("viewId")(state);
  const name = costAndUsageSumSetupPropertyByKeySelector("name")(state);
  const id = costAndUsageSumSetupPropertyByKeySelector("id")(state);

  return !filter?.length || (id && !editDisabledByChange) || !name || !viewId;
};
