import { Typography } from "@mui/material";
import { CostBreakdownWidgetTableForecastedCostHeaderCell } from "./cells/header/CostBreakdownWidgetTableForecastedCostHeaderCell";
import { CostBreakdownWidgetLabelCell } from "./cells/CostBreakdownWidgetLabelCell";
import { CostBreakdownPreviousPeriodCell } from "./cells/CostBreakdownPreviousPeriodCell";
import { CostBreakdownTrendCell } from "./cells/CostBreakdownTrendCell";
import { CostBreakdownWidgetTableLabelHeaderCell } from "./cells/header/CostBreakdownWidgetTableLabelHeaderCell";
import { CostBreakdownWidgetTableTotalCostHeaderCell } from "./cells/header/CostBreakdownWidgetTableTotalCostHeaderCell";
import { CostBreakdownWidgetTablePrevPeriodTotalCostHeaderCell } from "./cells/header/CostBreakdownWidgetTablePrevPeriodTotalCostHeaderCell";
import { CostBreakdownWidgetTableTrendHeaderCell } from "./cells/header/CostBreakdownWidgetTableTrendHeaderCell";
import { ColumnSetupType } from "../../../../../../../../../storybook/data-grid/utils/types/types";
import { ResourceExplorerGridDataWithId } from "../../../../../../../../../store/resource-explorer/utils/types/resourceExplorerDataGrid";
import { groupingsExcludingForecastedCostsColumns } from "../../../../../../../resource-explorer/components/resource-explorer-card/components/resource-explorer-data-grid/components/utils/constants/groupingsExcludingForecastedCostsColumns";
import {
  ForecastOption,
  NullableForecastedCost,
  ResourceExplorerGrouping,
} from "../../../../../../../../../services/cloudchipr.api";
import { money } from "../../../../../../../../../utils/numeral/money";
import { forecastedCostOptionLabels } from "../../../../../../../resource-explorer/components/resource-explorer-card/components/resource-explorer-metadata/components/utils/constants";
import { getForecastedCostsSortingFn } from "../../../../../../../resource-explorer/components/resource-explorer-card/components/resource-explorer-data-grid/components/columns/utils/helpers/sortingFunctions/forecastedCostsSortingFn";
import { labelColumnSortingFn } from "../../../../../../../resource-explorer/components/resource-explorer-card/components/resource-explorer-data-grid/components/columns/utils/helpers/sortingFunctions/sortingFunctions";

const getColumnsStartPart = (
  widgetId: string,
): ColumnSetupType<ResourceExplorerGridDataWithId>[] => {
  return [
    {
      size: 400,
      minSize: 150,
      accessorKey: "label",
      header: () => {
        return <CostBreakdownWidgetTableLabelHeaderCell widgetId={widgetId} />;
      },
      sortingFn: labelColumnSortingFn,
      cell: CostBreakdownWidgetLabelCell,
      meta: {
        headerTitle: "grouping",
      },
    },
    {
      minSize: 110,
      accessorKey: "total_cost",
      header: () => (
        <CostBreakdownWidgetTableTotalCostHeaderCell widgetId={widgetId} />
      ),
      cell: (cell) => {
        const val = cell.getValue() as number;

        return (
          <Typography variant="body2" align="right" pr={4}>
            {isNaN(val) ? "N / A" : money(val)}
          </Typography>
        );
      },
      meta: {
        headerTitle: "Total Cost",
      },
    },
    {
      minSize: 170,
      accessorKey: "previous_period_total_cost",
      header: () => (
        <CostBreakdownWidgetTablePrevPeriodTotalCostHeaderCell
          widgetId={widgetId}
        />
      ),
      cell: (cell) => {
        return (
          <CostBreakdownPreviousPeriodCell
            widgetId={widgetId}
            value={cell.getValue() as number | null}
          />
        );
      },
      meta: {
        headerTitle: "Previous Period Total Cost",
      },
    },
    {
      size: 100,
      accessorKey: "trend",
      header: () => (
        <CostBreakdownWidgetTableTrendHeaderCell widgetId={widgetId} />
      ),
      cell: (cell) => {
        return (
          <CostBreakdownTrendCell
            value={cell.getValue() as number | null}
            widgetId={widgetId}
          />
        );
      },
      meta: {
        headerTitle: "Trend",
      },
    },
  ];
};

interface GetCostBreakdownWidgetTableViewColumnsArgs {
  widgetId: string;
  forecastOption?: ForecastOption;
  grouping?: ResourceExplorerGrouping;
}

export const getCostBreakdownWidgetTableViewColumns = ({
  widgetId,
  forecastOption,
  grouping,
}: GetCostBreakdownWidgetTableViewColumnsArgs): ColumnSetupType<ResourceExplorerGridDataWithId>[] => {
  const startPart = getColumnsStartPart(widgetId);

  if (
    !grouping ||
    groupingsExcludingForecastedCostsColumns.has(grouping) ||
    !forecastOption
  ) {
    return startPart;
  }

  return [
    ...startPart,
    {
      minSize: 190,
      accessorKey:
        forecastOption === "month" ? "monthly_forecast" : "quarterly_forecast",
      header: () => (
        <CostBreakdownWidgetTableForecastedCostHeaderCell widgetId={widgetId} />
      ),
      meta: {
        headerTitle: `Forecasted ${forecastedCostOptionLabels.get(
          "month",
        )} Cost`,
      },
      sortingFn: getForecastedCostsSortingFn(forecastOption),
      cell: (cell) => {
        const data: NullableForecastedCost = cell.getValue();

        return (
          <Typography variant="body2" align="right" pr={4}>
            {data === null || data === undefined
              ? "-"
              : `~${money(data?.cost)}`}
          </Typography>
        );
      },
    },
  ];
};
