import { commitmentUtilizationWidgetByWidgetIdEndpointSelector } from "./commitmentUtilizationWidgetByWidgetIdEndpointSelector";
import { RootState } from "../../../../../store";

export const commitmentUtilizationWidgetByWidgetIdDataSelector = (
  state: RootState,
  widgetId: string,
) => {
  const response = commitmentUtilizationWidgetByWidgetIdEndpointSelector({
    widgetId,
  })(state);

  return response?.data;
};
