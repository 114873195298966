import {
  FC,
  forwardRef,
  HTMLAttributes,
  JSXElementConstructor,
  LegacyRef,
  SyntheticEvent,
  useCallback,
} from "react";
import {
  Autocomplete,
  AutocompleteRenderGetTagProps,
  Chip,
  MenuItem,
  Stack,
  TextField,
} from "@mui/material";
import { SelectFromEmailIntegrations } from "./SelectFromEmailIntegrations";

interface EmailsSelectProps {
  label: string;
  error?: string | string[] | null;
  selectedEmails: string[];
  allFieldsSelectedEmails: string[];
  onChange(metadata: string[]): void;
}

export const EmailsSelect: FC<EmailsSelectProps> = ({
  selectedEmails,
  error,
  onChange,
  label,
  allFieldsSelectedEmails,
}) => {
  const uniqueEmailsSelectHandler = useCallback(
    (emails: string[]) => onChange(Array.from(new Set(emails))),
    [onChange],
  );

  const changeHandler = useCallback(
    (_: SyntheticEvent | null, emails: string[]) =>
      uniqueEmailsSelectHandler(emails),
    [uniqueEmailsSelectHandler],
  );

  const blurHandler = useCallback(
    (event: any) => {
      const val = event.target.value;

      if (val) {
        changeHandler(null, [...(selectedEmails ?? []), val]);
      }
    },
    [changeHandler, selectedEmails],
  );

  const hasError = !!(Array.isArray(error) ? error.length : error);

  return (
    <Stack direction="row" spacing={2} alignItems="start">
      <Autocomplete
        multiple
        open={false}
        fullWidth
        freeSolo
        clearOnBlur
        size="small"
        value={selectedEmails ?? []}
        options={selectedEmails ?? []}
        filterSelectedOptions
        onChange={changeHandler}
        onBlur={blurHandler}
        getOptionLabel={(option) => option}
        renderTags={autoCompleteRenderTags(error)}
        ListboxComponent={ListBoxComponent}
        renderOption={(props, option: string) => (
          <MenuItem {...props} key={option} sx={{ gap: 1 }}>
            {option}
          </MenuItem>
        )}
        sx={{
          "& .MuiOutlinedInput-input": { pr: "32px !important" },
          position: "relative",
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            error={hasError}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <SelectFromEmailIntegrations
                  selectedEmails={selectedEmails}
                  onChange={uniqueEmailsSelectHandler}
                  allFieldsSelectedEmails={allFieldsSelectedEmails}
                />
              ),
            }}
            helperText={
              typeof error === "string"
                ? error
                : error?.find((error) => !!error)
            }
            label={label}
          />
        )}
      />
    </Stack>
  );
};

const autoCompleteRenderTags =
  (errors?: string[] | string | null) =>
  (value: string[], getTagProps: AutocompleteRenderGetTagProps) => {
    return value.map((option, index) => {
      const props = getTagProps({ index });

      return (
        <Chip
          label={option}
          size="small"
          variant="outlined"
          color={Array.isArray(errors) && errors[index] ? "error" : "default"}
          {...props}
          key={props.key}
        />
      );
    });
  };

const ListBoxComponent: JSXElementConstructor<HTMLAttributes<HTMLElement>> =
  forwardRef((props, ref: LegacyRef<HTMLUListElement>) => (
    <ul {...props} style={{ maxHeight: "200px" }} ref={ref} />
  ));
