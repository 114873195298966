import GridViewOutlinedIcon from "@mui/icons-material/GridViewOutlined";
import { ColumnSetupType } from "../../../../../../../storybook/data-grid/utils/types/types";
import { DashboardMixedVisibilitiesItemType } from "../../utils/types/types";
import { HierarchyAllItemsItemNameCell } from "../../../../../common/hierarchy-all-items/components/table-cells/HierarchyAllItemsItemNameCell";
import { DashboardV2ItemActionButton } from "../../../../../../navigation/components/dashboard-v2/components/item-action-menu/DashboardV2ItemActionButton";
import { UserActivityCell } from "../../../../../../common/data-grid-cells/UserActivityCell";
import { HierarchyAllItemsVisibilityCell } from "../../../../../common/hierarchy-all-items/components/table-cells/HierarchyAllItemsVisibilityCell";
import { hierarchyAllItemsCreatedBySortingFn } from "../../../../../common/hierarchy-all-items/helpers/sortingFuntions/hierarchyAllItemsCreatedBySortingFn";

export const getDashboardAllItemsTableSubRowColumns = (
  folderId: string,
): ColumnSetupType<DashboardMixedVisibilitiesItemType>[] => {
  return [
    {
      header: "",
      accessorKey: "name",
      minSize: 100,
      size: 250,
      maxSize: 450,
      cell: (cell) => {
        const item = cell.row.original;

        return (
          <HierarchyAllItemsItemNameCell
            link={`/dashboards/${item.id}`}
            name={item.name}
            visibility={item.visibility}
            icon={GridViewOutlinedIcon}
          />
        );
      },
      meta: {
        hideFromSettings: true,
        cellStyles: {
          pl: 4,
        },
      },
    },
    {
      header: "",
      accessorKey: "visibility",
      minSize: 70,
      size: 150,
      maxSize: 250,
      cell: (cell) => {
        const visibility = cell.row.original.visibility;

        return <HierarchyAllItemsVisibilityCell visibility={visibility} />;
      },
    },
    {
      header: "",
      accessorKey: "modified_at",
      minSize: 60,
      size: 150,
      maxSize: 250,
      cell: (cell) => {
        const date = cell.row.original.modified_at;
        const modified_by = cell.row.original.modified_by;

        if (!date) {
          return "-";
        }

        return (
          <UserActivityCell
            date={date}
            name={modified_by?.name ?? modified_by?.email ?? ""}
            deleted={modified_by?.name?.includes("deleted")}
          />
        );
      },
    },
    {
      header: "",
      accessorKey: "created_by",
      minSize: 60,
      size: 150,
      maxSize: 250,
      cell: (cell) => {
        const view = cell.row.original;
        const createdBy = view.created_by;

        return (
          <UserActivityCell
            date={view.created_at}
            name={createdBy?.name ?? createdBy?.email ?? ""}
            deleted={createdBy?.name?.includes("deleted")}
          />
        );
      },
      sortingFn: hierarchyAllItemsCreatedBySortingFn,
    },
    {
      id: "actions",
      accessorKey: "id",
      header: "",
      minSize: 60,
      size: 70,
      maxSize: 80,
      enableResizing: false,
      enableGlobalFilter: false,
      cell: (cell) => {
        const data = cell.row.original;

        return (
          <DashboardV2ItemActionButton
            size="medium"
            id={data.id}
            name={data.name}
            folderId={folderId}
            visibility={data.visibility}
          />
        );
      },
      meta: {
        hideFromSettings: true,
        cellStyles: {
          textAlign: "right",
        },
      },
    },
  ];
};
