import { FC } from "react";
import { Box, Typography } from "@mui/material";

export const Step0: FC = () => {
  return (
    <Box px={2}>
      <Typography variant="h5" fontWeight="medium">
        Get Started
      </Typography>

      <Typography variant="subtitle1" color="text.secondary">
        Follow the steps below to assign IAM Roles.
      </Typography>
    </Box>
  );
};
