import { FC, useMemo } from "react";
import {
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
  ReferenceLine,
  Tooltip,
  Legend,
  AreaChart,
} from "recharts";
import { CustomizedYAxisTick } from "./CustomizedYAxisTick";
import { LegendContent } from "./LegendContent";
import { tooltipFormatter } from "./tooltipFormatter";
import { CustomizedXAxisTick } from "../../../../../common/CustomizedXAxisTick";
import {
  MIN_CPU_USAGE,
  MIN_DATABASE_CONNECTIONS,
  OffHoursRecommendationsChartColors,
} from "../../../../utils/constants/constants";
import { OffHoursRecommendationsChartData } from "../../../../utils/types/types";

interface OffHoursRecommendationsChartProps {
  data: OffHoursRecommendationsChartData[];
  lineInCenter?: boolean;
  withDbConnections: boolean;
}

export const OffHoursRecommendationsChart: FC<
  OffHoursRecommendationsChartProps
> = ({ data, lineInCenter, withDbConnections }) => {
  const { cpu: maxCpu, dbConnections: maxDb } = useMemo(() => {
    return data.reduce(
      (max, val) => {
        return {
          cpu: Math.max(val.cpu || 0, max.cpu),
          dbConnections: Math.max(
            val.database_connections || 0,
            max.dbConnections,
          ),
        };
      },
      { cpu: 0, dbConnections: 0 },
    );
  }, [data]);

  const yAxisCpuTicks = useMemo(() => {
    const ticksCpu = [];
    const max = Math.max(maxCpu, 100);
    for (let i = 0; i < max + 10; i += 10) {
      ticksCpu.push(i);
    }

    return ticksCpu;
  }, [maxCpu]);

  const yAxisDbTicks = useMemo(() => {
    const ticksCpu = [];
    const max = Math.max(maxDb, 3);
    for (let i = 0; i < max + 5; i += 1) {
      ticksCpu.push(i);
    }

    return ticksCpu;
  }, [maxDb]);

  return (
    <ResponsiveContainer width="100%" height={400}>
      <AreaChart width={500} data={data}>
        <CartesianGrid
          vertical={false}
          strokeDasharray="3 3"
          opacity={0.3}
          horizontalPoints={[5, 66.8, 128.6, 190.4, 252.2]}
        />

        <XAxis
          dataKey="timestamp"
          height={60}
          interval={Math.floor(data.length / 7) - 1}
          tick={<CustomizedXAxisTick />}
        />

        {withDbConnections && (
          <YAxis
            yAxisId="right-axis"
            orientation="right"
            tick={<CustomizedYAxisTick />}
            ticks={yAxisDbTicks}
            interval={0}
          />
        )}

        <YAxis
          yAxisId="left-axis"
          tick={<CustomizedYAxisTick />}
          interval={0}
          ticks={yAxisCpuTicks}
        />

        <Tooltip
          labelFormatter={tooltipFormatter}
          itemStyle={{ display: "none" }}
          contentStyle={{ padding: "6px 8px", borderRadius: 12 }}
        />

        <Legend
          content={<LegendContent withDbConnection={withDbConnections} />}
        />

        <Area
          yAxisId="left-axis"
          stroke={OffHoursRecommendationsChartColors.CpuArea}
          type="monotone"
          dataKey="cpu"
          fillOpacity={0.5}
          fill={OffHoursRecommendationsChartColors.CpuArea}
          isAnimationActive={false}
        />

        {withDbConnections && (
          <Area
            yAxisId="right-axis"
            stroke={OffHoursRecommendationsChartColors.DbConnectionsArea}
            type="monotone"
            dataKey="database_connections"
            fillOpacity={0.5}
            fill={OffHoursRecommendationsChartColors.DbConnectionsArea}
            isAnimationActive={false}
          />
        )}

        <Area
          yAxisId="left-axis"
          isAnimationActive={false}
          type="step"
          dataKey="recommendedHours"
          fillOpacity={0.5}
          fill={OffHoursRecommendationsChartColors.HighlightedArea}
          stroke="none"
        />

        <ReferenceLine
          yAxisId="left-axis"
          y={MIN_CPU_USAGE}
          stroke={OffHoursRecommendationsChartColors.CpuLimitColor}
        />

        {withDbConnections && (
          <ReferenceLine
            yAxisId="right-axis"
            y={MIN_DATABASE_CONNECTIONS}
            stroke={OffHoursRecommendationsChartColors.DbConnectionsLimitColor}
          />
        )}

        {lineInCenter && (
          <ReferenceLine
            yAxisId="left-axis"
            x={data[Math.round(data.length / 2)].timestamp}
            stroke={OffHoursRecommendationsChartColors.HorizontalLine}
            opacity={0.2}
            label={{
              position: "insideTop",
              value: "Week",
              fill: OffHoursRecommendationsChartColors.TextColor,
              fontSize: 10,
            }}
          />
        )}
      </AreaChart>
    </ResponsiveContainer>
  );
};
