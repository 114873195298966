import { FC, useCallback } from "react";
import { SwitchWithTypography } from "../../../../../../../../../../SwitchWithTypography";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../../../../store/hooks";
import { costBreakdownSetupVisualizationPropertyByKeySelector } from "../../../../../../../../../../../store/dashboards/selectors/setups/cost-breakdown/costBreakdownSetupVisualizationPropertyByKeySelector";
import { costBreakdownViewVisualizationChangeThunk } from "../../../../../../../../../../../store/dashboards/thunks/widgets/cost-breakdown/setup-change/costBreakdownViewVisualizationChangeThunk";

export const CostBreakdownWidgetVisualizationRegionColumn: FC = () => {
  const dispatch = useAppDispatch();
  const regionColumnChecked = useAppSelector(
    costBreakdownSetupVisualizationPropertyByKeySelector("region_column"),
  );

  const handleRegionColumnCheckedChange = useCallback(
    (checked: boolean) => {
      dispatch(
        costBreakdownViewVisualizationChangeThunk({
          region_column: checked,
        }),
      );
    },
    [dispatch],
  );

  return (
    <SwitchWithTypography
      checked={regionColumnChecked}
      onChange={handleRegionColumnCheckedChange}
      title="Region"
    />
  );
};
