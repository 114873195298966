import { FC, Fragment, useCallback, useState } from "react";
import { IconButton, List, Tooltip } from "@mui/material";
import { LiveUsageMgmtNestedNavItem } from "./LiveUsageMgmtNestedNavItem";
import { LiveUsageMgmtNavItemMain } from "./LiveUsageMgmtNavItemMain";
import { IconByResourceType } from "../../../IconByResourceTypeV2";
import {
  ProviderType,
  ResourceType,
} from "../../../../../../../../services/cloudchipr.api";
import { useLiveUsageMgmtPageNavigate } from "../../../utils/hooks/useLiveUsageMgmtPageNavigate.hook";

interface LiveUsageMgmtNavItemCollapsedProps {
  resourceType: ResourceType;
  provider: ProviderType;
  actionableResourceTypes: ResourceType[];
  selected?: boolean;
  expandable?: boolean;
  selectedResourceType?: ResourceType;
}

export const LiveUsageMgmtNavItemCollapsed: FC<
  LiveUsageMgmtNavItemCollapsedProps
> = ({
  resourceType,
  selected,
  provider,
  expandable,
  actionableResourceTypes,
  selectedResourceType,
}) => {
  const handleNavigationClick = useLiveUsageMgmtPageNavigate(resourceType);

  const [open, setOpen] = useState(false);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const handleOpen = useCallback(() => {
    setOpen(true);
  }, []);

  return (
    <Tooltip
      open={open}
      onClose={handleClose}
      onOpen={handleOpen}
      disableFocusListener
      title={
        <Fragment>
          <LiveUsageMgmtNavItemMain
            resourceType={resourceType}
            provider={provider}
            expandable={expandable}
            clickable={!expandable}
          />
          {expandable && (
            <List dense>
              {actionableResourceTypes.map((rt) => (
                <LiveUsageMgmtNestedNavItem
                  resourceType={rt}
                  key={rt}
                  selected={selectedResourceType === rt}
                />
              ))}
            </List>
          )}
        </Fragment>
      }
      placement="right-start"
      slotProps={{
        tooltip: {
          sx: {
            bgcolor: (theme) => theme.palette.common.white,
            color: (theme) => theme.palette.text.primary,
            boxShadow: (theme) => theme.shadows.at(1),
            px: 0,
            minWidth: 280,
          },
        },
      }}
    >
      <IconButton
        sx={{
          mb: 1,
          ml: 1,
          borderRadius: 2,
          bgcolor: selected ? "primary.light" : undefined,
        }}
        size="small"
        onClick={handleNavigationClick}
      >
        <IconByResourceType resourceType={resourceType} primary={selected} />
      </IconButton>
    </Tooltip>
  );
};
