import { FC } from "react";
import { Button, DialogActions, Stack } from "@mui/material";
import { LoadingButton } from "@mui/lab";

interface CreateJiraTicketFormActionsProps {
  disabled: boolean;
  isLoading: boolean;
  onCancel(): void;
  onSubmit(): void;
}

export const CreateJiraTicketFormActions: FC<
  CreateJiraTicketFormActionsProps
> = ({ disabled, onCancel, onSubmit, isLoading }) => {
  return (
    <DialogActions sx={{ py: 2, px: 3 }}>
      <Stack direction="row" spacing={2} justifyContent="space-between">
        <Button disabled={isLoading} onClick={onCancel} color="tertiary">
          Cancel
        </Button>
        <LoadingButton
          onClick={onSubmit}
          disabled={disabled}
          loading={isLoading}
          variant="contained"
        >
          Create ticket
        </LoadingButton>
      </Stack>
    </DialogActions>
  );
};
