import { FC, useCallback, useEffect, useState } from "react";
import { CustomCellRendererProps } from "@ag-grid-community/react";
import { Task } from "../../../../../../services/cloudchipr.api";
import { useAppDispatch } from "../../../../../../store/hooks";
import { taskManagementUpdateTaskThunk } from "../../../../../../store/task-management/thunks/actions/taskManagementUpdateTaskThunk";
import { TaskDueDateSelection } from "../../../../common/task-management/components/common/properties/TaskDueDateSelection";

export const DueDateCellRenderer: FC<CustomCellRendererProps<Task>> = ({
  data,
}) => {
  const dispatch = useAppDispatch();
  const [date, setDate] = useState<string | null>();

  const changeHandler = useCallback(
    (value: string | null) => {
      if (!data?.id || date === value) {
        return null;
      }

      setDate(value);

      dispatch(
        taskManagementUpdateTaskThunk({
          task_ids: [data.id],
          due_date: { value },
        }),
      );
    },
    [dispatch, data?.id, date],
  );

  useEffect(() => {
    setDate(data?.due_date ?? null);
  }, [data?.due_date]);

  if (!data) {
    return null;
  }

  return <TaskDueDateSelection onChange={changeHandler} value={date} />;
};
