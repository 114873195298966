export const unaryOperators = new Set<string>([
  "exists",
  "does_not_exist",
  "is_invalid",
  "is_empty",
  "is_not_empty",
  "is_absent",
  "is_not_absent",
  "is_true",
  "is_false",
  "is_running",
  "is_stopped",
  "is_private",
  "is_public",
  "is_available",
  "is_deleted",
]);
