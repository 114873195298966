import { FC, Fragment } from "react";
import { Button } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { CategoryFormDialog } from "./CategoryFormDialog";
import { useDialog } from "../../../../../utils/hooks/useDialog.hook";

interface AddingNewCategoryProps {
  buttonTitle: string;
  disabled?: boolean;
}

export const AddingNewCategory: FC<AddingNewCategoryProps> = ({
  buttonTitle,
  disabled,
}) => {
  const { open, openDialog, closeDialog } = useDialog();

  return (
    <Fragment>
      <Button
        variant="contained"
        startIcon={<AddIcon />}
        onClick={openDialog}
        sx={{ whiteSpace: "nowrap" }}
        disabled={disabled}
      >
        {buttonTitle}
      </Button>
      <CategoryFormDialog open={open} onClose={closeDialog} />
    </Fragment>
  );
};
