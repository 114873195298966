import { FC, useCallback, useMemo } from "react";
import { Stack } from "@mui/material";
import { FormikHandlers } from "formik";
import { JiraFormSelectComponent } from "./JiraFormSelectComponent";
import { Integration } from "../../../../../../../../services/cloudchipr.api";
import { useIntegrationIssueTypes } from "../../../../../utils/hooks/useIntegrationIssueTypes.hook";
import { useIntegrationProjects } from "../../../../../utils/hooks/useIntegrationProjects.hook";

type TypesFromIntegration = Pick<
  Integration,
  | "api_token"
  | "jira_email"
  | "default_issue_type_id"
  | "default_project_key"
  | "url"
>;

export interface JiraTicketFormProps extends TypesFromIntegration {
  integrationId: string;
  issueTypeError?: string | boolean;
  projectError?: string | boolean;
  handleChange: FormikHandlers["handleChange"];
  setFieldValue(key: string, value: any): void;
}

export const JiraTicketForm: FC<JiraTicketFormProps> = ({
  jira_email,
  default_project_key,
  url,
  api_token,
  default_issue_type_id,
  issueTypeError,
  projectError,
  integrationId,
  handleChange,
  setFieldValue,
}) => {
  const { projects, projectsLoading } = useIntegrationProjects(integrationId);

  const { issues, issuesLoading } = useIntegrationIssueTypes({
    url,
    integrationId,
    email: jira_email,
    apiToken: api_token,
    projectKey: default_project_key,
  });

  const projectsOptions = useMemo(() => {
    return (
      projects?.map(({ key, name, avatar }) => ({
        value: key,
        label: name,
        imageUrl: avatar,
      })) || []
    );
  }, [projects]);

  const issueTypesOptions = useMemo(() => {
    return (
      issues?.map(({ id, name }) => ({
        value: id,
        label: name,
      })) ?? []
    );
  }, [issues]);

  const projectChangeHandler = useCallback(
    (event: any) => {
      setFieldValue("default_issue_type_id", "");
      handleChange(event);
    },
    [setFieldValue, handleChange],
  );

  return (
    <Stack spacing={4}>
      <JiraFormSelectComponent
        error={projectError}
        loading={projectsLoading}
        disabled={projectsLoading}
        options={projectsOptions}
        name="default_project_key"
        label="Default Jira Project"
        handleChange={projectChangeHandler}
        value={default_project_key}
      />

      <JiraFormSelectComponent
        error={issueTypeError}
        label="Default Ticket Type"
        name="default_issue_type_id"
        handleChange={handleChange}
        loading={issuesLoading}
        options={issueTypesOptions}
        value={default_issue_type_id}
        disabled={!default_project_key || issuesLoading}
      />
    </Stack>
  );
};
