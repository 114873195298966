import { Typography } from "@mui/material";
import { money } from "../../../../../../utils/numeral/money";
import { Account } from "../../../../../../services/cloudchipr.api";
import { ColumnSetupType } from "../../../../../../storybook/data-grid/utils/types/types";

export const getAccountsBarViewTableColumns = (
  columns: ColumnSetupType<Account>[],
): ColumnSetupType<Account>[] => {
  return columns
    .map((column) => {
      if (column.id !== "chartCell") {
        return column;
      }

      return [
        {
          accessorKey: "total_costs",
          header: "Tracked Total",
          cell: (cell) => {
            return (
              <Typography>{money((cell.getValue() ?? 0) as number)}</Typography>
            );
          },
        },
        {
          accessorKey: "ems",
          header: "Filtered resources",
          cell: (cell) => {
            return (
              <Typography color="error">
                {money((cell.getValue() ?? 0) as number)}
              </Typography>
            );
          },
        },
      ] as ColumnSetupType<Account>[];
    })
    .flat();
};
