import { FC, Fragment } from "react";

import { useEffectOnceWhen } from "rooks";
import { ResourceExplorerV2VisibilityItem } from "./ResourceExplorerV2VisibilityItem";
import { useAppDispatch, useAppSelector } from "../../../../../../store/hooks";
import { navigationResourceExplorerPublicDataSelector } from "../../../../../../store/navigation/selectors/resource-explorer/navigationResourceExplorerPublicDataSelector";
import { navigationResourceExplorerPrivateDataSelector } from "../../../../../../store/navigation/selectors/resource-explorer/navigationResourceExplorerPrivateDataSelector";
import { getResourceExplorerVisibilityHierarchyThunk } from "../../../../../../store/resource-explorer/thunks/resource-explorer-hierarchy-visibility/getResourceExplorerVisibilityHierarchyThunk";
import { useAppAbility } from "../../../../../../services/permissions";

export const ResourceExplorerV2Visibility: FC = () => {
  const { can } = useAppAbility();
  const dispatch = useAppDispatch();
  const resourceExplorerAvailable = can("view", "resource-explorer");

  const publicHierarchyData = useAppSelector(
    navigationResourceExplorerPublicDataSelector,
  );
  const privateHierarchyData = useAppSelector(
    navigationResourceExplorerPrivateDataSelector,
  );

  useEffectOnceWhen(() => {
    dispatch(getResourceExplorerVisibilityHierarchyThunk());
  }, resourceExplorerAvailable);

  return (
    <Fragment>
      <ResourceExplorerV2VisibilityItem
        data={publicHierarchyData}
        visibility="visible_to_everyone"
      />
      <ResourceExplorerV2VisibilityItem
        data={privateHierarchyData}
        visibility="visible_only_to_me"
      />
    </Fragment>
  );
};
