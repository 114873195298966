import { FC } from "react";
import { Box } from "@mui/material";

export const CommitmentsGridLoadingLayer: FC = () => {
  return (
    <Box
      position="absolute"
      width="100%"
      height="100%"
      bgcolor="grey.100"
      top={0}
      left={0}
      zIndex={2}
      sx={{ opacity: 0.5 }}
    />
  );
};
