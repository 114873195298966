import { cronToString } from "./cronToString";
import { AutomationTabType } from "../../../../../components/pages/schedule/common/utils/types/types";

export const getCronFrequency = (cron: string | null): AutomationTabType => {
  if (!cron) return "once";

  const cronStr = cronToString(cron || "");

  if (cronStr.includes("hour")) {
    return "hourly";
  }

  if (cronStr.includes(" day") && !cronStr.includes("week")) {
    return "daily";
  }

  if (cronStr.includes("day")) {
    return "weekly";
  }

  if (cron.split(" ")[2] === "*") {
    // in case "every 1 day", cronString is equal cron without days(ex. At 00:00), so we should check is day has been selected or this is a "once" cron only with hours
    return "once";
  }

  return "daily";
};
