import { FC, Fragment, memo, useCallback } from "react";
import { FormikHandlers, FormikHelpers } from "formik";
import { Grid, SelectChangeEvent } from "@mui/material";
import * as yup from "yup";
import { TagFilterKeys } from "./TagFilterKeys";
import { TagFilterValues } from "./TagFilterValues";
import { FilterItemOperatorType } from "../../FilterItemOperatorType";
import {
  FilterItem,
  Operator,
  ResourceType,
} from "../../../../../../../services/cloudchipr.api";
import { multipleValueSelectOperators } from "../../../utils/constants/filter-types/autocomplete-multipe-selection-operator-types";

export const TagFilterValueValidation = yup.object().when("operator", {
  is: (operator: string) => !["exists", "does_not_exist"].includes(operator),

  then: yup.object().when("operator", {
    is: (operator: string) => multipleValueSelectOperators.includes(operator),
    then: yup.object().shape({
      key: yup.string().required("Key is required"),
      value: yup
        .array()
        .required("Value is required")
        .min(1, "Value must have at least one item"),
    }),
    otherwise: yup.object().shape({
      key: yup.string().required("Key is required"),
      value: yup.string().required("Value is required"),
    }),
  }),

  otherwise: yup.object().shape({
    key: yup.string().required("Key is required"),
  }),
});
interface TagFilterProps {
  operators?: Operator[];
  filter: FilterItem;
  error?: any;
  setFieldValue: FormikHelpers<FilterItem>["setFieldValue"];
  onChange: FormikHandlers["handleChange"];
  accountIds?: string[];
  resourceType: ResourceType;
}

export const TagFilter: FC<TagFilterProps> = memo(
  ({
    onChange,
    resourceType,
    accountIds,
    operators,
    filter,
    setFieldValue,
    error,
  }) => {
    const filterValue = filter.value as any; // TODO: change `Tag` type in whole app

    const isValuesFilterHidden = ["exists", "does_not_exist"].includes(
      filter.operator,
    );

    const operatorChangeHandler = useCallback(
      (event: SelectChangeEvent) => {
        const operator = event.target.value;

        if (
          !multipleValueSelectOperators.includes(filter.operator) ||
          !multipleValueSelectOperators.includes(operator)
        ) {
          setFieldValue("value.value", "");
        }

        onChange(event);
      },
      [onChange, setFieldValue, filter.operator],
    );

    return (
      <Fragment>
        <FilterItemOperatorType
          value={filter.operator || ""}
          options={operators}
          onChange={operatorChangeHandler}
        />

        <Grid item md={isValuesFilterHidden ? 10 : 5}>
          <TagFilterKeys
            accountIds={accountIds}
            resourceType={resourceType}
            setFieldValue={setFieldValue}
            value={filterValue?.key || ""}
            error={!filterValue?.key?.length}
            tagValueVisible={!isValuesFilterHidden}
            disabled={!filter.key || !filter.operator}
          />
        </Grid>

        {!isValuesFilterHidden && (
          <Grid item md={5}>
            <TagFilterValues
              key={filterValue?.key}
              accountIds={accountIds}
              tagKey={filterValue?.key}
              value={filterValue?.value}
              resourceType={resourceType}
              setFieldValue={setFieldValue}
              operator={filter.operator || ""}
              error={!!error?.value?.value?.length}
              disabled={!filter.key || !filter.operator}
            />
          </Grid>
        )}
      </Fragment>
    );
  },
);
