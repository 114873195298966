import { Stack, Typography } from "@mui/material";
import { DropdownHeaderComponent } from "../../../../../../common/select/dropdown-select/utils/types/types";

export const DropdownHeader: DropdownHeaderComponent = ({ onClose }) => {
  return (
    <Stack px={2} py={1} width="100%" borderBottom={1} borderColor="grey.300">
      <Typography variant="subtitle2">Select account(s)</Typography>
    </Stack>
  );
};
