import { ChangeEvent, FC, Fragment, useCallback } from "react";
import { TextField, Typography } from "@mui/material";
import { CategoryNameAutocomplete } from "./CategoryNameAutocomplete";
import { useAppSelector } from "../../../../../store/hooks";
import { uniqueCategoryGroupNamesSelector } from "../../../../../store/categories/selectors/uniqueCategoryGroupNamesSelector";

interface CategoryNameValueProps {
  name: string;
  value: string;
  editingCategoryName?: string;
  setFieldValue(key: string, value: string): void;
}

export const CategoryNameValue: FC<CategoryNameValueProps> = ({
  name,
  value,
  setFieldValue,
  editingCategoryName,
}) => {
  const categoryNames = useAppSelector(uniqueCategoryGroupNamesSelector);

  const nameChangeHandler = useCallback(
    (name: string) => setFieldValue("name", name),
    [setFieldValue],
  );

  const valueChangeHandler = useCallback(
    (event: ChangeEvent<HTMLInputElement>) =>
      setFieldValue("value", event.target.value),
    [setFieldValue],
  );

  return (
    <Fragment>
      <div>
        <Typography variant="body2">Category Group Name</Typography>

        <CategoryNameAutocomplete
          name={name}
          options={categoryNames}
          onSelect={nameChangeHandler}
          disabled={!!editingCategoryName}
        />
      </div>

      <div>
        <Typography variant="body2">Category Name</Typography>
        <TextField
          fullWidth
          size="small"
          placeholder="e.g Engineering"
          value={value}
          sx={{ mt: 0.5 }}
          onChange={valueChangeHandler}
          type="text"
          autoFocus={!!editingCategoryName}
        />
      </div>
    </Fragment>
  );
};
