import { Row } from "@tanstack/react-table";
import { DimensionWithCosts } from "../../../../../../../../services/cloudchipr.api";
import { money } from "../../../../../../../../utils/numeral/money";

export const dimensionsTotalCostColumnFilter = (
  row: Row<DimensionWithCosts>,
  _: string,
  keyword: string,
) => {
  const data = row.original;
  const filterValue = keyword.trim();
  const filteredCost = money(data.total_cost).trim();

  if (filteredCost.includes(filterValue)) {
    return true;
  }
  if ("categories" in data) {
    return data.categories?.some((category) =>
      money(category.total_cost).includes(filterValue),
    );
  }
  return false;
};
