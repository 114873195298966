import { FC, useCallback } from "react";
import { Box, Drawer, IconButton, Stack, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {
  workflowDrawerHeaderId,
  workflowDrawerWrapperId,
} from "./constnats/common";
import { useAppDispatch, useAppSelector } from "../../../../../store/hooks";
import { automationInDrawerOpenSelector } from "../../../../../store/automations/selectros/common/automationInDrawerOpenSelector";
import { WorkflowForm } from "../single-workflow/WorkflowForm";
import { resetAutomationsState } from "../../../../../store/automations/automationsSlice";

export const CreateWorkflowDrawer: FC = () => {
  const dispatch = useAppDispatch();

  const open = useAppSelector(automationInDrawerOpenSelector);

  const closeHandler = useCallback(() => {
    dispatch(resetAutomationsState());
  }, [dispatch]);

  return (
    <Drawer
      open={open}
      anchor="bottom"
      onClose={closeHandler}
      PaperProps={{
        sx: { height: "94vh", borderRadius: 3 },
        id: workflowDrawerWrapperId,
      }}
    >
      <Stack
        px={2}
        py={1}
        top={0}
        direction="row"
        bgcolor="white"
        borderBottom={1}
        position="sticky"
        alignItems="center"
        borderColor="grey.300"
        justifyContent="space-between"
        zIndex={(theme) => theme.zIndex.appBar + 1}
      >
        <Typography variant="h6">Schedule a Workflow</Typography>

        <IconButton onClick={closeHandler}>
          <CloseIcon />
        </IconButton>
      </Stack>

      <div id={workflowDrawerHeaderId} />

      <Box mb={4}>
        <WorkflowForm />
      </Box>
    </Drawer>
  );
};
