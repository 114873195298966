import { getUsersMeWorkflowsByWorkflowIdBatchesAndBatchIdResourcesSelector } from "./getUsersMeWorkflowsByWorkflowIdBatchesAndBatchIdResourcesSelector";
import { RootState } from "../../../store";

export const protectorResourcesLoadingSelector = (
  state: RootState,
  workflowId?: string,
  batchId?: string,
): boolean => {
  if (!workflowId || !batchId) {
    return false;
  }

  const data =
    getUsersMeWorkflowsByWorkflowIdBatchesAndBatchIdResourcesSelector({
      workflowId,
      batchId,
    })(state);

  return !!data?.isLoading;
};
