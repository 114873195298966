import { createAsyncThunk } from "@reduxjs/toolkit";
import { enqueueSnackbar } from "notistack";
import { cloudChiprApi } from "../../../../services/cloudchipr.api";
import { connectedProvidersSelector } from "../../selectors/connectedProvidersSelector";
import { RootState } from "../../../store";

export const getAllProvidersBillingStatusThunk = createAsyncThunk(
  "resourceExplorer/getAllProvidersBillingStatus",
  async (_, { dispatch, getState }) => {
    const { getV2UsersMeOrganisationsCurrentProvidersByProviderBillingStatus } =
      cloudChiprApi.endpoints;

    const state = getState() as RootState;
    const providers = connectedProvidersSelector(state);

    try {
      const promises = providers.map((provider) => {
        return dispatch(
          getV2UsersMeOrganisationsCurrentProvidersByProviderBillingStatus.initiate(
            {
              provider,
            },
          ),
        ).unwrap();
      });

      Promise.all(promises);
    } catch (e) {
      // @ts-expect-error//TODO: return to this after adding error type
      const errMessage = e?.data?.message || "Something went wrong";
      enqueueSnackbar(errMessage, {
        variant: "snackbarAlert",
        AlertSnackBarProps: {
          severity: "error",
        },
      });
    }
  },
);
