import { savingsPlansDataSelector } from "./savingsPlansDataSelector";
import { savingsPlansGranularDataTotalUtilizationSelector } from "./granular/totals/savingsPlansGranularDataTotalUtilizationSelector";
import { RootState } from "../../../../store";

export const savingsPlanDataIsNotAvailableSelector = (state: RootState) => {
  const data = savingsPlansDataSelector(state);
  const totalUtilization =
    savingsPlansGranularDataTotalUtilizationSelector(state);

  return !!(data?.length && !totalUtilization?.amount);
};
