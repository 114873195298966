import { RootState } from "../../../store";
import { NavigationItemsVisibilityType } from "../../../navigation/utils/types";
import { navigationResourceExplorerDataByVisibilitySelector } from "../../../navigation/selectors/resource-explorer/navigationResourceExplorerDataByVisibilitySelector";
import { TreeItems } from "../../../../components/navigation/components/sortable-tree/utils/types";

export const resourceExplorerHierarchyVisibilityFoldersSelector = (
  state: RootState,
  visibility: NavigationItemsVisibilityType,
): TreeItems | undefined => {
  const hierarchy = navigationResourceExplorerDataByVisibilitySelector(
    state,
    visibility,
  );

  if (!hierarchy) {
    return;
  }

  return hierarchy.reduce((result, item) => {
    if (item.type === "folder") {
      result.push(item);
    }
    return result;
  }, [] as TreeItems);
};
