import { FC, useCallback } from "react";
import { useAppDispatch } from "../../../../../../../../../../store/hooks";
import { Dates } from "../../../../../../../../../../services/cloudchipr.api";
import { costBreakdownDateRangeChangeThunk } from "../../../../../../../../../../store/dashboards/thunks/widgets/cost-breakdown/setup-change/costBreakdownDateRangeChangeThunk";
import { WidgetSetupDateRangeSelect } from "../../../common/toolbar/WidgetSetupDateRangeSelect";

export const CostBreakdownWidgetSetupDateRangeSelect: FC = () => {
  const dispatch = useAppDispatch();

  const dateRangeChangeHandler = useCallback(
    (dates: Dates) => {
      dispatch(costBreakdownDateRangeChangeThunk(dates));
    },
    [dispatch],
  );

  return <WidgetSetupDateRangeSelect onDateChange={dateRangeChangeHandler} />;
};
