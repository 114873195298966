import { FC } from "react";
import { Typography } from "@mui/material";

export const SlackIntegrationActionExecutedSubTitle: FC = () => {
  return (
    <Typography variant="body1">
      Actions executed on resources by workflow{" "}
      <Typography component="span" fontWeight="medium">
        {`{{workflow name}}`}
      </Typography>
      . Please see the detailed list attached.
    </Typography>
  );
};
