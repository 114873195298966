import { FC, memo, useCallback, useEffect, useMemo, useState } from "react";
import { Box, Theme } from "@mui/material";
import { grey } from "@mui/material/colors";
import { VisibilityState } from "@tanstack/react-table";
import { useFlag } from "@unleash/proxy-client-react";
import { Toolbar } from "./Toolbar";
import { ResourceType } from "../../../../../../../services/cloudchipr.api";
import { DataGrid } from "../../../../../../../storybook/data-grid/DataGrid";
import { useAppSelector } from "../../../../../../../store/hooks";
import { ColumnSetupType } from "../../../../../../../storybook/data-grid/utils/types/types";
import { DataGridProvider } from "../../../../../../../storybook/data-grid/DataGridProvider";
import { RootState } from "../../../../../../../store/store";
import { liveUsageMgmtSelectedResourcesByResourceTypeSelector } from "../../../../../../../store/live-usage-mgmt/selectors/resource-type-data/liveUsageMgmtSelectedResourcesByResourceTypeSelector";
import {
  liveUsageMgmtSelectedResourcesByResourceTypeCsvDataSelector,
  selectedResourcesCSVDataDisabledColumns,
} from "../../../../../../../store/live-usage-mgmt/selectors/resource-type-data/liveUsageMgmtSelectedResourcesByResourceTypeCsvDataSelector";
import { generateColumns } from "../../../../../../pages/live-usage-mgmt/components/resource-card/resource-card-content/resource-card-data/resource-card-grid/resouce-card-data-grid/utils/data-grid/columns-setup/resourcesColumnsGenerator";
import { getResourceTypeColumns } from "../../../../../../pages/live-usage-mgmt/components/resource-card/resource-card-content/resource-card-data/resource-card-grid/resouce-card-data-grid/utils/data-grid/columns-setup/getResourceTypeColumns";
import { newMetricColumnsByFT } from "../../../../../../pages/live-usage-mgmt/components/resource-card/resource-card-content/resource-card-data/resource-card-grid/resouce-card-data-grid/cells/metrics-cells/utils/helpers/newMetricColumnsEnablenes";
import { ResourceDataType } from "../../../../../../../store/live-usage-mgmt/utils/types/types";

interface CreateEmailResourcesTableProps {
  resourceType: ResourceType;
  onResourcesChange(csv: Record<string, string | number>[]): void;
}

export const CreateEmailResourcesTableV2: FC<CreateEmailResourcesTableProps> =
  memo(({ resourceType, onResourcesChange }) => {
    const enableMetricsCharts = useFlag("EnableResourceSmallCharts");

    const [hiddenKeys, setHiddenKeys] = useState<string[]>([]);
    const [data, setData] = useState<ResourceDataType[] | null>(null);

    const csvSelector = useCallback(
      (state: RootState) =>
        liveUsageMgmtSelectedResourcesByResourceTypeCsvDataSelector(
          state,
          resourceType,
          hiddenKeys,
        ),
      [hiddenKeys, resourceType],
    );

    const resourcesCsv = useAppSelector(csvSelector);

    const resourcesSelector = useCallback(
      (state: RootState) =>
        liveUsageMgmtSelectedResourcesByResourceTypeSelector(
          state,
          resourceType,
        ),
      [resourceType],
    );
    const resources = useAppSelector(resourcesSelector);

    const columns = useMemo(() => {
      const resourceTypeColumns = getResourceTypeColumns(
        resourceType,
        selectedResourcesCSVDataDisabledColumns.concat(
          newMetricColumnsByFT(enableMetricsCharts),
        ),
      ) as ColumnSetupType<ResourceDataType>[];

      const columns = [...resourceTypeColumns];
      columns[0].type = "identifier";

      return generateColumns(columns);
    }, [resourceType, enableMetricsCharts]);

    const visibilityChangeHandler = useCallback(
      (visibility: VisibilityState) => {
        const hiddenKeys = Object.keys(visibility)
          .filter((key) => !visibility[key])
          .map((key) => {
            if (key.startsWith("resource.")) {
              return key.substring(9);
            }

            return key;
          });

        setHiddenKeys(hiddenKeys);
      },
      [],
    );

    useEffect(() => {
      resources && setData(resources);
    }, [resources]);

    useEffect(() => {
      resourcesCsv && onResourcesChange(resourcesCsv);
    }, [onResourcesChange, resourcesCsv]);

    if (!data) {
      return null;
    }

    return (
      <Box
        bgcolor="white"
        p={1}
        border={1}
        borderColor="grey.300"
        borderTop={0}
      >
        <Box
          border={1}
          borderRadius={1}
          bgcolor="grey.50"
          borderColor="grey.300"
        >
          <DataGridProvider>
            <DataGrid
              data={data}
              toolbar={toolbar}
              columnVisibility={{
                onColumnsVisibilityChange: visibilityChangeHandler,
              }}
              styles={styles}
              columns={columns}
            />
          </DataGridProvider>
        </Box>
      </Box>
    );
  });

const styles = {
  table: {
    borderCollapse: "separate",
    tableLayout: "fixed",
  },
  tableHeaderRow: {
    top: 0,
    position: "sticky",
    bgcolor: "grey.100",
    zIndex: (theme: Theme) => theme.zIndex.appBar - 1,
  },
  tableContainer: {
    maxHeight: 250,
    "& td": { border: "none", borderLeft: `1px solid ${grey[200]}` },
    "& tH": { borderLeft: `1px solid ${grey[200]}` },
    "& td:first-of-type": { border: "none" },
  },
};

const toolbar = { renderToolbar: Toolbar };
