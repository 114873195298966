import { SortingFn } from "@tanstack/react-table";
import { UnusedSince } from "../../../../services/cloudchipr.api";
import { compareUnusedSinceTimestamps } from "../helpers/compareUnusedSinceTimestamps";

export const sortWithUnusedSince: SortingFn<any> = (
  currentRow,
  nextRow,
  columnId,
) => {
  const current: UnusedSince = currentRow.getValue(columnId);
  const next: UnusedSince = nextRow.getValue(columnId);

  return compareUnusedSinceTimestamps(current, next) ? 1 : -1;
};
