import { tasksListGridDataSelector } from "./tasksListGridDataSelector";
import { RootState } from "../../../store";
import { formatDate } from "../../../../utils/helpers/date-time/datetime-format";
import { getResourceTypeName } from "../../../../utils/helpers/resources/getResourceTypeName";
import {
  taskSourceLabels,
  taskTargetStateLabels,
} from "../../../../components/pages/task-management/utils/constants/labels";

export const tasksListCsvSelector = (state: RootState) => {
  const data = tasksListGridDataSelector(state);

  return data?.map((task) => {
    const data: Record<string, any> = {
      Name: task.name,
      Action: task?.action?.name,
      Status: task?.status?.name,
      Assignee: `${task.assignees?.map((assignee) => assignee.name)?.join(", ") ?? "-"}`,
      Priority: task?.priority?.name,
      "Due Date": formatDate(task.due_date ?? "", { type: "date" }),
      Environment: task?.environment?.name,
      Source: taskSourceLabels[task.source],
      "Created by": task.created_by?.name || task.created_by?.email,
      "Created at": formatDate(task.created_at ?? "", { type: "date" }),
      "Modified by": task.modified_by?.name || task.modified_by?.email,
      "Modified at": formatDate(task.modified_at ?? "", { type: "date" }),
    };

    if (task.target.resource_type) {
      data["Resource State"] = taskTargetStateLabels[task.target.state];
      data["Monthly Price"] = task.target.price_per_month;
      data["Account"] = task.target.account.provider_account_name;
      data["Resource Type"] = getResourceTypeName(task.target.resource_type, {
        type: "short",
      });
      data["Tags"] =
        task.target.tags?.map((tag) => `${tag.key} ${tag.value}`)?.join(", ") ??
        "-";
    }

    return data;
  });
};
