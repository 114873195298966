import { isStringResourceType } from "./isStringResourceType";
import { getFirstParentInHierarchyResourceType } from "./getFirstParentInHierarchyResourceType";
import { ResourceType } from "../../../services/cloudchipr.api";
import {
  nestedResourcesHierarchy,
  NestedResourcesHierarchy,
} from "../../constants/resources/nestedResourcesHierarchy";

export const getAllResourcesInHierarchyByResourceType = (
  resourceType: ResourceType,
): ResourceType[] => {
  const resourceTypes: Partial<Record<ResourceType, ResourceType[]>> = {};

  const findChildrenRecursive = (
    hierarchy: NestedResourcesHierarchy,
    nestedResourceTypes: ResourceType[],
  ) => {
    Object.entries(hierarchy).forEach(([rt, children]) => {
      if (!isStringResourceType(rt)) {
        return;
      }

      if (children) {
        findChildrenRecursive(children, nestedResourceTypes.concat(rt));
      } else {
        const firstNested = nestedResourceTypes[0];

        if (!resourceTypes[firstNested]) {
          resourceTypes[firstNested] = [];
        }

        resourceTypes[firstNested]?.push(...nestedResourceTypes, rt);
      }
    });
  };

  findChildrenRecursive(nestedResourcesHierarchy, []);

  const parentType =
    getFirstParentInHierarchyResourceType(resourceType) ?? resourceType;
  const hierarchyItems = resourceTypes[parentType];

  return hierarchyItems?.length ? hierarchyItems : [resourceType];
};
