import { Chip } from "@mui/material";
import { FC } from "react";
import { IntegrationLogo } from "../../../../../../common/integration-dialogs/components/common/IntegrationLogo";
import { TypographyWithTooltip } from "../../../../../../common/TypographyWithTooltip";
import { IntegrationType } from "../../../../../integrations/components/integrations-create/components/common/integration-type/IntegrationTypeCard";

interface NotificationsInfoDataProps {
  type: IntegrationType;
  name: string;
}

export const NotificationsChip: FC<NotificationsInfoDataProps> = ({
  type,
  name,
}) => {
  return (
    <Chip
      variant="outlined"
      avatar={<IntegrationLogo width={18} type={type} />}
      sx={{ pl: 1, overflow: "hidden", width: "fit-content" }}
      size="small"
      label={<TypographyWithTooltip title={name} fontSize="inherit" />}
    />
  );
};
