import { generateDailyCron } from "./generateDailyCron";
import { generateHourlyCron } from "./generateHourlyCron";
import { generateWeeklyCron } from "./generateWeeklyCron";
import { AutomationFrequencyType } from "../../types/common";

export const generateCronForPayload = (
  frequency: AutomationFrequencyType,
  runTime: string | null,
  repeatValue?: number,
  weekDays?: number[],
) => {
  if (frequency === "hourly" && repeatValue) {
    return generateHourlyCron(repeatValue);
  }

  if (frequency === "daily" && repeatValue && runTime) {
    return generateDailyCron(repeatValue, runTime);
  }

  if (frequency === "weekly" && weekDays && runTime) {
    return generateWeeklyCron(weekDays, runTime);
  }

  return null;
};
