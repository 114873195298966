import { List, ListItem, Stack, Typography } from "@mui/material";
import { FC } from "react";

export const IntegrationUtilizationAlertCommitmentsExpirationDetails: FC =
  () => {
    return (
      <Stack gap={0.5}>
        <Typography variant="body1" fontWeight="medium">
          Details
        </Typography>
        <List sx={{ listStyleType: "disc", p: 0, m: 0 }}>
          {commitmentsExpirationDetailsData.map(({ key, value }) => (
            <ListItem sx={{ display: "list-item", p: 0, ml: 3 }} key={key}>
              <Typography variant="body1">
                {key}{" "}
                <Typography component="span" fontWeight="medium">
                  {value}
                </Typography>
              </Typography>
            </ListItem>
          ))}
        </List>
      </Stack>
    );
  };

const commitmentsExpirationDetailsData = [
  { key: "Alert Name:", value: "{{Alert Name}}" },
  { key: "Organization:", value: "{{Organization ID}}" },
  { key: "Alert Type:", value: "{{Commitments Expiration}}" },
  { key: "Commitment:", value: "{{Commitment ID}}" },
  { key: "End Date:", value: "{{14 June, 2024}}" },
];
