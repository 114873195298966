import { updateWidgetLoadingSelector } from "./updateWidgetLoadingSelector";
import { createWidgetLoadingSelector } from "./createWidgetLoadingSelector";
import { RootState } from "../../../../store";

export const buildWidgetLoadingSelector = (state: RootState): boolean => {
  const updateWidgetSelector = !!updateWidgetLoadingSelector(state);
  const createWidgetSelector = !!createWidgetLoadingSelector(state);

  return updateWidgetSelector || createWidgetSelector;
};
