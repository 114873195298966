import { FC, useCallback, useMemo } from "react";
import {
  KeyValuePair,
  ResourceExplorerDynamicFilterItemType,
  ResourceExplorerFilterProvider,
  useGetUsersMeOrganisationsCurrentResourceExplorerFiltersByFilterProviderAndFilterTypeValuesQuery as useValuesQuery,
} from "../../../../../../../../../services/cloudchipr.api";
import { TypographyWithTooltip } from "../../../../../../../../common/TypographyWithTooltip";
import { DropdownSelectOption } from "../../../../../../../../common/select/dropdown-select/utils/types/types";
import { FilterValueSelectionTrigger } from "../FilterValueSelectionTrigger";
import { DropdownSelect } from "../../../../../../../../common/select/dropdown-select/DropdownSelect";
import { FilterItemNodeWithId } from "../../../../../utils/types/common";
import { findAdditionalSelectedValues } from "../../../../../utils/helpers/findAdditionalSelectedValues";

interface FilterValueByKeyAutocompleteProps {
  value: KeyValuePair | null;
  filterType: ResourceExplorerDynamicFilterItemType;
  provider: ResourceExplorerFilterProvider;
  filterLabel: string;
  onChange(value: FilterItemNodeWithId["value"]): void;
}

export const FilterValueByKeyAutocomplete: FC<
  FilterValueByKeyAutocompleteProps
> = ({ provider, value, filterType, onChange, filterLabel }) => {
  const filterValueKey = value?.key;
  const filterValue = value?.value;

  const { data, isLoading } = useValuesQuery(
    {
      filterProvider: provider,
      filterType: filterType,
      key: filterValueKey,
    },
    { skip: !filterValueKey },
  );

  const changeHandler = useCallback(
    (value: string[]) => {
      if (!filterValueKey) {
        return;
      }

      onChange({ key: filterValueKey, value });
    },
    [onChange, filterValueKey],
  );

  const additionalValues = useMemo(() => {
    if (!filterValue) {
      return null;
    }

    return findAdditionalSelectedValues(
      Array.isArray(filterValue) ? filterValue : [filterValue],
      data,
    );
  }, [filterValue, data]);

  const renderOptions = useMemo(() => {
    const options = additionalValues
      ? [...(data ?? []), ...additionalValues]
      : data;

    return options?.map((option) => ({
      rawValue: option,
      value: option.value,
      label: <TypographyWithTooltip title={option.title ?? option.value} />,
    }));
  }, [data, additionalValues]);

  const values = typeof filterValue === "string" ? [filterValue] : filterValue;

  return (
    <DropdownSelect
      listWidth={300}
      label={`Select ${filterLabel}`}
      options={renderOptions ?? []}
      wrapperVariant="popover"
      submitHandlerOnClose={changeHandler}
      filterFn={filterFn}
      disabled={!filterValueKey || isLoading}
      values={values ?? []}
      optionsLoading={isLoading}
      TriggerComponent={FilterValueSelectionTrigger}
    />
  );
};

const filterFn = (option: DropdownSelectOption, keyword: string) => {
  const aim = `${option.rawValue.value} ${option.rawValue.title}`.toLowerCase();

  return aim.includes(keyword.toLowerCase().trim());
};
