import { FC, useMemo } from "react";
import { useDidMount } from "rooks";
import { grey } from "@mui/material/colors";
import { Box, Theme } from "@mui/material";
import { useFlag } from "@unleash/proxy-client-react";
import { LiveFilteredResourceItemToolbar } from "./LiveFilteredResourceItemToolbar";
import { getLiveFilteredResourcesColumns } from "./utils/getLiveFilteredResourcesColumns";
import { ResourceType } from "../../../../../../../../../../../../services/cloudchipr.api";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../../../../../store/hooks";
import { ColumnSetupType } from "../../../../../../../../../../../../storybook/data-grid/utils/types/types";
import { DataGridProvider } from "../../../../../../../../../../../../storybook/data-grid/DataGridProvider";
import { DataGrid } from "../../../../../../../../../../../../storybook/data-grid/DataGrid";
import { getLiveFilteredResourcesThunk } from "../../../../../../../../../../../../store/resource-explorer/thunks/live-filtered-resources/getLiveFilteredResourcesThunk";
import { liveFilteredResourcesSelector } from "../../../../../../../../../../../../store/resource-explorer/selectors/live-filtered-resources/liveFilteredResourcesSelector";
import { ToolbarConfig } from "../../../../../../../../../../../../storybook/data-grid/utils/types/prop-types";
import { getFirstParentInHierarchyResourceType } from "../../../../../../../../../../../../utils/helpers/resources/getFirstParentInHierarchyResourceType";
import { generateColumns } from "../../../../../../../../../../live-usage-mgmt/components/resource-card/resource-card-content/resource-card-data/resource-card-grid/resouce-card-data-grid/utils/data-grid/columns-setup/resourcesColumnsGenerator";
import { resourcesDataGridSubRow } from "../../../../../../../../../../live-usage-mgmt/components/resource-card/resource-card-content/resource-card-data/resource-card-grid/resouce-card-data-grid/subrow/ResourcesSubRowNF";
import { expandableResourceTypes } from "../../../../../../../../../../live-usage-mgmt/components/resource-card/resource-card-content/resource-card-data/resource-card-grid/resouce-card-data-grid/utils/data-grid/constants";
import { nfVisibilitiesConverter } from "../../../../../../../../../../live-usage-mgmt/components/resource-card/resource-card-content/resource-card-data/resource-card-grid/resouce-card-data-grid/utils/nf-tmp-helpers";
import { useColumnsVisibility } from "../../../../../../../../../../live-usage-mgmt/utils/hooks/useColumnsVisibility.hook";
import { ResourceDataType } from "../../../../../../../../../../../../store/live-usage-mgmt/utils/types/types";

interface LiveFilteredResourceItemProps {
  resourceType: ResourceType;
  accountIds: string[];
  resourceIds: string[];
  dataCount: number;
}
export const LiveFilteredResourceItem: FC<LiveFilteredResourceItemProps> = ({
  resourceIds,
  accountIds,
  resourceType,
  dataCount,
}) => {
  const dispatch = useAppDispatch();
  const enableMetricsCharts = useFlag("EnableResourceSmallCharts");

  const { visibility, setVisibility } = useColumnsVisibility(resourceType);

  const parentResourceType =
    getFirstParentInHierarchyResourceType(resourceType) ?? resourceType;

  const data = useAppSelector((state) =>
    liveFilteredResourcesSelector(state, parentResourceType),
  );

  const memoizedVisibility = useMemo(
    () => nfVisibilitiesConverter(visibility),
    [visibility],
  );

  const columnsSetup = useMemo(() => {
    const config = {
      includeExpander: expandableResourceTypes.has(parentResourceType),
      alwaysShowSortIcon: false,
    };
    const columns = getLiveFilteredResourcesColumns(parentResourceType, {
      includeFilterTemplate: true,
      enableMetricsCharts,
    });

    return generateColumns(
      columns as ColumnSetupType<ResourceDataType>[],
      config,
    );
  }, [parentResourceType, enableMetricsCharts]);

  const toolbar = useMemo((): ToolbarConfig => {
    return {
      renderToolbar: (props) => {
        return (
          <LiveFilteredResourceItemToolbar
            dataCount={data?.length}
            resourceType={parentResourceType}
            {...props}
          />
        );
      },
    };
  }, [data, parentResourceType]);

  useDidMount(() => {
    dispatch(
      getLiveFilteredResourcesThunk({
        accountIds,
        resourceType,
        resourceIds,
      }),
    );
  });

  return (
    <Box mb={dataCount === 1 ? 0 : 4}>
      <DataGridProvider>
        <DataGrid
          data={data ?? []}
          columns={columnsSetup}
          columnResizeMode="onEnd"
          columnSorting={sortingConfig}
          enableRowsVirtualization
          enableStickyColumns={!!data?.length}
          rowExpanding={{
            renderExpandedRowSubRow: resourcesDataGridSubRow,
            parentRowStyles: { bgcolor: grey[100], "& > td": { pl: 4 } },
          }}
          columnVisibility={{
            initialVisibility: memoizedVisibility,
            onColumnsVisibilityChange: setVisibility,
          }}
          toolbar={toolbar}
          styles={{
            ...styles,
            tableContainer: {
              ...styles.tableContainer,
              maxHeight: dataCount === 1 ? "80dvh" : 612,
            },
          }}
        />
      </DataGridProvider>
    </Box>
  );
};

const sortingConfig = {
  initialSort: [{ id: "resource.price_per_month", desc: true }],
};

const styles = {
  tableHeaderRow: {
    top: 0,
    position: "sticky",
    bgcolor: "grey.200",
    zIndex: (theme: Theme) => theme.zIndex.speedDial - 1,
  },
  tableRow: {
    background: "white",
  },
  tableToolbar: { px: 2 },
  tableContainer: {
    border: `1px solid ${grey[200]}`,
    borderRadius: 2,
  },
};
