import { createDraftSafeSelector } from "@reduxjs/toolkit";
import { resourceExplorerMutatedDataForDataGridSelector } from "./resourceExplorerMutatedDataForDataGridSelector";
import { ResourceExplorerGridTotal } from "../../../../../services/cloudchipr.api";

export const resourceExplorerDataGridAggregatedDataSelector =
  createDraftSafeSelector(
    [resourceExplorerMutatedDataForDataGridSelector],
    (data): ResourceExplorerGridTotal | null => {
      if (!data?.aggregatedData) {
        return null;
      }

      return data?.aggregatedData;
    },
  );
