import { FC, useCallback } from "react";
import { Button, ListItem } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

interface CreateIntegrationButtonProps {
  empty: boolean;
  onClick(): void;
}

export const CreateIntegrationButton: FC<CreateIntegrationButtonProps> = ({
  empty,
  onClick,
}) => {
  const preventCapturing = useCallback((event: any) => {
    event.stopPropagation();
    event.preventDefault();
  }, []);

  return (
    <ListItem
      sx={{
        p: 0,
        mt: 1,
        pt: 1,
        borderTop: "1px solid",
        borderColor: "grey.300",
      }}
    >
      <Button
        fullWidth
        size="large"
        variant="text"
        onClick={onClick}
        onMouseDown={preventCapturing}
        sx={{ pl: 2, justifyContent: "flex-start" }}
        startIcon={<AddIcon />}
      >
        CREATE {empty ? "FIRST" : "NEW"} INTEGRATION
      </Button>
    </ListItem>
  );
};
