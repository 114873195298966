import { FC, useMemo } from "react";
import { Stack, Typography } from "@mui/material";
import { DrawerActionsProps } from "./DrawerActions";
import { ProviderIcon } from "../../../../../../common/ProviderIcon";
import { getProviderName } from "../../../../../../../utils/helpers/providers/getProviderName";
import { useAppSelector } from "../../../../../../../store/hooks";
import { allAccountsSelectOptionsSelector } from "../../../../../../../store/accounts/selectors/all-providers/allAccountsSelectOptionsSelector";

interface WarningCardHeaderProps extends DrawerActionsProps {
  title: string;
}

export const WarningCardHeader: FC<WarningCardHeaderProps> = ({
  accountId,
  provider,
  title,
}) => {
  const groupedAllAccounts = useAppSelector(allAccountsSelectOptionsSelector);

  const accountName = useMemo(() => {
    const account = groupedAllAccounts?.find(
      ({ value }) => value === accountId,
    );

    if (!account) {
      return "";
    }

    return account.label;
  }, [groupedAllAccounts, accountId]);

  return (
    <Stack direction="row" spacing={1}>
      <ProviderIcon provider={provider} width={24} />

      <div>
        <Typography variant="subtitle1" fontWeight="medium">
          {title}
        </Typography>

        {accountName && (
          <Typography variant="subtitle2" fontWeight="normal">
            {getProviderName(provider, { capitalize: true })}:{" "}
            <Typography variant="subtitle2" component="span">
              {accountName}
            </Typography>
          </Typography>
        )}
      </div>
    </Stack>
  );
};
