import { createAsyncThunk } from "@reduxjs/toolkit";
import { enqueueSnackbar } from "notistack";
import { getProtectingResourcesMutatedData } from "./utils/helpers/getProtectingResourcesMutatedData";
import {
  cloudChiprApi,
  PatchUsersMeAccountsByAccountIdResourcesApiArg,
  ResourceType,
} from "../../../../services/cloudchipr.api";
import { getProtectResourceCacheKey } from "../../utils/helpers";

interface ProtectThunkArgs {
  resourceType: ResourceType;
  actionType: "protect" | "unprotect";
  resources?: string[];
  accountId: string;
}

export const protectV2Thunk = createAsyncThunk(
  "protectResource",
  async (
    { resourceType, actionType, resources, accountId }: ProtectThunkArgs,
    { dispatch, getState },
  ) => {
    if (!accountId || !resources?.length) {
      return;
    }

    const filteredResources = getProtectingResourcesMutatedData(
      resourceType,
      resources,
      actionType,
    );

    const { patchUsersMeAccountsByAccountIdResources } =
      cloudChiprApi.endpoints;

    if (filteredResources.length) {
      const request: PatchUsersMeAccountsByAccountIdResourcesApiArg = {
        accountId: accountId,
        body: filteredResources,
      };

      const resourceIds = request.body.reduce((result, item) => {
        if (item.id) {
          result.push(item.id);
        }
        return result;
      }, [] as string[]);
      try {
        await dispatch(
          patchUsersMeAccountsByAccountIdResources.initiate(request, {
            fixedCacheKey: getProtectResourceCacheKey(accountId, resourceIds),
          }),
        ).unwrap();

        enqueueSnackbar(
          `Protection ${actionType === "protect" ? "enabled" : "disabled"}`,
          {
            variant: "snackbarAlert",
            AlertSnackBarProps: { severity: "success" },
          },
        );
      } catch (e) {
        // @ts-expect-error TODO: return to this after adding error type
        const errMessage = e?.data?.message || "Something went wrong";
        enqueueSnackbar(errMessage, {
          variant: "snackbarAlert",
          AlertSnackBarProps: {
            severity: "error",
          },
        });
      }
    }
  },
);
