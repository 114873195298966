import { FC } from "react";
import { IconButton, Tooltip } from "@mui/material";
import { useOutletContext } from "react-router-dom";
import SidebarSvgIcon from "../../../../assets/images/icons/SidebarSvgIcon";
import { useAppSelector } from "../../../../store/hooks";
import { navigationCollapsedSelector } from "../../../../store/common/selectors/navigation/navigationCollapsedSelector";
import { OutletContextType } from "../../../layout/MainLayout";

export const NavigationExpander: FC = () => {
  const navigationCollapsed = useAppSelector(navigationCollapsedSelector);
  const { expandNavigation } = useOutletContext<OutletContextType>();

  if (!navigationCollapsed) {
    return null;
  }

  return (
    <Tooltip arrow title="Expand Sidebar">
      <IconButton size="small" onClick={expandNavigation}>
        <SidebarSvgIcon fontSize="inherit" />
      </IconButton>
    </Tooltip>
  );
};
