import { FC } from "react";
import { Stack } from "@mui/material";
import Typography from "@mui/material/Typography";
import DashboardImg from "../../../../../../assets/images/dashboard-img.png";
import { DashboardAllItemsHeaderActions } from "../../header/components/DashboardAllItemsHeaderActions";

export const DashboardAllItemsTableNoData: FC = () => {
  return (
    <Stack pt={12} spacing={4} alignItems="center" justifyContent="center">
      <img src={DashboardImg} alt="NoFilteredData" width={160} />
      <Stack spacing={2} alignItems="center" justifyContent="center">
        <Typography variant="h6" fontWeight="normal">
          Ready to get insights? Click “Add” to create your first dashboard.
        </Typography>
        <DashboardAllItemsHeaderActions />
      </Stack>
    </Stack>
  );
};
