import { FC, Fragment, ReactNode } from "react";
import { Menu, Stack } from "@mui/material";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { BreadcrumbsType } from "./Breadcrumbs";
import { BreadcrumbsItem } from "./BreadcrumbsItem";
import { useMenuHook } from "../../../../utils/hooks/useMenu.hook";

interface NestedItemsProps {
  title: ReactNode;
  items: Omit<BreadcrumbsType, "nested">[];
}

export const NestedItems: FC<NestedItemsProps> = ({ title, items }) => {
  const { anchor, open, openMenu, closeMenu } = useMenuHook();
  const ArrowIcon = open ? ArrowDropUpIcon : ArrowDropDownIcon;

  return (
    <Fragment>
      <Stack onClick={openMenu} direction="row">
        <BreadcrumbsItem title={title} />
        <ArrowIcon sx={{ color: "text.disabled", ml: 1 }} />
      </Stack>

      <Menu anchorEl={anchor} open={open} onClose={closeMenu}>
        {items.map(({ title, to }) => {
          return (
            <BreadcrumbsItem
              nested
              key={title?.toString()}
              title={title}
              to={to}
            />
          );
        })}
      </Menu>
    </Fragment>
  );
};
