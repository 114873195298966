import { Box, Stack, Typography } from "@mui/material";
import { OffHoursRecommendationsActionCell } from "./OffHoursRecommendationsActionCell";
import { AccountInfoData } from "../../../../../../common/AccountInfoData";
import { Tags } from "../../../../../../../services/cloudchipr.api";
import { ColumnSetupType } from "../../../../../../../storybook/data-grid/utils/types/types";
import { money } from "../../../../../../../utils/numeral/money";
import { TagCell } from "../../../../../../common/data-grid-cells/tag-cell/TagCell";
import {
  RecommendationDataType,
  RecommendationGroupType,
} from "../../utils/types/types";
import { getExpanderCell } from "../../../../../../../storybook/data-grid/common/getExpanderCell";
import { RecommendationsTabType } from "../OffHoursRecommendationsContent";
import { SelectAllHeaderCheckbox } from "../../../../../../../storybook/data-grid/common/SelectAllCheckboxHeaderCell";
import { capitalizeString } from "../../../../../../../utils/helpers/capitalizeString";
import { TypographyWithTooltip } from "../../../../../../common/TypographyWithTooltip";
import { RowSelectionCellCheckbox } from "../../../../../../../storybook/data-grid/common/RowSelectionCellCheckbox";
import { IdentifierCell } from "../../../../../live-usage-mgmt/components/resource-card/resource-card-content/resource-card-data/resource-card-grid/resouce-card-data-grid/cells/identifier-cell/IdentifierCell";

export const offHoursRecommendationsTableColumns = (
  group: RecommendationGroupType,
  tab: RecommendationsTabType,
): ColumnSetupType<RecommendationDataType>[] => {
  const columnsFirstPart: ColumnSetupType<RecommendationDataType>[] = [
    getExpanderCell<RecommendationDataType>(),
    {
      accessorKey: "resource_name",
      enableSorting: false,
      minSize: 200,
      meta: { cellStyles: { px: 0 } },
      header: (props) => (
        <Stack direction="row" spacing={0.5} alignItems="center">
          <SelectAllHeaderCheckbox {...props} sxProps={{ pl: 0 }} />
          {group === "compute" ? "Instance ID" : "Database ID"}
        </Stack>
      ),
      cell: ({ row, table }) => (
        <Stack direction="row" spacing={0.5} alignItems="center">
          <Box sx={{ "& span": { pl: 0 } }}>
            <RowSelectionCellCheckbox row={row} table={table} />
          </Box>

          <Box overflow="hidden">
            <IdentifierCell
              data={
                row?.original.resource_name ||
                row?.original.provider_unique_identifier
              }
            />
          </Box>
        </Stack>
      ),
    },
    {
      size: 100,
      header: "Resource",
      accessorKey: "name",
    },
    {
      accessorKey: "tags",
      header: "Tags/Labels",
      enableSorting: false,
      cell: (cell) => (
        <TagCell tags={cell.getValue() as Tags} widthToEllipsis={60} />
      ),
    },
    {
      size: 120,
      accessorKey: "potential_saving",
      header: "Est. Savings",
      meta: { alwaysShowSortIcon: true },
      cell: (cell) => money(cell.getValue() as number),
    },
    {
      accessorKey: "account",
      header: "Account",
      enableSorting: false,
      cell: (cell) => {
        const account = cell.getValue() as RecommendationDataType["account"];

        return (
          <AccountInfoData
            provider={account.provider}
            accountId={account.provider_account_id}
            accountName={account.provider_account_name}
          />
        );
      },
    },
    {
      size: 120,
      accessorKey: "type",
      header: "Resource Type",
      cell: (cell) => {
        return (
          <Typography variant="body2">{cell.getValue() as string}</Typography>
        );
      },
      enableSorting: false,
    },
  ];
  const columnsSecondPart: ColumnSetupType<RecommendationDataType>[] = [
    {
      size: 100,
      accessorKey: "frequency",
      header: "Frequency",
      enableSorting: false,
      cell: (cell) => capitalizeString((cell.getValue() ?? "") as string),
    },
    {
      size: 180,
      header: "Actions",
      id: "actions",
      cell: ({ row }) => {
        return (
          <OffHoursRecommendationsActionCell
            tab={tab}
            recommendation={row.original}
          />
        );
      },
    },
  ];

  if (tab === "active") {
    return [...columnsFirstPart, ...columnsSecondPart];
  }

  return [
    ...columnsFirstPart,
    {
      header: "Dismissed By",
      accessorKey: "dismissed_by",
      cell: (cell) => {
        return (
          <TypographyWithTooltip
            maxWidth={300}
            title={(cell.getValue() as string) ?? ""}
            variant="body2"
          />
        );
      },
    },
    ...columnsSecondPart,
  ];
};
