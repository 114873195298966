export const normalizeWeeklyCronText = (
  text: string,
  interval: number | null,
) => {
  if (!interval || interval < 2) {
    return text;
  }

  return text.replace("Weekly - ", `Every ${interval} weeks - `);
};
