import { Components, Theme } from "@mui/material";

declare module "@mui/material/Chip" {
  interface ChipPropsSizeOverrides {
    xsmall: true;
  }
}

export const muiChip: Components<Theme>["MuiChip"] = {
  styleOverrides: {
    root: ({ ownerState }) => {
      if (ownerState?.size === "xsmall") {
        return {
          minWidth: 20,
          "& .MuiChip-labelXsmall": {
            padding: "0 4px",
            fontSize: 10,
          },
          height: "20px",
        };
      }
    },
  },
};
