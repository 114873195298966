import { FC, useCallback, useState } from "react";
import {
  Alert,
  Button,
  CircularProgress,
  Stack,
  Typography,
} from "@mui/material";
import { usePatchUsersMeAccountsByAccountIdPermissionsMutation } from "../../../../../../../../services/cloudchipr.api";

interface MissingPermissionsRecheckProps {
  refetch(): void;
  forApi?: boolean;
  accountId: string;
}

export const MissingPermissionsRecheck: FC<MissingPermissionsRecheckProps> = ({
  forApi,
  refetch,
  accountId,
}) => {
  const [rechecked, setRechecked] = useState(false);

  const [recheckTrigger, { isLoading }] =
    usePatchUsersMeAccountsByAccountIdPermissionsMutation();

  const checkHandler = useCallback(async () => {
    if (accountId) {
      await recheckTrigger({ accountId });
      refetch();
      setRechecked(true);
    }
  }, [accountId, refetch, recheckTrigger]);

  if (rechecked) {
    return (
      <Alert
        variant="outlined"
        severity="warning"
        sx={{ alignItems: "center" }}
        action={
          isLoading ? (
            <Stack direction="row" spacing={1} alignItems="center">
              <CircularProgress size={18} color="warning" />
              <Typography variant="button" color="warning">
                CHECKING
              </Typography>
            </Stack>
          ) : (
            <Button
              size="small"
              variant="text"
              onClick={checkHandler}
              color="warning"
            >
              Retry
            </Button>
          )
        }
      >
        <Typography>
          {forApi
            ? "Missing APIs. Please make sure that all required APIs are enabled."
            : "Missing permissions. Please make sure that all required permissions are added."}
        </Typography>
      </Alert>
    );
  }

  return (
    <Alert
      icon={false}
      variant="outlined"
      severity="info"
      sx={{ alignItems: "center" }}
      action={
        isLoading ? (
          <Stack direction="row" spacing={1} alignItems="center">
            <CircularProgress size={18} />
            <Typography variant="button">CHECKING</Typography>
          </Stack>
        ) : (
          <Button size="small" variant="text" onClick={checkHandler}>
            Check {forApi ? "APIs" : "Permissions"}
          </Button>
        )
      }
    >
      <Typography>
        Cloudchipr will periodically check for {forApi ? "APIs" : "permissions"}
        .
      </Typography>
    </Alert>
  );
};
