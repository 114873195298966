import { FC, Fragment, useCallback } from "react";
import { CircularProgress, MenuItem } from "@mui/material";
import { MenuItemProps } from "@mui/material/MenuItem/MenuItem";
import {
  AccountId,
  usePatchUsersMeAccountsByAccountIdMutation,
} from "../../../../../../../services/cloudchipr.api";
import { useAppAbility } from "../../../../../../../services/permissions";

interface AccountAttachMenuItemProps extends MenuItemProps {
  accountId: AccountId;
}

export const AccountRetryMenuItem: FC<AccountAttachMenuItemProps> = ({
  accountId,
}) => {
  const [trigger, { isLoading }] = usePatchUsersMeAccountsByAccountIdMutation();
  const { cannot } = useAppAbility();
  const canNotCreateAccount = cannot("create", "account");

  const handleRetryClick = useCallback(() => {
    trigger({ accountId });
  }, [trigger, accountId]);

  return (
    <MenuItem onClick={handleRetryClick} disabled={canNotCreateAccount}>
      {isLoading ? (
        <Fragment>
          Retrying
          <CircularProgress size={16} disableShrink sx={{ ml: 1 }} />
        </Fragment>
      ) : (
        "Retry"
      )}
    </MenuItem>
  );
};
