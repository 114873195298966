import { FC } from "react";
import { TooltipProps } from "recharts";
import { Stack, Typography } from "@mui/material";
import { money } from "../../../../../../utils/numeral/money";
import { ChartsTooltipContentStyles } from "../../common/ChartsTooltipContent";
import { getPercentage } from "../../../../../../utils/numeral/getPercentage";

interface PieTooltipContentProps extends TooltipProps<any, any> {
  total: number;
}

export const PieTooltipContent: FC<PieTooltipContentProps> = ({
  label,
  payload,
  total,
}) => {
  return (
    <Stack sx={ChartsTooltipContentStyles} borderRadius={1}>
      <Typography variant="caption" pl={0.5}>
        {label}
      </Typography>

      {payload?.map((data) => {
        // TODO: Percentage calculations should be moved to numeral() instead manual calculation and formatting
        const percentage = getPercentage(data.payload.label, total).toFixed(2);

        return (
          <Stack
            pl={1}
            key={data.name}
            borderLeft={3}
            borderColor={data.payload.fill}
          >
            <Typography variant="caption">{data.name}</Typography>

            <Typography
              variant="caption"
              fontWeight="bold"
              color="text.primary"
            >
              {money(data.payload.label)} / {percentage}%
            </Typography>
          </Stack>
        );
      })}
    </Stack>
  );
};
