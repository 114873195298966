import momentTz from "moment-timezone";
import { getTimeZones } from "@vvo/tzdb";

export type TimeZoneObjectType = {
  tzCode: string;
  name: string;
};

export const timeZonesList = getTimeZones({ includeUtc: true }).map((tz) => {
  const ctf =
    "(UTC" + tz.currentTimeFormat.replace(` ${tz.alternativeName} - `, ") ");

  return {
    tzCode: tz.name,
    name: ctf,
  };
});

export const getTimeZoneObject = (timeZone?: string): TimeZoneObjectType => {
  const timeZoneCode = timeZone ?? momentTz.tz.guess();

  let timeZoneData = timeZonesList.find((tz) => tz?.tzCode === timeZoneCode);

  if (!timeZoneData) {
    timeZoneData = {
      name: "(UTC+00:00 Coordinated Universal Time (UTC)",
      tzCode: "Etc/UTC",
    };
  }

  return timeZoneData;
};
