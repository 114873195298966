import { FC, Fragment, useMemo } from "react";
import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query/react";
import { SerializedError } from "@reduxjs/toolkit";
import { Stack } from "@mui/material";
import { useDidMount } from "rooks";
import { InternalDashboardCommitmentUtilizationWidgetHeader } from "./components/InternalDashboardCommitmentUtilizationWidgetHeader";
import { InternalCommitmentUtilizationWidgetChartView } from "./components/InternalCommitmentUtilizationWidgetChartView";
import {
  DashboardWidgetCommitmentsUtilization,
  GetUsersMeOrganisationsCurrentWidgetsCommitmentsUtilizationByWidgetIdApiResponse,
  WidgetCommitmentsUtilizationAggregation,
} from "../../../../../../services/cloudchipr.api";
import { DashboardNotFoundWidget } from "../../../../../pages/dashboard/components/widgets/common/DashboardNotFoundWidget";
import { NoCommitmentsCard } from "../../../../../pages/dashboard/components/widgets/commitment-utlization/components/NoCommitmentsCard";
import { CommitmentUtilizationWidgetCosts } from "../../../../../pages/dashboard/components/widgets/commitment-utlization/components/CommitmentUtilizationWidgetCosts";
import { CommitmentUtilizationWidgetNumeralView } from "../../../../../pages/dashboard/components/widgets/commitment-utlization/components/CommitmentUtilizationWidgetNumeralView";
import { CommitmentUtilizationWidgetTableView } from "../../../../../pages/dashboard/components/widgets/commitment-utlization/components/CommitmentUtilizationWidgetTableView";

interface InternalDashboardCommitmentUtilizationWidgetProps
  extends DashboardWidgetCommitmentsUtilization {
  data?: GetUsersMeOrganisationsCurrentWidgetsCommitmentsUtilizationByWidgetIdApiResponse;
  isError: boolean;
  error?: FetchBaseQueryError | SerializedError;
  onWidgetMount?(): void;
}

export const InternalDashboardCommitmentUtilizationWidget: FC<
  InternalDashboardCommitmentUtilizationWidgetProps
> = ({
  data,
  error,
  isError,
  name,
  provider_organisation_id,
  commitment_type,
  grouping,
  dates,
  aggregation,
  visualization,
  onWidgetMount,
}) => {
  const status = error && "status" in error ? error?.status : 0;
  const hasAggregation = useMemo(
    () =>
      Object.keys(aggregation ?? {}).some(
        (key) =>
          !!aggregation?.[key as keyof WidgetCommitmentsUtilizationAggregation],
      ),
    [aggregation],
  );
  const chartType = visualization.chart_type;

  useDidMount(() => {
    onWidgetMount?.();
  });

  if (isError && status !== 404) {
    return;
  }

  return (
    <Stack flexGrow={1} overflow="auto">
      <InternalDashboardCommitmentUtilizationWidgetHeader
        widgetName={name}
        providerOrganisationId={provider_organisation_id}
        commitmentType={commitment_type}
        grouping={grouping}
        dates={dates}
      />

      {status === 404 && (
        <DashboardNotFoundWidget name="" label="organization" />
      )}

      {!data?.groups.length && status !== 404 && <NoCommitmentsCard />}

      {!!data?.groups.length && status !== 404 && (
        <Fragment>
          {hasAggregation && (
            <CommitmentUtilizationWidgetCosts
              data={data}
              dates={dates}
              //TODO: add quarterStartMonth
              quarterStartMonth={1}
              aggregation={aggregation}
            />
          )}

          {chartType === "numeral" && (
            <CommitmentUtilizationWidgetNumeralView data={data} />
          )}

          {chartType === "table" && (
            <CommitmentUtilizationWidgetTableView
              data={data}
              visualization={visualization}
            />
          )}

          {chartType !== "table" && chartType !== "numeral" && (
            <InternalCommitmentUtilizationWidgetChartView
              data={data}
              hasAggregation={hasAggregation}
              chartType={chartType}
              chartBase={visualization.chart_base_type}
            />
          )}
        </Fragment>
      )}
    </Stack>
  );
};
