import { generateKey } from "./generateKey";
import { ResourceExplorerDataGridView } from "../../../../../../../../store/resource-explorer/utils/types/resourceExplorerDataGrid";

export const getResourceExplorerDataGridSavedData = (
  viewId: string,
): ResourceExplorerDataGridView | undefined => {
  const key = generateKey(viewId);

  const data = localStorage.getItem(key);

  if (!data) {
    return;
  }
  return JSON.parse(data)?.viewType;
};

export const getResourceExplorerSavedDataGridVisibilities = (
  viewId: string,
): Record<string, boolean> | undefined => {
  const key = generateKey(viewId);
  const data = localStorage.getItem(key);

  if (!data) {
    return;
  }
  return JSON.parse(data)?.visibilities;
};
