import { ConvertorFnsType } from "../types";
import { UnusedSince } from "../../../../../../../../../../../../services/cloudchipr.api";

export const stateWithUnusedSinceValueGenerator: ConvertorFnsType = (
  key,
  resource,
) => {
  const data = resource[key] as UnusedSince;

  if (data?.is_used || !data?.timestamp) {
    return "In-ise";
  } else {
    return "Available | " + data?.timestamp;
  }
};
