import moment from "moment/moment";
import { formatDate } from "../../../../../../../../../../../../utils/helpers/date-time/datetime-format";
import { BudgetPeriod } from "../../../../../../../../../../../../services/cloudchipr.api";

export const generateDateLabelByBudgetPeriod = (
  startDate?: string,
  period?: BudgetPeriod,
) => {
  if (!startDate || !period) {
    return "";
  }

  let date = startDate;

  switch (period) {
    case "monthly": {
      date = formatDate(date, { format: "MMM" });
      break;
    }
    case "daily": {
      date = formatDate(date, { type: "dayMonthName" });
      break;
    }
    case "annually": {
      date = `${moment(date).get("year")}`;
      break;
    }
    case "quarterly": {
      date = `Q${moment(date).quarter()}`;
      break;
    }
  }

  return date;
};
