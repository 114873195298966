import { FC, useCallback } from "react";
import { DropdownSelect } from "../../../../../../common/select/dropdown-select/DropdownSelect";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../store/hooks";
import { setAutomationFilters } from "../../../../../../../store/automations/automationsSlice";
import { automationsActionsFilterOptionsSelector } from "../../../../../../../store/automations/selectros/workflow/filters/available/automationsActionsFilterOptionsSelector";

export const WorkflowActionsFilter: FC = () => {
  const dispatch = useAppDispatch();
  const actions = useAppSelector(automationsActionsFilterOptionsSelector);

  const options = actions.map((action) => ({
    value: action,
    hideOnly: true,
    label: labels[action],
  }));

  const optionsCount = options.length;

  const changeHandler = useCallback(
    (newFilters: string[]) => {
      dispatch(
        setAutomationFilters({
          key: "actions",
          values: newFilters.length === optionsCount ? [] : newFilters,
        }),
      );
    },
    [dispatch, optionsCount],
  );

  return (
    <DropdownSelect
      showSearch={false}
      label="Actions"
      options={options}
      submitHandlerOnClose={changeHandler}
    />
  );
};

const labels = {
  action: "Take Action",
  notify: "Notify Only",
};
