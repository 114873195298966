import { createAsyncThunk } from "@reduxjs/toolkit";
import { cloudChiprApi } from "../../../../services/cloudchipr.api";
import { RootState } from "../../../store";
import { connectedProvidersSelector } from "../../selectors/connectedProvidersSelector";
import { getAzureTagsFeatureToggleFromStorage } from "../../../../components/pages/resource-explorer/utils/helpers/azure-tags/getAzureTagsFeatureToggleFromStorage";

export const getResourceExplorerGroupingTagKeysThunk = createAsyncThunk(
  "common/getResourceExplorerGroupingTagKeys",
  async (_, { dispatch, getState }) => {
    const state = getState() as RootState;

    const providers = connectedProvidersSelector(state);
    const enableAzureTags = getAzureTagsFeatureToggleFromStorage();

    const {
      getUsersMeOrganisationsCurrentResourceExplorerFiltersByFilterProviderAndFilterTypeValues,
    } = cloudChiprApi.endpoints;

    providers.forEach((provider) => {
      if (provider === "azure" && !enableAzureTags) {
        return;
      }

      dispatch(
        getUsersMeOrganisationsCurrentResourceExplorerFiltersByFilterProviderAndFilterTypeValues.initiate(
          {
            filterProvider: provider,
            filterType: "tag",
          },
        ),
      );
    });
  },
);
