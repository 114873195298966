import { FC, useMemo } from "react";
import { Row } from "@tanstack/react-table";
import { TableRowProps } from "@mui/material";
import { usePreviousDifferent } from "rooks";
import { DataGrid } from "../../../../../../../../../storybook/data-grid/DataGrid";
import { useAppSelector } from "../../../../../../../../../store/hooks";
import { resourceExplorerGranularViewGridDataSelector } from "../../../../../../../../../store/resource-explorer/selectors/current-resource-explorer/data-grid/granular-costs-view/resourceExplorerGranularViewGridDataSelector";
import { resourceExplorerDataGridGranularViewColumns } from "../columns/setups/resourceExplorerDataGridGranularViewColumns";
import { resourceExplorerGranularViewGridColumnKeysSelector } from "../../../../../../../../../store/resource-explorer/selectors/current-resource-explorer/data-grid/granular-costs-view/resourceExplorerGranularViewGridColumnKeysSelector";
import {
  StylesProps,
  ToolbarConfig,
} from "../../../../../../../../../storybook/data-grid/utils/types/prop-types";
import { resourceExplorerGroupingSelector } from "../../../../../../../../../store/resource-explorer/selectors/current-resource-explorer/data/resourceExplorerGroupingSelector";

interface ResourceExplorerGranularViewDataGridProps extends StylesProps {
  toolbar: ToolbarConfig;
  getRowProps?: (row: Row<any>) => TableRowProps;
}

export const ResourceExplorerGranularViewDataGrid: FC<
  ResourceExplorerGranularViewDataGridProps
> = ({ toolbar, getRowProps, styles }) => {
  const gridData = useAppSelector(resourceExplorerGranularViewGridDataSelector);
  const columns = useAppSelector(
    resourceExplorerGranularViewGridColumnKeysSelector,
  );
  const grouping = useAppSelector(resourceExplorerGroupingSelector);
  const prevData = usePreviousDifferent(gridData);

  const columnsSetup = useMemo(() => {
    return resourceExplorerDataGridGranularViewColumns(grouping, columns);
  }, [columns, grouping]);

  const data = gridData ?? prevData;

  if (!data) {
    return null;
  }

  return (
    <DataGrid
      enableStickyColumns
      columnSorting
      columnVisibility
      globalFilter
      data={data}
      styles={styles}
      toolbar={toolbar}
      columns={columnsSetup}
      columnResizeMode="onEnd"
      getRowProps={getRowProps}
      enableRowsVirtualization={data.length > 30}
      enableColumnVirtualization={columns.length > 14}
    />
  );
};
