import { FC, Fragment, useCallback, useEffect, useMemo, useState } from "react";
import { Alert, FormHelperText, Stack, Typography } from "@mui/material";
import { SlackConversationSelect } from "./conversation-selection/SlackConversationSelect";
import { SlackDefaultConversationSelect } from "./default-conversation-selection/SlackDefaultConversationSelect";
import { SlackConversationLabel } from "./SlackConversationLabel";
import { DropdownSelectOption } from "../../../../../../../common/select/dropdown-select/utils/types/types";
import {
  SlackConversationResponse,
  useGetUsersMeSlackIntegrationsByIntegrationIdConversationsQuery,
} from "../../../../../../../../services/cloudchipr.api";
import {
  findSlackSelectedOptions,
  mergeSlackConversations,
} from "../utils/helpers/helpers";

interface SlackAppConnectionEditProps {
  id: string;
  name: string;
  selectedConversations?: SlackConversationResponse[] | null;
  defaultConversation?: SlackConversationResponse | null;
  setFieldValue(key: string, value: any): void;
}

export const SlackAppConnectionEdit: FC<SlackAppConnectionEditProps> = ({
  id,
  name,
  setFieldValue,
  defaultConversation,
  selectedConversations,
}) => {
  const [options, setOptions] = useState([] as DropdownSelectOption[]);
  const [search, setSearch] = useState("");

  const {
    data: conversations,
    isFetching,
    isLoading,
  } = useGetUsersMeSlackIntegrationsByIntegrationIdConversationsQuery(
    { integrationId: id, search },
    { skip: !id, refetchOnMountOrArgChange: !!search },
  );

  const value = useMemo(() => {
    return selectedConversations?.map((conversation) => conversation.id) ?? [];
  }, [selectedConversations]);

  const conversationsChangeHandler = useCallback(
    (values: string[]) => {
      const selected = findSlackSelectedOptions(options, values);
      if (
        defaultConversation?.id &&
        !values.includes(defaultConversation?.id)
      ) {
        setFieldValue("conversation", null);
      }

      setFieldValue("conversations", selected);
    },
    [options, setFieldValue, defaultConversation],
  );

  const defaultConversationChangeHandler = useCallback(
    (values: string[]) => {
      const selected = findSlackSelectedOptions(options, values);
      setFieldValue("conversation", selected.at(0));
    },
    [options, setFieldValue],
  );

  useEffect(() => {
    if (!conversations?.length) {
      return;
    }

    const uniqueConversations = mergeSlackConversations(
      selectedConversations ?? [],
      conversations,
    );

    setOptions(
      uniqueConversations.map((item) => {
        return {
          value: item.id,
          rawValue: item,
          label: <SlackConversationLabel type={item.type} name={item.name} />,
        };
      }),
    );
  }, [conversations, selectedConversations]);

  return (
    <Fragment>
      <Stack bgcolor="grey.100" p={1} borderRadius={2}>
        <Typography variant="caption" color="text.secondary">
          Workspace
        </Typography>

        {name}
      </Stack>

      <Stack>
        <SlackConversationSelect
          values={value}
          options={options}
          searchHandler={setSearch}
          optionsLoading={isLoading}
          optionsFetching={isFetching}
          onChange={conversationsChangeHandler}
        />
        <FormHelperText>
          Select Slack conversations to use for notifications and alerts. You
          can always modify it.
        </FormHelperText>

        <Alert severity="info" sx={{ mt: 1 }}>
          To use{" "}
          <Typography fontWeight="bold" component="span" fontSize="inherit">
            private channels
          </Typography>
          , you need to go into each private channel in Slack and add the
          <Typography fontWeight="bold" component="span" fontSize="inherit">
            {" "}
            @cloudchipr
          </Typography>{" "}
          app.
        </Alert>
      </Stack>

      <SlackDefaultConversationSelect
        integrationId={id}
        options={selectedConversations}
        selectedValue={defaultConversation?.id}
        onChange={defaultConversationChangeHandler}
      />
    </Fragment>
  );
};
