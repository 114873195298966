import { allAccountsGroupedByProviderAndOrgSelector } from "./all-providers/allAccountsGroupedByProviderAndOrgSelector";
import { RootState } from "../../store";
import { getProviderName } from "../../../utils/helpers/providers/getProviderName";
import { ProviderType } from "../../../services/cloudchipr.api";
import {
  noOrgAccountOrgId,
  noOrgAccountOrgName,
} from "../../../components/pages/accounts-group/utils/helpers/noOrganizationConstants";

export const liveUsageNavigationItemsSelector = (state: RootState) => {
  const allAccountsGroupedByProviderAndOrg =
    allAccountsGroupedByProviderAndOrgSelector(state);

  const items: {
    provider: ProviderType;
    org: string;
    path: string;
  }[] = [];

  if (allAccountsGroupedByProviderAndOrg) {
    Object.entries(allAccountsGroupedByProviderAndOrg).forEach(
      ([key, accountsGroupedByOrg]) => {
        const provider = key as ProviderType;
        const organizations = Object.keys(accountsGroupedByOrg);

        if (organizations.length > 1) {
          items.push(
            ...organizations.map((org) => {
              const orgName =
                org === noOrgAccountOrgId
                  ? noOrgAccountOrgName.get(provider)!
                  : org;

              return {
                provider,
                org: orgName,
                path: `/${provider}/${org}/accounts`,
              };
            }),
          );
        } else {
          const singleOrgId =
            Object.keys(accountsGroupedByOrg).at(0) ?? noOrgAccountOrgId;
          items.push({
            provider,
            org: getProviderName(provider, {
              nameAndTitle: true,
              capitalize: true,
              plural: true,
            }),
            path: `/${provider}/${singleOrgId}/accounts`,
          });
        }
      },
    );
  }

  return items;
};
