import { overviewDataSelector } from "./overviewDataSelector";
import { RootState } from "../../../store";
import { DateDetails } from "../../../../services/cloudchipr.api";

export const overviewPreviousDateDetailsSelector = (
  state: RootState,
): DateDetails | null => {
  const data = overviewDataSelector(state);

  return data?.previous_period_dates ?? null;
};
