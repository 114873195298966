import {
  ForecastOption,
  WidgetCostBreakdownVisualization,
} from "../../../../../../../../../../../services/cloudchipr.api";

export const getColumnVisibilityByVisualizationAndForecastedOption = (
  visualization?: WidgetCostBreakdownVisualization,
  forecastOption?: ForecastOption,
) => {
  return {
    previous_period_total_cost: visualization?.previous_period_cost ?? true,
    trend: visualization?.trend ?? true,
    [forecastOption === "month" ? "monthly_forecast" : "quarterly_forecast"]:
      visualization?.forecasted_cost ?? true,
  };
};
