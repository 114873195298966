import { getCurrentProvidersByProviderBillingStatusSelector } from "./getCurrentProvidersByProviderBillingStatusSelector";
import { ProviderType } from "../../../../services/cloudchipr.api";
import { RootState } from "../../../store";

export const getCurrentProvidersByProviderBillingStatusDataSelector = (
  state: RootState,
  provider: ProviderType,
) => {
  const selector = getCurrentProvidersByProviderBillingStatusSelector(
    state,
    provider,
  );

  return selector?.data;
};
