import { Route } from "react-router-dom";
import { Fragment } from "react";
import { DashboardUnauthorizedView } from "../../unauthorized-pages/dashbaord/DashboardUnauthorizedView";
import { InternalDashboard } from "../../unauthorized-pages/internal-dashboard/InternalDashboard";
import { InternalResourceExplorer } from "../../unauthorized-pages/internal-resource-explorer/InternalResourceExplorer";

export const UnauthorizedRoutes = () => {
  return (
    <Fragment>
      <Route path="/api">
        <Route
          path="dashboard/:dashboardId"
          element={<DashboardUnauthorizedView />}
        />
      </Route>

      <Route path="/internal">
        <Route path="dashboard/:dashboardId" element={<InternalDashboard />} />
        <Route
          path="resource-explorer/:viewId"
          element={<InternalResourceExplorer />}
        />
      </Route>
    </Fragment>
  );
};
