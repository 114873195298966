import { useCallback } from "react";
import { VisibilityState } from "@tanstack/react-table";
import { useLocalStorageHook } from "../../../../../../utils/hooks/useLocalStorage.hook";
import { CommitmentType } from "../../../../../../services/cloudchipr.api";
import { DataViewTab } from "../../../../../../store/commitments/utils/types/types";

export function useCommitmentsColumnsVisibility(
  commitmentType: CommitmentType,
  dataType: DataViewTab,
) {
  const { getItem, setItem } = useLocalStorageHook();

  const key = `c8r:${commitmentType}-${dataType}-data-grid-columns-visibility`;

  const visibility = (getItem(key) ?? {}) as Record<string, boolean>;

  const setVisibility = useCallback(
    (visibilities: VisibilityState) => {
      setItem(key, visibilities);
    },
    [key, setItem],
  );

  return {
    visibility,
    setVisibility,
  };
}
