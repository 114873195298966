import { resourceExplorerHierarchyMixedVisibilitiesDataSelector } from "./resourceExplorerHierarchyMixedVisibilitiesDataSelector";
import { RootState } from "../../../store";
import { VisibilityType } from "../../../../components/pages/common/hierarchy-all-items/utils/types/types";
import { filterHierarchyMixedVisibilitiesDataByVisibilityAndCreatedBy } from "../../../common/utils/helpers/filterHierarchyMixedVisibilitiesDataByVisibilityAndCreatedBy";
import { ReMixedVisibilitiesItemType } from "../../../../components/pages/resource-explorer-all-views/components/table/utils/types/types";

export const resourceExplorerHierarchyVisibilitiesDataByFilters = (
  state: RootState,
  visibilities?: VisibilityType[],
  createdBy?: string[],
) => {
  const data = resourceExplorerHierarchyMixedVisibilitiesDataSelector(state);

  return filterHierarchyMixedVisibilitiesDataByVisibilityAndCreatedBy<
    ReMixedVisibilitiesItemType[] | null
  >(data, visibilities, createdBy);
};
