import { FC, Fragment, useMemo } from "react";
import { Divider, ListSubheader, Tooltip, Typography } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { LiveUsageMgmtToolbarCleanActionItem } from "./LiveUsageMgmtToolbarCleanActionItem";
import { getResourcesCleanActionTypes } from "../../../../../../../../../../../utils/clean-options";
import { useAppSelector } from "../../../../../../../../../../../store/hooks";
import { ResourceType } from "../../../../../../../../../../../services/cloudchipr.api";
import { liveUsageMgmtSelectedResourcesAccountsAccessTypeSelector } from "../../../../../../../../../../../store/live-usage-mgmt/selectors/accounts-data/liveUsageMgmtSelectedResourcesAccountsAccessTypeSelector";
import { liveUsageMgmtAccountsVisibilitiesByResourceTypeSelector } from "../../../../../../../../../../../store/live-usage-mgmt/selectors/accounts-data/liveUsageMgmtAccountsVisibilitiesByResourceTypeSelector";
import { liveUsageMgmtSelectedResourcesByResourceTypeSelector } from "../../../../../../../../../../../store/live-usage-mgmt/selectors/resource-type-data/liveUsageMgmtSelectedResourcesByResourceTypeSelector";

interface LiveUsageMgmtToolbarCleanActionItemsProps {
  onClose(): void;
  resourceType: ResourceType;
}

export const LiveUsageMgmtToolbarCleanActionItems: FC<
  LiveUsageMgmtToolbarCleanActionItemsProps
> = ({ onClose, resourceType }) => {
  const accessType = useAppSelector((state) =>
    liveUsageMgmtSelectedResourcesAccountsAccessTypeSelector(
      state,
      resourceType,
    ),
  );

  const visibility = useAppSelector((state) =>
    liveUsageMgmtAccountsVisibilitiesByResourceTypeSelector(
      state,
      resourceType,
    ),
  );

  const selectedResources = useAppSelector((state) =>
    liveUsageMgmtSelectedResourcesByResourceTypeSelector(state, resourceType),
  );

  const isAnySelectedResourceProtected = selectedResources?.some(
    ({ resource }) => resource.is_protected,
  );

  const options = useMemo(() => {
    if (!visibility?.actions) {
      return null;
    }

    return getResourcesCleanActionTypes(resourceType).filter((option) => {
      return visibility?.actions?.[option.type] !== null;
    });
  }, [resourceType, visibility]);

  if (!options?.length) {
    return null;
  }

  return (
    <Fragment>
      <ListSubheader sx={{ lineHeight: 2 }}>
        <Typography fontSize="smaller">
          ACTIONS
          {(accessType === "read" || isAnySelectedResourceProtected) && (
            <Tooltip
              arrow
              title={
                accessType === "read"
                  ? "Please switch to Read/Write access mode to enable actions."
                  : "Actions can not be performed on protected resources."
              }
            >
              <InfoOutlinedIcon
                sx={{ verticalAlign: "middle", color: "text.secondary", ml: 1 }}
                fontSize="small"
              />
            </Tooltip>
          )}
        </Typography>
      </ListSubheader>

      {options?.map((item) => (
        <LiveUsageMgmtToolbarCleanActionItem
          item={item}
          key={item.type}
          onClose={onClose}
          resourceType={resourceType}
        />
      ))}

      <Divider />
    </Fragment>
  );
};
