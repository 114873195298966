import { Button, Link } from "@mui/material";
import { FC } from "react";
import TerraformIcon from "../../../../../assets/images/icons/terraform_icon.svg";

interface TerraformModuleLinkProps {
  terraformModuleLink: string;
}

export const TerraformModuleLink: FC<TerraformModuleLinkProps> = ({
  terraformModuleLink,
}) => {
  return (
    <Button
      variant="outlined"
      size="small"
      sx={buttonStyles}
      href={terraformModuleLink}
      target="_blank"
      component={Link}
      startIcon={<img src={TerraformIcon} alt="CFN stack" />}
    >
      Terraform Module
    </Button>
  );
};

const buttonStyles = {
  borderRadius: 2,
  fontWeight: "medium",
  textTransform: "none",
  fontSize: 10,
  color: "primary.main",
  "& .MuiButton-startIcon": {
    mr: 0.5,
  },
};
