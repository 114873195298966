import { FC } from "react";
import { Chip, Stack } from "@mui/material";
import Typography from "@mui/material/Typography";
import AttachmentOutlinedIcon from "@mui/icons-material/AttachmentOutlined";
import { ReportsCustomMessageSubject } from "./ReportsCustomMessageSubject";
import { ReportsCustomMessageBody } from "./ReportsCustomMessageBody";

import { NestedIntegration } from "../../../../../../../services/cloudchipr.api";

export interface ReportsNotificationsCustomMessageContentProps {
  type: NestedIntegration["type"];
  message: string;
}

export const ReportsNotificationsCustomMessageContent: FC<
  ReportsNotificationsCustomMessageContentProps
> = ({ type, message }) => {
  return (
    <Stack spacing={1} mt={2}>
      {type === "email" && <ReportsCustomMessageSubject />}

      <ReportsCustomMessageBody />

      <Typography
        variant="body1"
        dangerouslySetInnerHTML={{ __html: message }}
      />

      <Stack direction="row" spacing={1}>
        <Chip
          avatar={<AttachmentOutlinedIcon fontSize="small" />}
          label="Resources.csv"
        />
        <Chip
          avatar={<AttachmentOutlinedIcon fontSize="small" />}
          label="Resources.pdf"
        />
      </Stack>
    </Stack>
  );
};
