import { FC, Fragment, useCallback, useState } from "react";
import {
  Box,
  DialogContent,
  Divider,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";

import { ResourceExplorerBillingExportDialogAccessConsoleTab } from "./components/ResourceExplorerBillingExportDialogAccessConsoleTab";
import { ResourceExplorerBillingExportDialogAccessCLITab } from "./components/ResourceExplorerBillingExportDialogAccessCLITab";
import { ResourceExplorerBillingExportDialogAccessStepActions } from "./components/ResourceExplorerBillingExportDialogAccessStepActions";
import { ResourceExplorerBillingExportDialogAccessConsoleCheckPermission } from "./components/ResourceExplorerBillingExportDialogAccessConsoleCheckPermission";
import { BillingExportInitialValues } from "../../ResourceExplorerBillingExportDialog";
import { ResourceExplorerBillingExportDialogBillingStatus } from "../common/ResourceExplorerBillingExportDialogBillingStatus";
import { useAppSelector } from "../../../../../../../../../../../store/hooks";
import { resourceExplorerGcpBillingExportDataErrorSelector } from "../../../../../../../../../../../store/resource-explorer/selectors/billing/gcp-billing-data/resourceExplorerGcpBillingExportDataErrorSelector";

interface ResourceExplorerBillingExportDialogSecondStepProps {
  onCloseDialog(): void;
  onNextStep(): void;
  onPrevStep(): void;
  values: BillingExportInitialValues;
  onSuccess?(): void;
}

export const ResourceExplorerBillingExportDialogAccessStep: FC<
  ResourceExplorerBillingExportDialogSecondStepProps
> = ({ onCloseDialog, onNextStep, onPrevStep, values, onSuccess }) => {
  const [tab, setTab] = useState<"console" | "cli">("console");

  const handleTabValueChange = useCallback(
    (_: any, value: "console" | "cli") => setTab(value),
    [],
  );

  const error = useAppSelector(
    resourceExplorerGcpBillingExportDataErrorSelector,
  );

  return (
    <Fragment>
      <DialogContent sx={{ p: 4 }}>
        <Typography variant="body1" fontWeight="medium">
          Add permissions
        </Typography>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs variant="fullWidth" value={tab} onChange={handleTabValueChange}>
            <Tab value="console" label="Run Through gcp  console" />
            <Tab value="cli" label="Run Through CLI" />
          </Tabs>
        </Box>
        {tab === "console" && (
          <ResourceExplorerBillingExportDialogAccessConsoleTab />
        )}
        {tab === "cli" && (
          <ResourceExplorerBillingExportDialogAccessCLITab
            projectId={values.projectId}
          />
        )}

        <ResourceExplorerBillingExportDialogAccessConsoleCheckPermission
          values={values}
          onSuccess={onSuccess}
        />
        {error && (
          <ResourceExplorerBillingExportDialogBillingStatus
            message={error?.message}
            severity="warning"
          />
        )}
      </DialogContent>

      <Divider />

      <ResourceExplorerBillingExportDialogAccessStepActions
        onCloseDialog={onCloseDialog}
        onNextStep={onNextStep}
        onPrevStep={onPrevStep}
      />
    </Fragment>
  );
};
