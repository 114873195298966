import { createAsyncThunk } from "@reduxjs/toolkit";
import { enqueueSnackbar } from "notistack";
import { coverageRecommendationsPayloadSelector } from "../../selectors/coverage-and-utilization/details-drawer/recommendations/coverageRecommendationsPayloadSelector";
import { cloudChiprApi } from "../../../../services/cloudchipr.api";
import { RootState } from "../../../store";

export const getSavingPlansCoverageRecommendationsThunk = createAsyncThunk(
  "commitments/getSavingPlansCoverageRecommendations",
  async (_: void, { dispatch, getState }) => {
    const state = getState() as RootState;

    const { getUsersMeOrganisationsCurrentSavingsPlansRecommendations } =
      cloudChiprApi.endpoints;

    const payload = coverageRecommendationsPayloadSelector(state);
    try {
      await dispatch(
        getUsersMeOrganisationsCurrentSavingsPlansRecommendations.initiate({
          ...payload,
        }),
      ).unwrap();
    } catch (e) {
      // @ts-expect-error todo: api fix
      const errMessage = e?.data?.message || "Something went wrong";
      enqueueSnackbar(errMessage, {
        variant: "snackbarAlert",
        AlertSnackBarProps: { severity: "error" },
      });

      return null;
    }
  },
);
