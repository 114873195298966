import { FC, Fragment, useMemo } from "react";
import { Typography } from "@mui/material";
import { useAppSelector } from "../../../../../store/hooks";
import {
  EmbeddedBillingAccount,
  useGetUsersMeCategoriesQuery,
} from "../../../../../services/cloudchipr.api";
import { AccountSelect } from "../../../../common/account-select/AccountSelect";
import { allAccountsSelectOptionsSelector } from "../../../../../store/accounts/selectors/all-providers/allAccountsSelectOptionsSelector";

interface CategoriesAccountsSelectProps {
  categoryName: string;
  editingAccounts?: EmbeddedBillingAccount[];
  selectedAccounts: string[];
  onSelect(ids: string[]): void;
}

export const CategoriesAccountsSelect: FC<CategoriesAccountsSelectProps> = ({
  selectedAccounts,
  categoryName,
  onSelect,
  editingAccounts,
}) => {
  const { data } = useGetUsersMeCategoriesQuery();
  const groupedAllAccounts = useAppSelector(allAccountsSelectOptionsSelector);

  const disabledAccounts = useMemo(() => {
    if (!categoryName) return [];

    let ids =
      data
        ?.filter(({ name }) => name === categoryName)
        .map(({ accounts }) => accounts.map(({ id }) => id))
        .flat() || [];

    if (editingAccounts) {
      ids = ids.filter(
        (accountId) => !editingAccounts.some(({ id }) => id === accountId),
      );
    }

    return ids;
  }, [data, categoryName, editingAccounts]);

  const options = useMemo(() => {
    return groupedAllAccounts.map((data) => ({
      ...data,
      disabled: disabledAccounts.includes(data.value),
    }));
  }, [groupedAllAccounts, disabledAccounts]);

  return (
    <Fragment>
      <Typography variant="subtitle1" fontWeight="medium">
        Select Accounts
      </Typography>

      <div>
        <Typography variant="body2" mb={0.5}>
          Accounts
        </Typography>
        <AccountSelect
          listWidth={552}
          disabled={!categoryName}
          onChange={onSelect}
          options={options}
          selectedValue={selectedAccounts}
        />
      </div>
    </Fragment>
  );
};
