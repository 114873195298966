import { FC } from "react";
import { Button, TableCell, TableRow } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { CategoryFormDialog } from "../../../common/categories/category-adding/CategoryFormDialog";
import { useDialog } from "../../../../../utils/hooks/useDialog.hook";
import { useAppAbility } from "../../../../../services/permissions";

interface NewCategoryAddingRowProps {
  name: string;
}

export const NewCategoryAddingRow: FC<NewCategoryAddingRowProps> = ({
  name,
}) => {
  const { open, openDialog, closeDialog } = useDialog();
  const { cannot } = useAppAbility();
  const canNotEditCategory = cannot("edit", "category");
  return (
    <TableRow
      sx={{
        "& td": { borderColor: "grey.100" },
        bgcolor: "grey.50",
      }}
    >
      <TableCell width={65} />

      <TableCell colSpan={6}>
        <Button
          size="small"
          startIcon={<AddIcon />}
          onClick={openDialog}
          disabled={canNotEditCategory}
        >
          Add Category
        </Button>

        {open && (
          <CategoryFormDialog open defaultName={name} onClose={closeDialog} />
        )}
      </TableCell>
    </TableRow>
  );
};
