import { FC, Fragment, useMemo } from "react";
import { grey } from "@mui/material/colors";
import { Box, Theme } from "@mui/material";
import { getCostBreakdownWidgetSetupTableViewColumns } from "./getCostBreakdownWidgetSetupTableViewColumns";
import { useAppSelector } from "../../../../../../../../../../store/hooks";
import { DataGrid } from "../../../../../../../../../../storybook/data-grid/DataGrid";
import { widgetSetupContentChartHeight } from "../../../../../../../utils/constants/stylings";
import { costBreakdownSetupPropertyByKeySelector } from "../../../../../../../../../../store/dashboards/selectors/setups/cost-breakdown/costBreakdownSetupPropertyByKeySelector";
import { CostBreakdownWidgetSetupContentCosts } from "../CostBreakdownWidgetSetupContentCosts";
import { getColumnVisibilityByVisualizationAndForecastedOption } from "../../../../../../widgets/cost-breakdown/components/chart/table/utils/helpers/getColumnVisibilityByVisualizationAndForecastedOption";
import { costBreakdownSetupHasAggregationSelector } from "../../../../../../../../../../store/dashboards/selectors/setups/cost-breakdown/costBreakdownSetupHasAggregationSelector";
import { costBreakdownWidgetSetupNoChartViewDataSelector } from "../../../../../../../../../../store/dashboards/selectors/widgets/cost-breakdown/setup/costBreakdownWidgetSetupNoChartViewDataSelector";

export const CostBreakdownWidgetSetupTableView: FC = () => {
  const gridData = useAppSelector(
    costBreakdownWidgetSetupNoChartViewDataSelector,
  );
  const grouping = useAppSelector(
    costBreakdownSetupPropertyByKeySelector("grouping"),
  );

  const forecastOption = useAppSelector(
    costBreakdownSetupPropertyByKeySelector("forecastOption"),
  );

  const visualization = useAppSelector((state) =>
    costBreakdownSetupPropertyByKeySelector("visualization")(state),
  );

  const hasAggregations = useAppSelector(
    costBreakdownSetupHasAggregationSelector,
  );

  const columnVisibility = useMemo(
    () =>
      getColumnVisibilityByVisualizationAndForecastedOption(
        visualization ?? {},
        forecastOption,
      ),
    [visualization, forecastOption],
  );

  const columns = useMemo(() => {
    return getCostBreakdownWidgetSetupTableViewColumns({
      forecastOption,
      grouping,
    });
  }, [forecastOption, grouping]);

  return (
    <Fragment>
      {hasAggregations && (
        <Box p={2}>
          <CostBreakdownWidgetSetupContentCosts />
        </Box>
      )}
      <DataGrid
        styles={styles}
        columns={columns ?? []}
        columnSorting
        data={!gridData ? [] : gridData}
        columnVisibility={{
          initialVisibility: columnVisibility,
        }}
        enableRowsVirtualization={gridData?.length > 30}
      />
    </Fragment>
  );
};

const styles = {
  table: {
    borderCollapse: "separate",
    tableLayout: "fixed",
    "& tr:hover td": { bgcolor: grey[100] },
    "& tr td": { borderColor: grey[100], py: 0.5, pl: 2 },
  },

  tableHeaderRow: {
    "& th": {
      p: 1,
      verticalAlign: "inherit",
      bgcolor: grey[100],
    },
    top: 0,
    position: "sticky",
    zIndex: (theme: Theme) => theme.zIndex.speedDial - 1,
  },
  tableRow: {
    height: 40,
  },

  tableContainer: {
    border: 1,
    borderColor: grey[200],
    maxHeight: `${widgetSetupContentChartHeight}px`,
  },
};
