import { createAsyncThunk } from "@reduxjs/toolkit";
import { WidgetType } from "../../../../../services/cloudchipr.api";
import { editCostAndUsageSumWidgetThunk } from "../cost-and-usage/actions/editCostAndUsageSumWidgetThunk";
import { editLargestCostChangesWidgetThunk } from "../largest-cost-changes/actions/editLargestCostChangesWidgetThunk";
import { editCommitmentUtilizationChangesWidgetThunk } from "../commitment-utilization/actions/editCommitmentUtilizationChangesWidgetThunk";
import { editCostBreakdownWidgetThunk } from "../cost-breakdown/actions/editCostBreakdownWidgetThunk";

interface EditWidgetThunkArgs {
  widgetId: string;
  widgetType: WidgetType;
}
export const editWidgetThunk = createAsyncThunk(
  "dashboards/editWidget",
  ({ widgetId, widgetType }: EditWidgetThunkArgs, { dispatch }) => {
    if (widgetType === "cost_breakdown") {
      dispatch(editCostBreakdownWidgetThunk(widgetId));
    }
    if (widgetType === "cost_and_usage_summary") {
      dispatch(editCostAndUsageSumWidgetThunk(widgetId));
    }
    if (widgetType === "largest_cost_changes") {
      dispatch(editLargestCostChangesWidgetThunk(widgetId));
    }
    if (widgetType === "commitments_utilization") {
      dispatch(editCommitmentUtilizationChangesWidgetThunk(widgetId));
    }
  },
);
