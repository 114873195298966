import { createDraftSafeSelector } from "@reduxjs/toolkit";
import { v4 as uuid } from "uuid";
import { commitmentsRecommendationsDataSelector } from "./commitmentsRecommendationsDataSelector";
import { SavingsPlanRecommendation } from "../../../../../services/cloudchipr.api";
import { commitmentsRecommendationsFiltersSelector } from "../filters/commitmentsRecommendationsFiltersSelector";
import { filterSavingsPlansRecommendationsByFilters } from "../../../utils/helpers/filterSavingsPlansRecommendationsByFilters";

export const commitmentsRecommendationsFilteredDataSelector =
  createDraftSafeSelector(
    [
      commitmentsRecommendationsDataSelector,
      commitmentsRecommendationsFiltersSelector,
    ],
    (
      data,
      filters,
    ): (SavingsPlanRecommendation & { id: string })[] | undefined => {
      const filteredData = filterSavingsPlansRecommendationsByFilters(
        filters,
        data?.recommendations?.map((rec) => ({ ...rec, id: uuid() })),
      );

      if (filteredData) {
        return filteredData as (SavingsPlanRecommendation & { id: string })[];
      }
    },
  );
