import { FC, Fragment } from "react";
import { Button, Typography } from "@mui/material";
import FormatIndentIncreaseIcon from "@mui/icons-material/FormatIndentIncrease";
import { LiveFilteredResourcesDrawer } from "./components/live-filtered-resources-drawer/LiveFilteredResourcesDrawer";
import { useMenuHook } from "../../../../../../../../../utils/hooks/useMenu.hook";

interface LiveFilteredResourcesDrawerOpenButtonProps {
  hovered: boolean;
  loadFrom: "widget" | "resource-explorer";
}
export const LiveFilteredResourcesDrawerOpenButton: FC<
  LiveFilteredResourcesDrawerOpenButtonProps
> = ({ hovered, loadFrom }) => {
  const { open, openMenu, closeMenu } = useMenuHook();

  return (
    <Fragment>
      {hovered && (
        <Button
          size="small"
          variant="contained"
          onClick={openMenu}
          startIcon={<FormatIndentIncreaseIcon color="primary" sx={iconSx} />}
          disableRipple
          sx={{
            ...buttonSx,
          }}
        >
          <Typography variant="caption" color="primary.main">
            Open
          </Typography>
        </Button>
      )}
      <LiveFilteredResourcesDrawer
        open={open}
        onClose={closeMenu}
        loadFrom={loadFrom}
      />
    </Fragment>
  );
};

const buttonSx = {
  bgcolor: "primary.light",
  "&:hover": {
    bgcolor: "primary.light",
  },
  px: 0.5,
  py: 0.25,
  position: "absolute",
  right: 7,
};

const iconSx = {
  fontSize: 9,
  width: 12,
  height: 12,
  color: "primary.main",
};
