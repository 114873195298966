import { FC } from "react";
import { ForecastOption } from "../../../../../../../../../../../services/cloudchipr.api";
import { useAppSelector } from "../../../../../../../../../../../store/hooks";
import { resourceExplorerForecastedCostByForecastOptionSelector } from "../../../../../../../../../../../store/resource-explorer/selectors/current-resource-explorer/forecasted-cost/resourceExplorerForecastedCostByForecastOptionSelector";
import { ForecastedCostHeaderCell } from "../common/ForecastedCostHeaderCell";

interface ResourceExplorerForecastedCostHeaderCellProps {
  forecastOption: ForecastOption;
}
export const ResourceExplorerForecastedCostHeaderCell: FC<
  ResourceExplorerForecastedCostHeaderCellProps
> = ({ forecastOption }) => {
  const forecastedCost = useAppSelector((state) =>
    resourceExplorerForecastedCostByForecastOptionSelector(
      state,
      forecastOption,
    ),
  );

  return (
    <ForecastedCostHeaderCell
      forecastedCost={forecastedCost}
      forecastOption={forecastOption}
    />
  );
};
