import { FC } from "react";
import { Link, Stack, Typography } from "@mui/material";
import { ExecutionLogV3 } from "../../../../../../services/cloudchipr.api";
import { TypographyWithTooltip } from "../../../../../common/TypographyWithTooltip";
import { useAppSelector } from "../../../../../../store/hooks";
import { getExecutionLogsExecutorData } from "../../../utils/helpers/getExecutionLogsExecutorData";
import { executionLogAutomationExistSelector } from "../../../../../../store/execution-log/selectors/executionLogAutomationExistSelector";

interface ExecutionLogsDataGridSourceCellProps {
  source: ExecutionLogV3["source"];
}

export const ExecutionLogsDataGridSourceCell: FC<
  ExecutionLogsDataGridSourceCellProps
> = ({ source }) => {
  const automationExist = useAppSelector((state) =>
    executionLogAutomationExistSelector(state, source?.metadata?.id),
  );

  const { url, name, scheduleType, sourceName } = getExecutionLogsExecutorData(
    source,
    automationExist,
  );

  return (
    <Stack direction="row" spacing={1} alignItems="center">
      {scheduleType && (
        <Typography variant="body2" sx={{ textTransform: "capitalize" }}>
          {sourceName}
          {name && ":"}
        </Typography>
      )}

      <TypographyWithTooltip
        variant="body2"
        placement="top"
        title={
          url ? (
            <Link
              href={url}
              color="inherit"
              fontWeight="medium"
              target="_blank"
            >
              {name}
            </Link>
          ) : (
            name || "N/A"
          )
        }
      />
    </Stack>
  );
};
