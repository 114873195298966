import { FC, Fragment, memo, useCallback, useState } from "react";
import {
  FormHelperText,
  Grid,
  ListItemText,
  MenuItem,
  Select,
  Stack,
} from "@mui/material";
import { selectRenderValue } from "./selectRenderValue";
import {
  CustomFieldValueType,
  JiraOptionsChild,
  JiraOptionWithChildren,
} from "../../../../utils/types/types";

interface NestedSelectFieldProps {
  name: string;
  error?: string;
  label: string;
  value?: CustomFieldValueType;
  options: JiraOptionWithChildren[] | null;
  setFieldValue(key: string, value: any): void;
}

export const NestedSelectField: FC<NestedSelectFieldProps> = memo(
  ({ value, setFieldValue, name, options, label, error }) => {
    const primaryValue =
      (typeof value === "object" && "id" in value && value.id) || "";
    const secondaryValue =
      (typeof value === "object" && "child" in value && value.child?.id) || "";

    const [childOptions, setChildOptions] = useState<JiraOptionsChild[]>([]);

    const renderValue = useCallback(
      (selected: any) => selectRenderValue(selected, options),
      [options],
    );
    const childRenderValue = useCallback(
      (selected: any) => selectRenderValue(selected, childOptions),
      [childOptions],
    );

    const primaryChangeHandler = useCallback(
      (event: any) => {
        const val = event.target.value;
        setFieldValue(name, { id: val, child: null });

        setChildOptions(options?.find(({ id }) => id === val)?.children || []);
      },
      [setFieldValue, name, options],
    );

    const secondaryChangeHandler = useCallback(
      (event: any) => {
        const id = event.target.value;

        setFieldValue(name, { id: primaryValue, child: { id } });
      },
      [setFieldValue, primaryValue, name],
    );

    return (
      <Fragment>
        <Grid item sm={4}>
          <Stack spacing={0.5}>
            <Select
              fullWidth
              name={name}
              size="small"
              placeholder={label}
              value={primaryValue}
              renderValue={renderValue}
              onChange={primaryChangeHandler}
            >
              {options?.map((option) => {
                return (
                  <MenuItem value={option.id} key={option.id}>
                    <ListItemText primary={option.name ?? option.value} />
                  </MenuItem>
                );
              })}
            </Select>

            {!!childOptions.length && (
              <Select
                fullWidth
                name={name}
                size="small"
                placeholder={label}
                value={secondaryValue}
                disabled={!primaryValue}
                renderValue={childRenderValue}
                onChange={secondaryChangeHandler}
              >
                {childOptions?.map((option) => {
                  return (
                    <MenuItem value={option.id} key={option.id}>
                      <ListItemText primary={option.name ?? option.value} />
                    </MenuItem>
                  );
                })}
              </Select>
            )}
            {!!error && <FormHelperText error>{error}</FormHelperText>}
          </Stack>
        </Grid>
        <Grid item sm={6} />
      </Fragment>
    );
  },
);
