import { FC } from "react";

import { IconButton, Stack, Typography } from "@mui/material";
import { Close } from "@mui/icons-material";

interface TagFilterDropdownSelectPopoverHeaderProps {
  onCloseIconClick(): void;
  title: string;
}
export const TagFilterDropdownSelectPopoverHeader: FC<
  TagFilterDropdownSelectPopoverHeaderProps
> = ({ onCloseIconClick, title }) => {
  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      pl={3}
      pr={2}
      py={1.5}
    >
      <Typography color="text.primary" variant="subtitle1" fontWeight="medium">
        {title}
      </Typography>
      <IconButton onClick={onCloseIconClick}>
        <Close />
      </IconButton>
    </Stack>
  );
};
