import { liveUsageMgmtFilterTemplatesSelector } from "./liveUsageMgmtFilterTemplatesSelector";
import { RootState } from "../../store";

export const liveUsageMgmtFilterTemplateNameByIdSelector = (
  state: RootState,
  id: string,
): string | undefined => {
  const filterTemplates = liveUsageMgmtFilterTemplatesSelector(state);

  return filterTemplates?.find((item) => item.id === id)?.name;
};
