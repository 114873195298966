import { orgCurrentRoleByIdSelector } from "./orgCurrentRoleByIdSelector";
import { RootState } from "../../../../store";
import { Role } from "../../../../../services/cloudchipr.api";
import { userRoleDataIdSelector } from "../userRoleDataIdSelector";

export const userRoleDataByIdSelector = (
  state: RootState,
): Role | undefined => {
  const id = userRoleDataIdSelector(state);
  if (!id) {
    return;
  }
  return orgCurrentRoleByIdSelector({
    roleId: id,
  })(state)?.data;
};
