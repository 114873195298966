import { FC, useCallback, useMemo } from "react";
import { Box } from "@mui/material";
import { usePreviousDifferent } from "rooks";
import { utilizationAndCoverageDrawerDataGridColumns } from "./columns/utilizationAndCoverageDrawerDataGridColumns";
import { CommitmentsDrawerDataGridToolbar } from "../../../../../common/components/drawer/data-grid/CommitmentsDrawerDataGridToolbar";
import { DataGrid } from "../../../../../../../../storybook/data-grid/DataGrid";
import { ToolbarConfig } from "../../../../../../../../storybook/data-grid/utils/types/prop-types";
import { useAppSelector } from "../../../../../../../../store/hooks";
import { utilizationAndCoverageDetailsCoverageDataSelector } from "../../../../../../../../store/commitments/selectors/coverage-and-utilization/details-drawer/data/utilizationAndCoverageDetailsCoverageDataSelector";
import { commitmentsDetailsDrawerCoverageGroupingSelector } from "../../../../../../../../store/commitments/selectors/common/details-drawer/commitmentsDetailsDrawerCoverageGroupingSelector";
import { utilizationAndCoverageDetailsLoadingSelector } from "../../../../../../../../store/commitments/selectors/coverage-and-utilization/details-drawer/data/utilizationAndCoverageDetailsLoadingSelector";
import { CommitmentsGridLoadingLayer } from "../../../../../common/components/data-grid/CommitmentsGridLoadingLayer";
import { DataViewTab } from "../../../../../../../../store/commitments/utils/types/types";
import { EmptyDataGridFooter } from "../../../../../common/components/EmptyDataGridFooter";
import { getCommitmentsTableStyles } from "../../../../../common/utils/helpers/grid/getCommitmentsTableStyles";
import { coverageGroupingNames } from "../../../../../common/utils/constants/labels";

interface UtilizationAndCoverageDrawerDataGridProps {
  viewTab: DataViewTab;
}
export const UtilizationAndCoverageDrawerDataGrid: FC<
  UtilizationAndCoverageDrawerDataGridProps
> = ({ viewTab }) => {
  const data = useAppSelector(
    utilizationAndCoverageDetailsCoverageDataSelector,
  );
  const loading = useAppSelector(utilizationAndCoverageDetailsLoadingSelector);
  const grouping = useAppSelector(
    commitmentsDetailsDrawerCoverageGroupingSelector,
  );

  const prevData = usePreviousDifferent(data);
  const gridData = data ?? prevData ?? [];

  const columns = useMemo(() => {
    return (
      utilizationAndCoverageDrawerDataGridColumns?.[viewTab]?.[grouping] ?? []
    );
  }, [grouping, viewTab]);

  const empty = !loading && !gridData.length;

  const Footer = useCallback(() => {
    if (!empty) {
      return undefined;
    }

    return <EmptyDataGridFooter name={coverageGroupingNames[grouping]} />;
  }, [empty, grouping]);

  if (!columns) {
    return null;
  }

  return (
    <Box mt={1.5} position="relative" zIndex={1}>
      {loading && <CommitmentsGridLoadingLayer />}

      <DataGrid
        columnSorting
        toolbar={toolbar}
        styles={getCommitmentsTableStyles(empty)}
        data={gridData}
        columns={columns}
        Footer={Footer}
      />
    </Box>
  );
};

const toolbar: ToolbarConfig = {
  renderToolbar: (props) => <CommitmentsDrawerDataGridToolbar {...props} />,
};
