import { FC, Fragment, useCallback } from "react";
import { Collapse } from "@mui/material";
import { useToggle } from "rooks";
import { LiveUsageMgmtNestedNavItem } from "./components/LiveUsageMgmtNestedNavItem";
import { LiveUsageMgmtNavItemCollapsed } from "./components/LiveUsageMgmtNavItemCollapsed";
import { LiveUsageMgmtNavItemMain } from "./components/LiveUsageMgmtNavItemMain";
import { ResourceGroupType } from "../../../../../../../utils/constants/resources/resourceGroups";
import {
  ProviderType,
  ResourceType,
} from "../../../../../../../services/cloudchipr.api";
import { useLiveUsageMgmtPageNavigate } from "../../utils/hooks/useLiveUsageMgmtPageNavigate.hook";

interface LiveUsageMgmtNavItemProps extends ResourceGroupType {
  provider: ProviderType;
  selectedResourceType?: ResourceType;
  sidebarCollapsed?: boolean;
}

export const LiveUsageMgmtNavItem: FC<LiveUsageMgmtNavItemProps> = ({
  expandable,
  actionableResourceTypes,
  value,
  provider,
  selectedResourceType,
  sidebarCollapsed,
}) => {
  const [expanded, toggleExpanded] = useToggle();
  const handleNavigationClick = useLiveUsageMgmtPageNavigate(value);

  const nestedSelected = actionableResourceTypes.some(
    (resourceType) => resourceType === selectedResourceType,
  );

  const mainSelected =
    (!expandable || (expandable && !expanded)) && nestedSelected;

  const handleMainItemClick = useCallback(() => {
    toggleExpanded();

    if (!expanded) {
      handleNavigationClick();
    }
  }, [toggleExpanded, expanded, handleNavigationClick]);

  if (sidebarCollapsed) {
    return (
      <LiveUsageMgmtNavItemCollapsed
        resourceType={value}
        selected={nestedSelected}
        provider={provider}
        expandable={expandable}
        actionableResourceTypes={actionableResourceTypes}
        selectedResourceType={selectedResourceType}
      />
    );
  }

  return (
    <Fragment>
      <LiveUsageMgmtNavItemMain
        resourceType={value}
        provider={provider}
        selected={mainSelected}
        expandable={expandable}
        expanded={expanded}
        onClick={handleMainItemClick}
      />

      {expandable && (
        <Collapse in={expanded}>
          {actionableResourceTypes.map((resourceType) => (
            <LiveUsageMgmtNestedNavItem
              key={resourceType}
              resourceType={resourceType}
              selected={resourceType === selectedResourceType}
            />
          ))}
        </Collapse>
      )}
    </Fragment>
  );
};
