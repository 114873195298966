import { FC } from "react";
import { Card, Stack, Tooltip, Typography } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { money } from "../../../../../utils/numeral/money";

interface SavingsOpportunitiesMetadataCardProps {
  value: number;
  title: string;
  postFix?: string;
  tooltip?: string;
}

export const SavingsOpportunitiesMetadataCard: FC<
  SavingsOpportunitiesMetadataCardProps
> = ({ tooltip, value, postFix, title }) => {
  return (
    <Card variant="outlined" sx={{ p: 2, flex: 1 }}>
      <Stack direction="row" spacing={1}>
        <Typography variant="body2" fontWeight="medium" color="text.secondary">
          {title}
        </Typography>

        {tooltip && (
          <Tooltip arrow title={tooltip} placement="top">
            <InfoOutlinedIcon fontSize="small" color="action" />
          </Tooltip>
        )}
      </Stack>

      <Typography variant="h5" fontWeight="bold">
        {money(value)}
        {postFix && (
          <Typography variant="caption" color="text.secondary" component="span">
            {postFix}
          </Typography>
        )}
      </Typography>
    </Card>
  );
};
