import { FC, Fragment, useCallback, useMemo } from "react";
import {
  FormControlLabel,
  FormGroup,
  IconButton,
  Popover,
  PopoverOrigin,
  Switch,
  Tooltip,
} from "@mui/material";
import { AgGridReact } from "@ag-grid-community/react";
import { Tune } from "@mui/icons-material";
import { useCounter } from "rooks";
import { useMenuHook } from "../../../../utils/hooks/useMenu.hook";
import { capitalizeString } from "../../../../utils/helpers/capitalizeString";

interface AGGridColumnsToolPanelProps {
  agGrid: AgGridReact | null;
}

export const AGGridColumnsToolPanel: FC<AGGridColumnsToolPanelProps> = ({
  agGrid,
}) => {
  const { anchor, open, openMenu, closeMenu } = useMenuHook();
  const { decrement, value } = useCounter(0);

  const allColumns = useMemo(() => {
    return (
      agGrid?.api
        ?.getColumns()
        ?.filter((col) => !col.getColDef().suppressColumnsToolPanel) ?? []
    );
  }, [agGrid?.api]);

  const columnsShowHide = useCallback(
    (colId: string) => {
      const { visibleColumns, hiddenColumns } = allColumns.reduce(
        (acc, col) => {
          const id = col.getColId();

          if (id === colId) {
            if (col.isVisible()) {
              acc.hiddenColumns.push(id);
            } else {
              acc.visibleColumns.push(id);
            }

            return acc;
          }

          if (col.isVisible()) {
            acc.visibleColumns.push(id);
          } else {
            acc.hiddenColumns.push(id);
          }

          return acc;
        },
        {
          visibleColumns: [],
          hiddenColumns: [],
        } as Record<string, string[]>,
      );

      agGrid?.api?.setColumnsVisible(visibleColumns, true);
      agGrid?.api?.setColumnsVisible(hiddenColumns, false);

      decrement();
    },
    [agGrid?.api, allColumns, decrement],
  );

  return (
    <Fragment>
      <Tooltip arrow title="Table Settings" placement="top">
        <span>
          <IconButton
            onClick={openMenu}
            size="small"
            disabled={!allColumns?.length}
          >
            <Tune fontSize="small" />
          </IconButton>
        </span>
      </Tooltip>

      <Popover
        open={open}
        anchorEl={anchor}
        onClose={closeMenu}
        slotProps={slotProps}
        anchorOrigin={anchorOrigin}
      >
        <FormGroup key={`${value}`}>
          {allColumns.map((column) => {
            const colId = column.getColId();
            const colDef = column.getColDef();
            const idAsName = capitalizeString(colId);

            return (
              <FormControlLabel
                key={colId}
                sx={{ mb: 1, whiteSpace: "nowrap" }}
                control={
                  <Switch
                    size="small"
                    checked={column.isVisible()}
                    onChange={columnsShowHide.bind(null, colId)}
                  />
                }
                label={colDef.headerName || idAsName}
              />
            );
          })}
        </FormGroup>
      </Popover>
    </Fragment>
  );
};

const slotProps = {
  paper: { sx: { p: 2 } },
};
const anchorOrigin: PopoverOrigin = {
  vertical: "bottom",
  horizontal: "left",
};
