import { FC, useCallback } from "react";
import { Divider, Drawer } from "@mui/material";
import { useDidUpdate } from "rooks";
import Box from "@mui/material/Box";
import { CreateRoleDrawerContent } from "./components/CreateRoleDrawerContent";
import { CreateRoleDrawerHeader } from "./components/CreateRoleDrawerHeader";
import { useAppDispatch } from "../../../../../../../store/hooks";
import { resetUserRoleData } from "../../../../../../../store/user/userSlice";
import { initiateCreateUserRolesDrawerThunk } from "../../../../../../../store/user/thunks/roles/initiateCreateUserRolesDrawerThunk";

interface CreateRoleDrawerProps {
  open: boolean;
  onClose(): void;
}

export const CreateRoleDrawer: FC<CreateRoleDrawerProps> = ({
  open,
  onClose,
}) => {
  const dispatch = useAppDispatch();

  const drawerCloseHandler = useCallback(() => {
    onClose();
    dispatch(resetUserRoleData());
  }, [onClose, dispatch]);

  useDidUpdate(() => {
    if (open) {
      dispatch(initiateCreateUserRolesDrawerThunk());
    }
  }, [open]);

  return (
    <Drawer
      anchor="right"
      onClose={drawerCloseHandler}
      open={open}
      PaperProps={paperProps}
    >
      <Box bgcolor="white" position="sticky" top={0} zIndex={1}>
        <CreateRoleDrawerHeader onClose={drawerCloseHandler} />
        <Divider />
      </Box>

      <CreateRoleDrawerContent />
    </Drawer>
  );
};

const paperProps = {
  sx: {
    width: "40dvw",
    minWidth: 700,
  },
};
