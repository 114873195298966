import { savingsPlansDataForTableSelector } from "./savingsPlansDataForTableSelector";
import { RootState } from "../../../../store";
import { SavingsPlan } from "../../../../../services/cloudchipr.api";

export const savingsPlansDataSelector = (
  state: RootState,
): SavingsPlan[] | undefined => {
  const data = savingsPlansDataForTableSelector(state);

  return data?.utilization_and_coverage.savings_plans;
};
