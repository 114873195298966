import { FC, useCallback } from "react";
import Stack from "@mui/material/Stack";
import { useAuth0 } from "@auth0/auth0-react";
import { Button, Typography } from "@mui/material";
import { PageHeaderWithLogo } from "../PageHeaderWithLogo";

export const UserInvitationSent: FC = () => {
  const { logout } = useAuth0();

  const handleLogoutClickHandler = useCallback(() => {
    logout({ returnTo: window.location.origin });
  }, [logout]);

  return (
    <Stack height="100vh" bgcolor="grey.50">
      <PageHeaderWithLogo />
      <Stack alignItems="center" mt={20} spacing={3.5} px={1}>
        <Typography variant="h4" color="primary.dark" fontWeight="medium">
          Invitation Request Sent
        </Typography>
        <Typography
          variant="h6"
          textAlign="center"
          color="text.secondary"
          fontWeight="normal"
        >
          Your invitation request has been sent. <br />
          You will receive an email notification once your request has been
          approved.
        </Typography>
        <Button
          onClick={handleLogoutClickHandler}
          sx={{ textTransform: "none" }}
          variant="outlined"
        >
          Back to Login
        </Button>
      </Stack>
    </Stack>
  );
};
