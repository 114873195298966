import { getResourceExplorerResourceActionLabel } from "./getResourceExplorerResourceActionLabel";
import { getExecutionLogsExecutorData } from "./getExecutionLogsExecutorData";
import { ExecutionLogV3, Tag } from "../../../../../services/cloudchipr.api";
import { getResourceTypeName } from "../../../../../utils/helpers/resources/getResourceTypeName";

export const generateCsvDataFromResponse = (
  executionLog: ExecutionLogV3,
  automationExist: boolean,
) => {
  return executionLog.response
    .map(({ resources, type }) => {
      return resources.map((resource) => {
        const { csvString } = getExecutionLogsExecutorData(
          executionLog.source,
          automationExist,
        );

        return {
          "Resource Type": getResourceTypeName(type, {
            type: "long",
            singular: true,
          }),

          "Execution Date": executionLog.created_at,

          Action: getResourceExplorerResourceActionLabel(
            type,
            resource.action,
            resource.snapshot,
          ),

          "Monthly Price": resource.price_per_month,

          "Executed By": csvString,

          Account:
            resource.account.provider_account_name ||
            resource.account.provider_account_id ||
            resource.account.id,

          Region: resource.region,

          Status: resource.succeded ? "Succeeded" : "Failed",

          Log: resource.log,

          Tags: resource.tags
            ?.map((tag: Tag) => `${tag?.key}:${tag?.value}`)
            .join(" , "),
        };
      });
    })
    .flat();
};
