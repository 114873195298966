import { ResourceExplorerGridDataWithId } from "../../../../resource-explorer/utils/types/resourceExplorerDataGrid";
import { formatcostBreakdownWidgetTableViewLastGridItem } from "../../../selectors/widgets/cost-breakdown/utils/helpers/formatCostBreakdownV2WidgetTableViewLastGridItem";

export const combineCostBreakdownWidgetGridDataWithOthers = (
  data: ResourceExplorerGridDataWithId[],
) => {
  const lastItems = data.slice(visibleItemsCount, data?.length);
  const startItems = data.slice(0, visibleItemsCount);

  const lastItem = formatcostBreakdownWidgetTableViewLastGridItem(lastItems);

  const completeData = [...startItems];

  if (lastItems.length) {
    completeData.push(lastItem);
  }

  return completeData;
};

const visibleItemsCount = 9;
