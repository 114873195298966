import { FC, Fragment } from "react";
import { DialogContent, Divider, Stack, Typography } from "@mui/material";

import { ResourceExplorerBillingExportDialogProcessStepActions } from "./components/ResourceExplorerBillingExportDialogProcessStepActions";
import EmptyStateImage from "../../../../../../../../../../../assets/images/processing-data.png";

interface ResourceExplorerBillingExportDialogProcessStepProps {
  onCloseDialog(): void;
}
// TODO: add inLoading check
export const ResourceExplorerBillingExportDialogProcessStep: FC<
  ResourceExplorerBillingExportDialogProcessStepProps
> = ({ onCloseDialog }) => {
  return (
    <Fragment>
      <DialogContent sx={{ p: 4 }}>
        <Stack gap={3} alignItems="center">
          <Typography variant="body1">
            Collecting billing data. This process can take a few hours.
          </Typography>
          <img src={EmptyStateImage} alt="EmptyStateImage" />
        </Stack>
      </DialogContent>

      <Divider />

      <ResourceExplorerBillingExportDialogProcessStepActions
        onCloseDialog={onCloseDialog}
      />
    </Fragment>
  );
};
