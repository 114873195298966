import { dashboardsPossibleWidgetsSelector } from "./dashboardsPossibleWidgetsSelector";
import { RootState } from "../../../store";
import {
  WidgetStateReason,
  WidgetType,
} from "../../../../services/cloudchipr.api";

export const dashboardsPossibleWidgetStatusByWidgetTypeSelector = (
  state: RootState,
  widgetType: WidgetType,
): null | WidgetStateReason => {
  const statuses = dashboardsPossibleWidgetsSelector(state);

  return statuses?.[widgetType];
};
