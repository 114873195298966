import { FC, Fragment } from "react";
import TabContext from "@mui/lab/TabContext";
import { TabPanel } from "@mui/lab";
import { LinearProgress } from "@mui/material";
import { RightsizingRecommendationsTable } from "./RightsizingRecommendationsTable";
import { useAppSelector } from "../../../../../../../store/hooks";
import { rightsizingRecommendationsFilteredDataSelector } from "../../../../../../../store/recommendations/selectors/rightsizing/data/rightsizingRecommendationsFilteredDataSelector";
import { rightsizingRecommendationsDismissedFilteredDataSelector } from "../../../../../../../store/recommendations/selectors/rightsizing/data/rightsizingRecommendationsDismissedFilteredDataSelector";
import { RightsizingRecommendationTabs } from "../RightsizingRecommendationTabs";
import { RightsizingRecommendationsTitle } from "../RightsizingRecommendationsTitle";
import { rightsizingRecommendationsPageLoadingSelector } from "../../../../../../../store/recommendations/selectors/rightsizing/loading/rightsizingRecommendationsPageLoadingSelector";
import { useRecommendationsActiveTabControllerHook } from "../../../../utils/hooks/useRecommendationsActiveTabController.hook";
import { RightsizingDismissedResourcesDataGrid } from "../dismissed-resources/RightsizingDismissedResourcesDataGrid";
import { rightsizingRecommendationsDismissedResourcesDataTotalLengthSelector } from "../../../../../../../store/recommendations/selectors/rightsizing/dismissed-resources/rightsizingRecommendationsDismissedResourcesDataTotalLengthSelector";
import { rightsizingRecommendationsDismissedDataTotalLengthSelector } from "../../../../../../../store/recommendations/selectors/rightsizing/data/rightsizingRecommendationsDismissedDataTotalLengthSelector";
import { rightsizingRecommendationsDataTotalLengthSelector } from "../../../../../../../store/recommendations/selectors/rightsizing/data/rightsizingRecommendationsDataTotalLengthSelector";

export const RightsizingRecommendationsTableContent: FC = () => {
  const loading = useAppSelector(rightsizingRecommendationsPageLoadingSelector);
  const filteredData = useAppSelector(
    rightsizingRecommendationsFilteredDataSelector,
  );
  const filteredDismissedData = useAppSelector(
    rightsizingRecommendationsDismissedFilteredDataSelector,
  );
  const activeLength = useAppSelector(
    rightsizingRecommendationsDataTotalLengthSelector,
  );
  const dismissedLength = useAppSelector(
    rightsizingRecommendationsDismissedDataTotalLengthSelector,
  );

  const dismissedResourcesLength = useAppSelector(
    rightsizingRecommendationsDismissedResourcesDataTotalLengthSelector,
  );

  const { activeTab, setActiveTab } = useRecommendationsActiveTabControllerHook(
    loading,
    activeLength,
    dismissedLength,
    dismissedResourcesLength,
  );

  return (
    <Fragment>
      <RightsizingRecommendationsTitle />

      <TabContext value={activeTab}>
        {loading && <LinearProgress />}

        <RightsizingRecommendationTabs
          loading={loading}
          setActiveTab={setActiveTab}
        />

        <TabPanel value="active" sx={{ p: 0 }}>
          <RightsizingRecommendationsTable
            tab={activeTab}
            recommendations={filteredData}
          />
        </TabPanel>

        <TabPanel value="dismissed" sx={{ p: 0 }}>
          <RightsizingRecommendationsTable
            tab={activeTab}
            recommendations={filteredDismissedData}
          />
        </TabPanel>

        <TabPanel value="dismissed_resources" sx={{ p: 0 }}>
          <RightsizingDismissedResourcesDataGrid />
        </TabPanel>
      </TabContext>
    </Fragment>
  );
};
