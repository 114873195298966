import { FC } from "react";
import {
  TableRowNotVirtualizedCells,
  TableRowNotVirtualizedCellsProps,
} from "./TableRowNotVirtualizedCells";
import {
  TableRowVirtualizedCells,
  TableRowVirtualizedCellsProps,
} from "./TableRowVirtualizedCells";

interface TableRowProps
  extends TableRowNotVirtualizedCellsProps,
    TableRowVirtualizedCellsProps {
  enableColumnVirtualization: boolean;
}

export const TableRow: FC<TableRowProps> = ({
  enableColumnVirtualization,
  ...props
}) => {
  if (enableColumnVirtualization) {
    return <TableRowVirtualizedCells {...props} />;
  }

  return <TableRowNotVirtualizedCells {...props} />;
};
