import { FC } from "react";

import { Alert } from "@mui/material";

interface ResourceExplorerBillingExportDialogBillingStatusProps {
  message: string;
  severity?: "warning" | "success";
}

export const ResourceExplorerBillingExportDialogBillingStatus: FC<
  ResourceExplorerBillingExportDialogBillingStatusProps
> = ({ message, severity = "warning" }) => {
  if (!message) {
    return null;
  }
  return (
    <Alert
      variant="outlined"
      severity={severity}
      sx={{
        alignItems: "center",
      }}
    >
      {message}
    </Alert>
  );
};
