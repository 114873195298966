import { FC, useMemo } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useEffectOnceWhen } from "rooks";
import {
  ResourceFiltersWithAction,
  ResourceType,
} from "../../../../../../../services/cloudchipr.api";
import { useAppSelector } from "../../../../../../../store/hooks";
import {
  getActionValue,
  getCleanActionLabel,
} from "../../../../../../../utils/clean-options";
import { scheduleAllResourcesDataSelector } from "../../../../../../../store/automations/selectros/workflow/resources-preview/scheduleAllResourcesDataSelector";
import { formatDate } from "../../../../../../../utils/helpers/date-time/datetime-format";
import { scheduleStatusToggleLoadingSelector } from "../../../../../../../store/schedules/selectors/scheduleStatusToggleLoadingSelector";
import { UnprotectDialogTable } from "../../resources-preview-old/components/protection/UnprotectDialogTable";
import { getResourceTypeColumns } from "../../../../../live-usage-mgmt/components/resource-card/resource-card-content/resource-card-data/resource-card-grid/resouce-card-data-grid/utils/data-grid/columns-setup/getResourceTypeColumns";
import { ResourceItemType } from "../../../../../../../store/live-usage-mgmt/utils/types/types";

interface ResourceUnprotectDialogProps {
  open: boolean;
  scheduleId: string;
  accountIds: string[];
  onClose(): void;
  onSubmit(): void;
  resourcesFetchHandler(): void;
  resourceFilters?: ResourceFiltersWithAction[];
}

export const ScheduleDisablingDialog: FC<ResourceUnprotectDialogProps> = ({
  open,
  onClose,
  onSubmit,
  resourceFilters,
  accountIds,
  resourcesFetchHandler,
  scheduleId,
}) => {
  const isLoading = useAppSelector(scheduleStatusToggleLoadingSelector);

  const resourceTypes = resourceFilters?.map(({ filter }) => filter.type) ?? [];
  const resources = useAppSelector((state) =>
    scheduleAllResourcesDataSelector(
      state,
      accountIds,
      resourceTypes,
      scheduleId,
    ),
  );

  const data = useMemo(() => {
    return resources.map(({ resource }) => {
      const resourceType = resource?.resource_type as ResourceType;

      const nameKey = getResourceTypeColumns(resourceType)?.at(0)
        ?.accessorKey as keyof ResourceItemType;

      const sameResourceFromSchedule = resourceFilters?.find(
        ({ filter }) => filter.type === resourceType,
      );

      const action = getActionValue(
        sameResourceFromSchedule?.action,
        sameResourceFromSchedule?.snapshot,
      );

      const upcomingActionDate = resource?.upcoming_actions?.at(0)?.action_date;

      return {
        id: resource?.id,

        actionDate: upcomingActionDate
          ? formatDate(upcomingActionDate ?? "", { type: "dateTime" })
          : "-",

        resource: (resource?.[nameKey] ||
          resource?.provider_unique_identifier) as string,

        action: getCleanActionLabel(action, resourceType),

        account:
          resource?.account?.provider_account_name ||
          resource?.account?.provider_account_id,

        provider: resource?.account?.provider,
      };
    });
  }, [resourceFilters, resources]);

  useEffectOnceWhen(
    resourcesFetchHandler,
    !resources?.length && !!resourceFilters,
  );

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>Disable Workflow?</DialogTitle>

      <DialogContent>
        <Typography variant="body1" color="text.secondary">
          Disabling this workflow will reset all scheduled actions on following
          resources within the Grace Period.
        </Typography>

        <Box bgcolor="grey.100" p={1} borderRadius={2} mt={2}>
          <UnprotectDialogTable data={data} />
        </Box>
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose} color="tertiary">
          Cancel
        </Button>

        <LoadingButton
          variant="contained"
          loading={isLoading}
          color="primary"
          onClick={onSubmit}
        >
          Disable
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
