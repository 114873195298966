import { createAsyncThunk } from "@reduxjs/toolkit";
import { enqueueSnackbar } from "notistack";
import { createDimensionThunk } from "./createDimensionThunk";
import { updateCurrentDimensionThunk } from "./update-current-dimension/updateCurrentDimensionThunk";
import { getDimensionsThunk } from "./getDimensionsThunk";
import { dimensionDrawerModeSelector } from "../../selectors/slice-data/dimensionDrawerModeSelector";
import { RootState } from "../../../store";

export const dimensionSaveHandlerThunk = createAsyncThunk(
  "dimensions/dimensionSaveHandler",
  async (_: void, { dispatch, getState }) => {
    const state = getState() as RootState;

    const mode = dimensionDrawerModeSelector(state);

    const create = mode === "dimensionCreate";
    let response;

    if (create) {
      response = await dispatch(createDimensionThunk());
    } else {
      response = await dispatch(updateCurrentDimensionThunk());
    }

    if (response?.payload) {
      await dispatch(getDimensionsThunk(true));

      enqueueSnackbar(
        `Dimension successfully ${create ? "created" : "updated"}.`,
        {
          variant: "snackbarAlert",
          AlertSnackBarProps: { severity: "success" },
        },
      );

      return response;
    }
  },
);
