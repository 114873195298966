import { FC } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { ApiKeysListGridRow } from "./ApiKeysListGridRow";
import { useGetUsersMeOrganisationsCurrentApiKeysQuery } from "../../../../../../services/cloudchipr.api";

interface ApiKeysListGridProps {
  showForm(): void;
}
export const ApiKeysListGrid: FC<ApiKeysListGridProps> = ({ showForm }) => {
  const { data: apiKey } = useGetUsersMeOrganisationsCurrentApiKeysQuery();

  return (
    <TableContainer>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Key</TableCell>
            <TableCell sx={{ whiteSpace: "nowrap" }}>Last Used</TableCell>
            <TableCell>Created</TableCell>
            <TableCell>Expires</TableCell>
            <TableCell colSpan={3} />
          </TableRow>
        </TableHead>
        <TableBody>
          {apiKey?.map((item) => {
            return (
              <ApiKeysListGridRow
                key={item.id}
                apiKey={item}
                showForm={showForm}
              />
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
