import { FC, Fragment } from "react";
import { Chip, Stack, Tooltip, Typography } from "@mui/material";
import { CopyIconButton } from "../../../../../../../../../../common/CopyIconButton";

interface CreatedByCellWithoutEndPointsProps {
  hovered: boolean;
  value: string;
  start: string;
  end: string;
  copy: boolean;
}

export const CreatedByCellWithChip: FC<CreatedByCellWithoutEndPointsProps> = ({
  start,
  end,
  hovered,
  value,
  copy,
}) => {
  return (
    <Fragment>
      <Tooltip title={value} arrow>
        <Stack direction="row" overflow="hidden">
          <Typography variant="body2" whiteSpace="nowrap" mr={0.5}>
            {start}
          </Typography>
          <Chip
            size="small"
            label={end}
            sx={{
              overflow: "hidden",
              bgcolor: "grey.600",
              color: "white",
            }}
          />
        </Stack>
      </Tooltip>
      {copy && (
        <CopyIconButton
          data={end}
          visible={hovered}
          sx={{
            ml: 0.5,
            display: hovered ? "flex" : "none",
          }}
        />
      )}
    </Fragment>
  );
};
