import { FC } from "react";
import { Card, Grid, Stack, Typography } from "@mui/material";
import { ChatQuestion } from "./ChatQuestion";
import { preDefinedQuestions } from "../utils/constants";

interface ChatQuestionsSectionProps {
  questionsDisabled: boolean;
  onQuestionChange(question: string): void;
}
export const ChatQuestionsSection: FC<ChatQuestionsSectionProps> = ({
  onQuestionChange,
  questionsDisabled,
}) => {
  return (
    <Card sx={{ p: 3, minHeight: 700, display: "flex", alignItems: "center" }}>
      <Stack justifyContent="center" alignItems="center" flexGrow={1}>
        <Typography variant="h5">Ask Cloudchipr</Typography>
        <Typography
          mt={1}
          variant="body1"
          textAlign="center"
          sx={{ maxWidth: 570 }}
        >
          Natural Language Processing Engine will try to answer any question you
          may have about your cloud cost and usage.
        </Typography>

        <Grid container mt={3} spacing={3}>
          {preDefinedQuestions.map((question) => {
            return (
              <ChatQuestion
                key={question}
                disabled={questionsDisabled}
                onQuestionChange={onQuestionChange}
                question={question}
              />
            );
          })}
        </Grid>
      </Stack>
    </Card>
  );
};
