import { FC, useMemo } from "react";
import moment from "moment";
import { useAppSelector } from "../../../../../../../../../../../store/hooks";
import { resourceExplorerGranularTotalCostsSelector } from "../../../../../../../../../../../store/resource-explorer/selectors/current-resource-explorer/data-grid/granular-costs-view/resourceExplorerGranularTotalCostsSelector";
import { TotalCostColumnHeaderCell } from "../common/TotalCostColumnHeaderCell";

export const ResourceExplorerGranularTotalCostHeaderCell: FC = () => {
  const costs = useAppSelector(resourceExplorerGranularTotalCostsSelector);

  const dateDetails = useMemo(() => {
    if (!costs) {
      return undefined;
    }

    const moments = Object.keys(costs)
      .filter((d) => moment(d).isValid())
      .map((d) => moment(d));

    const maxDate = moment.max(moments);
    const minDate = moment.min(moments);

    return {
      to_date: maxDate.format(),
      from_date: minDate.format(),
    };
  }, [costs]);

  return (
    <TotalCostColumnHeaderCell
      totalCost={costs?.total}
      dateDetails={dateDetails}
    />
  );
};
