import { createAsyncThunk } from "@reduxjs/toolkit";
import { getLargestCostChangesWidgetDataBySetupThunk } from "./data-get/getLargestCostChangesWidgetDataBySetupThunk";
import { RootState } from "../../../../store";
import { setWidgetSetup } from "../../../dashboardsSlice";
import { largestCostChangesDefaultData } from "../../../utils/constants/largestCostChangesDefaultData";
import { defaultResourceExplorerViewIdSelector } from "../../../../resource-explorer/selectors/all-resource-explorers/defaultResourceExplorerViewIdSelector";

export const initiateLargestCostChangesWidgetThunk = createAsyncThunk(
  "dashboards/initiateLargestCostChangesWidget",
  async (_: void, { dispatch, getState }) => {
    const state = getState() as RootState;

    const defaultViewId = defaultResourceExplorerViewIdSelector(state);

    if (!defaultViewId) {
      dispatch(setWidgetSetup(largestCostChangesDefaultData));
      return;
    }

    dispatch(
      setWidgetSetup({
        ...largestCostChangesDefaultData,
        viewId: defaultViewId,
      }),
    );

    dispatch(getLargestCostChangesWidgetDataBySetupThunk());
  },
);
