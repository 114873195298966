import { RootState } from "../../../../store";
import { resourceExplorerWidgetDataSelector } from "../resource-explorer-widget/resourceExplorerWidgetDataSelector";
import {
  ForecastedCost,
  ForecastOption,
} from "../../../../../services/cloudchipr.api";

export const resourceExplorerForecastedCostByForecastOptionSelector = (
  state: RootState,
  forecastOption: ForecastOption,
): ForecastedCost | undefined => {
  const data = resourceExplorerWidgetDataSelector(state);

  return data?.forecasted_costs?.find(
    (item) => item.forecast_option === forecastOption,
  );
};
