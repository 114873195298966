import { FC, useCallback, useEffect, useState } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import { Chip, TextField } from "@mui/material";
import * as yup from "yup";

const isEmailValid = (str: string) => {
  const schema = yup.string().trim().required().email();

  try {
    schema.validateSync(str);
    return true;
  } catch (err) {
    return false;
  }
};

interface EmailFormProps {
  error?: string | boolean;
  emails?: string[];
  setFieldValue(key: string, value: string[]): void;
}

export const EmailForm: FC<EmailFormProps> = ({
  emails,
  setFieldValue,
  error,
}) => {
  const [value, setValue] = useState("");

  const changeHandler = useCallback(
    (_: any, values: string[]) => {
      setFieldValue("emails", values);
    },
    [setFieldValue],
  );

  const blurHandler = useCallback(() => {
    if (emails && value) {
      setFieldValue("emails", [...emails, value]);
    }
  }, [emails, value, setFieldValue]);

  const inputValueChangeHandler = useCallback(
    (e: any) => setValue(e.target.value),
    [],
  );

  useEffect(() => setValue(""), [emails?.length]);

  return (
    <Autocomplete
      freeSolo
      multiple
      fullWidth
      onBlur={blurHandler}
      open={false}
      value={emails}
      inputValue={value}
      options={emails || []}
      onChange={changeHandler}
      renderTags={(value: string[], getTagProps) =>
        value.map((option: string, index: number) => (
          <Chip
            variant="outlined"
            color={isEmailValid(option) ? "default" : "error"}
            label={option}
            {...getTagProps({ index })}
          />
        ))
      }
      renderInput={(params) => (
        <TextField
          {...params}
          name="emails"
          variant="outlined"
          label="Enter Emails"
          helperText={error}
          onChange={inputValueChangeHandler}
          error={!!error}
        />
      )}
    />
  );
};
