import { createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../../../store";
import { Dates } from "../../../../services/cloudchipr.api";
import {
  setResourceExplorerDates,
  setResourceExplorerSearch,
} from "../../resourceExplorerSlice";
import { getResourceExplorerDataThunk } from "../widgets/getResourceExplorerDataThunk";
import { resourceExplorerPathLastItemFilterTreeSelector } from "../../selectors/current-resource-explorer/path/resourceExplorerPathLastItemFilterTreeSelector";

export const changeResourceExplorerDateThunk = createAsyncThunk(
  "resourceExplorer/getResourceExplorerDataThunk",
  async (dates: Dates, { dispatch, getState }) => {
    const pathLastItemFilterTree =
      resourceExplorerPathLastItemFilterTreeSelector(getState() as RootState);

    dispatch(setResourceExplorerDates(dates));
    dispatch(getResourceExplorerDataThunk(pathLastItemFilterTree));
    dispatch(setResourceExplorerSearch(""));
  },
);
