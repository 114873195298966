import { FC, memo } from "react";
import { Skeleton, Stack } from "@mui/material";

import { useAppSelector } from "../../../../../../../../store/hooks";
import { ScheduledMoreNextRuns } from "../../../../../../automations/common/components/next-runs-old/ScheduledMoreNextRuns";
import { ScheduledNextRunDateTime } from "../../../../../../automations/common/components/next-runs-old/ScheduledNextRunDateTime";
import { ScheduledNextRunDateTimeZone } from "../../../../../../automations/common/components/next-runs-old/ScheduledNextRunDateTimeZone";
import { scheduleFutureNextRunDatesWithoutFirstSelector } from "../../../../../../../../store/automations/selectros/workflow/next-runs/old/scheduleFutureNextRunDatesWithoutFirstSelector";
import { scheduleNextRunDateTimeSelector } from "../../../../../../../../store/automations/selectros/workflow/next-runs/old/scheduleNextRunDateTimeSelector";
import { scheduleNextRunDatesLoadingSelector } from "../../../../../../../../store/automations/selectros/workflow/next-runs/old/scheduleNextRunDatesLoadingSelector";
import { AutomationTabType } from "../../../../../common/utils/types/types";

interface NextRunDatesTimeProps {
  timeZone: string;
  tabType?: AutomationTabType;
}
export const NextRunDatesTime: FC<NextRunDatesTimeProps> = memo(
  ({ timeZone, tabType }) => {
    const futureRunDates = useAppSelector((state) =>
      scheduleFutureNextRunDatesWithoutFirstSelector(state, tabType),
    );
    const loading = useAppSelector((state) =>
      scheduleNextRunDatesLoadingSelector(state, tabType),
    );
    const nextRunDateTime = useAppSelector((state) =>
      scheduleNextRunDateTimeSelector(state, tabType),
    );

    return (
      <Stack flex={1}>
        {loading ? (
          <Skeleton variant="text" height={22} width="100%" />
        ) : (
          <Stack direction="row" spacing={0.2}>
            <ScheduledNextRunDateTime
              nextRun={nextRunDateTime}
              variant="subtitle2"
            />
            <ScheduledNextRunDateTimeZone
              timeZone={timeZone}
              variant="subtitle2"
              fontWeight={400}
              color="text.primary"
            />
          </Stack>
        )}
        <span>
          <ScheduledMoreNextRuns
            nextRunList={futureRunDates ?? []}
            timeZone={timeZone}
            disabled={loading}
          />
        </span>
      </Stack>
    );
  },
);
