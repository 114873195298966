import { FC } from "react";
import { Table, TableBody, Typography } from "@mui/material";
import { TargetSingleResourceTableRow } from "./TargetSingleResourceTableRow";
import { TargetSingleResourceTableIdentifierRow } from "./TargetSingleResourceTableIdentifierRow";
import { TargetSingleResourceTableIStateRow } from "./TargetSingleResourceTableIStateRow";
import { useAppSelector } from "../../../../../../../../../../store/hooks";
import { taskManagementTargetResourcesSelector } from "../../../../../../../../../../store/task-management/selectors/form/target/resources/taskManagementTargetResourcesSelector";
import { TagCell } from "../../../../../../../../../common/data-grid-cells/tag-cell/TagCell";
import { money } from "../../../../../../../../../../utils/numeral/money";
import { resourceIdentifierLabels } from "../../../../../../../../../../utils/constants/resources/resourceTypeNames/resourceIdentifierLabels";
import { ProviderIcon } from "../../../../../../../../../common/ProviderIcon";
import { getResourceTypeName } from "../../../../../../../../../../utils/helpers/resources/getResourceTypeName";
import { CreatedByCell } from "../../../../../../../../live-usage-mgmt/components/resource-card/resource-card-content/resource-card-data/resource-card-grid/resouce-card-data-grid/cells/created-by-cell/CreatedByCell";

export const TargetSingleResource: FC = () => {
  const resources = useAppSelector(taskManagementTargetResourcesSelector);
  const targetResource = resources?.at(0);

  if (!targetResource) {
    return null;
  }

  return (
    <Table sx={{ tableLayout: "fixed" }}>
      <TableBody>
        <TargetSingleResourceTableRow
          label={
            resourceIdentifierLabels.get(targetResource.resource_type) ??
            "Instance ID"
          }
        >
          <TargetSingleResourceTableIdentifierRow resource={targetResource} />
        </TargetSingleResourceTableRow>

        <TargetSingleResourceTableRow label="Resource Type" highlight>
          {getResourceTypeName(targetResource.resource_type, {
            singular: true,
            type: "long",
          })}
        </TargetSingleResourceTableRow>

        <TargetSingleResourceTableRow label="Cloud Provider">
          <ProviderIcon provider={targetResource.account.provider} />
        </TargetSingleResourceTableRow>

        <TargetSingleResourceTableRow label="Monthly Price" highlight>
          <Typography variant="body2">
            {money(targetResource.price_per_month)}
          </Typography>
        </TargetSingleResourceTableRow>

        <TargetSingleResourceTableRow label="Region">
          <Typography variant="body2">{targetResource.region}</Typography>
        </TargetSingleResourceTableRow>

        <TargetSingleResourceTableRow highlight label="Created By">
          <CreatedByCell data={targetResource.creation_data} copy={false} />
        </TargetSingleResourceTableRow>

        <TargetSingleResourceTableRow label="Resource State">
          <TargetSingleResourceTableIStateRow state={targetResource.state} />
        </TargetSingleResourceTableRow>

        <TargetSingleResourceTableRow highlight label="Tags">
          {targetResource.tags?.length ? (
            <TagCell tags={targetResource.tags} />
          ) : (
            "-"
          )}
        </TargetSingleResourceTableRow>
      </TableBody>
    </Table>
  );
};
