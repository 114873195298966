import { FC } from "react";
import { Stack, Tooltip, Typography } from "@mui/material";

interface InfoCardProps {
  title: string;
  value: string;
  tooltip?: string;
}

export const InfoCard: FC<InfoCardProps> = ({ title, value, tooltip }) => {
  return (
    <Tooltip title={tooltip ?? ""} arrow>
      <Stack
        p={1}
        pr={2}
        bgcolor="grey.100"
        borderRadius={1}
        sx={{
          userSelect: "none",
        }}
      >
        <Typography variant="caption" color="text.disabled" fontWeight="medium">
          {title}
        </Typography>
        <Typography variant="body2" fontWeight="medium">
          {value}
        </Typography>
      </Stack>
    </Tooltip>
  );
};
