function C8RTextSvgIcon() {
  return (
    <svg
      width="111"
      height="22"
      viewBox="0 0 111 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.4221 14.2752C10.9388 15.2115 10.2592 15.9817 9.3833 16.5858C8.52248 17.1747 7.44269 17.4692 6.14392 17.4692C5.28311 17.4692 4.49026 17.3258 3.76536 17.0388C3.05557 16.7368 2.43639 16.3215 1.90782 15.7929C1.39435 15.2643 0.986596 14.6452 0.684557 13.9354C0.397619 13.2105 0.25415 12.4176 0.25415 11.5568C0.25415 10.696 0.397619 9.90314 0.684557 9.17824C0.986596 8.45335 1.39435 7.83417 1.90782 7.3207C2.43639 6.79213 3.05557 6.38438 3.76536 6.09744C4.49026 5.7954 5.28311 5.64438 6.14392 5.64438C7.42759 5.64438 8.50738 5.93887 9.3833 6.52785C10.2592 7.11683 10.901 7.87948 11.3088 8.8158L8.59044 9.94845C8.33371 9.43498 8.00147 9.04988 7.59371 8.79314C7.20106 8.52131 6.70269 8.38539 6.09862 8.38539C5.70596 8.38539 5.33597 8.4609 4.98862 8.61192C4.64128 8.76294 4.33169 8.97437 4.05985 9.2462C3.80312 9.51804 3.59924 9.85028 3.44822 10.2429C3.2972 10.6356 3.22169 11.0735 3.22169 11.5568C3.22169 12.0401 3.2972 12.478 3.44822 12.8707C3.59924 13.2633 3.80312 13.5956 4.05985 13.8674C4.33169 14.1392 4.64128 14.3507 4.98862 14.5017C5.33597 14.6527 5.70596 14.7282 6.09862 14.7282C6.7178 14.7282 7.23882 14.5923 7.66167 14.3205C8.09963 14.0335 8.44697 13.6258 8.70371 13.0972L11.4221 14.2752Z"
        fill="black"
      />
      <path
        d="M13.2325 17.1068V0.887262H16.2001V17.1068H13.2325Z"
        fill="black"
      />
      <path
        d="M23.8858 5.64438C24.7466 5.64438 25.5319 5.7954 26.2417 6.09744C26.9666 6.38438 27.5858 6.79213 28.0993 7.3207C28.6278 7.83417 29.0356 8.45335 29.3225 9.17824C29.6246 9.90314 29.7756 10.696 29.7756 11.5568C29.7756 12.4176 29.6246 13.2105 29.3225 13.9354C29.0356 14.6603 28.6278 15.287 28.0993 15.8156C27.5858 16.329 26.9666 16.7368 26.2417 17.0388C25.5319 17.3258 24.7466 17.4692 23.8858 17.4692C23.025 17.4692 22.2321 17.3258 21.5072 17.0388C20.7974 16.7368 20.1783 16.329 19.6497 15.8156C19.1362 15.287 18.7285 14.6603 18.4264 13.9354C18.1395 13.2105 17.996 12.4176 17.996 11.5568C17.996 10.696 18.1395 9.90314 18.4264 9.17824C18.7285 8.45335 19.1362 7.83417 19.6497 7.3207C20.1783 6.79213 20.7974 6.38438 21.5072 6.09744C22.2321 5.7954 23.025 5.64438 23.8858 5.64438ZM23.8858 14.7282C24.2633 14.7282 24.6258 14.6603 24.9731 14.5243C25.3356 14.3733 25.6527 14.1619 25.9246 13.8901C26.1964 13.6182 26.4078 13.286 26.5589 12.8933C26.725 12.5007 26.808 12.0552 26.808 11.5568C26.808 11.0584 26.725 10.6129 26.5589 10.2203C26.4078 9.82763 26.1964 9.49539 25.9246 9.22355C25.6527 8.95172 25.3356 8.74784 24.9731 8.61192C24.6258 8.4609 24.2633 8.38539 23.8858 8.38539C23.4931 8.38539 23.1232 8.4609 22.7758 8.61192C22.4285 8.74784 22.1189 8.95172 21.847 9.22355C21.5752 9.49539 21.3562 9.82763 21.1901 10.2203C21.0391 10.6129 20.9636 11.0584 20.9636 11.5568C20.9636 12.0552 21.0391 12.5007 21.1901 12.8933C21.3562 13.286 21.5752 13.6182 21.847 13.8901C22.1189 14.1619 22.4285 14.3733 22.7758 14.5243C23.1232 14.6603 23.4931 14.7282 23.8858 14.7282Z"
        fill="black"
      />
      <path
        d="M39.2511 15.657H39.0698C38.7225 16.2158 38.2543 16.6613 37.6654 16.9935C37.0764 17.3107 36.4043 17.4692 35.6492 17.4692C34.2448 17.4692 33.1952 17.0388 32.5005 16.178C31.8209 15.3172 31.4811 14.1694 31.4811 12.7348V6.00683H34.4486V12.3723C34.4486 13.1727 34.6223 13.7692 34.9697 14.1619C35.3321 14.5394 35.8531 14.7282 36.5327 14.7282C36.9405 14.7282 37.2954 14.6452 37.5974 14.479C37.9145 14.2978 38.1788 14.0637 38.3902 13.7768C38.6168 13.4748 38.7829 13.1274 38.8886 12.7348C39.0094 12.327 39.0698 11.8966 39.0698 11.4435V6.00683H42.0374V17.1068H39.2511V15.657Z"
        fill="black"
      />
      <path
        d="M52.7372 15.7703H52.556C52.2841 16.2384 51.8613 16.6386 51.2874 16.9709C50.7135 17.3031 50.0037 17.4692 49.158 17.4692C48.4482 17.4692 47.7686 17.3258 47.1193 17.0388C46.485 16.7368 45.9186 16.329 45.4203 15.8156C44.937 15.287 44.5519 14.6603 44.265 13.9354C43.978 13.2105 43.8346 12.4176 43.8346 11.5568C43.8346 10.696 43.978 9.90314 44.265 9.17824C44.5519 8.45335 44.937 7.83417 45.4203 7.3207C45.9186 6.79213 46.485 6.38438 47.1193 6.09744C47.7686 5.7954 48.4482 5.64438 49.158 5.64438C50.0037 5.64438 50.7135 5.8105 51.2874 6.14275C51.8613 6.47499 52.2841 6.87519 52.556 7.34336H52.7372L52.556 5.75765V0.887262H55.5235V17.1068H52.7372V15.7703ZM49.7696 14.7282C50.1623 14.7282 50.5323 14.6603 50.8796 14.5243C51.2421 14.3733 51.5592 14.1619 51.8311 13.8901C52.1029 13.6182 52.3219 13.286 52.488 12.8933C52.6541 12.5007 52.7372 12.0552 52.7372 11.5568C52.7372 11.0584 52.6541 10.6129 52.488 10.2203C52.3219 9.82763 52.1029 9.49539 51.8311 9.22355C51.5592 8.95172 51.2421 8.74784 50.8796 8.61192C50.5323 8.4609 50.1623 8.38539 49.7696 8.38539C49.377 8.38539 48.9994 8.4609 48.637 8.61192C48.2897 8.76294 47.9801 8.97437 47.7082 9.2462C47.4364 9.51804 47.2174 9.85028 47.0513 10.2429C46.8852 10.6356 46.8021 11.0735 46.8021 11.5568C46.8021 12.0401 46.8852 12.478 47.0513 12.8707C47.2174 13.2633 47.4364 13.5956 47.7082 13.8674C47.9801 14.1392 48.2897 14.3507 48.637 14.5017C48.9994 14.6527 49.377 14.7282 49.7696 14.7282Z"
        fill="black"
      />
      <path
        d="M68.4748 14.2752C67.9915 15.2115 67.312 15.9817 66.436 16.5858C65.5752 17.1747 64.4954 17.4692 63.1967 17.4692C62.3359 17.4692 61.543 17.3258 60.8181 17.0388C60.1083 16.7368 59.4891 16.3215 58.9606 15.7929C58.4471 15.2643 58.0393 14.6452 57.7373 13.9354C57.4504 13.2105 57.3069 12.4176 57.3069 11.5568C57.3069 10.696 57.4504 9.90314 57.7373 9.17824C58.0393 8.45335 58.4471 7.83417 58.9606 7.3207C59.4891 6.79213 60.1083 6.38438 60.8181 6.09744C61.543 5.7954 62.3359 5.64438 63.1967 5.64438C64.4803 5.64438 65.5601 5.93887 66.436 6.52785C67.312 7.11683 67.9538 7.87948 68.3615 8.8158L65.6432 9.94845C65.3865 9.43498 65.0542 9.04988 64.6465 8.79314C64.2538 8.52131 63.7554 8.38539 63.1514 8.38539C62.7587 8.38539 62.3887 8.4609 62.0414 8.61192C61.694 8.76294 61.3844 8.97437 61.1126 9.2462C60.8559 9.51804 60.652 9.85028 60.501 10.2429C60.3499 10.6356 60.2744 11.0735 60.2744 11.5568C60.2744 12.0401 60.3499 12.478 60.501 12.8707C60.652 13.2633 60.8559 13.5956 61.1126 13.8674C61.3844 14.1392 61.694 14.3507 62.0414 14.5017C62.3887 14.6527 62.7587 14.7282 63.1514 14.7282C63.7705 14.7282 64.2916 14.5923 64.7144 14.3205C65.1524 14.0335 65.4997 13.6258 65.7565 13.0972L68.4748 14.2752Z"
        fill="black"
      />
      <path
        d="M70.2853 0.887262H73.2528V5.44051L73.0716 7.45662H73.2528C73.57 6.92805 74.023 6.49765 74.612 6.1654C75.2161 5.81806 75.8881 5.64438 76.6281 5.64438C78.0628 5.64438 79.1199 6.06724 79.7995 6.91295C80.4942 7.75866 80.8416 8.91396 80.8416 10.3789V17.1068H77.874V10.7413C77.874 9.9711 77.6928 9.38967 77.3303 8.99702C76.9679 8.58927 76.462 8.38539 75.8126 8.38539C75.4048 8.38539 75.0424 8.476 74.7253 8.65723C74.4081 8.82335 74.1363 9.05743 73.9098 9.35947C73.6983 9.66151 73.5322 10.0164 73.4114 10.4242C73.3057 10.8168 73.2528 11.2397 73.2528 11.6927V17.1068H70.2853V0.887262Z"
        fill="black"
      />
      <path
        d="M84.9467 4.39847C84.69 4.39847 84.4408 4.35316 84.1992 4.26255C83.9726 4.15684 83.7687 4.02092 83.5875 3.8548C83.4214 3.67358 83.2855 3.4697 83.1798 3.24317C83.0892 3.01664 83.0438 2.76746 83.0438 2.49562C83.0438 2.22379 83.0892 1.9746 83.1798 1.74807C83.2855 1.52154 83.4214 1.32522 83.5875 1.1591C83.7687 0.977874 83.9726 0.841956 84.1992 0.751343C84.4408 0.64563 84.69 0.592773 84.9467 0.592773C85.4753 0.592773 85.9283 0.781548 86.3059 1.1591C86.6834 1.52154 86.8722 1.96705 86.8722 2.49562C86.8722 3.02419 86.6834 3.47725 86.3059 3.8548C85.9283 4.21725 85.4753 4.39847 84.9467 4.39847ZM83.4743 17.1068V6.00683H86.4418V17.1068H83.4743Z"
        fill="black"
      />
      <path
        d="M89.0648 6.00683H91.8511V7.34336H92.0323C92.3041 6.87519 92.727 6.47499 93.3009 6.14275C93.8747 5.8105 94.5845 5.64438 95.4302 5.64438C96.14 5.64438 96.8121 5.7954 97.4464 6.09744C98.0957 6.38438 98.6621 6.79213 99.1453 7.3207C99.6437 7.83417 100.036 8.45335 100.323 9.17824C100.61 9.90314 100.754 10.696 100.754 11.5568C100.754 12.4176 100.61 13.2105 100.323 13.9354C100.036 14.6603 99.6437 15.287 99.1453 15.8156C98.6621 16.329 98.0957 16.7368 97.4464 17.0388C96.8121 17.3258 96.14 17.4692 95.4302 17.4692C94.5845 17.4692 93.8747 17.3031 93.3009 16.9709C92.727 16.6386 92.3041 16.2384 92.0323 15.7703H91.8511L92.0323 17.356V21.9998H89.0648V6.00683ZM94.8186 14.7282C95.2113 14.7282 95.5813 14.6527 95.9286 14.5017C96.2911 14.3507 96.6082 14.1392 96.88 13.8674C97.1519 13.5956 97.3708 13.2633 97.537 12.8707C97.7031 12.478 97.7861 12.0401 97.7861 11.5568C97.7861 11.0735 97.7031 10.6356 97.537 10.2429C97.3708 9.85028 97.1519 9.51804 96.88 9.2462C96.6082 8.97437 96.2911 8.76294 95.9286 8.61192C95.5813 8.4609 95.2113 8.38539 94.8186 8.38539C94.426 8.38539 94.0484 8.4609 93.686 8.61192C93.3386 8.74784 93.029 8.95172 92.7572 9.22355C92.4854 9.49539 92.2664 9.82763 92.1003 10.2203C91.9341 10.6129 91.8511 11.0584 91.8511 11.5568C91.8511 12.0552 91.9341 12.5007 92.1003 12.8933C92.2664 13.286 92.4854 13.6182 92.7572 13.8901C93.029 14.1619 93.3386 14.3733 93.686 14.5243C94.0484 14.6603 94.426 14.7282 94.8186 14.7282Z"
        fill="black"
      />
      <path
        d="M102.539 6.00683H105.326V7.54723H105.507C105.643 7.2754 105.824 7.02621 106.05 6.79968C106.277 6.57315 106.526 6.37683 106.798 6.21071C107.085 6.04459 107.387 5.91622 107.704 5.82561C108.036 5.735 108.361 5.68969 108.678 5.68969C109.071 5.68969 109.403 5.72744 109.675 5.80295C109.962 5.87846 110.203 5.97663 110.4 6.09744L109.607 8.79314C109.426 8.70253 109.222 8.63457 108.995 8.58927C108.784 8.52886 108.52 8.49866 108.202 8.49866C107.795 8.49866 107.425 8.58172 107.092 8.74784C106.76 8.89886 106.473 9.11784 106.232 9.40477C106.005 9.69171 105.824 10.0315 105.688 10.4242C105.567 10.8017 105.507 11.217 105.507 11.6701V17.1068H102.539V6.00683Z"
        fill="black"
      />
    </svg>
  );
}
export default C8RTextSvgIcon;
