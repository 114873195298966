import { FC, useCallback } from "react";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../../../../../../../store/hooks";
import { commitmentUtilizationSetupVisualizationPropertyByKeySelector } from "../../../../../../../../../../../../../../store/dashboards/selectors/setups/commitment-utilization/commitmentUtilizationSetupVisualizationPropertyByKeySelector";
import { commitmentUtilizationWidgetVisualizationChangeThunk } from "../../../../../../../../../../../../../../store/dashboards/thunks/widgets/commitment-utilization/setup-change/commitmentUtilizationWidgetVisualizationChangeThunk";
import { SwitchWithTypography } from "../../../../../../../../../../../../../SwitchWithTypography";

export const CommitmentUtilizationWidgetVisualizationCommitment: FC = () => {
  const dispatch = useAppDispatch();
  const commitmentChecked = useAppSelector(
    commitmentUtilizationSetupVisualizationPropertyByKeySelector("commitment"),
  );

  const handleChange = useCallback(
    (checked: boolean) => {
      dispatch(
        commitmentUtilizationWidgetVisualizationChangeThunk({
          commitment: checked,
        }),
      );
    },
    [dispatch],
  );

  return (
    <SwitchWithTypography
      checked={commitmentChecked}
      onChange={handleChange}
      title="Commitments"
    />
  );
};
