import { FC } from "react";
import {
  MIN_CPU_USAGE,
  MIN_DATABASE_CONNECTIONS,
  OffHoursRecommendationsChartColors,
} from "../../../../utils/constants/constants";

export const CustomizedYAxisTick: FC<any> = (props) => {
  const { x, y, payload, orientation } = props;
  const isLeft = orientation === "left";
  const min = isLeft ? MIN_CPU_USAGE : MIN_DATABASE_CONNECTIONS;

  let fill = OffHoursRecommendationsChartColors.TextColor;

  if (payload.value <= min) {
    fill = isLeft
      ? OffHoursRecommendationsChartColors.CpuLimitColor
      : OffHoursRecommendationsChartColors.DbConnectionsLimitColor;
  }

  return (
    <g transform={`translate(${isLeft ? x : x + 12},${y + 5})`}>
      <text textAnchor="end" fill={fill} fontSize={isLeft ? 8 : 12}>
        {payload.value}
      </text>
    </g>
  );
};
