import { createAsyncThunk } from "@reduxjs/toolkit";
import { v4 as uuid } from "uuid";
import { setResourceExplorerFilterTree } from "../../resourceExplorerSlice";
import { cloudChiprApi } from "../../../../services/cloudchipr.api";
import { FilterTreeNodeWithId } from "../../../../components/pages/common/filters-tree/utils/types/common";

interface InitiateResourceExplorerThunkArgs {
  dimensionId?: string;
  categoryId?: string;
}

export const setResourceExplorerDimensionFilterFromPathThunk = createAsyncThunk(
  "resourceExplorer/setResourceExplorerDimensionFilterFromPath",
  async (
    { dimensionId, categoryId }: InitiateResourceExplorerThunkArgs,
    { dispatch },
  ) => {
    if (!dimensionId) {
      return;
    }

    let categoryIds = categoryId ? [categoryId] : [];

    if (!categoryId) {
      const { getUsersMeOrganisationsCurrentDimensionsByDimensionId } =
        cloudChiprApi.endpoints;

      const response = await dispatch(
        getUsersMeOrganisationsCurrentDimensionsByDimensionId.initiate({
          dimensionId,
        }),
      ).unwrap();

      categoryIds = response.categories.map(({ id }) => id);
    }

    const dimensionsFilterTreeFilters: FilterTreeNodeWithId = {
      node_type: "group",
      operator: "or",
      items: [
        {
          node_type: "group",
          operator: "or",
          items: [
            {
              id: uuid(),
              node_type: "item",
              type: "dimension_id",
              filter_provider: "dimensions",
              operator: "in",
              value: { key: dimensionId, value: categoryIds },
            },
          ],
        },
      ],
    };

    dispatch(setResourceExplorerFilterTree(dimensionsFilterTreeFilters));

    return dimensionsFilterTreeFilters;
  },
);
