import { Typography } from "@mui/material";
import { getCoveredUsageColumn } from "./common/coveredUsageColumn";
import { coveredHoursColumn } from "./common/coveredHoursColumn";
import { getCoverageColumn } from "./common/coverageColumn";
import { ColumnSetupType } from "../../../../../../../../../../storybook/data-grid/utils/types/types";
import { ReservationsCoverageDataWithId } from "../../../../../../../common/utils/types";

export const reservationCoverageGroupedByCommitmentColumns: ColumnSetupType<ReservationsCoverageDataWithId>[] =
  [
    {
      id: "reservation_id",
      header: "Reservation ID",
      accessorKey:
        "reservation.provider_id" as keyof ReservationsCoverageDataWithId,
      cell: ({ row }) => (
        <Typography variant="body2">
          {row.original.reservation?.provider_id}
        </Typography>
      ),
    },

    getCoveredUsageColumn("commitment"),

    coveredHoursColumn,

    getCoverageColumn("commitment"),
  ];
