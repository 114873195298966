import {
  FilterOperatorType,
  ResourceExplorerDynamicFilterItemType,
} from "../../../../../../../services/cloudchipr.api";

export const filterAvailableFieldsChecker = (
  type: ResourceExplorerDynamicFilterItemType,
  operator: FilterOperatorType,
) => {
  let hasKey = false;
  let hasValue = !["exclude_all", "include_all"].includes(operator);

  if (filterTypesWhichHasKey.includes(type)) {
    hasKey = true;

    if (["exists", "does_not_exist"].includes(operator)) {
      hasValue = false;
    }
  }

  return { hasKey, hasValue };
};

const filterTypesWhichHasKey: ResourceExplorerDynamicFilterItemType[] = [
  "tag",
  "dimension_id",
];
