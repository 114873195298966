import { FC } from "react";
import { Typography } from "@mui/material";

interface CalculatedFromToTextProps {
  from?: string;
  to?: string;
}

export const CalculatedFromToText: FC<CalculatedFromToTextProps> = ({
  from,
  to,
}) => {
  if (!from || !to) {
    return null;
  }

  return (
    <Typography variant="caption" color="text.secondary" mt={2}>
      From{" "}
      <Typography variant="inherit" fontWeight="medium" component="span">
        {from}
      </Typography>{" "}
      to{" "}
      <Typography variant="inherit" fontWeight="medium" component="span">
        {to}
      </Typography>
    </Typography>
  );
};
