import * as yup from "yup";
import {
  FilterItemWithType,
  FilterKeyType,
} from "../../../../../../../services/cloudchipr.api";
import { statisticsFilterTypes } from "../../../utils/constants/filter-types/statistics-filter-types";
import { isSmartTag } from "../../../utils/helpers/isSmartTag";
import { SmartTagFilterValueValidation } from "../../filter-by-type/SmartTagFilter";
import { durationFilterTypes } from "../../../utils/constants/filter-types/duration-filter-types";
import { dateFilterTypes } from "../../../utils/constants/filter-types/date-filter-types";
import { StatisticsFilterValueValidation } from "../../filter-by-type/statistic-filter/StatisticsFilter";
import { SimpleValueFilterValueValidation } from "../../filter-by-type/SimpleValueFilter";
import { TagFilterValueValidation } from "../../filter-by-type/tag-filter/TagFilter";
import { RelativeTimeFilterValueValidation } from "../../filter-by-type/RelativeTimeFilter";
import { DateSelectionFilterValueValidation } from "../../filter-by-type/DateSelectionFilter";
import { autocompleteFilterTypes } from "../../../utils/constants/filter-types/autocomplete-filter-types";
import { AutocompleteFilterValueValidation } from "../../filter-by-type/autocomplete-filters/AutocompleteFilterBody";
import { UnusedSinceFilterValueValidation } from "../../filter-by-type/UnusedSinceFilter";
import { LabelFilterValueValidation } from "../../filter-by-type/LabelFilter";

const filterItemValidationSchema = yup.lazy(
  ({ filter, type: resourceType }: FilterItemWithType) => {
    const { key } = filter;

    let valueValidation: any = SimpleValueFilterValueValidation;

    if (statisticsFilterTypes.has(key)) {
      valueValidation = StatisticsFilterValueValidation;
    }

    if (isSmartTag(key)) {
      valueValidation = SmartTagFilterValueValidation;
    }

    if (key === "tag") {
      valueValidation = TagFilterValueValidation;
    }

    if (key === "label") {
      valueValidation = LabelFilterValueValidation;
    }

    if (
      autocompleteFilterTypes.has(key) &&
      // todo: this is temporary solution, change all filters body and validation make them depended on resourceType
      resourceType !== "rds_snapshot_source"
    ) {
      valueValidation = AutocompleteFilterValueValidation;
    }

    if (durationFilterTypes.has(key)) {
      valueValidation = RelativeTimeFilterValueValidation;
    }

    if (key === "unused_since") {
      valueValidation = UnusedSinceFilterValueValidation;
    }

    if (dateFilterTypes.has(key)) {
      valueValidation = DateSelectionFilterValueValidation;
    }

    return yup.object({
      filter: yup.object({
        key: yup.string().required(),
        operator: yup.string().required(),
        metric: yup.object().when("key", {
          is: (key: FilterKeyType) => statisticsFilterTypes.has(key),
          then: yup.object({
            statistics_type: yup.string().required(),
            since: yup.object({
              value: yup
                .number()
                .integer()
                .required()
                .min(0)
                .when("unit", {
                  is: "hours",
                  then: (schema) => schema.max(24),
                  otherwise: (schema) => schema.max(31),
                }),
              unit: yup.string().required().oneOf(["hours", "days"]),
            }),
          }),
          otherwise: yup.object(),
        }),
        value: valueValidation,
      }),
    });
  },
);

export const filtersValidationSchema = yup.object({
  filter_items: yup
    .array()
    // @ts-expect-error: something wrong with the typescript, this is gotten from the YUP github example: https://github.com/jquense/yup
    .of(filterItemValidationSchema),
});
