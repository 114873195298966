import { createAsyncThunk } from "@reduxjs/toolkit";
import { cloudChiprApi } from "../../../../../services/cloudchipr.api";
import { RecommendationGroupType } from "../../../../../components/pages/recommendations/components/off-hours-recommendations/utils/types/types";

export const getWeeklyOffHoursRecommendationsThunk = createAsyncThunk(
  "offHoursRecommendations/getWeeklyOffHoursRecommendationsThunk",
  async (
    {
      group,
      dismissed,
    }: {
      group: RecommendationGroupType;
      dismissed?: boolean;
    },
    { dispatch },
  ) => {
    const {
      getUsersMeOrganisationsCurrentResourcesWeeklyOffHoursRecommendations,
    } = cloudChiprApi.endpoints;

    return await dispatch(
      getUsersMeOrganisationsCurrentResourcesWeeklyOffHoursRecommendations.initiate(
        { group, isDismissed: dismissed },
        { forceRefetch: true },
      ),
    ).unwrap();
  },
);
