import { getCurrentProvidersByProviderBillingStatusDataSelector } from "./getCurrentProvidersByProviderBillingStatusDataSelector";
import {
  BillingCollectionStatus,
  ProviderType,
} from "../../../../services/cloudchipr.api";
import { RootState } from "../../../store";

export const billingStatusByProviderSelector = (
  state: RootState,
  provider: ProviderType,
): BillingCollectionStatus | undefined => {
  return getCurrentProvidersByProviderBillingStatusDataSelector(state, provider)
    ?.status;
};
