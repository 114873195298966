import { createAsyncThunk } from "@reduxjs/toolkit";
import { cloudChiprApi } from "../../../services/cloudchipr.api";

export const getUsersPreferenceByKeyThunk = createAsyncThunk(
  "userPreference/getUsersPreferenceByKeyThunk",
  async (
    { key, forceRefetch }: { key: string; forceRefetch?: boolean },
    { dispatch },
  ) => {
    const { getUsersMeCurrentPreferenceByKey } = cloudChiprApi.endpoints;

    return await dispatch(
      getUsersMeCurrentPreferenceByKey.initiate({ key }, { forceRefetch }),
    ).unwrap();
  },
);
