import { FC, useCallback, useMemo } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../../store/hooks";
import {
  setExecutionLogFilters,
  setExecutionLogPagination,
} from "../../../../../../store/execution-log/executionLogSlice";
import { getExecutionLogThunk } from "../../../../../../store/execution-log/thunks/getExecutionLogThunk";
import { executionLogsAccountsStatuses } from "../../../utils/constants/common";
import { useGetUsersMeAccountsQuery } from "../../../../../../services/cloudchipr.api";
import { DropdownSelect } from "../../../../../common/select/dropdown-select/DropdownSelect";
import { accountOptionFilterFn } from "../../../../../common/account-select/utils/helpers/accountOptionFilterFn";
import { executionLogsAccountsGroupedByProviderAndOrganization } from "../../../../../../store/accounts/selectors/execution-logs/executionLogsAccountsGroupedByProviderAndOrganization";
import { AccountOptionLabel } from "../../../../../common/account-select/components/AccountOptionLabel";
import { executionLogFiltersSelector } from "../../../../../../store/execution-log/selectors/executionLogFiltersSelector";
import { arrayIsEqual } from "../../../../../../utils/arrayIsEqual";

export const ExecutionLogsAccountFilter: FC = () => {
  const dispatch = useAppDispatch();

  const statuses = executionLogsAccountsStatuses.join(",");
  const { data, isLoading } = useGetUsersMeAccountsQuery({ statuses });

  const groupedAllAccounts = useAppSelector(
    executionLogsAccountsGroupedByProviderAndOrganization,
  );
  const executionLogFilters = useAppSelector(executionLogFiltersSelector);

  const options = useMemo(() => {
    return groupedAllAccounts.map((option) => {
      return {
        value: option.value,
        rawValue: option,
        listItemHeight: option?.groupName?.includes(option.orgId ?? "")
          ? 55
          : undefined,
        label: (
          <AccountOptionLabel
            option={option}
            ems={false}
            total={false}
            showStatus={
              option.status === "deactivated" || option.status === "deleted"
            }
          />
        ),
      };
    });
  }, [groupedAllAccounts]);

  const filterSubmitHandler = useCallback(
    (accountIds: string[]) => {
      if (arrayIsEqual(accountIds, executionLogFilters?.accountIds ?? [])) {
        return;
      }

      dispatch(setExecutionLogFilters({ accountIds }));
      dispatch(setExecutionLogPagination({ page: 0 }));
      dispatch(getExecutionLogThunk({}));
    },
    [dispatch, executionLogFilters],
  );

  if (!data?.length && !isLoading) {
    return null;
  }

  return (
    <DropdownSelect
      listWidth={510}
      label="Accounts"
      filterFn={accountOptionFilterFn}
      options={options}
      optionsLoading={isLoading}
      submitHandlerOnClose={filterSubmitHandler}
    />
  );
};
