import { FC, Fragment, useCallback, useState } from "react";
import { Chip, Stack, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import {
  useGetUsersMeQuery,
  usePatchUsersMeMutation,
} from "../../../../../../../services/cloudchipr.api";
import { useAppDispatch } from "../../../../../../../store/hooks";
import { setLogout } from "../../../../../../../store/auth/authSlice";
import { ConfirmationDialog } from "../../../../../../common/modals/ConfirmationDialog";

export const AuthMFA: FC = () => {
  const { data, refetch } = useGetUsersMeQuery({});
  const [patchTrigger, { isLoading }] = usePatchUsersMeMutation();
  const [disabledDialogOpen, setDisabledDialogOpen] = useState(false);
  const [logoutDialogOpen, setLogoutDialogOpen] = useState(false);

  const dispatch = useAppDispatch();
  const useMfa = data?.use_mfa;

  const handleCloseConfirmDialog = useCallback(() => {
    setDisabledDialogOpen(false);
  }, []);

  const handleLaterClick = useCallback(() => {
    setLogoutDialogOpen(false);
  }, []);

  const handleToggleMFAClick = useCallback(async () => {
    handleCloseConfirmDialog();
    await patchTrigger({ body: { use_mfa: !useMfa } });
    await refetch();
    if (!useMfa) {
      setLogoutDialogOpen(true);
    }
  }, [useMfa, patchTrigger, refetch, handleCloseConfirmDialog]);

  const handleOpenConfirmDialog = useCallback(() => {
    if (useMfa) {
      setDisabledDialogOpen(true);
    } else {
      handleToggleMFAClick();
    }
  }, [handleToggleMFAClick, useMfa]);

  const handleLogoutNow = useCallback(() => {
    dispatch(setLogout(true));
  }, [dispatch]);

  if (useMfa === undefined) {
    return null;
  }

  return (
    <Fragment>
      <Stack direction="row" alignItems="center" gap={2}>
        <Typography variant="h6">Multi-Factor Authentication (MFA)</Typography>
        <Chip
          label={useMfa ? "Connected" : "Not Enabled"}
          color={useMfa ? "success" : "warning"}
          variant="outlined"
          size="small"
        />
      </Stack>
      <Typography variant="body1" color="text.secondary" mt={2} mb={5}>
        {useMfa
          ? "Your multi-factor authentication is enabled now, so your account is protected with an additional password"
          : "Two-factor authentication adds an additional layer of security to your account by requiring more than just a password to sign in."}
      </Typography>

      <LoadingButton
        variant="outlined"
        color={useMfa ? "error" : "primary"}
        onClick={handleOpenConfirmDialog}
        loading={isLoading}
      >
        {useMfa
          ? "Disable multi-factor authentication"
          : "Enable multi-factor authentication"}
      </LoadingButton>

      {disabledDialogOpen && (
        <ConfirmationDialog
          open={disabledDialogOpen}
          title="Disable MFA?"
          dialogContent="Your account is more secure when MFA is enabled. Do you still want to disable MFA?"
          CancelButtonProps={{
            onClick: handleCloseConfirmDialog,
          }}
          ConfirmButtonProps={{
            onClick: handleToggleMFAClick,
            children: "Disable",
          }}
          onClose={handleCloseConfirmDialog}
        />
      )}
      {logoutDialogOpen && (
        <ConfirmationDialog
          open={logoutDialogOpen}
          title="MFA is Enabled"
          dialogContent="Please logout and login again to set up your verification codes generation method."
          CancelButtonProps={{
            onClick: handleLaterClick,
          }}
          ConfirmButtonProps={{
            onClick: handleLogoutNow,
            children: "Logout now",
          }}
          onClose={handleLaterClick}
        />
      )}
    </Fragment>
  );
};
