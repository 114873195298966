import { costAndUsageSumWidgetSetupSelector } from "./costAndUsageSumWidgetSetupSelector";
import { RootState } from "../../../../store";
import { CostAndUsageSumSetupType } from "../../../utils/types/setups/costAndUsageSumSetupType";

export const costAndUsageSumSetupPropertyByKeySelector =
  (key: keyof CostAndUsageSumSetupType) =>
  (state: RootState): any => {
    const setup = costAndUsageSumWidgetSetupSelector(state);

    if (!setup) {
      return null;
    }

    return setup[key];
  };
