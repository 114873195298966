import { FC, useCallback } from "react";
import {
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Tooltip,
} from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../../store/hooks";
import { connectedOrganizationExistsSelector } from "../../../../../../../../../store/commitments/selectors/organisations/connectedOrganizationExistsSelector";
import { commitmentsRecommendationsAccountScopeSelector } from "../../../../../../../../../store/commitments/selectors/recommendations/filters/commitmentsRecommendationsAccountScopeSelector";
import {
  setCommitmentsRecommendationsAccountIds,
  setCommitmentsRecommendationsAccountScope,
} from "../../../../../../../../../store/commitments/commitmentsSlice";

interface RecommendationLevelFilterPayerProps {
  toggleOpen(): void;
}
export const RecommendationLevelFilterPayer: FC<
  RecommendationLevelFilterPayerProps
> = ({ toggleOpen }) => {
  const scope = useAppSelector(commitmentsRecommendationsAccountScopeSelector);
  const orgConnected = useAppSelector(connectedOrganizationExistsSelector);
  const disabled = !orgConnected;
  const dispatch = useAppDispatch();
  const clickHandler = useCallback(() => {
    if (disabled) {
      return;
    }
    toggleOpen();
    dispatch(setCommitmentsRecommendationsAccountScope("payer"));
    dispatch(setCommitmentsRecommendationsAccountIds([]));
  }, [dispatch, disabled, toggleOpen]);

  return (
    <ListItemButton
      onClick={clickHandler}
      selected={scope === "payer"}
      sx={{
        py: 0.5,
        "&:hover": disabled
          ? undefined
          : { bgcolor: "grey.100", cursor: "pointer" },
        color: disabled ? "text.disabled" : "text.primary",
      }}
    >
      <ListItemText>Payer</ListItemText>
      <ListItemIcon sx={{ minWidth: "auto", ml: 1 }}>
        <Tooltip
          title="Payer - Recommendations at the Organizations master account level are calculated considering usage across all accounts that have Reserved Instances (RI) or Savings Plans discount sharing enabled. This is to recommend a commitment that maximizes savings across your accounts."
          placement="top"
          arrow
        >
          <InfoOutlinedIcon color="action" fontSize="small" />
        </Tooltip>
      </ListItemIcon>
    </ListItemButton>
  );
};
