import { FC } from "react";
import { Link } from "@mui/material";
import LinkIcon from "@mui/icons-material/Link";
interface JiraSnackbarLinkProps {
  link: string;
}

export const JiraSnackbarLink: FC<JiraSnackbarLinkProps> = ({ link }) => {
  const title = link.split("/").at(-1);

  return (
    <Link
      gap={1}
      href={link}
      display="flex"
      color="inherit"
      target="_blank"
      underline="hover"
      alignItems="center"
    >
      {title}
      <LinkIcon />
    </Link>
  );
};
