import { FC } from "react";
import { Stack, Typography } from "@mui/material";
import MenuList from "@mui/material/MenuList";
import {
  FilterItemSelectMenuItem,
  NewFilterAddFn,
} from "./FilterItemSelectMenuItem";
import { ResourceExplorerPossibleFilterV2 } from "../../../../../../../services/cloudchipr.api";

interface FilterItemSelectProps {
  filters: ResourceExplorerPossibleFilterV2[];
  onSelect: NewFilterAddFn;
}

export const FilterItemSelect: FC<FilterItemSelectProps> = ({
  filters,
  onSelect,
}) => {
  return (
    <Stack
      p={1}
      width={360}
      maxHeight={filters.length ? 350 : undefined}
      overflow="auto"
    >
      {filters.length ? (
        <MenuList disablePadding>
          {filters.map((filterItem) => {
            return (
              <FilterItemSelectMenuItem
                key={filterItem.key + filterItem.filter_provider}
                filter={filterItem}
                onSelect={onSelect}
              />
            );
          })}
        </MenuList>
      ) : (
        <Typography variant="caption" align="center" p={2}>
          No options found.
        </Typography>
      )}
    </Stack>
  );
};
