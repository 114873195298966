import { Row } from "@tanstack/react-table";
import { ReMixedVisibilitiesItemType } from "../../../../resource-explorer-all-views/components/table/utils/types/types";
import { DashboardMixedVisibilitiesItemType } from "../../../../dashboard-all-items/components/table/utils/types/types";
import { isHierarchyFolder } from "../../../../../../store/navigation/utils/helpers/isHierarchyFolder";
import { formatDate } from "../../../../../../utils/helpers/date-time/datetime-format";

export const hierarchyAllItemsModifyByFilter = <
  T extends ReMixedVisibilitiesItemType | DashboardMixedVisibilitiesItemType,
>(
  row: Row<T>,
  _: string,
  keyword: string,
) => {
  const data = row.original;
  const filterValue = keyword.toLowerCase().trim();
  const filterData =
    `${formatDate(data.modified_at ?? "", { type: "date" })} ${data.modified_by?.email} ${data.modified_by?.name}`
      .toLowerCase()
      .trim();

  if (isHierarchyFolder(data)) {
    const childViewsFilterData = data.items?.reduce((cur, item) => {
      return `${cur} ${formatDate(item.modified_at ?? "", { type: "date" })} ${item.modified_by?.email} ${item.modified_by?.name}`;
    }, "");

    return `${filterData} ${childViewsFilterData?.toLowerCase().trim()}`.includes(
      filterValue,
    );
  }

  return filterData.includes(filterValue);
};
