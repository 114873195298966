import { RootState } from "../../store";
import { cloudChiprApi } from "../../../services/cloudchipr.api";
import { sendSingleReportFixedCacheKey } from "../utils/constants/fixedCacheKeys";

const endpoint =
  cloudChiprApi.endpoints.postUsersMeOrganisationsCurrentReportsOneTime.select(
    sendSingleReportFixedCacheKey,
  );

export const sendSingleReportLoadingSelector = (state: RootState) => {
  return endpoint(state)?.isLoading;
};
