import { createDraftSafeSelector } from "@reduxjs/toolkit";
import { resourceExplorerHierarchyMixedVisibilitiesDataSelector } from "./resourceExplorerHierarchyMixedVisibilitiesDataSelector";
import { getFlattenMixedHierarchyData } from "../../../common/utils/helpers/getFlattenMixedHierarchyData";
import { getFlattenMixedHierarchyMap } from "../../../common/utils/helpers/getFlattenMixedHierarchyMap";

export const resourceExplorerFlattenHierarchyMixedVisibilitiesMapSelector =
  createDraftSafeSelector(
    [resourceExplorerHierarchyMixedVisibilitiesDataSelector],
    (data) => {
      if (!data) {
        return null;
      }

      const flattenData = getFlattenMixedHierarchyData(data);

      return getFlattenMixedHierarchyMap(flattenData);
    },
  );
