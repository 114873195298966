import { FC, ReactNode } from "react";
import { Table, TableBody, TableRow, Typography } from "@mui/material";
import TableCell from "@mui/material/TableCell";

export interface AutomationPreviewTableProps {
  rows: {
    title: string;
    label: string | ReactNode;
  }[];
}

export const AutomationPreviewTable: FC<AutomationPreviewTableProps> = ({
  rows,
}) => {
  return (
    <Table size="small">
      <TableBody>
        {rows.map(({ title, label }, index) => {
          const border = index === rows.length - 1 ? "none" : undefined;
          return (
            <TableRow key={title}>
              <TableCell sx={{ px: 2, py: 1.5, width: 220, border }}>
                <Typography
                  variant="body2"
                  fontWeight="medium"
                  whiteSpace="nowrap"
                >
                  {title}
                </Typography>
              </TableCell>

              <TableCell sx={{ border }}>
                {typeof label === "string" ? (
                  <Typography variant="body2">{label}</Typography>
                ) : (
                  label
                )}
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
};
