import { getCostAnomalyAlertsLoadingSelector } from "./cost-anomaly-alerts/getCostAnomalyAlertsLoadingSelector";
import { getUtilizationAlertsLoadingSelector } from "./utilization-alerts/getUtilizationAlertsLoadingSelector";
import { RootState } from "../../store";
import { integrationsLoadingSelector } from "../../integrations/selectors/integrationsLoadingSelector";
import { organizationsForCommitmentsLoadingSelector } from "../../commitments/selectors/organisations/organizationsForCommitmentsLoadingSelector";

export const alertsPageDataLoadingSelector = (state: RootState) => {
  const costAnomalyLoading = getCostAnomalyAlertsLoadingSelector(state);
  const utilizationLoading = getUtilizationAlertsLoadingSelector(state);
  const integrationsLoading = integrationsLoadingSelector(state);
  const organizationsLoading =
    organizationsForCommitmentsLoadingSelector(state);

  return (
    costAnomalyLoading ||
    utilizationLoading ||
    integrationsLoading ||
    organizationsLoading
  );
};
