import { FC, useMemo } from "react";
import { capitalize } from "@mui/material";
import { TypographyWithTooltip } from "../../../../../../../../../common/TypographyWithTooltip";

interface PublicAccessCellProps {
  accessType?: string;
}

export const PublicAccessCell: FC<PublicAccessCellProps> = ({ accessType }) => {
  const title = useMemo(
    () =>
      accessType
        ?.split("_")
        ?.map((word) => capitalize(word))
        ?.join(" "),
    [accessType],
  );

  return <TypographyWithTooltip variant="inherit" title={title ?? ""} />;
};
