import { VirtualItem } from "react-virtual";

export const calculateVirtualizationPaddings = (
  virtualItems: VirtualItem[],
  totalSize: number,
) => {
  return {
    paddingTop: virtualItems.length > 0 ? virtualItems?.[0]?.start || 0 : 0,
    paddingBottom:
      virtualItems.length > 0
        ? totalSize - (virtualItems?.[virtualItems.length - 1]?.end || 0)
        : 0,
  };
};
