import { FC } from "react";
import { LinearProgress, Stack, Typography } from "@mui/material";

import { RolePermissionsCollapse } from "./role-permission-collapse/RolePermissionsCollapse";
import { useAppSelector } from "../../../../../../store/hooks";
import { userRoleDataPermissionsSelector } from "../../../../../../store/user/selectors/roles/userRoleDataPermissionsSelector";
import { userRoleDataPermissionsGroupsSelector } from "../../../../../../store/user/selectors/roles/userRoleDataPermissionsGroupsSelector";
import { userRolePermissionsGettingLoadingSelector } from "../../../../../../store/user/selectors/roles/loading/userRolePermissionsGettingLoadingSelector";
import { CreateRoleGroupedPermissionsToolbar } from "../list/create-role-drawer/components/CreateRoleGroupedPermissionsToolbar";

export const UserRoleGroupedPermissions: FC = () => {
  const permissions = useAppSelector(userRoleDataPermissionsSelector);
  const groups = useAppSelector(userRoleDataPermissionsGroupsSelector);
  const loading = useAppSelector(userRolePermissionsGettingLoadingSelector);

  if (loading) {
    return <LinearProgress />;
  }

  if (!permissions) {
    return null;
  }

  return (
    <Stack spacing={1}>
      <CreateRoleGroupedPermissionsToolbar />

      {groups.length === 0 ? (
        <Typography
          variant="body1"
          fontWeight="normal"
          pt={6}
          color="text.secondary"
          textAlign="center"
        >
          No matching results found.
        </Typography>
      ) : (
        groups.map((item) => {
          const group = permissions?.[item];
          return (
            <RolePermissionsCollapse
              key={item}
              group={item}
              data={group.data}
              collapsed={group?.collapsed}
            />
          );
        })
      )}
    </Stack>
  );
};
