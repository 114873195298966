import { RootState } from "../../../../store";
import { cloudChiprApi } from "../../../../../services/cloudchipr.api";
import { userRoleUpdatingFixedCacheKey } from "../../../thunks/roles/updateUserRoleThunk";

const selector =
  cloudChiprApi.endpoints.patchUsersMeOrganisationsCurrentRolesByRoleId.select;

export const userRoleCreationLoadingSelector = (state: RootState) => {
  return selector(userRoleUpdatingFixedCacheKey)(state)?.isLoading;
};
