import { FC, Fragment } from "react";
import { Stack } from "@mui/material";
import { ReportsDrawerScheduleFrequencies } from "./ReportsDrawerScheduleFrequencies";
import { ReportsDrawerDailySchedule } from "./ReportsDrawerDailySchedule";
import { ReportsDrawerWeeklySchedule } from "./ReportsDrawerWeeklySchedule";
import { ReportsDrawerMonthlySchedule } from "./ReportsDrawerMonthlySchedule";
import { useAppSelector } from "../../../../../../../store/hooks";
import { reportsScheduleDataByKeySelector } from "../../../../../../../store/reports/selector/reportsScheduleDataByKeySelector";

export const ReportsDrawerScheduleSection: FC = () => {
  const scheduleFrequency = useAppSelector(
    reportsScheduleDataByKeySelector("frequency"),
  );

  return (
    <Fragment>
      <ReportsDrawerScheduleFrequencies />

      <Stack
        gap={1.5}
        direction="row"
        alignItems="center"
        justifyContent="flex-start"
        flexWrap="wrap"
      >
        {scheduleFrequency === "daily" && <ReportsDrawerDailySchedule />}
        {scheduleFrequency === "weekly" && <ReportsDrawerWeeklySchedule />}
        {(scheduleFrequency === "monthly_by_day" ||
          scheduleFrequency === "monthly_by_weekday") && (
          <ReportsDrawerMonthlySchedule />
        )}
      </Stack>
    </Fragment>
  );
};
