import { FC } from "react";
import LinkIcon from "@mui/icons-material/Link";
import { CopyIconButton } from "../../../../../common/CopyIconButton";

interface ExecutionLogsDataGridLinkCopyCellProps {
  id: string;
}

export const ExecutionLogsDataGridLinkCopyCell: FC<
  ExecutionLogsDataGridLinkCopyCellProps
> = ({ id }) => {
  return (
    <CopyIconButton
      data={`${window.location.host}/execution-log?ids=${id}`}
      TooltipProps={{ placement: "top" }}
    >
      <LinkIcon fontSize="small" />
    </CopyIconButton>
  );
};
