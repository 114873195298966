import { createDraftSafeSelector } from "@reduxjs/toolkit";
import { generateCombinatedChartData } from "./utils/helpers/generateCombinatedChartData";
import { resourceExplorerChartDataSelector } from "./resourceExplorerChartDataSelector";
import { convertCostToPercent } from "./utils/helpers/convertCostToPercent";

export const resourceExplorerCombinedChartDataSelector =
  createDraftSafeSelector([resourceExplorerChartDataSelector], (costData) => {
    const percentData = convertCostToPercent(costData ?? []);
    return generateCombinatedChartData(costData, percentData);
  });
