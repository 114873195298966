import { cloudChiprApi } from "../../../../services/cloudchipr.api";
import { liveUsageMgmtProviderSelector } from "../store-selectors/liveUsageMgmtProviderSelector";
import { liveUsageMgmtAccountIdsSelector } from "../store-selectors/liveUsageMgmtAccountIdsSelector";
import { RootState } from "../../../store";

const selector =
  cloudChiprApi.endpoints
    .getUsersMeProvidersByProviderAccountsResourcesVisibilities.select;

export const getAccountsResourcesVisibilitiesSelector = (state: RootState) => {
  const provider = liveUsageMgmtProviderSelector(state);
  const accountIds = liveUsageMgmtAccountIdsSelector(state);

  if (!provider || !accountIds) {
    return;
  }
  return selector({
    provider,
    accountIds: accountIds.join(","),
  })(state)?.data;
};
