import { FC } from "react";
import { Chip, Divider, List, Stack, Typography } from "@mui/material";
import ListIcon from "@mui/icons-material/List";
import {
  AccountBoxOutlined,
  BusinessCenterOutlined,
  FilterAltOutlined,
  LockOutlined,
  NotificationsNoneOutlined,
  Payment,
  PeopleAltOutlined,
  VpnKeyOutlined,
  LockPersonOutlined,
} from "@mui/icons-material";
import { useFlag } from "@unleash/proxy-client-react";
import { NavItem } from "../nav-item/NavItem";
import { useAppAbility } from "../../../../services/permissions";
import { SettingsNavigationLogoutButton } from "../../../pages/settings/components/SettingsNavigationLogoutButton";

interface SettingsNavigationListItemsProps {
  disabled: boolean;
}

export const SettingsNavigationListItems: FC<
  SettingsNavigationListItemsProps
> = ({ disabled }) => {
  const { can } = useAppAbility();

  const enableUserRoleUsageFlow = useFlag("EnableUserRoleUsageFlow");
  const enableEmailPreferencesSettings = useFlag(
    "EnableEmailPreferencesSettings",
  );

  return (
    <Stack justifyContent="space-between" flexGrow={1}>
      <div>
        <List
          dense
          disablePadding
          subheader={
            <Typography variant="overline" pl={1} gutterBottom={false}>
              PREFERENCES
            </Typography>
          }
        >
          <NavItem
            comingSoon={
              <Chip
                variant="filled"
                color="default"
                label="Legacy"
                size="small"
                sx={{ fontSize: 10, height: 16, borderRadius: 1 }}
              />
            }
            primary="Categories"
            to="/settings/categories"
            icon={ListIcon}
          />

          <NavItem
            primary="Smart Tag"
            to="/settings/smart-tag"
            icon={FilterAltOutlined}
          />
        </List>

        <Divider sx={{ my: 1 }} />

        <List
          dense
          disablePadding
          subheader={
            <Typography variant="overline" pl={1} gutterBottom={false}>
              ORGANIZATION
            </Typography>
          }
        >
          <NavItem
            to="/settings/users"
            primary="Users"
            icon={PeopleAltOutlined}
            disabled={disabled}
          />
          {enableUserRoleUsageFlow && (
            <NavItem
              to="/settings/roles"
              primary="Roles"
              icon={LockPersonOutlined}
              disabled={disabled}
            />
          )}

          <NavItem
            to="/settings/billing"
            primary="Plans & Billing"
            icon={Payment}
            disabled={disabled}
          />

          {can("edit", "organisation") && (
            <NavItem
              to="/settings/organization"
              primary="General"
              icon={BusinessCenterOutlined}
              disabled={disabled}
            />
          )}

          <NavItem
            to="/settings/api-keys"
            primary="API Keys"
            icon={VpnKeyOutlined}
            disabled={disabled}
          />
        </List>

        <Divider sx={{ my: 1 }} />

        <List
          dense
          disablePadding
          subheader={
            <Typography variant="overline" pl={1}>
              PROFILE
            </Typography>
          }
        >
          <NavItem
            to="/settings/profile"
            icon={AccountBoxOutlined}
            primary="Profile"
            disabled={disabled}
          />

          {enableEmailPreferencesSettings && (
            <NavItem
              to="/settings/notifications"
              icon={NotificationsNoneOutlined}
              primary="Email Notifications"
              disabled={disabled}
            />
          )}

          <NavItem
            to="/settings/auth"
            icon={LockOutlined}
            primary="Account Security"
            disabled={disabled}
          />
        </List>
      </div>

      <SettingsNavigationLogoutButton inPopover={false} />
    </Stack>
  );
};
