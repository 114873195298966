import { FC, useCallback } from "react";
import { Box, Stack } from "@mui/material";
import { FilterRow } from "./FilterRow";
import { ResourceExplorerFilterProvider } from "../../../../../../services/cloudchipr.api";
import { AddFilterInProvider } from "../new-filter-creator/filters-actions/AddFilterInProvider";
import {
  FilterGroupNodeWithId,
  FiltersCombinationOperatorType,
  FilterTreeNodeWithId,
} from "../../utils/types/common";
import { generateFilterKey } from "../../utils/helpers/generateFilterKey";
import { ProviderIcon } from "../../../../../common/ProviderIcon";

interface ProviderFilterCardProps {
  groupIndex: number;
  filters: FilterGroupNodeWithId;
  filterProvider: ResourceExplorerFilterProvider;
  onFiltersChange(
    newFilters: FilterGroupNodeWithId | null,
    index: number,
  ): void;
}

export const ProviderFilterCard: FC<ProviderFilterCardProps> = ({
  groupIndex,
  filters,
  onFiltersChange,
  filterProvider,
}) => {
  const operatorChangeHandler = useCallback(
    (operator: FiltersCombinationOperatorType) => {
      onFiltersChange({ ...filters, operator }, groupIndex);
    },
    [onFiltersChange, filters, groupIndex],
  );

  const filterChangeHandler = useCallback(
    (newFilter: FilterTreeNodeWithId, index: number) => {
      onFiltersChange(
        {
          ...filters,
          items: filters.items.map((filter, i) => {
            if (i === index) {
              return newFilter;
            }

            return filter;
          }),
        },
        groupIndex,
      );
    },
    [onFiltersChange, filters, groupIndex],
  );

  const filterRemoveHandler = useCallback(
    (index: number) => {
      if (filters.items.length === 1) {
        onFiltersChange(null, groupIndex);
        return;
      }

      onFiltersChange(
        {
          ...filters,
          items: filters.items.filter((_, i) => i !== index),
        },
        groupIndex,
      );
    },
    [onFiltersChange, filters, groupIndex],
  );

  return (
    <Stack direction="row" borderRadius={2} bgcolor="grey.50" overflow="hidden">
      <Box p={1} bgcolor="grey.100">
        <ProviderIcon provider={filterProvider} />
      </Box>

      <Stack flex={1} overflow="hidden" p={1} spacing={1}>
        <Stack spacing={2} flex={1}>
          {filters.items.map((filter, index) => {
            return (
              <FilterRow
                index={index}
                filter={filter}
                filterProvider={filterProvider}
                onFilterRemove={filterRemoveHandler}
                key={generateFilterKey(filter)}
                parentOperator={filters.operator}
                onFiltersChange={filterChangeHandler}
                onOperatorChange={operatorChangeHandler}
              />
            );
          })}
        </Stack>

        <AddFilterInProvider
          groupIndex={groupIndex}
          filterProvider={filterProvider}
          filters={filters}
          onFiltersChange={onFiltersChange}
        />
      </Stack>
    </Stack>
  );
};
