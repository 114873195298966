import { FC } from "react";
import { Box } from "@mui/material";
import { useOffHoursNextRunDates } from "../../../../schedule/off-hours/utils/hooks/useOffHoursNextRunDates.hook";
import { useOffHoursPreviewDatesInfoHook } from "../../../../schedule/off-hours/utils/hooks/useOffHoursPreviewDatesInfo.hook";
import { AutomationSectionCard } from "../../../common/components/form/helper-components/AutomationSectionCard";
import { SchedulePreviewResources } from "../../../common/components/resources-preview-old/preview/SchedulePreviewResources";
import { automationIdSelector } from "../../../../../../store/automations/selectros/common/automationIdSelector";
import { automationNameSelector } from "../../../../../../store/automations/selectros/common/fields/automationNameSelector";
import { useAppSelector } from "../../../../../../store/hooks";
import { automationResourcesSelector } from "../../../../../../store/automations/selectros/common/fields/automationResourcesSelector";
import { automationAccountIdsSelector } from "../../../../../../store/automations/selectros/common/fields/automationAccountIdsSelector";
import { automationTimeZoneSelector } from "../../../../../../store/automations/selectros/common/fields/automationTimeZoneSelector";
import { automationStartDateSelector } from "../../../../../../store/automations/selectros/common/fields/automationStartDateSelector";
import { automationFrequencySelector } from "../../../../../../store/automations/selectros/common/automationFrequencySelector";
import { automationRegionsSelector } from "../../../../../../store/automations/selectros/common/fields/automationRegionsSelector";
import { offHoursSchedulerSelector } from "../../../../../../store/automations/selectros/off-hours/offHoursSchedulerSelector";
import { offHoursActionSelector } from "../../../../../../store/automations/selectros/off-hours/offHoursActionSelector";
import { generateCronForPayload } from "../../../../../../store/automations/utils/helpers/cron/generateCronForPayload";
import { automationEndDateSelector } from "../../../../../../store/automations/selectros/common/fields/automationEndDateSelector";
import { automationStatusSelector } from "../../../../../../store/automations/selectros/common/automationStatusSelector";

export const OffHoursResourcesPreview: FC = () => {
  const automationId = useAppSelector(automationIdSelector);
  const automationName = useAppSelector(automationNameSelector);
  const filter = useAppSelector(automationResourcesSelector);
  const accountIds = useAppSelector(automationAccountIdsSelector);
  const timeZone = useAppSelector(automationTimeZoneSelector);
  const startDate = useAppSelector(automationStartDateSelector);
  const endDate = useAppSelector(automationEndDateSelector);
  const automationFrequency = useAppSelector(automationFrequencySelector);
  const regions = useAppSelector(automationRegionsSelector);
  const scheduler = useAppSelector(offHoursSchedulerSelector);
  const action = useAppSelector(offHoursActionSelector);
  const status = useAppSelector(automationStatusSelector);

  const stopCron = generateCronForPayload(
    automationFrequency,
    scheduler?.stopTime ?? null,
    scheduler?.repeatValue,
    scheduler?.stopWeekDays,
  );
  const startCron = generateCronForPayload(
    automationFrequency,
    scheduler?.startTime ?? null,
    scheduler?.repeatValue,
    scheduler?.startWeekDays,
  );

  const { nextRunStartDate: nextStartRun, nextRunStopDate: nextStopRun } =
    useOffHoursNextRunDates({
      endDate,
      startDate: startDate ?? "",
      startCron,
      stopCron,
      timeZone,
    });

  const { nextRuns, frequency } = useOffHoursPreviewDatesInfoHook({
    timeZone,
    startCron,
    stopCron,
    nextStartRun,
    nextStopRun,
  });

  return (
    <AutomationSectionCard title="Select Services" icon="services" preview>
      <Box py={2}>
        <SchedulePreviewResources
          hideTitle
          scheduleId={automationId ?? ""}
          scheduleAction={action ?? null}
          frequency={frequency}
          nextRuns={nextRuns}
          regions={regions}
          name={automationName}
          filters={filter}
          accountIds={accountIds}
          status={status ?? "active"}
        />
      </Box>
    </AutomationSectionCard>
  );
};
