import { FC, Fragment } from "react";
import { IconButton, Menu } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { ReportEditAction } from "./ReportEditAction";
import { ReportDeleteAction } from "./ReportDeleteAction";
import { useMenuHook } from "../../../../../../../../utils/hooks/useMenu.hook";

interface ReportActionsCellProps {
  reportId: string;
}

export const ReportActionsCell: FC<ReportActionsCellProps> = ({ reportId }) => {
  const { anchor, open, openMenu, closeMenu } = useMenuHook();

  return (
    <Fragment>
      <IconButton onClick={openMenu}>
        <MoreVertIcon />
      </IconButton>
      <Menu anchorEl={anchor} open={open} onClose={closeMenu}>
        <ReportEditAction reportId={reportId} closeMenu={closeMenu} />
        <ReportDeleteAction reportId={reportId} closeMenu={closeMenu} />
      </Menu>
    </Fragment>
  );
};
