import { createDraftSafeSelector } from "@reduxjs/toolkit";
import { rightsizingRecommendationsDataSelector } from "./rightsizingRecommendationsDataSelector";
import { rightsizingAdvancedFiltersSettingsSelector } from "../advanced-filters/rightsizingAdvancedFiltersSettingsSelector";
import { recommendationAppliedTagsSetSelector } from "../../filters/recommendationAppliedTagsSetSelector";
import { recommendationAppliedProvidersSelector } from "../../filters/recommendationAppliedProvidersSelector";
import { recommendationAppliedTypesSelector } from "../../filters/recommendationAppliedTypesSelector";
import { recommendationAppliedAccountsSelector } from "../../filters/recommendationAppliedAccountsSelector";
import { rightsizingRecommendationsDataByFiltersFilterCombiner } from "../../../combiners/rightsizing/rightsizingRecommendationsDataByFiltersFilterCombiner";
import { RightSizingRecommendation } from "../../../../../services/cloudchipr.api";

export const rightsizingRecommendationsFilteredDataSelector =
  createDraftSafeSelector(
    [
      rightsizingRecommendationsDataSelector,
      recommendationAppliedProvidersSelector,
      recommendationAppliedTypesSelector,
      recommendationAppliedAccountsSelector,
      recommendationAppliedTagsSetSelector,
      rightsizingAdvancedFiltersSettingsSelector,
    ],
    (
      data,
      providers,
      types,
      accounts,
      tags,
      advancedFilters,
    ): RightSizingRecommendation[] => {
      if (!data) {
        return [];
      }

      return rightsizingRecommendationsDataByFiltersFilterCombiner(
        data,
        providers,
        types,
        accounts,
        tags,
        advancedFilters,
      );
    },
  );
