import { Fragment } from "react";
import { capitalize, Typography } from "@mui/material";
import { formatDate } from "../../../../../../../../../../utils/helpers/date-time/datetime-format";

interface SupportTypeWithDateProps {
  supportType?: string;
  expirationDate?: string;
}

export const SupportTypeWithDate = ({
  supportType,
  expirationDate,
}: SupportTypeWithDateProps) => {
  if (!supportType || !expirationDate) {
    return "-";
  }
  const formattedDate = formatDate(expirationDate, {
    type: "dateWithComma",
  });

  return (
    <Fragment>
      <Typography variant="body2">
        {capitalize(supportType.toLowerCase())}
      </Typography>
      <Typography variant="body2">until {formattedDate}</Typography>
    </Fragment>
  );
};
