import { RootState } from "../../../../store";
import { cloudChiprApi } from "../../../../../services/cloudchipr.api";
import { categoryUpdateFixedCacheKey } from "../../../thunks/categories/updateCategoryByIdThunk";

const categoryUpdateSelector =
  cloudChiprApi.endpoints.putUsersMeOrganisationsCurrentDimensionsByDimensionIdCategoriesAndCategoryId.select(
    categoryUpdateFixedCacheKey,
  );

export const categoryUpdateLoadingSelector = (state: RootState) => {
  return !!categoryUpdateSelector(state)?.isLoading;
};
