import { orgCurrentFilterTemplatesGroupedByProviderSelector } from "./orgCurrentFilterTemplatesGroupedByProviderSelector";
import { RootState } from "../../../store";
import { FilterSet, ProviderType } from "../../../../services/cloudchipr.api";

export const orgCurrentFilterTemplatesByProviderSelector = (
  state: RootState,
  provider: ProviderType | null,
): FilterSet[] | undefined => {
  const filterTemplates =
    orgCurrentFilterTemplatesGroupedByProviderSelector(state);
  if (!provider) {
    return;
  }
  return filterTemplates?.[provider];
};
