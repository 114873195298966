import { tasksListGridDataSelector } from "./tasksListGridDataSelector";
import { RootState } from "../../../store";
import { tasksListGetLoadingSelector } from "../loading/tasksListGetLoadingSelector";

export const tasksListIsEmptySelector = (state: RootState) => {
  const tasks = tasksListGridDataSelector(state);
  const loading = tasksListGetLoadingSelector(state);

  return !tasks?.length && !loading;
};
