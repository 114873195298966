import { FC, useCallback } from "react";
import {
  Avatar,
  CircularProgress,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import { FormikHandlers } from "formik";

interface JiraFormSelectComponentProps {
  label: string;
  name: string;
  value?: string | null;
  error?: string | boolean;
  disabled?: boolean;
  loading?: boolean;
  options: { value: string; label: string; imageUrl?: string }[];
  handleChange: FormikHandlers["handleChange"];
}

export const JiraFormSelectComponent: FC<JiraFormSelectComponentProps> = ({
  options,
  label,
  value,
  error,
  handleChange,
  disabled,
  name,
  loading,
}) => {
  const renderValue = useCallback(
    (val: any) =>
      loading ? "" : options.find(({ value }) => val === value)?.label || val,
    [options, loading],
  );

  return (
    <FormControl size="small">
      <InputLabel>{label}</InputLabel>
      <Select
        startAdornment={
          loading ? (
            <Stack alignItems="center" direction="row" spacing={2}>
              <Typography variant="body2">Loading</Typography>
              <CircularProgress disableShrink size={16} />
            </Stack>
          ) : undefined
        }
        disabled={disabled}
        fullWidth
        size="small"
        name={name}
        value={value || ""}
        label={label}
        onChange={handleChange}
        error={!!error}
        renderValue={renderValue}
      >
        {options.map(({ value, label, imageUrl }) => (
          <MenuItem key={value} value={value}>
            {imageUrl && (
              <Avatar
                sx={{ width: 24, height: 24, mr: 2 }}
                alt="avatar"
                src={imageUrl}
              />
            )}
            {label}
          </MenuItem>
        ))}
      </Select>
      {!!error && <FormHelperText error>{error}</FormHelperText>}
    </FormControl>
  );
};
