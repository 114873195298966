import { savingPlansCoverageRecommendationsDataSelector } from "./savingPlansCoverageRecommendationsDataSelector";
import {
  SavingsPlanAccountScope,
  SavingsPlanRecommendation,
} from "../../../../../../services/cloudchipr.api";
import { RootState } from "../../../../../store";

export const savingPlansCoverageRecommendationsDataGroupedByAccountScopeSelector =
  (
    state: RootState,
  ):
    | Partial<Record<SavingsPlanAccountScope, SavingsPlanRecommendation[]>>
    | undefined => {
    const data = savingPlansCoverageRecommendationsDataSelector(state);

    return data?.reduce(
      (result, item) => {
        if (item.account_scope === "payer") {
          result["payer"].push(item);
        } else {
          result["linked"].push(item);
        }
        return result;
      },
      {
        payer: [],
        linked: [],
      } as Record<SavingsPlanAccountScope, SavingsPlanRecommendation[]>,
    );
  };
