import { FC } from "react";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { Box, Stack, Tooltip, Typography } from "@mui/material";

interface ProtectionProps {
  protectedBy: string | null;
  placement?: "left" | "right";
}

export const Protection: FC<ProtectionProps> = ({
  protectedBy,
  placement = "left",
}) => {
  const icon = <LockOutlinedIcon fontSize="small" sx={{ color: "grey.600" }} />;

  if (!protectedBy) {
    return icon;
  }

  return (
    <Tooltip
      arrow
      placement={placement}
      componentsProps={{
        tooltip: { sx: customTooltipStyles },
      }}
      title={
        <Stack>
          <Typography variant="caption" color="text.secondary">
            Protection enabled by
          </Typography>
          <Typography variant="body2" fontWeight="medium">
            {protectedBy}
          </Typography>
        </Stack>
      }
    >
      <Box fontSize={0}>{icon}</Box>
    </Tooltip>
  );
};

const customTooltipStyles = {
  color: "text.primary",
  bgcolor: "background.paper",
  "& .MuiTooltip-arrow": {
    color: "background.paper",
  },
  boxShadow: 7,
  p: 1.5,
};
