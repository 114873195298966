import { FC } from "react";
import { Stack } from "@mui/material";
import { SavingsOpportunitiesMetadataCard } from "./SavingsOpportunitiesMetadataCard";
import { useAppSelector } from "../../../../../store/hooks";
import { savingsOpportunitiesCostSelector } from "../../../../../store/savings-opportunities/selectors/savingsOpportunitiesCostSelector";
import { useGetUsersMeOrganisationsCurrentBillingTotalCostQuery } from "../../../../../services/cloudchipr.api";

export const SavingsOpportunitiesMetadata: FC = () => {
  const savingsOpportunities = useAppSelector(savingsOpportunitiesCostSelector);

  const { data } = useGetUsersMeOrganisationsCurrentBillingTotalCostQuery();

  return (
    <Stack direction="row" spacing={1} p={1}>
      <SavingsOpportunitiesMetadataCard
        postFix="/mo"
        title="Total Cost"
        value={data?.total_cost ?? 0}
        tooltip="Total cloud cost over the past 30 days."
      />

      <SavingsOpportunitiesMetadataCard
        postFix="/mo"
        value={savingsOpportunities}
        title="Savings Opportunities"
        tooltip="Potential monthly savings based on filters."
      />
    </Stack>
  );
};
