import { FC, useCallback, useMemo } from "react";
import { Stack } from "@mui/material";
import {
  CommitmentUtilizationWidgetAggregationsSwitch,
  CommitmentUtilizationWidgetAggregationsSwitchProps,
} from "./CommitmentUtilizationWidgetAggregationsSwitch";
import AggregationsIcon from "../../../../../../../../../../../../../assets/images/icons/aggregations_icon.svg";
import { WidgetConfigSection } from "../../../../../../common/widget-configurations/WidgetConfigSection";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../../../../../../store/hooks";
import { commitmentUtilizationSetupPropertyByKeySelector } from "../../../../../../../../../../../../../store/dashboards/selectors/setups/commitment-utilization/commitmentUtilizationSetupPropertyByKeySelector";
import { WidgetCommitmentsUtilizationAggregation } from "../../../../../../../../../../../../../services/cloudchipr.api";
import { commitmentUtilizationWidgetAggregationChangeThunk } from "../../../../../../../../../../../../../store/dashboards/thunks/widgets/commitment-utilization/setup-change/commitmentUtilizationWidgetAggregationChangeThunk";

export const CommitmentUtilizationWidgetAggregationsSection: FC = () => {
  const dispatch = useAppDispatch();
  const aggregations = useAppSelector(
    commitmentUtilizationSetupPropertyByKeySelector("aggregation"),
  );

  const switchChecked = useMemo(
    () => Object.keys(aggregations).some((key) => !!aggregations[key]),
    [aggregations],
  );

  const handleSwitchChange = useCallback(() => {
    const aggregationsNewState = Object.keys(aggregations)?.reduce(
      (acc, key) => {
        acc[key as keyof WidgetCommitmentsUtilizationAggregation] =
          !switchChecked;
        return acc;
      },
      {} as WidgetCommitmentsUtilizationAggregation,
    );

    dispatch(
      commitmentUtilizationWidgetAggregationChangeThunk(aggregationsNewState),
    );
  }, [aggregations, switchChecked, dispatch]);

  return (
    <WidgetConfigSection
      title="Aggregations"
      icon={<img src={AggregationsIcon} alt="Aggregations Icon" />}
      switchChecked={switchChecked}
      onSwitchChange={handleSwitchChange}
    >
      <Stack spacing={1.5}>
        {aggregationsSwitchesData.map(({ selectorKey, title }) => (
          <CommitmentUtilizationWidgetAggregationsSwitch
            selectorKey={selectorKey}
            key={selectorKey}
            title={title}
          />
        ))}
      </Stack>
    </WidgetConfigSection>
  );
};

const aggregationsSwitchesData: CommitmentUtilizationWidgetAggregationsSwitchProps[] =
  [
    { selectorKey: "utilization", title: "Utilization" },
    { selectorKey: "commitments", title: "Commitments" },
    { selectorKey: "net_savings", title: "Net Savings" },
  ];
