import { useCallback } from "react";
import { useEffectOnceWhen } from "rooks";
import { SortModelItem } from "@ag-grid-community/core/dist/types/src/sortController";
import {
  usePostUsersMeCurrentPreferenceMutation,
  usePatchUsersMeCurrentPreferenceByKeyMutation,
  useGetUsersMeCurrentPreferenceByKeyQuery,
} from "../../../../../services/cloudchipr.api";
import type { SortChangedEvent } from "@ag-grid-community/core/dist/types/src/events";

type ReturnType = {
  sorting: SortModelItem[];
  onColumnsSortingChange(event: SortChangedEvent): void;
};

export const useSavingsOpportunitiesTableSortingHook = (): ReturnType => {
  const key = `c8r:$savings-opportunities-grid-rows-sorting`;

  const [create] = usePostUsersMeCurrentPreferenceMutation();
  const [update] = usePatchUsersMeCurrentPreferenceByKeyMutation();
  const {
    data: initialSettings,
    refetch,
    isLoading,
    isFetching,
  } = useGetUsersMeCurrentPreferenceByKeyQuery(
    { key },
    { refetchOnMountOrArgChange: true },
  );

  const sortingSettings = (
    initialSettings?.value ? JSON.parse(initialSettings?.value) : {}
  ) as SortModelItem;

  const saveChangedSort = useCallback(
    async (sortModel: SortModelItem) => {
      await update({ key, body: { value: JSON.stringify(sortModel) } });

      refetch();
    },
    [update, refetch, key],
  );

  const onColumnsSortingChange = useCallback(
    (event: SortChangedEvent) => {
      const columnState = event.api.getColumnState();
      const sortState = columnState
        .filter(({ sort }) => sort !== null)
        .map((s) => ({ colId: s.colId, sort: s.sort }))
        .at(0) as SortModelItem;

      if (sortState) {
        saveChangedSort(sortState);
      }
    },
    [saveChangedSort],
  );

  useEffectOnceWhen(
    async () => {
      await create({ body: { key, value: JSON.stringify(defaultSort) } });

      refetch();
    },
    !sortingSettings?.colId && !isLoading && !isFetching,
  );

  return {
    sorting: [!sortingSettings?.colId ? defaultSort : sortingSettings],
    onColumnsSortingChange,
  };
};

const defaultSort: SortModelItem = { colId: "monthly_savings", sort: "desc" };
