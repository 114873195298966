import { createAsyncThunk } from "@reduxjs/toolkit";
import { closeSnackbar, enqueueSnackbar, SnackbarKey } from "notistack";
import { createDashboardVisibilityHierarchyItemThunk } from "./createDashboardVisibilityHierarchyItemThunk";
import { updateDashboardVisibilityHierarchyThunk } from "./updateDashboardVisibilityHierarchyThunk";
import { PostV2UsersMeOrganisationsCurrentDashboardsApiArg } from "../../../../services/cloudchipr.api";

interface CreateDashboardVisibilityHierarchyItemByFolderIdThunkArg {
  name: string;
  folderId: string;
  visibility: PostV2UsersMeOrganisationsCurrentDashboardsApiArg["body"]["visibility"];
}

export const createDashboardVisibilityHierarchyItemByFolderIdThunk =
  createAsyncThunk(
    "dashboard/createDashboardVisibilityHierarchyItemByFolderId",
    async (
      {
        name,
        folderId,
        visibility,
      }: CreateDashboardVisibilityHierarchyItemByFolderIdThunkArg,
      { dispatch },
    ) => {
      try {
        const response = await dispatch(
          createDashboardVisibilityHierarchyItemThunk({ name, visibility }),
        ).unwrap();

        if (!response?.id) {
          return;
        }

        await dispatch(
          updateDashboardVisibilityHierarchyThunk({
            folderId,
            id: response.id,
            visibility,
            type: "dashboard",
          }),
        );

        return response;
      } catch (e) {
        // @ts-expect-error TODO: return to this after adding error type
        const errMessage = e?.data?.message || "Something went wrong";
        const snackbarId: SnackbarKey = enqueueSnackbar(errMessage, {
          variant: "snackbarAlert",
          AlertSnackBarProps: {
            severity: "error",
            onClose: () => closeSnackbar(snackbarId),
          },
        });
      }
    },
  );
