import { FC, useMemo } from "react";
import { Box } from "@mui/material";
import { grey } from "@mui/material/colors";
import { AgGridReact } from "@ag-grid-community/react";
import { getTargetMultipleResourcesGridColumns } from "./targetMultipleResourcesGridColumns";
import { agGridOverwrittenIcons } from "../../../../../../../../automations/workflows/data-grid/utils/constans/grid-icons";
import { taskManagementTargetResourcesSelector } from "../../../../../../../../../../store/task-management/selectors/form/target/resources/taskManagementTargetResourcesSelector";
import { useAppSelector } from "../../../../../../../../../../store/hooks";

export const TargetMultipleResources: FC = () => {
  const resources = useAppSelector(taskManagementTargetResourcesSelector);

  const resourceType = resources?.at(0)?.resource_type;

  const columnDefs = useMemo(() => {
    if (!resourceType) {
      return [];
    }

    return getTargetMultipleResourcesGridColumns(resourceType);
  }, [resourceType]);

  if (!resources) {
    return null;
  }

  return (
    <Box
      className="ag-theme-quartz"
      sx={tableWrapperStyles}
      height={((resources?.length ?? 0) + 1) * 50}
      minHeight={100}
    >
      <AgGridReact
        enableCellTextSelection
        rowData={resources}
        columnDefs={columnDefs}
        gridOptions={tableOptions}
      />
    </Box>
  );
};

const columnSortIconStyles = {
  "& .ag-sort-indicator-icon": { visibility: "hidden" },
  "& .ag-header-active:hover .ag-sort-indicator-icon": {
    visibility: "visible",
  },

  "& .ag-header-cell-sorted-desc .ag-sort-indicator-icon": {
    visibility: "visible !important",
  },
  "& .ag-header-cell-sorted-asc .ag-sort-indicator-icon": {
    visibility: "visible !important",
  },

  '& .ag-header-cell[aria-sort="ascending"] .ag-sort-indicator-icon': {
    visibility: "visible !important",
  },
  '& .ag-header-cell[aria-sort="descending"] .ag-sort-indicator-icon': {
    visibility: "visible !important",
  },
};

const rowHoverStyles = {
  "--ag-active-color": grey[100],
  "& .ag-row-hover": { backgroundColor: grey[100] },
};

const resizeHandle = {
  "& .ag-header-cell-resize": { width: 20, visibility: "hidden" },
  "& .ag-header-cell:hover .ag-header-cell-resize": { visibility: "visible" },
  "--ag-header-column-resize-handle-height": "80%",
};

export const tableWrapperStyles = {
  width: "100%",
  borderRadius: 2,
  border: "1px solid",
  borderColor: "grey.300",
  "& .ag-cell-wrapper": { height: "100%" },
  "& .ag-root-wrapper": { borderRadius: 0, border: "none" },
  "--ag-header-height": "44px",
  "--ag-header-background-color": grey[100],

  ...columnSortIconStyles,
  ...rowHoverStyles,
  ...resizeHandle,
};

export const tableOptions = {
  icons: agGridOverwrittenIcons,
};
