import { RootState } from "../../../../store";
import { commitmentsCurrentOrgIdSelector } from "../../common/commitmentsCurrentOrgIdSelector";
import { GetUsersMeOrganisationsCurrentReservationsRecommendationsApiArg } from "../../../../../services/cloudchipr.api";
import { reservationSelectedCoverageDataSelector } from "../../reservations/details-drawer/resource-type/reservationSelectedCoverageDataSelector";
import { commitmentsDetailsDrawerTargetTypeSelector } from "../../common/details-drawer/commitmentsDetailsDrawerTargetTypeSelector";

export const reservationsRecommendationsPayloadSelector = (
  state: RootState,
):
  | GetUsersMeOrganisationsCurrentReservationsRecommendationsApiArg
  | undefined => {
  const orgId = commitmentsCurrentOrgIdSelector(state);
  const type = commitmentsDetailsDrawerTargetTypeSelector(state);
  const coverageData = reservationSelectedCoverageDataSelector(state);

  const payloadData: GetUsersMeOrganisationsCurrentReservationsRecommendationsApiArg =
    {
      providerOrganisationId: orgId,
    };

  if (type === "resource_type") {
    payloadData.resourceType = coverageData?.resource_type?.resource_type;
    payloadData.region = coverageData?.resource_type?.region ?? undefined;
    payloadData.platform = coverageData?.resource_type?.platform ?? undefined;
    payloadData.instanceType =
      coverageData?.resource_type?.instance_type ?? undefined;
  }
  if (type === "account") {
    payloadData.accountId = coverageData?.account?.id;
  }

  return payloadData;
};
