import { FC, useCallback } from "react";
import { Button } from "@mui/material";
import {
  setRecommendationsReadyToRestore,
  setSelectedIds,
} from "../../../../../../store/recommendations/recommendationsSlice";
import { useAppDispatch, useAppSelector } from "../../../../../../store/hooks";
import { selectedIdsExistSelector } from "../../../../../../store/recommendations/selectors/selected-ids/selectedIdsExistSelector";
import { useAppAbility } from "../../../../../../services/permissions";

interface RecommendationRestoreButtonProps {
  recommendationId?: string;
  multiple?: boolean;
}

export const RecommendationRestoreButton: FC<
  RecommendationRestoreButtonProps
> = ({ multiple, recommendationId }) => {
  const dispatch = useAppDispatch();
  const { cannot } = useAppAbility();
  const canNotEditRecommendation = cannot("edit", "recommendation");

  const idsExist = useAppSelector(selectedIdsExistSelector);

  const onRestoreClick = useCallback(() => {
    if (recommendationId) {
      dispatch(setSelectedIds([recommendationId]));
    }

    dispatch(setRecommendationsReadyToRestore(true));
  }, [dispatch, recommendationId]);

  return (
    <Button
      size="small"
      onClick={onRestoreClick}
      disabled={(multiple && !idsExist) || canNotEditRecommendation}
      variant={multiple ? "contained" : "outlined"}
    >
      Restore
    </Button>
  );
};
