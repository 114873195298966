import { allAccountsGroupedByProviderAndOrgSelector } from "./allAccountsGroupedByProviderAndOrgSelector";
import { RootState } from "../../../store";
import { Account, ProviderType } from "../../../../services/cloudchipr.api";

export const allAccountsCostsByProviderAndOrgIdSelector = (
  state: RootState,
  provider: ProviderType,
  orgId: string,
) => {
  const allAccountsGroupedByProviderAndOrg =
    allAccountsGroupedByProviderAndOrgSelector(state);

  const providerOrganizations = allAccountsGroupedByProviderAndOrg?.[provider];

  const orgAccounts = providerOrganizations?.[orgId];

  if (!orgAccounts) {
    return null;
  }

  return {
    ems: costCombiner(orgAccounts, "ems"),
    spending: costCombiner(orgAccounts, "total_costs"),
    savedCost: costCombiner(orgAccounts, "total_monthly_saved_costs"),
  };
};

const costCombiner = (
  connectedAccounts: Account[] | null,
  key: "ems" | "total_costs" | "total_monthly_saved_costs",
): number => {
  return connectedAccounts?.reduce((acc, cur) => acc + cur[key], 0) ?? 0;
};
