import { FC } from "react";
import { LoadingButton } from "@mui/lab";
import { Alert, Stack, Typography } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";

interface JiraConnectActionProps {
  isLoading: boolean;
  connected: boolean;
  disabled: boolean;
  onConnect(): void;
}

export const JiraConnectAction: FC<JiraConnectActionProps> = ({
  onConnect,
  isLoading,
  connected,
  disabled,
}) => {
  return (
    <Stack alignItems="flex-end">
      {connected ? (
        <Typography variant="button" display="flex" alignItems="center" gap={1}>
          <CheckIcon />
          Connected
        </Typography>
      ) : (
        <LoadingButton
          variant="contained"
          loading={isLoading}
          disabled={disabled}
          onClick={onConnect}
        >
          Connect
        </LoadingButton>
      )}

      {connected && (
        <Alert severity="success" sx={{ width: "100%", mt: 5 }}>
          Connection tested.
        </Alert>
      )}
    </Stack>
  );
};
