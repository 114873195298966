import { FC, useCallback } from "react";
import {
  FormControl,
  Grid,
  GridProps,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import { Operator } from "../../../../../services/cloudchipr.api";

interface FilterItemOperatorTypeProps {
  value?: string;
  options?: Operator[];
  onChange(event: SelectChangeEvent): void;
  label?: string;
  gridProps?: GridProps;
}

export const FilterItemOperatorType: FC<FilterItemOperatorTypeProps> = ({
  value,
  options,
  onChange,
  label,
  gridProps,
}) => {
  const renderValue = useCallback(
    (value: any) => {
      return options?.find(({ key }) => key === value)?.primary_label;
    },
    [options],
  );

  return (
    <Grid item md={2} flexShrink={0} flexGrow={0} {...gridProps}>
      <FormControl size="xsmall" fullWidth>
        <InputLabel>{label ?? "Operator"}</InputLabel>
        <Select
          name="operator"
          labelId="operator-type"
          value={value || ""}
          label={label ?? "Operator"}
          onChange={onChange}
          renderValue={renderValue}
          MenuProps={{
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left",
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "left",
            },
          }}
        >
          {options?.map(({ key, primary_label, secondary_label }) => (
            <MenuItem key={key} value={key} sx={{ gap: 4 }}>
              <ListItemText> {primary_label}</ListItemText>
              {secondary_label && (
                <Typography variant="body2" color="text.secondary">
                  {secondary_label}
                </Typography>
              )}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Grid>
  );
};
