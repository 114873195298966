import { FC } from "react";
import { Chip } from "@mui/material";

interface NodeGroupModeChipProps {
  data: string | number;
}

export const modeCellParentStyles = {
  width: "100%",
  textAlign: "end",
};

export const NodeGroupModeChip: FC<NodeGroupModeChipProps> = ({ data }) => {
  if (!data) {
    return null;
  }

  return <Chip label={`Mode: ${data}`} variant="outlined" />;
};
