import { FC } from "react";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Collapse,
  Typography,
} from "@mui/material";
import { useToggle } from "rooks";
import KeyboardArrowUpOutlinedIcon from "@mui/icons-material/KeyboardArrowUpOutlined";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import { FiltersDisabledView } from "../../../../../common/filters/filters-disabled-view/FiltersDisabledView";
import { getResourceTypeName } from "../../../../../../../utils/helpers/resources/getResourceTypeName";
import { AccountResourceFilter2 } from "../../../../../../../services/cloudchipr.api";
interface FilterTemplatesFiltersDialogItemProps {
  filter: AccountResourceFilter2;
}

export const FilterTemplatesFiltersDialogItem: FC<
  FilterTemplatesFiltersDialogItemProps
> = ({ filter }) => {
  const [collapsed, toggleCollapsed] = useToggle(false);
  const clickable = !!filter.filter_items.length;

  return (
    <Card
      variant="outlined"
      sx={{
        my: 1,
        borderColor: "grey.300",
        borderRadius: collapsed ? "8px" : "4px 4px 0px 0px",
      }}
    >
      <CardHeader
        onClick={clickable ? toggleCollapsed : undefined}
        sx={{
          px: 1,
          py: 0,
          cursor: clickable ? "pointer" : "unset",
          bgcolor: "grey.100",
          "& .MuiCardHeader-action": {
            alignSelf: "center",
          },
        }}
        title={
          <Typography variant="body1" fontWeight="medium" py={1.5}>
            {getResourceTypeName(filter.type, {
              type: "long",
            })}{" "}
            ({filter.filter_items.length})
          </Typography>
        }
        action={
          clickable && (
            <Box sx={{ px: 2 }}>
              {collapsed ? (
                <KeyboardArrowUpOutlinedIcon />
              ) : (
                <KeyboardArrowDownOutlinedIcon />
              )}
            </Box>
          )
        }
      />

      <Collapse in={collapsed}>
        <CardContent>
          <FiltersDisabledView filter={filter} />
        </CardContent>
      </Collapse>
    </Card>
  );
};
