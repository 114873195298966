import { createDraftSafeSelector } from "@reduxjs/toolkit";
import { resourceExplorerIdSelector } from "./resourceExplorerIdSelector";
import { resourceExplorerGroupingSelector } from "./data/resourceExplorerGroupingSelector";
import { resourceExplorerQuarterEndForecastVisibilitySelector } from "./data-grid/resourceExplorerQuarterEndForecastVisibilitySelector";
import { getResourceExplorerSavedDataGridVisibilities } from "../../../../components/pages/resource-explorer/components/resource-explorer-card/utils/helpers/resourceExplorerDataGridSavedDataGetterSetter/getResourceExplorerDataGridSavedData";
import { getResourceExplorerDataGridColumnsInitialVisibility } from "../../../../components/pages/resource-explorer/components/resource-explorer-card/components/resource-explorer-data-grid/components/utils/helpers/getResourceExplorerDataGridColumnsInitialVisibility";
import { getResourceExplorerDataGridColumns } from "../../../../components/pages/resource-explorer/components/resource-explorer-card/components/resource-explorer-data-grid/components/columns/setups/getResourceExplorerDataGridColumns";

export const resourceExplorerDataGridVisibilitiesSelector =
  createDraftSafeSelector(
    [
      resourceExplorerIdSelector,
      resourceExplorerGroupingSelector,
      resourceExplorerQuarterEndForecastVisibilitySelector,
    ],
    (
      viewId,
      groupBy,
      quarterEndForecastVisibility,
    ): Record<string, boolean> => {
      const columns = getResourceExplorerDataGridColumns(groupBy);

      return (
        getResourceExplorerSavedDataGridVisibilities(viewId) ??
        getResourceExplorerDataGridColumnsInitialVisibility(
          columns,
          quarterEndForecastVisibility,
        )
      );
    },
  );
