import { billingErrorAccountStatusesByProviderSelector } from "./billingErrorAccountStatusesByProviderSelector";

import { RootState } from "../../../../store";
import { BillingAccountStatusWithProvider } from "../../../utils/types/types";
import { connectedProvidersSelector } from "../../connectedProvidersSelector";

export const billingErrorAccountStatusesSelector = (
  state: RootState,
): BillingAccountStatusWithProvider[] => {
  const connectedProviders = connectedProvidersSelector(state);

  return connectedProviders.reduce((result, provider) => {
    const data = billingErrorAccountStatusesByProviderSelector(state, provider);
    result = result.concat(data);

    return result;
  }, [] as BillingAccountStatusWithProvider[]);
};
