import { FC, Fragment } from "react";
import { Popover, Stack, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import { CapacityMode } from "../../../../../../../../../../services/cloudchipr.api";
import { useMenuHook } from "../../../../../../../../../../utils/hooks/useMenu.hook";

interface CapacityModeCellProps {
  data: CapacityMode;
  capacityMode: string;
}

export const CapacityModeCell: FC<CapacityModeCellProps> = ({
  data,
  capacityMode,
}) => {
  const { anchor, open, openMenu, closeMenu } = useMenuHook();

  const showPopover = !!(data?.min_capacity_units || data?.max_capacity_units);

  if (capacityMode !== "Provisioned" || !showPopover) {
    return (
      <Typography variant="body2">
        {capacityMode}{" "}
        {!!data?.current_provisioned_units &&
          `(${data?.current_provisioned_units})`}
      </Typography>
    );
  }

  return (
    <Fragment>
      <Typography
        variant="body2"
        onMouseEnter={openMenu}
        onMouseLeave={closeMenu}
        sx={{
          textUnderlineOffset: 4,
          textDecoration: "underline",
          textDecorationStyle: "dashed",
          textDecorationColor: grey["A400"],
        }}
      >
        {capacityMode} ({data?.current_provisioned_units})
      </Typography>

      <Popover
        disableRestoreFocus
        open={open}
        anchorEl={anchor}
        onClose={closeMenu}
        sx={{ pointerEvents: "none" }}
        PaperProps={{ sx: { borderRadius: 1 } }}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        transformOrigin={{ vertical: "top", horizontal: "left" }}
      >
        <Stack minWidth={250} p={1} spacing={1}>
          {row("Minimum capacity units", data?.min_capacity_units ?? 0)}

          {row(
            "Current provisioned units",
            data?.current_provisioned_units ?? 0,
          )}

          {row("Maximum capacity units", data?.max_capacity_units ?? 0)}

          {row("Autoscaling", data?.autoscaling ? "On" : "Off")}
        </Stack>
      </Popover>
    </Fragment>
  );
};

const row = (title: string, value: string | number) => {
  return (
    <Stack direction="row" justifyContent="space-between">
      <Typography variant="caption">{title}</Typography>
      <Typography variant="caption" fontWeight="medium">
        {value}
      </Typography>
    </Stack>
  );
};
