import { FC, useCallback } from "react";
import { IconButton, Stack, Tooltip } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import AddIcon from "@mui/icons-material/Add";
import { DashboardV2FolderListItemActionsMenu } from "./DashboardV2FolderListItemActionsMenu";
import { useMenuHook } from "../../../../../../utils/hooks/useMenu.hook";
import { useAppAbility } from "../../../../../../services/permissions";
import { NavigationItemsVisibilityType } from "../../../../../../store/navigation/utils/types";
import { useDialog } from "../../../../../../utils/hooks/useDialog.hook";
import { DashboardCreateFolderOrItemDialogV2 } from "../create-folder-or-item-dialog-v2/DashboardCreateFolderOrItemDialogV2";

interface DashboardV2FolderActionButtonProps {
  id: string;
  name: string;
  redirectAfterCreate?: boolean;
  size?: "small" | "medium" | "large";
  visibility: NavigationItemsVisibilityType;
}

export const DashboardV2FolderActionButton: FC<
  DashboardV2FolderActionButtonProps
> = ({ id, name, visibility, size, redirectAfterCreate }) => {
  const { cannot } = useAppAbility();
  const cannotDeleteDashboard = cannot("delete", "dashboard");
  const { anchor, open, openMenu, closeMenu } = useMenuHook();
  const {
    open: openAdd,
    openDialog: openAddDialog,
    closeDialog: closeAddDialog,
  } = useDialog();

  const closeDialog = useCallback(() => {
    closeAddDialog();
    closeMenu();
  }, [closeMenu, closeAddDialog]);

  if (cannotDeleteDashboard) {
    return null;
  }

  return (
    <Stack direction="row" spacing={0.5} justifyContent="flex-end">
      <Tooltip title="Add dashboard" arrow placement="top">
        <IconButton size="small" onClick={openAddDialog}>
          <AddIcon fontSize={size ?? "inherit"} />
        </IconButton>
      </Tooltip>

      <IconButton size="small" onClick={openMenu}>
        <MoreVertIcon fontSize={size ?? "inherit"} />
      </IconButton>

      <DashboardV2FolderListItemActionsMenu
        id={id}
        name={name}
        open={open}
        anchor={anchor}
        closeMenu={closeMenu}
        visibility={visibility}
        redirectAfterCreate={redirectAfterCreate}
      />

      {openAdd && (
        <DashboardCreateFolderOrItemDialogV2
          onClose={closeDialog}
          type="item"
          folderId={id}
          visibility={visibility}
          redirectAfterCreate={redirectAfterCreate}
        />
      )}
    </Stack>
  );
};
