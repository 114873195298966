import { FC, Fragment, memo, useCallback } from "react";
import {
  Avatar,
  CircularProgress,
  FormHelperText,
  Grid,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Typography,
} from "@mui/material";
import { usePostUsersMeJiraIntegrationsByIntegrationIdProjectsMutation } from "../../../../../../../services/cloudchipr.api";
import { RowLabelColumn } from "../RowLabelColumn";
import { jiraProjectsFetchFixedCacheKey } from "../../utils/constants/fixedCacheKeys";

interface ProjectsRowProps {
  error?: string;
  projectKey: string;
  integrationId: string;
  onChange(key: string): void;
}

export const ProjectsRow: FC<ProjectsRowProps> = memo(
  ({ integrationId, projectKey, onChange, error }) => {
    const [, { data: projects, isLoading }] =
      usePostUsersMeJiraIntegrationsByIntegrationIdProjectsMutation({
        fixedCacheKey: jiraProjectsFetchFixedCacheKey,
      });

    const renderValue = useCallback(
      (selected: string) => {
        const selectedItem = projects?.find(({ key }) => key === selected);

        if (!selectedItem) {
          return selected;
        }

        return (
          <Stack direction="row" alignItems="center" p={0}>
            {selectedItem.avatar && (
              <Avatar
                sx={{ width: 24, height: 24, mr: 2 }}
                alt="avatar"
                src={selectedItem.avatar}
              />
            )}

            <ListItemText primary={selectedItem.name} sx={{ m: 0 }} />
          </Stack>
        );
      },
      [projects],
    );

    const changeHandler = useCallback(
      (e: SelectChangeEvent) => onChange(e.target.value),
      [onChange],
    );

    return (
      <Fragment>
        <RowLabelColumn label="Project" required />

        <Grid item sm={4}>
          {isLoading ? (
            <Stack direction="row" alignItems="center">
              <CircularProgress size={24} />
              <Typography variant="subtitle1" color="text.secondary" ml={3}>
                Fetching fields ...
              </Typography>
            </Stack>
          ) : (
            <Fragment>
              <Select
                fullWidth
                size="small"
                value={projectKey}
                onChange={changeHandler}
                name="body.project_key"
                disabled={!integrationId}
                renderValue={renderValue}
                error={!!error}
              >
                {projects?.map(({ key, name, avatar }) => {
                  return (
                    <MenuItem value={key} key={key}>
                      {avatar && (
                        <Avatar
                          sx={{ width: 24, height: 24, mr: 2 }}
                          alt="avatar"
                          src={avatar}
                        />
                      )}

                      <ListItemText primary={name} />
                    </MenuItem>
                  );
                })}
              </Select>
              {!!error && <FormHelperText error>{error}</FormHelperText>}
            </Fragment>
          )}
        </Grid>

        <Grid item sm={6} />
      </Fragment>
    );
  },
);
