import { Stack, Typography } from "@mui/material";
import { ReservationsCoverageDataWithId } from "../../../../../../../common/utils/types";
import { ColumnSetupType } from "../../../../../../../../../../storybook/data-grid/utils/types/types";
import { netSavingsColumn } from "../coverage/common/netSavingsColumn";
import { getCoveredUsageColumn } from "../coverage/common/coveredUsageColumn";
import { getCoverageColumn } from "../coverage/common/coverageColumn";
import { totalCoverageColumn } from "../coverage/common/totalCoverageColumn";

export const reservationGroupedByAccountColumns: ColumnSetupType<ReservationsCoverageDataWithId>[] =
  [
    {
      accessorKey: "account",
      header: "Account",
      cell: ({ row }) => {
        return (
          <Stack>
            <Typography variant="body2">
              {row.original.account?.name}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {row.original.account?.provider_account_id}
            </Typography>
          </Stack>
        );
      },
    },

    getCoveredUsageColumn("account"),

    netSavingsColumn,

    getCoverageColumn("account"),

    totalCoverageColumn,
  ];
