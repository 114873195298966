import { FC, Fragment, useMemo } from "react";
import { Row } from "@tanstack/react-table";
import { CircularProgress, Stack, Typography } from "@mui/material";
import { OffHoursRecommendationsChart } from "./charts/OffHoursRecommendationsChart";
import { SubRowInfoSection } from "./SubRowInfoSection";
import { useResourceCpuData } from "../../../utils/hooks/useResourceCpuData.hook";
import { weekDaysIndex } from "../../../../../../../../utils/helpers/date-time/datetime-format";
import { generateChartDataFromMetrics } from "../../../utils/helpers/generateChartDataFromMetrics";
import {
  ChartDataGenerationArgsType,
  RecommendationDataType,
} from "../../../utils/types/types";

interface OffHoursRecommendationsSubRowProps {
  data: RecommendationDataType;
}

const OffHoursRecommendationsSubRow: FC<OffHoursRecommendationsSubRowProps> = ({
  data,
}) => {
  const {
    id,
    name,
    to_hour,
    from_hour,
    to_day,
    from_day,
    account_id,
    number_of_off_hours,
    frequency,
    resource_type,
    group,
  } = data;

  const { metrics, isLoading } = useResourceCpuData({
    frequency,
    accountId: account_id,
    recommendationId: id,
  });

  const chartData = useMemo(() => {
    if (!metrics) {
      return null;
    }

    const options: ChartDataGenerationArgsType = {
      metrics,
      fromHour: from_hour,
      toHour: to_hour,
    };

    if (from_day && to_day) {
      options.fromDay = weekDaysIndex[from_day];
      options.toDay = weekDaysIndex[to_day];
    }

    return generateChartDataFromMetrics(options);
  }, [metrics, from_hour, to_hour, from_day, to_day]);

  return (
    <Fragment>
      <SubRowInfoSection
        toHour={to_hour}
        fromHour={from_hour}
        fromToDiff={number_of_off_hours}
        name={name}
        fromDay={from_day}
        toDay={to_day}
        type={resource_type}
        withDbConnections={group === "database"}
        frequency={frequency}
      />

      <Stack direction="row" justifyContent="space-between">
        <Typography variant="subtitle2" ml={5} mb={1}>
          CPU Utilization (%)
        </Typography>

        {group === "database" && (
          <Typography variant="subtitle2" ml={5} mb={1}>
            Database Connections
          </Typography>
        )}
      </Stack>

      {isLoading ? (
        <Stack alignItems="center" py={6}>
          <CircularProgress disableShrink />
        </Stack>
      ) : (
        !!chartData?.length && (
          <OffHoursRecommendationsChart
            data={chartData}
            withDbConnections={group === "database"}
            lineInCenter={
              frequency === "weekly" && chartData.length === 24 * 14
            }
          />
        )
      )}
    </Fragment>
  );
};

export const offHoursRecommendationsSubRow = (
  row: Row<RecommendationDataType>,
) => <OffHoursRecommendationsSubRow data={row.original} />;
