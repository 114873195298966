import { useEffect } from "react";
import {
  usePostUsersMeJiraIntegrationsByIntegrationIdProjectsAndProjectIdIssueTypesMutation,
  usePostUsersMeJiraIntegrationsProjectsByProjectIdIssueTypesMutation,
} from "../../../../../services/cloudchipr.api";

export const useIntegrationIssueTypes = ({
  apiToken,
  email,
  url,
  projectKey,
  integrationId,
}: {
  url?: string | null;
  email?: string | null;
  integrationId?: string;
  apiToken?: string | null;
  projectKey?: string | null;
}) => {
  const [
    fetchIssuesOnCreate,
    { data: issuesOnCreate, isLoading: issuesLoadingOnCreate },
  ] = usePostUsersMeJiraIntegrationsProjectsByProjectIdIssueTypesMutation();

  const [
    fetchIssuesOnEdit,
    { data: issuesOnEdit, isLoading: issuesLoadingOnEdit },
  ] =
    usePostUsersMeJiraIntegrationsByIntegrationIdProjectsAndProjectIdIssueTypesMutation();

  const issues = integrationId ? issuesOnEdit : issuesOnCreate;
  const issuesLoading = integrationId
    ? issuesLoadingOnEdit
    : issuesLoadingOnCreate;

  useEffect(() => {
    if (integrationId) {
      return;
    }

    const incompleteData =
      !projectKey || !url || !apiToken || !email || !projectKey;

    if (incompleteData) {
      return;
    }

    fetchIssuesOnCreate({
      projectId: projectKey,
      body: { api_token: apiToken, url, email },
    }).unwrap();
  }, [integrationId, projectKey, url, apiToken, email, fetchIssuesOnCreate]);

  useEffect(() => {
    if (projectKey && integrationId) {
      fetchIssuesOnEdit({
        integrationId,
        projectId: projectKey,
      }).unwrap();
    }
  }, [integrationId, projectKey, fetchIssuesOnEdit]);

  return {
    issues,
    issuesLoading,
  };
};
