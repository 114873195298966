import { FC, useCallback } from "react";
import { Button, Stack } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import copy from "copy-to-clipboard";
import { useToggle } from "rooks";
import OpenInNewOutlinedIcon from "@mui/icons-material/OpenInNewOutlined";

interface TaskCreateSnackbarAlertProps {
  id: string;
}

export const TaskCreateSnackbarAlert: FC<TaskCreateSnackbarAlertProps> = ({
  id,
}) => {
  let url = `${window.location.origin}/task-mgmt`;

  if (id) {
    url = `${url}?&taskId=${id}`;
  }

  const [copied, toggleCopied] = useToggle(false);

  const handleCopyClick = useCallback(() => {
    copy(url);
    toggleCopied();
    setTimeout(toggleCopied, 1000);
  }, [url, toggleCopied]);

  return (
    <Stack direction="row" spacing={1} justifyContent="end">
      {id && (
        <Button
          color="tertiary"
          size="xsmall"
          onClick={handleCopyClick}
          disabled={copied}
        >
          {copied ? "Copied !" : "Copy Link"}
        </Button>
      )}

      <Button
        to={url}
        size="xsmall"
        color="primary"
        target="_blank"
        variant="contained"
        component={RouterLink}
        endIcon={<OpenInNewOutlinedIcon sx={{ fontSize: "16px !important" }} />}
      >
        {id ? "Open Task" : "View Created Tasks"}
      </Button>
    </Stack>
  );
};
