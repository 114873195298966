import { grey } from "@mui/material/colors";

export const alertsTableStyles = {
  table: {
    tableLayout: "fixed",
  },
  tableHeaderRow: {
    bgcolor: "grey.200",
    "& th": { py: 2 },
  },
  tableRow: {
    "& td": { py: 0.75 },
    "&:hover td": { bgcolor: grey[50] },
  },
};
