import { FilterFn } from "@tanstack/react-table";
import numeral from "numeral";
import { normalizeString } from "./common";

export const cpuFilter: FilterFn<any> = (row, columnId, value) => {
  return commonCpuFilter(columnId, value, row.getValue(columnId));
};

export const commonCpuFilter = (
  columnId: string,
  value: string,
  data: string,
) => {
  const val = normalizeString(value);

  const result = numeral(data).format("0.00") + " %";

  return result.includes(val);
};
