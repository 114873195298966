import { FC } from "react";
import { grey } from "@mui/material/colors";
import { Stack } from "@mui/material";
import { UserRoleEditMetaDataItem } from "./UserRoleEditMetaDataItem";
import { useAppSelector } from "../../../../../../../store/hooks";
import { userRoleMetaDataByIdSelector } from "../../../../../../../store/user/selectors/roles/role-by-id/userRoleMetaDataByIdSelector";
import { formatDate } from "../../../../../../../utils/helpers/date-time/datetime-format";

export const UserRoleEditMetaData: FC = () => {
  const metaData = useAppSelector(userRoleMetaDataByIdSelector);
  return (
    <Stack>
      <UserRoleEditMetaDataItem
        value="Created Date"
        description={
          metaData.createdAt
            ? formatDate(metaData.createdAt, { type: "date" })
            : "-"
        }
      />
      <UserRoleEditMetaDataItem
        value="Last Modified"
        description={
          metaData.modifiedAt
            ? formatDate(metaData.modifiedAt, { type: "date" })
            : "-"
        }
        color={grey["100"]}
      />
      <UserRoleEditMetaDataItem
        value="Created By"
        description={metaData.createdBy ?? "-"}
      />
      <UserRoleEditMetaDataItem
        value="Last Modified By"
        description={metaData.modifiedBy ?? "-"}
        color={grey["100"]}
      />
    </Stack>
  );
};
