import { FC, Fragment, memo, useCallback } from "react";
import { FormHelperText, Grid, Select } from "@mui/material";
import { selectMenuItem } from "./SelectMenuItem";
import { selectRenderValue } from "./selectRenderValue";
import { JiraSelectOptionsType } from "../../../../utils/types/types";

interface SingleSelectFieldProps {
  name: string;
  error?: string;
  label: string;
  value?: { id: string };
  options: JiraSelectOptionsType;
  setFieldValue(key: string, value: any): void;
}

export const SingleSelectField: FC<SingleSelectFieldProps> = memo(
  ({ value, setFieldValue, name, options, label, error }) => {
    const renderValue = useCallback(
      (selected: any) => selectRenderValue(selected, options),
      [options],
    );

    const changeHandler = useCallback(
      (event: any) => {
        setFieldValue(name, { id: event.target.value });
      },
      [setFieldValue, name],
    );

    return (
      <Fragment>
        <Grid item sm={4}>
          <Select
            fullWidth
            size="small"
            name={name}
            placeholder={label}
            value={value?.id || ""}
            onChange={changeHandler}
            renderValue={renderValue}
          >
            {options?.map((option) => selectMenuItem(option, null))}
          </Select>
          {!!error && <FormHelperText error>{error} </FormHelperText>}
        </Grid>

        <Grid item sm={6} />
      </Fragment>
    );
  },
);
