import { FC } from "react";
import { Stack, Typography } from "@mui/material";
import EmptyStateImage from "../../../../../../assets/images/empty_recommendation_state.svg";

export const OffHoursRecommendationsEmptyState: FC = () => {
  return (
    <Stack alignItems="center" my={25}>
      <img src={EmptyStateImage} alt="EmptyStateImage" />
      <Typography variant="h5" fontWeight="bold" mt={7}>
        There are no recommendations yet
      </Typography>
      <Typography
        variant="h6"
        fontWeight="regular"
        color="text.secondary"
        textAlign="center"
        mt={2}
      >
        We are checking for recommendations on hourly basis. <br /> Please come
        back later.
      </Typography>
    </Stack>
  );
};
