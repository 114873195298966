import { ColDef } from "@ag-grid-community/core/dist/types/src/entities/colDef";

export const reportsNotificationsSortingFn: ColDef["comparator"] = (
  valueA,
  valueB,
  nodeA,
  nodeB,
) => {
  const currentIntegrationName = nodeA.data.integrations?.at(0)?.name;
  const currentEmail = nodeA.data.emails?.to?.at(0);
  const current = (currentIntegrationName ?? currentEmail ?? "").toLowerCase();

  const nextIntegrationName = nodeB.data.integrations?.at(0)?.name ?? "";
  const nextEmail = nodeB.data.emails?.to?.at(0);
  const next = (nextIntegrationName ?? nextEmail ?? "").toLowerCase();

  return current > next ? 1 : -1;
};
