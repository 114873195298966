import { FC } from "react";
import { Stack, StackProps, styled } from "@mui/material";
import { SubscriptionPlanOptions } from "./SubscriptionPlanOptions";
import { SubscriptionPlanHeader } from "./SubscriptionPlanHeader";
import { SubscriptionPlanAction } from "./SubscriptionPlanAction";
import { Plan } from "../../../../../../../services/cloudchipr.api";
import { useAppSelector } from "../../../../../../../store/hooks";
import { currentSubscriptionSelector } from "../../../../../../../store/subscriptions/subscriptionsSelectors";
import { SubscriptionPlanActionType } from "../SubscriptionPlans";

interface SubscriptionPlanProps {
  plan: Plan;
  isYearly: boolean;
  action: SubscriptionPlanActionType;
}

export const SubscriptionPlan: FC<SubscriptionPlanProps> = ({
  plan,
  isYearly,
  action,
}) => {
  const { name, price, yearly, details, most_popular, id, name_key } = plan;
  const current = useAppSelector(currentSubscriptionSelector);

  const isSpecial = name_key === "enterprise";
  const isCurrent =
    current?.plan?.id === id ||
    (isSpecial &&
      current?.plan?.name_key === "enterprise" &&
      current?.plan?.yearly === isYearly);

  return (
    <SubscriptionPlanStyled popular={most_popular ? 1 : 0}>
      <SubscriptionPlanHeader
        name={name}
        yearly={yearly}
        price={price}
        monthlySpendRange={details?.monthly_cloud_spend_range}
        special={isSpecial}
      />

      <SubscriptionPlanAction
        id={id}
        current={isCurrent}
        special={isSpecial}
        action={action}
      />

      {details?.options && (
        <SubscriptionPlanOptions options={details.options} />
      )}
    </SubscriptionPlanStyled>
  );
};

const SubscriptionPlanStyled = styled(Stack)<{ popular: number } & StackProps>(
  ({ popular, theme }) => {
    return {
      padding: `${theme.spacing(4)} ${theme.spacing(2)}`,
      flexGrow: 1,
      borderRadius: 12,
      borderWidth: 1,
      borderStyle: popular ? "solid" : "none",
      borderColor: popular ? theme.palette.grey["300"] : "none",
      backgroundColor: popular ? theme.palette.grey["50"] : "none",
    };
  },
);
