import { FC } from "react";
import { Stack } from "@mui/material";
import { WorkflowWeeklyScheduler } from "./frequency-schedules/WorkflowWeeklyScheduler";
import { WorkflowDailyScheduler } from "./frequency-schedules/WorkflowDailyScheduler";
import { WorkflowHourlyScheduler } from "./frequency-schedules/WorkflowHourlyScheduler";
import { WorkflowOnceScheduler } from "./frequency-schedules/WorkflowOnceScheduler";

export const WorkflowScheduler: FC = () => {
  return (
    <Stack direction="row" alignItems="center" spacing={1.5}>
      <WorkflowHourlyScheduler />

      <WorkflowDailyScheduler />

      <WorkflowWeeklyScheduler />

      <WorkflowOnceScheduler />
    </Stack>
  );
};
