import { createAsyncThunk } from "@reduxjs/toolkit";
import { enqueueSnackbar } from "notistack";
import { generateSelectedRolePermissionNamesByGroupedPermissions } from "./utils/generateSelectedRolePermissionNamesByGroupedPermissions";
import { cloudChiprApi } from "../../../../services/cloudchipr.api";
import { userRoleDataSelector } from "../../selectors/roles/userRoleDataSelector";
import { RootState } from "../../../store";

export const userRoleUpdatingFixedCacheKey =
  "patchUsersMeOrganisationsCurrentRolesByRoleId_create_user_role";

export const updateUserRoleThunk = createAsyncThunk(
  "roles/updateUserRole",
  async (_, { dispatch, getState }) => {
    const state = getState() as RootState;
    const userRoleData = userRoleDataSelector(state);
    const { name, description, id, permissions } = userRoleData;

    if (!userRoleData || !id || !permissions) {
      return;
    }
    try {
      const response = await dispatch(
        cloudChiprApi.endpoints.patchUsersMeOrganisationsCurrentRolesByRoleId.initiate(
          {
            roleId: id,
            body: {
              name,
              description: description.length === 0 ? null : description,
              permissions:
                generateSelectedRolePermissionNamesByGroupedPermissions(
                  permissions,
                ),
            },
          },
          {
            fixedCacheKey: userRoleUpdatingFixedCacheKey,
          },
        ),
      );

      enqueueSnackbar(`Role updated!`, {
        variant: "snackbarAlert",
        AlertSnackBarProps: { severity: "success" },
      });
      return response;
    } catch (e) {
      // @ts-expect-error // todo: api fix
      enqueueSnackbar(e?.data?.message ?? "Something went wrong", {
        variant: "snackbarAlert",
        AlertSnackBarProps: {
          severity: "error",
        },
      });
    }
  },
);
