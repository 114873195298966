import { RootState } from "../../../../store";
import { cloudChiprApi } from "../../../../../services/cloudchipr.api";
import { dimensionUpdateFixedCacheKey } from "../../../thunks/dimensions/updateDimensionNameThunk";

const dimensionUpdateSelector =
  cloudChiprApi.endpoints.putUsersMeOrganisationsCurrentDimensionsByDimensionId.select(
    dimensionUpdateFixedCacheKey,
  );

export const dimensionUpdateLoadingSelector = (state: RootState) => {
  return !!dimensionUpdateSelector(state)?.isLoading;
};
