import { getUsersMeWorkflowsByWorkflowIdBatchesAndBatchIdResourcesSelector } from "./getUsersMeWorkflowsByWorkflowIdBatchesAndBatchIdResourcesSelector";
import { RootState } from "../../../store";
import { AllFilteredResources } from "../../../../services/cloudchipr.api";

export const protectorResourcesDataSelector = (
  state: RootState,
  workflowId?: string,
  batchId?: string,
): AllFilteredResources[] | undefined => {
  if (!workflowId || !batchId) {
    return;
  }

  const data =
    getUsersMeWorkflowsByWorkflowIdBatchesAndBatchIdResourcesSelector({
      workflowId,
      batchId,
    })(state);

  return data?.data;
};
