import {
  NullableDate,
  NullableEmbeddedUser,
} from "../../../../../../../../services/cloudchipr.api";
import { formatDate } from "../../../../../../../../utils/helpers/date-time/datetime-format";

export const getUserActivityQuickFilterText = (
  details?: NullableEmbeddedUser,
  date?: NullableDate,
) => {
  if (details?.name?.includes("deleted")) {
    return "Deleted User";
  }

  return `${details?.name ?? ""} ${details?.email ?? ""} ${formatDate(date ?? "", { type: "dateTime" })}`.toLowerCase();
};
