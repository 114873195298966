import { FC, Fragment } from "react";
import { Divider, Stack, Typography } from "@mui/material";

import { CommitmentUtilizationWidgetHeaderInfo } from "./CommitmentUtilizationWidgetHeaderInfo";
import {
  CommitmentsUtilizationGrouping,
  CommitmentUtilizationType,
  Dates,
} from "../../../../../../../services/cloudchipr.api";

interface CommitmentUtilizationWidgetSubHeaderProps {
  name: string;
  providerOrganisationId?: string;
  commitmentType?: CommitmentUtilizationType;
  grouping?: CommitmentsUtilizationGrouping;
  dates?: Dates;
}

export const CommitmentUtilizationWidgetSubHeader: FC<
  CommitmentUtilizationWidgetSubHeaderProps
> = ({ name, providerOrganisationId, commitmentType, grouping, dates }) => {
  return (
    <Fragment>
      <Stack px={2} py={1}>
        <Typography variant="body1" fontWeight="bold" minHeight={24}>
          {name}
        </Typography>

        <CommitmentUtilizationWidgetHeaderInfo
          providerOrganisationId={providerOrganisationId}
          commitmentType={commitmentType}
          grouping={grouping}
          dates={dates}
        />
      </Stack>

      <Divider />
    </Fragment>
  );
};
