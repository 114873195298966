import { FC } from "react";
import { ListItemIcon, MenuItem, Typography } from "@mui/material";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";

interface ResourceExplorerSettingsActionItemProps {
  onClick(): void;
}

export const ResourceExplorerSettingsActionItem: FC<
  ResourceExplorerSettingsActionItemProps
> = ({ onClick }) => {
  return (
    <MenuItem onClick={onClick}>
      <ListItemIcon>
        <SettingsOutlinedIcon fontSize="small" />
      </ListItemIcon>
      <Typography variant="body2">Settings</Typography>
    </MenuItem>
  );
};
