import { currentResourceExplorerHierarchyVisibilitiesDataSelector } from "./currentResourceExplorerHierarchyVisibilitiesDataSelector";
import { RootState } from "../../../store";
import { isHierarchyFolder } from "../../../navigation/utils/helpers/isHierarchyFolder";

export const resourceExplorerHierarchyVisibilityIsDefaultSelector = (
  state: RootState,
  id: string,
): boolean | undefined => {
  const data = currentResourceExplorerHierarchyVisibilitiesDataSelector(state);

  const item = data.find((item) => item.id === id);

  if (!item) {
    return;
  }
  return !isHierarchyFolder(item) ? item?.is_default : false;
};
