import { FC, Fragment } from "react";
import { Collapse, IconButton, Stack, Typography } from "@mui/material";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useToggle } from "rooks";
import { CommandSnippet } from "./CommandSnippet";

interface CollapsableCommandProps {
  message: string;
  commandSnippetCode: string;
  secondCommandSnippetCode?: string;
}

export const CollapsableCommand: FC<CollapsableCommandProps> = ({
  message,
  commandSnippetCode,
  secondCommandSnippetCode,
}) => {
  const [state, toggle] = useToggle();
  return (
    <Fragment>
      <Stack direction="row" alignItems="center" my={3}>
        <Typography variant="caption" flexGrow={1} color="text.secondary">
          {message}
        </Typography>
        <IconButton size="small" onClick={toggle}>
          {state ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        </IconButton>
      </Stack>

      <Collapse in={state}>
        <CommandSnippet code={commandSnippetCode} />
        {secondCommandSnippetCode && (
          <CommandSnippet code={secondCommandSnippetCode} />
        )}
      </Collapse>
    </Fragment>
  );
};
