import { isTreeNodeFilterGroup } from "./isTreeNodeFilterGroup";
import { FilterTreeNodeWithId } from "../types/common";

export const generateFilterKey = (filter: FilterTreeNodeWithId): string => {
  if (isTreeNodeFilterGroup(filter)) {
    return `${filter.items.map(generateFilterKey).join(",")}_filter_items_group_key`;
  }

  return `${filter.id}_filter_item_key`;
};
