import { RootState } from "../../store";
import { cloudChiprApi } from "../../../services/cloudchipr.api";
import { getExecutionLogDetailsThunkFixedCacheKey } from "../thunks/getExecutionLogDetailsThunk";

const selector = cloudChiprApi.endpoints.postUsersMeExecutionLogsDetails.select(
  getExecutionLogDetailsThunkFixedCacheKey,
);

export const executionLogDetailsLoadingSelector = (
  state: RootState,
): boolean => {
  return !!selector(state)?.isLoading;
};
