import { useCallback } from "react";
import { VisibilityState } from "@tanstack/react-table";
import { useAppSelector } from "../../../../../store/hooks";
import {
  generateStorageKey,
  useLocalStorageHook,
} from "../../../../../utils/hooks/useLocalStorage.hook";
import { ResourceType } from "../../../../../services/cloudchipr.api";
import { liveUsageMgmtAccountIdsSelector } from "../../../../../store/live-usage-mgmt/selectors/store-selectors/liveUsageMgmtAccountIdsSelector";

export function useColumnsVisibility(resourceType: ResourceType) {
  const { getItem, setItem } = useLocalStorageHook();

  const accountIds = useAppSelector(liveUsageMgmtAccountIdsSelector) || [];

  const keys = accountIds.map((accountId) => {
    return generateStorageKey({
      accountId,
      resourceType,
      identifier: "tableConfig",
    });
  });

  const configs = keys.map((key) => {
    return getItem(key);
  });
  const initialVisibility = configs[0]?.visibilities;

  const setVisibility = useCallback(
    (visibilities: VisibilityState) => {
      keys.forEach((key, index) => {
        setItem(key, { ...configs[index], visibilities });
      });
    },
    [keys, setItem, configs],
  );

  const defaultVisibility = {
    smart_tag: false,
    created_at: !hideLunchTimeColumns.has(resourceType),
    all_time_spent: false,
    ...initialVisibility,
  };

  return {
    visibility: defaultVisibility as Record<string, boolean>,
    setVisibility,
  };
}

const hideLunchTimeColumns = new Set(["elb", "lb", "az_lb", "disk", "az_disk"]);
