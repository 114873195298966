import { FC, useCallback } from "react";
import { HeaderContext } from "@tanstack/react-table";
import { SxProps } from "@mui/system";
import { IndeterminateCheckbox } from "./IndeterminateCheckbox";

interface SelectAllHeaderCheckboxProps extends HeaderContext<any, any> {
  sxProps?: SxProps;
}

export const SelectAllHeaderCheckbox: FC<SelectAllHeaderCheckboxProps> = ({
  table,
  sxProps,
}) => {
  const availableRows = table.getRowModel().rows;

  const allSelected =
    !!availableRows.length && availableRows.every((row) => row.getIsSelected());
  const indeterminate =
    !!availableRows.length && availableRows.some((row) => row.getIsSelected());

  const changeHandler = useCallback(() => {
    if (allSelected) {
      table.setRowSelection({});
      return;
    }

    table.setRowSelection(
      availableRows.reduce(
        (acc, row) => {
          acc[row.id] = true;

          return acc;
        },
        {} as Record<string, boolean>,
      ),
    );
  }, [allSelected, table, availableRows]);

  return (
    <IndeterminateCheckbox
      sx={{ pl: 1, ...sxProps }}
      size="small"
      onChange={changeHandler}
      disabled={!availableRows?.length}
      indeterminate={indeterminate}
      checked={allSelected}
    />
  );
};
