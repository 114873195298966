import { FC, Fragment } from "react";
import { Box, Chip, Drawer } from "@mui/material";
import WarningAmberRoundedIcon from "@mui/icons-material/WarningAmberRounded";
import { DrawerHeader } from "./DrawerHeader";
import { DrawerWarningCard } from "./DrawerWarningCard";
import { DrawerMissingPermissionCard } from "./missing-permissions/DrawerMissingPermissionCard";
import { useDialog } from "../../../../../../../utils/hooks/useDialog.hook";
import { useAppSelector } from "../../../../../../../store/hooks";
import { rightsizingRecommendationsStatusesGroupedSelector } from "../../../../../../../store/recommendations/selectors/rightsizing/statuses/rightsizingRecommendationsStatusesGroupedSelector";
import { ProviderType } from "../../../../../../../services/cloudchipr.api";
import { rightsizingRecommendationsWarningStatusesCountSelector } from "../../../../../../../store/recommendations/selectors/rightsizing/statuses/rightsizingRecommendationsWarningStatusesCountSelector";

export const RightsizingRecommendationWarnings: FC = () => {
  const { open, openDialog, closeDialog } = useDialog();
  const statuses = useAppSelector(
    rightsizingRecommendationsStatusesGroupedSelector,
  );
  const count = useAppSelector(
    rightsizingRecommendationsWarningStatusesCountSelector,
  );

  if (!count) {
    return null;
  }

  const providers = Object.keys(statuses) as ProviderType[];

  return (
    <Fragment>
      <Box bgcolor="grey.200" p={0.5} borderRadius={5}>
        <Chip
          icon={<WarningAmberRoundedIcon />}
          label={count}
          variant="outlined"
          sx={{
            "& .MuiChip-icon": { color: (theme) => theme.palette.warning.main },
          }}
          onClick={openDialog}
        />
      </Box>

      <Drawer anchor="right" open={open} onClose={closeDialog}>
        <Box width={600}>
          <DrawerHeader onClose={closeDialog} />

          {providers.map((provider) => {
            return [
              statuses[provider].disabled.map((accountId) => (
                <DrawerWarningCard
                  key={accountId}
                  provider={provider}
                  accountId={accountId}
                />
              )),

              statuses[provider].missing_permission.map((accountId) => (
                <DrawerMissingPermissionCard
                  key={accountId}
                  provider={provider}
                  accountId={accountId}
                />
              )),
            ];
          })}
        </Box>
      </Drawer>
    </Fragment>
  );
};
