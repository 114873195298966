import { RootState } from "../../../store";
import { Account, cloudChiprApi } from "../../../../services/cloudchipr.api";
import { executionLogsAccountsStatuses } from "../../../../components/pages/execution-log/utils/constants/common";

export const executionLogsAccountsSelector = (
  state: RootState,
): Account[] | null => {
  const statuses = executionLogsAccountsStatuses.join(",");

  const accounts = cloudChiprApi.endpoints.getUsersMeAccounts.select({
    statuses,
  })(state);

  return accounts?.data ?? null;
};
