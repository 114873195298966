import { FC, Fragment, memo, useEffect } from "react";
import { FormikHandlers, FormikHelpers } from "formik";
import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
} from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import Tooltip from "@mui/material/Tooltip";
import * as yup from "yup";
import { FilterItemOperatorType } from "../FilterItemOperatorType";
import {
  FilterItem,
  FilterItemTemplate,
  Operator,
  ResourceType,
} from "../../../../../../services/cloudchipr.api";
import { isSmartTag } from "../../utils/helpers/isSmartTag";
import { smartTagTypes } from "../../utils/constants/smart-tag-types";
import { getProviderTypeByResourceType } from "../../../../../../utils/helpers/providers/getProviderTypeByResourceType";

export const SmartTagFilterValueValidation = yup.string().when("operator", {
  is: (operator: string) =>
    ["less_than_equal_to", "greater_than_equal_to"].includes(operator),
  then: yup.string().test("value", "Invalid Value", (value) => {
    if (!value) {
      return false;
    }

    return isSmartTagValueValid(value);
  }),
  otherwise: yup.string(),
});

const isSmartTagValueValid = (value: string): boolean => {
  const isDay = value.match(/^(3[01]|[12]\d|[1-9])d$/g); // max 31d
  const isHour = value.match(/^(2[0-4]|1\d|[1-9])h$/g); // max 24h
  const isMinute = value.match(/(^[1-9]|[1-5]\d|60)m$/g); // max 60m

  return !!(isDay || isHour || isMinute);
};
interface SmartTagFilterProps {
  filters: FilterItemTemplate[];
  operators?: Operator[];
  filter: FilterItem;
  error?: any;
  resourceType: ResourceType;
  onChange: FormikHandlers["handleChange"];
  setFieldValue: FormikHelpers<FilterItem>["setFieldValue"];
}

export const SmartTagFilter: FC<SmartTagFilterProps> = memo(
  ({
    operators,
    filter,
    filters,
    resourceType,
    onChange,
    setFieldValue,
    error,
  }) => {
    const tagTypes = filters
      .filter(({ key }) => isSmartTag(key))
      .map(({ key }) => key);

    const isValueNeeded = [
      "less_than_equal_to",
      "greater_than_equal_to",
    ].includes(filter.operator);

    useEffect(() => {
      !isValueNeeded && setFieldValue("value", "");
    }, [isValueNeeded, setFieldValue]);

    return (
      <Fragment>
        <Grid item md={2}>
          <FormControl fullWidth size="xsmall">
            <InputLabel>
              {getProviderTypeByResourceType(resourceType) === "gcp"
                ? "Label"
                : "Tag"}{" "}
              type
            </InputLabel>
            <Select
              name="key"
              labelId="key"
              value={filter.key || ""}
              label={
                getProviderTypeByResourceType(resourceType) === "gcp"
                  ? "Label type"
                  : "Tag type"
              }
              onChange={onChange}
              MenuProps={{
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left",
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "left",
                },
              }}
            >
              {tagTypes?.map((option) => (
                <MenuItem key={option} value={option}>
                  {smartTagTypes.get(option)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <FilterItemOperatorType
          value={filter.operator || ""}
          options={operators}
          onChange={onChange}
        />

        {isValueNeeded ? (
          <Fragment>
            <Grid item md={2}>
              <Stack direction="row" alignItems="center" spacing={1}>
                <TextField
                  fullWidth
                  name="value"
                  size="xsmall"
                  label="Tag Value"
                  placeholder="e.g 2h"
                  value={filter.value || ""}
                  disabled={!filter.key || !filter.operator}
                  error={!!error?.value}
                  onChange={onChange}
                />
                <Tooltip
                  arrow
                  title="The value must be any number of m(minutes), h(hours) or d(days), e.g. 2d"
                  placement="bottom"
                >
                  <InfoOutlinedIcon
                    sx={{ color: "text.secondary" }}
                    fontSize="small"
                  />
                </Tooltip>
              </Stack>
            </Grid>
            <Grid item md={3} />
          </Fragment>
        ) : (
          <Grid item md={5} />
        )}
      </Fragment>
    );
  },
);
