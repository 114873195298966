import { FC } from "react";
import Typography from "@mui/material/Typography";
import { IconButton, Stack } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

interface ViewSettingsDrawerHeader {
  onClose(): void;
}

export const ViewSettingsDrawerHeader: FC<ViewSettingsDrawerHeader> = ({
  onClose,
}) => {
  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      px={2}
      py={1}
      alignItems="center"
    >
      <Typography variant="body1" fontWeight="medium">
        View Settings
      </Typography>
      <IconButton size="small" onClick={onClose}>
        <CloseIcon fontSize="small" />
      </IconButton>
    </Stack>
  );
};
