import { RootState } from "../../../../store";
import { widgetSetupSelector } from "../widgetSetupSelector";
import { CommitmentUtilizationWidgetSetupType } from "../../../utils/types/setups/commitmentUtilizationWidgetSetupType";

export const commitmentUtilizationWidgetSetupSelector = (
  state: RootState,
): CommitmentUtilizationWidgetSetupType | undefined => {
  if (!widgetSetupSelector(state)) {
    return;
  }
  return widgetSetupSelector(state) as CommitmentUtilizationWidgetSetupType;
};
