import { CostBreakdownWidgetAggregationsSwitchProps } from "../../components/aggregations/CostBreakdownWidgetAggregationsSwitch";
import { DateDataPoint } from "../../../../../../../../../../../services/cloudchipr.api";
import { capitalizeString } from "../../../../../../../../../../../utils/helpers/capitalizeString";

export const getCostBreakdownAggregationsSwitchesData = (
  averageExists: boolean,
  frequency: DateDataPoint,
): CostBreakdownWidgetAggregationsSwitchProps[] => {
  const firstPart: CostBreakdownWidgetAggregationsSwitchProps[] = [
    {
      selectorKey: "total_cost",
      title: "Total cost",
    },
  ];

  if (averageExists) {
    firstPart.push({
      selectorKey: "daily_average",
      title: `${capitalizeString(frequency)} Average`,
    });
  }

  return [
    ...firstPart,
    {
      selectorKey: "forecasted_cost",
      title: "Forecasted cost",
    },
    {
      selectorKey: "live_filtered",
      title: "Live filtered",
    },
  ];
};
