import { FC, useCallback } from "react";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../../../../../../../store/hooks";
import { commitmentUtilizationSetupVisualizationPropertyByKeySelector } from "../../../../../../../../../../../../../../store/dashboards/selectors/setups/commitment-utilization/commitmentUtilizationSetupVisualizationPropertyByKeySelector";
import { commitmentUtilizationWidgetVisualizationChangeThunk } from "../../../../../../../../../../../../../../store/dashboards/thunks/widgets/commitment-utilization/setup-change/commitmentUtilizationWidgetVisualizationChangeThunk";
import { SwitchWithTypography } from "../../../../../../../../../../../../../SwitchWithTypography";

export const CommitmentUtilizationWidgetVisualizationNetSavings: FC = () => {
  const dispatch = useAppDispatch();
  const netSavingsChecked = useAppSelector(
    commitmentUtilizationSetupVisualizationPropertyByKeySelector("net_savings"),
  );

  const handleChange = useCallback(
    (checked: boolean) => {
      dispatch(
        commitmentUtilizationWidgetVisualizationChangeThunk({
          net_savings: checked,
        }),
      );
    },
    [dispatch],
  );

  return (
    <SwitchWithTypography
      checked={netSavingsChecked}
      onChange={handleChange}
      title="Net Savings"
    />
  );
};
