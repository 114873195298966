import { FC, useMemo } from "react";
import {
  Stack,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { useAppSelector } from "../../../../../../../../store/hooks";
import { organisationQuarterStartMonthSelector } from "../../../../../../../../store/common/selectors/org-current-settings/organisationQuarterStartMonthSelector";
import { commitmentsDatesSelector } from "../../../../../../../../store/commitments/selectors/common/view-options/commitmentsDatesSelector";
import { generateFromToFromDates } from "../../../../../utilization-and-coverage/utils/helpers/generateFromToFromDates";

export const CommitmentsOverviewCoverageTableHeader: FC = () => {
  const quarterStartMonth = useAppSelector(
    organisationQuarterStartMonthSelector,
  );
  const dates = useAppSelector(commitmentsDatesSelector);

  const datesFromTo = useMemo(() => {
    return generateFromToFromDates(dates, quarterStartMonth);
  }, [dates, quarterStartMonth]);

  return (
    <TableHead>
      <TableRow>
        <TableCell
          sx={{ whiteSpace: "nowrap", width: 120, borderBottom: "none" }}
        >
          <Typography
            color="text.secondary"
            variant="body2"
            fontWeight="medium"
          >
            Resource Type
          </Typography>
        </TableCell>
        <TableCell
          sx={{ whiteSpace: "nowrap", borderBottom: "none", width: "100%" }}
        >
          <Stack direction="row" spacing={0.5} alignItems="center">
            <Typography
              color="text.secondary"
              variant="body2"
              fontWeight="medium"
            >
              Coverage
            </Typography>
            <Tooltip
              title={`Coverage of Resource Type ${datesFromTo}`}
              placement="top"
              arrow
            >
              <InfoOutlinedIcon
                sx={{ fontSize: 16, color: "action.disabled" }}
              />
            </Tooltip>
          </Stack>
        </TableCell>
        <TableCell
          sx={{ whiteSpace: "nowrap", borderBottom: "none", width: 200, pl: 0 }}
        >
          <Stack direction="row" spacing={0.5} alignItems="center">
            <Typography
              color="text.secondary"
              variant="body2"
              fontWeight="medium"
            >
              Not Covered
            </Typography>
            <Tooltip
              title={`Not Covered (On-demand) cost per Resource Type ${datesFromTo}`}
              placement="top"
              arrow
            >
              <InfoOutlinedIcon
                sx={{ fontSize: 16, color: "action.disabled" }}
              />
            </Tooltip>
          </Stack>
        </TableCell>
      </TableRow>
    </TableHead>
  );
};
