import { Button } from "@mui/material";
import { FC, useCallback } from "react";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
import Box from "@mui/material/Box";
import { AlertThresholdOperatorType } from "../../../../../../../../../../store/alerts/utils/types/types";

interface AlertsCostsAnomalyThresholdsOperatorProps {
  operator: AlertThresholdOperatorType;
  onChange(operator: AlertThresholdOperatorType): void;
}

export const AlertsCostsAnomalyThresholdsOperator: FC<
  AlertsCostsAnomalyThresholdsOperatorProps
> = ({ operator, onChange }) => {
  const operatorButtonClickHandler = useCallback(() => {
    onChange(operator === "or" ? "and" : "or");
  }, [operator, onChange]);

  return (
    <Box>
      <Button
        size="small"
        variant="outlined"
        endIcon={<UnfoldMoreIcon fontSize="small" />}
        onClick={operatorButtonClickHandler}
      >
        {labels[operator]}
      </Button>
    </Box>
  );
};

const labels: Record<AlertThresholdOperatorType, string> = {
  or: "or",
  and: "and",
};
