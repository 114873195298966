import { createDraftSafeSelector } from "@reduxjs/toolkit";
import { AccountOptionType } from "../../../../../../components/common/account-select/utils/types/types";
import { workflowsDataSelector } from "../../list-data/workflowsDataSelector";
import { ProviderType } from "../../../../../../services/cloudchipr.api";

export const automationsAccountsFilterOptionsSelector = createDraftSafeSelector(
  [workflowsDataSelector],
  (workflows): AccountOptionType[] => {
    if (!workflows) {
      return [];
    }

    const accounts = workflows.reduce(
      (acc, workflow) => {
        workflow.accounts.forEach((account) => {
          if (!acc[account.provider]) {
            acc[account.provider] = {};
          }

          acc[account.provider][account.id] = {
            value: account.id,
            providerAccountId: account.provider_account_id,
            label: account.provider_account_name || account.provider_account_id,
            accountType: account.provider,
            status: account.status,
          };
        });

        return acc;
      },
      {} as Record<ProviderType, Record<string, AccountOptionType>>,
    );

    return Object.values(accounts)
      .map((accounts) => Object.values(accounts))
      .flat();
  },
);
