import { FC, Fragment, memo, useCallback, useState } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import {
  CircularProgress,
  FormHelperText,
  Grid,
  TextField,
} from "@mui/material";
import { useDebounce } from "rooks";
import { selectMenuItem } from "./SelectMenuItem";
import { JiraAutocompleteOptionType } from "../../../../utils/types/types";
import {
  PostUsersMeJiraIntegrationsByIntegrationIdAutoCompleteUsersDataApiResponse,
  usePostUsersMeJiraIntegrationsByIntegrationIdAutoCompleteUsersDataMutation,
} from "../../../../../../../../../services/cloudchipr.api";

interface MultipleAutocompleteFieldProps {
  name: string;
  error?: string;
  integrationId: string;
  optionsUrl: string;
  label: string;
  value?: { id: string }[];
  setFieldValue(key: string, value: any): void;
}

export const MultipleAutocompleteField: FC<MultipleAutocompleteFieldProps> =
  memo(
    ({
      value,
      setFieldValue,
      name,
      label,
      optionsUrl,
      integrationId,
      error,
    }) => {
      const [inputValue, setInputValue] = useState("");
      const [allOptions, setAllOptions] =
        useState<PostUsersMeJiraIntegrationsByIntegrationIdAutoCompleteUsersDataApiResponse>(
          [],
        );
      const [trigger, { data: autoCompleteOptions, isLoading }] =
        usePostUsersMeJiraIntegrationsByIntegrationIdAutoCompleteUsersDataMutation();

      const getUniqueItemsFromArray = useCallback(
        (
          data: PostUsersMeJiraIntegrationsByIntegrationIdAutoCompleteUsersDataApiResponse,
          oldData: PostUsersMeJiraIntegrationsByIntegrationIdAutoCompleteUsersDataApiResponse,
        ) => {
          return [...data, ...oldData].filter((option, index, arr) => {
            const twinIndex = arr.findIndex(({ id }) => id === option.id);

            return twinIndex === index;
          });
        },
        [],
      );

      const getOptionsHandler = useCallback(
        (query: string) => {
          const url = `${optionsUrl || ""}${query || ""}`;

          if (!url || !query) {
            return;
          }

          trigger({
            integrationId,
            body: { auto_complete_url: url },
          })
            .unwrap()
            .then((data) => {
              setAllOptions((oldData) =>
                getUniqueItemsFromArray(data, oldData),
              );
            });
        },
        [integrationId, optionsUrl, trigger, getUniqueItemsFromArray],
      );

      const getOptionsDebounced = useDebounce(getOptionsHandler, 500);

      const inputChangeHandler = useCallback(
        (event: any) => {
          const value = event.target.value;

          getOptionsDebounced(value);
          setInputValue(value);
        },
        [getOptionsDebounced],
      );

      const changeHandler = useCallback(
        (_: any, options: JiraAutocompleteOptionType[]) => {
          setFieldValue(
            name,
            options?.map((option) => ({ id: option.id })),
          );
        },
        [setFieldValue, name],
      );

      const onClose = useCallback(() => {
        trigger({
          integrationId,
          body: { auto_complete_url: optionsUrl },
        });
      }, [trigger, optionsUrl, integrationId]);

      const renderOption = useCallback(
        (props: any, option: JiraAutocompleteOptionType) =>
          selectMenuItem(option, null, props),
        [],
      );

      const renderInput = useCallback(
        (params: any) => (
          <TextField
            {...params}
            label={label}
            value={inputValue}
            onChange={inputChangeHandler}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <Fragment>
                  {isLoading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : (
                    params.InputProps.endAdornment
                  )}
                </Fragment>
              ),
            }}
          />
        ),
        [inputValue, label, isLoading, inputChangeHandler],
      );

      const autoCompleteSelectedValue =
        allOptions?.filter((option) =>
          value?.some(({ id }) => id === option.id),
        ) || [];

      const options = [
        ...autoCompleteSelectedValue,
        ...(autoCompleteOptions || []),
      ];

      const noOptionsText =
        inputValue && !autoCompleteOptions?.length && !isLoading
          ? "No Options"
          : "Start typing to get a list of possible matches.";

      return (
        <Fragment>
          <Grid item sm={4}>
            <Autocomplete
              multiple
              fullWidth
              autoComplete
              includeInputInList
              filterSelectedOptions
              disableClearable
              openOnFocus
              disableCloseOnSelect
              clearOnBlur={false}
              onClose={onClose}
              limitTags={1}
              size="small"
              noOptionsText={isLoading ? "" : noOptionsText}
              getOptionLabel={(option) => option.name}
              filterOptions={(x) => x}
              options={options}
              value={autoCompleteSelectedValue}
              onChange={changeHandler}
              renderOption={renderOption}
              renderInput={renderInput}
            />
            {!!error && <FormHelperText error>{error} </FormHelperText>}
          </Grid>

          <Grid item sm={6} />
        </Fragment>
      );
    },
  );
