import { Typography } from "@mui/material";
import { ExecutionLogsDataGridStatusCell } from "./cells/ExecutionLogsDataGridStatusCell";
import { ExecutionLogsDataGridSourceCell } from "./cells/ExecutionLogsDataGridSourceCell";
import { ExecutionLogsDataGridLinkCopyCell } from "./cells/ExecutionLogsDataGridLinkCopyCell";
import { ExecutionLogsDataGridCSVCell } from "./cells/ExecutionLogsDataGridCSVCell";
import { ColumnSetupType } from "../../../../../storybook/data-grid/utils/types/types";
import { ExecutionLogV3 } from "../../../../../services/cloudchipr.api";
import {
  dateFromUtcByTimeZone,
  formatDate,
} from "../../../../../utils/helpers/date-time/datetime-format";
import { AccountsWithPopover } from "../../../../common/accounts-with-popover/AccountsWithPopover";
import { money } from "../../../../../utils/numeral/money";
import { getExpanderCell } from "../../../../../storybook/data-grid/common/getExpanderCell";

export const executionLogsTableColumns: ColumnSetupType<ExecutionLogV3>[] = [
  getExpanderCell<ExecutionLogV3>(),
  {
    accessorKey: "created_at",
    header: "Date",
    size: 180,
    cell: (cell) => {
      const createdAt = cell.getValue() as string;
      const localDateTime = dateFromUtcByTimeZone(createdAt);

      return (
        <Typography variant="body2" sx={{ whiteSpace: "nowrap" }}>
          {formatDate(localDateTime || "", { type: "dateTime" })}
        </Typography>
      );
    },
  },
  {
    accessorKey: "accounts",
    header: "Accounts",
    enableSorting: false,
    minSize: 200,
    cell: (cell) => {
      const accounts = cell.getValue() as ExecutionLogV3["accounts"];

      return (
        <AccountsWithPopover maxWidth={200} chipView accounts={accounts} />
      );
    },
  },
  {
    accessorKey: "monthly_price",
    header: () => (
      <Typography
        fontWeight="medium"
        variant="body2"
        align="right"
        width="100%"
      >
        Monthly Price
      </Typography>
    ),
    size: 130,
    cell: (cell) => {
      const allFailedOrSkipped = cell.row.original.response
        .map(({ resources }) => {
          return resources.map((item) => item.state);
        })
        .flat()
        .every((value) => value !== "succeed");

      return (
        <Typography pr={4} fontWeight="medium" variant="body2" align="right">
          {allFailedOrSkipped ? "-" : money(cell.getValue() as number)}
        </Typography>
      );
    },
  },
  {
    accessorKey: "source",
    header: "Executed By",
    minSize: 200,
    cell: (cell) => {
      return (
        <ExecutionLogsDataGridSourceCell
          source={cell.getValue() as ExecutionLogV3["source"]}
        />
      );
    },
  },
  {
    id: "status",
    accessorKey: "response",
    header: "Status",
    size: 70,
    cell: (cell) => {
      return (
        <ExecutionLogsDataGridStatusCell
          data={cell.getValue() as ExecutionLogV3["response"]}
        />
      );
    },
  },
  {
    id: "csv_export",
    accessorKey: "response",
    size: 40,
    header: "",
    enableSorting: false,
    cell: (cell) => {
      return <ExecutionLogsDataGridCSVCell executionLog={cell.row.original} />;
    },
  },
  {
    size: 40,
    accessorKey: "id",
    header: "",
    enableSorting: false,
    cell: (cell) => {
      return (
        <ExecutionLogsDataGridLinkCopyCell id={cell.getValue() as string} />
      );
    },
  },
];
