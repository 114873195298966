import { FC, ReactNode } from "react";
import {
  AbilityContext,
  createAbility,
  defineRulesFor,
} from "../services/permissions";
import { useAppSelector } from "../store/hooks";
import { authPermissionsSelector } from "../store/auth/authSelectors";

export const UserAbilities: FC<{ children?: ReactNode }> = ({ children }) => {
  const permissions = useAppSelector(authPermissionsSelector);

  if (!permissions.length) {
    return null;
  }

  const ability = createAbility();
  const rules = defineRulesFor(permissions);
  ability.update(rules);

  return (
    <AbilityContext.Provider value={ability}>
      {children}
    </AbilityContext.Provider>
  );
};
