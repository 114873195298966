import { createAsyncThunk } from "@reduxjs/toolkit";
import { WidgetCommitmentsUtilizationAggregation } from "../../../../../../services/cloudchipr.api";
import { RootState } from "../../../../../store";
import { commitmentUtilizationWidgetSetupSelector } from "../../../../selectors/setups/commitment-utilization/commitmentUtilizationWidgetSetupSelector";
import { setWidgetSetup } from "../../../../dashboardsSlice";

export const commitmentUtilizationWidgetAggregationChangeThunk =
  createAsyncThunk(
    "dashboard/commitmentUtilizationWidgetAggregationChange",
    async (
      aggregations: WidgetCommitmentsUtilizationAggregation,
      { dispatch, getState },
    ) => {
      const state = getState() as RootState;
      const existingSetup = commitmentUtilizationWidgetSetupSelector(state);

      if (!existingSetup) {
        return;
      }

      dispatch(
        setWidgetSetup({
          ...existingSetup,
          aggregation: {
            ...existingSetup.aggregation,
            ...aggregations,
          },
        }),
      );
    },
  );
