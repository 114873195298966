import { FC } from "react";
import { Grid, Typography } from "@mui/material";

interface RowLabelColumnProps {
  label: string;
  required?: boolean;
}

export const RowLabelColumn: FC<RowLabelColumnProps> = ({
  label,
  required,
}) => {
  return (
    <Grid
      item
      sm={2}
      sx={{
        verticalAlign: "top",
        textAlign: "right",
      }}
    >
      <Typography variant="subtitle1" color="text.secondary">
        {label}

        {required && (
          <Typography variant="subtitle1" color="error" component="span">
            {" "}
            *
          </Typography>
        )}
      </Typography>
    </Grid>
  );
};
