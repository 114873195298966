import { RootState } from "../../../../store";
import { editingCategorySelector } from "../../slice-data/editing-category/editingCategorySelector";
import { dimensionIdSelector } from "../../slice-data/dimensionIdSelector";
import { dimensionByIdSelector } from "../../dimensions-data/dimensionByIdSelector";
import { isDeepEqual } from "../../../../../utils/is-deep-equal";

export const anyChangesExistOnCategoryEditSelector = (state: RootState) => {
  const dimensionId = dimensionIdSelector(state);
  const editingCategory = editingCategorySelector(state);

  if (!dimensionId) {
    return false;
  }

  const originalDimension = dimensionByIdSelector(state, dimensionId);

  const category = originalDimension?.categories?.find(
    (category) => category.id === editingCategory?.id,
  );

  if (!editingCategory || !category) {
    return true;
  }

  return !isDeepEqual(editingCategory, category);
};
