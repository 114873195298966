import { FC, ReactNode } from "react";
import {
  DragOverlay,
  defaultDropAnimationSideEffects,
  DropAnimation,
} from "@dnd-kit/core";

const dropAnimationConfig: DropAnimation = {
  sideEffects: defaultDropAnimationSideEffects({}),
};

interface SortableNavItemOverlayProps {
  children: ReactNode;
}

export const SortableNavItemOverlay: FC<SortableNavItemOverlayProps> = ({
  children,
}) => {
  return (
    <DragOverlay dropAnimation={dropAnimationConfig}>{children}</DragOverlay>
  );
};
