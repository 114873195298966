import { scheduleNextRunDatesSelector } from "./scheduleNextRunDatesSelector";
import { RootState } from "../../../../../store";
import { getAutomationPreviewFormatDate } from "../../../../../../components/pages/schedule/common/utils/helpers/common";
import { AutomationTabType } from "../../../../../../components/pages/schedule/common/utils/types/types";

export const scheduleNextRunDateTimeSelector = (
  state: RootState,
  frequency?: AutomationTabType,
): string | undefined => {
  const runDate = scheduleNextRunDatesSelector(
    state,
    frequency,
  )?.next_run_date_time;
  if (!runDate) {
    return;
  }
  return getAutomationPreviewFormatDate(runDate);
};
