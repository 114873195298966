import { createContext, FC, ReactNode, useContext, useMemo } from "react";
import { ResourceExplorerPossibleFilterV2 } from "../../../../../services/cloudchipr.api";

interface FilterTreeContextType {
  possibleFilters: ResourceExplorerPossibleFilterV2[];
}

const FilterTreeContext = createContext({} as FilterTreeContextType);

export const FilterTreeProvider: FC<{
  children?: ReactNode;
  possibleFilters: ResourceExplorerPossibleFilterV2[];
}> = ({ children, possibleFilters }) => {
  const contextValue = useMemo(() => {
    return { possibleFilters };
  }, [possibleFilters]);

  return (
    <FilterTreeContext.Provider value={contextValue}>
      {children}
    </FilterTreeContext.Provider>
  );
};

export const useFilterTreeContext = () => {
  return useContext(FilterTreeContext);
};
