import { FC, useCallback } from "react";
import { Button, Stack } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useNavigate } from "react-router-dom";
import { enqueueSnackbar } from "notistack";
import { ActionsCancelButton } from "./ActionsCancelButton";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../store/hooks";
import { automationCurrentStepSelector } from "../../../../../../../store/automations/selectros/common/automationCurrentStepSelector";
import {
  resetAutomationsState,
  setAutomationStep,
} from "../../../../../../../store/automations/automationsSlice";
import { automationIdSelector } from "../../../../../../../store/automations/selectros/common/automationIdSelector";
import { automationSavingLoadingSelector } from "../../../../../../../store/automations/selectros/common/automationSavingLoadingSelector";
import { validateAutomationDataThunk } from "../../../../../../../store/automations/thunks/validateAutomationDataThunk";
import { saveWorkflowThunk } from "../../../../../../../store/automations/thunks/wokrflows/saveWorkflowThunk";
import { saveOffHoursThunk } from "../../../../../../../store/automations/thunks/off-hours/saveOffHoursThunk";
import { automationTypeSelector } from "../../../../../../../store/automations/selectros/common/automationTypeSelector";
import { CreateWorkflowSubmittedMessage } from "../../../../workflows/drawer/CreateWorkflowSubmittedMessage";
import { automationInDrawerOpenSelector } from "../../../../../../../store/automations/selectros/common/automationInDrawerOpenSelector";

export const AutomationActions: FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const step = useAppSelector(automationCurrentStepSelector);
  const automationId = useAppSelector(automationIdSelector);
  const loading = useAppSelector(automationSavingLoadingSelector);
  const automationType = useAppSelector(automationTypeSelector);
  const inDrawerMode = useAppSelector(automationInDrawerOpenSelector);

  const reviewHandler = useCallback(async () => {
    const errors = await dispatch(validateAutomationDataThunk()).unwrap();

    if (!errors) {
      dispatch(setAutomationStep("preview"));
    }
  }, [dispatch]);

  const previousHandler = useCallback(() => {
    dispatch(setAutomationStep("setup"));
  }, [dispatch]);

  const saveInDrawerHandler = useCallback(
    (id: string, name: string) => {
      dispatch(resetAutomationsState());

      enqueueSnackbar("", {
        variant: "snackbarAlert",
        AlertSnackBarProps: {
          severity: "success",
          action: <CreateWorkflowSubmittedMessage id={id} name={name} />,
        },
      });
    },
    [dispatch],
  );

  const saveHandler = useCallback(async () => {
    let response;
    if (automationType === "workflow") {
      response = await dispatch(saveWorkflowThunk()).unwrap();
    } else {
      response = await dispatch(saveOffHoursThunk()).unwrap();
    }

    if (inDrawerMode) {
      saveInDrawerHandler(response.id, response.name);
      return;
    }

    enqueueSnackbar(
      `${automationType === "workflow" ? "Workflow" : "Off Hours"} successfully ${automationId ? "updated" : "created"}.`,
      { variant: "snackbarAlert", AlertSnackBarProps: { severity: "success" } },
    );

    navigate(
      `/automations/${automationType === "workflow" ? "workflow" : "off-hours"}`,
    );
  }, [
    dispatch,
    navigate,
    automationType,
    inDrawerMode,
    saveInDrawerHandler,
    automationId,
  ]);

  return (
    <Stack direction="row" justifyContent="space-between">
      <ActionsCancelButton />

      {step === "setup" ? (
        <Button onClick={reviewHandler} variant="contained">
          Review
        </Button>
      ) : (
        <Stack direction="row" spacing={2}>
          <Button variant="outlined" onClick={previousHandler}>
            Previous
          </Button>

          <LoadingButton
            onClick={saveHandler}
            loading={loading}
            variant="contained"
          >
            {automationId ? "Update" : "Create"}
          </LoadingButton>
        </Stack>
      )}
    </Stack>
  );
};
