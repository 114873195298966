import { DateLabel } from "../../../../../services/cloudchipr.api";

export const preDefinedLabelsByDateLabel: Record<
  DateLabel,
  { long: string; short: string; withoutPrefix: string }
> = {
  past_seven_days: {
    long: "Past 7 days",
    short: "Past 7D",
    withoutPrefix: "7 days",
  },
  past_fourteen_days: {
    long: "Past 14 days",
    short: "Past 14D",
    withoutPrefix: "14 days",
  },
  past_thirty_days: {
    long: "Past 30 days",
    short: "Past 30D",
    withoutPrefix: "30 days",
  },
  past_forty_five_days: {
    long: "Past 45 days",
    short: "Past 45D",
    withoutPrefix: "45 days",
  },
  past_ninety_days: {
    long: "Past 90 days",
    short: "Past 90D",
    withoutPrefix: "90 days",
  },
  past_one_hundred_eighty_days: {
    long: "Past 180 days",
    short: "Past 180D",
    withoutPrefix: "180 days",
  },
  last_quarter: {
    long: "Last Quarter",
    short: "Last Quarter",
    withoutPrefix: "Quarter",
  },
  year_to_date: {
    long: "Year to Date",
    short: "YTD",
    withoutPrefix: "Year to Date",
  },
  quarter_to_date: {
    long: "Quarter to Date",
    short: "QTD",
    withoutPrefix: "Quarter to Date",
  },
  month_to_date: {
    long: "Month to Date",
    short: "MTD",
    withoutPrefix: "Month to Date",
  },
  last_one_month: {
    long: "Last 1 month",
    short: "Last 1M",
    withoutPrefix: "1 month",
  },
  last_three_months: {
    long: "Last 3 months",
    short: "Last 3M",
    withoutPrefix: "3 month",
  },
  last_six_months: {
    long: "Last 6 months",
    short: "Last 6M",
    withoutPrefix: "6 month",
  },
  last_twelve_months: {
    long: "Last 12 months",
    short: "Last 12M",
    withoutPrefix: "12 month",
  },
};
