import { FC, useCallback } from "react";
import {
  ListItemIcon,
  ListItemText,
  MenuItem,
  Typography,
} from "@mui/material";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { useAppDispatch } from "../../../../../../../../store/hooks";
import { useAppAbility } from "../../../../../../../../services/permissions";
import { WidgetType } from "../../../../../../../../services/cloudchipr.api";
import { editWidgetThunk } from "../../../../../../../../store/dashboards/thunks/widgets/common/editWidgetThunk";

interface WidgetEditActionMenuItemProps {
  widgetId: string;
  widgetType: WidgetType;
  onClose(): void;
}

export const WidgetEditActionMenuItem: FC<WidgetEditActionMenuItemProps> = ({
  widgetType,
  widgetId,
  onClose,
}) => {
  const dispatch = useAppDispatch();
  const { cannot } = useAppAbility();
  const canNotEditWidget = cannot("edit", "dashboard");

  const editWidgetHandler = useCallback(() => {
    dispatch(
      editWidgetThunk({
        widgetId,
        widgetType,
      }),
    );

    onClose();
  }, [dispatch, onClose, widgetId, widgetType]);

  return (
    <MenuItem onClick={editWidgetHandler} disabled={canNotEditWidget}>
      <ListItemIcon>
        <EditOutlinedIcon fontSize="small" />
      </ListItemIcon>
      <ListItemText>
        <Typography variant="body2">Edit</Typography>
      </ListItemText>
    </MenuItem>
  );
};
