import { RootState } from "../../../store";
import {
  ResourceFiltersWithAction,
  ResourceType,
} from "../../../../services/cloudchipr.api";
import { resourceGroups } from "../../../../utils/constants/resources/resourceGroups";
import { getFirstParentInHierarchyResourceType } from "../../../../utils/helpers/resources/getFirstParentInHierarchyResourceType";
import { isStringResourceType } from "../../../../utils/helpers/resources/isStringResourceType";
import { liveUsageMgmtProviderSelector } from "../store-selectors/liveUsageMgmtProviderSelector";
import { liveUsageMgmtRegionsSelector } from "../store-selectors/liveUsageMgmtRegionsSelector";
import { liveUsageMgmtAccountIdsSelector } from "../store-selectors/liveUsageMgmtAccountIdsSelector";
import { liveUsageMgmtAppliedFiltersByResourceTypeSelector } from "../liveUsageMgmtAppliedFiltersByResourceTypeSelector";

export const liveUsageMgmtAccountsWorkflowDataSelector = (
  state: RootState,
  resourceType: string | null,
) => {
  const accountIds = liveUsageMgmtAccountIdsSelector(state);
  const provider = liveUsageMgmtProviderSelector(state);
  const regions = liveUsageMgmtRegionsSelector(state);

  if (!provider || !accountIds) {
    return null;
  }

  const parentResourceType =
    getFirstParentInHierarchyResourceType(resourceType as ResourceType) ??
    resourceType;

  const providerResourceGroups = resourceGroups.get(provider);
  const service =
    providerResourceGroups?.find(
      ({ resourceTypes }) =>
        isStringResourceType(parentResourceType) &&
        resourceTypes.includes(parentResourceType),
    )?.value ?? providerResourceGroups?.[0]?.value;

  let resourceTypes = providerResourceGroups?.find(
    ({ value }) => value === service,
  )?.resourceTypes;

  if (!resourceTypes) {
    resourceTypes = providerResourceGroups?.[0]?.resourceTypes ?? [];
  }

  const filters: ResourceFiltersWithAction[] = resourceTypes.map((rt) => {
    const emptyFilterTemplate = {
      type: rt,
      filter_items: [],
      filter_groups: [],
      operators: [],
    };

    return {
      filter:
        liveUsageMgmtAppliedFiltersByResourceTypeSelector(state, rt) ??
        emptyFilterTemplate,
    };
  });

  return {
    provider,
    filters,
    resourceType: service,
    accountIds: accountIds,
    regions: regions ?? [],
  };
};
