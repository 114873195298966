import { UtilizationAlertsType } from "../../../../../../utils/types/types";

export const utilizationAlertTypeTitles = new Map<
  UtilizationAlertsType,
  string
>([
  ["commitments_expiration", "Commitments Expiration"],
  ["commitments_utilization", "Commitments Utilization"],
  ["coverage", "Coverage"],
]);

export const utilizationAlertThresholdTexts = new Map<
  UtilizationAlertsType,
  { title: string; description: string }
>([
  [
    "commitments_expiration",
    {
      title: "Get notified before",
      description:
        "Add all of the days you want to be notified before your Commitment expires.",
    },
  ],
  [
    "commitments_utilization",
    {
      title: "Threshold rules",
      description:
        "Receive notifications when utilization of each commitment falls below threshold.",
    },
  ],
  [
    "coverage",
    {
      title: "Threshold rules",
      description:
        "Receive notifications when total coverage falls below threshold.",
    },
  ],
]);
