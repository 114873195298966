import { FC, Fragment, useMemo } from "react";
import { Box, Chip, Stack, Typography } from "@mui/material";
import {
  GracePeriod,
  useGetUsersMeIntegrationsQuery,
} from "../../../../../../../services/cloudchipr.api";
import { IntegrationLogo } from "../../../../../../common/integration-dialogs/components/common/IntegrationLogo";
import { convertSecondsToUnits } from "../../../../common/utils/helpers/gracePeriodCalculations";
import { AutomationNotificationsEmails } from "../../../../common/components/preview/notifications/AutomationNotificationsEmails";

interface WorkflowGracePeriodPreviewProps {
  gracePeriod?: GracePeriod;
}

export const WorkflowGracePeriodPreview: FC<
  WorkflowGracePeriodPreviewProps
> = ({ gracePeriod }) => {
  const { message } = convertSecondsToUnits(gracePeriod?.period ?? 0);

  const { data } = useGetUsersMeIntegrationsQuery(
    {},
    { skip: !gracePeriod?.notifications?.length },
  );

  const { integrations, emails } = useMemo(() => {
    const initialValue = { integrations: [], emails: {} };

    return (
      gracePeriod?.notifications?.reduce((acc, notification) => {
        if (notification.integration_id) {
          acc.integrations.push(notification);
        } else {
          acc.emails = notification;
        }

        return acc;
      }, initialValue) ?? initialValue
    );
  }, [gracePeriod?.notifications]);

  return (
    <Stack>
      <Stack direction="row" spacing={1} mb={1}>
        <Typography variant="body2" mb={1}>
          Grace Period
        </Typography>
        {gracePeriod?.period && (
          <Typography variant="body2" mb={1}>
            {message}
          </Typography>
        )}
      </Stack>

      {emails && (
        <Stack spacing={1} mb={2}>
          <AutomationNotificationsEmails label="To" emails={emails.to} />
          <AutomationNotificationsEmails label="Cc" emails={emails.cc} />
          <AutomationNotificationsEmails label="Bcc" emails={emails.bcc} />
        </Stack>
      )}

      {!!integrations?.length && (
        <Fragment>
          <Typography variant="body2" mb={1}>
            Integration(s)
          </Typography>

          <Stack direction="row">
            {data?.map(({ id, type, name }) =>
              integrations?.some(
                (integration: any) => integration?.integration_id === id,
              ) ? (
                <Chip
                  key={id}
                  icon={
                    <Box pl={0.5} pt={0.5}>
                      <IntegrationLogo type={type} width={14} />
                    </Box>
                  }
                  label={name}
                  variant="outlined"
                  sx={{ mr: 1, mb: 1, ".MuiChip-icon": { ml: 1 } }}
                />
              ) : null,
            )}
          </Stack>
        </Fragment>
      )}
    </Stack>
  );
};
