import { FC, useCallback, useRef, useState } from "react";
import { Stack, Typography } from "@mui/material";
import { useEffectOnceWhen } from "rooks";
import { RangeSelectionInput } from "./RangeSelectionInput";
import { RangeSelectionPopover } from "./RangeSelectionPopover";
import { useDialog } from "../../../../../../utils/hooks/useDialog.hook";
import { Dates, NullableDate } from "../../../../../../services/cloudchipr.api";
import { formatDate } from "../../../../../../utils/helpers/date-time/datetime-format";
import { ApplyButton } from "../ApplyButton";
import { generateDateFromDateLabel } from "../../../utils/helpers/generateDateFromDateLabel";
import { getAvailableMinDateMessage } from "../../../utils/helpers/getAvailableMinDateMessage";

interface RangeSelectionProps {
  onClose(): void;
  minDate?: string;
  availableMinDate?: string;
  maxDate?: string;
  selectedDates: Dates;
  highlightToday?: boolean;
  quarterStartMonth?: number;
  onApply(from: NullableDate, to: NullableDate): void;
}

export const RangeSelection: FC<RangeSelectionProps> = ({
  highlightToday,
  onApply,
  onClose,
  selectedDates,
  minDate,
  maxDate,
  quarterStartMonth,
  availableMinDate,
}) => {
  const ref = useRef<null | HTMLDivElement>(null);
  const { open, openDialog, closeDialog } = useDialog();
  const [dates, setDates] = useState<Date[]>([]);
  const [clickSource, setClickSource] = useState("");

  const invalidRange = dates.length !== 2;

  const applyHandler = useCallback(() => {
    const firstDate = dates.at(0);
    const secondDate = dates.at(1);

    const from = firstDate
      ? formatDate(firstDate.toString(), { type: "yearMonthDay" })
      : null;
    const to = secondDate
      ? formatDate(secondDate.toString(), { type: "yearMonthDay" })
      : null;

    onApply(from, to);
    onClose();
  }, [dates, onApply, onClose]);

  const openHandler = useCallback(
    (source: string) => {
      openDialog();
      setClickSource(source);
    },
    [openDialog],
  );

  useEffectOnceWhen(() => {
    if (selectedDates.from && selectedDates.to) {
      setDates([
        new Date(formatDate(selectedDates.from)),
        new Date(formatDate(selectedDates.to)),
      ]);
      return;
    }

    if (selectedDates.label) {
      const dates = generateDateFromDateLabel(
        selectedDates.label,
        quarterStartMonth,
      );

      setDates([new Date(dates.dateFrom), new Date(dates.dateTo)]);
    }
  }, !dates.length);

  return (
    <Stack py={2} px={1.5}>
      <Typography variant="subtitle2" fontWeight="medium" mb={2}>
        Select date range
      </Typography>

      {availableMinDate && (
        <Typography variant="tiny" mb={2}>
          {getAvailableMinDateMessage(availableMinDate)}
        </Typography>
      )}

      <Stack ref={ref} spacing={1.5}>
        <RangeSelectionInput
          source="from"
          label="From"
          onClick={openHandler}
          date={dates.at(0)}
        />

        <RangeSelectionInput
          source="to"
          label="To"
          onClick={openHandler}
          date={dates.at(1)}
        />
      </Stack>

      <RangeSelectionPopover
        open={open}
        minDate={minDate}
        maxDate={maxDate}
        onChange={setDates}
        anchor={ref.current}
        onClose={closeDialog}
        selectedDates={dates}
        clickSource={clickSource}
        highlightToday={highlightToday}
      />

      <ApplyButton onClick={applyHandler} disabled={invalidRange} />
    </Stack>
  );
};
