import { createDraftSafeSelector } from "@reduxjs/toolkit";
import { capitalizeString } from "../../../../../utils/helpers/capitalizeString";
import { step1FormDataSelector } from "../forms/step1FormDataSelector";

export const budgetPreviewNotificationDataSelector = createDraftSafeSelector(
  [step1FormDataSelector],
  (data): { key: string; value: string }[] | null => {
    if (!data) {
      return null;
    }

    return [
      {
        key: "Threshold",
        value: data.thresholds
          .map(
            ({ percent, trigger }) =>
              `${percent}% ${capitalizeString(trigger)}`,
          )
          .join(", "),
      },
    ];
  },
);
