import { FC, useCallback } from "react";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../../../../store/hooks";
import { commitmentsCoverageGroupingSelector } from "../../../../../../store/commitments/selectors/common/view-options/commitmentsCoverageGroupingSelector";
import { setCommitmentsCoverageGrouping } from "../../../../../../store/commitments/commitmentsSlice";
import { getSavingsPlansForDataGridThunk } from "../../../../../../store/commitments/thunks/savings-plans/getSavingsPlansForDataGridThunk";
import { SavingsPlanCoverageGrouping } from "../../../../../../services/cloudchipr.api";
import { getReservationsForDataGridThunk } from "../../../../../../store/commitments/thunks/reservations/getReservationsForDataGridThunk";
import { commitmentsTypeTabSelector } from "../../../../../../store/commitments/selectors/common/commitmentsTypeTabSelector";

export const CoverageDataGridGrouping: FC = () => {
  const dispatch = useAppDispatch();
  const grouping = useAppSelector(commitmentsCoverageGroupingSelector);
  const activeTab = useAppSelector(commitmentsTypeTabSelector);

  const changeHandler = useCallback(
    (event: SelectChangeEvent) => {
      const grouping = event.target.value as SavingsPlanCoverageGrouping;

      if (!grouping) {
        return;
      }

      dispatch(setCommitmentsCoverageGrouping(grouping));

      if (activeTab === "reservation") {
        dispatch(getReservationsForDataGridThunk());
      } else {
        dispatch(getSavingsPlansForDataGridThunk());
      }
    },
    [dispatch, activeTab],
  );

  return (
    <FormControl size="xsmall" sx={{ width: 135 }}>
      <InputLabel>Group By</InputLabel>

      <Select
        sx={selectSx}
        label="Group By"
        value={grouping}
        onChange={changeHandler}
      >
        <MenuItem value="resource_type">Resource Type</MenuItem>
        <MenuItem value="account">Account </MenuItem>
      </Select>
    </FormControl>
  );
};

const selectSx = {
  bgcolor: "white",
  "& .MuiOutlinedInput-notchedOutline": { borderColor: "grey.300" },
};
