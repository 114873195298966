import { RootState } from "../../../store";
import { cloudChiprApi } from "../../../../services/cloudchipr.api";
import { liveFilteredTrackedResourcesFixedCacheKey } from "../../thunks/live-filtered-resources/utils/constants";

const selector =
  cloudChiprApi.endpoints.postUsersMeOrganisationsCurrentTrackedResources
    .select;

export const liveFilteredTrackedResourcesSelector = (state: RootState) => {
  return selector(liveFilteredTrackedResourcesFixedCacheKey)(state);
};
