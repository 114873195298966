import { FC, useCallback } from "react";
import { Button, Stack } from "@mui/material";
import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import { useAppDispatch } from "../../../../../../../../store/hooks";
import { userRoleGroupedPermissionsCollapsedChangeThunk } from "../../../../../../../../store/user/thunks/roles/userRoleGroupedPermissionsCollapsedChangeThunk";
import { userRolePermissionsSearchThunk } from "../../../../../../../../store/user/thunks/roles/userRolePermissionsSearchThunk";
import { GlobalFilter } from "../../../../../../../../storybook/data-grid/toolbar/GlobalFilter";

export const CreateRoleGroupedPermissionsToolbar: FC = () => {
  const dispatch = useAppDispatch();

  const expandAllClickHandler = useCallback(() => {
    dispatch(userRoleGroupedPermissionsCollapsedChangeThunk(false));
  }, [dispatch]);

  const collapseAllClickHandler = useCallback(() => {
    dispatch(userRoleGroupedPermissionsCollapsedChangeThunk(true));
  }, [dispatch]);

  const searchChangeHandler = useCallback(
    (value: string) => {
      dispatch(userRolePermissionsSearchThunk(value));
    },
    [dispatch],
  );

  return (
    <Stack justifyContent="space-between" spacing={1} direction="row">
      <Stack direction="row" spacing={1}>
        <Button
          variant="text"
          startIcon={<KeyboardDoubleArrowDownIcon />}
          sx={{ textTransform: "none", color: "text.secondary" }}
          size="small"
          onClick={expandAllClickHandler}
        >
          Expand All
        </Button>
        <Button
          variant="text"
          startIcon={<KeyboardDoubleArrowRightIcon />}
          sx={{ textTransform: "none", color: "text.secondary" }}
          size="small"
          onClick={collapseAllClickHandler}
        >
          Collapse All
        </Button>
      </Stack>

      <GlobalFilter
        globalFilter=""
        setGlobalFilter={searchChangeHandler}
        size="xsmall"
      />
    </Stack>
  );
};
