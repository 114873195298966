import { FC, Fragment, useMemo } from "react";
import { Divider } from "@mui/material";
import { RecommendationsTabType } from "../../off-hours-recommendations/components/OffHoursRecommendationsContent";
import { RecommendationsTabs } from "../../common/RecommendationsTabs";
import { useAppSelector } from "../../../../../../store/hooks";
import { rightsizingRecommendationsDataTotalLengthSelector } from "../../../../../../store/recommendations/selectors/rightsizing/data/rightsizingRecommendationsDataTotalLengthSelector";
import { rightsizingRecommendationsDismissedDataTotalLengthSelector } from "../../../../../../store/recommendations/selectors/rightsizing/data/rightsizingRecommendationsDismissedDataTotalLengthSelector";
import { rightsizingRecommendationsDismissedResourcesDataTotalLengthSelector } from "../../../../../../store/recommendations/selectors/rightsizing/dismissed-resources/rightsizingRecommendationsDismissedResourcesDataTotalLengthSelector";
import { rightsizingRecommendationsFilteredDataSelector } from "../../../../../../store/recommendations/selectors/rightsizing/data/rightsizingRecommendationsFilteredDataSelector";
import { rightsizingRecommendationsDismissedFilteredDataSelector } from "../../../../../../store/recommendations/selectors/rightsizing/data/rightsizingRecommendationsDismissedFilteredDataSelector";

interface RightsizingRecommendationTabsProps {
  loading: boolean;
  setActiveTab(tab: RecommendationsTabType): void;
}

export const RightsizingRecommendationTabs: FC<
  RightsizingRecommendationTabsProps
> = ({ setActiveTab, loading }) => {
  const activeLength = useAppSelector(
    rightsizingRecommendationsDataTotalLengthSelector,
  );
  const dismissedLength = useAppSelector(
    rightsizingRecommendationsDismissedDataTotalLengthSelector,
  );
  const filteredData = useAppSelector(
    rightsizingRecommendationsFilteredDataSelector,
  );
  const filteredDismissedData = useAppSelector(
    rightsizingRecommendationsDismissedFilteredDataSelector,
  );

  const dismissedResourcesLength = useAppSelector(
    rightsizingRecommendationsDismissedResourcesDataTotalLengthSelector,
  );

  const tabs = useMemo(() => {
    return [
      {
        value: "active",
        label: "Active",
        count: filteredData.length,
        disabled: !activeLength,
        tooltip: "There are no active recommendations",
      },
      {
        value: "dismissed",
        label: "Dismissed",
        count: filteredDismissedData.length,
        disabled: !dismissedLength,
        tooltip: "There are no dismissed recommendations",
      },
      {
        value: "dismissed_resources",
        label: "Dismissed Resources",
        count: dismissedResourcesLength,
        disabled: !dismissedResourcesLength,
        tooltip: "There are no dismissed resources",
      },
    ];
  }, [
    activeLength,
    dismissedLength,
    dismissedResourcesLength,
    filteredDismissedData.length,
    filteredData.length,
  ]);

  return (
    <Fragment>
      <RecommendationsTabs
        tabs={tabs}
        setActiveTab={setActiveTab}
        showCount={!loading}
      />

      <Divider />
    </Fragment>
  );
};
