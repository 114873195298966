import { ChartDataType } from "../../../../storybook/charts/multi-type-chart/utils/types/types";
import { normalizeResourceExplorerChartData } from "../../../common/utils/helpers/chart/normalizeResourceExplorerChartData";
import { resourceExplorerChartKeyGenerator } from "../../selectors/current-resource-explorer/chart/resourceExplorerChartDataSelector";
import {
  DateDataPoint,
  ResourceExplorerChartData,
} from "../../../../services/cloudchipr.api";
import { chartDataPointKey } from "../../../../storybook/charts/multi-type-chart/utils/constants/constants";

export const formatResourceExplorerChartData = (
  data: ResourceExplorerChartData[],
  dataPoint?: DateDataPoint,
) => {
  const chartData = data?.map((item) => {
    if (!item.items || !item.date) {
      return {} as ChartDataType;
    }

    const items = item.items.reduce(
      (acc, { field, label, cost }) => {
        const key = resourceExplorerChartKeyGenerator(field, label);

        if (field === undefined || cost === undefined) {
          return acc;
        }

        acc[key] = +cost;

        return acc;
      },
      {} as Record<string, number>,
    );

    return {
      [chartDataPointKey]: item.date,
      ...items,
    } as ChartDataType;
  });

  return normalizeResourceExplorerChartData(chartData, dataPoint);
};
