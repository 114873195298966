import { liveUsageMgmtSelectedResourcesByResourceTypeSelector } from "./liveUsageMgmtSelectedResourcesByResourceTypeSelector";
import { RootState } from "../../../store";
import {
  ProviderAccessType,
  ProviderType,
  ResourceType,
} from "../../../../services/cloudchipr.api";

export const liveUsageMgmtSelectedResourcesAccountsMetaDataSelector = (
  state: RootState,
  resourceType: ResourceType,
):
  | {
      name: string;
      id: string;
      provider: ProviderType;
      accessType: ProviderAccessType;
    }[]
  | undefined => {
  const data = liveUsageMgmtSelectedResourcesByResourceTypeSelector(
    state,
    resourceType,
  );

  if (!data) {
    return;
  }

  return data?.reduce(
    (result, item) => {
      const id = item.resource.account.id;
      const name = `${item.resource.account.provider_account_name ?? item.resource.account.provider_account_id}`;

      const newItem = {
        name,
        provider: item.resource.account.provider,
        id,
        accessType: item.resource.account.provider_access_type,
      };
      if (!result?.find((item) => item.id === id)) {
        result.push(newItem);
      }
      return result;
    },
    [] as {
      id: string;
      name: string;
      provider: ProviderType;
      accessType: ProviderAccessType;
    }[],
  );
};
