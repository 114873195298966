import { createAsyncThunk } from "@reduxjs/toolkit";
import { ReportsData, ReportsSchedule } from "../utils/types/types";
import { reportsDataPropertyByKeySelector } from "../selector/reportsDataPropertyByKeySelector";
import { setReportsData } from "../reportsSlice";
import { RootState } from "../../store";

export const setReportsScheduleDataThunk = createAsyncThunk(
  "reports/setReportsScheduleData",
  (data: Partial<ReportsSchedule>, { dispatch, getState }) => {
    const state = getState() as RootState;
    const schedulePrevData =
      reportsDataPropertyByKeySelector("schedule")(state);

    if (!data) {
      return;
    }

    dispatch(
      setReportsData({
        schedule: {
          ...schedulePrevData,
          ...data,
        } as ReportsData["schedule"],
      }),
    );
  },
);
