import { Typography } from "@mui/material";
import { ColumnSetupType } from "../../../../../../../../../../../storybook/data-grid/utils/types/types";
import { ReservationsCoverageDataWithId } from "../../../../../../../../common/utils/types";

export const coveredHoursColumn: ColumnSetupType<ReservationsCoverageDataWithId> =
  {
    accessorKey: "covered_hours",
    header: () => (
      <Typography variant="body2" fontWeight="medium">
        Covered Hours
      </Typography>
    ),
    meta: { headerTitle: "Covered Hours" },
    cell: ({ row }) => `${row.original.covered_hours}h`,
  };
