import { ColDef } from "@ag-grid-community/core/dist/types/src/entities/colDef";

export const assigneesComparatorFn: ColDef["comparator"] = (
  _,
  __,
  nodeA,
  nodeB,
) => {
  const currentAssignee = nodeA.data?.assignees?.at(0);
  const nextAssignee = nodeB.data?.assignees?.at(0);

  const current = currentAssignee?.name ?? currentAssignee?.email ?? "";
  const next = nextAssignee?.name ?? nextAssignee?.email ?? "";

  return current.localeCompare(next);
};
