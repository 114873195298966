import { ChangeEvent, FC } from "react";
import { Stack, TextField, Typography } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import { RenderToolbarProps } from "../../../../../../storybook/data-grid/utils/types/prop-types";

export const UsersTableToolbar: FC<RenderToolbarProps> = (props) => {
  const changeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    props.setGlobalFilter(event.target.value);
  };

  const { rows } = props.table.getRowModel();

  return (
    <Stack direction="row" spacing={1} justifyContent="space-between" py={1}>
      <Typography variant="body1" fontWeight="medium">
        Users{" "}
        <Typography
          color="text.secondary"
          component="span"
          variant="inherit"
          fontWeight="400"
        >
          ({rows?.length})
        </Typography>
      </Typography>
      <TextField
        type="search"
        placeholder="Search"
        value={props.globalFilter}
        fullWidth
        size="xsmall"
        onChange={changeHandler}
        sx={{ maxWidth: 200 }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
      />
    </Stack>
  );
};
