import { RootState } from "../../../store";
import { cloudChiprApi } from "../../../../services/cloudchipr.api";
import { deleteCostAnomalyAlertFixedCacheKey } from "../../utils/constants/fixedCacheKeys";

const endpoint =
  cloudChiprApi.endpoints.deleteUsersMeOrganisationsCurrentAlertsCostAnomalyByAlertId.select(
    deleteCostAnomalyAlertFixedCacheKey,
  );

export const deleteCostAnomalyAlertLoadingSelector = (state: RootState) => {
  return endpoint(state)?.isLoading;
};
