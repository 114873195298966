import { FC, useCallback } from "react";
import {
  FormControl,
  FormControlProps,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { DateDataPoint } from "../../../../../../../services/cloudchipr.api";

interface CommitmentsDateGranularitySelectorProps {
  size?: FormControlProps["size"];
  dateGranularity: DateDataPoint;
  onChange(dg: DateDataPoint): void;
}

export const CommitmentsDateGranularitySelector: FC<
  CommitmentsDateGranularitySelectorProps
> = ({ dateGranularity, onChange, size = "xsmall" }) => {
  const changeHandler = useCallback(
    (event: SelectChangeEvent) => {
      const value = event.target.value as DateDataPoint;

      if (!value) {
        return;
      }

      onChange(value);
    },
    [onChange],
  );

  return (
    <FormControl size={size} fullWidth sx={{ width: 110 }}>
      <Select
        value={dateGranularity}
        onChange={changeHandler}
        name="data_point"
        sx={{ bgcolor: "white" }}
      >
        <MenuItem value="daily">Daily</MenuItem>
        <MenuItem value="monthly">Monthly</MenuItem>
      </Select>
    </FormControl>
  );
};
