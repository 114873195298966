import { FC, ReactElement, ReactNode, useCallback } from "react";
import { Link, MenuItem } from "@mui/material";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import Typography from "@mui/material/Typography";

interface BreadcrumbsItemProps {
  title: ReactNode;
  to?: string;
  icon?: ReactElement;
  nested?: boolean;
}

const styling = {
  color: "text.secondary",
  display: "flex",
  alignItems: "center",
  gap: 1,
};

export const BreadcrumbsItem: FC<BreadcrumbsItemProps> = ({
  to,
  title,
  icon,
  nested,
}) => {
  const navigate = useNavigate();

  const onRouteChange = useCallback(() => {
    to && navigate(to);
  }, [navigate, to]);

  const navItem = (
    <Typography
      sx={{ ...styling, whiteSpace: "nowrap" }}
      variant="body1"
      component="div"
    >
      {icon}
      {title}
    </Typography>
  );

  if (nested) {
    return <MenuItem onClick={onRouteChange}>{navItem}</MenuItem>;
  }

  if (to) {
    return (
      <Link to={to} underline="hover" component={RouterLink} sx={styling}>
        {icon}
        {title}
      </Link>
    );
  }

  return navItem;
};
