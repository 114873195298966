import { cloudChiprApi } from "../../../../services/cloudchipr.api";
import { RootState } from "../../../store";
import { createResourceExplorerThunkFixedCacheKey } from "../../thunks/resource-explorer/createResourceExplorerThunk";

const selector =
  cloudChiprApi.endpoints.postV2UsersMeOrganisationsCurrentResourceExplorerViews
    .select;

export const createResourceExplorerLoadingSelector = (
  state: RootState,
): boolean => {
  return !!selector(createResourceExplorerThunkFixedCacheKey)(state)?.isLoading;
};
