import { FC } from "react";
import { Collapse, Stack, Typography } from "@mui/material";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { useToggle } from "rooks";
import { useGetUsersMeProvidersAwsAccountsByAccountIdMissingPermissionsQuery } from "../../../../../../../../services/cloudchipr.api";

interface MissingPermissionsProps {
  accountId: string;
}

export const MissingPermissions: FC<MissingPermissionsProps> = ({
  accountId,
}) => {
  const { data } =
    useGetUsersMeProvidersAwsAccountsByAccountIdMissingPermissionsQuery({
      accountId,
    });

  const [collapsed, toggleCollapsed] = useToggle();

  return (
    <Stack p={1} bgcolor="grey.300" borderRadius={2}>
      <Stack
        direction="row"
        alignItems="center"
        onClick={toggleCollapsed}
        sx={{ cursor: "pointer" }}
        justifyContent="space-between"
      >
        <Typography variant="caption" fontWeight="bold" color="text.disabled">
          Missing Permissions
        </Typography>

        {collapsed ? (
          <ArrowDropUpIcon color="action" />
        ) : (
          <ArrowDropDownIcon color="action" />
        )}
      </Stack>

      <Collapse in={collapsed} sx={{ maxHeight: 200, overflow: "auto" }}>
        <ul style={{ margin: "8px 0" }}>
          {data?.map((p) => (
            <li key={p.name}>
              <Typography variant="caption">{p.name}</Typography>
            </li>
          ))}
        </ul>
      </Collapse>
    </Stack>
  );
};
