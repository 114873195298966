import { Account } from "../../../services/cloudchipr.api";

export const providerAllAccountsCombiner = (
  accounts: Record<string, Account[]> | null,
): Account[] | null => {
  if (!accounts) {
    return null;
  }

  return Object.values(accounts).flat();
};
