import { isTreeNodeFilterGroup } from "./isTreeNodeFilterGroup";
import { FilterTreeNode } from "../../../../../../services/cloudchipr.api";
import { FilterTreeNodeWithId } from "../types/common";

export const removeIdsFromFilterTreeItems = (
  treeNode: FilterTreeNodeWithId,
): FilterTreeNode => {
  if (!isTreeNodeFilterGroup(treeNode)) {
    const { id, ...data } = treeNode;

    return data as FilterTreeNode;
  }

  return {
    ...treeNode,
    items: treeNode.items.map(removeIdsFromFilterTreeItems),
  };
};
