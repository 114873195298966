import { createAsyncThunk } from "@reduxjs/toolkit";
import { closeSnackbar, enqueueSnackbar, SnackbarKey } from "notistack";
import { getResourceExplorerVisibilityHierarchyThunk } from "./getResourceExplorerVisibilityHierarchyThunk";
import { createResourceExplorerVisibilityHierarchyItemByBodyThunk } from "./createResourceExplorerVisibilityHierarchyItemByBodyThunk";
import {
  cloudChiprApi,
  PostV2UsersMeOrganisationsCurrentDashboardsApiArg,
  PostV2UsersMeOrganisationsCurrentResourceExplorerViewsApiArg,
} from "../../../../services/cloudchipr.api";
import { RootState } from "../../../store";
import { resourceExplorerFilterTreeSelector } from "../../selectors/filter-tree/resourceExplorerFilterTreeSelector";
import { resourceExplorerDataSelector } from "../../selectors/current-resource-explorer/data/resourceExplorerDataSelector";
import { defaultResourceExplorerViewIdSelector } from "../../selectors/all-resource-explorers/defaultResourceExplorerViewIdSelector";

interface CreateResourceExplorerVisibilityHierarchyItemThunkArgs {
  name: string;
  visibility: PostV2UsersMeOrganisationsCurrentDashboardsApiArg["body"]["visibility"];
  refetch?: boolean;
  viewId?: string;
}

// todo: This is a temporary function: delete this function after we get the default view data from backend
const getResourceExplorerItemFilterTree = async (
  state: RootState,
  dispatch: any,
  viewId: string | undefined,
) => {
  const {
    getUsersMeOrganisationsCurrentResourceExplorerViewsByResourceExplorerViewId,
  } = cloudChiprApi.endpoints;
  let filterTree;

  if (!viewId) {
    const defaultViewId = defaultResourceExplorerViewIdSelector(state);
    if (defaultViewId) {
      const defaultViewData = await dispatch(
        getUsersMeOrganisationsCurrentResourceExplorerViewsByResourceExplorerViewId.initiate(
          { resourceExplorerViewId: defaultViewId },
        ),
      ).unwrap();

      filterTree = defaultViewData?.filter_tree;
    }
  } else {
    filterTree = resourceExplorerFilterTreeSelector(state);
  }

  return filterTree;
};

export const createResourceExplorerVisibilityHierarchyItemThunk =
  createAsyncThunk(
    "resourceExplorer/createResourceExplorerVisibilityHierarchyItem",
    async (
      {
        name,
        visibility,
        refetch,
        viewId,
      }: CreateResourceExplorerVisibilityHierarchyItemThunkArgs,
      { dispatch, getState },
    ) => {
      const state = getState() as RootState;
      let response;
      // const filterTree = resourceExplorerFilterTreeSelector(state); // todo: Uncomment this line and remove the following line after we get the default view data from backend
      const filterTree = await getResourceExplorerItemFilterTree(
        state,
        dispatch,
        viewId,
      );
      const resourceExplorerData = resourceExplorerDataSelector(state);

      const body: PostV2UsersMeOrganisationsCurrentResourceExplorerViewsApiArg["body"] =
        {
          name,
          // filter_tree: viewId ? filterTree : undefined, // todo: Uncomment this line and remove the following line after we get default view data from backend
          filter_tree: filterTree,
          visibility,
          data: viewId ? resourceExplorerData : undefined,
        };

      try {
        response = await dispatch(
          createResourceExplorerVisibilityHierarchyItemByBodyThunk(body),
        ).unwrap();

        if (response) {
          if (refetch) {
            dispatch(getResourceExplorerVisibilityHierarchyThunk());
          }

          const snackbarId: SnackbarKey = enqueueSnackbar(
            "View successfully created.",
            {
              variant: "snackbarAlert",
              AlertSnackBarProps: {
                severity: "success",
                onClose: () => closeSnackbar(snackbarId),
              },
            },
          );
        }
        return response;
      } catch (e) {
        // @ts-expect-error TODO: return to this after adding error type
        const errMessage = e?.data?.message || "Something went wrong";
        const snackbarId: SnackbarKey = enqueueSnackbar(errMessage, {
          variant: "snackbarAlert",
          AlertSnackBarProps: {
            severity: "error",
            onClose: () => closeSnackbar(snackbarId),
          },
        });
      }
    },
  );
