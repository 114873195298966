import { FC } from "react";
import { Stack } from "@mui/material";
import { TypographyWithTooltip } from "../../../../../../../../../common/TypographyWithTooltip";

interface InUseCellProps {
  data: boolean;
  id: string | null;
}

export const InUseCell: FC<InUseCellProps> = ({ data, id }) => {
  return (
    <Stack>
      {data ? "Used By" : "No"}
      {data && (
        <TypographyWithTooltip
          title={id ?? ""}
          fontSize="small"
          color="text.disabled"
        />
      )}
    </Stack>
  );
};
