import { RootState } from "../../../store";
import { cloudChiprApi } from "../../../../services/cloudchipr.api";

export const cfnChangeSetsLoadingSelector = (
  state: RootState,
): boolean | undefined => {
  return cloudChiprApi.endpoints.postUsersMeProvidersAwsAccountsByAccountIdCfnChangeSets.select(
    "cfn-chang-sets",
  )(state)?.isLoading;
};
