import { FC, useMemo } from "react";
import { WorkflowGracePeriodPreview } from "./WorkflowGracePeriodPreview";
import { AutomationActionNotifications } from "../../../../common/components/preview/notifications/AutomationActionNotifications";
import {
  AutomationPreviewTable,
  AutomationPreviewTableProps,
} from "../../../../common/components/form/helper-components/AutomationPreviewTable";
import { AutomationSectionCard } from "../../../../common/components/form/helper-components/AutomationSectionCard";
import { useAppSelector } from "../../../../../../../store/hooks";
import { automationNotificationsSelector } from "../../../../../../../store/automations/selectros/common/fields/automationNotificationsSelector";
import { automationEmailsSelector } from "../../../../../../../store/automations/selectros/common/fields/automationEmailsSelector";
import { automationThresholdSelector } from "../../../../../../../store/automations/selectros/common/fields/automationThresholdSelector";
import { workflowSnoozedSelector } from "../../../../../../../store/automations/selectros/workflow/workflowSnoozedSelector";
import { workflowGracePeriodSelector } from "../../../../../../../store/automations/selectros/workflow/workflowGracePeriodSelector";
import { workflowActionSelector } from "../../../../../../../store/automations/selectros/workflow/workflowActionSelector";

export const WorkflowNotificationsPreview: FC = () => {
  const emails = useAppSelector(automationEmailsSelector);
  const notifications = useAppSelector(automationNotificationsSelector);
  const action = useAppSelector(workflowActionSelector);
  const threshold = useAppSelector(automationThresholdSelector);

  const snoozed = useAppSelector(workflowSnoozedSelector);
  const gracePeriod = useAppSelector(workflowGracePeriodSelector);

  const rows = useMemo(() => {
    const rows: AutomationPreviewTableProps["rows"] = [
      {
        title: "Action",
        label: action === "notify" ? "Notify Only" : "Take Action",
      },
    ];

    rows.push({
      title: action === "notify" ? "Notification" : "Action Notification",
      label: (
        <AutomationActionNotifications
          emails={emails}
          snoozed={snoozed}
          minThreshold={threshold}
          notifications={notifications}
        />
      ),
    });

    if (gracePeriod) {
      rows.push({
        title: "Grace Period Before Actions",
        label: <WorkflowGracePeriodPreview gracePeriod={gracePeriod} />,
      });
    }

    return rows;
  }, [gracePeriod, emails, snoozed, action, threshold, notifications]);

  return (
    <AutomationSectionCard title="Select Action" icon="action" preview>
      <AutomationPreviewTable rows={rows} />
    </AutomationSectionCard>
  );
};
