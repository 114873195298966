import { createAsyncThunk } from "@reduxjs/toolkit";
import { getOffHoursRecommendationsThunk } from "./getOffHoursRecommendationsThunk";

export const getOffHoursAllActiveRecommendationsThunk = createAsyncThunk(
  "recommendations/getOffHoursAllActiveRecommendations",
  async (_, { dispatch }) => {
    const compute = await dispatch(
      getOffHoursRecommendationsThunk({ group: "compute" }),
    ).unwrap();
    const database = await dispatch(
      getOffHoursRecommendationsThunk({ group: "database" }),
    ).unwrap();

    return [...compute, ...database].flat();
  },
);
