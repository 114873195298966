import { FC, SyntheticEvent } from "react";
import { FormControlLabel, ListItemText, Radio } from "@mui/material";
import { TrendSettingsExampleDates } from "../TrendSettingsExampleDates";
import { TrendType } from "../../../../../../../../../../services/cloudchipr.api";
import { useAppSelector } from "../../../../../../../../../../store/hooks";
import { resourceExplorerTrendTypeSelector } from "../../../../../../../../../../store/resource-explorer/selectors/current-resource-explorer/resourceExplorerTrendTypeSelector";
import { trendSettingsTitlesByType } from "../../utils/constants";

interface ViewTrendSettingsRadioButtonProps {
  value: TrendType;
  onChange: (event: SyntheticEvent, checked: boolean) => void;
}

export const ViewTrendSettingsRadioButton: FC<
  ViewTrendSettingsRadioButtonProps
> = ({ value, onChange }) => {
  const trendType = useAppSelector(resourceExplorerTrendTypeSelector);

  return (
    <FormControlLabel
      onChange={onChange}
      checked={trendType === value}
      value={value}
      control={<Radio size="small" />}
      label={
        <ListItemText
          primary={trendSettingsTitlesByType.get(value)}
          primaryTypographyProps={{
            variant: "body2",
            fontWeight: "medium",
          }}
          secondary={<TrendSettingsExampleDates trendType={value} />}
        />
      }
    />
  );
};
