import { FC } from "react";
import { AccountActions } from "../account-card/actions/AccountActions";
import {
  ProviderAccessType,
  ProviderType,
} from "../../../../../../services/cloudchipr.api";

interface AccountsTableActionCellProps {
  accountId: string;
  disableDetach: boolean;
  attach: boolean;
  retry: boolean;
  provider: ProviderType;
  accessMode: ProviderAccessType;
  subAccount: boolean;
}

export const AccountsTableActionCell: FC<AccountsTableActionCellProps> = ({
  accountId,
  attach,
  disableDetach,
  provider,
  retry,
  accessMode,
  subAccount,
}) => {
  return (
    <AccountActions
      accountId={accountId}
      provider={provider}
      attach={attach}
      retry={retry}
      disableDetach={disableDetach}
      accessMode={accessMode}
      subAccount={subAccount}
    />
  );
};
