import { createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../../../store";
import { liveUsageMgmtAccountIdsSelector } from "../../../live-usage-mgmt/selectors/store-selectors/liveUsageMgmtAccountIdsSelector";
import { liveUsageMgmtProviderSelector } from "../../../live-usage-mgmt/selectors/store-selectors/liveUsageMgmtProviderSelector";
import { cleanV2ProcessResourcesSelector } from "../../selectors/cleanV2ProcessResourcesSelector";
import {
  CleanV2Details,
  setCleanV2CurrentId,
  setCleanV2Details,
  setCleanV2Open,
} from "../../cleanV2Slice";
import { NEW_CLEAN_ID } from "../../utils/constants";
import { ResourceType } from "../../../../services/cloudchipr.api";

interface CreateSingleCleanThunkArgs {
  resourceType: ResourceType;
  resourceId: string;
}
export const createV2SingleCleanThunk = createAsyncThunk(
  "createV2SingleClean",
  (
    { resourceType, resourceId }: CreateSingleCleanThunkArgs,
    { dispatch, getState },
  ) => {
    const state = getState() as RootState;
    const accountIds = liveUsageMgmtAccountIdsSelector(state);
    const provider = liveUsageMgmtProviderSelector(state);
    const cleanProcessResources = cleanV2ProcessResourcesSelector(
      state,
      resourceType,
      {
        [resourceId]: true,
      },
    );

    if (accountIds && provider) {
      const cleanProcess: CleanV2Details = {
        accountIds,
        resourceType,
        resources: cleanProcessResources?.resources,
        excludedResources: cleanProcessResources?.excludedResources,
      };

      dispatch(setCleanV2Details(cleanProcess));
      dispatch(setCleanV2CurrentId(NEW_CLEAN_ID));
      dispatch(setCleanV2Open(true));
    }
  },
);
