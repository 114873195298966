import { FC, Fragment, useCallback, useState } from "react";
import { Button, Container, Stack } from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useFlag } from "@unleash/proxy-client-react";
import { ChatQuestionsSection } from "./components/ChatQuestionsSection";
import { ChatForm } from "./components/ChatForm";
import { ChatAnswerSection } from "./components/ChatAnswerSection";
import { mutationFirstCacheKey } from "./utils/constants";
import { PageHeader } from "../common/PageHeader";
import { usePostUsersMeChatQuestionsMutation } from "../../../services/cloudchipr.api";
import { useAppDispatch } from "../../../store/hooks";
import { setCacheKey } from "../../../store/cloudchat/cloudChatSlice";

export const CloudChat: FC = () => {
  const enableCloudChat = useFlag("EnableCloudchat");

  const dispatch = useAppDispatch();
  const [newKey, setNewKey] = useState(mutationFirstCacheKey);

  const [trigger, { isLoading }] = usePostUsersMeChatQuestionsMutation({
    fixedCacheKey: newKey,
  });

  const [question, setQuestion] = useState("");

  const resetQuestionHandler = useCallback(() => {
    setQuestion("");
    setNewKey(mutationFirstCacheKey);
  }, []);

  const askQuestionHandler = useCallback(
    async (question: string) => {
      setQuestion(question);

      await trigger({ body: { question } }).unwrap();

      setNewKey((key) => key + 1);
      dispatch(setCacheKey(newKey));
    },
    [dispatch, trigger, newKey],
  );

  const isQuestionsPage = newKey === mutationFirstCacheKey;

  if (!enableCloudChat) {
    return null;
  }

  return (
    <Fragment>
      <PageHeader
        title="CloudChat"
        actions={
          !isQuestionsPage && (
            <Button
              size="small"
              startIcon={<ArrowBackIosIcon />}
              onClick={resetQuestionHandler}
            >
              Back To Questions
            </Button>
          )
        }
      />

      <Container sx={{ mt: 4 }}>
        <Stack maxHeight="85vh">
          {isQuestionsPage ? (
            <ChatQuestionsSection
              questionsDisabled={isLoading}
              onQuestionChange={setQuestion}
            />
          ) : (
            <ChatAnswerSection question={question} />
          )}

          <ChatForm
            isLoading={isLoading}
            question={question}
            onSubmit={askQuestionHandler}
          />
        </Stack>
      </Container>
    </Fragment>
  );
};
