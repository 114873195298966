import { FC, ReactNode } from "react";
import { Box, Card, CardContent, Slide, Typography } from "@mui/material";
import { useHover } from "../../../../../utils/hooks/useHover.hook";

interface WidgetOptionCardProps {
  title: string;
  description: string;
  disabledAction?: ReactNode;
  onClick?(): void;
  children?: ReactNode;
}

export const WidgetOptionCard: FC<WidgetOptionCardProps> = ({
  description,
  title,
  children,
  disabledAction,
  onClick,
}) => {
  const { ref, hovered } = useHover();

  return (
    <Card
      ref={ref}
      variant="outlined"
      onClick={disabledAction ? undefined : onClick}
      sx={{
        position: "relative",
        cursor: disabledAction ? "unset" : "pointer",
        boxShadow: !disabledAction
          ? (theme) => {
              return hovered ? theme.shadows[5] : theme.shadows[0];
            }
          : null,
      }}
    >
      {disabledAction && (
        <Box
          position="absolute"
          top={0}
          width="100%"
          height="100%"
          bgcolor="white"
          sx={{ opacity: 0.5 }}
        />
      )}

      <CardContent sx={{ p: 1 }}>
        <Typography variant="body1" fontWeight="medium" align="center">
          {title}
        </Typography>

        <Typography
          mt={0.5}
          mb={1}
          variant="body2"
          color="text.secondary"
          align="center"
          fontSize={13}
        >
          {description}
        </Typography>

        {children}
      </CardContent>

      {disabledAction && (
        <Slide in={hovered} direction="up" timeout={500}>
          <Box
            p={2}
            width="100%"
            bottom={0}
            bgcolor="#000000bf"
            position="absolute"
          >
            {disabledAction}
          </Box>
        </Slide>
      )}

      <div />
    </Card>
  );
};
