import { FC } from "react";
import { ListItemText, MenuItem, Stack, Tooltip } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { costsAnomalyThresholdDataByTypeV2 } from "../../../utils/constants/constants";
import { CostAnomalyAlertUnitType } from "../../../../../../../../../../store/alerts/utils/types/types";

interface AlertsCostsAnomalyThresholdUnitSelectItemProps {
  type: CostAnomalyAlertUnitType;
  selected: boolean;
  onClick: () => void;
  disabled: boolean;
}

export const AlertsCostsAnomalyThresholdUnitSelectItem: FC<
  AlertsCostsAnomalyThresholdUnitSelectItemProps
> = ({ type, selected, onClick, disabled }) => {
  return (
    <MenuItem
      value={type}
      selected={selected}
      disableRipple={disabled}
      onClick={onClick}
      sx={{
        opacity: disabled ? 0.5 : 1,
        position: "relative",
        cursor: disabled ? "default" : "pointer",
        "&:hover": disabled ? { bgcolor: "white" } : undefined,
      }}
    >
      <Tooltip
        title={costsAnomalyThresholdDataByTypeV2.get(type)?.tooltipTitle}
        placement="right"
        arrow
      >
        <Stack
          flex={1}
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <ListItemText>
            {costsAnomalyThresholdDataByTypeV2.get(type)?.title}
          </ListItemText>

          <InfoOutlinedIcon fontSize="small" color="action" />
        </Stack>
      </Tooltip>
    </MenuItem>
  );
};
