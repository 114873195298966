import { ChangeEvent, FC, useCallback, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../store/hooks";
import { getOrgCurrentRolesThunk } from "../../../../../../../../store/user/thunks/roles/getOrgCurrentRolesThunk";
import { duplicateUserRoleByIdThunk } from "../../../../../../../../store/user/thunks/roles/duplicateUserRoleByIdThunk";
import { userRoleCreationLoadingSelector } from "../../../../../../../../store/user/selectors/roles/loading/userRoleCreationLoadingSelector";
import { DialogTitleClosable } from "../../../../../../../common/dialog-utils/DialogTitleClosable";
import {
  resetUserRoleData,
  setUserRoleData,
} from "../../../../../../../../store/user/userSlice";

interface RolesDuplicationDialogProps {
  name: string;
  id: string;
  onClose(): void;
  open: boolean;
}
export const RoleDuplicationDialog: FC<RolesDuplicationDialogProps> = ({
  name,
  id,
  onClose,
  open,
}) => {
  const dispatch = useAppDispatch();
  const loading = useAppSelector(userRoleCreationLoadingSelector);

  const [roleName, setRoleName] = useState(`${name} (copy)`);

  const duplicateRoleHandler = useCallback(async () => {
    if (!id) {
      return;
    }
    await dispatch(duplicateUserRoleByIdThunk(id));
    dispatch(getOrgCurrentRolesThunk());
    dispatch(resetUserRoleData());
    onClose();
  }, [dispatch, id, onClose]);

  const nameChangeHandler = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setRoleName(event.target.value);
      dispatch(setUserRoleData({ name: event.target.value }));
    },
    [dispatch],
  );

  if (!id) {
    return null;
  }

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitleClosable title="Duplicate Role" onClose={onClose} />

      <DialogContent
        sx={{
          color: "text.secondary",
        }}
        dividers
      >
        <TextField
          autoFocus
          fullWidth
          type="text"
          value={roleName}
          size="small"
          variant="outlined"
          onChange={nameChangeHandler}
          placeholder="Role Name"
          label="Role Name"
        />
      </DialogContent>
      <DialogActions>
        <Button color="tertiary">Cancel</Button>

        <LoadingButton
          variant="contained"
          color="primary"
          loading={loading}
          onClick={duplicateRoleHandler}
        >
          Duplicate
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
