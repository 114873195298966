import { createAsyncThunk } from "@reduxjs/toolkit";
import { Layouts } from "react-grid-layout";
import { getDashboardByIdThunk } from "./getDashboardByIdThunk";
import { currentDashboardLayoutSelector } from "../../selectors/dashboard/currentDashboardLayoutSelector";
import { RootState } from "../../../store";
import { cloudChiprApi } from "../../../../services/cloudchipr.api";
import { updateDashboardFixedCacheKey } from "../../../../components/pages/dashboard/utils/constants/fixedCacheKeys";
import { isLayoutsEqual } from "../../utils/helpers/isLayoutsEqual";

type UpdateDashboardLayoutByIdThunkArgs = {
  dashboardId: string;
  newLayout: Layouts;
};

export const updateDashboardByIdLayoutThunk = createAsyncThunk(
  "dashboards/updateDashboardByIdLayout",
  async (
    { newLayout, dashboardId }: UpdateDashboardLayoutByIdThunkArgs,
    { dispatch, getState },
  ) => {
    const state = getState() as RootState;
    const { patchUsersMeOrganisationsCurrentDashboardsByDashboardId } =
      cloudChiprApi.endpoints;

    const currentLayouts = currentDashboardLayoutSelector(state);

    const equal = isLayoutsEqual(currentLayouts, newLayout);

    if (equal) {
      return;
    }

    await dispatch(
      patchUsersMeOrganisationsCurrentDashboardsByDashboardId.initiate(
        {
          dashboardId,
          body: {
            name: null,
            layout: newLayout,
          },
        },
        { fixedCacheKey: updateDashboardFixedCacheKey },
      ),
    ).unwrap();

    await dispatch(getDashboardByIdThunk(dashboardId));
  },
);
