import { Label } from "recharts";

interface LoadingLabelArgs {
  loading: boolean;
  empty: boolean;
  emptyText?: string;
}
export const getLoadingLabel = ({
  empty,
  loading,
  emptyText,
}: LoadingLabelArgs) => {
  if (!loading && !empty) {
    return null;
  }

  const loadingLabel = loading ? "Loading..." : null;
  const emptyLabel = empty
    ? emptyText ?? "No data by your selected parameters..."
    : null;

  const value = loadingLabel ?? emptyLabel ?? "";

  return <Label value={value} fontSize={24} offset={100} position="top" />;
};
