import { FC, Fragment } from "react";
import { OffHoursScheduleContent } from "./schedule-section/OffHoursScheduleContent";
import { OffHoursNotificationsContent } from "./notifications-content/OffHoursNotificationsContent";
import { AutomationSectionCard } from "../../../common/components/form/helper-components/AutomationSectionCard";
import { AutomationEnvironmentContent } from "../../../common/components/form/environment-content/AutomationEnvironmentContent";
import { AutomationResourcesContent } from "../../../common/components/form/resources-content/AutomationResourcesContent";

export const OffHoursFormSetup: FC = () => {
  return (
    <Fragment>
      <AutomationSectionCard title="Set Schedule" icon="schedule">
        <OffHoursScheduleContent />
      </AutomationSectionCard>

      <AutomationSectionCard
        title="Select Environment"
        icon="environment"
        sectionName="environment"
      >
        <AutomationEnvironmentContent />
      </AutomationSectionCard>

      <AutomationSectionCard
        title="Select Services"
        icon="services"
        sectionName="filter"
      >
        <AutomationResourcesContent />
      </AutomationSectionCard>

      <AutomationSectionCard
        title="Set Notification"
        icon="notification"
        sectionName="notification"
      >
        <OffHoursNotificationsContent />
      </AutomationSectionCard>
    </Fragment>
  );
};
