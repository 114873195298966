import { Box, Chip, ChipProps, Tooltip, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import { UsersListActions } from "./UsersListActions";
import { ColumnSetupType } from "../../../../../../storybook/data-grid/utils/types/types";
import {
  User,
  UserRole,
  UserStatus,
} from "../../../../../../services/cloudchipr.api";
import {
  userRoleDescriptions,
  userRoleLabels,
} from "../utils/constants/common";
import { capitalizeString } from "../../../../../../utils/helpers/capitalizeString";

export const usersTableColumns: ColumnSetupType<User>[] = [
  {
    accessorKey: "email",
    header: "Email",
    cell: (cell) => {
      const email = cell.getValue() as string;

      return (
        <Typography variant="body1" sx={{ whiteSpace: "nowrap" }}>
          {email}
        </Typography>
      );
    },
    meta: { alwaysShowSortIcon: true },
  },
  {
    accessorKey: "name",
    header: "Name",
    cell: (cell) => {
      const name = cell.getValue() as string;

      return (
        <Typography variant="body1" sx={{ whiteSpace: "nowrap" }}>
          {name || "-"}
        </Typography>
      );
    },
    meta: { alwaysShowSortIcon: true },
  },
  {
    accessorKey: "role",
    header: "Role",
    cell: (cell) => {
      const role = cell.getValue() as UserRole;

      return (
        <Tooltip
          arrow
          placement="top"
          title={userRoleDescriptions.get(role) ?? ""}
        >
          <Typography
            variant="body1"
            component="span"
            sx={{
              whiteSpace: "nowrap",
              textDecoration: "underline",
              textDecorationStyle: "dashed",
              textUnderlineOffset: 5,
              textDecorationColor: grey["A400"],
            }}
          >
            {userRoleLabels.get(role) ?? "-"}
          </Typography>
        </Tooltip>
      );
    },
    meta: { alwaysShowSortIcon: true },
  },
  {
    accessorKey: "status",
    header: "Status",
    cell: (cell) => {
      const status = cell.getValue() as UserStatus;

      let statusColor: ChipProps["color"] =
        status === "pending" ? "warning" : "error";

      if (status === "active") {
        statusColor = "success";
      }

      return (
        <Chip
          label={capitalizeString(status)}
          variant="outlined"
          color={statusColor}
        />
      );
    },
    meta: { alwaysShowSortIcon: true },
  },
  {
    id: "actions",
    size: 40,
    header: "",
    cell: (cell) => {
      const user = cell.row.original;

      if (user?.role !== "owner" && user?.id && user?.email && user?.role) {
        return (
          <UsersListActions
            userId={user.id}
            email={user.email}
            status={user.status}
            role={user.role}
          />
        );
      }

      return <Box pt={5} />;
    },
    meta: { alwaysShowSortIcon: true },
  },
];
