import { FC } from "react";
import { Typography } from "@mui/material";
import { ExampleTypography } from "./ExampleTypography";
import { useAppSelector } from "../../../../../../../store/hooks";
import { reportsDataPropertyByKeySelector } from "../../../../../../../store/reports/selector/reportsDataPropertyByKeySelector";

export const ReportsCustomMessageBody: FC = () => {
  const source = useAppSelector(
    reportsDataPropertyByKeySelector("source_type"),
  );

  return (
    <Typography variant="body1" lineHeight={1.5}>
      Hi, here is the Cloudchipr report of{" "}
      <ExampleTypography>{`${source} name`}</ExampleTypography> {source} for{" "}
      <ExampleTypography>date</ExampleTypography>
      <br />
      The {source} in the attached file may need your attention. Please review
      and take necessary actions.
      <br />
      {source === "dashboard" ? "Dashboard" : "Resource Explorer"} -{" "}
      <ExampleTypography>{`${source} name`}</ExampleTypography>
      <br />
      Report created by - <ExampleTypography>user name</ExampleTypography>
    </Typography>
  );
};
