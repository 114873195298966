import { FC, Fragment, memo } from "react";
import { FormikHandlers } from "formik";
import {
  AutocompleteFilterBody,
  AutocompleteFilterBodyProps,
} from "../AutocompleteFilterBody";
import {
  FilterItem,
  Operator,
  useGetUsersMeRdsStorageTypesQuery,
} from "../../../../../../../../services/cloudchipr.api";
import { FilterItemOperatorType } from "../../../FilterItemOperatorType";

interface StorageTypeFilterProps extends AutocompleteFilterBodyProps {
  filter: FilterItem;
  operators?: Operator[];
  onChange: FormikHandlers["handleChange"];
}

export const StorageTypeFilter: FC<StorageTypeFilterProps> = memo(
  ({ operators, filter, onChange, ...props }) => {
    const { data, isLoading } = useGetUsersMeRdsStorageTypesQuery();

    return (
      <Fragment>
        <FilterItemOperatorType
          onChange={onChange}
          options={operators}
          value={filter.operator || "in"}
        />

        <AutocompleteFilterBody
          {...props}
          options={data ?? []}
          isLoading={isLoading}
          label="Storage Type"
          selectedValue={Array.isArray(filter?.value) ? filter.value : []}
        />
      </Fragment>
    );
  },
);
