import { FC } from "react";
import { Stack } from "@mui/material";
import { OverviewEffectiveSavingsRate } from "./components/OverviewEffectiveSavingsRate";
import { CommitmentsOverviewMetadataLeftSide } from "./components/CommitmentsOverviewMetadataLeftSide";
import { CommitmentsOverviewMetadataRightSide } from "./components/CommitmentsOverviewMetadataRightSide";

export const CommitmentsOverviewMetadata: FC = () => {
  return (
    <Stack
      spacing={1}
      direction="row"
      flexWrap="wrap"
      justifyContent="space-between"
    >
      <Stack flex={1}>
        <CommitmentsOverviewMetadataLeftSide />
      </Stack>
      <Stack flex={1}>
        <OverviewEffectiveSavingsRate />
      </Stack>
      <Stack flex={1}>
        <CommitmentsOverviewMetadataRightSide />
      </Stack>
    </Stack>
  );
};
