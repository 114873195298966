import { formatDateData } from "./formatDateData";
import { Dates } from "../../../../../../services/cloudchipr.api";

export const formatPreviousDateData = (
  date?: Dates,
  quarterStartMonth?: number,
) => {
  if (!date) {
    return;
  }

  const formattedDate = formatDateData(date, quarterStartMonth);

  let label = formattedDate?.label
    ?.replace("Last ", "preceding ")
    ?.replace("Past ", "preceding ");

  if (date?.label === "month_to_date") {
    label = "the same period in last month";
  } else if (date?.label === "quarter_to_date") {
    label = "the same period in last quarter";
  }

  return { label: label, range: `${formattedDate?.dateRange}` };
};
