import { FC, Fragment } from "react";
import { Stack } from "@mui/material";
import { FiltersDisabledViewGroupList } from "./FiltersDisabledViewGroupList";
import { FiltersDisabledViewListItem } from "./FiltersDisabledViewListItem";
import { FiltersDisabledViewFilterOperator } from "./FiltersDisabledViewFilterOperator";
import { isFilterGroup } from "../filters-selection/utils/types/types";
import { AccountResourceFilter2 } from "../../../../../services/cloudchipr.api";

interface FiltersDisabledViewProps {
  filter: AccountResourceFilter2;
}

export const FiltersDisabledView: FC<FiltersDisabledViewProps> = ({
  filter,
}) => {
  let indexOfOperator = 0;

  return (
    <Stack gap={1} direction="row" flexWrap="wrap" alignItems="center">
      {filter.filter_groups.map((filterGroup, index) => {
        if (isFilterGroup(filterGroup)) {
          indexOfOperator++;

          return (
            <Stack
              direction="row"
              spacing={2}
              width="100%"
              alignItems="start"
              key={`${indexOfOperator}_group`}
            >
              <FiltersDisabledViewFilterOperator
                operator={filter.operators.at(0)}
                type={index === 0 ? "text" : "button"}
              />

              <FiltersDisabledViewGroupList
                filterItems={filter.filter_items}
                filterGroup={filterGroup}
                operator={filter.operators[indexOfOperator]}
              />
            </Stack>
          );
        }

        return (
          <Fragment key={filterGroup}>
            <FiltersDisabledViewFilterOperator
              operator={filter.operators.at(0)}
              type={index === 0 ? "text" : "button"}
            />

            <FiltersDisabledViewListItem
              filter={filter.filter_items[filterGroup]}
            />
          </Fragment>
        );
      })}
    </Stack>
  );
};
