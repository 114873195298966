import { FC, Fragment, useMemo } from "react";
import { Grid } from "@mui/material";
import { OffHoursPreviewNameRow } from "./OffHoursPreviewNameRow";
import { SchedulePreviewSectionTitle } from "../../../../../automations/common/components/resources-preview-old/preview/SchedulePreviewSectionTitle";
import { SchedulePreviewTable } from "../../../../../automations/common/components/resources-preview-old/preview/SchedulePreviewTable";
import { AutomationAccountsPreview } from "../../../../../automations/common/components/preview/AutomationAccountsPreview";
import { AutomationRegionsPreview } from "../../../../../automations/common/components/preview/AutomationRegionsPreview";
import { ProviderType } from "../../../../../../../services/cloudchipr.api";

interface SchedulePreviewEnvironmentProps {
  name: string;
  provider: ProviderType;
  accountIds: string[];
  regions: string[];
  onFieldChange(key: string, value: any): void;
}

export const OffHoursPreviewEnvironment: FC<
  SchedulePreviewEnvironmentProps
> = ({ name, accountIds, regions, provider, onFieldChange }) => {
  const data = useMemo(() => {
    return [
      {
        title: "Cloud Provider",
        label: provider?.toUpperCase() || "",
      },
      {
        title: "Cloud Account/s",
        label: (
          <AutomationAccountsPreview
            accountIds={accountIds}
            provider={provider}
          />
        ),
      },
      {
        title: "Region/s",
        label: (
          <AutomationRegionsPreview regions={regions} provider={provider} />
        ),
      },
    ];
  }, [provider, regions, accountIds]);

  return (
    <Fragment>
      <SchedulePreviewSectionTitle title="Selected Environment" />

      <Grid container>
        <Grid item xs={12} md={12}>
          <SchedulePreviewTable data={data}>
            <OffHoursPreviewNameRow
              title="Name"
              label={name}
              onFieldChange={onFieldChange}
            />
          </SchedulePreviewTable>
        </Grid>
      </Grid>
    </Fragment>
  );
};
