import { FC, useMemo } from "react";
import { Grid } from "@mui/material";
import { SubscriptionPlan } from "./components/SubscriptionPlan";
import { Plan } from "../../../../../../services/cloudchipr.api";
import { useAppSelector } from "../../../../../../store/hooks";
import { currentSubscriptionSelector } from "../../../../../../store/subscriptions/subscriptionsSelectors";

export type SubscriptionPlanActionType = "Upgrade" | "Downgrade" | "Change";

interface SubscriptionPlansProps {
  isYearly: boolean;
  plans: (Plan | undefined)[];
}

export const SubscriptionPlans: FC<SubscriptionPlansProps> = ({
  isYearly,
  plans,
}) => {
  const current = useAppSelector(currentSubscriptionSelector);

  const plansOrder = useMemo(() => {
    return plans.map((plan) => plan?.name_key);
  }, [plans]);

  return (
    <Grid container spacing={2}>
      {plans.map((plan) => {
        if (!plan) {
          return null;
        }

        const indexOfCurrent = plansOrder.indexOf(current?.plan?.name_key);
        const indexOfPlan = plansOrder.indexOf(plan.name_key);

        let action: SubscriptionPlanActionType =
          indexOfCurrent < indexOfPlan ? "Upgrade" : "Change";
        if (indexOfCurrent > indexOfPlan) {
          action = "Downgrade";
        }

        return (
          <Grid item xs={6} lg={3} key={plan.id} display="flex">
            <SubscriptionPlan plan={plan} isYearly={isYearly} action={action} />
          </Grid>
        );
      })}
    </Grid>
  );
};
