import {
  OrganisationDailyOffHoursRecommendation,
  OrganisationWeeklyOffHoursRecommendation,
} from "../../../../../../../services/cloudchipr.api";

export const isWeeklyOffHoursRecommendation = (
  recommendation:
    | OrganisationWeeklyOffHoursRecommendation
    | OrganisationDailyOffHoursRecommendation,
): recommendation is OrganisationWeeklyOffHoursRecommendation => {
  return "from_day" in recommendation && "to_day" in recommendation;
};
