import { FC } from "react";
import { Chip, Stack, Typography } from "@mui/material";

interface AutomationNotificationsEmailsProps {
  label: string;
  emails?: string[] | null;
}

export const AutomationNotificationsEmails: FC<
  AutomationNotificationsEmailsProps
> = ({ emails, label }) => {
  if (!emails?.length) {
    return null;
  }

  return (
    <Stack direction="row" alignItems="center" spacing={1}>
      <Typography variant="body2">{label}</Typography>

      <Stack direction="row" gap={1} flexWrap="wrap">
        {emails.map((email) => {
          return (
            <Chip key={email} label={email} size="small" variant="outlined" />
          );
        })}
      </Stack>
    </Stack>
  );
};
