import { resourceExplorerViewsDataSelector } from "./resourceExplorerViewsDataSelector";
import { RootState } from "../../../store";

export const defaultResourceExplorerViewIdSelector = (
  state: RootState,
): string | undefined => {
  const resourceExplorerViews = resourceExplorerViewsDataSelector(state);

  return resourceExplorerViews.find((item) => item.is_default)?.id;
};
