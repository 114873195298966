import { createAsyncThunk } from "@reduxjs/toolkit";
import moment from "moment";
import { RootState } from "../../store";
import { formatDate } from "../../../utils/helpers/date-time/datetime-format";
import {
  setAutomationData,
  setWorkflowSpecificData,
  setOffHoursSpecificData,
  setAutomationInitialized,
} from "../automationsSlice";
import {
  automationsInitialData,
  offHoursInitialData,
  workflowInitialData,
} from "../utils/constants/common";
import { AutomationType } from "../utils/types/common";
import { providersRegionsSelector } from "../selectros/common/helpers-data/regions/providersRegionsSelector";
import { connectedProvidersSelector } from "../../common/selectors/connectedProvidersSelector";

export const initiateEmptyAutomationThunk = createAsyncThunk(
  "automation/initiateEmptyAutomation",
  async (automationType: AutomationType, { dispatch, getState }) => {
    const state = getState() as RootState;

    const connectedProviders = connectedProvidersSelector(state);
    const firstProvider = connectedProviders?.at(0);

    let data = automationsInitialData;

    if (firstProvider) {
      const providerRegions = providersRegionsSelector(state);

      data = {
        ...data,

        name: `untitled-${formatDate(moment(), {
          type: "dashSeparated",
        })}`,

        provider: firstProvider,
        regions: providerRegions[firstProvider].map((region) => region.key),
      };
    }

    dispatch(setAutomationData(data));

    if (automationType === "workflow") {
      dispatch(setWorkflowSpecificData(workflowInitialData));
    } else if (automationType === "offHours") {
      dispatch(setOffHoursSpecificData(offHoursInitialData));
    }

    dispatch(setAutomationInitialized(true));
  },
);
