import { FC, Fragment, useCallback, useMemo } from "react";
import {
  ResponsiveContainer,
  BarChart as RechartsBarChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ReferenceLine,
  ReferenceArea,
  YAxisProps,
} from "recharts";
import { useWillUnmount } from "rooks";
import { useTheme } from "@mui/material";
import { grey } from "@mui/material/colors";
import { barChartBars } from "./barChartBars";
import {
  CustomizedYAxisTick,
  YAxisFormatter,
} from "../../common/CustomizedYAxisTick";
import {
  CustomizedXAxisTick,
  XAxisTickProps,
} from "../../common/CustomizedXAxisTick";
import {
  ChartsTooltipContent,
  ChartsTooltipProps,
} from "../../common/ChartsTooltipContent";
import { LegendContent } from "../../common/legend/LegentContent";
import { ZoomWrapper } from "../../common/ZoomWrapper";
import { getLoadingLabel } from "../../common/LoadingLabel";
import { ChartsTooltipZoom } from "../../common/ChartsTooltipZoom";
import { chartDataPointKey } from "../../../utils/constants/constants";
import { useMultiTypeChartContext } from "../../../MultiTypeChartProvider";
import { calculateLabelInterval } from "../../../utils/helpers/helpers";
import { ReferenceLineType } from "../../../utils/types/types";
import {
  useChartZoom,
  ZoomChangeFunction,
} from "../../../utils/hooks/useChartZoom.hook";
import { getYAxisMaxValue } from "../../../utils/helpers/getYAxisMaxValue";
import { hasZeroReferenceLine } from "../../../utils/helpers/hasZeroReferenceLine";

interface BarChartProps {
  zoom: boolean;
  highlight: boolean;
  selectable: boolean;
  stack?: boolean;
  loading: boolean;
  yAxisFormatter?: YAxisFormatter;
  xAxisTickProps?: XAxisTickProps;
  tooltipProps?: ChartsTooltipProps;
  referenceLines?: ReferenceLineType[];
  legendItemLabelFormatter?(key: string): string;
  emptyText?: string;
  yAxisProps?: YAxisProps;
  onZoomChange?: ZoomChangeFunction;
}

export const HorizontalBarChart: FC<BarChartProps> = ({
  zoom,
  stack,
  highlight,
  yAxisFormatter,
  xAxisTickProps,
  selectable,
  tooltipProps,
  referenceLines,
  loading,
  emptyText,
  legendItemLabelFormatter,
  yAxisProps,
  onZoomChange,
}) => {
  const theme = useTheme();
  const { data, keys, colors, hover, chartType } = useMultiTypeChartContext();
  const {
    referenceAreaProps,
    zoomResetHandler,
    handlers,
    zoomed,
    zoomedData,
    zooming,
  } = useChartZoom(data, zoom, onZoomChange);

  useWillUnmount(() => {
    zoomResetHandler();
  });

  const { maxDataValue: yAxisMaxData, minDataValue: yAxisMinData } = useMemo(
    () => getYAxisMaxValue(zoomedData, chartType?.type, referenceLines),
    [zoomedData, chartType?.type, referenceLines],
  );

  const onMouseEnter = useCallback(
    (payload: any) => {
      hover.setKey(payload?.tooltipPayload?.[0]?.unit);
    },
    [hover],
  );

  const onMouseLeave = useCallback(() => {
    hover.setKey("");
  }, [hover]);

  const isEmpty = !data.length;

  if (!data) {
    return null;
  }

  return (
    <ZoomWrapper enabled={zoom} zoomed={zoomed} onReset={zoomResetHandler}>
      <ResponsiveContainer width="100%" height={300}>
        <RechartsBarChart
          barGap={0}
          data={zoomedData}
          barSize={30}
          height={335}
          margin={{ right: 10 }}
          stackOffset="sign"
          style={{ cursor: "crosshair" }}
          layout="vertical"
          {...handlers}
        >
          <CartesianGrid
            vertical={false}
            strokeOpacity={0.5}
            strokeDasharray="3 3"
          />

          <XAxis
            type="number"
            height={xAxisTickProps?.parentHeight}
            padding={xAxisTickProps?.parentPadding}
            strokeOpacity={0.2}
            strokeDasharray="3 3"
            tick={
              <CustomizedYAxisTick
                empty={isEmpty || loading}
                formatter={yAxisFormatter}
              />
            }
            interval={calculateLabelInterval(zoomedData.length)}
          >
            {getLoadingLabel({
              empty: isEmpty,
              loading,
              emptyText,
            })}
          </XAxis>

          <YAxis
            dataKey={chartDataPointKey}
            type="category"
            domain={[yAxisMinData, yAxisMaxData]}
            strokeOpacity={0.2}
            strokeDasharray="3 3"
            tick={
              <CustomizedXAxisTick
                textY={0}
                chartType="horizontal_stack_bar"
                empty={isEmpty}
                xAxisTickProps={xAxisTickProps}
              />
            }
            width={120}
            {...yAxisProps}
          />

          <Tooltip
            cursor={{ fill: isEmpty ? "transparent" : grey[100] }}
            wrapperStyle={{ zIndex: theme.zIndex.tooltip }}
            content={
              zooming ? (
                <ChartsTooltipZoom
                  x1={referenceAreaProps.x1}
                  x2={referenceAreaProps.x2}
                />
              ) : (
                <ChartsTooltipContent
                  hoveredKey={hover.key}
                  referenceLines={referenceLines}
                  {...tooltipProps}
                />
              )
            }
          />

          {hasZeroReferenceLine(data) && (
            <ReferenceLine
              y={0}
              stroke={grey["300"]}
              label={{
                value: "0",
                fontSize: 14,
                fill: grey[400],
                position: { x: -5, y: 5 },
                style: { fontWeight: 500 },
              }}
            />
          )}

          {keys.visibleKeys && (
            <Fragment>
              {barChartBars({
                keys: keys.visibleKeys,
                highlighted: hover.key,
                onMouseEnter,
                onMouseLeave,
                stack,
                colors,
              })}
            </Fragment>
          )}

          {referenceLines?.map((props) => (
            <ReferenceLine {...props} key={props.uniqueId} />
          ))}

          {zoom && (
            <ReferenceArea
              {...referenceAreaProps}
              fill={grey[100]}
              y2={yAxisMaxData}
              y1={yAxisMinData}
              strokeOpacity={0.3}
            />
          )}
        </RechartsBarChart>
      </ResponsiveContainer>

      {keys.visibleKeys && (
        <Legend
          wrapperStyle={{ position: "initial", alignSelf: "center" }}
          content={
            <LegendContent
              keys={keys.allKeys}
              colors={colors}
              selectable={selectable}
              referenceLines={referenceLines}
              visibleKeys={keys.visibleKeys}
              highlightable={highlight}
              setVisibleKeys={keys.setVisibleKeys}
              legendItemLabelFormatter={legendItemLabelFormatter}
            />
          }
        />
      )}
    </ZoomWrapper>
  );
};
