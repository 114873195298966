import { FC } from "react";
import { Box, Typography } from "@mui/material";
import { AWSConsoleLink } from "./AWSConsoleLink";

export const TabPanelAWSConsole: FC = () => {
  return (
    <Box>
      <Typography variant="body2" fontWeight="medium" color="text.secondary">
        Click the button below to go to AWS Web Console. Once you are there,
        please log in, verify the pre-populated values in the CloudFormation
        template, and create the stack. After the stack is created, please
        return to this tab to follow the resource collection process.
      </Typography>
      <AWSConsoleLink />
    </Box>
  );
};
