import { FC, ReactNode } from "react";
import { Stack, Typography } from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";

interface ProviderStatusCellProps {
  data?: string;
}

export const ProviderStatusCell: FC<ProviderStatusCellProps> = ({ data }) => {
  return (
    <Stack direction="row" alignItems="center" spacing={1}>
      {(data && availableOptions.get(data)?.icon) ?? null}
      <Typography variant="body2">
        {(data && availableOptions.get(data)?.title) ?? data}
      </Typography>
    </Stack>
  );
};

const availableOptions = new Map<
  string,
  {
    title: string;
    icon: ReactNode;
  }
>([
  [
    "running",
    {
      title: "Running",
      icon: <CheckCircleOutlineIcon color="success" fontSize="small" />,
    },
  ],
  [
    "stopped",
    {
      title: "Stopped",
      icon: <RemoveCircleOutlineIcon color="action" fontSize="small" />,
    },
  ],
  [
    "deallocated",
    {
      title: "Stopped (deallocated)",
      icon: <RemoveCircleOutlineIcon color="action" fontSize="small" />,
    },
  ],
]);
