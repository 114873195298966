import { createAsyncThunk } from "@reduxjs/toolkit";
import { enqueueSnackbar } from "notistack";
import { RootState } from "../../store";
import { cloudChiprApi } from "../../../services/cloudchipr.api";
import { utilizationAndCoverageDetailsPayloadSelector } from "../selectors/coverage-and-utilization/details-drawer/utilizationAndCoverageDetailsPayloadSelector";

export const getUtilizationAndCoverageDetailsThunk = createAsyncThunk(
  "commitments/getUtilizationAndCoverageDrawerDetails",
  async (_: void, { dispatch, getState }) => {
    const state = getState() as RootState;

    const { getUsersMeOrganisationsCurrentSavingsPlansData } =
      cloudChiprApi.endpoints;

    const payload = utilizationAndCoverageDetailsPayloadSelector(state);

    if (!payload) {
      return;
    }

    try {
      await dispatch(
        getUsersMeOrganisationsCurrentSavingsPlansData.initiate(payload),
      ).unwrap();
    } catch (e) {
      // @ts-expect-error todo: api fix
      const errMessage = e?.data?.message || "Something went wrong";
      enqueueSnackbar(errMessage, {
        variant: "snackbarAlert",
        AlertSnackBarProps: { severity: "error" },
      });

      return null;
    }
  },
);
