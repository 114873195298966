import { RootState } from "../../store";
import { AccountAttemptStatusDetailsAzureStarted } from "../../../services/cloudchipr.api";

export const azureTenantIdSelector = (
  state: RootState,
): string | null | undefined =>
  (
    state.accountSetup.accountAttempt?.status_details
      ?.started as AccountAttemptStatusDetailsAzureStarted
  )?.tenant_id;
