import { ColDef } from "@ag-grid-community/core";
import { SavingsOpportunityActionTypeCellRenderer } from "./cell-renderers/SavingsOpportunityActionTypeCellRenderer";
import { SavingsOpportunityServiceCellRenderer } from "./cell-renderers/SavingsOpportunityServiceCellRenderer";
import { SavingsOpportunityImplementationEffortCellRenderer } from "./cell-renderers/SavingsOpportunityImplementationEffortCellRenderer";
import { SavingsOpportunityAccountCellRenderer } from "./cell-renderers/SavingsOpportunityAccountCellRenderer";
import { SavingsOpportunityTagsCellRenderer } from "./cell-renderers/SavingsOpportunityTagsCellRenderer";
import { SavingsOpportunityMonthlySavingsCellRenderer } from "./cell-renderers/SavingsOpportunityMonthlySavingsCellRenderer";
import { getSimpleTypographyCellRenderer } from "./cell-renderers/getSimpleTypographyCellRenderer";
import { getMoneyTypographyCellRenderer } from "./cell-renderers/getMoneyTypographyCellRenderer";
import { SavingsOpportunityBasedOnPastCellRenderer } from "./cell-renderers/SavingsOpportunityBasedOnPastCellRenderer";
import { SavingsOpportunity } from "../../../../services/cloudchipr.api";
import { getResourceTypeName } from "../../../../utils/helpers/resources/getResourceTypeName";

export const savingsOpportunitiesTableColumns: ColDef<SavingsOpportunity>[] = [
  {
    field: "action_type",
    minWidth: 140,
    unSortIcon: true,
    enableRowGroup: true,
    headerName: "Action Type",
    cellRenderer: SavingsOpportunityActionTypeCellRenderer,
  },
  {
    field: "description",
    minWidth: 150,
    unSortIcon: true,
    headerName: "Description",
    cellRenderer: getSimpleTypographyCellRenderer("description"),
  },
  {
    field: "service",
    minWidth: 130,
    unSortIcon: true,
    headerName: "Service",
    enableRowGroup: true,
    cellRenderer: SavingsOpportunityServiceCellRenderer,
    getQuickFilterText: ({ data }) => {
      return getResourceTypeName(data.service, {
        type: "long",
        singular: true,
      });
    },
  },
  {
    field: "resource_type",
    minWidth: 130,
    unSortIcon: true,
    enableRowGroup: true,
    headerName: "Resource Type",
    cellRenderer: getSimpleTypographyCellRenderer("resource_type"),
  },
  {
    field: "resource_id",
    unSortIcon: true,
    enableRowGroup: true,
    minWidth: 120,
    headerName: "Resource ID",
    cellRenderer: getSimpleTypographyCellRenderer("resource_id"),
  },
  {
    field: "implementation_effort",
    minWidth: 100,
    unSortIcon: true,
    enableRowGroup: true,
    headerName: "Implementation Effort",
    cellRenderer: SavingsOpportunityImplementationEffortCellRenderer,
  },
  {
    field: "based_on_past",
    minWidth: 100,
    unSortIcon: true,
    enableRowGroup: true,
    headerName: "Based On Past",
    cellRenderer: SavingsOpportunityBasedOnPastCellRenderer,
  },
  {
    field: "region",
    minWidth: 110,
    unSortIcon: true,
    enableRowGroup: true,
    headerName: "Region",
    cellRenderer: getSimpleTypographyCellRenderer("region"),
  },
  {
    field: "account",
    minWidth: 150,
    unSortIcon: true,
    enableRowGroup: true,
    headerName: "Account",
    cellRenderer: SavingsOpportunityAccountCellRenderer,
    getQuickFilterText: ({ data }) => data.account.provider_account_name,
    comparator: (valueA, valueB, nodeA, nodeB) => {
      const current = nodeA?.data?.account?.provider_account_name;
      const next = nodeB?.data?.account?.provider_account_name;

      if (!current) return 1;
      if (!next) return -1;

      return current.localeCompare(next);
    },
  },
  {
    field: "tags",
    minWidth: 120,
    unSortIcon: true,
    sortable: false,
    headerName: "Tags",
    cellRenderer: SavingsOpportunityTagsCellRenderer,
    getQuickFilterText: ({ data }) =>
      data.tags?.map((tag) => `${tag.key} ${tag.value}`).join(" ") ?? "",
  },

  {
    field: "current_monthly_price",
    minWidth: 100,
    unSortIcon: true,
    enableRowGroup: true,
    headerName: "Current Monthly Price",
    cellRenderer: getMoneyTypographyCellRenderer("current_monthly_price"),
  },
  {
    field: "estimated_monthly_price",
    minWidth: 100,
    unSortIcon: true,
    enableRowGroup: true,
    headerName: "Estimated Monthly Price",
    cellRenderer: getMoneyTypographyCellRenderer("estimated_monthly_price"),
  },
  {
    field: "monthly_savings",
    minWidth: 100,
    unSortIcon: true,
    resizable: false,
    suppressMovable: true,
    enableRowGroup: true,
    suppressColumnsToolPanel: true,
    pinned: "right",
    headerName: "Monthly Savings",
    cellRenderer: SavingsOpportunityMonthlySavingsCellRenderer,
  },

  {
    // column to fit rest place
    flex: 0.5,
    colId: "toFitEmptySpace",
    headerName: "",
    sortable: false,
    enableRowGroup: false,
    filter: false,
    cellDataType: false,
    resizable: false,
    unSortIcon: false,
    suppressMovable: true,
    suppressColumnsToolPanel: true,
    suppressHeaderMenuButton: true,
    suppressHeaderContextMenu: true,
  },
];
