import { resourceExplorerViewsHierarchyFoldersSelector } from "./resourceExplorerViewsHierarchyFoldersSelector";
import { RootState } from "../../../store";
import { NavigationItemsVisibilityType } from "../../../navigation/utils/types";
import { TreeItems } from "../../../../components/navigation/components/sortable-tree/utils/types";

export const resourceExplorerViewsHierarchyViewsByFolderIdSelector = (
  state: RootState,
  folderId: string,
  visibility: NavigationItemsVisibilityType,
): TreeItems | undefined => {
  const folders = resourceExplorerViewsHierarchyFoldersSelector(
    state,
    visibility,
  );

  return folders?.find((item) => item.id === folderId)?.items;
};
