import { TreeItem } from "../../../sortable-tree/utils/types";
import { Dashboard } from "../../../../../../services/cloudchipr.api";

export const getNextDashboardId = (
  currentId: string,
  dashboards: TreeItem[] | Dashboard[],
): string => {
  const index = dashboards.findIndex((item) => {
    return item.id === currentId;
  });

  return index !== dashboards.length - 1
    ? dashboards?.[index + 1]?.id
    : dashboards[0].id;
};
