import { FC, useCallback } from "react";
import { DropdownSelect } from "../../../../../../../common/select/dropdown-select/DropdownSelect";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../store/hooks";
import { setReportFilters } from "../../../../../../../../store/reports/reportsSlice";
import { reportsEnabledFilterValuesSelector } from "../../../../../../../../store/reports/selector/reportsEnabledFilterValuesSelector";

export const ReportsEnabledFilter: FC = () => {
  const dispatch = useAppDispatch();
  const initialEnabled = useAppSelector(reportsEnabledFilterValuesSelector);

  const optionsCount = options.length;

  const changeHandler = useCallback(
    (newFilters: string[]) => {
      dispatch(
        setReportFilters({
          key: "enabled",
          values: newFilters.length === optionsCount ? [] : newFilters,
        }),
      );
    },
    [dispatch, optionsCount],
  );

  return (
    <DropdownSelect
      initialSelectedValues={initialEnabled}
      showSearch={false}
      label="Enabled"
      options={options}
      submitHandlerOnClose={changeHandler}
    />
  );
};

const options = [
  {
    value: "active",
    hideOnly: true,
    label: "Yes",
  },
  {
    value: "paused",
    hideOnly: true,
    label: "No",
  },
];
