import { createDraftSafeSelector } from "@reduxjs/toolkit";
import { utilizationAndCoverageDetailsCoverageDataSelector } from "./utilizationAndCoverageDetailsCoverageDataSelector";
import { utilizationAndCoverageCSVDataGenerator } from "../../../../utils/helpers/csv/utilizationAndCoverageCSVDataGenerator";
import { commitmentsDataViewTabSelector } from "../../../common/view-options/commitmentsDataViewTabSelector";
import { commitmentsDetailsDrawerCoverageGroupingSelector } from "../../../common/details-drawer/commitmentsDetailsDrawerCoverageGroupingSelector";

export const utilizationAndCoverageDetailsCSVDataSelector =
  createDraftSafeSelector(
    [
      utilizationAndCoverageDetailsCoverageDataSelector,
      commitmentsDataViewTabSelector,
      commitmentsDetailsDrawerCoverageGroupingSelector,
    ],
    (data, viewTab, grouping) => {
      return utilizationAndCoverageCSVDataGenerator({
        viewTab,
        data,
        grouping,
      });
    },
  );
