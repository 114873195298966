import { createAsyncThunk } from "@reduxjs/toolkit";
import { enqueueSnackbar } from "notistack";
import {
  cloudChiprApi,
  PatchUsersMeResourcesDailyOffHoursRecommendationsApiArg,
} from "../../../../../../services/cloudchipr.api";
import { RootState } from "../../../../../store";
import { recommendationDismissByResourceFixedCacheKey } from "../../../../utils/constants/fixedCacheKeys";
import { refetchDataAfterActionThunk } from "../../../refetchDataAfterActionThunk";
import { recommendationsDataGridFilteredDataSelector } from "../../../../selectors/off-hours/data/recommendationsDataGridFilteredDataSelector";
import { RecommendationDataType } from "../../../../../../components/pages/recommendations/components/off-hours-recommendations/utils/types/types";

export const dismissOffHoursRecommendationResourceThunk = createAsyncThunk(
  "recommendations/dismissOffHoursRecommendationResourceThunk",
  async (recommendationId: string, { dispatch, getState }) => {
    const state = getState() as RootState;

    const {
      patchUsersMeResourcesDailyOffHoursRecommendations,
      patchUsersMeResourcesWeeklyOffHoursRecommendations,
    } = cloudChiprApi.endpoints;

    const computeData = recommendationsDataGridFilteredDataSelector(
      state,
      "compute",
    );
    const dbData = recommendationsDataGridFilteredDataSelector(
      state,
      "database",
    );

    const computeRecommendation = computeData?.find(
      ({ id }) => id === recommendationId,
    ) as RecommendationDataType;

    const dbRecommendation = dbData?.find(
      ({ id }) => id === recommendationId,
    ) as RecommendationDataType;

    if (!computeRecommendation && !dbRecommendation) {
      return;
    }

    try {
      const payload: PatchUsersMeResourcesDailyOffHoursRecommendationsApiArg = {
        body: {
          resource_identifier:
            computeRecommendation?.resource_identifier ||
            dbRecommendation?.resource_identifier,
          account_id:
            computeRecommendation?.account?.id || dbRecommendation?.account?.id,
        },
      };

      await Promise.all([
        dispatch(
          patchUsersMeResourcesDailyOffHoursRecommendations.initiate(payload, {
            fixedCacheKey: recommendationDismissByResourceFixedCacheKey,
          }),
        ),
        dispatch(
          patchUsersMeResourcesWeeklyOffHoursRecommendations.initiate(payload, {
            fixedCacheKey: recommendationDismissByResourceFixedCacheKey,
          }),
        ),
      ]);

      await dispatch(
        refetchDataAfterActionThunk({
          dbDaily: !!dbRecommendation,
          dbWeekly: !!dbRecommendation,
          dbDismissedResources: !!dbRecommendation,

          computeDaly: !!computeRecommendation,
          computeWeekly: !!computeRecommendation,
          computeDismissedResources: !!computeRecommendation,
        }),
      );
    } catch (error: any) {
      enqueueSnackbar(error?.data?.message ?? "Something went wrong.", {
        variant: "snackbarAlert",
        AlertSnackBarProps: {
          severity: "error",
        },
      });
    }
  },
);
