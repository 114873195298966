import { FC } from "react";
import { Theme } from "@mui/material";
import { Row } from "@tanstack/react-table";
import { resourceExplorerAllViewsTableColumns } from "./components/resourceExplorerAllViewsTableColumns";
import { resourceExplorerAllViewsTableSubRow } from "./components/sub-row/ResourceExplorerAllViewsTableSubRow";
import { ReMixedVisibilitiesItemType } from "./utils/types/types";
import { HierarchyAllItemsTableToolbar } from "../../../common/hierarchy-all-items/components/HierarchyAllItemsTableToolbar";
import { useAppSelector } from "../../../../../store/hooks";
import { DataGrid } from "../../../../../storybook/data-grid/DataGrid";
import {
  RowExpandingConfig,
  ToolbarConfig,
} from "../../../../../storybook/data-grid/utils/types/prop-types";
import { DataGridProvider } from "../../../../../storybook/data-grid/DataGridProvider";
import { useAllItemsFiltersContext } from "../../../common/hierarchy-all-items/components/HierarchyAllItemsFiltersProvider";
import { resourceExplorerHierarchyVisibilitiesDataByFilters } from "../../../../../store/resource-explorer/selectors/views-hierarchy-visibility/resourceExplorerHierarchyMixedVisibilitiesDataByFiltersSelector";
import { isHierarchyFolder } from "../../../../../store/navigation/utils/helpers/isHierarchyFolder";
import { NoFilteredData } from "../../../../common/NoFilteredData";

export const ResourceExplorerAllViewsTable: FC = () => {
  const {
    filters: { createdBy, visibilities },
  } = useAllItemsFiltersContext();
  const data = useAppSelector((state) =>
    resourceExplorerHierarchyVisibilitiesDataByFilters(
      state,
      visibilities,
      createdBy,
    ),
  );

  if (!data) {
    return null;
  }

  return (
    <DataGridProvider>
      <DataGrid
        columnSorting
        globalFilter
        columnVisibility
        data={data}
        enableRowsVirtualization={data.length > 30}
        columns={resourceExplorerAllViewsTableColumns}
        toolbar={dataGridToolbar}
        styles={styles}
        rowExpanding={rowExpandingConfig}
        Footer={NoFilteredData}
      />
    </DataGridProvider>
  );
};

const rowExpandingConfig: RowExpandingConfig = {
  getCanRowExpand: (row: Row<ReMixedVisibilitiesItemType>) =>
    isHierarchyFolder(row.original),
  renderExpandedRowSubRow: resourceExplorerAllViewsTableSubRow,
  parentRowStyles: { "& > td": { p: 0 } },
};

const dataGridToolbar: ToolbarConfig = {
  renderToolbar: HierarchyAllItemsTableToolbar,
};

const styles = {
  table: {
    tableLayout: "fixed",
    "& .hover-class:hover": { bgcolor: "grey.100" },
  },
  tableHeaderRow: {
    top: 0,
    position: "sticky",
    bgcolor: "grey.100",
    zIndex: (theme: Theme) => theme.zIndex.appBar - 1,
    "& th": { py: 1.5, borderColor: "grey.200" },
    "& th:first-of-type": { px: 2 },
  },
};
