import { FC, Fragment, useCallback } from "react";
import { Popover, PopoverOrigin, Skeleton, Stack } from "@mui/material";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { AccountCardFilterTemplatePopoverContent } from "./AccountCardFilterTemplatePopoverContent";
import { useAppSelector } from "../../../../../../../store/hooks";
import { useMenuHook } from "../../../../../../../utils/hooks/useMenu.hook";
import { ProviderType } from "../../../../../../../services/cloudchipr.api";
import { TypographyWithTooltip } from "../../../../../../common/TypographyWithTooltip";
import { accountFilterTemplateNameSelector } from "../../../../../../../store/accounts/selectors/accountFilterTemplateNameSelector";
import { organisationCurrentFilterSetsLoadingSelector } from "../../../../../../../store/filters/selectors/current-filter-sets/organisationCurrentFilterSetsLoadingSelector";

interface AccountCardFilterTemplateNameProps {
  accountId: string;
  provider: ProviderType;
  filterSetId: string | null;
}

export const AccountCardFilterTemplateName: FC<
  AccountCardFilterTemplateNameProps
> = ({ accountId, provider, filterSetId }) => {
  const { anchor, open, openMenu, closeMenu } = useMenuHook();
  const loading = useAppSelector(organisationCurrentFilterSetsLoadingSelector);

  const name = useAppSelector((state) =>
    accountFilterTemplateNameSelector(state, {
      provider,
      filterSetId,
    }),
  );

  const filterClickHandler = useCallback(
    (event: any) => {
      openMenu(event);
      event.preventDefault();
      event.stopPropagation();
    },
    [openMenu],
  );

  const closeHandler = useCallback(
    (event: any) => {
      closeMenu();
      event.preventDefault();
      event.stopPropagation();
    },
    [closeMenu],
  );

  return (
    <Fragment>
      {loading && <Skeleton width="100%" />}
      <Stack
        direction="row"
        overflow="hidden"
        spacing={0.25}
        onClick={filterClickHandler}
        flex={1}
      >
        <FilterAltIcon color="primary" fontSize="small" />
        <TypographyWithTooltip
          title={name ?? ""}
          placement="top"
          color="primary.main"
          variant="subtitle2"
          fontWeight={400}
        />
      </Stack>
      <Popover
        open={open}
        anchorEl={anchor}
        onClose={closeHandler}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
        PaperProps={paperProps}
      >
        <AccountCardFilterTemplatePopoverContent
          accountId={accountId}
          provider={provider}
          onClose={closeMenu}
          filterSetId={filterSetId}
        />
      </Popover>
    </Fragment>
  );
};

const transformOrigin: PopoverOrigin = {
  vertical: "top",
  horizontal: "left",
};
const anchorOrigin: PopoverOrigin = {
  vertical: "bottom",
  horizontal: "left",
};

const paperProps = {
  style: { maxHeight: 400, width: 380 },
};
