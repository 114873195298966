import { FC, useCallback, useMemo } from "react";
import { Button } from "@mui/material";
import { FormikHelpers } from "formik/dist/types";
import { v4 as uuid } from "uuid";
import RotateLeftIcon from "@mui/icons-material/RotateLeft";
import { FiltersInitialValues } from "./utils/types/types";
import { isDeepEqual } from "../../../../../utils/is-deep-equal";
import { ResourceFilters } from "../../../../../services/cloudchipr.api";

interface FiltersResetButtonProps {
  defaultFilters: ResourceFilters;
  filters: FiltersInitialValues;
  setValues: FormikHelpers<FiltersInitialValues>["setValues"];
}

export const FiltersResetButton: FC<FiltersResetButtonProps> = ({
  setValues,
  filters,
  defaultFilters,
}) => {
  const isDefaultFiltersChanged = useMemo(() => {
    if (
      !filters ||
      !defaultFilters ||
      defaultFilters?.filter_items?.length !== filters?.filter_items?.length ||
      defaultFilters?.filter_groups?.length !==
        filters?.filter_groups?.length ||
      defaultFilters?.operators?.length !== filters?.operators?.length
    ) {
      return true;
    }

    const normalizedAppliedFilters = {
      ...filters,
      filter_items: filters.filter_items.map(({ id, ...filterItem }) => ({
        ...filterItem,
      })),
    };

    return !isDeepEqual(defaultFilters, normalizedAppliedFilters);
  }, [filters, defaultFilters]);

  const resetHandler = useCallback(() => {
    if (!defaultFilters) {
      return;
    }

    setValues({
      ...defaultFilters,
      filter_items: defaultFilters.filter_items.map((filterItem) => ({
        ...filterItem,
        id: uuid(),
      })),
    });
  }, [defaultFilters, setValues]);

  return (
    <Button
      size="small"
      sx={{ mt: 2, textDecoration: "underline" }}
      onClick={resetHandler}
      startIcon={<RotateLeftIcon />}
      disabled={!isDefaultFiltersChanged}
    >
      REVERT
    </Button>
  );
};
