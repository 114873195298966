import { FC, useCallback } from "react";
import { Button } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useAppDispatch } from "../../../../store/hooks";
import { toggleReportsOpenDrawer } from "../../../../store/reports/reportsSlice";
import { useAppAbility } from "../../../../services/permissions";

interface CreateReportButtonProps {
  icon?: boolean;
  applyOnClick?(): void;
}

export const CreateReportButton: FC<CreateReportButtonProps> = ({
  icon = true,
  applyOnClick,
}) => {
  const dispatch = useAppDispatch();
  const { cannot } = useAppAbility();
  const cannotView = cannot("create", "report");

  const toggleOpenDrawer = useCallback(() => {
    applyOnClick?.();
    dispatch(toggleReportsOpenDrawer());
  }, [dispatch, applyOnClick]);

  return (
    <Button
      variant="contained"
      color="primary"
      onClick={toggleOpenDrawer}
      disabled={cannotView}
      sx={{ textTransform: "none" }}
      startIcon={icon ? <AddIcon sx={{ mr: 1 }} fontSize="small" /> : undefined}
    >
      Subscribe
    </Button>
  );
};
