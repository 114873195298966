import { commitmentUtilizationSetupPropertyByKeySelector } from "./commitmentUtilizationSetupPropertyByKeySelector";
import { RootState } from "../../../../store";
import { commitmentUtilizationWidgetEditChangesExistSelector } from "../../widgets/commitment-utilization/setup/commitmentUtilizationWidgetEditChangesExistSelector";

export const commitmentUtilizationSubmitButtonDisabledSelector = (
  state: RootState,
) => {
  const editDisabledByChange =
    commitmentUtilizationWidgetEditChangesExistSelector(state);
  const providerOrganisationId =
    commitmentUtilizationSetupPropertyByKeySelector("providerOrganisationId")(
      state,
    );
  const name = commitmentUtilizationSetupPropertyByKeySelector("name")(state);
  const id = commitmentUtilizationSetupPropertyByKeySelector("id")(state);

  return (id && !editDisabledByChange) || !name || !providerOrganisationId;
};
