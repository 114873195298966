import { FC, Fragment } from "react";
import { Stack } from "@mui/material";
import { ReportsDrawerTimePicker } from "./ReportsDrawerTimePicker";
import { ReportsDrawerTimeZone } from "./ReportsDrawerTimeZone";
import { ReportsDrawerMonthlyTypeSelect } from "./ReportsDrawerMonthlyTypeSelect";
import { ReportsDrawerMonthDaySelect } from "./ReportsDrawerMonthDaySelect";
import { ReportsDrawerWeekDaySelect } from "./ReportsDrawerWeekDaySelect";
import { useAppSelector } from "../../../../../../../store/hooks";
import { reportsScheduleDataByKeySelector } from "../../../../../../../store/reports/selector/reportsScheduleDataByKeySelector";

export const ReportsDrawerMonthlySchedule: FC = () => {
  const frequency = useAppSelector(
    reportsScheduleDataByKeySelector("frequency"),
  );

  return (
    <Fragment>
      <Stack flex={1.5}>
        <ReportsDrawerMonthlyTypeSelect />
      </Stack>

      {frequency === "monthly_by_day" && (
        <Stack flex={1}>
          <ReportsDrawerMonthDaySelect />
        </Stack>
      )}

      {frequency === "monthly_by_weekday" && (
        <Stack flex={1.5}>
          <ReportsDrawerWeekDaySelect />
        </Stack>
      )}

      <Stack flex={1.5}>
        <ReportsDrawerTimePicker icon />
      </Stack>

      <Stack flex={3}>
        <ReportsDrawerTimeZone />
      </Stack>
    </Fragment>
  );
};
