import { costBreakdownWidgetByIdSelector } from "./costBreakdownWidgetByIdSelector";
import { RootState } from "../../../../../store";

export const costBreakdownWidgetViewIdByWidgetIdSelector = (
  state: RootState,
  widgetId?: string,
): string | undefined => {
  if (!widgetId) {
    return;
  }

  const widget = costBreakdownWidgetByIdSelector(state, widgetId);

  return widget?.view_id ?? undefined;
};
