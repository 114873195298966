import { FC, Fragment, useCallback } from "react";
import { AlertsUtilizationThresholdsSingleSelect } from "./AlertsUtilizationThresholdsSingleSelect";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../store/hooks";
import { UtilizationAlertsType } from "../../../../../utils/types/types";
import { alertsDataThresholdItemsSelector } from "../../../../../../../../store/alerts/selectors/common/data/alertsDataThresholdItemsSelector";
import { deleteUtilizationAlertThresholdByIndexThunk } from "../../../../../../../../store/alerts/thunks/utilization-alerts/deleteUtilizationAlertThresholdByIndexThunk";
import { changeUtilizationAlertThresholdAmountThunk } from "../../../../../../../../store/alerts/thunks/utilization-alerts/changeUtilizationAlertThresholdAmountThunk";

interface AlertsUtilizationThresholdsSelectsProps {
  utilizationAlertType: UtilizationAlertsType;
}

export const AlertsUtilizationThresholdsSelects: FC<
  AlertsUtilizationThresholdsSelectsProps
> = ({ utilizationAlertType }) => {
  const dispatch = useAppDispatch();
  const thresholds = useAppSelector(alertsDataThresholdItemsSelector);

  const removeThresholdClickHandler = useCallback(
    (index: number) => {
      dispatch(deleteUtilizationAlertThresholdByIndexThunk(index));
    },
    [dispatch],
  );

  const thresholdChangeHandler = useCallback(
    (amount: string, index: number) => {
      dispatch(
        changeUtilizationAlertThresholdAmountThunk({
          amount,
          index,
        }),
      );
    },
    [dispatch],
  );

  return (
    <Fragment>
      {thresholds.map((threshold, index) => (
        <AlertsUtilizationThresholdsSingleSelect
          index={index}
          onDelete={
            thresholds.length === 1 ? undefined : removeThresholdClickHandler
          }
          onChange={thresholdChangeHandler}
          key={(threshold.unit ?? "") + index}
          utilizationAlertType={utilizationAlertType}
          amount={threshold.amount ?? "1"}
        />
      ))}
    </Fragment>
  );
};
