import { FC, useMemo } from "react";
import { useDidMount } from "rooks";
import { AutomationPreviewTable } from "../../../../common/components/form/helper-components/AutomationPreviewTable";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../store/hooks";
import { workflowSchedulerSelector } from "../../../../../../../store/automations/selectros/workflow/workflowSchedulerSelector";
import { automationFrequencySelector } from "../../../../../../../store/automations/selectros/common/automationFrequencySelector";
import { generateWorkflowSchedulerText } from "../helpers/generateWorkflowSchedulerText";
import { automationStartDateSelector } from "../../../../../../../store/automations/selectros/common/fields/automationStartDateSelector";
import { formatDate } from "../../../../../../../utils/helpers/date-time/datetime-format";
import { automationEndDateSelector } from "../../../../../../../store/automations/selectros/common/fields/automationEndDateSelector";
import { automationTimeZoneSelector } from "../../../../../../../store/automations/selectros/common/fields/automationTimeZoneSelector";
import { getTimeZoneObject } from "../../../../../../../utils/helpers/date-time/timeZones";
import { fetchWorkflowNextRunsThunk } from "../../../../../../../store/automations/thunks/wokrflows/fetchWorkflowNextRunsThunk";
import { WorkflowNextRuns } from "../../form/schedule-section/WorkflowNextRuns";
import { AutomationSectionCard } from "../../../../common/components/form/helper-components/AutomationSectionCard";

export const WorkflowSchedulePreview: FC = () => {
  const dispatch = useAppDispatch();
  const scheduler = useAppSelector(workflowSchedulerSelector);
  const frequency = useAppSelector(automationFrequencySelector);
  const startDate = useAppSelector(automationStartDateSelector);
  const endDate = useAppSelector(automationEndDateSelector);
  const timeZone = useAppSelector(automationTimeZoneSelector);

  const rows = useMemo(() => {
    const timeZoneRow = {
      title: "Time Zone",
      label: getTimeZoneObject(timeZone).name,
    };

    if (frequency === "once" && scheduler?.runTime) {
      const runTime = formatDate(scheduler.runTime, { type: "dateTime" });

      return [
        { title: "Schedule", label: `Once at ${runTime}` },
        { title: "Next Run At", label: `Once at ${runTime}` },
        { title: "Begins", label: `Once at ${runTime}` },
        timeZoneRow,
      ];
    }

    return [
      {
        title: "Schedule",
        label: generateWorkflowSchedulerText(frequency, scheduler),
      },
      {
        title: "Next Run At",
        label: <WorkflowNextRuns asSeparateSection={false} />,
      },
      {
        title: "Begins",
        label: startDate ? formatDate(startDate, { type: "dateTime" }) : "now",
      },
      {
        title: "Ends",
        label: endDate ? formatDate(endDate, { type: "dateTime" }) : "Never",
      },
      timeZoneRow,
    ];
  }, [frequency, scheduler, startDate, endDate, timeZone]);

  useDidMount(() => {
    dispatch(fetchWorkflowNextRunsThunk());
  });

  return (
    <AutomationSectionCard title="Set Schedule" icon="schedule" preview>
      <AutomationPreviewTable rows={rows} />
    </AutomationSectionCard>
  );
};
