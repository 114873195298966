import { FC, useCallback, useState } from "react";
import { Dialog } from "@mui/material";
import { AccountSwitchModeGcpDialog } from "./AccountSwitchModeGcpDialog";
import { AccountSwitchModeAzureDialog } from "./AccountSwitchModeAzureDialog";
import { AccountSwitchModeAwsDialog } from "./AccountSwitchModeAwsDialog";
import {
  ProviderAccessType,
  ProviderType,
} from "../../../../../../services/cloudchipr.api";

interface AccountSwitchModeDialogsProps {
  accessMode: ProviderAccessType;
  providerType: ProviderType;
  accountId: string;
  open: boolean;
  closeDialog: () => void;
}

export const AccountSwitchModeDialogs: FC<AccountSwitchModeDialogsProps> = ({
  accessMode,
  providerType,
  accountId,
  open,
  closeDialog,
}) => {
  const [localAccessMode, setLocalAccessMode] = useState(accessMode);

  const closeDialogMiddleware = useCallback(() => {
    closeDialog();
    // setTimeout is to prevent blinking opposite access mode to user
    setTimeout(() => {
      setLocalAccessMode(accessMode);
    }, 100);
  }, [closeDialog, accessMode]);

  return (
    <Dialog open={open} maxWidth="md" fullWidth>
      {providerType === "aws" && (
        <AccountSwitchModeAwsDialog
          onClose={closeDialogMiddleware}
          providerAccessType={localAccessMode}
          accountId={accountId}
        />
      )}
      {providerType === "gcp" && (
        <AccountSwitchModeGcpDialog
          onClose={closeDialogMiddleware}
          accountId={accountId}
          providerAccessType={localAccessMode}
        />
      )}
      {providerType === "azure" && (
        <AccountSwitchModeAzureDialog
          onClose={closeDialogMiddleware}
          accountId={accountId}
          providerAccessType={localAccessMode}
        />
      )}
    </Dialog>
  );
};
