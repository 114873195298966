import { FC } from "react";
import { Typography } from "@mui/material";

export const AzureServicePrincipalMessage: FC = () => {
  return (
    <Typography variant="body2" fontWeight="medium" color="text.secondary">
      Please run the following CLI command to create a service principal with
      the provided ID.
    </Typography>
  );
};
