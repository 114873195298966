import { createDraftSafeSelector } from "@reduxjs/toolkit";
import { recommendationDbDailyDismissedDataSelector } from "./recommendationDbDailyDismissedDataSelector";
import { recommendationDbWeeklyDismissedDataSelector } from "./recommendationDbWeeklyDismissedDataSelector";

export const recommendationDbDismissedDataSelector = createDraftSafeSelector(
  [
    recommendationDbDailyDismissedDataSelector,
    recommendationDbWeeklyDismissedDataSelector,
  ],
  (daily, weekly) => {
    return [...daily, ...weekly];
  },
);
