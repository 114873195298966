import { FC } from "react";
import { Table } from "@tanstack/react-table";
import { IconButton, Stack, Tooltip } from "@mui/material";
import { Tune } from "@mui/icons-material";
import { HierarchyAllItemsVisibilityFilter } from "./filters/HierarchyAllItemsVisibilityFilter";
import { HierarchyAllItemsCreatedByFilter } from "./filters/HierarchyAllItemsCreatedByFilter";
import { useMenuHook } from "../../../../../utils/hooks/useMenu.hook";
import { GlobalFilter } from "../../../../../storybook/data-grid/toolbar/GlobalFilter";
import { ColumnsSwitcher } from "../../../../../storybook/data-grid/toolbar/ColumnsSwitcher";

interface ResourceExplorerAllViewsDataGridToolbarProps {
  globalFilter: string | number;
  setGlobalFilter: (value: string) => void;
  table: Table<any>;
}

export const HierarchyAllItemsTableToolbar: FC<
  ResourceExplorerAllViewsDataGridToolbarProps
> = ({ globalFilter, setGlobalFilter, table }) => {
  const { anchor, openMenu, closeMenu, open } = useMenuHook();

  return (
    <Stack
      py={2.5}
      px={1.5}
      justifyContent="space-between"
      alignItems="center"
      direction="row"
      bgcolor="white"
    >
      <Stack direction="row" spacing={1}>
        <HierarchyAllItemsCreatedByFilter />
        <HierarchyAllItemsVisibilityFilter />
      </Stack>

      <Stack gap={2} direction="row" justifyContent="flex-end">
        <GlobalFilter
          globalFilter={globalFilter}
          setGlobalFilter={setGlobalFilter}
          size="xsmall"
          sx={{ width: 260 }}
        />

        <Tooltip arrow title="Table Settings" placement="top">
          <IconButton onClick={openMenu} size="small">
            <Tune fontSize="small" />
          </IconButton>
        </Tooltip>

        <ColumnsSwitcher
          open={open}
          onClose={closeMenu}
          anchor={anchor}
          table={table}
        />
      </Stack>
    </Stack>
  );
};
