import { createDraftSafeSelector } from "@reduxjs/toolkit";
import { resourceExplorerMutatedDataForDataGridSelector } from "./resourceExplorerMutatedDataForDataGridSelector";

export const resourceExplorerDataGridDataCountSelector =
  createDraftSafeSelector(
    [resourceExplorerMutatedDataForDataGridSelector],
    (data): number | null => {
      if (!data) {
        return null;
      }

      return data?.dataCount ?? 0;
    },
  );
