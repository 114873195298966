import { automationTypeSelector } from "./automationTypeSelector";
import { changesExistOnCurrentEditWorkflowSelector } from "../workflow/data/edit/changesExistOnCurrentEditWorkflowSelector";
import { changesExistOnCurrentEditOffHoursSelector } from "../off-hours/data/edit/changesExistOnCurrentEditOffHoursSelector";
import { RootState } from "../../../store";

export const automationHasChangesSelector = (state: RootState) => {
  const automationType = automationTypeSelector(state);

  if (automationType === "workflow") {
    return changesExistOnCurrentEditWorkflowSelector(state);
  }

  return changesExistOnCurrentEditOffHoursSelector(state);
};
