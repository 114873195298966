import { Fragment } from "react";
import moment from "moment";
import { Typography } from "@mui/material";
import { Payload } from "recharts/types/component/DefaultTooltipContent";
import numeral from "numeral";
import {
  dateFromUtcByTimeZone,
  formatDate,
} from "../../../../../../../../../utils/helpers/date-time/datetime-format";

export const tooltipFormatter = (
  time: string,
  data: Payload<string, any>[],
) => {
  const payload = data?.[0]?.payload;

  return (
    <Fragment>
      <Typography variant="caption" color="text.disabled" component="span">
        {+payload?.recommendedHours >= 100 ? "Underutilised " : "Utilised "}
        period
      </Typography>
      <br />
      <Typography variant="body2" fontSize={10} mt={1} component="span">
        {formatDate(dateFromUtcByTimeZone(time) || moment(), {
          type: "dateTime",
        })}
      </Typography>
      <br />
      <Typography variant="body2" fontSize={10} component="span">
        CPU {numeral(payload?.cpu).format("0.00")}%
      </Typography>
      <br />
      {payload?.database_connections !== undefined && (
        <Typography variant="body2" fontSize={10} component="span">
          Database connections {payload?.database_connections}
        </Typography>
      )}
    </Fragment>
  );
};
