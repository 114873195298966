import { FC } from "react";
import { Stack } from "@mui/material";
import { OffHoursDailyScheduler } from "./frequency-schedules/daily/OffHoursDailyScheduler";
import { OffHoursWeeklyScheduler } from "./frequency-schedules/OffHoursWeeklyScheduler";
import { OffHoursOnceScheduler } from "./frequency-schedules/OffHoursOnceScheduler";

export const OffHoursScheduler: FC = () => {
  return (
    <Stack direction="row" alignItems="center" spacing={1.5}>
      <OffHoursDailyScheduler />

      <OffHoursWeeklyScheduler />

      <OffHoursOnceScheduler />
    </Stack>
  );
};
