import { createAsyncThunk } from "@reduxjs/toolkit";
import { enqueueSnackbar } from "notistack";
import { cloudChiprApi } from "../../../../services/cloudchipr.api";
import { setCurrentDashboardId } from "../../dashboardsSlice";

export const getDashboardByIdThunk = createAsyncThunk(
  "dashboards/getDashboardById",
  async (dashboardId: string, { dispatch }) => {
    const { getUsersMeOrganisationsCurrentDashboardsByDashboardId } =
      cloudChiprApi.endpoints;

    try {
      const response = await dispatch(
        getUsersMeOrganisationsCurrentDashboardsByDashboardId.initiate(
          { dashboardId },
          { forceRefetch: true },
        ),
      ).unwrap();

      dispatch(setCurrentDashboardId(response.id));
    } catch (e) {
      // @ts-expect-error TODO: return to this after adding error type
      const errMessage = e?.data?.message || "Something went wrong";
      enqueueSnackbar(errMessage, {
        variant: "snackbarAlert",
        AlertSnackBarProps: {
          severity: "error",
        },
      });
    }
  },
);
