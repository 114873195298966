import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_API_ACCOUNT_SERVICE_HOST,
  prepareHeaders: (headers) => {
    headers.set("Content-type", "application/json");

    return headers;
  },
});

// initialize an empty api service that we'll inject endpoints into later as needed
export const emptySplitApiAccountService = createApi({
  reducerPath: "cloudchipr-account-service",
  baseQuery: baseQuery,
  endpoints: () => ({}),
});
