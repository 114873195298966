import { commitmentUtilizationWidgetByWidgetIdDataSelector } from "./commitmentUtilizationWidgetByWidgetIdDataSelector";
import { RootState } from "../../../../../store";

export const commitmentUtilizationWidgetByWidgetIdEmptyDataSelector = (
  state: RootState,
  widgetId: string,
) => {
  const data = commitmentUtilizationWidgetByWidgetIdDataSelector(
    state,
    widgetId,
  );

  return !data?.groups.length;
};
