import { FC, useCallback, useMemo } from "react";
import { DropdownSelect } from "../../../../../../common/select/dropdown-select/DropdownSelect";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../store/hooks";
import { getProviderName } from "../../../../../../../utils/helpers/providers/getProviderName";

import { DropdownSelectOption } from "../../../../../../common/select/dropdown-select/utils/types/types";
import { AccountOptionLabel } from "../../../../../../common/account-select/components/AccountOptionLabel";
import { setAutomationFilters } from "../../../../../../../store/automations/automationsSlice";
import { automationsAccountsFilterOptionsSelector } from "../../../../../../../store/automations/selectros/workflow/filters/available/automationsAccountsFilterOptionsSelector";

export const WorkflowAccountsFilter: FC = () => {
  const dispatch = useAppDispatch();

  const availableAccountOptions = useAppSelector(
    automationsAccountsFilterOptionsSelector,
  );

  const filterFunction = useCallback(
    (option: DropdownSelectOption, keyword: string) => {
      const aim =
        `${option.value} ${option.groupName} ${option.rawValue?.label} ${option.rawValue?.providerAccountId} ${option.rawValue?.orgId}`.toLowerCase();

      return aim.includes(keyword.trim().toLowerCase());
    },
    [],
  );

  const options = useMemo(() => {
    return availableAccountOptions.map((option) => ({
      groupName: option.accountType
        ? getProviderName(option.accountType, { title: true })
        : undefined,
      value: option.value,
      rawValue: option,
      listItemHeight: 57,
      label: (
        <AccountOptionLabel
          showAccountId
          option={option}
          total={false}
          ems={false}
        />
      ),
    }));
  }, [availableAccountOptions]);

  const optionsCount = options.length;

  const changeHandler = useCallback(
    (newFilters: string[]) => {
      dispatch(
        setAutomationFilters({
          key: "accounts",
          values: newFilters.length === optionsCount ? [] : newFilters,
        }),
      );
    },
    [dispatch, optionsCount],
  );

  return (
    <DropdownSelect
      listWidth={360}
      label="Accounts"
      options={options}
      filterFn={filterFunction}
      submitHandlerOnClose={changeHandler}
    />
  );
};
