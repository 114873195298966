import { costBreakdownWidgetSetupDataSelector } from "./costBreakdownWidgetSetupDataSelector";
import { RootState } from "../../../../../store";
import { ChartDataType } from "../../../../../../storybook/charts/multi-type-chart/utils/types/types";
import { formatResourceExplorerChartData } from "../../../../../resource-explorer/utils/helpers/formatResourceExplorerChartData";
import { costBreakdownSetupPropertyByKeySelector } from "../../../setups/cost-breakdown/costBreakdownSetupPropertyByKeySelector";
import { convertCostToPercent } from "../../../../../resource-explorer/selectors/current-resource-explorer/chart/utils/helpers/convertCostToPercent";
import { costBreakdownSetupVisualizationPropertyByKeySelector } from "../../../setups/cost-breakdown/costBreakdownSetupVisualizationPropertyByKeySelector";

export const costBreakdownWidgetSetupChartDataSelector = (
  state: RootState,
): ChartDataType[] | [] => {
  const base =
    costBreakdownSetupVisualizationPropertyByKeySelector("chart_base_type")(
      state,
    );
  const data = costBreakdownWidgetSetupDataSelector(state)?.data;
  const frequency = costBreakdownSetupPropertyByKeySelector("frequency")(state);

  if (!data) {
    return [];
  }

  const formatedData = formatResourceExplorerChartData(data, frequency);

  if (base === "cost" || !formatedData) {
    return formatedData;
  }

  return convertCostToPercent(formatedData);
};
