function TaskPropertyStatusIcon() {
  return (
    <svg
      width="30"
      height="31"
      viewBox="0 0 30 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect y="0.5" width="30" height="30" rx="8" fill="#F19B38" />
      <path
        d="M17.567 13.4917L15.0003 16.0584L12.4337 13.4917L11.2503 14.6667L15.0003 18.4167L18.7503 14.6667L17.567 13.4917ZM15.0003 7.16675C10.4003 7.16675 6.66699 10.9001 6.66699 15.5001C6.66699 20.1001 10.4003 23.8334 15.0003 23.8334C19.6003 23.8334 23.3337 20.1001 23.3337 15.5001C23.3337 10.9001 19.6003 7.16675 15.0003 7.16675ZM15.0003 22.1667C11.317 22.1667 8.33366 19.1834 8.33366 15.5001C8.33366 11.8167 11.317 8.83341 15.0003 8.83341C18.6837 8.83341 21.667 11.8167 21.667 15.5001C21.667 19.1834 18.6837 22.1667 15.0003 22.1667Z"
        fill="white"
      />
    </svg>
  );
}

export default TaskPropertyStatusIcon;
