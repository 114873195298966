import moment from "moment/moment";
import { Dates } from "../../../../../../services/cloudchipr.api";
import { formatDate } from "../../../../../../utils/helpers/date-time/datetime-format";
import { generateDateFromDateLabel } from "../generateDateFromDateLabel";
import { generateLabelByDatesData } from "../generateLabelByDatesData";
import { calculateFromToByDatesData } from "../calculateFromToByDatesData";

export const formatDateData = (date: Dates, quarterStartMonth?: number) => {
  const datesFromLabel =
    date?.label && generateDateFromDateLabel(date?.label, quarterStartMonth);
  const dateLabel = generateLabelByDatesData(date);

  const fromToFromUnits = calculateFromToByDatesData(date, quarterStartMonth);

  let dateTo = date?.to ?? datesFromLabel?.dateTo ?? fromToFromUnits?.toDate;
  let dateFrom =
    date?.from ?? datesFromLabel?.dateFrom ?? fromToFromUnits?.fromDate;

  dateTo = formatDate(moment(dateTo), {
    type: "date",
  });
  dateFrom = formatDate(moment(dateFrom), {
    type: "date",
  });

  return {
    label: dateLabel || `${dateFrom} - ${dateTo}`,
    dateRange: dateLabel ? `${dateFrom} - ${dateTo}` : "",
  };
};
