import { ResourceExplorer } from "../../../../services/cloudchipr.api";
import { RootState } from "../../../store";
import { resourceExplorerHierarchyVisibilitiesMergedDataItemsSelector } from "../views-hierarchy-visibility/resourceExplorerHierarchyVisibilitiesMergedDataItemsSelector";

export const resourceExplorerViewsDataSelector = (
  state: RootState,
): ResourceExplorer[] => {
  return (
    resourceExplorerHierarchyVisibilitiesMergedDataItemsSelector(state) ?? []
  );
};
