import { KeyboardEventHandler } from "react";

export const getPressEnterHandler = (
  callback?: () => void,
  disabled?: boolean,
): KeyboardEventHandler<HTMLElement> => {
  return (event) => {
    if (event.key === "Enter" && callback && !disabled) {
      callback();
    }
  };
};
