import { FC } from "react";
import { Stack } from "@mui/material";
import { OffHoursScheduler } from "./OffHoursScheduler";
import { AutomationFrequency } from "../../../../common/components/form/schedule-content/AutomationFrequency";
import { AutomationStartDateSelection } from "../../../../common/components/form/schedule-content/dates/AutomationStartDateSelection";
import { AutomationEndDateSelection } from "../../../../common/components/form/schedule-content/dates/AutomationEndDateSelection";
import { AutomationFrequencyType } from "../../../../../../../store/automations/utils/types/common";

export const OffHoursScheduleContent: FC = () => {
  return (
    <Stack spacing={2}>
      <AutomationFrequency frequencies={frequencies} />

      <OffHoursScheduler />

      <AutomationStartDateSelection />
      <AutomationEndDateSelection />
    </Stack>
  );
};

const frequencies: AutomationFrequencyType[] = ["daily", "weekly", "once"];
