import { createAsyncThunk } from "@reduxjs/toolkit";
import { enqueueSnackbar } from "notistack";
import { liveFilteredTrackedResourcesFixedCacheKey } from "./utils/constants";
import {
  cloudChiprApi,
  ResourceExplorerFilterSchema,
} from "../../../../services/cloudchipr.api";

export const getLiveFilteredTrackedResourcesForViewThunk = createAsyncThunk(
  "resourceExplorer/getLiveFilteredTrackedResourcesForView",
  async (filters: ResourceExplorerFilterSchema, { dispatch }) => {
    const { postUsersMeOrganisationsCurrentTrackedResources } =
      cloudChiprApi.endpoints;

    try {
      return await dispatch(
        postUsersMeOrganisationsCurrentTrackedResources.initiate(
          {
            body: {
              load_for: "view",
              filters,
            },
          },
          {
            fixedCacheKey: liveFilteredTrackedResourcesFixedCacheKey,
          },
        ),
      ).unwrap();
    } catch (e) {
      // @ts-expect-error TODO: return to this after adding error type
      const errMessage = e?.data?.message || "Something went wrong";
      enqueueSnackbar(errMessage, {
        variant: "snackbarAlert",
        AlertSnackBarProps: {
          severity: "error",
        },
      });
    }
  },
);
