import { FC, Fragment } from "react";
import { Alert, Link } from "@mui/material";
import { useAppSelector } from "../../../store/hooks";
import { MessageLinkToBilling } from "../../common/MessageLinkToBilling";
import {
  accountsMaxNumberExceededSelector,
  accountsMaxSpendingExceededSelector,
  currentOrganizationRestrictionGracePeriodSelector,
  disableByRestrictionViolationSelector,
} from "../../../store/organizations/organizationsSelectors";

export const DisabledByPlanSystemMessage: FC = () => {
  const maxNumberExceeded = useAppSelector(accountsMaxNumberExceededSelector);
  const maxSpendingExceeded = useAppSelector(
    accountsMaxSpendingExceededSelector,
  );
  const disabledByRestriction = useAppSelector(
    disableByRestrictionViolationSelector,
  );

  const gracePeriod = useAppSelector(
    currentOrganizationRestrictionGracePeriodSelector,
  );

  if (!maxNumberExceeded && !maxSpendingExceeded) {
    return null;
  }

  return (
    <Alert severity="warning" variant="filled" shape="sharp">
      {maxSpendingExceeded &&
        "Your cloud spend has exceeded the limits provided by the current plan."}
      {maxNumberExceeded &&
        "The number of connected cloud accounts exceeded your current plan limits."}
      {!disabledByRestriction && (
        <Fragment>
          <MessageLinkToBilling />
          to match the current limits otherwise your access to Cloudchipr
          features will be restricted in {gracePeriod} days. Please{" "}
          <Link href="mailto:support@cloudchipr.com" color="inherit">
            contact support
          </Link>{" "}
          for more details.
        </Fragment>
      )}
    </Alert>
  );
};
