import * as Yup from "yup";

const setValidations = (activeType: string) => {
  return Yup.string().when("type", {
    is: (type: string) => type === activeType,
    then: Yup.string()
      .required("This field is required")
      .typeError("This field is required"),
    otherwise: Yup.string().nullable(),
  });
};

export const integrationsValidationSchema = Yup.object({
  name: Yup.string().required("This field is required"),
  type: Yup.string().required("Please select the type"),

  token: setValidations("slack"),

  url: Yup.string().when("type", {
    is: (type: string) => type === "webhook" || type === "jira",
    then: Yup.string()
      .url("The URL is invalid.")
      .required("This field is required")
      .typeError("This field is required"),
    otherwise: Yup.string().nullable(),
  }),
  content_type: setValidations("webhook"),

  emails: Yup.array().when("type", {
    is: (type: string) => type === "email",
    then: Yup.array()
      .typeError("This field is required")
      .of(Yup.string().trim().email("Please set valid email"))
      .min(1, "Please enter email"),
    otherwise: Yup.array().nullable(),
  }),

  conversations: Yup.array().when("type", {
    is: (type: string) => type === "slack",
    then: Yup.array()
      .typeError("This field is required")
      .min(1, "Please selected at least one conversation"),
    otherwise: Yup.array().nullable(),
  }),

  api_token: setValidations("jira"),
  jira_email: setValidations("jira"),
});

export const jiraConnectionValidationSchema = Yup.object({
  api_token: Yup.string()
    .required("This field is required")
    .typeError("This field is required"),
  jira_email: Yup.string()
    .email("Email is incorrect")
    .required("This field is required")
    .typeError("This field is required"),
  url: Yup.string()
    .url("The URL is invalid.")
    .required("This field is required")
    .matches(/^https/, 'Please make sure the URL starts with "https"')
    .typeError('Please make sure the URL starts with "https"'),
});
