import { FC, useCallback } from "react";
import { IconButton, Tooltip } from "@mui/material";
import { liveUsageRowActionTooltipTitles } from "./utils/constants";
import { IntegrationLogo } from "../../../../../../../../../../common/integration-dialogs/components/common/IntegrationLogo";
import {
  IntegrationType,
  IntegrationTypeWithoutWebhook,
} from "../../../../../../../../../integrations/components/integrations-create/components/common/integration-type/IntegrationTypeCard";

interface LiveUsageMgmtToolbarRowIntegrationActionProps {
  type: IntegrationTypeWithoutWebhook;
  onClick(type: IntegrationType): void;
  disabled?: boolean;
}

export const LiveUsageMgmtToolbarRowIntegrationAction: FC<
  LiveUsageMgmtToolbarRowIntegrationActionProps
> = ({ type, onClick, disabled }) => {
  const clickHandler = useCallback(() => {
    onClick(type);
  }, [onClick, type]);

  const tooltipType = disabled ? "disabled" : "active";
  return (
    <Tooltip
      title={liveUsageRowActionTooltipTitles?.[type]?.[tooltipType] ?? ""}
      placement="top"
      arrow
    >
      <span>
        <IconButton sx={{ p: 0.7 }} onClick={clickHandler} disabled={disabled}>
          <IntegrationLogo
            type={type}
            width={18}
            variant={disabled ? "grey" : "colored"}
          />
        </IconButton>
      </span>
    </Tooltip>
  );
};
