import { RootState } from "../../../../../store";

import { reservationsDataForGranularSelector } from "../reservationsDataForGranularSelector";

export const reservationsRealCostSelector = (
  state: RootState,
): number | null => {
  const data = reservationsDataForGranularSelector(state);

  return data?.totals?.real_cost ?? null;
};
