import { isHierarchyFolder } from "./isHierarchyFolder";
import {
  Dashboard,
  DashboardFolder,
  ReFolderV2,
  ResourceExplorer,
} from "../../../../services/cloudchipr.api";
import {
  TreeItem,
  TreeItems,
} from "../../../../components/navigation/components/sortable-tree/utils/types";

export const transformHierarchyDataToTreeItems = (
  hierarchy:
    | (Dashboard | DashboardFolder)[]
    | (ResourceExplorer | ReFolderV2)[],
): TreeItems | null => {
  if (!hierarchy) {
    return null;
  }
  return hierarchy.reduce((result, item) => {
    if (isHierarchyFolder(item)) {
      const items = (item.items ?? [])?.reduce((result, item) => {
        result.push({
          id: item.id,
          name: item.name,
          type: "item",
          items: [],
        });

        return result;
      }, [] as TreeItem[]);

      result.push({
        id: item.id,
        items: items,
        name: item.name,
        type: "folder",
        collapsed: true,
      });
    } else {
      result.push({
        id: item.id,
        name: item.name,
        items: [],
        type: "item",
        disabled: "is_default" in item ? item.is_default : false,
      });
    }
    return result;
  }, [] as TreeItems);
};
