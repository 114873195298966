import { FC } from "react";
import moment from "moment";
import { TableCell, Typography } from "@mui/material";
import { formatDate } from "../../../../../../utils/helpers/date-time/datetime-format";

interface LocalDateCellProps {
  date?: string | null;
}

export const LocalDateCell: FC<LocalDateCellProps> = ({ date }) => {
  if (!date) {
    return <TableCell />;
  }
  const localDate = moment.utc(date).local();

  return (
    <TableCell sx={{ whiteSpace: "nowrap" }}>
      {formatDate(localDate, { type: "date" })}
      <Typography variant="body2" color="text.secondary">
        {formatDate(localDate, { type: "time" })}
      </Typography>
    </TableCell>
  );
};
