import { createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../../../store";
import { setAlertsData } from "../../alertsSlice";
import { alertsCostAnomalyDataGroupingSelector } from "../../selectors/cost-anomaly-alerts/data/alertsCostAnomalyDataGroupingSelector";
import { resourceExplorerGroupings } from "../../../../components/pages/resource-explorer/components/resource-explorer-card/utils/constants/groupings";

export const changeCostAnomalyAlertGroupingByViewIdThunk = createAsyncThunk(
  "alert/changeCostAnomalyAlertGroupingByViewId",
  (viewId: string, { dispatch, getState }) => {
    const state = getState() as RootState;
    const currentGroupings = alertsCostAnomalyDataGroupingSelector(state);

    dispatch(
      setAlertsData({
        grouping: resourceExplorerGroupings.includes(currentGroupings)
          ? currentGroupings
          : "service",
      }),
    );
  },
);
