import { FC, Fragment, useState, SyntheticEvent, useCallback } from "react";
import {
  Collapse,
  IconButton,
  Link,
  Stack,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useToggle } from "rooks";
import { CommandSnippet } from "../CommandSnippet";

export const AzureCLIInstallationGuide: FC = () => {
  const [state, toggle] = useToggle();
  const [tabIndex, setTabIndex] = useState(0);

  const currentTabIsMac = tabIndex === 0;

  const handleTabChange = useCallback(
    (event: SyntheticEvent<Element, Event>, newTabIndex: number) => {
      setTabIndex(newTabIndex);
    },
    [],
  );

  return (
    <Fragment>
      <Stack direction="row" alignItems="center" mt={3}>
        <Typography variant="caption" flexGrow={1} color="text.secondary">
          * Need help installing Azure CLI?
        </Typography>

        <IconButton size="small" onClick={toggle}>
          {state ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        </IconButton>
      </Stack>

      <Collapse in={state}>
        <Tabs value={tabIndex} onChange={handleTabChange} sx={{ mb: 2 }}>
          <Tab label="FOR MAC" />
          <Tab label="FOR WINDOWS" />
        </Tabs>

        {currentTabIsMac ? (
          <CommandSnippet code="brew update && brew install azure-cli" />
        ) : (
          <Typography variant="body2" color="text.secondary">
            Install Azure CLI -{" "}
            <Link
              href="https://learn.microsoft.com/en-us/cli/azure/install-azure-cli-windows?tabs=azure-cli"
              underline="hover"
              target="_blank"
            >
              the guide
            </Link>
            . Then login:
          </Typography>
        )}
        <CommandSnippet code="az login" />
      </Collapse>
    </Fragment>
  );
};
