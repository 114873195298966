import { userRoleDataByIdSelector } from "./userRoleDataByIdSelector";
import { RootState } from "../../../../store";

export const userRoleMetaDataByIdSelector = (state: RootState) => {
  const data = userRoleDataByIdSelector(state);
  return {
    createdBy: data?.created_by?.name ?? data?.created_by?.email,
    modifiedBy: data?.modified_by?.name ?? data?.modified_by?.email,
    createdAt: data?.created_at,
    modifiedAt: data?.modified_at,
    isDefault: data?.is_default,
  };
};
