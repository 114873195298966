import { costAndUsageSumSetupWidgetDataGridDataSelector } from "./costAndUsageSumSetupWidgetDataGridDataSelector";
import { costAndUsageSumSetupPropertyByKeySelector } from "../costAndUsageSumSetupPropertyByKeySelector";
import { RootState } from "../../../../../store";
import { CostAndUsageDataGridType } from "../../../../../../components/pages/dashboard/components/adding-widget/widget-create/widget-setups/cost-and-usage/content/data-grid/utils/types";

export const costAndUsageSumSetupWidgetFilteredDataSelector = (
  state: RootState,
): CostAndUsageDataGridType[] | undefined => {
  const gridData = costAndUsageSumSetupWidgetDataGridDataSelector(state);

  if (!gridData) {
    return;
  }

  const filters = costAndUsageSumSetupPropertyByKeySelector("filter")(state);

  if (!filters?.length) {
    return gridData;
  }

  return gridData.filter((item) => {
    return filters?.includes(item.costAndUsageSumWidgetFilterType);
  });
};
