import { GroupedRolePermissions } from "../../../utils/types/common";
import { Permission } from "../../../../../services/cloudchipr.api";

export const generateSelectedRolePermissionNamesByGroupedPermissions = (
  permissions: GroupedRolePermissions,
): string[] => {
  const groups = Object.keys(permissions ?? {}) as Permission["group"][];

  return groups.reduce((result, group) => {
    const names = permissions[group].data.reduce((result, item) => {
      if (item.selected && item.name) {
        result.push(item.name);
      }
      return result;
    }, [] as string[]);
    result = result.concat(names ?? []);
    return result;
  }, [] as string[]);
};
