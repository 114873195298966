import { FC, Fragment, useCallback } from "react";
import Typography from "@mui/material/Typography";
import { Button, Tooltip } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useAppAbility } from "../../../../../../../../../services/permissions";

interface CreateBudgetButtonProps {
  viewId: string;
}

export const CreateBudgetButton: FC<CreateBudgetButtonProps> = ({ viewId }) => {
  const navigate = useNavigate();
  const { can } = useAppAbility();
  const canCreateBudget = can("create", "budget");

  const backHandler = useCallback(() => {
    navigate(`/budgets/create?viewId=${viewId}`);
  }, [navigate, viewId]);

  return (
    <Fragment>
      <Typography
        variant="subtitle2"
        color="text.secondary"
        fontWeight="regular"
      >
        Budget
      </Typography>

      <Tooltip
        title="Create Budget"
        placement="top-start"
        arrow
        disableHoverListener={!canCreateBudget}
      >
        <span>
          <Button
            sx={{
              minWidth: 28,
              width: 28,
              height: 28,
              borderRadius: 2,
              bgcolor: "grey.200",
              fontSize: 22,
              fontWeight: 400,
            }}
            disabled={!canCreateBudget}
            onClick={backHandler}
            color="tertiary"
            variant="contained"
          >
            +
          </Button>
        </span>
      </Tooltip>
    </Fragment>
  );
};
