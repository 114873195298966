import { FC, Fragment } from "react";
import { Box, Chip, Stack, Typography } from "@mui/material";
import { AutomationNotificationsEmails } from "./AutomationNotificationsEmails";
import {
  Emails,
  useGetUsersMeIntegrationsQuery,
} from "../../../../../../../services/cloudchipr.api";
import { IntegrationLogo } from "../../../../../../common/integration-dialogs/components/common/IntegrationLogo";
import { money } from "../../../../../../../utils/numeral/money";
import { IntegrationNotification } from "../../../../../common/notification-selection/utils/types/types";

interface AutomationActionNotificationsProps {
  notifications?: IntegrationNotification[] | null;
  minThreshold?: number | null;
  snoozed?: boolean | null;
  emails: Emails;
}

export const AutomationActionNotifications: FC<
  AutomationActionNotificationsProps
> = ({ notifications, minThreshold, snoozed, emails }) => {
  const { data } = useGetUsersMeIntegrationsQuery(
    {},
    { skip: !notifications?.length },
  );

  return (
    <Stack>
      {emails && (
        <Stack spacing={1} mb={2}>
          <AutomationNotificationsEmails label="To" emails={emails.to} />
          <AutomationNotificationsEmails label="Cc" emails={emails.cc} />
          <AutomationNotificationsEmails label="Bcc" emails={emails.bcc} />
        </Stack>
      )}

      {!!notifications?.length && (
        <Fragment>
          <Typography variant="body2" mb={1}>
            Integration(s)
          </Typography>
          <Stack direction="row">
            {data?.map(({ id, type, name }) => {
              const includes = notifications?.some(
                ({ integration_id }) => integration_id === id,
              );

              if (!includes) {
                return null;
              }

              return (
                <Chip
                  key={id}
                  icon={
                    <Box pl={0.5} pt={0.5}>
                      <IntegrationLogo type={type} width={14} />
                    </Box>
                  }
                  label={name}
                  variant="outlined"
                  sx={{ mr: 1, mb: 1, ".MuiChip-icon": { ml: 1 } }}
                />
              );
            })}
          </Stack>
        </Fragment>
      )}

      <Stack direction="row" spacing={1}>
        {minThreshold && (
          <Chip
            size="small"
            label={`Minimum threshold: ${money(minThreshold)}`}
          />
        )}

        {!snoozed && <Chip size="small" label="Always Notify" />}
      </Stack>
    </Stack>
  );
};
