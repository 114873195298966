import { FC, Fragment, useCallback } from "react";
import { CommitmentUtilizationWidgetSetupSidebar } from "./components/sidebar/CommitmentUtilizationWidgetSetupSidebar";
import { CommitmentUtilizationWidgetSetupContent } from "./components/content/CommitmentUtilizationWidgetSetupContent";
import { WidgetSetupHeader } from "../common/WidgetSetupHeader";
import { WidgetSetupLayout } from "../common/WidgetSetupLayout";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../store/hooks";

import { scrollPageToBottom } from "../../../../../../utils/helpers/page-scrolling-functions";
import { buildCommitmentUtilizationWidgetThunk } from "../../../../../../../../store/dashboards/thunks/widgets/commitment-utilization/actions/buildCommitmentUtilizationWidgetThunk";
import { commitmentUtilizationWidgetSetupSelector } from "../../../../../../../../store/dashboards/selectors/setups/commitment-utilization/commitmentUtilizationWidgetSetupSelector";
import { commitmentUtilizationSubmitButtonDisabledSelector } from "../../../../../../../../store/dashboards/selectors/setups/commitment-utilization/commitmentUtilizationSubmitButtonDisabledSelector";

interface CommitmentUtilizationWidgetSetupProps {
  onClose(): void;
  onSubmit(): void;
}

export const CommitmentUtilizationWidgetSetup: FC<
  CommitmentUtilizationWidgetSetupProps
> = ({ onClose, onSubmit }) => {
  const dispatch = useAppDispatch();
  const setup = useAppSelector(commitmentUtilizationWidgetSetupSelector);

  const disabled = useAppSelector(
    commitmentUtilizationSubmitButtonDisabledSelector,
  );

  const submitHandler = useCallback(async () => {
    const responseId = await dispatch(
      buildCommitmentUtilizationWidgetThunk(),
    ).unwrap();
    onSubmit();

    if (responseId && !setup?.id) {
      scrollPageToBottom();
    }
  }, [onSubmit, dispatch, setup?.id]);

  return (
    <Fragment>
      <WidgetSetupHeader
        disabled={disabled}
        onAdd={submitHandler}
        onCancel={onClose}
      />
      <WidgetSetupLayout
        empty={
          !setup?.providerOrganisationId
            ? "Select Organization to get started."
            : ""
        }
        appBar={<CommitmentUtilizationWidgetSetupSidebar />}
      >
        <CommitmentUtilizationWidgetSetupContent />
      </WidgetSetupLayout>
    </Fragment>
  );
};
