import { useCallback, useState } from "react";
import { useLocalStorageHook } from "../../../../../../../utils/hooks/useLocalStorage.hook";

const localStorageOpenAdvancedFiltersKey = "openAdvancedFilter";

export const useToggleAdvancedFilterTriggerHook = () => {
  const { getItem, setItem } = useLocalStorageHook();
  const openAdvancedFilterFromLocalStorage = getItem(
    localStorageOpenAdvancedFiltersKey,
  );
  const [advancedFiltersOpen, setAdvancedFiltersOpen] = useState(
    openAdvancedFilterFromLocalStorage,
  );

  const toggleAdvancedFilter = useCallback(() => {
    setItem(localStorageOpenAdvancedFiltersKey, !advancedFiltersOpen);
    setAdvancedFiltersOpen(!advancedFiltersOpen);
  }, [advancedFiltersOpen, setItem]);

  return [advancedFiltersOpen, toggleAdvancedFilter];
};
