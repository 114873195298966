import { FC } from "react";
import { IconButton, Tooltip } from "@mui/material";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";

interface LiveUsageMgmtResourceCardHeaderFullScreenButtonProps {
  enter?: boolean;
  onClick(): void;
}

export const LiveUsageMgmtResourceCardHeaderFullScreenButton: FC<
  LiveUsageMgmtResourceCardHeaderFullScreenButtonProps
> = ({ enter, onClick }) => {
  return (
    <Tooltip
      arrow
      placement="left"
      title={`${enter ? "Enter" : "Exit"} full screen`}
    >
      <IconButton onClick={onClick} size="small" color="primary">
        {enter ? (
          <FullscreenIcon color="inherit" />
        ) : (
          <FullscreenExitIcon color="inherit" />
        )}
      </IconButton>
    </Tooltip>
  );
};
