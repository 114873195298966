import { FC, ReactElement } from "react";

export type YAxisFormatter = (
  value: string | number
) => string | number | ReactElement;

export const CustomizedYAxisTick: FC<any> = ({
  x,
  y,
  payload,
  formatter,
  empty,
}) => {
  if (empty) {
    return null;
  }

  const label = formatter ? formatter(payload.value) : payload.value;

  const length = `${label}`.length;
  const translateX = length > 5 ? x - 4 : x - 10;

  return (
    <g transform={`translate(${translateX},${y + 3})`}>
      <text textAnchor="end" fontSize={14} fontWeight={500}>
        {label}
      </text>
    </g>
  );
};
