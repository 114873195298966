import { RootState } from "../../../../../store";
import { CommitmentDateGranularDataPoint } from "../../../../../../services/cloudchipr.api";
import { reservationsDataForGranularSelector } from "../reservationsDataForGranularSelector";

export const reservationsGranularDataSelector = (
  state: RootState,
): CommitmentDateGranularDataPoint[] | undefined => {
  const data = reservationsDataForGranularSelector(state);

  return data?.date_granular_data?.data;
};
