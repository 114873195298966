import { FC } from "react";
import { Alert } from "@mui/material";
import { MessageLinkToBilling } from "../../common/MessageLinkToBilling";

export const UnpaidInvoicesMessage: FC = () => {
  return (
    <Alert variant="filled" shape="sharp" severity="error">
      There are unpaid invoices on your account. Please pay them not to lose
      access to Cloudchipr. <MessageLinkToBilling title="Manage Billing" />
    </Alert>
  );
};
