import { FC, useCallback } from "react";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../../../../store/hooks";
import { costBreakdownSetupPropertyByKeySelector } from "../../../../../../../../../../../store/dashboards/selectors/setups/cost-breakdown/costBreakdownSetupPropertyByKeySelector";
import { TrendType } from "../../../../../../../../../../../services/cloudchipr.api";
import { WidgetSetupTrendSettingSelect } from "../../../../common/toolbar/WidgetSetupTrendSettingSelect";
import { costBreakdownTrendTypeChangeThunk } from "../../../../../../../../../../../store/dashboards/thunks/widgets/cost-breakdown/setup-change/costBreakdownTrendTypeChangeThunk";

export const CostBreakdownWidgetAdvancedSettingsTrendSetting: FC = () => {
  const dispatch = useAppDispatch();

  const trendType = useAppSelector(
    costBreakdownSetupPropertyByKeySelector("trendType"),
  );

  const handleTrendTypeChange = useCallback(
    (trend: TrendType) => {
      dispatch(costBreakdownTrendTypeChangeThunk(trend));
    },
    [dispatch],
  );

  return (
    <WidgetSetupTrendSettingSelect
      trendType={trendType}
      onTrendTypeChange={handleTrendTypeChange}
    />
  );
};
