import { FC, useCallback } from "react";
import { Alert, Typography } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../../../../store/hooks";
import { awsTerraform } from "../../../../../../store/account-setup/selectors/awsTerraform";
import { CommandSnippet } from "../CommandSnippet";
import { setPolling } from "../../../../../../store/account-setup/accountSetupSlice";

export const TabPanelAWSTerraform: FC = () => {
  const dispatch = useAppDispatch();
  const terraform = useAppSelector(awsTerraform);

  const copyHandler = useCallback(() => {
    setTimeout(() => dispatch(setPolling(true)), 3000);
  }, [dispatch]);

  return (
    <div>
      <Typography variant="body2" fontWeight="medium" color="text.secondary">
        Please make sure you have Terraform installed and you are logged in to
        your desired AWS account. Then copy and paste the following code block
        to your Terraform configuration and apply it. Cloudchipr will
        automatically detect when the access is provided and proceed to the next
        step.
      </Typography>
      {terraform && <CommandSnippet code={terraform} onCopy={copyHandler} />}
      <Alert severity="info" variant="outlined">
        Cloudchipr’s Terraform module will create the necessary cross account
        roles and permissions. It will not take any actions on your resources.
      </Alert>
    </div>
  );
};
