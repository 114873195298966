import { getDefaultFilterTemplates } from "./utils/helpers/getDefaultFilterTemplatesWithoutEms";
import { RootState } from "../../../store";
import { organisationCurrentFilterSetsSelector } from "../current-filter-sets/organisationCurrentFilterSetsSelector";
import { ProviderType } from "../../../../services/cloudchipr.api";

export const filterTemplatesSelector = (
  state: RootState,
  provider: ProviderType,
) => {
  const data = organisationCurrentFilterSetsSelector(state);

  if (!data) {
    return [];
  }

  const defaults = getDefaultFilterTemplates(data, provider);

  const filteredData = data.filter((filterSet) => {
    return (
      filterSet.source !== "cloudchipr" && filterSet.cloud_provider === provider
    );
  });

  const rest = filteredData.toSorted((first, second) => {
    if (first.name.toLowerCase() > second.name.toLowerCase()) {
      return 1;
    }

    return -1;
  });

  return [...defaults, ...rest];
};
