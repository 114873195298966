import { FC, Fragment, useMemo } from "react";
import {
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from "@mui/material";
import { KeyboardArrowDown, KeyboardArrowRight } from "@mui/icons-material";
import { useToggle } from "rooks";
import { CategoriesListSubRow } from "./CategoriesListSubRow";
import { TableNameCell } from "./cells/TableNameCell";
import { TableSpendingCell } from "./cells/TableSpendingCell";
import { TableFilteredResourcesCell } from "./cells/TableFilteredResourcesCell";
import { NewCategoryAddingRow } from "./NewCategoryAddingRow";
import { CategoryActionsCell } from "./category-group-table/CategoryActionsCell";
import { AccountsWithPopover } from "../../../../common/accounts-with-popover/AccountsWithPopover";
import {
  AccountCategoryWithCost,
  EmbeddedBillingAccount,
} from "../../../../../services/cloudchipr.api";

interface CategoriesListRowProps {
  name: string;
  categories: AccountCategoryWithCost[];
}

export const CategoriesListRow: FC<CategoriesListRowProps> = ({
  name,
  categories,
}) => {
  const [collapsed, toggleCollapsed] = useToggle(true);

  const { costs, spending, accounts } = useMemo(() => {
    const allAccounts: EmbeddedBillingAccount[] = [];

    const { costs, spending } = categories.reduce(
      (sum, { filtered_resource_costs, total_cost, accounts }) => {
        allAccounts.push(...accounts);

        return {
          costs: sum.costs + filtered_resource_costs,
          spending: sum.spending + total_cost,
        };
      },
      { costs: 0, spending: 0 },
    );

    return {
      costs,
      spending,
      accounts: allAccounts,
    };
  }, [categories]);

  return (
    <Fragment>
      <TableRow
        sx={{
          borderTop: 1,
          borderColor: "grey.200",
          "& td,th": { border: "none" },
        }}
      >
        <TableCell component="th" scope="row" sx={{ width: 66, minWidth: 66 }}>
          <IconButton size="small" onClick={toggleCollapsed}>
            {collapsed ? <KeyboardArrowDown /> : <KeyboardArrowRight />}
          </IconButton>
        </TableCell>

        <TableNameCell name={name} />

        <TableSpendingCell spending={spending} />

        <TableFilteredResourcesCell costs={costs} />

        <TableCell>
          <AccountsWithPopover chipView accounts={accounts} />
        </TableCell>

        <CategoryActionsCell name={name} categories={categories} />
      </TableRow>

      <TableRow>
        <TableCell sx={{ border: "none", p: 0 }} colSpan={8}>
          <Collapse in={collapsed} timeout="auto" unmountOnExit>
            <Table size="small">
              <TableBody>
                {categories.map((category) => (
                  <CategoriesListSubRow
                    key={category.value}
                    category={category}
                  />
                ))}

                <NewCategoryAddingRow name={name} />
              </TableBody>
            </Table>
          </Collapse>
        </TableCell>
      </TableRow>
    </Fragment>
  );
};
