import { RootState } from "../../store";
import { cloudChiprApi } from "../../../services/cloudchipr.api";
import { getToggleReportStatusLoadingFixedCacheKey } from "../utils/constants/fixedCacheKeys";

export const toggleReportStatusLoadingSelector = (
  state: RootState,
  reportId: string,
) => {
  const { patchUsersMeOrganisationsCurrentReportsByReportId } =
    cloudChiprApi.endpoints;
  const fixedCacheKey = getToggleReportStatusLoadingFixedCacheKey(reportId);

  return patchUsersMeOrganisationsCurrentReportsByReportId.select(
    fixedCacheKey,
  )(state)?.isLoading;
};
