import { FC, useCallback } from "react";
import { TextField } from "@mui/material";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import { formatDate } from "../../../../../../utils/helpers/date-time/datetime-format";

interface RangeSelectionInputProps {
  source: string;
  label: string;
  date?: Date;
  onClick(source: string): void;
}

export const RangeSelectionInput: FC<RangeSelectionInputProps> = ({
  label,
  onClick,
  date,
  source,
}) => {
  const clickHandler = useCallback(() => {
    onClick(source);
  }, [onClick, source]);

  return (
    <TextField
      size="small"
      label={label}
      focused={false}
      onClick={clickHandler}
      InputProps={InputProps}
      value={date ? formatDate(date?.toString(), { type: "date" }) : ""}
    />
  );
};

const InputProps = {
  readOnly: true,
  sx: { cursor: "pointer" },
  inputProps: { sx: { cursor: "pointer" } },
  endAdornment: <CalendarTodayIcon color="action" fontSize="small" />,
};
