import { createAsyncThunk } from "@reduxjs/toolkit";
import { cloudChiprApi } from "../../../services/cloudchipr.api";

export const getAllBudgetsThunk = createAsyncThunk(
  "budgets/getAllBudgets",
  async (_, { dispatch }) => {
    const { getUsersMeOrganisationsCurrentBudgets } = cloudChiprApi.endpoints;

    return await dispatch(
      getUsersMeOrganisationsCurrentBudgets.initiate(undefined, {
        forceRefetch: true,
      }),
    ).unwrap();
  },
);
