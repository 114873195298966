import { FC, Fragment, useCallback } from "react";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import { Alert, Button } from "@mui/material";
import { Logout } from "@mui/icons-material";
import { useAuth0 } from "@auth0/auth0-react";
import { useWillUnmount } from "rooks";
import { AccountSetupSidebar } from "./AccountSetupSidebar";
import { SetupSteps } from "./SetupSteps";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { resetAccountSetup } from "../../../store/account-setup/accountSetupSlice";
import { accountsMaxNumberExceededSelector } from "../../../store/organizations/organizationsSelectors";

export const AccountSetup: FC = () => {
  const { logout } = useAuth0();

  const accountsMaxNumberExceeded = useAppSelector(
    accountsMaxNumberExceededSelector,
  );

  const handleOnLogOutClick = useCallback(
    () => logout({ returnTo: window.location.origin }),
    [logout],
  );

  const dispatch = useAppDispatch();
  useWillUnmount(() => {
    dispatch(resetAccountSetup());
  });

  return (
    <Stack direction="row" alignItems="stretch">
      <AccountSetupSidebar />

      <Box sx={mainAreaStyles}>
        {accountsMaxNumberExceeded ? (
          <Alert
            color="warning"
            action={
              <Button
                color="warning"
                variant="outlined"
                startIcon={<Logout />}
                onClick={handleOnLogOutClick}
              >
                Logout
              </Button>
            }
          >
            The number of connected cloud accounts exceeded your current plan
            limits.
          </Alert>
        ) : (
          <Fragment>
            <Button
              sx={logoutButtonStyles}
              startIcon={<Logout />}
              variant="outlined"
              onClick={handleOnLogOutClick}
            >
              Logout
            </Button>
            <SetupSteps />
          </Fragment>
        )}
      </Box>
    </Stack>
  );
};

const mainAreaStyles = {
  flexGrow: 2,
  flexShrink: 1,
  maxWidth: 900,
};

const logoutButtonStyles = {
  position: "absolute",
  right: 16,
  top: 16,
};
