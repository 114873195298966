import { DropdownSelectOption } from "../../../select/dropdown-select/utils/types/types";

export const accountOptionFilterFn = (
  option: DropdownSelectOption,
  keyword: string,
) => {
  keyword = keyword.trim().toLowerCase();

  if (!option.rawValue) {
    return false;
  }

  const { label, accountType, value, groupName, status } = option.rawValue;

  const aim =
    `${label} ${accountType} ${value} ${groupName} ${status}`.toLowerCase();

  return aim.includes(keyword);
};
