import {
  ChangeEvent,
  FC,
  ReactElement,
  ReactNode,
  useCallback,
  MouseEvent,
} from "react";
import { Box, Collapse, Stack, Switch, Typography } from "@mui/material";
import { useToggle } from "rooks";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useHover } from "../../../../../../../../../utils/hooks/useHover.hook";

interface WidgetConfigSectionProps {
  icon: ReactElement;
  title: string;
  children: ReactNode;
  initialExpanded?: boolean;
  switchChecked?: boolean;
  onSwitchChange?: (checked: boolean) => void;
}

export const WidgetConfigSection: FC<WidgetConfigSectionProps> = ({
  icon,
  title,
  children,
  initialExpanded,
  switchChecked,
  onSwitchChange,
}) => {
  const { ref, hovered } = useHover();
  const [collapsed, toggleCollapsed] = useToggle(initialExpanded);

  const handleSwitchChange = useCallback(
    (_: ChangeEvent<HTMLInputElement>, checked: boolean) => {
      onSwitchChange?.(checked);
    },
    [onSwitchChange],
  );

  return (
    <Stack
      p={1.5}
      border={1}
      borderColor={hovered ? "grey.600" : "grey.400"}
      borderRadius={1}
    >
      <Stack
        ref={ref}
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        onClick={toggleCollapsed}
        sx={{
          cursor: "pointer",
        }}
      >
        <Stack spacing={1} alignItems="center" direction="row">
          {icon}
          <Typography variant="body2" fontWeight="medium">
            {title}
          </Typography>
        </Stack>

        <Stack spacing={1} alignItems="center" direction="row">
          <Stack component="span" onClick={stopPropagation}>
            {onSwitchChange && (
              <Switch
                checked={switchChecked}
                onChange={handleSwitchChange}
                size="small"
              />
            )}
          </Stack>
          {collapsed ? (
            <KeyboardArrowUpIcon fontSize="small" />
          ) : (
            <KeyboardArrowDownIcon fontSize="small" />
          )}
        </Stack>
      </Stack>
      <Collapse in={collapsed}>
        <Box mt={1.5}>{children}</Box>
      </Collapse>
    </Stack>
  );
};

const stopPropagation = (event: MouseEvent<HTMLSpanElement>) => {
  event.stopPropagation();
};
