import { FC } from "react";
import { IconButton, Stack, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useAppSelector } from "../../../../../../../store/hooks";
import { dimensionDrawerModeSelector } from "../../../../../../../store/dimensions/selectors/slice-data/dimensionDrawerModeSelector";
import { dimensionNameSelector } from "../../../../../../../store/dimensions/selectors/slice-data/dimensionNameSelector";
import { editingCategoryNameSelector } from "../../../../../../../store/dimensions/selectors/slice-data/editing-category/editingCategoryNameSelector";

interface DrawerHeaderProps {
  onClose(): void;
}

export const CategoryDrawerHeader: FC<DrawerHeaderProps> = ({ onClose }) => {
  const mode = useAppSelector(dimensionDrawerModeSelector);
  const dimensionName = useAppSelector(dimensionNameSelector);
  const categoryName = useAppSelector(editingCategoryNameSelector);

  return (
    <Stack
      px={2}
      py={1.5}
      direction="row"
      borderBottom={1}
      alignItems="center"
      borderColor="grey.300"
      justifyContent="space-between"
    >
      {mode === "categoryCreate" && (
        <Typography variant="body2">
          Add Category to{" "}
          <Typography variant="inherit" component="span" fontWeight="bold">
            {dimensionName}
          </Typography>{" "}
          Dimension
        </Typography>
      )}

      {mode === "categoryEdit" && (
        <Typography variant="body2">
          <Typography variant="inherit" component="span" fontWeight="bold">
            {categoryName}
          </Typography>{" "}
          Category
        </Typography>
      )}

      <IconButton onClick={onClose} size="small">
        <CloseIcon fontSize="small" />
      </IconButton>
    </Stack>
  );
};
