import { createAsyncThunk } from "@reduxjs/toolkit";
import { enqueueSnackbar } from "notistack";
import { cloudChiprApi } from "../../../services/cloudchipr.api";
import { deleteReportFixedCacheKey } from "../utils/constants/fixedCacheKeys";

export const deleteReportThunk = createAsyncThunk(
  "reports/deleteReport",
  async (reportId: string, { dispatch }) => {
    const { deleteUsersMeOrganisationsCurrentReportsByReportId } =
      cloudChiprApi.endpoints;

    try {
      await dispatch(
        deleteUsersMeOrganisationsCurrentReportsByReportId.initiate(
          {
            reportId,
          },
          {
            fixedCacheKey: deleteReportFixedCacheKey,
          },
        ),
      );
    } catch (e) {
      // @ts-expect-error todo: fix api spec
      const errMessage = e?.data?.message || "Something went wrong";
      enqueueSnackbar(errMessage, {
        variant: "snackbarAlert",
        AlertSnackBarProps: {
          severity: "error",
        },
      });
    }
  },
);
