import { FC, Fragment } from "react";
import { OffHoursResourcesPreview } from "./OffHoursResourcesPreview";
import { OffHoursNotificationsPreview } from "./OffHoursNotificationsPreview";
import { OffHoursSchedulePreview } from "./OffHoursSchedulePreview";
import { AutomationEnvironmentPreview } from "../../../common/components/preview/AutomationEnvironmentPreview";

export const OffHoursFormPreview: FC = () => {
  return (
    <Fragment>
      <OffHoursSchedulePreview />

      <AutomationEnvironmentPreview />

      <OffHoursNotificationsPreview />

      <OffHoursResourcesPreview />
    </Fragment>
  );
};
