import { commitmentsRecommendationsFiltersSelector } from "./commitmentsRecommendationsFiltersSelector";
import { RootState } from "../../../../store";
import { CommitmentPaymentOption } from "../../../../../services/cloudchipr.api";

export const commitmentsRecommendationsPaymentOptionSelector = (
  state: RootState,
): CommitmentPaymentOption[] | undefined => {
  const filters = commitmentsRecommendationsFiltersSelector(state);

  return filters.payment_option;
};
