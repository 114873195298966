import { FC, useCallback } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../../store/hooks";
import { setCommitmentsDetailsDrawerDateGranularity } from "../../../../../../store/commitments/commitmentsSlice";
import { DateDataPoint } from "../../../../../../services/cloudchipr.api";
import { CommitmentsDateGranularitySelector } from "../options/date-granularity/CommitmentsDateGranularitySelector";
import { commitmentsDetailsDrawerDateGranularitySelector } from "../../../../../../store/commitments/selectors/common/details-drawer/commitmentsDetailsDrawerDateGranularitySelector";
import { getUtilizationAndCoverageDetailsThunk } from "../../../../../../store/commitments/thunks/getUtilizationAndCoverageDetailsThunk";
import { commitmentsTypeTabSelector } from "../../../../../../store/commitments/selectors/common/commitmentsTypeTabSelector";
import { getReservationDetailsThunk } from "../../../../../../store/commitments/thunks/getReservationDetailsThunk";

export const CommitmentsDrawerDateGranularity: FC = () => {
  const dispatch = useAppDispatch();

  const activeTab = useAppSelector(commitmentsTypeTabSelector);
  const dateGranularity = useAppSelector(
    commitmentsDetailsDrawerDateGranularitySelector,
  );

  const changeHandler = useCallback(
    (dg: DateDataPoint) => {
      dispatch(setCommitmentsDetailsDrawerDateGranularity(dg));

      if (activeTab === "reservation") {
        dispatch(getReservationDetailsThunk());
      } else {
        dispatch(getUtilizationAndCoverageDetailsThunk());
      }
    },
    [dispatch, activeTab],
  );

  if (!dateGranularity) {
    return null;
  }

  return (
    <CommitmentsDateGranularitySelector
      size="small"
      onChange={changeHandler}
      dateGranularity={dateGranularity}
    />
  );
};
