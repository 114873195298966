import { FC, Fragment } from "react";
import { Box, IconButton, Link, Stack } from "@mui/material";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { RecommendationLinkIcon } from "./RecommendationLinkIcon";
import { useHover } from "../../../../../../../../../../../utils/hooks/useHover.hook";
import { CopyIconButton } from "../../../../../../../../../../common/CopyIconButton";
import { TypographyWithTooltip } from "../../../../../../../../../../common/TypographyWithTooltip";
import {
  Recommendations,
  ResourceType,
} from "../../../../../../../../../../../services/cloudchipr.api";
import { Protection } from "../actions-menu-cell-v2/Protection";

interface IdentifierCellWithProtectedIconProps {
  data: string;
  link?: string;
  resourceType?: ResourceType;
  rightsizingRecommendations?: Recommendations["off_hours_recommendations"];
  offHoursRecommendations?: Recommendations["off_hours_recommendations"];
  accountId?: string;
  lockIconMessage?: string;
}

export const IdentifierCellWithProtectedIcon: FC<
  IdentifierCellWithProtectedIconProps
> = ({
  data,
  link,
  resourceType,
  rightsizingRecommendations,
  offHoursRecommendations,
  accountId,
  lockIconMessage,
}) => {
  const { ref, hovered } = useHover();

  if (!data) {
    return <Fragment>-</Fragment>;
  }

  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      ref={ref}
    >
      <Stack
        direction="row"
        overflow="hidden"
        alignItems="center"
        spacing={0.5}
      >
        <TypographyWithTooltip
          title={data}
          fontSize="inherit"
          sx={{ py: 0.75 }}
        />
        {lockIconMessage && (
          <Protection protectedBy={lockIconMessage} placement="right" />
        )}
      </Stack>

      <Stack direction="row">
        <Box
          sx={{
            display: hovered ? "flex" : "none",
            visibility: hovered ? "visible" : "hidden",
          }}
        >
          <CopyIconButton data={data} visible={hovered} sx={{ ml: 0.5 }} />

          {link && (
            <IconButton
              size="small"
              component={Link}
              href={link || ""}
              target="_blank"
            >
              <OpenInNewIcon fontSize="small" color="action" />
            </IconButton>
          )}
        </Box>

        <RecommendationLinkIcon
          resourceType={resourceType}
          accountId={accountId}
          rightsizingRecommendations={rightsizingRecommendations}
          offHoursRecommendations={offHoursRecommendations}
        />
      </Stack>
    </Stack>
  );
};
