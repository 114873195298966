import { createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../../../store";
import { resourceExplorerIdSelector } from "../../selectors/current-resource-explorer/resourceExplorerIdSelector";
import { resourceExplorerViewDataByIdSelector } from "../../selectors/resource-explorer-by-id/resourceExplorerViewDataByIdSelector";
import { resourceExplorerDefaultData } from "../../../../components/pages/resource-explorer/components/resource-explorer-card/utils/constants/common";
import {
  setResourceExplorerData,
  setResourceExplorerFilterTree,
} from "../../resourceExplorerSlice";
import { setResourceExplorerPathThunk } from "../widgets/path/setResourceExplorerPathThunk";
import { getResourceExplorerDataThunk } from "../widgets/getResourceExplorerDataThunk";
import { addIdsToFilterTreeItems } from "../../../../components/pages/common/filters-tree/utils/helpers/addIdsToFilterTreeItems";
import { resourceExplorerSessionStorageSavingDataKey } from "../../../../components/pages/resource-explorer/utils/constants";

export const resetResourceExplorerThunk = createAsyncThunk(
  "resourceExplorer/resetResourceExplorer",
  async (_, { dispatch, getState }) => {
    const state = getState() as RootState;

    const viewId = resourceExplorerIdSelector(state);
    const viewData = resourceExplorerViewDataByIdSelector(state, viewId);

    if (!viewData) {
      return;
    }

    const data =
      viewData.is_default && !viewData.data
        ? resourceExplorerDefaultData
        : { ...viewData.data, grouping: viewData.data.grouping ?? "service" };

    const filterTree = viewData.filter_tree;
    const mutatedFilters = filterTree
      ? addIdsToFilterTreeItems(filterTree)
      : undefined;
    dispatch(setResourceExplorerFilterTree(mutatedFilters));

    dispatch(setResourceExplorerData(data));
    dispatch(getResourceExplorerDataThunk());
    dispatch(setResourceExplorerPathThunk(false));
    sessionStorage.removeItem(resourceExplorerSessionStorageSavingDataKey);
  },
);
