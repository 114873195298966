import { FC, Fragment } from "react";
import { List } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import { resourceTypeSearchParams } from "./utils/constants";
import { LiveUsageMgmtNavItem } from "./components/live-usage-mgmt-nav-item/LiveUsageMgmtNavItem";
import { LiveUsageMgmtServicesCosts } from "./components/services-costs/LiveUsageMgmtServicesCosts";
import { useToggledResourceTypes } from "../../utils/hooks/useToggledResources";
import { useAppSelector } from "../../../../../store/hooks";
import { resourceGroups } from "../../../../../utils/constants/resources/resourceGroups";
import { ResourceType } from "../../../../../services/cloudchipr.api";
import { liveUsageMgmtProviderSelector } from "../../../../../store/live-usage-mgmt/selectors/store-selectors/liveUsageMgmtProviderSelector";

interface LiveUsageMgmtNavigationProps {
  collapsed: boolean;
}

export const LiveUsageMgmtNavigation: FC<LiveUsageMgmtNavigationProps> = ({
  collapsed,
}) => {
  const [searchParams] = useSearchParams();

  const provider = useAppSelector(liveUsageMgmtProviderSelector);
  const toggledResourceTypes = useToggledResourceTypes();

  const searchParamsResourceType = searchParams.get(
    resourceTypeSearchParams,
  ) as ResourceType;

  if (!provider) {
    return null;
  }

  const resources = resourceGroups.get(provider);
  const currentResourceType = searchParamsResourceType ?? resources?.[0].value;

  return (
    <Fragment>
      {!collapsed && <LiveUsageMgmtServicesCosts />}

      <List dense>
        {resources
          ?.filter((item) => !toggledResourceTypes.get(item.value))
          ?.map((resourceGroup) => (
            <LiveUsageMgmtNavItem
              provider={provider}
              key={resourceGroup.value}
              selectedResourceType={currentResourceType}
              sidebarCollapsed={collapsed}
              {...resourceGroup}
            />
          ))}
      </List>
    </Fragment>
  );
};
