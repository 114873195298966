import { FC } from "react";
import { Link, Stack, Typography } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";

interface RecommendationLinkPopoverContentProps {
  link?: string;
  title: string;
  maxSaving: string;
}

export const RecommendationLinkPopoverContent: FC<
  RecommendationLinkPopoverContentProps
> = ({ link, title, maxSaving }) => {
  if (!link) {
    return null;
  }
  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      px={2}
      py={1}
      minWidth={350}
    >
      <Link color="grey.800" component={RouterLink} to={link} target="_blank">
        <Typography variant="body2" color="text.secondary" fontWeight="medium">
          {title}
        </Typography>
      </Link>
      <Typography variant="body2" color="text.secondary" fontWeight="medium">
        to save{" "}
        <Typography
          component="span"
          variant="body2"
          color="text.primary"
          fontWeight="inherit"
        >
          {maxSaving}
        </Typography>
      </Typography>
    </Stack>
  );
};
