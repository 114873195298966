import { FC } from "react";
import {
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
  Legend,
  AreaChart,
  Tooltip,
} from "recharts";
import { LegendContent } from "./legendContent";
import { tooltipFormatter } from "./tooltipFormatter";
import { CustomizedYAxisTick } from "./CustomizedYAxisTick";
import { CustomizedXAxisTick } from "../../../../../common/CustomizedXAxisTick";
import { metricLabel } from "../../../../utils/helpers/metricLabel";
import { RightsizingRecommendationsChartColors } from "../../../../utils/constants/constants";
import { RightsizingRecommendationsChartData } from "../../../../utils/types/types";

interface RightsizingRecommendationsChartProps {
  data: RightsizingRecommendationsChartData[];
  currentType: string;
  recommendedType: string;
  metric: "average" | "maximum";
}

export const RightsizingRecommendationsChart: FC<
  RightsizingRecommendationsChartProps
> = ({ data, currentType, recommendedType, metric }) => {
  const yAxisCpuTicks = [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100];

  return (
    <ResponsiveContainer width="100%" height={400}>
      <AreaChart width={500} data={data}>
        <CartesianGrid vertical={false} strokeDasharray="3 3" opacity={0.5} />

        <XAxis
          dataKey="timestamp"
          height={60}
          interval={Math.floor(data.length / 7) - 1}
          tick={<CustomizedXAxisTick />}
        />

        <YAxis
          tick={<CustomizedYAxisTick />}
          interval={0}
          ticks={yAxisCpuTicks}
        />

        <Tooltip
          labelFormatter={tooltipFormatter}
          itemStyle={{ display: "none" }}
          contentStyle={{ padding: "6px 8px", borderRadius: 12 }}
        />

        <Legend
          content={
            <LegendContent
              currentType={currentType}
              recommendedType={recommendedType}
              metric={metricLabel(metric)}
            />
          }
        />
        <Area
          stroke={RightsizingRecommendationsChartColors.CpuArea}
          type="monotone"
          dataKey="cpu"
          fill={RightsizingRecommendationsChartColors.CpuArea}
          isAnimationActive={false}
        />

        <Area
          stroke={RightsizingRecommendationsChartColors.RecommendedSize}
          type="monotone"
          dataKey="recommended"
          fillOpacity={0.3}
          fill={RightsizingRecommendationsChartColors.RecommendedSize}
          isAnimationActive={false}
        />
      </AreaChart>
    </ResponsiveContainer>
  );
};
