import { FC } from "react";
import {
  Card,
  Container,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { BudgetsListRow } from "./BudgetsListRow";
import { useGetUsersMeOrganisationsCurrentBudgetsQuery } from "../../../../../services/cloudchipr.api";

export const BudgetsList: FC = () => {
  const { data } = useGetUsersMeOrganisationsCurrentBudgetsQuery();

  return (
    <Container sx={{ p: 1 }} disableGutters maxWidth={false}>
      <Card variant="outlined">
        <Table>
          <TableHead>
            <TableRow sx={{ bgcolor: "grey.50" }}>
              <TableCell>Name</TableCell>
              <TableCell>Budget</TableCell>
              <TableCell>View</TableCell>
              <TableCell>Threshold</TableCell>
              <TableCell>Period</TableCell>
              <TableCell>Progress</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.map((budget) => {
              return <BudgetsListRow budget={budget} key={budget.id} />;
            })}
          </TableBody>
        </Table>
      </Card>
    </Container>
  );
};
