import { getProviderName } from "./providers/getProviderName";
import { ProviderType } from "../../services/cloudchipr.api";

export const accountInfoDrawerContent = (provider: ProviderType) => {
  const providerName = getProviderName(provider, { title: true });

  return [
    {
      title: "Live tracked resources",
      children: [
        "Cloudchipr collects resource metadata every 15 minutes for all available accounts",
        "Our list of cloud provider services includes only the most commonly used services as defined by the industry",
        "The cost is determined by the current availability of resources and then converted into a monthly value",
      ],
    },
    {
      title: "Filtered resources",
      children: [
        "The cost of all filtered resources at the present moment",
        "Cloudchipr provides preconfigured filter sets tailored to each resource type by default",
        "Users have the flexibility to define their own filters according to their specific requirements",
      ],
    },
    {
      title: "Tracked total",
      children: [
        "The total cost of all collected resources is converted to its equivalent monthly value",
      ],
    },
    {
      title: "Trends",
      children: [
        "Trends display a monthly comparison of billing data as of today, compared to the same period in the previous month",
      ],
    },
    {
      title: `${providerName} charge as of last month`,
      children: [
        `This represents the costs collected from ${providerName} billing as the total amount billed for a specific account`,
      ],
    },
  ];
};
