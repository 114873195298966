import { createAsyncThunk } from "@reduxjs/toolkit";
import { WidgetCommitmentsUtilizationVisualization } from "../../../../../../services/cloudchipr.api";
import { RootState } from "../../../../../store";
import { commitmentUtilizationWidgetSetupSelector } from "../../../../selectors/setups/commitment-utilization/commitmentUtilizationWidgetSetupSelector";
import { setWidgetSetup } from "../../../../dashboardsSlice";

export const commitmentUtilizationWidgetVisualizationChangeThunk =
  createAsyncThunk(
    "dashboard/commitmentUtilizationWidgetVisualizationChange",
    async (
      visualization: WidgetCommitmentsUtilizationVisualization,
      { dispatch, getState },
    ) => {
      const state = getState() as RootState;
      const existingSetup = commitmentUtilizationWidgetSetupSelector(state);

      if (!existingSetup) {
        return;
      }

      dispatch(
        setWidgetSetup({
          ...existingSetup,
          visualization: {
            ...existingSetup.visualization,
            ...visualization,
          },
        }),
      );
    },
  );
