import { FC, useCallback, useMemo } from "react";
import { Button, Stack } from "@mui/material";
import { FormikHelpers } from "formik/dist/types";
import { filterGroupsNormalizer } from "./FilterDialogListItemRemoveButton";
import { FiltersInitialValues, isFilterGroup } from "../utils/types/types";
import {
  FilterGroup,
  ResourceFilters,
  ResourceType,
} from "../../../../../../services/cloudchipr.api";
import { useAppSelector } from "../../../../../../store/hooks";
import { createNewFilterTemplateByResourceType } from "../utils/helpers/createNewFilterTemplateByResourceType";
import { allowedFiltersByResourceTypeSelector } from "../../../../../../store/filters/selectors/allowedFiltersByResourceTypeSelector";

interface FilterDialogGroupListActionButtonsProps {
  indexOfGroup: number;
  defaultFilters: ResourceFilters;
  resourceType: ResourceType;
  setValues: FormikHelpers<FiltersInitialValues>["setValues"];
}

export const FilterDialogGroupListActionButtons: FC<
  FilterDialogGroupListActionButtonsProps
> = ({ setValues, resourceType, indexOfGroup, defaultFilters }) => {
  const allowedFilters = useAppSelector((state) =>
    allowedFiltersByResourceTypeSelector(state, resourceType),
  );

  const newFilter = useMemo(() => {
    return createNewFilterTemplateByResourceType(
      resourceType,
      defaultFilters,
      allowedFilters,
    );
  }, [resourceType, defaultFilters, allowedFilters]);

  const addFilterHandler = useCallback(() => {
    setValues((values) => {
      const newFilterIndex = (
        values.filter_groups?.[indexOfGroup] as FilterGroup
      ).at(-1) as number;

      if (!newFilter) {
        return values;
      }

      const filterItems = values.filter_items
        .map((filterItem, i) => {
          return i === newFilterIndex ? [filterItem, newFilter] : filterItem;
        })
        .flat();

      const filterGroups = values.filter_groups.map((filterGroup, index) => {
        if (index === indexOfGroup && isFilterGroup(filterGroup)) {
          return [...filterGroup, newFilterIndex];
        }

        return filterGroup;
      });

      const { normalizedFilterGroups, operators } = filterGroupsNormalizer(
        filterGroups,
        values.operators,
      );

      return {
        ...values,
        operators,
        filter_items: filterItems,
        filter_groups: normalizedFilterGroups,
      };
    });
  }, [setValues, indexOfGroup, newFilter]);

  const removeGroupHandler = useCallback(() => {
    setValues((values) => {
      if (values.filter_groups.length === 1) {
        return {
          ...values,
          operators: [],
          filter_items: [],
          filter_groups: [],
        };
      }

      let removingFilterIndexes: FilterGroup = [];

      const filterGroups = values.filter_groups.map((filterGroup, index) => {
        if (index === indexOfGroup && isFilterGroup(filterGroup)) {
          removingFilterIndexes = filterGroup;
          return [];
        }

        return filterGroup;
      });

      const { normalizedFilterGroups, operators } = filterGroupsNormalizer(
        filterGroups,
        values.operators,
      );

      return {
        ...values,
        filter_items: values.filter_items.filter(
          (_, index) => !removingFilterIndexes.includes(index),
        ),
        operators,
        filter_groups: normalizedFilterGroups,
      };
    });
  }, [setValues, indexOfGroup]);

  return (
    <Stack direction="row" justifyContent="space-between" pt={1}>
      <Button variant="text" onClick={addFilterHandler} size="small">
        + Add Filter
      </Button>

      <Button
        size="small"
        variant="text"
        color="tertiary"
        sx={{
          "&:hover": { textDecoration: "underline", bgcolor: "transparent" },
        }}
        onClick={removeGroupHandler}
      >
        Clear group
      </Button>
    </Stack>
  );
};
