import { FC, Fragment } from "react";
import { Typography } from "@mui/material";
import { MissingPermissionsRecheck } from "../../../../../common/MissingPermissionsRecheck";
import { useGetUsersMeProvidersGcpAccountsByAccountIdMissingRolesQuery } from "../../../../../../../../../../../../services/cloudchipr.api";
import { CommandSnippet } from "../../../../../../../../../../common/connectors/components/CommandSnippet";

export interface GcpCliTabProps {
  accountId: string;
  serviceAccount?: string;
  projectId?: string;
}
export const GcpCliTab: FC<GcpCliTabProps> = ({
  accountId,
  serviceAccount,
  projectId,
}) => {
  const { data, refetch } =
    useGetUsersMeProvidersGcpAccountsByAccountIdMissingRolesQuery(
      { accountId: accountId || "" },
      { skip: !accountId },
    );

  if (!data?.length) {
    return null;
  }

  return (
    <Fragment>
      <Typography variant="body1">
        To assign role, please run the following command in your terminal.
      </Typography>

      {data?.map((role) => (
        <CommandSnippet
          key={role.name}
          code={`gcloud projects add-iam-policy-binding ${projectId} --member serviceAccount:${serviceAccount} --role "${role.name}"`}
        >
          gcloud projects add-iam-policy-binding{" "}
          <Typography
            fontFamily="monospace"
            color="warning.light"
            component="span"
          >
            {projectId}
          </Typography>{" "}
          --member serviceAccount:{serviceAccount} --role "{role.name}"
        </CommandSnippet>
      ))}

      <MissingPermissionsRecheck refetch={refetch} accountId={accountId} />
    </Fragment>
  );
};
