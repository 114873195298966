import { FC } from "react";
import { Button, Stack } from "@mui/material";
import { AzureAssignRolesStepContent } from "../../common/connectors/components/azure/AzureAssignRolesStepContent";
import { useAssignRolesHook } from "../../common/connectors/components/azure/hooks/useAssignRoles.hook";
import { PollingProgress } from "../../common/connectors/components/PollingProgress";

export const AzureAssignRolesStep: FC = () => {
  const { prevHandler, tab, setTab, isPolling } = useAssignRolesHook();

  return (
    <div>
      <AzureAssignRolesStepContent value={tab} onChange={setTab} />
      <PollingProgress />
      <Stack
        direction="row"
        alignItems="end"
        justifyContent="end"
        gap={2}
        mt={2}
      >
        <Button onClick={prevHandler} variant="text" disabled={isPolling}>
          Prev
        </Button>
      </Stack>
    </div>
  );
};
