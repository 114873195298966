import { FC, Fragment, useCallback, useMemo } from "react";
import { Divider, ListSubheader, Tooltip, Typography } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { CleanV2ActionItem } from "./CleanV2ActionItem";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../../../../../../store/hooks";
import {
  CleanActionTypes,
  getResourcesCleanActionTypes,
} from "../../../../../../../../../../../../../utils/clean-options";
import {
  ProviderAccessType,
  ResourceType,
} from "../../../../../../../../../../../../../services/cloudchipr.api";
import { liveUsageMgmtAccountsVisibilitiesByResourceTypeSelector } from "../../../../../../../../../../../../../store/live-usage-mgmt/selectors/accounts-data/liveUsageMgmtAccountsVisibilitiesByResourceTypeSelector";
import { setCleanV2ActionType } from "../../../../../../../../../../../../../store/clean-v2/cleanV2Slice";
import { createV2SingleCleanThunk } from "../../../../../../../../../../../../../store/clean-v2/thunks/thunks/createV2SingleCleanThunk";
import { isResourceNotCleanable } from "../../../../../../../../../../../../../store/clean-v2/utils/helpers";

interface CleanActionItemsProps {
  resourceType: ResourceType;
  id: string;
  isProtected: boolean;
  inProgress?: boolean;
  unavailableActions?: string[];
  closeMenu(): void;
  accessType: ProviderAccessType;
}

export const CleanV2ActionItems: FC<CleanActionItemsProps> = ({
  unavailableActions,
  inProgress,
  isProtected,
  id,
  resourceType,
  closeMenu,
  accessType,
}) => {
  const dispatch = useAppDispatch();

  const visibility = useAppSelector((state) =>
    liveUsageMgmtAccountsVisibilitiesByResourceTypeSelector(
      state,
      resourceType,
    ),
  );

  const options = useMemo(() => {
    if (!visibility?.actions) {
      return null;
    }

    return getResourcesCleanActionTypes(resourceType).filter((option) => {
      return visibility?.actions?.[option.type] !== null;
    });
  }, [resourceType, visibility]);

  const cleanItemClickHandler = useCallback(
    (actionType: CleanActionTypes) => {
      dispatch(setCleanV2ActionType(actionType));
      dispatch(
        createV2SingleCleanThunk({
          resourceType,
          resourceId: id,
        }),
      );
      closeMenu();
    },
    [dispatch, resourceType, closeMenu, id],
  );

  if (!options?.length) {
    return null;
  }

  return (
    <Fragment>
      <ListSubheader>
        <Typography fontSize="smaller">
          ACTIONS
          {(accessType === "read" || isProtected) && (
            <Tooltip
              arrow
              title={
                accessType === "read"
                  ? "Please switch to Read/Write access mode to enable actions."
                  : "Actions can not be performed on protected resources."
              }
            >
              <InfoOutlinedIcon
                sx={{
                  verticalAlign: "middle",
                  color: "text.secondary",
                  ml: 1,
                }}
                fontSize="small"
              />
            </Tooltip>
          )}
        </Typography>
      </ListSubheader>

      {options.map((item) => {
        return (
          <CleanV2ActionItem
            key={item.type}
            data={item}
            disabled={isResourceNotCleanable(
              {
                isProtected,
                markedForDeletion: !!inProgress,
                notAllowedActions: unavailableActions,
                accessType,
              },
              item.type,
            )}
            resourceType={resourceType}
            onClick={cleanItemClickHandler}
          />
        );
      })}

      <Divider />
    </Fragment>
  );
};
