import { FC } from "react";
import Typography from "@mui/material/Typography";
import { ExampleTypography } from "./ExampleTypography";
import { useAppSelector } from "../../../../../../../store/hooks";
import { reportsDataPropertyByKeySelector } from "../../../../../../../store/reports/selector/reportsDataPropertyByKeySelector";

export const ReportsCustomMessageSubject: FC = () => {
  const source = useAppSelector(
    reportsDataPropertyByKeySelector("source_type"),
  );

  return (
    <Typography variant="body1">
      Subject:{" "}
      <ExampleTypography>
        {source === "dashboard" ? "dashboard" : "resource explorer view"}
      </ExampleTypography>{" "}
      report for <ExampleTypography>date</ExampleTypography>
    </Typography>
  );
};
