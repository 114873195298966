import { overviewDataSelector } from "./overviewDataSelector";
import { RootState } from "../../../store";
import { ResourceTypeCoverage } from "../../../../services/cloudchipr.api";

export const overviewCoveragesSelector = (
  state: RootState,
): ResourceTypeCoverage[] | null => {
  const data = overviewDataSelector(state);

  if (!data?.coverage) {
    return null;
  }

  return [...data.coverage].sort(
    (item1, item2) =>
      (item2.uncovered_usage ?? 0) - (item1.uncovered_usage ?? 0),
  );
};
