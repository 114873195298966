import { RowSelectionState, Updater } from "@tanstack/react-table";
import { RowSelectionConfig } from "../types/prop-types";

export function useRowSelection(
  data: any[],
  rowSelectionConfig?: RowSelectionConfig,
) {
  const enabled = !!rowSelectionConfig;

  if (!enabled) {
    return {
      enableRowSelection: false,
      rowSelection: {},
      onRowSelectionChange: undefined,
    };
  }

  const selectedRows = getInitialSelectedRows(
    data,
    rowSelectionConfig?.initialSelectedItems,
  );

  const rowSelectHandler = (updater: Updater<RowSelectionState>) => {
    let newState = updater as RowSelectionState;
    if (typeof updater === "function") {
      newState = updater(selectedRows);
    }

    rowSelectionConfig?.rowSelectionChange?.(newState);
  };

  return {
    enableRowSelection: true,
    rowSelection: selectedRows,
    onRowSelectionChange: rowSelectHandler,
  };
}

const getInitialSelectedRows = (
  data: any[],
  initialSelectedItems: Record<string, boolean> | undefined,
): RowSelectionState => {
  if (!initialSelectedItems) {
    return {};
  }

  return data.reduce((result: RowSelectionState, item) => {
    if (initialSelectedItems?.[item.id]) {
      result[item.id] = true;
    }

    return result;
  }, {});
};
