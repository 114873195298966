import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { Box } from "@mui/material";
import { AgGridReact, AgGridReactProps } from "@ag-grid-community/react";
import { grey, indigo } from "@mui/material/colors";
import {
  ColumnRowGroupChangedEvent,
  GetMainMenuItemsParams,
  ModuleRegistry,
  SelectionChangedEvent,
} from "@ag-grid-community/core";
import { RowGroupingModule } from "@ag-grid-enterprise/row-grouping";
import { AG_GRID_LOCALE_EN } from "@ag-grid-community/locale";
import { MenuModule } from "@ag-grid-enterprise/menu";
import { ColDef } from "@ag-grid-community/core/dist/types/src/entities/colDef";
import { getTasksGridColumns } from "./tasksGridColumns";
import {
  autoGroupColumnId,
  getAutoGroupColumnDef,
} from "./auto-group-column/getAutoGroupColumnDef";
import { agGridOverwrittenIcons } from "../../../automations/workflows/data-grid/utils/constans/grid-icons";
import { Task } from "../../../../../services/cloudchipr.api";
import { useTaskManagementContext } from "../TaskManagementProvider";
import { useAppDispatch, useAppSelector } from "../../../../../store/hooks";
import { taskAvailablePropertiesSelector } from "../../../../../store/task-management/selectors/properties/taskAvailablePropertiesSelector";
import { setTaskManagementSelectedTasks } from "../../../../../store/task-management/taskManagementSlice";
import type {
  FilterChangedEvent,
  GridReadyEvent,
  SortChangedEvent,
} from "@ag-grid-community/core/dist/types/src/events";

const gridCustomLocale = {
  ...AG_GRID_LOCALE_EN,
  rowGroupColumnsEmptyMessage: "Drag column here to set row groups",
};

ModuleRegistry.registerModules([RowGroupingModule, MenuModule]);

interface TasksGridProps {
  tasks: Task[];
  renderRows: boolean;
  singleGroup: boolean;
}

const rowHeight = 53;
const gridMaxHeight = rowHeight * 20;

export const TasksGrid: FC<TasksGridProps> = ({
  tasks,
  renderRows,
  singleGroup,
}) => {
  const { setGridApi } = useTaskManagementContext();
  const dispatch = useAppDispatch();
  const [rowsCount, setRowsCount] = useState(0);
  const [rowGroupCountReached, setRowGroupCountReached] = useState(false);

  const taskAvailableProperties = useAppSelector(
    taskAvailablePropertiesSelector,
  );

  const columnDefs = useMemo(() => {
    if (!renderRows) {
      return [];
    }

    return getTasksGridColumns(taskAvailableProperties);
  }, [renderRows, taskAvailableProperties]);

  const defaultColDef = useMemo<ColDef>(() => {
    return {
      minWidth: 150,
      unSortIcon: true,
      enableRowGroup: !rowGroupCountReached,
    };
  }, [rowGroupCountReached]);

  const onGridReady = useCallback(
    ({ api }: GridReadyEvent) => {
      setGridApi(api);
      setRowsCount(api?.getDisplayedRowCount() ?? 0);
    },
    [setGridApi],
  );

  const onColumnRowGroupChanged = useCallback(
    (params: ColumnRowGroupChangedEvent) => {
      const rowGroupColumns = params.api.getRowGroupColumns();
      setRowGroupCountReached(rowGroupColumns?.length > 2);
    },
    [],
  );

  const getMainMenuItems = useCallback(
    (params: GetMainMenuItemsParams) => {
      if (rowGroupCountReached) {
        return params.defaultItems.filter((item) => item !== "rowGroup");
      }

      return params.defaultItems;
    },
    [rowGroupCountReached],
  );

  const sortChangeHandler = useCallback(({ api }: SortChangedEvent) => {
    const sortState = api
      .getColumnState()
      .filter(({ sort }) => sort !== null)
      .map((s) => ({ colId: s.colId, sort: s.sort, sortIndex: s.sortIndex }));

    const sortedByGrouping = sortState.some(
      ({ colId }) => colId === autoGroupColumnId,
    );

    if (!sortedByGrouping) {
      sortState.push({
        colId: autoGroupColumnId,
        sort: "asc",
        sortIndex: sortState.length,
      });
    }

    api.applyColumnState({ state: sortState });
  }, []);

  const onSelectionChanged = useCallback(
    ({ api }: SelectionChangedEvent) => {
      dispatch(setTaskManagementSelectedTasks(api.getSelectedRows()));
    },
    [dispatch],
  );

  const onFilterChanged = useCallback(({ api }: FilterChangedEvent) => {
    setRowsCount(api?.getDisplayedRowCount() ?? 0);
  }, []);

  const autoGroupColumnDef = useMemo(
    () => getAutoGroupColumnDef(taskAvailableProperties),
    [taskAvailableProperties],
  );

  useEffect(() => {
    setRowsCount(tasks.length);
  }, [tasks.length]);

  return (
    <Box
      sx={wrapperStyles}
      className="ag-theme-quartz"
      maxHeight={singleGroup ? undefined : gridMaxHeight}
      height={singleGroup ? "90dvh" : rowHeight * (rowsCount + 1) + 20}
    >
      <AgGridReact
        // filter
        onFilterChanged={onFilterChanged}
        // sort
        onSortChanged={sortChangeHandler}
        // columns
        columnDefs={columnDefs}
        defaultColDef={defaultColDef}
        // data
        rowData={tasks}
        rowHeight={rowHeight}
        getRowId={({ data }) => data?.id}
        // enableness
        enableCellTextSelection
        suppressMovableColumns
        // grouping
        rowGroupPanelShow="always"
        groupDefaultExpanded={1}
        autoGroupColumnDef={autoGroupColumnDef}
        onColumnRowGroupChanged={onColumnRowGroupChanged}
        // context menu
        allowContextMenuWithControlKey
        getMainMenuItems={getMainMenuItems}
        // miscellaneous
        onGridReady={onGridReady}
        localeText={gridCustomLocale}
        gridOptions={gridOptions}
        // row selection
        onSelectionChanged={onSelectionChanged}
        selectionColumnDef={selectionColumnDef}
        //  rowSelection={{
        //   mode: "multiRow",
        //   selectAll: "filtered",
        // }}
      />
    </Box>
  );
};

const selectionColumnDef: AgGridReactProps["selectionColumnDef"] = {
  pinned: "left",
};

const iconsStyles = {
  "& span.ag-drag-handle ": { lineHeight: 0 },
  "& span.ag-menu-option-part.ag-menu-option-icon": { lineHeight: 0 },
  "& span.ag-column-drop-cell-button.ag-column-drop-horizontal-cell-button": {
    lineHeight: 0,
  },
  "& .ag-column-drop-title-bar.ag-column-drop-horizontal-title-bar": {
    lineHeight: 0,
  },
};

const columnSortIconStyles = {
  "& .ag-sort-indicator-icon": { visibility: "hidden" },
  "& .ag-header-cell-sortable.ag-header-active:hover .ag-sort-indicator-icon": {
    visibility: "visible",
  },

  "& .ag-header-cell-sorted-desc .ag-sort-indicator-icon": {
    visibility: "visible !important",
  },
  "& .ag-header-cell-sorted-asc .ag-sort-indicator-icon": {
    visibility: "visible !important",
  },

  '& .ag-header-cell[aria-sort="ascending"] .ag-sort-indicator-icon': {
    visibility: "visible !important",
  },
  '& .ag-header-cell[aria-sort="descending"] .ag-sort-indicator-icon': {
    visibility: "visible !important",
  },

  "& .ag-sort-indicator-container .ag-sort-order": {
    display: "none",
  },
};

const rowHoverStyles = {
  "--ag-active-color": grey[100],
  "& .ag-row-hover": { backgroundColor: grey[100] },
};

const resizeHandleStyles = {
  "& .ag-header-cell-resize": { width: 20, visibility: "hidden" },
  "& .ag-header-cell:hover .ag-header-cell-resize": { visibility: "visible" },
  "--ag-header-column-resize-handle-height": "80%",
};

const checkboxStyles = {
  ".ag-checkbox-input-wrapper.ag-checked::after": {
    color: `${indigo["500"]} !important`,
  },
  ".ag-checkbox-input-wrapper.ag-indeterminate::after": {
    color: `${indigo["500"]} !important`,
  },
};

const expanderStyles = {
  "& .ag-group-expanded,.ag-group-contracted": {
    padding: "4px",
    overflow: "hidden",
    borderRadius: "50%",
    height: "24px !important",
    width: "24px !important",
    "&:hover": { background: grey[300] },
  },
};

const wrapperStyles = {
  width: "100%",
  "& .ag-cell-wrapper": { height: "100%", alignItems: "center" },
  "& .name-cell-classname span": { height: "100%" },
  "& .ag-root-wrapper": { borderRadius: 0, border: "none" },
  "--ag-header-height": `${rowHeight}px`,
  "--ag-header-background-color": grey[100],

  ...iconsStyles,
  ...expanderStyles,
  ...columnSortIconStyles,
  ...rowHoverStyles,
  ...resizeHandleStyles,
  ...checkboxStyles,
};

const gridOptions = {
  icons: agGridOverwrittenIcons,
};
