import { FC, useCallback } from "react";
import { Stack } from "@mui/material";
import { ResourceExplorerDataGridViewTab } from "./ResourceExplorerDataGridViewTab";
import { ResourceExplorerDataGridView } from "../../../../../../../../../store/resource-explorer/utils/types/resourceExplorerDataGrid";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../../store/hooks";
import { resourceExplorerDataGridViewSelector } from "../../../../../../../../../store/resource-explorer/selectors/current-resource-explorer/resourceExplorerDataGridViewSelector";
import { setResourceExplorerGridDataViewTypeThunk } from "../../../../../../../../../store/resource-explorer/thunks/resource-explorer/setResourceExplorerGridDataViewTypeThunk";

export const ResourceExplorerDataGridViewTabs: FC = () => {
  const dispatch = useAppDispatch();

  const dataGridView = useAppSelector(resourceExplorerDataGridViewSelector);

  const dataGridColumnsViewChangeHandler = useCallback(
    (value: ResourceExplorerDataGridView) => {
      if (dataGridView === value) {
        return;
      }
      dispatch(setResourceExplorerGridDataViewTypeThunk(value));
    },
    [dispatch, dataGridView],
  );

  return (
    <Stack direction="row" bgcolor="grey.200" borderRadius={1} p={0.25}>
      <ResourceExplorerDataGridViewTab
        value="total"
        onChange={dataGridColumnsViewChangeHandler}
        selected={dataGridView === "total"}
      />
      <ResourceExplorerDataGridViewTab
        value="granular"
        onChange={dataGridColumnsViewChangeHandler}
        selected={dataGridView === "granular"}
      />
    </Stack>
  );
};
