import { createDraftSafeSelector } from "@reduxjs/toolkit";
import { getWeeklyRecommendationComputeDismissedSelector } from "../../recommendationWeeklyQuerySelector";
import { OrganisationWeeklyOffHoursRecommendation } from "../../../../../../services/cloudchipr.api";

export const recommendationComputeWeeklyDismissedDataSelector =
  createDraftSafeSelector(
    [getWeeklyRecommendationComputeDismissedSelector],
    (response): OrganisationWeeklyOffHoursRecommendation[] =>
      response?.data ?? [],
  );
