import { ColumnDef, Table } from "@tanstack/react-table";

const validateForColumnDnD = (
  needToValidate: boolean,
  columns: ColumnDef<any>[],
) => {
  if (!needToValidate) return;

  const someColumnHaveNoId = columns.some(({ id }) => !id);
  if (someColumnHaveNoId) {
    console.error(
      'Warning: Each column in the columns list should have a unique "id" prop for correct DnD work.',
    );
  }
};

const validateForRowDnD = (table: Table<any>) => {
  const rows = table.getRowModel().rows;

  const needToWarn = rows.some(({ original }) => !original.id);
  if (needToWarn) {
    console.error(
      'Warning: Each row in the provided data should have a unique "id" property.',
    );
  }
};
const validateForContextProvider = (
  needToValidate: boolean,
  wrappedWithProvider: boolean,
) => {
  if (!needToValidate) {
    return;
  }

  if (!wrappedWithProvider) {
    console.error(
      `The "DataGrid" component must be wrapped by a "DataGridProvider" for the "row-expanding" and "row-selection" functions to work correctly.`,
    );
  }
};
const validateForColumnsShowHide = (
  needToValidate: boolean,
  table: Table<any>,
) => {
  if (!needToValidate) return;

  table.getHeaderGroups().forEach((headersGroup) => {
    headersGroup.headers.forEach((header) => {
      const headerCell = header.column.columnDef.header;
      const headerTitle = header.column.columnDef.meta?.headerTitle;
      const hideFromSettings = header.column.columnDef.meta?.hideFromSettings;

      if (
        headerCell instanceof Function &&
        (hideFromSettings ? false : !headerTitle)
      ) {
        console.error(
          `Warning. Each column in the column setup data must have a "headerTitle" of type string, in order to display it in the column's visibility component. You can add it by passing "meta" object in column data. \n See: "${header.column.id}" column setup.\n\n`,
        );
      }
    });
  });
};

const validateForColumnsNumberFilter = (
  needToValidate: boolean,
  table: Table<any>,
) => {
  if (!needToValidate) return;

  let isAlreadyLogged = false;

  table.getAllColumns()?.forEach((col) => {
    if (isAlreadyLogged) return;

    if (col.getFilterFn()?.name === "I") {
      // if the column value is a number and the filterFn is not manually set, that filter type will be I (integer)
      console.error(
        `Warning: For columns with type "number" the filter "inNumberRange" will be applied by default, if you want to change it, set "filterFn".
          See filter types:  https://tanstack.com/table/v8/docs/api/features/filters#filter-functions`,
      );
      isAlreadyLogged = true;
    }
  });
};

export const validateDataGridPassedData = ({
  columns,
  table,
  enableColumnsDnD,
  enableColumnsFilter,
  enableColumnVisibility,
  wrappedWithProvider,
  enableExpandingRows,
  enableRowSelection,
}: {
  columns: ColumnDef<any>[];
  table: Table<any>;
  enableColumnsDnD: boolean;
  enableColumnsFilter: boolean;
  enableColumnVisibility: boolean;
  wrappedWithProvider: boolean;
  enableExpandingRows: boolean;
  enableRowSelection: boolean;
}) => {
  validateForRowDnD(table);
  validateForColumnDnD(enableColumnsDnD, columns);
  validateForColumnsNumberFilter(enableColumnsFilter, table);
  validateForColumnsShowHide(enableColumnVisibility, table);
  validateForContextProvider(
    enableExpandingRows || enableRowSelection,
    wrappedWithProvider,
  );
};
