import { FC, useCallback } from "react";
import { useAppSelector } from "../../../../../../../../../../../../../store/hooks";
import { ConfirmationDialog } from "../../../../../../../../../../../../common/modals/ConfirmationDialog";
import { liveUsageMgmtAccountIdsSelector } from "../../../../../../../../../../../../../store/live-usage-mgmt/selectors/store-selectors/liveUsageMgmtAccountIdsSelector";

interface ProtectDialogProps {
  isProtected: boolean;
  onClose(): void;
  onConfirm?: () => void;
  isLoading: boolean;
}

export const ProtectDialogV2: FC<ProtectDialogProps> = ({
  onClose,
  isProtected,
  onConfirm,
  isLoading,
}) => {
  const accountIds = useAppSelector(liveUsageMgmtAccountIdsSelector);

  const handleProtectClick = useCallback(() => {
    if (!accountIds) {
      return;
    }
    onConfirm?.();
  }, [accountIds, onConfirm]);

  return (
    <ConfirmationDialog
      open
      title={!isProtected ? "Enable Protection?" : "Disable Protection?"}
      dialogContent={
        !isProtected
          ? "Resources with Enabled Protection will be untouched by Cloudchipr."
          : "Users with sufficient permissions can take actions on resources with Disabled Protection."
      }
      CancelButtonProps={{
        onClick: onClose,
      }}
      ConfirmButtonProps={{
        children: !isProtected ? "Enable" : "Disable",
        onClick: handleProtectClick,
        loading: isLoading,
      }}
      onClose={onClose}
    />
  );
};
