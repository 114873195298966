import { createDraftSafeSelector } from "@reduxjs/toolkit";
import { organisationQuarterStartMonthSelector } from "../org-current-settings/organisationQuarterStartMonthSelector";
import { PreDefinedRanges } from "../../../../components/common/date-range-picker/utils/constants/types";
import { preDefinedRanges } from "../../../../components/common/date-range-picker/utils/preDefinedRanges/preDefinedRanges";
import { getQuarterlyPreDefinedRange } from "../../../../components/common/date-range-picker/utils/preDefinedRanges/getQuarterlyPreDefinedRange";

export const allDateRangesByQuarterStartMonthSelector = createDraftSafeSelector(
  [organisationQuarterStartMonthSelector],
  (data): PreDefinedRanges => {
    const ranges: PreDefinedRanges = {
      past_seven_days: preDefinedRanges.past_seven_days,
      past_fourteen_days: preDefinedRanges.past_fourteen_days,
      month_to_date: preDefinedRanges.month_to_date,
      past_thirty_days: preDefinedRanges.past_thirty_days,
      last_one_month: preDefinedRanges.last_one_month,
      last_three_months: preDefinedRanges.last_three_months,
      past_forty_five_days: preDefinedRanges.past_forty_five_days,
      past_ninety_days: preDefinedRanges.past_ninety_days,
      past_one_hundred_eighty_days:
        preDefinedRanges.past_one_hundred_eighty_days,
      year_to_date: preDefinedRanges.year_to_date,
      last_six_months: preDefinedRanges.last_six_months,
      last_twelve_months: preDefinedRanges.last_twelve_months,
    };

    const quarterToDate = getQuarterlyPreDefinedRange("quarter_to_date", data);
    const lastQuarter = getQuarterlyPreDefinedRange("last_quarter", data);

    if (quarterToDate) {
      ranges.quarter_to_date = quarterToDate;
    }
    if (lastQuarter) {
      ranges.last_quarter = lastQuarter;
    }

    return ranges;
  },
);
