import { FC } from "react";
import { Link, TableCell, TableRow, Typography } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { ProgressCell } from "./ProgressCell";
import { PeriodCell } from "./PeriodCell";
import { BudgetListActionsCell } from "./BudgetListActionsCell";
import { money } from "../../../../../utils/numeral/money";
import { TypographyWithTooltip } from "../../../../common/TypographyWithTooltip";
import { Budget } from "../../../../../services/cloudchipr.api";
import { useAppAbility } from "../../../../../services/permissions";

interface BudgetsListRowProps {
  budget: Budget;
}

export const BudgetsListRow: FC<BudgetsListRowProps> = ({ budget }) => {
  const { can } = useAppAbility();
  const canEditBudget = can("edit", "budget");

  return (
    <TableRow>
      <TableCell>
        {canEditBudget ? (
          <Link
            component={RouterLink}
            to={`/budgets/edit/${budget.id}`}
            color="primary"
          >
            <TypographyWithTooltip
              maxWidth={200}
              variant="body1"
              title={budget.name}
            />
          </Link>
        ) : (
          <TypographyWithTooltip
            maxWidth={200}
            variant="body1"
            title={budget.name}
          />
        )}
      </TableCell>

      <TableCell>
        <Typography variant="body2" fontWeight="medium">
          {money(budget.amount)}
        </Typography>
      </TableCell>

      <TableCell>
        <Link
          component={RouterLink}
          to={`/resource-explorer/${budget.view.id}`}
          color="primary"
        >
          <TypographyWithTooltip
            maxWidth={200}
            variant="body1"
            title={budget.view.name}
          />
        </Link>
      </TableCell>

      <TableCell>
        <Typography variant="body2">
          {budget.thresholds.map(({ percent }) => `${percent}%`).join(", ")}
        </Typography>
      </TableCell>

      <TableCell>
        <PeriodCell period={budget.period} startDate={budget.start_date} />
      </TableCell>

      <TableCell>
        <ProgressCell
          progress={budget.progress}
          amount={budget.amount}
          period={budget.period}
        />
      </TableCell>

      <TableCell>
        <BudgetListActionsCell id={budget.id} name={budget.name} />
      </TableCell>
    </TableRow>
  );
};
