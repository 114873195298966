import { commitmentsTypeTabSelector } from "./commitmentsTypeTabSelector";
import { RootState } from "../../../store";
import { reservationsCSVDataSelector } from "../reservations/data/csv/reservationsCSVDataSelector";
import { utilizationAndCoverageCSVDataSelector } from "../coverage-and-utilization/data/csv/utilizationAndCoverageCSVDataSelector";

export const commitmentsCsvDataSelector = (state: RootState) => {
  const tab = commitmentsTypeTabSelector(state);

  if (tab === "reservation") {
    return reservationsCSVDataSelector(state);
  }
  if (tab === "savings_plan") {
    return utilizationAndCoverageCSVDataSelector(state);
  }
};
