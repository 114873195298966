import { FC, Fragment } from "react";
import {
  Button,
  CircularProgress,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import { TestMessageInfo } from "./TestMessageInfo";

interface MessageActionsProps {
  isLoading: boolean;
  status: string;
  error: string;
  sendMessageHandler(): void;
  isDisabled: boolean;
}

export const MessageActions: FC<MessageActionsProps> = ({
  isLoading,
  status,
  error,
  sendMessageHandler,
  isDisabled,
}) => {
  return (
    <Fragment>
      {!status && !isLoading && (
        <Button
          size="small"
          disabled={isDisabled}
          onClick={sendMessageHandler}
          endIcon={
            error && (
              <Tooltip title={error} arrow>
                <WarningAmberIcon color="error" fontSize="small" />
              </Tooltip>
            )
          }
        >
          SEND
        </Button>
      )}

      {status && (
        <Fragment>
          {status === "success" && <TestMessageInfo />}
          {status === "error" && <TestMessageInfo error={error} />}
        </Fragment>
      )}

      {!status && isLoading && (
        <Stack spacing={1} alignItems="center" direction="row">
          <Typography variant="button" color="text.disabled">
            Sending
          </Typography>
          <CircularProgress disableShrink size={15} />
        </Stack>
      )}
    </Fragment>
  );
};
