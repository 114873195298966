import { createAsyncThunk } from "@reduxjs/toolkit";
import { enqueueSnackbar } from "notistack";
import {
  cloudChiprApi,
  ProviderType,
} from "../../../../services/cloudchipr.api";

interface ThunkArgs {
  provider: ProviderType;
  accountId: string;
  resourceId: string;
}

export const getResourceExplorerSingleResourceDataThunk = createAsyncThunk(
  "resourceExplorer/getResourceExplorerSingleResourceDataThunk",
  async ({ provider, resourceId, accountId }: ThunkArgs, { dispatch }) => {
    const {
      getUsersMeOrganisationsCurrentAccountsByAccountIdProvidersAndProviderResourceExplorerResources,
    } = cloudChiprApi.endpoints;

    try {
      await dispatch(
        getUsersMeOrganisationsCurrentAccountsByAccountIdProvidersAndProviderResourceExplorerResources.initiate(
          {
            provider,
            resourceId,
            accountId,
          },
        ),
      ).unwrap();
    } catch (e) {
      // @ts-expect-error TODO: return to this after adding error type
      const errMessage = e?.data?.message || "Something went wrong";
      enqueueSnackbar(errMessage, {
        variant: "snackbarAlert",
        AlertSnackBarProps: {
          severity: "error",
        },
      });
    }
  },
);
