import { FC } from "react";
import numeral from "numeral";
import { Typography } from "@mui/material";

interface NumeralCellProps {
  data: string | number | null;
  format?: string;
  startAdornment?: string;
  endAdornment?: string;
}

export const NumeralCell: FC<NumeralCellProps> = ({
  data,
  format,
  startAdornment,
  endAdornment,
}) => {
  if (data === null) {
    return <Typography variant="body2">N/A</Typography>;
  }
  const result = format ? numeral(data).format(format) : data;
  return (
    <div>
      {startAdornment}
      {result}
      {endAdornment}
    </div>
  );
};
