import { createAsyncThunk } from "@reduxjs/toolkit";
import { TrendType } from "../../../../../../services/cloudchipr.api";
import { setWidgetSetup } from "../../../../dashboardsSlice";
import { RootState } from "../../../../../store";
import { costBreakdownWidgetSetupSelector } from "../../../../selectors/setups/cost-breakdown/costBreakdownWidgetSetupSelector";
import { getCostBreakdownWidgetSetupDataThunk } from "../getCostBreakdownWidgetSetupDataThunk";

export const costBreakdownTrendTypeChangeThunk = createAsyncThunk(
  "dashboards/costBreakdownTrendTypeChange",
  async (trendType: TrendType, { dispatch, getState }) => {
    const state = getState() as RootState;
    const existingSetup = costBreakdownWidgetSetupSelector(state);

    if (!existingSetup) {
      return;
    }
    dispatch(
      setWidgetSetup({
        ...existingSetup,
        trendType,
      }),
    );

    dispatch(getCostBreakdownWidgetSetupDataThunk());
  },
);
