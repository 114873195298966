import { FC, ReactNode, useCallback, useEffect, useState } from "react";
import {
  Box,
  Card,
  Chip,
  Collapse,
  FormControlLabel,
  Stack,
  Switch,
  Tooltip,
  Typography,
} from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

interface CollapseSwitcherProps {
  beta?: boolean;
  disabled?: boolean;
  info?: string;
  title: string;
  children: ReactNode;
  checkedOverride: boolean;
  onCollapse?(collapsed: boolean): void;
}

export const CollapseSwitcher: FC<CollapseSwitcherProps> = ({
  beta,
  info,
  children,
  onCollapse,
  title,
  disabled,
  checkedOverride,
}) => {
  const [checked, setChecked] = useState(false);

  const collapseHandler = useCallback(() => {
    setChecked((checked) => {
      onCollapse?.(!checked);

      return !checked;
    });
  }, [onCollapse]);

  useEffect(() => {
    if (checkedOverride !== checked) {
      setChecked(checkedOverride);
    }
  }, [checked, checkedOverride]);

  return (
    <Card variant="outlined" sx={{ p: 1.5, borderRadius: 1 }}>
      <FormControlLabel
        disabled={disabled}
        onChange={collapseHandler}
        control={<Switch size="small" checked={checked} />}
        label={
          <Stack direction="row" spacing={1} alignItems="center">
            <Typography variant="body1">{title}</Typography>

            {info && (
              <Tooltip arrow title={info} placement="top">
                <InfoOutlinedIcon
                  color="info"
                  fontSize="small"
                  sx={{ ml: 1 }}
                />
              </Tooltip>
            )}

            {beta && (
              <Chip
                label="Beta"
                size="small"
                color="success"
                variant="outlined"
                sx={{ ml: 1 }}
              />
            )}
          </Stack>
        }
      />

      <Collapse in={checked} timeout="auto" unmountOnExit>
        <Box pt={2}>{children}</Box>
      </Collapse>
    </Card>
  );
};
