import { Account, ProviderType } from "../../../../services/cloudchipr.api";
import { getProviderName } from "../../../../utils/helpers/providers/getProviderName";
import { activeAccountStatuses } from "../../../../utils/constants/accounts";

export const providerDisabledTooltipTitleCombiner = (
  forOffHours: boolean,
  provider: ProviderType,
  accounts?: Account[] | null,
): string => {
  const emptyAccountsMessage = `You have no ${getProviderName(provider, {
    plural: true,
    nameAndTitle: true,
  })}.`;

  const noAccessMessage = `All ${getProviderName(provider, {
    plural: true,
  })} in this cloud provider are in Read/Only access mode, please switch to Read/Write access mode to schedule Off Hours.`;

  const accountsExist = accounts?.length;
  const activeAccountExist =
    accounts?.filter(
      ({ access_type, status }) =>
        access_type !== "read" && activeAccountStatuses.has(status),
    )?.length ?? 0;

  if (accountsExist && forOffHours) {
    return activeAccountExist ? "" : noAccessMessage;
  }

  return accountsExist ? "" : emptyAccountsMessage;
};
