import { FC, useCallback } from "react";
import { Button } from "@mui/material";
import {
  setResourcesReadyToRestore,
  setSelectedIds,
} from "../../../../../../../store/recommendations/recommendationsSlice";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../store/hooks";
import { selectedIdsExistSelector } from "../../../../../../../store/recommendations/selectors/selected-ids/selectedIdsExistSelector";
import { useAppAbility } from "../../../../../../../services/permissions";

interface ResourceRestoreButtonProps {
  resourceId?: string;
  multiple?: boolean;
}

export const ResourceRestoreButton: FC<ResourceRestoreButtonProps> = ({
  multiple,
  resourceId,
}) => {
  const dispatch = useAppDispatch();
  const { cannot } = useAppAbility();
  const canNotEditRecommendation = cannot("edit", "recommendation");

  const idsExist = useAppSelector(selectedIdsExistSelector);

  const onRestoreClick = useCallback(() => {
    if (resourceId) {
      dispatch(setSelectedIds([resourceId]));
    }

    dispatch(setResourcesReadyToRestore(true));
  }, [dispatch, resourceId]);

  return (
    <Button
      size="small"
      onClick={onRestoreClick}
      disabled={(multiple && !idsExist) || canNotEditRecommendation}
      variant={multiple ? "contained" : "outlined"}
    >
      Restore
    </Button>
  );
};
