import { createDraftSafeSelector } from "@reduxjs/toolkit";
import { resourceExplorerHierarchyMixedVisibilitiesDataSelector } from "./resourceExplorerHierarchyMixedVisibilitiesDataSelector";
import { getHierarchyMixedVisibilitiesCreatedByData } from "../../../common/utils/helpers/getHierarchyMixedVisibilitiesCreatedByData";

export const resourceExplorerHierarchyMixedVisibilitiesCreatedByDataSelector =
  createDraftSafeSelector(
    [resourceExplorerHierarchyMixedVisibilitiesDataSelector],
    (data): string[] => {
      return getHierarchyMixedVisibilitiesCreatedByData(data);
    },
  );
