import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  ReportFilters,
  ReportsData,
  ReportsInterface,
} from "./utils/types/types";
import { reportsDefaultData } from "./utils/constants/constants";
import { Report } from "../../services/cloudchipr.api";

const initialState: ReportsInterface = {
  openDrawer: false,

  data: reportsDefaultData,

  filters: {
    frequency: [],
    source: [],
    created_by: [],
    enabled: [],
  },
};

export const reportsSlice = createSlice({
  name: "reports",
  initialState: initialState,
  reducers: {
    toggleReportsOpenDrawer: (state) => {
      state.openDrawer = !state.openDrawer;
    },
    resetReportsData: (
      state,
      action: PayloadAction<Report["source_type"] | undefined>,
    ) => {
      state.data = {
        ...initialState.data,
        source_type: action.payload ?? initialState.data.source_type,
      };
    },
    resetReportsFilters: (state) => {
      state.filters = initialState.filters;
    },
    setReportsData: (state, action: PayloadAction<Partial<ReportsData>>) => {
      state.data = { ...state.data, ...action.payload };
    },
    setReportFilters: (
      state,
      action: PayloadAction<{
        key: keyof ReportFilters;
        values: string[];
      }>,
    ) => {
      const { key, values } = action.payload;

      state.filters[key] = values;
    },
  },
});

export const {
  toggleReportsOpenDrawer,
  resetReportsData,
  setReportsData,
  setReportFilters,
  resetReportsFilters,
} = reportsSlice.actions;

export default reportsSlice.reducer;
