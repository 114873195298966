import { FC } from "react";
import { Box, Popover, PopoverOrigin, Stack, Typography } from "@mui/material";
import {
  AccountStatus,
  EmbeddedAccount,
  ScheduleAccount,
} from "../../../services/cloudchipr.api";
import { AccountInfoData } from "../AccountInfoData";

type AccountType = (EmbeddedAccount | ScheduleAccount) & {
  status?: AccountStatus;
};
export type AccountsWithPopoverAccounts = Partial<AccountType>[];

interface AccountsListPopoverProps {
  open: boolean;
  closeMenu(): void;
  anchor: HTMLElement | null;
  accounts: AccountsWithPopoverAccounts;
}

export const AccountsListPopover: FC<AccountsListPopoverProps> = ({
  open,
  anchor,
  accounts,
  closeMenu,
}) => {
  return (
    <Popover
      open={open}
      anchorEl={anchor}
      onClose={closeMenu}
      anchorOrigin={anchorOrigin}
      transformOrigin={transformOrigin}
      PaperProps={paperProps}
    >
      <Box top={0} position="sticky" bgcolor="white" px={2} py={1}>
        <Typography variant="subtitle2" color="text.secondary">
          Accounts ({accounts.length})
        </Typography>
      </Box>
      <Stack spacing={2} p={2} pt={0}>
        {accounts.map((account) => (
          <AccountInfoData
            key={account.id}
            accountName={account.provider_account_name}
            accountId={account.provider_account_id}
            provider={account.provider}
          />
        ))}
      </Stack>
    </Popover>
  );
};

const anchorOrigin: PopoverOrigin = {
  vertical: "bottom",
  horizontal: "right",
};
const transformOrigin: PopoverOrigin = {
  vertical: "top",
  horizontal: "right",
};

const paperProps = {
  style: { maxHeight: 400, minWidth: 200, maxWidth: 400 },
};
