import { FC, useCallback, useMemo } from "react";
import { MenuItem, Select, SelectChangeEvent } from "@mui/material";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../store/hooks";
import { reportsScheduleDataByKeySelector } from "../../../../../../../store/reports/selector/reportsScheduleDataByKeySelector";
import { reportsScheduleNumberOfWeeksSelector } from "../../../../../../../store/reports/selector/reportsScheduleNumberOfWeeksSelector";
import { setReportsScheduleDataThunk } from "../../../../../../../store/reports/thunks/setReportsScheduleDataThunk";
import { reportsScheduleWeekDaySelector } from "../../../../../../../store/reports/selector/reportsScheduleWeekDaySelector";
import { TypographyWithTooltip } from "../../../../../../common/TypographyWithTooltip";

export const ReportsDrawerMonthlyTypeSelect: FC = () => {
  const dispatch = useAppDispatch();
  const frequency = useAppSelector(
    reportsScheduleDataByKeySelector("frequency"),
  );
  const weekDay = useAppSelector(reportsScheduleWeekDaySelector);
  const numberOfWeek = useAppSelector(reportsScheduleNumberOfWeeksSelector);
  const type = useMemo(() => {
    if (frequency === "monthly_by_day") {
      return "day";
    }

    return numberOfWeek.toString();
  }, [frequency, numberOfWeek]);

  const handleChange = useCallback(
    (e: SelectChangeEvent) => {
      const newType = e.target.value;

      if (newType === type) {
        return;
      }

      if (newType === "day") {
        dispatch(
          setReportsScheduleDataThunk({
            frequency: "monthly_by_day",
          }),
        );
        return;
      }

      dispatch(
        setReportsScheduleDataThunk({
          frequency: "monthly_by_weekday",
          number_of_week: Number(newType),
          week_day: frequency === "monthly_by_day" ? "monday" : weekDay,
        }),
      );
    },
    [type, dispatch, frequency, weekDay],
  );

  return (
    <Select value={type} fullWidth size="small" onChange={handleChange}>
      {monthlyTypesAndLabels.map(({ label, value }) => (
        <MenuItem value={value} key={value}>
          <TypographyWithTooltip title={label} variant="inherit" />
        </MenuItem>
      ))}
    </Select>
  );
};

const monthlyTypesAndLabels = [
  { value: "day", label: "On day" },
  { value: "1", label: "On the first" },
  { value: "2", label: "On the second" },
  { value: "3", label: "On the third" },
  { value: "4", label: "On the last" },
];
