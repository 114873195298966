import { createAsyncThunk } from "@reduxjs/toolkit";
import { enqueueSnackbar } from "notistack";
import { postCustomUserRoleThunk } from "./postCustomUserRoleThunk";
import { RootState } from "../../../store";
import { userOrgCurrentRolesSelector } from "../../selectors/roles/userOrgCurrentRolesSelector";
import { userRoleDataNameSelector } from "../../selectors/roles/userRoleDataNameSelector";

export const duplicateUserRoleByIdThunk = createAsyncThunk(
  "roles/duplicateUserRoleById",
  async (id: string, { dispatch, getState }) => {
    const state = getState() as RootState;
    const orgRolesData = userOrgCurrentRolesSelector(state);
    const name = userRoleDataNameSelector(state);
    const duplicatedRoleData = orgRolesData?.find((item) => item.id === id);

    if (!duplicatedRoleData) {
      return;
    }
    try {
      const response = await dispatch(
        postCustomUserRoleThunk({
          name: name.length ? name : duplicatedRoleData.name + " (copy)",
          description: duplicatedRoleData?.description,
          permissions:
            duplicatedRoleData.permissions.map((item) => item.name) ?? [],
        }),
      ).unwrap();

      if (response) {
        enqueueSnackbar("Role successfully duplicated.", {
          variant: "snackbarAlert",
          AlertSnackBarProps: { severity: "success" },
        });
      }
    } catch (e) {
      // @ts-expect-error // todo: api fix
      enqueueSnackbar(e?.data?.message ?? "Something went wrong", {
        variant: "snackbarAlert",
        AlertSnackBarProps: {
          severity: "error",
        },
      });
    }
  },
);
