import { createDraftSafeSelector } from "@reduxjs/toolkit";
import { reportsTableFilteredDataSelector } from "./reportsTableFilteredDataSelector";
import { currentDashboardIdSelector } from "../../dashboards/selectors/dashboard/currentDashboardIdSelector";

export const reportsTableFilteredDataByDashboardIdSelector =
  createDraftSafeSelector(
    [reportsTableFilteredDataSelector, currentDashboardIdSelector],
    (reports, id) => {
      return reports?.filter(({ source_id }) => source_id === id);
    },
  );
