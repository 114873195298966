import { billingInProgressStatusesSelector } from "./billingInProgressStatusesSelector";
import { RootState } from "../../../../store";

export const billingInProgressCountAllProvidersSelector = (
  state: RootState,
): number => {
  const inProgressStatuses = billingInProgressStatusesSelector(state);

  return inProgressStatuses?.length ?? 0;
};
