import { GetQuickFilterTextParams } from "@ag-grid-community/core/dist/types/src/entities/colDef";
import { Schedule } from "../../../../../../../services/cloudchipr.api";
import { getCronFrequency } from "../../../../../../../store/automations/utils/helpers/cron/getCronFrequency";

export const quickFilterFrequencyText = (
  params: GetQuickFilterTextParams<Schedule>,
) => {
  const cron = params.data.cron;
  return getCronFrequency(cron).toLowerCase();
};
