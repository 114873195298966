import { FC, useCallback } from "react";
import { Button } from "@mui/material";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { useAppDispatch } from "../../../../../../../../store/hooks";
import { useAppAbility } from "../../../../../../../../services/permissions";
import { WidgetType } from "../../../../../../../../services/cloudchipr.api";
import { editWidgetThunk } from "../../../../../../../../store/dashboards/thunks/widgets/common/editWidgetThunk";

interface WidgetEditButtonProps {
  widgetId: string;
  widgetType: WidgetType;
}

export const WidgetEditButton: FC<WidgetEditButtonProps> = ({
  widgetType,
  widgetId,
}) => {
  const dispatch = useAppDispatch();
  const { cannot } = useAppAbility();
  const canNotEditWidget = cannot("edit", "dashboard");

  const editWidgetHandler = useCallback(() => {
    dispatch(
      editWidgetThunk({
        widgetId,
        widgetType,
      }),
    );
  }, [dispatch, widgetId, widgetType]);

  return (
    <Button
      size="small"
      variant="outlined"
      sx={{ whiteSpace: "nowrap" }}
      onClick={editWidgetHandler}
      disabled={canNotEditWidget}
      startIcon={<EditOutlinedIcon fontSize="small" />}
    >
      Edit Widget
    </Button>
  );
};
