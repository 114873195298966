import { isTreeNodeFilterGroup } from "./isTreeNodeFilterGroup";
import { filterAvailableFieldsChecker } from "./data-type-checkers/filterAvailableFieldsChecker";
import { KeyValuePair } from "../../../../../../services/cloudchipr.api";
import { FilterTreeNodeWithId } from "../types/common";

export const filterTreeFiltersAreInvalid = (filter: FilterTreeNodeWithId) => {
  if (isTreeNodeFilterGroup(filter)) {
    return filter.items.some(filterTreeFiltersAreInvalid);
  }

  const { hasKey, hasValue } = filterAvailableFieldsChecker(
    filter.type,
    filter.operator,
  );

  if (!hasValue) {
    return hasKey ? !(filter.value as KeyValuePair)?.key : false;
  }

  const filterValue = hasKey
    ? (filter.value as KeyValuePair)?.value
    : filter.value;

  if (typeof filterValue === "number") {
    return !filter.value;
  }

  if (typeof filterValue === "string" || Array.isArray(filterValue)) {
    return !filterValue?.length;
  }

  return !filterValue;
};
