import { RootState } from "../../../store";
import { allAccountsGroupedByProviderSelector } from "../../../accounts/selectors/all-providers/allAccountsGroupedByProviderSelector";
import { liveUsageMgmtProviderSelector } from "../store-selectors/liveUsageMgmtProviderSelector";
import { Account } from "../../../../services/cloudchipr.api";

export const liveUsageMgmtAccountsListSelector = (
  state: RootState,
): Account[] | undefined => {
  const accountsGroupedByProvider = allAccountsGroupedByProviderSelector(state);
  const provider = liveUsageMgmtProviderSelector(state);

  if (!provider || !accountsGroupedByProvider?.[provider]) {
    return;
  }

  return accountsGroupedByProvider?.[provider];
};
