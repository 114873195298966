export const MIN_CPU_USAGE = 20;
export const MIN_DATABASE_CONNECTIONS = 1;

export enum OffHoursRecommendationsChartColors {
  CpuArea = "#36A2EB",
  DbConnectionsArea = "#9966FF",
  HorizontalLine = "#51B3CF",
  DbConnectionsLimitColor = "#ff0000",
  CpuLimitColor = "#ff9f57",
  TextColor = "#868E96",
  HighlightedArea = "#4caf5080",
}
