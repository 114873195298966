import { SvgIcon, SvgIconProps, useTheme, colors } from "@mui/material";

const AzAksSvgIcon = ({ primary }: SvgIconProps & { primary?: boolean }) => {
  const { palette } = useTheme();

  const colorDark = primary ? palette.primary.dark : palette.action.active;
  const colorLight = primary ? colors.indigo["300"] : palette.action.disabled;

  return (
    <SvgIcon>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_740_31342)">
          <path
            d="M7.73338 1.62695L3.92004 2.33362V7.53362L7.73338 8.34695L11.56 6.81362V2.93362L7.73338 1.62695Z"
            fill={colorLight}
          />
          <path
            d="M3.92004 2.33302V7.53302L7.77338 8.34635V1.67969L3.92004 2.33302ZM5.54671 7.13302L4.46671 6.91969V2.91969L5.54671 2.74635V7.13302ZM7.22671 7.43969L5.98671 7.23969V2.66635L7.22671 2.45302V7.43969Z"
            fill={colorDark}
          />
          <path
            d="M15.9867 1.69336L12.1733 2.40003V7.60003L15.9867 8.41336L19.8 6.86669V3.00003L15.9867 1.69336Z"
            fill={colorLight}
          />
          <path
            d="M12.1733 2.39943V7.59943L16 8.41276V1.74609L12.1733 2.39943ZM13.7867 7.19943L12.7067 6.98609V2.98609L13.7867 2.81276V7.19943ZM15.4667 7.50609L14.2267 7.30609V2.73276L15.4667 2.50609V7.50609Z"
            fill={colorDark}
          />
          <path
            d="M3.82664 8.80078L0.0133057 9.50745V14.7074L3.82664 15.5208L7.65331 13.9874V10.1074L3.82664 8.80078Z"
            fill={colorLight}
          />
          <path
            d="M0 9.50583V14.6658L3.85333 15.4792V8.8125L0 9.50583ZM1.61333 14.3192L0.533333 14.0925V10.0925L1.61333 9.90583V14.3192ZM3.30667 14.6658L2.06667 14.4658V9.83917L3.30667 9.62583V14.6658Z"
            fill={colorDark}
          />
          <path
            d="M12.0533 8.74609L8.23999 9.45276V14.6528L12.0533 15.4794L15.8667 13.9328V10.0528L12.0533 8.74609Z"
            fill={colorLight}
          />
          <path
            d="M8.23999 9.4525V14.6658L12.08 15.4792V8.8125L8.23999 9.4525ZM9.85332 14.2658L8.77332 14.0392V10.0392L9.85332 9.8525V14.2658ZM11.5333 14.5592L10.2933 14.3592V9.78583L11.5333 9.5725V14.5592Z"
            fill={colorDark}
          />
          <path
            d="M20.28 8.8125L16.4667 9.51917V14.7192L20.28 15.5325L24.1067 13.9992V10.1192L20.28 8.8125Z"
            fill={colorLight}
          />
          <path
            d="M16.4667 9.51917V14.6658L20.32 15.4792V8.8125L16.4667 9.51917ZM18.0933 14.3325L17.0133 14.1058V10.1058L18.0933 9.91917V14.3325ZM19.7733 14.6258L18.5333 14.4258V9.8525L19.7733 9.63917V14.6258Z"
            fill={colorDark}
          />
          <path
            d="M7.63999 16.0527L3.82666 16.7461V21.9461L7.63999 22.7727L11.4667 21.2261V17.3594L7.63999 16.0527Z"
            fill={colorLight}
          />
          <path
            d="M3.82666 16.7467V21.9467L7.67999 22.7733V16L3.82666 16.7467ZM5.45333 21.56L4.37333 21.3333V17.3333L5.45333 17.1467V21.56ZM7.13333 21.8667L5.89333 21.6667V17.08L7.13333 16.8667V21.8667Z"
            fill={colorDark}
          />
          <path
            d="M15.88 16.1074L12.0667 16.8141V22.0141L15.88 22.8274L19.7066 21.2941V17.4141L15.88 16.1074Z"
            fill={colorLight}
          />
          <path
            d="M12.0667 16.8135V22.0135L15.92 22.8268V16.1602L12.0667 16.8135ZM13.6933 21.6268L12.6133 21.4002V17.4002L13.6933 17.2135V21.6268ZM15.3733 21.9202L14.1333 21.7202V17.1468L15.3733 16.9335V21.9202Z"
            fill={colorDark}
          />
        </g>
        <defs>
          <clipPath id="clip0_740_31342">
            <rect width="24" height="24" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </SvgIcon>
  );
};

AzAksSvgIcon.muiName = "AzAksSvgIcon";

export default AzAksSvgIcon;
