import { FilterFn } from "@tanstack/react-table";
import { customFilterFn, customFilterFnByCellType } from "./common";
import { ResourceType } from "../../../../services/cloudchipr.api";
import { ColumnSetupType } from "../types/types";
import { getResourceTypeColumns } from "../../../../components/pages/live-usage-mgmt/components/resource-card/resource-card-content/resource-card-data/resource-card-grid/resouce-card-data-grid/utils/data-grid/columns-setup/getResourceTypeColumns";
import { getFirstResourceTypeFromResources } from "../../../../components/pages/live-usage-mgmt/components/resource-card/resource-card-content/resource-card-data/resource-card-grid/resouce-card-data-grid/utils/helpers/getFirstResourceTypeFromResources";
import {
  ResourceDataType,
  ResourceItemType,
} from "../../../../store/live-usage-mgmt/utils/types/types";

export const childResourcesDataFilter: FilterFn<any> = (
  row,
  columnId,
  value,
) => {
  const resources = row.original?.child_resource
    ?.resources as ResourceDataType[];

  const subRowResourceType = getFirstResourceTypeFromResources(resources);

  if (!subRowResourceType) {
    return false;
  }

  return recursiveFilter(subRowResourceType, resources, columnId, value);
};

const recursiveFilter = (
  resourceType: ResourceType,
  resources: ResourceDataType[],
  columnId: string,
  value: any,
): boolean => {
  let foundResult = false;

  const expandedColumns = getResourceTypeColumns(resourceType);

  if (!resources?.length || !expandedColumns) {
    return foundResult;
  }

  resources.forEach(({ resource, child_resource }) => {
    const childResources = child_resource?.resources;

    if (childResources) {
      const childResourceType =
        getFirstResourceTypeFromResources(childResources);

      if (childResourceType) {
        foundResult = recursiveFilter(
          childResourceType,
          childResources,
          columnId,
          value,
        );
      }
    }

    if (foundResult) {
      return;
    }

    foundResult = filterExpandedColumns(
      expandedColumns,
      resource,
      value,
      columnId,
    );
  });

  return foundResult;
};

const filterExpandedColumns = (
  expandedColumns: ColumnSetupType<any>[],
  resource: ResourceItemType,
  value: string,
  columnId: string,
) => {
  let returnValue = false;

  expandedColumns.forEach((column) => {
    if (returnValue) {
      return;
    }

    const type = column.type;
    const accessorKey = column.accessorKey as keyof ResourceItemType;
    const filterFnName =
      type && customFilterFnByCellType.get(type)
        ? customFilterFnByCellType.get(type)
        : "commonDefaultFilter";

    const filterFn = filterFnName && customFilterFn[filterFnName];

    if (!accessorKey) {
      return;
    }

    if (
      accessorKey &&
      filterFn?.(columnId, value, resource[accessorKey] as any)
    ) {
      returnValue = true;
    }
  });

  return returnValue;
};
