import moment from "moment";
import {
  OrganisationDailyOffHoursRecommendation,
  OrganisationWeeklyOffHoursRecommendation,
} from "../../../../services/cloudchipr.api";
import { isWeeklyOffHoursRecommendation } from "../../../../components/pages/recommendations/components/off-hours-recommendations/utils/helpers/isWeeklyOffHoursRecommendation";
import { weekDaysToNumber } from "../../../../components/pages/reports/utils/constants/constants";
import { OffHoursSpecificData } from "../types/common";

export const generateOffHoursRecommendationScheduler = (
  recommendation:
    | OrganisationWeeklyOffHoursRecommendation
    | OrganisationDailyOffHoursRecommendation,
): OffHoursSpecificData["scheduler"] => {
  let weekDays = {};

  if (isWeeklyOffHoursRecommendation(recommendation)) {
    weekDays = {
      startWeekDays: [weekDaysToNumber[recommendation.to_day]],
      stopWeekDays: [weekDaysToNumber[recommendation.from_day]],
    };
  }

  const startTime = moment()
    .set({ hours: recommendation.to_hour, minutes: 0, seconds: 0 })
    .format();
  const stopTime = moment()
    .set({ hours: recommendation.from_hour, minutes: 0, seconds: 0 })
    .format();

  return {
    repeatValue: 1,
    startTime,
    stopTime,
    ...weekDays,
  };
};
