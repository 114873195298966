import { FC } from "react";
import { Box, Divider, Stack } from "@mui/material";
import { InfoCards } from "../../../../common/info-cards/InfoCards";

interface DashboardEmptyWidgetStateProps {
  infoCards?: {
    value: string;
    title: string;
  }[];
}
export const DashboardEmptyWidgetState: FC<DashboardEmptyWidgetStateProps> = ({
  infoCards,
}) => {
  return (
    <Stack p={2} gap={1}>
      {infoCards && <InfoCards values={infoCards} />}

      <Box pb={6} px={5}>
        <Stack
          gap={6}
          sx={{
            py: 6,
            border: "1px dashed grey",
            borderColor: "grey.300",
          }}
        >
          <Divider sx={{ borderStyle: "dashed" }} />
          <Divider sx={{ borderStyle: "dashed" }} />
          <Divider sx={{ borderStyle: "dashed" }} />
          <Divider sx={{ borderStyle: "dashed" }} />
          <Divider sx={{ borderStyle: "dashed" }} />
        </Stack>
      </Box>
    </Stack>
  );
};
