import { FC, memo, useCallback } from "react";
import { MobileDateTimePicker } from "@mui/x-date-pickers";
import moment from "moment";
import { IconButton, InputAdornment } from "@mui/material";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import { formatDate } from "../../../../../../../../utils/helpers/date-time/datetime-format";

interface AutomationDateTimePickerProps {
  date: string | null;
  minDate: string;
  error?: string | boolean;
  icon?: boolean;
  disabled?: boolean;
  onChange?(date: string): void;
  onAccept?(date: string): void;
}

export const AutomationDateTimePicker: FC<AutomationDateTimePickerProps> = memo(
  ({ icon, date, onAccept, onChange, error, disabled, minDate }) => {
    const dateChangeHandler = useCallback(
      (moment: any) => {
        const date = formatDate(moment);

        onChange?.(date);
      },
      [onChange],
    );

    const dateAcceptHandler = useCallback(
      (moment: any) => {
        const date = formatDate(moment);

        onAccept?.(date);
      },
      [onAccept],
    );
    return (
      <MobileDateTimePicker
        disableHighlightToday
        disabled={disabled}
        value={date ? moment(date) : null}
        onChange={dateChangeHandler}
        onAccept={dateAcceptHandler}
        minDateTime={moment(minDate)}
        slotProps={{
          textField: {
            name: "startDate",
            size: "small",
            helperText: error,
            variant: "outlined",
            error: !!error,
            disabled: disabled,
            InputProps: icon ? InputProps : undefined,
          },
        }}
      />
    );
  },
);

const InputProps = {
  endAdornment: (
    <InputAdornment position="end">
      <IconButton edge="end">
        <CalendarTodayIcon />
      </IconButton>
    </InputAdornment>
  ),
};
