import { FC, Fragment, useCallback, useEffect, useState } from "react";
import { Divider, Tab } from "@mui/material";
import TabContext from "@mui/lab/TabContext";
import { TabList, TabPanel } from "@mui/lab";
import { ResourceExplorerBillingStatusTitle } from "./ResourceExplorerBillingStatusTitle";
import { ResourceExplorerInProgressBillingStatusPanel } from "../ResourceExplorerInProgressBillingStatusPanel";
import { ResourceExplorerWarningBillingStatusPanel } from "../ResourceExplorerWarningBillingStatusPanel";
import { ResourceExplorerErrorBillingStatusPanel } from "../ResourceExplorerErrorBillingStatusPanel";
import { useAppSelector } from "../../../../../../../../../store/hooks";
import { billingErrorsCountAllProvidersSelector } from "../../../../../../../../../store/common/selectors/billing-status/error/billingErrorsCountAllProvidersSelector";
import { billingWarningsCountAllProvidersSelector } from "../../../../../../../../../store/common/selectors/billing-status/warning/billingWarningsCountAllProvidersSelector";
import { billingInProgressCountAllProvidersSelector } from "../../../../../../../../../store/common/selectors/billing-status/in-progress/billingInProgressCountAllProvidersSelector";
import { ResourceExplorerStatusTypes } from "../../utils/types/resourceExplorerStatusTypes";
import { billingAccountSingleStatusSelector } from "../../../../../../../../../store/common/selectors/billing-status/billingAccountSingleStatusSelector";

interface ResourceExplorerBillingStatusesDrawerContentProps {
  activeTab: ResourceExplorerStatusTypes;
}

export const ResourceExplorerBillingStatusesDrawerContent: FC<
  ResourceExplorerBillingStatusesDrawerContentProps
> = ({ activeTab }) => {
  const hideTabs = !!useAppSelector(billingAccountSingleStatusSelector);
  const billingErrorsCount = useAppSelector(
    billingErrorsCountAllProvidersSelector,
  );
  const billingWarningsCount = useAppSelector(
    billingWarningsCountAllProvidersSelector,
  );
  const billingInProgressCount = useAppSelector(
    billingInProgressCountAllProvidersSelector,
  );

  const initialTab =
    (billingErrorsCount && "error") ||
    (billingWarningsCount && "warning") ||
    (billingInProgressCount && "inProgress") ||
    "error";

  const [tabValue, setTabValue] =
    useState<ResourceExplorerStatusTypes>(initialTab);

  const tabChangeHandler = useCallback(
    (_: any, tab: ResourceExplorerStatusTypes) => {
      setTabValue(tab);
    },
    [],
  );

  useEffect(() => {
    setTabValue(activeTab);
  }, [activeTab]);

  return (
    <Fragment>
      <Divider />
      <TabContext value={tabValue.toString()}>
        {!hideTabs && (
          <TabList onChange={tabChangeHandler} variant="fullWidth">
            {!!billingErrorsCount && (
              <Tab
                value="error"
                label={
                  <ResourceExplorerBillingStatusTitle
                    type="error"
                    count={billingErrorsCount}
                  />
                }
              />
            )}
            {!!billingWarningsCount && (
              <Tab
                value="warning"
                label={
                  <ResourceExplorerBillingStatusTitle
                    type="warning"
                    count={billingWarningsCount}
                  />
                }
              />
            )}
            {!!billingInProgressCount && (
              <Tab
                value="inProgress"
                label={
                  <ResourceExplorerBillingStatusTitle
                    type="inProgress"
                    count={billingInProgressCount}
                  />
                }
              />
            )}
          </TabList>
        )}

        <Divider />

        {!!billingErrorsCount && (
          <TabPanel value="error" sx={{ p: 2 }}>
            <ResourceExplorerErrorBillingStatusPanel />
          </TabPanel>
        )}
        {!!billingWarningsCount && (
          <TabPanel value="warning" sx={{ p: 2 }}>
            <ResourceExplorerWarningBillingStatusPanel />
          </TabPanel>
        )}
        {!!billingInProgressCount && (
          <TabPanel value="inProgress" sx={{ p: 2 }}>
            <ResourceExplorerInProgressBillingStatusPanel />
          </TabPanel>
        )}
      </TabContext>
    </Fragment>
  );
};
