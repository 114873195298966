import { RootState } from "../../../store";
import { cloudChiprApi, Role } from "../../../../services/cloudchipr.api";

const selector =
  cloudChiprApi.endpoints.getUsersMeOrganisationsCurrentRoles.select();

export const userOrgCurrentRolesSelector = (
  state: RootState,
): Role[] | undefined => {
  return selector(state)?.data;
};
