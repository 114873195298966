import { FC } from "react";
import { Stack, Tooltip, Typography } from "@mui/material";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

interface TestMessageInfoProps {
  error?: string;
}

export const TestMessageInfo: FC<TestMessageInfoProps> = ({ error }) => {
  return (
    <Stack alignItems="center" direction="row" spacing={1}>
      <Typography variant="button" color={error ? "error" : "success.main"}>
        {error ? "Failed" : "Sent"}
      </Typography>

      {error ? (
        <Tooltip title={error} arrow>
          <WarningAmberIcon color="error" fontSize="small" />
        </Tooltip>
      ) : (
        <CheckCircleOutlineIcon fontSize="small" color="success" />
      )}
    </Stack>
  );
};
