import { FC } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import { CommandSnippet } from "../../../../common/connectors/components/CommandSnippet";

interface ApiKeysTestProps {
  open: boolean;
  apiKey: string;
  handleClose(): void;
}

export const ApiKeysTest: FC<ApiKeysTestProps> = ({
  open,
  apiKey,
  handleClose,
}) => {
  return (
    <Dialog open={open} onClose={handleClose}>
      <Box sx={{ borderBottom: 1, borderColor: "grey.300", mb: 2 }}>
        <DialogTitle>
          <Typography component="span" variant="h6">
            Test API Key
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Typography variant="body2" mt={2} mb={4}>
            Sample curl request to test the API key.
          </Typography>

          <CommandSnippet
            code={`curl -X POST -H 'Content-type: application/json' -H 'x-api-key: ${apiKey}' --data '{"text":"Hello, World!"}' https://api.cloudchipr.com/test`}
          />
        </DialogContent>
      </Box>
      <DialogActions>
        <Button variant="outlined" onClick={handleClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};
