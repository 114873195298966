import { RootState } from "../../../../../store";
import { workflowsDataSelector } from "../../list-data/workflowsDataSelector";

export const automationsEnabledStateFilterOptionsSelector = (
  state: RootState,
) => {
  const workflows = workflowsDataSelector(state);

  const options = workflows?.map((workflow) => {
    return workflow.status === "active" ? "yes" : "no";
  });

  return Array.from(new Set(options));
};
