import {
  FilterTreeNode,
  ResourceExplorerFilterSchema,
} from "../../../../services/cloudchipr.api";

export const addFilterTreePathToResourceExplorerPayload = (
  resourceExplorerPayload: ResourceExplorerFilterSchema,
  filterTree?: FilterTreeNode,
): ResourceExplorerFilterSchema => {
  if (filterTree) {
    return { ...resourceExplorerPayload, scope_filter_tree: filterTree };
  }

  return resourceExplorerPayload;
};
