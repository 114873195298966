import { RootState } from "../../../store";
import { liveUsageMgmtViewWithoutFiltersResourceTypes } from "../store-selectors/liveUsageMgmtViewWithoutFiltersResourceTypes";
import { ResourceType } from "../../../../services/cloudchipr.api";

export const liveUsageMgmtResourceViewIsWithoutFiltersSelector = (
  state: RootState,
  resourceType: ResourceType,
): boolean => {
  const viewWithoutFiltersResourceTypes =
    liveUsageMgmtViewWithoutFiltersResourceTypes(state);

  return !!viewWithoutFiltersResourceTypes[resourceType];
};
