import { createAsyncThunk } from "@reduxjs/toolkit";
import { getCostBreakdownWidgetSetupDataThunk } from "./getCostBreakdownWidgetSetupDataThunk";
import { RootState } from "../../../../store";
import { setWidgetSetup } from "../../../dashboardsSlice";
import { costBreakdownWidgetDefaultData } from "../../../utils/constants/costBreakdownSetupDefaultData";
import { resourceExplorerViewsDataSelector } from "../../../../resource-explorer/selectors/all-resource-explorers/resourceExplorerViewsDataSelector";

export const initiateCostBreakdownWidgetThunk = createAsyncThunk(
  "dashboards/initiateCostBreakdownWidget",
  async (_: void, { dispatch, getState }) => {
    const state = getState() as RootState;

    const views = resourceExplorerViewsDataSelector(state);

    const defaultViewId = views?.find((view) => view.is_default)?.id;

    if (!defaultViewId) {
      dispatch(setWidgetSetup(costBreakdownWidgetDefaultData));
      return;
    }

    dispatch(
      setWidgetSetup({
        ...costBreakdownWidgetDefaultData,
        viewId: defaultViewId,
      }),
    );

    dispatch(getCostBreakdownWidgetSetupDataThunk());
  },
);
