import { FormControlLabel, Radio, Stack, Tooltip } from "@mui/material";
import Typography from "@mui/material/Typography";
import { FC, SyntheticEvent } from "react";
import { indigo } from "@mui/material/colors";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { AlertTabType } from "../../../../../../store/alerts/utils/types/types";
import { useAppSelector } from "../../../../../../store/hooks";
import { alertsDrawerEditModeSelector } from "../../../../../../store/alerts/selectors/alertsDrawerEditModeSelector";

interface AlertsDrawerTabProps {
  value: AlertTabType;
  labelTitle: string;
  selected: boolean;
  tooltipTitle: string;
  onChange(event: SyntheticEvent): void;
}

export const AlertsDrawerTab: FC<AlertsDrawerTabProps> = ({
  value,
  labelTitle,
  selected,
  onChange,
  tooltipTitle,
}) => {
  const editMode = useAppSelector(alertsDrawerEditModeSelector);
  const disabled = !selected && editMode;

  return (
    <FormControlLabel
      disableTypography
      disabled={disabled}
      value={value}
      onChange={onChange}
      control={<Radio size="small" />}
      label={
        <Stack
          pr={1}
          width="100%"
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography
            variant="body2"
            fontWeight="medium"
            color={disabled ? "text.disabled" : undefined}
          >
            {labelTitle}
          </Typography>
          <Tooltip
            arrow
            placement="left"
            title={tooltipTitle}
            PopperProps={popperProps}
          >
            <InfoOutlinedIcon color="action" fontSize="small" />
          </Tooltip>
        </Stack>
      }
      sx={{
        ...alertsDrawerTabStyles,
        bgcolor: selected ? indigo[50] : undefined,
      }}
    />
  );
};

const alertsDrawerTabStyles = {
  border: 1,
  borderColor: "grey.300",
  borderRadius: 1,
  py: 1,
  px: 0.5,
  m: 0,
  flex: 1,
};

const popperProps = { sx: { maxWidth: 250 } };
