import { ColumnSetupType } from "../../../../../../../../../../../../../../storybook/data-grid/utils/types/types";
import { AzVm } from "../../../../../../../../../../../../../../services/cloudchipr.api";

export const azVmssVmColumns: ColumnSetupType<AzVm>[] = [
  {
    accessorKey: "name",
    header: "Instances",
    type: "identifierWithRecommendationLink",
    meta: { sticky: "left" },
  },
  {
    accessorKey: "price_per_month",
    header: "Monthly Price",
    type: "money",
    headerTooltip:
      "Monthly prices are calculated based on the on-demand list price of each resource.",
  },
  {
    accessorKey: "all_time_spent",
    header: "Total Spend",
    type: "money",
  },
  {
    accessorKey: "provider_status",
    header: "Status",
    type: "providerStatus",
  },
  {
    accessorKey: "created_at",
    header: "Launch time",
    type: "date",
  },
  {
    accessorKey: "size",
    header: "Machine Size",
  },
  {
    accessorKey: "cpu_value",
    header: "CPU",
    headerTooltip: "The maximum usage of CPU for the last 7 days.",
    type: "cpuStatistic",
  },
  { accessorKey: "tags", header: "Tags", type: "tag", enableSorting: false },
];
