import { FC } from "react";
import { FormControlLabel, FormGroup, Popover, Switch } from "@mui/material";
import { Column, Table } from "@tanstack/react-table";

interface ColumnsSwitcherProps {
  open: boolean;
  table: Table<any>;
  anchor: HTMLElement | null;
  onClose(): void;
}

export const ColumnsSwitcher: FC<ColumnsSwitcherProps> = ({
  anchor,
  onClose,
  open,
  table,
}) => {
  //todo: this switcher imported and used in few places, better to move it in to DataGrid and render it by passing type to choose what kind of switcher we want to have, (the same about switcher opener button on logic)

  const getLabel = ({ columnDef }: Column<any>) => {
    if (columnDef.header instanceof Function) {
      return columnDef.meta?.headerTitle;
    }

    return columnDef.header;
  };

  return (
    <Popover
      open={open}
      anchorEl={anchor}
      onClose={onClose}
      PaperProps={{ sx: { p: 2 } }}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
    >
      <FormGroup>
        {table.getAllLeafColumns().map((column) => {
          if (column.columnDef.meta?.hideFromSettings) {
            return null;
          }

          return (
            <FormControlLabel
              key={column.id}
              sx={{ mb: 1, whiteSpace: "nowrap" }}
              disabled={!column.getCanHide()}
              control={
                <Switch
                  size="small"
                  checked={column.getIsVisible()}
                  onChange={column.getToggleVisibilityHandler()}
                />
              }
              label={getLabel(column)}
            />
          );
        })}
      </FormGroup>
    </Popover>
  );
};
