import { ChangeEvent, FC, useCallback, useState } from "react";
import { Stack, TextField } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../../../../store/hooks";
import { setUserRoleData } from "../../../../../../store/user/userSlice";
import { userRoleDataNameSelector } from "../../../../../../store/user/selectors/roles/userRoleDataNameSelector";
import { userRoleDataDescriptionSelector } from "../../../../../../store/user/selectors/roles/userRoleDataDescriptionSelector";

export const UserRoleEditableMetaData: FC = () => {
  const nameFromStore = useAppSelector(userRoleDataNameSelector);
  const descriptionFromStore = useAppSelector(userRoleDataDescriptionSelector);
  const dispatch = useAppDispatch();

  const [name, setName] = useState(nameFromStore);
  const [description, setDescription] = useState(descriptionFromStore);

  const nameChangeHandler = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setName(event.target.value);
    },
    [],
  );

  const descriptionChangeHandler = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setDescription(event.target.value);
    },
    [],
  );

  const handleNameBlur = useCallback(() => {
    dispatch(setUserRoleData({ name }));
  }, [dispatch, name]);

  const handleDescriptionBlur = useCallback(() => {
    dispatch(setUserRoleData({ description }));
  }, [dispatch, description]);

  return (
    <Stack spacing={2}>
      <TextField
        size="small"
        label="Role Name"
        onChange={nameChangeHandler}
        value={name}
        onBlur={handleNameBlur}
        autoFocus
      />
      <TextField
        size="small"
        label="Description (Optional)"
        onChange={descriptionChangeHandler}
        value={description}
        onBlur={handleDescriptionBlur}
      />
    </Stack>
  );
};
