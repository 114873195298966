import { FC, Fragment, useCallback } from "react";
import { Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import { useAppSelector } from "../../../../../store/hooks";
import { ProviderType } from "../../../../../services/cloudchipr.api";
import { accountsMaxNumberExceededSelector } from "../../../../../store/organizations/organizationsSelectors";
import { getProviderName } from "../../../../../utils/helpers/providers/getProviderName";
import { useDialog } from "../../../../../utils/hooks/useDialog.hook";
import { useAccountAddFunctional } from "../../utils/hooks/useAccountAddFunctional.hook";
import { ConfirmationDialog } from "../../../../common/modals/ConfirmationDialog";

interface AccountAddButtonProps {
  provider: ProviderType;
}

export const AccountAddButton: FC<AccountAddButtonProps> = ({ provider }) => {
  const { startAddAccountFlow, canNotCreateAccount } = useAccountAddFunctional({
    provider,
  });

  const navigate = useNavigate();
  const accountsMaxNumberExceeded = useAppSelector(
    accountsMaxNumberExceededSelector,
  );

  const { open, openDialog, closeDialog } = useDialog();

  const goToPlansHandler = useCallback(() => {
    navigate("/settings/billing");
  }, [navigate]);

  const handleAddAccountClick = useCallback(() => {
    if (accountsMaxNumberExceeded) {
      openDialog();
      return;
    }

    startAddAccountFlow();
  }, [startAddAccountFlow, accountsMaxNumberExceeded, openDialog]);

  const accountTypeName = getProviderName(provider, { plural: true });

  return (
    <Fragment>
      <Button
        variant="outlined"
        color="primary"
        onClick={handleAddAccountClick}
        disabled={canNotCreateAccount}
        sx={{ textTransform: "none" }}
        startIcon={<AddIcon />}
      >
        Add {getProviderName(provider, { capitalize: true })}
      </Button>

      <ConfirmationDialog
        open={open}
        title="Upgrade Your Plan"
        dialogContent={
          <Typography>
            You have reached the maximum amount of {accountTypeName} allowed by
            your plan. To add more, please upgrade your plan.
          </Typography>
        }
        CancelButtonProps={{
          onClick: closeDialog,
        }}
        ConfirmButtonProps={{
          children: "Go to plans",
          onClick: goToPlansHandler,
        }}
        onClose={closeDialog}
      />
    </Fragment>
  );
};
