import { ColDef } from "@ag-grid-community/core/dist/types/src/entities/colDef";
import { TasksGroupingByProperty } from "../../../utils/types/common";
import { taskPropertyNameByGrouping } from "../../../utils/constants/grouping";
import { GetUsersMeOrganisationsCurrentTasksPropertiesApiResponse } from "../../../../../../services/cloudchipr.api";

export const autoGroupColumnDefComparatorFn =
  (
    taskAvailableProperties?: GetUsersMeOrganisationsCurrentTasksPropertiesApiResponse,
  ): ColDef["comparator"] =>
  (_, __, nodeA, nodeB) => {
    const currentKey = (nodeA.field ?? "") as TasksGroupingByProperty;
    const nextKey = (nodeB.field ?? "") as TasksGroupingByProperty;

    const currentId = nodeA?.allLeafChildren?.at(0)?.data?.[currentKey]?.id;
    const nextId = nodeB?.allLeafChildren?.at(0)?.data?.[nextKey]?.id;

    const propKey = taskPropertyNameByGrouping[currentKey];

    if (!currentId || !propKey) {
      return 1;
    }

    const idsOrder = taskAvailableProperties?.[propKey]?.reduce(
      (map, item, index) => {
        map[item.id] = index;
        return map;
      },
      {} as Record<string, number>,
    );

    const indexA = idsOrder?.[currentId] ?? 0;
    const indexB = idsOrder?.[nextId] ?? 0;

    return indexA - indexB;
  };
