import { FC } from "react";
import { Box, Stack, Typography } from "@mui/material";
import {
  ForecastedCost,
  ForecastOption,
} from "../../../../../../../../../../../services/cloudchipr.api";
import { forecastedCostOptionLabels } from "../../../../../resource-explorer-metadata/components/utils/constants";
import { TooltipText } from "../../../../../../../../../../common/TooltipText";
import { formatDate } from "../../../../../../../../../../../utils/helpers/date-time/datetime-format";
import { money } from "../../../../../../../../../../../utils/numeral/money";

interface ForecastedCostHeaderCellProps {
  forecastOption: ForecastOption;
  forecastedCost?: ForecastedCost;
}
export const ForecastedCostHeaderCell: FC<ForecastedCostHeaderCellProps> = ({
  forecastOption,
  forecastedCost,
}) => {
  const toDate = formatDate(forecastedCost?.date_to ?? "", { type: "date" });
  const fromDate = formatDate(forecastedCost?.date_from ?? "", {
    type: "date",
  });

  const titleComponent = (
    <Box>
      Forecasted Cost <br /> from{" "}
      <Typography fontWeight="medium" fontSize="inherit" component="span">
        {fromDate}
      </Typography>{" "}
      to{" "}
      <Typography fontWeight="medium" fontSize="inherit" component="span">
        {toDate}
      </Typography>
    </Box>
  );

  return (
    <Stack alignItems="end" flexGrow={1}>
      <TooltipText
        fontWeight="medium"
        tooltipPlacement="top"
        tooltipTitle={titleComponent}
      >
        Forecasted {forecastedCostOptionLabels.get(forecastOption)} Cost
      </TooltipText>
      <Typography
        mt={0.5}
        fontSize={10}
        color="text.disabled"
        fontWeight="medium"
        align="right"
      >
        ~{money(forecastedCost?.cost ?? 0)}
      </Typography>
    </Stack>
  );
};
