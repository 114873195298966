import { FC, useMemo } from "react";
import { getProviderName } from "../../../../../../utils/helpers/providers/getProviderName";
import { noOrgAccountOrgId } from "../../../../accounts-group/utils/helpers/noOrganizationConstants";
import { Breadcrumbs } from "../../../../common/breadcrumbs/Breadcrumbs";
import { useAppSelector } from "../../../../../../store/hooks";
import { LiveUsageMgmtSelectedAccountNavigation } from "../../navigation/components/selected-account/LiveUsageMgmtSelectedAccountNavigation";
import { liveUsageMgmtProviderSelector } from "../../../../../../store/live-usage-mgmt/selectors/store-selectors/liveUsageMgmtProviderSelector";

export const LiveUsageMgmtAccountBreadcrumbs: FC = () => {
  const providerType = useAppSelector(liveUsageMgmtProviderSelector);

  const accountPageBreadcrumbs = useMemo(() => {
    if (!providerType) {
      return null;
    }

    return [
      {
        title: `${getProviderName(providerType, {
          title: true,
        })} ${getProviderName(providerType, {
          plural: true,
          capitalize: true,
        })}`,

        to: `/${providerType}/${noOrgAccountOrgId}/accounts`,
      },
      {
        title: <LiveUsageMgmtSelectedAccountNavigation />,
      },
    ];
  }, [providerType]);

  if (!accountPageBreadcrumbs) {
    return null;
  }

  return <Breadcrumbs breadcrumbs={accountPageBreadcrumbs} />;
};
