import { utilizationAndCoverageDetailsDataSelector } from "../data/utilizationAndCoverageDetailsDataSelector";
import { RootState } from "../../../../../store";
import { CommitmentUtilizationMetadata } from "../../../../../../services/cloudchipr.api";

export const savingsPlanDetailsMetaDataSelector = (
  state: RootState,
): CommitmentUtilizationMetadata | undefined => {
  return utilizationAndCoverageDetailsDataSelector(state)
    ?.metadata as CommitmentUtilizationMetadata;
};
