import { FC, useCallback, useMemo } from "react";
import { costAndUsageSumDataGridColumns } from "./columns/costAndUsageSumDataGridColumns";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../../../store/hooks";
import { DataGrid } from "../../../../../../../../../../storybook/data-grid/DataGrid";
import { costAndUsageSumSetupPropertyByKeySelector } from "../../../../../../../../../../store/dashboards/selectors/setups/cost-and-usage/costAndUsageSumSetupPropertyByKeySelector";
import { costAndUsageSumFilterChangeThunk } from "../../../../../../../../../../store/dashboards/thunks/widgets/cost-and-usage/widget-change/costAndUsageSumFilterChangeThunk";
import { DataGridProvider } from "../../../../../../../../../../storybook/data-grid/DataGridProvider";
import { widgetDataGridStyle } from "../../../../../../../utils/constants/stylings";
import { ToolbarConfig } from "../../../../../../../../../../storybook/data-grid/utils/types/prop-types";
import { WidgetSetupTableToolbar } from "../../../common/WidgetSetupTableToolbar";
import { costAndUsageSumSetupWidgetDataGridDataSelector } from "../../../../../../../../../../store/dashboards/selectors/setups/cost-and-usage/data/costAndUsageSumSetupWidgetDataGridDataSelector";

export const CostAndUsageSumWidgetSetupDataGrid: FC = () => {
  const dispatch = useAppDispatch();

  const filter = useAppSelector(
    costAndUsageSumSetupPropertyByKeySelector("filter"),
  ) as string[];

  const initialSelectedItems = useMemo(() => {
    return filter.reduce((acc, id) => ({ ...acc, [id]: true }), {}) ?? {};
  }, [filter]);

  const gridData = useAppSelector(
    costAndUsageSumSetupWidgetDataGridDataSelector,
  );

  const onRowSelectionChange = useCallback(
    (rows: Record<string, boolean>) => {
      const filter = Object.keys(rows);
      dispatch(costAndUsageSumFilterChangeThunk(filter));
    },
    [dispatch],
  );

  return (
    <DataGridProvider>
      <DataGrid
        toolbar={toolbar}
        includeHeader={false}
        data={gridData ?? []}
        styles={widgetDataGridStyle}
        columns={costAndUsageSumDataGridColumns}
        rowSelection={{
          initialSelectedItems,
          rowSelectionChange: onRowSelectionChange,
        }}
      />
    </DataGridProvider>
  );
};

const toolbar: ToolbarConfig = {
  renderToolbar: (props) => <WidgetSetupTableToolbar {...props} />,
};
