import { createAsyncThunk } from "@reduxjs/toolkit";
import { getUserRolesPermissionsThunk } from "./getUserRolesPermissionsThunk";
import { getUserRoleByIdThunk } from "./getUserRoleByIdThunk";
import { userRoleDataIdSelector } from "../../selectors/roles/userRoleDataIdSelector";
import { RootState } from "../../../store";

export const initiateCreateUserRolesDrawerThunk = createAsyncThunk(
  "roles/initiateCreateUserRolesDrawer",
  async (_, { dispatch, getState }) => {
    const state = getState() as RootState;
    const id = userRoleDataIdSelector(state);

    if (!id) {
      return;
    }

    await dispatch(getUserRolesPermissionsThunk());
    dispatch(getUserRoleByIdThunk(id));
  },
);
