import { FC } from "react";
import { Stack, Typography } from "@mui/material";
import { TooltipProps } from "recharts";
import {
  CommitmentDateGranularDataPoint,
  CommitmentDateGranularDataPointValue,
} from "../../../../../../services/cloudchipr.api";
import { useAppSelector } from "../../../../../../store/hooks";
import { chartDataPointKey } from "../../../../../../storybook/charts/multi-type-chart/utils/constants/constants";
import { ChartsTooltipContentStyles } from "../../../../../../storybook/charts/multi-type-chart/components/common/ChartsTooltipContent";
import { Cube } from "../../../../../../storybook/charts/multi-type-chart/components/common/Cube";
import { formatDateByFrequencyType } from "../../../../../../utils/helpers/date-time/datetime-format";
import { commitmentsDateGranularitySelector } from "../../../../../../store/commitments/selectors/common/view-options/commitmentsDateGranularitySelector";
import { money } from "../../../../../../utils/numeral/money";

interface UtilizationAndCoverageContentChartTooltipContentProps
  extends TooltipProps<any, any> {
  chartData: Record<string, CommitmentDateGranularDataPoint>;
}

export const CommitmentsContentChartTooltipContent: FC<
  UtilizationAndCoverageContentChartTooltipContentProps
> = ({ payload, chartData }) => {
  const dataPoint = useAppSelector(commitmentsDateGranularitySelector);
  const date = payload?.at(0)?.payload?.[chartDataPointKey] as string;
  const dataItem = chartData?.[date];

  if (!dataItem) {
    return null;
  }

  return (
    <Stack sx={ChartsTooltipContentStyles}>
      <Typography variant="caption">
        {formatDateByFrequencyType(dataPoint, date)}
      </Typography>

      {payload?.map((point) => {
        const key = point.dataKey as string;
        const date = key.toLowerCase() as keyof typeof dataItem;
        const data = dataItem?.[date] as CommitmentDateGranularDataPointValue;
        const percent = data?.percent;
        const amount = data?.amount;

        return (
          <Stack
            direction="row"
            key={key}
            alignItems="center"
            spacing={1}
            justifyContent="space-between"
          >
            <Stack direction="row" alignItems="center">
              <Cube radius={0.5} width={3} height={14} color={point.color} />
              <Typography variant="caption" textTransform="capitalize">
                {key}
              </Typography>
            </Stack>

            <Typography variant="caption" fontWeight="bold">
              {percent}% ({money(amount)})
            </Typography>
          </Stack>
        );
      })}
    </Stack>
  );
};
