import { costBreakdownWidgetDataByWidgetIdSelector } from "./costBreakdownWidgetDataByWidgetIdSelector";
import { RootState } from "../../../../../store";
import { ResourceExplorerGridDataWithId } from "../../../../../resource-explorer/utils/types/resourceExplorerDataGrid";
import { formatCostBreakdownREDataGridData } from "../../../../utils/helpers/costBreakdown/formatCostBreakdownREDataGridData";

export const costBreakdownWidgetTotalDataWithIdsSelector = (
  state: RootState,
  widgetId: string,
): ResourceExplorerGridDataWithId[] | undefined => {
  const data = costBreakdownWidgetDataByWidgetIdSelector(
    state,
    widgetId,
  )?.total;

  return formatCostBreakdownREDataGridData(data ?? [], true) ?? [];
};
