import { costBreakdownWidgetSetupDataSelector } from "./costBreakdownWidgetSetupDataSelector";
import { RootState } from "../../../../../store";

export const costBreakdownWidgetSetupForecastedOptionsSelector = (
  state: RootState,
) => {
  const widgetData = costBreakdownWidgetSetupDataSelector(state);

  return widgetData?.forecasted_costs;
};
