import { reservationCoverageGroupedByCommitmentColumns } from "./coverage/reservationCoverageGroupedByCommitmentColumns";
import { reservationCoverageGroupedByResourceTypeColumns } from "./coverage/reservationCoverageGroupedByResourceTypeColumns";
import { reservationGroupedByResourceTypeColumns } from "./reservation/reservationGroupedByResourceTypeColumns";
import { reservationCoverageGroupedByAccountColumns } from "./coverage/reservationCoverageGroupedByAccountColumns";
import { reservationGroupedByAccountColumns } from "./reservation/reservationGroupedByAccountColumns";
import { ColumnSetupType } from "../../../../../../../../../storybook/data-grid/utils/types/types";
import { ReservationsCoverageDataWithId } from "../../../../../../common/utils/types";
import { SavingsPlanCoverageGrouping } from "../../../../../../../../../services/cloudchipr.api";
import { DataViewTab } from "../../../../../../../../../store/commitments/utils/types/types";

type Columns = Partial<
  Record<
    SavingsPlanCoverageGrouping,
    ColumnSetupType<ReservationsCoverageDataWithId>[]
  >
>;

export const reservationDrawerDataGridColumns: Partial<
  Record<DataViewTab, Columns>
> = {
  commitment: {
    resource_type: reservationGroupedByResourceTypeColumns,
    account: reservationGroupedByAccountColumns,
  },
  coverage: {
    account: reservationCoverageGroupedByAccountColumns,
    commitment: reservationCoverageGroupedByCommitmentColumns,
    resource_type: reservationCoverageGroupedByResourceTypeColumns,
  },
};
