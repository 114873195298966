import { FC } from "react";
import { Stack } from "@mui/material";
import { WorkflowActionsSwitcher } from "./action-select/WorkflowActionsSwitcher";
import { WorkflowGracePeriodContent } from "./grace-period/WorkflowGracePeriodContent";
import { WorkflowNotificationsContent } from "./WorkflowNotificationsContent";
import { WorkflowNotificationOptions } from "./notification-options/WorkflowNotificationOptions";

export const WorkflowActionsContent: FC = () => {
  return (
    <Stack spacing={2}>
      <WorkflowActionsSwitcher />

      <WorkflowNotificationsContent />

      <WorkflowGracePeriodContent />

      <WorkflowNotificationOptions />
    </Stack>
  );
};
