import { useCallback, useMemo } from "react";
import { Box, Switch, Tooltip } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../store/hooks";
import { liveUsageMgmtFilterTemplatesEnabledSelector } from "../../../../../../../store/live-usage-mgmt/selectors/store-selectors/liveUsageMgmtFilterTemplatesEnabledSelector";
import { liveUsageMgmtProviderSelector } from "../../../../../../../store/live-usage-mgmt/selectors/store-selectors/liveUsageMgmtProviderSelector";
import { resourceTypeSearchParams } from "../../../navigation/utils/constants";
import { ResourceType } from "../../../../../../../services/cloudchipr.api";
import { resourceGroups } from "../../../../../../../utils/constants/resources/resourceGroups";
import { useToggledResourceTypes } from "../../../../utils/hooks/useToggledResources";
import { liveUsageMgmtFilterTemplateEnabledChangeThunk } from "../../../../../../../store/live-usage-mgmt/thunks/liveUsageMgmtFilterTemplateEnabledChangeThunk";

export const LiveUsageMgmtEnableFilterTemplatesSwitcher = () => {
  const dispatch = useAppDispatch();
  const filterTemplatesEnabled = useAppSelector(
    liveUsageMgmtFilterTemplatesEnabledSelector,
  );
  const provider = useAppSelector(liveUsageMgmtProviderSelector);
  const [searchParams] = useSearchParams();

  const resourceTypeFromRouter = searchParams.get(
    resourceTypeSearchParams,
  ) as ResourceType;
  const resourceGroup = provider && resourceGroups.get(provider);
  const toggledResourceTypes = useToggledResourceTypes();

  const activeResourceGroup = useMemo(() => {
    return resourceGroup?.find(
      (rg) =>
        rg.value === resourceTypeFromRouter ||
        rg.resourceTypes.includes(resourceTypeFromRouter),
    );
  }, [resourceGroup, resourceTypeFromRouter]);

  const switchChangeHandler = useCallback(() => {
    dispatch(
      liveUsageMgmtFilterTemplateEnabledChangeThunk({
        resourceTypes: activeResourceGroup?.resourceTypes ?? [],
        toggledResourceTypes,
      }),
    );
  }, [dispatch, activeResourceGroup, toggledResourceTypes]);

  return (
    <Box px={1}>
      <Tooltip
        title={`Turn ${filterTemplatesEnabled ? "Off" : "On"} Filter Template`}
        arrow
        placement="top"
      >
        <Switch
          size="small"
          checked={filterTemplatesEnabled}
          onChange={switchChangeHandler}
        />
      </Tooltip>
    </Box>
  );
};
