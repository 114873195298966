import { createDraftSafeSelector } from "@reduxjs/toolkit";
import { ProviderType } from "../../../services/cloudchipr.api";
import { allAccountsGroupedByProviderSelector } from "../../accounts/selectors/all-providers/allAccountsGroupedByProviderSelector";
import { isStringProviderType } from "../../../utils/helpers/providers/isStringProviderType";

export const connectedProvidersSelector = createDraftSafeSelector(
  [allAccountsGroupedByProviderSelector],
  (accounts) => {
    if (!accounts) {
      return [];
    }

    return Object.entries(accounts).reduce((acc, [key, value]) => {
      if (value.length && isStringProviderType(key)) {
        acc.push(key);
      }

      return acc;
    }, [] as ProviderType[]);
  },
);
