import { Tooltip, Typography } from "@mui/material";

export const CsvDownloadAlertTitle = (name: string) => {
  const nameFirstPart = name.substring(0, 12);
  const nameLastPart = name.substring(name.length - 2, name.length);

  return (
    <Typography variant="body2">
      Preparation is complete:{" "}
      <Tooltip title={name}>
        <Typography component="span" variant="inherit" fontWeight="bold">
          {nameFirstPart}...{nameLastPart}.csv
        </Typography>
      </Tooltip>
    </Typography>
  );
};
