import { createAsyncThunk } from "@reduxjs/toolkit";
import { setUserRoleData } from "../../userSlice";
import { GroupedRolePermissions } from "../../utils/types/common";
import { userRoleDataPermissionsSelector } from "../../selectors/roles/userRoleDataPermissionsSelector";
import { RootState } from "../../../store";
import { userRoleDataPermissionsGroupsSelector } from "../../selectors/roles/userRoleDataPermissionsGroupsSelector";

export const userRoleGroupedPermissionsCollapsedChangeThunk = createAsyncThunk(
  "roles/userRoleGroupedPermissionsCollapsedChange",
  (collapsed: boolean, { dispatch, getState }) => {
    const state = getState() as RootState;
    const permissions = userRoleDataPermissionsSelector(state);
    if (!permissions) {
      return;
    }
    const groups = userRoleDataPermissionsGroupsSelector(state);

    const updatedData = groups.reduce((result, group) => {
      const newRef = { ...permissions[group] };
      result[group] = {
        ...newRef,
        collapsed,
      };
      return result;
    }, {} as GroupedRolePermissions);

    dispatch(
      setUserRoleData({
        permissions: updatedData,
      }),
    );
  },
);
