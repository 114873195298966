import { FC, Fragment } from "react";
import { Box, Chip, Drawer } from "@mui/material";
import WarningAmberRoundedIcon from "@mui/icons-material/WarningAmberRounded";
import { ProviderType } from "../../../../../../services/cloudchipr.api";
import { useDialog } from "../../../../../../utils/hooks/useDialog.hook";
import { useAppSelector } from "../../../../../../store/hooks";
import { rightsizingRecommendationsStatusesGroupedSelector } from "../../../../../../store/recommendations/selectors/rightsizing/statuses/rightsizingRecommendationsStatusesGroupedSelector";
import { DrawerHeader } from "../../rightsizing-recommendations/components/warnings/DrawerHeader";
import { DrawerWarningCard } from "../../rightsizing-recommendations/components/warnings/DrawerWarningCard";
import { DrawerMissingPermissionCard } from "../../rightsizing-recommendations/components/warnings/missing-permissions/DrawerMissingPermissionCard";

interface SingleRightsizingRecommendationWarningProps {
  accountId?: string;
  provider?: ProviderType;
}

export const SingleRightsizingRecommendationWarning: FC<
  SingleRightsizingRecommendationWarningProps
> = ({ accountId, provider }) => {
  const { open, openDialog, closeDialog } = useDialog();
  const statuses = useAppSelector(
    rightsizingRecommendationsStatusesGroupedSelector,
  );

  if (!provider || !accountId) {
    return null;
  }

  const statusesByProvider = statuses[provider];
  const missingPermissions = statusesByProvider.missing_permission.filter(
    (id) => id === accountId,
  );
  const disabled = statusesByProvider.disabled.filter((id) => id === accountId);

  if (!(missingPermissions.length + disabled.length)) {
    return null;
  }

  return (
    <Fragment>
      <Box bgcolor="grey.200" p={0.5} borderRadius={5}>
        <Chip
          icon={<WarningAmberRoundedIcon />}
          label={missingPermissions.length + disabled.length}
          variant="outlined"
          sx={{
            "& .MuiChip-icon": { color: (theme) => theme.palette.warning.main },
          }}
          onClick={openDialog}
        />
      </Box>

      <Drawer anchor="right" open={open} onClose={closeDialog}>
        <Box width={600}>
          <DrawerHeader onClose={closeDialog} />

          {disabled.map((accountId) => (
            <DrawerWarningCard
              key={accountId}
              provider={provider}
              accountId={accountId}
            />
          ))}

          {missingPermissions.map((accountId) => (
            <DrawerMissingPermissionCard
              key={accountId}
              provider={provider}
              accountId={accountId}
            />
          ))}
        </Box>
      </Drawer>
    </Fragment>
  );
};
