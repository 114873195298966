import { createDraftSafeSelector } from "@reduxjs/toolkit";
import { costAnomalyPreviewDataGridDataSelector } from "./costAnomalyPreviewDataGridDataSelector";
import { CostAnomalyPreviewDataSumsType } from "../../../../components/pages/alerts/components/drawer/components/cost-anomaly/components/preview-section/utils/types/types";

export const costAnomalyPreviewDataGridDataSumsSelector =
  createDraftSafeSelector([costAnomalyPreviewDataGridDataSelector], (data) => {
    return data?.reduce(
      (acc, currentValue) => {
        acc.averageSum += currentValue.average_cost;
        acc.totalCostSum += currentValue.total;
        acc.trendSum += currentValue.trend ?? 0;
        acc.previousPeriodSum += currentValue.previous_period_cost ?? 0;

        return acc;
      },
      {
        averageSum: 0,
        totalCostSum: 0,
        trendSum: 0,
        previousPeriodSum: 0,
      } as CostAnomalyPreviewDataSumsType,
    );
  });
