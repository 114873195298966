import { RootState } from "../../../../../../store";

import { reservationsDataForGranularSelector } from "../../reservationsDataForGranularSelector";

export const reservationsGranularDataUncoveredUsageSelector = (
  state: RootState,
) => {
  const data = reservationsDataForGranularSelector(state);

  return data?.date_granular_data?.uncovered_usage;
};
