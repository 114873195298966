import { SvgIcon, SvgIconProps } from "@mui/material";

function GCPSvgIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <svg
        width="40"
        height="32"
        viewBox="0 0 40 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_4961_86366)">
          <path
            d="M14.5849 8.59456H13.3649L9.85039 5.1898L9.67969 3.75494C18.0643 -3.71121 32.4838 0.554464 35.2301 11.1968C34.5774 11.1676 27.8647 10.224 27.0212 10.01C27.0212 10.01 26.6698 9.44089 26.484 9.47494C24.9887 7.88201 22.9211 6.90344 20.705 6.73975C18.489 6.57607 16.2922 7.23967 14.5648 8.59456H14.5849Z"
            fill="#EA4335"
          />
          <path
            d="M4.896 11.1968C5.69859 8.33243 7.3477 5.75765 9.64057 3.78906L14.5358 8.53627C13.4125 9.44795 12.5348 10.6106 11.9786 11.924C11.4223 13.2374 11.204 14.6621 11.3426 16.0754C5.68425 15.9976 5.68425 24.6019 11.3426 24.5241L20.0636 24.5241L20.9321 25.3606V30.4483L20.0636 31.2947L11.3426 31.2947C0.392421 31.7081 -4.32203 16.8487 4.896 11.1968Z"
            fill="#4285F4"
          />
          <path
            d="M28.8133 31.2411H20.0723V24.4753C21.468 24.3537 29.471 24.8158 30.6158 24.0959L31.8408 24.4607L35.3553 27.8655L35.6616 29.0523C33.6877 30.4884 31.2818 31.2573 28.8133 31.2411Z"
            fill="#34A853"
          />
          <path
            d="M28.8146 9.30955C39.3581 9.04689 44.1579 23.0696 35.6629 29.0134L30.5869 24.0959C37.6159 20.4285 28.5786 11.6832 24.8181 18.5073L19.7773 13.6044C20.8395 12.2615 22.2081 11.175 23.7759 10.4299C25.3438 9.68485 27.0683 9.30138 28.8146 9.30955Z"
            fill="#FBBC05"
          />
        </g>
        <defs>
          <clipPath id="clip0_4961_86366">
            <rect width="40" height="31.3043" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </SvgIcon>
  );
}

GCPSvgIcon.muiName = "GCPSvgIcon";

export default GCPSvgIcon;
