import { AnyAction, createAsyncThunk, ThunkDispatch } from "@reduxjs/toolkit";
import { RootState } from "../../../../store";
import {
  setResourceExplorerDataPoint,
  setResourceExplorerDates,
  setResourceExplorerForecastOption,
  setResourceExplorerGrouping,
  setResourceExplorerPath,
  setResourceExplorerTrendType,
} from "../../../resourceExplorerSlice";
import { ResourceExplorerPath } from "../../../utils/types/resourceExplorer";
import { generateDefaultPath } from "../../../utils/helpers/generateDefaultPath";
import {
  objectToUrl,
  urlToObject,
} from "../../../../../components/utils/helpers/dataToUrlToDataConvertors";
import { resourceExplorerStateSelector } from "../../../selectors/resourceExplorerStateSelector";

/**
 * if "path" is object will set that
 * if "path" is true will use path from URL
 * if "path" is false will generate default path
 **/
export const setResourceExplorerPathThunk = createAsyncThunk(
  "resourceExplorer/setResourceExplorerPathThunk",
  async (path: ResourceExplorerPath[] | boolean, { dispatch, getState }) => {
    if (path === true) {
      setResourceExplorerDataFromPath(dispatch);
      return;
    }

    if (!path) {
      generateAndSetResourceExplorerDefaultPath(dispatch, getState);
      return;
    }

    dispatch(setResourceExplorerPath(path));
    applyPathToURL(path);
  },
);

const setResourceExplorerDataFromPath = (
  dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
) => {
  const url = new URL(window.location.href);
  const pathFromUrl = url.searchParams.get(pathKeyInURL);
  const pathObj = urlToObject(pathFromUrl) as ResourceExplorerPath[];

  if (!pathObj) {
    return;
  }

  dispatch(setResourceExplorerPath(pathObj));
  const dataFromPath = pathObj?.at(-1);

  const filters = dataFromPath?.filterTree;

  if (filters && dataFromPath?.groupedBy) {
    dispatch(
      setResourceExplorerGrouping({
        grouping: dataFromPath.groupedBy,
        groupValues: dataFromPath?.groupValues,
      }),
    );
  }
  if (dataFromPath?.forecastOption) {
    dispatch(setResourceExplorerForecastOption(dataFromPath.forecastOption));
  }

  if (dataFromPath?.dates) {
    dispatch(setResourceExplorerDates(dataFromPath.dates));
  }

  if (dataFromPath?.dateGranularity) {
    dispatch(setResourceExplorerDataPoint(dataFromPath.dateGranularity));
  }

  if (dataFromPath?.trendType) {
    dispatch(setResourceExplorerTrendType(dataFromPath.trendType));
  }
};

const generateAndSetResourceExplorerDefaultPath = (
  dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
  getState: () => unknown,
) => {
  const resourceExplorerState = resourceExplorerStateSelector(
    getState() as RootState,
  );

  const grouping = resourceExplorerState.data?.grouping;
  const groupValues = resourceExplorerState.data?.group_values || undefined;

  const path = generateDefaultPath(grouping, groupValues);

  dispatch(setResourceExplorerPath(path));
  applyPathToURL(path, true);
};

const applyPathToURL = (path: any, replace?: boolean) => {
  const url = new URL(window.location.href);

  url.searchParams.set(pathKeyInURL, objectToUrl(path));
  if (replace) {
    window.history.replaceState({}, "", url.href);
  } else {
    window.history.pushState({}, "", url.href);
  }
};

export const pathKeyInURL = "path";
