import { FC, Fragment, useCallback } from "react";
import { Divider, Menu } from "@mui/material";
import { useToggle } from "rooks";
import { ResourceExplorerViewMoveAction } from "../action-items/move-action/ResourceExplorerViewMoveAction";
import { navigationHierarchyItemNoDndKey } from "../../../../sortable-tree/utils/constants";
import { NavigationItemsVisibilityType } from "../../../../../../../store/navigation/utils/types";
import { CopyURLAction } from "../../../../../../common/copy-url/CopyURLAction";
import { ResourceExplorerRenameActionItem } from "../action-items/rename-action/ResourceExplorerRenameActionItem";
import { ResourceExplorerDeleteActionItem } from "../action-items/delete-action/ResourceExplorerDeleteActionItem";
import { ResourceExplorerDuplicateActionItem } from "../action-items/duplicate-action/ResourceExplorerDuplicateActionItem";
import { useAppSelector } from "../../../../../../../store/hooks";
import { resourceExplorerHierarchyVisibilityIsDefaultSelector } from "../../../../../../../store/resource-explorer/selectors/views-hierarchy-visibility/resourceExplorerHierarchyVisibilityIsDefaultSelector";
import { ResourceExplorerCsvDownloader } from "../../../../../../pages/resource-explorer/components/resource-explorer-card/components/resource-explorer-data-grid/components/csv/ResourceExplorerCSVDownloader";
import { ViewSettingsDrawer } from "../action-items/view-settings-action/components/ViewSettingsDrawer";
import { ResourceExplorerSettingsActionItem } from "../action-items/view-settings-action/ResourceExplorerSettingsActionItem";

interface CurrentResourceExplorerActionsMenuProps {
  id: string;
  name: string;
  open: boolean;
  closeMenu(): void;
  anchor: HTMLElement | null;
  folderId?: string;
  visibility: NavigationItemsVisibilityType;
}

export const CurrentResourceExplorerActionsMenu: FC<
  CurrentResourceExplorerActionsMenuProps
> = ({ name, id, open, anchor, closeMenu, folderId, visibility }) => {
  const [settingDrawerOpen, toggleSettingsDrawerOpen] = useToggle();

  const isVisibilityItemDefault = useAppSelector((state) =>
    resourceExplorerHierarchyVisibilityIsDefaultSelector(state, id),
  );

  const settingsDrawerOpenHandler = useCallback(() => {
    toggleSettingsDrawerOpen();
    closeMenu();
  }, [closeMenu, toggleSettingsDrawerOpen]);

  return (
    <Fragment>
      <Menu
        open={open}
        onClose={closeMenu}
        anchorEl={anchor}
        sx={{ "& li": { my: 0.5 } }}
        {...navigationHierarchyItemNoDndKey}
      >
        <CopyURLAction
          link={`${window.location.host}/resource-explorer/${id}`}
          onClose={closeMenu}
        />

        <ResourceExplorerCsvDownloader
          trigger="menuItem"
          onDownload={closeMenu}
        />

        <Divider sx={dividerSx} />

        {!isVisibilityItemDefault && (
          <ResourceExplorerRenameActionItem
            id={id}
            name={name}
            onClose={closeMenu}
          />
        )}

        <ResourceExplorerSettingsActionItem
          onClick={settingsDrawerOpenHandler}
        />

        <Divider sx={dividerSx} />

        {!isVisibilityItemDefault && (
          <ResourceExplorerViewMoveAction
            id={id}
            name={name}
            folderId={folderId}
            onClose={closeMenu}
            visibility={visibility}
          />
        )}

        <ResourceExplorerDuplicateActionItem
          id={id}
          name={name}
          onClose={closeMenu}
          folderId={folderId}
          visibility={visibility}
        />

        <Divider sx={dividerSx} />

        {!isVisibilityItemDefault && (
          <ResourceExplorerDeleteActionItem
            id={id}
            name={name}
            onClose={closeMenu}
          />
        )}
      </Menu>

      <ViewSettingsDrawer
        open={settingDrawerOpen}
        onClose={toggleSettingsDrawerOpen}
      />
    </Fragment>
  );
};

const dividerSx = { m: "0px !important" };
