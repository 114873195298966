import { FC, useCallback, useMemo } from "react";
import { Box } from "@mui/material";
import { usePreviousDifferent } from "rooks";
import { reservationDrawerDataGridColumns } from "./columns/reservationDrawerDataGridColumns";
import { CommitmentsDrawerDataGridToolbar } from "../../../../../common/components/drawer/data-grid/CommitmentsDrawerDataGridToolbar";
import { DataGrid } from "../../../../../../../../storybook/data-grid/DataGrid";
import { ToolbarConfig } from "../../../../../../../../storybook/data-grid/utils/types/prop-types";
import { useAppSelector } from "../../../../../../../../store/hooks";
import { commitmentsDetailsDrawerCoverageGroupingSelector } from "../../../../../../../../store/commitments/selectors/common/details-drawer/commitmentsDetailsDrawerCoverageGroupingSelector";
import { CommitmentsGridLoadingLayer } from "../../../../../common/components/data-grid/CommitmentsGridLoadingLayer";
import { DataViewTab } from "../../../../../../../../store/commitments/utils/types/types";
import { EmptyDataGridFooter } from "../../../../../common/components/EmptyDataGridFooter";
import { getCommitmentsTableStyles } from "../../../../../common/utils/helpers/grid/getCommitmentsTableStyles";
import { coverageGroupingNames } from "../../../../../common/utils/constants/labels";
import { reservationDetailsCoverageDataSelector } from "../../../../../../../../store/commitments/selectors/reservations/details-drawer/data/reservationDetailsCoverageDataSelector";
import { reservationDetailsLoadingSelector } from "../../../../../../../../store/commitments/selectors/reservations/details-drawer/data/reservationDetailsLoadingSelector";

interface ReservationDrawerDataGridProps {
  viewTab: DataViewTab;
}
export const ReservationDrawerDataGrid: FC<ReservationDrawerDataGridProps> = ({
  viewTab,
}) => {
  const data = useAppSelector(reservationDetailsCoverageDataSelector);
  const loading = useAppSelector(reservationDetailsLoadingSelector);
  const grouping = useAppSelector(
    commitmentsDetailsDrawerCoverageGroupingSelector,
  );

  const prevData = usePreviousDifferent(data);
  const gridData = data ?? prevData ?? [];

  const columns = useMemo(() => {
    return reservationDrawerDataGridColumns?.[viewTab]?.[grouping] ?? [];
  }, [grouping, viewTab]);

  const empty = !loading && !gridData.length;

  const Footer = useCallback(() => {
    if (!empty) {
      return undefined;
    }

    return <EmptyDataGridFooter name={coverageGroupingNames[grouping]} />;
  }, [empty, grouping]);

  if (!columns) {
    return null;
  }

  return (
    <Box mt={3} position="relative" zIndex={1}>
      {loading && <CommitmentsGridLoadingLayer />}

      <DataGrid
        columnSorting
        toolbar={toolbar}
        styles={getCommitmentsTableStyles(empty)}
        data={gridData}
        columns={columns}
        Footer={Footer}
      />
    </Box>
  );
};

const toolbar: ToolbarConfig = {
  renderToolbar: (props) => <CommitmentsDrawerDataGridToolbar {...props} />,
};
