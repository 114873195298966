import { IconButton, Stack, Typography } from "@mui/material";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { FC } from "react";

interface GcpBillingExportDrawerHeaderProps {
  onClose(): void;
}
export const GcpBillingExportDrawerHeader: FC<
  GcpBillingExportDrawerHeaderProps
> = ({ onClose }) => {
  return (
    <Stack
      justifyContent="space-between"
      spacing={1}
      direction="row"
      py={1.5}
      px={3}
      alignItems="center"
    >
      <Typography variant="subtitle1">GCP Billing Exports</Typography>
      <IconButton onClick={onClose}>
        <CloseOutlinedIcon fontSize="small" />
      </IconButton>
    </Stack>
  );
};
