import { createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../../../store";
import { setAlertsData } from "../../alertsSlice";
import { alertsDataThresholdItemsSelector } from "../../selectors/common/data/alertsDataThresholdItemsSelector";

export const deleteUtilizationAlertThresholdByIndexThunk = createAsyncThunk(
  "alerts/deleteUtilizationAlertThresholdByIndex",
  async (index: number, { dispatch, getState }) => {
    const state = getState() as RootState;

    const thresholdItems = alertsDataThresholdItemsSelector(state);

    const updatedThresholdItems = thresholdItems.filter(
      (_, itemIndex) => index !== itemIndex,
    );

    dispatch(
      setAlertsData({
        threshold: {
          threshold_items: updatedThresholdItems,
        },
      }),
    );
  },
);
