import {
  amber,
  blue,
  cyan,
  deepPurple,
  indigo,
  orange,
  pink,
  grey,
  teal,
} from "@mui/material/colors";

export const taskPropertiesColors = [
  blue[400],
  pink[300],
  teal[300],
  amber[400],
  pink[200],
  cyan[700],
  indigo[400],
  cyan[400],
  orange[400],
  deepPurple[400],
];

export const taskPropertyColorByDefault = grey[400];
