import { RootState } from "../../store";
import { cloudChiprApi } from "../../../services/cloudchipr.api";
import { getExecutionLogDetailsThunkFixedCacheKey } from "../thunks/getExecutionLogDetailsThunk";

const selector = cloudChiprApi.endpoints.postUsersMeExecutionLogsDetails.select(
  getExecutionLogDetailsThunkFixedCacheKey,
);

export const executionLogTotalPriceSelector = (
  state: RootState,
): number | undefined => {
  return selector(state)?.data?.total_price;
};
