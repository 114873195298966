import { FC } from "react";
import { Alert, Button, Collapse } from "@mui/material";
import { blue } from "@mui/material/colors";
import { useToggle } from "rooks";
import { useAppSelector } from "../../../../../../../store/hooks";
import { taskManagementAlreadyHasTaskResourcesIdsSelector } from "../../../../../../../store/task-management/selectors/form/target/resources/taskManagementAlreadyHasTaskResourcesIdsSelector";
import { TypographyWithTooltip } from "../../../../../../common/TypographyWithTooltip";
import { isTaskManagementDrawerEditModeSelector } from "../../../../../../../store/task-management/selectors/form/options/isTaskManagementDrawerEditModeSelector";

export const TaskCreationDuplicatedResourcesAlertMessage: FC = () => {
  const [collapsed, toggleCollapsed] = useToggle();

  const resources = useAppSelector(
    taskManagementAlreadyHasTaskResourcesIdsSelector,
  );
  const editMode = useAppSelector(isTaskManagementDrawerEditModeSelector);

  if (!resources?.length || editMode) {
    return null;
  }

  return (
    <Alert
      variant="filled"
      severity="info"
      sx={{
        bgcolor: blue[100],
        fontWeight: "normal",
        color: "primary.main",
      }}
      action={
        <Button
          size="small"
          onClick={toggleCollapsed}
          sx={{ whiteSpace: "nowrap" }}
        >
          {collapsed ? "Hide" : "Show"}
        </Button>
      }
    >
      Tasks will not be created for the following {resources.length} resource
      {resources.length > 1 ? "s " : " "}
      because {resources.length > 1 ? "they" : "it"} already have task
      {resources.length > 1 ? "s " : " "} assigned.
      <Collapse in={collapsed}>
        <ul>
          {resources.map((id) => {
            return (
              <li key={id}>
                <TypographyWithTooltip variant="body2" title={id} />
              </li>
            );
          })}
        </ul>
      </Collapse>
    </Alert>
  );
};
