import { getDatesFromTimeInterval } from "./getDatesFromTimeInterval";
import { CostAnomalyAlertTimeIntervalUnit } from "../types/types";
import { objectToUrl } from "../../../../components/utils/helpers/dataToUrlToDataConvertors";
import {
  Dates,
  DateUnit,
  ResourceExplorerGrouping,
} from "../../../../services/cloudchipr.api";

export const getViewLinkByAlertData = (
  grouping: ResourceExplorerGrouping,
  timeIntervalUnit: CostAnomalyAlertTimeIntervalUnit,
  timeInterval?: string,
) => {
  const { dateTo, dateFrom } = getDatesFromTimeInterval(
    timeInterval,
    timeIntervalUnit,
  );
  const date: Dates = {
    to: dateTo,
    from: dateFrom,
    unit: (timeIntervalUnit?.slice(0, -1) ?? "day") as DateUnit,
  };
  const path = objectToUrl([
    {
      groupedBy: grouping,
      dates: date,
    },
  ]);

  return `path=${path}`;
};
