import { ColumnSetupType } from "../../../../../../../../../../../storybook/data-grid/utils/types/types";
import { ReservationsCoverageDataWithId } from "../../../../../../../../common/utils/types";
import { CommitmentsProgressBar } from "../../../../../../../../common/components/CommitmentsProgressBar";

export const totalCoverageColumn: ColumnSetupType<ReservationsCoverageDataWithId> =
  {
    accessorKey: "total_coverage",
    header: "Total Coverage",
    cell: ({ row }) => (
      <CommitmentsProgressBar value={row.original.total_coverage ?? null} />
    ),
  };
