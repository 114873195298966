import { allowedFiltersSelector } from "./allowedFiltersSelector";
import { RootState } from "../../store";
import { ResourceType } from "../../../services/cloudchipr.api";

export const allowedFiltersByResourceTypeSelector = (
  state: RootState,
  resourceType: ResourceType,
) => {
  const allowedFilters = allowedFiltersSelector(state);

  const allowedFiltersByResourceType = allowedFilters.find(
    (f) => f.type === resourceType,
  );

  return allowedFiltersByResourceType?.filters.length
    ? allowedFiltersByResourceType?.filters
    : allowedFiltersByResourceType?.child_filters?.[0]?.filters;
};
