import { FC, Fragment, memo, useCallback } from "react";
import { Grid } from "@mui/material";
import { Editor } from "../../../../../../../storybook/editor/Editor";
import { RowLabelColumn } from "../RowLabelColumn";

interface DescriptionRowProps {
  description: string;
  setFieldValue(key: string, value: string): void;
}

export const DescriptionRow: FC<DescriptionRowProps> = memo(
  ({ description, setFieldValue }) => {
    const changeHandler = useCallback(
      (value: string) => {
        setFieldValue("body.description", value);
      },
      [setFieldValue],
    );

    return (
      <Fragment>
        <RowLabelColumn label="Description" required />

        <Grid item sm={10}>
          <Editor onChange={changeHandler} value={description} />
        </Grid>
      </Fragment>
    );
  },
);
