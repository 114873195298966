import { createAsyncThunk } from "@reduxjs/toolkit";
import { cloudChiprApi } from "../../../../services/cloudchipr.api";

export const getOffHoursThunk = createAsyncThunk(
  "automation/getOffHours",
  async (forceRefetch: boolean | undefined, { dispatch }) => {
    const { getUsersMeOffHoursSchedules } = cloudChiprApi.endpoints;

    return await dispatch(
      getUsersMeOffHoursSchedules.initiate({}, { forceRefetch: forceRefetch }),
    ).unwrap();
  },
);
