import { FC } from "react";
import { Stack, Typography } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

export const NoCommitmentsCard: FC = () => {
  return (
    <Stack
      flex={1}
      bgcolor="grey.50"
      m={2}
      borderRadius={2}
      alignItems="center"
      justifyContent="center"
      spacing={3}
    >
      <InfoOutlinedIcon sx={{ fontSize: 48, color: "grey.400" }} />
      <Typography variant="body1">No Commitments Found</Typography>
    </Stack>
  );
};
