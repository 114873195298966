import { FC, useCallback, useMemo, useState } from "react";
import { useEffectOnceWhen } from "rooks";
import {
  AutocompleteTrigger,
  AutocompleteTriggerProps,
} from "./components/AutocompleteTrigger";
import { findExtraOptions } from "./utils/helpers/findExtraOptions";
import {
  DropdownSelect,
  DropdownSelectProps,
} from "../dropdown-select/DropdownSelect";
import { DropdownSelectOption } from "../dropdown-select/utils/types/types";
import { FilterTriggerComponentProps } from "../dropdown-select/utils/types/filterTriggerComponentProps";

type DropdownSelectExtendedProps = Omit<
  DropdownSelectProps,
  "TriggerComponent" | "submitHandlerOnClose" | "setExtraOptions"
>;

type AutocompleteRenderTagsExtendedProps = Pick<
  AutocompleteTriggerProps,
  | "formatSelectedLabel"
  | "freeSolo"
  | "placeholder"
  | "RenderTagsComponent"
  | "onChange"
  | "allSelectedLabel"
  | "size"
>;

interface AutocompleteSelectProps
  extends DropdownSelectExtendedProps,
    AutocompleteRenderTagsExtendedProps {}

export const AutocompleteSelect: FC<AutocompleteSelectProps> = ({
  allSelectedLabel,
  onChange,
  error,
  RenderTagsComponent,
  formatSelectedLabel,
  size,
  freeSolo,
  creatable,
  placeholder,
  showSearch = false,
  wrapperVariant,
  ...dropdownProps
}) => {
  const label = dropdownProps.label;
  const disabled = !!dropdownProps.disabled;
  const singleSelect = !!dropdownProps.singleSelect;
  const optionsLoading = dropdownProps.optionsLoading;

  const [extraOptions, setExtraOptions] = useState<DropdownSelectOption[]>([]);

  const options: DropdownSelectOption[] = useMemo(() => {
    return freeSolo
      ? dropdownProps.options.concat(extraOptions)
      : dropdownProps.options;
  }, [freeSolo, dropdownProps.options, extraOptions]);

  const TriggerComponent = useCallback(
    (triggerProps: FilterTriggerComponentProps) => {
      return (
        <AutocompleteTrigger
          size={size}
          label={label}
          error={error}
          freeSolo={freeSolo}
          disabled={disabled}
          onChange={onChange}
          placeholder={placeholder}
          singleSelect={singleSelect}
          triggerProps={triggerProps}
          setExtraOptions={setExtraOptions}
          RenderTagsComponent={RenderTagsComponent}
          formatSelectedLabel={formatSelectedLabel}
          allSelectedLabel={allSelectedLabel}
          options={options}
          loading={optionsLoading}
        />
      );
    },
    [
      size,
      freeSolo,
      singleSelect,
      error,
      label,
      disabled,
      onChange,
      placeholder,
      allSelectedLabel,
      formatSelectedLabel,
      RenderTagsComponent,
      options,
      optionsLoading,
    ],
  );

  useEffectOnceWhen(
    () => {
      if (!freeSolo) {
        return;
      }

      const extraOptions = findExtraOptions(
        dropdownProps.options,
        dropdownProps.initialSelectedValues,
      );

      if (extraOptions?.length) {
        setExtraOptions(extraOptions);
      }
    },
    !!(freeSolo && dropdownProps.initialSelectedValues?.length),
  );

  return (
    <DropdownSelect
      {...dropdownProps}
      options={options}
      showSearch={showSearch}
      wrapperVariant={wrapperVariant ?? "dropdown"}
      submitHandlerOnClose={onChange}
      TriggerComponent={TriggerComponent}
      creatable={creatable}
      setExtraOptions={setExtraOptions}
    />
  );
};
