import { FC } from "react";
import { useAppSelector } from "../../../../../../../../../../../store/hooks";
import { commitmentUtilizationSetupPropertyByKeySelector } from "../../../../../../../../../../../store/dashboards/selectors/setups/commitment-utilization/commitmentUtilizationSetupPropertyByKeySelector";
import { commitmentUtilizationWidgetSetupDataSelector } from "../../../../../../../../../../../store/dashboards/selectors/widgets/commitment-utilization/setup/commitmentUtilizationWidgetSetupDataSelector";
import { WidgetCommitmentsUtilizationAggregation } from "../../../../../../../../../../../services/cloudchipr.api";
import { organisationQuarterStartMonthSelector } from "../../../../../../../../../../../store/common/selectors/org-current-settings/organisationQuarterStartMonthSelector";
import { widgetSetupWidgetDatesSelector } from "../../../../../../../../../../../store/dashboards/selectors/setups/common/widgetSetupWidgetDatesSelector";
import { CommitmentUtilizationWidgetCosts } from "../../../../../../../widgets/commitment-utlization/components/CommitmentUtilizationWidgetCosts";

export const CommitmentUtilizationWidgetSetupContentCosts: FC = () => {
  const aggregations: Required<WidgetCommitmentsUtilizationAggregation> =
    useAppSelector(
      commitmentUtilizationSetupPropertyByKeySelector("aggregation"),
    );
  const data = useAppSelector(commitmentUtilizationWidgetSetupDataSelector);
  const dates = useAppSelector(widgetSetupWidgetDatesSelector);
  const quarterStartMonth = useAppSelector(
    organisationQuarterStartMonthSelector,
  );

  return (
    <CommitmentUtilizationWidgetCosts
      data={data}
      dates={dates}
      quarterStartMonth={quarterStartMonth}
      aggregation={aggregations}
    />
  );
};
