import { FC, Fragment } from "react";
import { Link, Typography } from "@mui/material";

interface SsoFormHeaderProps {
  title: string;
  href: string;
}

export const SsoFormDialogHeader: FC<SsoFormHeaderProps> = ({
  title,
  href,
}) => {
  return (
    <Fragment>
      <Typography variant="body1">{title}</Typography>
      <Typography variant="body2" color="text.secondary">
        For detailed instructions please follow{" "}
        <Link href={href} target="_blank">
          the documentation.
        </Link>
      </Typography>
    </Fragment>
  );
};
