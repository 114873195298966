import { Dates } from "../../../../../../services/cloudchipr.api";
import { capitalizeString } from "../../../../../../utils/helpers/capitalizeString";

export const generateLabelByPeriod = (selectedDates: Dates) => {
  const plural = selectedDates.value && selectedDates.value > 1 ? "s" : "";

  if (!selectedDates.value || !selectedDates.unit || !selectedDates.type) {
    return "";
  }

  return `${capitalizeString(selectedDates.type)} ${selectedDates.value} ${
    selectedDates.unit
  }${plural}`;
};
