import {
  cloudChiprApi,
  PostUsersMeSchedulesNextRunDateApiResponse,
} from "../../../../../../services/cloudchipr.api";
import { RootState } from "../../../../../store";
import { AutomationTabType } from "../../../../../../components/pages/schedule/common/utils/types/types";
import { getScheduleNextRunDatesFixedCacheKey } from "../../../../utils/helpers/getScheduleNextRunDatesFixedCacheKey";

const selector = cloudChiprApi.endpoints.postUsersMeSchedulesNextRunDate.select;
export const scheduleNextRunDatesSelector = (
  state: RootState,
  frequency?: AutomationTabType,
): PostUsersMeSchedulesNextRunDateApiResponse | undefined => {
  return selector(getScheduleNextRunDatesFixedCacheKey(frequency))(state)?.data;
};
