import { FC, ReactNode, useCallback } from "react";
import { Link } from "@mui/material";
import {
  setCommitmentsDetailsDrawerDates,
  setCommitmentsDetailsDrawerTarget,
} from "../../../../../../../store/commitments/commitmentsSlice";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../store/hooks";
import { CommitmentFilterType } from "../../../../../../../services/cloudchipr.api";
import { commitmentsDatesSelector } from "../../../../../../../store/commitments/selectors/common/view-options/commitmentsDatesSelector";

interface CommitmentsTableNameProps {
  id: string;
  name: ReactNode;
  type: CommitmentFilterType;
}

export const CommitmentsTableName: FC<CommitmentsTableNameProps> = ({
  name,
  id,
  type,
}) => {
  const dispatch = useAppDispatch();
  const dates = useAppSelector(commitmentsDatesSelector);

  const clickHandler = useCallback(() => {
    dispatch(setCommitmentsDetailsDrawerDates(dates));
    dispatch(setCommitmentsDetailsDrawerTarget({ id, type }));
  }, [dispatch, dates, type, id]);

  return (
    <Link
      component="button"
      underline="none"
      onClick={clickHandler}
      sx={{ overflow: "hidden", width: "100%" }}
    >
      {name}
    </Link>
  );
};
