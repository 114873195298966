import { FC, useCallback, useMemo } from "react";
import { Alert, AlertTitle, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { FormikErrors } from "formik";
import { NotificationsSelection } from "../../../../../../common/notification-selection/NotificationsSelection";
import { EmailsSelectionErrors } from "../../../../../../../common/integration-dialogs/components/email/components/email-selection/EmailsSelection";
import { IntegrationNotification } from "../../../../../../common/notification-selection/utils/types/types";
import { ScheduleActionsStepInitialData } from "../../../../../../schedule/common/utils/types/types";
import {
  Emails,
  GracePeriod,
  NotificationEmail,
  NotificationJira,
  NotificationSlack,
  NotificationWebhook,
} from "../../../../../../../../services/cloudchipr.api";
import {
  IntegrationCustomMessage,
  IntegrationCustomMessageProps,
} from "../../../../../common/components/custom-message/IntegrationCustomMessage";

export type GracePeriodNotificationsType = (
  | NotificationEmail
  | NotificationSlack
  | NotificationWebhook
  | NotificationJira
)[];

export type GracePeriodNotificationsError = FormikErrors<
  ScheduleActionsStepInitialData["grace_period"]
>;

interface GracePeriodNotificationsProps {
  gracePeriod?: GracePeriod;
  error?: GracePeriodNotificationsError;
  onChange(gracePeriod: GracePeriod | null): void;
}

export const GracePeriodNotifications: FC<GracePeriodNotificationsProps> = ({
  gracePeriod,
  onChange,
  error,
}) => {
  const emailNotification = useMemo(() => {
    return (gracePeriod?.notifications?.find(
      (notification) => !notification.integration_id,
    ) ?? null) as Emails;
  }, [gracePeriod?.notifications]);

  const integrations = useMemo(() => {
    return gracePeriod?.notifications?.filter(
      (notification) => !!notification.integration_id,
    );
  }, [gracePeriod?.notifications]);

  const notificationChangeHandler = useCallback(
    (integrations: IntegrationNotification[] | null) => {
      const gracePeriodNotifications: GracePeriodNotificationsType =
        integrations ?? [];

      let notifications: GracePeriodNotificationsType = [];

      if (!integrations && emailNotification) {
        notifications = [emailNotification];
      } else if (integrations && !emailNotification) {
        notifications = gracePeriodNotifications;
      } else if (integrations && emailNotification) {
        notifications = [...gracePeriodNotifications, emailNotification];
      }

      onChange({ ...gracePeriod, notifications });
    },
    [onChange, emailNotification, gracePeriod],
  );

  const emailsChangeHandler = useCallback(
    (data: Emails | null) => {
      const emails = data ? { ...data, type: "email" } : null;

      let notifications = [...(gracePeriod?.notifications ?? [])];

      const emailsIncluded = notifications.some(
        ({ integration_id }) => !integration_id,
      );

      if (emails && emailsIncluded) {
        notifications = notifications.map((notification) => {
          if (notification.integration_id) {
            return notification;
          }

          return emails;
        });
      } else if (emails && !emailsIncluded) {
        notifications.push(emails);
      } else if (!emails && emailsIncluded) {
        notifications = notifications.filter((notification) => {
          return !!notification.integration_id;
        });
      }

      onChange({ ...gracePeriod, notifications });
    },
    [onChange, gracePeriod],
  );

  const notIntegrationsEmailsIndex = gracePeriod?.notifications?.findIndex(
    (error) => !error.integration_id,
  );

  const emailsErrors = (
    Array.isArray(error?.notifications) && notIntegrationsEmailsIndex
      ? error?.notifications?.at(notIntegrationsEmailsIndex)
      : undefined
  ) as EmailsSelectionErrors;

  const integrationErrors = (
    Array.isArray(error?.notifications) && notIntegrationsEmailsIndex
      ? error?.notifications?.filter((_, i) => i !== notIntegrationsEmailsIndex)
      : undefined
  ) as IntegrationErrors[];

  const integrationCustomMessageHandler = useCallback(
    (props: IntegrationCustomMessageProps) => {
      return <IntegrationCustomMessage {...props} actionFrom="grace-period" />;
    },
    [],
  );

  return (
    <NotificationsSelection
      emailsErrors={emailsErrors}
      integrationErrors={integrationErrors}
      selectedEmails={emailNotification}
      selectedIntegrations={integrations}
      emailsChangeHandler={emailsChangeHandler}
      setSelectedIntegrations={notificationChangeHandler}
      disabledIntegrationTypes={["jira"]}
      IntegrationCustomMessage={integrationCustomMessageHandler}
    >
      {gracePeriod?.notifications?.length ? (
        <Alert
          variant="filled"
          severity="info"
          sx={{ bgcolor: grey[100], mt: 2 }}
          icon={<InfoOutlinedIcon fontSize="small" color="action" />}
        >
          <AlertTitle>
            <Typography color="text.secondary" variant="body2">
              Private link will be shared
            </Typography>
          </AlertTitle>
          <Typography color="text.secondary" variant="caption">
            Cloudchipr users will get a private link to access and protect their
            workflow resources.
          </Typography>
        </Alert>
      ) : null}
    </NotificationsSelection>
  );
};

export type IntegrationErrors = FormikErrors<Emails & NotificationSlack>;
