import { RootState } from "../../../store";
import { cloudChiprApi } from "../../../../services/cloudchipr.api";

export const getOrganisationsCurrentFilterTemplatesSelector = (
  state: RootState,
) => {
  return cloudChiprApi.endpoints.getUsersMeOrganisationsCurrentFilterSets.select(
    undefined,
  )(state);
};
