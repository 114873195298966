import { createAsyncThunk } from "@reduxjs/toolkit";
import { getScheduleNextRunDatesThunk } from "../../../schedules/thunks/getScheduleNextRunDatesThunk";
import { automationStartDateSelector } from "../../selectros/common/fields/automationStartDateSelector";
import { RootState } from "../../../store";
import { automationEndDateSelector } from "../../selectros/common/fields/automationEndDateSelector";
import { automationTimeZoneSelector } from "../../selectros/common/fields/automationTimeZoneSelector";
import { automationFrequencySelector } from "../../selectros/common/automationFrequencySelector";
import { workflowSchedulerSelector } from "../../selectros/workflow/workflowSchedulerSelector";
import { generateCronForPayload } from "../../utils/helpers/cron/generateCronForPayload";
import { workflowNextRunsAvailableSelector } from "../../selectros/workflow/next-runs/workflowNextRunsAvailableSelector";

export const fetchWorkflowNextRunsThunk = createAsyncThunk(
  "automation/fetchWorkflowNextRuns",
  async (_: void, { dispatch, getState }) => {
    const state = getState() as RootState;

    const workflowNextRunsAvailable = workflowNextRunsAvailableSelector(state);

    if (!workflowNextRunsAvailable) {
      return;
    }

    const startDate = automationStartDateSelector(state);
    const endDate = automationEndDateSelector(state);
    const timeZone = automationTimeZoneSelector(state);
    const frequency = automationFrequencySelector(state);
    const scheduler = workflowSchedulerSelector(state);

    const cron = generateCronForPayload(
      frequency,
      scheduler?.runTime ?? null,
      scheduler?.repeatValue,
      scheduler?.weekDays,
    );

    if (frequency === "once" || !cron) {
      return;
    }

    dispatch(
      getScheduleNextRunDatesThunk({
        cron,
        startDate,
        endDate,
        timeZone,
        frequency,
        interval: scheduler?.repeatValue,
      }),
    );
  },
);
