import { costAndUsageSumWidgetByIdSelector } from "./costAndUsageSumWidgetByIdSelector";
import { RootState } from "../../../../store";
import { buildingInProgressWidgetIdWithNoFilter } from "../../../thunks/widgets/utils/constants";

export const costAndUsageSumWidgetFiltersByIdSelector = (
  state: RootState,
  widgetId?: string,
): string[] => {
  const data = costAndUsageSumWidgetByIdSelector(
    state,
    widgetId ?? buildingInProgressWidgetIdWithNoFilter,
  );

  if (!data) {
    return [];
  }
  return data?.filter ?? [];
};
