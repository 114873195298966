import { createDraftSafeSelector } from "@reduxjs/toolkit";
import { recommendationComputeDismissedDataSelector } from "./compute/recommendationComputeDismissedDataSelector";
import { recommendationDbDismissedDataSelector } from "./database/recommendationDbDismissedDataSelector";
import { recommendationGridDataCombiner } from "../../../combiners/off-hours/recommendationGridDataCombiner";
import { RootState } from "../../../../store";
import { RecommendationGroupType } from "../../../../../components/pages/recommendations/components/off-hours-recommendations/utils/types/types";

export const recommendationDismissedDataSelector = createDraftSafeSelector(
  [
    recommendationComputeDismissedDataSelector,
    recommendationDbDismissedDataSelector,
    (_: RootState, group: RecommendationGroupType) => group,
  ],
  (computeData, dbData, group) => {
    const data = group === "compute" ? computeData : dbData;

    return recommendationGridDataCombiner(group, data);
  },
);
