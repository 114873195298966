import { generateOffHoursRecommendationFilter } from "./generateOffHoursRecommendationFilter";
import {
  OrganisationDailyOffHoursRecommendation,
  OrganisationWeeklyOffHoursRecommendation,
} from "../../../../services/cloudchipr.api";
import { AutomationsData } from "../types/common";
import { isWeeklyOffHoursRecommendation } from "../../../../components/pages/recommendations/components/off-hours-recommendations/utils/helpers/isWeeklyOffHoursRecommendation";

export const generateOffHoursRecommendationData = (
  recommendation:
    | OrganisationWeeklyOffHoursRecommendation
    | OrganisationDailyOffHoursRecommendation,
): Partial<AutomationsData> => {
  return {
    frequency: isWeeklyOffHoursRecommendation(recommendation)
      ? "weekly"
      : "daily",
    name: `${recommendation.resource_type}: ${recommendation.provider_unique_identifier} Off Hours`,

    provider: recommendation.account.provider,
    accountIds: [recommendation.account_id],
    regions: [recommendation.region],

    filter: generateOffHoursRecommendationFilter(
      recommendation.resource_type,
      recommendation.provider_unique_identifier,
    ),
  };
};
