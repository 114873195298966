import { FC, useCallback } from "react";
import { Button, Stack } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useAppDispatch, useAppSelector } from "../../../../../../store/hooks";
import { reportsSaveButtonDisabledSelector } from "../../../../../../store/reports/selector/reportsSaveButtonDisabledSelector";
import { saveReportThunk } from "../../../../../../store/reports/thunks/saveReportThunk";
import { saveReportLoadingSelector } from "../../../../../../store/reports/selector/saveReportLoadingSelector";

interface ReportsDrawerActionsProps {
  onClose(): void;
}

export const ReportsDrawerActions: FC<ReportsDrawerActionsProps> = ({
  onClose,
}) => {
  const dispatch = useAppDispatch();
  const disabled = useAppSelector(reportsSaveButtonDisabledSelector);
  const loading = useAppSelector(saveReportLoadingSelector);

  const saveHandler = useCallback(() => {
    dispatch(saveReportThunk());
  }, [dispatch]);

  return (
    <Stack direction="row" spacing={2.5}>
      <LoadingButton
        onClick={saveHandler}
        loading={loading}
        disabled={disabled}
        variant="contained"
      >
        Save
      </LoadingButton>

      <Button color="tertiary" onClick={onClose}>
        Cancel
      </Button>
    </Stack>
  );
};
