import { FC, useCallback } from "react";
import { ConfirmationDialog } from "../../../../../common/modals/ConfirmationDialog";
import { useAppDispatch, useAppSelector } from "../../../../../../store/hooks";
import { dismissPeriodSelector } from "../../../../../../store/recommendations/selectors/dismiss/dismissPeriodSelector";
import { recommendationDismissThunk } from "../../../../../../store/recommendations/thunks/recommendationDismissThunk";
import { recommendationDismissingLoadingSelector } from "../../../../../../store/recommendations/selectors/dismiss/recommendationDismissingLoadingSelector";
import { offHoursRecommendationsLoadingSelector } from "../../../../../../store/recommendations/selectors/off-hours/loading/offHoursRecommendationsLoadingSelector";
import { rightsizingRecommendationsLoadingSelector } from "../../../../../../store/recommendations/selectors/rightsizing/loading/rightsizingRecommendationsLoadingSelector";
import { recommendationsReadyToDismissSelector } from "../../../../../../store/recommendations/selectors/dismiss/recommendationsReadyToDismissSelector";
import {
  closeReadyToActionsDialogs,
  resetDismiss,
} from "../../../../../../store/recommendations/recommendationsSlice";
import { offHoursDismissedRecommendationsLoadingSelector } from "../../../../../../store/recommendations/selectors/off-hours/loading/offHoursDismissedRecommendationsLoadingSelector";
import { rightsizingDismissedRecommendationsLoadingSelector } from "../../../../../../store/recommendations/selectors/rightsizing/loading/rightsizingDismissedRecommendationsLoadingSelector";
import { selectedIdsSelector } from "../../../../../../store/recommendations/selectors/selected-ids/selectedIdsSelector";
import { recommendationMessages } from "../../../utils/constants/recommendationDismissMessages";

export const RecommendationDismissConfirmDialog: FC = () => {
  const dispatch = useAppDispatch();

  const open = useAppSelector(recommendationsReadyToDismissSelector);
  const ids = useAppSelector(selectedIdsSelector);
  const multiple = ids?.length > 1;

  const dismissPeriod = useAppSelector(dismissPeriodSelector);
  const dismissLoading = useAppSelector(
    recommendationDismissingLoadingSelector,
  );
  const offHoursGetLoading = useAppSelector(
    offHoursRecommendationsLoadingSelector,
  );
  const offHoursDismissedGetLoading = useAppSelector(
    offHoursDismissedRecommendationsLoadingSelector,
  );
  const rightSizingGetLoading = useAppSelector(
    rightsizingRecommendationsLoadingSelector,
  );
  const rightSizingDismissedGetLoading = useAppSelector(
    rightsizingDismissedRecommendationsLoadingSelector,
  );

  const loading =
    dismissLoading ||
    offHoursGetLoading ||
    offHoursDismissedGetLoading ||
    rightSizingDismissedGetLoading ||
    rightSizingGetLoading;

  const closeHandler = useCallback(() => {
    dispatch(closeReadyToActionsDialogs());
  }, [dispatch]);

  const dismissConfirmHandler = useCallback(async () => {
    await dispatch(recommendationDismissThunk());
    dispatch(resetDismiss());
  }, [dispatch]);

  const confirmationMessage = recommendationMessages({
    action: "dismiss",
    multiple: multiple,
    period: dismissPeriod,
  });

  return (
    <ConfirmationDialog
      open={open}
      title={`Dismiss recommendation${multiple ? "s" : ""}?`}
      dialogContent={confirmationMessage}
      CancelButtonProps={{ onClick: closeHandler }}
      ConfirmButtonProps={{
        loading: loading,
        children: "Dismiss",
        onClick: dismissConfirmHandler,
      }}
      onClose={closeHandler}
    />
  );
};
