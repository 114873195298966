import { FC } from "react";
import { Stack, Typography } from "@mui/material";
import { LiveUsageMgmtResourcesCount } from "../toolbar/LiveUsageMgmtResourcesCount";
import { ResourceType } from "../../../../../../../../../../services/cloudchipr.api";
import { getFirstParentInHierarchyResourceType } from "../../../../../../../../../../utils/helpers/resources/getFirstParentInHierarchyResourceType";
import { ResourceFiltersTextView } from "../../../../resource-filters/ResourceFiltersTextView";
import { getChildResourceType } from "../../../../../../../../../../utils/helpers/resources/getChildResourceType";

interface CardTableFilteredResultsCellProps {
  count: number;
  totalCount: number;
  resourceType: ResourceType;
}

export const CardTableFilteredResultsCell: FC<
  CardTableFilteredResultsCellProps
> = ({ count, totalCount, resourceType }) => {
  const localResourceType =
    getFirstParentInHierarchyResourceType(resourceType) ?? resourceType;
  const childResourceType = getChildResourceType(resourceType);

  return (
    <Stack direction="row" spacing={0.5} alignItems="center">
      <Typography color="text.secondary">|</Typography>
      <ResourceFiltersTextView resourceType={localResourceType} />
      <LiveUsageMgmtResourcesCount
        color="text.secondary"
        resourceType={childResourceType}
        dataCount={count}
        totalDataCount={totalCount}
        filtersApplied={count !== totalCount}
      />
    </Stack>
  );
};
