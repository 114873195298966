import { FC, useCallback, useState } from "react";
import moment from "moment";
import { IconButton, InputAdornment } from "@mui/material";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import { MobileDateTimePicker } from "@mui/x-date-pickers";

interface DateTimePickerProps {
  date: string | null;
  onChange(e: any): void;
  error?: string | boolean;
  minDateTime: string;
  isDisabled?: boolean;
}

export const DateTimePicker: FC<DateTimePickerProps> = ({
  date,
  onChange,
  error,
  minDateTime,
  isDisabled,
}) => {
  const [open, setOpen] = useState(false);

  const handleOpen = useCallback(() => setOpen(true), []);
  const handleClose = useCallback(() => setOpen(false), []);

  return (
    <MobileDateTimePicker
      disabled={isDisabled}
      open={open}
      ampm
      value={date ? moment(date) : null}
      label="Date"
      onChange={onChange}
      onOpen={handleOpen}
      onClose={handleClose}
      minDateTime={moment(minDateTime)}
      slotProps={{
        textField: {
          name: "startDate",
          size: "small",
          helperText: error,
          variant: "outlined",
          error: !!error,
          disabled: isDisabled,
          sx: { width: 220 },
          InputProps: {
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  edge="end"
                  onClick={handleOpen}
                  disabled={isDisabled}
                >
                  <CalendarTodayIcon />
                </IconButton>
              </InputAdornment>
            ),
          },
        },
      }}
    />
  );
};
