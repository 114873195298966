import { FC } from "react";
import { Stack, Typography } from "@mui/material";
import { money } from "../../../../../../../../../../../utils/numeral/money";
import { formatDateByFrequencyType } from "../../../../../../../../../../../utils/helpers/date-time/datetime-format";
import { useAppSelector } from "../../../../../../../../../../../store/hooks";
import { resourceExplorerGranularTotalCostByKeySelector } from "../../../../../../../../../../../store/resource-explorer/selectors/current-resource-explorer/data-grid/granular-costs-view/resourceExplorerGranularTotalCostByKeySelector";
import { resourceExplorerDataPointSelector } from "../../../../../../../../../../../store/resource-explorer/selectors/current-resource-explorer/data/resourceExplorerDataPointSelector";

interface ResourceExplorerGranularDateHeadersCellProps {
  date: string;
}

export const ResourceExplorerGranularDateHeadersCell: FC<
  ResourceExplorerGranularDateHeadersCellProps
> = ({ date }) => {
  const total = useAppSelector((state) =>
    resourceExplorerGranularTotalCostByKeySelector(state, date),
  );
  const dataPoint = useAppSelector(resourceExplorerDataPointSelector);

  return (
    <Stack alignItems="end" width="100%">
      {dataPoint && (
        <Typography variant="body2" fontWeight="medium">
          {formatDateByFrequencyType(dataPoint, date)}
        </Typography>
      )}

      <Typography
        fontSize={10}
        variant="caption"
        color="text.disabled"
        fontWeight="medium"
      >
        {money(total)}
      </Typography>
    </Stack>
  );
};
