import moment from "moment/moment";
import { DurationInputArg2 } from "moment";
import { DateType, DateUnit } from "../../../../../../services/cloudchipr.api";
import { formatDate } from "../../../../../../utils/helpers/date-time/datetime-format";

export const generateFromToByPeriod = (
  value: string | number,
  dateType: DateType,
  dateUnit?: DateUnit | null | DurationInputArg2,
) => {
  const unit =
    dateUnit === "week" ? ("isoWeek" as DurationInputArg2) : dateUnit;

  let from = moment();
  let to = moment();

  if (!unit) {
    return {
      from: formatDate(from, { type: "date" }),
      to: formatDate(to, { type: "date" }),
    };
  }

  if (dateType === "past") {
    from = from.subtract(value, unit);
  }

  if (dateType === "last") {
    to = moment().endOf(unit).subtract(1, unit).endOf(unit);
    from = moment(to).subtract(value, unit).add(1, "day");
  }

  if (from.isBefore(moment().set("year", 1970)) || !moment(from).isValid()) {
    return {
      from: "",
      to: "",
    };
  }

  return {
    from: formatDate(from, { type: "date" }),
    to: formatDate(to, { type: "date" }),
  };
};
