import { RootState } from "../../store";
import { AccountAttemptStatusDetailsAzureStarted } from "../../../services/cloudchipr.api";

export const azureServicePrincipalSelector = (
  state: RootState,
): string | undefined =>
  (
    state.accountSetup.accountAttempt?.status_details
      ?.started as AccountAttemptStatusDetailsAzureStarted
  )?.service_principal;
