import { FC } from "react";
import { Stack } from "@mui/material";
import Typography from "@mui/material/Typography";
import { IntegrationCostAnomalyAlertSubject } from "../message/IntegrationCostAnomalyAlertSubject";
import { IntegrationCostAnomalyAlertBody } from "../message/IntegrationCostAnomalyAlertBody";
import { CreateIntegrationMessageEditor } from "../../../../../../../../common/integration-dialogs/components/common/CreateIntegrationMessageEditor";
import { integrationCustomEmailCostAnomalyDataGridColumns } from "../../utils/constants/constants";
import { EmailIntegrationCsvInfo } from "../../../../../../../automations/common/components/custom-message/email/common/EmailIntegrationCsvInfo";
import { IntegrationCustomMessageDataGrid } from "../../../../../../../automations/common/components/custom-message/common/IntegrationCustomMessageDataGrid";

interface IntegrationsCostAnomalyAlertDialogContentProps {
  customMessage: string;
  onCustomMessageChange(message: string): void;
  type: "email" | "slack";
}

export const IntegrationsCostAnomalyAlertDialogContent: FC<
  IntegrationsCostAnomalyAlertDialogContentProps
> = ({ customMessage, onCustomMessageChange, type }) => {
  return (
    <Stack spacing={1}>
      {type === "email" && <IntegrationCostAnomalyAlertSubject />}

      <IntegrationCostAnomalyAlertBody />

      <CreateIntegrationMessageEditor
        value={customMessage ?? ""}
        onChange={onCustomMessageChange}
        placeholder="Write a custom message..."
      />

      <Typography variant="body1">
        Please review and take the necessary actions.
      </Typography>
      <IntegrationCustomMessageDataGrid
        data={integrationCustomEmailCostAnomalyDataGridColumns}
        label=""
      />
      {type === "email" && <EmailIntegrationCsvInfo />}
    </Stack>
  );
};
