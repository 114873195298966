import { AccountTabs } from "../types/types";

export const defaultAccountTab: AccountTabs = "active";

export const accountTabs: {
  value: string;
  label: string;
}[] = [
  {
    value: "active",
    label: "active accounts",
  },
  {
    value: "inactive",
    label: "inactive accounts",
  },
];
