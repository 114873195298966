import { FC } from "react";
import {
  Card,
  Divider,
  Table,
  TableContainer,
  Typography,
} from "@mui/material";
import { CommitmentsOverviewCoverageTableHeader } from "./components/CommitmentsOverviewCoverageTableHeader";
import { CommitmentsOverviewCoverageTableBody } from "./components/CommitmentsOverviewCoverageTableBody";
import { useAppSelector } from "../../../../../../../store/hooks";
import { overviewCoveragesSelector } from "../../../../../../../store/commitments/selectors/overview/overviewCoveragesSelector";
import { overviewLoadingSelector } from "../../../../../../../store/commitments/selectors/overview/loading/overviewLoadingSelector";
import { CommitmentsOverviewEmptyState } from "../common/CommitmentsOverviewEmptyState";
import { CommitmentsOverviewContentLoading } from "../common/CommitmentsOverviewContentLoading";

export const CommitmentsOverviewCoverage: FC = () => {
  const coverages = useAppSelector(overviewCoveragesSelector);
  const loading = useAppSelector(overviewLoadingSelector);

  const content =
    coverages?.length === 0 ? (
      <CommitmentsOverviewEmptyState dataType="coverage" />
    ) : (
      <TableContainer>
        <Table size="small" sx={{ my: 1 }}>
          <CommitmentsOverviewCoverageTableHeader />
          <CommitmentsOverviewCoverageTableBody />
        </Table>
      </TableContainer>
    );

  return (
    <Card variant="outlined" sx={{ flex: 1 }}>
      <Typography variant="subtitle2" color="text.secondary" px={2} py={1}>
        Coverage
      </Typography>
      <Divider />

      {loading ? <CommitmentsOverviewContentLoading /> : content}
    </Card>
  );
};
