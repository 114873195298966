import { FC } from "react";
import { Stack } from "@mui/material";
import { DailyInterval } from "./DailyInterval";
import { useAppSelector } from "../../../../../../../../../store/hooks";
import { automationFrequencySelector } from "../../../../../../../../../store/automations/selectros/common/automationFrequencySelector";
import { OffHoursSchedulerFieldLabel } from "../../OffHoursSchedulerFieldLabel";
import { OffHoursStopStartTime } from "../../OffHoursStopStartTime";
import { offHoursSchedulerSelector } from "../../../../../../../../../store/automations/selectros/off-hours/offHoursSchedulerSelector";
import { AutomationTimeZone } from "../../../../../../common/components/form/schedule-content/AutomationTimeZone";
import { StopDateInfoTooltip } from "../../StopDateInfoTooltip";

export const OffHoursDailyScheduler: FC = () => {
  const frequency = useAppSelector(automationFrequencySelector);
  const scheduler = useAppSelector(offHoursSchedulerSelector);

  if (frequency !== "daily") {
    return null;
  }

  return (
    <Stack spacing={2} flex={1}>
      <Stack direction="row" spacing={2}>
        <Stack spacing={0.5}>
          <OffHoursSchedulerFieldLabel type="stop" title="Stop resources at" />
          <OffHoursStopStartTime value={scheduler?.stopTime} type="stop" />
        </Stack>

        <Stack spacing={0.5}>
          <OffHoursSchedulerFieldLabel type="stop" title="Start resources at" />

          <Stack direction="row" spacing={1} alignItems="center">
            <OffHoursStopStartTime value={scheduler?.startTime} type="start" />
            <StopDateInfoTooltip />
          </Stack>
        </Stack>
      </Stack>

      <Stack direction="row" spacing={2} flex={1}>
        <DailyInterval />
        <AutomationTimeZone />
      </Stack>
    </Stack>
  );
};
