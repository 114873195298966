import { createDraftSafeSelector } from "@reduxjs/toolkit";
import { rightsizingRecommendationsStatusesGroupedSelector } from "./rightsizingRecommendationsStatusesGroupedSelector";
import { ProviderType } from "../../../../../services/cloudchipr.api";

export const rightsizingRecommendationsWarningStatusesCountSelector =
  createDraftSafeSelector(
    [rightsizingRecommendationsStatusesGroupedSelector],
    (data) => {
      let count = 0;

      Object.keys(data).forEach((provider) => {
        count +=
          (data[provider as ProviderType]?.disabled?.length ?? 0) +
          (data[provider as ProviderType]?.missing_permission?.length ?? 0);
      });

      return count;
    },
  );
