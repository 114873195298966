import { RootState } from "../../../store";
import { cloudChiprApi } from "../../../../services/cloudchipr.api";
import { taskUpdateFixedCacheKey } from "../../utils/constants/fixedCacheKeys";

const endpointSelector =
  cloudChiprApi.endpoints.patchUsersMeOrganisationsCurrentTasks.select(
    taskUpdateFixedCacheKey,
  );

export const taskUpdateLoadingSelector = (state: RootState) => {
  return endpointSelector(state)?.isLoading;
};
