import { FC, memo, useCallback } from "react";
import { Button, FormHelperText, Stack } from "@mui/material";

const defaultWeekDays = [
  { key: 0, day: "S" },
  { key: 1, day: "M" },
  { key: 2, day: "T" },
  { key: 3, day: "W" },
  { key: 4, day: "T" },
  { key: 5, day: "F" },
  { key: 6, day: "S" },
];

interface WeekDaysSelectProps {
  error?: string | string[] | boolean;
  color?: "success" | "warning" | "primary";
  weekDays: number[];
  onChange(days: number[]): void;
}

export const WeekDaysSelect: FC<WeekDaysSelectProps> = memo(
  ({ weekDays, error, onChange, color }) => {
    const changeHandler = useCallback(
      (day: number) => () => {
        if (weekDays.includes(day)) {
          onChange(weekDays.filter((d) => d !== day));
        } else {
          onChange([...weekDays, day]);
        }
      },
      [onChange, weekDays],
    );

    return (
      <Stack>
        <Stack direction="row" alignItems="center">
          {defaultWeekDays.map((item) => (
            <Button
              color={color}
              key={item.key}
              sx={{
                mr: 1,
                borderRadius: "50%",
                minWidth: 32,
                width: 32,
                height: 32,
              }}
              variant={weekDays.includes(item.key) ? "contained" : "outlined"}
              onClick={changeHandler(item.key)}
            >
              {item.day}
            </Button>
          ))}
        </Stack>
        {error && (
          <FormHelperText error sx={{ mt: 1 }}>
            {error}
          </FormHelperText>
        )}
      </Stack>
    );
  },
);
