import { FC, useCallback } from "react";
import { TextField, Typography } from "@mui/material";
import { FormikHandlers } from "formik";
import { useDebounce } from "rooks";
import { useAppDispatch } from "../../../../../../../../../store/hooks";
import { setCurrentBudgetData } from "../../../../../../../../../store/budgets/budgetsSlice";

interface BudgetAmountProps {
  error?: string;
  amount: number | string;
  handleChange: FormikHandlers["handleChange"];
}

export const BudgetAmount: FC<BudgetAmountProps> = ({
  error,
  amount,
  handleChange,
}) => {
  const dispatch = useAppDispatch();

  const dispatchAmountHandler = useCallback(
    (amount: number) => {
      dispatch(setCurrentBudgetData({ amount }));
    },
    [dispatch],
  );

  const dispatchAmountDebounced = useDebounce(dispatchAmountHandler, 500);

  const changeHandler = useCallback(
    (event: any) => {
      handleChange(event);
      if (!error) {
        dispatchAmountDebounced(+event.target.value);
      }
    },
    [dispatchAmountDebounced, error, handleChange],
  );

  return (
    <div>
      <Typography variant="body2" mb={1}>
        Budget Amount*
      </Typography>

      <TextField
        size="small"
        name="amount"
        type="number"
        value={amount}
        placeholder="0"
        variant="outlined"
        error={!!error}
        helperText={error}
        onChange={changeHandler}
        InputProps={{
          startAdornment: (
            <Typography pr={1} color="text.secondary">
              $
            </Typography>
          ),
        }}
      />
    </div>
  );
};
