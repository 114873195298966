import { FC, useCallback } from "react";
import { Button, Tooltip } from "@mui/material";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import { useAppDispatch, useAppSelector } from "../../../../../../store/hooks";
import { resetResourceExplorerThunk } from "../../../../../../store/resource-explorer/thunks/resource-explorer/resetResourceExplorerThunk";
import { isBillingAvailableSelector } from "../../../../../../store/common/selectors/billing-status/isBillingAvailableSelector";
import { changesExistOnResourceExplorer } from "../../../../../../store/resource-explorer/selectors/current-resource-explorer/checkers/changesExistOnResourceExplorer";

export const ResourceExplorerResetButton: FC = () => {
  const anyFilterChanged = useAppSelector(changesExistOnResourceExplorer);
  const resourceExplorerAvailable = useAppSelector(isBillingAvailableSelector);
  const location = useLocation();
  const pathname = location.pathname;
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const categoryId = searchParams.get("category") as string;
  const path = searchParams.get("path") as string;

  const dispatch = useAppDispatch();
  const resetREClickHandler = useCallback(() => {
    if (categoryId) {
      navigate(`${pathname}?path=${path}`, {
        replace: true,
      });
    }
    dispatch(resetResourceExplorerThunk());
  }, [dispatch, categoryId, navigate, path, pathname]);

  if (!anyFilterChanged || !resourceExplorerAvailable) {
    return null;
  }

  return (
    <Tooltip title="Reset all unsaved changes" arrow placement="left">
      <Button
        onClick={resetREClickHandler}
        startIcon={<RestartAltIcon color="primary" />}
      >
        Reset
      </Button>
    </Tooltip>
  );
};
