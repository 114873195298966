import { FC, Fragment, useCallback } from "react";
import { Button } from "@mui/material";
import { useSnackbar } from "notistack";
import { ConfirmationDialog } from "../../../../../../../common/modals/ConfirmationDialog";
import { useDialog } from "../../../../../../../../utils/hooks/useDialog.hook";
import { useAppAbility } from "../../../../../../../../services/permissions";
import {
  PostUsersMeOrganisationsCurrentSsoApiArg,
  useDeleteUsersMeOrganisationsCurrentSsoCurrentMutation,
  useGetUsersMeOrganisationsCurrentQuery,
} from "../../../../../../../../services/cloudchipr.api";

interface DisableSsoButtonProps {
  ssoType: PostUsersMeOrganisationsCurrentSsoApiArg["body"]["type"];
}

export const DisableSsoButton: FC<DisableSsoButtonProps> = ({ ssoType }) => {
  const { can } = useAppAbility();
  const { enqueueSnackbar } = useSnackbar();

  const { data: organization, refetch } =
    useGetUsersMeOrganisationsCurrentQuery();

  const [triggerDelete, { isLoading }] =
    useDeleteUsersMeOrganisationsCurrentSsoCurrentMutation();

  const {
    open: deleteOpen,
    openDialog: openDeleteDialog,
    closeDialog: closeDeleteDialog,
  } = useDialog();

  const currentSSoDeleteHandler = useCallback(() => {
    triggerDelete()
      .unwrap()
      .then(() => {
        refetch();
        closeDeleteDialog();
        enqueueSnackbar(
          `You've successfully disabled ${
            ssoType === "okta" ? "Okta" : "Microsoft"
          } SSO!`,
          {
            variant: "snackbarAlert",
            AlertSnackBarProps: {
              severity: "success",
            },
            autoHideDuration: 2000,
          },
        );
      })
      .catch((error) => {
        if (error && "status" in error && error.status === 400) {
          enqueueSnackbar(error?.data?.message, {
            variant: "snackbarAlert",
            AlertSnackBarProps: {
              severity: "error",
            },
            autoHideDuration: 2000,
          });
        }
      });
  }, [enqueueSnackbar, triggerDelete, refetch, ssoType, closeDeleteDialog]);

  if (organization?.sso_connection_type && can("delete", "sso")) {
    return (
      <Fragment>
        <Button variant="outlined" color="error" onClick={openDeleteDialog}>
          Disable
        </Button>

        <ConfirmationDialog
          open={deleteOpen}
          title="Disable Single Sign On?"
          dialogContent="Disabling Single Sign On might prevent all team members from signing in via the Identity Provider."
          CancelButtonProps={{
            onClick: closeDeleteDialog,
          }}
          ConfirmButtonProps={{
            color: "error",
            children: "Delete",
            onClick: currentSSoDeleteHandler,
            loading: isLoading,
          }}
          onClose={closeDeleteDialog}
        />
      </Fragment>
    );
  }

  return null;
};
