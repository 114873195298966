import {
  cloudChiprApi,
  GetUsersMeOrganisationsCurrentResourceExplorerViewsByResourceExplorerViewIdAverageApiArg,
} from "../../../../services/cloudchipr.api";

export const getCostAnomalyPreviewDataGridDataSelector = (
  arg: GetUsersMeOrganisationsCurrentResourceExplorerViewsByResourceExplorerViewIdAverageApiArg,
) =>
  cloudChiprApi.endpoints.getUsersMeOrganisationsCurrentResourceExplorerViewsByResourceExplorerViewIdAverage.select(
    arg,
  );
