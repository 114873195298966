import { FC } from "react";
import { capitalize, Stack, Typography } from "@mui/material";
import EmptyStateImage from "../../../../../../../assets/images/empty_recommendation_state.svg";

interface CommitmentsOverviewCommitmentsEmptyStateProps {
  dataType: "commitments" | "coverage";
}

export const CommitmentsOverviewEmptyState: FC<
  CommitmentsOverviewCommitmentsEmptyStateProps
> = ({ dataType }) => {
  return (
    <Stack alignItems="center" justifyContent="center" py={3}>
      <img
        src={EmptyStateImage}
        alt="EmptyStateImage"
        height={100}
        width={280}
      />
      <Typography variant="body1" fontWeight="bold" mt={1}>
        No {capitalize(dataType)}
      </Typography>
      <Typography variant="body2" color="text.secondary" textAlign="center">
        Try changing the date range.
      </Typography>
    </Stack>
  );
};
