import { FC, useCallback } from "react";
import { Card, Collapse } from "@mui/material";
import { useToggle } from "rooks";
import { LiveUsageMgmtResourceCardHeader } from "./resource-card-header/LiveUsageMgmtResourceCardHeader";
import { LiveUsageMgmtResourceCardGrid } from "./resource-card-content/resource-card-data/resource-card-grid/LiveUsageMgmtResourceCardGrid";
import { LiveUsageMgmtResourceCardDialog } from "./LiveUsageMgmtResourceCardDialog";
import { ResourceType } from "../../../../../services/cloudchipr.api";
import { useResourceCardCollapsedState } from "../../utils/hooks/useResourceCardState.hook";

interface ResourceCardProps {
  resourceType: ResourceType;
}

export const generateResourceCardId = (resourceType: ResourceType) =>
  `${resourceType}_resource_type_card`;

export const LiveUsageMgmtResourceCard: FC<ResourceCardProps> = ({
  resourceType,
}) => {
  const { collapsedState, setCollapsedState } =
    useResourceCardCollapsedState(resourceType);

  const [collapsed, toggleCollapsed] = useToggle(collapsedState);
  const [fullScreen, toggleFullScreen] = useToggle();

  const onCollapse = useCallback(() => {
    setCollapsedState(!collapsed);
    toggleCollapsed(!collapsed);
  }, [collapsed, setCollapsedState, toggleCollapsed]);

  return (
    <Card
      variant="outlined"
      id={generateResourceCardId(resourceType)}
      sx={{ borderColor: "primary.light" }}
    >
      <LiveUsageMgmtResourceCardHeader
        resourceType={resourceType}
        onCollapse={onCollapse}
        onFullScreen={toggleFullScreen}
        collapsed={collapsed}
      />

      <Collapse in={!collapsed}>
        <LiveUsageMgmtResourceCardGrid resourceType={resourceType} />
      </Collapse>

      {fullScreen && (
        <LiveUsageMgmtResourceCardDialog
          resourceType={resourceType}
          onFullScreen={toggleFullScreen}
        />
      )}
    </Card>
  );
};
