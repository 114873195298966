import { RootState } from "../../../../store";
import { workflowGracePeriodSelector } from "../workflowGracePeriodSelector";
import { workflowActionSelector } from "../workflowActionSelector";
import { automationDataToValidateSelector } from "../../common/automationDataToValidateSelector";

export const workflowDataToValidateSelector = (state: RootState) => {
  const automationDataToValidate = automationDataToValidateSelector(state);
  const gracePeriod = workflowGracePeriodSelector(state);
  const action = workflowActionSelector(state);

  return {
    ...automationDataToValidate,
    action,
    gracePeriod,
  };
};
