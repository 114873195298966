import { createAsyncThunk } from "@reduxjs/toolkit";

import { closeSnackbar, enqueueSnackbar, SnackbarKey } from "notistack";
import { getDashboardsVisibilityHierarchyThunk } from "./getDashboardsVisibilityHierarchyThunk";
import { NavigationItemsVisibilityType } from "../../../navigation/utils/types";
import { cloudChiprApi } from "../../../../services/cloudchipr.api";
import { updateDashboardVisibilityHierarchyFixedCacheKey } from "../../utils/constants/fixedCacheKeys";

interface UpdateDashboardVisibilityHierarchyThunkArg {
  id: string;
  folderId?: string;
  index?: number;
  visibility: NavigationItemsVisibilityType;
  type: "dashboard" | "folder";
}

export const updateDashboardVisibilityHierarchyThunk = createAsyncThunk(
  "dashboard/updateDashboardVisibilityHierarchy",
  async (
    {
      id,
      folderId,
      index,
      visibility,
      type,
    }: UpdateDashboardVisibilityHierarchyThunkArg,
    { dispatch },
  ) => {
    const { patchV2UsersMeOrganisationsCurrentDashboardHierarchy } =
      cloudChiprApi.endpoints;

    try {
      await dispatch(
        patchV2UsersMeOrganisationsCurrentDashboardHierarchy.initiate(
          {
            body: {
              id,
              parent_id: folderId,
              index,
              visibility,
              type,
            },
          },
          {
            fixedCacheKey: updateDashboardVisibilityHierarchyFixedCacheKey,
          },
        ),
      ).unwrap();
      dispatch(getDashboardsVisibilityHierarchyThunk());
    } catch (e) {
      // @ts-expect-error TODO: return to this after adding error type
      const errMessage = e?.data?.message || "Something went wrong";
      const snackbarId: SnackbarKey = enqueueSnackbar(errMessage, {
        variant: "snackbarAlert",
        AlertSnackBarProps: {
          severity: "error",
          onClose: () => closeSnackbar(snackbarId),
        },
      });
    }
  },
);
