import { RootState } from "../../../../store";
import { cloudChiprApi } from "../../../../../services/cloudchipr.api";
import { recommendationDismissByResourceFixedCacheKey } from "../../../utils/constants/fixedCacheKeys";

const dailySelector =
  cloudChiprApi.endpoints.patchUsersMeResourcesDailyOffHoursRecommendations.select(
    recommendationDismissByResourceFixedCacheKey,
  );

const weeklySelector =
  cloudChiprApi.endpoints.patchUsersMeResourcesWeeklyOffHoursRecommendations.select(
    recommendationDismissByResourceFixedCacheKey,
  );

const rightsizingSelector =
  cloudChiprApi.endpoints.patchUsersMeResourcesRightSizingRecommendations.select(
    recommendationDismissByResourceFixedCacheKey,
  );

export const recommendationResourceDismissLoadingSelector = (
  state: RootState,
): boolean => {
  return !!(
    dailySelector(state)?.isLoading ||
    weeklySelector(state)?.isLoading ||
    rightsizingSelector(state)?.isLoading
  );
};
