import { FC, useCallback } from "react";
import PostAddOutlinedIcon from "@mui/icons-material/PostAddOutlined";
import { IconButton, Tooltip } from "@mui/material";
import { useFlag } from "@unleash/proxy-client-react";
import { liveUsageRowActionTooltipTitles } from "./utils/constants";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../../../../store/hooks";
import { taskManagementInitiateEmptyTaskThunk } from "../../../../../../../../../../../store/task-management/thunks/form/taskManagementInitiateEmptyTaskThunk";
import { setTaskManagementTaskTarget } from "../../../../../../../../../../../store/task-management/taskManagementSlice";
import { ResourceType } from "../../../../../../../../../../../services/cloudchipr.api";
import { liveUsageMgmtSelectedResourcesByResourceTypeSelector } from "../../../../../../../../../../../store/live-usage-mgmt/selectors/resource-type-data/liveUsageMgmtSelectedResourcesByResourceTypeSelector";

interface ToolbarTaskCreateActionItemProps {
  resourceType: ResourceType;
  disabled: boolean;
}

export const ToolbarTaskCreateActionItem: FC<
  ToolbarTaskCreateActionItemProps
> = ({ resourceType, disabled }) => {
  const dispatch = useAppDispatch();
  const enableTaskManagement = useFlag("EnableTaskManagement");

  const resources = useAppSelector((state) =>
    liveUsageMgmtSelectedResourcesByResourceTypeSelector(state, resourceType),
  );

  const clickHandler = useCallback(async () => {
    if (!resources?.length) {
      return;
    }

    await dispatch(taskManagementInitiateEmptyTaskThunk());
    dispatch(
      setTaskManagementTaskTarget({
        type: "resource",
        resources: resources.map((resource) => {
          return {
            state: "live",
            id: resource.id,
            name: resource.resource.name ?? "",
            tags: resource.resource.tags,
            link: resource.resource.link,
            account: resource.resource.account,
            resource_type: resource.resource_type,
            region: resource.resource.region ?? "",
            price_per_month: `${resource.resource.price_per_month}`,
            provider_identifier: resource.resource.provider_unique_identifier,
            creation_data: resource.resource.creation_data ?? {},
          };
        }),
      }),
    );
  }, [dispatch, resources]);

  if (!enableTaskManagement) {
    return null;
  }

  return (
    <Tooltip
      title={
        liveUsageRowActionTooltipTitles.crate_task[
          disabled ? "disabled" : "active"
        ] ?? ""
      }
      placement="top"
      arrow
    >
      <span>
        <IconButton sx={{ p: 0.5 }} onClick={clickHandler} disabled={disabled}>
          <PostAddOutlinedIcon color={disabled ? "disabled" : "primary"} />
        </IconButton>
      </span>
    </Tooltip>
  );
};
