import { RootState } from "../../store";
import { AccountAttemptStatusDetailsGcpStarted } from "../../../services/cloudchipr.api";

export const gcpServiceAccountSelector = (
  state: RootState,
): string | undefined =>
  (
    state.accountSetup.accountAttempt?.status_details
      ?.started as AccountAttemptStatusDetailsGcpStarted
  )?.service_account;
