import { FC } from "react";
import { IconButton, Stack } from "@mui/material";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import { useAppSelector } from "../../../../../../../store/hooks";
import { alertsDrawerEditModeSelector } from "../../../../../../../store/alerts/selectors/alertsDrawerEditModeSelector";

interface AlertDrawerHeaderProps {
  onClose(): void;
}

export const AlertDrawerHeader: FC<AlertDrawerHeaderProps> = ({ onClose }) => {
  const editMode = useAppSelector(alertsDrawerEditModeSelector);

  return (
    <Stack
      py={1}
      px={2}
      direction="row"
      alignItems="center"
      justifyContent="space-between"
    >
      <Typography variant="body1" fontWeight="medium">
        {editMode ? "Edit" : "Create"} Alert
      </Typography>
      <IconButton size="small" onClick={onClose}>
        <CloseIcon fontSize="small" />
      </IconButton>
    </Stack>
  );
};
