import { createAsyncThunk } from "@reduxjs/toolkit";
import { cloudChiprApi } from "../../../../services/cloudchipr.api";

export const dimensionUpdateFixedCacheKey =
  "putUsersMeOrganisationsCurrentDimensionsByDimensionId-fixed-cache-key";

export const updateDimensionNameThunk = createAsyncThunk(
  "dimensions/updateDimensionName",
  async (
    { dimensionId, name }: { dimensionId: string; name: string },
    { dispatch },
  ) => {
    const { putUsersMeOrganisationsCurrentDimensionsByDimensionId } =
      cloudChiprApi.endpoints;

    return await dispatch(
      putUsersMeOrganisationsCurrentDimensionsByDimensionId.initiate(
        { dimensionId, body: { name } },
        { fixedCacheKey: dimensionUpdateFixedCacheKey },
      ),
    ).unwrap();
  },
);
