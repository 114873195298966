import { FC } from "react";
import { Stack, Typography } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { IntegrationCustomMessageInfoSection } from "../../common/IntegrationCustomMessageInfoSection";

export const EmailIntegrationNotifyWorkflowInfo: FC = () => {
  return (
    <Stack direction="row" justifyContent="space-between">
      <Stack>
        <IntegrationCustomMessageInfoSection
          start="Workflow"
          tail="{{workflow name}}"
          color="primary.main"
        />

        <Stack direction="row" spacing={0.5}>
          <SearchIcon
            sx={{
              color: "info.main",
            }}
          />
          <Typography variant="body1" fontWeight="medium">
            Found Resources
          </Typography>
        </Stack>
        <IntegrationCustomMessageInfoSection start="Run date" tail="{{date}}" />
      </Stack>
      <Stack alignItems="flex-end">
        <Typography color="text.secondary" variant="body2" fontWeight="medium">
          Monthly Price:
        </Typography>
        <Typography variant="body2" fontWeight="medium">
          {`{{0.00}}`}
        </Typography>
      </Stack>
    </Stack>
  );
};
