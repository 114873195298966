import { RootState } from "../../../../../store";
import { cloudChiprApi } from "../../../../../../services/cloudchipr.api";
import { updateTaskPriorityPropertiesFixedCacheKey } from "../../../../utils/constants/fixedCacheKeys";

const selector =
  cloudChiprApi.endpoints.putUsersMeOrganisationsCurrentTasksPropertiesPriorities.select(
    updateTaskPriorityPropertiesFixedCacheKey,
  );

export const taskManagementEditingPrioritiesLoadingSelector = (
  state: RootState,
) => {
  return selector(state)?.isLoading;
};
