import { ChartDataType } from "../../../../../../../storybook/charts/multi-type-chart/utils/types/types";

export const convertCostToPercent = (chartData: ChartDataType[]) => {
  return chartData.map((entry) => {
    const totalCost = Object.values(entry).reduce((sum: number, value) => {
      return typeof value === "number" ? sum + Math.abs(value) : sum;
    }, 0);

    const result = { ...entry };
    Object.keys(result).forEach((key) => {
      if (typeof result[key] === "number") {
        result[key] = +((Math.abs(result[key]) / totalCost) * 100).toFixed(2);
      }
    });
    return result;
  });
};
