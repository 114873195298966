import { FC, ReactNode, useCallback, useEffect, MouseEvent } from "react";
import { Box, Stack } from "@mui/material";
import { TypographyWithTooltip } from "../../../../../../components/common/TypographyWithTooltip";
import { ChartColorsType } from "../../../utils/types/types";
import { useMultiTypeChartContext } from "../../../MultiTypeChartProvider";
import { Cube } from "../Cube";

interface LegendItemProps {
  itemKey: string;
  highlightable: boolean;
  selectable: boolean;
  colors: ChartColorsType;
  visibleKeys: string[];
  allKeys: string[];
  legendItemLabelFormatter?(key: string): string;
  setVisibleKeys(key: string[]): void;
  children?: ReactNode;
}

export const LegendItem: FC<LegendItemProps> = ({
  itemKey,
  setVisibleKeys,
  colors,
  highlightable,
  selectable,
  visibleKeys,
  allKeys,
  legendItemLabelFormatter,
  children,
}) => {
  const { hover } = useMultiTypeChartContext();
  const setHighlighted = hover.setKey;
  const highlightedKey = hover.key;

  const isSelected = visibleKeys.includes(itemKey);

  const mouseEnterHandler = useCallback(() => {
    isSelected && setHighlighted(itemKey);
  }, [setHighlighted, isSelected, itemKey]);

  const mouseLeaveHandler = useCallback(
    () => setHighlighted(""),
    [setHighlighted],
  );

  const singleSelectHandler = useCallback(() => {
    setHighlighted("");
    setVisibleKeys(
      visibleKeys.length === 1 && visibleKeys[0] === itemKey
        ? allKeys
        : [itemKey],
    );
  }, [itemKey, setHighlighted, setVisibleKeys, allKeys, visibleKeys]);

  const multipleSelectHandler = useCallback(
    (event: MouseEvent<HTMLDivElement>) => {
      event.preventDefault();
      event.stopPropagation();

      setHighlighted("");
      setVisibleKeys(
        visibleKeys.includes(itemKey)
          ? visibleKeys.filter((key) => itemKey !== key)
          : [...visibleKeys, itemKey],
      );
    },
    [itemKey, setHighlighted, setVisibleKeys, visibleKeys],
  );

  const color = colors[itemKey];
  const selectOrHighlight = selectable || highlightable;

  useEffect(() => {
    if (!visibleKeys.includes(highlightedKey)) {
      setHighlighted("");
    }
  }, [visibleKeys, highlightedKey, setHighlighted]);

  return (
    <Box
      pb={highlightedKey ? 0.5 : 0}
      px={highlightedKey ? 0.5 : 0}
      mb={!highlightedKey ? 0.5 : 0}
      mx={!highlightedKey ? 0.5 : 0}
      onMouseEnter={highlightable ? mouseEnterHandler : undefined}
      onMouseLeave={highlightable ? mouseLeaveHandler : undefined}
    >
      <Stack
        display="flex"
        direction="row"
        alignItems="center"
        whiteSpace="nowrap"
        onClick={selectable ? singleSelectHandler : undefined}
        sx={{
          px: 0.5,
          cursor: selectOrHighlight ? "pointer" : undefined,
          "&:hover": selectOrHighlight
            ? {
                borderRadius: 2,
                bgcolor: "grey.200",
              }
            : undefined,
        }}
      >
        <Box
          onClick={selectable ? multipleSelectHandler : undefined}
          sx={selectable ? getSelectableXStyles(isSelected, color) : undefined}
        >
          <Cube
            color={isSelected ? color : "transparent"}
            border={`1px solid ${color}`}
            width={12}
            height={12}
          />
        </Box>

        {children ?? (
          <TypographyWithTooltip
            title={
              legendItemLabelFormatter
                ? legendItemLabelFormatter(itemKey)
                : itemKey
            }
            variant="caption"
            color="text.secondary"
            maxWidth={150}
            sx={{ userSelect: selectOrHighlight ? "none" : "unset" }}
          />
        )}
      </Stack>
    </Box>
  );
};

const getSelectableXStyles = (selected: boolean, color: string) => {
  return {
    "&:hover": {
      position: "relative",
      "&:after": {
        left: 5,
        transform: "rotate(-45deg)",
      },
      "&:before": {
        right: 9,
        transform: "rotate(45deg)",
      },
      "&:after, &:before": {
        top: 5,
        width: 2,
        height: 11,
        content: '""',
        position: "absolute",
        bgcolor: selected ? "white" : color,
      },
    },
  };
};
