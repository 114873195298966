import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  cloudChiprApi,
  PostUsersMeOrganisationsCurrentGcpBillingExportDataApiArg,
} from "../../../../../services/cloudchipr.api";
import { createResourceExplorerGcpBillingExportDataFixedCacheKey } from "../../../../../components/pages/resource-explorer/components/resource-explorer-card/utils/constants/fixedCacheKeys";

type CreateResourceExplorerGcpBillingExportDataArgs =
  PostUsersMeOrganisationsCurrentGcpBillingExportDataApiArg["body"];

export const createResourceExplorerGcpBillingExportDataThunk = createAsyncThunk(
  "resourceExplorer/createGcpBillingExportData",
  async (
    {
      dataset,
      project_id,
      table,
    }: CreateResourceExplorerGcpBillingExportDataArgs,
    { dispatch },
  ) => {
    const { postUsersMeOrganisationsCurrentGcpBillingExportData } =
      cloudChiprApi.endpoints;

    try {
      return await dispatch(
        postUsersMeOrganisationsCurrentGcpBillingExportData.initiate(
          {
            body: {
              project_id,
              dataset,
              table,
            },
          },
          {
            fixedCacheKey:
              createResourceExplorerGcpBillingExportDataFixedCacheKey,
          },
        ),
      ).unwrap();
    } catch (e) {
      return e;
    }
  },
);
