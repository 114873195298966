import { FC, FormEventHandler, ChangeEventHandler } from "react";
import { Fade, IconButton, Stack, TextField } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";

interface WidgetNameEditViewProps {
  handleSubmit: FormEventHandler<HTMLFormElement>;
  handleChange: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  name: string;
  isValid: boolean;
  hovered: boolean;
}

export const WidgetNameEditView: FC<WidgetNameEditViewProps> = ({
  handleSubmit,
  handleChange,
  name,
  isValid,
  hovered,
}) => {
  return (
    <form onSubmit={handleSubmit}>
      <Stack direction="row" spacing={1} alignItems="center">
        <TextField
          fullWidth
          variant="outlined"
          size="xsmall"
          name="name"
          value={name}
          onChange={handleChange}
          error={!isValid}
          autoFocus
        />
        <Fade in={hovered}>
          <IconButton size="small" type="submit" disabled={!isValid}>
            <CheckIcon fontSize="small" />
          </IconButton>
        </Fade>
      </Stack>
    </form>
  );
};
