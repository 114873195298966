import { FC, useCallback, useMemo } from "react";
import { Stack, Typography } from "@mui/material";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../store/hooks";
import { automationProviderSelector } from "../../../../../../../store/automations/selectros/common/fields/automationProviderSelector";
import { AutocompleteSelect } from "../../../../../../common/select/autocomplete-select/AutocompleteSelect";
import { DropdownSelectOption } from "../../../../../../common/select/dropdown-select/utils/types/types";
import {
  setAutomationData,
  setAutomationErrors,
} from "../../../../../../../store/automations/automationsSlice";
import { automationRegionsSelector } from "../../../../../../../store/automations/selectros/common/fields/automationRegionsSelector";
import { providersRegionsSelector } from "../../../../../../../store/automations/selectros/common/helpers-data/regions/providersRegionsSelector";
import { automationRegionsErrorSelector } from "../../../../../../../store/automations/selectros/common/errors/automationRegionsErrorSelector";

export const AutomationRegionsSelection: FC = () => {
  const dispatch = useAppDispatch();

  const provider = useAppSelector(automationProviderSelector);
  const regions = useAppSelector(automationRegionsSelector);
  const regionsError = useAppSelector(automationRegionsErrorSelector);

  const providerRegions = useAppSelector(providersRegionsSelector);

  const options = useMemo(() => {
    const regionsData = provider ? providerRegions[provider] : [];

    return regionsData?.map((region) => ({
      value: region.key,
      rawValue: region,
      label: (
        <Stack direction="row" spacing={1} justifyContent="space-between">
          <Typography variant="body2">{region.name}</Typography>
          <Typography variant="body2" color="text.secondary">
            {region.key}
          </Typography>
        </Stack>
      ),
    }));
  }, [providerRegions, provider]);

  const changeHandler = useCallback(
    (regions: string[]) => {
      dispatch(setAutomationData({ regions }));

      if (regions.length && regionsError) {
        dispatch(setAutomationErrors({ regions: "" }));
      }
    },
    [dispatch, regionsError],
  );

  if (!options) {
    return null;
  }

  return (
    <AutocompleteSelect
      error={regionsError}
      disabled={!provider}
      options={options}
      filterFn={filterFn}
      onChange={changeHandler}
      label="Select Region/s*"
      allSelectedLabel="Regions"
      values={regions}
      wrapperVariant="popover"
    />
  );
};

const filterFn = (option: DropdownSelectOption, keyword: string) => {
  keyword = keyword.trim().toLowerCase();

  if (!option.rawValue) {
    return false;
  }

  const target = `${option.rawValue.key} ${option.rawValue.name}`.toLowerCase();

  return target.includes(keyword);
};
