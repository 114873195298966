import {
  OrganisationDailyOffHoursRecommendation,
  OrganisationWeeklyOffHoursRecommendation,
  RightSizingRecommendation,
} from "../../../services/cloudchipr.api";

export const recommendationByIdSeparatorByTypeCombiner = (
  ids: string[],
  dailyDb: OrganisationDailyOffHoursRecommendation[],
  weeklyDb: OrganisationWeeklyOffHoursRecommendation[],
  dailyCompute: OrganisationDailyOffHoursRecommendation[],
  weeklyCompute: OrganisationWeeklyOffHoursRecommendation[],
  rightsizing?: RightSizingRecommendation[],
) => {
  return {
    rightsizing: recommendationTypeFinderById(ids, rightsizing),
    computeDaily: recommendationTypeFinderById(ids, dailyCompute),
    computeWeekly: recommendationTypeFinderById(ids, weeklyCompute),
    dbDaily: recommendationTypeFinderById(ids, dailyDb),
    dbWeekly: recommendationTypeFinderById(ids, weeklyDb),
  };
};

const recommendationTypeFinderById = (
  ids: string[],
  recommendations?: { id: string }[],
) => {
  const recommendationsIds =
    recommendations?.reduce(
      (ids, recommendation) => {
        ids[recommendation.id] = true;

        return ids;
      },
      {} as Record<string, boolean>,
    ) ?? {};

  return ids.filter((selectedId) => recommendationsIds[selectedId]);
};
