import { createAsyncThunk } from "@reduxjs/toolkit";
import { cloudChiprApi } from "../../../../../services/cloudchipr.api";

export const getRightsizingDismissedResourcesThunk = createAsyncThunk(
  "offHoursRecommendations/getRightsizingDismissedResourcesThunk",
  async (_: void, { dispatch }) => {
    const { getUsersMeOrganisationsCurrentDismissedResources } =
      cloudChiprApi.endpoints;

    return await dispatch(
      getUsersMeOrganisationsCurrentDismissedResources.initiate(
        {
          group: "compute",
          recommendationType: "right_sizing",
        },
        { forceRefetch: true },
      ),
    ).unwrap();
  },
);
