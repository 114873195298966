import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  resetLiveUsageMgmtAllSelectedResources,
  resetLiveUsageMgmtViewWithoutFiltersResourceTypes,
  setLiveUsageMgmtFilterTemplatesEnabled,
} from "../liveUsageMgmtSlice";

export const liveUsageMgmtResetResourcesConfigsPerAccountsThunk =
  createAsyncThunk(
    "liveUsageMgmt/liveUsageMgmtResetResourcesConfigsPerAccounts",
    (_, { dispatch }) => {
      dispatch(setLiveUsageMgmtFilterTemplatesEnabled(true));
      dispatch(resetLiveUsageMgmtViewWithoutFiltersResourceTypes());
      dispatch(resetLiveUsageMgmtAllSelectedResources());
    },
  );
