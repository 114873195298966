import { createDraftSafeSelector } from "@reduxjs/toolkit";
import { getDailyDismissedRecommendationDbSelector } from "../../recommendationDailyQuerySelector";
import { OrganisationDailyOffHoursRecommendation } from "../../../../../../services/cloudchipr.api";

export const recommendationDbDailyDismissedDataSelector =
  createDraftSafeSelector(
    [getDailyDismissedRecommendationDbSelector],
    (response): OrganisationDailyOffHoursRecommendation[] =>
      response?.data ?? [],
  );
