import { FC, useCallback, useMemo } from "react";
import { FilterKeySelectTriggerComponent } from "./FilterKeySelectTriggerComponent";
import {
  KeyValuePair,
  useGetUsersMeOrganisationsCurrentResourceExplorerFiltersByFilterProviderAndFilterTypeValuesQuery as useValuesQuery,
} from "../../../../../../../../../services/cloudchipr.api";
import { DropdownSelect } from "../../../../../../../../common/select/dropdown-select/DropdownSelect";
import { TypographyWithTooltip } from "../../../../../../../../common/TypographyWithTooltip";
import { FilterTriggerComponentProps } from "../../../../../../../../common/select/dropdown-select/utils/types/filterTriggerComponentProps";
import { FilterItemNodeWithId } from "../../../../../utils/types/common";
import { DropdownSelectOption } from "../../../../../../../../common/select/dropdown-select/utils/types/types";

export type FilterWithKey = { value: KeyValuePair } & FilterItemNodeWithId;

interface FilterKeySelectProps {
  label?: string;
  index: number;
  filter: FilterWithKey;
  onFilterChange(filter: FilterItemNodeWithId, index: number): void;
}

export const FilterKeySelect: FC<FilterKeySelectProps> = ({
  onFilterChange,
  filter,
  index,
  label,
}) => {
  const { data, isLoading } = useValuesQuery({
    filterProvider: filter.filter_provider,
    filterType: filter.type,
  });

  const renderOptions = useMemo(() => {
    return data?.map((option) => ({
      rawValue: option,
      value: option.value,
      label: <TypographyWithTooltip title={option.title ?? option.value} />,
    }));
  }, [data]);

  const renderValue = useMemo(() => {
    const cache: Record<string, string> = {};

    return (value: string) => {
      if (!cache[value]) {
        const selectedItem = data?.find((option) => option.value === value);
        cache[value] = selectedItem?.title || selectedItem?.value || value;
      }

      return cache[value];
    };
  }, [data]);

  const changeHandler = useCallback(
    (values: string[]) => {
      const key = values?.at(0);

      if (filter?.value?.key === key || !key) {
        return;
      }

      onFilterChange({ ...filter, value: { key, value: [] } }, index);
    },
    [index, onFilterChange, filter],
  );

  const value = filter.value?.key;

  const TriggerComponent = (props: FilterTriggerComponentProps) => (
    <FilterKeySelectTriggerComponent {...props} renderValue={renderValue} />
  );

  return (
    <DropdownSelect
      singleSelect
      label={label ?? "Key"}
      disabled={isLoading}
      options={renderOptions ?? []}
      optionsLoading={isLoading}
      wrapperVariant="popover"
      submitHandlerOnClose={changeHandler}
      values={value ? [value] : []}
      TriggerComponent={TriggerComponent}
      filterFn={filterFn}
    />
  );
};

const filterFn = (option: DropdownSelectOption, keyword: string) => {
  return `${option.rawValue.title} ${option.rawValue.value}`
    .toLowerCase()
    .includes(keyword.toLowerCase());
};
