import moment from "moment/moment";
import { createDraftSafeSelector } from "@reduxjs/toolkit";
import { resourceExplorerGranularViewGridDataSelector } from "./resourceExplorerGranularViewGridDataSelector";

export const resourceExplorerGranularTotalCostsSelector =
  createDraftSafeSelector(
    [resourceExplorerGranularViewGridDataSelector],
    (gridData) => {
      return gridData?.reduce(
        (acc, item) => {
          acc.total = (acc.total ?? 0) + (item.total_cost ?? 0);

          Object.keys(item).forEach((key) => {
            const isKeyDate = moment(key).isValid();

            if (isKeyDate) {
              acc[key] = (acc[key] ?? 0) + (item[key].cost ?? 0);
            }
          });

          return acc;
        },
        {} as Record<string, number>,
      );
    },
  );
