import { FC, Fragment } from "react";
import { IconButton, Stack } from "@mui/material";
import LinkIcon from "@mui/icons-material/Link";
import CopyToClipboard from "react-copy-to-clipboard";
import { useFlag } from "@unleash/proxy-client-react";
import { ResourceExplorerBillingStatuses } from "./resource-explorer-billing-statuses/ResourceExplorerBillingStatuses";
import { ResourceExplorerResetButton } from "./ResourceExplorerResetButton";
import { ResourceExplorerAppBarSaveButton } from "./ResourceExplorerAppBarSaveButton";
import { ResourceExplorerActionType } from "../../../utils/types/types";
import { ResourceExplorerCsvDownloader } from "../../resource-explorer-card/components/resource-explorer-data-grid/components/csv/ResourceExplorerCSVDownloader";
import { SendReportButton } from "../../../../common/send-report/SendReportButton";
import { ReportsBySourceButton } from "../../../../common/reports-by-source/ReportsBySourceButton";

interface ResourceExplorerAppBarActionsProps {
  viewId: string;
  loading?: boolean;
  onSaveClick(type: ResourceExplorerActionType): void;
}

export const ResourceExplorerAppBarActions: FC<
  ResourceExplorerAppBarActionsProps
> = ({ onSaveClick, viewId, loading }) => {
  const enableSubscriptions = useFlag("EnableSubscriptionsPage");

  return (
    <Stack direction="row" spacing={2} alignItems="center">
      <ResourceExplorerCsvDownloader trigger="button" color="primary" />

      <CopyToClipboard
        text={`${window.location.host}/resource-explorer/${viewId}`}
      >
        <IconButton>
          <LinkIcon fontSize="small" color="primary" />
        </IconButton>
      </CopyToClipboard>

      {enableSubscriptions && (
        <Fragment>
          <SendReportButton
            disabled={loading}
            sourceType="view"
            sourceId={viewId}
          />

          <ReportsBySourceButton sourceId={viewId} sourceType="view" />
        </Fragment>
      )}

      <ResourceExplorerBillingStatuses />

      <ResourceExplorerResetButton />

      <ResourceExplorerAppBarSaveButton
        openDialog={onSaveClick}
        viewId={viewId}
      />
    </Stack>
  );
};
