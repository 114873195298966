import { FC, memo, useState } from "react";
import { Box, Stack } from "@mui/material";
import { ResourceExplorerMetadata } from "./components/resource-explorer-metadata/ResourceExplorerMetadata";
import { ResourceExplorerChart } from "./components/resource-explorer-chart/ResourceExplorerChart";
import { ResourceExplorerTable } from "./components/resource-explorer-data-grid/ResourceExplorerTable";
import {
  ScreenSplitButtons,
  PossibleVisibilities,
} from "./components/ScreenSplitButtons";
import { useAppSelector } from "../../../../../store/hooks";
import { isCurrentResourceExplorerInitializedSelector } from "../../../../../store/resource-explorer/selectors/current-resource-explorer/checkers/isCurrentResourceExplorerInitializedSelector";
import { ProviderBillingDataAccessController } from "../../../common/billing/billing-data-access-controller/ProviderBillingDataAccessController";
import { isBillingAvailableSelector } from "../../../../../store/common/selectors/billing-status/isBillingAvailableSelector";
import { MultiTypeChartProvider } from "../../../../../storybook/charts/multi-type-chart/MultiTypeChartProvider";
import { connectedProvidersSelector } from "../../../../../store/common/selectors/connectedProvidersSelector";
import { resourceExplorerChartDataByBaseSelector } from "../../../../../store/resource-explorer/selectors/current-resource-explorer/chart/resourceExplorerChartDataByBaseSelector";

interface ResourceExplorerCardProps {
  viewId: string;
}

export const ResourceExplorerCard: FC<ResourceExplorerCardProps> = memo(
  ({ viewId }) => {
    const chartData = useAppSelector(resourceExplorerChartDataByBaseSelector);
    const connectedProviders = useAppSelector(connectedProvidersSelector);

    const resourceExplorerInitialized = useAppSelector((state) =>
      isCurrentResourceExplorerInitializedSelector(state, viewId),
    );
    const resourceExplorerAvailable = useAppSelector(
      isBillingAvailableSelector,
    );

    const [visibleContent, setVisibleContent] =
      useState<PossibleVisibilities>("chart_grid");

    if (!resourceExplorerInitialized) {
      return null;
    }

    if (!resourceExplorerAvailable) {
      const provider = connectedProviders[0];
      return <ProviderBillingDataAccessController provider={provider} />;
    }

    return (
      <Stack bgcolor="white" flexGrow={1} pb={1} overflow="auto">
        <ResourceExplorerMetadata />

        <MultiTypeChartProvider
          value={{
            initialChartType: "stack",
            data: chartData ?? [],
          }}
        >
          {visibleContent !== "grid" && (
            <Box position="relative" zIndex={2} px={1}>
              <ResourceExplorerChart viewId={viewId} />
            </Box>
          )}

          <ScreenSplitButtons
            viewId={viewId}
            visibleContent={visibleContent}
            setVisibleContent={setVisibleContent}
          />

          {visibleContent !== "chart" && <ResourceExplorerTable />}
        </MultiTypeChartProvider>
      </Stack>
    );
  },
);
