import { FC } from "react";
import { Stack, Typography } from "@mui/material";
import { SlackIntegrationMessageNotifyMetaData } from "./SlackIntegrationMessageNotifyMetaData";
import { SlackIntegrationMessageNotifyWorkflowInfo } from "./SlackIntegrationMessageNotifyWorkflowInfo";
import { SlackIntegrationDataGrid } from "../common/SlackIntegrationDataGrid";

interface SlackIntegrationMessageNotifyProps {
  message: string;
}

export const SlackIntegrationMessageNotify: FC<
  SlackIntegrationMessageNotifyProps
> = ({ message }) => {
  return (
    <Stack spacing={1}>
      <SlackIntegrationMessageNotifyMetaData />
      <SlackIntegrationMessageNotifyWorkflowInfo />
      {message && (
        <Typography
          variant="body1"
          dangerouslySetInnerHTML={{ __html: message }}
        />
      )}
      <SlackIntegrationDataGrid />
    </Stack>
  );
};
