import { RootState } from "../../../store";
import { ResourceType } from "../../../../services/cloudchipr.api";
import { resourceTypesByProvider } from "../../../../utils/helpers/providers/resourceTypesByProvider";
import { liveUsageMgmtProviderSelector } from "../store-selectors/liveUsageMgmtProviderSelector";
import { liveUsageMgmtResourceTypeDataSpendingSelector } from "../resource-type-data/liveUsageMgmtResourceTypeDataSpendingSelector";

export const liveUsageMgmtResourcesSpendingSelector = (
  state: RootState,
  resourceTypesToSkip?: Map<ResourceType, boolean>,
) => {
  const provider = liveUsageMgmtProviderSelector(state);
  if (!provider) {
    return;
  }

  const resourceTypes = resourceTypesByProvider(provider).filter(
    (resourceType) => !resourceTypesToSkip?.get(resourceType),
  );

  return (
    resourceTypes
      ?.map((resourceType) =>
        liveUsageMgmtResourceTypeDataSpendingSelector(state, resourceType),
      )
      ?.reduce((acc, currentValue) => acc + currentValue, 0) || 0
  );
};
