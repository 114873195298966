import { FC } from "react";
import { grey } from "@mui/material/colors";
import { Stack } from "@mui/material";
import { MultiTypeChartProvider } from "../../../../../../../../../../../storybook/charts/multi-type-chart/MultiTypeChartProvider";
import { useAppSelector } from "../../../../../../../../../../../store/hooks";
import { commitmentUtilizationSetupVisualizationPropertyByKeySelector } from "../../../../../../../../../../../store/dashboards/selectors/setups/commitment-utilization/commitmentUtilizationSetupVisualizationPropertyByKeySelector";
import { commitmentUtilizationWidgetSetupChartDataSelector } from "../../../../../../../../../../../store/dashboards/selectors/widgets/commitment-utilization/setup/commitmentUtilizationWidgetSetupChartDataSelector";
import { commitmentUtilizationWidgetSetupDataSelector } from "../../../../../../../../../../../store/dashboards/selectors/widgets/commitment-utilization/setup/commitmentUtilizationWidgetSetupDataSelector";
import { ChartType } from "../../../../../../../../../../../storybook/charts/multi-type-chart/utils/types/types";
import { CommitmentUtilizationWidgetChart } from "../../../../../../../widgets/commitment-utlization/components/CommitmentUtilizationWidgetChart";
import { commitmentUtilizationSetupHasAggregationSelector } from "../../../../../../../../../../../store/dashboards/selectors/setups/commitment-utilization/commitmentUtilizationSetupHasAggregationSelector";

export const CommitmentUtilizationWidgetSetupChartView: FC = () => {
  const chartType = useAppSelector(
    commitmentUtilizationSetupVisualizationPropertyByKeySelector("chart_type"),
  );
  const hasAggregation = useAppSelector(
    commitmentUtilizationSetupHasAggregationSelector,
  );
  const chartBase = useAppSelector(
    commitmentUtilizationSetupVisualizationPropertyByKeySelector(
      "chart_base_type",
    ),
  );
  const data = useAppSelector(commitmentUtilizationWidgetSetupDataSelector);
  const { groups } = data ?? {};
  const chartData = useAppSelector(
    commitmentUtilizationWidgetSetupChartDataSelector,
  );

  return (
    <Stack flex={1} p={2} justifyContent={hasAggregation ? "start" : "center"}>
      <MultiTypeChartProvider
        value={{
          data: chartData ?? [],
          initialChartType: chartType,
          disabledKeysSorting: true,
          colors,
        }}
      >
        <CommitmentUtilizationWidgetChart
          chartType={chartType as ChartType}
          chartBase={chartBase}
          groups={groups}
        />
      </MultiTypeChartProvider>
    </Stack>
  );
};

const colors = {
  Waste: grey[400],
  Utilization: "#9966FF",
};
