import { FC, ReactNode } from "react";
import { Box, LinearProgress, Stack } from "@mui/material";
import { SxProps } from "@mui/system";
import { Breadcrumbs, BreadcrumbsType } from "./breadcrumbs/Breadcrumbs";
import { NavigationExpander } from "./navigation-expander/NavigationExpander";

interface PageHeaderProps {
  breadcrumbs?: BreadcrumbsType[] | ReactNode;
  actions?: ReactNode;
  title?: ReactNode;
  extra?: ReactNode;
  sticky?: boolean;
  wrapperSx?: SxProps;

  // todo: use this loading for all pages where this component used
  loading?: boolean;
}

export const PageHeader: FC<PageHeaderProps> = ({
  breadcrumbs,
  actions,
  title,
  extra,
  sticky,
  loading,
  wrapperSx,
}) => {
  breadcrumbs = breadcrumbs || [{ title: title || "" }];

  return (
    <Box
      position={sticky ? "sticky" : "relative"}
      top={0}
      zIndex="appBar"
      bgcolor="white"
    >
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        borderBottom={1}
        borderColor="grey.300"
        height={52}
        px={1}
        sx={wrapperSx}
      >
        <Stack direction="row" alignItems="center" spacing={1}>
          <NavigationExpander />
          {Array.isArray(breadcrumbs) ? (
            <Breadcrumbs breadcrumbs={breadcrumbs} />
          ) : (
            breadcrumbs
          )}
        </Stack>

        {actions}
      </Stack>

      {extra}

      {loading && (
        <Box position="absolute" bottom={0} left={0} right={0}>
          <LinearProgress />
        </Box>
      )}
    </Box>
  );
};
