import { filterRecommendationByTagsFilter } from "../../utils/helpers/filterRecommendationByTagsFilter";
import { RecommendationDataType } from "../../../../components/pages/recommendations/components/off-hours-recommendations/utils/types/types";
import { ProviderType } from "../../../../services/cloudchipr.api";
import {
  TagsFilter,
  TagsFilterSet,
} from "../../../../components/common/select/tag-filter-dropdown-select/utils/types";

export const recommendationsDataByFiltersFilterCombiner = (
  data: RecommendationDataType[],
  providers: ProviderType[],
  types: string[],
  accounts: string[],
  tags: {
    combinationOperator: TagsFilter["combinationOperator"];
    filter: TagsFilterSet;
  },
) => {
  return data?.filter((value) => {
    if (!!providers.length && !providers.includes(value?.account?.provider)) {
      return false;
    }

    if (!!types.length && !types.includes(value.type)) {
      return false;
    }

    const existsByTagFilter = filterRecommendationByTagsFilter(
      tags,
      value?.tags ?? [],
    );

    if (!existsByTagFilter) {
      return false;
    }

    return accounts.length ? accounts.includes(value?.account?.id) : true;
  });
};
