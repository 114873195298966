import { FC, Fragment } from "react";
import { CommitmentUtilizationWidgetSetupTypeSelect } from "./components/CommitmentUtilizationWidgetSetupTypeSelect";
import { CommitmentUtilizationWidgetSetupGroupBySelect } from "./components/CommitmentUtilizationWidgetSetupGroupBySelect";
import { CommitmentUtilizationWidgetSetupOrgSelect } from "./components/CommitmentUtilizationWidgetSetupOrgSelect";
import { CommitmentUtilizationWidgetSetupDateRangeSelect } from "./components/CommitmentUtilizationWidgetSetupDateRangeSelect";
import { CommitmentUtilizationWidgetVisualizationSection } from "./components/configurations/visualization/CommitmentUtilizationWidgetVisualizationSection";
import { CommitmentUtilizationWidgetAggregationsSection } from "./components/configurations/aggregations/CommitmentUtilizationWidgetAggregationsSection";
import { CommitmentUtilizationWidgetAdvancedSettingsSection } from "./components/configurations/advanced-settings/CommitmentUtilizationWidgetAdvancedSettingsSection";

export const CommitmentUtilizationWidgetSetupSidebar: FC = () => {
  return (
    <Fragment>
      <CommitmentUtilizationWidgetSetupTypeSelect />
      <CommitmentUtilizationWidgetSetupGroupBySelect />
      <CommitmentUtilizationWidgetSetupOrgSelect />
      <CommitmentUtilizationWidgetSetupDateRangeSelect />
      <CommitmentUtilizationWidgetVisualizationSection />
      <CommitmentUtilizationWidgetAggregationsSection />
      <CommitmentUtilizationWidgetAdvancedSettingsSection />
    </Fragment>
  );
};
