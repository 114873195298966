import { FC, ReactNode } from "react";
import {
  Breakpoint,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { LoadingButton, LoadingButtonProps } from "@mui/lab";
import { ButtonProps } from "@mui/material/Button/Button";
import { DialogContentProps } from "@mui/material/DialogContent/DialogContent";
import { getPressEnterHandler } from "../../utils/helpers/getPressEnterHandler";
import { DialogTitleClosable } from "../dialog-utils/DialogTitleClosable";

type CustomLoadingButtonProps = Omit<LoadingButtonProps, "onClick"> & {
  onClick: () => void;
};

interface ConfirmationDialogProps extends DialogContentProps {
  open: boolean;
  title: string;
  dialogContent?: ReactNode;
  ConfirmButtonProps?: CustomLoadingButtonProps;
  CancelButtonProps?: ButtonProps;
  hideCancelButton?: boolean;
  onClose(): void;
  closeIcon?: boolean;
  maxWidth?: Breakpoint;
}

export const ConfirmationDialog: FC<ConfirmationDialogProps> = ({
  open,
  dialogContent,
  title,
  ConfirmButtonProps,
  CancelButtonProps,
  hideCancelButton,
  onClose,
  closeIcon,
  maxWidth = "sm",
  dividers,
}) => {
  const handlePressEnter = getPressEnterHandler(
    ConfirmButtonProps?.onClick,
    (ConfirmButtonProps?.disabled ?? ConfirmButtonProps?.loading) && open,
  );

  return (
    <Dialog
      open={open}
      onClose={onClose}
      onKeyDown={handlePressEnter}
      maxWidth={maxWidth}
      fullWidth
    >
      {closeIcon ? (
        <DialogTitleClosable title={title} onClose={onClose} />
      ) : (
        <DialogTitle>{title}</DialogTitle>
      )}

      <DialogContent
        sx={{
          color: "text.secondary",
        }}
        dividers={dividers}
      >
        {dialogContent}
      </DialogContent>
      <DialogActions>
        {!hideCancelButton ? (
          <Button {...CancelButtonProps} color="tertiary">
            {CancelButtonProps?.children ?? "Cancel"}
          </Button>
        ) : null}
        <LoadingButton
          variant="contained"
          color="primary"
          {...ConfirmButtonProps}
        >
          {ConfirmButtonProps?.children || "Confirm"}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
