import { CommitmentUtilizationWidgetSetupType } from "../types/setups/commitmentUtilizationWidgetSetupType";

export const commitmentUtilizationWidgetSetupDefaultData: CommitmentUtilizationWidgetSetupType =
  {
    widgetType: "commitments_utilization",
    name: "Commitments Utilization",
    grouping: "commitment_type",
    commitmentType: "all",
    dates: {
      type: "past",
      value: 60,
      unit: "day",
    },
    trendType: "month_over_month",
    aggregation: {
      net_savings: true,
      utilization: true,
      commitments: true,
    },
    visualization: {
      chart_type: "stack",
      chart_base_type: "percent",
      net_savings: true,
      commitment: true,
      utilization: true,
    },
  };
