import {
  Dispatch,
  FC,
  Fragment,
  ReactNode,
  SetStateAction,
  useCallback,
} from "react";
import { Box, ListSubheader } from "@mui/material";
import { FilterListItem } from "./FilterListItem";
import { FilterListParentItem } from "./FilterListParentItem";
import { FilterItemsList } from "./FilterItemsList";
import { DropdownSelectOption } from "../utils/types/types";
import { TypographyWithTooltip } from "../../../TypographyWithTooltip";

export interface FilterListItemWrapperProps {
  index: number;
  keyword: string;
  selectedValues: string[];
  filteredOptions: DropdownSelectOption[];
  setSelectedValues: Dispatch<SetStateAction<string[]>>;
  singleSelect?: boolean;
  closeHandler(): void;
  submitHandlerOnClose?(selectedOptions: string[]): void;
  noItemsLabel?: (keyword: string) => ReactNode;
  optionsLoading?: boolean;
  renderOption?(option: DropdownSelectOption): ReactNode;
  listMaxHeight?: number;
}

export const FilterListItemWrapper: FC<FilterListItemWrapperProps> = ({
  filteredOptions,
  closeHandler,
  submitHandlerOnClose,
  selectedValues,
  setSelectedValues,
  singleSelect,
  index,
  renderOption,
  noItemsLabel,
  keyword,
  optionsLoading,
  listMaxHeight,
}) => {
  const option = filteredOptions[index] ?? {};
  const { value, nestedOptions, groupName } = option;

  const withGroupLabel =
    groupName &&
    index !== 0 &&
    groupName !== filteredOptions.at(index - 1)?.groupName;

  const handleItemToggle = useCallback(
    (value: string) => {
      setSelectedValues((selectedValues) => {
        const isSelected = selectedValues.includes(value);

        let newSelectedValues = selectedValues;
        if (isSelected) {
          newSelectedValues = newSelectedValues.filter((v) => v !== value);
        } else {
          newSelectedValues = [...newSelectedValues, value];
        }

        return newSelectedValues;
      });
    },
    [setSelectedValues],
  );

  const handleOnlyToggle = useCallback(
    (value: string) => {
      setSelectedValues([value]);
      closeHandler();
      submitHandlerOnClose?.([value]);
    },
    [closeHandler, submitHandlerOnClose, setSelectedValues],
  );

  const props = {
    option,
    selectedValues,
    singleSelect: !!singleSelect,
    onClick: handleItemToggle,
    onOnlyClick: handleOnlyToggle,
    renderOption: renderOption,
  };

  if (withGroupLabel) {
    return (
      <Fragment>
        {[
          <ListSubheader key={groupName} sx={{ whiteSpace: "nowrap" }}>
            <TypographyWithTooltip title={groupName} variant="inherit" />
          </ListSubheader>,
          <FilterListItem key={value} {...props} />,
        ]}
      </Fragment>
    );
  }

  if (nestedOptions) {
    return (
      <Fragment>
        {[
          <FilterListParentItem
            {...props}
            key={value}
            nestedOptions={nestedOptions}
            setSelectedValues={setSelectedValues}
          />,
          <Box key={`${value}_nested_items`}>
            <FilterItemsList
              nested
              keyword={keyword}
              virtualized={false}
              noItemsLabel={noItemsLabel}
              singleSelect={singleSelect}
              closeHandler={closeHandler}
              listMaxHeight={listMaxHeight}
              optionsLoading={optionsLoading}
              selectedValues={selectedValues}
              filteredOptions={nestedOptions}
              setSelectedValues={setSelectedValues}
              submitHandlerOnClose={submitHandlerOnClose}
            />
          </Box>,
        ]}
      </Fragment>
    );
  }

  return <FilterListItem {...props} />;
};
