import { FC } from "react";
import { Stack } from "@mui/material";
import { DashboardAllItemsHeader } from "./components/header/DashbaordAllItemsHeader";
import { DashboardAllItemsTable } from "./components/table/DashboardAllItemsTable";
import { HierarchyAllItemsFiltersProvider } from "../common/hierarchy-all-items/components/HierarchyAllItemsFiltersProvider";
import { useAppSelector } from "../../../store/hooks";
import { dashboardHierarchyVisibilitiesCreatedByDataSelector } from "../../../store/dashboards/selectors/dashboard-hierarchy-visibility/dashboardHierarchyMixedVisibilitiesCreatedByDataSelector";

export const DashboardAllItems: FC = () => {
  const createdByes = useAppSelector(
    dashboardHierarchyVisibilitiesCreatedByDataSelector,
  );

  return (
    <Stack bgcolor="white" height="100dvh" overflow="hidden">
      <DashboardAllItemsHeader />

      <HierarchyAllItemsFiltersProvider createdByOptions={createdByes}>
        <DashboardAllItemsTable />
      </HierarchyAllItemsFiltersProvider>
    </Stack>
  );
};
