import { Row } from "@tanstack/react-table";
import {
  CostAnomalyAlertsDataGridDataType,
  UtilizationAlertDataGridDataType,
} from "../../../../../../../../store/alerts/utils/types/types";

export const alertsThresholdSortingFn = <
  T extends
    | CostAnomalyAlertsDataGridDataType
    | UtilizationAlertDataGridDataType,
>(
  currentRow: Row<T>,
  nextRow: Row<T>,
) => {
  const currentThreshold = currentRow.original.thresholds;
  const nextThreshold = nextRow.original.thresholds;

  const current =
    currentThreshold.find((threshold) => threshold.unit === "percent")
      ?.amount ?? currentThreshold?.at(0)?.amount;
  const currentFirstThresholdValue = Number(current ?? 0);
  const next =
    nextThreshold.find((threshold) => threshold.unit === "percent")?.amount ??
    nextThreshold?.at(0)?.amount;
  const nextFirstThresholdValue = Number(next ?? 0);

  return currentFirstThresholdValue > nextFirstThresholdValue ? 1 : -1;
};
