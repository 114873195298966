import { FC, useCallback } from "react";
import { Stack } from "@mui/material";
import { GroupingSelector } from "../../../../../../common/resource-explorer-grouping-selector/GroupingSelector";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../store/hooks";
import { alertsCostAnomalyDataGroupingSelector } from "../../../../../../../../store/alerts/selectors/cost-anomaly-alerts/data/alertsCostAnomalyDataGroupingSelector";
import { setAlertsData } from "../../../../../../../../store/alerts/alertsSlice";
import { ResourceExplorerGrouping } from "../../../../../../../../services/cloudchipr.api";
import { getCostAnomalyPreviewDataGridDataThunk } from "../../../../../../../../store/alerts/thunks/cost-anomaly-alerts/getCostAnomalyPreviewDataGridDataThunk";

interface AlertsCostsAnomalyGroupingSelectProps {
  averageCostCollapsed: boolean;
}

export const AlertsCostsAnomalyGroupingSelect: FC<
  AlertsCostsAnomalyGroupingSelectProps
> = ({ averageCostCollapsed }) => {
  const dispatch = useAppDispatch();
  const groupBy = useAppSelector(alertsCostAnomalyDataGroupingSelector);

  const handleGroupingChange = useCallback(
    (grouping: ResourceExplorerGrouping) => {
      if (grouping !== groupBy) {
        dispatch(setAlertsData({ grouping }));

        if (averageCostCollapsed) {
          dispatch(getCostAnomalyPreviewDataGridDataThunk());
        }
      }
    },
    [dispatch, groupBy, averageCostCollapsed],
  );

  if (!groupBy) {
    return null;
  }

  return (
    <Stack flex={1}>
      <GroupingSelector
        value={groupBy}
        onChange={handleGroupingChange}
        hiddenGroupings={["cost_allocation_tag", "resource"]}
      />
    </Stack>
  );
};
