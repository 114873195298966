import { editingCategoryIdSelector } from "./editingCategoryIdSelector";
import { dimensionCategoriesEntriesSelector } from "../dimensionCategoriesEntriesSelector";
import { DimensionCategory } from "../../../utils/types/common";
import { RootState } from "../../../../store";

export const editingCategorySelector = (
  state: RootState,
): DimensionCategory | null => {
  const editingCategoryId = editingCategoryIdSelector(state);
  const categories = dimensionCategoriesEntriesSelector(state);

  const entry = categories?.find(([id]) => id === editingCategoryId);
  const id = entry?.at(0) as string;
  const category = entry?.at(1) as DimensionCategory;

  if (!category || !id) {
    return null;
  }

  return { ...category, id };
};
