import { FC, useCallback } from "react";
import { Dialog } from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";
import { useCounter } from "rooks";
import { ResourceExplorerBillingExportDialogContent } from "./ResourceExplorerBillingExportDialogContent";
import { DialogTitleClosable } from "../../../../../../../../common/dialog-utils/DialogTitleClosable";
import { usePostUsersMeOrganisationsCurrentGcpBillingExportDataMutation } from "../../../../../../../../../services/cloudchipr.api";
import { createResourceExplorerGcpBillingExportDataFixedCacheKey } from "../../../../utils/constants/fixedCacheKeys";

interface ResourceExplorerBillingExportDialogProps {
  open: boolean;
  onClose(): void;
  onSuccess?(): void;
}

export const ResourceExplorerBillingExportDialog: FC<
  ResourceExplorerBillingExportDialogProps
> = ({ open, onClose, onSuccess }) => {
  const {
    value: currentStep,
    incrementBy: incrementStepBy,
    increment: incrementStep,
    decrement: decrementStep,
    reset: resetSteps,
  } = useCounter(0);

  const [, { reset: resetCreateCache }] =
    usePostUsersMeOrganisationsCurrentGcpBillingExportDataMutation({
      fixedCacheKey: createResourceExplorerGcpBillingExportDataFixedCacheKey,
    });

  const { values, dirty, isValid, handleChange, resetForm } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async () => {
      incrementStep();
    },
  });

  const closeDialogHandler = useCallback(() => {
    onClose();
    resetSteps();
    resetForm();
    resetCreateCache();
  }, [onClose, resetSteps, resetForm, resetCreateCache]);

  return (
    <Dialog open={open} onClose={closeDialogHandler} maxWidth="md" fullWidth>
      <DialogTitleClosable
        title="Add Billing Export Details"
        onClose={closeDialogHandler}
      />
      <ResourceExplorerBillingExportDialogContent
        currentStep={currentStep}
        onCloseDialog={closeDialogHandler}
        onNextStep={incrementStep}
        onSkipStep={incrementStepBy}
        onPrevStep={decrementStep}
        values={values}
        onValuesChange={handleChange}
        dirty={dirty}
        isValid={isValid}
        onSuccess={onSuccess}
      />
    </Dialog>
  );
};

const validationSchema = yup.object({
  projectId: yup.string().required("The project id is required"),
  dataSetName: yup.string().required("The data set name is required"),
  table: yup.string().required("The table name is required"),
});

const initialValues: BillingExportInitialValues = {
  projectId: "",
  dataSetName: "",
  table: "",
};

export interface BillingExportInitialValues {
  projectId: string;
  dataSetName: string;
  table: string;
}
