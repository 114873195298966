import { FC, useCallback, useMemo } from "react";
import { Collapse, Divider, IconButton, Stack } from "@mui/material";

import { KeyboardArrowDown } from "@mui/icons-material";
import Typography from "@mui/material/Typography";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { rolePermissionsMapping } from "./utils/constants";
import { RolePermissionsCollapseContent } from "./RolePermissionsCollapseContent";
import { UserRoleUpdatedPermissionsKeys } from "./utils/types";
import { PermissionWithSelectedState } from "../../../../../../../store/user/utils/types/common";
import { Permission } from "../../../../../../../services/cloudchipr.api";
import { useAppDispatch } from "../../../../../../../store/hooks";
import { userRolePermissionChangeThunk } from "../../../../../../../store/user/thunks/roles/userRolePermissionChangeThunk";
import { setUserRoleDataPermissionsGroupCollapsed } from "../../../../../../../store/user/userSlice";

interface RolePermissionsCollapseProps {
  group: Permission["group"];
  data?: PermissionWithSelectedState[];
  collapsed?: boolean;
}

export const RolePermissionsCollapse: FC<RolePermissionsCollapseProps> = ({
  group,
  data,
  collapsed,
}) => {
  const dispatch = useAppDispatch();

  const selectedCounts = useMemo(() => {
    return data?.reduce((result, item) => {
      if (item.selected) {
        result++;
      }
      return result;
    }, 0);
  }, [data]);

  const permissionsChangeHandler = useCallback(
    (
      group: Permission["group"],
      updatedPermissions: UserRoleUpdatedPermissionsKeys[],
    ) => {
      dispatch(
        userRolePermissionChangeThunk({
          group,
          updatedPermissions,
        }),
      );
    },
    [dispatch],
  );

  const collapseChangeHandler = useCallback(() => {
    dispatch(
      setUserRoleDataPermissionsGroupCollapsed({
        group,
        collapsed: !collapsed,
      }),
    );
  }, [dispatch, collapsed, group]);

  const Icon = rolePermissionsMapping.get(group)?.Icon;

  return (
    <Stack border={1} borderBottom={0} borderColor="grey.300" borderRadius={1}>
      <Stack spacing={1} pt={1}>
        <Stack
          direction="row"
          alignItems="center"
          spacing={0.5}
          onClick={collapseChangeHandler}
          pl={0.5}
          justifyContent="space-between"
        >
          <Stack direction="row" spacing={0.5} alignItems="center">
            <IconButton size="small">
              {collapsed ? (
                <ChevronRightIcon color="action" />
              ) : (
                <KeyboardArrowDown color="action" />
              )}
            </IconButton>
            <Stack direction="row" alignItems="flex-end" spacing={0.5}>
              <Icon fontSize="small" />
              <Typography
                variant="body2"
                fontWeight="medium"
                sx={{ cursor: "pointer" }}
              >
                {rolePermissionsMapping.get(group)?.label}
              </Typography>
            </Stack>
          </Stack>

          <Typography variant="caption" color="text.secondary" pr={1}>
            {selectedCounts} permissions enabled
          </Typography>
        </Stack>
        <Divider />
      </Stack>
      <Collapse in={!collapsed}>
        <RolePermissionsCollapseContent
          data={data}
          onPermissionChange={permissionsChangeHandler}
        />
      </Collapse>
    </Stack>
  );
};
