import { GetQuickFilterTextParams } from "@ag-grid-community/core/dist/types/src/entities/colDef";
import { ReportsTableData } from "../../../../../../../../store/reports/utils/types/types";
import { formatDate } from "../../../../../../../../utils/helpers/date-time/datetime-format";

export const reportsNextExecutionQuickFilter = (
  params: GetQuickFilterTextParams<ReportsTableData>,
) => {
  const report = params.data;

  if (!report?.next_execution_date) {
    return "-";
  }

  return formatDate(report.next_execution_date, {
    type: "dateTimeWithComma",
  });
};
