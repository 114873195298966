import { FC, useCallback, useMemo, useState } from "react";
import { Stack, Switch, Typography } from "@mui/material";
import { indigo } from "@mui/material/colors";
import { SubscriptionPlans } from "./subscription-plans/SubscriptionPlans";
import { useAppSelector } from "../../../../../store/hooks";
import { currentSubscriptionSelector } from "../../../../../store/subscriptions/subscriptionsSelectors";
import { useGetV2PlansQuery } from "../../../../../services/cloudchipr.api";
import { enterprisePlan } from "../../utils/enterprisePlan";

export const BillingContent: FC = () => {
  const current = useAppSelector(currentSubscriptionSelector);
  const [isYearly, setIsYearly] = useState(current?.plan?.yearly || false);
  const handleSwitchChange = useCallback(() => {
    setIsYearly((isYearly) => !isYearly);
  }, []);

  const { data, isLoading: loading } = useGetV2PlansQuery();

  const plans = useMemo(() => {
    return [
      data?.free?.find((plan) => plan.yearly === isYearly),
      data?.advanced?.find((plan) => plan.yearly === isYearly),
      data?.pro?.find((plan) => plan.yearly === isYearly),
      enterprisePlan,
    ];
  }, [data, isYearly]);

  return (
    <Stack flexGrow={1}>
      <Stack
        p={1}
        mb={3}
        direction="row"
        alignItems="center"
        justifyContent="center"
      >
        <Typography color="text.secondary">Monthly</Typography>

        <Switch
          checked={isYearly}
          onChange={handleSwitchChange}
          sx={{
            ml: 1,
            "& .MuiSwitch-switchBase": {
              color: indigo[500],
              "& + .MuiSwitch-track": {
                opacity: 1,
                backgroundColor: indigo[100],
              },
            },
          }}
        />

        <Typography color="text.secondary">Yearly</Typography>
      </Stack>

      {!loading && <SubscriptionPlans plans={plans} isYearly={isYearly} />}
    </Stack>
  );
};
