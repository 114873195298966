import { UniqueIdentifier } from "@dnd-kit/core";
import type { FlattenedItem, TreeItemTypes } from "../types";

interface GetSortableTreeParentIdArgs {
  previousItem: FlattenedItem;
  depth: number;
  newItems: FlattenedItem[];
  overData: {
    id?: UniqueIdentifier | null;
    type?: TreeItemTypes;
    index: number;
  } | null;
}

export const getSortableTreeParentId = (args: GetSortableTreeParentIdArgs) => {
  const { previousItem, depth, newItems, overData } = args;
  if (depth === 0 || !previousItem) {
    return null;
  }

  if (depth === previousItem.depth) {
    return previousItem.parentId;
  }

  if (depth > previousItem.depth) {
    return previousItem.id;
  }

  const newParent = newItems.find((item) => {
    return item.id !== overData?.id && item.depth === depth;
  })?.parentId;

  return newParent ?? null;
};
