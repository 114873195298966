import { FC } from "react";
import { Dialog, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { ProviderType } from "../../../../../../services/cloudchipr.api";
import GcpRightsizingVideo from "../../../../../../assets/recommendations/gcp-rightsizing.mp4";
import AwsRightsizingVideo from "../../../../../../assets/recommendations/aws-rightsizing.mp4";

interface VideoDialogProps {
  provider: ProviderType;
  open: boolean;
  onClose(): void;
}

export const VideoDialog: FC<VideoDialogProps> = ({
  open,
  onClose,
  provider,
}) => {
  const video = rightSizingRecommendationsVideos[provider];

  return (
    <Dialog fullWidth maxWidth="md" open={open} onClose={onClose}>
      {video && (
        <video autoPlay loop height={500}>
          <track
            label="Aws missing permissions"
            kind="captions"
            src="../../assets/feature-videos/captions/rightsizing-recommendations.vtt"
          />

          <source src={video} type="video/mp4" />
        </video>
      )}

      <IconButton
        onClick={onClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
        }}
      >
        <CloseIcon />
      </IconButton>
    </Dialog>
  );
};

export const rightSizingRecommendationsVideos = {
  aws: AwsRightsizingVideo,
  gcp: GcpRightsizingVideo,
  azure: null,
};
