import { createAsyncThunk } from "@reduxjs/toolkit";
import { cloudChiprApi } from "../../../../services/cloudchipr.api";

export const getRightSizingRecommendationsStatusesThunk = createAsyncThunk(
  "recommendations/getRightSizingRecommendationsStatuses",
  async (arg, { dispatch }) => {
    const { getUsersMeOrganisationsCurrentRecommendationsStatus } =
      cloudChiprApi.endpoints;

    await dispatch(
      getUsersMeOrganisationsCurrentRecommendationsStatus.initiate(undefined, {
        forceRefetch: true,
      }),
    );
  },
);
