import { FC, ReactNode } from "react";
import { Avatar, Stack, Typography } from "@mui/material";

interface SsoFormStepProps {
  step: number;
  title: string;
  children?: ReactNode;
}

export const SsoFormDialogStep: FC<SsoFormStepProps> = ({
  step,
  title,
  children,
}) => {
  return (
    <Stack direction="row" mt={3} spacing={1}>
      <Avatar sx={{ width: 24, height: 24, my: 0.5 }} variant="rounded">
        {step}
      </Avatar>

      <Stack flexGrow={1}>
        <Typography variant="h6">{title}</Typography>
        {children}
      </Stack>
    </Stack>
  );
};
