import { createDraftSafeSelector } from "@reduxjs/toolkit";
import { rightsizingRecommendationsDismissedResourcesDataSelector } from "./rightsizingRecommendationsDismissedResourcesDataSelector";
import { selectedIdsSelector } from "../../selected-ids/selectedIdsSelector";
import { RestoreRecommendationByResourceRequest } from "../../../../../services/cloudchipr.api";

export const rightsizingRecommendationsDismissedResourcesAccountIdsByResourceIdSelector =
  createDraftSafeSelector(
    [
      rightsizingRecommendationsDismissedResourcesDataSelector,
      selectedIdsSelector,
    ],
    (data, selectedIds): RestoreRecommendationByResourceRequest | undefined => {
      return data?.reduce(
        (acc, item) => {
          if (selectedIds.includes(item.id)) {
            acc.account_ids.push(item?.account?.id ?? "");
            acc.resource_identifiers.push(item.resource_identifier);
          }

          return acc;
        },
        {
          account_ids: [],
          resource_identifiers: [],
        } as RestoreRecommendationByResourceRequest,
      );
    },
  );
