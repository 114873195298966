import { FC } from "react";
import { Step, StepLabel, Stepper } from "@mui/material";
import { blueGrey } from "@mui/material/colors";

interface ResourceExplorerBillingExportDialogStepperProps {
  currentStep: number;
}
export const ResourceExplorerBillingExportDialogStepper: FC<
  ResourceExplorerBillingExportDialogStepperProps
> = ({ currentStep }) => {
  return (
    <Stepper sx={{ backgroundColor: blueGrey["50"], p: 4 }}>
      {resourceExplorerBillingDialogSteps.map((step, index) => (
        <Step
          key={step}
          active={index === currentStep}
          completed={index < currentStep}
          disabled={index > currentStep}
        >
          <StepLabel>{step}</StepLabel>
        </Step>
      ))}
    </Stepper>
  );
};

const resourceExplorerBillingDialogSteps = [
  "Billing Details",
  "Access",
  "Data Collection",
];
