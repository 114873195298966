export const filterRecommendationByMultiplePerResourceFilter = (
  defaultRecommendation: boolean | null,
  multipleRecommendationsPerResource?: boolean,
) => {
  if (!multipleRecommendationsPerResource && defaultRecommendation) {
    return true;
  }

  return !!multipleRecommendationsPerResource;
};
