import { reservationsDataSelector } from "./reservationsDataSelector";
import { reservationsGranularDataTotalUtilizationSelector } from "./granular/totals/reservationsGranularDataTotalUtilizationSelector";
import { RootState } from "../../../../store";

export const reservationsDataIsNotAvailableSelector = (state: RootState) => {
  const data = reservationsDataSelector(state);
  const totalUtilization =
    reservationsGranularDataTotalUtilizationSelector(state);

  return !!(data?.length && !totalUtilization?.amount);
};
