import moment from "moment";
import { getTimeZoneObject } from "../../../../utils/helpers/date-time/timeZones";
import {
  AutomationsData,
  OffHoursSpecificData,
  WorkflowSpecificData,
} from "../types/common";

export const automationsInitialData: AutomationsData = {
  name: "",
  description: "",
  startDate: "",
  endDate: "",
  timeZone: getTimeZoneObject().tzCode,

  frequency: "daily",

  provider: null,
  threshold: null,

  emails: null,
  accountIds: [],
  regions: [],
  notifications: [],
  filter: [],
};

export const workflowInitialData: WorkflowSpecificData = {
  action: null,
  gracePeriod: null,
  snoozed: true,
  scheduler: {
    repeatValue: 1,
    runTime: moment().add(1, "day").set({ minutes: 0, hours: 0 }).format(),
  },
};

const offHoursStopTime = moment().add(1, "day").set({ minutes: 0, hours: 0 });
export const offHoursInitialData: OffHoursSpecificData = {
  action: "silent",
  scheduler: {
    repeatValue: 1,
    stopTime: offHoursStopTime.format(),
    startTime: offHoursStopTime.add(1, "hour").format(),
  },
};
