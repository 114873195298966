import { FC } from "react";
import { Box, Stack, TypographyProps } from "@mui/material";
import { TypographyWithTooltip } from "./TypographyWithTooltip";
import { CopyIconButton } from "./CopyIconButton";
import { useHover } from "../../utils/hooks/useHover.hook";

export const TypographyWithCopy: FC<
  TypographyProps & {
    value?: string;
    hideCopyButtonArea?: boolean;
    overrideHovered?: boolean;
    iconSize?: number;
  }
> = ({
  iconSize,
  hideCopyButtonArea,
  value,
  overrideHovered,
  ...typographyProps
}) => {
  const { ref, hovered } = useHover();

  if (!value) {
    return null;
  }
  const copyButton = (
    <CopyIconButton
      data={value}
      visible={overrideHovered || hovered}
      sx={{
        my: -1,
        ml: 0.3,
        fontSize: iconSize,
        display: hovered ? "flex" : "none",
      }}
    />
  );

  return (
    <Stack direction="row" ref={ref} alignItems="center" overflow="hidden">
      <TypographyWithTooltip
        title={value}
        variant="body2"
        {...typographyProps}
      />

      {hideCopyButtonArea ? (
        <Box display={overrideHovered || hovered ? "flex" : "none"}>
          {copyButton}
        </Box>
      ) : (
        copyButton
      )}
    </Stack>
  );
};
