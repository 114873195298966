import { FC, memo, ReactNode } from "react";
import {
  DialogTitle,
  DialogTitleProps,
  IconButton,
  IconButtonProps,
  Stack,
  Typography,
  TypographyProps,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

interface DialogTitleClosableProps {
  title: string;
  onClose(): void;
  icon?: ReactNode;
  TitleProps?: DialogTitleProps;
  ButtonProps?: IconButtonProps;
  TitleTypographyProps?: TypographyProps;
}

export const DialogTitleClosable: FC<DialogTitleClosableProps> = memo(
  ({ title, TitleProps, ButtonProps, TitleTypographyProps, icon, onClose }) => {
    return (
      <DialogTitle {...TitleProps}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography
            variant="subtitle1"
            fontWeight="medium"
            {...TitleTypographyProps}
          >
            {title}
          </Typography>
          <IconButton {...ButtonProps} onClick={onClose} size="small">
            {icon || <CloseIcon fontSize="inherit" />}
          </IconButton>
        </Stack>
      </DialogTitle>
    );
  },
);
