import { ResourceType } from "../../../services/cloudchipr.api";
import {
  actionableChildResourceTypes,
  notActionableResourceTypes,
  resourceTypeGroups,
} from "../../constants/resources/resources";

export const getActionableResourceTypeByParentResourceType = (
  parentResourceType: ResourceType,
): ResourceType => {
  if (notActionableResourceTypes.has(parentResourceType)) {
    const subResourceTypes = resourceTypeGroups[parentResourceType];

    return (
      subResourceTypes?.find((item) =>
        actionableChildResourceTypes.has(item),
      ) ?? parentResourceType
    );
  }

  return parentResourceType;
};
