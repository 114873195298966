import { FC, useCallback } from "react";
import { Divider, Stack } from "@mui/material";
import { useFlag } from "@unleash/proxy-client-react";
import { AverageCost } from "./components/AverageCost";
import { ResourceExplorerBudgets } from "./components/budgets/ResourceExplorerBudgets";
import { ResourceExplorerLiveFiltered } from "./components/live-usage/ResourceExplorerLiveFiltered";
import { ResourceExplorerForecastedCosts } from "./components/forecasted/ResourceExplorerForecastedCosts";
import { TotalCost } from "./components/TotalCost";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../store/hooks";
import { resourceExplorerWidgetTotalCostSelector } from "../../../../../../../store/resource-explorer/selectors/current-resource-explorer/resource-explorer-widget/resourceExplorerWidgetTotalCostSelector";
import { resourceExplorerWidgetAverageDataSelector } from "../../../../../../../store/resource-explorer/selectors/current-resource-explorer/resource-explorer-widget/resourceExplorerWidgetAverageDataSelector";
import { resourceExplorerDataPointSelector } from "../../../../../../../store/resource-explorer/selectors/current-resource-explorer/data/resourceExplorerDataPointSelector";
import { resourceExplorerDataGridDataTotalTrendSelector } from "../../../../../../../store/resource-explorer/selectors/current-resource-explorer/data-grid/resourceExplorerDataGridDataTotalTrendSelector";
import { resourceExplorerLiveUsageTotalCostSelector } from "../../../../../../../store/resource-explorer/selectors/current-resource-explorer/live-usage/resourceExplorerLiveUsageTotalCostSelector";
import { resourceExplorerForecastOptionSelector } from "../../../../../../../store/resource-explorer/selectors/current-resource-explorer/data/resourceExplorerForecastOptionSelector";
import { setResourceExplorerForecastOption } from "../../../../../../../store/resource-explorer/resourceExplorerSlice";
import { resourceExplorerForecastedCostsSelector } from "../../../../../../../store/resource-explorer/selectors/current-resource-explorer/forecasted-cost/resourceExplorerForecastedCostsSelector";
import { resourceExplorerDataGridDataTotalCostDateDetailsSelector } from "../../../../../../../store/resource-explorer/selectors/current-resource-explorer/data-grid/resourceExplorerDataGridDataTotalCostDateDetailsSelector";
import { resourceExplorerDataGridDataPreviousCostDateDetailsSelector } from "../../../../../../../store/resource-explorer/selectors/current-resource-explorer/data-grid/resourceExplorerDataGridDataPreviousCostDateDetailsSelector";
import { resourceExplorerDatesSelector } from "../../../../../../../store/resource-explorer/selectors/current-resource-explorer/data/resourceExplorerDatesSelector";
import { resourceExplorerCostTypeSelector } from "../../../../../../../store/resource-explorer/selectors/current-resource-explorer/resourceExplorerCostTypeSelector";

export const ResourceExplorerMetadata: FC = () => {
  const budgetsEnabled = useFlag("EnableResourcesExplorerBudgets");
  const enableLiveUsageOnResourceExplorer = useFlag(
    "EnableLiveUsageOnResourceExplorer",
  );

  const trend = useAppSelector(resourceExplorerDataGridDataTotalTrendSelector);
  const totalCostDates = useAppSelector(
    resourceExplorerDataGridDataTotalCostDateDetailsSelector,
  );
  const previousPeriodCostDates = useAppSelector(
    resourceExplorerDataGridDataPreviousCostDateDetailsSelector,
  );
  const forecastedCosts = useAppSelector(
    resourceExplorerForecastedCostsSelector,
  );

  const liveUsageTotalCost = useAppSelector(
    resourceExplorerLiveUsageTotalCostSelector,
  );

  const dispatch = useAppDispatch();

  const totalCost = useAppSelector(resourceExplorerWidgetTotalCostSelector);
  const averageData = useAppSelector(resourceExplorerWidgetAverageDataSelector);
  const dataPoint = useAppSelector(resourceExplorerDataPointSelector);
  const dates = useAppSelector(resourceExplorerDatesSelector);
  const forecastOption = useAppSelector(resourceExplorerForecastOptionSelector);
  const selectedCostType = useAppSelector(resourceExplorerCostTypeSelector);

  const forecastChangeHandler = useCallback(
    (option: any) => {
      dispatch(setResourceExplorerForecastOption(option));
    },
    [dispatch],
  );

  return (
    <Stack direction="row" justifyContent="space-between" p={1}>
      <Stack
        px={1}
        border={1}
        spacing={2}
        direction="row"
        borderRadius={2}
        borderColor="grey.300"
        alignItems="center"
        divider={<Divider orientation="vertical" flexItem />}
      >
        <TotalCost
          totalCost={totalCost}
          totalCostTrend={trend}
          totalCostDates={totalCostDates}
          previousPeriodCostDates={previousPeriodCostDates}
          dateLabel={dates?.label}
          costType={selectedCostType}
        />

        {averageData?.cost !== null && (
          <AverageCost
            average={averageData?.cost}
            averageTrend={averageData?.trend}
            dateDetails={totalCostDates}
            previousPeriodCostDates={previousPeriodCostDates}
            dateLabel={dates?.label}
            dataPoint={dataPoint ?? "monthly"}
          />
        )}

        {budgetsEnabled && <ResourceExplorerBudgets />}
      </Stack>

      <Stack
        direction="row"
        border={1}
        borderRadius={2}
        borderColor="grey.300"
        alignItems="center"
        divider={<Divider orientation="vertical" flexItem />}
      >
        <ResourceExplorerForecastedCosts
          forecastedCosts={forecastedCosts}
          selectedForecastOption={forecastOption}
          onForecastChange={forecastChangeHandler}
        />

        {enableLiveUsageOnResourceExplorer && (
          <ResourceExplorerLiveFiltered
            totalCost={liveUsageTotalCost}
            loadFrom="resource-explorer"
            loadFor="view"
          />
        )}
      </Stack>
    </Stack>
  );
};
