import { createDraftSafeSelector } from "@reduxjs/toolkit";
import { ChartDataType } from "../../../../../storybook/charts/multi-type-chart/utils/types/types";
import { resourceExplorerWidgetDataSelector } from "../resource-explorer-widget/resourceExplorerWidgetDataSelector";
import { resourceExplorerDataPointSelector } from "../data/resourceExplorerDataPointSelector";
import { formatResourceExplorerChartData } from "../../../utils/helpers/formatResourceExplorerChartData";

export const resourceExplorerChartDataSelector = createDraftSafeSelector(
  [resourceExplorerWidgetDataSelector, resourceExplorerDataPointSelector],
  (widgetData, dataPoint): ChartDataType[] | null => {
    const data = widgetData?.data;
    if (!data) {
      return null;
    }

    return formatResourceExplorerChartData(data, dataPoint);
  },
);

export const resourceExplorerChartKeyGenerator = (
  field?: string,
  label?: string | null,
) => {
  const keyLabel = label ? `${label} - ` : "";
  return `${keyLabel} ${field}`.trim();
};

export const resourceExplorerChartDataOthersKey = "Others";
