import { createAsyncThunk } from "@reduxjs/toolkit";
import { enqueueSnackbar } from "notistack";
import { findAndCreateNewAddedCategoriesThunk } from "./findAndCreateNewAddedCategoriesThunk";
import { findAndUpdateChangedCategoriesThunk } from "./findAndUpdateChangedCategoriesThunk";
import { findAndDeleteRemovedCategoriesThunk } from "./findAndDeleteRemovedCategoriesThunk";
import { updateDimensionNameThunk } from "../updateDimensionNameThunk";
import { dimensionIdSelector } from "../../../selectors/slice-data/dimensionIdSelector";
import { dimensionNameSelector } from "../../../selectors/slice-data/dimensionNameSelector";
import { dimensionByIdSelector } from "../../../selectors/dimensions-data/dimensionByIdSelector";
import { RootState } from "../../../../store";
import {
  CategoryWithCosts,
  DimensionWithCosts,
} from "../../../../../services/cloudchipr.api";

export const updateCurrentDimensionThunk = createAsyncThunk(
  "dimensions/updateCurrentDimension",
  async (_: void, { dispatch, getState }) => {
    const state = getState() as RootState;

    const dimensionId = dimensionIdSelector(state);
    const dimensionName = dimensionNameSelector(state);

    const originalDimension = dimensionByIdSelector(state, dimensionId ?? "");

    if (!originalDimension || !dimensionId) {
      return;
    }

    const originalCategoriesByIds =
      convertCategoriesArrayToObjectByIds(originalDimension);

    const needToUpdateName = originalDimension.name !== dimensionName;

    try {
      if (needToUpdateName) {
        await dispatch(
          updateDimensionNameThunk({ dimensionId, name: dimensionName }),
        ).unwrap();
      }

      await dispatch(
        findAndCreateNewAddedCategoriesThunk(originalCategoriesByIds),
      );

      await dispatch(
        findAndDeleteRemovedCategoriesThunk(originalCategoriesByIds),
      );

      await dispatch(
        findAndUpdateChangedCategoriesThunk({
          originalCategoriesByIds,
          originalDimension,
        }),
      );

      return true;
    } catch (e) {
      // @ts-expect-error todo: fix api spec
      const errMessage = e?.data?.message || "Something went wrong";
      enqueueSnackbar(errMessage, {
        variant: "snackbarAlert",
        AlertSnackBarProps: {
          severity: "error",
        },
      });
    }
  },
);

const convertCategoriesArrayToObjectByIds = (dimension: DimensionWithCosts) => {
  return dimension?.categories?.reduce(
    (acc, category) => {
      acc[category.id] = category;
      return acc;
    },
    {} as Record<string, CategoryWithCosts>,
  );
};
