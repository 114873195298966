import { RootState } from "../../store";
import { cloudChiprApi } from "../../../services/cloudchipr.api";
import { deleteReportFixedCacheKey } from "../utils/constants/fixedCacheKeys";

const endpoint =
  cloudChiprApi.endpoints.deleteUsersMeOrganisationsCurrentReportsByReportId.select(
    deleteReportFixedCacheKey,
  );

export const deleteReportLoadingSelector = (state: RootState) => {
  return endpoint(state).isLoading;
};
