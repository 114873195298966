import { Components, Theme } from "@mui/material";
import { indigo } from "@mui/material/colors";

declare module "@mui/material/Button" {
  interface ButtonPropsColorOverrides {
    tertiary?: true;
    primaryLight?: true;
  }
  interface ButtonPropsSizeOverrides {
    xsmall: true;
  }
}

export const muiButton: Components<Theme>["MuiButton"] = {
  styleOverrides: {
    root: ({ ownerState }) => {
      if (ownerState?.size === "xsmall") {
        return {
          fontSize: 12,
          padding: "0 8px",
          textTransform: "none",
        };
      }
      if (ownerState?.color === "primaryLight") {
        return {
          borderRadius: 16,
          backgroundColor: indigo["50"],
          color: indigo["500"],
          textTransform: "none",
          whiteSpace: "nowrap",
          minWidth: "auto",
          paddingLeft: 10,
          paddingRight: 10,
          "&:hover": {
            backgroundColor: indigo["50"],
            borderColor: indigo["50"],
          },
        };
      }
    },
  },
};
