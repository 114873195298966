import { FC, useCallback } from "react";
import { Link, Stack, Tooltip } from "@mui/material";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import { TypographyWithCopy } from "../../../../../common/TypographyWithCopy";
import { toggleAlertsOpenDrawer } from "../../../../../../store/alerts/alertsSlice";
import { useAppDispatch } from "../../../../../../store/hooks";
import { AlertTabType } from "../../../../../../store/alerts/utils/types/types";
import { setCostAnomalyAlertEditDataThunk } from "../../../../../../store/alerts/thunks/cost-anomaly-alerts/setCostAnomalyAlertEditDataThunk";
import { setUtilizationAlertEditDataThunk } from "../../../../../../store/alerts/thunks/utilization-alerts/setUtilizationAlertEditDataThunk";

interface AlertsDataGridNameCellProps {
  alertName: string;
  alertId: string;
  alertType: AlertTabType;
  deletedView?: boolean;
}

export const AlertsDataGridNameCell: FC<AlertsDataGridNameCellProps> = ({
  alertId,
  alertType,
  alertName,
  deletedView,
}) => {
  const dispatch = useAppDispatch();

  const handleClick = useCallback(() => {
    dispatch(toggleAlertsOpenDrawer());

    if (alertType === "costAnomaly") {
      dispatch(setCostAnomalyAlertEditDataThunk(alertId));
    } else {
      dispatch(setUtilizationAlertEditDataThunk(alertId));
    }
  }, [dispatch, alertType, alertId]);

  return (
    <Stack direction="row" spacing={0.5}>
      {deletedView && (
        <Tooltip
          arrow
          placement="top"
          title="The connected Resource Explorer view has been deleted."
        >
          <WarningAmberIcon color="warning" fontSize="small" />
        </Tooltip>
      )}

      <TypographyWithCopy
        fontSize="inherit"
        value={alertName}
        component={Link}
        onClick={handleClick}
        sx={{ cursor: "pointer" }}
      />
    </Stack>
  );
};
