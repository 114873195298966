import { FC, RefObject, useCallback, useLayoutEffect, useMemo } from "react";
import {
  ColumnResizeMode,
  Header,
  HeaderGroup,
  Table,
} from "@tanstack/react-table";
import { TableCell, TableRow, Theme } from "@mui/material";
import { SxProps } from "@mui/system";
import { useVirtual, VirtualItem } from "react-virtual";
import { TableHeaderCell } from "../TableHeaderCell";

type VirtualColumnsConfig = {
  columns: VirtualItem[];
  pl: number;
  pr: number;
};

interface TableHeaderRowVirtualizedProps {
  table: Table<any>;
  columnsFilterEnabled: boolean;
  rowDnDEnabled: boolean;
  columnsDnDEnabled: boolean;
  columnsSortEnabled: boolean;
  stickyColumnsEnabled: boolean;
  styles?: SxProps<Theme>;
  columnResizeMode?: ColumnResizeMode;
  rerender(): void;
  tableContainerRef: RefObject<HTMLTableElement>;
  reAssignVirtualColumns(config: VirtualColumnsConfig): void;
  headerGroup: HeaderGroup<any>;
}

export const TableHeaderRowVirtualized: FC<TableHeaderRowVirtualizedProps> = ({
  table,
  columnsFilterEnabled,
  columnsDnDEnabled,
  columnsSortEnabled,
  columnResizeMode,
  rowDnDEnabled,
  styles,
  stickyColumnsEnabled,
  rerender,
  tableContainerRef,
  reAssignVirtualColumns,
  headerGroup,
}) => {
  const visibleColumns = table.getVisibleLeafColumns();
  const noStickyColumns = useMemo(() => {
    if (!stickyColumnsEnabled) {
      return visibleColumns;
    }

    return visibleColumns.filter((column) => !column.columnDef.meta?.sticky);
  }, [stickyColumnsEnabled, visibleColumns]);

  const estimateSize = useCallback(
    (index: any) => noStickyColumns[index].getSize(),
    [noStickyColumns],
  );

  const { virtualItems, totalSize } = useVirtual({
    size: noStickyColumns.length,
    estimateSize,
    horizontal: true,
    overscan: 1,
    parentRef: tableContainerRef,
  });

  let virtualPl = 0;
  let virtualPr = 0;

  if (virtualItems?.length) {
    virtualPl = virtualItems.at(0)?.start ?? 0;
    virtualPr = totalSize - (virtualItems.at(-1)?.end ?? 0);
  }

  const { noStickyHeaders, rightHeaders, leftHeaders } = useMemo(() => {
    if (!stickyColumnsEnabled) {
      return {
        noStickyHeaders: headerGroup.headers,
        rightHeaders: [],
        leftHeaders: [],
      };
    }

    return headerGroup.headers?.reduce(
      (acc, header) => {
        if (!header.column.columnDef.meta?.sticky) {
          acc.noStickyHeaders.push(header);
        } else if (header.column.columnDef.meta?.sticky === "left") {
          acc.leftHeaders.push(header);
        } else if (header.column.columnDef.meta?.sticky === "right") {
          acc.rightHeaders.push(header);
        }

        return acc;
      },
      {
        noStickyHeaders: [],
        rightHeaders: [],
        leftHeaders: [],
      } as Record<string, Header<any, any>[]>,
    );
  }, [stickyColumnsEnabled, headerGroup.headers]);

  useLayoutEffect(() => {
    reAssignVirtualColumns({
      columns: virtualItems,
      pl: virtualPl,
      pr: virtualPr,
    });
  }, [virtualItems, reAssignVirtualColumns, virtualPl, virtualPr]);

  return (
    <TableRow key={headerGroup.id} id={headerGroup.id} sx={styles}>
      {rowDnDEnabled && <TableCell />}

      {leftHeaders.map((header) => {
        return (
          <TableHeaderCell
            key={header.id}
            header={header}
            rerender={rerender}
            resizeMode={columnResizeMode}
            dndEnabled={columnsDnDEnabled}
            sortEnabled={columnsSortEnabled}
            filtersEnabled={columnsFilterEnabled}
            setColumnOrder={table.setColumnOrder}
            columnOrder={table.getState().columnOrder}
            stickyColumnsEnabled={stickyColumnsEnabled}
            resizingDeltaOffset={table.getState().columnSizingInfo.deltaOffset}
          />
        );
      })}

      {virtualPl ? <th style={{ width: virtualPl }} /> : null}

      {virtualItems.map((vc) => {
        const header = noStickyHeaders[vc.index];

        return (
          <TableHeaderCell
            key={header.id}
            header={header}
            rerender={rerender}
            resizeMode={columnResizeMode}
            dndEnabled={columnsDnDEnabled}
            sortEnabled={columnsSortEnabled}
            filtersEnabled={columnsFilterEnabled}
            setColumnOrder={table.setColumnOrder}
            columnOrder={table.getState().columnOrder}
            stickyColumnsEnabled={stickyColumnsEnabled}
            resizingDeltaOffset={table.getState().columnSizingInfo.deltaOffset}
          />
        );
      })}

      {virtualPr ? <th style={{ width: virtualPr }} /> : null}

      {rightHeaders.map((header) => {
        return (
          <TableHeaderCell
            key={header.id}
            header={header}
            rerender={rerender}
            resizeMode={columnResizeMode}
            dndEnabled={columnsDnDEnabled}
            sortEnabled={columnsSortEnabled}
            filtersEnabled={columnsFilterEnabled}
            setColumnOrder={table.setColumnOrder}
            columnOrder={table.getState().columnOrder}
            stickyColumnsEnabled={stickyColumnsEnabled}
            resizingDeltaOffset={table.getState().columnSizingInfo.deltaOffset}
          />
        );
      })}
    </TableRow>
  );
};
