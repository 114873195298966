import { SvgIcon, SvgIconProps, useTheme } from "@mui/material";

const EbsSvgIcon = ({ primary }: SvgIconProps & { primary?: boolean }) => {
  const { palette } = useTheme();

  const color = primary ? palette.primary.main : palette.action.active;

  return (
    <SvgIcon>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_18376_16335)">
          <path
            d="M0 4C0 1.79086 1.79086 0 4 0H20C22.2091 0 24 1.79086 24 4V20C24 22.2091 22.2091 24 20 24H4C1.79086 24 0 22.2091 0 20V4Z"
            fill="#EEEEEE"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M19.7992 16.8016H20.3992V20.4016H16.7992V19.8016H19.375L16.5868 17.014L17.0116 16.5892L19.7992 19.3774V16.8016ZM8.01162 17.014L5.22342 19.8016H7.79922V20.4016H4.19922V16.8016H4.79922V19.3774L7.58682 16.5892L8.01162 17.014ZM20.3992 3.60156V7.20156H19.7992V4.62576L17.0116 7.41396L16.5868 6.98916L19.375 4.20156H16.7992V3.60156H20.3992ZM5.22342 4.20156L8.01162 6.98916L7.58682 7.41396L4.79922 4.62576V7.20156H4.19922V3.60156H7.79922V4.20156H5.22342ZM12.2992 9.30156C10.156 9.30156 8.99922 8.67756 8.99922 8.42316V8.40156C8.99922 8.02596 10.255 7.50156 12.2992 7.50156C14.3434 7.50156 15.5992 8.02596 15.5992 8.40156V8.42316C15.5992 8.67756 14.4424 9.30156 12.2992 9.30156ZM12.2992 16.5016C10.255 16.5016 8.99922 15.9772 8.99922 15.6016V9.23496C9.73602 9.67116 11.0416 9.90156 12.2992 9.90156C13.5568 9.90156 14.8624 9.67116 15.5992 9.23496V15.6016C15.5992 15.9772 14.3434 16.5016 12.2992 16.5016ZM12.2992 6.90156C10.3582 6.90156 8.39922 7.36536 8.39922 8.40156V15.6016C8.39922 16.6378 10.3582 17.1016 12.2992 17.1016C14.2402 17.1016 16.1992 16.6378 16.1992 15.6016V8.40156C16.1992 7.36536 14.2402 6.90156 12.2992 6.90156Z"
            fill={color}
            fillOpacity="0.87"
          />
        </g>
        <defs>
          <clipPath id="clip0_18376_16335">
            <path
              d="M0 4C0 1.79086 1.79086 0 4 0H20C22.2091 0 24 1.79086 24 4V20C24 22.2091 22.2091 24 20 24H4C1.79086 24 0 22.2091 0 20V4Z"
              fill="white"
            />
          </clipPath>
        </defs>
      </svg>
    </SvgIcon>
  );
};

EbsSvgIcon.muiName = "EksSvgIcon";

export default EbsSvgIcon;
