import { createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../../../../../store";
import { costAndUsageSumWidgetSetupSelector } from "../../../../selectors/setups/cost-and-usage/costAndUsageSumWidgetSetupSelector";
import { setWidgetSetup } from "../../../../dashboardsSlice";
import { Dates } from "../../../../../../services/cloudchipr.api";
import { getCostAndUsageSumWidgetDataBySetupThunk } from "../data-get/getCostAndUsageSumWidgetDataBySetupThunk";

export const costAndUsageSumDateChangeThunk = createAsyncThunk(
  "dashboards/costAndUsageDateChange",
  async (dates: Dates, { dispatch, getState }) => {
    const state = getState() as RootState;
    const setup = costAndUsageSumWidgetSetupSelector(state);

    if (!setup) {
      return;
    }

    dispatch(setWidgetSetup({ ...setup, dates }));
    dispatch(getCostAndUsageSumWidgetDataBySetupThunk());
  },
);
