import { RootState } from "../../../../store";
import { automationErrorsSelector } from "../../common/errors/automationErrorsSelector";
import { automationNotificationsErrorsGenerator } from "../../common/errors/notifications/automationNotificationsErrorsGenerator";
import { GracePeriodNotificationsError } from "../../../../../components/pages/automations/workflows/single-workflow/form/actions-section/grace-period/GracePeriodNotifications";

export const workflowGracePeriodNotificationsError = (state: RootState) => {
  const errors = automationErrorsSelector(state);

  const gracePeriodErrors = Object.keys(errors).reduce(
    (acc, key) => {
      if (key.startsWith("gracePeriod.notifications")) {
        acc[key.replace("gracePeriod.", "")] = errors[key];
      }

      return acc;
    },
    {} as Record<string, string>,
  );

  return {
    notifications: automationNotificationsErrorsGenerator(gracePeriodErrors),
  } as unknown as GracePeriodNotificationsError;
};
