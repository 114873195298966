import { ColDef } from "@ag-grid-community/core/dist/types/src/entities/colDef";

export const automationsEnabledStateSortingFn: ColDef["comparator"] = (
  valueA,
  valueB,
  nodeA,
  nodeB,
) => {
  const current = nodeA.data.status;
  const next = nodeB.data.status;

  return current > next ? 1 : -1;
};
