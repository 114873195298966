import { utilizationAndCoverageDetailsPayloadSelector } from "../utilizationAndCoverageDetailsPayloadSelector";
import { RootState } from "../../../../../store";
import { cloudChiprApi } from "../../../../../../services/cloudchipr.api";

const selector =
  cloudChiprApi.endpoints.getUsersMeOrganisationsCurrentSavingsPlansData.select;

export const utilizationAndCoverageDetailsSelector = (state: RootState) => {
  const payload = utilizationAndCoverageDetailsPayloadSelector(state);

  if (!payload) {
    return;
  }

  return selector(payload)(state);
};
