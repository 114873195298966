import {
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from "@mui/material";
import { FC, Fragment, SyntheticEvent, useCallback, useState } from "react";
import {
  AwsTerraformModuleLink,
  PermissionStepContentType,
} from "../utils/types/permissionStepContentType";
import { StepOption } from "../../../account-setup/steps/components/StepOption";
import { StepOptionActions } from "../../../account-setup/steps/components/StepOptionActions";
import { useAppDispatch, useAppSelector } from "../../../../../store/hooks";
import { setProviderAccessType } from "../../../../../store/account-setup/accountSetupSlice";
import {
  ProviderAccessType,
  ProviderType,
} from "../../../../../services/cloudchipr.api";
import { providerAccessTypeSelector } from "../../../../../store/account-setup/selectors/providerAccessTypeSelector";

interface PermissionOptionsProps {
  provider: ProviderType;
}

export const PermissionOptions: FC<PermissionOptionsProps> = ({ provider }) => {
  const dispatch = useAppDispatch();
  const accessType = useAppSelector(providerAccessTypeSelector);

  const [selectedValue, setSelectedValue] = useState(accessType);

  const handleRadioSelection = useCallback(
    (_: SyntheticEvent, value: string) => {
      setSelectedValue(value as ProviderAccessType);
      dispatch(setProviderAccessType(value as ProviderAccessType));
    },
    [dispatch],
  );

  return (
    <Fragment>
      <Stack direction="row">
        <Typography variant="body1" mb={1} fontWeight="medium">
          Choose the initial access mode
        </Typography>
      </Stack>

      <RadioGroup value={selectedValue} onChange={handleRadioSelection}>
        {permissionStepContents.map((content) => (
          <FormControlLabel
            key={content.accessType}
            value={content.accessType}
            control={<Radio />}
            label={
              <StepOption
                content={content}
                actions={
                  <StepOptionActions
                    accessType={content.accessType}
                    terraformModuleLink={
                      terraformModuleLinks[provider]?.[content.accessType]
                    }
                  />
                }
              />
            }
            sx={{ alignItems: "start" }}
          />
        ))}
      </RadioGroup>
    </Fragment>
  );
};

const permissionStepContents: PermissionStepContentType[] = [
  {
    accessType: "read_write",
    title: "Read / Write",
    caption: (
      <Typography variant="body2" color="text.secondary">
        <Typography component="span" variant="body2" fontWeight="bold">
          Read / Write{" "}
        </Typography>
        access allows to use automation workflows and actions.{" "}
        <Typography component="span" variant="body2" fontWeight="medium">
          No modifications will be made to cloud resources{" "}
        </Typography>{" "}
        at this time. Before taking any action, Cloudchipr will ask for
        confirmation.
      </Typography>
    ),
  },
  {
    accessType: "read",
    title: "Read / Only",
    caption: (
      <Typography variant="body2" color="text.secondary">
        <Typography component="span" variant="body2" fontWeight="bold">
          Read / Only{" "}
        </Typography>
        access is great for exploring saving opportunities, it only permits
        receiving notifications and does not support any actions.
      </Typography>
    ),
  },
];

const terraformModuleLinks: AwsTerraformModuleLink = {
  aws: {
    read_write:
      "https://registry.terraform.io/modules/cloudchipr/connect-c8r/cloudchipr/latest#read-and-write-access",
    read: "https://registry.terraform.io/modules/cloudchipr/connect-c8r/cloudchipr/latest#read-only-access",
  },
};
