import { FC, useCallback } from "react";
import { DropdownSelectOption } from "../../../../../../common/select/dropdown-select/utils/types/types";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../store/hooks";
import { DropdownSelect } from "../../../../../../common/select/dropdown-select/DropdownSelect";
import { setRecommendationsAccountFilter } from "../../../../../../../store/recommendations/recommendationsSlice";
import { connectedAccountsGroupedByProviderAndOrganization } from "../../../../../../../store/accounts/selectors/connected-accounts/connectedAccountsGroupedByProviderAndOrganization";
import { AccountOptionLabel } from "../../../../../../common/account-select/components/AccountOptionLabel";

export const OffHoursRecommendationsAccountFilter: FC = () => {
  const dispatch = useAppDispatch();
  const groupedAllAccounts = useAppSelector(
    connectedAccountsGroupedByProviderAndOrganization,
  );

  const filterFunction = useCallback(
    (option: DropdownSelectOption, keyword: string) => {
      const aim =
        `${option.value} ${option.rawValue?.label} ${option.rawValue?.providerAccountId} ${option.rawValue?.orgId}`.toLowerCase();

      return aim.includes(keyword.trim().toLowerCase());
    },
    [],
  );

  const options = groupedAllAccounts.map((option) => ({
    value: option.value,
    rawValue: option,
    listItemHeight: option?.groupName?.includes(option.orgId ?? "")
      ? 77
      : undefined,
    label: (
      <AccountOptionLabel
        showAccountId
        option={option}
        total={false}
        ems={false}
      />
    ),
  }));

  const changeHandler = useCallback(
    (accounts: string[]) => {
      dispatch(setRecommendationsAccountFilter(accounts));
    },
    [dispatch],
  );

  return (
    <DropdownSelect
      listWidth={360}
      label="Accounts"
      options={options}
      filterFn={filterFunction}
      submitHandlerOnClose={changeHandler}
    />
  );
};
