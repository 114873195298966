import { FC, useMemo } from "react";
import { Box } from "@mui/material";
import { Layouts, Responsive, WidthProvider } from "react-grid-layout";
import { InternalWidgetComponentByWidgetType } from "./InternalWidgetComponentByWidgetType";
import {
  reactGridLayoutBreakpoints,
  reactGridLayoutCols,
  reactGridLayoutMargin,
  reactGridLayoutStyles,
} from "../../../pages/dashboard/utils/constants/reactGridLayout";
import { widgetSetupContentChartHeight } from "../../../pages/dashboard/utils/constants/stylings";
import { GetUsersMeOrganisationsCurrentDashboardsByDashboardIdApiResponse } from "../../../../services/cloudchipr.api";
import { generateDefaultLayoutFromWidgetIds } from "../../../../store/dashboards/utils/helpers/dashboardLayout";
import { RegisterRequestState } from "../utils/hooks/useDashboardStateGlobalVar.hook";
import { WidgetWrapper } from "../../../pages/dashboard/components/WidgetWrapper";

const ResponsiveGridLayout = WidthProvider(Responsive);

interface InternalDashboardLayoutProps {
  dashboard: GetUsersMeOrganisationsCurrentDashboardsByDashboardIdApiResponse;
  organisationId: string;
  registerRequestState: RegisterRequestState;
}

export const InternalDashboardLayout: FC<InternalDashboardLayoutProps> = ({
  dashboard,
  organisationId,
  registerRequestState,
}) => {
  const layout = useMemo(() => {
    if (!dashboard?.layout) {
      return generateDefaultLayoutFromWidgetIds(
        dashboard.widgets.map((widget) => widget.id),
      );
    }

    return dashboard?.layout as Layouts;
  }, [dashboard]);

  if (!layout) {
    return null;
  }

  return (
    <Box pb={3} sx={reactGridLayoutStyles}>
      <ResponsiveGridLayout
        isDraggable={false}
        isResizable={false}
        layouts={layout}
        className="layout"
        cols={reactGridLayoutCols}
        margin={reactGridLayoutMargin}
        rowHeight={widgetSetupContentChartHeight}
        breakpoints={reactGridLayoutBreakpoints}
      >
        {dashboard.widgets?.map((widget) => (
          <WidgetWrapper key={widget.id} widgetId={widget.id}>
            <InternalWidgetComponentByWidgetType
              key={widget.id}
              widget={widget}
              organisationId={organisationId}
              registerRequestState={registerRequestState}
            />
          </WidgetWrapper>
        ))}
      </ResponsiveGridLayout>
    </Box>
  );
};
