import { rightsizingRecommendationsDismissedResourcesDataSelector } from "../rightsizing/dismissed-resources/rightsizingRecommendationsDismissedResourcesDataSelector";
import { RootState } from "../../../store";
import { offHoursRecommendationsDismissedResourcesSelector } from "../off-hours/dismissed-resources/offHoursRecommendationsDismissedResourcesSelector";

export const recommendationsDismissedResourcesNamesSelector = (
  state: RootState,
  recommendationIds: string[],
  recommendationType: "off-hours" | "rightsizing",
) => {
  let names: string[];
  const recommendationIdsSet = new Set(recommendationIds);

  if (recommendationType === "off-hours") {
    const offHoursRecommendationsData =
      offHoursRecommendationsDismissedResourcesSelector(state);

    names =
      offHoursRecommendationsData
        ?.filter((r) => recommendationIdsSet.has(r.id))
        ?.map((r) => r.name ?? "") || [];
  } else {
    const recommendationsData =
      rightsizingRecommendationsDismissedResourcesDataSelector(state);
    names =
      recommendationsData
        ?.filter((r) => recommendationIdsSet.has(r.id))
        ?.map((r) => r.name ?? "") || [];
  }

  return names?.join(", ");
};
