import { RootState } from "../../../../store";
import { widgetSetupSelector } from "../widgetSetupSelector";
import { CostAndUsageSumSetupType } from "../../../utils/types/setups/costAndUsageSumSetupType";

export const costAndUsageSumWidgetSetupSelector = (
  state: RootState,
): CostAndUsageSumSetupType | undefined => {
  const data = widgetSetupSelector(state);
  if (!data) {
    return;
  }
  return data as CostAndUsageSumSetupType;
};
