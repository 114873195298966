import { Row } from "@tanstack/react-table";
import { OffHoursSchedule } from "../../../../../../../services/cloudchipr.api";
import { frequencySortingFn } from "../../../../common/utils/sortingFunctions/frequencySortingFn";

export const offHoursFrequencySortingFn = (
  currentRow: Row<OffHoursSchedule>,
  nextRow: Row<OffHoursSchedule>,
) => {
  const current = currentRow.original.start_cron;
  const next = nextRow.original.start_cron;

  return frequencySortingFn(current, next);
};
