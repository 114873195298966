import { commitmentUtilizationSetupPropertyByKeySelector } from "./commitmentUtilizationSetupPropertyByKeySelector";
import { RootState } from "../../../../store";
import { WidgetCommitmentsUtilizationAggregation } from "../../../../../services/cloudchipr.api";

export const commitmentUtilizationSetupHasAggregationSelector = (
  state: RootState,
) => {
  const aggregations: Required<WidgetCommitmentsUtilizationAggregation> | null =
    commitmentUtilizationSetupPropertyByKeySelector("aggregation")(state);

  return Object.keys(aggregations ?? {}).some(
    (key) =>
      !!aggregations?.[key as keyof WidgetCommitmentsUtilizationAggregation],
  );
};
