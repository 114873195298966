import { RootState } from "../../../../store";

import { CommitmentType } from "../../../../../services/cloudchipr.api";
import { commitmentsRecommendationsCSVDataSelector } from "../data/commitmentsRecommendationsCSVDataSelector";
import { reservationsRecommendationsCSVDataSelector } from "../reservations/reservationsRecommendationsCSVDataSelector";

export const commitmentsRecommendationsCSVDataByTypeSelector = (
  state: RootState,
  type: CommitmentType,
) => {
  if (type === "savings_plan") {
    return commitmentsRecommendationsCSVDataSelector(state);
  }
  if (type === "reservation") {
    return reservationsRecommendationsCSVDataSelector(state);
  }
};
