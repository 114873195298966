import { FC } from "react";
import { Stack } from "@mui/material";
import { ProviderIcon } from "../../../../../../../../../../common/ProviderIcon";
import { TypographyWithTooltip } from "../../../../../../../../../../common/TypographyWithTooltip";
import { ProviderType } from "../../../../../../../../../../../services/cloudchipr.api";

interface CostAnomalyPreviewFieldCellProps {
  cloudProvider?: ProviderType;
  field: string;
}

export const CostAnomalyPreviewFieldCell: FC<
  CostAnomalyPreviewFieldCellProps
> = ({ cloudProvider, field }) => {
  return (
    <Stack direction="row" spacing={1}>
      {cloudProvider && <ProviderIcon provider={cloudProvider} width="20" />}
      <TypographyWithTooltip title={field} variant="inherit" />
    </Stack>
  );
};
