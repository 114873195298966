import { FC, useCallback } from "react";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../../../store/hooks";
import { WidgetViewTypeSelect } from "../../../common/WidgetViewTypeSelect";
import { costBreakdownViewTypeChangeThunk } from "../../../../../../../../../../store/dashboards/thunks/widgets/cost-breakdown/setup-change/costBreakdownViewTypeChangeThunk";
import { costBreakdownSetupVisualizationPropertyByKeySelector } from "../../../../../../../../../../store/dashboards/selectors/setups/cost-breakdown/costBreakdownSetupVisualizationPropertyByKeySelector";

export const CostBreakdownWidgetViewTypeSelect: FC = () => {
  const dispatch = useAppDispatch();
  const viewType = useAppSelector(
    costBreakdownSetupVisualizationPropertyByKeySelector("chart_type"),
  );

  const changeHandler = useCallback(
    (e: any) => {
      const type = e.target.value;

      dispatch(costBreakdownViewTypeChangeThunk(type));
    },
    [dispatch],
  );

  return (
    <WidgetViewTypeSelect
      viewType={viewType}
      options={["bar", "stack", "line", "pie", "table", "numeral"]}
      onChange={changeHandler}
    />
  );
};
