import { useCallback, useState } from "react";
import { useDidMount, useTimeoutWhen } from "rooks";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../store/hooks";
import {
  goToPrevStep,
  resetAccountAttempt,
  setPolling,
} from "../../../../../../../store/account-setup/accountSetupSlice";
import { pollingSelector } from "../../../../../../../store/account-setup/selectors/pollingSelector";

export function useAssignRolesHook() {
  const isPolling = useAppSelector(pollingSelector);
  const [tab, setTab] = useState<"tenant" | "subscription">("tenant");
  const [pollingStarted, setPollingStarted] = useState(false);

  const dispatch = useAppDispatch();
  const prevHandler = useCallback(() => {
    dispatch(resetAccountAttempt());
    dispatch(goToPrevStep());
  }, [dispatch]);

  useDidMount(() => {
    setPollingStarted(true);
  });

  useTimeoutWhen(
    () => {
      dispatch(setPolling(true));
    },
    10000,
    pollingStarted,
  );

  return { tab, setTab, prevHandler, isPolling };
}
