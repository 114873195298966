import { createDraftSafeSelector } from "@reduxjs/toolkit";
import { dashboardHierarchyVisibilitiesDataSelector } from "./dashboardHierarchyVisibilitiesDataSelector";
import { VisibilityType } from "../../../../components/pages/common/hierarchy-all-items/utils/types/types";
import { DashboardMixedVisibilitiesItemType } from "../../../../components/pages/dashboard-all-items/components/table/utils/types/types";

export const dashboardHierarchyMixedVisibilitiesDataSelector =
  createDraftSafeSelector(
    [dashboardHierarchyVisibilitiesDataSelector],
    (data): DashboardMixedVisibilitiesItemType[] | null => {
      if (!data) {
        return null;
      }

      return [
        ...(data?.visible_to_everyone?.map((view) => ({
          ...view,
          visibility: "visible_to_everyone" as VisibilityType,
        })) ?? []),
        ...(data?.visible_only_to_me?.map((view) => ({
          ...view,
          visibility: "visible_only_to_me" as VisibilityType,
        })) ?? []),
      ];
    },
  );
