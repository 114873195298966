import { resourceExplorerGroupingTagKeysSelector } from "./resourceExplorerGroupingTagKeysSelector";
import { RootState } from "../../../store";

import { connectedProvidersSelector } from "../connectedProvidersSelector";

export const resourceExplorerGroupingTagKeysLoadingSelector = (
  state: RootState,
) => {
  const providers = connectedProvidersSelector(state);

  return providers.some((provider) => {
    return resourceExplorerGroupingTagKeysSelector(state, provider)?.isLoading;
  });
};
