import { RootState } from "../../../store";
import { navigationDashboardPrivateDataSelector } from "../../../navigation/selectors/dashboard/navigationDashboardPrivateDataSelector";
import { navigationDashboardPublicDataSelector } from "../../../navigation/selectors/dashboard/navigationDashboardPublicDataSelector";

export const dashboardsItemsCountSelector = (state: RootState): number => {
  const privateDataCount =
    navigationDashboardPrivateDataSelector(state)?.length ?? 0;
  const publicDataCount =
    navigationDashboardPublicDataSelector(state)?.length ?? 0;

  return publicDataCount + privateDataCount;
};
