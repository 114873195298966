import { FC, useCallback } from "react";
import { Box, Typography } from "@mui/material";
import { useEffectOnceWhen } from "rooks";
import { ResourceDetailsLoading } from "./ResourceDetailsLoading";
import { ResourceDetailsTable } from "./ResourceDetailsTable";
import { ProviderType } from "../../../../../../../../../../../../services/cloudchipr.api";
import { tagLabels } from "../../../../../../../utils/constants/tagLabels";
import { resourceExplorerSingleResourceDataLoadingSelector } from "../../../../../../../../../../../../store/resource-explorer/selectors/resource-explorer-single-resource-data/resourceExplorerSingleResourceDataLoadingSelector";
import { RootState } from "../../../../../../../../../../../../store/store";
import { resourceExplorerSingleResourceDataDataSelector } from "../../../../../../../../../../../../store/resource-explorer/selectors/resource-explorer-single-resource-data/resourceExplorerSingleResourceDataDataSelector";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../../../../../store/hooks";
import { getResourceExplorerSingleResourceDataThunk } from "../../../../../../../../../../../../store/resource-explorer/thunks/resource-explorer/getResourceExplorerSingleResourceDataThunk";

interface ResourceDetailsProps {
  needToFetch: boolean;
  accountId: string;
  resourceId: string;
  provider: ProviderType;
}

export const ResourceDetails: FC<ResourceDetailsProps> = ({
  resourceId,
  accountId,
  provider,
  needToFetch,
}) => {
  const dispatch = useAppDispatch();

  const selector = useCallback(
    (state: RootState) => {
      return {
        isLoading: resourceExplorerSingleResourceDataLoadingSelector(
          state,
          provider,
          accountId,
          resourceId,
        ),
        data: resourceExplorerSingleResourceDataDataSelector(
          state,
          provider,
          accountId,
          resourceId,
        ),
      };
    },
    [resourceId, accountId, provider],
  );

  const { isLoading, data } = useAppSelector(selector);

  useEffectOnceWhen(() => {
    dispatch(
      getResourceExplorerSingleResourceDataThunk({
        provider,
        accountId,
        resourceId,
      }),
    );
  }, needToFetch);

  if (isLoading) {
    return <ResourceDetailsLoading />;
  }

  return (
    <Box width={500} minHeight={200} maxHeight={350}>
      <Box>
        <Typography
          pl={2}
          variant="overline"
          fontWeight="medium"
          color="text.secondary"
        >
          {tagLabels.get(provider)}
        </Typography>
        <ResourceDetailsTable tags={data?.tags ?? []} provider={provider} />
      </Box>
    </Box>
  );
};
