import { allAccountsGroupedByProviderAndOrgSelector } from "./all-providers/allAccountsGroupedByProviderAndOrgSelector";
import { RootState } from "../../store";
import { ProviderType } from "../../../services/cloudchipr.api";

export const accountsByProviderAndOrgIdSelector = (
  state: RootState,
  provider?: ProviderType,
  orgId?: string,
) => {
  if (!orgId || !provider) {
    return null;
  }

  const allAccountsGroupedByProviderAndOrg =
    allAccountsGroupedByProviderAndOrgSelector(state);
  return allAccountsGroupedByProviderAndOrg?.[provider][orgId] ?? [];
};
