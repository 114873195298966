import { createDraftSafeSelector } from "@reduxjs/toolkit";
import { accountsStateSelector } from "./accountsStateSelector";
import { Account } from "../../../../services/cloudchipr.api";

export const stateAccountsSelector = createDraftSafeSelector(
  [accountsStateSelector],
  (accountsState): Account[] | null => {
    return accountsState.accounts;
  },
);
