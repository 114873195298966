import { CostAnomalyThresholdDataType } from "../types/types";
import {
  CostAnomalyAlertTimeIntervalUnit,
  CostAnomalyAlertUnitType,
} from "../../../../../../../../../store/alerts/utils/types/types";

export const costsAnomalyThresholdDataByTypeV2 = new Map<
  CostAnomalyAlertUnitType,
  CostAnomalyThresholdDataType
>([
  [
    "percent",
    {
      title: "Min percentage increase",
      unit: "%",
      tooltipTitle:
        "Only trigger alerts for cost changes greater than this percentage.",
    },
  ],
  [
    "cost",
    {
      title: "Min cost increase",
      unit: "$",
      tooltipTitle:
        "Only trigger alerts for cost changes greater than this amount.",
    },
  ],
]);

export const intervalLimitByIntervalUnit = new Map<
  CostAnomalyAlertTimeIntervalUnit,
  number
>([
  ["days", 365],
  ["weeks", 52],
  ["months", 12],
]);
