import { Fragment } from "react";
import { LiveUsageMgmtAwsMissings } from "./components/aws-missings/LiveUsageMgmtAwsMissings";
import { LiveUsageMgmtGcpMissings } from "./components/gcp-missings/LiveUsageMgmtGcpMissings";
import { LiveUsageMgmtAzureMissings } from "./components/azure-missings/LiveUsageMgmtAzureMissings";
import { useAppSelector } from "../../../../../../store/hooks";
import { liveUsageMgmtProviderSelector } from "../../../../../../store/live-usage-mgmt/selectors/store-selectors/liveUsageMgmtProviderSelector";
import { liveUsageMgmtAccountIdsSelector } from "../../../../../../store/live-usage-mgmt/selectors/store-selectors/liveUsageMgmtAccountIdsSelector";
import { liveUsageFirstAccountMetaDataSelector } from "../../../../../../store/live-usage-mgmt/selectors/accounts-data/liveUsageFirstAccountMetaDataSelector";

export const LiveUsageMissingPermissions = () => {
  const provider = useAppSelector(liveUsageMgmtProviderSelector);
  const accountIds = useAppSelector(liveUsageMgmtAccountIdsSelector);

  const metaData = useAppSelector(liveUsageFirstAccountMetaDataSelector);

  if (!accountIds || accountIds?.length > 1 || !metaData) {
    return null;
  }

  const {
    isSubAccount,
    isRootAccount,
    parentId,
    serviceAccount,
    projectId,
    servicePrincipal,
    subscriptionId,
  } = metaData;

  return (
    <Fragment>
      {provider === "aws" && (
        <LiveUsageMgmtAwsMissings
          accountId={accountIds[0]}
          isSubAccount={isSubAccount}
          isRootAccount={isRootAccount}
          parentId={parentId}
        />
      )}

      {provider === "gcp" && (
        <LiveUsageMgmtGcpMissings
          accountId={accountIds[0]}
          serviceAccount={serviceAccount}
          projectId={projectId}
        />
      )}

      {provider === "azure" && (
        <LiveUsageMgmtAzureMissings
          accountId={accountIds[0]}
          subscriptionId={subscriptionId}
          servicePrincipal={servicePrincipal}
        />
      )}
    </Fragment>
  );
};
