import { FC, useCallback } from "react";
import { ListItemButton, Stack, Typography } from "@mui/material";
import FolderOpenIcon from "@mui/icons-material/FolderOpen";

interface ResourceExplorerBreadcrumbsMenuItemProps {
  name: string;
  onClick(index: number): void;
  index: number;
}
export const ResourceExplorerBreadcrumbsMenuItem: FC<
  ResourceExplorerBreadcrumbsMenuItemProps
> = ({ name, onClick, index }) => {
  const onClickHandler = useCallback(() => {
    onClick(index);
  }, [onClick, index]);
  return (
    <ListItemButton onClick={onClickHandler}>
      <Stack direction="row" gap={1} alignItems="center">
        <FolderOpenIcon color="action" />
        <Typography variant="body2">{name}</Typography>
      </Stack>
    </ListItemButton>
  );
};
