import { taskManagementTargetResourcesSelector } from "./taskManagementTargetResourcesSelector";
import { RootState } from "../../../../../store";

export const taskManagementTargetResourcesTitlesSelector = (
  state: RootState,
): string[] | undefined => {
  const resources = taskManagementTargetResourcesSelector(state);

  return resources?.map((resource) => resource?.provider_identifier ?? "");
};
