import { cloudChiprApi } from "../../../../services/cloudchipr.api";
import { RootState } from "../../../store";
import { editCostAnomalyAlertFixedCacheKey } from "../../utils/constants/fixedCacheKeys";

const endpoint =
  cloudChiprApi.endpoints.patchUsersMeOrganisationsCurrentAlertsCostAnomalyByAlertId.select(
    editCostAnomalyAlertFixedCacheKey,
  );

export const editCostAnomalyLoadingSelector = (state: RootState) => {
  return endpoint(state)?.isLoading;
};
