import { rightsizingRecommendationsDataSelector } from "./rightsizingRecommendationsDataSelector";
import { rightsizingRecommendationsDismissedDataSelector } from "./rightsizingRecommendationsDismissedDataSelector";
import { RootState } from "../../../../store";
import { RecommendationsTabType } from "../../../../../components/pages/recommendations/components/off-hours-recommendations/components/OffHoursRecommendationsContent";
import {
  GetUsersMeOrganisationsCurrentDismissedResourcesApiResponse,
  RightSizingRecommendation,
} from "../../../../../services/cloudchipr.api";
import { rightsizingRecommendationsDismissedResourcesDataSelector } from "../dismissed-resources/rightsizingRecommendationsDismissedResourcesDataSelector";

export const rightsizingRecommendationsDataByTabSelector = (
  state: RootState,
  tab: RecommendationsTabType,
):
  | RightSizingRecommendation[]
  | GetUsersMeOrganisationsCurrentDismissedResourcesApiResponse => {
  if (tab === "active") {
    return rightsizingRecommendationsDataSelector(state) ?? [];
  }
  if (tab === "dismissed") {
    return rightsizingRecommendationsDismissedDataSelector(state) ?? [];
  }
  return rightsizingRecommendationsDismissedResourcesDataSelector(state) ?? [];
};
