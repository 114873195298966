import { FC } from "react";
import { Button, Stack, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import EmptyDashboardImage from "../../../../../assets/images/empty-dashboard.png";
import { useDialog } from "../../../../../utils/hooks/useDialog.hook";
import { useAppAbility } from "../../../../../services/permissions";
import { DashboardCreateFolderOrItemDialogV2 } from "../../../../navigation/components/dashboard-v2/components/create-folder-or-item-dialog-v2/DashboardCreateFolderOrItemDialogV2";
export const EmptyDashboards: FC = () => {
  const { cannot } = useAppAbility();
  const { open, openDialog, closeDialog } = useDialog();
  const canNotCreateDashboard = cannot("create", "dashboard");

  return (
    <Stack alignItems="center" gap={2} mt={6}>
      <img src={EmptyDashboardImage} alt="EmptyDashboard" />

      <Typography variant="h5" fontWeight="medium">
        Dashboards
      </Typography>

      <Typography variant="subtitle1" color="text.secondary">
        Create your first dashboard and share it with others.
      </Typography>

      <Button
        disabled={canNotCreateDashboard}
        variant="contained"
        startIcon={<AddIcon />}
        onClick={openDialog}
      >
        Add Dashboard
      </Button>
      {open && (
        <DashboardCreateFolderOrItemDialogV2
          type="item"
          visibility="visible_to_everyone"
          onClose={closeDialog}
        />
      )}
    </Stack>
  );
};
