import { ResourceType } from "../../../services/cloudchipr.api";
import {
  resourceTypeNamesData,
  resourceTypeServiceNamesData,
} from "../../constants/resources/resourceTypeNames/resourceTypeNames";

export const getResourceTypeName = (
  resourceType: ResourceType,
  options?: {
    service?: boolean;
    singular?: boolean;
    type?: "short" | "long" | "abbreviation";
  },
) => {
  const type = options?.type ?? "short";

  const data = options?.service
    ? resourceTypeServiceNamesData[type].get(resourceType)
    : resourceTypeNamesData[type].get(resourceType);

  if (!data) {
    return resourceType;
  }

  if (options?.singular) {
    return data?.singular;
  }

  return data?.plural;
};
