import { Stack } from "@mui/material";
import { FC, ReactNode } from "react";
import { CoverageDataGridGrouping } from "./CoverageDataGridGrouping";
import { ColumnsShowHideSwitcher } from "./ColumnsShowHideSwitcher";
import { CommitmentsCSVDownloader } from "./CommitmentsCSVDownloader";
import { commitmentsDataViewTabSelector } from "../../../../../../store/commitments/selectors/common/view-options/commitmentsDataViewTabSelector";
import { useAppSelector } from "../../../../../../store/hooks";
import { GlobalFilter } from "../../../../../../storybook/data-grid/toolbar/GlobalFilter";
import { RenderToolbarProps } from "../../../../../../storybook/data-grid/utils/types/prop-types";

export const CommitmentsDataGridToolbar: FC<
  RenderToolbarProps & { children?: ReactNode }
> = ({ setGlobalFilter, globalFilter, table, children }) => {
  const tab = useAppSelector(commitmentsDataViewTabSelector);
  const isCoverageTab = tab === "coverage";

  return (
    <Stack
      pb={2}
      flexDirection={!isCoverageTab ? "row" : "column"}
      justifyContent="space-between"
    >
      {children}
      <Stack
        direction="row"
        spacing={2}
        pt={isCoverageTab ? 2 : 0}
        alignItems="flex-end"
      >
        {tab === "coverage" && <CoverageDataGridGrouping />}
        <Stack
          flex={1}
          spacing={1}
          direction="row"
          justifyContent="flex-end"
          alignContent="center"
          alignItems="center"
        >
          <GlobalFilter
            globalFilter={globalFilter}
            setGlobalFilter={setGlobalFilter}
            size="xsmall"
          />
          <CommitmentsCSVDownloader />
          <ColumnsShowHideSwitcher table={table} />
        </Stack>
      </Stack>
    </Stack>
  );
};
