import { FC } from "react";
import { Stack } from "@mui/material";
import { UserRoleEditMetaData } from "./UserRoleEditMetaData";
import { UserRoleEditFooter } from "./UserRoleEditFooter";
import { UserRoleEditableMetaData } from "../../common/UserRoleEditableMetaData";
import { UserRoleGroupedPermissions } from "../../common/UserRoleGroupedPermissions";

export const UserRoleEditContent: FC = () => {
  return (
    <Stack spacing={3}>
      <UserRoleEditableMetaData />
      <UserRoleEditMetaData />
      <UserRoleGroupedPermissions />
      <UserRoleEditFooter />
    </Stack>
  );
};
