import moment from "moment";
import { commitmentsDataViewTabSelector } from "./view-options/commitmentsDataViewTabSelector";
import { RootState } from "../../../store";
import { formatDate } from "../../../../utils/helpers/date-time/datetime-format";

export const commitmentsCSVFileNameSelector = (state: RootState) => {
  const tab = commitmentsDataViewTabSelector(state);

  if (tab === "coverage") {
    return `${formatDate(moment(), { type: "dateWithComma" })}_Coverage_CSV`;
  }

  if (tab === "commitment") {
    return `${formatDate(moment(), { type: "dateWithComma" })}_Commitments_CSV`;
  }

  return "";
};
