import { orgCurrentFilterTemplatesSelector } from "./orgCurrentFilterTemplatesSelector";
import { RootState } from "../../../store";
import { FilterSet, ProviderType } from "../../../../services/cloudchipr.api";

export const orgCurrentFilterTemplatesGroupedByProviderSelector = (
  state: RootState,
) => {
  const filterTemplates = orgCurrentFilterTemplatesSelector(state);

  return filterTemplates?.reduce(
    (result, item) => {
      result[item.cloud_provider] = [
        ...(result[item.cloud_provider] ?? []),
        item,
      ];
      return result;
    },
    {} as Record<ProviderType, FilterSet[]>,
  );
};
