import { FC } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { CategoriesListRow } from "./CategoriesListRow";
import { useAppSelector } from "../../../../../store/hooks";
import { groupedCategoriesSelector } from "../../../../../store/categories/selectors/groupedCategoriesSelector";

export const CategoriesGridList: FC = () => {
  const groupedCategories = useAppSelector(groupedCategoriesSelector);

  return (
    <TableContainer>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell />

            <TableCell>
              <Typography
                variant="subtitle2"
                color="text.disabled"
                fontWeight="medium"
                py={1}
              >
                Name
              </Typography>
            </TableCell>

            <TableCell>
              <Typography
                variant="subtitle2"
                color="text.disabled"
                fontWeight="medium"
                align="right"
                py={1}
              >
                Total Cost (MTD)
              </Typography>
            </TableCell>

            <TableCell sx={{ whiteSpace: "nowrap" }}>
              <Typography
                variant="subtitle2"
                color="text.disabled"
                fontWeight="medium"
                align="right"
                py={1}
              >
                Filtered Resources
              </Typography>
            </TableCell>

            <TableCell sx={{ whiteSpace: "nowrap" }}>
              <Typography
                variant="subtitle2"
                color="text.disabled"
                fontWeight="medium"
                py={1}
                minWidth={200}
              >
                Accounts
              </Typography>
            </TableCell>

            <TableCell />
          </TableRow>
        </TableHead>

        <TableBody>
          {Object.keys(groupedCategories).map((name) => (
            <CategoriesListRow
              key={name}
              name={name}
              categories={groupedCategories[name]?.categories ?? []}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
