import { RootState } from "../../../store";
import { cloudChiprApi } from "../../../../services/cloudchipr.api";
import { deleteUtilizationAlertFixedCacheKey } from "../../utils/constants/fixedCacheKeys";

const endpoint =
  cloudChiprApi.endpoints.deleteUsersMeOrganisationsCurrentAlertsUtilizationAndCoverageByAlertId.select(
    deleteUtilizationAlertFixedCacheKey,
  );

export const deleteUtilizationAlertLoadingSelector = (state: RootState) => {
  return endpoint(state)?.isLoading;
};
