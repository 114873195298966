function C8RSvgIcon() {
  return (
    <svg
      width="39"
      height="22"
      viewBox="0 0 39 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.57793 21.6539C6.49973 21.6536 5.43399 21.4233 4.45187 20.9784C3.46974 20.5335 2.59385 19.8842 1.88266 19.0738C1.17148 18.2634 0.641375 17.3106 0.327747 16.279C0.0141199 15.2474 -0.0758135 14.1608 0.0639542 13.0917C0.203722 12.0226 0.569973 10.9956 1.13825 10.0793C1.70653 9.16303 2.46376 8.37854 3.35937 7.77821C4.25498 7.17788 5.26837 6.77553 6.33186 6.59803C7.39536 6.42054 8.48448 6.47199 9.52651 6.74894L8.62488 10.1176C8.28355 10.0258 7.93141 9.98021 7.57793 9.98224C6.90421 9.9819 6.24081 10.1478 5.64653 10.4652C5.05226 10.7826 4.54547 11.2417 4.17109 11.8018C3.7967 12.3619 3.56629 13.0058 3.50028 13.6763C3.43426 14.3467 3.53468 15.0232 3.79264 15.6455C4.05059 16.2679 4.45811 16.8171 4.97908 17.2443C5.50004 17.6715 6.11835 17.9635 6.7792 18.0946C7.44006 18.2257 8.12304 18.1917 8.7676 17.9956C9.41217 17.7996 9.99842 17.4475 10.4744 16.9707L12.9382 19.4345C12.2355 20.14 11.4001 20.6994 10.48 21.0803C9.56004 21.4613 8.57368 21.6562 7.57793 21.6539Z"
        fill="#E0BD2B"
      />
      <path
        d="M30.7599 21.6539V18.1695C31.4339 18.171 32.0978 18.006 32.6928 17.6893C33.2877 17.3726 33.7953 16.914 34.1706 16.3541C34.5458 15.7942 34.777 15.1503 34.8437 14.4796C34.9104 13.8089 34.8106 13.1322 34.553 12.5093C34.2955 11.8865 33.8882 11.3368 33.3672 10.9091C32.8463 10.4814 32.2279 10.1889 31.5668 10.0575C30.9058 9.92613 30.2225 9.95994 29.5776 10.1559C28.9327 10.3519 28.3462 10.7041 27.87 11.1811L25.4062 8.71733C26.2874 7.83532 27.3725 7.18428 28.5654 6.82189C29.7582 6.45951 31.0221 6.39697 32.245 6.63982C33.4678 6.88266 34.6118 7.42341 35.5757 8.21411C36.5396 9.00482 37.2936 10.0211 37.7708 11.1729C38.2481 12.3246 38.4338 13.5763 38.3117 14.817C38.1895 16.0578 37.7631 17.2492 37.0704 18.2857C36.3777 19.3223 35.44 20.172 34.3404 20.7595C33.2408 21.347 32.0132 21.6542 30.7665 21.6539H30.7599Z"
        fill="#CC2323"
      />
      <path
        d="M17.8405 21.6523C16.0616 21.65 14.3106 21.2093 12.7424 20.3693C11.1743 19.5293 9.83729 18.3158 8.84972 16.8361C7.86215 15.3565 7.25443 13.6562 7.08031 11.8858C6.90618 10.1154 7.17103 8.32931 7.85141 6.68559C8.53179 5.04187 9.60674 3.59115 10.9812 2.46172C12.3556 1.3323 13.9872 0.558976 15.7317 0.210157C17.4761 -0.138661 19.2796 -0.0522304 20.9827 0.461808C22.6858 0.975847 24.2359 1.90165 25.4961 3.15734L23.0323 5.62113C22.0055 4.59446 20.6973 3.89531 19.2732 3.6121C17.849 3.32889 16.3729 3.47434 15.0314 4.03005C13.6899 4.58577 12.5433 5.52678 11.7366 6.73412C10.9299 7.94146 10.4994 9.36089 10.4994 10.8129C10.4994 12.265 10.9299 13.6844 11.7366 14.8917C12.5433 16.0991 13.6899 17.0401 15.0314 17.5958C16.3729 18.1515 17.849 18.297 19.2732 18.0137C20.6973 17.7305 22.0055 17.0314 23.0323 16.0047L25.4961 18.4685C24.4942 19.4794 23.3016 20.2814 21.9875 20.8279C20.6733 21.3744 19.2638 21.6546 17.8405 21.6523Z"
        fill="url(#paint0_linear_6196_17300)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_6196_17300"
          x1="7.01439"
          y1="10.8261"
          x2="25.4961"
          y2="10.8261"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#3F51B5" />
          <stop offset="1" stopColor="#33417C" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default C8RSvgIcon;
