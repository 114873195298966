import { FC, Fragment } from "react";
import {
  Chip,
  Link,
  Popover,
  Stack,
  Table,
  TableBody,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import TableCell from "@mui/material/TableCell";
import { blue, green } from "@mui/material/colors";
import { useMenuHook } from "../../../../../../../../../../utils/hooks/useMenu.hook";
import { ResourceIssues } from "../../../../../../../../../../services/cloudchipr.api";

const defaultColors = {
  primary: "grey.800",
  secondary: "grey.100",
};

const ticketColors = new Map([
  ["yellow", { primary: blue[800], secondary: blue[50] }],
  ["green", { primary: green[800], secondary: green[50] }],
  ["blue-gray", defaultColors],
]);

interface JiraTicketStatusCellProps {
  tickets: ResourceIssues;
}

export const JiraTicketStatusCell: FC<JiraTicketStatusCellProps> = ({
  tickets,
}) => {
  const { anchor, open, openMenu, closeMenu } = useMenuHook();

  if (tickets?.length === 0) {
    return null;
  }

  const firstTicket = tickets?.[0];
  const firstTicketColors =
    ticketColors.get(firstTicket?.status?.color_name) ?? defaultColors;

  if (!firstTicket) {
    return null;
  }

  return (
    <Stack direction="row" alignItems="center">
      <Typography
        px={0.5}
        borderRadius={1}
        whiteSpace="nowrap"
        variant="subtitle2"
        color={firstTicketColors.primary}
        bgcolor={firstTicketColors.secondary}
      >
        <Link href={firstTicket.link} target="_blank" color="inherit">
          {firstTicket.key}
        </Link>{" "}
        | {firstTicket.status.name.toUpperCase()}
      </Typography>

      {tickets.length > 1 && (
        <Fragment>
          <Chip
            label={`+${tickets.length - 1}`}
            onClick={openMenu}
            variant="outlined"
            size="small"
            sx={{ ml: 0.5 }}
          />

          {open && (
            <Popover
              open={open}
              anchorEl={anchor}
              onClose={closeMenu}
              transformOrigin={{ horizontal: "right", vertical: "top" }}
              anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
            >
              <Table size="small" sx={{ my: 1 }}>
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ width: 150, borderColor: "grey.100" }}>
                      Ticket ID
                    </TableCell>
                    <TableCell sx={{ width: 250, borderColor: "grey.100" }}>
                      Status
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {tickets.map((ticket) => {
                    const colors =
                      ticketColors.get(ticket.status.color_name) ??
                      defaultColors;

                    return (
                      <TableRow key={ticket.id}>
                        <TableCell sx={{ borderColor: "grey.100" }}>
                          <Link
                            href={ticket.link}
                            target="_blank"
                            color="text.secondary"
                            variant="subtitle2"
                          >
                            {ticket.key}
                          </Link>
                        </TableCell>

                        <TableCell sx={{ borderColor: "grey.100" }}>
                          <Typography
                            px={0.5}
                            borderRadius={1}
                            variant="subtitle2"
                            display="inline-block"
                            color={colors.primary}
                            bgcolor={colors.secondary}
                          >
                            {ticket.status.name.toUpperCase()}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </Popover>
          )}
        </Fragment>
      )}
    </Stack>
  );
};
