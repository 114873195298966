import { FC, useMemo } from "react";
import { TextField } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { FilterTriggerComponentProps } from "../../../../../../../common/select/dropdown-select/utils/types/filterTriggerComponentProps";

export const CommitmentsFilterSelectTriggerComponent: FC<
  FilterTriggerComponentProps
> = ({ onClick, selectedValues, options, open, label }) => {
  const optionsMap = useMemo(() => {
    return new Map(options.map(({ value, label }) => [value, label]));
  }, [options]);

  const renderValue = useMemo(() => {
    const selectedLabels = selectedValues.map((value) => optionsMap.get(value));
    if (
      selectedValues.length === 0 ||
      optionsMap.size === selectedValues.length
    ) {
      return "All Selected";
    }
    return selectedLabels.map((item) => item).join(", ");
  }, [selectedValues, optionsMap]);

  return (
    <TextField
      size="xsmall"
      label={label}
      onClick={onClick}
      contentEditable={false}
      value={renderValue}
      InputProps={{
        inputProps: {
          style: {
            cursor: "pointer",
            caretColor: "transparent",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          },
        },
        endAdornment: open ? (
          <ArrowDropUpIcon
            sx={{
              color: "action",
            }}
          />
        ) : (
          <ArrowDropDownIcon
            sx={{
              color: "action",
            }}
          />
        ),
      }}
    />
  );
};
