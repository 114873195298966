import { commitmentsDetailsDrawerDetailsSelector } from "./commitmentsDetailsDrawerDetailsSelector";
import { RootState } from "../../../../store";

export const commitmentsDetailsDrawerTargetTypeSelector = (
  state: RootState,
) => {
  const details = commitmentsDetailsDrawerDetailsSelector(state);

  return details.targetType;
};
