import { FC } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDidMount } from "rooks";
import { AccountId, ProviderType } from "../../../services/cloudchipr.api";
import { generateAccountIdsQueryParams } from "../live-usage-mgmt/utils/helpers/generateAccountIdsQueryParams";

interface AccountV2Props {
  provider: ProviderType;
}

export const AccountV2: FC<AccountV2Props> = ({ provider }) => {
  const { accountId } = useParams<{
    accountId: AccountId;
  }>();

  const navigate = useNavigate();

  useDidMount(() => {
    if (accountId) {
      navigate(
        `/${provider}/live-usage/${generateAccountIdsQueryParams([accountId])}`,
      );
    }
  });

  return null;
};
