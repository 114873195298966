import { liveUsageMgmtResourceTypeDataSelector } from "./liveUsageMgmtResourceTypeDataSelector";
import { RootState } from "../../../store";
import { ResourceType } from "../../../../services/cloudchipr.api";
import { liveUsagePageStateSelector } from "../store-selectors/liveUsagePageStateSelector";

export const liveUsageMgmtResourceTypeDataEmsSelector = (
  state: RootState,
  resourceType: ResourceType,
) => {
  const pageState = liveUsagePageStateSelector(state);

  if (pageState === "pending") {
    return 0;
  }

  return (
    liveUsageMgmtResourceTypeDataSelector(state, resourceType)
      ?.estimated_monthly_waste ?? 0
  );
};
