import { FC } from "react";
import { Divider, IconButton, Stack } from "@mui/material";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import { useAppSelector } from "../../../../../../store/hooks";
import { isReportEditModeSelector } from "../../../../../../store/reports/selector/isReportEditModeSelector";

interface ReportsDrawerHeaderProps {
  onClose(): void;
}

export const ReportsDrawerHeader: FC<ReportsDrawerHeaderProps> = ({
  onClose,
}) => {
  const editMode = useAppSelector(isReportEditModeSelector);

  return (
    <Stack
      top={0}
      position="sticky"
      bgcolor="white"
      zIndex={(theme) => theme.zIndex.speedDial}
    >
      <Stack
        py={1}
        px={2}
        direction="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <Typography variant="body1" fontWeight="medium">
          {editMode ? "Edit Subscription" : "Subscribe"}
        </Typography>
        <IconButton size="small" onClick={onClose}>
          <CloseIcon fontSize="small" />
        </IconButton>
      </Stack>
      <Divider />
    </Stack>
  );
};
