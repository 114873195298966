import { liveUsageMgmtResourceTypesHierarchySelector } from "./liveUsageMgmtResourceTypesHierarchySelector";
import { RootState } from "../../../store";
import { ResourceType } from "../../../../services/cloudchipr.api";

export const liveUsageMgmtSelectedRowIdsWithHierarchyByResourceTypeSelector = (
  state: RootState,
  resourceType?: ResourceType,
) => {
  if (!resourceType) {
    return;
  }

  const resourceTypesHierarchy = liveUsageMgmtResourceTypesHierarchySelector(
    state,
    resourceType,
  );

  const selectedItems = resourceTypesHierarchy.reduce(
    (result, item) => {
      const data = Object.values(state.liveUsageMgmt.selected[item] ?? {});
      result = result.concat(data);

      return result;
    },
    [] as Record<string, boolean>[],
  );

  return selectedItems.reduce(
    (result, item) => {
      result = {
        ...result,
        ...item,
      };
      return result;
    },
    {} as Record<string, boolean>,
  );
};
