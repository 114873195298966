import { FC } from "react";
import { Divider, Stack, Typography } from "@mui/material";
import { useAppSelector } from "../../../../../../../../../../store/hooks";
import { largestCostChangesSetupPropertyByKeySelector } from "../../../../../../../../../../store/dashboards/selectors/setups/larges-cost-changes/largestCostChangesSetupPropertyByKeySelector";
import { LargestCostChangesWidgetHeaderCardSourceData } from "../../../../../../widgets/largest-cost-change/components/header/LargestCostChangesWidgetHeaderCardSourceData";
import { largestCostChangesSetupWidgetTitleSelector } from "../../../../../../../../../../store/dashboards/selectors/setups/larges-cost-changes/largestCostChangesSetupWidgetTitleSelector";
import { largestCostChangesSetupPrevPeriodCostDateDetailsSelector } from "../../../../../../../../../../store/dashboards/selectors/setups/larges-cost-changes/data/largestCostChangesSetupPrevPeriodCostDateDetailsSelector";

export const LargestCostChangesWidgetSetupHeader: FC = () => {
  const name = useAppSelector(
    largestCostChangesSetupPropertyByKeySelector("name"),
  );

  const date = useAppSelector(
    largestCostChangesSetupPropertyByKeySelector("dates"),
  );

  const prevDateDetails = useAppSelector(
    largestCostChangesSetupPrevPeriodCostDateDetailsSelector,
  );

  const widgetTitleProps = useAppSelector(
    largestCostChangesSetupWidgetTitleSelector,
  );

  return (
    <Stack direction="row">
      <Stack px={2} py={1}>
        <Typography variant="body1" fontWeight="bold" minHeight={24}>
          {name}
        </Typography>

        <Stack direction="row" alignItems="center" spacing={0.5}>
          <LargestCostChangesWidgetHeaderCardSourceData
            date={date}
            prevDate={prevDateDetails}
            {...widgetTitleProps}
          />
        </Stack>
      </Stack>
      <Divider />
    </Stack>
  );
};
