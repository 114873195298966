import { FC } from "react";
import { CustomCellRendererProps } from "@ag-grid-community/react";
import { Stack } from "@mui/material";
import { Role } from "../../../../../../../services/cloudchipr.api";
import { TypographyWithTooltip } from "../../../../../../common/TypographyWithTooltip";
import { formatDate } from "../../../../../../../utils/helpers/date-time/datetime-format";

export const RolesGridCreatedDateCell: FC<CustomCellRendererProps<Role>> = ({
  data: role,
}) => {
  if (!role) {
    return null;
  }

  const createdAt = role?.created_at;

  if (!createdAt) {
    return <div>-</div>;
  }

  return (
    <Stack direction="row" spacing={1} overflow="hidden">
      <TypographyWithTooltip
        title={formatDate(createdAt || "", { type: "date" })}
        variant="body2"
      />
    </Stack>
  );
};
