import { FC, useCallback, useState } from "react";
import {
  Box,
  CardContent,
  CardMedia,
  Chip,
  Dialog,
  Typography,
} from "@mui/material";
import ZoomInMapIcon from "@mui/icons-material/ZoomInMap";
import ZoomOutMapIcon from "@mui/icons-material/ZoomOutMap";
import { green } from "@mui/material/colors";
import CloudChiprLogo from "../../../assets/images/cloudchipr.png";
import { VideoWithoutCaptions } from "../../common/VideoWithoutCaptions";

export interface FeatureUpdateProps {
  title: string;
  label?: string;
  description: string;
  media?: string;
  mediaType?: "video" | "image";
}

export const FeatureCardContent: FC<FeatureUpdateProps> = ({
  title,
  description,
  label,
  media,
  mediaType,
}) => {
  const [open, setOpen] = useState(false);

  const handleToggle = useCallback(() => {
    setOpen(!open);
  }, [open]);

  return (
    <CardContent>
      {media && (
        <Box
          onClick={handleToggle}
          sx={{
            cursor: "pointer",
          }}
        >
          {mediaType === "video" ? (
            <CardMedia
              component="video"
              src={media}
              height={140}
              autoPlay
              loop
              muted
            />
          ) : (
            <CardMedia component="img" src={media} alt={title} height={140} />
          )}
          <ZoomOutMapIcon
            color="action"
            sx={{
              position: "absolute",
              right: 10,
              top: 140,
            }}
          />
        </Box>
      )}
      {label && (
        <Chip
          label={label}
          color="success"
          variant="outlined"
          size="small"
          sx={{ mt: 2, border: "none", bgcolor: green[50] }}
        />
      )}
      <Typography variant="subtitle2" my={1}>
        <Box mr={1} component="span">
          <img src={CloudChiprLogo} alt="Cloudchipr" width={20} />
        </Box>
        {title}
      </Typography>
      <Typography variant="body2" color="text.secondary">
        {description}
      </Typography>
      {media && (
        <Dialog fullWidth maxWidth="md" open={open} onClose={handleToggle}>
          {mediaType === "video" ? (
            <VideoWithoutCaptions src={media} autoPlay loop height={500} />
          ) : (
            <img src={media} alt={title} height={500} />
          )}

          <ZoomInMapIcon
            color="action"
            onClick={handleToggle}
            sx={{
              cursor: "pointer",
              position: "absolute",
              right: 10,
              bottom: 10,
            }}
          />
        </Dialog>
      )}
    </CardContent>
  );
};
