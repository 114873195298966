import { createDraftSafeSelector } from "@reduxjs/toolkit";
import { recommendationsDataGridDataSelector } from "./recommendationsDataGridDataSelector";
import { RecommendationDataType } from "../../../../../components/pages/recommendations/components/off-hours-recommendations/utils/types/types";
import { recommendationAppliedProvidersSelector } from "../../filters/recommendationAppliedProvidersSelector";
import { recommendationAppliedTypesSelector } from "../../filters/recommendationAppliedTypesSelector";
import { recommendationAppliedAccountsSelector } from "../../filters/recommendationAppliedAccountsSelector";
import { recommendationAppliedTagsSetSelector } from "../../filters/recommendationAppliedTagsSetSelector";
import { recommendationsDataByFiltersFilterCombiner } from "../../../combiners/off-hours/recommendationsDataByFiltersFilterCombiner";

export const recommendationsDataGridFilteredDataSelector =
  createDraftSafeSelector(
    [
      recommendationsDataGridDataSelector,
      recommendationAppliedProvidersSelector,
      recommendationAppliedTypesSelector,
      recommendationAppliedAccountsSelector,
      recommendationAppliedTagsSetSelector,
    ],
    (data, providers, types, accounts, tags): RecommendationDataType[] => {
      return recommendationsDataByFiltersFilterCombiner(
        data,
        providers,
        types,
        accounts,
        tags,
      );
    },
  );
