import { Stack } from "@mui/material";
import { GcpAddBillingExportButton } from "./GcpAddBillingExportButton";
import { AccountAddButton } from "../AccountAddButton";

export const GcpAccountsHeaderActionButtons = () => {
  return (
    <Stack direction="row" spacing={1}>
      <GcpAddBillingExportButton />
      <AccountAddButton provider="gcp" />
    </Stack>
  );
};
