import { FC, Fragment, ReactElement, useCallback, useState } from "react";
import { Chip, Stack, Theme } from "@mui/material";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { ResourceExplorerBillingStatusesDrawer } from "./components/drawer/ResourceExplorerBillingStatusesDrawer";
import { ResourceExplorerStatusTypes } from "./utils/types/resourceExplorerStatusTypes";
import { useAppSelector } from "../../../../../../../store/hooks";
import { billingErrorsCountAllProvidersSelector } from "../../../../../../../store/common/selectors/billing-status/error/billingErrorsCountAllProvidersSelector";
import { billingWarningsCountAllProvidersSelector } from "../../../../../../../store/common/selectors/billing-status/warning/billingWarningsCountAllProvidersSelector";
import { billingInProgressCountAllProvidersSelector } from "../../../../../../../store/common/selectors/billing-status/in-progress/billingInProgressCountAllProvidersSelector";
import { useDialog } from "../../../../../../../utils/hooks/useDialog.hook";
import { isBillingAvailableSelector } from "../../../../../../../store/common/selectors/billing-status/isBillingAvailableSelector";
export const ResourceExplorerBillingStatuses: FC = () => {
  const { open: drawerOpen, openDialog, closeDialog } = useDialog();
  const [resourceExplorerStatus, setResourceExplorerStatus] =
    useState<ResourceExplorerStatusTypes>("error");

  const billingErrorsCount = useAppSelector(
    billingErrorsCountAllProvidersSelector,
  );
  const billingWarningsCount = useAppSelector(
    billingWarningsCountAllProvidersSelector,
  );
  const billingInProgressCount = useAppSelector(
    billingInProgressCountAllProvidersSelector,
  );

  const isResourceExplorerAvailable = useAppSelector(
    isBillingAvailableSelector,
  );

  const chipClickHandler = useCallback(
    (type: ResourceExplorerStatusTypes) => {
      setResourceExplorerStatus(type);
      openDialog();
    },
    [openDialog],
  );
  const closeDrawerHandler = useCallback(() => {
    closeDialog();
  }, [closeDialog]);

  if (
    (!billingErrorsCount && !billingInProgressCount && !billingWarningsCount) ||
    !isResourceExplorerAvailable
  ) {
    return null;
  }

  return (
    <Fragment>
      <Stack
        direction="row"
        bgcolor="grey.200"
        px={0.5}
        borderRadius={20}
        spacing={1}
        alignItems="center"
      >
        {!!billingErrorsCount && (
          <ResourceExplorerBillingStatusesChip
            onClick={chipClickHandler}
            label={billingErrorsCount}
            status="error"
          />
        )}
        {!!billingWarningsCount && (
          <ResourceExplorerBillingStatusesChip
            onClick={chipClickHandler}
            label={billingWarningsCount}
            status="warning"
          />
        )}
        {!!billingInProgressCount && (
          <ResourceExplorerBillingStatusesChip
            onClick={chipClickHandler}
            label={billingInProgressCount}
            status="inProgress"
          />
        )}
      </Stack>

      <ResourceExplorerBillingStatusesDrawer
        open={drawerOpen}
        onCloseDrawer={closeDrawerHandler}
        status={resourceExplorerStatus}
      />
    </Fragment>
  );
};

interface ResourceExplorerBillingStatusesChipProps {
  status: ResourceExplorerStatusTypes;
  onClick(type: ResourceExplorerStatusTypes): void;
  label: number;
}

const ResourceExplorerBillingStatusesChip: FC<
  ResourceExplorerBillingStatusesChipProps
> = ({ status, onClick, label }) => {
  const clickHandler = useCallback(() => {
    onClick(status);
  }, [status, onClick]);

  return (
    <Chip
      icon={billingStatusIcons[status]}
      label={label}
      variant="outlined"
      sx={{
        "& .MuiChip-icon": {
          color: (theme) => getColor(theme, status),
        },
      }}
      onClick={clickHandler}
    />
  );
};

const billingStatusIcons: Record<ResourceExplorerStatusTypes, ReactElement> = {
  error: <ErrorOutlineIcon />,
  warning: <WarningAmberIcon />,
  inProgress: <InfoOutlinedIcon />,
};

const getColor = (theme: Theme, type: ResourceExplorerStatusTypes) => {
  const data = {
    error: theme.palette.error.main,
    warning: theme.palette.warning.main,
    inProgress: theme.palette.primary.main,
  };

  return data[type];
};
