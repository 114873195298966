import { FC, useCallback } from "react";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { IconButton } from "@mui/material";
import { FormikHelpers } from "formik";
import {
  FilterGroups,
  FilterOperators,
  FiltersInitialValues,
} from "../utils/types/types";
import { FilterGroup } from "../../../../../../services/cloudchipr.api";

interface FilterDialogListItemRemoveButtonProps {
  index: number;
  setValues: FormikHelpers<FiltersInitialValues>["setValues"];
}

export const FilterDialogListItemRemoveButton: FC<
  FilterDialogListItemRemoveButtonProps
> = ({ index, setValues }) => {
  const removeHandler = useCallback(() => {
    setValues((values) => {
      const filterItems = values.filter_items.filter((_, i) => i !== index);
      let filterGroups = [...values.filter_groups];

      if (filterItems.length === 0) {
        return {
          ...values,
          operators: [],
          filter_items: [],
          filter_groups: [],
        };
      }

      filterGroups = recursiveRemove(filterGroups, index);

      const { normalizedFilterGroups, operators } = filterGroupsNormalizer(
        filterGroups,
        values.operators,
      );

      return {
        ...values,
        operators,
        filter_items: filterItems,
        filter_groups: normalizedFilterGroups,
      };
    });
  }, [index, setValues]);

  return (
    <IconButton onClick={removeHandler} size="small">
      <DeleteOutlineIcon />
    </IconButton>
  );
};

export const filterGroupsNormalizer = (
  filterGroups: FilterGroups,
  operators: FilterOperators,
) => {
  let index = 0;
  let operatorIndex = 0;
  const normalizeRecursive = (filterGroups: FilterGroup) => {
    return filterGroups.reduce((group, filterGroup) => {
      if (!Array.isArray(group)) {
        return group;
      }

      if (Array.isArray(filterGroup)) {
        operatorIndex++;

        if (filterGroup.length) {
          group.push(normalizeRecursive(filterGroup));
        } else {
          operators = operators.filter((_, i) => i !== operatorIndex);
        }
      } else {
        group.push(index++);
      }

      return group;
    }, []) as FilterGroups;
  };

  return {
    normalizedFilterGroups: normalizeRecursive(filterGroups),
    operators,
  };
};

const recursiveRemove = (filterGroups: FilterGroup, index: number) => {
  return filterGroups.reduce((group, filterGroup) => {
    if (Array.isArray(filterGroup) && Array.isArray(group)) {
      group.push(recursiveRemove(filterGroup, index));
    } else if (filterGroup !== index && Array.isArray(group)) {
      group.push(index);
    }

    return group;
  }, []) as FilterGroups;
};
