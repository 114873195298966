import { createAsyncThunk } from "@reduxjs/toolkit";
import { enqueueSnackbar } from "notistack";
import { taskManagementGetTasksThunk } from "./taskManagementGetTasksThunk";
import { cloudChiprApi } from "../../../../services/cloudchipr.api";
import { taskDeletionFixedCacheKey } from "../../utils/constants/fixedCacheKeys";

export const taskManagementDeleteTasksByIdsThunk = createAsyncThunk(
  "taskManagement/taskManagementDeleteTasksByIds",
  async (ids: string[], { dispatch }) => {
    const { putUsersMeOrganisationsCurrentTasks } = cloudChiprApi.endpoints;

    try {
      await dispatch(
        putUsersMeOrganisationsCurrentTasks.initiate(
          {
            body: { task_ids: ids },
          },
          { fixedCacheKey: taskDeletionFixedCacheKey },
        ),
      ).unwrap();

      await dispatch(taskManagementGetTasksThunk()).unwrap();

      enqueueSnackbar("Task successfully deleted.", {
        variant: "snackbarAlert",
        AlertSnackBarProps: { severity: "success" },
      });
    } catch (e) {
      // @ts-expect-error TODO: return to this after adding error type
      const errMessage = e?.data?.message || "Something went wrong";
      enqueueSnackbar(errMessage, {
        variant: "snackbarAlert",
        AlertSnackBarProps: { severity: "error" },
      });
    }
  },
);
