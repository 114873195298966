import { FC, Fragment } from "react";
import { Box, FormControlLabel, Radio, Typography } from "@mui/material";

interface GCPConnectionTypeOptionProps {
  value: string;
  title: string;
  description?: string;
  selected?: boolean;
}

export const GCPConnectionTypeOption: FC<GCPConnectionTypeOptionProps> = ({
  value,
  title,
  selected,
  description,
}) => {
  return (
    <Box
      sx={{
        border: 1,
        borderRadius: 1,
        borderColor: selected ? "primary.main" : "main",
        bgcolor: selected ? "primary.light" : "transparent",
        flexGrow: 1,
        alignItems: "flex-start",
        display: "flex",
        px: 2,
        py: 1,
      }}
    >
      <FormControlLabel
        sx={{ alignItems: "flex-start", flexGrow: 1 }}
        control={<Radio />}
        value={value}
        label={
          <Fragment>
            <Typography variant="subtitle1" fontWeight="medium">
              {title}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {description}
            </Typography>
          </Fragment>
        }
      />
    </Box>
  );
};
