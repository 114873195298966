export const generateUniqueNameBySuffix = (
  baseName: string,
  existingNames: Set<string>,
  suffix: string,
): string => {
  let counter = 0;
  let newName = baseName;

  while (existingNames.has(newName)) {
    counter += 1;
    newName = `${baseName} ${suffix.repeat(counter)}`;
  }

  return newName;
};
