import { FC } from "react";
import { Stack, Typography } from "@mui/material";
import { NotificationPreviewItem } from "./NotificationPreviewItem";
import { useAppSelector } from "../../../../../../../../../../store/hooks";
import { step1FormDataSelector } from "../../../../../../../../../../store/budgets/selectors/current-budget/forms/step1FormDataSelector";

export const EmailsPreview: FC = () => {
  const data = useAppSelector(step1FormDataSelector);
  const emails = data?.email_to;

  if (!emails) {
    return null;
  }

  return (
    <Stack gap={1}>
      Emails
      {emails?.to && (
        <Stack direction="row" alignItems="center">
          <Typography mr={1} variant="body2">
            To:
          </Typography>

          <NotificationPreviewItem
            firstItem={emails?.to?.at(0)}
            restItems={emails?.to?.slice(1)}
          />
        </Stack>
      )}
      {emails?.cc && (
        <Stack direction="row" alignItems="center">
          <Typography mr={1} variant="body2">
            Cc:
          </Typography>

          <NotificationPreviewItem
            firstItem={emails?.cc?.at(0)}
            restItems={emails?.cc?.slice(1)}
          />
        </Stack>
      )}
      {emails?.bcc && (
        <Stack direction="row" alignItems="center">
          <Typography mr={1} variant="body2">
            Bcc:
          </Typography>

          <NotificationPreviewItem
            firstItem={emails?.bcc?.at(0)}
            restItems={emails?.bcc?.slice(1)}
          />
        </Stack>
      )}
    </Stack>
  );
};
