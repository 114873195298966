import { FC } from "react";
import { Stack, Tooltip } from "@mui/material";
import ArchiveOutlinedIcon from "@mui/icons-material/ArchiveOutlined";
import { UseMenuHookOpenMenu } from "../../../../../../utils/hooks/useMenu.hook";

interface SingleDismissButtonProps {
  disabled: boolean;
  onClick: UseMenuHookOpenMenu;
}

export const SingleDismissButton: FC<SingleDismissButtonProps> = ({
  onClick,
  disabled,
}) => {
  const button = (
    <Stack
      border={1}
      width={30}
      height={30}
      borderRadius={1}
      onClick={disabled ? undefined : onClick}
      alignItems="center"
      justifyContent="center"
      sx={disabled ? undefined : { cursor: "pointer" }}
      borderColor={(theme) =>
        disabled ? theme.palette.action.disabled : theme.palette.primary.main
      }
    >
      <ArchiveOutlinedIcon
        color={disabled ? "disabled" : "primary"}
        fontSize="small"
      />
    </Stack>
  );

  if (disabled) {
    return button;
  }

  return (
    <Tooltip
      title="Dismiss"
      placement="top"
      PopperProps={{
        modifiers: [{ name: "offset", options: { offset: [0, -10] } }],
      }}
    >
      {button}
    </Tooltip>
  );
};
