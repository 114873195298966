import { useCallback, useMemo, useState } from "react";
import { Updater } from "@tanstack/react-table";
import { GlobalFilterConfig } from "../types/prop-types";

export function useColumnGlobalFilter(globalFilterConfig?: GlobalFilterConfig) {
  const [globalFilter, setGlobalFilter] = useState(
    typeof globalFilterConfig === "boolean"
      ? ""
      : (globalFilterConfig?.initialGlobalFilter ?? ""),
  );

  const globalFilterChangeHandler = useCallback(
    (updater: Updater<string>) => {
      if (typeof globalFilterConfig !== "boolean") {
        if (
          typeof updater === "function" &&
          globalFilterConfig?.onFilterChange
        ) {
          const updatedState = updater(globalFilter);
          globalFilterConfig.onFilterChange(updatedState);
        }
      }

      setGlobalFilter(updater);
    },
    [globalFilterConfig, globalFilter],
  );

  return useMemo(() => {
    return {
      globalFilter,
      globalFilterChangeHandler,
    };
  }, [globalFilter, globalFilterChangeHandler]);
}
