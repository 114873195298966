import { cloudChiprApi } from "../../../../services/cloudchipr.api";
import { RootState } from "../../../store";
import { editUtilizationAlertFixedCacheKey } from "../../utils/constants/fixedCacheKeys";

const endpoint =
  cloudChiprApi.endpoints.patchUsersMeOrganisationsCurrentAlertsUtilizationAndCoverageByAlertId.select(
    editUtilizationAlertFixedCacheKey,
  );

export const editUtilizationLoadingSelector = (state: RootState) => {
  return endpoint(state)?.isLoading;
};
