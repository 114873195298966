import { FC } from "react";
import { Stack, Typography } from "@mui/material";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { green, red } from "@mui/material/colors";
import { ResourceTarget } from "../../../../../../../../../../services/cloudchipr.api";
import { taskTargetStateLabels } from "../../../../../../../../task-management/utils/constants/labels";

interface TargetSingleResourceTableIStateRowProps {
  state: ResourceTarget["state"];
}

export const TargetSingleResourceTableIStateRow: FC<
  TargetSingleResourceTableIStateRowProps
> = ({ state }) => {
  const isLive = state === "live";

  return (
    <Stack
      px={0.5}
      py={0.25}
      spacing={0.5}
      direction="row"
      borderRadius={1}
      width="fit-content"
      alignItems="center"
      bgcolor={isLive ? green[50] : red[50]}
    >
      {isLive ? (
        <PlayCircleOutlineIcon color="success" sx={{ fontSize: 16 }} />
      ) : (
        <HighlightOffIcon color="error" sx={{ fontSize: 16 }} />
      )}

      <Typography
        variant="caption"
        fontWeight="medium"
        color={isLive ? "success.main" : "error.main"}
      >
        {taskTargetStateLabels[state]}
      </Typography>
    </Stack>
  );
};
