import { AlertTabType } from "../types/types";

export const createCostAnomalyAlertFixedCacheKey =
  "createCostAnomalyAlertFixedCacheKey";
export const createUtilizationAlertFixedCacheKey =
  "createUtilizationAlertFixedCacheKey";

export const editCostAnomalyAlertFixedCacheKey =
  "editCostAnomalyAlertFixedCacheKey";
export const editUtilizationAlertFixedCacheKey =
  "editUtilizationAlertFixedCacheKey";

export const deleteCostAnomalyAlertFixedCacheKey =
  "deleteCostAnomalyAlertFixedCacheKey";
export const deleteUtilizationAlertFixedCacheKey =
  "deleteUtilizationAlertFixedCacheKey";

export const generateToggleAlertActionFixedCacheKey = (
  id: string,
  alertsActiveTab: AlertTabType,
) => `generateToggleAlertActionFixedCacheKey-${id}-${alertsActiveTab}`;
