import { nextGroupingByCurrentGrouping } from "./nextGroupingByCurrentGrouping";
import {
  ResourceExplorerFilterProvider,
  ResourceExplorerGrouping,
} from "../../../../../../../../services/cloudchipr.api";

export const nextGroupingByCurrentGroupingAndProvider: Record<
  ResourceExplorerFilterProvider,
  Record<string, ResourceExplorerGrouping>
> = {
  aws: nextGroupingByCurrentGrouping,
  gcp: nextGroupingByCurrentGrouping,
  azure: nextGroupingByCurrentGrouping,
  confluent: nextGroupingByCurrentGrouping,
  dimensions: nextGroupingByCurrentGrouping,
  eks: { ...nextGroupingByCurrentGrouping, account: "cluster" },
  global: nextGroupingByCurrentGrouping,
};
