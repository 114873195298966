import { FC, Fragment, useCallback } from "react";
import { Typography } from "@mui/material";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../store/hooks";
import { automationFrequencySelector } from "../../../../../../../../store/automations/selectros/common/automationFrequencySelector";
import { setWorkflowSpecificData } from "../../../../../../../../store/automations/automationsSlice";
import { automationTimeZoneSelector } from "../../../../../../../../store/automations/selectros/common/fields/automationTimeZoneSelector";
import { AutomationDateTimePicker } from "../../../../../common/components/form/schedule-content/dates/AutomationDateTimePicker";
import { AutomationTimeZone } from "../../../../../common/components/form/schedule-content/AutomationTimeZone";
import { workflowSchedulerSelector } from "../../../../../../../../store/automations/selectros/workflow/workflowSchedulerSelector";
import { getDateNowByTimeZone } from "../../../../../../../../utils/helpers/date-time/datetime-format";

export const WorkflowOnceScheduler: FC = () => {
  const dispatch = useAppDispatch();

  const frequency = useAppSelector(automationFrequencySelector);
  const timeZone = useAppSelector(automationTimeZoneSelector);
  const scheduler = useAppSelector(workflowSchedulerSelector);

  const dateChangeHandler = useCallback(
    (runTime: string) => {
      dispatch(
        setWorkflowSpecificData({ scheduler: { ...scheduler, runTime } }),
      );
    },
    [dispatch, scheduler],
  );

  if (frequency !== "once") {
    return null;
  }

  return (
    <Fragment>
      <Typography variant="body2" color="text.secondary">
        Run
      </Typography>

      <AutomationDateTimePicker
        icon
        date={scheduler?.runTime ?? ""}
        onAccept={dateChangeHandler}
        minDate={getDateNowByTimeZone(timeZone)}
      />

      <AutomationTimeZone />
    </Fragment>
  );
};
