import { FC, useCallback } from "react";
import { useAppSelector } from "../../../../../../../store/hooks";
import { commitmentsDetailsDrawerDateGranularitySelector } from "../../../../../../../store/commitments/selectors/common/details-drawer/commitmentsDetailsDrawerDateGranularitySelector";
import { formatDateByFrequencyType } from "../../../../../../../utils/helpers/date-time/datetime-format";
import { CommitmentsChart } from "../../../../common/components/chart/CommitmentsChart";
import { reservationDetailsLoadingSelector } from "../../../../../../../store/commitments/selectors/reservations/details-drawer/data/reservationDetailsLoadingSelector";
import { reservationDetailsGranularDataGroupedByDateSelector } from "../../../../../../../store/commitments/selectors/reservations/details-drawer/reservationDetailsGranularDataGroupedByDateSelector";

interface ReservedInstanceDrawerChartProps {
  ChartHeader?: FC;
}

export const ReservedInstanceDrawerChart: FC<
  ReservedInstanceDrawerChartProps
> = ({ ChartHeader }) => {
  const dataGroupedByDate = useAppSelector(
    reservationDetailsGranularDataGroupedByDateSelector,
  );
  const dataPoint = useAppSelector(
    commitmentsDetailsDrawerDateGranularitySelector,
  );
  const loading = useAppSelector(reservationDetailsLoadingSelector);

  const labelFormatter = useCallback(
    (value: string) => formatDateByFrequencyType(dataPoint, value),
    [dataPoint],
  );

  return (
    <CommitmentsChart
      loading={loading}
      availableTypes={["area"]}
      labelFormatter={labelFormatter}
      dataGroupedByDate={dataGroupedByDate}
      ChartHeader={ChartHeader}
    />
  );
};
