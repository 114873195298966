import { FC, useCallback } from "react";
import {
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  TableCell,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { useNavigate } from "react-router-dom";
import { IntegrationRemoveAction } from "./IntegrationRemoveAction";
import { useMenuHook } from "../../../../../utils/hooks/useMenu.hook";
import { useAppAbility } from "../../../../../services/permissions";
import { Integration } from "../../../../../services/cloudchipr.api";

interface IntegrationsListActionCellProps {
  integrationId: string;
  type: Integration["type"];
  name: string;
}

export const IntegrationsListActionCell: FC<
  IntegrationsListActionCellProps
> = ({ integrationId, type, name }) => {
  const navigate = useNavigate();
  const { cannot } = useAppAbility();
  const canNotEditIntegration = cannot("edit", "integration");

  const { anchor, open, openMenu, closeMenu } = useMenuHook();

  const onEditHandler = useCallback(() => {
    navigate(`/integrations/edit/${type}/${integrationId}`);
  }, [navigate, type, integrationId]);

  return (
    <TableCell sx={{ textAlign: "right" }}>
      <IconButton onClick={openMenu}>
        <MoreVertIcon />
      </IconButton>

      <Menu anchorEl={anchor} open={open} onClose={closeMenu}>
        <MenuItem
          dense
          sx={{ minWidth: 180 }}
          onClick={onEditHandler}
          disabled={canNotEditIntegration}
        >
          <ListItemIcon>
            <EditOutlinedIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Edit</ListItemText>
        </MenuItem>

        <IntegrationRemoveAction
          name={name}
          integrationId={integrationId}
          closeMenu={closeMenu}
        />
      </Menu>
    </TableCell>
  );
};
