import { FC } from "react";
import { TrendChip } from "../../../../../../../../../../common/chips/TrendChip";
import { useAppSelector } from "../../../../../../../../../../../store/hooks";
import { alertsCostAnomalyDataTimeIntervalSelector } from "../../../../../../../../../../../store/alerts/selectors/cost-anomaly-alerts/data/alertsCostAnomalyDataTimeIntervalSelector";
import { alertsCostAnomalyDataTimeIntervalUnitSelector } from "../../../../../../../../../../../store/alerts/selectors/cost-anomaly-alerts/data/alertsCostAnomalyDataTimeIntervalUnitSelector";
import { costAnomalyPreviewDatesFromTimeIntervalSelector } from "../../../../../../../../../../../store/alerts/selectors/cost-anomaly-alerts/costAnomalyPreviewDatesFromTimeIntervalSelector";

interface CostAnomalyPreviewTrendCellProps {
  trend?: number | null;
}

export const CostAnomalyPreviewTrendCell: FC<
  CostAnomalyPreviewTrendCellProps
> = ({ trend }) => {
  const timeInterval = useAppSelector(
    alertsCostAnomalyDataTimeIntervalSelector,
  );
  const timeIntervalUnit = useAppSelector(
    alertsCostAnomalyDataTimeIntervalUnitSelector,
  );
  const { dateFrom, dateTo, previousDateFrom, previousDateTo } = useAppSelector(
    costAnomalyPreviewDatesFromTimeIntervalSelector,
  );

  const unit =
    Number(timeInterval) === 1
      ? timeIntervalUnit?.slice(0, -1)
      : timeIntervalUnit;

  return (
    <TrendChip
      value={trend}
      tooltipTitle={`Last ${timeInterval} ${unit} (${dateFrom} - ${dateTo}) compared with the preceding ${timeInterval} ${unit} (${previousDateFrom} - ${previousDateTo}).`}
    />
  );
};
