import { createAsyncThunk } from "@reduxjs/toolkit";
import { cloudChiprApi } from "../../../../services/cloudchipr.api";

export const getWorkflowsThunk = createAsyncThunk(
  "automations/getWorkflows",
  async (forceRefetch: boolean | undefined, { dispatch }) => {
    const { getUsersMeSchedules } = cloudChiprApi.endpoints;

    return await dispatch(
      getUsersMeSchedules.initiate({}, { forceRefetch: forceRefetch }),
    ).unwrap();
  },
);
