import { Button } from "@mui/material";
import { Dispatch, FC, SetStateAction, useCallback } from "react";
import { Add } from "@mui/icons-material";
import { TagFilter, TagsFilter } from "../utils/types";
import { tagsFilterDefaultCombinedOperator } from "../utils/constants";

interface TagFilterAddFilterButtonProps {
  setFilter: Dispatch<SetStateAction<TagsFilter | null>>;
  disabled?: boolean;
  label: string;
}
export const TagFilterAddFilterButton: FC<TagFilterAddFilterButtonProps> = ({
  setFilter,
  disabled,
  label,
}) => {
  const addNewCostAllocationTagClickHandler = useCallback(() => {
    setFilter((filter) => {
      const emptyTag: TagFilter = {
        key: "",
        values: [],
        operator: "in",
      };

      return {
        value: !filter?.value ? [emptyTag] : [...filter.value, emptyTag],
        combinationOperator:
          filter?.combinationOperator ?? tagsFilterDefaultCombinedOperator,
      };
    });
  }, [setFilter]);

  return (
    <Button
      variant="text"
      onClick={addNewCostAllocationTagClickHandler}
      disabled={disabled}
      startIcon={
        <Add
          sx={{
            color: !disabled ? "primary.main" : "text.disabled",
          }}
        />
      }
    >
      {label}
    </Button>
  );
};
