import moment from "moment/moment";
import { createCron } from "./createCron";

export const generateWeeklyCron = (days: number[], date: string | null) => {
  const cron = createCron({
    dayOfWeek: days,
    hour: +moment(date).hours(),
    minute: +moment(date).minutes(),
  }).split(" ");

  cron[2] = "?";
  cron[cron.length - 1] = increaseCronWeekDays(cron[cron.length - 1]);

  return cron.join(" ");
};

const increaseCronWeekDays = (weekDays: string) => {
  // this is because our cron supports 0-6, but the backend cron supports 1-7

  return weekDays
    ?.split("")
    ?.map((x) => (isNaN(+x) ? x : +x + 1))
    .join("");
};
