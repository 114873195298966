import { FC, Fragment } from "react";
import { Box } from "@mui/material";
import { getCostBreakdownNumeralItemSecondaryLabel } from "./utils/helpers/getCostBreakdownNumeralItemSecondaryLabel";
import { useAppSelector } from "../../../../../../../../../store/hooks";
import { WidgetNumeralViewItem } from "../../../../../adding-widget/widget-create/widget-setups/common/view-type/WidgetNumeralViewItem";
import { WidgetNumeralView } from "../../../../../adding-widget/widget-create/widget-setups/common/view-type/WidgetNumeralView";
import { CostBreakdownWidgetContentCosts } from "../../CostBreakdownWidgetContentCosts";
import { DateLabelNullable } from "../../../../../../../../../services/cloudchipr.api";
import { costBreakdownWidgetTableViewDataSelector } from "../../../../../../../../../store/dashboards/selectors/widgets/cost-breakdown/widget-view/costBreakdownWidgetTableViewDataSelector";
import { costBreakdownWidgetForecastOptionSelector } from "../../../../../../../../../store/dashboards/selectors/widgets/cost-breakdown/widget-view/costBreakdownWidgetForecastOptionSelector";
import { costBreakdownWidgetVisualizationSelector } from "../../../../../../../../../store/dashboards/selectors/widgets/cost-breakdown/widget-view/costBreakdownWidgetVisualizationSelector";
import { costBreakdownWidgetByWidgetIdLoadingSelector } from "../../../../../../../../../store/dashboards/selectors/widgets/cost-breakdown/loading/costBreakdownWidgetByWidgetIdLoadingSelector";
import { costBreakdownWidgetGroupingSelector } from "../../../../../../../../../store/dashboards/selectors/widgets/cost-breakdown/widget-view/costBreakdownWidgetGroupingSelector";
import { costBreakdownWidgetHasAggregationSelector } from "../../../../../../../../../store/dashboards/selectors/widgets/cost-breakdown/widget-view/costBreakdownWidgetHasAggregationSelector";

interface CostBreakdownWidgetNumeralViewProps {
  widgetId: string;
  dateLabel?: DateLabelNullable;
}

export const CostBreakdownWidgetNumeralView: FC<
  CostBreakdownWidgetNumeralViewProps
> = ({ widgetId, dateLabel }) => {
  const data = useAppSelector((state) =>
    costBreakdownWidgetTableViewDataSelector(state, widgetId),
  );
  const forecastOption = useAppSelector((state) =>
    costBreakdownWidgetForecastOptionSelector(state, widgetId),
  );
  const chartVisualization = useAppSelector((state) =>
    costBreakdownWidgetVisualizationSelector(state, widgetId),
  );

  const loading = useAppSelector((state) =>
    costBreakdownWidgetByWidgetIdLoadingSelector(state, widgetId),
  );

  const grouping = useAppSelector((state) =>
    costBreakdownWidgetGroupingSelector(state, widgetId),
  );

  const hasAggregations = useAppSelector((state) =>
    costBreakdownWidgetHasAggregationSelector(state, widgetId),
  );

  if (!forecastOption) {
    return null;
  }

  return (
    <Fragment>
      {hasAggregations && (
        <Box px={2} pt={2}>
          <CostBreakdownWidgetContentCosts
            widgetId={widgetId}
            dateLabel={dateLabel}
          />
        </Box>
      )}

      <WidgetNumeralView
        loading={loading}
        skeletonCount={data?.length}
        width={itemWidth}
      >
        {data?.map((item) => {
          const providers = item?.multiProviders ?? [item.cloud_provider];

          const secondaryLabel = getCostBreakdownNumeralItemSecondaryLabel({
            monthlyCost: item?.monthly_forecast?.cost,
            quarterlyCost: item?.quarterly_forecast?.cost,
            grouping,
            forecastOption,
          });

          return (
            <WidgetNumeralViewItem
              key={item.cloud_provider + item.field}
              providers={item.cloud_provider ? providers : []}
              label={item.field}
              cost={item.total_cost}
              trend={chartVisualization?.trend ? item.trend : null}
              itemsCount={data.length}
              secondaryLabel={
                chartVisualization?.forecasted_cost ? secondaryLabel : undefined
              }
              width={itemWidth}
            />
          );
        })}
      </WidgetNumeralView>
    </Fragment>
  );
};

const itemWidth = "20%";
