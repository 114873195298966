import { FC, useCallback, useMemo } from "react";
import { getAccountOptionDisabledData } from "./utils/helpers/getAccountOptionDisabledData";
import { RightsizingRecommendationsFilterTrigger } from "../RightsizingRecommendationsFilterTrigger";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../store/hooks";
import { rightsizingRecommendationsStatusesGroupedSelector } from "../../../../../../../../store/recommendations/selectors/rightsizing/statuses/rightsizingRecommendationsStatusesGroupedSelector";
import { DropdownSelect } from "../../../../../../../common/select/dropdown-select/DropdownSelect";
import { DropdownSelectOption } from "../../../../../../../common/select/dropdown-select/utils/types/types";
import { connectedAccountsGroupedByProviderAndOrganization } from "../../../../../../../../store/accounts/selectors/connected-accounts/connectedAccountsGroupedByProviderAndOrganization";
import { FilterTriggerComponentProps } from "../../../../../../../common/select/dropdown-select/utils/types/filterTriggerComponentProps";
import { setRecommendationsAccountFilter } from "../../../../../../../../store/recommendations/recommendationsSlice";
import { AccountOptionLabel } from "../../../../../../../common/account-select/components/AccountOptionLabel";

export const RightsizingAccountFilter: FC = () => {
  const dispatch = useAppDispatch();
  const statuses = useAppSelector(
    rightsizingRecommendationsStatusesGroupedSelector,
  );

  const groupedAllAccounts = useAppSelector(
    connectedAccountsGroupedByProviderAndOrganization,
  );

  const options = useMemo(() => {
    return groupedAllAccounts.map((option) => {
      const disabled = getAccountOptionDisabledData(
        statuses,
        option.value,
        option.accountType,
      );

      return {
        value: option.value,
        rawValue: option,
        disabled,
        listItemHeight: option?.groupName?.includes(option.orgId ?? "")
          ? 77
          : undefined,
        label: (
          <AccountOptionLabel
            showAccountId
            option={option}
            total={false}
            ems={false}
          />
        ),
      } as DropdownSelectOption;
    });
  }, [groupedAllAccounts, statuses]);

  const filterFunction = useCallback(
    (option: DropdownSelectOption, keyword: string) => {
      const aim =
        `${option.value} ${option.rawValue?.label} ${option.rawValue?.providerAccountId} ${option.rawValue?.orgId}`.toLowerCase();
      return aim.includes(keyword.trim().toLowerCase());
    },
    [],
  );

  const TriggerComponent = useCallback((props: FilterTriggerComponentProps) => {
    return (
      <RightsizingRecommendationsFilterTrigger
        {...props}
        tooltip="Recommendations are not enabled on some of the accounts."
      />
    );
  }, []);

  const changeHandler = useCallback(
    (accounts: string[]) => {
      dispatch(setRecommendationsAccountFilter(accounts));
    },
    [dispatch],
  );

  return (
    <DropdownSelect
      label="Accounts"
      listWidth={360}
      options={options}
      submitHandlerOnClose={changeHandler}
      filterFn={filterFunction}
      TriggerComponent={TriggerComponent}
    />
  );
};
