import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  cloudChiprApi,
  Schedule,
  ScheduleStatus,
} from "../../../services/cloudchipr.api";
import {
  generateWorkflowUpdatingData,
  getWorkflowUpdatePayloadData,
} from "../../../components/pages/automations/common/utils/helpers/helpers";
import { getWorkflowsThunk } from "../../automations/thunks/wokrflows/getWorkflowsThunk";

export const scheduledJobsPatchFixedCacheKey =
  "patchUsersMeSchedulesByScheduleId-fixed-cache-key";

export const toggleScheduledJobsStatusThunk = createAsyncThunk(
  "scheduledJobs/toggleScheduleStatus",
  async (
    {
      schedule,
      status,
    }: {
      schedule: Schedule;
      status: ScheduleStatus;
    },
    { dispatch },
  ) => {
    const { patchUsersMeSchedulesByScheduleId } = cloudChiprApi.endpoints;

    const payloadData = getWorkflowUpdatePayloadData(
      generateWorkflowUpdatingData({ ...schedule, status }),
    );

    await dispatch(
      patchUsersMeSchedulesByScheduleId.initiate(payloadData, {
        fixedCacheKey: scheduledJobsPatchFixedCacheKey,
      }),
    );
    await dispatch(getWorkflowsThunk(true));
  },
);
