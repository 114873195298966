import { TabList } from "@mui/lab";
import { Divider, Tab } from "@mui/material";
import { FC, Fragment, SyntheticEvent, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { AlertsTabLabel } from "./AlertsTabLabel";
import { AlertTabType } from "../../../../store/alerts/utils/types/types";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { setAlertsActiveTab } from "../../../../store/alerts/alertsSlice";
import { costAnomalyAlertsLengthSelector } from "../../../../store/alerts/selectors/cost-anomaly-alerts/costAnomalyAlertsLengthSelector";
import { utilizationAlertsLengthSelector } from "../../../../store/alerts/selectors/utilization-alerts/utilizationAlertsLengthSelector";
import { alertsActiveTabSelector } from "../../../../store/alerts/selectors/alertsActiveTabSelector";

export const AlertsTabs: FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const costAnomalyLength = useAppSelector(costAnomalyAlertsLengthSelector);
  const utilizationLength = useAppSelector(utilizationAlertsLengthSelector);
  const alertsActiveTab = useAppSelector(alertsActiveTabSelector);

  const handleTabChange = useCallback(
    (_: SyntheticEvent, tab: AlertTabType) => {
      dispatch(setAlertsActiveTab(tab));
      navigate(`/alerts?alertType=${tab}`, { replace: true });
    },
    [dispatch, navigate],
  );

  return (
    <Fragment>
      <TabList sx={{ bgcolor: "white" }} onChange={handleTabChange}>
        <Tab
          value="costAnomaly"
          label={
            <AlertsTabLabel
              label="Cost Anomaly Alerts"
              count={costAnomalyLength}
              active={alertsActiveTab === "costAnomaly"}
            />
          }
          sx={{ textTransform: "none" }}
        />
        <Tab
          value="utilization"
          label={
            <AlertsTabLabel
              label="Utilization & Coverage Alerts"
              count={utilizationLength}
              active={alertsActiveTab === "utilization"}
            />
          }
          sx={{ textTransform: "none" }}
        />
      </TabList>

      <Divider />
    </Fragment>
  );
};
