import { FC, useMemo } from "react";
import { Stack, Typography } from "@mui/material";

interface PieChartLoadingOrEmptyStateProps {
  loading: boolean;
  emptyText?: string;
}
export const PieChartLoadingOrEmptyState: FC<
  PieChartLoadingOrEmptyStateProps
> = ({ loading, emptyText }) => {
  const label = useMemo(() => {
    if (loading) {
      return "Loading...";
    }
    return emptyText ?? "No data by your selected parameters";
  }, [loading, emptyText]);

  return (
    <Stack height={300} justifyContent="center" alignItems="center">
      <Typography fontSize={24}>{label}</Typography>
    </Stack>
  );
};
