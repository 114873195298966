import { liveUsageMgmtAccountIdsSelector } from "../store-selectors/liveUsageMgmtAccountIdsSelector";
import { liveUsageMgmtProviderSelector } from "../store-selectors/liveUsageMgmtProviderSelector";
import { stateAccountsSelector } from "../../../accounts/selectors/slice-data/stateAccountsSelector";
import { RootState } from "../../../store";
import {
  AccountAwsProviderDetails,
  AccountAzureProviderDetails,
  AccountGcpProviderDetails,
} from "../../../../services/cloudchipr.api";

export const liveUsageFirstAccountMetaDataSelector = (
  state: RootState,
):
  | {
      isRootAccount?: boolean;
      isSubAccount?: boolean;
      parentId?: string;
      serviceAccount?: string;
      projectId?: string;
      servicePrincipal?: string;
      subscriptionId?: string;
    }
  | undefined => {
  const accountIds = liveUsageMgmtAccountIdsSelector(state);
  const accounts = stateAccountsSelector(state);
  const provider = liveUsageMgmtProviderSelector(state);

  if (!accountIds || !provider) {
    return;
  }
  const account = accounts?.find((item) => item.id === accountIds?.[0]);

  if (provider === "aws") {
    const providerDetails = (
      account?.provider_details as AccountAwsProviderDetails
    )?.aws;
    return {
      isRootAccount: providerDetails?.is_root,
      isSubAccount: account?.is_sub_account,
      parentId: account?.parent_id ?? undefined,
    };
  }

  if (provider === "gcp") {
    const providerDetails = (
      account?.provider_details as AccountGcpProviderDetails
    )?.gcp;
    return {
      serviceAccount: providerDetails?.service_account,
      projectId: providerDetails?.project_id ?? undefined,
    };
  }

  if (provider === "azure") {
    const providerDetails = (
      account?.provider_details as AccountAzureProviderDetails
    )?.azure;
    return {
      servicePrincipal: providerDetails?.service_principal,
      subscriptionId: providerDetails?.subscription_id,
    };
  }
};
