import { createDraftSafeSelector } from "@reduxjs/toolkit";
import { prefillAccountIdSelector } from "./prefillAccountIdSelector";
import { providerSelector } from "./providerSelector";
import { stateAccountsSelector } from "../../accounts/selectors/slice-data/stateAccountsSelector";
import { Account } from "../../../services/cloudchipr.api";

export const prefillAccountDetailsSelector = createDraftSafeSelector(
  [prefillAccountIdSelector, providerSelector, stateAccountsSelector],
  (prefillAccountId, provider, accounts) => {
    if (provider !== "gcp") {
      return;
    }

    const account: Account | undefined = accounts?.find(
      (account) => account.id === prefillAccountId,
    );

    if (account) {
      return {
        connectionTypeId: account.provider_account_id,
      };
    }
  },
);
