import { ResourceExplorerGridTotal } from "../../../../../services/cloudchipr.api";

export const getCostBreakdownREDataFieldsCountOccurrences = (
  data: ResourceExplorerGridTotal[],
) => {
  if (!data) {
    return null;
  }

  const occurrences = new Map();

  data.forEach((item) => {
    const total_cost =
      (occurrences.get(item.field)?.total_cost ?? 0) + item.total_cost;
    const previous_period_total_cost =
      (occurrences.get(item.field)?.previous_period_total_cost ?? 0) +
      item.previous_period_total_cost;
    const trend =
      item.trend || occurrences.get(item.field)?.trend
        ? (occurrences.get(item.field)?.trend ?? 0) + item.trend
        : null;

    const existingMultiProviders =
      occurrences.get(item.field)?.multiProviders ?? [];
    const multiProviders = item?.cloud_provider
      ? [...existingMultiProviders, item.cloud_provider]
      : existingMultiProviders;

    occurrences.set(item.field, {
      ...item,
      multiProviders,
      total_cost,
      previous_period_total_cost,
      trend,
    });
  });

  return occurrences;
};
