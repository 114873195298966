import { FC, useCallback } from "react";
import { CommitmentsDateGranularitySelector } from "./CommitmentsDateGranularitySelector";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../store/hooks";
import { setCommitmentsDateGranularity } from "../../../../../../../store/commitments/commitmentsSlice";
import { commitmentsDateGranularitySelector } from "../../../../../../../store/commitments/selectors/common/view-options/commitmentsDateGranularitySelector";
import { DateDataPoint } from "../../../../../../../services/cloudchipr.api";
import { getSavingsPlansForGranularThunk } from "../../../../../../../store/commitments/thunks/savings-plans/getSavingsPlansForGranularThunk";
import { getReservationsForGranularThunk } from "../../../../../../../store/commitments/thunks/reservations/getReservationsForGranularThunk";
import { commitmentsTypeTabSelector } from "../../../../../../../store/commitments/selectors/common/commitmentsTypeTabSelector";

export const CommitmentsDateGranularity: FC = () => {
  const dispatch = useAppDispatch();

  const activeTab = useAppSelector(commitmentsTypeTabSelector);
  const dateGranularity = useAppSelector(commitmentsDateGranularitySelector);

  const changeHandler = useCallback(
    (dg: DateDataPoint) => {
      dispatch(setCommitmentsDateGranularity(dg));

      if (activeTab === "reservation") {
        dispatch(getReservationsForGranularThunk());
      } else {
        dispatch(getSavingsPlansForGranularThunk());
      }
    },
    [dispatch, activeTab],
  );

  if (!dateGranularity) {
    return null;
  }

  return (
    <CommitmentsDateGranularitySelector
      onChange={changeHandler}
      dateGranularity={dateGranularity}
    />
  );
};
