import { DateLabel } from "../../../../../services/cloudchipr.api";
import {
  preDefinedRanges,
  preDefinedThisMonth,
} from "../preDefinedRanges/preDefinedRanges";
import { PreDefinedRange } from "../constants/types";
import { getQuarterlyPreDefinedRange } from "../preDefinedRanges/getQuarterlyPreDefinedRange";

export const generateDateFromDateLabel = (
  label: DateLabel,
  startQuarter?: number,
): PreDefinedRange => {
  if (!label) {
    return preDefinedThisMonth;
  }

  return (
    preDefinedRanges[label] ??
    getQuarterlyPreDefinedRange(label, startQuarter) ??
    preDefinedThisMonth
  );
};
