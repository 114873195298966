import { capitalize } from "@mui/material";
import { WeeklySchedule } from "../../../../../../../services/cloudchipr.api";

export const getWeeklyScheduleTitleAndTooltip = (
  scheduleInterval: number,
  time: string,
  scheduleWeekDays: WeeklySchedule["week_days"],
) => {
  const prefix =
    scheduleInterval === 1 ? "Every week" : `Every ${scheduleInterval} weeks`;
  let weekDays;

  if (scheduleWeekDays.length > 1) {
    const allWeekDays = [...scheduleWeekDays];
    const lastDay = capitalize(allWeekDays.pop() ?? "");
    const firstDays = allWeekDays.map((day) => capitalize(day)).join(", ");
    weekDays = `${firstDays} and ${lastDay}`;
  } else {
    weekDays = capitalize(scheduleWeekDays.at(0) ?? "");
  }

  return {
    title: "Weekly",
    tooltip: `${prefix} - on ${weekDays}, at ${time}`,
  };
};
