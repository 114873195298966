import { FC } from "react";
import { Tooltip, Typography } from "@mui/material";
import numeral from "numeral";
import { metricsUnit } from "../utils/constants";
import { MetricType } from "../utils/types";
import { formatMaxPoint } from "../utils/helpers/formatMaxPoint";

interface ResourceMetricsAggregationProps {
  value?: number | null;
  type: MetricType;
}

export const ResourceMetricsAggregation: FC<
  ResourceMetricsAggregationProps
> = ({ value, type }) => {
  const formattedPoint = formatMaxPoint(type, value);

  const typography = (
    <Typography
      variant="caption"
      color="text.secondary"
      fontSize={10}
      width={30}
      textTransform="uppercase"
    >
      {formattedPoint ?? "N/A"}
      {!!value && metricsUnit[type]}
    </Typography>
  );

  if (value && formattedPoint !== `${value}`) {
    const tooltipTitle = `${numeral(value).format("0,0.[00]")} ${
      metricsUnit[type]
    }`;

    return (
      <Tooltip title={tooltipTitle} arrow placement="left">
        {typography}
      </Tooltip>
    );
  }

  return typography;
};
