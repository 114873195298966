import { useMemo } from "react";
import {
  getAutomationPreviewFormatDate,
  getRunDateFromCron,
} from "../../../../schedule/common/utils/helpers/common";
import { capitalizeString } from "../../../../../../utils/helpers/capitalizeString";
import { dateToUtcByTimeZone } from "../../../../../../utils/helpers/date-time/datetime-format";
import { normalizeWeeklyCronText } from "../helpers/normalizeWeeklyCronText";
import { getCronFrequency } from "../../../../../../store/automations/utils/helpers/cron/getCronFrequency";

export function useSchedulePreviewDatesInfoHook({
  cron,
  timeZone,
  nextRun,
  weeklyInterval,
}: {
  nextRun: string;
  cron: string | null;
  timeZone: string;
  weeklyInterval?: number | null;
}) {
  const nextRuns = useMemo(() => {
    let date = "";
    let utc = "";

    if (nextRun) {
      date = getAutomationPreviewFormatDate(nextRun);
      utc = getAutomationPreviewFormatDate(
        dateToUtcByTimeZone(nextRun, timeZone) || nextRun,
      );
    }

    return [
      {
        title: "Next Run",
        date: date || "Never",
        utc: utc || "",
      },
    ];
  }, [nextRun, timeZone]);

  const frequency: {
    title: string;
    label: string;
    tooltip: string;
    hideEnds?: boolean;
  }[] = useMemo(() => {
    if (!cron) {
      return [
        {
          title: "Frequency",
          label: "Once",
          tooltip: nextRun ? `Once at ${nextRun}` : "",
          hideEnds: true,
        },
      ];
    }

    return [
      {
        title: "Frequency",
        label: capitalizeString(getCronFrequency(cron)),
        tooltip: normalizeWeeklyCronText(
          getRunDateFromCron(cron),
          weeklyInterval ?? null,
        ),
      },
    ];
  }, [cron, nextRun, weeklyInterval]);

  return { frequency, nextRuns, nextRunDate: nextRun };
}
