import { FC, Fragment, ReactNode, useMemo } from "react";
import { Stack, Tooltip } from "@mui/material";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import { ResourceActionMissingPermissionsTooltipTitle } from "./ResourceActionMissingPermissionsTooltipTitle";
import { CleanActionTypes } from "../../../../../../../../../utils/clean-options";
import { useAllResourcesVisibility } from "../../../../../../../schedule/common/utils/hooks/useAllResourcesVisibility.hook";
import { useAppSelector } from "../../../../../../../../../store/hooks";
import { automationProviderSelector } from "../../../../../../../../../store/automations/selectros/common/fields/automationProviderSelector";
import { ResourceType } from "../../../../../../../../../services/cloudchipr.api";

interface ResourceActionsMissingPermissionsWrapperProps {
  cleanAction: CleanActionTypes;
  children: ReactNode;
  resourceType: ResourceType;
  readWriteAccountsIds: string;
}

export const ResourceActionsMissingPermissionsWrapper: FC<
  ResourceActionsMissingPermissionsWrapperProps
> = ({ children, cleanAction, resourceType, readWriteAccountsIds }) => {
  const provider = useAppSelector(automationProviderSelector);

  const resourcesVisibility = useAllResourcesVisibility({
    accountIds: readWriteAccountsIds,
    provider,
  });

  const missingPermissionAccountIds = useMemo(() => {
    return Object.keys(resourcesVisibility).reduce((acc, key) => {
      const accountActions = resourcesVisibility[key]?.[resourceType]?.actions;

      const actionIsNotAvailable = accountActions?.[cleanAction] === false;

      if (actionIsNotAvailable) {
        acc.push(key);
      }

      return acc;
    }, [] as string[]);
  }, [resourcesVisibility, resourceType, cleanAction]);

  if (!missingPermissionAccountIds.length) {
    return <Fragment>{children}</Fragment>;
  }

  return (
    <Stack direction="row" alignItems="center">
      {children}

      <Tooltip
        title={
          <ResourceActionMissingPermissionsTooltipTitle
            missingPermissionAccountIds={missingPermissionAccountIds}
          />
        }
        placement="right-start"
        componentsProps={tooltipComponentsProps}
      >
        <WarningAmberIcon color="warning" />
      </Tooltip>
    </Stack>
  );
};

const tooltipComponentsProps = {
  tooltip: {
    sx: {
      color: "text.primary",
      bgcolor: "background.paper",
      minWidth: 425,
      boxShadow: 7,
      p: 0,
    },
  },
};
