import { RootState } from "../../../../store";
import { getUsersMeOrganisationsCurrentDashboardsByDashboardIdSelector } from "../getUsersMeOrganisationsCurrentDashboardsByDashboardIdSelector";

export const getDashboardByIdLoadingSelector = (
  state: RootState,
  dashboardId?: string,
): boolean => {
  if (!dashboardId) {
    return false;
  }

  return !!getUsersMeOrganisationsCurrentDashboardsByDashboardIdSelector({
    dashboardId,
  })(state)?.isLoading;
};
