import { DashboardMixedVisibilitiesItemType } from "../../../../components/pages/dashboard-all-items/components/table/utils/types/types";
import { ReMixedVisibilitiesItemType } from "../../../../components/pages/resource-explorer-all-views/components/table/utils/types/types";

export const getHierarchyMixedVisibilitiesCreatedByData = (
  data:
    | DashboardMixedVisibilitiesItemType[]
    | ReMixedVisibilitiesItemType[]
    | null,
) => {
  const createdByes = new Set<string>();

  data?.forEach((item) => {
    const name = item.created_by?.name ?? item.created_by?.email;

    if (name) {
      createdByes.add(name?.includes("deleted") ? "Deleted user" : name);
    }
  });

  return Array.from(createdByes);
};
