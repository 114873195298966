import { FC } from "react";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";

export const ManageAllReportsButton: FC = () => {
  return (
    <Button
      variant="text"
      color="tertiary"
      component={Link}
      target="_blank"
      to="/subscriptions"
      sx={{ textTransform: "none" }}
      endIcon={<OpenInNewIcon />}
    >
      Manage All
    </Button>
  );
};
