import { FC, Fragment, useMemo } from "react";
import { Row } from "@tanstack/react-table";
import { CircularProgress, Stack, Typography } from "@mui/material";
import { RightsizingSubRowInfoSection } from "./RightsizingSubRowInfoSection";
import { RightsizingRecommendationsChart } from "./charts/RightsizingRecommendationsChart";
import {
  RightSizingRecommendation,
  useGetUsersMeAccountsByAccountIdRightSizingRecommendationsAndRecommendationIdQuery,
} from "../../../../../../../../services/cloudchipr.api";
import { generateChartData } from "../../../utils/helpers/generateChartData";

interface RightsizingRecommendationsSubRowProps {
  data: RightSizingRecommendation;
}

const RightsizingRecommendationsSubRow: FC<
  RightsizingRecommendationsSubRowProps
> = ({ data }) => {
  const { data: currentData, isLoading } =
    useGetUsersMeAccountsByAccountIdRightSizingRecommendationsAndRecommendationIdQuery(
      {
        recommendationId: data.id,
        accountId: data.account.id,
        numberOfMetricsDataPoints:
          data.account.provider === "azure" ? 168 : undefined,
      },
    );

  const chartData = useMemo(() => {
    if (!currentData?.cpu) {
      return null;
    }

    return generateChartData(
      currentData.cpu,
      currentData.current_vcpus,
      currentData.recommended_vcpus,
      currentData.current_machine_type,
      currentData.recommended_machine_type,
      currentData?.account?.provider === "azure" ? "average" : "maximum",
    );
  }, [
    currentData?.current_vcpus,
    currentData?.recommended_vcpus,
    currentData?.cpu,
    currentData?.current_machine_type,
    currentData?.recommended_machine_type,
    currentData?.account?.provider,
  ]);

  if (isLoading) {
    return (
      <Stack alignItems="center" py={6}>
        <CircularProgress disableShrink />
      </Stack>
    );
  }

  if (!currentData || !chartData) {
    return null;
  }

  const recommendedCosts = currentData.spending - currentData.potential_saving;

  return (
    <Fragment>
      <RightsizingSubRowInfoSection
        resourceType={currentData.resource_type}
        spending={currentData.spending}
        region={currentData.region}
        recommendedCosts={recommendedCosts}
        provider={currentData?.account?.provider}
      />

      {!!chartData.length && (
        <Fragment>
          <Typography variant="subtitle2" pl={5} mt={6} mb={2}>
            CPU Utilization (%)
          </Typography>
          <RightsizingRecommendationsChart
            data={chartData}
            currentType={currentData.current_machine_type}
            recommendedType={currentData.recommended_machine_type}
            metric={
              currentData?.account?.provider === "azure" ? "average" : "maximum"
            }
          />
        </Fragment>
      )}
    </Fragment>
  );
};

export const rightsizingRecommendationsSubRow = (
  row: Row<RightSizingRecommendation>,
) => <RightsizingRecommendationsSubRow data={row.original} />;
