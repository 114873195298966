import cronstrue from "cronstrue";

export const cronToString = (cron: string) => {
  try {
    return cronstrue.toString(cron, {
      use24HourTimeFormat: false,
      dayOfWeekStartIndexZero: false,
    });
  } catch (e) {
    return "Invalid Date";
  }
};
