import { RootState } from "../../../../store";
import { automationFrequencySelector } from "../../common/automationFrequencySelector";
import { workflowSchedulerSelector } from "../workflowSchedulerSelector";

export const workflowNextRunsAvailableSelector = (state: RootState) => {
  const frequency = automationFrequencySelector(state);
  const scheduler = workflowSchedulerSelector(state);

  if (!scheduler) {
    return false;
  }

  const hourlyAvailable = frequency === "hourly" && scheduler.repeatValue;
  const dailyAvailable =
    frequency === "daily" && scheduler.repeatValue && scheduler.runTime;

  const weeklyAvailable =
    frequency === "weekly" && scheduler.weekDays && scheduler.runTime;

  return !!(hourlyAvailable || dailyAvailable || weeklyAvailable);
};
