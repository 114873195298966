export const executionLogPaginationSize = 10;

export const executionLogCleanActionName = new Map([
  ["terminate", "Delete"],
  ["stop", "Stop"],
  ["start", "Start"],
]);

export const executionLogsAccountsStatuses = [
  "connected",
  "deleted",
  "deactivated",
];

export const executionLogsSourceNames = new Map([
  ["workflow", "Workflow"],
  ["manual", "Manual"],
  ["schedule", "Schedule"],
  ["off_hours", "Off Hours"],
]);

export const getExecutionLogThunkFixedCacheKey =
  "postV3UsersMeExecutionLogs-fixed-cache-key";
