import { createDraftSafeSelector } from "@reduxjs/toolkit";
import { rightsizingRecommendationsDismissedResourcesDataSelector } from "./rightsizingRecommendationsDismissedResourcesDataSelector";
import { recommendationAppliedProvidersSelector } from "../../filters/recommendationAppliedProvidersSelector";
import { recommendationAppliedTypesSelector } from "../../filters/recommendationAppliedTypesSelector";
import { recommendationAppliedAccountsSelector } from "../../filters/recommendationAppliedAccountsSelector";
import { recommendationsDismissedResourcesDataByFiltersCombiner } from "../../dismiss/resources/recommendationsDismissedResourcesDataByFiltersCombiner";
import { recommendationAppliedTagsSetSelector } from "../../filters/recommendationAppliedTagsSetSelector";

export const rightsizingRecommendationsDismissedResourcesFilteredDataSelector =
  createDraftSafeSelector(
    [
      rightsizingRecommendationsDismissedResourcesDataSelector,
      recommendationAppliedProvidersSelector,
      recommendationAppliedTypesSelector,
      recommendationAppliedAccountsSelector,
      recommendationAppliedTagsSetSelector,
    ],
    (data, providers, types, accounts, tags) => {
      return recommendationsDismissedResourcesDataByFiltersCombiner(
        data,
        providers,
        types,
        accounts,
        tags,
      );
    },
  );
