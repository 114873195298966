import { FC, useCallback, useMemo, useState } from "react";
import { useContextYears } from "@rehookify/datepicker";
import { Box } from "@mui/material";
import { DPOffsetValue } from "@rehookify/datepicker/dist/types/offset";
import { DateRangeMonthAndYear } from "./DateRangeMonthAndYear";
import { DateRangeCalendarDaySelection } from "./day/DateRangeCalendarDaySelection";
import { DateRangeCalendarYearSelection } from "./year/DateRangeCalendarYearSelection";
import { DateRangeCalendarMonthSelection } from "./month/DateRangeCalendarMonthSelection";

interface DateRangeCalendarProps {
  highlightToday?: boolean;
}

export const DateRangeCalendar: FC<DateRangeCalendarProps> = ({
  highlightToday,
}) => {
  const [offSet, setOffSet] = useState<keyof DPOffsetValue>("days");
  const years = useContextYears();

  const label = useMemo(() => {
    if (offSet !== "years") {
      return undefined;
    }

    return `${years?.years?.at(0)?.year} - ${years?.years?.at(4)?.year}`;
  }, [years, offSet]);

  const monthSelectHandler = useCallback(() => {
    setOffSet("days");
  }, [setOffSet]);

  const yearSelectHandler = useCallback(() => {
    setOffSet("months");
  }, [setOffSet]);

  return (
    <Box>
      <DateRangeMonthAndYear
        offSet={offSet}
        setOffSet={setOffSet}
        label={label}
      />

      <Box px={0.5} mt={2}>
        {offSet === "days" && (
          <DateRangeCalendarDaySelection highlightToday={highlightToday} />
        )}

        {offSet === "months" && (
          <DateRangeCalendarMonthSelection onSelect={monthSelectHandler} />
        )}

        {offSet === "years" && (
          <DateRangeCalendarYearSelection onSelect={yearSelectHandler} />
        )}
      </Box>
    </Box>
  );
};
