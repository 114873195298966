import { recommendationComputeActiveDataSelector } from "./compute/recommendationComputeActiveDataSelector";
import { recommendationComputeDismissedDataSelector } from "./compute/recommendationComputeDismissedDataSelector";
import { getUsersMeOrganisationsCurrentDismissedResourcesSelector } from "../../dismiss/resources/getUsersMeOrganisationsCurrentDismissedResourcesSelector";
import { RootState } from "../../../../store";

export const offHoursRecommendationsComputeEmptySelector = (
  state: RootState,
) => {
  const computeActiveData = recommendationComputeActiveDataSelector(state);
  const computeDismissedData =
    recommendationComputeDismissedDataSelector(state);
  const computeDismissedResources =
    getUsersMeOrganisationsCurrentDismissedResourcesSelector({
      group: "compute",
      recommendationType: "off_hours",
    })(state).data;

  return !(
    computeActiveData.length ||
    computeDismissedData.length ||
    computeDismissedResources?.length
  );
};
