import numeral from "numeral";

export const moneyFormatter = (value: number) => {
  const absoluteValue = Math.abs(value);

  if ((absoluteValue < 10 && absoluteValue > 0) || absoluteValue >= 1000) {
    return `${numeral(value).format("$0.0a")}`;
  }

  return value ? numeral(value).format("$0") : value;
};
