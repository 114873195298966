import { FC, useCallback } from "react";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import { TextFieldProps } from "@mui/material/TextField/TextField";
import { DebouncedInput } from "../common/DebouncedInput";

interface GlobalFilterProps extends Omit<TextFieldProps, "onChange"> {
  globalFilter: string | number;
  setGlobalFilter: (value: string) => void;
}

export const GlobalFilter: FC<GlobalFilterProps> = ({
  globalFilter,
  setGlobalFilter,
  ...textFieldProps
}) => {
  const handleChange = useCallback(
    (value: any) => setGlobalFilter(value.toString()),
    [setGlobalFilter],
  );

  return (
    <DebouncedInput
      size="small"
      placeholder="Search"
      type="search"
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        ),
      }}
      variant="outlined"
      value={globalFilter}
      onChange={handleChange}
      {...textFieldProps}
    />
  );
};
