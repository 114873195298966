import { cloudChiprApi } from "../../../../../services/cloudchipr.api";
import { RootState } from "../../../../store";
import { createDashboardVisibilityHierarchyFolderThunkFixedCacheKey } from "../../../thunks/dashboard-hierarchy-visibility/createDashboardVisibilityHierarchyFolderThunk";

const selector =
  cloudChiprApi.endpoints.postV2UsersMeOrganisationsCurrentDashboardFolders
    .select;

export const createDashboardFolderLoadingSelector = (
  state: RootState,
): boolean => {
  return !!selector(createDashboardVisibilityHierarchyFolderThunkFixedCacheKey)(
    state,
  )?.isLoading;
};
