import { FC } from "react";
import Box from "@mui/material/Box";
import { HeaderCellInfoIcon } from "./HeaderCellInfoIcon";

interface HeaderCellProps {
  title: string;
  tooltip?: string;
}

export const HeaderCell: FC<HeaderCellProps> = ({ title, tooltip }) => {
  return (
    <Box px={1}>
      {title}
      {tooltip && <HeaderCellInfoIcon title={tooltip} />}
    </Box>
  );
};
