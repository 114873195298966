import { RootState } from "../../store";
import { ProviderType } from "../../../services/cloudchipr.api";
import { filterTemplatesSelector } from "../../filters/selectors/filter-set/filterTemplatesSelector";

interface AccountFilterTemplateNameSelectorArgs {
  provider: ProviderType;
  filterSetId: string | null;
}
export const accountFilterTemplateNameSelector = (
  state: RootState,
  { provider, filterSetId }: AccountFilterTemplateNameSelectorArgs,
): string | undefined => {
  if (!filterSetId) {
    return;
  }

  const filterSetsList = filterTemplatesSelector(state, provider);

  return (
    filterSetsList?.find((filter_set) => filter_set?.id === filterSetId)
      ?.name ?? ""
  );
};
