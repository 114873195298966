import { FC, useCallback } from "react";
import { Stack } from "@mui/material";
import { ExecutionLogsAccountFilter } from "./ExecutionLogsAccountFilter";
import { ExecutionLogSourceFilter } from "./ExecutionLogSourceFilter";
import { useAppDispatch, useAppSelector } from "../../../../../../store/hooks";
import {
  setExecutionLogFilters,
  setExecutionLogPagination,
} from "../../../../../../store/execution-log/executionLogSlice";
import {
  actionFilterOptions,
  statusFilterOptions,
} from "../../../utils/constants/filtersOptions";
import { ExecutionLogFilters } from "../../../utils/types/types";
import { getExecutionLogThunk } from "../../../../../../store/execution-log/thunks/getExecutionLogThunk";
import { DropdownSelect } from "../../../../../common/select/dropdown-select/DropdownSelect";
import { executionLogFiltersSelector } from "../../../../../../store/execution-log/selectors/executionLogFiltersSelector";
import { arrayIsEqual } from "../../../../../../utils/arrayIsEqual";

export const ExecutionLogsFilters: FC = () => {
  const dispatch = useAppDispatch();
  const executionLogFilters = useAppSelector(executionLogFiltersSelector);

  const filterSubmitHandler = useCallback(
    (filterKey: Extract<keyof ExecutionLogFilters, "action" | "state">) =>
      (values: string[]) => {
        const prevFilters = executionLogFilters?.[filterKey] ?? [];

        if (arrayIsEqual(values, prevFilters)) {
          return;
        }

        dispatch(setExecutionLogFilters({ [filterKey]: values }));
        dispatch(setExecutionLogPagination({ page: 0 }));

        dispatch(getExecutionLogThunk({}));
      },
    [dispatch, executionLogFilters],
  );

  return (
    <Stack
      direction="row"
      spacing={2}
      px={2}
      py={1.5}
      borderBottom={1}
      borderColor="grey.300"
    >
      <ExecutionLogsAccountFilter />

      <DropdownSelect
        label="Actions"
        options={actionFilterOptions}
        submitHandlerOnClose={filterSubmitHandler("action")}
      />

      <ExecutionLogSourceFilter />

      <DropdownSelect
        label="Statuses"
        options={statusFilterOptions}
        submitHandlerOnClose={filterSubmitHandler("state")}
      />
    </Stack>
  );
};
