import { getDefaultFilterTemplates } from "./utils/helpers/getDefaultFilterTemplates";
import { FilterSet } from "../../../../services/cloudchipr.api";
import { RootState } from "../../../store";
import { liveUsageMgmtFilterTemplatesSelector } from "../liveUsageMgmtFilterTemplatesSelector";

export const liveUsageMgmtSortedFilterTemplatesSelector = (
  state: RootState,
): FilterSet[] => {
  const filterTemplates = liveUsageMgmtFilterTemplatesSelector(state);

  if (!filterTemplates) {
    return [];
  }

  const defaults = getDefaultFilterTemplates(filterTemplates) ?? [];

  const rest = filterTemplates
    .filter(({ source }) => {
      return source !== "cloudchipr";
    })
    .sort((first, second) => {
      if (first.name.toLowerCase() > second.name.toLowerCase()) {
        return 1;
      }

      return -1;
    });

  return [...defaults, ...rest];
};
