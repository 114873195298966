import { FC, useCallback, useMemo } from "react";
import {
  FormControl,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import { ResourceExplorerChartBaseSelect } from "../../../pages/resource-explorer/components/resource-explorer-card/components/resource-explorer-chart/components/ResourceExplorerChartBaseSelect";
import { ChartTypeSelect } from "../../../../storybook/charts/multi-type-chart/components/common/ChartTypeSelect";
import { resourceExplorerPossibleChartTypes } from "../../../../store/resource-explorer/utils/types/resourceExplorer";
import { MultiTypeChart } from "../../../../storybook/charts/multi-type-chart/MultiTypeChart";
import { generateBudgetDataOnChart } from "../../../../store/resource-explorer/utils/helpers/generateBudgetDataOnChart";
import { Budget, DateDataPoint } from "../../../../services/cloudchipr.api";
import { moneyFormatter } from "../../../../utils/numeral/moneyFormatter";

interface InternalResourceExplorerChartProps {
  budget: Budget | null;
  dataPoint: DateDataPoint;
  showBudgetLine: boolean;
}

export const InternalResourceExplorerChart: FC<
  InternalResourceExplorerChartProps
> = ({ budget, dataPoint, showBudgetLine }) => {
  const budgetLineData = useMemo(
    () => generateBudgetDataOnChart(dataPoint, budget),
    [dataPoint, budget],
  );

  const referenceLines = useMemo(() => {
    return budgetLineData && showBudgetLine ? budgetLineData : [];
  }, [budgetLineData, showBudgetLine]);

  const yAxisFormatter = useCallback((value: number | string) => {
    return moneyFormatter(+value);
  }, []);

  return (
    <Stack mt={3}>
      <Stack direction="row" justifyContent="space-between">
        {dataPoint && (
          <FormControl size="xsmall" fullWidth sx={{ width: 110 }}>
            <Select
              value={dataPoint}
              name="data_point"
              sx={{
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "grey.300",
                },
              }}
            >
              <MenuItem value="daily">
                <Typography variant="caption">Daily</Typography>
              </MenuItem>
              <MenuItem value="weekly">
                <Typography variant="caption">Weekly</Typography>
              </MenuItem>
              <MenuItem value="monthly">
                <Typography variant="caption">Monthly</Typography>
              </MenuItem>
            </Select>
          </FormControl>
        )}

        <Stack
          spacing={1}
          direction="row"
          alignItems="center"
          justifyContent="flex-end"
        >
          <ResourceExplorerChartBaseSelect
            base="cost"
            onChartBaseChange={() => {}}
          />

          <ChartTypeSelect
            selectionType="select"
            size="xsmall"
            availableTypes={resourceExplorerPossibleChartTypes}
            onChartTypeChange={() => {}}
          />
        </Stack>
      </Stack>

      <MultiTypeChart
        highlight
        selectable
        showLoader={false}
        referenceLines={referenceLines}
        yAxisFormatter={yAxisFormatter}
        xAxisTickProps={budgetLineData ? xAxisTickProps : undefined}
        emptyText="No data by your selected date range and filters."
      />
    </Stack>
  );
};

const xAxisTickProps = { parentPadding: { left: 50 } };
