import { Dispatch, FC, SetStateAction, useCallback, useRef } from "react";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { AutocompleteChangeReason } from "@mui/base/useAutocomplete/useAutocomplete";
import Autocomplete, {
  AutocompleteRenderInputParams,
} from "@mui/material/Autocomplete";
import { Box } from "@mui/material";
import { AutocompleteInput, AutocompleteInputProps } from "./AutocompleteInput";
import {
  AutocompleteRenderTags,
  AutocompleteRenderTagsProps,
} from "./AutocompleteRenderTags";
import { DropdownSelectOption } from "../../dropdown-select/utils/types/types";

type AutocompleteInputExtendedProps = Pick<
  AutocompleteInputProps,
  "label" | "error" | "placeholder" | "size"
>;

export interface AutocompleteTriggerProps
  extends AutocompleteRenderTagsProps,
    AutocompleteInputExtendedProps {
  freeSolo?: boolean;
  setExtraOptions: Dispatch<SetStateAction<DropdownSelectOption[]>>;
  options: DropdownSelectOption[];
}

export const AutocompleteTrigger: FC<AutocompleteTriggerProps> = ({
  label,
  triggerProps,
  allSelectedLabel,
  onChange,
  error,
  disabled,
  formatSelectedLabel,
  placeholder,
  RenderTagsComponent,
  freeSolo,
  setExtraOptions,
  singleSelect,
  size = "small",
  options,
  loading,
}) => {
  const ref = useRef<HTMLDivElement | null>(null);

  const {
    open,
    keyword,
    onClick,
    setKeyword,
    selectedValues,
    setSelectedValues,
    onCloseAndSubmit,
  } = triggerProps;

  const openOnTyping = useCallback(
    () => ref.current && !open && onClick({ currentTarget: ref.current }),
    [open, onClick],
  );

  const inputChangeHandler = useCallback(
    (value: string) => {
      if (singleSelect && freeSolo && !value) {
        setSelectedValues([]);
      }

      setKeyword(value);
    },
    [setKeyword, singleSelect, freeSolo, setSelectedValues],
  );

  const renderInput = useCallback(
    (params: AutocompleteRenderInputParams) => (
      <AutocompleteInput
        size={size}
        error={error}
        label={label}
        params={params}
        keyword={
          singleSelect && freeSolo && !keyword ? selectedValues[0] : keyword
        }
        placeholder={placeholder}
        setKeyword={inputChangeHandler}
        openOnTyping={openOnTyping}
      />
    ),
    [
      keyword,
      label,
      error,
      size,
      placeholder,
      openOnTyping,
      inputChangeHandler,
      freeSolo,
      selectedValues,
      singleSelect,
    ],
  );

  const renderTags = useCallback(
    () => (
      <AutocompleteRenderTags
        disabled={disabled}
        onChange={onChange}
        singleSelect={singleSelect}
        triggerProps={triggerProps}
        allSelectedLabel={allSelectedLabel}
        formatSelectedLabel={formatSelectedLabel}
        RenderTagsComponent={RenderTagsComponent}
        loading={loading}
        freeSolo={freeSolo}
      />
    ),
    [
      onChange,
      disabled,
      singleSelect,
      triggerProps,
      allSelectedLabel,
      formatSelectedLabel,
      RenderTagsComponent,
      loading,
      freeSolo,
    ],
  );

  const changeHandler = useCallback(
    (event: any, value: any, reason: AutocompleteChangeReason) => {
      if (reason === "clear") {
        onCloseAndSubmit([]);
        event.preventDefault();
        event.stopPropagation();
      }

      if (!freeSolo || !event.target.value) {
        return;
      }

      if (options.some((item) => item.value === event.target.value)) {
        return;
      }

      if (keyword) {
        setKeyword("");
        setExtraOptions((options) => [
          ...options,
          { value: keyword, label: keyword },
        ]);

        if (singleSelect) {
          setSelectedValues([keyword]);
          onCloseAndSubmit([keyword]);
        } else {
          setSelectedValues((selectedValues: string[]) => [
            ...selectedValues,
            keyword,
          ]);
        }
      }
    },
    [
      onCloseAndSubmit,
      freeSolo,
      singleSelect,
      keyword,
      setSelectedValues,
      setExtraOptions,
      setKeyword,
      options,
    ],
  );

  const icon = open ? (
    <ArrowDropUpIcon color="action" />
  ) : (
    <ArrowDropDownIcon color="action" />
  );

  return (
    <Box onClick={disabled ? undefined : onClick} ref={ref}>
      <Autocomplete
        multiple
        freeSolo={freeSolo}
        open={false}
        disableClearable={!keyword.length}
        disabled={disabled}
        clearOnBlur={singleSelect}
        clearOnEscape={false}
        size={size}
        onChange={changeHandler}
        options={[] as string[]}
        popupIcon={icon}
        value={selectedValues}
        renderTags={renderTags}
        renderInput={renderInput}
        forcePopupIcon={freeSolo}
        sx={{
          "& .MuiOutlinedInput-root.MuiInputBase-root": {
            flexWrap: "nowrap",
          },
          "& input": {
            minWidth: "60px !important",
          },
        }}
      />
    </Box>
  );
};
