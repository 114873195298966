import { resourceExplorerViewByIdSelector } from "./resourceExplorerViewByIdSelector";
import { ResourceExplorer } from "../../../../services/cloudchipr.api";
import { RootState } from "../../../store";

export const resourceExplorerViewDataByIdSelector = (
  state: RootState,
  viewId: string,
): ResourceExplorer | undefined => {
  return resourceExplorerViewByIdSelector(state, viewId)?.data;
};
