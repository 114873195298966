import * as Sentry from "@sentry/react";
import { SnackbarProvider } from "notistack";
import { useFlag } from "@unleash/proxy-client-react";
import { Routes, Route } from "react-router-dom";
import { AppRouter } from "./components/router/AppRouter";
import { UserAbilities } from "./components/UserAbilities";
import { Authentication } from "./components/Authentication";
import { SnackbarAlert } from "./components/pages/common/snackbar-alert/SnackbarAlert";
import { Common } from "./components/Common";
import { MaintenanceMode } from "./components/error-pages/MaintenanceMode";
import { UserPermissionAbilities } from "./components/UserPermissionAbilities";
import { UnauthorizedRoutes } from "./components/router/routes/UnauthorizedRoutes";

export const App = Sentry.withProfiler(() => {
  const enableMaintenanceMode = useFlag("EnableMaintenanceMode");

  if (
    process.env.REACT_APP_MAINTENANCE_MODE === "true" ||
    enableMaintenanceMode
  ) {
    return <MaintenanceMode />;
  }

  return (
    <Routes>
      {UnauthorizedRoutes()}

      <Route
        path="/*"
        element={
          <Authentication>
            <UserAbilities>
              <SnackbarProvider
                maxSnack={4}
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                Components={{ snackbarAlert: SnackbarAlert }}
              >
                <UserPermissionAbilities>
                  <AppRouter />
                  <Common />
                </UserPermissionAbilities>
              </SnackbarProvider>
            </UserAbilities>
          </Authentication>
        }
      />
    </Routes>
  );
});
