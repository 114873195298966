import { FC, Fragment } from "react";
import { AzureMissingRolesDialog } from "./components/AzureMissingRolesDialog";
import { MissingPermissionsAlertButton } from "../common/MissingPermissionsAlertButton";
import { useGetUsersMeProvidersAzureAccountsByAccountIdMissingRolesQuery } from "../../../../../../../../services/cloudchipr.api";
import { useDialog } from "../../../../../../../../utils/hooks/useDialog.hook";

interface LiveUsageMgmtAzureMissingsProps {
  accountId: string;
  servicePrincipal?: string;
  subscriptionId?: string;
}
export const LiveUsageMgmtAzureMissings: FC<
  LiveUsageMgmtAzureMissingsProps
> = ({ accountId, servicePrincipal, subscriptionId }) => {
  const { open, openDialog, closeDialog } = useDialog();

  const { data } =
    useGetUsersMeProvidersAzureAccountsByAccountIdMissingRolesQuery(
      { accountId: accountId || "" },
      { skip: !accountId },
    );

  if (!data?.length) {
    return null;
  }

  return (
    <Fragment>
      <MissingPermissionsAlertButton
        onOpen={openDialog}
        title="Missing Permissions"
      />

      <AzureMissingRolesDialog
        open={open}
        onClose={closeDialog}
        servicePrincipal={servicePrincipal}
        subscriptionId={subscriptionId}
        accountId={accountId}
      />
    </Fragment>
  );
};
