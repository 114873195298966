import { FC, useMemo } from "react";
import { IconButton, Stack, Tooltip } from "@mui/material";
import LinkIcon from "@mui/icons-material/Link";
import { CSVLink } from "react-csv";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import { generateRightsizingRecommendationCsv } from "../../utils/helpers/generateRightsizingRecommendationCsv";
import { RightSizingRecommendation } from "../../../../../../../services/cloudchipr.api";
import { CopyIconButton } from "../../../../../../common/CopyIconButton";

interface RightsizingRecommendationsExportDataCellProps {
  recommendation: RightSizingRecommendation;
}

export const RightsizingRecommendationsExportDataCell: FC<
  RightsizingRecommendationsExportDataCellProps
> = ({ recommendation }) => {
  const filename = `${
    recommendation.dismissed_by ? "Dismissed " : ""
  }Rightsizing Recommendation ${recommendation.resource_name}`;

  const data = useMemo(() => {
    return generateRightsizingRecommendationCsv(recommendation);
  }, [recommendation]);

  return (
    <Stack direction="row" spacing={1}>
      <CSVLink filename={filename} data={data}>
        <Tooltip title="Export CSV" placement="top">
          <IconButton size="small">
            <DownloadOutlinedIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      </CSVLink>

      <CopyIconButton
        data={`${window.location.host}/recommendations/rightsizing/${recommendation.id}?accountId=${recommendation.account.id}`}
      >
        <LinkIcon fontSize="small" />
      </CopyIconButton>
    </Stack>
  );
};
