import { FC, RefObject, useCallback, useMemo } from "react";
import { Box } from "@mui/material";
import { grey } from "@mui/material/colors";
import { AgGridReact } from "@ag-grid-community/react";
import { getRolesDataGridColumns } from "./utils/getRolesDataGridColumns";
import { getOrgCurrentRolesThunk } from "../../../../../../store/user/thunks/roles/getOrgCurrentRolesThunk";
import { useAppDispatch, useAppSelector } from "../../../../../../store/hooks";
import { userOrgCurrentRolesSelector } from "../../../../../../store/user/selectors/roles/userOrgCurrentRolesSelector";

interface RolesDataGridProps {
  refToPassGrid: RefObject<AgGridReact>;
}

export const RolesDataGrid: FC<RolesDataGridProps> = ({ refToPassGrid }) => {
  const dispatch = useAppDispatch();

  const data = useAppSelector(userOrgCurrentRolesSelector);
  const gridReadyHandler = useCallback(async () => {
    dispatch(getOrgCurrentRolesThunk());
  }, [dispatch]);

  const columns = useMemo(() => {
    return getRolesDataGridColumns(refToPassGrid);
  }, [refToPassGrid]);

  return (
    <Box className="ag-theme-quartz" sx={wrapperStyles}>
      <AgGridReact
        enableCellTextSelection
        ref={refToPassGrid}
        rowHeight={58}
        rowData={data}
        columnDefs={columns}
        onGridReady={gridReadyHandler}
      />
    </Box>
  );
};

const columnSortIconStyles = {
  "& .ag-sort-indicator-icon": { visibility: "hidden" },
  "& .ag-header-active:hover .ag-sort-indicator-icon": {
    visibility: "visible",
  },

  "& .ag-header-cell-sorted-desc .ag-sort-indicator-icon": {
    visibility: "visible !important",
  },
  "& .ag-header-cell-sorted-asc .ag-sort-indicator-icon": {
    visibility: "visible !important",
  },

  '& .ag-header-cell[aria-sort="ascending"] .ag-sort-indicator-icon': {
    visibility: "visible !important",
  },
  '& .ag-header-cell[aria-sort="descending"] .ag-sort-indicator-icon': {
    visibility: "visible !important",
  },
};

const rowHoverStyles = {
  "--ag-active-color": grey[100],
  "& .ag-row-hover": { backgroundColor: grey[100] },
};

const resizeHandle = {
  "& .ag-header-cell-resize": { width: 20, visibility: "hidden" },
  "& .ag-header-cell:hover .ag-header-cell-resize": { visibility: "visible" },
  "--ag-header-column-resize-handle-height": "80%",
};

const wrapperStyles = {
  height: "100%",
  width: "100%",
  "& .ag-cell-wrapper": { height: "100%" },
  "& .ag-root-wrapper": { borderRadius: 0, border: "none" },
  "--ag-header-height": "56px",
  "--ag-header-background-color": grey[100],

  ...columnSortIconStyles,
  ...rowHoverStyles,
  ...resizeHandle,
};
