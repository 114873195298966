import { ChartDataType, ChartType, ReferenceLineType } from "../types/types";
import { getNumberByPercentage } from "../../../../../utils/numeral/getPercentage";

export const getYAxisMaxValue = (
  data: ChartDataType[],
  chartType: ChartType,
  referenceLines?: ReferenceLineType[],
) => {
  const maxReferenceLine =
    referenceLines?.reduce((max, line) => {
      const value = line?.y && +line?.y ? +line?.y : 0;

      return Math.max(max, value);
    }, 0) ?? 0;

  const maxDataValue = data?.reduce((max, data) => {
    const maxValue: number = +(
      Object.values(data).reduce((result, val) => {
        if (typeof val !== "number" || typeof result !== "number") {
          return result;
        }

        if (chartType === "stack") {
          return +result + val;
        }

        return Math.max(+result, val);
      }, 0) ?? 0
    );

    return Math.max(max, maxValue);
  }, 0);

  const minDataValue = data?.reduce((max, data) => {
    const maxValue: number = +(
      Object.values(data).reduce((result, val) => {
        if (typeof val !== "number" || typeof result !== "number") {
          return result;
        }

        return Math.min(+result, val);
      }, 0) ?? 0
    );

    return Math.min(max, maxValue);
  }, 0);

  const maxValue = Math.max(maxReferenceLine, maxDataValue);
  const percentage = getNumberByPercentage(10, maxValue);

  return {
    maxDataValue: Math.ceil(percentage + maxValue),
    minDataValue: generateMinValue(minDataValue),
  };
};

const generateMinValue = (minDataValue: number) => {
  const positiveMin = minDataValue < 1 && minDataValue > 0 ? 0 : minDataValue;
  const negativeMin = minDataValue > -1 && minDataValue < 0 ? -1 : minDataValue;

  return Math.abs(minDataValue) === minDataValue ? positiveMin : negativeMin;
};
