import { Box, Button, Stack, Tab, Tabs, Typography } from "@mui/material";
import { FC, useCallback, useState } from "react";
import { GcpConsoleTab } from "./missing-roles-dialog-tabs/console-tab/GcpConsoleTab";
import {
  GcpCliTab,
  GcpCliTabProps,
} from "./missing-roles-dialog-tabs/cli-tab/GcpCliTab";
import { MessagesDialog } from "../../../common/MessagesDialog";
import { useGetUsersMeProvidersGcpAccountsByAccountIdMissingRolesQuery } from "../../../../../../../../../../services/cloudchipr.api";

type MissingRolesTabType = "console" | "cli";

interface GcpMissingRolesDialogProps extends GcpCliTabProps {
  open: boolean;
  onClose(): void;
}

export const GcpMissingRolesDialog: FC<GcpMissingRolesDialogProps> = ({
  open,
  onClose,
  accountId,
  serviceAccount,
  projectId,
}) => {
  const [tab, setTab] = useState<MissingRolesTabType>("console");

  const { data } =
    useGetUsersMeProvidersGcpAccountsByAccountIdMissingRolesQuery(
      { accountId: accountId || "" },
      { skip: !accountId },
    );

  const handleTabChange = useCallback(
    (_: unknown, value: MissingRolesTabType) => {
      setTab(value);
    },
    [],
  );

  if (!data?.length) {
    return null;
  }

  return (
    <MessagesDialog
      name="Permissions"
      open={open}
      onClose={onClose}
      width="md"
      actions={
        <Button color="tertiary" onClick={onClose}>
          Close
        </Button>
      }
    >
      <Stack spacing={3}>
        <Typography fontWeight="medium" variant="body1">
          Choose preferred assign method
        </Typography>

        <Box sx={{ borderBottom: 1, borderColor: "divider" }} my={2}>
          <Tabs variant="fullWidth" onChange={handleTabChange} value={tab}>
            <Tab label="Run through GCP Console" value="console" />
            <Tab label="Run through CLI" value="cli" />
          </Tabs>
        </Box>

        {tab === "console" && (
          <GcpConsoleTab
            accountId={accountId}
            serviceAccount={serviceAccount}
          />
        )}
        {tab === "cli" && (
          <GcpCliTab
            serviceAccount={serviceAccount}
            projectId={projectId}
            accountId={accountId}
          />
        )}
      </Stack>
    </MessagesDialog>
  );
};
