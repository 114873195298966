import { FC, useMemo } from "react";
import { Stack } from "@mui/material";
import { ReservationsMultiTypeChartMetadata } from "./ReservationsMultiTypeChartMetadata";
import { useAppSelector } from "../../../../../../../../store/hooks";
import { formatDateByFrequencyType } from "../../../../../../../../utils/helpers/date-time/datetime-format";
import { commitmentsDateGranularitySelector } from "../../../../../../../../store/commitments/selectors/common/view-options/commitmentsDateGranularitySelector";
import { CommitmentsChart } from "../../../../../common/components/chart/CommitmentsChart";
import { commitmentsChartTypeSelector } from "../../../../../../../../store/commitments/selectors/common/view-options/commitmentsChartTypeSelector";
import { CommitmentsDateGranularity } from "../../../../../common/components/options/date-granularity/CommitmentsDateGranularity";
import { CommitmentsChartTypeSelect } from "../../../../../common/components/chart/CommitmentsChartTypeSelect";
import { reservationsForGranularLoadingSelector } from "../../../../../../../../store/commitments/selectors/reservations/data/loading/reservationsForGranularLoadingSelector";
import { reservationsGranularDataGroupedByDateSelector } from "../../../../../../../../store/commitments/selectors/reservations/data/granular/reservationsGranularDataGroupedByDateSelector";

export const ReservationsMultiTypeChart: FC = () => {
  const chartType = useAppSelector(commitmentsChartTypeSelector);
  const loading = useAppSelector(reservationsForGranularLoadingSelector);
  const dataPoint = useAppSelector(commitmentsDateGranularitySelector);
  const dataGroupedByDate = useAppSelector(
    reservationsGranularDataGroupedByDateSelector,
  );

  const chartProps = useMemo(() => {
    return {
      loading,
      dataGroupedByDate,
      labelFormatter: (value: string) =>
        formatDateByFrequencyType(dataPoint, value),
    };
  }, [dataPoint, dataGroupedByDate, loading]);

  if (chartType === "gauge") {
    return null;
  }

  return (
    <Stack>
      <ReservationsMultiTypeChartMetadata />

      <Stack
        direction="row"
        alignItems="center"
        justifyContent="end"
        spacing={1}
      >
        <CommitmentsDateGranularity />

        <CommitmentsChartTypeSelect />
      </Stack>

      {chartType === "bar" ? (
        <CommitmentsChart key="bar" availableTypes={["bar"]} {...chartProps} />
      ) : (
        <CommitmentsChart
          key="area"
          availableTypes={["area"]}
          {...chartProps}
        />
      )}
    </Stack>
  );
};
