import { FC } from "react";
import { Typography } from "@mui/material";

interface UsersRequestTableToolbarProps {
  dataCount?: number;
}
export const UsersRequestTableHeader: FC<UsersRequestTableToolbarProps> = ({
  dataCount,
}) => {
  return (
    <Typography variant="body1" fontWeight="medium" py={1}>
      Requests{" "}
      <Typography
        color="text.secondary"
        component="span"
        variant="inherit"
        fontWeight="400"
      >
        ({dataCount})
      </Typography>
    </Typography>
  );
};
