function TaskPropertyPriorityIcon() {
  return (
    <svg
      width="30"
      height="31"
      viewBox="0 0 30 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect y="0.5" width="30" height="30" rx="8" fill="#70B59C" />
      <path
        d="M14.8833 10.0832L15.2167 11.7498H19.5833V16.7498H16.7833L16.45 15.0832H10.4167V10.0832H14.8833ZM16.25 8.4165H8.75V22.5832H10.4167V16.7498H15.0833L15.4167 18.4165H21.25V10.0832H16.5833L16.25 8.4165Z"
        fill="white"
      />
    </svg>
  );
}

export default TaskPropertyPriorityIcon;
