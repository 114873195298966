import { FC, ReactNode, useCallback } from "react";
import { ListItemText, MenuItem } from "@mui/material";
import { DismissPeriod } from "../../../../../../services/cloudchipr.api";

interface DismissMenuOptionProps {
  value: DismissPeriod;
  onClick(value: DismissPeriod): void;
  children?: ReactNode;
}

export const DismissMenuOption: FC<DismissMenuOptionProps> = ({
  children,
  onClick,
  value,
}) => {
  const clickHandler = useCallback(() => {
    onClick(value);
  }, [value, onClick]);

  return (
    <MenuItem color="text.primary" onClick={clickHandler}>
      <ListItemText>{children}</ListItemText>
    </MenuItem>
  );
};
