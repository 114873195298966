import { FilterSet } from "../../../../../../services/cloudchipr.api";

export const generateCopiedFromDefaultFilterTemplateName = (
  templates?: FilterSet[],
  filterTemplateName?: string,
) => {
  const newName = `${filterTemplateName} (copy)`;
  const similarNameFilterTemplates = templates?.filter((template) =>
    template.name.includes(newName),
  );

  if (!similarNameFilterTemplates?.length) {
    return newName;
  }

  return `${newName} ${similarNameFilterTemplates.length + 1}`;
};
