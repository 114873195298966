import { Stack, Tooltip, Typography } from "@mui/material";
import StarsIcon from "@mui/icons-material/Stars";
import { ColumnSetupType } from "../../../../../../../../storybook/data-grid/utils/types/types";
import {
  SavingsPlanCoverageGrouping,
  CommitmentResourceType,
} from "../../../../../../../../services/cloudchipr.api";
import { money } from "../../../../../../../../utils/numeral/money";
import { CommitmentsProgressBar } from "../../../../../common/components/CommitmentsProgressBar";
import { ReservationsCoverageDataWithId } from "../../../../../common/utils/types";
import { CommitmentsTableName } from "../../../../../common/components/data-grid/cells/CommitmentsTableName";
import { HeaderCellWidthDateTooltip } from "../../../../../common/components/data-grid/cells/HeaderCellWidthDateTooltip";
import { commitmentsResourceTypeNames } from "../../../../../common/utils/constants/labels";
import { TypographyWithTooltip } from "../../../../../../../common/TypographyWithTooltip";
import { ReservationsColumnsHeaderCellWithTotalAndDate } from "../components/cells/ReservationsColumnsHeaderCellWithTotalAndDate";

const groupedByAccountColumns: ColumnSetupType<ReservationsCoverageDataWithId>[] =
  [
    {
      minSize: 100,
      accessorKey: "account.name" as keyof ReservationsCoverageDataWithId,
      header: "Account",
      meta: { hideFromSettings: true },
      cell: ({ row }) => {
        return (
          <CommitmentsTableName
            type="account"
            id={row.original.id}
            name={
              <Typography variant="body2" align="left" component="div">
                <Stack direction="row" spacing={0.5}>
                  {row.original.account?.is_management_account && (
                    <Tooltip title="Management account" placement="top" arrow>
                      <StarsIcon color="primary" fontSize="small" />
                    </Tooltip>
                  )}

                  <TypographyWithTooltip
                    variant="body2"
                    title={row.original.account?.name}
                  />
                </Stack>

                <TypographyWithTooltip
                  variant="body2"
                  title={row.original.account?.provider_account_id}
                />
              </Typography>
            }
          />
        );
      },
    },
  ];

const groupedByResourceColumns: ColumnSetupType<ReservationsCoverageDataWithId>[] =
  [
    {
      minSize: 140,
      id: "resource_type",
      accessorKey:
        "resource_type.resource_type" as keyof ReservationsCoverageDataWithId,
      header: "Resource Type",
      meta: { hideFromSettings: true },
      cell: ({ row }) => {
        const rt = row.original.resource_type
          ?.resource_type as CommitmentResourceType;

        return (
          <CommitmentsTableName
            type="resource_type"
            id={row.original.id}
            name={
              <TypographyWithTooltip
                title={commitmentsResourceTypeNames[rt]}
                variant="body2"
                align="left"
              />
            }
          />
        );
      },
    },
    {
      minSize: 130,
      id: "instance_type",
      accessorKey:
        "resource_type.instance_type" as keyof ReservationsCoverageDataWithId,
      header: "Instance Type",
      cell: ({ row }) => {
        return row.original.resource_type?.instance_type ?? "-";
      },
    },
    {
      minSize: 100,
      id: "platform",
      accessorKey:
        "resource_type.platform" as keyof ReservationsCoverageDataWithId,
      header: "Platform",
      cell: ({ row }) => (
        <TypographyWithTooltip
          variant="inherit"
          title={row.original.resource_type?.platform ?? "-"}
        />
      ),
    },

    {
      minSize: 110,
      id: "region",
      accessorKey:
        "resource_type.region" as keyof ReservationsCoverageDataWithId,
      header: "Region/AZ",
      cell: ({ row }) => {
        return row.original.resource_type?.region ?? null;
      },
    },
  ];

const columnsWithoutGrouping: ColumnSetupType<ReservationsCoverageDataWithId>[] =
  [
    {
      minSize: 150,
      accessorKey: "covered_hours",
      header: () => (
        <ReservationsColumnsHeaderCellWithTotalAndDate
          title="Covered Hours"
          type="coveredHours"
          alignItems="start"
        />
      ),
      cell: ({ row }) => {
        return (
          // height 45 is for virtualization
          <Stack justifyContent="center" height={45}>
            <Typography variant="body2">
              {row.original.covered_hours}h
            </Typography>
          </Stack>
        );
      },
      meta: { headerTitle: "Covered Hours" },
    },

    {
      minSize: 220,
      accessorKey: "covered_usage",
      header: () => (
        <ReservationsColumnsHeaderCellWithTotalAndDate
          title="Spend Covered by RIs"
          type="coveredUsage"
        />
      ),

      cell: ({ row }) => (
        <Typography align="right" pr={4} variant="body2">
          {money(row.original.covered_usage)}
        </Typography>
      ),
      meta: { headerTitle: "Spend Covered by RIs" },
    },

    {
      minSize: 210,
      accessorKey: "uncovered_usage",
      header: () => (
        <ReservationsColumnsHeaderCellWithTotalAndDate
          type="unCoveredUsage"
          title="Not Covered (On-Demand)"
        />
      ),
      meta: { headerTitle: "Not Covered (On-Demand)" },
      cell: ({ row }) => (
        <Typography align="right" pr={4} variant="body2">
          {money(row.original.uncovered_usage)}
        </Typography>
      ),
    },

    {
      minSize: 200,
      accessorKey: "net_savings",
      header: () => (
        <ReservationsColumnsHeaderCellWithTotalAndDate
          title="Net Savings"
          type="netSavings"
        />
      ),
      cell: ({ row }) => (
        <Typography align="right" pr={4} variant="body2">
          {money(row.original.net_savings)}
        </Typography>
      ),
      meta: { headerTitle: "Net Savings" },
    },
    {
      enableResizing: false,
      minSize: 150,
      accessorKey: "coverage",
      meta: { headerTitle: "Coverage" },
      header: () => <HeaderCellWidthDateTooltip title="Coverage" />,
      cell: ({ row }) => (
        <CommitmentsProgressBar value={row.original.coverage} />
      ),
    },
  ];

const accountGroupedColumns = [
  ...groupedByAccountColumns,
  ...columnsWithoutGrouping,
];
const resourceGroupedColumns = [
  ...groupedByResourceColumns,
  ...columnsWithoutGrouping,
];

export const reservationCoverageTableColumns = (
  grouping: SavingsPlanCoverageGrouping,
): ColumnSetupType<ReservationsCoverageDataWithId>[] => {
  if (grouping === "account") {
    return accountGroupedColumns;
  }

  if (grouping === "resource_type") {
    return resourceGroupedColumns;
  }

  return [];
};
