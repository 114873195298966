import { SvgIcon, SvgIconProps, useTheme, colors } from "@mui/material";

const AzVmssSvgIcon = ({ primary }: SvgIconProps & { primary?: boolean }) => {
  const { palette } = useTheme();

  const colorDark = primary ? palette.primary.dark : colors.grey["800"];
  const color = primary ? palette.primary.main : colors.grey["600"];
  const colorLight = primary ? colors.indigo["200"] : colors.grey["400"];

  return (
    <SvgIcon>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M17.7627 1.20508H1.14536C0.814724 1.20508 0.546692 1.47311 0.546692 1.80374V12.4997C0.546692 12.8304 0.814724 13.0984 1.14536 13.0984H17.7627C18.0933 13.0984 18.3614 12.8304 18.3614 12.4997V1.80374C18.3614 1.47311 18.0933 1.20508 17.7627 1.20508Z"
          fill={colorDark}
        />
        <path
          d="M20.4867 3.62695H3.86936C3.53872 3.62695 3.27069 3.89499 3.27069 4.22562V14.9216C3.27069 15.2523 3.53872 15.5203 3.86936 15.5203H20.4867C20.8173 15.5203 21.0854 15.2523 21.0854 14.9216V4.22562C21.0854 3.89499 20.8173 3.62695 20.4867 3.62695Z"
          fill={color}
        />
        <path
          d="M22.856 6.39258H6.23868C5.90805 6.39258 5.64001 6.66061 5.64001 6.99124V17.6872C5.64001 18.0179 5.90805 18.2859 6.23868 18.2859H22.856C23.1867 18.2859 23.4547 18.0179 23.4547 17.6872V6.99124C23.4547 6.66061 23.1867 6.39258 22.856 6.39258Z"
          fill={colorLight}
        />
        <path
          d="M18.0707 21.7896C16.308 21.5083 16.308 20.239 16.308 18.2656H12.784C12.784 20.3096 12.784 21.5776 11.0227 21.7896C10.7813 21.8268 10.5606 21.9478 10.3995 22.1314C10.2384 22.315 10.147 22.5494 10.1414 22.7936H18.952C18.9464 22.5494 18.855 22.315 18.6939 22.1314C18.5327 21.9478 18.3121 21.8268 18.0707 21.7896Z"
          fill={color}
        />
        <path
          d="M14.336 16.1925C16.5584 16.1925 18.36 14.3909 18.36 12.1685C18.36 9.94614 16.5584 8.14453 14.336 8.14453C12.1136 8.14453 10.312 9.94614 10.312 12.1685C10.312 14.3909 12.1136 16.1925 14.336 16.1925Z"
          fill={colorDark}
        />
        <path
          d="M16.704 10.8574V13.6028L14.332 14.9814V12.2321L16.704 10.8574Z"
          fill="#E0E0E0"
        />
        <path
          d="M16.704 10.8566L14.332 12.2352L11.96 10.8552L14.332 9.47656L16.704 10.8566Z"
          fill="#F5F5F5"
        />
        <path
          d="M14.3326 12.2353V14.9803L11.9534 13.5911L11.9626 10.8516L14.3326 12.2353Z"
          fill="#EEEEEE"
        />
        <path
          d="M17.52 9.08075C17.4593 9.02066 17.3774 8.98695 17.292 8.98695C17.2066 8.98695 17.1247 9.02066 17.064 9.08075C17.0025 9.14312 16.9681 9.22718 16.9681 9.31475C16.9681 9.40233 17.0025 9.48639 17.064 9.54875C17.6867 10.1734 18.0756 10.9932 18.1656 11.8706C18.2555 12.7481 18.041 13.6297 17.5579 14.3677C17.0749 15.1057 16.3528 15.6552 15.5127 15.9239C14.6726 16.1926 13.7656 16.1642 12.944 15.8435C12.1223 15.5228 11.4359 14.9293 11 14.1626C10.564 13.3958 10.405 12.5025 10.5496 11.6324C10.6942 10.7623 11.1336 9.96841 11.7942 9.38389C12.4547 8.79937 13.2961 8.45978 14.1773 8.42209L14.1307 8.67409C14.1274 8.69535 14.1297 8.71711 14.1375 8.73719C14.1452 8.75726 14.1581 8.77494 14.1749 8.78845C14.1916 8.80196 14.2116 8.81083 14.2329 8.81416C14.2542 8.81749 14.2759 8.81516 14.296 8.80742L15.536 8.33675C15.563 8.32646 15.5868 8.30921 15.605 8.28674C15.6232 8.26428 15.6352 8.23741 15.6396 8.20885C15.6441 8.18029 15.6409 8.15106 15.6305 8.12412C15.62 8.09717 15.6026 8.07347 15.58 8.05542L14.5333 7.24475C14.5162 7.23148 14.4959 7.22292 14.4745 7.21993C14.453 7.21695 14.4311 7.21963 14.411 7.22773C14.391 7.23582 14.3733 7.24903 14.3599 7.26606C14.3465 7.28308 14.3378 7.30332 14.3347 7.32475L14.272 7.76609C13.2344 7.78162 12.2341 8.15549 11.4407 8.82433C10.6473 9.49316 10.1095 10.4158 9.9187 11.4358C9.72785 12.4558 9.89562 13.5105 10.3936 14.4209C10.8915 15.3314 11.689 16.0416 12.6508 16.4312C13.6126 16.8208 14.6795 16.8659 15.6707 16.5587C16.6619 16.2515 17.5164 15.6109 18.0893 14.7457C18.6621 13.8804 18.9181 12.8437 18.8139 11.8112C18.7097 10.7787 18.2516 9.81405 17.5173 9.08075H17.52Z"
          fill="white"
        />
      </svg>
    </SvgIcon>
  );
};

AzVmssSvgIcon.muiName = "AzVmssSvgIcon";

export default AzVmssSvgIcon;
