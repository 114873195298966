import { FC } from "react";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { DialogTitle, Typography } from "@mui/material";
import { useAppSelector } from "../../../../../store/hooks";
import { pollingSelector } from "../../../../../store/account-setup/selectors/pollingSelector";
import { accountAttemptSelector } from "../../../../../store/account-setup/selectors/accountAttemptSelector";
import { currentStepSelector } from "../../../../../store/account-setup/selectors/currentStepSelector";
import { providerSelector } from "../../../../../store/account-setup/selectors/providerSelector";

interface AccountSetupDialogTitleProps {
  onClose?(): void;
}

export const AccountSetupDialogTitle: FC<AccountSetupDialogTitleProps> = ({
  onClose,
}) => {
  const currentStep = useAppSelector(currentStepSelector);
  const provider = useAppSelector(providerSelector);
  const accountAttempt = useAppSelector(accountAttemptSelector);
  const polling = useAppSelector(pollingSelector);
  const title = titlesByProvider.get(provider);

  const showCloseButton =
    onClose &&
    (accountAttempt?.status === "failed" || currentStep === 0 || !polling);

  return (
    <DialogTitle>
      <Typography variant="body1" fontWeight="medium">
        {title}
      </Typography>
      {showCloseButton && (
        <IconButton
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
      )}
    </DialogTitle>
  );
};

const titlesByProvider = new Map([
  ["aws", "Add AWS Account"],
  ["gcp", "Add GCP Connection"],
  ["azure", "Add Azure Subscription"],
]);
