import { tasksListGridDataSelector } from "./tasksListGridDataSelector";
import { RootState } from "../../../store";
import { Task } from "../../../../services/cloudchipr.api";

export const tasksListDataByIdSelector = (
  state: RootState,
  id?: string,
): Task | undefined => {
  const tasks = tasksListGridDataSelector(state);

  return tasks?.find((task) => task.id === id);
};
