import { FC } from "react";
import { useAppSelector } from "../../../../../../../../../store/hooks";
import { WidgetTotalsSummary } from "../../../../../widgets/common/WidgetTotalsSummary";
import { costBreakdownSetupPropertyByKeySelector } from "../../../../../../../../../store/dashboards/selectors/setups/cost-breakdown/costBreakdownSetupPropertyByKeySelector";
import { costBreakdownSetupVisualizationPropertyByKeySelector } from "../../../../../../../../../store/dashboards/selectors/setups/cost-breakdown/costBreakdownSetupVisualizationPropertyByKeySelector";
import { costBreakdownWidgetSetupCostsSelector } from "../../../../../../../../../store/dashboards/selectors/widgets/cost-breakdown/setup/costBreakdownWidgetSetupCostsSelector";

export const CostBreakdownWidgetSetupContentCosts: FC = () => {
  const dates = useAppSelector(
    costBreakdownSetupPropertyByKeySelector("dates"),
  );
  const costsData = useAppSelector(costBreakdownWidgetSetupCostsSelector);
  const showTrends = useAppSelector(
    costBreakdownSetupVisualizationPropertyByKeySelector("trend"),
  );

  const frequency = useAppSelector(
    costBreakdownSetupPropertyByKeySelector("frequency"),
  );

  const forecastOption = useAppSelector(
    costBreakdownSetupPropertyByKeySelector("forecastOption"),
  );

  const aggregations = useAppSelector(
    costBreakdownSetupPropertyByKeySelector("aggregation"),
  );

  if (!costsData) {
    return null;
  }

  return (
    <WidgetTotalsSummary
      frequency={frequency}
      costsData={costsData}
      forecastOption={forecastOption}
      aggregations={aggregations}
      dateLabel={dates.label}
      showTrends={showTrends}
    />
  );
};
