import { JSONTransformer } from "@atlaskit/editor-json-transformer";
import { JIRATransformer } from "@atlaskit/editor-jira-transformer";
import { defaultSchema } from "@atlaskit/adf-schema/schema-default";

const adfTransformer = new JSONTransformer();
const jiraTransformer = new JIRATransformer(defaultSchema);

export const htmlToJiraADF = (html: string, toString?: boolean) => {
  // source: https://codesandbox.io/s/html-to-adf-to-html-y07eqv?file=/src/App.js
  html = replaceHtmlTags(html);

  const adfText = jiraTransformer.parse(html);
  const endCoded = adfTransformer.encode(adfText);

  return toString ? JSON.stringify(endCoded) : endCoded;
};

const replaceHtmlTags = (html: string) => {
  const tags = [
    { from: /<strong >/g, to: "<b>" },
    { from: /<strong>/g, to: "<b>" },
    { from: /<\/strong>/g, to: "</b>" },

    { from: /<div >/g, to: "<p>" },
    { from: /<div>/g, to: "<p>" },
    { from: /<\/div>/g, to: "</p>" },

    { from: /<br>/g, to: "<br />" },
    { from: /<br >/g, to: "<br />" },
  ];

  for (const tag of tags) {
    html = html.replaceAll(tag.from, tag.to);
  }

  return html;
};
