import { FC } from "react";
import { Stack } from "@mui/material";
import { AutomationProviderSelection } from "./AutomationProviderSelection";
import { AutomationAccountsSelection } from "./AutomationAccountsSelection";
import { AutomationRegionsSelection } from "./AutomationRegionsSelection";

export const AutomationEnvironmentContent: FC = () => {
  return (
    <Stack spacing={2}>
      <AutomationProviderSelection />

      <AutomationAccountsSelection />

      <AutomationRegionsSelection />
    </Stack>
  );
};
