import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  cloudChiprApi,
  OffHoursSchedule,
  ScheduleStatus,
} from "../../../services/cloudchipr.api";
import {
  generateOffHoursUpdatingData,
  getOffHoursUpdatePayloadData,
} from "../../../components/pages/schedule/off-hours/utils/helpers/helpers";

export const offHoursPatchFixedCacheKey =
  "patchUsersMeOffHoursSchedulesByScheduleId-fixed-cache-key";

export const toggleOffHoursStatusThunk = createAsyncThunk(
  "offHours/toggleScheduleStatus",
  async (
    {
      schedule,
      status,
    }: {
      schedule: OffHoursSchedule;
      status: ScheduleStatus;
    },
    { dispatch },
  ) => {
    const {
      patchUsersMeOffHoursSchedulesByScheduleId,
      getUsersMeOffHoursSchedules,
    } = cloudChiprApi.endpoints;

    const payloadData = getOffHoursUpdatePayloadData(
      generateOffHoursUpdatingData({ ...schedule, status }),
    );

    await dispatch(
      patchUsersMeOffHoursSchedulesByScheduleId.initiate(payloadData, {
        fixedCacheKey: offHoursPatchFixedCacheKey,
      }),
    ).unwrap();
    await dispatch(
      getUsersMeOffHoursSchedules.initiate({}, { forceRefetch: true }),
    );
  },
);
