import { FC, Fragment } from "react";
import {
  Card,
  CardContent,
  CardHeader,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";

export const PreviewDataGridSkeleton: FC = () => {
  return (
    <Fragment>
      {CardSkeleton}
      {CardSkeleton}
      {CardSkeleton}
    </Fragment>
  );
};

const SkeletonRow = (
  <Stack direction="row" justifyContent="space-between">
    <Skeleton width="15%" height={50} />
    <Skeleton width="15%" height={50} />
    <Skeleton width="15%" height={50} />
    <Skeleton width="15%" height={50} />
    <Skeleton width="15%" height={50} />
    <Skeleton width="15%" height={50} />
  </Stack>
);

const CardSkeleton = (
  <Card>
    <CardHeader
      sx={{ pb: 0 }}
      avatar={<Skeleton width={150} height={30} />}
      action={<Skeleton variant="circular" width={30} height={30} />}
    />
    <CardContent sx={{ py: 0 }}>
      <Typography variant="caption">
        <Skeleton width="100%" height={50} />
      </Typography>

      {SkeletonRow}
      {SkeletonRow}
      {SkeletonRow}
    </CardContent>
  </Card>
);
