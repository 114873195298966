import { ForecastOption } from "../../../../../../services/cloudchipr.api";

export const getForecastedTrendTooltipTitle = (
  currentDatePeriod: string,
  previousDatesPeriod: string,
  forecastedOption: ForecastOption,
) => {
  const label = new Map<ForecastOption, { long: string; short: string }>([
    ["month", { long: "Forecasted Month-end Cost", short: "month" }],
    ["quarter", { long: "Forecasted Quarter-end Cost", short: "quarter" }],
  ]);

  return `${
    label.get(forecastedOption)?.long
  } (${currentDatePeriod}) compared with last ${
    label.get(forecastedOption)?.short
  } (${previousDatesPeriod}).`;
};
