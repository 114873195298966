import { FC, memo } from "react";
import { IconButton } from "@mui/material";
import ImportExportIcon from "@mui/icons-material/ImportExport";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import { SortDirection } from "@tanstack/react-table";
import CloseIcon from "@mui/icons-material/Close";
import Stack from "@mui/material/Stack";

interface TableHeaderCellSortIconsProps {
  sortedBy: false | SortDirection;
  canSort: boolean;
  hover: boolean;
  onSort?(event: unknown): void;
  onClearSort?(event: unknown): void;
}

export const TableHeaderCellSortIcons: FC<TableHeaderCellSortIconsProps> = memo(
  ({ canSort, sortedBy, hover, onClearSort, onSort }) => {
    if (!canSort) {
      return null;
    }

    return (
      <Stack direction="row" spacing={0.5} ml={1}>
        <IconButton sx={{ p: 0.25 }} size="small" onClick={onSort}>
          {!sortedBy && (
            <ImportExportIcon
              color="disabled"
              fontSize="small"
              opacity={canSort && hover ? 1 : 0}
            />
          )}

          {sortedBy === "asc" && (
            <ArrowDownwardIcon color="disabled" fontSize="small" />
          )}
          {sortedBy === "desc" && (
            <ArrowUpwardIcon color="disabled" fontSize="small" />
          )}
        </IconButton>

        {sortedBy && onClearSort && (
          <IconButton sx={{ p: 0.25 }} size="small" onClick={onClearSort}>
            <CloseIcon fontSize="small" color="disabled" />
          </IconButton>
        )}
      </Stack>
    );
  },
);
