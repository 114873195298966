import { RootState } from "../../../../store";
import { cloudChiprApi } from "../../../../../services/cloudchipr.api";
import { userRoleCreationFixedCacheKey } from "../../../thunks/roles/createCustomUserRoleThunk";

const selector =
  cloudChiprApi.endpoints.postUsersMeOrganisationsCurrentRoles.select;

export const userRoleCreationLoadingSelector = (state: RootState) => {
  return selector(userRoleCreationFixedCacheKey)(state)?.isLoading;
};
