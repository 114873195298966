import { getUsersMeOrganisationsCurrentDashboardsByDashboardIdSelector } from "./getUsersMeOrganisationsCurrentDashboardsByDashboardIdSelector";
import { currentDashboardIdSelector } from "./currentDashboardIdSelector";
import { GetUsersMeOrganisationsCurrentDashboardsByDashboardIdApiResponse } from "../../../../services/cloudchipr.api";
import { RootState } from "../../../store";

export const currentDashboardSelector = (
  state: RootState,
): GetUsersMeOrganisationsCurrentDashboardsByDashboardIdApiResponse | null => {
  const dashboardId = currentDashboardIdSelector(state);

  if (!dashboardId) {
    return null;
  }
  return (
    getUsersMeOrganisationsCurrentDashboardsByDashboardIdSelector({
      dashboardId,
    })(state)?.data || null
  );
};
