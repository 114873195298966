import { metricsChartValueGenerator } from "./metricsChartValueGenerator";
import { getMetricsByType } from "../../../cells/metrics-cells/utils/helpers/getMetricsByType";
import { ConvertorFnsType } from "../types";
import { MetricsFrequenciesKeysType } from "../../types/metricsFrequenciesType";
import {
  ComputeInlineMetrics,
  DatabaseInlineMetrics,
  DataStorageInlineMetrics,
} from "../../../../../../../../../../../../services/cloudchipr.api";
import { getMetricsTypeAndOptionById } from "../../data-grid/constants";

export const getMetricsGeneratorFn = (
  frequencyKey: MetricsFrequenciesKeysType,
): ConvertorFnsType => {
  const { type, option } = getMetricsTypeAndOptionById.get(frequencyKey) ?? {};

  return (key, resource, options) => {
    if (!type || !option) {
      return "N/A";
    }

    const { metricsFrequencies } = options ?? {};
    const duration = metricsFrequencies?.[frequencyKey] ?? "";
    const data = (
      key in resource ? resource[key] : undefined
    ) as MetricDataType;

    if (!data) {
      return "N/A";
    }

    const metrics = getMetricsByType(data, type);
    const value = metricsChartValueGenerator(duration, option, metrics);

    if (option === "sum") {
      return value;
    }

    return value === "N/A" ? value : `${value} %`;
  };
};

type MetricDataType =
  | DatabaseInlineMetrics
  | DataStorageInlineMetrics
  | ComputeInlineMetrics
  | undefined;
