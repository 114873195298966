import { FC } from "react";
import { FormikErrors, FormikHandlers, FormikTouched } from "formik";
import { Stack } from "@mui/material";
import { IntegrationTypes } from "./integration-type/IntegrationTypes";
import { IntegrationType } from "./integration-type/IntegrationTypeCard";
import { IntegrationFormHeaderField } from "./IntegrationFormHeaderField";
import { WebhookForm } from "../webhook/WebhookForm";
import { EmailForm } from "../email/EmailForm";
import { JiraForm } from "../jira/jira-form/JiraForm";
import { Integration } from "../../../../../../../services/cloudchipr.api";
import { SlackForm } from "../slack/components/SlackForm";

interface IntegrationFormProps {
  id: string;
  typeChangeDisabled: boolean;
  values: Integration;
  errors: FormikErrors<Integration>;
  touched: FormikTouched<Integration>;
  handleChange: FormikHandlers["handleChange"];
  setFieldValue(key: string, value: any): void;
  onTypeChange(type: IntegrationType): void;
  disabledIntegrationTypes?: IntegrationType[];
  workspaceId?: string | null;
}

export const IntegrationForm: FC<IntegrationFormProps> = ({
  typeChangeDisabled,
  values,
  errors,
  touched,
  handleChange,
  setFieldValue,
  onTypeChange,
  id,
  disabledIntegrationTypes,
  workspaceId,
}) => {
  return (
    <Stack spacing={4} flex={1}>
      <IntegrationTypes
        onChange={onTypeChange}
        activeType={values.type}
        disabled={!!values.id || typeChangeDisabled}
        disabledIntegrationTypes={disabledIntegrationTypes}
      />

      {values.type && (
        <IntegrationFormHeaderField
          type={values.type}
          value={values.name}
          handleChange={handleChange}
          error={touched.name ? errors.name : ""}
        />
      )}

      {values.type === "slack" && (
        <SlackForm
          id={id}
          workspaceId={workspaceId}
          type={values.type}
          name={values.name}
          conversations={values.conversations}
          defaultConversation={values.conversation}
          setFieldValue={setFieldValue}
        />
      )}

      {values.type === "email" && (
        <EmailForm
          emails={values.emails || []}
          setFieldValue={setFieldValue}
          error={touched.emails && errors.emails}
        />
      )}

      {values.type === "webhook" && (
        <WebhookForm
          url={values.url || ""}
          handleChange={handleChange}
          urlError={touched.url && errors.url}
          contentType={values.content_type || ""}
          contentTypeError={touched.content_type && errors.content_type}
        />
      )}

      {values.type === "jira" && (
        <JiraForm
          integrationId={values.id}
          url={values.url}
          api_token={values.api_token}
          jira_email={values.jira_email}
          handleChange={handleChange}
          setFieldValue={setFieldValue}
          default_project_key={values.default_project_key}
          default_issue_type_id={values.default_issue_type_id}
          projectError={
            touched.default_project_key && errors.default_project_key
          }
          issueTypeError={
            touched.default_issue_type_id && errors.default_issue_type_id
          }
        />
      )}
    </Stack>
  );
};
