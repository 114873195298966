import { FC, useCallback, useMemo } from "react";
import {
  Divider,
  FormControl,
  IconButton,
  InputLabel,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
} from "@mui/material";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { CreateIntegrationButton } from "./CreateIntegrationButton";
import { IntegrationNotification } from "../../utils/types/types";
import { emailNotificationId } from "../../utils/constants/constants";
import { generateIntegrationMetadata } from "../../utils/helpers/helpers";
import { IntegrationType } from "../../../../integrations/components/integrations-create/components/common/integration-type/IntegrationTypeCard";
import { IntegrationLogo } from "../../../../../common/integration-dialogs/components/common/IntegrationLogo";
import { SlackConversationsSelect } from "../../../../../common/integration-dialogs/components/slack/SlackConversationsSelect";
import { EmailIntegratedEmailsSelect } from "../../../../../common/integration-dialogs/components/email/components/EmailIntegratedEmailsSelect";
import {
  Integration,
  SlackConversation,
  useGetUsersMeIntegrationsQuery,
} from "../../../../../../services/cloudchipr.api";
import { TypographyWithTooltip } from "../../../../../common/TypographyWithTooltip";
import { IntegrationErrors } from "../../../../automations/workflows/single-workflow/form/actions-section/grace-period/GracePeriodNotifications";
import { IntegrationCustomMessageProps } from "../../../../automations/common/components/custom-message/IntegrationCustomMessage";

interface IntegrationItemProps {
  integration: IntegrationNotification;
  onEmailsCreate(): void;
  selectedIntegrations: IntegrationNotification[];
  onCreateDialogOpen(): void;
  disableEmailsSelection: boolean;
  setSelectedIntegrations(integrations: IntegrationNotification[]): void;
  error?: IntegrationErrors;
  CustomMessage?: FC<IntegrationCustomMessageProps>;
  disabledIntegrationTypes?: IntegrationType[];
}

export const IntegrationItem: FC<IntegrationItemProps> = ({
  integration,
  onCreateDialogOpen,
  selectedIntegrations,
  setSelectedIntegrations,
  disabledIntegrationTypes,
  error,
  CustomMessage,
  disableEmailsSelection,
  onEmailsCreate,
}) => {
  const { data } = useGetUsersMeIntegrationsQuery({});
  const options = useMemo(() => {
    return data?.filter(
      (integration) => !disabledIntegrationTypes?.includes(integration.type),
    );
  }, [data, disabledIntegrationTypes]);

  const integrationRemoveHandler = useCallback(() => {
    setSelectedIntegrations(
      selectedIntegrations.filter(
        ({ integration_id }) => integration_id !== integration.integration_id,
      ),
    );
  }, [setSelectedIntegrations, selectedIntegrations, integration]);

  const changeHandler = useCallback(
    (event: SelectChangeEvent) => {
      const id = event.target.value;

      if (id === emailNotificationId) {
        onEmailsCreate();
        integrationRemoveHandler();
        return;
      }

      const found = options?.find((int) => int.id === id) as Integration;

      if (!found) {
        return;
      }

      const selected = generateIntegrationMetadata(found.type, found);

      const integrations = selectedIntegrations.map((int) => {
        return integration.integration_id === int.integration_id
          ? selected
          : int;
      });

      setSelectedIntegrations(integrations);
    },
    [
      selectedIntegrations,
      integration,
      setSelectedIntegrations,
      options,
      integrationRemoveHandler,
      onEmailsCreate,
    ],
  );

  const renderValue = useCallback(
    (id: string) => {
      const selected = options?.find((int) => int.id === id);

      if (!selected) {
        return id;
      }

      return (
        <Stack direction="row" spacing={2}>
          <IntegrationLogo type={selected.type} width={20} />
          <ListItemText>
            <TypographyWithTooltip title={selected.name} />
          </ListItemText>
        </Stack>
      );
    },
    [options],
  );

  const toEmailsChangeHandler = useCallback(
    (emails: string[]) => {
      setSelectedIntegrations(
        selectedIntegrations.map((int) => {
          return integration.integration_id === int.integration_id
            ? { ...int, to: emails }
            : int;
        }),
      );
    },
    [selectedIntegrations, setSelectedIntegrations, integration],
  );

  const slackConversationsChangeHandler = useCallback(
    (conversations: SlackConversation[]) => {
      setSelectedIntegrations(
        selectedIntegrations.map((int) => {
          return integration.integration_id === int.integration_id
            ? { ...int, conversations }
            : int;
        }),
      );
    },
    [selectedIntegrations, setSelectedIntegrations, integration],
  );

  const customMessageChangeHandler = useCallback(
    (message: string) => {
      const data = selectedIntegrations.map((item) => {
        if (item.integration_id === integration.integration_id) {
          return {
            ...item,
            custom_message: message,
          };
        }
        return item;
      });
      setSelectedIntegrations(data);
    },
    [integration, selectedIntegrations, setSelectedIntegrations],
  );

  if (!options) {
    return null;
  }

  return (
    <Stack direction="row" spacing={2} alignItems="start">
      <Stack flexGrow={1} gap={2} overflow="hidden" pt={1}>
        <FormControl size="small" fullWidth>
          <InputLabel>Select Notification Method</InputLabel>

          <Select
            value={integration.integration_id}
            onChange={changeHandler}
            label="Select Notification Method"
            renderValue={renderValue}
          >
            <MenuItem
              value={emailNotificationId}
              disabled={disableEmailsSelection}
            >
              <Stack direction="row" spacing={2}>
                <IntegrationLogo variant="colored" type="email" width={20} />

                <ListItemText>EMAIL</ListItemText>
              </Stack>
            </MenuItem>

            <Divider />

            {options.map(({ id, type, name }) => {
              return (
                <MenuItem
                  key={id}
                  value={id}
                  disabled={selectedIntegrations.some(
                    (integration) => id === integration.integration_id,
                  )}
                >
                  <ListItemIcon>
                    <IntegrationLogo variant="colored" type={type} width={20} />
                  </ListItemIcon>

                  <ListItemText>{name}</ListItemText>
                </MenuItem>
              );
            })}

            <CreateIntegrationButton
              onClick={onCreateDialogOpen}
              empty={!options?.length}
            />
          </Select>
        </FormControl>
        {integration?.type === "slack" && (
          <SlackConversationsSelect
            error={error?.conversations}
            integrationId={integration.integration_id}
            selectedConversations={integration.conversations}
            onChange={slackConversationsChangeHandler}
          />
        )}
        {integration?.type === "email" && (
          <EmailIntegratedEmailsSelect
            errorMessages={error?.to}
            clearValue={false}
            integrationId={integration.integration_id}
            selectedValue={integration.to}
            onChange={toEmailsChangeHandler}
          />
        )}
        {CustomMessage && (
          <CustomMessage
            type={integration?.type}
            onChange={customMessageChangeHandler}
            message={integration?.custom_message ?? ""}
          />
        )}
      </Stack>

      <Stack pt={1}>
        <IconButton onClick={integrationRemoveHandler}>
          <DeleteOutlineIcon />
        </IconButton>
      </Stack>
    </Stack>
  );
};
