import { createAsyncThunk } from "@reduxjs/toolkit";
import { cloudChiprApi } from "../../../services/cloudchipr.api";

export const getOrganisationCurrentFilterTemplatesThunk = createAsyncThunk(
  "liveUsageMgmt/getOrganisationCurrentFilterTemplate",
  async (_, { dispatch }) => {
    const { getUsersMeOrganisationsCurrentFilterSets } =
      cloudChiprApi.endpoints;

    return await dispatch(
      getUsersMeOrganisationsCurrentFilterSets.initiate(undefined, {
        forceRefetch: true,
      }),
    );
  },
);
