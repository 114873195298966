import { FC } from "react";
import { Link } from "@mui/material";
import { TypographyWithTooltip } from "../../../../../../common/TypographyWithTooltip";

interface AlertsCostAnomalyDataGridViewCellProps {
  viewId: string;
  viewName: string;
}

export const AlertsCostAnomalyDataGridViewCell: FC<
  AlertsCostAnomalyDataGridViewCellProps
> = ({ viewId, viewName }) => {
  return (
    <Link href={`/resource-explorer/${viewId}`} target="_blank">
      <TypographyWithTooltip variant="inherit" title={viewName} />
    </Link>
  );
};
