import { resourceExplorerHierarchyVisibilitiesDataSelector } from "./resourceExplorerHierarchyVisibilitiesDataSelector";
import { RootState } from "../../../store";
import { ReFolderV2 } from "../../../../services/cloudchipr.api";
import { isHierarchyFolder } from "../../../navigation/utils/helpers/isHierarchyFolder";

export const resourceExplorerHierarchyVisibilitiesDataFoldersSelector = (
  state: RootState,
): ReFolderV2[] | undefined => {
  const data = resourceExplorerHierarchyVisibilitiesDataSelector(state);
  const privateData = data?.visible_only_to_me;
  const publicData = data?.visible_to_everyone;
  if (!privateData) {
    return;
  }
  const privateDataItems =
    privateData.reduce((result, item) => {
      if (isHierarchyFolder(item)) {
        result.push(item);
      }
      return result;
    }, [] as ReFolderV2[]) ?? [];
  const publicDataItems =
    publicData?.reduce((result, item) => {
      if (isHierarchyFolder(item)) {
        result.push(item);
      }
      return result;
    }, [] as ReFolderV2[]) ?? [];
  return privateDataItems.concat(publicDataItems);
};
