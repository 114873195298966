import { RootState } from "../../../store";
import { resourceExplorerIdSelector } from "../current-resource-explorer/resourceExplorerIdSelector";
import { ResourceExplorer } from "../../../../services/cloudchipr.api";
import { resourceExplorerHierarchyVisibilitiesMergedDataItemsSelector } from "../views-hierarchy-visibility/resourceExplorerHierarchyVisibilitiesMergedDataItemsSelector";

export const resourceExplorerByCurrentIdSelector = (
  state: RootState,
): ResourceExplorer | undefined => {
  const id = resourceExplorerIdSelector(state);

  return resourceExplorerHierarchyVisibilitiesMergedDataItemsSelector(
    state,
  )?.find((view) => view.id === id);
};
