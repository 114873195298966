import { FC } from "react";
import { Chip, Stack, Tooltip, Typography } from "@mui/material";
import { useAppSelector } from "../../../../../../../store/hooks";
import { taskManagementTargetResourcesTitlesSelector } from "../../../../../../../store/task-management/selectors/form/target/resources/taskManagementTargetResourcesTitlesSelector";
import { TypographyWithTooltip } from "../../../../../../common/TypographyWithTooltip";

export const TaskCreationDrawerResourcesTitles: FC = () => {
  const titles = useAppSelector(taskManagementTargetResourcesTitlesSelector);

  const firstTitle = titles?.at(0);

  if (!firstTitle) {
    return null;
  }

  return (
    <Stack direction="row" spacing={1} alignItems="center">
      <Chip
        size="small"
        label={
          <TypographyWithTooltip
            title={firstTitle}
            variant="body2"
            maxWidth={300}
          />
        }
        variant="filled"
        sx={{ borderRadius: 1 }}
      />

      {titles && titles.length > 1 && (
        <Tooltip
          arrow
          componentsProps={componentsProps}
          title={
            <Stack
              px={2}
              py={1}
              spacing={1}
              maxHeight={300}
              sx={{ overflowY: "auto" }}
            >
              <Typography
                variant="caption"
                fontWeight="medium"
                color="text.secondary"
              >
                Tasks ({titles?.length})
              </Typography>

              {titles?.map((title) => (
                <Typography key={title} variant="caption">
                  {title}
                </Typography>
              ))}
            </Stack>
          }
        >
          <Chip
            size="small"
            label={`+${titles.length - 1}`}
            variant="outlined"
            sx={{ cursor: "pointer" }}
          />
        </Tooltip>
      )}
    </Stack>
  );
};

const componentsProps = {
  tooltip: {
    sx: {
      color: "text.primary",
      bgcolor: "background.paper",
      "& .MuiTooltip-arrow": {
        color: "background.paper",
      },
      boxShadow: 7,
      p: 0,
    },
  },
};
