import { createAsyncThunk } from "@reduxjs/toolkit";
import { findAndApplyFilterTemplatesThunk } from "./filters/findAndApplyLiveUsageMgmtFiltersThunk";
import { getOrganisationCurrentFilterTemplatesThunk } from "./getOrganisationCurrentFilterTemplatesThunk";
import { getLiveUsageMgmtAccountsVisibilitiesThunk } from "./getLiveUsageMgmtAccountsVisibilitiesThunk";
import { getLiveUsageMgmtAllResourceTypesDataThunk } from "./resources/getLiveUsageMgmtAllResourceTypesDataThunk";
import {
  setAccountIds,
  setLiveUsageMgmtAppliedFilters,
  setLiveUsageMgmtPageState,
  setLiveUsageMgmtProvider,
} from "../liveUsageMgmtSlice";
import { createUniqueIdFilterFromSearchParams } from "../utils/helpers/createUniqueIdFilterFromSearchParams";
import { generateAccountIdsFromSearchParams } from "../../../components/pages/live-usage-mgmt/utils/helpers/generateAccountIdsFromSearchParams";
import { AccountId, ProviderType } from "../../../services/cloudchipr.api";
import { RootState } from "../../store";
import { liveUsagePageStateSelector } from "../selectors/store-selectors/liveUsagePageStateSelector";

interface InitiateLiveUsageMgmtThunkArgs {
  accountIds: AccountId | null;
  provider: ProviderType;
  resourceIds: string | null;
  resourceType: string | null;
}

export const initiateLiveUsageMgmtThunk = createAsyncThunk(
  "liveUsageMgmt/initiateLiveUsageMgmtThunk",
  async (
    {
      accountIds,
      provider,
      resourceType,
      resourceIds,
    }: InitiateLiveUsageMgmtThunkArgs,
    { dispatch, getState },
  ) => {
    const state = getState() as RootState;

    const pageState = liveUsagePageStateSelector(state);

    if (pageState === "fulfilled") {
      return;
    }

    dispatch(setLiveUsageMgmtProvider(provider));
    dispatch(setLiveUsageMgmtPageState("pending"));
    if (!accountIds) {
      return;
    }

    dispatch(setAccountIds(generateAccountIdsFromSearchParams(accountIds)));

    await dispatch(getOrganisationCurrentFilterTemplatesThunk());

    await dispatch(findAndApplyFilterTemplatesThunk());

    const uniqueIdFilter = createUniqueIdFilterFromSearchParams(
      resourceIds,
      resourceType,
    );
    if (uniqueIdFilter) {
      dispatch(setLiveUsageMgmtAppliedFilters(uniqueIdFilter));
    }

    await dispatch(getLiveUsageMgmtAccountsVisibilitiesThunk());
    await dispatch(getLiveUsageMgmtAllResourceTypesDataThunk());

    dispatch(setLiveUsageMgmtPageState("fulfilled"));
  },
);
