import { FC, RefObject, useMemo } from "react";
import { Row, Table, VisibilityState } from "@tanstack/react-table";
import { Stack, Typography } from "@mui/material";
import { SortingState } from "@tanstack/table-core";
import { getResourceExplorerAllViewsTableSubRowColumns } from "./resourceExplorerAllViewsTableSubRowColumns";
import {
  ReFolderV2,
  ResourceExplorer,
} from "../../../../../../../services/cloudchipr.api";
import { VisibilityType } from "../../../../../common/hierarchy-all-items/utils/types/types";
import { DataGrid } from "../../../../../../../storybook/data-grid/DataGrid";

interface ResourceExplorerAllViewsTableSubRowProps {
  data?: ResourceExplorer[];
  folderId: string;
  sorting?: SortingState;
  visibility?: VisibilityState;
}

const ResourceExplorerAllViewsTableSubRow: FC<
  ResourceExplorerAllViewsTableSubRowProps
> = ({ data, visibility, sorting, folderId }) => {
  const columns = useMemo(
    () => getResourceExplorerAllViewsTableSubRowColumns(folderId),
    [folderId],
  );

  if (!data?.length) {
    return (
      <Stack py={2} px={5}>
        <Typography variant="body2" color="text.secondary">
          No views inside
        </Typography>
      </Stack>
    );
  }

  return (
    <DataGrid
      columnVisibility={{
        initialVisibility: visibility,
      }}
      columnSorting={{
        initialSort: sorting,
      }}
      data={data}
      columns={columns}
      styles={styles}
    />
  );
};

const styles = {
  table: {
    tableLayout: "fixed",
  },
  tableHeaderRow: {
    "& th": {
      border: "none",
    },
    visibility: "collapse",
  },
  tableRow: {
    "&:last-of-type td": {
      border: `none`,
    },
  },
};

export const resourceExplorerAllViewsTableSubRow = (
  row: Row<ReFolderV2 & { visibility: VisibilityType }>,
  _: RefObject<HTMLTableElement>,
  globalFilter?: string,
  table?: Table<ReFolderV2>,
) => {
  const tableState = table?.getState();

  return (
    <ResourceExplorerAllViewsTableSubRow
      data={row.original.items}
      folderId={row.original.id}
      sorting={tableState?.sorting}
      visibility={tableState?.columnVisibility}
    />
  );
};
