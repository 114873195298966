import {
  ComputeInlineMetrics,
  DatabaseInlineMetrics,
  DataStorageInlineMetrics,
  InlineResourceMetric,
} from "../../../../../../../../../../../../../services/cloudchipr.api";
import { MetricType } from "../types";

export const getMetricsByType = (
  metrics:
    | DatabaseInlineMetrics
    | DataStorageInlineMetrics
    | ComputeInlineMetrics,
  type: MetricType,
): InlineResourceMetric | undefined => {
  if (type === "compute" && "cpu_max" in metrics) {
    return metrics.cpu_max;
  }

  if (type === "database" && "total_connection_sum" in metrics) {
    return metrics.total_connection_sum;
  }

  if (type === "storage" && "all_request_sum" in metrics) {
    return metrics.all_request_sum;
  }

  if (type === "memory_used" && "mem_used_percent_max" in metrics) {
    return metrics.mem_used_percent_max;
  }

  if (type === "memory_available" && "mem_available_percent_min" in metrics) {
    return metrics.mem_available_percent_min;
  }
};
