import { createAsyncThunk } from "@reduxjs/toolkit";
import { getResourceExplorerDataGridSavedData } from "../../../../components/pages/resource-explorer/components/resource-explorer-card/utils/helpers/resourceExplorerDataGridSavedDataGetterSetter/getResourceExplorerDataGridSavedData";
import { setResourceExplorerDataGridView } from "../../resourceExplorerSlice";

export const setResourceExplorerInitialDataGridViewTypeFromStorageThunk =
  createAsyncThunk(
    "resourceExplorer/setResourceExplorerDataGridViewTypeFromStorage",
    async (viewId: string, { dispatch }) => {
      const viewTypeFromStorage = getResourceExplorerDataGridSavedData(viewId);

      dispatch(setResourceExplorerDataGridView(viewTypeFromStorage ?? "total"));
    },
  );
