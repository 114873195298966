import Typography from "@mui/material/Typography";
import { Stack } from "@mui/material";
import { FC, useCallback } from "react";
import { AlertsCostsAnomalyThresholdItemWithActions } from "./AlertsCostsAnomalyThresholdItemWithActions";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../../../store/hooks";
import { alertsDataThresholdSelector } from "../../../../../../../../../../store/alerts/selectors/common/data/alertsDataThresholdSelector";
import { setAlertsData } from "../../../../../../../../../../store/alerts/alertsSlice";
import {
  AlertThresholdOperatorType,
  CostAnomalyAlertUnitType,
} from "../../../../../../../../../../store/alerts/utils/types/types";
import { changeCostAnomalyAlertThresholdValueThunk } from "../../../../../../../../../../store/alerts/thunks/cost-anomaly-alerts/changeCostAnomalyAlertThresholdValueThunk";
import { addCostAnomalyAlertThresholdThunk } from "../../../../../../../../../../store/alerts/thunks/cost-anomaly-alerts/addCostAnomalyAlertThresholdThunk";

export const AlertsCostsAnomalyThresholdsSection: FC = () => {
  const thresholdData = useAppSelector(alertsDataThresholdSelector);
  const dispatch = useAppDispatch();

  const onOperatorChange = useCallback(
    (operator: AlertThresholdOperatorType) => {
      dispatch(
        setAlertsData({
          threshold: {
            ...thresholdData,
            operator,
          },
        }),
      );
    },
    [dispatch, thresholdData],
  );

  const unitChangeHandler = useCallback(
    (unit: CostAnomalyAlertUnitType, index: number) => {
      dispatch(changeCostAnomalyAlertThresholdValueThunk({ index, unit }));
    },
    [dispatch],
  );

  const amountChangeHandler = useCallback(
    (amount: string, index: number) => {
      dispatch(changeCostAnomalyAlertThresholdValueThunk({ index, amount }));
    },
    [dispatch],
  );

  const removeThresholdClickHandler = useCallback(
    (index: number) => {
      const updatedItems = thresholdData?.threshold_items?.filter(
        (_, itemIndex) => index !== itemIndex,
      );
      dispatch(
        setAlertsData({
          threshold: {
            ...thresholdData,
            threshold_items: updatedItems ?? [],
          },
        }),
      );
    },
    [thresholdData, dispatch],
  );

  const addThresholdClickHandler = useCallback(() => {
    dispatch(addCostAnomalyAlertThresholdThunk());
  }, [dispatch]);

  return (
    <Stack spacing={1.5}>
      <Typography variant="body1" fontWeight="medium">
        Set threshold
      </Typography>

      {thresholdData?.threshold_items?.map((item, index) => {
        const length = thresholdData?.threshold_items?.length ?? 0;
        return (
          <AlertsCostsAnomalyThresholdItemWithActions
            key={(item.unit ?? "") + index}
            index={index}
            unit={(item.unit ?? "percent") as CostAnomalyAlertUnitType}
            amount={item?.amount ?? "1"}
            onUnitChange={unitChangeHandler}
            onAmountChange={amountChangeHandler}
            onRemove={length === 1 ? undefined : removeThresholdClickHandler}
            count={length}
            onAddThresholdClick={addThresholdClickHandler}
            operator={thresholdData?.operator ?? "or"}
            onOperatorChange={onOperatorChange}
            optionDisabled={length > 1}
          />
        );
      })}
    </Stack>
  );
};
