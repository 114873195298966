import { FC } from "react";
import { Stack } from "@mui/material";
import {
  CostType,
  DateLabelNullable,
  ResourceExplorerCostsDateDetails,
} from "../../../../../../../../services/cloudchipr.api";
import { money } from "../../../../../../../../utils/numeral/money";
import { TrendChip } from "../../../../../../../common/chips/TrendChip";
import { InfoCard } from "../../../../../../common/info-cards/InfoCard";
import { getTrendTooltipTitle } from "../../../../../../dashboard/utils/helpers/trend-tooltip-title/getTrendTooltipTitle";
import { costTypes } from "../../../../../../../navigation/components/resource-explorer-v2/components/view-action-menu/action-items/view-settings-action/components/view-cost-type/utils/constants/constants";

interface TotalCostProps {
  totalCost: number;
  totalCostTrend: number | null;
  totalCostDates?: ResourceExplorerCostsDateDetails;
  previousPeriodCostDates?: ResourceExplorerCostsDateDetails;
  dateLabel?: DateLabelNullable;
  costType?: CostType;
}

export const TotalCost: FC<TotalCostProps> = ({
  totalCost,
  totalCostTrend,
  totalCostDates,
  previousPeriodCostDates,
  dateLabel,
  costType,
}) => {
  const tooltipTitle = getTrendTooltipTitle(
    totalCostDates?.from_date,
    totalCostDates?.to_date,
    previousPeriodCostDates?.from_date,
    previousPeriodCostDates?.to_date,
    undefined,
    dateLabel,
  );

  return (
    <Stack direction="row" alignItems="end" spacing={1}>
      <Stack direction="row">
        <InfoCard
          title="Total Cost"
          value={money(totalCost)}
          tooltip={`Total cost includes AWS ${costType && costTypes[costType]} cost, and GCP and Azure actual costs.`}
        />
      </Stack>

      <TrendChip value={totalCostTrend} tooltipTitle={tooltipTitle} />
    </Stack>
  );
};
