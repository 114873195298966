import { liveUsageMgmtSelectedRowsWithHierarchyByResourceTypeSelector } from "./liveUsageMgmtSelectedRowsWithHierarchyByResourceTypeSelector";
import { RootState } from "../../../store";
import { ResourceType } from "../../../../services/cloudchipr.api";

export const liveUsageMgmtResourceCleanActionsButtonDisabledReasonSelector = (
  state: RootState,
  resourceType: ResourceType,
): "non_selected_data" | "multi_resource_type_selection" | undefined => {
  const selectedResourcesWithHierarchy =
    liveUsageMgmtSelectedRowsWithHierarchyByResourceTypeSelector(
      state,
      resourceType,
    );

  const selectedItems = Object.keys(selectedResourcesWithHierarchy ?? {});

  if (!selectedItems?.length) {
    return "non_selected_data";
  }

  if (selectedItems.length > 1) {
    return "multi_resource_type_selection";
  }
};
