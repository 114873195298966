type ToDateTpe = "month" | "quarter";

export const getToDateTrendTooltipTitle = (
  currentDatePeriod: string,
  previousDatesPeriod: string,
  toDateType: ToDateTpe,
) => {
  const label = new Map<ToDateTpe, { long: string; short: string }>([
    ["month", { long: "Month to date", short: "month" }],
    ["quarter", { long: "Quarter to date", short: "quarter" }],
  ]);

  return `${
    label.get(toDateType)?.long
  } (${currentDatePeriod}) compared with the same period in last ${
    label.get(toDateType)?.short
  } (${previousDatesPeriod}).`;
};
