import { FC, useCallback, useMemo } from "react";
import { Alert, Button } from "@mui/material";
import { useAppSelector } from "../../../../../../store/hooks";
import { automationErrorsSelector } from "../../../../../../store/automations/selectros/common/errors/automationErrorsSelector";
import { getAutomationSectionIdByKeyword } from "../../../workflows/single-workflow/utils/sectionsIds";

export const AutomationErrorsAlert: FC = () => {
  const errors = useAppSelector(automationErrorsSelector);

  const errorsArray = useMemo(() => {
    return Object.keys(errors).filter((key) => errors[key]);
  }, [errors]);

  const firstError = errorsArray.at(0);

  const errorSectionId = useMemo(() => {
    const keyword = firstError?.split("[")?.at(0)?.split(".")?.at(0);

    return keyword && getAutomationSectionIdByKeyword(keyword);
  }, [firstError]);

  const goToErrorHandler = useCallback(() => {
    if (!errorSectionId) {
      return;
    }

    const commonWrapper = document.getElementById(errorSectionId);

    commonWrapper?.scrollIntoView();
  }, [errorSectionId]);

  if (!firstError) {
    return null;
  }

  return (
    <Alert
      severity="error"
      sx={{ "& .MuiAlert-action": { p: 0 } }}
      action={
        errorSectionId ? (
          <Button onClick={goToErrorHandler} color="error">
            GO To Issues(S)
          </Button>
        ) : undefined
      }
    >
      Make sure all fields are correct to continue.
    </Alert>
  );
};
