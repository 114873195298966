import { FC, useCallback } from "react";
import { useDidMount } from "rooks";
import { DialogsContainer } from "./components/DialogsContainer";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { currentIntegrationTypeSelector } from "../../../store/integrations/selectors/currentIntegrationTypeSelector";
import { currentIntegrationResourceTypeSelector } from "../../../store/integrations/selectors/currentIntegrationResourceTypeSelector";
import { destroyCurrentIntegration } from "../../../store/integrations/integrationsSlice";
import { getAllIntegrationsThunk } from "../../../store/integrations/thunks/getAllIntegrationsThunk";
import { useAppAbility } from "../../../services/permissions";

export const IntegrationDialogs: FC = () => {
  const dispatch = useAppDispatch();
  const { can } = useAppAbility();
  const integrationsAvailable = can("view", "integration");

  const currentIntegrationType = useAppSelector(currentIntegrationTypeSelector);
  const resourceType = useAppSelector(currentIntegrationResourceTypeSelector);

  const closeHandler = useCallback(() => {
    dispatch(destroyCurrentIntegration());
  }, [dispatch]);

  useDidMount(() => {
    if (!integrationsAvailable) {
      return;
    }
    dispatch(getAllIntegrationsThunk());
  });

  if (!currentIntegrationType || !resourceType) {
    return null;
  }

  return (
    <DialogsContainer
      onClose={closeHandler}
      resourceType={resourceType}
      integrationType={currentIntegrationType}
    />
  );
};
