import { FC, useCallback, useEffect, useState } from "react";
import { LoadingButton } from "@mui/lab";
import { useAppDispatch, useAppSelector } from "../../../../../../store/hooks";
import { useAppAbility } from "../../../../../../services/permissions";
import { tryToApplyLiveUsageMgmtFilterTemplateThunk } from "../../../../../../store/live-usage-mgmt/thunks/filter-template/tryToApplyLiveUsageMgmtFilterTemplateThunk";
import { liveUsageMgmtAppliedTemplateIdSelector } from "../../../../../../store/live-usage-mgmt/selectors/store-selectors/liveUsageMgmtAppliedTemplateIdSelector";

interface ApplyFilterTemplateButtonProps {
  filterSetId: string;
  onApply(): void;
}

export const ApplyFilterTemplateButton: FC<ApplyFilterTemplateButtonProps> = ({
  filterSetId,
  onApply,
}) => {
  const dispatch = useAppDispatch();
  const appliedFilterSetId = useAppSelector(
    liveUsageMgmtAppliedTemplateIdSelector,
  );
  const [applying, setApplying] = useState(false);
  const { cannot } = useAppAbility();
  const cannotEditAccount = cannot("edit", "account");

  const [applied, setApplied] = useState(filterSetId === appliedFilterSetId);

  const applyHandler = useCallback(async () => {
    setApplying(true);
    await dispatch(tryToApplyLiveUsageMgmtFilterTemplateThunk(filterSetId));
    onApply();
    setApplying(false);
  }, [dispatch, filterSetId, onApply]);

  useEffect(() => {
    setApplied(filterSetId === appliedFilterSetId);
  }, [filterSetId, appliedFilterSetId]);

  return (
    <LoadingButton
      size="small"
      loading={applying}
      variant="outlined"
      disabled={applied || cannotEditAccount}
      onClick={applyHandler}
      sx={{ minWidth: 75 }}
    >
      {applied ? "Applied" : "Apply"}
    </LoadingButton>
  );
};
