import { Row } from "@tanstack/react-table";
import { visibilityTitleByVisibility } from "../../utils/constants/constants";
import { DashboardMixedVisibilitiesItemType } from "../../../../dashboard-all-items/components/table/utils/types/types";
import { ReMixedVisibilitiesItemType } from "../../../../resource-explorer-all-views/components/table/utils/types/types";

export const hierarchyAllItemsVisibilityFilter = <
  T extends ReMixedVisibilitiesItemType | DashboardMixedVisibilitiesItemType,
>(
  row: Row<T>,
  _: string,
  keyword: string,
) => {
  const filterValue = keyword.toLowerCase().trim();
  const visibility = `${visibilityTitleByVisibility.get(
    row.original.visibility,
  )} ${row.original.visibility}`
    ?.toLowerCase()
    .trim();

  return !!visibility?.includes(filterValue);
};
