import { FC } from "react";
import { GridApi, RowNode } from "@ag-grid-community/core";
import { Box, Chip } from "@mui/material";
import { ungroupedLabelsByGrouping } from "../../../utils/constants/grouping";
import { TasksGroupingByProperty } from "../../../utils/types/common";

interface AutoGroupColumnDefInnerRendererProps {
  api: GridApi;
  node: RowNode;
}

export const AutoGroupColumnDefInnerRenderer: FC<
  AutoGroupColumnDefInnerRendererProps
> = (props) => {
  const groupingColumns = props.api.getRowGroupColumns();
  const currentGrouping = props.node.field as TasksGroupingByProperty;

  if (!currentGrouping) {
    return null;
  }

  const currentCol = groupingColumns.find(
    (col) => col?.getColId() === currentGrouping,
  );

  if (!currentCol) {
    return null;
  }

  const data = props.node?.allLeafChildren?.at(0)?.data;

  if (ungroupedLabelsByGrouping[currentGrouping] && !data?.[currentGrouping]) {
    return (
      <Chip
        clickable={false}
        size="small"
        variant="filled"
        label={ungroupedLabelsByGrouping[currentGrouping]}
      />
    );
  }

  return (
    <Box pr={1}>
      {currentCol?.getColDef()?.cellRenderer?.({ ...props, data })}
    </Box>
  );
};
