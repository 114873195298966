import { TagFilterValueOptions } from "../../../../../../common/select/tag-filter-dropdown-select/utils/types";
import {
  GetUsersMeOrganisationsCurrentDismissedResourcesApiResponse,
  RightSizingRecommendation,
} from "../../../../../../../services/cloudchipr.api";
import { RecommendationDataType } from "../../../off-hours-recommendations/utils/types/types";

export const generateRecommendationsTagsFilterOptionsByTableData = (
  tableData: (
    | RecommendationDataType
    | RightSizingRecommendation
    | GetUsersMeOrganisationsCurrentDismissedResourcesApiResponse[0]
  )[],
) => {
  return tableData?.reduce(
    (result, item) => {
      item.tags?.forEach((tag) => {
        const tagValues = result?.valuesOptions?.[tag.key];

        if (!tagValues) {
          result = {
            ...result,
            valuesOptions: {
              ...result.valuesOptions,
              [tag.key]: [
                {
                  value: tag.value,
                },
              ],
            },
          };
        } else if (!tagValues?.find((item) => item.value === tag.value)) {
          tagValues.push({
            value: tag.value,
          });
        }

        if (!result?.keysOptions?.find((item) => item.value === tag.key)) {
          result.keysOptions = result.keysOptions
            ? [...result.keysOptions, { value: tag.key }]
            : [{ value: tag.key }];
        }
      });
      return result;
    },
    {} as {
      valuesOptions?: TagFilterValueOptions;
      keysOptions?: {
        value: string;
        title?: string;
      }[];
    },
  );
};
