import { FC, useMemo } from "react";
import { useDragLayer } from "react-dnd";
import { Box, Card, CardHeader, IconButton, Stack } from "@mui/material";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import { AccountCardHeaderTitle } from "./AccountCardHeaderTitle";
import { AccountCardHeaderInfo } from "./AccountCardHeaderInfo";
import { Account } from "../../../../../../services/cloudchipr.api";

interface AccountCardDragPreviewProps {
  account: Account;
  currentOffsetX: number;
  currentOffsetY: number;
}

const AccountCardDragPreview: FC<AccountCardDragPreviewProps> = ({
  account,
  currentOffsetY,
  currentOffsetX,
}) => {
  return (
    <Box
      position="fixed"
      sx={{ pointerEvents: "none", transform: "rotate(-10deg)" }}
      zIndex={100}
      width={400}
      left={currentOffsetX}
      top={currentOffsetY}
    >
      <Card
        sx={{
          borderRadius: 3,
        }}
        variant="outlined"
      >
        <CardHeader
          sx={{
            "& .MuiCardHeader-content": { overflow: "hidden" },
            pb: 1,
          }}
          title={
            <Stack direction="row" alignItems="center">
              <IconButton>
                <DragIndicatorIcon color="action" />
              </IconButton>
              <AccountCardHeaderTitle
                name={account?.provider_account_name || ""}
                accountId={account?.provider_account_id || ""}
              />
            </Stack>
          }
        />
        <AccountCardHeaderInfo
          accountId={account.provider_account_id ?? ""}
          status={account.status}
          accessType={account.access_type}
          missingPermissions={account.missing_permissions}
        />
      </Card>
    </Box>
  );
};

export const DragPreview: FC<{ accounts?: Account[] }> = ({ accounts }) => {
  const { isDragging, item, currentOffset } = useDragLayer((monitor) => ({
    item: monitor.getItem(),
    currentOffset: monitor.getSourceClientOffset(),
    isDragging: monitor.isDragging(),
    clientOffset: monitor.getClientOffset(),
  }));

  const account = useMemo(() => {
    return accounts?.find((a) => a.id === item?.id);
  }, [item?.id, accounts]);

  if (!account || !isDragging || !currentOffset) {
    return null;
  }

  return (
    <AccountCardDragPreview
      account={account}
      currentOffsetX={currentOffset?.x}
      currentOffsetY={currentOffset?.y}
    />
  );
};
