import { createAsyncThunk } from "@reduxjs/toolkit";
import { enqueueSnackbar } from "notistack";
import { getCostAndUsageSumWidgetDataByWidgetIdThunk } from "../data-get/getCostAndUsageSumWidgetDataByWidgetIdThunk";
import { DashboardWidgetCostAndUsageSummaryRequest } from "../../../../../../services/cloudchipr.api";
import { currentDashboardIdSelector } from "../../../../selectors/dashboard/currentDashboardIdSelector";
import { RootState } from "../../../../../store";
import { buildWidgetThunk } from "../../common/buildWidgetThunk";
import { costAndUsageSumWidgetSetupSelector } from "../../../../selectors/setups/cost-and-usage/costAndUsageSumWidgetSetupSelector";

export const buildCostAndUsageSumWidgetThunk = createAsyncThunk(
  "dashboards/buildCostAndUsageSumWidget",
  async (_, { dispatch, getState }) => {
    const state = getState() as RootState;

    const dashboardId = currentDashboardIdSelector(state);
    const setup = costAndUsageSumWidgetSetupSelector(state);

    if (!dashboardId || !setup?.viewId) {
      return;
    }

    const widget: DashboardWidgetCostAndUsageSummaryRequest = {
      type: "cost_and_usage_summary",
      name: setup.name,
      date_to: setup.dates.to,
      view_id: setup.viewId,
      date_from: setup.dates.from,
      date_label: setup.dates.label,
      dates: setup.dates,
      layout: null,
      filter: setup.filter,
      grouping: setup.grouping,
    };

    try {
      const response = await dispatch(
        buildWidgetThunk({
          widgetId: setup?.id,
          widget,
        }),
      ).unwrap();

      if (response && setup?.id) {
        await dispatch(
          getCostAndUsageSumWidgetDataByWidgetIdThunk(response.id),
        );
      }
      return response?.id;
    } catch (e) {
      // @ts-expect-error todo: api fix
      const errMessage = e?.data?.message || "Something went wrong";
      enqueueSnackbar(errMessage, {
        variant: "snackbarAlert",
        AlertSnackBarProps: { severity: "error" },
      });

      return null;
    }
  },
);
