import { FC } from "react";
import { useAppSelector } from "../../../../../../../../../../../store/hooks";
import { resourceExplorerDataGridDataPrevPeriodTotalCostSelector } from "../../../../../../../../../../../store/resource-explorer/selectors/current-resource-explorer/data-grid/resourceExplorerDataGridDataPrevPeriodTotalCostSelector";
import { resourceExplorerDataGridDataPreviousCostDateDetailsSelector } from "../../../../../../../../../../../store/resource-explorer/selectors/current-resource-explorer/data-grid/resourceExplorerDataGridDataPreviousCostDateDetailsSelector";
import { PrevPeriodCostColumnHeaderCell } from "../common/PrevPeriodCostColumnHeaderCell";

export const ResourceExplorerPrevPeriodHeaderCell: FC = () => {
  const sum = useAppSelector(
    resourceExplorerDataGridDataPrevPeriodTotalCostSelector,
  );
  const dateDetails = useAppSelector(
    resourceExplorerDataGridDataPreviousCostDateDetailsSelector,
  );

  return <PrevPeriodCostColumnHeaderCell sum={sum} dateDetails={dateDetails} />;
};
