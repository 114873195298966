import { createAsyncThunk } from "@reduxjs/toolkit";
import ValidationError from "yup/lib/ValidationError";
import * as Yup from "yup";
import { enqueueSnackbar } from "notistack";
import { RootState } from "../../store";
import { workflowValidationSchema } from "../utils/constants/validation";
import { workflowDataToValidateSelector } from "../selectros/workflow/data/workflowDataToValidateSelector";
import { setAutomationErrors } from "../automationsSlice";
import { offHoursDataToValidateSelector } from "../selectros/off-hours/data/offHoursDataToValidateSelector";
import { automationTypeSelector } from "../selectros/common/automationTypeSelector";
import {
  scrollPageToTop,
  scrollToElement,
} from "../../../components/pages/utils/helpers/page-scrolling-functions";
import {
  workflowDrawerHeaderId,
  workflowDrawerWrapperId,
} from "../../../components/pages/automations/workflows/drawer/constnats/common";
import { automationInDrawerOpenSelector } from "../selectros/common/automationInDrawerOpenSelector";

export const validateAutomationDataThunk = createAsyncThunk(
  "automation/validateWorkflowData",
  async (_: void, { dispatch, getState }) => {
    const state = getState() as RootState;

    try {
      const automationType = automationTypeSelector(state);
      const workflow = workflowDataToValidateSelector(state);
      const offHours = offHoursDataToValidateSelector(state);
      const inDrawer = automationInDrawerOpenSelector(state);
      const data = automationType === "workflow" ? workflow : offHours;

      const errors = await Yup.object(workflowValidationSchema)
        .validate(data, { abortEarly: false })
        .then(() => null)
        .catch((error) => {
          return error.inner.reduce(
            (acc: Record<string, string>, errObj: ValidationError) => {
              const key = (errObj.path || errObj?.params?.path) as string;

              if (!key) {
                return acc;
              }

              acc[key] = errObj.message;

              return acc;
            },
            {} as Record<string, string>,
          );
        });

      dispatch(setAutomationErrors(errors));

      if (errors) {
        if (inDrawer) {
          scrollToElement(workflowDrawerHeaderId, workflowDrawerWrapperId);
        } else {
          scrollPageToTop();
        }
      }

      return errors;
    } catch (error) {
      const message = `Something went wrong during validation. Please check that all fields are filled correctly.
      Error: ${JSON.stringify(error)}
      `;
      enqueueSnackbar(message, {
        variant: "snackbarAlert",
        AlertSnackBarProps: { severity: "error" },
      });
    }
  },
);
