import { FC, useCallback } from "react";
import { FormHelperText, Typography } from "@mui/material";
import { GracePeriodFrequency } from "./GracePeriodFrequency";
import { GracePeriodNotifications } from "./GracePeriodNotifications";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../store/hooks";
import { workflowGracePeriodSelector } from "../../../../../../../../store/automations/selectros/workflow/workflowGracePeriodSelector";
import { CollapseSwitcher } from "../CollapseSwitcher";
import {
  setAutomationErrors,
  setWorkflowSpecificData,
} from "../../../../../../../../store/automations/automationsSlice";
import { GracePeriod } from "../../../../../../../../services/cloudchipr.api";
import { secondsOnWeeks } from "../../../../../common/utils/helpers/gracePeriodCalculations";
import { workflowActionSelector } from "../../../../../../../../store/automations/selectros/workflow/workflowActionSelector";
import { automationHasAccountIdsSelectedSelector } from "../../../../../../../../store/automations/selectros/common/fields/automationHasAccountIdsSelectedSelector";
import { workflowGracePeriodPeriodError } from "../../../../../../../../store/automations/selectros/workflow/errors/workflowGracePeriodPeriodError";
import { workflowGracePeriodNotificationsError } from "../../../../../../../../store/automations/selectros/workflow/errors/workflowGracePeriodNotificationsError";
import { workflowGracePeriodNotificationExistenceError } from "../../../../../../../../store/automations/selectros/workflow/errors/workflowGracePeriodNotificationExistenceError";

export const WorkflowGracePeriodContent: FC = () => {
  const dispatch = useAppDispatch();
  const gracePeriod = useAppSelector(workflowGracePeriodSelector);
  const accountIdsSelected = useAppSelector(
    automationHasAccountIdsSelectedSelector,
  );
  const action = useAppSelector(workflowActionSelector);
  const periodError = useAppSelector(workflowGracePeriodPeriodError);
  const notificationError = useAppSelector(
    workflowGracePeriodNotificationExistenceError,
  );
  const notificationsErrors = useAppSelector(
    workflowGracePeriodNotificationsError,
  );

  const clearNotificationErrors = useCallback(() => {
    if (!Array.isArray(notificationsErrors?.notifications)) {
      return;
    }

    notificationsErrors?.notifications?.forEach((error, i) => {
      let key = "";

      if (error.conversations) {
        key = `gracePeriod.notifications[${i}].conversations`;
      }
      if (error.to) {
        key = `gracePeriod.notifications[${i}].to`;
      }

      if (key) {
        dispatch(setAutomationErrors({ [key]: "" }));
      }
    });
  }, [dispatch, notificationsErrors]);

  const gracePeriodChangeHandler = useCallback(
    (gracePeriod: GracePeriod) => {
      dispatch(setWorkflowSpecificData({ gracePeriod }));
      clearNotificationErrors();

      if (notificationError) {
        dispatch(setAutomationErrors({ "gracePeriod.notifications": "" }));
      }
    },
    [dispatch, clearNotificationErrors, notificationError],
  );

  const collapseHandler = useCallback(
    (collapsed: boolean) => {
      if (collapsed) {
        gracePeriodChangeHandler({
          period: secondsOnWeeks,
          notifications: [],
        });
      } else {
        gracePeriodChangeHandler(null);
      }
    },
    [gracePeriodChangeHandler],
  );

  const periodChangeHandler = useCallback(
    (period: number) => {
      gracePeriodChangeHandler({
        ...gracePeriod,
        period,
      });
    },
    [gracePeriodChangeHandler, gracePeriod],
  );

  if (action === "notify") {
    return null;
  }

  return (
    <CollapseSwitcher
      beta
      checkedOverride={!!gracePeriod}
      disabled={!accountIdsSelected}
      onCollapse={collapseHandler}
      title="Grace Period Before Actions"
      info="When enabled, actions will only be taken after a notification is sent and the grace period has expired. Users will be able to protect or backup their resources during the grace period."
    >
      <Typography variant="body2" color="text.secondary">
        Notify first, wait for grace period before taking actions.
      </Typography>

      <GracePeriodFrequency
        period={gracePeriod?.period ?? 0}
        onChange={periodChangeHandler}
        error={periodError}
      />

      <GracePeriodNotifications
        error={notificationsErrors}
        gracePeriod={gracePeriod}
        onChange={gracePeriodChangeHandler}
      />

      {notificationError && (
        <FormHelperText error>{notificationError}</FormHelperText>
      )}
    </CollapseSwitcher>
  );
};
