import { UnauthorizedWidgetHookArguments } from "../types/types";
import { useGetWidgetsLargestCostChangesByWidgetIdFromAccountServiceQuery } from "../../../../../services/cloudchipr-account-service.api";
import { useGetWidgetsLargestCostChangesByWidgetIdQuery } from "../../../../../services/cloudchipr-authless.api";
import { covertDatesToQueryParam } from "../../../../common/date-range-picker/utils/helpers/covertDatesToQueryParam";

export function useUnauthorizedLargestCostChangesHook({
  widgetId,
  organisationId,
  categoryId,
  overrideQueryDates,
}: UnauthorizedWidgetHookArguments) {
  const accountServiceResponse =
    useGetWidgetsLargestCostChangesByWidgetIdFromAccountServiceQuery(
      { widgetId: widgetId, organisationId: organisationId ?? "" },
      { skip: !organisationId, refetchOnMountOrArgChange: true },
    );

  const datesFormQuery = covertDatesToQueryParam(overrideQueryDates ?? {});
  const apiServiceResponse = useGetWidgetsLargestCostChangesByWidgetIdQuery(
    { widgetId: widgetId, categoryId, ...datesFormQuery },
    { skip: !categoryId, refetchOnMountOrArgChange: true },
  );

  return !accountServiceResponse.isUninitialized
    ? accountServiceResponse
    : apiServiceResponse;
}
