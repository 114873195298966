import { UniqueIdentifier } from "@dnd-kit/core";
import type { FlattenedItem, TreeItem, TreeItems } from "../types";

export const buildSortableTree = (
  flattenedItems: FlattenedItem[],
): TreeItems => {
  const root: TreeItem = {
    id: "root",
    items: [],
    name: "root",
    type: "folder",
  };

  const nodes: Record<string, TreeItem> = { [root.id]: root };
  const items = flattenedItems.map((item) => ({ ...item, items: [] }));

  for (const item of items) {
    const { id, items: children, name, disabled, type } = item;
    const parentId = item.parentId ?? root.id;
    const parent = nodes[parentId] ?? findItem(items, parentId);

    nodes[id] = { id, items: children, name, disabled, type };
    (parent.items ?? []).push(item);
  }

  return root.items ?? [];
};

const findItem = (items: TreeItem[], itemId: UniqueIdentifier) => {
  return items.find(({ id }) => id === itemId);
};
