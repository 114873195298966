import { DateLabel } from "../../../../../../services/cloudchipr.api";
import { preDefinedLabelsByDateLabel } from "../../../../../common/date-range-picker/utils/preDefinedRanges/preDefinedLabelsByDateLabel";

export const getLastPastTrendTooltipTitle = (
  currentDatePeriod: string,
  previousDatesPeriod: string,
  dateLabel: DateLabel,
) => {
  const label = preDefinedLabelsByDateLabel[dateLabel]?.long;
  const withoutPrefix = preDefinedLabelsByDateLabel[dateLabel]?.withoutPrefix;

  return `${label} (${currentDatePeriod}) compared with the preceding ${withoutPrefix} (${previousDatesPeriod}).`;
};
