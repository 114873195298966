import { Row } from "@tanstack/react-table";
import { ResourceExplorerGridDataWithId } from "../../../../../../../../../../../../store/resource-explorer/utils/types/resourceExplorerDataGrid";

const sortLabels = (current: string, next: string) => {
  if (current > next) {
    return -1;
  }
  if (current < next) {
    return 1;
  }
  return 0;
};
export const labelColumnSortingFn = (
  currentRow: Row<ResourceExplorerGridDataWithId>,
  nextRow: Row<ResourceExplorerGridDataWithId>,
) => {
  const current = currentRow.original.label || currentRow.original.field;
  const next = nextRow.original.label || nextRow.original.field;

  return sortLabels(current, next);
};

export const accountSortingFn = (
  currentRow: Row<ResourceExplorerGridDataWithId>,
  nextRow: Row<ResourceExplorerGridDataWithId>,
) => {
  const current =
    currentRow.original.metadata?.provider_account_name ||
    currentRow.original.metadata?.provider_account_id ||
    "-";

  const next =
    nextRow.original.metadata?.provider_account_name ||
    nextRow.original.metadata?.provider_account_id ||
    "-";

  if (current === "-" || next === "-") {
    return -1;
  }

  return sortLabels(current, next);
};

export const regionSortingFn = (
  currentRow: Row<ResourceExplorerGridDataWithId>,
  nextRow: Row<ResourceExplorerGridDataWithId>,
) => {
  const current = currentRow.original.metadata?.region;
  const next = nextRow.original.metadata?.region;
  return sortLabels(current, next);
};
