import { orgCurrentFilterTemplatesByProviderSelector } from "./org-filter-templates/orgCurrentFilterTemplatesByProviderSelector";
import { liveUsageMgmtProviderSelector } from "./store-selectors/liveUsageMgmtProviderSelector";
import { RootState } from "../../store";
import { FilterSet } from "../../../services/cloudchipr.api";

export const liveUsageMgmtFilterTemplatesSelector = (
  state: RootState,
): FilterSet[] | undefined => {
  const provider = liveUsageMgmtProviderSelector(state);
  if (!provider) {
    return;
  }
  return orgCurrentFilterTemplatesByProviderSelector(state, provider);
};
