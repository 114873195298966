import { createDraftSafeSelector } from "@reduxjs/toolkit";
import { resourceExplorerWidgetDataSelector } from "./resourceExplorerWidgetDataSelector";
import { resourceExplorerDataPointSelector } from "../data/resourceExplorerDataPointSelector";
import { WidgetAverageData } from "../../../utils/types/resourceExplorer";
import { getResourceExplorerWidgetAverageData } from "../../../utils/helpers/getResourceExplorerWidgetAverageData";

export const resourceExplorerWidgetAverageDataSelector =
  createDraftSafeSelector(
    [resourceExplorerWidgetDataSelector, resourceExplorerDataPointSelector],
    (data, dataPoint): WidgetAverageData | undefined => {
      return getResourceExplorerWidgetAverageData(data, dataPoint);
    },
  );
