import { FC, useCallback } from "react";
import { TrendType } from "../../../../../../../../../../../../../services/cloudchipr.api";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../../../../../../store/hooks";
import { commitmentUtilizationSetupPropertyByKeySelector } from "../../../../../../../../../../../../../store/dashboards/selectors/setups/commitment-utilization/commitmentUtilizationSetupPropertyByKeySelector";
import { WidgetSetupTrendSettingSelect } from "../../../../../../common/toolbar/WidgetSetupTrendSettingSelect";
import { commitmentUtilizationWidgetSetupDataChangeThunk } from "../../../../../../../../../../../../../store/dashboards/thunks/widgets/commitment-utilization/setup-change/commitmentUtilizationWidgetSetupDataChangeThunk";

export const CommitmentUtilizationWidgetAdvancedSettingsTrendSetting: FC =
  () => {
    const dispatch = useAppDispatch();
    const trendType: TrendType = useAppSelector(
      commitmentUtilizationSetupPropertyByKeySelector("trendType"),
    );

    const handleTrendTypeChange = useCallback(
      (trendType: TrendType) => {
        dispatch(
          commitmentUtilizationWidgetSetupDataChangeThunk({ trendType }),
        );
      },
      [dispatch],
    );

    return (
      <WidgetSetupTrendSettingSelect
        trendType={trendType}
        onTrendTypeChange={handleTrendTypeChange}
      />
    );
  };
