import { FC, useCallback } from "react";
import { ListItemText, MenuItem, Tooltip } from "@mui/material";
import { money } from "../../../../../../../../../utils/numeral/money";
import {
  ForecastOption,
  Date,
} from "../../../../../../../../../services/cloudchipr.api";
import { forecastedCostOptionLabels } from "../utils/constants";

interface ResourceExplorerForecastedCostsOptionProps {
  cost: number;
  selected?: boolean;
  forecastOption: ForecastOption;
  onClick(option: ForecastOption): void;
  dateFrom: Date;
  dateTo: Date;
}
export const ResourceExplorerForecastedCostsOption: FC<
  ResourceExplorerForecastedCostsOptionProps
> = ({ cost, forecastOption, onClick, selected, dateFrom, dateTo }) => {
  const forecastOptionClickHandler = useCallback(() => {
    onClick(forecastOption);
  }, [onClick, forecastOption]);

  return (
    <Tooltip
      title={`Forecasted Cost from ${dateFrom} to ${dateTo}`}
      placement="left"
      arrow
    >
      <MenuItem onClick={forecastOptionClickHandler} selected={selected}>
        <ListItemText
          primary={`Forecasted ${forecastedCostOptionLabels.get(
            forecastOption,
          )} Cost`}
          secondary={`~${money(cost)}`}
          primaryTypographyProps={{
            variant: "body2",
          }}
          secondaryTypographyProps={{
            variant: "body2",
          }}
        />
      </MenuItem>
    </Tooltip>
  );
};
