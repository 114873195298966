import { FC } from "react";
import { Button, Stack } from "@mui/material";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { SvgIconComponent } from "@mui/icons-material";
import { FilterTriggerComponentProps } from "../../../../../../common/select/dropdown-select/utils/types/filterTriggerComponentProps";
import { TypographyWithTooltip } from "../../../../../../common/TypographyWithTooltip";

interface BreadcrumbNavigationTriggerProps extends FilterTriggerComponentProps {
  icon: SvgIconComponent;
}
export const BreadcrumbNavigationTrigger: FC<
  BreadcrumbNavigationTriggerProps
> = ({ onClick, open, label, icon: Icon }) => {
  return (
    <Button
      color="tertiary"
      onClick={onClick}
      sx={{ fontWeight: "normal", textTransform: "none", maxWidth: 400 }}
      startIcon={
        <Stack direction="row" spacing={0.5}>
          <Icon fontSize="small" />
        </Stack>
      }
      endIcon={
        open ? (
          <ArrowDropUpIcon fontSize="small" sx={{ color: "action" }} />
        ) : (
          <ArrowDropDownIcon fontSize="small" sx={{ color: "action" }} />
        )
      }
    >
      <TypographyWithTooltip title={label} />
    </Button>
  );
};
