import { ChangeEvent, FC, useCallback } from "react";
import { Stack, TextField } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../../../../store/hooks";
import { reportsDataPropertyByKeySelector } from "../../../../../../store/reports/selector/reportsDataPropertyByKeySelector";
import { setReportsData } from "../../../../../../store/reports/reportsSlice";

export const ReportsDrawerNameField: FC = () => {
  const dispatch = useAppDispatch();
  const name = useAppSelector(reportsDataPropertyByKeySelector("name"));

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      dispatch(setReportsData({ name: event.target.value }));
    },
    [dispatch],
  );

  return (
    <Stack direction="row" spacing={1.5}>
      <Stack flex={1}>
        <TextField
          label="Name*"
          size="small"
          value={name}
          onChange={handleChange}
        />
      </Stack>
      <Stack flex={1}></Stack>
    </Stack>
  );
};
