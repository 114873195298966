import { FC, Fragment, useCallback, useState } from "react";
import { TextField, Typography } from "@mui/material";
import { AzureSubscriptionAppIdCodeSnippet } from "./AzureSubscriptionAppIdCodeSnippet";
import {
  generateAzureCommand,
  azureReadOnlyCommands,
  azureReadWriteCommands,
} from "./utils/generateAzureCommand";
import { useAppSelector } from "../../../../../../store/hooks";
import { azureServicePrincipalSelector } from "../../../../../../store/account-setup/selectors/azureServicePrincipalSelector";
import { providerAccessTypeSelector } from "../../../../../../store/account-setup/selectors/providerAccessTypeSelector";

export const AzureSubscriptionTabContent: FC = () => {
  const servicePrincipal = useAppSelector(azureServicePrincipalSelector);
  const providerAccessType = useAppSelector(providerAccessTypeSelector);
  const [subscriptionId, setSubscriptionId] = useState(initialSubscriptionId);
  const subscriptionIdChangeHandler = useCallback((e: any) => {
    setSubscriptionId(e.target.value);
  }, []);

  const command = generateAzureCommand({
    type: "subscription",
    actionType: "create",
    providerAccessType,
    identifier: subscriptionId,
    servicePrincipal: servicePrincipal || "",
  });

  const subscriptionIdFocusHandler = useCallback((event: any) => {
    if (event.target.value === initialSubscriptionId) {
      event.target.select();
    }
  }, []);

  return (
    <Fragment>
      <Typography
        variant="body2"
        fontWeight="medium"
        color="text.secondary"
        mt={3}
        mb={2}
      >
        Please copy and execute the following snippet in your terminal.
      </Typography>
      <TextField
        value={subscriptionId}
        onChange={subscriptionIdChangeHandler}
        size="small"
        label="SUBSCRIPTION ID"
        sx={{ verticalAlign: "middle" }}
        autoFocus
        fullWidth
        onFocus={subscriptionIdFocusHandler}
      />
      <AzureSubscriptionAppIdCodeSnippet
        scope="subscription"
        command={command}
        identifier={subscriptionId}
        disabled={!subscriptionId}
        servicePrincipal={servicePrincipal}
        commands={
          providerAccessType === "read"
            ? azureReadOnlyCommands
            : azureReadWriteCommands
        }
      />
    </Fragment>
  );
};

const initialSubscriptionId = "<YOUR SUBSCRIPTION ID>";
