import { capitalize } from "@mui/material";
import { MonthlyByWeekdaySchedule } from "../../../../../../../services/cloudchipr.api";

export const getMonthlyByWeekdayScheduleTitleAndTooltip = (
  numberOfWeeks: MonthlyByWeekdaySchedule["number_of_week"],
  weekDay: MonthlyByWeekdaySchedule["week_day"],
  time: string,
) => {
  return {
    title: "Monthly",
    tooltip: `On the ${numberOfWeekToString[numberOfWeeks]} ${capitalize(weekDay)} of month at ${time}`,
  };
};

const numberOfWeekToString: Record<number, string> = {
  1: "first",
  2: "second",
  3: "third",
  4: "last",
};
