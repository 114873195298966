import { FC, Fragment, useEffect } from "react";
import { Stack } from "@mui/material";
import { useParams } from "react-router-dom";
import { UtilizationAndCoverageMetadata } from "./components/metadata/UtilizationAndCoverageMetadata";
import { UtilizationAndCoverageContent } from "./components/content/UtilizationAndCoverageContent";
import { UtilizationAndCoverageDrawers } from "./components/drawers/UtilizationAndCoverageDrawers";
import { UtilizationAndCoveragePageHeader } from "./components/UtilizationAndCoveragePageHeader";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { ProviderBillingDataAccessController } from "../../common/billing/billing-data-access-controller/ProviderBillingDataAccessController";
import { awsBillingStatusNotAvailableSelector } from "../../../../store/commitments/selectors/organisations/awsBillingStatusNotAvailableSelector";
import { getSavingsPlansThunk } from "../../../../store/commitments/thunks/savings-plans/getSavingsPlansThunk";
import { setCommitmentsOrgId } from "../../../../store/commitments/commitmentsSlice";

export const UtilizationAndCoveragePage: FC = () => {
  const params = useParams();
  const orgId = params?.orgId ?? "";
  const dispatch = useAppDispatch();

  const billingStatusNotAvailable = useAppSelector(
    awsBillingStatusNotAvailableSelector,
  );

  useEffect(() => {
    dispatch(setCommitmentsOrgId(orgId));

    dispatch(getSavingsPlansThunk());
  }, [dispatch, orgId]);

  return (
    <Fragment>
      <UtilizationAndCoveragePageHeader />

      {billingStatusNotAvailable ? (
        <ProviderBillingDataAccessController provider="aws" />
      ) : (
        <Stack p={1} spacing={1}>
          <UtilizationAndCoverageMetadata />
          <UtilizationAndCoverageContent />
        </Stack>
      )}

      <UtilizationAndCoverageDrawers />
    </Fragment>
  );
};
