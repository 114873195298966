import { FC, useCallback, useMemo } from "react";
import { Button, Stack } from "@mui/material";
import { FormikHelpers } from "formik/dist/types";
import { FiltersInitialValues } from "../utils/types/types";
import {
  ResourceFilters,
  ResourceType,
} from "../../../../../../services/cloudchipr.api";
import { FiltersResetButton } from "../FiltersResetButton";
import { useAppSelector } from "../../../../../../store/hooks";
import { allowedFiltersByResourceTypeSelector } from "../../../../../../store/filters/selectors/allowedFiltersByResourceTypeSelector";
import { createNewFilterTemplateByResourceType } from "../utils/helpers/createNewFilterTemplateByResourceType";

interface FilterActionButtonsProps {
  resourceType: ResourceType;
  hideRevert?: boolean;
  filters: FiltersInitialValues;
  defaultFilters: ResourceFilters;
  setValues: FormikHelpers<FiltersInitialValues>["setValues"];
}

export const FilterDialogListActionButtons: FC<FilterActionButtonsProps> = ({
  setValues,
  filters,
  resourceType,
  defaultFilters,
  hideRevert,
}) => {
  const allowedFilters = useAppSelector((state) =>
    allowedFiltersByResourceTypeSelector(state, resourceType),
  );

  const newFilter = useMemo(() => {
    return createNewFilterTemplateByResourceType(
      resourceType,
      defaultFilters,
      allowedFilters,
    );
  }, [resourceType, defaultFilters, allowedFilters]);

  const makeNewFiltersForEmptyState = useCallback(
    (group?: boolean): FiltersInitialValues | null => {
      if (!newFilter) {
        return null;
      }

      return {
        type: resourceType,
        filter_items: [newFilter],
        filter_groups: group ? [[0]] : [0],
        operators: group ? ["and", "and"] : ["and"],
      };
    },
    [resourceType, newFilter],
  );

  const addFilterHandler = useCallback(() => {
    setValues((values) => {
      if (!newFilter) {
        return values;
      }

      if (!values.filter_items.length) {
        return makeNewFiltersForEmptyState() ?? values;
      }

      const newIndex = values.filter_items.length;

      return {
        ...values,
        filter_items: [...values.filter_items, newFilter],
        filter_groups: [...values.filter_groups, newIndex],
      };
    });
  }, [newFilter, setValues, makeNewFiltersForEmptyState]);

  const addFilterGroupHandler = useCallback(() => {
    setValues((values) => {
      if (!newFilter) {
        return values;
      }

      if (!values.filter_items.length) {
        return makeNewFiltersForEmptyState(true) ?? values;
      }

      const newIndex = values.filter_items.length;

      return {
        ...values,
        filter_items: [...values.filter_items, newFilter],
        filter_groups: [...values.filter_groups, [newIndex]],
        operators: [...values.operators, "and"],
      };
    });
  }, [newFilter, setValues, makeNewFiltersForEmptyState]);

  return (
    <Stack
      mt={2}
      spacing={2}
      direction="row"
      alignItems="center"
      justifyContent="space-between"
    >
      <div>
        <Button variant="text" onClick={addFilterHandler} size="small">
          + Add Filter
        </Button>

        <Button
          variant="text"
          onClick={addFilterGroupHandler}
          size="small"
          sx={{ ml: 2 }}
        >
          + Add Filter Group
        </Button>
      </div>

      {!hideRevert && (
        <FiltersResetButton
          filters={filters}
          setValues={setValues}
          defaultFilters={defaultFilters}
        />
      )}
    </Stack>
  );
};
