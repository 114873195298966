import { FC, useCallback } from "react";
import { UniqueIdentifier } from "@dnd-kit/core";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";

import { useParams } from "react-router-dom";
import InsertChartOutlinedOutlinedIcon from "@mui/icons-material/InsertChartOutlinedOutlined";
import { ResourceExplorerActionButton } from "../view-action-menu/for-all-views/ResourceExplorerActionButton";
import { ResourceExplorerV2NavigationAddViewNavItem } from "../nav-items/ResourceExplorerV2NavigationAddViewNavItem";
import { ResourceExplorerV2ViewFolderActionButton } from "../folder-actions-menu/ResourceExplorerV2ViewFolderActionButton";
import { SortableTree } from "../../../sortable-tree/SortableTree";
import { NavigationVisibilitySection } from "../../../common/visibility-types/NavigationVisibilitySection";
import {
  SortableTreeFolderActionArgs,
  SortableTreeItemActionArgs,
  SortableTreeOnchangeArgs,
  TreeItems,
} from "../../../sortable-tree/utils/types";
import { setNavigationResourceExplorer } from "../../../../../../store/navigation/navigationSlice";
import { useAppDispatch, useAppSelector } from "../../../../../../store/hooks";
import { NavigationItemsVisibilityType } from "../../../../../../store/navigation/utils/types";
import { updateResourceExplorerVisibilityHierarchyThunk } from "../../../../../../store/resource-explorer/thunks/resource-explorer-hierarchy-visibility/updateResourceExplorerVisibilityHierarchyThunk";
import { navigationResourceExplorerVisibilitySelectedSelector } from "../../../../../../store/navigation/selectors/resource-explorer/navigationResourceExplorerVisibilitySelectedSelector";
import { useAppAbility } from "../../../../../../services/permissions";
import { TypographyWithTooltip } from "../../../../../common/TypographyWithTooltip";

interface ResourceExplorerV2VisibilityItemProps {
  visibility: NavigationItemsVisibilityType;
  data?: TreeItems;
}

export const ResourceExplorerV2VisibilityItem: FC<
  ResourceExplorerV2VisibilityItemProps
> = ({ visibility, data }) => {
  const dispatch = useAppDispatch();
  const { viewId } = useParams<{ viewId: string }>();
  const { cannot } = useAppAbility();

  const canNotCreateView = cannot("create", "resource-explorer");
  const selected = useAppSelector((state) =>
    navigationResourceExplorerVisibilitySelectedSelector(
      state,
      visibility,
      viewId,
    ),
  );

  const renderFolderAction = useCallback(
    ({ id, name }: SortableTreeFolderActionArgs) => {
      return (
        <ResourceExplorerV2ViewFolderActionButton
          id={id}
          name={name}
          visibility={visibility}
        />
      );
    },
    [visibility],
  );

  const renderItemAction = useCallback(
    ({ id, name, parentId }: SortableTreeItemActionArgs) => {
      return (
        <ResourceExplorerActionButton
          id={id}
          name={name}
          folderId={(parentId as string) ?? undefined}
          visibility={visibility}
        />
      );
    },
    [visibility],
  );

  const hierarchyChangeHandler = useCallback(
    ({ index, id, parentId, items, type }: SortableTreeOnchangeArgs) => {
      if (!items) {
        return;
      }
      dispatch(
        setNavigationResourceExplorer({ data: items, type: visibility }),
      );
      dispatch(
        updateResourceExplorerVisibilityHierarchyThunk({
          id: id as string,
          index,
          folderId: (parentId as string) ?? undefined,
          visibility,
          type: type === "item" ? "view" : "folder",
        }),
      );
    },
    [dispatch, visibility],
  );

  const resetHandler = useCallback(() => {
    dispatch(
      setNavigationResourceExplorer({
        data: null,
        type: visibility,
      }),
    );
  }, [dispatch, visibility]);

  const navigateToHandler = useCallback((id: UniqueIdentifier) => {
    return `/resource-explorer/${id}`;
  }, []);

  if (!data) {
    return null;
  }

  return (
    <NavigationVisibilitySection
      expanded={!viewId}
      visibility={visibility}
      selected={selected}
    >
      {data?.length ? (
        <SortableTree
          items={data}
          renderItemAction={renderItemAction}
          renderFolderAction={renderFolderAction}
          onChange={hierarchyChangeHandler}
          getNavigateTo={navigateToHandler}
          onReset={resetHandler}
          icon={InsertChartOutlinedOutlinedIcon}
          rightIcon={
            visibility === "visible_only_to_me" ? LockOutlinedIcon : undefined
          }
          emptyText="No views inside"
          disabled={canNotCreateView}
        />
      ) : (
        <TypographyWithTooltip
          variant="caption"
          color="text.secondary"
          title="No folders and views inside"
          py={0.5}
          pl={4}
        />
      )}
      <ResourceExplorerV2NavigationAddViewNavItem visibility={visibility} />
    </NavigationVisibilitySection>
  );
};
