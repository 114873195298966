import { FC } from "react";
import { Stack, Typography } from "@mui/material";
import {
  CommitmentsUtilizationGrouping,
  CommitmentUtilizationType,
  Dates,
} from "../../../../../../../services/cloudchipr.api";
import { CommitmentUtilizationWidgetHeaderInfo } from "../../../../../../pages/dashboard/components/widgets/commitment-utlization/components/CommitmentUtilizationWidgetHeaderInfo";

interface InternalDashboardCommitmentUtilizationWidgetHeaderProps {
  widgetName: string;
  providerOrganisationId?: string;
  commitmentType: CommitmentUtilizationType;
  grouping: CommitmentsUtilizationGrouping;
  dates?: Dates;
}

export const InternalDashboardCommitmentUtilizationWidgetHeader: FC<
  InternalDashboardCommitmentUtilizationWidgetHeaderProps
> = ({
  widgetName,
  providerOrganisationId,
  grouping,
  dates,
  commitmentType,
}) => {
  return (
    <Stack
      pl={2}
      pr={0}
      py={1}
      borderBottom={1}
      position="sticky"
      top={0}
      bgcolor="white"
      zIndex={2}
      spacing={0.5}
      borderColor="grey.300"
    >
      <Typography variant="subtitle1" fontWeight="medium">
        {widgetName}
      </Typography>
      <CommitmentUtilizationWidgetHeaderInfo
        providerOrganisationId={providerOrganisationId}
        commitmentType={commitmentType}
        grouping={grouping}
        dates={dates}
      />
    </Stack>
  );
};
