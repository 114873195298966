import { FC, Fragment, useCallback } from "react";
import {
  CircularProgress,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import CachedIcon from "@mui/icons-material/Cached";
import ManageAccountsOutlinedIcon from "@mui/icons-material/ManageAccountsOutlined";
import { enqueueSnackbar } from "notistack";
import { ChangeRoleDialog } from "./ChangeRoleDialog";
import { useMenuHook } from "../../../../../../utils/hooks/useMenu.hook";
import {
  useDeleteUsersMeOrganisationsCurrentUsersByUserIdMutation,
  useGetV2UsersMeOrganisationsCurrentUsersQuery,
  usePatchUsersMeOrganisationsCurrentUsersByUserIdMutation,
  usePostUsersMeOrganisationsCurrentUsersByUserIdReInvitationsMutation,
  UserStatus,
} from "../../../../../../services/cloudchipr.api";
import { useAppAbility } from "../../../../../../services/permissions";
import { useDialog } from "../../../../../../utils/hooks/useDialog.hook";
import { DeleteConfirmationDialog } from "../../../../../common/modals/DeleteConfirmationDialog";
import { InvitedUserRoles } from "../utils/types/types";
import { useAppSelector } from "../../../../../../store/hooks";
import { currentUserIdSelector } from "../../../../../../store/profile/profileSelectors";

interface UsersListActionsProps {
  userId: string;
  email?: string;
  role: InvitedUserRoles;
  status?: UserStatus;
}

export const UsersListActions: FC<UsersListActionsProps> = ({
  userId,
  email = "",
  role,
  status,
}) => {
  const { can } = useAppAbility();
  const canCreateUser = can("create", "user");
  const canDeleteUser = can("delete", "user");

  const currentUserId = useAppSelector(currentUserIdSelector);

  const { open, anchor, openMenu, closeMenu } = useMenuHook();
  const { open: confirmDialogOpen, openDialog, closeDialog } = useDialog();
  const {
    open: changeRoleDialogOpen,
    openDialog: openChangeRoleDialog,
    closeDialog: closeChangeRoleDialog,
  } = useDialog();

  const [deleteUser, { isLoading: deleteUserIsLoading }] =
    useDeleteUsersMeOrganisationsCurrentUsersByUserIdMutation();
  const [resendInvitation, { isLoading: resendInvitationIsLoading }] =
    usePostUsersMeOrganisationsCurrentUsersByUserIdReInvitationsMutation();
  const [changeRole, { isLoading: changeRoleIsLoading }] =
    usePatchUsersMeOrganisationsCurrentUsersByUserIdMutation();

  const { refetch } = useGetV2UsersMeOrganisationsCurrentUsersQuery({});

  const confirmUserDeleteHandler = useCallback(async () => {
    try {
      await deleteUser({ userId }).unwrap();

      enqueueSnackbar("Invitation successfully deleted!", {
        variant: "snackbarAlert",
        AlertSnackBarProps: { severity: "success" },
      });

      closeDialog();
      await refetch();
    } catch (error: any) {
      enqueueSnackbar(error?.data?.message || "Something went wrong.", {
        variant: "snackbarAlert",
        AlertSnackBarProps: {
          severity: "error",
        },
      });
    }
  }, [userId, deleteUser, closeDialog, refetch]);

  const invitationResendHandler = useCallback(async () => {
    try {
      await resendInvitation({ userId }).unwrap();

      closeMenu();
      enqueueSnackbar("Invitation has been resent successfully!", {
        variant: "snackbarAlert",
        AlertSnackBarProps: { severity: "success" },
      });
    } catch (error: any) {
      enqueueSnackbar(error?.data?.message || "Something went wrong.", {
        variant: "snackbarAlert",
        AlertSnackBarProps: {
          severity: "error",
        },
      });
    }
  }, [closeMenu, userId, resendInvitation]);

  const roleChangeSuccessHandler = useCallback(
    async (role: any) => {
      try {
        await changeRole({ userId: userId, body: { role: role } }).unwrap();
        closeMenu();
        closeChangeRoleDialog();
        enqueueSnackbar("Role successfully changed!", {
          variant: "snackbarAlert",
          AlertSnackBarProps: { severity: "success" },
        });
        await refetch();
      } catch (error: any) {
        enqueueSnackbar(error?.data?.message || "Something went wrong.", {
          variant: "snackbarAlert",
          AlertSnackBarProps: {
            severity: "error",
          },
        });
      }
    },
    [closeMenu, refetch, changeRole, userId, closeChangeRoleDialog],
  );

  return (
    <Fragment>
      <IconButton onClick={openMenu}>
        <MoreVertIcon />
      </IconButton>

      <Menu anchorEl={anchor} open={open} onClose={closeMenu}>
        {status === "pending" && canCreateUser && (
          <MenuItem
            dense
            onClick={invitationResendHandler}
            disabled={resendInvitationIsLoading}
          >
            <ListItemIcon>
              {resendInvitationIsLoading ? (
                <CircularProgress size={20} color="inherit" />
              ) : (
                <CachedIcon fontSize="small" />
              )}
            </ListItemIcon>

            <ListItemText>Resend</ListItemText>
          </MenuItem>
        )}

        {canCreateUser && currentUserId !== userId && (
          <MenuItem
            dense
            onClick={openChangeRoleDialog}
            disabled={!canCreateUser}
          >
            <ListItemIcon>
              <ManageAccountsOutlinedIcon />
            </ListItemIcon>
            <ListItemText>Change Role</ListItemText>
          </MenuItem>
        )}

        <MenuItem dense onClick={openDialog} disabled={!canDeleteUser}>
          <ListItemIcon>
            <DeleteOutlinedIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Delete</ListItemText>
        </MenuItem>
      </Menu>

      <DeleteConfirmationDialog
        open={confirmDialogOpen}
        onClose={closeDialog}
        onDelete={confirmUserDeleteHandler}
        loading={deleteUserIsLoading}
        title="Delete User Invitation?"
        content={
          <Typography>
            Please confirm if you want to delete invitation for
            <Typography component="span" fontWeight="bold">
              {" "}
              {email}
            </Typography>
          </Typography>
        }
      />

      {changeRoleDialogOpen && (
        <ChangeRoleDialog
          open
          role={role}
          email={email}
          loading={changeRoleIsLoading}
          onClose={closeChangeRoleDialog}
          onSuccess={roleChangeSuccessHandler}
        />
      )}
    </Fragment>
  );
};
