import { SvgIcon, SvgIconProps, useTheme, colors } from "@mui/material";

const SqlSvgIcon = ({ primary }: SvgIconProps & { primary?: boolean }) => {
  const { palette } = useTheme();

  const color = primary ? palette.primary.main : palette.action.active;
  const colorLight = primary ? colors.indigo["300"] : palette.action.disabled;

  return (
    <SvgIcon>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.66992 10.44V13.45L11.9999 17.35V14.34L4.66992 10.44Z"
          fill={color}
          fillOpacity="0.5"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.66992 15.09V18.1L11.9999 22V18.99L4.66992 15.09Z"
          fill={color}
          fillOpacity="0.5"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 17.35L19.33 13.45V10.44L12 14.34V17.35Z"
          fill={colorLight}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 22L19.33 18.1V15.09L12 18.99V22Z"
          fill={colorLight}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19.33 8.91V5.9L12 2V5.01L19.33 8.91Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.9999 2L4.66992 5.9V8.91L11.9999 5.01V2Z"
          fill={colorLight}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.66992 5.87V8.89L11.9999 12.79V9.77L4.66992 5.87Z"
          fill={color}
          fillOpacity="0.5"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 12.79L19.33 8.89V5.87L12 9.77V12.79Z"
          fill={colorLight}
        />
      </svg>
    </SvgIcon>
  );
};

SqlSvgIcon.muiName = "SqlSvgIcon";

export default SqlSvgIcon;
