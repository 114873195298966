import { cleanV2ActionTypeSelector } from "./store-selectors/cleanV2ActionTypeSelector";
import { getFirstParentInHierarchyResourceType } from "../../../utils/helpers/resources/getFirstParentInHierarchyResourceType";
import { liveUsageMgmtResourceTypeDataWithChildrenSelector } from "../../live-usage-mgmt/selectors/resource-type-data/liveUsageMgmtResourceTypeDataWithChildrenSelector";
import { CleanV2ProcessResources, CleanV2ResourceType } from "../cleanV2Slice";
import { ResourceType } from "../../../services/cloudchipr.api";
import { RootState } from "../../store";
import {
  getResourceCleanActionType,
  isResourceNotCleanable,
} from "../utils/helpers";

export const cleanV2ProcessResourcesSelector = (
  state: RootState,
  resourceType: ResourceType,
  selectedResources: Record<string, boolean>,
): CleanV2ProcessResources | null => {
  const resources = liveUsageMgmtResourceTypeDataWithChildrenSelector(
    state,
    getFirstParentInHierarchyResourceType(resourceType) ?? resourceType,
  );

  const cleanActionType = cleanV2ActionTypeSelector(state);

  if (!cleanActionType || !resources) {
    return null;
  }

  return resources.reduce(
    (result, { resource }) => {
      if (!resource || !selectedResources?.[resource?.id]) {
        return result;
      }

      const action: CleanV2ResourceType["action"] =
        getResourceCleanActionType(cleanActionType);
      const snapshot = cleanActionType === "snapshot_and_delete";

      if (
        isResourceNotCleanable(
          {
            isProtected: resource.is_protected,
            markedForDeletion: !!resource.marked_for_deletion,
            notAllowedActions: resource.not_allowed_actions,
            accessType: resource.account.provider_access_type,
          },
          cleanActionType,
        )
      ) {
        result?.excludedResources?.push({
          action,
          snapshot,
          id: resource.id,
          type: resourceType,
          provider_unique_identifier: resource.provider_unique_identifier,
        });
      } else {
        result?.resources?.push({
          action,
          snapshot,
          id: resource.id,
          type: resourceType,
          provider_unique_identifier: resource.provider_unique_identifier,
        });
      }

      return result;
    },
    {
      resources: [],
      excludedResources: [],
    } as CleanV2ProcessResources,
  );
};
