import { ChangeEvent, FC, useCallback } from "react";

import InputAdornment from "@mui/material/InputAdornment";
import Typography from "@mui/material/Typography";
import { NumberField } from "../../../../../../../../../common/NumberField";
import { costsAnomalyThresholdDataByTypeV2 } from "../../../utils/constants/constants";
import { CostAnomalyAlertUnitType } from "../../../../../../../../../../store/alerts/utils/types/types";

interface AlertsCostsAnomalyThresholdSelectValueProps {
  unit: CostAnomalyAlertUnitType;
  onChange(value: string, index: number): void;
  amount: string;
  index: number;
}

export const AlertsCostsAnomalyThresholdSelectValue: FC<
  AlertsCostsAnomalyThresholdSelectValueProps
> = ({ unit, onChange, amount, index }) => {
  const handleThresholdAmountChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const amount = event.target.value;
      onChange(amount, index);
    },
    [onChange, index],
  );
  const thresholdData = costsAnomalyThresholdDataByTypeV2.get(unit);
  return (
    <NumberField
      minValue={1}
      maxValue={unit === "percent" ? 100 : undefined}
      size="small"
      sx={{ width: 160 }}
      value={amount ?? ""}
      onChange={handleThresholdAmountChange}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start" disableTypography>
            <Typography variant="inherit">{thresholdData?.unit}</Typography>
          </InputAdornment>
        ),
      }}
    />
  );
};
